import { ChangeDetectorRef, Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PutDebitSpreadRollStrategyParameters } from '../adjustment-strategies/put-debit-spread-roll/put-debit-spread-roll-strategy-parameters';
import { InterestCalculatorParameters } from '../adjustment-strategy-dialog/strategy-parameters/interest/interest-calculator-strategy-parameters';
import { LongOptionParameters } from '../adjustment-strategy-dialog/strategy-parameters/long-option/long-option-parameters';
import { PutSpreadParameters } from '../adjustment-strategy-dialog/strategy-parameters/put-spread/put-spread-parameters';
import { ShortOptionParameters } from '../adjustment-strategy-dialog/strategy-parameters/short-option/short-option-parameters';
import { SessionService } from '../authentication/session-service.service';
import { EtsConstants } from '../ets-constants.const';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { OrderDto } from '../shell-communication/dtos/order-dto.class';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { StrategyStateDto } from '../shell-communication/dtos/strategy-state-dto.class';
import { ExitStrategies } from '../shell-communication/operations/strategies/exit-strategies.class';
import { StartStrategies } from '../shell-communication/operations/strategies/start-strategies.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { FutureTimeSettings } from '../shell-communication/shell-dto-protocol';
import { GetLegUnderAdjustment, UpdateAdjustmentStrategy, UpdateOrderTimeSettings } from '../shell-communication/shell-operations-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategyState } from '../strategies/strategy-state.enum';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { BucketItemHighlighted } from '../ui-messages/ui-messages';
import { DetectMethodChanges, DetectSetterChanges, isAdjustmentAlgo, isHedgingAlgo, isNullOrUndefined } from '../utils';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-parameters-panel',
   templateUrl: 'parameters-panel.component.html',
   styleUrls: ['parameters-panel.component.scss']
})

export class ParametersPanelComponent extends PanelBaseComponent {
   constructor(
      _changeDetector: ChangeDetectorRef,
      _userSettingService: UserSettingsService,
      _messageBus: MessageBusService,
      private readonly _strategyService: StrategiesService,
      private readonly _shellService: ShellClientService,
      private readonly _toastr: ToastrService,
      sessionService: SessionService,
      portfolioService: PortfoliosService,
   ) {
      super(_changeDetector, _userSettingService, _messageBus);

      this.putSpreadParameters = new PutSpreadParameters(this._changeDetector);
      this.longOptionParameters = new LongOptionParameters(this._changeDetector);
      this.shortOptionParameters = new ShortOptionParameters(this._changeDetector);
      this.interestCalculatorParameters = new InterestCalculatorParameters(this._changeDetector, sessionService);
      this.rollSpreadParameters = new PutDebitSpreadRollStrategyParameters(this._changeDetector, null);

      this.shortOptionParameters.changed$.subscribe(x => this.hasChanges = true);
      this.longOptionParameters.changed$.subscribe(x => this.hasChanges = true);
      this.putSpreadParameters.changed$.subscribe(x => this.hasChanges = true);
      this.interestCalculatorParameters.changed$.subscribe(x => this.hasChanges = true);
   }

   //

   private _unsubscriber = new Subject();

   //

   private _showLegHeader = true;
   get showLegHeader(): boolean {
      return this._showLegHeader;
   }
   @DetectSetterChanges()
   set showLegHeader(v: boolean) {
      this._showLegHeader = v;
   }

   //

   strategy: StrategyModel;
   
   //

   orderModel: {
      order?: OrderDto,
      futureSettings?: FutureTimeSettings
      convertToMarket?: FutureTimeSettings;
      isConvertToMarket?: boolean,
      hasFutureSettings?: boolean
   } = {
      order: null,
      futureSettings: {},
      convertToMarket: {}
   };

   //

   leg: PositionDto;

   //

   private _hasChanges: boolean;
   get hasChanges(): boolean {
      return this._hasChanges;
   }
   @DetectSetterChanges()
   set hasChanges(v: boolean) {
      this._hasChanges = v;
   }
   

   //

   get canExitStrategy(): boolean {
      if (!this.strategy) {
         return false;
      }

      if (this.strategy.state === StrategyState.Active) {
         return true;
      }

      return false;
   }

   //

   get canStartStrategy(): boolean {
      if (!this.strategy) {
         return false;
      }

      if (this.strategy.state === StrategyState.Inactive) {
         return true;
      }

      return false;
   }

   //

   get canUpdateStrategy(): boolean {
      return this.canStartStrategy;
   }

   //

   readonly putSpreadParameters: PutSpreadParameters;
   readonly longOptionParameters: LongOptionParameters;
   readonly shortOptionParameters: ShortOptionParameters;
   readonly interestCalculatorParameters: InterestCalculatorParameters;
   readonly rollSpreadParameters: PutDebitSpreadRollStrategyParameters;

   //

   async etsOnInit(): Promise<void> {
      this.subscribeToMessages();
      this.shortOptionParameters.init();
   }

   //

   etsOnDestroy(): void {
      //
   }

   //

   etsAfterViewInit(): void {
      //
   }

   //

   @DetectMethodChanges({isAsync: true})
   async startStrategy() {
      const cmd = new StartStrategies([this.strategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //

   @DetectMethodChanges()
   async exitStrategy() {
      const cmd = new ExitStrategies([this.strategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //

   @DetectMethodChanges()
   async updateStrategy() {
      let parameters;
      let displayName;

      if (this.strategy.algoId === EtsConstants.algorithms.adjustment.putSpreadAlgoId) {
         parameters = this.putSpreadParameters.getParameters();
         displayName = this.putSpreadParameters.displayName;
      } else if (this.strategy.algoId === EtsConstants.algorithms.adjustment.longOptionAlgoId) {
         parameters = this.longOptionParameters.getParameters();
         displayName = this.longOptionParameters.displayName;
      } else if (this.strategy.algoId === EtsConstants.algorithms.adjustment.shortOptionAlgoId) {
         parameters = this.shortOptionParameters.getParameters();
         displayName = this.shortOptionParameters.displayName;
      } else if (this.strategy.algoId === EtsConstants.algorithms.interestAlgoId) {
         parameters = this.interestCalculatorParameters.getParameters();
         displayName = this.interestCalculatorParameters.displayName;
      }else if (this.strategy.algoId === EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId) {
         parameters = this.rollSpreadParameters.getParameters();
         displayName = this.rollSpreadParameters.displayName;
      }


      const cmd = new UpdateAdjustmentStrategy(
         this.strategy.strategyId,
         displayName,
         parameters
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(cmd);
         this.hasChanges = false;

      } catch (e) {

         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //

   private subscribeToMessages() {
      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(x => this.layoutTabId === x.scopeId)
         )
         .subscribe(msg => this.onBucketItemHighlighted(msg.payload));

      this._messageBus.of<StrategyStateDto[]>('StrategyStateDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onStrategyStateChanged(msg.payload));
      
      this._messageBus.of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(x => this.layoutTabId === x.scopeId)
         )
         .subscribe(msg => this.onStrategyHighlighted(msg.payload));
      
      this._messageBus.of<any>('OrderHighlightedUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(x => this.layoutTabId === x.scopeId)
         )
         .subscribe(msg => this.onOrderHighlighted(msg.payload));
   }

   @DetectMethodChanges()
   onOrderHighlighted(payload: {order: OrderDto}): void {
      
      if (!payload.order.futureSettings && !payload.order.convertToMarketSettings) {
         this.orderModel = {
            isConvertToMarket: false,
            convertToMarket: {},
            futureSettings: {},
            order: payload.order,
         };

         this.leg = {
            tickerDisplayName: payload.order.tickerDisplayName,
            portfolioName: payload.order.portfolioName,
            comboName: payload.order.comboName,
            comboGroupName: payload.order.comboGroupName
         } as any;

         return;
      }
      
      const futureSettings = JSON.parse(payload.order.futureSettings) as FutureTimeSettings || {};
      const convertToMarket = JSON.parse(payload.order.convertToMarketSettings) as FutureTimeSettings || {};

      this.orderModel = {
         order: payload.order,
         futureSettings,
         convertToMarket,
         isConvertToMarket: !isNullOrUndefined(convertToMarket.actionTime),
         hasFutureSettings: !isNullOrUndefined(futureSettings.actionTime),
      };
      
      this.leg = {
         tickerDisplayName: payload.order.tickerDisplayName,
         portfolioName: payload.order.portfolioName,
         comboName: payload.order.comboName,
         comboGroupName: payload.order.comboGroupName
      } as any;
   }

   @DetectMethodChanges({isAsync: true})
   private async onStrategyHighlighted(payload: StrategyHighlightedUIMessage): Promise<void> {
         
      if (!payload.strategy) {
         this.resetView();
         return;
      }

      if (!isAdjustmentAlgo(payload.strategy.algoId) && payload.strategy.algoId !== EtsConstants.algorithms.interestAlgoId) {
         this.resetView();
         return;
      }


      await this.showStrategyParameters(payload.strategy);
   }

   //

   private onStrategyStateChanged(payload: StrategyStateDto[]): void {
      
      if (!this.strategy) {
         return;
      }
      
      const filtered = payload.filter(x => x.strategyId === this.strategy.strategyId);

      if (filtered.length > 0) {
         this._changeDetector.detectChanges();
      }
      
   }

   //

   @DetectMethodChanges({isAsync: true})
   private async onBucketItemHighlighted(msg: BucketItemHighlighted): Promise<void> {
      
      if (msg.item.itemType !== PortfolioItemType.Strategy) {
         this.resetView();
         return;
      }

      const str = this._strategyService.getById(msg.item.portfolioItemId);

      if (isNullOrUndefined(str)) {
         this.resetView();
         return;
      }

      await this.showStrategyParameters(str);
   }

   @DetectMethodChanges()
   private async showStrategyParameters(str: StrategyModel) {
      
      if (this.strategy) {
         this.resetView();
      }

      
      try {
         
         this.strategy = str;
         
         this.showLegHeader = isHedgingAlgo(str.algoId);

         if (this.showLegHeader) {
            const cmd = new GetLegUnderAdjustment(str.strategyId);
            this.isLoading = true;
            const dto = await this._shellService.processQuery<PositionDto>(cmd);
            this.leg = dto;
         }

         if (str.algoId === EtsConstants.algorithms.adjustment.putSpreadAlgoId) {
            this.putSpreadParameters.setParameters(str);
         } else if (str.algoId === EtsConstants.algorithms.adjustment.longOptionAlgoId) {
            this.longOptionParameters.setParameters(str);
         } else if (str.algoId === EtsConstants.algorithms.adjustment.shortOptionAlgoId) {
            this.shortOptionParameters.setParameters(str);
         } else if (str.algoId === EtsConstants.algorithms.interestAlgoId) {
            this.interestCalculatorParameters.setParameters(str);
         } else if (str.algoId === EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId) {
            this.rollSpreadParameters.setParameters(str);
         }

      } finally {

         this.isLoading = false;
         this.hasChanges = false;
      }
   }

   @DetectMethodChanges()
   resetView() {
      const str = this.strategy;

      if (!str) {
         return;
      }

      if (str.algoId === EtsConstants.algorithms.adjustment.putSpreadAlgoId) {
         this.putSpreadParameters.reset();
      } else if (str.algoId === EtsConstants.algorithms.adjustment.longOptionAlgoId) {
         this.longOptionParameters.reset();
      } else if (str.algoId === EtsConstants.algorithms.adjustment.shortOptionAlgoId) {
         this.shortOptionParameters.reset();
      } else if (str.algoId === EtsConstants.algorithms.interestAlgoId) {
         this.interestCalculatorParameters.reset();
      } else if (str.algoId === EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId) {
         this.rollSpreadParameters.reset();
      }

      this.leg = null;
      this.showLegHeader = null;
      this.strategy = null;
      this.hasChanges = false;
   }

   //

   protected getState() {
      return null;
   }

   //

   protected setState(state: any) {
      //
   }

   @DetectMethodChanges()
   async updateOrder() {
      let futureSettings: FutureTimeSettings;
      if (this.orderModel.futureSettings) {
         futureSettings = this.orderModel.futureSettings;
      }

      let convertToMarketSettings: FutureTimeSettings;

      if (this.orderModel.convertToMarket) {
         convertToMarketSettings = this.orderModel.convertToMarket;
      }

      const updateCmd = new UpdateOrderTimeSettings(
         this.orderModel.order.orderId,
         futureSettings,
         convertToMarketSettings
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(updateCmd);

      } catch (e) {

         this._toastr.error('"Update Order Time Settings" command completed with errors');

      } finally {

         this.isLoading = false;

      }
   }


   @DetectMethodChanges()
   onChange() {
      if (!this.orderModel.isConvertToMarket) {
         this.orderModel.convertToMarket = {};
      }
   }
}
