<dx-popup 
   [resizeEnabled]="true" 
   [dragEnabled]="true"
   [title]="this.title"
   [visible]="this.isVisible"
   (onShown)="this.onShown()"
   (onHidden)="this.onHidden()"
   height="90vh"
   width="50vw"
   >
   <div *dxTemplate="let data of 'content'" class="content-wrapper" #wrapper>
   
      <div class="editor">

         <div class="symbol-picker">
            <ets-symbol-picker 
               [showLastPrice]="false" 
               [showClearButton]="true"
               [selectedInstrument]="this.tradingInstrument" 
               (instrumentSelected)="this.onTradingInstrumentSelected($event)"
               placeholder="Mother of Defaults"
               >
            </ets-symbol-picker>
         </div>

         <hr class="separator" />

         <div class="settings">
            
            <div class="parameters">
               <dx-scroll-view>
                  <ets-cashflow-strategy-settings
                     [settings]="this.settingsFirst"
                     [reversedOrder]="true"
                     strategy="Calls"
                     [shouldRollDates]="true">
                  </ets-cashflow-strategy-settings>
               </dx-scroll-view>
            </div>                  
            
            <div class="parameters">
               <dx-scroll-view>
                  <ets-cashflow-strategy-settings
                     [settings]="this.settingsSecond"
                     strategy="Puts"
                     [shouldRollDates]="true">
                  </ets-cashflow-strategy-settings>
               </dx-scroll-view>
            </div>
            
         </div>

      </div>

      <hr class="separator" />

      <div class="buttons">
         <div class="button">
            <dx-button text="Save" width="100%" (onClick)="this.onSaveClicked()"></dx-button>
         </div>
         <div class="button">
            <dx-button text="Close" width="100%" (onClick)="this.onCloseClicked()"></dx-button>
         </div>
      </div>


      <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: wrapper}"></dx-load-panel>
      
   </div>
</dx-popup>