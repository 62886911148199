<div class="panel-container" [ngClass]="{'ets-hidden': !isActive}" #container>

   <dx-scroll-view class="orders-block">

      <ets-multi-leg-order #mainOrder
                           [comp]="this"
                           [orderRole]="'Main'"
                           (destinationAdded)="this.onDestinationAdded('main', $event)"
                           (destinationRemoved)="this.onDestinationRemoved('main', $event)"
                           (destinationPressed)="this.onDestinationPressed('main', $event)"
                           (linkedOrdersPasted)="this.onLinkedOrdersPasted($event, mainOrderComp)"
                           >
      </ets-multi-leg-order>

      <div class="separator-section">

         <div class="section input linked-order-type">
            <dx-select-box [items]="this.linkedOrderTypeList"
                           [placeholder]="'Linked Order'"
                           (onValueChanged)="this.onLinkedOrderATypeSelected($event)"
                           [value]="this.linkedOrderAType"
                           [showClearButton]="true"
                           [disabled]="this.isEmulationMode"
                           [width]="120">
            </dx-select-box>
         </div>

         <div class="section input emulator">
            <dx-check-box text="Emulation Mode" [(value)]="this.isEmulationMode"></dx-check-box>
         </div>

         <div class="section verify-btn">
            <dx-button  [text]="'Verify'"
                        [width]="'100%'"
                        [type]="'default'"
                        (onClick)="verifyOrders()"
            ></dx-button>
         </div>

      </div>

      <ets-multi-leg-order [class.ets-hidden]="!this.linkedOrderAType"
                           #linkedOrderA
                           [comp]="this"
                           [orderRole]="'Linked #1'"
                           (destinationAdded)="this.onDestinationAdded('link-a', $event)"
                           (destinationRemoved)="this.onDestinationRemoved('link-a', $event)"
                           (destinationPressed)="this.onDestinationPressed('link-a', $event)"
                           (linkedOrdersPasted)="this.onLinkedOrdersPasted($event, linkedOrderAComp)"
                           >
      </ets-multi-leg-order>

      <div class="separator-section" *ngIf="this.linkedOrderAType">

         <div class="section input linked-order-type">

            <dx-select-box [items]="this.linkedOrderTypeList"
                           [placeholder]="'Linked Order'"
                           (onValueChanged)="this.onLinkedOrderBTypeSelected($event)"
                           [value]="this.linkedOrderBType"
                           [showClearButton]="true"
                           [disabled]="this.isEmulationMode"
                           [width]="120">
            </dx-select-box>
         </div>

      </div>

      <ets-multi-leg-order [class.ets-hidden]="!this.linkedOrderBType"
                           #linkedOrderB
                           [comp]="this"
                           [orderRole]="'Linked #2'"
                           (destinationAdded)="this.onDestinationAdded('link-b', $event)"
                           (destinationRemoved)="this.onDestinationRemoved('link-b', $event)"
                           (destinationPressed)="this.onDestinationPressed('link-b', $event)"
                           (linkedOrdersPasted)="this.onLinkedOrdersPasted($event, linkedOrderBComp)"
                           >
      </ets-multi-leg-order>

   </dx-scroll-view>

   <!-- Trade Confirmation Dialog -->
   <dx-popup   [width]="1350"
               [height]="this.confirmationModel.dialogHeight"
               title="Trade Confirmation"
               [showTitle]="true"
               [closeOnOutsideClick]="false"
               [resizeEnabled]="true"
               [dragEnabled]="true">

      <ng-template #confirmation let-model>

         <div class="order-header">
            <span>{{model?.orderName}}</span>
            <span *ngIf="model?.linkType">&nbsp;({{model?.linkType}})</span>
            <span *ngIf="model?.hasAttachment">&nbsp;(+AA)</span>
            <span *ngIf="model?.hasFutureSettings || model?.hasConvertSettings">&nbsp;(+FO)</span>
         </div>

         <div class="root-destination" *ngIf="model?.root">

            <div class="cell terminal" *ngIf="model?.root?.terminal">

               <span class="title">Terminal:</span>

               <span class="value">{{model?.root?.terminal}}</span>
            </div>

            <div class="cell portfolio" *ngIf="model?.root?.portfolio">

               &nbsp;|&nbsp;

               <span class="title">Portfolio: </span>

               <span class="value">{{model?.root?.portfolio}}</span>
            </div>

            <div class="cell combo" *ngIf="model?.root?.combo">

               &nbsp;|&nbsp;

               <span class="title">Combo: </span>

               <span class="value">{{model?.root?.combo}}</span>
            </div>

            <div class="cell combo-group" *ngIf="model?.root?.comboGroup">

               &nbsp;|&nbsp;

               <span class="title">ComboGroup: </span>

               <span class="value">{{model?.root?.comboGroup}}</span>
            </div>
         </div>

         <table>

            <thead>
               <tr>
                  <td class="cell symbol" width="20%">Symbol</td>
                  <td class="cell side" width="5%">Side</td>
                  <td class="cell qty" width="5%" title="Total Qty">T.Qty</td>
                  <td class="cell eff-qty" width="5%" title="Effective Qty">E.Qty</td>
                  <td class="cell terminal" width="12%">Terminal</td>
                  <td class="cell portfolio" width="18%">Portfolio</td>
                  <td class="cell combo" width="18%">Combo</td>
                  <td class="cell combo-group" width="17%">Combo Group</td>
               </tr>
            </thead>

            <tbody>

               <tr *ngFor="let leg of model?.legs">

                  <td class="cell ellipsis symbol" [class.put]="leg.symbol.indexOf(' Put ') > 0"
                     #symbolCell
                     (mouseenter)="this.toggleTooltip(leg, 'symbol')"
                     (mouseleave)="this.toggleTooltip(leg, 'symbol')">
                     <span>{{leg.symbol}}</span>
                     <dx-tooltip [target]="symbolCell" [visible]="leg.tooltip_symbol">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.symbol}}
                        </div>
                     </dx-tooltip>
                  </td>

                  <td class="cell side" [class.buy]="leg.side === 'Buy'"
                                          [class.sell]="leg.side === 'Sell'">
                     {{leg.side}}
                  </td>

                  <td class="cell qty">
                     {{leg.totalQty | number:'1.0-2':'en-US' }}
                  </td>

                  <td class="cell eff-qty">
                     {{leg.effectiveQty | number:'1.0-2':'en-US' }}
                  </td>

                  <td class="cell ellipsis terminal">
                     <span>{{leg.terminal}}</span>
                  </td>

                  <td class="cell ellipsis portfolio" #pfCell
                     (mouseenter)="this.toggleTooltip(leg, 'portfolio')"
                     (mouseleave)="this.toggleTooltip(leg, 'portfolio')">
                     <span>{{leg.portfolio}}</span>
                     <dx-tooltip [target]="pfCell" [visible]="leg.tooltip_portfolio">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.portfolio}}
                        </div>
                     </dx-tooltip>
                  </td>

                  <td class="cell ellipsis combo" #comboCell
                     (mouseenter)="this.toggleTooltip(leg, 'combo')"
                     (mouseleave)="this.toggleTooltip(leg, 'combo')">

                     <span>{{leg.combo}}</span>
                     <dx-tooltip [target]="comboCell" [visible]="leg.tooltip_combo">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.combo}}
                        </div>
                     </dx-tooltip>
                  </td>

                  <td class="cell ellipsis combo-group" #comboGroupCell
                     (mouseenter)="this.toggleTooltip(leg, 'comboGroup')"
                     (mouseleave)="this.toggleTooltip(leg, 'comboGroup')">
                     <span>{{leg.comboGroup}}</span>
                     <dx-tooltip [target]="comboGroupCell" [visible]="leg.tooltip_comboGroup">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.comboGroup}}
                        </div>
                     </dx-tooltip>
                  </td>
               </tr>

               <tr *ngIf="model?.totalQty > 1">
                  <td class="cell symbol"></td>
                  <td class="cell side"></td>
                  <td class="cell qty"></td>
                  <td class="cell eff-qty">
                     <span class="total">
                        x {{model?.totalQty | number:'1.0-2':'en-US' }}
                     </span>
                  </td>
                  <td class="cell terminal"></td>
                  <td class="cell portfolio"></td>
                  <td class="cell combo"></td>
                  <td class="cell combo-group"></td>
               </tr>


            </tbody>

         </table>

         <hr>

         <div class="order-params">

            <div class="cell account">
               <span class="title">Account:</span>
               <span class="value">{{model?.account}}</span>
            </div>


            <div class="cell terminal" *ngIf="!model?.root">

               &nbsp;|&nbsp;

               <span class="title">Terminal:</span>

               <span class="value">{{model?.terminal}}</span>
            </div>

            <div class="cell order-duration">
               &nbsp;|&nbsp;

               <span class="title duration">Duration:</span>

               <span class="value duration">{{model?.duration}}</span>
            </div>

            <div class="cell order-type">

               &nbsp;|&nbsp;

               <span class="title order-type">Type:</span>

               <span class="value order-type">{{model?.type}}</span>
            </div>

            <div class="cell order-price" *ngIf="model?.type === 'Limit'">

               &nbsp;|&nbsp;

               <span class="title order-price">Limit Px:</span>

               <span class="value order-price">{{model?.limitPx}}</span>
            </div>

            <div class="cell market-value">

               &nbsp;|&nbsp;

               <span class="title">Market Value:</span>

               <span class="value" [class.credit-trade]="model?.marketValue > 0"
                                    [class.debit-trade]="model?.marketValue < 0">
                  {{ model?.marketValue | currency }}
               </span>
            </div>

            <div class="cell limit-value" *ngIf="model?.type === 'Limit'">
               &nbsp;|&nbsp;

               <span class="title">Limit Value:</span>

               <span class="value" [class.credit-trade]="model?.limitValue > 0"
                                    [class.debit-trade]="model?.limitValue < 0">
                  {{ model?.limitValue | currency }}
               </span>
            </div>
         </div>

      </ng-template>

      <div *dxTemplate="let data of 'content'" style="position: relative" class="verify-dialog">

         <div *ngFor="let orderModel of this.confirmationModel.orders; index as ix"
                  [style.display]="ix !== this.confirmationModel.selectedDestination ? 'none' : 'unset' ">

            <ng-container *ngTemplateOutlet="confirmation; context: {$implicit: orderModel?.mainOrder}"></ng-container>

            <div class="order linked" *ngIf="this.linkedOrderAType">
               <ng-container *ngTemplateOutlet="confirmation; context: {$implicit: orderModel?.linkedOrderA}"></ng-container>
            </div>

            <div class="order linked" *ngIf="this.linkedOrderBType">
               <ng-container *ngTemplateOutlet="confirmation; context: {$implicit: orderModel?.linkedOrderB}"></ng-container>
            </div>

         </div>

         <div class="bottom">
            <div class="destinations">
               <span *ngFor="let orderModel of this.confirmationModel.orders; index as ix; last as isLast" class="destination-selector"
                  [class.active]="this.confirmationModel.selectedDestination === ix" (click)="this.selectVerifyDestination(ix)">
                  Dest.: "{{orderModel?.mainOrder?.destinationName}}"
                  <span *ngIf="!isLast">&nbsp; | &nbsp;</span>
               </span>
            </div>

            <div>
               <dx-button text="Place Order" [disabled]="!this.canPlaceOrders" (onClick)="this.sendOrders()"></dx-button>
            </div>

         </div>

         <dx-load-panel [visible]="this.sendingOrder"></dx-load-panel>

      </div>

   </dx-popup>

   <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: container}"></dx-load-panel>
</div>
