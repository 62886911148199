import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConvertToMarketSettings } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';
import { isNullOrUndefined } from 'util';

@Component({
   selector: 'ets-convert-to-market',
   templateUrl: 'convert-to-market.component.html',
   styleUrls: ['./convert-to-market.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConvertToMarketComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   @Input() hideCalendar: boolean;
   
   @Input() flow: 'inline' | 'block' = 'inline';
   
   private _settings: ConvertToMarketSettings;
   get settings(): ConvertToMarketSettings {
      return this._settings;
   }
   @Input()
   @DetectSetterChanges()
   set settings(value: ConvertToMarketSettings) {
      this._settings = value || {};
   }


   @Output() parameterChanged = new EventEmitter();

   ngOnInit() { }

   onChange(parameter?: string) {
      if (parameter === 'replaceBeforeConvert') {
         if (!this.settings.replaceBeforeConvert) {
            this.settings.timesToReplace = null;
            this.settings.replaceEvery = null;
            this.settings.rateOfChange = null;
            this.settings.reverseTimeDirection = false;
            this.settings.replacePersistently = false;
         }
      } else if (parameter === 'replacePersistently') {
         if (this.settings.replacePersistently) {
            this.settings.timesToReplace = null;
         }
      }
      this._changeDetector.detectChanges();
      this.parameterChanged.emit();
   }

   getDateTimePickerMode() {
      if (!this.settings) {
         return null;
      }

      if (!this.settings.actionTimeMode) {
         return null;
      }

      if (this.settings.actionTimeMode === 'Convert After') {
         return 'timespan';
      }

      if (this.settings.actionTimeMode === 'Convert At') {
         return this.hideCalendar ? 'time' : 'datetime';
      }

      return null;
   }

   // this hack is used to avoid 3-state checkbox. and because settings are implemented as interface
   // we cannot guarantee null value in place of booleans
   getBoolean(attr: string) {
      const val = this.settings[attr];

      if (isNullOrUndefined(val)) {
         return false;
      }

      return val;
   }

   @DetectMethodChanges()
   onReplaceBeforeConvertDisabledChange(disabled: boolean) {
      if (disabled) {
         this.settings.replaceBeforeConvert = false;
      }
   }
}
