import { formatNumber } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { AgGridColumn } from 'ag-grid-angular';
import { ColDef, FirstDataRenderedEvent, GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import * as Enumerable from 'linq';
import { pipe, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { centeredColumnDef, defaultLoadingOverlayTemplate, defaultNumberCellFormatter, defaultPriceCellFormatter, defaultQuoteCellFormatter, expandFirstLevelGroups, GetDetailRowDataParams, getDetailSymbolColumn, getMasterSymbolColumn, liveQuoteFormatter } from '../ag-grid-contrib';
import { EtsConstants } from '../ets-constants.const';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { OrderDto } from '../shell-communication/dtos/order-dto.class';
import { FutureTimeSettings, GetAvailableBucketsReply } from '../shell-communication/shell-dto-protocol';
import { TimestampsService } from '../timestamps.service';
import { MarketSide } from '../trading-model/market-side.enum';
import { OrderStatus } from '../trading-model/order-status.enum';
import { OrderType } from '../trading-model/order-type.enum';
import { TimeInForce } from '../trading-model/time-in-force.enum';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { WorkingOrdersIndex } from '../unspecific/working-orders-index.class';
import { DetectMethodChanges, DetectSetterChanges, isCashSettledComboOrder, isCashSettledOptionTicker, isNullOrUndefined, parseOrderLegs } from '../utils';
import { AdjustmentBucketConfig } from './bucket-config';


export class OrdersBlock {
   
   constructor(private _changeDetector: ChangeDetectorRef, 
               private _messageBus: MessageBusService, 
               private _timestampsService: TimestampsService,       
               private _lastQuoteCache: LastQuoteCacheService, 
               private _portfolioService: PortfoliosService) {

         this.gridOptions = getOrdersGridOptions.bind(this)();
         this._ordersIx = new WorkingOrdersIndex();
   }


   private _grid: GridReadyEvent;
   private _unsubscriber = new Subject<void>();
   private _availableBuckets: GetAvailableBucketsReply;
   private _ordersIx: WorkingOrdersIndex;
   private _bucketConfig: AdjustmentBucketConfig;

   //
   private _collapsed: boolean;
   get collapsed(): boolean {
      return this._collapsed;
   }
   
   @DetectSetterChanges()
   set collapsed(v: boolean) {
      this._collapsed = v;
   }
   
   //
   get messageBus(): MessageBusService {
      return this._messageBus;   
   }

   //
   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   //
   get unsubscriber(): Subject<void> {
      return this._unsubscriber;
   }

   //
   get gridApi(): GridApi {
      
      if (!this._grid) {
         return;
      }

      return this._grid.api;
   }

   //
   get availableBuckets(): GetAvailableBucketsReply {
      return this._availableBuckets;
   }

   //
   get lastQuoteCache(): LastQuoteCacheService {
      return this._lastQuoteCache;
   }

   //
   get height(): number {
      
      let h = 90;


      const groups = Enumerable.from(this._ordersIx.getCurrentState()).select(x => x.comboGroupId).distinct().count();


      const extr = (this._ordersIx.count() + groups) * 26;

      h += extr;

      return h;
   }

   //
   gridOptions: GridOptions;

   //
   async onGridReady(args: GridReadyEvent) {
      this._grid = args;
      this._availableBuckets = await this._portfolioService.getAvailableBuckets();
      this._messageBus.of<any>('OrderDto')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(x => EtsConstants.strategies.manualStrategyId in x.payload),
            map(x => x.payload[EtsConstants.strategies.manualStrategyId]),
            filter(x => x.length > 0),
         )
         .subscribe(msg => this.onOrderDto(msg));
   }

   //
   @DetectMethodChanges()
   onOrderDto(payload: OrderDto[]): void {

      if (!this._bucketConfig) {
         return;
      }

      const filtered = payload.filter(o => o.comboId === this._bucketConfig.comboId);
      
      const calcState = this._ordersIx.calculateState(filtered);
      
      if (this._grid) {
         this._grid.api.setRowData(calcState);
         expandFirstLevelGroups(this._grid);
      }
   }
   
   setBucket(bucketConfig: AdjustmentBucketConfig) {
      this._bucketConfig = bucketConfig;
   }

   setData(orders: OrderDto[]) {

      const data = orders || [];
      
      const calcState = this._ordersIx.calculateState(data);
      
      if (this._grid) {
         this._grid.api.setRowData(calcState);
         expandFirstLevelGroups(this._grid);
      }
   }

   reset() {
      this._ordersIx.reset();
      if (!this._grid) {
         return;
      }
      this._grid.api.setRowData([]);
   }
}

export function getOrdersGridOptions(this: OrdersBlock): GridOptions {

   const columns: Partial<AgGridColumn>[] = getColumnDefs(this);

   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      immutableData: true,

      tooltipShowDelay: 1000,

      frameworkComponents: {
         simpleHeaderRenderer: SimpleHeaderGroupRowInnerRenderer,
      },

      groupUseEntireRow: true,

      groupRowRendererParams: {
         innerRenderer: 'simpleHeaderRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: 'never',

      suppressCellSelection: true,

      masterDetail: true,

      detailRowAutoHeight: true,

      detailCellRendererParams: {

         detailGridOptions: {

            defaultColDef: centeredColumnDef,

            groupIncludeTotalFooter: true,

            suppressAggFuncInHeader: true,

            columnDefs: [

               getDetailSymbolColumn('ticker', this.messageBus, this.timestampsService, this.unsubscriber, () => this.gridApi),

               { headerName: 'Buy/Sell', field: 'side' },

               { headerName: 'Qty', field: 'qty' },

               {
                  headerName: 'Live Quote',
                  field: 'liveQuote',
                  valueFormatter: liveQuoteFormatter
               },

               { headerName: 'Portfolio', field: 'portfolioName', tooltipField: 'portfolioName' },
               
               { headerName: 'Combo', field: 'comboName', tooltipField: 'comboName' },
               
               { headerName: 'ComboGroup', field: 'comboGroupName', tooltipField: 'comboGroupName' },

               {
                  headerName: 'Delta',
                  field: 'delta',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  aggFunc: 'sum'
               },

               {
                  headerName: 'T. Delta',
                  field: 'totalDelta',
                  aggFunc: 'sum',
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data as any;

                     if (!params.data) {
                        return null;
                     }

                     const value = leg.ticker.startsWith('@')
                        ? leg.delta * 100
                        : leg.qty;

                     return isNaN(value) ? null : value;
                  },
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-2');
                  }
               },

               {
                  headerName: 'Gamma',
                  field: 'gamma',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  hide: true
               },

               {
                  headerName: 'T. Gamma',
                  field: 'totalGamma',
                  hide: true,
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data;

                     if (!params.data) {
                        return null;
                     }

                     const v = leg.ticker.startsWith('@')
                        ? leg.gamma * 100
                        : leg.qty;

                     return isNaN(v) ? null : v;
                  },
               },

               {
                  headerName: 'Vega',
                  field: 'vega',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  hide: true
               },

               {
                  headerName: 'T. Vega',
                  field: 'totalVega',
                  hide: true,
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data;

                     if (!params.data) {
                        return null;
                     }

                     return leg.ticker.startsWith('@')
                        ? leg.vega * 100
                        : leg.qty;
                  },
               },

               {
                  headerName: 'Theta',
                  field: 'theta',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data;

                     if (!params.data) {
                        return null;
                     }

                     return leg.ticker.startsWith('@')
                        ? leg.theta * 100
                        : leg.qty;
                  },
                  hide: true
               },
            ],

            getRowNodeId: (rowData) => {
               return rowData.ticker;
            }

         },

         getDetailRowData: (params: GetDetailRowDataParams) => {
            const order = params.data as OrderDto;

            if (order.legs) {
               params.successCallback(order.legs);
               return;
            }

            parseOrderLegs(order, this.availableBuckets, this.lastQuoteCache);

            params.successCallback(order.legs);
         },
      },

      isRowMaster: (dataItem) => {
         const order = dataItem as OrderDto;
         return !isNullOrUndefined(order.multiLegDescriptor);
      },

      onGridReady: (args) => this.onGridReady(args),

      getRowNodeId: (rowData: OrderDto) => rowData.orderId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];

         menu.push('separator');

         menu.push(
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            'copy',
            'export'
         );

         return menu;
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      // onDisplayedColumnsChanged: () => this.onStateChanged(),

      // onColumnResized: () => this.onStateChanged(),

      // onRowSelected: (args: RowSelectedEvent) => this.onRowSelected(args)

   } as GridOptions;
}


function getColumnDefs(comp: OrdersBlock): Partial<AgGridColumn>[] {

   const cols: Partial<AgGridColumn>[] = [];

   cols.push({
      headerName: '',
      checkboxSelection: true,
      pinned: true,
      width: 30
   });


   const symbolCol = getMasterSymbolColumn('ticker', comp.messageBus, comp.timestampsService, comp.unsubscriber, () => comp.gridApi);
   cols.push(symbolCol);

   const expirationStyle: ColDef = {
      headerName: 'Exp. Style',
      colId: 'expStyle',
      valueGetter: (args: ValueGetterParams) => {
         if (!args.data) {
            return '';
         }

         if (!args.data.ticker.startsWith('@')) {

            const comboOrder = args.data && args.data.multiLegDescriptor;
            
            if (comboOrder) {
               
               if (isCashSettledComboOrder(args.data.multiLegDescriptor)) {
                  return 'European';
               }

               return 'American';
            }

            return '';
         }

         return isCashSettledOptionTicker(args.data.ticker) ? 'European' : 'American';
      }
   };
   cols.push(expirationStyle);

   cols.push({
      headerName: 'Status',
      field: 'status',
      valueFormatter: (params: ValueFormatterParams) => {

         const order = params.data as OrderDto;

         let status = OrderStatus[params.value];

         if (order.masterOrder) {
            status += ` (${order.masterOrder})`;
         }

         return status;

      },
      filter: true
   });

   cols.push({
      headerName: 'Type',
      field: 'orderType',
      valueFormatter: (params: ValueFormatterParams) => {
         return OrderType[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'Leaves Qty',
      field: 'leavesQty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.leavesQty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push(
      {
         headerName: 'Order Px',
         valueGetter(params: ValueGetterParams) {
            const data: OrderDto = params.data;

            if (data) {
               if (data.limitPrice && data.limitPrice !== 0) {
                  return data.limitPrice;
               }
               
               if (data.stopPrice && data.stopPrice !== 0) {
                  return data.stopPrice;
               }
   
               if (data.autoLimitPrice) {
                  return data.autoLimitPrice;
               }
            }
            
            
            return 0;
         },
         sort: 'desc',
         valueFormatter: defaultPriceCellFormatter
      },
   );

   cols.push({
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter
   });

   cols.push(
      {
         headerName: 'Combo Group',
         field: 'comboGroupName',
         tooltipField: 'comboGroupName',
         hide: false,
         enableRowGroup: true,
         rowGroup: true
      },
   );

   cols.push({
      headerName: 'Last Qty.',
      field: 'lastQty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Last Fill Px.',
      field: 'lastPx',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Qty',
      field: 'qty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.qty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Limit Px',
      field: 'limitPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Stop Px',
      field: 'stopPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Side',
      field: 'side',
      valueFormatter: (params: ValueFormatterParams) => {
         return MarketSide[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'Filled',
      field: 'filledQty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.filledQty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Avg. Px',
      field: 'avgFillPrice',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Account',
      field: 'resolveAccountCode',
      filter: true
   });

   cols.push({
      headerName: 'Comment',
      field: 'comment',
      filter: 'agTextColumnFilter'
   });

   cols.push({
      headerName: 'Timestamp',
      field: 'lastModifiedDate',
      valueFormatter: (params: ValueFormatterParams) => {
         const order: OrderDto = params.data;
         const frmtTime = comp.timestampsService.getFormattedDateTimeForStrategy(order.strategyId, order.lastModifiedDate);
         return order.lastModifiedDate
            ? frmtTime
            : '';
      },
   });

   cols.push({
      headerName: 'TIF',
      field: 'tif',
      valueFormatter: (params: ValueFormatterParams) => {
         return TimeInForce[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'ID',
      field: 'orderId',
      filter: 'agTextColumnFilter',
   });

   cols.push({
      headerName: 'Convert To Market',
      field: 'convertToMarketSettings',
      valueGetter: (args: ValueGetterParams) => {
         const stngs = JSON.parse(args.data.convertToMarketSettings) as FutureTimeSettings;
         if (!stngs) {
            return null;
         }

         if (!stngs.actionTimeMode) {
            return null;
         }
         
         if (stngs.actionTimeMode.endsWith(' At')) {
            return `at ${stngs.actionTime} (${stngs.timezone})`;
         }

         if (stngs.actionTimeMode.endsWith(' After')) {
            return `after ${stngs.actionTime}`;
         }

         return null;
      }
   });
   
   cols.push({
      headerName: 'Place Order',
      field: 'futureSettings',
      valueGetter: (args: ValueGetterParams) => {
         const stngs = JSON.parse(args.data.futureSettings) as FutureTimeSettings;
         if (!stngs) {
            return null;
         }

         if (!stngs.actionTimeMode) {
            return null;
         }
         
         if (stngs.actionTimeMode.endsWith(' At')) {
            return `at ${stngs.actionTime} (${stngs.timezone})`;
         }

         if (stngs.actionTimeMode.endsWith(' After')) {
            return `after ${stngs.actionTime}`;
         }

         return null;
      }
   });

   return cols;
}
