import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdjustmentStrategiesModule } from '../adjustment-strategies/adjustment-strategies.module';
import { AdjustmentStrategyDialogModule } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DevExtremeModule } from '../devextreme.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';

import { ParametersPanelComponent } from './parameters-panel.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      AdjustmentStrategyDialogModule,
      DateTimePickerModule,
      TimezonePickerModule,
      AdjustmentStrategiesModule
   ],
   exports: [
      ParametersPanelComponent
   ],
   declarations: [
      ParametersPanelComponent
   ],
   providers: [],
})
export class ParametersPanelModule { }
