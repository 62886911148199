// tslint:disable: no-string-literal
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { DetectParameterChanges, isTruthy } from 'projects/shared-components/utils';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { RemoteAlgoParametersViewComponent } from './remote-algo-parameters-view.component';
import { ParametersControlBase } from '../../parameters-control-base';
import { ICommonDispositionParameters } from '../../common-disposition-parameters';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { TriggerDto, TriggerPurpose } from 'projects/shared-components/shell-communication/dtos/triggers';

export class RemoteAlgoParametersControl extends ParametersControlBase<RemoteAlgoParametersViewComponent> {

   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);

      for (let i = 1; i < 51; i++) {
         this.multiplierItems.push(i);
      }

      for (let i = 60; i < 110; i += 10) {
         this.multiplierItems.push(i);
      }

      for (let i = 125; i < 225; i += 25) {
         this.multiplierItems.push(i);
      }

      for (let i = 250; i < 550; i += 50) {
         this.multiplierItems.push(i);
      }
   }

   private _symbol: TradingInstrument;
   private _multiplier: number;

   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;
   multiplierItems: number[] = [];
   algoId = EtsConstants.algorithms.remoteAlgoId;

   get symbol(): TradingInstrument {
      return this._symbol;
   }

   @DetectParameterChanges()
   set symbol(value: TradingInstrument) {
      if (this._symbol === value) {
         return;
      }
      const oldOne = this._symbol;
      this._symbol = value;
      const newOne = value;
      if (isTruthy(newOne)) {
         if (isTruthy(oldOne)) {
            if (newOne.underlying !== oldOne.underlying) {
               this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
            }
         } else {
            this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
         }
      }
   }

   get multiplier(): number {
      return this._multiplier;
   }

   @DetectParameterChanges()
   set multiplier(value: number) {
      if (this._multiplier === value) {
         return;
      }
      this._multiplier = value;
   }

   get longTriggers(): TriggerDto[] {
      return this.holder.availableTriggers
         .filter(t => (t.triggerPurpose & TriggerPurpose.Long) === TriggerPurpose.Long);
   }
   
   selectedLongTrigger: TriggerDto;

   get closeLongTriggers(): TriggerDto[] {
      return this.holder.availableTriggers
         .filter(t => (t.triggerPurpose & TriggerPurpose.CloseLong) === TriggerPurpose.CloseLong);
   }

   selectedCloseLongTrigger: TriggerDto;

   get shortTriggers(): TriggerDto[] {
      return this.holder.availableTriggers
         .filter(t => (t.triggerPurpose & TriggerPurpose.Short) === TriggerPurpose.Short);
   }

   selectedShortTrigger: TriggerDto;

   get closeShortTriggers(): TriggerDto[] {
      return this.holder.availableTriggers
         .filter(t => (t.triggerPurpose & TriggerPurpose.CloseShort) === TriggerPurpose.CloseShort);
   }

   selectedCloseShortTrigger: TriggerDto;

   getSymbolToSet(): TradingInstrument {
      return this.symbol;
   }

   _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker : null;
      params['multiplier'] = isTruthy(this.multiplier) ? this.multiplier + '' : null;
      params['longtrigger'] = isTruthy(this.selectedLongTrigger) ? this.selectedLongTrigger.triggerId + '' : null;
      params['shorttrigger'] = isTruthy(this.selectedShortTrigger) ? this.selectedShortTrigger.triggerId + '' : null;
      params['closelongtrigger'] = isTruthy(this.selectedCloseShortTrigger) ? this.selectedCloseLongTrigger.triggerId + '' : null;
      params['closeshorttrigger'] = isTruthy(this.selectedCloseShortTrigger) ? this.selectedCloseShortTrigger.triggerId + '' : null;

      return params;
   }

   _SetParameters(dto: StrategyDto) {
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }
      
      if (isTruthy(params['multiplier'])) {
         this.multiplier = parseInt(params['multiplier']);
      }

      if (isTruthy(params['longtrigger'])) {
         this.selectedLongTrigger = this.longTriggers.find(x => x.triggerId === params['longtrigger']);
      }

      if (isTruthy(params['shorttrigger'])) {
         this.selectedShortTrigger = this.shortTriggers.find(x => x.triggerId === params['shorttrigger']);
      }
      
      if (isTruthy(params['closelongtrigger'])) {
         this.selectedCloseLongTrigger = this.closeLongTriggers.find(x => x.triggerId === params['closelongtrigger']);
      }

      if (isTruthy(params['closeshorttrigger'])) {
         this.selectedCloseShortTrigger = this.closeShortTriggers.find(x => x.triggerId === params['closeshorttrigger']);
      }
   }

   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!isTruthy(this.symbol)) {
         errors.push('Symbol is required');
      }

      if (!isTruthy(this.multiplier) || this.multiplier === 0) {
         errors.push('Multiplier is required');
      }

      if (!isTruthy(this.tradingObjectives.accumulatedNpo) && !isTruthy(this.tradingObjectives.tradeNpo)) {
         errors.push('NPO is required');
      }

      return errors;
   }

   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean) {
   }

   onCustomNumberCreated(data, collection: number[]) {
      const newItem = parseFloat(data.text);
      if (!isNaN(newItem)) {
         const isNew = collection.indexOf(newItem) === -1;
         data.customItem = newItem;
         if (isNew) {
            collection.push(newItem);
         }
      }
   }

   private _updateTickSizeDependentDropdowns(tickSize: number, precision: number) {
  
      const formatter = new Intl.NumberFormat('en-IN', {
         minimumFractionDigits: precision,
         maximumFractionDigits: precision
      });

      for (let i = 1; i < 50; i++) {
         let step = i * tickSize;
         step = parseFloat(formatter.format(step));
      }
   }

   onStrategyTerminalChanged(): void {
   }

}
