import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { CashFlowTrackingRecordModel } from 'projects/shared-components/cashflow-tracking/CashFlowTrackingRecordModel';
import { CashFlowTrackingComponent } from 'projects/shared-components/cashflow-tracking/cashflow-tracking.component';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { IPanelComponent } from 'projects/shared-components/panels/panel-component.interface';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { ComboDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, getShortUUID, isNullOrUndefined, isValidNumber, isVoid } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'ets-automation-cp-cashflow-tracker-section',
   templateUrl: './cashflow-tracker-section.component.html',
   styleUrls: ['./cashflow-tracker-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashflowTrackerSectionComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();

   readonly scope: IPanelComponent = {
      layoutTabId: getShortUUID()
   };

   @ViewChild(CashFlowTrackingComponent, { static: false }) comp: CashFlowTrackingComponent;

   @Input()
   combo: ComboDto;

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   header = 'Cashflow Tracker';

   //
   private _sectionHeight;
   get sectionHeight(): number {

      if (!isValidNumber(this._sectionHeight)) {

         let h = 200;

         let count = 0;
         let detailHeight = 0;

         let hasExpanded = false;

         if (this.comp) {
            if (this.comp.grid) {

               this.comp.grid.forEachNode((node: RowNode) => {

                  if (node.master && node.expanded) {
                     const d: CashFlowTrackingRecordModel = node.data;
                     if (d) {
                        count += d.getInnerRecords().length + 4;
                        hasExpanded = true;
                     }
                  }

                  if (!isNullOrUndefined(node.rowTop)) {
                     count += 1;
                  }
               });
            }

            count -= 1;
            if (count < 0) {
               count = 0;
            }
            const extr = count * 26;

            h += extr;
            h += detailHeight;
         }

         if (h > 250) {
            h = 200;
         }

         this._sectionHeight = h;

      }

      return this._sectionHeight;
   }

   //
   ngOnInit(): void {
      this._cpMessageBus
         .when<any>('AdjustmentStrategyLoaded')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onStrategyChanged(msg));

      this._cpMessageBus
         .when<any>('AdjustmentStrategyCreated')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onStrategyChanged(msg));

      this._cpMessageBus
         .when<any>('AdjustmentStrategyRemoved')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onStrategyChanged(msg));
   }

   //
   onStrategyChanged(msg: { strategy: StrategyDto }) {

      this._messageBus.publishAsync({
         topic: 'BucketHighlighted',
         payload: {
            bucketContext: {
               bucketType: 'Combo',
               bucketId: msg.strategy.comboId
            }
         },
         scopeId: this.scope.layoutTabId
      })
   }

   //
   onStrategyRemoved() {

      this._messageBus.publishAsync({
         topic: 'BucketHighlighted',
         payload: {
            bucketContext: {
               bucketType: 'Combo',
               bucketId: null
            }
         },
         scopeId: this.scope.layoutTabId
      })
   }

   //
   @DetectMethodChanges()
   onSectionCollapsedChanged(ev) {
      if (ev) {
         this._sectionHeight = 0;
      } else {
         this._sectionHeight = null;
      }
   }

   //
   recalculateHeight() {
      this._sectionHeight = null;
      this._changeDetector.detectChanges();
   }
}
