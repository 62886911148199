import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { SettingsStorageService } from '../settings-storage-service.service';
import { DetectSetterChanges } from '../utils';
import { ByBrokerModel } from './by-broker-model';
import {UserSettingsService} from "../user-settings.service";

interface Tab {
   text: string;
   id: string;
}

@Component({
   selector: 'ets-margin-requirements',
   templateUrl: 'margin-requirements.component.html',
   styleUrls: ['margin-requirements.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarginRequirementsComponent extends PanelBaseComponent implements OnInit {

   constructor(
      _changeDetector: ChangeDetectorRef,
      _layoutService: UserSettingsService,
      _messageBus: MessageBusService) {
      
         super(_changeDetector, _layoutService, _messageBus);
         this.byBrokerModel = new ByBrokerModel();
   }

   //

   tabs: Tab[] = [
      {text: 'By Broker', id: 'broker'},
      {text: 'By Asset',  id: 'asset' }
   ];

   //

   private _selectedTab: Tab;
   get selectedTab(): Tab {
      return this._selectedTab;
   }
   @DetectSetterChanges()
   set selectedTab(v: Tab) {
      this._selectedTab = v;
   }

   //

   byBrokerModel: ByBrokerModel;

   //

   etsOnInit(): void {
      this._selectedTab = this.tabs[0];
   }
   
   //

   etsOnDestroy(): void {
   }
   
   //

   etsAfterViewInit(): void {
   }
   
   //

   protected getState() {
      return {};
   }
   
   //

   protected setState(state: any) {
      //
   }
}
