// tslint:disable: ban-types

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-confirmation-dialog',
   templateUrl: './confirmation-dialog.component.html',
   styleUrls: ['./confirmation-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmationDialogComponent implements OnInit, AfterViewInit {
   
   constructor(private _changeDetector: ChangeDetectorRef) {
   }

   private _resolve: Function;
   private _reject: Function;

   visible: boolean;
   message: string;
   aggObj: any;
   title: string;


   @DetectMethodChanges()
   show(aggObj): Promise<any> {

      this.aggObj = aggObj;

      this.visible = true;

      this.title = aggObj.deleteArchived
         ? 'All Matching Archived Positions Will Be Removed From Calculations'
         : 'All Matching Positions Will Be Closed';

      return new Promise<any>((res, rej) => {
         this._resolve = res;
         this._reject = rej;
      });
   }


   
   @DetectMethodChanges()
   hide() {
      this.visible = false;
      this._reject = null;
      this._resolve = null;
   }

   
   
   @DetectMethodChanges()
   confirm() {
      if (this._resolve) {
         this._resolve();
      }
   }

   
   
   @DetectMethodChanges()
   cancel() {
      if (this._reject) {
         this._reject();
      }
   }

   
   
   ngOnInit() {
   }

   ngAfterViewInit() { this._changeDetector.detach(); }
}
