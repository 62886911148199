import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { PanelModel } from '../../panel.model';
import { interval, Subscription, Subject } from 'rxjs';
import * as shortid from 'shortid';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

interface TabWidthData {
   tab: PanelModel;
   width: number;
}

const SCROLL_NUMBER = 100;
const SCROLL_STEP = 3;

@Component({
   selector: 'ets-tabs-panel',
   templateUrl: './tabs-panel.component.html',
   styleUrls: ['./tabs-panel.component.scss']
})
export class EtsTabsPanelComponent implements OnInit, AfterViewInit, OnDestroy {
   constructor(private _messageBus: MessageBusService) {
      this._lastPanelSize = null;
      this._tabSizes = [];
      this.tabs = [];
      this.tabClicked = new EventEmitter<PanelModel>();
      this.tabClosed = new EventEmitter<PanelModel>();
      this.tabAdded = new EventEmitter<PanelModel>();
      this.tabHeaderChanged = new EventEmitter<PanelModel>();
      this.symbolLinkChanged = new EventEmitter<any>();
   }

   private _tabSizes: TabWidthData[];

   @ViewChild('panel', { static: true })
   private _panelElment: ElementRef;

   private _lastPanelSize: number;
   private _selfRef: HTMLElement;
   private _unsubscriber: Subject<any>;


   @Input() tabs: PanelModel[];
   @Output() tabClicked;
   @Output() tabClosed;
   @Output() symbolLinkChanged: EventEmitter<any>;
   @Output() tabAdded;
   @Output() tabHeaderChanged;

   wspId: string;
   leftPaddleVisible: boolean;
   rightPaddleVisible: boolean;

   ngOnInit() {
      this.wspId = shortid.generate();

      this._unsubscriber = new Subject();

      this._messageBus.of<any>('PanelSelfCloseRequest')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => {
            if (this._tabSizes.length > 0) {
               const tab = this._tabSizes.find(t => t.tab.panelId === x.payload.tabId);
               if (tab) {
                  this.onTabCloseRequest(tab.tab);
               }
            }
         });
   }

   ngOnDestroy() {
      this._unsubscriber.next();
      this._unsubscriber.complete();
   }

   ngAfterViewInit() {
      this._selfRef = document.getElementById(`wsp_${this.wspId}`);
      console.assert(!!this._selfRef);
   }

   onResized(): void {
      const width = this._selfRef.offsetWidth;
      this._lastPanelSize = width;
      this.togglePaddlesOnWidthChange();
   }

   onTabAdded($event: { tab: PanelModel; newWidth: number }) {
      if (!$event.newWidth) {
         return;
      }
      const tabWidth = $event.newWidth + 20; // 20 is a lost margin
      this._tabSizes.push({ tab: $event.tab, width: tabWidth });
      this.scrollToSelectedTab($event.tab.panelId);
      this.togglePaddlesOnWidthChange();
   }

   scrollToSelectedTab(panelId: string): void {
      let panelElement = this._panelElment.nativeElement as HTMLElement;
      let subs: Subscription;

      const lastTab = this.tabs[this.tabs.length - 1];
      if (lastTab.panelId === panelId) {
         subs = interval().subscribe(x => {
            const condition = this.isScrollAtTheEnd();
            if (condition) {
               subs.unsubscribe();
               return;
            }
            panelElement.scrollLeft += SCROLL_STEP;
         });

         return;
      }

      const offsetWidth = panelElement.offsetWidth;
      const scrolled = panelElement.scrollLeft;
      const currentPosition = offsetWidth + scrolled;
      // console.log(`scrollWidth = ${scrollWidth}, offsetWidth = ${offsetWidth}, scrolled=${scrolled}`);
      let tabEnding = 0;
      const ts = this._tabSizes;
      let i: number;
      for (i = 0; i < ts.length; i++) {
         const tabWidthData = ts[i];
         tabEnding += tabWidthData.width;
         if (tabWidthData.tab.panelId === panelId) {
            break;
         }
      }
      // console.log('Desired tab ends at = ' + tabEnding);
      if (tabEnding < offsetWidth) {
         if (scrolled === 0) {
            // console.log('no need to scroll');
         } else {
            panelElement = this._panelElment.nativeElement as HTMLElement;
            subs = interval().subscribe(x => {
               const condition = this.isScrollAtTheBeginning();
               if (condition) {
                  subs.unsubscribe();
                  return;
               }
               panelElement.scrollLeft -= SCROLL_STEP;
            });
         }

         return;
      }

      const tabOverflow = tabEnding - offsetWidth;
      let toScroll = scrolled - tabOverflow;
      const scrollDirection = tabEnding > currentPosition ? 1 : -1;
      if (scrollDirection === 1) {
         toScroll *= -1;
         toScroll += offsetWidth / 2;
      } else {
         toScroll -= offsetWidth / 2;
      }

      // console.log(`toScroll = ${toScroll}, direction = ${scrollDirection}`);
      subs = interval().subscribe(x => {
         const condition =
            (scrollDirection === 1 && this.isScrollAtTheEnd()) ||
            (scrollDirection === -1 && this.isScrollAtTheBeginning());
         if (x >= toScroll / SCROLL_STEP || condition) {
            subs.unsubscribe();
            // console.log('finished');
            return;
         }
         panelElement.scrollLeft += SCROLL_STEP * scrollDirection;
      });
   }

   onTabClicked(tab: PanelModel): void {
      this.tabClicked.emit(tab);
   }

   onTabHeaderChanged(tab: PanelModel): void {
      this.tabHeaderChanged.emit(tab);
   }

   onTabCloseRequest(tab: PanelModel): void {
      this.tabClosed.emit(tab);
      // update tab sizes
      const ts = this._tabSizes;
      const ix = ts.findIndex(x => x.tab === tab);
      if (ix >= 0) {
         ts.splice(ix, 1);
      }
      this.togglePaddlesOnWidthChange();
   }

   onSymbolLinkChanged(ev, tab: PanelModel) {
      this.symbolLinkChanged.emit({isLinked: ev, panel: tab});
   }

   onLeftPaddleClick(): void {
      const panelElement = this._panelElment.nativeElement as HTMLElement;
      const subs = interval().subscribe(x => {
         const condition = this.isScrollAtTheBeginning();
         if (x >= SCROLL_NUMBER || condition) {
            subs.unsubscribe();
            return;
         }
         panelElement.scrollLeft -= SCROLL_STEP;
      });
   }

   onRightPaddleClick(): void {
      const panelElement = this._panelElment.nativeElement as HTMLElement;
      const subs = interval().subscribe(x => {
         const condition = this.isScrollAtTheEnd();
         if (x >= SCROLL_NUMBER || condition) {
            subs.unsubscribe();
            return;
         }
         panelElement.scrollLeft += SCROLL_STEP;
      });
   }

   onPanelScroll($event): void {
      this.togglePaddlesOnWidthChange();
   }


   private getTotalTabsWidth(): number {
      let totalWidth = 0;
      const ts = this._tabSizes;
      ts.forEach((x: TabWidthData) => {
         totalWidth += x.width;
      });
      return totalWidth;
   }

   private isScrollAtTheEnd(): boolean {
      const panelElement = this._panelElment.nativeElement as HTMLElement;
      const condition =
         panelElement.offsetWidth + panelElement.scrollLeft >= panelElement.scrollWidth;
      return condition;
   }

   private isScrollAtTheBeginning(): boolean {
      const panelElement = this._panelElment.nativeElement as HTMLElement;
      const result = panelElement.scrollLeft === 0;
      return result;
   }

   private togglePaddlesOnWidthChange(): void {
      if (!this._lastPanelSize) {
         return;
      }
      // 1) is scroll required at all?
      const totalTabsWidth = this.getTotalTabsWidth();
      if (totalTabsWidth <= this._lastPanelSize) {
         this.leftPaddleVisible = this.rightPaddleVisible = false;
         return;
      }
      const panel = this._panelElment.nativeElement as HTMLElement;
      // 2) should we show left paddle?
      this.leftPaddleVisible = panel.scrollLeft > 0;
      // 3) should we show left paddle?
      const scrollDelta = panel.scrollWidth - panel.offsetWidth - 10; // 20 is a margin
      const scrolled = panel.scrollLeft;
      this.rightPaddleVisible = scrolled < scrollDelta;
   }
}
