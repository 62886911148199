import {AlgoDescriptionBuilderBase} from '../algo-description-builder-base.class';
import {StrategyParameters} from '../../strategies/strategy-parameters.enum';

export class FusionAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {

  getSymbolPropertyNames(): string[] {
    throw new Error('Operation not applicable');
  }

  getAlgoName(parameters: StrategyParameters): string {
    return 'Fusion';
  }

  getDescription(parameters: StrategyParameters): string {
    return '"Fusion" Disposition Strategy';
  }
}
