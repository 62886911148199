import { Injectable } from '@angular/core';
import { CqrsService } from '../service-model/cqrs-service.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ProtocolQuery } from '../service-model/protocol-query.interface';
import { WebCommand } from '../service-model/web-command.interface';
import { ProtocolCommand } from '../service-model/protocol-command.interface';

@Injectable({ providedIn: 'root' })
export class TelemetryBackendClientService implements CqrsService {
  constructor(
    private readonly httpClient: HttpClient
  ) {
    this.commandUrl = `${environment.telemetryServiceUrl}/processCommand`;
  }

  private readonly commandUrl: string;

  public processQuery<T>(query: ProtocolQuery): Promise<T> {
    throw new Error('Not implemented');
  }

  public processCommand(command: ProtocolCommand): Promise<any> {
    const requestData: string = JSON.stringify(command);
    const operation: string = command.getOperationName();
    const webCommand: WebCommand = { type: operation, data: requestData };
    return this.httpClient.post(this.commandUrl, webCommand).toPromise();
  }
}
