import { Component, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { DispositionReportEntryModel } from './disposition-report-entry-model';
import { getRegularDispositionReportGridModel } from './disposition-report-games-regular-grid-model';
import { getFlatColumnDefs, getFlatDispositionReportGridModel } from './disposition-report-games-flat-grid-model';
import { StrategyGameRecordDto } from 'projects/shared-components/shell-communication/dtos/strategy-game-record-dto.class';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ToastrService } from 'ngx-toastr';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategiesService } from '../../../../../shared-components/strategies/strategies.service';
import { SystemDetailsComponent } from '../../../../../shared-components/system-details/system-details.component';
import {
   GetStrategyGameRecordsWeb
} from 'projects/shared-components/shell-communication/operations/game-records/get-strategy-game-records-web.class';
import { filter, takeUntil } from 'rxjs/operators';
import { MarkLastGameRecordMessage } from 'projects/shared-components/shell-communication/dtos/mark-last-game-record-message.class';
import { isNullOrUndefined } from 'util';
import { WinLoss } from '../win-loss';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { DATA_LOAD_MODE, DateRange } from '../common-reports.mode';
import { correctedDateAsUTC } from 'projects/shared-components/utils';
import { GetStrategyGameRecordsByDateRange, GetDeletedStrategiesFlagshipReports } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { plainToClass } from 'class-transformer';
import { AlgoMetadataService } from 'projects/shared-components/algo/algo-metadata.service';
import { TimestampsService } from 'projects/shared-components/timestamps.service';

interface TickerModel {
   ticker: string;
   displayName: string;
}

interface PendingDataContainer {
   isLoading: boolean;
   data: StrategyGameRecordDto[];
}

@Component({
   selector: 'ets-disposition-report',
   templateUrl: 'disposition-report.component.html',
   styleUrls: ['disposition-report.component.scss']
})
export class DispositionReportComponent implements OnInit, OnDestroy {
   constructor(
      private _displayNameService: TradingInstrumentDisplayNameService,
      private _tradingInstruments: TradingInstrumentsService,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _messageBus: MessageBusService,
      private _strategiesService: StrategiesService,
      private readonly _algoMetadataService: AlgoMetadataService,
      private readonly _timestampsService: TimestampsService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('DispositionReportComponent');
   }

   private readonly _logger: Logger;
   private readonly _pendingDataContainer: PendingDataContainer = {
      isLoading: false,
      data: []
   };
   private readonly _subscribedStrategies: string[] = [];
   private readonly _innerGamesCache: { [gameId: string]: StrategyGameRecordDto[] } = {};
   private _flatView = false;
   private _regularGrid: GridReadyEvent;
   private _selectedStrategy: StrategyModel;
   private _selectedTicker: TickerModel;
   private _unsubscriber: Subject<any>;
   private _reportEntries: DispositionReportEntryModel[] = [];
   private _ticksOrPoints = false;
   private _flatGrid: GridReadyEvent;
   private _isDeletedMode = false;


   @ViewChild(SystemDetailsComponent)
   systemDetails: SystemDetailsComponent;
   title = 'Disposition Report';
   isVisible = false;
   regularGamesGridModel: GridOptions;
   flatGamesGridModel: GridOptions;
   availableStrategies: StrategyModel[];
   isLoading: boolean;
   availableTickersOfStrategy: TickerModel[];
   percentWins: number;
   netSlippage: number;
   readonly dataLoadModes = [
      {
         text: 'Date Range',
         value: 'Date',
      },
      {
         text: 'Active Session',
         value: 'Session',
      },
   ];
   selectedDataLoadMode: { text: string; value: DATA_LOAD_MODE } = null;
   dateRange: DateRange = {};
   isDatePickerVisible = false;
   selectedTicker: TickerModel;

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   get isDeletedMode() {
      return this._isDeletedMode;
   }
   set isDeletedMode(value: boolean) {
      if (this._isDeletedMode === value) {
         return;
      }
      this._isDeletedMode = value;
      setTimeout(() => this._onModeChange(), 0);
   }

   get ticksOrPoints(): boolean {
      return this._ticksOrPoints;
   }

   set ticksOrPoints(value: boolean) {
      if (this._ticksOrPoints === value) {
         return;
      }

      this._ticksOrPoints = value;

      const tickSize = this._getTickSize();
      if (this._reportEntries.length > 0) {
         if (value) {
            this._reportEntries.forEach(x => x.changeTickPointView(tickSize));
         } else {
            this._reportEntries.forEach(x => x.changeTickPointView(null));
         }
      }

      const grid = this._getCurrentGrid();
      if (grid) {
         grid.api.refreshCells();
      } else {
         this._toastr.error('Grid Not Initialized');
      }
   }

   get selectedStrategy(): StrategyModel {
      return this._selectedStrategy;
   }

   set selectedStrategy(strategy: StrategyModel) {
      if (this._selectedStrategy === strategy) {
         return;
      }
      this._selectedStrategy = strategy;
      this._clearGames();
      if (!strategy) {
         return;
      }

      const parameters = strategy.dispositionStrategies.map(x => x.parameters);
      if (!parameters || parameters.length === 0) {
         return;
      }

      setTimeout(() => {
         const filteredTickers = [];
         parameters.forEach(p => {
            const usedTickers = Object.keys(p).filter(x => x.endsWith('symbol'))
               .map(x => p[x]);

            usedTickers.forEach(s => {
               if (filteredTickers.includes(s)) {
                  return;
               }
               filteredTickers.push(s);
            });
         });
         const tickers: TickerModel[] = filteredTickers.map(t => ({
            ticker: t,
            displayName: this._displayNameService.getDisplayNameForTicker(t)
         }));
         this.availableTickersOfStrategy = tickers;

         if (!!this.systemDetails) {
            this.systemDetails.system = strategy;
            this.systemDetails.setupCommonGrid();
            this.systemDetails.setupSpecificGrid();
         }
      }, 0);
   }

   get flatView(): boolean {
      return this._flatView;
   }

   set flatView(value: boolean) {
      if (value === this._flatView) {
         return;
      }
      this._flatView = value;

      if (this.selectedStrategy && this.selectedTicker) {
         if (value) {
            const flatColumnDefs = getFlatColumnDefs(this.selectedStrategy);
            this._flatGrid.api.setRowData([]);
            this._flatGrid.api.setColumnDefs(flatColumnDefs);
            this._flatGrid.api.setRowData(this._reportEntries);
         } else {
            this._regularGrid.api.setRowData([]);
            this._regularGrid.api.setRowData(this._reportEntries);
            this._regularGrid.api.sizeColumnsToFit();
         }
      }
   }

   ngOnInit() {
      this.regularGamesGridModel = getRegularDispositionReportGridModel.bind(this)();
      this.flatGamesGridModel = getFlatDispositionReportGridModel.bind(this)();
   }

   onGamesRegularGridReady(args: GridReadyEvent) {
      this._regularGrid = args;
      this._regularGrid.api.sizeColumnsToFit();
   }

   ngOnDestroy() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   onHidden() {
      this.isVisible = false;

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._resetState(true);
   }

   onShown() {
      this.availableStrategies = this._strategiesService
         .getAllStrategies()
         .filter(str => !!str.dispositionStrategies && str.dispositionStrategies.length > 0);

      this._subscribeToMessages();
   }

   onGamesFlatGridReady(args: GridReadyEvent) {
      this._flatGrid = args;
   }

   async loadEarlierGames(batchSize: number): Promise<any> {
      const grid = this._getCurrentGrid();

      if (!grid) {
         this._toastr.error('Grid not initialized');
         return;
      }

      grid.api.showLoadingOverlay();

      try {
         const historyStop = this._reportEntries.length
            ? this._reportEntries[0].seqNum
            : 0;
         const qry = new GetStrategyGameRecordsWeb(
            this._selectedStrategy.strategyId,
            this._selectedTicker.ticker,
            historyStop,
            batchSize
         );
         const loadedMessages = await this._shellClient.processQuery<StrategyGameRecordDto[]>(qry);
         if (loadedMessages.length === 0) {
            this._toastr.info('All messages loaded');
            return;
         }
         this._processStrategyGameRecords(loadedMessages, true);
      } catch (error) {
         const errorMessage = 'Failed to load strategy messages';
         this._toastr.error(errorMessage);
      } finally {
         grid.api.hideOverlay();
      }
   }

   private _getCurrentGrid() {
      return this.flatView ? this._flatGrid : this._regularGrid;
   }

   private _loadStrategyGameRecords(value: TickerModel) {
      this.isLoading = true;
      this._pendingDataContainer.data.length = 0;
      this._pendingDataContainer.isLoading = true;

      this._subscribedStrategies.length = 0;
      this._subscribedStrategies.push(
         this.selectedStrategy.strategyId
      );
      this._subscribedStrategies.push(
         ...this.selectedStrategy.dispositionStrategies.map(x => x.strategyId)
      );

      const strategyId = this.selectedStrategy.strategyId;
      const ticker = value.ticker;
      const qry = new GetStrategyGameRecordsWeb(
         strategyId,
         ticker,
         0,
         100
      );

      this._shellClient.processQuery<StrategyGameRecordDto[]>(qry)
         .then((data) => {
            this._pendingDataContainer.isLoading = false;
            this._onStrategyGameRecord(data);

         })
         .catch((err) => {
            const message = '"Load Strategy Games" operation completed with errors';
            this._toastr.error(message);
            const error = { error: err.stack || err, message: err.message || message };
            this._logger.error(message, error);
         })
         .then(() => {
            this.isLoading = false;
            this._pendingDataContainer.isLoading = false;
            this._pendingDataContainer.data.length = 0;
         });
   }

   private _subscribeToMessages() {
      this._unsubscriber = new Subject<any>();
      this._messageBus.of<any>('StrategyGameRecordDto')
         .pipe(
            filter(x => {
               return !!this.selectedStrategy && !!this.selectedTicker;
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onStrategyGameRecord(message.payload));

      this._messageBus.of<MarkLastGameRecordMessage>('MarkLastGameRecordMessage')
         .pipe(
            filter(x => {
               return !!this.selectedStrategy && !!this.selectedTicker;
            }),
            filter(x => {
               return x.payload.strategyId === this.selectedStrategy.strategyId;
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe((message) => {
            setTimeout(() => {
               this._onMarkLastGameRecordMessage(message.payload);
            }, 750);
         });
   }

   private _onStrategyGameRecord(dtos: StrategyGameRecordDto[]) {
      if (dtos.length === 0) {
         return;
      }

      if (this.selectedDataLoadMode.value === 'Session'
         && this._subscribedStrategies.length === 0) {
         return;
      }

      this._processStrategyGameRecords(dtos);
   }

   private _processStrategyGameRecords(dtos: StrategyGameRecordDto[], unshift?: boolean) {
      if (this._pendingDataContainer.isLoading) {
         this._pendingDataContainer.data.push(...dtos);
         return;
      }

      if (this._pendingDataContainer.data.length > 0) {
         if (dtos.length > 0) {
            const lastDtoNum = dtos[dtos.length - 1].strategyGameRecordId;
            const filteredPendingItems = this._pendingDataContainer.data.filter(x => x.strategyGameRecordId > lastDtoNum);
            dtos.push(...filteredPendingItems);
         } else {
            dtos.push(...this._pendingDataContainer.data);
         }

         this._pendingDataContainer.data.length = 0;
      }

      const filtered = dtos.filter(x => this._subscribedStrategies.includes(x.strategyId)
         && x.ticker === this.selectedTicker.ticker);

      this._handleFilteredGameRecords(filtered, unshift);

      const grid = this._getCurrentGrid();
      if (grid) {
         grid.api.setRowData(this._reportEntries);
      } else {
         this._toastr.error('Grid Not Initialized');
      }

      this._updateStatistics();
   }

   private _handleFilteredGameRecords(dtos: StrategyGameRecordDto[], unshift?: boolean) {
      const models = dtos.map(dto => {
         if (dto.strategyId !== this.selectedStrategy.strategyId) {
            if (!(dto.gameId in this._innerGamesCache)) {
               this._innerGamesCache[dto.gameId] = [];
            }
            this._innerGamesCache[dto.gameId].push(dto);
            return;
         }

         if (!dto.innerRecords || dto.innerRecords.length === 0) {
            if (dto.gameId in this._innerGamesCache) {
               const innerGames = this._innerGamesCache[dto.gameId];
               if (innerGames) {
                  dto.innerRecords = innerGames;
               }
               delete this._innerGamesCache[dto.gameId];
            }
         }

         const model = new DispositionReportEntryModel(dto);
         if (this.ticksOrPoints) {
            model.changeTickPointView(this._getTickSize());
         }
         return model;
      }).filter(model => !!model);

      if (!unshift) {
         this._reportEntries.push(...models);
      } else {
         this._reportEntries.unshift(...models);
      }
   }

   private _getTickSize(): number {
      if (!this.selectedTicker) {
         return null;
      }
      const instrumentByTicker = this._tradingInstruments.getInstrumentByTicker(this.selectedTicker.ticker);
      if (!instrumentByTicker) {
         return null;
      }
      return instrumentByTicker.tickSize;
   }

   private _updateStatistics() {
      const entries = this._reportEntries.slice();
      if (entries.length === 0) {
         return;
      }

      const validGames = entries.filter(x => !isNullOrUndefined(x.actualBet));
      const wins = validGames.filter(x => x.winLoss === WinLoss.Win).length;

      this.percentWins = wins / validGames.length;
      this.netSlippage = entries.map(x => x.slippage).reduce((acc, curr) => acc + curr, 0);
   }

   private _onMarkLastGameRecordMessage(dto: MarkLastGameRecordMessage) {
      const game = this._reportEntries.find(x => x.gameId === dto.gameId);
      if (game) {
         game.isLast = true;
         this._regularGrid.api.applyTransaction({ update: [game] });
      }
   }

   private _onModeChange() {
      if (!this.isVisible) {
         return;
      }

      setTimeout(() => {
         this.isLoading = true;
         this._resetState(false);
         if (this.isDeletedMode) {
            setTimeout(() => (this.isDatePickerVisible = true), 0);
         } else {
            this._setAvailableStrategiesToActiveStrategies();
         }
         this.isLoading = false;
      }, 0);
   }

   private _setAvailableStrategiesToActiveStrategies() {
      this.availableStrategies = this._strategiesService
         .getAllStrategies()
         .filter(
            (str) =>
               !str.dispositionStrategies || str.dispositionStrategies.length === 0
         );
   }

   private _resetState(isHardReset: boolean) {
      this._clearGames();

      this.selectedTicker = this.selectedStrategy = null;

      this.availableStrategies = [];
      this.availableTickersOfStrategy = [];

      this.systemDetails.system = null;
      this.systemDetails.setupCommonGrid();
      this.systemDetails.setupSpecificGrid();

      if (isHardReset) {
         this.isDeletedMode = false;
         this._setTitle();
         this.selectedDataLoadMode = null;
         this.dateRange = {};
      }
   }

   private _clearGames() {
      this._reportEntries.length = 0;
      if (this._flatGrid) {
         this._flatGrid.api.setRowData([]);
      }
      if (this._regularGrid) {
         this._regularGrid.api.setRowData([]);
      }
   }

   private _setTitle() {
      if (!this.isDeletedMode) {
         this.title = 'Disposition Report';
      } else {
         this.title = 'Disposition Report (Deleted Strategies)';
      }
   }

   onLoadDataButtonClick() {
      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      if (!this.selectedDataLoadMode) {
         this._toastr.error('Data Load Mode Not Selected');
         return;
      }

      if (this.selectedDataLoadMode.value === 'Date') {
         this.isDatePickerVisible = true;
      } else if (this.selectedDataLoadMode.value === 'Session') {
         this._loadStrategyGameRecordsForActiveSession();
      } else {
         this._toastr.error('Unknown Data Load Mode');
      }
   }

   private _loadStrategyGameRecordsForActiveSession(): void {
      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }
      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      this._clearGames();

      this._subscribedStrategies.length = 0;
      this._subscribedStrategies.push(this.selectedStrategy.strategyId);
      this._subscribedStrategies.push(
         ...this.selectedStrategy.dispositionStrategies.map(x => x.strategyId)
      );


      this.isLoading = true;

      this._pendingDataContainer.data.length = 0;
      this._pendingDataContainer.isLoading = true;

      const strategyId = this.selectedStrategy.strategyId;
      const ticker = this.selectedTicker.ticker;

      const qry = new GetStrategyGameRecordsWeb(
         strategyId,
         ticker,
         0,
         100,
         this.isDeletedMode
      );

      this._shellClient
         .processQuery<StrategyGameRecordDto[]>(qry)
         .then((data) => {
            this._pendingDataContainer.isLoading = false;
            this._onStrategyGameRecord(data);
         })
         .catch((err) => {
            const message = '"Load Strategy Games" operation completed with errors';
            this._toastr.error(message);
            const error = {
               error: err.stack || err,
               message: err.message || message,
            };
            this._logger.error(message, error);
         })
         .then(() => {
            this.isLoading = false;
            this._pendingDataContainer.isLoading = false;
            this._pendingDataContainer.data.length = 0;
         });
   }

   canLoadData() {
      return (
         this.selectedStrategy && this.selectedTicker && this.selectedDataLoadMode
      );
   }

   async applyDateFilter(startDate?: Date, endDate?: Date) {
      if (isNullOrUndefined(startDate)) {
         this._toastr.error('Incorrect date range');
         return;
      }

      if (isNullOrUndefined(endDate)) {
         this._toastr.error('Incorrect date range');
         return;
      }

      this._clearGames();

      startDate = correctedDateAsUTC(startDate);
      endDate = correctedDateAsUTC(endDate);

      if (!this.isDeletedMode) {
         this._loadStrategyGameRecordsByDateRange(startDate, endDate);
      } else {
         if (this.availableStrategies.length === 0) {
            await this._tryLoadDeletedStrategiesByDateRange(startDate, endDate);
         } else {
            this._loadStrategyGameRecordsByDateRange(startDate, endDate);
         }
      }

      this.isDatePickerVisible = false;
   }

   private async _loadStrategyGameRecordsByDateRange(
      startDate: Date,
      endDate: Date
   ) {
      if (!startDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!endDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }

      const grid = this._getCurrentGrid();

      if (!grid) {
         this._toastr.error('Grid Not Initialized');
         return;
      }

      this._clearGames();

      grid.api.showLoadingOverlay();

      const qry = new GetStrategyGameRecordsByDateRange(
         this.selectedStrategy.strategyId,
         this.selectedTicker.ticker,
         startDate,
         endDate,
         this.isDeletedMode
      );

      try {
         const dtos = await this._shellClient.processQuery<
            StrategyGameRecordDto[]
         >(qry);
         this._onStrategyGameRecord(dtos);
      } catch (err) {
         const message = '"Load Strategy Games" operation completed with errors';
         this._toastr.error(message);
         const error = {
            error: err.stack || err,
            message: err.message || message,
         };
         this._logger.error(message, error);
      } finally {
         grid.api.hideOverlay();
      }
   }

   private async _tryLoadDeletedStrategiesByDateRange(
      startDate: Date,
      endDate: Date
   ) {
      if (!startDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!endDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      const grid = this._getCurrentGrid();

      if (!grid) {
         this._toastr.error('Grid Not Initialized');
         return;
      }

      this._clearGames();

      grid.api.showLoadingOverlay();

      try {
         const qry = new GetDeletedStrategiesFlagshipReports(
            startDate,
            endDate,
            true
         );
         const list = await this._shellClient.processQuery<StrategyDto[]>(qry);
         const modelsList: StrategyModel[] = list.map((x) => {
            const model = plainToClass(StrategyModel, x);
            model.updateMetadata(this._algoMetadataService);
            return model;
         });

         this.selectedStrategy = this.selectedTicker = null;
         this.availableStrategies.length = 0;
         this.availableStrategies.push(...modelsList);
      } catch (e) {
         this._toastr.error('"Strategies List" was loaded with errors');
         const data = { error: e.stack || e, dateRange: { startDate, endDate } };
         this._logger.error('_tryLoadDeletedStrategiesByDateRange()', data);
      } finally {
         grid.api.hideOverlay();
      }

      this._setTitle();
   }
}
