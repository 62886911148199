import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({name: 'allowStyles'})
export class AllowStylesPipe implements PipeTransform {
   
   constructor(private sanitizer:DomSanitizer) {}

   transform(value: any): any {
      return this.sanitizer.bypassSecurityTrustHtml(value);   
   }
}