import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LongOptionParameters } from './long-option-parameters';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { DetectSetterChanges } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-long-option-strategy',
   templateUrl: './long-option-strategy.component.html',
   styleUrls: ['../strategy-parameters.scss', './long-option-strategy.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class LongOptionStrategyComponent implements OnInit, AfterViewInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }

   ngAfterViewInit(): void {
      this.parameters.setDetector(this._changeDetector);
   }

   @Input() parameters: LongOptionParameters;

   @Input() cpMode: string;

   private _strategy: CashFlowStrategy;
   get strategy(): CashFlowStrategy {
      return this._strategy;
   }

   @Input()
   @DetectSetterChanges()
   set strategy(value: CashFlowStrategy) {
      this._strategy = value;
      this.parameters.strategy = value;
   }

   ngOnInit() { }

   getDateTimePickerMode() {
      if (!this.parameters) {
         return null;
      }

      if (this.parameters.convertToMarketSettings.actionTimeMode === 'Convert At') {
         return 'time';
      }

      if (this.parameters.convertToMarketSettings.actionTimeMode === 'Convert After') {
         return 'timespan';
      }

      return null;
   }
}
