import { TerminalTotalPositionDto } from '../../../../shared-components/shell-communication/dtos/terminal-total-position-dto.class';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MessageBusService } from '../../../../shared-components/message-bus.service';
import { ShellClientService } from '../../../../shared-components/shell-communication/shell-client.service';
import { takeUntil } from 'rxjs/operators';
import {
   GetTerminalTotalPosition
} from '../../../../shared-components/shell-communication/operations/aggregated-positions/get-terminal-total-position.class';
import {
   ShellConnectionStatusChangedUIMessage
} from '../../../../shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { ClearTradingDataUIMessage } from '../../../../shared-components/ui-messages/clear-trading-data-ui-message.class';
import {
   ResetAggregatedPositionsUIMessage
} from '../../../../shared-components/ui-messages/reset-aggregated-positions-ui-message.interface';
import { TerminalExposureChangedUIMessage } from '../../../../shared-components/ui-messages/terminal-exposure-changed-ui-message.interface';
import { SessionService } from '../../../../shared-components/authentication/session-service.service';

@Injectable({
   providedIn: 'root'
})
export class TerminalTotalDataService {
   constructor(
      private messageBus: MessageBusService,
      private shellClient: ShellClientService,
      private sessionService: SessionService,
   ) {
      this._unsubscriber = new Subject<any>();
   }

   //

   private _unsubscriber: Subject<any>;
   private _terminalTotals: Record<string, TerminalTotalPositionDto> = {};

   //

   async init(): Promise<any> {
      this.subscribeMessages();
      console.debug('[TerminalTotalDataService] Initialized');
      await this.getTerminalTotal();
   }

   getTotalByTerminal(terminalId: string): TerminalTotalPositionDto {
      return this._terminalTotals[terminalId];
   }

   //

   private subscribeMessages() {
      if (this._unsubscriber) {
         if (!this._unsubscriber.closed) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
         }
      }
      this._unsubscriber = new Subject<any>();

      this.messageBus
         .of<TerminalTotalPositionDto[]>('TerminalTotalPositionDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data =>
            setTimeout(() => this.onTerminalTotalPositionMessage(data.payload), 0)
         );

      this.messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data =>
            setTimeout(() => this.onShellConnectionStatusChanged(data.payload), 0)
         );

      this.messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data =>
            setTimeout(() => this.onClearTradingDataUIMessage(data.payload), 0)
         );

      this.messageBus
         .of<ResetAggregatedPositionsUIMessage>('ResetAggregatedPositionsUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data =>
            setTimeout(() => this.onResetAggregatedPositionsMessage(data.payload), 0)
         );
   }

   //

   private async onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<void> {
      if (!message.isConnected) {
         return;
      }

      console.debug('Received "ShellConnectionStatusChangedUIMessage"');
      
      try {
         
         await this.init();
         
         this.onTerminalTotalPositionMessage(Object.values(this._terminalTotals));

      } catch (error) {
         console.error(error);
      }
   }

   //

   private async onClearTradingDataUIMessage(message: ClearTradingDataUIMessage): Promise<any> {
      if (message.hasErrors) {
         return;
      }

      console.debug('Received "ClearTradingDataUIMessage"');
      
      try {
         await this.init();
         this.onTerminalTotalPositionMessage(Object.values(this._terminalTotals));
      } catch (error) {
         console.error(error);
      }
   }

   //

   private async onResetAggregatedPositionsMessage(message: ResetAggregatedPositionsUIMessage): Promise<void> {
      console.debug('Received "ResetAggregatedPositionsUIMessage"');
      try {
         await this.init();
         this.onTerminalTotalPositionMessage(Object.values(this._terminalTotals));
      } catch (error) {
         console.error(error);
      }
   }

   //

   private onTerminalTotalPositionMessage(data: TerminalTotalPositionDto[]): void {
      const ownTermianlId = this.sessionService.loginResult.ownTerminal.terminalId;
      
      data.forEach(x => {
         
         this._terminalTotals[x.terminalId] = x;
         
         const exposureChangedMessage: TerminalExposureChangedUIMessage = {
            sessionTerminalTotal: x.sessionTotalPnL,
            accumulatedTerminalTotal: x.accumulatedTotalPnL,
            terminalId: x.terminalId,
            displayName: x.displayName,
            isOwnTerminal: ownTermianlId === x.terminalId
         };

         this.messageBus.publishAsync({
            topic: 'TerminalExposureChangedUIMessage',
            payload: exposureChangedMessage
         });
      });
   }

   //

   private async getTerminalTotal(): Promise<any> {
      const terminals = this.sessionService.loginResult.availableTerminals.map(
         x => x.terminalId
      );

      const ownTerminal = this.sessionService.loginResult.ownTerminal;

      const query = new GetTerminalTotalPosition(terminals);

      const dtos = await this.shellClient
         .processQuery<TerminalTotalPositionDto[]>(query);

      this.onTerminalTotalPositionMessage(dtos);
   }
}
