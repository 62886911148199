import { Injectable } from '@angular/core';
import { EtsConstants } from '../ets-constants.const';
import { AccessControlService } from '../access-control-service.class';
import { environment } from '../environments/environment';

const DASHBOARD = EtsConstants.companyServices.etsDashboardApplicationId;
const WEBTRADER = EtsConstants.companyServices.etsWebTraderApplicationId;

@Injectable()
export class WorkspaceSecurityContextService {
   constructor(
      private readonly _accessControl: AccessControlService
   ) { }

   get canSplitWorkspace(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('8fbf5c7b-895c-45c9-a126-96a3cf8b0c2f');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('013d3ec9-c621-4d1e-8d7c-62469561999a');
      }
      return false;
   }

   get canCollapseWorkspace(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('a3af79fe-77c5-46de-9686-0995165669e8');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('5ed8f180-cf54-4d8a-bb9e-146d6cc31e8f');
      }
      return false;
   }

   get canAddPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('d64b4c26-a023-4b75-9381-0e1cf4a2c816');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('0deb3eb4-a1e5-4924-a2ef-7a36593687a2');
      }
      return false;
   }

   get canExpandCollapsePanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('ce6e8058-5af3-4392-9a98-7800a109085d');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('1fc90888-b0d9-4470-ba8d-1ceeb35ba4cf');
      }
      return false;
   }

   get canClosePanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('a3af79fe-77c5-46de-9686-0995165669e8');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('44a14661-ff6b-437e-ac1e-1f575d9545d6');
      }
      return false;
   }

   get tradingSystemsViewPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('42eeba59-5991-4bb9-b36a-ec00eb5382e7');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('2b46116e-97de-452d-a619-1cf8703a0dae');
      }
      return false;
   }

   get strategyOrdersAndTradesPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('507120af-3305-4a8b-9993-b42ca1d05e07');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('c7295ab3-5e3d-4ad1-a557-5b084fb8a9dc');
      }
      return false;
   }

   get strategyMessagesPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('7f1f4830-5d0c-493f-a20e-824f0582f47c');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('818552e6-131a-40ae-98d5-a31ee737c5a9');
      }
      return false;
   }

   get quoteBoardPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('6dbc24ef-fe4b-4e00-9482-247f65f06875');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('23ab2c60-c6f4-4052-8e9b-87ccc25bcac0');
      }
      return false;
   }

   get manualOrdersAndTradesPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('0b94e31c-c6e7-4fed-9cef-e06096a973dc');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('43aef47a-4c6d-4485-8b14-8dc580c9d945');
      }
      return false;
   }

   get manualPositionsPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('2f6be36d-27ab-440d-b02e-2f3820f62dd9');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('8a12e053-134b-4b44-a87c-6c5338c0e9e1');
      }
      return false;
   }

   get aggregatedPositionsPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('fcdc14ef-100e-43b0-b0b7-cb5fbcdb16e4');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('ec4da7d3-3458-4bf3-b9d9-fe6f0406f889');
      }
      return false;
   }

   get pnlChartPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('1f567817-d3dd-4fb0-acb8-7ce318752890');
      }
      return false;
   }

   get priceChartPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('e338f229-756b-41d8-af0c-0670cd71124c');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('c8ee83ad-0c28-4170-b778-69df6be68dce');
      }
      return false;
   }

   get strategyIssuesPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('cc5cf765-1dba-4d68-b241-aa5186a81aaa');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('5e53faf3-1162-4157-b65b-5a481bef34da');
      }
      return false;
   }

   get hpDatasetsPanel(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('e06a04e5-8bb8-43d1-a9ab-93099ac5fdcd');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('07260981-836b-4d11-8790-0e25e3d54307');
      }
      return false;
   }

   get openPositionChart(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('3d57a03a-5e23-4128-a6e6-ffbaed3e50f7');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('2badad3a-aeed-4323-9e71-693d92cc063d');
      }
      return false;
   }

   get positionsSizingReport(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('1d28de93-eae4-4c02-86be-d027075407f5');
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('22584eba-7df9-4d4c-b409-f1ee828d5a7d');
      }

      return false;
   }

   get strategyTriggers(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('9613000b-6c02-4d71-ad10-cc617ddd838f');
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('c362ee32-6279-486f-9880-674499a201b0');
      }
      return false;
   }

   get optionsBoard(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('57380a3d-aca8-4a82-b4ca-ffa2630bc25c');
      }
      
      return false;
   }

   //
   get portfolios(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('daedf268-165e-4e2d-be43-64501a0622be');
      }

      return false;
   }

   //
   get multiTradePad(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('b3d2569e-d458-4bb9-9328-f91e1ab8b76f');
      }

      return false;
   }

   //
   get ledger(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('a6b53672-fdbd-4103-a76a-9b1f1c69bcca');
      }

      return false;
   }
 
   //
   get optionsPricingPad(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('53d9ffd3-7ce7-4204-b66e-6d6da600a4a4');
      }

      return false;
   }

   //
   get optionsPricingGrid(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('31a6d84d-d670-4670-aab8-0eada132e299');
      }

      return false;
   }

   //
   get strategyHedgedPositions(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('6eb83241-e812-4a3e-b341-a41714e991c8');
      }

      return false;
   }
   
   //
   get sessionHistory(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('1f27e4b4-030b-4832-a0dc-91b10744ab4b');
      }

      return false;
   }

   //
   get portfolioItems(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('2a1b78e9-0545-4c3c-b5b8-005a47f2493e');
      }

      return false;
   }

   //
   get portfolioOrdersAndTrades(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('16674044-8dd6-4abb-9100-c1b02e8d7d95');
      }

      return false;
   }

   //
   get watchList(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('110902a1-09e7-449e-8340-dfb77f1dbcce');
      }

      return false;
   }

   //
   get adjustmentStrategies(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('424a5e0d-1917-4399-b95e-253080a9e96a');
      }

      return false;
   }

   //
   get parametersPanel(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('38001753-16ba-4683-b32a-f6a98b4aef71');
      }

      return false;
   }

   //
   get marginRequirements(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('81b3fe56-b396-43d0-855c-437905e89f4f');
      }

      return false;
   }

   //
   get shellMessages(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('77c4d396-ee28-43ee-bf01-dcaa370ad9e4');
      }

      return false;
   }

   //
   get futureOrders(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('f9b48fd7-33ef-416a-83da-c1b94efa956f');
      }

      return false;
   }

   //
   get automationCp(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('b716d471-3d48-4cdc-9dcf-bda20d72ab7b');
      }

      return false;
   }

   //
   get adjustmentPricingGrid(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('184f0d77-b9a4-46d7-adee-0f448ed7614d');
      }

      return false;
   }


   //
   get cashflowTracking(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('bd4100f3-95d1-432d-8750-c74d8d81129e');
      }

      return false;
   }
   
   //
   get adjustmentComparisonGrid(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('e1503295-4c6e-4b78-a92a-b6fae48de6ae');
      }

      return false;
   }

   //
   get checklistEditor(): boolean {
      
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('b70aa54c-75c8-4a79-aa2f-302231bf2a13');
      }

      return false;
   }

   get resourceEditor(): boolean {

      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('04baebc0-009f-4f64-815b-2caf68ed8312');
      }

      return false;
   }

   get hedgingGrid(): boolean {

      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('465289b5-6930-41e1-8d57-5bd8d0b2ff9f');
      }

      return false;
   }

   get packageComparison(): boolean {

      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('48892090-49ac-4385-ad27-9f55ee461fc4');
      }

      return false;
   }

   get positionModifier(): boolean {

      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('ad9c1475-387a-45a4-92a4-fdb62b6a9e51');
      }

      return false;
   }
}
