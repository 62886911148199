<dx-popup
   [width]="this.width"
   [height]="this.height"
   [title]="this.title"
   [showCloseButton]="false"
   [closeOnOutsideClick]="false"
   [resizeEnabled]="false"
   [shading]="false"
   >
   <div *dxTemplate="let data of 'content'">
      <div class="container">
         <div class="content">
            <div class="header">
               <div class="title">
                  After State
               </div>
               <div class="details">
                  <div class="adjustment-type">
                     <span class="value">{{this.adjustment}}</span>
                  </div>
               </div>
            </div>
            <div class="state">
               <table>
                  <thead>
                     <tr>
                        <th>Qty</th>
                        <th>Side</th>
                        <th>Type</th>
                        <th>Expiration</th>
                        <th>Strike</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let row of this.state" [ngClass]="this.getRowRoleClass(row)">
                        <td>{{row.qty}}</td>
                        <td>{{row.side}}</td>
                        <td>{{row.type}}</td>
                        <td>{{row.expirationFull}}</td>
                        <td>{{row.strike}}</td>
                     </tr>
                  </tbody>
               </table>
            </div>

         </div>
         <div class="footer">
            <div class="btn apply">
               <dx-button [text]="this.btnApplyText" width="100%"
                  (onClick)="this.onApplyClicked()">
               </dx-button>
            </div>
            <div class="btn close">
               <dx-button [text]="this.btnCloseText" width="100%"
                  (onClick)="this.onCloseClicked()">
               </dx-button>
            </div>
         </div>
      </div>
   </div>
</dx-popup>
