import {ChangeDetectorRef} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {DetectMethodChanges, isVoid} from "projects/shared-components/utils";
import {CashFlowStrategyTemplatesService} from "../services/cashflow-strategy-templates.service";
import {ICashFlowAdjustmentSettingsTemplate} from "./ICashFlowAdjustmentSettingsTemplate";

export class DeleteTemplatePopupModel {
    constructor(
        private _changeDetector: ChangeDetectorRef,
    ) {
    }

    private _resolve;
    private _reject;

    visible= false;
    template: ICashFlowAdjustmentSettingsTemplate;

    @DetectMethodChanges()
    show(template: ICashFlowAdjustmentSettingsTemplate): Promise<void> {
        if (isVoid(template)) {
            return null;
        }
        this.template = template;
        this.visible = true;
        return new Promise((res, rej) => {
            this._resolve = res;
            this._reject = rej;
        });
    }

    @DetectMethodChanges()
    onYesClicked() {
        this._resolve();
        this.close();
    }

    @DetectMethodChanges()
    onNoClicked() {
        this._reject();
        this.close();
    }

    @DetectMethodChanges()
    close() {
        this.visible = false;
    }
}

export class EditTemplatePopupModel {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _toastr: ToastrService,
        private _cashFlowStrategyTemplatesService: CashFlowStrategyTemplatesService
    ) {
    }

    private _template: ICashFlowAdjustmentSettingsTemplate;

    private _mode: 'new' | 'edit';

    isVisible = false;

    templateName: string;

    isShared = false;

    get canChangeStrategy(): boolean {
        return this._mode !== 'edit';
    }

    get title(): string {
        return this._mode === 'new'
            ? 'Save New Template'
            : 'Edit Template';
    }

    //
    @DetectMethodChanges()
    show(template: ICashFlowAdjustmentSettingsTemplate, mode: 'new' | 'edit') {

        if (isVoid(template)) {
            this._toastr.error('Please provide correct settings to save as template');
            return;
        }

        this._mode = mode;

        this._template = template;

        this.templateName = template.templateName;

        this.isShared = template.isShared || false;

        this.isVisible = true;
    }

    //
    async save() {

        if (isVoid(this.templateName)) {
            this._toastr.error('Please provide valid template name');
            return;
        }

        if (isVoid(this._template.strategyName)) {
            this._toastr.error('Please select valid strategy for the template');
            return;
        }

        this._template.templateName = this.templateName;

        const sharedStateChanged = this._template.isShared !== this.isShared;

        this._template.isShared = this.isShared;

        const success = await this._cashFlowStrategyTemplatesService
            .saveTemplate(this._template, {sharedStateChanged});

        if (success) {
            this._toastr.success('Template has been saved', 'Settings Templates');
        } else {
            this._toastr.success('Template has been saved', 'Settings Templates');
        }

        this.close();
    }

    //
    @DetectMethodChanges()
    close() {
        this.isVisible = false;
        this.isShared = false;
        this.templateName = null;
        this._template = null;
    }
}