import {EventEmitter} from "@angular/core";
import {SolutionPositionDto} from "./model/SolutionPositionDto";
import {CashFlowStrategy} from "../adjustment-control-panel/cash-flow-strategy";
import {isVoid} from "../utils";

export class AfterStateDetalizationService {

    ready$ = new EventEmitter();

    private _positions: { positions: SolutionPositionDto[], strategy: CashFlowStrategy }[] = [];

    private _promise: Promise<void>;
    private _resolver: (value?: (PromiseLike<void> | void)) => void;

    add(positions: SolutionPositionDto[], strategy: CashFlowStrategy): Promise<void> {

        this._positions.push({positions, strategy});

        if (this._positions.length >= 2) {
            this.ready$.emit();
        }

        if (isVoid(this._promise)) {
            this._promise = new Promise<void>((res, rej) => {
                this._resolver = res;
            });
        }

        return this._promise;
    }

    clear() {
        this._positions.length = 0;
        this._promise = null;
    }

    resolve() {
        this._resolver();
        this.clear();
    }

    log() {
        console.log(this._positions);
    }

    getData() {
        return this._positions;
    }
}