import {StrategyParameters} from '../../strategies/strategy-parameters.enum';
import {AlgoDescriptionBuilderBase} from '../algo-description-builder-base.class';

export class DefaultAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
  
  getSymbolPropertyNames(): string[] {
    return [];
  }

  getAlgoName(parameters: StrategyParameters): string {
    return 'N/A';
  }
  
  getDescription(parameters: StrategyParameters): string {
    return 'N/A';
  }
}
