import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'projects/shared-components/environments/environment';
import { parseOptionTicker } from 'projects/shared-components/options-common/options.model';
import { DetectMethodChanges, DetectSetterChanges, delay, getBucketRoleClass, isVoid, makeFullExpirationDate, makeGuiFriendlyExpirationDate } from 'projects/shared-components/utils';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { BeforePositionDto } from '../../model/BeforePositionDto';
import { SolutionPositionDto } from '../../model/SolutionPositionDto';
import { SweetPriceTrackingService } from '../../services/sweet-price-tracking.service';
import { ICashFlowAdjustmentSettingsTemplate } from '../../model/ICashFlowAdjustmentSettingsTemplate';

@Component({
   selector: 'ets-apg-tracking-sync-dialog',
   templateUrl: './apg-tracking-sync-dialog.component.html',
   styleUrls: [
      './apg-tracking-sync-dialog.component.scss',
      '../../adjustment-grid-common-style.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApgTrackingSyncDialogComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _sweetPriceTrackingService: SweetPriceTrackingService
   ) { }

   //
   private _resolver: (value: 'Former' | 'Current') => void;
   
   //
   private _rejecter: (err?) => void;
   
   //
   private _portfolioId: string;

   //
   private _userId: string;
   
   //
   title = 'Sync Tracking Data';

   //
   formerState: SolutionPositionDto[][] = [];
   
   //
   currentState: SolutionPositionDto[][] = [];

   //
   formerTemplate: string;

   //
   formerStrategy: CashFlowStrategy;

   //
   formerUnderlying: string;

   //
   currentTemplate: string;

   //
   currentStrategy: CashFlowStrategy;

   //
   currentUnderlying: string;

   //
   @ViewChild(DxPopupComponent) 
   popup: DxPopupComponent;

   //
   private _isLoading : boolean;
   get isLoading() : boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v : boolean) {
      this._isLoading = v;
   }
   
   //
   get width(): number {
      return this.hasCurrentState ? 640 : 370;
   };
   
   //
   get height(): number {

      const max = Math.max(this.formerState.length, this.currentState.length);
      const base =  max == 2 ? 475 : 360;

      if (this.formerState.length === 0) {
         return base;
      }

      const lineHeight = 25;

      const formerLegsCount = this.formerState.map(x => x.length).reduce((p, c) => p + c, 0);
      const formerLegsExcess = formerLegsCount - 8;
      
      if (this.currentState.length === 0) {
         return base + formerLegsExcess * lineHeight;
      }
      
      const currentLegsCount = this.currentState.map(x => x.length).reduce( (p, c) => p + c, 0);
      const currentLegsExcess = currentLegsCount - 8;

      const biggerExcess = Math.max(currentLegsExcess, formerLegsExcess);
      
      const extraHeight = biggerExcess * lineHeight;

      return base + extraHeight;
   };

   //
   get resizeEnabled(): boolean {
      return !environment.production;
   }

   //
   hasCurrentState: boolean;

   //
   get footerCssClass(): string[] {
      const css = ['footer'];
      
      if (!this.hasCurrentState) {
         css.push('single');
      }

      return css;
   }

   //
   ngOnInit(): void {

   }

   async show(
      userId: string,
      portfolioId: string,
      currentPositions: BeforePositionDto[][],
      currentTemplate: ICashFlowAdjustmentSettingsTemplate
   ): Promise<'Former' | 'Current'> {
   
      this._userId = userId;

      this._portfolioId = portfolioId;

      this.hasCurrentState = true;

      this.isLoading = true;

      this.popup.visible = true;

      // hack to make loading dialog show immediately
      await delay(250);

      const trackingObj = this._sweetPriceTrackingService.getSweetPriceTrackingObject(
         this._userId,
         this._portfolioId
      );

      const formerState = this.transformData(trackingObj.positions);
      this.formerState = formerState;
      this.formerStrategy = this.formerState.length == 2 ? 'Calls & Puts' : this.formerState[0][0].strategy;
      this.formerUnderlying = this.formerState[0][0].underlying;
      this.formerTemplate = trackingObj.template.templateName;

      const currentState = this.transformData(currentPositions);
      this.currentState = currentState;
      this.currentStrategy = currentState.length == 2 ? 'Calls & Puts' : currentState[0][0].strategy;
      this.currentUnderlying = currentState[0][0].underlying;
      this.currentTemplate = currentTemplate.templateName;

      this.isLoading = false;
      
      this._changeDetector.detectChanges();

      return new Promise( (resolve, reject) => {
         this._rejecter = reject;
         this._resolver = resolve;
      });
   }

   //
   getRowRoleClass(item: SolutionPositionDto) {
      return getBucketRoleClass(item);
   }

   @DetectMethodChanges()
   onDialogClosed() {
      if (this._rejecter) {
         this._rejecter();
      }

      this.popup.visible = false;
   }

   @DetectMethodChanges()
   onStateSelected(state: 'Former' | 'Current') {
      try {
         this._resolver(state);
      } finally {
         this.popup.visible = false;
      }

   }

   //
   private transformData(positions: BeforePositionDto[][]): SolutionPositionDto[][] {
      
      const solutionPositions = positions.map(poses => {
         
         const solutionPoses = poses.map(p => {

            const optionTicker = parseOptionTicker(p.ticker);

            let solutionPosition: SolutionPositionDto;

            if (!isVoid(optionTicker)) {
               solutionPosition = {
                  underlying: optionTicker.underlying,
                  type: optionTicker.type,
                  expiration: makeGuiFriendlyExpirationDate(optionTicker.expiration),
                  expirationFull: makeFullExpirationDate(optionTicker.expiration),
                  qty: p.qty,
                  role: p.role,
                  strike: optionTicker.strike,
                  strategy: p.strategy,
                  ticker: p.ticker,
                  side: p.qty > 0 ? 'Long' : p.qty < 0 ? 'Short' : undefined  
               };
            } else {
               solutionPosition = {
                  type: '---',
                  side: '---',
                  expiration: '---',
                  strike: '---'
               } as any;
            } 

            return solutionPosition;
            
         });

         return solutionPoses;

      });

      return solutionPositions;
   }

}
