import { Component, OnInit, OnDestroy, EventEmitter, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { ColumnState, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { getStrategyMessagesGridModel } from './strategy-messages-grid-model';
import { getPanelStateKey, isTruthy, isNullOrUndefined, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { StrategyHighlightedUIMessage } from 'projects/shared-components/ui-messages/strategy-highlighted-ui-message.interface';
import { filter, takeUntil, map, throttleTime } from 'rxjs/operators';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { StrategyRemovedUIMessage } from 'projects/shared-components/ui-messages/strategy-removed-ui-message.interface';
import { StrategyLogMessageDto } from 'projects/shared-components/shell-communication/dtos/strategy-log-message-dto.class';
import {
   GetStrategyLogMessagesWeb
} from 'projects/shared-components/shell-communication/operations/strategies/get-strategy-log-messages-web.class';
import { StrategiesTradingDataBackendService } from '../strategies/strategies-trading-data-backend-service.interface';
import { TimestampsService } from '../timestamps.service';
import { StrategiesService } from '../strategies/strategies.service';
import { SessionEndedDto } from '../shell-communication/shell-dto-protocol';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { BucketItemHighlighted } from '../ui-messages/ui-messages';
import { IPanelComponent } from '../panels/panel-component.interface';

interface PanelState {
   columnsState: ColumnState[];
}

@Component({
   selector: 'ets-strategy-messages',
   templateUrl: './strategy-messages.component.html',
   styleUrls: ['./strategy-messages.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyMessagesComponent implements OnInit, OnDestroy  {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _layoutService: SettingsStorageService,
      protected readonly _messageBus: MessageBusService,

      private readonly _toastr: ToastrService,
      private readonly _backendClient: StrategiesTradingDataBackendService,
      private readonly _timestampsService: TimestampsService,
      private readonly _strategiesService: StrategiesService,
   ) {
      
      this._messagesIx = [];
      this.contextPopupParent = document.querySelector('body');
   }
   
   private readonly _messagesIx: StrategyLogMessageDto[];
   private _pendingDataContainer: StrategyLogMessageDto[];
   private _grid: GridReadyEvent;
   private _unsubscriber: Subject<any> = new Subject<any>();
   private _stateChanged = new EventEmitter();
  
   @Input() container: IPanelComponent;
   @Output() saveState$ = new EventEmitter();
   @Output() restoreState$ = new EventEmitter();
   
   contextPopupParent: HTMLBodyElement;
   strategyLogMessagesGridOptions: GridOptions;
   contextStrategyId: string;
   selectedMessage: StrategyLogMessageDto;

   get timestampsService(): TimestampsService { return this._timestampsService; }
   
   private _isDetailsWindowVisible: boolean;
   get isDetailsWindowVisible(): boolean { return this._isDetailsWindowVisible; }
   
   @DetectSetterChanges()
   set isDetailsWindowVisible(value: boolean) {
      this._isDetailsWindowVisible = value;
   }

   //
   ngOnInit(): void {
      this.strategyLogMessagesGridOptions = getStrategyMessagesGridModel.bind(this)();
   }
   
   //
   ngOnDestroy(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   async onGridReady(args): Promise<void> {
      this._grid = args;

      const key = getPanelStateKey(this as any);
      const item = this._layoutService.getItem(key);
      
      if (!item) {
         this.saveState$.emit();
      } else {
         this.restoreState$.emit();
      }

      this._subscribeToMessages();
      
      await this._loadLastHighlightedStrategy();
   }

   
   async loadMoreMessages(batchSize: number): Promise<any> {
      if (!this.contextStrategyId) {
         return;
      }

      this._grid.api.showLoadingOverlay();
      
      try {
         const historyStop = this._messagesIx.length ? this._messagesIx[0].seqNum : 0;
         const qry = new GetStrategyLogMessagesWeb(this.contextStrategyId, historyStop, batchSize);
         const resp = await this._backendClient.getMessages(qry);
         const loadedMessages = resp.data as StrategyLogMessageDto[];
         if (loadedMessages.length === 0) {
            this._toastr.info('All messages loaded');
            return;
         }
         this._messagesIx.unshift(...loadedMessages);
         this._grid.api.setRowData(this._messagesIx);
      } catch (error) {
         const errorMessage = 'Failed to load strategy messages';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);
      } finally {
         this._grid.api.hideOverlay();
      }
   }

   
   onStateChanged(): void {
      this._stateChanged.emit();
   }

   
   private async _getLogMessagesFromServer(strategyId: string): Promise<StrategyLogMessageDto[]> {
      const qry = new GetStrategyLogMessagesWeb(strategyId, 0, 100);
      const resp = await this._backendClient.getMessages(qry);
      return resp.data as StrategyLogMessageDto[];
   }

   
   private _logDataLoadingError(error: any, errorMessge: string): void {
      const data = {};
      if (error instanceof Error) {
         // tslint:disable-next-line: no-string-literal
         data['error'] = error.stack;
      } else {
         // tslint:disable-next-line: no-string-literal
         data['error'] = error;
      }
      console.error(errorMessge, data);
   }

   
   private _subscribeToMessages() {
      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.container.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onStrategyHighlightedMessage(message.payload));

      this._messageBus
         .of<any>('StrategyLogMessageDto')
         .pipe(
            filter(x => {
               return this.contextStrategyId in x.payload;
            }),
            map(x => {
               return x.payload[this.contextStrategyId];
            }),
            filter(x => {
               return x.length > 0;
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onStrategyLogMessage(message));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors && !!this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onClearTradingDataMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onShellConnectionStatusChanged(msg.payload));

      this._stateChanged
         .pipe(
            throttleTime(250),
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => this.saveState$.emit());

      this._messageBus
         .of<StrategyRemovedUIMessage>('StrategyRemovedUIMessage')
         .pipe(
            filter(msg => msg.payload.strategyId === this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onStrategyRemovedUIMessage(msg.payload));

      this._messageBus
         .of<SessionEndedDto>('SessionEndedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this._onSessionResetCompleted(data.payload));

      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.container.layoutTabId),
            filter(msg => msg.payload.item.itemType === PortfolioItemType.Strategy)
         )
         .subscribe(msg => this.onBucketItemHighlighted(msg.payload));

      this._messageBus.of('DefaultTimezoneChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => {
            if (this._grid) {
               this._grid.api.refreshCells({force: true});
            }
         });
   }

   private async onBucketItemHighlighted(msg: BucketItemHighlighted): Promise<void> {
      if (msg.item.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const strategyId = msg.item.portfolioItemId;

      const strategyHighlightedMessage: StrategyHighlightedUIMessage = { strategyId };
      
      await this._onStrategyHighlightedMessage(strategyHighlightedMessage);
   }

   
   private async _onSessionResetCompleted(data: SessionEndedDto) {
      if (!isTruthy(this.contextStrategyId)) {
         return;
      }
      const str = this._strategiesService.getById(this.contextStrategyId);

      if (isNullOrUndefined(str)) {
         return;
      }

      if (data.terminalId !== str.terminalId) {
         return;
      }

      const strategyId = this.contextStrategyId;
      this.contextStrategyId = null;
      await this._onStrategyHighlightedMessage({ strategyId });
   }

   
   private async _onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {
      if (this.contextStrategyId) {
         const strategyId = this.contextStrategyId;
         this.contextStrategyId = null;
         await this._onStrategyHighlightedMessage({ strategyId });
      }
   }

   
   private async _loadLastHighlightedStrategy(): Promise<void> {
      const lastMessage = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>(
         'StrategyHighlightedUIMessage',
         this.container.layoutTabId
      );
      if (lastMessage) {
         await this._onStrategyHighlightedMessage(lastMessage.payload);
      }
   }


   private async _onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage): Promise<any> {

      this.contextStrategyId = message.strategyId;

      if (isVoid(this.contextStrategyId)) {
         this._grid.api.setRowData([]);
         return;
      }

      try {
         
         this._grid.api.showLoadingOverlay();
         await this._loadStrategyLogMessages();

      } catch (error) {
         
         this._grid.api.setRowData([]);
         const errorMessage = 'Failed to load messages';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);

      } finally {

         this._grid.api.hideOverlay();

      }
   }

   
   private async _loadStrategyLogMessages(): Promise<any> {
      let messages: StrategyLogMessageDto[];
      this._pendingDataContainer = [];
      
      try {
      
         messages = await this._getLogMessagesFromServer(this.contextStrategyId);
         this._messagesIx.length = 0;
         
         if (messages.length > 0) {
            const lastSeqNum = messages[messages.length - 1].seqNum;
            const filteredPendings = (this._pendingDataContainer || []).filter(t => t.seqNum > lastSeqNum);
            const totalMessages = messages.concat(filteredPendings);
            this._messagesIx.push(...totalMessages);
         }

         this._grid.api.setRowData(this._messagesIx);
      } finally {
         this._pendingDataContainer = null;
      }
   }

   
   private _onStrategyLogMessage(dtos: StrategyLogMessageDto[]): void {
      const pendingDataContainer = this._pendingDataContainer;
      if (pendingDataContainer) {
         pendingDataContainer.push(...dtos);
      } else {
         this._messagesIx.push(...dtos);
         this._grid.api.applyTransactionAsync({ add: dtos });
      }
   }

   
   private _onClearTradingDataMessage(message: ClearTradingDataUIMessage): void {
      if (message.hasErrors) {
         return;
      }

      if (message.strategies.includes(this.contextStrategyId) || message.refreshDb) {
         this._messagesIx.length = 0;
         this._grid.api.setRowData(this._messagesIx);
      }
   }

   
   getState(): PanelState {
      if (!this._grid) {
         return null;
      }


      const gridState = this._grid.columnApi.getColumnState();
      const state: PanelState = { columnsState: gridState };

      return state;
   }

   
   setState(state: PanelState): void {
      if (!this._grid) {
         return;
      }

      const isOK = this._grid.columnApi.setColumnState(state.columnsState);
      
      if (!isOK) {
         this._toastr.error('"Strategy Messages" panel was restored with errors');
      }
   }

   
   private _onStrategyRemovedUIMessage(msg: StrategyRemovedUIMessage): void {
      this._messagesIx.length = 0;
      this._grid.api.setRowData([]);
      this.contextStrategyId = null;
   }

}
