import { formatCurrency, formatNumber } from '@angular/common';
import { AgGridColumn } from 'ag-grid-angular';
import { FirstDataRenderedEvent, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { centeredColumnDef, defaultLoadingOverlayTemplate, defaultMoneyCellDefinition, defaultPriceCellFormatter, defaultQuoteCellFormatter, getDetailSymbolColumn, getMasterSymbolColumn, liveQuoteFormatter } from '../ag-grid-contrib';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { StrategyHedgedPositionsComponent } from './strategy-hedged-positions.component';

export function getStrategyHedgedPositionsGridModel(
   this: StrategyHedgedPositionsComponent
): GridOptions {

   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   const gridOptions: GridOptions = {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: setupGridColumns(this),

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowGroupPanelShow: 'always',

      suppressAggFuncInHeader: true,

      groupIncludeTotalFooter: true,

      animateRows: true,

      postSort: (rowNodes: RowNode[]) => {

         const nextInsertPos = rowNodes.length;
         for (let i = 0; i < rowNodes.length; i++) {
             const position: PositionDto = rowNodes[i].data;
             if (position.strategyId === this.hedgeStrategyId) {
                 rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
             }
         }

         // let nextInsertPos = 0;
         // for (let i = 0; i < rowNodes.length; i++) {
         //    const position: PositionDto = rowNodes[i].data;
         //    if (position.strategyId === this.hedgeStrategyId) {
         //       rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
         //       nextInsertPos++;
         //    }
         // }
      },


      getRowStyle: (args) => {
         if (!args.node) {
            return;
         }

         if (!args.node.data) {
            return;
         }

         if (args.node.data.strategyId === this.hedgeStrategyId) {
            return { 'font-weight': 'bold', 'font-family': 'cursive' };
         }
      },

      onGridReady: (args) => this.onGridReady(args),


      getRowNodeId: (data: PositionDto) => {
         return data.positionId;
      },


      onFirstDataRendered: (params: FirstDataRenderedEvent) => {
         params.api.sizeColumnsToFit();
      },


      getContextMenuItems: (params: GetContextMenuItemsParams) => {

         return [
            'autoSizeAll', 'copy', 'export'
         ];

      },
   };

   return gridOptions;
}


function setupGridColumns(comp: StrategyHedgedPositionsComponent): Partial<AgGridColumn>[] {

   return [

      getMasterSymbolColumn('ticker', comp.messageBus, comp.timestampsService
         , comp.unsubscriber, () => comp.gridApi),

      {
         headerName: 'Position',
         field: 'netPosition',
         valueGetter: (params: ValueGetterParams) => {
            if (!params.data) {
               return null;
            }
            return params.data.netPosition;
         }
      },

      {
         headerName: 'Live Quote',
         field: 'liveQuote',
         valueFormatter: liveQuoteFormatter,
      },

      {
         headerName: 'Avg. Px',
         field: 'avgPx',
         valueGetter: (params: ValueGetterParams) => {
            if (!params.data) {
               return null;
            }
            return params.data.avgPx;
         },
         valueFormatter: defaultPriceCellFormatter,
      },

      Object.assign(
         { headerName: 'Session P&L', field: 'sessionTotalPnL', aggFunc: 'sum' },
         defaultMoneyCellDefinition
      ),

      Object.assign(
         { headerName: 'Acc. P&L', field: 'accumulatedTotalPnL', aggFunc: 'sum' },
         defaultMoneyCellDefinition
      ),

      {
         headerName: 'Delta',
         field: 'delta',
         aggFunc: 'sum',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) {
               return '';
            }
            return formatNumber(params.value || 0, 'en-US', '1.0-0');
         }
      },

      {
         headerName: 'Theta',
         field: 'theta',
         aggFunc: 'sum',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) {
               return '';
            }
            return formatNumber(params.value || 0, 'en-US', '1.0-0');
         },
         hide: true,
      },

      {
         headerName: 'Gamma',
         field: 'gamma',
         aggFunc: 'sum',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) {
               return '';
            }

            return formatNumber(params.value || 0, 'en-US', '1.0-0');
         },
         hide: true,
      },

      {
         headerName: 'Vega',
         field: 'vega',
         aggFunc: 'sum',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) {
               return '';
            }

            return formatNumber(params.value || 0, 'en-US', '1.0-0');
         },
         hide: true,
      },

      { headerName: 'Terminal', field: 'terminalCode' },

      { headerName: 'Account', field: 'accountCode' },

      {
         headerName: 'Last Match Px.',
         field: 'lastMatchPx',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: 'Portfolio',
         field: 'portfolioName',
         hide: false,
         enableRowGroup: true,
         valueGetter: (params: ValueGetterParams) => {
            if (!params.data && !!params.node && !!params.node.aggData) {
               return '';
            }
            return params.data && params.data.portfolioName ? params.data.portfolioName : '<No Portfolio>';
         }
      },

      {
         headerName: 'Combo',
         field: 'comboName',
         hide: false,
         enableRowGroup: true,
         valueGetter: (params: ValueGetterParams) => {
            if (params.node.footer) {
               return '';
            }
            return params.data && params.data.comboName ? params.data.comboName : '<No Combo>';
         }
      },

      {
         headerName: 'Group',
         field: 'comboGroupName',
         hide: false,
         enableRowGroup: true,
         valueGetter: (params: ValueGetterParams) => {
            if (params.node.footer) {
               return '';
            }
            return params.data && params.data.comboGroupName ? params.data.comboGroupName : '<No Group>';
         }
      },

      {
         field: 'strategyId',
         hide: true,
         sort: 'asc',
         sortIndex: 0,

      }
   ];
}
