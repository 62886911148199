import { CashFlowAdjustmentLogicalId } from 'projects/shared-components/adjustment-control-panel/CashFlowAdjustmentId';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { ChecklistStepDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { isVoid } from 'projects/shared-components/utils';


export class ChecklistStep {

   constructor() {
      this.isCleanCopy = false;
      this.isDirty = true;
   }

   get id(): string {
      return `${this.strategy}+${this.adjustment}+${this.name}`;
   }

   checklistStepId: number;
   name: string;
   strategy: CashFlowStrategy;
   adjustment: CashFlowAdjustmentLogicalId;
   markup: string;
   seqNo: number;
   isCleanCopy: boolean;
   isDirty: boolean;

   dteGreaterThanCondition?: number;
   dayOfWeekCondition?: string;
   dayOfYearCondition?: string;
   dayOfExpirationCondition?: string;
   protectiveOptionsCondition?: string;
   dynamicOffsetsCondition?: string;
   assetCondition?: string;
   userCondition?: string;
   hasExtraPosCondition: boolean;

   isValid(): boolean {

      if (isVoid(this.name)) {
         return false;
      }
      
      if (isVoid(this.adjustment)) {
         return false;
      }

      if (isVoid(this.strategy)) {
         return false;
      }

      if (isVoid(this.markup)) {
         return false;
      }

      return true;
   }

   static fromDto(dto: ChecklistStepDto): ChecklistStep {

      const step = new ChecklistStep();
      step.checklistStepId = dto.checklistStepId;
      step.name = dto.name;
      step.strategy = dto.strategy;
      step.adjustment = dto.adjustment;
      step.markup = dto.markup;
      step.seqNo = dto.seqNo;
      step.isCleanCopy = dto.isCleanCopy;

      step.dteGreaterThanCondition = dto.dteGreaterThanCondition;
      step.dayOfWeekCondition = dto.dayOfWeekCondition;
      step.dayOfYearCondition = dto.dayOfYearCondition;
      step.dayOfExpirationCondition = dto.dayOfExpirationCondition;
      step.protectiveOptionsCondition = dto.protectiveOptionsCondition;
      step.dynamicOffsetsCondition = dto.dynamicOffsetsCondition;
      step.assetCondition = dto.assetCondition;
      step.userCondition = dto.userCondition;
      step.hasExtraPosCondition = dto.hasExtraPosCondition;

      step.isDirty = false;

      console.assert(step.isValid(), 'step is not valid', step);

      return step;
   }

   toDto(): ChecklistStepDto {
      const dto: ChecklistStepDto = {
         checklistStepId: this.checklistStepId,
         name: this.name,
         strategy: this.strategy,
         adjustment: this.adjustment,
         markup: this.markup,
         seqNo: this.seqNo,
         isCleanCopy: this.isCleanCopy,

         dteGreaterThanCondition: this.dteGreaterThanCondition,
         dayOfWeekCondition: this.dayOfWeekCondition,
         dayOfYearCondition: this.dayOfYearCondition,
         dayOfExpirationCondition: this.dayOfExpirationCondition,
         protectiveOptionsCondition: this.protectiveOptionsCondition,
         dynamicOffsetsCondition: this.dynamicOffsetsCondition,
         assetCondition: this.assetCondition,
         hasExtraPosCondition: this.hasExtraPosCondition,
         userCondition: this.userCondition
      };

      return dto;
   }
}
