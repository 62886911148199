import { PriceDataFeedBackendService } from 'projects/shared-components/price-chart/price-data-feed-backend-service.class';
import { Injectable } from '@angular/core';
import { UnsubscribePriceBars } from 'projects/shared-components/shell-communication/operations/price-charts/unsubscribe-price-bars.class';
import { SubscribePriceBars } from 'projects/shared-components/shell-communication/operations/price-charts/subscribe-price-bars.class';
import { GetPriceBars } from 'projects/shared-components/shell-communication/operations/price-charts/get-price-bars.class';
import { PriceBarDto } from 'projects/shared-components/shell-communication/dtos/price-bar-dto.interface';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';

@Injectable({providedIn: 'root'})
export class WebtraderPriceDataFeedBackendService extends PriceDataFeedBackendService {
  
  constructor(
    private readonly _shellClient: ShellClientService, 
    ) {
    super();
  }
  
  unsubscribePriceBars(cmd: UnsubscribePriceBars): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }  
  
  subscribePriceBars(cmd: SubscribePriceBars): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }
  
  getPriceBars(qry: GetPriceBars): Promise<PriceBarDto[]> {
    return this._shellClient.processQuery(qry);
  }
}
