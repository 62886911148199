import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';

export class CloseTerminalPositions implements ProtocolQuery {
  constructor(public readonly terminalId: string) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.CloseTerminalPositions`;
  }
}
