export class StrategyTradingObjectives {
  accumulatedNpo: number;
  accumulatedDollarTS: number;
  accumulatedPercentTS: number;
  accumulatedStopLoss: number;
  sessionNpo: number;
  sessionDollarTS: number;
  sessionPercentTS: number;
  sessionStopLoss: number;
  tradeNpo: number;
  tradeDollarTS: number;
  tradePercentTS: number;
  tradeStopLoss: number;
}
