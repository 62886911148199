<div class="protective-option-section" #container>
   <div class="header">
      <ets-section-header 
         [header]="this.header"
         >
      </ets-section-header>
   </div>

   <ng-container *ngIf="this.isMultiDirection; then double; else single">
   </ng-container>

   <dx-load-panel 
      [visible]="this.isLoading" 
      [position]="{my: 'center', at: 'center', of: container}"
      >
   </dx-load-panel>
</div>

<ng-template #single>

   <div class="body single">

      <div class="sidebox">
         <ets-automation-cp-protective-option-sidebox #firstSidebox>
         </ets-automation-cp-protective-option-sidebox>
      </div>

      <div class="parameters">
         <ets-protective-option-cashflow-strategy #protectiveOptionParameters
            [automationCpMode]="this.automationCpMode"
            [strategy]="this.strategy">
         </ets-protective-option-cashflow-strategy>
      </div>

   </div>
</ng-template>

<ng-template #double>

   <div class="body double">
      
      <div class="sidebox left">
         <ets-automation-cp-protective-option-sidebox #firstSidebox>
         </ets-automation-cp-protective-option-sidebox>
      </div>
      
      <div class="parameters">
         <ets-protective-option-cashflow-strategy #protectiveOptionParameters
            [automationCpMode]="this.automationCpMode"
            [strategy]="this.strategy">
         </ets-protective-option-cashflow-strategy>
      </div>

      <div class="sidebox right">
         <ets-automation-cp-protective-option-sidebox #secondSidebox>
         </ets-automation-cp-protective-option-sidebox>
      </div>

   </div>
   
</ng-template>
