import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {RiskManagerRuleDto} from '../../dtos/risk-manager-rule-dto.interface';

export class UpdateRiskManagerRule implements ProtocolCommand {
  constructor(public readonly rule: RiskManagerRuleDto) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateRiskManagerRule`;
  }
}
