import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { StrategyCommanderComponent } from './strategy-commander.component';
import { StrategyCommanderRuleModel } from './strategy-commander-rule-model';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';

export function getStrategyCommanderGridModel(
  this: StrategyCommanderComponent
): GridOptions {
  return  {
    rowData: [],

    defaultColDef: centeredColumnDef,

    columnDefs: [
      {
        headerName: 'Trigger',
        field: 'handlerName',
        width: 80
      },
      {
        headerName: 'Action',
        field: 'actionName',
        width: 80
      },
      {
        headerName: 'Description',
        field: 'description',
        width: 200
      },
      {
        headerName: 'Recurring?',
        field: 'isRecurring',
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value ? 'Yes' : 'No';
        },
        cellStyle(params: CellClassParams) {
          return params.value
            ? { color: 'green', 'font-weight': 'bold' }
            : { color: 'red', 'font-weight': 'bold' };
        },
        width: 40
      }
    ],

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    getRowStyle: (params) => {
      if (params.node.data.isDisabled) {
        return { 'font-style': 'italic' };
      }
      return { 'font-style': 'regular' };
    },

    onGridReady: args => this.onGridReady(args),

    getRowNodeId: (rowData: StrategyCommanderRuleModel) => {
      const id = rowData.strategyCommanderRuleId || 'adasdfsd';
      return id.toString();
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      const menu = [];
      if (this.securityContext.createRule) {
        const createMI = {
          name: 'Create',
          action: () => {
            const data = params.node ? params.node.data : null;
            return this.showCommanderEditor('create', data);
          }
        };
        menu.push(createMI);
      }

      if (this.securityContext.updateRule) {
        const updateMI = {
          name: 'Update',
          disabled: !params.node,
          action: () => {
            const data = params.node ? params.node.data : null;
            return this.showCommanderEditor('update', data);
          }
        };
        menu.push(updateMI);
      }

      if (this.securityContext.removeRule) {
        const removeMI = {
          name: 'Remove',
          disabled: !params.node,
          action: () => this.removeCommanderRule(params.node.data)
        };
        menu.push(removeMI);
      }

      if (this.securityContext.enableDisableRule) {
        if (params.node) {
          const enableDisableRule = {
            name: params.node.data.isDisabled ? 'Enable' : 'Disable',
            action: () => this.changeRuleState(params.node.data),
            disabled: !params.node || !params.node.data
          };
          menu.push('separator');
          menu.push(enableDisableRule);
        }
      }

      menu.push(
        'separator',
        'autoSizeAll',
        'copy',
        'export'
      );

      return menu;
    }
  } as GridOptions;
}
