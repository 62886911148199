import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdjustmentStrategiesModule } from '../adjustment-strategies/adjustment-strategies.module';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DevExtremeModule } from '../devextreme.module';
import { OrderExtensionsModule } from '../order-extensions/order-extensions.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { AdjustmentStrategyDialogComponent } from './adjustment-strategy-dialog.component';
import { InterestCalculatorStrategyComponent } from './strategy-parameters/interest/interest-calculator-strategy.component';
import { LongOptionStrategyComponent } from './strategy-parameters/long-option/long-option-strategy.component';
import { PutSpreadStrategyComponent } from './strategy-parameters/put-spread/put-spread-strategy.component';
import { BucketSelectorComponent } from './strategy-parameters/short-option/bucket-selector/bucket-selector.component';
import { ShortOptionStrategyComponent } from './strategy-parameters/short-option/short-option-strategy.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      DateTimePickerModule,
      TimezonePickerModule,
      OrderExtensionsModule,
      AdjustmentStrategiesModule
   ],
   exports: [
      AdjustmentStrategyDialogComponent,
      PutSpreadStrategyComponent,
      LongOptionStrategyComponent,
      ShortOptionStrategyComponent,
      InterestCalculatorStrategyComponent,
   ],
   declarations: [
      AdjustmentStrategyDialogComponent,
      PutSpreadStrategyComponent,
      LongOptionStrategyComponent,
      ShortOptionStrategyComponent,
      BucketSelectorComponent,
      InterestCalculatorStrategyComponent,
   ],
   providers: [],
})
export class AdjustmentStrategyDialogModule { }
