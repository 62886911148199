import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BucketContext } from '../shell-communication/shell-operations-protocol';

export enum PortfolioItemType {
   Strategy = 0,
   Stock = 1,
   Future = 2,
   Call = 3,
   Put = 4
}


export type BucketType = '<No Bucket>' | 'Terminal' | 'Portfolio' | 'Combo' | 'ComboGroup';


@Component({
   selector: '',
   template: `
      <div *ngIf="isArchived" [style.color]="cssRules.archivedColor">
         Archived Items
      </div>
      <div *ngIf="!isArchived" [style.color]="cssRules.comboColor">
         <span>{{groupType}}: </span>
         <span> "{{groupName}}"</span>
      </div>
   `
})
export class PortfolioGroupCellRendererComponent implements ICellRendererAngularComp {

   groupType: string;
   groupName: string;
   isArchived = false;
   cssRules = {
      archivedColor: 'yellow',
      comboColor: null
   };

   refresh(params: any): boolean {
      return false;
   }

   agInit(params: ICellRendererParams): void | Promise<void> {

      switch (params.node.field) {
         case 'portfolioName':
            this.isArchived = false;
            this.groupType = 'Portfolio';
            this.groupName = params.node.key;
            break;
         case 'comboName':
            this.isArchived = false;
            this.groupType = 'Combo';
            this.groupName = params.node.key;
            break;
         case 'comboGroupName':
            this.isArchived = false;
            this.groupType = 'Group';
            this.groupName = params.node.key;
            break;
         case 'isArchived':
            this.isArchived = true;
            break;
         default:
            this.groupType = '<No Type>';
            this.groupName = '<No Name>';
            break;
      }
   }
}


export interface WhatBucketIsSelectedUIMessage {
   bucketContext?: BucketContext;
}
