import { StrategySessionHistoryComponent } from './strategy-session-history.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultLoadingOverlayTemplate,
   AG_SYMBOL_COL_MIN_WIDTH,
   AG_DATE_COL_MIN_WIDTH
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { TimestampsService } from '../timestamps.service';

export function getSnapshotsGridModel(
   this: StrategySessionHistoryComponent,
   displayNameService: TradingInstrumentDisplayNameService,
   timestampsService: TimestampsService,
): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [

         {
            headerName: 'Symbol',
            field: 'tradingInstrumentCode',
            minWidth: AG_SYMBOL_COL_MIN_WIDTH,
            valueFormatter: (params: ValueFormatterParams) => {
               return displayNameService.getDisplayNameForTicker(params.value);
            },
            filter: true
         },
         {
            headerName: 'Type',
            field: 'orderType',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderType[params.value];
            },
            filter: true
         },
         {
            headerName: 'Status',
            field: 'status',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderStatus[params.value];
            },
            filter: true
         },
         {
            headerName: 'Qty',
            field: 'qty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return data.qty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Leaves Qty',
            field: 'leavesQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return (data.qty - data.filledQty) * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Order Px',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               if (data.limitPrice) {
                  return data.limitPrice;
               }
               if (data.stopPrice) {
                  return data.stopPrice;
               }
               return 0;
            },
            sort: 'desc',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Limit Px',
            field: 'limitPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Stop Px',
            field: 'stopPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Last Px',
            field: 'lastPx',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Side',
            field: 'side',
            valueFormatter: (params: ValueFormatterParams) => {
               return MarketSide[params.value];
            },
            filter: true
         },
         {
            headerName: 'Last Qty',
            field: 'lastQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return data.lastQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Filled',
            field: 'filledQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return data.filledQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Avg. Px',
            field: 'avgFillPx',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Tags',
            field: 'tags'
         },
         { headerName: 'Account', field: 'accountCode', filter: true },
         { headerName: 'Comment', field: 'comment', filter: true },
         {
            headerName: 'Timestamp',
            field: 'lastModifiedDate',
            minWidth: AG_DATE_COL_MIN_WIDTH,
            valueFormatter: (params: ValueFormatterParams) => {
               if (!params.value) {
                  return '';
               }
               const snapshot: OrderStateSnapshotDto = params.data;
               const frmtTime = timestampsService.getFormattedDateTimeForStrategy(snapshot.strategyId, snapshot.lastModifiedDate);
               return snapshot.lastModifiedDate
                  ? frmtTime
                  : '';
            }
         },
         { headerName: 'Order Id', field: 'orderId', filter: 'agTextColumnFilter' },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: args => this.onSnapshotsGridReady(args),

      getRowNodeId: (rowData: OrderStateSnapshotDto) =>
         rowData.orderStateSnapshotId,

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Load More ...',
               disabled: !this.selectedStrategy,
               subMenu: [
                  {
                     name: '25',
                     action: () => this.loadMoreTradingData(25, 'snapshots')
                  },
                  {
                     name: '50',
                     action: () => this.loadMoreTradingData(50, 'snapshots')
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreTradingData(100, 'snapshots')
                  }
               ]
            },
            {
               name: 'Toggle Full Size',
               action: () => this.toggleSectionFullWidth('tradingData')
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}
