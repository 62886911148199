import { StrategyOrdersAndTradesComponent } from './strategy-orders-and-trades.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultLoadingOverlayTemplate,
   liveQuoteFormatter,
   getMasterSymbolColumn
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { isCashSettledOptionTicker } from '../utils';

export function getStrategyOrdersGridModel(this: StrategyOrdersAndTradesComponent): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         
         getMasterSymbolColumn('ticker', this.messageBus, this.timestampsService, this.unsubscriber, () => this.ordersGridApi),
         
         {
            headerName: 'Exp. Style',
            field: 'expirationStyle',
            hide: true
         },

         {
            headerName: 'Status',
            field: 'status',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderStatus[params.value];
            },
            filter: true
         },
         {
            headerName: 'Type',
            field: 'orderType',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderType[params.value];
            },
            filter: true
         },
         {
            headerName: 'Leaves Qty',
            field: 'leavesQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderDto = params.data;
               return data.leavesQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Order Px',
            valueGetter(params: ValueGetterParams) {
               const data: OrderDto = params.data;
               if (data.limitPrice) {
                  return data.limitPrice;
               }
               if (data.stopPrice) {
                  return data.stopPrice;
               }
               return 0;
            },
            sort: 'desc',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Live Quote',
            field: 'liveQuote',
            valueFormatter: liveQuoteFormatter
         },
         {
            headerName: 'Last Fill Px',
            field: 'lastPx',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Last Qty',
            field: 'lastQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderDto = params.data;
               return data.lastQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Tags',
            field: 'tags',
            filter: 'agTextColumnFilter'
         },
         {
            headerName: 'Comment',
            field: 'comment',
            filter: 'agTextColumnFilter'
         },
         {
            headerName: 'Qty',
            field: 'qty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderDto = params.data;
               return data.qty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Side',
            field: 'side',
            valueFormatter: (params: ValueFormatterParams) => {
               return MarketSide[params.value];
            },
            filter: true,
         },
         {
            headerName: 'Limit Px',
            field: 'limitPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Stop Px',
            field: 'stopPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Filled',
            field: 'filledQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderDto = params.data;
               return data.filledQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Avg. Px',
            field: 'avgFillPrice',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Account',
            field: 'resolveAccountCode',
            filter: true
         },
         {
            headerName: 'Terminal',
            field: 'terminalCode',
            filter: true
         },
         {
            headerName: 'Timestamp',
            field: 'lastModifiedDate',
            valueFormatter: (params: CellClassParams) => {
               const order: OrderDto = params.data;
               const frmtTime = this.timestampsService.getFormattedDateTimeForStrategy(order.strategyId, order.lastModifiedDate);
               return order.lastModifiedDate
                  ? frmtTime
                  : '';
            },
         },
         {
            headerName: 'ID',
            field: 'orderId',
            filter: 'agTextColumnFilter'
          },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      onGridReady: (args) => this.onOrdersGridReady(args),

      getRowNodeId: (rowData: OrderDto) => rowData.orderId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         if (this.securityContext.requestOrderStatus) {
            menu.push(
               {
                  name: 'Request Status',
                  action: () => this.requestOrderStatus(params.node.data),
                  disabled: !params.node
               }
            );
            menu.push(
               {
                  name: 'Request Status All',
                  action: () => this.requestOrderStatus(),
                  disabled: !this.hasWorkingOrders()
               }
            );
         }

         if (this.securityContext.cancelCustomExitOrder) {
            menu.push({
               name: 'Cancel CE order',
               action: () => this.cancelCustomExitOrder(params.node.data),
               disabled: !params.node
                  || params.node.group
                  || !params.node.data.tags
                  || params.node.data.tags.indexOf('(CE_') === - 1
            });
            menu.push({
               name: 'Cancel All CE orders',
               action: () => this.cancelAllCustomExitOrders(),
            });
         }

         if (menu.length > 0) {
            menu.push('separator');
         }

         menu.push({
            name: 'Show Comment Popup',
            action: () => {
               this.selectedOrderOrSnapshot = params.node.data;
               this.isCommentPopupVisible = true;
            },
            disabled: !params.node
         });

         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );
         return menu;
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}
