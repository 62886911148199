<dx-popup class="popup"
          #popup
          [visible]="isVisible"
          [width]="350"
          [height]="350"
          [showTitle]="true"
          title="Re-Arrange Layouts"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="true"
          (onShowing)="onShowing()"
          (onHidden)="onHidden()">
    <div *dxTemplate="let data of 'content'">
        <div class="container">
            <div class="list">
                <div *ngFor="let panel of layoutsToSort" class="panel">    
                  <div class="title" [ngClass]="{'active': panel.isActive}">
                    {{panel.header}}
                  </div>
                  <div class="buttons">
                      <div class="button">
                        <dx-button class="up" (onClick)="movePanel(panel, 'up')">
                          <i class="fas fa-angle-up"></i>
                        </dx-button>
                      </div>
                      <div class="button">
                          <dx-button class="down" (onClick)="movePanel(panel, 'down')">
                            <i class="fas fa-angle-down"></i>
                          </dx-button>
                      </div>
                  </div>
                </div> 
            </div>
            <div class="save">
              <dx-button [type]="'success'" (onClick)="save()">
                Save
              </dx-button>
            </div>
          </div>            
    </div>
  </dx-popup>
