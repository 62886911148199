import { Component } from '@angular/core';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';

@Component({
   selector: 'ets-adjustment-pricing-grid-panel',
   template: `
<div class="panel-container" [ngClass]="{'ets-hidden': !this.isActive}">
   <ets-adjustment-pricing-grid></ets-adjustment-pricing-grid>
</div>
`,
   styles: [`
      .panel-container {
         width: 100%;
         height: 100%;
      }
   `]
})
export class AdjustmentPricingGridPanelComponent extends PanelBaseComponent {
   
   etsOnInit(): void {
   }
   
   etsOnDestroy(): void {
   }
   
   etsAfterViewInit(): void {
   }
   
   protected getState() {
      return null;
   }
   
   protected setState(state: any) {
      // 
   }
}
