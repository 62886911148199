import { NgModule } from '@angular/core';
import { PriceChartComponent } from './price-chart.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
  imports: [
     CommonModule, 
     DevExtremeModule
   ],
  exports: [PriceChartComponent],
  declarations: [PriceChartComponent],
  providers: []
})
export class EtsPriceChartModule { }
