import {ProtocolQuery} from '../../service-model/protocol-query.interface';
import {PROTOCOL_NAMESPACE} from './auth-protocol-namespace.const';


export class CheckIfTokenValid implements ProtocolQuery {
  constructor(public readonly authToken: string) { }

  getOperationName(): string {
    return `${PROTOCOL_NAMESPACE}.CheckIfTokenValid`;
  }
}
