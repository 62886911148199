<span #offsetsTargetA
      *ngIf="this.isEmptyCell(column)"
      [class.offset-violated]="this.doesViolateSpreadOffset(column.adjustments)"
      [class.spread-width-violated]="this.doesViolateSpreadWidth(column.adjustments)"
      class="na price"
>
    <span
        [class.offset-violated]="this.doesViolateSpreadOffset(column.adjustments)">N</span
    >/<span
    [class.spread-width-violated]="this.doesViolateSpreadWidth(column.adjustments)">A</span>
     <ng-container>
        <ets-visible-offsets-component
            [adjustments]="column.adjustments"
            [mouseOverTarget]="offsetsTargetA"
            [target]="'Zones Grid'"
        >
        </ets-visible-offsets-component>
     </ng-container>
</span>

<span #offsetsTarget *ngIf="!this.isEmptyCell(column)">
     <span [ngClass]="this.getPriceColorClass(column?.price)" class="price"
           [class.displaced]="column.displacedStrike"
           [class.theo]="this.hasTheoPrices(column.adjustments)"
     >
        {{ column?.price | currency:'USD':'symbol':'1.2-2' }}
     </span>
     <ng-container>
        <ets-visible-offsets-component
            [adjustments]="column.adjustments"
            [mouseOverTarget]="offsetsTarget"
            [target]="'Zones Grid'"
        >
        </ets-visible-offsets-component>
     </ng-container>
</span>
