import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

/**
 * @returns TerminalLoginResultDto
 */
export class TerminalLogin implements ProtocolQuery {
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.TerminalLogin`;
  }
}
