import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { TerminalExposureChangedUIMessage } from 'projects/shared-components/ui-messages/terminal-exposure-changed-ui-message.interface';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';
import { TerminalTotalDataService } from '../../global-services/terminal-total-data.service';


@Component({
   selector: 'ets-pnl-box',
   template: `
    <div #target class="pnl-box" [style.border-color]="this.borderColor" (click)="this.isPopoverVisible = !this.isPopoverVisible">
   
      <strong>{{ prefix }}&nbsp;:&nbsp;</strong>
      
      <span [style.color]="this.textColor">{{ this.pnl | currency:'USD':'symbol':'1.2-2' }}</span>

      <dx-popover
        [target]="target"
        position="top"
        [width]="300"
        [(visible)]="this.isPopoverVisible"
      >
        <div *dxTemplate="let data = model; of: 'content'">
         <table>
            <thead>
               <tr>
                  <th class="terminal-head">Terminal</th>
                  <th>Total</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let total of this.totals">
                  <td>{{total.terminalName}}</td>
                  <td [style.color]="total.color" class="total-value">{{total.total | currency:'USD':'symbol':'1.2-2' }}</td>
               </tr>
            </tbody>
         </table>
        </div>
      </dx-popover>

    </div>
  `,
   styles: [
      `
      .pnl-box {
        text-align: center;
        min-width: 120px;
        border-top: 2px solid;
        padding: 3px;
      }

      table {
         width: 100%;
      }

      thead {
         font-weight: 800;
         text-transform: uppercase;
      }

      .terminal-head {
         text-align: left;
      }

      .total-value {
         text-align: center;
      }
    `
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PnlBoxComponent implements OnInit, AfterViewInit {

   constructor(
      private _changeDetector: ChangeDetectorRef, 
      private _terminalTotalsService: TerminalTotalDataService,
      private readonly _sessionService: SessionService,
      private readonly _messageBus: MessageBusService) {

   }

   private _pnl: number;

   
   @Input() prefix: string;
   
   @Input() borderColor: string;
   
   
   get pnl(): number {
      return this._pnl;
   }
   
   @Input() set pnl(v: number) {
      
      if (this._pnl === v) {
         return;
      }
      
      this._pnl = v;
      
      if (v > 0) {
         this.textColor = 'green';
      } else if (v < 0) {
         this.textColor = 'red';
      } else {
         this.textColor = undefined;
      }
      
      this._changeDetector.detectChanges();
   }

   totals: any[] = [];
   
   private _isPopoverVisible: boolean;
   get isPopoverVisible(): boolean {
      return this._isPopoverVisible;
   }
   
   @DetectSetterChanges()
   set isPopoverVisible(v: boolean) {
      this._isPopoverVisible = v;

      if (this._isPopoverVisible) {
         
         const totals = this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy).map(term => {
         
            const total = this._terminalTotalsService.getTotalByTerminal(term.terminalId);

            const retval: any = { terminalId: term.terminalId };

            if (total) {
               retval.terminalName = total.displayName;
               retval.total = this.prefix === 'S' ? total.sessionTotalPnL : total.accumulatedTotalPnL;
               retval.color = this.getTextColor(retval.total);
            }

            return retval;

         });

         this.totals = totals.filter(x => !!x);
         
      }
   }
   
   
   textColor: string;


   ngOnInit() {
      this._messageBus.of<TerminalExposureChangedUIMessage>('TerminalExposureChangedUIMessage')
         .subscribe(msg => this.onTerminalExposureChanged(msg.payload));
   }


   @DetectMethodChanges()
   onTerminalExposureChanged(payload: TerminalExposureChangedUIMessage): void {
      if (this.totals.length === 0) {
         return;
      }
      const total = this.totals.find(x => x.terminalId === payload.terminalId);
      
      if (total) {
         total.total = this.prefix === 'S' ? payload.sessionTerminalTotal : payload.accumulatedTerminalTotal;
         total.color = this.getTextColor(total.total);
      }
   }

   ngAfterViewInit(): void {
      this._changeDetector.detach();
   }

   private getTextColor(pnl: number): string {
      let color;
      
      if (pnl > 0) {
         
         color = 'green';

      } else if (pnl < 0) {
         
         color = 'red';

      } else {

         color = undefined;

      }

      return color;

   }
}
