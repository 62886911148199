import {WorkerMessage} from '../worker-message.interface';
import {MessageBusService} from '../message-bus.service';
import {Injectable} from '@angular/core';
import {
    EventStreamConnectionChangedUIMessage
} from '../ui-messages/event-stream-connection-changed-message-ui-message.interface';
import {SessionService} from '../authentication/session-service.service';
import {EtsSessionData} from 'projects/shared-components/webworkers/ets-session-data.interface';
import {environment} from '../environments/environment';
import {Logger} from '../logging/logger.interface';
import {EtsWorker} from '../webworkers/ets-worker.class';
import {WorkerLoadedUIMessage} from '../ui-messages/worker-loaded-ui-message.interface';
import {CrossThreadMessage} from '../webworkers/cross-thread-message.interface';
import {AuthTokenExpiredUIMessage} from '../ui-messages/auth-token-expired-ui-message.interface';
import {LoggerService} from '../logging/logger-factory.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EventStreamServiceClient {
    constructor(
        private _messageBus: MessageBusService,
        private _sessionService: SessionService,
        loggerService: LoggerService
    ) {
        this._logger = loggerService.createLogger('EventStreamServiceClient');
    }

    private _logger: Logger;
    private _isConnected: boolean;
    private _essWorker: EtsWorker;
    private _unsubscriber: Subject<any>;

    get isConnected(): boolean {
        return this._isConnected;
    }

    init(): Promise<any> {
        if (!!this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

        this._unsubscriber = new Subject<any>();

        this._messageBus
            .of<AuthTokenExpiredUIMessage>('AuthTokenExpiredUIMessage')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(msg => setTimeout(() => this._onAuthTokenExpiredUIMessage(msg.payload), 0));

        this._messageBus
            .of<EventStreamConnectionChangedUIMessage>('EventStreamConnectionChangedUIMessage')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => (this._isConnected = x.payload.isConnected));

        return this._loadWorker().then(() => this._sendSessionDataToWorker());
    }

    private _sendSessionDataToWorker(): void {
        this._logger.info('Preparing session data for ess-worker');

        const authToken = this._sessionService.authToken;

        const shells = this._sessionService.sessionData.availableShells
            ? this._sessionService.sessionData.availableShells
            : [];

        const connectedShell = this._sessionService.connectedShell ? this._sessionService.connectedShell : null;

        const payload: EtsSessionData = {
            authToken,
            shells,
            connectedShell,
            applicationId: environment.runtimeAppId,
            eventStreamUrl: environment.eventStreamUrl
        };

        const message: WorkerMessage = {
            payload,
            messageType: 'session-data'
        };

        this._logger.info('Sending session data for ess-worker', message);

        this._sendMessageToWorker(message);
    }

    private _loadWorker(): Promise<void> {
        if (this._essWorker) {
            this._logger.info(`ESS worker already loaded. Skipping.`);
            return Promise.resolve(null);
        }

        const workerPath = `ess-worker.bundle.js`;
        const worker = new EtsWorker(workerPath);

        // to access service's context in event handler, where 'this' will be overriden
        const self = this;

        worker.onerror = (error: ErrorEvent) => {
            self._workerOnError(error);
        };

        worker.onmessage = (message: MessageEvent) => {
            self._workerOnMessage(message);
        };

        this._essWorker = worker;

        const promise = new Promise((res, rej) => {
            let timeout;
            const subs = this._messageBus.of<WorkerLoadedUIMessage>('WorkerLoadedUIMessage').subscribe(msg => {
                subs.unsubscribe();

                res({});

                if (timeout) {
                    clearTimeout(timeout);
                }
            });

            timeout = setTimeout(() => {
                rej('Load timeout');
                subs.unsubscribe();
            }, 7 * 1000);
        });

        return promise as any;
    }

    private _sendMessageToWorker(message: WorkerMessage): void {
        if (!this._essWorker) {
            throw new Error('Worker not loaded');
        }

        this._essWorker.postMessage(message);
    }

    private _workerOnMessage(message: MessageEvent): void {
        const envelope = message.data as CrossThreadMessage;
        switch (envelope.messageType) {
            case '[]': {
                const messages = envelope.payload as CrossThreadMessage[];
                for (const msg of messages) {
                    this._messageBus.publishAsync({
                        topic: msg.messageType,
                        payload: msg.payload
                    });
                }
                break;
            }
            default: {
                this._messageBus.publishAsync({
                    topic: envelope.messageType,
                    payload: envelope.payload
                });
                break;
            }
        }
    }

    private _workerOnError(error: ErrorEvent): void {
        this._logger.error('Worker error', error);
    }

    private _onAuthTokenExpiredUIMessage(msg: AuthTokenExpiredUIMessage): void {
        this._logger.info('Received message that auth. token is expired', msg);

        if (!this._essWorker) {
            this._logger.warn('"AuthTokenExpiredUIMessage" cannot be delivered to ESS worker. It is not loaded');
        }

        this._essWorker.postMessage({
            messageType: 'disconnect',
            payload: {shouldReconnect: false}
        });
    }
}
