import { NgModule } from '@angular/core';
import { AggregatedPositionsComponent } from './aggregated-positions.component';
import { AgGridModule } from 'ag-grid-angular';
import { AggregatedPositionsGroupRowInnerRenderer } from './aggregated-positions-group-row-inner-renderer.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CommonModule } from '@angular/common';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { AgGridContribModule } from '../unspecific/ag-grid-contrib/ag-grid-contrib.module';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      AgGridContribModule,
      AgGridModule.withComponents([
         AggregatedPositionsGroupRowInnerRenderer,
         SimpleHeaderGroupRowInnerRenderer
      ])
   ],
   exports: [
   ],
   declarations: [
      AggregatedPositionsComponent,
      ConfirmationDialogComponent,
      AggregatedPositionsGroupRowInnerRenderer,
   ],
   providers: []
})
export class AggregatedPositionsModule { }
