import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SimexSettingsDto} from '../../dtos/simex-settings-dto.class';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class SetSimexSettings implements ProtocolCommand {
  constructor(public readonly settings: SimexSettingsDto) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.SetSimexSettings`;
  }
}
