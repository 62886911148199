<dx-scroll-view height="100%" [showScrollbar]="'always'">

   <ng-container *ngIf="this.parameters.isSingleDirection; then single; else double"></ng-container>
   
</dx-scroll-view>

<ng-template #single>
   <div class="parameters">

      <!-- display name -->
      <div class="row-2" *ngIf="!this.parameters.manualMode">
         <div class="label">Display Name</div>
         <div class="input">
            <dx-text-box [(value)]="this.parameters.displayName"></dx-text-box>
         </div>
      </div>

      <!-- roll [x] days before expiration -->
      <div class="row-2" *ngIf="!this.parameters.manualMode">
         <div class="label">
            Roll [x] Days Before Expiration 
         </div>
         <div class="input">
            <dx-number-box [min]="0" 
                           [step]="1" 
                           [(value)]="this.parameters.rollDaysBeforeExpiration" 
                           format="0#d"></dx-number-box>
         </div>
      </div>

      <!-- roll time -->
      <div class="row-2" *ngIf="!this.parameters.manualMode && this.cpMode !== 'Automated' " >
         <div class="label">Roll Time & Mode</div>
         <div class="input">
            <div class="roll-mode">
               <p>Mode</p>
               <dx-select-box [items]="this.parameters.rollTimeModes"
                  [(value)]="this.parameters.rollTimeMode"></dx-select-box>
            </div>
            <br>
            <div class="roll-time">
               <p>Time</p>
               <ets-datetime-picker [contextWord]="this.parameters.rollTimeMode"
                                    [mode]="this.parameters.rollTimePickerMode"
                                    [(value)]="this.parameters.rollTime" [showTimezone]="false">
               </ets-datetime-picker>
               <div style="margin-top: 5px;" *ngIf="this.parameters.rollTimePickerShowTimezone">
                  <ets-timezone-picker
                           [(timezone)]="this.parameters.rollTimezone"
                           [flow]="'col'">
                  </ets-timezone-picker>
               </div>
            </div>
         </div>
      </div>

      <!-- epxiration to roll to offset -->
      <div class="row-2" *ngIf="!this.parameters.manualMode">
         <div class="label">Roll To Days To Expiration</div>
         <div class="input">
            <div class="expiration-offset-mode">
               <p>Mode</p>
               <dx-select-box [items]="this.parameters.getExpirationOffsetModes(this.cpMode)"
                  [(value)]="this.parameters.expirationOffsetMode"></dx-select-box>
            </div>
            <br>
            <div class="expiration-offset-value">
               <p>Value</p>
               <dx-number-box [(value)]="this.parameters.expirationOffsetValue"
                  [min]="1" [step]="1"
                  [format]="this.parameters.expirationValueOffsetFormat"></dx-number-box>
            </div>
            <br>
            <div class="expiration-offset-rolltobuffer">
               <p>Expiration Preference (if unavailble)</p>
               <dx-select-box [items]="['Near', 'Far']" [(value)]="this.parameters.expirationPreference"></dx-select-box>
            </div>
         </div>
      </div>

      <!-- expirations -->
      <div class="row-2" *ngIf="this.parameters.manualMode">
         <div class="label">
            Expiration
         </div>
         <div class="input">
            <dx-select-box [items]="this.parameters.expirations" displayExpr="dateWithDaysToExpiration"
                           [inputAttr]="this.parameters.manualModeSettings?.expiration?.expirationTicker === '@SPX' ? {'style': 'color: yellow'} : {}"
                           [showClearButton]="true"
                           [(value)]="this.parameters.manualModeSettings.expiration">
               <div *dxTemplate="let data of 'item'">
                  <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                     {{data?.dateWithDaysToExpiration}}
                  </span>
               </div>
            </dx-select-box>
         </div>
      </div>

      <!-- spread width -->
      <div class="row-2">
         <div class="label">Spread Width</div>
         <div class="input">
            <dx-number-box [(value)]="this.parameters.spreadWidth"
               format="$#0" [min]="1"></dx-number-box>
         </div>
      </div>

      <!-- ATM Offset -->
      <div class="row-2">
         <div class="label">ATM Offset</div>
         <div class="input">
            <dx-number-box [(value)]="this.parameters.atmOffset" [min]="0" format="$#0"></dx-number-box>
         </div>
      </div>
      
      <!-- ATM Neutral Zone -->
      <div class="row-2"  *ngIf="this.cpMode !== 'Automated'">
         <div class="label">ATM Neutral Zone</div>
         <div class="input">
            <dx-number-box [(value)]="this.parameters.atmNeutralZone" 
                           [min]="0" 
                           [step]="0.01" 
                           format="#0%"></dx-number-box>
         </div>
      </div>
      
      <!-- order type -->
      <div class="row-2"  *ngIf="this.cpMode !== 'Automated'">
         <div class="label">Order Type</div>
         <div class="input">
            <dx-select-box [items]="this.parameters.orderTypes"
                           displayExpr="text"
                           valueExpr="value"
                           [(value)]="this.parameters.orderType">
            </dx-select-box>
            
            <br>
            
            <!-- ctm -->
            <div *ngIf="this.parameters.orderType === 1" >

               <div class="row-2">
                     <div class="label">Limit Price</div>
                     <div class="input">
                        <dx-select-box [items]="['Bid', 'Mid', 'Ask']"
                                       [(value)]="this.parameters.autoLimitPrice">
                        </dx-select-box>
                     </div>
               </div>

               <div style="margin-top: -12px;">
                  <fieldset>
                     <legend>
                        <dx-check-box  #ctm 
                                       text="Convert To Market" 
                                       [(value)]="this.parameters.convertToMarket"
                                       (onValueChanged)="this.parameters.onChange()"
                                       >
                        </dx-check-box>
                     </legend>
                     
                     <ets-convert-to-market *ngIf="ctm.value" 
                                             [hideCalendar]="true"                        
                                             [settings]="this.parameters.convertToMarketSettings">
                     </ets-convert-to-market>
                  </fieldset>
               </div>
                  
            </div>
         </div>
      </div>
      
      <!-- FO settings -->
      <div class="row-2" *ngIf="this.parameters.manualMode">
         <div class="label">FO Settings</div>
         <div class="input">
            <fieldset>
               <legend>
                  <dx-check-box text="Future Time Settings" #fo [(value)]="this.parameters.isFutureOrder"></dx-check-box>
               </legend>
      
               <div class="fo-settings" *ngIf="this.parameters.manualModeSettings.isFutureOrder">

                  <div class="row">
                     
                     <div class="block">
                        <dx-select-box 
                                       [items]="['Place At', 'Place After']"
                                       [showClearButton]="true"
                                       [(value)]="this.parameters.manualModeSettings.futureTimeSettings.actionTimeMode"
                                       placholder="Place Mode" #place
                                       [disabled]="!fo.value"
                                       (onValueChanged)="this.parameters.onChange()"
                        ></dx-select-box>
                     </div>
                     
                     <div class="block">
                        <ets-datetime-picker
                                    [contextWord]="this.parameters.manualModeSettings.futureTimeSettings.actionTimeMode" 
                                    [disabled]="!place.value"
                                    [mode]="this.parameters.dateTimePickerModeForFutureSettings"
                                    [(value)]="this.parameters.manualModeSettings.futureTimeSettings.actionTime"
                                    (onValueChanged)="this.parameters.onChange()"
                        ></ets-datetime-picker>
                     </div>
   
                  </div>
            
                  <div class="input row" style="margin-top: 5px;" *ngIf="this.parameters.manualModeSettings.futureTimeSettings.actionTimeMode === 'Place At'">
                     <ets-timezone-picker
                              [(timezone)]="this.parameters.manualModeSettings.futureTimeSettings.timezone"
                              [flow]="'col'">
                     </ets-timezone-picker>
                  </div>

               </div>
            </fieldset>
         </div>
      </div>
   </div>

</ng-template>

<ng-template #double>

   <div class="parameters">

      <div class="common">
   
            <!-- ATM Offset -->
            <div class="row-2">
               <div class="label">ATM Offset</div>
               <div class="input">
                  <dx-number-box [(value)]="this.parameters.atmOffset" [min]="0" format="$#0"></dx-number-box>
               </div>
            </div>
            
            <!-- roll [x] days before expiration -->
            <div class="row-2" *ngIf="!this.parameters.manualMode">
               <div class="label">
                  Roll [x] Days Before Expiration 
               </div>
               <div class="input">
                  <dx-number-box [min]="0" 
                                 [step]="1" 
                                 [(value)]="this.parameters.rollDaysBeforeExpiration" 
                                 format="0#d"></dx-number-box>
               </div>
            </div>
   
            <!-- epxiration to roll to offset -->
            <div class="row-2" *ngIf="!this.parameters.manualMode">
               <div class="label">Roll To Days To Expiration</div>
               <div class="input">
                  <div class="expiration-offset-mode">
                     <p>Mode</p>
                     <dx-select-box [items]="this.parameters.getExpirationOffsetModes(this.cpMode)"
                        [(value)]="this.parameters.expirationOffsetMode"></dx-select-box>
                  </div>
                  <br>
                  <div class="expiration-offset-value">
                     <p>Value</p>
                     <dx-number-box [(value)]="this.parameters.expirationOffsetValue"
                        [min]="1" [step]="1"
                        [format]="this.parameters.expirationValueOffsetFormat"></dx-number-box>
                  </div>
                  <br>
                  <div class="expiration-offset-rolltobuffer">
                     <p>Expiration Preference (if unavailble)</p>
                     <dx-select-box [items]="['Near', 'Far']" [(value)]="this.parameters.expirationPreference"></dx-select-box>
                  </div>
               </div>
            </div>
   
      </div>

      <div class="individual">

         <fieldset class="calls">
            <legend>Calls</legend>
            <!-- spread width -->
            <div class="row-2">
               <div class="label">Spread Width</div>
               <div class="input">
                  <dx-number-box [(value)]="this.parameters.spreadWidth"
                     format="$#0" [min]="1"></dx-number-box>
               </div>
            </div>
         </fieldset>

         <fieldset class="puts">
            <legend>Puts</legend>
            <!-- spread width -->
            <div class="row-2">
               <div class="label">Spread Width</div>
               <div class="input">
                  <dx-number-box [(value)]="this.parameters.spreadWidth2"
                     format="$#0" [min]="1"></dx-number-box>
               </div>
            </div>
         </fieldset>

      </div>

   </div>

</ng-template>
