import { Injectable } from '@angular/core';
import { ApgPortfolio, DefaultApgPortfolioId, getDefaultPortflio } from '../model/ApgPortfolio';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { ServiceConfiguration } from './ServiceConfiguration';
import { isVoid } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';

const StorageKey = 'apg.portfolios';
const ComparisonStorageKeyLeft = 'cpg.portfolios.left';
const ComparisonStorageKeyRight = 'cpg.portfolios.right';

@Injectable()
export class CashFlowPortfoliosService {
   constructor(
      private _settingsStorage: SettingsStorageService
   ) {

   }

   //
   private _serviceConfig: ServiceConfiguration;

   //
   configure(cfg: ServiceConfiguration) {
      this._serviceConfig = cfg;
   }

   //
   getPortfolios(): ApgPortfolio[] {
      
      const key = this.makeStorageKey();

      const portfolios = this._settingsStorage
          .getItem<ApgPortfolio[]>(key, this._serviceConfig?.userId) || [];

      if (portfolios.findIndex(x => x.id === DefaultApgPortfolioId) < 0) {

         const defaultPf = getDefaultPortflio(
            this._serviceConfig.userId, 
            this._serviceConfig.userName
         );
         
         portfolios.unshift(defaultPf);

      }

      portfolios.sort((a,b) => a.name.localeCompare(b.name));

      return portfolios;
   }

   //
   savePortfolios(portfolios: ApgPortfolio[]) {
      const key = this.makeStorageKey();
      this._settingsStorage.setItem(key, portfolios, this._serviceConfig?.userId);
   }

   //
   private makeStorageKey(): string {

      console.assert(!isVoid(this._serviceConfig));

      let rootKey = StorageKey;

      if (this._serviceConfig.orientation === 'left') {
         rootKey = ComparisonStorageKeyLeft;
      } else if (this._serviceConfig.orientation === 'right') {
         rootKey = ComparisonStorageKeyRight;
      }

      // if (this._serviceConfig.userId) {
      //    rootKey += `${EtsConstants.storageKeys.userSeparator}${this._serviceConfig.userId}`;
      // }

      return rootKey;
   }
}