import {Injectable} from '@angular/core';
import {CashFlowAdjustmentLogicalId} from 'projects/shared-components/adjustment-control-panel/CashFlowAdjustmentId';
import {CashFlowStrategy} from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import {
    DaysOfWeekMap,
    EasternStandardTimezone,
    isValidNumber,
    isVoid,
    wrapInPromise
} from 'projects/shared-components/utils';
import {ChecklistStep} from './ChecklistStep';
import {ShellClientService} from 'projects/shared-components/shell-communication/shell-client.service';
import {
    GetChecklistStepsShell,
    RemoveChecklistStepShell,
    SaveChecklistStepsShell,
    ToggleChecklistStepStateShell,
    WithdrawAllChecklistStepsShell
} from 'projects/shared-components/shell-communication/shell-operations-protocol';
import {
    GetChecklistStepsShellReply,
    SaveChecklistStepShellReply
} from 'projects/shared-components/shell-communication/shell-dto-protocol';
import {CashFlowAdjustment} from '../../model/CashFlowAdjustment';
import {DateTime} from 'luxon';
import {
    SecondOption
} from 'projects/shared-components/checklist-editor/conditions/second-option/protective-options-condition.component';
import {ResourceButton} from "../../../resource-editor/resource-button.interface";
import {MessageBusService} from "../../../message-bus.service";
import {SessionService} from "../../../authentication/session-service.service";

@Injectable({providedIn: 'root'})
export class ApgChecklistService {

    constructor(
        private _shellClient: ShellClientService,
        private _messageBus: MessageBusService,
        private _sessionService: SessionService
    ) {
    }

    //

    async saveChecklistSteps(steps: ChecklistStep[]): Promise<void> {

        if (steps.some(x => !x.isValid())) {
            throw new Error('Some steps are not valid');
        }

        const dtos = steps.map(x => x.toDto());

        const qry = new SaveChecklistStepsShell(dtos);

        const reply = await this._shellClient.processQuery<SaveChecklistStepShellReply>(qry);

        reply.ids.forEach(kvp => {
            const step = steps.find(step => step.seqNo === kvp.item2);
            if (step) {
                step.checklistStepId = kvp.item1;
            }
        });
    }

    //

    async getChecklistStepsFiltered(
        pricing: CashFlowAdjustment,
        includeDrafts: boolean
    ): Promise<ChecklistStep[]> {

        const matchingSteps = await this.getChecklistStepsFull(
            pricing.cashFlowStrategy,
            pricing.logicalId,
            includeDrafts
        );

        if (isVoid(pricing)) {
            return matchingSteps;
        }

        const filtered = matchingSteps.filter(x => {

            const dteGreaterThanCondition = this.checkDteGreaterThanCondition(x, pricing);
            const dayOfWeekCondition = this.checkDayOfWeekCondition(x);
            const dayOfYearCondition = this.checkDayOfYearCondition(x);
            const dayOfYearExpirationCondition = this.checkDayOfExpirationCondition(x, pricing);
            const secondOptionCondition = this.checkProtectiveOptionsCondition(x, pricing);
            const dynamicOffsetsCondition = this.checkDynamicOffsetsCondition(x, pricing);
            const assetCondition = this.filterAsset(x);
            const userCondition = this.filterUsers(x);
            const hasExtaPos = this.filterExtraPos(x);

            return dteGreaterThanCondition
                && dayOfWeekCondition
                && dayOfYearCondition
                && dayOfYearExpirationCondition
                && secondOptionCondition
                && dynamicOffsetsCondition
                && assetCondition
                && userCondition
                && hasExtaPos;

        });

        // await wrapInPromise(() => {}, 1000);

        return filtered;

    }

    //

    private checkDynamicOffsetsCondition(x: ChecklistStep, pricing: CashFlowAdjustment): boolean {

        if (isVoid(x.dynamicOffsetsCondition)) {
            return true;
        }

        if (['Yes', 'No'].indexOf(x.dynamicOffsetsCondition) < 0) {
            return false;
        }

        const isDynamicOffsets = x.dynamicOffsetsCondition === 'Yes';

        return pricing.dynamicOffsets === isDynamicOffsets;
    }

    //

    private checkProtectiveOptionsCondition(x: ChecklistStep, pricing: CashFlowAdjustment): boolean {

        if (isVoid(x.protectiveOptionsCondition)) {
            return true;
        }

        const value = x.protectiveOptionsCondition;

        if (value.indexOf('::') < 0) {
            return false;
        }

        const parts = value.split(' :: ').map(x => x.trim());

        if (parts.length != 2) {
            return false;
        }

        const options = parts[0].split(', ') as SecondOption[];
        const structures = parts[1].split(', ');

        // let's define what type of structure we are dealing with
        const has1stPo = pricing.beforeState.findIndex(x => x.role === 'ProtectiveOption') >= 0;
        const has2ndPo = pricing.beforeState.findIndex(x => x.role === 'SecondProtectiveOption') >= 0;
        const has2ndSpread = pricing.beforeState.findIndex(x => x.role === 'SecondSpreadLongLeg') >= 0;

        let structure = '';

        if (!has2ndPo && !has2ndSpread) {
            structure = '#1';
        } else if (has2ndPo && !has2ndSpread) {
            structure = '#2';
        } else if (!has2ndPo && has2ndSpread) {
            structure = '#3';
        } else if (has2ndPo && has2ndSpread) {
            structure = '#4';
        }

        if (structures.indexOf(structure) < 0) {
            return false;
        }

        let need1stPo = options.indexOf('1st PO') >= 0;
        let need2ndPo = options.indexOf('2nd PO') >= 0;

        let shouldShow;

        if (need1stPo) {
            shouldShow = has1stPo;
        }

        if (need2ndPo) {
            shouldShow = has2ndPo;
        }

        return shouldShow;
    }

    //

    private checkDayOfExpirationCondition(x: ChecklistStep, pricing: CashFlowAdjustment): boolean {

        if (isVoid(x.dayOfExpirationCondition)) {
            return true;
        }

        const dayOfWeek = x.dayOfExpirationCondition;

        const expirationDt = DateTime.fromFormat(pricing.expiration, 'yyyy-MM-dd', {zone: EasternStandardTimezone});

        const expirationDayOfWeek = DaysOfWeekMap[expirationDt.weekday];

        return expirationDayOfWeek === dayOfWeek;

    }

    //

    private checkDayOfYearCondition(x: ChecklistStep): boolean {
        if (isVoid(x.dayOfYearCondition)) {
            return true;
        }

        if (x.dayOfYearCondition.indexOf('|') < 0) {
            return false;
        }

        const parts = x.dayOfYearCondition.split('|');

        if (parts.length < 3) {
            return false;
        }

        const month = parts[0];

        const day = parseInt(parts[1]);

        const timezone = parts[2];

        const zonedDt = DateTime.utc().setZone(timezone);

        const sameMonth = zonedDt.monthLong === month;

        if (!sameMonth) {
            return false;
        }

        const sameDay = zonedDt.day === day;

        if (!sameDay) {
            return false;
        }


        return sameDay && sameMonth;
    }

    //

    private checkDayOfWeekCondition(x: ChecklistStep): boolean {

        if (isVoid(x.dayOfWeekCondition)) {
            return true;
        }

        if (x.dayOfWeekCondition.indexOf('|') < 0) {
            return false;
        }

        const parts = x.dayOfWeekCondition.split('|');

        if (parts.length < 2) {
            return false;
        }

        const dayOfWeek = parts[0];

        const timezone = parts[1];

        const zonedDt = DateTime.utc().setZone(timezone);

        const zonedDayOfWeek = DaysOfWeekMap[zonedDt.weekday];

        return zonedDayOfWeek === dayOfWeek;

    }

    private checkDteGreaterThanCondition(x: ChecklistStep, pricing: CashFlowAdjustment): boolean {

        if (isVoid(x.dteGreaterThanCondition)) {
            return true;
        }

        const expirationDateWithDte = pricing.expirationDatePrettyWithDaysToExpiration;

        if (isVoid(expirationDateWithDte)) {
            return false;
        }

        const parts = expirationDateWithDte.split(' ');

        console.assert(parts.length === 2, 'parts.length === 2');

        const strDte = parts[1];

        const ix = strDte.indexOf('d)');
        const strNumOfDays = strDte.substring(1, ix);
        const dte = parseInt(strNumOfDays);

        console.assert(isValidNumber(dte), 'isValidNumber(dte)');

        return dte > x.dteGreaterThanCondition;
    }

    //
    async getChecklistStepsFull(
        strategy: CashFlowStrategy,
        adjustment: CashFlowAdjustmentLogicalId,
        includeDrafts: boolean
    ): Promise<ChecklistStep[]> {

        const qry = new GetChecklistStepsShell(
            strategy,
            adjustment,
            includeDrafts
        );

        const reply = await this._shellClient.processQuery<GetChecklistStepsShellReply>(qry);

        const dtos = reply.steps.map(dto => ChecklistStep.fromDto(dto));

        return dtos.sort(x => x.seqNo);
    }

    //
    async removeChecklistStep(step: ChecklistStep): Promise<void> {
        const cmd = new RemoveChecklistStepShell(
            step.checklistStepId
        );

        await this._shellClient.processCommand(cmd);
    }

    //
    async toggleChecklistDraftState(step: ChecklistStep): Promise<void> {
        const cmd = new ToggleChecklistStepStateShell(
            step.checklistStepId
        );
        await this._shellClient.processCommand(cmd);
    }

    //
    async withdrawChecklist(strategy: CashFlowStrategy, adjustment: CashFlowAdjustmentLogicalId): Promise<void> {

        const cmd = new WithdrawAllChecklistStepsShell(
            strategy,
            adjustment
        );

        await this._shellClient.processCommand(cmd);
    }

    private filterAsset(btn: ChecklistStep): boolean {
        if (isVoid(btn.assetCondition)) {
            return true;
        }

        const payload: { asset?: string } = {};
        this._messageBus.publish({
            topic: 'Resources.Asset',
            payload
        });

        return payload.asset === btn.assetCondition;
    }

    private filterExtraPos(btn: ChecklistStep): boolean {

        const payload: { hasExtraPos?: boolean } = {};

        this._messageBus.publish({
            topic: 'Resources.HasExtraPos',
            payload
        });

        if (btn.hasExtraPosCondition) {
            return payload.hasExtraPos || false;
        }

        return true;
    }

    private filterUsers(btn: ChecklistStep): boolean {

        if (isVoid(btn.userCondition)) {
            return true;
        }

        const parts = btn.userCondition.split('|');

        if (parts.length === 0) {
            return true;
        }

        const available = parts.some(x => x === this._sessionService.sessionData.userId);

        return available;
    }
}
