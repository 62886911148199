import { NgModule } from '@angular/core';
import { EtsPnLChartDialogComponent } from './pnl-chart-dialog/pnl-chart-dialog.component';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { PnLChart2Component } from './pnl-chart-2/pnl-chart-2.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
  imports: [
    CommonModule,
    EtsSymbolPickerModule,
    DevExtremeModule
  ],
  exports: [
    EtsPnLChartDialogComponent, 
    // PnLChartComponent, 
    PnLChart2Component
  ],
  declarations: [
    // PnLChartComponent, 
    PnLChart2Component, 
    EtsPnLChartDialogComponent
  ],
  providers: []
})
export class EtsPnLChartModule { }
