import {NgModule} from '@angular/core';
import {WorkspaceModule} from '../../../../shared-components/workspace/workspace.module';
import {EtsTradingPadModule} from '../trading-pad/trading-pad.module';
import {EtsGatewayManagerModule} from '../gateways/gateway-manager.module';
import {EtsTerminalExitModule} from '../terminal-exit/terminal-exit.module';
import {
    TotalStrategyIssuesModule
} from '../../../../shared-components/total-strategy-issues/total-strategy-issues.module';
import {WebtraderHostMenuModule} from '../host-menu/host-menu.module';
import {WebtraderHostComponent} from './host.component';
import {ServerTimePanelComponent} from './server-time-panel/server-time-panel.component';
import {PnlBoxComponent} from './pnl-box/pnl-box.component';
import {CommonModule} from '@angular/common';
import {LayoutModule} from '../../../../shared-components/layout/layout.module';
import {UnaccountedDataModule} from '../unaccounted-data/unaccounted-data.module';
import {AngularSplitModule} from 'angular-split';
import {DevExtremeModule} from 'projects/shared-components/devextreme.module';
import {JobsTrackerModule} from 'projects/shared-components/jobs-tracker/jobs-tracker.module';
import {EtsShellMessagesModule} from 'projects/shared-components/shell-messages/shell-messages.module';
import {ResourceEditorModule} from "../../../../shared-components/resource-editor/resource-editor.module";
import {LastPriceModule} from "../../../../shared-components/last-price/last-price.module";
import {OptionsPricingGridModule} from "../../../../shared-components/options-pricing-grid/options-pricing-grid.module";
import {PivotPointsPopupModule} from "../../../../shared-components/pivot-points-popup/pivot-points-popup.module";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule,
        LayoutModule,
        AngularSplitModule,
        WorkspaceModule,
        EtsTradingPadModule,
        EtsGatewayManagerModule,
        EtsTerminalExitModule,
        EtsShellMessagesModule,
        WebtraderHostMenuModule,
        TotalStrategyIssuesModule,
        UnaccountedDataModule,
        JobsTrackerModule,
        ResourceEditorModule,
        LastPriceModule,
        OptionsPricingGridModule,
        PivotPointsPopupModule
    ],

    declarations: [
        WebtraderHostComponent,
        ServerTimePanelComponent,
        PnlBoxComponent
    ]
})
export class WebtraderHostModule {
}
