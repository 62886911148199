import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';

import { TimezonePickerComponent } from './timezone-picker.component';

@NgModule({
   imports: [
      DevExtremeModule,
      CommonModule
   ],
   exports: [TimezonePickerComponent],
   declarations: [TimezonePickerComponent],
   providers: [],
})
export class TimezonePickerModule { }
