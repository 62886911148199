import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import {DetectMethodChanges, isVoid} from '../utils';

@Component({
   selector: 'ets-generic-confirmation-dialog',
   template: `
<dx-popup   class="popup"
      [width]="400"
      [height]="210"
      [showTitle]="true"
      [title]="this.title"
      [closeOnOutsideClick]="false"
      [resizeEnabled]="true"
      [dragEnabled]="true"
      [visible]="this.visible"
      [showCloseButton]="false"
            (onInitialized)="this.onInitialized($event)"
>
   <div *dxTemplate="let data of 'content'" class="wrapper">
      <div class="message">
            <p *ngFor="let line of this.text">
               {{line}}
            </p>
      </div>
      <div class="buttons">
            <dx-button  
                  [text]="this.confirmText"
                  (onClick)="this.onConfirm()"
                  width="100"
                  type="success"
            ></dx-button>
            <dx-button  
                  [text]="this.rejectText"
                  (onClick)="this.onReject()"
                  width="100"
                  type="danger"
            ></dx-button>
      </div>
   </div>
</dx-popup>
   `,
   styles: [`       
      .wrapper {
         display: flex;
         flex-direction: column;
         padding: 15px;
      }
      .message {
         flex: 1;
         font-size: 1.5em;
         text-align: center;
      }
      .buttons {
         display: flex;
         justify-content: space-around;
         align-items: center;
      }
   `],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericConfirmationDialogComponent implements OnInit, AfterViewInit {

   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   private _onReject: (reason?: any) => void;

   private _onResolve: (value?: (PromiseLike<void> | void)) => void;

   @Input()
   visible: boolean;

   @Input()
   title: string;

   @Input()
   text: string[];

   @Input()
   confirmText = "Yes";
   
   @Input()
   rejectText = "No";

   ngOnInit(): void {

   }

   ngAfterViewInit(): void {
      //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
      //Add 'implements AfterViewInit' to the class.
      this._changeDetector.detach();
   }

   @DetectMethodChanges()
   show(text?: string[]): Promise<void> {

      if (!isVoid(text)) {
         this.text = text;
      }

      this.visible = true;

      return new Promise( (res, rej) => {
         this._onReject = rej;
         this._onResolve = res;
      });

   }

   @DetectMethodChanges()
   onConfirm() {
      this._onResolve();
      this.visible = false;
   }

   @DetectMethodChanges()
   onReject() {
      this._onReject();
      this.visible = false;
   }

   onInitialized($event: any) {
      $event?.component?.registerKeyHandler('escape', (args) => args?.stopPropagation());
   }
}
