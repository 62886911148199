import { CashFlowStrategy } from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";

export const DefaultApgPortfolioId = '----';

export interface ApgPortfolio {
   id: string;
   name: string;
   asset?: string;
   strategy?: CashFlowStrategy;
   userId?: string;
   userName?: string;
}

export function getDefaultPortflio(userId: string, userName: string, defaults?: {asset?: string, strategy?: CashFlowStrategy}): ApgPortfolio {
   return {
      id: DefaultApgPortfolioId,
      name: '<No Portfolio>',
      userId,
      userName,
      asset: defaults ? defaults.asset : undefined,
      strategy: defaults ? defaults.strategy : undefined
   };
}