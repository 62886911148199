import {NgModule} from '@angular/core';

import {ColorPickerComponent} from './color-picker.component';
import {DevExtremeModule} from "../devextreme.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule
    ],
    exports: [
        ColorPickerComponent
    ],
    declarations: [ColorPickerComponent],
    providers: [],
})
export class ColorPickerModule {
}
