import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { EngineStrategyAddedDto } from 'projects/shared-components/shell-communication/dtos/engine-strategy-added-dto.class';
import { EngineStrategyRemovedDto } from 'projects/shared-components/shell-communication/dtos/engine-strategy-removed-dto.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { StrategyStateDto } from 'projects/shared-components/shell-communication/dtos/strategy-state-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, GetStrategyControlsSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DeleteCashFlowAutoAdjustmentStrategy, DisableCashFlowAdjustmentStrategy, EnableCashFlowAdjustmentStrategy, GetStrategyControlsSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { StrategyState } from 'projects/shared-components/strategies/strategy-state.enum';
import { DetectMethodChanges, isVoid } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { AutomationCpMessageBusService } from '../services/automation-cp-message-bus.service';
import { StrategyParametersUpdatedDto } from 'projects/shared-components/shell-communication/dtos/strategy-parameters-updated-dto.class';

@Component({
   selector: 'ets-automationcp-adj-strategy-controls',
   templateUrl: './adjustment-strategy-controls.component.html',
   styleUrls: ['./adjustment-strategy-controls.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdjustmentStrategyControlsComponent implements OnInit, OnDestroy {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _strategy: StrategyDto;

   private _unsubscriber = new Subject();

   @Input() panelId: string;


   private _combo : ComboDto;
   get combo() : ComboDto {
      return this._combo;
   }
   @Input()
   set combo(v : ComboDto) {
      this._combo = v;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   @Output() 
   onCreateStrategy = new EventEmitter();

   //
   @Output() 
   onUpdateStrategy = new EventEmitter<string>();

   //
   get hasStrategy(): boolean {
      return !isVoid(this._strategy);
   }

   //
   get isComboSelected(): boolean {
      return !isVoid(this.combo);
   }

   //
   get showCreateButton(): boolean {
      return !this.hasStrategy && this.isComboSelected;
   }

   //
   get showManageButtons(): boolean {
      return this.hasStrategy && this.isComboSelected;
   }

   //
   get backgroundColor(): string {
      
      if (!this._strategy) {
         return 'blue';
      }

      if (this._strategy.state === StrategyState.Inactive) {
         return 'dimgray'
      };

      if (this._strategy.state === StrategyState.Active) {
         return 'green'
      };

      if (this._strategy.state === StrategyState.Dead) {
         return 'red';
      }
   }

   //
   get canUpdateStrategy(): boolean {
      return this.hasStrategy && this._strategy.state == StrategyState.Inactive;
   }

   //
   get canEnableStrategy(): boolean{
      return this.canUpdateStrategy;
   }

   //
   get canDeleteStrategy(): boolean {
      return this.canUpdateStrategy;
   }

   //
   get canDisableStrategy(): boolean {
      return !this.canUpdateStrategy;
   }

   //
   get showStatusBar(): boolean {
      return this.showManageButtons || this.showCreateButton;
   }

   //
   get labelText(): string {
      if (!this.hasStrategy) {
         return 'No Strategy';
      }

      if (this._strategy.state == StrategyState.Active) {
         return 'Enabled';
      }

      if (this._strategy.state == StrategyState.Inactive) {
         return 'Disabled';
      }

      return 'Status Unknown';
   }

   //
   ngOnInit(): void {
      this._messageBus.of<EngineStrategyAddedDto>('EngineStrategyAddedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         ) 
         .subscribe(msg => this.onStrategyCreated(msg.payload));

      this._messageBus.of<EngineStrategyRemovedDto>('EngineStrategyRemovedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         ) 
         .subscribe(msg => this.onStrategyRemoved(msg.payload));

      this._messageBus.of<StrategyStateDto[]>('StrategyStateDto')
         .pipe(
            takeUntil(this._unsubscriber)
         ) 
         .subscribe(msg => this.onStrategyStateChanged(msg.payload));

      this._messageBus.of<StrategyParametersUpdatedDto>('StrategyParametersUpdatedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         ) 
         .subscribe(msg => this.onStrategyParametersUpdated(msg.payload));
   }


   private onStrategyParametersUpdated(payload: StrategyParametersUpdatedDto): void {
      if (!this._strategy) {
         return;
      }

      if (this._strategy.strategyId !== payload.strategy.strategyId) {
         return;
      } 

      this._cpMessageBus.publish('AdjustmentParametersUpdated');
   }

   //
   ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }
   
   //
   onCreateStrategyClicked() {
      this.onCreateStrategy.emit();
   }

   //
   onUpdateStrategyClicked() {
      
      if (isVoid(this._strategy)) {
         this._toastr.error('Cannot detect adjustment strategy');
         return;
      }

      this.onUpdateStrategy.emit(this._strategy.strategyId);

   }

   //
   @DetectMethodChanges()
   onStrategyCreated(dto: EngineStrategyAddedDto) {
      if (!this.combo) {
         return;
      }

      if (this.combo.comboId !== dto.strategy.comboId) {
         return;
      }

      if (dto.strategy.algoId !== EtsConstants.algorithms.adjustment.cashFlow.cashFlowAutoAdjustmentId) {
         return;
      }

      this._strategy = dto.strategy;

      this._cpMessageBus.publish('AdjustmentStrategyCreated', {strategy: this._strategy});
   }

   //
   @DetectMethodChanges()
   onStrategyRemoved(dto: EngineStrategyRemovedDto) {
      
      if (!this._strategy) {
         return;
      }

      const ix = dto.strategies.findIndex(x => x.strategyId === this._strategy.strategyId);
      
      if (ix < 0 ) {
         return;
      }

      const strategy = this._strategy;

      this._strategy = null;

      setTimeout(() => {
         this._cpMessageBus.publish('AdjustmentStrategyRemoved', {strategy});
      }, 0);
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onComboSelected(): Promise<void> {

      if (isVoid(this.combo)) {
         return;
      }

      try {

         const qry = new GetStrategyControlsSectionDataModel(this.combo.comboId);
         const reply = await this._shellClient.processQuery<GetStrategyControlsSectionDataModelReply>(qry);
         this._strategy = reply.strategy;
         this._cpMessageBus.publish('AdjustmentStrategyLoaded', {strategy: this._strategy});

      } catch(e) {
         console.error(e);
         this._toastr.error('"Load Adjustment Strategy" operation completed with arrors', 'Automation CP')  
      }
      
   }

   //
   onStrategyStateChanged(dtos: StrategyStateDto[]) {
      if (!this._strategy) {
         return;
      }

      const dto = dtos.find(x => x.strategyId === this._strategy.strategyId );

      if (isVoid(dto)) {
         return;
      }

      this._strategy.state = dto.state;

      this._changeDetector.detectChanges();
   }

   //
   async onEnableStrategyClicked(): Promise<void> {
      if (!this._strategy) {
         return;
      }

      const cmd = new EnableCashFlowAdjustmentStrategy(this._strategy.strategyId);

      await this._shellClient.processCommand(cmd);
   }

   //
   async onDisableStrategyClicked(): Promise<void> {
      if (!this._strategy) {
         return;
      }

      const cmd = new DisableCashFlowAdjustmentStrategy(this._strategy.strategyId);

      await this._shellClient.processCommand(cmd);
   }

   @DetectMethodChanges({isAsync: true})
   async onDeleteStrategyClicked(): Promise<void> {
      
      this._cpMessageBus.publish('Loading', true);
      await delay(250);

      try {

         const cmd = new DeleteCashFlowAutoAdjustmentStrategy(this.combo.comboId);
         await this._shellClient.processCommand(cmd);

      } finally {
         this._cpMessageBus.publish('Loading', false);
      }
      if (!this.combo){
         return;
      }
   }
}
