<div class="root-container parameters">
  
  <!-- Templates -->
  <div class="parameter long">
    <p class="part label">Settings Template</p>
    <div class="part input">
      <dx-select-box
        (onValueChanged)="this.viewModel.onParameterChanged($event, 'selectedTemplate')"
        [(value)]="this.viewModel.selectedTemplate"
        [displayExpr]="'templateName'"
        [items]="this.viewModel.strategyTemplates"
      >
        <div *dxTemplate="let data of 'item'" class="template">
          <p [ngClass]="{'shared-template': data?.isShared}">{{ data?.templateName }}</p>
        </div>
      </dx-select-box>
    </div>
  </div>
  
  <div *ngIf="this.viewModel.selectedTemplate" class="parameter long time">
    <ets-market-time-component></ets-market-time-component>
  </div>
  
  <ng-container *ngIf="this.viewModel.selectedTemplate">
    <!-- Underlying -->
    <div class="parameter">
      
      <p class="part label">
        <span>Underlying</span>
      </p>
      
      <div class="part input">
        <ets-symbol-picker
          (instrumentSelected)="this.viewModel.onParameterChanged({ value: $event, event: 'ets' }, 'tradingInstrument')"
          [disabled]="this.viewModel.isReadOnlyTemplate || !this.viewModel.canChangeSymbol"
          [selectedInstrument]="this.viewModel.tradingInstrument">
        </ets-symbol-picker>
      </div>
    
    </div>
    
    <!-- Strategy -->
    <div class="parameter">
      <p class="part label">Strategy</p>
      <div class="part input">
        <dx-select-box
          (onValueChanged)="this.viewModel.onParameterChanged($event, 'selectedStrategy')"
          [(value)]="this.viewModel.selectedStrategy"
          [disabled]="this.viewModel.isReadOnlyTemplate || !this.viewModel.canChangeStrategy"
          [items]="this.viewModel.availableStrategies"
        >
        </dx-select-box>
      </div>
    </div>
    
    <!--    ATM -->
    <div *ngIf="this.viewModel.isOverrideAtmAvailable" class="parameter atm">
      
      <p class="part label">ATM</p>
      <div class="part input">
        <dx-number-box (onValueChanged)="this.viewModel.onParameterChanged($event, 'overrideAtm')"
                       [(value)]="this.viewModel.overrideAtm"
                       [min]="0"
                       [showClearButton]="true"
                       [showSpinButtons]="true"
                       [step]="this.viewModel.underlying === 'SPX' ? 5 : 1"
                       [inputAttr]="{style: this.viewModel.overrideAtmStyle }"
        ></dx-number-box>
      </div>
    </div>
    
    <div class="parameter checkboxes">
      
      <div class="col">
        
        <div [class.different]="this.viewModel.isDifferentFromTemplate('priceToDestination')"
             class="checkbox"
        >
          <dx-check-box (onValueChanged)="this.viewModel.onParameterChanged($event, 'priceToDestination')"
                        [(value)]="this.viewModel.priceToDestination"
                        [disabled]="this.viewModel.isReadOnlyTemplate"
                        text="Price To Destination">
          </dx-check-box>
        </div>
        
        <div [class.different]="this.viewModel.isDifferentFromTemplate('priceToOpen')"
             class="checkbox"
        >
          <dx-check-box
            (onValueChanged)="this.viewModel.onParameterChanged($event, 'priceToOpen')"
            [(value)]="this.viewModel.priceToOpen"
            [disabled]="this.viewModel.isReadOnlyTemplate"
            text="Price To Open">
          </dx-check-box>
        </div>
      
      </div>
      
      <div class="col">
        <div [class.different]="this.viewModel.isDifferentFromTemplate('isStrategyAdvancedMode')"
             class="checkbox"
        >
          <dx-check-box
            (onValueChanged)="this.viewModel.onParameterChanged($event, 'isStrategyAdvancedMode')"
            [(value)]="this.viewModel.isStrategyAdvancedMode"
            [disabled]="this.viewModel.isReadOnlyTemplate"
            text="Advanced Pricing"
          >
          </dx-check-box>
        </div>
        
        <div *ngIf="this.viewModel.isDynamicOffsetsAvailable"
             [class.different]="this.viewModel.isDifferentFromTemplate('isDynamicOffsets')"
             class="checkbox"
        >
          <dx-check-box
            (onValueChanged)="this.viewModel.onParameterChanged($event, 'isDynamicOffsets')"
            [(value)]="this.viewModel.isDynamicOffsets"
            [disabled]="this.viewModel.isReadOnlyTemplate"
            text="Dynamic Offsets"
          >
          </dx-check-box>
        </div>
        
        
        <div *ngIf="this.viewModel.isAdjustToQtyAvailable" class="checkbox parameter adjust-to-qty">
          
          <p class="part label ">
            <dx-check-box [(value)]="this.viewModel.useAdjustToQty"
                          text="Adjust To Qty">
            </dx-check-box>
          </p>
          
          <div class="part input">
            <dx-number-box
              (onValueChanged)="this.viewModel.onParameterChanged($event, 'adjustToQty')"
              [(value)]="this.viewModel.adjustToQty"
              [disabled]="!this.viewModel.useAdjustToQty"
              [min]="0"
            >
            </dx-number-box>
          </div>
        </div>
      
      </div>
    
    </div>
    
    <hr class="separator" *ngIf="this.viewModel.isSuperUser" />
    
    <fieldset class="theo" *ngIf="this.viewModel.isSuperUser">
      <legend class="header">
        <div>
          <dx-check-box
            [(value)]="this.viewModel.useTheoreticalPrices"
            (onValueChanged)="this.viewModel.onParameterChanged($event, 'useTheoreticalPrices')"
            text="Use Theoretical Prices"
          >
          </dx-check-box>
        </div>
      </legend>
      
      <div class="settings">
        <div class="setting">
          <div class="label">Target</div>
          <div class="input">
            <dx-select-box
              [items]="['All', 'Price Box', 'Zones Grid']"
              [disabled]="!this.viewModel.useTheoreticalPrices"
              [(value)]="this.viewModel.theoreticalPriceTarget"
              (onValueChanged)="this.viewModel.onParameterChanged($event, 'theoreticalPriceTarget')"
            >
            </dx-select-box>
          </div>
        </div>
        
        <div class="setting">
          <div class="label">Mode</div>
          <div class="input">
            <dx-select-box
              [items]="['Fill Blanks', 'All Theoretical']"
              [disabled]="!this.viewModel.useTheoreticalPrices"
              [(value)]="this.viewModel.theoreticalPriceMode"
              (onValueChanged)="this.viewModel.onParameterChanged($event, 'theoreticalPriceMode')"
            >
            </dx-select-box>
          </div>
        </div>
        
        <div class="setting">
          <div class="label">Custom IV (%)</div>
          <div class="input">
            <dx-number-box
              [min]="1"
              [showClearButton]="true"
              [disabled]="!this.viewModel.useTheoreticalPrices"
              [(value)]="this.viewModel.theoreticalPriceIv"
              (onValueChanged)="this.viewModel.onParameterChanged($event, 'theoreticalPriceIv')"
            >
            </dx-number-box>
<!--            <div class="specific-iv">-->
<!--              <dx-button-->
<!--                text="Specific IV"-->
<!--                [disabled]="!this.viewModel.useTheoreticalPrices"-->
<!--                (onClick)="this.viewModel.showSpecificIv()"-->
<!--              >-->
<!--              </dx-button>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </fieldset>
  
  
  </ng-container>

</div>

<dx-popup [width]="400"
          [height]="200"
          title="Log-out Dialog"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="false"
          [visible]="this.viewModel.specificIvVisible"
          (onHidden)="this.viewModel.specificIvHidden()">
  <div *dxTemplate="let data of 'content'" class="specific-iv-container">
    <ng-container *ngFor="let item of this.viewModel.specificIvExpirations">
      <div class="specific-iv">
        <div class="expiration">
          {{item.expiration}}
        </div>
        <div class="role">
          {{item.role}}
        </div>
        <div class="iv">
          <dx-number-box></dx-number-box>
        </div>
      </div>
    </ng-container>
    
    <div class="buttons">
      <div class="btn">
        <dx-button text="Save"></dx-button>
      </div>
      <div class="btn">
        <dx-button text="Close"></dx-button>
      </div>
    </div>
    
  </div>
</dx-popup>
