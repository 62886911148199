import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { ToastrService } from 'ngx-toastr';
import { PnLChartType } from './pnl-chart-type';
import { SymbolPickerTreeNode, SymbolPickerTreeService } from '../../symbol-picker/symbol-picker-tree.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { StrategiesService } from '../../strategies/strategies.service';
import { ChartItemsSelectedEventArgs } from './chart-items-selected-event-args';
import { GetAccounts } from 'projects/shared-components/shell-communication/operations/accounts/get-accounts.class';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';

export const PNL_CHART_DIALOG_TITLE = 'Pick Chart Source';

interface IDxTreeNode {
  selected: boolean;
  itemData: SymbolPickerTreeNode;
  children: IDxTreeNode[];
}

@Component({
  selector: 'ets-pnl-chart-dialog',
  templateUrl: './pnl-chart-dialog.component.html',
  styleUrls: ['./pnl-chart-dialog.component.scss']
})
export class EtsPnLChartDialogComponent implements OnInit, OnDestroy {
  public constructor(
    private _symbolTreeService: SymbolPickerTreeService,
    private _shellClient: ShellClientService,
    private _toastr: ToastrService,
    private _strategiesService: StrategiesService,
    private _sessionService: SessionService,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger('EtsPnLChartDialogComponent');
  }

  public get selectedChartType(): PnLChartType {
    return this._selectedChartType;
  }

  public set selectedChartType(v: PnLChartType) {
    this._selectedChartType = v;
    if (v === 'Comparison') {
      this.chartTitle = null;
    }
  }

  public get isSelectionValid(): boolean {

    if (!this.selectedDataType) {
      return false;
    }

    if (this.selectedChartType === 'Comparison') {
      return this._selectedNodes.length > 0;
    } else if (this.selectedChartType === 'Combined') {
      return this.chartTitle && this._selectedNodes.length > 0;
    }
    return false;
  }

  public isLoading: boolean;

  public treeItems: SymbolPickerTreeNode[];

  @ViewChild(DxPopupComponent, { static: true })
  public popup: DxPopupComponent;

  public readonly popupTitle = PNL_CHART_DIALOG_TITLE;

  public readonly chartNamePlaceholder = 'Give chart a name...';

  public readonly chartTypePlaceholder = 'Select chart type...';

  public chartTitle: string;

  @Output()
  public readonly chartItemSelected = new EventEmitter<ChartItemsSelectedEventArgs>();

  public readonly chartTypeItems: PnLChartType[] = ['Combined', 'Comparison'];

  public selectedDataType: 'Session' | 'Accumulated';

  @Output()
  public closed = new EventEmitter();

  private _selectedChartType: PnLChartType;

  private _logger: Logger;

  private _selectedNodes: any[] = [];

  public onSelectionChanged(args): void {
    const value: IDxTreeNode = args.node;

    const leafNodes = this.getLeafNodes(value);

    if (leafNodes.length === 0) {
      return;
    }

    leafNodes.forEach(item => {
      if (item.selected) {
        this._selectedNodes.push(item);
        if (!this.chartTitle) {
          if (this.selectedChartType === 'Combined') {
            this.chartTitle = item.itemData.header;
          }
        }
      } else {
        const ix = this._selectedNodes.indexOf(item);
        if (ix >= 0) {
          this._selectedNodes.splice(ix, 1);
        }
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this._logger.debug('+');
    this.treeItems = [];
    this._selectedNodes.length = 0;
    this.isLoading = true;

    try {
      await this.setupPopup();
    } catch (e) {
      // should empty tree if something was added
      this.treeItems = [];

      // avoiding 'ExpressionChangedAfterItChecked' angular error
      setTimeout(
        () => this._toastr.error('"PnL Chart Dialog" loaded with errors'),
        1
      );
      const data = { error: e instanceof Error ? e.stack : e };
      this._logger.error('ngOnInit()', data);
    } finally {
      this.isLoading = false;
    }

    this.show();
  }

  public async show(): Promise<void> {
    this.popup.visible = true;
  }

  public createChart(shouldCloseAfter: boolean): void {
    this.chartItemSelected.emit({
      items: this._selectedNodes.map(x => x.itemData),
      chartType: this.selectedChartType,
      chartTitle: this.chartTitle,
      isAccumulated: this.selectedDataType === 'Accumulated'
    });

    if (shouldCloseAfter) {
      this.close();
    }
  }

  public close() {
    this.popup.visible = false;
    this.closed.emit();
  }

  public ngOnDestroy(): void {
    this._logger.debug('~');
  }

  private async setupPopup() {
    await this.addAccounts(this._shellClient, this.treeItems);
    this.addTerminals(this.treeItems, this._sessionService);
    this.addTradingInstruments(this._symbolTreeService, this.treeItems);
    this.addStrategies(this._strategiesService);
  }

  private addStrategies(strategiesService: StrategiesService): void {
    const allStrategies = strategiesService.getAllStrategies() || [];
    if (allStrategies.length > 0) {
      const nodes = this.makeStrategiesNodes(allStrategies);
      if (nodes.length > 0) {
        this.treeItems.push({
          id: 'Strategyies',
          header: 'Strategies',
          children: nodes
        });
      }
    }
  }

  private addTradingInstruments(
    symbolTreeService: SymbolPickerTreeService,
    treeItems: SymbolPickerTreeNode[]
  ): void {
    const tree = symbolTreeService.getTree() || [];
    if (tree.length > 0) {
      treeItems.push({
        id: 'Trading Instruments',
        header: 'Trading Instruments',
        children: tree
      });
    }
  }

  private addTerminals(tree: SymbolPickerTreeNode[], sessionService: SessionService) {
    const terminals = sessionService.loginResult.availableTerminals.filter(
      x => !x.isProxy
    );
    if (terminals.length > 0) {
      const nodes = this.makeTerminalNodes(terminals);
      if (terminals.length > 0) {
        tree.push({
          id: 'Terminals',
          header: 'Terminals',
          children: nodes
        });
      }
    }
  }

  private async addAccounts(
    shellClient: ShellClientService,
    tree: SymbolPickerTreeNode[]
  ) {
    const qry = new GetAccounts();
    const dtos = (await shellClient.processQuery<AccountDto[]>(qry)) || [];
    if (dtos.length > 0) {
      const accounts = this.makeAccountNodes(dtos);
      tree.push({
        id: 'Accounts',
        header: 'Accounts',
        children: accounts
      });
    }
  }

  private makeStrategiesNodes(strategies: StrategyModel[]): SymbolPickerTreeNode[] {
    const strats = strategies.map(
      strat =>
        (
          {
            // tslint:disable-next-line: no-unused-expression
            id: strat.strategyId,
            header: `${strat.displayName}@${strat.terminalCode}`,
            dataObject: { strategyId: strat.strategyId, displayName: strat.displayName },
            categoryLabel: 'strategy'
          } as SymbolPickerTreeNode
        )
    );
    return strats;
  }

  private makeTerminalNodes(terminals: TerminalDto[]): SymbolPickerTreeNode[] {
    const nodes = terminals.filter(x => !x.isProxy).map(
      term =>
        ({
          id: term.terminalId,
          header: term.displayName,
          dataObject: { terminalId: term.terminalId, displayName: term.displayName },
          categoryLabel: 'terminal'
        } as SymbolPickerTreeNode
        ));
    return nodes;
  }

  private makeAccountNodes(accounts: AccountDto[]) {
    const nodes = accounts.map(
      dto =>
        ({
          id: dto.accountId,
          header: dto.accountCode,
          dataObject: { accountId: dto.accountId, accountCode: dto.accountCode },
          categoryLabel: 'account'
        } as SymbolPickerTreeNode
        ));
    return nodes;
  }

  private getLeafNodes(value: IDxTreeNode): IDxTreeNode[] {
    const retVal: IDxTreeNode[] = [];
    if (this.isLeafNode(value)) {
      retVal.push(value);
    } else {
      value.children.forEach(kidNode => {
        const kids = this.getLeafNodes(kidNode);
        retVal.push(...kids);
      });
    }
    return retVal;
  }

  private isLeafNode(value): boolean {
    return !(value.children && value.children.length > 0);
  }
}
