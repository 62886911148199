<div class="container" style="position: relative"
     [hidden]="totalIssuesCount === 0">

  <div class="icon">
    <img src="assets/strategy_issues.png" style="width: 27px; height: 25px;">
  </div>

  <div class="issues-count" style="position: absolute;top: 3px;left: 28px;font-size: 120%;">
    <span>{{totalIssuesCount}}</span>
  </div>

</div>
