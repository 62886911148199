import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';

import { StrikesDropdownComponent } from './strikes-dropdown/strikes-dropdown.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule
   ],
   exports: [StrikesDropdownComponent],
   declarations: [StrikesDropdownComponent],
   providers: [],
})
export class OptionsCommonModule { }
