export class AccountDto {
  accountId: string;
  shellId: string;
  accountCode: string;
  broker: string;
  description: string;
  gatewayName: string;
  gatewayId?: string;
  isPaperTrading: boolean;
}
