<dx-popup [(visible)]="isVisible" [position]="position" width="280" height="160" [dragEnabled]="true"
   [resizeEnabled]="true" [shading]="true" title="Adjust Position" (onHidden)="this.onHidden()">

   <div *dxTemplate="let data of 'content'">
      <div class="content">
         
         <div class="row">
            {{row?.symbol}}
         </div>

         <div class="row">

            <div class="cell">
               <dx-button  [type]="'normal'" [text]="'Adjust To'" [width]="100" [disabled]="!canAdjustPosition"
                           (onClick)="adjustPosition()" [visible]="securityContext.adjustPosition">
                  <div *dxTemplate="let data of 'content'">
                     <span class="dx-button-text adjust" *ngIf="isTradeConfirmationPending"><strong>....</strong></span>
                     <span class="dx-button-text adjust" *ngIf="!isTradeConfirmationPending">Adjust To:</span>
                  </div>
               </dx-button>
            </div>

            <div class="cell">
               <dx-number-box [step]="1" [(value)]="adjustQty" [showSpinButtons]="true" 
                              [showClearButton]="true"
                              [width]="100"
                              [useLargeSpinButtons]="false"
                              format="#,##0"
               ></dx-number-box>
            </div>
         </div>

         <div class="row last">
            <div class="cell">
               <dx-button  [type]="'danger'" [text]="'Close'" [width]="100" [disabled]="!canCloseOrReversePosition"
                           (onClick)="closePosition()" [visible]="securityContext.adjustPosition">
               <div *dxTemplate="let data of 'content'">
                  <span class="dx-button-text" *ngIf="isTradeConfirmationPending"><strong>....</strong></span>
                  <span class="dx-button-text" *ngIf="!isTradeConfirmationPending">Flatten</span>
               </div>
               </dx-button>
            </div>
            <div class="cell">
               <dx-button [type]="'default'" [text]="'Reverse'" [width]="100" [disabled]="!canCloseOrReversePosition"
                  (onClick)="reversePosition()" [visible]="securityContext.adjustPosition">
                  <div *dxTemplate="let data of 'content'" style="background: yellow; border: 1px solid black">
                     <span class="dx-button-text" *ngIf="isTradeConfirmationPending">....</span>
                     <span class="dx-button-text" *ngIf="!isTradeConfirmationPending">Reverse</span>
                  </div>
               </dx-button>               
            </div>
         </div>
      </div>

   </div>

</dx-popup>