import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    ICashFlowAdjustmentSettingsTemplate
} from "../../adjustment-pricing-grid/model/ICashFlowAdjustmentSettingsTemplate";
import {Adjustment, ExpirationDescriptor, PackageComparisonModel} from "../model/PackageComparisonModel";
import {PricingGridStrategyDescriptor} from "../../options-pricing-grid/model/pricing-grid-strategy.descriptor";
import {isVoid} from "../../utils";

export class HedgeBoardComparisonModel {
    constructor(
        public readonly templatePackageId: string,
        public readonly models: PackageComparisonModel[]
    ) {
    }

    isPinned = false;

    get uniqueId(): string {
        return this.models[0]?.getUniqueId();
    }

    get isCalls() : boolean {
        return !isVoid(this.models[0].callHedge);
    }

    get portfolio(): ApgPortfolio {
        return this.models[0]?.portfolio;
    }

    get shortOptionStrike() : string {
        return this.models[0].shortOptionStrike;
    }

    get template(): ICashFlowAdjustmentSettingsTemplate {
        return this.models[0]?.template;
    }

    get adjustment(): string {
        const call = this.models[0]?.adjustmentOne;
        const put = this.models[0]?.adjustmentTwo;

        return `${call?.name}(C) + ${put?.name}(P)`;
    }

    get expirations(): any[] {
        return this.models.map(x => x.expiration);
    }

    get hedge(): PricingGridStrategyDescriptor {
        const model = this.models[0];
        if (model.callHedge) {
            return model.callHedge;
        } else if (model.putHedge) {
            return model.putHedge;
        }
        return null;
    }

    get offset(): number {
        const model = this.models[0];
        if (model.callHedge) {
            return model.callHedgeOffset;
        } else if (model.putHedge) {
            return model.putHedgeOffset;
        }
        return null;
    }

    get total(): number {
        const total = this.models.map(x => x.getPackagePrice())
            .reduce((a,b) => a + b, 0);
        return total;
    }

    get delta() : number {
        return this.models[0].getLatestSettings()?.delta || 0;
    }
}