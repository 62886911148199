import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { TimestampsService } from '../timestamps.service';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from '../message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { getCashFlowTrackingGridOptions } from './cashflow-tracking-grid-options';
import { CashFlowTrackingRecordDto } from '../shell-communication/shell-dto-protocol';
import { CashFlowTrackingRecordModel } from './CashFlowTrackingRecordModel';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BucketHighlighted } from '../ui-messages/ui-messages';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetCashFlowTrackingRecords, GetCashFlowTrackingRecordsReply } from '../shell-communication/shell-operations-protocol';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { AdjustmentSolutionPopupComponent } from '../adjustment-pricing-grid/solution-popup/adjustment-solution-popup.component';
import { DetectMethodChanges, isVoid } from '../utils';
import * as Enumerable from 'linq';
import { IPanelComponent } from '../panels/panel-component.interface';

@Component({
   selector: 'ets-cashflow-tracking',
   templateUrl: './cashflow-tracking.component.html',
   styleUrls: ['./cashflow-tracking.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashFlowTrackingComponent implements OnInit, OnDestroy {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _layoutService: SettingsStorageService,
      protected readonly _messageBus: MessageBusService,

      private readonly _toastr: ToastrService,
      private readonly _timestampsService: TimestampsService,
      private readonly _shellClient: ShellClientService
   ) {  
   }
   //
   private _rows: CashFlowTrackingRecordModel[] = [];

   //
   private _selectedComboId: string;

   //
   private _unsubscriber = new Subject();

   //
   private _gridApi: GridApi;

   //
   @Input() container: IPanelComponent;
   @Output() saveState$ = new EventEmitter();
   @Output() restoreState$ = new EventEmitter();
   @Output() heightChanged = new EventEmitter();

   //
   @ViewChild(AdjustmentSolutionPopupComponent, {static: true}) 
   solutionPopupCmp: AdjustmentSolutionPopupComponent;

   //
   get grid(): GridApi {
      return this._gridApi;
   }

   //
   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   //
   cssClassForGrid = ['ag-theme-balham-dark', 'grid'];
   
   //
   gridOptions: GridOptions;

   //
   onGridReady(args: GridReadyEvent): void {
      
      this._gridApi = args.api;

      this._gridApi['ets-component'] = this;

      this._messageBus
         .of<BucketHighlighted>('BucketHighlighted')
         .pipe(
            filter(x => x.scopeId === this.container.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => {
            const msg = x.payload;

            const bucketType = msg.bucketContext.bucketType;

            if (bucketType !== 'Combo') {
               return;
            }

            this.onComboHighlighted(msg);
         });

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onClearTradingDataMessage(msg.payload));

      this._messageBus
         .of<CashFlowTrackingRecordDto>('CashFlowTrackingRecordDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onCashFlowTrackingRecord(x.payload));
   }

   //
   ngOnInit(): void {

      this.gridOptions = getCashFlowTrackingGridOptions.bind(this)();
   
   }
   
   //
   onCashFlowTrackingRecord(dto: CashFlowTrackingRecordDto): void {
      
      if (!this._gridApi) {
         return;
      }

      if (dto.comboId != this._selectedComboId) {
         return;
      }

      const id = dto.adjustmentOperationId;

      const node = this._gridApi.getRowNode(id);

      if (node) {
         
         const model = node.data as CashFlowTrackingRecordModel;
         
         model.add(dto);
         
         this._gridApi.applyTransaction({update: [model]});

      } else {
         
         const model = new CashFlowTrackingRecordModel([dto]);
      
         this._rows.push(model);

         if (this._rows.length > 1) {
            const prev = this._rows[this._rows.length - 2];
            model.underlyingChange = model.underlyingPrice - prev.underlyingPrice;
            model.runningTotal = model.factPremium + (prev.runningTotal || 0);
         }

         this._gridApi.applyTransaction({add: [model]});
         
      }

   }

   //
   private async onComboHighlighted(msg: BucketHighlighted) {

      this._selectedComboId = msg.bucketContext.bucketId;
      await this.loadRecords();

   }

   //
   private async loadRecords(): Promise<void> {

      this._gridApi.showLoadingOverlay();
      
      try {

         this._rows = [];
         this._gridApi.setRowData([]);

         if (isVoid(this._selectedComboId)) {
            return;
         }

         const qry = new GetCashFlowTrackingRecords({comboId: this._selectedComboId}, 100);
         
         const reply = await this._shellClient.processQuery<GetCashFlowTrackingRecordsReply>(qry);

         if (reply.records.length === 0) {
            return;
         }

         const cfRecords = Enumerable.from(reply.records);

         const groupByOperationId = cfRecords
            .orderBy(x => x.cashFlowTrackingRecordId)
            .groupBy(x => x.adjustmentOperationId);

         const models = groupByOperationId.select(grp => {
            const ordered = grp.orderBy(x => x.transactionDate);
            const model = new CashFlowTrackingRecordModel(ordered.toArray());
            return model;
         }).toArray();

 
         
         if (this._gridApi) {
            
            const rows = models.sort(x => x.recordId);

            rows.forEach((row, ix, arr) => {
               
               const prevIx = ix-1;

               if (prevIx < 0) {
                  row.runningTotal = row.factPremium;
                  return;
               }

               const prevRow = arr[prevIx];

               const mktChg = row.underlyingPrice - prevRow.underlyingPrice;
               const total = row.factPremium + (prevRow.runningTotal || 0);

               row.underlyingChange = mktChg;
               row.runningTotal = total;
               
            });

            this._rows = rows;

            this._gridApi.setRowData(rows);
         }

      } catch(e) {
         this._toastr.error('"Load Cash Flow Tracking Records" operation completed with errors');
         console.error(e);
      } finally {
         this._gridApi.hideOverlay();
      }

   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   onClearTradingDataMessage(msg: ClearTradingDataUIMessage): void {
      if (!this._gridApi) {
         return;
      }
      this.loadRecords();
   }

   //
   @DetectMethodChanges()
   showSolutionPopup(data: CashFlowTrackingRecordModel) {

      if (isVoid(data)) {
         this._toastr.error('Bad data record selected');
         return;
      }

      const snapshot = data.adjustmentSolutionSnapshot;

      
      const innerRecords = data.getInnerRecords();
      
      const mainOrderRecords = innerRecords.filter(x => x.orderRole === 'M');
      
      if (mainOrderRecords.length === snapshot.length)
      {
         for (let index = 0; index < mainOrderRecords.length; index++) {
            
            const innerRecord = mainOrderRecords[index];
            
            const matchingSnapshot = snapshot[index];

            if (matchingSnapshot) {
               matchingSnapshot.price = innerRecord.factPremium
            }

         }
      }

      this.solutionPopupCmp.show(
         undefined,
         snapshot,
         snapshot.length > 1 ? 'double' : 'single',
          null
      );
   }

   //
   getState() {
      //
   }

   //
   setState(state: any) {
      //
   }

   //
   onHeightChanged() {
      this.heightChanged.emit();
   }
}
