import { NgModule } from '@angular/core';
import { SymbolPickerDialogComponent } from './symbol-picker-dialog.component';
import { SymbolPickerComponent } from './symbol-picker.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';
import { SymbolPickerAclService } from './symbol-picker-acl.service';
import { LastPriceModule } from '../last-price/last-price.module';

@NgModule({
   declarations: [
      SymbolPickerComponent,
      SymbolPickerDialogComponent
   ],

   imports: [
      CommonModule,
      DevExtremeModule,
      LastPriceModule
   ],

   exports: [
      SymbolPickerComponent,
      SymbolPickerDialogComponent,
   ],
   
   providers: [
      SymbolPickerAclService
   ]
})
export class EtsSymbolPickerModule { }
