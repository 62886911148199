// tslint:disable: no-string-literal
import { ParametersControlBase } from '../../parameters-control-base';
import { ICommonDispositionParameters } from '../../common-disposition-parameters';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { isTruthy, isNullOrUndefined, DetectSetterChanges, DetectParameterChanges } from 'projects/shared-components/utils';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { FlagshipParametersViewComponent } from './flagship-parameters-view.component';

export enum FlagshipBetStyle {
   None,
   Flat,
   PositiveProgression,
   NegativeProgression,
   Pyramid,
   HighProbability,
   Factor
}

export enum FlagshipBetSequence {
   None = 0,
   Everyone,
   LastWasWinner,
   LastWasLoser,
   XofY
}

export enum FlagshipAlgorithm {
   None = 0,
   Follow,
   Outright,
   S40,
   BeforeLast,
   OppositeBeforeLast,
   Majority,
   Minority,
   ZigZag,
   FMR
}

export class FlagshipAlgoParametersControl extends ParametersControlBase<FlagshipParametersViewComponent> {

   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService, algoId: string) {
      super(tradingInstrumentsService, toastr);

      this.algoId = algoId;

      for (let index = 1; index <= 50; index++) {
         const v = index / 10;
         this.samsRatioItems.push(v);
         this.multiplierItems.push(index);
         this.ctlTimesItems.push(index);
         this.factorItems.push(index);
      }

      for (let index = 1; index <= 20; index++) {
         this.otrTimesToStayOtrItems.push(index);
         this.otrNumberOfLossesToGoOTRItems.push(index);
         this.otrMaxTimesDoubleOtrBetOnLossItems.push(index);
         this.alterOtrNumberOfLossesToGoOTRItems.push(index);
         this.alterOtrTimesToStayOtrItems.push(index);
         this.alterOtrNumberOfLossesToAlterItems.push(index);
         this.samsMpNpoItems.push(index);
         this.samsConsecutiveWinsItems.push(index);
         this.samsMaxTimesToIncreasItems.push(index);
      }

      if (algoId === EtsConstants.algorithms.flagships.s40AlgoId ||
         algoId === EtsConstants.algorithms.flagships.fmr) {
         this.ctlTimes = 1;
      }

   }

   private _symbol: TradingInstrument;
   private _multiplier: number;
   private _factor: number;
   private _isOtrEnabled = false;
   private _otrTimesToStayOtr: number;
   private _alterOtrTimesToStayOtr: number;
   private _isAlterOtrEnabled = false;
   private _otrUseOtrBetting = false;
   private _alterOtrUseOtrBetting = false;
   private _useSamsoniteMM = false;
   private _betStyle: FlagshipBetStyle;
   private _otrUseOtrAlgorithm = false;
   private _otrAlternativeAlgo: FlagshipAlgorithm;
   private _samsNumberOfConsecutiveWins: number;
   private _otrBets: string;
   private _alterOtrBets: string;
   private _betSequence: FlagshipBetSequence;

   algoId: string;
   maxPosition: number;
   ctlValue: number;
   ctlTimes: number;

   get betStyle(): FlagshipBetStyle {
      return this._betStyle;
   }

   @DetectParameterChanges()
   set betStyle(v: FlagshipBetStyle) {
      if (this._betStyle === v) {
         return;
      }

      this._betStyle = v;

      if (v !== FlagshipBetStyle.Factor) {
         this.factor = null;
         this.maxPosition = null;
      }

      if (v !== FlagshipBetStyle.HighProbability) {
         this.hpDatasetSequence = null;
         this.hpDatasetsToComplete = null;
      } else {
         this.ctlTimes = 1;
         this.multiplier = null;
      }

      if (v !== FlagshipBetStyle.PositiveProgression && v !== FlagshipBetStyle.NegativeProgression) {
         this.progressionSequence = null;
      }

      if (v !== FlagshipBetStyle.Pyramid) {
         this.pyramidDown = null;
      }

      if (v === FlagshipBetStyle.Pyramid || v === FlagshipBetStyle.NegativeProgression) {
         this.betSequence = FlagshipBetSequence.Everyone;
      }

      if (v !== FlagshipBetStyle.NegativeProgression) {
         this.safetyStart = false;
      }
   }

   get betSequence(): FlagshipBetSequence {
      return this._betSequence;
   }

   @DetectParameterChanges()
   set betSequence(value: FlagshipBetSequence) {
      if (this._betSequence === value) {
         return;
      }
      this._betSequence = value;
      if (this._betSequence !== FlagshipBetSequence.XofY) {
         this.xOfyLhs = this.xOfyRhs = this.xOfyType = null;
      }
   }


   safetyStart = false;
   resetOnExit = false;

   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;

   otrNumberOfLossesToGoOTR: number;
   otrMaxTimesDoubleOtrBetOnLoss: number;
   otrRepeatOtrProgression = false;
   otrDoubleOtrBetOnLoss = false;
   otrStayOTR = false;

   get otrBets(): string {
      return this._otrBets;
   }

   @DetectParameterChanges()
   set otrBets(v: string) {
      if (this._otrBets === v) {
         return;
      }
      this._otrBets = v;
      if (isTruthy(v)) {
         this.otrUseOtrBetting = true;
      }
   }

   alterOtrNumberOfLossesToAlter: number;
   alterOtrNumberOfLossesToGoOTR: number;

   get alterOtrBets(): string {
      return this._alterOtrBets;
   }

   @DetectParameterChanges()
   set alterOtrBets(v: string) {
      if (this._alterOtrBets === v) {
         return;
      }

      this._alterOtrBets = v;

      if (isTruthy(v)) {
         this.alterOtrUseOtrBetting = true;
      }
   }

   get canChangeBetSequence(): boolean {
      return this.betStyle !== FlagshipBetStyle.Pyramid
         && this.betStyle !== FlagshipBetStyle.NegativeProgression;
   }

   get canChangeCtlTimes(): boolean {
      return this.algoId !== EtsConstants.algorithms.flagships.s40AlgoId &&
         this.algoId !== EtsConstants.algorithms.flagships.fmr &&
         this.betStyle !== FlagshipBetStyle.HighProbability;
   }

   get canChangeMultiplier(): boolean {
      return this.betStyle !== FlagshipBetStyle.HighProbability;
   }

   outrightSide: MarketSide;

   pyramidDown: number;
   progressionSequence: string;

   samsMpNpo: number;
   samsRatio: number;
   samsMaxTimesToIncrease: number;

   get samsNumberOfConsecutiveWins(): number {
      return this._samsNumberOfConsecutiveWins;
   }

   @DetectParameterChanges()
   set samsNumberOfConsecutiveWins(v: number) {
      if (this._samsNumberOfConsecutiveWins === v) {
         return;
      }
      this._samsNumberOfConsecutiveWins = v;

      if (isTruthy(v)) {
         this.useSamsoniteMM = true;
      }
   }

   hpDatasetSequence: string;
   hpDatasetsToComplete: number;

   betStyleItems = [
      { text: 'Flat', value: FlagshipBetStyle.Flat },
      { text: 'Positive Progression', value: FlagshipBetStyle.PositiveProgression },
      { text: 'Negative Progression', value: FlagshipBetStyle.NegativeProgression },
      { text: 'Pyramid', value: FlagshipBetStyle.Pyramid },
      { text: 'Factor', value: FlagshipBetStyle.Factor },
      { text: 'High Probability', value: FlagshipBetStyle.HighProbability }
   ];
   betSequenceItems = [
      { text: 'Everyone', value: FlagshipBetSequence.Everyone },
      { text: 'Last Was Winner', value: FlagshipBetSequence.LastWasWinner },
      { text: 'Last Was Loser', value: FlagshipBetSequence.LastWasLoser },
      { text: 'X of Y', value: FlagshipBetSequence.XofY },
   ];
   outrightSideItems = [
      { text: 'Buy', value: MarketSide.Buy },
      { text: 'Sell', value: MarketSide.Sell },
   ];
   otrAlgorithmItems = [
      { text: 'Follow', value: FlagshipAlgorithm.Follow },
      { text: 'Outright', value: FlagshipAlgorithm.Outright },
      { text: 'Before Last', value: FlagshipAlgorithm.BeforeLast },
      { text: 'Opposite Before Last', value: FlagshipAlgorithm.OppositeBeforeLast },
      { text: 'Majoirty', value: FlagshipAlgorithm.Majority },
      { text: 'Minority', value: FlagshipAlgorithm.Minority },
      { text: 'S40', value: FlagshipAlgorithm.S40 },
      { text: 'ZigZag', value: FlagshipAlgorithm.ZigZag },
      { text: 'FMR', value: FlagshipAlgorithm.FMR }
   ];
   alterOtrNumberOfLossesToGoOTRItems: number[] = [];
   alterOtrNumberOfLossesToAlterItems: number[] = [];
   otrNumberOfLossesToGoOTRItems: number[] = [];
   otrMaxTimesDoubleOtrBetOnLossItems: number[] = [];
   otrTimesToStayOtrItems: number[] = [];
   alterOtrTimesToStayOtrItems: number[] = [];
   multiplierItems: number[] = [];
   ctlTimesItems: number[] = [];
   ctlValueItems: number[] = [];
   factorItems: number[] = [];
   maxPositionItems: number[] = [];
   samsRatioItems: number[] = [];
   samsConsecutiveWinsItems: number[] = [];
   samsMpNpoItems: number[] = [];
   samsMaxTimesToIncreasItems: number[] = [];
   xOfyLhs: number;
   xOfyRhs: number;
   xOfyType: number;

   get symbol(): TradingInstrument {
      return this._symbol;
   }

   @DetectParameterChanges()
   set symbol(value: TradingInstrument) {
      if (this._symbol === value) {
         return;
      }
      const oldOne = this._symbol;
      this._symbol = value;
      const newOne = value;
      if (isTruthy(newOne)) {
         if (isTruthy(oldOne)) {
            if (newOne.underlying !== oldOne.underlying) {
               this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
            }
         } else {
            this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
         }
      }
   }

   get multiplier(): number {
      return this._multiplier;
   }

   @DetectParameterChanges()
   set multiplier(value: number) {
      if (this._multiplier === value) {
         return;
      }
      this._multiplier = value;
      this._updateMaxPositionsItems();
   }

   get isOtrEnabled(): boolean {
      return this._isOtrEnabled;
   }

   @DetectParameterChanges()
   set isOtrEnabled(value: boolean) {
      if (this._isOtrEnabled === value) {
         return;
      }
      this._isOtrEnabled = value;
      if (!value) {
         this.otrUseOtrAlgorithm = false;
         this.otrUseOtrBetting = false;
         this.otrAlternativeAlgo = null;
         this.otrTimesToStayOtr = null;
         this.otrBets = null;
         this.otrStayOTR = false;
         this.otrRepeatOtrProgression = false;
         this.otrDoubleOtrBetOnLoss = false;
         this.otrMaxTimesDoubleOtrBetOnLoss = null;
         this.otrNumberOfLossesToGoOTR = null;
         this.isAlterOtrEnabled = false;
      }
   }

   get otrAlternativeAlgo(): FlagshipAlgorithm {
      return this._otrAlternativeAlgo;
   }

   @DetectParameterChanges()
   set otrAlternativeAlgo(v: FlagshipAlgorithm) {
      if (this._otrAlternativeAlgo === v) {
         return;
      }
      this._otrAlternativeAlgo = v;
      if (!!v) {
         this.otrUseOtrAlgorithm = true;
      }
   }

   get otrTimesToStayOtr(): number {
      return this._otrTimesToStayOtr;
   }

   @DetectParameterChanges()
   set otrTimesToStayOtr(value: number) {
      if (this._otrTimesToStayOtr === value) {
         return;
      }

      this._otrTimesToStayOtr = value;

      if (isTruthy(value)) {
         this.isOtrEnabled = true;
      }
   }

   get alterOtrTimesToStayOTR(): number {
      return this._alterOtrTimesToStayOtr;
   }

   @DetectParameterChanges()
   set alterOtrTimesToStayOTR(v: number) {
      if (this._alterOtrTimesToStayOtr === v) {
         return;
      }

      this._alterOtrTimesToStayOtr = v;

      if (isTruthy(v)) {
         this.isAlterOtrEnabled = true;
      }
   }

   get otrUseOtrBetting(): boolean {
      return this._otrUseOtrBetting;
   }

   @DetectParameterChanges()
   set otrUseOtrBetting(v: boolean) {
      this._otrUseOtrBetting = v || false;
      if (!v) {
         this.otrBets = null;
      }
   }

   get otrUseOtrAlgorithm(): boolean {
      return this._otrUseOtrAlgorithm;
   }

   @DetectParameterChanges()
   set otrUseOtrAlgorithm(v: boolean) {
      if (this._otrUseOtrAlgorithm === v) {
         return;
      }

      this._otrUseOtrAlgorithm = v;
      if (!v) {
         this.otrAlternativeAlgo = null;
      }
   }

   get isAlterOtrEnabled(): boolean {
      return this._isAlterOtrEnabled;
   }

   @DetectParameterChanges()
   set isAlterOtrEnabled(value: boolean) {
      this._isAlterOtrEnabled = value;
      if (!value) {
         this.alterOtrUseOtrBetting = false;
         this.alterOtrTimesToStayOTR = null;
         this.alterOtrBets = null;
         this.alterOtrNumberOfLossesToAlter = null;
         this.alterOtrNumberOfLossesToGoOTR = null;
      }
   }

   get alterOtrUseOtrBetting(): boolean {
      return this._alterOtrUseOtrBetting;
   }

   @DetectParameterChanges()
   set alterOtrUseOtrBetting(v: boolean) {
      this._alterOtrUseOtrBetting = v || false;
      if (!v) {
         this.alterOtrBets = null;
      }
   }

   get factor(): number {
      return this._factor;
   }

   @DetectParameterChanges()
   set factor(value: number) {
      if (this._factor === value) {
         return;
      }
      this._factor = value;
      this._updateMaxPositionsItems();
   }

   get useSamsoniteMM(): boolean {
      return this._useSamsoniteMM;
   }

   @DetectParameterChanges()
   set useSamsoniteMM(v: boolean) {
      if (this._useSamsoniteMM === v) {
         return;
      }
      this._useSamsoniteMM = v;
      if (!this._useSamsoniteMM) {
         this.samsMaxTimesToIncrease = null;
         this.samsMpNpo = null;
         this.samsRatio = null;
         this.samsNumberOfConsecutiveWins = null;
      }
   }

   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!isTruthy(this.symbol)) {
         errors.push('Symbol is required');
      }

      if (!isTruthy(this.ctlValue)) {
         errors.push('"CTL Value" is not set');
      }

      if (!isTruthy(this.ctlTimes)) {
         errors.push('"CTL Times" is not set');
      }

      if (!isTruthy(this.multiplier) || this.multiplier === 0) {
         if (this.betStyle !== FlagshipBetStyle.HighProbability) {
            errors.push('Multiplier is required');
         }
      }

      if (!isTruthy(this.betStyle)) {
         errors.push('"Bet Style" not set');
      }

      if (!isTruthy(this.betSequence)) {
         errors.push('"Bet Sequence" not set');
      }

      if (this.isOtrEnabled) {

         if (!isTruthy(this.otrNumberOfLossesToGoOTR)) {
            errors.push('"# of Losses To Go OTR" is not set');
         }

         if (this.otrUseOtrAlgorithm) {
            if (!isTruthy(this.otrAlternativeAlgo)) {
               errors.push('"OTR Algorithm" is not set');
            }
         }

         if (!isTruthy(this.otrTimesToStayOtr) || this.otrTimesToStayOtr === 0) {
            errors.push('"Times To Stay OTR" is required');
         }

         if (this.otrUseOtrBetting) {
            const otrBetsValid = this._validateIntegerSequenceFromString(this.otrBets);
            if (!otrBetsValid) {
               errors.push('Incorrect "OTR Bets"');
            }
         }

         if (this.otrDoubleOtrBetOnLoss) {
            if (!isTruthy(this.otrMaxTimesDoubleOtrBetOnLoss)) {
               errors.push('"Max. Times Double OTR" is not set');
            }
         }

      }

      if (this.isAlterOtrEnabled) {

         if (!isTruthy(this.alterOtrNumberOfLossesToGoOTR)) {
            errors.push('"# of Losses To Go From A-OTR" is not set');
         }

         if (!isTruthy(this.alterOtrTimesToStayOTR) || this.alterOtrTimesToStayOTR === 0) {
            errors.push('"A-OTR Times To Stay" is required');
         }

         if (this.alterOtrUseOtrBetting) {
            const otrBetsValid = this._validateIntegerSequenceFromString(this.alterOtrBets);
            if (!otrBetsValid) {
               errors.push('Incorrect "A-OTR Bets"');
            }
         }

         if (!isTruthy(this.alterOtrNumberOfLossesToAlter)) {
            errors.push('"# of Losses to Alter." is not set');
         }
      }

      if (this.betStyle === FlagshipBetStyle.Factor) {
         if (!isTruthy(this.factor) || this.factor === 0) {
            errors.push('Factor is required');
         }

         if (!isTruthy(this.maxPosition) || this.maxPosition === 0) {
            errors.push('MaxPosition is required');
         } else {
            if (!this._checkMaxPosition()) {
               errors.push('Incorrect MaxPosition value');
            }
         }
      }

      if (this.betStyle === FlagshipBetStyle.Pyramid) {
         if (isNullOrUndefined(this.pyramidDown)) {
            errors.push('Please provide "Pyramid Down" parameter');
         }
         if (this.betSequence !== FlagshipBetSequence.Everyone) {
            errors.push('"Pyramid" only valid with "Everyone" bet sequence');
         }
      }

      if (this.betStyle === FlagshipBetStyle.HighProbability) {
         const seqValid = this._validateIntegerSequenceFromString(this.hpDatasetSequence);
         if (!seqValid) {
            errors.push('Incorrect "HP Dataset" parameter');
         }
         if (isTruthy(this.multiplier)) {
            errors.push('"High Probability" bet style cannot have multiplier set');
         }
      }

      if (this.betStyle === FlagshipBetStyle.PositiveProgression || this.betStyle === FlagshipBetStyle.NegativeProgression) {
         const seqValid = this._validateIntegerSequenceFromString(this.progressionSequence);
         if (!seqValid) {
            errors.push('Incorrect "Progression Sequence" parameter');
         }

         if (this.betStyle === FlagshipBetStyle.NegativeProgression) {
            if (this.betSequence !== FlagshipBetSequence.Everyone) {
               errors.push('"Negatvie Progression" bet style is only allowed with "Everyone" bet sequence');
            }
         }
      }

      if (this.useSamsoniteMM) {
         if (!isTruthy(this.samsNumberOfConsecutiveWins)) {
            errors.push('Samsonite: "Consecutive Wins" parameter not set');
         }

         if (!isTruthy(this.samsRatio)) {
            errors.push('Samsonite: "Ratio" paramter not set');
         }

         if (!isTruthy(this.samsMpNpo)) {
            errors.push('Samsonite: "MP-NPO" parameter not set');
         }

         if (!isTruthy(this.samsMaxTimesToIncrease)) {
            errors.push('Samsonite: "MP" parameter not set');
         }
      }

      if (this.algoId === EtsConstants.algorithms.flagships.outrightAlgoId) {
         if (!isTruthy(this.outrightSide)) {
            errors.push('"Outright Side" parameter not set');
         }
      }

      if (this.algoId === EtsConstants.algorithms.flagships.s40AlgoId ||
         this.algoId === EtsConstants.algorithms.flagships.fmr) {
         if (this.ctlTimes !== 1) {
            errors.push('"S40 and FMR strategies only allowed CtlTimes = 1"');
         }
      }

      if (this.betSequence === FlagshipBetSequence.XofY) {
         if (!isTruthy(this.xOfyRhs)) {
            errors.push('"Y" value must be set for "X of Y" Bet Sequence');
         }

         if (!isTruthy(this.xOfyLhs)) {
            errors.push('"X" value must be set for "X of Y" Bet Sequence');
         }

         if (this.xOfyLhs >= this.xOfyRhs) {
            errors.push('"X" must be less than "Y" for "X of Y" Bet Sequence');
         }

         if (!isTruthy(this.xOfyType)) {
            errors.push('Specify  Winner/Loser for "X of Y" Bet Sequence');
         }
      } else {
         if (isTruthy(this.xOfyType) || isTruthy(this.xOfyRhs) || isTruthy(this.xOfyLhs)) {
            errors.push('Incorrect "X of Y" parameters');
         }
      }

      return errors;
   }

   protected _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker : null;
      params['ctlvalue'] = isTruthy(this.ctlValue) ? this.ctlValue + '' : null;
      params['ctltimes'] = isTruthy(this.ctlTimes) ? this.ctlTimes + '' : null;
      params['multiplier'] = isTruthy(this.multiplier) ? this.multiplier + '' : null;
      params['betstyle'] = isTruthy(this.betStyle) ? this.betStyle + '' : null;
      params['betsequence'] = isTruthy(this.betSequence) ? this.betSequence + '' : null;
      params['safetystart'] = isTruthy(this.safetyStart) ? this.safetyStart + '' : null;

      params['outrightside'] = isTruthy(this.outrightSide) ? this.outrightSide + '' : null;

      params['factor'] = isTruthy(this.factor) ? this.factor + '' : null;
      params['maxposition'] = isTruthy(this.maxPosition) ? this.maxPosition + '' : null;

      params['pyramiddown'] = isTruthy(this.pyramidDown) ? this.pyramidDown + '' : null;

      params['progressionsequence'] = isTruthy(this.progressionSequence) ? this.progressionSequence : null;
      params['hpdatasetsequence'] = isTruthy(this.hpDatasetSequence) ? this.hpDatasetSequence : null;
      params['hpdatasetstocomplete'] = isTruthy(this.hpDatasetsToComplete) ? this.hpDatasetsToComplete + '' : '0';

      params['samsnumberofconsecutivewins'] = isTruthy(this.samsNumberOfConsecutiveWins) ? this.samsNumberOfConsecutiveWins + '' : null;
      params['samsratio'] = isTruthy(this.samsRatio) ? this.samsRatio + '' : null;
      params['samsmaxtimestoincrease'] = isTruthy(this.samsMaxTimesToIncrease) ? this.samsMaxTimesToIncrease + '' : null;
      params['samsmpnpo'] = isTruthy(this.samsMpNpo) ? this.samsMpNpo + '' : null;

      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['istradethroughmit'] = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;

      params['otrnumberoflossestogootr'] = isTruthy(this.otrNumberOfLossesToGoOTR) ? this.otrNumberOfLossesToGoOTR + '' : null;
      params['otralternativealgo'] = isTruthy(this.otrAlternativeAlgo) ? this.otrAlternativeAlgo + '' : null;
      params['otrtimestostayotr'] = isTruthy(this.otrTimesToStayOtr) ? this.otrTimesToStayOtr + '' : null;
      params['otrbets'] = isTruthy(this.otrBets) ? this.otrBets + '' : null;
      params['otrstayotr'] = isTruthy(this.otrStayOTR) ? this.otrStayOTR + '' : null;
      params['otrrepeatotrprogression'] = isTruthy(this.otrRepeatOtrProgression) ? this.otrRepeatOtrProgression + '' : null;
      params['otrdoubleotrbetonloss'] = isTruthy(this.otrDoubleOtrBetOnLoss) ? this.otrDoubleOtrBetOnLoss + '' : null;
      params['otrmaxtimesdoubleotrbetonloss'] = isTruthy(this.otrMaxTimesDoubleOtrBetOnLoss) ? this.otrMaxTimesDoubleOtrBetOnLoss + '' : null;

      params['alterotrnumberoflossestogootr'] = isTruthy(this.alterOtrNumberOfLossesToGoOTR) ? this.alterOtrNumberOfLossesToGoOTR + '' : null;
      params['alterotrtimestostayotr'] = isTruthy(this.alterOtrTimesToStayOTR) ? this.alterOtrTimesToStayOTR + '' : null;
      params['alterotrbets'] = isTruthy(this.alterOtrBets) ? this.alterOtrBets + '' : null;
      params['alterotrnumberoflossestoalter'] = isTruthy(this.alterOtrNumberOfLossesToAlter) ? this.alterOtrNumberOfLossesToAlter + '' : null;

      params['resetonexit'] = isTruthy(this.resetOnExit) ? this.resetOnExit + '' : null;

      params['xofylhs'] = isTruthy(this.xOfyLhs) ? this.xOfyLhs + '' : null;
      params['xofyrhs'] = isTruthy(this.xOfyRhs) ? this.xOfyRhs + '' : null;
      params['xofytype'] = isTruthy(this.xOfyType) ? this.xOfyType + '' : null;

      return params;
   }

   protected _SetParameters(dto: StrategyDto): void {
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }

      if (isTruthy(params['ctlvalue'])) {
         this.ctlValue = parseFloat(params['ctlvalue']);
      }

      if (isTruthy(params['ctltimes'])) {
         this.ctlTimes = parseInt(params['ctltimes']);
      }

      if (isTruthy(params['multiplier'])) {
         this.multiplier = parseInt(params['multiplier']);
      }

      if (isTruthy(params['betstyle'])) {
         this.betStyle = parseInt(params['betstyle']);
      }

      if (isTruthy(params['betsequence'])) {
         this.betSequence = parseInt(params['betsequence']);
      }

      if (isTruthy(params['safetystart'])) {
         this.safetyStart = params['safetystart'].toLowerCase() === 'true';
      }

      if (isTruthy(params['outrightside'])) {
         this.outrightSide = parseInt(params['outrightside']);
      }

      if (isTruthy(params['factor'])) {
         this.factor = parseInt(params['factor']);
      }

      if (isTruthy(params['maxposition'])) {
         this.maxPosition = parseInt(params['maxposition']);
      }

      if (isTruthy(params['pyramiddown'])) {
         this.pyramidDown = params['pyramiddown'];
      }

      if (isTruthy(params['progressionsequence'])) {
         this.progressionSequence = params['progressionsequence'];
      }

      if (isTruthy(params['hpdatasetsequence'])) {
         this.hpDatasetSequence = params['hpdatasetsequence'];
      }

      if (isTruthy(params['hpdatasetstocomplete'])) {
         this.hpDatasetsToComplete = parseInt(params['hpdatasetstocomplete']);
      }

      if (isTruthy(params['samsnumberofconsecutivewins'])) {
         this.samsNumberOfConsecutiveWins = parseInt(params['samsnumberofconsecutivewins']);
      }

      if (isTruthy(params['samsratio'])) {
         this.samsRatio = parseFloat(params['samsratio']);
      }

      if (isTruthy(params['samsmaxtimestoincrease'])) {
         this.samsMaxTimesToIncrease = parseInt(params['samsmaxtimestoincrease']);
      }

      if (isTruthy(params['samsmpnpo'])) {
         this.samsMpNpo = parseInt(params['samsmpnpo']);
      }

      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }

      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }

      if (isTruthy(params['istradethroughmit'])) {
         this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
      }

      if (isTruthy(params['otrnumberoflossestogootr'])) {
         this.otrNumberOfLossesToGoOTR = parseInt(params['otrnumberoflossestogootr']);
      }

      if (isTruthy(params['otralternativealgo'])) {
         this.otrAlternativeAlgo = parseInt(params['otralternativealgo']);
      }

      if (isTruthy(params['otrtimestostayotr'])) {
         this.otrTimesToStayOtr = parseInt(params['otrtimestostayotr']);
      }

      if (isTruthy(params['otrbets'])) {
         this.otrBets = params['otrbets'];
      }

      if (isTruthy(params['otrstayotr'])) {
         this.otrStayOTR = params['otrstayotr'].toLowerCase() === 'true';
      }

      if (isTruthy(params['otrrepeatotrprogression'])) {
         this.otrRepeatOtrProgression = params['otrrepeatotrprogression'].toLowerCase() === 'true';
      }
      if (isTruthy(params['otrdoubleotrbetonloss'])) {
         this.otrDoubleOtrBetOnLoss = params['otrdoubleotrbetonloss'].toLowerCase() === 'true';
      }

      if (isTruthy(params['otrmaxtimesdoubleotrbetonloss'])) {
         this.otrMaxTimesDoubleOtrBetOnLoss = parseInt(params['otrmaxtimesdoubleotrbetonloss']);
      }

      if (isTruthy(params['alterotrnumberoflossestogootr'])) {
         this.alterOtrNumberOfLossesToGoOTR = parseInt(params['alterotrnumberoflossestogootr']);
      }

      if (isTruthy(params['alterotrtimestostayotr'])) {
         this.alterOtrTimesToStayOTR = parseInt(params['alterotrtimestostayotr']);
      }

      if (isTruthy(params['alterotrbets'])) {
         this.alterOtrBets = params['alterotrbets'];
      }

      if (isTruthy(params['alterotrnumberoflossestoalter'])) {
         this.alterOtrNumberOfLossesToAlter = parseInt(params['alterotrnumberoflossestoalter']);
      }

      if (isTruthy(params['resetonexit'])) {
         this.resetOnExit = params['resetonexit'].toLowerCase() === 'true';
      }

      if (isTruthy(params['xofylhs'])) {
         this.xOfyLhs = parseInt(params['xofylhs']);
      }

      if (isTruthy(params['xofyrhs'])) {
         this.xOfyRhs = parseInt(params['xofyrhs']);
      }

      if (isTruthy(params['xofytype'])) {
         this.xOfyType = parseInt(params['xofytype']);
      }
   }

   private _updateTickSizeDependentDropdowns(tickSize: number, precision: number) {
      this.ctlValue = null;
      this.ctlValueItems = [];

      const formatter = new Intl.NumberFormat('en-IN', {
         minimumFractionDigits: precision,
         maximumFractionDigits: precision
      });

      for (let i = 1; i < 50; i++) {
         let step = i * tickSize;
         step = parseFloat(formatter.format(step));
         this.ctlValueItems.push(step);
      }
   }

   private _updateMaxPositionsItems() {
      if (!isTruthy(this.factor) || !isTruthy(this.multiplier)
         || this.multiplier === 0) {
         this.maxPosition = null;
         this.maxPositionItems = [];

         return;
      }

      const newItems = [];

      for (let i = 1; i < 50; i++) {
         if (this.factor === 1) {
            const item = i * this.multiplier;
            newItems.push(item);
         } else {
            const item = Math.pow(this.factor, i) * this.multiplier;
            newItems.push(item);
         }
      }

      this.maxPositionItems = newItems;
      this.maxPosition = null;
   }

   private _checkMaxPosition(): boolean {
      const correctMaxPosition: number[] = [];
      if (isTruthy(this.factor) && isTruthy(this.multiplier)) {
         for (let i = 0; i < 100; i++) {
            if (this.factor === 1) {
               const val = this.multiplier * (i + 1);
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            } else {
               const pow = Math.pow(this.factor, i);
               const val = this.multiplier * pow;
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            }
         }
      }

      const curMP = this.maxPosition || 0;
      let mpIsCorrect = false;
      for (const mp of correctMaxPosition) {
         if (mp === curMP) {
            mpIsCorrect = true;
            break;
         }
      }
      return mpIsCorrect;
   }

   private _validateIntegerSequenceFromString(sequence: string): boolean {
      if (!isTruthy(sequence)) {
         return false;
      }

      let counter = 0;

      const items = sequence.split(',');

      items.forEach(b => {
         const parsed = Number(b);
         if (!isNaN(parsed)) {
            counter++;
         }
      });


      return counter > 0 && counter === items.length;
   }

   onCustomNumberCreated(data, collection: number[]) {
      const newItem = parseFloat(data.text);
      if (!isNaN(newItem)) {
         const isNew = collection.indexOf(newItem) === -1;
         data.customItem = newItem;
         if (isNew) {
            collection.push(newItem);
         }
      }
   }

   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean): void {
      //
   }

   onStrategyTerminalChanged(): void {

   }

}
