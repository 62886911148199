<dx-popup
  (onHidden)="this.onHidden()"
  [(visible)]="this.visible"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [showTitle]="true"
  [width]="270"
  height="auto"
  [shading]="false"
  titleTemplate="title"
>
  <div *dxTemplate="let data of 'title'" style="background: black;">
    <div class="header">{{ this.mergingPackageLabel }}</div>
  </div>
  
  <div *dxTemplate="let data of 'content'">
    <div #root class="container">
      
      <div class="body">
        <dx-list
          (onSelectionChanged)="this.onSelectedPackagesChanged($event)"
          [items]="this.availablePackages"
          [selectedItems]="this.selectedPackages"
          [selectionMode]="'multiple'"
          [showSelectionControls]="true"
          itemTemplate="item"
        >
          <div *dxTemplate="let data of 'item'">
            <div [style.color]="data.color">
              <span>{{ data.label }}</span>
              <span *ngIf="this.isSelfPackage(data)"
                    style="margin-left: 5px; vertical-align: super; font-weight: 900"
              >*</span>
            </div>
          </div>
        </dx-list>
      </div>
      
      <div class="new-package">
        <dx-check-box
          [(value)]="this.createNewPackage"
          text="Create New Package"
          (onValueChanged)="this.onCreateNewPackageChanged()"
        >
        </dx-check-box>
      </div>
      
      <div class="footer">
        <div class="btn">
          <dx-button (onClick)="this.onMergeClicked()"
                     text="Merge"
                     width="100%">
          </dx-button>
        </div>
        <div class="btn">
          <dx-button (onClick)="this.onCancelClicked()" text="Cancel" width="100%">
          </dx-button>
        </div>
      </div>
    
    </div>
  
  </div>
</dx-popup>