<div class="container">
  <div class="login-info" *ngIf="securityContext.loginInfoPanel">
    <div class="login">
      <span class="user">{{loginInfo.userId}}&nbsp;</span>
    </div>
    <div class="terminal-shell">
      <span class="terminal">&nbsp;{{loginInfo.terminal}}&nbsp;|</span>
      <span class="shell">&nbsp;{{loginInfo.shell}}</span>
    </div>
  </div>
  
  <div class="time-panel" (click)="showSettingsWindow()">
    <div class="timezone" *ngIf="securityContext.serverTimeIndicator">{{ timeZone }}</div>
    <div class="time" *ngIf="securityContext.serverTimeIndicator">{{ dateTime }}</div>
  </div>
  
  <span class="pvi">[ v{{productVersion}} ]</span>
  <i class="fas fa-sign-out-alt logout" title="Logout"
     (click)="this.showLogoutDialog()"
  ></i>
</div>



<dx-popup [width]="400"
          [height]="200"
          title="Log-out Dialog"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="false"
          [visible]="isLogoutConfirmVisible"
          (onHidden)="isLogoutConfirmVisible = false">
  <div *dxTemplate="let data of 'content'"
       style="position: relative">
    <div>
      <p class="logout-confirm">Are you sure you want to log-out?</p>
    </div>
    <div class="logout-dialog-buttons">
      <dx-button class="logout-dialog-button yes" text="Yes" (onClick)="logoutUser()"
                 [disabled]="isLoading"
      ></dx-button>
      <dx-button class="logout-dialog-button no" text="No"
                 (onClick)="isLogoutConfirmVisible = false"
                 [disabled]="isLoading"
      ></dx-button>
    </div>
    <dx-load-panel [visible]="isLoading"></dx-load-panel>
  </div>
</dx-popup>

<dx-popup [width]="600"
          [height]="250"
          title="Set Server Time"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="true"
          [visible]="isSettingsWindowVisible"
          (onHidden)="onSettingsWindowClosed()">
  <div *dxTemplate="let data of 'content'" id="group-exit_confirmation-dialog"
       style="position: relative">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Country:</div>
        <div class="dx-field-value">
          <dx-select-box [items]="timeZoneFamilies"
                         displayExpr="displayName"
                         [(value)]="selectedTimeZoneFamily"
          ></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Time Zone:</div>
        <div class="dx-field-value">
          <dx-select-box [items]="selectedTimeZoneFamily?.timeZoneDescriptors"
                         displayExpr="displayName"
                         [(value)]="selectedTimeZoneDescriptor"
          ></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Set Base Date:</div>
        <div class="dx-field-value">
          <dx-text-box
            mask="00/00/0000 00:00:00"
            maskChar="-"
            [text]="baseDate"
            [value]="baseDate"
            [isValid]="isBaseDateValid"
            [useMaskedValue]="true"
            (onValueChanged)="onBaseDateChanged($event)"
            maskInvalidMessage="Invalid date. Correct format: 'MM/dd/yyyy hh:mm:ss'"
          ></dx-text-box>
        </div>
      </div>
    </div>
    <div style="position: absolute; bottom: 20px; right: 10px">
      <dx-button text="Adjust Time"
                 [disabled]="!canAdjustTime || isLoading"
                 style="margin-right: 20px"
                 (onClick)="onAdjustTimeClicked()"
      ></dx-button>
      <dx-button text="Reset Time"
                 [disabled]="isLoading"
                 (onClick)="onResetTimeClicked()"
      ></dx-button>
    </div>
    <dx-load-panel [visible]="isLoading"></dx-load-panel>
  </div>
</dx-popup>
