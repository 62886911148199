<div *ngIf="this.visible" class="portoflio-section" [style.height.px]="this.gridHeight" #container>

   <div class="header">
      <ets-section-header 
         [header]="this.header">
      </ets-section-header>
   </div>

   <div class="body">
      
      <!-- <div class="sidebox left">
         <ets-automation-cp-portfolio-sidebox #sidebox>
         </ets-automation-cp-portfolio-sidebox>
      </div> -->
      
      <div class="portfolio">
         <ag-grid-angular style="width: 100%; height: 100%" 
            class="ag-theme-balham-dark"
            [gridOptions]="this.gridOptions">
         </ag-grid-angular>
      </div>

   </div>

   <dx-load-panel 
      [visible]="this.isLoading" 
      [position]="{my: 'center', at: 'center', of: container}"
      >
   </dx-load-panel>
</div>
