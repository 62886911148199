import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Enumerable from 'linq';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged, isValidNumber, isVoid } from 'projects/shared-components/utils';

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday';

@Component({
   selector: 'ets-checklist-day-of-year-condition',
   template: `
<div class="container">
   <dx-drop-down-box
      [disabled]="this.disabled"
      [dropDownOptions]="{width: 300}"
      fieldTemplate="field"
      (onOpened)="this.onOpened()"
      (onClosed)="this.onClosed()"
      >
      <div *dxTemplate="let data of 'field'">
         <dx-text-box 
            [value]="this.dayOfYear" 
            [readOnly]="true"
            >
         </dx-text-box>
      </div>

      <div *dxTemplate="let data of 'content'">
         <div class="day-of-year-picker">
            <div class="month">
               <div class="label">Month</div>
               <div class="input">
                  <dx-select-box
                     [items]="this.months"
                     [(value)]="this.selectedMonth"
                     [showClearButton]="true"
                     >
                  </dx-select-box>
               </div>
            </div>
            <div class="day">
               <div class="label">Day</div>
               <dx-select-box
                  [items]="this.days"
                  [(value)]="this.selectedDay"
                  [showClearButton]="true"
                  >
               </dx-select-box>
            </div>
         </div>

         <ets-timezone-picker
            [(timezone)]="this.timezone"
            [flow]="'col'">
         </ets-timezone-picker>
      </div>
   </dx-drop-down-box>
   
</div>
   `,
   styles: [`
   .day-of-year-picker {
      display: flex;
   }

   .month {
      flex: 2;
   }

   .day {
      flex: 1;
   }
   `],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistDayOfYearConditionComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }
   private _oldValue: string;

   @Input() disabled: boolean;

   months: string[] = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
   ];

   
   private _selectedMonth : string;
   get selectedMonth() : string {
      return this._selectedMonth;
   }

   @DetectSetterChanges({delay: 1})
   set selectedMonth(v : string) {
      this._selectedMonth = v;
      this.days = this.updateDays();  
   }

   days: number[];

   selectedDay: number;

   timezone: string;

   get dayOfYear(): string {
      if (!this.selectedMonth) {
         return undefined;
      }

      if (!this.selectedDay) {
         return undefined;
      }

      return `${this.selectedMonth}, ${this.selectedDay}`;
   }

   get value(): string {
      if (!this.selectedMonth) {
         return undefined;
      }

      if (!this.selectedDay) {
         return undefined;
      }

      if (!this.timezone) {
         return undefined;
      }
      const val = `${this.selectedMonth}|${this.selectedDay}|${this.timezone}`;
      return val;
   }

   @Input()
   set value(value: string) {

      if (isVoid(value)) {
         this.reset();
         return;
      }

      if (value.indexOf('|') < 0) {
         this.reset();
         return;
      }

      const parts = value.split('|');

      if (parts.length < 3) {
         this.reset();
         return;
      }

      this.selectedMonth = parts[0];
      this.selectedDay = parseInt(parts[1]);
      this.timezone = parts[2];
   }

   @Output()
   valueChange = new EventEmitter<string>();

   @Output() 
   valueChanged = new EventEmitter<DxValueChanged<string>>();

   @DetectMethodChanges()
   ngOnInit(): void { }

   @DetectMethodChanges()
   onValueChanged(arg: DxValueChanged<DayOfWeek>) {
      if (isVoid(this.timezone)) {
         return;
      }
      this.valueChange.emit(arg.value);
      this.valueChanged.emit(arg);
   }

   onOpened() {
      this._oldValue = this.value;
   }

   onClosed() {
      if (this.value === this._oldValue) {
         return;
      }

      if (isVoid(this.timezone)) {
         return;
      }
      this.valueChange.emit(this.value);
      this.valueChanged.emit({value: this.value, event: 'ets'});
   }

   private reset() {
      this.selectedMonth = undefined;
      this.selectedDay = undefined;
      this.timezone = undefined;
   }

   private updateDays() {
      if (isVoid(this.selectedMonth)) {
         return [];
      }
      
      switch(this.selectedMonth) {
         case 'January':
         case 'March':
         case 'May':
         case 'July':
         case 'August':
         case 'October':
         case 'December':
            return Enumerable.range(1, 31, 1).toArray();
         case 'February':
            return Enumerable.range(1,29, 1).toArray();
         default:
            return Enumerable.range(1, 30, 1).toArray();
      }
   }
}
