import { MarketSide } from '../../../trading-model/market-side.enum';
import { OrderType } from '../../../trading-model/order-type.enum';
import { ProtocolCommand } from '../../../service-model/protocol-command.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';
import { OrderDuration } from 'projects/shared-components/multi-trade-pad/multi-trade-pad.model';

export class SendManualOrder implements ProtocolCommand {
   constructor(
      public readonly terminalId: string,
      public readonly tradingInstrumentCode: string,
      public readonly orderType: OrderType,
      public readonly orderDuration: OrderDuration,
      public readonly side: MarketSide,
      public readonly quantity: number,
      public readonly price?: number,
      public readonly accountId?: string,
      public portfolioId?: string,
      public comboId?: string,
      public comboGroupId?: string,
      public guiToken?: string
   ) {
      //
   }

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.SendManualOrder`;
   }
}
