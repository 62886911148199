import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class MasterExit implements ProtocolCommand {
  public static secureElementId = '3ee7bd04-04c1-4407-96b5-87ed9edf2def';

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.MasterExit`;
  }
}
