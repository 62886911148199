import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EtsConstants } from '../../ets-constants.const';
import { LastQuoteCacheService } from '../../last-quote-cache.service';
import { ManualTradingBackendService } from '../manual-trading-backend.service';
import { ManualTradingSecurityContextService } from '../manual-trading-security-context.service';
import { OrdersAndTradesBaseComponent } from './orders-and-trades-base.component';
import { MessageBusService } from '../../message-bus.service';
import { SettingsStorageService } from '../../settings-storage-service.service';
import { OrderDto } from '../../shell-communication/dtos/order-dto.class';
import { OrderStateSnapshotDto } from '../../shell-communication/dtos/order-state-snapshot-dto.class';
import { TradeDto } from '../../shell-communication/dtos/trade-dto.class';
import { GetWorkingOrdersWeb, GetTradesWeb, GetOrderStateSnapshotsWeb } from '../../shell-communication/shell-operations-protocol';
import { TimestampsService } from '../../timestamps.service';
import { TradingInstrumentDisplayNameService } from '../../trading-instruments/trading-instrument-display-name.service';
import { isNullOrUndefined } from '../../utils';
import {UserSettingsService} from "../../user-settings.service";

@Component({
   selector: 'ets-future-orders',
   templateUrl: './orders-and-trades-base.component.html',
   styleUrls: ['./orders-and-trades-base.component.scss'],
   providers: [ManualTradingSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FutureOrdersComponent extends OrdersAndTradesBaseComponent {

   constructor(
      _changeDetector: ChangeDetectorRef,
      _layoutService: UserSettingsService,
      _messageBus: MessageBusService,
      _backendClient: ManualTradingBackendService,
      _tickerDisplayNameService: TradingInstrumentDisplayNameService,
      _toastr: ToastrService,
      _lastQuoteCache: LastQuoteCacheService,
      _timestampsService: TimestampsService,
      securityContext: ManualTradingSecurityContextService,
   ) {
      super(_changeDetector, _layoutService, _messageBus, _backendClient, _tickerDisplayNameService
         , _toastr, _lastQuoteCache, _timestampsService, securityContext);
   }

   get linkedContext(): 'Manual Positions' | 'Portfolios' | 'Symbol' | 'All' | 'Future' {
      return 'Future';
   }

   changeIsLinked(): void {
      //
   }
   
   protected prepareWorkingOrdersQuery(): GetWorkingOrdersWeb {
      return new GetWorkingOrdersWeb(
         EtsConstants.strategies.manualStrategyId,
         'Future',
         null,
         null,
         null,
      );
   }
   
   protected prepareTradesQuery(historyStop?: number, batchSize?: number): GetTradesWeb {
      return null;
   }
   
   protected prepareSnapshotsQuery(historyStop?: number, batchSize?: number): GetOrderStateSnapshotsWeb {
      return null;
   }
   
   protected filterTradingEntities<T extends TradeDto | OrderStateSnapshotDto | OrderDto>(entities: T[]): T[] {
      const filtered: T[] = entities.filter( (x: any) => !isNullOrUndefined(x.futureSettings));
      return filtered;
   }
   
   protected subscribeSpecificMessages(): void {
      //
   }
   
   protected resetLinkedContext(): void {
      //
   }
}
