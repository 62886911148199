export const CommanderActionsList = {
  StartStrategiesByFamily: {
    id: 'bcf088dd-d5ea-4d32-b9c0-c0aa216dec2b',
    text: 'Start Strategies By Family'
  },
  ExitStrategiesByFamily: {
    id: '942ddb03-074f-4c15-ad52-76a87c1eebbd',
    text: 'Exit Strategies By Family'
  },
  StartSpecificStrategy: {
    id: 'f563f50a-5ad3-4b10-be12-ab8c1252f575',
    text: 'Start Specific Strategy'
  },
  ExitSpecificStrategy: {
    id: '1339844d-c421-4080-b7f7-18d37cb055bb',
    text: 'Exit Specific Strategy'
  },
};