import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';

import { DateTimePickerComponent } from './datetime-picker.component';

@NgModule({
   imports: [
      DevExtremeModule,
      CommonModule,
      TimezonePickerModule
   ],
   exports: [DateTimePickerComponent],
   declarations: [DateTimePickerComponent],
   providers: [],
})
export class DateTimePickerModule { }
