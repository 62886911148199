import { NgModule } from '@angular/core';
import { SimexSettingsComponent } from './simex-settings.component';
import { AgGridModule } from 'ag-grid-angular';
import { EtsSymbolPickerModule } from '../../../../shared-components/symbol-picker/symbol-picker.module';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      EtsSymbolPickerModule,
      AgGridModule.withComponents([]),
      DevExtremeModule
   ],
   exports: [SimexSettingsComponent],
   declarations: [SimexSettingsComponent],
   providers: []
})
export class EtsSimexSettingsModule { }
