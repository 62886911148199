// tslint:disable: no-string-literal
import { ParametersControlBase } from '../../../parameters-control-base';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { DetectParameterChanges, isTruthy } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { ICommonDispositionParameters } from '../../../common-disposition-parameters';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { TrendLimitParametersViewComponent } from './trendlimit-parameters-view.component';

export enum TrendLimitMode {
   None,
   Bull,
   BullBear,
   Bear
}

export class TrendLimitAlgoParametersControl extends ParametersControlBase<TrendLimitParametersViewComponent> {
   
   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);

      for (let i = 1; i < 51; i++) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
      }

      for (let i = 60; i < 110; i += 10) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
      }

      for (let i = 125; i < 225; i += 25) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
      }

      for (let i = 250; i < 550; i += 50) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
      }
   }

   private _symbol: TradingInstrument;
   private _multiplier: number;
   private _factor: number;
   private _mode: TrendLimitMode;

   filter: number;
   tradeLevel: number;
   maxPosition: number;
   exitOnStopOut = false;
   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;
   factorItems: number[] = [];
   multiplierItems: number[] = [];
   tradeLevelItems: number[] = [];
   maxPositionItems: number[] = [];
   filterItems: number[] = [];
   modeItems = [
      { text: 'Bull', value: TrendLimitMode.Bull },
      { text: 'Bear', value: TrendLimitMode.Bear },
      { text: 'Bull/Bear', value: TrendLimitMode.BullBear },
   ];

   get symbol(): TradingInstrument {
      return this._symbol;
   }

   @DetectParameterChanges()
   set symbol(value: TradingInstrument) {
      if (this._symbol === value) {
         return;
      }
      const oldOne = this._symbol;
      this._symbol = value;
      const newOne = value;
      if (isTruthy(newOne)) {
         if (isTruthy(oldOne)) {
            if (newOne.underlying !== oldOne.underlying) {
               this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
            }
         } else {
            this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
         }
      }
   }

   get multiplier(): number {
      return this._multiplier;
   }

   @DetectParameterChanges()
   set multiplier(value: number) {
      if (this._multiplier === value) {
         return;
      }
      this._multiplier = value;
      this._updateMaxPositionsItems();
   }

   get factor(): number {
      return this._factor;
   }

   @DetectParameterChanges()
   set factor(value: number) {
      if (this._factor === value) {
         return;
      }
      this._factor = value;
      this._updateMaxPositionsItems();
   }

   get algoId(): string {
      return EtsConstants.algorithms.trendLimitAlgoId;
   }

   get mode(): TrendLimitMode {
      return this._mode;
   }

   @DetectParameterChanges()
   set mode(v: TrendLimitMode) {
      this._mode = v;
      if (v !== TrendLimitMode.BullBear) {
         this.filter = null;
      }
   }

   get canSetFilter(): boolean {
      return this.mode === TrendLimitMode.BullBear;
   }

   getSymbolToSet(): TradingInstrument {
      return this.symbol;
   }

   _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker : null;
      params['filter'] = isTruthy(this.filter) ? this.filter + '' : null;
      params['tradelevel'] = isTruthy(this.tradeLevel) ? this.tradeLevel + '' : null;
      params['multiplier'] = isTruthy(this.multiplier) ? this.multiplier + '' : null;
      params['factor'] = isTruthy(this.factor) ? this.factor + '' : null;
      params['maxposition'] = isTruthy(this.maxPosition) ? this.maxPosition + '' : null;
      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['istradethroughmit'] = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;
      params['mode'] = isTruthy(this.mode) ? this.mode + '' : null;
      params['exitonstopout'] = isTruthy(this.exitOnStopOut) ? this.exitOnStopOut + '' : null;

      return params;
   }

   _SetParameters(dto: StrategyDto) {
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }
      if (isTruthy(params['filter'])) {
         this.filter = parseFloat(params['filter']);
      }
      if (isTruthy(params['tradelevel'])) {
         this.tradeLevel = parseFloat(params['tradelevel']);
      }
      if (isTruthy(params['multiplier'])) {
         this.multiplier = parseInt(params['multiplier']);
      }
      if (isTruthy(params['factor'])) {
         this.factor = parseFloat(params['factor']);
      }
      if (isTruthy(params['maxposition'])) {
         this.maxPosition = parseInt(params['maxposition']);
      }
      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughmit'])) {
         this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
      }
      if (isTruthy(params['exitonstopout'])) {
         this.exitOnStopOut = params['exitonstopout'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mode'])) {
         this.mode = parseInt(params['mode']);
      }
   }

   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!isTruthy(this.symbol)) {
         errors.push('Symbol is required');
      }

      if (!isTruthy(this.filter)) {
         if (this.mode === TrendLimitMode.BullBear) {
            errors.push('Filter is required');
         }
      }

      if (!isTruthy(this.tradeLevel) || this.tradeLevel === 0) {
         errors.push('TradeLevel is required');
      }

      if (!isTruthy(this.multiplier) || this.multiplier === 0) {
         errors.push('Multiplier is required');
      }

      if (!isTruthy(this.factor) || this.factor === 0) {
         errors.push('Factor is required');
      }

      if (!isTruthy(this.maxPosition) || this.maxPosition === 0) {
         errors.push('MaxPosition is required');
      } else {
         if (!this._checkMaxPosition()) {
            errors.push('Incorrect MaxPosition value');
         }
      }

      if (!isTruthy(this.mode)) {
         errors.push('Mode is required');
      }

      if (!isTruthy(this.tradingObjectives.accumulatedNpo) && !isTruthy(this.tradingObjectives.tradeNpo)) {
         errors.push('NPO is required');
      }

      return errors;
   }

   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean) {
   }

   onCustomNumberCreated(data, collection: number[]) {
      const newItem = parseFloat(data.text);
      if (!isNaN(newItem)) {
         const isNew = collection.indexOf(newItem) === -1;
         data.customItem = newItem;
         if (isNew) {
            collection.push(newItem);
         }
      }
   }

   private _updateTickSizeDependentDropdowns(tickSize: number, precision: number) {
      this.filter = null;
      this.tradeLevel = null;
      this.filterItems = [];
      this.tradeLevelItems = [];

      const formatter = new Intl.NumberFormat('en-IN', {
         minimumFractionDigits: precision,
         maximumFractionDigits: precision
      });

      for (let i = 1; i < 50; i++) {
         let step = i * tickSize;
         step = parseFloat(formatter.format(step));
         this.filterItems.push(step);
         this.tradeLevelItems.push(step);
      }
   }

   private _updateMaxPositionsItems() {
      if (!isTruthy(this.factor) || !isTruthy(this.multiplier)
         || this.multiplier === 0) {
         this.maxPosition = null;
         this.maxPositionItems = [];

         return;
      }

      const newItems = [];

      for (let i = 1; i < 50; i++) {
         if (this.factor === 1) {
            const item = i * this.multiplier;
            newItems.push(item);
         } else {
            const item = Math.pow(this.factor, i) * this.multiplier;
            newItems.push(item);
         }
      }

      this.maxPositionItems = newItems;
      this.maxPosition = null;
   }

   private _checkMaxPosition(): boolean {
      const correctMaxPosition: number[] = [];
      if (isTruthy(this.factor) && isTruthy(this.multiplier)) {
         for (let i = 0; i < 100; i++) {
            if (this.factor === 1) {
               const val = this.multiplier * (i + 1);
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            } else {
               const pow = Math.pow(this.factor, i);
               const val = this.multiplier * pow;
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            }
         }
      }

      const curMP = this.maxPosition || 0;
      let mpIsCorrect = false;
      for (const mp of correctMaxPosition) {
         if (mp === curMP) {
            mpIsCorrect = true;
            break;
         }
      }
      return mpIsCorrect;
   }

   onStrategyTerminalChanged(): void {
   }

}
