import {GridSettingsProvider} from "./grid-settings.provider";
import {PricingGridStrategyColumn} from "./pricing-grid-strategy.column";
import {makeOptionTicker, OptionType, parseOptionTicker} from "../../options-common/options.model";
import {isNullOrUndefined, isTruthy, isValidNumber, isVoid} from "../../utils";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";
import {GreeksDto, StrategyPriceDto} from "../../shell-communication/shell-dto-protocol";
import {PricingGridLeg} from "./pricing-grid.leg";
import {MarketSide} from "../../trading-model/market-side.enum";
import {PricingGridStrategy} from "./pricing-grid.strategy";
import {HedgePosition} from "../../hedging-grid/data-model/hedge-position";

const SelectedCellBgColor = 'gray';

export class PricingGridRow {

    constructor(
        public readonly rowStrike: number,
        public readonly gridSettings: GridSettingsProvider
    ) {

        this.callOtm = (rowStrike - gridSettings.atmStrike) / gridSettings.atmStrike;
        this.callOtmPts = (rowStrike - gridSettings.atmStrike);

        this.putOtm = (gridSettings.atmStrike - rowStrike) / gridSettings.atmStrike;
        this.putOtmPts = (gridSettings.atmStrike - rowStrike);

        this.isAtm = rowStrike === gridSettings.atmStrike;

        this.makeRowTickers();
    }

    private _columnByStrategyCode: { [ix: string]: PricingGridStrategyColumn[] } = {};


    isCallsInRange: boolean;
    isPutsInRange: boolean;
    isAtm: boolean;
    callTicker: string;
    putTicker: string;
    callDelta: number;
    putDelta: number;
    callIV: number;
    putIV: number;
    callPrice: number;
    callBid: number;
    callAsk: number;
    putPrice: number;
    putBid: number;
    putAsk: number;
    callOtm: number;
    callOtmPts: number;
    putOtm: number;
    putOtmPts: number;

    callPriceHighlightColor: string;
    putPriceHighlightColor: string;
    callPriceQty: number;
    putPriceQty: number;

    callTransPriceHighlightColor: string;
    callTransQty: number;
    putTransPriceHighlightColor: string;
    putTransQty: number;

    callOwnPriceHighlightColor: string;
    callOwnQty: number;
    putOwnPriceHighlightColor: string;
    putOwnQty: number;


    setPricingColumns(): void {
        this.setPricingColumnsComparison();
        this.updateRowPrices();
    }

    setPricingColumnsComparison() {
        this.gridSettings
            .callStrategies
            .forEach(strategy => this.addStrategyColumn('calls', strategy));

        this.gridSettings
            .putStrategies
            .forEach(strategy => this.addStrategyColumn('puts', strategy));
    }


    deleteAllPricingColumns() {
        const pricingColumns = this.getPricingColumnsAttributes();
        pricingColumns.forEach(attr => {
            delete this[attr];
        });
        this._columnByStrategyCode = {};
    }


    addStrategyColumn(targetSide: 'calls' | 'puts', str: PricingGridStrategy)
        : string {

        const type = targetSide === 'calls' ? OptionType.Call : OptionType.Put;

        const pricingColumn =
            new PricingGridStrategyColumn(this, type, str);

        const spreadWidth = pricingColumn.spreadWidth;

        const suffix = targetSide === 'calls' ? 'call' : 'put';

        const attributeName = `pricing-${str.strategyId}-${suffix}`;

        this[attributeName] = pricingColumn;

        let container = this._columnByStrategyCode[pricingColumn.strategyCode];

        if (isVoid(container)) {
            container = [];
            this._columnByStrategyCode[pricingColumn.strategyCode] = container;
        }

        container.push(pricingColumn);

        const code = this.getColumnStrategyCodeForSubscription(pricingColumn,
            attributeName,
            'sub');

        if (!isVoid(code)) {
            return code;
        }
    }


    removeStrategyColumn(str: PricingGridStrategy, mode: 'comparison' | 'plain'): any[] {

        const columns = [];

        if (mode === 'comparison') {

            const callColumnName = `pricing-${str.strategyId}-call`;
            const callColumn = this[callColumnName];

            if (callColumn) {
                columns.push(callColumn);

                const container = this._columnByStrategyCode[callColumn.strategyCode];
                if (!isVoid(container)) {
                    const ix = container.indexOf(callColumn);
                    if (ix >= 0) {
                        container.splice(ix, 1);
                    }
                }
            }

            delete this[callColumnName];

            const putColumnName = `pricing-${str.strategyId}-put`;
            const putColumn = this[putColumnName];

            if (putColumn) {
                columns.push(putColumn);

                const container = this._columnByStrategyCode[putColumn.strategyCode];
                if (!isVoid(container)) {
                    const ix = container.indexOf(putColumn);
                    if (ix >= 0) {
                        container.splice(ix, 1);
                    }
                }
            }
            delete this[putColumnName];

        }

        return columns;
    }

    resetStrategyLegHighlights(targetSide: "calls" | "puts", str: PricingGridStrategy) {
        if (str.targetSide === 'puts') {
            this.putPriceQty = undefined;
            this.putPriceHighlightColor = undefined;

            this.putTransQty = undefined;
            this.putTransPriceHighlightColor = undefined;

        } else if (str.targetSide === 'calls') {
            this.callPriceQty = undefined;
            this.callPriceHighlightColor = undefined;

            this.callTransQty = undefined;
            this.callTransPriceHighlightColor = undefined;
        }
    }

    resetCellHighlights(): string[] {
        const cols2update = [];

        // Price/Outcome
        if (!isNullOrUndefined(this.callPriceHighlightColor)) {
            this.callPriceHighlightColor = null;
            this.callPriceQty = null;
            cols2update.push('callPrice');
        }

        if (!isNullOrUndefined(this.putPriceHighlightColor)) {
            this.putPriceHighlightColor = null;
            this.putPriceQty = null;
            cols2update.push('putPrice');
        }

        // Trans
        if (!isNullOrUndefined(this.callTransPriceHighlightColor)) {
            this.callTransPriceHighlightColor = undefined;
            this.callTransQty = undefined;
            cols2update.push('trans-call');
        }

        if (!isNullOrUndefined(this.putTransPriceHighlightColor)) {
            this.putTransPriceHighlightColor = undefined;
            this.putTransQty = undefined;
            cols2update.push('trans-put');
        }

        // // Own
        // if (!isNullOrUndefined(this.callOwnPriceHighlightColor)) {
        //     this.callOwnPriceHighlightColor = undefined;
        //     this.callOwnQty = undefined;
        //     cols2update.push('own-call');
        // }
        //
        // if (!isNullOrUndefined(this.putOwnPriceHighlightColor)) {
        //     this.putOwnPriceHighlightColor = undefined;
        //     this.putOwnQty = undefined;
        //     cols2update.push('own-put');
        // }


        const pricingColumns = this.getPricingColumnsAttributes();

        pricingColumns.forEach(attr => {

            const col: PricingGridStrategyColumn = this[attr];

            if (!col) {
                return;
            }

            let shouldAddCol = false;

            if (col.payForIt) {
                col.payForIt = false;
                shouldAddCol = true;
            }

            if (!isNullOrUndefined(col.highlightColor)) {
                col.highlightColor = undefined;
                shouldAddCol = true;
            }

            if (col.percentageProfit) {
                col.percentageProfit = false;
                shouldAddCol = true;
            }

            if (shouldAddCol) {
                cols2update.push(col.columnId);
            }

        });

        return cols2update;
    }


    onQuote(quotes: QuoteDto[]): boolean {
        let affected: boolean;

        const callQuote = quotes.find(x => x.ticker === this.callTicker);
        if (callQuote) {

            affected = true;

            this.callBid = isTruthy(callQuote.bid) ? callQuote.bid : undefined;

            this.callAsk = isTruthy(callQuote.ask) ? callQuote.ask : undefined;

            this.callPrice = callQuote.mid;

        }

        const putQuote = quotes.find(x => x.ticker === this.putTicker);
        if (putQuote) {
            affected = true;

            this.putBid = isTruthy(putQuote.bid) ? putQuote.bid : undefined;

            this.putAsk = isTruthy(putQuote.ask) ? putQuote.ask : undefined;

            this.putPrice = putQuote.mid;
        }

        return affected;
    }


    onGreeks(x: GreeksDto) {

        if (!x) {
            return;
        }

        if (!this.gridSettings.showItmData) {
            if (x.optionType === OptionType.Put) {
                if (isNullOrUndefined(this.putOtm)) {
                    return;
                }
            } else if (x.optionType === OptionType.Call) {
                if (isNullOrUndefined(this.callOtm)) {
                    return;
                }
            }
        }

        if (x.optionType === OptionType.Put) {
            if (this.putTicker !== x.ticker) {
                return;
            }

            this.putDelta = x.delta;
            this.putIV = x.impliedVolatility;


        } else if (x.optionType === OptionType.Call) {

            if (this.callTicker !== x.ticker) {
                return;
            }

            this.callDelta = x.delta;
            this.callIV = x.impliedVolatility;

        }
    }


    onStrategyPrice(x: StrategyPriceDto): string[] {

        const container: PricingGridStrategyColumn[]
            = this._columnByStrategyCode[x.strategyCode];

        if (isVoid(container)) {
            console.error(`Missing strategy price col. RowStrike=${this.rowStrike}. Strategy Code=${x.strategyCode}`);
            return;
        }

        const affectedColumns = container.map(col => {
            col.price = x.price * -1 * col.hcfMultiplier;
            this.highlightRMultiple();
            this.highlightPercentageProfit();

            const colId = col.columnId;
            return colId;
        });

        return affectedColumns;
    }


    //#region Highlight Methods

    makeHighlights() {

        this.highlightTargetRange();
        this.highlightRMultiple();
        this.highlightPercentageProfit();
        this.highlightPayForIt();
        this.highlightStrategyLegs();
        if (this.gridSettings.mode === 'pmd') {
            this.highlightOwnLegs();
        }

    }


    highlightTargetRange() {
        if (this.gridSettings.isCustomRange) {
            this.highlightCustomTargetRange();
        } else {
            this.highlightAutoTargetRange();
        }
    }

    private highlightAutoTargetRange() {

        const callOffset = this.gridSettings.callOffset || NaN;
        const callRange = this.gridSettings.callRange || NaN;

        const putOffset = this.gridSettings.putOffset || NaN;
        const putRange = this.gridSettings.putRange || NaN;

        const atmStrike = this.gridSettings.atmStrike || NaN;

        const callsStart = atmStrike + callOffset;
        const callsEnd = callsStart + callRange;

        const putsStart = atmStrike - putOffset;
        const putsEnd = putsStart - putRange;

        let colorPut = false;
        let colorCall = false;

        if (isValidNumber(callsStart, true) && isValidNumber(callsEnd, true)) {
            colorCall =
                this.rowStrike >= Math.min(callsStart, callsEnd)
                && this.rowStrike <= Math.max(callsStart, callsEnd);
        }

        if (isValidNumber(putsStart, true) && isValidNumber(putsEnd, true)) {
            colorPut =
                this.rowStrike >= Math.min(putsStart, putsEnd)
                && this.rowStrike <= Math.max(putsStart, putsEnd);
        }

        if (colorCall) {
            if (this.gridSettings.callsPropagateRange) {
                colorPut = true;
            }
        }

        if (colorPut) {
            if (this.gridSettings.putsPropagateRange) {
                colorCall = true;
            }
        }

        this.isCallsInRange = colorCall;
        this.isPutsInRange = colorPut;
    }


    private highlightCustomTargetRange() {
        const callsStart = this.gridSettings.callsTargetRangeStart;
        const callsEnd = this.gridSettings.callsTargetRangeEnd;

        const putsStart = this.gridSettings.putsTargetRangeStart;
        const putsEnd = this.gridSettings.putsTargetRangeEnd;

        let colorPut = false;
        let colorCall = false;

        if (!isVoid(callsStart) && !isVoid(callsEnd)) {
            colorCall =
                this.rowStrike >= Math.min(callsStart, callsEnd)
                && this.rowStrike <= Math.max(callsStart, callsEnd);
        }

        if (!isVoid(putsStart) && !isVoid(putsEnd)) {
            colorPut =
                this.rowStrike >= Math.min(putsStart, putsEnd)
                && this.rowStrike <= Math.max(putsStart, putsEnd);
        }

        if (colorCall) {
            if (this.gridSettings.callsPropagateRange) {
                colorPut = true;
            }
        }

        if (colorPut) {
            if (this.gridSettings.putsPropagateRange) {
                colorCall = true;
            }
        }

        this.isCallsInRange = colorCall;
        this.isPutsInRange = colorPut;
    }

    highlightRMultiple() {

        this.getPricingColumnsAttributes()
            .forEach(attr => {

                const col: PricingGridStrategyColumn = this[attr];

                if (!col) {
                    return;
                }

                const spreadWidth = col.spreadWidth;

                const spreadPrice = Math.abs(col.price);
                const ratio = (spreadWidth - spreadPrice) / spreadPrice;

                col.rMultiple = ratio >= this.gridSettings.rMultiple;
            });
    }


    highlightPercentageProfit() {

        this.getPricingColumnsAttributes()
            .forEach(attr => {

                const col: PricingGridStrategyColumn = this[attr];

                if (!col) {
                    return;
                }

                if (!this.gridSettings.percentageProfit) {
                    col.percentageProfit = false;
                    return;
                }

                const spreadWidth = col.spreadWidth;
                const spreadPrice = Math.abs(col.price);

                const percent = this.gridSettings.percentageProfit / 100;
                const targetValue = Math.abs(spreadPrice / spreadWidth);

                col.percentageProfit = targetValue >= percent;
            });
    }


    highlightPayForIt() {

        if (!this.gridSettings.selectedCell) {
            return;
        }

        const field = this.gridSettings.selectedCell.colDef.field.split('.')[0];
        const fieldParts = field.split('-');
        const right = fieldParts[1]; // call/put
        const width = parseInt(fieldParts[2]);

        if (!right || !width) {
            return;
        }

        const selectedColumn: PricingGridStrategyColumn = this[field];

        if (!selectedColumn) {
            return;
        }

        if (selectedColumn.optionType === OptionType.Call) {
            if (this.rowStrike < selectedColumn.edgeStrike) {
                return;
            }
        }

        if (selectedColumn.optionType === OptionType.Put) {
            if (this.rowStrike > selectedColumn.edgeStrike) {
                return;
            }
        }

        this.getPricingColumnsAttributes()
            .forEach(attr => {

                if (attr === field) {
                    return;
                }

                const col: PricingGridStrategyColumn = this[attr];

                if (!col) {
                    return;
                }

                const columnsNameParts = attr.split('-');
                const colSpreadWidth = parseInt(columnsNameParts[2]);
                if (!colSpreadWidth) {
                    return;
                }

                const multiplier = Math.floor(width / colSpreadWidth);

                if (!multiplier) {
                    return;
                }

                const profit = Math.abs(col.price * multiplier);

                col.payForIt = profit >= Math.abs(selectedColumn.price);
            });
    }

    highlightStrategyLegs() {

        const theCell = this.gridSettings.selectedCell;

        if (isNullOrUndefined(theCell?.data)) {
            this.callPriceHighlightColor = undefined;
            this.callPriceQty = undefined;
            this.putPriceHighlightColor = undefined;
            this.putPriceQty = undefined;

            this.callTransPriceHighlightColor = undefined;
            this.callTransQty = undefined;
            this.putTransPriceHighlightColor = undefined;
            this.putTransQty = undefined;

            this.callOwnPriceHighlightColor = undefined;
            this.callOwnQty = undefined;
            this.putOwnPriceHighlightColor = undefined;
            this.putOwnQty = undefined;

            return;
        }

        const rowStrike = (theCell.data as PricingGridRow).rowStrike;

        if (this.rowStrike !== rowStrike) {
            return;
        }

        const objAttribute = theCell.colDef.field.split('.')[0];

        const column = this[objAttribute] as PricingGridStrategyColumn;

        if (!column) {
            return;
        }

        function getColor(leg: PricingGridLeg) {
            return leg.side === MarketSide.Buy ? '#00007c' : 'darkred';
        }

        function getPriceColor(leg: PricingGridLeg) {
            return leg.side === MarketSide.Buy ? '#5278ed' : 'red';
        }

        column.legs.forEach(leg => {

            if (leg.strike === this.rowStrike) {

                const legColor = getColor(leg);

                column.highlightColor = SelectedCellBgColor;

                let sign = 1;

                if (isValidNumber(leg.qty, true)) {
                    if (!isVoid(leg.side)) {
                        sign = leg.side === MarketSide.Buy ? 1 : -1;
                    }
                }

                // sign *= this.gridSettings.defaultQty || 1;

                if (theCell.colDef.field.indexOf('-call') >= 0) {

                    this.callPriceHighlightColor = legColor;
                    this.callPriceQty = leg.qty * sign;

                } else {

                    this.putPriceHighlightColor = legColor;
                    this.putPriceQty = leg.qty * sign;
                }

            } else {

                const aRow: PricingGridRow = this.gridSettings.pricingRows.find(row => row.rowStrike === leg.strike);

                if (aRow) {
                    const aColumn = aRow[objAttribute];

                    if (aColumn) {

                        const legColor = getColor(leg);

                        let sign = 1;

                        if (isValidNumber(leg.qty, true)) {
                            if (!isVoid(leg.side)) {
                                sign = leg.side === MarketSide.Buy ? 1 : -1;
                            }
                        }

                        // sign *= this.gridSettings.defaultQty || 1;

                        if (theCell.colDef.field.indexOf('-call') >= 0) {

                            aRow.callPriceHighlightColor = legColor;
                            aRow.callPriceQty = leg.qty * sign;

                        } else {

                            aRow.putPriceHighlightColor = legColor;
                            aRow.putPriceQty = leg.qty * sign;

                        }
                    }
                }
            }
        });

        if (this.gridSettings.mode === 'pmd') {

            column.ownLegs.forEach(leg => {

                let sign = 1;
                if (isValidNumber(leg.qty, true)) {
                    if (!isVoid(leg.side)) {
                        sign = leg.side === MarketSide.Buy ? 1 : -1;
                    }
                }

                if (leg.strike === this.rowStrike) {

                    const legColor = getColor(leg);

                    column.highlightColor = SelectedCellBgColor;

                    if (theCell.colDef.colId?.indexOf('-call') >= 0) {

                        this.callOwnPriceHighlightColor = legColor;
                        this.callOwnQty = leg.qty * sign;

                    } else {

                        this.putOwnPriceHighlightColor = legColor;
                        this.putOwnQty = leg.qty * sign;
                    }

                } else {

                    const aRow: PricingGridRow = this.gridSettings.pricingRows
                        .find(row => row.rowStrike === leg.strike);

                    if (aRow) {
                        const aColumn = aRow[objAttribute];

                        if (aColumn) {

                            const legColor = getColor(leg);

                            if (theCell.colDef.colId?.indexOf('-call') >= 0) {

                                aRow.callOwnPriceHighlightColor = legColor;
                                aRow.callOwnQty = leg.qty * sign;

                            } else {

                                aRow.putOwnPriceHighlightColor = legColor;
                                aRow.putOwnQty = leg.qty * sign;

                            }
                        }
                    }
                }
            });


            column.transLegs.forEach(leg => {

                let sign = 1;
                if (isValidNumber(leg.qty, true)) {
                    if (!isVoid(leg.side)) {
                        sign = leg.side === MarketSide.Buy ? 1 : -1;
                    }
                }

                if (leg.strike === this.rowStrike) {

                    const legColor = getColor(leg);

                    column.highlightColor = SelectedCellBgColor;

                    if (theCell.colDef.colId?.indexOf('-call') >= 0) {

                        this.callTransPriceHighlightColor = legColor;
                        this.callTransQty = leg.qty * sign;

                    } else {

                        this.putTransPriceHighlightColor = legColor;
                        this.putTransQty = leg.qty * sign;
                    }

                } else {

                    const aRow: PricingGridRow = this.gridSettings.pricingRows
                        .find(row => row.rowStrike === leg.strike);

                    if (aRow) {
                        const aColumn = aRow[objAttribute];

                        if (aColumn) {

                            const legColor = getColor(leg);

                            if (theCell.colDef.colId?.indexOf('-call') >= 0) {

                                aRow.callTransPriceHighlightColor = legColor;
                                aRow.callTransQty = leg.qty * sign;

                            } else {

                                aRow.putTransPriceHighlightColor = legColor;
                                aRow.putTransQty = leg.qty * sign;

                            }
                        }
                    }
                }
            });
        }
    }

    highlightOwnLegs(targetSide: 'calls' | 'puts' | undefined = undefined) {

        if (isVoid(targetSide)) {
            this.highlightOwnLegs('calls');
            this.highlightOwnLegs('puts');
            return;
        }

        function getColor(leg: PricingGridLeg) {
            return leg.side === MarketSide.Buy ? '#00007c' : 'darkred';
        }

        // Reset highlights first
        if (targetSide === 'calls') {

            this.callOwnPriceHighlightColor = undefined;
            this.callOwnQty = undefined;

        } else {

            this.putOwnPriceHighlightColor = undefined;
            this.putOwnQty = undefined;
        }

        const type = targetSide === 'calls' ? OptionType.Call : OptionType.Put;

        let hedges : HedgePosition[] = [];

        if (this.gridSettings.pmdHedgeMode === 'existing') {
            hedges = this.gridSettings.getExistingHedges(type).filter(x => !isVoid(x));
        } else if (this.gridSettings.pmdHedgeMode === 'custom') {
            hedges = this.gridSettings
                .getCustomLegs(targetSide).map(x => x.asHedgePosition())
                .filter(x => !isVoid(x));
        }

        const currentState = hedges
            .map(hp => {
                return {
                    ticker: hp.ticker,
                    qty: hp.qty
                }
            });

        const ownLegs = currentState.map(x => {
            const optionTicker = parseOptionTicker(x.ticker);
            return {
                ticker: x.ticker,
                qty: Math.abs(x.qty),
                side: x.qty > 0 ? MarketSide.Buy : MarketSide.Sell,
                strike: optionTicker?.strike
            } as PricingGridLeg
        });

        ownLegs.forEach(leg => {

            let sign = 1;

            if (isValidNumber(leg.qty, true)) {
                if (!isVoid(leg.side)) {
                    sign = leg.side === MarketSide.Buy ? 1 : -1;
                }
            }

            if (leg.strike === this.rowStrike) {

                const legColor = getColor(leg);

                if (targetSide === 'calls') {

                    this.callOwnPriceHighlightColor = legColor;
                    this.callOwnQty = leg.qty * sign;

                } else {

                    this.putOwnPriceHighlightColor = legColor;
                    this.putOwnQty = leg.qty * sign;
                }

            } else {

                const aRow: PricingGridRow = this.gridSettings.pricingRows
                    .find(row => row.rowStrike === leg.strike);

                if (aRow) {

                    const legColor = getColor(leg);

                    if (targetSide === 'calls') {

                        aRow.callOwnPriceHighlightColor = legColor;
                        aRow.callOwnQty = leg.qty * sign;

                    } else {

                        aRow.putOwnPriceHighlightColor = legColor;
                        aRow.putOwnQty = leg.qty * sign;

                    }

                }
            }
        });
    }


    //#endregion


    getTickers(): string[] {
        return [this.callTicker, this.putTicker];
    }


    getStrategyCodes(purpose: 'sub' | 'unsub'): string[] {
        const codes: string[] = [];

        const pricingColumns = this.getPricingColumnsAttributes();

        pricingColumns.forEach(attr => {
            const col: PricingGridStrategyColumn = this[attr];

            if (!col) {
                return;
            }

            const code = this.getColumnStrategyCodeForSubscription(col, attr, purpose);

            if (!isVoid(code)) {
                codes.push(code);
            }

        });

        return codes;
    }

    getItmStrategyCodes(): string[] {
        const codes: string[] = [];

        const pricingColumns = this.getPricingColumnsAttributes();

        pricingColumns.forEach(attr => {
            const col: PricingGridStrategyColumn = this[attr];

            if (!col) {
                return;
            }

            const code = this.getItmColumnStrategyCodeForSubscription(col, attr);

            if (!isVoid(code)) {
                codes.push(code);
            }
        });

        return codes;
    }

    private getItmColumnStrategyCodeForSubscription(col: PricingGridStrategyColumn, attr: string) {

        if (col.strategy.targetSide === 'calls') {
            if (attr.endsWith('-put')) {
                return;
            }
        }

        if (col.strategy.targetSide === 'puts') {
            if (attr.endsWith('-call')) {
                return;
            }
        }

        const strategyCode = col.strategyCode;


        const rowStrike = col.gridRow.rowStrike;

        const atmStrike = this.gridSettings.atmStrike;

        let isItm = false;

        if (col.optionType === OptionType.Put) {
            isItm = rowStrike < atmStrike;
        } else if (col.optionType === OptionType.Call) {
            isItm = rowStrike > atmStrike;
        }

        if (isItm) {
            return strategyCode;
        }
    }


    private getColumnStrategyCodeForSubscription(col: PricingGridStrategyColumn, attr: string, purpose: 'sub' | 'unsub') {

        if (col.strategy.targetSide === 'calls') {
            if (attr.endsWith('-put')) {
                return;
            }
        }

        if (col.strategy.targetSide === 'puts') {
            if (attr.endsWith('-call')) {
                return;
            }
        }

        if (purpose === 'unsub') {
            
        }

        const strategyCode = col.strategyCode;

        return strategyCode;
    }


    onExpirationChanged(): void {
        this.callPrice = this.putPrice = this.callDelta = this.putDelta = this.callIV = this.putIV = null;

        this._columnByStrategyCode = {};

        this.makeRowTickers();

        const pricingColumns = Object.getOwnPropertyNames(this).filter(x => x.startsWith(`pricing-`));

        pricingColumns.forEach(attr => {
            const col: PricingGridStrategyColumn = this[attr];

            if (!col) {
                return;
            }

            col.onExpirationChanged();

            let container = this._columnByStrategyCode[col.strategyCode];
            if (isVoid(container)) {
                container = [];
                this._columnByStrategyCode[col.strategyCode] = container;
            }
            container.push(col);
        });
    }


    updateStrategyColumns(str: PricingGridStrategy) {

        const pricingColumns = this.getPricingColumnsAttributes(str.strategyId);

        pricingColumns.forEach(attr => {
            const col: PricingGridStrategyColumn = this[attr];

            if (!col) {
                return;
            }

            if (col.setStrategy) {

                let container = this._columnByStrategyCode[col.strategyCode];
                if (isVoid(container)) {
                    container = [];
                    this._columnByStrategyCode[col.strategyCode] = container;
                }

                const ix = container.indexOf(col);

                if (ix >= 0) {
                    container.splice(ix, 1);
                }

                col.setStrategy(str);

                container = this._columnByStrategyCode[col.strategyCode];
                if (isVoid(container)) {
                    container = [];
                    this._columnByStrategyCode[col.strategyCode] = container;
                }
                container.push(col);
            }
        });
    }


    private updateRowPrices() {
        const quotes = [];
        const callQuote = this.gridSettings.lastQuoteCache.getLastQuote(this.callTicker);

        if (callQuote) {
            quotes.push(callQuote);
        }

        const putQuote = this.gridSettings.lastQuoteCache.getLastQuote(this.putTicker);
        if (putQuote) {
            quotes.push(putQuote);
        }

        this.onQuote(quotes);


        const callGreek = this.gridSettings.lastQuoteCache.getLastGreeks(this.callTicker);
        const putGreek = this.gridSettings.lastQuoteCache.getLastGreeks(this.putTicker);

        if (callGreek) {
            this.onGreeks(callGreek);
        }

        if (putGreek) {
            this.onGreeks(putGreek);
        }

        this.getPricingColumnsAttributes()
            .forEach(attr => {

                const pricingCol: PricingGridStrategyColumn = this[attr];

                if (isNullOrUndefined(pricingCol)) {
                    return;
                }

                const strategyPrice = this.gridSettings.lastQuoteCache.getLastStrategyPrice(pricingCol.strategyCode);

                if (isNullOrUndefined(strategyPrice)) {
                    return;
                }

                pricingCol.price = strategyPrice.price;
            });
    }


    private makeRowTickers() {
        this.callTicker = makeOptionTicker(this.gridSettings.expiration, OptionType.Call, this.rowStrike, 'American');
        this.putTicker = makeOptionTicker(this.gridSettings.expiration, OptionType.Put, this.rowStrike, 'American');
    }


    getPricingColumnsAttributes(strategyId?: string): string[] {
        if (!strategyId) {
            return Object.getOwnPropertyNames(this).filter(x => x.startsWith('pricing-'));
        }

        const pricingColumns = Object.getOwnPropertyNames(this).filter(x => x.startsWith(`pricing-${strategyId}`));
        return pricingColumns;
    }
}