import {
   GridOptions,
   CellClassParams,
   ValueGetterParams,
   ValueFormatterParams
} from 'ag-grid-community';
import {
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   centeredColumnDef,
   defaultPriceCellFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { AgGridColumn } from 'ag-grid-angular';
import { SyncOverrideDialogComponent } from './sync-override-dialog.component';
import { StrategyState } from '../strategies/strategy-state.enum';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';

export function getSyncOverrideGridModel(this: SyncOverrideDialogComponent, gridType: 'aTS' | 'sTS'): GridOptions {
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   const columns = setupColumns(this, isDashboard);

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      groupUseEntireRow: true,

      frameworkComponents: {
         simpleGroupRowRenderer: SimpleHeaderGroupRowInnerRenderer
      },

      groupRowRendererParams: {
         innerRenderer: 'simpleGroupRowRenderer',
         suppressCount: true
      },

      suppressCellSelection: true,

      onGridReady: args => this.onGridReady(args, gridType),

      getRowNodeId: (strat: StrategyModel) => {
         return strat.strategyId;
      },

      getContextMenuItems: () => {
         return ['autoSizeAll', 'copy', 'export'];
      },

      onFirstDataRendered: () => this.autoSizeColumns(gridType),

   } as GridOptions;
}

function setupColumns(panel: SyncOverrideDialogComponent, isDashboard: boolean): Partial<AgGridColumn>[] {

   const columns: Partial<AgGridColumn>[] = [];

   columns.push({
      headerName: '',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 80
   });

   if (isDashboard) {
      columns.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 0
      });

      columns.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 1
      });
   }

   columns.push({
      headerName: 'Mode',
      field: 'mode',
      enableRowGroup: true
   });

   columns.push({
      headerName: 'Name',
      field: 'displayName'
   });

   if (panel.securityContext.systemDetails) {
      columns.push({
         headerName: 'Description',
         field: 'description',
         headerComponentParams: {},
         cellStyle: (args) => {
            if (args.node.group) {
               return undefined;
            }

            const strategy: StrategyModel = args.data;

            if (strategy) {
               return {
                  'text-align': 'left',
                  'color': args.data.isAboutToExpire ? 'coral' : null
               };
            } else {
               return null;
            }

         }
      });
   }

   columns.push({
      headerName: 'Units',
      field: 'unitsAllocated'
   });

   columns.push({
      headerName: 'State',
      enableRowGroup: true,
      field: 'state',
      valueFormatter: (params: ValueFormatterParams) => {
         return StrategyState[params.value];
      }
   });

   columns.push({ headerName: 'Algo', field: 'algo', enableRowGroup: true });

   columns.push({ headerName: 'Status', field: 'status', enableRowGroup: true });

   columns.push({ headerName: 'Position', field: 'netPosition' });

   columns.push({
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueFormatter: defaultPriceCellFormatter
   });

   // columns.push(Object.assign({ headerName: 'Open P&L', field: 'sessionUnrealizedPnL' }, defaultMoneyCellDefinition));

   columns.push(Object.assign({ headerName: 'Session P&L', field: 'sessionTotalPnL' }, defaultMoneyCellDefinition));

   columns.push(Object.assign({ headerName: 'Acc. P&L', field: 'accumulatedTotalPnL' }, defaultMoneyCellDefinition));

   columns.push({ headerName: 'Account', field: 'brokerage', enableRowGroup: true });

   columns.push({ headerName: 'Terminal', field: 'terminalCode', enableRowGroup: true });

   columns.push({
      headerName: 'S/P',
      field: 'isSession',
      enableRowGroup: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return params.value ? 'S' : 'P';
      },
      cellStyle(params: CellClassParams) {
         return params.value ? { color: 'yellow', 'font-weight': 'bold' } : null;
      }
   });

   columns.push({
      headerName: 'Flags',
      field: 'flagsData',
   }
   );

   columns.push({
      headerName: 'Last Match Px',
      field: 'lastPx',
      hide: true,
      valueFormatter: defaultQuoteCellFormatter
   });

   columns.push(Object.assign({ headerName: 'Trade P&L', field: 'tradePnL' }, defaultMoneyCellDefinition));

   columns.push({
      headerName: 'Timezone',
      hide: true,
      valueGetter(args: ValueGetterParams) {
         const str: StrategyModel = args.data;
         if (!str) {
            return 'N/A';
         }
         const zone = str.parameters['timestampszone'];
         return zone || 'N/A';
      }
   });

   return columns;
}
