import { GetContextMenuItemsParams, GridOptions, ValueGetterParams, GridSizeChangedEvent, FirstDataRenderedEvent, ValueFormatterParams, ColDef } from 'ag-grid-community';
import {
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate, centeredColumnDef, getMasterSymbolColumn, defaultNumberCellFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { environment } from 'projects/shared-components/environments/environment';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { AgGridColumn } from 'ag-grid-angular';
import {
   SimpleHeaderGroupRowInnerRenderer
} from 'projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrdersAndTradesBaseComponent } from './orders-and-trades-base.component';


export function getTradesGridOptions(this: OrdersAndTradesBaseComponent): GridOptions {

   const gridColumns: Partial<AgGridColumn>[] = setupGridColumns(this);

   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: gridColumns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      tooltipShowDelay: 1000,

      frameworkComponents: {
         simpleHeaderRenderer: SimpleHeaderGroupRowInnerRenderer
      } as any,

      groupUseEntireRow: true,

      groupRowRendererParams: {
         innerRenderer: 'simpleHeaderRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: isDashboard ? 'always' : null,

      masterDetail: false,

      onGridReady: (args) => this.onTradesGridReady(args),

      onGridSizeChanged: (args: GridSizeChangedEvent) => {
         if (args.clientWidth === 0 || args.clientHeight === 0) {
            return;
         }

         this.onTradesGridSizeChanged(args);
      },

      getRowNodeId: (rowData: TradeDto) => rowData.tradeId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];

         menu.push(
            {
               name: 'Load More ...',
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadMoreTrades(50)
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreTrades(100)
                  },
                  {
                     name: '500',
                     action: () => this.loadMoreTrades(500)
                  },
                  {
                     name: '1000',
                     action: () => this.loadMoreTrades(1000)
                  },
                  'separator',
               ]
            },
         );


         if (this.linkedContext !== 'Portfolios') {
            menu.push(
               {
                  name: 'Is Linked',
                  action: () => this.changeIsLinked(),
                  checked: this.isLinked
               },
               'separator',
            );
         }

         menu.push(
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            'copy',
            'export'
         );

         if (this.linkedContext === 'Portfolios') {
            menu.push(
               'separator',
               {
                  name: 'Bucket Context',
                  icon: '<i class="fas fa-info-circle"></i>',
                  action: () => (this as any).showBucketContextHint()
               }
            );
         }

         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),

      getRowStyle: (args) => {
         const data: TradeDto = args.data;
         if (!data) {
            return null;
         }
         return data.isProxy ? { background: 'yellow', color: 'black' } : null;
      }
   } as GridOptions;
}

function setupGridColumns(comp: OrdersAndTradesBaseComponent): Partial<AgGridColumn>[] {

   const cols: Partial<AgGridColumn>[] = [];

   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   if (isDashboard) {
      cols.push(
         {
            headerName: 'Client',
            field: 'clientName',
            enableRowGroup: true,
            filter: true
         },
         {
            headerName: 'Shell',
            field: 'shellName',
            enableRowGroup: true,
            filter: true
         });
   }

   const expirationStyle: ColDef = {
      headerName: 'Exp. Style',
      field: 'sourceOrderExpirationStyle'
   };

   cols.push(

      getMasterSymbolColumn('tradingInstrumentCode', comp.messageBus, comp.timestampsService, comp.unsubscriber, () => comp.tradesGridApi),

      expirationStyle,

      {
         headerName: 'Qty',
         field: 'qty',
         valueGetter(params: ValueGetterParams) {
            const data: TradeDto = params.data;
            return data.qty * data.side;
         },
         valueFormatter: defaultNumberCellFormatter
      },

      {
         headerName: 'Price',
         field: 'execPrice',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: 'Buy/Sell',
         field: 'side',
         valueFormatter: (params: ValueFormatterParams) => {
            return MarketSide[params.value];
         },
         filter: true
      },

      {
         headerName: 'Order Type',
         field: 'sourceOrderType',
         valueFormatter: (params: ValueFormatterParams) => {
            return OrderType[params.value];
         },
         filter: true
      },

      Object.assign(
         { headerName: 'P&L', field: 'pnL' },
         defaultMoneyCellDefinition
      ),

      Object.assign(
         { headerName: 'Acc. P&L', field: 'accumulatedPnL' },
         defaultMoneyCellDefinition
      ),

      Object.assign(
         { headerName: 'Trade P&L', field: 'tradePnL', hide: true },
         defaultMoneyCellDefinition
      ),

      Object.assign(
         { headerName: 'Acc. Trade P&L', field: 'accumulatedTradePnL', hide: true },
         defaultMoneyCellDefinition
      ),

      Object.assign(
         { headerName: 'Perf. P&L', field: 'perfectPnL', hide: true },
         defaultMoneyCellDefinition
      ),

      Object.assign(
         { headerName: 'Acc. Perf. P&L', field: 'accumulatedPerfectPnL', hide: true },
         defaultMoneyCellDefinition
      ));

   if (comp.linkedContext === 'Portfolios') {
      cols.push(
         {
            headerName: 'Portfolio',
            field: 'portfolioName',
            tooltipField: 'portfolioName'
         },
         {
            headerName: 'Combo',
            field: 'comboName',
            tooltipField: 'comboName'
         },
         {
            headerName: 'ComboGroup',
            field: 'comboGroupName',
            tooltipField: 'comboGroupName',
         },
      );
   }

   cols.push(
      {
         headerName: 'Account',
         field: 'accountCode',
         tooltipField: 'accountCode',
         filter: true
      },
      {
         headerName: 'Terminal',
         field: 'terminalCode',
         tooltipField: 'terminalCode',
         filter: true
      },
      {
         headerName: 'Timestamp',
         field: 'timestamp',
         valueFormatter: (params: ValueFormatterParams) => {
            const trade: TradeDto = params.data;
            const frmtTime = comp.timestampsService.getFormattedDateTimeForStrategy(trade.strategyId, trade.timestamp);
            return trade.timestamp
               ? frmtTime
               : '';
         },
      },
      {
         headerName: 'SeqNum',
         field: 'seqNum',
         sort: 'asc',
         hide: true,
         valueFormatter: defaultNumberCellFormatter
      },
      {
         headerName: 'Trade Id',
         field: 'tradeId',
         filter: 'agTextColumnFilter',
         cellRenderer: 'agGroupCellRenderer'
      },

      {
         headerName: 'Order Id',
         field: 'orderId',
         filter: 'agTextColumnFilter'
      }
   );

   return cols;
}
