import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SweetSpotAdjustmentSettingsDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, isValidNumber, isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-sweetspot-adjustment',
   templateUrl: './sweetspot-adjustment.component.html',
   styleUrls: [
      './sweetspot-adjustment.component.scss',
      '../common-fieldset.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SweetspotAdjustmentComponent implements OnInit {

   constructor(
      private _changeDetector: ChangeDetectorRef,
   ) { }

   useSweetspotAdjustment = false;

   aboveBufferStart: number;
   
   aboveBufferEnd: number;

   belowBufferStart: number;
   
   belowBufferEnd: number;

   startTime: string;

   endTime: string;

   timezone: string;

   ngOnInit(): void { }

   @DetectMethodChanges()
   onChange() {
   }

   getParameters(): SweetSpotAdjustmentSettingsDto {

      if (!this.useSweetspotAdjustment) {
         return null;
      }

      const dto: SweetSpotAdjustmentSettingsDto = {
         aboveBufferStart: this.aboveBufferStart,
         aboveBufferEnd: this.aboveBufferEnd,
         belowBufferStart: this.belowBufferStart,
         belowBufferEnd: this.belowBufferEnd,
      };

      dto.startTime = this.startTime;
      dto.endTime = this.endTime;
      dto.timezone = this.timezone

      return dto;
   }

   @DetectMethodChanges()
   setData(dto: SweetSpotAdjustmentSettingsDto) {
      this.aboveBufferStart = dto.aboveBufferStart;
      this.aboveBufferEnd = dto.aboveBufferEnd;
      this.belowBufferStart = dto.belowBufferStart;
      this.belowBufferEnd = dto.belowBufferEnd;
      this.startTime = dto.startTime;
      this.endTime = dto.endTime;
      this.timezone = dto.timezone;

      if (isValidNumber(this.aboveBufferStart)) {
         this.useSweetspotAdjustment = true;
      }
   }

   @DetectMethodChanges()
   reset() {
      this.useSweetspotAdjustment = false;
      this.aboveBufferStart = undefined;
      this.aboveBufferEnd = undefined;
      this.belowBufferStart = undefined;
      this.belowBufferEnd = undefined;
      this.startTime = undefined;
      this.endTime = undefined;
      this.timezone = undefined;
   }

}
