<fieldset [ngClass]="{
                       'debit-trade': this.orderModel?.totalAsk < 0,
                       'credit-trade': this.orderModel?.totalAsk > 0,
                       'emulation': this.comp?.isEmulationMode
                     }" class="order-dialog">
  
  <legend *ngIf="!this.comp?.isEmulationMode" class="real-order-legend">{{ this.orderRole }}</legend>
  
  <legend *ngIf="this.comp?.isEmulationMode" class="emulator-order-legend">Emulator</legend>
  
  <div #order>
    <div class="top-area">
      
      <div class="state-controls">
        <div class="control symbol-picker">
          <ets-symbol-picker
              #symbolPicker (instrumentSelected)="onSymbolSelected($event)"
              [selectedInstrument]="this.orderModel?.tradingInstrument">
          </ets-symbol-picker>
        </div>
        
        <div class="toggle linked-toggle">
          <dx-check-box [(value)]="this.isLinkedMode" text="Linked"></dx-check-box>
        </div>
        
        <div class="toggle roll-toggle">
          <dx-check-box
            (valueChange)="this.onRollModeChange($event)"
            [disabled]="this.comp.isEmulationMode || !this.isLinkedMode || this.isMultiDestination"
            [value]="this.isRollMode" text="Roll">
          </dx-check-box>
        </div>
        
        <div class="toggle routed-toggle">
          <dx-check-box
            [(value)]="this.isMultiBucket"
            [disabled]="this.orderModel?.legs.length < 2 || this.isRollMode || this.isMultiDestination"
            text="Multi-Bucket"
          >
          </dx-check-box>
        </div>
        
        <div class="toggle cumulative-toggle">
          <dx-check-box [(value)]="this.isCumulative" text="Cumulative"></dx-check-box>
        </div>
        
        <div class="toggle show-row-gap">
          <dx-check-box
            (onValueChanged)="this.onChange()"
            [(value)]="this.showRowGap"
            text="Show Row Gap"
          >
          </dx-check-box>
        </div>
        
        <div class="toggle show-row-gap">
          <dx-check-box
            (onValueChanged)="this.onAutoSortChanged()"
            [(value)]="this.enableAutoSort"
            text="Auto-Sort"
          >
          </dx-check-box>
        </div>
      </div>
      
      <div class="leg-modifiers">
        
        <div class="modifier change-type">
          <dx-button
            (onClick)="this.orderModel.reverseLegsOptionType()"
            [disabled]="!this.orderModel?.tradingInstrument" text="Reverse Type"
            width="100%"
          >
          </dx-button>
        </div>
        
        <div class="modifier change-side">
          <dx-button (onClick)="this.orderModel.reverseLegsSide()" [disabled]="!this.orderModel?.tradingInstrument"
                     text="Reverse Side"
                     width="100%">
          </dx-button>
        </div>
        
        <div *ngIf="!this.comp?.isEmulationMode" class="modifier strategy-picker ets-dx-center">
          <dx-select-box
            (onValueChanged)="this.onStrategyChanged($event)"
            [(value)]="this.orderModel.selectedStrategy"
            [disabled]="!this.orderModel?.tradingInstrument"
            [items]="this.availableStrategies"
            [showClearButton]="true"
            placeholder="Strategy"
            width="100%"
          ></dx-select-box>
        </div>
        
        <div class="modifier option-type ets-dx-center">
          <p>Option Type</p>
          <dx-select-box
            [(value)]="this.optionType"
            [disabled]="!this.orderModel.selectedStrategy || !this.isOptionTypeAvailable"
            [items]="this.optionTypeList"
          >
          </dx-select-box>
        </div>
        
        <div class="modifier width ets-dx-center">
          <p>Strike Width</p>
          <dx-number-box
            [(value)]="this.strikeWidth"
            [disabled]="!this.isStrikeWidthAvailable"
          ></dx-number-box>
        </div>
        
        <div class="modifier expiration ets-dx-center">
          <div class="label">Expiration</div>
          <dx-select-box
            [(value)]="this.selectedExpiration"
            [disabled]="!this.orderModel?.tradingInstrument"
            [items]="this.orderModel?.expirationsList"
            [showClearButton]="true"
            displayExpr="dateWithDaysToExpiration"
          >
            <div *dxTemplate="let data of 'item'">
                <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                   {{ data?.dateWithDaysToExpiration }}
                </span>
            </div>
          </dx-select-box>
        </div>
        
        <div class="modifier offset">
          <div class="label">
            <div class="btn"
                 [class.hot]="this.strikeOffsetMode === 'Offset'"
                 [class.disabled]="!this.isOffsetAvailable"
                 (click)="this.changeOffsetMode('Offset')"
            >
              Offset
            </div>
            <div class="btn"
                 [class.hot]="this.strikeOffsetMode === 'Strike'"
                 [class.disabled]="!this.isOffsetAvailable"
                 (click)="this.changeOffsetMode('Strike')"
            >
              Strike
            </div>
          </div>
          <div class="ets-dx-center" [style.display]="this.strikeOffsetMode === 'Offset' ? 'block' : 'none' ">
            <dx-number-box
              [(value)]="this.strikeOffset"
              [disabled]="!this.isOffsetAvailable"
              [showClearButton]="true"
              [showSpinButtons]="true"
              [inputAttr]="{style: 'text-align: center'}"
            ></dx-number-box>
          </div>
          <div class="ets-dx-center" [style.display]="this.strikeOffsetMode === 'Strike' ? 'block' : 'none' ">
            <ets-strikes-dropdown
              [targetInstrument]="this.orderModel?.tradingInstrument"
              [expiration]="this.selectedExpiration"
              [(strike)]="this.baseStrike"
              [inputAttr]="{style: 'text-align: center'}"
            >
            </ets-strikes-dropdown>
          </div>
        </div>
        
        <div class="modifier apply">
          <dx-button
            (onClick)="this.onOptionStrategyApplied()"
            [disabled]="!this.canApplyStrategy()"
            text="Apply"
            type="default"
            width="100%"
          >
          </dx-button>
        </div>
      
      
      </div>
    
    
    </div>
    
    <div class="content-area">
      
      <table class="legs-list">
        
        <colgroup *ngIf="!this.comp?.isEmulationMode">
          <col name="dup" span="1" style="width: 1%;">
          <col name="leg" span="1" style="width: 4%;">
          <col name="action" span="1" style="width: 6%;">
          <col name="qty" span="1" style="width: 7%;">
          <col name="expiration" span="1" style="width: 12%;">
          <col name="strike" span="1" style="width: 7%;">
          <col name="cp" span="1" style="width: 5%;">
          <col name="x" span="1" style="width: 1%;">
          <col name="bid" span="1" style="width: 5%;">
          <col name="mid" span="1" style="width: 5%;">
          <col name="ask" span="1" style="width: 5%;">
          
          <col *ngIf="this.isMultiBucket" name="portfolio" span="1" style="width: 10%;">
          <col *ngIf="this.isMultiBucket" name="combo" span="1" style="width: 10%;">
          <col *ngIf="this.isMultiBucket" name="combogroup" span="1" style="width: 10%;">
        </colgroup>
        
        <!-- EMULATION mode extra columns -->
        <colgroup *ngIf="this.comp?.isEmulationMode">
          <col name="dup" span="1" style="width: 1%;">
          <col name="leg" span="1" style="width: 4%;">
          <col name="action" span="1" style="width: 4%;">
          <col name="qty" span="1" style="width: 6%;">
          <col name="expiration" span="1" style="width: 9%;">
          <col name="strike" span="1" style="width: 5%;">
          <col name="cp" span="1" style="width: 5%;">
          <col name="x" span="1" style="width: 1%;">
          <col name="exec-price" span="1" style="width: 7%;">
          <col name="exec-date" span="1" style="width: 8%;">
          <col name="exec-sec" span="1" style="width: 3%;">
          <col name="exec-ms" span="1" style="width: 2%;">
          
          
          <col *ngIf="this.isMultiBucket" name="terminal" span="1" style="width: 10%;">
          <col *ngIf="this.isMultiBucket" name="portfolio" span="1" style="width: 10%;">
          <col *ngIf="this.isMultiBucket" name="combo" span="1" style="width: 10%;">
          <col *ngIf="this.isMultiBucket" name="combogroup" span="1" style="width: 10%;">
          
          <col name="commission" span="1" style="width: 3%;">
        </colgroup>
        
        <thead class="headers">
        
        <tr>
          <td class="cell leg">&nbsp;</td>
          <td class="cell leg">Leg</td>
          <td class="cell input">Side</td>
          <td class="cell input">Qty</td>
          <td class="cell input">Expiration</td>
          <td class="cell input">Strike</td>
          <td class="cell input">C/P</td>
          <td class="cell remove">
            <i class="far fa-window-close"></i>
          </td> <!-- Remove button  -->
          <td *ngIf="!this.comp?.isEmulationMode" class="cell price">Bid</td>
          <td *ngIf="!this.comp?.isEmulationMode" class="cell price">Mid</td>
          <td *ngIf="!this.comp?.isEmulationMode" class="cell price">Ask</td>
          
          <!-- EMULATION Mode extra columns -->
          <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price">Price</td>
          <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-date">Date</td>
          <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-ms">Mls</td>
          
          <td *ngIf="this.isMultiBucket" class="cell terminal">Terminal</td>
          <td *ngIf="this.isMultiBucket" class="cell portfolio">Portfolio</td>
          <td *ngIf="this.isMultiBucket" class="cell combo">Combo</td>
          <td *ngIf="this.isMultiBucket" class="cell combogroup">ComboGroup</td>
          
          <td *ngIf="this.comp?.isEmulationMode" class="cell commission">Cmsn</td>
        
        </tr>
        </thead>
        
        <tbody class="legs">
        <!-- Main Legs Section : START -->
        <!-- Main Legs : START -->
        <ng-container *ngFor="let grp of this.orderModel?.getLegsByGroup(this.enableAutoSort); last as isLast">
          
          <tr *ngFor="let leg of grp.legs"
              [ngClass]="{
                            'underlying': leg.legType == 1,
                            'option': leg.legType == 2
                         }"
          >
            <ng-container *ngIf="leg.legType !== 3 && leg.legType !== 0">
              <td (click)="this.orderModel.duplicateLeg(leg)" class="cell duplicate">
                <i class="far fa-clone"></i>
              </td>
              
              <!-- Leg Type -->
              <td [ngStyle]="{'color':  leg.attachTo ? 'green' : 'unset' }" class="cell leg">
                {{ (leg.legType == 1 ? 'Asset' : 'Option') }} {{ (leg.attachTo ? '(AA)' : '') }}
              </td>
              
              <!-- Buy/Sell -->
              <td class="cell input market-side">
                
                <dx-select-box [(value)]="leg.action"
                               [disabled]="this.isRollMode"
                               [inputAttr]="{style: leg.getLegColor()}"
                               [items]="this.orderModel.actionTypeList"
                               displayExpr="text"
                               valueExpr="value">
                  <div *dxTemplate="let data of 'item'">
                               <span [ngStyle]="{'color': data && (data.value === 1) ? 'cornflowerblue' : 'red'}">
                                  {{ data && data.text }}
                               </span>
                  </div>
                </dx-select-box>
              
              </td>
              
              <!-- Qty -->
              <td class="cell input qty">
                
                <dx-number-box [(value)]="leg.qty"
                               [disabled]="this.isRollMode" [min]="1"
                               [showSpinButtons]="true"
                               [useLargeSpinButtons]="false"
                               format="#,##0">
                </dx-number-box>
              </td>
              
              <!-- Expiration -->
              <td class="cell input expiration">
                
                <div [ngSwitch]="leg.legType">
                  
                  <div *ngSwitchCase="1">&nbsp;</div>
                  
                  <div *ngSwitchCase="2">
                    
                    <div class="expiration-placeholder">
                      
                      <div [ngClass]="{'emulated': this.comp?.isEmulationMode}"
                           class="expiration-input-control">
                        
                        <div *ngIf="!leg.isCustomExpiration" class="control expirations-list">
                          
                          <dx-select-box
                            (onValueChanged)="this.onLegQuoteParameterChanged($event, 'expiration', leg, this.orderModel)"
                            [(value)]="leg.expiration"
                            [disabled]="this.isRollMode"
                            [inputAttr]="leg?.expiration?.expirationTicker === '@SPX' ? {'style': 'color: yellow'} : {}"
                            [items]="this.orderModel.expirationsList"
                            [showClearButton]="true"
                            displayExpr="dateWithDaysToExpiration">
                            <div *dxTemplate="let data of 'item'">
                                              <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                                                 {{ data?.dateWithDaysToExpiration }}
                                              </span>
                            </div>
                          </dx-select-box>
                        </div>
                        
                        <div *ngIf="leg.isCustomExpiration" class="control custom-expiration">
                          <ets-datetime-picker
                            (valueChanged)="this.onCustomExpirationDateSelected($event, this.orderModel, leg)"
                            [value]="leg.expiration?.optionExpirationDate"
                            mode="date">
                          </ets-datetime-picker>
                        </div>
                      </div>
                      
                      <div *ngIf="this.comp?.isEmulationMode"
                           class="custom-expiration-toggle">
                        
                        <dx-check-box (onValueChanged)="leg.expiration = null"
                                      [(value)]="leg.isCustomExpiration"
                                      tooltip="Custom Expiration">
                        </dx-check-box>
                      </div>
                    </div>
                  </div>
                  
                  <div *ngSwitchDefault>
                    
                    <span style="color: red; font-weight: bold">!ERR!</span>
                  </div>
                </div>
              </td>
              
              <!-- Strike -->
              <td class="cell input">
                
                <div [ngSwitch]="leg.legType">
                  
                  <div *ngSwitchCase="1">&nbsp;</div>
                  
                  <div *ngSwitchCase="2">
                    
                    <ets-strikes-dropdown
                      (atmStrikeChanged)="this.onLegQuoteParameterChanged($event, 'atmStrike', leg, this.orderModel)"
                      (strikeChanged)="this.onLegQuoteParameterChanged($event, 'strike', leg, this.orderModel)"
                      [(atmStrike)]="leg.strike"
                      [(strike)]="leg.strike"
                      [disable]="this.isRollMode"
                      [expiration]="leg.expiration"
                      [showAtm]="true"
                      [targetInstrument]="this.orderModel?.tradingInstrument">
                    </ets-strikes-dropdown>
                  </div>
                  
                  <div *ngSwitchDefault>
                               <span style="color: red; font-weight: bold">
                                  !ERR!
                               </span>
                  </div>
                </div>
              </td>
              
              <!-- Call/Put -->
              <td class="cell input">
                
                <div [ngSwitch]="leg.legType">
                  
                  <div *ngSwitchCase="1">&nbsp;</div>
                  
                  <div *ngSwitchCase="2">
                    <dx-select-box
                      (onValueChanged)="this.onLegQuoteParameterChanged($event, 'type', leg, this.orderModel)"
                      [(value)]="leg.optionType"
                      [disabled]="this.isRollMode"
                      [items]="this.orderModel.optionTypeList"
                      displayExpr="text"
                      valueExpr="value"
                    ></dx-select-box>
                  </div>
                  
                  <div *ngSwitchDefault>
                               <span style="color: red; font-weight: bold">
                                  !ERR!
                               </span>
                  </div>
                </div>
              </td>
              
              <td class="cell remove">
                <i (click)="this.removeLeg(this.orderModel, leg)" class="far fa-window-close"></i>
              </td>
              
              <!-- Bid -->
              <td *ngIf="!this.comp?.isEmulationMode" [ngClass]="{'sell': leg.action === -1}"
                  class="cell price">
                {{ (leg.bid === -1) ? '?' : (leg.bid | currency:'USD':'symbol':'1.2-2') }}
              </td>
              
              <!-- Mid -->
              <td *ngIf="!this.comp?.isEmulationMode" class="cell price">
                {{
                      (leg.bid === -1 || leg.ask === -1) ? '?' : (((leg.bid + leg.ask) / 2) |
                          currency:'USD':'symbol':'1.2-2')
                }}
              </td>
              
              <!-- Ask -->
              <td *ngIf="!this.comp?.isEmulationMode" [ngClass]="{'buy': leg.action === 1}" class="cell price">
                {{ (leg.ask === -1) ? '?' : (leg.ask | currency:'USD':'symbol':'1.2-2') }}
              </td>
              
              <!-- EMULATOR: Exec. Price -->
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price">
                <dx-number-box [(value)]="leg.execPrice" [showClearButton]="true" [showSpinButtons]="true"
                               [step]="this.orderModel?.tradingInstrument ? this.orderModel?.tradingInstrument.tickSize : 0.01"
                               [useLargeSpinButtons]="false">
                </dx-number-box>
              </td>
              
              <!-- EMULATOR: Exec. Date -->
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-date">
                <ets-datetime-picker
                  (valueChanged)="this.orderModel.onExecDateChanged($event)"
                  [(timezone)]="leg.timezone"
                  [(value)]="leg.execDate"
                  [showTimezone]="true"
                  mode="datetime">
                </ets-datetime-picker>
              </td>
              
              <!-- EMULATOR: Exec. Millis -->
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-ms">
                <dx-number-box (onValueChanged)="this.orderModel.onExecMillisecondsChanged($event)"
                               [(value)]="leg.execMilliseconds" [max]="999"
                               [min]="0"></dx-number-box>
              </td>
              
              <!-- EMULATOR: Comiss -->
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price">
                <dx-number-box [(value)]="leg.commission" [min]="0"
                               [showClearButton]="true" [useLargeSpinButtons]="false">
                </dx-number-box>
              </td>
              
              <!-- MULTI-BUCKET: Terminal -->
              <td *ngIf="this.isMultiBucket" class="cell terminal">
                <dx-select-box (onValueChanged)="leg.onTerminalChanged($event)"
                               [(value)]="leg.terminal"
                               [disabled]="!this.orderModel?.tradingInstrument || this.isMultiBucket"
                               [items]="leg.terminalList"
                               [showClearButton]="true"
                               displayExpr="displayName"
                               placeholder="Terminal"
                               width="100%"></dx-select-box>
              </td>
              
              <!-- MULTI-BUCKET: Portfolio -->
              <td *ngIf="this.isMultiBucket" class="cell portfolio">
                <dx-select-box (onValueChanged)="leg.onPortfolioChanged($event)"
                               [(value)]="leg.portfolio"
                               [disabled]="!this.orderModel?.tradingInstrument"
                               [items]="leg.portfoliosList"
                               [showClearButton]="true"
                               displayExpr="portfolioName"
                               placeholder="Portfolio"
                               width="100%">
                </dx-select-box>
              </td>
              
              <!-- MULTI-BUCKET: Combo -->
              <td *ngIf="this.isMultiBucket" class="cell combo">
                <dx-select-box (onValueChanged)="leg.onComboChanged($event)"
                               [(value)]="leg.combo"
                               [disabled]="!this.orderModel?.tradingInstrument"
                               [items]="leg.comboList"
                               [showClearButton]="true"
                               displayExpr="comboName"
                               placeholder="Combo"
                               width="100%">
                </dx-select-box>
              </td>
              
              <!-- MULTI-BUCKET: ComboGroup -->
              <td *ngIf="this.isMultiBucket" class="cell combogroup">
                <dx-select-box [(value)]="leg.comboGroup"
                               [disabled]="!this.orderModel?.tradingInstrument"
                               [items]="leg.comboGroupList"
                               [showClearButton]="true"
                               displayExpr="comboGroupName"
                               placeholder="Group"
                               width="100%">
                </dx-select-box>
              </td>
            </ng-container>
          
          
          </tr>
          
          <!-- Sub-Total Row -->
          <ng-container *ngIf="this.showRowGap">
            
            <ng-container *ngIf="this.getSeparatorType() === 'Row'">
              <tr class="legs-total sub-total">
                <td class="cell duplicate">&nbsp;</td>
                <td class="cell leg">&nbsp;</td>
                <td class="cell input">&nbsp;</td>
                <td class="cell input">&nbsp;</td>
                <td class="cell input">&nbsp;</td>
                <td class="cell input">&nbsp;</td>
                <td class="cell input">&nbsp;</td>
                <td class="cell remove">&nbsp;</td>
                
                <td *ngIf="!this.comp?.isEmulationMode"
                    [class.credit-trade]="this.getGroupTotals(grp.groupLabel).bidTotal > 0"
                    [class.debit-trade]="this.getGroupTotals(grp.groupLabel).bidTotal < 0"
                    class="cell price total bid"
                >
                   <span>
                      {{ (this.getGroupTotals(grp.groupLabel).bidTotal | currency:'USD':'symbol':'1.2-2') || '-' }}
                   </span>
                </td>
                
                <td *ngIf="!this.comp?.isEmulationMode"
                    [class.credit-trade]="this.getGroupTotals(grp.groupLabel).midTotal > 0"
                    [class.debit-trade]="this.getGroupTotals(grp.groupLabel).midTotal < 0"
                    class="cell price total mid"
                >
                   <span>
                      {{ (this.getGroupTotals(grp.groupLabel).midTotal | currency:'USD':'symbol':'1.2-2') || '-' }}
                   </span>
                </td>
                
                <td *ngIf="!this.comp?.isEmulationMode"
                    [class.credit-trade]="this.getGroupTotals(grp.groupLabel).askTotal > 0"
                    [class.debit-trade]="this.getGroupTotals(grp.groupLabel).askTotal < 0"
                    class="cell price total ask"
                >
                       <span>
                          {{ (this.getGroupTotals(grp.groupLabel).askTotal | currency:'USD':'symbol':'1.2-2') || '-' }}
                       </span>
                </td>
                
                <!-- EMULATION Mode extra columns -->
                <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price">&nbsp;</td>
                <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-date">&nbsp;</td>
                <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-ms">&nbsp;</td>
                
                <td *ngIf="this.isMultiBucket" class="cell terminal">&nbsp;</td>
                <td *ngIf="this.isMultiBucket" class="cell portfolio">&nbsp;</td>
                <td *ngIf="this.isMultiBucket" class="cell combo">&nbsp;</td>
                <td *ngIf="this.isMultiBucket" class="cell combogroup">&nbsp;</td>
                
                <td *ngIf="this.comp?.isEmulationMode" class="cell commission">&nbsp;</td>
              </tr>
            </ng-container>
            
            <ng-container *ngIf="this.getSeparatorType() === 'Line'">
              <tr class="legs-total">
                
                <td class="cell" colspan="8" style="padding: 10px 0">
                  <hr style="width: 85%; border-color: gray; margin-bottom: 15px; margin-top:15px;">
                </td>
                
                <td *ngIf="!this.comp?.isEmulationMode"
                    [class.credit-trade]="this.getGroupTotals(grp.groupLabel).bidTotal > 0"
                    [class.debit-trade]="this.getGroupTotals(grp.groupLabel).bidTotal < 0"
                    class="cell price total bid"
                >
                   <span>
                      {{ (this.getGroupTotals(grp.groupLabel).bidTotal | currency:'USD':'symbol':'1.2-2') || '-' }}
                   </span>
                </td>
                
                <td *ngIf="!this.comp?.isEmulationMode"
                    [class.credit-trade]="this.getGroupTotals(grp.groupLabel).midTotal > 0"
                    [class.debit-trade]="this.getGroupTotals(grp.groupLabel).midTotal < 0"
                    class="cell price total mid"
                >
                   <span>
                      {{ (this.getGroupTotals(grp.groupLabel).midTotal | currency:'USD':'symbol':'1.2-2') || '-' }}
                   </span>
                </td>
                
                <td *ngIf="!this.comp?.isEmulationMode"
                    [class.credit-trade]="this.getGroupTotals(grp.groupLabel).askTotal > 0"
                    [class.debit-trade]="this.getGroupTotals(grp.groupLabel).askTotal < 0"
                    class="cell price total ask"
                >
                       <span>
                          {{ (this.getGroupTotals(grp.groupLabel).askTotal | currency:'USD':'symbol':'1.2-2') || '-' }}
                       </span>
                </td>
              
              </tr>
            </ng-container>
            
          </ng-container>
          
        </ng-container>
        <!-- Main Legs : END -->
        
        <!-- Main Legs Total Row : START -->
        <tr class="legs-total">
          
          <td class="cell duplicate">&nbsp;</td>
          
          <td class="cell leg"></td>
          
          <td class="cell input side"></td>
          
          <td class="cell input qty"></td>
          
          <td class="cell input expiration">
            <dx-check-box [(value)]="this.orderModel.linkExpirations"
                          [disabled]="this.isRollMode">
            </dx-check-box>
          </td>
          
          <td class="cell input"></td>
          
          <td class="cell input"></td>
          
          <td class="cell remove">
            <i (click)="this.clearLegs('main')" class="far fa-window-close" style="color: red"></i>
          </td>
          
          <!-- Non Emulator Price Columns : START -->
          <td *ngIf="!this.comp?.isEmulationMode"
              [class.credit-trade]="this.orderModel.totalMid > 0"
              [class.debit-trade]="this.orderModel.totalMid < 0"
              class="cell price total bid"
          >
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.totalBid)">
                        {{ (this.orderModel.totalBid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td *ngIf="!this.comp?.isEmulationMode"
              [class.credit-trade]="this.orderModel.totalMid > 0"
              [class.debit-trade]="this.orderModel.totalMid < 0"
              class="cell price total mid"
          >
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.totalMid)">
                        {{ (this.orderModel.totalMid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td *ngIf="!this.comp?.isEmulationMode"
              [class.credit-trade]="this.orderModel.totalMid > 0"
              [class.debit-trade]="this.orderModel.totalMid < 0"
              class="cell price total ask"
          >
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.totalAsk)">
                        {{ (this.orderModel.totalAsk | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          <!-- Non Emulator Price Columns : END -->
          
          <!-- Emulator Price Columns : START -->
          <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price total mid">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.totalMid)">
                        {{ (this.orderModel.totalMid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-date">
            <dx-check-box [(value)]="this.orderModel.linkExecDates"></dx-check-box>
          </td>
          
          <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-ms"></td>
          <!-- Emulator Price Columns : END -->
        </tr>
        <!-- Main Legs Total Row : END -->
        <!-- Main Legs Section : END -->
        
        
        <!-- Rolling Legs Section : START -->
        <!-- Rolling Legs Separator : START -->
        <tr *ngIf="this.orderModel.isRolling">
          <td colspan="10">
            <hr class="separator">
          </td>
        </tr>
        <!-- Rolling Legs Separator : END -->
        
        <!-- Rolling Legs : START -->
        <tr *ngFor="let leg of this.orderModel.rollingLegs" [ngClass]="{
                                 'underlying': leg.legType == 1,
                                 'option': leg.legType == 2
                             }">
          
          
          <ng-container *ngIf="leg.legType ===  3">
            <ng-container *ngIf="this.getSeparatorType() === 'Row'">
              <td class="cell leg">&nbsp;</td>
              <td class="cell leg">&nbsp;</td>
              <td class="cell input">&nbsp;</td>
              <td class="cell input">&nbsp;</td>
              <td class="cell input">&nbsp;</td>
              <td class="cell input">&nbsp;</td>
              <td class="cell input">&nbsp;</td>
              <td class="cell remove">&nbsp;</td>
              <td *ngIf="!this.comp?.isEmulationMode" class="cell price">&nbsp;</td>
              <td *ngIf="!this.comp?.isEmulationMode" class="cell price">&nbsp;</td>
              <td *ngIf="!this.comp?.isEmulationMode" class="cell price">&nbsp;</td>
              
              <!-- EMULATION Mode extra columns -->
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price">&nbsp;</td>
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-date">&nbsp;</td>
              <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-ms">&nbsp;</td>
              
              <td *ngIf="this.isMultiBucket" class="cell terminal">&nbsp;</td>
              <td *ngIf="this.isMultiBucket" class="cell portfolio">&nbsp;</td>
              <td *ngIf="this.isMultiBucket" class="cell combo">&nbsp;</td>
              <td *ngIf="this.isMultiBucket" class="cell combogroup">&nbsp;</td>
              
              <td *ngIf="this.comp?.isEmulationMode" class="cell commission">&nbsp;</td>
            </ng-container>
            <ng-container *ngIf="this.getSeparatorType() === 'Line'">
              <td class="cell" colspan="11" style="padding: 10px 0">
                <hr style="width: 85%; border-color: gray; margin-bottom: 15px; margin-top:15px;">
              </td>
            </ng-container>
          </ng-container>
          
          <ng-container *ngIf="leg.legType !== 0">
            
            <td class="cell duplicate">&nbsp;</td>
            
            <!-- Leg Type -->
            <td class="cell leg">{{ (leg.legType == 1 ? 'Asset' : 'Option') }}</td>
            
            <!-- Buy/Sell -->
            <td class="cell input market-side">
              <dx-select-box [(value)]="leg.action"
                             [items]="this.orderModel.actionTypeList"
                             displayExpr="text"
                             fieldTemplate="field" valueExpr="value">
                <div *dxTemplate="let data of 'field'">
                  <div [ngClass]="{'buy': data && (data.value === 1), 'sell': data && (data.value === -1)}">
                    <dx-text-box [readOnly]="true" [value]="data && data.text"></dx-text-box>
                  </div>
                </div>
                
                <div *dxTemplate="let data of 'item'">
                             <span [ngStyle]="{'color': data && (data.value === 1) ? 'cornflowerblue' : 'red'}">{{
                                     data &&
                                     data.text
                               }}</span>
                </div>
              </dx-select-box>
            
            </td>
            
            <!-- Qty -->
            <td class="cell input qty">
              
              <dx-number-box [(value)]="leg.qty"
                             [min]="1"
                             [showSpinButtons]="true"
                             [showSpinButtons]="false"
                             [step]="1"
                             [useLargeSpinButtons]="false">
              </dx-number-box>
            
            </td>
            
            <!-- Expiration -->
            <td class="cell input">
              <div [ngSwitch]="leg.legType">
                
                <div *ngSwitchCase="1">&nbsp;</div>
                
                <div *ngSwitchCase="2">
                  <dx-select-box
                    (onValueChanged)="this.onLegQuoteParameterChanged($event, 'expiration', leg, this.orderModel)"
                    [(value)]="leg.expiration"
                    [items]="this.orderModel.expirationsList"
                    [selectedItem]="leg.expiration" displayExpr="dateWithDaysToExpiration">
                  </dx-select-box>
                </div>
                <div *ngSwitchDefault>
                             <span style="color: red; font-weight: bold">
                                !ERR!
                             </span>
                </div>
              </div>
            </td>
            
            <!-- Strike -->
            <td class="cell input">
              <div [ngSwitch]="leg.legType">
                
                <div *ngSwitchCase="1">&nbsp;</div>
                
                <div *ngSwitchCase="2">
                  
                  <ets-strikes-dropdown
                    (atmStrikeChanged)="this.onLegQuoteParameterChanged($event, 'atmStrike', leg, this.orderModel)"
                    (strikeChanged)="this.onLegQuoteParameterChanged($event, 'strike', leg, this.orderModel)"
                    [(atmStrike)]="leg.strike"
                    [(strike)]="leg.strike"
                    [expiration]="leg.expiration"
                    [showAtm]="true"
                    [targetInstrument]="this.orderModel?.tradingInstrument">
                  </ets-strikes-dropdown>
                </div>
                
                <div *ngSwitchDefault>
                             <span style="color: red; font-weight: bold">
                                !ERR!
                             </span>
                </div>
              </div>
            </td>
            
            <!-- Call/Put -->
            <td class="cell input">
              
              <div [ngSwitch]="leg.legType">
                
                <div *ngSwitchCase="1">&nbsp;</div>
                
                <div *ngSwitchCase="2">
                  <dx-select-box
                    (onValueChanged)="this.onLegQuoteParameterChanged($event, 'type', leg, this.orderModel)"
                    [(value)]="leg.optionType"
                    [items]="this.orderModel.optionTypeList"
                    displayExpr="text"
                    valueExpr="value">
                  </dx-select-box>
                </div>
                <div *ngSwitchDefault>
                             <span style="color: red; font-weight: bold">
                                !ERR!
                             </span>
                </div>
              </div>
            </td>
            
            <td class="cell remove">
              <i (click)="this.removeLeg(this.orderModel, leg)" class="far fa-window-close"></i>
            </td>
            
            <td *ngIf="!this.comp?.isEmulationMode" [ngClass]="{'sell': leg.action === -1}"
                class="cell price">{{ leg.bid | currency:'USD':'symbol':'1.2-2' }}
            </td>
            
            <td *ngIf="!this.comp?.isEmulationMode" class="cell price">
              {{ ((leg.bid + leg.ask) / 2) | currency:'USD':'symbol':'1.2-2' }}
            </td>
            
            <td *ngIf="!this.comp?.isEmulationMode" [ngClass]="{'buy': leg.action === 1}" class="cell price">
              {{ leg.ask | currency:'USD':'symbol':'1.2-2' }}
            </td>
            
            <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-price">
              <dx-number-box></dx-number-box>
            </td>
            
            <td *ngIf="this.comp?.isEmulationMode" class="cell emulator-exec-date">
              <dx-text-box></dx-text-box>
            </td>
          
          </ng-container>
        
        
        </tr>
        <!-- Rolling Legs : END -->
        <!-- Rolling Legs Total Row : START -->
        <tr *ngIf="this.orderModel.isRolling" class="legs-total">
          
          <td class="cell duplicate"></td>
          
          <td class="cell leg"></td>
          
          <td class="cell input side"></td>
          
          <td class="cell input qty"></td>
          
          <td class="cell input expiration">
            <dx-check-box [(value)]="this.orderModel.linkRollingExpirations"></dx-check-box>
          </td>
          
          <td class="cell input"></td>
          
          <td class="cell input"></td>
          
          <td class="cell remove">
            <i (click)="this.clearLegs('rolling')" class="far fa-window-close" style="color: red"></i>
          </td>
          
          <td class="cell price total bid">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.rollingTotalBid)">
                        {{ (this.orderModel.rollingTotalBid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td class="cell price total mid">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.rollingTotalMid)">
                        {{ (this.orderModel.rollingTotalMid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td class="cell price total ask">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.rollingTotalAsk)">
                        {{ (this.orderModel.rollingTotalAsk | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
        </tr>
        <!-- Rolling Legs Total Row : END -->
        
        <!-- Rolling Legs Grant Total Row Separator : START -->
        <tr *ngIf="this.orderModel.isRolling">
          <td colspan="10">
            <hr class="separator">
          </td>
        </tr>
        <!-- Rolling Legs Grant Total Row Separator : END -->
        <!-- Rolling Legs : END -->
        
        <!-- Rolling Legs Grand Total Row : START -->
        <tr *ngIf="this.orderModel.isRolling" class="legs-grand-total">
          
          <td class="cell duplicate"></td>
          
          <td class="cell leg"></td>
          
          <td class="cell input"></td>
          
          <td class="cell input"></td>
          
          <td class="cell input expiration"></td>
          
          <td class="cell input"></td>
          
          <td class="cell input"></td>
          
          <td class="cell remove"></td>
          
          <td class="cell price total bid">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.grandTotalBid)">
                        {{ (this.orderModel.grandTotalBid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td class="cell price total mid">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.grandTotalMid)">
                        {{ (this.orderModel.grandTotalMid | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
          
          <td class="cell price total ask">
                     <span (click)="this.orderModel.setLimitPrice(this.orderModel.grandTotalAsk)">
                        {{ (this.orderModel.grandTotalAsk | currency:'USD':'symbol':'1.2-2') || '-' }}
                     </span>
          </td>
        </tr>
        <!-- Rolling Legs Grand Total Row : END -->
        
        </tbody>
      </table>
      
      <hr class="separator">
      
      <div class="controls-section">
        
        <div class="input add-underlying">
          <dx-button
            (onClick)="this.addEmptyLegToOrder(1)" [disabled]="!this.orderModel.canAddAsset"
            text="Add Asset"
            width="100%"
          >
          </dx-button>
        </div>
        
        <div class="input add-option">
          <dx-button (onClick)="this.addEmptyLegToOrder(2)" [disabled]="!this.orderModel?.tradingInstrument"
                     text="Add Option"
                     width="100%"></dx-button>
        </div>
        
        <div class="input add-destination">
          <dx-button
            (onClick)="this.orderModel.addDestination()"
            [disabled]="!this.orderModel?.tradingInstrument || !this.canAddDestination"
            text="Add Destination"
            width="100%"
          ></dx-button>
        </div>
        
        <div class="input paste">
          <div class="button">
            <dx-button (onClick)="this.copyLegsToClipboard()" [disabled]="!this.orderHasLegs" hint="Copy Legs Clipboard"
                       icon="copy"></dx-button>
          </div>
          <div class="button">
            <dx-button (onClick)="this.pasteComboFromClipboard()" [disabled]="!this.hasSomethingInClipboard"
                       hint="Paste Legs From Clipboard"
                       icon="paste"></dx-button>
          </div>
          <div *ngIf="!this.comp?.isEmulationMode" class="button">
            <dx-button (onClick)="this.showAttachStrategyDialog()" [disabled]="!this.orderHasLegs" height="26px"
                       hint="Attach AA strategy">
              <div *dxTemplate="let data of 'content'">
                        <span [style.color]="this.attachment ? 'green' : null "
                              [style.vertical-align]="'middle'">AA</span>
                <!-- <i class="dx-icon dx-icon-link" [style.color]="this.attachment ? 'green' : null "></i> -->
              </div>
            </dx-button>
          </div>
          <div *ngIf="!this.comp?.isEmulationMode" class="button">
            <dx-button (onClick)="this.showFutureTimeSettingsDialog()" [disabled]="!this.orderHasLegs" height="26px"
                       hint="Place in Future">
              <div *dxTemplate="let data of 'content'">
                        <span
                          [style.color]="(this.futureOrderSettings || this.convertToMarketSettings) ? 'green' : null "
                          [style.vertical-align]="'middle'">FO/CTM</span>
              </div>
            </dx-button>
          </div>
        </div>
        
        <div *ngIf="!this.comp?.isEmulationMode && this.orderModel.orderType === 1" class="input order price">
          <dx-number-box
            (onValueChanged)="this.orderModel.onLimitPriceChanged($event)"
            [disabled]="!this.orderModel?.tradingInstrument || this.orderModel.orderType !== 1"
            [format]="{ style: 'currency', currency: 'USD' }"
            [inputAttr]="this.orderModel.limitPriceColor"
            [placeholder]="'Limit Price'"
            [showClearButton]="true"
            [showSpinButtons]="true"
            [step]="this.orderModel?.tradingInstrument ? this.orderModel?.tradingInstrument.tickSize : 0"
            [useLargeSpinButtons]="false"
            [value]="this.orderModel.limitPrice">
          </dx-number-box>
        </div>
        
        <div *ngIf="!this.comp?.isEmulationMode && this.orderModel.orderType === 17" class="input order autolimit">
          <dx-select-box [(value)]="this.orderModel.autoLimitPrice"
                         [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="['Bid', 'Ask', 'Mid']" [placeholder]="'Auto Price'"
                         hint="Auto Price"
                         width="100%">
          </dx-select-box>
        </div>
        
        <div *ngIf="!this.comp?.isEmulationMode" class="input order type">
          <dx-select-box (onValueChanged)="this.onOrderTypeChanged()" [(value)]="this.orderModel.orderType"
                         [disabled]="!this.orderModel?.tradingInstrument" [items]="this.orderModel.orderTypeList"
                         [placeholder]="'Order Type'" displayExpr="text"
                         hint="Order Type"
                         valueExpr="value" width="100%">
          </dx-select-box>
        </div>
        
        <div *ngIf="!this.comp?.isEmulationMode" class="input order duration">
          <dx-select-box [(value)]="this.orderModel.orderDuration" [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="this.orderModel.orderDurationList" [placeholder]="'Duration'" displayExpr="text"
                         hint="Duration"
                         valueExpr="value" width="100%">
          </dx-select-box>
        </div>
        
        <div class="input order expiration-style">
          <dx-select-box [(value)]="this.orderModel.expirationStyle"
                         [disabled]="!this.orderModel?.canChangeExpirationStyle || this.isLiveAccountSelected"
                         [items]="this.orderModel.expirationStyleList" [placeholder]="'Exp. style'"
                         hint="Expiration Style"
                         width="100%">
          </dx-select-box>
        </div>
      
      </div>
    </div>
    
    <div class="footer">
      
      <div *ngFor="let dest of this.orderModel.destinations; index as ix"
           [style.display]="dest.hidden ? 'none' : 'flex'"
           class="dest">
        
        <div class="section qty">
          <div class="blocks">
            <div (click)="this.orderModel.pressDestination(ix, !dest.pressed, false)" [class.pressed]="dest.pressed"
                 class="block">
              ({{ dest.letter }})
            </div>
            <div class="block">
              <dx-number-box
                [(value)]="dest.qty"
                [disabled]="this.isRollMode || this.orderModel?.legs?.length === 0"
                [inputAttr]="{ style: 'color: yellow; font-weight: bold; font-family: cursive' }"
                [min]="1" [showSpinButtons]="true"
                [step]="1"
                [useLargeSpinButtons]="false"
                format="#,##0"
                hint="Leg Qty Multiplier"
                outerText="Qty">
              </dx-number-box>
            </div>
          </div>
        </div>
        
        <div class="section reference">
          <dx-drop-down-button
            (onOptionChanged)="dest.bucketReference_onOptionChanged($event, dest)"
            [disabled]="dest.isEmpty || !this.orderModel?.tradingInstrument"
            [dropDownOptions]="{minWidth: 650, minHeight: 280}"
            text="R">
            <div *dxTemplate="let data of 'content'" style="padding: 10px">
              <ag-grid-angular [gridOptions]="this.getBucketReferenceGridOptions(dest)"
                               class="ag-theme-balham-dark"
                               style="width: 100%; height: 100%">
              </ag-grid-angular>
            </div>
          </dx-drop-down-button>
        </div>
        
        <div class="section account">
          <dx-select-box (onValueChanged)="this.onDestinationAccountChanged()"
                         [(value)]="dest.account"
                         [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="this.comp.availableAccounts"
                         [showClearButton]="true"
                         displayExpr="description"
                         placeholder="Account"
                         width="100%">
          </dx-select-box>
        </div>
        
        <div class="section terminal">
          
          <dx-select-box (onValueChanged)="dest.onTerminalChanged($event)"
                         [(value)]="dest.terminal"
                         [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="this.comp.availableTerminals"
                         [showClearButton]="true"
                         displayExpr="displayName"
                         placeholder="Terminal" width="100%">
          </dx-select-box>
        </div>
        
        <div class="section portfolio">
          
          <dx-select-box (onValueChanged)="dest.onPortfolioChanged($event)"
                         [(value)]="dest.portfolio"
                         [acceptCustomValue]="false"
                         [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="dest.portfoliosList"
                         [showClearButton]="true"
                         displayExpr="portfolioName"
                         placeholder="Portfolio" width="100%">
          </dx-select-box>
        </div>
        
        <div class="section combo">
          
          <dx-select-box (onValueChanged)="dest.onComboChanged($event)"
                         [(value)]="dest.combo"
                         [acceptCustomValue]="false"
                         [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="dest.comboList"
                         [showClearButton]="true"
                         displayExpr="comboName"
                         placeholder="Combo"
                         width="100%">
          </dx-select-box>
        </div>
        
        <div class="section group">
          
          <dx-select-box [(value)]="dest.comboGroup"
                         [acceptCustomValue]="false"
                         [disabled]="!this.orderModel?.tradingInstrument"
                         [items]="dest.comboGroupList"
                         [showClearButton]="true"
                         displayExpr="comboGroupName"
                         placeholder="Group"
                         width="100%">
          </dx-select-box>
        </div>
        
        <div class="section remove">
          <dx-button (onClick)="this.orderModel.removeDestination(ix, false)"
                     [disabled]="this.orderModel?.destinations.length === 1" hint="Remove Destination"
                     icon="remove"></dx-button>
        </div>
        
        <div class="section duplicate">
          <dx-button (onClick)="this.orderModel.duplicateDestination(ix)"
                     [disabled]="!this.orderModel?.tradingInstrument" hint="Duplicate"
                     text="D"></dx-button>
        </div>
      
      
      </div>
    
    </div>
  </div>
  
  <dx-load-panel [visible]="this.orderModel.isLoadingData">
    <dxo-position [of]="order" at="center" my="center">
    </dxo-position>
  </dx-load-panel>

</fieldset>

<ets-adjustment-strategy-dialog (attchmentCreated)="this.onAttachmentCreated($event)"
                                [legs]="this.orderModel?.legs"></ets-adjustment-strategy-dialog>

<dx-popup (onHidden)="this.futureOrderDialog.onHidden()" [(visible)]="this.futureOrderDialog.isVisible"
          [closeOnOutsideClick]="false" [dragEnabled]="true" [height]="this.futureOrderDialog.getDialogHeight()"
          [resizeEnabled]="true" [showTitle]="true" title="Place Order In Future"
          width="435">
  <div *dxTemplate="let data of 'content'" class="future-order">
    
    <dx-box [align]="'end'" direction="col" height="100%">
      <dxi-item [ratio]="1">
        
        <fieldset>
          <legend>
            <dx-check-box #fo [(value)]="this.futureOrderDialog.futureOrder" text="Future Time Settings"></dx-check-box>
          </legend>
          
          <div class="input row-2" style="margin-top: 5px;">
            
            <div class="cell">
              <dx-select-box
                  #place
                  (onValueChanged)="this.futureOrderDialog.futureTimeSettings.onChange($event)"
                  [(value)]="this.futureOrderDialog.futureTimeSettings.actionTimeMode"
                  [disabled]="!fo.value"
                  [items]="['Place At', 'Place After']" [showClearButton]="true"
                  placholder="Place Mode"></dx-select-box>
            </div>
            
            <div class="cell">
              <ets-datetime-picker
                (onValueChanged)="this.futureOrderDialog.futureTimeSettings.onChange($event)"
                [(value)]="this.futureOrderDialog.futureTimeSettings.actionTime"
                [contextWord]="this.futureOrderDialog.futureTimeSettings.actionTimeMode"
                [disabled]="!place.value"
                [mode]="this.futureOrderDialog.futureTimeSettings.getDatetimePickerMode()"
              ></ets-datetime-picker>
            </div>
          
          </div>
          
          <div *ngIf="this.futureOrderDialog.futureTimeSettings.actionTimeMode === 'Place At'" class="input row"
               style="margin-top: 5px;">
            <ets-timezone-picker
              [(timezone)]="this.futureOrderDialog.futureTimeSettings.timezone"
              [flow]="'col'"></ets-timezone-picker>
          </div>
        
        </fieldset>
        
        <fieldset>
          <legend>
            <dx-check-box [(value)]="this.futureOrderDialog.convertToMarket" text="Convert To Market"></dx-check-box>
          </legend>
          
          <div [class.disabled]="!this.futureOrderDialog.convertToMarket">
            
            <ets-convert-to-market [settings]="this.futureOrderDialog.convertToMarketSettings"></ets-convert-to-market>
          
          </div>
        </fieldset>
      
      </dxi-item>
      
      <dxi-item [baseSize]="30" [ratio]="0">
        <div class="buttons">
          <dx-button (onClick)="this.futureOrderDialog.onOkClicked()" text="OK"></dx-button>
          <dx-button (onClick)="this.futureOrderDialog.onCancelClicked()" text="Cancel"></dx-button>
          <dx-button (onClick)="this.futureOrderDialog.onResetClicked()" *ngIf="this.futureOrderDialog.showResetButton"
                     text="Reset"></dx-button>
        </div>
      </dxi-item>
    </dx-box>
  
  </div>
</dx-popup>