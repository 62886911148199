import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';
import { LedgerNote2CellRendererComponent, LedgerNoteCellRendererComponent } from './ledger-note-cell-renderer';
import { LedgerComponent } from './ledger.component';
import { LedgerService } from './ledger.service';

@NgModule({
   imports: [
      CommonModule,
      AgGridModule.withComponents([
         LedgerNoteCellRendererComponent,
         LedgerNote2CellRendererComponent
      ]),
      DevExtremeModule
   ],
   exports: [
      LedgerComponent,
      LedgerNoteCellRendererComponent,
      LedgerNote2CellRendererComponent,
   ],
   declarations: [
      LedgerComponent,
      LedgerNoteCellRendererComponent,
      LedgerNote2CellRendererComponent
   ],
   providers: [LedgerService],
})
export class LedgerModule { }
