<span class="container" #targetElement>

   <div class="lp" *ngIf="this.showLastPrice">
      <ets-last-price [asset]="this.selectedInstrument"></ets-last-price>
   </div>

   <div class="picker">
      <dx-select-box
         [placeholder]="this.placeholder"
         [value]="this.selectedInstrument"
         class="container-item"
         [disabled]="this.disabled"
         [searchEnabled]="false"
         [readOnly]="!this.aclService.isMruListAvailable"
         [items]="this.mruItems"
         [acceptCustomValue]="false"
         [displayExpr]="'displayName'"
         (onValueChanged)="this.onMruItemSelected($event)"
         [showClearButton]="this.showClearButton"
         >
      </dx-select-box>
      <ng-container *ngIf="this.aclService.isPopupAvailable">
         <dx-button
            text="..."
            class="container-item"
            width="30"
            (onClick)="this.togglePopup()"
            [disabled]="disabled"
            >
         </dx-button>
      </ng-container>
   </div>

</span>

<ets-symbol-picker-dialog 
   [isVisible]="isPopupVisible" 
   (closed)="onDialogClosed()"
   (instrumentSelected)="onInstrumentSelected($event)" 
   [underlyings]="this.underlyings"
   >
</ets-symbol-picker-dialog>