import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { SessionHistoryComponent } from '../session-history.component';

@Component({
   selector: 'ets-session-history-popup',
   template: `
   <dx-popup width="95%" height="65%" 
            [showTitle]="true" 
            title="Session History" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="true" 
            [dragEnabled]="true" 
            [visible]="isVisible" 
            (onShown)="onShown()" 
            (onHidden)="onHidden()" 
            [fullScreen]="true">
      <div *dxTemplate="let data of 'content'">
         <ets-session-history></ets-session-history>
      </div>
   </dx-popup>
   `,
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class SessionHistoryPopupComponent implements OnInit {
   
   constructor(private readonly _changeDetector: ChangeDetectorRef) {
   }

   @ViewChild(SessionHistoryComponent) sessionHistoryComponent: SessionHistoryComponent;
   private _config: any;

   isVisible = false;

   ngOnInit() { }

   @DetectMethodChanges()
   onShown() {

      if (this.sessionHistoryComponent) {
         this.sessionHistoryComponent.onShown(this._config);
      }
      
   }

   @DetectMethodChanges()
   onHidden() {

      this.isVisible = false;

      if (this.sessionHistoryComponent) {
         this.sessionHistoryComponent.onHidden();
      }
   }

   @DetectMethodChanges()
   show(config: any) {
      this._config = config;
      this.isVisible = true;
   }
}
