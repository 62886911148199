import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

/**
 * @returns AggregatedPositionDto[]
 */
export class GetAggregatedPositions implements ProtocolQuery {
  constructor(public includeExpiredContracts?: boolean) {

  }
 
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAggregatedPositions`;
  }
}
