import { ProtocolQuery } from 'projects/shared-components/service-model/protocol-query.interface';
import { ClientShellDescriptorDto } from './dto';
import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';
import { PriceBarDto } from 'projects/shared-components/shell-communication/dtos/price-bar-dto.interface';

const NAMESPACE = 'ETS.ClientConsoleService.Protocol';

/**
 * @returns ClientShellTerminalDto[]
 */
export class GetClientShellTerminals implements ProtocolQuery {
   constructor(public clientShells: ClientShellDescriptorDto[]) { }
   getOperationName(): string {
      return `${NAMESPACE}.GetClientShellTerminals`;
   }
}

/**
 * @returns ClientShellDescriptorDto[]
 */
export class GetShellsAvailableForMonitoring implements ProtocolQuery {
   getOperationName(): string {
      return `${NAMESPACE}.GetShellsAvailableForMonitoring`;
   }
}

/**
 * @returns TradingInstrument[]
 */
export class GetAvailableTradingInstruments implements ProtocolQuery {
   constructor(public shells: ClientShellDescriptorDto[]) { }
   getOperationName(): string {
      return `${NAMESPACE}.GetAvailableTradingInstruments`;
   }
}

/**
 * @returns SpecificationDto[]
 */
export class GetAvailableSpecifications implements ProtocolQuery {
   getOperationName(): string {
      return `${NAMESPACE}.GetAvailableSpecifications`;
   }
}

/**
 * @returns AlgoDescriptor[]
 */
export class GetAlgoMetadata implements ProtocolQuery {
   getOperationName(): string {
      return `${NAMESPACE}.GetAlgoMetadata`;
   }
}

/**
 * @returns Guid[]
 */
export class GetDBDSecurityContext implements ProtocolQuery {
   getOperationName(): string {
      return `${NAMESPACE}.GetDBDSecurityContext`;
   }
}

export class SubscribeGuiMarketDataForDashboard implements ProtocolCommand {
   constructor(
      public readonly ticker: string,
      public readonly isLevel2: boolean,
      public readonly isTimesAndSales: boolean
   ) { }

   getOperationName(): string {
      return `${NAMESPACE}.SubscribeGuiMarketDataForDashboard`;
   }
}

export class UnsubscribeGuiMarketDataForDashboard implements ProtocolCommand {
   constructor(
      public readonly ticker: string,
      public readonly isLevel2: boolean,
      public readonly isTimesAndSales: boolean,
   ) { }

   getOperationName(): string {
      return `${NAMESPACE}.UnsubscribeGuiMarketDataForDashboard`;
   }
}


export class SubscribePriceBarsForDashboard implements ProtocolCommand {
   constructor(
      public readonly ticker: string,
      public readonly timeframe: string,
      public readonly subscriptionKey: string,
      public readonly baseBar: PriceBarDto
   ) { }

   getOperationName(): string {
      return `${NAMESPACE}.SubscribePriceBarsForDashboard`;
   }
}

export class UnsubscribePriceBarsForDashboard implements ProtocolCommand {
   constructor(
      public readonly ticker: string,
      public readonly timeframe: string,
      public readonly subscriptionKey: string
   ) { }

   getOperationName(): string {
      return `${NAMESPACE}.UnsubscribePriceBarsForDashboard`;
   }
}

export class GetPriceBarsForDashboard implements ProtocolQuery {
   constructor(
      public readonly ticker: string,
      public readonly timeframe: string,
      public readonly rangeStart: Date,
      public readonly rangeEnd?: Date
   ) { }
   getOperationName(): string {
      return `${NAMESPACE}.GetPriceBarsForDashboard`;
   }
}
