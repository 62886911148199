<dx-popup (onHidden)="this.onHidden()"
          height="66vh"
          [visible]="this.visible"
          [width]="this.getWidth()"
          resizeEnabled="true"
          dragEnabled="true"
          title="Sync Hedges With Client's State"
>
  <div *dxTemplate="let data of 'content'" class="container">
    
    <div class="side former">
      <ng-container *ngTemplateOutlet="positions; context: {
        $implicit: {
          header: 'former',
          callPackages: this.formerCallPackages,
          putPackages: this.formerPutPackages
        }
      }"
      ></ng-container>
    </div>
    
    <div class="side current" *ngIf="this.hasCurrentState">
      <ng-container *ngTemplateOutlet="positions; context: {
        $implicit: {
          header: 'current',
          callPackages: this.currentCallPackages,
          putPackages: this.currentPutPackages
        }
      }"
      ></ng-container>
    </div>
  </div>
  
</dx-popup>

<ng-template #hedgePackage let-ctx>
  <div class="package">
    <div [style.color]="ctx.package.color" class="label">{{ ctx.package.label }}</div>
    <table class="legs-list">
      <tr *ngFor="let row of ctx.package.legs" [style.color]="ctx.package.color">
        <td class="qty">{{ row.qty }}</td>
        <td class="market-side">{{ row.side }}</td>
        <td class="type">{{ row.type }}</td>
        <td>{{ row.expiration | etsFriendlyDatePipe:'full' }}</td>
        <td class="strike">{{ row.strike }}</td>
      </tr>
    </table>
  </div>
  
</ng-template>

<ng-template #positions let-ctx>
  
  <div class="header">
    <div>
      {{ ctx.header | uppercase }}
    </div>
  </div>
  
  <div class="state">
    
    <dx-scroll-view>
      
      <div style="margin-bottom: 10px">
        <ng-container *ngFor="let pkg of ctx.callPackages">
          <ng-container *ngTemplateOutlet="hedgePackage; context: {$implicit: {package: pkg}}">
          </ng-container>
        </ng-container>
      </div>
      
      <div *ngIf="ctx.putPackages">
        <hr class="divider">
        <ng-container *ngFor="let pkg of ctx.putPackages">
          <ng-container *ngTemplateOutlet="hedgePackage; context: {$implicit: {package: pkg}}">
          </ng-container>
        </ng-container>
      </div>
    
    </dx-scroll-view>
  
  </div>
  
  <div class="btn-select">
    <dx-button text="Select" width="100%" (onClick)="this.applyState(ctx.header)">
    </dx-button>
  </div>
</ng-template>