import { QuoteBoardComponent } from './quote-board.component';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultQuoteCellFormatter,
   defaultNumberCellFormatter,
   defaultTimestampFormat,
   defaultLoadingOverlayTemplate
} from 'projects/shared-components/ag-grid-contrib';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { TimestampsService } from '../timestamps.service';

export function getQuoteBoardGridModel(
   this: QuoteBoardComponent,
   displayNameService: TradingInstrumentDisplayNameService,
   timestampsService: TimestampsService
): GridOptions {
   const isDashboard =
      environment.runtimeAppId ===
      EtsConstants.companyServices.etsDashboardApplicationId;
   const columns = setupColumns(displayNameService, timestampsService, isDashboard);

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      onGridReady: args => this.onGridReady(args),

      getRowNodeId: (rowData: QuoteDto) => {
         return rowData.ticker;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Add Symbol',
               action: () => this.showSymbolPicker()
            },
            {
               name: 'Unsubscribe',
               action: () => this.removeQuoteLine(params.node.data),
               disabled: !params.node
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}

function setupColumns(
   displayNameService: TradingInstrumentDisplayNameService,
   timestampsService: TimestampsService,
   isDashboard: boolean
): Partial<AgGridColumn>[] {
   const cols: Partial<AgGridColumn>[] = [];

   cols.push(
      {
         headerName: 'Symbol',
         field: 'ticker',
         valueFormatter: (params: ValueFormatterParams) => {
            return displayNameService.getDisplayNameForTicker(params.value);
         }
      },

      {
         headerName: 'Last',
         field: 'lastPx',
         valueFormatter: defaultQuoteCellFormatter,
         enableCellChangeFlash: true
      },

      {
         headerName: 'Last Qx',
         field: 'lastQx',
         valueFormatter: defaultNumberCellFormatter
      },

      {
         headerName: 'Bid',
         field: 'bid',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: 'Ask',
         field: 'ask',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: 'Bid Qx',
         field: 'bidSize',
         valueFormatter: defaultNumberCellFormatter
      },

      {
         headerName: 'Ask Qx',
         field: 'askSize',
         valueFormatter: defaultNumberCellFormatter
      },

      {
         headerName: 'Open',
         field: 'open',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: 'High',
         field: 'high',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: 'Low',
         field: 'low',
         valueFormatter: defaultQuoteCellFormatter
      },

      {
         headerName: '% Chg',
         field: 'percentChange',
         valueFormatter: defaultNumberCellFormatter
      },

      {
         headerName: 'Timestamp',
         field: 'timeStamp',
         valueFormatter: (params: ValueFormatterParams) => {
            const quote: QuoteDto = params.data;
            if (!quote.timeStamp) {
               return '';
            }
            const fmtTime = timestampsService.getDefaultFormattedDateTime(quote.timeStamp);
            return fmtTime;
         }
      },

      {
         headerName: 'Provider',
         field: 'provider'
      }
   );

   return cols;
}
