import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GameOutcome } from '../game-outcome';
import { DispositionReportComponent } from './disposition-report.component';
import { WinLoss } from '../win-loss';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { StrategyGameRecordDto } from 'projects/shared-components/shell-communication/dtos/strategy-game-record-dto.class';

export function getInnerGamesGridModel(
  this: DispositionReportComponent,
) {
  return  {
    rowData: [],

    defaultColDef: Object.assign({ filter: true }, centeredColumnDef),    

    columnDefs: [
      {
        headerName: 'Strategy',
        field: 'strategyName'
      },
      {
        headerName: 'Outcome',
        field: 'outcome',
        valueGetter(args: ValueGetterParams) {
          const outcome: GameOutcome = args.data.outcome;
          return GameOutcome[outcome];
        }
      },
      //
      {
        headerName: 'Side',
        field: 'side',
        valueGetter(args: ValueGetterParams) {
          const side: MarketSide = args.data.side;
          return MarketSide[side];
        }
      },
      //
      {
        headerName: 'Win/Loss',
        field: 'winLoss',
        valueGetter(args: ValueGetterParams) {
          const winLoss = args.data.winLoss;
          return WinLoss[winLoss];
        },
        cellStyle(args: CellClassParams) {
          if (args.value === 'Win') {
            return { color: 'green' };
          }
          if (args.value === 'Loss') {
            return { color: 'red' };
          }
          return null;
        }
      },
      {
        headerName: 'Bet',
        field: 'actualBet'
      }
    ],

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    onGridReady: (args) => args.api.sizeColumnsToFit(),

    getRowNodeId: (rowData: StrategyGameRecordDto) => {
      return rowData.seqNum + '';
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return ['autoSizeAll', 'copy', 'export'];
    }
  } as GridOptions;
}
