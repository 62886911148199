import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { Subject } from 'rxjs';
import { MessageBusService } from '../message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { filter } from 'rxjs/internal/operators/filter';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { CashFlowTrackingComponent } from './cashflow-tracking.component';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-cashflow-tracking-panel',
   template: `
<div class="panel-container" [ngClass]="{'ets-hidden': !isActive}">
   <ets-cashflow-tracking 
      [container]="this"
      (saveState)="this.saveContainerState()"
      (restoreState)="this.restoreContainerState()"
      >
   </ets-cashflow-tracking>
</div>
   `,
   styles: [`
.panel-container {
  height: 100%;
}
   `]
})
export class CashFlowTrackingPanelComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
   ) {

      super(_changeDetector, _userSettingsService, _messageBus);
   }

   private _unsubscriber: Subject<any> = new Subject<any>();

   @ViewChild(CashFlowTrackingComponent, { static: false }) 
   comp: CashFlowTrackingComponent;

   //
   etsOnInit(): void {
      this._messageBus
         .of<any>('WorkspaceClosed')
         .pipe(
            filter(msg => msg.payload.workspaceId === this.workspaceId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onRemovedFromWorkspace());
   }

   //
   etsOnDestroy(): void {
   }

   //
   etsAfterViewInit(): void {
   }

   //
   saveContainerState() {
      this.saveState();
   }

   //
   restoreContainerState() {
      this.restoreState();
   }

   //
   protected getState() {
      return this.comp.getState();
   }

   //
   protected setState(state: any) {
      this.comp.setState(state);
   }
}
