import { ChangeDetectorRef } from '@angular/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { LongOptionParameters } from '../adjustment-strategy-dialog/strategy-parameters/long-option/long-option-parameters';
import { EtsConstants } from '../ets-constants.const';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { OptionsChainService } from '../option-chains.service';
import { TargetStrikeDialog } from '../portfolios/portfolio-items/TargetStrikeDialog/TargetStrikeDialog';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from '../shell-communication/shell-dto-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategyState } from '../strategies/strategy-state.enum';
import { TimestampsService } from '../timestamps.service';
import { findTimezoneOrigin } from '../timezones/time-zones.const';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { OrderType } from '../trading-model/order-type.enum';
import { daysToExpiration, DetectMethodChanges, DetectSetterChanges, isNullOrUndefined, militaryTimeToAmPm, timespanToUserFriendly } from '../utils';
import { AdjustmentBucketConfig } from './bucket-config';
import { LongTermPutOverviewBlockViewModel } from './overview-blocks/long-term-put-block/long-term-put-overview-block.model';
import { StrategyOverview } from './overview-blocks/strategy-overview.model';


export class LongTermOptionBlock {
   
   constructor(private _changeDetector: ChangeDetectorRef,
               private _toastr: ToastrService,
               private _lastQuoteCache: LastQuoteCacheService,
               private _optionChains: OptionsChainService,
               private _tiService: TradingInstrumentsService,
               private _shellClient: ShellClientService,
               private _messageBus: MessageBusService,
               private _timestampsService: TimestampsService,
               private readonly _strategiesService: StrategiesService,
               private readonly _strategyIssuesService: StrategiesIssuesService,
   ) {
      this.targetStrikeDialog = new TargetStrikeDialog(
         _changeDetector,
         _toastr,
         _lastQuoteCache,
         _optionChains,
         _tiService,
         _shellClient
      );

      this.parameters = new LongOptionParameters(this._changeDetector);

      this._messageBus.of<PositionDto[]>('PositionDto')
         .subscribe(x => this.onPositionDto(x.payload));

      this._messageBus.of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .subscribe(x => this.onPortfolioItemAdded(x.payload));

      this._messageBus.of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .subscribe(x => this.onPortfolioItemRemoved(x.payload));

   }

   private _leg: PortfolioItemDto;
   private _leg2: PortfolioItemDto;

   //
   private _strategy: StrategyModel;
   private _strategy2: StrategyModel;

   //
   private _bucketConfig: AdjustmentBucketConfig;

   get isVisible(): boolean {
      return !isNullOrUndefined(this.displayName);
   }

   blockId: string;
   bgColor = '#5c1c67';
   displayName: string;
   displayName2: string;
   sessionPnL: number;
   accumulatedPnL: number;

   //
   parameters: LongOptionParameters;

   get overviewBlockColor(): 'green' | 'red' | 'grey' {
      return this.getBlockColor();
   }


   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   } 

   //
   get strategyId(): string {
      return this._strategy ? this._strategy.strategyId : null;
   }

   //
   get strategyId2(): string {
      return this._strategy2 ? this._strategy2.strategyId : null;
   }

   //
   get isStrategyRunning(): boolean {
      if (!this._strategy) {
         return false;
      }
      return (this._strategy.state & StrategyState.Enabled) === this._strategy.state;
   } 

   //
   targetStrikeDialog: TargetStrikeDialog;

   //
   setLeg(leg: PortfolioItemDto): void {
      this._leg = leg
   }
   setLeg2(leg2: PortfolioItemDto): void {
      this._leg2 = leg2
   }

   //
   setBucket(bucketConfig: AdjustmentBucketConfig) {
      this._bucketConfig = bucketConfig;
   }

   //
   reset() {
      this._strategy = null;
      this._strategy2 = null;
      this.displayName = null;
      this.displayName2 = null;
      this.targetStrikeDialog.onHidden();
      this.parameters.reset();
   }

   //
   getViewModel(legNo: number): LongTermPutOverviewBlockViewModel {

      let shortOption = 'N/A';
      let shortOptionQty = null;
      let shortOptionAsset = 'N/A';
      let shortOptionExpiration = 'N/A';
      let shortOptionDaysToExpiration = -1;
      let hasErrors = false;
      let noLegs: boolean;

      const theLeg = legNo === 1 ? this._leg : this._leg2;
      
      if (theLeg) {
         shortOption = theLeg.tickerDisplayName;
         shortOptionQty = theLeg.netPosition;
         shortOptionAsset = shortOption.split(' ').slice(0, 3).join(' ');
         shortOptionExpiration = shortOption.split(' ').slice(3).join(' ');

         const sExpDate = theLeg.ticker.split(' ')[4];
         const diffIndays = daysToExpiration(sExpDate);
         shortOptionDaysToExpiration = diffIndays;
      }


      if (!theLeg || theLeg.netPosition >= 0) {
         hasErrors = true;
      }

      noLegs = !theLeg || theLeg.netPosition == 0;

      return {
         bgColor: this.bgColor,
         shortOption,
         shortOptionQty,
         shortOptionAsset,
         shortOptionExpiration,
         shortOptionDaysToExpiration,
         hasErrors,
         noLegs
      };
   }

   //
   getStrategiesOverview(strNo: number): StrategyOverview[] {
      if (!this._strategy && !this._strategy2) {
         return null;
      }

      const strategy = strNo === 1 ? this._strategy : this._strategy2;

      const rollDaysBeforeExpiration = this.parameters.rollDaysBeforeExpiration + ' days';     
      
      const rollTimeMode = this.parameters.rollTimeMode || '';
      
      let rollTime = this.parameters.rollTime || '';
      
      if (rollTimeMode.includes(' Of ')) {
         rollTime = militaryTimeToAmPm(rollTime);
      } else {
         rollTime = timespanToUserFriendly(rollTime);
      }

      let rollTimezoneCountry = 'N/A';
      let rollTimezone = this.parameters.rollTimezone || 'N/A';
      
      if (rollTimezone) {
         const rtzOrigin = findTimezoneOrigin(rollTimezone);
         rollTimezoneCountry = rtzOrigin.country;
         rollTimezone = rtzOrigin.timezone;
      }

      const expirationOffsetMode = this.parameters.expirationOffsetMode || '';
      let expirationOffsetValue = this.parameters.expirationOffsetValue + '';
      if (expirationOffsetMode === 'Calendar Days') {
         expirationOffsetValue = `${expirationOffsetValue} days`;
      }

      const atmNeutralZone = `${this.parameters.atmNeutralZone * 100}%`;

      const orderType = OrderType[this.parameters.orderType];

      const convertToMarket = this.parameters.convertToMarket ? 'Yes' : 'No';

      let ctmMode = 'N/A';
      let ctmTime = 'N/A';
      let ctmTimezoneCountry = 'N/A';
      let ctmTimezone = 'N/A';

      if (convertToMarket === 'Yes') {
         
         ctmMode = this.parameters.convertToMarketSettings.actionTimeMode || '';
         
         ctmTime = this.parameters.convertToMarketSettings.actionTime || '';
         
         if (ctmMode.endsWith(' At')) {
            ctmTime = militaryTimeToAmPm(ctmTime);
         } else if (ctmMode.endsWith(' After')) {
            ctmTime = timespanToUserFriendly(ctmTime);
         }

         const origin = findTimezoneOrigin(this.parameters.convertToMarketSettings.timezone);
         ctmTimezoneCountry = origin.country;
         ctmTimezone = origin.timezone;

      }

      const overview: StrategyOverview = {
         bgColor: this.bgColor,
         parameters: [
         ],
         strategy: strategy
      };

      overview.parameters.push(
         { key: 'Status:', value: this.isStrategyRunning ? 'Enabled' : 'Disabled' },
         { key: 'Issues:', value: this.getNumberOfIssues(strategy) },
         { key: 'Roll [x] Days Before Expiration:', value: rollDaysBeforeExpiration },
         { key: 'Roll Time Mode:', value: rollTimeMode },
         { key: 'Roll Time:', value: rollTime },
      )

      if (rollTimeMode === 'Time Of Day') {
         overview.parameters.push(
            { key: 'Roll Timezone:', value: rollTimezone },
            { key: 'Roll Timezone Country:', value: rollTimezoneCountry },
         );
      }

      overview.parameters.push(
         { key: 'Expiration Offset Mode:', value: expirationOffsetMode },
         { key: 'Expiration Offset Value:', value: expirationOffsetValue },
         // { key: 'ATM Neutral Zone:', value: atmNeutralZone },
         { key: 'Order Type:', value: orderType },
         { key: 'Convert To Market:', value: convertToMarket },
      );

      if (convertToMarket === 'Yes') {
         overview.parameters.push(
            { key: 'Convert Mode:', value: ctmMode },
            { key: 'Convert Time:', value: ctmTime },
            { key: 'Timezone Country:', value: ctmTimezoneCountry },
            { key: 'Timezone:', value: ctmTimezone }
         )
      }

      return [overview];
   }

   //
   private getNumberOfIssues(strategy: StrategyModel): number {
      let result = 0;

      if (isNullOrUndefined(strategy)) {
         return result;
      }

      result = this._strategyIssuesService.getStrategyIssuesCount(strategy.strategyId);
      
      return result;
   }

   //
   private getBlockColor(): 'green' | 'red' | 'grey' {
      const leg = this.targetStrikeDialog.originalLeg;

      if (!leg) {
         return 'grey';
      }

      if ((this.targetStrikeDialog.legQty || 0) === 0) {
         return 'red';
      }

      return 'green';
   }

   //
   @DetectMethodChanges()
   private onPositionDto(dtos: PositionDto[]) {
      if (!this._bucketConfig) {
         return;
      }

      if (!this.targetStrikeDialog.legToRoll) {
         return;
      }

      const filtered = dtos.filter(x => x.comboGroupId === this._bucketConfig.comboGroupId);

      filtered.forEach(dto => {
         const update = dto.positionId === this.targetStrikeDialog.legToRoll.portfolioItemId;

         if (update) {
            this.targetStrikeDialog.legToRoll.netPosition = dto.netPosition;

         } else {
            if (dto.strategyId === EtsConstants.strategies.manualStrategyId) {
               if (dto.netPosition > 0) {
                     dto['portfolioItemId'] = dto.positionId;
                     this.targetStrikeDialog.show({ legToRoll: dto as any });
               }
            }
         }
      });
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.portfolioItem.comboGroupId !== this._bucketConfig.comboGroupId) {
         return;
      }

      if (msg.portfolioItem.itemType === PortfolioItemType.Strategy) {
         if (msg.portfolioItem.algoName && msg.portfolioItem.algoName.toLowerCase().includes('long')) {
                
            // hack for  race condition between 'strategy added' event and 'portfolio item added' event
                let cntr = 0;
                const i = setInterval(() => {
                   
                   cntr++;
    
                   if (cntr > 50) {
                      clearInterval(i);
                      return;
                   }
    
                   const str = this._strategiesService.getById(msg.portfolioItem.portfolioItemId);
                   if (str) {
                      this._strategy = str;
                      clearInterval(i);
                      this._changeDetector.detectChanges();
                   }

                }, 50);
         }
      } else {

         if (msg.portfolioItem.netPosition > 0) {
            this.targetStrikeDialog.show({
               legToRoll: msg.portfolioItem
            });
         }
      }

   }

   //
   @DetectMethodChanges()
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.itemType === PortfolioItemType.Strategy) {
         if (this._strategy && this._strategy.strategyId === msg.portfolioItemId) {
            this._strategy = null;
         }
      } else {
         
         if (!this.targetStrikeDialog.originalLeg) {
            return;
         }
   
         if (this.targetStrikeDialog.legToRoll.portfolioItemId === msg.portfolioItemId) {
            this.targetStrikeDialog.show({ legToRoll: null });
         }
      }
   }

   //
   @DetectMethodChanges()
   setStrategy(strategy: StrategyModel) {
      this._strategy = strategy;
      this.parameters.setParameters(strategy);
   }

   //
   @DetectMethodChanges()
   setStrategy2(strategy: StrategyModel) {
      this._strategy2 = strategy;
      this.parameters.setParameters2(strategy);
   }
}
