import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SmartSettings } from 'projects/shared-components/app-settings/model/ExpirationSmartModeSettings';
import { ExpirationSmartModeDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-global-settngs-expiraion-smart',
   templateUrl: './expiration-smart-settings.component.html',
   styleUrls: [
      './expiration-smart-settings.component.scss',
      '../common-fieldset.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpirationSmartModeSettingsComponent implements OnInit {
   
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   spread: SmartSettings;
   secondSpread: SmartSettings;
   protectiveOption: SmartSettings;
   secondProtectiveOption: SmartSettings;
   
   //
   ngOnInit(): void { 
      this.spread = {isEnabled: true, back: 5, forth: 20};
      this.secondSpread = {isEnabled: true, back: 5, forth: 20};
      this.protectiveOption = {isEnabled: true, back: 1, forth: 3};
      this.secondProtectiveOption = {isEnabled: true, back: 1, forth: 3};
   }

   //
   @DetectMethodChanges()
   onChanges() {}

   //
   @DetectMethodChanges()
   setData(expirationSmartMode: ExpirationSmartModeDto) {
      
      if (isVoid(expirationSmartMode)) {
         return;
      }

      this.spread = expirationSmartMode.spread;
      this.secondSpread = expirationSmartMode.secondSpread;
      this.protectiveOption = expirationSmartMode.protectiveOption;
      this.secondProtectiveOption = expirationSmartMode.secondProtectiveOption;
   }

   //
   getParameters(): ExpirationSmartModeDto {
      
      const reply: ExpirationSmartModeDto = {
         spread: this.spread,
         secondSpread: this.secondSpread,
         protectiveOption: this.protectiveOption,
         secondProtectiveOption: this.secondProtectiveOption
      };

      return reply;
   }

   //
   reset() {
   }

}
