export class HpDatasetItemDto {
  item: number;
  isActive: boolean;
  isCompleted: boolean;
}

export class HpDatasetDto {
  constructor() {  
    this.datasetItems = [];
  }
  datasetId: string;
  strategyId: string;
  datasetItems: HpDatasetItemDto[]; 
  lastUpdateTime: Date;
}
