<dx-popup (onHidden)="this.onClosed()"
          [dragEnabled]="true"
          [resizeEnabled]="true"
          [visible]="this.visible"
          [width]="this.popupWidth"
          [height]="this.popupHeight"
          [title]="this.title"
>
  <div *dxTemplate="let data of 'content'" class="content">
    <div class="text">
      Select Value for '{{this.attributeName}}' Filter
    </div>
    <div class="selector">
      <dx-select-box [(value)]="this.value"
                     [selectedItem]="this.value"
                     [showClearButton]="true"
                     [displayExpr]="'text'"
                     [items]="this.items"
                     [valueExpr]="'value'"
                     (onValueChanged)="this.onValueChanged($event)"
      ></dx-select-box>
    </div>
    <div class="buttons">
      <div class="btn">
        <dx-button width="100%" text="OK" (onClick)="this.onOkClicked()"></dx-button>
      </div>
      <div class="btn">
        <dx-button width="100%" text="Cancel" (onClick)="this.onCancelClicked()"></dx-button>
      </div>
    </div>
  </div>
</dx-popup>