import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { StrategyIssuesSecurityContextService } from './strategy-issues-security-context.service';
import { StrategyIssuesComponent } from './strategy-issues.component';
import { LoggerService } from '../logging/logger-factory.service';
import { DetectMethodChanges } from '../utils';


@Component({
   selector: 'ets-strategy-issues-browser',
   templateUrl: 'strategy-issues-browser.component.html',
   providers: [StrategyIssuesSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyIssuesBrowserComponent implements OnInit, AfterViewInit {
   constructor(
      public securityContext: StrategyIssuesSecurityContextService,
      private readonly _changeDetector: ChangeDetectorRef,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('StrategyIssuesBrowserComponent');
   }

   private readonly _logger: Logger;

   @ViewChild(StrategyIssuesComponent) issuesComponent: StrategyIssuesComponent;
   
   isVisible = false;
   showStrategy: StrategyModel;

   ngOnInit(): void { }

   ngAfterViewInit(): void { }

   @DetectMethodChanges()
   async show(strategy: StrategyModel): Promise<void> {
      this.showStrategy = strategy;
      this.isVisible = true;
   }


   @DetectMethodChanges()
   onShown(): void {
      this.issuesComponent.show(this.showStrategy);
   }

   @DetectMethodChanges()
   onHidden(): void {
      this.issuesComponent.hide();
      this.showStrategy = null;
      this.isVisible = false;
   }
}
