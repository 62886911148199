import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, isVoid} from "../../utils";
import {SettingsStorageService} from "../../settings-storage-service.service";
import {ToastrService} from "ngx-toastr";
import {CashFlowAdjustment} from "../model/CashFlowAdjustment";

const StorageKey = 'cashflow.zones-grid-settings';


export interface ZonesGridSettings {
    rangeDown?: number;
    range: number;
    step: number;
}

@Component({
    selector: 'ets-zones-grid-settings',
    templateUrl: 'zones-grid-settings.component.html',
    styleUrls: ['zones-grid-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ZonesGridSettingsComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _settingsStorageService: SettingsStorageService,
        private _toastr: ToastrService
    ) {
    }

    private _resolve: (value?: (PromiseLike<ZonesGridSettings> | ZonesGridSettings)) => void;
    private _reject: (reason?: any) => void;

    private _underlying: string;

    visible = false;

    range: number;
    step: number;
    rangeDown: number;

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(underlying: string): Promise<ZonesGridSettings> {

        this._underlying = underlying;

        this.visible = true;

        this.restoreSettings();

        return new Promise<ZonesGridSettings>((res, rej) => {
           this._resolve = res;
           this._reject = rej;
        });
    }

    onApply() {
        if (isVoid(this.range)) {
            this._toastr.error('Please, provide the strikes range');
            return;
        }
        if (isVoid(this.step)) {
            this._toastr.error('Please, provide strikes step');
            return;
        }
        const settings: ZonesGridSettings = {
            range: this.range,
            step: this.step,
            rangeDown: this.rangeDown
        };
        this._resolve(settings);
        this.saveSettings();
        this.onHidden();
    }

    onCancel() {
        this._reject();
        this.onHidden();
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this.range = undefined;
        this.step = undefined;
        this.rangeDown = undefined;
    }

    private restoreSettings() {
        const settings = this.getSettings(this._underlying);
        if (isVoid(settings)) {
            return;
        }
        this.range = settings.range;
        this.step = settings.step;
        this.rangeDown = settings.rangeDown;
    }

    private saveSettings() {
        const data: ZonesGridSettings = {
            range: this.range,
            step: this.step,
            rangeDown: this.rangeDown
        };

        if (isVoid(this._underlying)) {
            this._toastr.error('Underlying for Zones Grid settings not specified');
            this._reject();
            return;
        }

        const key = StorageKey + `.${this._underlying}`;

        this._settingsStorageService.setItem(key, data);
    }

    getSettings(underlying: string): ZonesGridSettings {

        const key = StorageKey + `.${underlying}`

        const data = this._settingsStorageService.getItem<ZonesGridSettings>(key);

        if (isVoid(data)) {
            return undefined;
        }

        return data;
    }
}