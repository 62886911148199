import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class ExitStrategies implements ProtocolCommand {
  constructor(public readonly strategies: string[]) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ExitStrategies`;
  }
}
