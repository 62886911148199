import { Inject, Injectable } from '@angular/core';
import { ProtocolCommand } from '../service-model/protocol-command.interface';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CqrsService } from '../service-model/cqrs-service.interface';
import { WebCommand } from '../service-model/web-command.interface';
import { ProtocolQuery } from '../service-model/protocol-query.interface';
import { environment } from '../environments/environment';
import { Logger } from '../logging/logger.interface';
import { LoggerService } from '../logging/logger-factory.service';

@Injectable({ providedIn: 'root' })
export class AuthServiceClientService implements CqrsService {
  constructor(
    private readonly httpClient: HttpClient,
    loggerService: LoggerService
  ) {
    this.queryUrl = `${environment.authServiceUrl}/processQuery`;
    this.commandUrl = `${environment.authServiceUrl}/processCommand`;
    this.logger = loggerService.createLogger('AuthServiceClientService');
  }

  private readonly logger: Logger;
  private readonly queryUrl: string;
  private readonly commandUrl: string;

  public processQuery<T>(query: ProtocolQuery): Promise<T> {
    const requestData: string = JSON.stringify(query);
    const operation: string = query.getOperationName();
    const webCommand: WebCommand = { type: operation, data: requestData };
    return this.httpClient
      .post<any>(this.queryUrl, webCommand)
      .pipe(
        map((x: string) => {
          return JSON.parse(x);
        }),
        map((x: any) => {
          return x as T;
        })
      )
      .toPromise<T>();
  }

  public processCommand(command: ProtocolCommand): Promise<any> {
    const requestData: string = JSON.stringify(command);
    const operation: string = command.getOperationName();
    const webCommand: WebCommand = { type: operation, data: requestData };
    return this.httpClient.post(this.commandUrl, webCommand).toPromise();
  }
}
