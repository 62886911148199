import {ChangeDetectorRef} from "@angular/core";
import {DetectMethodChanges, isVoid} from "../utils";
import {ToastrService} from "ngx-toastr";

export class OpgEditTemplatePopupModel {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }

    private _onSave: (value?: (PromiseLike<string> | string)) => void;

    private _onCancel: (reason?: any) => void;

    visible = false;

    templateName: string;

    title = 'Save As Template';

    @DetectMethodChanges()
    show(title: string, existingTemplateName?: string): Promise<string> {

        this.visible = true;

        this.templateName = existingTemplateName;

        return new Promise((res, rej) => {
            this._onSave = res;
            this._onCancel = rej;
        });
    }

    @DetectMethodChanges()
    onSaveClicked() {
        if (isVoid(this.templateName)) {
            this._toastr.error('Provide template name');
            return;
        }
        this._onSave(this.templateName);
        this.visible = false;
    }

    @DetectMethodChanges()
    onCancelClicked() {
        this._onCancel();
        this.visible = false;
    }
}