import { Component, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService,
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsStorageService } from '../../settings-storage-service.service';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { WorkingOrdersIndex } from 'projects/shared-components/unspecific/working-orders-index.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { ReplaceOrderDialogComponent } from './replace-order-popup/replace-order-dialog.component';
import { GridOptions, GridReadyEvent, GridApi, ColumnState, RowSelectedEvent } from 'ag-grid-community';
import { CancelManualOrders } from 'projects/shared-components/shell-communication/operations/manual-trading/cancel-manual-orders.class';
import { getOrdersGridOptions } from './grid-options-orders';
import { getSnapshotsGridOptions } from './grid-options-snapshots';
import { DetectMethodChanges, DetectSetterChanges, getPanelStateKey, groupTimezoneFamilies, isNullOrUndefined, parseOrderLegs, tickersMatch } from 'projects/shared-components/utils';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { ManualTradingBackendService } from '../manual-trading-backend.service';
import { getTradesGridOptions } from './grid-options-trades';
import { ManualTradingSecurityContextService } from '../manual-trading-security-context.service';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { ComboCreatedDto, ComboGroupCreatedDto, ConvertToMarketSettings, GetAvailableBucketsReply, GreeksDto, GroupDeletedDto, PortfolioCreatedDto, SessionEndedDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { ConvertLimitToMarket, GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';
import { MoveOrderToPortfolioDialogComponent } from './move-to-portfolio-dialog/move-order-to-portfolio-dialog.component';
import { MoveOrderToPortfolioDialogConfig } from './move-to-portfolio-dialog/move-order-to-portfolio-dialog.model';
import { OrdersAndTradesLinkedContext } from '../manual-trading.model';
import {UserSettingsService} from "../../user-settings.service";

function gridsReadyWatcher(res, rej): void {
   let attempts = 0;
   const intRef = setInterval(() => {
      attempts++;
      const ordersGridReady = !!this._ordersGrid;
      const tradesGridReady = !!this._tradesGrid;
      const snapshotsGridReady = !!this._snapshotsGrid;

      if (ordersGridReady && tradesGridReady && snapshotsGridReady) {
         clearInterval(intRef);
         res();
      } else {
         if (attempts >= 4 * 5) {
            clearInterval(intRef);
            rej();
         }
      }
   }, 250);
}

interface PanelState {
   ordersColumnsState: ColumnState[];
   tradesColumnsState: ColumnState[];
   snapshotsColumnsState: ColumnState[];
   isLinked: boolean;
   sectionSizes: SectionSizes;
}

interface SectionSizes {
   top?: number;
   bottom?: number;
}

interface ConvertToMarketConfig {
   orderId?: string;
   isVisible?: boolean;
   settings: ConvertToMarketSettings;
}

const DEFAULT_SECTION_SIZES: SectionSizes = { top: 40, bottom: 60 };

@Component({
   selector: 'ets-otc-base',
   template: ''
})
export abstract class OrdersAndTradesBaseComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _backendClient: ManualTradingBackendService,
      private readonly _tickerDisplayNameService: TradingInstrumentDisplayNameService,
      protected readonly _toastr: ToastrService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _timestampsService: TimestampsService,
      public securityContext: ManualTradingSecurityContextService,
   ) {

      super(_changeDetector, _userSettingsService, _messageBus);

      this._orderIx = new WorkingOrdersIndex();
      this._tradesIx = [];
      this._snapshotsIx = [];
      this.contextPopupParent = document.querySelector('body');
   }

   //#region Fields

   private readonly _orderIx: WorkingOrdersIndex;

   private readonly _tradesIx: TradeDto[];

   private readonly _snapshotsIx: OrderStateSnapshotDto[];

   private readonly _stateChanged = new EventEmitter();

   private _pendingOrdersContainer: OrderDto[];

   private _pendingTradesContainer: TradeDto[];

   private _pendingSnapshotsContainer: OrderStateSnapshotDto[];

   protected _unsubscriber: Subject<any> = new Subject();

   private _tradesGrid: GridOptions;

   private _ordersGrid: GridOptions;

   private _snapshotsGrid: GridReadyEvent;

   private _componentWasShownAtLeastOnce = false;

   private _availableBuckets: GetAvailableBucketsReply = {
      portfolios: [],
      combos: [],
      comboGroups: []
   };

   //#endregion Private Fields


   //#region View Queries

   @ViewChild(ReplaceOrderDialogComponent, { static: true }) replaceDialog: ReplaceOrderDialogComponent;
   @ViewChild(MoveOrderToPortfolioDialogComponent, { static: true }) moveToPortfolioDialog: MoveOrderToPortfolioDialogComponent;

   //#endregion View Queries


   //#region  Instance Props & Accessors

   convertToMarketDialog: ConvertToMarketConfig = { settings: {} };

   tabs = [
      { text: 'Trades' },
      { text: 'Snapshots' }
   ];

   selectedTabIndex = 0;

   sectionSizes: SectionSizes = {};

   ordersGridModel: any;

   tradesGridModel: any;

   snapshotsGridModel: GridOptions;

   contextPopupParent: HTMLElement;

   selectedOrderOrSnapshot: OrderDto | OrderStateSnapshotDto;

   isLinked = true;

   abstract get linkedContext(): OrdersAndTradesLinkedContext;

   get unsubscriber(): Subject<void> {
      return this._unsubscriber;
   }

   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   get ordersGridApi(): GridApi {
      if (this._ordersGrid) {
         return this._ordersGrid.api;
      }
      return undefined;
   }

   get tradesGridApi(): GridApi {
      if (this._tradesGrid) {
         return this._tradesGrid.api;
      }
      return undefined;
   }

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   get availableBuckets(): GetAvailableBucketsReply {
      return this._availableBuckets;
   }

   get lastQuoteCache(): LastQuoteCacheService {
      return this._lastQuoteCache;
   }

   private _isCommentPopupVisible: boolean;
   get isCommentPopupVisible(): boolean { return this._isCommentPopupVisible; }

   @DetectSetterChanges()
   set isCommentPopupVisible(val: boolean) { this._isCommentPopupVisible = val; }

   //#endregion Instance Props & Accessors


   //#region PanelBaseComponent.ts Implementation

   async etsOnInit(): Promise<any> {

      const tickerNameService = this._tickerDisplayNameService;
      const timestampsService = this._timestampsService;

      this.ordersGridModel = getOrdersGridOptions.bind(this)
         (tickerNameService, timestampsService);

      this.tradesGridModel = getTradesGridOptions.bind(this)
         (tickerNameService, timestampsService);

      this.snapshotsGridModel = getSnapshotsGridOptions.bind(this)
         (tickerNameService, timestampsService);

      const resolver = gridsReadyWatcher.bind(this);

      this._availableBuckets = await this.getAvailableBuckets();

      try {

         await new Promise(resolver);

         const key = getPanelStateKey(this);
         const item = this._userSettingsService.getValue(key);
         if (!item) {
            this.saveState();
         }

         this.subscribeToMessages();

         await this.loadManualTradingData();

      } catch (error) {

         const errorMessage = '"Orders & Trades" panel failed to initialize. Please, reload';
         this._toastr.error(errorMessage);

         // as we might have enabled overlays on grids initialization
         const orderGrid = this._ordersGrid;
         if (orderGrid) {
            orderGrid.api.hideOverlay();
         }

         const tradesGrid = this._tradesGrid;
         if (tradesGrid) {
            tradesGrid.api.hideOverlay();
         }

         const snapshotsGrid = this._snapshotsGrid;
         if (snapshotsGrid) {
            snapshotsGrid.api.hideOverlay();
         }

      }
   }

   etsAfterViewInit() { 
      const key = getPanelStateKey(this);
      
      const item = this._userSettingsService.getValue(key);
      if (!item) {
         this.saveState();
      } else {
         this.restoreState();
      }

   }

   etsOnDestroy(): void {
      const unsubscriber = this._unsubscriber;

      if (unsubscriber) {
         unsubscriber.next();
         unsubscriber.complete();
      }
   }

   //#endregion


   //#region Ag-Grid Init

   onTradesGridReady(args: GridReadyEvent): void {
      this._tradesGrid = args;
      args.columnApi.autoSizeAllColumns();
      this._tradesGrid.api.showLoadingOverlay();
   }

   onOrdersGridReady(args: GridReadyEvent): void {
      this._ordersGrid = args;
      args.columnApi.autoSizeAllColumns();
      this._ordersGrid.api.showLoadingOverlay();
   }

   onTradesGridSizeChanged(args: { api: GridApi, clientWidth: number, clientHeight: number }): void {
      if (!this._componentWasShownAtLeastOnce) {
         this._componentWasShownAtLeastOnce = true;
         this.restoreState();
      }
   }

   onSnapshotsGridReady(args: GridReadyEvent): void {
      this._snapshotsGrid = args;
      args.columnApi.autoSizeAllColumns();
   }


   //#endregion Ag-Grid Init

   @DetectMethodChanges()
   onChange(ev) {
      
   }

   //

   getDatetimePickerMode() {
      if (!this.convertToMarketDialog) {
         return null;
      }


      const actionTimeMode = this.convertToMarketDialog.settings.actionTimeMode || '';
      
      if (!actionTimeMode) {
         return null;
      }

      if (actionTimeMode.endsWith(' At')) {
         return 'datetime';
      }

      if (actionTimeMode.endsWith(' After')) {
         return 'timespan';
      }

      return null;
   }

   //

   getConvertDialogHeight(): number {
      let height = 336;
      
      if (this.convertToMarketDialog) {
         
         const convertMode = this.convertToMarketDialog.settings.actionTimeMode || '';

         if (convertMode.endsWith(' At')) {
            height = 430;
         }
      }
      
      return height;
   }

   //

   @DetectMethodChanges()
   showConvertToMarketDialog(order: OrderDto) {
      this.convertToMarketDialog.orderId = order.orderId;
      this.convertToMarketDialog.isVisible = true;
   }

   //

   @DetectMethodChanges()
   convertToMarketDialog_onHidden() {
      this.convertToMarketDialog = { settings: {} };
   }

   //

   @DetectMethodChanges()
   convertToMarketDialog_onCancel() {
      this.convertToMarketDialog.isVisible = false;
   }

   //

   @DetectMethodChanges()
   convertToMarketDialog_onSend() {

      if (!this.convertToMarketDialog.orderId) {
         this._toastr.error('Order not selected');
         return;
      }

      if (!this.convertToMarketDialog.settings.actionTime) {
         this._toastr.error('Convert date & time not provided');
         return;
      }

      if (!this.convertToMarketDialog.settings.timezone) {
         if (this.convertToMarketDialog.settings.actionTimeMode === 'Convert At') {
            this._toastr.error('Timezone not provided');
            return;
         }
      }

      const cmd = new ConvertLimitToMarket(
         this.convertToMarketDialog.orderId,
         this.convertToMarketDialog.settings
      );

      this.isLoading = true;

      try {
         this._backendClient.convertLimitToMarket(cmd);
         const node = this._ordersGrid.api.getRowNode(this.convertToMarketDialog.orderId);
         if (node) {
            node.data.automationAttached = true;
            this.ordersGridApi.redrawRows();
         }
      } finally {
         this.isLoading = false;
         this.convertToMarketDialog.isVisible = false;
      }

   }

   //

   @DetectMethodChanges()
   showMoveToPortfolioDialog(data: OrderDto) {
      const cfg: MoveOrderToPortfolioDialogConfig = {
         itemId: data.orderId,
         itemTerminalId: data.terminalId,
         itemPortfolioId: data.portfolioId,
         itemComboId: data.comboId,
         itemComboGroupId: data.comboGroupId
      };
      this.moveToPortfolioDialog.show(cfg);
   }

   @DetectMethodChanges()
   showCommentPopup(data: any) {
      this.selectedOrderOrSnapshot = data;
      this.isCommentPopupVisible = true;
   }

   @DetectMethodChanges()
   selectTab($event: any): void {
      this.selectedTabIndex = $event.itemIndex;
   }

   @DetectMethodChanges()
   showReplaceDialog(order: OrderDto): void {
      this.replaceDialog.show(order);
   }

   async loadMoreTrades(batchSize: number): Promise<any> {

      this._tradesGrid.api.showLoadingOverlay();

      try {

         const historyStop = this._tradesIx.length ? this._tradesIx[0].seqNum : 0;

         const qry = this.prepareTradesQuery(historyStop, batchSize);

         const resp = await this._backendClient.getTradesWeb(qry);

         const loadedTrades = resp.data as TradeDto[];

         if (loadedTrades.length === 0) {
            return;
         }

         this._tradesIx.unshift(...loadedTrades);

         this._tradesGrid.api.setRowData(this._tradesIx);

      } catch (error) {

         const errorMessage = 'Failed to load trades';
         this._toastr.error(errorMessage);

      } finally {
         this._tradesGrid.api.hideOverlay();
      }
   }

   async cancelAllOrders(): Promise<void> {

      const filteredOrders: OrderDto[] = this._orderIx.getCurrentState();

      if (filteredOrders.length) {

         const cmd = new CancelManualOrders(filteredOrders.map(x => x.orderId));

         try {

            await this._backendClient.cancelManualOrders(cmd);

         } catch (error) {

            const errorMessage = 'Error occurred during order cancel operation';
            this._toastr.error(errorMessage);
         }
      } else {

         this._toastr.info('No working orders to cancel');

      }
   }

   async cancelSelectedOrders(): Promise<any> {
      const selectedRows = this._ordersGrid.api.getSelectedRows() as OrderDto[];

      if (isNullOrUndefined(selectedRows) || selectedRows.length === 0) {
         return;
      }

      const ids = selectedRows.map(x => x.orderId);
      const cmd = new CancelManualOrders(ids);

      try {

         this._ordersGrid.api.showLoadingOverlay();
         await this._backendClient.cancelManualOrders(cmd);

      } catch (error) {

         const errorMessage = 'Error occurred during order cancel operation';
         this._toastr.error(errorMessage);

      } finally {

         this._ordersGrid.api.hideOverlay();

      }
   }

   hasWorkingOrders(): number {
      return this._orderIx.count();
   }

   async loadMoreSnapshots(batchSize: number): Promise<any> {

      this._snapshotsGrid.api.showLoadingOverlay();

      try {

         const historyStop = this._snapshotsIx.length
            ? this._snapshotsIx[0].seqNum
            : 0;

         const qry = this.prepareSnapshotsQuery(historyStop, batchSize);

         const resp = await this._backendClient.getOrderStateSnapshotsWeb(qry);

         const loadedSnapshots = resp.data as OrderStateSnapshotDto[];

         if (loadedSnapshots.length === 0) {
            this._toastr.info('All Snapshots Loaded');
            return;
         }

         this._snapshotsIx.unshift(...loadedSnapshots);
         this._snapshotsGrid.api.setRowData(this._snapshotsIx);

      } catch (error) {

         const errorMessage = 'Failed to load strategy snapshots';
         this._toastr.error(errorMessage);

      } finally {

         this._snapshotsGrid.api.hideOverlay();

      }
   }

   abstract changeIsLinked(): void;

   onStateChanged(): void {
      this._stateChanged.emit();
   }

   onSplitSizeChanged($event: { gutterNum: number; sizes: Array<number> }): void {
      this.sectionSizes.top = $event.sizes[0];
      this.sectionSizes.bottom = $event.sizes[1];
      this._stateChanged.emit();
   }

   async requestOrderStatus(order?: OrderDto): Promise<void> {
      if (!order) {
         // request for all
         const filteredOrders: OrderDto[] = this._orderIx.getCurrentState();

         if (filteredOrders.length) {

            const ordersToShells = filteredOrders.map(o => ({ orderId: o.orderId, shellId: o.shellId }));

            try {

               await this._backendClient.requestOrderStatus(ordersToShells);

            } catch (error) {

               const errorMessage = 'Error occurred during order status request operation';
               this._toastr.error(errorMessage);

            }

         } else {

            this._toastr.info('No working orders for status request');

         }
      } else {

         // request single
         try {

            await this._backendClient.requestOrderStatus([{ orderId: order.orderId, shellId: order.shellId }]);

         } catch (error) {

            const errorMessage = 'Error occurred during order status request operation';
            this._toastr.error(errorMessage);

         }
      }
   }

   protected async loadManualTradingData(shellId?: string): Promise<any> {

      if (shellId) {

         const removeByShellId = (arr: { shellId: string }[]) => {
            let i = arr.length;
            while (i--) {
               if (arr[i].shellId === shellId) {
                  arr.splice(i, 1);
               }
            }
         };

         removeByShellId(this._tradesIx);
         removeByShellId(this._snapshotsIx);

         this._orderIx.resetByShell(shellId);

         const workingOrdersState = this._orderIx.getCurrentState();
         this._ordersGrid.api.setRowData(workingOrdersState);
         this._tradesGrid.api.setRowData(this._tradesIx);
         this._snapshotsGrid.api.setRowData(this._snapshotsIx);

      } else {

         this._orderIx.reset();
         this._tradesIx.length = 0;
         this._snapshotsIx.length = 0;

         this._ordersGrid.api.setRowData([]);
         this._tradesGrid.api.setRowData([]);
         this._snapshotsGrid.api.setRowData([]);
      }

      let hadLoadingErrors = false;

      try {

         this._ordersGrid.api.showLoadingOverlay();
         await this.loadWorkingOrders(shellId);

      } catch (error) {

         hadLoadingErrors = true;

         this._ordersGrid.api.setRowData([]);
         const errorMessage = 'Failed to load working orders';

         this._toastr.error(errorMessage);

      } finally {

         this._ordersGrid.api.hideOverlay();

      }

      if (hadLoadingErrors) {
         return;
      }

      try {

         this._tradesGrid.api.showLoadingOverlay();
         await this.loadTrades(shellId);

      } catch (error) {

         hadLoadingErrors = true;
         this._tradesGrid.api.setRowData([]);
         const errorMessage = 'Failed to load trades';
         this._toastr.error(errorMessage);

      } finally {

         this._tradesGrid.api.hideOverlay();

      }

      if (hadLoadingErrors) {
         return;
      }

      try {

         this._snapshotsGrid.api.showLoadingOverlay();
         await this.loadSnapshots(shellId);

      } catch (error) {

         hadLoadingErrors = true;
         this._snapshotsGrid.api.setRowData([]);
         const errorMessage = 'Failed to load snapshots';
         this._toastr.error(errorMessage);

      } finally {

         this._snapshotsGrid.api.hideOverlay();

      }
   }

   // Working orders

   private async loadWorkingOrders(shellId?: string): Promise<any> {

      this._pendingOrdersContainer = [];

      let workingOrders: OrderDto[];

      try {

         workingOrders = await this.getWorkingOrdersFromServer(shellId);

         const totalOrders = workingOrders.concat(this._pendingOrdersContainer);

         const filteredOrders = {};

         totalOrders.forEach(order => {
            if (!!order) {
               filteredOrders[order.orderId] = order;
            }
         });

         const rowData = Object.keys(filteredOrders)
            .map(key => filteredOrders[key])
            .filter((dto: OrderDto) => (dto.status & OrderStatus.Working) === dto.status);

         const state = this._orderIx.calculateState(rowData);

         this._ordersGrid.api.setRowData(state);

         const tickersToSubscribe = [];

         state.forEach(order => {
            if (tickersToSubscribe.indexOf(order.ticker) === -1) {
               tickersToSubscribe.push(order.ticker);
            }
         });

         tickersToSubscribe.forEach(ticker => this._lastQuoteCache.subscribeTicker(ticker));

      } finally {

         this._pendingOrdersContainer = null;

      }
   }

   private async getWorkingOrdersFromServer(shellId?: string): Promise<OrderDto[]> {

      const qry = this.prepareWorkingOrdersQuery();

      if (isNullOrUndefined(qry)) {
         return Promise.resolve([]);
      }

      const resp = await this._backendClient.getWorkingOrdersWeb(qry, shellId);

      return resp.data as OrderDto[];

   }

   protected abstract prepareWorkingOrdersQuery(): GetWorkingOrdersWeb;

   // Trades

   private async loadTrades(shellId?: string): Promise<any> {
      let trades: TradeDto[];

      this._pendingTradesContainer = [];

      try {

         trades = await this.getTradesFromServer(shellId);

         this._tradesIx.length = 0;

         if (trades.length > 0) {
            const lastSeqNum = trades[trades.length - 1].seqNum;

            const filteredPendings = this._pendingTradesContainer.filter(
               t => t.seqNum > lastSeqNum
            );

            const totalTrades = trades.concat(filteredPendings);

            this._tradesIx.push(...totalTrades);

         }

         this._tradesGrid.api.setRowData(this._tradesIx);

      } finally {

         this._pendingTradesContainer = null;

      }
   }

   private async getTradesFromServer(shellId?: string): Promise<TradeDto[]> {

      const qry: GetTradesWeb = this.prepareTradesQuery();

      if (isNullOrUndefined(qry)) {
         return Promise.resolve([]);
      }

      const resp = await this._backendClient.getTradesWeb(qry, shellId);

      return resp.data as TradeDto[];

   }

   protected abstract prepareTradesQuery(historyStop?: number, batchSize?: number): GetTradesWeb;

   // Snapshots

   private async loadSnapshots(shellId?: string): Promise<any> {

      let snapshots: OrderStateSnapshotDto[];
      this._pendingSnapshotsContainer = [];

      try {

         snapshots = await this.getSnapshotsFromServer(EtsConstants.strategies.manualStrategyId, shellId);
         this._snapshotsIx.length = 0;

         if (snapshots.length > 0) {

            const lastSeqNum = snapshots[snapshots.length - 1].seqNum;

            const filteredPendings = this._pendingSnapshotsContainer.filter(
               t => t.seqNum > lastSeqNum
            );

            const totalSnapshots = snapshots.concat(filteredPendings);

            this._snapshotsIx.push(...totalSnapshots);
         }

         this._snapshotsGrid.api.setRowData(this._snapshotsIx);

      } finally {

         this._pendingSnapshotsContainer = null;

      }
   }

   private async getSnapshotsFromServer(strategyId: string, shellId?: string): Promise<OrderStateSnapshotDto[]> {

      const qry: GetOrderStateSnapshotsWeb = this.prepareSnapshotsQuery();

      if (isNullOrUndefined(qry)) {
         return Promise.resolve([]);
      }

      const resp = await this._backendClient.getOrderStateSnapshotsWeb(qry, shellId);

      return resp.data as OrderStateSnapshotDto[];

   }

   protected abstract prepareSnapshotsQuery(historyStop?: number, batchSize?: number): GetOrderStateSnapshotsWeb;

   //

   private onOrderDtoMessage(dtos: OrderDto[]): void {

      if (!this.linkedContext) {
         console.error('Linked context not set');
         return;
      }

      const filtered = this.filterTradingEntities(dtos);

      const pendingDataContainer = this._pendingOrdersContainer;

      if (pendingDataContainer) {
         pendingDataContainer.push(...filtered);
      } else {
         const state = this._orderIx.calculateState(filtered);
         state.forEach(x => this._lastQuoteCache.subscribeTicker(x.ticker));
         this._ordersGrid.api.setRowData(state);
      }
   }

   private onTradeDtoMessage(dtos: TradeDto[]): void {

      const filtered = this.filterTradingEntities(dtos);

      const pendingDataContainer = this._pendingTradesContainer;

      if (pendingDataContainer) {

         pendingDataContainer.push(...filtered);

      } else {

         this._tradesIx.push(...filtered);
         this._tradesGrid.api.applyTransactionAsync({ add: filtered });
      }
   }

   private onSnapshotDtoMessage(dtos: OrderStateSnapshotDto[]): void {

      const filtered = this.filterTradingEntities(dtos);

      const pendingDataContainer = this._pendingSnapshotsContainer;

      if (pendingDataContainer) {
         pendingDataContainer.push(...filtered);
      } else {
         this._snapshotsIx.push(...filtered);
         this._snapshotsGrid.api.applyTransactionAsync({ add: filtered });
      }
   }

   //

   protected abstract filterTradingEntities<T extends TradeDto | OrderDto | OrderStateSnapshotDto>(entities: T[]): T[];

   //

   private async onClearTradingDataMessage(message: ClearTradingDataUIMessage) {

      if (!message.data.manual && !message.refreshDb) {
         return;
      }

      await this.loadManualTradingData(message.shellId);
   }

   private async onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {

      await this.loadManualTradingData(message.shellId);

   }

   //

   private subscribeToMessages(): void {

      this._messageBus
         .of<any>('OrderDto')
         .pipe(
            filter(x => EtsConstants.strategies.manualStrategyId in x.payload),
            map(x => x.payload[EtsConstants.strategies.manualStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onOrderDtoMessage(message));

      this._messageBus
         .of<any>('TradeDto')
         .pipe(
            filter(x => EtsConstants.strategies.manualStrategyId in x.payload),
            map(x => x.payload[EtsConstants.strategies.manualStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onTradeDtoMessage(message));

      this._messageBus
         .of<any>('OrderStateSnapshotDto')
         .pipe(
            filter(x => EtsConstants.strategies.manualStrategyId in x.payload),
            map(x => x.payload[EtsConstants.strategies.manualStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onSnapshotDtoMessage(message));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onClearTradingDataMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this.onShellConnectionStatusChanged(data.payload));

      this._stateChanged.pipe(
         throttleTime(250),
         takeUntil(this._unsubscriber)
      ).subscribe(() => this.saveState());

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onQuoteMessage(x.payload));


      this._messageBus.of<GreeksDto>('GreeksDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onGreeks(x.payload));


      this._messageBus
         .of<SessionEndedDto>('SessionEndedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onSessionResetCompleted(msg.payload));

      this._messageBus
         .of<PortfolioCreatedDto>('PortfolioCreatedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this.onPortfolioCreatedMessage(dto.payload));

      this._messageBus
         .of<ComboCreatedDto>('ComboCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onComboCreatedMessage(msg.payload));

      this._messageBus
         .of<ComboGroupCreatedDto>('ComboGroupCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onComboGroupCreatedMessage(msg.payload));

      this._messageBus
         .of<GroupDeletedDto>('GroupDeletedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this.onGroupDeletedMessage(dto.payload));

      this._messageBus.of('DefaultTimezoneChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => {
            if (this._ordersGrid) {
               this._ordersGrid.api.refreshCells({force: true});
            }
            
            if (this._tradesGrid) {
               this._tradesGrid.api.refreshCells({force: true});
            }

            if (this._snapshotsGrid) {
               this._snapshotsGrid.api.refreshCells({force: true});
            }
         });

      this.subscribeSpecificMessages();
   }

   //

   private onPortfolioCreatedMessage(dto: PortfolioCreatedDto): void {
      this._availableBuckets.portfolios.push(dto.portfolio);
   }

   //

   private onComboCreatedMessage(msg: ComboCreatedDto): void {
      this._availableBuckets.combos.push(msg.combo);
   }

   //

   private onComboGroupCreatedMessage(msg: ComboGroupCreatedDto): void {
      this._availableBuckets.comboGroups.push(msg.comboGroup);
   }

   //

   private onGroupDeletedMessage(dto: GroupDeletedDto): void {

      switch (dto.groupType) {
         case 'combo':
            {
               const ix = this._availableBuckets.combos.findIndex(x => x.comboId === dto.comboId);

               if (ix >= 0) {
                  this._availableBuckets.combos.splice(ix, 1);
               }
            }
            break;

         case 'group':
            {
               const ix = this._availableBuckets.comboGroups.findIndex(x => x.comboGroupId === dto.comboGroupId);

               if (ix >= 0) {
                  this._availableBuckets.comboGroups.splice(ix, 1);
               }
            }
            break;

         case 'portfolio':
            {
               const ix = this._availableBuckets.portfolios.findIndex(x => x.portfolioId === dto.portfolioId);

               if (ix >= 0) {
                  this._availableBuckets.portfolios.splice(ix, 1);
               }
            }
            break;

         default:
            return;
      }
   }

   //

   protected abstract subscribeSpecificMessages(): void;

   //

   private async onSessionResetCompleted(msg: SessionEndedDto): Promise<void> {
      await this.loadManualTradingData();
   }

   //

   private onQuoteMessage(quotes: QuoteDto[]) {

      this._ordersGrid.api.forEachLeafNode(node => {

         const order = node.data as OrderDto;

         if (!order) {
            return;
         }

         if (order.multiLegDescriptor) {

            if (!order.legs || order.legs.length === 0) {
               parseOrderLegs(order, this._availableBuckets, this.lastQuoteCache);
            }

            order.legs.forEach(leg => {
               
               const quote = quotes.find(q => tickersMatch(q.ticker, leg.ticker));

               if (!quote) {
                  return;
               }

               if (quote.ticker.indexOf('@XSP') >= 0)  {

                  leg.liveQuote = quote.mid;

               } else {

                  if (leg.qty > 0) {

                     leg.liveQuote = quote.ask;
   
                  } else if (leg.qty < 0) {
   
                     leg.liveQuote = quote.bid;
   
                  } else {
   
                     leg.liveQuote = quote.lastPx;
   
                  }
               }

            });

            const sum = order.legs
               .map(data => {

                  return { qty: data.qty, liveQuote: data.liveQuote };

               }).reduce((prev, curr) => {

                  return (curr.liveQuote * curr.qty) + prev;

               }, 0);

            order.liveQuote = isNaN(sum) ? null : sum;

         } else {

            const quote = quotes.find(q => tickersMatch(q.ticker, order.ticker));

            if (quote) {

               if (quote.ticker.indexOf('@XSP') >= 0) {

                  order.liveQuote = quote.mid;

               } else {
                  if (order.side === MarketSide.Buy) {

                     order.liveQuote = quote.ask;

                  } else if (order.qty === MarketSide.Sell) {

                     order.liveQuote = quote.bid;

                  } else {

                     order.liveQuote = quote.lastPx;
                  }
               }

            }
         }

         this._ordersGrid.api.applyTransaction({ update: [order] });
      });
   }

   private onGreeks(x: GreeksDto): void {

      this._ordersGrid.api.forEachNode(node => {

         if (!node.expanded) {
            return;
         }

         const detailGridInfo = this._ordersGrid.api.getDetailGridInfo(`detail_${node.id}`);

         if (detailGridInfo) {

            const legNode = detailGridInfo.api.getRowNode(x.ticker);

            if (legNode) {
               const data = legNode.data;

               if (data) {
                  data.delta = x.delta;
                  data.gamma = x.gamma;
                  data.vega = x.vega;
                  data.theta = x.theta;

                  detailGridInfo.api.refreshCells({ rowNodes: [legNode], force: true });
               }
            }
         }

      });
   }

   protected abstract resetLinkedContext(): void;

   protected getState(): PanelState {
      if (!this._tradesGrid || !this._ordersGrid || !this._snapshotsGrid) {
         return null;
      }

      if (!this._componentWasShownAtLeastOnce) {
         return null;
      }

      const ordersGridState = this._ordersGrid.columnApi.getColumnState();
      const tradesGridState = this._tradesGrid.columnApi.getColumnState();
      const snapshotsGridState = this._snapshotsGrid.columnApi.getColumnState();
      const state: PanelState = {
         ordersColumnsState: ordersGridState,
         tradesColumnsState: tradesGridState,
         snapshotsColumnsState: snapshotsGridState,
         isLinked: this.isLinked,
         sectionSizes: this.sectionSizes
      };

      return state;
   }

   protected setState(state: PanelState): void {
      if (!this._ordersGrid || !this._tradesGrid || !this._snapshotsGrid) {
         return;
      }

      let isOrdersOK = true;
      if (state.ordersColumnsState) {
         isOrdersOK = this._ordersGrid.columnApi.setColumnState(state.ordersColumnsState);
      }

      let isTradesOK = true;
      if (state.tradesColumnsState) {
         isTradesOK = this._tradesGrid.columnApi.setColumnState(state.tradesColumnsState);
      }


      let isSnapshotsOK = true;
      if (state.snapshotsColumnsState) {
         isSnapshotsOK = this._snapshotsGrid.columnApi.setColumnState(state.snapshotsColumnsState);
      }

      if (!isOrdersOK || !isTradesOK || !isSnapshotsOK) {
         this._toastr.error(`"Manual Orders & Trades" panel was restored with errors`);
      }

      this.isLinked = state.isLinked;

      if (this._componentWasShownAtLeastOnce) {
         if (state.sectionSizes) {

            let defaultSizes = DEFAULT_SECTION_SIZES;

            if (state.sectionSizes.top && state.sectionSizes.bottom) {
               defaultSizes = state.sectionSizes;
            }

            if (this.sectionSizes.top === defaultSizes.top && this.sectionSizes.bottom === defaultSizes.bottom) {
               this.sectionSizes = { top: 0, bottom: 100 };
               setTimeout(() => this.sectionSizes = defaultSizes, 0);
            } else {
               this.sectionSizes = defaultSizes;
            }
         }
      }
   }

   private getAvailableBuckets(): Promise<GetAvailableBucketsReply> {
      return this._backendClient.getAvailableBuckets();
   }

   onRowSelected(args: RowSelectedEvent): void {
      if (args.node.group) {
         return;
      }

      if (!args.node.isSelected) {
         return;
      }

      const order = args.node.data as OrderDto;

      this._messageBus.publishAsync({
         topic: 'OrderHighlightedUIMessage',
         payload: {
            order
         },
         scopeId: this.layoutTabId
      });
   }
}
