<div class="ir-container" [ngClass]="{'auto': this.auto}">
   <dx-scroll-view>

      <div class="row" *ngIf="!this.manualMode && !this.auto">
         <div class="col label">Display Name:</div>
         <div class="col value">
            <dx-text-box [(value)]="this.parameters.displayName"
            (onValueChanged)="this.parameters.onChange($event)"></dx-text-box>
         </div>
      </div>
   
      <fieldset [ngClass]="{'auto': this.auto}">
         <legend>Amount & Account</legend>
         
         <div class="row" [ngClass]="{'auto': this.auto}">
            <div class="col label">Sum:</div>
            <div class="col value">
               <dx-number-box
                  [(value)]="this.parameters.amount"
                  (onValueChanged)="this.parameters.onChange($event)"
                  [showClearButton]="true"
                  [useLargeSpinButtons]="false"
                  format="$#,##0">
               </dx-number-box>
            </div>
         </div>
   
         <div class="row-2" [ngClass]="{'auto': this.auto}">

            <div class="col">
               <div class="label">% Rate:</div>
               <div class="value">
                  <dx-number-box 
                     [(value)]="this.parameters.rate"
                     (onValueChanged)="this.parameters.onChange($event)"
                     [showClearButton]="true"
                     [useLargeSpinButtons]="false"
                     [min]="0"
                     [step]="0.0001"
                     format="#0.00%">
                  </dx-number-box>
               </div>
            </div>

            <div class="col">
               <div class="label">Days In Year:</div>
               <div class="value">
                  <dx-number-box 
                     [(value)]="this.parameters.daysInYear"
                     [showClearButton]="true"
                     [useLargeSpinButtons]="false"
                     [min]="1"
                     [step]="1">
                  </dx-number-box>
               </div>
            </div>
            
         </div>

         <div class="row" [ngClass]="{'auto': this.auto}">
            <div class="col label">Account</div>
            <div class="col value">
               <dx-select-box [items]="this.parameters.accountList"
                              displayExpr="description"
                              valueExpr="accountId"
                              [(value)]="this.parameters.account"
               ></dx-select-box>
            </div>
         </div>

      </fieldset>
   
      <fieldset *ngIf="!this.manualMode" [ngClass]="{'auto': this.auto}">
         
         <legend>Interval</legend>
         
         <div class="row" [ngClass]="{'auto': this.auto}">
            <div class="col label">Repeat</div>
            <div class="col value">
               <dx-select-box 
                     [items]="['Daily', 'Weekly']" 
                     [(value)]="this.parameters.repeat"
                     (onValueChanged)="this.parameters.onRepeatChanged()">
               </dx-select-box>
            </div>
         </div>
   
         <div class="row" [ngClass]="{'auto': this.auto}" *ngIf="this.parameters.repeat === 'Daily'">
            <div class="daily">
               <div class="col">Every</div>
               <div class="col number">
                  <dx-number-box [(value)]="this.parameters.everyXday" 
                                 [showClearButton]="true"></dx-number-box>
               </div>
               <div class="col">day</div>   
            </div>
         </div>
   
         <div class="row" [ngClass]="{'auto': this.auto}" *ngIf="this.parameters.repeat === 'Weekly'">
            <div class="weekly">
               <div class="days">
                  <div class="col"><dx-check-box text="Mon" [(value)]="this.parameters.mon"></dx-check-box></div>
                  <div class="col"><dx-check-box text="Tue" [(value)]="this.parameters.tue"></dx-check-box></div>
                  <div class="col"><dx-check-box text="Wed" [(value)]="this.parameters.wed"></dx-check-box></div>
                  <div class="col"><dx-check-box text="Thu" [(value)]="this.parameters.thu"></dx-check-box></div>
                  <div class="col"><dx-check-box text="Fri" [(value)]="this.parameters.fri"></dx-check-box></div>
               </div>
   
               <div class="interval">
                  <div class="col">Every</div>
                  <div class="col number">
                     <dx-number-box [showClearButton]="true" [min]="1"
                                 [(value)]="this.parameters.everyXweek"></dx-number-box>
                  </div>
                  <div class="col">week</div>
               </div>
            </div>
         </div>
   
         
      </fieldset>

      <fieldset [ngClass]="{'auto': this.auto}">
         <legend>Specific Time</legend>
         
         <div class="row" [ngClass]="{'auto': this.auto}">
            <div class="col label">
               Country:
            </div>
            <div class="col value">
               <dx-select-box 
                  placeholder="Select Country" 
                  displayExpr="displayName"
                  [items]="this.parameters.timezoneFamilies"
                  [grouped]="true"
                  [value]="this.parameters.timezoneCountry" 
                  valueExpr="displayName"
                  (valueChange)="this.parameters.onTimezoneCountryChange($event)"
                  [showClearButton]="true" #tzFamilies>
               </dx-select-box>
            </div>
         </div>

         <div class="row" [ngClass]="{'auto': this.auto}">
            <div class="col label">Timezone:</div>
            <div class="col value">
               <dx-select-box placeholder="Select Timezone"
                  [items]="this.parameters.timezoneItems"
                  [(value)]="this.parameters.timezone" 
                  displayExpr="displayName"
                  valueExpr="nodaTimeZoneId"
                  [showClearButton]="true">
               </dx-select-box>
            </div>
         </div>

         <div class="row" [ngClass]="{'auto': this.auto}" *ngIf="this.manualMode">
            <div class="col label">Date</div>
            <div class="col value">
               <ets-datetime-picker mode="date" [(value)]="this.parameters.date"
                  (onValueChanged)="this.parameters.onChange($event)"></ets-datetime-picker>
            </div>
         </div>

         <div class="row" [ngClass]="{'auto': this.auto}">
            <div class="col label">Time of Day</div>
            <div class="col value">
               <ets-datetime-picker mode="time" [(value)]="this.parameters.timeOfDay"
                  (onValueChanged)="this.parameters.onChange($event)"></ets-datetime-picker>
            </div>
         </div>

         
         <div class="row" *ngIf="this.manualMode" [ngClass]="{'auto': this.auto}">
            <div class="col label">Days Of Interest</div>
            <div class="col value">
               <dx-number-box [(value)]="this.parameters.daysOfInterest"></dx-number-box>
            </div>
         </div>

      </fieldset>
      
   </dx-scroll-view>   
</div>