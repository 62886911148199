import { NgModule } from '@angular/core';
import { SessionHistoryComponent } from './session-history.component';
import { AgGridModule } from 'ag-grid-angular';
import {
   SessionHistoryAggregatedRowHeaderRenderer
} from './session-history-aggregated-row-header-renderer.component';
import { CommonModule } from '@angular/common';
import { SimpleHeaderGroupRowInnerRenderer } from 'projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { SessionHistoryPopupComponent } from './popup/session-history-popup.component';
import { SessionHistoryPanelComponent } from './panel/session-history-panel.component';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      AgGridModule.withComponents([
         SessionHistoryAggregatedRowHeaderRenderer,
         SimpleHeaderGroupRowInnerRenderer
      ]),
      DevExtremeModule
   ],
   exports: [
      SessionHistoryPopupComponent,
      SessionHistoryPanelComponent
   ],
   declarations: [
      SessionHistoryComponent,
      SessionHistoryPopupComponent,
      SessionHistoryPanelComponent,
      SessionHistoryAggregatedRowHeaderRenderer
   ],
   providers: []
})
export class SessionHistoryModule { }
