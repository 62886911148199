import { CashFlowStrategyRole } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { isNullOrUndefined } from "util";

class CashFlowEntityWrapper {
   constructor(private _entity: any) {}

   get entity() {
      return this._entity;
   }

   get isArchived(): boolean {
      return this._entity.isArchived;
   }

   get role(): CashFlowStrategyRole {
      if (this._entity.role) {
         return this._entity.role;
      }

      if (this._entity.legRole) {
         return this._entity.legRole;
      }

      if (this._entity.bucketRole) {
         return this._entity.bucketRole;
      }

      return undefined;
   }

   get qty(): number {

      if (!isNullOrUndefined(this._entity.qty)) {
         return this._entity.qty;
      }

      if (!isNullOrUndefined(this._entity.netPosition)) {
         return this._entity.netPosition;
      }

   }

}

type RoleWeights = { [K in CashFlowStrategyRole]: number };

const WEIGHTS_TABLE: RoleWeights = {
   'Asset':                   0,
   'ShortOption':             1,
   'SpreadLongLeg':           2,
   'SpreadShortLeg':          3,
   'SecondSpreadLongLeg':     4,
   'SecondSpreadShortLeg':    5,
   'ProtectiveOption':        6,
   'SecondProtectiveOption':  7
}

export function cashFlowPositionsSort(entities: any[], order: 'asc' | 'desc' = 'asc'): any[] {

   const wrapped = entities.map(x => new CashFlowEntityWrapper(x));

   const sortedWrapped = wrapped.sort((a, b) =>  {

      if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
         return 0;
      }

      if (a.isArchived || b.isArchived) {
         return 1;
      }

      const aRole = WEIGHTS_TABLE[a.role];
      
      if (isNullOrUndefined(aRole)) {
         return -1;
      }

      const bRole = WEIGHTS_TABLE[b.role];

      if (isNullOrUndefined(bRole)) {
         return -1;
      }

      return order === 'asc' ? aRole - bRole : bRole - aRole;
   });

   return sortedWrapped.map(x => x.entity);

}