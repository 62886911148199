export const EtsConstants = {
   algorithms: {
      limitAlgoId: '63b9803e-dc5f-407a-a089-ea846a221be4',
      bullBearAlgoId: '52e421a5-0cdd-4c84-8430-b282b55ef9a8',
      dynamicHedgeAlgoId: 'ad38a4cc-7212-4446-9a2c-19a57f7fa973',
      freezeHedgeAlgoId: '8094d698-0cb0-4692-95f3-40ded27750b4',
      hedgeAlgoId: 'eb0234e9-280b-4aa9-9716-e820a8bf05a3',
      fusionAlgoId: 'bc529650-7ff8-47c7-8713-55ef4ce8055c',
      flagships: {
         followAlgoId: '2f268317-8157-4643-983d-a01f7e2055d1',
         outrightAlgoId: '235a0f9c-6e9d-4ba0-aba4-ca8b3a3d9246',
         s40AlgoId: '26b37a9f-eb73-4568-82e5-f8d8d8c14d91',
         beforeLastAlgoId: 'cce8b0fe-7983-45ac-8dc0-771d5e2e36a6',
         oppositeBeforeLastAlgoId: 'a5a51189-e432-4eb8-8480-afa22db7ea63',
         majority: 'c007e674-4588-4e98-8099-82dde892e40d',
         minority: '8d14b83f-d3f9-432a-98bf-c046ca60e310',
         zigZag: 'fe53d361-de66-41ca-a965-0db6a5951f17',
         fmr: '9e0693e4-3aee-46be-a951-7d2508f9f54a'
      },
      adjustment: {
         putSpreadAlgoId: '2b77bb62-42d1-4bb8-8d85-d84b8c636748',
         longOptionAlgoId: '66e80323-139f-490b-a4a7-2d2a4da7dcf0',
         shortOptionAlgoId: '7158e24e-19bc-4228-a708-a6343054e31f',
         putDebitSpreadRollAlgoId: '1ba31a08-2619-48bc-9192-38fa2b81aafa',
         cashFlow: {
            cashFlowAutoAdjustmentId: 'da4c6bca-9a57-49ae-8cdb-4edf35fe41e6',
            hedgedPortfolioId: '51ac4f93-c9a4-4371-baa0-1633c7b449ea',
            reversedHedgedPortfolioId: '1e1f5e89-f619-48d6-a30f-937f5a8fa980',
            callsId: '42c5c87d-9872-4374-b102-aafeabbcc4ab',
            putsId: 'a07c87e1-35ca-4491-bc08-bb0cc50b9ffe',
            callsAndPutsId: '5001d8a8-fc1f-4a2d-a9af-61ee582620f6',
         }
      },
      manualStrategyId: '00000000-0000-0000-0000-000000000002',
      trendLimitAlgoId: '390fcd11-e0e4-4662-ae1c-123bf420e805',
      tradeTheLineAlgoId: '52411e71-e0ec-45eb-8b78-a580da6bcaad',
      remoteAlgoId: '21ffdad7-b234-420d-a6c3-5d8112006396',
      interestAlgoId: '1b807506-4c14-4005-a826-55625946856b',
   },
   strategies: {
      manualStrategyId: '00000000-0000-0000-0000-000000000002',
      groupExitStrategyId: '00000000-0000-0000-0000-000000000004',
      unknownStrategyId: '00000000-0000-0000-0000-000000000001'
   },
   companyServices: {
      etsWebTraderApplicationId: '4df01585-45a1-4e20-9294-dc6743022cba',
      etsDashboardApplicationId: '1189a5f3-b4f7-498f-b261-f9db10e58418',
      fullEditStrategyWindowUIElement: 'c1f14db1-85ee-4043-9b61-103c5af8f9ab'
   },
   emptyGuid: '00000000-0000-0000-0000-000000000000',
   storageKeys: {
      scopePrefix: 'ets',
      separator: '+',
      layoutRootKey: 'layout_root',
      dashboardScopeKey: 'ets+dashboard_scope',
      appSettings: {
         adjustmentPricingGrid: 'app.settings.apg',
         defaultTimezone: 'app.settings.default-timezone'
      },
   },
   bucketAttributes: {
      positionSizing: 'Bucket.PositionSizing',
      setQty: 'Bucket.SetQty'
   }
};

//

export function isFlagshipAlgo(algoId: string): boolean {
   switch (algoId) {
      case EtsConstants.algorithms.flagships.followAlgoId:
      case EtsConstants.algorithms.flagships.s40AlgoId:
      case EtsConstants.algorithms.flagships.outrightAlgoId:
      case EtsConstants.algorithms.flagships.beforeLastAlgoId:
      case EtsConstants.algorithms.flagships.oppositeBeforeLastAlgoId:
      case EtsConstants.algorithms.flagships.majority:
      case EtsConstants.algorithms.flagships.minority:
      case EtsConstants.algorithms.flagships.zigZag:
      case EtsConstants.algorithms.flagships.fmr:
         return true;
      default:
         return false;
   }
}

//

export function isDisposition(algoId: string): boolean {
   switch (algoId) {
      case EtsConstants.algorithms.fusionAlgoId:
         return true;
      default:
         return false;
   }
}
