<div class="column left" style="margin: auto; float: none">

  <div class="ets-field">
    <div class="ets-field-label">Symbol</div>
    <div class="ets-field-value">
      <ets-symbol-picker 
           (instrumentSelected)="control.symbol = $event"
           [selectedInstrument]="control.symbol"
           #symbolPicker
      ></ets-symbol-picker>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Multiplier</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.multiplierItems"
                     [value]="control.multiplier"
                     (valueChange)="control.multiplier = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.multiplierItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Chart Timeframe</div>
    <div class="ets-field-value">
        <dx-select-box [items]="control.chartTimeframeItems"
                       [displayExpr]="'text'"
                       [valueExpr]="'value'"
                       [(value)]="control.chartTimeframe"
        ></dx-select-box>
    </div>
  </div>

  <fieldset>
    <legend>Chart Close Mode</legend>
    <div class="ets-field">
      <dx-check-box text="Previous Close"
                    [value]="control.useChartCloseModePrevious"
                    (valueChange)="control.useChartCloseModePrevious = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Current Close"
                    [value]="control.useChartCloseModeCurrent"
                    (valueChange)="control.useChartCloseModeCurrent = $event"
      ></dx-check-box>
    </div>
  </fieldset>
  

  <div class="ets-field">
    <div class="ets-field-label">Mode</div>
    <div class="ets-field-value">
        <dx-select-box [items]="control.modeItems"
                       [displayExpr]="'text'"
                       [valueExpr]="'value'"
                       [(value)]="control.mode"
        ></dx-select-box>
    </div>
  </div>
  
  <fieldset>
    <legend>Disaster Prices</legend>
    <div class="ets-field">
      <div class="ets-field-label">"Bull" Disaster Price</div>
      <div class="ets-field-value">
          <dx-number-box  [step]="control.symbol?.tickSize" 
                          [min]="control.symbol?.tickSize" 
                          [showClearButton]="true" 
                          [showSpinButtons]="true"
                          [(value)]="control.disasterPriceBull"
                          [disabled]="!control.canSetDisasterPriceBull"
          ></dx-number-box>
      </div>
    </div>
    <div class="ets-field">
      <div class="ets-field-label">"Bear" Disaster Price</div>
      <div class="ets-field-value">
          <dx-number-box  [step]="control.symbol?.tickSize" 
                          [min]="control.symbol?.tickSize" 
                          [showClearButton]="true" 
                          [showSpinButtons]="true"
                          [(value)]="control.disasterPriceBear"
                          [disabled]="!control.canSetDisasterPriceBear"
          ></dx-number-box>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <legend>Order Conditions</legend>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Proxy"
                    [value]="control.isTradeThroughProxy"
                    (valueChange)="control.isTradeThroughProxy = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Market"
                    [value]="control.isTradeThroughStop"
                    (valueChange)="control.isTradeThroughStop = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through PT"
                    [value]="control.isTradeThroughMIT"
                    (valueChange)="control.isTradeThroughMIT = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Limits"
                    [value]="control.isTradeThroughLimit"
                    (valueChange)="control.isTradeThroughLimit = $event"
      ></dx-check-box>
    </div>
  </fieldset>

</div>
