export interface OptionContract {
  ticker: string;
  displayName: string;
  bid?: number;
  ask?: number;
  bidSize?: number;
  askSize?: number;
  iv?: number;
  oi?: number;
  intrinsic?: number;
  extrinsic?: number;
  netPosition?: number;
  delta?: number;
  gamma?: number;
  theta?: number;
  vega?: number;
  itm?: boolean;
}


export interface ChainLine {
  strike: number;
  call?: OptionContract;
  put?: OptionContract;
  isCustomAdded?: boolean;
}



export const COLUMNS = [
  'bid',
  'ask',
  'bidSize',
  'askSize',
  'delta',
  'gamma',
  'vega',
  'theta',
  'rho',
  'intrinsic',
  'extrinsic',
  'iv',
  'oi',
  'netPosition',
];
