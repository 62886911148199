import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { MessageBusService } from '../message-bus.service';
import { GuiOrderConfirmationDto } from '../shell-communication/shell-dto-protocol';

interface PromiseReference {
   resolve: () => void;
   reject: () => void;
}

@Injectable({providedIn: 'root'})
export class OrderConfirmationService {
   
   constructor(
        private _toastr: ToastrService
      , private _messageBus: MessageBusService) { }

   private readonly _tokens: {[ix: string]: PromiseReference} = {};
   private _unsubscriber: Subject<void>;

   init(): Promise<void> {

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject();

      this._messageBus.of<GuiOrderConfirmationDto>('GuiOrderConfirmationDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onGuiOrderConfirmationDto(msg.payload));

      return Promise.resolve();
   }

   registerToken(token: string, timeoutMessage: string = null): Promise<void> {
      
      return new Promise<void>( (res, rej) => {

         this._tokens[token] = { resolve: res, reject: rej };

         setTimeout(() => {
            const tokenThere =  token in this._tokens;
            if (tokenThere) {
               delete this._tokens[token];
               rej(timeoutMessage || 'Order Operation Timeout');
            }
         }, 5000);
      });

   }

   private onGuiOrderConfirmationDto(msg: GuiOrderConfirmationDto): void {
      
      if (!isNullOrUndefined(msg.guiToken)) {
         const promiseReference = this._tokens[msg.guiToken];
         if (!isNullOrUndefined(promiseReference)) {
            delete this._tokens[msg.guiToken];
            promiseReference.resolve();
         }
      }
      
      if (msg.isNegative) {
         this._toastr.error(msg.message, msg.title);
      } else {
         this._toastr.info(msg.message, msg.title);
      }

   }
}
