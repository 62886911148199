import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { isTruthy } from 'projects/shared-components/utils';
import { EditStrategyDialogComponent } from '../../../edit-strategy-dialog.component';
import { ParametersViewBase } from '../../parameters-view-base';
import { FreezeHedgeAlgoParametersControl } from './freeze-hedge-algo-parameters-control';

@Component({
   selector: 'ets-freeze-hedge-parameters-view',
   templateUrl: './freeze-hedge-parameters-view.component.html',
   styleUrls: ['./freeze-hedge-parameters-view.component.scss']
})
export class FreezeHedgeParametersViewComponent extends ParametersViewBase<FreezeHedgeAlgoParametersControl> {
   
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;
   
   onEtsInit() { }
   
   onEtsAfterViewInit() { }

}
