import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetAvailableBuckets, BucketItemRelocateType as BucketItemRelocateType, RelocateSplitOptions, BucketItemTransferSpec, BucketItemTransferType, BucketSpec, TransferBucketItem } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined, isTruthy } from 'projects/shared-components/utils';
import { MoveToPortfolioDialogConfig, PortfolioEntityType, TransferStrategyCode } from './move-to-portfolio-dialog.model';

interface PopupSettings {
   title?: string;
   width?: number | string;
   height?: number | string;
   resize?: boolean;
   dragEnabled?: boolean;
   isVisible?: boolean;
}

interface ActionButton {
   type?: 'danger' | 'default' | 'success',
   width?: number;
   text?: string;
}

@Component({
   selector: 'ets-move-to-portfolio-dialog',
   templateUrl: './move-to-portfolio-dialog.component.html',
   styleUrls: ['./move-to-portfolio-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class MoveToPortfolioDialogComponent implements OnInit, AfterViewInit {

   constructor(
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef,
      private _sessionService: SessionService) { }

   private _availableBuckets: GetAvailableBucketsReply;

   popupSettings: PopupSettings = {};

   //

   actionButton: ActionButton = {};

   //

   config: Partial<MoveToPortfolioDialogConfig> = {};

   //

   terminalList: TerminalDto[] = [];

   //

   private _selectedTerminal: TerminalDto;
   get selectedTerminal(): TerminalDto { return this._selectedTerminal; }

   @DetectSetterChanges()
   set selectedTerminal(v: TerminalDto) {
      this._selectedTerminal = v;
   }

   //

   private _selectedPortfolio: PortfolioDto;
   get selectedPortfolio(): PortfolioDto { return this._selectedPortfolio; }

   @DetectSetterChanges()
   set selectedPortfolio(v: PortfolioDto) {
      this._selectedPortfolio = v;
   }

   //

   portfolioList: PortfolioDto[] = [];

   //

   comboList: ComboDto[] = [];

   //

   comboGroupList: ComboGroupDto[] = [];

   //

   transferStrategyList: TransferStrategyCode[];

   //

   selectedTransferStrategy: TransferStrategyCode;

   //

   overridePx: number;

   //

   overrideQx: number;

   //

   private _isLoading = false;
   get isLoading(): boolean { return this._isLoading; }

   @DetectSetterChanges()
   set isLoading(value: boolean) { this._isLoading = value; }

   //

   private _selectedCombo: ComboDto;
   get selectedCombo(): ComboDto { return this._selectedCombo; }

   @DetectSetterChanges()
   set selectedCombo(v: ComboDto) {
      this._selectedCombo = v;
   }

   //

   private _selectedComboGroup: ComboGroupDto;
   get selectedComboGroup(): ComboGroupDto { return this._selectedComboGroup; }

   @DetectSetterChanges()
   set selectedComboGroup(v: ComboGroupDto) {
      this._selectedComboGroup = v;
   }

   //

   get blockOverrideControls(): boolean {
      return this.config.itemType === PortfolioEntityType.Strategy
         || this.selectedTransferStrategy !== 'Split';
   }

   //

   ngOnInit() { }

   //

   ngAfterViewInit() { this._changeDetector.detach(); }

   //

   @DetectMethodChanges({ isAsync: true })
   async show(config: MoveToPortfolioDialogConfig) {

      this.popupSettings = {
         dragEnabled: false,
         title: 'Move To Portfolio',
         resize: false,
         width: 300,
         height: 300,
         isVisible: true
      };

      this.actionButton = {
         text: 'OK',
         width: 100,
         type: 'default'
      };

      try {

         const qryPortfolios = new GetAvailableBuckets();
         this._availableBuckets = await this._shellClient.processQuery<GetAvailableBucketsReply>(qryPortfolios);

         this.terminalList = this._sessionService
            .loginResult
            .availableTerminals
            .filter(x => !x.isProxy)
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .slice();

         this._availableBuckets.portfolios = this._availableBuckets
            .portfolios
            .sort( (a, b) => a.portfolioName.localeCompare(b.portfolioName) );

         this.config = config;

         this.transferStrategyList = config.itemType === PortfolioEntityType.Strategy
            ? ['With P&L', 'Without P&L']
            : ['With P&L', 'Without P&L', 'Split'];

      } finally {

         this.isLoading = false;

      }

   }

   //

   @DetectMethodChanges({ isAsync: true })
   async onActionButtonClicked() {

      const cmd = this.makeTransferCommand();

      const errors = this.validateCommandIsCorrect(cmd);

      if (errors.length > 0) {
         
         errors.forEach(x => this._toastr.error(x));
         
         return;
      }

      try {

         await this.transferToPortfolio(cmd);

      } catch (e) {

         console.error(e);

         this._toastr.error('"Move To Portfolio" command completed with error');

      } finally {

         this.popupSettings.isVisible = false;

      }

   }

   //

   private makeTransferCommand(): TransferBucketItem {
      const transferSpec: BucketItemTransferSpec = {
         transferType: BucketItemTransferType.Relocate
      };


      // Target Bucket Spec
      const targetBucketSpec: BucketSpec = {};

      if (this.selectedTerminal) {
         targetBucketSpec.terminalId = this.selectedTerminal.terminalId;
      }

      if (this.selectedPortfolio) {
         targetBucketSpec.portfolioId = this.selectedPortfolio.portfolioId;
      }

      if (this.selectedCombo) {
         if (this.selectedCombo.comboId === EtsConstants.emptyGuid) {
            targetBucketSpec.newComboName = this.selectedCombo.comboName;
         } else {
            targetBucketSpec.comboId = this.selectedCombo.comboId;
         }
      }

      if (this.selectedComboGroup) {
         if (this.selectedComboGroup.comboGroupId === EtsConstants.emptyGuid) {
            targetBucketSpec.newComboGroupName = this.selectedComboGroup.comboGroupName;
         } else {
            targetBucketSpec.comboGroupId = this.selectedComboGroup.comboGroupId;
         }
      }

      transferSpec.destinationBucketSpec = targetBucketSpec;

      // Relocate Spec
      const relocateType = this.getRelocateType();

      transferSpec.relocateSpec = { relocateType };

      if (relocateType === BucketItemRelocateType.Split) {

         const splitOptions: RelocateSplitOptions = {
            price: this.overridePx,
            qty: this.overrideQx
         };

         transferSpec.relocateSpec.splitOptions = splitOptions;
      }


      const cmd = new TransferBucketItem(this.config.itemId, transferSpec);

      return cmd;
   }

   //

   @DetectMethodChanges()
   onHidden() {
      this.popupSettings = {};

      this.config = {};

      this.overrideQx = null;
      this.overridePx = null;

      this.selectedTransferStrategy = null;
      this.transferStrategyList = [];

      this.selectedTerminal = null;
      this.selectedPortfolio = null;
      this.selectedCombo = null;
      this.selectedComboGroup = null;
   }

   //

   @DetectMethodChanges()
   onTransferStrategyChanged(event: { value: TransferStrategyCode }) {

      if (event.value === 'Split') {

         this.overridePx = this.config.itemAvgPx;
         this.overrideQx = this.config.itemNetPosition;

         if (this.popupSettings) {
            if (this.popupSettings.height) {
               this.popupSettings.height = 335;
            }
         }

      } else {

         this.overrideQx = null;
         this.overridePx = null;

         if (this.popupSettings) {
            if (this.popupSettings.height) {
               this.popupSettings.height = 300;
            }
         }
      }
   }

   //

   @DetectMethodChanges()
   onTerminalSelected(args) {
      
      this.selectedPortfolio = null;
      this.selectedCombo = null;
      this.selectedComboGroup = null;

      this.portfolioList = [];
      this.comboList = [];
      this.comboGroupList = [];

      if (!this.selectedTerminal) {
         return;
      }

      const portfolios = this._availableBuckets.portfolios.filter(X => X.terminalId === this.selectedTerminal.terminalId);
      this.portfolioList = portfolios;
   }

   //

   @DetectMethodChanges()
   onPortfolioSelected(args) {

      this.comboList = [];
      this.comboGroupList = [];
      this.selectedCombo = null;
      this.selectedComboGroup = null;

      if (!this.selectedPortfolio) {
         return;
      }

      const portfolioCombos = this._availableBuckets.combos.filter(c => c.portfolioId === this.selectedPortfolio.portfolioId);
      this.comboList = portfolioCombos;
   }

   //

   @DetectMethodChanges()
   onComboSelected(args) {

      this.comboGroupList = [];
      this.selectedComboGroup = null;

      if (!this.selectedCombo) {
         return;
      }

      const comboGroups = this._availableBuckets.comboGroups.filter(c => c.comboId === this.selectedCombo.comboId);
      this.comboGroupList = comboGroups;
   }

   //

   private validateCommandIsCorrect(cmd: TransferBucketItem): string[] {
      
      const errors: string[] = [];

      if (isNullOrUndefined(cmd.itemId)) {
         errors.push('Item Id is necessary');
      }

      if (isNullOrUndefined(cmd.transferSpec)) {
         errors.push('Transfer specification is necessary');
      }


      switch (cmd.transferSpec.transferType) {
         case BucketItemTransferType.Unknown:
         case BucketItemTransferType.Include:
            errors.push('Bad "Trasnfer Type" parameter');
            break;

         case BucketItemTransferType.Relocate:
            const error = this.validateRelocateTransferSpec(cmd.transferSpec);
            if (!isNullOrUndefined(error)) {
               errors.push(error);
            }
            break;

         case BucketItemTransferType.Archive:
         case BucketItemTransferType.Exclude:
         default:
            break;
      }

      if (!isTruthy(cmd.transferSpec.relocateSpec.relocateType)) {
         
         errors.push('Transfer strategy not set');

      } else if (cmd.transferSpec.relocateSpec.relocateType == BucketItemRelocateType.Split) {
         
         if (!cmd.transferSpec.relocateSpec.splitOptions) {
            
            errors.push('Split options not set');

         } else  {
            
            const splitPrice = cmd.transferSpec.relocateSpec.splitOptions.price;
            if (!splitPrice || splitPrice < 0) {
               errors.push('Split price not set');
            }

            const splitQty = cmd.transferSpec.relocateSpec.splitOptions.qty;
            if (!splitQty || splitQty < 0) {
               errors.push('Split quantity not set');
            }
         }
      }

      const destinationBucketSpec = cmd.transferSpec.destinationBucketSpec;

      console.assert(!isNullOrUndefined(destinationBucketSpec), 'destinationBucketSpec != null');

      const bucketErrors = this.validateDestinationBucket(destinationBucketSpec);
      
      if (bucketErrors.length > 0) {
         errors.push(...bucketErrors);
      }

      return errors;
   }

   //
   
   private validateDestinationBucket(destinationBucketSpec: BucketSpec): string[] {
      const errors: string[] = [];
      
      const sourceTerminalId = this.config.itemTerminalId || null;
      const sourcePortfolioId = this.config.itemPortfolioId || null;
      const sourceComboId = this.config.itemComboId || null;
      const sourceComboGroupId = this.config.itemComboGroupId || null;

      const destTerminalId = destinationBucketSpec.terminalId || null;
      const destPortfolioId = destinationBucketSpec.portfolioId || null;
      const destComboId = destinationBucketSpec.comboId || null;
      const destComboGroupId = destinationBucketSpec.comboGroupId || null;

      if (sourceTerminalId == destTerminalId) {
         if (sourcePortfolioId == destPortfolioId) {
            if (sourceComboId == destComboId) {
               if (sourceComboGroupId == destComboGroupId) {
                  errors.push('Destination bucket is same as source');
               }
            }
         }
      }

      return errors;
   }

   //

   private validateRelocateTransferSpec(transferSpec: BucketItemTransferSpec): string {
      const targetBucket = transferSpec.destinationBucketSpec;

      if (isNullOrUndefined(targetBucket)) {
         return 'Target Bucket Specification is mandatory';
      }

      if (isNullOrUndefined(targetBucket.terminalId)) {
         return 'Terminal Net Specified';
      }

      // if (isNullOrUndefined(targetBucket.portfolioId) && isNullOrUndefined(targetBucket.newPortfolioName)) {
      //    return 'Incorrect Target Portfolio';
      // }

      // if (isNullOrUndefined(transferSpec.relocateSpec)) {
      //    return 'Relocated Specification is necessary';
      // }

      // if (transferSpec.relocateSpec.relocateType === PortfolioItemRelocateType.Unknown) {
      //    return 'Relocate Type is not provided';
      // }

      // if (transferSpec.relocateSpec.relocateType === PortfolioItemRelocateType.Split) {
      //    if (isNullOrUndefined(transferSpec.relocateSpec.splitOptions)) {
      //       return 'Split Options Not Provided';
      //    }
      // }
   }

   //

   private transferToPortfolio(cmd: TransferBucketItem): Promise<void> {

      return this._shellClient.processCommand(cmd);
   }

   //

   private getRelocateType(): BucketItemRelocateType {

      switch (this.selectedTransferStrategy) {
         case 'With P&L':
            return BucketItemRelocateType.WithPnL;

         case 'Without P&L':
            return BucketItemRelocateType.WithoutPnL;

         case 'Split':
            return BucketItemRelocateType.Split;

         default:
            break;
      }
   }
}
