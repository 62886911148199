import { StrategySessionHistoryComponent } from './strategy-session-history.component';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate } from '@angular/common';
import { InformationCategory } from '../information-category.enum';
import { centeredColumnDef, defaultTimestampFormat, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { StrategyLogMessageDto } from 'projects/shared-components/shell-communication/dtos/strategy-log-message-dto.class';
import { TimestampsService } from '../timestamps.service';

export function getMessagesGridModel(
  this: StrategySessionHistoryComponent,
  timestampsService: TimestampsService
): GridOptions {
  return  {
    rowData: [],

    defaultColDef: centeredColumnDef,

    columnDefs: [
      {
        headerName: 'Timestamp',
        field: 'timestamp',
        valueFormatter: (params: ValueFormatterParams) => {
          const message: StrategyLogMessageDto = params.data;
          const frmtTime = timestampsService.getFormattedDateTimeForStrategy(message.strategyId, message.timestamp);
          return message.timestamp
            ? frmtTime
            : '';
        }
      },
      {
        headerName: 'Category',
        field: 'category',
        valueFormatter: (params: ValueFormatterParams) => {
          return InformationCategory[params.value];
        },
        filter: true
      },
      {
        headerName: 'Message',
        field: 'messageText',
        headerComponentParams: {},
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter'
      }
    ],

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    onGridReady: args => this.onMessagesGridReady(args),

    getRowNodeId: (rowData: StrategyLogMessageDto) => rowData.messageId,

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [
        {
          name: 'Load More ...',
          disabled: !this.selectedStrategy,
          subMenu: [
            {
              name: '25',
              action: () => this.loadMoreTradingData(25, 'messages')
            },
            {
              name: '50',
              action: () => this.loadMoreTradingData(50, 'messages')
            },
            {
              name: '100',
              action: () => this.loadMoreTradingData(100, 'messages')
            }
          ]
        },
        {
          name: 'Toggle Full Size',
          action: () => this.toggleSectionFullWidth('tradingData')
        },
        'separator',
        'autoSizeAll',
        'copy',
        'export'
      ];
    }
  } as GridOptions;
}
