<div [class.ets-hidden]="!this.isActive" class="panel-container">

   <as-split class="split" [disabled]="this.linkedContext === 'Future'"  direction="vertical" [gutterSize]="5" (dragEnd)="onSplitSizeChanged($event)">
      
      <as-split-area order="0" [size]=" this.linkedContext !== 'Future' ? sectionSizes.top : 100">
         <div class="grid-container">
            <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="ordersGridModel"></ag-grid-angular>
         </div>
      </as-split-area>

      <as-split-area order="1" [size]=" this.linkedContext !== 'Future' ? sectionSizes.bottom : 0">
         <div class="grid-container bottom-area">
            <ag-grid-angular [class.ets-hidden]="selectedTabIndex !== 0" class="ag-theme-balham-dark"
               [gridOptions]="tradesGridModel"></ag-grid-angular>
            <ag-grid-angular [class.ets-hidden]="selectedTabIndex !== 1" class="ag-theme-balham-dark"
               [gridOptions]="snapshotsGridModel"></ag-grid-angular>
         </div>
         <dx-tabs [class.hidden]="this.linkedContext === 'Future'" [dataSource]="tabs" [selectedIndex]="selectedTabIndex" (onItemClick)="selectTab($event)" class="tabs">
         </dx-tabs>
      </as-split-area>

   </as-split>

   <ets-order-replace-dialog></ets-order-replace-dialog>

   <ets-move-order-to-portfolio-dialog></ets-move-order-to-portfolio-dialog>

</div>

<!-- Order Comment -->
<dx-popup width="550" height="350" [showTitle]="true" title='Order Comment' [closeOnOutsideClick]="true"
   [resizeEnabled]="true" [dragEnabled]="true" (onHidden)="isCommentPopupVisible = false"
   [visible]="isCommentPopupVisible">
   <div *dxTemplate="let data of 'content'" id="session_message_details">
      <div id="popup_container">
         <p>{{selectedOrderOrSnapshot?.comment}}</p>
      </div>
   </div>
</dx-popup>

<!-- Future / Convert To Market -->
<dx-popup width="405" [height]="this.getConvertDialogHeight()" [showTitle]="true" title="Convert To Market" 
         [resizeEnabled]="true" [dragEnabled]="true" [visible]="this.convertToMarketDialog.isVisible"
         (onHidden)="this.convertToMarketDialog_onHidden()">
         
   <div *dxTemplate="let data of 'content'" class="convert-container">
      
      <dx-box [direction]="'col'" height="100%">

         <dxi-item [ratio]="1">
           
            <ets-convert-to-market (parameterChanged)="this.onChange()" [settings]="this.convertToMarketDialog.settings"></ets-convert-to-market>

         </dxi-item>
         
         <dxi-item [ratio]="0" [baseSize]="27">

            <div class="buttons">
               <div class="btn cancel">
                  <dx-button width="100%" text="Cancel" (onClick)="this.convertToMarketDialog_onCancel()"></dx-button>
               </div>
               <div class="btn ok">
                  <dx-button type="success" width="100%" text="Send" (onClick)="this.convertToMarketDialog_onSend()"></dx-button>
               </div>
            </div>
      
         </dxi-item>

      </dx-box>

   </div>
</dx-popup>

