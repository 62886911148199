<div class="root-container">
  <dx-scroll-view>
    <div class="portfolio-selector">
      <ets-hg-portfolio-selector
        (portfolioSelected)="this.onPortfolioSelected($event)"
      ></ets-hg-portfolio-selector>
    </div>
    
    <div class="hedge-positions">
      <ets-hg-hedge-positions>
      </ets-hg-hedge-positions>
    </div>
    
    <div class="portfolio-positions">
      <ets-hg-portfolio-positions>
      </ets-hg-portfolio-positions>
    </div>
  </dx-scroll-view>
</div>
