import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {TradingInstrument} from "../../../trading-instruments/trading-instrument.class";
import {ResourceButton} from "../../resource-button.interface";
import {DetectMethodChanges, DetectSetterChanges, isVoid} from "../../../utils";
import {TradingInstrumentsService} from "../../../trading-instruments/trading-instruments-service.interface";
import {ResourceEditorComponent} from "../../resource-editor.component";
import {SymbolPickerComponent} from "../../../symbol-picker/symbol-picker.component";

@Component({
    selector: 'ets-asset-condition-resource',
    template: `
      <div>
        <p class="label">
          Asset
        </p>
        <p class="input">
          <ets-symbol-picker
            [showLastPrice]="false"
            (instrumentSelected)="this.onSelected($event)"
            [disabled]="this.disabled"
            [showClearButton]="true"
          >
          </ets-symbol-picker>
        </p>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AssetConditionResourceComponent implements OnInit, AfterViewInit {

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _tiService: TradingInstrumentsService
    ) {
    }

    @ViewChild(SymbolPickerComponent, {static: true}) symbolPicker: SymbolPickerComponent;

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._changeDetector.detach();
    }

    private _comp: ResourceEditorComponent;
    get comp(): ResourceEditorComponent {
        return this._comp;
    }

    @Input()
    set comp(value: ResourceEditorComponent) {
        this._comp = value;
    }

    get disabled(): boolean {
        return isVoid(this.resource);
    }

    private _resource: ResourceButton;
    get resource(): ResourceButton {
        return this._resource;
    }

    @Input()
    @DetectSetterChanges()
    set resource(value: ResourceButton) {

        this.symbolPicker.selectedInstrument = undefined;

        this._resource = value;

        if (isVoid(this._resource)) {
            return;
        }

        if (isVoid(this._resource.conditionAsset)) {
            return;
        }

        const ti = this._tiService.getInstrumentByTicker(this._resource.conditionAsset);

        this.symbolPicker.selectedInstrument = ti;
    }

    @DetectMethodChanges({isAsync: true})
    async onSelected($event: TradingInstrument) {

        if (isVoid(this.resource)) {
            return;
        }

        if (isVoid($event)) {
            this.resource.conditionAsset = undefined;
        } else {
            this.resource.conditionAsset = $event.ticker;
        }

        this.resource.hasChanges = true;

        await this._comp.onChange({});
    }
}