import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketTimeComponent } from './market-time.component';

@NgModule({
   declarations: [MarketTimeComponent],
   imports: [ CommonModule ],
   exports: [MarketTimeComponent],
   providers: [],
})
export class MarketTimeModule {}