import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {PanelBaseComponent} from 'projects/shared-components/panels/panel-base.component';
import {AdjustmentPricingGridComponent, ApgGridColumn} from '../adjustment-pricing-grid.component';
import {DetectMethodChanges, isVoid} from "../../utils";
import {SettingsStorageService} from "../../settings-storage-service.service";
import {MessageBusService} from "../../message-bus.service";
import {ToastrService} from "ngx-toastr";
import {UserSettingsService} from "../../user-settings.service";

@Component({
    selector: 'ets-adjustment-comparison-grid-panel',
    template: `
      <div [ngClass]="{'ets-hidden': !this.isActive}" [id]="this.panelId" class="panel-container">
        <dx-scroll-view direction="horizontal">
          <div class="root" [style.width.vw]="this.rootWidth" [style.height.px]="this.rootHeight">
            <div [ngClass]="this.leftGridCssClass">
              <ets-adjustment-pricing-grid #leftGrid orientation="left">
              </ets-adjustment-pricing-grid>
            </div>
            <div [ngClass]="this.rightGridCssClass">
              <ets-adjustment-pricing-grid #rightGrid orientation="right">
              </ets-adjustment-pricing-grid>
            </div>
          </div>
        </dx-scroll-view>
      </div>
    `,
    styles: [`
        .panel-container {
            width: 100%;
            height: 100%;
        }

        .root {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;
            min-width: 100vw;
            max-width: 200vw;
            column-gap: 10px;
        }

        .root .column {
            height: 100%;
            min-width: 33.1%;
            max-width: 50%;
            flex: 1;
        }

        .root .column.full {
            flex: 1;
        }
    `]
})
export class AdjustmentComparisonGridPanelComponent extends PanelBaseComponent {

    constructor(changeDetector: ChangeDetectorRef, userSettingsService: UserSettingsService, messageBus: MessageBusService,
                private readonly _toastr: ToastrService) {
        super(changeDetector, userSettingsService, messageBus);
    }

    @ViewChild('leftGrid') leftGrid: AdjustmentPricingGridComponent;

    @ViewChild('rightGrid') rightGrid: AdjustmentPricingGridComponent;

    get leftGridCssClass(): string[] {
        const css = ['left', 'column'];
        if (this.leftGrid && !this.leftGrid.fullyCollapsed) {
            css.push('full');
        }
        return css;
    }

    get rightGridCssClass(): string[] {
        const css = ['right', 'column'];
        if (this.rightGrid && !this.rightGrid.fullyCollapsed) {
            css.push('full');
        }
        return css;
    }

    rootHeight: number

    private _rootWidth = 100;

    get rootWidth() {
        return this._rootWidth;// + '%';
    }

    etsOnInit(): void {
    }

    etsOnDestroy(): void {
    }

    etsAfterViewInit(): void {
        this.leftGrid.sectionCollapsed$.subscribe(x => {
            this.onSectionCollapsed(x, 'left');
        });
        this.rightGrid.sectionCollapsed$.subscribe(x => {
            this.onSectionCollapsed(x, 'right');
        });

        const observer = new ResizeObserver(entries => {
            if (isVoid(entries)) {
                return;
            }
            const resizeObserverEntry = entries[0];

            const height = resizeObserverEntry.contentRect.height;

            this.rootHeight = height;

            this._changeDetector.detectChanges();
        });

        const elementById = document.getElementById(this.panelId);
        observer.observe(elementById);
    }

    @DetectMethodChanges()
    onSectionCollapsed(args: {section: ApgGridColumn, state: boolean}, grid: 'left' | 'right') {

        let leftCount = 0;

        if (!this.leftGrid.strategiesColCollapsed) {
            leftCount++;
        }

        if (!this.leftGrid.priceboxColCollapsed) {
            leftCount++;
        }

        if (!this.leftGrid.zonesGridColCollapsed) {
            leftCount++;
        }

        let rightCount = 0;

        if (!this.rightGrid.strategiesColCollapsed) {
            rightCount++;
        }

        if (!this.rightGrid.priceboxColCollapsed) {
            rightCount++;
        }

        if (!this.rightGrid.zonesGridColCollapsed) {
            rightCount++;
        }

        const maxPanelsOpen = Math.max(rightCount, leftCount);

        // let adj = 0;
        // if (maxPanelsOpen === 1) {
        //     adj  = 70;
        //     if (!this.rightGrid.zonesGridColCollapsed || !this.leftGrid.zonesGridColCollapsed) {
        //         adj = 60;
        //     }
        // } else if (maxPanelsOpen === 2) {
        //     adj = 110;
        // } else if (maxPanelsOpen === 3) {
        //     adj = 150;
        // }

        if (maxPanelsOpen > 0) {
            this._rootWidth = 190;
        } else {
            this._rootWidth= 100;
        }
    }

    protected getState() {
        return null;
    }

    protected setState(state: any) {
        //
    }
}
