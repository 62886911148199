<dx-popup
  width="40%"
  height="35%"
  [showTitle]="true"
  title="Advanced Schedule"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let data of 'content'">
    <div class="schedule">
      <fieldset>
        <legend>Strategy Schedule</legend>
        <div class="time-area">
          <div class="country">
            <dx-text-box [disabled]="true"
                         [value]="parameters?.country"
            ></dx-text-box>
          </div>
          <div class="timezone">
            <dx-text-box [disabled]="true"
                         [value]="parameters?.timeZone"
            ></dx-text-box>
          </div>
        </div>
        <div class="sse">
          <div class="start sse-item first">
            <dx-text-box [value]="parameters?.startTime"
                         [disabled]="true"
            ></dx-text-box>
          </div>
          <div class="stop sse-item">
            <dx-text-box [disabled]="true"
                         [value]="parameters?.stopTime"
            ></dx-text-box>
          </div>
          <div class="exit sse-item last">
            <dx-text-box [disabled]="true"
                         [value]="parameters?.exitTime"
            ></dx-text-box>
          </div>
        </div>
        <div class="misc">
          <dx-check-box text="Autostart"
                        [disabled]="true"
                        [value]="parameters?.isAutostart"
          ></dx-check-box>
        </div>
      </fieldset>
    </div>
    <div class="advanced-schedule">
      <div class="dates">
        <div class="date-box begin">
          <dx-date-box width="100%"
                       placeholder="Begin Date"
                       [showClearButton]="true"
                       [(value)]="parameters.beginDate"
          ></dx-date-box>
        </div>
        <div class="date-box end">
          <dx-date-box width="100%"
                       placeholder="End Date"
                       [showClearButton]="true"
                       [(value)]="parameters.endDate"
          ></dx-date-box>
        </div>
      </div>
      <div class="recurring">
        <fieldset>
          <legend>
            <dx-check-box #recur
                          text="Recurring (repeat on days)"
                          [(value)]="parameters.isRecurring"
            ></dx-check-box>
          </legend>
          <ul>
            <li>
              <dx-check-box text="Mon"
                            #mon
                            [disabled]="!recur.value"
                            (disabledChange)=" $event ? mon.value = false : null"
                            [value]="parameters?.mon"
                            (valueChange)="this.onWeekdayChanged($event, 'mon')"
              ></dx-check-box>
            </li>
            <li>
              <dx-check-box text="Tue"
                            #tue
                            [disabled]="!recur.value"
                            (disabledChange)=" $event ? tue.value = false : null"
                            [value]="parameters?.tue"
                            (valueChange)="this.onWeekdayChanged($event, 'tue')"
              ></dx-check-box>
            </li>
            <li>
              <dx-check-box text="Wed"
                            #wed
                            [disabled]="!recur.value"
                            (disabledChange)=" $event ? wed.value = false : null"
                            [value]="parameters?.wed"
                            (valueChange)="this.onWeekdayChanged($event, 'wed')"
              ></dx-check-box>
            </li>
            <li>
              <dx-check-box text="Thu"
                            #thu
                            [disabled]="!recur.value"
                            (disabledChange)=" $event ? thu.value = false : null"
                            [value]="parameters?.thu"
                            (valueChange)="this.onWeekdayChanged($event, 'thu')"
              ></dx-check-box>
            </li>
            <li>
              <dx-check-box text="Fri"
                            #fri
                            [disabled]="!recur.value"
                            (disabledChange)=" $event ? fri.value = false : null"
                            [value]="parameters?.fri"
                            (valueChange)="this.onWeekdayChanged($event, 'fri')"
              ></dx-check-box>
            </li>
          </ul>
        </fieldset>
      </div>
    </div>
    <div class="buttons">
      <dx-button text="Cancel" (onClick)="onCancelClicked()"></dx-button>
      <dx-button text="Save" (onClick)="onSaveClicked()"></dx-button>
    </div>
  </div>
</dx-popup>
