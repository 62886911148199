import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';

/**
 * @returns RiskManagerRuleDto[]
 */
export class GetRiskManagerRules implements ProtocolQuery {
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetRiskManagerRules`;
  }
}
