import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetAvailableBuckets, BucketItemRelocateType as BucketItemRelocateType, RelocateSplitOptions, BucketItemTransferSpec, BucketItemTransferType, BucketSpec, TransferBucketItem, MoveOrderToBucket, TransferOrderSpec } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined, isTruthy } from 'projects/shared-components/utils';
import { MoveOrderToPortfolioDialogConfig } from './move-order-to-portfolio-dialog.model';

interface PopupSettings {
   title?: string;
   width?: number | string;
   height?: number | string;
   resize?: boolean;
   dragEnabled?: boolean;
   isVisible?: boolean;
}

interface ActionButton {
   type?: 'danger' | 'default' | 'success';
   width?: number;
   text?: string;
}

@Component({
   selector: 'ets-move-order-to-portfolio-dialog',
   templateUrl: './move-order-to-portfolio-dialog.component.html',
   styleUrls: ['./move-order-to-portfolio-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class MoveOrderToPortfolioDialogComponent implements OnInit, AfterViewInit {

   constructor(
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef,
      private _sessionService: SessionService) { }

   private _availableBuckets: GetAvailableBucketsReply;

   popupSettings: PopupSettings = {};

   //

   actionButton: ActionButton = {};

   //

   config: Partial<MoveOrderToPortfolioDialogConfig> = {};

   //

   terminalList: TerminalDto[] = [];

   //

   private _selectedTerminal: TerminalDto;
   get selectedTerminal(): TerminalDto { return this._selectedTerminal; }

   @DetectSetterChanges()
   set selectedTerminal(v: TerminalDto) {
      this._selectedTerminal = v;
   }

   //

   private _selectedPortfolio: PortfolioDto;
   get selectedPortfolio(): PortfolioDto { return this._selectedPortfolio; }

   @DetectSetterChanges()
   set selectedPortfolio(v: PortfolioDto) {
      this._selectedPortfolio = v;
   }

   //

   portfolioList: PortfolioDto[] = [];

   //

   comboList: ComboDto[] = [];

   //

   comboGroupList: ComboGroupDto[] = [];

   //

   private _isLoading = false;
   get isLoading(): boolean { return this._isLoading; }

   @DetectSetterChanges()
   set isLoading(value: boolean) { this._isLoading = value; }

   //

   private _selectedCombo: ComboDto;
   get selectedCombo(): ComboDto { return this._selectedCombo; }

   @DetectSetterChanges()
   set selectedCombo(v: ComboDto) {
      this._selectedCombo = v;
   }

   //

   private _selectedComboGroup: ComboGroupDto;
   get selectedComboGroup(): ComboGroupDto { return this._selectedComboGroup; }

   @DetectSetterChanges()
   set selectedComboGroup(v: ComboGroupDto) {
      this._selectedComboGroup = v;
   }

   //

   ngOnInit() { }

   //

   ngAfterViewInit() { this._changeDetector.detach(); }

   //

   @DetectMethodChanges({ isAsync: true })
   async show(config: MoveOrderToPortfolioDialogConfig) {

      this.popupSettings = {
         dragEnabled: false,
         title: 'Move To Portfolio',
         resize: false,
         width: 300,
         height: 300,
         isVisible: true
      };

      this.actionButton = {
         text: 'OK',
         width: 100,
         type: 'default'
      };

      try {

         const qryPortfolios = new GetAvailableBuckets();
         this._availableBuckets = await this._shellClient.processQuery<GetAvailableBucketsReply>(qryPortfolios);

         this.terminalList = this._sessionService
            .loginResult
            .availableTerminals
            .filter(x => !x.isProxy)
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .slice();

         this._availableBuckets.portfolios = this._availableBuckets
            .portfolios
            .sort( (a, b) => a.portfolioName.localeCompare(b.portfolioName) );

         this.config = config;

      } finally {

         this.isLoading = false;

      }

   }

   //

   @DetectMethodChanges({ isAsync: true })
   async onActionButtonClicked() {

      const cmd = this.makeTransferCommand();

      const errors = this.validateCommandIsCorrect(cmd);

      if (errors.length > 0) {
         
         errors.forEach(x => this._toastr.error(x));
         
         return;
      }

      try {

         await this._shellClient.processCommand(cmd);

      } catch (e) {

         console.error(e);

         this._toastr.error('"Move Order To Bucket" command completed with error');

      } finally {

         this.popupSettings.isVisible = false;

      }

   }

   //

   private makeTransferCommand(): MoveOrderToBucket {
      

      // Target Bucket Spec
      const spec: TransferOrderSpec = { itemId: this.config.itemId };

      if (this.selectedTerminal) {
         spec.terminalId = this.selectedTerminal.terminalId;
      }

      if (this.selectedPortfolio) {
         spec.portfolioId = this.selectedPortfolio.portfolioId;
      }

      if (this.selectedCombo) {
         spec.comboId = this.selectedCombo.comboId;
      }

      if (this.selectedComboGroup) {
         spec.comboGroupId = this.selectedComboGroup.comboGroupId;
      }

      const cmd = new MoveOrderToBucket(spec);

      return cmd;
   }

   //

   @DetectMethodChanges()
   onHidden() {
      this.popupSettings = {};

      this.config = {};

      this.selectedTerminal = null;
      this.selectedPortfolio = null;
      this.selectedCombo = null;
      this.selectedComboGroup = null;
   }

   //

   @DetectMethodChanges()
   onTerminalSelected(args) {
      
      this.selectedPortfolio = null;
      this.selectedCombo = null;
      this.selectedComboGroup = null;

      this.portfolioList = [];
      this.comboList = [];
      this.comboGroupList = [];

      if (!this.selectedTerminal) {
         return;
      }

      const portfolios = this._availableBuckets.portfolios.filter(X => X.terminalId === this.selectedTerminal.terminalId);
      this.portfolioList = portfolios;
   }

   //

   @DetectMethodChanges()
   onPortfolioSelected(args) {

      this.comboList = [];
      this.comboGroupList = [];
      this.selectedCombo = null;
      this.selectedComboGroup = null;

      if (!this.selectedPortfolio) {
         return;
      }

      const portfolioCombos = this._availableBuckets.combos.filter(c => c.portfolioId === this.selectedPortfolio.portfolioId);
      this.comboList = portfolioCombos;
   }

   //

   @DetectMethodChanges()
   onComboSelected(args) {

      this.comboGroupList = [];
      this.selectedComboGroup = null;

      if (!this.selectedCombo) {
         return;
      }

      const comboGroups = this._availableBuckets.comboGroups.filter(c => c.comboId === this.selectedCombo.comboId);
      this.comboGroupList = comboGroups;
   }

   //

   private validateCommandIsCorrect(cmd: MoveOrderToBucket): string[] {
      
      const errors: string[] = [];

      if (isNullOrUndefined(cmd.transferSpec)) {
         errors.push('Transfer spec is necessary');
         return;
      }

      const spec = cmd.transferSpec;

      if (isNullOrUndefined(spec.itemId)) {
         errors.push('Order Id is necessary');
      }

      const bucketErrors = this.validateDestinationBucket(spec);
      
      if (bucketErrors.length > 0) {
         errors.push(...bucketErrors);
      }

      return errors;
   }

   //
   
   private validateDestinationBucket(destinationBucketSpec: TransferOrderSpec): string[] {
      
      const errors: string[] = [];
      
      const sourceTerminalId = this.config.itemTerminalId || null;
      const sourcePortfolioId = this.config.itemPortfolioId || null;
      const sourceComboId = this.config.itemComboId || null;
      const sourceComboGroupId = this.config.itemComboGroupId || null;

      const destTerminalId = destinationBucketSpec.terminalId || null;
      const destPortfolioId = destinationBucketSpec.portfolioId || null;
      const destComboId = destinationBucketSpec.comboId || null;
      const destComboGroupId = destinationBucketSpec.comboGroupId || null;

      if (!destTerminalId) {
         errors.push('Destination terminal is required');
      }

      if (sourceTerminalId === destTerminalId) {
         if (sourcePortfolioId === destPortfolioId) {
            if (sourceComboId === destComboId) {
               if (sourceComboGroupId === destComboGroupId) {
                  errors.push('Destination bucket is same as source');
               }
            }
         }
      }

      return errors;
   }
}
