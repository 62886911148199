import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { ShellMessagesComponent as ShellMessagesComponent } from './shell-messages.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';
import { AngularSplitModule } from 'angular-split';
import { SessionMessagesGrouRowInnerRendererComponent as ShellMessagesGrouRowInnerRendererComponent } from './group-row/shell-messages-group-row-renderer.component';
import { ShellMessagesOverlayComponent } from './overlay/shell-messages-overlay.component';
import { ShellMessagesPanelComponent } from './panel/shell-messages-panel.component';
import { EtsDateGridFilterComponent } from './ets-date-filter.component';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { NoHtmlCellRendererComponent } from './no-html-cell-renderer.component';

@NgModule({
   imports: [
      CommonModule,
      AgGridModule.withComponents([
         ShellMessagesGrouRowInnerRendererComponent,
         EtsDateGridFilterComponent
      ]),
      DevExtremeModule,
      AngularSplitModule,
      DateTimePickerModule,
      TimezonePickerModule
   ],
   exports: [
      ShellMessagesComponent, 
      ShellMessagesGrouRowInnerRendererComponent,
      ShellMessagesOverlayComponent,
      ShellMessagesPanelComponent,
      EtsDateGridFilterComponent
   ],
   declarations: [
      ShellMessagesComponent, 
      ShellMessagesGrouRowInnerRendererComponent,
      ShellMessagesOverlayComponent,
      ShellMessagesPanelComponent,
      EtsDateGridFilterComponent,
      NoHtmlCellRendererComponent
   ],
   providers: []
})
export class EtsShellMessagesModule { }
