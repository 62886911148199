<div class="root-container">

  <div class="block positions">
    <table [cellPadding]="0" [cellSpacing]="0" [width]="'100%'">

      <caption>
        <span class="header-text">{{this.header}}</span>
      </caption>

      <thead>
      <tr class="row-2x">
        <th>Qty</th>
        <th>Asset</th>
        <th>Type</th>
        <th>Expiration</th>
        <th>Strike</th>
        <th *ngIf="!this.readOnly">Price</th>
        <th *ngIf="this.showSubTotals">
          Total   <!-- #7 -->
        </th>
      </tr>
      </thead>

      <tbody>

      <tr *ngFor="let position of this.positions; index as i" class="position row-2x">

        <td [ngClass]="this.getQtyClass(position)" class="qty">
          {{ isValidNumber(position.qty) ? position.qty / this.hcf : ''}}
        </td>

        <td [ngClass]="this.getPositionRoleClass(position)" class="underlying">
          {{position?.underlying || '&nbsp;'}}
        </td>

        <td [ngClass]="this.getPositionRoleClass(position)" class="type">
          {{position?.type}}
        </td>

        <td [ngClass]="this.getPositionRoleClass(position)" class="expiration">
          {{position?.expiration}}
        </td>

        <td [ngClass]="this.getPositionRoleClass(position)" class="strike">
          {{position.strike + (this.adjustmentPricing.zonesGridStrikeDelta || 0)}}
        </td>

        <td *ngIf="!this.readOnly" [ngClass]="this.getPriceClass(position)" class="price"
          [class.theo]="this.isTheoPrice(position)">
          {{
            position?.price * Math.abs(position.qty) / this.hcf
                | currency:'USD':'symbol':'1.2-2'
          }}
        </td>

        <td *ngIf="this.showSubTotals"
            [ngClass]="this.getCssClassForTotalPrice('row', i, position)"
            class="total row-2x"
        >
          {{ this.getTotalPrice('row', i, position) | currency:'USD':'symbol':'1.2-2' }}
        </td>
      </tr>

      <!-- Positions Totals -->
      <tr class="row-2x">
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td *ngIf="!this.readOnly"
            [ngClass]="this.getCssClassForTotalPrice('col')"
            class="price total sub"
        >
          {{ this.getTotalPrice('col', undefined, undefined) | currency:'USD':'symbol':'1.2-2'}}
        </td>
        <td *ngIf="this.showSubTotals"
            [ngClass]="this.getCssClassForTotalPrice('rowcol')"
            class="total sub"
        >
          {{ this.getTotalPrice('rowcol', undefined, undefined) | currency:'USD':'symbol':'1.2-2'}}
        </td>
      </tr>

      <!--   Increase / Decrease   -->
      <ng-container *ngIf="this.hasExtras else emptyExtras">

        <!-- Spacer row -->
        <tr>
          <td class="spacer" colspan="7">&nbsp;</td>
        </tr>

        <tr *ngFor="let position of this.extraPositions; index as i" class="position row-2x">

          <td [ngClass]="this.getQtyClass(position)" class="qty">
            {{ isValidNumber(position.qty) ? position.qty / this.extraPositionsHcf : ''}}
          </td>

          <td [ngClass]="this.getPositionRoleClass(position)" class="underlying">
            {{position?.underlying || '&nbsp;'}}
          </td>

          <td [ngClass]="this.getPositionRoleClass(position)" class="type">
            {{position?.type}}
          </td>

          <td [ngClass]="this.getPositionRoleClass(position)" class="expiration">
            {{position?.expiration}}
          </td>

          <td [ngClass]="this.getPositionRoleClass(position)" class="strike">
            {{position?.strike}}
          </td>

          <td *ngIf="!this.readOnly" [ngClass]="this.getPriceClass(position)" class="price">
            {{
              position?.price * Math.abs(position.qty) / this.extraPositionsHcf
                  | currency:'USD':'symbol':'1.2-2'
            }}
          </td>

          <td *ngIf="this.showSubTotals"
              [ngClass]="this.getPriceClass(position)"
              class="total"
          >
            {{
              position?.price * Math.abs(position.qty) / this.extraPositionsHcf
                  | currency:'USD':'symbol':'1.2-2'
            }}
          </td>

        </tr>

        <!-- Positions Totals -->
        <tr class="row-2x">
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td *ngIf="!this.readOnly"
              [ngClass]="this.getCssClassForTotalPrice('extra-col')"
              class="price total sub"
          >
            {{ this.getTotalPrice('extra-col', undefined, undefined) | currency:'USD':'symbol':'1.2-2'}}
          </td>
          <td *ngIf="this.showSubTotals"
              [ngClass]="this.getCssClassForTotalPrice('extra-col')"
              class="total sub"
          >
            {{ this.getTotalPrice('extra-col', undefined, undefined) | currency:'USD':'symbol':'1.2-2'}}
          </td>
        </tr>

      </ng-container>
      <ng-template #emptyExtras>
        <tr>
          <td class="spacer" colspan="7">&nbsp;</td>
        </tr>

        <tr *ngFor="let position of this.extraPositions; index as i" class="position row-2x">
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td *ngIf="!this.readOnly">&nbsp;</td>
          <td *ngIf="this.showSubTotals">&nbsp;</td>
        </tr>

      </ng-template>

      </tbody>

      <tfoot>

      <!-- Asset Total -->
      <tr *ngIf="this.showAssetTotal">
        <td class="asset-name" colspan="6">
          {{ this.assetName }} (Gain/Loss)
        </td>
        <td [ngClass]="this.getAssetPriceClass()" class="total sub">
          {{ this.marketDelta | currency:'USD':'symbol':'1.2-2'}}
        </td>
      </tr>

      <!-- Spacer row -->
      <tr *ngIf="this.showSubTotals">
        <td class="spacer" colspan="7">&nbsp;</td>
      </tr>

      <ng-container *ngIf="this.showExtraTotals">

        <tr class="roll-total row-2x">
          <td [attr.colspan]="this.readOnly ? 4 : 6" class="grand-total-header">
              <span class="formula">
                (
                <span>{{this.rollQty}}</span> x
                <span [ngClass]="this.getCssClassForTotalPrice('rowcol')">
                  {{ this.getTotalPrice('rowcol', undefined, undefined) | currency:'USD':'symbol':'1.2-2'  }}
                </span> )
              </span>
            <span>&nbsp;Roll Total</span>
          </td>
          <td [ngClass]="this.getCssClassForTotalPrice('rowcol')" class="total sub">
            {{this.getTotalPrice('rowcol', undefined, undefined) * this.rollQty | currency:'USD':'symbol':'1.2-2'}}
          </td>
        </tr>

        <tr class="sign">
          <td *ngFor="let i of [1,2,3,4,5,6]"></td>
          <td>+</td>
        </tr>

        <tr class="change-total row-2x">
          <td [attr.colspan]="this.readOnly ? 4 : 6" class="grand-total-header">
              <span class="formula">
                (
                  <span>{{this.changeQty}}</span> x
                  <span [ngClass]="this.getPriceClass({price: this.getExtraTotalPrice()})">
                    {{ this.getExtraTotalPrice() | currency:'USD':'symbol':'1.2-2'}}
                  </span>
                )
              </span>
            <span>&nbsp;{{ this.extraType === 'increase' ? 'Increase' : 'Decrease' }} Total</span>
          </td>
          <td [ngClass]="this.getPriceClass({price: this.getExtraTotalPrice()})" class="total sub">
            {{this.getExtraTotalPrice() * this.changeQty | currency:'USD':'symbol':'1.2-2'}}
          </td>
        </tr>

        <tr class="sign">
          <td *ngFor="let i of [1,2,3,4,5,6]"></td>
          <td>&#247;</td>
        </tr>

        <tr class="contracts row-2x">
          <td [attr.colspan]="this.readOnly ? 4 : 6" class="grand-total-header">Transacted Contracts</td>
          <td class="total sub">
            {{ this.changeQty + this.rollQty }}
          </td>
        </tr>

        <tr class="sign">
          <td *ngFor="let i of [1,2,3,4,5,6]"></td>
          <td>=</td>
        </tr>

      </ng-container>

      <tr *ngIf="this.showSubTotals || this.showGrandTotalInReadOnlyMode" class="row-2x">
        <td [attr.colspan]="this.readOnly ? 4 : 6" class="grand-total-header">Grand Total</td>
        <td [ngClass]="this.getCssClassForTotalPrice('grand')" class="total-cell">
          <div class="total grand">
            {{ this.getTotalPrice('grand', undefined, undefined) | currency:'USD':'symbol':'1.2-2'}}
          </div>
        </td>
      </tr>

      </tfoot>

    </table>
  </div>

</div>
