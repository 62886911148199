import {NgModule} from '@angular/core';
import {OrdersRegroupDialogComponent} from "./orders-regroup-dialog.component";
import {CommonModule} from "@angular/common";
import {DevExtremeModule} from "../devextreme.module";
import {EtsCommonModule} from "../common-services/common.module";


@NgModule({
    imports: [
        CommonModule,
        EtsCommonModule,
        DevExtremeModule
    ],
    exports: [OrdersRegroupDialogComponent],
    declarations: [OrdersRegroupDialogComponent],
    providers: [],
})
export class OrdersRegroupModule {
}
