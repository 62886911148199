
export interface SmartSettings {
   back: number;
   forth: number;
   isEnabled: boolean;
}

export interface IExpirationSmartModeSettings {
   spread: SmartSettings;
   secondSpread: SmartSettings;
   protectiveOption: SmartSettings;
   secondProtectiveOption: SmartSettings;
}

export class ExpirationSmartModeSettings implements IExpirationSmartModeSettings {
   constructor(settings?: IExpirationSmartModeSettings) {
      this.spread = settings ? settings.spread : { isEnabled: true, back: 0, forth: 0 };
      this.secondSpread = settings ? settings.secondSpread : { isEnabled: true, back: 0, forth: 0 };
      this.protectiveOption = settings ? settings.protectiveOption : { isEnabled: true, back: 0, forth: 0 };
      this.secondProtectiveOption = settings ? settings.secondProtectiveOption : { isEnabled: true, back: 0, forth: 0 };
   }
   spread: SmartSettings;
   secondSpread: SmartSettings;
   protectiveOption: SmartSettings;
   secondProtectiveOption: SmartSettings;
}
