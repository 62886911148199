import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, GridReadyEvent, GridSizeChangedEvent } from 'ag-grid-community';
import { GatewayModel } from './gateway-model';
import { getEventsGridModel } from './gateway-manager-events-grid-model';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { GatewayType } from 'projects/shared-components/gateways/gateway-type.enum';
import { GatewayManagerMessageDto } from 'projects/shared-components/shell-communication/dtos/gateway-manager-message-dto.class';
import { takeUntil, filter } from 'rxjs/operators';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { GetAvailableGateways } from 'projects/shared-components/shell-communication/operations/gateways/get-available-gateways.class';
import { GatewayDto } from 'projects/shared-components/shell-communication/dtos/gateway-dto.class';
import { ForceDisconnectGateway } from 'projects/shared-components/shell-communication/operations/gateways/force-disconnect-gateway.class';
import { ForceConnectGateway } from 'projects/shared-components/shell-communication/operations/gateways/force-connect-gateway.class';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';

interface GatewayManagerSecurityContext {
   changeGatewayStatus: boolean;
   viewGatewaysLog: boolean;
}

@Component({
   selector: 'ets-gateway-manager',
   templateUrl: './gateway-manager.component.html',
   styleUrls: ['./gateway-manager.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatewayManagerComponent
   implements OnInit, OnDestroy, AfterViewInit {

   constructor(
      private _messageBus: MessageBusService,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _accessControlService: AccessControlService,
      private _changeDetector: ChangeDetectorRef,
      @Inject('WINDOW') private _window: any,
   ) { }

   private readonly _gateways: GatewayModel[] = [];
   private _isDrawerOpened: boolean;
   private _isOverlayVisible = false;
   private _eventsGrid: GridOptions;
   private _selfEl: HTMLElement;
   private _logger: Logger;
   private _unsubscriber: Subject<any>;
   private _isLoading: boolean;

   securityContext: GatewayManagerSecurityContext;
   overlayPosition: { left: number; bottom: number };
   eventsList: GatewayManagerMessageDto[] = [];
   selectedEvent: GatewayManagerMessageDto;
   actionButtonText = 'Gateways';
   notificationsCount = 0;
   logEventsGridModel: GridOptions;
   isMessagePopupVisible: boolean;
   
   dataGateways: GatewayModel[];
   tradingGateways: GatewayModel[];
   
   get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   set isLoading(value: boolean) {
      if (value === this._isLoading) {
         return;
      }
      this._isLoading = value;
   }

   get drawerHeight(): number {
      return this._isDrawerOpened ? 300 : 28;
   }

   get badgeLeftPosition(): number {
      // best look achieved when it shifted 4px for every decimal rank
      return Math.ceil(this.notificationsCount / 10) * -4;
   }

   get isDrawerOpened(): boolean {
      return this._isDrawerOpened;
   }

   @DetectSetterChanges()
   set isDrawerOpened(value: boolean) {
      this._isDrawerOpened = value;
      if (this._isDrawerOpened) {
         this.notificationsCount = 0;
      }
   }

   get isOverlayVisible(): boolean {
      return this._isOverlayVisible;
   }

   @DetectSetterChanges()
   set isOverlayVisible(value: boolean) {
      this._isOverlayVisible = value;
      if (!value) {
         this.isDrawerOpened = false;
      }
   }


   ngOnDestroy(): void { }

   
   onGridReady(args: GridReadyEvent): void {
      this._eventsGrid = args;
      this._eventsGrid.api.setRowData(this.eventsList);
   }

   
   onGridSizeChanged(args: GridSizeChangedEvent) {

   }

   
   getGatewayId(inedx: number, item: GatewayModel) {
      return item.gatewayId;
   }

   
   async ngOnInit(): Promise<any> {

      const isAvailable = (id) => this._accessControlService
         .isSecureElementAvailable(id);

      this.securityContext = {
         changeGatewayStatus: isAvailable('b895d482-0840-4e88-a739-f75656a57fc1'),
         viewGatewaysLog: isAvailable('ce0f06c5-c113-453b-bf6b-13e62dc197eb')
      };

      this.logEventsGridModel = getEventsGridModel.bind(this)();

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject<any>();

      await this._loadGateways();

      this._messageBus
         .of<GatewayManagerMessageDto>('GatewayManagerMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(message => this.onGatewayManagerMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe( _ => this._loadGateways());

      fromEvent(this._window, 'resize')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(args => this.onWindowResized(args));
   }

   
   @DetectMethodChanges({isAsync: true})
   async onActionButtonClick($event: any): Promise<void> {

      const selfEl = this._selfEl;
      
      if (!selfEl) {
         return;
      }

      if (!selfEl.offsetParent) {
         return;
      }

      if (!this.isOverlayVisible) {
         this.overlayPosition = {
            left: selfEl.offsetLeft,
            bottom: (selfEl.offsetParent as HTMLElement).offsetHeight
         };
      }

      this.isOverlayVisible = !this.isOverlayVisible;

      if (this.isOverlayVisible) {
         await this._loadGateways();
      }
   }

   
   ngAfterViewInit(): void {
      const el: HTMLElement = document.querySelector(
         '#gateway-manager-action-button'
      );
      if (el) {
         this._selfEl = el;
      }
   }

   
   clearEventsList(): void {
      this.eventsList.length = 0;
      this._eventsGrid.api.setRowData([]);
   }


   @DetectMethodChanges()
   private onGatewayManagerMessage(message: GatewayManagerMessageDto): void {
      
      if (message.gatewayId) {
         const ix = this._gateways.findIndex(g => g.gatewayId === message.gatewayId);
         if (ix >= 0) {
            this._gateways[ix].status = message.gatewayStatus;
         }
      }

      this.eventsList.push(message);

      const gridControls = this._eventsGrid;
      
      if (gridControls) {
         gridControls.api.applyTransaction({add: [message] });
      }

      if (!this.isDrawerOpened) {
         if (message.category >= 2) {
            this.notificationsCount++;
         }
      }
   }


   @DetectMethodChanges()
   private onWindowResized(args: any) {
      const el: HTMLElement = this._selfEl;
      
      if (!el) {
         return;
      }
      
      if (!el.offsetParent) {
         return;
      }
      
      this.overlayPosition = {
         left: el.offsetLeft,
         bottom: (el.offsetParent as HTMLElement).offsetHeight
      };

   }

   
   @DetectMethodChanges({isAsync: true})
   private async _loadGateways(): Promise<void> {

      this.isLoading = true;

      this._changeDetector.detectChanges();

      this._gateways.length = 0;

      try {
         const query = new GetAvailableGateways();

         const dtos = await this._shellClient.processQuery<GatewayDto[]>(query);

         const models = dtos.map(
            dto =>
               new GatewayModel(dto, this._shellClient, this._toastr, this._logger)
         );

         this._gateways.push(...models);

         this.dataGateways = this._gateways.filter(
            g =>
               g.gatewayType === GatewayType.Data ||
               g.gatewayType === GatewayType.Combined
         );

         this.tradingGateways = this._gateways.filter(
            g =>
               g.gatewayType === GatewayType.Trading ||
               g.gatewayType === GatewayType.Combined
         );
      } catch (e) {
   
         console.error(e);
         this._toastr.error('"Gateway Manager" dialog loaded with errors');

      } finally {
         
         this.isLoading = false;

      }
   }


   @DetectMethodChanges({isAsync: true})
   async forceGatewayStatus(gatewayId: string, event: any): Promise<void> {
      
      if (!gatewayId) {
         return;
      }

      const action = event.itemData.id;

      const cmd = action === 'd' ? new ForceDisconnectGateway(gatewayId)
         : new ForceConnectGateway(gatewayId);

      try {
         
         this.isLoading = true;
         await this._shellClient.processCommand(cmd);

      } catch (e) {

         const message = `"Force ${action === 'd' ? 'Disconnect' : 'Connect'}" gateway Command Completed with Errors`;
         console.log(e);
         this._toastr.error(message);

      } finally {
         
         this.isLoading = false;

      }

   }
}
