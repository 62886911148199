<div class="component">
   
      <div class="block action-time-mode">
         <dx-select-box [items]="['Convert At', 'Convert After']"
                        [(value)]="this.settings.actionTimeMode"
                        (onValueChanged)="this.onChange()"
                        [showClearButton]="true"
                        placeholder="Select Convert Mode..."></dx-select-box>
      </div>
      
      <div class="block action-time">
         <ets-datetime-picker [contextWord]="this.settings.actionTimeMode"
                              [mode]="this.getDateTimePickerMode()"
                              [disabled]="!this.settings.actionTimeMode"
                              [(value)]="this.settings.actionTime"
                              (onValueChanged)="this.onChange()"></ets-datetime-picker>
      </div>

      <div class="block timezone" *ngIf="this.settings.actionTimeMode === 'Convert At'">
         <ets-timezone-picker [(timezone)]="this.settings.timezone" flow="col"></ets-timezone-picker>
      </div>

      <div class="block replace">
         <fieldset>
            <legend>
               <dx-check-box text="Replace Before Convert"
                  [disabled]="!this.settings.actionTimeMode"
                  (disabledChange)="this.onReplaceBeforeConvertDisabledChange($event)"
                  [value]="this.getBoolean('replaceBeforeConvert')"
                  (valueChange)="this.settings.replaceBeforeConvert = $event"
                  (onValueChanged)="this.onChange('replaceBeforeConvert')"></dx-check-box>
            </legend>
            
            <div class="parameter times-to-replace">
               <span class="label" [class.inline]="this.flow === 'inline'">Times To Replace</span>
               <div class="input" [class.inline]="this.flow === 'inline'">
                  <div class="ttr-param">
                     <dx-number-box [min]="1" 
                                    [step]="1" 
                                    [showClearButton]="true"
                                    [(value)]="this.settings.timesToReplace"
                                    [disabled]="!this.settings.replaceBeforeConvert || this.settings.replacePersistently"
                                    (onValueChanged)="this.onChange()"></dx-number-box>
                  </div>
                  <span class="ttr-divider">or</span>
                  <div class="ttr-persistently">
                     <dx-check-box text="Persistently" [value]="this.settings.replacePersistently || false"
                                    (valueChange)="this.settings.replacePersistently = $event"
                           (onValueChanged)="this.onChange('replacePersistently')"></dx-check-box>
                  </div>
               </div>
            </div>
            
            <div class="parameter replace-every">
               <span class="label" [class.inline]="this.flow === 'inline'">Replace Every</span>
               <div class="input" [class.inline]="this.flow === 'inline'">
                  <ets-datetime-picker 
                     mode="timespan"
                     [(value)]="this.settings.replaceEvery" 
                     [disabled]="!this.settings.replaceBeforeConvert"
                     (onValueChanged)="this.onChange()"></ets-datetime-picker>
               </div>
            </div>

            <div class="parameter rate-of-change">
               <span class="label" [class.inline]="this.flow === 'inline'">Rate Of Change ($)</span>
               <div class="input" [class.inline]="this.flow === 'inline'">
                  <dx-number-box [step]="0.01" 
                                 [min]="0.01"
                                 [showClearButton]="true"
                                 [(value)]="this.settings.rateOfChange"
                                 (onValueChanged)="this.onChange()"
                                 [disabled]="!this.settings.replaceBeforeConvert"></dx-number-box>
               </div>
            </div>

            <div class="parameter time-order">
               <span class="label" [class.inline]="this.flow === 'inline'">
                  Reverese Time Direction
               </span>
               <div class="input" [class.inline]="this.flow === 'inline'">
                  <dx-check-box [value]="this.getBoolean('reverseTimeDirection')"
                                (valueChange)="this.settings.reverseTimeDirection = $event"
                                [disabled]="!this.settings.replaceBeforeConvert"
                                (onValueChanged)="this.onChange()"></dx-check-box>
               </div>
            </div>
         </fieldset>         
      </div>
</div>