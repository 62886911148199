import * as Enumerable from 'linq';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { getHistoryDataGridModel } from './history-list-gird-model';
import { GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { getStrategiesListGridModel as getStrategiesGridModel } from './strategies-list-grid-model';
import { getTradesGridModel } from './trades-grid-model';
import { getSnapshotsGridModel } from './snapshots-grid-model';
import { getMessagesGridModel } from './messages-grid-model';
import { takeUntil } from 'rxjs/operators';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { StrategyLogMessageDto } from 'projects/shared-components/shell-communication/dtos/strategy-log-message-dto.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import {
   GetSessionStrategyTrades
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-trades.class';
import {
   GetSessionStrategySnapshots
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-snapshots.class';
import {
   GetSessionStrategyMessages
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-messages.class';
import { isNullOrUndefined } from 'util';
import { ShowStrategyHistoryUIMessage } from '../ui-messages/ui-messages';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategyHistoryInfoDto } from '../shell-communication/shell-dto-protocol';
import { GetStrategySessionHistory, GetStrategiesHistoryListByDateRange, ExtraStartegyHistoryDirection, GetExtraStrategyHistory } from '../shell-communication/shell-operations-protocol';
import { StrategyDto } from '../shell-communication/dtos/strategy-dto.class';
import { formatDate } from '@angular/common';
import { plainToClass } from 'class-transformer';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { TradingSystemsSecurityContextService } from '../trading-systems/trading-systems-security-context.service';
import { correctedDateAsUTC, DetectMethodChanges, DetectSetterChanges } from '../utils';
import { TimestampsService } from '../timestamps.service';

//

interface StrategyHistorySecurityContext {
   strategiesList: boolean;
   historyList: boolean;
   strategyTradingData: boolean;
   loadStrategiesByDateRange: boolean;
   getExtraHistory: boolean;
   systemDetails: boolean;
}

//

interface HistoryDateRange {
   startDate?: Date;
   endDate?: Date;
   ctx?: 'strategy' | 'history';
}

//

interface SystemDetailsConfig {
   isSystemDetailsVisible?: boolean;
   plainSystem?: StrategyModel;
   disposition?: {
      itself: StrategyModel;
      groups: {
         header: string;
         strategies: StrategyModel[];
      }[];
   };
   containerHeight?: number;
}

//

@Component({
   selector: 'ets-strategy-session-history',
   templateUrl: 'strategy-session-history.component.html',
   styleUrls: ['strategy-session-history.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategySessionHistoryComponent
   implements OnInit, OnDestroy {
   public constructor(
      private _changeDetector: ChangeDetectorRef,
      private _tradingSystemsSecurityContext: TradingSystemsSecurityContextService,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _messageBus: MessageBusService,
      private _displayNameService: TradingInstrumentDisplayNameService,
      private _accessControlSvc: AccessControlService,
      private _algoMetadataService: AlgoMetadataService,
      private _timestampService: TimestampsService
   ) {
   }

   private _strategiesList: StrategyModel[] = [];

   private _historyList: StrategyHistoryInfoDto[] = [];

   private _historyListGrid: GridReadyEvent;

   private _strategiesGrid: GridReadyEvent;

   private _tradesGrid: GridReadyEvent;

   private _snapshotsGrid: GridReadyEvent;

   private _messagesGrid: GridReadyEvent;

   private _selectedStrategyTradingData: {
      trades: TradeDto[];
      snapshots: OrderStateSnapshotDto[];
      messages: StrategyLogMessageDto[];
   };

   private _unsubscriber = new Subject<any>();

   //

   tabs = [
      { text: 'Trades' },
      { text: 'Snapshots' },
      { text: 'Messages' }
   ];

   //

   securityContext: StrategyHistorySecurityContext;

   //

   strategiesGridModel;
   tradesGridModel;
   snapshotsGridModel;
   messagesGridModel;
   historyDataGridModel;

   //

   private _isVisible = false;

   get isVisible(): boolean { return this._isVisible; }

   @DetectSetterChanges()
   set isVisible(val: boolean) {
      this._isVisible = val;
   }

   //

   private _selectedTabIndex = 0;

   get selectedTabIndex(): number { return this._selectedTabIndex; }

   @DetectSetterChanges()
   set selectedTabIndex(val: number) {
      this._selectedTabIndex = val;
   }

   //

   private _selectedStrategy: StrategyModel;

   get selectedStrategy(): StrategyModel { return this._selectedStrategy; }

   @DetectSetterChanges()
   set selectedStrategy(val: StrategyModel) {
      this._selectedStrategy = val;
   }

   //

   private _selectedHistoryRecord: StrategyHistoryInfoDto;

   get selectedHistoryRecord(): StrategyHistoryInfoDto { return this._selectedHistoryRecord; }

   @DetectSetterChanges()
   set selectedHistoryRecord(val: StrategyHistoryInfoDto) {
      this._selectedHistoryRecord = val;
   }

   //

   private _historyListSectionSize = 33;

   get historyListSectionSize(): number { return this._historyListSectionSize; }

   @DetectSetterChanges()
   set historyListSectionSize(val: number) {
      this._historyListSectionSize = val;
   }

   //

   private _sessionStrategiesListSectionSize = 33;

   get sessionStrategiesListSectionSize(): number { return this._sessionStrategiesListSectionSize; }

   @DetectSetterChanges()
   set sessionStrategiesListSectionSize(val: number) {
      this._sessionStrategiesListSectionSize = val;
   }

   //

   private _tradingDataSectionSize = 33;

   get tradingDataSectionSize(): number { return this._tradingDataSectionSize; }

   @DetectSetterChanges()
   set tradingDataSectionSize(val: number) {
      this._tradingDataSectionSize = val;
   }

   //

   private _isDatePickerVisible = false;

   get isDatePickerVisible(): boolean { return this._isDatePickerVisible; }

   @DetectSetterChanges()
   set isDatePickerVisible(val: boolean) {
      this._isDatePickerVisible = val;
   }

   //

   private _dateRange: HistoryDateRange = {};

   get dateRange(): HistoryDateRange { return this._dateRange; }

   @DetectSetterChanges()
   set dateRange(val: HistoryDateRange) {
      this._dateRange = val;
   }

   //

   private _title = 'Strategy History';

   get title(): string { return this._title; }

   @DetectSetterChanges()
   set title(val: string) {
      this._title = val;
   }

   //

   private _systemDetailsModel: SystemDetailsConfig;

   get systemDetailsModel(): SystemDetailsConfig { return this._systemDetailsModel; }

   @DetectSetterChanges()
   set systemDetailsModel(val: SystemDetailsConfig) {
      this._systemDetailsModel = val;
   }

   //

   ngOnInit() {
      const isAvailable = (id) => this._accessControlSvc.isSecureElementAvailable(id);

      this.securityContext = {
         strategiesList: isAvailable('8ae71c1b-c627-4cc1-a90d-44d7f9524d8c'),
         loadStrategiesByDateRange: isAvailable('20fad765-092d-46c6-91ea-954069c0a950'),
         historyList: isAvailable('8b133ecb-dfe9-4121-bca2-394099340d9d'),
         getExtraHistory: isAvailable('9e23ba46-36c5-4c25-bbde-6fbe1253af13'),
         strategyTradingData: isAvailable('2c2ca54f-4b1d-4a1b-bbe0-bf3306f107f9'),
         systemDetails: this._tradingSystemsSecurityContext.systemDetails
      };

      this.historyDataGridModel = getHistoryDataGridModel.bind(this)(this._timestampService);
      this.strategiesGridModel = getStrategiesGridModel.bind(this)();
      this.tradesGridModel = getTradesGridModel.bind(this)(this._displayNameService, this._timestampService);
      this.snapshotsGridModel = getSnapshotsGridModel.bind(this)(this._displayNameService, this._timestampService);
      this.messagesGridModel = getMessagesGridModel.bind(this)(this._timestampService);

      this._subscribeToMessages();
   }

   //

   onHidden(): void {
      this.isVisible = false;
      this._resetState();
   }

   //

   ngOnDestroy() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
         this._unsubscriber = null;
      }
   }

   //

   onStrategiesGridReady(args: GridReadyEvent): void {
      this._strategiesGrid = args;
      if (this._strategiesList.length > 0) {
         this._strategiesGrid.api.setRowData(this._strategiesList);
      }

      const parent: HTMLElement = document.getElementById('strategy-history-popup');
      this._strategiesGrid.api.setPopupParent(parent);
   }

   //

   onHistoryListGridReady(args: GridReadyEvent) {
      this._historyListGrid = args;

      const parent: HTMLElement = document.getElementById('strategy-history-popup');
      this._historyListGrid.api.setPopupParent(parent);

      this._historyListGrid.api.sizeColumnsToFit();
   }

   //

   onTradesGridReady(args: GridReadyEvent) {
      this._tradesGrid = args;
      const parent: HTMLElement = document.getElementById('strategy-history-popup');
      this._tradesGrid.api.setPopupParent(parent);
   }

   //

   onSnapshotsGridReady(args: GridReadyEvent) {
      this._snapshotsGrid = args;
      const parent: HTMLElement = document.getElementById('strategy-history-popup');
      this._snapshotsGrid.api.setPopupParent(parent);
   }

   //

   onMessagesGridReady(args: GridReadyEvent) {
      this._messagesGrid = args;
      const parent: HTMLElement = document.getElementById('strategy-history-popup');
      this._messagesGrid.api.setPopupParent(parent);
   }

   //

   @DetectMethodChanges()
   onDateRangeChanged() { }

   //

   async selectTab($event: any): Promise<void> {
      if (this.selectedTabIndex === $event.itemIndex) {
         return;
      }


      if (!this.selectedStrategy) {
         return;
      }

      if (!this.selectedHistoryRecord) {
         return;
      }

      if (!this._selectedStrategyTradingData) {
         return;
      }

      this.selectedTabIndex = $event.itemIndex;

      let shouldLoadData = true;
      switch (this.selectedTabIndex) {
         case 0: {
            shouldLoadData =
               this._selectedStrategyTradingData.trades.length === 0;
            break;
         }
         case 1: {
            shouldLoadData =
               this._selectedStrategyTradingData.snapshots.length === 0;
            break;
         }
         case 2: {
            shouldLoadData =
               this._selectedStrategyTradingData.messages.length === 0;
            break;
         }
      }

      if (shouldLoadData) {
         await this._loadTradingDataForHistoryRecord(this.selectedHistoryRecord);
      }
   }

   //

   async onHistoryListSelectionChanged(
      args: SelectionChangedEvent
   ): Promise<void> {

      if (!this.securityContext.strategyTradingData) {
         return;
      }

      const selectedRows = args.api.getSelectedRows();

      if (selectedRows.length > 0) {
         this._selectedStrategyTradingData = {
            messages: [],
            snapshots: [],
            trades: []
         };
         this.selectedHistoryRecord = selectedRows[0];
         await this._loadTradingDataForHistoryRecord(this.selectedHistoryRecord);
      }
   }

   //

   async loadMoreTradingData(
      batchSize: number,
      dataType: 'trades' | 'snapshots' | 'messages'
   ): Promise<void> {
      if (!this.selectedStrategy || !this.selectedHistoryRecord) {
         this._toastr.info('Select a strategy and/or session first', 'Strategy/Session Not Selected');
         return;
      }

      let historyStart = 0;

      switch (dataType) {
         case 'trades': {
            const tradesContainer = this._selectedStrategyTradingData.trades;
            if (tradesContainer.length > 0) {
               const lastTrade = tradesContainer[tradesContainer.length - 1];
               historyStart = lastTrade.seqNum;
            }
            await this._tryLoadSessionStrategyTrades(
               this.selectedHistoryRecord.sessionId,
               this.selectedHistoryRecord.strategyId,
               this.selectedHistoryRecord.strategyId === EtsConstants.strategies.manualStrategyId
                  ? this.selectedHistoryRecord.ticker
                  : null,
               historyStart,
               batchSize
            );
            break;
         }
         case 'snapshots': {
            const snapshotsContainer = this._selectedStrategyTradingData.snapshots;
            if (snapshotsContainer.length > 0) {
               const lastTrade = snapshotsContainer[snapshotsContainer.length - 1];
               historyStart = lastTrade.seqNum;
            }
            await this._tryLoadSessionStrategySnapshots(
               this.selectedHistoryRecord.sessionId,
               this.selectedHistoryRecord.strategyId,
               this.selectedHistoryRecord.strategyId === EtsConstants.strategies.manualStrategyId
                  ? this.selectedHistoryRecord.ticker
                  : null,
               historyStart,
               batchSize
            );
            break;
         }
         case 'messages': {
            break;
         }
      }
   }

   //

   async onStrategiesSelectionChanged(args: SelectionChangedEvent): Promise<void> {
      if (!this.securityContext.historyList) {
         return;
      }

      const selectedRows = args.api.getSelectedRows();

      if (selectedRows.length > 0) {
         this.selectedStrategy = selectedRows[0];
         await this._tryLoadStrategyHistory(this.selectedStrategy.strategyId);
      }
   }

   //

   toggleSectionFullWidth(
      section?: 'history' | 'strategy' | 'tradingData'
   ): void {
      if (
         this.historyListSectionSize === 100 ||
         this.sessionStrategiesListSectionSize === 100 ||
         this.tradingDataSectionSize === 100
      ) {
         this.historyListSectionSize = 33;
         this.sessionStrategiesListSectionSize = 33;
         this.tradingDataSectionSize = 33;
      } else {
         switch (section) {
            case 'history': {
               this.historyListSectionSize = 100;
               this.sessionStrategiesListSectionSize = 0;
               this.tradingDataSectionSize = 0;
               break;
            }
            case 'strategy': {
               this.historyListSectionSize = 0;
               this.sessionStrategiesListSectionSize = 100;
               this.tradingDataSectionSize = 0;
               break;
            }
            case 'tradingData': {
               this.historyListSectionSize = 0;
               this.sessionStrategiesListSectionSize = 0;
               this.tradingDataSectionSize = 100;
               break;
            }
         }
      }
   }

   //

   async onShown() {
      this._setTitle();

      if (this._strategiesList.length > 0) {
         if (this.securityContext.getExtraHistory) {
            setTimeout(() => this.isDatePickerVisible = true, 0);
         } else {
            await this._tryLoadStrategyHistory(this._strategiesList[0].strategyId);
         }
      } else {
         if (this.securityContext.loadStrategiesByDateRange) {
            setTimeout(() => this.isDatePickerVisible = true, 0);
         }
      }
   }

   //

   async applyDateFilter() {
      if (isNullOrUndefined(this.dateRange.startDate)) {
         this._toastr.error('Incorrect date range');
         return;
      }

      if (isNullOrUndefined(this.dateRange.endDate)) {
         this._toastr.error('Incorrect date range');
         return;
      }

      this._setTitle();

      if (this.dateRange.ctx === 'strategy' || this._strategiesList.length === 0) {
         this.dateRange.ctx = null;

         this._historyList.length = 0;
         this._historyListGrid.api.setRowData([]);

         this._strategiesList.length = 0;
         this._strategiesGrid.api.setRowData([]);

         await this._tryLoadStrategiesListByDateRange();
      } else {
         setTimeout(() => {
            this.selectedStrategy = this._strategiesList[0];
            const node = this._strategiesGrid.api.getRowNode(this.selectedStrategy.strategyId);
            this._strategiesGrid.api.selectNode(node);
         }, 0);
      }

      this.isDatePickerVisible = false;
   }

   //

   onDatePickerHidden() {
      this.isDatePickerVisible = false;
   }

   //

   async loadEarlierHistory() {
      if (this._historyList.length === 0 && !this.dateRange.startDate) {
         this._toastr.error('History Wasn\'t Loaded Previously');
         return;
      }

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      const startDate = this._historyList.length > 0
         ? this._historyList[0].endDate
         : this.dateRange.startDate;

      const correctedStartDate = correctedDateAsUTC(startDate);

      const qry = new GetExtraStrategyHistory(
         this.selectedStrategy.strategyId,
         ExtraStartegyHistoryDirection.Earlier
      );

      this._historyListGrid.api.showLoadingOverlay();

      try {
         const dtos = await this._shellClient.processQuery<StrategyHistoryInfoDto[]>(qry);
         if (dtos.length > 0) {
            this._historyList.unshift(...dtos);
            this._historyListGrid.api.setRowData(this._historyList);
         } else {
            this._toastr.info('No Earlier History');
         }
      } catch (e) {
         this._toastr.error('"Load Earlier History" command completed with errors');
         const data = { error: e.stack || e };
         console.error('loadEarlierHistory()', data);
      } finally {
         this._historyListGrid.api.hideOverlay();
      }
   }

   //

   async loadLaterHistory() {
      if (this._historyList.length === 0 || !this.dateRange.endDate) {
         this._toastr.error('Date Range Not Set');
         return;
      }

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }


      const endDate = this._historyList.length > 0
         ? this._historyList[this._historyList.length - 1].endDate
         : this.dateRange.endDate;

      const correctedEndDate = correctedDateAsUTC(endDate);

      const qry = new GetExtraStrategyHistory(
         this.selectedStrategy.strategyId,
         ExtraStartegyHistoryDirection.Later
      );

      this._historyListGrid.api.showLoadingOverlay();

      try {
         const dtos = await this._shellClient.processQuery<StrategyHistoryInfoDto[]>(qry);
         if (dtos.length > 0) {
            this._historyList.push(...dtos);
            this._historyListGrid.api.setRowData(this._historyList);
         } else {
            this._toastr.info('No Later History');
         }
      } catch (e) {
         this._toastr.error('"Load Later History" command completed with errors');
         const data = { error: e.stack || e };
         console.error('loadLaterHistory()', data);
      } finally {
         this._historyListGrid.api.hideOverlay();
      }
   }

   //

   showSystemDetails(strategy: StrategyModel): void {
      if (strategy.dispositionStrategies && strategy.dispositionStrategies.length) {
         const byAlgoId = Enumerable.from(strategy.dispositionStrategies)
            .groupBy(x => x.algoId);

         const groups = byAlgoId
            .select(x => ({
               header: this._algoMetadataService.getAlgoModel(x.key()).displayName,
               strategies: x.getSource()
            })
            )
            .toArray();

         this.systemDetailsModel = {
            disposition: { itself: strategy, groups }
         };
      } else {
         this.systemDetailsModel = { plainSystem: strategy };
      }

      this.systemDetailsModel.isSystemDetailsVisible = true;
   }

   //

   onHeightCalculated(event: number): void {
      if (this.systemDetailsModel) {
         const height = this.systemDetailsModel.containerHeight || 0;
         if (event > height) {
            this.systemDetailsModel.containerHeight = event;
         }
      }
   }

   //

   private async _loadStrategyHistory(
      strategyId: string,
      startDate?: Date,
      endDate?: Date
   ): Promise<StrategyHistoryInfoDto[]> {
      const qry = new GetStrategySessionHistory(
         strategyId,
         startDate,
         endDate
      );
      return this._shellClient.processQuery<StrategyHistoryInfoDto[]>(qry);
   }

   //

   private async _loadTradingDataForHistoryRecord(
      historyRecord: StrategyHistoryInfoDto
   ) {
      switch (this.selectedTabIndex) {
         case 0: {
            await this._tryLoadSessionStrategyTrades(
               historyRecord.sessionId,
               historyRecord.strategyId,
               historyRecord.strategyId === EtsConstants.strategies.manualStrategyId
                  ? historyRecord.ticker
                  : null
            );
            break;
         }
         case 1: {
            await this._tryLoadSessionStrategySnapshots(
               historyRecord.sessionId,
               historyRecord.strategyId,
               historyRecord.strategyId === EtsConstants.strategies.manualStrategyId
                  ? historyRecord.ticker
                  : null
            );
            break;
         }
         case 2: {
            await this._tryLoadSessionStrategyMessages(
               historyRecord.sessionId,
               historyRecord.strategyId
            );
            break;
         }
      }
   }

   //

   private async _tryLoadStrategyHistory(
      strategyId: string
   ): Promise<void> {

      this._historyList.length = 0;

      this._historyListGrid.api.setRowData([]);

      this._messagesGrid.api.setRowData([]);
      this._snapshotsGrid.api.setRowData([]);
      this._tradesGrid.api.setRowData([]);

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy not selected');
         return;
      }

      this._historyListGrid.api.showLoadingOverlay();

      try {
         const cDateRange = this._getCorrectedDateRange();
         const dtos = await this._loadStrategyHistory(strategyId, cDateRange.startDate, cDateRange.endDate);
         this._historyList.push(...dtos);
         this._historyListGrid.api.setRowData(this._historyList);
      } catch (e) {
         this._toastr.error('Startegy history loaded with errors');
         const data = { error: e.stack || e };
         console.error('_tryLoadStrategyHistory()', data);
      } finally {
         this._historyListGrid.api.hideOverlay();
      }
   }

   //

   private async _tryLoadSessionStrategyTrades(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number
   ): Promise<void> {
      const gridApi = this._tradesGrid.api;
      gridApi.showLoadingOverlay();
      try {
         const dtos = await this._loadSessionStrategyTrades(
            sessionId,
            strategyId,
            ticker,
            historyStart,
            batchSize
         );
         const tradesContainer = this._selectedStrategyTradingData.trades;
         tradesContainer.push(...dtos);
         gridApi.setRowData(tradesContainer);
      } catch (e) {
         this._toastr.error('"Session Strategy Trades" were loaded with errors');
         const data = { error: e.stack || e, strategyId, batchSize };
         console.error('_tryLoadSessionStrategyTrades()', data);
      } finally {
         gridApi.hideOverlay();
      }
   }

   //

   private async _loadSessionStrategyTrades(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number
   ): Promise<TradeDto[]> {

      const qry = new GetSessionStrategyTrades(
         strategyId,
         sessionId,
         ticker,
         historyStart || 0,
         batchSize || 100,
         null,
         null,
         null
      );
      return this._shellClient.processQuery<TradeDto[]>(qry);
   }

   //

   private async _loadSessionStrategySnapshots(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number
   ): Promise<OrderStateSnapshotDto[]> {
      const qry = new GetSessionStrategySnapshots(
         strategyId,
         sessionId,
         ticker,
         historyStart || 0,
         batchSize || 100,
         null,
         null,
         null
      );
      return this._shellClient.processQuery<OrderStateSnapshotDto[]>(qry);
   }

   //

   private async _loadSessionStrategyMessages(
      sessionId: string,
      strategyId: string,
      historyStart?: number,
      batchSize?: number
   ): Promise<StrategyLogMessageDto[]> {
      const qry = new GetSessionStrategyMessages(
         strategyId,
         sessionId,
         historyStart || 0,
         batchSize || 100
      );
      return this._shellClient.processQuery<StrategyLogMessageDto[]>(qry);
   }

   //

   private _subscribeToMessages() {
      this._unsubscriber = new Subject<any>();

      this._messageBus.of<ShowStrategyHistoryUIMessage>('ShowStrategyHistoryUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onShowStrategyHistoryUIMessage(message.payload));
   }

   //

   private _onShowStrategyHistoryUIMessage(message: ShowStrategyHistoryUIMessage): void {
      this._strategiesList = message.strategies;

      if (this._strategiesList.length === 1) {
         this.selectedStrategy = this._strategiesList[0];
      }

      if (this._strategiesGrid) {
         this._strategiesGrid.api.setRowData(this._strategiesList);
      }

      this.isVisible = true;
   }

   //

   private async _tryLoadSessionStrategySnapshots(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number
   ): Promise<void> {
      const gridApi = this._snapshotsGrid.api;
      gridApi.showLoadingOverlay();
      try {
         const dtos = await this._loadSessionStrategySnapshots(
            sessionId,
            strategyId,
            ticker,
            historyStart,
            batchSize
         );
         const snapshotsContainer = this._selectedStrategyTradingData.snapshots;
         snapshotsContainer.push(...dtos);
         gridApi.setRowData(snapshotsContainer);
      } catch (e) {
         this._toastr.error(
            '"Session Strategy Snapshots" were loaded with errors'
         );
         const data = { error: e.stack || e, strategyId, batchSize };
         console.error('_tryLoadSessionStrategySnapshots()', data);
      } finally {
         gridApi.hideOverlay();
      }
   }

   //

   private async _tryLoadSessionStrategyMessages(
      sessionId: string,
      strategyId: string,
      historyStart?: number,
      batchSize?: number
   ): Promise<void> {
      const gridApi = this._messagesGrid.api;
      gridApi.showLoadingOverlay();
      try {
         if (strategyId === EtsConstants.strategies.manualStrategyId) {
            gridApi.setRowData([]);
         } else {
            const dtos = await this._loadSessionStrategyMessages(
               sessionId,
               strategyId,
               historyStart,
               batchSize
            );
            const messagesContainer = this._selectedStrategyTradingData.messages;
            messagesContainer.push(...dtos);
            gridApi.setRowData(messagesContainer);
         }
      } catch (e) {
         this._toastr.error(
            '"Session Strategy Snapshots" were loaded with errors'
         );
         const data = { error: e.stack || e, strategyId, batchSize };
         console.error('_tryLoadSessionStrategyMessages()', data);
      } finally {
         gridApi.hideOverlay();
      }
   }

   //

   private async _tryLoadStrategiesListByDateRange() {
      if (!this.dateRange.startDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!this.dateRange.endDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      const grid = this._strategiesGrid;
      if (!grid) {
         this._toastr.error('Strategies Grid Not Initialized');
         return;
      }

      this._strategiesList.length = 0;
      this._strategiesGrid.api.setRowData([]);

      grid.api.showLoadingOverlay();

      try {
         const dr = this._getCorrectedDateRange();

         const qry = new GetStrategiesHistoryListByDateRange(
            dr.startDate,
            dr.endDate
         );
         const list = await this._shellClient.processQuery<StrategyDto[]>(qry);
         const modelsList: StrategyModel[] = list.map(x => {
            const model = plainToClass(StrategyModel, x);
            model.updateMetadata(this._algoMetadataService);
            return model;
         });
         this._strategiesList.push(...modelsList);
         this._strategiesGrid.api.setRowData(this._strategiesList);
      } catch (e) {
         this._toastr.error('"Strategies List" was loaded with errors');
         const data = { error: e.stack || e, dateRange: this.dateRange };
         console.error('_tryLoadStrategiesListByDateRange()', data);
      } finally {
         grid.api.hideOverlay();
      }
   }

   //

   private _resetState() {
      this.dateRange = {};
      this.isDatePickerVisible = false;
      this.selectedStrategy = null;

      this._strategiesList.length = 0;
      this._historyList.length = 0;

      this._setTitle();

      this._messagesGrid.api.setRowData([]);
      this._snapshotsGrid.api.setRowData([]);
      this._tradesGrid.api.setRowData([]);
      this._historyListGrid.api.setRowData([]);
      this._strategiesGrid.api.setRowData([]);
   }

   //

   private _setTitle() {
      if (this.dateRange.startDate && this.dateRange.endDate) {
         const startDate = formatDate(this.dateRange.startDate, 'yyyy-MMM-dd', 'en-US');
         const endDate = formatDate(this.dateRange.endDate, 'yyyy-MMM-dd', 'en-US');
         this.title = `Strategy History (from '${startDate}' to '${endDate}')`;
      } else {
         this.title = 'Strategy History';
      }
   }

   //

   private _getCorrectedDateRange(): { startDate?: Date, endDate?: Date } {
      if (!this.dateRange.startDate || !this.dateRange.endDate) {
         return {};
      }

      const start = correctedDateAsUTC(this.dateRange.startDate);
      const end = correctedDateAsUTC(this.dateRange.endDate);

      return { startDate: start, endDate: end };
   }
}
