import { isNullOrUndefined } from 'projects/shared-components/utils';

export class TradingObjectivesModel {

  //#region Accumulated

  private _isAccumulatedNpo = false;
  private _accumulatedNpo: number;
  
  private _isAccumulatedDollarTS = false;
  private _accumulatedDollarTS: number;
  
  private _isAccumulatedPercentTS = false;
  private _accumulatedPercentTS: number;
  
  private _isAccumulatedStopLoss = false;
  private _accumulatedStopLoss: number;

  private _isAccumulatedSlMargin = false;
  private _accumulatedSlMargin: number;

  get isAccumulatedSlMargin(): boolean {
    return this._isAccumulatedSlMargin;
  }

  set isAccumulatedSlMargin(value: boolean) {
    this._isAccumulatedSlMargin = value;
    if (!value) {
      this.accumulatedSlMargin = null;
    }
  }

  get accumulatedSlMargin(): number {
    return this._accumulatedSlMargin;
  }

  set accumulatedSlMargin(value: number) {
    this._accumulatedSlMargin = value;
    if (!isNullOrUndefined(value)) {
      if (value > 0) {
         this.isAccumulatedSlMargin = true;
      }
    }
  }

  get isAccumulatedNpo(): boolean {
    return this._isAccumulatedNpo;
  }

  set isAccumulatedNpo(value: boolean) {
    this._isAccumulatedNpo = value;
    if (!value) {
      this.accumulatedNpo = null;
    }
  }

  get accumulatedNpo(): number {
    return this._accumulatedNpo;
  }

  set accumulatedNpo(value: number) {
    this._accumulatedNpo = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isAccumulatedNpo = true;
      }
    }
  }

  get isAccumulatedDollarTS(): boolean {
    return this._isAccumulatedDollarTS;
  }

  set isAccumulatedDollarTS(value: boolean) {
    this._isAccumulatedDollarTS = value;
    if (!value) {
      this.accumulatedDollarTS = null;
    }
  }

  get accumulatedDollarTS(): number {
    return this._accumulatedDollarTS;
  }

  set accumulatedDollarTS(value: number) {
    this._accumulatedDollarTS = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isAccumulatedDollarTS = true;
      }
    }
  }

  get isAccumulatedPercentTS(): boolean {
    return this._isAccumulatedPercentTS;
  }

  set isAccumulatedPercentTS(value: boolean) {
    this._isAccumulatedPercentTS = value;
    if (!value) {
      this.accumulatedPercentTS = null;
    }
  }

  get accumulatedPercentTS(): number {
    return this._accumulatedPercentTS;
  }

  set accumulatedPercentTS(value: number) {
    this._accumulatedPercentTS = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isAccumulatedPercentTS = true;
      }
    }
  }

  get isAccumulatedStopLoss(): boolean {
    return this._isAccumulatedStopLoss;
  }

  set isAccumulatedStopLoss(value: boolean) {
    this._isAccumulatedStopLoss = value;
    if (!value) {
      this.accumulatedStopLoss = null;
    }
  }

  get accumulatedStopLoss(): number {
    return this._accumulatedStopLoss;
  }

  set accumulatedStopLoss(value: number) {
    this._accumulatedStopLoss = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isAccumulatedStopLoss = true;
      }
    }
  }

  //#endregion Totals


  //#region Trades

  private _isTradeNpo = false;
  private _tradeNpo: number;
  
  private _isTradeDollarTS = false;
  private _tradeDollarTS: number;
  
  private _isTradePercentTS = false;
  private _tradePercentTS: number;
  
  private _isTradeStopLoss = false;
  private _tradeStopLoss: number;

  private _isTradeSlMargin = false;
  private _tradeSlMargin: number;

  get isTradeSlMargin(): boolean {
    return this._isTradeSlMargin;
  }

  set isTradeSlMargin(value: boolean) {
    this._isTradeSlMargin = value;
    if (!value) {
      this.tradeSlMargin = null;
    }
  }

  get tradeSlMargin(): number {
    return this._tradeSlMargin;
  }

  set tradeSlMargin(value: number) {
    this._tradeSlMargin = value;
    if (!isNullOrUndefined(value)) {
      if (value > 0) {
         this.isTradeSlMargin = true;
      }
    }
  }

  get isTradeNpo(): boolean {
    return this._isTradeNpo;
  }

  set isTradeNpo(value: boolean) {
    this._isTradeNpo = value;
    if (!value) {
      this.tradeNpo = null;
    }
  }

  get tradeNpo(): number {
    return this._tradeNpo;
  }

  set tradeNpo(value: number) {
    this._tradeNpo = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isTradeNpo = true;
      }
    }
  }

  get isTradeDollarTS(): boolean {
    return this._isTradeDollarTS;
  }

  set isTradeDollarTS(value: boolean) {
    this._isTradeDollarTS = value;
    if (!value) {
      this.tradeDollarTS = null;
    }
  }

  get tradeDollarTS(): number {
    return this._tradeDollarTS;
  }

  set tradeDollarTS(value: number) {
    this._tradeDollarTS = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isTradeDollarTS = true;
      }
    }
  }

  get isTradePercentTS(): boolean {
    return this._isTradePercentTS;
  }

  set isTradePercentTS(value: boolean) {
    this._isTradePercentTS = value;
    if (!value) {
      this.tradePercentTS = null;
    }
  }

  get tradePercentTS(): number {
    return this._tradePercentTS;
  }

  set tradePercentTS(value: number) {
    this._tradePercentTS = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isTradePercentTS = true;
      }
    }
  }

  get isTradeStopLoss(): boolean {
    return this._isTradeStopLoss;
  }

  set isTradeStopLoss(value: boolean) {
    this._isTradeStopLoss = value;
    if (!value) {
      this.tradeStopLoss = null;
    }
  }

  get tradeStopLoss(): number {
    return this._tradeStopLoss;
  }

  set tradeStopLoss(value: number) {
    this._tradeStopLoss = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isTradeStopLoss = true;
      }
    }
  }

  //#endregion


  //#region Session

  private _isSessionNpo = false;
  private _sessionNpo: number;
  
  private _isSessionDollarTS = false;
  private _sessionDollarTS: number;
  
  private _isSessionPercentTS = false;
  private _sessionPercentTS: number;
  
  private _isSessionStopLoss = false;
  private _sessionStopLoss: number;

  private _isSessionSlMargin = false;
  private _sessionSlMargin: number;

  get isSessionSlMargin(): boolean {
    return this._isSessionSlMargin;
  }

  set isSessionSlMargin(value: boolean) {
    this._isSessionSlMargin = value;
    if (!value) {
      this.sessionSlMargin = null;
    }
  }

  get sessionSlMargin(): number {
    return this._sessionSlMargin;
  }

  set sessionSlMargin(value: number) {
    this._sessionSlMargin = value;
    
    if (!isNullOrUndefined(value)) {
      if (value > 0) {
         this.isSessionSlMargin = true;
      }
    }
  }

  get isSessionNpo(): boolean {
    return this._isSessionNpo;
  }

  set isSessionNpo(value: boolean) {
    this._isSessionNpo = value;
    if (!value) {
      this.sessionNpo = null;
    }
  }

  get sessionNpo(): number {
    return this._sessionNpo;
  }

  set sessionNpo(value: number) {
    this._sessionNpo = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isSessionNpo = true;
      }
    }
  }

  get isSessionDollarTS(): boolean {
    return this._isSessionDollarTS;
  }

  set isSessionDollarTS(value: boolean) {
    this._isSessionDollarTS = value;
    if (!value) {
      this.sessionDollarTS = null;
    }
  }

  get sessionDollarTS(): number {
    return this._sessionDollarTS;
  }

  set sessionDollarTS(value: number) {
    this._sessionDollarTS = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isSessionDollarTS = true;
      }
    }
  }

  get isSessionPercentTS(): boolean {
    return this._isSessionPercentTS;
  }

  set isSessionPercentTS(value: boolean) {
    this._isSessionPercentTS = value;
    if (!value) {
      this.sessionPercentTS = null;
    }
  }

  get sessionPercentTS(): number {
    return this._sessionPercentTS;
  }

  set sessionPercentTS(value: number) {
    this._sessionPercentTS = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isSessionPercentTS = true;
      }
    }
  }

  get isSessionStopLoss(): boolean {
    return this._isSessionStopLoss;
  }

  set isSessionStopLoss(value: boolean) {
    this._isSessionStopLoss = value;
    if (!value) {
      this.sessionStopLoss = null;
    }
  }

  get sessionStopLoss(): number {
    return this._sessionStopLoss;
  }

  set sessionStopLoss(value: number) {
    this._sessionStopLoss = value;
    if (value !== null && value !== undefined) {
      if (value > 0) {
        this.isSessionStopLoss = true;
      }
    }
  }

  //#endregion


  get canSetAccumulatedPercentTS(): boolean {
    return !!this.accumulatedNpo &&
      this.accumulatedNpo > 0;
  }

  get canSetTradePercentTS(): boolean {
    return !!this.tradeNpo &&
      this.tradeNpo > 0;
  }

  get canSetSessionPercentTS(): boolean {
    return !!this.sessionNpo &&
      this.sessionNpo > 0;
  }

  toJSON(): string {
    return JSON.stringify({
      accumulatedNpo: this.accumulatedNpo,
      accumulatedPercentTS: this.accumulatedPercentTS,
      accumulatedDollarTS: this.accumulatedDollarTS,
      accumulatedStopLoss: this.accumulatedStopLoss,
      accumulatedSlMargin: this.accumulatedSlMargin,
      
      tradeNpo: this.tradeNpo,
      tradePercentTS: this.tradePercentTS,
      tradeDollarTS: this.tradeDollarTS,
      tradeStopLoss: this.tradeStopLoss,
      tradeSlMargin: this.tradeSlMargin,

      sessionNpo: this.sessionNpo,
      sessionPercentTS: this.sessionPercentTS,
      sessionDollarTS: this.sessionDollarTS,
      sessionStopLoss: this.sessionStopLoss,
      sessionSlMargin: this.sessionSlMargin
    });
  }
}
