import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AdjustmentStrategyDialogModule } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DevExtremeModule } from '../devextreme.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { OrderExtensionsModule } from '../order-extensions/order-extensions.module';
import { PortfoliosModule } from '../portfolios/portfolios.module';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { MultiLegOrderComponent } from './multi-leg-order/multi-leg-order.component';
import { MultiTradePadComponent } from './multi-trade-pad.component';
import { MarketTimeModule } from '../market-time/market-time.module';

@NgModule({
imports: [
      CommonModule,
      EtsSymbolPickerModule,
      OptionsCommonModule,
      DevExtremeModule,
      PortfoliosModule,
      AdjustmentStrategyDialogModule,
      DateTimePickerModule,
      TimezonePickerModule,
      AgGridModule.withComponents([]),
      OrderExtensionsModule,
      MarketTimeModule
   ],
   exports: [MultiTradePadComponent],
   declarations: [
      MultiTradePadComponent,
      MultiLegOrderComponent
   ],
   providers: [],
})
export class MultiTradePadModule { }
