export enum OrderType {
   Unknown = 0,
   Limit = 1,
   Market = 2,
   Stop = 3,
   StopLimit = 4,
   MIT = 5,
   StopTT = 6,
   StopLimitTT = 7,
   MitTT = 8,
   ProxyLimit = 9,
   ProxyStop = 10,
   ProxyLimitTT = 11,
   ProxyStopTT = 12,
   MarketOnOpen = 13,
   LimitOnOpen = 14,
   FutureLimit = 15,
   FutureMarket = 16,
   AutoLimit = 17,
}
