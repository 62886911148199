import { ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageBusMessage, MessageBusService } from 'projects/shared-components/message-bus.service';
import { ShellMessageDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'ets-shell-messages-group-row',
   templateUrl: 'shell-messages-group-row-renderer.component.html',
   styleUrls: ['shell-messages-group-row-renderer.component.scss']
})
export class SessionMessagesGrouRowInnerRendererComponent implements ICellRendererAngularComp {

   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _messageBus: MessageBusService) {

   }

   private _unsubsriber = new Subject();

   title: string;

   //

   rendererParams: ICellRendererParams;

   //

   unreadCount = 0;
   
   //

   totalCount = 0;
   
   //
   
   agInit(params: ICellRendererParams): void {
      this.rendererParams = params;

      this.title = this.rendererParams.node.key === 'true' ? 'Important' : 'Regular';

      this._messageBus.of('SessionMessagesGroupRowUpdate')
         .pipe(takeUntil(this._unsubsriber))
         .subscribe( (msg: MessageBusMessage<any>) => {
            
            const payload = msg.payload;
            
            if (payload.important && this.title === 'Regular') {
               return;
            }

            if (!payload.important && this.title === 'Important') {
               return;
            }

            this.refreshData();
            
         });

      this.refreshData();
   }

   //

   refresh(): boolean {
      return false;
   }

   //

   @DetectMethodChanges()
   private refreshData() {
      this.unreadCount = this.totalCount = 0;

      this.rendererParams.api.forEachNodeAfterFilter(node => {
         if (node.group) {
            return;
         }

         const data: ShellMessageDto = node.data;

         if (!data.shellMessageId) {
            return;
         }

         if (this.title === 'Important' && data.level !== 'Critical') {
            return;
         }

         if (this.title === 'Regular' && data.level === 'Critical') {
            return;
         }

         if (!data.isRead) {
            this.unreadCount++;
         }

         this.totalCount++;

      });
   }
}
