<div class="panel-container"  [class.ets-hidden]="!this.isActive" 
                              [class.no-bucket]="this.currentSpec?.bucketType == '<No Bucket>'" 
                              #container>
   <ag-grid-angular
      #grid
     class="ag-theme-balham-dark grid"
     [gridOptions]="gridOptions"
   ></ag-grid-angular>

   <dx-load-panel [visible]="isLoading" 
                  [position]="{ my: 'center', at: 'center', of: container }">
   </dx-load-panel>

</div>

<dx-popup width="450"
          height="350"
          [showTitle]="true"
          title="Record Note"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          (onHidden)="this.onAddNoteDialogHidden()"
          [visible]="this.ledgerNoteConfig.isVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="messagebox">
      <div class="text">
        <dx-text-area [value]="this.ledgerNoteConfig.ledgerRecord?.note" [readOnly]="false" #note></dx-text-area>
      </div>
      <div class="controls">
         <dx-button text="Save" (onClick)="this.saveLedgerRecordNote(this.ledgerNoteConfig.ledgerRecord, note.value)"></dx-button>
         <dx-button text="Cancel" (onClick)="this.onAddNoteDialogCancelClicked()"></dx-button>
      </div>
    </div>
  </div>
</dx-popup>