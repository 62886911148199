import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PnlValue } from './PnlValue';
import { AutomationCpMessageBusService } from '../../services/automation-cp-message-bus.service';
import { BucketSummaryDto, ComboDto, GetBucketSummaryForAutomationCpReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { DetectMethodChanges, isVoid } from 'projects/shared-components/utils';
import { GetBucketSummaryForAutomationCp } from 'projects/shared-components/shell-communication/shell-operations-protocol';

@Component({
   selector: 'ets-automation-cp-header-combo-pnls',
   templateUrl: './combo-pnls.component.html',
   styleUrls: ['./combo-pnls.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboPnlsComponent implements OnInit, OnDestroy {
   constructor(
      private _cpMessageBus: AutomationCpMessageBusService,
      private _changeDetector: ChangeDetectorRef,
      private _shellService: ShellClientService,
      private _messageBus: MessageBusService
   ) { }

   private _unsubscriber = new Subject();

   private _selectedCombo: ComboDto;

   private _pendingUpdate: BucketSummaryDto;

   private _isLoadingData: boolean;

   readonly sessionPnlSectionHeader = 'Sess. PnL';

   readonly accumulatedPnlSectionHeader = 'Acc. PnL';

   readonly sessionPnl = new PnlValue();

   readonly accumulatedPnl = new PnlValue();

   ngOnInit(): void {

      this._cpMessageBus.when<ComboDto>('ComboChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this.onComboChanged(dto))

      this._messageBus.of<BucketSummaryDto[]>('BucketSummaryDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onBucketSummaryDto(msg.payload));
   }

   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   @DetectMethodChanges()
   onBucketSummaryDto(dtos: BucketSummaryDto[]): void {

      if (isVoid(this._selectedCombo)) {
         return;
      }

      const found = dtos.find(x => x.comboId === this._selectedCombo.comboId);

      if (isVoid(found)) {
         return;
      }

      if (this._isLoadingData) {
         this._pendingUpdate = found;
         return;
      }

      this.sessionPnl.onValueChanged(found.sessionTotalPnL);
      this.accumulatedPnl.onValueChanged(found.accumulatedTotalPnL);

   }

   @DetectMethodChanges({ isAsync: true })
   async onComboChanged(dto: ComboDto): Promise<void> {

      this._selectedCombo = dto;

      this.sessionPnl.onValueChanged(null);

      this.accumulatedPnl.onValueChanged(null);

      if (isVoid(dto)) {
         return;
      }

      const qry = new GetBucketSummaryForAutomationCp(dto.comboId);

      try {

         this._isLoadingData = true;

         const reply = await this._shellService
            .processQuery<GetBucketSummaryForAutomationCpReply>(qry);

         let sessionPnL = reply.sessionPnL;
         let accumulatedPnL = reply.accumulatedPnL;

         if (this._pendingUpdate) {
            sessionPnL = this._pendingUpdate.sessionTotalPnL;
            accumulatedPnL = this._pendingUpdate.accumulatedTotalPnL;
            this._pendingUpdate = null;
         }

         this.sessionPnl.onValueChanged(sessionPnL);
         this.accumulatedPnl.onValueChanged(accumulatedPnL);

      } finally {

         this._isLoadingData = false;

      }
   }
}
