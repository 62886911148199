import { Injectable } from '@angular/core';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { SubscribeForJobProgressMessagesShell } from '../shell-communication/shell-operations-protocol';
import Guid from 'devextreme/core/guid';

@Injectable({providedIn: 'root'})
export class JobsService {
   
   constructor(private _shellClient: ShellClientService) { }

   init(): Promise<void> {
      const cmd = new SubscribeForJobProgressMessagesShell();     
      return this._shellClient.processCommand(cmd);
   }

   subscribeJob() {
      const jobId = new Guid();
      const cmd = new SubscribeForJobProgressMessagesShell(jobId.toString());
      this._shellClient.processCommand(cmd);
   }
}
