import { GridOptions } from 'ag-grid-community';
import { SystemDetailsComponent } from './system-details.component';
import { centeredColumnDef } from 'projects/shared-components/ag-grid-contrib';
import { FirstDataRenderedEvent } from 'ag-grid-community/dist/lib/events';

export function getSystemDetailsSpecificGridModel(
  this: SystemDetailsComponent
) {
  return  {
    rowClass: 'ets-text-centered',

    rowData: [],

    defaultColDef: centeredColumnDef,

    suppressCellSelection: true,

    rowSelection: 'single',

     

    columnDefs: [
      {
        headerName: 'Parameter',
        field: 'parameter',
        width: 140
      },
      {
        headerName: 'Value',
        field: 'value',
        width: 120
      }
    ],

    getRowNodeId: (args) => {
      return args.parameter;
    },

    onGridReady: (args) => this.onSystemDetailsSpecificGridReady(args),

    onSelectionChanged: (args) => this.onSelectionChanged(args),

    onFirstDataRendered: (args: FirstDataRenderedEvent) => args.api.sizeColumnsToFit()
  } as GridOptions;
}
