<div [ngClass]="{'ets-hidden': !isActive}" style="margin: 10px; height: 100%;" #container>
  
  <div class="control-panel">

    <div class="cp-item underlying">
      <p>
        Underlying
        <span *ngIf="!!lastPx" class="last-px">&nbsp;{{this.lastPx | number:'1.0-4'}}
          <span [ngStyle]="{'color': this.lastChange < 0 ? 'red' : (this.lastPx > 0 ? 'green' : undefined)}">
            ({{(this.lastChange > 0 ? '+' : '')}}{{this.lastChange | number:'1.2-2'}}%)
          </span>
        </span> 
      </p>
      <ets-symbol-picker (instrumentSelected)="this.onSymbolSelected($event)">
      </ets-symbol-picker>  
    </div>
    
    <div class="cp-item num-of-strikes">
      <p># of Strikes</p>
      <dx-number-box  [showSpinButtons]="true" [useLargeSpinButtons]="false" [min]="1"
                      [(value)]="numOfStrikes"
      ></dx-number-box>
    </div>

    <div class="cp-item strike-step">
      <p>Strike Step</p>
      <dx-number-box    [showSpinButtons]="true" 
                        [useLargeSpinButtons]="false" 
                        [min]="0.5"
                        [step]="0.5"
                        [showClearButton]="true"
                        [(value)]="this.strikeStep"
      ></dx-number-box>
    </div>
    
    <div class="cp-item center-strike">
      <p>Center Strike</p>
      <ets-strikes-dropdown [(strike)]="this.centerStrike" [targetInstrument]="this.underlying" 
            [expiration]="this.expiration"></ets-strikes-dropdown>
    </div>
    
    <div class="cp-item calls-puts">
      <div>
        <dx-check-box text="Calls" [(value)]="loadCalls"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Puts" [(value)]="loadPuts"></dx-check-box>
      </div>
    </div>
    
    <div class="cp-item expiration-date">
      <p>Select expiration date:</p>
      <dx-select-box [(value)]="this.expiration" 
                     [items]="expirations"
                     displayExpr="dateWithDaysToExpiration"
                     [disabled]="!expirations">
         <div *dxTemplate="let data of 'item'">
            <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
               {{data?.dateWithDaysToExpiration}}
            </span>
         </div>
      </dx-select-box>
    </div>

    <div class="cp-item load">
      <dx-button type="default" text="Load Chain" width="122px"
                  (onClick)="loadChain()"
                  [disabled]="!underlying || (numOfStrikes || 0) === 0 || (!loadCalls && !loadPuts) || !this.expiration"
      ></dx-button>
    </div>
  </div>

  <div class="separator">
    <hr/>
  </div>
  
  <div class="board">
    
    <ag-grid-angular  style="width: 100%; height: 100%;"
                      class="ag-theme-balham-dark"
                      [gridOptions]="optionsGridModel"></ag-grid-angular>

  </div>

</div>

<dx-load-panel  [position]="{ my: 'center', at: 'center', of: container }" 
                [visible]="isLoading"
                [message]="loadingMessage"
></dx-load-panel>

<dx-popup
            #customStrikeDialog
            title="Custom Strike"
            [width]="240"
            [height]="120"
            [showTitle]="true"
            [closeOnOutsideClick]="false"
            [resizeEnabled]=""
            [dragEnabled]="true"
>
  <div *dxTemplate="let data of 'content'">
    <div class="custom-strike">
      <div class="strike">
        <dx-number-box [(value)]="customStrikePrice"></dx-number-box>
      </div>
      <div class="buttons">
        <dx-button text="OK" (onClick)="onAddCustomStrikeClicked()" width="80px"></dx-button>
      </div>
    </div>
  </div>
</dx-popup>