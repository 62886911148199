<div class="working-orders">
   <div class="header">
      <ets-section-header
         [header]="this.header"
         [collapsible]="true"
         (onCollapsedChanged)="this.onSectionCollapsedChanged($event)"
         >
      </ets-section-header>
   </div>
   <div class="content" [style.height.px]="this.sectionHeight">
      <ag-grid-angular
      class="ag-theme-balham-dark grid"
         [gridOptions]="this.gridOptions">
      </ag-grid-angular>
   </div>
</div>