import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {SimexSettingsDto} from '../../dtos/simex-settings-dto.class';

/**
 * @returns SimexSettingsDto
 */
export class GetSimexSettings implements ProtocolQuery {
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSimexSettings`;
  }
}

