import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   EventEmitter,
   Input,
   OnInit,
   Output,
   ViewChild
} from '@angular/core';
import { SettingsSectionModel } from '../model/SettingsSectionModel';
import {ClipboardService} from "../../clipboard.service";
import {ApgContextMenuComponent} from "../context-menu/apg-context-menu.component";


@Component({
   selector: 'ets-adjustment-grid-settings-section',
   templateUrl: './adjustment-grid-settings-section.component.html',
   styleUrls: [
      './adjustment-grid-settings-section.component.scss',
      '../adjustment-grid-common-style.scss',
   ],
})
export class AdjustmentGridSettingsSectionComponent implements OnInit, AfterViewInit {

   constructor(
       private readonly _changeDetector: ChangeDetectorRef,
       private readonly _clipboardService: ClipboardService
   ) {
   }


   @Input()
   viewModel: SettingsSectionModel;


   @Input()
   orientation: 'left' | 'right';


   @Output()
   onToggleCollapsed = new EventEmitter();


   @ViewChild(ApgContextMenuComponent)
   ctxMenu: ApgContextMenuComponent;


   ngOnInit(): void {

   }


   ngAfterViewInit(): void {
      this.viewModel.ctxMenu = this.ctxMenu;
   }


   toggleCollapsed() {
      if (!this.viewModel) {
         return;
      }
      this.viewModel.toggleCollapsed();
   }
}
