import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { AccessControlService } from '../access-control-service.class';

const DASHBOARD = EtsConstants.companyServices.etsDashboardApplicationId;
const WEBTRADER = EtsConstants.companyServices.etsWebTraderApplicationId;

@Injectable({ providedIn: 'root' })
export class TradingSystemsSecurityContextService {
   constructor(
      private readonly _accessControl: AccessControlService
   ) { }

   get addStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         const isEditSystemAvailable = this._accessControl.isSecureElementAvailable('f6e88e3d-308f-4dfc-b73c-5e4c44da3f01');
         return this._accessControl.isSecureElementAvailable('6243397a-ed79-45d8-87aa-e4990a3e0e04')
            && isEditSystemAvailable;
      }
      return false;
   }

   get updateStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         const isEditSystemAvailable = this._accessControl.isSecureElementAvailable('f6e88e3d-308f-4dfc-b73c-5e4c44da3f01');
         return this._accessControl.isSecureElementAvailable('80d450e1-e421-46fc-96c9-57b10e3019ad')
            && isEditSystemAvailable;
      }
      return false;
   }

   get startStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('bbd8aad7-a5bb-4e08-a797-343ba07a76f6');
      }
      return false;
   }

   get pauseStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('e309582d-102b-450f-8434-979c0630ce80');
      }
      return false;
   }

   get stopStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('ed380dd9-6a47-46e4-81c4-f9ebdc7ecd8c');
      }
      return false;
   }

   get removeStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('e0183e61-9ec7-4128-ad6d-fdc3e2d61ea1');
      }
      return false;
   }

   get setToStart(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('06f9f3f7-d129-448a-8c34-74c1ca183554');
      }
      return false;
   }

   get exitStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('c30259d8-cec5-4183-a0e9-2d2f5f518742');
      }
      return false;
   }

   get overrideExitStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('1efa3504-8b07-4fa8-8a4d-93fb04eff56d');
      }
      return false;
   }

   get overrideResetStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('0c94f722-adbd-4e69-8fa2-e4f9769fd751');
      }
      return false;
   }

   get overridePosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('8f602b40-f7c6-4758-90bb-a7b5fb45ab2a');
      }
      return false;
   }

   get duplicateStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('e80e23c7-aeb8-432a-8455-ee3de4ff46e0');
      }
      return false;
   }

   get clearTradingData(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('64c61492-0d6b-4164-a1be-188a4a3cba16');
      }
      return false;
   }

   get systemDetails(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('f715010f-d4e5-4783-961c-9764bf79e47f');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('2690745d-9d05-4933-b528-b9445ff346ab');
      }
      return false;
   }

   get strategyIssues(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('5d8058f5-18f9-492c-97fb-d2d4990d2e56');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('cc771c08-2d80-46e3-880b-641eea6f65b6');
      }
      return false;
   }

   get rollNextMonth(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('f1906944-8621-4355-9087-8ab2d1f819dd');
      }
      return false;
   }

   get archiveStrategyPosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('a411ce6a-170d-4660-9619-d809e9df6e0b');
      }
      return false;
   }

   get customPriceStartStrategy(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('0ea40aff-9e1c-4740-8237-e1926f017977');
      }
      return false;
   }

   get freezeUnfreeze(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('a03f27de-6cd5-4543-abf0-0dbbc0496410');
      }
      return false;
   }

   get strategyHistory(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('ad6d3d04-65f9-4f32-9319-360faca42411');
      }
   }

   get customExits(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }

      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('b1435927-05c1-4d7d-b3d0-d6ffc13dab83');
      }
   }
}
