import { Component, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { ParametersViewBase } from '../parameters-view-base';
import { RemoteAlgoParametersControl } from './remote-algo-parameters-control';

@Component({
  selector: 'ets-remote-algo-parameters-view',
  templateUrl: './remote-algo-parameters-view.component.html',
  styleUrls: ['./remote-algo-parameters-view.component.scss']
})
export class RemoteAlgoParametersViewComponent  extends ParametersViewBase<RemoteAlgoParametersControl> {
  
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;

   onEtsInit() {
   
   }
   
   onEtsAfterViewInit() {
   }

}
