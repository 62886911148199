import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { TimestampsService } from '../timestamps.service';
import { TimeZoneDescriptor } from '../timezones/time-zone-descriptor.interface';
import { TIME_ZONE_FAMILIES } from '../timezones/time-zones.const';
import { DetectMethodChanges, DetectSetterChanges, groupTimezoneFamilies } from '../utils';

@Component({
   selector: 'ets-timezone-picker',
   templateUrl: 'timezone-picker.component.html',
   styleUrls: ['timezone-picker.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimezonePickerComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _timestampsService: TimestampsService
   ) {
      this.timezonesCountriesList = groupTimezoneFamilies();
   }

   @Input() flow: 'col' | 'row' = 'row';

   @Input() showCaption = true;
   
   @Input() showBorder = true;

   @Input() disabled = false;

   timezonesCountriesList: any[];

   timezonesByCountry: TimeZoneDescriptor[] = [];   
   
   private _timezoneCountry: string;
   get timezoneCountry(): string {
      return this._timezoneCountry;
   }

   @DetectSetterChanges()
   set timezoneCountry(v: string) {
      
      if (this._timezoneCountry === v) {
         return;
      }

      this._timezoneCountry = v;
      
      if (isNullOrUndefined(v)) {
         this.timezonesByCountry = [];
      } else {
         const fam = TIME_ZONE_FAMILIES.find(x => x.displayName === v);
         this.timezonesByCountry =  fam.timeZoneDescriptors;
      }

      this.timezone = null;
   }

   private _timezone: string;
   get timezone(): string {
      return this._timezone;
   }

   @Input()
   set timezone(value: string) {

      if (this._timezone === value) {
         return;
      }
      
      if (isNullOrUndefined(value) || !value || value.indexOf('UTC') >= 0) {
         
         this.displayTimezone = null;
         this.timezoneCountry = null;

      } else {

         const fam = TIME_ZONE_FAMILIES.find(x => x.timeZoneDescriptors.some(y => y.nodaTimeZoneId === value));
         const tz = fam.timeZoneDescriptors.find(x => x.nodaTimeZoneId === value);

         if (this.timezoneCountry !== fam.displayName) {
            this.timezoneCountry = fam.displayName;
         }

         this.displayTimezone = tz ? tz.displayName : null;

         setTimeout(() => this._changeDetector.detectChanges(), 100);
      }

      this._timezone = value;
      this.timezoneChange.emit(this.timezone);
   }
   
   @Output() timezoneChange = new EventEmitter<string>();

   private _displayTimezone: string;
   get displayTimezone(): string {
      if (isNullOrUndefined(this._displayTimezone)) {
         return 'Pick timezone...';
      }
      return this._displayTimezone;
   }

   @DetectSetterChanges()
   set displayTimezone(v: string) {
      this._displayTimezone = v;
   }
   

   @DetectMethodChanges()
   ngOnInit() {
      if (!this.timezone) {
         const defaultTimezone = this._timestampsService.getDefaultTimezone();
         if (defaultTimezone) {
            this.timezone = defaultTimezone;
         }
      }
    }

   @DetectMethodChanges()
   reset() {
      this.timezoneCountry = null;
      this.timezone = null;
   }
}
