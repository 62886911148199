import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ComparisonPricingSettingsDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges } from 'projects/shared-components/utils';

type PricingMode = 'Better' | 'Breakeven Or Better';

@Component({
   selector: 'ets-automation-cp-comparison-pricing-settings',
   templateUrl: './comparison-pricing-settings.component.html',
   styleUrls: [
      './comparison-pricing-settings.component.scss',
      '../common-fieldset.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonPricingSettingsComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }

   //
   expirationsLookForward: number;

   //
   pricingModes: PricingMode[] = [
      'Better',
      'Breakeven Or Better'
   ];

   //
   pricingMode: PricingMode;

   //
   pricingBuffer: number;

   //
   differentialBuffer: number;

   ngOnInit(): void { }
   
   @DetectMethodChanges()
   setData(dto: ComparisonPricingSettingsDto) {
      this.expirationsLookForward = dto.expirationsLookForward;
      this.pricingMode = dto.pricingMode;
      this.pricingBuffer = dto.priceBuffer;
      this.differentialBuffer = dto.differentialBuffer;
   }

   getParameters(): ComparisonPricingSettingsDto {
      return {
         expirationsLookForward: this.expirationsLookForward,
         pricingMode: this.pricingMode,
         priceBuffer: this.pricingBuffer,
         differentialBuffer: this.differentialBuffer
         
      }
   }

   @DetectMethodChanges()
   reset() {
      this.expirationsLookForward = undefined;
      this.pricingMode = undefined;
      this.pricingBuffer = undefined;
      this.differentialBuffer = undefined;
   }
}
