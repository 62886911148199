<div class="content">

   <div class="column filters">
      <!-- Date -->
      <fieldset>
         <legend>Date</legend>
         <ets-datetime-picker placeholder="From / After" mode="datetime" [showTimezone]="false"
            [(value)]="this.dateFilter.dateFrom" (valueChanged)="this.onDateFilterChanged()">
         </ets-datetime-picker>
         <ets-datetime-picker placeholder="To / Before" mode="datetime" [showTimezone]="false"
            [(value)]="this.dateFilter.dateTo" (valueChanged)="this.onDateFilterChanged()">
         </ets-datetime-picker>
         <div class="timezone">
            <ets-timezone-picker [(timezone)]="this.dateFilter.timezone" [flow]="'col'"
               [showBorder]="false"></ets-timezone-picker>
         </div>
      </fieldset>

      <!-- Domain -->
      <fieldset>
         <legend>Domain</legend>
         <dx-select-box [items]="this.domainFilter.domains" [(value)]="this.domainFilter.domain"
            [showClearButton]="true" [searchEnabled]="true"
            (onValueChanged)="this.onDomainFilterChanged()"></dx-select-box>
      </fieldset>

      <!-- Source -->
      <fieldset>
         <legend>Source</legend>
         <dx-select-box [items]="this.sourceFilter.sources" [(value)]="this.sourceFilter.source"
            [showClearButton]="true" [searchEnabled]="true" (onValueChanged)="this.onSourceFilterChanged()">
         </dx-select-box>
      </fieldset>

      <!-- Tags -->
      <fieldset>
         <legend>Tags</legend>
         <dx-select-box [items]="this.tagsFilter.tags" [(value)]="this.tagsFilter.tag" [showClearButton]="true"
            [searchEnabled]="true" (onValueChanged)="this.onTagsFilterChanged()">
         </dx-select-box>
      </fieldset>

      <!-- All Unread -->
      <div class="unread">
         <dx-check-box text="Unread Only" [(value)]="this.unreadFilter"
            (onValueChanged)="this.onUnreadFilterChanged($event)"></dx-check-box>
         <dx-button text="Clear All" (onClick)="this.clearAllFilters()"></dx-button>
      </div>
   </div>

   <div class="column messages">
      <as-split class="split" direction="horizontal" [gutterSize]="5">

         <as-split-area order="0" [size]="this.messageListSectionSize">
            <div class="section search">
               <dx-text-box placeholder="Search Box" [(value)]="this.messageFilter.searchString"
                  [showClearButton]="true" (onValueChanged)="this.onMessageFilterChanged($event)"></dx-text-box>
            </div>

            <div class="section grid">
               <ag-grid-angular #portfolios class="ag-theme-balham-dark" style="width: 100%; height: 100%;"
                  [gridOptions]="this.sessionMessagesGridOptions"></ag-grid-angular>
            </div>
         </as-split-area>

         <as-split-area order="2" [size]="this.readingPanelSectionSize">
            <dx-html-editor [value]="this.extendedMessage" [readOnly]="true"></dx-html-editor>
            <!-- <dx-text-area [readOnly]="true" height="100%" width="100%" [value]="this.extendedMessage"></dx-text-area> -->
         </as-split-area>

      </as-split>
   </div>

</div>