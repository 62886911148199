import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StrategyState } from 'projects/shared-components/strategies/strategy-state.enum';

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'ets-simple-group-row-renderer',
   template: `
    <div class="wrapper">
      <div class="block">
        <span [ngClass]="{ 
                            'traded': this.rendererParams.node.key === 'true', 
                            'not-traded': this.rendererParams.node.key === 'false'
                          }">
            {{title}}
        </span>
      </div>
    </div>
  `,
   styles: [
      `
      .wrapper {
        display: inline-block;
        width: 100%;
        text-align: left;
      }

      .block {
        display: inline-block;
      }

      .traded {
        color: yellow;
      }

      .not-traded {
        color: gray;
      }
    `
   ]
})
// tslint:disable-next-line: component-class-suffix
export class SimpleHeaderGroupRowInnerRenderer implements ICellRendererAngularComp {
   constructor() { }

   rendererParams: ICellRendererParams;

   get title(): string {
      const field = this.rendererParams.node.field;

      const key = this.rendererParams.node.key;

      let title = key;

      switch (field) {
         case 'isSession':
            title = key === 'true' ? 'Session' : 'Position';
            break;
         case 'state':
            title = StrategyState[title];
            break;
         case 'hasTradedThisSession':
            title = key === 'true' ? 'Traded Today' : 'Not Traded Today';
            break;
         default:
            break;
      }

      return title;
   }

   
   
   agInit(params: ICellRendererParams): void {
      this.rendererParams = params;
   }



   refresh(): boolean {
      return false;
   }
}
