export enum PositionFlags {
   None = 0,
   LastOverSessionReset = 1 << 0
}

export function positionHasFlag(position: PositionDto, flag: PositionFlags): boolean {
   return (position.flags & flag) === flag;
 }

export class PositionDto {
   positionId: string;
   strategyId: string;
   accountCode: string;
   ticker: string;
   netPosition: number;
   avgPx: number;
   sessionRealizedPnL: number;
   sessionUnrealizedPnL: number;
   maxSessionPnL: number;
   maxAccumulatedPnL: number;
   sessionTradeTotalPnL: number;
   sessionTotalPnL: number;
   sessionPerfTotalPnL: number;
   marketSide: number;
   lastMatchPx: number;
   accountId: string;
   terminalId: string;
   terminalCode: string;
   accumulatedTotalPnL: number;
   isArchived: boolean;
   strategyName: string;
   isSync: boolean;
   shellId: string;
   clientId: string;
   shellName: string;
   clientName: string;
   seqNum: number;
   lastMarketQuote: number;
   dispositionId: string;
   isInnerStrategyPosition: boolean;
   ownerLabel: string;
   settledRolloverAdjustment: number;
   portfolioId: string;
   underlying: string;
   comboName: string;
   tickerDisplayName: string;
   delta: number;
   gamma: number;
   vega: number;
   theta: number;
   comboId: string;
   liveQuote: number;
   portfolioName: string;
   comboGroupId: string;
   comboGroupName: string;
   hedgeStrategyId: string;
   flags: PositionFlags;
   openPrice?: number;
   lastTraded: Date;
}
