import { isNullOrUndefined } from "util";
import { CashFlowAdjustment } from "./CashFlowAdjustment";
import { CashFlowStrategyAdjustmentsObject } from "./CashFlowStrategyAdjustmentsObject";
import {DetectMethodChanges, isVoid} from "projects/shared-components/utils";
import * as Enumerable from "linq";

export class StrategiesSectionModel {
   
   constructor() {
   }

   private _strategies: CashFlowStrategyAdjustmentsObject[];

   //
   get strategies(): CashFlowStrategyAdjustmentsObject[] {
      return this._strategies || [];
   }

   //
   setData(adjustments: CashFlowAdjustment[]) {
      const strategyObjects = this.makeDataModel(adjustments);
      this._strategies = strategyObjects;  
   }


   reset() {
      this._strategies = [];
   }

   //
   getStrategyColWidth(strategy: CashFlowStrategyAdjustmentsObject, colNum = 0) {

      if (isNullOrUndefined(strategy.columnWidth)) {
         strategy.columnWidth = strategy.adjustmentTypes && strategy.adjustmentTypes.length
            ? Math.floor(100 / (strategy.adjustmentTypes.length + 1 + colNum)) + '%'
            : 'auto';
      }

      return strategy.columnWidth;
   }

   private makeDataModel(adjustments: CashFlowAdjustment[]): CashFlowStrategyAdjustmentsObject[] {
      const singleAdjustments = adjustments.filter(x => isVoid(x.coupleTag));
      
      if (singleAdjustments.length == 0) {
         return [];
      }

      const objects = Enumerable.from(singleAdjustments)
         .groupBy(adj => adj.cashFlowStrategy)
         .select(adj => {
            const obj = new CashFlowStrategyAdjustmentsObject();
            obj.setData(adj.toArray());
            return obj;
         })
         .toArray();

      return objects;
   }
}
