import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged, isValidNumber, isVoid } from 'projects/shared-components/utils';

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday';

@Component({
   selector: 'ets-checklist-day-of-week-condition',
   template: `
<div class="container">
   <dx-drop-down-box
      [disabled]="this.disabled"
      [dropDownOptions]="{width: 300, left: -86, top: -3}"
      [value]="this.dayOfWeek"
      [acceptCustomValue]="true"
      fieldTemplate="field"
      (onOpened)="this.onOpened()"
      (onClosed)="this.onClosed()"
      >
      <div *dxTemplate="let data of 'field'">
         <dx-text-box 
            [value]="this.dayOfWeek" 
            [readOnly]="true"
            (onValueChanged)="this.onValueChanged($event)"
            >
         </dx-text-box>
      </div>
      <div *dxTemplate="let data of 'content'">
         <dx-select-box
            [items]="this.items"
            [(value)]="this.dayOfWeek"
            [showClearButton]="true"
            >
         </dx-select-box>
         <ets-timezone-picker
            [(timezone)]="this.timezone"
            [flow]="'col'">
         </ets-timezone-picker>
      </div>
   </dx-drop-down-box>
   
</div>
   `,
   styles: [``],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistDayOfWeekConditionComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   @Input() disabled: boolean;

   items: DayOfWeek[] = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday'
   ];

   private _dayOfWeek: DayOfWeek;
   get dayOfWeek(): DayOfWeek {
      return this._dayOfWeek;
   }

   @DetectSetterChanges()
   set dayOfWeek(value: DayOfWeek) {
      this._dayOfWeek = value;
   }

   timezone: string;

   get value(): string {
      if (!this._dayOfWeek || !this.timezone) {
         return undefined;
      } 
      const val = `${this._dayOfWeek}|${this.timezone}`;
      return val;
   }

   @Input()
   set value(value: string) {

      if (isVoid(value)) {
         this.reset();
         return;
      }

      if (value.indexOf('|') < 0) {
         this.reset();
         return;
      }

      const parts = value.split('|');

      this.dayOfWeek = parts[0] as any;
      this.timezone = parts[1];
   }

   @Output()
   valueChange = new EventEmitter<string>();

   @Output() valueChanged = new EventEmitter<DxValueChanged<string>>();

   @DetectMethodChanges()
   ngOnInit(): void { }

   onValueChanged(arg: DxValueChanged<DayOfWeek>) {
      if (isVoid(this.timezone)) {
         return;
      }
      this.valueChange.emit(arg.value);
      this.valueChanged.emit(arg);
   }

   private _oldValue: string;
   onOpened() {
      this._oldValue = this.value;
   }

   onClosed() {
      if (this.value === this._oldValue) {
         return;
      }

      if (isVoid(this.timezone)) {
         return;
      }
      this.valueChange.emit(this.value);
      this.valueChanged.emit({value: this.value, event: 'ets'});
   }

   private reset() {
      this.dayOfWeek = undefined;
      this.timezone = undefined;
   }
}
