import { StrategyOrdersAndTradesComponent } from './strategy-orders-and-trades.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultTimestampFormat,
   defaultLoadingOverlayTemplate,
   getMasterSymbolColumn
} from 'projects/shared-components/ag-grid-contrib';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { formatDate, formatCurrency } from '@angular/common';
import { TradeDto, tradeHasFlag, TradeFlags } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { OrderType } from '../trading-model/order-type.enum';
import { TimestampsService } from '../timestamps.service';
import { isCashSettledOptionTicker } from '../utils';

const pnlColumnDefinitionBase = {
   valueFormatter: (params: ValueFormatterParams) => {
      const v = Math.abs(params.value);
      return formatCurrency(v || 0, 'en-US', '$', 'USD', '0.0-2');
   },

   cellStyle: (params: any) => {

      const trade: TradeDto = params.node.data;

      const isNpo = tradeHasFlag(trade, TradeFlags.TradeNpo) || tradeHasFlag(trade, TradeFlags.AccumulatedNpo) || tradeHasFlag(trade, TradeFlags.SessionNpo);
      const isSl = tradeHasFlag(trade, TradeFlags.TradeStopLoss) || tradeHasFlag(trade, TradeFlags.AccumulatedStopLoss) || tradeHasFlag(trade, TradeFlags.SessionStopLoss);

      if (isNpo || isSl) {
         return { color: 'black' };
      }


      if (params.value > 0) {
         return { color: 'green' };
      }

      if (params.value < 0) {
         return { color: 'red' };
      }

      return { color: '' };
   }
};


export function getStrategyTradesGridModel(
   this: StrategyOrdersAndTradesComponent,
   displayNameService: TradingInstrumentDisplayNameService,
   timestampsService: TimestampsService
): GridOptions {

   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         getMasterSymbolColumn('tradingInstrumentCode', this.messageBus, this.timestampsService, this.unsubscriber, () => this.tradesGridApi),

         {
            headerName: 'Exp. Style',
            field: 'sourceOrderExpirationStyle',
            hide: true
         },
         
         {
            headerName: 'Qty',
            field: 'qty',
            valueGetter(params: ValueGetterParams) {
               const data: TradeDto = params.data;
               return data.qty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },

         {
            headerName: 'Price',
            field: 'execPrice',
            valueFormatter: defaultQuoteCellFormatter
         },

         {
            headerName: 'Buy/Sell',
            field: 'side',
            valueFormatter: (params: ValueFormatterParams) => {
               return MarketSide[params.value];
            },
            filter: true
         },

         {
            headerName: 'Order Type',
            field: 'sourceOrderType',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderType[params.value];
            },
            filter: true
         },

         Object.assign(
            { headerName: 'P&L', field: 'pnL' },
            pnlColumnDefinitionBase
         ),

         Object.assign(
            { headerName: 'Perf. P&L', field: 'perfectPnL' },
            pnlColumnDefinitionBase
         ),

         Object.assign(
            { headerName: 'Trade P&L', field: 'tradePnL' },
            pnlColumnDefinitionBase
         ),

         Object.assign(
            { headerName: 'Acc. P&L', field: 'accumulatedPnL' },
            pnlColumnDefinitionBase
         ),

         Object.assign(
            { headerName: 'Acc. Perf. P&L', field: 'accumulatedPerfectPnL' },
            pnlColumnDefinitionBase
         ),

         Object.assign(
            { headerName: 'Acc. Trade P&L', field: 'accumulatedTradePnL' },
            pnlColumnDefinitionBase
         ),

         {
            headerName: 'Account',
            field: 'accountCode',
            filter: true
         },

         {
            headerName: 'Terminal',
            field: 'terminalCode',
            filter: true
         },

         {
            headerName: 'Timestamp',
            field: 'timestamp',
            valueFormatter: (params: ValueFormatterParams) => {
               const trade: TradeDto = params.data;
               const frmtTime = timestampsService.getFormattedDateTimeForStrategy(trade.strategyId, trade.timestamp);
               return trade.timestamp
                  ? frmtTime
                  : '';
            },
         },

         {
            headerName: 'Trade Id',
            field: 'tradeId',
            filter: 'agTextColumnFilter'
         },

         {
            headerName: 'Order Id',
            field: 'orderId',
            filter: 'agTextColumnFilter'
         },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      onGridReady: (args) => this.onTradesGridReady(args),

      onGridSizeChanged: (args: GridSizeChangedEvent) => {
         if (args.clientHeight === 0 || args.clientWidth === 0) {
            return;
         }
         this.onTradesGridSizeChanged(args);
      },

      getRowNodeId: (rowData: TradeDto) => rowData.tradeId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Load More ...',
               disabled: !this.contextStrategyId,
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadMoreTrades(50)
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreTrades(100)
                  },
                  {
                     name: '500',
                     action: () => this.loadMoreTrades(500)
                  },
                  {
                     name: '1000',
                     action: () => this.loadMoreTrades(1000)
                  }
               ]
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      getRowStyle: (args) => {
         const data: TradeDto = args.data;
         if (!data) {
            return undefined;
         }

         if (data.isProxy) {
            return { background: 'yellow', color: 'black' };
         }

         if (data.flags === 0) {
            return undefined;
         }

         const style = { background: '', color: 'black' };

         if (tradeHasFlag(data, TradeFlags.TradeNpo) || tradeHasFlag(data, TradeFlags.AccumulatedNpo)
            || tradeHasFlag(data, TradeFlags.SessionNpo) || tradeHasFlag(data, TradeFlags.CustomExitNpo)) {
            style.background = 'green';
            return style;
         }

         if (tradeHasFlag(data, TradeFlags.TradeStopLoss) || tradeHasFlag(data, TradeFlags.AccumulatedStopLoss)
            || tradeHasFlag(data, TradeFlags.SessionStopLoss) || tradeHasFlag(data, TradeFlags.CustomExitStopLoss)) {
            style.background = 'red';
            return style;
         }

         return undefined;
      }
   } as GridOptions;
}
