import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import {BeforePositionDto} from "../../../adjustment-pricing-grid/model/BeforePositionDto";
import {DetectMethodChanges, isVoid, makeGuiFriendlyExpirationDate} from "../../../utils";
import {parseOptionTicker} from "../../../options-common/options.model";
import {ApgDataService} from "../../../adjustment-pricing-grid/services/apg-data.service";

@Component({
    selector: 'ets-hg-portfolio-positions',
    templateUrl: 'hg-portfolio-positions.component.html',
    styleUrls: [
        './hg-portfolio-positions.component.scss',
        '../../hedging-grid-common-styles.scss'
    ]
})

export class HgPortfolioPositionsComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _apgDataService: ApgDataService
    ) {
    }

    portfolioPositions: any[][];

    ngOnInit() {

    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: ApgPortfolio) {

        const positions
            = await this._apgDataService.getPortfolioPositions(args);

        this.portfolioPositions = positions;
    }

    private _cache = {};

    getHumanFriendlyOptionTicker(leg: BeforePositionDto) {

        let result = this._cache[leg.ticker];

        if (isVoid(result)) {
            const optionTicker = parseOptionTicker(leg.ticker);

            if (!isVoid(optionTicker)) {
                const underlying = optionTicker.underlying;
                const type = optionTicker.type;
                const expiration = makeGuiFriendlyExpirationDate(optionTicker.expiration);
                const strike = optionTicker.strike;

                result = `${underlying}  ${type}  ${expiration}  ${strike}`;

                this._cache[leg.ticker] = result;
            }
        }

        return result || '! BAD OPTION CONTRACT TICKER !';
    }

    getRoleClass(pos: BeforePositionDto) {
        switch (pos.role) {
            case "ShortOption":
                return 'colorcode-short-option';
            case "SpreadLongLeg":
            case "SpreadShortLeg":
                return 'colorcode-spread';
            case "SecondSpreadLongLeg":
            case "SecondSpreadShortLeg":
                return 'colorcode-second-spread';
                break;
            case "ProtectiveOption":
            case "SecondProtectiveOption":
                return 'colorcode-protective-option';
                break;

        }
    }
}