import {formatCurrency} from '@angular/common';
import {isNullOrUndefined} from 'util';
import {LastQuoteCacheService} from '../last-quote-cache.service';
import {makeOptionTicker, OptionType} from '../options-common/options.model';
import {QuoteDto} from '../shell-communication/dtos/quote-dto.class';
import {OptionExpirationDescriptor} from '../shell-communication/shell-dto-protocol';
import {TradingInstrument} from '../trading-instruments/trading-instrument.class';
import {MarketSide} from '../trading-model/market-side.enum';
import {findHCF, getShortUUID} from '../utils';

export class PricingPadLeg {

   constructor(public readonly underlying: TradingInstrument, isSeparator?: boolean) {
      this.linkId = getShortUUID();
      this.isSeparator = isSeparator;
   }

   private _ticker;
   private _strike: number;
   private _optionType: OptionType;
   private _expiration: OptionExpirationDescriptor;

   linkId: string;

   expectedStrike: number;

   get optionType(): OptionType {
      return this._optionType;
   }

   set optionType(v: OptionType) {
      if (this._optionType === v) {
         return;
      }
      this._optionType = v;
      this._ticker = undefined;
   }

   get expiration(): OptionExpirationDescriptor {
      return this._expiration;
   }

   set expiration(v: OptionExpirationDescriptor) {
      if (v === this._expiration) {
         return;
      }
      this._expiration = v;
      this._ticker = undefined;
   }

   get strike(): number {
      return this._strike;
   }

   set strike(v: number) {
      if (v === this._strike) {
         return;
      }
      this._strike = v;
      this._ticker = undefined;
   }

   marketSide?: MarketSide;
   isStock: boolean;
   qty?: number;
   bid?: number;
   ask?: number;
   price: number;
   isSeparator: boolean;

   get marketSideString(): string {
      return MarketSide[this.marketSide];
   }

   get optionTypeString(): string {
      if (this.isStock) {
         return null;
      }

      return OptionType[this.optionType];
   }

   get ticker(): string {

      if (this.isStock) {
         return this.underlying.ticker;
      }

      if (!this.strike || !this.optionType || !this.expiration) {
         return undefined;
      }

      if (!this._ticker) {
         this._ticker = makeOptionTicker(this.expiration, this.optionType, this.strike, 'American');
      }

      return this._ticker;
   }


   isValid(): boolean {
      let isValid = false;

      if (this.isStock) {

         isValid = !isNullOrUndefined(this.ticker);

      } else {

         isValid = !isNullOrUndefined(this.strike) && !isNaN(this.strike) && !isNullOrUndefined(this.expiration)
            && !isNullOrUndefined(this.optionType) && !isNullOrUndefined(this.ticker) && !isNullOrUndefined(this.marketSide);

      }

      return isValid;

   }

}


export class PricingPadStrategy {

   constructor(
      legs: PricingPadLeg[],
      public readonly expiration: OptionExpirationDescriptor,
      public readonly underlying: TradingInstrument,
      private readonly _lastQuoteCache: LastQuoteCacheService
   ) {
      if (legs && legs.length > 0) {
         this.setLegs(legs);
      }

      const dateParts = this.expiration.optionExpirationDisplayDate.split(' ');
      if (dateParts.length >= 2) {
         this.expirationDay = dateParts[0];
         this.expirationDate = dateParts[1];
      }
   }


   marketSideList: string[] = [
      'Buy',
      'Sell'
   ];

   optionTypes: string[] = [
      'Call',
      'Put',
   ];

   legs: PricingPadLeg[] = [];

   get sortedLegs(): PricingPadLeg[] {
      return this.legs.slice().sort(  (x, y) => y.strike - x.strike );
   }

   getLegs(enableAutoSorting: boolean) {
      if (enableAutoSorting) {
         return this.sortedLegs;
      }
      return this.legs;
   }

   tickers: string[] = [];

   strategyCode: string;

   price: number;

   expirationDay: string;

   expirationDate: string;

   get expirationPriceColorObj() {
      const obj = { style: '' };
      if (this.price > 0) {
         obj.style = 'color: red';
      } else if (this.price < 0) {
         obj.style = 'color: green';
      } else {
         obj.style = 'color: ';
      }

      return obj;
   }

   get expirationPriceColor(): string {
      if (this.price > 0) {
         return 'red';
      }

      if (this.price < 0) {
         return 'green';
      }

      return undefined;
   }

   get expirationPriceString(): string {
      if (!this.expiration) {
         return;
      }

      if (!this.expiration.optionExpirationDisplayDate) {
         return;
      }

      let str = this.expiration.optionExpirationDisplayDate;

      if (this.price) {
         const pr = formatCurrency(Math.abs(this.price), 'en-US', '$');
         str += ` / ${pr}`;
      } else {
         str += ` / $--,--`;
      }

      return str;
   }

   get totalCost(): number {
      const hcf = findHCF(this.legs.map(x => Math.abs(x.qty)));
      return Math.abs(this.price) * 100 * hcf;
   }

   getStrategyCodeAndResetIt(): any {
      const sc = this.strategyCode;
      this.strategyCode = undefined;
      this.price = undefined;
      return sc;
   }

   clearLegs(): void {
      this.legs.length = 0;
      this.tickers = [];
      this.strategyCode = undefined;
      this.price = undefined;
   }


   setLegs(newLegs: PricingPadLeg[]): void {

      this.legs.length = 0;

      newLegs.forEach(x => {

        const aLeg = new PricingPadLeg(x.underlying);
        aLeg.linkId = x.linkId;
        aLeg.isStock = x.isStock;
        aLeg.isSeparator = x.isSeparator;
        aLeg.marketSide = x.marketSide;
        aLeg.qty = x.qty;
        aLeg.expiration = this.expiration;
        aLeg.strike = x.strike;
        aLeg.expectedStrike = x.expectedStrike;
        aLeg.optionType = x.optionType;

        if (this.expiration.strikes.indexOf(x.strike) < 0) {
           aLeg.strike = undefined;
        }

        this.legs.push(aLeg);
      });

      this.price = undefined;
      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   onQuote(quotes: QuoteDto[]): boolean {
      let detectChanges = false;

      this.legs.forEach(leg => {

         const quote = quotes.find(q => q.ticker === leg.ticker);

         if (quote) {
            leg.bid = quote.bid;
            leg.ask = quote.ask;

            const price = quote.mid;

            leg.price = isNaN(price) || price < Number.EPSILON ? undefined : price;

            detectChanges = true;
         }
      });

      return detectChanges;
   }

   addLeg(templateLeg: PricingPadLeg) {
      if (templateLeg.isStock && this.legs.find(x => x.isStock)) {
         return;
      }

      const leg = new PricingPadLeg(this.underlying);
      leg.linkId = templateLeg.linkId;
      leg.isStock = templateLeg.isStock;
      leg.marketSide = templateLeg.marketSide;
      leg.qty = templateLeg.qty;
      leg.expiration = this.expiration;
      leg.strike = templateLeg.strike;
      leg.expectedStrike = templateLeg.expectedStrike;
      leg.optionType = templateLeg.optionType;
      leg.price = templateLeg.price;

      if (leg.isStock) {
         this.legs.unshift(leg);
      } else {
         this.legs.push(leg);
      }

      this.price = undefined;

      if (leg.isValid()) {
         this.strategyCode = this.makeStrategyCode(this.legs);
      } else {
         this.strategyCode = undefined;
      }

      this.tickers = this.makeTickers(this.legs);
   }

   addEmptyLeg(legType: 'asset' | 'option'): PricingPadLeg {

      if (legType === 'asset' && this.legs.find(x => x.isStock)) {
         return;
      }

      const leg = new PricingPadLeg(this.underlying);
      leg.expiration = this.expiration;
      leg.isStock = legType === 'asset';

      if (leg.isStock) {
         this.legs.unshift(leg);
      } else {
         this.legs.push(leg);
      }

      this.price = undefined;
      this.strategyCode = undefined;
      this.tickers = this.makeTickers(this.legs);

      return leg;
   }


   removeLeg(legLinkId: string): PricingPadLeg[] {
      const ix = this.legs.findIndex(l => l.linkId === legLinkId);

      console.assert(ix >= 0, 'leg not found');

      if (ix < 0) {
         return;
      }

      const removed = this.legs.splice(ix, 1);

      this.price = undefined;
      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);

      return removed;
   }

   duplicateLeg(leg: PricingPadLeg) {
      const dupLeg = new PricingPadLeg(leg.underlying);
      dupLeg.marketSide = leg.marketSide;
      dupLeg.qty = leg.qty;
      dupLeg.expiration = leg.expiration;
      dupLeg.strike = leg.strike;
      dupLeg.expectedStrike = leg.expectedStrike;
      dupLeg.optionType = leg.optionType;
      dupLeg.price = leg.price;

      const ix = this.legs.indexOf(leg);
      if (ix >= 0) {
         if (ix === this.legs.length - 1) {
            this.legs.push(dupLeg);
         } else {
            this.legs.splice(ix, 0, dupLeg)
         }
      }

      this.price = undefined;

      if (dupLeg.isValid()) {
         this.strategyCode = this.makeStrategyCode(this.legs);
      } else {
         this.strategyCode = undefined;
      }
      this.tickers = this.makeTickers(this.legs);

      return dupLeg;
   }

   strikeUp(step: number, legLinkId: string, isLinked: boolean) {
      if (isLinked) {
         this.legs.forEach( (leg)  => this.upStrikeLeg(leg.linkId, step) );
      } else {
         this.upStrikeLeg(legLinkId, step);
      }

      this.price = undefined;
      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   strikeDown(step: number, legLinkId: string, isLinked: boolean) {
      if (isLinked) {
         this.legs.forEach( (leg)  => this.downStrikeLeg(leg.linkId, step) );
      } else {
         this.downStrikeLeg(legLinkId, step);
      }

      this.price = undefined;
      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   reverseLegsOption() {
      this.legs.forEach(x => {

         if (x.optionType === OptionType.Put) {
            x.optionType = OptionType.Call;
         } else if (x.optionType === OptionType.Call) {
            x.optionType = OptionType.Put;
         }
      });

      this.price = undefined;
      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   reverseLegsSide() {
      this.legs.forEach(x => {
         if (x.marketSide === MarketSide.Buy) {
            x.marketSide = MarketSide.Sell;
         } else if (x.marketSide === MarketSide.Sell) {
            x.marketSide = MarketSide.Buy;
         }
      });

      this.price = undefined;
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   changeLegOptionType(legLinkId: string, newType: 'Call' | 'Put', lqc: LastQuoteCacheService) {
      const targetLeg = this.legs.find(leg => leg.linkId === legLinkId);
      targetLeg.ask = targetLeg.bid = null;

      if (newType === 'Call') {
         targetLeg.optionType = OptionType.Call;
      } else if (newType === 'Put') {
         targetLeg.optionType = OptionType.Put;
      }

      const q = lqc.getLastQuote(targetLeg.ticker);
      if (q) {
         targetLeg.ask = q.ask;
         targetLeg.bid = q.bid;
         targetLeg.price = isNaN(q.mid) || q.mid < Number.EPSILON ? undefined : q.mid;
      }

      this.price = undefined;
      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   changeLegStrike(legLinkId: string, newStrike: number, lqc: LastQuoteCacheService) {

      const targetLeg = this.legs.find(leg => leg.linkId === legLinkId);

      targetLeg.ask = targetLeg.bid = null;

      targetLeg.strike = newStrike;
      targetLeg.expectedStrike = newStrike;

      if (this.expiration.strikes.indexOf(newStrike) < 0) {
         targetLeg.strike = undefined;
      }

      this.price = undefined;
      this.tickers = [];
      this.strategyCode = undefined;

      const q = lqc.getLastQuote(targetLeg.ticker);

      if (q) {
         targetLeg.ask = q.ask;
         targetLeg.bid = q.bid;
         targetLeg.price = q.mid;

         if (isNaN(targetLeg.price) || targetLeg.price < Number.EPSILON) {
            targetLeg.price = undefined;
         }
      }

      this.tickers = this.makeTickers(this.legs);
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   changeLegSide(legLinkId: string, value: any) {
      const targetLeg = this.legs.find(leg => leg.linkId === legLinkId);
      if (value === 'Buy') {
         targetLeg.marketSide = MarketSide.Buy;
      } else if (value === 'Sell') {
         targetLeg.marketSide = MarketSide.Sell;
      }

      this.price = undefined;
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   changeLegQty(legLinkId: string, value: any) {
      const targetLeg = this.legs.find(leg => leg.linkId === legLinkId);
      targetLeg.qty = value;

      this.price = undefined;
      this.strategyCode = this.makeStrategyCode(this.legs);
   }


   private downStrikeLeg(legLinkId: string, step: number) {
      const targetLeg = this.legs.find(l => l.linkId === legLinkId);

      if (targetLeg.isStock) {
         return;
      }

      const originalStrike = targetLeg.strike || targetLeg.expectedStrike;

      // const ix = this.expiration.strikes.indexOf(originalStrike);
      //
      // if (ix < 0) {
      //    console.error('strike not found in list');
      //    return;
      // }

      let newStrike = originalStrike - step;

      // if (!step) {
      //    let downIx = ix - 1;
      //
      //    if (downIx < 0) {
      //       downIx = 0;
      //    }
      //
      //    newStrike = this.expiration.strikes[downIx];
      //
      // } else {
      //
      //    const currentStrike = this.expiration.strikes[ix];
      //    const downStrike = currentStrike - step;
      //
      //    let downStrikeIx = this.expiration.strikes.indexOf(downStrike);
      //
      //    if (downStrikeIx === -1) {
      //       downStrikeIx = ix - 1;
      //    }
      //
      //    if (downStrikeIx < 0) {
      //       downStrikeIx = 0;
      //    }
      //
      //    newStrike = this.expiration.strikes[downStrikeIx];
      // }

      targetLeg.expectedStrike = newStrike;
      targetLeg.strike = newStrike;
   }


   private upStrikeLeg(legLinkId: string, step: number) {

      const targetLeg = this.legs.find(leg => leg.linkId === legLinkId);

      if (targetLeg.isStock) {
         return;
      }

      // const ix = this.expiration.strikes.indexOf(targetLeg.strike);
      //
      // if (ix < 0) {
      //    console.error('strike not found in list');
      //    return;
      // }

      const originalStrike = targetLeg.strike || targetLeg.expectedStrike;

      let newStrike = originalStrike + step;

      // if (!step) {
      //    let upIx = ix + 1;
      //
      //    if (upIx > this.expiration.strikes.length - 1) {
      //       upIx = this.expiration.strikes.length - 1;
      //    }
      //
      //    newStrike = this.expiration.strikes[upIx];
      //
      // } else {
      //
      //    const currentStrike = this.expiration.strikes[ix];
      //    const downStrike = currentStrike + step;
      //
      //    let downStrikeIx = this.expiration.strikes.indexOf(downStrike);
      //
      //    if (downStrikeIx === -1) {
      //       downStrikeIx = ix + 1;
      //    }
      //
      //    if (downStrikeIx > this.expiration.strikes.length - 1) {
      //       downStrikeIx = this.expiration.strikes.length - 1;
      //    }
      //
      //    newStrike = this.expiration.strikes[downStrikeIx];
      // }

      targetLeg.expectedStrike = newStrike;
      targetLeg.strike = newStrike;
   }


   private makeTickers(legs: PricingPadLeg[]): string[] {
      if (legs.length === 0) {
         return [];
      }

      return legs.filter(x => x.isValid())
          .map(x => x.ticker)
          .filter(x => !isNullOrUndefined(x)) || [];
   }


   private makeStrategyCode(legs: PricingPadLeg[]): string {
      legs = legs.filter(x => !x.isSeparator)

      if (legs.length === 0) {
         return undefined;
      }

      if (legs.some(x => !x.isValid())) {
         return undefined;
      }

      const codes = legs
          .slice()
          .sort( (a, b) => a.strike - b.strike ).map(x => `${x.qty * x.marketSide}|${x.ticker}`);

      const strategyCode = codes.join(';');

      if (!isNullOrUndefined(strategyCode)) {

         const slp = this._lastQuoteCache.getLastStrategyPrice(strategyCode);

         if (!isNullOrUndefined(slp)) {
            this.price = slp.price;
         }
      }

      return strategyCode;
   }
}
