import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { RiskRuleModel } from '../risk-rule-model';
import { ToastrService } from 'ngx-toastr';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { RiskManagerRuleDto, RiskRuleTradingObjectives } from 'projects/shared-components/shell-communication/dtos/risk-manager-rule-dto.interface';
import { CreateRiskManagerRule } from 'projects/shared-components/shell-communication/operations/risk/create-risk-manager-rule.class';
import { UpdateRiskManagerRule } from 'projects/shared-components/shell-communication/operations/risk/update-risk-manager-rule.class';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { PositionClass } from 'projects/shared-components/aggregated-positions/position-class.const';
import { AggregationSpec } from 'projects/shared-components/aggregated-positions/aggregation-spec.interface';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { GetAvailableBuckets } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';
import { isNullOrUndefined } from 'util';


@Component({
   selector: 'ets-risk-rule-editor',
   templateUrl: 'risk-rule-editor.component.html',
   styleUrls: ['risk-rule-editor.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskRuleEditorComponent {
   
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _sessionService: SessionService,
      private readonly _tiService: TradingInstrumentsService
   ) {

   }

   private _availalbleBuckets: GetAvailableBucketsReply = { portfolios: [], combos: [], comboGroups: [] };

   @ViewChild(SymbolPickerComponent) symbolPicker: SymbolPickerComponent;
   
   private _visible: boolean;
   get visible(): boolean { return this._visible; }
   
   @DetectSetterChanges()
   set visible(v: boolean) {
      this._visible = v;
   }
   
   private _isLoading: boolean;
   get isLoading(): boolean { return this._isLoading; }
   
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   mode: 'update' | 'create';
      
   tradingObjectives: RiskRuleTradingObjectives = {};
   
   ruleName: string;
   
   ruleDescription: string;

   isSessionRule = false;

   selectedSecurity: string;
   
   selectedTerminal: string;

   selectedAccount: string;

   selectedPortfolio: string;

   selectedCombo: string;

   selectedComboGroup: string;

   selectedPositionClass: string;

   isShellTotal = false;

   terminalsList: TerminalDto[] = [];
   
   accountsList: AccountDto[] = [];
   
   portfoliosList: PortfolioDto[] = [];
   
   combosList: ComboDto[] = [];

   comboGroupsList: ComboGroupDto[] = [];

   positionClassList = [
      PositionClass.manualPositions,
      PositionClass.tradingSystems
   ];

      
   private _originalRule: RiskRuleModel;


   @DetectMethodChanges({isAsync: true})
   async show(rule?: RiskRuleModel): Promise<void> {
      this.isLoading = true;
      
      this.visible = true;

      this._resetState();

      this.mode = rule ? 'update'  : 'create';
      
      if (this.mode === 'update') {
         this._onUpdateMode(rule);
      }

      try {
         await this._loadReferenceData();
      } catch (e) {
         console.error(e);
      } finally {
         this.isLoading = false;
      }
   }



   @DetectMethodChanges()
   onHidden() {
      this.visible = false;
      this._resetState();
   }

   
   @DetectMethodChanges({isAsync: true})
   async onCreateClicked(): Promise<void> {

      const errors: string[] = this._validateErrors();

      if (errors.length > 0) {
         errors.forEach( err => this._toastr.warning(err) );
         return;
      }

      const dto: RiskManagerRuleDto = {};
      
      if (this.mode === 'update') {
         dto.ruleId = this._originalRule.ruleId;
      }
   
      dto.ruleName = this.ruleName;
      dto.ruleDescription = this.ruleDescription;
      dto.isSession = this.isSessionRule;
      
      dto.npo = this.tradingObjectives.npo || 0;
      dto.percentTS = this.tradingObjectives.percentTS || 0;
      dto.stopLoss = this.tradingObjectives.stopLoss || 0;
      dto.dollarTS = this.tradingObjectives.dollarTS || 0;

      const spec: AggregationSpec = {};

      if (this.isShellTotal) {
         spec.shellTotal = true;
      } else {
         spec.terminalId = this.selectedTerminal;
         spec.accountId = this.selectedAccount;
         spec.securityId = this.selectedSecurity;
         spec.positionClass = this.selectedPositionClass;
         spec.portfolioId = this.selectedPortfolio;
         spec.comboId = this.selectedCombo;
         spec.comboGroupId = this.selectedComboGroup;
      }

      dto.aggregationSpec = JSON.stringify(spec);

      const cmd = this.mode === 'create' ? new CreateRiskManagerRule(dto) : new UpdateRiskManagerRule(dto);

      this.isLoading = true;

      try {

         await this._shellClient.processCommand(cmd);

      } catch (e) {

         console.error(e);

      } finally {

         this.isLoading = false;
      }
   }
   

   @DetectMethodChanges()
   onCancelClicked() {
      this.onHidden();
   }

   
   @DetectMethodChanges({isAsync: true})
   async onCreateAndCloseClicked(): Promise<void> {
      await this.onCreateClicked();
      this.onCancelClicked();
   }


   @DetectMethodChanges()
   onTerminalChanged() {
      if (isNullOrUndefined(this.selectedTerminal)) {
         this.portfoliosList = [];
         this.selectedPortfolio = undefined;

         return;
      }

      this.portfoliosList = this._availalbleBuckets.portfolios.filter(p => p.terminalId === this.selectedTerminal);
   }

   @DetectMethodChanges()
   onPortfolioChanged() {
      if (isNullOrUndefined(this.selectedPortfolio)) {
         this.combosList = [];
         this.selectedCombo = undefined;

         return;
      }

      this.combosList = this._availalbleBuckets.combos.filter(c => c.portfolioId === this.selectedPortfolio);
   }
   
   @DetectMethodChanges()
   onComboChanged() {
      if (isNullOrUndefined(this.selectedCombo)) {
         this.comboGroupsList = [];
         this.selectedComboGroup = undefined;

         return;
      }

      this.comboGroupsList = this._availalbleBuckets.comboGroups.filter(c => c.comboId === this.selectedCombo);
   }


   @DetectMethodChanges()
   onShellTotalValueChanged(event) {
      if (event.value) {
         this.selectedTerminal = null;
         this.selectedAccount = null;
         this.selectedPortfolio = null;
         this.selectedSecurity = null;
         this.selectedPositionClass = null;

         if (this.symbolPicker) {
            this.symbolPicker.selectedInstrument = null;
         }
      }
   }



   private _validateErrors(): string[] {
      const errors = [];
      
      if (!this.ruleName) {
         errors.push('Rule name is required');
      }

      if (!this.tradingObjectives.npo && 
         !this.tradingObjectives.dollarTS &&
         !this.tradingObjectives.percentTS &&
         !this.tradingObjectives.stopLoss) {
            errors.push('At least one TO is required');
      }

      return errors;
   }


   @DetectMethodChanges()
   private async _loadReferenceData(): Promise<void> {
      // get terminals
      const terminals = this._sessionService.loginResult.availableTerminals.filter(
         x => !x.isProxy
      );

      this.terminalsList = terminals;

      // get accounts
      this.accountsList = this._sessionService.loginResult.availableAccounts;


      // get portfolios
      const bucketsQuery = new GetAvailableBuckets();
      const reply = await this._shellClient.processQuery<GetAvailableBucketsReply>(bucketsQuery);
      this._availalbleBuckets = reply;
   }
   
   
   @DetectMethodChanges()
   private _onUpdateMode(rule: RiskRuleModel) {

      this._originalRule = rule;

      this.ruleName = rule.ruleName;
      this.ruleDescription = rule.ruleDescription;


      const spec = rule.aggregationSpecObj;

      this.selectedTerminal = spec.terminalId;
      this.selectedAccount = spec.accountId;
      this.selectedPortfolio = spec.portfolioId;
      this.selectedCombo = spec.comboId;
      this.selectedComboGroup = spec.comboGroupId;
      this.isShellTotal = spec.shellTotal || false;
      
      if (spec.securityId) {
         if (this.symbolPicker) {
            const ti = this._tiService.getInstrumentByTicker(spec.securityId);
            this.symbolPicker.onInstrumentSelected(ti);
         }
      }

      this.selectedPositionClass = spec.positionClass;

      this.tradingObjectives.npo = rule.npo;
      this.tradingObjectives.stopLoss = rule.stopLoss;
      this.tradingObjectives.percentTS = rule.percentTS;
      this.tradingObjectives.dollarTS = rule.dollarTS;
      
      this.isSessionRule = rule.isSession || false;
   }

   
   @DetectMethodChanges()
   private async _resetState(): Promise<void> {

      this.mode = undefined;

      this.tradingObjectives = {};

      this._originalRule = undefined;

      this.ruleName = undefined;
      this.ruleDescription = undefined;

      this.selectedTerminal = undefined;
      this.selectedAccount = undefined;
      this.selectedPortfolio = undefined;
      this.selectedSecurity = undefined;
      this.selectedPositionClass = undefined;
      
      this.isShellTotal = false;
      this.isSessionRule = false;

      if (this.symbolPicker) {
         this.symbolPicker.selectedInstrument = null;
      }
   }
}
