import { CellValueChangedEvent, FirstDataRenderedEvent, ValueGetterParams } from 'ag-grid-community';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions';
import {
   centeredColumnDef,
   defaultLoadingOverlayTemplate,
   defaultMoneyCellDefinition,
   defaultPriceCellFormatter
} from '../../ag-grid-contrib';
import { PortfolioItemsComponent } from './portfolio-items.component';
import { PortfolioItemDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { ExpireDialogDateCellEditorComponent, ExpireDialogGroupRowRendererComponent, ExpireDialogTimeCellEditorComponent } from './expire-dialog-group-row';



export function getExpirationDialogGridOptions(this: PortfolioItemsComponent): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Symbol',
            field: 'tickerDisplayName',
            minWidth: 240,
         },
         {
            headerName: 'Underlying',
            field: 'underlyingDisplayName',
            rowGroup: true,
            hide: true,
         },
         {
            headerName: 'Expiration',
            field: 'expirationDisplayDate',
            rowGroup: true,
            hide: true,
         },
         {
            headerName: 'Position',
            field: 'netPosition',
            valueGetter: (params: ValueGetterParams) => {
               if (!params.data) {
                  return null;
               }
               return params.data.netPosition;
            }
         },
         {
            headerName: 'Open Price',
            field: 'openPrice',
            valueGetter: (params: ValueGetterParams) => {
               if (!params.data) {
                  return null;
               }
               return params.data.openPrice;
            },
            valueFormatter: defaultPriceCellFormatter,
         },
         {
            headerName: 'Portfolio',
            field: 'portfolioName',
         },
         {
            headerName: 'Combo',
            field: 'comboName',
         },
         {
            headerName: 'Group',
            field: 'comboGroupName',
            flex: 1
         },
         {
            headerName: 'Exp. Price',
            field: 'expirationPrice',
            editable: true,
            pinned: 'right'
         },
         Object.assign({
            headerName: 'Net Diff.',
            field: 'expirationDifference',
            editable: false,
            pinned: 'right',
         },
            defaultMoneyCellDefinition),
         {
            headerName: 'Exp. Time',
            field: 'expirationTime',
            editable: true,
            pinned: 'right',
            cellEditor: 'timeEditor'
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      immutableData: true,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      suppressAggFuncInHeader: true,

      groupRowRendererParams: {
         innerRenderer: 'groupRowRenderer',
         suppressCount: true,
         component: this
      },

      rowGroupPanelShow: 'never',

      groupUseEntireRow: true,

      frameworkComponents: {
         groupRowRenderer: ExpireDialogGroupRowRendererComponent,
         dateEditor: ExpireDialogDateCellEditorComponent,
         timeEditor: ExpireDialogTimeCellEditorComponent
      } as any,


      onGridReady: (args) => this.onExpirationDialogGridReady(args),


      onCellValueChanged: (args: CellValueChangedEvent) => {
         if (args.colDef.field === 'expirationPrice') {

            this.onExpirationDialogPriceEntered(args.newValue, args.data);

         } else if (args.colDef.field === 'expirationTime') {

            this.onExpirationDialogTimeEntered(args.newValue, args.data);

         }
      },

      getRowNodeId: (data: PortfolioItemDto) => {
         return data.portfolioItemId;
      },

      getContextMenuItems: (args: GetContextMenuItemsParams) => {

         return [
            {
               name: 'Refill Last Prices',
               action: () => this.expirationDialogRefillActualLastPrices()
            },
            'separator',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll'
         ];
      },
   };
}
