import { Component, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { TrendLimitAlgoParametersControl } from './trendlimit-algo-parameters-control';
import { ParametersViewBase } from '../../parameters-view-base';

@Component({
  selector: 'ets-trendlimit-parameters-view',
  templateUrl: './trendlimit-parameters-view.component.html',
  styleUrls: ['./trendlimit-parameters-view.component.scss']
})
export class TrendLimitParametersViewComponent  extends ParametersViewBase<TrendLimitAlgoParametersControl> {
  
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;
   
   onEtsInit() {
   
   }
   
   onEtsAfterViewInit() {
   
   }

}
