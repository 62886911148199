<div class="popup_container_item">
  <div class="popup_container_content">
    <div class="system-details-grid common">
      <ag-grid-angular
        class="ag-theme-balham-dark"
        [gridOptions]="commonParametersGridModel">
      </ag-grid-angular>
    </div>
    <div class="system-details-grid specific">
      <ag-grid-angular
        class="ag-theme-balham-dark"
        [gridOptions]="specificParametersGridModel">
      </ag-grid-angular>
    </div>
  </div>
</div>