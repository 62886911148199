import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { isVoid } from '../utils';

@Component({
   selector: 'ets-no-html-text',
   template: `<div>{{cleanText}}</div>`,
   styles: []
})
export class NoHtmlCellRendererComponent implements ICellRendererAngularComp {

   constructor(
      private _messageBus: MessageBusService
   ) {}

   cleanText: string;

   agInit(params: ICellRendererParams): void {
      if (isVoid(params.value)) {
         return;
      }
      const value = params.value  as string;
      if (value.indexOf('</') > 0 || value.indexOf('/>')  > 0) {
         var div = document.createElement("div");
         div.innerHTML = params.value;
         this.cleanText = div.innerText;
      } else {
         this.cleanText = params.value;
      }
   }

   refresh(): boolean {
      return false;
   }
}


