import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

/**
 * @returns TerminalTotalPositionDto[]
 */
export class GetTerminalTotalPosition implements ProtocolQuery {
  constructor(public terminals: string[]) { }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetTerminalTotalPosition`;
  }
}
