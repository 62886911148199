import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../operations/shell-protocol-namespace.const';
import { ProtocolQuery } from 'projects/shared-components/service-model/protocol-query.interface';

export enum TriggerSuitability {
  Activation = 1 << 0,
  Entry = 1 << 1,
  Exit = 1 << 2
}

export enum TriggerType {
   Unknown,
   Condition,
   Trading
}

export enum TriggerPurpose {
   Unknown = 0,
   Filter = 1,
   Condition = 1 << 1,
   Entry = 1 << 2,
   Exit = 1 << 3,
   Long = 1 << 4,
   Short= 1 << 5,
   CloseLong = 1 << 6,
   CloseShort = 1 << 7
}


export interface TriggerDto {
  triggerId?: string;
  displayName?: string;
  vendorName?: string;
  triggerPurpose?: TriggerPurpose;
  triggerType?: TriggerType;
  isApproved?: boolean;
}

export interface TriggerEditedDto {
  trigger: TriggerDto;
}

export interface TriggerChangeProgressDto {
  progressMessage: string;
  progressValue: number;
  correlationTicketId: string;
  hasErrors: boolean;
}

export class RemoveTrigger implements ProtocolCommand {
  constructor(
    public readonly triggerId: string,
    public readonly correlationTicket: string
  ) {

  }
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveTrigger`;
  }
}

export class GetTriggers implements ProtocolQuery {
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetTriggers`;
  } 
}

export class CreateTrigger implements ProtocolCommand {
  constructor(
    public readonly trigger: TriggerDto,
    public readonly correlationTicketId: string
  ) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateTrigger`;
  }
}

export class UpdateTrigger implements ProtocolCommand {
  constructor(
    public readonly trigger: TriggerDto,
    public readonly correlationTicketId: string
  ) {
  }
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateTrigger`;
  }
}
