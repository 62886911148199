import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
   selector: '',
   template: `
  <div *ngIf="isArchived" [style.color]="cssRules.archivedColor">
      <span>Archived Items</span>
   </div>
   <div *ngIf="!isArchived" [style.color]="cssRules.activeColor">
      <span>Active Items</span>
   </div>
   `
})
export class InnerStrategyGroupRowRendererComponent implements ICellRendererAngularComp {

   isArchived = false;

   cssRules = {
      archivedColor: 'yellow',
      activeColor: '#e8e2e2'
   };

   field: 'isArchived';

   terminalName: string;

   refresh(params: any): boolean {
      return false;
   }

   agInit(params: ICellRendererParams): void | Promise<void> {

      this.isArchived = params.node.key.indexOf('Archived') >= 0 ? true : false;
   }
}
