import { ProtocolQuery } from '../../../service-model/protocol-query.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';

/** @returns PositionDto */
export class CheckIfManualPositionExists implements ProtocolQuery {
   public constructor(
      public readonly ticker: string,
      public readonly terminalId: string,
      public readonly accountId: string,
      public readonly portfolioId: string,
      public readonly comboId: string,
      public readonly comboGroupId: string,
   ) {
   }

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.CheckIfManualPositionExists`;
   }
}
