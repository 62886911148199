import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ShortOptionParameters } from '../adjustment-strategy-dialog/strategy-parameters/short-option/short-option-parameters';
import { SessionService } from '../authentication/session-service.service';
import { EtsConstants } from '../ets-constants.const';
import { MessageBusService } from '../message-bus.service';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { ExitStrategies } from '../shell-communication/operations/strategies/exit-strategies.class';
import { StartStrategies } from '../shell-communication/operations/strategies/start-strategies.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from '../shell-communication/shell-dto-protocol';
import { CreateAdjustmentStrategy, UpdateAdjustmentStrategy } from '../shell-communication/shell-operations-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategyState } from '../strategies/strategy-state.enum';
import { TimestampsService } from '../timestamps.service';
import { findTimezoneOrigin } from '../timezones/time-zones.const';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { OrderType } from '../trading-model/order-type.enum';
import { daysToExpiration, DetectMethodChanges, DetectSetterChanges, isNullOrUndefined, isVoid, militaryTimeToAmPm, timespanToUserFriendly } from '../utils';
import { AdjustmentBucketConfig } from './bucket-config';
import { SellOptionsBlockOverviewViewModel } from './overview-blocks/short-options-block/short-options-overview-block.model';
import { StrategyOverview } from './overview-blocks/strategy-overview.model';


export class ShortOptionsBlock {
   constructor(private _changeDetector: ChangeDetectorRef,
               private _sessionService: SessionService,
               private _portfolioService: PortfoliosService,
               private _shellService: ShellClientService,
               private _tiService: TradingInstrumentsService,
               private readonly _strategyIssuesService: StrategiesIssuesService,
               private _messageBus: MessageBusService,
               private _timestampsService: TimestampsService,
               private readonly _toastr: ToastrService,
               private readonly _strategiesService: StrategiesService,
               private _shellClient: ShellClientService)  {

      this.parameters = new ShortOptionParameters(_changeDetector);

      this._messageBus.of<PositionDto[]>('PositionDto')
         .subscribe(x => this.onPositionDto(x.payload));
      
      this._messageBus.of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .subscribe(x => this.onPortfolioItemAdded(x.payload));

      this._messageBus.of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .subscribe(x => this.onPortfolioItemRemoved(x.payload));
      
   }

   private _strategy: StrategyModel;
   private _strategy2: StrategyModel;
   private _shortOption: PortfolioItemDto;
   private _shortOption2: PortfolioItemDto;
   private _bucketConfig: AdjustmentBucketConfig;

   get isVisible(): boolean {
      return !isNullOrUndefined(this.displayName);
   }

   blockId: string;
   bgColor = '#9c6821';
   displayName: string;
   displayName2: string;
   sessionPnL: number;
   accumulatedPnL: number;

   //
   get hasStrategy(): boolean {
      return !isNullOrUndefined(this._strategy);
   }

   //
   get isStrategyRunning(): boolean {
      if (!this._strategy) {
         return false;
      }
      return (this._strategy.state & StrategyState.Enabled) === this._strategy.state;
   } 

   //
   get overviewBlockColor(): 'green' | 'red' | 'grey' {
      return this.getBlockColor();
   }

   //
   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }

   //
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   optionType: string;
   optionType2: string;

   //
   parameters: ShortOptionParameters;

   //
   private _underlying: TradingInstrument;
   get underlying(): TradingInstrument {
      return this._underlying;
   }

   //
   @DetectSetterChanges()
   set underlying(v: TradingInstrument) {
      this._underlying = v;
   }

   //
   get canExitStrategy(): boolean {
      if (!this._strategy) {
         return false;
      }

      if (this._strategy.state === StrategyState.Active) {
         return true;
      }

      return false;
   }

   //
   get canStartStrategy(): boolean {
      if (!this._strategy) {
         return false;
      }

      if (this._strategy.state === StrategyState.Inactive) {
         return true;
      }

      return false;
   }

   //
   get canUpdateStrategy(): boolean {
      return this.canStartStrategy;
   }

   //
   get strategyId(): string {
      return this._strategy ? this._strategy.strategyId : null;
   }

   //
   get strategyId2(): string {
      return this._strategy2 ? this._strategy2.strategyId : null;
   }

   //
   setLeg(leg: PortfolioItemDto) {
      this._shortOption = leg;
   }

   //
   setLeg2(leg2: PortfolioItemDto) {
      this._shortOption2 = leg2;
   }

   //
   @DetectMethodChanges()
   setStrategy(strategy: StrategyModel) {
      this._strategy = strategy;
      this.parameters.setParameters(strategy);
   }

   //
   @DetectMethodChanges()
   setStrategy2(strategy2: StrategyModel) {
      this._strategy2 = strategy2;
      this.parameters.setParameters2(strategy2);
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async startStrategy() {
      const cmd = new StartStrategies([this._strategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async exitStrategy() {
      const cmd = new ExitStrategies([this._strategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async updateStrategy() {
      const parameters = this.parameters.getParameters();
      const displayName = this.parameters.displayName;

      const cmd = new UpdateAdjustmentStrategy(
         this._strategy.strategyId,
         displayName,
         parameters
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(cmd);

      } catch (e) {

         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   async createStrategy(): Promise<void> {
      if (!this._shortOption) {
         this._toastr.error('Bucket has no short option');
         return;
      }

      const errors = this.parameters.validate({
         legRequired: false,
         legToAttachTo: undefined,
         mode: 'create',
         ctx: 'direct'
      });

      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e));
         return;
      }

      const cmd = new CreateAdjustmentStrategy(
         EtsConstants.algorithms.adjustment.shortOptionAlgoId,
         this.parameters.displayName,
         this._shortOption.portfolioItemId,
         this.parameters.getParameters(),
      );

      try {
         this.isLoading = true;
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         console.error(e);
      } finally {
         this.isLoading = false;
      }
   }

   //
   reset() {
      this._strategy = null;
      this._strategy2 = null;
      this.underlying = null;
      this.displayName = null;
      this.displayName2 = null;
      this.parameters.reset();
   }

   //
   setBucket(bucketConfig: AdjustmentBucketConfig) {
      this._bucketConfig = bucketConfig;
   }

   //
   getViewModel(legNo: number): SellOptionsBlockOverviewViewModel {
      
      let shortOption = 'N/A';
      let shortOptionQty = null;
      let shortOptionAsset = 'N/A';
      let shortOptionExpiration = 'N/A';
      let shortOptionDaysToExpiration = -1;
      let hasErrors = false;
      let noLegs: boolean;
      
      if (legNo === 1) {
         if (this._shortOption) {
            shortOption = this._shortOption.tickerDisplayName;
            shortOptionQty = this._shortOption.netPosition;
            shortOptionAsset = shortOption.split(' ').slice(0, 3).join(' ');
            shortOptionExpiration = shortOption.split(' ').slice(3).join(' ');
   
            const sExpDate = this._shortOption.ticker.split(' ')[4];
            const diffIndays = daysToExpiration(sExpDate);
            shortOptionDaysToExpiration = diffIndays;
         }
   
   
         if (!this._shortOption || this._shortOption.netPosition >= 0) {
            hasErrors = true;
         }

         noLegs = !this._shortOption || this._shortOption.netPosition == 0;

      } else if (legNo === 2) {
         if (this._shortOption2) {
            shortOption = this._shortOption2.tickerDisplayName;
            shortOptionQty = this._shortOption2.netPosition;
            shortOptionAsset = shortOption.split(' ').slice(0, 3).join(' ');
            shortOptionExpiration = shortOption.split(' ').slice(3).join(' ');
   
            const sExpDate = this._shortOption2.ticker.split(' ')[4];
            const diffIndays = daysToExpiration(sExpDate);
            shortOptionDaysToExpiration = diffIndays;
         }
   
   
         if (!this._shortOption2 || this._shortOption2.netPosition >= 0) {
            hasErrors = true;
         }

         noLegs = !this._shortOption2 || this._shortOption2.netPosition == 0;
      }


      return {
         bgColor: this.bgColor,
         shortOption,
         shortOptionQty,
         shortOptionAsset,
         shortOptionExpiration,
         shortOptionDaysToExpiration,
         hasErrors,
         noLegs
      };
   }

   //
   getStrategiesOverview(): StrategyOverview[] {

      if (!this._strategy) {
         return [];
      }

      const strikeOffset = this.parameters.strikeOffset * 100;
      
      const expirationOffset = this.parameters.expirationOffset;

      const minPrice = this.parameters.minimumPrice;

      const buffer = this.parameters.strikeBuffer;
      
      const orderType = OrderType[this.parameters.orderType];

      const convertToMarket = this.parameters.convertToMarket ? 'Yes' : 'No';

      let convertMode = 'N/A';
      let convertTime = 'N/A';
      let country = 'N/A';
      let timezone = 'N/A';

      if (convertToMarket === 'Yes') {
         
         convertMode = this.parameters.convertToMarketSettings.actionTimeMode || '';
         
         convertTime = this.parameters.convertToMarketSettings.actionTime || '';
         
         if (convertMode.endsWith(' At')) {
            convertTime = militaryTimeToAmPm(convertTime);
         } else if (convertMode.endsWith(' After')) {
            convertTime = timespanToUserFriendly(convertTime);
         }

         const origin = findTimezoneOrigin(this.parameters.convertToMarketSettings.timezone);
         country = origin.country;
         timezone = origin.timezone;
      }

      const overview: StrategyOverview = {
         bgColor: this.bgColor,
         parameters: [
            { key: 'Status:', value: this.isStrategyRunning ? 'Enabled' : 'Disabled' },
            { key: 'Issues:', value: this.getNumberOfIssues() },
            { key: 'Strike Offset:', value: strikeOffset },
            { key: 'Expiration Offset:', value: expirationOffset },
            { key: 'Min. Price:', value: minPrice },
            { key: 'Buffer:', value: buffer },
            { key: 'Order Type:', value: orderType },
            { key: 'Convert To Market:', value: convertToMarket },
         ],
         strategy: this._strategy
      };

      let overview2: StrategyOverview = undefined;
      if (this._strategy2) {
         overview2 = {
            bgColor: this.bgColor,
            parameters: [
               { key: 'Status:', value: this.isStrategyRunning ? 'Enabled' : 'Disabled' },
               { key: 'Issues:', value: this.getNumberOfIssues() },
               { key: 'Strike Offset:', value: strikeOffset },
               { key: 'Expiration Offset:', value: expirationOffset },
               { key: 'Min. Price:', value: minPrice },
               { key: 'Buffer:', value: buffer },
               { key: 'Order Type:', value: orderType },
               { key: 'Convert To Market:', value: convertToMarket },
            ],
            strategy: this._strategy2
         };
      }

      if (convertToMarket === 'Yes') {
         overview.parameters.push(
            { key: 'Convert Mode:', value: convertMode },
            { key: 'Convert Time:', value: convertTime },
            { key: 'Timezone Country:', value: country },
            { key: 'Timezone:', value: timezone },
         );

         if (overview2) {
            overview2.parameters.push(
               { key: 'Convert Mode:', value: convertMode },
               { key: 'Convert Time:', value: convertTime },
               { key: 'Timezone Country:', value: country },
               { key: 'Timezone:', value: timezone },
            );   
         }
      }
     
      const result = [ 
         overview
      ];

      if (!isVoid(overview2)) {
         result.push(overview2);
      }

      return result;
   }

   //
   private getBlockColor(): 'green' | 'red' | 'grey' {
      if (!this._bucketConfig) {
         return 'grey';
      }

      if (!this._bucketConfig.comboId) {
         return 'grey';
      }

      if (!this._strategy) {
         return 'red';
      }

      if (!this.isStrategyRunning) {
         return 'red';
      }

      return 'green';
   }

   //
   private getNumberOfIssues(): number {
      let result = 0;
      if (this._strategy) {
         result = this._strategyIssuesService
            .getStrategyIssuesCount(this._strategy.strategyId);
      }
      return result;
   }

    //
    private onPositionDto(dtos: PositionDto[]) {
      if (!this._bucketConfig) {
         return;
      }

      if (!this._shortOption && !this._shortOption2) {
         return;
      }

      const filtered = dtos.filter(x => x.comboGroupId === this._bucketConfig.comboGroupId);

      filtered.forEach(dto => {

         const pos1 = this._shortOption ? this._shortOption.portfolioItemId : null;
         const pos2 = this._shortOption2 ? this._shortOption2.portfolioItemId : null;

         const update = dto.positionId === pos1
            || dto.positionId === pos2;

         if (update) {

            if (dto.positionId === pos1) {
               this._shortOption.netPosition = dto.netPosition;
            } else if (dto.positionId === pos2) {   
               this._shortOption2.netPosition = dto.netPosition;
            }

         } else {

            if (dto.strategyId === EtsConstants.strategies.manualStrategyId) {
               if (dto.netPosition < 0) {
                  if (dto.ticker.indexOf('call') > 0) {
                     dto['portfolioItemId'] = dto.positionId;
                     this._shortOption = dto as any;
                  } else if (dto.ticker.indexOf('put')) {
                     dto['portfolioItemId'] = dto.positionId;
                     this._shortOption2 = dto as any;
                  }
               }
            }
         }
      });
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.portfolioItem.comboGroupId !== this._bucketConfig.comboGroupId) {
         return;
      }

      if (msg.portfolioItem.itemType === PortfolioItemType.Strategy) {
         if (msg.portfolioItem.algoName && msg.portfolioItem.algoName.toLowerCase().includes('short')) {
                
            // hack for  race condition between 'strategy added' event and 'portfolio item added' event
                let cntr = 0;
                const i = setInterval(() => {
                   
                   cntr++;
    
                   if (cntr > 50) {
                      clearInterval(i);
                      return;
                   }
    
                   const str = this._strategiesService.getById(msg.portfolioItem.portfolioItemId);
                   if (str) {
                      this._strategy = str;
                      clearInterval(i);
                      this._changeDetector.detectChanges();
                   }

                }, 50);
         }
      } else {

         if (msg.portfolioItem.netPosition >= 0) {
            return;
         }
   
         this._shortOption = msg.portfolioItem;
      }

   }

   //
   @DetectMethodChanges()
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.itemType === PortfolioItemType.Strategy) {
         if (this._strategy && this._strategy.strategyId === msg.portfolioItemId) {
            this._strategy = null;
         }
      } else {

         if (this._shortOption && this._shortOption.portfolioItemId === msg.portfolioItemId)  {
            this._shortOption = null;
         }
      }
   } 
}
