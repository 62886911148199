import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { StrategySessionHistoryComponent } from './strategy-session-history.component';
import { StrategySessionHistoryAggregatedRowHeaderRendererComponent } from './strategy-session-history-aggregated-row-header-renderer.component';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { centeredColumnDef, defaultMoneyCellDefinition, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { StrategyHistoryInfoDto } from '../shell-communication/shell-dto-protocol';
import { TimestampsService } from '../timestamps.service';

export function getHistoryDataGridModel(
   this: StrategySessionHistoryComponent,
   timestampsService: TimestampsService
) {
   const options: GridOptions = {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Session Reset Time',
            field: 'endDate',
            valueFormatter(params: ValueFormatterParams) {
               if (!params.value) {
                  return '';
               }
               const frmtTime = timestampsService.getDefaultFormattedDateTime(params.value);
               return frmtTime;
            },
            sort: 'desc'
         },
         //
         Object.assign(
            {
               headerName: 'Session P&L',
               field: 'sessionPnL'
            },
            defaultMoneyCellDefinition
         ),
         //
         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accPnL'
            },
            defaultMoneyCellDefinition
         ) as any
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      suppressAggFuncInHeader: true,

      suppressCellSelection: true,

      frameworkComponents: {
         strategyHistoryGroupRowRenderer: StrategySessionHistoryAggregatedRowHeaderRendererComponent
      },

      groupRowRendererParams: {
         innerRenderer: 'strategyHistoryGroupRowRenderer',
         suppressCount: true
      },

      onGridReady: args => this.onHistoryListGridReady(args),

      onSelectionChanged: args => this.onHistoryListSelectionChanged(args),

      getRowNodeId: (rowData: StrategyHistoryInfoDto) => {
         return rowData.historyRecorId + '';
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];

         if (this.securityContext.getExtraHistory) {
            menu.push(
               {
                  name: 'Load Earlier History',
                  action: () => this.loadEarlierHistory()
               },
               {
                  name: 'Load Later History',
                  action: () => this.loadLaterHistory()
               },
               'separator',
            );
         }

         menu.push(
            {
               name: 'Toggle Full Size',
               action: () => this.toggleSectionFullWidth('history')
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );

         return menu;
      }
   };

   return options;
}
