<div class="interest-section" #container>
   <div class="header">
      <ets-section-header 
         [header]="this.header">
      </ets-section-header>
   </div>

   <div class="body">
      
      <div class="sidebox left">
         <ets-automation-cp-interest-sidebox #sidebox>
         </ets-automation-cp-interest-sidebox>
      </div>
      
      <div class="parameters">
         <ets-interest-calculator-strategy
            [parameters]="this.parameters"
            [auto]="true"
            >
         </ets-interest-calculator-strategy>
      </div>

      <div class="sidebox right" *ngIf="false">
         <div class="strategy-buttons">
   
            <div class="btn create-button" *ngIf="false">
               <div class="btn create">
                  <dx-button type="default" width="100%" text="Create Strategy" [disabled]="true"
                     (onClick)="null"></dx-button>
               </div>
            </div>
         
            <div class="manage-buttons" *ngIf="true">
               <div class="btn start">
                  <dx-button type="success" width="100%" text="Enable Strategy" [disabled]="true"
                     (onClick)="null"></dx-button>
               </div>
               
               <div class="btn stop">
                  <dx-button type="danger" width="100%" text="Disable Strategy" [disabled]="true"
                  (onClick)="null"></dx-button>
               </div>
               
               <div class="btn update">
                  <dx-button type="default" width="100%" text="Update Strategy" [disabled]="true"
                  (onClick)="this.interestBlock.updateStrategy()"></dx-button>
               </div>
               
            </div>
         </div>
      </div>
   </div>



   <dx-load-panel 
      [visible]="this.isLoading" 
      [position]="{my: 'center', at: 'center', of: container}"
      >
   </dx-load-panel>
</div>

<ng-template #separator>
   <div class="parameter separator">
      <div class="label">&nbsp;</div>
      <div class="input">
         <hr class="dash">
      </div>
   </div>
</ng-template>