import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ProtectiveOptionParameters } from 'projects/shared-components/cashflow-strategies/protective-option/ProtectiveOptionParameters';
import { ProtectiveOptionCashFlowStrategyComponent } from 'projects/shared-components/cashflow-strategies/protective-option/protective-option-cashflow-strategy.component';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { ProtectiveOptionSideboxComponent } from './legs/protective-option-sidebox.component';
import { GetProtectiveOptionSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ComboDto, GetProtectiveOptionSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { ToastrService } from 'ngx-toastr';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
   selector: 'ets-automation-cp-body-protective-option-section',
   templateUrl: './protective-option-section.component.html',
   styleUrls: ['./protective-option-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectiveOptionSectionComponent implements OnInit, OnDestroy {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();


   //
   header = 'Protective Option';

   //
   private _combo: ComboDto;
   public get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   public set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   //
   @ViewChild('protectiveOptionParameters', { static: false })
   protectiveOptionParametersComponent: ProtectiveOptionCashFlowStrategyComponent;

   //
   @ViewChild('firstSidebox', { static: false })
   firstSidebox: ProtectiveOptionSideboxComponent;

   //
   @ViewChild('secondSidebox', { static: false })
   secondSidebox: ProtectiveOptionSideboxComponent;

   //
   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   get isMultiDirection() {
      return this.strategy === 'Calls & Puts';
   }

   //
   ngOnInit(): void {

      this._cpMessageBus.when('AdjustmentStrategyCreated')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onComboSelected());


      this._cpMessageBus.when('AdjustmentStrategyRemoved')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.reset());
   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   getParameters(): ProtectiveOptionParameters[] {
      if (isVoid(this.protectiveOptionParametersComponent)) {
         return undefined;
      }

      const params = this.protectiveOptionParametersComponent
         .getParameters();

      return params;
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {

      this.reset();

      if (isVoid(this.combo)) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetProtectiveOptionSectionDataModel(
            this.combo.comboId,
            this.strategy
         );

         const reply = await this._shellClient.processQuery<GetProtectiveOptionSectionDataModelReply>(qry);

         this.firstSidebox.setData(reply.comboGroupId, reply.leg);

         this.protectiveOptionParametersComponent.setParameters(reply);

         if (this.secondSidebox) {
            this.secondSidebox.setData(reply.comboGroupId2, reply.leg2);
         }

      } catch (e) {

         this._toastr.error(
            '"Load Protective Option Data" operation completed with errors',
            'Protective Option'
         );

      } finally {

         this.isLoading = false;

      }

   }

   //
   private reset() {

      if (!this.combo) {

         this.firstSidebox.reset();

         if (this.secondSidebox) {
            this.secondSidebox.reset();
         }
      }

      this.protectiveOptionParametersComponent.reset();

   }
}
