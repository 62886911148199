import {CashFlowAdjustmentLogicalId} from "projects/shared-components/adjustment-control-panel/CashFlowAdjustmentId";
import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {SolutionOrderLegDto} from "projects/shared-components/adjustment-pricing-grid/model/SolutionOrderLegDto";
import {SolutionPositionDto} from "projects/shared-components/adjustment-pricing-grid/model/SolutionPositionDto";
import {CashFlowStrategyAdjustmentType} from "./CashFlowStrategyAdjustmentType";

export class CashFlowAdjustment {
    public uniqueAdjustmentSpecificationId: string;

    public cashFlowContext: string;

    public cashFlowStrategy: CashFlowStrategy;

    public underlying: string;

    public logicalId: CashFlowAdjustmentLogicalId;

    public logicalIdWithStrategy: string;

    public initialStrike: string;

    public destinationStrike: string;

    public direction: string;

    public adjustmentType: string;

    public isEmpty: boolean;

    public optionStrategyCode: string;

    public expiration: string;

    public expirationPretty: string;

    public mainLegs: SolutionOrderLegDto[];

    public linkedLegs: SolutionOrderLegDto[];

    public secondLinkedLegs: SolutionOrderLegDto[];

    public thirdLinkedLegs: SolutionOrderLegDto[];

    public beforeState: SolutionPositionDto[];

    public afterState: SolutionPositionDto[];

    public price: number;

    public marketDelta: number;

    public isDefault: boolean;

    public coupleTag: string;

    public description: string;

    public atmStrike: number;

    public dayOfWeek: string;

    public templateName: string;

    // Looks like: "26-May-23 (2d)"
    expirationDatePrettyWithDaysToExpiration: string;

    adjustmentTypeObj: CashFlowStrategyAdjustmentType;

    dynamicOffsets: boolean;

    actualSpreadOffset: number;
    actualSpreadWidth: number;
    actualSecondSpreadOffset: number;
    actualSecondSpreadWidth: number;
    actualProtectiveOptionOffset: number;
    actualSecondProtectiveOptionOffset: number;

    perContract: number;
    ratio: number;

    isPriceToOpen: boolean;

    // GUI only
    positionsVisible: boolean;

    extraType: 'increase' | 'decrease';

    extraPositions: SolutionPositionDto[];

    isPriceToClose: boolean;

    zonesGridStrikeDelta?: number;

    zonesGridRoles?: string[];
}
