<dx-popup
  width="50%"
  height="60%"
  [showTitle]="true"
  title="Rollover Scheduler"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onHidden)="onHidden()"
  (onShown)="onShown()"
>
  <div *dxTemplate="let data of 'content'" class="view-container">
      
   <as-split class="split" direction="vertical" [gutterSize]="5">
        <as-split-area [size]="sessionRulesSectionSize">
          <ag-grid-angular
            class="ag-theme-balham-dark"
            [gridOptions]="sessionRulesGridModel"
          ></ag-grid-angular>
        </as-split-area>
        <as-split-area [size]="positionalRulesSectionSize">
          <ag-grid-angular
            class="ag-theme-balham-dark"
            [gridOptions]="positionalRulesGridModel"
          ></ag-grid-angular>
        </as-split-area>
      </as-split>
    </div>

    <div class="action-btns">
      <dx-button text="Save Changes" (onClick)="saveChanges()"></dx-button>
      <dx-button text="Discard" (onClick)="onHidden()"></dx-button>
    </div>

</dx-popup>
