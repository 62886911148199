import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TradingInstrument} from "../../../trading-instruments/trading-instrument.class";
import {DetectMethodChanges, DetectSetterChanges, isVoid} from "../../../utils";
import {TradingInstrumentsService} from "../../../trading-instruments/trading-instruments-service.interface";
import {ChecklistEditorComponent} from "../../checklist-editor.component";
import {ChecklistStep} from "../../../adjustment-pricing-grid/popup/checklist/ChecklistStep";

@Component({
    selector: 'ets-checklist-asset-condition',
    template: `
      <div class="container">
        <ets-symbol-picker
          (instrumentSelected)="this.onSelected($event)"
          [disabled]="this.disabled"
          [selectedInstrument]="this.selectedInstrument"
          [showClearButton]="true"
          [showLastPrice]="false"
        >
        </ets-symbol-picker>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChecklistAssetConditionComponent implements OnInit, AfterViewInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _tiService: TradingInstrumentsService
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // this._changeDetector.detach();
    }

    selectedInstrument: TradingInstrument;

    @Input() comp: ChecklistEditorComponent;

    get disabled(): boolean {
        return isVoid(this.step);
    }

    private _step: ChecklistStep;
    get step():  ChecklistStep {
        return this._step;
    }

    @DetectSetterChanges()
    @Input()
    set step(value: ChecklistStep) {
        this._step = value;

        if (isVoid(this._step)) {
            this.selectedInstrument = undefined;
            return;
        }

        if (isVoid(this._step.assetCondition)) {
            this.selectedInstrument = undefined;
            return;
        }

        const ti = this._tiService.getInstrumentByTicker(this._step.assetCondition);

        this.selectedInstrument = ti;
    }

    @DetectMethodChanges()
    onSelected($event: TradingInstrument) {

        if (isVoid(this.step)) {
            return;
        }

        if (isVoid($event)) {
            this.step.assetCondition = undefined;
        } else {
            this.step.assetCondition = $event.ticker;
        }

        this.step.isDirty = true;
        this.comp.onChange({});
    }
}