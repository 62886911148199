<div class="root">
  <div class="btn-mode">
    <div #btnPause>
      <dx-button (onClick)="this.toggleDialog()" [text]="this.buttonText"></dx-button>
    </div>
    <div class="pause-text">
      {{ this.pausedUntilText }}
    </div>
  </div>
</div>

<ng-template #punchButton let-value>
  <div class="time-btn">
    <dx-button (onClick)="this.onApplyClicked(value)" [text]="value" height="100%" width="100%"></dx-button>
  </div>
</ng-template>

<dx-popover
  (onHidden)="this.onHidden()"
  [position]="this.position"
  [target]="btnPause"
  [width]="315"
>
  <div *dxTemplate="let data of 'content'" class="wrapper">
    <div class="content">
      
      <div class="punch-box">
        <div class="btn-row">
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '15m'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '30m'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '45m'}"></ng-container>
        </div>
        <div class="btn-row">
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '1h'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '2h'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '3h'}"></ng-container>
        </div>
        <div class="btn-row">
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '4h'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '5h'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: '6h'}"></ng-container>
        </div>
        <div class="btn-row">
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: 'For This Session'}"></ng-container>
          <ng-container *ngTemplateOutlet="punchButton; context: {$implicit: 'Pause Indefinitely'}"></ng-container>
        </div>
      </div>
      
      <div class="separator-text">
        - or pause till specific date & time -
      </div>
      
      <div class="custom-time">
        <div class="dt-comp">
          <ets-datetime-picker [(value)]="this.specificDateAndTime"
                               mode="datetime"
                               [showTodayButton]="false"
          ></ets-datetime-picker>
        </div>
        <div>
          <dx-button (onClick)="this.onApplyClicked()" text="Apply"></dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popover>