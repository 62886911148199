// tslint:disable: object-literal-key-quotes
// tslint:disable: no-string-literal

import {
   GetContextMenuItemsParams,
   GridOptions,
   CellClassParams,
   CellValueChangedEvent,
   MenuItemDef,
   ValueGetterParams,
   ValueFormatterParams,
   RowDoubleClickedEvent,
   RowNode
} from 'ag-grid-community';
import { TradingSystemsViewComponent } from './trading-systems-view.component';
import { StrategyRulesCellRendererComponent } from './strategy-commander-rules-cell-renderer.component';
import { StrategyState } from 'projects/shared-components/strategies/strategy-state.enum';
import {
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   centeredColumnDef,
   defaultPriceCellFormatter,
   liveQuoteFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { StrategyIssuesCellRendererComponent } from './strategy-issues-cell-renderer.component';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { AgGridColumn } from 'ag-grid-angular';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { StrategyFlags } from '../strategies/strategy-flags.enum';
import { isAdjustmentAlgo, isHedgingAlgo, isNullOrUndefined } from '../utils';
import { AdjustmentStrategiesViewComponent } from './adjustment-strategies-view.component';
import { StrategyTriggersCellRendererComponent } from './strategy-triggers-cell-renderer.component';


const STATUS_MAP = {
   'Disconnected': 1,
   'Started':      2,
   'Prestarted':   3,
   'Stopping':     4,
   'Activated':    5,
   'Listening':    6,
   'Blocking':     7,
   'Scheduled':    8,
   'Exited':       9,
};


const STATUS_COLOR_MAP = {
   'Disconnected': 'red',
   'Started':      'green',
   'Prestarted':   'green',
   'Stopping':     '#9c27b0',
   'Activated':    '#d3ff3a',
   'Listening':    'yellow',
   'Blocking':     'orange',
   'Scheduled':    'blue',
   'Exited':       '',
};


export function getTradingSystemsGridModel(
   this: TradingSystemsViewComponent | AdjustmentStrategiesViewComponent
): GridOptions {

   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;
   const columns = setupColumns(this, isDashboard);

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      popupParent: this.popupParent,

      groupUseEntireRow: true,

      rowGroupPanelShow: 'always',

      suppressCellSelection: true,

      tooltipShowDelay: 1000,

      frameworkComponents: {
         strategyIssuesCellRenderer: StrategyIssuesCellRendererComponent,
         strategyRulesCellRenderer: StrategyRulesCellRendererComponent,
         simpleGroupRowRenderer: SimpleHeaderGroupRowInnerRenderer,
         strategyTriggersCellRenderer: StrategyTriggersCellRendererComponent
      },

      groupRowRendererParams: {
         innerRenderer: 'simpleGroupRowRenderer',
         suppressCount: true
      },

      getRowStyle: (args) => {
         const data: StrategyModel = args.data;

         if (!data) {
            return { background: null, color: null };
         }

         if (!data.hasFlag(StrategyFlags.MaxPositionViolation)) {
            return { background: null, color: null };
         }

         if (data.state !== StrategyState.Active) {
            return { background: 'firebrick' };
         }

         return { background: 'orange' };
      },

      onGridReady: args => this.onStrategiesGridReady(args),

      onCellValueChanged: (args: CellValueChangedEvent) => {
         if (args.api && args.data) {
            const changedData = [args.data];
            args.api.applyTransaction({ update: changedData });
         }
      },

      onSelectionChanged: args => this.onSelectionChanged(),

      getRowNodeId: (strat: StrategyModel) => {
         return strat.strategyId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {

         let menu: Partial<MenuItemDef | string>[] = [];

         if (this.viewType === 'trading') {
            menu = buildTradingViewMenu(this, params);
         } else if (this.viewType === 'adjustment') {
            menu = buildAdjustmentViewMenu(this, params);
         }

         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),

      onRowDoubleClicked: (args: RowDoubleClickedEvent) => {
         if (!args.node) {
            return;
         }

         if (!args.node.isSelected()) {
            return;
         }

         if (args.node.group || !args.node.data) {
            return;
         } 
         
         this.onSelectionChanged();
      }

   } as GridOptions;
}

//

function buildAdjustmentViewMenu(comp: AdjustmentStrategiesViewComponent, params: GetContextMenuItemsParams): any[] {
   const menu: Partial<MenuItemDef | string>[] = [];
   
   const strategy: StrategyModel = params.node ? params.node.data : null;
            
   menu.push(
      {
         name: 'Update Strategy',
         disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
         action: () => comp.editStrategy(strategy, 'update', 'main')
      },
      'separator',
      {
         name: 'Enable Strategy',
         action: () => comp.startStrategy(strategy),
         disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state
      },
      {
         name: 'Disable Strategy',
         action: () => comp.exitStrategy(strategy),
         disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) !== strategy.state
      },
      {
         name: 'Set On Schedule',
         disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
         action: () => comp.setToStartStrategy(strategy)
      },
      'separator',
      {
         name: 'Remove',
         disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
         action: () => comp.removeStrategy(params.node.data)
      },
      'separator',
      {
         name: 'Show History',
         disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
         action: () => comp.requestSessionHistoryDialog(strategy)
      },
      {
         name: 'Clear Trading Data',
         disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
         action: () => comp.clearTradingDataForStrategy(strategy)
      },
      'separator'
   );

   const overrideActions = [];
   overrideActions.push({
      name: 'Disable',
      disabled: isNullOrUndefined(strategy),
      action: () => comp.overrideExitStrategy(strategy)
   });

   overrideActions.push({
      name: 'Reset',
      disabled: isNullOrUndefined(strategy),
      action: () => comp.overrideResetStrategy(strategy)
   });

   menu.push(
      {
         name: 'Override',
         subMenu: overrideActions
      }
   );

   menu.push(
      'separator',
      {
         name: 'Size To Fit',
         action: () => params.api.sizeColumnsToFit()
      },
      'autoSizeAll'
   );

   return menu;
}

//

function buildTradingViewMenu(comp: TradingSystemsViewComponent, params: GetContextMenuItemsParams): any[] {
   const menu: Partial<MenuItemDef | string>[] = [];

   const strategyModel: StrategyModel = params.node ? params.node.data : null;

   if (strategyModel) {

      if (isHedgingAlgo(strategyModel.algoId)) {

         if (!isAdjustmentAlgo(strategyModel.algoId)) {
            const hedgeActions = [];

            hedgeActions.push({
               name: 'Exit With Limit',
               disabled: strategyModel.state !== StrategyState.Active,
               action: () => comp.hedgeActionsExitWithLimit(),
            });

            menu.push({
               name: 'Hedge/Freeze...',
               subMenu: hedgeActions
            });
            menu.push('separator');
         }
      }
   }

   if (comp.securityContext.addStrategy) {
      menu.push({
         name: 'Add',
         action: () => comp.editStrategy(params.node ? params.node.data : null, 'add', 'main')
      });
   }

   if (comp.securityContext.updateStrategy) {
      menu.push({
         name: 'Update',
         disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
         action: () => comp.editStrategy(params.node.data, 'update', 'main')
      });
   }

   if (menu.length > 0) {
      menu.push('separator');
   }

   if (comp.securityContext.startStrategy) {
      menu.push({
         name: 'Enable',
         disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
         action: () => comp.startStrategy(params.node.data)
      });
   }

   if (comp.securityContext.exitStrategy) {
      menu.push({
         name: 'Disable',
         disabled: !params.node || (params.node.data.state & StrategyState.Enabled) !== params.node.data.state,
         action: () => comp.exitStrategy(params.node.data)
      });
   }

   if (comp.securityContext.setToStart) {
      menu.push({
         name: 'Set On Schedule',
         disabled: !params.node,
         action: () => comp.setToStartStrategy(params.node.data)
      });
   }

   if (comp.securityContext.pauseStrategy) {
      if (params.node && params.node.data) {
         const strategy = params.node.data as StrategyModel;
         menu.push({
            name: strategy.hasFlag(StrategyFlags.PauseAutoSchedule) ? 'Un-Pause' : 'Pause',
            disabled: !params.node || !params.node.data.isSession,
            action: () => comp.pauseStrategy(params.node.data)
         });
      }
   }

   if (comp.securityContext.stopStrategy) {
      menu.push({
         name: 'Stop',
         disabled: !params.node || (params.node.data.state & StrategyState.Enabled) !== params.node.data.state,
         action: () => comp.stopStrategy(params.node.data)
      });
   }

   if (comp.securityContext.removeStrategy) {
      menu.push({
         name: 'Remove',
         disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
         action: () => comp.removeStrategy(params.node.data)
      });
   }

   const groupActions = [];
   if (comp.securityContext.startStrategy) {
      groupActions.push({
         name: 'Enable',
         action: () => comp.startAllStrategies()
      });
   }

   if (comp.securityContext.stopStrategy) {
      groupActions.push({
         name: 'Stop',
         action: () => comp.stopAllStrategies()
      });
   }

   if (comp.securityContext.exitStrategy) {
      groupActions.push({
         name: 'Disable',
         action: () => comp.exitAllStrategies()
      });
   }

   if (comp.securityContext.setToStart) {
      groupActions.push({
         name: 'Set On Schedule',
         action: () => comp.setAllStrategiesToStart()
      });
   }

   if (comp.securityContext.freezeUnfreeze) {
      groupActions.push({
         name: 'Freeze All',
         action: () => comp.changeFreezeAllStrategies('freeze')
      });
      groupActions.push({
         name: 'Unfreeze All',
         action: () => comp.changeFreezeAllStrategies('unfreeze')
      });
   }

   if (groupActions.length > 0) {
      menu.push({
         name: 'All Strategies...',
         subMenu: groupActions
      });
   }

   const overrideActions = [];
   if (comp.securityContext.overrideExitStrategy) {
      overrideActions.push({
         name: 'Disable',
         disabled: !params.node,
         action: () => comp.overrideExitStrategy(params.node.data)
      });
   }

   if (comp.securityContext.overrideResetStrategy) {
      overrideActions.push({
         name: 'Reset',
         disabled: !params.node,
         action: () => comp.overrideResetStrategy(params.node.data)
      });
   }

   if (comp.securityContext.overridePosition) {
      overrideActions.push({
         name: 'Positions',
         disabled: !params.node
      });
   }

   if (overrideActions.length > 0) {
      menu.push({
         name: 'Override',
         disabled: !params.node,
         subMenu: overrideActions
      });
   }

   if (menu.length > 0) {
      menu.push('separator');
   }

   if (comp.securityContext.customExits) {
      menu.push({
         name: 'Custom Exits',
         disabled: !params.node || params.node.group,
         action: () => comp.showCustomExistsDialog(params.node.data)
      });
   }

   const flagsActions = [];
   if (comp.securityContext.strategyIssues) {
      if (strategyModel && strategyModel.hasFlag(StrategyFlags.MaxPositionViolation)) {
         flagsActions.push({
            name: 'Ack MP Violation',
            action: () => comp.toggleStrategyFlag(strategyModel, StrategyFlags.MaxPositionViolation)
         });
      }
   }

   menu.push({
      name: 'Flag...',
      disabled: !params.node,
      subMenu: flagsActions
   });

   if (menu.length > 0) {
      menu.push('separator');
   }

   if (comp.securityContext.duplicateStrategy) {
      menu.push({
         name: 'Duplicate',
         disabled: !params.node,
         action: () => comp.duplicateStrategy(params.node.data)
      });
   }

   if (comp.securityContext.systemDetails) {
      menu.push({
         name: 'System Details',
         disabled: !params.node,
         action: () => comp.showSystemDetails(params.node.data)
      });
   }

   if (comp.securityContext.strategyIssues) {
      menu.push({
         name: 'Show Issues',
         disabled: !params.node,
         action: () => comp.showIssues(params.node.data)
      });
   }

   if (comp.securityContext.rollNextMonth) {
      menu.push({
         name: 'Roll Next Month',
         disabled: !params.node,
         action: () => comp.rollNextMonth(params.node.data)
      });
   }

   if (comp.securityContext.strategyHistory) {
      menu.push({
         name: 'Show History',
         disabled: !params.node || params.node.group || !params.node.data,
         action: () => comp.requestSessionHistoryDialog(params.node.data)
      });
   }

   if (comp.securityContext.freezeUnfreeze) {
      if (params.node) {
         menu.push('separator');
         menu.push({
            name: 'Freeze',
            action: () => comp.changeFreezeOnStrategy('freeze')
         });
         menu.push({
            name: 'Unfreeze',
            action: () => comp.changeFreezeOnStrategy('unfreeze')
         });
      }
   }

   if (comp.securityContext.clearTradingData) {
      menu.push(
         'separator',
         {
            name: 'Clear Trading Data',
            disabled: !params.node,
            action: () => comp.clearTradingDataForStrategy(params.node.data)
         });
   }

   if (menu.length > 0) {
      menu.push('separator');
   }

   menu.push({
      name: 'Show Positions Grid',
      action: () => (comp.showStrategyPositionsGrid = !comp.showStrategyPositionsGrid),
      checked: comp.showStrategyPositionsGrid
   });

   menu.push({
      name: 'Show Inner Strategies Grid',
      action: () => (comp.showInnerStrategiesGrid = !comp.showInnerStrategiesGrid),
      checked: comp.showInnerStrategiesGrid
   });


   menu.push('separator');

   menu.push({
      name: 'Move To Portfolio...',
      action: () => comp.addToPortfolio('existing', params.node.data),
      disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
   });

   menu.push('separator', 'autoSizeAll', 'copy', 'export');

   return menu;
}

//

function setupColumns(comp: TradingSystemsViewComponent, isDashboard: boolean): Partial<AgGridColumn>[] {

   const columns: Partial<AgGridColumn>[] = [];

   columns.push({
      headerName: '',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 80
   });

   if (comp.securityContext.strategyIssues) {
      columns.push({
         headerName: '!',
         field: 'issues',
         width: 60,
         cellRenderer: 'strategyIssuesCellRenderer'
      });
   }
   
   if (true) {
      columns.push({
         headerName: 'T',
         field: 'triggers',
         maxWidth: 100,
         cellRenderer: 'strategyTriggersCellRenderer'
      });
   }

   if (isDashboard) {
      columns.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 0
      });

      columns.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 1
      });
   }

   if (comp.viewType === 'trading') {
      columns.push({
         headerName: 'Mode',
         field: 'mode',
         enableRowGroup: true
      });
   }

   columns.push({
      headerName: 'Name',
      field: 'displayName',
      tooltipField: 'displayName',
      sortIndex: 1,
      sort: 'asc',
      sortable: true,
      comparator: (valueA: string, valueB: string, nodeA, nodeB, isDescending) => {
         if (valueA === valueB) { return 0; }
         if (!valueA) { return -1; }
         if (!valueB) { return 1; }

         return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
     }
   });

   if (comp.securityContext.systemDetails) {
      columns.push({
         headerName: 'Description',
         field: 'description',
         headerComponentParams: {},
         cellStyle: (args) => {
            if (args.node.group) {
               return undefined;
            }

            const strategy: StrategyModel = args.data;

            if (strategy) {
               return {
                  'text-align': 'left',
                  'color': args.data.isAboutToExpire ? 'coral' : null
               };
            } else {
               return null;
            }

         }
      });
   }

   if (comp.viewType === 'trading') {
      columns.push({
         headerName: 'Units',
         field: 'unitsAllocated'
      });
   }

   columns.push({
      headerName: 'State',
      enableRowGroup: true,
      field: 'state',
      valueFormatter: (params: ValueFormatterParams) => {
         
         const v: StrategyState = params.value;
         
         let retval = StrategyState[params.value];

         switch (v) {
            case StrategyState.Activating:
               retval = 'Enabling';
               break;
            case StrategyState.Active:
               retval = 'Enabled';
               break;
            case StrategyState.Deactivating:
               retval = 'Disabling';
               break;
            case StrategyState.Inactive:
               retval = 'Disabled';
               break;
            case StrategyState.RiskViolation:
               retval = 'Risk Violation';
               break;
            default:
               break;
         }

         return retval;

      }
   });

   columns.push({ headerName: 'Algo', field: 'algo', enableRowGroup: true });

   columns.push({ 
      headerName: 'Status', 
      field: 'status', 
      enableRowGroup: false,
      sortable: true,
      sortIndex: 0,
      sort: 'asc',
      comparator: (valueA, valueB, nodeA: RowNode, nodeB: RowNode, isDescending: boolean) => {

         const a = valueA;
         const b = valueB;

         if (a === b) {
            return 0;
        }
        
         if (!a) {
            return 1;
        }
        
         if (!b) {
            return 1;
        }
    
         const aMapNum = STATUS_MAP[a] || 99;
         const bMapNum = STATUS_MAP[b] || 99;
       
         if (aMapNum > bMapNum) {
            return 1;
        }
    
         if (aMapNum < bMapNum) {
            return -1;
        }
    
         return 0;
      },
      cellStyle: (args: CellClassParams) => {
         const status = args.value;
         
         let bgColor = STATUS_COLOR_MAP[status] || '';
         
         let color = '';
         
         if (status === 'Blocking' || status === 'Listening' || status === 'Activated') {
            color = 'black';
         }

         if (status === 'Exited') {
            const model = args.data as StrategyModel;

            if (model.hasBeenActiveDuringTheSession) {
               bgColor = 'grey';
            }
         }
         
         return { 'background-color': bgColor, 'color': color };
      }
   });

   columns.push({
      headerName: 'Position',
      field: 'netPosition',
      valueFormatter: (args: ValueFormatterParams) => {
         
         if (isAdjustmentAlgo(args.data.algoId)) {
            return '*';
         }
         
         if (isNaN(args.value)) {
            return '*';
         }
         
         return args.value;
      }
   });

   columns.push({
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueFormatter: defaultPriceCellFormatter
   }); 

   if (comp.viewType === 'trading') {
      columns.push({
         headerName: 'Live Quote',
         field: 'liveQuote',
         valueFormatter: liveQuoteFormatter
      });
   }

   columns.push(Object.assign({ headerName: 'Session P&L', field: 'hedgedPositionsSessionTotalPnL' }, defaultMoneyCellDefinition));

   columns.push(Object.assign({ headerName: 'Acc. P&L', field: 'hedgedPositionsAccumulatedTotalPnL' }, defaultMoneyCellDefinition));

   if (comp.viewType === 'trading') {
      columns.push({ headerName: 'Account', field: 'brokerage', enableRowGroup: true });
      
      columns.push({ headerName: 'Terminal', field: 'terminalCode', enableRowGroup: true });
      
      columns.push({ headerName: '# Rules', field: 'numberOfCommanderRules', cellRenderer: 'strategyRulesCellRenderer' });
      
      columns.push({
         headerName: 'S/P',
         field: 'isSession',
         enableRowGroup: true,
         valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? 'S' : 'P';
         },
         cellStyle: (params: CellClassParams) => {
            return params.value ? { color: 'yellow', 'font-weight': 'bold' } : null;
         }
      });

      columns.push({
         headerName: 'Flags',
         field: 'flagsData',
      }
      );
   
      columns.push({
         headerName: 'Last Match Px',
         field: 'lastPx',
         valueFormatter: defaultQuoteCellFormatter
      });

      columns.push(Object.assign({ headerName: 'Trade P&L', field: 'tradePnL' }, defaultMoneyCellDefinition));

      columns.push({
         headerName: 'Timezone',
         valueGetter(args: ValueGetterParams) {
            const str: StrategyModel = args.data;
            if (!str) {
               return 'N/A';
            }
            const zone = str.parameters['timestampszone'];
            return zone || 'N/A';
         }
      });
   }

   columns.push({
      headerName: 'Portfolio',
      field: 'portfolioName',
      tooltipField: 'portfolioName',
      // enableRowGroup: true,
      // rowGroupIndex: 4
   });

   columns.push({
      headerName: 'Combo',
      field: 'comboName',
      tooltipField: 'comboName',
      // enableRowGroup: true,
      // rowGroupIndex: 5
   });

   columns.push({
      headerName: 'ComboGroup',
      field: 'comboGroupName',
      tooltipField: 'comboGroupName',
      // enableRowGroup: true,
      // rowGroupIndex: 6
   });

   columns.push({
      headerName: 'Pos. Sizing',
      field: 'positionSizingSource'
   });

   return columns;
}
