import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {OverridePositionTokenDto} from '../../dtos/override-position-token-dto.interface';

export class OverridePosition implements ProtocolCommand {
  constructor(public readonly overrideTokens: OverridePositionTokenDto[]) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.OverridePosition`;
  }
}
