import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FusionAlgoParametersControl } from './fusion-algo-parameters-control';
import { EditStrategyDialogComponent } from '../../../edit-strategy-dialog.component';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';

@Component({
  selector: 'ets-fusion-parameters-view',
  templateUrl: 'fusion-parameters-view.component.html',
  styleUrls: ['fusion-parameters-view.component.scss']
})

export class FusionParametersViewComponent implements OnInit {
  constructor() {
  }

  @Input() control: FusionAlgoParametersControl;

  @ViewChild(EditStrategyDialogComponent, { static: true }) editStrategyDialog: EditStrategyDialogComponent;

  ngOnInit() {
    this.control.showEditor.subscribe((x) => {
      setTimeout(() => this.editStrategyDialog.show(x), 0);
    });
  }

  onInnerStrategyAdded(dto: StrategyDto) {
    this.control.addInnerStrategy(dto);
  }

  onInnerStrategyUpdated(dto: StrategyDto) {
    this.control.updateInnerStrategy(dto);
  }
}
