import { GetStrategyLogMessagesWeb } from '../shell-communication/operations/strategies/get-strategy-log-messages-web.class';
import { GetTradingDataResponseWeb } from '../shell-communication/dtos/get-trading-data-response-web.class';
import { GetStrategyIssuesWeb } from '../shell-communication/operations/strategies/get-strategy-issues-web.class';
import { StrategyIssueDto } from '../shell-communication/dtos/strategy-issue-dto.class';
import { AckStrategyIssue } from '../shell-communication/operations/strategies/ack-strategy-issue.class';
import { CancelAllCustomExitOrders, CancelCustomExitOrder, GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb } from '../shell-communication/shell-operations-protocol';
import { GetAvailableBucketsReply } from '../shell-communication/shell-dto-protocol';

export abstract class StrategiesTradingDataBackendService {
  abstract getAvailableBuckets(): Promise<GetAvailableBucketsReply>;
  abstract ackIssues(cmd: AckStrategyIssue): Promise<void>;
  abstract getIssues(qry: GetStrategyIssuesWeb): Promise<StrategyIssueDto[]>;
  abstract getWorkingOrders(qry: GetWorkingOrdersWeb): Promise<GetTradingDataResponseWeb>;
  abstract requestOrderStatus(orders: { orderId: string, shellId: string }[]): Promise<void>;
  abstract getOrderStateSnapshots(qry: GetOrderStateSnapshotsWeb): Promise<GetTradingDataResponseWeb>;
  abstract getTrades(qry: GetTradesWeb): Promise<GetTradingDataResponseWeb>;
  abstract getMessages(qry: GetStrategyLogMessagesWeb): Promise<GetTradingDataResponseWeb>;
  abstract cancelAllCustomExitOrders(cmd: CancelAllCustomExitOrders): Promise<void>;
  abstract cancelCustomExitOrder(cmd: CancelCustomExitOrder): Promise<void>;
}
