import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class DeleteArchivedPositions implements ProtocolCommand {
  constructor(public readonly positionsIds: string[]) { }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteArchivedPositions`;
  }
}
