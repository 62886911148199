import { SyncOverrideHistoryComponent } from './sync-override-history.component';
import { GridOptions, CellClassParams } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate, defaultNumberCellFormatter, defaultMoneyCellDefinition } from 'projects/shared-components/ag-grid-contrib';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { SimpleHeaderGroupRowInnerRenderer } from 'projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component';


export function getSyncOverrideDetailStrategiesGridModel(
   this: SyncOverrideHistoryComponent
): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Name',
            field: 'displayName'
         },
         {
            headerName: 'Terminal',
            field: 'terminal',
         },
         {
            headerName: 'Position',
            field: 'netPosition',
            valueFormatter: defaultNumberCellFormatter
         },
         Object.assign(
            { headerName: 'Before P&L', field: 'beforePnL' },
            defaultMoneyCellDefinition
         ),
         Object.assign(
            { headerName: 'After P&L', field: 'afterPnL' },
            defaultMoneyCellDefinition
         ),
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      groupUseEntireRow: true,

      frameworkComponents: {
         simpleGroupRowRenderer: SimpleHeaderGroupRowInnerRenderer
      },

      groupRowRendererParams: {
         innerRenderer: 'simpleGroupRowRenderer',
         suppressCount: true
      },

      suppressCellSelection: true,

      onGridReady: args => this.onGridReady(args, 'detailStrategy'),

      getRowNodeId: (data: any) => {
         return data.syncOverrideRecordDetailId;
      },

      onFirstDataRendered: () => this.autoSizeColumns('detailStrategy'),

      getContextMenuItems: () => {
         return ['autoSizeAll', 'copy', 'export'];
      },


   } as GridOptions;
}
