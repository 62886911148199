import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StrategyIssuesBrowserComponent } from 'projects/shared-components/strategy-issues/strategy-issues-browser.component';
import { ShortTermSpreadOverviewBlockViewModel } from './short-term-spread-overview-block.model';

@Component({
   selector: 'ets-short-term-spread-overview-block',
   templateUrl: 'short-term-spread-overview-block.component.html',
   styleUrls: ['short-term-spread-overview-block.component.scss', '../table-styles.scss']
})

export class ShortTermSpreadOverviewBlockComponent implements OnInit {
   constructor() { }

   private _viewModel: ShortTermSpreadOverviewBlockViewModel;
   get viewModel(): ShortTermSpreadOverviewBlockViewModel {
      return this._viewModel;
   }

   @Input()
   set viewModel(v: ShortTermSpreadOverviewBlockViewModel) {
      this._viewModel = v;
   }

   @Input() mode: string;

   ngOnInit() { }
}
