import { UnsubscribePriceBars } from '../shell-communication/operations/price-charts/unsubscribe-price-bars.class';
import { SubscribePriceBars } from '../shell-communication/operations/price-charts/subscribe-price-bars.class';
import { GetPriceBars } from '../shell-communication/operations/price-charts/get-price-bars.class';
import { PriceBarDto } from '../shell-communication/dtos/price-bar-dto.interface';

export abstract class PriceDataFeedBackendService {
  abstract subscribePriceBars(cmd: SubscribePriceBars): Promise<void>;
  abstract unsubscribePriceBars(cmd: UnsubscribePriceBars): Promise<void>;
  abstract getPriceBars(qry: GetPriceBars): Promise<PriceBarDto[]>;
}
