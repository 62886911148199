import { Component, Input, OnInit } from '@angular/core';
import {
   findHCF,
   getActionClass,
   getBucketRoleClass,
   getPriceClass,
   getQtyClass, isValidNumber, isVoid
} from 'projects/shared-components/utils';
import { SolutionOrderLegDto } from '../../model/SolutionOrderLegDto';
import { SolutionPositionDto } from '../../model/SolutionPositionDto';
import {CashFlowStrategyRole} from "../../../shell-communication/shell-dto-protocol";
import {CashFlowAdjustment} from "../../model/CashFlowAdjustment";
import {AdjustmentPricingGridComponent} from "../../adjustment-pricing-grid.component";
import {AdjustmentSolutionPopupComponent} from "../../solution-popup/adjustment-solution-popup.component";
import {AdjustmentPricingSettingsDto} from "../../../shell-communication/shell-operations-protocol";

@Component({
   selector: 'ets-adjustment-grid-orders',
   templateUrl: './adjustment-grid-orders.component.html',
   styleUrls: [
      './adjustment-grid-orders.component.scss',
      '../../adjustment-grid-common-style.scss'
   ]
})
export class AdjustmentGridOrdersComponent implements OnInit {

   constructor() { }

   private _originalHcfRatio: number;


   @Input()
   orders: SolutionOrderLegDto[];

   @Input()
   header: string;

   @Input()
   readOnly: boolean;

   @Input()
   adjustmentPricing: CashFlowAdjustment;

   @Input() root: AdjustmentSolutionPopupComponent;

   private _qtyMultiplier = 1;
   get qtyMultiplier(): number {
      return this._qtyMultiplier * (this._originalHcfRatio || 1);
   }

   @Input()
   set qtyMultiplier(value: number) {
      this._qtyMultiplier = value;
   }

   @Input()
   legRatios: Record<CashFlowStrategyRole, number> = {} as any;

   hcf = 1;

   ngOnInit(): void {
      const hcf = findHCF(this.orders.map(x =>  Math.abs(x.qty)));
      const originalHcfRatio = hcf / this.qtyMultiplier;
      this.hcf = hcf;
      this._originalHcfRatio = originalHcfRatio;
   }

   getOrderLegStrike(orderLeg: SolutionOrderLegDto) {
      const strike = parseInt(orderLeg.strike) + (this.adjustmentPricing.zonesGridStrikeDelta || 0);
      return isValidNumber(strike, true) ? strike : '';
   }

   getOrderLegPrice(orderLeg: SolutionOrderLegDto) {
      const ratio = this.legRatios[orderLeg.role] || orderLeg.qty;
      return (orderLeg.price * ratio);
   }

   //
   getPositionRoleClass(pos: SolutionPositionDto | SolutionOrderLegDto): string {
      return getBucketRoleClass(pos);
   }

   //
   getQtyClass(position: any): any {
      return getQtyClass(position);
   }

   //
   getActionClass(order: SolutionOrderLegDto, isEvenRow: boolean) {
      const classes = [];

      const actionClass = getActionClass(order);

      classes.push(actionClass);

      if (isEvenRow) {
         classes.push('couple-start');
      } else {
         classes.push('couple-end');
      }

      return classes;
   }

   //
   getPriceClass(order: SolutionOrderLegDto) {
      return getPriceClass(order);
   }

   //
   getPriceClassForTotal() {
      const total = this.getTotalPrice();
      const item = { price: total };
      return getPriceClass(item);
   }

   //
   getTotalPrice(): number {
      const pricingBase = this.getPricingBase(this.orders);
      const sum = pricingBase.map(o => o.price * o.qty).reduce((p, c) => p + c, 0);
      return sum;
   }

   //
   getSubTotalPrice(order, i): number {

      const pricingBase = this.getPricingBase(this.orders);

      const nextIx = i+1;

      let px = pricingBase[i].price * pricingBase[i].qty;

      if (nextIx < pricingBase.length) {
         const nextOrder = pricingBase[nextIx];
         px += nextOrder.price * nextOrder.qty;
      }

      return px;
   }

   //
   getPriceClassForSubTotal(order, i) {
      const subTotal = this.getSubTotalPrice(order, i);
      const item = { price: subTotal };
      return getPriceClass(item);
   }

   private getPricingBase(orders: SolutionOrderLegDto[]): {price: number, qty: number}[] {
      orders = orders || [];
      const mapped = orders.map(x => ({price: x.price, qty: Math.abs(x.qty)}));
      mapped.forEach(x => x.qty = x.qty / this.qtyMultiplier);
      return mapped;
   }

   isTheoPrice(orderLeg: SolutionOrderLegDto) {
      if (isVoid(orderLeg)) {
         return false;
      }

      let lastUsedSettings : AdjustmentPricingSettingsDto[];

      if (this.root?.source === 'PriceBox') {
         lastUsedSettings = this.root?.root?.priceboxSection?.lastUsedSettings;
      } else if (this.root?.source === 'Zones Grid') {
         lastUsedSettings = this.root?.root?.zonesGridSection?.lastUsedSettings;
      }

      if (isVoid(lastUsedSettings)) {
         if (this.root?.root?.settingsSection?.globalSettings?.theoreticalPriceMode !== 'Fill Blanks') {
            return false;
         }
      } else {
         if (lastUsedSettings[0].theoreticalPriceMode !== 'Fill Blanks') {
            return false;
         }
      }

      return orderLeg.ticker.indexOf(' !') > 0;
   }
}
