import { Injectable } from '@angular/core';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto } from '../shell-communication/shell-dto-protocol';
import { ArchiveBucket, CreateCombo, CreateComboGroup, CreatePortfolio, DeleteBucket, GetAvailableBuckets, GetComboGroupsByComboId, GetCombosByPortfolioId, GetCombosByTerminal, GetPortfolios, RenameBucket } from '../shell-communication/shell-operations-protocol';

@Injectable({providedIn: 'root'})
export class PortfoliosService {

   constructor(
      private readonly _shellClient: ShellClientService
   ) { }

   getAvailableBuckets(): Promise<GetAvailableBucketsReply> {
      const qry = new GetAvailableBuckets();
      return this._shellClient.processQuery<GetAvailableBucketsReply>(qry);
   }

   getComboGroupsByComboId(comboId: string): Promise<ComboGroupDto[]> {
      const qry = new GetComboGroupsByComboId(comboId, true);
      return this._shellClient.processQuery(qry);
   }

   getCombosByPortfolioId(portfolioId: string): Promise<ComboDto[]> {
      const qry = new GetCombosByPortfolioId(portfolioId, true);
      return this._shellClient.processQuery(qry);
   }

   getCombosByTerminal(terminalId: string): ComboDto[] | PromiseLike<ComboDto[]> {
      const qry = new GetCombosByTerminal(terminalId, true);
      return this._shellClient.processQuery(qry);
   }
   
   getPortfolios(): Promise<PortfolioDto[]> {
      const qry = new GetPortfolios(true);
      return this._shellClient.processQuery(qry);
   }
   
   renameComboGroup(comboGroupId: string, comboGroupName): Promise<void> {
      const cmd = new RenameBucket(comboGroupId, comboGroupName, 'group');
      return this._shellClient.processCommand(cmd);
   }

   renameCombo(comboId: string, comboName: string): Promise<void> {
      const cmd = new RenameBucket(comboId, comboName, 'combo');
      return this._shellClient.processCommand(cmd);
   }

   renamePortfolio(portfolioId: string, portfolioName: string): Promise<void> {
      const cmd = new RenameBucket(portfolioId, portfolioName, 'portfolio');
      return this._shellClient.processCommand(cmd);
   }

   createComboGroup(comboGroupName: string, portfolioId: string, comboId: string): Promise<void> {
      const cmd = new CreateComboGroup(portfolioId, comboId, comboGroupName);
      return this._shellClient.processCommand(cmd);
   }

   createCombo(
      comboName: string, 
      portfolioId: string, 
      templateName: string, 
      isAuto: boolean
   ): Promise<void> {
      const cmd = new CreateCombo(portfolioId, comboName, templateName, isAuto);
      return this._shellClient.processCommand(cmd);
   }

   createPortfolio(portfolioName: string, terminalId: string): Promise<void> {
      const cmd = new CreatePortfolio(portfolioName, terminalId);
      return this._shellClient.processCommand(cmd);
   }

   deleteComboGroup(portfolioItemId: string): Promise<void> {
      const cmd = new DeleteBucket(portfolioItemId, 'group');
      return this._shellClient.processCommand(cmd);
   }
   
   archiveComboGroup(comboGroupId: string): Promise<void> {
      const cmd = new ArchiveBucket(comboGroupId, 'ComboGroup');
      return this._shellClient.processCommand(cmd);
   }

   deleteCombo(comboId: string): Promise<void> {
      const cmd = new DeleteBucket(comboId, 'combo');
      return this._shellClient.processCommand(cmd);
   }

   archiveCombo(comboId: string): Promise<void> {
      const cmd = new ArchiveBucket(comboId, 'Combo');
      return this._shellClient.processCommand(cmd);
   }

   deletePortfolio(portfolioId: string): Promise<void> {
      const cmd = new DeleteBucket(portfolioId, 'portfolio');
      return this._shellClient.processCommand(cmd);
   }

   archivePortfolio(portfolioId: string): Promise<void> {
      const cmd = new ArchiveBucket(portfolioId, 'Portfolio');
      return this._shellClient.processCommand(cmd);
   }

}
