import { NgModule } from '@angular/core';
import { PositionSizingReportComponent } from './position-sizing-report.component';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { DevExtremeModule } from '../devextreme.module';
import { ProfitCellRendererComponent } from './profit-cell-renderer.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      AgGridModule.withComponents([ProfitCellRendererComponent])
   ],
   exports: [ProfitCellRendererComponent],
   declarations: [PositionSizingReportComponent, ProfitCellRendererComponent],
   providers: [],
})
export class PositionSizingReportModule { }
