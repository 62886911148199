import {NgModule} from '@angular/core';
import {DxAutocompleteModule} from 'devextreme-angular/ui/autocomplete';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxCheckBoxModule} from 'devextreme-angular/ui/check-box';
import {DxContextMenuModule} from 'devextreme-angular/ui/context-menu';
import {DxDateBoxModule} from 'devextreme-angular/ui/date-box';
import {DxDropDownBoxModule} from 'devextreme-angular/ui/drop-down-box';
import {DxListModule} from 'devextreme-angular/ui/list';
import {DxLoadIndicatorModule} from 'devextreme-angular/ui/load-indicator';
import {DxLoadPanelModule} from 'devextreme-angular/ui/load-panel';
import {DxNumberBoxModule} from 'devextreme-angular/ui/number-box';
import {DxPopupModule} from 'devextreme-angular/ui/popup';
import {DxProgressBarModule} from 'devextreme-angular/ui/progress-bar';
import {DxRadioGroupModule} from 'devextreme-angular/ui/radio-group';
import {DxScrollViewModule} from 'devextreme-angular/ui/scroll-view';
import {DxSelectBoxModule} from 'devextreme-angular/ui/select-box';
import {DxSwitchModule} from 'devextreme-angular/ui/switch';
import {DxTabsModule} from 'devextreme-angular/ui/tabs';
import {DxTextAreaModule} from 'devextreme-angular/ui/text-area';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {DxTooltipModule} from 'devextreme-angular/ui/tooltip';
import {DxTreeListModule} from 'devextreme-angular/ui/tree-list';
import {DxTreeViewModule} from 'devextreme-angular/ui/tree-view';
import {DxValidatorModule} from 'devextreme-angular/ui/validator';
import {DxMultiViewModule} from 'devextreme-angular/ui/multi-view';
import {DxBoxModule} from 'devextreme-angular/ui/box';
import {DxDropDownButtonModule} from 'devextreme-angular/ui/drop-down-button';
import {DxCalendarModule} from 'devextreme-angular/ui/calendar';
import {DxPopoverModule} from 'devextreme-angular/ui/popover';
import {DxAccordionModule} from 'devextreme-angular/ui/accordion';
import {DxFormModule} from 'devextreme-angular/ui/form';
import {DxHtmlEditorModule} from 'devextreme-angular/ui/html-editor';
import {DxColorBoxModule, DxDraggableModule} from "devextreme-angular";

@NgModule({
    imports: [
        DxLoadPanelModule,
        DxPopupModule,
        DxButtonModule,
        DxProgressBarModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxLoadIndicatorModule,
        DxTreeViewModule,
        DxScrollViewModule,
        DxTabsModule,
        DxListModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxRadioGroupModule,
        DxDropDownBoxModule,
        DxValidatorModule,
        DxTooltipModule,
        DxContextMenuModule,
        DxRadioGroupModule,
        DxTreeListModule,
        DxAutocompleteModule,
        DxTextAreaModule,
        DxSwitchModule,
        DxLoadIndicatorModule,
        DxMultiViewModule,
        DxBoxModule,
        DxDropDownButtonModule,
        DxCalendarModule,
        DxPopoverModule,
        DxAccordionModule,
        DxFormModule,
        DxHtmlEditorModule,
        DxDraggableModule
    ],
    exports: [
        DxLoadPanelModule,
        DxPopupModule,
        DxButtonModule,
        DxProgressBarModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxLoadIndicatorModule,
        DxTreeViewModule,
        DxScrollViewModule,
        DxTabsModule,
        DxListModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxRadioGroupModule,
        DxDropDownBoxModule,
        DxValidatorModule,
        DxTooltipModule,
        DxContextMenuModule,
        DxRadioGroupModule,
        DxTreeListModule,
        DxAutocompleteModule,
        DxTextAreaModule,
        DxSwitchModule,
        DxLoadIndicatorModule,
        DxMultiViewModule,
        DxBoxModule,
        DxDropDownButtonModule,
        DxCalendarModule,
        DxPopoverModule,
        DxAccordionModule,
        DxFormModule,
        DxHtmlEditorModule,
        DxColorBoxModule,
        DxDraggableModule
    ],
    declarations: [],
    providers: [],
})
export class DevExtremeModule {
}
