import {SolutionOrderLegDto} from "../adjustment-pricing-grid/model/SolutionOrderLegDto";
import {CashFlowStrategyRole} from "../shell-communication/shell-dto-protocol";
import {HedgeOrder} from "../hedging-grid/data-model/hedge-order";
import {OrdersGroupToRegroup} from "./OrdersGroupToRegroup";

export class OrderToRegroupWrapper {
    constructor(
        private readonly _order: SolutionOrderLegDto | HedgeOrder,
        public readonly color?: string | undefined
    ) {
    }

    get order(): SolutionOrderLegDto | HedgeOrder {return this._order;};
    get ticker(): string { return this._order.ticker; };
    get action(): string { return this._order.action; };
    get underlying(): string {
        const ord = this._order as any;
        return ord.underlying || ord.asset;
    };
    get type(): 'Call' | 'Put' {return this._order.type; };
    get strike(): string {
        return this._order.strike + '';
    };
    get expiration(): string {return this._order.expiration; };
    get role(): CashFlowStrategyRole {return (this._order as any).role; };
    get price(): number {return this._order.price; };
    get qty(): number {return this._order.qty; };
    settledQty = 0;
    // moved: boolean;
    group: OrdersGroupToRegroup;

    get leftOverQty() : number {
        return this.qty - (this.settledQty || 0);
    }

    get isSettled() : boolean {
        return this.settledQty === this.qty;
    }

    adjustSettledQty(qty: number): boolean {
        const newSettledQty = (this.settledQty || 0) + qty;

        if (Math.abs(newSettledQty) > Math.abs(this.qty)) {
            return false;
        }

        this.settledQty += qty;

        return true;
    }

}

export class DropZoneOrder {
    constructor(
        private readonly _order: OrderToRegroupWrapper,
    ) {
        this.qty = _order.leftOverQty;
        _order.adjustSettledQty(this.qty);
    }

    get ticker(): string { return this._order.ticker; };

    get action(): string { return this._order.action; };

    get underlying(): string {
        const ord = this._order.order as any;
        return ord.underlying || ord.asset;
    };

    get type(): 'Call' | 'Put' {return this._order.type; };

    get strike(): string {
        return this._order.strike + '';
    };

    get expiration(): string {return this._order.expiration; };

    get role(): CashFlowStrategyRole {return (this._order as any).role; };

    get price(): number {return this._order.price; };

    qty: number;

    get order(): SolutionOrderLegDto | HedgeOrder {return this._order.order;};

    get color() {
        return this._order.color;
    }

    group: OrdersGroupToRegroup;

    adjustSettledQty(delta: number): boolean {
        return this._order.adjustSettledQty(delta);
    }

    onRemoved() {
        this._order.adjustSettledQty(-this.qty);
    }
}