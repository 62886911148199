import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { StrategyCommanderRuleEditorComponent } from './strategy-commander-rule-editor/strategy-commander-rule-editor.component';
import { StrategyCommanderRuleModel } from './strategy-commander-rule-model';
import { getStrategyCommanderGridModel } from './strategy-commander-grid-model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Guid from 'devextreme/core/guid';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService,

} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { StrategiesService } from '../../../../shared-components/strategies/strategies.service';
import {
   RemoveCommanderRule
} from 'projects/shared-components/shell-communication/operations/strategy-commander/remove-commander-rule.class';
import {
   ChangeCommanderRuleState
} from 'projects/shared-components/shell-communication/operations/strategy-commander/change-commander-rule-state.class';
import { StrategyCommanderRuleDto } from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-dto.interface';
import {
   GetAvailableCommanderRules
} from 'projects/shared-components/shell-communication/operations/strategy-commander/get-available-commander-rules.class';
import {
   StrategyCommanderRuleEditedDto
} from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-edited-dto.interface';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import {
   StrategyCommanderRuleChangeProgressDto
} from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-change-progress-dto.interface';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';

interface CommanderSecurityContext {
   createRule: boolean;
   updateRule: boolean;
   removeRule: boolean;
   enableDisableRule: boolean;
}

@Component({
   selector: 'ets-strategy-commander',
   templateUrl: './strategy-commander.component.html',
   styleUrls: ['./strategy-commander.component.scss']
})
export class StrategyCommanderComponent implements OnInit {
   constructor(
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _accessControlSvc: AccessControlService,
      private readonly _shellClient: ShellClientService,
      private readonly _messageBus: MessageBusService,
      private readonly _toastr: ToastrService,
      private readonly _strategiesService: StrategiesService,
      private readonly _sessionService: SessionService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('StrategyCommanderComponent');
      this.contextPopupParent = document.querySelector('body');
   }

   private readonly _logger: Logger;
   private readonly _commanderRules: StrategyCommanderRuleModel[] = [];
   private _commanderRulesGrid: GridReadyEvent;
   private _unsubscriber: Subject<any>;

   @ViewChild(StrategyCommanderRuleEditorComponent, { static: true }) commanderRuleEditor: StrategyCommanderRuleEditorComponent;
   securityContext: CommanderSecurityContext;
   contextPopupParent: HTMLElement;
   commanderGridModel: GridOptions;
   isVisible = false;

   async onGridReady(args: GridReadyEvent): Promise<void> {
      this._commanderRulesGrid = args;
      this._commanderRulesGrid.api.sizeColumnsToFit();
   }

   async removeCommanderRule(data: StrategyCommanderRuleModel): Promise<void> {
      const correlationTicket: string = new Guid().valueOf();
      const cmd = new RemoveCommanderRule(
         data.strategyCommanderRuleId,
         correlationTicket
      );

      this._commanderRulesGrid.api.showLoadingOverlay();
      let subscription: Subscription;
      try {
         subscription = this._messageBus.of<StrategyCommanderRuleChangeProgressDto>('StrategyCommanderRuleChangeProgressDto')
            .subscribe(x => {
               if (x.payload.progressValue === 100 || x.payload.hasErrors) {

                  subscription.unsubscribe();

                  const ix = this._commanderRules.indexOf(data);

                  if (!x.payload.hasErrors) {
                     if (ix >= 0) {
                        this._commanderRules.splice(ix, 1);
                        this._commanderRulesGrid.api.setRowData(this._commanderRules);
                     }

                     this._commanderRulesGrid.api.hideOverlay();
                     if (x.payload.hasErrors) {
                        this._toastr.error('"Remove Commander Rule" operation completed with errors');
                     }
                  }
               }
            });

         await this._shellClient.processCommand(cmd);

      } catch (e) {
         this._toastr.error('"Remove Rule" operation completed with errors');
         this._logger.error('removeRule()', e);
      }
   }

   ngOnInit(): void {
      const isAvailable = (id) =>
         this._accessControlSvc.isSecureElementAvailable(id);
      this.securityContext = {
         createRule: isAvailable('826cc2db-9bd4-4f48-bae3-d100c152a10b'),
         updateRule: isAvailable('909cea32-a0a4-4793-8ba8-77932358a92b'),
         removeRule: isAvailable('6cc46f76-453d-4eca-a89a-f9ea523f3cef'),
         enableDisableRule: isAvailable('db71fc3b-fdf7-480e-a967-3174f1425a16')
      };
      this.commanderGridModel = getStrategyCommanderGridModel.bind(this)();
   }

   showCommanderEditor(mode: 'create' | 'update', rule?: StrategyCommanderRuleModel): void {
      this.commanderRuleEditor.show(mode, rule);
   }

   onHidden(): void {
      this.isVisible = false;
      this._resetState();
   }

   async onShown(): Promise<void> {
      this._resetState();
      this._subscribeToMessages();
      await this._tryLoadData();
   }

   async changeRuleState(data: StrategyCommanderRuleModel): Promise<void> {
      const action = data.isDisabled ? 'enable' : 'disable';
      const rules = [data.strategyCommanderRuleId];
      const ticket = new Guid().valueOf();

      const cmd = new ChangeCommanderRuleState(rules, action, ticket);

      this._commanderRulesGrid.api.showLoadingOverlay();
      let subscription: Subscription;
      try {
         subscription = this._messageBus.of<StrategyCommanderRuleChangeProgressDto>('StrategyCommanderRuleChangeProgressDto')
            .subscribe(x => {
               if (x.payload.progressValue === 100 || x.payload.hasErrors) {
                  subscription.unsubscribe();
                  this._commanderRulesGrid.api.hideOverlay();
                  if (x.payload.hasErrors) {
                     this._toastr.error('"Enable/Disable Rule" operation completed with errors');
                  }
               }
            });

         await this._shellClient.processCommand(cmd);

      } catch (e) {
         this._toastr.error('"Enable/Disable Rule" operation completed with errors');
         const errorData = { error: e.stack || e };
         this._logger.error('changeRuleState()', errorData);
      }
   }

   private _loadCommanderRules(): Promise<StrategyCommanderRuleDto[]> {
      const terminalIds = this._sessionService.loginResult.availableTerminals.map(x => x.terminalId);
      const qry = new GetAvailableCommanderRules(
         terminalIds
      );
      return this._shellClient.processQuery<StrategyCommanderRuleDto[]>(qry);
   }

   private _onCommanderRuleEdited(msg: StrategyCommanderRuleEditedDto) {
      const dto = msg.rule;
      const existing: StrategyCommanderRuleModel = this._commanderRules.find(
         x => x.strategyCommanderRuleId === dto.strategyCommanderRuleId
      );
      if (existing) {
         existing.setDto(dto);
         this._commanderRulesGrid.api.redrawRows();
      } else {
         const model = new StrategyCommanderRuleModel(dto, this._displayNameService, this._strategiesService);
         this._commanderRules.push(model);
         this._commanderRulesGrid.api.setRowData(this._commanderRules);
      }
   }

   private async _tryLoadData(): Promise<void> {
      this._commanderRulesGrid.api.showLoadingOverlay();
      try {
         const dtos = await this._loadCommanderRules();
         const models = dtos.map(dto => new StrategyCommanderRuleModel(dto, this._displayNameService, this._strategiesService));
         this._commanderRules.length = 0;
         this._commanderRules.push(...models);
         this._commanderRulesGrid.api.setRowData(this._commanderRules);
      } catch (e) {
         this._toastr.error('"Strategy Commander" View loaded with errors');
         const data = { error: e.stack || e };
         this._logger.error('_tryLoadData()', data);
      } finally {
         this._commanderRulesGrid.api.hideOverlay();
      }
   }

   private _resetState(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
         this._unsubscriber = null;
      }
      this._commanderRules.length = 0;
   }

   private _subscribeToMessages(): void {
      this._unsubscriber = new Subject<any>();

      this._messageBus.of<StrategyCommanderRuleEditedDto>('StrategyCommanderRuleEditedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onCommanderRuleEdited(msg.payload));
   }
}
