import { DispositionParametersControlBase } from './disposition-parameters-control-base';
import { AlgoMetadataService } from '../../../algo/algo-metadata.service';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { GridOptions, ValueGetterParams, GetContextMenuItemsParams } from 'ag-grid-community';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';


export function getInnerStrategiesGridModel(this: DispositionParametersControlBase<unknown>,
                                            algoMetadataService: AlgoMetadataService): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Name',
            field: 'displayName'
         },
         {
            headerName: 'Algo',
            field: 'algo',
            valueGetter(params: ValueGetterParams) {
               const data = params.data as StrategyDto;
               const value = data.algoId;
               const name = algoMetadataService.getAlgoDescriptionBuilder(value)
                  .getAlgoName(data.parameters);
               return name;
            }
         },
         {
            headerName: 'Description',
            field: 'description',
            headerComponentParams: {},
            cellStyle: { 'text-align': 'left' },
            valueGetter(params: ValueGetterParams) {
               const data = params.data as StrategyDto;
               const value = data.algoId;
               const description = algoMetadataService.getAlgoDescriptionBuilder(value)
                  .getDescription(data.parameters);
               return description;
            }
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      suppressCellSelection: true,

      onGridReady: args => this.onInnerStrategiesGridReady(args),

      getRowNodeId: (strat: StrategyModel) => {
         return strat.strategyId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Add',
               action: () => this.showInnerStrategyEditor(params.node ? params.node.data : new StrategyDto(), 'add')
            },
            {
               name: 'Update',
               disabled: !params.node,
               action: () => this.showInnerStrategyEditor(params.node.data, 'update')
            },
            {
               name: 'Remove',
               disabled: !params.node,
               action: () => this.removeInnerStrategy(params.node.data)
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}
