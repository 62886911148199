import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';
import { SessionHistoryComponent } from '../session-history.component';
import {UserSettingsService} from "../../../../../shared-components/user-settings.service";

@Component({
   selector: 'ets-session-history-panel',
   template: `
      <div class="panel-container" [ngClass]="{'ets-hidden': !isActive}">
         <ets-session-history [isPanelContext]="true"></ets-session-history>
      </div>
   `,
   styles: [
      `
      .panel-container {
         width: 100%;
         height: 100%;
      }
      `
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionHistoryPanelComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService
   ) { 
      super(_changeDetector, _userSettingsService, _messageBus);
   }
   
   @ViewChild(SessionHistoryComponent) sessionHistoryComponent: SessionHistoryComponent;
   
   
   etsOnInit() { }

   
   etsAfterViewInit() { }

   
   etsOnDestroy(): void {
      if (this.sessionHistoryComponent) {
         this.sessionHistoryComponent.onHidden();
      }
   }

   
   protected getState(): any { return null; }

   
   protected setState(state: any) { }
}
