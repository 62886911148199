import { GridOptions, ColDef, GetContextMenuItemsParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { defaultLoadingOverlayTemplate, centeredColumnDef } from '../ag-grid-contrib';
import { OptionChainComponent } from './option-chain.component';
import { ColumnVisibleEvent, FirstDataRenderedEvent } from 'ag-grid-community/dist/lib/events';
import { formatNumber } from '@angular/common';
import { ChainLine } from './option-types';
import { isNullOrUndefined } from '../utils';

export function getOptionsBoardGridModel(
   this: OptionChainComponent
): GridOptions {
   const options: GridOptions = {};

   options.rowData = [];
   options.defaultColDef = centeredColumnDef;
   options.columnDefs = getColumnDefs.bind(this)();
   options.overlayLoadingTemplate = defaultLoadingOverlayTemplate;
   options.rowClass = 'ets-text-centered';
   options.rowSelection = null;
   options.suppressRowClickSelection = true;
   options.rowModelType = 'clientSide';
   options.immutableData = true;
   options.getContextMenuItems = (params: GetContextMenuItemsParams) => {
      return [
         {
            name: 'Add Strike',
            action: () => this.addCustomStrike(),
            disabled: !this.expiration
         },
         {
            name: 'Remove Strike',
            action: () => this.removeStrike(params.node),
            disabled: !params.node || !params.node.data
         },
         {
            name: 'Copy Option',
            action: () => this.onCellCopy(params)
         },
         'separator',
         {
            name: 'Size Columns To Fit',
            action: () => params.api.sizeColumnsToFit()
         },
         'autoSizeAll',
      ];
   };
   options.onGridReady = (args) => this.onGridReady(args);
   options.getRowNodeId = (row) => row.strike;
   options.onFirstDataRendered = (args: FirstDataRenderedEvent) => args.api.sizeColumnsToFit();
   options.onColumnVisible = (args: ColumnVisibleEvent) => this.onColumnVisibilityChanged(args);

   return options;
}



const itmCellColorerCall = (args) => {
   if (args.data.call) {
      if (args.data.call.itm) {
         return { color: 'yellow' };
      }
   }
   return null;
};



const itmCellColorerPut = (args) => {
   if (args.data.put) {
      if (args.data.put.itm) {
         return { color: 'yellow' };
      }
   }
   return null;
};



const greeksFormatter = (args: ValueFormatterParams) => {
   if (!args.value) {
      return '?';
   }

   return formatNumber(args.value, 'en-US', '1.0-3');
};



const priceFormatter = (args: ValueFormatterParams) => {
   if (isNullOrUndefined(args.value) || args.value === -1) {
      return '?';
   }

   return formatNumber(args.value, 'en-US', '1.2-2');
};


function getColumnDefs(this: OptionChainComponent): ColDef[] {
   const cols: ColDef[] = [
      {
         headerName: 'CALLS',

         children: [
            {
               headerName: 'Pos.',
               field: 'call.netPosition',
               hide: true,
               cellStyle: itmCellColorerCall,
               sortable: false
            },
            {
               headerName: 'Vega',
               field: 'call.vega',
               cellStyle: itmCellColorerCall,
               hide: true,
               valueFormatter: greeksFormatter,
               sortable: false
            },
            {
               headerName: 'Theta',
               field: 'call.theta',
               cellStyle: itmCellColorerCall,
               hide: true,
               valueFormatter: greeksFormatter,
               sortable: false
            },
            {
               headerName: 'Gamma',
               field: 'call.gamma',
               cellStyle: itmCellColorerCall,
               valueFormatter: greeksFormatter,
               hide: true,
               sortable: false
            },
            {
               headerName: 'IV',
               field: 'call.iv',
               cellStyle: itmCellColorerCall,
               valueFormatter: (args: ValueFormatterParams) => {
                  if (!args.value) {
                     return '?';
                  }

                  return formatNumber(args.value * 100, 'en-US', '1.1-1') + '%';
               },
               sortable: false
            },
            {
               headerName: 'Extrinsic V.',
               field: 'call.extrinsic',
               cellStyle: itmCellColorerCall,
               valueFormatter: priceFormatter,
               valueGetter: (args: ValueGetterParams) => {

                  const chainLine = args.data as ChainLine;

                  if (!chainLine) {
                     return null;
                  }

                  if (!chainLine.strike) {
                     return null;
                  }

                  if (!chainLine.call) {
                     return;
                  }

                  const midPx = (chainLine.call.ask + chainLine.call.bid) / 2;

                  if (isNullOrUndefined(midPx) || isNaN(midPx)) {
                     return null;
                  }

                  return midPx - args.getValue('call.intrinsic');
               },
               sortable: false
            },
            {
               headerName: 'Intrinsic V.',
               field: 'call.intrinsic',
               cellStyle: itmCellColorerCall,
               valueFormatter: priceFormatter,
               valueGetter: (args: ValueGetterParams) => {
                  if (!this.lastPx) {
                     return null;
                  }

                  const chainLine = args.data as ChainLine;

                  if (!chainLine) {
                     return null;
                  }

                  if (!chainLine.strike) {
                     return null;
                  }

                  const iv = this.lastPx - chainLine.strike;

                  if (isNullOrUndefined(iv)) {
                     return null;
                  }

                  return iv > 0 ? iv : 0;
               },
               sortable: false
            },
            {
               headerName: 'Delta',
               field: 'call.delta',
               cellStyle: itmCellColorerCall,
               valueFormatter: greeksFormatter,
               sortable: false
            },
            {
               headerName: 'OI',
               field: 'call.oi',
               cellStyle: itmCellColorerCall,
               valueFormatter: (args: ValueFormatterParams) => {
                  if (!args.value) {
                     return '?';
                  }

                  return args.value;
               },
               sortable: false
            },
            {
               headerName: 'Bid Size',
               field: 'call.bidSize',
               cellStyle: itmCellColorerCall,
               hide: true,
               sortable: false
            },
            {
               headerName: 'Bid',
               field: 'call.bid',
               cellStyle: itmCellColorerCall,
               valueFormatter: priceFormatter,
               sortable: false
            },
            {
               headerName: 'Ask',
               field: 'call.ask',
               cellStyle: itmCellColorerCall,
               valueFormatter: priceFormatter,
               sortable: false
            },
            {
               headerName: 'Ask Size',
               field: 'call.askSize',
               cellStyle: itmCellColorerCall,
               hide: true,
               sortable: false
            },
         ],

      } as Partial<ColDef>,
      {
         headerName: 'Strike',
         field: 'strike',
         sort: 'asc',
      },
      {
         headerName: 'PUTS',
         children: [
            {
               headerName: 'Ask',
               field: 'put.ask',
               cellStyle: itmCellColorerPut,
               valueFormatter: priceFormatter,
               sortable: false
            },
            {
               headerName: 'Bid',
               field: 'put.bid',
               cellStyle: itmCellColorerPut,
               valueFormatter: priceFormatter,
               sortable: false
            },
            {
               headerName: 'OI',
               field: 'put.oi',
               cellStyle: itmCellColorerPut,
               valueFormatter: (args: ValueFormatterParams) => {
                  if (!args.value) {
                     return '?';
                  }
                  return args.value;
               },
               sortable: false
            },
            {
               headerName: 'Delta',
               field: 'put.delta',
               cellStyle: itmCellColorerPut,
               valueFormatter: greeksFormatter,
               sortable: false
            },
            {
               headerName: 'Intrinsic V.',
               field: 'put.intrinsic',
               cellStyle: itmCellColorerPut,
               valueFormatter: priceFormatter,
               valueGetter: (args: ValueGetterParams) => {
                  if (!this.lastPx || isNaN(this.lastPx)) {
                     return null;
                  }

                  const chainLine = args.data as ChainLine;

                  if (!chainLine) {
                     return null;
                  }

                  if (!chainLine.strike) {
                     return null;
                  }

                  const iv = chainLine.strike - this.lastPx;

                  return iv > 0 ? iv : 0;
               },
               sortable: false
            },
            {
               headerName: 'Extrinsic V.',
               field: 'put.extrinsic',
               cellStyle: itmCellColorerPut,
               valueFormatter: priceFormatter,
               valueGetter: (args: ValueGetterParams) => {

                  const chainLine = args.data as ChainLine;

                  if (!chainLine) {
                     return null;
                  }

                  if (!chainLine.strike) {
                     return null;
                  }

                  if (!chainLine.put) {
                     return;
                  }

                  const midPx = (chainLine.put.ask + chainLine.put.bid) / 2;

                  if (!midPx || isNaN(midPx)) {
                     return null;
                  }

                  return midPx - args.getValue('put.intrinsic');
               },
               sortable: false
            },
            {
               headerName: 'IV',
               field: 'put.iv',
               cellStyle: itmCellColorerPut,
               valueFormatter: (args: ValueFormatterParams) => {
                  return formatNumber(args.value * 100, 'en-US', '1.1-1') + '%';
               },
               sortable: false
            },
            {
               headerName: 'Pos.',
               field: 'put.netPosition',
               cellStyle: itmCellColorerPut,
               hide: true,
               sortable: false
            },
            {
               headerName: 'Gamma',
               field: 'put.gamma',
               cellStyle: itmCellColorerPut,
               valueFormatter: greeksFormatter,
               hide: true,
               sortable: false
            },
            {
               headerName: 'Theta',
               field: 'put.theta',
               cellStyle: itmCellColorerPut,
               valueFormatter: greeksFormatter,
               hide: true,
               sortable: false
            },
            {
               headerName: 'Vega',
               field: 'put.vega',
               cellStyle: itmCellColorerPut,
               valueFormatter: greeksFormatter,
               hide: true,
               sortable: false
            },
         ]
      } as Partial<ColDef>,
   ];

   return cols;
}


function getFakeRowData(): any[] {
   return [
      {
         call: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13,
         },
         strike: 1000,
         put: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
      },
      {
         call: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
         strike: 2000,
         put: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
      },
      {
         call: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
         strike: 3000,
         put: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
      },
      {
         call: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
         strike: 4000,
         put: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
      },
      {
         call: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
         strike: 5000,
         put: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
      },
      {
         call: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
         strike: 7000,
         put: {
            bid: 67.8,
            ask: 97.9,
            bidSize: 100,
            askSize: 80,
            delta: 0.58,
            gamma: 0.0003,
            theta: -0.3,
            vega: 13
         },
      }
   ];
}
