import { isNullOrUndefined } from "util";
import { CashFlowAdjustment } from "./CashFlowAdjustment";
import { isVoid } from "projects/shared-components/utils";
import { StrategyPriceDto } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { LastQuoteCacheService } from "projects/shared-components/last-quote-cache.service";
import * as Enumerable from "linq";


type IndexByCode = { [ix: string]: CashFlowAdjustment[] };

export class StrategyCodeIndex {
   constructor(private _lastQuoteCache: LastQuoteCacheService) {
   }

   //
   private _indexBySrategyCode: IndexByCode = {};
   private _indexBySrategyCodeZones: IndexByCode = {};

   addData(adjustments: CashFlowAdjustment[]) {

      const toSubscribe = this.buildStrategyCodeIndex(adjustments);

      const strategyCodes = Enumerable
          .from(toSubscribe)
          .distinct()
          .toArray();

      this._lastQuoteCache.subscribeStrategyCodesDiff([], strategyCodes);
   }

   addDataZones(adjustments: CashFlowAdjustment[]) {

      const toSubscribe = this.buildStrategyCodeIndexZones(adjustments);

      const strategyCodes = Enumerable
          .from(toSubscribe)
          .distinct()
          .toArray();

      this._lastQuoteCache.subscribeStrategyCodesDiff([], strategyCodes);
   }

   removeData(adjustments: CashFlowAdjustment[]) {
      const toUnsubscribe = [];

      adjustments.forEach(adj => {

         if (isVoid(adj.optionStrategyCode)) {
            return;
         }

         let container = this._indexBySrategyCode[adj.optionStrategyCode];

         if (isNullOrUndefined(container)) {
            return;
         }

         const ix = container.indexOf(adj);

         if (ix < 0) {
            return;
         }

         container.splice(ix, 1);

         if (container.length === 0) {
            toUnsubscribe.push(adj.optionStrategyCode);
         }
      });

      this._lastQuoteCache.subscribeStrategyCodesDiff(toUnsubscribe, []);
   }

   //
   reset() {

      const strategyCodes = Enumerable
         .from(Object.keys(this._indexBySrategyCode))
         .distinct()
         .toArray();

      this._lastQuoteCache.subscribeStrategyCodesDiff(strategyCodes, []);

      this._indexBySrategyCode = {};
   }

   resetZones() {

      const strategyCodes = Enumerable
         .from(Object.keys(this._indexBySrategyCodeZones))
         .distinct()
         .toArray();

      this._lastQuoteCache.subscribeStrategyCodesDiff(strategyCodes, []);

      this._indexBySrategyCodeZones = {};
   }

   //
   onStrategyPrice(prices: StrategyPriceDto[]): string[] {
      const main = this.onStrategyPriceIndex(prices, this._indexBySrategyCode);
      const zones = this.onStrategyPriceIndex(prices, this._indexBySrategyCodeZones);

      return main.concat(zones);
   }

   private onStrategyPriceIndex(prices: StrategyPriceDto[], index: IndexByCode): string[] {

      if (!index) {
         return [];
      }

      if (Object.keys(prices).length === 0) {
         return [];
      }

      // let hasUpdates = false;
       const updates = [];

      prices.forEach(strategyPrice => {

         const container = index[strategyPrice.strategyCode];

         if (isNullOrUndefined(container)) {
            return;
         }

         if (container.length === 0) {
            return;
         }

         container.forEach(exp => {
            exp.price = (strategyPrice.price * -1) + (exp.marketDelta || 0);
            // hasUpdates = true;
             updates.push(exp.optionStrategyCode);
         });
      });

      return updates;
   }

   //
   private buildStrategyCodeIndex(adjustments: CashFlowAdjustment[]): string[] {

      const toSubscribe = [];

      adjustments.forEach(adj => {

         if (isVoid(adj.optionStrategyCode)) {
            return;
         }

         let container = this._indexBySrategyCode[adj.optionStrategyCode];

         if (isNullOrUndefined(container)) {
            container = [];
            this._indexBySrategyCode[adj.optionStrategyCode] = container;
            toSubscribe.push(adj.optionStrategyCode);
         }

         container.push(adj);

         const lastStrategyPrice = this._lastQuoteCache.getLastStrategyPrice(adj.optionStrategyCode);
         if (!isVoid(lastStrategyPrice)) {
            this.onStrategyPrice([lastStrategyPrice]);
         }

      });

      return toSubscribe;

   }

   private buildStrategyCodeIndexZones(adjustments: CashFlowAdjustment[]): string[] {

      const toSubscribe = [];

      adjustments.forEach(adj => {

         if (isVoid(adj.optionStrategyCode)) {
            return;
         }

         let container = this._indexBySrategyCodeZones[adj.optionStrategyCode];

         if (isNullOrUndefined(container)) {
            container = [];
            this._indexBySrategyCodeZones[adj.optionStrategyCode] = container;
            toSubscribe.push(adj.optionStrategyCode);
         }

         container.push(adj);

      });

      return toSubscribe;

   }

   isPriceBoxTheoretical() {
      return Object.keys(this._indexBySrategyCode)
          .some(x => x.indexOf(' !') >= 0);
   }

   isZonesGridTheoretical() {
      return Object.keys(this._indexBySrategyCodeZones)
          .some(x => x.indexOf(' !') >= 0);
   }
}

