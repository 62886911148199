import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { BucketContext, GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { BucketHighlighted } from 'projects/shared-components/ui-messages/ui-messages';
import { filter, last, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ManualTradingBackendService } from '../manual-trading-backend.service';
import { ManualTradingSecurityContextService } from '../manual-trading-security-context.service';
import { OrdersAndTradesLinkedContext } from '../manual-trading.model';
import { OrdersAndTradesBaseComponent } from './orders-and-trades-base.component';
import {UserSettingsService} from "../../user-settings.service";

@Component({
   selector: 'ets-portfolio-orders-and-trades',
   templateUrl: './orders-and-trades-base.component.html',
   styleUrls: ['./orders-and-trades-base.component.scss'],
   providers: [ManualTradingSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioOrdersAndTradesComponent extends OrdersAndTradesBaseComponent {
   
   constructor(
      _changeDetector: ChangeDetectorRef,
      _userSettingsService: UserSettingsService,
      _messageBus: MessageBusService,
      _backendClient: ManualTradingBackendService,
      _tickerDisplayNameService: TradingInstrumentDisplayNameService,
      _toastr: ToastrService,
      _lastQuoteCache: LastQuoteCacheService,
      _timestampsService: TimestampsService,
      securityContext: ManualTradingSecurityContextService,
   ) {
      super(_changeDetector, _userSettingsService, _messageBus, _backendClient, _tickerDisplayNameService
         , _toastr, _lastQuoteCache, _timestampsService, securityContext);
   }

   private _bucketContext: BucketContext;

   get linkedContext(): OrdersAndTradesLinkedContext {
      return 'Portfolios';
   }

   etsOnInit(): Promise<void> {
      
      const lastHighlightedMessage = this._messageBus.getLastMessage<BucketHighlighted>('BucketHighlighted', this.layoutTabId);
      
      if (lastHighlightedMessage) {
         this.setBucketContext(lastHighlightedMessage.payload);
      }

      return super.etsOnInit();
   }

   changeIsLinked(): void {
      //
   }

   showBucketContextHint() {
      const config = { positionClass: 'toastr-bottom-wide-width' };

      if (this._bucketContext) {

         const msg = `Bucket Type: [${this._bucketContext.bucketType}], Name: "${this._bucketContext.bucketName}"`;
         this._toastr.info(msg, 'Portfolio Orders & Trades', config);

      } else {

         this._toastr.info('Bucket is unknown', 'Portfolio Orders & Trades', config);

      }
   }

   protected subscribeSpecificMessages(): void {
      this._messageBus.of<BucketHighlighted>('BucketHighlighted')
      .pipe(
         filter(x => x.scopeId === this.layoutTabId),
         takeUntil(this._unsubscriber)
      )
      .subscribe(msg => this.onBucketHighlighted(msg.payload));
   }

   protected prepareWorkingOrdersQuery(): GetWorkingOrdersWeb {

      if (isNullOrUndefined(this._bucketContext)) { 
         return undefined;
      }

      return new GetWorkingOrdersWeb(
         EtsConstants.strategies.manualStrategyId,
         'Portfolios',
         null,
         null,
         this._bucketContext
      );
   }

   protected prepareSnapshotsQuery(historyStop?: number, batchSize?: number): GetOrderStateSnapshotsWeb {
      
      if (isNullOrUndefined(this._bucketContext)) { 
         return undefined;
      }

      historyStop = historyStop || 0;
      batchSize = batchSize || 100;

      return new GetOrderStateSnapshotsWeb(
         EtsConstants.strategies.manualStrategyId,
         historyStop,
         batchSize,
         this.linkedContext,
         null,
         null,
         this._bucketContext
      );
   }

   protected prepareTradesQuery(historyStop?: number, batchSize?: number): GetTradesWeb {
      
      if (isNullOrUndefined(this._bucketContext)) { 
         return undefined;
      }

      historyStop = historyStop || 0;
      batchSize = batchSize || 100;

      return new GetTradesWeb(
         EtsConstants.strategies.manualStrategyId,
         historyStop,
         batchSize,
         this.linkedContext,
         null,
         null,
         this._bucketContext
      );
   }

   protected filterTradingEntities<T extends TradeDto | OrderDto | OrderStateSnapshotDto>(entities: T[]): T[] {
      
      if (!this._bucketContext) {
         return [];
      }

      let filtered: T[] = entities.filter(e => !isNullOrUndefined(e.portfolioId));

      if (this._bucketContext.bucketType === 'Portfolio') {

         filtered = filtered.filter(entity => entity.portfolioId === this._bucketContext.bucketId);

      } else if (this._bucketContext.bucketType === 'Combo') {

         filtered = filtered.filter(entity => entity.comboId === this._bucketContext.bucketId);

      } else if (this._bucketContext.bucketType === 'ComboGroup') {

         filtered = filtered.filter(entity => entity.comboGroupId === this._bucketContext.bucketId);

      } else if (this._bucketContext.bucketType === 'Terminal') {

         filtered = filtered.filter(entity => entity.terminalId === this._bucketContext.bucketId);

      }

      return filtered;

   }

   private onBucketHighlighted(x: BucketHighlighted): void {
      this.setBucketContext(x);
      this.loadManualTradingData();
   }

   private setBucketContext(x: BucketHighlighted) {
      this._bucketContext = x.bucketContext;
   }

   protected async resetLinkedContext(): Promise<void> {
      
   }
}
