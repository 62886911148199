import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import * as shortid from 'shortid';
import { ToastrService } from 'ngx-toastr';
import {
   ChartingLibraryWidgetOptions,
   IChartingLibraryWidget,
   widget
} from '../../webtrader/src/assets/tv/charting_library/charting_library.min';
import { PriceDataFeedFactoryService } from './price-data-feed-factory.service';
import { IDatafeedChartApi, Timezone } from './data-feed-chart-api';
import { SettingsStorageService } from '../settings-storage-service.service';
import { getPanelStateKey, isInstrumentExpired } from 'projects/shared-components/utils';
import { environment } from '../environments/environment';
import { MessageBusService } from '../message-bus.service';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetTheTradeLine } from '../shell-communication/operations/strategies/get-the-trade-line.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { ValueDto } from '../shell-communication/dtos/value-dto.interface';
import { isNullOrUndefined } from 'util';
import { EtsConstants } from '../ets-constants.const';
import { StrategyLineSetDto } from '../shell-communication/dtos/strategy-line-set-dto.class';
import { TimestampsService } from '../timestamps.service';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { SymbolHighlighted } from '../ui-messages/ui-messages';
import { PanelBaseComponent } from '../panels/panel-base.component';
import {UserSettingsService} from "../user-settings.service";


interface PriceChartState {
   chartState: any;
   theLineId?: string;
   isLinkedToSymbol: boolean;
}

@Component({
   selector: 'ets-price-chart',
   templateUrl: 'price-chart.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class PriceChartComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _toastr: ToastrService,
      private readonly _dataFeedFactory: PriceDataFeedFactoryService,
      private readonly _shellClientService: ShellClientService,
      private readonly _timestampsService: TimestampsService,
      private readonly _tradingInstrumentsService: TradingInstrumentsService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
      this.chartId = shortid.generate();
   }
   
   private _isChartInitialized: boolean;
   private _datafeed: IDatafeedChartApi;
   private _tvWidget: IChartingLibraryWidget | null = null;
   private _unsubscriber: Subject<void>;
   private _theTradeLineId: string;
   private _selectedStrategy: string;
   
   
   
   readonly chartId: string;
   
   
   etsOnInit(): void {
      this._unsubscriber = new Subject();

      this._messageBus.of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.layoutTabId),
            filter(msg => !msg.payload.strategy),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onStrategyHighlighted(msg.payload));

      this._messageBus.of<StrategyLineSetDto>('StrategyLineSetDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onStrategyLineSetMessage(msg.payload));

      this._messageBus
         .of<SymbolHighlighted>('SymbolHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId),
            filter( _ => this.isLinkedToSymbol),
         )
         .subscribe(msg => this.onSymbolHighlightedMessage(msg.payload));
   }


   onSymbolHighlightedMessage(x: SymbolHighlighted): void {
      const ti = this._tradingInstrumentsService.getInstrumentByTicker(x.ticker);
      if (!ti) {
         return;
      }

      try {
         this._tvWidget.chart().setSymbol(ti.ticker, () => {
            console.log('symbol loaded');
         });
      } catch {
         //
      }
   }

   etsAfterViewInit(): void {
      if (!this._isChartInitialized) {
         this._initChart();
         this._isChartInitialized = true;
      }
   }

   
   etsOnDestroy(): void {
      this._unsubscriber.next();
      this._unsubscriber.complete();

      const feed: any = this._datafeed;
      if (feed) {
         feed.dispose();
      }
   }


   private async _onStrategyHighlighted(msg: StrategyHighlightedUIMessage) {

      this._selectedStrategy = null;

      if (msg.strategy.algoId !== EtsConstants.algorithms.tradeTheLineAlgoId) {
         this._clearTheLine();
         return;
      }

      this.isLoading = true;

      let theTradeLine: number;
      try {
         const qry = new GetTheTradeLine(msg.strategyId);
         const valueObj = await this._shellClientService.processQuery<ValueDto>(qry, msg.strategy.shellId);
         theTradeLine = Number(valueObj.value);
      } catch (error) {
         this._toastr.error(`Error getting the line the strategy "${msg.strategyName}" `);
      } finally {
         this.isLoading = false;
      }

      this._setTheTradeLine(theTradeLine, msg.strategyId);
   }

   
   private onStrategyLineSetMessage(msg: StrategyLineSetDto) {
      if (isNullOrUndefined(this._selectedStrategy)) {
         return;
      }

      if (this._selectedStrategy !== msg.strategyId) {
         return;
      }

      this._setTheTradeLine(msg.theLine, msg.strategyId);
   }

   
   private _setTheTradeLine(theTradeLine: number, strategyId: string) {
      this._selectedStrategy = strategyId;

      this._clearTheLine();

      if (isNullOrUndefined(theTradeLine) || isNaN(theTradeLine)) {
         return;
      }

      const point = {
         time: new Date().getTime(),
         price: theTradeLine
      };

      const options = {
         shape: 'horizontal_line',
         lock: true,
         text: theTradeLine + ''
      };

      const theTradeLineId = this._tvWidget.chart().createShape(point, options);

      this._theTradeLineId = theTradeLineId;

      this.saveState();
   }

   
   private _clearTheLine() {
      if (!isNullOrUndefined(this._theTradeLineId)) {
         this._tvWidget.chart().removeEntity(this._theTradeLineId);
         this._theTradeLineId = null;
         this.saveState();
      }
   }

   
   private _initChart(): void {

      this.isLoading = true;

      const key = getPanelStateKey(this);
      const state = this._userSettingsService.getValue<PriceChartState>(key);
      // let state: PriceChartState;
      // if (item) {
      //    try {
      //       state = JSON.parse(item);
      //
      //       if (state) {
      //          this.isLinkedToSymbol = state.isLinkedToSymbol;
      //       }
      //
      //    } catch (e) {
      //       this._toastr.error('"Price Chart" panel was restored with errors');
      //       this._settingsService.removeItem(key);
      //       this.isLoading = false;
      //       return;
      //    }
      // }

      let isExpired = false;
      let shortName = 'N/A';

      if (state && state.chartState) {
         const chartState = state.chartState;
         // chartState.charts[0].panes[0].sources[0].state.symbol/shortName
         const charts = chartState.charts;
         if (charts && charts.length > 0) {
            const panes = charts[0].panes;
            if (panes && panes.length > 0) {
               const sources = panes[0].sources;
               if (sources && sources.length > 0) {
                  const chartState1 = sources[0].state;
                  if (chartState1) {
                     const symbol = chartState1.symbol;
                     shortName = chartState1.shortName;
                     if (symbol) {
                        const ti = this._tradingInstrumentsService.getInstrumentByTicker(symbol);
                        isExpired = isInstrumentExpired(ti);
                     }
                  }
               }
            }
         }
      }

      const defaultTimezone = this._timestampsService.getDefaultTimezone('Etc/UTC') as Timezone;
      const containerId = `pricechart_${this.chartId}`;
      const dataFeed = this._dataFeedFactory.getDataFeed(
         containerId,
         () => this._tvWidget,
         () => {
            this.isLoading = false;
         }
      );
      this._datafeed = dataFeed;
      const widgetOptions: ChartingLibraryWidgetOptions = {
         symbol: 'SPY',
         datafeed: dataFeed,
         timezone: defaultTimezone,
         interval: '1',
         container_id: containerId,
         library_path: '/assets/tv/charting_library/',
         locale: 'en',
         disabled_features: [
            'use_localstorage_for_settings',
            'header_widget_dom_node',
            'symbol_search_hot_key',
            'header_screenshot',
            'header_fullscreen_button',
            'header_saveload',
            'create_volume_indicator_by_default',
            'uppercase_instrument_names',
         ],
         enabled_features: [
            'no_min_chart_width',
            'cl_feed_return_all_data',
            'seconds_resolution',
            'hide_left_toolbar_by_default'
         ],
         charts_storage_url: 'https://saveload.tradingview.com',
         charts_storage_api_version: '1.1',
         client_id: 'ets',
         user_id: 'ets',
         fullscreen: false,
         autosize: true,
         theme: 'dark',
         saved_data: !!state ? state.chartState : undefined,
         // indicators_file_name: './indicators.js',
         studies_access: {
            type: 'white',
            tools: [
               { name: 'Moving Average' },
               { name: 'Relative Strength Index' },
               { name: 'Stochastic' },
               { name: 'MACD' },
               { name: 'Volume' },
               { name: 'Sessions' },
               { name: 'Overlay' },
               { name: 'Compare' }
            ]
         },
         debug: environment.production,
         overrides: {
            'mainSeriesProperties.style': 0
         }
      };

      this._tvWidget = new widget(widgetOptions);
      this._tvWidget.onChartReady(() => {

         this.isLoading = false;

         if (!isExpired) {
            this._tvWidget.subscribe('onAutoSaveNeeded', () => {
               this.saveState();
            });

            if (state) {
               if (state.theLineId) {
                  this._tvWidget.chart().removeEntity(state.theLineId);
               }

               const lastMessage = this._messageBus
                  .getLastMessage<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage', this.layoutTabId);

               if (lastMessage) {
                  this._onStrategyHighlighted(lastMessage.payload);
               }
            }
         } else {
            setTimeout(() => {
               this._toastr.warning(`Chart for ${shortName} was not restored, because contact is expired`);
               this._messageBus.publish({
                  topic: 'PanelSelfCloseRequest',
                  payload: {
                     workspaceId: this.workspaceId,
                     tabId: this.panelId
                  }
               });
               this.isLoading = false;
               return;
            });
         }
      });
   }

   
   protected getState(): PriceChartState {
      
      if (!this._tvWidget) { return; }
      
      let state: PriceChartState = null;
      
      this._tvWidget.save((s) => {
         state = {
            chartState: s,
            theLineId: this._theTradeLineId,
            isLinkedToSymbol: this.isLinkedToSymbol
         };
      });

      return state;
   }

   protected setState(state) { }
}
