<dx-drop-down-box (onClosed)="this.onClosed()"
                  (onOpened)="this.onOpened()"
                  [(opened)]="this.isOpened"
                  [acceptCustomValue]="false"
                  [contentEditable]="false"
                  [disabled]="this.isDisabled"
                  [dropDownOptions]="this.dropdownOptions"
                  [openOnFieldClick]="true"
                  fieldTemplate="field">
  
  <div *dxTemplate="let data of 'field'">
    <dx-text-box [readOnly]="true" [value]="this.displayText"></dx-text-box>
  </div>
  
  <div #container *dxTemplate="let data of 'content'">
    
    <!-- Datetime -->
    <div *ngIf="this.showDatePicker" class="row calendar">
      <dx-calendar [(value)]="this.calendar.date" [showTodayButton]="this.showTodayButton"
                   dateSerializationFormat="yyyy-MM-dd"
                   width="100%"
      ></dx-calendar>
    </div>
    
    <!-- Time -->
    <p *ngIf="this.showTimePicker" class="row at">at</p>
    
    <div *ngIf="this.showTimePicker" class="row time">
      <div class="col hours">
        <dx-select-box [(value)]="this.time.hours" [items]="this.time.hoursList" placeholder=" "></dx-select-box>
        <p class="label">hrs</p>
      </div>
      
      <div class="col dots">:</div>
      
      <div class="col minutes">
        <dx-select-box [(value)]="this.time.minutes" [items]="this.time.minutesList" placeholder=" ">
        </dx-select-box>
        <p class="label">mins</p>
      </div>
      
      <div class="col dots">:</div>
      
      <div class="col seconds">
        <dx-select-box [(value)]="this.time.seconds" [items]="this.time.secondsList" placeholder=" ">
        </dx-select-box>
        <p class="label">secs</p>
      </div>
      
      <div class="col period">
        <dx-select-box [(value)]="this.time.period" [items]="this.time.periodList" placeholder=" "></dx-select-box>
        <p class="label">AM/PM</p>
      </div>
    </div>
    
    <!-- Timezone -->
    <div *ngIf="this.mode !== 'timespan' && this.showTimezone" class="row timezone">
      <ets-timezone-picker [(timezone)]="this.timezone" [flow]="'col'" [showBorder]="false"
                           [showCaption]="true"></ets-timezone-picker>
    </div>
    
    <!-- Timespan  -->
    <p *ngIf="this.mode === 'timespan'" class="row after">duration</p>
    
    <div *ngIf="this.mode === 'timespan'" class="row timespan">
      
      <div class="col hours">
        <dx-number-box [(value)]="this.time.hours" [min]="0" [showSpinButtons]="true"></dx-number-box>
        <p class="label">hrs</p>
      </div>
      
      <div class="col dots">:</div>
      
      <div class="col minutes">
        <dx-number-box [(value)]="this.time.minutes" [max]="59" [min]="0" [showSpinButtons]="true"></dx-number-box>
        <p class="label">mins</p>
      </div>
      
      <div class="col dots">:</div>
      
      <div class="col seconds">
        <dx-number-box [(value)]="this.time.seconds" [max]="59" [min]="0" [showSpinButtons]="true"></dx-number-box>
        <p class="label">secs</p>
      </div>
    </div>
    
    <hr class="row separator">
    
    <div class="row buttons">
      <div class="col clear">
        <dx-button (onClick)="this.onClearClicked()" text="Clear" type="danger" width="100%"></dx-button>
      </div>
      <div class="col">
        <dx-button (onClick)="this.onCancelClicked()" text="Cancel" width="100%"></dx-button>
      </div>
      <div class="col">
        <dx-button (onClick)="this.onOkClicked()" text="OK" type="success" width="100%"></dx-button>
      </div>
    </div>
  </div>

</dx-drop-down-box>
<div #ddBox></div>