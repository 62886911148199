import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class RollNextMonth implements ProtocolCommand {
  constructor(
    public readonly strategyId: string,
    public readonly positionId?: string) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.RollNextMonth`;
  }
}
