import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LongTermPutOverviewBlockViewModel } from './long-term-put-overview-block.model';

@Component({
   selector: 'ets-long-term-put-overview-block',
   templateUrl: 'long-term-put-overview-block.component.html',
   styleUrls: ['long-term-put-overview-block.component.scss', '../table-styles.scss']
})

export class LongTermPutOverviewBlockComponent implements OnInit {
   constructor(private _changeDetector: ChangeDetectorRef) { }

   
   private _viewModel: LongTermPutOverviewBlockViewModel;
   get viewModel(): LongTermPutOverviewBlockViewModel {
      return this._viewModel;
   }
   @Input() set viewModel(v: LongTermPutOverviewBlockViewModel) {
      this._viewModel = v;
   }
   

   @Input() mode: string;

   ngOnInit() { }
}
