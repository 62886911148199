import { NgModule } from '@angular/core';
import { StrategyMessagesComponent } from './strategy-messages.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';
import { StrategyMessagesPanelComponent } from './strategy-messages-panel.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      AgGridModule.withComponents([])
   ],
   exports: [
      StrategyMessagesComponent,
      StrategyMessagesPanelComponent
   ],
   declarations: [
      StrategyMessagesComponent,
      StrategyMessagesPanelComponent
   ],
   providers: []
})
export class EtsStrategyMessagesModule { }
