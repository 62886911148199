<div class="messages-section">

   <div class="header">
      <ets-section-header
         [header]="this.header"
         [collapsible]="true"
         (onCollapsedChanged)="this.onSectionCollapsedChanged($event)"
         >
      </ets-section-header>
   </div>

   <div class="content" [style.height.px]="this.sectionHeight">
      <ets-strategy-messages  [container]="this.scope">
      </ets-strategy-messages>   
   </div>

</div>