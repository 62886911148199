import {AlgoDescriptionBuilderBase} from '../algo-description-builder-base.class';
import {StrategyParameters} from '../../strategies/strategy-parameters.enum';

export class LimitAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
  getSymbolPropertyNames(): string[] {
    return ['symbol'];
  }

  getAlgoName(parameters: StrategyParameters): string {
    return 'LIMIT';
  }
  
  getDescription(parameters: StrategyParameters): string {
    let res = '';
    const defaultValue = 'N/A';
    let symbol: string = parameters.symbol || defaultValue;

    if (symbol !== defaultValue) {
      symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
    }

    res += `[${symbol}]: `;
    res += `PT-${parameters.profittarget || defaultValue}, `;
    res += `TL-${parameters.tradelevel || defaultValue}, `;
    res += `M-${parameters.multiplier || defaultValue}`;

    res += `, F-${parameters.factor || defaultValue}`;
    res += `, MP-${parameters.maxposition || defaultValue}`;

    const ctlValue: string = parameters.ctlvalue || defaultValue;
    const isCtlEnabled: boolean = ctlValue && ctlValue !== defaultValue;
    if (isCtlEnabled) { res += `, CTL-${ctlValue}`; }

    const fe1: string = parameters.fe1 || defaultValue;
    const isFe1Enabled: boolean = fe1 && fe1 !== defaultValue;
    if (isFe1Enabled) { res += `, FE1-${fe1}`; }

    const fe2: string = parameters.fe2 || defaultValue;
    const isFe2Enabled: boolean = fe2 && fe2 !== defaultValue;
    if (isFe2Enabled) { res += `, FE2-${fe2}`; }

    const contractsAddedPer: string = parameters.mmcontractsaddedper || defaultValue;
    const isCapEnabled: boolean = contractsAddedPer && contractsAddedPer !== defaultValue;
    if (isCapEnabled) {
      const maxScaleLevels: string = parameters.mmmaxscalelevels || defaultValue;
      res += `, CAP-${contractsAddedPer}`;
      res += `, MSL-${maxScaleLevels}`;

      const reset: string = parameters.mmisreset || defaultValue;
      const isResetEnabled: boolean = reset.toLowerCase() === 'true';
      if (isResetEnabled) { res += ', R'; }
    }


    const contractsDecreasePer: string = parameters.mmcontractsdecreaseper || defaultValue;
    const isCdpEnabled: boolean = contractsDecreasePer && contractsDecreasePer !== defaultValue;
    if (isCdpEnabled) {
      res += `, DeP-${contractsAddedPer}`;

      const contractsDecreaseNum: string = parameters.mmcontractsdecreasenum || defaultValue;
      res += `, CnD-${contractsDecreaseNum}`;

      const maxScaleLevels: string = parameters.mmmaxscalelevels || defaultValue;
      res += `, MSL-${maxScaleLevels}`;

      const reset: string = parameters.mmisreset || defaultValue;
      const isResetEnabled: boolean = reset.toLowerCase() === 'true';
      if (isResetEnabled) { res += ', R'; }

      const regress: string = parameters.mmisregress || defaultValue;
      const isRegressEnabled: boolean = regress.toLowerCase() === 'true';
      if (isRegressEnabled) { res += ', Rg'; }
    }

    return res;
  }
}
