import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {DetectMethodChanges, DxValueChanged, isValidNumber, isVoid} from "../../utils";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {Observable, Observer} from "rxjs";


@Component({
    selector: 'ets-package-moving-floater-component',
    templateUrl: 'package-moving-floater.component.html',
    styleUrls: ['package-moving-floater.component.scss'],
})

export class PackageMovingFloaterComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
    ) {
    }

    private _cfg: {
        portfolioSelector: () => any[],
        distanceSelector: (pf: ApgPortfolio) => Promise<number>,
        priceInverter: () => void,
        isInverted: () => boolean
    }

    closed$ = new EventEmitter();

    popupWidth = 565;

    popupHeight = 100;

    visible = false;

    incrementors: number[];

    step: number = 0;

    isLoading = false;

    distance: number;

    isLive = false;

    movePackages$ = new EventEmitter<{portfolio: ApgPortfolio, delta: number}>();

    get canChange(): boolean {
        return !this.isLoading;
    }

    get isInverted(): boolean {
        return this._cfg?.isInverted() || false;
    }

    portfolioList: { key: string, userId: string, items: ApgPortfolio[] }[];

    selectedPortfolio: ApgPortfolio;

    originalDistance : any;

    private _interval : number;

    ngOnInit() {
    }

    @DetectMethodChanges({isAsync: true})
    async show(cfg: any) {
        this._cfg = cfg;

        this.portfolioList = this._cfg.portfolioSelector();

        if (this.portfolioList.length === 0) {
            return;
        }

        this.originalDistance = new Observable<number>((observer: Observer<number>) => {
            this._interval = setInterval(async () => {
                const distance = await this._cfg?.distanceSelector(this.selectedPortfolio);
                observer.next(distance);
                this._changeDetector.detectChanges();
            }, 250) as any;
        });

        if (!isValidNumber(this.distance)) {
            this.distance = await this._cfg.distanceSelector(this.selectedPortfolio);
        }

        this.incrementors = [ // TODO: get ul from portfolios
            5, 10, 50, 100
        ];

        this.visible = true;

        if (this.portfolioList.length === 1) {
            const items = this.portfolioList[0].items;
            if (items.length === 1) {
                this.selectedPortfolio = items[0];
                await this.onPortfolioSelected({value: this.selectedPortfolio, event: 'ets'});
            }
        }
    }

    @DetectMethodChanges()
    onClosed() {
        clearInterval(this._interval);
        this.closed$.emit();
        this.visible = false;
    }

    @DetectMethodChanges()
    decreaseStep(inc: number) {
        if (!this.canChange) {
            return;
        }

        const v = this.step || 0;
        let nv = v - inc;
        if (nv < 0) {
            nv = 0;
        }
        this.step = nv;
    }

    @DetectMethodChanges({isAsync: true})
    async increaseStep(inc: number) {

        if (!this.canChange) {
            return;
        }


        const v = this.step || 0;
        let nv = v + inc;
        if (nv < 0) {
            nv = 0;
        }
        this.step = nv;
    }

    @DetectMethodChanges({isAsync: true})
    async moveUp() {
        if (!this.canChange) {
            return;
        }
        this.isLoading = true;
        try {
            this.updateDistance(+1);
            await this.applyMoveIfPossible();
        } finally {
            this.isLoading = false;
        }
    }

    @DetectMethodChanges({isAsync: true})
    async moveDown() {
        if (!this.canChange) {
            return;
        }
        this.isLoading = true;
        try {
            this.updateDistance(-1);
            await this.applyMoveIfPossible();
        } finally {
            this.isLoading = false;
        }
    }

    private updateDistance(sign: number) {
        const change = Math.abs(this.step) * sign;
        const move = (this.distance || 0) + change;
        this.distance = move;
    }


    @DetectMethodChanges()
    onChange() {

    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(event: DxValueChanged<ApgPortfolio>) {
        if (isVoid(event.event)) {
            return;
        }
        if (!isValidNumber(this.distance)) {
            const distance = await this._cfg.distanceSelector(event.value);
            this.distance = distance;
        }
    }

    async applyMove() {
      const move = await this.getMove();
        this.movePackages$.emit(move);
    }

    async getMove(implicitDistance?: number) : Promise<{ portfolio: ApgPortfolio, delta: number, isHedgesDashboard?: boolean }> {
        if (isVoid(this._cfg?.distanceSelector)) {
            return null;
        }

        let distance = this.distance || 0;
        let pfDistance = 0;

        if (!isValidNumber(implicitDistance)) {
            try {
                pfDistance = await this._cfg.distanceSelector(this.selectedPortfolio);
            } catch  {
                return null;
            }
        } else {
            pfDistance = 0;
        }

        const delta =  distance - pfDistance;

        const move = {portfolio: this.selectedPortfolio, delta};

        return move;
    }

    private async applyMoveIfPossible() {
        if (this.isLive) {
            await this.applyMove();
        }
    }

    canApplyMove() : boolean {
        if (isVoid(this.selectedPortfolio)) {
            return false;
        }

        if (!isValidNumber(this.distance)) {
            return false;
        }

        if (!isValidNumber(this.step)) {
            return false;
        }

        return true;
    }

    @DetectMethodChanges({isAsync: true})
    async onLiveChanged() {
        if (!this.isLive) {
            return;
        }
        await this.applyMoveIfPossible();
    }

    private _tempHiddenFlag = false;

    @DetectMethodChanges()
    hide() {
        if (this.visible) {
            this._tempHiddenFlag = true;
            this.onClosed();
        }
    }

    unHide() {
        if (this._tempHiddenFlag) {
            this._tempHiddenFlag = false;
            setTimeout(() => {
                this.show(this._cfg).then(() => {});
            }, 100);
        }
    }

    reset() {
        this._tempHiddenFlag = false;
        this._cfg = null;
        this.distance = null;
        this.selectedPortfolio = null;
    }

    @DetectMethodChanges({isAsync: true})
    async resetDistance() {
        this.distance = await this._cfg?.distanceSelector(this.selectedPortfolio);
        await this.applyMoveIfPossible()
    }

    @DetectMethodChanges()
    onInvertPricesChanged() {
        this._cfg.priceInverter();
    }
}