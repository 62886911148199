import { Injectable } from '@angular/core';
import { MessageBusService } from './message-bus.service';

@Injectable({providedIn: 'root'})
export class ClipboardService {
   
   private readonly _storage: Record<string, any> = {};

   constructor(private readonly _messageBus: MessageBusService) { }

   put(key: string, obj: any) {
      this._storage[key] = obj;
      this._messageBus.publishAsync({
         topic: 'ClipboardUpdated',
         payload: undefined
      });
   }

   get(key: string): any {
      const v = this._storage[key];
      delete this._storage[key];
      return v;
   }

   hasKey(key: string) {
      return key in this._storage;
   }
}
