import { AlgoDescriptionBuilderBase } from '../algo-description-builder-base.class';
import { StrategyParameters } from '../../strategies/strategy-parameters.enum';

export class DynamicHedgeAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
   getSymbolPropertyNames(): string[] {
      return ['symbol'];
   }

   getAlgoName(parameters: StrategyParameters): string {
      return 'Dynamic Hedge';
   }

   getDescription(parameters: StrategyParameters): string {
      let res = '';
      
      const defaultValue = 'N/A';
      
      let symbol: string = parameters.symbol || defaultValue;

      if (symbol !== defaultValue) {
         symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
      }

      res += `[${symbol}] `;

      res += `Mode: "${parameters.deltahedgemode}", `;
      
      res += `Adj. When: ${parameters.deltaadjustwhen}, `;

      if (parameters.deltaadjustto) {
         res += `Adj. To: ${parameters.deltaadjustto}, `;
      }

      res += `Order Type: ${parameters.ordertype}, `;
      res += `Hedge Instr.: ${parameters.deltahedgeinstrument}`;
      
      return res;

   }
}
