<div class="container" [ngClass]="{'ets-hidden': !isActive}">
  <div class="grids-area">
    <ag-grid-angular [ngClass]="{'hidden': selectedTab.id === 'o'}"
                     class="ag-theme-balham-dark"
                     [gridOptions]="positionsGridModel"
                     *ngIf="securityContext.showPositions"
    ></ag-grid-angular>


    <ag-grid-angular [ngClass]="{'hidden': selectedTab.id === 'p'}"
                     class="ag-theme-balham-dark"
                     [gridOptions]="groupExitsGridModel"
                     *ngIf="securityContext.showOrders"
    ></ag-grid-angular>
  </div>
  <dx-tabs [dataSource]="tabs"
           (onItemClick)="selectTab($event)"
           class="tabs"
  ></dx-tabs>
  <ets-confirmation-dialog></ets-confirmation-dialog>
</div>
