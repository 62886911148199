import {EventEmitter} from "@angular/core";
import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {
    ICashFlowStrategySettings
} from "projects/shared-components/adjustment-pricing-grid/model/ICashFlowStrategySettings";
import {OptionExpirationDescriptor} from "projects/shared-components/shell-communication/shell-dto-protocol";
import {
    areTemplateSettingsValuesEqual,
    DxValueChanged,
    isDefaultTemplate,
    isTruthy, isValidNumber,
    isVoid
} from "projects/shared-components/utils";
import {isNullOrUndefined, isUndefined} from "util";
import {ICashFlowAdjustmentSettingsTemplate} from "../../../model/ICashFlowAdjustmentSettingsTemplate";
import {CashFlowStrategyParameterChangedEvent} from "./CashFlowStrategyParameterChangedEvent";

interface ContextDataProvider {
    getSelectedTemplate(): ICashFlowAdjustmentSettingsTemplate;
    isOperationInProgress(): boolean;
}

export class CashFlowStrategySettingsModel implements ICashFlowStrategySettings {

    constructor(
        private readonly _contextDataProvider: ContextDataProvider,
        public readonly isDefaultsEditor?: boolean
    ) {

    }

    private ignoreKeys: (keyof CashFlowStrategySettingsModel)[] = [
        '_contextDataProvider' as any,
        'isDefaultsEditor',
        'parameterChanged$',
        'ignoreKeys',
        'expirations',
        'strategyName',
        'evergreenDates'
    ];

    parameterChanged$ = new EventEmitter<CashFlowStrategyParameterChangedEvent>();

    strategyName: CashFlowStrategy;

    expirations: OptionExpirationDescriptor[];

    evergreenDates = [
        'Next Monday',
        'Next Tuesday',
        'Next Wednesday',
        'Next Thursday',
        'Next Friday'
    ];

    // Short Option

    // Spread
    spreadOffset: number;
    spreadWidth: number;
    spreadRollXDaysBeforeExpiration: number;
    spreadRollToDaysToExp: number;
    spreadOverrideRollToDaysToExp: string;
    spreadEvergreenOverrideRollToDaysToExp: string;

    // 2nd Spread
    secondSpreadEnabled: boolean;
    secondSpreadOffset: number;
    secondSpreadWidth: number;
    secondSpreadRollXDaysBeforeExpiration: number;
    secondSpreadRollToDaysToExp: number;
    secondSpreadOverrideRollToDaysToExp: string;
    secondSpreadEvergreenOverrideRollToDaysToExp: string;

    // Protective Option
    protectiveOptionOffset: number;
    protectiveOptionRollXDaysBeforeExpiration: number;
    protectiveOptionRollToDaysToExp: number;
    protectiveOptionOverrideRollToDaysToExp: string;
    protectiveOptionEvergreenOverrideRollToDaysToExp: string;
    protectiveOptionRollToXBusinessDaysToExp: number;

    // 2nd Protective Option
    secondProtectiveOptionEnabled: boolean;
    secondProtectiveOptionOffset: number;
    secondProtectiveOptionRollXDaysBeforeExpiration: number;
    secondProtectiveOptionRollToDaysToExp: number;
    secondProtectiveOptionOverrideRollToDaysToExp: string;
    secondProtectiveOptionEvergreenOverrideRollToDaysToExp: string;
    secondProtectiveOptionRollToXBusinessDaysToExp: number;

    //
    get isSpreadRollToDateOverriden(): boolean {
        return !isNullOrUndefined(this.spreadOverrideRollToDaysToExp)
            || !isNullOrUndefined(this.spreadEvergreenOverrideRollToDaysToExp);
    }

    //
    // noinspection JSUnusedGlobalSymbols
    get isSecondSpreadRollToDateOverriden(): boolean {
        return !isNullOrUndefined(this.secondSpreadOverrideRollToDaysToExp)
            || !isNullOrUndefined(this.secondSpreadEvergreenOverrideRollToDaysToExp);
    }

    //
    get isProtectiveOptionRollDateOverriden(): boolean {
        return !isNullOrUndefined(this.protectiveOptionOverrideRollToDaysToExp)
            || !isNullOrUndefined(this.protectiveOptionEvergreenOverrideRollToDaysToExp)
            || !isNullOrUndefined(this.protectiveOptionRollToXBusinessDaysToExp);
    }

    //
    // noinspection JSUnusedGlobalSymbols
    get isSecondProtectiveOptionRollDateOverriden(): boolean {
        return !isNullOrUndefined(this.secondProtectiveOptionOverrideRollToDaysToExp)
            || !isNullOrUndefined(this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp)
            || !isNullOrUndefined(this.secondProtectiveOptionRollToXBusinessDaysToExp);
    }

    //
    onPriceToDestinationChanged(priceToDestination: boolean) {

        if (this.isDefaultsEditor) {
            return;
        }

        const template = this._contextDataProvider.getSelectedTemplate();
        const ix = this.strategyName === 'Puts' ? 1 : 0;
        const settings = template[ix];

        if (isVoid(settings)) {
            console.error('unable to restore strategy settings in "priceToDestination" parameter change');
            return;
        }

        this.spreadRollXDaysBeforeExpiration = priceToDestination
            ? settings.spreadRollXDaysBeforeExpiration
            : null;

        this.spreadRollToDaysToExp = priceToDestination
            ? settings.spreadRollToDaysToExp
            : null;

        this.spreadOverrideRollToDaysToExp = priceToDestination
            ? settings.spreadOverrideRollToDaysToExp
            : null;

        this.spreadEvergreenOverrideRollToDaysToExp = priceToDestination
            ? settings.spreadEvergreenOverrideRollToDaysToExp
            : null;

        this.secondSpreadRollXDaysBeforeExpiration = priceToDestination
            ? settings.secondSpreadRollXDaysBeforeExpiration
            : null;

        this.secondSpreadRollToDaysToExp = priceToDestination
            ? settings.secondSpreadRollToDaysToExp
            : null;

        this.secondSpreadOverrideRollToDaysToExp = priceToDestination
            ? settings.secondSpreadOverrideRollToDaysToExp
            : null;

        this.secondSpreadEvergreenOverrideRollToDaysToExp = priceToDestination
            ? settings.secondSpreadEvergreenOverrideRollToDaysToExp
            : null;

        this.protectiveOptionRollXDaysBeforeExpiration = priceToDestination
            ? settings.protectiveOptionRollXDaysBeforeExpiration
            : null;

        this.protectiveOptionRollToDaysToExp = priceToDestination
            ? settings.protectiveOptionRollToDaysToExp
            : null;

        this.protectiveOptionOverrideRollToDaysToExp = priceToDestination
            ? settings.protectiveOptionOverrideRollToDaysToExp
            : null;

        this.protectiveOptionEvergreenOverrideRollToDaysToExp = priceToDestination
            ? settings.protectiveOptionEvergreenOverrideRollToDaysToExp
            : null;

        this.protectiveOptionRollToXBusinessDaysToExp = priceToDestination
            ? settings.protectiveOptionRollXBusinessDays
            : null;

        this.secondProtectiveOptionRollXDaysBeforeExpiration = priceToDestination
            ? settings.secondProtectiveOptionRollXDaysBeforeExpiration
            : null;

        this.secondProtectiveOptionRollToDaysToExp = priceToDestination
            ? settings.secondProtectiveOptionRollToDaysToExp
            : null;

        this.secondProtectiveOptionOverrideRollToDaysToExp = priceToDestination
            ? settings.secondProtectiveOptionOverrideRollToDaysToExp
            : null;

        this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = priceToDestination
            ? settings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp
            : null;

        this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = priceToDestination
            ? settings.secondProtectiveOptionRollToXBusinessDaysToExp
            : null;
    }


    onOptionChainUpdated(expirations: OptionExpirationDescriptor[]) {

        this.expirations = expirations;

        this.parameterChanged$.emit({
            strategy: this.strategyName,
            property: 'expirations',
            value: expirations
        });
    }


    onParameterChanged(event: DxValueChanged<any>, setting: keyof CashFlowStrategySettingsModel) {

        if (isVoid(event.event)) {
            return;
        }

        const isSpreadOverrideDates =
            setting === 'spreadOverrideRollToDaysToExp' ||
            setting === 'secondSpreadOverrideRollToDaysToExp' ||
            setting === 'spreadEvergreenOverrideRollToDaysToExp' ||
            setting === 'secondSpreadEvergreenOverrideRollToDaysToExp';

        if (isSpreadOverrideDates) {

            if (setting.indexOf('second') >= 0) {

                const hasOverrideValues =
                    !isVoid(this.secondSpreadOverrideRollToDaysToExp) ||
                    !isVoid(this.secondSpreadEvergreenOverrideRollToDaysToExp);

                if (hasOverrideValues) {
                    this.secondSpreadRollToDaysToExp = null;
                    this.secondSpreadRollXDaysBeforeExpiration = null;

                    if (setting.indexOf('Evergreen') >= 0) {
                        if (!isVoid(this.secondSpreadEvergreenOverrideRollToDaysToExp)) {
                            this.secondSpreadOverrideRollToDaysToExp = null;
                        }
                    } else {
                        if (!isVoid(this.secondSpreadOverrideRollToDaysToExp)) {
                            this.secondSpreadEvergreenOverrideRollToDaysToExp = null;
                        }
                    }
                } else {

                    const defaultValue = this.getDefaultValue('secondSpreadRollToDaysToExp');
                    const defaultValue1 = this.getDefaultValue('secondSpreadRollXDaysBeforeExpiration');

                    this.secondSpreadRollToDaysToExp = defaultValue;
                    this.secondSpreadRollXDaysBeforeExpiration = defaultValue1;

                    setTimeout(() => {
                        this.onParameterChanged(
                            {event: 'ets', value: this.secondSpreadRollToDaysToExp},
                            'secondSpreadRollToDaysToExp'
                        );
                        this.onParameterChanged(
                            {event: 'ets', value: this.secondSpreadRollXDaysBeforeExpiration},
                            'secondSpreadRollXDaysBeforeExpiration'
                        );
                    });

                }
            } else { // it's a first spread
                const hasOverridenValues =
                    !isVoid(this.spreadOverrideRollToDaysToExp) ||
                    !isVoid(this.spreadEvergreenOverrideRollToDaysToExp);

                if (hasOverridenValues) {
                    this.spreadRollToDaysToExp = null;
                    this.spreadRollXDaysBeforeExpiration = null;

                    if (setting.indexOf('Evergreen') >= 0) {
                        if (!isVoid(this.spreadEvergreenOverrideRollToDaysToExp)) {
                            this.spreadOverrideRollToDaysToExp = null;
                        }
                    } else {
                        if (!isVoid(this.spreadOverrideRollToDaysToExp)) {
                            this.spreadEvergreenOverrideRollToDaysToExp = null;
                        }
                    }
                } else {
                    const defaultValue = this.getDefaultValue('spreadRollToDaysToExp');
                    const defaultValue1 = this.getDefaultValue('spreadRollXDaysBeforeExpiration');

                    this.spreadRollToDaysToExp = defaultValue;
                    this.spreadRollXDaysBeforeExpiration = defaultValue1;

                    setTimeout(() => {
                        this.onParameterChanged(
                            {event: 'ets', value: this.spreadRollToDaysToExp},
                            'spreadRollToDaysToExp'
                        );
                        this.onParameterChanged(
                            {event: 'ets', value: this.spreadRollXDaysBeforeExpiration},
                            'spreadRollXDaysBeforeExpiration'
                        );
                    });
                }
            }
        } else {

            const isProtectiveOptionOverrideDates =
                setting === 'protectiveOptionOverrideRollToDaysToExp' ||
                setting === 'secondProtectiveOptionOverrideRollToDaysToExp' ||
                setting === 'protectiveOptionEvergreenOverrideRollToDaysToExp' ||
                setting === 'secondProtectiveOptionEvergreenOverrideRollToDaysToExp' ||
                setting === 'protectiveOptionRollToXBusinessDaysToExp' ||
                setting === 'secondProtectiveOptionRollToXBusinessDaysToExp';

            if (isProtectiveOptionOverrideDates) {

                if (setting.indexOf('second') >= 0) {
                    const hasOverrideDates =
                        !isVoid(this.secondProtectiveOptionOverrideRollToDaysToExp) ||
                        !isVoid(this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp) ||
                        isValidNumber(this.secondProtectiveOptionRollToXBusinessDaysToExp);

                    if (hasOverrideDates) {

                        this.secondProtectiveOptionRollToDaysToExp = null;
                        this.secondProtectiveOptionRollXDaysBeforeExpiration = null;

                        if (setting.indexOf('Evergreen') >= 0) {
                            if (!isVoid(this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp)) {
                                this.secondProtectiveOptionOverrideRollToDaysToExp = null;
                                this.secondProtectiveOptionRollToXBusinessDaysToExp = null;
                            }
                        } else if (setting.indexOf('Business') >= 0) {
                            if (!isVoid(this.secondProtectiveOptionRollToXBusinessDaysToExp)) {
                                this.secondProtectiveOptionOverrideRollToDaysToExp = null;
                                this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = null;
                            }
                        } else {
                            if (!isVoid(this.secondProtectiveOptionOverrideRollToDaysToExp)) {
                                this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = null;
                                this.secondProtectiveOptionRollToXBusinessDaysToExp = null;
                            }
                        }
                    } else {
                        const defaultValue = this.getDefaultValue('secondProtectiveOptionRollToDaysToExp');
                        const defaultValue1 = this.getDefaultValue('secondProtectiveOptionRollXDaysBeforeExpiration');

                        this.secondProtectiveOptionRollToDaysToExp = defaultValue;
                        this.secondProtectiveOptionRollXDaysBeforeExpiration = defaultValue1;

                        setTimeout(() => {
                            this.onParameterChanged(
                                {event: 'ets', value: this.secondProtectiveOptionRollToDaysToExp},
                                'secondProtectiveOptionRollToDaysToExp'
                            );
                            this.onParameterChanged(
                                {event: 'ets', value: this.secondProtectiveOptionRollXDaysBeforeExpiration},
                                'secondProtectiveOptionRollXDaysBeforeExpiration'
                            );
                        });
                    }
                } else { // it's a first PO

                    const hasOverrideDates =
                        !isVoid(this.protectiveOptionOverrideRollToDaysToExp) ||
                        !isVoid(this.protectiveOptionEvergreenOverrideRollToDaysToExp) ||
                        isValidNumber(this.protectiveOptionRollToXBusinessDaysToExp);

                    if (hasOverrideDates) {
                        this.protectiveOptionRollToDaysToExp = null;
                        this.protectiveOptionRollXDaysBeforeExpiration = null;

                        if (setting.indexOf('Evergreen') >= 0) {
                            if (!isVoid(this.protectiveOptionEvergreenOverrideRollToDaysToExp)) {
                                this.protectiveOptionOverrideRollToDaysToExp = null;
                                this.protectiveOptionRollToXBusinessDaysToExp = null;
                            }
                        } else if (setting.indexOf('Business') >= 0) {
                            if (!isVoid(this.protectiveOptionRollToXBusinessDaysToExp)) {
                                this.protectiveOptionEvergreenOverrideRollToDaysToExp = null;
                                this.protectiveOptionOverrideRollToDaysToExp = null;
                            }
                        } else {
                            if (!isVoid(this.protectiveOptionOverrideRollToDaysToExp)) {
                                this.protectiveOptionEvergreenOverrideRollToDaysToExp = null;
                                this.protectiveOptionRollToXBusinessDaysToExp = null;
                            }
                        }
                    } else {
                        const defaultValue = this.getDefaultValue('protectiveOptionRollToDaysToExp');
                        const defaultValue1 = this.getDefaultValue('protectiveOptionRollXDaysBeforeExpiration');

                        this.protectiveOptionRollToDaysToExp = defaultValue;
                        this.protectiveOptionRollXDaysBeforeExpiration = defaultValue1;

                        setTimeout(() => {
                            this.onParameterChanged(
                                {event: 'ets', value: this.protectiveOptionRollToDaysToExp},
                                'protectiveOptionRollToDaysToExp'
                            );
                            this.onParameterChanged(
                                {event: 'ets', value: this.protectiveOptionRollXDaysBeforeExpiration},
                                'protectiveOptionRollXDaysBeforeExpiration'
                            );
                        });
                    }
                }
            }
        }

        if (event.event === 'ets') {
            return;
        }

        this.parameterChanged$.emit({
            strategy: this.strategyName,
            property: setting,
            value: event.value,
            oldValue: event.previousValue
        });
    }


    applyTemplate(template: ICashFlowAdjustmentSettingsTemplate) {

        const mySettings = template.settings.find(x => x.strategyName === this.strategyName);

        if (isVoid(mySettings)) {
            console.error('cannot find strategy in settings', mySettings, this.strategyName);
            return;
        }


        this.applySettings(mySettings.strategySettings);
    }


    applySettings(strategySettings: ICashFlowStrategySettings) {

        // Short option
        // Spread
        this.spreadOffset = strategySettings.spreadOffset;
        this.spreadWidth = strategySettings.spreadWidth;
        this.spreadRollXDaysBeforeExpiration = strategySettings.spreadRollXDaysBeforeExpiration;
        this.spreadRollToDaysToExp = strategySettings.spreadRollToDaysToExp;
        this.spreadOverrideRollToDaysToExp = strategySettings.spreadOverrideRollToDaysToExp;
        this.spreadEvergreenOverrideRollToDaysToExp = strategySettings.spreadEvergreenOverrideRollToDaysToExp;

        // Second Spread
        this.secondSpreadEnabled = strategySettings.secondSpreadEnabled;
        this.secondSpreadOffset = strategySettings.secondSpreadOffset;
        this.secondSpreadWidth = strategySettings.secondSpreadWidth;
        this.secondSpreadRollXDaysBeforeExpiration = strategySettings.secondSpreadRollXDaysBeforeExpiration;
        this.secondSpreadRollToDaysToExp = strategySettings.secondSpreadRollToDaysToExp;
        this.secondSpreadOverrideRollToDaysToExp = strategySettings.secondSpreadOverrideRollToDaysToExp;
        this.secondSpreadEvergreenOverrideRollToDaysToExp = strategySettings.secondSpreadEvergreenOverrideRollToDaysToExp;


        // Protective Option
        this.protectiveOptionOffset = strategySettings.protectiveOptionOffset;
        this.protectiveOptionRollXDaysBeforeExpiration
            = strategySettings.protectiveOptionRollXDaysBeforeExpiration;
        this.protectiveOptionRollToDaysToExp = strategySettings.protectiveOptionRollToDaysToExp;
        this.protectiveOptionOverrideRollToDaysToExp = strategySettings.protectiveOptionOverrideRollToDaysToExp;
        this.protectiveOptionEvergreenOverrideRollToDaysToExp
            = strategySettings.protectiveOptionEvergreenOverrideRollToDaysToExp;
        this.protectiveOptionRollToXBusinessDaysToExp
            = strategySettings.protectiveOptionRollToXBusinessDaysToExp;

        // 2nd PO
        this.secondProtectiveOptionEnabled = strategySettings.secondProtectiveOptionEnabled;
        this.secondProtectiveOptionOffset = strategySettings.secondProtectiveOptionOffset;
        this.secondProtectiveOptionRollXDaysBeforeExpiration
            = strategySettings.secondProtectiveOptionRollXDaysBeforeExpiration;
        this.secondProtectiveOptionRollToDaysToExp = strategySettings.secondProtectiveOptionRollToDaysToExp;
        this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp
            = strategySettings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp;
        this.secondProtectiveOptionRollToXBusinessDaysToExp
            = strategySettings.secondProtectiveOptionRollToXBusinessDaysToExp;
    }


    setParameter(property: string, value: any) {
        this[property] = value;
        this.onParameterChanged({event: 'ets', value}, property as any);
    }


    getSettings(): ICashFlowStrategySettings {

        function getValue(v: string | number) {
            if (isUndefined(v)) return null;
            return v;
        }

        return {
            strategyName: this.strategyName,

            // Spread
            spreadOffset: getValue(this.spreadOffset),
            spreadWidth: getValue(this.spreadWidth),
            spreadRollXDaysBeforeExpiration: getValue(this.spreadRollXDaysBeforeExpiration),
            spreadRollToDaysToExp: getValue(this.spreadRollToDaysToExp),
            spreadOverrideRollToDaysToExp: getValue(this.spreadOverrideRollToDaysToExp),
            spreadEvergreenOverrideRollToDaysToExp: getValue(this.spreadEvergreenOverrideRollToDaysToExp),

            // 2nd Spread
            secondSpreadEnabled: this.secondSpreadEnabled,
            secondSpreadOffset: this.secondSpreadEnabled ? getValue(this.secondSpreadOffset) : null,
            secondSpreadWidth: this.secondSpreadEnabled ? getValue(this.secondSpreadWidth) : null,
            secondSpreadRollXDaysBeforeExpiration: this.secondSpreadEnabled ? getValue(this.secondSpreadRollXDaysBeforeExpiration) : null,
            secondSpreadRollToDaysToExp: this.secondSpreadEnabled ? getValue(this.secondSpreadRollToDaysToExp) : null,
            secondSpreadOverrideRollToDaysToExp: this.secondSpreadEnabled ? getValue(this.secondSpreadOverrideRollToDaysToExp) : null,
            secondSpreadEvergreenOverrideRollToDaysToExp: this.secondSpreadEnabled ? getValue(this.secondSpreadEvergreenOverrideRollToDaysToExp) : null,

            // Protective Option
            protectiveOptionOffset: getValue(this.protectiveOptionOffset),
            protectiveOptionRollXDaysBeforeExpiration: getValue(this.protectiveOptionRollXDaysBeforeExpiration),
            protectiveOptionRollToDaysToExp: getValue(this.protectiveOptionRollToDaysToExp),
            protectiveOptionOverrideRollToDaysToExp: getValue(this.protectiveOptionOverrideRollToDaysToExp),
            protectiveOptionEvergreenOverrideRollToDaysToExp: getValue(this.protectiveOptionEvergreenOverrideRollToDaysToExp),
            protectiveOptionRollToXBusinessDaysToExp: getValue(this.protectiveOptionRollToXBusinessDaysToExp),

            // 2nd Protective Option
            secondProtectiveOptionEnabled: this.secondProtectiveOptionEnabled,
            secondProtectiveOptionOffset: this.secondProtectiveOptionEnabled ? getValue(this.secondProtectiveOptionOffset) : null,
            secondProtectiveOptionRollXDaysBeforeExpiration: this.secondProtectiveOptionEnabled ? getValue(this.secondProtectiveOptionRollXDaysBeforeExpiration) : null,
            secondProtectiveOptionRollToDaysToExp: this.secondProtectiveOptionEnabled ? getValue(this.secondProtectiveOptionRollToDaysToExp) : null,
            secondProtectiveOptionOverrideRollToDaysToExp: this.secondProtectiveOptionEnabled ? getValue(this.secondProtectiveOptionOverrideRollToDaysToExp) : null,
            secondProtectiveOptionEvergreenOverrideRollToDaysToExp: this.secondProtectiveOptionEnabled ? getValue(this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp) : null,
            secondProtectiveOptionRollToXBusinessDaysToExp: this.secondProtectiveOptionEnabled ? getValue(this.secondProtectiveOptionRollToXBusinessDaysToExp) : null,

        } as ICashFlowStrategySettings
    }


    changeSecondSpreadState(isEnabled: boolean, notify: boolean = true) {

        if (this.secondSpreadEnabled === isEnabled) {
            return;
        }

        this.secondSpreadEnabled = isEnabled;


        if (this.isDefaultsEditor) {

            this.parameterChanged$.emit({
                property: 'secondSpreadEnabled',
                value: this.secondSpreadEnabled,
                strategy: this.strategyName
            });

            return;
        }

        if (!isEnabled) {

            this.secondSpreadOffset = null;
            this.secondSpreadWidth = null;
            this.secondSpreadRollXDaysBeforeExpiration = null;
            this.secondSpreadRollToDaysToExp = null;
            this.secondSpreadOverrideRollToDaysToExp = null;
            this.secondSpreadEvergreenOverrideRollToDaysToExp = null;

        } else {

            const template = this._contextDataProvider.getSelectedTemplate();
            const ix = this.strategyName === 'Puts' ? 1 : 0;
            const tplSettings = template.settings[ix].strategySettings;

            this.secondSpreadOffset = tplSettings.secondSpreadOffset;
            this.secondSpreadWidth = tplSettings.secondSpreadWidth;
            this.secondSpreadRollXDaysBeforeExpiration = tplSettings.secondSpreadRollXDaysBeforeExpiration;

            if (tplSettings.secondSpreadEvergreenOverrideRollToDaysToExp) {

                this.secondSpreadEvergreenOverrideRollToDaysToExp = tplSettings.secondSpreadEvergreenOverrideRollToDaysToExp;
                this.secondSpreadOverrideRollToDaysToExp = null;
                this.secondSpreadRollToDaysToExp = null;

            } else {
                this.secondSpreadRollToDaysToExp = tplSettings.secondSpreadRollToDaysToExp;
                this.secondSpreadOverrideRollToDaysToExp = null;
                this.secondSpreadEvergreenOverrideRollToDaysToExp = null;
            }

        }

        if (notify) {

            this.parameterChanged$.emit({
                property: 'secondSpreadEnabled',
                value: this.secondSpreadEnabled,
                strategy: this.strategyName
            });

        }
    }


    changeSecondProtectiveOptionState(isEnabled: boolean, notify: boolean = true) {

        if (this.secondProtectiveOptionEnabled === isEnabled) {
            return;
        }

        this.secondProtectiveOptionEnabled = isEnabled;


        if (this.isDefaultsEditor) {

            this.parameterChanged$.emit({
                property: 'secondProtectiveOptionEnabled',
                value: this.secondProtectiveOptionEnabled,
                strategy: this.strategyName
            });

            return;
        }


        if (!isEnabled) {

            this.secondProtectiveOptionOffset = null;
            this.secondProtectiveOptionRollXDaysBeforeExpiration = null;
            this.secondProtectiveOptionRollToDaysToExp = null;
            this.secondProtectiveOptionOverrideRollToDaysToExp = null;
            this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = null;
            this.secondProtectiveOptionRollToXBusinessDaysToExp = null;

        } else {

            const template = this._contextDataProvider.getSelectedTemplate();
            const ix = this.strategyName === 'Puts' ? 1 : 0;
            const tplSettings = template.settings[ix].strategySettings;

            this.secondProtectiveOptionOffset = tplSettings.secondProtectiveOptionOffset;
            this.secondProtectiveOptionRollXDaysBeforeExpiration = tplSettings.secondProtectiveOptionRollXDaysBeforeExpiration;

            if (!isVoid(tplSettings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp)) {

                this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp
                    = tplSettings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp;

                this.secondProtectiveOptionOverrideRollToDaysToExp = null;
                this.secondProtectiveOptionRollToDaysToExp = null;
                this.secondProtectiveOptionRollToXBusinessDaysToExp = null;

            } else if (!isVoid(tplSettings.secondProtectiveOptionRollToXBusinessDaysToExp)) {
               this.secondProtectiveOptionRollToXBusinessDaysToExp
                   = tplSettings.secondProtectiveOptionRollToXBusinessDaysToExp;

               this.secondProtectiveOptionRollToXBusinessDaysToExp = null;
               this.secondProtectiveOptionRollToDaysToExp = null;
               this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = null;

            } else {

                this.secondProtectiveOptionRollToDaysToExp = tplSettings.secondProtectiveOptionRollToDaysToExp;

                this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp = null;
                this.secondProtectiveOptionOverrideRollToDaysToExp = null;
                this.secondProtectiveOptionRollToXBusinessDaysToExp = null;

            }
        }

        if (notify) {

            this.parameterChanged$.emit({
                property: 'secondProtectiveOptionEnabled',
                value: this.secondProtectiveOptionEnabled,
                strategy: this.strategyName
            });

        }
    }


    validate(validationCtx: { shouldRollDates: boolean }): string {
        // Short Option


        // Spread

        if (isNullOrUndefined(this.spreadOffset)) {
            return this.getErrorString('Spread Offset');
        }

        if (!isTruthy(this.spreadWidth)) {
            return this.getErrorString('Spread Width');
        }

        if (isNullOrUndefined(this.spreadRollXDaysBeforeExpiration)) {
            if (validationCtx.shouldRollDates) {
                if (!this.isSpreadRollToDateOverriden) {
                    return this.getErrorString('Spread Roll [x] Days Before Expiration');
                }
            }
        }

        if (!isTruthy(this.spreadRollToDaysToExp)) {
            if (validationCtx.shouldRollDates) {
                if (!this.isSpreadRollToDateOverriden) {
                    return this.getErrorString('Spread Roll To Days To Expiration');
                }
            }
        }

        //2nd Spread

        if (this.secondSpreadEnabled) {
            if (isNullOrUndefined(this.secondSpreadOffset)) {
                return this.getErrorString('2nd Spread Offset');
            }

            if (!isTruthy(this.secondSpreadWidth)) {
                return this.getErrorString('2nd Spread Width');
            }

            if (isNullOrUndefined(this.secondSpreadRollXDaysBeforeExpiration)) {
                if (validationCtx.shouldRollDates) {
                    if (!this.isSpreadRollToDateOverriden) {
                        return this.getErrorString('2nd Spread Roll [x] Days Before Expiration');
                    }
                }
            }

            if (!isTruthy(this.secondSpreadRollToDaysToExp)) {
                if (validationCtx.shouldRollDates) {
                    if (!this.isSpreadRollToDateOverriden) {
                        return this.getErrorString('2nd Spread Roll To Days To Expiration');
                    }
                }
            }
        }

        // Protective Put

        if (isNullOrUndefined(this.protectiveOptionOffset)) {
            return this.getErrorString('Protective Option Offset');
        }

        if (isNullOrUndefined(this.protectiveOptionRollXDaysBeforeExpiration)) {
            if (validationCtx.shouldRollDates) {
                if (!this.isProtectiveOptionRollDateOverriden) {
                    return this.getErrorString('Protective Option Roll [x] Days Before Expiration');
                }
            }
        }

        if (!isTruthy(this.protectiveOptionRollToDaysToExp)) {
            if (validationCtx.shouldRollDates) {
                if (!this.isProtectiveOptionRollDateOverriden) {
                    return this.getErrorString('Protective Option Roll To  Days To Exp');
                }
            }
        }

        // 2nd Protective Put

        if (this.secondProtectiveOptionEnabled) {
            if (isNullOrUndefined(this.secondProtectiveOptionOffset)) {
                return this.getErrorString('2nd Protective Option Offset');
            }

            if (isNullOrUndefined(this.secondProtectiveOptionRollXDaysBeforeExpiration)) {
                if (validationCtx.shouldRollDates) {
                    if (!this.isSecondProtectiveOptionRollDateOverriden) {
                        return this.getErrorString('2nd Protective Option Roll [x] Days Before Expiration');
                    }
                }
            }

            if (!isTruthy(this.secondProtectiveOptionRollToDaysToExp)) {
                if (validationCtx.shouldRollDates) {
                    if (!this.isSecondProtectiveOptionRollDateOverriden) {
                        return this.getErrorString('2nd Protective Option Roll To  Days To Exp');
                    }
                }
            }
        }

        // Expirations

        if (validationCtx.shouldRollDates) {

            if (isVoid(this.spreadOverrideRollToDaysToExp) &&
                isVoid(this.spreadRollToDaysToExp) &&
                isVoid(this.spreadEvergreenOverrideRollToDaysToExp)
            ) {
                return 'Spread: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
            }

            if (isVoid(this.protectiveOptionOverrideRollToDaysToExp) &&
                isVoid(this.protectiveOptionRollToDaysToExp) &&
                isVoid(this.protectiveOptionEvergreenOverrideRollToDaysToExp) &&
                !isValidNumber(this.protectiveOptionRollToXBusinessDaysToExp)
            ) {
                return 'Protective Option: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
            }

            if (this.secondSpreadEnabled) {
                if (
                    isVoid(this.secondSpreadOverrideRollToDaysToExp) &&
                    isVoid(this.secondSpreadRollToDaysToExp) &&
                    isVoid(this.secondSpreadEvergreenOverrideRollToDaysToExp)
                ) {
                    return '2nd Spread: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
                }
            }

            if (this.secondProtectiveOptionEnabled) {
                if (
                    isVoid(this.secondProtectiveOptionOverrideRollToDaysToExp) &&
                    isVoid(this.secondProtectiveOptionRollToDaysToExp) &&
                    isVoid(this.secondProtectiveOptionEvergreenOverrideRollToDaysToExp) &&
                    !isValidNumber(this.secondProtectiveOptionRollToXBusinessDaysToExp)
                ) {
                    return '2nd Protective Option: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
                }
            }

            if (!isNullOrUndefined(this.spreadRollToDaysToExp)) {
                if (this.spreadRollToDaysToExp < this.spreadRollXDaysBeforeExpiration) {
                    return '"Spread: "Roll To Days To Exp." must be greater than "Roll [x] Days Before Expiration"'
                }
            }

            if (!isNullOrUndefined(this.protectiveOptionRollToDaysToExp)) {
                if (this.protectiveOptionRollToDaysToExp < this.protectiveOptionRollXDaysBeforeExpiration) {
                    return '"Protective Option: "Roll To Days To Exp." must be greater than "Roll [x] Days Before Expiration"'
                }
            }
        }

        return null;
    }


    reset() {
        this.applySettings({});
    }


    isDifferentFromTemplate(name?: keyof CashFlowStrategySettingsModel): boolean {

        if (this._contextDataProvider.isOperationInProgress()) {
            return false;
        }

        if (isVoid(name)) {
            let keys = Object.keys(this) as (keyof CashFlowStrategySettingsModel)[];
            keys = keys.filter(x => this.ignoreKeys.indexOf(x) < 0);
            const differences = keys.map(x => this.isDifferentFromTemplate(x));
            return differences.some(x => x);
        }

        const template = this._contextDataProvider.getSelectedTemplate();

        if (isVoid(template)) {
            return false;
        }

        const isUsingDefaultTemplate = isDefaultTemplate(template);

        if (isUsingDefaultTemplate) {
            if (name === 'secondSpreadEnabled' || name === 'secondProtectiveOptionEnabled') {
                return false;
            }
        }

        const ix = this.strategyName === 'Puts' ? 1 : 0;
        let settingsTemplateSet;
        if (template.settings.length > 1) {
            settingsTemplateSet = template.settings[ix];
        } else {
            settingsTemplateSet = template.settings[0];
        }

        const originalValue = settingsTemplateSet.strategySettings[name];

        const currentValue = this[name];

        let areEqual = areTemplateSettingsValuesEqual(originalValue, currentValue);

        if (name.startsWith('second')) {
            if (name.toLowerCase().indexOf('protective') > 0) {
                const sameState = areTemplateSettingsValuesEqual(
                    this.secondProtectiveOptionEnabled,
                    settingsTemplateSet.strategySettings.secondProtectiveOptionEnabled
                );
                if (sameState) {
                    if (!this.secondProtectiveOptionEnabled) {
                        areEqual = true; // if both are disabled, don't indicate diff
                    }
                } else {
                    if (name !== 'secondProtectiveOptionEnabled' && !isUsingDefaultTemplate) {
                        // don't indicate diff for every element of the section because
                        // whole section already marked
                        areEqual = true
                    }
                }
            } else if (name.toLowerCase().indexOf('spread') > 0) {
                const sameState = areTemplateSettingsValuesEqual(
                    this.secondSpreadEnabled,
                    settingsTemplateSet.strategySettings.secondSpreadEnabled
                );
                if (sameState) {
                    if (!this.secondSpreadEnabled) {
                        areEqual = true; // if both are disabled, don't indicate diff
                    }
                } else {
                    if (name !== 'secondSpreadEnabled' && !isUsingDefaultTemplate) {
                        // don't indicate diff for every element of the section because
                        // whole section already marked
                        areEqual = true;
                    }
                }
            }
        }

        return !areEqual;
    }


    getHint(name: keyof ICashFlowStrategySettings): any {
        const template = this._contextDataProvider.getSelectedTemplate();

        if (isVoid(template)) {
            return '(Value Not Defined)';
        }

        if (isVoid(template.settings)) {
            return '(Value Not Defined)';
        }

        const ix = this.strategyName === 'Puts' ? 1 : 0;

        const settingsTemplateSet = template.settings[ix];

        if (isVoid(settingsTemplateSet)) {
            return '(Value Not Defined)';
        }

        const originalValue = settingsTemplateSet.strategySettings[name];

        return isVoid(originalValue) ? '(Value Not Defined)' : originalValue;
    }


    private getDefaultValue(name: keyof CashFlowStrategySettingsModel): any {

        const template = this._contextDataProvider.getSelectedTemplate();

        if (isVoid((template))) {
            return null;
        }

        const ix = this.strategyName === 'Puts' ? 1 : 0;

        const settings = template.settings[ix];

        if (isVoid(settings)) {
            return null;
        }

        return settings.strategySettings[name];
    }


    private getErrorString(parameter: string): string {
        return `"${parameter}" a mandatory parameter`;
    }
}
