import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DetectMethodChanges } from '../utils';
import { TimestampsService } from '../timestamps.service';
import { findTimezoneOrigin } from '../timezones/time-zones.const';

@Component({
   selector: 'ets-market-time-component',
   templateUrl: './market-time.component.html',
   styleUrls: ['./market-time.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketTimeComponent implements OnInit, OnDestroy {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _timestampsService: TimestampsService
   ) { }

   private _interval;

   //
   time : string;

   //
   timezone : string;
   
   //
   ngOnInit(): void { 
      this.updateTime();
      this._interval = setInterval(() => this.updateTime(), 1000);
   }

   //
   ngOnDestroy(): void {
      clearInterval(this._interval);
   }

   //
   @DetectMethodChanges()
   private updateTime() {
      const tz = this._timestampsService.getDefaultTimezone('UTC');
      const tzObject = findTimezoneOrigin(tz);
      this.timezone = tzObject.windowsTimeZoneId;

      const time = this._timestampsService.getZonedFormattedDateTime(new Date(), 'hh:mm:ss a');
      this.time = time;
   }
}
