import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from '../devextreme.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import {OptionPricingGridTemplatesService} from "./option-pricing-grid-templates.service";
import {GenericConfirmationDialogModule} from "../generic-confirmation-dialog/generic-confirmation-dialog.module";
import {OptionsPricingGridComponent} from "./options-pricing-grid.component";
import {PositionModifierComponent} from "./position-modifier.component";
import {OrdersVerificationDialogComponent} from "./orders-verification-dialog/orders-verification-dialog.component";
import {HedgingGridModule} from "../hedging-grid/hedging-grid.module";
import {NormalizingCalculatorComponent} from "./normalizing-calculator/normalizing-calculator.component";

@NgModule({
    imports: [
        CommonModule,
        EtsSymbolPickerModule,
        AngularSplitModule,
        OptionsCommonModule,
        AgGridModule.withComponents([]),
        DevExtremeModule,
        GenericConfirmationDialogModule,
        HedgingGridModule
    ],
   exports: [
       OptionsPricingGridComponent,
       PositionModifierComponent,
       NormalizingCalculatorComponent
   ],
   declarations: [
       OptionsPricingGridComponent,
       PositionModifierComponent,
       OrdersVerificationDialogComponent,
       NormalizingCalculatorComponent
   ],
   providers: [
       // OptionPricingGridTemplatesService
   ],
})
export class OptionsPricingGridModule { }
