import { CashFlowStrategy } from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";

export abstract class LegsOverviewBlockBase {
   header: string;
   legId: string;
   ticker: string;
   tickerDisplayName: string;
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   qty: number;
   asset: string;
   expirationDate: string;
   daysToExpiration: number;
   backgroundColor: string;
   color: string;
}
