import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { EtsConstants } from '../ets-constants.const';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { IPanelComponent } from '../panels/panel-component.interface';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetStrategyHedgedPositions } from '../shell-communication/shell-operations-protocol';
import { StrategyModel } from '../strategies/strategy-model';
import { TimestampsService } from '../timestamps.service';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { ShellConnectionStatusChangedUIMessage } from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { getPanelStateKey, isHedgingAlgo, isNullOrUndefined } from '../utils';
import { getStrategyHedgedPositionsGridModel } from './strategy-hedged-positions-grid-options';
import { BucketItemHighlighted } from '../ui-messages/ui-messages';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { StrategiesService } from '../strategies/strategies.service';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-strategy-hedge-positions',
   templateUrl: 'strategy-hedged-positions.component.html',
   styleUrls: ['strategy-hedged-positions.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class StrategyHedgedPositionsComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      
      private readonly _toastr: ToastrService,
      private readonly _timestampsService: TimestampsService,
      private readonly _shellService: ShellClientService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _strategyService: StrategiesService
   ) { 
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   private _unsubscriber: Subject<void>;
   private _grid: GridReadyEvent;
   private _stateChanged = new EventEmitter();
   private readonly _pendingDataContainer: {
      isLoading: boolean;
      data: PositionDto[];
   } = { isLoading: false, data: [] };

   gridOptions: GridOptions;

   hedgeStrategyId: string;
   
   get gridApi(): GridApi {
      return this._grid.api;
   }

   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   get unsubscriber(): Subject<void> {
      return this._unsubscriber;
   }


   etsOnInit() {
      this._unsubscriber = new Subject();
      this.gridOptions = getStrategyHedgedPositionsGridModel.bind(this)();
   }


   etsAfterViewInit() { }

   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   
   async onGridReady(args: GridReadyEvent): Promise<void> {
      this._grid = args;

      this.subscribeToMessages();
      await this.loadLastHighlightedStrategy();
   }

   
   onStateChanged(): void {
      this._stateChanged.emit();
   }


   private async loadLastHighlightedStrategy(): Promise<void> {
      const lastMessage = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>(
         'StrategyHighlightedUIMessage',
         this.layoutTabId
      );
      if (lastMessage) {
         await this.onStrategyHighlightedMessage(lastMessage.payload);
      }
   }
   

   private subscribeToMessages() {

      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onStrategyHighlightedMessage(message.payload));


      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors && !!this.hedgeStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onClearTradingDataMessage(message.payload));


      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this.onShellConnectionStatusChanged(data.payload));

         
      this._messageBus
         .of<any>('WorkspaceClosed')
         .pipe(
            filter(msg => msg.payload.workspaceId === this.workspaceId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onRemovedFromWorkspace());


      this._stateChanged
         .pipe(
            throttleTime(250),
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => this.saveState());


      this._messageBus
         .of<PositionDto[]>('PositionDto')
         .pipe(
            map(msg => msg.payload.filter(x => !x.isArchived && (x.strategyId === this.hedgeStrategyId || x.hedgeStrategyId === this.hedgeStrategyId))),
            filter(positions => positions.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(positions => this.onPositionDtoMessage(positions));

      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onQuoteMessage(msg.payload));

      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId),
            filter(msg => msg.payload.item.itemType === PortfolioItemType.Strategy)
         )
         .subscribe(msg => this.onBucketItemHighlighted(msg.payload));

   }

   //

   private async onBucketItemHighlighted(msg: BucketItemHighlighted): Promise<void> {
      if (msg.item.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const strategyId = msg.item.portfolioItemId;

      if (!strategyId) {
         this._toastr.error('Strategy Not Found', 'Strategy Hedged Positions');
         return;
      }

      const strategy = this._strategyService.getById(strategyId);

      if (!strategy) {
         this._toastr.error('Strategy Not Found', 'Strategy Hedged Positions');
         return;
      }

      const strategyHighlightedMessage: StrategyHighlightedUIMessage = { strategyId, strategy };

      await this.onStrategyHighlightedMessage(strategyHighlightedMessage);
   }

   //

   private async onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage): Promise<any> {
      
      if (!isHedgingAlgo(message.strategy.algoId)) {
         this.hedgeStrategyId = null;
         this._grid.api.setRowData([]);
         
         return;
      }

      if (this.hedgeStrategyId) {
         if (message.strategyId === this.hedgeStrategyId) {
            return;
         }   
      }

      this.hedgeStrategyId = message.strategyId;

      try {
         this._grid.api.showLoadingOverlay();
         await this.loadPositions();
      } catch (error) {
         this._grid.api.setRowData([]);
         const errorMessage = 'Failed to load positions';
         this._toastr.error(errorMessage);
      } finally {
         this._grid.api.hideOverlay();
      }
   }


   private onClearTradingDataMessage(message: ClearTradingDataUIMessage): void {
      if (message.hasErrors) {
         return;
      }

      if (message.strategies.includes(this.hedgeStrategyId) || message.refreshDb) {
         this._grid.api.setRowData([]);
      }
   }


   private async onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {
      if (!this.hedgeStrategyId) {
         return;
      }
      
      const strategyId = this.hedgeStrategyId;
      this.hedgeStrategyId = null;
      await this.onStrategyHighlightedMessage({ strategyId });
   }


   private onPositionDtoMessage(positions: PositionDto[]) {
      
      if (!this.hedgeStrategyId) {
         return;
      }

      if (this._pendingDataContainer.isLoading) {

         this._pendingDataContainer.data.push(...positions);

         return;

      } 

      if (this._pendingDataContainer.data.length) {
         positions.push(...this._pendingDataContainer.data);
         this._pendingDataContainer.data.length = 0;
      }

      const actualStateOfPositions: Record<string, PositionDto> = {};

      positions.forEach(dto => {
         if (dto.isArchived) {
            return;
         }

         if (dto.hedgeStrategyId !== this.hedgeStrategyId && dto.strategyId !== this.hedgeStrategyId)  {
            return;
         }

         actualStateOfPositions[dto.positionId] = dto;
      });

      const reducedPositions = Object.values(actualStateOfPositions);

      const toAdd: PositionDto[] = [];
      const toUpdate: PositionDto[] = [];

      reducedPositions.forEach(pos => {
         const node = this._grid.api.getRowNode(pos.positionId);
         if (!node) {
            toAdd.push(pos);
         } else {
            toUpdate.push(pos);
            pos.liveQuote = node.data.liveQuote;
         }
      });


      const tikcersToSubscribe = toAdd.map(x => {
         const lq = this._lastQuoteCache.getLastQuote(x.ticker);
         
         if (lq) {
            x.liveQuote = lq.lastPx;
         } 

         return lq ? null : x.ticker;

      }).filter(x => !isNullOrUndefined(x));

      if (tikcersToSubscribe.length > 0) {
         this._lastQuoteCache.subscribeTickers(tikcersToSubscribe);
      }

      // const pinAdd = toAdd.filter(x => x.strategyId === this.hedgeStrategyId);
      
      this._grid.api.applyTransaction({
         add: toAdd,
         update: toUpdate
      });
   }


   private async loadPositions(): Promise<void> {

      this._grid.api.showLoadingOverlay();
      this._pendingDataContainer.isLoading = true;

      let positionDtos: PositionDto[] = [];

      try {
         
         this._grid.api.setRowData([]);
         const query = new GetStrategyHedgedPositions(this.hedgeStrategyId);
         positionDtos = await this._shellService.processQuery<PositionDto[]>(query);
         
      } catch (error) {

         const errorMessage = '"Get Strategy Hedged Positions" operation completed with errors';
         this._toastr.error(errorMessage);

      } finally {

         this._pendingDataContainer.isLoading = false;
         this._grid.api.hideOverlay();

      }

      this.onPositionDtoMessage(positionDtos);
   }


   private onQuoteMessage(quotes: QuoteDto[]) {
      const nodes = [];

      this._grid.api.forEachLeafNode(node => {
         
         const pos = node.data as PositionDto;
         
         const quote = quotes.find(q => q.ticker === pos.ticker);
               
         if (quote) {
            if (pos.netPosition > 0) {

               pos.liveQuote = quote.bid;

            } else if (pos.netPosition < 0) {
               
               pos.liveQuote = quote.ask;

            } else {

               pos.liveQuote = quote.lastPx;

            }

            nodes.push(node);
         }
      });

      if (nodes.length > 0)  {
         this._grid.api.refreshCells({columns: ['liveQuote'], rowNodes: nodes});
      }
   }


   protected getState(): any { return null; }

   protected setState(state) { }
}
