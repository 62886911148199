import { ManualTradingBackendService } from 'projects/shared-components/manual-trading/manual-trading-backend.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { GetTradingDataResponseWeb } from 'projects/shared-components/shell-communication/dtos/get-trading-data-response-web.class';
import { GetManualPositions } from 'projects/shared-components/shell-communication/operations/manual-trading/get-manual-positions.class';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { GetAccounts } from 'projects/shared-components/shell-communication/operations/accounts/get-accounts.class';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import { RequestOrderStatus } from 'projects/shared-components/shell-communication/operations/orders/request-order-status.class';
import { CancelManualOrders } from 'projects/shared-components/shell-communication/operations/manual-trading/cancel-manual-orders.class';
import { AddManualPosition } from 'projects/shared-components/shell-communication/operations/manual-trading/add-manual-position.class';
import {
   CheckIfManualPositionExists
} from 'projects/shared-components/shell-communication/operations/manual-trading/check-if-manual-position-exists.class';
import { OverridePosition } from 'projects/shared-components/shell-communication/operations/strategies/override-position.class';
import { ArchivePosition } from 'projects/shared-components/shell-communication/operations/archived-positions/archive-position.class';
import {
   ReverseManualPosition
} from 'projects/shared-components/shell-communication/operations/manual-trading/reverse-manual-position.class';
import { CloseManualPosition } from 'projects/shared-components/shell-communication/operations/manual-trading/close-manual-position.class';
import {
   AdjustManualPosition
} from 'projects/shared-components/shell-communication/operations/manual-trading/adjust-manual-position.class';
import { ReplaceOrder } from 'projects/shared-components/shell-communication/operations/manual-trading/replace-order.class';
import { Injectable } from '@angular/core';
import { RollNextMonth } from 'projects/shared-components/shell-communication/operations/strategies/roll-next-month.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { 
   GetOrderStateSnapshotsWeb, 
   GetPortfolios, 
   GetAvailableBuckets, 
   GetTradesWeb, 
   GetWorkingOrdersWeb, 
   ConvertLimitToMarket
} from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { GetAvailableBucketsReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { SendManualOrder } from 'projects/shared-components/shell-communication/operations/manual-trading/send-manual-order.class';
import { getShortUUID } from 'projects/shared-components/utils';
import { OrderConfirmationService } from 'projects/shared-components/manual-trading/order-confirmation-service.service';
import { environment } from 'projects/shared-components/environments/environment';

@Injectable({ providedIn: 'root' })
export class WebtraderManualTradingBackendService extends ManualTradingBackendService {
   
   constructor(
        private readonly _shellClient: ShellClientService
      , private readonly _orderConfirmationService: OrderConfirmationService
      ) {
      super();
   }

   //#region Queries

   getWorkingOrdersWeb(query: GetWorkingOrdersWeb): Promise<GetTradingDataResponseWeb> {
      return this._shellClient.processQuery(query);
   }
   
   getOrderStateSnapshotsWeb(query: GetOrderStateSnapshotsWeb): Promise<GetTradingDataResponseWeb> {
      return this._shellClient.processQuery(query);
   }
   
   getTradesWeb(query: GetTradesWeb): Promise<GetTradingDataResponseWeb> {
      return this._shellClient.processQuery(query);
   }
   
   getManualPositions(query: GetManualPositions, shellId = ''): Promise<PositionDto[]> {
      return this._shellClient.processQuery(query);
   }
   
   getAccounts(query: GetAccounts): Promise<AccountDto[]> {
      return this._shellClient.processQuery(query);
   }
   
   checkIfManualPositionExists(query: CheckIfManualPositionExists): Promise<PositionDto> {
      return this._shellClient.processQuery(query);
   }

   getAvailableBuckets(): Promise<GetAvailableBucketsReply> {
      return this._shellClient.processQuery<GetAvailableBucketsReply>(new GetAvailableBuckets());
   }
   
   //#endregion

   //#region Commands

   sendManualOrder(cmd: SendManualOrder): Promise<void> {

      const token = getShortUUID();
      cmd.guiToken = token;

      const commandPromise = this._shellClient.processCommand(cmd);

      if (environment.disableOrderConfirmations) {

         return commandPromise;

      }

      return this._orderConfirmationService.registerToken(token);
   
   }

   cancelManualOrders(cmd: CancelManualOrders): Promise<void[]> {

      if (environment.disableOrderConfirmations) {
         
         return this._shellClient.processCommand(cmd);

      }

      const promises = cmd.orderIds.map(orderId => this._orderConfirmationService.registerToken(orderId));

      const cmdPromise = this._shellClient.processCommand(cmd);

      promises.push(cmdPromise);
      
      return Promise.all(promises);
   }

   requestOrderStatus(orders: { orderId: string, shellId: string }[]): Promise<void> {
      
      const ordersByShell: Record<string, string[]> = {};
      
      orders.forEach(orderShell => {
         if (!(orderShell.shellId in ordersByShell)) {
            ordersByShell[orderShell.shellId] = [];
         }
         ordersByShell[orderShell.shellId].push(orderShell.orderId);
      });

      const promises = [];
      
      for (const shellId of Object.keys(ordersByShell)) {
         const shellOrders = ordersByShell[shellId];
         const cmd = new RequestOrderStatus(shellOrders);
         const promise = this._shellClient.processCommand(cmd);
         promises.push(promise);
      }
      
      return Promise.all(promises) as any;
   }
   
   addManualPosition(cmd: AddManualPosition): Promise<void> {
      return this._shellClient.processCommand(cmd);
   }
   
   overridePosition(cmd: OverridePosition): Promise<void> {
      return this._shellClient.processCommand(cmd);
   }
   
   archivePosition(cmd: ArchivePosition): Promise<void> {
      return this._shellClient.processCommand(cmd);
   }
   
   reverseManualPosition(cmd: ReverseManualPosition): Promise<void> {
      cmd.guiToken = getShortUUID();
      
      const commandPromise = this._shellClient.processCommand(cmd);

      if (environment.disableOrderConfirmations) {

         return commandPromise;

      }
      
      return this._orderConfirmationService.registerToken(cmd.guiToken);
   }
   
   closeManualPosition(cmd: CloseManualPosition): Promise<void> {
      cmd.guiToken = getShortUUID();

      const commandPromise =  this._shellClient.processCommand(cmd);

      if (environment.disableOrderConfirmations) {
         return commandPromise;
      }

      return this._orderConfirmationService.registerToken(cmd.guiToken);
   }
   
   adjustManualPosition(cmd: AdjustManualPosition): Promise<void> {

      cmd.guiToken = getShortUUID();
      
      const commandPromise = this._shellClient.processCommand(cmd);

      if (environment.disableOrderConfirmations) {

         return commandPromise;

      }

      return this._orderConfirmationService.registerToken(cmd.guiToken);
      
   }
   
   replaceOrder(cmd: ReplaceOrder): Promise<void> {
      
      const commandPromise = this._shellClient.processCommand(cmd);

      if (environment.disableOrderConfirmations) {
         return commandPromise;
      }

      return this._orderConfirmationService.registerToken(cmd.orderId);
   }
   
   rollManualPosition(positionId: string) {
      const cmd = new RollNextMonth(EtsConstants.emptyGuid, positionId);
      return this._shellClient.processCommand(cmd);
   }
   
   async getAvailablePortfolios(): Promise<PortfolioDto[]> {
      const qry = new GetPortfolios();

      const dtos = await this._shellClient.processQuery<PortfolioDto[]>(qry);
   
      const portfolios = dtos.sort((a, b) => a.portfolioName.localeCompare(b.portfolioName));
      
      return portfolios;
   }

   convertLimitToMarket(cmd: ConvertLimitToMarket): Promise<void> {
      return this._shellClient.processCommand(cmd);
   }

   //#endregion
}
