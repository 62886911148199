import {Pipe, PipeTransform} from '@angular/core';
import {isValidNumber} from "../utils";

@Pipe({
    name: 'etsIsValidNumber'
})

export class IsValidNumberPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (typeof value !== 'number') {
            return '';
        }
        const falseOnZero = args[0];
        const isValid = isValidNumber(value, falseOnZero);
        return isValid ? value : '';
    }
}
