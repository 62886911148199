import { Component, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ColumnState, GridOptions } from 'ag-grid-community';
import { getQuoteBoardGridModel } from './quote-board-grid-model';
import { DetectSetterChanges, getPanelStateKey, isInstrumentExpired, isNullOrUndefined } from 'projects/shared-components/utils';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { Subject } from 'rxjs';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { TimestampsService } from '../timestamps.service';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { PanelBaseComponent } from '../panels/panel-base.component';
import {UserSettingsService} from "../user-settings.service";

interface PanelState {
   columnsState: ColumnState[];
   subscribedTickers: string[];
}

@Component({
   selector: 'ets-quote-board',
   templateUrl: './quote-board.component.html',
   styleUrls: ['./quote-board.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteBoardComponent extends PanelBaseComponent {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _tiService: TradingInstrumentsService,
      private readonly _toastr: ToastrService,
      private readonly _lastQuoteCacheService: LastQuoteCacheService,
      private readonly _timestampsService: TimestampsService,
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
      this.contextPopupParent = document.querySelector('body');
   }
   
   private _grid: GridOptions;
   private _unsubscriber: Subject<any>;
   private _stateChanged = new EventEmitter();
   private _isSymbolPickerVisible = false;

   contextPopupParent: HTMLBodyElement | null;
   
   quoteBoardGridModel: GridOptions;

   get isSymbolPickerVisible(): boolean {
      return this._isSymbolPickerVisible;
   }

   @DetectSetterChanges()
   set isSymbolPickerVisible(value: boolean) {
      if (this._isSymbolPickerVisible === value) { return; }
      this._isSymbolPickerVisible = value;
   }

   etsOnInit(): void {
      this.quoteBoardGridModel = getQuoteBoardGridModel.bind(this)(this._displayNameService, this._timestampsService);
      this.subscribeToMessages();
   }

   etsAfterViewInit() {  }
   
   onGridReady(args: GridOptions): void {
      this._grid = args;
      this._grid.api.sizeColumnsToFit();


      const key = getPanelStateKey(this);
      const item = this._userSettingsService.getValue(key);
      if (!item) {
         this.saveState();
      } else {
         this.restoreState();
      }

      this._stateChanged.pipe(
         throttleTime(250),
         takeUntil(this._unsubscriber)
      ).subscribe(() => this.saveState());
   }
   
   async etsOnDestroy(): Promise<any> {
      const unsubscriber = this._unsubscriber;
      
      if (unsubscriber) {
         unsubscriber.next();
         unsubscriber.complete();
      }
      
      this._unsubscriber = null;
   }

   showSymbolPicker(): void {
      this.isSymbolPickerVisible = true;
   }
   
   async removeQuoteLine(quoteLine: QuoteDto): Promise<any> {
      if (!quoteLine || !quoteLine.ticker) {
         this._toastr.warning('Please, select quote to unsubscribe');
         return;
      }

      this._grid.api.applyTransaction({ remove: [quoteLine] });

      this.onStateChanged();
   }
   
   async addQuoteLine(ticker: string, isRestore: boolean = false): Promise<any> {

      const ti = this._tiService.getInstrumentByTicker(ticker);

      if (isInstrumentExpired(ti)) {
         
         const instr = ti ? ti.displayName : this._displayNameService.getDisplayNameForTicker(ticker);
         
         this._toastr.warning(`Instrument '${instr}' not added: expired`);
         
         return;
      }

      this._grid.api.showLoadingOverlay();

      try {
         this.subscribeToMarketData(ticker);
         const rowData = new QuoteDto();
         rowData.ticker = ticker;
         this._grid.api.applyTransaction({ add: [rowData] });
         if (!isRestore) {
            this.onStateChanged();
         }
         const lastQuote = this._lastQuoteCacheService.getLastQuote(ticker);
         if (lastQuote) {
            setTimeout(() => this.onQuoteDtoMessage([lastQuote]), 0);
         }
      } finally {
         this._grid.api.hideOverlay();
      }
   }
   
   onStateChanged(): void {
      this._stateChanged.emit();
   }
   
   async onInstrumentSelected(data: TradingInstrument): Promise<any> {
      this.isSymbolPickerVisible = false;
      await this.addQuoteLine(data.ticker);
   }

   onSymbolPickerClosed() {
      this.isSymbolPickerVisible = false;
   }

   onGridDoubleClick() { this.isSymbolPickerVisible = true; }
   
   private subscribeToMessages(): void {
      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onQuoteDtoMessage(msg.payload));
   }
   
   private subscribeToMarketData(ticker: string): QuoteDto {
      return this._lastQuoteCacheService.subscribeTicker(ticker);
   }
   
   private onQuoteDtoMessage(quotes: QuoteDto[]): void {
      const prepared = quotes
         .filter(x => {
            
            if (x.ticker.startsWith('@')) {
               return false;
            }
            
            const isOnGrid = !isNullOrUndefined(this._grid.api.getRowNode(x.ticker));
            
            if (!isOnGrid) {
               return false;
            }

            if (x.isLevel2) {
               return false;
            }

            return true;
         })
         .map(quote => {
            quote.bid = quote.bidPx[0] || 0;
            quote.ask = quote.askPx[0] || 0;
            quote.bidSize = quote.bidQx[0] || 0;
            quote.askSize = quote.askQx[0] || 0;
            // % chg
            const open = quote.open || quote.lastPx;
            quote.percentChange = ((quote.lastPx - open) / (open || 1)) * 100 || 0;

            return quote;
         });

      if (prepared.length) {
         this._grid.api.applyTransactionAsync({ update: prepared });
      }
   }
   
   protected getState(): PanelState {
      if (!this._grid) {
         return null;
      }

      const gridState = this._grid.columnApi.getColumnState();
   
      const subscribedTickers = [];
      this._grid.api.forEachNode(node => {
         const dto = node.data as QuoteDto;
         if (dto) {
            if (dto.ticker) {
               subscribedTickers.push(dto.ticker);
            }
         }
      });

      const state: PanelState = {
         columnsState: gridState,
         subscribedTickers
      };

      return state;
   }

   protected setState(state: PanelState): void {
      if (!this._grid) {
         return;
      }


      const isOK = this._grid.columnApi.setColumnState(state.columnsState);
   
      if (isOK) {
         
         state.subscribedTickers.forEach(async x => {
            await this.addQuoteLine(x, true);
         });
         
         this.onStateChanged();

      } else {

         this._toastr.error('"Quote Board" panel was restored with errors');

      }
   }
}
