import {ChangeDetectorRef} from "@angular/core";
import {PackageComparisonDto} from "../../../package-comparison/model/PackageComparisonModel";
import {DetectMethodChanges, DetectSetterChanges, isValidNumber, isVoid} from "../../../utils";
import {ExpirationSolutionPrice} from "../../model/ExpirationSolutionPrice";

export interface ExpirationToPrice {
    expiration: number;
    sweetPrice: number;
}

export interface IPackageToTrack {
    packageId: string;
    expirationSeqNo: number;
    sweetPrice: number;
}

export class PackageToTrackGroup {
    constructor(private readonly _initialPackage: PackageToTrack, private readonly _changeDetector: ChangeDetectorRef) {
        this.items = [_initialPackage];
    }

    get packageId() : string {
        return this.items[0].packageId;
    }

    get isSelected() : boolean {
        return this.items[0].isSelected;
    }

    @DetectSetterChanges()
    set isSelected(v: boolean) {
        this.items.forEach(x => {
            x.isSelected = v;
            if (!v) {
                x.selectedExpiration = null;
                x.sweetPrice = null;
            }
        });
    }

    items : PackageToTrack[] = [];

    @DetectMethodChanges()
    addExpiration(packageToTrack?: IPackageToTrack) {
        if (!isVoid(packageToTrack)) {

            const existingExp = this.items.find(x => x.selectedExpiration?.seqNo === packageToTrack.expirationSeqNo);

            if (existingExp) {
                existingExp.sweetPrice = packageToTrack.sweetPrice;
                return;
            }
        }

        const clone = this.items[0].clone();

        clone.isClone = true;

        if (!isVoid(packageToTrack)) {
            clone.setExpiration(packageToTrack.expirationSeqNo);
            clone.sweetPrice = packageToTrack.sweetPrice;
        }

        this.items.push(clone);
    }

    @DetectMethodChanges()
    removeExpiration(pkg: PackageToTrack) {
        const number = this.items.indexOf(pkg);
        if (number !== -1) {
            this.items.splice(number, 1);
        }
    }

    setExpirations(find: IPackageToTrack[]) {
        const initial = this.items[0].package;
        this.items.length = 0;
        const items  = find.map(x => {

            const packageToTrack = new PackageToTrack(initial, this._changeDetector);
            packageToTrack.setExpiration(x.expirationSeqNo);
            packageToTrack.sweetPrice = x.sweetPrice;

            return packageToTrack;

        });

        if (items.length > 1) {
            // items.sort((a, b) => a.selectedExpiration.seqNo - b.selectedExpiration.seqNo);

            items.filter((v,ix) => ix > 0)
                .forEach((v) => v.isClone = true);
        }

        this.items = items;
    }
}

export class PackageToTrack {
    constructor(
        readonly _package : PackageComparisonDto,
        private readonly _changeDetector: ChangeDetectorRef
    ) {
        this.selectedExpiration = this.expirations.find(x => x.seqNo === this._package.expirationSeqNo);
    }

    private _isSelected = false;

    get isSelected(): boolean {
        return this._isSelected;
    }

    get package() :PackageComparisonDto {
        return this._package;
    }

    expirations = [
        {name: 'Exp. 1', seqNo: 0},
        {name: 'Exp. 2', seqNo: 1},
        {name: 'Exp. 3', seqNo: 2},
        {name: 'Exp. 4', seqNo: 3},
        {name: 'Exp. 5', seqNo: 4},
    ];

    isClone = false;

    selectedExpiration : any;

    sweetPrice : number;

    set isSelected(value: boolean) {
        this._isSelected = value;
        if (!this._isSelected) {
            this.sweetPrice = null;
        }
        setTimeout(() => this._changeDetector.detectChanges());
    }

    get name() : string {
        return this._package?.name;
    }

    get packageId() : string {
        return this._package?.packageId;
    }

    clone() : PackageToTrack {
        const ptt = new PackageToTrack(this._package, this._changeDetector);
        ptt.isClone = true;
        return ptt;
    }

    setExpiration(seqNo: number) {
        const find = this.expirations.find(x => x.seqNo === seqNo);
        this.selectedExpiration = find;
    }
}