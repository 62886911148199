import { Injectable } from '@angular/core';
import { Logger } from './logger.interface';
import { ConsoleLogger } from './console-logger.class';
import { TelemetryService } from '../telemetry/telemetry.service';

@Injectable({providedIn: 'root'})
export class LoggerService {
  constructor(private readonly _telemetryService: TelemetryService) { }

  createLogger(name: string): Logger {
    return new ConsoleLogger(name, this._telemetryService);
  } 

}
