import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { StrategyOrdersAndTradesComponent } from './strategy-orders-and-trades.component';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultLoadingOverlayTemplate,
   getMasterSymbolColumn
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { TimestampsService } from '../timestamps.service';
import { isCashSettledOptionTicker } from '../utils';

export function getStrategySnapshotsGridModel(
   this: StrategyOrdersAndTradesComponent,
   tickerDisplayNameService: TradingInstrumentDisplayNameService,
   timestampsService: TimestampsService
): GridOptions {


   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         getMasterSymbolColumn('tradingInstrumentCode', this.messageBus, this.timestampsService, this.unsubscriber, () => this.ordersGridApi),

         {
            headerName: 'Exp. Style',
            field: 'expirationStyle',
            hide: true
         },

         {
            headerName: 'Type',
            field: 'orderType',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderType[params.value];
            },
            filter: true
         },
         {
            headerName: 'Status',
            field: 'status',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderStatus[params.value];
            },
            filter: true
         },
         {
            headerName: 'Qty',
            field: 'qty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return data.qty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Leaves Qty',
            field: 'leavesQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return (data.qty - data.filledQty) * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Limit Px',
            field: 'limitPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Stop Px',
            field: 'stopPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Last Px',
            field: 'lastPx',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Side',
            field: 'side',
            valueFormatter: (params: ValueFormatterParams) => {
               return MarketSide[params.value];
            },
            filter: true
         },
         {
            headerName: 'Last Qty',
            field: 'lastQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return data.lastQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Filled',
            field: 'filledQty',
            valueGetter(params: ValueGetterParams) {
               const data: OrderStateSnapshotDto = params.data;
               return data.filledQty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Avg. Px',
            field: 'avgFillPx',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Tags',
            field: 'tags',
            filter: 'agTextColumnFilter'
         },
         { headerName: 'Account', field: 'accountCode', filter: true },
         { headerName: 'Comment', field: 'comment', tooltip: (args) => args.value, filter: 'agTextColumnFilter' },
         {
            headerName: 'Timestamp',
            field: 'lastModifiedDate',
            valueFormatter: (params: ValueFormatterParams) => {
               const order: OrderStateSnapshotDto = params.data;
               const frmtTime = timestampsService.getFormattedDateTimeForStrategy(order.strategyId, order.lastModifiedDate);
               return order.lastModifiedDate
                  ? frmtTime
                  : '';
            },
         },
         { headerName: 'Order Id', field: 'orderId', filter: 'agTextColumnFilter' },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      onGridReady: args => this.onSnapshotsGridReady(args),

      getRowNodeId: (rowData: OrderStateSnapshotDto) => rowData.orderStateSnapshotId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Load More ...',
               disabled: !this.contextStrategyId,
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadMoreSnapshots(50)
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreSnapshots(100)
                  },
                  {
                     name: '500',
                     action: () => this.loadMoreSnapshots(500)
                  },
                  {
                     name: '1000',
                     action: () => this.loadMoreSnapshots(1000)
                  }
               ]
            },
            {
               name: 'Show Comment Popup',
               action: () => {
                  this.selectedOrderOrSnapshot = params.node.data;
                  this.isCommentPopupVisible = true;
               },
               disabled: !params.node
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}
