import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged, isVoid } from 'projects/shared-components/utils';

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday';

@Component({
   selector: 'ets-checklist-day-of-expiration-condition',
   template: `
<div class="container">
   <dx-select-box
      [items]="this.items"
      [(value)]="this.value"
      [showClearButton]="true"
      [disabled]="this.disabled"
      (onValueChanged)="this.onValueChanged($event)"
      >
   </dx-select-box>   
</div>
   `,
   styles: [``],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistDayOfExpirationConditionComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   @Input() disabled: boolean;

   items: DayOfWeek[] = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday'
   ];

   private _value: DayOfWeek;
   public get value(): DayOfWeek {
      return this._value;
   }
   @Input()
   @DetectSetterChanges()
   public set value(value: DayOfWeek) {
      this._value = value;
   }

   @Output()
   valueChange = new EventEmitter<string>();

   @Output() valueChanged = new EventEmitter<DxValueChanged<string>>();

   @DetectMethodChanges()
   ngOnInit(): void { }

   onValueChanged(event: DxValueChanged<string>) {
      if (event.value === event.previousValue) {
         return;
      }

      if (isVoid(event.event)) {
         return;
      }
      
      this.valueChange.emit(this.value);
      this.valueChanged.emit({value: this.value, event: 'ets'});
   }
}
