import { isNullOrUndefined } from 'util';

export enum PositionSizingStrategy {
  None = 0,
  FixedRatio = 1,
  FixedFractional = 2
}

export class StrategyPositionSizing {

  constructor() {
    this.sizingStrategyItems = [
      {
        text: 'Fixed Ratio',
        value: PositionSizingStrategy.FixedRatio
      },
      {
        text: 'Fixed Fractional',
        value: PositionSizingStrategy.FixedFractional
      }
    ];

    this.switchToItems = [
      {
        text: 'Fixed Ratio',
        value: PositionSizingStrategy.FixedRatio
      },
      {
        text: 'Fixed Fractional',
        value: PositionSizingStrategy.FixedFractional
      }
    ];
  }
  
  sizingStrategyItems = [];
  switchToItems = [];

  isPositionSizingEnabled = false;

  sizingStrategy?: PositionSizingStrategy;
  ratioUnitSize?: number;
  fractionalUnitSize?: number;
  deltaSize?: number;
  unitPerAmount?: number;
  unitPerPercent?: number;
  riskPerUnit?: number;
  baseAccountSize?: number;
  allowDecreaseMultiplier = false;
  switchTo?: PositionSizingStrategy;
  switchWhenProfit?: number;
  switchWhenMoreEfficient = false;
  stayWhenSwitched = false;

  altRatioUnitSize?: number;
  altFractionalUnitSize?: number;
  altDeltaSize?: number;
  altAllowDecreaseMultiplier = false;  
  altUnitPerAmount?: number;
  altUnitPerPercent?: number;
  altRiskPerUnit?: number;

  useAccountSizeInCalculations = false;

  uiSwitchWhen: any;
}
