// tslint:disable: no-string-literal

import { DialogConfig } from './dialog-config';
import * as moment from 'moment';
import { ICommonDispositionParameters } from './common-disposition-parameters';
import { ToastrService } from 'ngx-toastr';
import { TradingObjectivesModel } from './parameters-controls/trading-objectives-model';
import { StrategyScheduleModel } from './parameters-controls/strategy-schedule-model';
import { plainToClass } from 'class-transformer';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { EtsConstants } from '../ets-constants.const';
import { isHedgingAlgo, isTruthy } from '../utils';
import { TIME_ZONES_LIST } from '../timezones/time-zones.const';
import { EditStrategyDialogComponent } from './edit-strategy-dialog.component';
import { EventEmitter } from '@angular/core';


export abstract class ParametersControlBase<T> {
   
   protected constructor(
      protected readonly _TradingInstrumentsService: TradingInstrumentsService,
      protected readonly _Toastr: ToastrService
   ) {
   }

   private _isSession = false;

   changes$ = new EventEmitter<void>();
   parametersSet$ = new EventEmitter<void>();

   get isSession() {
      return this._isSession;
   }

   set isSession(v: boolean) {
      
      this._isSession = v;
      
      if (!v) {
         if (this.tradingObjectives) {
            this.tradingObjectives.isSessionNpo = false;
            this.tradingObjectives.isSessionDollarTS = false;
            this.tradingObjectives.isSessionPercentTS = false;
            this.tradingObjectives.isSessionStopLoss = false;
            this.tradingObjectives.isSessionSlMargin = false;
         }
      }

      this.hasChanges();
   }

   holder: EditStrategyDialogComponent;
   view: T;
   
   private _displayName = '';
   get displayName(): string {
      return this._displayName;
   }
   set displayName(value: string) {
      this._displayName = value;
      this.hasChanges();
   }

   private _tags = '';
   get tags(): string {
      return this._tags;
   }
   set tags(value: string) {
      this._tags = value;
      this.hasChanges();
   }

   private _tradingObjectives = new TradingObjectivesModel();
   get tradingObjectives(): TradingObjectivesModel {
      return this._tradingObjectives;
   }
   set tradingObjectives(value: TradingObjectivesModel) {
      this._tradingObjectives = value;
      this.hasChanges();
   }

   private _scheduleParameters = new StrategyScheduleModel();
   get scheduleParameters(): StrategyScheduleModel {
      return this._scheduleParameters;
   }
   set scheduleParameters(value: StrategyScheduleModel) {
      this._scheduleParameters = value;
      this.hasChanges();
   }

   private _maxPositionViolationsAllowed = 1;
   get maxPositionViolationsAllowed(): number { return this._maxPositionViolationsAllowed; }
   set maxPositionViolationsAllowed(value: number) {
      this._maxPositionViolationsAllowed = value;
      this.hasChanges();
   }

   //
   
   private _maxPositionViolationMargin = 0;
   get maxPositionViolationMargin(): number { return this._maxPositionViolationMargin; }
   set maxPositionViolationMargin(value: number) {
      this._maxPositionViolationMargin = value;
      this.hasChanges();
   }

   //

   private _timestampsZoneItems = TIME_ZONES_LIST;
   get timestampsZoneItems(): string[] { return this._timestampsZoneItems; }
   set timestampsZoneItems(value: string[]) {
      this._timestampsZoneItems = value;
      this.hasChanges();
   }

   //

   private _timestampsZone: string;
   get timstampsZone(): string { return this._timestampsZone; }
   set timestampsZone(value: string) {
      this._timestampsZone = value;
      this.hasChanges();
   }

   //

   private _doNotAutoRoll = false;
   get doNotAutoRoll(): boolean { return this._doNotAutoRoll; }
   set doNotAutoRoll(value: boolean) {
      this._doNotAutoRoll = value;
      this.hasChanges();
   }

   //

   private _dontUseCancelConfirmations = false;
   get dontUseCancelConfirmations(): boolean { return this._dontUseCancelConfirmations; }
   set dontUseCancelConfirmations(value: boolean) {
      this._dontUseCancelConfirmations = value;
      this.hasChanges();
   }

   //

   private _isTradeThroughLimit = false; 
   get isTradeThroughLimit(): boolean { return this._isTradeThroughLimit; }
   set isTradeThroughLimit(value: boolean) {
      this._isTradeThroughLimit = value;
      this.hasChanges();
   }

   //

   abstract get algoId(): string;

   //
   
   setStrategy(config: DialogConfig): void {

      this.displayName = config.strategy.displayName;
      this.tags = config.strategy.tags;

      const tradingObjectives: object = JSON.parse(config.strategy.tradingObjectives || '{}');
      this.tradingObjectives = plainToClass(TradingObjectivesModel, tradingObjectives);

      const scheduleParameters: object = JSON.parse(config.strategy.scheduleParameters || '{}');
      this.scheduleParameters = plainToClass(StrategyScheduleModel, scheduleParameters);

      this.isSession = config.strategy.isSession;

      this.maxPositionViolationMargin = parseInt(config.strategy.parameters['maxpositionviolationmargin']) || 0;
      this.maxPositionViolationsAllowed = parseInt(config.strategy.parameters['maxpositionviolationsallowed']) || 1;

      this.timestampsZone = config.strategy.parameters['timestampszone'];

      this.isTradeThroughLimit = (config.strategy.parameters['istradethroughlimit'] || '').toLowerCase() === 'true';

      this.doNotAutoRoll = (config.strategy.parameters['donotautoroll'] || '').toLowerCase() === 'true';

      this.dontUseCancelConfirmations = (config.strategy.parameters['dontusecancelconfirmations'] || '').toLowerCase() === 'true';

      this.setStrategyParameters(config.strategy);

      this.hasChanges();
   }
   
   
   isFlagshipFamily(): boolean {
      let result = false;
      switch (this.algoId) {
         case EtsConstants.algorithms.flagships.followAlgoId:
         case EtsConstants.algorithms.flagships.outrightAlgoId:
         case EtsConstants.algorithms.flagships.s40AlgoId:
         case EtsConstants.algorithms.flagships.beforeLastAlgoId:
         case EtsConstants.algorithms.flagships.oppositeBeforeLastAlgoId:
         case EtsConstants.algorithms.flagships.majority:
         case EtsConstants.algorithms.flagships.minority:
         case EtsConstants.algorithms.flagships.zigZag:
         case EtsConstants.algorithms.flagships.fmr:
            result = true;
            break;
         default:
            break;
      }
      return result;
   }

   
   get isDisposition(): boolean {
      return this.algoId === EtsConstants.algorithms.fusionAlgoId;
   }

   
   abstract setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean): void;

   
   validate(validationContext: any): string[] {
      const errors = [];

      if (!this.displayName) {
         errors.push('Display Name Is Missing');
      }

      this._ValidateSchedule(errors);

      this._ValidateTradingObjectives(errors);

      const specificErrors = this.validateSpecificParameters(validationContext);

      if (specificErrors.length > 0) {
         errors.push(...specificErrors);
      }

      return errors;
   }

   
   getStrategy(): StrategyDto {
      const dto = new StrategyDto();
      dto.algoId = this.algoId;
      dto.displayName = this.displayName;
      dto.parameters = this._GetParameters();

      dto.parameters['maxpositionviolationsallowed'] = (this.maxPositionViolationsAllowed || 1).toString();
      dto.parameters['maxpositionviolationmargin'] = (this.maxPositionViolationMargin || 0).toString();

      if (!dto.parameters.hasOwnProperty('istradethroughproxy')) {
         dto.parameters['istradethroughproxy'] = '' + false;
      }

      if (!dto.parameters.hasOwnProperty('istradethroughstop')) {
         dto.parameters['istradethroughstop'] = '' + false;
      }

      if (!dto.parameters.hasOwnProperty('istradethroughmit')) {
         dto.parameters['istradethroughmit'] = '' + false;
      }

      if (!dto.parameters.hasOwnProperty('isprofittargetasmarket')) {
         dto.parameters['isprofittargetasmarket'] = '' + false;
      }

      dto.parameters['timestampszone'] = this.timestampsZone || '';
      dto.parameters['istradethroughlimit'] = isTruthy(this.isTradeThroughLimit) ? this.isTradeThroughLimit + '' : null;
      dto.parameters['donotautoroll'] = isTruthy(this.doNotAutoRoll) ? this.doNotAutoRoll + '' : null;
      dto.parameters['dontusecancelconfirmations'] = isTruthy(this.dontUseCancelConfirmations) ? this.dontUseCancelConfirmations + '' : null;

      dto.tags = this.tags;
      dto.tradingObjectives = this.tradingObjectives.toJSON();
      dto.scheduleParameters = JSON.stringify(this.scheduleParameters);
      dto.isSession = this.isSession;


      return dto;
   }

   
   abstract validateSpecificParameters(validationContext: any): string[];

   
   isValidTime(time: string): boolean {
      const exp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/;
      const res = exp.test(time);
      return res;
   }

   
   timeMask(time: string): string {
      if (!time || time === '  :  :') {
         return '';
      }
      return '00:00:00';
   }

   
   hasAdvancedSchedule(): boolean {
      const p = this.scheduleParameters;
      return !!p &&
         (
            !!p.beginDate ||
            !!p.endDate ||
            !!p.mon ||
            !!p.tue ||
            !!p.wed ||
            !!p.thu ||
            !!p.fri
            || p.isRecurring
         );
   }

   
   onNpoChange(event): void {

      if (typeof event === 'number') {
         this.tradingObjectives.accumulatedNpo = event;
      } else if (typeof event === 'boolean') {
         if (event) {
            this.tradingObjectives.accumulatedNpo = 0;
         } else {
            this.tradingObjectives.accumulatedNpo = null;
         }
      }

      this.hasChanges();
   }

   
   abstract onStrategyTerminalChanged(): void;

   
   protected abstract _SetParameters(dto: StrategyDto): void;

   
   protected abstract _GetParameters(): StrategyParameters;

   
   protected _ValidateTradingObjectives(errors): void {
      if (this.tradingObjectives.accumulatedNpo !== null && this.tradingObjectives.accumulatedNpo !== undefined
         && this.tradingObjectives.accumulatedNpo <= 0) {
         errors.push('Incorrect Accunulated NPO value');
      }
      if (this.tradingObjectives.accumulatedDollarTS === 0) {
         errors.push('Incorrect Accunulated $-TS value');
      }
      if (this.tradingObjectives.accumulatedPercentTS === 0) {
         errors.push('Incorrect Accunulated %-TS value');
      }
      if (this.tradingObjectives.accumulatedStopLoss === 0) {
         errors.push('Incorrect Accunulated SL value');
      }
      if (this.tradingObjectives.isAccumulatedSlMargin && this.tradingObjectives.accumulatedSlMargin <= 0) {
         errors.push('Incorrect Accunulated SL margin value');
      }


      if (this.tradingObjectives.tradeNpo !== null && this.tradingObjectives.tradeNpo !== undefined
         && this.tradingObjectives.tradeNpo <= 0) {
         errors.push('Incorrect Trade NPO value');
      }
      if (this.tradingObjectives.tradeDollarTS === 0) {
         errors.push('Incorrect Trade $-TS value');
      }
      if (this.tradingObjectives.tradePercentTS === 0) {
         errors.push('Incorrect Trade %-TS value');
      }
      if (this.tradingObjectives.tradeStopLoss === 0) {
         errors.push('Incorrect Trade SL value');
      }
      if (this.tradingObjectives.isTradeSlMargin && this.tradingObjectives.tradeSlMargin <= 0) {
         errors.push('Incorrect Trade SL margin value');
      }


      if (this.tradingObjectives.sessionNpo !== null && this.tradingObjectives.sessionNpo !== undefined
         && this.tradingObjectives.sessionNpo <= 0) {
         errors.push('Incorrect Session NPO value');
      }
      if (this.tradingObjectives.sessionDollarTS === 0) {
         errors.push('Incorrect Session $-TS value');
      }
      if (this.tradingObjectives.sessionPercentTS === 0) {
         errors.push('Incorrect Session %-TS value');
      }
      if (this.tradingObjectives.sessionStopLoss === 0) {
         errors.push('Incorrect Session SL value');
      }
      if (this.tradingObjectives.isSessionSlMargin && this.tradingObjectives.sessionSlMargin <= 0) {
         errors.push('Incorrect Session SL margin value');
      }

      const noStopLoss =  !isTruthy(this.tradingObjectives.accumulatedDollarTS) 
                           && !isTruthy(this.tradingObjectives.accumulatedPercentTS) 
                           && !isTruthy(this.tradingObjectives.accumulatedStopLoss);
      
      if (noStopLoss) {
      
         if (!isHedgingAlgo(this.algoId)) {
            errors.push('Cannot create strategy without any stop-loss set');
         }

      }

      if (isTruthy(this.tradingObjectives.accumulatedNpo) && isTruthy(this.tradingObjectives.tradeNpo)) {
         if (this.tradingObjectives.accumulatedNpo < this.tradingObjectives.tradeNpo) {
            errors.push('"Accumulated NPO" should be greater than "Trade NPO"');
         }
      }

      if (isTruthy(this.tradingObjectives.accumulatedDollarTS) && isTruthy(this.tradingObjectives.tradeDollarTS)) {
         if (this.tradingObjectives.accumulatedDollarTS < this.tradingObjectives.tradeDollarTS) {
            errors.push('"Accumulated $-TS" should be greater than "Trade $-TS"');
         }
      }

      if (isTruthy(this.tradingObjectives.accumulatedStopLoss) && isTruthy(this.tradingObjectives.tradeStopLoss)) {
         if (this.tradingObjectives.accumulatedStopLoss < this.tradingObjectives.tradeStopLoss) {
            errors.push('"Accumulated SL" should be greater than "Trade SL"');
         }
      }

   }

   
   protected _ValidateSchedule(errors): void {
      const times = [
         this.scheduleParameters.startTime ? 1 : 0,
         this.scheduleParameters.stopTime ? 1 : 0,
         this.scheduleParameters.exitTime ? 1 : 0
      ].reduce((acc, curr) => acc + curr, 0);

      const sp = this.scheduleParameters;
      const startTime = moment.utc(sp.startTime, 'HH:mm:ss');
      const stopTime = moment.utc(sp.stopTime, 'HH:mm:ss');
      const exitTime = moment.utc(sp.exitTime, 'HH:mm:ss');

      if (this.isSession) {
         if (times !== 3) {
            errors.push('Session Strategy Must Have Start/Stop/Exit Time');
         } else {
            if (sp.startTime && sp.stopTime && sp.exitTime) {
               const isExitLaterThanStop = exitTime.isAfter(stopTime);
               const isStopLaterThanStart = stopTime.isAfter(startTime);
               if (!isExitLaterThanStop || !isStopLaterThanStart) {
                  errors.push('Start/Stop/Exit times must be one later than other');
               }
            }
         }
      } else {
         if (times > 1) {
            if (!sp.endDate) {
               if (!(sp.mon || sp.tue || sp.wed || sp.thu || sp.fri)) {
                  errors.push('Positional strategy can have only StartTime (if "End Date" is not provided)');
               }
            } else {
               if (!sp.exitTime) {
                  errors.push('Positional Strategy with End Date Must Have Exit Time');
               }
            }

            if (sp.mon || sp.tue || sp.wed || sp.thu || sp.fri) {
               if (!sp.exitTime || !sp.startTime) {
               
                  errors.push('Positional Strategy with Daily Schedule Must Have Start/Exit Time');
   
               } else {
                  
                  if (!exitTime.isAfter(startTime)) {
                     errors.push('Exit time must be later than Start time');
                  }
   
                  if (!!sp.stopTime) {
                     if (!stopTime.isAfter(startTime)) {
                        errors.push('Exit time must be later than Stop time');
                     }
                  }
               }
            }

         } else if (times === 1) {
            
            if (!sp.startTime) {
               errors.push('Position strategy can have only StartTime');
            }

            if (!!sp.endDate) {
               errors.push('Position strategy must have  Stop and Exit time, if "End Date" is provided)');
            }
         }
      }

      if (times > 0) {
         if (!sp.country || !sp.timeZone) {
            errors.push('Must Specify a Time Zone!');
         }
      }

      if (!!sp.beginDate) {
         const d = moment.utc(sp.beginDate);
         if (d.isValid()) {
            const dayOfWeek = d.weekday();
            if (dayOfWeek > 5) {
               errors.push('"Begin Date" must be a working day of week');
            }
         } else {
            errors.push('"Begin Date" is invalid');
         }
      }

      if (!!sp.endDate) {
         const d = moment.utc(sp.endDate);
         if (d.isValid()) {
            if (d.weekday() > 5) {
               errors.push('"End Date" must be a working day of week');
            }
         } else {
            errors.push('"End Date" is invalid');
         }
      }

      const hasSelectedDays = sp.mon || sp.tue || sp.wed || sp.thu || sp.fri;
      if (sp.isRecurring) {
         if (!hasSelectedDays) {
            errors.push('For Recurring Schedule You Must Select Days');
         }
      } else {
         if (hasSelectedDays) {
            errors.push('Not Recurring Strategy Should Not have Selected Days');
         }
      }
   }

   protected hasChanges() {
      this.changes$.emit();
   }

   private setStrategyParameters(strategy: StrategyDto) {
      this._SetParameters(strategy);
      this.hasChanges();
   }
}

