import {ChangeDetectorRef} from '@angular/core';
import {OptionType} from '../../options-common/options.model';
import {BucketType} from '../../portfolios/portfolios.model';
import {TerminalDto} from '../../shell-communication/dtos/terminal-dto.class';
import {
   ComboDto,
   ComboGroupDto,
   OptionExpirationDescriptor,
   PortfolioDto
} from '../../shell-communication/shell-dto-protocol';
import {ArrayWrapper, DetectMethodChanges, DetectSetterChanges, DxValueChanged, isNullOrUndefined} from '../../utils';
import {MultiLegOrder} from './multi-leg-order.class';
import {ActionType, LegType} from '../multi-trade-pad.model';


export class OrderLeg {

   constructor(private readonly _root: MultiLegOrder) {
      this.legType = LegType.Option;
   }


   //

   groupLabel: string;

   //

   attachTo = false;

   //
   private _legType: LegType;

   get legType(): LegType { return this._legType; }

   @DetectSetterChanges()
   set legType(val: LegType) {
      this._legType = val;
      if (!this._qty) {
         this._qty = val === LegType.Asset ? 100 : 1;
      }
   }

   //
   ticker?: string;

   //
   displayName?: string;

   //
   private _action: ActionType;

   get action(): ActionType { return this._action; }

   @DetectSetterChanges()
   set action(val: ActionType) {
      this._action = val;
   }

   //
   private _qty: number;

   get qty(): number { return this._qty; }

   @DetectSetterChanges()
   set qty(val: number) {
      this._qty = val;
   }

   //
   private _expiration: OptionExpirationDescriptor;

   get expiration(): OptionExpirationDescriptor { return this._expiration; }

   @DetectSetterChanges()
   set expiration(val: OptionExpirationDescriptor) {
      this._expiration = val;
   }

   //
   private _strike: number | string;

   get strike(): number | string { return this._strike; }

   @DetectSetterChanges()
   set strike(val: number | string) {
      this._strike = val;
   }

   //

   private _atmOffset: number;
   get atmOffset(): number {
      return this._atmOffset;
   }
    set atmOffset(v: number) {
      this._atmOffset = v;
   }

   //
   private _optionType: OptionType;

   get optionType(): OptionType { return this._optionType; }

   @DetectSetterChanges()
   set optionType(val: OptionType) {
      this._optionType = val;
   }

   //
   private _bid: number;

   get bid(): number { return this._bid; }

   @DetectSetterChanges()
   set bid(val: number) {
      this._bid = val;
   }

   //
   private _ask: number;

   get ask(): number { return this._ask; }

   @DetectSetterChanges()
   set ask(val: number) {
      this._ask = val;
   }

   //
   private _execPrice: number;

   get execPrice(): number { return this._execPrice; }

   @DetectSetterChanges()
   set execPrice(val: number) {
      this._execPrice = val;
   }

   //
   private _execDate: string;

   get execDate(): string { return this._execDate; }

   @DetectSetterChanges()
   set execDate(val: string) {
      this._execDate = val;
   }

   //
   private _execMilliseconds: number;

   get execMilliseconds(): number { return this._execMilliseconds; }

   @DetectSetterChanges()
   set execMilliseconds(val: number) {
      this._execMilliseconds = val;
   }

   //
   private _isCustomExpiration = false;

   get isCustomExpiration(): boolean { return this._isCustomExpiration; }

   @DetectSetterChanges()
   set isCustomExpiration(val: boolean) {
      this._isCustomExpiration = val;
   }

   //
   private _terminal: TerminalDto;

   get terminal(): TerminalDto { return this._terminal; }

   @DetectSetterChanges()
   set terminal(val: TerminalDto) {
      this._terminal = val;
   }

   //
   private _portfolio: PortfolioDto;

   get portfolio(): PortfolioDto { return this._portfolio; }

   @DetectSetterChanges()
   set portfolio(val: PortfolioDto) {
      this._portfolio = val;
   }

   //
   private _combo: ComboDto;

   get combo(): ComboDto { return this._combo; }

   @DetectSetterChanges()
   set combo(val: ComboDto) {
      this._combo = val;
   }

   //
   private _comboGroup: ComboGroupDto;

   get comboGroup(): ComboGroupDto { return this._comboGroup; }

   @DetectSetterChanges()
   set comboGroup(val: ComboGroupDto) {
      this._comboGroup = val;
   }

   //
   get _changeDetector(): ChangeDetectorRef {
      return this._root.root.changeDetector;
   }

   //
   private _terminalList: ArrayWrapper<TerminalDto> = new ArrayWrapper<TerminalDto>('displayName', 'terminalId');

   get terminalList(): TerminalDto[] {
      return this._terminalList.data;
   }

   @DetectSetterChanges()
   set terminalList(v: TerminalDto[]) {
      this._terminalList.setData(v);
   }

   //
   private readonly _portfoliosList: ArrayWrapper<PortfolioDto> = new ArrayWrapper<PortfolioDto>('portfolioName', 'portfolioId');

   get portfoliosList(): PortfolioDto[] {
      return this._portfoliosList.data;
   }

   @DetectSetterChanges()
   set portfoliosList(v: PortfolioDto[]) {
      this._portfoliosList.setData(v);
   }

   //
   private readonly _comboList: ArrayWrapper<ComboDto> = new ArrayWrapper<ComboDto>('comboName', 'comboId');

   get comboList(): ComboDto[] {
      return this._comboList.data;
   }

   @DetectSetterChanges()
   set comboList(val: ComboDto[]) {
      this._comboList.setData(val);
   }

   //
   private readonly _comboGroupsList: ArrayWrapper<ComboGroupDto> = new ArrayWrapper<ComboGroupDto>('comboGroupName', 'comboGroupId');

   get comboGroupList(): ComboGroupDto[] {
      return this._comboGroupsList.data;
   }

   @DetectSetterChanges()
   set comboGroupList(val: ComboGroupDto[]) {
      this._comboGroupsList.setData(val);
   }

   //
   setTerminal(terminal: TerminalDto): void {
      this.terminalList = this._root.root.availableTerminals.slice();
      this.terminal = terminal;
   }

   //
   @DetectMethodChanges()
   removeBucket(bucketType: BucketType, bucketId: string) {

      if (bucketType === 'Portfolio') {

         this._portfoliosList.remove(bucketId);

      } else if (bucketType === 'Combo') {

         this._comboList.remove(bucketId);

      } else if (bucketType === 'ComboGroup') {

         this._comboGroupsList.remove(bucketId);

      }
   }

   //
   @DetectMethodChanges()
   addBucket(bucketType: string, bucket: any): void {
      if (bucketType === 'Portfolio') {

         if (this.terminal) {
            if (this.terminal.terminalId === bucket.terminalId) {
               this._portfoliosList.insert(bucket);
            }
         }

      } else if (bucketType === 'Combo') {

         if (this.portfolio) {
            if (this.portfolio.portfolioId === bucket.portfolioId) {
               this._comboList.insert(bucket);
            }
         }

      } else if (bucketType === 'ComboGroup') {
         if (this.combo) {
            if (this.combo.comboId === bucket.comboId) {
               this._comboGroupsList.insert(bucket);
            }
         }
      }
   }

   //
   @DetectMethodChanges()
   onTerminalChanged(args: DxValueChanged<TerminalDto>) {
      if (this.portfolio) {
         if (isNullOrUndefined(args.value) ||
            (this.portfolio.terminalId !== args.value.terminalId)) {

            this.portfolio = null;
         }
      }

      this.setPortfolioListByTerminal(args.value);
   }

   //
   @DetectMethodChanges()
   onPortfolioChanged(args: DxValueChanged<PortfolioDto>) {

      if (this.combo) {
         if (isNullOrUndefined(args.value) ||
            (this.combo.portfolioId !== args.value.portfolioId)) {

            this.combo = null;
         }
      }

      this.setComboListByPortfolio(args.value);
   }

   //
   @DetectMethodChanges()
   onComboChanged(args: DxValueChanged<ComboDto>) {

      if (this.comboGroup) {
         if (isNullOrUndefined(args.value) ||
            (this.comboGroup.comboId !== args.value.comboId)) {

            this.comboGroup = null;
         }
      }

      this.setComboGroupListByCombo(args.value);
   }

   //
   getLegColor(): string {

      if (this.action === ActionType.Sell) {
         return 'color: red';
      }

      if (this.action === ActionType.Buy) {
         return 'color: cornflowerblue';
      }

      return undefined;
   }

   //
   getLegType(): string {
      return LegType[this.legType];
   }

   //
   getLegAction(): string {
      return ActionType[this.action];
   }

   //
   @DetectMethodChanges()
   reverseSide(): void {
      if (this.action === ActionType.Buy) {

         this.action = ActionType.Sell;

      } else if (this.action === ActionType.Sell) {

         this.action = ActionType.Buy;

      }
   }

   //
   @DetectMethodChanges()
   reverseOptionType(): void {
      if (this.optionType === OptionType.Call) {

         this.optionType = OptionType.Put;

      } else if (this.optionType === OptionType.Put) {

         this.optionType = OptionType.Call;

      }
   }

   //
   copyFrom(that: OrderLeg) {
      
      this._legType = that.legType;

      this.ticker = that.ticker;

      this.displayName = that.displayName;

      this._action = that.action;

      this._qty = that.qty;

      this._expiration = that.expiration;

      this._strike = that.strike;

      this._atmOffset = that.atmOffset;

      this._optionType = that.optionType;

      this._bid = that.bid;

      this._ask = that.ask;

      this._execPrice = that.execPrice;

      this._execDate = that.execDate;

      this._execMilliseconds = that.execMilliseconds;

      this._isCustomExpiration = that.isCustomExpiration;

      this._terminal = that.terminal;

      this._portfolio = that.portfolio;

      this._combo = that.combo;

      this._comboGroup = that.comboGroup;

      this._terminalList.setData(that.terminalList);

      this._portfoliosList.setData(that.portfoliosList);

      this._comboList.setData(that.comboList);

      this._comboGroupsList.setData(that.comboGroupList);

      this.groupLabel = that.groupLabel;
   }

   //
   changeSide() {
      this._action = this._action * -1;
   }

   clone(): OrderLeg {
      const clone = new OrderLeg(this._root);
      clone.copyFrom(this);
      return clone;
   }

   //
   private setPortfolioListByTerminal(terminal: TerminalDto) {

      const terminalId = terminal ? terminal.terminalId : null;

      const filteredPortfolios = this._root.root.availablePortfolios.filter(x => x.terminalId === terminalId);

      this.portfoliosList = filteredPortfolios;
   }

   //
   private setComboListByPortfolio(portfolio: PortfolioDto) {

      const portfolioId = portfolio ? portfolio.portfolioId : null;

      const filteredCombos = this._root.root.availableCombos.filter(combo => combo.portfolioId === portfolioId);

      this.comboList = filteredCombos;
   }

   //
   private setComboGroupListByCombo(combo: ComboDto) {

      const comboId = combo ? combo.comboId : null;

      const filteredComboGroups = this._root.root.availableComboGroups.filter(comboGroup => comboGroup.comboId === comboId);

      this.comboGroupList = filteredComboGroups;
   }
}
