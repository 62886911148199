import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'projects/shared-components/environments/environment';
import { BeforePositionDto } from '../model/BeforePositionDto';
import { SolutionPositionDto } from '../model/SolutionPositionDto';
import { parseOptionTicker } from 'projects/shared-components/options-common/options.model';
import { DetectMethodChanges, DetectSetterChanges, delay, getBucketRoleClass, isVoid, makeFullExpirationDate, makeGuiFriendlyExpirationDate } from 'projects/shared-components/utils';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { LastUsedTemplate } from '../services/cashflow-strategy-templates.service';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import {ApgOriginalClientPositionsService} from "../services/apg-original-client-positions.service";
import {ApgPortfolio} from "../model/ApgPortfolio";
import {AdjustmentPricingGridComponent} from "../adjustment-pricing-grid.component";

@Component({
   selector: 'ets-apg-portfolio-refresh-dialog',
   templateUrl: './apg-portfolio-refresh-dialog.component.html',
   styleUrls: [
      './apg-portfolio-refresh-dialog.component.scss',
      '../adjustment-grid-common-style.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApgPortfolioRefreshDialogComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _apgOriginalClientPositionsService: ApgOriginalClientPositionsService
   ) { }

   private _resolver: (value: 'Former' | 'Current') => void;

   private _rejecter: (err?) => void;

   private _portfolio: ApgPortfolio;


   originalFormerState: BeforePositionDto[][];

   originalCurrentState: BeforePositionDto[][];

   formerStrategy: CashFlowStrategy;

   currentStrategy: CashFlowStrategy;

   formerUnderlying: string;

   currentUnderlying: string;

   formerTemplate: LastUsedTemplate;
   
   currentTemplate: LastUsedTemplate;

   @ViewChild(DxPopupComponent) popup: DxPopupComponent;

   private _isLoading : boolean;
   get isLoading() : boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v : boolean) {
      this._isLoading = v;
   }
   
   get width(): number {
      return this.hasCurrentState ? 640 : 370;
   };
   
   get height(): number {
      const max = Math.max(this.formerState?.length, this.currentState?.length);

      const base =  max == 2 ? 485 : 370;

      if (isVoid(this.formerState)) {
         return base;
      }

      const lineHeight = 25;

      const formerLegsCount = this.formerState.map(x => x.length).reduce((p, c) => p + c, 0);
      const formerLegsExcess = formerLegsCount - 8;
      
      if (this.currentState.length === 0) {
         return base + formerLegsExcess * lineHeight;
      }
      
      const currentLegsCount = this.currentState.map(x => x.length).reduce( (p, c) => p + c, 0);
      const currentLegsExcess = currentLegsCount - 8;

      const biggerExcess = Math.max(currentLegsExcess, formerLegsExcess);
      
      const extraHeight = biggerExcess * lineHeight;

      return base + extraHeight;
   };

   title = 'Portfolio Refresh Dialog';

   formerState: SolutionPositionDto[][] = [];
   
   currentState: SolutionPositionDto[][] = [];

   get resizeEnabled(): boolean {
      return !environment.production;
   }

   hasCurrentState: boolean;

   get footerCssClass(): string[] {
      const css = ['footer'];
      
      if (!this.hasCurrentState) {
         css.push('single');
      }

      return css;
   }

   ngOnInit(): void {

   }

   async show(
       portfolio: ApgPortfolio,
       comp: AdjustmentPricingGridComponent
   ): Promise<'Former' | 'Current'> {

      this._portfolio = portfolio;

      const formerState = this._apgOriginalClientPositionsService.getTheirOriginalPositions(
          this._portfolio
      );

      this.originalFormerState = formerState;

      const formerStateTransformed = this.transformData(formerState);

      this.formerState = formerStateTransformed;

      if (formerState.length > 0) {

         this.formerStrategy = this.formerState.length == 2
             ? 'Calls & Puts'
             : this.formerState[0][0].strategy;

         this.formerUnderlying = this.formerState.length > 0
             ? this.formerState[0][0].underlying
             : 'N/A';
      } else {
         this.formerStrategy = 'UNKNOWN' as any;
         this.formerUnderlying = 'N/A';
      }

      const beforeLut: LastUsedTemplate = this._apgOriginalClientPositionsService
          .getTheirSnapshotTemplate(this._portfolio);

      this.formerTemplate = beforeLut;

      this.isLoading = true;

      this.popup.visible = true;

      // hack to make loading dialog show immediately
      await delay(250);

      const hasTheirChanges = comp.hasTheirChanges();
      const hasOwnChanges = comp.hasOwnChanges();

      this.hasCurrentState = hasTheirChanges;

      if (hasTheirChanges) {

         let currentState = this._apgOriginalClientPositionsService.getTheirCurrentPositions(
             this._portfolio
         );

         this.originalCurrentState = currentState;

         const currentStateTransformed = this.transformData(currentState);

         this.currentState = currentStateTransformed;

         if (this.currentState.length > 0) {
            this.currentStrategy = this.currentState.length == 2
                ? 'Calls & Puts'
                : this.currentState[0][0].strategy;

            this.currentUnderlying = this.currentState.length > 0
                ? this.currentState[0][0].underlying
                : 'N/A';
         } else {
            this.currentStrategy = 'UNKNOWN' as any;
            this.currentUnderlying = 'N/A';
         }

         let currentTemplate = this._apgOriginalClientPositionsService.getTheirCurrentTemplate(
             this._portfolio
         );

         if (isVoid(currentTemplate)) {
            currentTemplate = this.formerTemplate;
         }

         this.currentTemplate = currentTemplate;
      }

      this.isLoading = false;

      this._changeDetector.detectChanges();

      return new Promise( (resolve, reject) => {
         this._rejecter = reject;
         this._resolver = resolve;
      });
   }

   //
   getRowRoleClass(item: SolutionPositionDto) {
      return getBucketRoleClass(item);
   }

   @DetectMethodChanges()
   onDialogClosed() {
      if (this._rejecter) {
         this._rejecter();
      }

      this.originalCurrentState = [];
      this.originalFormerState = [];
      this.formerTemplate = null;
      this.currentTemplate = null;
      this.currentState = [];
      this.formerState = [];
      this.currentUnderlying = null;
      this.formerUnderlying = null;
      this.formerStrategy = null;
      this.currentStrategy = null;

      this.popup.visible = false;
   }

   @DetectMethodChanges()
   onStateSelected(state: BeforePositionDto[][]) {
      try {

         if (state === this.originalCurrentState) {

            this._apgOriginalClientPositionsService
                .updateSnapshotStateWithCurrent(this._portfolio);

            this._resolver('Current');

         } else if (state === this.originalFormerState) {

            this._apgOriginalClientPositionsService
                .resetToSnapshotState(this._portfolio);

            this._resolver('Former');

         } else {
            this._rejecter();
         }   

      } finally {
         this.popup.visible = false;
      }

   }

   //
   private transformData(positions: BeforePositionDto[][]): SolutionPositionDto[][] {
      
      const solutionPositions = positions.map(poses => {
         
         const solutionPoses = poses.map(p => {

            const optionTicker = parseOptionTicker(p.ticker);

            let solutionPosition: SolutionPositionDto;

            if (!isVoid(optionTicker)) {
               solutionPosition = {
                  underlying: optionTicker.underlying,
                  type: optionTicker.type,
                  expiration: makeGuiFriendlyExpirationDate(optionTicker.expiration),
                  expirationFull: makeFullExpirationDate(optionTicker.expiration),
                  qty: p.qty,
                  role: p.role,
                  strike: optionTicker.strike,
                  strategy: p.strategy,
                  ticker: p.ticker,
                  side: p.qty > 0 ? 'Long' : p.qty < 0 ? 'Short' : undefined,
               };
            } else {
               solutionPosition = {
                  type: '---',
                  side: '---',
                  expiration: '---',
                  strike: '---'
               } as any;
            } 

            return solutionPosition;
            
         });

         return solutionPoses;

      });

      return solutionPositions;
   }

}
