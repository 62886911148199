<div [class.ets-hidden]="!this.isActive" style="margin: 10px;">
  <dx-tree-list [showBorders]="true"
                [dataSource]="triggers"
                [allowColumnResizing]="true"
                >
    <dxi-column dataField="displayName" caption="Trigger Name"></dxi-column>
    <dxi-column dataField="triggerType" caption="Trigger Type"></dxi-column>
    <dxi-column dataField="state" caption="State" cellTemplate="stateTemplate"></dxi-column>
    <div *dxTemplate="let trigger of 'stateTemplate'">
      <div>
        <span [ngStyle]="{'color': trigger.data.state ? 'green' : 'red', 'font-weight': 'bold'}">
          {{trigger.data.state ? 'TRUE' : 'FALSE'}}
        </span>
      </div>
    </div>
  </dx-tree-list>
</div>
