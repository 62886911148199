<dx-scroll-view [direction]="'both'" class="scroll">
  <div [ngClass]="{'ets-hidden': this.priceboxColCollapsed}" class="zones-grid">
    <table [width]="'100%'">
      
      <colgroup>
        <col span="1"/>
        <col span="1"/>
        <col *ngFor="let _ of this.columnHeaders" span="1"/>
      </colgroup>
      
      <thead>
      <tr *ngIf="this.rows.length > 0" class="highlighted-row">
        
        <td class="header-cell">
          <div>Strike</div>
          <div>{{ this.adjustment }}</div>
        </td>
        
        <td *ngFor="let col of this.columnHeaders" class="header-cell exp">
          <div>
            {{ col?.date }}
          </div>
          <div>
            {{ col?.dayOfWeek }} {{ col?.dte }}
          </div>
          <div *ngIf="this.showImpliedVolatility">
            <ets-volatility-header
              [root]="this"
              [expiration]="col.date"
              [underlying]="this.root.settingsSection.underlying">
            </ets-volatility-header>
          </div>
        </td>
      
      </tr>
      </thead>
      
      <tbody>
      
      
      <ng-container *ngFor="let row of this.sortedRows; odd as isOdd">
        
        <tr [ngClass]="{'odd-row': isOdd}" class="combo-line">
          
          <!--          <td [ngClass]="this.getCellCss(row?.strike)" class="header-cell strike">{{row?.strike}}</td>-->
          <td [ngClass]="this.getRowRoleCss(row)" class="header-cell strike">{{ row?.strike }}</td>
          
          <td (click)="this.showPopup(column.adjustments)" *ngFor="let column of row?.columns"
              class="expiration-pricing"
          >
            <ets-zones-grid-cell
              [column]="column"
              [comp]="this"
            >
            </ets-zones-grid-cell>
          </td>
        </tr>
      </ng-container>
      
      </tbody>
    </table>
  </div>
</dx-scroll-view>