<div #root class="root-container">
  
  <div [ngClass]="{'right': this.orientation === 'right'}" class="body">
    
    <div [ngClass]="{'collapsed': this.positionsColCollapsed}" class="column positions-column">
      
      <div (click)="this.toggleCollapsed('positions')"
           [ngClass]="{'ets-hidden': !this.positionsColCollapsed, 'right': this.orientation === 'right'}"
           class="section-caption collapsed-panel"
      >
        Positions
      </div>
      
      <div *ngIf="this.showPortfoliosSection" [id]="this.portfolioSectionUniqueId" class="portfolios-section">
        <div (click)="this.toggleCollapsed('positions')" class="section-caption">
          Portfolios
        </div>
        <div class="portfolios">
          <div class="selector">
            <div>
              <dx-select-box
                (onValueChanged)="this.onPortfolioSelected($event)" [(value)]="this.selectedPortfolio"
                [grouped]="true" [items]="this.portfolios" displayExpr="name"
                fieldTemplate="field"
              >
                <div *dxTemplate="let data of 'field'">
                  <div>
                    <dx-text-box [readOnly]="true"
                                 [value]="data && data.userName + '  |  ' + data.name"
                                 placeholder="Select portfolio...">
                    </dx-text-box>
                  </div>
                </div>
              </dx-select-box>
            </div>
          </div>
          <div class="btn-new">
            <dx-button (onClick)="this.showPortfolioPopup()" [disabled]="!this.canCreatePortfolio" icon="add">
            </dx-button>
          </div>
          <div class="btn-edit">
            <dx-button (onClick)="this.editPortfolio()" [disabled]="!this.canEditPortfolio" icon="edit">
            </dx-button>
          </div>
          <div [ngClass]="this.getRefreshPortfolioCss() | etsNgClassAsync:this" class="btn-refresh">
            <dx-button (onClick)="this.refreshPortfolio(this.selectedPortfolio)" [disabled]="!this.canRefreshPortfolio"
                       icon="refresh">
            </dx-button>
          </div>
          <div class="btn-delete">
            <dx-button (onClick)="this.deleteSelectedPortfolio()" [disabled]="!this.canDeletePortfolio"
                       icon="remove">
            </dx-button>
          </div>
        </div>
      </div>
      
      <div [id]="this.positionSectionUniqueId" [ngClass]="this.cssForPositionsSection" class="positions-section">
        <ets-adjustment-pricing-grid-positions-section [orientation]="this.orientation"
                                                       [viewModel]="this.positionsSection">
        </ets-adjustment-pricing-grid-positions-section>
      </div>
    
      <div class="hedges-totals">
        <div class="section-caption" (click)="this.toggleShowHedges()">Hedge Totals</div>
        <div>
          <ets-apg-hedges-total></ets-apg-hedges-total>
        </div>
      </div>
      
    </div>
    
<!--    Settings -->
    <div [ngClass]="{'collapsed': this.settingsColCollapsed}" class="column settings-column">
      <div (click)="this.toggleCollapsed('settings')"
           [class.different]="this.settingsSection.hasDifferencesWithTemplate()"
           [class.mismatch]="this.settingsSection.hasConfigurationMismatch()"
           [ngClass]="{'ets-hidden': !this.settingsColCollapsed, 'right': this.orientation === 'right'}"
           class="section-caption collapsed-panel"
      >
        Settings
      </div>
      
      <div [ngClass]="this.cssForSettingsSection" class="settings">
        <ets-adjustment-grid-settings-section
          [orientation]="this.orientation"
          [viewModel]="this.settingsSection"
        >
        </ets-adjustment-grid-settings-section>
      </div>
    </div>
    
<!--    Strategies -->
    <div [ngClass]="{'collapsed': this.strategiesColCollapsed}" class="column strategies-column">
      
      <div (click)="this.toggleCollapsed('strategies')"
           [ngClass]="{
                  'ets-hidden': !this.strategiesColCollapsed,
                  'right': this.orientation === 'right',
                  'atm-outdated': this.isAtmOutdated
               }"
           class="section-caption collapsed-panel"
      >
        Strategies
      </div>
      
      <div (click)="this.toggleCollapsed('strategies')" (dragend)="this.collapseAllBut('strategies')" [draggable]="true"
           [ngClass]="{'right': this.orientation === 'right'}" class="section-caption">
        Strategies
      </div>
      
      <dx-scroll-view [direction]="'both'" class="scroll">
        <div *ngFor="let strategy of this.strategiesSection.strategies"
             [ngClass]="{'ets-hidden': this.strategiesColCollapsed}" class="adj-strategy"
        >
          <table [width]="'100%'">
            
            <caption>"{{ strategy.cashFlowStrategy }}"</caption>
            
            <colgroup>
              <col [width]="this.strategiesSection.getStrategyColWidth(strategy, 5)" span="1"/>
              <col *ngFor="let _ of strategy.adjustmentTypes"
                   [width]="this.strategiesSection.getStrategyColWidth(strategy)"
                   span="1"
              />
            </colgroup>
            
            <tbody>
            
            <!-- Adjustment -->
            <tr class="highlighted-row">
              <td class="header-cell">Adjustment</td>
              <td *ngFor="let sol of strategy.adjustmentTypes" class="header-cell">{{ sol.adjustmentType }}</td>
            </tr>
            
            <!-- Current Strike -->
            <tr class="highlighted-row">
              <td class="header-cell">Current Strike</td>
              <td [colSpan]="strategy.adjustmentTypes.length" class="header-cell current-strike">
                ${{ this.strategy.initialStrike || 'N/A' }}
              </td>
            </tr>
            
            <!-- Destination Strike -->
            <tr class="highlighted-row">
              <td class="header-cell">Destination Strike</td>
              <td *ngFor="let sol of strategy.adjustmentTypes" [ngClass]="{'atm-outdated': sol.isAtmStrikeOutdated}"
                  class="header-cell">
                <span *ngIf="!sol.isEmpty">$</span>{{ sol.destinationStrike }}&nbsp;
                <span [ngSwitch]="sol.direction">
                  <span *ngSwitchCase="'Up'">
                     <i class="fas fa-arrow-up"></i>
                  </span>
                  <span *ngSwitchCase="'Down'">
                     <i class="fas fa-arrow-down"></i>
                  </span>
                  <span *ngSwitchCase="'Sideways'">
                     <i class="fas fa-arrow-right"></i>
                  </span>
                  <span *ngSwitchDefault>
                     <i class="fas fa-circle-sm"></i>
                  </span>
                 </span>
              </td>
            </tr>
            
            <!-- Adjustment Type -->
            <tr>
              <td class="header-cell">Adjustment Type</td>
              <td *ngFor="let adjType of strategy.adjustmentTypes">{{ adjType.description }}</td>
            </tr>
            
            <!-- Expirations -->
            <tr *ngFor="let adj of strategy?.adjustmentTypes[0]?.adjustments; let i=index"
                class="expiration-pricing">
              
              <td class="header-cell">
                {{ adj.dayOfWeek }}, {{ adj.expirationDatePrettyWithDaysToExpiration }}
              </td>
              
              <td (click)="this.showPopup(adjType.adjustments[i], 'PriceBox')"
                  *ngFor="let adjType of strategy.adjustmentTypes"
              >
                <ets-strategies-cell [adjType]="adjType" [index]="i" [root]="this">
                </ets-strategies-cell>
              </td>
            </tr>
            </tbody>
          
          </table>
        </div>
      </dx-scroll-view>
    
    </div>
    
<!--    Pricebox -->
    <div [ngClass]="{'collapsed': this.priceboxColCollapsed}" class="column pricebox-column">
      
      <div (click)="this.toggleCollapsed('pricebox')"
           [ngClass]="{'ets-hidden': !this.priceboxColCollapsed, 'right': this.orientation ===  'right'}"
           class="section-caption collapsed-panel"
      >
        Price Box {{ this.getPriceBoxSectionHeaderVolatility() }}
      </div>
      
      <div (dragend)="this.collapseAllBut('pricebox')" [draggable]="true"
           [ngClass]="{'right': this.orientation === 'right'}"
           [style.width.%]="this.getPriceboxHeaderWidth()"
           class="section-caption full"
      >
        <div (click)="this.toggleCollapsed('pricebox')" class="header">
          Price Box {{ this.getPriceBoxSectionHeaderVolatility() }}
        </div>
        <div (click)="this.showPriceBoxMenu()" class="menu">
          <i class="fas fa-bars"></i>
        </div>
      </div>
      
      <dx-scroll-view [direction]="'both'" class="scroll">
        <div [ngClass]="{'ets-hidden': this.priceboxColCollapsed}" class="pricebox">
          <table [width]="this.getPriceboxTableWidth()">
            <colgroup>
              <col span="1"/>
              <col span="1"/>
              <col *ngIf="this.priceboxSection.isMultiStrategy" span="1"/>
              <col *ngIf="this.priceboxSection.isMultiStrategy" span="1"/>
              <col *ngFor="let _ of this.priceboxSection.columnHeaders" span="1"/>
            </colgroup>
            
            <thead class="theader">
            <tr *ngIf="this.priceboxSection.rows.length > 0" class="highlighted-row">
              <td class="pin">
                <!-- checkbox -->
                &nbsp;
              </td>
              
              <td class="header-cell">
                {{ this.priceboxSection.firstStrategyHeader }}
              </td>
              <td *ngIf="this.priceboxSection.isMultiStrategy" class="header-cell">+</td>
              <td *ngIf="this.priceboxSection.isMultiStrategy" class="header-cell">
                {{ this.priceboxSection.secondStrategyHeader }}
              </td>
              
              <td (click)="this.priceboxSection.setSortColumn(col)"
                  *ngFor="let col of this.priceboxSection.columnHeaders"
                  [ngClass]="this.priceboxSection.getCssForExpirationCol(col)"
                  class="header-cell exp">
                <div>
                  {{ col?.date }}
                </div>
                <div>
                  {{ col?.dayOfWeek }} {{ col?.dte }}
                </div>
                <div *ngIf="this.priceboxSection.showImpliedVolatility">
                  <ets-volatility-header
                    [expiration]="col.date"
                    [root]="this.priceboxSection"
                    [underlying]="this.settingsSection.underlying">
                  </ets-volatility-header>
                </div>
              </td>
            
            </tr>
            </thead>
            
            <tbody>
            <ng-container *ngFor="let row of this.priceboxSection?.sortedRows; odd as isOdd">
              
              <tr [ngClass]="{'odd-row': isOdd}" class="combo-line">
                
                <td class="pin">
                  <dx-check-box
                    (onValueChanged)="this.priceboxSection.onRowPinnedChanged($event, row)"
                    [value]="this.priceboxSection.isRowPinned(row)">
                  </dx-check-box>
                </td>
                <td (dblclick)="this.loadDataZonesGrid(row)" class="header-cell">{{ row?.adjustmentType }}</td>
                <td (dblclick)="this.loadDataZonesGrid(row)" *ngIf="this.priceboxSection.isMultiStrategy"
                    class="header-cell">+
                </td>
                <td (dblclick)="this.loadDataZonesGrid(row)" *ngIf="this.priceboxSection.isMultiStrategy"
                    class="header-cell">
                  {{ row?.adjustmentType2 }}
                </td>
                
                <td
                  *ngFor="let column of row?.columns; last as isLast"
                  class="expiration-pricing"
                >
                  <div class="price-cell">
                      
                      <span (click)="this.showPopup(column.adjustments, 'PriceBox')" class="adj-price">
                        <ets-pricebox-cell-component
                          [column]="column"
                          [priceboxSection]="this.priceboxSection"
                          [root]="this"
                        >
                        </ets-pricebox-cell-component>
                      </span>
                    
                    <span (click)="this.loadDataZonesGrid(row)" *ngIf="isLast && this.priceboxSection.showZonesGridArrow"
                          class="zones-grid-arrow">
                        <i class="fas fa-arrow-alt-circle-right fa-xs"></i>
                      </span>
                  
                  </div>
                </td>
              </tr>
            </ng-container>
            
            </tbody>
          </table>
        </div>
      </dx-scroll-view>
    </div>
    
    <div *ngIf="this.isZonesGridAvailable"
         [ngClass]="{'collapsed': this.zonesGridColCollapsed}" class="column zones-grid-column">
      <div (click)="this.toggleCollapsed('zones-grid')"
           [ngClass]="{
                         'ets-hidden': !this.zonesGridColCollapsed,
                         'right': this.orientation ===  'right',
                         'different': this.areSettingsDifferent()
                      }"
           class="section-caption collapsed-panel"
      >
        Zones Grid {{ this.getZonesGridSectionHeaderVolatility() }}
      </div>
      
      <div
        [ngClass]="{
                      'different': this.areSettingsDifferent(),
                      'right': this.orientation === 'right'
                   }"
        class="section-caption full"
      >
        <div class="header-icons">
            <span (click)="this.zonesGridChangeLinkState()" class="link">
              <i *ngIf="!this.isZonesGridLinked" [style.cursor]="'pointer'" class="fas fa-unlink"></i>
              <i *ngIf="this.isZonesGridLinked" [style.cursor]="'pointer'" class="fas fa-link"></i>
            </span>
          <span (click)="this.zonesGridClear()" class="clear">
              <i [style.cursor]="'pointer'" class="far fa-window-close"></i>
            </span>
        </div>
        <div (click)="this.toggleCollapsed('zones-grid')" class="header">
          Zones Grid {{ this.getZonesGridSectionHeaderVolatility() }}
        </div>
        <div (click)="this.showZonesGriMenu()" class="menu">
          <i class="fas fa-bars"></i>
        </div>
      </div>
      <ets-zones-grid [collapsed]="this.zonesGridColCollapsed"
                      [root]="this">
      </ets-zones-grid>
    </div>
  
  </div>
  
  <dx-load-panel [position]="{my: 'center', at: 'center', of: root}" [visible]="this.isDataLoading">
  </dx-load-panel>
</div>

<ets-adjustment-solution-popup [root]="this">
</ets-adjustment-solution-popup>

<ets-apg-portfolio-refresh-dialog>
</ets-apg-portfolio-refresh-dialog>

<ets-apg-tracking-sync-dialog>
</ets-apg-tracking-sync-dialog>

<ets-pricebox-menu-component>
</ets-pricebox-menu-component>

<ets-zones-grid-menu-component>
</ets-zones-grid-menu-component>

<dx-popup (onHidden)="this.onPortfolioPopupClosed()" [closeOnOutsideClick]="false" [height]="245" [resizeEnabled]="true"
          [visible]="this.portfolioPopup.isVisible" [width]="315" title="New Portfolio">
  
  <div *dxTemplate="let data of 'content'" class="portfolio-popup">
    
    <ng-template #portfolioNew>
      <div class="inputs-new">
        <div class="input name">
          <p>Portfolio Name</p>
          <dx-text-box [(value)]="this.portfolioPopup.name"></dx-text-box>
        </div>
        <div class="input asset">
          <p>Asset</p>
          <ets-symbol-picker (instrumentSelected)="this.portfolioPopup.tradingInstrument = $event"
                             [selectedInstrument]="this.portfolioPopup.tradingInstrument"
                             [showLastPrice]="false">
          </ets-symbol-picker>
        </div>
        <div class="input strategy">
          <p>Strategy</p>
          <dx-select-box [(value)]="this.portfolioPopup.strategy" [items]="this.portfolioPopup.strategies">
          </dx-select-box>
        </div>
      </div>
    </ng-template>
    
    <ng-template #portfolioEdit>
      <div class="inputs-edit">
        <div class="input name">
          <p>Enter New Portfolio Name</p>
          <dx-text-box [(value)]="this.portfolioPopup.name"></dx-text-box>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="this.portfolioPopup.mode === 'new'">
      <ng-container *ngTemplateOutlet="portfolioNew"></ng-container>
    </ng-container>
    <ng-container *ngIf="this.portfolioPopup.mode === 'edit'">
      <ng-container *ngTemplateOutlet="portfolioEdit"></ng-container>
    </ng-container>
    
    <div class="buttons">
      <div class="btn">
        <dx-button (onClick)="this.saveNewApgPortfolio()" text="Save" width="100%">
        </dx-button>
      </div>
      <div class="btn">
        <dx-button (onClick)="this.onPortfolioPopupClosed()" text="Cancel" width="100%">
        </dx-button>
      </div>
    </div>
    
  </div>
</dx-popup>

<ets-generic-confirmation-dialog
  #portfolioDelete
  [text]="
   [
      'Are you sure you want to delete',
      '[' + this.selectedPortfolio?.name + ']'
   ]"
  confirmText="Yes"
  rejectText="No"
  title="Delete Portfolio?"
>
</ets-generic-confirmation-dialog>

<ets-after-state-detalization>
</ets-after-state-detalization>

<ets-zones-grid-settings>
</ets-zones-grid-settings>

<ets-override-atm-dialog>
</ets-override-atm-dialog>