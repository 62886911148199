import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChecklistPopupModel } from './ChecklistPopupModel';
import { isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-apg-checklist',
   templateUrl: './apg-checklist.component.html',
   styleUrls: ['./apg-checklist.component.scss', './reset-css.scss'],
   encapsulation: ViewEncapsulation.None
})
export class ApgChecklistComponent implements OnInit, AfterViewInit {
   constructor() { }

   @Input() viewModel: ChecklistPopupModel;

   @ViewChild('checklist', {static: false}) checkListContainer: ElementRef;

   ngOnInit(): void { }

   ngAfterViewInit(): void {
      if (this.viewModel) {
         this.viewModel.setChecklistContainer(() => this.checkListContainer);
      }
   }

}
