import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    DetectMethodChanges,
    DetectSetterChanges,
    DxValueChanged,
    isValidNumber,
    isVoid
} from 'projects/shared-components/utils';
import {ResourceButton} from "../../resource-button.interface";
import {ResourceEditorComponent} from "../../resource-editor.component";

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday';

@Component({
    selector: 'ets-day-of-week-condition-resource',
    template: `
      <div class="container">
        <p class="label">
          Day Of Week
        </p>
        <div class="input">
            <dx-drop-down-box
              [disabled]="this.disabled"
              [dropDownOptions]="this.dropDownOptions"
              [value]="this.dayOfWeek"
              [acceptCustomValue]="true"
              fieldTemplate="field"
              (onOpened)="this.onOpened()"
              (onClosed)="this.onClosed()"
            >
              <div *dxTemplate="let data of 'field'">
                <dx-text-box
                  [value]="this.dayOfWeek"
                  [readOnly]="true"
                >
                </dx-text-box>
              </div>
              <div *dxTemplate="let data of 'content'">
                <dx-select-box
                  [items]="this.items"
                  [(value)]="this.dayOfWeek"
                  [showClearButton]="true"
                >
                </dx-select-box>
                <ets-timezone-picker
                  [(timezone)]="this.timezone"
                  [flow]="'col'">
                </ets-timezone-picker>
              </div>
            </dx-drop-down-box>
        </div>
      </div>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayOfWeekConditionResourceComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    items: DayOfWeek[] = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday'
    ];

    dropDownOptions = {width: 300, left: -86, top: -3};

    @Input() comp: ResourceEditorComponent;

    get disabled(): boolean {
        return isVoid(this.resource);
    }

    private _resource: ResourceButton;
    get resource(): ResourceButton {
        return this._resource;
    }

    @Input()
    @DetectSetterChanges()
    set resource(value: ResourceButton) {
        this.reset();

        this._resource = value;

        if (isVoid(value)) {
            return;
        }

        if (isVoid(value.conditionDayOfWeek)) {
            return;
        }

        if (value.conditionDayOfWeek.indexOf('|') < 0) {
            return;
        }

        const parts = value.conditionDayOfWeek.split('|');

        if (parts.length < 2) {
            return;
        }

        this.dayOfWeek = parts[0] as any;
        this.timezone = parts[1];
    }


    private _dayOfWeek: DayOfWeek;
    get dayOfWeek(): DayOfWeek {
        return this._dayOfWeek;
    }

    @DetectSetterChanges()
    set dayOfWeek(value: DayOfWeek) {
        this._dayOfWeek = value;
    }

    timezone: string;

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this._changeDetector.detach();
    }

    private _oldValue: string;

    onOpened() {
        this._oldValue = this.getValue();
    }

    private getValue(): string {
        if (!this._dayOfWeek || !this.timezone) {
            return undefined;
        }
        const val = `${this._dayOfWeek}|${this.timezone}`;
        return val;
    }

    onClosed() {
        const value = this.getValue();
        this.resource.conditionDayOfWeek = value;
        if (this._oldValue === value) {
            return;
        }
        this.resource.hasChanges = true;
        this.comp.onChange({});
    }

    private reset() {
        this.dayOfWeek = undefined;
        this.timezone = undefined;
    }
}
