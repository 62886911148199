import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ets-time-cell',
  template: `
    <span>{{timeOfDay}}</span>
  `,
})
export class TimeRendererComponent implements ICellRendererAngularComp {
  private params: any;

  get timeOfDay(): string {
    return this.params.value;
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}