import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, isOptionExpired, isVoid} from "../../../utils";
import {HgOriginalClientPositionsService} from "../portfolio-selector/hg-original-client-positions.service";
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import {HedgePosition} from "../../data-model/hedge-position";
import * as Enumerable from "linq";
import {MessageBusService} from "../../../message-bus.service";
import {HgPortfolioSelectorComponent} from "../portfolio-selector/hg-portfolio-selector.component";

interface HedgingLeg {
    qty: number;
    side: 'Buy' | 'Sell' | 'Flat';
    type: 'Call' | 'Put';
    expiration: string;
    strike: number;
    position: HedgePosition;
}

interface HedgingGroup {
    label: string;
    color: string;
    groupId: string;
    legs: HedgingLeg[];
}

@Component({
    selector: 'ets-hg-hedge-positions-sync-dialog',
    templateUrl: 'hg-hedge-positions-sync-dialog.component.html',
    styleUrls: ['./hg-hedge-positions-sync-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgHedgePositionsSyncDialogComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _originalStateService: HgOriginalClientPositionsService,
        private readonly _messageBus: MessageBusService,
    ) {
    }

    private _formerCallPackages: HedgingGroup[] = [];
    private _formerPutPackages: HedgingGroup[] = [];
    private _currentCallPackages: HedgingGroup[] = [];
    private _currentPutPackages: HedgingGroup[] = [];
    private _portfolio: ApgPortfolio;

    visible = false;

    get hasCurrentState(): boolean {
        return !isVoid(this._currentCallPackages) ||
            !isVoid(this._currentPutPackages);
    }

    ngOnInit() {
    }

    get formerCallPackages() {
        return this._formerCallPackages;
    }

    get formerPutPackages() {
        return this._formerPutPackages;
    }

    get currentCallPackages() {
        return this._currentCallPackages;
    }

    get currentPutPackages() {
        return this._currentPutPackages;
    }

    @DetectMethodChanges({isAsync: true})
    async show(portfolio: ApgPortfolio, comp: HgPortfolioSelectorComponent) {

        const hasOwnChanges = await comp.hasOwnChanges();
        const hasTheirChanges = comp.hasTheirChanges();

        if (!hasOwnChanges && !hasTheirChanges) {
            return;
        }

        const originalPositions = this._originalStateService
            .getTheirOriginalPositions(portfolio);

        const currentPositions = hasTheirChanges ? this._originalStateService
            .getTheirCurrentPositions(portfolio) : [];

        if (isVoid(originalPositions) && isVoid(currentPositions)) {
            return;
        }

        this._portfolio = portfolio;

        const originalPosition = originalPositions.find(x => !isVoid(x.ticker));
        const currentPosition = currentPositions.find(x => !isVoid(x.ticker));

        let isOriginalExpired = false;
        let isCurrentExpired = false;

        if (!isVoid(originalPosition)) {
            isOriginalExpired = isOptionExpired(originalPosition.ticker);
        } else {
            isOriginalExpired = true;
        }

        if (hasTheirChanges) {
            if (!isVoid(currentPosition)) {
                isCurrentExpired = isOptionExpired(currentPosition.ticker);
            }
        }

        if (isOriginalExpired && isCurrentExpired) {
            return;
        }

        if (!isOriginalExpired) {
            this._formerCallPackages = this.makeHedgingGroups(originalPositions.filter(x => x.type === 'Call'));
            this._formerPutPackages = this.makeHedgingGroups(originalPositions.filter(x => x.type === 'Put'));
        }

        if (!isCurrentExpired) {
            if (!isVoid(currentPositions)) {
                this._currentCallPackages = this.makeHedgingGroups(currentPositions.filter(x => x.type === 'Call'));
                this._currentPutPackages = this.makeHedgingGroups(currentPositions.filter(x => x.type === 'Put'));
            }
        }

        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;

        this._formerPutPackages = null;
        this._formerPutPackages = null;
        this._currentCallPackages = null;
        this._currentPutPackages = null;
        this._portfolio = null;

    }

    getWidth() {
        let width = 380;
        if (this.hasCurrentState) {
            width *= 2;
        }
        return width;
    }

    applyState(header: 'former' | 'current') {
        this._messageBus.publish({
            topic: 'Hg.RefreshClientPositions',
            payload: {
                state: header,
                portfolio: this._portfolio
            }
        });
        this.onHidden();
    }

    private makeHedgingGroups(hedgePositions: HedgePosition[]): HedgingGroup[] {
        const groups = Enumerable.from(hedgePositions)
            .orderByDescending(x => x.strike)
            .groupBy(x => `${x.label}^${x.color}^${x.groupId}`)
            .select(grp => {

                const key = grp.key();
                const parts = key.split('^');

                const label = parts[0];
                const color = parts[1];
                const groupId = parts[2];

                const legs: HedgingLeg[] = grp.select(item => {
                    const leg: HedgingLeg = {
                        position: item,
                        side: item.qty === 0 ? 'Flat' : (item.qty > 0 ? 'Buy' : 'Sell'),
                        qty: item.qty,
                        type: item.type,
                        expiration: item.expiration,
                        strike: item.strike
                    };
                    return leg;
                }).toArray();

                const res: HedgingGroup = {
                    label,
                    color,
                    groupId,
                    legs
                };

                return res;
            }).toArray();

        return groups;
    }
}