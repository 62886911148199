<dx-popup (onHidden)="this.onHidden()"
          [closeOnOutsideClick]="true"
          [height]="this.height"
          [maxHeight]="this.maxHeight"
          [maxWidth]="this.maxWidth"
          [minHeight]="640"
          [minWidth]="800"
          [resizeEnabled]="false"
          [visible]="this.isVisible"
          [width]="this.width"
>
  <div #popup *dxTemplate="let data of 'content'" class="adj-solution-popup">

    <div [ngClass]="this.containerCss">

      <div *ngFor="let pricing of this.pricings; even as isEven" class="adj-solution">

        <div class="header">

          <table class="table">

            <colgroup>
              <col span="1" style="width: 25%;">
              <col span="1">
            </colgroup>

            <!-- Strategy Name -->
            <tr class="row">
              <td class="header-cell">Strategy Name</td>
              <td class="strategy-name">
                "{{pricing?.cashFlowStrategy}}"
              </td>
            </tr>

            <!-- Adjustment -->
            <tr class="row">
              <td class="header-cell">Adjustment</td>
              <td class="strategy-name">
                {{pricing?.adjustmentType}}
              </td>
            </tr>

            <!-- Current Strike -->
            <tr class="row">
              <td class="header-cell">Current Strike</td>
              <td class="curren-strike">
                ${{this.getCurrentStrike(pricing)}}
              </td>
            </tr>

            <!-- Destination Strike -->
            <tr class="row">
              <td class="header-cell">Destination Strike</td>
              <td class="destination-strike">
                ${{this.getDestinationStrike(pricing)}}
                <span [ngSwitch]="pricing?.direction">
                  <span *ngSwitchCase="'Up'">
                     <i class="fas fa-arrow-up"></i>
                  </span>
                  <span *ngSwitchCase="'Down'">
                     <i class="fas fa-arrow-down"></i>
                  </span>
                  <span *ngSwitchCase="'Sideways'">
                     <i class="fas fa-arrow-right"></i>
                  </span>
                  <span *ngSwitchDefault>
                     <i class="fas fa-circle-sm"></i>
                  </span>
                 </span>
              </td>
            </tr>

            <!-- Adjustment Type -->
            <tr class="row">
              <td class="header-cell">Adjustment Type</td>
              <td class="adjustment-type">
                {{pricing?.description}}
              </td>
            </tr>

            <!-- Expiration -->
            <tr class="row">
              <td class="header-cell">Expiration</td>
              <td class="expiration-date">
                <div class="expiration-slider">
                  <div *ngIf="!this.readOnly" class="nav-block prev">
                    <ng-container *ngIf="this.getPreviousExpiration(pricing); else empty">
                                    <span (click)="this.movePreviousExpiration(pricing)" class="date">
                                       <span [ngClass]="this.getPriceClass(this.getPreviousExpiration(pricing))"
                                             class="price">
                                          ({{this.getPreviousExpiration(pricing)?.price | currency:'USD':'symbol':'1.2-2'}}
                                         )
                                       </span>
                                      {{this.getPreviousExpiration(pricing)?.dayOfWeek + ','}} {{this.getPreviousExpiration(pricing)?.expirationPretty}}
                                    </span>
                    </ng-container>
                    <ng-template #empty>
                      &nbsp;
                    </ng-template>
                  </div>

                  <div class="nav-block current">
                    <i *ngIf="this.getPreviousExpiration(pricing)" class="fas fa-arrow-left"></i>
                    <span class="date current">
                                    {{pricing.dayOfWeek + ','}} {{pricing?.expirationPretty}}
                                 </span>
                    <i *ngIf="this.getNextExpiration(pricing)" class="fas fa-arrow-right"></i>
                  </div>

                  <div *ngIf="!this.readOnly" class="nav-block next">
                    <ng-container *ngIf="this.getNextExpiration(pricing); else empty">
                                    <span (click)="this.moveNextExpiration(pricing)" class="date">
                                       {{this.getNextExpiration(pricing)?.dayOfWeek + ','}} {{this.getNextExpiration(pricing)?.expirationPretty}}
                                      <span [ngClass]="this.getPriceClass(this.getNextExpiration(pricing))"
                                            class="price">
                                          ({{ this.getNextExpiration(pricing)?.price | currency:'USD':'symbol':'1.2-2' }}
                                        )
                                       </span>
                                    </span>
                    </ng-container>
                    <ng-template #empty>
                      &nbsp;
                    </ng-template>

                  </div>

                </div>
              </td>
            </tr>

            <!-- Template Name-->
            <tr class="row">
              <td class="header-cell">Template</td>
              <td class="adjustment-type">
                {{pricing?.templateName}}
              </td>
            </tr>

          </table>

        </div>

        <div class="trading-data" [class.full-size]="this.readOnly">

          <div class="before-after">

            <div class="section-header collapsible">
              <div class="block side">&nbsp;</div>
              <div class="block header">
                <div class="header-block text">POSITIONS</div>
                <div class="header-block switch">
                  <dx-switch (onValueChanged)="this.notifyChangeDetector()"
                             [(value)]="pricing.positionsVisible">
                  </dx-switch>
                </div>
              </div>
              <div class="block side">&nbsp;</div>
            </div>

              <div [class.collapsed]="!pricing.positionsVisible" class="state">
                <dx-scroll-view>

                <div [ngClass]="{'readonly': this.readOnly}" class="positions before">

                  <ets-adjustment-grid-positions-state
                    [adjustmentPricing]="pricing"
                    [positions]="pricing?.beforeState"
                    [readOnly]="this.readOnly"
                    [root]="this"
                    header="BEFORE"
                  >
                  </ets-adjustment-grid-positions-state>

                </div>
                <div [ngClass]="{'readonly': this.readOnly}" class="positions after">

                  <ets-adjustment-grid-positions-state
                    [adjustmentPricing]="pricing"
                    [forTotals]="pricing?.beforeState"
                    [isPriceToOpen]="this.qtyChangeEnabled"
                    [marketDelta]="pricing?.marketDelta"
                    [positions]="pricing?.afterState"
                    [qtyMultiplier]="isEven ? this.orderQty : this.orderQty2"
                    [readOnly]="this.readOnly"
                    [root]="this"
                    header="AFTER"
                  >
                  </ets-adjustment-grid-positions-state>

                </div>

                </dx-scroll-view>
              </div>

          </div>

          <div class="orders-section">

            <div class="section-header">
              <div class="block side">&nbsp;</div>
              <div class="block header">ORDERS</div>
              <div class="block side">&nbsp;</div>
            </div>

            <div class="orders">
              <dx-scroll-view>

                <div class="main">
                  <ets-adjustment-grid-orders
                    [adjustmentPricing]="pricing"
                    [legRatios]="this.legRatios"
                    [orders]="pricing?.mainLegs"
                    [qtyMultiplier]="isEven ? this.orderQty : this.orderQty2"
                    [readOnly]="this.readOnly"
                    [root]="this"
                    header="Main"
                  >
                  </ets-adjustment-grid-orders>
                </div>

                <div *ngIf="this.hasLinkedOrder(pricing)" class="linked">
                  <ets-adjustment-grid-orders
                    [adjustmentPricing]="pricing"
                    [legRatios]="this.legRatios"
                    [orders]="pricing?.linkedLegs"
                    [qtyMultiplier]="isEven ? this.orderQty : this.orderQty2"
                    [readOnly]="this.readOnly"
                    [root]="this"
                    header="Linked #1"
                  >
                  </ets-adjustment-grid-orders>
                </div>

                <div *ngIf="this.hasSecondLinkedOrder(pricing)" class="linked">
                  <ets-adjustment-grid-orders
                    [adjustmentPricing]="pricing"
                    [legRatios]="this.legRatios"
                    [orders]="pricing?.secondLinkedLegs"
                    [qtyMultiplier]="isEven ? this.orderQty : this.orderQty2"
                    [readOnly]="this.readOnly"
                    [root]="this"
                    header="Linked #2"
                  >
                  </ets-adjustment-grid-orders>
                </div>

                <div *ngIf="this.hasThirdLinkedOrder(pricing)" class="linked">
                  <ets-adjustment-grid-orders
                    [adjustmentPricing]="pricing"
                    [legRatios]="this.legRatios"
                    [orders]="pricing?.thirdLinkedLegs"
                    [qtyMultiplier]="isEven ? this.orderQty : this.orderQty2"
                    [readOnly]="this.readOnly"
                    [root]="this"
                    header="Linked #3"
                  >
                  </ets-adjustment-grid-orders>
                </div>

              </dx-scroll-view>
            </div>

          </div>

        </div>

        <div *ngIf="!this.readOnly" class="buttons">

          <div class="button destination">
            <p>Destination</p>
            <dx-select-box
              (onValueChanged)="this.onDestinationChanged($event)"
              [(value)]="this.selectedDestination"
              [items]="this.destinationList"
            >
            </dx-select-box>
          </div>

          <div class="button order-to-copy">
            <p>Order To Copy</p>
            <dx-select-box
              (valueChange)="isEven ? (this.selectedOrder = $event) : (this.selectedOrder2 = $event)"
              [items]="isEven ? this.ordersList : this.ordersList2"
              [value]="isEven ? this.selectedOrder : this.selectedOrder2"
            >
            </dx-select-box>
          </div>

          <div class="button qty">
            <p>Order Quantity</p>
            <dx-number-box
              (onValueChanged)="this.onOrderQtyChanged($event, isEven ? 0 : 1)"
              (valueChange)="isEven ? (this.orderQty = $event) : (this.orderQty2 = $event)"
              [disabled]="!this.qtyChangeEnabled"
              [min]="1"
              [showSpinButtons]="true"
              [useLargeSpinButtons]="true"
              [value]="isEven ? this.orderQty : this.orderQty2"
            >
            </dx-number-box>
          </div>

          <div class="button submit">
            <dx-button
              (onClick)="this.copyOrdersTo(pricing)"
              text="Copy"
              type="default"
              icon="copy"
              width="100%"
            >
            </dx-button>
          </div>

          <div class="button reorder">
            <dx-button width="100%" icon="refresh" type="default" text="Regroup" (onClick)="this.regroupOrders(pricing)"></dx-button>
          </div>
          
        </div>

      </div>

    </div>


    <dx-load-panel [position]="{my: 'center', at: 'center', of: popup}"
                   [visible]="this.isLoading">
    </dx-load-panel>

    <ets-apg-checklist [viewModel]="this.checklistPopup">
    </ets-apg-checklist>

  </div>
</dx-popup>


<ets-orders-regroup-dialog></ets-orders-regroup-dialog>