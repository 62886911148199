<fieldset [class.invisible]="!this.showBorder">
   <legend *ngIf="this.showCaption">Timezone</legend>

   <div class="container" [ngClass]="{ 'col':  this.flow === 'col', 'row': this.flow === 'row' }">
      <div class="section country">
         <dx-select-box placeholder="Select Country" 
                        displayExpr="displayName"
                        [items]="this.timezonesCountriesList"
                        [grouped]="true"
                        [(value)]="this.timezoneCountry" 
                        valueExpr="displayName"
                        [showClearButton]="true"
                        [disabled]="this.disabled">
         </dx-select-box>
      </div>   

      <div class="section timezone">
         <dx-select-box placeholder="Select Timezone"
                        [items]="this.timezonesByCountry"
                        [(value)]="this.timezone" 
                        displayExpr="displayName"
                        valueExpr="nodaTimeZoneId"
                        [showClearButton]="true"
                        [disabled]="this.disabled">
         </dx-select-box>
      </div>
   </div>

</fieldset>