import { ReplaceOrder } from '../shell-communication/operations/manual-trading/replace-order.class';
import { GetTradingDataResponseWeb } from '../shell-communication/dtos/get-trading-data-response-web.class';
import { CancelManualOrders } from '../shell-communication/operations/manual-trading/cancel-manual-orders.class';
import { GetManualPositions } from '../shell-communication/operations/manual-trading/get-manual-positions.class';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { AddManualPosition } from '../shell-communication/operations/manual-trading/add-manual-position.class';
import { CheckIfManualPositionExists } from '../shell-communication/operations/manual-trading/check-if-manual-position-exists.class';
import { GetAccounts } from '../shell-communication/operations/accounts/get-accounts.class';
import { AccountDto } from '../shell-communication/dtos/account-dto.class';
import { OverridePosition } from '../shell-communication/operations/strategies/override-position.class';
import { ArchivePosition } from '../shell-communication/operations/archived-positions/archive-position.class';
import { ReverseManualPosition } from '../shell-communication/operations/manual-trading/reverse-manual-position.class';
import { CloseManualPosition } from '../shell-communication/operations/manual-trading/close-manual-position.class';
import { AdjustManualPosition } from '../shell-communication/operations/manual-trading/adjust-manual-position.class';
import { ConvertLimitToMarket, GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb } from '../shell-communication/shell-operations-protocol';
import { SendManualOrder } from '../shell-communication/operations/manual-trading/send-manual-order.class';
import { GetAvailableBucketsReply } from '../shell-communication/shell-dto-protocol';

export abstract class ManualTradingBackendService {
   
  abstract getWorkingOrdersWeb(qry: GetWorkingOrdersWeb, shellId?: string): Promise<GetTradingDataResponseWeb>;
  abstract getOrderStateSnapshotsWeb(qry: GetOrderStateSnapshotsWeb, shellId?: string): Promise<GetTradingDataResponseWeb>;
  abstract getTradesWeb(qry: GetTradesWeb, shellId?: string): Promise<GetTradingDataResponseWeb>;
  abstract getManualPositions(query: GetManualPositions, shellId?: string): Promise<PositionDto[]>;
  
  abstract getAccounts(query: GetAccounts): Promise<AccountDto[]>;
  abstract getAvailableBuckets(): Promise<GetAvailableBucketsReply>;

  abstract sendManualOrder(cmd: SendManualOrder): Promise<void>;
  abstract requestOrderStatus(orders: { orderId: string, shellId: string }[]): Promise<void>;
  abstract cancelManualOrders(cmd: CancelManualOrders): Promise<void[]>;
  abstract addManualPosition(addPositionCommand: AddManualPosition): Promise<void>;
  abstract checkIfManualPositionExists(checkPositionQry: CheckIfManualPositionExists): Promise<PositionDto>;
  abstract overridePosition(cmd: OverridePosition): Promise<void>;
  abstract archivePosition(cmd: ArchivePosition): Promise<void>;
  abstract reverseManualPosition(cmd: ReverseManualPosition): Promise<void>;
  abstract closeManualPosition(cmd: CloseManualPosition): Promise<void>;
  abstract adjustManualPosition(cmd: AdjustManualPosition): Promise<void>;
  abstract replaceOrder(cmd: ReplaceOrder): Promise<void>;
  abstract rollManualPosition(positionId: string);
  abstract convertLimitToMarket(cmd: ConvertLimitToMarket): Promise<void>;
}
