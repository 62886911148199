import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AdjustmentStrategiesModule } from '../adjustment-strategies/adjustment-strategies.module';
import { AdjustmentStrategyDialogModule } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DevExtremeModule } from '../devextreme.module';
import { LastPriceModule } from '../last-price/last-price.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { OrderExtensionsModule } from '../order-extensions/order-extensions.module';
import { StrategyIssuesModule } from '../strategy-issues/strategy-issues.module';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';

import { AdjustmentControlPanelComponent } from './adjustment-control-panel.component';
import { InterestOverviewBlockComponent } from './overview-blocks/interest-block/interest-overview-block.component';
import { LongTermPutOverviewBlockComponent } from './overview-blocks/long-term-put-block/long-term-put-overview-block.component';
import { PortfolioOverviewBlockComponent } from './overview-blocks/portfolio-block/portfolio-overview-block.component';
import { ShortOptionsOverviewBlockComponent } from './overview-blocks/short-options-block/short-options-overview-block.component';
import { ShortTermSpreadOverviewBlockComponent } from './overview-blocks/short-term-spread-block/short-term-spread-overview-block.component';
import { StrategyOverviewComponent } from './overview-blocks/strategy-overview-block.component';

@NgModule({
   imports: [
      DevExtremeModule,
      CommonModule,
      AgGridModule.withComponents([]),
      AdjustmentStrategyDialogModule,
      OptionsCommonModule,
      DateTimePickerModule,
      TimezonePickerModule,
      EtsSymbolPickerModule,
      LastPriceModule,
      StrategyIssuesModule,
      OrderExtensionsModule,
      AdjustmentStrategiesModule
   ],
   exports: [AdjustmentControlPanelComponent],
   declarations: [
      AdjustmentControlPanelComponent, 
      PortfolioOverviewBlockComponent,
      InterestOverviewBlockComponent,
      LongTermPutOverviewBlockComponent,
      ShortTermSpreadOverviewBlockComponent,
      ShortOptionsOverviewBlockComponent,
      StrategyOverviewComponent
   ],
   providers: [],
})
export class AdjustmentControlPanelModule { }
