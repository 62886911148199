import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ILayoutSection } from './layout-section';
import { LayoutTab } from './layout-tabs/layout-tab';
import { SettingsStorageService } from '../settings-storage-service.service';
import { DetectMethodChanges, makeWorkspaceId } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-layout',
   templateUrl: 'layout.component.html',
   styleUrls: ['layout.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements AfterViewInit {
   constructor(
      private readonly _toastr: ToastrService,
      private readonly _layoutService: SettingsStorageService,
      private readonly _changeDetector: ChangeDetectorRef
   ) {
   }


   @Input() rootSection: LayoutTab;
   @Output() layoutChanged = new EventEmitter();


   ngAfterViewInit() { this._changeDetector.detach();}

   
   @DetectMethodChanges()
   onSplitArea(event, section: ILayoutSection): void {

      if (event.direction === 'close') {

         if (!section.parent || (section.parent.subsections.length === 1 && !section.parent.parent)) {
            this._toastr.info('Cannot close root section');
            return;
         }

         const parent = section.parent;
         const ix = parent.subsections.indexOf(section);
         if (ix >= 0) {
            parent.subsections.splice(ix, 1);
            if (parent.subsections.length === 0) {
               const ix1 = parent.parent.subsections.indexOf(section.parent);
               if (ix1 >= 0) {
                  parent.parent.subsections.splice(ix1, 1);
               }
            } else if (parent.subsections.length === 1) {
               const grandParent = parent.parent;
               if (!!grandParent) {
                  const leftSection = parent.subsections[0];
                  const ix2 = grandParent.subsections.indexOf(leftSection.parent);
                  if (ix2 >= 0) {
                     leftSection.order = ix2;
                     grandParent.subsections[ix2] = leftSection;
                     leftSection.parent = grandParent;
                  }
               }
            }
         }
         
         this._layoutService.removeItem(section.workspaceId);
         
         this.layoutChanged.emit();
      
         
         return;
      }

      const layoutTabId: string = this.rootSection.id;
      const workspaceId = makeWorkspaceId(layoutTabId);

      if (section) {

         if (section.parent.direction === event.direction || section.parent.subsections.length === 1) {
            section.parent.direction = event.direction;
            const newSize = section.size / 2;
            section.size = newSize;

            const newSection: ILayoutSection = {
               layoutTabId,
               workspaceId,
               parent: section.parent,
               order: section.parent.subsections.length,
               size: newSize
            };
            section.parent.subsections.push(newSection);

         } else {
            
            const cpy = Object.assign({}, section);

            cpy.direction = event.direction;
            cpy.workspaceId = workspaceId;
            cpy.parent = section.parent;

            section.parent = cpy;
            section.subsections = null;
            section.order = 0;

            cpy.subsections = [];
            cpy.subsections.push(section);
            cpy.subsections.push(
               {
                  layoutTabId,
                  workspaceId: makeWorkspaceId(layoutTabId),
                  parent: cpy,
                  order: 1
               });

            const ix = cpy.parent.subsections.indexOf(section);
            if (ix >= 0) {
               cpy.parent.subsections[ix] = cpy;
            }
         }
      }

      this.layoutChanged.emit();
   }

   
   @DetectMethodChanges()
   onSizeChanged(e, section: ILayoutSection): void {
      const sizesNum = e.sizes.length;
      
      for (let i = 0; i < sizesNum; i++) {
         const size = e.sizes[i];
         section.subsections[i].size = size;
      }

      this.layoutChanged.emit();
   }

   
   @DetectMethodChanges()
   onExpandAreaRequest(section: ILayoutSection): void {
      const allSections = this._getAllSections(this.rootSection.layoutRoot.subsections);

      const doNotTouchList = [];
      doNotTouchList.push(section.workspaceId);

      let parent = section.parent;
      while (!!parent) {
         doNotTouchList.push(parent.workspaceId);
         parent = parent.parent;
      }

      allSections.forEach(x => {
         if (!doNotTouchList.includes(x.workspaceId)) {
            x.isHidden = !x.isHidden;
         }
      });

      this.layoutChanged.emit();
   }


   private _getAllSections(sections: ILayoutSection[]): ILayoutSection[] {
      const retval = [];
      sections.forEach(x => {
         retval.push(x);
         if (x.subsections) {
            retval.push(...this._getAllSections(x.subsections));
         }
      });

      return retval;
   }
}
