import {EventEmitter} from "@angular/core";
import {AtmStrikeChangedEvent} from "./atm-strike-changed-event";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";
import {isValidNumber, isVoid, roundToStep} from "../../utils";

export class AtmWatcher {

    constructor(private _underlying: string) {
        this._strikeStep = _underlying === 'SPX' ? 5 : 1;
    }

    private readonly _strikeStep: number;

    private _previousAtm: number;
    private _currentAtm: number;

    //
    atmChanged$ = new EventEmitter<AtmStrikeChangedEvent>();

    //
    get atm(): number {
        return this._currentAtm;
    }

    //
    onQuote(quote: QuoteDto) {
        if (this._underlying != quote.ticker) {
            return;
        }

        this._previousAtm = this._currentAtm;
        this._currentAtm = roundToStep(quote.lastPx, this._strikeStep);

        this.broadcast();
    }

    //
    broadcast() {

        if (this._currentAtm === this._previousAtm) {
            return;
        }

        this.atmChanged$.emit({
            underlying: this._underlying,
            previousAtm: this._previousAtm,
            currentAtm: this._currentAtm
        });

    }
}
