import {Injectable} from '@angular/core';
import {SavedPositionsService} from "./saved-positions.service";
import {CashFlowStrategyTemplatesService, LastUsedTemplate} from "./cashflow-strategy-templates.service";
import {ApgPortfolio} from "../model/ApgPortfolio";
import {BeforePositionDto} from "../model/BeforePositionDto";
import {isVoid} from "../../utils";
import {OptionTicker, parseOptionTicker} from "../../options-common/options.model";
import {PositionsStateService} from "./positions-before-state.service";

@Injectable()
export class ApgDataService {

    constructor(
        private readonly _cashFlowPositionsService: SavedPositionsService,
        private readonly _cashFlowTemplatesService: CashFlowStrategyTemplatesService,
        private readonly _apgPositionsStateService: PositionsStateService,
    ) {
    }

    private _underlyingByPortfolio : {[ix: string]: string} = {};


    async getPortfolioPositions(portfolio: ApgPortfolio): Promise<BeforePositionDto[][]> {

        const serviceConfig = {
            orientation: undefined,
            userId: portfolio.userId,
            userName: portfolio.userName
        };

        this._cashFlowPositionsService.configure(serviceConfig);

        await this._cashFlowTemplatesService.configure(serviceConfig);

        const lastUsedTemplate = this._cashFlowTemplatesService
            .getLastUsedTemplate(portfolio.id);

        let positions: BeforePositionDto[][];

        if (isVoid(lastUsedTemplate)) {
            console.error('Failed to detect last used template. Positions loaded based on best effort');
            positions = this._cashFlowPositionsService.getPositions(
                portfolio.id,
                portfolio.asset,
                portfolio.strategy
            );
        } else {
            positions = this._cashFlowPositionsService.getPositions(
                portfolio.id,
                lastUsedTemplate.underlying,
                lastUsedTemplate.strategy
            );
        }

        return positions;
    }

    async getUnderlyingOfPortfolio(portfolio: ApgPortfolio): Promise<string> {
        if (!portfolio.id.startsWith('----')) {
            return portfolio.asset;
        }

        const key = portfolio.id  + portfolio.userId;

        const cashed = this._underlyingByPortfolio[key];

        if (cashed) {
            return cashed;
        }

        const serviceConfig = {
            orientation: undefined,
            userId: portfolio.userId,
            userName: portfolio.userName
        };

        this._cashFlowPositionsService.configure(serviceConfig);

        await this._cashFlowTemplatesService.configure(serviceConfig);

        const lastUsedTemplate = this._cashFlowTemplatesService
            .getLastUsedTemplate(portfolio.id);

        let ul = isVoid(lastUsedTemplate) ? portfolio.asset : lastUsedTemplate.underlying;

        this._underlyingByPortfolio[key] = ul;

        return ul;
    }

    async getLastUsedTemplateOfPortfolio(portfolio: ApgPortfolio) : Promise<LastUsedTemplate> {

        const serviceConfig = {
            orientation: undefined,
            userId: portfolio.userId,
            userName: portfolio.userName
        };

        await this._cashFlowTemplatesService.configure(serviceConfig);

        const lastUsedTemplate = this._cashFlowTemplatesService
            .getLastUsedTemplate(portfolio.id);

        return lastUsedTemplate;
    }

    async getShortOptionTicker(portfolio: ApgPortfolio): Promise<OptionTicker> {
        const positions = await this.getPortfolioPositions(portfolio);

        if (isVoid(positions)) {
            return undefined;
        }

        const side = positions[0];

        if (isVoid(side)) {
            return undefined;
        }

        const beforePositionDto = side.find(x => x.role === 'ShortOption');

        if (isVoid(beforePositionDto)) {
            return undefined;
        }

        const optionTicker = parseOptionTicker(beforePositionDto.ticker);

        return optionTicker;
    }

    async getDefaultQtyForPortfolio(args: ApgPortfolio) : Promise<number> {

        const underlyingOfPortfolio = await this.getUnderlyingOfPortfolio(args);

        const lut = await this.getLastUsedTemplateOfPortfolio(args);

        const serviceConfig = {
            orientation: undefined,
            userId: args.userId,
            userName: args.userName
        };

        this._apgPositionsStateService.configure(serviceConfig);

        const dp = this._apgPositionsStateService.getDefaultsProvider(
            args.id,
            underlyingOfPortfolio,
            lut.strategy === 'Calls & Puts' ? 'double' : 'single'
        );

        return dp?.first?.orderQty;
    }

}