import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getJobsGridOptions } from './jobs-tracker-grid-options';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { TimestampsService } from '../timestamps.service';
import { DetectMethodChanges, DetectSetterChanges } from '../utils';
import { JobsService } from './jobs-service.service';
import { JobProgressDto } from '../shell-communication/shell-dto-protocol';
import { isNull, isNullOrUndefined } from 'util';

@Component({
   selector: 'ets-jobs-tracker',
   templateUrl: 'jobs-tracker.component.html',
   styleUrls: ['jobs-tracker.component.scss'],
   providers: [
      { provide: 'WINDOW', useValue: window }
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsTrackerComponent implements OnInit, OnDestroy, AfterViewInit {

   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _messageBusService: MessageBusService,
      private _toastrService: ToastrService,
      private _timestampsService: TimestampsService,
      private _jobsService: JobsService,
      @Inject('WINDOW') private _window: any,
   ) {
   }
   
   private _jobsGrid: GridReadyEvent;
   private _unsubscriber: Subject<any> = new Subject<any>();
   private _selfEl: HTMLElement;
   private _pendingData: JobProgressDto[];

   //

   overlayPosition: { left: number; bottom: number };
   jobsGridOptions: GridOptions;

   //

   private _isOverlayVisible = false;
   get isOverlayVisible() { return this._isOverlayVisible; }

   @DetectSetterChanges()
   set isOverlayVisible(value: boolean) {
      this._isOverlayVisible = value;
   }
   
   //
   
   private _isDetailsWindowVisible = false;
   get isDetailsWindowVisible(): boolean { return this._isDetailsWindowVisible; }
   
   @DetectSetterChanges()
   set isDetailsWindowVisible(value: boolean) {
      this._isOverlayVisible = value;
   }
   
   //

   get iconColor(): 'red' | undefined {
      const grid = this._jobsGrid;
      
      if (!grid) {
         return undefined;
      }

      let cntr = 0;
      grid.api.forEachNode(node => {
         if (node.group) {
            return;
         }
         const dto = node.data as JobProgressDto;
         if (dto.hasErrors) {
            cntr++;
         }
      });

      return cntr > 0 ? 'red' : undefined;
   }
   
   get hasRunningJobs(): boolean {

      const grid = this._jobsGrid;
      
      if (!grid) {
         return false;
      }

      let cntr = 0;
      grid.api.forEachNode(node => {
         if (node.group) {
            return;
         }
         const dto = node.data as JobProgressDto;
         if (dto.progress < 100) {
            cntr++;
         }
      });

      return cntr > 0;
   }

   //


   ngOnInit() {
      this._unsubscriber = new Subject<any>();

      fromEvent(this._window, 'resize')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(args => this.onWindowResized(args));

      this._messageBusService
         .of<JobProgressDto>('JobProgressDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this.onJobProgressMessage(dto.payload));

      this.jobsGridOptions = getJobsGridOptions.bind(this)(this._timestampsService);
   }

   //

   onGridReady(args: GridReadyEvent) {
      this._jobsGrid = args;
      this.loadRunningJobs();
   }

   //
   
   onGridSizeChanged(args: any) {

   }

   //
   
   ngAfterViewInit(): void {
      const el: HTMLElement = document.querySelector(
         '#jobs-tracker-action-button'
      );
      if (el) {
         this._selfEl = el;
      }

      this._changeDetector.detach();
   }

   //

   loadRunningJobs() {

   }

   //
   
   onActionButtonClick(): void {
      const selfEl = this._selfEl;
      
      if (!selfEl) {
         return;
      }

      if (!this.isOverlayVisible) {
         // this._jobsService.subscribeJob();
         const left = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId
            ? selfEl.offsetLeft
            : selfEl.offsetLeft - 300;
         this.overlayPosition = {
            left,
            bottom: (selfEl.offsetParent as HTMLElement).offsetHeight
         };
      }

      this.isOverlayVisible = !this.isOverlayVisible;
   }
   
   //

   @DetectMethodChanges()
   private onWindowResized(args: any) {
      const el: HTMLElement = this._selfEl;
      if (!el) {
         return;
      }
      this.overlayPosition = {
         left: el.offsetLeft - 60,
         bottom: (el.offsetParent as HTMLElement).offsetHeight
      };
   }

   //

   ngOnDestroy(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }
   
   //

   removeAllFinishedJobs(): void {
      const grid = this._jobsGrid;

      if (isNullOrUndefined(grid)) {
         return;
      }

      const rows = [];
      grid.api.forEachNode(node => {
         const dto = node.data as JobProgressDto;

         if (isNullOrUndefined(dto)) {
            return;
         }

         if (dto.progress === 100) {
            rows.push(dto);
         }
      });

      if (rows.length > 0) {
         grid.api.applyTransaction({remove: rows});
      }
   }

   //

   removeJob(data: JobProgressDto): void {
      
      if (isNullOrUndefined(data)) {
         return;
      }

      const grid = this._jobsGrid;

      if (isNullOrUndefined(grid)) {
         return;
      }

      grid.api.applyTransaction({ remove: [data] });
   }

   //

   @DetectMethodChanges()
   private onJobProgressMessage(dto: JobProgressDto): void {
      const grid = this._jobsGrid;

      if (!grid) {
         return;
      }

      const node = grid.api.getRowNode(dto.jobId);
      if (node) {
         grid.api.applyTransaction({ update: [dto] });
      } else {
         grid.api.applyTransaction({ add: [dto] });
      }
   }
}
