import { InterestOverviewBlockDto } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { isVoid, militaryTimeToAmPm } from "projects/shared-components/utils";

export class InterestOverviewBlock {

   constructor(
      public readonly header: string
   ) {
      this.backgroundColor = '#504e4e';
   }

   backgroundColor: string;
   color: string;

   get hasSettings(): boolean {
      return !isVoid(this.strategyId);
   };

   strategyId: string;
   isStrategyRunning: boolean;
   amount: number;
   interestRate: number;
   interval: string;
   account: number;
   chargeTime: string;
   chargeTimezone: string;

   //
   setDto(dto: InterestOverviewBlockDto) {
      if (isVoid(dto)) {
         return;
      }

      this.strategyId = dto.strategyId;
      this.isStrategyRunning = dto.isStrategyRunning;
      this.amount = dto.amount;
      this.interestRate = dto.interestRate;
      this.interval = dto.interval;
      this.account = dto.account;
      this.chargeTime = militaryTimeToAmPm(dto.chargeTime);
      this.chargeTimezone = dto.chargeTimezone;
   }

   //
   reset() {
      this.strategyId = undefined;
      this.isStrategyRunning = undefined;
      this.amount = undefined;
      this.interestRate = undefined;
      this.interval = undefined;
      this.account = undefined;
      this.chargeTime = undefined;
      this.chargeTimezone = undefined;
   }

}