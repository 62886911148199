import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { MessageBusService } from '../message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IssueAckedUIMessage } from '../ui-messages/issue-acked-ui-message.interface';

@Component({
   selector: 'ets-total-strategy-issues',
   templateUrl: 'total-strategy-issues.component.html',
   styleUrls: ['total-strategy-issues.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalStrategyIssuesComponent implements OnInit, AfterViewInit {
   constructor(
      private _strategyIssuesService: StrategiesIssuesService,
      private _changeDetector: ChangeDetectorRef,
      private _messageBus: MessageBusService
   ) {
      
   }

   private readonly _unsubscriber = new Subject<void>();

   get totalIssuesCount(): number {
      return this._strategyIssuesService.getTotalIssuesCount() || 0;
   }

   ngOnInit() {

      this._messageBus.of('StrategyIssuesChanged')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(() => this._changeDetector.detectChanges());

      this._messageBus.of<IssueAckedUIMessage>('IssueAckedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(() => this._changeDetector.detectChanges());

   }

   ngAfterViewInit() { 
      this._changeDetector.detach(); 
      setTimeout(() => {
         this._changeDetector.detectChanges();
      }, 1000);
   }
}
