<div class="ets-workspace-toolbar">
  <i *ngIf="!isExpanded && securityContext.canExpandCollapsePanel"
     class="fas fa-expand-arrows-alt fa-lg"
     (click)="onExpandButtonClicked($event)"
  ></i>

  <i *ngIf="isExpanded && securityContext.canExpandCollapsePanel"
     class="fas fa-clone fa-lg" (click)="onExpandButtonClicked($event)"
  ></i>

  <i class="fas fa-th fa-lg" *ngIf="securityContext.canSplitWorkspace" (click)="onSplitWorkspaceRequest($event)"></i>

  <i class="fas fa-plus fa-lg" (click)="onContextMenu($event)"
     *ngIf="securityContext.canAddPanel"
  ></i>
</div>