<div class="root-container">
  <div class="portfolio-list">
    <dx-select-box
      (onValueChanged)="this.onPortfolioSelected($event)"
      [(value)]="this.selectedPortfolio"
      [grouped]="true"
      [items]="this.portfolios"
      displayExpr="name"
      fieldTemplate="field"
    >
      <div *dxTemplate="let data of 'field'">
        <div>
          <dx-text-box [readOnly]="true"
                       [value]="data && data.userName + '  |  ' + data.name"
                       placeholder="Select portfolio...">
          </dx-text-box>
        </div>
      </div>
    </dx-select-box>
  </div>
  <div class="refresh-btn" [ngClass]="this.getRefreshPortfolioCss() | etsNgClassAsync:this">
    <dx-button (onClick)="this.refreshPortfolio(this.selectedPortfolio)"
               [disabled]="!this.canRefreshPortfolio"
               icon="refresh"
    ></dx-button>
  </div>
</div>

<ets-hg-hedge-positions-sync-dialog>
</ets-hg-hedge-positions-sync-dialog>
