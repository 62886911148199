import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SettingsStorageService } from '../settings-storage-service.service';
import { MessageBusService } from '../message-bus.service';
import { getPanelStateKey } from '../utils';
import { IPanelComponent } from './panel-component.interface';
import {UserSettingsService} from "../user-settings.service";

const CHANGE_DETECT_DELAY = 1;

@Component({
   selector: 'panel-base',
   template: ''
})
export abstract class PanelBaseComponent implements OnInit, OnDestroy, AfterViewInit, IPanelComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService
   ) { }

   private _subscription: Subscription;

   panelId: string;
   
   workspaceId: string;
   
   layoutTabId: string;
   
   private _isActive: boolean;
   get isActive(): boolean { return this._isActive; }
   
   set isActive(v: boolean) { 
      
      if (this._isActive === v) {
         return;
      }

      this._isActive = v; 
      
      setTimeout(() => {
         this._changeDetector.detectChanges();
      }, CHANGE_DETECT_DELAY);

      this.onIsActiveChanged();
   }
   
   private _isLinkedToSymbol: boolean;
   get isLinkedToSymbol(): boolean { return this._isLinkedToSymbol; }

   set isLinkedToSymbol(v: boolean) {
      if (this._isLinkedToSymbol === v) { return; }
      
      this._isLinkedToSymbol = v;
   
      this.saveState();
      
      setTimeout(() => {
         this._changeDetector.detectChanges();
      }, CHANGE_DETECT_DELAY);
   }


   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   
   set isLoading(val: boolean) { 
      if (this._isLoading === val) { return; }
      this._isLoading = val; 
      this._changeDetector.detectChanges();
   }


   ngOnInit(): void {

      this._subscription = this._messageBus.of<any>('WorkspaceClosed')
         .pipe(
            filter(msg => msg.payload.workspaceId === this.workspaceId)
         )
         .subscribe(msg => this.onRemovedFromWorkspace());

      this.etsOnInit();
   }

   abstract etsOnInit(): void;

   ngOnDestroy(): void {
      this.etsOnDestroy();
   }

   abstract etsOnDestroy(): void;

   ngAfterViewInit(): void {
      this._changeDetector.detach();
      this.etsAfterViewInit();
   }

   abstract etsAfterViewInit(): void;

   onRemovedFromWorkspace(): void {
      if (this._subscription) {
         this._subscription.unsubscribe();
      }

      const key = getPanelStateKey(this);
      
      if (key) {
         this._userSettingsService.deleteValue(key);
      }
   }

   protected saveState() {
      setTimeout(() => {
         const state = this.getState();
         if (!state) {
            return;
         }
         const key = getPanelStateKey(this);
         this._userSettingsService.setValue(key, state);
      }, 1);
   }

   protected abstract getState(): any;

   protected restoreState() {

      const key = getPanelStateKey(this);
      const state = this._userSettingsService.getValue(key);

      
      if (!state) {
         return;
      }
      
      this.setState(state);
   }

   protected abstract setState(state: any);

   protected onIsActiveChanged() {

   }
}
