import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {DetectSetterChanges, isVoid} from "../../utils";

@Component({
    selector: 'ets-apg-context-menu',
    templateUrl: './apg-context-menu.component.html',
    styleUrls: [
        './apg-context-menu.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApgContextMenuComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef
    ) {
    }


    @Input()
    target: any;


    private _visible: boolean;
    get visible(): boolean {
        return this._visible;
    }


    @DetectSetterChanges()
    set visible(value: boolean) {
        this._visible = value;
    }


    @Input()
    menuItems: any[];


    @Output()
    menuItemClicked = new EventEmitter<string>();


    ngOnInit() {
    }


    toggle() {
        if (this.visible) {
            this.visible = false;
        } else {
            if (isVoid(this.menuItems)) {
                return;
            }
            this.visible = true;
        }
    }


    onMenuItemClicked($event: any) {
        const itemData = $event.itemData;
        if (isVoid(itemData)) {
            return;
        }
        this.menuItemClicked.emit(itemData.text);
    }
}
