<div class="container empty" 
   *ngIf="this.viewModel.noLegs" [ngStyle]="{'background-color': this.mode !== 'sideblock' ? this.viewModel.bgColor : 'inherit'}">
   <p>No Legs</p>
</div>

<div class="container">
   <table class="table" *ngIf="!this.viewModel.noLegs" 
                        [ngStyle]="{
                                       'background-color': this.mode !== 'sideblock' ? this.viewModel.bgColor : 'inherit', 
                                       'font-size': this.mode === 'sideblock' ? 'larger' : 'inherit'
                                    }">
      <tbody>
         <tr>
            <td class="parameter">Short Opt.:</td>
            <td class="value">   
               <div class="leg-descriptor">
                  <div>{{this.viewModel.shortOptionQty}}</div>
                  <div>{{this.viewModel.shortOptionAsset}}</div> 
                  <div>{{this.viewModel.shortOptionExpiration}}</div>
                  <div>{{this.viewModel.shortOptionDaysToExpiration}} days left</div>
               </div>
            </td>
         </tr>
      </tbody>
   </table>
</div>
