import { NgModule } from '@angular/core';

import { SystemDetailsComponent } from './system-details.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([])
  ],
  exports: [SystemDetailsComponent],
  declarations: [SystemDetailsComponent],
  providers: []
})
export class EtsSystemDetailsModule { }
