import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoggerService } from '../logging/logger-factory.service';
import { Logger } from '../logging/logger.interface';
import { ManualTradingBackendService } from '../manual-trading/manual-trading-backend.service';
import { OverridePositionTokenDto } from '../shell-communication/dtos/override-position-token-dto.interface';
import { OverridePosition } from '../shell-communication/operations/strategies/override-position.class';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { DetectMethodChanges, isNullOrUndefined } from '../utils';

interface PositionToOverride {
   positionId: string;
   ticker: string;
   netPosition: number;
   avgPx: number;
   tickSize?: number;
   tickerDisplayName: string;
}

const NULL_POSITION: PositionToOverride = { 
   positionId: null, 
   ticker: null, 
   tickerDisplayName: null,
   netPosition: null, 
   avgPx: null, 
   tickSize: null 
};

@Component({
   selector: 'ets-position-override',
   templateUrl: 'position-override.component.html',
   styleUrls: ['position-override.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionOverrideComponent implements OnInit, AfterViewInit {
   constructor(
      private _tiService: TradingInstrumentsService,
      private _toastr: ToastrService,
      private readonly _backendClient: ManualTradingBackendService,
      private _changeDetector: ChangeDetectorRef,
      loggerService: LoggerService
   ) { 
      this._logger = loggerService.createLogger('ManualPositionsComponent');
   }

   private readonly _logger: Logger;


   isVisible = false;
   positionToOverride = NULL_POSITION;

   

   ngOnInit() { }


   ngAfterViewInit() { this._changeDetector.detach(); }

   
   @DetectMethodChanges()
   show(aPositionToOverride: PositionToOverride) {
      this.positionToOverride = aPositionToOverride;
      this.positionToOverride.tickSize = this.getTickSize(aPositionToOverride.ticker) || 0.01;
      this.isVisible = true;
   }


   canOverride() {

      if (isNullOrUndefined(this.positionToOverride.netPosition)) {
         return false;
      }

      if (isNullOrUndefined(this.positionToOverride.avgPx)) {
         return false;
      }

      if (isNaN(this.positionToOverride.avgPx)) {
         return false;
      }

      const netPos = this.positionToOverride.netPosition || 0;
      const avgPx = this.positionToOverride.avgPx || 0;
      
      if (netPos === 0 &&  avgPx !== 0) {
         return false;
      }

      if (netPos !== 0 && avgPx === 0) {
         return false;
      }

      return true;
   }


   
   async overridePosition(): Promise<any> {
      if (!this.positionToOverride) {
         this._toastr.info('Position not selected');
         return;
      }

      const overrideTokens: OverridePositionTokenDto[] = [this.positionToOverride];
      const cmd = new OverridePosition(overrideTokens);

      try {
         await this._backendClient.overridePosition(cmd);
      } catch (error) {
         const errorMessage = `Error occurred during "Override Position" operation`;
         this._logger.error(errorMessage, { error });
         this._toastr.error(errorMessage);
      } finally {
         this.closeOverrideDialog();
      }
   }


   @DetectMethodChanges()
   closeOverrideDialog() {
      this.positionToOverride = NULL_POSITION;
      this.isVisible = false;
   }

   @DetectMethodChanges()
   onHidden() {
      this.closeOverrideDialog();
   }
   
   private getTickSize(ticker: string) {

      let instrument: TradingInstrument;
      
      if (ticker.startsWith('@')) {
         const parts = ticker.split(' ');
         if (parts.length < 2) {
            this._toastr.error('Cannot determine order\'s symbol');
            return;
         }
         const underlying = parts[1];

         instrument = this._tiService.getInstrumentByTicker(underlying);

      } else {
         instrument = this._tiService.getInstrumentByTicker(ticker);
      }
      
      if (!instrument) {
         this._toastr.error('Cannot determine order\'s symbol');
         return;
      }

      return instrument.tickSize;
   }
}
