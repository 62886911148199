import { SubscribeToMarketData } from './shell-communication/operations/market-data/subscirbe-market-data.class';
import { UnsubscribeFromMarketData } from './shell-communication/operations/market-data/unsubscribe-market-data.class';
import { ChangeMarketDataSubscriptionShell, ChangeOptionStrategiesSubscriptionShell } from './shell-communication/shell-operations-protocol';

export abstract class LastQuoteCacheBackendService {
   abstract unsubscribeMarketData(cmd: UnsubscribeFromMarketData): void;
   abstract subscribeMarketData(cmd: SubscribeToMarketData): void;
   abstract changeMarketDataSubscription(cmd: ChangeMarketDataSubscriptionShell): Promise<void>;
   abstract changeOptionStrategiesSubscription(cmd: ChangeOptionStrategiesSubscriptionShell): Promise<void>;
}
