<dx-popup
  width="70%"
  height="93%"
  [showTitle]="true"
  title="New Flagship Report"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let data of 'content'">
    <div class="container">
      <div class="header">
        <div class="section strategy-selector">
          <p>Pick a Strategy</p>
          <dx-select-box [items]="availableStrategies"
                         displayExpr="displayName"
                         [(value)]="selectedStrategy"
          ></dx-select-box>
        </div>
        <div class="section ticker-selector">
          <p>Ticker</p>
          <dx-select-box [items]="availableTickersOfStrategy"
                         [(value)]="selectedTicker"
                         displayExpr="displayName"
          ></dx-select-box>
        </div>
        <div class="section metadata">
          <div>
            <span class="label">Algorithm:&nbsp;</span>
            <span class="value">{{(selectedStrategy?.algo) || 'N/A'}}</span>
          </div>
          <div>
            <span class="label">Bet Style:&nbsp;</span>
            <span class="value">{{betStyle}}</span>
          </div>
          <div>
            <span class="label">Bet Sequence:&nbsp;</span>
            <span class="value">{{betSequence}}</span>
          </div>
        </div>

        <div class="section">
          <dx-check-box text="Deleted Strategies" 
                        [(value)]="isDeletedMode"
                        #deletedStrats></dx-check-box>
        </div>


        <div class="section">
          <p>Data Load Mode</p>
          <dx-select-box [items]="dataLoadModes"
                         [(value)]="selectedDataLoadMode"
                         [displayExpr]="'text'"
                         #modeBox
          ></dx-select-box>
        </div>

        <div class="section">
          <dx-button text="Load Data" (onClick)="onLoadDataButtonClick()"
                      [disabled]="!canLoadData()"
          ></dx-button>
        </div>

      </div>
      
      <div class="main">
        <div class="system-details">
          <ets-system-details></ets-system-details>
        </div>
        <div class="games">
          <ag-grid-angular
            class="ag-theme-balham-dark"
            [gridOptions]="gamesGridModel"
          ></ag-grid-angular>
        </div>
      </div>

      <div class="footer">
        <span class="badge percent-wins">%Wins: {{percentWins | percent: '1.2-2'}}</span>
        <span class="badge ticks-points">
          <dx-check-box text="Ticks/Points"
                        [(value)]="ticksOrPoints"
          ></dx-check-box>
        </span>
      </div>
    </div>

    <dx-load-panel [visible]="isLoading"
    ></dx-load-panel>

    <!-- Date Picker -->
    <dx-popup
      width="300"
      height="140"
      [showTitle]="true"
      [visible]="isDatePickerVisible"
      (onHidden)="isDatePickerVisible = false"
      title="Select Dates...">
      <div *dxTemplate="let data of 'content'">
        
        <div class="dates">
          <div class="datebox">
            <p>Start:</p>
            <dx-date-box 
              [showClearButton]="true" width="100%" 
              #startDate
              type="date"
              [(value)]="dateRange.startDate"
            ></dx-date-box>  
          </div>
          
          <div class="datebox">
            <p>End:</p>
            <dx-date-box  [showClearButton]="true" 
                          width="100%" 
                          #endDate
                          type="date"
                          [(value)]="dateRange.endDate"
            ></dx-date-box>
          </div>
        </div>

        <div class="set-dates">
          <dx-button  text="Apply Filter" 
                      (onClick)="applyDateFilter(startDate.value, endDate.value)" 
                      [disabled]="!(startDate.value && endDate.value)"
          ></dx-button>
        </div>

      </div>
    </dx-popup>

  </div>
</dx-popup>