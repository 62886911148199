import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Enumerable from 'linq';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import {ResourceButton} from "../../resource-button.interface";
import {ResourceEditorComponent} from "../../resource-editor.component";

@Component({
    selector: 'ets-date-condition-resource',
    template: `
<div>
  <p class="label">
    Date
  </p>
  <div class="input">
       <dx-drop-down-box
          [disabled]="this.disabled"
          [dropDownOptions]="{width: 300}"
          fieldTemplate="field"
          (onOpened)="this.onOpened()"
          (onClosed)="this.onClosed()"
          >
          <div *dxTemplate="let data of 'field'">
             <dx-text-box 
                [value]="this.dayOfYear" 
                [readOnly]="true"
                >
             </dx-text-box>
          </div>
    
          <div *dxTemplate="let data of 'content'">
             <div class="day-of-year-picker">
                <div class="month">
                   <div class="label">Month</div>
                   <div class="input">
                      <dx-select-box
                         [items]="this.months"
                         [(value)]="this.selectedMonth"
                         [showClearButton]="true"
                         >
                      </dx-select-box>
                   </div>
                </div>
                <div class="day">
                   <div class="label">Day</div>
                   <dx-select-box
                      [items]="this.days"
                      [(value)]="this.selectedDay"
                      [showClearButton]="true"
                      >
                   </dx-select-box>
                </div>
             </div>
    
             <ets-timezone-picker
                [(timezone)]="this.timezone"
                [flow]="'col'">
             </ets-timezone-picker>
          </div>
       </dx-drop-down-box>
  </div>
</div>
   `,
    styles: [`
   .day-of-year-picker {
      display: flex;
   }

   .month {
      flex: 2;
   }

   .day {
      flex: 1;
   }
   `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateConditionResourceComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef
    ) { }

    private _oldValue: string;

    @Input() comp: ResourceEditorComponent;

    months: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    get disabled(): boolean {
        return isVoid(this.resource);
    }

    private _resource: ResourceButton;
    get resource(): ResourceButton {
        return this._resource;
    }

    @Input()
    @DetectSetterChanges()
    set resource(value: ResourceButton) {
        this.reset();

        this._resource = value;

        if (isVoid(value)) {
            return;
        }

        if (isVoid(value.conditionDate)) {
            return;
        }

        if (value.conditionDate.indexOf('|') < 0) {
            return;
        }

        const parts = value.conditionDate.split('|');

        if (parts.length < 3) {
            return;
        }

        this.selectedMonth = parts[0];
        this.selectedDay = parseInt(parts[1]);
        this.timezone = parts[2];
    }


    private _selectedMonth : string;
    get selectedMonth() : string {
        return this._selectedMonth;
    }

    @DetectSetterChanges({delay: 1})
    set selectedMonth(v : string) {
        this._selectedMonth = v;
        this.days = this.updateDays();
    }

    days: number[];

    selectedDay: number;

    timezone: string;

    get dayOfYear(): string {
        if (!this.selectedMonth) {
            return undefined;
        }

        if (!this.selectedDay) {
            return undefined;
        }

        return `${this.selectedMonth}, ${this.selectedDay}`;
    }

    private getValue(): string {
        if (!this.selectedMonth) {
            return undefined;
        }

        if (!this.selectedDay) {
            return undefined;
        }

        if (!this.timezone) {
            return undefined;
        }

        const val = `${this.selectedMonth}|${this.selectedDay}|${this.timezone}`;

        return val;
    }

    ngOnInit(): void { }

    ngAfterViewInit() {
        this._changeDetector.detach();
    }

    onOpened() {
        this._oldValue = this.getValue();
    }

    @DetectMethodChanges()
    onClosed() {

        const value = this.getValue();

        this.resource.conditionDate = value;

        if (this._oldValue === value) {
            return;
        }

        this._resource.hasChanges = true;
        this.comp.onChange({});
    }

    private reset() {
        this.selectedMonth = undefined;
        this.selectedDay = undefined;
        this.timezone = undefined;
    }

    private updateDays() {
        if (isVoid(this.selectedMonth)) {
            return [];
        }

        switch(this.selectedMonth) {
            case 'January':
            case 'March':
            case 'May':
            case 'July':
            case 'August':
            case 'October':
            case 'December':
                return Enumerable.range(1, 31, 1).toArray();
            case 'February':
                return Enumerable.range(1,29, 1).toArray();
            default:
                return Enumerable.range(1, 30, 1).toArray();
        }
    }
}
