export function getCacheKey(messageType: string, message: any): string {
   let retval;

   switch (messageType) {
      case 'BucketSummaryDto': {
         retval = `bucksum:${message.bucketType}-${message.terminalId}-${message.portfolioId}-${message.comboId}-${message.comboGroupId}`;
         break;
      }
      case 'StrategyPriceDto': {
         retval = message.strategyCode;
         break;
      }
      case 'QuoteDto': {
         retval = `quote:${message.ticker}-${message.tag}`;
         break;
      }
      case 'OrderDto': {
         retval = message.orderId;
         break;
      }
      case 'PositionDto': {
         retval = message.positionId;
         break;
      }
      case 'TerminalTotalPositionDto': {
         retval = message.terminalId;
         break;
      }
      case 'StrategyStatusDto':
      case 'StrategySessionActivityMessageDto':
      case 'StrategyStateDto':
      case 'StrategyTradePnLDto':
      case 'StrategyOpenPositionSnapshotDto': {
         retval = message.strategyId;
         break;
      }
      case 'AggregatedPositionDto': {
         retval = `aggpos:${message.terminalId}-${message.accountId}-${message.tradingInstrumentCode}=${message.positionClass}`;
         break;
      }
      case 'PnLSnapshotDto': {
         retval = message.subscriptionId;
         break;
      }
      default: {
         throw new Error(`Message of type ${messageType} is does not support 'fresh-only' cache strategy`);
      }
   }

   return retval;
}
