export class QuoteDto {
   constructor() {
      this.bidPx = this.askPx = this.bidQx = this.askQx = [];
   }

   ticker: string;
   displayTicker: string;
   lastPx: number;
   lastQx: number;
   open: number;
   high: number;
   low: number;
   provider: string;
   bidPx: number[];
   bid: number;
   bidSize: number;
   askPx: number[];
   ask: number;
   askSize: number;
   bidQx: number[];
   askQx: number[];
   timeStamp: Date;
   spreadTicks: number;
   isLevel2: boolean;
   shellId: string;
   shellName: string;
   clientId: string;
   clientName: string;
   strikePrice: number;
   openInterest: number;
   percentChange: number;
   dollarChange: number;
   mid: number;
}
