import { NgModule } from '@angular/core';
import { OptionChainComponent } from './option-chain.component';
import { CommonModule } from '@angular/common';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { AgGridModule } from 'ag-grid-angular';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      EtsSymbolPickerModule,
      AgGridModule.withComponents([]),
      OptionsCommonModule,
      DevExtremeModule
   ],
   exports: [],
   declarations: [OptionChainComponent],
   providers: [],
})
export class OptionChainModule { }
