import { isNullOrUndefined } from '../utils';
import { TimeZoneFamily } from './time-zone-family.interface';

export const TIME_ZONE_FAMILIES: TimeZoneFamily[] = [
  {
    displayName: 'Afghanistan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:30) Kabul',
        countryName: 'Afghanistan',
        baseUtcOffset: '04:30:00',
        windowsTimeZoneId: 'Afghanistan Standard Time',
        nodaTimeZoneId: 'Asia/Kabul',
        offset: {
          offsetSeconds: 16200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Åland Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Åland Islands',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Mariehamn',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Albania',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Albania',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Tirane',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Algeria',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Algeria',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Algiers',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Andorra',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Andorra',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Andorra',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Angola',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Angola',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Luanda',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Anguilla',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Anguilla',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Anguilla',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Antarctica',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        countryName: 'Antarctica',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'SA Eastern Standard Time',
        nodaTimeZoneId: 'Antarctica/Palmer',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Antarctica',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Antarctica/Syowa',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'Antarctica',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Antarctica/Mawson',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+06:00) Astana',
        countryName: 'Antarctica',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Central Asia Standard Time',
        nodaTimeZoneId: 'Antarctica/Vostok',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        countryName: 'Antarctica',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'SE Asia Standard Time',
        nodaTimeZoneId: 'Antarctica/Davis',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+10:00) Guam, Port Moresby',
        countryName: 'Antarctica',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'West Pacific Standard Time',
        nodaTimeZoneId: 'Antarctica/DumontDUrville',
        offset: {
          offsetSeconds: 36000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        countryName: 'Antarctica',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Central Pacific Standard Time',
        nodaTimeZoneId: 'Antarctica/Casey',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+13:00) Auckland, Wellington',
        countryName: 'Antarctica',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'New Zealand Standard Time',
        nodaTimeZoneId: 'Antarctica/McMurdo',
        offset: {
          offsetSeconds: 46800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Antigua & Barbuda',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Antigua & Barbuda',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Antigua',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Argentina',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) City of Buenos Aires',
        countryName: 'Argentina',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'Argentina Standard Time',
        nodaTimeZoneId: 'America/Argentina/Salta',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Armenia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Yerevan',
        countryName: 'Armenia',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Caucasus Standard Time',
        nodaTimeZoneId: 'Asia/Yerevan',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Aruba',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Aruba',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Aruba',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Australia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Perth',
        countryName: 'Australia',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'W. Australia Standard Time',
        nodaTimeZoneId: 'Australia/Perth',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+08:45) Eucla',
        countryName: 'Australia',
        baseUtcOffset: '08:45:00',
        windowsTimeZoneId: 'Aus Central W. Standard Time',
        nodaTimeZoneId: 'Australia/Eucla',
        offset: {
          offsetSeconds: 31500
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+10:30) Adelaide',
        countryName: 'Australia',
        baseUtcOffset: '09:30:00',
        windowsTimeZoneId: 'Cen. Australia Standard Time',
        nodaTimeZoneId: 'Australia/Broken_Hill',
        offset: {
          offsetSeconds: 37800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+09:30) Darwin',
        countryName: 'Australia',
        baseUtcOffset: '09:30:00',
        windowsTimeZoneId: 'AUS Central Standard Time',
        nodaTimeZoneId: 'Australia/Darwin',
        offset: {
          offsetSeconds: 34200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+11:00) Hobart',
        countryName: 'Australia',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'Tasmania Standard Time',
        nodaTimeZoneId: 'Australia/Hobart',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+11:00) Canberra, Melbourne, Sydney',
        countryName: 'Australia',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'AUS Eastern Standard Time',
        nodaTimeZoneId: 'Australia/Melbourne',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+10:00) Brisbane',
        countryName: 'Australia',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'E. Australia Standard Time',
        nodaTimeZoneId: 'Australia/Brisbane',
        offset: {
          offsetSeconds: 36000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+11:00) Lord Howe Island',
        countryName: 'Australia',
        baseUtcOffset: '10:30:00',
        windowsTimeZoneId: 'Lord Howe Standard Time',
        nodaTimeZoneId: 'Australia/Lord_Howe',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        countryName: 'Australia',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Central Pacific Standard Time',
        nodaTimeZoneId: 'Antarctica/Macquarie',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Austria',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Austria',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Vienna',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Azerbaijan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Baku',
        countryName: 'Azerbaijan',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Azerbaijan Standard Time',
        nodaTimeZoneId: 'Asia/Baku',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Bahamas',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Eastern Time (US & Canada)',
        countryName: 'Bahamas',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Eastern Standard Time',
        nodaTimeZoneId: 'America/Nassau',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Bahrain',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Kuwait, Riyadh',
        countryName: 'Bahrain',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Arab Standard Time',
        nodaTimeZoneId: 'Asia/Bahrain',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Bangladesh',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+06:00) Dhaka',
        countryName: 'Bangladesh',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Bangladesh Standard Time',
        nodaTimeZoneId: 'Asia/Dhaka',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Barbados',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Barbados',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Barbados',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Belarus',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Minsk',
        countryName: 'Belarus',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Belarus Standard Time',
        nodaTimeZoneId: 'Europe/Minsk',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Belgium',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        countryName: 'Belgium',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Romance Standard Time',
        nodaTimeZoneId: 'Europe/Brussels',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Belize',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'Belize',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'America/Belize',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Benin',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Benin',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Porto-Novo',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Bermuda',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Atlantic Time (Canada)',
        countryName: 'Bermuda',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Atlantic Standard Time',
        nodaTimeZoneId: 'Atlantic/Bermuda',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Bhutan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+06:00) Dhaka',
        countryName: 'Bhutan',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Bangladesh Standard Time',
        nodaTimeZoneId: 'Asia/Thimphu',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Bolivia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Bolivia',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/La_Paz',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Bosnia & Herzegovina',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        countryName: 'Bosnia & Herzegovina',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central European Standard Time',
        nodaTimeZoneId: 'Europe/Sarajevo',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Botswana',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Botswana',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Gaborone',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Brazil',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Brazil',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Eirunepe',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-03:00) Cuiaba',
        countryName: 'Brazil',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Central Brazilian Standard Time',
        nodaTimeZoneId: 'America/Campo_Grande',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Brazil',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Porto_Velho',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        countryName: 'Brazil',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'SA Eastern Standard Time',
        nodaTimeZoneId: 'America/Belem',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-03:00) Araguaina',
        countryName: 'Brazil',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'Tocantins Standard Time',
        nodaTimeZoneId: 'America/Araguaina',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-03:00) Salvador',
        countryName: 'Brazil',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'Bahia Standard Time',
        nodaTimeZoneId: 'America/Bahia',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-02:00) Brasilia',
        countryName: 'Brazil',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'E. South America Standard Time',
        nodaTimeZoneId: 'America/Sao_Paulo',
        offset: {
          offsetSeconds: -7200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-02:00) Coordinated Universal Time-02',
        countryName: 'Brazil',
        baseUtcOffset: '-02:00:00',
        windowsTimeZoneId: 'UTC-02',
        nodaTimeZoneId: 'America/Noronha',
        offset: {
          offsetSeconds: -7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Britain (UK)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Britain (UK)',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Europe/London',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'British Indian Ocean Territory',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+06:00) Astana',
        countryName: 'British Indian Ocean Territory',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Central Asia Standard Time',
        nodaTimeZoneId: 'Indian/Chagos',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Brunei',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
        countryName: 'Brunei',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Singapore Standard Time',
        nodaTimeZoneId: 'Asia/Brunei',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Bulgaria',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Bulgaria',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Sofia',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Burkina Faso',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Burkina Faso',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Ouagadougou',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Burundi',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Burundi',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Bujumbura',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Cambodia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        countryName: 'Cambodia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'SE Asia Standard Time',
        nodaTimeZoneId: 'Asia/Phnom_Penh',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Cameroon',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Cameroon',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Douala',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Canada',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-08:00) Pacific Time (US & Canada)',
        countryName: 'Canada',
        baseUtcOffset: '-08:00:00',
        windowsTimeZoneId: 'Pacific Standard Time',
        nodaTimeZoneId: 'America/Vancouver',
        offset: {
          offsetSeconds: -28800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Mountain Time (US & Canada)',
        countryName: 'Canada',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'Mountain Standard Time',
        nodaTimeZoneId: 'America/Edmonton',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Arizona',
        countryName: 'Canada',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'US Mountain Standard Time',
        nodaTimeZoneId: 'America/Creston',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-06:00) Central Time (US & Canada)',
        countryName: 'Canada',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central Standard Time',
        nodaTimeZoneId: 'America/Winnipeg',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-06:00) Saskatchewan',
        countryName: 'Canada',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Canada Central Standard Time',
        nodaTimeZoneId: 'America/Regina',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-05:00) Eastern Time (US & Canada)',
        countryName: 'Canada',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Eastern Standard Time',
        nodaTimeZoneId: 'America/Toronto',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-04:00) Atlantic Time (Canada)',
        countryName: 'Canada',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Atlantic Standard Time',
        nodaTimeZoneId: 'America/Halifax',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Canada',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Blanc-Sablon',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-03:30) Newfoundland',
        countryName: 'Canada',
        baseUtcOffset: '-03:30:00',
        windowsTimeZoneId: 'Newfoundland Standard Time',
        nodaTimeZoneId: 'America/St_Johns',
        offset: {
          offsetSeconds: -12600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Cape Verde',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-01:00) Cabo Verde Is.',
        countryName: 'Cape Verde',
        baseUtcOffset: '-01:00:00',
        windowsTimeZoneId: 'Cape Verde Standard Time',
        nodaTimeZoneId: 'Atlantic/Cape_Verde',
        offset: {
          offsetSeconds: -3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Caribbean NL',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Caribbean NL',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Kralendijk',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Cayman Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Cayman Islands',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Cayman',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Central African Rep.',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Central African Rep.',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Bangui',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Chad',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Chad',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Ndjamena',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Chile',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Easter Island',
        countryName: 'Chile',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Easter Island Standard Time',
        nodaTimeZoneId: 'Pacific/Easter',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-03:00) Santiago',
        countryName: 'Chile',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Pacific SA Standard Time',
        nodaTimeZoneId: 'America/Santiago',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        countryName: 'Chile',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'SA Eastern Standard Time',
        nodaTimeZoneId: 'America/Punta_Arenas',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'China',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+06:00) Astana',
        countryName: 'China',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Central Asia Standard Time',
        nodaTimeZoneId: 'Asia/Urumqi',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        countryName: 'China',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'China Standard Time',
        nodaTimeZoneId: 'Asia/Shanghai',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Christmas Island',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        countryName: 'Christmas Island',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'SE Asia Standard Time',
        nodaTimeZoneId: 'Indian/Christmas',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Cocos (Keeling) Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+06:30) Yangon (Rangoon)',
        countryName: 'Cocos (Keeling) Islands',
        baseUtcOffset: '06:30:00',
        windowsTimeZoneId: 'Myanmar Standard Time',
        nodaTimeZoneId: 'Indian/Cocos',
        offset: {
          offsetSeconds: 23400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Colombia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Colombia',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Bogota',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Comoros',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Comoros',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Indian/Comoro',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Congo (Dem. Rep.)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Congo (Dem. Rep.)',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Kinshasa',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Congo (Dem. Rep.)',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Lubumbashi',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Congo (Rep.)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Congo (Rep.)',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Brazzaville',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Cook Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-10:00) Hawaii',
        countryName: 'Cook Islands',
        baseUtcOffset: '-10:00:00',
        windowsTimeZoneId: 'Hawaiian Standard Time',
        nodaTimeZoneId: 'Pacific/Rarotonga',
        offset: {
          offsetSeconds: -36000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Costa Rica',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'Costa Rica',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'America/Costa_Rica',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Côte d\'Ivoire',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Côte d\'Ivoire',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Abidjan',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Croatia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        countryName: 'Croatia',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central European Standard Time',
        nodaTimeZoneId: 'Europe/Zagreb',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Cuba',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Havana',
        countryName: 'Cuba',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Cuba Standard Time',
        nodaTimeZoneId: 'America/Havana',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Curaçao',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Curaçao',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Curacao',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Cyprus',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Athens, Bucharest',
        countryName: 'Cyprus',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'GTB Standard Time',
        nodaTimeZoneId: 'Asia/Nicosia',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+02:00) Istanbul',
        countryName: 'Cyprus',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Turkey Standard Time',
        nodaTimeZoneId: 'Asia/Famagusta',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Czech Republic',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Czech Republic',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Prague',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Denmark',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        countryName: 'Denmark',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Romance Standard Time',
        nodaTimeZoneId: 'Europe/Copenhagen',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Djibouti',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Djibouti',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Djibouti',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Dominica',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Dominica',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Dominica',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Dominican Republic',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Dominican Republic',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Santo_Domingo',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'East Timor',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        countryName: 'East Timor',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Tokyo Standard Time',
        nodaTimeZoneId: 'Asia/Dili',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Ecuador',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'Ecuador',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'Pacific/Galapagos',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Ecuador',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Guayaquil',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Egypt',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Cairo',
        countryName: 'Egypt',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Egypt Standard Time',
        nodaTimeZoneId: 'Africa/Cairo',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'El Salvador',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'El Salvador',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'America/El_Salvador',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Equatorial Guinea',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Equatorial Guinea',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Malabo',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Estonia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Estonia',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Tallinn',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Ethiopia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Ethiopia',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Addis_Ababa',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Falkland Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        countryName: 'Falkland Islands',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'SA Eastern Standard Time',
        nodaTimeZoneId: 'Atlantic/Stanley',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Fiji',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+13:00) Fiji',
        countryName: 'Fiji',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'Fiji Standard Time',
        nodaTimeZoneId: 'Pacific/Fiji',
        offset: {
          offsetSeconds: 46800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Finland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Finland',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Helsinki',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'France',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        countryName: 'France',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Romance Standard Time',
        nodaTimeZoneId: 'Europe/Paris',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'French Guiana',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        countryName: 'French Guiana',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'SA Eastern Standard Time',
        nodaTimeZoneId: 'America/Cayenne',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'French Polynesia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-10:00) Hawaii',
        countryName: 'French Polynesia',
        baseUtcOffset: '-10:00:00',
        windowsTimeZoneId: 'Hawaiian Standard Time',
        nodaTimeZoneId: 'Pacific/Tahiti',
        offset: {
          offsetSeconds: -36000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-09:30) Marquesas Islands',
        countryName: 'French Polynesia',
        baseUtcOffset: '-09:30:00',
        windowsTimeZoneId: 'Marquesas Standard Time',
        nodaTimeZoneId: 'Pacific/Marquesas',
        offset: {
          offsetSeconds: -34200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-09:00) Coordinated Universal Time-09',
        countryName: 'French Polynesia',
        baseUtcOffset: '-09:00:00',
        windowsTimeZoneId: 'UTC-09',
        nodaTimeZoneId: 'Pacific/Gambier',
        offset: {
          offsetSeconds: -32400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'French Southern & Antarctic Lands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'French Southern & Antarctic Lands',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Indian/Kerguelen',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Gabon',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Gabon',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Libreville',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Gambia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Gambia',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Banjul',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Georgia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Tbilisi',
        countryName: 'Georgia',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Georgian Standard Time',
        nodaTimeZoneId: 'Asia/Tbilisi',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Germany',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Germany',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Berlin',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Ghana',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Ghana',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Accra',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Gibraltar',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Gibraltar',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Gibraltar',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Greece',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Athens, Bucharest',
        countryName: 'Greece',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'GTB Standard Time',
        nodaTimeZoneId: 'Europe/Athens',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Greenland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Atlantic Time (Canada)',
        countryName: 'Greenland',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Atlantic Standard Time',
        nodaTimeZoneId: 'America/Thule',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-03:00) Greenland',
        countryName: 'Greenland',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'Greenland Standard Time',
        nodaTimeZoneId: 'America/Godthab',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-01:00) Azores',
        countryName: 'Greenland',
        baseUtcOffset: '-01:00:00',
        windowsTimeZoneId: 'Azores Standard Time',
        nodaTimeZoneId: 'America/Scoresbysund',
        offset: {
          offsetSeconds: -3600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+00:00)UTC',
        countryName: 'Greenland',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'UTC',
        nodaTimeZoneId: 'America/Danmarkshavn',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Grenada',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Grenada',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Grenada',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Guadeloupe',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Guadeloupe',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Guadeloupe',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Guam',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+10:00) Guam, Port Moresby',
        countryName: 'Guam',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'West Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Guam',
        offset: {
          offsetSeconds: 36000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Guatemala',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'Guatemala',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'America/Guatemala',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Guernsey',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Guernsey',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Europe/Guernsey',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Guinea',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Guinea',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Conakry',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Guinea-Bissau',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Guinea-Bissau',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Bissau',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Guyana',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Guyana',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Guyana',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Haiti',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Haiti',
        countryName: 'Haiti',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Haiti Standard Time',
        nodaTimeZoneId: 'America/Port-au-Prince',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Honduras',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'Honduras',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'America/Tegucigalpa',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Hong Kong',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        countryName: 'Hong Kong',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'China Standard Time',
        nodaTimeZoneId: 'Asia/Hong_Kong',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Hungary',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Hungary',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Budapest',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Iceland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Iceland',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Atlantic/Reykjavik',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Indonesia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        countryName: 'Indonesia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'SE Asia Standard Time',
        nodaTimeZoneId: 'Asia/Jakarta',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
        countryName: 'Indonesia',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Singapore Standard Time',
        nodaTimeZoneId: 'Asia/Makassar',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        countryName: 'Indonesia',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Tokyo Standard Time',
        nodaTimeZoneId: 'Asia/Jayapura',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Iran',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:30) Tehran',
        countryName: 'Iran',
        baseUtcOffset: '03:30:00',
        windowsTimeZoneId: 'Iran Standard Time',
        nodaTimeZoneId: 'Asia/Tehran',
        offset: {
          offsetSeconds: 12600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Iraq',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Baghdad',
        countryName: 'Iraq',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Arabic Standard Time',
        nodaTimeZoneId: 'Asia/Baghdad',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Ireland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Ireland',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Europe/Dublin',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Isle of Man',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Isle of Man',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Europe/Isle_of_Man',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Israel',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Jerusalem',
        countryName: 'Israel',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Israel Standard Time',
        nodaTimeZoneId: 'Asia/Jerusalem',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Italy',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Italy',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Rome',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Jamaica',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Jamaica',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Jamaica',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Japan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        countryName: 'Japan',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Tokyo Standard Time',
        nodaTimeZoneId: 'Asia/Tokyo',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Jersey',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Jersey',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Europe/Jersey',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Jordan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Amman',
        countryName: 'Jordan',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Jordan Standard Time',
        nodaTimeZoneId: 'Asia/Amman',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Kazakhstan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'Kazakhstan',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Asia/Aqtobe',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+06:00) Astana',
        countryName: 'Kazakhstan',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Central Asia Standard Time',
        nodaTimeZoneId: 'Asia/Almaty',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Kenya',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Kenya',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Nairobi',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Kiribati',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        countryName: 'Kiribati',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'UTC+12',
        nodaTimeZoneId: 'Pacific/Tarawa',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+13:00) Nuku\'alofa',
        countryName: 'Kiribati',
        baseUtcOffset: '13:00:00',
        windowsTimeZoneId: 'Tonga Standard Time',
        nodaTimeZoneId: 'Pacific/Enderbury',
        offset: {
          offsetSeconds: 46800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+14:00) Kiritimati Island',
        countryName: 'Kiribati',
        baseUtcOffset: '14:00:00',
        windowsTimeZoneId: 'Line Islands Standard Time',
        nodaTimeZoneId: 'Pacific/Kiritimati',
        offset: {
          offsetSeconds: 50400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Korea (North)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:30) Pyongyang',
        countryName: 'Korea (North)',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'North Korea Standard Time',
        nodaTimeZoneId: 'Asia/Pyongyang',
        offset: {
          offsetSeconds: 30600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Korea (South)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+09:00) Seoul',
        countryName: 'Korea (South)',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Korea Standard Time',
        nodaTimeZoneId: 'Asia/Seoul',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Kuwait',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Kuwait, Riyadh',
        countryName: 'Kuwait',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Arab Standard Time',
        nodaTimeZoneId: 'Asia/Kuwait',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Kyrgyzstan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+06:00) Astana',
        countryName: 'Kyrgyzstan',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Central Asia Standard Time',
        nodaTimeZoneId: 'Asia/Bishkek',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Laos',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        countryName: 'Laos',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'SE Asia Standard Time',
        nodaTimeZoneId: 'Asia/Vientiane',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Latvia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Latvia',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Riga',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Lebanon',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Beirut',
        countryName: 'Lebanon',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Middle East Standard Time',
        nodaTimeZoneId: 'Asia/Beirut',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Lesotho',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Lesotho',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Maseru',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Liberia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Liberia',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Monrovia',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Libya',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Tripoli',
        countryName: 'Libya',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Libya Standard Time',
        nodaTimeZoneId: 'Africa/Tripoli',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Liechtenstein',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Liechtenstein',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Vaduz',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Lithuania',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Lithuania',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Vilnius',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Luxembourg',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Luxembourg',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Luxembourg',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Macau',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        countryName: 'Macau',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'China Standard Time',
        nodaTimeZoneId: 'Asia/Macau',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Macedonia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        countryName: 'Macedonia',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central European Standard Time',
        nodaTimeZoneId: 'Europe/Skopje',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Madagascar',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Madagascar',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Indian/Antananarivo',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Malawi',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Malawi',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Blantyre',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Malaysia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
        countryName: 'Malaysia',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Singapore Standard Time',
        nodaTimeZoneId: 'Asia/Kuala_Lumpur',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Maldives',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'Maldives',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Indian/Maldives',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Mali',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Mali',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Bamako',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Malta',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Malta',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Malta',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Marshall Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        countryName: 'Marshall Islands',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'UTC+12',
        nodaTimeZoneId: 'Pacific/Majuro',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Martinique',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Martinique',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Martinique',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Mauritania',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Mauritania',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Nouakchott',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Mauritius',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Port Louis',
        countryName: 'Mauritius',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Mauritius Standard Time',
        nodaTimeZoneId: 'Indian/Mauritius',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Mayotte',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Mayotte',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Indian/Mayotte',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Mexico',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-08:00) Baja California',
        countryName: 'Mexico',
        baseUtcOffset: '-08:00:00',
        windowsTimeZoneId: 'Pacific Standard Time (Mexico)',
        nodaTimeZoneId: 'America/Tijuana',
        offset: {
          offsetSeconds: -28800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
        countryName: 'Mexico',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'Mountain Standard Time (Mexico)',
        nodaTimeZoneId: 'America/Mazatlan',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Mountain Time (US & Canada)',
        countryName: 'Mexico',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'Mountain Standard Time',
        nodaTimeZoneId: 'America/Ojinaga',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Arizona',
        countryName: 'Mexico',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'US Mountain Standard Time',
        nodaTimeZoneId: 'America/Hermosillo',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
        countryName: 'Mexico',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central Standard Time (Mexico)',
        nodaTimeZoneId: 'America/Mexico_City',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-06:00) Central Time (US & Canada)',
        countryName: 'Mexico',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central Standard Time',
        nodaTimeZoneId: 'America/Matamoros',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-05:00) Chetumal',
        countryName: 'Mexico',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Eastern Standard Time (Mexico)',
        nodaTimeZoneId: 'America/Cancun',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Micronesia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        countryName: 'Micronesia',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Central Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Kosrae',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Moldova',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Chisinau',
        countryName: 'Moldova',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'E. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Chisinau',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Monaco',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Monaco',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Monaco',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Mongolia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+07:00) Hovd',
        countryName: 'Mongolia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'W. Mongolia Standard Time',
        nodaTimeZoneId: 'Asia/Hovd',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+08:00) Ulaanbaatar',
        countryName: 'Mongolia',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Ulaanbaatar Standard Time',
        nodaTimeZoneId: 'Asia/Ulaanbaatar',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Montenegro',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Montenegro',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Podgorica',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Montserrat',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Montserrat',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Montserrat',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Morocco',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Casablanca',
        countryName: 'Morocco',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Morocco Standard Time',
        nodaTimeZoneId: 'Africa/Casablanca',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Mozambique',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Mozambique',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Maputo',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Namibia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Windhoek',
        countryName: 'Namibia',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Namibia Standard Time',
        nodaTimeZoneId: 'Africa/Windhoek',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Nauru',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        countryName: 'Nauru',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'UTC+12',
        nodaTimeZoneId: 'Pacific/Nauru',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Netherlands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Netherlands',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Amsterdam',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'New Caledonia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        countryName: 'New Caledonia',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Central Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Noumea',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'New Zealand',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+13:00) Auckland, Wellington',
        countryName: 'New Zealand',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'New Zealand Standard Time',
        nodaTimeZoneId: 'Pacific/Auckland',
        offset: {
          offsetSeconds: 46800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+13:45) Chatham Islands',
        countryName: 'New Zealand',
        baseUtcOffset: '12:45:00',
        windowsTimeZoneId: 'Chatham Islands Standard Time',
        nodaTimeZoneId: 'Pacific/Chatham',
        offset: {
          offsetSeconds: 49500
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Nicaragua',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-06:00) Central America',
        countryName: 'Nicaragua',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central America Standard Time',
        nodaTimeZoneId: 'America/Managua',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Niger',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Niger',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Niamey',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Nigeria',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Nigeria',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Lagos',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Niue',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-11:00) Coordinated Universal Time-11',
        countryName: 'Niue',
        baseUtcOffset: '-11:00:00',
        windowsTimeZoneId: 'UTC-11',
        nodaTimeZoneId: 'Pacific/Niue',
        offset: {
          offsetSeconds: -39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Norfolk Island',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+11:00) Norfolk Island',
        countryName: 'Norfolk Island',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Norfolk Standard Time',
        nodaTimeZoneId: 'Pacific/Norfolk',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Northern Mariana Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+10:00) Guam, Port Moresby',
        countryName: 'Northern Mariana Islands',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'West Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Saipan',
        offset: {
          offsetSeconds: 36000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Norway',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Norway',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Oslo',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Oman',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Abu Dhabi, Muscat',
        countryName: 'Oman',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Arabian Standard Time',
        nodaTimeZoneId: 'Asia/Muscat',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Pakistan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Islamabad, Karachi',
        countryName: 'Pakistan',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'Pakistan Standard Time',
        nodaTimeZoneId: 'Asia/Karachi',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Palau',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        countryName: 'Palau',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Tokyo Standard Time',
        nodaTimeZoneId: 'Pacific/Palau',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Palestine',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Gaza, Hebron',
        countryName: 'Palestine',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'West Bank Standard Time',
        nodaTimeZoneId: 'Asia/Gaza',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Panama',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Panama',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Panama',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Papua New Guinea',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+10:00) Guam, Port Moresby',
        countryName: 'Papua New Guinea',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'West Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Port_Moresby',
        offset: {
          offsetSeconds: 36000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+11:00) Bougainville Island',
        countryName: 'Papua New Guinea',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Bougainville Standard Time',
        nodaTimeZoneId: 'Pacific/Bougainville',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Paraguay',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Asuncion',
        countryName: 'Paraguay',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Paraguay Standard Time',
        nodaTimeZoneId: 'America/Asuncion',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Peru',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
        countryName: 'Peru',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'SA Pacific Standard Time',
        nodaTimeZoneId: 'America/Lima',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Philippines',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
        countryName: 'Philippines',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Singapore Standard Time',
        nodaTimeZoneId: 'Asia/Manila',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Pitcairn',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-08:00) Coordinated Universal Time-08',
        countryName: 'Pitcairn',
        baseUtcOffset: '-08:00:00',
        windowsTimeZoneId: 'UTC-08',
        nodaTimeZoneId: 'Pacific/Pitcairn',
        offset: {
          offsetSeconds: -28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Poland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        countryName: 'Poland',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central European Standard Time',
        nodaTimeZoneId: 'Europe/Warsaw',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Portugal',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-01:00) Azores',
        countryName: 'Portugal',
        baseUtcOffset: '-01:00:00',
        windowsTimeZoneId: 'Azores Standard Time',
        nodaTimeZoneId: 'Atlantic/Azores',
        offset: {
          offsetSeconds: -3600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Portugal',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Europe/Lisbon',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Puerto Rico',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Puerto Rico',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Puerto_Rico',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Qatar',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Kuwait, Riyadh',
        countryName: 'Qatar',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Arab Standard Time',
        nodaTimeZoneId: 'Asia/Qatar',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Réunion',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Port Louis',
        countryName: 'Réunion',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Mauritius Standard Time',
        nodaTimeZoneId: 'Indian/Reunion',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Romania',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Athens, Bucharest',
        countryName: 'Romania',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'GTB Standard Time',
        nodaTimeZoneId: 'Europe/Bucharest',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Russia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Kaliningrad',
        countryName: 'Russia',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Kaliningrad Standard Time',
        nodaTimeZoneId: 'Europe/Kaliningrad',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
        countryName: 'Russia',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Russian Standard Time',
        nodaTimeZoneId: 'Europe/Moscow',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+04:00) Astrakhan, Ulyanovsk',
        countryName: 'Russia',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Astrakhan Standard Time',
        nodaTimeZoneId: 'Europe/Astrakhan',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+04:00) Izhevsk, Samara',
        countryName: 'Russia',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Russia Time Zone 3',
        nodaTimeZoneId: 'Europe/Samara',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+05:00) Ekaterinburg',
        countryName: 'Russia',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'Ekaterinburg Standard Time',
        nodaTimeZoneId: 'Asia/Yekaterinburg',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+06:00) Omsk',
        countryName: 'Russia',
        baseUtcOffset: '06:00:00',
        windowsTimeZoneId: 'Omsk Standard Time',
        nodaTimeZoneId: 'Asia/Omsk',
        offset: {
          offsetSeconds: 21600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+07:00) Novosibirsk',
        countryName: 'Russia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'N. Central Asia Standard Time',
        nodaTimeZoneId: 'Asia/Novosibirsk',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+07:00) Barnaul, Gorno-Altaysk',
        countryName: 'Russia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'Altai Standard Time',
        nodaTimeZoneId: 'Asia/Barnaul',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+07:00) Tomsk',
        countryName: 'Russia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'Tomsk Standard Time',
        nodaTimeZoneId: 'Asia/Tomsk',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+07:00) Krasnoyarsk',
        countryName: 'Russia',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'North Asia Standard Time',
        nodaTimeZoneId: 'Asia/Novokuznetsk',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+08:00) Irkutsk',
        countryName: 'Russia',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'North Asia East Standard Time',
        nodaTimeZoneId: 'Asia/Irkutsk',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+09:00) Chita',
        countryName: 'Russia',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Transbaikal Standard Time',
        nodaTimeZoneId: 'Asia/Chita',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+09:00) Yakutsk',
        countryName: 'Russia',
        baseUtcOffset: '09:00:00',
        windowsTimeZoneId: 'Yakutsk Standard Time',
        nodaTimeZoneId: 'Asia/Yakutsk',
        offset: {
          offsetSeconds: 32400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+10:00) Vladivostok',
        countryName: 'Russia',
        baseUtcOffset: '10:00:00',
        windowsTimeZoneId: 'Vladivostok Standard Time',
        nodaTimeZoneId: 'Asia/Vladivostok',
        offset: {
          offsetSeconds: 36000
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+11:00) Magadan',
        countryName: 'Russia',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Magadan Standard Time',
        nodaTimeZoneId: 'Asia/Magadan',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+11:00) Sakhalin',
        countryName: 'Russia',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Sakhalin Standard Time',
        nodaTimeZoneId: 'Asia/Sakhalin',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+11:00) Chokurdakh',
        countryName: 'Russia',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Russia Time Zone 10',
        nodaTimeZoneId: 'Asia/Srednekolymsk',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
        countryName: 'Russia',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'Russia Time Zone 11',
        nodaTimeZoneId: 'Asia/Kamchatka',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Rwanda',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Rwanda',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Kigali',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Samoa (American)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-11:00) Coordinated Universal Time-11',
        countryName: 'Samoa (American)',
        baseUtcOffset: '-11:00:00',
        windowsTimeZoneId: 'UTC-11',
        nodaTimeZoneId: 'Pacific/Pago_Pago',
        offset: {
          offsetSeconds: -39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Samoa (western)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+14:00) Samoa',
        countryName: 'Samoa (western)',
        baseUtcOffset: '13:00:00',
        windowsTimeZoneId: 'Samoa Standard Time',
        nodaTimeZoneId: 'Pacific/Apia',
        offset: {
          offsetSeconds: 50400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'San Marino',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'San Marino',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/San_Marino',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Sao Tome & Principe',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Sao Tome & Principe',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Sao_Tome',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Saudi Arabia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Kuwait, Riyadh',
        countryName: 'Saudi Arabia',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Arab Standard Time',
        nodaTimeZoneId: 'Asia/Riyadh',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Senegal',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Senegal',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Dakar',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Serbia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Serbia',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Belgrade',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Seychelles',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Port Louis',
        countryName: 'Seychelles',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Mauritius Standard Time',
        nodaTimeZoneId: 'Indian/Mahe',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Sierra Leone',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Sierra Leone',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Freetown',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Singapore',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
        countryName: 'Singapore',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Singapore Standard Time',
        nodaTimeZoneId: 'Asia/Singapore',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Slovakia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Slovakia',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Bratislava',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Slovenia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        countryName: 'Slovenia',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Central Europe Standard Time',
        nodaTimeZoneId: 'Europe/Ljubljana',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Solomon Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        countryName: 'Solomon Islands',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Central Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Guadalcanal',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Somalia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Somalia',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Mogadishu',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'South Africa',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'South Africa',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Johannesburg',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'South Georgia & the South Sandwich Islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-02:00) Coordinated Universal Time-02',
        countryName: 'South Georgia & the South Sandwich Islands',
        baseUtcOffset: '-02:00:00',
        windowsTimeZoneId: 'UTC-02',
        nodaTimeZoneId: 'Atlantic/South_Georgia',
        offset: {
          offsetSeconds: -7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'South Sudan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'South Sudan',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Juba',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Spain',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
        countryName: 'Spain',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'GMT Standard Time',
        nodaTimeZoneId: 'Atlantic/Canary',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        countryName: 'Spain',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'Romance Standard Time',
        nodaTimeZoneId: 'Europe/Madrid',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Sri Lanka',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:30) Sri Jayawardenepura',
        countryName: 'Sri Lanka',
        baseUtcOffset: '05:30:00',
        windowsTimeZoneId: 'Sri Lanka Standard Time',
        nodaTimeZoneId: 'Asia/Colombo',
        offset: {
          offsetSeconds: 19800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Barthelemy',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'St Barthelemy',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/St_Barthelemy',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Helena',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'St Helena',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Atlantic/St_Helena',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Kitts & Nevis',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'St Kitts & Nevis',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/St_Kitts',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Lucia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'St Lucia',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/St_Lucia',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Maarten (Dutch)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'St Maarten (Dutch)',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Lower_Princes',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Martin (French)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'St Martin (French)',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Marigot',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'St Pierre & Miquelon',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Saint Pierre and Miquelon',
        countryName: 'St Pierre & Miquelon',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'Saint Pierre Standard Time',
        nodaTimeZoneId: 'America/Miquelon',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'St Vincent',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'St Vincent',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/St_Vincent',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Sudan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Nairobi',
        countryName: 'Sudan',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Khartoum',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Suriname',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
        countryName: 'Suriname',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'SA Eastern Standard Time',
        nodaTimeZoneId: 'America/Paramaribo',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Svalbard & Jan Mayen',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Svalbard & Jan Mayen',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Arctic/Longyearbyen',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Swaziland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Swaziland',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Mbabane',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Sweden',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Sweden',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Stockholm',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Switzerland',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Switzerland',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Zurich',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Syria',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Damascus',
        countryName: 'Syria',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'Syria Standard Time',
        nodaTimeZoneId: 'Asia/Damascus',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Taiwan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+08:00) Taipei',
        countryName: 'Taiwan',
        baseUtcOffset: '08:00:00',
        windowsTimeZoneId: 'Taipei Standard Time',
        nodaTimeZoneId: 'Asia/Taipei',
        offset: {
          offsetSeconds: 28800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Tajikistan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'Tajikistan',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Asia/Dushanbe',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Tanzania',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Tanzania',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Dar_es_Salaam',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Thailand',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        countryName: 'Thailand',
        baseUtcOffset: '07:00:00',
        windowsTimeZoneId: 'SE Asia Standard Time',
        nodaTimeZoneId: 'Asia/Bangkok',
        offset: {
          offsetSeconds: 25200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Togo',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
        countryName: 'Togo',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Greenwich Standard Time',
        nodaTimeZoneId: 'Africa/Lome',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Tokelau',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+13:00) Nuku\'alofa',
        countryName: 'Tokelau',
        baseUtcOffset: '13:00:00',
        windowsTimeZoneId: 'Tonga Standard Time',
        nodaTimeZoneId: 'Pacific/Fakaofo',
        offset: {
          offsetSeconds: 46800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Tonga',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+13:00) Nuku\'alofa',
        countryName: 'Tonga',
        baseUtcOffset: '13:00:00',
        windowsTimeZoneId: 'Tonga Standard Time',
        nodaTimeZoneId: 'Pacific/Tongatapu',
        offset: {
          offsetSeconds: 46800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Trinidad & Tobago',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Trinidad & Tobago',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Port_of_Spain',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Tunisia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) West Central Africa',
        countryName: 'Tunisia',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Central Africa Standard Time',
        nodaTimeZoneId: 'Africa/Tunis',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Turkey',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Istanbul',
        countryName: 'Turkey',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Turkey Standard Time',
        nodaTimeZoneId: 'Europe/Istanbul',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Turkmenistan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'Turkmenistan',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Asia/Ashgabat',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Turks & Caicos Is',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-05:00) Turks and Caicos',
        countryName: 'Turks & Caicos Is',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Turks And Caicos Standard Time',
        nodaTimeZoneId: 'America/Grand_Turk',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Tuvalu',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        countryName: 'Tuvalu',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'UTC+12',
        nodaTimeZoneId: 'Pacific/Funafuti',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Uganda',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Nairobi',
        countryName: 'Uganda',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'E. Africa Standard Time',
        nodaTimeZoneId: 'Africa/Kampala',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Ukraine',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        countryName: 'Ukraine',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'FLE Standard Time',
        nodaTimeZoneId: 'Europe/Kiev',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'United Arab Emirates',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+04:00) Abu Dhabi, Muscat',
        countryName: 'United Arab Emirates',
        baseUtcOffset: '04:00:00',
        windowsTimeZoneId: 'Arabian Standard Time',
        nodaTimeZoneId: 'Asia/Dubai',
        offset: {
          offsetSeconds: 14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'United States',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-10:00) Aleutian Islands',
        countryName: 'United States',
        baseUtcOffset: '-10:00:00',
        windowsTimeZoneId: 'Aleutian Standard Time',
        nodaTimeZoneId: 'America/Adak',
        offset: {
          offsetSeconds: -36000
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-10:00) Hawaii',
        countryName: 'United States',
        baseUtcOffset: '-10:00:00',
        windowsTimeZoneId: 'Hawaiian Standard Time',
        nodaTimeZoneId: 'Pacific/Honolulu',
        offset: {
          offsetSeconds: -36000
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-09:00) Alaska',
        countryName: 'United States',
        baseUtcOffset: '-09:00:00',
        windowsTimeZoneId: 'Alaskan Standard Time',
        nodaTimeZoneId: 'America/Anchorage',
        offset: {
          offsetSeconds: -32400
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-08:00) Pacific Time (US & Canada)',
        countryName: 'United States',
        baseUtcOffset: '-08:00:00',
        windowsTimeZoneId: 'Pacific Standard Time',
        nodaTimeZoneId: 'America/Los_Angeles',
        offset: {
          offsetSeconds: -28800
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Mountain Time (US & Canada)',
        countryName: 'United States',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'Mountain Standard Time',
        nodaTimeZoneId: 'America/Denver',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-07:00) Arizona',
        countryName: 'United States',
        baseUtcOffset: '-07:00:00',
        windowsTimeZoneId: 'US Mountain Standard Time',
        nodaTimeZoneId: 'America/Phoenix',
        offset: {
          offsetSeconds: -25200
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC-06:00) Central Time (US & Canada)',
        countryName: 'United States',
        baseUtcOffset: '-06:00:00',
        windowsTimeZoneId: 'Central Standard Time',
        nodaTimeZoneId: 'America/Chicago',
        offset: {
          offsetSeconds: -21600
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-05:00) Eastern Time (US & Canada)',
        countryName: 'United States',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'Eastern Standard Time',
        nodaTimeZoneId: 'America/New_York',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      },
      {
        displayName: '(UTC-05:00) Indiana (East)',
        countryName: 'United States',
        baseUtcOffset: '-05:00:00',
        windowsTimeZoneId: 'US Eastern Standard Time',
        nodaTimeZoneId: 'America/Indiana/Marengo',
        offset: {
          offsetSeconds: -18000
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Uruguay',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-03:00) Montevideo',
        countryName: 'Uruguay',
        baseUtcOffset: '-03:00:00',
        windowsTimeZoneId: 'Montevideo Standard Time',
        nodaTimeZoneId: 'America/Montevideo',
        offset: {
          offsetSeconds: -10800
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'US minor outlying islands',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-11:00) Coordinated Universal Time-11',
        countryName: 'US minor outlying islands',
        baseUtcOffset: '-11:00:00',
        windowsTimeZoneId: 'UTC-11',
        nodaTimeZoneId: 'Pacific/Midway',
        offset: {
          offsetSeconds: -39600
        },
        supportsDaylightSavingTime: false
      },
      {
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        countryName: 'US minor outlying islands',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'UTC+12',
        nodaTimeZoneId: 'Pacific/Wake',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Uzbekistan',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
        countryName: 'Uzbekistan',
        baseUtcOffset: '05:00:00',
        windowsTimeZoneId: 'West Asia Standard Time',
        nodaTimeZoneId: 'Asia/Samarkand',
        offset: {
          offsetSeconds: 18000
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Vanuatu',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
        countryName: 'Vanuatu',
        baseUtcOffset: '11:00:00',
        windowsTimeZoneId: 'Central Pacific Standard Time',
        nodaTimeZoneId: 'Pacific/Efate',
        offset: {
          offsetSeconds: 39600
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Vatican City',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        countryName: 'Vatican City',
        baseUtcOffset: '01:00:00',
        windowsTimeZoneId: 'W. Europe Standard Time',
        nodaTimeZoneId: 'Europe/Vatican',
        offset: {
          offsetSeconds: 3600
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Venezuela',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Caracas',
        countryName: 'Venezuela',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'Venezuela Standard Time',
        nodaTimeZoneId: 'America/Caracas',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Virgin Islands (UK)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Virgin Islands (UK)',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/Tortola',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Virgin Islands (US)',
    timeZoneDescriptors: [
      {
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        countryName: 'Virgin Islands (US)',
        baseUtcOffset: '-04:00:00',
        windowsTimeZoneId: 'SA Western Standard Time',
        nodaTimeZoneId: 'America/St_Thomas',
        offset: {
          offsetSeconds: -14400
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Wallis & Futuna',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
        countryName: 'Wallis & Futuna',
        baseUtcOffset: '12:00:00',
        windowsTimeZoneId: 'UTC+12',
        nodaTimeZoneId: 'Pacific/Wallis',
        offset: {
          offsetSeconds: 43200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Western Sahara',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+00:00) Casablanca',
        countryName: 'Western Sahara',
        baseUtcOffset: '00:00:00',
        windowsTimeZoneId: 'Morocco Standard Time',
        nodaTimeZoneId: 'Africa/El_Aaiun',
        offset: {
          offsetSeconds: 0
        },
        supportsDaylightSavingTime: true
      }
    ]
  },
  {
    displayName: 'Yemen',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+03:00) Kuwait, Riyadh',
        countryName: 'Yemen',
        baseUtcOffset: '03:00:00',
        windowsTimeZoneId: 'Arab Standard Time',
        nodaTimeZoneId: 'Asia/Aden',
        offset: {
          offsetSeconds: 10800
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Zambia',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Zambia',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Lusaka',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  },
  {
    displayName: 'Zimbabwe',
    timeZoneDescriptors: [
      {
        displayName: '(UTC+02:00) Harare, Pretoria',
        countryName: 'Zimbabwe',
        baseUtcOffset: '02:00:00',
        windowsTimeZoneId: 'South Africa Standard Time',
        nodaTimeZoneId: 'Africa/Harare',
        offset: {
          offsetSeconds: 7200
        },
        supportsDaylightSavingTime: false
      }
    ]
  }
];


const zones = TIME_ZONE_FAMILIES;
const descriptors = zones.flatMap(fam => fam.timeZoneDescriptors);
const zonesIds = descriptors.map(d => d.nodaTimeZoneId);

export const TIME_ZONES_LIST = zonesIds;


export function findTimezoneOrigin(timezoneId: string): {country: string, timezone: string, windowsTimeZoneId: string} {
   
   const tzf = TIME_ZONE_FAMILIES.find( x => x.timeZoneDescriptors.some(y => y.nodaTimeZoneId === timezoneId));

   if (isNullOrUndefined(tzf)) {
      return { country: 'N/A', timezone: 'N/A', windowsTimeZoneId: 'Universal Coordinated Time' };
   }

   const tzd = tzf.timeZoneDescriptors.find(x => x.nodaTimeZoneId === timezoneId);

   return {
      country: tzd.countryName,
      timezone: tzd.displayName,
      windowsTimeZoneId: tzd.windowsTimeZoneId
   };
}
