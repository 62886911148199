import { NgModule } from '@angular/core';
import { TerminalExitComponent } from './terminal-exit.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule
   ],
   exports: [TerminalExitComponent],
   declarations: [TerminalExitComponent],
   providers: []
})
export class EtsTerminalExitModule { }
