import { Component } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { getNewReportGridModel } from './new-report-games-grid-model';
import { FlagshipReportBase } from '../flagship/flagship-report-base.class';


@Component({
  selector: 'ets-new-flagship-report',
  templateUrl: 'new-flagship-report.component.html',
  styleUrls: ['new-flagship-report.component.scss'],
})
export class NewFlagshipReportComponent extends FlagshipReportBase {
  getReportGridModel(): GridOptions {
    return getNewReportGridModel.bind(this)();
  }
}
