import { StrategyMessagesComponent } from './strategy-messages.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { InformationCategory } from '../information-category.enum';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { StrategyLogMessageDto } from 'projects/shared-components/shell-communication/dtos/strategy-log-message-dto.class';
import { IANAZone } from 'luxon';

const timeZonesCache: { [ix: string]: IANAZone } = {};

function getTimeZone(tzCode: string): IANAZone {
   if (!(tzCode in timeZonesCache)) {
      const z = IANAZone.create(tzCode);
      timeZonesCache[tzCode] = z;
   }
   return timeZonesCache[tzCode];
}

export function getStrategyMessagesGridModel(this: StrategyMessagesComponent): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Timestamp',
            field: 'timestamp',
            valueGetter: (params: ValueGetterParams) => {
               const data = params.data as StrategyLogMessageDto;
               if (!data || !data.timestamp) {
                  return null;
               }
               return this.timestampsService.getZonedDateTime(data.timestamp.getMilliseconds());
            },
            valueFormatter: (params: CellClassParams) => {
               const msg: StrategyLogMessageDto = params.data;
               const frmtTime = this.timestampsService.getFormattedDateTimeForStrategy(msg.strategyId, msg.timestamp);
               return msg.timestamp
                  ? frmtTime
                  : '';
            },
            width: 160
         },
         {
            headerName: 'Category',
            field: 'category',
            valueFormatter: (params: ValueFormatterParams) => {
               return InformationCategory[params.value];
            },
            width: 120,
            filter: true
         },
         {
            headerName: 'Message',
            field: 'messageText',
            headerComponentParams: {},
            cellStyle: { 'text-align': 'left' },
            width: 600,
            filter: 'agTextColumnFilter',
            flex: 1
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      onGridReady: args => this.onGridReady(args),

      getRowNodeId: (rowData: StrategyLogMessageDto) => rowData.messageId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Load More ...',
               disabled: !this.contextStrategyId,
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadMoreMessages(50)
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreMessages(100)
                  },
                  {
                     name: '500',
                     action: () => this.loadMoreMessages(500)
                  },
                  {
                     name: '1000',
                     action: () => this.loadMoreMessages(1000)
                  }
               ]
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.api.sizeColumnsToFit();
      },

      onRowDoubleClicked: (args) => {
         this.selectedMessage = args.data;
         this.isDetailsWindowVisible = true;
      }
   } as GridOptions;
}
