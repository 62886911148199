import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { 
  TradingInstrumentDisplayNameService,  
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { defaultCurrencyFormatter } from 'projects/shared-components/utils';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
// tslint:disable-next-line: component-selector
  selector: 'floating-cell',
  template: `
    <div class="wrapper">
      <div class="block">
        <span class="group-title">{{groupTitle}}</span>
      </div>
      <div class="block action-panel">
        <span>PnL:
          <span [style.color]="accumulatedTotalPnLColor">
            {{ accumulatedTotalPnL }}
          </span>
        </span>
      </div>
    </div>
  `,
  styles: [
      `
      .wrapper {
        display: inline-block;
        width: 100%;
        text-align: left;
      }

      .block {
        display: inline-block;
      }

      .group-title {
        color: yellow;
      }

      .action-panel {
        position: absolute;
        right: 1px;
      }

      .action-panel > span {
        font-weight: bold;
        padding-right: 5px;
      }

      .go-flat-button {
        margin-top: -3px;
      }
    `
  ]
})
// tslint:disable-next-line: component-class-suffix
export class ArchivedPositionsGroupRowInnerRenderer implements ICellRendererAngularComp {
  constructor(
    private readonly _tickerFriendlyNameService: TradingInstrumentDisplayNameService
  ) {
  }

  initParams: ICellRendererParams;

  get groupTitle(): string {
    let title = 'N/A';

    if (this.initParams.node.field === 'tradingInstrumentCode' ||
        this.initParams.node.field === 'ticker') {
      title = this._tickerFriendlyNameService.getDisplayNameForTicker(
        this.initParams.node.key
      );
    } else if (this.initParams.node.field === 'shellId') {
      const value = (this.initParams.node.key as string);
      return value ? value.split('^')[0] : 'N/A';
    } else {
      title = this.initParams.node.key;
    }
    return title;
  }

  get sessionTotalPnLValue(): number {
    return this.initParams.node.aggData.sessionTotalPnL || 0;
  }

  get accumulatedTotalPnLValue(): number {
    return this.initParams.node.aggData.accumulatedTotalPnL || 0;
  }

  get sessionTotalPnLColor(): string {
    const value = this.sessionTotalPnLValue;
    if (value === 0) {
      return null;
    }
    return value > 0 ? 'green' : ' red';
  }

  get accumulatedTotalPnLColor(): string {
    const value = this.accumulatedTotalPnLValue;
    if (value === 0) {
      return null;
    }
    return value > 0 ? 'green' : ' red';
  }

  get sessionTotalPnL(): string {
    return defaultCurrencyFormatter(this.sessionTotalPnLValue);
  }

  get accumulatedTotalPnL(): string {
    return defaultCurrencyFormatter(this.accumulatedTotalPnLValue);
  }

  agInit(params: ICellRendererParams): void {
    this.initParams = params;    
  }

  refresh(): boolean {
    return false;
  }
}
