<div class="view-container" [class.ets-hidden]="!this.isActive">

   <ag-grid-angular class="ag-theme-balham-dark" [popupParent]="contextPopupParent"
         [gridOptions]="quoteBoardGridModel" (dblclick)="this.onGridDoubleClick()"></ag-grid-angular>

</div>

<ets-symbol-picker-dialog  [isVisible]="isSymbolPickerVisible" 
                           (closed)="this.onSymbolPickerClosed()"
                           (instrumentSelected)="onInstrumentSelected($event)">
</ets-symbol-picker-dialog>