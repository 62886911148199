import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DevExtremeModule } from '../devextreme.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';

import { ConvertToMarketComponent } from './convert-to-market/convert-to-market.component';

@NgModule({
   imports: [CommonModule, DevExtremeModule, DateTimePickerModule, TimezonePickerModule],
   exports: [ConvertToMarketComponent],
   declarations: [ConvertToMarketComponent],
   providers: [],
})
export class OrderExtensionsModule { }
