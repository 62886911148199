<dx-popup
  width="300"
  height="300"
  [showTitle]="true"
  title="Select Terminal"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onHidden)="onHidden()"
  (onShown)="onShown()"
>
  <div *dxTemplate="let data of 'content'" class="alerts-container">
    <div style="width: 100%; height: 90%; position: relative">
      <dx-list
        [dataSource]="availableTerminals"
        [selectionMode]="'single'"
        keyExpr="terminalId"
        [(selectedItemKeys)]="selectedTerminals">
        <div *dxTemplate="let item of 'item'">
          {{item.displayName}}
        </div>
      </dx-list>
      <dx-button width="100" text="Allocate"
                 style="float: left"
                 type="default"
                 (onClick)="allocate()"
      ></dx-button>
      <dx-button width="100"
                 text="Close"
                 style="float: right" type="normal"
                 (onClick)="isVisible = false"
      ></dx-button>
    </div>
    <dx-load-panel [visible]="isLoading" style="margin: auto"></dx-load-panel>
  </div>
</dx-popup>