import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from '../utils';
import { CashFlowStrategySettingsModel } from '../adjustment-pricing-grid/settings-section/cash-flow-strategy-settings/model/CashFlowStrategySettingsModel';
import { ApgDefaultsService } from './apg-defaults.service';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'ets-apg-defaults-dialog',
   templateUrl: './apg-defaults-dialog.component.html',
   styleUrls: [
      './apg-defaults-dialog.component.scss',
      '../adjustment-pricing-grid/settings-section/adjustment-grid-parameters-style.scss',
      '../adjustment-pricing-grid/adjustment-grid-common-style.scss',
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApgDefaultsDialogComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _apgDefaultsService: ApgDefaultsService,
      private _toastr: ToastrService
   ) { }


   title = 'APG Defaults';


   private _isLoading : boolean;
   public get isLoading() : boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   public set isLoading(v : boolean) {
      this._isLoading = v;
   }


   private _isVisible : boolean;
   public get isVisible() : boolean {
      return this._isVisible;
   }
   @DetectSetterChanges()
   public set isVisible(v : boolean) {
      this._isVisible = v;
   }

   //
   settingsFirst: CashFlowStrategySettingsModel;

   //
   settingsSecond: CashFlowStrategySettingsModel;

   //
   tradingInstrument: TradingInstrument;

   //
   ngOnInit(): void {

       const ctx = {
           getSelectedTemplate: () => {},
           isOperationInProgress: () => false
       } as any;

      const modelFirst = new CashFlowStrategySettingsModel(
         ctx,
         true
      );
      modelFirst.parameterChanged$.subscribe( (_: any) => this._changeDetector.detectChanges());

      this.settingsFirst = modelFirst;

      const modelSecond = new CashFlowStrategySettingsModel(
         ctx,
         true
      );
      modelSecond.parameterChanged$.subscribe( (_: any) => this._changeDetector.detectChanges());

      this.settingsSecond = modelSecond;
   }

   //
   onShown() {
      this.isLoading = true;
      try {
         this.applyDefaults();
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   onHidden() {
      this.isVisible = false;
   }

   //
   onTradingInstrumentSelected(event: TradingInstrument) {
      this.tradingInstrument = event;
      this.applyDefaults();
   }

   //
   async onSaveClicked(): Promise<void> {

      const errorFirst = this.settingsFirst.validate({shouldRollDates: true});

      if (!isVoid(errorFirst)) {
         this._toastr.error(errorFirst, 'Calls (HP, rHP)');
         return;
      }

      const errorSecond = this.settingsSecond.validate({shouldRollDates: true});

      if (!isVoid(errorSecond)) {
         this._toastr.error(errorSecond, 'Puts');
         return;
      }


      const settingsFirst = this.settingsFirst.getSettings();
      const settingsSecond = this.settingsSecond.getSettings();

      const settings = [settingsFirst, settingsSecond];

      try {
         this.isLoading = true;
         await this._apgDefaultsService.save(settings, this.tradingInstrument);
      } catch(e) {
         this._toastr.error('"Save APG Default Settings" operation completed with errors');
      } finally {
         this.isLoading = false;
      }

   }

   //
   @DetectMethodChanges()
   onCloseClicked() {
      this.onHidden();
   }

   //
   @DetectMethodChanges()
   private applyDefaults() {

      let defaults = this._apgDefaultsService.get(this.tradingInstrument);

      if(!isVoid(defaults)) {
         console.assert(defaults.length == 2, 'defaults.length == 2');
         this.settingsFirst.applySettings(defaults[0]);
         this.settingsSecond.applySettings(defaults[1]);
      } else {
         this.settingsFirst.reset();
         this.settingsSecond.reset();
      }

   }
}
