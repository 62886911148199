export class PnLChartSubscription {

  constructor(subscriptionId?: string, displayName?: string) {
    this.subscriptionId = subscriptionId;
    this.displayName = displayName;
    this.terminals = [];
    this.accounts = [];
    this.strategies = [];
    this.tradingInstruments = [];
  }

  subscriptionId: string;
  isAccumulated: boolean;
  displayName: string;
  terminals: string[];
  accounts: string[];
  strategies: string[];
  tradingInstruments: string[];
}
