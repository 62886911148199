// tslint:disable: no-string-literal

import { Injectable } from '@angular/core';
import { TelemetryEvent } from './telementry-event.class';
import { environment } from '../environments/environment';
import { SessionService } from '../authentication/session-service.service';
import { TelemetryBackendClientService } from './telemetry-client.service';
import { LogEvent } from './telemetry-service.protocol';

@Injectable({ providedIn: 'root' })
export class TelemetryService {
   private readonly _events: TelemetryEvent[] = [];
   private _workerRef: number;

   constructor(
      private readonly _backendClient: TelemetryBackendClientService,
      private _sessionService: SessionService
   ) { }

   init() {
      return;
      
      const handler: TimerHandler = () => this._sendEventsToBackend();
      this._workerRef = setInterval(handler, 1500);
   }

   log(message: string, extraData?: object) {
      return;

      const dataObj = {
         runtimeInstanceId: environment.runtimeInstanceId,
         message,
         payload: extraData
      };


      const serializedData = JSON.stringify(dataObj);

      const event: TelemetryEvent = {
         timestamp: new Date(),
         appId: environment.runtimeAppId,
         appVersion: environment.productVersion,
         authToken: this._sessionService.authToken,
         eventType: 'log',
         data: serializedData
      };

      this._events.push(event);
   }

   private async _sendEventsToBackend(): Promise<void> {
      return;

      if (this._events.length === 0) {
         return;
      }

      const events = this._events.slice();

      this._events.length = 0;

      try {
         const cmd = new LogEvent(events);
         this._backendClient.processCommand(cmd);
      } catch (error) {
         console.error(error);
      }
   }
}
