<table class="table" *ngIf="this.notEmpty">
  
  <thead class="table-header">
    <tr class="header-row">
      <th class="col header current">Current</th>
      <th class="col header current-px">Px</th>
      <th class="col header new">New</th>
      <th class="col header new-px">Px</th>
      <th class="col header trans">Trans</th>
    </tr>
  </thead>
  
  <tbody class="table-body">
    <tr *ngFor="let row of this.transactions" class="row">
      <td class="col current">
        {{ row.beforeState?.label }}
      </td>
      <td class="col current-px" [ngClass]="this.getPriceClass(this.getGroupTotal(row.beforeState))">
        {{ this.getGroupTotal(row.beforeState) | currency }}
      </td>
      <td class="col new">
        {{row.afterState?.label}}
      </td>
      <td class="col new-px" [ngClass]="this.getPriceClass(this.getGroupTotal(row.afterState))">
        {{ this.getGroupTotal(row.afterState) | currency }}
      </td>
      <td class="col trans" [ngClass]="this.getPriceClass(this.getGroupTransTotal(row))">
        {{( this.getGroupTransTotal(row)) | currency }}
      </td>
    </tr>
  </tbody>
  
  <tfoot class="table-footer">
  <tr>
    <td class="col current">
      Total
    </td>
    <td class="col current-px" [ngClass]="this.getPriceClass(this.getSubTotals('current'))">
      {{ this.getSubTotals('current')  | currency }}
    </td>
    <td class="col new">
      &nbsp;
    </td>
    <td class="col new-px" [ngClass]="this.getPriceClass(this.getSubTotals('new'))">
      {{ this.getSubTotals('new') | currency }}
    </td>
    <td class="col trans" (click)="this.showTransactionPopup()"
        [ngClass]="this.getPriceClass(this.getGrandTotal())"
    >
      {{ this.getGrandTotal() | currency }}
    </td>
  </tr>
  </tfoot>
  
</table>

<ets-hg-transaction-popup>
</ets-hg-transaction-popup>