import { Component, OnInit } from '@angular/core';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { ToastrService } from 'ngx-toastr';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { Subject } from 'rxjs';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { takeUntil } from 'rxjs/operators';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { CustomExitOrderDescriptor } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { PlaceCustomExitOrders, GetCustomExitNpoTotalQtyForStrategy } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';

class CustomExitDescriptor {
  private _usePrice = false;
  private _usePoints = false;

  isEnabled = false;
  type: 'npo' | 'sl';
  lastPx: number;
  qty?: number;
  price?: number;
  points?: number;

  get usePrice(): boolean {
    return this._usePrice;
  }
  set usePrice(val: boolean) {
    this._usePrice = val;
    if (val) {
      this.usePoints = false;
    } else {
      this.price = null;
    }
  }

  get usePoints(): boolean {
    return this._usePoints;
  }

  set usePoints(val: boolean) {
    this._usePoints = val;
    if (val) {
      this.usePrice = false;
    } else {
      this.points = null;
    }
  }
}

@Component({
  selector: 'ets-custom-exits',
  templateUrl: './custom-exits.component.html',
  styleUrls: ['./custom-exits.component.scss']
})
export class CustomExitsComponent implements OnInit {
  constructor(
    private readonly _toastr: ToastrService,
    private readonly _tiService: TradingInstrumentsService,
    private readonly _tiDisplayName: TradingInstrumentDisplayNameService,
    private readonly _messageBus: MessageBusService,
    private readonly _lastQuoteCache: LastQuoteCacheService,
    private readonly _shellClient: ShellClientService,
  ) { }

  private _totalNpoQty = 0;
  private _unsubscriber: Subject<void>;
  private _strategy: StrategyModel;
  private _useNpoOrder = false;
  private _useStopLossOrder = false;


  isLoading = false;
  isVisible = false;
  npoOrder: CustomExitDescriptor;
  slOrder: CustomExitDescriptor;
  tradingInstrument: TradingInstrument;

  get useNpoOrder(): boolean {
    return this._useNpoOrder;
  }
  set useNpoOrder(value: boolean) {
    if (value) {
      const npoOrder = new CustomExitDescriptor();
      const quote = this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker);
      if (quote) {
        npoOrder.lastPx = quote.lastPx;
      }
      npoOrder.qty =  Math.abs(this._strategy.positions.getNetPosition()) - this._totalNpoQty;
      npoOrder.type = 'npo';
      this.npoOrder = npoOrder;
    } else {
      this.npoOrder = null;
    }

    this._useNpoOrder = value;
  }

  get useStopLossOrder(): boolean {
    return this._useStopLossOrder;
  }
  set useStopLossOrder(value: boolean) {    
    if (value) {
      const slOrder = new CustomExitDescriptor();
      const quote = this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker);
      if (quote) {
        slOrder.lastPx = quote.lastPx;
      }
      slOrder.qty =  Math.abs(this._strategy.positions.getNetPosition());
      slOrder.type = 'sl';
      this.slOrder = slOrder;
    } else {
      this.slOrder = null;
    }

    this._useStopLossOrder = value;
  }

  get isNpoPriceValid(): boolean {
    if (!this._strategy) {
      return true;
    }

    if (!this.npoOrder) {
      return true;
    }

    if (!this.npoOrder.price) {
      return true;
    }    

    let netPos = this._strategy.positions.getNetPosition();
    
    if (isNaN(netPos)) {
      netPos = 0;
    }
    
    if (netPos === 0) {
      return false;
    }

    return netPos > 0 ? this.npoOrder.price > this.npoOrder.lastPx :  this.npoOrder.price < this.npoOrder.lastPx;
  }

  
  get isSlPriceValid(): boolean {
    if (!this._strategy) {
      return true;
    }

    if (!this.slOrder) {
      return true;
    }

    if (!this.slOrder.price) {
      return true;
    }    

    let netPos = this._strategy.positions.getNetPosition();
    
    if (isNaN(netPos)) {
      netPos = 0;
    }
    
    if (netPos === 0) {
      return false;
    }

    return netPos > 0 ? this.slOrder.price < this.slOrder.lastPx : this.slOrder.price > this.slOrder.lastPx;
  }

  ngOnInit() { }

  async show(strategy: StrategyModel) {
    if (!strategy) {
      this._toastr.error('Strategy Not Selected');
      return;
    }

    if (strategy.positions.getNetPosition() === 0) {
      this._toastr.error('Selected strategy has no open position!');
      return;
    }

    const tis = strategy.getTradingInstruments();

    if (tis.length === 0) {
      this._toastr.error('Strategy Has No Trading Instrument');
      return;
    }

    try {
      this._strategy = strategy;
      
      this.isLoading = true;

      const qry = new GetCustomExitNpoTotalQtyForStrategy(this._strategy.strategyId);
      const promise = this._shellClient.processQuery<number>(qry);

      this.tradingInstrument = this._tiService.getInstrumentByTicker(tis[0]);
      this._lastQuoteCache.subscribeTicker(this.tradingInstrument.ticker);

      this._unsubscriber = new Subject();
      
      this._messageBus.of<QuoteDto[]>('QuoteDto')
        .pipe(takeUntil(this._unsubscriber))
        .subscribe( msg => this._onQuote(msg.payload));

      this._totalNpoQty = await promise;

      this.isVisible = true; 
    } catch (e)  {
      console.error(e);
      this._toastr.error('Error occured when showing "Custom Exits" dialog');
    } finally {
      this.isLoading = false;
    }
  }

  get buyOrSell(): string {
    const np = this._strategy.positions.getNetPosition();
    if (np === 0) {
      return 'Flat';
    }
    if (np > 0) {
      return 'Sell';
    }
    return 'Buy';
  }

  onHidden() {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
    }
    
    this.isVisible = false;
    
    this.npoOrder = null;
    this.slOrder = null;
    this.tradingInstrument = null;
    this.useNpoOrder = false;
    this.useStopLossOrder = false;
  }

  canPlaceOrders(): boolean {
    function checkOrder(order: CustomExitDescriptor) {
      if (!order.qty) { 
        if (order.type !== 'sl') {
          return false;
        }
      }

      if (!order.price && !order.points) {
        return false;
      }

      return true;
    }

    let result = false;
    if (this.npoOrder) {
      result = checkOrder(this.npoOrder);
    }

    if (this.slOrder) {
      result = checkOrder(this.slOrder);
    }

    return result;
  }

  async placeOrders() {
    if (!this.canPlaceOrders()) {
      this._toastr.error('Please fill all necessary order fields!');
      return;
    }

    let npoDescriptor: CustomExitOrderDescriptor = null;
    if (this.npoOrder) {
      npoDescriptor =  {
        qty: this.npoOrder.qty,
        price: this.npoOrder.price,
        points: this.npoOrder.points
      };
    }

    let slDescriptor: CustomExitOrderDescriptor = null;
    if (this.slOrder) {
      slDescriptor = {
        qty: this.slOrder.qty,
        price: this.slOrder.price,
        points: this.slOrder.points
      };  
    }

    const cmd = new PlaceCustomExitOrders(
      this._strategy.strategyId,
      this.tradingInstrument.ticker,
      npoDescriptor,
      slDescriptor
    );

    try {
      await this._shellClient.processCommand(cmd);
    } catch (e) {
      this._toastr.error('"Place Custom Exit Orders" operation completed with errors');
    } finally {
      this.onHidden();
    }
  }

  onSlOrderPriceCheckboxChanged(ev) {
    if (this.slOrder) {
      this.slOrder.usePrice = ev.value;
      if (ev.value) {
        const quote = this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker);
        if (quote) {
          this.slOrder.price  = quote.lastPx;
        }
      }
    }
  }

  onNpoOrderPriceCheckboxChanged(ev) {
    if (this.npoOrder) {
      this.npoOrder.usePrice = ev.value;
      if (ev.value) {
        const quote = this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker);
        if (quote) {
          this.npoOrder.price  = quote.lastPx;
        }
      }
    }
  }

  private _onQuote(quotes: QuoteDto[]): void {
    if (!this.npoOrder && !this.slOrder) {
      return;
    }

    quotes.forEach(quote => {
      if (this.tradingInstrument.ticker !== quote.ticker) {
        return;
      }

      if (this.npoOrder) {  
        this.npoOrder.lastPx = quote.lastPx;
      }

      if (this.slOrder) {
        this.slOrder.lastPx = quote.lastPx;
      }
    });
  }
}
