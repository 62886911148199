import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';

import { PositionOverrideComponent } from './position-override.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule
   ],
   exports: [PositionOverrideComponent],
   declarations: [PositionOverrideComponent],
   providers: [],
})
export class PositionOverrideModule { }
