<div class="container" #container [ngClass]="{'ets-hidden': !isActive}">
  <hr />
  <div *ngFor="let dataset of datasets; index as ix" class="dataset">
    <span class="last-update">{{dataset.lastUpdateTime | date:'dd/MM/yyyy hh:mm:ss a'}} (Shell)</span>
    <span class="label">Dataset #{{ix+1}}: </span>
    <span *ngFor="let dsItem of dataset.datasetItems; last as isLast">
      <span class="dsItem"   [ngClass]="{
                                          'active': dsItem.isActive, 
                                          'completed': dsItem.isCompleted,
                                          'pending': !dsItem.isActive && !dsItem.isCompleted
                                        }">
        <span *ngIf="dsItem.isCompleted">(</span>{{dsItem.item}}<span *ngIf="dsItem.isCompleted">)</span>
      </span>
      <span *ngIf="!isLast">&nbsp;-&nbsp;</span>
    </span>
  </div>
  <dx-load-panel [position]="{ my: 'center', at: 'center', of: container }" [visible]="loading"></dx-load-panel>
</div>