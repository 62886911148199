import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AutomationCpMessageBusService } from '../../services/automation-cp-message-bus.service';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { DetectMethodChanges, DxValueChanged, delay, getAvailableCashFlowStrategies, isVoid } from 'projects/shared-components/utils';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { AutomationCpMode } from '../../model/AutomationCpMode';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { ComboDto, GetCombosForAutomationCpReply, GetPortfoliosForAutomationCpReply, GetTerminalsForAutomationCpReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { GetCombosForAutomationCp, GetPortfoliosForAutomationCp, GetTerminalsForAutomationCp } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ShellConnectionStatusChangedUIMessage } from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
   selector: 'ets-automation-cp-header-bucket-selector',
   templateUrl: './bucket-selector.component.html',
   styleUrls: ['./bucket-selector.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class BucketSelectorComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _cpMessageBus: AutomationCpMessageBusService,
      private readonly _accessControlSvc: AccessControlService,
      private readonly _sessionService: SessionService,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _messageBus: MessageBusService
   ) { }

   private _unsubscriber = new Subject();

   //
   strategiesSelectorLabel = 'Strategies';

   //
   modeSelectorLabel = 'Mode';

   //
   terminalSelectorLabel = 'Terminal';

   //
   portfolioSelectorLabel = 'Portfolio';

   //
   comboSelectorLabel = 'Combo';

   //
   modes: AutomationCpMode[];

   //
   selectedMode: AutomationCpMode;

   //
   get isModeSelected(): boolean {
      return !isVoid(this.selectedMode);
   }

   //
   strategies: CashFlowStrategy[];
   
   //
   selectedStrategy: CashFlowStrategy;

   //
   get isStrategySelected(): boolean {
      return !isVoid(this.selectedStrategy);
   }

   //
   get canSelectStrategy(): boolean {
      return this.isModeSelected;
   }

   //
   terminals: TerminalDto[];

   //
   selectedTerminal: TerminalDto;

   //
   get isTerminalSelected(): boolean {
      return !isVoid(this.selectedTerminal);
   }

   //
   get canSelectTerminal(): boolean {
      return !isVoid(this.selectedStrategy);
   }

   //
   portfolios: PortfolioDto[];

   //
   selectedPortfolio: PortfolioDto;

   //
   get isPortfolioSelected(): boolean {
      return !isVoid(this.selectedPortfolio);
   }

   //
   get canSelectPortfolio(): boolean {
      return this.isTerminalSelected;
   }

   //
   combos: ComboDto[];

   //
   selectedCombo: ComboDto;

   //
   get isComboSelected(): boolean {
      return !isVoid(this.selectedCombo);
   }

   //
   get canSelectCombo(): boolean {
      return this.isPortfolioSelected;
   }

   //
   ngOnInit(): void {

      this.strategies = getAvailableCashFlowStrategies(
         this._accessControlSvc
      );

      this.modes = ['Automated'];

      // emulating combo change to force reload data after certain events
      this._messageBus.of('ClearTradingDataUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(x => {
            this.emulateComboReload();
         });

      this._messageBus.of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(x => x.payload.isConnected)
         ).subscribe(x => {
            this.emulateComboReload();
         });
      
      setTimeout(() => {
         this.selectedMode = this.modes[0];
         this._changeDetector.detectChanges();
      }, 0);
   }

   //
   @DetectMethodChanges()
   onModeChanged(args: DxValueChanged<AutomationCpMode>) {

      this.selectedStrategy = null;
      this.selectedTerminal = null;
      this.selectedPortfolio = null;
      this.selectedCombo = null;

      this._cpMessageBus.publish('ModeChanged', args.value);
   
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onStrategyChanged(args: DxValueChanged<CashFlowStrategy>) {

      if (isVoid(args.event)) {
         return;
      }

      this.selectedTerminal = null;
      this.selectedPortfolio = null;
      this.selectedCombo = null;

      if (!this.isStrategySelected) {
         return;
      }
      
      try {

         this._cpMessageBus.publish('Loading', true);

         this._cpMessageBus.publish('StrategyChanged', args.value);
   
         const qry = new GetTerminalsForAutomationCp(
            this.selectedMode,
            this.selectedStrategy,
         );

         const reply = await this._shellClient
            .processQuery<GetTerminalsForAutomationCpReply>(qry);
   
         this.terminals = reply.terminals;

         if (this.terminals.length === 1)
         {
            setTimeout(() => {
               this.selectedTerminal = this.terminals[0];
               this.onTerminalChanged({value: this.selectedTerminal, event: 'ets'});
            }, 0);
         }

      } finally {

         this._cpMessageBus.publish('Loading', false);

      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onTerminalChanged(args: DxValueChanged<TerminalDto>) {

      if (isVoid(args.event)) {
         return;
      }
      
      this.selectedPortfolio = null;
      this.selectedCombo = null;
      
      if (!this.isTerminalSelected) {
         return;
      }
      
      this._cpMessageBus.publish('Loading', true);
      
      try {

         const qry = new GetPortfoliosForAutomationCp(
            this.selectedMode,
            this.selectedStrategy,
            this.selectedTerminal.terminalId
         );
   
         const reply = await this._shellClient
            .processQuery<GetPortfoliosForAutomationCpReply>(qry);
   
         this.portfolios = reply.portfolios;

         if (this.portfolios.length === 1)
         {
            setTimeout(() => {
               this.selectedPortfolio = this.portfolios[0];
               this.onPortfolioChanged({value: this.selectedPortfolio, event: 'ets'});
            }, 0);
         }


      } finally {

         this._cpMessageBus.publish('Loading', false);

      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onPortfolioChanged(args: DxValueChanged<PortfolioDto>) {

      if (isVoid(args.event)) {
         return;
      }
      
      this.selectedCombo = null;
      
      if (!this.isPortfolioSelected) {
         return;
      }

      this._cpMessageBus.publish('Loading', true);

      try {

         const qry = new GetCombosForAutomationCp(
            this.selectedMode,
            this.selectedStrategy,
            this.selectedPortfolio.portfolioId
         );

         const reply = await this._shellClient
            .processQuery<GetCombosForAutomationCpReply>(qry);
   
         this.combos = reply.combos;

         if (this.combos.length === 1)
         {
            setTimeout(() => {
               this.selectedCombo = this.combos[0];
               this.onComboChanged({value: this.selectedCombo, event: 'ets'});
            }, 0);
         }


      } finally {

         this._cpMessageBus.publish('Loading', false);

      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onComboChanged(args: DxValueChanged<ComboDto>) {

      try {
         this._cpMessageBus.publish('Loading', true);
         this._cpMessageBus.publish('ComboChanged', args.value);
      } catch(e) {
         console.error(e);
         this._toastr.error('"Load Combo Data" operation completed with errors');
      } finally {
         this._cpMessageBus.publish('Loading', false);
      }

   }

   private async emulateComboReload() {
      const combo = this.selectedCombo;
      this.selectedCombo = null;
      await this.onComboChanged({value: null});
      setTimeout(async () => {
         this.selectedCombo = combo;
         await this.onComboChanged({value: null});
      }, 0);
   }
}
 