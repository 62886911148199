import { GetContextMenuItemsParams, GridOptions, CellClassParams } from 'ag-grid-community';
import {
   centeredColumnDef,
   defaultLoadingOverlayTemplate,
   defaultMoneyCellDefinition
} from 'projects/shared-components/ag-grid-contrib';
import { PositionSizingReportComponent } from './position-sizing-report.component';


export function getEtalonGridModel(this: PositionSizingReportComponent): GridOptions {
   return {
      rowData: [],

      defaultColDef: Object.assign({ filter: true }, centeredColumnDef),

      columnDefs: [
         Object.assign(
            {
               headerName: 'Increase Lvl',
               field: 'increaseLevel',
            },
            defaultMoneyCellDefinition
         ),
         {
            headerName: 'Unit Lvl',
            field: 'unitsToTrade',
            sortable: false,
         },
         {
           headerName: 'Multiplier',
           field: 'multiplier',
         },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: false,

      onGridReady: args => this.onEtalonGridReady(args),

      getRowNodeId: (rowData) => {
         return rowData.recordId;
      },

      getRowStyle: (args) => {
         const data = args.data;
         if (!data) {
            return null;
         }
         return data.switchLevel ? { background: 'cyan', color: 'black' } : null;
      },

      onFirstDataRendered: (args) => args.api.sizeColumnsToFit(),

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: this.ethalonMode === 'Main' ? 'To "Alternative" Mode' : 'To "Main" Mode',
               action: () => setTimeout(() => this.changeEthalonMode(), 0),
               disabled: !this.selectedStrategyHasSwitchTo,
            },
            'separator',
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}
