<dx-popup
  (onHidden)="this.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [showCloseButton]="false"
  [showTitle]="true"
  [visible]="this.visible"
  height="325"
  title="Zones Grid Settings"
  width="180"
>
  <div *dxTemplate="let data of 'content'">
    <div class="container">
      
      <div class="row inputs">
        
        <div class="setting">
          <p class="label">Range (up)</p>
          <div class="input">
            <dx-number-box
              [(value)]="this.range"
              [min]="1"
              [showClearButton]="true"
              [showSpinButtons]="true"
            >
            </dx-number-box>
          </div>
        </div>
        
        <div class="setting">
          <p class="label">Step</p>
          <div class="input">
            <dx-number-box
              [(value)]="this.step"
              [min]="1"
              [showClearButton]="true"
              [showSpinButtons]="true"
            >
            </dx-number-box>
          </div>
        </div>
        
        <div class="setting">
          <p class="label">Range (down)</p>
          <div class="input">
            <dx-number-box
              [(value)]="this.rangeDown"
              [min]="1" [showClearButton]="true"
              [showSpinButtons]="true"
            >
            </dx-number-box>
          </div>
        </div>
      
      </div>
      
      <div class="row">
        <hr>
      </div>
      
      <div class="row buttons">
        
        <div class="button">
          <dx-button (onClick)="this.onApply()" text="Apply" width="100%">
          </dx-button>
        </div>
        <div class="button">
          <dx-button (onClick)="this.onCancel()" text="Cancel" width="100%">
          </dx-button>
        </div>
      </div>
    
    </div>
  </div>
</dx-popup>