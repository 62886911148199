import { Injectable } from '@angular/core';
import {environment} from "./environments/environment";

@Injectable({
   providedIn: 'root'
})
export class LocationService {
   get isEtsHost(): boolean {
      let isEtsHost = location.hostname === 'localhost' || location.hostname.indexOf('evergreen') >= 0;

      if (environment.production) {
         return isEtsHost;
      }

      return false;
   }
}
