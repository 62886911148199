import { Injectable } from '@angular/core';
import { EtsConstants } from '../ets-constants.const';
import { AccessControlService } from '../access-control-service.class';
import { environment } from '../environments/environment';

const DASHBOARD = EtsConstants.companyServices.etsDashboardApplicationId;
const WEBTRADER = EtsConstants.companyServices.etsWebTraderApplicationId;


@Injectable()
export class StrategyOrdersAndTradesSecurityContextService {
  constructor(
    private readonly _accessControl: AccessControlService
  ) { }
  
  get requestOrderStatus(): boolean {
    if (environment.runtimeAppId === DASHBOARD) {
      return this._accessControl.isSecureElementAvailable('0d1b1330-4514-4dcb-85f9-e435cae0f351');
    }
    if (environment.runtimeAppId === WEBTRADER) {
      return this._accessControl.isSecureElementAvailable('60b61640-133f-4e4e-8c78-308e4b8f3880');
    }
    return false;
  }

  get cancelCustomExitOrder(): boolean {
    if (environment.runtimeAppId === DASHBOARD) {
      return false;
    }
    if (environment.runtimeAppId === WEBTRADER) {
      return this._accessControl.isSecureElementAvailable('b1435927-05c1-4d7d-b3d0-d6ffc13dab83');
    }
    return false;
  } 
}
