import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent } from 'rxjs';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { takeUntil } from 'rxjs/operators';
import { ILogger } from 'ag-grid-community/dist/lib/iLogger';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { DetectMethodChanges } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-terminal-exit',
   templateUrl: 'terminal-exit.component.html',
   styleUrls: ['terminal-exit.component.scss'],
   providers: [
      { provide: 'WINDOW', useValue: window }
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class TerminalExitComponent implements OnInit, AfterViewInit {
   constructor(
      @Inject('WINDOW') private _window: any,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _sessionService: SessionService,
      private _changeDetector: ChangeDetectorRef,
      loggerService: LoggerService
   ) {
      this._unsubscriber = new Subject<any>();
      this._logger = loggerService.createLogger('TerminalExitComponent');
   }

   public overlayPosition: { left: number; top: number } = {
      left: 0,
      top: 0
   };

   public isOverlayVisible = false;
   public availableTerminals: TerminalDto[] = [];
   @Output() terminalExitRequest: EventEmitter<TerminalDto> = new EventEmitter<TerminalDto>();

   private _unsubscriber: Subject<any>;
   private _logger: Logger;
   private _selfEl: HTMLElement;

   ngOnInit() {
      fromEvent(this._window, 'resize')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(args => this.onWindowResized(args));

      this.availableTerminals = this._sessionService.loginResult.availableTerminals
         .filter(x => !x.isProxy)
         .sort((a, b) => {
            return a.displayName.localeCompare(b.displayName);
         });
   }

   @DetectMethodChanges()
   public onActionButtonClick($event: any): void {
      const selfEl = this._selfEl;
      if (!selfEl) {
         return;
      }

      const horizontalPosition = selfEl.offsetLeft - 60;

      if (!this.isOverlayVisible) {
         this.overlayPosition = {
            left: horizontalPosition,
            top: 51
         };
      }

      this.isOverlayVisible = !this.isOverlayVisible;
   }

   public ngAfterViewInit(): void {
      const el: HTMLElement = document.querySelector('#terminal-exit-action-button');
      if (el) {
         this._selfEl = el;
      }
   }

   @DetectMethodChanges()
   exitTerminal(term: TerminalDto) {
      this.terminalExitRequest.emit(term);
      this.isOverlayVisible = false;
   }

   @DetectMethodChanges()
   private onWindowResized(args: any) {
      const el: HTMLElement = this._selfEl;
      if (!el) {
         return;
      }
      this.overlayPosition = {
         left: el.offsetLeft - 60,
         top: el.offsetHeight
      };
   }
}
