<div class="hedges-totals">
  
  <div class="calls section">
    <div class="label">Calls Totals</div>
    <div class="value" [ngClass]="this.getPriceClass({price: this.callsPrice})">
      {{this.callsPrice|currency}}
    </div>
  </div>
  
  <div class="puts section">
    <div class="label">Puts Totals</div>
    <div class="value" [ngClass]="this.getPriceClass({price: this.putsPrice})">
      {{this.putsPrice|currency}}
    </div>
  </div>
  
  <div class="total section">
    <div class="label">Grand Total</div>
    <div class="value" [ngClass]="this.getPriceClass({price: this.totalPrice})">
      {{this.totalPrice | currency}}
    </div>
  </div>


</div>