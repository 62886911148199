<div class="panel-container" #positionsGrid [ngClass]="{'ets-hidden': !isActive}">
   <div *ngIf="this.hideClosedPositions" class="gradient">&nbsp;</div>
   <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="gridOptions" #portfolioItemsGrid></ag-grid-angular>
</div>

<ets-move-to-portfolio-dialog></ets-move-to-portfolio-dialog>

<ets-position-adjust [position]="{ my: 'center', at: 'center', of: '#portfolioItemsGrid' }"></ets-position-adjust>

<ets-position-override></ets-position-override>

<dx-popup width="65%" height="60%" [showTitle]="true" title="Expiration Dialog" [closeOnOutsideClick]="false"
      [resizeEnabled]="true" [dragEnabled]="true" [visible]="expirationDialog?.isVisible"
      (onHidden)="onExpirationDialogHidden()" (onShown)="onExpirationDialogShown()">
   <div *dxTemplate="let data of 'content'" class="expiration-dialog" #popup>
      <div class="grid">
         <ag-grid-angular class="ag-theme-balham-dark" 
                  [gridOptions]="this.expirationDialog.gridOptions"></ag-grid-angular>
      </div>

      <div class="action-btns">
         <dx-button text="Expire" (onClick)="this.expirationDialogOnExpireBtnClicked()"></dx-button>
         <dx-button text="Close" (onClick)="this.onExpirationDialogHidden()"></dx-button>
      </div>

      <dx-load-panel [visible]="this.expirationDialog.isLoading" [position]="{my: 'center', at: 'center', of: popup}"></dx-load-panel>
   </div>

</dx-popup>

<ets-adjustment-strategy-dialog></ets-adjustment-strategy-dialog>


<!-- Clear Trading Data Confirmation -->
<dx-popup [width]="400"
          [height]="200"
          title="Confirm Clearing Trading Data"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="false"
          [visible]="this.clearTradingDataConfirmPopupSettings.isVisible"
          (onHidden)="this.onClearTradingDataConfirmPopupClosed()">
  <div *dxTemplate="let data of 'content'" style="position: relative">
    <div>
      <p class="ctd-confirm">Are you sure you want to clear trading data for the strategy(-ies)?</p>
    </div>
    <div class="ctd-confirm-dialog-buttons">
      <dx-button class="ctd-confirm-dialog-button yes" text="Yes" (onClick)="onClearTradingDataForStrategyConfirmed()"
        type="danger"
      ></dx-button>
      <dx-button class="ctd-confirm-dialog-button no" text="No"
                 (onClick)="onClearTradingDataConfirmPopupClosed()"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup   [width]="400"
            [height]="460"
            title="Add Interest Record"
            [showTitle]="true"
            [closeOnOutsideClick]="false"
            [resizeEnabled]="false"
            [dragEnabled]="false"
            (onHidden)="this.interestRecordDialog_onHidden()"
            [(visible)]="this.interestRecordDialogConfig.isVisible">
   <div *dxTemplate="let data of 'content'" class="interest-record" #interestRecord>
      <ets-interest-calculator-strategy [manualMode]="true" [parameters]="this.interestRecordDialogConfig.parameters">
      </ets-interest-calculator-strategy>
      <hr>
      <div class="buttons">
         <div class="btn save">
            <dx-button width="100%" text="Save" (onClick)="this.interestRecordDialog_onSave()"></dx-button>
         </div>
         <div class="btn cancel">
            <dx-button width="100%" text="Cancel" (onClick)="this.interestRecordDialog_onCancel()"></dx-button>
         </div>
      </div>
      <dx-load-panel [visible]="this.interestRecordDialogConfig.isLoading" 
                           [position]="{my: 'center', at: 'center', of: interestRecord}"></dx-load-panel>
   </div>
</dx-popup>

<!-- Target Strike Dialog -->
<dx-popup width="435" 
   [height]="this.targetStrikeDialog.popupHeight" 
   [showTitle]="true" title="Target Strike Dialog" 
   [closeOnOutsideClick]="false"
   [resizeEnabled]="true" 
   [dragEnabled]="true" 
   (onHidden)="this.targetStrikeDialog.onHidden()"
   [visible]="this.targetStrikeDialog.isVisible">
   <div *dxTemplate="let data of 'content'" class="roll-leg-dialog">

      <!-- Original Leg -->
      <div class="row original">
         <div class="col label">
            Original Leg
         </div>
         <div class="col input">
            {{this.targetStrikeDialog.originalLeg}}
         </div>
      </div>

      <!-- Strike -->
      <div class="row stike">
         
         <div class="col label">
            Target Strike
         </div>

         <div class="col input">
            <ets-strikes-dropdown 
               [targetInstrument]="this.targetStrikeDialog.tradingInstrument"
               [expiration]="this.targetStrikeDialog.expirationDescriptor" 
               [(strike)]="this.targetStrikeDialog.strike"
               [(atmStrike)]="this.targetStrikeDialog.atmStrike"
               [showAtm]="true"
               (strikeChanged)="this.targetStrikeDialog.onStrikeChanged($event)"
               (atmStrikeChanged)="this.targetStrikeDialog.onAtmStrikeChanged($event)"
               [hidden]="!this.targetStrikeDialog.isVisible">
            </ets-strikes-dropdown>            
         </div>
      </div>

      <!-- Quotes -->
      <div class="row quotes" *ngIf="this.targetStrikeDialog.showQuotes">

         <fieldset>
            <legend>Quotes</legend>
            
            <!-- Header -->
            <div class="row header">
               <div class="col label">&nbsp;</div>
               <div class="col input">
                  <div class="quote bid">
                     <div class="label">BID</div>
                  </div>
                  <div class="quote mid">
                     <div class="label">MID</div>
                  </div>
                  <div class="quote ask">
                     <div class="label">ASK</div>
                  </div>
               </div>
            </div>
            
            <!-- Original -->
            <div class="row">
               <div class="col label">Original</div>
               <div class="col input">
                  <div class="quote bid">
                     <div class="value">{{this.targetStrikeDialog.bidQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
                  <div class="quote mid">
                     <div class="value">{{this.targetStrikeDialog.midQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
                  <div class="quote ask">
                     <div class="value">{{this.targetStrikeDialog.askQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
               </div>
            </div>

            <!-- Desired -->
            <div class="row">
               <div class="col label">Desired</div>
               <div class="col input">
                  <div class="quote bid">
                     <div class="value">{{this.targetStrikeDialog.bidQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
                  <div class="quote mid">
                     <div class="value">{{this.targetStrikeDialog.midQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
                  <div class="quote ask">
                     <div class="value">{{this.targetStrikeDialog.askQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
               </div>
            </div>

            <!-- Total -->
            <div class="row total">
               <div class="col label">&nbsp;</div>
               <div class="col input">
                  <div class="quote bid">
                     <div (click)="this.targetStrikeDialog.setLimitPrice(this.targetStrikeDialog.bidQuoteTotal)" class="value">{{this.targetStrikeDialog.bidQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
                  <div class="quote mid">
                     <div (click)="this.targetStrikeDialog.setLimitPrice(this.targetStrikeDialog.midQuoteTotal)" class="value">{{this.targetStrikeDialog.midQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
                  <div class="quote ask">
                     <div (click)="this.targetStrikeDialog.setLimitPrice(this.targetStrikeDialog.askQuoteTotal)" class="value">{{this.targetStrikeDialog.askQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                  </div>
               </div>
            </div>
         </fieldset>

      </div>

      <!-- Order Type -->
      <div class="row order-type">
         <div class="col label">
            Order Type
         </div>
         <div class="col input">
            <dx-select-box [items]="this.targetStrikeDialog.orderTypeList"
                  valueExpr="value"
                  displayExpr="key"
                  [(value)]="this.targetStrikeDialog.selectedOrderType">
            </dx-select-box>
         </div>
      </div>

      <!-- Order Price -->
      <div class="row price" *ngIf="this.targetStrikeDialog.selectedOrderType !== 2">
         
         <div class="limit-price" *ngIf="this.targetStrikeDialog.selectedOrderType === 1">
            <div class="col label">Limit Px.</div>
            <div class="col input">
               <dx-number-box [min]="0.01" [(value)]="this.targetStrikeDialog.limitPrice" [showSpinButtons]="true" 
                              [showClearButton]="true"
                              [format]="{ style: 'currency', currency: 'USD' }" [inputAttr]="this.targetStrikeDialog.limitPriceColor">
               </dx-number-box>
            </div>
         </div>

         <div class="auto-price" *ngIf="this.targetStrikeDialog.selectedOrderType === 17">
            <div class="col label">
               Auto Px.
            </div>
            <div class="col input">
               <dx-select-box [items]="['Bid', 'Mid', 'Ask']" [(value)]="this.targetStrikeDialog.autoLimitPrice"></dx-select-box>
            </div>
         </div>

      </div>

      <!-- Future Settings -->
      <div class="row future-settings">
         
         <div class="col label">
            <dx-check-box text="Future Placement" [(value)]="this.targetStrikeDialog.futurePlacement"></dx-check-box>
         </div>
         
         <div class="col input" style="margin-top: 5px;">
            <div *ngIf="this.targetStrikeDialog.futurePlacement">
               <div>
                  <dx-select-box 
                                 [items]="['Place At', 'Place After']"
                                 [showClearButton]="true"
                                 [(value)]="this.targetStrikeDialog.futureTimeSettings.actionTimeMode"
                                 (onValueChanged)="this.targetStrikeDialog.onChange($event)"
                                 placholder="Place Mode" #place></dx-select-box>
               </div>
                        
               <div>
                  <ets-datetime-picker
                              [contextWord]="this.targetStrikeDialog.futureTimeSettings.actionTimeMode"
                              [disabled]="!place.value"
                              [mode]="this.targetStrikeDialog.getDateTimePickerMode('future')"
                              [(value)]="this.targetStrikeDialog.futureTimeSettings.actionTime"
                  ></ets-datetime-picker>
               </div>

               <div>
                  <ets-timezone-picker
                     *ngIf="this.targetStrikeDialog.futureTimeSettings.actionTimeMode === 'Place At'"
                     [(timezone)]="this.targetStrikeDialog.futureTimeSettings.timezone"
                     [flow]="'col'"></ets-timezone-picker>
               </div>
            </div>
         </div>
   
      </div>

      <!-- Convert To Market -->
      <div class="row to-market">
         
         <div class="col label">
            <dx-check-box text="Convert To Market" [disabled]="!this.targetStrikeDialog.canConvertToMarket" [(value)]="this.targetStrikeDialog.convertToMarket"></dx-check-box>
         </div>

         <div class="col input">
            <div *ngIf="this.targetStrikeDialog.convertToMarket">
               <div style="margin-top: 5px;">
                  <dx-select-box [disabled]="!this.targetStrikeDialog.convertToMarket"
                                 [items]="['Convert At', 'Convert After']"
                                 [(value)]="this.targetStrikeDialog.convertToMarketSettings.actionTimeMode"
                                 (onValueChanged)="this.targetStrikeDialog.onChange()"
                                 [showClearButton]="true"
                                 placholder="Convert Mode"></dx-select-box>
               </div>
               <div style="margin-top: 5px;">
                  <ets-datetime-picker [contextWord]="this.targetStrikeDialog.convertToMarketSettings.actionTimeMode"
                                       [mode]="this.targetStrikeDialog.getDateTimePickerMode('convert')"
                                       [(value)]="this.targetStrikeDialog.convertToMarketSettings.actionTime"
                                       [disabled]="!this.targetStrikeDialog.convertToMarket || !this.targetStrikeDialog.convertToMarketSettings.actionTimeMode"
                                       (onValueChanged)="this.targetStrikeDialog.onChange()"></ets-datetime-picker>
               </div>

               <div style="margin-top: 5px;" *ngIf="this.targetStrikeDialog.convertToMarketSettings.actionTimeMode === 'Convert At'">
                  <ets-timezone-picker [(timezone)]="this.targetStrikeDialog.convertToMarketSettings.timezone" flow="col"></ets-timezone-picker>
               </div>

               
               <fieldset class="ctm-extension">
                  <legend>Replace Before Convert</legend>
                  
                  <div class="parameter times-to-replace">
                     <span class="label">Times To Replace</span>
                     <div class="input">
                        <dx-number-box [min]="0" [step]="1" [showClearButton]="true"
                        [(value)]="this.targetStrikeDialog.convertToMarketSettings.timesToReplace"></dx-number-box>
                     </div>
                  </div>
                  <div class="parameter replace-every">
                     <span class="label">Replace Every</span>
                     <div class="input">
                        <ets-datetime-picker 
                           mode="timespan"
                           [(value)]="this.targetStrikeDialog.convertToMarketSettings.replaceEvery">
                        </ets-datetime-picker>
                     </div>
                  </div>
                  <div class="parameter rate-of-change">
                     <span class="label">Rate Of Change ($)</span>
                     <div class="input">
                        <dx-number-box [step]="0.01" [showClearButton]="true"
                           [(value)]="this.targetStrikeDialog.convertToMarketSettings.rateOfChange"></dx-number-box>
                     </div>
                  </div>
                  <div class="parameter  time-order">
                     <span class="label">
                        Reverese Time Direction
                     </span>
                     <div class="input">
                        <dx-check-box
                           [(value)]="this.targetStrikeDialog.convertToMarketSettings.reverseTimeOrder"></dx-check-box>
                     </div>
                  </div>
               </fieldset>

            </div>
         </div>

      </div>
      
      <div class="row buttons">
         <dx-button width="100px" text="OK" (onClick)="this.targetStrikeDialog.onOkClicked()"></dx-button>
         <dx-button width="100px" text="Cancel" (onClick)="this.targetStrikeDialog.onCancelClicked()"></dx-button>
      </div>

      <dx-load-panel [visible]="this.targetStrikeDialog.isLoading"></dx-load-panel>
   </div>
</dx-popup>
