import { Component, Input, OnInit } from '@angular/core';
import { CashFlowStrategyGlobalSettingsModel } from './CashFlowStrategyGlobalSettingsModel';
import {tokenReference} from "@angular/compiler";

@Component({
   selector: 'ets-adjustment-grid-global-settings',
   templateUrl: './adjustment-grid-global-settings.component.html',
   styleUrls: [
      './adjustment-grid-global-settings.component.scss',
      '../adjustment-grid-parameters-style.scss'
   ],
})
export class AdjustmentGridGlobalSettingsComponent implements OnInit {
   constructor(
   ) { }

   //
   @Input() viewModel: CashFlowStrategyGlobalSettingsModel;

   //
   ngOnInit(): void {
   }

   protected readonly tokenReference = tokenReference;
}
