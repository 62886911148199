import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultLoadingOverlayTemplate,
   GetDetailRowDataParams,
   getDetailSymbolColumn,
   getMasterSymbolColumn,
   liveQuoteFormatter,
   defaultPriceCellFormatter,
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderDto, OrderLinkType } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from 'projects/shared-components/environments/environment';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import {
   SimpleHeaderGroupRowInnerRenderer
} from 'projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { isCashSettledComboOrder, isCashSettledOptionTicker, isNullOrUndefined, parseOrderLegs } from 'projects/shared-components/utils';
import { formatNumber } from '@angular/common';
import { TimeInForce } from 'projects/shared-components/trading-model/time-in-force.enum';
import { OrdersAndTradesBaseComponent } from './orders-and-trades-base.component';
import { FutureTimeSettings } from 'projects/shared-components/shell-communication/shell-dto-protocol';


export function getOrdersGridOptions(this: OrdersAndTradesBaseComponent): GridOptions {

   const columns: Partial<AgGridColumn>[] = getColumnDefs(this);
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      tooltipShowDelay: 1000,

      frameworkComponents: {
         simpleHeaderRenderer: SimpleHeaderGroupRowInnerRenderer,
      },

      groupUseEntireRow: true,

      groupRowRendererParams: {
         innerRenderer: 'simpleHeaderRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: isDashboard ? 'always' : null,

      suppressCellSelection: true,

      masterDetail: true,

      detailRowAutoHeight: true,

      detailCellRendererParams: {

         detailGridOptions: {

            defaultColDef: centeredColumnDef,

            groupIncludeTotalFooter: true,

            suppressAggFuncInHeader: true,

            columnDefs: [

               getDetailSymbolColumn('ticker', this.messageBus, this.timestampsService, this.unsubscriber, () => this.ordersGridApi),

               { headerName: 'Buy/Sell', field: 'side' },

               { headerName: 'Qty', field: 'qty' },

               {
                  headerName: 'Live Quote',
                  field: 'liveQuote',
                  valueFormatter: liveQuoteFormatter
               },

               { headerName: 'Portfolio', field: 'portfolioName', tooltipField: 'portfolioName' },
               
               { headerName: 'Combo', field: 'comboName', tooltipField: 'comboName' },
               
               { headerName: 'ComboGroup', field: 'comboGroupName', tooltipField: 'comboGroupName' },

               {
                  headerName: 'Delta',
                  field: 'delta',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  aggFunc: 'sum'
               },

               {
                  headerName: 'T. Delta',
                  field: 'totalDelta',
                  aggFunc: 'sum',
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data as any;

                     if (!params.data) {
                        return null;
                     }

                     const value = leg.ticker.startsWith('@')
                        ? leg.delta * 100
                        : leg.qty;

                     return isNaN(value) ? null : value;
                  },
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-2');
                  }
               },

               {
                  headerName: 'Gamma',
                  field: 'gamma',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  hide: true
               },

               {
                  headerName: 'T. Gamma',
                  field: 'totalGamma',
                  hide: true,
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data;

                     if (!params.data) {
                        return null;
                     }

                     const v = leg.ticker.startsWith('@')
                        ? leg.gamma * 100
                        : leg.qty;

                     return isNaN(v) ? null : v;
                  },
               },

               {
                  headerName: 'Vega',
                  field: 'vega',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  hide: true
               },

               {
                  headerName: 'T. Vega',
                  field: 'totalVega',
                  hide: true,
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data;

                     if (!params.data) {
                        return null;
                     }

                     return leg.ticker.startsWith('@')
                        ? leg.vega * 100
                        : leg.qty;
                  },
               },

               {
                  headerName: 'Theta',
                  field: 'theta',
                  valueFormatter: (params: ValueFormatterParams) => {
                     return formatNumber(params.value || 0, 'en-US', '1.0-3');
                  },
                  valueGetter: (params: ValueGetterParams) => {
                     const leg = params.data;

                     if (!params.data) {
                        return null;
                     }

                     return leg.ticker.startsWith('@')
                        ? leg.theta * 100
                        : leg.qty;
                  },
                  hide: true
               },
            ],

            getRowNodeId: (rowData) => {
               return rowData.ticker;
            }

         },

         getDetailRowData: (params: GetDetailRowDataParams) => {
            const order = params.data as OrderDto;

            if (order.legs) {
               params.successCallback(order.legs);
               return;
            }

            parseOrderLegs(order, this.availableBuckets, this.lastQuoteCache);

            params.successCallback(order.legs);
         },
      },

      isRowMaster: (dataItem) => {
         const order = dataItem as OrderDto;
         return !isNullOrUndefined(order.multiLegDescriptor);
      },

      onGridReady: (args) => this.onOrdersGridReady(args),

      getRowNodeId: (rowData: OrderDto) => rowData.orderId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];

         if (this.securityContext.replaceOrder) {

            menu.push(
               {
                  name: 'Replace',
                  action: () => {
                     this.showReplaceDialog(params.node.data);
                  },
                  disabled: !params.node
               }
            );
         }

         if (this.securityContext.cancelOrder) {
            const hasSelectedOrdes = params.api.getSelectedNodes().length > 0;
            menu.push(
               {
                  name: 'Cancel Selected',
                  action: () => this.cancelSelectedOrders(),
                  disabled: !hasSelectedOrdes
               }
            );
            menu.push(
               {
                  name: 'Cancel All',
                  action: () => this.cancelAllOrders(),
                  disabled: !this.hasWorkingOrders()
               }
            );
         }

         if (this.securityContext.requestOrderStatus) {
            menu.push(
               {
                  name: 'Request Status',
                  action: () => this.requestOrderStatus(params.node.data),
                  disabled: !params.node
               }
            );
            menu.push(
               {
                  name: 'Request Status All',
                  action: () => this.requestOrderStatus(),
                  disabled: !this.hasWorkingOrders()
               }
            );
         }

         if (menu.length > 0) {
            menu.push('separator');
         }

         if (params.node) {
            if (params.node.data) {
               if (params.node.data.orderType === 1) {
                  menu.push({
                     name: 'Convert To Market',
                     action: () => this.showConvertToMarketDialog(params.node.data)
                  });
                  menu.push('separator');
               }
            }
         }

         menu.push({
            name: 'Move to Bucket',
            action: () => this.showMoveToPortfolioDialog(params.node.data),
            disabled: !params.node
         });

         menu.push({
            name: 'Show Comment Popup',
            action: () =>  this.showCommentPopup(params.node.data),
            disabled: !params.node
         });

         menu.push('separator');

         if (this.linkedContext !== 'Portfolios') {
            menu.push(
               {
                  name: 'Is Linked',
                  action: () => this.changeIsLinked(),
                  checked: this.isLinked
               },
               'separator',
            );   
         }
         
         menu.push(
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            'copy',
            'export'
         );

         if (this.linkedContext === 'Portfolios') {
            menu.push(
               'separator',
               {
                  name: 'Bucket Context',
                  icon: '<i class="fas fa-info-circle"></i>',
                  action: () => (this as any).showBucketContextHint()
               }
            );
         }

         return menu;
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),

      onRowSelected: (args: RowSelectedEvent) => this.onRowSelected(args)

   } as GridOptions;
}


function getColumnDefs(comp: OrdersAndTradesBaseComponent): Partial<AgGridColumn>[] {

   const cols: Partial<AgGridColumn>[] = [];
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   cols.push({
      headerName: '',
      checkboxSelection: true,
      pinned: true,
      width: 30
   });


   if (isDashboard) {
      cols.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         filter: true,
         cellRenderer: 'agGroupCellRenderer'
      });

      cols.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true,
         filter: true
      });
   }

   const symbolCol = getMasterSymbolColumn('ticker', comp.messageBus, comp.timestampsService, comp.unsubscriber, () => comp.ordersGridApi);
   cols.push(symbolCol);

   const strategyName: ColDef = {
      headerName: 'Opt. Strategy',
      field: 'optionStrategyName'
   };
   cols.push(strategyName);

   const expirationStyle: ColDef = {
      headerName: 'Exp. Style',
      colId: 'expStyle',
      valueGetter: (args: ValueGetterParams) => {
         if (!args.data) {
            return '';
         }

         if (!args.data.ticker.startsWith('@')) {

            const comboOrder = args.data && args.data.multiLegDescriptor;
            
            if (comboOrder) {
               
               if (isCashSettledComboOrder(args.data.multiLegDescriptor)) {
                  return 'European';
               }

               return 'American';
            }

            return '';
         }

         return isCashSettledOptionTicker(args.data.ticker) ? 'European' : 'American';
      }
   };
   cols.push(expirationStyle);

   cols.push({
      headerName: 'Status',
      field: 'status',
      valueFormatter: (params: ValueFormatterParams) => {

         const order = params.data as OrderDto;

         let status = OrderStatus[params.value];

         if (order.masterOrder) {
            status += ` (${order.masterOrder})`;
         }

         return status;

      },
      filter: true
   });

   cols.push({
      headerName: 'Type',
      field: 'orderType',
      valueFormatter: (params: ValueFormatterParams) => {
         return OrderType[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'Leaves Qty',
      field: 'leavesQty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.leavesQty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push(
      {
         headerName: 'Order Px',
         valueGetter(params: ValueGetterParams) {
            const data: OrderDto = params.data;
            
            if (data.limitPrice && data.limitPrice !== 0) {
               return data.limitPrice;
            }
            
            if (data.stopPrice && data.stopPrice !== 0) {
               return data.stopPrice;
            }

            if (data.autoLimitPrice) {
               return data.autoLimitPrice;
            }
            
            return 0;
         },
         sort: 'desc',
         valueFormatter: defaultPriceCellFormatter
      },
   );

   cols.push({
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter
   });

   if (comp.linkedContext === 'Portfolios') {
      cols.push(
         {
            headerName: 'Portfolio',
            field: 'portfolioName',
            tooltipField: 'portfolioName',
            hide: false,
            enableRowGroup: true,
         },
   
         {
            headerName: 'Combo',
            field: 'comboName',
            tooltipField: 'comboName',
            hide: false,
            enableRowGroup: true,
         },
   
         {
            headerName: 'Combo Group',
            field: 'comboGroupName',
            tooltipField: 'comboGroupName',
            hide: false,
            enableRowGroup: true,
         },
   
      );
   }

   cols.push({
      headerName: 'Terminal',
      field: 'terminalCode',
      filter: true
   });

   cols.push({
      headerName: 'Last Qty.',
      field: 'lastQty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Last Fill Px.',
      field: 'lastPx',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Qty',
      field: 'qty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.qty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Limit Px',
      field: 'limitPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Stop Px',
      field: 'stopPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Side',
      field: 'side',
      valueFormatter: (params: ValueFormatterParams) => {
         return MarketSide[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'Filled',
      field: 'filledQty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.filledQty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Avg. Px',
      field: 'avgFillPrice',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Account',
      field: 'resolveAccountCode',
      filter: true
   });

   cols.push({
      headerName: 'Comment',
      field: 'comment',
      filter: 'agTextColumnFilter'
   });

   cols.push({
      headerName: 'Timestamp',
      field: 'lastModifiedDate',
      valueFormatter: (params: ValueFormatterParams) => {
         const order: OrderDto = params.data;
         const frmtTime = comp.timestampsService.getFormattedDateTimeForStrategy(order.strategyId, order.lastModifiedDate);
         return order.lastModifiedDate
            ? frmtTime
            : '';
      },
   });

   cols.push({
      headerName: 'TIF',
      field: 'tif',
      valueFormatter: (params: ValueFormatterParams) => {
         return TimeInForce[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'ID',
      field: 'orderId',
      filter: 'agTextColumnFilter',
   });

   cols.push({
      headerName: 'Convert To Market',
      field: 'convertToMarketSettings',
      valueGetter: (args: ValueGetterParams) => {
         const stngs = JSON.parse(args.data.convertToMarketSettings) as FutureTimeSettings;
         if (!stngs) {
            return null;
         }

         if (!stngs.actionTimeMode) {
            return null;
         }
         
         if (stngs.actionTimeMode.endsWith(' At')) {
            return `at ${stngs.actionTime} (${stngs.timezone})`;
         }

         if (stngs.actionTimeMode.endsWith(' After')) {
            return `after ${stngs.actionTime}`;
         }

         return null;
      }
   });
   
   cols.push({
      headerName: 'Place Order',
      field: 'futureSettings',
      valueGetter: (args: ValueGetterParams) => {
         const stngs = JSON.parse(args.data.futureSettings) as FutureTimeSettings;
         if (!stngs) {
            return null;
         }

         if (!stngs.actionTimeMode) {
            return null;
         }
         
         if (stngs.actionTimeMode.endsWith(' At')) {
            return `at ${stngs.actionTime} (${stngs.timezone})`;
         }

         if (stngs.actionTimeMode.endsWith(' After')) {
            return `after ${stngs.actionTime}`;
         }

         return null;
      }
   });

   return cols;
}
