import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MessageBusService } from '../message-bus.service';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Logger } from '../logging/logger.interface';
import { LoggerService } from '../logging/logger-factory.service';

@Injectable()
export class EtsHeadersInterceptor implements HttpInterceptor {
   constructor(
      private readonly messageBus: MessageBusService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('EtsHeadersInterceptor');
   }

   private readonly _logger: Logger;

   public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.url.indexOf('api/event') === -1) {
         request = request.clone({
            setHeaders: {
               'Content-Type': 'text/plain'
            }
         });
      }

      return next.handle(request).pipe(
         timeout(60000),
         catchError(err => {
            if (err instanceof HttpErrorResponse) {
               const e = err as HttpErrorResponse;
               if (e.status === 401) {
                  this._logger.error('Request failed: unauthorized', request);
                  this.messageBus.publish({
                     topic: 'AuthTokenExpiredUIMessage',
                     payload: { source: 'HttpClient' }
                  });
               }
            }
            throw err;
         })
      );
   }
}
