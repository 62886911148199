import { formatDate } from '@angular/common';
import { ChangeDetectorRef, EventEmitter, Input } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { DetectMethodChanges, DetectSetterChanges, padTime } from '../utils';

export type DateTimePickerMode = 'date' | 'time' | 'datetime' | 'timespan';

export class CalendarModel {
   constructor(private _changeDetector: ChangeDetectorRef) {}

   date: string;
   
   //

   private _showTodayButton = true;
   get showTodayButton(): boolean {
      return this._showTodayButton;
   }

   @DetectSetterChanges()
   set showTodayButton(v: boolean) {
      this._showTodayButton = v;
   }

   getValue(): string {
      return this.date;
   }
   setValue(value: string) {
      if (isNullOrUndefined(value)) {
         return;
      }

      const dateTime = value.split('T');

      if (dateTime.length > 1) {
         value = dateTime[0];
      }

      this.date = value;
   }
   getDisplayText(): string {
      return this.date ? formatDate(this.date, 'EEE, dd-MMM-yyyy', 'en-US') : 'Pick date...';
   }
   reset() {
      this.date = null;
   }
   validate(): string[] {
      const errors = [];
      if (!this.date) {
         errors.push('Date not set');
      } else {
         const d = new Date(this.date);
         if (!(d instanceof Date && !isNaN(d.getTime()))) {
            errors.push('Date not set');
         }
      }
      return errors;
   }
}

export class TimeModel {
   constructor(private _changeDetector: ChangeDetectorRef) {
      for (let index = 1; index < 13; index++) {
         this.hoursList.push(index);
      }

      for (let index = 0; index < 60; index++) {
         this.minutesList.push(index);
         this.secondsList.push(index);
      }
   }

   //

   private _mode: DateTimePickerMode;

   //

   contextWord: string;

   //

   hoursList: number[] = [];
   
   //

   minutesList: number[] = [];
   
   //

   secondsList: number[] = [];
   
   //

   periodList = ['AM', 'PM'];
   
   //
   
   private _hours: number;
   get hours(): number {
      return this._hours;
   }
   @DetectSetterChanges()
   set hours(v: number) {
      this._hours = v;
   }
   
   //
   
   private _minutes: number;
   get minutes(): number {
      return this._minutes;
   }
   @DetectSetterChanges()
   set minutes(v: number) {
      this._minutes = v;
   }
   
   //
   
   private _seconds: number;
   get seconds(): number {
      return this._seconds;
   }
   @DetectSetterChanges()
   set seconds(v: number) {
      this._seconds = v;
   }
   

   
   private _period: string;
   get period(): string {
      return this._period;
   }
   set period(v: string) {
      this._period = v;
   }
   
   //

   setMode(mode: DateTimePickerMode) {
      this._mode = mode;
   }
   
   //
   getValue(): string {

      if (isNullOrUndefined(this.hours) || isNullOrUndefined(this.minutes)
         || isNullOrUndefined(this.seconds)) {
            return null;
      }

      let hours = this.hours;

      if (this._mode !== 'timespan') {
         if (this.period === 'PM') {
            if (hours < 12) {
               hours += 12;
            }
         } else if (this.period === 'AM') {
            if (hours === 12) {
               hours = 0;
            }
         }
      }
      const value = `${padTime(hours)}:${padTime(this.minutes)}:${padTime(this.seconds)}`;
      return value;
   }
   setValue(value: string) {

      if (isNullOrUndefined(value)) {
         return;
      }
      

      if (value.indexOf('T') > 0)
      {
         const dateTime = value.split('T');
         if (dateTime.length === 2) {
            value = dateTime[1];
         }
      }

      const parts = value.split(':');
      
      if (parts.length !== 3) {
         throw Error(`Incorrect time format: ${value}`);
      }
      
      this._hours = parseInt(parts[0]);
      this._minutes = parseInt(parts[1]);
      this._seconds = parseInt(parts[2]);

      if (this._mode !== 'timespan') {
         if (this._hours >= 12) {
            this._period = 'PM';
         } else {
            this._period = 'AM';
         }
         this._hours = this._hours % 12;
         if (this._hours === 0) {
            this._hours = 12;
         }
      }
   }
   @DetectMethodChanges()
   reset() {
      this._hours = null;
      this._minutes = null;
      this._seconds = null;
      this._period = null;
   }

   getDisplayText(): string {

      let text = this._mode === 'timespan' ? 'Pick duration...' : 'Pick time...';

      if (isNullOrUndefined(this.hours) || isNullOrUndefined(this.minutes) || isNullOrUndefined(this.seconds)) {
         return text;
      }

      if (this._mode === 'timespan') {
         if (this.hours || this.minutes || this.seconds) {
            
            text = `${padTime(this.hours)} hrs. ${padTime(this.minutes)} mins. ${padTime(this.seconds)} secs.`;
            
            if (this.contextWord) {
               const cxWord = this.contextWord.toLowerCase();
               
               if (cxWord.indexOf('before') >= 0) {
                  text = text + ' before';
               } else if (cxWord.indexOf('after') >= 0) {
                  text = text + ' after';
               }

               if (cxWord.indexOf('close') >= 0) {
                  text += ' close';
               } else if (cxWord.indexOf('open') >= 0) {
                  text += ' open';
               }
               
               if (cxWord.indexOf('convert') >= 0 || cxWord.indexOf('place') >= 0) {
                  text += ' placed';
               }
            }
         }
      } else {
         if (this.period) {
            text = `at ${padTime(this.hours)}:${padTime(this.minutes)}:${padTime(this.seconds)} ${this.period}`;
         }
      }

      return text;
   }
   validate(): string[] {

      const errors = [];

      if (this._mode === 'timespan') {
         if (isNullOrUndefined(this.hours) || this.hours < 0) {
            errors.push('Timespan has incorrect hours part');
         }

         if (isNullOrUndefined(this.minutes) ||  (this.minutes < 0 || this.minutes > 59)) {
            errors.push('Timespan has incorrect minutes part');
         }

         if (isNullOrUndefined(this.seconds) || (this.seconds < 0 || this.seconds > 59)) {
            errors.push('Timespan has incorrect seconds part');
         }

         if (this.hours === this.minutes && this.minutes === this.seconds && this.seconds === 0) {
            errors.push('Timespan is empty');
         }
      } else {
         const time = this.getValue();
         const fakeDate = `2022-02-02T${time}`;
         const d = new Date(fakeDate);
         const isDate = d instanceof Date;
         const isValidDate = !isNaN(d.getTime());
         if (!isDate || !isValidDate) {
            errors.push('Time is incorrect');
         }
      }

      return errors;
   }
}
