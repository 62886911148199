import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GameOutcome } from '../game-outcome';
import { DispositionReportComponent } from './disposition-report.component';
import { DispositionReportEntryModel } from './disposition-report-entry-model';
import { WinLoss } from '../win-loss';
import { getInnerGamesGridModel } from './inner-games-grid-model';
import { 
  centeredColumnDef, 
  defaultMoneyCellDefinition, 
  defaultQuoteCellFormatter, 
  defaultLoadingOverlayTemplate 
} from 'projects/shared-components/ag-grid-contrib';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';

export function getRegularDispositionReportGridModel(
  this: DispositionReportComponent,
): GridOptions {
  return  {
    rowData: [],

    defaultColDef: Object.assign({ filter: true }, centeredColumnDef),

    columnDefs: [
      {
        headerName: '#',
        field: 'seqNum',
        width: 80,
        hide: true,
      },
      {
        headerName: 'Outcome',
        field: 'outcome',
        valueGetter(args: ValueGetterParams) {
          const outcome: GameOutcome = args.data.outcome;
          return GameOutcome[outcome];
        },
        cellRenderer: 'agGroupCellRenderer'
      },
      //
      {
        headerName: 'Side',
        field: 'side',
        valueGetter(args: ValueGetterParams) {
          const side: MarketSide = args.data.side;
          return MarketSide[side];
        }
      },
      //
      {
        headerName: 'Win/Loss',
        field: 'winLoss',
        valueGetter(args: ValueGetterParams) {
          const winLoss = args.data.winLoss;
          return WinLoss[winLoss];
        },
        cellStyle(args: CellClassParams) {
          if (args.value === 'Win') {
            return { color: 'green' };
          }
          if (args.value === 'Loss') {
            return { color: 'red' };
          }
          return null;
        }
      },
      {
        headerName: 'Bet',
        field: 'actualBet'
      },
      //
      Object.assign(
        {
          headerName: 'Total P&L',
          field: 'totalPnL'
        },
        defaultMoneyCellDefinition
      ),
      //
      Object.assign(
        {
          headerName: 'Perfect P&L',
          field: 'perfectPnL'
        },
        defaultMoneyCellDefinition
      ),
      //
      Object.assign(
        {
          headerName: 'Slippage',
          field: 'slippageView'
        },
        defaultQuoteCellFormatter
      ),
      //
      {
        headerName: 'CTL Size',
        field: 'ctlValueView',
        valueFormatter: defaultQuoteCellFormatter
      },
      //
      {
        headerName: 'Timestamp',
        field: 'timestamp',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return '';
          }
          return this.timestampsService.getDefaultFormattedDateTime(params.value);
        }
      },
    ],

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    masterDetail: true,

    detailCellRendererParams: {
      detailGridOptions: getInnerGamesGridModel.bind(this)(),
      getDetailRowData: params => {
        const innerGames = params.data.innerGames;
        params.successCallback(innerGames);
      }
    },

    onGridReady: args => this.onGamesRegularGridReady(args),

    getRowNodeId: (rowData: DispositionReportEntryModel) => {
      return rowData.seqNum + '';
    },

    getRowStyle: (args) => {
      const data: DispositionReportEntryModel = args.data;
      if (!data) {
        return null;
      }
      return data.isLast ? { background: 'yellow', color: 'black' } : null;
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [
        {
          name: 'Load Earlier Games...',
          subMenu: [
            {
              name: '50',
              action: () => this.loadEarlierGames(50)
            },
            {
              name: '100',
              action: () => this.loadEarlierGames(100)
            },
            {
              name: '500',
              action: () => this.loadEarlierGames(500)
            },
            {
              name: '1000',
              action: () => this.loadEarlierGames(1000)
            }
          ],
          disabled: !this.selectedDataLoadMode || this.selectedDataLoadMode.value === 'Date'
        },
        'separator',
        'autoSizeAll',
        'copy',
        'export'
      ];
    }
  } as GridOptions;
}
