<ng-container *ngIf="this.showOffsets">
  <ng-container *ngIf="this.showMode === 'Displayed'">
    <ng-container *ngTemplateOutlet="offsets"></ng-container>
  </ng-container>

  <ng-container *ngIf="this.showMode === 'Mouse Over'">
    <dx-popover [hideEvent]="'mouseleave'"
                [showEvent]="'mouseenter'"
                [target]="this.mouseOverTarget"
    >
      <div *dxTemplate="let data of 'content'" class="mouseOverOffsets">
        <ng-container *ngTemplateOutlet="offsets"></ng-container>
      </div>
    </dx-popover>
  </ng-container>
</ng-container>

<ng-template #offsets>
  <div class="container">

    <span *ngIf="this.showSpreadOffset" class="colorcode-short-option">
      <span *ngIf="this.isValidNumber(this.spreadWidthFirst)">${{this.spreadOffsetFirst}}</span>
      <span *ngIf="this.isValidNumber(this.spreadWidthSecond) && !this.showSingleValue('so')">/${{this.spreadOffsetSecond}}
      </span>
    </span>

    <span *ngIf="this.showSpreadWidth" class="colorcode-spread">
      <span *ngIf="this.isValidNumber(this.spreadWidthFirst)">&nbsp;${{this.spreadWidthFirst}}</span>
      <span *ngIf="this.isValidNumber(this.spreadWidthSecond)">/${{this.spreadWidthSecond || 0}}</span>
    </span>

    <span *ngIf="this.showSecondSpreadOffset" class="colorcode-second-spread">
      <span *ngIf="this.isValidNumber(this.secondSpreadOffsetFirst)">&nbsp;${{this.secondSpreadOffsetFirst}}</span>
      <span *ngIf="this.isValidNumber(this.secondSpreadOffsetSecond)">/${{this.secondSpreadOffsetSecond || 0}}</span>
    </span>

    <span *ngIf="this.showSecondSpreadWidth" class="colorcode-second-spread">
      <span *ngIf="this.isValidNumber(this.secondSpreadWidthFirst)">&nbsp;${{this.secondSpreadWidthFirst}}</span>
      <span *ngIf="this.isValidNumber(this.secondSpreadWidthSecond)">/${{this.secondSpreadWidthSecond || 0}}</span>
    </span>

    <span *ngIf="this.showProtectiveOptionOffset" class="colorcode-protective-option">
      <span *ngIf="this.isValidNumber(this.protectiveOptionOffsetFirst)">&nbsp;${{this.protectiveOptionOffsetFirst}}</span>
      <span *ngIf="this.isValidNumber(this.protectiveOptionOffsetSecond) && !this.showSingleValue('po')" >/${{this.protectiveOptionOffsetSecond || 0}}
      </span>
    </span>

    <span *ngIf="this.showSecondProtectiveOptionOffset" class="colorcode-second-protective-option">
      <span *ngIf="this.isValidNumber(this.secondProtectiveOptionOffsetFirst)">&nbsp;${{this.secondProtectiveOptionOffsetFirst}}</span>
      <span *ngIf="this.isValidNumber(this.secondProtectiveOptionOffsetSecond)&& !this.showSingleValue('po2')">/${{this.secondProtectiveOptionOffsetSecond || 0}}
      </span>
    </span>

  </div>
</ng-template>

