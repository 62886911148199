import { LastQuoteCacheBackendService } from 'projects/shared-components/last-quote-cache-backend-service.class';
import { SubscribeToMarketData } from 'projects/shared-components/shell-communication/operations/market-data/subscirbe-market-data.class';
import { Injectable } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { UnsubscribeFromMarketData } from 'projects/shared-components/shell-communication/operations/market-data/unsubscribe-market-data.class';
import { ChangeMarketDataSubscriptionShell, ChangeOptionStrategiesSubscriptionShell } from 'projects/shared-components/shell-communication/shell-operations-protocol';

@Injectable({ providedIn: 'root' })
export class WebtraderLastQuoteCacheBackendService extends LastQuoteCacheBackendService {
   constructor(
      private readonly _shellClientService: ShellClientService,
      private readonly _sessionService: SessionService
   ) {
      super();
   }
   
   async subscribeMarketData(cmd: SubscribeToMarketData): Promise<string> {
      await this._shellClientService.processCommand(cmd);
      const connectedShell = this._sessionService.connectedShell;
      let shellId = 'N/A';
      if (connectedShell) {
         shellId = connectedShell.shellId;
      }
      return shellId;
   }

   
   async unsubscribeMarketData(cmd: UnsubscribeFromMarketData) {
      await this._shellClientService.processCommand(cmd);
      const connectedShell = this._sessionService.connectedShell;
      let shellId = 'N/A';
      if (connectedShell) {
         shellId = connectedShell.shellId;
      }
      return shellId;
   }

   changeMarketDataSubscription(cmd: ChangeMarketDataSubscriptionShell): Promise<void> {
      return this._shellClientService.processCommand(cmd);
   }

   changeOptionStrategiesSubscription(cmd: ChangeOptionStrategiesSubscriptionShell): Promise<void> {
      return this._shellClientService.processCommand(cmd);
   }
}
