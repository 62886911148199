<div class="column left" style="margin: auto; float: none">

  <div class="ets-field">
    <div class="ets-field-label">Symbol</div>
    <div class="ets-field-value">
      <ets-symbol-picker (instrumentSelected)="control.symbol = $event"
      [selectedInstrument]="control.symbol"
      #symbolPicker
      ></ets-symbol-picker>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Mode</div>
    <div class="ets-field-value">
        <dx-select-box [items]="control.modeItems"
                       [displayExpr]="'text'"
                       [valueExpr]="'value'"
                       [(value)]="control.mode"
        ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Filter</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.filterItems"
                     [value]="control.filter"
                     (valueChange)="control.filter = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     [disabled]="!control.canSetFilter"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.filterItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Trade Level</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.tradeLevelItems"
                     [value]="control.tradeLevel"
                     (valueChange)="control.tradeLevel = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.tradeLevelItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Multiplier</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.multiplierItems"
                     [value]="control.multiplier"
                     (valueChange)="control.multiplier = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.multiplierItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Factor</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.factorItems"
                     [value]="control.factor"
                     (valueChange)="control.factor = $event"
                     [showClearButton]="true"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Max. Position</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.maxPositionItems"
                     [value]="control.maxPosition"
                     (valueChange)="control.maxPosition = $event"
                     [showClearButton]="true"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Exit on Stop-Out</div>
    <div class="ets-field-value">
      <dx-check-box [(value)]="control.exitOnStopOut"></dx-check-box>
    </div>
  </div>

  <fieldset>
    <legend>Order Conditions</legend>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Proxy"
                    [value]="control.isTradeThroughProxy"
                    (valueChange)="control.isTradeThroughProxy = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Market"
                    [value]="control.isTradeThroughStop"
                    (valueChange)="control.isTradeThroughStop = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through PT"
                    [value]="control.isTradeThroughMIT"
                    (valueChange)="control.isTradeThroughMIT = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Limits"
                    [value]="control.isTradeThroughLimit"
                    (valueChange)="control.isTradeThroughLimit = $event"
      ></dx-check-box>
    </div>
  </fieldset>

</div>
