import {ProtocolCommand} from '../../service-model/protocol-command.interface';
import {PROTOCOL_NAMESPACE} from './auth-protocol-namespace.const';

export class KeepTokenAlive implements ProtocolCommand {
  constructor(public readonly authToken: string) {
  }

  getOperationName(): string {
    return `${PROTOCOL_NAMESPACE}.KeepTokenAlive`;
  }
}
