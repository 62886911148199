import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { CommonModule } from '@angular/common';
import { WebtraderInitializerModule } from '../initializer/wt-initializer.module';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';
import {
    GenericConfirmationDialogModule
} from "../../../../shared-components/generic-confirmation-dialog/generic-confirmation-dialog.module";

@NgModule({
    imports: [
        CommonModule,
        WebtraderInitializerModule,
        DevExtremeModule,
        GenericConfirmationDialogModule
    ],
  exports: [LoginComponent],
  declarations: [LoginComponent],
  providers: []
})
export class LoginModule {}
