import {ChangeDetectorRef} from "@angular/core";
import {DetectMethodChanges} from "../../../utils";

export class UpdateOrderQtyPromptModel {
    constructor(private _changeDetector: ChangeDetectorRef) {
    }

    private _reject: (reason?: any) => void;
    private _confirm: (value?: (PromiseLike<boolean> | boolean)) => void;

    visible: boolean;

    generateOrders = false;

    @DetectMethodChanges()
    show(): Promise<boolean> {
        this.visible = true;

        return new Promise<boolean>((ok, err) => {
            this._confirm = ok;
            this._reject = err;
        });
    }

    @DetectMethodChanges()
    onConfirm() {
        this._confirm(this.generateOrders);
        this.visible = false;
    }

    @DetectMethodChanges()
    onCancel() {
        this._reject();
        this.visible = false;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        try {
            this._reject();
        } catch {
            //
        }
    }
}
