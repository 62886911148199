<dx-popup
  [width]="700"
  [height]="400"
  [visible]="isVisible"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  title="Clear Trading Data"
  (onHidden)="onHidden()"
  (onShown)="onShown()">

  <div *dxTemplate="let data of 'content'" class="popup-template">
    <div class="configuration">
      <div class="section data">
        <div class="header">Data</div>
        <dx-check-box text="Strategy" [(value)]="dataStrategy"></dx-check-box>
        <dx-check-box text="Manual" [(value)]="dataManual"></dx-check-box>
        <dx-check-box text="Risk Manager" [(value)]="dataRiskManager"></dx-check-box>
        <dx-check-box text="Group Exits" [(value)]="dataGroupExits"></dx-check-box>
        <dx-check-box text="Session History" [(value)]="dataSessionHistory"></dx-check-box>
        <dx-check-box text="Ledger Records" [(value)]="dataLedgerRecords"></dx-check-box>
      </div>

      <div class="section">
        <div class="header">Terminals</div>
        <dx-list  [items]="terminals" 
                  [selectionMode]="'multiple'"
                  keyExpr="terminalId"
                  [displayExpr]="'displayName'"
                  [showSelectionControls]="true"
                  [(selectedItems)]="selectedTerminals">
        </dx-list>
      </div>

      <div class="section">
        <div class="header">Accounts</div>
        <dx-list  [items]="accounts"
                  [selectionMode]="'multiple'"
                  keyExpr="accountId"
                  [showSelectionControls]="true"
                  [(selectedItems)]="selectedAccounts"
                  itemTemplate="listItem">
              <div *dxTemplate="let item of 'listItem'">
                <span [ngClass]="{
                'real-account': !item.isPaperTrading,
                'selected-account': item.isSelected
                }">{{item.accountCode}}</span>
              </div>
        </dx-list>
      </div>

    </div>

    <div class="buttons">
      <dx-button class="refreshdb" width="100"
          text="Refresh DB" type="danger"
          (onClick)="onClearTradingDataClicked('refresh')"
          >
      </dx-button>

      <dx-button  width="100"
                  text="Archive"
                  type="success"
                  (onClick)="onClearTradingDataClicked('archive')"
                  [disabled]="isClearingData || !canSendRequest">
      </dx-button>

      <dx-button width="100"
                 text="Delete"
                 type="default"
                 (onClick)="onClearTradingDataClicked('delete')"
                 [disabled]="isClearingData || !canSendRequest">
      </dx-button>
    </div>
    
    <dx-load-panel [visible]="isLoading" style="margin: auto"
    ></dx-load-panel>
    
    <dx-load-panel [visible]="isClearingData" style="margin: auto"
                   [message]="clearDataProgressMessage"
    ></dx-load-panel>
  </div>
</dx-popup>
