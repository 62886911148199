import { formatNumber } from '@angular/common';
import { CellClassParams, ColDef, GetContextMenuItemsParams, GridOptions, GridReadyEvent, RowClickedEvent, RowDoubleClickedEvent, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { isNullOrUndefined } from 'util';
import { centeredColumnDef, defaultMoneyCellDefinition, AG_DEFAULT_ROW_HEIGHT as AG_DEFAULT_ROW_HEIGHT } from '../ag-grid-contrib';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { PortfolioDto } from '../shell-communication/shell-dto-protocol';
import { PortfoliosComponent } from './portfolios.component';

const IS_DASHBOARD = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;


export function getPortfoliosGridOptions(this: PortfoliosComponent): GridOptions {
   const body = document.querySelector('body') as HTMLElement;

   return {

      rowData: [],

      columnDefs: getColumnDefs(),

      defaultColDef: centeredColumnDef,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      suppressAggFuncInHeader: true,

      suppressCellSelection: true,

      popupParent: body,

      groupUseEntireRow: false,

      tooltipShowDelay: 1000,

      autoGroupColumnDef: {
         headerName: 'Portfolio',
         field: 'portfolioName',
         minWidth: 215,
         flex: 1,
         tooltipField: 'portfolioName',
         cellRendererParams: {
            suppressCount: true,
         },
         cellStyle: (args: CellClassParams) => {
            if (isNullOrUndefined(args.data)) {
               return undefined;
            }
            const style = { 'text-align': 'left', color: undefined };
            
            if (args.data.isArchived) {
               style.color = 'yellow';
            }
            return style;
         },
         valueGetter: (params: ValueGetterParams) => {
            if (!params.node.group) {
               return params.data.portfolioName;
            }
            return params.data.terminalName;
         }
      },

      getRowHeight: (args) => {

         if (isNullOrUndefined(args.node) || isNullOrUndefined(args.node.data)) {
            return AG_DEFAULT_ROW_HEIGHT;
         }

         return !this.showArchivedData && args.node.data.isArchived ? 0 : AG_DEFAULT_ROW_HEIGHT;
      },

      postSort: (rowNodes: RowNode[]) => {
         const nextInsertPos = rowNodes.length;
         
         for (let i = rowNodes.length - 1; i >= 0; i--) {
             const pfItem: PortfolioDto = rowNodes[i].data;
             if (pfItem && pfItem.isArchived) {
                if (i !== nextInsertPos) {
                   rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                }
             }
         }
      },

      getRowNodeId: (row: PortfolioDto) => row.portfolioId,

      onGridReady: (args: GridReadyEvent) => this.onPortfoliosGridReady(args),

      getContextMenuItems: (args: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Create Portfolio',
               action: () => this.createPortfolio()
            },
            'separator',
            {
               name: 'Rename Portfolio',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedPortfolio),
               action: () => this.renamePortfolio()
            },
            {
               name: 'Transfer Portfolio',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedPortfolio),
               action: () => this.transferPortfolio(args.node.data)
            },
            {
               name: 'Duplicate Portfolio',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedPortfolio),
               action: () => this.duplicatePortfolio(args.node.data)
            },
            {
               name: 'Archive Portfolio',
               disabled: !args.node || !args.node.data || (this.selectedPortfolio !== args.node.data),
               action: () => this.archivePortfolio()
            },
            {
               name: 'Delete Portfolio',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedPortfolio),
               action: () => this.deletePortfolio()
            },
            'separator',
            {
               name: 'Attributes...',
               subMenu: [
                  {
                     name: 'Position Sizing',
                     action: () => this.showPositionSizingDialog(args.node.data.portfolioId, 'Portfolio')
                  },
               ],
               disabled: !args.node || ! args.node.data || args.node.data.group || (args.node.data !== this.selectedPortfolio) 
                  || args.node.data.isArchived,
            },
            {
               name: 'Hedge/Freeze Portfolio',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedPortfolio),
               action: () => this.hedgePortfolio()
            },
            {
               name: 'Add Interest Calculator',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedPortfolio),
               action: () => this.showInterestRateDialog(args.node.data.portfolioId, 'Portfolio')
            },
            {
               name: 'Clear Trading Data',
               disabled: !args.node || ! args.node.data || args.node.data.group || (args.node.data !== this.selectedPortfolio),
               action: () => this.clearTradingDataForBucket('Portfolio', args.node.data.portfolioId, args.node.data.portfolioName)
            },
            'separator',
            {
               name: 'Bucket History',
               action: () => this.showBucketHistory(args.node.data, 'Portfolio')
            },
            'separator',
            {
               name: 'Show/Hide Archived Data',
               action: () => this.changeShowArchivedData(),
               checked: this.showArchivedData
            },
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll'
         ];
      },

      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node) {
            this.clearAllSections();
            return;
         }

         if (args.node.group) {
            this.onTerminalSelected(args.node);
            return;
         }

         if (args.node.data === this.selectedPortfolio) {
            if (!this.selectedCombo) {
               // if we clicked already selected portfolio, and no combo is selected,
               // that means we are showing the portfolio data already and no need to reload
               return;
            }
         }

         this.onPortfolioSelected(args.node.data);
      },

      onRowDoubleClicked: (args: RowDoubleClickedEvent) => {
         if (!args.node) {
            this.clearAllSections();
            return;
         }

         if (args.node.group) {
            this.selectedTerminal = null;
            this.onTerminalSelected(args.node);
            return;
         }

         this.selectedTerminal = null;
         this.onPortfolioSelected(args.node.data);
      }
   };
}

function getColumnDefs(): ColDef[] {
   const colDefs: ColDef[] = [];

   const isArchivedCol: ColDef = {
      field: 'isArchived',
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return undefined;
         }
         return params.data.isArchived ? 'Archived Items' : 'Active Items';
      }
   };


   if (IS_DASHBOARD) {
      const clientNameCol: ColDef = {
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 0,
         hide: true
      };

      const shellNameCol: ColDef = {
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 1,
         hide: true
      };

      colDefs.push(clientNameCol);
      colDefs.push(shellNameCol);
   }

   const portfolioNameCol: ColDef = {
      headerName: 'Name',
      field: 'portfolioName',
      cellRenderer: 'agGroupCellRenderer',
   };

   const terminalNameCol: ColDef = {
      headerName: 'Terminal',
      field: 'terminalName',
      enableRowGroup: true,
      rowGroup: true,
      hide: true
   };

   const sessionPnLCol: ColDef = Object.assign({
      headerName: 'Sess.P&L',
      field: 'sessionPnL',
      minWidth: 117,
      aggFunc: 'sum',
   }, defaultMoneyCellDefinition);

   const accumulatedPnLCol: ColDef = Object.assign({
      headerName: 'Acc.P&L',
      field: 'accumulatedPnL',
      minWidth: 117,
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition);


   const totalDeltaCol: ColDef = {
      headerName: 'T.Delta',
      field: 'totalDelta',
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalGammaCol: ColDef = {
      headerName: 'T. Gamma',
      field: 'totalGamma',
      aggFunc: 'sum',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalVegaCol: ColDef = {
      headerName: 'T. Vega',
      field: 'totalVega',
      aggFunc: 'sum',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalThetaCol: ColDef = {
      headerName: 'T. Theta',
      field: 'totalTheta',
      aggFunc: 'sum',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   // colDefs.push(portfolioNameCol);
   colDefs.push(terminalNameCol);
   colDefs.push(sessionPnLCol);
   colDefs.push(accumulatedPnLCol);
   colDefs.push(totalDeltaCol);
   colDefs.push(totalGammaCol);
   colDefs.push(totalVegaCol);
   colDefs.push(totalThetaCol);
   colDefs.push(isArchivedCol);

   return colDefs;
}
