import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SimpleHeaderGroupRowInnerRenderer} from './group-row-inner-renderer.component';
import {LastQuoteCellRendererComponent} from './last-quote-cell-inner-renderer.component';
import {GreekCellRendererComponent} from "./greek-cell-renderer.component";

@NgModule({
    declarations: [
        SimpleHeaderGroupRowInnerRenderer,
        LastQuoteCellRendererComponent,
        GreekCellRendererComponent
    ],
    imports: [CommonModule],
    exports: [
        SimpleHeaderGroupRowInnerRenderer,
        LastQuoteCellRendererComponent
    ],
    providers: [],
})
export class AgGridContribModule {
}
