import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RowFilter} from "../model/row.filter";
import {DashboardFilterConfig, DashboardFilterItem} from "./DashboardFilterConfig";
import {RowFilterAttribute} from "../model/RowFilterAttribute";
import {ToastrService} from "ngx-toastr";
import {DetectMethodChanges, isVoid} from "../../utils";

@Component({
    selector: 'ets-dashboard-filter-popup',
    templateUrl: 'dashboard-filter-popup.component.html',
    styleUrls: ['dashboard-filter-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardFilterPopupComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }

    private _config: DashboardFilterConfig;
    private _resolve: (value?: (PromiseLike<RowFilter> | RowFilter)) => void;
    private _reject: (reason?: any) => void;

    value: any;

    popupWidth = 250;

    popupHeight = 190;

    visible = false;

    get title(): string {
        return `Filter Value`
    }

    get items(): DashboardFilterItem[] {
        return this._config?.items;
    }

    get attributeName(): string {
        return  this._config?.attributeName;
    }

    @DetectMethodChanges()
    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: DashboardFilterConfig): Promise<RowFilter> {
        this._config = config;

        this.visible = true;

        return new Promise<RowFilter>((res, rej) => {
            this._resolve = res;
            this._reject = rej;
        });
    }

    @DetectMethodChanges()
    onOkClicked() {
        if (isVoid(this.value)) {
            this._toastr.error('Please Select a Value');
            return;
        }

        const rule: RowFilter = {
            attribute: this._config.attribute,
            value: this.value
        };

        this._resolve(rule);

        this.onClosed();
    }

    @DetectMethodChanges()
    onCancelClicked() {
        this._reject();
        this.onClosed();
    }

    @DetectMethodChanges()
    onClosed() {
        this._config = null;
        this.value = null;
        this.visible = false;
        this._reject = null;
        this._resolve = null;
    }

    @DetectMethodChanges()
    onValueChanged($event: any) {
        //
    }
}