import { formatNumber } from '@angular/common';
import { CellClassParams, ColDef, GetContextMenuItemsParams, GridOptions, GridReadyEvent, RowClickedEvent, RowDoubleClickedEvent, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { isNullOrUndefined } from 'util';
import { AG_DEFAULT_ROW_HEIGHT, centeredColumnDef, defaultMoneyCellDefinition } from '../ag-grid-contrib';
import { ComboDto } from '../shell-communication/shell-dto-protocol';
import { PortfoliosComponent } from './portfolios.component';

export function getCombosGridOptions(this: PortfoliosComponent, forTerminal?: boolean): GridOptions {
   const body = document.querySelector('body') as HTMLElement;

   return {

      rowData: [],

      columnDefs: getColumnDefs(forTerminal),

      defaultColDef: centeredColumnDef,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      suppressAggFuncInHeader: true,

      suppressCellSelection: true,

      popupParent: body,

      tooltipShowDelay: 1000,

      getRowHeight: (args) => {
         if (isNullOrUndefined(args.node) || isNullOrUndefined(args.node.data)) {
            return AG_DEFAULT_ROW_HEIGHT;
         }

         return !this.showArchivedData && args.node.data.isArchived ? 0 : AG_DEFAULT_ROW_HEIGHT;
      },

      postSort: (rowNodes: RowNode[]) => {
         const nextInsertPos = rowNodes.length;
         
         for (let i = rowNodes.length - 1; i >= 0; i--) {
             const pfItem: ComboDto = rowNodes[i].data;
             if (pfItem && pfItem.isArchived) {
                if (i !== nextInsertPos) {
                   rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                }
             }
         }
      },

      getRowNodeId: (row: ComboDto) => row.comboId,

      onGridReady: (args: GridReadyEvent) => this.onCombosGridReady(args),

      getContextMenuItems: (args: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Create Combo',
               disabled: !this.selectedPortfolio,
               action: () => this.createCombo()
            },
            'separator',
            {
               name: 'Rename Combo',
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.renameCombo()
            },
            {
               name: 'Transfer Combo',
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.transferCombo(args.node.data)
            },
            {
               name: 'Duplicate Combo',
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.duplicateCombo(args.node.data)
            },
            {
               name: 'Archive Combo',
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.archiveCombo()
            },
            {
               name: 'Delete Combo',
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data),
               action: () => this.deleteCombo()
            },
            'separator',
            {
               name: 'Attributes...',
               subMenu: [
                  {
                     name: 'Position Sizing',
                     action: () => this.showPositionSizingDialog(args.node.data.comboId, 'Combo')
                  },
               ],
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data) || args.node.data.isArchived,
            },
            {
               name: 'Hedge/Freeze Combo',
               disabled: !args.node || !args.node.data || (this.selectedCombo !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.hedgeCombo()
            },
            {
               name: 'Add Interest Calculator',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedCombo),
               action: () => this.showInterestRateDialog(args.node.data.comboId, 'Combo')
            },
            {
               name: 'Clear Trading Data',
               disabled: !args.node || ! args.node.data || args.node.data.group || (args.node.data !== this.selectedCombo),
               action: () => this.clearTradingDataForBucket('Combo', args.node.data.comboId, args.node.data.comboName)
            },
            'separator',
            {
               name: 'Bucket History',
               action: () => this.showBucketHistory(args.node.data, 'Combo')
            },
            'separator',
            {
               name: 'Show/Hide Archived Data',
               action: () => this.changeShowArchivedData(),
               checked: this.showArchivedData
            },
            ,
            'separator',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll'
         ];
      },

      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node || !args.node.data) {
            this.selectedCombo = null;
            this.selectedComboGroup = null;
            return;
         }

         if (args.node.data === this.selectedCombo) {
            if (!this.selectedComboGroup) {
               // if we clicked already selected combo, and no combogroup is selected,
               // that means we are showing the combo data already and no need to reload
               return;
            }
         }

         this.onComboSelected(args.node.data);
      },

      onRowDoubleClicked: (args: RowDoubleClickedEvent) => {
         if (!args.node || !args.node.data) {
            return;
         }

         this.onComboSelected(args.node.data);
      }
   };
}

export function getComboGridColumnDefs(forTerminal: boolean): ColDef[] {
   return getColumnDefs(forTerminal);
}

function getColumnDefs(forTerminal: boolean): ColDef[] {
   const colDefs: ColDef[] = [];

   const isArchivedCol: ColDef = {
      field: 'isArchived',
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return undefined;
         }
         return params.data.isArchived ? 'Archived Items' : 'Active Items';
      }
   };

   const comboName: ColDef = {
      headerName: 'Name',
      field: 'comboName',
      minWidth: 220,
      flex: 1,
      tooltipField: 'comboName',
      valueFormatter: (args: ValueFormatterParams) => {
         if (!args.data) {
            return args.value;
         }

         const combo = args.data as ComboDto;

         let name  = combo.comboName;

         if (combo.isCfsRunning) {
            name  = name + ' - # CFS #';
         }

         return name;
      },
      cellStyle: (args: CellClassParams) => {
         if (isNullOrUndefined(args.data)) {
            return undefined;
         }
         const style = { 'text-align': 'left', color: undefined };
         
         if (args.data.isArchived) {
            style.color = 'yellow';
         }
         return style;
      },
   };

   const portfolioName: ColDef = {
      headerName: 'Portfolio',
      field: 'portfolioName',
      minWidth: 170,
      tooltipField: 'portfolioName',
      cellStyle: (args: CellClassParams) => {
         
         if (isNullOrUndefined(args.data)) {
            return undefined;
         }

         const style = { 'text-align': 'left', color: undefined };
         
         return style;
      },
   };

   const sessionPnLCol: ColDef = Object.assign({
      headerName: 'Sess.P&L',
      field: 'sessionPnL',
      minWidth: 117,
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition);

   const accumulatedPnLCol: ColDef = Object.assign({
      headerName: 'Acc.P&L',
      field: 'accumulatedPnL',
      minWidth: 117,
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition);

   const totalDeltaCol: ColDef = {
      headerName: 'T.Delta',
      field: 'totalDelta',
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalGammaCol: ColDef = {
      headerName: 'T. Gamma',
      field: 'totalGamma',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalVegaCol: ColDef = {
      headerName: 'T. Vega',
      field: 'totalVega',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalThetaCol: ColDef = {
      headerName: 'T. Theta',
      field: 'totalTheta',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   colDefs.push(comboName);
   if (forTerminal) {
      colDefs.push(portfolioName);
   }
   colDefs.push(sessionPnLCol);
   colDefs.push(accumulatedPnLCol);
   colDefs.push(totalDeltaCol);
   colDefs.push(totalGammaCol);
   colDefs.push(totalVegaCol);
   colDefs.push(totalThetaCol);
   colDefs.push(isArchivedCol);

   return colDefs;
}
