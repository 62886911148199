import { GetContextMenuItemsParams, GridOptions, CellClassParams, FirstDataRenderedEvent, ValueGetterParams, ITooltipParams } from 'ag-grid-community';
import {
   centeredColumnDef,
   defaultLoadingOverlayTemplate,
   defaultMoneyCellDefinition
} from 'projects/shared-components/ag-grid-contrib';
import { PositionSizingReportComponent } from './position-sizing-report.component';
import { PositionSizingRecordDto } from '../shell-communication/shell-dto-protocol';
import { isNullOrUndefined } from 'util';
import { ProfitCellRendererComponent } from './profit-cell-renderer.component';

export function getUnitsGridModel(this: PositionSizingReportComponent): GridOptions {
   return {
      rowData: [],

      defaultColDef: Object.assign({ filter: true }, centeredColumnDef),

      columnDefs: [

         {
            headerName: '#',
            field: 'sequenceNum',
            minWidth: 40,
            maxWidth: 50,
            cellStyle(args: CellClassParams) {
               return { background: 'cornsilk', color: 'black' };
            },
            sort: 'asc',
            // tooltipField: 'timestamp',
            tooltipValueGetter: (args: ITooltipParams) => {
               const tz = this.timestampsService.getDefaultTimezone();
               const value = this.timestampsService.getZonedFormattedDateTime(args.data.timestamp, 'dd-MMM-yyyy hh:mm:ss a');
               return `${value} (${tz})`;
            }
         },

         {
            headerName: 'Curr. Units',
            field: 'units',
            sortable: false,
            maxWidth: 100,
            minWidth: 90,
         },
         
         {
            headerName: 'Profit',
            sortable: false,
            cellRenderer: 'psrProfitRenderer',
            minWidth: 110,
         },

         {
            headerName: 'Qty',
            field: 'qty',
            sortable: false,
            minWidth: 60,
            maxWidth: 100,
         },

         {
            headerName: 'Delta Units',
            field: 'deltaUnits',
            sortable: false,
            minWidth: 50,
            maxWidth: 100,
         },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      frameworkComponents: {
         psrProfitRenderer: ProfitCellRendererComponent
      },

      onGridReady: args => this.onUnitsGridReady(args),

      getRowNodeId: (rowData: PositionSizingRecordDto) => {
         return rowData.recordId;
      },

      getRowStyle: (args) => {
         const data = args.data;
         if (!data) {
            return null;
         }
         return data.switchHappened ? { background: 'cyan', color: 'black' } : null;
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.api.sizeColumnsToFit();
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}
