import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenPositionChartComponent } from './open-position-chart.component';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule
   ],
   exports: [
      OpenPositionChartComponent
   ],
   declarations: [
      OpenPositionChartComponent
   ],
   providers: [

   ]
})
export class EtsOpenPositionChartModule { }
