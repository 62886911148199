<dx-scroll-view height="100%" [showScrollbar]="'always'">

   <div class="content-area">
      
      <div class="section left">
   
         <div class="input">
            <p>Display Name</p>
            <dx-text-box [(value)]="this.parameters.displayName" 
             (onValueChanged)="this.parameters.onChange($event)"></dx-text-box>
         </div>
      
         <!-- Strike Offset -->
         <div class="input" *ngIf="this.cpMode !== 'Automated' ">
            <p>Strike %-OTM Offset (0% = ATM) </p>
            <dx-number-box [(value)]="this.parameters.strikeOffset"
                           (onValueChanged)="this.parameters.onChange($event)"
                           [showSpinButtons]="true"
                           [useLargeSpinButtons]="false"
                           [min]="0"
                           format="#0%"
                           [step]="0.01"
            ></dx-number-box>
         </div>
      
         <!-- Days to Expiration Offset -->
         <div class="input" *ngIf=" this.cpMode !== 'Automated' ">
            <p>Expiration Offset (1 = Next Listed Expiration)</p>
            <dx-number-box [(value)]="this.parameters.expirationOffset"
                           (onValueChanged)="this.parameters.onChange($event)"
                           [showSpinButtons]="true"
                           [useLargeSpinButtons]="false"
                           [min]="1"
                           format="#0d"
                           [step]="1"
            ></dx-number-box>
         </div>

         <div class="input" *ngIf=" this.cpMode !== 'Automated' ">
            <p>Expiration Ofset Buffer</p>
            <dx-number-box [(value)]="this.parameters.expirationOffsetBuffer"
                           (onValueChanged)="this.parameters.onChange($event)"
                           [showSpinButtons]="true"
                           [useLargeSpinButtons]="false"
                           [min]="0"
                           format="#0d"
                           [step]="1"
            ></dx-number-box>
         </div>

         <!-- Option Selling -->
         <fieldset *ngIf=" this.cpMode !== 'Automated' ">
            <legend>Short Option Roll Settings</legend>
            
            <!-- Sell Mode -->
            <div class="input">
               <p>Roll Day</p>
               <dx-select-box [items]="['Expiration Day', 'After Expiration']"
                  [(value)]="this.parameters.optionSellingDay"
                  (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
            </div>
      
            <div class="input">
               <dx-select-box [items]="this.parameters.sellTimeActionModes"
                  [(value)]="this.parameters.sellActionTimeMode"
                  (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
            </div>
      
            <div class="input">
               <p>Roll Time</p>
               <ets-datetime-picker 
                              [contextWord]="this.parameters.sellActionTimeMode"
                              [mode]="this.parameters.sellTimePickerMode" 
                              [showTimezone]="false"
                              [(value)]="this.parameters.sellActionTime"
                              (valueChanged)="this.parameters.onChange($event)"
               ></ets-datetime-picker>

               <ets-timezone-picker *ngIf="this.parameters.showTimezone" [(timezone)]="this.parameters.sellActionTimezone" [flow]="'col'"></ets-timezone-picker>
            </div>
      
         </fieldset>

         <!-- Order Type -->
         <div class="input" *ngIf=" this.cpMode !== 'Automated' ">
            <p>Order Type</p>
            <dx-select-box [items]="this.parameters.orderTypes" 
                           (onValueChanged)="this.parameters.onChange($event)"
                           displayExpr="name" 
                           valueExpr="value" 
                           [(value)]="this.parameters.orderType"
            ></dx-select-box>
         </div>
      
         <!-- Limit Order Extensions -->
         <div  *ngIf=" this.cpMode !== 'Automated' && this.parameters.orderType === 1" style="margin-top: 10px">
      
            <div class="input">
               <p>Limit Price</p>
               <dx-select-box [items]="['Bid', 'Mid', 'Ask']"
                              [(value)]="this.parameters.limitPrice"
                              (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
            </div>
      
            <div class="input">
               <p>Minimum Price</p>
               <dx-number-box [(value)]="this.parameters.minimumPrice"
                              (onValueChanged)="this.parameters.onChange($event)"
                              [showSpinButtons]="true"
                              [showClearButton]="true"
                              [useLargeSpinButtons]="false"
                              [min]="0"
                              format="$#0.00"
               ></dx-number-box>
            </div>
      
            <fieldset style="margin-top: 5px;">
               <legend>
                  <dx-check-box  #ctm 
                                 text="Convert To Market" 
                                 [(value)]="this.parameters.convertToMarket"
                                 (onValueChanged)="this.parameters.onChange($event)"></dx-check-box>
               </legend>
               
               <ets-convert-to-market *ngIf="ctm.value" 
                                       [hideCalendar]="true"
                                       [settings]="this.parameters.convertToMarketSettings">
               </ets-convert-to-market>
      
            </fieldset>
            
         </div>
            
         <!-- ATM Neutral Zone -->
         <div class="input" *ngIf=" this.cpMode !== 'Automated' ">
            <p>ATM Neutral Zone</p>
            <dx-number-box [showClearButton]="true" 
                           [(value)]="this.parameters.atmNeutralZone" 
                           (onValueChanged)="this.parameters.onChange($event)"
                           [min]="0"
                           [step]="0.01" 
                           format="#0%"></dx-number-box>
         </div>
      
         <!-- Mode: independent / CFS -->
         <div class="input" *ngIf=" this.cpMode !== 'Automated' ">
            <p>Operation Mode</p>
            <dx-select-box [items]="this.parameters.operationModes" 
                           [(value)]="this.parameters.operationMode"
                           (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
         </div>
      </div>
   
      <div class="section right" *ngIf=" this.cpMode !== 'Automated' ">

         <fieldset style="margin-top: 5px;" *ngIf="this.parameters.operationMode === 'Engage With CFS Template'">
            <legend>
               "Engage With CFS Template" Settings
            </legend>   
            
               <!-- Strike Buffer -->
               <div class="input">
                  <div class="col">Strike Buffer ($)</div>
                  <div class="col input">
                     <dx-number-box [showClearButton]="true" 
                                    [(value)]="this.parameters.strikeBuffer" 
                                    (onValueChanged)="this.parameters.onChange($event)"
                                    [step]="1" format="$#0"></dx-number-box>
                  </div>
               </div>
            
               <!-- Move Spread's Leg -->
               <div class="input">
                  <dx-check-box text="Move Short-Term Debit Spread's Long Leg" [(value)]="this.parameters.moveShortTermDebitSpread"></dx-check-box>
               </div>
      
         </fieldset>

         <fieldset style="margin-top: 5px;" *ngIf="this.parameters.operationMode === 'Engage With CFS Template'">
            <legend>
               <dx-check-box text="Optimal Expiration Selection" [(value)]="this.parameters.useOptimalExpirationSelection"
                  [disabled]="true"
                  (onValueChanged)="this.parameters.onChange($event)"></dx-check-box>
            </legend>

            <div *ngIf="this.parameters.useOptimalExpirationSelection">
               <!-- Max Expirations Forward -->
               <div class="input">
                  <div class="col">Max. Expirations Forward</div>
                  <div class="col input">
                     <dx-number-box [showClearButton]="true" 
                                    [showSpinButtons]="true"
                                    [useLargeSpinButtons]="true"
                                    [(value)]="this.parameters.optimalExpirationLookForwardLimit" 
                                    (onValueChanged)="this.parameters.onChange($event)"
                                    [step]="1" [min]="1"></dx-number-box>
                  </div>
               </div>
   
               <!-- Selectıon Mode -->
               <div class="input">
                  <div class="col">Expiration Selection Mode</div>
                  <div class="col input">
                     <dx-select-box [showClearButton]="true" 
                                    [items]="this.parameters.optimalExpirationSelectionModeList"
                                    [(value)]="this.parameters.optimalExpirationSelectionMode" 
                                    (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
                  </div>
               </div>
            
                <!-- Price Buffer -->
               <div class="input">
                  <p>Price Buffer</p>
                  <dx-number-box [showClearButton]="true" 
                                 [showSpinButtons]="true"
                                 [useLargeSpinButtons]="true"
                                 [(value)]="this.parameters.optimalExpirationPriceBuffer" 
                                 (onValueChanged)="this.parameters.onChange($event)"
                                 [min]="0"
                                 [step]="0.01" 
                                 format="0.##"></dx-number-box>
               </div>   
            </div>
            
         </fieldset>

      </div>

   </div>

</dx-scroll-view>