import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ComboDto, GetPortfolioSectionDataModelReply, GetProtectiveOptionSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { ToastrService } from 'ngx-toastr';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { PortfolioSideboxComponent } from './sidebox/portfolio-sidebox.component';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { getPortfolioSectionGridOptions } from './portfolio-section-grid-options';
import { GetPortfolioSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';

@Component({
   selector: 'ets-automation-cp-body-portfolio-section',
   templateUrl: './portfolio-section.component.html',
   styleUrls: ['./portfolio-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioSectionComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
   ) { }

   private _grid: GridApi;

   header: string = 'Portfolio';

   get gridHeight(): number {
      let height = 130;

      if (this._grid) {
         const rows = this._grid.getDisplayedRowCount()
         height += (rows - 1) * 27;
      }

      return height;
   }

   get visible(): boolean {
      return this.strategy === 'Hedged Portfolio' 
       || this.strategy === 'Reversed Hedged Portfolio';
   }

   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(value: boolean) {
      this._isLoading = value;
   }

   //
   private _combo: ComboDto;
   get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   //
   gridOptions: GridOptions;

   //
   ngOnInit(): void { 
      this.gridOptions = getPortfolioSectionGridOptions.bind(this)('section')
   }

   //
   onGridReady(args: GridReadyEvent) {
      this._grid = args.api;
   }
   
   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {

      this.reset();

      if (isVoid(this.combo)) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetPortfolioSectionDataModel(
            this.combo.comboId
         );

         const reply = await this._shellClient
            .processQuery<GetPortfolioSectionDataModelReply>(qry);

         if (this._grid) {
            this._grid.setRowData(reply.items)
         }

      } catch (e) {

         this._toastr.error(
            '"Load Portfolio Data" operation completed with errors',
            'Protective Option'
         );

      } finally {

         this.isLoading = false;

      }
   }

   //
   reset() {
      if (this._grid) {
         this._grid.setRowData([]);
      }
   }
}
