import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { RiskRuleModel } from './risk-rule-model';
import { getRiskRulesGridModel } from './risk-rules-grid-model';
import { getViolationsGridModel } from './violations-grid-model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService,

} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { RiskRuleEditorComponent } from './risk-rule-editor/risk-rule-editor.component';
import { RiskManagerRuleViolationDto } from 'projects/shared-components/shell-communication/dtos/risk-manager-rule-violation-dto.interface';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import {
   MarkRiskManagerRuleViolationsAsViewed
} from 'projects/shared-components/shell-communication/operations/risk/mark-risk-manager-rule-violation-viewed.class';
import {
   RemoveRiskManagerRuleViolations
} from 'projects/shared-components/shell-communication/operations/risk/remove-risk-manager-rule-violations.class';
import { UnblockRiskManagerRule } from 'projects/shared-components/shell-communication/operations/risk/unblock-risk-manager-rule.class';
import { RemoveRiskManagerRule } from 'projects/shared-components/shell-communication/operations/risk/remove-risk-manager-rule.class';
import { RiskManagerRuleDto } from 'projects/shared-components/shell-communication/dtos/risk-manager-rule-dto.interface';
import { GetRiskManagerRules } from 'projects/shared-components/shell-communication/operations/risk/get-risk-manager-rules.class';
import {
   GetRiskManagerRuleViolations
} from 'projects/shared-components/shell-communication/operations/risk/get-risk-manager-rule-violations.class';
import {
   GetRiskManagerRuleViolationOrders
} from 'projects/shared-components/shell-communication/operations/risk/get-risk-manager-rule-violation-orders.class';
import { RiskManagerMessageDto } from 'projects/shared-components/shell-communication/dtos/risk-manager-message-dto.class';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { RiskManagementRuleEditedDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';

interface RiskManagerSecurityContext {
   createRule: boolean;
   updateRule: boolean;
   removeRule: boolean;
   unblockRule: boolean;
   clearViolations: boolean;
}

@Component({
   selector: 'ets-risk-management',
   templateUrl: './risk-management.component.html',
   styleUrls: ['./risk-management.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskManagementComponent implements OnInit {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _messageBus: MessageBusService,
      private readonly _toastr: ToastrService,
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _accessControlSvc: AccessControlService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('RiskManagementComponent');
   }


   @ViewChild(RiskRuleEditorComponent) ruleEditor: RiskRuleEditorComponent;

   securityContext: RiskManagerSecurityContext;
   rulesSectionSize: number;
   riskRulesGridModel: GridOptions;
   violationsGridModel: GridOptions;
   violationsSectionSize: number;
   
   private _isVisible = false;
   get isVisible(): boolean {
      return this._isVisible;
   }
   
   @DetectSetterChanges()
   set isVisible(v: boolean) {
      this._isVisible = v;
   }


   private readonly _riskRules: RiskRuleModel[] = [];
   private readonly _violations: RiskManagerRuleViolationDto[] = [];
   private readonly _violationOrders: OrderDto[] = [];
   private _riskRulesGrid: GridReadyEvent;
   private _violationsGrid: GridReadyEvent;
   private _selectedRule: RiskRuleModel;
   private _selectedViolation: RiskManagerRuleViolationDto;
   private _logger: Logger;
   private _unsubscriber = new Subject();


   get hasAnyViolations(): boolean {
      return this._violations.length > 0;
   }


   ngOnInit() {

      const isAvailable = (id) =>
         this._accessControlSvc.isSecureElementAvailable(id);

      this.securityContext = {
         createRule: isAvailable('709219f6-5dac-40db-b0a0-f175750d2af4'),
         updateRule: isAvailable('2b42e580-73ad-402f-98d1-1a50d61a34f4'),
         removeRule: isAvailable('dd94327e-3a7a-41f1-8ea2-cf209156194a'),
         unblockRule: isAvailable('eca250e7-ae1f-4c43-9474-bb411e1e70d3'),
         clearViolations: isAvailable('f3c768c6-9c88-40da-9b16-9b7da0576ea4')
      };

      this.riskRulesGridModel = getRiskRulesGridModel.bind(this)();

      this.violationsGridModel = getViolationsGridModel.bind(this)(
         this._displayNameService
      );
   }



   onRiskRulesGridReady(args: GridReadyEvent): void {
      this._riskRulesGrid = args;
      this._riskRulesGrid.api.sizeColumnsToFit();
   }

  
  
   async onViolationsGridReady(args: GridReadyEvent): Promise<void> {
      this._violationsGrid = args;
      this._violationsGrid.api.sizeColumnsToFit();

      const par = document.getElementById('risk_management_popup');
      this._violationsGrid.api.setPopupParent(par);
   }

   
   @DetectMethodChanges()
   onRiskRulesSelectionChanged(args: SelectionChangedEvent) {
      const selectedRows: RiskRuleModel[] = args.api.getSelectedRows();
      
      if (selectedRows.length > 0) {

         const selectedRow = selectedRows[0];
         
         selectedRow.hasUviewedViolations = false;
         
         this._selectedRule = selectedRow;
         
         const id = selectedRow.ruleId;
         
         const ruleViolations = this._violations.filter(
            v => v.riskManagerRuleId === id
         );
         
         this._violationsGrid.api.setRowData(ruleViolations);
         
         const violationIds = ruleViolations.map(x => x.violationId);
         
         const cmd = new MarkRiskManagerRuleViolationsAsViewed(violationIds);
         
         this._shellClient.processCommand(cmd).catch(err => console.error(err) );
      }
   }

   
   
   getOrdersByViolation(violation: RiskManagerRuleViolationDto): OrderDto[] {
      
      const orders = this._violationOrders.filter(
         x => x.violationId === violation.violationId
      );

      this._selectedViolation = violation;
      
      this._selectedViolation.orders = orders;

      return orders;
   }

   
   @DetectMethodChanges({isAsync: true})
   async removeViolation(data: RiskManagerRuleViolationDto): Promise<void> {
      
      if (!this._selectedRule) {
         this._toastr.error('Risk Rule Not Selected');
         return;
      }

      const cmd = new RemoveRiskManagerRuleViolations([data.violationId], this._selectedRule.ruleId);
      
      this._violationsGrid.api.showLoadingOverlay();
      
      try {
         
         await this._shellClient.processCommand(cmd);
         
         const ix = this._violations.indexOf(data);
         
         if (ix >= 0) {
            this._violations.splice(ix, 1);
            this._violationsGrid.api.setRowData(this._violations);
            this._selectedViolation = null;
         }

      } catch (e) {
         
         this._toastr.error('"Remove Violation" command completed with errors');
         this._logger.error('removeAllViolations()', e);

      } finally {

         this._violationsGrid.api.hideOverlay();
      }
   }

   
   @DetectMethodChanges({isAsync: true})
   async removeAllViolations(): Promise<void> {
      
      if (!this._selectedRule) {
         this._toastr.error('Risk Rule Not Selected');
         return;
      }

      const riskManagerRuleId = this._selectedRule.ruleId;
      const cmd = new RemoveRiskManagerRuleViolations(null, riskManagerRuleId);
      
      this._violationsGrid.api.showLoadingOverlay();

      try {

         await this._shellClient.processCommand(cmd);
         this._violationsGrid.api.setRowData([]);

         const filtered = this._violations.filter(x => x.riskManagerRuleId !== riskManagerRuleId);
         this._violations.length = 0;
         this._violations.push(...filtered);

         this._violationOrders.length = 0;
      } catch (e) {

         this._toastr.error('"Remove Violations" command completed with errors');
         const data = { error: e.stack || e };
         this._logger.error('removeAllViolations()', data);

      } finally {

         this._violationsGrid.api.hideOverlay();
      }
   }

   
   @DetectMethodChanges({isAsync: true})
   async unblockRule(data: RiskRuleModel): Promise<void> {
      
      const cmd = new UnblockRiskManagerRule(data.ruleId);
      
      this._riskRulesGrid.api.showLoadingOverlay();
      
      try {

         await this._shellClient.processCommand(cmd);
         data.isBlocked = false;

      } catch (e) {

         this._toastr.error('"Unblock Rule" command completed with errors');
         this._logger.error('unblockRule()', e);

      } finally {

         this._riskRulesGrid.api.hideOverlay();
      }
   }


   @DetectMethodChanges({isAsync: true})
   async removeRule(data: RiskRuleModel): Promise<void> {
      
      const cmd = new RemoveRiskManagerRule(data.ruleId);

      this._riskRulesGrid.api.showLoadingOverlay();

      try {
         await this._shellClient.processCommand(cmd);
         
         this._violationsGrid.api.setRowData([]);
         
         const ix = this._riskRules.indexOf(data);
         if (ix >= 0) {
            this._riskRules.splice(ix, 1);
            this._selectedRule = null;
            this._riskRulesGrid.api.setRowData(this._riskRules);
         }
         
         const violationsToRemove = this._violations.filter(
            x => x.riskManagerRuleId === data.ruleId
         );
         
         violationsToRemove.forEach(v => {
            const violationIx = this._violations.indexOf(v);
            if (violationIx >= 0) {
               this._violations.splice(violationIx, 1);
            }
         });
         
         const ordersToRemove = this._violationOrders.filter(
            x => x.riskManagerRuleId === data.ruleId
         );
         
         ordersToRemove.forEach(x => {
            const violationOrderIx = this._violationOrders.indexOf(x);
            if (violationOrderIx >= 0) {
               this._violationOrders.splice(violationOrderIx, 1);
            }
         });

      } catch (e) {
         
         this._toastr.error('"Remove Rule" operation completed with errors');
         this._logger.error('removeRule()', e);

      } finally {
         
         this._riskRulesGrid.api.hideOverlay();
      }
   }

   
   @DetectMethodChanges({isAsync: true})
   onViolationSelectionChanged(args: SelectionChangedEvent) {
      const selectedRows = args.api.getSelectedRows();
      
      if (selectedRows.length > 0) {
         this._selectedViolation = selectedRows[0];
      }
   }

   
   @DetectMethodChanges({isAsync: true})
   async showRuleEditor(rule?: RiskRuleModel) {
      await this.ruleEditor.show(rule);
   }

   
   @DetectMethodChanges()
   onHidden() {
      this.isVisible = false;

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
         this._unsubscriber = null;
      }

      this._resetState();
   }


   @DetectMethodChanges()
   async onShown() {
      this.rulesSectionSize = 70;
      this.violationsSectionSize = 30;

      this._subscribeToMessages();

      this._resetState();
      await this._tryLoadData();
   }

   
   
   private async _tryLoadData(): Promise<void> {
      
      this._riskRulesGrid.api.showLoadingOverlay();
      this._violationsGrid.api.showLoadingOverlay();
      
      try {
         // load rules
         const ruleDtos = await this._loadRules();
         const models = ruleDtos.map(this._createRiskRuleModel);
         this._riskRules.push(...models);

         // load violations
         const violationDtos = await this._loadViolations();
         this._violations.push(...violationDtos);

         // load orders
         const orderDtos = await this._loadViolationOrders();
         orderDtos.forEach(dto => {
            const extractResult = this._extractRiskRuleAndViolationIdFromOrderTag(
               dto.tags
            );
            if (!extractResult) {
               this._logger.warn('Violation orders has incorrect tags', dto);
            } else {
               const riskRuleId = extractResult[0];
               const violationId = extractResult[1];
               dto.riskManagerRuleId = riskRuleId;
               dto.violationId = violationId;
            }
         });

         this._violationOrders.push(...orderDtos);

         this._riskRules.forEach(rule => {
            rule.hasUviewedViolations = this._violations
               .filter(v => v.riskManagerRuleId === rule.ruleId)
               .some(x => !x.isViewedByUser);
         });

         this._riskRulesGrid.api.setRowData(this._riskRules);
      } catch (e) {
         
         this._toastr.error('"Risk Management" view loaded with errors');
         const data = { error: e.stack || e };
         this._logger.error('_tryLoadData()', data);

      } finally {

         this._riskRulesGrid.api.hideOverlay();
         this._violationsGrid.api.hideOverlay();
      }
   }

   
   
   private _resetState() {
      this._riskRulesGrid.api.setRowData([]);
      this._violationsGrid.api.setRowData([]);

      this._riskRules.length = 0;
      this._violations.length = 0;
      this._violationOrders.length = 0;

      this._selectedRule = null;
      this._selectedViolation = null;
   }

   
   
   private _loadRules(): Promise<RiskManagerRuleDto[]> {
      const qry = new GetRiskManagerRules();
      return this._shellClient.processQuery<RiskManagerRuleDto[]>(qry);
   }

   
   
   private _loadViolations(): Promise<RiskManagerRuleViolationDto[]> {
      const qry = new GetRiskManagerRuleViolations();
      return this._shellClient.processQuery<RiskManagerRuleViolationDto[]>(qry);
   }

   
   
   private _extractRiskRuleAndViolationIdFromOrderTag(tag: string): boolean | [string, string] {
      
      if (!tag) {
         return false;
      }

      tag = tag.toLowerCase();
      const startOfRiskManagerTag = tag.indexOf('rid=');
      if (startOfRiskManagerTag < 0) {
         return false;
      }

      const endOfRiskManagerTag = tag.indexOf(')', startOfRiskManagerTag);
      if (endOfRiskManagerTag < 0) {
         return false;
      }

      const rm_tag = tag.substring(startOfRiskManagerTag, endOfRiskManagerTag);

      const ridAndVid = rm_tag.split(';');
      if (ridAndVid.length !== 2) {
         this._logger.error(`Unexpected tag in risk manager order: ${rm_tag}`);
         return false;
      }

      const rid = ridAndVid[0].split('=');
      if (rid.length !== 2) {
         this._logger.error(
            `Unexpected tag format in risk manager order. Expected rid=[guid], but was ${ridAndVid[0]
            }`
         );
         return false;
      }

      const vid = ridAndVid[1].split('=');
      if (vid.length !== 2) {
         this._logger.error(
            `Unexpected tag format in risk manager order. Expected vid=[guid], but was ${ridAndVid[1]
            }`
         );
         return false;
      }

      return [rid[1], vid[1]];
   }

   
   
   private _loadViolationOrders(): Promise<OrderDto[]> {
      const qry = new GetRiskManagerRuleViolationOrders();
      return this._shellClient.processQuery<OrderDto[]>(qry);
   }

   
   
   private _subscribeToMessages(): void {
      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<RiskManagerMessageDto>('RiskManagerMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg =>
            setTimeout(
               () => this._onRiskManagerMessage(msg.payload),
               0
            )
         );

      this._messageBus.of<RiskManagementRuleEditedDto>('RiskManagementRuleEditedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this._onRuleEdited(dto.payload));

   }

   
   
   private _onRiskManagerMessage(message: RiskManagerMessageDto): void {
      if (message.violation) {
         this._processViolationMessage(message.violation);
      }

      if (message.violationOrder) {
         this._processViolationOrder(message.violationOrder);
      }

      if (message.positionLiquidationInfo) {
         const ruleId = message.positionLiquidationInfo.riskManagerRuleId;
         const existing = this._riskRules.find(
            x => x.ruleId === ruleId
         );
         existing.isViolationInProgress = false;
         existing.isBlocked = true;
      }
   }

   
   
   private _processViolationMessage(violation: RiskManagerRuleViolationDto) {
      const ruleForViolation = this._riskRules.find(
         x => x.ruleId === violation.riskManagerRuleId
      );

      if (!ruleForViolation) {
         this._logger.error('Received violation for unknown rule', { violation });
         return;
      }

      this._violations.push(violation);

      if (this._selectedRule === ruleForViolation) {
         const violations = this._violations.filter(
            x => x.riskManagerRuleId === ruleForViolation.ruleId
         );
         if (violations.length > 0) {
            this._violationsGrid.api.setRowData(violations);
         }
      } else {
         ruleForViolation.hasUviewedViolations = true;
      }

      ruleForViolation.isViolationInProgress = true;
   }

   
   
   private _processViolationOrder(violationOrder: OrderDto) {
      const existingOrder = this._violationOrders.find(
         x => x.orderId === violationOrder.orderId
      );
      if (existingOrder != null) {
         Object.assign(existingOrder, violationOrder);
      } else {
         const res = this._extractRiskRuleAndViolationIdFromOrderTag(
            violationOrder.tags
         );
         if (!res) {
            return;
         }

         const riskRuleId = res[0];
         const violationId = res[1];

         violationOrder.violationId = violationId;
         violationOrder.riskManagerRuleId = riskRuleId;

         this._violationOrders.push(violationOrder);

         if (
            this._selectedViolation &&
            this._selectedViolation.violationId === violationId
         ) {
            if (this._selectedViolation.orders) {
               this._selectedViolation.orders.push(violationOrder);
            } else {
               this._selectedViolation.orders = [violationOrder];
            }
         }
      }
   }

   
   
   private _onRuleEdited(dto: RiskManagementRuleEditedDto) {
      const rule = dto.riskManagementRule;

      const existed = this._riskRules.find(
         x => x.ruleId === rule.ruleId
      );

      if (existed) {
         existed.onRuleEdited(rule);
         this._riskRulesGrid.api.refreshCells();

      } else {

         const model = this._createRiskRuleModel(dto.riskManagementRule);
         this._riskRules.push(model);
         this._riskRulesGrid.api.setRowData(this._riskRules);
      }
   }


   _createRiskRuleModel(dto: RiskManagerRuleDto): RiskRuleModel {
      const model = new RiskRuleModel(dto);
      return model;
   }
}
