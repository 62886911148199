import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { DebitSpreadParameters} from './DebitSpreadParameters';
import { GetDebitSpreadSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-debit-spread-cashflow-strategy',
   templateUrl: './debit-spread-cashflow-strategy.component.html',
   styleUrls: ['./debit-spread-cashflow-strategy.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebitSpreadCashFlowStrategyComponent implements OnInit {
   
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }

   //
   private _automationCpMode: AutomationCpMode;
   public get automationCpMode(): AutomationCpMode {
      return this._automationCpMode;
   }
   @Input()
   @DetectSetterChanges()
   public set automationCpMode(value: AutomationCpMode) {
      this._automationCpMode = value;
   }

   //
   private _strategy: CashFlowStrategy;
   public get strategy(): CashFlowStrategy {
      return this._strategy;
   }
   @Input()
   @DetectSetterChanges()
   public set strategy(value: CashFlowStrategy) {
      this._strategy = value;
   }

   //
   @ViewChild('autoSingle', {static: true}) 
   autoSingleTemplate: ElementRef;

   //
   @ViewChild('autoDouble', {static: true}) 
   autoDoubleTemplate: ElementRef;

   //
   get isMultiDirection(): boolean {
      return this.strategy === 'Calls & Puts';
   }

   //
   get templateOutlet(): ElementRef {
      
      if (isVoid(this.automationCpMode)) {
         return undefined;
      }

      if (this.automationCpMode === 'Automated') {
         return this.isMultiDirection ? this.autoDoubleTemplate : this.autoSingleTemplate;
      } else {
         return undefined;
      }
   }

   //
   get cssClassForMainContainer(): string[] {
      const css = ['debit-spread'];
      
      if (this.isMultiDirection) {
         css.push('multi');
      }

      return css;
   }

   //
   get cssClassForParametersContainer(): string[] {
      const css = ['parameters'];
      
      if (this.isMultiDirection) {
         css.push('multi');
      }

      return css;
   }

   //
   parameters: DebitSpreadParameters = {};

   //
   parameters2: DebitSpreadParameters = {};

   //
   ngOnInit(): void { }

   //
   getParameters(): DebitSpreadParameters[] {
      
      const params = [];

      if (!this.isMultiDirection) {

         const single = JSON.parse(JSON.stringify(this.parameters));
         params.push(single);

      } else {

         const calls = JSON.parse(JSON.stringify(this.parameters));
         params.push(calls);

         const puts = JSON.parse(JSON.stringify(this.parameters2));
         params.push(puts);
      }

      return params;
   }

   //
   @DetectMethodChanges()
   setParameters(reply: GetDebitSpreadSectionDataModelReply) {
      this.parameters.shortOptionOffset = reply.shortOptionOffset;
      this.parameters.width = reply.spreadWidth;
      this.parameters.rollXDaysBeforeExpiration = reply.rollXDaysBeforeExpiration;
      this.parameters.rollDaysToExpiration = reply.rollToDaysToExpiration;

      this.parameters2.shortOptionOffset = reply.shortOptionOffset2;
      this.parameters2.width = reply.spreadWidth2;
      this.parameters2.rollXDaysBeforeExpiration = reply.rollXDaysBeforeExpiration2;
      this.parameters2.rollDaysToExpiration = reply.rollToDaysToExpiration2;

   }

   //
   @DetectMethodChanges()
   reset() {
      this.parameters = {};
      this.parameters2 = {};
   }

   //
   onParameterChanged(parameter: keyof DebitSpreadParameters) {

      if (parameter === 'width') {
         return;
      }

      this.parameters2[parameter] = this.parameters[parameter];
   }
}
