import { Injectable } from '@angular/core';
import { AccessControlService } from '../access-control-service.class';

@Injectable()
export class SymbolPickerAclService {
   constructor(private _accessControlService: AccessControlService) { }

   //
   get isAvailable(): boolean {
      const id = 'ac5dafff-64d1-46e1-8c15-9496dfd6fd39';
      return this._accessControlService
         .isSecureElementAvailable(id) || false;
   }

   //
   get isPopupAvailable(): boolean {
      const id = 'b8ab47ee-c5ec-497f-9903-a8d1db8a5dbd'
      return this._accessControlService
         .isSecureElementAvailable(id) || false;
   }

   //
   get isMruListAvailable(): boolean {
      const id = 'd551b22b-3ace-40ee-b58e-cfa3d7516384';
      return this._accessControlService
         .isSecureElementAvailable(id) || false;
   }
}