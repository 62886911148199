import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConvertToMarketSettings, OrderTypeSettingsDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-order-type-settings',
   templateUrl: './order-type-settings.component.html',
   styleUrls: [
      './order-type-settings.component.scss',
      '../common-fieldset.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTypeSettingsComponent implements OnInit {
  
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }

   //
   orderTypes = [
      'Limit',
      'Market'
   ];

   //
   private _orderType: 'Limit' | 'Market';
   get orderType(): 'Limit' | 'Market' {
      return this._orderType;
   }
   @DetectSetterChanges()
   set orderType(value: 'Limit' | 'Market') {
      this._orderType = value;
      if (value === 'Market') {
         this.convertToMarket = false;
      }
   }

   //
   get isLimitOrder(): boolean {
      return this.orderType === 'Limit';
   }

   //
   autoLimitPrices = [
      'Bid',
      'Ask',
      'Mid'
   ];

   //
   autoLimitPrice: 'Bid' | 'Ask' | 'Mid';

   //
   private _convertToMarket = false;
   get convertToMarket() : boolean {
      return this._convertToMarket;
   }
   @DetectSetterChanges()
   set convertToMarket(v : boolean) {
      this._convertToMarket = v;
      this.convertToMarketSettings = {
         replaceBeforeConvert: false
      };
   }
   
   //
   convertToMarketSettings: ConvertToMarketSettings = {}

   //
   ngOnInit(): void { }

   //
   @DetectMethodChanges({delay: 250})
   setData(orderTypeSettings: OrderTypeSettingsDto) {

      if (isVoid(orderTypeSettings)) {
         return;
      }
      
      this.orderType = orderTypeSettings.orderType as any;
      
      this.autoLimitPrice = orderTypeSettings.limitPrice as any;
      
      this.convertToMarket = orderTypeSettings.convertToMarket;

      this.convertToMarketSettings = orderTypeSettings.convertToMarketSettings;

      if (this.convertToMarketSettings.replacePersistently 
            || this.convertToMarketSettings.timesToReplace 
            || this.convertToMarketSettings.replaceEvery) {
               this.convertToMarketSettings.replaceBeforeConvert = true;
      }
   }

   //
   getParameters(): OrderTypeSettingsDto {

      const reply: OrderTypeSettingsDto = {
         orderType: this.orderType,
         limitPrice: this.autoLimitPrice,
         convertToMarket: this.convertToMarket,
         convertToMarketSettings: this.convertToMarketSettings
      };

      return reply;
   }

   //
   reset() {
      this.orderType = undefined;
      this.autoLimitPrice = undefined;
      this.convertToMarket = false;
      this.convertToMarketSettings = {
         replaceBeforeConvert: false
      };
   }
   
}
