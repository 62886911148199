import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RiskRuleModel } from './risk-rule-model';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: "is-blocked-cell",
  template: `
    <i class="blocked" *ngIf="data.isBlocked"></i>
    <i *ngIf="!data.isBlocked"></i>
  `,
  styles: [
      `
      .blocked {
        width: 20px;
        height: 20px;
        margin-top: 3px;
        text-align: center;
        background-repeat: no-repeat;
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAACdUlEQVQ4T6XQy08TQQAG8AbogYdQmi2h2ooItqXdtrS7WOkDjDFq4h/gRcNBuekB9aJGMR7ERA8QExMbtTxaCIUWoRStB98hYKwGwQdShGDwSdT/4HN26LYSSqjlS75kZnbml52RpJEspbLook6jahbGy0uZJ1utlrlDPTvxIOAEz2ncZC1jNI7V4M/8AdL9BLWDyxCl2FC3Fb/n9q5opN/232iWWiVzD/rM+PVpN2ZfOXHjGgt3qxELky66dr+fB2dJD80WsLteI5Zm6zA1ugusXo6jR7bi8CEVeCuDuQkH+ebCvT7run9KsQEvi6WYk/ZM03Zacd7YoEbbFS0d/yQd8VvWRCkW7DLgR8yRaGODCu42XWLe0lyJU8fLkntm7Aj3Vq9CyZsVugOdenz/WEtqT/SYALZqE/PLFypw+kTZij3fyJnhXnPyTRmGqW85X4Gv07X0o9gXDzlUVmwib1gEu01Oq9MWoop04jm/Yq9w9uolDWQyWZ0kPz+/xMQqx8NeLeZf1+DzZC0W3zsRCVrBMHkp+2SEw5cPLiy+c2DhjQ0RPwudhhkrKChQCFfOkUqlJkNVaTTUWYWZMY6Ux2CnAcXFubRyebLCPOI3ITbO031hHwtNJfOSGEZiZQugEAE1C+hgB4vpURuCHiO5Qm7KhrvNdE/Ia/4Xy1mmkkmgA+1m9Hss5HBeyg77OAx1cQRTrImJiaObox3Xq3G2Sbeq507q0HOTTwsTk0ADd2yYerYPb+MVxgMdDoKVpI2JiaNbon23HZh8epA22F6PHRlgYiiqJ6j/Vh0Cnj0bwsRQtLxM8bh8m+LRRjExAlAa7zqYRPIXiuOu0HNj6+IAAAAASUVORK5CYII=);
      }
    `
  ]
})
export class BlockedRuleCellRendererComponent
  implements ICellRendererAngularComp {
  public data: RiskRuleModel;

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
  }

  public refresh(): boolean {
    return false;
  }
}
