<div class="body" [style.visibility]="this.visibility" >
   
   <div class="navigation">

      <ets-automation-cp-body-navigation
         (navigate)="this.onNavigateClicked($event)"
         [combo]="this.combo"
         [automationCpMode]="this.automationCpMode"
         [strategy]="this.strategy"
         >
      </ets-automation-cp-body-navigation>
      
   </div>

   <div class="content">

      <dx-scroll-view>

         <div class="wrapper">

            <div class="section overview" id="overview">
               <ets-automation-cp-body-overview-section
                  id="overview-section"
                  [combo]="this.combo"
                  [automationCpMode]="this.automationCpMode"
                  [strategy]="this.strategy">
               </ets-automation-cp-body-overview-section>   
            </div>
   
            <div class="section working-orders" id="working-orders">
               <ets-automation-cp-body-working-orders-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-body-working-orders-section>
            </div>

            <div class="section messages" id="strategy-messages">
               <ets-automation-cp-strategy-messages-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-strategy-messages-section>
            </div>

            <div class="section tracker" id="cashflow-tracker">
               <ets-automation-cp-cashflow-tracker-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-cashflow-tracker-section>
            </div>
   
            <div class="section global-settings" id="global-settings">
               <ets-automation-cp-body-global-settings-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-body-global-settings-section>
            </div>
   
            <div class="section short-option" id="short-option">
               <ets-automation-cp-body-short-option-section
                  [combo]="this.combo"
                  [automationCpMode]="this.automationCpMode"
                  [strategy]="this.strategy"
                  >
               </ets-automation-cp-body-short-option-section>
            </div>
   
            <div class="section debit-spread" id="debit-spread">
               <ets-automation-cp-body-debit-spread-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-body-debit-spread-section>
            </div>

            <div class="section protective-option" id="protective-option">
               <ets-automation-cp-body-protective-option-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-body-protective-option-section>
            </div>

            <div class="section portfolio" id="portfolio">
               <ets-automation-cp-body-portfolio-section
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-body-portfolio-section>
            </div>

            <div class="section interest" id="interest">
               <ets-automation-cp-interest-section 
                  [combo]="this.combo"
                  [strategy]="this.strategy"
                  [automationCpMode]="this.automationCpMode"
                  >
               </ets-automation-cp-interest-section>
            </div>

         </div>

      </dx-scroll-view>

   </div>

</div>