import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';

export namespace Handlers {
  export interface MovingAverageCrossover {
    ma1Period?: number;
    ma2Period?: number;
    direction?: string;
    ticker?: string;
    timeframe?: string;
  }
}

export namespace Actions {
  export interface StartStrategiesByFamily {
    family?: string;
  }
  export interface StartSpecificStrategy {
    strategyId?: string;
  }
  export interface ExitStrategiesByFamily {
    family?: string;
  }
  export interface ExitSpecificStrategy {
    strategyId?: string;
  }

}


export interface StrategyCommanderEditorParameters {
  selectedInstrument: TradingInstrument;
  isSymbolPickerVisible: boolean;
  mode: 'create' | 'update';
  originalRuleId?: string;
  isRecurring: boolean,
  isDisabled: boolean,
  handlers: {
    maCrossover: Handlers.MovingAverageCrossover
  },
  actions: {
    startStrategiesByFamily: Actions.StartStrategiesByFamily;
    startSpecificStrategy: Actions.StartSpecificStrategy;
    exitStrategiesByFamily: Actions.ExitStrategiesByFamily;
    exitSpecificStrategy: Actions.ExitSpecificStrategy;
  }
}

const DEFAULT_PARAMETERS: StrategyCommanderEditorParameters = {
  selectedInstrument: null,
  isSymbolPickerVisible: false,
  mode: 'create',
  originalRuleId: null,
  isRecurring: false,
  isDisabled: false,
  handlers: {
    maCrossover: {}
  },
  actions: {
    startStrategiesByFamily: {},
    startSpecificStrategy: {},
    exitStrategiesByFamily: {},
    exitSpecificStrategy: {}
  }
};

export function getStrategyCommanderEditorDefaultParameters(): StrategyCommanderEditorParameters {
  return JSON.parse(JSON.stringify(DEFAULT_PARAMETERS));
}