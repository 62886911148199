<dx-popup
  (onHidden)="this.onHidden()"
  [(visible)]="this.visible"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [width]="350"
  height="auto"
  title="Custom Sort Hedges"
>
  <div *dxTemplate="let data of 'content'">
    
    <div class="content">
      <div class="calls side">
        <ng-container *ngTemplateOutlet="hedges; context: {hedgeList: this.calls, side: 'Calls'}">
        </ng-container>
      </div>
      
      <hr class="separator">
      
      <div class="puts side">
        <ng-container *ngTemplateOutlet="hedges; context: {hedgeList: this.puts, side: 'Puts'}">
        </ng-container>
      </div>
      
      <hr class="separator">
      
      <div class="dialog-buttons">
        <div class="button save">
          <dx-button width="100%" text="Save" (onClick)="this.onSaveClicked()"></dx-button>
        </div>
        <div class="button cancel">
          <dx-button width="100%" text="Cancel" (onClick)="this.onCancelClicked()"></dx-button>
        </div>
      </div>
      
    </div>
  </div>
</dx-popup>

<ng-template #hedges let-hedgeList="hedgeList" let-side="side">
  <div class="header">{{ side | uppercase }}</div>
  <div class="list-of-hedges">
    <div class="hedge" *ngFor="let hedge of hedgeList">
      <div class="name" [style.color]="hedge.groupColor">{{ hedge.groupLabel }}</div>
      <div class="buttons">
        <div class="button">
          <dx-button width="100%" class="up" (onClick)="this.moveHedge(hedge, 'up', side)">
            <i class="fas fa-angle-up"></i>
          </dx-button>
        </div>
        <div class="button">
          <dx-button width="100%" class="down" (onClick)="this.moveHedge(hedge, 'down', side)">
            <i class="fas fa-angle-down"></i>
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
