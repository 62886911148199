import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InterestCalculatorParameters } from '../adjustment-strategy-dialog/strategy-parameters/interest/interest-calculator-strategy-parameters';
import { SessionService } from '../authentication/session-service.service';
import { MessageBusService } from '../message-bus.service';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { ExitStrategies } from '../shell-communication/operations/strategies/exit-strategies.class';
import { StartStrategies } from '../shell-communication/operations/strategies/start-strategies.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { PortfolioItemAddedDto, PortfolioItemRemovedDto } from '../shell-communication/shell-dto-protocol';
import { CreateInterestStrategy, UpdateAdjustmentStrategy } from '../shell-communication/shell-operations-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategyState } from '../strategies/strategy-state.enum';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from '../utils';
import { AdjustmentBucketConfig } from './bucket-config';
import { InterestBlockViewModel } from './overview-blocks/interest-block/interest-overview-block.model';


export class InterestBlock {
   constructor(private readonly _changeDetector: ChangeDetectorRef,
               private _sessionService: SessionService,
               private _shellService: ShellClientService,
               private readonly _strategyIssuesService: StrategiesIssuesService,
               private readonly _toastr: ToastrService,
               private readonly _strategyService: StrategiesService,
               private readonly _messageBus: MessageBusService) {
      this.parameters = new InterestCalculatorParameters(_changeDetector, _sessionService);
      
      this._messageBus.of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .subscribe(x => this.onPortfolioItemAdded(x.payload));

      this._messageBus.of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .subscribe(x => this.onPortfolioItemRemoved(x.payload));

   }


   private _strategy: StrategyModel;
   private _bucketConfig: AdjustmentBucketConfig;

   get isVisible(): boolean {
      return !isNullOrUndefined(this.displayName);
   }

   blockId: string;
   displayName: string;
   sessionPnL: number;
   accumulatedPnL: number;

   get overviewBlockColor(): 'green' | 'red' | 'grey' {
      return this.getBlockColor();
   }


   get isStrategyRunning(): boolean {
      
      if (!this._strategy) {
         return false;
      }

      return (this._strategy.state & StrategyState.Enabled) === this._strategy.state;
   }

   get hasStrategy(): boolean {
      return !isNullOrUndefined(this._strategy);
   }
   

   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   get canExitStrategy(): boolean {
      if (!this._strategy) {
         return false;
      }

      if (this._strategy.state === StrategyState.Active) {
         return true;
      }

      return false;
   }

   //
   get canStartStrategy(): boolean {
      if (!this._strategy) {
         return false;
      }

      if (this._strategy.state === StrategyState.Inactive) {
         return true;
      }

      return false;
   }

   //
   get canUpdateStrategy(): boolean {
      return this.canStartStrategy;
   }

   parameters: InterestCalculatorParameters;

   //
   setStrategy(strategy: StrategyModel) {
      this._strategy = strategy;
      this.parameters.setParameters(strategy);
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async startStrategy() {
      const cmd = new StartStrategies([this._strategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async exitStrategy() {
      const cmd = new ExitStrategies([this._strategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async updateStrategy() {
      const parameters = this.parameters.getParameters();
      const displayName = this.parameters.displayName;

      const cmd = new UpdateAdjustmentStrategy(
         this._strategy.strategyId,
         displayName,
         parameters
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(cmd);

      } catch (e) {

         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async createStrategy() {

      const errors = this.parameters.validate(null);
      
      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e));
         return;
      }
      
      const parameters = this.parameters.getParameters();
      
      const displayName = this.parameters.displayName;

      const cmd = new CreateInterestStrategy(
         parameters,
         displayName,
         this.blockId,
         'ComboGroup'
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(cmd);

      } catch (e) {

         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   setBucket(bucketConfig: AdjustmentBucketConfig) {
      this._bucketConfig = bucketConfig;
   }
   
   //
   reset() {
      this._strategy = null;
      this.displayName = null;
      this.parameters.reset();
   }

   getViewModel(): InterestBlockViewModel {
      if (!this.parameters.amount) {
         return null;
      }

      const accountDto = this._sessionService.loginResult.availableAccounts.find(x => x.accountId === this.parameters.account);

      return {
         isStrategyRunning: this.isStrategyRunning,
         strategy: this._strategy,
         strategyIssues: this.getNumberOfIssues(),
         amount: this.parameters.amount,
         account: accountDto ? accountDto.accountCode : '{ID}',
         interestRate: this.parameters.rate * 100,
         interval: this.parameters.repeat
      };
   }

   private getBlockColor(): 'green' | 'red' | 'grey' {
      if (!this.displayName) {
         return 'grey';
      }

      if (!this.isStrategyRunning) {
         return 'red';
      }

      return 'green';
   }

   private getNumberOfIssues(): number {
      let result = 0;
      if (this._strategy) {
         result = this._strategyIssuesService
            .getStrategyIssuesCount(this._strategy.strategyId);
      }
      return result;
   }

   @DetectMethodChanges({delay: 300})
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto): void {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.portfolioItem.comboGroupId !== this._bucketConfig.comboGroupId) {
         return;
      }

      if (msg.portfolioItem.itemType === PortfolioItemType.Strategy) {
         if (msg.portfolioItem.algoName && msg.portfolioItem.algoName.toLowerCase().includes('interest')) {
            
            // hack for  race condition between 'strategy added' event and 'portfolio item added' event
            let cntr = 0;
            const i = setInterval(() => {
               
               cntr++;

               if (cntr > 50) {
                  clearInterval(i);
                  return;
               }

               const str = this._strategyService.getById(msg.portfolioItem.portfolioItemId);
               if (str) {
                  this._strategy = str;
                  clearInterval(i);
                  this._changeDetector.detectChanges();
               }
            }, 50);
         }
      }
   }
   
   @DetectMethodChanges()
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto): void {
      if (!this._bucketConfig) {
         return;
      }

      if (!this._strategy) {
         return;
      }
      
      if (msg.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      if (this._strategy.strategyId === msg.portfolioItemId) {
         this._strategy = null;
         this._changeDetector.detectChanges();
      }
   }
}
