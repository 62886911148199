<dx-scroll-view height="100%">
      
   <div class="input content-area">
      <dx-text-box [(value)]="this.parameters.displayName" placeholder="Strategy Display Name"
               (onValueChanged)="this.parameters.onChange($event)"></dx-text-box>
      
      <fieldset class="inner">
         <legend>Adjustment Mode</legend>
         
         <div class="input">
            <dx-select-box [items]="this.parameters.adjustmentStrategies"
                           [(value)]="this.parameters.adjustmentStrategy"
                           placeholder="Select Adjustment Mode"
                           (onValueChanged)="this.parameters.onChange($event)"
                           [showClearButton]="true"
            ></dx-select-box>   
         </div>

         <div class="input row-2">
            <div class="cell">
               <p>Debit</p>
               <dx-number-box [(value)]="this.parameters.debitCost"
                              (onValueChanged)="this.parameters.onChange($event)"
                              [disabled]="!this.parameters.isDebitCostAvailable"
                              [showSpinButtons]="true"
                              [showClearButton]="true"
                              [useLargeSpinButtons]="false"
                              [min]="0"
                              [step]="0.01"
                              format="#0%"
               ></dx-number-box>
            </div>

            <div class="cell">
               <p>Credit</p>
               <dx-number-box [(value)]="this.parameters.creditCost"
                              (onValueChanged)="this.parameters.onChange($event)"
                              [disabled]="!this.parameters.isCreditCostAvailable"
                              [showSpinButtons]="true"
                              [showClearButton]="true"
                              [useLargeSpinButtons]="false"
                              [min]="0"
                              [step]="0.01"
                              format="#0%"
               ></dx-number-box>
            </div>
         </div>   
      </fieldset>

      <fieldset class="inner">
         <legend>Range Select Mode</legend>

         <div class="input">
            <dx-select-box [items]="this.parameters.rangeSelectStrategies"
                           [(value)]="this.parameters.rangeSelectStrategy"
                           (onValueChanged)="this.parameters.onChange($event)"
                           placeholder="Select Range Mode"
            ></dx-select-box>
         </div>

         <div class="input row-2">
            <div class="cell">
               <p>Strike Range</p>
               <dx-number-box [(value)]="this.parameters.strikeRange"
                              (onValueChanged)="this.parameters.onChange($event)"
                              [disabled]="!this.parameters.isStrikeRangeAvailable"
                              [min]="1"
                              [showSpinButtons]="true"
                              [showClearButton]="true"
                              [useLargeSpinButtons]="false"
               ></dx-number-box>
            </div>

            <div class="cell">
               <p>Dollar Range</p>
               <dx-number-box [(value)]="this.parameters.dollarRange"
                              (onValueChanged)="this.parameters.onChange($event)"
                              [disabled]="!this.parameters.isDollarRangeAvailable"
                              [min]="0"
                              [showSpinButtons]="true"
                              [showClearButton]="true"
                              [useLargeSpinButtons]="false"
                              format="$#0"
               ></dx-number-box>
            </div>   
         </div>

      </fieldset>

      <div class="input">
         <p>Order Type</p>
         <dx-select-box [items]="this.parameters.orderTypes" displayExpr="name" valueExpr="value" 
                        [(value)]="this.parameters.orderType"
                        (onValueChanged)="this.parameters.onChange($event)"
         ></dx-select-box>

         <div *ngIf="this.parameters.orderType === 1" style="margin-top: 10px">

            <fieldset style="margin-top: 5px;">
               <legend>
                  <dx-check-box #ctm text="Convert To Market" [(value)]="this.parameters.convertToMarket"
                     (onValueChanged)="this.parameters.onChange($event)"></dx-check-box>
               </legend>
         
               <div *ngIf="ctm.value">
                  <ets-convert-to-market  [settings]="this.parameters.convertToMarketSettings"
                     [hideCalendar]="true">
                  </ets-convert-to-market>
   
                  <div class="input" *ngIf="this.parameters.adjustmentStrategy === '2-Sides'">
                     <dx-select-box [items]="['Debit', 'Credit']"
                                    [(value)]="this.parameters.convertSide"
                                    (onValueChanged)="this.parameters.onChange($event)"
                                    placeholder="Convert Side"
                                    [showClearButton]="true"
                                    [disabled]="!ctm.value"
                     ></dx-select-box>   
                  </div>
   
               </div>
   
            </fieldset>

         </div>
         
      </div>

   </div>

</dx-scroll-view>