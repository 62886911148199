import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DetectSetterChanges, DxValueChanged, isVoid} from "../../../utils";
import {ChecklistEditorComponent} from "../../checklist-editor.component";
import {ChecklistStep} from "../../../adjustment-pricing-grid/popup/checklist/ChecklistStep";

@Component({
    selector: 'ets-checklist-extra-pos-condition',
    template: `
      <div>
          <dx-check-box 
            [(value)]="this.extraPos"
            (onValueChanged)="this.onValueChanged($event)"
            [disabled]="this.disabled"
          >
          </dx-check-box>
      </div>
    `,
    styles: [
        '.input {text-align: center}'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChecklistExtraPosConditionComponent implements OnInit, AfterViewInit {
    constructor(
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    private _oldValue = false;

    extraPos = false;

    @Input() comp: ChecklistEditorComponent;


    ngOnInit() {
    }

    ngAfterViewInit() {
        this._changeDetector.detach();
    }

    get disabled(): boolean {
        return isVoid(this.step);
    }

    private _step: ChecklistStep;
    get step(): ChecklistStep {
        return this._step;
    }
    @DetectSetterChanges()
    @Input()
    set step(value: ChecklistStep) {
        this._step = value;

        if (this._step) {
            this._oldValue = this._step.hasExtraPosCondition;
        }

        setTimeout(() => {
            try {
                if (isVoid(this._step)) {
                    this.extraPos = false;
                    return;
                }
                this.extraPos = this._step.hasExtraPosCondition || false;
            } finally {
                this._changeDetector.detectChanges();
            }
        });
    }

    onValueChanged($event: DxValueChanged<boolean>) {
        const resource = this.step;
        if (isVoid(resource)) {
            return;
        }
        if (isVoid($event.event)) {
            return;
        }

        resource.hasExtraPosCondition = this.extraPos;

        if (this._oldValue === resource.hasExtraPosCondition) {
            return;
        }

        resource.isDirty = true;
        this.comp.onChange({});
    }
}