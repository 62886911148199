import { Component, EventEmitter, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IDatafeedChartApi } from '../price-chart/data-feed-chart-api';
import {
   ChartingLibraryWidgetOptions,
   IChartingLibraryWidget,
   widget
} from '../../webtrader/src/assets/tv/charting_library/charting_library.min';
import { ToastrService } from 'ngx-toastr';
import * as shortid from 'shortid';
import { OpenPositionDataFeedFactoryService } from './open-position-data-feed-factory.service';
import { takeUntil, throttleTime, filter, last } from 'rxjs/operators';
import { Subject, Subscriber } from 'rxjs';
import { SettingsStorageService } from '../settings-storage-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { DetectSetterChanges, getPanelStateKey } from 'projects/shared-components/utils';
import { OpenPositionChartStateInfo } from './open-position-chart-state-info.interface';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { OpenPositionChartSubscription } from './open-position-chart-subscription.interface';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategyModel } from '../strategies/strategy-model';
import { PanelBaseComponent } from '../panels/panel-base.component';
import {UserSettingsService} from "../user-settings.service";

interface OpenPositionChartPanelState {
   stateInfo: OpenPositionChartStateInfo;
   chartState: any;
   isLinkedToStrategies: boolean;
   isLinkedToSymbol: boolean;
}

@Component({
   selector: 'ets-open-position-chart',
   templateUrl: 'open-position-chart.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenPositionChartComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _toastr: ToastrService,
      private readonly _dataFeedFactory: OpenPositionDataFeedFactoryService,
      private readonly _strategiesService: StrategiesService,
   ) {
      
      super(_changeDetector, _userSettingsService, _messageBus);

      this.chartId = shortid.generate();
   }
   
   private _stateChanged = new EventEmitter();
   private _isChartInitialized: boolean;
   private _datafeed: IDatafeedChartApi;
   private _tvWidget: IChartingLibraryWidget | null = null;
   private _stateInfo: OpenPositionChartStateInfo;
   private _unsubscriber: Subject<any> = new Subject<any>();
   private _listenToSelectedStrategy: boolean;
   
   readonly chartId: string;
   

   get stateInfo(): OpenPositionChartStateInfo {
      return this._stateInfo;
   }

   set stateInfo(value: OpenPositionChartStateInfo) {
      this._stateInfo = value;
      if (value) {
         if (value.panelId) {
            this.panelId = value.panelId;
         }
         this._stateChanged.emit();
      }
   }

   
   etsOnInit(): void { }

   
   etsAfterViewInit(): void {
      if (!this._isChartInitialized) {
         this._initChart();
         this._isChartInitialized = true;
      }
   }

   
   etsOnDestroy(): void {
      const feed: any = this._datafeed;
      if (feed) {
         feed.dispose();
      }

      this._unsubscriber.next();
      this._unsubscriber.complete();

      if (this._tvWidget) {
         const etsSubs: Subscriber<void> = this._tvWidget.etsSymbolSubscription;
         if (etsSubs) {
            etsSubs.unsubscribe();
         }
      }
   }

   
   private _initChart(): void {
      this.isLoading = true;

      this._stateChanged
         .pipe(
            throttleTime(250),
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => this.saveState());

      const key = getPanelStateKey(this);
      const item = this._userSettingsService.getValue<any>(key);
      let state: OpenPositionChartPanelState = item;

      if (state) {
         this.stateInfo = state.stateInfo;
         this._listenToSelectedStrategy = state.isLinkedToStrategies;
         this.isLinkedToSymbol = state.isLinkedToSymbol;
      }

      const containerId = `openposchart_${this.chartId}`;

      const dataFeed = this._dataFeedFactory.getDataFeed(containerId, () => this._tvWidget);

      this._datafeed = dataFeed;

      let subscription: OpenPositionChartSubscription;
      if (this.stateInfo) {
         subscription = this.stateInfo.subscription;
      }

      if (!subscription) {
         const lastHighlightedStrategy = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage', this.layoutTabId);
         if (!lastHighlightedStrategy) {
            const strats: StrategyModel[] = this._strategiesService.getAllStrategies();
            if (strats.length > 0) {
               subscription = { strategyId: strats[0].strategyId } as any;
            } else {
               subscription = { strategyId: 'N/A' } as any;
            }
         } else {
            subscription = { strategyId: lastHighlightedStrategy.payload.strategyId } as any;
         }
      }

      const widgetOptions: ChartingLibraryWidgetOptions = {
         symbol: `${subscription.strategyId}`,
         datafeed: dataFeed,
         timezone: 'Etc/UTC',
         interval: '10S',
         container_id: containerId,
         library_path: '/assets/tv/charting_library/',
         locale: 'en',
         disabled_features: [
            'header_resolutions',
            'header_chart_type',
            'header_settings',
            'header_indicators',
            'header_compare',
            'header_undo_redo',
            'header_screenshot',
            'header_saveload',
            'compare_symbol',
            'timeframes_toolbar',
            // 'context_menus',
            'use_localstorage_for_settings',
            'create_volume_indicator_by_default',
            'delete_button_in_legend',
            'left_toolbar',
            'show_hide_button_in_legend'
         ],
         enabled_features: ['no_min_chart_width', 'cl_feed_return_all_data', 'seconds_resolution'],
         charts_storage_url: 'https://saveload.tradingview.com',
         charts_storage_api_version: '1.1',
         client_id: 'ets',
         user_id: 'ets',
         fullscreen: false,
         autosize: true,
         theme: 'dark',
         saved_data: state && state.chartState ? state.chartState : undefined,
         studies_access: {
            type: 'white',
            tools: [{ name: 'Sessions' }]
         },
         debug: true,
         overrides: {
            'mainSeriesProperties.style': 2
         },
         studies_overrides: { 'Overlay.style': 2 }
      };

      this._tvWidget = new widget(widgetOptions);

      this._tvWidget.onChartReady(() => {
         this.isLoading = false;

         this._tvWidget.subscribe('onAutoSaveNeeded', () => {
            this.saveState();
         });

         this._tvWidget.onContextMenu((unixtime, price) => {
            if (!this._datafeed) {
               return [];
            }

            return [
               {
                  position: 'top',
                  text: this._listenToSelectedStrategy ? 'Unlink from selected strategy...' : 'Link to selected strategy...',
                  click: () => {

                     this._listenToSelectedStrategy = !this._listenToSelectedStrategy;

                     if (this._listenToSelectedStrategy) {
                        this._subscribeToStrategySelectEvent();
                     } else {
                        const s: Subscriber<void> = this._tvWidget.etsSymbolSubscription;
                        if (s) {
                           s.unsubscribe();
                           delete this._tvWidget.etsSymbolSubscription;
                        }
                     }
                  },
               },
               {
                  position: 'top',
                  text: '-'
               },
               {
                  text: '-Reset Chart'
               },
               {
                  position: 'top',
                  text: '-Change Interval...'
               },
               {
                  position: 'top',
                  text: '-Symbol Info...'
               },
               {
                  position: 'top',
                  text: '-Lock Cursor in Time'
               },
               {
                  position: 'top',
                  text: '-Objects Tree...'
               },
               {
                  position: 'top',
                  text: '-Insert Indicator...'
               },
               {
                  position: 'top',
                  text: '-Drawing Tools'
               },
               {
                  position: 'top',
                  text: '-Hide Marks On Bars'
               },
               {
                  position: 'top',
                  text: '-Remove'
               },
               {
                  position: 'top',
                  text: '-Properties...'
               }
            ];
         });

         if (this._listenToSelectedStrategy) {
            this._subscribeToStrategySelectEvent();
         }
      });
   }

   
   private _subscribeToStrategySelectEvent() {
      const etsSubscription = this._messageBus.of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(filter(x => x.scopeId === this.layoutTabId))
         .subscribe(x => {
            this._tvWidget.setSymbol(x.payload.strategyId, '10S', () => { });
         });
      this._tvWidget.etsSymbolSubscription = etsSubscription;
   }

   
   protected getState(): any {
      
      const tvWidget = this._tvWidget;

      if (!tvWidget) { return null; }
      
      let state: OpenPositionChartPanelState = null;

      tvWidget.save( chartState => {

         state = { 
            stateInfo: this.stateInfo, 
            chartState, 
            isLinkedToStrategies: this._listenToSelectedStrategy,
            isLinkedToSymbol: this.isLinkedToSymbol
         };


      });

      return state;
   }


   protected setState(state) { }
}
