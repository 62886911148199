import {EventEmitter} from '@angular/core';
import {HedgePositionsService} from "../../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {MessageBusService} from "../../message-bus.service";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";
import {HedgePosition} from "../../hedging-grid/data-model/hedge-position";
import {Subscription} from "rxjs";
import {clone, deepCloneObject, findHCF, isValidNumber, isVoid} from "../../utils";
import * as Enumerable from "linq";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {ServiceConfiguration} from "../../adjustment-pricing-grid/services/ServiceConfiguration";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {parseOptionTicker, zipOptionTicker} from "../../options-common/options.model";
import {isNullOrUndefined} from "util";

export interface HedgePriceChangedEventArgs {
    calls: number;
    puts: number;
    total: number;
    portfolioKey: string;
    delta: number;
}

interface HedgePositionsContainer {
    delta: number;
    positions: HedgePosition[];
    calls?: number;
    puts?: number;
    total?: number;
}

export class HedgesPricingService {

    constructor(
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService,
        private readonly _apgDataService: ApgDataService,
    ) {
    }

    private _subscriptions: Subscription[] = [];
    private _hedgePositionsByPortfolio: { [ix: string]: HedgePositionsContainer[] } = {};
    private _defaultQtyByPortfolio: {[ix: string]: number} = {};
    private _hedgeCostByGroup: { [ix: string]: number } = {};

    hedgePriceChanged$ = new EventEmitter<HedgePriceChangedEventArgs>();

    init() {
        this._subscriptions.push(
            this._messageBus.of<QuoteDto[]>('QuoteDto')
                .subscribe(x => this.onQuote(x.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<{ portfolio: ApgPortfolio, positions: HedgePosition[] }>('Hg.InitialPositionsChanged')
                .subscribe(x => this.onHedgePositionsChanged(x.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<{ portfolio: ApgPortfolio }>('Hg.HedgesCleared')
                .subscribe(x => this.onHedgesCleared(x.payload))
        );
    }

    async subscribe(portfolio: ApgPortfolio, delta = 0) {

        if (isVoid(portfolio)) {
            return;
        }

        const key = `${portfolio.userId}@${portfolio.id}`;

        let containers : HedgePositionsContainer[];

        if (key in this._hedgePositionsByPortfolio) {

            containers = this._hedgePositionsByPortfolio[key];

            if (containers.length > 0) {
                const existingContainer = containers.find(x => x.delta === delta);
                if (!isVoid(existingContainer)) {
                    setTimeout(() => {
                        this.hedgePriceChanged$.emit({
                            calls: existingContainer.calls,
                            puts: existingContainer.puts,
                            total: existingContainer.total,
                            portfolioKey: key,
                            delta: existingContainer.delta,
                        });
                    });
                    return;
                }
            }
        } else {
            containers = [];
            this._hedgePositionsByPortfolio[key] = containers;
        }

        let newContainer : HedgePositionsContainer = { delta, positions: [] };
        containers.push(newContainer);

        const config: ServiceConfiguration = {
            userId: portfolio.userId,
            userName: portfolio.userName,
            orientation: undefined
        };

        this._hedgePositionsService.configure(config);

        let positions = await this._hedgePositionsService.getHedgePositions(portfolio);

        positions = deepCloneObject(positions);

        if (delta !== 0) {
            positions.forEach(pos => {
                const tickerObj = parseOptionTicker(pos.ticker);
                tickerObj.strike = tickerObj.strike - delta;
                pos.ticker = zipOptionTicker(tickerObj);
                pos.strike = tickerObj.strike;
            });
        }

        const portfolioDefaultQty = await this._apgDataService.getDefaultQtyForPortfolio(portfolio)

        this._defaultQtyByPortfolio[key] = portfolioDefaultQty;

        this.changeMarketDataSubscription(positions, key);

        newContainer.positions = positions;
    }

    onQuote(payload: QuoteDto[]) {

        for (const portfolioKey in this._hedgePositionsByPortfolio) {

            const containers = this._hedgePositionsByPortfolio[portfolioKey];

            for (const container of containers) {

                const positions = container.positions;

                if (isVoid(positions)) {
                    continue;
                }

                positions.forEach(x => {
                    const aQuote = payload.find(q => q.ticker === x.ticker);
                    if (isVoid(aQuote)) {
                        return;
                    }
                    x.price = aQuote.mid;
                });

                this.calculateTotals(portfolioKey);
            }
        }
    }

    dispose() {
        for (const pfKey in this._hedgePositionsService) {
            this.changeMarketDataSubscription([], pfKey)
        }

        this._subscriptions.forEach(x => x.unsubscribe());
    }

    getHedgesCost(key: string, type: "calls" | "puts" | "total", delta = 0): number {

        const containers = this._hedgePositionsByPortfolio[key];

        if (isVoid(containers)) {
            return undefined;
        }

        const targetContainer = containers.find(x => x.delta === delta);

        if (isVoid(targetContainer)) {
            return undefined;
        }

        return targetContainer[type];
    }

    getHedgeGroupCost(groupId: string): number {
        const hedgeCost = this._hedgeCostByGroup[groupId];
        return hedgeCost;
    }

    private changeMarketDataSubscription(newPositions: HedgePosition[], key: string) {

        const containers = this._hedgePositionsByPortfolio[key];

        if (isVoid(containers)) {
            return;
        }

        for (const container of containers) {

            const oldPositions = container.positions || [];

            const oldTickers = oldPositions.map(x => x.ticker);

            const freshPositions : HedgePosition[] = deepCloneObject(newPositions);

            if (container.delta !== 0) {
                freshPositions.forEach(freshPosition => {
                    const tickerObj = parseOptionTicker(freshPosition.ticker);
                    tickerObj.strike = tickerObj.strike - container.delta;
                    freshPosition.ticker = zipOptionTicker(tickerObj);
                    freshPosition.strike = tickerObj.strike;
                });
            }

            const newTickers = newPositions.map(x => x.ticker);

            this._lastQuoteCache.subscribeTickersDiff(oldTickers, newTickers);

            newPositions.forEach(hp => {
                const lastQuote = this._lastQuoteCache.getLastQuote(hp.ticker);
                if (isVoid(lastQuote)) {
                    return;
                }
                hp.price = lastQuote.mid;
            });

            this.calculateTotals(key);
        }
    }

    private calculateTotals(key: string) {

        const containers = this._hedgePositionsByPortfolio[key];

        for (let container of containers) {

            let calls = undefined;
            let puts = undefined;

            if (isVoid(container)) {
                return;
            }

            const positions = container.positions || [];

            const baseQty = this._defaultQtyByPortfolio[key] || 1;

            Enumerable.from(positions)
                .groupBy(x => x.type)
                .forEach(grp => {

                    const hedgeGroups = grp.groupBy(x => x.groupId);

                    hedgeGroups.forEach(hg => {
                        const qtties = hg.select(pos => Math.abs(pos.qty)).toArray();

                        const hcf = findHCF(qtties);

                        const prices = hg.select(pos => {
                            let px = pos.price * pos.qty / hcf;
                            const ratio = hcf / baseQty;
                            if (isValidNumber(ratio, true)) {
                                px = px * ratio;
                            }
                            return px;
                        }).sum();

                        this._hedgeCostByGroup[hg.key()] = prices;

                        if (isValidNumber(prices)) {
                            if (grp.key() === 'Call') {
                                calls = (calls || 0) + prices;
                            } else if (grp.key() === 'Put') {
                                puts = (puts || 0) + prices;
                            }
                        }
                    });
                });

            let total: number;

            if (isValidNumber(calls) || isValidNumber(puts)) {
                total = (calls || 0) + (puts || 0);
            } else {
                total = undefined;
            }

            container.calls = calls;
            container.puts = puts;
            container.total = total;

            this.hedgePriceChanged$.emit({calls, puts, total, portfolioKey: key, delta: container.delta});
        }
    }

    private onHedgePositionsChanged(payload: { portfolio: ApgPortfolio, positions: HedgePosition[] }) {

        const key = `${payload.portfolio?.userId}@${payload.portfolio?.id}`;

        const containers = this._hedgePositionsByPortfolio[key];

        if (isNullOrUndefined(containers)) {
            return;
        }

        if (isVoid(containers)) {
            containers.push({delta: 0, positions: []});
        }

        for (const container of containers) {

            this.changeMarketDataSubscription(payload?.positions || [], key);

            container.positions = payload.positions;

            this.calculateTotals(key);
        }
    }

    private onHedgesCleared(payload: {portfolio: ApgPortfolio}) {
        this.onHedgePositionsChanged({portfolio: payload.portfolio, positions: []});
    }
}