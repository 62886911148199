import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ShellMessageDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectSetterChanges, DetectMethodChanges } from 'projects/shared-components/utils';
import { ShellMessagesService } from '../shell-messages.service';
import { TimestampsService } from 'projects/shared-components/timestamps.service';

class StickyMessagesDialog {
   constructor(private _changeDetector: ChangeDetectorRef) {}


   private _isVisible: boolean;
   public get isVisible(): boolean {
      return this._isVisible;
   }

   @DetectSetterChanges()
   public set isVisible(v: boolean) {
      this._isVisible = v;
   }


   private _showCloseButton = false;
   public get showCloseButton(): boolean {
      return this._showCloseButton;
   }

   @DetectSetterChanges()
   public set showCloseButton(v: boolean) {
      this._showCloseButton = v;
   }


   private _messages: ShellMessageDto[] = [];
   public get messages(): ShellMessageDto[] {
      return this._messages;
   }

   @DetectSetterChanges()
   public set messages(v: ShellMessageDto[]) {
      this._messages = v;
   }


   private _currentMessageIndex = 0;
   public get currentMessageIndex(): number {
      return this._currentMessageIndex;
   }

   @DetectSetterChanges()
   public set currentMessageIndex(v: number) {
      this._currentMessageIndex = v;
   }

   get canSlideNext(): boolean {
      return this.currentMessageIndex < this.messages.length - 1;
   }

   get canSlidePrev(): boolean {
      return this.currentMessageIndex > 0;
   }

   isSnoozed = false;

   @DetectMethodChanges()
   show() {
      if (this.isVisible) {
         return;
      }
      this.isVisible = true;
   }

   @DetectMethodChanges()
   addMessage(msg: ShellMessageDto) {
      this.messages.push(msg);
   }


   @DetectMethodChanges()
   onHidden() {
      this.isVisible = false;

      if (!this.isSnoozed) {
         this.messages = [];
         this.currentMessageIndex = 0;
      }
   }

   @DetectMethodChanges()
   onShown() {
      this.isSnoozed = false;
      if (this.messages.length === 1) {
         setTimeout(() => {
            this.showCloseButton = true;
         }, 3000);
      }
   }

   @DetectMethodChanges()
   moveNextMessage(): void {
      const nextMsgIndex = this.currentMessageIndex + 1;
      if (nextMsgIndex < this.messages.length) {

         this.currentMessageIndex = nextMsgIndex;

         if (nextMsgIndex === this.messages.length - 1) {
            this.showCloseButton = true;
         }
      }
   }

   @DetectMethodChanges()
   movePrevMessage(): void {
      const prevMessageIndex = this.currentMessageIndex - 1;
      if (prevMessageIndex >= 0) {
         this.currentMessageIndex = prevMessageIndex;
      }
   }

   snooze(time: SnoozeTime): void {
      let snoozeTime  = 1000 * 60; // minute

      if (time === '1 min') {

      } else if (time === '5 min') {
         snoozeTime *= 5;
      } else if (time === '15 min') {
         snoozeTime *= 15;
      } else if (time === '30 min') {
         snoozeTime *= 30;
      } else if (time === '1 hour') {
         snoozeTime *= 60;
      }

      this.isSnoozed = true;

      this.isVisible = false;

      setTimeout(() => this.isVisible = true, snoozeTime);
   }

}

type SnoozeTime = '1 min' | '5 min' | '15 min' | '30 min' | '1 hour';


@Component({
   selector: 'ets-shell-messages-overlay',
   templateUrl: 'shell-messages-overlay.component.html',
   styleUrls: ['shell-messages-overlay.component.scss'],
   providers: [
      { provide: 'WINDOW', useValue: window }
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellMessagesOverlayComponent implements OnInit, OnDestroy, AfterViewInit {

   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _toastrService: ToastrService,
      private _shellMessagesService: ShellMessagesService,
      private _timestampsService: TimestampsService,
   ) {
      this.stickyMessagesDialog = new StickyMessagesDialog(this._changeDetector);
      this.messagesIndicator = { important: 0, warn: 0, regular: 0 };
   }

   private _unsubscriber: Subject<any> = new Subject<any>();

   messagesIndicator: { important: number; warn: number; regular: number; };

   overlayPosition: { left: number; bottom: number };

   stickyMessagesDialog: StickyMessagesDialog;

   private _isOverlayVisible = false;
   get isOverlayVisible() { return this._isOverlayVisible; }

   @DetectSetterChanges()
   set isOverlayVisible(value: boolean) {
      this._isOverlayVisible = value;
   }

   ngOnInit() {

      this._shellMessagesService.shellMessageReceived$
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onShellMessage(msg));

      this._shellMessagesService.closeOverlay$
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.isOverlayVisible = false);
   }

   //

   onValueChanged() {
      this._changeDetector.detectChanges();
   }

   //

   ngAfterViewInit(): void {
      this._changeDetector.detach();
   }


   onActionButtonClick($event: any): void {
      this.isOverlayVisible = true;
   }

   //

   @DetectMethodChanges()
   onHidden() {
      this.messagesIndicator.important = 0;
      this.messagesIndicator.warn = 0;
      this.messagesIndicator.regular = 0;
      this.isOverlayVisible = false;
   }

   //

   ngOnDestroy(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }


   @DetectMethodChanges()
   private onShellMessage(msg: ShellMessageDto) {

      if (!this.isOverlayVisible) {

         if (msg.level === 'Critical') {

            this.messagesIndicator.important += 1;


         } else {

            this.messagesIndicator.regular += 1;

         }
      }

      if (msg.sticky) {
         this.stickyMessagesDialog.addMessage(msg);
         this.stickyMessagesDialog.show();
      }
   }

   formatMessageTime(msg: ShellMessageDto): string {
      if (!msg) {
         return undefined;
      }
      const frmtTime = this._timestampsService.getDefaultFormattedDateTime(msg.timestamp);
      return frmtTime;
   }
}
