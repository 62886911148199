import { ChangeDetectorRef, ElementRef } from "@angular/core";
import { DetectMethodChanges, DetectSetterChanges, isVoid } from "projects/shared-components/utils";
import { ApgChecklistService } from "./apg-checklist.service";
import { ChecklistStep } from "./ChecklistStep";
import { CashFlowAdjustment } from "../../model/CashFlowAdjustment";
import {RegroupedOrders} from "../../../orders-regroup-dialog/RegroupedOrders";

export class ChecklistPopupModel {
 
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _ackChecklistCallback: () => void,
      private _checklistService: ApgChecklistService
   ) {

   }
   
   private _checklistContainerResolver: () => ElementRef;
   
   //
   private _isVisible : boolean;
   public get isVisible() : boolean {
      return this._isVisible;
   }
   @DetectSetterChanges()
   public set isVisible(v : boolean) {
      this._isVisible = v;
   }

   //
   private _isLoading : boolean;
   public get isLoading() : boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   public set isLoading(v : boolean) {
      this._isLoading = v;
   }

   //
   get isDoubleMode(): boolean {
      return this.checklists.length == 2;
   }
   
   //
   get height() {
      return '90vh';
   }

   //
   get width() {
      return this.isDoubleMode ? '93vw' : '53vw';
   }

    //
    get maxWidth() {
      return this.isDoubleMode ? '1600' : '800px';
   }

   //
   get maxHeight() {
      return this.isDoubleMode ? '900px' : '700px';
   }

   //
   selectedPricing: CashFlowAdjustment;

   regroupedOrders: RegroupedOrders[];

   //
   checklists: ChecklistStep[][] = [];

   //
   @DetectMethodChanges()
   show(checklists: ChecklistStep[][], selectedPricing: CashFlowAdjustment, regrouped?: RegroupedOrders[]) {
      this.selectedPricing = selectedPricing;
      this.regroupedOrders = regrouped;
      this.checklists = checklists;
      this.isVisible = true;     
   }

   //
   onPopupHidden() {
      this.checklists = [];
      this.isVisible = false;
   }

   //
   onConfirmed() {
      if (!isVoid(this._ackChecklistCallback)) {
         this._ackChecklistCallback();
      }
      this.isVisible = false;
   }

   //
   setChecklistContainer(checkListContainerResolver: () => ElementRef) {
      this._checklistContainerResolver = 
         checkListContainerResolver;
   }

   //
   getChecklistTitle(checklist: ChecklistStep[]): string {
      let defaultTitle = 'N/A';
      
      if (isVoid(checklist)) {
         return defaultTitle;
      }
      const strategy = checklist[0].strategy || 'N/A';
      const adjustment = checklist[0].adjustment || 'N/A';
      
      const realTitle = `${strategy} (${adjustment})`
      
      if (isVoid(realTitle)) {
         return defaultTitle;
      }

      return realTitle;
   }
}