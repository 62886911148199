import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { ArchivedPositionsComponent } from './archived-positions.component';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   defaultPriceCellFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { ArchivedPositionsGroupRowInnerRenderer } from './archived-group-row-cell-renderer';

export function getArchivedPositionsGridModel(
   this: ArchivedPositionsComponent,
   displayNameService: TradingInstrumentDisplayNameService
): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: '',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            width: 80
         },
         {
            headerName: 'Shell',
            field: 'shellName',
            enableRowGroup: true,
            rowGroup: true
         },
         {
            headerName: 'Symbol',
            field: 'ticker',
            valueFormatter: (params: ValueFormatterParams) => {
               return displayNameService.getDisplayNameForTicker(params.value);
            },
            enableRowGroup: true,
         },
         {
            headerName: 'Strategy Name',
            field: 'ownerLabel',
            cellStyle: (args: any) => {
               
               if (args.node.group) {
                  return undefined;
               }

               const data: PositionDto = args.data;

               if (data && data.isInnerStrategyPosition) {
                  return {
                     color: 'yellow'
                  };
               } else {
                  return null;
               }
            },
            enableRowGroup: true,
         },
         //
         {
            headerName: 'Net Positions',
            field: 'netPosition',
            valueFormatter: defaultNumberCellFormatter
         },
         //
         {
            headerName: 'Avg. Px',
            field: 'avgPx',
            valueFormatter: defaultPriceCellFormatter
         },
         //
         {
            headerName: 'Last Price',
            field: 'lastMatchPx',
            valueFormatter: defaultQuoteCellFormatter
         },
         //
         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accumulatedTotalPnL',
               aggFunc: 'sum'
            },
            defaultMoneyCellDefinition,

         ),
         //
         {
            headerName: 'Terminal',
            field: 'terminalCode',
            enableRowGroup: true
         },
         //
         {
            headerName: 'Account',
            field: 'accountCode',
            enableRowGroup: true
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      immutableData: true,

      rowGroupPanelShow: 'always',

      groupUseEntireRow: true,

      suppressDragLeaveHidesColumns: true,

      suppressMakeColumnVisibleAfterUnGroup: false,

      suppressAggFuncInHeader: true,

      frameworkComponents: {
         archivedPositionsGroupRowRenderer: ArchivedPositionsGroupRowInnerRenderer,
      } as any,

      groupRowRendererParams: {
         innerRenderer: 'archivedPositionsGroupRowRenderer',
         suppressCount: true
      },

      onGridReady: (args) => this.onPositionsGridReady(args),

      getRowNodeId: (rowData: PositionDto) => {
         return rowData.positionId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         if (this.securityContext.deletePosition) {
            menu.push(
               {
                  name: 'Remove From Calculations',
                  action: () => this.deletePosition(params.node.data),
                  disabled: !params.node
               }
            );
         }
         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );
         return menu;
      }
   } as GridOptions;
}
