import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { formatNumber } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';

@Component({
   selector: 'ets-quote-cell',
   template: `
      <div>{{lastQuote}}</div>
  `
})

export class LastQuoteCellRendererComponent implements OnInit, OnDestroy, ICellRendererAngularComp {
   constructor(
      private readonly _messageBus: MessageBusService,
      private readonly _lastQuoteCache: LastQuoteCacheService
   ) { }

   private _ticker: string;
   private _entity: any;
   private _unsubscriber: Subject<any>;

   lastQuote: string;

   ngOnInit(): void {
   }

   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   refresh(params: any): boolean {
      return false;
   }

   agInit(params: ICellRendererParams): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject();

      const obj = params.data;

      const dataType = this._getDataType(obj);

      if (!dataType) {
         return;
      }

      switch (dataType) {
         case 'strategy':
            const sm = obj as StrategyModel;
            const tis = sm.getTradingInstruments();
            if (tis.length !== 1) {
               this.lastQuote = '*';
               this._ticker = null;
            } else {
               this._ticker = tis[0];
            }
            break;

         case 'order':
            this._ticker = obj.ticker;
            break;

         case 'position':
            this._ticker = obj.ticker ||  obj.underlying;
            break;

         case 'aggregated':
            this._ticker = obj.tradingInstrumentCode;
            break;

         default:
            break;
      }

      if (!this._ticker) {
         return;
      }

      this._entity = obj;

      const lastQuote = this._lastQuoteCache.getLastQuote(this._ticker);

      if (lastQuote) {
         this._setLastQuote(lastQuote.lastPx);
      }

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this._onQuoteDto(msg.payload));
   }

   private _getDataType(obj: any): 'strategy' | 'order' | 'position' | 'aggregated' {
      if (!obj) {
         return null;
      }

      if (obj.positions !== undefined) {
         return 'strategy';
      }

      if (obj.orderType !== undefined) {
         return 'order';
      }

      if (obj.aggregatedId !== undefined || obj.positionId !== undefined || obj.portfolioItemId !== undefined) {
         return 'position';
      }

      return 'aggregated';
   }

   private _onQuoteDto(quotes: QuoteDto[]): void {
      if (!this._ticker) {
         return;
      }

      const filtered = quotes.filter(q => q.ticker === this._ticker);
      if (filtered.length === 0) {
         return;
      }

      const quote = filtered[filtered.length - 1].lastPx;

      this._setLastQuote(quote);
   }

   private _setLastQuote(quote: number): void {
      this.lastQuote = formatNumber(quote || 0, 'en-US', '0.0-7');
      const entity = this._entity;
      if (entity) {
         this._entity.lastMarketQuote = quote;
      }
   }
}
