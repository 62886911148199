import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ets-tabs-menu',
  templateUrl: './tabs-menu.component.html',
  styleUrls: ['./tabs-menu.component.scss']
})
export class EtsTabsMenuComponent implements OnInit {
  @Output() contextMenu = new EventEmitter();
  @Output() sortPanels = new EventEmitter();
  //
  constructor() { }
  //
  ngOnInit() {
  }
  //
  onContextMenuRequest(event) {
    this.contextMenu.emit(event);
    event.preventDefault();
    event.stopPropagation();
  }

  onSortPanelRequest(event) {
    this.sortPanels.emit();
    event.preventDefault();
    event.stopPropagation();
  }
}