import { Injectable } from '@angular/core';
import { ManualTradingModule } from './manual-trading.module';
import { AccessControlService } from '../access-control-service.class';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';

const DASHBOARD = EtsConstants.companyServices.etsDashboardApplicationId;
const WEBTRADER = EtsConstants.companyServices.etsWebTraderApplicationId;

@Injectable()
export class ManualTradingSecurityContextService {
   constructor(
      private readonly _accessControl: AccessControlService
   ) { }

   get overridePosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('df4cac8f-523b-4d61-8183-57634c30ab68');
      }
      return false;
   }

   get archivePosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('647a5c33-ca95-4e92-9dbd-c97ffacc8f02');
      }
      return false;
   }

   get addNewPosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('8f1d634e-8c78-4fce-9a95-e0e13ac46b97');
      }
      return false;
   }

   get adjustPosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('87657669-7ec0-48d2-b42a-2a8a8ca20252');
      }
      return false;
   }

   get reversePosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('8f966009-5ead-4ade-a5cb-1eb0ff1ef133');
      }
      return false;
   }

   get cancelOrder(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('8136b9e8-0cf3-47a7-8c15-c7b0b0a2e20f');
      }
      return false;
   }

   get replaceOrder(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return false;
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('930f1854-b422-4bb8-b8ac-23d4155e6bf6');
      }
      return false;
   }

   get requestOrderStatus(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('6caa880b-791c-4d25-8241-ac386dbc5a03');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('4ec963ed-56e0-45d9-a8dc-d35b926c4f4d');
      }
      return false;
   }

   get rollPosition(): boolean {
      if (environment.runtimeAppId === DASHBOARD) {
         return this._accessControl.isSecureElementAvailable('6caa880b-791c-4d25-8241-ac386dbc5a03');
      }
      if (environment.runtimeAppId === WEBTRADER) {
         return this._accessControl.isSecureElementAvailable('4ec963ed-56e0-45d9-a8dc-d35b926c4f4d');
      }
      return false;
   }
}
