import { Component, EventEmitter, OnDestroy, OnInit, ViewChild, Inject, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { ColumnState, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { getAggregatedPositionsGridModel } from './aggregated-positions-grid-model';
import { getGroupExistsGridModel } from './group-exits-grid-model';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { CancelManualOrders } from 'projects/shared-components/shell-communication/operations/manual-trading/cancel-manual-orders.class';
import {
   GetAggregatedPositions
} from 'projects/shared-components/shell-communication/operations/aggregated-positions/get-aggregated-positions.class';
import { AggregatedPositionDto } from 'projects/shared-components/shell-communication/dtos/aggregated-position-dto.class';
import {
   GetAggregatedExitOrders
} from 'projects/shared-components/shell-communication/operations/aggregated-positions/get-aggregated-exit-orders.class';
import { getCacheKey } from 'projects/ess-worker/throttler/get-cache-key.function';
import {
   FlattenAggregatedPositionsUIMessage, AggObj
} from 'projects/shared-components/ui-messages/flatten-aggregated-positions-ui-message.interface';
import {
   CloseAggregatedPosition
} from 'projects/shared-components/shell-communication/operations/aggregated-positions/close-aggregated-position.class';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import { ResetAggregatedPositionsUIMessage } from 'projects/shared-components/ui-messages/reset-aggregated-positions-ui-message.interface';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { IPanelComponent } from 'projects/shared-components/panels/panel-component.interface';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { DetectMethodChanges, DetectSetterChanges, getPanelStateKey } from 'projects/shared-components/utils';
import { AggregatedPositionsBackendService } from './aggregated-positions-backend.service';
import { AggregatedPositionsSecurityContextService } from './aggregated-positions-security-context.service';
import { LoggerService } from '../logging/logger-factory.service';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { AGGREGATED_POSITIONS_FAKE_DATA } from './fake-data-provider';
import { DeleteArchivedPositionByAggregation } from '../shell-communication/shell-operations-protocol';
import { PanelBaseComponent } from '../panels/panel-base.component';
import {UserSettingsService} from "../user-settings.service";

function gridsReadyWatcher(res, rej): void {
   let attempts = 0;
   const intRef = setInterval(() => {
      attempts++;
      const positionsGridReady = !!this._positionsGrid || !this.securityContext.showPositions;
      const exitsGridReady = !!this._groupExitsGrid || !this.securityContext.showOrders;

      if (positionsGridReady && exitsGridReady) {
         clearInterval(intRef);
         res();
      } else {
         if (attempts >= 4 * 5) {
            clearInterval(intRef);
            rej();
         }
      }
   }, 250);
}

interface PanelState {
   positionsGridState: ColumnState[];
   groupExitsGridState: ColumnState[];
   showExpiredContracts: boolean;
}

@Component({
   selector: 'ets-aggregated-positions',
   templateUrl: 'aggregated-positions.component.html',
   styleUrls: ['./aggregated-positions.component.scss'],
   providers: [AggregatedPositionsSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AggregatedPositionsComponent extends PanelBaseComponent {
   constructor(
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _messageBus: MessageBusService,
      
      private readonly _securityContext: AggregatedPositionsSecurityContextService,
      private readonly _backendService: AggregatedPositionsBackendService,
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _toastr: ToastrService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _tradingInstrumentService: TradingInstrumentsService,
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
    }

   private _groupExitsGrid: GridReadyEvent;
   
   private _groupExitOrdersIx: { [cacheKey: string]: OrderDto } = {};
   
   private _positionsIx: { [cacheKey: string]: AggregatedPositionDto } = {};

   private readonly _pendingPositionsContainer: {
      isLoading: boolean;
      container: AggregatedPositionDto[];
   } = { isLoading: false, container: [] };

   private readonly _pendingGroupExitsContainer: {
      isLoading: boolean;
      container: OrderDto[];
   } = { isLoading: false, container: [] };

   private _positionsGrid: GridReadyEvent;

   private _unsubscriber = new Subject<any>();

   private code2Id = {
      accountCode: 'accountId',
      terminalName: 'terminalId',
      tradingInstrumentCode: 'tradingInstrumentCode',
   };

   private _stateChanged = new EventEmitter();
   
   private _hideExpiredContracts = false;



   @ViewChild(ConfirmationDialogComponent)
   confirmationDialog: ConfirmationDialogComponent;



   readonly tabs = [];

   groupExitsGridModel: GridOptions;

   positionsGridModel: GridOptions;

   contextPopupParent: HTMLElement;

   selectedTab: { text: string; id: string };


   get securityContext(): AggregatedPositionsSecurityContextService {
      return this._securityContext;
   }
   
   get hideExpiredContracts() {
      return this._hideExpiredContracts;
   }


   @DetectSetterChanges()
   set hideExpiredContracts(value: boolean) {
      if (this._hideExpiredContracts === value) {
         return;
      }

      this._hideExpiredContracts = value;

      setTimeout(() => {
         const g = this._positionsGrid;
         if (g) {
            g.api.resetRowHeights();
         }
         this.onStateChanged();
      });
   }

   
   @DetectMethodChanges()
   selectTab($event: any): void {
      this.selectedTab = $event.itemData as any;
   }


   async onGroupExitsGridReady(args: GridReadyEvent): Promise<void> {
      this._groupExitsGrid = args;
      this._groupExitsGrid.api.sizeColumnsToFit();
   }


   async etsOnInit(): Promise<void> {
      if (this._securityContext.showPositions) {
         this.tabs.push({ text: 'Current Positions', id: 'p' });
      }

      if (this._securityContext.showOrders) {
         this.tabs.push({ text: 'Group Exit Orders', id: 'o' });
      }

      this.positionsGridModel = getAggregatedPositionsGridModel.bind(this)(
         this._displayNameService,
         this._tradingInstrumentService
      );

      this.groupExitsGridModel = getGroupExistsGridModel.bind(this)(
         this._displayNameService
      );

      if (this.tabs.length > 0) {
         this.selectedTab = this.tabs[0];
      }

      await this._setupGridReadyWatcher();
   }


   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }


   etsAfterViewInit() {  }


   
   async onPositionsGridReady(args: GridReadyEvent): Promise<void> {
      this._positionsGrid = args;
      this._positionsGrid.api['ets-panel-id'] = this.panelId;
      this._positionsGrid.api.sizeColumnsToFit();
   }


   onStateChanged(): void {
      this._stateChanged.emit();
   }



   async cancelSelectedOrder(order?: OrderDto): Promise<any> {
      if (!order) {
         this._toastr.info('Please, select order to cancel');
         return;
      }

      if (order.strategyId !== EtsConstants.strategies.manualStrategyId) {
         this._toastr.warning('Only manual orders can be canceled');
         return;
      }

      const cmd = new CancelManualOrders([order.orderId]);

      try {
         await this._backendService.cancelManualOrders(cmd);
      } catch (error) {
         console.error(error);
         const errorMessage = 'Error occurred during order cancel operation';
         this._toastr.error(errorMessage);
      }
   }


   private _onGroupExitOrderDto(orderDtos: OrderDto[]): void {
      if (!this._securityContext.showOrders) {
         return;
      }

      const pendingData = this._pendingGroupExitsContainer;
      if (pendingData.isLoading) {
         pendingData.container.push(...orderDtos);
      } else {
         if (pendingData.container.length) {
            orderDtos.push(...pendingData.container);
            pendingData.container.length = 0;
         }
         orderDtos.forEach((dto) => {
            this._groupExitOrdersIx[dto.orderId] = dto;
         });

         const cleanOrders = Object.keys(this._groupExitOrdersIx).map(
            (key) => this._groupExitOrdersIx[key]
         );

         this._groupExitsGrid.api.setRowData(cleanOrders);
      }
   }


   private async _loadPositionsDataFromServer(shellId?: string): Promise<void> {
      if (!this._securityContext.showPositions) {
         return;
      }

      this._pendingPositionsContainer.isLoading = true;
      this._positionsGrid.api.showLoadingOverlay();
      try {
         const qry = new GetAggregatedPositions(this.hideExpiredContracts);
         const positions = await this._backendService.getAggregatedPositions(
            qry,
            shellId
         );
         // const positions = AGGREGATED_POSITIONS_FAKE_DATA as any;
         this._pendingPositionsContainer.isLoading = false;
         this._onAggregatedPositionsMessage(positions);
      } catch (e) {
         this._toastr.error('"Aggregated Positions" panel loaded with errors');
         console.error('Failed to load positions from server', e);
      } finally {
         this._positionsGrid.api.hideOverlay();
         this._pendingPositionsContainer.isLoading = false;
      }
   }


   private async _loadGroupExistsDataFromServer(shellId?: string): Promise<void> {
      if (!this._securityContext.showOrders) {
         return;
      }

      this._pendingGroupExitsContainer.isLoading = true;
      this._groupExitsGrid.api.showLoadingOverlay();
      try {
         const qry = new GetAggregatedExitOrders();
         const dtos = await this._backendService.getAggregatedExitOrders(
            qry,
            shellId
         );
         this._pendingGroupExitsContainer.isLoading = false;
         this._onGroupExitOrderDto(dtos);
      } catch (e) {

         this._toastr.error('"Aggregated Positions" panel loaded with errors');
         const extraData = {
            error: e instanceof Error ? e.stack : e,
         };
         console.error('Failed to load group exits from server', extraData);

      } finally {

         this._groupExitsGrid.api.hideOverlay();
         this._pendingGroupExitsContainer.isLoading = false;
         this._changeDetector.detectChanges();

      }
   }


   private _onAggregatedPositionsMessage(dtos: AggregatedPositionDto[]): void {
      if (!this._securityContext.showPositions) {
         return;
      }

      if (this._pendingPositionsContainer.isLoading) {
         this._pendingPositionsContainer.container.push(...dtos);
      } else {
         if (this._pendingPositionsContainer.container.length) {
            dtos.unshift(...this._pendingPositionsContainer.container);
            this._pendingPositionsContainer.container.length = 0;
         }

         dtos.forEach((dto) => {
            const cacheKey = getCacheKey('AggregatedPositionDto', dto);
            const old = this._positionsIx[cacheKey];
            if (old) {
               dto.lastMarketQuote = old.lastMarketQuote;
            }
            this._positionsIx[cacheKey] = dto;
            // this._lastQuoteCache.subscribeTicker(dto.tradingInstrumentCode);
         });

         const poses = Object.values(this._positionsIx);
         this._positionsGrid.api.setRowData(poses);
      }

      this._messageBus.publishAsync({topic: 'AggregatedPositionsUpdated', payload: {}});
   }


   @DetectMethodChanges({isAsync: true})
   private async _onFlattenAggregatedPositionRequest(msg: FlattenAggregatedPositionsUIMessage): Promise<void> {
      const aggObj = msg.aggObj;

      const data = Object.values(this._positionsIx);

      const entity = data.length ? data[0] : null;
      if (!entity) {
         this._toastr.warning(
            aggObj.deleteArchived
               ? 'Cannot remove  archived aggregated positions: no position selected'
               : 'Cannot flatten aggregated position: no position selected'
         );
         return;
      }

      for (const code of Object.keys(aggObj)) {
         for (const e of data) {
            const entityValue = e[code];
            const aggObjValue = aggObj[code];
            if (entityValue === aggObjValue) {
               const id = this.code2Id[code];
               if (id === 'tradingInstrumentCode') {
                  aggObj.tickerFriendlyName = this._displayNameService.getDisplayNameForTicker(
                     entityValue
                  );
               } else {
                  aggObj[id] = e[id];
               }
               break;
            }
         }
      }

   
      this.confirmationDialog
         .show(aggObj)
         .then(() => {
            this._closeAggregatedPosition(aggObj)
               .then(() => {
                  this.confirmationDialog.hide();
                  this._changeDetector.detectChanges();
               });
         })
         .catch(() => {
            this.confirmationDialog.hide();
            this._changeDetector.detectChanges();
         });
   }

   
   private async _closeAggregatedPosition(aggObj: AggObj): Promise<void> {
      if (aggObj.positionClass === 'Archived Positions'
         || aggObj.deleteArchived) {
         try {
            const cmd = new DeleteArchivedPositionByAggregation(
               aggObj.accountId,
               aggObj.terminalId,
               aggObj.tradingInstrumentCode,
               aggObj.positionClass
            );
            await this._backendService.deleteArchivedPositionByAggregation(cmd);
         } catch (e) {
            this._toastr.error(
               '"Remove Archived Aggregated Position" command completed with errors'
            );
            const data = e instanceof Error ? e.stack : e;
            console.error('Failed to remove archived aggregated position', data);
         }
      } else {
         try {
            const cmd = new CloseAggregatedPosition(
               aggObj.accountId,
               aggObj.terminalId,
               aggObj.tradingInstrumentCode,
               aggObj.positionClass
            );
            await this._backendService.closeAggregatedPosition(cmd);
         } catch (e) {
            this._toastr.error(
               '"Close Aggregated Position" command completed with errors'
            );
            const data = e instanceof Error ? e.stack : e;
            console.error('Failed to close aggregated position', data);
         }
      }
   }

   
   private async _onClearTradingDataUIMessage(msg: ClearTradingDataUIMessage): Promise<void> {
      console.info('Received "ClearTradingDataUIMessage"');
      await this._reloadData(msg.shellId);
   }

   
   private async _onResetAggregatedPositions(msg: ResetAggregatedPositionsUIMessage): Promise<void> {
      console.info('Received "ResetAggregatedPositionsUIMessage"');
      await this._reloadData(msg.shellId);
   }

   
   private async _onShellConnectionStatusChangedUIMessage(msg: ShellConnectionStatusChangedUIMessage): Promise<void> {
      console.info('Received "ShellConnectionStatusChangedUIMessage"');
      await this._reloadData(msg.shellId);
   }

   
   private async _reloadData(shellId?: string): Promise<void> {
      console.info(`Loading aggreageted data. ShellId=${shellId || '(empty)'}`);
      
      if (shellId) {
         
         Object.values(this._positionsIx)
            .filter((position) => position.shellId === shellId)
            .forEach((position) => {
               const cacheKey = getCacheKey('AggregatedPositionDto', position);
               delete this._positionsIx[cacheKey];
            });

         Object.values(this._groupExitOrdersIx)
            .filter((order) => order.shellId === shellId)
            .forEach((order) => delete this._groupExitOrdersIx[order.orderId]);

      } else {

         this._positionsIx = {};
         this._groupExitOrdersIx = {};

      }
      
      const positionsPromise = this._loadPositionsDataFromServer(shellId);
      const groupExitsPromise = this._loadGroupExistsDataFromServer(shellId);
      await Promise.all([positionsPromise, groupExitsPromise]);
   }

   
   private _subscribeToMessages(): void {
      this._messageBus
         .of<FlattenAggregatedPositionsUIMessage>(
            'FlattenAggregatedPositionsUIMessage'
         )
         .pipe(
            filter((x) => {
               return x.payload.panelId === this.panelId;
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) =>
            setTimeout(() => this._onFlattenAggregatedPositionRequest(msg.payload), 0)
         );

      this._messageBus
         .of<AggregatedPositionDto[]>('AggregatedPositionDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((dtos) =>
            setTimeout(() => this._onAggregatedPositionsMessage(dtos.payload), 0)
         );

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) =>
            setTimeout(() => this._onClearTradingDataUIMessage(msg.payload), 0)
         );

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>(
            'ShellConnectionStatusChangedUIMessage'
         )
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) =>
            setTimeout(() => this._onShellConnectionStatusChangedUIMessage(msg.payload), 0)
         );

      this._messageBus
         .of<ResetAggregatedPositionsUIMessage>(
            'ResetAggregatedPositionsUIMessage'
         )
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) =>
            setTimeout(() => this._onResetAggregatedPositions(msg.payload), 0)
         );

      const groupExitStrategyId = EtsConstants.strategies.groupExitStrategyId;
      this._messageBus
         .of<OrderDto[]>('OrderDto')
         .pipe(
            filter((groupByStrategy) => {
               return groupExitStrategyId in groupByStrategy;
            }),
            map((groupByStrtategyId) => {
               return groupByStrtategyId[groupExitStrategyId];
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe((dtos) =>
            setTimeout(() => this._onGroupExitOrderDto(dtos), 0)
         );

      this._stateChanged
         .pipe(throttleTime(250), takeUntil(this._unsubscriber))
         .subscribe(() => this.saveState());

      this._messageBus
         .of<any>('WorkspaceClosed')
         .pipe(
            filter((msg) => msg.payload.workspaceId === this.workspaceId),
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) => this.onRemovedFromWorkspace());
   }

   
   protected getState(): PanelState {
      
      if (!this._positionsGrid || !this._groupExitsGrid) {
         return null;
      }

      const key = getPanelStateKey(this);
      const positionsGridState = this._positionsGrid.columnApi.getColumnState();
      const groupExitsGridState = this._groupExitsGrid.columnApi.getColumnState();
      const state: PanelState = {
         positionsGridState,
         groupExitsGridState,
         showExpiredContracts: this.hideExpiredContracts,
      };

      return state;
   }

   
   protected setState(state: PanelState): void {
      
      if (!this._positionsGrid || !this._groupExitsGrid) {
         return;
      }

      
      this.hideExpiredContracts = state.showExpiredContracts || false;

      const isPositionsGridOK = this._positionsGrid.columnApi.setColumnState(
         state.positionsGridState
      );

      const isGroupExitsGridOK = this._groupExitsGrid.columnApi.setColumnState(
         state.groupExitsGridState
      );
      if (
         !isPositionsGridOK ||
      /*!isWorkingOrdersGridOK ||*/ !isGroupExitsGridOK
      ) {
         this._toastr.error(
            '"Trading Systems View" panel was restored with errors'
         );
      }
   }

   
   private async _setupGridReadyWatcher(): Promise<void> {
      const resolver = gridsReadyWatcher.bind(this);
      try {
         await new Promise(resolver);
         const key = getPanelStateKey(this);
         const item = this._userSettingsService.getValue(key);
         if (!item) {
            this.saveState();
         } else {
            this.restoreState();
         }
         this._subscribeToMessages();
         await this._reloadData();
      } catch (error) {
         this._toastr.error(
            '"Aggregated Positions" panel failed to initialize. Please, reload'
         );

         // as we might have enabled overlays on grids initialization
         const positionsGrid = this._positionsGrid;
         if (positionsGrid) {
            positionsGrid.api.hideOverlay();
         }

         const exitsGrid = this._groupExitsGrid;
         if (exitsGrid) {
            exitsGrid.api.hideOverlay();
         }
      }
   }
}
