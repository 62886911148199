<div class="view-container" id="session-history-panel" [class.traded]="!this.showAllData">

   <as-split class="split" direction="horizontal" [gutterSize]="5">
      
      <as-split-area order="0" [visible]="securityContext.sessionsList" [size]="historyListSectionSize">
         <ag-grid-angular id="history-grid" class="ag-theme-balham-dark" style="width: 100%; height: 100%;"
            [gridOptions]="historyDataGridOptions"></ag-grid-angular>
      </as-split-area>

      <as-split-area order="1" [visible]="securityContext.sessionDetails" [size]="sessionBucketsListSectionSize">
         <as-split direction="vertical" [gutterSize]="5">
            <as-split-area [order]="0" [size]="bucketSectionSize" *ngIf="this.showPortfolios">
               <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="portfoliosGridOptions" style="width: 100%; height: 100%;">
               </ag-grid-angular>
            </as-split-area>
            <as-split-area [order]="1" [size]="bucketSectionSize" *ngIf="this.showCombos">
               <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="combosGridOptions" style="width: 100%; height: 100%;">
               </ag-grid-angular>
            </as-split-area>
            <as-split-area [order]="2" [size]="bucketSectionSize" *ngIf="this.showComboGroups">
               <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="comboGroupsGridOptions" style="width: 100%; height: 100%;">
               </ag-grid-angular>
            </as-split-area>
         </as-split>
      </as-split-area>

      <as-split-area order="2" [visible]="securityContext.sessionStrategyDetails" [size]="sessionDetailsSectionSize">
         
         <as-split direction="vertical" [gutterSize]="5">
      
            <as-split-area [size]="bucketItemsSectionSize" [order]="0">
               <div style="height: 100%; position: relative">
                  <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="bucketItemsGridOptions">
                  </ag-grid-angular>
               </div>
            </as-split-area>

            <as-split-area [size]="tradingDataSectionSize" [order]="1">
               <div style="height: 100%; position: relative">
                  <ag-grid-angular class="ag-theme-balham-dark trading-data"
                     [ngClass]="{'hidden': selectedTabIndex !== 0 }" [gridOptions]="tradesGridOptions">
                  </ag-grid-angular>
                  
                  <ag-grid-angular class="ag-theme-balham-dark trading-data"
                     [ngClass]="{'hidden': selectedTabIndex !== 1 }" [gridOptions]="snapshotsGridOptions">
                  </ag-grid-angular>
                  
                  <ag-grid-angular class="ag-theme-balham-dark trading-data"
                     [ngClass]="{'hidden': selectedTabIndex !== 2 }" [gridOptions]="messagesGridOptions">
                  </ag-grid-angular>
                  
                  <div class="tabs" (dblclick)="toggleSectionFullWidth('tradingData')">
                     <dx-tabs [dataSource]="tabs" [selectedIndex]="selectedTabIndex" 
                                 (onItemClick)="selectTab($event)">
                     </dx-tabs>
                  </div>
               </div>
            </as-split-area>
         </as-split>
      </as-split-area>
   </as-split>

</div>
