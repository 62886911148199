<dx-popup (onHidden)="this.onClosed()"
          [dragEnabled]="true"
          [resizeEnabled]="true"
          [shading]="false"
          [visible]="this.visible"
          [width]="this.popupWidth"
          [height]="this.popupHeight"
          title="Price Adjuster"
          [restorePosition]="false"
>
  <div *dxTemplate="let data of 'content'" class="content">
    <div class="root">
      
      <div class="portfolio">
        <div class="label">Portfolio</div>
        <div class="input">
          <dx-select-box
            width="100%"
            height="100%"
            fieldTemplate="field"
            [items]="this.portfolioList"
            [grouped]="true"
            [(value)]="this.selectedPortfolio"
            [displayExpr]="'name'"
            (onValueChanged)="this.onPortfolioSelected($event)"
          >
            <div *dxTemplate="let data of 'field'" style="height: 100%">
              <div style="height: 100%; display: grid;">
                <dx-text-box
                  [readOnly]="true"
                  [value]="data && data.userName + '  |  ' + data.name"
                  placeholder="Select portfolio..."
                ></dx-text-box>
              </div>
            </div>
          </dx-select-box>
        </div>
      </div>
      
      <div class="step">
        <div class="label">
          Step
        </div>
        <div class="input">
          
          <dx-drop-down-box
            [disabled]="!this.canChange"
            [dropDownOptions]="{height: 135}"
            [(value)]="this.step"
            [acceptCustomValue]="true"
            [inputAttr]="{style: 'text-align: center;'}"
          >
            <div *dxTemplate="let data of 'field'">
              <dx-text-box [readOnly]="true" [value]="this.step+''"
                [inputAttr]="{style: 'text-align: center; font-size: 1.2em'}">
              </dx-text-box>
            </div>
            
            <div *dxTemplate="let data of 'content'">
              <div class="incrementor" *ngFor="let inc of this.incrementors">
                <div class="minus sign" (click)="this.decreaseStep(inc)">
                  <i class="fas fa-minus"></i>
                </div>
                <div class="value">
                  {{inc}}
                </div>
                <div class="plus sign" (click)="this.increaseStep(inc)">
                  <i class="fas fa-plus"></i>
                </div>
              </div>
            </div>
            
          </dx-drop-down-box>
          
        </div>
      </div>
      
      <div class="arrows">
        <div class="up arrow" (click)="this.moveUp()" [class.disabled]="!this.canChange">
          <i class="fas fa-arrow-up fa-lg"></i>
        </div>
        
        <div class="distance">
          <div>
            {{ this.distance | currency:'USD':'symbol-narrow':'1.0-0' }}
          </div>
          <div class="original-distance" (click)="this.resetDistance()">
            {{ (this.originalDistance | async) | currency:'USD':'symbol-narrow':'1.0-0' }}
          </div>
        </div>
        
        <div class="down arrow" (click)="this.moveDown()" [class.disabled]="!this.canChange">
          <i (click)="null" class="fas fa-arrow-down fa-lg"></i>
        </div>
      </div>
      
      <div class="btn">
        <dx-check-box text="Invert Prices" [value]="this.isInverted" (onValueChanged)="this.onInvertPricesChanged()"></dx-check-box>
      </div>
      
      <div class="buttons">
        
        <div class="btn">
          <dx-check-box text="Live" [(value)]="this.isLive" (onValueChanged)="this.onLiveChanged()"></dx-check-box>
        </div>
        
        <div class="btn">
          <dx-button icon="check" width="100%" type="default" [disabled]="this.isLive || !this.canApplyMove()"
            (onClick)="this.applyMove()">
          </dx-button>
        </div>
      </div>
      
    </div>
  </div>
</dx-popup>