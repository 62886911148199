import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class UnblockRiskManagerRule implements ProtocolCommand {
  constructor(public readonly riskManagerRuleId: string) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.UnblockRiskManagerRule`;
  }
}
