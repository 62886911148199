import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';

/**
 * @returns GetTradingDataResponse
 */
export class GetStrategyLogMessagesWeb implements ProtocolQuery {
  constructor(
    public readonly strategyId: string,
    public readonly historyStop: number,
    public readonly batchSize: number
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategyLogMessagesWeb`;
  }
}
