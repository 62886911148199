import { GameOutcome } from '../game-outcome';
import { WinLoss } from '../win-loss';
import { StrategyGameRecordDto } from 'projects/shared-components/shell-communication/dtos/strategy-game-record-dto.class';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { isNullOrUndefined } from 'util';
import { FlagshipReportEntryModel } from '../flagship/flagship-report-entry-model.class';

export class DispositionReportEntryModel {
  constructor(private _dto: StrategyGameRecordDto) {
    this.slippageView = this._dto.slippage;
    this.ctlValueView = this._dto.ctlValue;
    this.innerGames = _dto.innerRecords.map(g => new FlagshipReportEntryModel(g));
  }

  slippageView: number;
  ctlValueView: number;
  innerGames: FlagshipReportEntryModel[];

  get strategyGameRecordId(): number {
    return this._dto.strategyGameRecordId;
  }

  get strategyId(): string {
    return this._dto.strategyId;
  }

  get gameId(): string {
    return this._dto.gameId;
  }

  get strategyName(): string {
    return this._dto.strategyName;
  }

  get outcome(): GameOutcome {
    return this._dto.outcome;
  }

  get side(): MarketSide {
    if (isNullOrUndefined(this.actualBet)) {
      return null;
    }
    if (this.actualBet === 0) {
      return MarketSide.Flat;
    }
    if (this.actualBet > 0) {
      return MarketSide.Buy;
    }
    if (this.actualBet < 0) {
      return MarketSide.Sell;
    }
    throw new Error('Game side is undefined');
  }

  get winLoss(): WinLoss {
    const isWinner = !isNullOrUndefined(this.actualBet) &&
      this.actualBet !== 0 && !isNullOrUndefined(this.outcome)
      && Math.sign(this.actualBet) === (this.outcome + 0);

    const isLooser = !isNullOrUndefined(this.actualBet) &&
      this.actualBet !== 0 && !isNullOrUndefined(this.outcome)
      && Math.sign(this.actualBet) !== (this.outcome + 0);

    if (isWinner) {
      return WinLoss.Win;
    }

    if (isLooser) {
      return WinLoss.Loss;
    }

    return null;
  }

  get actualBet(): number {
    return this._dto.actualBet;
  }

  get bet(): number {
    return this._dto.bet;
  }

  get totalPnL(): number {
    return this._dto.totalPnL;
  }

  get perfectPnL(): number {
    return this._dto.perfectPnL;
  }

  get slippage(): number {
    return this._dto.slippage;
  }

  get ctlValue(): number {
    return this._dto.ctlValue;
  }

  get isLast(): boolean {
    return this._dto.isLast;
  }

  set isLast(value: boolean) {
    this._dto.isLast = value;
  }

  get seqNum(): number {
    return this._dto.seqNum;
  }

  get timestamp(): Date {
    return this._dto.timestamp;
  }

  changeTickPointView(tickSize: number = null) {
    if (!tickSize) {
      this.slippageView = this.slippage;
      this.ctlValueView = this.ctlValue;
    } else {
      this.slippageView = this.slippage / tickSize;
      this.ctlValueView = this.ctlValue / tickSize;
    }
  }
}
