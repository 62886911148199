import { Component, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { TradeTheLineAlgoParametersControl } from './tradetheline-algo-parameters-control';
import { ParametersViewBase } from '../../parameters-view-base';

@Component({
  selector: 'ets-tradetheline-parameters-view',
  templateUrl: './tradetheline-parameters-view.component.html',
  styleUrls: ['./tradetheline-parameters-view.component.scss']
})
export class TradeTheLineParametersViewComponent  extends ParametersViewBase<TradeTheLineAlgoParametersControl> {
  
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;
   
   onEtsInit() {
   
   }
   
   onEtsAfterViewInit() {
   
   }

}
