import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, GridReadyEvent, RowNode, _ } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../authentication/session-service.service';
import { EditStrategyDialogComponent } from '../edit-strategy-dialog/edit-strategy-dialog.component';
import { EtsConstants } from '../ets-constants.const';
import { SettingsStorageService } from '../settings-storage-service.service';
import { MessageBusMessage, MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { StrategyDto } from '../shell-communication/dtos/strategy-dto.class';
import { TerminalDto } from '../shell-communication/dtos/terminal-dto.class';
import { ClearTradingData } from '../shell-communication/operations/shell/clear-trading-data.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { BucketAttributes, BucketSummaryDto, ComboBucketCfsStatusChangedDto, ComboCreatedDto, ComboDto, ComboGroupCreatedDto, ComboGroupDto, ComboGroupRunningCfsStrategiesDto, GroupDeletedDto, GroupRenamedDto, PortfolioCreatedDto, PortfolioDto, PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from '../shell-communication/shell-dto-protocol';
import { BucketContext, CreateInterestCalculatorStrategy, GetBucketAttributes, SaveBucketAttributes } from '../shell-communication/shell-operations-protocol';
import { StrategyKind } from '../strategies/strategy-kind.enum';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { BucketHighlighted, ClearPortfolioItemsUIMessage, ShowBucketPositionsUIMessage } from '../ui-messages/ui-messages';
import { DetectMethodChanges, isNullOrUndefined } from '../utils';
import { getComboGroupsGridOptions } from './combogroups-grid-options';
import { getComboGridColumnDefs, getCombosGridOptions } from './combos-grid-options';
import { getPortfoliosGridOptions } from './portfolios-grid-options';
import { PortfoliosService } from './portfolios.service';
import { TransferBucketDialogComponent } from './transfer-bucket-dialog/transfer-bucket-dialog.component';
import { TransferBucketDialogConfig } from './transfer-bucket-dialog/transfer-bucket-dialog.model';
import { DuplicateBucketDialogComponent } from './duplicate-bucket-dialog/duplicate-bucket-dialog.component';
import { DuplicateBucketDialogConfig } from './duplicate-bucket-dialog/duplicate-bucket-dialog.model';
import { BucketType } from './portfolios.model';
import { StrategyPositionSizing } from '../strategies/strategy-position-sizing.class';
import { InterestCalculatorParameters } from '../adjustment-strategy-dialog/strategy-parameters/interest/interest-calculator-strategy-parameters';
import { DialogConfig } from '../edit-strategy-dialog/dialog-config';
import { SessionHistoryComponent, SessionHistoryConfig } from 'projects/webtrader/src/app/session-history/session-history.component';
import { SessionHistoryPopupComponent } from 'projects/webtrader/src/app/session-history/popup/session-history-popup.component';
import { StrategyStateDto } from '../shell-communication/dtos/strategy-state-dto.class';
import { StrategiesService } from '../strategies/strategies.service';
import { CashFlowStrategy } from '../adjustment-control-panel/cash-flow-strategy';
import {UserSettingsService} from "../user-settings.service";

interface CreatePortfolioConfig  {
   isVisible?: boolean;
   portfolioName?: string;
   terminalId?: string;
   terminalList?: TerminalDto[];
}

interface RenameGroupConfig {
   isVisible?: boolean;
   bucketId?: string;
   newBucketName?: string;
   oldGroupName?: string;
   bucketType?: 'portfolio' | 'combo' | 'group';
   isAuto?: boolean;
}

interface CreateComboConfig {
   isVisible?: boolean;
   portfolioId?: string;
   comboName?: string;
   templates?: CashFlowStrategy[];
   templateName?: string;
   isAuto?: boolean;
}

interface CreateComboGroupConfig {
   isVisible?: boolean;
   portfolioId?: string;
   comboId?: string;
   comboGroupName?: string;
}


interface PositionSizingConfig {
   positionSizing?: StrategyPositionSizing;
   bucketType?: BucketType;
   bucketId?: string;
   isVisible?: boolean;
   isLoading?: boolean;
}


interface DeleteBucketConfig {
   context?: BucketContext;
   isVisible?: boolean;
}


interface InterestRateConfig {
   isVisible?: boolean;
   bucketType?: BucketType;
   bucketId?: string;
   parameters: InterestCalculatorParameters;
}

@Component({
   selector: 'ets-portfolios',
   templateUrl: './portfolios.component.html',
   styleUrls: ['./portfolios.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfoliosComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _sessionService: SessionService,
      private readonly _toastr: ToastrService,
      private readonly _portfoliosService: PortfoliosService,
      private readonly _shellClient: ShellClientService,

      private readonly _strategiesService: StrategiesService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
      this.interestRateDialog = { parameters: new InterestCalculatorParameters(this._changeDetector, _sessionService) };
    }
   

   private _portfoliosGrid: GridReadyEvent;
   private _combosGrid: GridReadyEvent;
   private _comboGroupsGrid: GridReadyEvent;
   private _unsubscriber: Subject<any> = new Subject<any>();
   private _panelWasShown = false;
   private _portfolioRaceData: BucketSummaryDto[];
   private _comboRaceData: BucketSummaryDto[];
   private _comboGroupRaceData: BucketSummaryDto[];

   @ViewChild('portfolios', {static: false}) portfoliosEl: ElementRef<AgGridAngular>;
   @ViewChild(EditStrategyDialogComponent) editStrategyDialog: EditStrategyDialogComponent;
   @ViewChild(TransferBucketDialogComponent) transferBucketDialog: TransferBucketDialogComponent;
   @ViewChild(DuplicateBucketDialogComponent) duplicateBucketDialog: DuplicateBucketDialogComponent;
   @ViewChild(SessionHistoryPopupComponent) sessionHistoryDialog: SessionHistoryPopupComponent;

   portfoliosGridOptions: GridOptions;
   combosGridOptions: GridOptions;
   comboGroupsGridOptions: GridOptions;

   selectedTerminal: string;
   selectedPortfolio: PortfolioDto;
   selectedCombo: ComboDto;
   selectedComboGroup: ComboGroupDto;

   createPortfolioConfig: CreatePortfolioConfig = { terminalList: [] };
   renameBucketConfig: RenameGroupConfig = {};
   createComboConfig: CreateComboConfig = {};
   createComboGroupConfig: CreateComboGroupConfig = {};
   sectionSize: number;
   clearTradingDataConfirmPopupSettings: { context?: BucketContext, isVisible?: boolean } = {};
   deleteBucketDialog: DeleteBucketConfig = {};
   positionSizingDialog: PositionSizingConfig = { positionSizing: new StrategyPositionSizing() };
   showArchivedData: boolean;
   interestRateDialog: InterestRateConfig;

   //
   tabs = [
      { text: 'Daily' },
      { text: 'Weekly' }
   ];

   //
   selectedTabIndex = 0;

   //
   @DetectMethodChanges()
   selectTab($event: any): void {
      this.selectedTabIndex = $event.itemIndex;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onClearTradingDataForStrategyConfirmed() {
      
      const ctx = this.clearTradingDataConfirmPopupSettings.context;
      
      if (!ctx || !ctx.bucketId) {
         
         this._toastr.error('Bucket Not Selected');

         this.onClearTradingDataConfirmPopupClosed();

         return;

      }

      this.onClearTradingDataConfirmPopupClosed();

      const cmd = new ClearTradingData();
      cmd.bucketContext = ctx;
      cmd.deleteData = false;

      try {

         this._messageBus.publish({
            topic: 'SpecificDatabaseCleanupStarted',
            payload: {}
         });
         
         await this._shellClient.processCommand(cmd);

      } catch (e) {
         
         this._toastr.error('"Clear Trading Data" Operation Completed With Errors');

      }
   }

   //
   @DetectMethodChanges()
   clearTradingDataForBucket(bucketType: 'Portfolio' | 'Combo' | 'ComboGroup', bucketId: string, bucketName: string): void {
      this.clearTradingDataConfirmPopupSettings.context = { bucketId, bucketType, bucketName };
      this.clearTradingDataConfirmPopupSettings.isVisible = true;
   }

   //
   @DetectMethodChanges()
   onClearTradingDataConfirmPopupClosed() {
      this.clearTradingDataConfirmPopupSettings = {};
   }

   //
   etsOnInit() {
      this.portfoliosGridOptions = getPortfoliosGridOptions.bind(this)();
      this.combosGridOptions = getCombosGridOptions.bind(this)();
      this.comboGroupsGridOptions = getComboGroupsGridOptions.bind(this)();
   }

   //
   @DetectMethodChanges()
   deleteBucketDialog_onClosed() {
      this.deleteBucketDialog = {};
   }

   @DetectMethodChanges()
   async deleteBucketDialog_onConfirmed() {

      switch (this.deleteBucketDialog.context.bucketType) {
         case 'Portfolio':
            await this._portfoliosService.deletePortfolio(this.deleteBucketDialog.context.bucketId);
            break;
         
         case 'Combo':
            await this._portfoliosService.deleteCombo(this.deleteBucketDialog.context.bucketId);
            break;

         case 'ComboGroup':
            await this._portfoliosService.deleteComboGroup(this.deleteBucketDialog.context.bucketId);
            break;
      
         default:
            break;
      }

      this.deleteBucketDialog_onClosed();
   }

   //
   etsAfterViewInit() {  }

   //
   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   onPortfoliosGridReady(args: GridReadyEvent): void {
      this._portfoliosGrid = args;
      args.columnApi.autoSizeAllColumns();
   }
   
   //
   onCombosGridReady(args: GridReadyEvent): void {
      this._combosGrid = args;
      args.columnApi.autoSizeAllColumns();
   }

   //
   onComboGroupsGridReady(args: GridReadyEvent): void {
      this._comboGroupsGrid = args;
      
      args.columnApi.autoSizeAllColumns();

      this.subscribeToMessages();

      this.loadPortfolios();
   }

   //
   @DetectMethodChanges()
   clearAllSections(doNotNotify?: boolean) {
   
      this.selectedPortfolio = null;
      this.selectedCombo = null;
      this.selectedComboGroup = null;

      this._combosGrid.api.setRowData([]);
      this._comboGroupsGrid.api.setRowData([]);

      if (doNotNotify) {
         return;
      }

      const msg: MessageBusMessage<ClearPortfolioItemsUIMessage> = {
         topic: 'ClearPortfolioItemsUIMessage',
         payload: {},
         scopeId: this.layoutTabId
      };
      this._messageBus.publishAsync(msg);
   }

   //
   @DetectMethodChanges()
   onTerminalSelected(node: RowNode) {
      
      if (!node.group) {
         return;
      }

      if (!node.key) {
         return;
      }

      const terminals = this._sessionService.loginResult.availableTerminals.filter(x => x.displayName === node.key);

      if (terminals.length !== 1) {
         this._toastr.error('Cannot determine terminal', 'Portfolios Panel');
         console.debug(`portfolios|cannot load terminal items. terminal with name '${node.key}' not found`);
         
         return;
      }

      if (this.selectedTerminal === node.key) {
         return;
      }

      this._combosGrid.api.setColumnDefs([]);
      const colDefs = getComboGridColumnDefs(true);
      this._combosGrid.api.setColumnDefs(colDefs);
      this._combosGrid.columnApi.autoSizeAllColumns();

      this.selectedTerminal = node.key;

      this.clearAllSections(true);

      console.debug(`portfolios|terminal selected. terminal = ${node.key} (${terminals[0].terminalId})`);

      this.loadCombosByTerminal(terminals[0]);

      this._messageBus.publishAsync<BucketHighlighted>({
         topic: 'BucketHighlighted',
         payload: {
            bucketContext: {
               bucketType: 'Terminal',
               bucketId: terminals[0].terminalId,
               bucketName: terminals[0].displayName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onPortfolioSelected(data: PortfolioDto): Promise<void> {

      if (this.selectedTerminal) {
         this._combosGrid.api.setColumnDefs([]);
         const colDefs = getComboGridColumnDefs(false);
         this._combosGrid.api.setColumnDefs(colDefs);
         this._combosGrid.columnApi.autoSizeAllColumns();   
      }

      this.selectedTerminal = null;
      this.selectedPortfolio = data;
      this.selectedCombo = null;
      this.selectedComboGroup = null;

      if (isNullOrUndefined(data)) {
         return;
      }

      await this.loadCombosByPortfolio(data);
      
      this._messageBus.publish<BucketHighlighted>({
         topic: 'BucketHighlighted',
         payload: {
            bucketContext: {
               bucketType: 'Portfolio',
               bucketId: data.portfolioId,
               bucketName: data.portfolioName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onComboSelected(data: ComboDto): Promise<void> {

      this.selectedCombo = data;
      this.selectedComboGroup = null;
      
      await this.loadComboGroupsByCombo(data);
   
      if (isNullOrUndefined(data)) {
         return;
      }

      this._messageBus.publish<BucketHighlighted>({
         topic: 'BucketHighlighted',
         payload: {
            bucketContext: {
               bucketType: 'Combo',
               bucketId: data.comboId,
               bucketName: data.comboName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onComboGroupSelected(data: ComboGroupDto): Promise<void> {

      this.selectedComboGroup = data;

      this.loadComboGroupItems(data);

      if (isNullOrUndefined(data)) {
         return;
      }

      this._messageBus.publish<BucketHighlighted>({
         topic: 'BucketHighlighted',
         payload: {
            bucketContext: {
               bucketType: 'ComboGroup',
               bucketId: data.comboGroupId,
               bucketName: data.comboGroupName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   @DetectMethodChanges()
   setSectionSize() {
   
      if (this._panelWasShown) {
         return;
      }
         
      this._panelWasShown = true;

      this.sectionSize = 33;
   }

   //
   expandTerminalGroups() {
      
      if (!this._portfoliosGrid) {
         return;
      }

      this._portfoliosGrid.api.forEachNode(node => {
         if (!node.isExpandable()) {
            return;
         }

         node.expanded = true;
      });

      if (!isNullOrUndefined(this.portfoliosEl)) {
         const portfolioElement = this.portfoliosEl as any;
         if (!isNullOrUndefined(portfolioElement._nativeElement)) {
            setTimeout(() => {
               if (portfolioElement._nativeElement.clientHeight > 0) {
                  this._portfoliosGrid.api.onGroupExpandedOrCollapsed();
               }
            }, 50);
         }
      }
   }

   //
   changeShowArchivedData() {
      this.showArchivedData = !this.showArchivedData;
      
      if (this._portfoliosGrid) {
         this._portfoliosGrid.api.resetRowHeights();
      }

      if (this._combosGrid) {
         this._combosGrid.api.resetRowHeights();
      }

      if (this._comboGroupsGrid) {
         this._comboGroupsGrid.api.resetRowHeights();
      }
   }

   //
   private subscribeToMessages() {

      this._messageBus.of<PortfolioCreatedDto>('PortfolioCreatedDto')
            .pipe(
               takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onPortfolioCreatedMessage(msg.payload));


      this._messageBus.of<GroupDeletedDto>('GroupDeletedDto')
            .pipe(
               takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onGroupDeletedMessage(msg.payload));

            
      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onClearTradingDataMessage(msg.payload));
      

      this._messageBus
         .of<GroupRenamedDto>('GroupRenamedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onGroupRenamedMessage(msg.payload));

      
      this._messageBus
         .of<ComboCreatedDto>('ComboCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onComboCreatedMessage(msg.payload));
      

      this._messageBus
         .of<ComboGroupCreatedDto>('ComboGroupCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onComboGroupCreatedMessage(msg.payload));

      this._messageBus
         .of<BucketSummaryDto[]>('BucketSummaryDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onBucketSummaryDto(msg.payload));


      this._messageBus.of<ComboBucketCfsStatusChangedDto>('ComboBucketCfsStatusChangedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onComboBucketCfsStatusChanged(msg.payload));

      
      this._messageBus.of<ComboGroupRunningCfsStrategiesDto>('ComboGroupRunningCfsStrategiesDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onComboGroupRunningStrategiesChanged(msg.payload));

   }

   //
   private onComboGroupRunningStrategiesChanged(payload: ComboGroupRunningCfsStrategiesDto): void {
      if (!this._comboGroupsGrid) {
         return;
      }

      const comboGroupNode = this._comboGroupsGrid.api.getRowNode(payload.comboGroupId);

      if (isNullOrUndefined(comboGroupNode)) {
         return;
      }

      const comboGroup = comboGroupNode.data as ComboGroupDto;

      if (isNullOrUndefined(comboGroup)) {
         return;
      }

      if (comboGroup.runningStrategies === payload.runningStrategies) {
         return;
      }

      comboGroup.runningStrategies = payload.runningStrategies;

      this._combosGrid.api.refreshCells({force: true, rowNodes: [comboGroupNode]});
   }

   //
   private onComboBucketCfsStatusChanged(payload: ComboBucketCfsStatusChangedDto): void {
      if (!this._combosGrid) {
         return;
      }

      const comboRow = this._combosGrid.api.getRowNode(payload.comboId);

      if (isNullOrUndefined(comboRow)) {
         return;
      }

      const comboDto = comboRow.data as ComboDto;

      if (isNullOrUndefined(comboDto)) {
         return;
      }

      if (comboDto.isCfsRunning === payload.hasRunningStrategies) {
         return;
      }

      comboDto.isCfsRunning = payload.hasRunningStrategies;

      this._combosGrid.api.refreshCells({force: true, rowNodes: [comboRow]});
   }
   
   //
   private onBucketSummaryDto(dtos: BucketSummaryDto[]): void {

      const portfolioDatas = [];
      const comboDatas = [];
      const groupDatas = [];

      dtos.forEach(dto => {

         if (dto.bucketType === 'Portfolio') {

            if (this._portfolioRaceData) {
               this._portfolioRaceData.push(dto);
               
               return;
            }
            
            const node = this._portfoliosGrid.api.getRowNode(dto.portfolioId);
            
            if (node) {
               const data: PortfolioDto = node.data;
               data.sessionPnL = dto.sessionTotalPnL;
               data.accumulatedPnL = dto.accumulatedTotalPnL;
               data.totalDelta = dto.totalDelta;

               portfolioDatas.push(data);
            }

         } else if (dto.bucketType === 'Combo') {

            if (this._comboRaceData) {
               this._comboRaceData.push(dto);

               return;
            }

            const node = this._combosGrid.api.getRowNode(dto.comboId);
            
            if (node) {
               const data: ComboDto = node.data;
               data.sessionPnL = dto.sessionTotalPnL;
               data.accumulatedPnL = dto.accumulatedTotalPnL;
               data.totalDelta = dto.totalDelta;

               comboDatas.push(data);
            }

         } else if (dto.bucketType === 'ComboGroup') {

            if (this._comboGroupRaceData) {
               this._comboGroupRaceData.push(dto);

               return;
            }

            const node = this._comboGroupsGrid.api.getRowNode(dto.comboGroupId);
            
            if (node) {
               const data: ComboGroupDto = node.data;
               data.sessionPnL = dto.sessionTotalPnL;
               data.accumulatedPnL = dto.accumulatedTotalPnL;
               data.totalDelta = dto.totalDelta;

               groupDatas.push(data);
            }

         }
      });

      if (portfolioDatas.length > 0) {
         this._portfoliosGrid.api.applyTransactionAsync({update: portfolioDatas});
      }

      if (comboDatas.length > 0) {
         this._combosGrid.api.applyTransactionAsync({update: comboDatas});
      }

      if (groupDatas.length > 0) {
         this._comboGroupsGrid.api.applyTransactionAsync({update: groupDatas});
      }
   }

   //
   private async loadCombosByTerminal(terminal: TerminalDto): Promise<void> {
      
      if (!terminal) { 
         return; 
      }

      console.debug(`portfolios|loading combos by terminal. Terminal = ${terminal.displayName} (${terminal.terminalId})`);

      try {
         
         this._combosGrid.api.showLoadingOverlay();

         this._comboRaceData = [];

         const combos: ComboDto[] = await this._portfoliosService.getCombosByTerminal(terminal.terminalId);
         
         this._combosGrid.api.setRowData(combos);
         
         this._comboGroupsGrid.api.setRowData([]);

         if (this._comboRaceData.length > 0) {
            
            console.debug(`portfolios|combo pending items = ${this._comboRaceData.length}`);
            
            const maxUpdate = Math.max(...combos.map(x => x.lastUpdate));
            
            const raceItems = this._comboRaceData.filter(x => x.lastUpdate > maxUpdate);
            
            this._comboRaceData = null;

            if (raceItems.length > 0) {

               console.debug(`portfolios|combo race items = ${raceItems.length}`);
               this.onBucketSummaryDto(raceItems);
               
            }
         } else {
            console.assert(this._comboRaceData.length === 0);
            console.debug(`portfolios|no combo pending items`);
            this._comboRaceData = null;
         }

      } catch {

         this._toastr.error('"Load Combos By Terminal" operation completed with errors');

      } finally {

         this._combosGrid.api.hideOverlay();

      }

      this._messageBus.publishAsync<ShowBucketPositionsUIMessage>({
         topic: 'ShowBucketPositionsUIMessage',
         payload: {
            bucketContext: {
               bucketType: 'Terminal',
               bucketId: terminal.terminalId,
               bucketName: terminal.displayName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   private async loadCombosByPortfolio(data: PortfolioDto): Promise<void> {
      if (!data) { 
         return; 
      }

      console.debug(`portfolios|loading combos by portfolio. Portfolio = ${data.portfolioName} (${data.portfolioId})`);

      try {
         
         this._combosGrid.api.showLoadingOverlay();

         this._comboRaceData = [];

         const combos: ComboDto[] = await this._portfoliosService.getCombosByPortfolioId(data.portfolioId);
         this._combosGrid.api.setRowData(combos);
         this._comboGroupsGrid.api.setRowData([]);

         if (this._comboRaceData.length > 0) {
            
            console.debug(`portfolios|combo pending items = ${this._comboRaceData.length}`);
            
            const maxUpdate = Math.max(...combos.map(x => x.lastUpdate));
            
            const raceItems = this._comboRaceData.filter(x => x.lastUpdate > maxUpdate);
            
            this._comboRaceData = null;

            if (raceItems.length > 0) {

               console.debug(`portfolios|combo race items = ${raceItems.length}`);
               this.onBucketSummaryDto(raceItems);
               
            }
         } else {
            console.assert(this._comboRaceData.length === 0);
            console.debug(`portfolios|no combo pending items`);
            this._comboRaceData = null;
         }

      } catch {

         this._toastr.error('"Load Combos By Portfolio" operation completed with errors');

      } finally {

         this._combosGrid.api.hideOverlay();

      }

      this._messageBus.publish<ShowBucketPositionsUIMessage>({
         topic: 'ShowBucketPositionsUIMessage',
         payload: {
            bucketContext: {
               bucketType: 'Portfolio',
               bucketId: data.portfolioId,
               bucketName: data.portfolioName   
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   private async loadComboGroupsByCombo(data: ComboDto): Promise<void> {
      
      if (!data) {
         this._comboGroupsGrid.api.setRowData([]);
         return;
      }

      console.debug(`portfolios|loading comboGroups by combo. Combo = ${data.comboName} (${data.comboId})`);

      try {

         this._comboGroupsGrid.api.showLoadingOverlay();
         
         this._comboGroupRaceData = [];

         const comboGroups: ComboGroupDto[] = await this._portfoliosService.getComboGroupsByComboId(data.comboId);
         
         this._comboGroupsGrid.api.setRowData(comboGroups);

         if (this._comboGroupRaceData.length > 0) {
            
            console.debug(`portfolios|combogroup pending items = ${this._comboGroupRaceData.length}`);
            
            const maxUpdate = Math.max(...comboGroups.map(x => x.lastUpdate));
            
            const raceItems = this._comboGroupRaceData.filter(x => x.lastUpdate > maxUpdate);
            
            this._comboGroupRaceData = null;

            if (raceItems.length > 0) {

               console.debug(`portfolios|comboGroup race items = ${raceItems.length}`);
               this.onBucketSummaryDto(raceItems);

            }
         } else {
            console.assert(this._comboGroupRaceData.length === 0);
            console.debug(`portfolios|no combogroup pending items`);
            this._comboGroupRaceData = null;
         }

      }  finally {
         this._comboGroupsGrid.api.hideOverlay();
      }

      this._messageBus.publish<ShowBucketPositionsUIMessage>({
         topic: 'ShowBucketPositionsUIMessage',
         payload: {
            bucketContext: {
               bucketType: 'Combo',
               bucketId: data.comboId,
               bucketName: data.comboName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   async loadComboGroupItems(data: ComboGroupDto) {

      if (!data) {
         return;
      }

      this._messageBus.publish<ShowBucketPositionsUIMessage>({
         topic: 'ShowBucketPositionsUIMessage',
         payload: {
            bucketContext: {
               bucketType: 'ComboGroup',
               bucketId: data.comboGroupId,
               bucketName: data.comboGroupName
            }
         },
         scopeId: this.layoutTabId
      });
   }

   //
   @DetectMethodChanges()
   showRenameDialog(node: RowNode, groupType: 'portfolio' | 'combo' | 'group') {
      let groupId: string;
      let groupName: string;
      let isAuto = false;

      if (groupType === 'combo')  {
         
         const item = node.data as PortfolioItemDto;
         groupId = item.comboId;
         groupName = item.comboName;

         if (groupName.startsWith('[Auto]'))
         {
            const firstWhiteSpace = groupName.indexOf(' ');
            groupName = groupName.substring(firstWhiteSpace + 1);
            isAuto = true;
         }

      } else if (groupType === 'portfolio') {

         if (isNullOrUndefined(node.data)) {
            return;
         }

         const pf = node.data as PortfolioDto;
         groupId = pf.portfolioId;
         groupName = pf.portfolioName;

      } else if (groupType === 'group') {

         const item = node.data as PortfolioItemDto;
         groupId = item.comboGroupId;
         groupName = item.comboGroupName;

      } else {

         this._toastr.error('Incorrect Group Type');
         return;
      }

      if (isNullOrUndefined(groupId)) {
         this._toastr.error('Cannot determine group ID');
         return;
      }

      this.renameBucketConfig = {
         bucketId: groupId,
         bucketType: groupType,
         newBucketName: groupName,
         oldGroupName: groupName,
         isAuto,
         isVisible: true
      };
   }

   //
   @DetectMethodChanges()
   createPortfolio(): void {
      this.createPortfolioConfig.terminalList = this._sessionService.loginResult.availableTerminals.filter( t => !t.isProxy).slice();
      this.createPortfolioConfig.portfolioName = null;
      this.createPortfolioConfig.terminalId = null;
      this.createPortfolioConfig.isVisible = true;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async deletePortfolio(): Promise<void> {
      
      if (!this.selectedPortfolio) {
         this._toastr.error('Select Portfolio First');
         return;
      }

      this.deleteBucketDialog.context = { 
         bucketId: this.selectedPortfolio.portfolioId,
         bucketType: 'Portfolio',
         bucketName: this.selectedPortfolio.portfolioName
      };

      this.deleteBucketDialog.isVisible = true;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async archivePortfolio(): Promise<void> {
      
      if (!this.selectedPortfolio) {
         this._toastr.error('Select Portfolio First');
         return;
      }
      
      await this._portfoliosService.archivePortfolio(this.selectedPortfolio.portfolioId);

   }
   
   //
   @DetectMethodChanges()
   renamePortfolio(): void {
      
      if (!this.selectedPortfolio) {
         this._toastr.error('Select Portfolio First');
         return;
      }
      
      const node = this._portfoliosGrid.api.getRowNode(this.selectedPortfolio.portfolioId);
      
      this.showRenameDialog(node, 'portfolio');
   }

   //
   @DetectMethodChanges({isAsync: true})
   async hedgePortfolio(): Promise<void> {
      
      if (!this.editStrategyDialog) {
         return;
      }

      if (!this.selectedPortfolio) {
         return;
      }

      const str = new StrategyDto();
      str.algoId = EtsConstants.algorithms.dynamicHedgeAlgoId;

      const dialogConfig: DialogConfig = { 
         isUpdate: false, 
         strategy: str,
         editMode: 'immediate', 
         strategyKind: StrategyKind.Engine, 
         source: 'main' 
      };
      
      await this.editStrategyDialog.show(dialogConfig);

      this.editStrategyDialog.boltOn({ 
         terminalId: this.selectedPortfolio.terminalId, 
         portfolioId: this.selectedPortfolio.portfolioId 
      });
   }

   //
   @DetectMethodChanges()
   transferPortfolio(portfolio: PortfolioDto): void {
      const config: TransferBucketDialogConfig = {
         bucketId: portfolio.portfolioId,
         bucketType: 'Portfolio',
         originalTerminal: portfolio.terminalId,
         originalPortfolio: portfolio.portfolioId,
         originalCombo: null
      };
      this.transferBucketDialog.show(config);
   }

   //
   @DetectMethodChanges()
   duplicatePortfolio(portfolio: PortfolioDto): void {
      const config: DuplicateBucketDialogConfig = {
         bucketId: portfolio.portfolioId,
         bucketType: 'Portfolio',
         originalTerminal: portfolio.terminalId,
         originalPortfolio: portfolio.portfolioId,
         originalCombo: null
      };
      this.duplicateBucketDialog.show(config);
   }

   //
   @DetectMethodChanges()
   createCombo(): void {

      if (!this.selectedPortfolio) {
         this._toastr.error('Select portfolio first');
         return;
      }

      this.createComboConfig = {
         portfolioId: this.selectedPortfolio.portfolioId,
         templates: [
            'Hedged Portfolio',
            'Reversed Hedged Portfolio',
            'Calls',
            'Puts',
            'Calls & Puts'
         ],
         isAuto: false
      };
      
      this.createComboConfig.isVisible = true;

   }

   //
   @DetectMethodChanges()
   renameCombo(): void {
      if (!this.selectedCombo) {
         this._toastr.error('Select Combo First');
         return;
      }
      const node = this._combosGrid.api.getRowNode(this.selectedCombo.comboId);
      
      this.showRenameDialog(node, 'combo');
   }

   //
   @DetectMethodChanges({isAsync: true})
   async hedgeCombo(): Promise<void> {
      if (!this.editStrategyDialog) {
         return;
      }

      if (!this.selectedPortfolio) {
         return;
      }

      if (!this.selectedCombo) {
         return;
      }

      const str = new StrategyDto();
      str.algoId = EtsConstants.algorithms.dynamicHedgeAlgoId;
      
      const dialogConfig: DialogConfig = { 
         isUpdate: false, 
         strategy: str, 
         editMode: 'immediate', 
         strategyKind: StrategyKind.Engine,
         source: 'main'
      };

      await this.editStrategyDialog.show(dialogConfig);


      this.editStrategyDialog.boltOn({ 
         terminalId: this.selectedPortfolio.terminalId, 
         portfolioId: this.selectedPortfolio.portfolioId, 
         comboId: this.selectedCombo.comboId 
      });
   }
   
   //
   @DetectMethodChanges({isAsync: true})
   async deleteCombo(): Promise<void> {
      
      if (!this.selectedCombo) {
         this._toastr.error('Select Combo First');
         return;
      }
      
      this.deleteBucketDialog.context = { 
         bucketId: this.selectedCombo.comboId,
         bucketType: 'Combo',
         bucketName: this.selectedCombo.comboName
      };

      this.deleteBucketDialog.isVisible = true;

   }

   //
   @DetectMethodChanges({isAsync: true})
   async archiveCombo(): Promise<void> {
      
      if (!this.selectedCombo) {
         this._toastr.error('Select Combo First');
         return;
      }
      
      await this._portfoliosService.archiveCombo(this.selectedCombo.comboId);
   }

   //
   @DetectMethodChanges()
   transferCombo(combo: ComboDto): void {
      const config: TransferBucketDialogConfig = {
         bucketId: combo.comboId,
         bucketType: 'Combo',
         originalTerminal: null,
         originalPortfolio: null,
         originalCombo: null
      };
      this.transferBucketDialog.show(config);
   }
   
   //
   @DetectMethodChanges()
   duplicateCombo(combo: ComboDto): void {
      const config: DuplicateBucketDialogConfig = {
         bucketId: combo.comboId,
         bucketType: 'Combo',
         originalTerminal: null,
         originalPortfolio: null,
         originalCombo: null
      };
      this.duplicateBucketDialog.show(config);
   }

   //
   @DetectMethodChanges()
   createComboGroup(): void {
      
      if (!this.selectedCombo) {
         this._toastr.error('Select Combo First');
         return;
      }

      this.createComboGroupConfig.portfolioId = this.selectedCombo.portfolioId;
      this.createComboGroupConfig.comboId = this.selectedCombo.comboId;
      
      this.createComboGroupConfig.isVisible = true;
   }
   
   //
   @DetectMethodChanges()
   renameComboGroup(): void {
      if (!this.selectedComboGroup) {
         this._toastr.error('Select Combo Group First');
         return;
      }
      const node = this._comboGroupsGrid.api.getRowNode(this.selectedComboGroup.comboGroupId);
      this.showRenameDialog(node, 'group');
   }
   
   //
   @DetectMethodChanges({isAsync: true})
   async deleteComboGroup(): Promise<void> {
      
      if (!this.selectedComboGroup) {
         this._toastr.error('Select Combo Group First');
         return;
      }
      
      this.deleteBucketDialog.context = { 
         bucketId: this.selectedComboGroup.comboGroupId,
         bucketType: 'ComboGroup',
         bucketName: this.selectedComboGroup.comboGroupName
      };

      this.deleteBucketDialog.isVisible = true;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async archiveComboGroup(): Promise<void> {
      
      if (!this.selectedComboGroup) {
         this._toastr.error('Select Combo Group First');
         return;
      }
      
      await this._portfoliosService.archiveComboGroup(this.selectedComboGroup.comboGroupId);
   }
   
   //
   @DetectMethodChanges()
   transferComboGroup(comboGroup: ComboGroupDto): void {
      const config: TransferBucketDialogConfig = {
         bucketId: comboGroup.comboGroupId,
         bucketType: 'ComboGroup',
         originalTerminal: null,
         originalPortfolio: null,
         originalCombo: null
      };
      this.transferBucketDialog.show(config);
   }

   //
   @DetectMethodChanges()
   duplicateComboGroup(comboGroup: ComboGroupDto): void {
      const config: DuplicateBucketDialogConfig = {
         bucketId: comboGroup.comboGroupId,
         bucketType: 'ComboGroup',
         originalTerminal: null,
         originalPortfolio: null,
         originalCombo: null
      };
      this.duplicateBucketDialog.show(config);
   }

   //
   @DetectMethodChanges({isAsync: true})
   async hedgeComboGroup(): Promise<void> {
      if (!this.editStrategyDialog) {
         return;
      }

      if (!this.selectedPortfolio) {
         return;
      }

      if (!this.selectedCombo) {
         return;
      }

      if (!this.selectedComboGroup) {
         return;
      }

      const str = new StrategyDto();
      str.algoId = EtsConstants.algorithms.dynamicHedgeAlgoId;
      
      const dialogConfig: DialogConfig = { 
         isUpdate: false, 
         strategy: str, 
         editMode: 'immediate', 
         strategyKind: StrategyKind.Engine ,
         source: 'main'
      };
      await this.editStrategyDialog.show(dialogConfig);

     
      this.editStrategyDialog.boltOn({ 
         terminalId: this.selectedPortfolio.terminalId, 
         portfolioId: this.selectedPortfolio.portfolioId, 
         comboId: this.selectedCombo.comboId, 
         comboGroupId: this.selectedComboGroup.comboGroupId
      });
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onCreatePortfolioClicked(config: CreatePortfolioConfig): Promise<void> {
      if (!config.portfolioName) {
         return;
      }

      if (!config.terminalId) {
         return;
      }

      try {
         
         this.isLoading = true;

         await this._portfoliosService.createPortfolio(
            config.portfolioName,
            config.terminalId
         );
   
      } catch (e) {

         this._toastr.error('"Create Portfolio" operation completed with errors', 'Create Portfolio');
         console.error(e);

      } finally {

         this.isLoading = false;

      }
      
   }

   //
   @DetectMethodChanges()
   onCloseCreatePortfolioDialogClicked() {
      this.createPortfolioConfig = {};
   }
   
   //
   @DetectMethodChanges({isAsync: true})
   async onCreateComboClicked(config: CreateComboConfig): Promise<void> {
      if (!config.portfolioId) {
         this._toastr.error('Unkown Portfolio');
         return;
      }

      if (!config.comboName) {
         this._toastr.error('Combo Name Not Set');
         return;
      }

      try {
         
         this.isLoading = true;

         await this._portfoliosService.createCombo(
            config.comboName,
            config.portfolioId,
            config.templateName,
            config.isAuto
         );

      } catch (e) {

         this._toastr.error('"Create Combo" operation completed with errors', 'Create Combo');
         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   @DetectMethodChanges()
   onCloseCreateComboDialogClicked() {
      this.createComboConfig = {};
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onCreateComboGroupClicked(config: CreateComboGroupConfig): Promise<void> {
      if (!config.portfolioId) {
         this._toastr.error('Unkown Portfolio');
         return;
      }

      if (!config.comboId) {
         this._toastr.error('Unknown Combo');
         return;
      }

      if (!config.comboGroupName) {
         this._toastr.error('Name not set');
         return;
      }


      try {
         
         this.isLoading = true;
  
         await this._portfoliosService.createComboGroup(
            config.comboGroupName,
            config.portfolioId,
            config.comboId
         );

      } catch (e) {

         this._toastr.error('"Create ComboGroup" operation completed with errors', 'Create ComboGroup');
         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   @DetectMethodChanges()
   onCloseCreateComboGroupDialogClicked() {
      this.createComboGroupConfig = {};
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onRenameBucketClicked(): Promise<void> {
      if (!this.renameBucketConfig.newBucketName) {
         this._toastr.error('New Bucket Name Not Provided');
         return;
      }

      if (this.renameBucketConfig.oldGroupName === this.renameBucketConfig.newBucketName) {
         this._toastr.error('Bucket Name Not Changed');
         return;
      }

      if (!this.renameBucketConfig.bucketId) {
         this._toastr.error('Incorect Configuration');
         return;
      }

      if (!this.renameBucketConfig.bucketType) {
         this._toastr.error('Incorect Configuration');
         return;
      }

      if (this.renameBucketConfig.isAuto) {
         if (this.renameBucketConfig.newBucketName.startsWith('[Auto]')) {
            this._toastr.error('Auto-managed combo cannot contain [Auto] in its name');
            return;
         }
      }

      const bucketId = this.renameBucketConfig.bucketId;
      const bucketName = this.renameBucketConfig.newBucketName;
      const bucketType = this.renameBucketConfig.bucketType;

      if (bucketType === 'portfolio') {
         await this._portfoliosService.renamePortfolio(bucketId, bucketName);
      } else if (bucketType === 'combo') {
         await this._portfoliosService.renameCombo(bucketId, bucketName);
      } else if (bucketType === 'group') {
         await this._portfoliosService.renameComboGroup(bucketId, bucketName);
      }

      this.renameBucketConfig = {};
   }

   //
   @DetectMethodChanges()
   onCancelRenameBucketClicked() {
      this.renameBucketConfig = {};
   }

   //
   @DetectMethodChanges({isAsync: true})
   async showPositionSizingDialog(bucketId: string, bucketType: BucketType): Promise<void> {

      this.positionSizingDialog.bucketType = bucketType;
      this.positionSizingDialog.bucketId = bucketId;
      this.positionSizingDialog.isVisible = true;
      
      this.positionSizingDialog.isLoading = true;
      try {
         const qty = new GetBucketAttributes(bucketId);
         const attrs = await this._shellClient.processQuery<BucketAttributes>(qty);
         // const psAttr = attrs.find(a => a.name === EtsConstants.bucketAttributes.positionSizing)?.value;
         // if (psAttr) {
         //    this.positionSizingDialog.positionSizing = JSON.parse(psAttr) as StrategyPositionSizing;
         // }
      } finally {
         this.positionSizingDialog.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   showInterestRateDialog(bucketId: string, bucketType: BucketType) {
      this.interestRateDialog.bucketType = bucketType;
      this.interestRateDialog.bucketId = bucketId;
      this.interestRateDialog.isVisible = true;
   }

   //
   @DetectMethodChanges()
   interestRateDialog_onHidden() {
      this.interestRateDialog = {
         parameters: new InterestCalculatorParameters(this._changeDetector, this._sessionService)
      };
   }

   //
   @DetectMethodChanges()
   interestRateDialog_onCancel() {
      this.interestRateDialog.isVisible = false;
   }

   //
   @DetectMethodChanges()
   async interestRateDialog_onSave() {
      console.log(this.interestRateDialog);

      let everyXday = this.interestRateDialog.parameters.everyXday;
      let everyXweek = this.interestRateDialog.parameters.everyXweek;
      let mon = this.interestRateDialog.parameters.mon;
      let tue = this.interestRateDialog.parameters.tue;
      let wed = this.interestRateDialog.parameters.wed;
      let thu = this.interestRateDialog.parameters.thu;
      let fri = this.interestRateDialog.parameters.fri;

      if (this.interestRateDialog.parameters.repeat === 'Daily') {
         everyXweek = mon = tue = wed = thu = fri = null;
      } else if (this.interestRateDialog.parameters.repeat === 'Weekly') {
         everyXday = null;
      }

      const cmd = new CreateInterestCalculatorStrategy(
         this.interestRateDialog.bucketId,
         this.interestRateDialog.bucketType,
         this.interestRateDialog.parameters.displayName,
         this.interestRateDialog.parameters.amount,
         this.interestRateDialog.parameters.rate,
         this.interestRateDialog.parameters.account,
         this.interestRateDialog.parameters.repeat,
         everyXday,
         everyXweek,
         mon,
         tue,
         wed,
         thu,
         fri,
         this.interestRateDialog.parameters.timeOfDay,
         this.interestRateDialog.parameters.timezone
      );

      this.interestRateDialog.isVisible = false;

      this.isLoading = true;
      
      try {

         await this._shellClient.processCommand(cmd);

      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   interestRateDialog_onChange() {

   }

   //
   @DetectMethodChanges()
   positionSizingDialog_onHidden() {
      this.positionSizingDialog.isVisible = false;
      this.positionSizingDialog.bucketType = null;
      this.positionSizingDialog.bucketId = null;
      this.positionSizingDialog.positionSizing = new StrategyPositionSizing();
   }

   //
   @DetectMethodChanges()
   positionSizingDialog_onCancel() {
      this.positionSizingDialog.isVisible = false;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async positionSizingDialog_onSave(): Promise<void> {

      const sizingParams = this.positionSizingDialog.positionSizing;
      const bucketId = this.positionSizingDialog.bucketId;
      const bucketType = this.positionSizingDialog.bucketType;

      const attr: BucketAttributes = {
         positionSizing: JSON.stringify(sizingParams)
      };

      const cmd = new SaveBucketAttributes(bucketId, bucketType, attr);

      try {

         this.positionSizingDialog.isLoading = true;
         
         await this._shellClient.processCommand(cmd);

         if (this.positionSizingDialog.bucketType === 'Combo') {
            const node = this._combosGrid.api.getRowNode(this.positionSizingDialog.bucketId);
            if (node) {
               (node.data as ComboDto).hasAttributes = true;
               this._combosGrid.api.refreshCells({force: true});
            }
         } else if (this.positionSizingDialog.bucketType === 'Portfolio') {
            const node = this._portfoliosGrid.api.getRowNode(this.positionSizingDialog.bucketId);
            if (node) {
               (node.data as PortfolioDto).hasAttributes = true;
               this._portfoliosGrid.api.refreshCells({force: true});
            }
         } else if (this.positionSizingDialog.bucketType === 'ComboGroup') {
            const node = this._comboGroupsGrid.api.getRowNode(this.positionSizingDialog.bucketId);
            if (node) {
               (node.data as ComboGroupDto).hasAttributes = true;
               this._comboGroupsGrid.api.refreshCells({force: true});
            }
         }

      } finally {

         this.positionSizingDialog.isLoading = false;
         this.positionSizingDialog.isVisible = false;
      }

   }

   //
   private onPortfolioCreatedMessage(dto: PortfolioCreatedDto): void {
      this._portfoliosGrid.api.applyTransactionAsync({add: [dto.portfolio]});
   }

   //
   private onComboCreatedMessage(msg: ComboCreatedDto): void {
      if (!this.selectedPortfolio) {
         return;   
      }

      if (this.selectedPortfolio.portfolioId === msg.combo.portfolioId) {
         this._combosGrid.api.applyTransactionAsync({add: [msg.combo]});
      }
   }

   //
   private onComboGroupCreatedMessage(msg: ComboGroupCreatedDto): void {
      
      if (!this.selectedCombo) {
         return;
      }
      
      
      if (this.selectedCombo.comboId === msg.comboGroup.comboId) {
         this._comboGroupsGrid.api.applyTransaction({add: [msg.comboGroup]});
      }

   }

   //
   @DetectMethodChanges()
   private onGroupDeletedMessage(dto: GroupDeletedDto): void {

      if (dto.groupType === 'portfolio') {
      
         this._portfoliosGrid.api.applyTransactionAsync({remove: [{portfolioId: dto.portfolioId}]});

         if (this.selectedPortfolio && this.selectedPortfolio.portfolioId === dto.portfolioId) {
            this._combosGrid.api.setRowData([]);
            this._comboGroupsGrid.api.setRowData([]);
            this.selectedPortfolio = null;
         }
   
      } else if (dto.groupType === 'combo') {

         this._combosGrid.api.applyTransaction({remove: [{comboId: dto.comboId}]});
            
         if (this.selectedCombo && this.selectedCombo.comboId === dto.comboId) {
            this._comboGroupsGrid.api.setRowData([]);
            this.selectedCombo = null;
         }

      } else if (dto.groupType === 'group') {
                   
         this._comboGroupsGrid.api.applyTransaction({remove: [{ comboGroupId: dto.comboGroupId }]});

         if (this.selectedComboGroup && this.selectedComboGroup.comboGroupId === dto.comboGroupId) {
            this.selectedComboGroup = null;
            // TODO: send message to clear portfolio positions panel
         }
      }

   }

   //
   @DetectMethodChanges()
   private onGroupRenamedMessage(msg: GroupRenamedDto): void {

      if (msg.groupType  === 'portfolio') {

         const node = this._portfoliosGrid.api.getRowNode(msg.portfolioId);

         if (node) {
            const portfolio: PortfolioDto = node.data;
            portfolio.portfolioName = msg.newGroupName;
            // this._portfoliosGrid.api.refreshCells({rowNodes: [node], columns: ['portfolioName'], force: true});
            this._portfoliosGrid.api.redrawRows({rowNodes: [node]});
         }
   
      } else if (msg.groupType === 'combo') {

         const node = this._combosGrid.api.getRowNode(msg.comboId);
         
         if (node) {
            const combo: ComboDto = node.data;
            combo.comboName = msg.newGroupName;
            this._combosGrid.api.refreshCells({rowNodes: [node], columns: ['comboName'], force: true});
         }
         
      } else if (msg.groupType === 'group') {
         
         const node = this._comboGroupsGrid.api.getRowNode(msg.comboGroupId);

         if (node) {
            const group: ComboGroupDto = node.data;
            group.comboGroupName = msg.newGroupName;
            this._comboGroupsGrid.api.refreshCells({rowNodes: [node], columns: ['comboGroupName'], force: true});
         }
        
      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   private async onClearTradingDataMessage(msg: ClearTradingDataUIMessage): Promise<void> {
      this.selectedComboGroup = this.selectedCombo = this.selectedPortfolio = undefined;
      this._comboGroupsGrid.api.setRowData([]);
      this._combosGrid.api.setRowData([]);
      this._portfoliosGrid.api.setRowData([]);
      await this.loadPortfolios();
   }

   //
   private async loadPortfolios(): Promise<void> {
      
      this._portfoliosGrid.api.showLoadingOverlay();
      
      try {
      
         this._portfolioRaceData = [];

         const portfolios = await this._portfoliosService.getPortfolios();
         this._portfoliosGrid.api.setRowData(portfolios);   
         this.expandTerminalGroups();

         if (this._portfolioRaceData.length > 0) {
            
            console.debug(`portfolios|portfolio pending items = ${this._portfolioRaceData.length}`);
            
            const maxUpdate = Math.max(...portfolios.map(x => x.lastUpdate));
            
            const raceItems = this._portfolioRaceData.filter(x => x.lastUpdate > maxUpdate);
            
            this._portfolioRaceData = null;

            if (raceItems.length > 0) {

               console.debug(`portfolios|portfolio race items = ${raceItems.length}`);
               this.onBucketSummaryDto(raceItems);

            }
         } else {
            console.assert(this._portfolioRaceData.length === 0);
            console.debug(`portfolios|no portoflio pending items`);
            this._portfolioRaceData = null;
         }

      } finally {
         this._portfoliosGrid.api.hideOverlay();
      }
   }

   //
   protected getState(): any { return null; }

   //
   protected setState(state) { }

   @DetectMethodChanges()
   showBucketHistory(data: any, bucketType: BucketType): void {
      if (!this.sessionHistoryDialog) {
         return;
      }

      const config: SessionHistoryConfig = {
         bucketType,
         data
      };

      this.sessionHistoryDialog.show(config);
   }
}
