import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { AlgoDescriptionBuilderBase } from '../../algo-description-builder-base.class';

export class LongOptionAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
   getSymbolPropertyNames(): string[] {
      return ['symbol'];
   }

   getAlgoName(parameters: StrategyParameters): string {
      return 'Protective Option';
   }

   getDescription(parameters: StrategyParameters): string {
      let res = '';
      
      // const defaultValue = 'N/A';
      // let symbol: string = parameters.symbol || defaultValue;

      // if (symbol !== defaultValue) {
      //    symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
      // }

      // res += `[${symbol}]`;
      // res += ` Multiplier: ${parameters.multiplier || defaultValue}`;
      
      return res;
   }
}
