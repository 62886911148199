import { SessionHistoryComponent } from './session-history.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { CellClassParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   AG_SYMBOL_COL_MIN_WIDTH,
   AG_DATE_COL_MIN_WIDTH
} from 'projects/shared-components/ag-grid-contrib';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { TradeDto, tradeHasFlag, TradeFlags } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';

export function getTradesGridModel(this: SessionHistoryComponent): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Symbol',
            field: 'tickerDisplayName',
            minWidth: AG_SYMBOL_COL_MIN_WIDTH,
            filter: true
         },

         {
            headerName: 'Qty',
            field: 'qty',
            valueGetter: (params: ValueGetterParams) => {
               const data: TradeDto = params.data;
               return data.qty * data.side;
            },
            valueFormatter: defaultNumberCellFormatter
         },

         {
            headerName: 'Price',
            field: 'execPrice',
            valueFormatter: defaultQuoteCellFormatter
         },

         {
            headerName: 'Buy/Sell',
            field: 'side',
            valueFormatter: (params: CellClassParams) => {
               return MarketSide[params.value];
            },
            filter: true
         },

         {
            headerName: 'Order Type',
            field: 'sourceOrderType',
            valueFormatter: (params: CellClassParams) => {
               return OrderType[params.value];
            },
            filter: true
         },

         Object.assign(
            { headerName: 'P&L', field: 'pnL' },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            { headerName: 'Perf. P&L', field: 'perfectPnL' },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            { headerName: 'Trade P&L', field: 'tradePnL' },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            { headerName: 'Acc. P&L', field: 'accumulatedPnL' },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            { headerName: 'Acc. Perf. P&L', field: 'accumulatedPerfectPnL' },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            { headerName: 'Acc. Trade P&L', field: 'accumulatedTradePnL' },
            defaultMoneyCellDefinition
         ),

         {
            headerName: 'Account',
            field: 'accountCode',
            filter: true
         },

         {
            headerName: 'Terminal',
            field: 'terminalCode',
            filter: true
         },

         {
            headerName: 'Timestamp',
            field: 'timestamp',
            minWidth: AG_DATE_COL_MIN_WIDTH,
            valueFormatter: (params: CellClassParams) => {
               const trade: TradeDto = params.data;
               const frmtTime = this.timestampsService.getFormattedDateTimeForStrategy(trade.strategyId, trade.timestamp);
               return trade.timestamp
                  ? frmtTime
                  : '';
            }
         },

         {
            headerName: 'Trade Id',
            field: 'tradeId',
            filter: 'agTextColumnFilter'
         },

         {
            headerName: 'Order Id',
            field: 'orderId',
            filter: 'agTextColumnFilter'
         },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: args => this.onTradesGridReady(args),

      getRowNodeId: (rowData: TradeDto) => rowData.tradeId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Load More ...',
               disabled: !this.selectedStrategyInfo,
               subMenu: [
                  {
                     name: '25',
                     action: () => this.loadMoreTradingData(25, 'trades')
                  },
                  {
                     name: '50',
                     action: () => this.loadMoreTradingData(50, 'trades')
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreTradingData(100, 'trades')
                  }
               ]
            },
            {
               name: 'Toggle Full Size',
               action: () => this.toggleSectionFullWidth('tradingData')
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      getRowStyle: (args) => {
         const data: TradeDto = args.data;
         if (!data) {
            return undefined;
         }

         if (data.isProxy) {
            return { background: 'yellow', color: 'black' };
         }

         if (data.flags === 0) {
            return undefined;
         }

         const style = { background: '', color: 'black' };

         if (tradeHasFlag(data, TradeFlags.TradeNpo) || tradeHasFlag(data, TradeFlags.AccumulatedNpo) || tradeHasFlag(data, TradeFlags.SessionNpo)) {
            style.background = 'green';
            return style;
         }

         if (tradeHasFlag(data, TradeFlags.TradeStopLoss) || tradeHasFlag(data, TradeFlags.AccumulatedStopLoss) || tradeHasFlag(data, TradeFlags.SessionStopLoss)) {
            style.background = 'red';
            return style;
         }

         return undefined;
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      onGridSizeChanged: (args: GridSizeChangedEvent) => {
         if (args.clientHeight === 0 || args.clientWidth === 0) {
            return;
         }
         this.setSectionSize();
      }
   } as GridOptions;
}
