<div style="width: 100%;">
   <div class="empty" *ngIf="this.viewModel.portfolioItems.length === 0">
      <p>Empty Portfolio</p>
   </div>
   
   <div style="width: 100%;">
      <table class="table" style="width: 100%;"  *ngIf="this.viewModel.portfolioItems.length > 0">
         <tbody>
            <tr *ngFor="let item of this.viewModel.portfolioItems">
               <td class="parameter">{{item.tickerDisplayName}}</td>
               <td class="value">{{item.netPosition}} shrs.</td>
               <!-- <td class="price">{{item.liveQuote}}</td> -->
               <td class="price">
                  <ets-last-price [asset]="item.ticker"></ets-last-price>
               </td>
            </tr>
         </tbody>
      </table>
      
   </div>
   
</div>
