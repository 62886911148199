import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class TogglePauseStrategy implements ProtocolCommand {
  constructor(public readonly strategyIds: string[]) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.TogglePauseStrategy`;
  }
}
