import { ChangeDetectorRef } from "@angular/core";
import * as Enumerable from "linq";
import {
   DetectMethodChanges,
   DxValueChanged,
   isValidNumber,
   isVoid,
   removeExtraAdjustmentSettingsForComparison
} from "projects/shared-components/utils";
import { PriceboxRow } from "./PriceboxRow";
import { PriceboxColumn } from "./PriceboxColumn";
import { Subject } from "rxjs";
import { CashFlowAdjustment } from "./CashFlowAdjustment";
import { AdjustmentsGroup } from "./AdjustmentsGroup";
import { environment } from "projects/shared-components/environments/environment";
import { PriceboxPinnedRowsService } from "../services/pricebox-pinned-rows.service";
import { ApgPortfolio } from "./ApgPortfolio";
import {VisibleOffsetsSettingsService} from "../visible-offsets/visible-offsets-settings.service";
import {AdjustmentPricingSettingsDto} from "../../shell-communication/shell-operations-protocol";
import {FavoriteAdjustment} from "../pricebox-menu/FavoriteAdjustment";

type ExpirationColumn = {
   date: string;
   sort: 'asc' | 'desc';
};

interface GridDataProvider {
   getSelectedPortfolio(): ApgPortfolio;
}

export class PriceboxSectionModel {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _unsubscriber: Subject<void>,
      private readonly _pinnedPriceboxRowsService: PriceboxPinnedRowsService,
      private readonly _gridDataProvider: GridDataProvider,
      private readonly _visibleOffsetsService: VisibleOffsetsSettingsService
   ) {
      this.showImpliedVolatility = this._visibleOffsetsService.priceBox.showImpliedVolatility;
      this.showZonesGridArrow = this._visibleOffsetsService.priceBox.showZonesGridArrow;
      this.favoriteAdjustments = this.getFavoriteAdjustments();
      this._visibleOffsetsService
          .priceBoxUpdated$.subscribe(() => this.onPriceboxSettingsUpdated());
   }

   private _lastUsedSettings: AdjustmentPricingSettingsDto[];
   private _lastUsedStaticVol: number;

   get staticVol(): number {

      return this._lastUsedStaticVol / 100;

   }


   get lastUsedSettings(): AdjustmentPricingSettingsDto[] {
      return this._lastUsedSettings;
   }

   set lastUsedSettings(value: AdjustmentPricingSettingsDto[]) {
      if (isVoid(value)) {
         this._lastUsedSettings = undefined;
         return;
      }
      const nv = JSON.parse(JSON.stringify(value)) as AdjustmentPricingSettingsDto[];
      this._lastUsedStaticVol = nv[0].theoreticalPriceIv;
      nv.forEach(x => removeExtraAdjustmentSettingsForComparison(x));
      this._lastUsedSettings = nv;
   }

   get isMultiStrategy(): boolean {
      if (this.rows.length === 0) {
         return false;
      }

      if (!isVoid(this.rows[0].adjustmentType2)) {
         return true;
      }

      return false;
   }

   get firstStrategyHeader(): string {
      if (this.rows.length === 0) {
         return undefined;
      }
      return this.rows[0].cashFlowStrategy;
   }

   get secondStrategyHeader(): string {
      if (this.rows.length === 0) {
         return undefined;
      }
      return this.rows[0].cashFlowStrategy2;
   }

   get sortedRows(): PriceboxRow[] {

      const copy = this.filteredRows;

      if (isVoid(this.sortColumn)) {

         const pinned = copy.filter(x => this.isRowPinned(x));

         const notPinned = copy.filter(x => !this.isRowPinned(x));

         return pinned.concat(notPinned);

      }

      const compareFn  = (a, b) =>  {

         const aCol = a.columns.find(x => x.expirationDatePretty === this.sortColumn.date);
         const bCol = b.columns.find(x => x.expirationDatePretty === this.sortColumn.date);

         const aPrice = aCol ? (aCol.isEmpty ? NaN : aCol.price) : NaN;
         const bPrice = bCol ? (bCol.isEmpty ? NaN : bCol.price) : NaN;

         let compare = 0;

         if (isVoid(aPrice) && !isVoid(bPrice)) {
            compare = 1;
         } else  if (isVoid(bPrice) && !isVoid(aPrice)) {
            compare = -1;
         } else {
            if (this.sortColumn.sort === 'desc') {
               compare  = Math.sign(bPrice - aPrice);
            } else {
               compare = Math.sign(aPrice - bPrice);
            }
         }

         return compare;
      };

      const pinned = copy.filter(x => this.isRowPinned(x)).sort(compareFn);
      const notPinned = copy.filter(x => !this.isRowPinned(x)).sort(compareFn);

      return pinned.concat(notPinned);
   }

   rows: PriceboxRow[] = [];

   get filteredRows() : PriceboxRow[] {
      if (isVoid(this.favoriteAdjustments)) {
         return this.rows;
      }
      const filtered = this.rows.filter(r => {
         const key = `${r.adjustmentType}+${r.adjustmentType2}`;
         return this.favoriteAdjustments.includes(key);
      });
      return filtered;
   }

   columnHeaders: any[];

   sortColumn: ExpirationColumn;

   showImpliedVolatility: boolean;
   showZonesGridArrow: boolean;
   favoriteAdjustments : string[] = [];

   getCssForExpirationCol(col: ExpirationColumn) {

      if (isVoid(this.sortColumn)) {
         return undefined;
      }

      if (this.sortColumn.date !== col.date) {
         return undefined;
      }

      if (isVoid(this.sortColumn.sort)) {
         return undefined;
      }

      return [this.sortColumn.sort];
   }

   @DetectMethodChanges()
   setSortColumn(col: ExpirationColumn) {
      if (!this.sortColumn || this.sortColumn.date !== col.date) {
         this.sortColumn = {
            date: col.date,
            sort: 'asc'
         }
      } else {
         if (this.sortColumn.sort === 'asc') {
            this.sortColumn.sort = 'desc'
         } else if (this.sortColumn.sort === 'desc') {
            this.sortColumn = null;
         }
      }
   }

   @DetectMethodChanges()
   onRowPinnedChanged(ev: DxValueChanged<boolean>, row: PriceboxRow) {

      const rowId = this.getRowId(row);

      const selectedPortfolio = this._gridDataProvider.getSelectedPortfolio();

      if (isVoid(selectedPortfolio)) {
         return;
      }

      const portfolioId = selectedPortfolio.id;

      if (ev.value) {
         this._pinnedPriceboxRowsService.pinRow(portfolioId, rowId);
      } else {
         this._pinnedPriceboxRowsService.unpinRow(portfolioId, rowId);
      }

   }

   isRowPinned(row: PriceboxRow | string): boolean {
      const rowId = this.getRowId(row);

      const selectedPortfolio = this._gridDataProvider.getSelectedPortfolio();

      if (isVoid(selectedPortfolio)) {
         return false;
      }

      const portfolioId = selectedPortfolio.id;

      return this._pinnedPriceboxRowsService.isRowPinned(portfolioId, rowId)
   }

   private getRowId(row: PriceboxRow | string): string {
      let id;

      if (typeof row === 'object') {
         id = row.adjustmentType;
         if (!isVoid(row.adjustmentType2)) {
            id = id + '+' + row.adjustmentType2;
         }
      } else {
         id = row;
      }

      return id;
   }

   setData(adjustments: CashFlowAdjustment[]) {

      const badCouples = Enumerable.from(adjustments)
         .groupBy(x => { x.expiration, x.coupleTag })
         .selectMany(x => x.select(y => y.afterState))
         .where(x => {
            const faulty = Enumerable.from(x)
               .groupBy(y => y.role)
               .where(y => {
                  if (y.count() == 1) {
                     return false;
                  }

                  const exps = y.distinct(x => x.expiration).count();

                  return exps > 1;

               });

               return faulty.count() > 1;
         }).toArray();

      if (badCouples.length > 0) {
         console.error(badCouples);

         if (!environment.production) {
            alert('Bad Couples');
         }
      }

      this.rows = this.makeDataForPriceboxSection(adjustments);



      this.columnHeaders = Enumerable.from(this.rows)
         .selectMany(x => x.columns)
         .select(x => `${x.dayOfWeek} ${x.expirationDatePrettyWithDaysToExpiration}`)
         .distinct()
         .select(x => {
            const parts = x.split(' ');
            if (parts.length === 3) {
               const date = parts[1];
               const dayOfWeek = parts[0];
               const dte = parts[2];
               return {
                  date,
                  dayOfWeek,
                  dte,
                  iv: 0.093542
               }
            }

            return {
               date: parts[1],
               dayOfWeek: parts[0]
            }
         })
         .toArray();
   }

   reset() {
      this.rows = [];
      this.columnHeaders = [];
      this.lastUsedSettings = undefined;
   }

   isEmptyCell(exp: PriceboxColumn): boolean {

      if (isVoid(exp)) {
         return true;
      }

      if (exp.isEmpty) {
         return true;
      }

      if (!isValidNumber(exp.price)) {
         return true;
      }

      if (this.isFilteredCell(exp.adjustments)) {
         return true;
      }

      return false;
   }

   isFilteredCell(adjustments: CashFlowAdjustment[]) {
      const violatesSpreadOffset = this.doesViolateSpreadOffset(adjustments);

      if (violatesSpreadOffset) {
         return true;
      }

      const violateSpreadWidth = this.doesViolateSpreadWidth(adjustments);

      return violateSpreadWidth;
   }

   doesViolateSpreadOffset(adjustments: CashFlowAdjustment[]) {
      const offsetFilter = this._visibleOffsetsService.priceBox.spreadOffsetFilter;
      if (!isValidNumber(offsetFilter)) {
         return false;
      }
      const offsets = adjustments.map(x => Math.abs(x.actualSpreadOffset || 0));
      const violatesOffset = offsets.some(o => o >= offsetFilter);
      return violatesOffset;
   }

   doesViolateSpreadWidth(adjustments: CashFlowAdjustment[]) {
      const spreadFilter = this._visibleOffsetsService.priceBox.spreadWidthFilter;
      if (!isValidNumber(spreadFilter)) {
         return false;
      }
      const widths = adjustments.map(x => Math.abs(x.actualSpreadWidth || 999999999));
      const violatesWidth = widths.some(w => w <= spreadFilter);
      return violatesWidth;
   }

   private makeDataForPriceboxSection(adjustments: CashFlowAdjustment[]): PriceboxRow[] {
      const couples = adjustments.filter(x => !isVoid(x.coupleTag));

      if (!isVoid(couples)) {
         return this.makeDataForPriceboxSection2(couples);
      }

      return this.makeDataForPriceboxSection1(adjustments);
   }

   private makeDataForPriceboxSection1(adjustments: CashFlowAdjustment[]): PriceboxRow[] {

      const adjustmentsByType = Enumerable.from(adjustments)
         .groupBy(x => x.adjustmentType)
         .select(grp => {
            return {
               adjustmentType: grp.key(),
               adjustments: grp.toArray()
            }
         });

      const priceboxRows = adjustmentsByType.select(x => {
         const row = new PriceboxRow(x);
         return row;
      }).toArray();


      return priceboxRows;
   }

   private makeDataForPriceboxSection2(adjustments: CashFlowAdjustment[]): PriceboxRow[] {
      const rows = Enumerable.from(adjustments)
         .groupBy(x => x.coupleTag)
         .select(group => {
            const ajdGroup: AdjustmentsGroup = {
               adjustments: group.toArray(),
               adjustmentType: group.key()
            };
            const row = new PriceboxRow(ajdGroup);
            return row;
         })
         .toArray();

      return rows;
   }

   private onPriceboxSettingsUpdated() {
      this.showImpliedVolatility = this._visibleOffsetsService.priceBox.showImpliedVolatility;
      this.showZonesGridArrow = this._visibleOffsetsService.priceBox.showZonesGridArrow;
      this.favoriteAdjustments = this.getFavoriteAdjustments();
   }

   private getFavoriteAdjustments() : string[] {
      const json = this._visibleOffsetsService.priceBox.favoriteAdjustments;
      const favs = JSON.parse(json) as FavoriteAdjustment[] || [];
      return favs.map(x => `${x.oneAdjustment}+${x.twoAdjustment}`);
   }
}
