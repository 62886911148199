import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApgDefaultsDialogComponent } from './apg-defaults-dialog.component';
import { ApgDefaultsService } from './apg-defaults.service';
import { DevExtremeModule } from '../devextreme.module';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { AdjustmentPricingGridModule } from '../adjustment-pricing-grid/adjustment-pricing-grid.module';

@NgModule({
   declarations: [
      ApgDefaultsDialogComponent
   ],
   imports: [ 
      CommonModule, 
      DevExtremeModule, 
      EtsSymbolPickerModule,
      AdjustmentPricingGridModule
   ],
   exports: [
      ApgDefaultsDialogComponent
   ],
   providers: [
   ],
})
export class ApgDefaultsDialogModule {}