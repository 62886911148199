import {NgModule} from '@angular/core';

import {OrdersChainDialogComponent} from './orders-chain-dialog.component';
import {CommonModule} from "@angular/common";
import {DevExtremeModule} from "../devextreme.module";
import {EtsCommonModule} from "../common-services/common.module";

@NgModule({
    imports: [
        EtsCommonModule,
        CommonModule,
        DevExtremeModule
    ],
    exports: [OrdersChainDialogComponent],
    declarations: [OrdersChainDialogComponent],
    providers: [],
})
export class OrdersChainDialogModule {
}
