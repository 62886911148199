import { CashFlowAdjustmentLogicalId } from "projects/shared-components/adjustment-control-panel/CashFlowAdjustmentId";
import { CashFlowAdjustment } from "./CashFlowAdjustment";
import { isVoid } from "projects/shared-components/utils";


export class CashFlowStrategyAdjustmentType {

   underlying: string;
   adjustmentType: string;
   isEmpty: boolean;
   description: string;
   destinationStrike: string;
   logicalId: CashFlowAdjustmentLogicalId;
   isAtmStrikeOutdated: boolean;
   columnWidth?: string;
   atmStrike: number;
   direction: string;
   adjustments: CashFlowAdjustment[];

   setData(data: CashFlowAdjustment[]) {

      if (isVoid(data)) {
         return;
      }

      this.underlying = data[0].underlying;
      this.adjustmentType = data[0].adjustmentType;
      this.description = data[0].description;
      this.destinationStrike = data[0].destinationStrike;
      this.logicalId = data[0].logicalId;
      this.atmStrike = data[0].atmStrike;
      this.direction = data[0].direction;

      this.adjustments = data;

      this.adjustments.forEach(x => x.adjustmentTypeObj = this);
   }
}
