import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../../../utils";
import {LegsGroup} from "../hg-settings-section.component";

export interface HedgesSortingDialogConfig {
    calls: LegsGroup[];
    puts: LegsGroup[];
}

@Component({
    selector: 'ets-hedges-sorting-dialog',
    templateUrl: 'hedges-sorting-dialog.component.html',
    styleUrls: ['hedges-sorting-dialog.component.scss'],
})

export class HedgesSortingDialogComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
    ) {
    }

    private _config: HedgesSortingDialogConfig;

    hedgesReordered$ = new EventEmitter();

    visible = false;

    calls: LegsGroup[] = [];

    puts: LegsGroup[] = [];

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: HedgesSortingDialogConfig) {
        this.visible = true;
        this._config = config;
        this.calls = config.calls?.slice() || [];
        this.puts = config.puts?.slice() || [];
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }

    @DetectMethodChanges()
    moveHedge(hedge: LegsGroup, direction: 'up' | 'down', side: 'Calls' | 'Puts') {

        if (side !== 'Calls' && side !== 'Puts') {
            return;
        }

        const hedges = side === 'Calls' ? this.calls : this.puts;

        const ix = hedges.findIndex(p => p === hedge);

        if (ix < 0) {
            return;
        }

        let newIx = ix;

        if (newIx === 0 && direction === 'up') {
            return;
        }

        if (direction === 'up') {
            newIx -= 1;
        } else if (direction === 'down') {
            newIx += 1;
        } else {
            throw new Error('Unexpected direction');
        }

        if (newIx >= hedges.length) {
            return;
        }

        if (newIx < 0) {
            return;
        }

        const panelToMove = hedges[ix];
        const panelToReplace = hedges[newIx];

        hedges[ix] = panelToReplace;
        hedges[newIx] = panelToMove;

    }

    onSaveClicked() {
        const rankGroups = (grp: LegsGroup[]) => {
            grp.forEach( (val, ix) => {
                val.legs.forEach(leg => {
                    leg.groupOrder = ix;
                });
            });
        };

        rankGroups(this.calls);

        rankGroups(this.puts);

        setTimeout(() => {
            this.hedgesReordered$.emit();
        });

        this.onHidden();
    }


    onCancelClicked() {
        this.onHidden();
    }
}