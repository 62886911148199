import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';
import { LedgerRecord, LedgerRecordDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import { BucketHighlighted, BucketItemHighlighted } from 'projects/shared-components/ui-messages/ui-messages';
import { DetectMethodChanges, getPanelStateKey, isTruthy } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getLedgerGridOptions } from './ledger-grid-options';
import { LedgerService, LedgerSpec } from './ledger.service';
import { GetLedgerRecordsReply } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import {UserSettingsService} from "../../../../shared-components/user-settings.service";

interface LedgerNoteConfig {
   isVisible?: boolean;
   ledgerRecord?: LedgerRecord;
}

const NUM_OF_ROWS = 100;


@Component({
   selector: 'ets-ledger',
   templateUrl: 'ledger.component.html',
   styleUrls: ['./ledger.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class LedgerComponent extends PanelBaseComponent {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _timestampsService: TimestampsService,
      private readonly _ledgerService: LedgerService,
      private readonly _toastr: ToastrService,
   ) { 
      super(_changeDetector, _userSettingsService, _messageBus);
   }
   
   
   private _grid: GridReadyEvent;
   private _unsubscriber: Subject<void>;
   
   gridOptions: GridOptions;
   currentSpec: LedgerSpec = {};
   ledgerNoteConfig: LedgerNoteConfig = {};
   

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }


   etsOnInit() {

      this.gridOptions = getLedgerGridOptions.bind(this)();

      this._unsubscriber = new Subject();

      this._messageBus.of<BucketHighlighted>('BucketHighlighted')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onBucketHighlighted(x.payload));

      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onBucketItemHighlighted(x.payload));
      
      this._messageBus.of<{layoutTabId: string, record: LedgerRecord}>('LedgerNoteClickedUIMessage')
         .pipe(
            filter(x => x.payload.layoutTabId === this.layoutTabId ),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onLedgerNoteClicked(x.payload.record));
      
      this._messageBus.of<LedgerRecordDto>('LedgerRecordDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onLedgerRecordDto(x.payload));

      this._messageBus
         .of<any>('ClearPortfolioItemsUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onClearPortfolioItemsUIMessage());

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onClearTradingDataMessage(msg.payload));
      
   }
   
   async onBucketItemHighlighted(payload: BucketItemHighlighted): Promise<void> {
      const pfItem = payload.item;

      const portfolioId = pfItem.portfolioId;
      const comboId = pfItem.comboId;
      const comboGroupId = pfItem.comboGroupId;
      const itemId = `${pfItem.strategyId}|${pfItem.ticker}`;

      const subSpec: LedgerSpec = {
         portfolioId,
         comboId,
         comboGroupId,
         itemId
      };

      if (isTruthy(subSpec.comboGroupId)) {
         subSpec.bucketType = 'ComboGroup';
      } else if (isTruthy(subSpec.comboId)) {
         subSpec.bucketType = 'Combo';
      } else if (isTruthy(subSpec.portfolioId)) {
         subSpec.bucketType = 'Portfolio';
      } else {
         subSpec.bucketType = '<No Bucket>';
      }

      this.currentSpec = subSpec;

      await this.loadRecords(NUM_OF_ROWS);
   }
   
   
   etsOnDestroy() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }


   etsAfterViewInit() {  }
   
   
   @DetectMethodChanges()
   onLedgerNoteClicked(x: LedgerRecord): void {
      this.addNoteToRecord(x);
   }


   async onGridReady(args: GridReadyEvent) {
      this._grid = args;
      this._grid.api['ets-layoutTabId'] = this.layoutTabId;
   }

   
   onRemovedFromWorkspace(): void {

      const key = getPanelStateKey(this);
      
      if (key) {
         this._userSettingsService.deleteValue(key);
      }
   }

   @DetectMethodChanges({isAsync: true})
   async showAllRecords(): Promise<void> {
      
      this.currentSpec = { bucketType: '<No Bucket>', bucketName: 'Shell' };

      this.isLoading = true;

      try {
         
         await this.loadRecords(NUM_OF_ROWS);

      } finally {
         
         this.isLoading = false;

      }

   }
   
   @DetectMethodChanges({isAsync: true})
   private async onBucketHighlighted(msg: BucketHighlighted): Promise<void> {

      const bucketType = msg.bucketContext.bucketType;

      if (bucketType === 'Portfolio') {
         
         await this.onPortfolioHighlighted(msg);

      } else if (bucketType === 'Combo') {

         await this.onComboHighlighted(msg);

      } else if (bucketType === 'ComboGroup') {

         await this.onComboGroupHighlighted(msg);
         
      } else if (bucketType === 'Terminal') {

         await this.onTerminalHighlighted(msg);

      }
   }

   
   private async onTerminalHighlighted(msg: BucketHighlighted) {
      this.currentSpec = {
         bucketType: 'Terminal',
         bucketName: msg.bucketContext.bucketName,
         terminalId: msg.bucketContext.bucketId
      };
   
      await this.loadRecords(NUM_OF_ROWS);
   }

   private async onPortfolioHighlighted(msg: BucketHighlighted) {
      this.currentSpec = {
         bucketType: 'Portfolio',
         bucketName: msg.bucketContext.bucketName,
         portfolioId: msg.bucketContext.bucketId
      };

      await this.loadRecords(NUM_OF_ROWS);
   }

   
   private async onComboHighlighted(msg: BucketHighlighted) {

      this.currentSpec = {
         bucketType: 'Combo',
         bucketName: msg.bucketContext.bucketName,
         comboId: msg.bucketContext.bucketId
      };

      await this.loadRecords(NUM_OF_ROWS);
   }

   
   private async onComboGroupHighlighted(msg: BucketHighlighted) {

      this.currentSpec = {
         bucketType: 'ComboGroup',
         bucketName: msg.bucketContext.bucketName,
         comboGroupId: msg.bucketContext.bucketId
      };

      await this.loadRecords(NUM_OF_ROWS);
   }

   @DetectMethodChanges({isAsync: true})
   async saveLedgerRecordNote(ledgerRecord: LedgerRecord, note: string): Promise<void> {
      this.isLoading = true;

      try {
         
         await this._ledgerService.saveRecordNote(ledgerRecord.recordId, note);
         ledgerRecord.note = note;
      } catch {

         this._toastr.error('"Save Ledger Record Note" operation completed with errors');

      } finally {
         this.isLoading = false;
      }
   }

   
   @DetectMethodChanges()
   addNoteToRecord(data: LedgerRecord): void {
      this.ledgerNoteConfig.ledgerRecord = data;
      this.ledgerNoteConfig.isVisible = true;
   }

   
   async loadRecords(numOfRecords: number, loadMore?: boolean) {
      
      if (!this.currentSpec) {
         this._toastr.info('Cannot load rows. Ledger has no spec', 'Ledger Panel');
         return;
      }
      
      this._grid.api.showLoadingOverlay();

      try {
         
         const data: GetLedgerRecordsReply = await this._ledgerService.getLedgerRecords(numOfRecords, this.currentSpec);

         const ids = [];
         data.ledgerRecords.forEach(x => {
            ids.push(x.recordId);
            x.layoutId = this.layoutTabId;
         });

         const startingRecord = Math.min(...ids);
         this.currentSpec.startingRecordId = startingRecord === 0 ? null : startingRecord;

         if (!data.allRecordsLoaded) {
            data.ledgerRecords[0].hasMoreRecords = true;
         }

         if (!loadMore) {

            this._grid.api.setRowData(data.ledgerRecords);

         } else {
            
            if (data.ledgerRecords.length > 0) {
               
               this._grid.api.forEachNode(node => {
                  if (!node.data) {
                     return;
                  }
                  node.data.hasMoreRecords = false;
               });
               this._grid.api.redrawRows();
               this._grid.api.applyTransactionAsync({add: data.ledgerRecords});

            } else {

               this._toastr.info('All records are loaded');
            }
         }
      } finally {
         
         this._grid.api.hideOverlay();
      }
   }

   
   async loadSubRecords(spec: LedgerSpec): Promise<LedgerRecord[]> {

      const data = await this._ledgerService.getLedgerRecords(NUM_OF_ROWS, spec);

      this.currentSpec = spec;

      if (data.ledgerRecords.length  === 0) {
         this._toastr.info('All records are loaded');
      }
      
      return data.ledgerRecords;
   }

   
   showBucketContextHint() {
      const config = { positionClass: 'toastr-bottom-wide-width' };

      if (this.currentSpec && this.currentSpec.bucketName) {

         const msg = `Bucket Type: [${this.currentSpec.bucketType}], Name: "${this.currentSpec.bucketName}"`;
         this._toastr.info(msg, 'Ledger', config);

      } else {

         this._toastr.info('Bucket is unknown', 'Ledger', config);

      }
   }


   
   private onLedgerRecordDto(x: LedgerRecordDto): void {
      
      if (!this.currentSpec) {
         return;
      }

      if (!isTruthy(this.currentSpec.bucketType)) {
         return;
      }

      if (this.currentSpec.bucketType === 'ComboGroup') {
         if (this.currentSpec.comboGroupId !== x.record.comboGroupId) {
            return;
         }
      }

      if (this.currentSpec.bucketType === 'Combo') {
         if (this.currentSpec.comboId !== x.record.comboId) {
            return;
         }
      }

      if (this.currentSpec.bucketType === 'Portfolio') {
         if (this.currentSpec.portfolioId !== x.record.portfolioId) {
            return;
         }
      }

      if (this.currentSpec.bucketType === 'Terminal') {
         if (this.currentSpec.terminalId !== x.record.terminalId) {
            return;
         }
      }

      this._grid.api.applyTransaction({add: [x.record]});
   }

   
   @DetectMethodChanges()
   onAddNoteDialogHidden() {
      this.ledgerNoteConfig.isVisible = false;
   }


   onAddNoteDialogCancelClicked() {
      this.onAddNoteDialogHidden();
   }


   onClearTradingDataMessage(msg: ClearTradingDataUIMessage): void {
      this._grid.api.setRowData([]);
      this.loadRecords(NUM_OF_ROWS);
   }


   @DetectMethodChanges()
   private onClearPortfolioItemsUIMessage(): void {
      this.currentSpec = {};
      this._grid.api.setRowData([]);
   }

   
   protected getState(): any { return null; }
   

   protected setState(state) { }
}
