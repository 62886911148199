<div class="root-container">
  
  <div
    [class.different]="this.viewModel.hasDifferencesWithTemplate()"
    [class.mismatch]="this.viewModel.hasConfigurationMismatch()"
    [ngClass]="{'right': this.orientation === 'right'}"
    class="section-caption full"
  >
    <div class="header">
            <span (click)="this.toggleCollapsed()">
                Settings
            </span>
    </div>
    <div (click)="this.viewModel.toggleCtxMenu()" class="menu">
      <div>
        <i class="fas fa-bars"></i>
      </div>
      <div #ctxMenuPlaceHolder></div>
    </div>
  </div>
  
  <div class="scroll">
    <dx-scroll-view>
      
      <div [ngClass]="this.viewModel.getContainerCssClass()" class="body">
        
        <div class="global-settings">
          <ets-adjustment-grid-global-settings
            [viewModel]="this.viewModel.globalSettings">
          </ets-adjustment-grid-global-settings>
        </div>
        
        <div *ngIf="this.viewModel.isTemplateSelected">
          <hr class="separator"/>
        </div>
     
        <div class="strategies">
          
          <!-- Hedged Portfolio -->
          <ng-container *ngIf="this.viewModel.showHedgePortfolioStrategy">
            <ets-cashflow-strategy-settings
              [disabled]="!this.viewModel.canProvideSettings || this.viewModel.isReadOnlyTemplate"
              [secondPoMismatchResolver]="this.viewModel.getSecondPoMismatchResolver(this.viewModel.strategyHedgePortfolio)"
              [secondSpreadMismatchResolver]="this.viewModel.getSecondSpreadMismatchResolver(this.viewModel.strategyHedgePortfolio)"
              [settings]="this.viewModel.strategyHedgePortfolio"
              [shouldRollDates]="this.viewModel.shouldRollDates"
              strategy="Hedged Portfolio"
            >
            </ets-cashflow-strategy-settings>
          </ng-container>
          
          <!-- Reversed Hedged Portfolio -->
          <ng-container *ngIf="this.viewModel.showReversedHedgePortfolioStrategy">
            <ets-cashflow-strategy-settings
              [disabled]="!this.viewModel.canProvideSettings || this.viewModel.isReadOnlyTemplate"
              [secondPoMismatchResolver]="this.viewModel.getSecondPoMismatchResolver(this.viewModel.strategyReversedHedgePortfolio)"
              [secondSpreadMismatchResolver]="this.viewModel.getSecondSpreadMismatchResolver(this.viewModel.strategyReversedHedgePortfolio)"
              [settings]="this.viewModel.strategyReversedHedgePortfolio"
              [shouldRollDates]="this.viewModel.shouldRollDates"
              strategy="Reversed Hedged Portfolio"
            >
            </ets-cashflow-strategy-settings>
          </ng-container>
          
          <!-- Sell Calls -->
          <ng-container *ngIf="this.viewModel.showCallsStrategy">
            <ets-cashflow-strategy-settings
              [disabled]="!this.viewModel.canProvideSettings || this.viewModel.isReadOnlyTemplate"
              [isSubStrategy]="this.viewModel.isTwoSideStrategy"
              [secondPoMismatchResolver]="this.viewModel.getSecondPoMismatchResolver(this.viewModel.strategyCalls)"
              [secondSpreadMismatchResolver]="this.viewModel.getSecondSpreadMismatchResolver(this.viewModel.strategyCalls)"
              [settings]="this.viewModel.strategyCalls"
              [shouldRollDates]="this.viewModel.shouldRollDates"
              strategy="Calls"
            >
            </ets-cashflow-strategy-settings>
          </ng-container>
          
          <!-- Strategy Puts -->
          <ng-container *ngIf="this.viewModel.showPutsStrategy">
            <ets-cashflow-strategy-settings
              [disabled]="!this.viewModel.canProvideSettings || this.viewModel.isReadOnlyTemplate"
              [isSubStrategy]="this.viewModel.isTwoSideStrategy"
              [secondPoMismatchResolver]="this.viewModel.getSecondPoMismatchResolver(this.viewModel.strategyPuts)"
              [secondSpreadMismatchResolver]="this.viewModel.getSecondSpreadMismatchResolver(this.viewModel.strategyPuts)"
              [settings]="this.viewModel.strategyPuts"
              [shouldRollDates]="this.viewModel.shouldRollDates"
              strategy="Puts"
            >
            </ets-cashflow-strategy-settings>
          </ng-container>
        
        </div>
        
        <ng-container *ngIf="this.viewModel.isTemplateSelected">
          <div class="expirations-settings">
            <ets-adjustment-grid-expirations-settings
              [disabled]="!this.viewModel.canProvideSettings || this.viewModel.isReadOnlyTemplate"
              [settings]="this.viewModel.expirationsSettings">
            </ets-adjustment-grid-expirations-settings>
          </div>
        </ng-container>
        
        <div *ngIf="this.viewModel.isTemplateSelected && !this.viewModel.isReadOnlyTemplate">
          
          <fieldset class="template-buttons">
            
            <legend>Current Template</legend>
            
            <div class="buttons-wrapper">
              <div *ngIf="this.viewModel.showSaveTemplateButton" class="button">
                <dx-button
                  (onClick)="this.viewModel.saveTemplate()"
                  text="Save"
                  type="default"
                  width="100%"
                >
                </dx-button>
              </div>
              
              <div class="button">
                <dx-button
                  (onClick)="this.viewModel.saveSettingsAsTemplate()"
                  text="Save New"
                  type="success"
                  width="100%"
                >
                </dx-button>
              </div>
              
              <div class="button" *ngIf="this.viewModel.showEditTemplateButton">
                <dx-button
                  (onClick)="this.viewModel.editTemplate()"
                  text="Edit"
                  type="normal"
                  width="100%"
                >
                </dx-button>
              </div>
              
              <div *ngIf="this.viewModel.showDeleteTemplateButton" class="button">
                <dx-button
                  (onClick)="this.viewModel.deleteSettingsTemplate()"
                  text="Delete"
                  type="danger"
                  width="100%"
                >
                </dx-button>
              </div>
            </div>
          
          
          </fieldset>
        
        </div>
        
        <div *ngIf="this.viewModel.showAssignTemplatesButton" class="assign-templates-btn">
          
          <dx-button
            (onClick)="this.viewModel.assignTemplates()"
            text="Assign Templates"
            width="100%"
          >
          </dx-button>
        </div>
      
      </div>
    
    </dx-scroll-view>
  
  </div>

</div>

<!-- Edit Template -->
<dx-popup (onHidden)="this.viewModel.editTemplatePopup.close()"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="150"
          [resizeEnabled]="false"
          [showTitle]="true"
          [visible]="this.viewModel.editTemplatePopup.isVisible"
          [width]="300"
          [title]="this.viewModel.editTemplatePopup.title">
  
  <div *dxTemplate="let data of 'content'" class="dialog">
    <div class="input name">
      
      <dx-text-box
        [(value)]="this.viewModel.editTemplatePopup.templateName"
        placeholder="Enter template name..."
      >
      </dx-text-box>
    
    </div>
    
    <div *ngIf="this.viewModel.canSaveTemplateAssignable" class="input assignable">
      <dx-check-box
        [(value)]="this.viewModel.editTemplatePopup.isShared"
        text="Assignable Template"
      >
      </dx-check-box>
    </div>
    
    <div class="buttons">
      <div class="button">
        <dx-button
          (onClick)="this.viewModel.editTemplatePopup.save()"
          text="Save"
          type="success"
          width="100%"
        >
        </dx-button>
      </div>
      <div class="button">
        <dx-button
          (onClick)="this.viewModel.editTemplatePopup.close()"
          text="Cancel"
          type="danger"
          width="100%"
        >
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<!-- Delete Template -->
<dx-popup (onHidden)="this.viewModel.deleteTemplatePopup.close()"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="150"
          [resizeEnabled]="false"
          [showTitle]="true"
          [visible]="this.viewModel.deleteTemplatePopup.visible"
          [width]="350"
          title="Delete Template?"
>
  
  <div *dxTemplate="let data of 'content'" class="dialog">
    
    <div class="message" style="font-size: 1.2em; text-align: center">
      <div>Are you sure you want to delete template?</div>
      <div style="font-weight: bold; font-size: 1.3em; margin-top: 10px;">
        "{{this.viewModel.deleteTemplatePopup.template.templateName}}"
      </div>
    </div>
    
    <div class="buttons">
      <div class="button">
        <dx-button
          (onClick)="this.viewModel.deleteTemplatePopup.onYesClicked()"
          text="Yes"
          type="danger"
          width="100%"
        >
        </dx-button>
      </div>
      <div class="button">
        <dx-button
          (onClick)="this.viewModel.deleteTemplatePopup.onNoClicked()"
          text="No"
          type="default"
          width="100%"
        >
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<!-- Assigned Template -->
<dx-popup (onHidden)="this.viewModel.assignTemplatesPopup.close()"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="380"
          [resizeEnabled]="true"
          [showTitle]="true"
          [visible]="this.viewModel.assignTemplatesPopup.isVisible"
          [width]="480"
          title="Assign Templates">
  
  <div #assignTemplatesContainer *dxTemplate="let data of 'content'" class="assign-templates">
    
    <div class="body">
      <div class="col templates">
        <dx-list
            #templatesList
            (onSelectionChanged)="this.viewModel.assignTemplatesPopup.onSelectionChanged()"
            [(selectedItems)]="this.viewModel.assignTemplatesPopup.selectedTemplates"
            [dataSource]="this.viewModel.assignTemplatesPopup.templates"
            [displayExpr]="'templateName'"
            [selectionMode]="'single'"
            [showSelectionControls]="false"
        >
        </dx-list>
      </div>
      
      <div class="col clients">
        <dx-list
          [dataSource]="this.viewModel.assignTemplatesPopup.selectedTemplates[0]?.clients"
        >
          <div *dxTemplate="let item of 'item'">
            <dx-check-box [(value)]="item.isSelected" [text]="item.clientName">
            </dx-check-box>
          </div>
        </dx-list>
      
      </div>
    </div>
    
    <div class="footer">
      <div class="btn">
        <dx-button
          (onClick)="this.viewModel.assignTemplatesPopup.saveChanges()"
          text="Save Changes">
        </dx-button>
      </div>
      <div class="btn">
        <dx-button (onClick)="this.viewModel.assignTemplatesPopup.cancelChanges()" text="Cancel"></dx-button>
      </div>
    </div>
    
    <dx-load-panel
      [position]="{my: 'center', at: 'center', of: assignTemplatesContainer}"
      [visible]="this.viewModel.assignTemplatesPopup.isLoading">
    </dx-load-panel>
  
  </div>
</dx-popup>

<ets-apg-context-menu
    #ctxMenu
    (menuItemClicked)="this.viewModel.onCtxMenuItemClick($event)"
    [menuItems]="this.viewModel.ctxMenuItems"
    [target]="ctxMenuPlaceHolder"
>
</ets-apg-context-menu>
