import { ILayoutSection } from '../layout-section';
import { makeLayoutTabId, makeWorkspaceId } from 'projects/shared-components/utils';

export class LayoutTab {
   constructor(header?: string, isInitial?: boolean) {

      this.id = makeLayoutTabId();
      
      this.header = header || 'Default';
      
      this.proposedHeader = this.header;
      
      if (isInitial) {
         this.layoutRoot = this.makeInitialLayout();
      } else {
         this.layoutRoot = this.makeDefaultLayout();
      }
   }

   id: string;
   header?: string;
   isActive?: boolean;
   isEditMode?: boolean;
   proposedHeader?: string;
   layoutRoot: ILayoutSection;
   isInitialized: boolean;

   private makeInitialLayout(): ILayoutSection {
      const root: ILayoutSection = {
         layoutTabId: this.id,
         direction: 'vertical',
         subsections: [],
         order: 0
      };
      const topSection: ILayoutSection = {
         layoutTabId: this.id,
         workspaceId: makeWorkspaceId(this.id),
         parent: root,
         order: 0,
         isInitial: true
      };
      root.subsections.push(topSection);

      return root;
   }

   
   private makeDefaultLayout(): ILayoutSection {
      const root: ILayoutSection = {
         layoutTabId: this.id,
         direction: 'vertical',
         subsections: [],
         order: 0
      };
      const topSection: ILayoutSection = {
         layoutTabId: this.id,
         workspaceId: makeWorkspaceId(this.id),
         parent: root,
         order: 0
      };
      const bottomSection: ILayoutSection = {
         layoutTabId: this.id,
         direction: 'horizontal',
         workspaceId: makeWorkspaceId(this.id),
         parent: root,
         order: 1,
         subsections: []
      };
      root.subsections.push(topSection);
      root.subsections.push(bottomSection);

      const bottomSectionA: ILayoutSection = {
         layoutTabId: this.id,
         workspaceId: makeWorkspaceId(this.id),
         parent: bottomSection,
         order: 0
      };
      const bottomSectionB: ILayoutSection = {
         layoutTabId: this.id,
         workspaceId: makeWorkspaceId(this.id),
         parent: bottomSection,
         order: 1
      };

      bottomSection.subsections.push(bottomSectionA);
      bottomSection.subsections.push(bottomSectionB);
      return root;
   }
}
