// tslint:disable: no-non-null-assertion

import { Component, ViewChild, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { Subject } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import {
   TradingInstrumentDisplayNameService,
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaceOrder } from 'projects/shared-components/shell-communication/operations/manual-trading/replace-order.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { DetectMethodChanges, DetectSetterChanges, toNearestTickSize } from 'projects/shared-components/utils';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { takeUntil } from 'rxjs/operators';
import { ManualTradingBackendService } from '../../manual-trading-backend.service';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';

@Component({
   selector: 'ets-order-replace-dialog',
   templateUrl: './replace-order-dialog.component.html',
   styleUrls: ['./replace-order-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplaceOrderDialogComponent {

   constructor(
      private readonly _tradingInstrumentsService: TradingInstrumentsService,
      private readonly _backendClient: ManualTradingBackendService,
      private readonly _lastQuoteCacheService: LastQuoteCacheService,
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _messageBus: MessageBusService,
      private readonly _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef,
   ) {
   }

   private _order: OrderDto;
   private _unsubscriber: Subject<any>;


   @ViewChild(DxPopupComponent, { static: true }) popupDialog: DxPopupComponent;
   
   symbol: string;
   bid: number;
   ask: number;
   tickSize: number;
   numberFormat: string;

   private _orderOperationInProgress = false;
   get orderOperationInProgress(): boolean { return this._orderOperationInProgress; }
   
   @DetectSetterChanges()
   set orderOperationInProgress(v: boolean) {
      this._orderOperationInProgress = v;
   }
   
   private _orderPrice: number;
   get orderPrice(): number { return this._orderPrice; }
   
   @DetectSetterChanges()
   set orderPrice(val: number) { this._orderPrice = val; }
   
   private _orderQty: number;
   get orderQty(): number { return this._orderQty; }
   
   @DetectSetterChanges()
   set orderQty(val: number) { this._orderQty = val; }


   get canReplaceOrder(): boolean {
      
      if (!this._order) {
         return false;
      }

      if (this.orderOperationInProgress) {
         return false;
      }

      const oldQty = this._order!.qty;
      const oldPrice = this._order!.limitPrice;

      if (oldQty === this.orderQty && oldPrice === this.orderPrice) {
         return false;
      }

      if (this.orderQty <= 0) {
         return false;
      }

      if (this.orderPrice <= 0) {
         if (!this._order.ticker.startsWith('@')) {
            if (!this._order.multiLegDescriptor) {
               return false;
            }
         }
      }

      return true;
   }


   @DetectMethodChanges({isAsync: true})
   async show(order: OrderDto): Promise<void> {
      this.setOrder(order);
      await this.subscribeForQuotes();
      this.popupDialog.visible = true;
   }

   
   @DetectMethodChanges({isAsync: true})
   async onReplaceClicked(): Promise<any> {
      
      const cmd = new ReplaceOrder(
         EtsConstants.strategies.manualStrategyId,
         this._order.orderId,
         toNearestTickSize(this.orderPrice, this.tickSize),
         this.orderQty
      );

      try {
         
         this.orderOperationInProgress = true;

         await this._backendClient.replaceOrder(cmd);

      } catch (error) {

         this._toastr.error('Error occurred during "Replace Order" operation');

      } finally {

         this.orderOperationInProgress = false;
         this.popupDialog.visible = false;
      }
   }

   @DetectMethodChanges()
   onPopupClosed(): void {
      this.popupDialog.visible = false;

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this.bid = this.ask = null;
   }

   
   private async subscribeForQuotes(): Promise<void> {
      try {
         this._lastQuoteCacheService.subscribeTicker(this._order.ticker);
      } catch (e) {
         this._toastr.error('Error occurred during market data subscribe request');
         return;
      }

      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this._onQuote(msg.payload));
   }

   
   @DetectMethodChanges()
   private setOrder(value: OrderDto): Promise<void> {
      if (!value) {
         return;
      }

      this._order = value;

      this.orderPrice = !!value.limitPrice ? value.limitPrice : value.stopPrice;
      this.orderQty = value.leavesQty;

      const ticker = this._order.ticker;
      const ti = this._tradingInstrumentsService.getInstrumentByTicker(ticker);
      this.symbol = ti ? ti.displayName : this._order.tickerDisplayName;

      let instrument: TradingInstrument;
      
      if (ticker.startsWith('@')) {
         const parts = ticker.split(' ');
         if (parts.length < 2) {
            this._toastr.error('Cannot determine order\'s symbol');
            return;
         }
         const underlying = parts[1];

         instrument = this._tradingInstrumentsService.getInstrumentByTicker(underlying);

      } else {
         instrument = this._tradingInstrumentsService.getInstrumentByTicker(ticker);
      }
      
      if (!instrument) {
         this._toastr.error('Cannot determine order\'s symbol');
         return;
      }

      this.tickSize = instrument.tickSize;
      this.numberFormat = `#,##0.${'#'.repeat(instrument.precision)}`;
   }

   @DetectMethodChanges()
   private _onQuote(quotes: QuoteDto[]): Promise<void> {
      const order = this._order;

      if (!order) {
         return;
      }

      const ourQoute = quotes.find(x => x.ticker === order.ticker);

      if (ourQoute) {
         this.bid = ourQoute.bid;
         this.ask = ourQoute.ask;
      }
   }
}
