<dx-popup   width="50%" 
            height="65%" 
            [showTitle]="true" 
            title="Matching Engine Settings" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="true" 
            [dragEnabled]="true" 
            [visible]="isVisible" 
            (onHidden)="onHidden()" 
            (onShown)="onShown()">

   <div *dxTemplate="let data of 'content'" class="container" #container>

      <div class="execution-settings">
         <fieldset>

            <legend>General</legend>
            
            <div class="row">
               <div class="cell">
                  <dx-check-box text="Use Trade-Through Limit Orders" [(value)]="this.useTTLimits"></dx-check-box>
               </div>
               
               <div class="cell">
                  <dx-check-box  text="Accept Data Outside of Market Hours"
                                 [(value)]="this.acceptDataOutsideOfMarketHours">
                  </dx-check-box>
               </div>
   
               <div class="cell">
                  <dx-button [text]="this.matchingRulesDialog.buttonText" (onClick)="this.toggleMatchingRulesDialog()"></dx-button>
               </div>
   
            </div>

         </fieldset>
      </div>

      <div class="data-providers">
         <fieldset>
                        
            <legend>Data Providers</legend>
            
            <table>
               
               <thead>
                  <th>Enabled?</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Configuration</th>
               </thead>
               
               <tbody>

                  <tr *ngFor="let provider of dataProviders">

                     <td>
                        <dx-check-box [(value)]="provider.isEnabled"></dx-check-box>
                     </td>
                     
                     <td>{{provider.id}}</td>
                     
                     <td>{{provider.description}}</td>
                     
                     <td>{{provider.status}}</td>
                     
                     <td>

                        <dx-button  text="Edit" 
                                    width="100" 
                                    (onClick)="this.editQuoteProviderConfig(provider)">
                        </dx-button>
                     </td>
                  </tr>
               </tbody>
            </table>
         </fieldset>
      </div>

      <div class="buttons">

         <div class="button">
            <dx-button  text="Save Changes"
                        [width]="120"
                        (onClick)="saveChangesAndClose()"
                        [visible]="securityContext.saveSimexSettings">
            </dx-button>
         </div>

         <div class="button">
            <dx-button  text="Save & Restart"
                        [width]="120"
                        type="default"
                        (onClick)="saveChangesAndRestart()"
                        [visible]="securityContext.saveSimexSettings && securityContext.restartSimex">
            </dx-button>
         </div>

         <div class="button">
            <dx-button  text="Restart"
                        [width]="120"
                        (onClick)="restartSimex()"
                        [visible]="securityContext.restartSimex">
            </dx-button>
         </div>

         <div class="button">
            <dx-button text="Discard Changes" [width]="120" (onClick)="close()"></dx-button>
         </div>
      </div>

      <dx-load-panel [visible]="isLoading" [position]="{my: 'center', at: 'center', of: container}"></dx-load-panel>
   </div>
</dx-popup>

<!-- Quote Configuration -->
<dx-popup   width="22%" 
            height="50%" 
            [showTitle]="true" 
            title="Quote Provider Configuration" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="true" 
            [dragEnabled]="true" 
            [visible]="quoteProviderConfigEditor?.isQuoteProviderEditorVisible"
            (onHidden)="onQuoteProviderConfigHidden()">
   
   <div *dxTemplate="let data of 'content'" style="position: relative">

      <div class="quote-provider-editor">
         
         <div class="text">
            
            <dx-text-area [value]="quoteProviderConfigEditor?.newConfiguration"
               (valueChange)=" (quoteProviderConfigEditor) ? quoteProviderConfigEditor.newConfiguration = $event : null "
               width="100%" height="100%"></dx-text-area>
         </div>
         
         <div class="controls">
            <dx-button text="Save" width="100px" (onClick)="saveQuoteProviderConfig()"></dx-button>
            <dx-button text="Discard" width="100px" (onClick)="discardQuoteProviderConfig()"></dx-button>
         </div>
      </div>
   </div>
</dx-popup>

<!-- Matching Rules Dialog -->
<dx-popup   [width]="this.matchingRulesDialog.width" 
            [height]="this.matchingRulesDialog.height" 
            [title]="this.matchingRulesDialog.title" 
            [closeOnOutsideClick]="this.matchingRulesDialog.closeOnOutsideClick"
            [(visible)]="this.matchingRulesDialog.visible"
            (onHidden)="this.runChangeDetector()">

   <div *dxTemplate="let data of 'content'" class="rules-editor">
   
         <fieldset class="rules-list">
            <legend>Matching Rules</legend>
   
            <dx-scroll-view>

               <div class="table">

                  <div class="row headers">
                     <div class="column symbol">Symbol</div>
                     <div class="column kind">Kind</div>
                     <div class="column price">Price</div>
                     <div class="column tt">Trade-Through</div>
                     <div class="column action">Action</div>
                  </div>

                  <div class="row rule" *ngFor="let item of this.matchingRulesDialog.rules">

                     <div class="column symbol">
                        <ets-symbol-picker   [selectedInstrument]="item.uniqueUnderlying"
                                             (instrumentSelected)="this.onUniqueUnderlyingSelectedForMatchingRule($event, item)"
                                             [underlyings]="true"
                        ></ets-symbol-picker>
                     </div>

                     <div class="column kind">
                        {{item.uniqueUnderlying?.kind}}
                     </div>

                     <div class="column price">
                        <dx-select-box [items]="['MID', 'LAST', 'BOOK']" [(value)]="item.price">
                        </dx-select-box>
                     </div>

                     <div class="column tt">
                        <dx-check-box [(value)]="item.tradeThrough"></dx-check-box>
                     </div>

                     <div class="column action">
                        <dx-button text="Remove" width="75" (onClick)="this.removeMatchingRule(item)"></dx-button>
                     </div>

                  </div>

                  <div class="row footer">
                     <div class="column symbol">&nbsp;</div>
                     <div class="column kind">&nbsp;</div>
                     <div class="column price">&nbsp;</div>
                     <div class="column tt">&nbsp;</div>
                     <div class="column action">
                        <dx-button text="Add Rule" width="75" type="default" (onClick)="this.addMatchingRule()"></dx-button>
                     </div>
                  </div>
               </div>
            </dx-scroll-view>
         </fieldset>

      <div class="buttons">
         <div class="button save">
            <dx-button text="Save" (onClick)="this.saveMatchingRulesSettings()"></dx-button>
         </div>
         <div class="button close">
            <dx-button text="Close" (onClick)="this.toggleMatchingRulesDialog()"></dx-button>
         </div>
      </div>

   </div>
</dx-popup>