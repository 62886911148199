import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {PriceBarDto} from '../../dtos/price-bar-dto.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class SubscribePriceBars implements ProtocolCommand {
  constructor(
    public readonly ticker: string,
    public readonly timeframe: string,
    public readonly subscriptionKey: string,
    public readonly baseBar: PriceBarDto
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.SubscribePriceBars`;
  }
}
