<div class="bucket-selector">
   
   <div class="bucket mode">
      
      <div class="label">
         <span>{{this.modeSelectorLabel}}</span>
      </div>
      
      <div class="input">
         <dx-select-box 
            [items]="this.modes"
            [(value)]="this.selectedMode"
            (onValueChanged)="this.onModeChanged($event)"
            >
         </dx-select-box>
      </div>

   </div>
   
   <div class="bucket strategies">

      <div class="label">
         <span>{{this.strategiesSelectorLabel}}</span>
      </div>
      
      <div class="input">
         <dx-select-box 
            [items]="this.strategies"
            [(value)]="this.selectedStrategy"
            (onValueChanged)="this.onStrategyChanged($event)"
            [disabled]="!this.canSelectStrategy"
            [showClearButton]="true"
            >
         </dx-select-box>
      </div>
   </div>

   <div class="bucket terminal">
      
      <div class="label">
         <span>{{this.terminalSelectorLabel}}</span>
      </div>
      
      <div class="input">
         <dx-select-box
            [items]="this.terminals"
            [(value)]="this.selectedTerminal"
            displayExpr="displayName"
            (onValueChanged)="this.onTerminalChanged($event)"
            [disabled]="!this.canSelectTerminal"
            [showClearButton]="true"
            >
         </dx-select-box>
      </div>

   </div>

   <div class="bucket portfolio">

      <div class="label">
         <span>{{this.portfolioSelectorLabel}}</span>
      </div>
      
      <div class="input">
         <dx-select-box
            [items]="this.portfolios"
            [(value)]="this.selectedPortfolio"
            (onValueChanged)="this.onPortfolioChanged($event)"
            displayExpr="portfolioName"
            [disabled]="!this.canSelectPortfolio"
            [showClearButton]="true"
            >
         </dx-select-box>
      </div>

   </div>

   <div class="bucket combo">

      <div class="label">
         <span>{{this.comboSelectorLabel}}</span>
      </div>
      
      <div class="input">
         <dx-select-box
            [items]="this.combos"
            [(value)]="this.selectedCombo"
            (onValueChanged)="this.onComboChanged($event)"
            displayExpr="comboName"
            [disabled]="!this.canSelectCombo"
            [showClearButton]="true"
            >
         </dx-select-box>
      </div>

   </div>
</div>