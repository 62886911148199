<div [ngClass]="this.sortOrderingCssClass" class="root-container parameters">

  <!-- Short Option -->
  <fieldset class="block short-option">

    <legend class="colorcode-short-option">{{this.shortOptionSectionHeader}}</legend>

    <div class="na">
      N/A
    </div>

  </fieldset>

  <!-- Spread -->
  <fieldset class="block spread">

    <legend class="colorcode-spread">{{this.spreadSectionHeader}}</legend>

    <!-- Short Option Offset -->
    <div class="parameter">
      <p class="part label">Short {{this.anchorShortOptionType}} Offset</p>
      <div
        [class.different]="this.settings.isDifferentFromTemplate('spreadOffset')"
        class="part input"
        [title]="this.settings.getHint('spreadOffset')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'spreadOffset')"
          [(value)]="this.settings.spreadOffset"
          [disabled]="this.disabled"
          [min]="0"
          [showClearButton]="true"
          [showSpinButtons]="true">
        </dx-number-box>
      </div>
    </div>

    <!-- Spread: Width -->
    <div class="parameter">
      <p class="part label">Width</p>
      <div
        [class.different]="this.settings.isDifferentFromTemplate('spreadWidth')"
        class="part input"
        [title]="this.settings.getHint('spreadWidth')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'spreadWidth')"
          [(value)]="this.settings.spreadWidth"
          [disabled]="this.disabled"
          [min]="1"
          [showClearButton]="true"
          [showSpinButtons]="true">
        </dx-number-box>
      </div>
    </div>

    <!-- Spread: Roll X Days Before -->
    <div class="parameter">
      <p class="part label">Roll [x] Days Before Exp.</p>
      <div
        [class.different]="this.settings.isDifferentFromTemplate('spreadRollXDaysBeforeExpiration')"
        class="part input"
        [title]="this.settings.getHint('spreadRollXDaysBeforeExpiration')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'spreadRollXDaysBeforeExpiration')"
          [(value)]="this.settings.spreadRollXDaysBeforeExpiration"
          [disabled]="this.isRollXDaysBeforeDisabled || this.settings.isSpreadRollToDateOverriden"
          [min]="0"
          [showClearButton]="true"
          [showSpinButtons]="true">
        </dx-number-box>
      </div>
    </div>

    <!-- Spread: Roll To Days To Exp. -->
    <div class="parameter">
      <p class="part label">Roll Days To Exp.</p>
      <div
        [class.different]="this.settings.isDifferentFromTemplate('spreadRollToDaysToExp')"
        class="part input"
        [title]="this.settings.getHint('spreadRollToDaysToExp')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'spreadRollToDaysToExp')"
          [(value)]="this.settings.spreadRollToDaysToExp"
          [disabled]="this.isSpreadRollDaysToExpirationDisabled"
          [min]="0"
          [showClearButton]="true"
          [showSpinButtons]="true">
        </dx-number-box>
      </div>
    </div>

    <!-- Spread: Override Roll To Days To Exp. -->
    <div *ngIf="!this.settings.isDefaultsEditor" class="parameter">
      <p class="part label">Override Roll Days To Exp.</p>
      <div
        [class.different]="this.settings.isDifferentFromTemplate('spreadOverrideRollToDaysToExp')"
        class="part input"
        [title]="this.settings.getHint('spreadOverrideRollToDaysToExp')"
      >
        <dx-select-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'spreadOverrideRollToDaysToExp')"
          [(value)]="this.settings.spreadOverrideRollToDaysToExp"
          [dataSource]="this.settings.expirations"
          [disabled]="this.disabled || !this.shouldRollDates"
          [displayExpr]="'dateWithDaysToExpiration'"
          [showClearButton]="true"
          [valueExpr]="'optionExpirationDate'">
        </dx-select-box>
      </div>
    </div>

    <!-- Spread: Evergreen Override Roll To Days To Exp. -->
    <div class="parameter">
      <p class="part label">Evergreen Overr. Roll to DTE</p>
      <div
        [class.different]="this.settings.isDifferentFromTemplate('spreadEvergreenOverrideRollToDaysToExp')"
        class="part input"
        [title]="this.settings.getHint('spreadEvergreenOverrideRollToDaysToExp')"
      >
        <dx-select-box
          (onValueChanged)="this.settings.onParameterChanged(
            $event,
            'spreadEvergreenOverrideRollToDaysToExp'
            )"
          [(value)]="this.settings.spreadEvergreenOverrideRollToDaysToExp"
          [dataSource]="this.settings.evergreenDates"
          [disabled]="this.disabled || !this.shouldRollDates"
          [showClearButton]="true"
        >
        </dx-select-box>
      </div>
    </div>

  </fieldset>

  <!-- 2nd Spread -->
  <fieldset
    *ngIf="this.isSecondSpreadAvailable"
    class="block spread second"
    [ngClass]="{'configuration-mismatch': this.secondSpreadMismatchResolver()}"
    [class.different2]="this.settings.isDifferentFromTemplate('secondSpreadEnabled')"
  >
    <legend [ngClass]="{'configuration-mismatch': this.secondSpreadMismatchResolver()}"
            class="colorcode-second-spread"
    >
      <span class="checkbox">
        <dx-check-box
          (valueChange)="this.changeSecondSpreadState($event)"
          [disabled]="this.disabled"
          [text]="null"
          [value]="this.isSecondSpreadEnabled"
        >
        </dx-check-box>
         </span>
      <span class="label">
        {{this.secondSpreadSectionHeader}}
      </span>
    </legend>

    <ng-container *ngIf="!this.isSecondSpreadEnabled; else elseTemplate">
      <div class="na">
        N/A
      </div>
    </ng-container>

    <ng-template #elseTemplate>
      <div>
        <!-- 1st Spread Short Leg Offset -->
        <div class="parameter">
          <p class="part label">Offset from Spread #1</p>
          <div
            class="part input"
            [class.different]="this.settings.isDifferentFromTemplate('secondSpreadOffset')"
            [title]="this.settings.getHint('secondSpreadOffset')"
          >
            <dx-number-box
              (onValueChanged)="this.settings.onParameterChanged($event, 'secondSpreadOffset')"
              [(value)]="this.settings.secondSpreadOffset"
              [disabled]="this.disabled"
              [min]="0"
              [showClearButton]="true"
              [showSpinButtons]="true"
            >
            </dx-number-box>
          </div>
        </div>

        <!-- 2nd Spread: Width -->
        <div class="parameter">
          <p class="part label">Width</p>
          <div
            class="part input"
            [class.different]="this.settings.isDifferentFromTemplate('secondSpreadWidth')"
            [title]="this.settings.getHint('secondSpreadWidth')"
          >
            <dx-number-box
              (onValueChanged)="this.settings.onParameterChanged($event, 'secondSpreadWidth')"
              [(value)]="this.settings.secondSpreadWidth"
              [disabled]="this.disabled"
              [min]="1"
              [showClearButton]="true"
              [showSpinButtons]="true"
            >
            </dx-number-box>
          </div>
        </div>

        <!-- 2nd Spread: Roll X Days Before -->
        <div class="parameter">
          <p class="part label">Roll [x] Days Before Exp.</p>
          <div
            class="part input"
            [class.different]="this.settings.isDifferentFromTemplate('secondSpreadRollXDaysBeforeExpiration')"
            [title]="this.settings.getHint('secondSpreadRollXDaysBeforeExpiration')"
          >
            <dx-number-box
              [(value)]="this.settings.secondSpreadRollXDaysBeforeExpiration"
              [disabled]="this.isRollXDaysBeforeDisabled || this.settings.isSecondSpreadRollToDateOverriden"
              [min]="0"
              [showClearButton]="true"
              [showSpinButtons]="true"
              (onValueChanged)="this.settings.onParameterChanged(
                $event,
                'secondSpreadRollXDaysBeforeExpiration'
                )"
            >
            </dx-number-box>
          </div>
        </div>

        <!-- 2nd Spread: Roll To Days To Exp. -->
        <div class="parameter">
          <p class="part label">Roll Days To Exp.</p>
          <div
            class="part input"
            [class.different]="this.settings.isDifferentFromTemplate('secondSpreadRollToDaysToExp')"
            [title]="this.settings.getHint('secondSpreadRollToDaysToExp')"
          >
            <dx-number-box
              [(value)]="this.settings.secondSpreadRollToDaysToExp"
              [disabled]="this.isSecondSpreadRollDaysToExpirationDisabled"
              [min]="0"
              [showClearButton]="true"
              [showSpinButtons]="true"
              (onValueChanged)="this.settings.onParameterChanged($event, 'secondSpreadRollToDaysToExp')"
            >
            </dx-number-box>
          </div>
        </div>

        <!-- 2nd Spread: Override Roll To Days To Exp. -->
        <div *ngIf="!this.settings.isDefaultsEditor" class="parameter">
          <p class="part label">Override Roll Days To Exp.</p>
          <div
            class="part input"
            [class.different]="this.settings.isDifferentFromTemplate('secondSpreadOverrideRollToDaysToExp')"
            [title]="this.settings.getHint('secondSpreadOverrideRollToDaysToExp')"
          >
            <dx-select-box
              [(value)]="this.settings.secondSpreadOverrideRollToDaysToExp"
              [dataSource]="this.settings.expirations"
              [disabled]="this.disabled || !this.shouldRollDates"
              [displayExpr]="'dateWithDaysToExpiration'"
              [showClearButton]="true"
              [valueExpr]="'optionExpirationDate'"
              (onValueChanged)="this.settings.onParameterChanged(
                $event,
                'secondSpreadOverrideRollToDaysToExp'
                )"
            >
            </dx-select-box>
          </div>
        </div>

        <!-- 2nd Spread: Evergreen Override Roll To Days To Exp. -->
        <div class="parameter">
          <p class="part label">Evergreen Overr. Roll to DTE</p>
          <div
            class="part input"
            [class.different]="this.settings.isDifferentFromTemplate('secondSpreadEvergreenOverrideRollToDaysToExp')"
            [title]="this.settings.getHint('secondSpreadEvergreenOverrideRollToDaysToExp')"
          >
            <dx-select-box
              [(value)]="this.settings.secondSpreadEvergreenOverrideRollToDaysToExp"
              [dataSource]="this.settings.evergreenDates"
              [disabled]="this.disabled || !this.shouldRollDates"
              [showClearButton]="true"
              (onValueChanged)="this.settings.onParameterChanged(
                $event,
                'secondSpreadEvergreenOverrideRollToDaysToExp'
                )"
            >
            </dx-select-box>
          </div>
        </div>

      </div>
    </ng-template>

  </fieldset>

  <!-- Protective Option -->
  <fieldset class="block protective-option">

    <legend class="colorcode-protective-option">{{this.protectiveOptionSectionHeader}}</legend>

    <!-- Protective Option: Offset -->
    <div class="parameter">
      <p class="part label">Offset From Spread Short Leg</p>
      <div
        class="part input"
        [class.different]="this.settings.isDifferentFromTemplate('protectiveOptionOffset')"
        [title]="this.settings.getHint('protectiveOptionOffset')"
      >
        <dx-number-box
          [(value)]="this.settings.protectiveOptionOffset"
          [disabled]="this.disabled"
          [min]="0"
          [showClearButton]="true"
          [showSpinButtons]="true"
          (onValueChanged)="this.settings.onParameterChanged(
            $event,
            'protectiveOptionOffset'
            )"
        >
        </dx-number-box>
      </div>
    </div>

    <!-- Protective Option: Roll X Days Before -->
    <div class="parameter">
      <p class="part label">Roll [x] Days Before Exp.</p>
      <div
        class="part input"
        [class.different]="this.settings.isDifferentFromTemplate('protectiveOptionRollXDaysBeforeExpiration')"
        [title]="this.settings.getHint('protectiveOptionRollXDaysBeforeExpiration')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'protectiveOptionRollXDaysBeforeExpiration')"
          [(value)]="this.settings.protectiveOptionRollXDaysBeforeExpiration"
          [disabled]="this.isRollXDaysBeforeDisabled || this.settings.isProtectiveOptionRollDateOverriden"
          [min]="0"
          [showClearButton]="true"
          [showSpinButtons]="true"
        >
        </dx-number-box>
      </div>
    </div>

    <!-- Protective Option: Roll To Days To Exp -->
    <div class="parameter">
      <p class="part label">Roll Days To Exp.</p>
      <div
        class="part input"
        [class.different]="this.settings.isDifferentFromTemplate('protectiveOptionRollToDaysToExp')"
        [title]="this.settings.getHint('protectiveOptionRollToDaysToExp')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'protectiveOptionRollToDaysToExp')"
          [(value)]="this.settings.protectiveOptionRollToDaysToExp"
          [disabled]="this.isProtectiveOptionRollDaysToExpirationDisabled"
          [min]="0"
          [showClearButton]="true"
          [showSpinButtons]="true">
        </dx-number-box>
      </div>
    </div>

    <!-- Protective Option: Override Roll To Exp. Date  -->
    <div *ngIf="!this.settings.isDefaultsEditor" class="parameter">
      <p class="part label">Override Roll Days To Exp.</p>
      <div
        class="part input"
        [class.different]="this.settings.isDifferentFromTemplate('protectiveOptionOverrideRollToDaysToExp')"
        [title]="this.settings.getHint('protectiveOptionOverrideRollToDaysToExp')"
      >
        <dx-select-box
          (onValueChanged)="this.settings.onParameterChanged($event, 'protectiveOptionOverrideRollToDaysToExp')"
          [(value)]="this.settings.protectiveOptionOverrideRollToDaysToExp"
          [dataSource]="this.settings.expirations"
          [disabled]="this.disabled || !this.shouldRollDates"
          [displayExpr]="'dateWithDaysToExpiration'"
          [showClearButton]="true"
          [valueExpr]="'optionExpirationDate'"
        >
        </dx-select-box>
      </div>
    </div>

    <!-- Protective Option: Override Roll To Exp. Date  -->
    <div class="parameter">
      <p class="part label">Evergreen Overr. Roll to DTE</p>
      <div
        class="part input"
        [class.different]="this.settings.isDifferentFromTemplate('protectiveOptionEvergreenOverrideRollToDaysToExp')"
        [title]="this.settings.getHint('protectiveOptionEvergreenOverrideRollToDaysToExp')"
      >
        <dx-select-box
          (onValueChanged)="this.settings.onParameterChanged(
            $event,
            'protectiveOptionEvergreenOverrideRollToDaysToExp'
            )"
          [(value)]="this.settings.protectiveOptionEvergreenOverrideRollToDaysToExp"
          [dataSource]="this.settings.evergreenDates"
          [disabled]="this.disabled || !this.shouldRollDates"
          [showClearButton]="true"
        >
        </dx-select-box>
      </div>
    </div>
    
    <!-- Protective Option: Roll X Business Days To Expiration -->
    <div class="parameter">
      <p class="part label">Roll [x] Tradable Days Past Shorts Expiration</p>
      <div
        class="part input"
        [class.different]="this.settings.isDifferentFromTemplate('protectiveOptionRollToXBusinessDaysToExp')"
        [title]="this.settings.getHint('protectiveOptionRollToXBusinessDaysToExp')"
      >
        <dx-number-box
          (onValueChanged)="this.settings.onParameterChanged(
            $event,
            'protectiveOptionRollToXBusinessDaysToExp'
            )"
          [(value)]="this.settings.protectiveOptionRollToXBusinessDaysToExp"
          [disabled]="this.disabled || !this.shouldRollDates"
          [showClearButton]="true"
        >
        </dx-number-box>
      </div>
    </div>

  </fieldset>

  <!-- 2nd Protective Option -->
  <fieldset
    *ngIf="this.isSecondProtectiveOptionAvailable"
    class="block protective-option second"
    [ngClass]="{'configuration-mismatch': this.secondPoMismatchResolver()}"
    [class.different2]="this.settings.isDifferentFromTemplate('secondProtectiveOptionEnabled')"
  >
    <legend
      class="colorcode-protective-option"
      [ngClass]="{'configuration-mismatch': this.secondPoMismatchResolver()}"
    >
     <span class="checkbox">
        <dx-check-box
          (valueChange)="this.changeSecondProtectiveOptionState($event)"
          [disabled]="this.disabled"
          [text]="null"
          [value]="this.isSecondProtectiveOptionEnabled"
        >
        </dx-check-box>
     </span>
      <span class="label">
            {{this.secondProtectiveOptionSectionHeader}}
         </span>
    </legend>

    <ng-container *ngIf="!this.isSecondProtectiveOptionEnabled; else secondPo">
      <div class="na">
        N/A
      </div>
    </ng-container>

    <ng-template #secondPo>
      
      <!-- 2nd Protective Option: Offset -->
      <div class="parameter">
        <p class="part label">Offset From Protective Option</p>
        <div
          class="part input"
          [class.different]="this.settings.isDifferentFromTemplate('secondProtectiveOptionOffset')"
          [title]="this.settings.getHint('secondProtectiveOptionOffset')"
        >
          <dx-number-box
            (onValueChanged)="this.settings.onParameterChanged($event, 'secondProtectiveOptionOffset')"
            [(value)]="this.settings.secondProtectiveOptionOffset"
            [disabled]="this.disabled"
            [min]="0"
            [showClearButton]="true"
            [showSpinButtons]="true"
          >
          </dx-number-box>
        </div>
      </div>

      <!-- 2nd Protective Option: Roll X Days Before -->
      <div class="parameter">
        <p class="part label">Roll [x] Days Before Exp.</p>
        <div
          class="part input"
          [class.different]="this.settings.isDifferentFromTemplate('secondProtectiveOptionRollXDaysBeforeExpiration')"
          [title]="this.settings.getHint('secondProtectiveOptionRollXDaysBeforeExpiration')"
        >
          <dx-number-box
            (onValueChanged)="this.settings.onParameterChanged(
              $event,
              'secondProtectiveOptionRollXDaysBeforeExpiration'
              )"
            [(value)]="this.settings.secondProtectiveOptionRollXDaysBeforeExpiration"
            [disabled]="this.isRollXDaysBeforeDisabled || this.settings.isSecondProtectiveOptionRollDateOverriden"
            [min]="0"
            [showClearButton]="true"
            [showSpinButtons]="true"
          >
          </dx-number-box>
        </div>
      </div>

      <!-- 2nd Protective Option: Roll To Days To Exp -->
      <div class="parameter">
        <p class="part label">Roll Days To Exp.</p>
        <div
          class="part input"
          [class.different]="this.settings.isDifferentFromTemplate('secondProtectiveOptionRollToDaysToExp')"
          [title]="this.settings.getHint('secondProtectiveOptionRollToDaysToExp')"
        >
          <dx-number-box
            (onValueChanged)="this.settings.onParameterChanged(
              $event,
              'secondProtectiveOptionRollToDaysToExp'
              )"
            [(value)]="this.settings.secondProtectiveOptionRollToDaysToExp"
            [disabled]="this.isSecondProtectiveOptionRollDaysToExpirationDisabled"
            [min]="0"
            [showClearButton]="true"
            [showSpinButtons]="true"
          >
          </dx-number-box>
        </div>
      </div>

      <!-- 2nd Protective Option: Override Roll To Exp. Date  -->
      <div *ngIf="!this.settings.isDefaultsEditor"
           class="parameter"
      >
        <p class="part label">Override Roll Days To Exp.</p>
        <div
          class="part input"
          [class.different]="this.settings.isDifferentFromTemplate('secondProtectiveOptionOverrideRollToDaysToExp')"
        >
          <dx-select-box
            (onValueChanged)="this.settings.onParameterChanged(
              $event,
              'secondProtectiveOptionOverrideRollToDaysToExp'
              )"
            [(value)]="this.settings.secondProtectiveOptionOverrideRollToDaysToExp"
            [dataSource]="this.settings.expirations"
            [disabled]="this.disabled || !this.shouldRollDates"
            [displayExpr]="'dateWithDaysToExpiration'"
            [showClearButton]="true"
            [valueExpr]="'optionExpirationDate'"
          >
          </dx-select-box>
        </div>
      </div>

      <!-- 2nd Protective Option: Evergreen Override Roll To Exp. Date  -->
      <div class="parameter">
        <p class="part label">Evergreen Overr. Roll to DTE</p>
        <div
          class="part input"
          [class.different]="this.settings.isDifferentFromTemplate(
            'secondProtectiveOptionEvergreenOverrideRollToDaysToExp'
            )"
          [title]="this.settings.getHint('secondProtectiveOptionEvergreenOverrideRollToDaysToExp')"
        >
          <dx-select-box
            (onValueChanged)="this.settings.onParameterChanged(
              $event,
              'secondProtectiveOptionEvergreenOverrideRollToDaysToExp'
              )"
            [(value)]="this.settings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp"
            [dataSource]="this.settings.evergreenDates"
            [disabled]="this.disabled || !this.shouldRollDates"
            [showClearButton]="true"
          >
          </dx-select-box>
        </div>
      </div>
      
      <!-- 2nd Protective Option: Roll X Business Days -->
      <div class="parameter">
        <p class="part label">Roll [x] Tradable Days Past Shorts Expiration</p>
        <div
          class="part input"
          [class.different]="this.settings.isDifferentFromTemplate('secondProtectiveOptionRollToXBusinessDaysToExp')"
          [title]="this.settings.getHint('secondProtectiveOptionRollToXBusinessDaysToExp')"
        >
          <dx-number-box
            (onValueChanged)="this.settings.onParameterChanged(
            $event,
            'secondProtectiveOptionRollToXBusinessDaysToExp'
            )"
            [(value)]="this.settings.secondProtectiveOptionRollToXBusinessDaysToExp"
            [disabled]="this.disabled || !this.shouldRollDates"
            [showClearButton]="true"
            [showSpinButtons]="true"
          >
          </dx-number-box>
        </div>
      </div>
    </ng-template>

  </fieldset>

</div>
