import { Pipe, PipeTransform } from "@angular/core";
import { arraysEqual, isVoid } from "../utils";

//
@Pipe({name: 'etsNgClassAsync'})
export class EtsNgClassAsyncPipe implements PipeTransform {
   
   private _resolvedValue: string[] = [];

   transform(obj: Promise<any>, component: any) {

      if (!obj) {
         return this._resolvedValue;
      }
      
      obj.then((classes: string[]) => {

         classes = classes || [];

         const areEqual = arraysEqual(this._resolvedValue, classes);

         if (!areEqual) {
            
            this._resolvedValue = classes;

            setTimeout(() => {
               component['_changeDetector'].detectChanges();
            }, 250);
      
         }
      });

      return this._resolvedValue;
   }
}