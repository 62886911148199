import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomationCpComponent } from './automation-cp.component';
import { DevExtremeModule } from '../devextreme.module';
import { OptionChainModule } from '../option-chain/option-chain.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { HeaderComponent } from './header/header.component';
import { BucketSelectorComponent } from './header/bucket-selector/bucket-selector.component';
import { ComboPnlsComponent } from './header/combo-pnls/combo-pnls.component';
import { QtyGuardComponent } from './header/qty-guard/qty-guard.component';
import { NavigationComponent } from './body/navigation/navigation.component';
import { BodyComponent } from './body/body.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { OverviewSectionComponent } from './body/content/overview/overview-section.component';
import { WorkingOrdersSectionComponent } from './body/content/working-orders/working-orders-section.component';
import { GlobalSettingsSectionComponent } from './body/content/global-settings/global-settings-section.component';
import { ShortOptionSectionComponent } from './body/content/short-option/short-option-section.component';
import { DebitSpreadSectionComponent } from './body/content/debit-spread/debit-spread-section.component';
import { ProtectiveOptionSectionComponent } from './body/content/protective-option/protective-option-section.component';
import { CashFlowStrategiesModule } from '../cashflow-strategies/cashflow-strategies.module';
import { RollTimeSettingsComponent } from './body/content/global-settings/roll-time/roll-time-settings.component';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { OrderTypeSettingsComponent } from './body/content/global-settings/order-type/order-type-settings.component';
import { OrderExtensionsModule } from '../order-extensions/order-extensions.module';
import { ComparisonPricingSettingsComponent } from './body/content/global-settings/comparison-pricing/comparison-pricing-settings.component';
import { AgGridModule } from 'ag-grid-angular';
import { DebitSpreadSideboxComponent } from './body/content/debit-spread/legs/debit-spread-sidebox.component';
import { ProtectiveOptionSideboxComponent } from './body/content/protective-option/legs/protective-option-sidebox.component';
import { ShortOptionSideboxComponent } from './body/content/short-option/legs/short-option-sidebox.component';
import { InterestSectionComponent } from './body/content/interest/interest-section.component';
import { AdjustmentStrategyDialogModule } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import { InterestSectionSideboxComponent } from './body/content/interest/sidebox/interest-sidebox.component';
import { PortfolioSectionComponent } from './body/content/portfolio/portfolio-section.component';
import { PortfolioSideboxComponent } from './body/content/portfolio/sidebox/portfolio-sidebox.component';
import { LastPriceModule } from '../last-price/last-price.module';
import { ExpirationSmartModeSettingsComponent } from './body/content/global-settings/expiration-smart/expiration-smart-settings.component';
import { AdjustmentStrategyControlsComponent } from './adjustment-strategy-controls/adjustment-strategy-controls.component';
import { EtsStrategyMessagesModule } from '../strategy-messages/strategy-messages.module';
import { StrategyMessagesSectionComponent } from './body/content/strategy-messages/strategy-messages-section.component';
import { CashflowTrackerSectionComponent } from './body/content/cashflow-tracker/cashflow-tracker-section.component';
import { CashFlowTrackingModule } from '../cashflow-tracking/cashflow-tracking.module';
import { SweetspotAdjustmentComponent } from './body/content/global-settings/sweetspot/sweetspot-adjustment.component';

@NgModule({
   declarations: [
      AutomationCpComponent,
      HeaderComponent,
      BucketSelectorComponent,
      ComboPnlsComponent,
      QtyGuardComponent,
      BodyComponent,
      NavigationComponent,
      SectionHeaderComponent,
      OverviewSectionComponent,
      WorkingOrdersSectionComponent,
      GlobalSettingsSectionComponent,
      ShortOptionSectionComponent,
      DebitSpreadSectionComponent,
      ProtectiveOptionSectionComponent,
      RollTimeSettingsComponent,
      OrderTypeSettingsComponent,
      ComparisonPricingSettingsComponent,
      DebitSpreadSideboxComponent,
      ProtectiveOptionSideboxComponent,
      ShortOptionSideboxComponent,
      InterestSectionComponent,
      InterestSectionSideboxComponent,
      PortfolioSectionComponent,
      PortfolioSideboxComponent,
      ExpirationSmartModeSettingsComponent,
      AdjustmentStrategyControlsComponent,
      StrategyMessagesSectionComponent,
      CashflowTrackerSectionComponent,
      SweetspotAdjustmentComponent
   ],
   imports: [ 
      CommonModule,
      DevExtremeModule,
      OptionChainModule,
      OptionsCommonModule,
      CashFlowStrategiesModule,
      DateTimePickerModule,
      TimezonePickerModule,
      OrderExtensionsModule,
      AgGridModule.withComponents([]),
      AdjustmentStrategyDialogModule,
      LastPriceModule,
      EtsStrategyMessagesModule,
      CashFlowTrackingModule
   ],
    exports: [
        AutomationCpComponent,
        SectionHeaderComponent
    ]
})
export class AutomationCpModule {}