import {NgModule} from '@angular/core';
import {EtsNgClassAsyncPipe} from "./ets-ngclass-async.pipe";
import {IsValidNumberPipe} from "./is-valid-number.pipe";
import {PriceFormatterPipe} from "./price-formatter.pipe";
import {FriendlyDatePipe} from "./friendly-date.pipe";
import {FriendlyTickerPipe} from "./friendly-ticker.pipe";

@NgModule({
    imports: [],
    declarations: [
        EtsNgClassAsyncPipe,
        IsValidNumberPipe,
        PriceFormatterPipe,
        FriendlyDatePipe,
        FriendlyTickerPipe,
    ],
    exports: [
        EtsNgClassAsyncPipe,
        IsValidNumberPipe,
        PriceFormatterPipe,
        FriendlyDatePipe,
        FriendlyTickerPipe,
    ],
    providers: [],
})
export class EtsCommonModule {
}
