// tslint:disable:no-string-literal

import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { OrderLeg } from 'projects/shared-components/multi-trade-pad/multi-leg-order/order-leg.class';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { TimeZoneDescriptor } from 'projects/shared-components/timezones/time-zone-descriptor.interface';
import { TIME_ZONE_FAMILIES } from 'projects/shared-components/timezones/time-zones.const';
import { DxValueChanged } from 'projects/shared-components/utils';
import { AAStrategyParameters, AAStrategyValidationContext, OrderLegWrapper } from '../../adjustment-strategy-dialog.model';
import { InterestParameters } from 'projects/shared-components/shell-communication/shell-dto-protocol';

export class InterestCalculatorParameters implements AAStrategyParameters {
   
   constructor(private _cdRef: ChangeDetectorRef, sessionService: SessionService) { 
      this.timezoneFamilies = this.makeTimezoneFamilies();
      this.accountList = sessionService.loginResult.availableAccounts.slice();
   }

   displayName: string;
   repeat?: 'Daily' | 'Weekly';
   amount?: number;
   rate?: number;
   timeOfDay?: string;
   everyXday?: number;
   everyXweek?: number;
   mon = false;
   tue = false;
   wed = false;
   thu = false;
   fri = false;
   timezone: string;
   timezoneCountry: string;
   timezoneFamilies: any[];
   account: string;
   date: string;
   daysInYear = 365;
   daysOfInterest = 1;

   accountList: AccountDto[] = [];
   timezoneItems: TimeZoneDescriptor[] = [];

   changed$: EventEmitter<void> = new EventEmitter();

   onChange(ev: DxValueChanged<any>) {
      this._cdRef.detectChanges();
      if (ev.event) {
         this.changed$.emit();
      }
   }

   onRepeatChanged() {
      this._cdRef.detectChanges();
   }


   onTimezoneCountryChange(country) {
      this.timezoneCountry = country;
      
      const fam = TIME_ZONE_FAMILIES.find(x => x.displayName === country);
      this.timezoneItems =  fam ? fam.timeZoneDescriptors : [];

      this._cdRef.detectChanges();
   }

   //
   validate(context: AAStrategyValidationContext): string[] {
      return [];
   }
   
   //
   getParameters(): string {
      let p = '';
      
      p += `symbol::=SPY`;
      p += `(~)amount::=${this.amount}`;
      p += `(~)rate::=${this.rate}`;
      p += `(~)repeat::=${this.repeat}`;
      p += `(~)timeofday::=${this.timeOfDay || ''}`;
      p += `(~)timezone::=${this.timezone || ''}`;
      
      if (this.repeat === 'Daily') {
         p += `(~)everyxday::=${this.everyXday}`;
         p += `(~)everyxweek::=`;
         p += `(~)mon::=`;
         p += `(~)tue::=`;
         p += `(~)wed::=`;
         p += `(~)thu::=`;
         p += `(~)fri::=`;
      } else if (this.repeat === 'Weekly') {
         p += `(~)everyxday::=`;
         p += `(~)everyxweek::=${this.everyXweek}`;
         p += `(~)mon::=${this.mon || ''}`;
         p += `(~)tue::=${this.tue || ''}`;
         p += `(~)wed::=${this.wed || ''}`;
         p += `(~)thu::=${this.thu || ''}`;
         p += `(~)fri::=${this.fri || ''}`;
      }

      p += `(~)forceaccount::=${this.account || ''}`;
    
      return p;
   }

   //
   getParametersAuto(): InterestParameters {

      const reply: InterestParameters = {
         repeat: this.repeat,
         amount: this.amount,
         account: this.account,
         rate: this.rate,
         timeOfDay: this.timeOfDay,
         everyXDay: this.everyXday,
         everyXWeek: this.everyXweek,
         mon: this.mon,
         tue: this.tue,
         wed: this.wed,
         thu: this.thu,
         fri: this.fri,
         timezone: this.timezone
      };

      return reply;

   }

   //
   
   reset(): void {
      this.repeat = this.amount = this.rate = this.date = this.timeOfDay
      = this.everyXday = this.everyXweek  = this.timezone = this.timezoneCountry =  this.account = null;

      this.mon = this.tue = this.wed = this.thu
       = this.fri = false;

      this.displayName = null;
   }
   
   setParameters(model: StrategyModel): void {
      
      this.timezoneFamilies = this.makeTimezoneFamilies();

      const p = model.parameters;

      this.displayName = model.displayName;

      const repeat = p['repeat'];
      this.repeat = repeat as any;
      
      const amount = parseFloat(p['amount']);
      if (!isNaN(amount)) {
         this.amount = amount;
      }

      const acc = p['forceaccount'];
      if (acc) {
         this.account = acc;
      }

      this.rate = parseFloat(p['rate']);
      this.timeOfDay =  p['timeofday'];
      
      const everyXday = parseInt(p['everyxday']);
      if (!isNaN(everyXday)) {
         this.everyXday = everyXday;
      }
      
      const everyXweek = parseInt(p['everyxweek']);
      if (!isNaN(everyXweek)) {
         this.everyXweek = everyXweek;
      }

      const mon = p['mon'] === 'true';
      if (mon) {
         this.mon = true;
      }
      
      const tue = p['tue'] === 'true';
      if (tue) {
         this.tue = true;
      }
      
      const wed = p['wed'] === 'true';
      if (wed) {
         this.wed = true;
      }
      
      const thu = p['thu'] === 'true';
      if (thu) {
         this.thu = true;
      }

      const fri = p['fri'] === 'true';
      if (fri) {
         this.fri = true;
      }

      const tz = p['timezone'];
      if (tz) {
         const fam = TIME_ZONE_FAMILIES.find(x => x.timeZoneDescriptors.some(y => y.nodaTimeZoneId === tz));
         this.timezoneCountry = fam.displayName;
         this.timezoneItems = fam.timeZoneDescriptors;
         this.timezone = tz;
      }
   }

   setParametersAuto(dto: InterestParameters): void {
      
      // this.timezoneFamilies = this.makeTimezoneFamilies();

      this.repeat = dto.repeat;
      
      this.amount = dto.amount;
      
      this.account = dto.account;

      this.rate = dto.rate;

      this.timeOfDay = dto.timeOfDay;

      this.everyXday = dto.everyXDay;
      
      this.everyXweek = dto.everyXWeek;

      this.mon = dto.mon;
      this.tue = dto.tue;
      this.wed = dto.wed;
      this.thu = dto.thu;
      this.fri = dto.fri;

      const tz = dto.timezone;
      if (tz) {
         const fam = TIME_ZONE_FAMILIES.find(
            x => x.timeZoneDescriptors.some(y => y.nodaTimeZoneId === tz)
         );
         
         this.timezoneCountry = fam.displayName;
         
         this.timezoneItems = fam.timeZoneDescriptors;
         
         this.timezone = tz;
      }
   }
   
   filterSuitableLegs(legs: OrderLeg[]): OrderLegWrapper[] {
      return legs.map(x => ({leg: x, isAvailable: true}));
   }

   private makeTimezoneFamilies() {
      const tzFamiliesGroup = [
         {
            key: 'Favorites',
            items: []
         }, 
         {
            key: 'Others',
            items: []
         }
      ];

      const availableTimeZoneFamilies = TIME_ZONE_FAMILIES.slice();
      
      availableTimeZoneFamilies.forEach(tzf => {
         if (tzf.displayName === 'United States') {
            tzFamiliesGroup[0].items.push(tzf);
         } else {
            tzFamiliesGroup[1].items.push(tzf);
         }
      });

      return tzFamiliesGroup;
   }

}
