import { Component, Input, OnInit } from '@angular/core';
import { ShortOptionParameters } from './short-option-parameters';

@Component({
   selector: 'ets-short-option-strategy',
   templateUrl: 'short-option-strategy.component.html',
   styleUrls: ['../strategy-parameters.scss', 'short-option-strategy.component.scss']
})

export class ShortOptionStrategyComponent implements OnInit {

   constructor() { }

   @Input() parameters: ShortOptionParameters;
   
   @Input() mode: string;

   @Input() cpMode: string;

   ngOnInit() { 
      this.parameters.init();
   }

   getDateTimePickerMode() {
      if (!this.parameters) {
         return null;
      }

      if (this.parameters.convertToMarketSettings.actionTimeMode === 'Convert At') {
         return 'time';
      }

      if (this.parameters.convertToMarketSettings.actionTimeMode === 'Convert After') {
         return 'timespan';
      }

      return null;
   }
}
