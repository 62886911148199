import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular/ui/popup';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/internal/operators/filter';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {SessionService} from '../authentication/session-service.service';
import {SettingsStorageService} from '../settings-storage-service.service';
import {MessageBusService} from '../message-bus.service';
import {PanelBaseComponent} from '../panels/panel-base.component';
import {AccountDto} from '../shell-communication/dtos/account-dto.class';
import {TerminalDto} from '../shell-communication/dtos/terminal-dto.class';
import {ShellClientService} from '../shell-communication/shell-client.service';
import {
    ComboCreatedDto,
    ComboDto,
    ComboGroupCreatedDto,
    ComboGroupDto,
    GetAvailableBucketsReply,
    GroupDeletedDto,
    JobProgressDto,
    PortfolioCreatedDto,
    PortfolioDto
} from '../shell-communication/shell-dto-protocol';
import {
    GetAvailableBuckets,
    MultiLegTradeDto,
    PlaceMultiLegTrades
} from '../shell-communication/shell-operations-protocol';
import {
    BucketHighlighted,
    BucketItemHighlighted,
    CashFlowAdjustmentSpec,
    ComboHighlightedUIMessage,
    MultiLegOrderDataUIMessage,
    SymbolHighlighted
} from '../ui-messages/ui-messages';
import {
    isNullOrUndefined,
    DetectMethodChanges,
    DetectSetterChanges,
    getShortUUID, isVoid
} from '../utils';
import {
    IMultiTradePadComponent, OrderDestination, OrderLinkType,
} from './multi-trade-pad.model';
import {OrderLeg} from './multi-leg-order/order-leg.class';
import {OrderConfirmationService} from '../manual-trading/order-confirmation-service.service';
import {MultiLegOrderComponent} from './multi-leg-order/multi-leg-order.component';
import {JobsService} from '../jobs-tracker/jobs-service.service';
import {PortfolioItemType} from '../portfolios/portfolios.model';
import {UserSettingsService} from "../user-settings.service";


//

interface PanelState {
    isLinkedToSymbol: boolean;
}

//

function removeBucketFromComponent(collection: Array<any>, id: string, itemId: string) {

    if (isNullOrUndefined(collection)) {
        return;
    }

    if (isNullOrUndefined(id)) {
        return;
    }

    const ix = collection.findIndex(c => c[id] === itemId);

    if (ix >= 0) {
        collection.splice(ix, 1);
    }
}


function addBucketToComponent(collection: Array<any>, id: string, item: any) {

    if (isNullOrUndefined(collection)) {
        return;
    }

    if (isNullOrUndefined(id)) {
        return;
    }

    const ix = collection.findIndex(c => c[id] === item[id]);

    if (ix >= 0) {
        collection.splice(ix, 1);
    }

    collection.push(item);
}

//

@Component({
    selector: 'ets-multi-trade-pad',
    templateUrl: 'multi-trade-pad.component.html',
    styleUrls: ['./multi-trade-pad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiTradePadComponent extends PanelBaseComponent implements IMultiTradePadComponent {

    constructor(
        protected readonly _changeDetector: ChangeDetectorRef,
        protected readonly _userSettingsService: UserSettingsService,
        protected readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _shellClient: ShellClientService,
        private readonly _sessionService: SessionService,
        private readonly _orderConfirmationService: OrderConfirmationService,
        private readonly _jobService: JobsService
    ) {
        super(_changeDetector, _userSettingsService, _messageBus);
    }

    //

    private _unsubscriber: Subject<any> = new Subject<any>();

    //

    get changeDetector() {
        return this._changeDetector;
    }

    //

    @ViewChild(DxPopupComponent) readonly confirmationDialog: DxPopupComponent;
    @ViewChild('mainOrder', {static: false}) mainOrderComp: MultiLegOrderComponent;
    @ViewChild('linkedOrderA', {static: false}) linkedOrderAComp: MultiLegOrderComponent;
    @ViewChild('linkedOrderB', {static: false}) linkedOrderBComp: MultiLegOrderComponent;

    //

    linkedOrderAType: OrderLinkType;

    linkedOrderBType: OrderLinkType;

    readonly linkedOrderTypeList: OrderLinkType[] = [
        'OTO',
        'OCO'
    ];

    //

    get higlightTargetOrder(): MultiLegOrderComponent {
        return this.mainOrderComp;
    }

    //

    private _isEmulationMode = false;
    get isEmulationMode(): boolean {
        return this._isEmulationMode;
    }

    @DetectSetterChanges()
    set isEmulationMode(val: boolean) {

        this._isEmulationMode = val;

        if (val) {
            this.linkedOrderAType = null;
            this.linkedOrderBType = null;
        }

    }

    //

    private _sendingOrder = false;
    get sendingOrder(): boolean {
        return this._sendingOrder;
    }

    @DetectSetterChanges()
    set sendingOrder(val: boolean) {
        this._sendingOrder = val;
    }

    //

    get canPlaceOrders(): boolean {
        return this.confirmationModel.orders.every(x => x.verified);
    }

    //

    availableTerminals: TerminalDto[];

    availableAccounts: AccountDto[];

    availablePortfolios: PortfolioDto[];

    availableCombos: ComboDto[];

    availableComboGroups: ComboGroupDto[];

    //

    confirmationModel: { orders: any[], dialogHeight: number, selectedDestination: number } = {
        orders: [],
        dialogHeight: 0,
        selectedDestination: 0
    };

    //

    async etsOnInit() {

        this.availableTerminals = this._sessionService.loginResult.availableTerminals.filter(
            terminal => !terminal.isProxy
        );

        this.availableAccounts = this._sessionService.loginResult.availableAccounts.slice();

        this.isLoading = true;

        try {

            const qry = new GetAvailableBuckets(false);
            const reply = await this._shellClient.processQuery<GetAvailableBucketsReply>(qry);

            this.availablePortfolios = reply.portfolios;
            this.availableCombos = reply.combos;
            this.availableComboGroups = reply.comboGroups;

        } finally {

            this.isLoading = false;

        }

        this.subscribeToMessages();

        this.restoreState();
    }

    //

    etsAfterViewInit() {
        console.assert(!isNullOrUndefined(this.mainOrderComp));
        console.assert(!isNullOrUndefined(this.linkedOrderAComp));
        console.assert(!isNullOrUndefined(this.linkedOrderBComp));
    }

    //

    @DetectMethodChanges()
    onDestinationRemoved(source: 'main' | 'link-a' | 'link-b', ix: number) {
        const components: MultiLegOrderComponent[] = [];

        if (source !== 'main') {
            components.push(this.mainOrderComp);
        }

        if (source !== 'link-a') {
            if (!isNullOrUndefined(this.linkedOrderAType)) {
                components.push(this.linkedOrderAComp);
            }
        }

        if (source !== 'link-b') {
            if (!isNullOrUndefined(this.linkedOrderBType)) {
                components.push(this.linkedOrderBComp);
            }
        }

        components.forEach(x => x.orderModel.removeDestination(ix, true));
    }

    //

    @DetectMethodChanges()
    onDestinationPressed(source: 'main' | 'link-a' | 'link-b', data: { ix: number, state: boolean }) {
        const components: MultiLegOrderComponent[] = [];

        if (source !== 'main') {
            components.push(this.mainOrderComp);
        }

        if (source !== 'link-a') {
            if (!isNullOrUndefined(this.linkedOrderAType)) {
                components.push(this.linkedOrderAComp);
            }
        }

        if (source !== 'link-b') {
            if (!isNullOrUndefined(this.linkedOrderBType)) {
                components.push(this.linkedOrderBComp);
            }
        }

        components.forEach(x => x.orderModel.pressDestination(data.ix, data.state, true));
    }

    //

    @DetectMethodChanges()
    onDestinationAdded(source: 'main' | 'link-a' | 'link-b', dest: OrderDestination) {
        const components: MultiLegOrderComponent[] = [];

        if (source !== 'main') {
            components.push(this.mainOrderComp);
        }

        if (source !== 'link-a') {
            if (!isNullOrUndefined(this.linkedOrderAType)) {
                components.push(this.linkedOrderAComp);
            }
        }

        if (source !== 'link-b') {
            if (!isNullOrUndefined(this.linkedOrderBType)) {
                components.push(this.linkedOrderBComp);
            }
        }

        components.forEach(comp => comp.orderModel.addDestination(dest, true));
    }

    //

    private subscribeToMessages() {

        this._messageBus.of<BucketHighlighted>('BucketHighlighted')
            .pipe(
                takeUntil(this._unsubscriber),
                filter(msg => msg.scopeId === this.layoutTabId)
            )
            .subscribe(msg => this.onBucketHighlighted(msg.payload));

        this._messageBus
            .of<BucketItemHighlighted>('BucketItemHighlighted')
            .pipe(
                takeUntil(this._unsubscriber),
                filter(msg => msg.scopeId === this.layoutTabId),
                filter(msg => msg.payload.item.itemType !== PortfolioItemType.Strategy)
            )
            .subscribe(msg => this.onBucketItemHighlighted(msg.payload));

        this._messageBus
            .of<PortfolioCreatedDto>('PortfolioCreatedDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onPortfolioCreatedMessage(msg.payload));

        this._messageBus
            .of<ComboCreatedDto>('ComboCreatedDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((msg) => this.onComboCreatedMessage(msg.payload));

        this._messageBus
            .of<ComboGroupCreatedDto>('ComboGroupCreatedDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((msg) => this.onComboGroupCreatedMessage(msg.payload));

        this._messageBus
            .of<GroupDeletedDto>('GroupDeletedDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(dto => this.onGroupDeletedMessage(dto.payload));

        this._messageBus
            .of<SymbolHighlighted>('SymbolHighlighted')
            .pipe(
                takeUntil(this._unsubscriber),
                filter(msg => msg.scopeId === this.layoutTabId),
                filter(_ => this.isLinkedToSymbol),
            )
            .subscribe(msg => this.onSymbolHighlightedMessage(msg.payload));
    }

    //

    private onSymbolHighlightedMessage(msg: SymbolHighlighted): void {
        if (!this.isLinkedToSymbol) {
            return;
        }

        this.higlightTargetOrder.processSymbolHighlightedMessage(msg);
    }

    //

    private onGroupDeletedMessage(dto: GroupDeletedDto): void {

        let collection: any[];
        let id: string;
        let itemId: string;

        switch (dto.groupType) {
            case 'combo': {
                collection = this.availableCombos;
                id = 'comboId';
                itemId = dto.comboId;
            }
                break;

            case 'group': {
                collection = this.availableComboGroups;
                id = 'comboGroupId';
                itemId = dto.comboGroupId;
            }
                break;

            case 'portfolio': {
                collection = this.availablePortfolios;
                id = 'portfolioId';
                itemId = dto.portfolioId;
            }
                break;

            default:
                return;
        }

        removeBucketFromComponent(
            collection,
            id,
            itemId
        );
    }

    //

    private onComboGroupCreatedMessage(msg: ComboGroupCreatedDto): void {
        addBucketToComponent(
            this.availableComboGroups,
            'comboGroupId',
            msg.comboGroup
        );
    }

    //

    private onComboCreatedMessage(msg: ComboCreatedDto): void {
        addBucketToComponent(
            this.availableCombos,
            'comboId',
            msg.combo
        );
    }

    //

    private onPortfolioCreatedMessage(dto: PortfolioCreatedDto): void {
        addBucketToComponent(
            this.availablePortfolios,
            'portfolioId',
            dto.portfolio
        );
    }

    //

    private onBucketItemHighlighted(msg: BucketItemHighlighted): void {
        if (!this.higlightTargetOrder.isLinkedMode) {
            return;
        }

        this.higlightTargetOrder.processBucketItemHighlightedMessage(msg);
    }

    //

    private onBucketHighlighted(msg: BucketHighlighted): void {
        if (!this.higlightTargetOrder.isLinkedMode) {
            return;
        }

        this.higlightTargetOrder.processBucketHighlightedMessage(msg);
    }

    //

    etsOnDestroy(): void {

        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

    }


    //#endregion

    toggleTooltip(leg: OrderLeg, property: string) {

        const tooltipProp = `tooltip_${property}`;

        leg[tooltipProp] = !leg[tooltipProp];

        if (!leg[tooltipProp]) {

            this._changeDetector.detectChanges();

        } else {

            const tooltipState = leg[tooltipProp];

            setTimeout(() => {
                if (tooltipState === leg[tooltipProp]) {
                    this._changeDetector.detectChanges();
                }
            }, 700);
        }
    }

    //

    @DetectMethodChanges()
    onLinkedOrderATypeSelected(ev: { value: OrderLinkType, previousValue: OrderLinkType }) {

        this.linkedOrderAType = ev.value;

        if (isNullOrUndefined(this.linkedOrderAComp)) {
            return;
        }

        if (isNullOrUndefined(ev.previousValue)) {
            this.linkedOrderAComp.recreateOrderModel(this.mainOrderComp.orderModel);
            return;
        }
    }

    //

    @DetectMethodChanges()
    onLinkedOrderBTypeSelected(ev: { value: OrderLinkType, previousValue: OrderLinkType }) {

        this.linkedOrderBType = ev.value;

        if (isNullOrUndefined(this.linkedOrderAComp)) {
            return;
        }

        if (isNullOrUndefined(this.linkedOrderBComp)) {
            return;
        }

        if (isNullOrUndefined(ev.previousValue)) {
            this.linkedOrderBComp.recreateOrderModel(this.linkedOrderAComp.orderModel);
            return;
        }
    }

    //

    @DetectMethodChanges()
    verifyOrders() {
        this.mainOrderComp.onExpirationStyleChanged();

        const mainOrderErrors = this.mainOrderComp.validateOrder();

        if (mainOrderErrors.length > 0) {

            mainOrderErrors.forEach(error => {
                this._toastr.error(error, 'Main Order');
            });

            return;
        }

        if (!isNullOrUndefined(this.linkedOrderAType)) {

            if (isNullOrUndefined(this.linkedOrderAComp)) {

                this._toastr.error('Order not available', 'Linked #1');

                return;
            }

            this.linkedOrderAComp.onExpirationStyleChanged();
            const linkedOrderAErrors = this.linkedOrderAComp.validateOrder();

            if (linkedOrderAErrors.length > 0) {

                linkedOrderAErrors.forEach(error => {
                    this._toastr.error(error, 'Linked #1');
                });

                return;
            }

            if (!isNullOrUndefined(this.linkedOrderBType)) {

                if (isNullOrUndefined(this.linkedOrderBComp)) {

                    this._toastr.error('Order not available', 'Linked #2');

                    return;
                }

                this.linkedOrderBComp.onExpirationStyleChanged();

                const linkedOrderBErrors = this.linkedOrderBComp.validateOrder();

                if (linkedOrderBErrors.length > 0) {

                    linkedOrderBErrors.forEach(error => {
                        this._toastr.error(error, 'Linked #2');
                    });

                    return;
                }
            }
        }

        this.showConfirmationDialog();
    }

    //

    @DetectMethodChanges({isAsync: true})
    async sendOrders(): Promise<void> {

        const mainOrders = this.mainOrderComp.getOrderDto();

        const mlTrades: MultiLegTradeDto[] = mainOrders.map((dto, ix) => {

            const mlTrade = new MultiLegTradeDto(dto);

            mlTrade.mainOrderAttachment = this.mainOrderComp.attachment;
            mlTrade.futureOrderSettings = this.mainOrderComp.futureOrderSettings;
            mlTrade.convertToMarketSettings = this.mainOrderComp.convertToMarketSettings;
            mlTrade.cashFlowSpec = this.mainOrderComp.cashFlowSpec;

            if (this.linkedOrderAType) {

                try {

                    const linkedAOrders = this.linkedOrderAComp.getOrderDto();
                    mlTrade.linkedOrderA = linkedAOrders[ix];
                    mlTrade.linkAType = this.linkedOrderAType;
                    mlTrade.linkedOrderAAttachment = this.linkedOrderAComp.attachment;

                } catch {
                    this._toastr.error('Failed to created Linked Order #1', 'Linked #1');

                    return;
                }


                if (this.linkedOrderBType) {
                    try {

                        const linkedBOrders = this.linkedOrderBComp.getOrderDto();
                        mlTrade.linkedOrderB = linkedBOrders[ix];
                        mlTrade.linkBType = this.linkedOrderBType;
                        mlTrade.linkedOrderBAttachment = this.linkedOrderBComp.attachment;

                    } catch {

                        this._toastr.error('Failed to created Linked Order #2', 'Linked #2');
                        return;
                    }

                }
            }

            return mlTrade;
        });

        const cmd = new PlaceMultiLegTrades(mlTrades);

        cmd.guiToken = getShortUUID();

        this.sendingOrder = true;

        try {

            this.isLoading = true;

            const ignoreConfirmation = true;

            if (ignoreConfirmation) {

                await this._shellClient.processCommand(cmd);

            } else {

                await this._shellClient.processCommand(cmd);
                await this._orderConfirmationService.registerToken(cmd.guiToken);

            }

        } catch (e) {

            console.error(e);
            this._toastr.error('"Place Multi-Leg Order" operation completed with errors', 'Multi-Leg Trade');

        } finally {

            this.isLoading = false;
            this.confirmationDialog.visible = false;
            this.sendingOrder = false;

            if (this.mainOrderComp) {
                this.mainOrderComp.resetAttachment();
                this.mainOrderComp.resetFutureOrderSettings();
                this.mainOrderComp.resetCashFlowSpec();
            }

            if (this.linkedOrderAComp) {
                this.linkedOrderAComp.resetAttachment();
                this.linkedOrderAComp.resetCashFlowSpec();
            }

            if (this.linkedOrderBComp) {
                this.linkedOrderBComp.resetAttachment();
                this.linkedOrderBComp.resetCashFlowSpec();
            }

        }
    }

    //

    @DetectMethodChanges()
    selectVerifyDestination(ix: number) {
        this.confirmationModel.orders[ix].verified = true;
        this.confirmationModel.selectedDestination = ix;
    }

    //

    private showConfirmationDialog() {
        let dialogHeight = 260; // just a empirical constant


        const mainOrderModels = this.mainOrderComp.getVerificationModels();
        dialogHeight += mainOrderModels[0].legs.length * 19;

        let linkedOrderAModels = [];
        if (this.linkedOrderAType) {
            linkedOrderAModels = this.linkedOrderAComp.getVerificationModels();
            dialogHeight += linkedOrderAModels[0].legs.length * 19 + 120;
            linkedOrderAModels.forEach(x => x.linkType = this.linkedOrderAType);
        }

        let linkedOrderBModels = [];
        if (this.linkedOrderBType) {
            linkedOrderBModels = this.linkedOrderBComp.getVerificationModels();
            dialogHeight += linkedOrderBModels[0].legs.length * 19 + 120;
            linkedOrderBModels.forEach(x => x.linkType = this.linkedOrderBType);
        }

        const destinations = mainOrderModels.map((mom, ix) => {
            const res: any = {
                mainOrder: mom
            };

            if (linkedOrderAModels.length >= ix) {
                res.linkedOrderA = linkedOrderAModels[ix];
            }

            if (linkedOrderBModels.length >= ix) {
                res.linkedOrderB = linkedOrderBModels[ix];
            }

            return res;
        });

        destinations[0].verified = true;

        this.confirmationModel.orders = destinations;
        this.confirmationModel.selectedDestination = 0;
        this.confirmationModel.dialogHeight = dialogHeight;

        this.confirmationDialog.visible = true;
    }

    //

    protected getState(): PanelState {

        const state: PanelState = {
            isLinkedToSymbol: this.isLinkedToSymbol
        };

        return state;
    }

    //

    protected setState(state: PanelState): void {
        this.isLinkedToSymbol = state.isLinkedToSymbol;
    }

    //
    @DetectMethodChanges({isAsync: true})
    async onLinkedOrdersPasted(msg: MultiLegOrderDataUIMessage, component: MultiLegOrderComponent): Promise<void> {

        const self = this;

        if (isVoid(msg.main)) {
            return;
        }

        if (isVoid(this.mainOrderComp)) {
            return;
        }

        self.isLoading = true;

        const orders = [msg.main, msg.linked, msg.secondLinked, msg.thirdLinked];

        if (component === this.mainOrderComp) {
            setTimeout(() => this.copyToMainOrder(msg, orders), 250);
        }
        else if (component === this.linkedOrderAComp) {
            setTimeout(() => this.copyToLinkedOrderA(msg, orders), 250);
        }
        else if (component === this.linkedOrderBComp) {
            setTimeout(() => this.copyToLinkedOrderB(msg, orders));
        }
        else {
            self.isLoading = false;
        }
    }

    private copyToMainOrder(msg: MultiLegOrderDataUIMessage, orders: ComboHighlightedUIMessage[]) {
        if (orders.length == 0) {
            return;
        }

        let hadError = false;

        this.mainOrderComp
            .processComboHighlightedMessage(orders[0])
            .catch(e => hadError = true)
            .finally(() => {
                if (msg.linked && msg.linked.items.length > 0) {
                    if (msg.linkType) {
                        this.linkedOrderAType = msg.linkType;
                    }
                    orders.splice(0, 1);
                    setTimeout(() => this.copyToLinkedOrderA(msg, orders), 250);
                } else {
                    this.isLoading = false;
                }
            });
    }

    private copyToLinkedOrderA(msg: MultiLegOrderDataUIMessage, orders: ComboHighlightedUIMessage[]) {
        if (orders.length === 0) {
            return;
        }

        let hadError = false;

        this.linkedOrderAComp
            .processComboHighlightedMessage(orders[0])
            .catch(e => hadError = true )
            .finally(() => {
                if (msg.secondLinked && msg.secondLinked.items.length > 0) {
                    this.linkedOrderBType = msg.linkType;
                    orders.splice(0,1);
                    setTimeout(() => this.copyToLinkedOrderB(msg, orders), 250);
                } else {
                    this.isLoading = false;
                }
            });
    }

    private copyToLinkedOrderB(msg: MultiLegOrderDataUIMessage, orders: ComboHighlightedUIMessage[]) {

        if (orders.length === 0) {
            return;
        }

        this.linkedOrderBComp
            .processComboHighlightedMessage(orders[0])
            .catch(e => console.error(e))
            .finally(() => {
                setTimeout(() => {
                    this.isLoading = false
                }, 250);
            });
    }
}
