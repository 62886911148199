import { OrderDto } from '../shell-communication/dtos/order-dto.class';
import { OrderStatus } from '../trading-model/order-status.enum';

export class WorkingOrdersIndex {
  private _ix: { [orderId: string]: OrderDto };

  constructor() {
    this._ix = {};
  }

  calculateState(dtos: OrderDto[]): OrderDto[] {
    for (const dto of dtos) {
      if (dto.orderId in this._ix) {
        if ((dto.status & OrderStatus.Working) === dto.status) {
          this._ix[dto.orderId] = dto;
        } else {
          delete this._ix[dto.orderId];
        }
      } else {
        if ((dto.status & OrderStatus.Working) === dto.status) {
          this._ix[dto.orderId] = dto;
        }
      }
    }
    return Object.keys(this._ix).map(x => this._ix[x]);
  }

  reset(): void {
    this._ix = {};
  }

  getCurrentState(): OrderDto[] {
    return Object.keys(this._ix).map(key => this._ix[key]) || [];
  }

  count(): number {
    return Object.keys(this._ix).length;
  }

  resetByShell(shellId: string) {
    Object.values(this._ix)
      .filter(order => order.shellId === shellId)
      .forEach(order => delete this._ix[order.orderId]);
  }
}
