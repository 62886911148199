<div class="container">
   
   <div class="buttons">
      <div class="btn create" *ngIf="this.showCreateButton">
         <dx-button width="100%" text="Create Adjustment Strategy" (onClick)="this.onCreateStrategyClicked()"></dx-button>
      </div>
   
      <div class="manage" *ngIf="this.showManageButtons">
         <div class="btn">
            <dx-button width="100%" text="Enable" [disabled]="!this.canEnableStrategy" (onClick)="this.onEnableStrategyClicked()"></dx-button>
         </div>

         <div class="btn">
            <dx-button width="100%" text="Disable" [disabled]="!this.canDisableStrategy" (onClick)="this.onDisableStrategyClicked()"></dx-button>
         </div>

         <div class="btn">
            <dx-button width="100%" text="Update" [disabled]="!this.canUpdateStrategy" (onClick)="this.onUpdateStrategyClicked()"></dx-button>
         </div>

         <div class="btn">
            <dx-button width="100%" text="Delete" [disabled]="!this.canDeleteStrategy" (onClick)="this.onDeleteStrategyClicked()"></dx-button>
         </div>
      </div>
   </div>

   <div class="status-bar" *ngIf="this.showStatusBar">
      <div class="label" [style.background]="this.backgroundColor">{{this.labelText}}</div>
   </div>

</div>