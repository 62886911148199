import { Component, OnInit } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { getSessionRulesGridModel } from './session-rules-grid-model';
import { getPositionalRulesGridModel } from './positional-rules-grid-model';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import {  DateTime } from 'luxon';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { RolloverDescriptor } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { GetRolloverSchedule, SaveRolloverSchedule } from 'projects/shared-components/shell-communication/shell-operations-protocol';


@Component({
  selector: 'ets-rollover-scheduler',
  templateUrl: 'rollover-scheduler.component.html',
  styleUrls: ['rollover-scheduler.component.scss']
})
export class RolloverSchedulerComponent implements OnInit {
  
  constructor(
    private _displayNameService: TradingInstrumentDisplayNameService,
    private _tiService: TradingInstrumentsService,
    private _messageBus: MessageBusService,
    private _toastr: ToastrService,
    private _shellClient: ShellClientService) { 
  }

  private _sessionRulesGrid: GridReadyEvent;
  private _positionalRulesGrid: GridReadyEvent;

  private _rolloverDescriptors: RolloverDescriptor[] = [];

  isVisible = false;
  isBusy = false;

  sessionRulesSectionSize = null;
  positionalRulesSectionSize = null;

  sessionRulesGridModel: GridOptions;
  positionalRulesGridModel: GridOptions;


  ngOnInit() { 
    this.sessionRulesGridModel = getSessionRulesGridModel.bind(this)(this._displayNameService);
    this.positionalRulesGridModel = getPositionalRulesGridModel.bind(this)(this._displayNameService);
    this._messageBus.of<any>('BaseOffsetChanged')
      .subscribe(msg => this.onOffsetChanged(msg));
  }

  async onShown() {
    await this._loadData();
  }

  onHidden() {
    this.isVisible = false;
    this._rolloverDescriptors = [];
    this._sessionRulesGrid.api.setRowData([]);
    this._positionalRulesGrid.api.setRowData([]);
  }

  async saveChanges() {
    try {
      this.isBusy = true;
      this._sessionRulesGrid.api.showLoadingOverlay();
      this._positionalRulesGrid.api.showLoadingOverlay();
      const cmd = new SaveRolloverSchedule(this._rolloverDescriptors);
      await this._shellClient.processCommand(cmd);
    } finally {
      this.isBusy = false;
      this._sessionRulesGrid.api.hideOverlay();
      this._positionalRulesGrid.api.hideOverlay();
    }

    this.onHidden();
  }

  onSessionRulesGridReady(args: GridReadyEvent): void {
    this._sessionRulesGrid = args;
  }

  async onPositionalRulesGridReady(args: GridReadyEvent) {
    this._positionalRulesGrid = args;

    this.sessionRulesSectionSize = 50;
    this.positionalRulesSectionSize = 50;
  }

  onOffsetChanged(msg) {
    const data = this._rolloverDescriptors.filter(x => x.isSession === msg.isSession);
    this._processData(data, msg.symbol);
    const grid: GridOptions = msg.isSession ? this._sessionRulesGrid : this._positionalRulesGrid;
    grid.api.setRowData(data);
    grid.api.refreshCells();
  }

  private async _loadData(): Promise<void> {
    try {

      this._sessionRulesGrid.api.showLoadingOverlay();
      this._positionalRulesGrid.api.showLoadingOverlay();

      const loadedData = await this._loadDataFromServer();

      this._rolloverDescriptors = loadedData;

      this._processData(this._rolloverDescriptors, null);


      const sessionData = this._rolloverDescriptors.filter(d => d.isSession);
      const positionalData = this._rolloverDescriptors.filter(d => !d.isSession);
            
      this._sessionRulesGrid.api.setRowData(sessionData);
      this._positionalRulesGrid.api.setRowData(positionalData);
      
      return Promise.resolve(null);
    } catch {
      this._toastr.error('Rollover Scheduler data loaded with errors');
      this._sessionRulesGrid.api.setRowData([]);
      this._positionalRulesGrid.api.setRowData([]);
    } finally {
      this._sessionRulesGrid.api.hideOverlay();
      this._positionalRulesGrid.api.hideOverlay();
    }
  }

  private _processData(data: RolloverDescriptor[], symbol: string) {
    data.filter(x => isNullOrUndefined(symbol) || x.baseSymbol === symbol ).forEach( (d: RolloverDescriptor) => {
      const instr = this._tiService.getInstrumentByTicker(d.ticker);
      if (instr) {
        const expDate = instr.expirationDate;
        if (expDate) {
          const lxnExpDate = DateTime.fromJSDate(expDate);
          const lxnTargetDate = lxnExpDate.plus({days: d.specificOffset || d.baseOffset || 0});
          d.rolloverDate = lxnTargetDate.toJSDate();
        }
      }
    });
  }

  private _loadDataFromServer(): Promise<RolloverDescriptor[]> {
    // const data: RolloverDescriptor[] = [
    //   { baseSymbol: 'ES', ticker: 'ESU0', specificOffset: null, baseOffset: 0, rolloverDate: null, isSession: true },
    //   { baseSymbol: 'ES', ticker: 'ESZ0', specificOffset: null, baseOffset: 0, rolloverDate: null, isSession: true },
    //   { baseSymbol: 'CL', ticker: 'CLQ0', specificOffset: null, baseOffset: 0, rolloverDate: null, isSession: true },
    //   { baseSymbol: 'CL', ticker: 'CLU0', specificOffset: null, baseOffset: 0, rolloverDate: null, isSession: true },
    //   { baseSymbol: 'YM', ticker: 'YMZ0', specificOffset: null, baseOffset: 0, rolloverDate: null, isSession: false },
    //   { baseSymbol: '6E', ticker: '6EU0', specificOffset: null, baseOffset: 0, rolloverDate: null, isSession: false },
    // ];
    // return Promise.resolve(data);

    const qry = new GetRolloverSchedule();
    return this._shellClient.processQuery<RolloverDescriptor[]>(qry);
  }
}

