import { AlgoDescriptionBuilderBase } from '../algo-description-builder-base.class';
import { StrategyParameters } from '../../strategies/strategy-parameters.enum';

export class RemoteAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
   getSymbolPropertyNames(): string[] {
      return ['symbol'];
   }

   getAlgoName(parameters: StrategyParameters): string {
      return 'Remote';
   }

   getDescription(parameters: StrategyParameters): string {
      let res = '';
      const defaultValue = 'N/A';
      let symbol: string = parameters.symbol || defaultValue;

      if (symbol !== defaultValue) {
         symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
      }

      res += `[${symbol}]`;
      res += ` Multiplier: ${parameters.multiplier || defaultValue}`;
      
      return res;
   }
}
