import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DetectMethodChanges, isVoid} from "../../../utils";
import {SelectionChangedEvent} from "devextreme/ui/list";
import {ToastrService} from "ngx-toastr";
import {HgSettingsSectionComponent, LegsGroup} from "../hg-settings-section.component";

export interface HgMergeDialogConfig {
    target: any;
    mergingPackageLabel: string;
    packages: HedgePackageToMerge[];
    comp: HgSettingsSectionComponent
}

export interface HedgePackageToMerge {
    pckg: LegsGroup
    label: string;
    color: string;
    groupId: string;
    selected: boolean;
}

@Component({
    selector: 'ets-hg-merge-dialog',
    templateUrl: 'hg-merge-dialog.component.html',
    styleUrls: ['./hg-merge-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgMergeDialogComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }

    private _resolve: (value?: (PromiseLike<boolean> | boolean)) => void;
    private _reject: (reason?: any) => void;
    private _config: HgMergeDialogConfig;

    visible = false;

    target: any;

    packages: HedgePackageToMerge[];

    get availablePackages(): HedgePackageToMerge[] {
        return this.packages
            .filter(x => this.createNewPackage ? true : !x.selected);
    }

    selectedPackages : HedgePackageToMerge[] = [];

    @Output() packageSelected$
        = new EventEmitter<{packageId: string, selected:boolean}>();

    mergingPackageLabel = 'Package';

    createNewPackage = false;

    get canMerge() {

        const selectedLegs = this._config.packages
            .map(x => x.pckg)
            .filter(x => x.selectedForMerge)
            .flatMap(x => x.legs.filter(y => y.selectedForMerge));

        if (selectedLegs.length === 0) {
            return false;
        }

        const notAllValid = selectedLegs.some(x => !x.isValid());

        if (notAllValid) {
            return false;
        }

        const badQty = selectedLegs.some(leg => {
            if (Math.abs(leg.transQty) > Math.abs(leg.qty)) {
                return true;
            }
        });

        if (badQty) {
            return false;
        }

        return true;
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: HgMergeDialogConfig): Promise<boolean> {
        this._config = config;

        this.target = config.target;
        this.packages = config.packages;
        this.selectedPackages = [];
        this.createNewPackage = false;
        this.mergingPackageLabel = config.mergingPackageLabel || 'Package';
        this.visible = true;

        return new Promise<boolean>((res, rej) => {
            this._resolve = res;
            this._reject= rej;
        })
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this.target = undefined;
        this.packages = [];
        this.createNewPackage = false;
        this._config = undefined;
    }

    onSelectedPackagesChanged(args: SelectionChangedEvent<HedgePackageToMerge>) {
        args.removedItems.forEach(x => {
            const ix = this.selectedPackages.indexOf(x);
            if (ix < 0) {
                return;
            }
            this.selectedPackages.splice(ix, 1);

            this.packageSelected$.emit({packageId: x.groupId, selected: false});
        });

        args.addedItems.forEach(x => {
            const ix = this.selectedPackages.indexOf(x);
            if (ix === -1) {
                this.selectedPackages.push(x);
            }

            this.packageSelected$.emit({packageId: x.groupId, selected: true});
        });
    }

    @DetectMethodChanges()
    onMergeClicked() {

        if (!this.canMerge) {
            this._toastr.error('Cannot Merge Current Configuration');
            return;
        }

        if (this.selectedPackages.length === 0) {
            this._toastr.error('Please Select Packages To Merge With');
            return;
        }

        this._resolve(this.createNewPackage);

        this.onHidden();
    }

    onCancelClicked() {
        this._reject();
        this.onHidden();
    }

    @DetectMethodChanges()
    onCreateNewPackageChanged() {
        if (!this.createNewPackage) {
            const selfPackage = this.packages.find(x => x.selected);
            if (!isVoid(selfPackage)) {
                this.selectedPackages = this.selectedPackages.filter(x => x !== selfPackage);
                this.packageSelected$.emit({packageId: selfPackage.groupId, selected: false});
            }
        }
    }

    isSelfPackage(data: HedgePackageToMerge) {
        return data?.selected;
    }
}