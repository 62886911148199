<ag-grid-angular
  class="ag-theme-balham-dark"
  [gridOptions]="strategyLogMessagesGridOptions"
></ag-grid-angular>

<dx-popup width="550"
          height="350"
          [showTitle]="true"
          title='Message Details'
          [closeOnOutsideClick]="true"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          (onHidden)="isDetailsWindowVisible = false"
          [visible]="isDetailsWindowVisible">
  <div *dxTemplate="let data of 'content'" id="session_message_details">
    <div id="popup_container">
      <p>{{selectedMessage?.messageText}}</p>
    </div>
  </div>
</dx-popup>