import {DashboardComparisonModel} from "./DashboardComparisonModel";
import {SortingRule} from "./sorting.rule";
import {RowFilter} from "./row.filter";
import {DetectMethodChanges, isVoid} from "../../utils";
import {SortingAttribute} from "./sorting.attribute";
import {ChangeDetectorRef} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import * as Enumerable from "linq";
import {RowFilterAttribute} from "./RowFilterAttribute";
import {HedgesPricingService} from "../services/hedges-pricing.service";
import {PackageComparisonFiltersService} from "../package-comparison-filters.service";
import {PackageComparisonSortingService} from "../package-comparison-sorting.service";

export class DashboardViewModelGroup {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _id: string,
        private readonly _toastr: ToastrService,
        private readonly _hedgesPricing: HedgesPricingService,
        public readonly items: DashboardComparisonModel[],
        private readonly _filtersService: PackageComparisonFiltersService,
        private readonly _sortingService: PackageComparisonSortingService
    ) {
        this._filtersService.filtersChanged$.subscribe(() => {
            this._filteredItems = undefined;
        });

        this._sortingService.sortingChanged$.subscribe(() => {
            this._filteredItems = undefined;
        });
    }

    get sortingRules(): ReadonlyArray<SortingRule> {
        return this._sortingService.sortingRules;
    }

    get rowFilters(): ReadonlyArray<RowFilter> {
        return this._filtersService.filters;
    };


    get id(): string {
        return this._id;
    }

    private _filteredItems: DashboardComparisonModel[];
    get filteredItems(): DashboardComparisonModel[] {

        if (isVoid(this._filteredItems)) {
            let originalPackages = this.items
                .slice();

            let packages = originalPackages;

            if (!isVoid(this.rowFilters)) {
                this.rowFilters.forEach(f => {
                    switch (f.attribute) {
                        case "portfolio":
                            packages = packages
                                .filter(x => {
                                    const key = `${x.portfolio?.userId}@${x.portfolio?.id}`;
                                    return key === f.value;
                                });
                            break;
                        case "template":
                            packages = packages.filter(x => x.template?.templateId === f.value);
                            break;
                        case "adjustment":
                            packages = packages.filter(x => x.adjustment === f.value);
                            break;
                        case "adj_call":
                            packages = packages.filter(x => x.adjustment?.indexOf(f.value) >= 0);
                            break;
                        case "adj_put":
                            packages = packages.filter(x => x.adjustment?.indexOf(f.value) >= 0);
                            break;
                        case "hedge_call":
                            packages = packages.filter(x => x.callHedge?.strategyId === f.value);
                            break;
                        case "hedge_put":
                            packages = packages.filter(x => x.putHedge?.strategyId === f.value);
                            break;
                        case "hedge_call_family":
                            packages = packages.filter(x => x.callHedge?.templateId === f.value);
                            break;
                        case "hedge_put_family":
                            packages = packages.filter(x => x.putHedge?.templateId === f.value);
                            break;
                        case "offset_call":
                            packages = packages.filter(x => x.callHedgeOffset === f.value);
                            break;
                        case "offset_put":
                            packages = packages.filter(x => x.putHedgeOffset === f.value);
                            break;
                    }
                });
            }

            if (!isVoid(this.sortingRules)) {
                if (packages.length > 1) {
                    let enumerable = Enumerable.from(packages);
                    for (let sortingRule of this.sortingRules) {
                        switch (sortingRule.attribute) {
                            case "portfolio":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.portfolio?.name?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.portfolio?.name?.toLowerCase());
                                break;
                            case "template":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.template?.templateName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.template?.templateName?.toLowerCase());
                                break;
                            case "hedge_call":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.callHedge?.strategyName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.callHedge?.strategyName?.toLowerCase());
                                break;
                            case "hedge_put":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.putHedge?.strategyName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.putHedge?.strategyName?.toLowerCase());
                                break;
                            case "expiration":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => {
                                        return x.models[sortingRule.data]?.getPackagePrice() || Number.MAX_SAFE_INTEGER;
                                    })
                                    : enumerable.orderByDescending(x => {
                                        return x.models[sortingRule.data]?.getPackagePrice() || Number.MIN_SAFE_INTEGER;
                                    });
                                break;
                            case "adjustment":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.adjustment)
                                    : enumerable.orderByDescending(x => x.adjustment);
                                break;
                            case "hedges_cost_calls":
                                const sortFunc = (x: DashboardComparisonModel) => {
                                    const key = `${x.portfolio?.userId}@${x.portfolio.id}`;
                                    const cost = this._hedgesPricing.getHedgesCost(key, 'calls');
                                    return cost;
                                };
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(sortFunc)
                                    : enumerable.orderByDescending(sortFunc);
                                break;
                            case "hedges_cost_puts":
                                const sortFunc1 = (x: DashboardComparisonModel) => {
                                    const key = `${x.portfolio?.userId}@${x.portfolio.id}`;
                                    const cost = this._hedgesPricing.getHedgesCost(key, 'calls');
                                    return cost;
                                };
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(sortFunc1)
                                    : enumerable.orderByDescending(sortFunc1);
                                break;
                            case "hedges_cost_total":
                                const sortFunc2 = (x: DashboardComparisonModel) => {
                                    const key = `${x.portfolio?.userId}@${x.portfolio.id}`;
                                    const cost = this._hedgesPricing.getHedgesCost(key, 'total');
                                    return cost;
                                };
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(sortFunc2)
                                    : enumerable.orderByDescending(sortFunc2);
                                break;
                            case "offset_call":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.callHedgeOffset || Number.MAX_SAFE_INTEGER)
                                    : enumerable.orderByDescending(x => x.callHedgeOffset || Number.MIN_SAFE_INTEGER);
                                break;
                            case "offset_put":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.putHedgeOffset || Number.MAX_SAFE_INTEGER)
                                    : enumerable.orderByDescending(x => x.putHedgeOffset || Number.MIN_SAFE_INTEGER);
                                break;
                        }
                    }
                    packages = enumerable.toArray();
                }
            }

            packages = packages.sort((a, b) => {
                const aPin = a?.isPinned ? 1 : 0;
                const bPin = b?.isPinned ? 1 : 0;
                return bPin - aPin;
            });

            if (packages.length === 0) {
                this._filteredItems = originalPackages
            }

            this._filteredItems = packages;
        }


        return this._filteredItems;
    }

    @DetectMethodChanges()
    onDashboardFilterRequest(filter: RowFilter) {

        try {

            if (isVoid(filter)) {
                this._filtersService.clearFilters();
                return;
            }

            if (filter === 'adjustment') {
                const hasAdjustments = this.rowFilters
                    .filter(x => x.attribute.startsWith('adj_'));

                if (hasAdjustments) {
                    this._filtersService.remove(hasAdjustments);
                    return;
                }
            }

            const ix = this.rowFilters.findIndex(x => x.attribute === filter.attribute);

            if (ix < 0) {
                this._filtersService.addFilter(filter);
            } else {
                const existing = this.rowFilters[ix];
                if (existing.value === filter.value) {
                    this._filtersService.removeAt(ix);
                } else {
                    this._filtersService.replaceAt(ix, filter);
                }
            }
        } finally {
            this._filteredItems = undefined;
        }

    }

    @DetectMethodChanges()
    clearSorting() {
        this._sortingService.clearSortingRules();
    }

    @DetectMethodChanges()
    clearFilters() {
        this._filtersService.clearFilters();
    }

    setRowFilters(filters: RowFilter[]) {
    }

    isDashboardFilterEnabled(attribute: RowFilterAttribute) {
        return this.isFilterEnabledInternal(this.rowFilters, attribute);
    }

    @DetectMethodChanges()
    changeDashboardSortingAttribute(attribute: SortingAttribute, data?: any) {
        try {
            this.changeSortingAttributeInternal(this.sortingRules, attribute, data);
        } finally {
            this._filteredItems = undefined;
        }
    }

    private changeSortingAttributeInternal(rules: ReadonlyArray<SortingRule>, attribute: SortingAttribute, data?: any) {
        const sortingRule = rules.find(x => x.attribute === attribute);
        if (isVoid(sortingRule)) {
            const rule: SortingRule = {
                attribute,
                order: 'asc',
                data
            };
            this._sortingService.addSortingRule(rule)
        } else if (sortingRule.order === 'asc') {
            sortingRule.order = 'desc';
        } else if (sortingRule.order === 'desc') {
            const ix = rules.indexOf(sortingRule);
            this._sortingService.removeAt(ix);
        }

        this._sortingService.sortRules();
    }

    private isFilterEnabledInternal(filters: ReadonlyArray<RowFilter>, attribute: RowFilterAttribute) {

        if (isVoid(attribute)) {
            return false;
        }

        if (isVoid(filters)) {
            return false;
        }

        let isEnabled = filters.findIndex(x => x.attribute === attribute) >= 0;

        if (!isEnabled && attribute.startsWith('hedge_')) {
            const attr = attribute + '_family';
            isEnabled = filters.findIndex(x => x.attribute === attr) >= 0;
        } else if (!isEnabled && attribute === 'adjustment') {
            isEnabled = filters.some(x => x.attribute.startsWith('adj_'));
        }

        return isEnabled;
    }

    private isFamilyFilterEnabledInternal(filters: ReadonlyArray<RowFilter>, filter: RowFilterAttribute) {
        if (filter === 'hedge_call') {
            return filters.findIndex(x => x.attribute === 'hedge_call_family') >= 0;
        }

        if (filter === 'hedge_put') {
            return filters.findIndex(x => x.attribute === 'hedge_put_family') >= 0;
        }

        return false;
    }

    isDashboardFamilyFilterEnabled(filter: RowFilterAttribute) {
        return this.isFamilyFilterEnabledInternal(this.rowFilters, filter);
    }


    getDashboardSortingState(attribute: SortingAttribute, data?: any) {
        return this.getSortingStateInternal(this.sortingRules, attribute, data);
    }


    private getSortingStateInternal(rules: ReadonlyArray<SortingRule>, attribute: SortingAttribute, data = undefined) {
        const sortingRule = rules
            .find(x => x.attribute === attribute && x.data === data);
        return sortingRule?.order;
    }

    @DetectMethodChanges()
    disableFilter(filter: RowFilterAttribute) {
        const ix = this.rowFilters.findIndex(x => x.attribute === filter);
        if (ix < 0) {
            if (filter === 'adjustment') {
                const adjFilters = this.rowFilters
                    .filter(x => x.attribute.startsWith('adj_'));
                this._filtersService.remove(adjFilters);
            }
            return;
        }
        this._filtersService.removeAt(ix);
    }

    resetSorting() {
        this._filteredItems = undefined;
    }
}