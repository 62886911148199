import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { JobsTrackerComponent } from './jobs-tracker.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AgGridModule.withComponents([]),
      DevExtremeModule
   ],
   exports: [JobsTrackerComponent],
   declarations: [JobsTrackerComponent],
   providers: []
})
export class JobsTrackerModule { }
