<div class="panel-container" [ngClass]="{'ets-hidden': !isActive}">
   <div class="tabs">
      <dx-tabs [dataSource]="tabs" [(selectedItem)]="this.selectedTab" ></dx-tabs>
   </div>
   <div class="content">
         <div class="grid broker" *ngIf="this.selectedTab?.id === 'broker'">
            <ag-grid-angular 
               class="ag-theme-balham-dark"
               [gridOptions]="this.byBrokerModel.gridOptions">
            </ag-grid-angular>
         </div>
         <div class="grid asset" *ngIf="this.selectedTab?.id === 'asset'">
            <ag-grid-angular
               class="ag-theme-balham-dark"
               [gridOptions]="this.byBrokerModel.gridOptions">
            </ag-grid-angular>
         </div>
      
   </div>
</div>