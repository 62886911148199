import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { isNullOrUndefined } from 'util';

const PORTFOLIO_1 = {
   id: '9l__dC7jF',
   header: 'Default',
   proposedHeader: 'Default',
   layoutRoot: {
      layoutTabId: '9l__dC7jF',
      direction: 'vertical',
      subsections: [
         {
            layoutTabId: '9l__dC7jF',
            workspaceId: '9l__dC7jF+xfG9c9Sahy',
            parent: null,
            order: 0,
            isInitial: false
         }
      ],
      order: 0
   },
   isActive: true
};

const PORTFOLIO_2 = {
layoutTabId: '9l__dC7jF',
workspaceId: '9l__dC7jF+xfG9c9Sahy',
panels: [
   {
      panelId: 'oM-NhahkW',
      header: 'Portfolios',
      typeCode: 16,
      isActive: true
   }
]
};

const PORTFOLIO_3 = {expandRowsOnLoad: true};


const ADJPG_ROOT = {
   "id": "cV75okgIh",
   "header": "Default",
   "proposedHeader": "Default",
   "layoutRoot": {
      "layoutTabId": "cV75okgIh",
      "direction": "vertical",
      "subsections": [
         {
            "layoutTabId": "cV75okgIh",
            "workspaceId": "cV75okgIh+USKX0J9_lv",
            "parent": null,
            "order": 0
         }
      ],
      "order": 0
   },
   "isActive": true
}

const ADJPG_PANEL = {
   "layoutTabId": "cV75okgIh",
   "workspaceId": "cV75okgIh+USKX0J9_lv",
   "panels": [
      {
         "panelId": "H6F9pHlc6",
         "header": "Adjustment Pricing Grid",
         "typeCode": 34,
         "isActive": true
      }
   ]
}

export function getTemplateItems(templateId: string, clientName: string): Record<string, any>[] {
   
   if (isNullOrUndefined(templateId)) {

      const empty = {
         id: '9l__dC7jF1',
         header: 'Default',
         proposedHeader: 'Default',
         layoutRoot: {
            layoutTabId: '9l__dC7jF1',
            direction: 'vertical',
            subsections: [
               {
                  layoutTabId: '9l__dC7jF1',
                  workspaceId: '9l__dC7jF1+xfG9c9Sahy1',
                  parent: null,
                  order: 0,
                  isInitial: true
               }
            ],
            order: 0
         },
         isActive: true
      };
      
      const lo = JSON.stringify(empty);

      return [
         { key: EtsConstants.storageKeys.layoutRootKey, value:  [lo]}
      ];

   } else if (templateId === 'Portfolios') {
      
      PORTFOLIO_1.header = clientName;
      PORTFOLIO_1.proposedHeader = clientName;

      const lo = JSON.stringify(PORTFOLIO_1);
      
      return [
         { key: EtsConstants.storageKeys.layoutRootKey, value:  [lo]},
         { key: '9l__dC7jF+xfG9c9Sahy', value:  PORTFOLIO_2},
         { key: '9l__dC7jF+xfG9c9Sahy+oM-NhahkW', value:  PORTFOLIO_3},
      ];
   } else if (templateId === 'Adjustment_Pricing_Grid_Default') {

      const root = [JSON.stringify(ADJPG_ROOT)];
      const panel = JSON.stringify(ADJPG_PANEL);

      return [
         {key: EtsConstants.storageKeys.layoutRootKey,   value: root },
         {key: 'cV75okgIh+USKX0J9_lv',               value: panel  },
      ];
   }
}
