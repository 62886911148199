import { Component } from '@angular/core';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';

@Component({
   selector: 'ets-shell-messages-panel',
   template: `
<div class="panel-container" [ngClass]="{'ets-hidden': !isActive}">
   <ets-shell-messages></ets-shell-messages>
</div>
   `,
   styles: [`
   .panel-container {
      height: 100%;
      ag-grid-angular {
         height: 100%;
      }
   }  
   `],
})

export class ShellMessagesPanelComponent extends PanelBaseComponent {
   
   etsOnInit(): void {
      
   }
   
   etsOnDestroy(): void {
   
   }
   etsAfterViewInit(): void {
   
   }
   
   protected getState() {
      return {};
   }
   
   protected setState(state: any) {
      
   }
   
}
