import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate } from '@angular/common';
import { GatewayManagerComponent } from './gateway-manager.component';
import { InformationCategory } from '../../../../shared-components/information-category.enum';
import { centeredColumnDef, defaultTimestampFormat } from 'projects/shared-components/ag-grid-contrib';

export function getEventsGridModel(
  this: GatewayManagerComponent
): GridOptions {
  return {
    rowClass: 'ets-text-centered',
    
    rowData: [],
    
    defaultColDef: centeredColumnDef,    

    columnDefs: [
      {
        headerName: 'Timestamp',
        field: 'timestamp',
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value
            ? formatDate(params.value, defaultTimestampFormat, 'en-US')
            : '';
        },
        width: 160
      },
      {
        headerName: 'Category',
        field: 'category',
        valueFormatter: (params: ValueFormatterParams) => {
          return InformationCategory[params.value];
        },
        width: 90
      },
      {
        headerName: 'Source',
        field: 'source',
        width: 120
      },
      {
        headerName: 'Message',
        field: 'message',
        headerComponentParams: {},
        cellStyle: { 'text-align': 'left' },
        width: 600
      }
    ],
    
    rowSelection: 'single',

    rowModelType: 'clientSide',

    onGridReady: (args) => this.onGridReady(args),

    onGridSizeChanged: (args) => this.onGridSizeChanged(args),


    onRowDoubleClicked: (args) => {
      if (args.node) {
        this.selectedEvent = args.node.data;
        this.isMessagePopupVisible = true;  
      }
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [
        {
          name: 'Clear',
          action: () => this.clearEventsList()
        },
        'separator',
        'autoSizeAll',
        'copy',
        'export'
      ];
    }
  } as GridOptions;
}
