import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, GetAvailableBucketsReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetAvailableBuckets, BucketItemRelocateType as BucketItemRelocateType, BucketItemTransferSpec, BucketItemTransferType, BucketSpec, TransferBucket, TransferBucketItem } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from 'projects/shared-components/utils';
import { TransferStrategyCode } from '../move-to-portfolio-dialog/move-to-portfolio-dialog.model';
import { TransferBucketDialogConfig } from './transfer-bucket-dialog.model';

@Component({
   selector: 'ets-transfer-bucket-dialog',
   templateUrl: './transfer-bucket-dialog.component.html',
   styleUrls: ['./transfer-bucket-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class TransferBucketDialogComponent implements OnInit, AfterViewInit {

   constructor(
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef,
      private _sessionService: SessionService) {

   }

   private _availableBuckets: GetAvailableBucketsReply;
   
   //

   config: Partial<TransferBucketDialogConfig> = {};
   
   //

   terminalList: TerminalDto[] = [];
   
   //

   portfolioList: PortfolioDto[] = [];

   //
   
   comboList: ComboDto[] = [];

   //

   transferStrategyList: TransferStrategyCode[];

   //

   private _selectedTerminal: TerminalDto;
   get selectedTerminal(): TerminalDto { return this._selectedTerminal; }
   
   @DetectSetterChanges()
   set selectedTerminal(v: TerminalDto) {
      this._selectedTerminal = v;
   }

   //

   private _selectedPortfolio: PortfolioDto;
   get selectedPortfolio(): PortfolioDto { return this._selectedPortfolio; }
   
   @DetectSetterChanges()
   set selectedPortfolio(v: PortfolioDto) {
      this._selectedPortfolio = v;
   }

   //

   selectedTransferStrategy: TransferStrategyCode;

   //

   private _isLoading = false;
   get isLoading(): boolean { return this._isLoading; }

   @DetectSetterChanges()
   set isLoading(value: boolean) { this._isLoading = value; }

   //

   private _selectedCombo: ComboDto;
   get selectedCombo(): ComboDto { return this._selectedCombo; }
   
   @DetectSetterChanges()
   set selectedCombo(v: ComboDto) {
      this._selectedCombo = v;
   }
   
   //

   private _isVisible;
   get isVisible(): boolean { return this._isVisible; }

   @DetectSetterChanges()
   set isVisible(value: boolean) { this._isVisible = value; }

   //

   get canSetPortfolio(): boolean {
      return this.config.bucketType !== 'Portfolio';
   }

   //

   get canSetCombo(): boolean {
      return this.canSetPortfolio && this.config.bucketType !== 'Combo';
   }

   //

   ngOnInit() { }

   //

   ngAfterViewInit() { this._changeDetector.detach(); }

   //

   @DetectMethodChanges({isAsync: true})
   async show(config: TransferBucketDialogConfig) {

      this.isVisible = true;

      try {

         const qry = new GetAvailableBuckets();
         
         this._availableBuckets = await this._shellClient.processQuery<GetAvailableBucketsReply>(qry);

         this.terminalList = this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy).slice();
         
         this.config = config;

         this.transferStrategyList = ['With P&L', 'Without P&L'];

         if (config.originalTerminal) {
            const terminal = this.terminalList.find(x => x.terminalId === config.originalTerminal);
            this.selectedTerminal = terminal;
         }

         if (config.originalPortfolio) {
            const portfolio = this.portfolioList.find(x => x.portfolioId === config.originalPortfolio);
            this.selectedPortfolio = portfolio;
         }

         if (config.originalCombo) {
            const combo = this.comboList.find(x => x.comboId === config.originalCombo);
            this.selectedCombo = combo;
         }

      } finally {

         this.isLoading = false;

      }

   }

   //

   @DetectMethodChanges({isAsync: true})
   async onOkButtonClicked() {
      
      try {
         
         await this.transferBucket();

      } catch (e) {

         console.error(e);

         this._toastr.error('"Move To Portfolio" command completed with error');

      } finally {

         this.isVisible = false;

      }

   }

   //

   @DetectMethodChanges()
   onHidden() {
      this.config = {};

      this.selectedTransferStrategy = undefined;
      this.selectedTerminal = undefined;
      this.selectedPortfolio = undefined;
      this.selectedCombo = undefined;

      this.transferStrategyList = [];
      this.terminalList = [];
      this.portfolioList = [];
      this.comboList = [];

   }

   //
   
   @DetectMethodChanges()
   onTransferStrategyChanged(event: { value: TransferStrategyCode }) {

   }

   //

   @DetectMethodChanges()
   onTerminalSelected() {
      
      this.selectedPortfolio = null;
      
      this.selectedCombo = null;

      this.comboList = [];

      if (!this.selectedTerminal) {
         return;
      }

      const portfolios = this._availableBuckets.portfolios.filter(X => X.terminalId === this.selectedTerminal.terminalId);
      this.portfolioList = portfolios;
   }

   //

   @DetectMethodChanges()
   onPortfolioSelected() {

      this.selectedCombo = null;

      if (!this.selectedPortfolio) {
         return;
      }

      const portfolioCombos = this._availableBuckets.combos.filter( c => c.portfolioId === this.selectedPortfolio.portfolioId);
      this.comboList = portfolioCombos;
   }

   //

   private validateCommandIsCorrect(cmd: TransferBucket): string[] {
      const errors: string[] = [];

      if (isNullOrUndefined(cmd.bucketId)) {
         errors.push('Bucket ID is not set');
      }

      if (isNullOrUndefined(cmd.bucketType)) {
         errors.push('Bucket Type is not set');
      }


      if (isNullOrUndefined(cmd.transferSpec)) {
         errors.push('Transfer specification is not set');
      }


      switch (cmd.transferSpec.transferType) {
         case BucketItemTransferType.Unknown:
         case BucketItemTransferType.Include:
            {
               errors.push('Bad "Trasnfer Type" parameter');
            }
            break;

         case BucketItemTransferType.Relocate:
            {
               const error = this.validateRelocateTransferSpec(cmd.transferSpec);
               
               if (!isNullOrUndefined(error)) {
                  errors.push(error);
               }
            }
            break;

         case BucketItemTransferType.Archive:
         case BucketItemTransferType.Exclude:
         default:
            break;
      }

      return errors;
   }

   //

   private validateRelocateTransferSpec(transferSpec: BucketItemTransferSpec): string {
      
      const targetBucket = transferSpec.destinationBucketSpec;

      if (isNullOrUndefined(targetBucket)) {
         return 'Target Bucket Specification is mandatory';
      }

      if (isNullOrUndefined(targetBucket.terminalId)) {
         return 'Terminal Net Specified';
      }
   }

   //

   private transferBucket(): Promise<void> {
      
      const transferSpec: BucketItemTransferSpec = {
         transferType: BucketItemTransferType.Relocate
      };


      // Target Bucket Spec
      const targetBucketSpec: BucketSpec = {};
      
      if (this.selectedTerminal) {
         targetBucketSpec.terminalId = this.selectedTerminal.terminalId;
      }

      if (this.selectedPortfolio) {
         targetBucketSpec.portfolioId = this.selectedPortfolio.portfolioId;
      }
      
      if (this.selectedCombo) {
         if (this.selectedCombo.comboId === EtsConstants.emptyGuid) {
            targetBucketSpec.newComboName = this.selectedCombo.comboName;
         } else {
            targetBucketSpec.comboId = this.selectedCombo.comboId;
         }
      }

      transferSpec.destinationBucketSpec = targetBucketSpec;
   
      // Relocate Spec
      const relocateType = this.getRelocateType();

      transferSpec.relocateSpec = { relocateType };
   
      const cmd = new TransferBucket(this.config.bucketId, this.config.bucketType, transferSpec);

      const errors = this.validateCommandIsCorrect(cmd);

      if (errors.length > 0) {
         
         errors.forEach(x => this._toastr.error(x));
         
         return;
      }

      return this._shellClient.processCommand(cmd);
   }

   
   private getRelocateType(): BucketItemRelocateType {
      
      switch (this.selectedTransferStrategy) {
         case 'With P&L':
            return BucketItemRelocateType.WithPnL;
         
         case 'Without P&L':
            return BucketItemRelocateType.WithoutPnL;

         case 'Split':
            return BucketItemRelocateType.Split;
      
         default:
            break;
      }
   }
}
