import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {delay, DetectMethodChanges, findAtmStrikeIndex, isNullOrUndefined, isValidNumber, isVoid} from "../../../utils";
import {TradingInstrument} from "../../../trading-instruments/trading-instrument.class";
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import {OptionsChainService} from "../../../option-chains.service";
import {HedgePositionModel} from "./hedge-position.model";
import {HedgePositionsService} from "./hedge-positions.service";
import {TradingInstrumentsService} from "../../../trading-instruments/trading-instruments-service.interface";
import * as Enumerable from "linq";
import {HgAfterStatePreviewComponent} from "../after-state-preview/hg-after-state-preview.component";
import {MessageBusService} from "../../../message-bus.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HgOptionStrategyService, HgOptionStrategyConfig} from "../../hg-option-strategy.service";
import {ApplicationSettingsService} from "../../../app-settings/application-settings.service";
import {parseOptionTicker} from "../../../options-common/options.model";
import {LastQuoteCacheService} from "../../../last-quote-cache.service";
import {HedgePosition} from "../../data-model/hedge-position";
import {HgOriginalClientPositionsService} from "../portfolio-selector/hg-original-client-positions.service";
import {ToastrService} from "ngx-toastr";
import {
    GenericConfirmationDialogComponent
} from "../../../generic-confirmation-dialog/generic-confirmation-dialog.component";
import {ApgDataService} from "../../../adjustment-pricing-grid/services/apg-data.service";

interface HedgeGroup {
    groupLabel: string;
    groupColor: string;
    legs: HedgePositionModel[];
}

@Component({
    selector: 'ets-hg-hedge-positions',
    templateUrl: 'hg-hedge-positions.component.html',
    styleUrls: [
        './hg-hedge-positions.component.scss',
        '../../hedging-grid-common-styles.scss'
    ]
})

export class HgHedgePositionsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _messageBus: MessageBusService,
        private readonly _optionChainsService: OptionsChainService,
        private readonly _hgOptionStrategyService: HgOptionStrategyService,
        private readonly _apgDataService: ApgDataService,
        private readonly _appSettingsService: ApplicationSettingsService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _originalPositions: HgOriginalClientPositionsService,
        private readonly _toastr: ToastrService
    ) {
    }

    private _unsubscriber = new Subject();
    private _callLegsByGroup = undefined;
    private _putLegsByGroup = undefined;
    private _selectedPortfolio: ApgPortfolio;

    tradingInstrument: TradingInstrument;

    hedgePositions: HedgePositionModel[] = [];

    @ViewChild(HgAfterStatePreviewComponent)
    afterStatePreviewCmp: HgAfterStatePreviewComponent;

    isLoading: boolean;

    @ViewChild(GenericConfirmationDialogComponent)
    confirmationDialog: GenericConfirmationDialogComponent;

    get hasPortfolio(): boolean {
        return !isVoid(this._selectedPortfolio);
    }

    get hasLegs(): boolean {
        return this.hasPortfolio && !isVoid(this.hedgePositions);
    }

    get hasCalls(): boolean {
        return this.hasPortfolio && this.hedgePositions.some(x => x.type === 'Call');
    }

    get hasPuts(): boolean {
        return this.hasPortfolio && this.hedgePositions.some(x => x.type === 'Put');
    }

    ngOnInit() {

        this._messageBus.of('Hg.AfterStateUpdated')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this._changeDetector.detectChanges());

        this._messageBus.of<{ positions: HedgePosition[], slot: 'first' | 'second' }>('Hg.SaveAfterState')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe((x) => {
                this._hedgePositionsService.saveAfterStatePositions(
                    x.payload.positions,
                    x.payload.slot,
                    this._selectedPortfolio
                );
            });

        this._messageBus.of<{ positions: HedgePositionModel[] }>('Hg.SyncPositions')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onSyncPositions(x.payload));


        this._messageBus.of<{ state: 'former' | 'current', portfolio: ApgPortfolio }>('Hg.RefreshClientPositions')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(async x => {
                try {
                    this._messageBus.publish({
                        topic: 'Hg.Loading',
                        payload: {isLoading: true}
                    });

                    await this.onRefreshClientPositions(x.payload);

                } finally {
                    this._messageBus.publish({
                        topic: 'Hg.Loading',
                        payload: {isLoading: false}
                    });
                    this._changeDetector.detectChanges();
                }
            })
    }

    ngOnDestroy() {
        this._unsubscriber.next();
        this._unsubscriber.complete();
    }

    getLegGroups(side: 'Call' | 'Put'): { groupLabel: string, groupColor: string, legs: HedgePositionModel[] }[] {

        if (side !== 'Call' && side !== 'Put') {
            return;
        }

        let groupField = side === 'Call' ? this._callLegsByGroup : this._putLegsByGroup;

        if (isVoid(groupField)) {
            const grps = Enumerable.from(this.hedgePositions)
                .where(x => x.type === side)
                .groupBy(x => x.label)
                .select(x => {
                    const label = x.key();
                    const legs = x.toArray();
                    if (true) {
                        legs.sort((a, b) => {
                            const bStrike = typeof b.strike === 'string' ? parseFloat(b.strike) : b.strike;
                            const aStrike = typeof a.strike === 'string' ? parseFloat(a.strike) : a.strike;

                            return bStrike - aStrike;
                        });
                    }
                    return {
                        groupLabel: x.key(),
                        groupColor: legs[0].color,
                        legs: legs,
                        groupOrder: legs[0].groupOrder
                    };
                }).toArray();

            const sorted = grps
                .sort((grpA, grpB) => {

                    const grpAOrder = grpA.legs[0].groupOrder;
                    const grpBOrder = grpB.legs[0].groupOrder;

                    if (isValidNumber(grpAOrder) && isValidNumber(grpBOrder))  {
                        return grpAOrder - grpBOrder;
                    } else {
                        const lastStrikeA = grpA.legs[grpA.legs.length - 1].strike;
                        const lastStrikeB = grpB.legs[grpB.legs.length - 1].strike;
                        return lastStrikeB - lastStrikeA;
                    }
                });

            if (side === 'Call') {
                this._callLegsByGroup = groupField = sorted;
            } else {
                this._putLegsByGroup = groupField = sorted;
            }
        }

        return groupField;
    }

    getExpirationColorRank(data, position: any) {
        return undefined;
    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: ApgPortfolio) {

        this._selectedPortfolio = args;

        const hedgePositionModels = await this._hedgePositionsService
            .getPositionModels(args);

        if (!isVoid(hedgePositionModels)) {
            const underlying = hedgePositionModels[0].underlying;
            if (!isVoid(underlying)) {
                this.tradingInstrument = this._tiService.getInstrumentByTicker(underlying);
            }
        }

        this.hedgePositions = hedgePositionModels;

        this.refreshLegs('Calls & Puts');
    }

    @DetectMethodChanges({isAsync: true})
    async showAfterStatePreview(slot: 'first' | 'second') {

        if (isVoid(this._selectedPortfolio)) {
            return;
        }

        const positions = this._hedgePositionsService.getAfterState(slot, this._selectedPortfolio);

        if (isNullOrUndefined(positions)) {
            return;
        }

        let side: 'Calls' | 'Puts';

        if (!isVoid(positions)) {
            side = positions.some(x => x.type === 'Call')
                ? 'Calls'
                : 'Puts';
        } else {
            side = slot === 'second' ? 'Puts' : 'Calls';
        }

        try {

            await this.afterStatePreviewCmp.show({
                adjustmentDescriptor: side,
                positions: positions
            });

            if (side === 'Calls') {

                const models = await this._hedgePositionsService.convertToModels(positions)

                const newHedgePositions = this.hedgePositions
                    .filter(x => x.type === 'Put')
                    .concat(models);

                this.hedgePositions = newHedgePositions;

            } else {

                const models = await this._hedgePositionsService
                    .convertToModels(positions)

                const newHedgePositions = this.hedgePositions
                    .filter(x => x.type === 'Call')
                    .concat(models);

                this.hedgePositions = newHedgePositions;
            }

            this.refreshLegs(side);

            this._hedgePositionsService.clearAfterState(this._selectedPortfolio, slot);

            const currentHedgePositions = this.hedgePositions.map(x => x.asDto());

            await this._hedgePositionsService.saveHedgePositions(currentHedgePositions, this._selectedPortfolio);

            this._messageBus.publish({
                topic: 'Hg.AfterStateApplied',
                payload: {
                    side
                }
            });

        } catch {
            //
        }
    }

    hasAfterState(slot: 'first' | 'second') {
        if (isVoid(this._selectedPortfolio)) {
            return;
        }

        const afterState = this._hedgePositionsService
            .getAfterState(slot, this._selectedPortfolio);

        return !isNullOrUndefined(afterState);
    }

    hasAnyAfterState() {
        return this.hasAfterState('first') || this.hasAfterState('second');
    }

    @DetectMethodChanges({isAsync: true})
    async clearAfterState() {
        try {
            await this.confirmationDialog.show(['Are you sure you want to clear saved state?']);
        } catch {
            return;
        }
        this._hedgePositionsService.clearAfterState(this._selectedPortfolio);
    }

    @DetectMethodChanges({isAsync: true})
    async clearHedges() {

        try {
            await this.confirmationDialog?.show(['Are you sure you want to clear current hedges?']);
        } catch {
            return;
        }

        this._messageBus.publish({
            topic: 'Hg.Loading',
            payload: {
                isLoading: true
            }
        });

        try {

            this.hedgePositions = [];

            await this._hedgePositionsService
                .saveHedgePositions([], this._selectedPortfolio);

            this._messageBus.publish({
                topic: 'Hg.HedgesCleared',
                payload: {
                    portfolio: this._selectedPortfolio,
                }
            });

        } finally {
            this._messageBus.publish({
                topic: 'Hg.Loading',
                payload: {
                    isLoading: false
                }
            });
        }
    }

    @DetectMethodChanges({isAsync: true})
    async resetToDefaults() {

        if (this.hedgePositions.length > 0) {
            try {
                await this.confirmationDialog?.show(
                    [
                        'Are you sure you want to clear current hedges and load default ones?'
                    ]);
            } catch {
                return;
            }
        }

        this._messageBus.publish({
            topic: 'Hg.Loading',
            payload: {
                isLoading: true
            }
        });

        try {

            const positions = await this._apgDataService
                .getPortfolioPositions(this._selectedPortfolio);

            let underlying = this._appSettingsService.adjustmentPricingGrid.defaultUnderlying;

            if (!isVoid(positions)) {
                if (!isVoid(positions[0])) {
                    const ticker = positions[0][0].ticker;
                    const optTicker = parseOptionTicker(ticker);
                    underlying = optTicker.underlying;
                }
            }

            const multiplier = underlying === 'SPX' ? 10 : 1;

            const callSlingshotCfg: HgOptionStrategyConfig = {
                optionType: 'Calls',
                strategy: 'Slingshot',
                strikeOffset: 5 * multiplier,
                underlying: underlying,
                strikeWidth: 1 * multiplier,
                baseQty: 10,
                color: '#3f83ea'
            };

            const callSlingshot = await this._hgOptionStrategyService
                .makeOptionStrategy(callSlingshotCfg, this._optionChainsService, this._lastQuoteCache);

            const putSlingshotCfg: HgOptionStrategyConfig = {
                optionType: 'Puts',
                strategy: 'Slingshot',
                strikeOffset: 5 * multiplier,
                underlying: underlying,
                strikeWidth: 1 * multiplier,
                baseQty: 10,
                color: '#873434'
            };

            const putSlingshot = await this._hgOptionStrategyService
                .makeOptionStrategy(putSlingshotCfg, this._optionChainsService, this._lastQuoteCache);

            this.hedgePositions = callSlingshot.concat(putSlingshot);

            const currentHedgePositions = this.hedgePositions.map(x => x.asDto());
            await this._hedgePositionsService.saveHedgePositions(
                currentHedgePositions,
                this._selectedPortfolio,
            );

            this.refreshLegs('Calls & Puts');

            this._messageBus.publish({
                topic: 'Hg.InitialPositionsChanged',
                payload: {
                    portfolio: this._selectedPortfolio,
                    positions: currentHedgePositions
                }
            });

        } finally {
            this._messageBus.publish({
                topic: 'Hg.Loading',
                payload: {
                    isLoading: false
                }
            });
        }
    }

    @DetectMethodChanges()
    async removeHedge(pGroup: HedgeGroup, side: 'Call' | 'Put') {
        this.hedgePositions =
            this.hedgePositions.filter(x => pGroup.legs.indexOf(x) < 0);

        this.refreshLegs(side);

        const dtos = this.hedgePositions.map(x => x.asDto());

        await this._hedgePositionsService
            .saveHedgePositions(dtos, this._selectedPortfolio);

        this._messageBus.publish({
            topic: 'Hg.InitialPositionsChanged',
            payload: {
                portfolio: this._selectedPortfolio,
                positions: dtos
            }
        });
    }

    @DetectMethodChanges({isAsync: true})
    private async onSyncPositions(payload: { positions: HedgePositionModel[] }) {

        this._messageBus.publish({
            topic: 'Hg.Loading',
            payload: {isLoading: true}
        });

        await delay(250);

        try {

            const dtos = payload.positions
                .filter(x => !x.removed)
                .map(x => x.asDto());

            await this._hedgePositionsService.saveHedgePositions(
                dtos,
                this._selectedPortfolio,
            );

            const promises = dtos.map(x => HedgePositionModel.fromDto(x, this._optionChainsService));

            const positions = await Promise.all(promises);

            this.hedgePositions = positions;

            this.refreshLegs('Calls & Puts');

            this._messageBus.publish({
                topic: 'Hg.InitialPositionsChanged',
                payload: {
                    portfolio: this._selectedPortfolio,
                    positions: dtos
                }
            });

        } finally {

            this._messageBus.publish({
                topic: 'Hg.Loading',
                payload: {isLoading: false}
            });
        }
    }

    private refreshLegs(side: 'Call' | 'Put' | 'Calls & Puts' | 'Calls' | 'Puts') {
        if (side === 'Calls & Puts') {
            this._callLegsByGroup = undefined;
            this._putLegsByGroup = undefined;
        } else if (side.startsWith('C')) {
            this._callLegsByGroup = undefined;
        } else if (side.startsWith('P')) {
            this._putLegsByGroup = undefined;
        }
    }

    private async onRefreshClientPositions(payload: { state: "former" | "current", portfolio: ApgPortfolio }) {

        let positions: HedgePosition[];

        if (payload.state === 'former') {
            this._originalPositions.resetToSnapshotState(payload.portfolio);
        } else if (payload.state === 'current') {
            this._originalPositions.updateSnapshotStateWithCurrent(payload.portfolio);
        }

        positions = this._originalPositions.getTheirOriginalPositions(payload.portfolio);

        const promises = positions
            .map(async x => HedgePositionModel.fromDto(x, this._optionChainsService));

        const models = await Promise.all(promises);

        await this.onSyncPositions({positions: models});
    }
}