<dx-popup
  width="80%"
  height="51%"
  [showTitle]="true"
  title="Archived Positions"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let data of 'content'" class="alerts-container">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-balham-dark"
      [gridOptions]="positionsGridModel"
    ></ag-grid-angular>
  </div>
</dx-popup>