import { HpDatasetsBackendService } from 'projects/shared-components/hp-datasets/hp-datasets-backend-service.interface';
import { Injectable } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { HpDatasetDto } from 'projects/shared-components/shell-communication/dtos/hp-dataset-dto.class';
import { GetHpDatasets } from 'projects/shared-components/shell-communication/operations/strategies/get-hp-datasets.class';

@Injectable({providedIn: 'root'})
export class WebtraderHpDatasetsBackendService extends HpDatasetsBackendService {
  constructor(private readonly _shellService: ShellClientService) {
    super();
  }
  
  getDatasetsForStrategy(strategyId: string): Promise<HpDatasetDto[]> {
    const qry = new GetHpDatasets(strategyId);
    return this._shellService.processQuery<HpDatasetDto[]>(qry);
  }
}
