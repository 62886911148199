import { ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AccountDto } from '../shell-communication/dtos/account-dto.class';
import { TerminalDto } from '../shell-communication/dtos/terminal-dto.class';
import { ComboDto, ComboGroupDto, PortfolioDto } from '../shell-communication/shell-dto-protocol';
import { ALPHABET, ArrayWrapper, DxValueChanged, isNullOrUndefined, isTruthy } from '../utils';
import { MultiLegOrder } from './multi-leg-order/multi-leg-order.class';

export enum ActionType {
   Flat = 0,
   Buy = 1,
   Sell = -1
}

export enum LegType {
   Unknown = 0,
   Asset = 1,
   Option = 2,
   Separator = 3
}

export enum OrderType {
   Limit = 1,
   Market = 2,
   AutoLimit = 17
}

export enum OrderDuration {
   Unknown = 0,
   DAY = 1,
   GTC = 2,
}

//

export type OrderLinkType = 'OTO' | 'OCO';

export interface IMultiTradePadComponent {
   layoutTabId: string;
   isEmulationMode: boolean;
   availableAccounts: AccountDto[];
   availableTerminals: TerminalDto[];
   availablePortfolios: PortfolioDto[];
   availableCombos: ComboDto[];
   availableComboGroups: ComboGroupDto[];
   changeDetector: ChangeDetectorRef;
}

//

export interface OrderLegVerificationModel {
   symbol?: string;
   side?: string;
   totalQty?: number;
   effectiveQty?: number;
   terminal?: string;
   portfolio?: string;
   combo?: string;
   comboGroup?: string;
}

//
function referenceGridReadyWatcher(res, rej): void {
   let attempts = 0;
   const intRef = setInterval(() => {
      attempts++;
      if (!isNullOrUndefined(this.referenceGrid)) {
         clearInterval(intRef);
         res();
      } else {
         if (attempts >= 4 * 25) {
            clearInterval(intRef);
            rej();
         }
      }
   }, 50);
}

//

export class OrderDestination {

   constructor(
      private _orderComponent: MultiLegOrder,
      portfoliosList: PortfolioDto[] = [],
      comboList: ComboDto[] = [],
      comboGroupList: ComboGroupDto[] = []) {
         this.qty = 1;
         this.portfoliosList = portfoliosList;
         this.comboList = comboList;
         this.comboGroupList = comboGroupList;
   }


   referenceGridOpened = new EventEmitter<OrderDestination>();
   
   //
   referenceGrid: GridOptions;
   
   //
   private _portfoliosList: ArrayWrapper<PortfolioDto> = new ArrayWrapper<PortfolioDto>('portfolioName', 'portfolioId');
   get portfoliosList(): PortfolioDto[] {
      return this._portfoliosList.data;
   }
   set portfoliosList(v: PortfolioDto[]) {
      this._portfoliosList.setData(v);
   }

   //
   private _comboList: ArrayWrapper<ComboDto> = new ArrayWrapper<ComboDto>('comboName', 'comboId');
   get comboList(): ComboDto[] {
      return this._comboList.data;
   }
   set comboList(val: ComboDto[]) {
      this._comboList.setData(val);
   }

   //
   private _comboGroupsList: ArrayWrapper<ComboGroupDto> = new ArrayWrapper<ComboGroupDto>('comboGroupName', 'comboGroupId');
   get comboGroupList(): ComboGroupDto[] {
      return this._comboGroupsList.data;
   }
   set comboGroupList(val: ComboGroupDto[]) {
      this._comboGroupsList.setData(val);
   }


   hidden?: boolean;
   pressed?: boolean;
   letter?: string;
   qty?: number;
   account?: AccountDto;
   terminal?: TerminalDto;
   portfolio?: PortfolioDto;
   combo?: ComboDto;
   comboGroup?: ComboGroupDto;

   //

   get isEmpty(): boolean {
      return isNullOrUndefined(this.portfolio);
   }

   //

   onGridReady(event: GridReadyEvent) {
      if (!isNullOrUndefined(this.referenceGrid)) {
         return;
      }
      this.referenceGrid = event;
   }

   //

   addBucket(bucketType: string, bucket: any) {
      if (bucketType === 'Portfolio') {

         if (isTruthy(this.terminal) && this.terminal.terminalId === bucket.terminalId) {
            this._portfoliosList.insert(bucket);
         }

      } else if (bucketType === 'Combo') {

         if (isTruthy(this.portfolio) && this.portfolio.portfolioId === bucket.portfolioId) {
            this._comboList.insert(bucket);
         }


      } else if (bucketType === 'ComboGroup') {

         if (isTruthy(this.combo) && this.combo.comboId === bucket.comboId) {
            this._comboGroupsList.insert(bucket);
         }

      }

      this.detectChanges();
   }

   //

   removeBucket(bucketType: string, bucketId: string) {
      if (bucketType === 'Portfolio') {

         this._portfoliosList.remove(bucketId);

      } else if (bucketType === 'Combo') {

         this._comboList.remove(bucketId);

      } else if (bucketType === 'ComboGroup') {

         this._comboGroupsList.remove(bucketId);

      }
   }

   //
   onTerminalChanged(args: DxValueChanged<TerminalDto>) {
      if (this.portfolio) {
         if (isNullOrUndefined(args.value) ||
            (this.portfolio.terminalId !== args.value.terminalId)) {

            this.portfolio = null;
         }
      }

      this.setPortfolioListByTerminal(args.value);

      this._orderComponent.setTerminalForLegs(args.value);

      this.detectChanges();
   }

   //
   onPortfolioChanged(args: DxValueChanged<PortfolioDto>) {

      if (this.combo) {
         if (isNullOrUndefined(args.value) ||
            (this.combo.portfolioId !== args.value.portfolioId)) {

            this.combo = null;
         }
      }

      this.setComboListByPortfolio(args.value);

      this.detectChanges();
   }

   //
   onComboChanged(args: DxValueChanged<ComboDto>) {

      if (this.comboGroup) {
         if (isNullOrUndefined(args.value) ||
            (this.comboGroup.comboId !== args.value.comboId)) {

            this.comboGroup = null;
         }
      }

      this.setComboGroupListByCombo(args.value);

      this.detectChanges();
   }

   private setPortfolioListByTerminal(terminal: TerminalDto) {

      const terminalId = terminal ? terminal.terminalId : null;

      const filteredPortfolios = this._orderComponent.root.availablePortfolios.filter(x => x.terminalId === terminalId);

      this.portfoliosList = filteredPortfolios;
   }

   //
   private setComboListByPortfolio(portfolio: PortfolioDto) {

      const portfolioId = portfolio ? portfolio.portfolioId : null;

      const filteredCombos = this._orderComponent.root.availableCombos.filter(combo => combo.portfolioId === portfolioId);

      this.comboList = filteredCombos;
   }

   //
   private setComboGroupListByCombo(combo: ComboDto) {

      const comboId = combo ? combo.comboId : null;

      const filteredComboGroups = this._orderComponent.root.availableComboGroups.filter(comboGroup => comboGroup.comboId === comboId);

      this.comboGroupList = filteredComboGroups;
   }

   //
   private detectChanges() {
      this._orderComponent._changeDetector.detectChanges();
   }

   //
   async bucketReference_onOptionChanged(ev) {
      if (ev.name !== 'opened') {
         return;
      }

      if (ev.value) {
         
         if (!this.referenceGrid) {

            const gridWatcher = referenceGridReadyWatcher.bind(this);
            await new Promise(gridWatcher);
         }

         this.referenceGridOpened.emit(this);

      } else {

         this.referenceGrid.api.setRowData([]);

      }
   }

   //
   clone(): OrderDestination {
      const clone = new OrderDestination(this._orderComponent, this.portfoliosList, this.comboList, this.comboGroupList);
      
      clone.letter = this.letter;
      clone.hidden = this.hidden;
      clone.qty = this.qty;
      clone.account = this.account;
      clone.terminal = this.terminal;
      clone.portfolio = this.portfolio;
      clone.combo = this.combo;
      clone.comboGroup = this.comboGroup;

      return clone;
   }
}

//

export interface DestinationBucketDescriptor {
   terminal?: string;
   portfolio?: string;
   combo?: string;
   comboGroup?: string;
}

//

export interface MultiLegOrderVerificationModel {
   orderName?: string;
   destinationName?: string;
   root?: DestinationBucketDescriptor;
   legs: OrderLegVerificationModel[];
   account: string;
   terminal: string;
   duration: string;
   type: string;
   limitPx: string;
   autoLimitPx: string;
   marketValue: number;
   limitValue: number;
   totalQty: number;
   hasAttachment?: boolean;
   hasFutureSettings?: boolean;
   hasConvertSettings?: boolean;
}
