// tslint:disable:no-string-literal

import {formatNumber} from '@angular/common';
import {
    BodyScrollEvent,
    CellClassParams,
    CellClickedEvent,
    ColDef,
    ColGroupDef, ColumnMovedEvent, DragStoppedEvent,
    GetContextMenuItemsParams,
    GridOptions,
    ValueFormatterParams,
    ValueGetterParams
} from 'ag-grid-community';
import {
    centeredColumnDef,
    defaultLoadingOverlayTemplate,
    defaultPriceCellFormatter,
    defaultPriceCellFormatterWithDollarSign
} from '../ag-grid-contrib';
import {OptionType} from '../options-common/options.model';
import {isNullOrUndefined, isValidNumber, isVoid} from '../utils';
import {OptionsPricingGridComponentBase} from './options-pricing-grid-base.component';
import {PricingGridStrategy} from './model/pricing-grid.strategy';
import {
    CopyToEtsDestinationId,
    CopyToTOSDesktopDestinationId,
} from "../access-control-service.class";
import {PricingGridStrategyColumn} from "./model/pricing-grid-strategy.column";
import {PricingGridRow} from "./model/pricing-grid.row";
import * as Enumerable from "linq";

const minCellWidth = 90;

const maxCellWidth = 120;

export function getOptionsPricingGridModel(this: OptionsPricingGridComponentBase): GridOptions {
    const options: GridOptions = {};

    options.rowData = [];
    options.defaultColDef = centeredColumnDef;
    options.columnDefs = getEmptyGridTemplate(this);
    options.overlayLoadingTemplate = defaultLoadingOverlayTemplate;
    options.rowClass = 'ets-text-centered';
    options.rowSelection = null;
    options.suppressRowClickSelection = true;
    options.rowModelType = 'clientSide';
    options.immutableData = true;
    options.enableBrowserTooltips = true;
    options.getContextMenuItems = (params: GetContextMenuItemsParams) => {

        let isPricing = false;
        let isOption = false;
        let is3col = false;

        if (params.column) {
            const field = params.column.getColDef().field;
            isPricing = field.indexOf('pricing-') >= 0;
            isOption = field === 'callPrice' || field === 'putPrice';

            if (this.mode === 'pmd') {
                const col = params.column.getColId();
                is3col = col.startsWith('trans') || col.startsWith('own') || col.startsWith('out');
            }
        }

        const ctxMenu = [
            {
                name: 'Size Columns To Fit',
                disabled: false,
                action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
        ];

        ctxMenu.push('separator');

        if (this.mode === 'opg') {
            if (this.accessControlService.isSecureElementAvailable(CopyToEtsDestinationId)) {
                ctxMenu.push(
                    {
                        name: 'Copy To ETS',
                        disabled: !isPricing && !isOption,
                        action: () => this.copyOrders('ets', params)
                    }
                );
            }

            ctxMenu.push({
                name: 'Copy To Hedging Grid',
                disabled: !isPricing && !isOption,
                action: () => this.copyOrders('hedging-grid', params)
            });

            if (this.accessControlService.isSecureElementAvailable(CopyToTOSDesktopDestinationId)) {

                ctxMenu.push({
                    name: 'Copy To TOS',
                    disabled: !isPricing && !isOption,
                    action: () => this.copyOrders('tos', params)
                });

            }

        } else {
            if (!is3col && !isOption) {

                if (this.accessControlService.isSecureElementAvailable(CopyToEtsDestinationId)) {
                    ctxMenu.push(
                        {
                            name: 'Copy To ETS',
                            disabled: !isPricing && !isOption,
                            action: () => this.copyOrders('ets', params)
                        }
                    );
                }

                if (this.pmdHedgeMode === 'existing') {
                    ctxMenu.push({
                        name: 'Copy To Hedging Grid',
                        disabled: !isPricing && !isOption,
                        action: () => this.copyOrders('hedging-grid', params)
                    });
                }
            }
        }

        return ctxMenu;

    };
    options.onGridReady = (args) => this.onGridReady(args);
    options.getRowNodeId = (row) => row.rowStrike;
    options.onCellClicked = (args: CellClickedEvent) => this.onCellClicked(args);
    options.onBodyScroll = (args: BodyScrollEvent) => this.onBodyScrollEvent(args);

    let lastPricingColumnMoved : ColumnMovedEvent;

    options.onDragStopped = (event: DragStoppedEvent) => {

        if (isVoid(lastPricingColumnMoved)) {
            return;
        }

        const colId = lastPricingColumnMoved.column.getColId();

        if (!colId.startsWith('pricing')) {
            lastPricingColumnMoved = null;
            return;
        }

        const parts = colId.split('-');
        let strategyId = parts[1];
        const optionType = parts[parts.length - 1];

        if (parts.length > 3) {
           strategyId = Enumerable.from(parts).skip(1)
               .take(parts.length - 2)
               .toJoinedString('-');
        }

        let container: PricingGridStrategy[] = [];
        let targetSide: 'calls' | 'puts';
        if (optionType === 'call') {
            container = this.callStrategies;
            targetSide = 'calls';
        } else if (optionType === 'put') {
            container = this.putStrategies;
            targetSide = 'puts';
        }

        const ix = container.findIndex(x => x.strategyId === strategyId);
        if (ix < 0) {
            this.toastr.warning('Strategy column was not identified. Changes in columns order will not be saved');
            return;
        }

        const colGroup : ColGroupDef = lastPricingColumnMoved.api.getColumnDefs().find( (x : ColGroupDef) => x.groupId === targetSide ) as any;
        const strategyColumns = colGroup.children.filter( (x : ColDef) => x.colId.startsWith('pricing-'));
        const column = strategyColumns.find( (x : ColDef) => x.colId === colId) as ColDef;
        const indexInGrid = strategyColumns.indexOf(column);

        this.isLoading = true;

        let hadErrors = false;

        setTimeout(() => {
            try {

                let errorMsg = 'Column moved outside of pricing columns range. Changes in columns order won\'t  be saved';

                if (indexInGrid < 0) {
                    this.toastr.warning(errorMsg);
                    return;
                }

                if (indexInGrid > container.length) {
                    this.toastr.warning(errorMsg);
                    return;
                }

                const currentIndexInContainer = container.findIndex( (x) => x.strategyId === strategyId);

                const targetIndexInContainer = optionType === 'call' ? container.length - indexInGrid - 1 : indexInGrid;

                const deleted = container.splice(currentIndexInContainer, 1);

                container.splice(targetIndexInContainer, 0, deleted[0]);

            } catch {
                hadErrors = true;
            } finally {
                if (!hadErrors) {
                    if (this.canSaveTemplate(targetSide)) {
                        this.saveTemplate(targetSide)
                            .then(() => {})
                            .catch(() => {});
                    }
                }
                this.isLoading = false;
                lastPricingColumnMoved = null;
            }
        }, 1000);
    };

    options.onColumnMoved = (args : ColumnMovedEvent) => {

        lastPricingColumnMoved = args;

        return; // need to wait till drag is finished to handle event
    }

    return options;
}


export function getGridColumnDefinitions(comp: OptionsPricingGridComponentBase) {

    let colDefs = [];

    colDefs = getFullGridTemplate(comp);

    return colDefs;
}

function getFullGridTemplate(comp: OptionsPricingGridComponentBase): ColDef[] {
    const existingDefs = getEmptyGridTemplate(comp);

    let callChildren = [];

    let putChildren = [];

    const callDefs = existingDefs.find((x: any) => x.groupId === 'calls') as ColGroupDef
        || {children: []};

    const putDefs = existingDefs.find((x: any) => x.groupId === 'puts') as ColGroupDef
        || {children: []};

    callChildren = callDefs.children.map((x: ColDef) => {
        return {
            headerName: x.headerName,
            headerClass: x.headerClass,
            headerValueGetter: x.headerValueGetter,
            colId: x.colId,
            field: x.field,
            valueFormatter: x.valueFormatter,
            sortable: x.sortable,
            tooltipField: x.tooltipField,
            hide: x.hide,
            cellStyle: x.cellStyle,
            maxWidth: x.maxWidth,
            minWidth: x.minWidth,
            width: x.width
        } as ColDef;
    });

    putChildren = putDefs.children.map((x: ColDef) => {
        return {
            headerName: x.headerName,
            headerClass: x.headerClass,
            headerValueGetter: x.headerValueGetter,
            colId: x.colId,
            field: x.field,
            valueFormatter: x.valueFormatter,
            sortable: x.sortable,
            tooltipField: x.tooltipField,
            hide: x.hide,
            cellStyle: x.cellStyle,
            maxWidth: x.maxWidth,
            minWidth: x.minWidth,
            width: x.width
        } as ColDef;
    });

    const callColumns = comp.callStrategies.map(strategy => {

        const call = {
            headerValueGetter: (args) => strategy.isDirty ? `${strategy.strategyName}` : strategy.strategyName,
            headerClass: (args) => strategy.isDirty ? 'opg-difference' : undefined,
            colId: `pricing-${strategy.strategyId}-call`,
            field: `pricing-${strategy.strategyId}-call.price`,
            tooltipField: `pricing-${strategy.strategyId}-call.tooltip`,
            sortable: false,
            valueFormatter: defaultPriceCellFormatterWithDollarSign,
            cellStyle: getPricingColumnCellStyle(comp),
            valueGetter: getPricingRowValueGetter(comp, `pricing-${strategy.strategyId}-call`),
            minWidth: 90
        };

        return call;

    });

    const putColumns = comp.putStrategies.map(strategy => {

        const put = {
            headerValueGetter: (args) => strategy.isDirty ? `${strategy.strategyName}` : strategy.strategyName,
            headerClass: (args) => strategy.isDirty ? 'opg-difference' : undefined,
            colId: `pricing-${strategy.strategyId}-put`,
            field: `pricing-${strategy.strategyId}-put.price`,
            tooltipField: `pricing-${strategy.strategyId}-put.tooltip`,
            sortable: false,
            valueFormatter: defaultPriceCellFormatterWithDollarSign,
            cellStyle: getPricingColumnCellStyle(comp),
            valueGetter: getPricingRowValueGetter(comp, `pricing-${strategy.strategyId}-put`),
            minWidth: 90
        };
        return put;

    });

    callChildren.splice(2, 0, ...callColumns.reverse());

    putChildren.splice(3, 0, ...putColumns);

    const cols: (ColDef | ColGroupDef)[] = [
        {
            headerName: 'CALLS',
            groupId: 'calls',
            children: callChildren
        },

        {
            headerName: 'Strike',
            field: 'rowStrike',
            sort: 'desc',
            cellStyle(args: CellClassParams) {
                if (args.data.isAtm) {
                    return {
                        background: 'black',
                        color: 'gold',
                        'font-weight': '800'
                    };

                } else {
                    return {
                        background: 'cornflowerblue',
                        color: 'white',
                        'font-weight': '800'
                    };

                }
            },
            minWidth: minCellWidth
        },

        {
            headerName: 'PUTS',
            groupId: 'puts',
            children: putChildren
        }
    ];

    return cols;
}

function getPricingRowValueGetter(comp: OptionsPricingGridComponentBase, colId: string) {

    const f = function pricingRowValueGetter(args: ValueGetterParams): string {

        const row: PricingGridRow = args.data;

        if (!row) {
            return '';
        }

        if (!isValidNumber(comp.atmStrike, true)) {
            return '';
        }

        const col: PricingGridStrategyColumn = row[colId];

        if (!col) {
            return '';
        }

        const isCall = colId.endsWith('call');

        let isItm = true;

        if (isCall) {
            isItm = row.rowStrike < comp.atmStrike;
        } else {
            isItm = row.rowStrike > comp.atmStrike;
        }

        if (isItm && !comp.showItmData) {
            return '';
        }

        return col.price as any;
    };

    return f;
}

function getPricingColumnCellStyle(comp: OptionsPricingGridComponentBase) {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        const styleObj = {};

        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;


        if (!args.colDef.field) {
            return styleObj;
        }

        if (!args.colDef.field.startsWith('pricing')) {
            return styleObj;
        }

        const objAttributeName = args.colDef.field.split('.')[0];

        const data = args.data as PricingGridRow;
        const pricingCol = data[objAttributeName] as PricingGridStrategyColumn;

        if (!pricingCol) {
            return styleObj;
        }

        if (pricingCol.optionType === OptionType.Call) {
            if (isNullOrUndefined(args.data.callOtm)) {
                if (!comp.showItmData) {
                    return styleObj;
                }
            }
        }

        if (pricingCol.optionType === OptionType.Put) {
            if (isNullOrUndefined(args.data.putOtm)) {
                if (!comp.showItmData) {
                    return styleObj;
                }
            }
        }

        if (pricingCol.rMultiple) {
            styleObj['font-weight'] = '800';
        } else {
            styleObj['font-weight'] = 'normal';
        }

        if (pricingCol.payForIt) {
            styleObj['color'] = 'yellow';
        } else {
            styleObj['color'] = undefined;
        }

        if (pricingCol.percentageProfit) {
            styleObj['background-color'] = 'green';
        } else {
            styleObj['background-color'] = 'rgba(0,0,0,0)';
        }


        if (pricingCol.highlightColor) {
            styleObj['background-color'] = pricingCol.highlightColor;
            styleObj['font-weight'] = 'bold';
        }

        return styleObj;

    };

    return f;

}

function getEmptyGridTemplate(comp: OptionsPricingGridComponentBase): ColDef[] {

    const cols: ColDef[] = [
        {
            headerName: 'CALLS',
            groupId: 'calls',
            children: [
                {
                    headerName: '% OTM',
                    field: 'callOtm',
                    sortable: false,
                    valueFormatter: (args: ValueFormatterParams) => {
                        if (!args.value) {
                            return undefined;
                        }
                        return formatNumber(args.value * 100, 'en-US', '1.1-1') + '%';
                    },
                    maxWidth: minCellWidth,
                    minWidth: minCellWidth
                },
                {
                    headerName: '$ OTM',
                    field: 'callOtmPts',
                    sortable: false,
                    maxWidth: minCellWidth,
                    minWidth: minCellWidth
                },
                {
                    headerName: 'IV',
                    field: 'callIV',
                    sortable: false,
                    hide: true,
                    valueFormatter: (args: ValueFormatterParams) => {

                        if (!args.value) {
                            return '';
                        }

                        if (!comp.showItmData) {
                            const data: PricingGridRow = args.data;
                            if (isNullOrUndefined(data.callOtm)) {
                                return '';
                            }
                        }

                        return formatNumber(args.value * 100, 'en-US', '1.1-1') + '%';
                    },
                },
                {
                    headerName: 'Delta',
                    field: 'callDelta',
                    sortable: false,
                    hide: true,
                    valueFormatter: defaultPriceCellFormatter
                },
                {
                    headerName: 'Bid',
                    field: 'callBid',
                    sortable: false,
                    hide: true,
                    valueFormatter: defaultPriceCellFormatter
                },
                {
                    headerName: 'Ask',
                    field: 'callAsk',
                    sortable: false,
                    hide: true,
                    valueFormatter: defaultPriceCellFormatter
                },
                {
                    headerName: 'Own',
                    headerClass: 'pmd-column',
                    field: 'callPrice',
                    colId: 'own-call',
                    maxWidth: maxCellWidth,
                    minWidth: maxCellWidth,
                    hide: comp.mode === 'opg',
                    cellStyle: getOwnColumnCellStyle,
                    valueFormatter: getOwnCellFormattedValue,
                },
                {
                    headerName: 'Trans.',
                    headerClass: 'pmd-column',
                    field: 'callPrice',
                    colId: 'trans-call',
                    maxWidth: maxCellWidth,
                    minWidth: maxCellWidth,
                    hide: comp.mode === 'opg',
                    cellStyle: getTransColumnCellStyle,
                    valueFormatter: getTransCellFormattedValue,
                },
                {
                    headerName: comp.mode === 'opg' ? 'Price' : 'Outcome',
                    headerClass: comp.mode === 'pmd' ? 'pmd-column' : undefined,
                    field: 'callPrice',
                    colId: 'price-call',
                    sortable: false,
                    cellStyle: getPriceColumnCellStyle,
                    valueFormatter: getPriceCellFormattedValue,
                    maxWidth: maxCellWidth,
                    minWidth: maxCellWidth
                },
            ],
        } as Partial<ColDef>,

        {
            headerName: 'Strike',
            field: 'rowStrike',
            sort: 'desc',
            cellStyle(args: CellClassParams) {
                if (args.data.isAtm) {
                    return {
                        background: 'black',
                        color: 'gold',
                        'font-weight': '800'
                    };

                } else {
                    return {
                        background: 'cornflowerblue',
                        color: 'white',
                        'font-weight': '800'
                    };

                }
            },
            minWidth: minCellWidth
        },

        {
            headerName: 'PUTS',
            groupId: 'puts',
            children: [
                {
                    headerName: comp.mode === 'opg' ? 'Price' : 'Outcome',
                    headerClass: comp.mode === 'pmd' ? 'pmd-column' : undefined,
                    field: 'putPrice',
                    colId: 'price-put',
                    sortable: false,
                    cellStyle: getPriceColumnCellStyle,
                    valueFormatter: getPriceCellFormattedValue,
                    maxWidth: maxCellWidth,
                    minWidth: maxCellWidth
                },
                {
                    headerName: 'Trans.',
                    headerClass: 'pmd-column',
                    field: 'putPrice',
                    colId: 'trans-put',
                    maxWidth: maxCellWidth,
                    minWidth: maxCellWidth,
                    hide: comp.mode === 'opg',
                    cellStyle: getTransColumnCellStyle,
                    valueFormatter: getTransCellFormattedValue,
                },
                {
                    headerName: 'Own',
                    headerClass: 'pmd-column',
                    field: 'putPrice',
                    colId: 'own-put',
                    maxWidth: maxCellWidth,
                    minWidth: maxCellWidth,
                    hide: comp.mode === 'opg',
                    cellStyle: getOwnColumnCellStyle,
                    valueFormatter: getOwnCellFormattedValue,
                },
                {
                    headerName: 'Bid',
                    field: 'putBid',
                    sortable: false,
                    hide: true,
                    valueFormatter: defaultPriceCellFormatter
                },
                {
                    headerName: 'Ask',
                    field: 'putAsk',
                    sortable: false,
                    hide: true,
                    valueFormatter: defaultPriceCellFormatter
                },
                {
                    headerName: 'Delta',
                    field: 'putDelta',
                    sortable: false,
                    hide: true,
                    valueFormatter: defaultPriceCellFormatter
                },
                {
                    headerName: 'IV',
                    field: 'putIV',
                    sortable: false,
                    hide: true,
                    valueFormatter: (args: ValueFormatterParams) => {
                        if (!args.value) {
                            return '';
                        }

                        if (!comp.showItmData) {
                            const data: PricingGridRow = args.data;
                            if (isNullOrUndefined(data.putOtm)) {
                                return '';
                            }
                        }

                        return formatNumber(args.value * 100, 'en-US', '1.1-1') + '%';
                    },
                },
                {
                    headerName: '$ OTM',
                    field: 'putOtmPts',
                    sortable: false,
                    maxWidth: minCellWidth,
                    minWidth: minCellWidth
                },
                {
                    headerName: '% OTM',
                    field: 'putOtm',
                    sortable: false,
                    valueFormatter: (args: ValueFormatterParams) => {
                        if (!args.value) {
                            return undefined;
                        }
                        return formatNumber(args.value * 100, 'en-US', '1.1-1') + '%';
                    },
                    maxWidth: minCellWidth,
                    minWidth: minCellWidth
                }
            ]
        } as Partial<ColDef>,
    ];


    return cols;
}

function getPriceColumnCellStyle(args: CellClassParams) {

    const row = args.data as PricingGridRow;

    const style = {
        background: 'rgba(0,0,0,0)',
        color: null
    };

    if (args.colDef.colId === 'price-call') {

        if (row.isCallsInRange) {
            style.background = 'lemonchiffon';
            style.color = 'black';
        } else {
            style.background = 'rgba(0,0,0,0)';
            style.color = null;
        }

        if (row.callPriceHighlightColor) {
            style.background = row.callPriceHighlightColor;
            style.color = 'white';
        }
    } else if (args.colDef.colId === 'price-put') {

        if (row.isPutsInRange) {
            style.background = 'lemonchiffon';
            style.color = 'black';
        } else {
            style.background = 'rgba(0,0,0,0)';
            style.color = null;
        }

        if (row.putPriceHighlightColor) {
            style.background = row.putPriceHighlightColor;
            style.color = 'white';
        }
    }

    return style;
}

function getPriceCellFormattedValue(args: ValueFormatterParams) {

    if (!isValidNumber(args.value)) {
        return '';
    }

    let retval = args.value;

    try {

        retval = (args.value).toFixed(2);

    } catch {
        //
    }

    retval = '$' + retval;

    const row = args.data as PricingGridRow;

    if (args.colDef.field === 'callPrice') {
        if (!isNullOrUndefined(row.callPriceHighlightColor)) {
            if (isValidNumber(row.callPriceQty, true)) {
                const sign = row.callPriceQty > 0 ? '+' : '';
                retval += ` (${sign}${row.callPriceQty})`;
            }
        }
    }

    if (args.colDef.field === 'putPrice') {
        if (!isNullOrUndefined(row.putPriceHighlightColor)) {
            if (isValidNumber(row.putPriceQty, true)) {
                const sign = row.putPriceQty > 0 ? '+' : '';
                retval += ` (${sign}${row.putPriceQty})`;
            }
        }
    }

    return retval;
}

function getTransColumnCellStyle(args: CellClassParams) {

    const row = args.data as PricingGridRow;

    const style = {
        background: 'rgba(0,0,0,0)',
        color: null
    };

    if (args.colDef.colId === 'trans-call') {
        if (row.callTransPriceHighlightColor) {
            style.background = row.callTransPriceHighlightColor;
            style.color = 'white';
        }
    } else if (args.colDef.colId === 'trans-put') {
        if (row.putTransPriceHighlightColor) {
            style.background = row.putTransPriceHighlightColor;
            style.color = 'white';
        }
    }

    return style;
}

function getTransCellFormattedValue(args: ValueFormatterParams) {

    if (!isValidNumber(args.value)) {
        return '';
    }

    let retval = args.value;

    try {

        retval = (args.value).toFixed(2);

    } catch {
        //
    }

    retval = '$' + retval;

    const row = args.data as PricingGridRow;

    if (args.colDef.colId === 'trans-call') {
        if (!isNullOrUndefined(row.callTransPriceHighlightColor)) {
            if (isValidNumber(row.callTransQty, true)) {
                const sign = row.callTransQty > 0 ? '+' : '';
                retval += ` (${sign}${row.callTransQty})`;
            }
        }
    }

    if (args.colDef.colId === 'trans-put') {
        if (!isNullOrUndefined(row.putTransPriceHighlightColor)) {
            if (isValidNumber(row.putTransQty, true)) {
                const sign = row.putTransQty > 0 ? '+' : '';
                retval += ` (${sign}${row.putTransQty})`;
            }
        }
    }

    return retval;
}

function getOwnColumnCellStyle(args: CellClassParams) {

    const row = args.data as PricingGridRow;

    const style = {
        background: 'rgba(0,0,0,0)',
        color: null
    };

    if (args.colDef.colId === 'own-call') {
        if (row.callOwnPriceHighlightColor) {
            style.background = row.callOwnPriceHighlightColor;
            style.color = 'white';
        }
    } else if (args.colDef.colId === 'own-put') {
        if (row.putOwnPriceHighlightColor) {
            style.background = row.putOwnPriceHighlightColor;
            style.color = 'white';
        }
    }

    return style;
}

function getOwnCellFormattedValue(args: ValueFormatterParams) {

    if (!isValidNumber(args.value)) {
        return '';
    }

    let retval = args.value;

    try {

        retval = (args.value).toFixed(2);

    } catch {
        //
    }

    retval = '$' + retval;

    const row = args.data as PricingGridRow;

    if (args.colDef.colId === 'own-call') {
        if (!isNullOrUndefined(row.callOwnPriceHighlightColor)) {
            if (isValidNumber(row.callOwnQty, true)) {
                const sign = row.callOwnQty > 0 ? '+' : '';
                retval += ` (${sign}${row.callOwnQty})`;
            }
        }
    }

    if (args.colDef.colId === 'own-put') {
        if (!isNullOrUndefined(row.putOwnPriceHighlightColor)) {
            if (isValidNumber(row.putOwnQty, true)) {
                const sign = row.putOwnQty > 0 ? '+' : '';
                retval += ` (${sign}${row.putOwnQty})`;
            }
        }
    }

    return retval;
}
