// noinspection DuplicatedCode

import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CashFlowStrategy} from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import {LastQuoteCacheService} from 'projects/shared-components/last-quote-cache.service';
import {OptionsChainService} from 'projects/shared-components/option-chains.service';
import {OptionTicker, parseOptionTicker} from 'projects/shared-components/options-common/options.model';
import {
    GetOptionChainShellResponse,
    OptionExpirationDescriptor
} from 'projects/shared-components/shell-communication/shell-dto-protocol';
import {
    findAtmStrikeIndex,
    getNearestExpiration,
    isVoid,
    isReversedCashFlowOrder,
    isValidNumber,
    pickExpirationSmart, makeGuiFriendlyExpirationDate, getExpirationXBusinessDaysAfterDate
} from 'projects/shared-components/utils';
import {isNullOrUndefined} from 'util';
import {SavedPositionsService} from './saved-positions.service';
import {BeforePositionDto} from '../model/BeforePositionDto';
import {BeforePositionModel} from '../positions-section/model/BeforePositionModel';
import {PositionsData} from '../positions-section/model/PositionsData';
import {ServiceConfiguration} from './ServiceConfiguration';
import {
    ExpirationSmartModeSettings,
    IExpirationSmartModeSettings
} from 'projects/shared-components/app-settings/model/ExpirationSmartModeSettings';
import {DefaultApgPortfolioId} from '../model/ApgPortfolio';
import {SolutionPositionDto} from '../model/SolutionPositionDto';
import {UserSettingsService} from "../../user-settings.service";

export interface PositionsDefaultsProvider {
    first: PositionsDefaultSettings;
    second?: PositionsDefaultSettings;
}

export interface PositionsDefaultSettings {

    customAtm?: number;
    atmOffset: number;
    shortOptionDaysToExpiration: number;

    spreadOffset: number;
    spreadWidth: number;
    spreadDaysToExpiration: number;

    includeSecondSpread: boolean;
    secondSpreadWidth: number;
    secondSpreadOffset: number;
    secondSpreadDaysToExpiration: number;

    protectiveOptionOffset: number;
    protectiveOptionDaysToExpiration: number;
    protectiveOptionRollXDaysAfterShorts?: number;

    includeSecondProtectiveOption: boolean;
    secondProtectiveOptionOffset: number;
    secondProtectiveOptionDaysToExpiration: number;
    secondProtectiveOptionRollXDaysAfterShorts?: number;

    orderQty: number;
    protectiveOptionOverrideQty?: number;
    secondProtectiveOptionOverrideQty?: number;

    expirationSmartMode: IExpirationSmartModeSettings;
}

export interface GetPositionsDataRequest {
    underlying: string;
    strategyName: CashFlowStrategy;
    isDoubleMode: boolean;
    includeSpreadTop: boolean;
    includeSpreadBottom: boolean;
    includeSecondSpreadTop?: boolean;
    includeSecondSpreadBottom?: boolean;
    includeSecondProtectiveOptionTop?: boolean;
    includeSecondProtectiveOptionBottom?: boolean;
    chain?: GetOptionChainShellResponse;
    referenceStrike?: number;
    temporaryDefaults?: PositionsDefaultsProvider,
    isInitial?: boolean;
    forceDefaults?: boolean;
    // expirationSmartModeSettings: IExpirationSmartModeSettings,
    portfolioId: string,
    pairStrategy?: PositionsData
}

const BeforeStateDefaultsSingleKey = 'apg.before-state-defaults-single';
const BeforeStateDefaultsDoubleKey = 'apg.before-state-defaults-double';
const ComparisonBeforeStateDefaultsSingleKeyLeft = 'cpg.before-state-defaults-single.left';
const ComparisonBeforeStateDefaultsSingleKeyRight = 'cpg.before-state-defaults-single.right';
const ComparisonBeforeStateDefaultsDoubleKeyLeft = 'cpg.before-state-defaults-double.left';
const ComparisonBeforeStateDefaultsDoubleKeyRight = 'cpg.before-state-defaults-double.right';

const NoAssetKey = 'TICKER';

type SecondSpread = {
    longLeg: BeforePositionModel;
    shortLeg: BeforePositionModel;
};

@Injectable()
export class PositionsStateService {

    constructor(
        private readonly _optionChainsService: OptionsChainService,
        private readonly _toastr: ToastrService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _savedPositionsService: SavedPositionsService
    ) {

    }

    private _serviceConfig: ServiceConfiguration;

    //
    getDefaultsProvider(
        portfolioId: string,
        asset: string,
        type: 'single' | 'double'
    ): PositionsDefaultsProvider {

        if (isVoid(asset)) {
            asset = NoAssetKey;
            console.warn('no asset provided for get before state defaults');
        }

        const key = this.getStorageSettingsKey(portfolioId, asset, type);

        let defaultSettings = this._userSettingsService
            .getValue<PositionsDefaultsProvider>(key, this._serviceConfig?.userId);

        if (isVoid(defaultSettings)) {

            defaultSettings = {
                first: this.getPositionsDefaultSettings(asset),
            };

            if (type === 'double') {
                defaultSettings.second = this.getPositionsDefaultSettings(asset);
            }

        } else {

            this.upgradePositionsSettingsDefaultsModel(defaultSettings, asset);
        }

        // return copy to avoid accidental changes
        return JSON.parse(JSON.stringify(defaultSettings));
    }

    //
    async saveDefaultsProvider(
        portfolioId: string,
        asset: string,
        suggestedDefaults: PositionsDefaultsProvider
    ): Promise<void> {

        if (isVoid(asset)) {
            asset = NoAssetKey;
            console.warn('no asset provided for before state defaults ');
        }

        const bothSidesProvided = !isVoid(suggestedDefaults.first)
            && !isVoid(suggestedDefaults.second);

        const type = bothSidesProvided
            ? 'double'
            : 'single';

        let currentDefaults = this.getDefaultsProvider(portfolioId, asset, type);

        if (isNullOrUndefined(suggestedDefaults.first.shortOptionDaysToExpiration)) {
            suggestedDefaults.first.shortOptionDaysToExpiration =
                currentDefaults.first.shortOptionDaysToExpiration;
        }

        if (isNullOrUndefined(suggestedDefaults.first.secondSpreadDaysToExpiration)) {
            suggestedDefaults.first.secondSpreadDaysToExpiration =
                currentDefaults.first.secondSpreadDaysToExpiration;
        }

        if (isNullOrUndefined(suggestedDefaults.first.secondSpreadWidth)) {
            suggestedDefaults.first.secondSpreadWidth =
                currentDefaults.first.secondSpreadWidth;
        }

        if (isNullOrUndefined(suggestedDefaults.first.secondProtectiveOptionOffset)) {
            suggestedDefaults.first.secondProtectiveOptionOffset =
                currentDefaults.first.secondProtectiveOptionOffset;
        }

        if (suggestedDefaults.second) {

            if (isNullOrUndefined(suggestedDefaults.second.shortOptionDaysToExpiration)) {
                suggestedDefaults.second.shortOptionDaysToExpiration =
                    currentDefaults.second.shortOptionDaysToExpiration;
            }

            if (isNullOrUndefined(suggestedDefaults.second.secondSpreadDaysToExpiration)) {
                suggestedDefaults.second.secondSpreadDaysToExpiration =
                    currentDefaults.second.secondSpreadDaysToExpiration;
            }

            if (isNullOrUndefined(suggestedDefaults.second.secondSpreadWidth)) {
                suggestedDefaults.second.secondSpreadWidth =
                    currentDefaults.second.secondSpreadWidth;
            }

            if (isNullOrUndefined(suggestedDefaults.second.secondProtectiveOptionOffset)) {
                suggestedDefaults.second.secondProtectiveOptionOffset =
                    currentDefaults.second.secondProtectiveOptionOffset;
            }

            suggestedDefaults.second.expirationSmartMode =
                JSON.parse(JSON.stringify(currentDefaults.first.expirationSmartMode));
        }

        const key = type === 'single'
            ? this.getStorageSettingsKey(portfolioId, asset, 'single')
            : this.getStorageSettingsKey(portfolioId, asset, 'double');

        this._userSettingsService.setValue(
            key,
            suggestedDefaults,
            this._serviceConfig?.userId
        );
    }

    //
    async getPositionsState(
        parameters: GetPositionsDataRequest
    ): Promise<{ error: string, positions: PositionsData[] }> {

        let chain: GetOptionChainShellResponse;

        try {

            chain = await this._optionChainsService
                .getChain(parameters.underlying);

        } catch (e) {
            //
        }

        if (isNullOrUndefined(chain) || isVoid(chain.expirations)) {
            this._toastr.error(`"Load Option Chain" operation for
            ${parameters.underlying} completed with errors`);

            return {
                error: 'Options Chain is Not Available',
                positions: []
            };
        }

        parameters.chain = chain;

        let savedState: BeforePositionDto[][];
        let error: string;
        let positions: PositionsData[] = [];

        if (parameters.isInitial && !parameters.forceDefaults) {
            savedState = this.getSavedPositionsState(parameters);
        }

        if (isVoid(savedState)) {

            try {

                positions = await this.getDefaultPositionsState(parameters);

            } catch (e) {

                console.error(e);

                error = 'Failed to create default positions. Please close and re-open the panel you are working on';

            }

        } else {

            try {

                positions = this.restorePositionsStateFromSaved(parameters, savedState);

            } catch (e) {

                console.error(e);

                console.error('Saved state:', savedState);

                error = 'Failed to restore saved positions state. Default positions were loaded instead';
            }
        }

        return {
            error,
            positions
        };
    }

    //
    configure(cfg: ServiceConfiguration) {
        this._serviceConfig = cfg;
    }

    //
    async makeFromAfterState(positions: SolutionPositionDto[], underlying: string): Promise<BeforePositionModel[]> {

        if (isVoid(positions)) {
            return null;
        }

        // const bpDto = positions.map(dto => {
        //     const bpDto: BeforePositionDto = {
        //         ticker: dto.ticker,
        //         qty: dto.qty,
        //         role: dto.role,
        //         strategy: dto.strategy
        //     };
        //     return bpDto;
        // });

        const chain = await this._optionChainsService.getChain(underlying);

        const models = this.makeBeforePositionModelFromSolutionPositionDto(positions, chain);

        return models;
    }

    //
    private getSavedPositionsState(
        parameters: GetPositionsDataRequest
    ): BeforePositionDto[][] {

        const savedState = this._savedPositionsService.getPositions(
            parameters.portfolioId,
            parameters.underlying,
            parameters.strategyName
        );

        return savedState;
    }

    //
    private restorePositionsStateFromSaved(
        parameters: GetPositionsDataRequest,
        savedPositionsData: BeforePositionDto[][]
    ): PositionsData[] {

        const result = savedPositionsData
            .map(savedPositions => this.makeBeforePositionModelFromDto(savedPositions, parameters.chain));

        const positionsData = result.map(poses => {
            const pd: PositionsData = {
                isSavedState: true,
                positions: poses,
                strategy: poses[0].strategy
            };
            return pd;
        });

        return positionsData;
    }

    getOptionChain(underlying: string): Promise<GetOptionChainShellResponse> {
        return this._optionChainsService.getChain(underlying);
    }

    //
    makeBeforePositionModelFromDto(dtos: BeforePositionDto[], chain: GetOptionChainShellResponse): BeforePositionModel[] {

        if (dtos.length < 4) {
            throw new Error('Cannot recover saved positions');
        }

        const positionModels = dtos.map(pos => {

            if (isVoid(pos.ticker)) {
                throw new Error('Cannot recover saved positions');
            }

            if (isVoid(pos.role)) {
                throw new Error('Cannot recover saved positions');
            }

            const tickerObj = parseOptionTicker(pos.ticker);

            if (!this.validateOptionTicker(tickerObj)) {
                throw new Error('Cannot recover saved positions');
            }

            const expiration = chain.expirations
                .find(x => x.optionExpirationDate === tickerObj.expiration);

            let strike = undefined;

            if (!isVoid(expiration)) {

                strike = expiration.strikes.indexOf(tickerObj.strike) >= 0
                    ? tickerObj.strike
                    : undefined;

            }

            const positionModel = new BeforePositionModel(chain);
            positionModel.strategy = pos.strategy;
            positionModel.role = pos.role;
            positionModel.type = tickerObj.type;
            positionModel.qty = pos.qty;

            positionModel.selectedExpiration = expiration;
            positionModel.strike = strike;

            return positionModel;

        });

        const spreadLong = positionModels.find(x => x.role === 'SpreadLongLeg');

        const spreadShort = positionModels.find(x => x.role === 'SpreadShortLeg');

        if (spreadShort) {
            if (spreadLong) {
                spreadLong.addDependentPosition(spreadShort);
            }
        }

        const secondSpreadLong = positionModels.find(x => x.role === 'SecondSpreadLongLeg');
        const secondSpreadShort = positionModels.find(x => x.role === 'SecondSpreadShortLeg');
        if (secondSpreadShort) {
            if (secondSpreadLong) {
                secondSpreadLong.addDependentPosition(secondSpreadShort);
            }
        }

        return positionModels;

    }

    //
    makeBeforePositionModelFromSolutionPositionDto(dtos: SolutionPositionDto[], chain: GetOptionChainShellResponse): BeforePositionModel[] {

        if (dtos.length < 4) {
            throw new Error('Cannot recover saved positions');
        }

        const positionModels = dtos.map(dto => {

            if (isVoid(dto.role)) {
                throw new Error(`Cannot recover saved positions. Leg has no role: ${JSON.stringify(dto)}`);
            }

            const expiration = chain.expirations
                .find(x => makeGuiFriendlyExpirationDate(x.optionExpirationDate) === dto.expiration);

            let strike = undefined;

            if (!isVoid(expiration)) {

                strike = expiration.strikes.indexOf(dto.strike) >= 0
                    ? dto.strike
                    : undefined;

            }

            const positionModel = new BeforePositionModel(chain);
            positionModel.strategy = dto.strategy;
            positionModel.role = dto.role;
            positionModel.type = dto.type;
            positionModel.qty = dto.qty;

            positionModel.selectedExpiration = expiration;
            positionModel.strike = strike;

            return positionModel;

        });

        const spreadLong = positionModels.find(x => x.role === 'SpreadLongLeg');

        const spreadShort = positionModels.find(x => x.role === 'SpreadShortLeg');

        if (spreadShort) {
            if (spreadLong) {
                spreadLong.addDependentPosition(spreadShort);
            }
        }

        const secondSpreadLong = positionModels.find(x => x.role === 'SecondSpreadLongLeg');
        const secondSpreadShort = positionModels.find(x => x.role === 'SecondSpreadShortLeg');
        if (secondSpreadShort) {
            if (secondSpreadLong) {
                secondSpreadLong.addDependentPosition(secondSpreadShort);
            }
        }

        return positionModels;

    }


    //
    private validateOptionTicker(optionTicker: OptionTicker): boolean {
        if (isVoid(optionTicker)) {
            return false;
        }

        if (isVoid(optionTicker.underlying)) {
            return false;
        }

        if (isVoid(optionTicker.type)) {
            return false;
        }

        return !isVoid(optionTicker.expirationTicker);
    }

    //
    private getDefaultPositionsState(
        parameters: GetPositionsDataRequest
    ): Promise<PositionsData[]> {

        const type = (parameters.strategyName === 'Calls & Puts' || parameters.isDoubleMode)
            ? 'double'
            : 'single';

        let defaultsProvider = parameters.temporaryDefaults;

        const fallBackDefaultsProvider = this.getDefaultsProvider(
            parameters.portfolioId,
            parameters.underlying,
            type
        );

        if (isVoid(defaultsProvider)) {
            defaultsProvider = fallBackDefaultsProvider;
        } else {
            this.fillAbsentValuesInDefaults(defaultsProvider, fallBackDefaultsProvider);
        }

        switch (parameters.strategyName) {

            case 'Hedged Portfolio': {
                return this.hedgedPortfolioDefaultLegs(parameters, defaultsProvider);
            }
            case 'Calls': {
                return this.callsDefaultLegs(parameters, defaultsProvider);
            }
            case 'Puts': {
                return this.putsDefaultLegs(parameters, defaultsProvider);
            }
            case 'Reversed Hedged Portfolio': {
                return this.reversedHedgedPortfolioDefaultLegs(parameters, defaultsProvider);
            }
            case 'Calls & Puts': {
                return this.callsAndPutsDefaultLegs(parameters, defaultsProvider);
            }
            default:
                return Promise.resolve([]);
        }
    }

    //
    private async hedgedPortfolioDefaultLegs(
        request: GetPositionsDataRequest,
        defaultsProvider: PositionsDefaultsProvider
    ): Promise<PositionsData[]> {

        const positionsSettings = defaultsProvider!.first;

        return this.makeStrategyLegs(
            'Hedged Portfolio',
            request,
            positionsSettings
        );

    }

    //
    private async putsDefaultLegs(
        request: GetPositionsDataRequest,
        defaultsProvider: PositionsDefaultsProvider,
        contextLegs?: PositionsData
    ): Promise<PositionsData[]> {

        const isDoubleMode = request.strategyName === 'Calls & Puts'
            || request.isDoubleMode;

        const pairStrategy = contextLegs || request.pairStrategy;

        if (isDoubleMode) {
            console.assert(!isVoid(pairStrategy));
        }

        const positionsSettings = isDoubleMode
            ? defaultsProvider!.second
            : defaultsProvider!.first;

        return this.makeStrategyLegs(
            'Puts',
            request,
            positionsSettings,
            pairStrategy
        );
    }

    //
    private async callsDefaultLegs(
        request: GetPositionsDataRequest,
        defaultsProvider: PositionsDefaultsProvider
    ): Promise<PositionsData[]> {

        const positionsSettings = defaultsProvider!.first;

        return this.makeStrategyLegs(
            'Calls',
            request,
            positionsSettings
        );

    }

    //
    private async reversedHedgedPortfolioDefaultLegs(
        request: GetPositionsDataRequest,
        defaultsProvider: PositionsDefaultsProvider
    ): Promise<PositionsData[]> {

        const settings = defaultsProvider!.first;

        return this.makeStrategyLegs(
            'Reversed Hedged Portfolio',
            request,
            settings
        );

    }

    // Temporary defaults may have absent values because of incorrect leg configuration (e.g. 2nd spread)
    // To avoid errors in building positions, default values will be used
    private fillAbsentValuesInDefaults(defaultsProvider: PositionsDefaultsProvider, fallBackDefaultsProvider: PositionsDefaultsProvider) {

        function fill(target: PositionsDefaultSettings, source: PositionsDefaultSettings) {
            for (const k in source) {
                if (k === 'expirationSmartMode') {
                    const smartMode = JSON.parse(JSON.stringify(source.expirationSmartMode));
                    target.expirationSmartMode = smartMode;
                } else {
                    const v = target[k];
                    if (isNullOrUndefined(v)) {
                        target[k] = source[k];
                    }
                }
            }
        }

        if (defaultsProvider.first && fallBackDefaultsProvider.first) {
            fill(defaultsProvider.first, fallBackDefaultsProvider.first);
        }

        if (defaultsProvider.second && fallBackDefaultsProvider.second) {
            fill(defaultsProvider.second, fallBackDefaultsProvider.second);
        }
    }

    private async callsAndPutsDefaultLegs(
        request: GetPositionsDataRequest,
        defaultsProvider: PositionsDefaultsProvider
    ): Promise<PositionsData[]> {

        const calls = await this.callsDefaultLegs(request, defaultsProvider);

        console.assert(!isVoid(calls));

        const puts = await this.putsDefaultLegs(request, defaultsProvider, calls[0]);

        return [
            calls[0],
            puts[0],
        ];
    }

    private async makeStrategyLegs(
        strategy: CashFlowStrategy,
        request: GetPositionsDataRequest,
        positionsSettings: PositionsDefaultSettings,
        contextLegs?: PositionsData
    ): Promise<PositionsData[]> {

        const shortOption = await this.getShortOption(
            strategy,
            request,
            positionsSettings
        );

        const spreadLongLeg = await this.getSpreadLongLeg(
            strategy,
            request,
            positionsSettings,
            shortOption,
            contextLegs
        );

        const spreadShortLeg = await this.getSpreadShortLeg(
            strategy,
            request,
            positionsSettings,
            spreadLongLeg,
        );

        let secondSpreadLongLeg: BeforePositionModel;
        let secondSpreadShortLeg: BeforePositionModel;

        const secondSpread = await this.getSecondSpread(
            strategy,
            request,
            positionsSettings,
            spreadShortLeg || shortOption,
            contextLegs
        );

        if (!isVoid(secondSpread)) {
            secondSpreadLongLeg = secondSpread.longLeg;
            secondSpreadShortLeg = secondSpread.shortLeg;
        }

        const protectiveOption = await this.getProtectiveOption(
            strategy,
            request,
            positionsSettings,
            spreadShortLeg,
            secondSpreadShortLeg,
            contextLegs,
            shortOption
        );

        const secondProtectiveOption = await this.getSecondProtectiveOption(
            strategy,
            request,
            positionsSettings,
            protectiveOption,
            contextLegs,
            shortOption
        );

        //
        // Result
        //
        const positions: BeforePositionModel[] = [];

        positions.push(shortOption);

        if (spreadLongLeg) {
            positions.push(spreadLongLeg);
        }

        if (spreadShortLeg) {
            positions.push(spreadShortLeg);
        }

        if (secondSpreadLongLeg) {
            positions.push(secondSpreadLongLeg);
        }

        if (secondSpreadShortLeg) {
            positions.push(secondSpreadShortLeg);
        }

        positions.push(protectiveOption);

        if (secondProtectiveOption) {
            positions.push(secondProtectiveOption);
        }

        const isReversed = isReversedCashFlowOrder(strategy);

        if (isReversed) {
            positions.reverse();
        }

        const o = {
            strategy,
            positions
        } as PositionsData;

        return [o];
    }

    private async getShortOption(
        strategyName: CashFlowStrategy,
        request: GetPositionsDataRequest,
        settings: PositionsDefaultSettings
    ): Promise<BeforePositionModel> {

        console.assert(!isVoid(request.chain), 'chain must be available')

        const defaultsProvider
            = this.getDefaultsProvider(
            request.portfolioId,
            request.underlying,
            request.isDoubleMode ? 'double' : 'single'
        );

        let defaultSettings: PositionsDefaultSettings;

        if (request.isDoubleMode) {
            if (strategyName === 'Calls') {
                defaultSettings = defaultsProvider.first;
            } else if (strategyName === 'Puts') {
                defaultSettings = defaultsProvider.second;
            }
        } else {
            defaultSettings = defaultsProvider.first;
        }

        if (!isValidNumber(settings.atmOffset)) {
            settings.atmOffset
                = defaultSettings.atmOffset;
        }

        if (!isValidNumber(settings.shortOptionDaysToExpiration)) {
            settings.shortOptionDaysToExpiration
                = defaultSettings.shortOptionDaysToExpiration;
        }

        let shortOptionExpiration: OptionExpirationDescriptor;

        if (isVoid(request.temporaryDefaults)) {
            shortOptionExpiration = getNearestExpiration(
                settings.shortOptionDaysToExpiration,
                request.chain.expirations
            );
        } else {
            const tempDefaults = request.strategyName === 'Puts'
                ? request.temporaryDefaults.second
                : request.temporaryDefaults.first;
            const soDte = tempDefaults.shortOptionDaysToExpiration;
            shortOptionExpiration = request.chain.expirations.find(x => x.daysToExpiration === soDte);
        }


        let shortOptionStrike = request.referenceStrike;

        if (isVoid(shortOptionStrike)) {
            if (settings.customAtm) {
                shortOptionStrike = settings.customAtm;
            } else {
                shortOptionStrike = await this.getAtmStrike(
                    request.underlying,
                    shortOptionExpiration
                );

                shortOptionStrike = shortOptionStrike + (settings.atmOffset || 0);
            }
        }

        if (shortOptionExpiration.strikes.indexOf(shortOptionStrike) < 0) {
            shortOptionStrike = undefined;
        }

        const shortOptionLeg = new BeforePositionModel(request.chain);
        shortOptionLeg.strategy = strategyName;
        shortOptionLeg.role = 'ShortOption';

        switch (strategyName) {
            case 'Calls':
            case 'Hedged Portfolio':
                shortOptionLeg.type = 'Call';
                break;
            case 'Puts':
            case 'Reversed Hedged Portfolio':
                shortOptionLeg.type = 'Put';
                break;
            default:
                throw new Error('Unknown cash flow strategy type');
        }

        shortOptionLeg.qty = settings.orderQty * -1;
        shortOptionLeg.selectedExpiration = shortOptionExpiration;
        shortOptionLeg.strike = shortOptionStrike;

        return shortOptionLeg;
    }


    private async getSpreadLongLeg(
        strategyName: CashFlowStrategy,
        request: GetPositionsDataRequest,
        settings: PositionsDefaultSettings,
        shortOption: BeforePositionModel,
        contextLegs?: PositionsData
    ): Promise<BeforePositionModel> {

        if (request.isDoubleMode) {

            if (strategyName === 'Calls') {
                if (!request.includeSpreadTop) {
                    return null;
                }
            } else if (strategyName === 'Puts') {
                if (!request.includeSpreadBottom) {
                    return null;
                }
            }
        } else {
            if (!request.includeSpreadTop) {
                return null;
            }
        }

        const defaultsProvider
            = this.getDefaultsProvider(
            request.portfolioId,
            request.underlying,
            request.isDoubleMode ? 'double' : 'single'
        );

        let defaultSettings: PositionsDefaultSettings;

        if (request.isDoubleMode) {
            if (strategyName === 'Calls') {
                defaultSettings = defaultsProvider.first;
            } else if (strategyName === 'Puts') {
                defaultSettings = defaultsProvider.second;
            }
        } else {
            defaultSettings = defaultsProvider.first;
        }

        if (!isValidNumber(settings.spreadOffset)) {
            settings.spreadOffset
                = defaultSettings.spreadOffset;
        }

        if (!isValidNumber(settings.spreadDaysToExpiration)) {
            settings.spreadDaysToExpiration
                = defaultSettings.spreadDaysToExpiration;
        }

        let spreadExpiration: OptionExpirationDescriptor;

        /*
           Hack to quickly select same expiration if already was resolved for
           other strategy in double mode
        */
        if (request.isDoubleMode && !isVoid(contextLegs) && !isVoid(contextLegs.positions)) {

            const spreadLeg = contextLegs.positions.find(x => x.role === 'SpreadLongLeg');

            if (!isVoid(spreadLeg)) {
                spreadExpiration = spreadLeg.selectedExpiration;
            }

        } else if (!isVoid(request.temporaryDefaults)) {
            const tempDefaults = request.strategyName === 'Puts'
                ? request.temporaryDefaults.second
                : request.temporaryDefaults.first;

            const spreadDte = tempDefaults.spreadDaysToExpiration;

            spreadExpiration = request.chain.expirations.find(x => x.daysToExpiration === spreadDte);
        }


        let sign = isReversedCashFlowOrder(strategyName) ? -1 : 1;
        let strike = shortOption.strike - (settings.spreadOffset * sign);

        if (isVoid(spreadExpiration)) {

            if (settings.expirationSmartMode.spread.isEnabled) {

                const back = settings.expirationSmartMode.spread.back || 0;
                const forth = settings.expirationSmartMode.spread.forth || 0;

                spreadExpiration = pickExpirationSmart({
                    targetStrike: strike,
                    strikeStart: strike,
                    strikeRange: settings.spreadWidth,
                    strikeReverseDirection: isReversedCashFlowOrder(strategyName),
                    expirationRangeStart: settings.spreadDaysToExpiration - back,
                    expirationRangeEnd: settings.spreadDaysToExpiration + forth,
                    defaultDaysToExpiration: settings.spreadDaysToExpiration,
                    expirations: request.chain.expirations
                });

            } else {

                spreadExpiration = getNearestExpiration(
                    settings.spreadDaysToExpiration,
                    request.chain.expirations
                );

            }
        }

        console.assert(!isVoid(spreadExpiration));

        const spreadLongLeg = new BeforePositionModel(request.chain);
        spreadLongLeg.strategy = strategyName;
        spreadLongLeg.role = 'SpreadLongLeg';

        switch (strategyName) {
            case 'Calls':
            case 'Reversed Hedged Portfolio':
                spreadLongLeg.type = 'Call';
                break;
            case 'Puts':
            case 'Hedged Portfolio':
                spreadLongLeg.type = 'Put';
                break;
            default:
                throw new Error('Unknown cashflow strategy type');
        }

        spreadLongLeg.qty = settings.orderQty;

        spreadLongLeg.selectedExpiration = spreadExpiration;


        if (spreadExpiration.strikes.indexOf(strike) < 0) {
            strike = undefined;
        }
        spreadLongLeg.strike = strike;


        return spreadLongLeg;

    }


    private async getSpreadShortLeg(
        strategyName: CashFlowStrategy,
        request: GetPositionsDataRequest,
        settings: PositionsDefaultSettings,
        longLeg: BeforePositionModel
    ): Promise<BeforePositionModel> {

        if (request.isDoubleMode) {

            if (strategyName === 'Calls') {
                if (!request.includeSpreadTop) {
                    return null;
                }
            } else if (strategyName === 'Puts') {
                if (!request.includeSpreadBottom) {
                    return null;
                }
            }
        } else {
            if (!request.includeSpreadTop) {
                return null;
            }
        }

        const defaultsProvider
            = this.getDefaultsProvider(
            request.portfolioId,
            request.underlying,
            request.isDoubleMode ? 'double' : 'single'
        );

        let defaultSettings: PositionsDefaultSettings;

        if (request.isDoubleMode) {
            if (strategyName === 'Calls') {
                defaultSettings = defaultsProvider.first;
            } else if (strategyName === 'Puts') {
                defaultSettings = defaultsProvider.second;
            }
        } else {
            defaultSettings = defaultsProvider.first;
        }

        if (!isValidNumber(settings.spreadWidth)) {
            settings.spreadWidth
                = defaultSettings.spreadWidth;
        }

        const spreadShortLeg = new BeforePositionModel(request.chain);
        spreadShortLeg.strategy = longLeg.strategy;
        spreadShortLeg.role = 'SpreadShortLeg';
        spreadShortLeg.type = longLeg.type;
        spreadShortLeg.qty = settings.orderQty * -1;
        spreadShortLeg.selectedExpiration = longLeg.selectedExpiration;

        let sign = isReversedCashFlowOrder(strategyName) ? -1 : 1;

        let desiredStrike = longLeg.strike - (settings.spreadWidth * sign);

        if (longLeg.selectedExpiration.strikes.indexOf(desiredStrike) < 0) {
            desiredStrike = undefined;
        }

        spreadShortLeg.strike = desiredStrike;

        longLeg.addDependentPosition(spreadShortLeg);

        return spreadShortLeg;
    }


    private async getProtectiveOption(
        strategyName: CashFlowStrategy,
        request: GetPositionsDataRequest,
        settings: PositionsDefaultSettings,
        spreadShortLeg: BeforePositionModel,
        secondSpreadShortLeg: BeforePositionModel,
        contextLegs?: PositionsData,
        shortOption?: BeforePositionModel
    ): Promise<BeforePositionModel> {

        const defaultsProvider
            = this.getDefaultsProvider(
            request.portfolioId,
            request.underlying,
            request.isDoubleMode ? 'double' : 'single'
        );

        let defaultSettings: PositionsDefaultSettings;

        if (request.isDoubleMode) {
            if (strategyName === 'Calls') {
                defaultSettings = defaultsProvider.first;
            } else if (strategyName === 'Puts') {
                defaultSettings = defaultsProvider.second;
            }
        } else {
            defaultSettings = defaultsProvider.first;
        }

        if (!isValidNumber(settings.protectiveOptionDaysToExpiration)) {
            if (!isValidNumber(settings.protectiveOptionRollXDaysAfterShorts)) {
                settings.protectiveOptionDaysToExpiration
                    = defaultSettings.protectiveOptionDaysToExpiration;
            }
        }

        if (!isValidNumber(settings.protectiveOptionOffset)) {
            settings.protectiveOptionOffset
                = defaultSettings.protectiveOptionOffset;
        }

        const relativeLegForProtectiveOption: BeforePositionModel = secondSpreadShortLeg
            ? secondSpreadShortLeg
            : spreadShortLeg;

        let protectiveOptionExpiration: OptionExpirationDescriptor;

        if (request.isDoubleMode && !isVoid(contextLegs) && !isVoid(contextLegs.positions)) {

            const poLeg = contextLegs.positions.find(x => x.role === 'ProtectiveOption');

            if (!isVoid(poLeg)) {
                protectiveOptionExpiration = poLeg.selectedExpiration;
            }

        } else if (!isVoid(request.temporaryDefaults)) {
            const tempDefaults = request.strategyName === 'Puts'
                ? request.temporaryDefaults.second
                : request.temporaryDefaults.first;

            const poDte = tempDefaults.protectiveOptionDaysToExpiration;

            protectiveOptionExpiration = request.chain.expirations.find(x => x.daysToExpiration === poDte)
        }

        let sign = isReversedCashFlowOrder(strategyName) ? -1 : 1;

        let strike = relativeLegForProtectiveOption.strike -
            (settings.protectiveOptionOffset * sign);

        if (isVoid(protectiveOptionExpiration)) {

            if (isVoid(settings.protectiveOptionRollXDaysAfterShorts)) {
                if (settings.expirationSmartMode.protectiveOption.isEnabled) {

                    const back = settings.expirationSmartMode.protectiveOption.back || 0;
                    const forth = settings.expirationSmartMode.protectiveOption.forth || 0;

                    let range = settings.protectiveOptionOffset;
                    if (!isValidNumber(range, true)) {
                        range = request.underlying === 'SPX' ? 5 : 1;
                    }

                    protectiveOptionExpiration = pickExpirationSmart({
                        targetStrike: strike,
                        strikeStart: strike,
                        strikeRange: range,
                        strikeReverseDirection: isReversedCashFlowOrder(strategyName),
                        expirationRangeStart: settings.protectiveOptionDaysToExpiration - back,
                        expirationRangeEnd: settings.protectiveOptionDaysToExpiration + forth,
                        defaultDaysToExpiration: settings.protectiveOptionDaysToExpiration,
                        expirations: request.chain.expirations
                    });
                } else {
                    protectiveOptionExpiration = getNearestExpiration(
                        settings.protectiveOptionDaysToExpiration,
                        request.chain.expirations
                    );
                }

            } else {

                console.assert(!isVoid(shortOption), 'shortOption');

                protectiveOptionExpiration = getExpirationXBusinessDaysAfterDate(
                    settings.protectiveOptionRollXDaysAfterShorts,
                    shortOption?.selectedExpiration?.optionExpirationDate,
                    request.chain.expirations
                );

            }
        }

        const protectiveOptionLeg = new BeforePositionModel(request.chain);
        protectiveOptionLeg.strategy = strategyName;
        protectiveOptionLeg.role = 'ProtectiveOption';
        protectiveOptionLeg.type = relativeLegForProtectiveOption.type; // types of those legs always same
        protectiveOptionLeg.qty = settings.protectiveOptionOverrideQty || settings.orderQty;
        protectiveOptionLeg.selectedExpiration = protectiveOptionExpiration;


        if (protectiveOptionExpiration.strikes.indexOf(strike) < 0) {
            strike = undefined;
        }

        protectiveOptionLeg.strike = strike;

        return protectiveOptionLeg;
    }


    private async getSecondProtectiveOption(
        strategyName: CashFlowStrategy,
        request: GetPositionsDataRequest,
        settings: PositionsDefaultSettings,
        protectiveOption: BeforePositionModel,
        contextLegs?: PositionsData,
        shortOption?: BeforePositionModel
    ): Promise<BeforePositionModel> {

        const forceInclude = settings.includeSecondProtectiveOption && request.forceDefaults;

        if (!request.isDoubleMode) {
            if (!request.includeSecondProtectiveOptionTop && !forceInclude) {
                return undefined;
            }
        } else {
            if (
                !request.includeSecondProtectiveOptionTop &&
                !request.includeSecondProtectiveOptionBottom &&
                !forceInclude
            ) {
                return undefined;
            }

            if (strategyName === 'Calls' && !request.includeSecondProtectiveOptionTop && !forceInclude) {
                return undefined;
            }

            if (strategyName === 'Puts' && !request.includeSecondProtectiveOptionBottom && !forceInclude) {
                return undefined;
            }
        }

        const defaultsProvider
            = this.getDefaultsProvider(
            request.portfolioId,
            request.underlying,
            request.isDoubleMode ? 'double' : 'single'
        );

        let defaultSettings: PositionsDefaultSettings;

        if (request.isDoubleMode) {
            if (strategyName === 'Calls') {
                defaultSettings = defaultsProvider.first;
            } else if (strategyName === 'Puts') {
                defaultSettings = defaultsProvider.second;
            }
        } else {
            defaultSettings = defaultsProvider.first;
        }

        if (!isValidNumber(settings.secondProtectiveOptionDaysToExpiration)) {
            if (!isValidNumber(settings.secondProtectiveOptionRollXDaysAfterShorts)) {
                settings.secondProtectiveOptionDaysToExpiration
                    = defaultSettings.secondProtectiveOptionDaysToExpiration;
            }
        }

        if (!isValidNumber(settings.secondProtectiveOptionOffset)) {
            settings.secondProtectiveOptionOffset
                = defaultSettings.secondProtectiveOptionOffset;
        }


        let secondProtectiveOptionExpiration: OptionExpirationDescriptor;

        if (request.isDoubleMode && !isVoid(contextLegs) && !isVoid(contextLegs.positions)) {

            const poLeg = contextLegs.positions.find(x => x.role === 'SecondProtectiveOption');

            if (!isVoid(poLeg)) {
                secondProtectiveOptionExpiration = poLeg.selectedExpiration;
            }
        } else if (request.isDoubleMode && !isVoid(request.pairStrategy) && !isVoid(request.pairStrategy.positions)) {

            const poLeg = request.pairStrategy.positions.find(x => x.role === 'SecondProtectiveOption');

            if (!isVoid(poLeg)) {
                secondProtectiveOptionExpiration = poLeg.selectedExpiration;
            }

        } else if (!isVoid(request.temporaryDefaults)) {
            const tempDefaults = request.strategyName === 'Puts'
                ? request.temporaryDefaults.second
                : request.temporaryDefaults.first;

            const secondPoDte = tempDefaults.secondProtectiveOptionDaysToExpiration;

            secondProtectiveOptionExpiration = request.chain
                .expirations.find(x => x.daysToExpiration === secondPoDte)
        }

        let sign = isReversedCashFlowOrder(strategyName) ? -1 : 1;

        let strike = protectiveOption.strike -
            (settings.secondProtectiveOptionOffset * sign);


        if (isVoid(secondProtectiveOptionExpiration)) {

            if (!isValidNumber(settings.secondProtectiveOptionRollXDaysAfterShorts, true)) {

                if (settings.expirationSmartMode.secondProtectiveOption.isEnabled) {

                    const back = settings.expirationSmartMode.secondProtectiveOption.back || 0;
                    const forth = settings.expirationSmartMode.secondProtectiveOption.forth || 0;


                    let range = settings.secondProtectiveOptionOffset;
                    if (!isValidNumber(range, true)) {
                        range = request.underlying === 'SPX' ? 5 : 1;
                    }

                    secondProtectiveOptionExpiration = pickExpirationSmart({
                        strikeStart: protectiveOption.strike,
                        strikeRange: range,
                        strikeReverseDirection: isReversedCashFlowOrder(strategyName),
                        expirationRangeStart: settings.secondProtectiveOptionDaysToExpiration - back,
                        expirationRangeEnd: settings.secondProtectiveOptionDaysToExpiration + forth,
                        defaultDaysToExpiration: settings.secondProtectiveOptionDaysToExpiration,
                        expirations: request.chain.expirations,
                        targetStrike: strike
                    });

                } else {
                    secondProtectiveOptionExpiration = getNearestExpiration(
                        settings.secondProtectiveOptionDaysToExpiration,
                        request.chain.expirations
                    );
                }

            } else {

                console.assert(!isVoid(shortOption));

                secondProtectiveOptionExpiration = getExpirationXBusinessDaysAfterDate(
                    settings.secondProtectiveOptionRollXDaysAfterShorts,
                    shortOption?.selectedExpiration?.optionExpirationDate,
                    request.chain.expirations
                );
            }
        }

        const secondProtectiveOptionLeg = new BeforePositionModel(request.chain);
        secondProtectiveOptionLeg.strategy = strategyName;
        secondProtectiveOptionLeg.role = 'SecondProtectiveOption';
        secondProtectiveOptionLeg.type = protectiveOption.type; // types of those legs always same
        secondProtectiveOptionLeg.qty = settings.secondProtectiveOptionOverrideQty || settings.orderQty;
        secondProtectiveOptionLeg.selectedExpiration = secondProtectiveOptionExpiration;


        if (secondProtectiveOptionExpiration.strikes.indexOf(strike) < 0) {
            strike = undefined;
        }

        secondProtectiveOptionLeg.strike = strike;

        return secondProtectiveOptionLeg;
    }


    private async getSecondSpread(
        strategyName: CashFlowStrategy,
        request: GetPositionsDataRequest,
        settings: PositionsDefaultSettings,
        firstSpreadShortLeg: BeforePositionModel,
        contextLegs?: PositionsData
    ): Promise<SecondSpread | undefined> {

        const forceInclude = settings.includeSecondSpread && request.forceDefaults;

        if (!request.isDoubleMode) {
            if (!request.includeSecondSpreadTop && !forceInclude) {
                return undefined;
            }
        } else {
            if (
                !request.includeSecondSpreadTop &&
                !request.includeSecondSpreadBottom &&
                !forceInclude
            ) {
                return undefined;
            }

            if (strategyName === 'Calls' && !request.includeSecondSpreadTop && !forceInclude) {
                return undefined;
            }

            if (strategyName === 'Puts' && !request.includeSecondSpreadBottom && !forceInclude) {
                return undefined;
            }
        }

        const defaultsProvider: PositionsDefaultsProvider
            = this.getDefaultsProvider(
            request.portfolioId,
            request.underlying,
            request.isDoubleMode ? 'double' : 'single'
        );

        let defaultSettings: PositionsDefaultSettings;

        if (request.isDoubleMode) {
            if (strategyName === 'Calls') {
                defaultSettings = defaultsProvider.first;
            } else if (strategyName === 'Puts') {
                defaultSettings = defaultsProvider.second;
            }
        } else {
            defaultSettings = defaultsProvider.first;
        }

        if (!isValidNumber(settings.secondSpreadDaysToExpiration)) {
            settings.secondSpreadDaysToExpiration = defaultSettings.secondSpreadDaysToExpiration;
        }

        if (!isValidNumber(settings.secondSpreadWidth)) {
            settings.secondSpreadWidth = defaultSettings.secondSpreadWidth;
        }


        let secondSpreadExpiration: OptionExpirationDescriptor;

        if (request.isDoubleMode && !isVoid(contextLegs) && !isVoid(contextLegs.positions)) {

            const ssLongLeg = contextLegs.positions.find(x => x.role === 'SecondSpreadLongLeg');

            if (!isVoid(ssLongLeg)) {
                secondSpreadExpiration = ssLongLeg.selectedExpiration;
            }
        } else if (request.isDoubleMode && !isVoid(request.pairStrategy) && !isVoid(request.pairStrategy.positions)) {
            const ssLongLeg = request.pairStrategy.positions.find(x => x.role === 'SecondSpreadLongLeg');

            if (!isVoid(ssLongLeg)) {
                secondSpreadExpiration = ssLongLeg.selectedExpiration;
            }
        }
        else if (!isVoid(request.temporaryDefaults)) {
            const tempDefaults = request.strategyName === 'Puts'
                ? request.temporaryDefaults.second
                : request.temporaryDefaults.first;

            const spreadDte = tempDefaults.secondSpreadDaysToExpiration;

            secondSpreadExpiration = request.chain.expirations.find(x => x.daysToExpiration === spreadDte)
        }


        const isReversed = isReversedCashFlowOrder(strategyName);
        const sign = isReversed ? -1 : 1;
        let desiredSecondSpreadLongLegStrike = firstSpreadShortLeg.strike - (settings.secondSpreadOffset * sign);


        if (isVoid(secondSpreadExpiration)) {

            if (settings.expirationSmartMode.secondSpread.isEnabled) {

                const back = settings.expirationSmartMode.secondSpread.back || 0;
                const forth = settings.expirationSmartMode.secondSpread.forth || 0;

                secondSpreadExpiration = pickExpirationSmart({
                    targetStrike: desiredSecondSpreadLongLegStrike,
                    strikeStart: firstSpreadShortLeg.strike,
                    strikeRange: settings.secondSpreadWidth,
                    strikeReverseDirection: isReversedCashFlowOrder(strategyName),
                    expirationRangeStart: settings.secondSpreadDaysToExpiration - back,
                    expirationRangeEnd: settings.secondSpreadDaysToExpiration + forth,
                    defaultDaysToExpiration: settings.secondSpreadDaysToExpiration,
                    expirations: request.chain.expirations
                });

            } else {

                secondSpreadExpiration = getNearestExpiration(
                    settings.secondSpreadDaysToExpiration,
                    request.chain.expirations
                );

            }
        }

        let spreadType: 'Call' | 'Put';
        switch (strategyName) {
            case 'Hedged Portfolio':
            case 'Puts':
                spreadType = 'Put';
                break
            case 'Calls':
            case 'Reversed Hedged Portfolio':
                spreadType = 'Call';
                break
            default:
                throw new Error('Cannot determine 2nd spread type for strategy: ' + strategyName);
        }

        const secondSpreadLongLeg = new BeforePositionModel(request.chain);
        secondSpreadLongLeg.strategy = strategyName;
        secondSpreadLongLeg.role = 'SecondSpreadLongLeg';
        secondSpreadLongLeg.type = spreadType;
        secondSpreadLongLeg.qty = settings.orderQty;
        secondSpreadLongLeg.selectedExpiration = secondSpreadExpiration;

        if (secondSpreadExpiration.strikes.indexOf(desiredSecondSpreadLongLegStrike) < 0) {
            desiredSecondSpreadLongLegStrike = undefined;
        }

        secondSpreadLongLeg.strike = desiredSecondSpreadLongLegStrike;

        const secondSpreadShortLeg = new BeforePositionModel(request.chain);
        secondSpreadShortLeg.strategy = strategyName;
        secondSpreadShortLeg.role = 'SecondSpreadShortLeg';
        secondSpreadShortLeg.type = spreadType;
        secondSpreadShortLeg.qty = settings.orderQty * -1;
        secondSpreadShortLeg.selectedExpiration = secondSpreadExpiration;

        let desiredSecondSpreadShortLegStrike =
            secondSpreadLongLeg.strike - (settings.secondSpreadWidth * sign);

        if (secondSpreadExpiration.strikes.indexOf(desiredSecondSpreadShortLegStrike) < 0) {
            desiredSecondSpreadShortLegStrike = undefined;
        }
        secondSpreadShortLeg.strike = desiredSecondSpreadShortLegStrike;

        secondSpreadLongLeg.addDependentPosition(secondSpreadShortLeg);

        return {
            longLeg: secondSpreadLongLeg,
            shortLeg: secondSpreadShortLeg
        };
    }

    private async getAtmStrike(
        underlying: string,
        expiration: OptionExpirationDescriptor
    ): Promise<number> {

        const lastQuote = await this._lastQuoteCache.getLastQuoteWithAwait(underlying);

        const atmStrikeIx = findAtmStrikeIndex(expiration.strikes, lastQuote);

        if (atmStrikeIx < 0) {
            throw new Error('ATM strike index');
        }

        const atmStrike = expiration.strikes[atmStrikeIx];

        return atmStrike;
    }

    private getPositionsDefaultSettings(asset: string): PositionsDefaultSettings {

        const obj: PositionsDefaultSettings = {
            atmOffset: 0,
            shortOptionDaysToExpiration: 0,

            spreadOffset: 0,
            spreadWidth: 10,
            spreadDaysToExpiration: 44,

            includeSecondSpread: false,
            secondSpreadOffset: 0,
            secondSpreadWidth: 20,
            secondSpreadDaysToExpiration: 59,

            protectiveOptionOffset: 0,
            protectiveOptionDaysToExpiration: 4,
            protectiveOptionRollXDaysAfterShorts: null,

            includeSecondProtectiveOption: false,
            secondProtectiveOptionOffset: 1,
            secondProtectiveOptionDaysToExpiration: 5,
            secondProtectiveOptionRollXDaysAfterShorts: null,

            orderQty: 1,

            expirationSmartMode: new ExpirationSmartModeSettings()
        };

        if (asset === 'SPX') {
            obj.atmOffset *= 10;
            obj.spreadOffset *= 10;
            obj.spreadWidth *= 10;
            obj.secondSpreadWidth *= 10;
            obj.protectiveOptionOffset *= 10;
            obj.secondProtectiveOptionOffset *= 10;
        }

        return obj;
    }

    private getStorageSettingsKey(portfolioId: string, asset: string, type: 'single' | 'double') {

        let key: string = this.getStorageRootKey(type);

        if (portfolioId && (portfolioId !== DefaultApgPortfolioId)) {
            key += `.${portfolioId}`;
        }

        key += `.${asset}`;

        // if (this._serviceConfig.userId) {
        //     key += `${EtsConstants.storageKeys.userSeparator}${this._serviceConfig.userId}`;
        // }


        return key;
    }

    private getStorageRootKey(type: 'single' | 'double'): string {
        if (isVoid(this._serviceConfig.orientation)) {
            if (type === 'single') {
                return BeforeStateDefaultsSingleKey;
            } else if (type === 'double') {
                return BeforeStateDefaultsDoubleKey;
            }
        } else if (this._serviceConfig.orientation === 'left') {
            if (type === 'single') {
                return ComparisonBeforeStateDefaultsSingleKeyLeft;
            } else if (type === 'double') {
                return ComparisonBeforeStateDefaultsDoubleKeyLeft;
            }
        } else if (this._serviceConfig.orientation === 'right') {
            if (type === 'single') {
                return ComparisonBeforeStateDefaultsSingleKeyRight;
            } else if (type === 'double') {
                return ComparisonBeforeStateDefaultsDoubleKeyRight;
            }
        }

        throw new Error('Cannot determine root key for positions-before-state');
    }

    private upgradePositionsSettingsDefaultsModel(
        defaultSettings: PositionsDefaultsProvider,
        asset: string
    ): void {

        const currentDefaults = this.getPositionsDefaultSettings(asset);

        function updateDefaults(settings: PositionsDefaultSettings) {

            if (isNullOrUndefined(settings.atmOffset)) {
                settings.atmOffset = currentDefaults.atmOffset;
            }

            if (isNullOrUndefined(settings.orderQty)) {
                settings.orderQty = currentDefaults.orderQty;
            }

            if (isNullOrUndefined(settings.secondProtectiveOptionDaysToExpiration)) {
                if (isNullOrUndefined(settings.secondProtectiveOptionRollXDaysAfterShorts)) {
                    settings.secondProtectiveOptionDaysToExpiration =
                        currentDefaults.secondProtectiveOptionDaysToExpiration;
                }
            }

            if (isNullOrUndefined(settings.secondProtectiveOptionOffset)) {
                settings.secondProtectiveOptionOffset = currentDefaults.secondProtectiveOptionOffset;
            }

            if (isNullOrUndefined(settings.includeSecondSpread)) {
                settings.includeSecondSpread = currentDefaults.includeSecondSpread;
            }

            if (isNullOrUndefined(settings.includeSecondProtectiveOption)) {
                settings.includeSecondProtectiveOption = currentDefaults.includeSecondProtectiveOption;
            }

            if (isVoid(settings.expirationSmartMode)) {
                settings.expirationSmartMode = new ExpirationSmartModeSettings();
            }
        }

        if (defaultSettings.first) {
            updateDefaults(defaultSettings.first);
        }

        if (defaultSettings.second) {
            updateDefaults(defaultSettings.second);
        }
    }
}
