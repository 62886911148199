import {EventEmitter, Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CashFlowStrategy} from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import {SettingsStorageService} from 'projects/shared-components/settings-storage-service.service';
import {
    DefaultTemplateId,
    getCashFlowStrategySecureElementId,
    isDefaultTemplate,
    isVoid
} from 'projects/shared-components/utils';
import {
    CASH_FLOW_STRATEGY_SETTINGS_TEMPLATE_VERSION,
    ICashFlowAdjustmentSettingsTemplate
} from '../model/ICashFlowAdjustmentSettingsTemplate';
import {CashFlowStrategySettingsTemplateSet} from '../model/CashFlowStrategySettingsTemplateSet';
import {ICashFlowStrategySettings} from '../model/ICashFlowStrategySettings';
import * as shortid from 'shortid';
import {ApplicationSettingsService} from 'projects/shared-components/app-settings/application-settings.service';
import {ServiceConfiguration} from './ServiceConfiguration';
import {AccessControlService} from 'projects/shared-components/access-control-service.class';
import {EtsConstants} from 'projects/shared-components/ets-constants.const';
import {
    GetAssignedAdjustmentTemplates,
    RemoveAssignableAdjustmentTemplateShell,
    SaveAssignableAdjustmentTemplateShell
} from 'projects/shared-components/shell-communication/shell-operations-protocol';
import {GetAssignedAdjustmentTemplatesReply} from 'projects/shared-components/shell-communication/shell-dto-protocol';
import {ShellClientService} from 'projects/shared-components/shell-communication/shell-client.service';
import {ApgDefaultsService} from 'projects/shared-components/apg-defaults-dialog/apg-defaults.service';
import {MessageBusService} from "../../message-bus.service";
import {UserSettingsService} from "../../user-settings.service";

const LastUsedTemplateStorageKey = 'apg.last-used-template';
const ComparisonLastUsedTemplateStorageKeyLeft = 'cpg.last-used-template.left';
const ComparisonLastUsedTemplateStorageKeyRight = 'cpg.last-used-template.right';

const TemplatesStorageKey = 'apg.templates';
// const ComparisonTemplatesStorageKeyLeft = 'cpg.templates.left';
// const ComparisonTemplatesStorageKeyRight = 'cpg.templates.right';
//

export type TemplateSavedEventAgs = {
    template: ICashFlowAdjustmentSettingsTemplate;
    isNew: boolean;
};


export interface LastUsedTemplate {
    templateId: string;
    underlying: string;
    strategy: CashFlowStrategy;
    templateName: string;
}

export interface EditTemplateOptions {
    sharedStateChanged?: boolean;
}

@Injectable()
export class CashFlowStrategyTemplatesService {

    constructor(
        private readonly _userSettingsService: UserSettingsService,
        private readonly _toastr: ToastrService,
        private readonly _applicationSettingsService: ApplicationSettingsService,
        private readonly _accessControlService: AccessControlService,
        private readonly _shellService: ShellClientService,
        private readonly _apgDefaultsService: ApgDefaultsService,
        private readonly _messageBusService: MessageBusService
    ) {
    }

    private _index: ICashFlowAdjustmentSettingsTemplate[] = [];


    private _serviceConfig: ServiceConfiguration;


    templateSaved$ = new EventEmitter<TemplateSavedEventAgs>();
    templateRemoved$ = new EventEmitter<void>();


    get activeUserId(): string {
        return this._serviceConfig ? this._serviceConfig.userId : null;
    }


    async configure(cfg: ServiceConfiguration): Promise<void> {
        this._serviceConfig = cfg;
        await this.init();
    }


    getTemplates(): ICashFlowAdjustmentSettingsTemplate[] {
        return this._index.slice();
    }


    isDefaultTemplate(tpl: ICashFlowAdjustmentSettingsTemplate | string): boolean {
        return isDefaultTemplate(tpl);
    }


    canDeleteTemplate(tpl: ICashFlowAdjustmentSettingsTemplate): boolean {
        return !this.isDefaultTemplate(tpl) && !isVoid(tpl.templateId);
    }


    async saveTemplate(template: ICashFlowAdjustmentSettingsTemplate, opts: EditTemplateOptions = {}): Promise<boolean> {

        if (!template.templateName) {
            this._toastr.error('Please provide template name');
            return false;
        }

        if (!template.strategyName) {
            this._toastr.error('Please provide strategy name for the template', 'Settings Templates');
            return false;
        }

        if (isVoid(template.underlying)) {
            this._toastr.error('Please provide underlying for template', 'Settings Templates');
            return false;
        }

        const nameIx = this._index
            .findIndex(x => x.templateName === template.templateName);

        if (nameIx >= 0 && isVoid(template.templateId)) {
            // attempt to save new template (id is empty) with existing name
            this._toastr.error('Template with such name already exists', 'Settings Templates');
            return false;
        }

        let result;

        const isNew = isVoid(template.templateId);

        if (opts.sharedStateChanged) {
            await this.removeTemplate(template, opts.sharedStateChanged);
        }

        if (template.isShared) {
            result = await this.saveSharedTemplate(template, opts.sharedStateChanged);
        } else {
            result = this.saveLocalTemplate(template, opts.sharedStateChanged);
        }

        const ix = this._index.findIndex(x => x.templateId === template.templateId);

        if (ix < 0) {
            this._index.push(template);
        } else {
            this._index[ix] = template;
        }

        this.saveTemplatesToStore();

        this.templateSaved$.emit({
            template,
            isNew
        });

        this._messageBusService.publish({
            topic: 'Apg.TemplatesChanged',
            payload: {}
        })

        return result;

    }


    async removeTemplate(template: ICashFlowAdjustmentSettingsTemplate, sharedStateChanged: boolean): Promise<void> {

        if (template.isShared) {
            if (sharedStateChanged) {
                this.removeLocalTemplate(template);
            } else {
                await this.removeSharedTemplate(template);
            }
        } else {
            if (sharedStateChanged) {
                await this.removeSharedTemplate(template);
            } else {
                this.removeLocalTemplate(template);
            }
        }

        const ix = this._index.findIndex(x => x.templateId === template.templateId);

        if (ix >= 0) {
            this._index.splice(ix, 1);
        }

        this._messageBusService.publish({
            topic: 'Apg.TemplatesChanged',
            payload: {}
        })

    }


    getDefaultStrategySettingsObject(
        underlying: string
    ): ICashFlowStrategySettings[] {

        const defaults = this._apgDefaultsService.get(underlying);

        return defaults;
    }


    getOrCreateDefaultTemplate(
        underlying?: string,
        strategy?: CashFlowStrategy,
    ): ICashFlowAdjustmentSettingsTemplate {

        const apgSettings = this._applicationSettingsService.adjustmentPricingGrid;

        if (isVoid(strategy)) {
            strategy = apgSettings.defaultStrategy;
        }

        if (isVoid(underlying)) {
            underlying = apgSettings.defaultUnderlying
        }

        const defaultTemplateId = `${DefaultTemplateId}^${underlying}^${strategy}`;

        const settings: CashFlowStrategySettingsTemplateSet[] = [];

        const strategySecureId = getCashFlowStrategySecureElementId(strategy);

        if (!this._accessControlService.isSecureElementAvailable(strategySecureId)) {
            return null;
        }

        if (strategy === 'Calls & Puts') {

            const defaults = this.getDefaultStrategySettingsObject(underlying);

            const calls = defaults[0];

            calls.strategyName = 'Calls';
            const callsSet: CashFlowStrategySettingsTemplateSet = {
                strategyName: 'Calls',
                strategySettings: calls
            };

            settings.push(callsSet);

            const puts = defaults[1];
            puts.strategyName = 'Puts';
            const putsSet: CashFlowStrategySettingsTemplateSet = {
                strategyName: 'Puts',
                strategySettings: puts
            };

            settings.push(putsSet);

        } else {

            const defaultStrategySettingsObj = strategy === 'Puts'
                ? this.getDefaultStrategySettingsObject(underlying)[1]
                : this.getDefaultStrategySettingsObject(underlying)[0];

            defaultStrategySettingsObj.strategyName = strategy;

            const strategySet: CashFlowStrategySettingsTemplateSet = {
                strategyName: strategy,
                strategySettings: defaultStrategySettingsObj
            };

            settings.push(strategySet);
        }

        const templateName = `Default (${underlying}, ${strategy})`

        const obj: ICashFlowAdjustmentSettingsTemplate = {

            version: CASH_FLOW_STRATEGY_SETTINGS_TEMPLATE_VERSION,

            templateId: defaultTemplateId,

            underlying,

            templateName: templateName,

            strategyName: strategy,

            settings,

            globalSettings: {
                priceToDestination: true
            },

            expirationSettings: {
                expirationsToLookForward: 3
            }

        }

        const existingIndex = this._index.findIndex(x => x.templateId === defaultTemplateId);
        if (existingIndex >= 0) {
            this._index[existingIndex] = obj;
        } else {
            this._index.unshift(obj);
        }

        this.saveTemplatesToStore();

        return obj;
    }


    saveLastUsedTemplate(template: ICashFlowAdjustmentSettingsTemplate, portfolioId: string): boolean {

        if (isVoid(template)) {
            return false;
        }

        if (isVoid(portfolioId)) {
            return false;
        }

        const storageKey = this.getLastUsedTemplateStorageKey(portfolioId);

        const value: LastUsedTemplate = {
            templateId: template.templateId,
            underlying: template.underlying,
            strategy: template.strategyName,
            templateName: template.templateName
        };

        this._userSettingsService.setValue(
            storageKey,
            value,
            this._serviceConfig?.userId
        );

        return true;

    }


    getLastUsedTemplate(portfolioId: string): LastUsedTemplate {
        if (isVoid(portfolioId)) {
            return {} as any;
        }

        const storageKey = this.getLastUsedTemplateStorageKey(portfolioId);

        const tpl = this._userSettingsService.getValue<LastUsedTemplate>(
            storageKey,
            this._serviceConfig?.userId
        ) || {} as any;

        return tpl;
    }


    getTemplatesStorageKey(): string {

        console.assert(!isVoid(this._serviceConfig));

        let key = TemplatesStorageKey;

        return key;
    }


    getLastUsedTemplateStorageKey(portfolioId: string): string {

        console.assert(!isVoid(this._serviceConfig));

        let key = LastUsedTemplateStorageKey;

        if (this._serviceConfig.orientation === 'left') {
            key = ComparisonLastUsedTemplateStorageKeyLeft;
        }

        if (this._serviceConfig.orientation === 'right') {
            key = ComparisonLastUsedTemplateStorageKeyRight;
        }

        key = `${key}.${portfolioId}`;

        return key;
    }


    private upgradeTemplateIfNeeded(
        template: ICashFlowAdjustmentSettingsTemplate
    ): ICashFlowAdjustmentSettingsTemplate {

        if (isVoid(template)) {
            return;
        }

        if (template.version >= CASH_FLOW_STRATEGY_SETTINGS_TEMPLATE_VERSION) {
            return template;
        }

        // upgrade

        if (isVoid(template.underlying)) {
            template.underlying = 'SPY';
        }

        if (template.templateName.indexOf('Default') > 0) {
            if (!template.globalSettings.priceToDestination) {
                template.globalSettings.priceToDestination = true;
            }
        }

        if (isVoid(template.templateId)) {
            template.templateId = this.makeTemplateId(template, false);
        } else {
            if (template.templateId.split('^').length !== 3) {
                const isDefault = template.templateId.startsWith(DefaultTemplateId);
                template.templateId = this.makeTemplateId(template, isDefault);
            }
        }

        template.version = CASH_FLOW_STRATEGY_SETTINGS_TEMPLATE_VERSION;

        return template;

    }


    private shortNameCodeForStrategy(strategy: CashFlowStrategy): string {
        switch (strategy) {
            case 'Calls':
                return 'C';
            case 'Calls & Puts':
                return 'C&P';
            case 'Hedged Portfolio':
                return 'HP';
            case 'Puts':
                return 'P';
            case 'Reversed Hedged Portfolio':
                return 'rHP';
            default:
                return 'N/A';
        }
    }


    private saveTemplatesToStore(): boolean {

        this._index.sort((p, c) => {

            const pId = p.templateId || DefaultTemplateId;
            const cId = c.templateId || DefaultTemplateId;

            if (pId.startsWith(DefaultTemplateId)) {
                return 1
            }
            if (cId.startsWith(DefaultTemplateId)) {
                return 1;
            }

            return p.templateName.localeCompare(c.templateName);
        });

        const filtered = this._index.filter(x => !x.isShared && !this.isDefaultTemplate(x));

        const storageKey = this.getTemplatesStorageKey();

        this._userSettingsService.setValue(
            storageKey,
            filtered,
            this._serviceConfig?.userId
        );

        return true;
    }


    private async init(): Promise<void> {

        const storageKey = this.getTemplatesStorageKey();

        const templates = this._userSettingsService
            .getValue<ICashFlowAdjustmentSettingsTemplate[]>(
                storageKey, this._serviceConfig?.userId) || [];

        this._index = templates;

        if (this._index.length > 0) {

            const upgraded = this._index
                .filter(x => x.templateName !== 'Default')
                .map(x => this.upgradeTemplateIfNeeded(x))
                .filter(x => {
                    const id = getCashFlowStrategySecureElementId(x.strategyName);
                    return !isVoid(id) && this._accessControlService.isSecureElementAvailable(id);
                });

            this._index = upgraded;

        }

        // get assigned templates
        // const qry = new GetAssignedAdjustmentTemplates(this._serviceConfig.userId);

        // const reply = await this._shellService.processQuery<GetAssignedAdjustmentTemplatesReply>(qry)

        // const dtos = reply.templates;

        // if (isVoid(dtos)) {
        //     return;
        // }

        // const assignedTemplates = dtos.map(x => {
        //     const tpl = JSON.parse(x) as ICashFlowAdjustmentSettingsTemplate;
        //     tpl.isShared = true;
        //     return tpl;
        // });

        // const availableSharedTemplates = assignedTemplates.filter(x => {
        //     const id = getCashFlowStrategySecureElementId(x.strategyName);
        //     return !isVoid(id) && this._accessControlService.isSecureElementAvailable(id);
        // });
        //
        // this._index.push(...availableSharedTemplates);
    }


    private async removeSharedTemplate(template: ICashFlowAdjustmentSettingsTemplate): Promise<void> {
        const cmd = new RemoveAssignableAdjustmentTemplateShell(
            template.templateId
        );
        await this._shellService.processCommand(cmd);
    }


    private removeLocalTemplate(template: ICashFlowAdjustmentSettingsTemplate) {
        const ix = this._index.indexOf(template);

        if (ix < 0) {
            return;
        }

        this._index.splice(ix, 1);

        const storageKey = this.getTemplatesStorageKey();

        this._userSettingsService.setValue(
            storageKey,
            this._index,
            this._serviceConfig?.userId
        );
    }


    private saveLocalTemplate(template: ICashFlowAdjustmentSettingsTemplate, sharedStateChanged: boolean) {

        const isNew = isVoid(template.templateId) || sharedStateChanged;

        if (isNew) {

            template.templateId = this.makeTemplateId(template, false);

            const apgSettings = this._applicationSettingsService.adjustmentPricingGrid;

            if (apgSettings.prefixTemplates) {
                const shortStrategyName = this.shortNameCodeForStrategy(template.strategyName);
                template.templateName = `[${template.underlying}] [${shortStrategyName}] ${template.templateName}`;
            }

        } else {

            const ix = this._index.findIndex(x => x.templateId === template.templateId);

            if (ix < 0) {
                this._toastr.error('Template not found', 'Settings Templates');
                return;
            }

        }

        template.isModified = false;

        template.version = CASH_FLOW_STRATEGY_SETTINGS_TEMPLATE_VERSION;

        return true;
    }


    private async saveSharedTemplate(tpl: ICashFlowAdjustmentSettingsTemplate, sharedStateChanged: boolean): Promise<boolean> {

        try {

            const isNew = isVoid(tpl.templateId) || sharedStateChanged;

            if (isNew) {
                tpl.templateId = this.makeTemplateId(tpl, false);
            }

            const tplData = JSON.stringify(tpl);

            const cmd = new SaveAssignableAdjustmentTemplateShell(
                tpl.templateId,
                tplData
            );

            await this._shellService.processCommand(cmd);


            return true;

        } catch {

            return false;

        }
    }


    private makeTemplateId(tpl: ICashFlowAdjustmentSettingsTemplate, isDefault: boolean): string {
        const id = isDefault ? DefaultTemplateId : shortid.generate();

        const ul = tpl.underlying;

        console.assert(!isVoid(ul));

        const str = tpl.strategyName;

        console.assert(!isVoid(str));

        return `${id}^${ul}^${str}`
    }


    async clearLastUsedTemplate(portfolioId: string): Promise<void> {

        if (isVoid(portfolioId)) {
            return;
        }

        const storageKey = this.getLastUsedTemplateStorageKey(portfolioId);

        this._userSettingsService.deleteValue(storageKey);
    }
}
