import { ColDef, ColGroupDef, FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, RowGroupOpenedEvent, ValueFormatterParams } from "ag-grid-community";
import { CashFlowTrackingComponent } from "./cashflow-tracking.component";
import { DetailCellRendererParams, GetDetailRowDataParams, centeredColumnDef, defaultLoadingOverlayTemplate, defaultMoneyCellDefinition, defaultNumberCellFormatter, defaultPriceCellFormatter, defaultQuoteCellFormatter, defaultTimestampFormatNoMs } from "../ag-grid-contrib";
import { CashFlowTrackingRecordDto } from "../shell-communication/shell-dto-protocol";
import { CashFlowTrackingRecordModel } from "./CashFlowTrackingRecordModel";
import { CashFlowTrackingInfoCellRendererComponent } from "./cashflow-tracking-info-cell-renderer.component";

export function getCashFlowTrackingGridOptions(this: CashFlowTrackingComponent): GridOptions {
   
   const body = document.querySelector('body') as HTMLElement;

   const opts: GridOptions = {
      rowData: [],
      
      defaultColDef: centeredColumnDef,

      columnDefs: getColumnsDefinitions(this),
      
      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      masterDetail: true,

      detailRowAutoHeight: true,

      tooltipShowDelay: 1000,

      popupParent: body,

      frameworkComponents: {
         adjustmentSnapshotInfoCell: CashFlowTrackingInfoCellRendererComponent
      },

      detailCellRendererParams: {

         detailGridOptions: {

            defaultColDef: centeredColumnDef,

            rowClass: 'ets-text-centered',

            rowSelection: 'single',

            animateRows: true,

            popupParent: body,

            columnDefs: getDetailColumnsDefinitions(this),

            getRowNodeId: (data: CashFlowTrackingRecordDto) => {
               return data.cashFlowTrackingRecordId + '';
            },

            onFirstDataRendered: (args: FirstDataRenderedEvent) => {
               // args.columnApi.autoSizeAllColumns();
            },

            getContextMenuItems: (args: GetContextMenuItemsParams) => {
               return [
                  {
                     name: 'Size To Fit',
                     action: () => args.api.sizeColumnsToFit()
                  },
                  'autoSizeAll',
                  'copy',
                  'export'
               ];
            },
         },

         getDetailRowData: (args: GetDetailRowDataParams) => {
   
            const cashFlowRecordModel = args.data as CashFlowTrackingRecordModel;

            if (!cashFlowRecordModel) {
               return;               
            }

            const innerRecords = cashFlowRecordModel.getInnerRecords();
            args.successCallback(innerRecords);
         }

      } as DetailCellRendererParams,

      isRowMaster: (dataItem) => {
         const model = dataItem as CashFlowTrackingRecordModel;
         return model.getInnerRecords().length > 1;
      },

      onGridReady: args => this.onGridReady(args),

      onFirstDataRendered: (params) => {
         params.columnApi.autoSizeAllColumns();
      },

      getRowNodeId: (rowData: CashFlowTrackingRecordModel) => {
         return rowData.adjustmentOperationId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Show Details',
               action: () => {
                  this.showSolutionPopup(params.node.data);
               }
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export',
         ];
      },

      onRowGroupOpened: (args: RowGroupOpenedEvent) => {
         this.onHeightChanged();
      }
   
   };

   return opts;

}

function getColumnsDefinitions(comp: CashFlowTrackingComponent): (ColDef | ColGroupDef)[] {
   
   const reuslt = [];

   const expander: ColDef = {
      headerName: ' ',
      field: 'noField',
      valueGetter: (args) => {
         return '    ';
      },
      width: 8,
      cellRenderer: 'agGroupCellRenderer',
   }
   
   const dateTimeCol: ColDef = {
      headerName: 'Date',
      field: 'transactionDate',
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      filterParams: {
         browserDatePicker: true,
         filterOptions: ['inRange'],
         inRangeInclusive: true,  
      },
      valueFormatter: (params: ValueFormatterParams) => {
         const record: CashFlowTrackingRecordDto = params.data;
         const frmtTime = comp.timestampsService.getZonedFormattedDateTime(
            record.transactionDate,
            'ccc dd-MMM-yy HH:mm:ss'
         );
         return frmtTime;
      },
   };

   const infoCol: ColDef = {
      headerName: 'Info',
      field: 'info',
      cellRenderer: 'adjustmentSnapshotInfoCell'
   };

   const expectedPremium: ColDef = Object.assign({
      headerName: 'Debit/Credit',
      field: 'expectedPremium'
   }, defaultMoneyCellDefinition);

   const factPremium: ColDef = Object.assign({
      headerName: 'Fill Debit/Credit',
      field: 'factPremium',
   }, defaultMoneyCellDefinition);

   const underlyingPrice: ColDef = {
      headerName: 'Market',
      field: 'underlyingPrice',
      valueFormatter: defaultPriceCellFormatter,
   }

   const underlyingPriceChange: ColDef = Object.assign({
      headerName: 'Market Chg.',
      field: 'underlyingChange',
   }, defaultMoneyCellDefinition);

   const adjustmentId: ColDef = {
      headerName: 'Adjustment',
      field: 'adjustmentCode',
      filter: 'agSetColumnFilter',
      floatingFilter: true
   };

   const expiration: ColDef = {
      headerName: 'Expiration',
      field: 'expiration'
   };

   const dte: ColDef = {
      headerName: 'DTE',
      field: 'daysToExpiration'
   };

   const dteSeqNo: ColDef = {
      headerName: 'Exp.Seq.No',
      field: 'expirationSeqNo'
   };

   const slippage: ColDef = Object.assign({
      headerName: 'Slippage',
      field: 'slippage',
   }, defaultMoneyCellDefinition);

   const strategy: ColDef = {
      headerName: 'Strategy',
      field: 'strategy',
      filter: 'agSetColumnFilter',
      floatingFilter: true
   }

   const comboName: ColDef = {
      headerName: 'Combo',
      field: 'comboName',
      filter: 'agSetColumnFilter',
      floatingFilter: true
   }

   const runningTotal: ColDef = Object.assign({
      headerName: 'Total',
      field: 'runningTotal',

   }, defaultMoneyCellDefinition);
   
   const sessPnL: ColDef = Object.assign({
      headerName: 'Session P&L',
      field: 'sessionComboPnL',

   }, defaultMoneyCellDefinition);

   const accPnL: ColDef = Object.assign({
      headerName: 'Accum. P&L',
      field: 'accumulatedComboPnL',

   }, defaultMoneyCellDefinition);

   reuslt.push(expander);
   reuslt.push(dateTimeCol);
   reuslt.push(infoCol);
   reuslt.push(expectedPremium);
   reuslt.push(factPremium);
   reuslt.push(slippage);
   reuslt.push(runningTotal);
   reuslt.push(sessPnL);
   reuslt.push(accPnL);
   reuslt.push(underlyingPrice);
   reuslt.push(underlyingPriceChange);
   reuslt.push(comboName);
   reuslt.push(strategy);
   reuslt.push(adjustmentId);
   reuslt.push(expiration);
   reuslt.push(dte);
   reuslt.push(dteSeqNo);

   return reuslt;
}

function getDetailColumnsDefinitions(comp: CashFlowTrackingComponent): (ColDef | ColGroupDef)[] {
   
   const reuslt = [];
   
   const dateTimeCol: ColDef = {
      headerName: 'Date',
      field: 'transactionDate',
      valueFormatter: (params: ValueFormatterParams) => {
         const record: CashFlowTrackingRecordDto = params.data;
         const frmtTime = comp.timestampsService.getZonedFormattedDateTime(
            record.transactionDate,
            'hh:mm:ss a'
         );
         return frmtTime;
      },
   };

   const expectedPremium: ColDef =  Object.assign({
      headerName: 'Debit/Credit',
      field: 'expectedPremium'
   }, defaultMoneyCellDefinition);

   const factPremium: ColDef = Object.assign({
      headerName: 'Fill Debit/Credit',
      field: 'factPremium',
   }, defaultMoneyCellDefinition);

   const slippage: ColDef = Object.assign({
      headerName: 'Slippage',
      field: 'slippage',
   }, defaultMoneyCellDefinition);

   const underlyingPrice: ColDef = {
      headerName: 'Market',
      field: 'underlyingPrice',
      valueFormatter: defaultQuoteCellFormatter
   }

   const pricingOption: ColDef = {
      headerName: 'Pricing Option',
      field: 'pricingOption',
   }

   const orderRole: ColDef = {
      headerName: 'Order Role',
      field: 'orderRole'
   }


   reuslt.push(dateTimeCol);
   reuslt.push(expectedPremium);
   reuslt.push(factPremium);
   reuslt.push(slippage);
   reuslt.push(underlyingPrice);
   reuslt.push(pricingOption);
   reuslt.push(orderRole);

   return reuslt;
}
