<dx-popup   class="popup"
            [width]="345"
            [height]="125"
            [showTitle]="true"
            [title]="this.title"
            [closeOnOutsideClick]="false"
            [dragEnabled]="true"
            [visible]="this.visible"
            [showCloseButton]="false"
            (onInitialized)="this.onInitialized($event)"
            (onHidden)="this.onHidden()"
>
  <div *dxTemplate="let data of 'content'" class="wrapper">
    
    <div class="block side current">
<!--      <div class="atm">{{this.currentAtm}}</div>-->
      <div class="button">
        <dx-button text="Use Market ATM" (onClick)="this.useMarketAtmClicked()"></dx-button>
      </div>
    </div>
    
    <div class="block splitter">|</div>
    
    <div class="block side custom">
      <div class="input">
        <dx-number-box [(value)]="this.overrideAtm" [inputAttr]="{style: 'text-align: center; font-size: 1.2em'}">
        </dx-number-box>
      </div>
      <div class="button">
        <dx-button text="Use This ATM" (onClick)="this.useCustomAtmClicked()"></dx-button>
      </div>
    </div>
    
  </div>
</dx-popup>