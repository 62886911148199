import { Injectable, Inject } from '@angular/core';
import { IDatafeedChartApi } from './data-feed-chart-api';
import { PriceDataFeed } from './price-data-feed';
import { ToastrService } from 'ngx-toastr';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { 
  TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { PriceDataFeedBackendService } from './price-data-feed-backend-service.class';
import { LoggerService } from '../logging/logger-factory.service';
import { TimestampsService } from '../timestamps.service';

@Injectable({ providedIn: 'root' })
export class PriceDataFeedFactoryService {
  constructor(
    private _backendService: PriceDataFeedBackendService,
    private _messageBus: MessageBusService,
    private _tradingInstruments: TradingInstrumentsService,
    private _toastr: ToastrService,
    private _loggerService: LoggerService
  ) {
  }

  public getDataFeed(componentId: string, chart: () => any, dataErrorCallback: () => void): IDatafeedChartApi {
    const priceDataFeedService = new PriceDataFeed(
      componentId,
      this._backendService,
      this._messageBus,
      this._tradingInstruments,
      this._toastr,
      chart,
      dataErrorCallback,
      this._loggerService
    );
    priceDataFeedService.init();
    return priceDataFeedService;
  }
}
