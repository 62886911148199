import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from '../../../symbol-picker/symbol-picker.component';
import { ParametersViewBase } from '../parameters-view-base';
import { BullBearAlgoParametersControl } from './bullbear-algo-parameters-control';

@Component({
   selector: 'ets-bullbear-parameters-view',
   templateUrl: 'bullbear-parameters-view.component.html',
   styleUrls: ['bullbear-parameters-view.component.scss']
})
export class BullBearParametersViewComponent extends ParametersViewBase<BullBearAlgoParametersControl> {

   constructor(cd: ChangeDetectorRef) {
      super(cd);
   }

   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;

   onEtsInit() {
   }
   
   onEtsAfterViewInit() {
   }

}
