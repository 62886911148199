import { ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { PositionSizingRecordDto } from '../shell-communication/shell-dto-protocol';
import { isNullOrUndefined } from '../utils';

@Component({
   selector: 'ets-psr-profit-cell',
   template: `
      <div *ngIf="this.isBucketRecord">
         <span [ngClass]="
            {
               'ets-positive-number': strategyProfit > 0 && !isPassiveRecord, 
               'ets-negative-number': strategyProfit < 0 && !isPassiveRecord,
               'passive': isPassiveRecord
            }">{{strategyProfit | currency:'USD':'symbol':'1.2-2' }}</span> 
         / 
         <span [ngClass]="{'ets-positive-number': bucketProfit > 0, 'ets-negative-number': bucketProfit < 0}">{{bucketProfit | currency:'USD':'symbol':'1.2-2' }}</span>
      </div>

      <div *ngIf="!this.isBucketRecord" [ngClass]="{'ets-positive-number': strategyProfit > 0, 'ets-negative-number': strategyProfit < 0}">
         {{strategyProfit | currency:'USD':'symbol':'1.2-2' }}
      </div>
   `,
   styles: [`
      .passive {
         color: grey;
      }
   `]
})
export class ProfitCellRendererComponent implements ICellRendererAngularComp {
   
   constructor(private readonly _changeDetector: ChangeDetectorRef) {
      
   }

   bucketProfit: number;
   strategyProfit: number;
   isPassiveRecord: boolean;

   get isBucketRecord(): boolean {
      return !isNullOrUndefined(this.bucketProfit);
   }


   refresh(params: any): boolean {
      return false;
   }
   
   agInit(params: ICellRendererParams): void {
      const record = params.data as PositionSizingRecordDto;
      if (isNullOrUndefined(record)) {
         return;
      }

      this.bucketProfit = record.bucketProfit;
      this.strategyProfit = record.profit;
      this.isPassiveRecord = record.isPassiveRecord;
   }
}
