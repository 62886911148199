import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class ExecuteCustomAlgoOperation implements ProtocolCommand {
  constructor(
    public readonly strategyId: string,
    public readonly operationId: string,
    public readonly dataObject: any
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ExecuteCustomAlgoOperation`;
  }
}
