import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, DxValueChanged, getActionClass, getBucketRoleClass, getQtyClass, isVoid} from "../utils";
import {RegroupOrdersConfig} from "./RegroupOrdersConfig";
import {DropZone} from "./DropZone";
import {OrdersGroupToRegroup} from "./OrdersGroupToRegroup";
import {ToastrService} from "ngx-toastr";
import {SolutionOrderLegDto} from "../adjustment-pricing-grid/model/SolutionOrderLegDto";
import {DropZoneOrder, OrderToRegroupWrapper} from "./OrderToRegroupWrapper";
import {SolutionPositionDto} from "../adjustment-pricing-grid/model/SolutionPositionDto";
import {RegroupedOrders} from "./RegroupedOrders";
import * as Enumerable from "linq";

@Component({
    selector: 'ets-orders-regroup-dialog',
    templateUrl: 'orders-regroup-dialog.component.html',
    styleUrls: ['./orders-regroup-dialog.component.scss']
})

export class OrdersRegroupDialogComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }

    private _config : RegroupOrdersConfig;

    visible = false;

    loading = false;

    splitQty = false;

    get orderGroups() : OrdersGroupToRegroup[] {
        return this._config?.orders;
    }

    readonly dropZones : DropZone[] = [];

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: RegroupOrdersConfig) {
        this._config = config;

        this._config.orders.forEach(x => {
            const dz = new DropZone();
            dz.title = x.title;
            this.dropZones.push(dz);
        });

        this.visible = true;
    }

    getOrderTitle(zone: DropZone) {
        const ix = this.dropZones.indexOf(zone);
        if (ix < 0) {
            return 'N/A';
        }

        if (ix === 0) {
            return 'Main';
        }

        return `Linked #${ix}`;
    }

    @DetectMethodChanges()
    onHidden() {
        this.dropZones.length = 0;
        this.visible = false;
    }

    onDragStart($event: DragEvent, order: OrderToRegroupWrapper) {
        $event.dataTransfer.setData("text", `${order.ticker},${order.group.title}`);
    }

    @DetectMethodChanges()
    onDrop($event: DragEvent, zone: DropZone) {

        $event.preventDefault();

        if (isVoid(zone)) {
            return;
        }

        this.loading = true;

        const id = $event.dataTransfer.getData("text");

        const parts = id.split(',');

        if (parts.length !== 2) {
            this._toastr.error('Order Not Recognized');
            return;
        }

        const ticker = parts[0];

        const title = parts[1];

        setTimeout(() => {
            try {

                if (!ticker || !title) {
                    return;
                }

                if (zone.items.length >= 4) {
                    this._toastr.error('Order cannot have more than 4 legs');
                    return;
                }

                const item  = this.orderGroups
                    .find(x => x.title === title)
                    ?.orders.find(x => x.ticker === ticker);

                if (isVoid(item)) {
                    this._toastr.error('Drop operation cannot be completed' ,'Error');
                    return;
                }


                const index = zone.items.findIndex(x => x.ticker === ticker);

                if (index >= 0) {
                    this._toastr.error('Selected leg already added to this order' ,'Error');
                    return;
                }

                const copy = new DropZoneOrder(item);
                copy.group = item.group;

                zone.add(copy);

                (item as any).moved = true;
            } finally {
                this.loading = false;
                this._changeDetector.detectChanges();
            }
        });
    }

    onDragOver($event: DragEvent) {
        $event.preventDefault();
    }

    @DetectMethodChanges()
    addDropZone() {
        if(this.dropZones.length > 4) {
            this._toastr.error('Cannot have more than 4 orders');
            return;
        }
        const dz = new DropZone();
        dz.title = this.dropZones.length === 0
            ? 'Main'
            :  `Linked #${this.dropZones.length}`;
        this.dropZones.push(dz);
    }


    @DetectMethodChanges()
    removeOrder(order: DropZoneOrder) {
        this.removeOrderSilent(order);
    }

    private removeOrderSilent(order: DropZoneOrder) {
        this.dropZones.forEach(dz => {
            const ix = dz.items.indexOf(order);
            if (ix < 0) {
                return;
            }
            dz.items.splice(ix, 1);
        });

        order.onRemoved();

        // const original = this.orderGroups
        //     .flatMap(grp => grp.orders)
        //     .find(wrp => wrp.ticker === order.ticker);
        //
        // const originalGroup = order.group;
        //
        // if (isVoid(originalGroup)) {
        //     this._toastr.error('Order\'s Original Group Not Defined' ,'Error');
        //     return;
        // }
        //
        // const original = originalGroup.orders.find(wrp => wrp.ticker === order.ticker);
        //
        // if (isVoid(original)) {
        //     return;
        // }
        //
        // // original.moved = false;
    }

    getActionClass(order: SolutionOrderLegDto, isEvenRow: boolean) {
        const classes = [];

        const actionClass = getActionClass(order);

        classes.push(actionClass);

        if (isEvenRow) {
            classes.push('couple-start');
        } else {
            classes.push('couple-end');
        }

        return classes;
    }

    getPositionRoleClass(pos: SolutionPositionDto | SolutionOrderLegDto): string {
        return getBucketRoleClass(pos);
    }

    //
    getQtyClass(position: any): any {
        return getQtyClass(position);
    }

    @DetectMethodChanges()
    removeZone(zone: DropZone) {

        const slice = zone.items.slice();

        slice.forEach(item => {
            this.removeOrderSilent(item);
        });

        const ix = this.dropZones.indexOf(zone);

        if (ix < 0) return;

        this.dropZones.splice(ix, 1);
    }

    copyLegs(zone: DropZone) {
        const orders = zone.items
            .map(y => y.order);

        const cbArg =  {
            orderToCopy: zone.title,
            orders
        } as RegroupedOrders;

        this._config.copyCallback([cbArg]);
    }

    copyAllOrders() {

        if (this.dropZones.length === 0) {
            this._toastr.error("No orders to copy");
            return;
        }

        const error = this.validate();

        if (!isVoid(error)) {
            this._toastr.error(error);
            return;
        }

        const allOrders = this.dropZones.map(dz => {

            const orders = dz.items.map(y => y.order);

            return {
                orderToCopy: dz.title,
                orders
            } as RegroupedOrders;
        });

        this._config.copyCallback(allOrders);
    }

    @DetectMethodChanges()
    onSplitQtyModeChanged($event: any) {

        if (this.dropZones.length === 0) {
            return;
        }

        const title = this.dropZones[0].title;

        this.dropZones
            .slice()
            .forEach(dz => this.removeZone(dz));

        const dz = new DropZone();
        dz.title = title;
        this.dropZones.push(dz);
    }

    @DetectMethodChanges()
    onSettledQtyChanged(value: number, order: DropZoneOrder) {

        const orderSideMultiplier = Math.sign(order.order.qty);

        if (Math.sign(value) !== orderSideMultiplier) {
            this._toastr.error("The quantity you entered doesn't match order's action");
            return;
        }

        const prev = Math.abs(order.qty || 0) * orderSideMultiplier;

        const curr = Math.abs(value || 0) * orderSideMultiplier;

        const delta = curr - prev;

        const adjusted = order.adjustSettledQty(delta);

        if (!adjusted) {
            this._toastr
                .error('Cannot adjust order qty. Please, check that total settled quantity matches original order\'s quantity');
        } else {
            order.qty = value;
        }
    }

    private validate() : string {

        // Rule 1: one order should not contain 2 same tickers
        for (const zone of this.dropZones) {
            const duplicates = Enumerable.from(zone.items)
                .groupBy(x => x.ticker)
                .count(x => x.count() > 1);

            if (duplicates > 0) {
                return `Same contract legs are not allowed within one order (${zone.title})`;
            }
        }


        // Rule 2: split orders qty must match
        if (this.splitQty) {
            const summarized = Enumerable.from(this.dropZones)
                .selectMany(x => x.items)
                .groupBy(x => x.ticker)
                .select(x => {
                    return {
                        ticker: x.key(),
                        qty: x.sum(y=>y.qty)
                    }
                }).toArray();

            const originals = this.orderGroups.flatMap(x => x.orders);
            for (const summarizedElement of summarized) {
                const match = originals.find(x => x.ticker === summarizedElement.ticker);
                if (isVoid(match)) {
                    continue;
                }
                if (match.qty !== summarizedElement.qty) {
                    return "Regrouped orders' quantity does not match original orders' quantity;";
                }
            }
        }
    }

}