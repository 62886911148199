import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CellValueChangedEvent, ColumnState, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { TimestampsService } from '../timestamps.service';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { SymbolHighlighted } from '../ui-messages/ui-messages';
import { DetectMethodChanges, DetectSetterChanges, getPanelStateKey, isNullOrUndefined } from '../utils';
import { getWatchlistGridOptions } from './watchlist-grid-options';
import {UserSettingsService} from "../user-settings.service";

interface WatchlistState {
   tickers: string[];
   columnsState: ColumnState[];
}


export interface WatchlistRow {
   rowId: string;
   ticker: string;
   last?: number;
   bid?: number;
   ask?: number;
   percentChange?: number;
   dollarChange?: number;
   timestamp?: Date;
}

@Component({
   selector: 'ets-watchlist',
   templateUrl: 'watchlist.component.html',
   styleUrls: ['watchlist.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class WatchlistComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _toastr: ToastrService,
      private readonly _tiService: TradingInstrumentsService,
      private readonly _timestampsService: TimestampsService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
    }

   private _grid: GridReadyEvent;
   private _unsubscriber: Subject<void>;

   
   private _isSymbolPickerVisible: boolean;
   get isSymbolPickerVisible(): boolean { return this._isSymbolPickerVisible; }
   
   @DetectSetterChanges()
   set isSymbolPickerVisible(value: boolean) {
      this._isSymbolPickerVisible = value;
   }
   
   gridOptions: GridOptions;

   etsOnInit() {
      this.gridOptions = getWatchlistGridOptions.bind(this)(this._tiService, this._timestampsService);
      this.subscribeMessages();
   }


   etsAfterViewInit() { }


   etsOnDestroy() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      const tickersToUnsub = [];
      if (!isNullOrUndefined(this._grid)) {
         this._grid.api.forEachNode(x => {
            
            const row = x.data as WatchlistRow;

            if (!isNullOrUndefined(row)) {

               if (!isNullOrUndefined(row.ticker)) {
                  tickersToUnsub.push(row.ticker);
               }
            }
         });
      }

      const distinct = tickersToUnsub.filter( ( v, ix, arr) => arr.indexOf(v) === ix );
      
      this._lastQuoteCache.unsubscribeTickers(distinct);
   }

   
   onGridReady(args: GridReadyEvent): void {

      args.api.sizeColumnsToFit();

      this._grid = args;

      const key = getPanelStateKey(this);

      const item = this._userSettingsService.getValue(key);

      if (!item) {
         this.loadFavorites();
         this.saveState();
      } else {
         this.restoreState();
      }

      this.subscribeMessages();
   }


   @DetectMethodChanges()
   onAddSymbol() {

      this.isSymbolPickerVisible = true;

   }


   onTickerChanged(args: CellValueChangedEvent) {

      let newTicker;
      let oldTicker;

      if (args.newValue) {

         const ti = this._tiService.getInstrumentByDisplayName(args.newValue);

         if (!ti) {

            this._toastr.info('Trading Instrument Does Not Exist');

            this._grid.api.undoCellEditing();

            return;
         }

         newTicker = ti.ticker;

      }

      if (args.oldValue) {

         const ti = this._tiService.getInstrumentByDisplayName(args.oldValue);

         if (ti) {
            oldTicker = ti.ticker;
         }
      }

      if (args.data.rowId === '---') {

         if (newTicker) {

            this._grid.api.undoCellEditing();


            const data: WatchlistRow = {
               rowId: newTicker,
               ticker: newTicker
            };

            this._lastQuoteCache.subscribeTicker(newTicker);
            this.updateWatchlistRow(data);

            this._grid.api.applyTransaction({ add: [data] });
         }
      } else {

         const rowIndex = args.node.rowIndex;

         this._grid.api.applyTransaction({ remove: [args.data] });

         if (newTicker) {

            const newData: WatchlistRow = {
               rowId: newTicker,
               ticker: newTicker
            };

            this._lastQuoteCache.subscribeTicker(newTicker);
            this.updateWatchlistRow(newData);

            this._grid.api.applyTransaction({ add: [newData], addIndex: rowIndex - 1 });
         }

         if (oldTicker) {
            this._lastQuoteCache.unsubscribeTicker(oldTicker);
         }
      }

      this.saveState();
   }

   
   onSymbolSelected(ti: TradingInstrument) {
      const row: WatchlistRow = {
         rowId: ti.ticker,
         ticker: ti.ticker
      };

      this._lastQuoteCache.subscribeTicker(ti.ticker);
      this.updateWatchlistRow(row);

      this._grid.api.applyTransaction({ add: [row] });

      this.saveState();
   }

   
   @DetectMethodChanges()
   onSymbolPickerClosed() {
      this.isSymbolPickerVisible = false;
   }

   
   onRemoveSymbol(data: WatchlistRow): void {
      if (!data) {
         return;
      }

      this._grid.api.applyTransaction({ remove: [data] });
      this._lastQuoteCache.unsubscribeTicker(data.ticker);

      this.saveState();
   }

   
   resetToFavorites() {
      const tickers = [];

      this._grid.api.forEachNode(node => {
         if (!node.data) {
            return;
         }
         const t = node.data.ticker;
         if (t) {
            tickers.push(t);
         }
      });

      if (tickers.length > 0) {
         this._lastQuoteCache.unsubscribeTickers(tickers);
      }

      this.loadFavorites();
      this.saveState();
   }

   
   onRowSelected(row: WatchlistRow) {
      if (!row) {
         return;
      }

      this._messageBus.publish<SymbolHighlighted>({
         topic: 'SymbolHighlighted',
         payload: {
            ticker: row.ticker,
            source: 'Watchlist'
         },
         scopeId: this.layoutTabId
      });

   }

   
   private subscribeMessages() {

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject();

      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onQuote(msg.payload));
   }

   
   private onQuote(quotes: QuoteDto[]) {
      if (!this._grid) {
         return;
      }

      const updateRows = [];


      quotes.forEach(q => {

         const node = this._grid.api.getRowNode(q.ticker);

         if (!node) {
            return;
         }

         const row: WatchlistRow = node.data;

         this.updateWatchlistRow(row, q);

         updateRows.push(row);
      });

      if (updateRows.length > 0) {
         this._grid.api.applyTransactionAsync({ update: updateRows });
      }

   }


   private loadFavorites() {

      try {

         this._grid.api.setRowData([]);

         this._grid.api.showLoadingOverlay();

         const favs: string[] = this._userSettingsService.getValue('favorites') || [];

         this.setWatchlistSymbols(favs);

      } finally {

         this._grid.api.hideOverlay();

      }
   }


   private setWatchlistSymbols(symbols: string[]) {

      if (symbols.length === 0) {
         this._grid.api.setPinnedTopRowData([{ rowId: '---' }]);
         return;
      }

      const rows = symbols.map(ticker => {
         const watchlistRow: WatchlistRow = {
            rowId: ticker,
            ticker
         };
         return watchlistRow;
      });

      rows.forEach(x => this.updateWatchlistRow(x));

      this._grid.api.setRowData(rows);

      this._grid.api.setPinnedTopRowData([{ rowId: '---' }]);

      this._grid.api.sizeColumnsToFit();

      this._lastQuoteCache.subscribeTickers(rows.map(x => x.ticker));
   }


   private updateWatchlistRow(data: WatchlistRow, lq?: QuoteDto) {

      if (!lq) {
         lq = this._lastQuoteCache.getLastQuote(data.ticker);
      }

      if (lq) {
         data.bid = lq.bid;
         data.ask = lq.ask;
         data.last = lq.lastPx;
         data.timestamp = lq.timeStamp;
         data.percentChange = lq.percentChange;
         data.dollarChange = lq.dollarChange;
         data.timestamp = lq.timeStamp;
      }
   }


   protected getState(): WatchlistState {

      if (!this._grid) {
         return null;
      }


      const gridState = this._grid.columnApi.getColumnState();

      const tickers = [];

      this._grid.api.forEachNode(x => {
         const row: WatchlistRow = x.data;
         if (!row) {
            return;
         }
         if (!row.ticker) {
            return;
         }
         tickers.push(row.ticker);
      });

      const state: WatchlistState = {
         columnsState: gridState,
         tickers
      };

      return state;
   }

   
   protected setState(state: WatchlistState): void {

      if (!this._grid) {
         return;
      }

      const isOK = this._grid.columnApi.setColumnState(state.columnsState);

      if (!isOK) {
         this._toastr.error('"Watchlist" panel was restored with errors');
      }

      this.setWatchlistSymbols(state.tickers);

   }
}
