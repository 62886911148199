import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import {isNullOrUndefined, isVoid} from 'projects/shared-components/utils';
import { CashFlowStrategySettingsModel } from './model/CashFlowStrategySettingsModel';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';


@Component({
   selector: 'ets-cashflow-strategy-settings',
   templateUrl: './cash-flow-strategy-settings.component.html',
   styleUrls: [
      './cash-flow-strategy-settings.component.scss',
      '../../adjustment-grid-common-style.scss',
      '../adjustment-grid-parameters-style.scss',
   ],
})
export class CashFlowStrategySettingsComponent implements OnInit, OnDestroy {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _accessControlService: AccessControlService
   ) { }

   //

   @Input()
   settings: CashFlowStrategySettingsModel;

   //

   @Input()
   strategy: CashFlowStrategy;

   //

   @Input()
   reversedOrder: boolean;

   //

   @Input()
   disabled: boolean;

   //

   @Input()
   isSubStrategy: boolean;

   //

   private _shouldRollDates : boolean;
   get shouldRollDates() : boolean {
      return this._shouldRollDates;
   }
   @Input()
   set shouldRollDates(v : boolean) {

      if (this._shouldRollDates === v) {
         return;
      }

      this._shouldRollDates = v;

      setTimeout(() => {
         if (this.settings) {
            this.settings.onPriceToDestinationChanged(v);
            this._changeDetector.detectChanges();
         }
      });

   }

   //

   @Input()
   secondPoMismatchResolver: () => boolean = () => false;

   //

   @Input()
   secondSpreadMismatchResolver: () => boolean = () => false;

   //

   get isRollXDaysBeforeDisabled(): boolean {
      return this.disabled || !this.shouldRollDates;
   }

   //

   get isRollDaysToExpirationDisabled(): boolean {
      return this.disabled || !this.shouldRollDates;
   }

   //

   get isSpreadRollDaysToExpirationDisabled(): boolean {
      return this.isRollDaysToExpirationDisabled
         || !isNullOrUndefined(this.settings.spreadOverrideRollToDaysToExp)
         || !isNullOrUndefined(this.settings.spreadEvergreenOverrideRollToDaysToExp);
   }

   //

   get isSecondSpreadRollDaysToExpirationDisabled(): boolean {
      return this.isRollDaysToExpirationDisabled
         || !isVoid(this.settings.secondSpreadOverrideRollToDaysToExp)
         || !isVoid(this.settings.secondSpreadEvergreenOverrideRollToDaysToExp);
   }

   //

   get isProtectiveOptionRollDaysToExpirationDisabled(): boolean {
      return this.isRollDaysToExpirationDisabled
         || !isVoid(this.settings.protectiveOptionOverrideRollToDaysToExp)
         || !isVoid(this.settings.protectiveOptionEvergreenOverrideRollToDaysToExp)
          || !isVoid(this.settings.protectiveOptionRollToXBusinessDaysToExp);
   }

   //

   get isSecondProtectiveOptionRollDaysToExpirationDisabled(): boolean {
      return this.isRollDaysToExpirationDisabled
         || !isVoid(this.settings.secondProtectiveOptionOverrideRollToDaysToExp)
         || !isVoid(this.settings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp)
         || !isVoid(this.settings.secondProtectiveOptionRollToXBusinessDaysToExp);
   }

   //

   get sortOrderingCssClass(): string {
      let retval = undefined;

      if (this.reversedOrder) {
         retval = 'reverse';
      } else {
         if (this.strategy) {
            if (this.strategy === 'Calls' ||
                  this.strategy === 'Reversed Hedged Portfolio' ||
                  this.reversedOrder) {
               retval = 'reverse';
            }
         }
      }

      return retval;
   }

   //

   get shortOptionSectionHeader(): string {
      let header = 'Short Option';

      if (this.strategy === 'Hedged Portfolio' ||
            this.strategy === 'Calls' ) {
         header = 'Short Call';
      } else if (this.strategy === 'Puts' ||
                     this.strategy === 'Reversed Hedged Portfolio') {
         header = 'Short Put';
      }

      return header;

   }

   //

   get spreadSectionHeader(): string {
      let header = 'Debit Spread';

      if (this.strategy === 'Hedged Portfolio' ||
            this.strategy === 'Puts' ) {
         header = 'Put Debit Spread';
      } else if (this.strategy === 'Calls' ||
                     this.strategy === 'Reversed Hedged Portfolio') {
         header = 'Call Debit Spread';
      }

      return header;
   }

   //

   get secondSpreadSectionHeader(): string {
      let header = '2nd Debit Spread';

      if (this.strategy === 'Hedged Portfolio' ||
            this.strategy === 'Puts' ) {
         header = 'Put Debit Spread #2';
      } else if (this.strategy === 'Calls' ||
                     this.strategy === 'Reversed Hedged Portfolio') {
         header = 'Call Debit Spread #2';
      }

      return header;
   }

   //

   get protectiveOptionSectionHeader(): string {
      let header = 'Protective Option';

      if (this.strategy === 'Hedged Portfolio' ||
            this.strategy === 'Puts' ) {
         header = 'Protective Put';
      } else if (this.strategy === 'Calls' ||
                  this.strategy === 'Reversed Hedged Portfolio') {
         header = 'Protective Call';
      }

      return header;
   }

   //

   get secondProtectiveOptionSectionHeader(): string {
      let header = '2nd Protective Option';

      if (this.strategy === 'Hedged Portfolio' ||
            this.strategy === 'Puts' ) {
         header = 'Protective Put #2';
      } else if (this.strategy === 'Calls' ||
                  this.strategy === 'Reversed Hedged Portfolio') {
         header = 'Protective Call #2';
      }

      return header;
   }

   //

   get anchorShortOptionType(): string {
      let retval = 'Option';

      if (this.strategy === 'Hedged Portfolio' ||
            this.strategy === 'Calls' ) {
         retval = 'Call';
      } else if (this.strategy === 'Puts' ||
                  this.strategy === 'Reversed Hedged Portfolio') {
         retval = 'Put';
      }

      return retval;
   }

   //

   get isSecondSpreadAvailable(): boolean {
      return this._accessControlService.isSecureElementAvailable(
         '78779154-4f9c-425e-8805-d710ec52868c'
      );
   }

   //

   get isSecondProtectiveOptionAvailable(): boolean {
      return this._accessControlService.isSecureElementAvailable(
         '3724dd8f-725d-40a5-b673-84ced010adb2'
      );
   }

   //

   get isSecondSpreadEnabled(): boolean {
      return this.settings ? (this.settings.secondSpreadEnabled || false) : false;
   };

   //

   get isSecondProtectiveOptionEnabled(): boolean {
      return this.settings ? (this.settings.secondProtectiveOptionEnabled || false) : false;
   };

   //

   ngOnInit(): void { }

   //

   ngOnDestroy(): void {
      this.settings.secondSpreadEnabled = false;
      this.settings.secondProtectiveOptionEnabled = false;
   }

   //

   changeSecondSpreadState(value: boolean) {
      this.settings.changeSecondSpreadState(value);
   }

   //

   changeSecondProtectiveOptionState(value: boolean) {
      this.settings.changeSecondProtectiveOptionState(value);
   }
}
