import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from '../message-bus.service';
import { AuthServiceClientService } from './auth-service-client.service';
import { AuthResultDto } from './dtos/auth-result-dto.inteface';
import { KeepTokenAlive } from './operations/keep-token-alive.class';
import { SessionService } from './session-service.service';
import { AuthTokenExpiredUIMessage } from '../ui-messages/auth-token-expired-ui-message.interface';
import { environment } from '../environments/environment';
import { Logger } from '../logging/logger.interface';
import { LoggerService } from '../logging/logger-factory.service';

declare function setInterval(callback: () => void, timeout: number): number;

@Injectable({
  providedIn: 'root'
})
export class KeepSessionAliveService {

  constructor(
    private readonly authSevice: AuthServiceClientService,
    private readonly toastr: ToastrService,
    private readonly messageBus: MessageBusService,
    private readonly sessionService: SessionService,
    loggerService: LoggerService
  ) {
    this.logger = loggerService.createLogger('keep-session-alive.service');

    this.messageBus
      .of<AuthTokenExpiredUIMessage>('AuthTokenExpiredUIMessage')
      .subscribe((msg) => {
        setTimeout(() => this._onAuthTokenExpiredUIMessage(msg.payload), 0);
      });
  }

  private readonly logger: Logger;
  private sessionData: AuthResultDto;
  private pollingWorkerRef: number;

  start(): void {
    this.stop();

    this.sessionData = this.sessionService.sessionData;

    this.pollingWorkerRef = setInterval(() => this._updateTokenStatus(), environment.keepAliveInterval);

    this.logger.info('Started polling');
  }

  stop(): void {
    const pollerRef = this.pollingWorkerRef;

    this.pollingWorkerRef = null;

    if (pollerRef) {
      clearInterval(pollerRef);
      this.logger.info('Keep-alive stopped');
    }
  }

  private _onAuthTokenExpiredUIMessage(msg: AuthTokenExpiredUIMessage) {
    this.logger.info('Received message that auth. token is expired', msg);
    this.stop();
  }

  private _updateTokenStatus() {
    const authToken = this.sessionData.authToken;
    if (authToken) {
      this.authSevice.processCommand(new KeepTokenAlive(authToken)).catch(() => {
        this.stop();
        this.messageBus.publish({
          payload: { source: 'KeepAliveService' },
          topic: 'AuthTokenExpiredUIMessage'
        });
      });
    }
  }
}
