<div class="column left">

  <div class="ets-field">
    <div class="ets-field-label">Symbol</div>
    <div class="ets-field-value">
      <ets-symbol-picker (instrumentSelected)="control.symbol = $event"
                         [selectedInstrument]="control.symbol"
                         [disabled]="control.isNetBettingMode"
      ></ets-symbol-picker>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Filter</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.filterItems"
                     [value]="control.filter"
                     (valueChange)="control.filter = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.filterItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Profit Target</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.profitTargetItems"
                     [value]="control.profitTarget"
                     (valueChange)="control.profitTarget = $event"
                     [showClearButton]="true"
                     [disabled]="!control.useFactor"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.profitTargetItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Trade Level</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.tradeLevelItems"
                     [value]="control.tradeLevel"
                     (valueChange)="control.tradeLevel = $event"
                     [showClearButton]="true"
                     [disabled]="!control.useFactor"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.tradeLevelItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Multiplier</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.multiplierItems"
                     [value]="control.multiplier"
                     (valueChange)="control.multiplier = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.multiplierItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Factor</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.factorItems"
                     [value]="control.factor"
                     (valueChange)="control.factor = $event"
                     [showClearButton]="true"
                     [disabled]="!control.useFactor"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Max. Position</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.maxPositionItems"
                     [value]="control.maxPosition"
                     (valueChange)="control.maxPosition = $event"
                     [showClearButton]="true"
                     [disabled]="!control.useFactor"
      ></dx-select-box>
    </div>
  </div>

  <fieldset>
    <legend>Order Conditions</legend>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Proxy"
                    [value]="control.isTradeThroughProxy"
                    (valueChange)="control.isTradeThroughProxy = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Market"
                    [value]="control.isTradeThroughStop"
                    (valueChange)="control.isTradeThroughStop = $event"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Limits"
                    [value]="control.isTradeThroughLimit"
                    (valueChange)="control.isTradeThroughLimit = $event"
                    [disabled]="control.isNetBettingMode"
      ></dx-check-box>
    </div>
  </fieldset>

  <fieldset>
    <legend>
      <dx-check-box text="Bracketing Mode"
                    [value]="control.useBracketing"
                    (valueChange)="control.useBracketing = $event"
      ></dx-check-box>
    </legend>
    <div class="fields-line">
      <div class="ets-field inline bracketing">
        <p>CTL Value</p>
        <dx-select-box [items]="control.ctlValueItems"
                       [disabled]="!control.useBracketing"
                       [value]="control.ctlValue"
                       (valueChange)="control.ctlValue = $event"
                       [showClearButton]="true"
                       [acceptCustomValue]="true"
                       (onCustomItemCreating)="control.onCustomNumberCreated($event, control.ctlValueItems)"
        ></dx-select-box>
      </div>
      <div class="ets-field inline bracketing">
        <p>CTL Times</p>
        <dx-select-box [items]="control.ctlTimesItems"
                       [disabled]="!control.useBracketing"
                       [value]="control.ctlTimes"
                       (valueChange)="control.ctlTimes = $event"
                       [showClearButton]="true"
        ></dx-select-box>
      </div>
      <div class="ets-field inline bracketing renko">
        <dx-check-box text="Renko Counter"
                      [disabled]="!control.useBracketing"
                      [value]="control.isRenkoCounter"
                      (valueChange)="control.isRenkoCounter = $event"
        ></dx-check-box>
      </div>
    </div>
  </fieldset>

</div>

<div class="column right">

  <fieldset>
    <legend>Bull/Bear Specific</legend>

    <div class="ets-field">
      <div class="ets-field-label">Strategy Mode</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.strategyModeItems"
                       [(value)]="control.mode"
        ></dx-select-box>
      </div>
    </div>

    <div class="fields-line two">
      <div class="ets-field inline">
        <div class="ets-field-label">BB Mode</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.bbModeItems"
                         [(value)]="control.bbMode"
          ></dx-select-box>
        </div>
      </div>
      <div class="ets-field inline last">
        <div class="ets-field-label">Trigger</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.wlTriggerItems"
                         [(value)]="control.wlTrigger"
                         [disabled]="!control.isWlTriggerEnabled"
                         [acceptCustomValue]="true"
                         (onCustomItemCreating)="control.onCustomNumberCreated($event, control.wlTriggerItems)"
          ></dx-select-box>
        </div>
      </div>
    </div>

    <div class="fields-line two">
      <div class="ets-field inline">
        <div class="ets-field-label large">
          Immediate Start
        </div>
        <div class="ets-field-value small">
          <dx-check-box [(value)]="control.immediateStart"
          ></dx-check-box>
        </div>
      </div>
      <div class="ets-field inline last">
        <div class="ets-field-label">Trail Value</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.trailValueItems"
                         [(value)]="control.trailValue"
                         [disabled]="!control.isTrailValueEnabled"
                         [acceptCustomValue]="true"
                         (onCustomItemCreating)="control.onCustomNumberCreated($event, control.trailValueItems)"
          ></dx-select-box>
        </div>
      </div>
    </div>

    <div class="fields-line two">
      <div class="ets-field inline">
        <div class="ets-field-label large">Use Factor</div>
        <div class="ets-field-value small">
          <dx-check-box [(value)]="control.useFactor"
          ></dx-check-box>
        </div>
      </div>
      <div class="ets-field inline last">
        <div class="ets-field-label">Trail Freq.</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.trailFreqItems"
                         [(value)]="control.trailFreq"
                         [disabled]="!control.isTrailFreqEnabled"
                         [acceptCustomValue]="true"
                         (onCustomItemCreating)="control.onCustomNumberCreated($event, control.trailFreqItems)"
          ></dx-select-box>
        </div>
      </div>
    </div>

    <div class="fields-line two">
      <div class="ets-field inline">
        <div class="ets-field-label large">Direction Reset</div>
        <div class="ets-field-value small">
          <dx-check-box [(value)]="control.directionalReset"
                        [disabled]="!control.isDirectionalResetEnabled"
          ></dx-check-box>
        </div>
      </div>
      <div class="ets-field inline last">
        <div class="ets-field-label">Waterline</div>
        <div class="ets-field-value">
          <dx-number-box [(value)]="control.explicitWaterline"
                         [showClearButton]="true"
                         [min]="0"
          ></dx-number-box>
        </div>
      </div>
    </div>

    <div class="fields-line two">
      <div class="ets-field inline">
        <div class="ets-field-label large">Trend MPS</div>
        <div class="ets-field-value small">
          <dx-check-box [(value)]="control.trendMPS"
                        [disabled]="!control.useFactor"
          ></dx-check-box>
        </div>
      </div>
      <div class="ets-field inline last">
        <div class="ets-field-label">Delayed Start</div>
        <div class="ets-field-value">
          <dx-number-box [(value)]="control.delayedStart"
                         [step]="control?.symbol?.tickSize"
                         [showClearButton]="true"
                         [disabled]="control.immediateStart"
          ></dx-number-box>
        </div>
      </div>
    </div>

    <div class="fields-line two">
      <div class="ets-field inline">&nbsp;</div>
      <div class="ets-field inline last">
        <div class="ets-field-label">Max. Levels</div>
        <div class="ets-field-value">
          <dx-select-box [(value)]="control.maxLevels"
                         [items]="control.maxLevelsItems"
                         [showClearButton]="true"
                         [disabled]="!control.useFactor"
          ></dx-select-box>
        </div>
      </div>
    </div>

  </fieldset>

  <fieldset>
    <legend>Money Management</legend>
    <div class="ets-field">
      <div class="ets-field-label">Contracts Added Per</div>
      <div class="ets-field-value">
        <dx-text-box [value]="control.mmContractsAddedPer"
                     (valueChange)="control.mmContractsAddedPer = $event"
                     [disabled]="control.mmIsReverse"
        ></dx-text-box>
      </div>
    </div>

    <div class="ets-field">
      <div class="ets-field-label">Max. Scale Levels</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.mmMaxScaleLevelsItems"
                       [value]="control.mmMaxScaleLevels"
                       (valueChange)="control.mmMaxScaleLevels = $event"
        ></dx-select-box>
      </div>
    </div>

    <div class="ets-field">
      <div class="ets-field-label">Decrease Per</div>
      <div class="ets-field-value">
        <dx-text-box [value]="control.mmContractsDecreasePer"
                     (valueChange)="control.mmContractsDecreasePer = $event"
                     [disabled]="!control.mmIsReverse"
        ></dx-text-box>
      </div>
    </div>

    <div class="ets-field">
      <div class="ets-field-label">Contracts To Decrease</div>
      <div class="ets-field-value">
        <dx-text-box [value]="control.mmContractsDecreaseNum"
                     (valueChange)="control.mmContractsDecreaseNum = $event"
                     [disabled]="!control.mmIsReverse"
        ></dx-text-box>
      </div>
    </div>

    <div class="fields-line mm-checkboxes">
      <dx-check-box text="Reset"
                    [value]="control.mmResetOnMaxLevel"
                    (valueChange)="control.mmResetOnMaxLevel = $event"
      ></dx-check-box>
      <dx-check-box text="Reverse"
                    [value]="control.mmIsReverse"
                    (valueChange)="control.mmIsReverse = $event"
      ></dx-check-box>
      <dx-check-box text="Regress"
                    [value]="control.mmIsRegress"
                    (valueChange)="control.mmIsRegress = $event"
      ></dx-check-box>
    </div>

  </fieldset>

</div>