<div class="panel-container" [class.ets-hidden]="!this.isActive" style="height: 100%;">

   <as-split class="split" direction="vertical" [gutterSize]="5" (dragEnd)="onSplitSizeChanged($event)">
      <as-split-area [order]="0" [size]="sectionSizes.top">
         <div class="grid-container">
            <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="workingOrdersGridModel"></ag-grid-angular>
         </div>
      </as-split-area>
      <as-split-area [order]="1" [size]="sectionSizes.bottom">
         <div class="grid-container bottom-area">
            <ag-grid-angular [class.ets-hidden]="selectedTabIndex !== 0" class="ag-theme-balham-dark"
               [gridOptions]="tradesGridModel"></ag-grid-angular>
            <ag-grid-angular [class.ets-hidden]="selectedTabIndex !== 1" class="ag-theme-balham-dark"
               [gridOptions]="snapshotsGridModel"></ag-grid-angular>
         </div>
         <dx-tabs [dataSource]="tabs" [selectedIndex]="selectedTabIndex" (onItemClick)="selectTab($event)" class="tabs">
         </dx-tabs>
      </as-split-area>
   </as-split>

</div>

<dx-popup width="550" height="350" [showTitle]="true" title='Order Comment' [closeOnOutsideClick]="true"
   [resizeEnabled]="true" [dragEnabled]="true" (onHidden)="isCommentPopupVisible = false"
   [visible]="isCommentPopupVisible">
   <div *dxTemplate="let data of 'content'" id="session_message_details">
      <div id="popup_container">
         <p>{{selectedOrderOrSnapshot?.comment}}</p>
      </div>
   </div>
</dx-popup>