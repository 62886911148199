<div class="root-container">
  
  <dx-scroll-view (onScroll)="this.onScroll()" [direction]="'both'" class="scroll" width="100%">
    <table class="table" *ngIf="!this.isEmpty">
      
      <thead class="header">
      
      <tr>
        
        <td [rowSpan]="2" [style.min-width.px]="200" class="hedge-column" style="position: relative">
          <div class="wrapper">
            <div class="text">Hedges</div>
            <div>&nbsp;</div>
            <div class="hedge-selector">
              <div class="available" (click)="this.showColumnSelector()" *ngIf="this.canShowColumnSelector()">
                <div>
                  {{ this.getColumnSelectorTextHedges() }}
                </div>
                <div>
                  {{ this.getColumnSelectorTextExpirations() }}
                </div>
              </div>
              <div  class="na" *ngIf="!this.canShowColumnSelector()">
                NOT AVAILABLE
              </div>
            </div>
          </div>
        </td>
        
        <td [rowSpan]="2" [style.min-width.px]="80">Strike</td>
        
        <td *ngIf="this.selectedCallExpirations.length > 0" [colSpan]="this.selectedCallExpirations.length">
          C. Price
        </td>
        
        <td *ngIf="this.selectedCallHedges.length > 0" [colSpan]="this.selectedCallHedges.length">
          CALLS
        </td>
        
        <td *ngIf="this.selectedPutHedges.length > 0" [colSpan]="this.selectedPutHedges.length">
          PUTS
        </td>
        
        <td *ngIf="this.selectedPutExpirations.length > 0" [colSpan]="this.selectedPutExpirations.length">
          P. Price
        </td>
        
        <td [rowSpan]="2" [style.min-width.px]="80">Total Price</td>
      
      </tr>
      
      <tr [style.line-height.em]="1.5">
        
        <td
          *ngFor="let exp of this.selectedCallExpirations"
          [style.min-width.px]="80"
          class="hedge"
        >
          {{ exp.expirationFull }}
        </td>
        
        <td
          *ngFor="let hedge of this.selectedCallHedges"
          [style.background]="hedge.color"
          [style.min-width.px]="80"
          class="hedge"
        >
          {{ hedge.label }}
        </td>
        
        <td *ngFor="let hedge of this.selectedPutHedges"
            [style.background]="hedge.color"
            [style.min-width.px]="80"
            class="hedge"
        >
          {{ hedge.label }}
        </td>
        
        <td
          *ngFor="let exp of this.selectedPutExpirations"
          [style.min-width.px]="80"
          class="hedge"
        >
          {{ exp.expirationFull }}
        </td>
      </tr>
      
      </thead>
      
      <tbody>
      <tr *ngFor="let row of this.rows"
          [ngClass]="{'multi-row': (this.getHedgeLabelColorsForStrike(row.strike) || []).length > 1 }"
      >
        
        <td class="hedge-label">
          <div
            *ngFor="let leg of this.getHedgeLabelColorsForStrike(row.strike)"
            [style.background]="leg.color"
            [style.color]="this.getFontColorForHedgeLabels(row.strike)"
            style="font-size: 90%; padding: 5px 0;"
          >
            {{ this.getHedgeLabel(leg) }}
          </div>
        </td>
        
        <td #strikeCell [ngClass]="this.getRowRoleCss(row)" class="strike"
          (click)="this.showCalculator(row,strikeCell)"
        >
          {{ row.strike }}{{ this.isAtmAtCalculation(row.strike) ? ' *' : undefined }}
        </td>
        
        <td
            #expCell
            *ngFor="let exp of this.selectedCallExpirations"
            class="expiration-price"
            (click)="this.showCalculator(row, expCell, exp)"
        >
          {{ this.getExpirationPrice(exp, row) | currency }}
        </td>
        
        <td
            *ngFor="let hedge of this.selectedCallHedges"
            [ngClass]="this.getPriceClass(this.getHedgePrice(row, hedge))"
            (click)="this.showTransactionPopup(row, hedge)"
        >
          {{ this.getHedgePrice(row, hedge) | currency }}
        </td>
        
        <td
            *ngFor="let hedge of this.selectedPutHedges"
            [ngClass]="this.getPriceClass(this.getHedgePrice(row, hedge))"
            (click)="this.showTransactionPopup(row, hedge)"
        >
          {{ this.getHedgePrice(row, hedge) | currency }}
        </td>
        
        <td
          #expCell
          *ngFor="let exp of this.selectedPutExpirations"
          class="expiration-price"
          (click)="this.showCalculator(row, expCell, exp)"
        >
          {{ this.getExpirationPrice(exp, row) | currency }}
        </td>
        
        <td [ngClass]="this.getPriceClass(this.getTotalPrice(row))">
          {{ this.getTotalPrice(row) | currency }}
        </td>
      </tr>
      </tbody>
    
    </table>
  </dx-scroll-view>
</div>

<dx-load-panel
  [position]="{my: 'center', at: 'center', of: '#body'}"
  [visible]="this.isLoading"
></dx-load-panel>

<ets-hg-zones-grid-parameters>
</ets-hg-zones-grid-parameters>

<ets-hg-transaction-popup>
</ets-hg-transaction-popup>

<ets-hg-zones-grid-column-selector>
</ets-hg-zones-grid-column-selector>

<ets-hg-zones-grid-calculator [section]="this">
</ets-hg-zones-grid-calculator>

<ets-hg-zones-grid-menu>
</ets-hg-zones-grid-menu>

<dx-popup #legsViewer [resizeEnabled]="true" [dragEnabled]="true" width="430px" height="270px"
          (onHidden)="this.closeLegsDialog()" [title]="this.legsData?.title">
  <div *dxTemplate="let data of 'content'" class="leg-viewer">
    
    <div class="positions">
      
      <div class="position header" [style.color]="this.legsData.color">
        <div class="qty">Qty</div>
        <div class="ticker">Ticker</div>
        <div class="price">
          Price
        </div>
      </div>
      
      <div *ngFor="let position of this.legsData?.legs" class="position">
        <div class="qty" [style.color]="this.legsData.color">
          {{position.qty}}
        </div>
        <div class="ticker" [style.color]="this.legsData.color">
          {{position.ticker | etsFriendlyTickerPipe}}
        </div>
        <div class="price" [ngClass]="this.getPriceClass(this.getLegViewerLegPrice(position, this.legsData?.legs))">
          {{ this.getLegViewerLegPrice(position, this.legsData?.legs)  | currency }}
        </div>
      </div>
      
      <div class="position total" [style.color]="this.legsData.color">
        <div class="qty">&nbsp;</div>
        <div class="ticker">&nbsp;</div>
        <div class="price" [ngClass]="this.getPriceClass(this.getLegViewerTotalPrice(this.legsData?.legs))">
          {{ this.getLegViewerTotalPrice(this.legsData?.legs) | currency }}
        </div>
      </div>
    </div>
    
    <div class="buttons">
      <div class="btn">
        <dx-button text="Close" width="100%" (onClick)="this.closeLegsDialog()"></dx-button>
      </div>
    </div>
    
  </div>
</dx-popup>