import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService,
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { defaultCurrencyFormatter, DetectMethodChanges } from 'projects/shared-components/utils';
import { AggregatedPositionsSecurityContextService } from './aggregated-positions-security-context.service';
import { ICellRendererParams } from 'ag-grid-community';
import { AggObj } from '../ui-messages/flatten-aggregated-positions-ui-message.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'floating-cell',
   template: `
    <div class="wrapper">
      <div class="block">
        <span class="group-title">{{groupTitle}}</span>
      </div>
      <div class="block action-panel">
        <span>Sess.:
          <span [style.color]="sessionTotalPnLColor">
            {{ sessionTotalPnL }}
          </span>
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span>Acc.:
          <span [style.color]="accumulatedTotalPnLColor">
            {{ accumulatedTotalPnL }}
          </span>
        </span>
        <dx-button [text]="groupButtonText"
                   class="go-flat-button"
                   (onClick)="onClick()"
                   [visible]="securityContext.closeAggregatedPosition"
        ></dx-button>
      </div>
    </div>
  `,
   styles: [
      `
      .wrapper {
        display: inline-block;
        width: 100%;
        text-align: left;
      }

      .block {
        display: inline-block;
      }

      .group-title {
        color: yellow;
      }

      .action-panel {
        position: absolute;
        right: 1px;
      }

      .action-panel > span {
        font-weight: bold;
        padding-right: 5px;
      }

      .go-flat-button {
        margin-top: -3px;
      }
    `
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line: component-class-suffix
export class AggregatedPositionsGroupRowInnerRenderer implements ICellRendererAngularComp, OnDestroy {
   constructor(
      readonly securityContext: AggregatedPositionsSecurityContextService,
      private readonly _messageBus: MessageBusService,
      private readonly _tickerFriendlyNameService: TradingInstrumentDisplayNameService,
      private readonly _changeDetector: ChangeDetectorRef
   ) {
   }

   private _streamEnd = new Subject();

   initParams: ICellRendererParams;
   groupButtonText = 'Go Flat';

   get groupTitle(): string {
      let title = 'N/A';

      if (this.initParams.node.field === 'tradingInstrumentCode') {
         title = this._tickerFriendlyNameService.getDisplayNameForTicker(
            this.initParams.node.key
         );
      } else if (this.initParams.node.field === 'shellId') {
         const value = (this.initParams.node.key as string);
         return value ? value.split('^')[0] : 'N/A';
      } else {
         title = this.initParams.node.key;
      }
      return title;
   }

   
   get sessionTotalPnLValue(): number {
      return this.initParams.node.aggData.sessionTotalPnL || 0;
   }

   
   get accumulatedTotalPnLValue(): number {
      return this.initParams.node.aggData.accumulatedTotalPnL || 0;
   }

   
   get sessionTotalPnLColor(): string {
      const value = this.sessionTotalPnLValue;
      if (value === 0) {
         return null;
      }
      return value > 0 ? 'green' : ' red';
   }

   get accumulatedTotalPnLColor(): string {
      const value = this.accumulatedTotalPnLValue;
      if (value === 0) {
         return null;
      }
      return value > 0 ? 'green' : ' red';
   }

   
   get sessionTotalPnL(): string {
      return defaultCurrencyFormatter(this.sessionTotalPnLValue);
   }

   
   get accumulatedTotalPnL(): string {
      return defaultCurrencyFormatter(this.accumulatedTotalPnLValue);
   }


   @DetectMethodChanges()
   agInit(params: ICellRendererParams): void {
      
      this.initParams = params;

      let node = params.node;

      while (node.level >= 0) {
         if (node.key === 'Archived Positions') {
            this.groupButtonText = 'Remove';
            break;
         }
         node = node.parent;
      }

      this._messageBus.of('AggregatedPositionsUpdated')
         .pipe(takeUntil(this._streamEnd))
         .subscribe(x => this._changeDetector.detectChanges());

      this._changeDetector.detach();
   }

   ngOnDestroy() {
      if (this._streamEnd) {
         this._streamEnd.next();
         this._streamEnd.complete();
      }
   }


   refresh(): boolean {
      return false;
   }

   
   @DetectMethodChanges()
   onClick() {
      const aggObj: AggObj = {};

      let node = this.initParams.node;

      if (node.field === 'shellId') {
         return;
      }

      while (node.level >= 0) {
         aggObj[node.field] = node.key;
         node = node.parent;
      }

      const panelId = this.initParams.api['ets-panel-id'];

      if (this.groupButtonText === 'Remove') {
         aggObj.deleteArchived = true;
      }

      this._messageBus.publish({
         topic: 'FlattenAggregatedPositionsUIMessage',
         payload: { panelId, aggObj }
      });
   }
}
