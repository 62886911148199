import { AggregatedPositionsComponent } from './aggregated-positions.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate } from '@angular/common';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultTimestampFormat,
   defaultLoadingOverlayTemplate
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';

export function getGroupExistsGridModel(
   this: AggregatedPositionsComponent,
   displayNameService: TradingInstrumentDisplayNameService
): GridOptions {

   const columns = setupColumns(displayNameService);

   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      frameworkComponents: {
         groupExitsGroupRowRenderer: SimpleHeaderGroupRowInnerRenderer
      } as any,

      groupUseEntireRow: true,

      groupRowRendererParams: {
         innerRenderer: 'groupExitsGroupRowRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: 'always',

      onGridReady: (args) => this.onGroupExitsGridReady(args),

      getRowNodeId: (rowData: OrderDto) => rowData.orderId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}


function setupColumns(displayNameService: TradingInstrumentDisplayNameService): Partial<AgGridColumn>[] {
   const columns: Partial<AgGridColumn>[] = [];

   if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      columns.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         rowGroup: true
      });
      columns.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true,
         rowGroup: true
      });
   }

   columns.push({
      headerName: 'Symbol',
      field: 'ticker',
      valueFormatter: (params: ValueFormatterParams) => {
         return displayNameService.getDisplayNameForTicker(params.value);
      }
   });

   columns.push({
      headerName: 'Type',
      field: 'orderType',
      valueFormatter: (params: ValueFormatterParams) => {
         return OrderType[params.value];
      }
   });

   columns.push({
      headerName: 'Status',
      field: 'status',
      valueFormatter: (params: ValueFormatterParams) => {
         return OrderStatus[params.value];
      }
   });

   columns.push({
      headerName: 'Qty',
      field: 'qty',
      valueFormatter: defaultNumberCellFormatter
   });

   columns.push({
      headerName: 'Leaves Qty',
      field: 'leavesQty',
      valueFormatter: defaultNumberCellFormatter
   });

   columns.push({
      headerName: 'Limit Px',
      field: 'limitPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   columns.push({
      headerName: 'Last Quote',
      field: 'lastQuote',
      valueFormatter: defaultQuoteCellFormatter
   });

   columns.push({
      headerName: 'Stop Px',
      field: 'stopPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   columns.push({
      headerName: 'Last Px',
      field: 'lastPx',
      valueFormatter: defaultQuoteCellFormatter
   });

   columns.push({
      headerName: 'Side',
      field: 'side',
      valueFormatter: (params: ValueFormatterParams) => {
         return MarketSide[params.value];
      }
   });

   columns.push({
      headerName: 'Last Qty',
      field: 'lastQty',
      valueFormatter: defaultNumberCellFormatter
   });

   columns.push({
      headerName: 'Filled',
      field: 'filledQty',
      valueFormatter: defaultNumberCellFormatter
   });

   columns.push({
      headerName: 'Avg. Px',
      field: 'avgFillPrice',
      valueFormatter: defaultNumberCellFormatter
   });

   columns.push({
      headerName: 'Account',
      field: 'accountCode'
   });

   columns.push({
      headerName: 'Comment',
      field: 'comment'
   });

   columns.push({
      headerName: 'Timestamp',
      field: 'lastModifiedDate',
      valueFormatter: (params: ValueFormatterParams) => {
         return params.value
            ? formatDate(params.value, defaultTimestampFormat, 'en-US')
            : '';
      }
   });

   columns.push({
      headerName: 'ID',
      field: 'orderId'
   });

   return columns;
}
