import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class RemoveCommanderRule implements ProtocolCommand {
  constructor(
    public readonly id: string,
    public readonly correlationTicketId: string
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveCommanderRule`;
  }
}
