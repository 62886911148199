<div
  (click)="this.onToggleCollapsed()"
  *ngIf="this.collapsed"
  [class.different]="this.hasDifference()"
  [class.outdated]="this.isOutdated()"
  class="section-caption collapsed"
>
  {{ this.text }}
</div>

<div *ngIf="!this.collapsed"
     [class.different]="this.hasDifference()"
     [class.outdated]="this.isOutdated()"
     class="section-caption full"
>
  <div *ngIf="this.showZonesGridIcons" class="header-icons">
    
    <span (click)="this.onLinkStateChange()" class="link">
      <i *ngIf="!this.isLinked" [style.cursor]="'pointer'" class="fas fa-unlink"></i>
      <i *ngIf="this.isLinked" [style.cursor]="'pointer'" class="fas fa-link"></i>
    </span>
    
    <span (click)="this.onClearDataClicked()" class="clear">
      <i [style.cursor]="'pointer'" class="far fa-window-close"></i>
    </span>
  </div>
  
  <div class="header">
    <span (click)="this.onToggleCollapsed()">{{ this.text }}</span>
    <i (click)="this.onLoadDataClicked()" *ngIf="this.canShowZonesGridDownloadIcon()" [style.cursor]="'pointer'"
       class="fas fa-download"
    ></i>
    <i (click)="this.onExpandClicked()" *ngIf="this.showHedgePadIcons" [style.cursor]="'pointer'"
       class="fas fa-expand-arrows-alt"
    ></i>
  </div>

  <div class="menu">
    <i (click)="this.onShowZonesGridMenuClicked()" class="fas fa-bars"
       *ngIf="this.showZonesGridIcons && this.zonesGridParametersAvailable"
       [style.cursor]="'pointer'"
    ></i>
  </div>
  
</div>



