<div class="cashflow-tracker-section">

   <div class="header">
      <ets-section-header
         [header]="this.header"
         [collapsible]="true"
         (onCollapsedChanged)="this.onSectionCollapsedChanged($event)"
         >
      </ets-section-header>
   </div>

   <div class="content" [style.height.px]="this.sectionHeight">
      <ets-cashflow-tracking
         [container]="this.scope"
         (heightChanged)="this.recalculateHeight()">
      </ets-cashflow-tracking>   
   </div>

</div>