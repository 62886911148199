import { GlobalParametersOverviewBlockDto } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { isVoid, militaryTimeToAmPm, timespanToUserFriendly } from "projects/shared-components/utils";

export class GlobalParametersOverviewBlock {
   
   constructor(
      public readonly header: string,
   ) {
      this.backgroundColor = 'darkslategray';
   }
   
   backgroundColor: string;
   color: string;

   //
   adjustmentTimeMode: string = 'N/A'
   adjustmentTime: string = 'N/A';
   adjustmentTimezone: string = 'N/A';

   //
   orderType: string;         
   limitPrice: string;
   convertToMarket: boolean;     
   convertMode: string;
   convertTime: string;
   convertTimezone: string;
   replaceBeforeConvert: boolean;
   timesToReplace: number;
   replacePersistently: boolean;
   replaceEvery: string;
   rateOfChange: number;
   reverseTimeDirection: boolean;

   //
   esmSpread: string;
   esm2ndSpread: string;
   esmProtectiveOption: string;
   esm2ndProtectiveOption: string;


   get hasSettings(): boolean {
      return !isVoid(this.orderType);
   }

   get showTimezone(): boolean {
      return this.adjustmentTimeMode === 'Time Of Day';
   }

   get showLimitPrice(): boolean {
      return this.orderType === 'Limit';
   }

   get showConvertToMarketSettings(): boolean {
      return this.convertToMarket;
   }

   get showConvertTimezone(): boolean {
      return this.convertToMarket && this.convertMode === 'Convert At';
   }

   //
   setDto(dto: GlobalParametersOverviewBlockDto) {
      this.adjustmentTimeMode = dto.adjustmentTimeMode;

      this.adjustmentTime = dto.adjustmentTimeMode === 'Time Of Day'
         ? militaryTimeToAmPm(dto.adjustmentTime)
         : timespanToUserFriendly(dto.adjustmentTime);

      this.adjustmentTimezone = dto.adjustmentTimezone;

      //
      this.orderType = dto.orderType;
      this.limitPrice = dto.limitPrice;
      this.convertToMarket = dto.convertToMarket;  
      this.convertMode = dto.convertMode;
      this.convertTime = dto.convertMode === 'Convert At'
         ? militaryTimeToAmPm(dto.convertTime)
         : timespanToUserFriendly(dto.convertTime);
      this.convertTimezone = dto.convertTimezone;
      this.replaceBeforeConvert = dto.replaceBeforeConvert;
      this.timesToReplace = dto.timesToReplace;
      this.replacePersistently = dto.replacePersistently;
      this.replaceEvery =  timespanToUserFriendly(dto.replaceEvery);
      this.rateOfChange = dto.rateOfChange;
      this.reverseTimeDirection = dto.reverseTimeDirection;

      //
      this.esmSpread = dto.esmSpread;
      this.esm2ndSpread = dto.esm2ndSpread;
      this.esmProtectiveOption = dto.esmProtectiveOption;
      this.esm2ndProtectiveOption = dto.esm2ndProtectiveOption;
   }

   //
   reset() {

      this.adjustmentTimeMode = undefined; 
      this.adjustmentTime  = undefined; 
      this.adjustmentTimezone = undefined; 
      
      //
      this.orderType = undefined; 
      this.limitPrice   = undefined; 
      this.convertToMarket = undefined;   
      this.convertMode  = undefined; 
      this.convertTime  = undefined; 
      this.convertTimezone = undefined; 
      this.replaceBeforeConvert  = undefined; 
      this.timesToReplace  = undefined; 
      this.replacePersistently   = undefined; 
      this.replaceEvery = undefined;
      this.rateOfChange = undefined; 
      this.reverseTimeDirection  = undefined;

      //
      this.esmSpread = undefined;
      this.esm2ndSpread = undefined;
      this.esmProtectiveOption = undefined;
      this.esm2ndProtectiveOption = undefined;
   }
   
}