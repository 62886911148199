import { GetContextMenuItemsParams, GridOptions, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { AggregatedPositionsComponent } from './aggregated-positions.component';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { AggregatedPositionsGroupRowInnerRenderer } from './aggregated-positions-group-row-inner-renderer.component';
import { AggregatedPositionDto } from 'projects/shared-components/shell-communication/dtos/aggregated-position-dto.class';
import { getCacheKey } from 'projects/ess-worker/throttler/get-cache-key.function';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   liveQuoteFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { isInstrumentExpired } from '../utils';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';

export function getAggregatedPositionsGridModel(
   this: AggregatedPositionsComponent,
   displayNameService: TradingInstrumentDisplayNameService,
   tradingInstrumentsService: TradingInstrumentsService,
) {

   const columns = setupColumns(displayNameService);

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      suppressDragLeaveHidesColumns: true,

      suppressMakeColumnVisibleAfterUnGroup: false,

      frameworkComponents: {
         aggregatedPositionsGroupRowRenderer: AggregatedPositionsGroupRowInnerRenderer
      },

      groupRowRendererParams: {
         innerRenderer: 'aggregatedPositionsGroupRowRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: 'always',

      suppressAggFuncInHeader: true,
      
      groupUseEntireRow: true,
      

      onGridReady: args => this.onPositionsGridReady(args),

      getRowNodeId: (rowData: AggregatedPositionDto) => {
         return getCacheKey('AggregatedPositionDto', rowData);
      },

      getRowHeight: (params) => {
         let height = 27;

         if (!this.hideExpiredContracts) {
            return height;
         }

         let ticker = '---';

         if (!params.node.group) {
            if (params.data) {
               const data: AggregatedPositionDto = params.data;
               ticker = data.tradingInstrumentCode;
            }
         } else {
            if (params.node.field === 'tradingInstrumentCode') {
               ticker = params.node.key;
            }
         }

         if (ticker !== '---') {
            const ti = tradingInstrumentsService.getInstrumentByTicker(ticker);
            if (ti) {
               if (isInstrumentExpired(ti)) {
                  height = 0;
               }
            } else {
               height = 0;
            }
         }

         return height;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Hide Expired Contracts',
               checked: this.hideExpiredContracts,
               action: () => this.hideExpiredContracts = !this.hideExpiredContracts
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}

function setupColumns(displayNameService: TradingInstrumentDisplayNameService): Partial<AgGridColumn>[] {
   const columns: Partial<AgGridColumn>[] = [];

   if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      columns.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         rowGroup: true,
      });
      columns.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true,
         rowGroup: true,
      });
   } else {
      columns.push({
         headerName: 'Shell',
         field: 'shellId',
         valueGetter(params: ValueGetterParams) {
            return params.data.shellName + '^' + params.data.shellId;
         },
         valueFormatter: (params: ValueFormatterParams) => {
            const value = (params.value as string);
            return value ? value.split('^')[0] : 'N/A';
         },
         enableRowGroup: true,
         rowGroup: true
      });
   }

   columns.push(
      {
         field: 'positionClass',
         hide: true,
         rowGroup: true,
         enableRowGroup: true
      }
   );

   columns.push({
      headerName: 'Asset',
      field: 'tradingInstrumentCode',
      valueFormatter: (params: ValueFormatterParams) => {
         return displayNameService.getDisplayNameForTicker(params.value);
      },
      enableRowGroup: true,
      rowGroup: true
   });

   columns.push({
      headerName: 'Position',
      field: 'netPosition',
      valueFormatter: defaultNumberCellFormatter
   });

   columns.push({
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter
   });

   columns.push(Object.assign({
      headerName: 'Session Total P&L',
      field: 'sessionTotalPnL',
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition));

   columns.push(Object.assign({
      headerName: 'Acc. P&L',
      field: 'accumulatedTotalPnL',
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition));

   columns.push({
      headerName: 'Terminal',
      field: 'terminalName',
      enableRowGroup: true
   });

   columns.push({
      headerName: 'Account',
      field: 'accountCode',
      enableRowGroup: true
   });

   return columns;
}
