import { AlgoDescriptionBuilderBase } from '../algo-description-builder-base.class';
import { StrategyParameters } from '../../strategies/strategy-parameters.enum';
import { TrendLimitMode } from 'projects/shared-components/edit-strategy-dialog/parameters-controls/trend/trend-limit/trendlimit-algo-parameters-control';

export class TrendLimitAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
   getSymbolPropertyNames(): string[] {
      return ['symbol'];
   }

   getAlgoName(parameters: StrategyParameters): string {
      return 'Trend LIMIT';
   }

   getDescription(parameters: StrategyParameters): string {
      let res = '';
      const defaultValue = 'N/A';
      let symbol: string = parameters.symbol || defaultValue;

      if (symbol !== defaultValue) {
         symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
      }

      res += `[${symbol}]: `;

      res += `Mode-${TrendLimitMode[parameters.mode]}, `;

      if (parameters.filter) {
         res += `Fltr-${parameters.filter}, `;
      }

      res += `TL-${parameters.tradelevel || defaultValue}, `;
      res += `M-${parameters.multiplier || defaultValue}, `;
      res += `, F-${parameters.factor || defaultValue}, `;
      res += `, MP-${parameters.maxposition || defaultValue}`;

      return res;
   }
}
