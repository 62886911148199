import { Injectable } from '@angular/core';
import { ProtocolCommand } from '../service-model/protocol-command.interface';
import { HttpClient } from '@angular/common/http';
import { customJsonParser } from '../unspecific/custom-json-parser.function';
import { map } from 'rxjs/operators';
import { SessionService } from '../authentication/session-service.service';
import { CqrsService } from '../service-model/cqrs-service.interface';
import { WebCommand } from '../service-model/web-command.interface';
import { ProtocolQuery } from '../service-model/protocol-query.interface';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class ShellClientService implements CqrsService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly sessionService: SessionService
  ) {
    this.queryUrl = `${environment.shellRouterUrl}/processQuery`;
    this.commandUrl = `${environment.shellRouterUrl}/processCommand`;
  }

  private readonly queryUrl: string;
  private readonly commandUrl: string;

  processQuery<T>(query: ProtocolQuery, shellId = ''): Promise<T> {
    const requestData: string = JSON.stringify(query);
    const operation: string = query.getOperationName();

    const authToken = this.sessionService.authToken || '';

    if (!authToken) {
      throw new Error('Request failed: invalid session');
    }

    if (!shellId) {
      if (this.sessionService.connectedShell) {
        shellId = this.sessionService.connectedShell.shellId;
      } 
    }

    if (!shellId) {
      throw new Error('Request failed: cannot determine target shell for query');
    }

    const webCommand: WebCommand = {
      type: operation,
      data: requestData,
      token: authToken,
      extraData: shellId
    };

    return this.httpClient
      .post(this.queryUrl, webCommand)
      .pipe(
        map((x: any) => JSON.parse(x, customJsonParser))
      )
      .toPromise<T>();
  }

  processCommand(command: ProtocolCommand, shellId = ''): Promise<any> {
    const requestData: string = JSON.stringify(command);
    const operation: string = command.getOperationName();

    const authToken = this.sessionService.authToken || '';

    if (!authToken) {
      throw new Error('Request failed: invalid session');
    }

    if (!shellId) {
      if (this.sessionService.connectedShell) {
        shellId = this.sessionService.connectedShell.shellId;
      } 
    }

    if (!shellId) {
      throw new Error('Request failed: cannot determine target shell for query');
    }

    const webCommand: WebCommand = {
      type: operation,
      data: requestData,
      token: authToken,
      extraData: shellId
    };

    return this.httpClient.post(this.commandUrl, webCommand).toPromise();
  }
}
