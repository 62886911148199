import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { UnaccountedDataComponent } from './unaccounted-data.component';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultQuoteCellFormatter,
   defaultNumberCellFormatter,
   defaultTimestampFormat,
   defaultLoadingOverlayTemplate
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';


export function getUnaccountedOrdersGridModel(
   this: UnaccountedDataComponent,
   displayNameService: TradingInstrumentDisplayNameService
): object {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: '',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            width: 40
         },

         {
            headerName: 'Symbol',
            field: 'ticker',
            valueFormatter: (params: ValueFormatterParams) => {
               return displayNameService.getDisplayNameForTicker(params.value);
            },
            filter: 'agTextColumnFilter'
         },
         {
            headerName: 'Type',
            field: 'orderType',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderType[params.value];
            }
         },
         {
            headerName: 'Status',
            field: 'status',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderStatus[params.value];
            }
         },
         {
            headerName: 'Limit Px',
            field: 'limitPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Stop Px',
            field: 'stopPrice',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Side',
            field: 'side',
            valueFormatter: (params: ValueFormatterParams) => {
               return MarketSide[params.value];
            }
         },
         {
            headerName: 'Avg. Px',
            field: 'avgFillPrice',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Last Qty',
            field: 'lastQty',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Filled',
            field: 'filledQty',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Account',
            field: 'accountCode',
            filter: 'agTextColumnFilter'
         },
         {
            headerName: 'Terminal',
            field: 'terminalCode',
            filter: 'agTextColumnFilter'
         },
         {
            headerName: 'Comment',
            field: 'comment'
         },
         {
            headerName: 'Timestamp',
            field: 'lastModifiedDate',
            valueFormatter: (params: ValueFormatterParams) => {
               return params.value
                  ? formatDate(params.value, defaultTimestampFormat, 'en-US')
                  : '';
            }
         },
         {
            headerName: 'ID',
            field: 'orderId'
          },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowSelection: 'multiple',

      rowClass: 'ets-text-centered',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      onGridReady: (args) => this.onOrdersGridReady(args),

      getRowNodeId: (rowData: OrderDto) => rowData.orderId,

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         if (this.securityContext.allocateOrders) {
            menu.push(
               {
                  name: 'Allocate',
                  action: () => {
                     this.showAllocateOrdersDialog();
                  },
                  disabled: !params.node
               }
            );
         }
         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );
         return menu;
      }
   } as GridOptions;
}
