<table class="table">
  
  <ng-container *ngIf="this.hasCalls">
    <ng-container *ngTemplateOutlet="innerGrid; context: {$implicit: {side: 'Calls', transactions: this.callTransactions}}"></ng-container>
  </ng-container>
  
  <ng-container *ngIf="this.hasPuts">
    <ng-container *ngTemplateOutlet="innerGrid; context: {$implicit: {side: 'Puts', transactions: this.putTransactions}}"></ng-container>
  </ng-container>
  

</table>

<ng-template #innerGrid let-ctx>
  
  <tr class="header-row" style="background: black; color: white">
    <th colspan="5" class="col header current">"{{ ctx.side | uppercase }}"</th>
  </tr>
  
  <tr class="header-row">
    <th class="col header current">Current</th>
    <th class="col header current-px">Px</th>
    <th class="col header new">New</th>
    <th class="col header new-px">Px</th>
    <th class="col header trans">Trans</th>
  </tr>
  
  <tr *ngFor="let row of ctx.transactions" class="row">
    <td class="col current">
      {{ row.beforeState?.label }}
    </td>
    <td class="col current-px" [ngClass]="this.getPriceClass(this.getGroupTotal(row.beforeState))">
      {{ this.getGroupTotal(row.beforeState) | currency }}
    </td>
    <td class="col new">
      {{row.afterState?.label}}
    </td>
    <td class="col new-px" [ngClass]="this.getPriceClass(this.getGroupTotal(row.afterState))">
      {{ this.getGroupTotal(row.afterState) | currency }}
    </td>
    <td class="col trans" [ngClass]="this.getPriceClass(this.getGroupTransTotal(row))">
      {{( this.getGroupTransTotal(row)) | currency }}
    </td>
  </tr>
  
  <tr class="footer-row">
    <td class="col current">
      Total
    </td>
    <td class="col current-px" [ngClass]="this.getPriceClass(this.getSubTotals(ctx.transactions,'current'))">
      {{ this.getSubTotals(ctx.transactions, 'current')  | currency }}
    </td>
    <td class="col new">
      &nbsp;
    </td>
    <td class="col new-px" [ngClass]="this.getPriceClass(this.getSubTotals(ctx.transactions, 'new'))">
      {{ this.getSubTotals(ctx.transactions, 'new') | currency }}
    </td>
    <td class="col trans" (click)="this.showTransactionPopup(ctx.side)"
        [ngClass]="this.getPriceClass(this.getGrandTotal(ctx.side))"
    >
      {{ this.getGrandTotal(ctx.side) | currency }}
    </td>
  </tr>
</ng-template>


<ets-hg-transaction-popup>
</ets-hg-transaction-popup>