import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class AckStrategyIssue implements ProtocolCommand {
  public constructor(
    public readonly strategyId: string,
    public readonly issueId: string[]
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.AckStrategyIssue`;
  }
}
