import { ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { DetectMethodChanges, DetectSetterChanges } from "projects/shared-components/utils";
import { AssignableAdjustmentTemplatesService } from "./assignable-adjustment-templates.service";
import { AdjsutmentTemplateToClientsMap } from "projects/shared-components/shell-communication/shell-dto-protocol";

export class AssignTemplatesPopupModel {

   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _toastr: ToastrService,
      private _assignTemplatesService: AssignableAdjustmentTemplatesService
   ) {
   }

   //
   private _isVisible = false;
   get isVisible() {
      return this._isVisible;
   }
   @DetectSetterChanges()
   set isVisible(value) {
      this._isVisible = value;
   }

   //
   private _isLoading : boolean;
   get isLoading() : boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v : boolean) {
      this._isLoading = v;
   }


   //
   private _templates: AdjsutmentTemplateToClientsMap[] = [];
   get templates(): AdjsutmentTemplateToClientsMap[] {
      return this._templates;
   }
   @DetectSetterChanges()
   set templates(value: AdjsutmentTemplateToClientsMap[]) {
      this._templates = value;
   }

   //
   selectedTemplates: AdjsutmentTemplateToClientsMap[] = [];

   //
   @DetectMethodChanges({isAsync: true})
   async show(): Promise<void> {

      this.isVisible = true;

      this.isLoading = true;

      const templates = await this._assignTemplatesService
         .getSharedTemplates();

      this.templates = templates;

      this.isLoading = false;

   }

   //
   @DetectMethodChanges()
   close() {
      this.isVisible = false;
      this._templates = [];
      this.selectedTemplates.length = 0;
   }

   //
   onSelectionChanged() {
      this._changeDetector.detectChanges();
   }

   //
   async saveChanges(): Promise<void> {
      this.isLoading = true;

      try {
         await this._assignTemplatesService.saveSharedTemplatesAssignments(this.templates);
         this.close();
      } catch {
         this._toastr.error('Operation completed with errors');
      } finally {
         this.isLoading = false;
      }
   }

   //
   cancelChanges() {
      this.close();
   }
}
