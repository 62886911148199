import {Pipe, PipeTransform} from '@angular/core';
import {isValidNumber} from "../utils";

@Pipe({
    name: 'etsPriceFormatterPipe'
})

export class PriceFormatterPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (!isValidNumber(value)) {
            return '';
        }
        let prefix = '$';
        if (value < 0) {
            prefix = '-$';
        }
        return prefix + (value as number).toFixed(2);
    }
}