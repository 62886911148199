import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
   selector: '',
   template: `
<div *ngIf="this.field === 'isArchived'">
   <div *ngIf="isArchived" [style.color]="cssRules.archivedColor">
      <span>Archived Items</span>
   </div>
   <div *ngIf="!isArchived" [style.color]="cssRules.activeColor">
      <span>Active Items</span>
   </div>
</div>

<div *ngIf="this.field === 'terminalName'">
   <span>{{terminalName}}</span>
</div>
   `
})
export class PortfolioItemsGroupRowRendererComponent implements ICellRendererAngularComp {

   isArchived = false;

   cssRules = {
      archivedColor: 'yellow',
      activeColor: '#e8e2e2'
   };

   field: 'isArchived' | 'terminalName';

   terminalName: string;

   refresh(params: any): boolean {
      return false;
   }

   agInit(params: ICellRendererParams): void | Promise<void> {

      switch (params.node.field) {
         case 'isArchived':
            this.field = 'isArchived';
            this.isArchived = params.node.key.indexOf('Archived') >= 0 ? true : false;
            break;
         
         case 'terminalName':
            this.field = 'terminalName';
            this.terminalName = params.node.key;
            break;

         default:
            break;
      }
      
   }
}
