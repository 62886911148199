<div class="wrapper">
   <div class="block">
     <span [ngClass]="{ 
                         'important': this.rendererParams.node.key === 'true', 
                         'not-important': this.rendererParams.node.key === 'false',
                         'unread': this.unreadCount > 0
                       }">
         {{title}} ({{this.unreadCount}} unread of {{this.totalCount}} total)
     </span>
   </div>
 </div>