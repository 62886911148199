import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';
import { DebitSpreadCashFlowStrategyComponent } from './debit-spread/debit-spread-cashflow-strategy.component';
import { ProtectiveOptionCashFlowStrategyComponent } from './protective-option/protective-option-cashflow-strategy.component';

@NgModule({
   declarations: [
      DebitSpreadCashFlowStrategyComponent,
      ProtectiveOptionCashFlowStrategyComponent
   ],
   imports: [ 
      CommonModule,
      DevExtremeModule
   ],
   exports: [
      DebitSpreadCashFlowStrategyComponent,
      ProtectiveOptionCashFlowStrategyComponent
   ],
   providers: [],
})
export class CashFlowStrategiesModule {}