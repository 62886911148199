import { Component, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { getUnaccountedPositionsGridModel } from './positions-grid-model';
import { ToastrService } from 'ngx-toastr';
import { AllocateItemDialogComponent } from './allocate-dialog/allocate-item-dialog.component';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { 
  TradingInstrumentDisplayNameService, 
   
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { getUnaccountedOrdersGridModel } from './orders-grid-model';
import { UnaccountedDataDto } from 'projects/shared-components/shell-communication/dtos/unaccounted-data-dto.class';
import { GetUnaccountedData } from 'projects/shared-components/shell-communication/operations/unaccounted/get-unaccounted-data.class';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { 
  ShellConnectionStatusChangedUIMessage 
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { 
  UnaccountedPositionAllocatedMessageDto 
} from 'projects/shared-components/shell-communication/dtos/unaccounted-position-allocated-message-dto.class';
import { UnaccountedOrderAllocatedDto } from 'projects/shared-components/shell-communication/dtos/unaccounted-order-allocated-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';

interface UnaccountedDataSecurityContext {
  allocateOrders: boolean;
  allocatePositions: boolean;
}

@Component({
  selector: 'ets-unaccounted-data',
  templateUrl: 'unaccounted-data.component.html',
  styleUrls: ['unaccounted-data.component.scss']
})

export class UnaccountedDataComponent implements OnInit, OnDestroy {

  public constructor(
    private _displayNameService: TradingInstrumentDisplayNameService,
    private _shellClient: ShellClientService,
    private _toastr: ToastrService,
    private _messageBus: MessageBusService,
    private _accessControlSvc: AccessControlService,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger('UnaccountedDataComponent');
  }

  public securityContext: UnaccountedDataSecurityContext;

  @ViewChild(AllocateItemDialogComponent)
  public allocateItemDialog: AllocateItemDialogComponent;

  public contextPopupParent: any;

  public positionsGridModel: GridOptions;

  public ordersGridModel: GridOptions;

  public selectedTabIndex = 0;

  public tabs = [
    { text: 'Positions' },
    { text: 'Orders' }
  ];

  public isLoading: boolean;

  public isVisible = false;

  private _ordersIX: { [ix: string]: OrderDto } = {};

  private _unsubscriber = new Subject<any>();
  private _logger: Logger;

  private _positionsGrid: GridReadyEvent;

  private _ordersGrid: GridReadyEvent;

  private _pendingDataContainer: { isLoading: boolean, positions: PositionDto[], orders: OrderDto[] }
    = { isLoading: false, positions: [], orders: [] };

  private _positionsIX: { [ix: string]: PositionDto } = {};

  public ngOnInit(): void {
    const isAvailable = (id) =>
      this._accessControlSvc.isSecureElementAvailable(id);
    this.securityContext = {
      allocateOrders: isAvailable('704f7411-dfdb-4d58-8714-c14ed77608b9'),
      allocatePositions: isAvailable('531a8310-84f7-4064-9683-09ff8835e6e5')
    };

    this.positionsGridModel = getUnaccountedPositionsGridModel.bind(this)(this._displayNameService);
    this.ordersGridModel = getUnaccountedOrdersGridModel.bind(this)(this._displayNameService);
  }

  public async onShown() {
    this._resetState();
    this._subscribeToMessages();
    await this._tryLoadData();
  }

  public onPositionsGridReady(args: GridReadyEvent) {
    this._positionsGrid = args;
    this._positionsGrid.api.sizeColumnsToFit();
  }

  public async onOrdersGridReady(args: GridReadyEvent): Promise<void> {
    this._ordersGrid = args;
    this._ordersGrid.api.sizeColumnsToFit();
  }

  public selectTab($event: any) {
    this.selectedTabIndex = $event.itemIndex;
  }

  public showAllocateOrdersDialog() {
    const selectedRows = this._ordersGrid.api.getSelectedRows();
    if (!selectedRows.length) {
      this._toastr.warning('Please, select orders to allocate');
    } else {
      this.allocateItemDialog.selectedOrders = selectedRows;
      this.allocateItemDialog.isVisible = true;
    }
  }

  public showAllocatePositionDialog(data: any) {
    this.allocateItemDialog.selectedPosition = data;
    this.allocateItemDialog.isVisible = true;
  }

  public ngOnDestroy(): void {
  }

  public onHidden() {
    this.isVisible = false;
    this._resetState();
  }

  private async _tryLoadData(): Promise<void> {
    this.isLoading = true;

    this._pendingDataContainer = { isLoading: false, orders: [], positions: [] };
    this._pendingDataContainer.isLoading = true;

    try {
      const data: UnaccountedDataDto = await this._loadData();

      this._pendingDataContainer.isLoading = false;

      if (data.positions.length) {
        this._onPositionDto(data.positions);
      } else {
        this._positionsGrid.api.setRowData([]);
      }

      if (data.orders.length) {
        this._onOrderDto(data.orders);
      } else {
        this._ordersGrid.api.setRowData([]);
      }
    } catch (e) {
      const data = { error: e.stack || e };
      this._logger.error('Failed to load unaccounted data', data);
      this._toastr.error('"Unaccounted Data" dialog loaded with errors');
    } finally {
      this.isLoading = false;
      this._pendingDataContainer = { isLoading: false, positions: [], orders: [] };
    }
  }

  private async _loadData(): Promise<UnaccountedDataDto> {
    const qry = new GetUnaccountedData();
    return this._shellClient.processQuery<UnaccountedDataDto>(qry);
  }

  private _onPositionDto(dtos: PositionDto[]) {
    if (!this._pendingDataContainer.isLoading) {
      if (this._pendingDataContainer.positions.length) {
        dtos.unshift(...this._pendingDataContainer.positions);
      }
      this._processPositionUpdate(dtos);
    } else {
      this._pendingDataContainer.positions.push(...dtos);
    }
  }

  private _processPositionUpdate(dtos: PositionDto[]) {
    dtos.forEach(dto => {
      this._positionsIX[dto.positionId] = dto;
    });
    const positions = Object.keys(this._positionsIX).map(key => this._positionsIX[key]);
    this._positionsGrid.api.setRowData(positions);
  }

  private _onOrderDto(dtos: OrderDto[]) {
    if (!this._pendingDataContainer.isLoading) {
      if (this._pendingDataContainer.orders.length) {
        dtos.unshift(...this._pendingDataContainer.orders);
      }
      this._processOrderUpdate(dtos);
    } else {
      this._pendingDataContainer.orders.push(...dtos);
    }
  }

  private async _onShellConnectionStatusChanged(
    message: ShellConnectionStatusChangedUIMessage
  ): Promise<any> {
    await this._tryLoadData();
  }

  private _subscribeToMessages() {
    this._unsubscriber = new Subject<any>();

    this._messageBus.of<PositionDto[]>('PositionDto').pipe(
      map(msg => {
        return msg.payload.filter(
          x =>
            x.strategyId === EtsConstants.strategies.unknownStrategyId &&
            !x.isArchived
        );
      }),
      filter(positions => {
        return positions.length > 0;
      }),
      takeUntil(this._unsubscriber)
    ).subscribe(x => this._onPositionDto(x));

    this._messageBus.of<OrderDto[]>('OrderDto')
      .pipe(
        map(orders => {
          return orders[EtsConstants.strategies.unknownStrategyId];
        }),
        filter(x => {
          return !!x && x.length > 0;
        }),
        takeUntil(this._unsubscriber))
      .subscribe(x => this._onOrderDto(x));

    this._messageBus
      .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
      .pipe(
        filter(message => message.payload.isConnected),
        takeUntil(this._unsubscriber))
      .subscribe(x => this._onShellConnectionStatusChanged(x.payload));

    this._messageBus
      .of<UnaccountedPositionAllocatedMessageDto>('UnaccountedPositionAllocatedMessageDto')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(x => this._onPositionAllocatedMessageDto(x.payload));

    this._messageBus
      .of<UnaccountedOrderAllocatedDto>('UnaccountedOrderAllocatedDto')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(x => this._onOrderAllocatedDto(x.payload));
  }

  private _onOrderAllocatedDto(x: UnaccountedOrderAllocatedDto) {
    delete this._ordersIX[x.orderId];
    const positions = Object.keys(this._positionsIX).map(key => this._positionsIX[key]);
    this._ordersGrid.api.setRowData(positions);
    this._publishUnaccountedDataUpdate();
  }

  private _onPositionAllocatedMessageDto(x: UnaccountedPositionAllocatedMessageDto) {
    delete this._positionsIX[x.positionId];
    const positions = Object.keys(this._positionsIX)
      .map(key => this._positionsIX[key]);
    this._positionsGrid.api.setRowData(positions);
    this._publishUnaccountedDataUpdate();
  }

  private _publishUnaccountedDataUpdate() {
    const poses = Object.keys(this._positionsIX).length;
    const orders = Object.keys(this._ordersIX).length;

    if ((poses + orders) === 0) {
      this._messageBus.publish({
        topic: 'AllUnaccountedDataAllocatedUIMessage',
        payload: {}
      });
    }
  }

  private _processOrderUpdate(dtos: OrderDto[]) {
    dtos.forEach(dto => {
      this._ordersIX[dto.orderId] = dto;
    });
    const orders = Object.keys(this._ordersIX).map(key => this._ordersIX[key]);
    this._ordersGrid.api.setRowData(orders);
  }


  private _resetState() {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
      this._unsubscriber = null;
    }

    this._positionsIX = {};
    this._ordersIX = {};

    this._positionsGrid.api.setRowData([]);
    this._ordersGrid.api.setRowData([]);
  }
}
