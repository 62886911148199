<div class="navigation">

   <ets-section-header 
      [header]="this.header"
      >
   </ets-section-header>

   <div class="menu">

      <div class="item" *ngFor="let item of this.menuItems">
         <ng-container *ngIf="!item.isSeparator; else separator">
            <span (click)="this.onNavigateClicked(item)">{{item.text}}</span>
         </ng-container>
         <ng-template #separator>
            <span class="separator">&nbsp;</span>
         </ng-template>         
      </div>      

   </div>

</div>