import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {getSortingWeight, SortingRule} from "../model/sorting.rule";

@Injectable()
export class PackageComparisonHedgeboardSortingService {

    constructor() {
    }

    private _sortingRules: SortingRule[] = [];

    get sortingRules() : ReadonlyArray<SortingRule> {
        return this._sortingRules;
    }

    get sortingChanged$() : Observable<void> {
        return this._sortingChanged$;
    }
    private readonly _sortingChanged$ : Subject<void> = new Subject<void>();

    clearSortingRules(): void {
        this._sortingRules.length = 0;
        this.onSortingChanged();
    }

    removeAt(existingCallHedge: number) {
        this._sortingRules.splice(existingCallHedge, 1);
        this.onSortingChanged();
    }

    addSortingRule(rowFilter: SortingRule) {
        this._sortingRules.unshift(rowFilter);
        this.onSortingChanged();
    }

    private onSortingChanged() {
        this._sortingChanged$.next();
    }

    replaceAt(ix: number, rowFilter: SortingRule) {
        this._sortingRules[ix] = rowFilter;
        this.onSortingChanged();
    }

    remove(hasAdjustments: SortingRule[]) {
        hasAdjustments.forEach(x => {
            const ix = this._sortingRules.indexOf(x);
            if (ix === -1) {
                return;
            }
            this._sortingRules.splice(ix, 1);
        });
        this.onSortingChanged();
    }

    sortRules() {
        this._sortingRules.sort((a, b) => {
            const aW = getSortingWeight(a.attribute, 'packages');
            const bW = getSortingWeight(b.attribute, 'packages');
            return bW - aW;
        });
    }
}