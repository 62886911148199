import { NgModule } from '@angular/core';

import { TriggersComponent } from './triggers.component';
import { TriggerEditorComponent } from './trigger-editor/trigger-editor.component';
import { CommonModule } from '@angular/common';
import { EtsSymbolPickerModule } from 'projects/shared-components/symbol-picker/symbol-picker.module';
import { AgGridModule } from 'ag-grid-angular';
import { MovingAverageCrossoverComponent } from './trigger-editor/trigger-controls/ma-crossover/ma-crossover.component';
import { RSICrossesNumberComponent } from './trigger-editor/trigger-controls/rsi-crosses-number/rsi-crosses-number.component';
import { XCTLValuesInARowComponent } from './trigger-editor/trigger-controls/ctl-values-in-row/ctl-values-in-row.component';
import { PriceHitsXTriggerComponent } from './trigger-editor/trigger-controls/price-hits-x/price-hits-x.component';
import { XBarsClosedAboveBelowMAComponent } from './trigger-editor/trigger-controls/x-bars-close-above-below-ma/x-bars-close-above-below-ma.component';
import { MultiTriggerComponent } from './trigger-editor/trigger-controls/multi-trigger/multi-trigger.component';
import { PriceFilterTriggerComponent } from './trigger-editor/trigger-controls/price-filter/price-filter-trigger.component';
import { PriceMovesXTicksAndFilterTriggerComponent } from './trigger-editor/trigger-controls/price-moves-x-ticks-and-filter/price-moves-x-ticks-and-filter-trigger.component';
import { TriggerRebindDialogComponent } from './rebind-dialog/trigger-rebind-dialog.component';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      EtsSymbolPickerModule,
      AgGridModule.withComponents([])
   ],
   exports: [
      TriggersComponent,
      TriggerEditorComponent,
      TriggerRebindDialogComponent
   ],
   declarations: [
      TriggersComponent,
      TriggerEditorComponent,
      TriggerRebindDialogComponent,
      MovingAverageCrossoverComponent,
      RSICrossesNumberComponent,
      XCTLValuesInARowComponent,
      PriceHitsXTriggerComponent,
      XBarsClosedAboveBelowMAComponent,
      MultiTriggerComponent,
      PriceFilterTriggerComponent,
      PriceMovesXTicksAndFilterTriggerComponent,
   ],
   providers: [],
})
export class TriggersModule { }
