import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {CashFlowStrategyAdjustmentType} from "../model/CashFlowStrategyAdjustmentType";
import {isVoid} from "../../utils";
import {AdjustmentPricingGridComponent} from "../adjustment-pricing-grid.component";
import {MessageBusService} from "../../message-bus.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'ets-strategies-cell',
    templateUrl: 'strategies-cell.component.html',
    styleUrls: [
        '../adjustment-grid-common-style.scss',
        'strategies-cell.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategiesCellComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService
    ) {
    }

    private _subscription: Subscription;


    @Input() index: number;

    @Input() adjType: CashFlowStrategyAdjustmentType;

    @Input() root: AdjustmentPricingGridComponent;

    ngOnInit() {
        this._subscription = this._messageBus
            .of<string[]>('Apg.StrategyPriceUpdated')
            .subscribe(msg => this.onStrategyPriceUpdated(msg.payload));
    }

    ngAfterViewInit(): void {
        this._changeDetector.detach();
    }

    ngOnDestroy() {
        if (isVoid(this._subscription)) {
            return;
        }
        this._subscription.unsubscribe();
    }

    private onStrategyPriceUpdated(payload: string[]) {
        if (isVoid(this.adjType)) {
            return;
        }

        const hasIt = this.adjType.adjustments
            .some(x => payload.indexOf(x.optionStrategyCode) >= 0);

        if (hasIt) {
            this._changeDetector.detectChanges();
        }
    }
}
