import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {MessageBusService} from "../../message-bus.service";
import {PriceboxColumn} from "../model/PriceboxColumn";
import {isVoid} from "../../utils";
import {PriceboxSectionModel} from "../model/PriceboxSectionModel";
import {Subject, Subscription} from "rxjs";
import {VisibleOffsetsSettingsService} from "../visible-offsets/visible-offsets-settings.service";
import {take, takeUntil} from "rxjs/operators";
import {CashFlowAdjustment} from "../model/CashFlowAdjustment";
import {AdjustmentPricingGridComponent} from "../adjustment-pricing-grid.component";

@Component({
    selector: 'ets-pricebox-cell-component',
    templateUrl: 'pricebox-cell.component.html',
    styleUrls: [
        'pricebox-cell.component.scss',
        '../adjustment-grid-common-style.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceboxCellComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private readonly _changeDetector: ChangeDetectorRef,
                private readonly _messageBus: MessageBusService,
                private readonly _visibleOffsetService: VisibleOffsetsSettingsService
    ) {
    }

    private _subscription: Subscription;

    private _unsubscriber = new Subject();

    @Input() column: PriceboxColumn;
    @Input() priceboxSection: PriceboxSectionModel;
    @Input() root: AdjustmentPricingGridComponent;

    ngOnInit() {
        this._subscription = this._messageBus
            .of<string[]>('Apg.StrategyPriceUpdated')
            .subscribe(msg => this.onStrategyPriceUpdated(msg.payload));

        this._visibleOffsetService.priceBoxUpdated$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => {
                this._changeDetector.detectChanges();
            });
    }

    ngOnDestroy() {
        if (isVoid(this._subscription)) {
            return;
        }
        this._subscription.unsubscribe();

        this._unsubscriber.next();
        this._unsubscriber.complete();
    }

    ngAfterViewInit(): void {
        this._changeDetector.detach();
    }

    getPriceColorClass(price: number) {
        if (!price) {
            return undefined;
        }

        if (price > 0) {
            return 'credit';
        }

        if (price < 0) {
            return 'debit';
        }

        return undefined;
    }

    hasTheoPrices(adjustments: CashFlowAdjustment[]): boolean {
        if (isVoid(adjustments)) {
            return false;
        }

        const lastUsedSettings = this.root.priceboxSection.lastUsedSettings;
        if (isVoid(lastUsedSettings)) {
            if (this.root.settingsSection.globalSettings.theoreticalPriceMode !== 'Fill Blanks') {
                return false;
            }
        } else {
            if (lastUsedSettings[0].theoreticalPriceMode !== 'Fill Blanks') {
                return false;
            }
        }

        return adjustments.some(x => x.optionStrategyCode.indexOf('!') >= 0);
    }

    private onStrategyPriceUpdated(payload: string[]) {
        if (isVoid(this.column)) {
            return;
        }

        const hasIt = this.column.adjustments.some(x => payload.indexOf(x.optionStrategyCode) >= 0);

        if (hasIt) {
            this._changeDetector.detectChanges();
        }
    }
}
