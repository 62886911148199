import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { DetectMethodChanges, DetectSetterChanges, isValidNumber, isVoid } from 'projects/shared-components/utils';
import { BeforePositionModel } from '../model/BeforePositionModel';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { Subscription } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import { DynamicOffsetCalculator } from './DynamicOffsetCalculator';
import { ToastrService } from 'ngx-toastr';

interface PositionsDataProvider {
   positionsChanged$: EventEmitter<void>;
   getPositions(): BeforePositionModel[][];
}

@Component({
   selector: 'ets-dynamic-offsets-button',
   templateUrl: './dynamic-offsets-button.component.html',
   styleUrls: ['./dynamic-offsets-button.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicOffsetsButtonComponent implements OnInit, OnDestroy {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _lastQuoteCache: LastQuoteCacheService,
      private _messageBus: MessageBusService,
      private _toastr: ToastrService
   ) { 
      this._dynamicOffsetCalculator = new DynamicOffsetCalculator(this._lastQuoteCache, this._messageBus);
   }

   private readonly _dynamicOffsetCalculator: DynamicOffsetCalculator;
   private _watcher: Subscription;

   //

   get lastDynamicOffset(): number {
      return this._dynamicOffsetCalculator.lastOffset || 0;
   }

   //

   get applyButtonText(): string {
      
      let text = 'Apply Dynamic Offsets';

      if (this.isWatching && this.isOffsetsOutdated) {
         text += ` ($${this.lastDynamicOffset})`;
      }

      return text;
   }

   //

   @Output()
   resolvingDynamicOffsets = new EventEmitter();

   //

   @Output()
   dynamicOffsetsResolved = new EventEmitter<number>();

   //

   @Input()
   positionsProvider: PositionsDataProvider;

   //

   get isWatching(): boolean {
      return !isVoid(this._watcher);
   }

   //

   private _isOffsetsOutdated: boolean;
   public get isOffsetsOutdated(): boolean {
      return this._isOffsetsOutdated;
   }
   @DetectSetterChanges()
   public set isOffsetsOutdated(value: boolean) {
      this._isOffsetsOutdated = value;
   }

   //

   ngOnInit(): void {
      this.positionsProvider.positionsChanged$.subscribe(x => this.updateOffsetsCalculator());
   }

   //

   ngOnDestroy(): void {

      if (this._watcher) {
         this._watcher.unsubscribe();
      }

      if (this._dynamicOffsetCalculator) {
         this._dynamicOffsetCalculator.stop();
      }
   }

   //

   @DetectMethodChanges()
   onOffsetChanged(x: number) {
      
      if (!this.isWatching) {
         return;
      }

      if (!isValidNumber(x)) {
         return;
      }

      const poses = this.positionsProvider.getPositions();

      if (isVoid(poses)) {
         return;
      }

      const positions = poses[0];

      const so = positions.find(x => x.role === 'ShortOption');
      const spread = positions.find(x => x.role === 'SpreadLongLeg');

      const soStrike = so.strike;
      const spreadStrike = spread.strike;

      const offset = Math.abs(soStrike - spreadStrike);

      if (!isValidNumber(offset)) {
         return;
      }

      const isOffsetsOutdated = offset !== x;
      if (isOffsetsOutdated !== this.isOffsetsOutdated) {
         this.isOffsetsOutdated = isOffsetsOutdated;
      }
   }

   //

   @DetectMethodChanges()
   onWatchClicked() {
      
      if (!this.isWatching) {

         const tickers = this.positionsProvider
            .getPositions()
            .flatMap(x => x)
            .filter(x => x.role === 'ShortOption')
            .map(x => x.getTicker());

         this._dynamicOffsetCalculator.start(tickers);

         this._watcher = this._dynamicOffsetCalculator.offsetChanged$
            .subscribe(x => this.onOffsetChanged(x));

      } else {

         if (this._watcher) {
            this._watcher.unsubscribe();
            this._watcher = null;
            this.isOffsetsOutdated = undefined;
         }

      }
   }

   //

   @DetectMethodChanges({isAsync: true})
   async onApplyOffsetsClicked() {

      this.resolvingDynamicOffsets.emit();

      await delay(250);

      const tickers = this.positionsProvider
         .getPositions()
         .flatMap(x => x)
         .filter(x => x.role === 'ShortOption')
         .map(x => x.getTicker());
      

      let offset = await this._dynamicOffsetCalculator.getOffset(tickers);

      if (!isValidNumber(offset)) {
         offset = 0;
      }
      
      this._toastr.info(`Dynamic Offset Resolved: $${offset}`);

      this.dynamicOffsetsResolved.emit(offset);

   }

   //

   private updateOffsetsCalculator() {

      const tickers = this.positionsProvider
         .getPositions()
         .flatMap(x => x)
         .filter(x => x.role === 'ShortOption')
         .map(x => x.getTicker());

      if (tickers.length === 0) {
         return;
      }

      this._dynamicOffsetCalculator.start(tickers);
   }
}
