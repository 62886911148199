// tslint:disable: no-string-literal

import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { GlobalSettings } from 'projects/shared-components/adjustment-control-panel/adjustment-control-panel.component';
import { DateTimePickerMode } from 'projects/shared-components/datetime-picker/datetime-picker.model';
import { OrderLeg } from 'projects/shared-components/multi-trade-pad/multi-leg-order/order-leg.class';
import { ConvertToMarketSettings } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { DetectSetterChanges, DxValueChanged } from 'projects/shared-components/utils';
import { isNullOrUndefined } from 'util';
import { AAStrategyParameters, AAStrategyValidationContext, AttachStrategyDialogConfig, OrderLegWrapper } from '../../adjustment-strategy-dialog.model';

export class ShortOptionParameters implements AAStrategyParameters {
   constructor(
      private _cdRef: ChangeDetectorRef) { 

      this.displayName = 'Short Option Adjustment';
   }

   private _attachmentConfig: AttachStrategyDialogConfig;

   //
   displayName: string;

   //
   changed$: EventEmitter<void> = new EventEmitter();
   
   //
   private _strikeOffset = 0;
   get strikeOffset(): number {
      return this._strikeOffset;
   }
   set strikeOffset(v: number) {
      this._strikeOffset = v;
   }

   //
   private _expirationOffset = 0;
   get expirationOffset(): number {
      return this._expirationOffset;
   }
   set expirationOffset(v: number) {
      this._expirationOffset = v;
   }

   //
   private _minimumPrice: number;
   get minimumPrice(): number {
      return this._minimumPrice;
   }
   set minimumPrice(v: number) {
      this._minimumPrice = v;
   }
   
   //

   readonly orderTypes: { value: OrderType, name: string }[] = [
      {
         name: 'Market',
         value: OrderType.Market
      },
      {
         name: 'Limit',
         value: OrderType.Limit
      }
   ];

   //
   
   private _orderType = OrderType.Limit;
   get orderType(): OrderType {
      return this._orderType;
   }
   set orderType(v: OrderType) {
      this._orderType = v;
      if (this._orderType !== OrderType.Limit) {
         this.convertToMarket = false;
         this.limitPrice = null;
         this.minimumPrice = null;
      }
   }
   
   //
   strikeBuffer: number;

   //
   limitPrice: 'Bid' | 'Mid' | 'Ask';

   //
   atmNeutralZone: number;

   //
   moveShortTermDebitSpread = false;

   //
   operationModes = ['Isolated', 'Engage With CFS Template'];

   //
   private _operationMode: string;
   get operationMode(): string {
      return this._operationMode;
   }

   @DetectSetterChanges()
   set operationMode(v: string) {
      this._operationMode = v;

      if (this._operationMode !== 'Engage With CFS Template') {
         this.moveShortTermDebitSpread = false;
         this.strikeBuffer = null;
         this.useOptimalExpirationSelection = false;
      }
   }

   //
   private _convertToMarket = false;
   get convertToMarket(): boolean {
      return this._convertToMarket;
   }
   set convertToMarket(val: boolean) {
      this._convertToMarket = val;
      if (!this._convertToMarket) {
         this.convertToMarketSettings = {};
      }
   }

   //
   private _useOptimalExpirationSelection = false;
   get useOptimalExpirationSelection() : boolean {
      return this._useOptimalExpirationSelection;
   }
   @DetectSetterChanges()
   set useOptimalExpirationSelection(v : boolean) {
      this._useOptimalExpirationSelection = v;
      if (!v) {
         this.optimalExpirationLookForwardLimit = null;
         this.optimalExpirationPriceBuffer = null;
         this.optimalExpirationSelectionMode = null;
      }
   }
   

   //
   optionSellingDay: 'Expiration Day' | 'After Expiration';

   //
   get sellTimeActionModes(): string[] {
      const modes = ['Sell At'];
      if (this.optionSellingDay === 'Expiration Day') {
         modes.push('Sell [h:m:s] Before Close');
      } else if (this.optionSellingDay === 'After Expiration') {
         modes.push('Sell [h:m:s] After Open');
      }
      return modes;
   }

   //
   sellActionTimeMode: string;
   
   //
   sellActionTime: string;
   
   //
   sellActionTimezone: string;

   //
   get sellTimePickerMode(): DateTimePickerMode {
      const mode = this.sellActionTimeMode || '';
      if (mode.endsWith(' At')) {
         return 'time';
      } else if (mode.indexOf('[h:m:s]') >= 0) {
         return 'timespan';
      }
   }

   //
   get showTimezone(): boolean {
      const mode = this.sellActionTimeMode || '';
      return mode.endsWith('At');
   }

   //
   convertToMarketSettings: ConvertToMarketSettings = {};

   //
   optimalExpirationLookForwardLimit: number;

   //
   optimalExpirationSelectionModeList = ['Breakeven or Better', 'Better']
   
   optimalExpirationSelectionMode: 'Breakeven or Better' | 'Better';

   //
   optimalExpirationPriceBuffer: number;

   async init() {
      this._cdRef.detectChanges();
   }

   //
   onChange(ev: DxValueChanged<any>) {
      this._cdRef.detectChanges();
      if (ev.event) {
         this.changed$.emit();
      }
   }

   //
   getParameters(commonSettings?: GlobalSettings): string {

      const os = commonSettings ? commonSettings.orderSettings : this;
      const ctm = commonSettings ? commonSettings.orderSettings.convertToMarketSettings : this.convertToMarketSettings;

      let own = `(~)strikeoffset::=${this.strikeOffset * 100}`;

      own += `(~)expirationoffset::=${this.expirationOffset || ''}`;

      // own += `(~)expirationpreference::=${ (isNullOrUndefined(this.expirationPreference) ? '' : this.expirationPreference) }`;
      
      own += `(~)minimumprice::=${this.minimumPrice || ''}`;
      own += `(~)ordertype::=${os.orderType}`;
      own += `(~)strikebuffer::=${this.strikeBuffer || ''}`;
      own += `(~)atmneutralzone::=${this.atmNeutralZone || ''}`;
      own += `(~)limitprice::=${ commonSettings ? commonSettings.orderSettings.autoLimitPrice : this.limitPrice || ''}`;
      own += `(~)moveshorttermdebitspread::=${this.moveShortTermDebitSpread || ''}`;
      own += `(~)ctmactiontimemode::=${ctm.actionTimeMode || ''}`;
      own += `(~)ctmactiontime::=${ctm.actionTime || ''}`;
      own += `(~)ctmtimestoreplace::=${ctm.timesToReplace || ''}`;
      own += `(~)ctmreplacepersistently::=${ctm.replacePersistently || ''}`;
      own += `(~)ctmreplaceevery::=${ctm.replaceEvery || ''}`;
      own += `(~)ctmrateofchange::=${ctm.rateOfChange || ''}`;
      own += `(~)ctmreversedirection::=${ctm.reverseTimeDirection || ''}`;
      own += `(~)ctmtimezone::=${ctm.timezone || ''}`;
      own += `(~)operationmode::=${ commonSettings ? 'Engage With CFS Template' : this.operationMode || ''}`;
      own += `(~)optionsellingday::=${ commonSettings ? 'Expiration Day ' : this.optionSellingDay || ''}`;
      own += `(~)sellactiontimemode::=${ (commonSettings ? commonSettings.rollTimeSettings.rollTimeMode : this.sellActionTimeMode) || ''}`;
      own += `(~)sellactiontime::=${ (commonSettings ? commonSettings.rollTimeSettings.rollTime : this.sellActionTime) || ''}`;
      own += `(~)sellactiontimezone::=${ (commonSettings ? commonSettings.rollTimeSettings.rollTimezone : this.sellActionTimezone) || ''}`;

      if(commonSettings){
         const opt = commonSettings.optimalPricingUp;
         const optDown = commonSettings.optimalPricingDown;
         own += `(~)optimalexpirationlookforwardlimit::=${isNullOrUndefined(opt.optimalExpirationLookForwardLimit) ? '' : opt.optimalExpirationLookForwardLimit}`;
         own += `(~)optimalexpirationselectionmode::=${opt.optimalExpirationSelectionMode || ''}`;
         own += `(~)optimalexpirationpricebuffer::=${ isNullOrUndefined(opt.optimalExpirationPriceBuffer) ? '' : opt.optimalExpirationPriceBuffer}`;
         own += `(~)optimalexpirationlookforwardlimitdown::=${isNullOrUndefined(optDown.optimalExpirationLookForwardLimit) ? '' : optDown.optimalExpirationLookForwardLimit}`;
         own += `(~)optimalexpirationselectionmodedown::=${optDown.optimalExpirationSelectionMode || ''}`;
         own += `(~)optimalexpirationpricebufferdown::=${ isNullOrUndefined(optDown.optimalExpirationPriceBuffer) ? '' : optDown.optimalExpirationPriceBuffer}`;
         own += `(~)joinrollbuffer::=${ isNullOrUndefined(commonSettings.rollTimeSettings.joinRollBuffer) ? '' : commonSettings.rollTimeSettings.joinRollBuffer }`;
      } else {
         own += `(~)optimalexpirationlookforwardlimit::=${isNullOrUndefined(this.optimalExpirationLookForwardLimit)? '' : this.optimalExpirationLookForwardLimit}`;
         own += `(~)optimalexpirationselectionmode::=${this.optimalExpirationSelectionMode || ''}`;
         own += `(~)optimalexpirationpricebuffer::=${ isNullOrUndefined(this.optimalExpirationPriceBuffer) ? '' : this.optimalExpirationPriceBuffer}`;
         own += `(~)optimalexpirationlookforwardlimitdown::=`;
         own += `(~)optimalexpirationselectionmodedown::=`;
         own += `(~)optimalexpirationpricebufferdown::=`;
         own += `(~)joinrollbuffer::=`;
      }
      
      return own;
   }

   //
   setAttachmentConifg(_config: AttachStrategyDialogConfig) {
      this._attachmentConfig = _config;
   }

   //
   setParameters(strategy: StrategyModel) {
      const p = strategy.parameters;
    
      this.displayName = strategy.displayName;

      
      const expirationOffset = p['expirationoffset'];
      this.expirationOffset = expirationOffset ? parseInt(expirationOffset) : 0;

      // this.expirationPreference = p['expirationpreference'] as any;

      const strikeOffset = p['strikeoffset'];
      this.strikeOffset = strikeOffset ? parseInt(strikeOffset) / 100 : 0;

      const minimumPrice = p['minimumprice'];
      this.minimumPrice = minimumPrice ? parseFloat(minimumPrice) : null;

      const oType = p['ordertype'];
      this.orderType = oType ? parseInt(oType) : null;

      const limitPrice = p['limitprice'] as any;
      this.limitPrice = limitPrice;

      const atmNeutralZone = p['atmneutralzone'];
      this.atmNeutralZone = atmNeutralZone ? parseFloat(atmNeutralZone) : null;

      const moveShortTermDebitSpread = p['moveshorttermdebitspread'];
      this.moveShortTermDebitSpread = moveShortTermDebitSpread === 'true';


      const strikeBuffer = p['strikebuffer'];
      if (strikeBuffer) {
         this.strikeBuffer = parseFloat(strikeBuffer);
      }

      const ctmActionTimeMode = p['ctmactiontimemode'] as any;

      if (ctmActionTimeMode) {
         this.convertToMarket = true;
         this.convertToMarketSettings.actionTimeMode = ctmActionTimeMode;
         this.convertToMarketSettings.actionTime = p['ctmactiontime'];
         this.convertToMarketSettings.timezone = p['ctmtimezone'];

         const timesToReplace = p['ctmtimestoreplace'];
         this.convertToMarketSettings.timesToReplace = timesToReplace ? parseInt(timesToReplace) : null;

         const replacePersistently = p['ctmreplacepersistently'];
         this.convertToMarketSettings.replacePersistently = replacePersistently === 'true';

         if (timesToReplace || replacePersistently) {
            this.convertToMarketSettings.replaceBeforeConvert = true;
         }

         this.convertToMarketSettings.replaceEvery = p['ctmreplaceevery'];

         const roc = p['ctmrateofchange'];
         if (roc) {
            this.convertToMarketSettings.rateOfChange = parseFloat(roc);
         }

         const reverseTimeDirection = p['ctmreversedirection'];
         this.convertToMarketSettings.reverseTimeDirection = reverseTimeDirection === 'true';
      }

      this.operationMode = p['operationmode'];
      this.optionSellingDay = p['optionsellingday'] as any;
      this.sellActionTimeMode = p['sellactiontimemode'];
      this.sellActionTime = p['sellactiontime'];
      this.sellActionTimezone = p['sellactiontimezone'];

      this.optimalExpirationLookForwardLimit = parseInt(p['optimalexpirationlookforwardlimit']) || null;
      this.optimalExpirationSelectionMode = p['optimalexpirationselectionmode'] as any;
      this.optimalExpirationPriceBuffer = parseFloat(p['optimalexpirationpricebuffer'])  || null;

      if (this.optimalExpirationSelectionModeList.indexOf(this.optimalExpirationSelectionMode) >= 0) {
         this.useOptimalExpirationSelection = true;
      }
   }

   //
   setParameters2(strategy2: StrategyModel) {
      
   }

   //
   validate(validationContext: AAStrategyValidationContext): string[] {
      const errors = [];

      if (!this.displayName) {
         errors.push('"Display Name" is mandatory');
      }

      if (isNullOrUndefined(this.expirationOffset)) {
         errors.push('"Expiration Offset" is mandatory');
      }

      if (isNullOrUndefined(this.strikeOffset)) {
         errors.push('"Strike Offset" is mandatory');
      }

      const orderType = validationContext.globalSettings 
         ? validationContext.globalSettings.orderSettings.orderType 
         : this.orderType;

      if (isNullOrUndefined(orderType)) {
         errors.push('"Order Type" is mandatory');
      } else {
         if (orderType === OrderType.Limit) {
            const convertToMarket = validationContext.globalSettings 
               ? validationContext.globalSettings.orderSettings.convertToMarket 
               : this.convertToMarket;

            if (convertToMarket) {
               
               const ctmSettings = validationContext.globalSettings 
                  ? validationContext.globalSettings.orderSettings.convertToMarketSettings
                  : this.convertToMarketSettings;

               if (isNullOrUndefined(ctmSettings.actionTime)) {
                  errors.push('"Convert Time is requried"');
               } else {
                  const parts = ctmSettings.actionTime.split(':');
                  if (parts.length !== 3) {
                     errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                  } else {
                     const hours = parseInt(parts[0]);
                     const minutes = parseInt(parts[1]);
                     const seconds = parseInt(parts[2]);

                     if (isNaN(hours)) {
                        errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                     } else {
                        if (isNaN(minutes) || minutes > 59) {
                           errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                        } else {
                           if (isNaN(seconds) || seconds > 59) {
                              errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                           }
                        }
                     }
                  }
               }
            }
         }
      }

      if (!validationContext.globalSettings) {
         if (this.operationMode !== 'Isolated' && this.useOptimalExpirationSelection) {
            if (isNullOrUndefined(this.optimalExpirationSelectionMode)) {
               errors.push('"Optimal Expiration Selection Mode" is required')
            }
      
            if (isNullOrUndefined(this.optimalExpirationLookForwardLimit)) {
               errors.push('"Optimal Expiration\'s Max. Expirations Forward" is required')
            }
      
            if (isNullOrUndefined(this.optimalExpirationPriceBuffer)) {
               errors.push('"Optimal Expiration Price Buffer" is required')
            }
         }
      }


      return errors;
   }

   //
   reset() {
      this.displayName = 'Short Option Adjustment';
      this.expirationOffset = 0;
      this.strikeOffset = 0;
      this.minimumPrice = null;
      this.orderType = null;
      this.convertToMarketSettings = {};
      this.strikeBuffer = null;
      this.moveShortTermDebitSpread = false;
      this.optimalExpirationLookForwardLimit = null;
      this.useOptimalExpirationSelection = false;
      this.optimalExpirationPriceBuffer = null;
      this.optimalExpirationSelectionMode = null;
      this.optionSellingDay = null;
      this.sellActionTime = null;
      this.sellActionTimeMode = null;
      this.sellActionTimezone = null;
   }

   //
   filterSuitableLegs(legs: OrderLeg[]): OrderLegWrapper[] {
      return legs.map( l => ({ leg: l, isAvailable: true }) );
   }
}
