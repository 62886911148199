import { CrossThreadMessage } from './cross-thread-message.interface';

export class EtsWorker implements Worker {
   private readonly worker: Worker;

   constructor(s: string) {
      this.worker = new Worker(s);
   }

   onmessageerror: (this: Worker, ev: MessageEvent<any>) => any;

   get onerror(): (this: Worker, ev: ErrorEvent) => any {
      return this.worker.onerror;
   }

   set onerror(v: (this: Worker, ev: ErrorEvent) => any) {
      this.worker.onerror = v;
   }

   get onmessage() {
      return this.worker.onmessage;
   }

   set onmessage(v: (this: Worker, ev: MessageEvent) => any) {
      this.worker.onmessage = v;
   }

   postMessage<K extends CrossThreadMessage>(message: K): void;

   postMessage(message: any, transfer?: any[]): void {
      this.worker.postMessage(message, transfer);
   }

   terminate(): void {
      this.worker.terminate();
   }

   addEventListener<K extends 'message' | 'error'>(
      type: K,
      listener: (this: Worker, ev: WorkerEventMap[K]) => void,
      options?: boolean | AddEventListenerOptions
   ): void;

   addEventListener(
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | AddEventListenerOptions
   ): void;

   addEventListener(type: any, listener: any, options?: any) {
      this.worker.addEventListener(type, listener, options);
   }

   removeEventListener<K extends 'message' | 'error'>(
      type: K,
      listener: (this: Worker, ev: WorkerEventMap[K]) => void,
      options?: boolean | EventListenerOptions
   ): void;

   removeEventListener(
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | EventListenerOptions
   ): void;

   removeEventListener(type: any, listener: any, options?: any) {
      this.worker.removeEventListener(type, listener, options);
   }

   dispatchEvent(evt: Event): boolean {
      return this.worker.dispatchEvent(evt);
   }
}
