import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SymbolPickerTreeNode, SymbolPickerTreeService } from './symbol-picker-tree.service';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { TradingInstrumentKind } from '../trading-instruments/trading-instrument-kind.enum';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
import { DxAutocompleteComponent } from 'devextreme-angular/ui/autocomplete';

@Component({
   selector: 'ets-symbol-picker-dialog',
   templateUrl: './symbol-picker-dialog.component.html',
   styleUrls: ['./symbol-picker-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SymbolPickerDialogComponent implements OnInit, AfterViewInit {

   constructor(
      private _tiService: TradingInstrumentsService,
      private symbolTreeService: SymbolPickerTreeService,
      private _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef) {

      this.isVisible = false;
      this.tradingInstruments = symbolTreeService.getTree();
      this.instrumentSelected = new EventEmitter();
      this.closed = new EventEmitter();

   }
   private _isVisible;
   private _groupedInstruments: DataSource;
   private _groupedUnderlyings: DataSource;

   @ViewChild(DxContextMenuComponent, { static: false }) contextMenu: DxContextMenuComponent;
   
   @ViewChild(DxAutocompleteComponent, { static: false }) autoComplete: DxAutocompleteComponent;

   @Input() selectedInstrument: TradingInstrument;
   
   @Input() underlyings: boolean;

   get isVisible(): boolean { 
      return this._isVisible; 
   }

   @Input() set isVisible(v: boolean) {
      
      if (this._isVisible === v)  {
         return;
      }

      this._isVisible = v;
      
      setTimeout(() => this._changeDetector.detectChanges());
   }

   @Output() instrumentSelected: EventEmitter<TradingInstrument>;

   @Output() closed: EventEmitter<any>;

   get groupedData(): DataSource {
      return this.underlyings ? this._groupedUnderlyings : this._groupedInstruments;
   }

   tradingInstruments: SymbolPickerTreeNode[];

   isLoading = false;

   newInstrumentConfig: {
      isVisible?: boolean;
      isLoading?: boolean;
      instrument?: TradingInstrument;
   };

   ngOnInit() {
      this.setupTradingInstrumentsAutocomplete();
   }

   ngAfterViewInit() {
      this._changeDetector.detach();
   }
   

   private setupTradingInstrumentsAutocomplete() {

      const instruments = this._tiService.getAllTradingInstruments();

      this._groupedInstruments = new DataSource({
         store: new ArrayStore({
            key: 'instrument.ticker',
            data: instruments.filter(x => x.kind !== TradingInstrumentKind.Option).map(i => {
               const category = TradingInstrumentKind[i.kind];
               return {
                  category,
                  instrument: i
               };
            })
         }),
         group: 'category'
      });

      const uls = this._tiService.getUniqueUnderlyings();

      this._groupedUnderlyings = new DataSource({
         store: new ArrayStore({
            key: 'instrument.underlying',
            data: uls.map(i => {
               const category = i.kind;
               return {
                  category,
                  instrument: i
               };
            })
         }),
         group: 'category'
      });
   }


   onTradingInstrumentSelected(event) {
      if (event.selectedItem) {
         this.selectedInstrument = event.selectedItem.instrument;
      } else {
         this.selectedInstrument = null;
      }

      this._changeDetector.detectChanges();
   }


   onItemSelectionChanged($event): void {
      const node = $event.node;

      if (!node) {
         return;
      }

      this.selectedInstrument = $event.node.itemData.dataObject;

      this._changeDetector.detectChanges();
   }

   async okButtonClicked(): Promise<void> {

      const si = this.selectedInstrument;

      if (!si) {
         return;
      }

      this.instrumentSelected.emit(si);

      this.isVisible = false;

      this._changeDetector.detectChanges();
   }



   onClosed(): void {
      this.isVisible = false;

      this.closed.emit();

      this.selectedInstrument = null;

      if (this.autoComplete) {
         this.autoComplete.value = null;
      }

      this._changeDetector.detectChanges();
   }

   canSelectInstrument() {
      let canSelect = false;
      canSelect = !!this.selectedInstrument;
      return canSelect;
   }

   contextMenuItemClick(ev) {
      const menuItem = ev.itemData;

      const selectedInstrument = this.selectedInstrument;

      if (!selectedInstrument) {
         return;
      }


      if (menuItem.id === 'add') {

         this.symbolTreeService.addToFavorites(selectedInstrument);

      } else if (menuItem.id === 'remove') {

         this.symbolTreeService.removeFromFavorites(selectedInstrument);

      }

      this.tradingInstruments = null;

      setTimeout(() => {
         this.tradingInstruments = this.symbolTreeService.getTree();
         this._changeDetector.detectChanges();
      });
   }

   onAddToFavoritesClicked() {
      if (!this.selectedInstrument) {
         this._toastr.info('Instrument not selected');
         return;
      }

      this.contextMenuItemClick({ itemData: { id: 'add' } });
   }

   treeViewItemContextMenu(e) {
      console.log('onMenuItem', e);

      const node = e.itemData as SymbolPickerTreeNode;

      const isLeafNode = !!node.dataObject && node.dataObject === this.selectedInstrument;
      const isFavNode = node.categoryLabel === 'favorite';

      const contextMenu = this.contextMenu.instance;
      contextMenu.option('items[0].visible', isLeafNode && !isFavNode);
      contextMenu.option('items[1].visible', isLeafNode && isFavNode);

      this._changeDetector.detectChanges();
   }
}
