import { CqrsService } from '../service-model/cqrs-service.interface';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../authentication/session-service.service';
import { environment } from '../environments/environment';
import { Logger } from '../logging/logger.interface';
import { ProtocolQuery } from '../service-model/protocol-query.interface';
import { WebCommand } from '../service-model/web-command.interface';
import { map } from 'rxjs/operators';
import { ProtocolCommand } from '../service-model/protocol-command.interface';
import { Injectable } from '@angular/core';
import { LoggerService } from '../logging/logger-factory.service';
import { customJsonParser } from '../unspecific/custom-json-parser.function';

@Injectable({ providedIn: 'root' })
export class ClientConsoleClientService implements CqrsService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _sessionService: SessionService,
    loggerService: LoggerService
  ) {
    this._queryUrl = `${environment.ccServiceUrl}/processQuery`;
    this._commandUrl = `${environment.ccServiceUrl}/processCommand`;
    this._logger = loggerService.createLogger('ClientConsoleService');
  }

  private readonly _logger: Logger;
  private readonly _queryUrl: string;
  private readonly _commandUrl: string;

  processQuery<T>(query: ProtocolQuery): Promise<T> {
    const requestData: string = JSON.stringify(query);
    const operation: string = query.getOperationName();
    const authToken = this._sessionService.authToken || '';

    const webCommand: WebCommand = { type: operation, data: requestData, token: authToken };

    return this._httpClient
      .post<any>(this._queryUrl, webCommand)
      .pipe(
        map((x: string) => {
          return JSON.parse(x, customJsonParser);
        }),
        map((x: any) => {
          return x as T;
        })
      )
      .toPromise<T>();
  }

  processCommand(command: ProtocolCommand): Promise<any> {
    const requestData: string = JSON.stringify(command);
    const operation: string = command.getOperationName();
    const authToken = this._sessionService.authToken || '';

    const webCommand: WebCommand = { type: operation, data: requestData, token: authToken };

    return this._httpClient.post(this._commandUrl, webCommand).toPromise();
  }
}
