import {AlgoModel} from './algo-model.class';

export class AlgoFamily {
  constructor(public dn: string) {
    this.displayName = dn;
    this.algoes = [];
  }
  public displayName: string;
  public algoes: AlgoModel[];
}
