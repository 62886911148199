import { GridOptions } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from '../ag-grid-contrib';

export class ByBrokerModel {

   constructor() {
      this.gridOptions = getByBrokerGridOptions.bind(this)();
   }

   gridOptions: GridOptions;
}


function getByBrokerGridOptions(this: ByBrokerModel): GridOptions {
   return {
      rowData: [],
      defaultColDef: centeredColumnDef,
      overlayLoadingTemplate: defaultLoadingOverlayTemplate,
      rowClass: 'ets-text-centered',
      rowSelection: 'single',
      rowModelType: 'clientSide',
      suppressCellSelection: true,
      columnDefs: [
         {
            headerName: 'Ticker',
         },
         {
            headerName: 'IBKR'
         },
         {
            headerName: 'GAIN'
         },
      ],
      onGridReady: args => {},
      getRowNodeId: (data: any) => {
         return data.strategyId;
      },
   };
}
