import { ChangeDetectorRef } from "@angular/core";
import { GlobalSettings } from "projects/shared-components/adjustment-control-panel/adjustment-control-panel.component";
import { CashFlowStrategy } from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import { AAStrategyValidationContext } from "projects/shared-components/adjustment-strategy-dialog/adjustment-strategy-dialog.model";
import { DateTimePickerMode } from "projects/shared-components/datetime-picker/datetime-picker.model";
import { OptionsChainService } from "projects/shared-components/option-chains.service";
import { ConvertToMarketSettings, FutureTimeSettings, OptionExpirationDescriptor } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { StrategyModel } from "projects/shared-components/strategies/strategy-model";
import { OrderType } from "projects/shared-components/trading-model/order-type.enum";
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from "projects/shared-components/utils";

export type RollTimeMode =  'Time Of Day' | '[H:M:S] Before Close' | '[H:M:S] After Open';

export type ExpirationOffsetMode = 'Calendar Days' | 'Sequential';

export type LimitPrice = 'Bid' | 'Mid' | 'Ask';

export interface ManualModeParameters {
   expiration?: OptionExpirationDescriptor;
   spreadWidth?: number;
   atmOffset?: number;
   atmNeutralZone?: number;
   orderType?: number;
   autoLimitPrice?: LimitPrice;
   convertToMarket?: boolean;
   isFutureOrder?: boolean;
   convertToMarketSettings: ConvertToMarketSettings;
   futureTimeSettings: FutureTimeSettings;
}

export class PutDebitSpreadRollStrategyParameters {

   constructor(
         private readonly _changeDetector: ChangeDetectorRef
       , private _chains: OptionsChainService) {
   }

   private _expirationOffsetModesRegular = [
      'Calendar Days',
      'Sequential'
   ];

   private _expirationOffsetModesAutomated =[
      'Calendar Days',
   ];

   //
   
   private _tickerForChains : string;
   get tickerForChains() : string {
      return this._tickerForChains;
   }
   @DetectSetterChanges()
   set tickerForChains(v : string) {
      this._tickerForChains = v;
   }
   

   //

   private _manualMode = false;
   get manualMode() : boolean {
      return this._manualMode;
   }

   @DetectSetterChanges()
   set manualMode(v : boolean) {
      this._manualMode = v;
      if (!v) {
         this.manualModeSettings = {
            convertToMarket: false,
            isFutureOrder: false,
            convertToMarketSettings: {},
            futureTimeSettings: {}
         }
      } else {
         if (this.tickerForChains) {
            if (this._chains) {
               const undrelying = this.extractUnderlying(this.tickerForChains);
               this._chains.getChain(undrelying)
                  .then(x => {
                     this.expirations = x.expirations;
                  })
                  .catch(e => {
                     console.error(e);
                  });
            }
         }

         this.fillManualModeParameters();
      }
   }

   //
   private _expirations : OptionExpirationDescriptor[];
   get expirations() : OptionExpirationDescriptor[] {
      return this._expirations;
   }

   @DetectSetterChanges()
   set expirations(v : OptionExpirationDescriptor[]) {
      this._expirations = v;
   }
   
   //
   manualModeSettings: ManualModeParameters = { 
      convertToMarket: false,
      isFutureOrder: false,
      convertToMarketSettings: {},
      futureTimeSettings: {}
   };

   //
   strategy: CashFlowStrategy;
   
   //
   get isSingleDirection(): boolean {
      return this.strategy !== 'Calls & Puts';
   }

   //   
   displayName = 'Put Debit Spread Roll';

   //
   get dateTimePickerModeForFutureSettings(): DateTimePickerMode {
      if (this.manualModeSettings) {
         if (this.manualModeSettings.futureTimeSettings) {
            if (this.manualModeSettings.futureTimeSettings.actionTimeMode) {
               if (this.manualModeSettings.futureTimeSettings.actionTimeMode.endsWith(' At')) {
                  return 'datetime';
               } else {
                  return 'timespan';
               }
            }
         }
      }

      return undefined;
   }


   //
   rollDaysBeforeExpiration: number;
   rollDaysBeforeExpiration2: number;

   //

   private _rollTimeMode : RollTimeMode;
   get rollTimeMode() : RollTimeMode {
      return this._rollTimeMode;
   }

   @DetectSetterChanges()
   set rollTimeMode(v : RollTimeMode) {
      this._rollTimeMode = v;
   }
   
   //

   rollTimeModes: RollTimeMode[] = [
      'Time Of Day',
      '[H:M:S] Before Close',
      '[H:M:S] After Open',
   ];

   //

   rollTime: string;

   //

   rollTimezone: string;

   //

   get rollTimePickerMode(): string {

      if (!this.rollTimeMode) {
         return undefined;
      }

      if (this.rollTimeMode === 'Time Of Day') {
         return 'time';
      }

      return 'timespan';

   }

   //

   get rollTimePickerShowTimezone(): boolean {
      return this.rollTimePickerMode === 'time';
   }

   //
   
   private _expirationOffsetMode : ExpirationOffsetMode;
   get expirationOffsetMode() : ExpirationOffsetMode {
      return this._expirationOffsetMode;
   }

   @DetectSetterChanges()
   set expirationOffsetMode(v : ExpirationOffsetMode) {
      this._expirationOffsetMode = v;
   }

   //
   private _expirationOffsetValue : number;
   get expirationOffsetValue() : number {
      return this._expirationOffsetValue;
   }
   @DetectSetterChanges()
   set expirationOffsetValue(v : number) {
      this._expirationOffsetValue = v;
   }
   
   //
   expirationOffsetValue2: number;

   //
   get expirationValueOffsetFormat(): string {
      if (this.expirationOffsetMode === 'Sequential') {
         return undefined;
      }

      return ' #0d';
   }

   //
   expirationPreference: 'Near' | 'Far' = 'Near';

   //
   private _spreadWidth : number;
   get spreadWidth() : number {
      return this.manualMode ? this.manualModeSettings.spreadWidth : this._spreadWidth;
   }
   @DetectSetterChanges()
   set spreadWidth(v : number) {
      if (this.manualMode) {
         this.manualModeSettings.spreadWidth = v;
      } else {
         this._spreadWidth = v;
      }
   }

   //
   spreadWidth2: number;
   
   // 
   private _atmOffset : number;
   get atmOffset() : number {
      return this.manualMode ? this.manualModeSettings.atmOffset : this._atmOffset;
   }
   @DetectSetterChanges()
   set atmOffset(v : number) {
      if (this.manualMode) {
         this.manualModeSettings.atmOffset = v;
      } else {
         this._atmOffset = v;
      }
   }

   //
   atmOffset2: number;

   //   
   private _atmNeutralZone : number;
   get atmNeutralZone() : number {
      return this.manualMode ? this.manualModeSettings.atmNeutralZone : this._atmNeutralZone;
   }
   @DetectSetterChanges()
   set atmNeutralZone(v : number) {
      if (this.manualMode) {
         this.manualModeSettings.atmNeutralZone = v;
      } else {
         this._atmNeutralZone = v;
      }
   }
   
      
   //
   private _orderType : OrderType;
   get orderType() : OrderType {
      return this.manualMode ? this.manualModeSettings.orderType : this._orderType;
   }
   @DetectSetterChanges()
   set orderType(v : OrderType) {
      if (this.manualMode) {
         this.manualModeSettings.orderType = v;
      } else {
         this._orderType = v;
      }
   }
   
   //
   orderTypes = [
      {
         text: 'Limit',
         value: OrderType.Limit
      },
      {
         text: 'Market',
         value: OrderType.Market
      }
   ];

   //
   private _autoLimitPrice : LimitPrice;
   get autoLimitPrice() : LimitPrice {
      return this.manualMode ? this.manualModeSettings.autoLimitPrice : this._autoLimitPrice;
   }
   @DetectSetterChanges()
   set autoLimitPrice(v : LimitPrice) {
      if (this.manualMode) {
         this.manualModeSettings.autoLimitPrice = v;
      } else {
         this._autoLimitPrice = v;
      }
   }
   
   //
   limitPrices: LimitPrice[] = [
      'Bid',
      'Mid',
      'Ask'
   ];

   //
   private _convertToMarket = false;
   get convertToMarket() : boolean {
      return this.manualMode ? this.manualModeSettings.convertToMarket : this._convertToMarket;
   }

   @DetectSetterChanges()
   set convertToMarket(v : boolean) {
      if (this.manualMode) {
         this.manualModeSettings.convertToMarket = v;
         if (!v) {
            this.manualModeSettings.convertToMarketSettings = {};
         }
      } else {
         this._convertToMarket = v;
      
         if (!v) {
            this.convertToMarketSettings = {};
         }   
      }
   }

   //
   private _convertToMarketSettings = {};
   get convertToMarketSettings() : ConvertToMarketSettings {
      return this.manualMode ? this.manualModeSettings.convertToMarketSettings : this._convertToMarketSettings;
   }
   @DetectSetterChanges()
   set convertToMarketSettings(v : ConvertToMarketSettings) {
      if (this.manualMode) {
         this.manualModeSettings.convertToMarketSettings = v;
      } else {
         this._convertToMarketSettings = v;
      }
   }

   //   
   get isFutureOrder() : boolean {
      return this.manualModeSettings.isFutureOrder || false;
   }
   @DetectSetterChanges()
   set isFutureOrder(v : boolean) {
      this.manualModeSettings.isFutureOrder = v;
   }
   
   //
   getParameters(commonSettings?: GlobalSettings, strategyNo?: number): string {

      if (this.manualMode) {
         return JSON.stringify(this.manualModeSettings);
      }

      let own = `rolldaysbeforeexpiration::=${ isNullOrUndefined(this.rollDaysBeforeExpiration) ? '' : this.rollDaysBeforeExpiration}`;

      if (commonSettings) {
         const rollSettings = commonSettings.rollTimeSettings;
         own += `(~)rolltimemode::=${rollSettings.rollTimeMode || ''}`;
         own += `(~)rolltime::=${rollSettings.rollTime || ''}`;
         own += `(~)rolltimezone::=${rollSettings.rollTimezone || ''}`;
      } else {
         own += `(~)rolltimemode::=${this.rollTimeMode || ''}`;
         own += `(~)rolltime::=${this.rollTime || ''}`;
         own += `(~)rolltimezone::=${this.rollTimezone || ''}`;
      }
      
      own += `(~)expirationoffsetmode::=${this.expirationOffsetMode || ''}`;
      own += `(~)expirationoffsetvalue::=${this.expirationOffsetValue}`;
      own += `(~)expirationpreference::=${this.expirationPreference}`;

      if (strategyNo && strategyNo === 2) {
         own += `(~)spreadwidth::=${this.spreadWidth2}`;
      } else {
         own += `(~)spreadwidth::=${this.spreadWidth}`;
      }
      own += `(~)atmoffset::=${this.atmOffset}`;
      // own += `(~)atmneutralzone::=${this.atmNeutralZone}`;
      own += `(~)atmneutralzone::=0.5`;

      const os = commonSettings ? commonSettings.orderSettings : this;
      own += `(~)ordertype::=${os.orderType}`;
      own += `(~)autolimitprice::=${os.autoLimitPrice || ''}`;


      const ctm = commonSettings ? commonSettings.orderSettings : this;
      own += `(~)ctmactiontimemode::=${ctm.convertToMarketSettings.actionTimeMode || ''}`;
      own += `(~)ctmactiontime::=${ctm.convertToMarketSettings.actionTime || ''}`;
      own += `(~)ctmtimestoreplace::=${ctm.convertToMarketSettings.timesToReplace || ''}`;
      own += `(~)ctmreplacepersistently::=${ctm.convertToMarketSettings.replacePersistently || ''}`;
      own += `(~)ctmreplaceevery::=${ctm.convertToMarketSettings.replaceEvery || ''}`;
      own += `(~)ctmrateofchange::=${ctm.convertToMarketSettings.rateOfChange || ''}`;
      own += `(~)ctmreversedirection::=${ctm.convertToMarketSettings.reverseTimeDirection || ''}`;
      own += `(~)ctmtimezone::=${ctm.convertToMarketSettings.timezone || ''}`;
   
      return own;
   }

   //

   @DetectMethodChanges()
   setParameters(strategy: StrategyModel) {
      const p = strategy.parameters as any;
    
      this.displayName = strategy.displayName;

      this.rollDaysBeforeExpiration = parseInt(p.rolldaysbeforeexpiration);
      this.rollTimeMode = p.rolltimemode;
      this.rollTimezone = p.rolltimezone;
      this.rollTime = p.rolltime;

      this.expirationOffsetMode = p.expirationoffsetmode;
      if (!isNullOrUndefined(p.expirationoffsetvalue)) {
         this.expirationOffsetValue = parseInt(p.expirationoffsetvalue);
      }

      if (!isNullOrUndefined(p.spreadwidth)) {
         this.spreadWidth = parseInt(p.spreadwidth);
      }

      if (!isNullOrUndefined(p.atmoffset)) {
         this.atmOffset = parseInt(p.atmoffset);
      }

      if (!isNullOrUndefined(p.atmneutralzone)) {
         this.atmNeutralZone = parseFloat(p.atmneutralzone);
      }

      if (!isNullOrUndefined(p.ordertype)) {
         this.orderType = parseInt(p.ordertype);
      }

      this.autoLimitPrice = p.autolimitprice;

      if (p.ctmactiontimemode) {
         this.convertToMarketSettings.actionTimeMode = p.ctmactiontimemode;
         this.convertToMarketSettings.actionTime = p.ctmactiontime;
         this.convertToMarketSettings.timezone = p.ctmtimezone;

         const timesToReplace = p.ctmtimestoreplace;
         this.convertToMarketSettings.timesToReplace = timesToReplace ? parseInt(timesToReplace) : null;

         const replacePersistently = p.ctmreplacepersistently;
         this.convertToMarketSettings.replacePersistently = replacePersistently === 'true';

         if (timesToReplace || replacePersistently) {
            this.convertToMarketSettings.replaceBeforeConvert = true;
         }

         this.convertToMarketSettings.replaceEvery = p.ctmreplaceevery;

         const roc = p.ctmrateofchange;
         if (roc) {
            this.convertToMarketSettings.rateOfChange = parseFloat(roc);
         }

         const reverseTimeDirection = p.ctmreversedirection;
         this.convertToMarketSettings.reverseTimeDirection = reverseTimeDirection === 'true';

         this.convertToMarket = true;
      }  
   }

   //
   @DetectMethodChanges()
   setParameters2(strategy: StrategyModel) {

      const p = strategy.parameters as any;
      
      if (!isNullOrUndefined(p.spreadwidth)) {
         this.spreadWidth2 = parseInt(p.spreadwidth);
      }
   }

   //
   validate(validationContext: AAStrategyValidationContext): string[] {
      const errors = [];

      if (!this.displayName) {
         errors.push('"Display Name" is mandatory');
      }

      if (isNullOrUndefined(this.rollDaysBeforeExpiration) || isNaN(this.rollDaysBeforeExpiration)) {
         errors.push('"Roll Days Before Expiration" is mandatory');
      }

      const rollTime = validationContext.globalSettings 
         ? validationContext.globalSettings.rollTimeSettings.rollTime
         : this.rollTime;
         
      const rollTimeMode = validationContext.globalSettings 
         ? validationContext.globalSettings.rollTimeSettings.rollTimeMode
         : this.rollTimeMode;

      const rollTimeZone = validationContext.globalSettings
         ? validationContext.globalSettings.rollTimeSettings.rollTimezone
         : this.rollTimezone;

      if (isNullOrUndefined(rollTime)) {
         errors.push('"Roll Time is mandatory"');
      }

      if (rollTimeMode === 'Time Of Day') {
         if (!rollTimeZone) {
            errors.push('"Timezone" must be provided for roll time');
         }
      }

      if (isNullOrUndefined(this.expirationOffsetMode)) {
         errors.push('"Expiration Offset Mode" is mandatory');
      }

      if (isNullOrUndefined(this.expirationOffsetValue) || this.expirationOffsetValue === 0
         || isNaN(this.expirationOffsetValue)) {
         errors.push('"Expiration Offset Value" is mandatory');
      }

      if (isNullOrUndefined(this.spreadWidth) || this.spreadWidth === 0
       || isNaN(this.spreadWidth)) {
         errors.push('"Spread Width" is mandatory and must be greater than 0');
      }

      if (isNullOrUndefined(this.atmOffset) || isNaN(this.atmOffset) ) {
         errors.push('"ATM Offset" is mandatory');
      }

      const orderType = validationContext.globalSettings 
         ? validationContext.globalSettings.orderSettings.orderType 
         : this.orderType;

      if (isNullOrUndefined(orderType)) {
         errors.push('"Order Type" is mandatory');
      } else {
         if (orderType === OrderType.Limit) {
            const convertToMarket = validationContext.globalSettings 
               ? validationContext.globalSettings.orderSettings.convertToMarket 
               : this.convertToMarket;

            if (convertToMarket) {
               
               const ctmSettings = validationContext.globalSettings 
                  ? validationContext.globalSettings.orderSettings.convertToMarketSettings
                  : this.convertToMarketSettings;

               if (isNullOrUndefined(ctmSettings.actionTime)) {
                  errors.push('"Convert Time is requried"');
               } else {
                  const parts = ctmSettings.actionTime.split(':');
                  if (parts.length !== 3) {
                     errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                  } else {
                     const hours = parseInt(parts[0]);
                     const minutes = parseInt(parts[1]);
                     const seconds = parseInt(parts[2]);

                     if (isNaN(hours)) {
                        errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                     } else {
                        if (isNaN(minutes) || minutes > 59) {
                           errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                        } else {
                           if (isNaN(seconds) || seconds > 59) {
                              errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                           }
                        }
                     }
                  }
               }
            }
         }
      }

      if (this.expirationOffsetMode === 'Calendar Days') {
         let diff = this.expirationOffsetValue - this.rollDaysBeforeExpiration;
         
         const errorMessage = 'Roll vs Offset validation failed';
         if (isNaN(diff)) {
            errors.push(errorMessage);
         } else {
            if (diff <=  0) {
               errors.push(errorMessage);
            }
         }
      }

      if (isNullOrUndefined(this.expirationPreference)) {
         errors.push('"Expiration Preference" is mandatory');
      }

      return errors;
   }

   //
   reset() {
      this.displayName = 'Put Debit Spread Roll';
      this.expirationOffsetMode = null;
      this.expirationOffsetValue = null;
      this.rollDaysBeforeExpiration = null;
      this.rollTimeMode = null;
      this.rollTime = null;
      this.rollTimezone = null;
      this.spreadWidth = null;
      this.atmOffset = null;
      this.atmNeutralZone = null;
      this.orderType = null;
      this.autoLimitPrice = null;
      this.convertToMarket = false;
      this.convertToMarketSettings = {};
   }

   //
   extractUnderlying(ticker: string): string {
      if (!ticker.startsWith('@')) {
         return ticker;
      }

      const parts = ticker.split(' ');
      return parts[1];
   }

   //
   private fillManualModeParameters() {
      this.manualModeSettings.atmNeutralZone = 0.1;
      this.manualModeSettings.atmOffset = this._atmOffset;
      this.manualModeSettings.spreadWidth = this._spreadWidth;
      this.manualModeSettings.orderType = this._orderType;
      this.manualModeSettings.autoLimitPrice = this._autoLimitPrice;
      this.manualModeSettings.convertToMarket = this._convertToMarket;
      this.manualModeSettings.convertToMarketSettings = this._convertToMarketSettings;
   }

   // temporary hack method . remove after fo settings are refactored into component
   onChange() {
      this._changeDetector.detectChanges();
   }

   //
    //
    getExpirationOffsetModes(cpMode: string) {
      if (cpMode === 'Automated') {
         this._expirationOffsetMode = 'Calendar Days';
         return this._expirationOffsetModesAutomated;
      } else {
         return this._expirationOffsetModesRegular;
      }
   }
}
