import { ToastrService } from 'ngx-toastr';
import { DispositionParametersControlBase } from '../disposition-parameters-control-base';
import { DispositionType } from '../../../disposition-type';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { AlgoMetadataService } from 'projects/shared-components/algo/algo-metadata.service';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { Directive } from '@angular/core';
import { FusionParametersViewComponent } from './fusion-parameters-view.component';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class FusionAlgoParametersControl extends DispositionParametersControlBase<FusionParametersViewComponent> {
   constructor(
      tradingInstrumentsService: TradingInstrumentsService,
      toastr: ToastrService,
      algoMetadataService: AlgoMetadataService
   ) {
      super(tradingInstrumentsService, toastr, algoMetadataService);
   }

   get symbol(): TradingInstrument {
      return undefined;
   }

   dispositionType: DispositionType = DispositionType.Fusion;

   fallbackCtlValue: number = null;

   get algoId(): string {
      return 'bc529650-7ff8-47c7-8713-55ef4ce8055c';
   }

   onSymbolChanged(old: TradingInstrument, value: TradingInstrument) {
      // ignore
   }

   protected getParametersSpecific(params: StrategyParameters) {
      // ignore
   }

   protected setParametersSpecific(params: StrategyParameters) {
      // ignore
   }

   onStrategyTerminalChanged(): void {

   }

}
