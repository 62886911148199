import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';

@Component({
  selector: 'ets-strategy-triggers-cell',
  template: `
    <p *ngIf="this.hasTriggers" class="has-triggers" (click)="showStrategyTriggers()">Tr</p>
  `,
  styles: [
    `
      .has-triggers {
        text-align: center;
        color: crimson;
        font-weight: bold;
        cursor: pointer;
      }
    `
  ]
})
export class StrategyTriggersCellRendererComponent implements ICellRendererAngularComp {
  constructor(private _messageBus: MessageBusService) {}

  data: StrategyModel;
  panelId: string;

  get hasTriggers(): boolean {
    if (!this.data) {
      return false;
    }

    return this.data.hasTriggers;
  }

  agInit(params: ICellRendererParams): void {
    
   this.data = params.data;
   
   this.panelId = params.api['ets-panel-id'];
   
  }

  refresh(): boolean {
    return false;
  }

  showStrategyTriggers() {
    this._messageBus.publish({
      topic: 'ShowStrategyTriggersRequest',
      payload: {
        strategy: this.data,
        panelId: this.panelId
      },
    });
  }
}
