import { GetOpenPositionChartData, GetOpenPositionChartDataResponse } from '../shell-communication/operations/charts/get-open-position-chart-data.class';
import { StrategyOpenPositionSnapshotDto } from '../shell-communication/dtos/strategy-open-position-snapshot-dto.class';
import { 
  RemoveOpenPositionChartSubscription 
} from '../shell-communication/operations/charts/remove-open-position-chart-subscription.class';

export abstract class OpenPositionSnapshotsBackendService {
  abstract getSnapshots(qry: GetOpenPositionChartData): Promise<GetOpenPositionChartDataResponse>;
  abstract unsubscribeUpdates(cmd: RemoveOpenPositionChartSubscription): Promise<void>;
}
