import { Component, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsStorageService } from '../settings-storage-service.service';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { WorkingOrdersIndex } from 'projects/shared-components/unspecific/working-orders-index.class';
import { GridOptions, GridReadyEvent, GridApi, ColumnState } from 'ag-grid-community';
import { getStrategyOrdersGridModel } from './strategy-orders-grid-model';
import { getStrategyTradesGridModel } from './strategy-trades-grid-model';
import { getStrategySnapshotsGridModel } from './strategy-snapshots-grid-model';
import { DetectMethodChanges, DetectSetterChanges, getPanelStateKey, isTruthy, parseOrderLegs, tickersMatch } from 'projects/shared-components/utils';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { Subject } from 'rxjs';
import { StrategyHighlightedUIMessage } from 'projects/shared-components/ui-messages/strategy-highlighted-ui-message.interface';
import { filter, takeUntil, map, throttleTime } from 'rxjs/operators';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { StrategyRemovedUIMessage } from 'projects/shared-components/ui-messages/strategy-removed-ui-message.interface';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { StrategiesTradingDataBackendService } from '../strategies/strategies-trading-data-backend-service.interface';
import { StrategyOrdersAndTradesSecurityContextService } from './strategy-orders-and-trades-security-context.service';
import { TimestampsService } from '../timestamps.service';
import { StrategiesService } from '../strategies/strategies.service';
import { isNullOrUndefined } from 'util';
import { CancelAllCustomExitOrders, CancelCustomExitOrder, GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb } from '../shell-communication/shell-operations-protocol';
import { GetAvailableBucketsReply, SessionEndedDto } from '../shell-communication/shell-dto-protocol';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { BucketItemHighlighted } from '../ui-messages/ui-messages';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { MarketSide } from '../trading-model/market-side.enum';
import {UserSettingsService} from "../user-settings.service";



function gridsReadyWatcher(res, rej): void {
   let attempts = 0;
   const intRef = setInterval(() => {
      attempts++;

      const ordersGridReady = !!this._ordersGrid;
      const tradesGridReady = !!this._tradesGrid;
      const snapshotsGridReady = !!this._snapshotsGrid;

      if (ordersGridReady && tradesGridReady && snapshotsGridReady) {
         clearInterval(intRef);
         res();
      } else {
         if (attempts >= 4 * 5) {
            clearInterval(intRef);
            rej();
         }
      }
   }, 250);
}


interface PanelState {
   ordersColumnsState: ColumnState[];
   tradesColumnsState: ColumnState[];
   snapshotsColumnsState: ColumnState[];
   sectionSizes: SectionSizes;
}

interface SectionSizes {
   top?: number;
   bottom?: number;
}



const DEFAULT_SECTION_SIZES: SectionSizes = { top: 40, bottom: 60 };


@Component({
   selector: 'ets-strategy-orders-and-trades',
   templateUrl: './strategy-orders-and-trades.component.html',
   styleUrls: ['./strategy-orders-and-trades.component.scss'],
   providers: [StrategyOrdersAndTradesSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyOrdersAndTradesComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _securityContext: StrategyOrdersAndTradesSecurityContextService,
      private readonly _tickerDisplayNameService: TradingInstrumentDisplayNameService,
      private readonly _toastr: ToastrService,
      private readonly _backendClient: StrategiesTradingDataBackendService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _timestampsService: TimestampsService,
      private readonly _strategiesService: StrategiesService,
   ) {
      
      super(_changeDetector, _userSettingsService, _messageBus);

      this._orderIx = new WorkingOrdersIndex();
      this._tradesIx = [];
      this._snapshotsIx = [];
      this.contextPopupParent = document.querySelector('body');

   }
   
   private readonly _orderIx: WorkingOrdersIndex;
   private readonly _tradesIx: TradeDto[];
   private readonly _snapshotsIx: OrderStateSnapshotDto[];
   private _pendingOrdersContainer: OrderDto[];
   private _pendingTradesContainer: TradeDto[];
   private _pendingSnapshotsContainer: OrderStateSnapshotDto[];
   private _tradesGrid: GridReadyEvent;
   private _ordersGrid: GridReadyEvent;
   private _snapshotsGrid: GridReadyEvent;
   private _stateChanged = new EventEmitter();
   private _unsubscriber: Subject<any> = new Subject<any>();
   // this is flag to prevent settings section sizes when panel still not initialized, and has 0 height
   private _componentWasShownAtLeastOnce = false;
   
   private _availableBuckets: GetAvailableBucketsReply = {
      portfolios: [],
      combos: [],
      comboGroups: []
   };


   contextPopupParent: HTMLBodyElement;
   contextStrategyId: string;
   workingOrdersGridModel: GridOptions;
   tradesGridModel: GridOptions;
   snapshotsGridModel: GridOptions;
   sectionSizes: SectionSizes = {};
   tabs = [{ text: 'Trades' }, { text: 'Snapshots' }];
   selectedTabIndex = 0;
   selectedOrderOrSnapshot: OrderDto | OrderStateSnapshotDto;

   get securityContext(): StrategyOrdersAndTradesSecurityContextService { return this._securityContext; }

   get timestampsService(): TimestampsService { return this._timestampsService; }

   get displayNameService(): TradingInstrumentDisplayNameService { return this._tickerDisplayNameService; }
   
   get ordersGridApi(): GridApi { return this._ordersGrid.api; }
   
   get tradesGridApi(): GridApi { return this._tradesGrid.api; }
   
   get snapshotsGridApi(): GridApi { return this._snapshotsGrid.api; }

   get messageBus(): MessageBusService { return this._messageBus; }

   get unsubscriber(): Subject<void> { return this._unsubscriber; }


   private _isCommentPopupVisible = false;
   get isCommentPopupVisible(): boolean {
      return this._isCommentPopupVisible;
   }
   
   @DetectSetterChanges()
   set isCommentPopupVisible(value: boolean) {
      this._isCommentPopupVisible = value;
   }
   
   
   async etsOnInit(): Promise<void> {
      this.workingOrdersGridModel = getStrategyOrdersGridModel.bind(this)(
         this._tickerDisplayNameService,
         this._timestampsService
      );

      this.tradesGridModel = getStrategyTradesGridModel.bind(this)(
         this._tickerDisplayNameService,
         this._timestampsService
      );

      this.snapshotsGridModel = getStrategySnapshotsGridModel.bind(this)(
         this._tickerDisplayNameService,
         this._timestampsService
      );

      this._availableBuckets = await this.getAvailableBuckets();

      await this._setupGridReadyWatcher();
   }

   
   etsAfterViewInit() {  }
 
   
   private getAvailableBuckets(): Promise<GetAvailableBucketsReply> {
      return this._backendClient.getAvailableBuckets();
   }

   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   
   onTradesGridReady(args: GridReadyEvent): void {
      this._tradesGrid = args;
      this._tradesGrid.api.sizeColumnsToFit();
   }

   
   @DetectMethodChanges()
   onTradesGridSizeChanged(args: { api: GridApi, clientWidth: number, clientHeight: number }): void {
      // grid size changes every time component shown or hidden, this is why 
      // we hook to this event. Trades grid is initializes last
      if (!this._componentWasShownAtLeastOnce) {
         this._componentWasShownAtLeastOnce = true;
         this.restoreState();
      }
   }

   
   onOrdersGridReady(args: GridReadyEvent): void {
      this._ordersGrid = args;
      this._ordersGrid.api.sizeColumnsToFit();
   }

   
   onSnapshotsGridReady(args: GridReadyEvent): void {
      this._snapshotsGrid = args;
      this._snapshotsGrid.api.sizeColumnsToFit();
   }

   
   async loadMoreTrades(batchSize: number): Promise<any> {
      
      if (!this.contextStrategyId) {
         return;
      }

      this._tradesGrid.api.showLoadingOverlay();
      try {

         const contextStrategyId = this.contextStrategyId;

         const historyStop = this._tradesIx.length ? this._tradesIx[0].seqNum : 0;
         
         const qry = new GetTradesWeb(
            contextStrategyId,
            historyStop,
            batchSize
         );
         
         const resp = await this._backendClient.getTrades(qry);
         
         const loadedTrades = resp.data as TradeDto[];
         
         if (loadedTrades.length === 0) {
            this._toastr.info('All Trades are Loaded');
            return;
         }
         
         if (this.contextStrategyId === contextStrategyId) {
            this._tradesIx.unshift(...loadedTrades);
            this._tradesGrid.api.setRowData(this._tradesIx);
         } else {
            console.debug('strategy-orders-trades|context changed. data load skipped');
         }

      } catch (error) {

         const errorMessage = 'Failed to load trades';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);
      } finally {
         this._tradesGrid.api.hideOverlay();
      }
   }

   
   async loadMoreSnapshots(batchSize: number): Promise<any> {
      if (!this.contextStrategyId) {
         return;
      }

      this._snapshotsGrid.api.showLoadingOverlay();
      try {
         const historyStop = this._snapshotsIx.length
            ? this._snapshotsIx[0].seqNum
            : 0;

         const contextStrategyId = this.contextStrategyId;

         const qry = new GetOrderStateSnapshotsWeb(
            contextStrategyId,
            historyStop,
            batchSize
         );

         const resp = await this._backendClient.getOrderStateSnapshots(qry);
         
         const loadedSnapshots = resp.data as OrderStateSnapshotDto[];
         
         if (loadedSnapshots.length === 0) {
            this._toastr.info('All Snapshots Loaded');
            return;
         }
         
         if (this.contextStrategyId === contextStrategyId) {
            this._snapshotsIx.unshift(...loadedSnapshots);
            this._snapshotsGrid.api.setRowData(this._snapshotsIx);   
      } else {
            console.debug('strategy-orders-trades|context changed. data load skipped');
         }

      } catch (error) {
         
         const errorMessage = 'Failed to load strategy snapshots';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);

      } finally {
         
         this._snapshotsGrid.api.hideOverlay();

      }
   }

   
   onStateChanged(): void {
      this._stateChanged.emit();
   }

   
   
   @DetectMethodChanges()
   selectTab($event: any): void {
      this.selectedTabIndex = $event.itemIndex;
   }

   
   onSplitSizeChanged($event: { gutterNum: number; sizes: Array<number> }): void {
      this.sectionSizes.top = $event.sizes[0];
      this.sectionSizes.bottom = $event.sizes[1];
      this._stateChanged.emit();
   }

   
   async requestOrderStatus(order?: OrderDto): Promise<void> {
      if (!order) {
         // request for all
         const allWorkingOrders: OrderDto[] = this._orderIx.getCurrentState();
         if (allWorkingOrders.length) {
            const ordersShells = allWorkingOrders.map(x => ({ orderId: x.orderId, shellId: x.shellId }));
            try {
               await this._backendClient.requestOrderStatus(ordersShells);
            } catch (error) {
               const errorMessage = 'Error occurred during order status request operation';
               console.error(errorMessage, { error });
               this._toastr.error(errorMessage);
            }
         } else {
            this._toastr.info('No working orders for status request');
         }
      } else {
         // request single
         try {
            await this._backendClient.requestOrderStatus([{ orderId: order.orderId, shellId: order.shellId }]);
         } catch (error) {
            const errorMessage = 'Error occurred during order status request operation';
            console.error(errorMessage, { error });
            this._toastr.error(errorMessage);
         }
      }
   }

   
   hasWorkingOrders(): boolean {
      return this._orderIx.getCurrentState().length > 0;
   }

   
   
   async cancelCustomExitOrder(order: OrderDto) {
      if (!order) {
         this._toastr.error('Please select CE order');
         return;
      }

      try {
         const cmd = new CancelCustomExitOrder(order.orderId);
         await this._backendClient.cancelCustomExitOrder(cmd);
      } catch {
         this._toastr.error('"Cancel CE order" opeartion completed with errors');
      }
   }

   
   async cancelAllCustomExitOrders() {
      if (!this.contextStrategyId) {
         this._toastr.error('Strategy ID cannot be determined');
         return;
      }

      const cmd = new CancelAllCustomExitOrders(this.contextStrategyId);
      try {
         await this._backendClient.cancelAllCustomExitOrders(cmd);
      } catch (e) {
         this._toastr.error('"Cancel All CE orders" opeartion completed with errors');
      }
   }

   
   private async _getWorkingOrdersFromServer(strategyId: string): Promise<OrderDto[]> {
      const qry = new GetWorkingOrdersWeb(strategyId);
      const resp = await this._backendClient.getWorkingOrders(qry);
      return resp.data as OrderDto[];
   }

   
   private async _setupGridReadyWatcher(): Promise<void> {

      const resolver = gridsReadyWatcher.bind(this);

      try {
         await new Promise(resolver);
         const key = getPanelStateKey(this);
         const item = this._userSettingsService.getValue(key);

         if (!item) {
            this.saveState();
         }

         this._subscribeToMessages();

         await this._loadLastHighlightedStrategy();

      } catch (error) {

         this._toastr.error(
            '"Strategy Orders & Trades" panel initialized with errors'
         );

         // as we might have enabled overlays on grids initialization
         const orderGrid = this._ordersGrid;
         if (orderGrid) {
            orderGrid.api.hideOverlay();
         }

         const tradesGrid = this._tradesGrid;
         if (tradesGrid) {
            tradesGrid.api.hideOverlay();
         }

         const snapshotsGrid = this._snapshotsGrid;
         if (snapshotsGrid) {
            snapshotsGrid.api.hideOverlay();
         }
      }
   }

   
   private async _getTradesFromServer(strategyId: string): Promise<TradeDto[]> {
      const qry = new GetTradesWeb(strategyId, 0, 100);
      const resp = await this._backendClient.getTrades(qry);
      return resp.data as TradeDto[];
   }

   
   private async _getSnapshotsFromServer(strategyId: string): Promise<OrderStateSnapshotDto[]> {
      const qry = new GetOrderStateSnapshotsWeb(strategyId, 0, 100);
      const resp = await this._backendClient.getOrderStateSnapshots(qry);
      return resp.data as OrderStateSnapshotDto[];
   }

   
   private _subscribeToMessages(): void {

      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId)
         )
         .subscribe(message => this.onStrategyHighlightedMessage(message.payload));

      this._messageBus
         .of<any>('OrderDto')
         .pipe(
            filter(x => this.contextStrategyId in x.payload),
            map(x => x.payload[this.contextStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onOrderDtoMessage(message));

      this._messageBus
         .of<any>('TradeDto')
         .pipe(
            filter(x => this.contextStrategyId in x.payload),
            map(x => x.payload[this.contextStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onTradeDtoMessage(message));

      this._messageBus
         .of<any>('OrderStateSnapshotDto')
         .pipe(
            filter(x => this.contextStrategyId in x.payload),
            map(x => x.payload[this.contextStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onSnapshotDtoMessage(message));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors && !!this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onClearTradingDataMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>(
            'ShellConnectionStatusChangedUIMessage'
         )
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this._onShellConnectionStatusChanged(data.payload));

      this._stateChanged.pipe(
         throttleTime(250),
         takeUntil(this._unsubscriber)
      ).subscribe(() => this.saveState());

      this._messageBus.of<any>('WorkspaceClosed')
         .pipe(
            filter(msg => msg.payload.workspaceId === this.workspaceId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onRemovedFromWorkspace());

      this._messageBus.of<StrategyRemovedUIMessage>('StrategyRemovedUIMessage')
         .pipe(
            filter(msg => msg.payload.strategyId === this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onStrategyRemovedUIMessage(msg.payload));

      this._messageBus
         .of<SessionEndedDto>('SessionEndedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this._onSessionResetCompleted(data.payload));

      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
            .pipe(
               takeUntil(this._unsubscriber),
               filter(msg => msg.scopeId === this.layoutTabId),
               filter(msg => msg.payload.item.itemType === PortfolioItemType.Strategy)
            )
            .subscribe(msg => this.onBucketItemHighlighted(msg.payload));

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onQuoteMessage(x.payload));

      this._messageBus.of('DefaultTimezoneChanged')
            .pipe(
               takeUntil(this._unsubscriber)
            )
            .subscribe(() => {
               if (this._ordersGrid) {
                  this._ordersGrid.api.refreshCells({force: true});
               }
               
               if (this._tradesGrid) {
                  this._tradesGrid.api.refreshCells({force: true});
               }

               if (this._snapshotsGrid) {
                  this._snapshotsGrid.api.refreshCells({force: true});
               }
            });
   }

   private async onBucketItemHighlighted(msg: BucketItemHighlighted): Promise<void> {
      if (msg.item.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const strategyId = msg.item.portfolioItemId;

      const strategyHighlightedMessage: StrategyHighlightedUIMessage = { strategyId };
      
      await this.onStrategyHighlightedMessage(strategyHighlightedMessage);
   }

   
   private async _onSessionResetCompleted(data: SessionEndedDto) {
      if (!isTruthy(this.contextStrategyId)) {
         return;
      }
      const str = this._strategiesService.getById(this.contextStrategyId);

      if (isNullOrUndefined(str)) {
         return;
      }

      if (data.terminalId !== str.terminalId) {
         return;
      }

      const strategyId = this.contextStrategyId;
      this.contextStrategyId = null;
      await this.onStrategyHighlightedMessage({ strategyId });
   }

   
   private async onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage): Promise<any> {
      if (message.strategyId === this.contextStrategyId) {
         return;
      }

      this.contextStrategyId = message.strategyId;
      
      let hadLoadingErrors = false;
      
      try {
      
         this._ordersGrid.api.showLoadingOverlay();
         await this._loadWorkingOrders();
      
      } catch (error) {
      
         hadLoadingErrors = true;
         this._ordersGrid.api.setRowData([]);
         const errorMessage = 'Failed to load working orders';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);

      } finally {
         
         this._ordersGrid.api.hideOverlay();

      }

      if (hadLoadingErrors) {
         return;
      }

      try {
         this._tradesGrid.api.showLoadingOverlay();
         await this._loadTrades();
      } catch (error) {
         hadLoadingErrors = true;
         this._tradesGrid.api.setRowData([]);
         const errorMessage = 'Failed to load trades';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);
      } finally {
         this._tradesGrid.api.hideOverlay();
      }

      if (hadLoadingErrors) {
         return;
      }

      try {
         this._snapshotsGrid.api.showLoadingOverlay();
         await this._loadSnapshots();
      } catch (error) {
         this._snapshotsGrid.api.setRowData([]);
         const errorMessage = 'Failed to load snapshots';
         this._logDataLoadingError(error, errorMessage);
         this._toastr.error(errorMessage);
      } finally {
         this._snapshotsGrid.api.hideOverlay();
      }
   }

   
   private _logDataLoadingError(error: any, errorMessge: string): void {
      const data = {};

      if (error instanceof Error) {
         // tslint:disable-next-line: no-string-literal
         data['error'] = error.stack;
      } else {
         // tslint:disable-next-line: no-string-literal
         data['error'] = error;
      }

      console.error(errorMessge, data);
   }

   
   private async _loadWorkingOrders(): Promise<any> {
      this._pendingOrdersContainer = [];
      let workingOrders: OrderDto[];

      try {

         const contextStratgyId = this.contextStrategyId;
         
         workingOrders = await this._getWorkingOrdersFromServer(contextStratgyId);
         
         const totalOrders = workingOrders.concat(this._pendingOrdersContainer || []);
         
         const filteredOrders = {};
         
         totalOrders.forEach(order => {
            filteredOrders[order.orderId] = order;
         });
         
         const rowData = Object.keys(filteredOrders)
            .map(key => filteredOrders[key])
            .filter(
               (dto: OrderDto) =>
                  (dto.status & OrderStatus.Working) === dto.status
            );
         
         this._orderIx.reset();

         const state = this._orderIx.calculateState(rowData);
         
         if (contextStratgyId === this.contextStrategyId) {
            this._ordersGrid.api.setRowData(state);
         }

      } finally {
         this._pendingOrdersContainer = null;
      }
   }

   
   private async _onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {
      if (this.contextStrategyId) {
         const strategyId = this.contextStrategyId;
         this.contextStrategyId = null;
         await this.onStrategyHighlightedMessage({ strategyId });
      }
   }

   
   private async _loadLastHighlightedStrategy(): Promise<void> {
      const lastMessage = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>(
         'StrategyHighlightedUIMessage', 
         this.layoutTabId );

      if (lastMessage) {
         await this.onStrategyHighlightedMessage(lastMessage.payload);
      }
   }

   
   private async _loadTrades(): Promise<any> {
      let trades: TradeDto[];
      this._pendingTradesContainer = [];
      
      try {
         
         const contextStrategyId = this.contextStrategyId;
         
         trades = await this._getTradesFromServer(contextStrategyId);
         
         this._tradesIx.length = 0;
         
         if (trades.length > 0) {
            const lastSeqNum = trades[trades.length - 1].seqNum;
            const filteredPendings = (this._pendingTradesContainer || []).filter(t => t.seqNum > lastSeqNum);
            const totalTrades = trades.concat(filteredPendings);
            this._tradesIx.push(...totalTrades);
         }

         if (this.contextStrategyId === contextStrategyId) {
            this._tradesGrid.api.setRowData(this._tradesIx);
         } else {
            console.debug('strategy-orders-trades|context changed. data load skipped');
         }

      } finally {
         
         this._pendingTradesContainer = null;

      }
   }

   
   private async _loadSnapshots(): Promise<any> {
      let snapshots: OrderStateSnapshotDto[];
      
      this._pendingSnapshotsContainer = [];
      
      try {
         
         const contextStrategyId = this.contextStrategyId;
         
         snapshots = await this._getSnapshotsFromServer(this.contextStrategyId);
         
         this._snapshotsIx.length = 0;
         
         if (snapshots.length > 0) {
            const lastSeqNum = snapshots[snapshots.length - 1].seqNum;
            const filteredPendings = (this._pendingSnapshotsContainer || []).filter(t => t.seqNum > lastSeqNum);
            const totalSnapshots = snapshots.concat(filteredPendings);
            this._snapshotsIx.push(...totalSnapshots);
         }

         if (this.contextStrategyId === contextStrategyId) {
            this._snapshotsGrid.api.setRowData(this._snapshotsIx);
         } else {
            console.debug('strategy-orders-trades|context changed. data load skipped');
         }

      } finally {
         
         this._pendingSnapshotsContainer = null;

      }
   }

   
   private _onOrderDtoMessage(dtos: OrderDto[]): void {

      const pendingDataContainer = this._pendingOrdersContainer;
      if (pendingDataContainer) {
         pendingDataContainer.push(...dtos);
      } else {
         const state = this._orderIx.calculateState(dtos);
         const tickers = [];
         state.forEach(x => {
            tickers.push(x.ticker);
            const lastQuote = this._lastQuoteCache.subscribeTicker(x.ticker);
            if (lastQuote) {
               x.lastQuote = lastQuote.lastPx;
            }
         });
         this._ordersGrid.api.setRowData(state);
      }
   }

   
   private _onTradeDtoMessage(dtos: TradeDto[]): void {
      const pendingDataContainer = this._pendingTradesContainer;
      if (pendingDataContainer) {
         pendingDataContainer.push(...dtos);
      } else {
         this._tradesIx.push(...dtos);
         this._tradesGrid.api.applyTransactionAsync({
            add: dtos
         });
      }
   }

   
   private _onSnapshotDtoMessage(dtos: OrderStateSnapshotDto[]): void {
      const pendingDataContainer = this._pendingSnapshotsContainer;
      if (pendingDataContainer) {
         pendingDataContainer.push(...dtos);
      } else {
         this._snapshotsIx.push(...dtos);
         this._snapshotsGrid.api.applyTransactionAsync({
            add: dtos
         });
      }
   }

   
   private _onClearTradingDataMessage(message: ClearTradingDataUIMessage) {
      if (message.hasErrors) {
         return;
      }

      if (message.strategies.includes(this.contextStrategyId) || message.refreshDb) {
         this._orderIx.reset();
         this._ordersGrid.api.setRowData([]);

         this._tradesIx.length = 0;
         this._tradesGrid.api.setRowData([]);

         this._snapshotsIx.length = 0;
         this._snapshotsGrid.api.setRowData(this._snapshotsIx);
      }
   }

   
   protected getState(): PanelState {
      
      if (!this._tradesGrid || !this._ordersGrid || !this._snapshotsGrid) {
         return null;
      }

      if (!this._componentWasShownAtLeastOnce) {
         return null;
      }


      const ordersGridState = this._ordersGrid.columnApi.getColumnState();
      const tradesGridState = this._tradesGrid.columnApi.getColumnState();
      const snapshotsGridState = this._snapshotsGrid.columnApi.getColumnState();
      const state: PanelState = {
         ordersColumnsState: ordersGridState,
         tradesColumnsState: tradesGridState,
         snapshotsColumnsState: snapshotsGridState,
         sectionSizes: this.sectionSizes
      };

      return state;
   }

   
   protected setState(state: PanelState): void {
      if (!this._ordersGrid || !this._tradesGrid || !this._snapshotsGrid) {
         return;
      }


      let isOrdersOK = true;
      if (state.ordersColumnsState) {
         isOrdersOK = this._ordersGrid.columnApi.setColumnState(state.ordersColumnsState);
      }

      let isTradesOK = true;
      if (state.tradesColumnsState) {
         isTradesOK = this._tradesGrid.columnApi.setColumnState(state.tradesColumnsState);
      }


      let isSnapshotsOK = true;
      if (state.snapshotsColumnsState) {
         isSnapshotsOK = this._snapshotsGrid.columnApi.setColumnState(state.snapshotsColumnsState);
      }

      if (!isOrdersOK || !isTradesOK || !isSnapshotsOK) {
         this._toastr.error('"Strategy Orders & Trades" panel was restored with errors');
      }

      if (this._componentWasShownAtLeastOnce) {
         if (state.sectionSizes) {

            let defaultSizes = DEFAULT_SECTION_SIZES;

            if (state.sectionSizes.top && state.sectionSizes.bottom) {
               defaultSizes = state.sectionSizes;
            }

            if (this.sectionSizes.top === defaultSizes.top && this.sectionSizes.bottom === defaultSizes.bottom) {
               this.sectionSizes = { top: 0, bottom: 100 };
               setTimeout(() => this.sectionSizes = defaultSizes, 0);
            } else {
               this.sectionSizes = defaultSizes;
            }
         }
      }
   }

   
   private _onStrategyRemovedUIMessage(msg: StrategyRemovedUIMessage): void {
      this._orderIx.reset();
      this._tradesIx.length = 0;
      this._snapshotsIx.length = 0;

      this._ordersGrid.api.setRowData([]);
      this._tradesGrid.api.setRowData([]);
      this._snapshotsGrid.api.setRowData([]);

      this.contextStrategyId = null;
   }

   private onQuoteMessage(quotes: QuoteDto[]) {

      this._ordersGrid.api.forEachLeafNode(node => {

         const order = node.data as OrderDto;

         if (!order) {
            return;
         }

         if (order.multiLegDescriptor) {

            if (!order.legs || order.legs.length === 0) {
               parseOrderLegs(order, this._availableBuckets, this._lastQuoteCache);
            }

            order.legs.forEach(leg => {
               
               const quote = quotes.find(q => tickersMatch(q.ticker, leg.ticker));

               if (!quote) {
                  return;
               }

               if (quote.ticker.indexOf('@XSP') >= 0)  {

                  leg.liveQuote = quote.mid;

               } else {

                  if (leg.qty > 0) {

                     leg.liveQuote = quote.ask;
   
                  } else if (leg.qty < 0) {
   
                     leg.liveQuote = quote.bid;
   
                  } else {
   
                     leg.liveQuote = quote.lastPx;
   
                  }
               }

            });

            const sum = order.legs
               .map(data => {

                  return { qty: data.qty, liveQuote: data.liveQuote };

               }).reduce((prev, curr) => {

                  return (curr.liveQuote * curr.qty) + prev;

               }, 0);

            order.liveQuote = isNaN(sum) ? null : sum;

         } else {

            const quote = quotes.find(q => tickersMatch(q.ticker, order.ticker));

            if (quote) {

               if (quote.ticker.indexOf('@XSP') >= 0) {

                  order.liveQuote = quote.mid;

               } else {
                  if (order.side === MarketSide.Buy) {

                     order.liveQuote = quote.ask;

                  } else if (order.qty === MarketSide.Sell) {

                     order.liveQuote = quote.bid;

                  } else {

                     order.liveQuote = quote.lastPx;
                  }
               }

            }
         }

         this._ordersGrid.api.applyTransaction({ update: [order] });
      });
   }
}
