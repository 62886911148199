import { OpenPositionSnapshotsBackendService } from 'projects/shared-components/open-position-chart/open-position-snapshots-backend.interface';
import { GetOpenPositionChartData, GetOpenPositionChartDataResponse } from 'projects/shared-components/shell-communication/operations/charts/get-open-position-chart-data.class';
import { StrategyOpenPositionSnapshotDto } from 'projects/shared-components/shell-communication/dtos/strategy-open-position-snapshot-dto.class';
import { RemoveOpenPositionChartSubscription } from 'projects/shared-components/shell-communication/operations/charts/remove-open-position-chart-subscription.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class WebtraderOpenPositionSnapshotsBackendService extends OpenPositionSnapshotsBackendService {
  constructor(
    private readonly _shellClient: ShellClientService,
  ) {
    super();
  }
  
  getSnapshots(qry: GetOpenPositionChartData): Promise<GetOpenPositionChartDataResponse> {
    return this._shellClient.processQuery(qry);
  }
  
  unsubscribeUpdates(cmd: RemoveOpenPositionChartSubscription): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }
}
