import { Injectable } from '@angular/core';
import { ICashFlowStrategySettings } from '../adjustment-pricing-grid/model/ICashFlowStrategySettings';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetApgDefaultSettingsShell, GetApgDefaultSettingsShellReply, SaveApgDefaultSettingsShell } from '../shell-communication/shell-operations-protocol';
import { isVoid } from '../utils';

export const MotherOfDefaults = 'MotherOfDefaults';


export const DefaultStrategySettingSet: ICashFlowStrategySettings = {
   spreadOffset: 0,
   spreadWidth: 10,
   spreadRollXDaysBeforeExpiration: 14,
   spreadRollToDaysToExp: 45,

   secondSpreadOffset: 0,
   secondSpreadWidth: 20,
   secondSpreadRollToDaysToExp: 60,
   secondSpreadRollXDaysBeforeExpiration: 14,

   protectiveOptionOffset: 0,
   protectiveOptionRollXDaysBeforeExpiration: 1,
   protectiveOptionRollToDaysToExp: 1,

   secondProtectiveOptionOffset: 1,
   secondProtectiveOptionRollXDaysBeforeExpiration: 1,
   secondProtectiveOptionRollToDaysToExp: 1
}


@Injectable({providedIn: 'root'})
export class ApgDefaultsService {

   constructor(
      private _shellService: ShellClientService
   ) {
   }

   //
   private _defaultsByTradingInstrument: Record<string, ICashFlowStrategySettings[]> = {};

   async init(): Promise<void> {
      
      this._defaultsByTradingInstrument = {};

      const qry = new GetApgDefaultSettingsShell();
      
      const reply = await this._shellService.processQuery<GetApgDefaultSettingsShellReply>(qry);
      
      reply.settings.forEach(s => {
         
         const settings = JSON.parse(s.settings) as ICashFlowStrategySettings[];
         
         if (typeof settings === 'object' &&   settings.hasOwnProperty('length')) {
            this._defaultsByTradingInstrument[s.underlying] = settings;
         }

      });
   }


   //
   get(tradingInstrument: TradingInstrument | string): ICashFlowStrategySettings[] {
      
      const ul = typeof tradingInstrument === 'string' ? tradingInstrument : this.extractUnderlying(tradingInstrument);
   
      let apgDefalults = this._defaultsByTradingInstrument[ul];

      let isHardCodedDefaults = false;

      if (isVoid(apgDefalults)) {
         
         isHardCodedDefaults = true;

         apgDefalults = [DefaultStrategySettingSet, DefaultStrategySettingSet];

      }

      const copy = JSON.parse(JSON.stringify(apgDefalults)) as ICashFlowStrategySettings[];
   
      if (isHardCodedDefaults) {
         if (ul === 'SPX') {
            copy.forEach(c => {
               c.spreadOffset *= 10;
               c.spreadWidth *= 10;
               c.secondSpreadOffset *= 10;
               c.secondSpreadWidth *= 10;
               c.protectiveOptionOffset *= 10;
               c.secondProtectiveOptionOffset *= 10;
            });
         }
      }

      return copy;
   }
   
   //
   async save(settings: ICashFlowStrategySettings[], tradingInstrument: TradingInstrument): Promise<void> {
      
      const ul = this.extractUnderlying(tradingInstrument);
      
      const serializedSetting = JSON.stringify(settings);
      
      const cmd = new SaveApgDefaultSettingsShell([{underlying: ul, settings: serializedSetting}]);

      await this._shellService.processCommand(cmd);
      
      this._defaultsByTradingInstrument[ul] = settings;
   }

   //
   private extractUnderlying(ti: TradingInstrument) {
      return ti ? ti.underlying : MotherOfDefaults;
   }

}