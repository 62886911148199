import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UserDto} from "../../../authentication/dtos/auth-result-dto.inteface";
import {SessionService} from "../../../authentication/session-service.service";
import {ResourceButton} from "../../resource-button.interface";
import {DetectMethodChanges, DetectSetterChanges, isVoid} from "../../../utils";
import {SelectionChangedEvent} from "devextreme/ui/list";
import {ResourceEditorComponent} from "../../resource-editor.component";

@Component({
    selector: 'ets-users-condition-resource',
    template: `
      <div>
        <p class="label">Users</p>
        <div class="input">
          <dx-drop-down-box
            [disabled]="this.disabled"
            [acceptCustomValue]="true"
            fieldTemplate="field"
            contentTemplate="content"
            (onOpened)="this.onOpened()"
            (onClosed)="this.onClosed()"
            [value]="text"
          >
            <div *dxTemplate="let data of 'field'">
              <dx-text-box
                [readOnly]="true"
                [value]="this.text"
              >
              </dx-text-box>
            </div>
            
            <div *dxTemplate="let data of 'content'">
              <dx-list
                [items]="this.userList"
                [displayExpr]="'userName'"
                [(selectedItems)]="this.selectedUsers"
                [selectionMode]="'multiple'"
                [showSelectionControls]="true"
                (onSelectionChanged)="this.onSelectionChanged($event)"
              >
              </dx-list>
            </div>
          </dx-drop-down-box>
        
        
        </div>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsersConditionResourceComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _sessionData: SessionService
    ) {
    }
    private _oldValue: string;

    userList: UserDto[] = [];

    selectedUsers: UserDto[] = [];

    @Input() comp: ResourceEditorComponent;

    get text() {
        if (this.selectedUsers.length === 0) {
            return undefined;
        }

        if (this.selectedUsers.length === 1) {
            return this.selectedUsers[0].userName;
        }

        return `${this.selectedUsers.length} users`;
    }

    get disabled(): boolean {
        return isVoid(this.resource);
    }

    private _resource: ResourceButton;
    get resource(): ResourceButton {
        return this._resource;
    }

    @Input()
    @DetectSetterChanges()
    set resource(value: ResourceButton) {

        this.reset();

        this._resource = value;

        if (isVoid(value)) {
            return;
        }

        if (isVoid(value.conditionUsers)) {
            return;
        }

        const parts = value.conditionUsers.split('|');

        parts.forEach(x => {
            const user = this.userList.find(y => y.userId === x);
            if (user) {
                this.selectedUsers.push(user);
            }
        });
    }

    ngOnInit() {
        this.userList = this._sessionData.sessionData.users.slice();
    }

    @DetectMethodChanges()
    private reset() {
        this.selectedUsers.length = 0;
    }

    onOpened() {
        this._oldValue = this.selectedUsers.map(x => x.userId).join('|');
    }

    @DetectMethodChanges()
    onClosed() {
        const users = this.selectedUsers.map(x => x.userId).join('|');
        this.resource.conditionUsers = users;
        if (this._oldValue === users) {
            return;
        }
        this.resource.hasChanges = true;
        this.comp.onChange({});
    }

    onSelectionChanged($event: SelectionChangedEvent<UserDto>) {

    }
}