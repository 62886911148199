import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

/**
 * @returns PriceBarDto[]
 */
export class GetPriceBars implements ProtocolQuery {
  constructor(
    public readonly ticker: string,
    public readonly timeframe: string,
    public readonly rangeStart: Date,
    public readonly rangeEnd?: Date
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPriceBars`;
  }
}
