<dx-popup #override title="Override Manual Position" [width]="350" [height]="210" [showTitle]="true"
   [closeOnOutsideClick]="false" [resizeEnabled]="false" [dragEnabled]="true" [(visible)]="isVisible"
      (onHidden)="this.onHidden()">

   <div *dxTemplate="let data of 'content'">

      <div class="container">

         <div class="ets-row">
            <div class="ets-column header">
               Symbol
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               {{ positionToOverride.tickerDisplayName }}
            </div>
         </div>

         <div class="ets-row">
            <hr>
         </div>

         <div class="ets-row">
            <div class="ets-column header">
               Avg. Price
            </div>
            <div class="ets-column header">
               Quantity
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-number-box mode="number" format="#,##0.##" [(value)]="positionToOverride.avgPx"
                  [showSpinButtons]="true" [showClearButton]="true" [step]="positionToOverride.tickSize">
               </dx-number-box>
            </div>

            <div class="ets-column">
               <dx-number-box mode="number" [(value)]="positionToOverride.netPosition" [showSpinButtons]="true"
                  [showClearButton]="true" [step]="1"></dx-number-box>
            </div>
         </div>

         <div class="ets-row buttons">
            <div class="ets-column">
               <dx-button type="normal" text="Override" (onClick)="overridePosition()" [disabled]="!this.canOverride()"></dx-button>
            </div>
            <div class="ets-column">
               <dx-button type="default" text="Close Window" (onClick)="closeOverrideDialog()"></dx-button>
            </div>
         </div>

      </div>
   </div>
</dx-popup>