import { StrategyPositionModel } from './strategy-position-model.class';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { plainToClass } from 'class-transformer';


export class PositionsCollection {
   private readonly _positions: StrategyPositionModel[] = [];
   private readonly _archived: StrategyPositionModel[] = [];
   private _archivedAccPnL = 0;

   get seqNum(): number {
      const seqNum = Math.max(...this._positions.map(x => x.seqNum));
      return seqNum;
   }

   addFromDto(dto: PositionDto) {
      const model = plainToClass(StrategyPositionModel, dto);
      if (model.isArchived) {
         this._archived.push(model);
         this._archivedAccPnL += model.accumulatedTotalPnL;
      } else {
         if (!(model.positionId in this._positions)) {
            this._positions.push(model);
         }
      }
   }

   addFromModel(model: StrategyPositionModel) {
      if (model.isArchived) {
         this._archived.push(model);
      } else {
         this._positions.push(model);
      }
   }

   clear() {
      this._archivedAccPnL = 0;
      this._archived.length = 0;
      this._positions.length = 0;
   }

   getNetPosition(): number {
      if (this._positions.length === 0) { return 0; }
      return this._positions.length === 1 ? this._positions[0].netPosition : NaN;
   }

   getAvgPx(): number {
      if (this._positions.length === 0) { return 0; }
      return this._positions.length === 1 ? this._positions[0].avgPx : NaN;
   }


   getSessionTotalPnL(): number {
      let sessionTotalPnL = NaN;
      if (this._positions.length === 0) {
         sessionTotalPnL = 0;
      } else if (this._positions.length === 1) {
         sessionTotalPnL = this._positions[0].sessionTotalPnL;
      } else {
         let sum = 0;
         this._positions.forEach(x => (sum += x.sessionTotalPnL));
         sessionTotalPnL = sum;
      }
      return sessionTotalPnL;
   }

   getAccumulatedTotalPnL(): number {
      let accumulatedTotalPnL;
      if (this._positions.length === 0) {
         accumulatedTotalPnL = 0;
      } else if (this._positions.length === 1) {
         accumulatedTotalPnL = this._positions[0].accumulatedTotalPnL;
      } else {
         let sum = 0;
         this._positions.forEach(x => (sum += x.accumulatedTotalPnL));
         accumulatedTotalPnL = sum;
      }
      accumulatedTotalPnL += this._archivedAccPnL;
      return accumulatedTotalPnL;
   }

   getSessionUnrealizedPnL(): number {
      let sessionUnrealizedPnL;
      if (this._positions.length === 0) {
         sessionUnrealizedPnL = 0;
      } else if (this._positions.length === 1) {
         sessionUnrealizedPnL = this._positions[0].sessionUnrealizedPnL;
      } else {
         let sum = 0;
         this._positions.forEach(x => (sum += x.sessionUnrealizedPnL));
         sessionUnrealizedPnL = sum;
      }

      return sessionUnrealizedPnL;
   }

   getBrokerage(): string {
      if (this._positions.length === 0) { return ''; }
      return this._positions.length === 1 ? this._positions[0].accountCode : '*';
   }

   getLastPx(): number {
      if (this._positions.length === 0) {
         return 0;
      }
      return this._positions.length === 1 ? this._positions[0].lastMatchPx : NaN;
   }

   getOpenPrice(): number {
      if (this._positions.length === 0) {
         return 0;
      }
      return this._positions.length === 1 ? this._positions[0].openPrice : NaN;
   }

   count() {
      return this._positions.length;
   }

   getPositions(): StrategyPositionModel[] {
      return this._positions;
   }

   getArchivedPositions(): StrategyPositionModel[] {
      return this._archived;
   }

   archivePosition(positionId: string) {
      const ix = this._positions.findIndex(p => p.positionId === positionId);
      if (ix >= 0) {
         const removedPosition = this._positions[ix];
         this._archived.push(removedPosition);
         this._archivedAccPnL += removedPosition.accumulatedTotalPnL;
         this._positions.splice(ix, 1);
      }
   }

   removeArchivedPosition(positionId: string): boolean {
      const ix = this._archived.findIndex(p => p.positionId === positionId);
      if (ix < 0) {
         return false;
      }

      const arr = this._archived.splice(ix, 1);

      if (arr && arr.length > 0) {
         const p = arr[0];
         this._archivedAccPnL -= p.accumulatedTotalPnL;
      }

      return true;
   }

   adjustRolloverPnL(dto: PositionDto) {
      this._archivedAccPnL += dto.settledRolloverAdjustment;
   }
}
