import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ProtectiveOptionParameters } from './ProtectiveOptionParameters';
import { GetProtectiveOptionSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-protective-option-cashflow-strategy',
   templateUrl: './protective-option-cashflow-strategy.component.html',
   styleUrls: ['./protective-option-cashflow-strategy.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectiveOptionCashFlowStrategyComponent implements OnInit {
  
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }

   //
   @Input() 
   automationCpMode: AutomationCpMode;

   //
   @Input() 
   strategy: CashFlowStrategy;

   //
   @ViewChild('autoSingle', {static: true}) 
   autoSingleTemplate: ElementRef;

   //
   @ViewChild('autoDouble', {static: true}) 
   autoDoubleTemplate: ElementRef;

   //
   get isMultiDirection(): boolean {
      return this.strategy === 'Calls & Puts';
   }

   //
   get templateOutlet(): ElementRef {
      
      if (this.automationCpMode === 'Automated') {
         
         return this.isMultiDirection ? this.autoDoubleTemplate : this.autoSingleTemplate;

      } else {

         return undefined;
      }

   }

    //
    get cssClassForMainContainer(): string[] {
      const css = ['protective-option'];
      
      if (this.isMultiDirection) {
         css.push('multi');
      }

      return css;
   }

   //
   get cssClassForParametersContainer(): string[] {
      const css = ['parameters'];
      
      if (this.isMultiDirection) {
         css.push('multi');
      }

      return css;
   }

   //
   parameters: ProtectiveOptionParameters = {};

   //
   parameters2: ProtectiveOptionParameters = {};

   //
   ngOnInit(): void { }

   //
   getParameters(): ProtectiveOptionParameters[] {
      
      const params = [];

      if (!this.isMultiDirection) {

         const single = JSON.parse(JSON.stringify(this.parameters));
         params.push(single);

      } else {

         const calls = JSON.parse(JSON.stringify(this.parameters));
         params.push(calls);

         const puts = JSON.parse(JSON.stringify(this.parameters2));
         params.push(puts);
      }

      return params;
   }

   //
   @DetectMethodChanges()
   setParameters(reply: GetProtectiveOptionSectionDataModelReply) {
      this.parameters.spreadOffset = reply.spreadOffset;
      this.parameters.rollXDaysBeforeExpiration = reply.rollXDaysBeforeExpiration;
      this.parameters.rollDaysToExpiration = reply.rollToDaysToExpiration;

      this.parameters2.spreadOffset = reply.spreadOffset2;
      this.parameters2.rollXDaysBeforeExpiration = reply.rollXDaysBeforeExpiration2;
      this.parameters2.rollDaysToExpiration = reply.rollToDaysToExpiration2;
   }

   //
   @DetectMethodChanges()
   reset() {
      this.parameters = {};
      this.parameters2 = {};
   }

   //
   onParameterChanged(parameter: keyof ProtectiveOptionParameters) {
      
      if (parameter === 'spreadOffset') {
         return;
      }

      this.parameters2[parameter] = this.parameters[parameter];
   }
}
