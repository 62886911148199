<dx-popup   [width]="this.popupSettings.width" 
            [height]="this.popupSettings.height" 
            [title]="this.popupSettings.title" 
            [resizeEnabled]="this.popupSettings.resize" 
            [dragEnabled]="this.popupSettings.dragEnabled"
            [visible]="this.popupSettings.isVisible" 
            (onHidden)="this.onHidden()">
   
   <div *dxTemplate="let data of 'content'" #container>

      <div class="content">

         <div class="portfolio-list" [ngClass]="{'reduced': !this.blockOverrideControls}">

            <dx-select-box [items]="this.terminalList"
                           (onValueChanged)="this.onTerminalSelected($event)"
                           [placeholder]="'Select Terminal'"
                           [displayExpr]="'displayName'"
                           [(value)]="this.selectedTerminal"
                           [showClearButton]="true"
                           style="margin-bottom: 10px;"
            ></dx-select-box>

            <dx-select-box [items]="this.portfolioList"
                           (onValueChanged)="this.onPortfolioSelected($event)"
                           [placeholder]="'Select Portfolio'"
                           [displayExpr]="'portfolioName'"
                           [(value)]="this.selectedPortfolio"
                           [showClearButton]="true"
            ></dx-select-box>
   
         </div>

         <!-- Combos -->
         <div style="margin-bottom: 10px;">

            <dx-select-box [items]="this.comboList"
                           displayExpr="comboName"
                           placeholder="Select Combo"
                           [(value)]="this.selectedCombo"
                           (onValueChanged)="this.onComboSelected($event)"
                           [showClearButton]="true"
            ></dx-select-box>

         </div>

         <!-- Combo Groups -->
         <div style="margin-bottom: 10px;">

            <dx-select-box [items]="this.comboGroupList"
                           displayExpr="comboGroupName"
                           placeholder="Select Combo Group"
                           [(value)]="this.selectedComboGroup"
                           [showClearButton]="true"
            ></dx-select-box>

         </div>
            
      </div>

      <div class="footer">
         
         <div class="buttons">
            <dx-button  [text]="actionButton.text" 
                        [width]="actionButton.width" 
                        [type]="actionButton.type"
                        (onClick)="this.onActionButtonClicked()"></dx-button>
         </div>

      </div>

      <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: container}"></dx-load-panel>

   </div>
</dx-popup>