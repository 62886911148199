import { RiskManagementComponent } from './risk-management.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate } from '@angular/common';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultTimestampFormat,
   defaultLoadingOverlayTemplate
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';

export function getViolationOrdersGridModel(
   this: RiskManagementComponent,
   displayNameService: TradingInstrumentDisplayNameService
): object {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Symbol',
            field: 'ticker',
            valueFormatter: (params: ValueFormatterParams) => {
               return displayNameService.getDisplayNameForTicker(params.value);
            }
         },
         {
            headerName: 'Type',
            field: 'orderType',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderType[params.value];
            }
         },
         {
            headerName: 'Status',
            field: 'status',
            valueFormatter: (params: ValueFormatterParams) => {
               return OrderStatus[params.value];
            }
         },
         {
            headerName: 'Qty',
            field: 'qty',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Leaves Qty',
            field: 'leavesQty',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Last Px',
            field: 'lastPx',
            valueFormatter: defaultQuoteCellFormatter
         },
         {
            headerName: 'Side',
            field: 'side',
            valueFormatter: (params: ValueFormatterParams) => {
               return MarketSide[params.value];
            }
         },
         {
            headerName: 'Last Qty',
            field: 'lastQty',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Filled',
            field: 'filledQty',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Avg. Px',
            field: 'avgFillPrice',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'Account',
            field: 'accountCode'
         },
         {
            headerName: 'Comment',
            field: 'comment'
         },
         {
            headerName: 'Timestamp',
            field: 'lastModifiedDate',
            valueFormatter: (params: ValueFormatterParams) => {
               return params.value
                  ? formatDate(params.value, defaultTimestampFormat, 'en-US')
                  : '';
            }
         },
         {
            headerName: 'ID',
            field: 'orderId'
          },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      getRowNodeId: (rowData: OrderDto) => rowData.orderId,

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}
