import {Injectable} from "@angular/core";
import {OptionsChainService} from "../../../option-chains.service";
import {HedgePositionModel} from "./hedge-position.model";
import {isVoid} from "../../../utils";
import {HedgePosition} from "../../data-model/hedge-position";
import {MessageBusService} from "../../../message-bus.service";
import {ServiceConfiguration} from "../../../adjustment-pricing-grid/services/ServiceConfiguration";
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import {UserSettingsService} from "../../../user-settings.service";
import {ApgDataService} from "../../../adjustment-pricing-grid/services/apg-data.service";
import {SessionService} from "../../../authentication/session-service.service";

const StorageKey = 'hg.hedge-positions';
const AfterStateKey = 'hg.after-state'

@Injectable()
export class HedgePositionsService {

    constructor(
        private readonly _optionChainsService: OptionsChainService,
        private readonly _messageBus: MessageBusService,
        private readonly _apgDataService: ApgDataService,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _sessionService: SessionService,
    ) {
    }

    private _serviceConfig: ServiceConfiguration;

    configure(cfg: ServiceConfiguration) {
        this._serviceConfig = cfg;
    }

    async getPositionModels(portfolio: ApgPortfolio): Promise<HedgePositionModel[]> {

        let models = [];

        try {
            const dtos = await this.getHedgePositions(portfolio);
            models = await this.convertToModels(dtos);
        } catch (e) {
            console.error(e);
        }

        return models;
    }

    async getHedgePositions(portfolio: ApgPortfolio): Promise<HedgePosition[]> {
        const key = await this.makeStorageKey(portfolio);
        const positions = this._userSettingsService.getValue<HedgePosition[]>(
            key,
            this._serviceConfig?.userId
        ) || [];
        return positions;
    }

    saveAfterStatePositions(positions: HedgePosition[], slot: 'first' | 'second', portfolio: ApgPortfolio) {

        if (isVoid(portfolio)) {
            return;
        }

        const key = this.makeAfterStateStorageKey(portfolio, slot);

        this._userSettingsService.setValue(key, positions, this._serviceConfig?.userId);

        this._messageBus.publishAsync({
            topic: 'Hg.AfterStateUpdated',
            payload: {}
        });
    }

    getAfterState(slot: 'first' | 'second', portfolio: ApgPortfolio): HedgePosition[] {

        const key = this.makeAfterStateStorageKey(portfolio, slot);

        const portfolioUserState = this._userSettingsService.getValue<HedgePosition[]>(
            key,
            portfolio.userId
        );

        // if (isVoid(portfolioUserState)) {
        //     return null;
        // }

        return portfolioUserState;
    }

    clearAfterState(portfolio: ApgPortfolio, slot?: 'first' | 'second') {

        const slots = isVoid(slot)
            ? ['first', 'second']
            : [slot];

        slots.forEach(slot => {
            const key = this.makeAfterStateStorageKey(portfolio, slot);
            this._userSettingsService.deleteValue(key, portfolio.userId);
        });

        this._messageBus.publishAsync({
            topic: 'Hg.AfterStateUpdated',
            payload: {}
        });
    }

    async convertToModels(positions: HedgePosition[]): Promise<HedgePositionModel[]> {
        const promises =
            positions.map(async x => {
                const dto = await HedgePositionModel.fromDto(x, this._optionChainsService);
                return dto;
            });

        const hedgePositionModels
            = await Promise.all(promises);

        return hedgePositionModels;
    }

    async saveHedgePositions(currentHedgePositions: HedgePosition[], portfolio: ApgPortfolio) {
        const key = await this.makeStorageKey(portfolio);

        const ul = await this._apgDataService.getUnderlyingOfPortfolio(portfolio);

        this._userSettingsService.setValue(key, currentHedgePositions, this._serviceConfig?.userId);

        this._messageBus.publish({
            topic: 'Hg.HedgePositionsChanged',
            payload: {}
        });
    }

    private async makeStorageKey(portfolio: ApgPortfolio): Promise<string> {

        console.assert(!isVoid(this._serviceConfig));

        const ul = await this._apgDataService.getUnderlyingOfPortfolio(portfolio);

        let rootKey = StorageKey;

        let key = `${rootKey}.${portfolio.id}.${ul}`;

        return key;
    }

    private makeAfterStateStorageKey(portfolio: ApgPortfolio, slot: string): string {

        console.assert(!isVoid(this._serviceConfig));

        let rootKey = AfterStateKey;

        let key = `${rootKey}.${portfolio.id}.${slot}`;

        return key;
    }

}