import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';

@Component({
  selector: 'ets-strategy-commander-rules',
  template: `
    <p *ngIf="numberOfRules > 0" class="rules-count" (click)="showStrategyCommanderRules()">{{ numberOfRules }}</p>
  `,
  styles: [
    `
      .rules-count {
        text-align: center;
        color: yellow;
        font-weight: bold;
        cursor: pointer;
      }
    `
  ]
})
export class StrategyRulesCellRendererComponent implements ICellRendererAngularComp {
  constructor(private _messageBus: MessageBusService) {}

  data: StrategyModel;
  panelId: string;

  get numberOfRules(): number {
    let result = 0;
    if (!!this.data) {
      result = this.data.numberOfCommanderRules;
    }
    return result;
  }

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    const column = params.columnApi.getColumn('numberOfCommanderRules');
    if (column) {
      const panelId = column['ets-panel-id'];
      this.panelId = panelId;
    }
  }

  refresh(): boolean {
    return false;
  }

  showStrategyCommanderRules() {
    this._messageBus.publish({
      topic: 'ShowStrategyCommanderRulesRequest',
      payload: {
        strategy: this.data,
        panelId: this.panelId
      }
    });
  }
}
