import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AdjustmentTimeSettingsDto, OrderTypeSettingsDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges, isValidNumber, isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-global-settings-roll-time-settings',
   templateUrl: './roll-time-settings.component.html',
   styleUrls: [
      './roll-time-settings.component.scss',
      '../common-fieldset.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class RollTimeSettingsComponent implements OnInit {

   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   rollTimeModes = [
      'Time Of Day',
      '[H:M:S] Before Close',
      '[H:M:S] After Open'
   ];

   private _rollTimeMode: string;
   get rollTimeMode(): string {
      return this._rollTimeMode;
   }
   @DetectSetterChanges()
   set rollTimeMode(value: string) {
      this._rollTimeMode = value;
   }

   rollTime: string;

   rollTimezone: string;

   get rollTimePickerMode(): 'time' | 'timespan' {

      if (!this.rollTimeMode) {
         return undefined;
      }

      if (this.rollTimeMode === 'Time Of Day') {
         return 'time';
      }

      return 'timespan';
   }

   get showTimezonePicker(): boolean {
      return this.rollTimeMode === 'Time Of Day';
   }

   joinRollBuffer: number;

   ngOnInit(): void { }

   //
   @DetectMethodChanges()
   setData(adjustmentTimeSettings: AdjustmentTimeSettingsDto) {

      if (isVoid(adjustmentTimeSettings)) {
         return;
      }

      this._rollTimeMode = adjustmentTimeSettings.adjustmentTimeMode;
      this.rollTime = adjustmentTimeSettings.adjustmentTime;
      this.rollTimezone = adjustmentTimeSettings.adjustmentTimezone;
      this.joinRollBuffer = adjustmentTimeSettings.joinRollBuffer;
   }

   //
   getParameters(): AdjustmentTimeSettingsDto{
      const reply: AdjustmentTimeSettingsDto = {
         adjustmentTimeMode: this.rollTimeMode,
         adjustmentTime: this.rollTime,
         adjustmentTimezone: this.rollTimezone,
         joinRollBuffer: this.joinRollBuffer
      };

      return reply;
   }

   //
   @DetectMethodChanges()
   reset() {
      this.rollTimeMode = undefined;
      this.rollTime = undefined;
      this.rollTimezone = undefined;
      this.joinRollBuffer = undefined;
   }

}
