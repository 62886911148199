import {StrategyParameters} from '../../strategies/strategy-parameters.enum';
import {StrategyFlags} from '../../strategies/strategy-flags.enum';
import {PositionDto} from './position-dto.class';

export class StrategyDto {
  constructor() {
    this.positions = [];
    this.parameters = {};
    this.dispositionStrategies = [];
  }

  strategyId: string;
  displayName: string;
  algoId: string;
  state: number;
  status: string;
  positions: PositionDto[];
  parameters: StrategyParameters;
  tags: string;
  strategyKind: number;
  terminalId: string;
  terminalCode: string;
  unitsAllocated: number;
  autostopping: boolean;
  isSession: boolean;
  tradingObjectives: string;
  scheduleParameters: string;
  triggers: string;
  positionSizing: string;
  flags: StrategyFlags;
  dispositionStrategies: StrategyDto[];
  dispositionId: string;
  numberOfCommanderRules: number;
  shellId: string;
  clientId: string;
  shellName: string;
  clientName: string;
  isAboutToExpire: boolean;
  portfolioId: string;
  comboId: string;
  comboGroupId: string;
  hedgedPositions: PositionDto[];
  positionSizingSource: string;
  hasBeenActiveDuringTheSession: boolean;
}
