import {MarketSide} from '../../trading-model/market-side.enum';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';

export enum TradeFlags {
  None = 0,
  AccumulatedNpo = 1 << 0,
  AccumulatedStopLoss = 1 << 1,
  TradeNpo = 1 << 2,
  TradeStopLoss = 1 << 3,
  SessionNpo = 1 << 4,
  SessionStopLoss = 1 << 5,
  CustomExitNpo = 1 << 6,
  CustomExitStopLoss = 1 << 7,
  ComboRoot = 1 << 8
}

export function tradeHasFlag(trade: TradeDto, flag: TradeFlags): boolean {
  return (trade.flags & flag) === flag;
}

export class TradeDto {
  accountId: string;
  qty: number;
  execPrice: number;
  side: MarketSide;
  timestamp: Date;
  pnL: number;
  perfectPnL;
  number;
  tradePnL: number;
  orderId: string;
  strategyId: string;
  tradeId: string;
  terminalId: string;
  accountCode: string;
  terminalCode: string;
  tradingInstrumentCode: string;
  comment: string;
  isProxy: boolean;
  accumulatedPnL: number;
  accumulatedPerfectPnL: number;
  accumulatedTradePnL: number;
  sessionId: string;
  seqNum: number;
  isPossibleDuplicate: boolean;
  shellId: string;
  clientId: string;
  shellName: string;
  clientName: string;
  flags: TradeFlags;
  sourceOrderType: OrderType;
  comboName: string;
  tickerDisplayName: string;
  comboId: string;
  portfolioId: string;
  comboGroupId: string;
  sourceOrderExpirationStyle: string;
}
