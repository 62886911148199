<div class="sweetspot-adjustment">
   <fieldset>
      <legend>
         <span>Sweet Spot Adjustment</span>
         <span class="checkbox">
            <dx-check-box 
               [(value)]="this.useSweetspotAdjustment" 
               (onValueChanged)="this.onChange()"
               >
            </dx-check-box>
         </span>
      </legend>

      <ng-container *ngIf="this.useSweetspotAdjustment">
         
         <div class="field2">
            <div class="field">
               <div class="label">Above Buffer Start</div>
               <div class="input">
                  <dx-number-box 
                     [useLargeSpinButtons]="true"
                     [showSpinButtons]="true"
                     [(value)]="this.aboveBufferStart"
                     [min]="0"
                     >
                  </dx-number-box>
               </div>
            </div>
      
            <div class="field">
               <div class="label">Above Buffer End</div>
               <div class="input">
                  <dx-number-box 
                     [useLargeSpinButtons]="true"
                     [showSpinButtons]="true"
                     [(value)]="this.aboveBufferEnd"
                     [min]="0"
                     >
                  </dx-number-box>
               </div>
            </div>
         </div>

         <div class="field2">
            <div class="field">
               <div class="label">Below Buffer Start</div>
               <div class="input">
                  <dx-number-box 
                     [useLargeSpinButtons]="true"
                     [showSpinButtons]="true"
                     [(value)]="this.belowBufferStart"
                     [max]="0"
                     >
                  </dx-number-box>
               </div>
            </div>
      
            <div class="field">
               <div class="label">Below Buffer End</div>
               <div class="input">
                  <dx-number-box 
                     [useLargeSpinButtons]="true"
                     [showSpinButtons]="true"
                     [(value)]="this.belowBufferEnd"
                     [max]="0"
                     >
                  </dx-number-box>
               </div>
            </div>
         </div>
   
         <div class="field">
            <div class="label">Start Time</div>
            <div class="input">
               <ets-datetime-picker 
                  [mode]="'time'"
                  [(value)]="this.startTime"
                  >
               </ets-datetime-picker>
            </div>
         </div>

         <div class="field">
            <div class="label">End Time</div>
            <div class="input">
               <ets-datetime-picker 
                  [mode]="'time'"
                  [(value)]="this.endTime"
                  >
               </ets-datetime-picker>
            </div>
         </div>

         <div class="field">
            <ets-timezone-picker 
               [flow]="'col'"
               [showBorder]="false"
               [(timezone)]="this.timezone"
               >
            </ets-timezone-picker>
         </div>


      </ng-container>


   </fieldset>
</div>