import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';
import { ProtocolCommand } from '../../../service-model/protocol-command.interface';

export class RemoveRiskManagerRule implements ProtocolCommand {
   constructor(
      public readonly id: string
   ) { }

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveRiskManagerRule`;
   }
}
