import { NgModule } from '@angular/core';
import { ManualPositionsComponent } from './positions/manual-positions.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReplaceOrderDialogComponent } from './orders-and-trades/replace-order-popup/replace-order-dialog.component';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { CommonModule } from '@angular/common';
import { SyncOverrideDialogModule } from '../sync-override-dialog/sync-override-dialog.module';
import { PortfoliosModule } from '../portfolios/portfolios.module';
import { PositionAdjustModule } from '../position-adjust/position-adjust.module';
import { PositionOverrideModule } from '../position-override/position-override.module';
import { AgGridContribModule } from '../unspecific/ag-grid-contrib/ag-grid-contrib.module';
import { AngularSplitModule } from 'angular-split';
import { ManualOrdersAndTradesComponent } from './orders-and-trades/manual-orders-and-trades.component';
import { PortfolioOrdersAndTradesComponent } from './orders-and-trades/portfolio-orders-and-trades.component';
import { DevExtremeModule } from '../devextreme.module';
import { MoveOrderToPortfolioDialogComponent } from './orders-and-trades/move-to-portfolio-dialog/move-order-to-portfolio-dialog.component';
import { FutureOrdersComponent } from './orders-and-trades/future-orders.component';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { OrderExtensionsModule } from '../order-extensions/order-extensions.module';


@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      EtsSymbolPickerModule,
      SyncOverrideDialogModule,
      AgGridContribModule,
      AgGridModule.withComponents([]),
      PortfoliosModule,
      PositionAdjustModule,
      PositionOverrideModule,
      DevExtremeModule,
      DateTimePickerModule,
      TimezonePickerModule,
      OrderExtensionsModule
   ],
   exports: [
      ManualOrdersAndTradesComponent,
      PortfolioOrdersAndTradesComponent,
      ManualPositionsComponent
   ],
   declarations: [
      ManualOrdersAndTradesComponent,
      ManualPositionsComponent,
      ReplaceOrderDialogComponent,
      PortfolioOrdersAndTradesComponent,
      MoveOrderToPortfolioDialogComponent,
      FutureOrdersComponent
   ],
   providers: []
})
export class ManualTradingModule { }
