import { Injectable } from '@angular/core';
import { ClientShellDescriptorDto } from 'projects/shared-components/client-console/protocol/dto';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ScopeStorageService {
   constructor(
      private readonly _sessionService: SessionService,
      private readonly _toastr: ToastrService
   ) { }

   getScope(): ClientShellDescriptorDto[] {
      const key = this._getStorageKey();

      if (!key) {
         this._toastr.error('"Get Scope" operation completed with errors: no session data', 'Scope Setup');
         return [];
      }

      const scopeString = sessionStorage.getItem(key);
      if (!scopeString) {
         return [];
      }
      const scope = JSON.parse(scopeString) as ClientShellDescriptorDto[];
      if (!scope) {
         return [];
      }
      return scope;
   }

   setScope(scope: ClientShellDescriptorDto[]): void {
      if (!scope) {
         this._toastr.error(
            '"Set Scope" operation completed with errors: empty scope',
            'Scope Setup'
         );
         return;
      }
      const key = this._getStorageKey();
      if (!key) {
         this._toastr.error('"Set Scope" operation completed with errors: no session data', 'Scope Setup');
         return;
      }
      const stringScope = JSON.stringify(scope);
      sessionStorage.setItem(key, stringScope);
   }

   resetScope(): void {
      const key = this._getStorageKey();
      if (!key) {
         this._toastr.error('"Reset Scope" operation completed with errors: no session data', 'Scope Setup');
         return;
      }
      sessionStorage.removeItem(key);
   }

   private _getStorageKey(): string {
      // tslint:disable-next-line: no-non-null-assertion
      if (!this._sessionService.sessionData) {
         return null;
      }
      const userEmail = this._sessionService.sessionData.userEmail;
      const storageKey = `${EtsConstants.storageKeys.dashboardScopeKey}+${userEmail}`;
      return storageKey;
   }
}
