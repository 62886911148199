// tslint:disable: no-string-literal
import { ParametersControlBase } from '../../../parameters-control-base';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { DetectParameterChanges, DetectSetterChanges, isTruthy } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { ICommonDispositionParameters } from '../../../common-disposition-parameters';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { TradeTheLineParametersViewComponent } from './tradetheline-parameters-view.component';


export enum TradeTheLineMode {
   None,
   Bull,
   BullBear,
   Bear
}

export enum TradeTheLineChartCloseMode {
   Unknown,
   Previous,
   Current
}

export class TradeTheLineAlgoParametersControl extends ParametersControlBase<TradeTheLineParametersViewComponent> {
   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);

      for (let i = 1; i < 51; i++) {
         this.multiplierItems.push(i);
      }

      for (let i = 60; i < 110; i += 10) {
         this.multiplierItems.push(i);
      }

      for (let i = 125; i < 225; i += 25) {
         this.multiplierItems.push(i);
      }

      for (let i = 250; i < 550; i += 50) {
         this.multiplierItems.push(i);
      }
   }

   private _symbol: TradingInstrument;
   private _useChartCloseModePrevious = false;
   private _useChartCloseModeCurrent = false;
   private _mode: TradeTheLineMode;

   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;
   multiplierItems: number[] = [];
   multiplier: number;
   chartTimeframe: string;
   disasterPriceBull: number;
   disasterPriceBear: number;

   get mode(): TradeTheLineMode {
      return this._mode;
   }

   @DetectParameterChanges()
   set mode(v: TradeTheLineMode) {
      this._mode = v;
      if (v === TradeTheLineMode.Bear) {
         this.disasterPriceBull = null;
      } else if (v === TradeTheLineMode.Bull) {
         this.disasterPriceBear = null;
      }
   }

   get useChartCloseModePrevious(): boolean {
      return this._useChartCloseModePrevious;
   }

   @DetectParameterChanges()
   set useChartCloseModePrevious(v: boolean) {
      this._useChartCloseModePrevious = v;
      if (v) {
         this._useChartCloseModeCurrent = false;
      }
   }

   //

   get useChartCloseModeCurrent(): boolean {
      return this._useChartCloseModeCurrent;
   }

   @DetectParameterChanges()
   set useChartCloseModeCurrent(v: boolean) {
      this._useChartCloseModeCurrent = v;
      if (v) {
         this._useChartCloseModePrevious = false;
      }
   }

   //

   get canSetDisasterPriceBull(): boolean {
      return this._mode !== TradeTheLineMode.Bear;
   }

   //

   get canSetDisasterPriceBear(): boolean {
      return this._mode !== TradeTheLineMode.Bull;
   }

   chartTimeframeItems = [
      { text: '1 min', value: '1' },
      { text: '5 min', value: '5' },
      { text: '15 min', value: '15' },
      { text: '30 min', value: '30' },
      { text: '1 hour', value: '60' },
      { text: '4 hours', value: '240' },
      { text: 'Daily', value: 'D' },
      { text: 'Weekly', value: 'W' }
   ];

   modeItems = [
      { text: 'Bull', value: TradeTheLineMode.Bull },
      { text: 'Bear', value: TradeTheLineMode.Bear },
      { text: 'Bull/Bear', value: TradeTheLineMode.BullBear },
   ];

   get symbol(): TradingInstrument {
      return this._symbol;
   }

   @DetectParameterChanges()
   set symbol(value: TradingInstrument) {
      if (this._symbol === value) {
         return;
      }
      this._symbol = value;
   }

   get algoId(): string {
      return EtsConstants.algorithms.tradeTheLineAlgoId;
   }

   _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker : null;
      params['multiplier'] = isTruthy(this.multiplier) ? this.multiplier + '' : null;
      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['istradethroughmit'] = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;
      params['mode'] = isTruthy(this.mode) ? this.mode + '' : null;
      params['charttimeframe'] = isTruthy(this.chartTimeframe) ? this.chartTimeframe + '' : null;

      if (this.useChartCloseModeCurrent) {
         params['chartclosemode'] = TradeTheLineChartCloseMode.Current + '';
      } else if (this.useChartCloseModePrevious) {
         params['chartclosemode'] = TradeTheLineChartCloseMode.Previous + '';
      } else {
         params['chartclosemode'] = null;
      }

      params['disasterpricebull'] = isTruthy(this.disasterPriceBull) ? this.disasterPriceBull + '' : null;
      params['disasterpricebear'] = isTruthy(this.disasterPriceBear) ? this.disasterPriceBear + '' : null;

      return params;
   }

   //

   _SetParameters(dto: StrategyDto) {
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }
      if (isTruthy(params['multiplier'])) {
         this.multiplier = parseInt(params['multiplier']);
      }
      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughmit'])) {
         this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mode'])) {
         this.mode = parseInt(params['mode']);
      }
      if (isTruthy(params['chartclosemode'])) {
         const chartCloseMode = parseInt(params['chartclosemode']);
         if (chartCloseMode === TradeTheLineChartCloseMode.Current) {
            this.useChartCloseModeCurrent = true;
         } else if (chartCloseMode === TradeTheLineChartCloseMode.Previous) {
            this.useChartCloseModePrevious = true;
         }
      }
      if (isTruthy(params['charttimeframe'])) {
         this.chartTimeframe = params['charttimeframe'];
      }
      if (isTruthy(params['disasterpricebull'])) {
         this.disasterPriceBull = parseFloat(params['disasterpricebull']);
      }
      if (isTruthy(params['disasterpricebear'])) {
         this.disasterPriceBear = parseFloat(params['disasterpricebear']);
      }
   }

   //

   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!isTruthy(this.symbol)) {
         errors.push('Symbol is required');
      }
      if (!isTruthy(this.multiplier)) {
         errors.push('Multiplier is required');
      }
      if (!isTruthy(this.mode)) {
         errors.push('Mode is required');
      }
      if (!isTruthy(this.chartTimeframe)) {
         errors.push('Chart Timeframe is requried');
      }
      if (!this.useChartCloseModeCurrent && !this.useChartCloseModePrevious) {
         errors.push('Chart Close Mode is required');
      }
      if (!isTruthy(this.tradingObjectives.accumulatedNpo) && !isTruthy(this.tradingObjectives.tradeNpo)) {
         errors.push('NPO is required');
      }
      if (isTruthy(this.disasterPriceBull)) {
         const tickSize = this.symbol.tickSize;
         const base = Math.floor(this.disasterPriceBull / tickSize);
         const restored = base * tickSize;
         if (restored !== this.disasterPriceBull) {
            errors.push('Please, enter correct disaster price (Bull)');
         }
      }
      if (isTruthy(this.disasterPriceBear)) {
         const tickSize = this.symbol.tickSize;
         const base = Math.floor(this.disasterPriceBear / tickSize);
         const restored = base * tickSize;
         if (this.disasterPriceBear !== restored) {
            errors.push('Please, enter correct disaster price (Bear)');
         }
      }

      return errors;
   }

   //

   onCustomNumberCreated(data, collection: number[]) {
      const newItem = parseFloat(data.text);
      if (!isNaN(newItem)) {
         const isNew = collection.indexOf(newItem) === -1;
         data.customItem = newItem;
         if (isNew) {
            collection.push(newItem);
         }
      }
   }

   //

   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean) {
   }

   //

   onStrategyTerminalChanged(): void {
   }
}
