// tslint:disable: no-string-literal
import { ParametersControlBase } from '../../../parameters-control-base';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { isTruthy } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { ICommonDispositionParameters } from '../../../common-disposition-parameters';
import { ComboDto, ComboGroupDto, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { HedgeParametersViewComponent } from './hedge-parameters-view.component';



export class HedgeAlgoParametersControl extends ParametersControlBase<HedgeParametersViewComponent> {
   
   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);
   }

   symbol: TradingInstrument;
   
   availablePortfolios: PortfolioDto[];
   availableCombos: ComboDto[];
   availableComboGroups: ComboGroupDto[];

   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;

   hedgeTarget: 'Cash Value' | 'Deltas';
   hedgeRounding: 'Up' | 'Down';
   targetPortfolio: string;
   targetCombo: string;
   targetComboGroup: string;
   deltaHedgeMode: '+Delta' | '-Delta' | '+/-Delta';
   hedgeInstrument: 'Underlying' | 'Option';
   hedgeOptionDaysToExpiration: number;
   hedgeOptionPercentOtmStrike: number;
   deltaAdjustWhen: number;
   deltaAdjustTo: number;
   orderType: 'Market' | 'Limit';
   orderDistance: number;
   deltaDeviationCancel: number;
   customBeta: number;

   get algoId(): string {
      return EtsConstants.algorithms.hedgeAlgoId;
   }

   onStrategyTerminalChanged() {
      
      this.availablePortfolios = this.availableCombos = this.availableComboGroups = null;
      this.targetPortfolio = this.targetCombo = this.targetComboGroup = null;

      if (!this.holder.selectedTerminal) {
         return;
      }

      const portfolios = this.holder.availableBuckets.portfolios.filter(x => x.terminalId === this.holder.selectedTerminal.terminalId);
      this.availablePortfolios = portfolios;
   }

   onTargetPortfolioChanged() {
      this.availableCombos = this.availableComboGroups = null;
      this.targetCombo = this.targetComboGroup = null;

      if (!this.targetPortfolio) {
         return;
      }
      
      const combos = this.holder.availableBuckets.combos.filter(x => x.portfolioId === this.targetPortfolio);
      this.availableCombos = combos;
   }

   onTargetComboChanged() {
      this.availableComboGroups = null;
      this.targetComboGroup = null;

      if (!this.targetCombo) {
         return;
      }

      const groups = this.holder.availableBuckets.comboGroups.filter(x => x.comboId === this.targetCombo);
      this.availableComboGroups = groups;
   }

   _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['istradethroughmit'] = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;
      params['targetportfolio'] = isTruthy(this.targetPortfolio) ? this.targetPortfolio + '' : null;
      params['targetcombo'] = isTruthy(this.targetCombo) ? this.targetCombo + '' : null;
      params['targetcombogroup'] = isTruthy(this.targetComboGroup) ? this.targetComboGroup + '' : null;
      params['ordertype'] = isTruthy(this.orderType) ? this.orderType + '' : null;
      params['deltahedgemode'] = isTruthy(this.deltaHedgeMode) ? this.deltaHedgeMode + '' : null;
      params['deltaadjustwhen'] = isTruthy(this.deltaAdjustWhen) ? this.deltaAdjustWhen + '' : null;
      params['deltaadjustto'] = isTruthy(this.deltaAdjustTo) ? this.deltaAdjustTo + '' : null;
      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker + '' : null;
      params['hedgetarget'] = isTruthy(this.hedgeTarget) ? this.hedgeTarget + '' : null;
      params['hedgerounding'] = isTruthy(this.hedgeRounding) ? this.hedgeRounding + '' : null;
      params['hedgeinstrument'] = isTruthy(this.hedgeInstrument) ? this.hedgeInstrument + '' : null;

      params['hedgeoptiondaystoexpiration'] = null;
      params['hedgeoptionpercentotmstrike'] = null;

      if (this.hedgeInstrument === 'Option') {
         params['hedgeoptiondaystoexpiration'] = isTruthy(this.hedgeOptionDaysToExpiration) ? this.hedgeOptionDaysToExpiration + '' : null;
         params['hedgeoptionpercentotmstrike'] = isTruthy(this.hedgeOptionPercentOtmStrike) ? this.hedgeOptionPercentOtmStrike + '' : null;
      }

      params['custombeta'] = isTruthy(this.customBeta) ? this.customBeta + '' : null;
      
      params['orderdistance'] = null;
      params['deltadeviationcancel'] = null;
   
      if (this.orderType === 'Limit') {
         params['orderdistance'] = isTruthy(this.orderDistance) ? this.orderDistance + '' : null;
         params['deltadeviationcancel'] = isTruthy(this.deltaDeviationCancel) ? this.deltaDeviationCancel + '' : null;
      }

      return params;
   }

   _SetParameters(dto: StrategyDto) {

      this.onStrategyTerminalChanged();
      
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }
    
      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughmit'])) {
         this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
      }

      if (isTruthy(params['targetportfolio'])) {
         const portfolioId = params['targetportfolio'];
         this.targetPortfolio = portfolioId;
         this.onTargetPortfolioChanged();
      }

      if (isTruthy(params['targetcombo'])) {
         const comboId = params['targetcombo'];
         this.targetCombo = comboId;
         this.onTargetComboChanged();
      }

      if (isTruthy(params['targetcombogroup'])) {
         const comboGroupId = params['targetcombogroup'];
         this.targetComboGroup = comboGroupId;
      }

      if (isTruthy(params['ordertype'])) {
         this.orderType = params['ordertype'];
      }

      if (isTruthy(params['deltahedgemode'])) {
         this.deltaHedgeMode = params['deltahedgemode'];
      }
      
      if (isTruthy(params['deltaadjustwhen'])) {
         this.deltaAdjustWhen = parseInt(params['deltaadjustwhen']);
      }

      if (isTruthy(params['deltaadjustto'])) {
         this.deltaAdjustTo = parseInt(params['deltaadjustto']);
      }

      if (isTruthy(params['hedgeoptiondaystoexpiration'])) {
         this.hedgeOptionDaysToExpiration = parseInt(params['hedgeoptiondaystoexpiration']);
      }

      if (isTruthy(params['hedgeoptionpercentotmstrike'])) {
         this.hedgeOptionPercentOtmStrike = parseInt(params['hedgeoptionpercentotmstrike']);
      }

      if (isTruthy(params['orderdistance'])) {
         this.orderDistance = parseFloat(params['orderdistance']);
      }

      if (isTruthy(params['deltadeviationcancel'])) {
         this.deltaDeviationCancel = parseFloat(params['deltadeviationcancel']);
      }
      
      if (isTruthy(params['hedgetarget'])) {
         this.hedgeTarget = params['hedgetarget'];
      }
     
      if (isTruthy(params['custombeta'])) {
         this.customBeta = params['custombeta'];
      }

      if (isTruthy(params['hedgeinstrument'])) {
         this.hedgeInstrument = params['hedgeinstrument'];
      }

      if (isTruthy(params['hedgerounding'])) {
         this.hedgeRounding = params['hedgerounding'];
      }
   }

   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!this.symbol) {
         errors.push('Symbol is mandatory');
      }

      if (!this.hedgeTarget) {
         errors.push('"Hedge Target" is requried');
      }


      if (!this.hedgeRounding) {
         errors.push('"Hedge Rounding" is required');
      }

      if (!this.hedgeInstrument) {
         errors.push('"Hedge Instrument" is Required');
      }


      if (this.hedgeTarget === 'Deltas') {
         if (!this.deltaAdjustWhen) {
            if (this.deltaAdjustTo) {
               errors.push('"Delta Adjust To" only available with "Delta Adjust When"');
            }
         } else {
            const adjustTo = this.deltaAdjustTo || 0;
            if (Math.abs(this.deltaAdjustWhen) <= adjustTo) {
               errors.push('Delta\'s "Adjust To" parameter must be less than "Adjust When"');
            }
         }
   
         if (!this.deltaHedgeMode) {
            errors.push('Delta Hedge Mode is Required');
         }
      }


      if (!this.orderType) {
         errors.push('Order type is required');
      }

      if (!this.targetPortfolio) {
         errors.push('Target portfolio is required');
      }

      if (!this.targetCombo) {
         if (this.targetComboGroup) {
            errors.push('Target combo is required');
         }
      }

      return errors;
   }

   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean): void {
      //
   }
}
