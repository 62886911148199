import {GatewayStatus} from '../../../gateways/gateway-status.enum';
import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class ChangeGatewayStatus implements ProtocolCommand {
  constructor(
    public readonly gatewayId: string,
    public readonly status: GatewayStatus
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ChangeGatewayStatus`;
  }
}
