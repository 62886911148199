import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';
import { ProtocolCommand } from '../../../service-model/protocol-command.interface';

export class CancelManualOrders implements ProtocolCommand {
   public constructor(
      public readonly orderIds: string[]
   ) { }

   public getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.CancelManualOrders`;
   }
}
