import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   GetContextMenuItemsParams,
   GridOptions,
} from 'ag-grid-community';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   defaultPriceCellFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from 'projects/shared-components/environments/environment';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { SyncOverrideDialogComponent } from './sync-override-dialog.component';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';

export function getSyncOverrideManualPositionsGrid(
   this: SyncOverrideDialogComponent,
   displayNameService: TradingInstrumentDisplayNameService,
   gridType: 'aMP' | 'sMP'
): GridOptions {

   const columns = setupColumns(displayNameService);

   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      groupUseEntireRow: true,

      frameworkComponents: {
         simpleGroupRowRenderer: SimpleHeaderGroupRowInnerRenderer
      },

      groupRowRendererParams: {
         innerRenderer: 'simpleGroupRowRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: isDashboard ? 'always' : null,

      suppressCellSelection: true,

      onGridReady: args => this.onGridReady(args, gridType),

      getRowNodeId: (data: PositionDto) => {
         return data.positionId;
      },

      getContextMenuItems: () => {

         return ['autoSizeAll', 'copy', 'export'];
      },

      onFirstDataRendered: () => this.autoSizeColumns(gridType),

   } as GridOptions;
}

function setupColumns(displayNameService: TradingInstrumentDisplayNameService): Partial<AgGridColumn>[] {
   const cols: Partial<AgGridColumn>[] = [];

   cols.push({
      headerName: '',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 60
   });

   if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      cols.push(
         {
            headerName: 'Client',
            field: 'clientName',
            enableRowGroup: true,
            rowGroup: true
         },
         {
            headerName: 'Shell',
            field: 'shellName',
            enableRowGroup: true,
            rowGroup: true
         }
      );
   }

   cols.push(
      {
         headerName: 'Symbol',
         field: 'ticker',
         valueFormatter: (params: ValueFormatterParams) => {
            return displayNameService.getDisplayNameForTicker(params.value);
         },
      },
      //
      {
         headerName: 'Position',
         field: 'netPosition',
         valueFormatter: defaultNumberCellFormatter
      },
      //
      {
         headerName: 'Avg. Px',
         field: 'avgPx',
         valueFormatter: defaultPriceCellFormatter
      },
      //
      // Object.assign(
      //    { headerName: 'Open P&L', field: 'sessionUnrealizedPnL' },
      //    defaultMoneyCellDefinition
      // ),
      //
      Object.assign(
         { headerName: 'Session P&L', field: 'sessionTotalPnL' },
         defaultMoneyCellDefinition
      ),
      //
      Object.assign(
         { headerName: 'Acc. P&L', field: 'accumulatedTotalPnL' },
         defaultMoneyCellDefinition
      ),
      //
      { headerName: 'Terminal', field: 'terminalCode' },
      //
      { headerName: 'Account', field: 'accountCode' },
      //
      {
         headerName: 'Last Match Px.',
         field: 'lastMatchPx',
         valueFormatter: defaultQuoteCellFormatter,
         hide: true
      }
   );

   return cols;
}
