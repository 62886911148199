<dx-popup [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="500"
          [width]="405"
          [resizeEnabled]="true"
          [showCloseButton]="false"
          [showTitle]="true"
          [title]="this.title"
          [visible]="this.visible"
          class="popup"
          (onHidden)="this.onClosed()"
>
  <div *dxTemplate="let data of 'content'" class="root">
    
    <div class="row fills">
      
      <div class="fill">
        <div class="col number header">
          #
        </div>
        <div class="col remove header">
          <div class="trash-icon">
            <dx-button icon="trash"></dx-button>
          </div>
        </div>
        <div class="col side header">
          Side
        </div>
        <div class="col qty header">
          Qty
        </div>
        <div class="col price header">
          Price
        </div>
        <div class="col price header">
          Total
        </div>
      </div>
      
      <div class="fills-container">
        <dx-scroll-view>
          <div *ngFor="let fill of this.fills; index as i" class="fill">
            
            <div class="col number">
              {{ i + 1 }}
            </div>
            
            <div class="col remove">
              <dx-button (onClick)="this.removeFill(fill)" icon="trash"></dx-button>
            </div>
            
            <div class="col side">
              <dx-select-box [items]="fill.sideList" [(value)]="fill.side" displayExpr="side"
                             [inputAttr]="this.getInputFieldStyle(fill)"
                (onValueChanged)="this.onChange()">
                <div *dxTemplate="let data of 'item'">
                  <span [style.color]=" data &&  data.side === 'Buy' ? 'cornflowerblue' : 'red'">
                    {{ data?.side }}
                  </span>
                </div>
              </dx-select-box>
            </div>
            
            <div class="col qty">
              <dx-number-box (onValueChanged)="this.onChange()"
                             [(value)]="fill.quantity"
                             [inputAttr]="this.numberBoxInputAttr"
                             [min]="0"
                             placeholder="Qty"
              ></dx-number-box>
            </div>
            
            <div class="col price">
              <dx-number-box (onValueChanged)="this.onChange()"
                             [(value)]="fill.price"
                             [inputAttr]="this.numberBoxInputAttr"
                             placeholder="Price"
                             format="$0#.00"
              ></dx-number-box>
            </div>
            
            <div class="col total">
              <div class="sign">=</div>
              <div class="value">
                <dx-number-box [value]="fill.total"
                               [inputAttr]="this.getPnLAttribute(fill.total)"
                               format="$0#.00"
                               [readOnly]="true"
                ></dx-number-box>
              </div>
            </div>
            
          </div>
        </dx-scroll-view>
      </div>
      
      <div class="summary">
        
        <div class="col">
          <div class="add-fill">
            <dx-button (onClick)="this.addFill()" text="Add Fill" width="100%"></dx-button>
          </div>
        </div>
        
        <div class="col">
          
          <div class="row">
            <div class="col label">
              Mode
            </div>
            <div class="col input">
              <dx-select-box [items]="this.modeList" [(value)]="this.mode" [inputAttr]="this.numberBoxInputAttr">
              </dx-select-box>
            </div>
          </div>
          
          <div class="row">
            <div class="col label">
              Transaction Total
            </div>
            <div class="col input">
              <dx-number-box
                  #total
                [readOnly]="true"
                [value]="this.getTransValue()"
                format="$0#.00"
                [inputAttr]="this.getPnLAttribute(total.value)"
              ></dx-number-box>
            </div>
          </div>
          
          <div class="row avg-price">
            <div class="col label">
              Avg. Fill Price
            </div>
            <div class="col input">
              <dx-number-box
                  #avgPx
                [readOnly]="true"
                [value]="this.getAvgFillPrice()"
                format="$0#.00"
                [inputAttr]="this.getPnLAttribute(avgPx.value)"
              ></dx-number-box>
            </div>
          </div>
        </div>
        
      </div>
      
      <hr style="width: 30%; border-color: dimgray">
      
    </div>
    
    <div class="row pricing target-default-qty">
      <div class="col label">
        Enter Default Quantity
      </div>
      <div class="col input">
        <dx-number-box
                       [(value)]="this.defaultQty"
                       [inputAttr]="this.numberBoxInputAttr"
        ></dx-number-box>
      </div>
    </div>
    
    <div class="row pricing normalized-price">
      <div class="col label">
        Normalized Price
      </div>
      <div class="col input">
        <dx-number-box #normPx
                       [readOnly]="true"
                       [value]="this.getNormalizedPrice()"
                       format="$0#.00"
                       [inputAttr]="this.getPnLAttribute(normPx.value)"
        ></dx-number-box>
      </div>
    </div>
    
    <div class="row close">
      <dx-button (onClick)="this.onClosed()" text="Close" width="100%"></dx-button>
    </div>
  
  </div>
</dx-popup>