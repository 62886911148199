<div class="sidebox">
   
   <ng-container *ngIf="(this.longLeg || this.shortLeg) else noLegs">
      <!-- Long Leg -->
      <ng-container *ngIf="this.longLeg; else noLongLeg">
         
         <div class="leg">
         
            <div class="label">
               Long Leg: 
            </div>
            
            <div class="data">
               <div class="qty">
                  {{this.longLeg.qty}}
               </div>
               <div class="asset">
                  {{this.longLeg.asset}}
               </div>
               <div class="exp">
                  {{this.longLeg.expiration}}
               </div>
               <div class="dte">
                  {{this.longLeg.daysToExpiration}}
               </div>
            </div>
         </div>
      </ng-container>
      <ng-template #noLongLeg>
         <div class="leg">
            <div class="label">
               Long Leg: 
            </div>
            <div class="data">N/A</div>
         </div>
      </ng-template>
      
      <!-- Short Leg -->
      <ng-container *ngIf="this.shortLeg; else noShortLeg">
   
         <div class="leg">
   
            <div class="label">
               Short Leg: 
            </div>
   
            <div class="data">
               <div class="qty">
                  {{this.shortLeg.qty}}
               </div>
               <div class="asset">
                  {{this.shortLeg.asset}}
               </div>
               <div class="exp">
                  {{this.shortLeg.expiration}}
               </div>
               <div class="dte">
                  {{this.shortLeg.daysToExpiration}}
               </div>
            </div>
   
         </div>
   
      </ng-container>
      
      <ng-template #noShortLeg>
         <div class="leg">
            <div class="label">
               Short Leg: 
            </div>
            <div class="data">N/A</div>
         </div>
      </ng-template>
   </ng-container>
   
   <ng-template #noLegs>
      <div class="na">
         <p>N/A</p>
      </div>
   </ng-template>

</div>
