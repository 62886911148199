import { Injectable } from '@angular/core';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { ParametersControlBase } from './parameters-control-base';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import {
   LimitAlgoParametersControl
} from './parameters-controls/limit/limit-algo-parameters-control';
import {
   BullBearAlgoParametersControl
} from './parameters-controls/bullbear/bullbear-algo-parameters-control';
import {
   FusionAlgoParametersControl
} from './parameters-controls/disposition/fusion/fusion-algo-parameters-control';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { FlagshipAlgoParametersControl } from './parameters-controls/flagships/flagship-algo-parameters-control';
import { TrendLimitAlgoParametersControl } from './parameters-controls/trend/trend-limit/trendlimit-algo-parameters-control';
import { TradeTheLineAlgoParametersControl } from './parameters-controls/trend/trade-the-line/tradetheline-algo-parameters-control';
import { DynamicHedgeAlgoParametersControl } from './parameters-controls/hedging/dynamic-hedge/dynamic-hedge-algo-parameters-control';
import { FreezeHedgeAlgoParametersControl } from './parameters-controls/hedging/freeze-hedge/freeze-hedge-algo-parameters-control';
import { HedgeAlgoParametersControl } from './parameters-controls/hedging/hedge/hedge-algo-parameters-control';
import { RemoteAlgoParametersControl } from './parameters-controls/remote/remote-algo-parameters-control';

@Injectable({ providedIn: 'root' })
export class ParametersControlService {
   constructor(
      private readonly _tradingInstrumentsService: TradingInstrumentsService,
      private readonly _toastr: ToastrService,
      private readonly _algoMetadataService: AlgoMetadataService
   ) {
   }

   getParametersControl(algoId: string): ParametersControlBase<unknown> {
      switch (algoId) {
         case EtsConstants.algorithms.limitAlgoId: {
            return new LimitAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }

         case EtsConstants.algorithms.bullBearAlgoId: {
            return new BullBearAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }

         case EtsConstants.algorithms.fusionAlgoId: {
            return new FusionAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr,
               this._algoMetadataService
            );
         }


         case EtsConstants.algorithms.flagships.followAlgoId:
         case EtsConstants.algorithms.flagships.s40AlgoId:
         case EtsConstants.algorithms.flagships.outrightAlgoId:
         case EtsConstants.algorithms.flagships.beforeLastAlgoId:
         case EtsConstants.algorithms.flagships.oppositeBeforeLastAlgoId:
         case EtsConstants.algorithms.flagships.majority:
         case EtsConstants.algorithms.flagships.minority:
         case EtsConstants.algorithms.flagships.zigZag:
         case EtsConstants.algorithms.flagships.fmr: {
            const ctrl = new FlagshipAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr,
               algoId
            );
            return ctrl;
         }

         case EtsConstants.algorithms.trendLimitAlgoId: {
            return new TrendLimitAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }

         case EtsConstants.algorithms.tradeTheLineAlgoId: {
            return new TradeTheLineAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }

         case EtsConstants.algorithms.dynamicHedgeAlgoId: {
            return new DynamicHedgeAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }

         case EtsConstants.algorithms.freezeHedgeAlgoId: {
            return new FreezeHedgeAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }
         
         case EtsConstants.algorithms.hedgeAlgoId: {
            return new HedgeAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }
         
         case EtsConstants.algorithms.remoteAlgoId: {
            return new RemoteAlgoParametersControl(
               this._tradingInstrumentsService,
               this._toastr
            );
         }

         default: {
            return null;
         }
      }
   }
}
