import { Component, EventEmitter, Input, OnInit, Output, Inject, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { LayoutTab } from './layout-tab';
import { LayoutSecurityContextService } from '../layout-security-context.service';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { LocationService } from 'projects/shared-components/location.service';


@Component({
   selector: 'ets-layout-tabs',
   templateUrl: 'layout-tabs.component.html',
   styleUrls: ['layout-tabs.component.scss'],
   providers: [LayoutSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class LayoutTabsComponent implements OnInit {

   constructor(
      public readonly securityContext: LayoutSecurityContextService,
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _locationService: LocationService
   ) { }

   @Input() tabs: LayoutTab[] = [];
   @Output() tabSelected = new EventEmitter();
   @Output() onAddTab = new EventEmitter();
   @Output() onCloseTab = new EventEmitter<LayoutTab>();
   @Output() onHeaderChanged = new EventEmitter<LayoutTab>();
   @Output() sortLayouts = new EventEmitter();

   get isEtsHost(): boolean {
      return this._locationService.isEtsHost;
   }

   ngOnInit() {
   }


   @DetectMethodChanges()
   addTab() {
      this.onAddTab.emit();
   }


   @DetectMethodChanges()
   selectTab(tab: LayoutTab) {
      if (tab.isActive) {
         return;
      }
      this.tabs.forEach(x => x.isActive = false);
      tab.isActive = true;
      this.tabSelected.emit(tab);
   }


   @DetectMethodChanges()
   setTabHeader(tab: LayoutTab) {
      tab.isEditMode = false;
      if (!tab.proposedHeader) {
         return;
      }
      tab.header = tab.proposedHeader;
      this.onHeaderChanged.emit(tab);
   }

   @DetectMethodChanges()
   onTabKeyUp(e: any, tab: LayoutTab) {
      const ke = e.event as KeyboardEvent;
      ke.preventDefault();
      if (ke.key === 'Escape') {
         this.onFocusLost(tab);
      }
   }

   @DetectMethodChanges()
   onFocusLost(tab: LayoutTab) {
      tab.isEditMode = false;
      tab.proposedHeader = tab.header;
   }

   @DetectMethodChanges()
   closeTab(tab: LayoutTab) {
      this.onCloseTab.emit(tab);
   }

   @DetectMethodChanges()
   onTabHeaderDoubleClick(tab: LayoutTab) {
      if (!this.securityContext.canChangeLayoutHeader) {
         return;
      }
      tab.isEditMode = true;
   }

   @DetectMethodChanges()
   onSortLayouts() {
      this.sortLayouts.emit();
   }
}
