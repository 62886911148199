/**
 * Represents a subscription from TradingSystemsView for a subset of strategies it should show
 */
import { StrategyModel, ObserversNotificationParams } from './strategy-model';
import { StrategySubscriptionViewAdapter } from './strategy-subscription-view-adapter.interface';
import { isNullOrUndefined } from '../utils';
import { StrategiesService } from './strategies.service';

export class StrategiesSubscription {
   constructor(
      public readonly predicate: (s: StrategyModel) => boolean,
      private readonly _viewAdapter: StrategySubscriptionViewAdapter,
      private readonly _type: 'main' | 'inner'
   ) { }

   private readonly _subscribedStrategies: string[] = [];

   add(...strategies: StrategyModel[]) {
      
      const filtered = [];
      

      strategies.forEach(str => {

         if (this._subscribedStrategies.indexOf(str.strategyId) === -1) {
            this._subscribedStrategies.push(str.strategyId);
         }

         if (this._type === 'main') {
            if (!str.isInnerStrategy) {
               filtered.push(str);
               str.addObserver(this);
            }
         } else if (this._type === 'inner') {
            if (str.isInnerStrategy) {
               filtered.push(str);
               str.addObserver(this);
            }
         }

      });

      if (filtered.length > 0) {
         if (this._type === 'main') {
            const strategyGrid = this._viewAdapter.strategiesGrid;
            if (!isNullOrUndefined(strategyGrid)) {
               strategyGrid.api.applyTransaction({ add: filtered });
            }
         } else if (this._type === 'inner') {
            const innersGrid = this._viewAdapter.innerStrategiesGrid;
            if (!isNullOrUndefined(innersGrid)) {
               innersGrid.api.applyTransaction({ add: filtered });
            }
         }
      }
   }

   //

   remove(...strategies: StrategyModel[]) {
      const toInner = [];
      const toMain = [];

      strategies.forEach(x => {
         x.removeObserver(this);
         const indexOfStrategyToRemove = this._subscribedStrategies.indexOf(
            x.strategyId
         );
         if (indexOfStrategyToRemove >= 0) {
            this._subscribedStrategies.splice(indexOfStrategyToRemove, 1);
         }

         if (!isNullOrUndefined(x.dispositionId)) {
            toInner.push(x);
         } else {
            toMain.push(x);
         }
      });

      const strategyGrid = this._viewAdapter.strategiesGrid;
      const innersGrid = this._viewAdapter.innerStrategiesGrid;

      if (toMain.length > 0 && !isNullOrUndefined(strategyGrid)) {
         strategyGrid.api.applyTransaction({ remove: strategies });
      }

      if (toInner.length > 0 && !isNullOrUndefined(innersGrid)) {
         innersGrid.api.applyTransaction({ remove: strategies });
      }
   }

   //

   reset(): void {

      this._subscribedStrategies.length = 0;

      if (this._type === 'main') {
         
         const strategyGrid = this._viewAdapter.strategiesGrid;
         if (!isNullOrUndefined(strategyGrid)) {
            strategyGrid.api.setRowData([]);
         }

      } else if (this._type === 'inner') {

         const innerGrid = this._viewAdapter.innerStrategiesGrid;   
         if (!isNullOrUndefined(innerGrid)) {
            innerGrid.api.setRowData([]);
         }

      }    

   }

   //

   dispose(strategiesService: StrategiesService): void {
      this._subscribedStrategies.forEach(strId => {
         const strategy = strategiesService.getById(strId);
         if (isNullOrUndefined(strategy)) {
            return;
         }
         strategy.removeObserver(this);
      });
      this._subscribedStrategies.length = 0;
   }

   //

   refreshView(
      strategy: StrategyModel,
      notificationParams: ObserversNotificationParams
   ): void {

      const strategyGrid = this._viewAdapter.strategiesGrid;
      const innerStrategiesGrid = this._viewAdapter.innerStrategiesGrid;

      const isInnerStrategyRefreshing = !isNullOrUndefined(
         strategy.dispositionId
      );

      if (isInnerStrategyRefreshing) {
         if (!isNullOrUndefined(innerStrategiesGrid)) {
            if (this._viewAdapter.isInnerStrategiesGridVisible) {
               const strategyNode = innerStrategiesGrid.api.getRowNode(
                  strategy.strategyId
               );

               if (notificationParams.redrawRows) {
                  innerStrategiesGrid.api.redrawRows();
               } else {
                  if (!isNullOrUndefined(strategyNode)) {
                     innerStrategiesGrid.api.applyTransaction({ update: [strategy]});
                  }
               }
            }
         }
      } else {
         if (!isNullOrUndefined(strategyGrid)) {
            const strategyNode = strategyGrid.api.getRowNode(strategy.strategyId);

            if (!isNullOrUndefined(strategyNode)) {
               if (notificationParams.redrawRows) {
                  strategyGrid.api.redrawRows();
               } else {
                  strategyGrid.api.applyTransactionAsync({ update: [strategy] });
               }

               if (this._viewAdapter.isPositionsGridVisible) {
                  const positionsGrid = this._viewAdapter.positionsGrid;

                  if (!isNullOrUndefined(positionsGrid)) {
                     if (strategy.positions.count() > 0) {
                        strategy.positions.getPositions().forEach(pos => {
                           const rowNode = positionsGrid.api.getRowNode(pos.positionId);
                           if (rowNode) {
                              rowNode.setData(pos);
                           } else {
                              positionsGrid.api.setRowData(strategy.positions.getPositions());
                           }
                        });
                     } else {
                        positionsGrid.api.setRowData([]);
                     }
                  }
               }

               if (notificationParams.refreshCells) {
                  strategyGrid.api.refreshCells({ columns: ['description', 'status'], rowNodes: [strategyNode], force: true });
               }
            }
         }
      }
   }

   //
   
   contains(strategyId: string): boolean {
      return this._subscribedStrategies.indexOf(strategyId) >= 0;
   }
}
