import { AdjustmentSolutionExpiration } from "../adjustment-pricing-grid/model/AdjustmentSolutionExpiration";
import { CashFlowAdjustment } from "../adjustment-pricing-grid/model/CashFlowAdjustment";
import { CashFlowTrackingRecordDto } from "../shell-communication/shell-dto-protocol";
import { isValidNumber } from "../utils";

export class CashFlowTrackingRecordModel {

   constructor(records: CashFlowTrackingRecordDto[]) {
      this._records = records;
   }
 
   //
   private readonly _records: CashFlowTrackingRecordDto[];

   //
   get recordId(): number {
      return this._records[0].cashFlowTrackingRecordId;
   }

   //
   get adjustmentOperationId(): string {
      return this._records[0].adjustmentOperationId;
   }

   get comboId(): string {
      return this._records[0].comboId;
   }

   //
   get comboName(): string {
      return this._records[0].comboName;
   }

   //
   get transactionDate(): Date {
      return this._records[0].transactionDate;
   }

   //
   get expectedPremium(): number {
      const sum = this._records
         .map(x => x.expectedPremium)
         .reduce( (p, c) => {
            const prev = isValidNumber(p) ? p : 0;
            const curr = isValidNumber(c) ? c : 0;
            return prev + curr;
         }, 0);
      return sum;
   }

   //
   get factPremium(): number {
      const sum = this._records
         .map(x => x.factPremium)
         .reduce( (p, c) => {
            const prev = isValidNumber(p) ? p : 0;
            const curr = isValidNumber(c) ? c : 0;
            return prev + curr;
         }, 0);
      return sum;
   }

   //
   get slippage(): number {
      const sum = this._records
         .map(x => x.slippage)
         .reduce( (p, c) => {
            const prev = isValidNumber(p) ? p : 0;
            const curr = isValidNumber(c) ? c : 0;
            return prev + curr;
         }, 0);
      return sum;
   }

   //
   get underlyingPrice(): number {
      return this._records[this._records.length - 1].underlyingPrice;
   }

   //
   underlyingChange: number;
   
   //
   runningTotal: number;

   //
   get sessionComboPnL(): number {
      return this._records[this._records.length - 1].sessionComboPnL;
   }

   //
   get accumulatedComboPnL(): number {
      return this._records[this._records.length - 1].accumulatedComboPnL;
   }

   //
   get adjustmentCode(): string {
      return this._records[0].adjustmentCode;
   }

   //
   get expiration(): string {
      return this._records[0].expiration;
   }

   //
   get daysToExpiration(): number {
      return this._records[0].daysToExpiration;
   }

   //
   get expirationSeqNo(): number {
      return this._records[0].expirationSeqNo;
   }

   //
   get strategy(): string {
      return this._records[0].strategy;
   }

   get adjustmentSolutionSnapshot(): CashFlowAdjustment[] {
      const snapshot = this._records[0].adjustmentSolutionSnapshot;
      return JSON.parse(snapshot);
   }

   add(record: CashFlowTrackingRecordDto) {
      this._records.push(record);
      this.runningTotal += isValidNumber(record.factPremium) ? record.factPremium : 0;
   }

   getInnerRecords(): CashFlowTrackingRecordDto[] {
      return this._records;
   }
}