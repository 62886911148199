import { ChangeDetectorRef, Component } from '@angular/core';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { MessageBusService } from '../message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { ApgChecklistService } from '../adjustment-pricing-grid/popup/checklist/apg-checklist.service';
import { ChecklistStep } from "../adjustment-pricing-grid/popup/checklist/ChecklistStep";
import { ToastrService } from 'ngx-toastr';
import { CashFlowStrategy } from '../adjustment-control-panel/cash-flow-strategy';
import { AccessControlService } from '../access-control-service.class';
import { DetectMethodChanges, DxValueChanged, except, getAvailableCashFlowStrategies, isVoid } from '../utils';
import { CashFlowAdjustmentLogicalId } from '../adjustment-control-panel/CashFlowAdjustmentId';
import { ApplicationSettingsService } from '../app-settings/application-settings.service';
import { ItemDeletedEvent } from 'devextreme/ui/list';
import { ItemReorderedEvent } from 'devextreme/ui/list';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-checklist-editor',
   templateUrl: './checklist-editor.component.html',
   styleUrls: ['./checklist-editor.component.scss'],
})
export class ChecklistEditorComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _checklistService: ApgChecklistService,
      private readonly _toastr: ToastrService,
      private readonly _aclService: AccessControlService,
      private readonly _applicationSettings: ApplicationSettingsService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   //
   strategies: CashFlowStrategy[];

   //
   selectedStrategy: CashFlowStrategy;

   //
   adjustments: CashFlowAdjustmentLogicalId[];

   //
   selectedAdjustment: CashFlowAdjustmentLogicalId;

   //
   checklistSteps: ChecklistStep[] = [];

   //
   selectedChecklistSteps: ChecklistStep[] = [];

   //
   get canAddChecklistStep(): boolean {
      return !isVoid(this.selectedAdjustment)
         && !isVoid(this.selectedStrategy);
   }

   //
   get canRemoveChecklistStep(): boolean {
      return !isVoid(this.selectedChecklistSteps)
   }

   //
   get canSelectAdjustmentStrategy(): boolean {
      return !isVoid(this.selectedStrategy);
   }

   //
   get checklistStepSelected(): boolean {
      return !isVoid(this.selectedChecklistSteps);
   }

   //
   get selectedChecklistStep(): ChecklistStep {
      return this.selectedChecklistSteps[0];
   }

   //
   get hasChanges(): boolean {
      return this.checklistSteps.some(x => x.isDirty);
   }

   //
   @DetectMethodChanges({isAsync: true})
   async etsOnInit(): Promise<void> {
      this.isLoading = true;
      try {
         const strats = getAvailableCashFlowStrategies(this._aclService);
         except(strats, 'Calls & Puts');
         this.strategies = strats;
         this.adjustments = ['#1', '#2', '#2A', '#2B', '#2C', '#2D', '#3', '#3DO'];
      } finally {
         this.isLoading = false;
      }
   }

   //
   etsOnDestroy(): void {
      // throw new Error('Method not implemented.');
   }

   //
   etsAfterViewInit(): void {
      // throw new Error('Method not implemented.');
   }

   //
   @DetectMethodChanges({isAsync: true})
   async saveAllChanges() {

      const dirtyOnes = this.checklistSteps.filter(x => x.isDirty);

      if (isVoid(dirtyOnes)) {
         this._toastr.warning('No changes found in the listed checklist steps');
         return;
      }

      await this._checklistService.saveChecklistSteps(dirtyOnes);

      dirtyOnes.forEach(x => x.isDirty = false);
   }

   //
   @DetectMethodChanges()
   onStrategySelected(event: DxValueChanged<CashFlowStrategy>) {
      this.selectedAdjustment = null;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onAdjustmentSelected(event: DxValueChanged<CashFlowAdjustmentLogicalId>) {

      if (isVoid(this.selectedStrategy)) {
         return;
      }

      if (isVoid(this.selectedAdjustment)) {
         this.checklistSteps = [];
         return;
      }

      this.isLoading = true;

      try {
         const steps = await this._checklistService.getChecklistStepsFull(
            this.selectedStrategy,
            this.selectedAdjustment,
            true
         );

         this.checklistSteps = steps;

      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   addChecklistStep() {
      const step = new ChecklistStep();
      step.name = 'Step #' + this.checklistSteps.length;
      step.strategy = this.selectedStrategy;
      step.adjustment = this.selectedAdjustment;
      step.seqNo = this.checklistSteps.length;
      this.checklistSteps.push(step);
      this.selectedChecklistSteps[0] = step;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async removeChecklistStep(data: ChecklistStep) {

      if (isVoid(data)) {
         this._toastr.error('Step not selected')
         return;
      }

      this.isLoading = true;

       try {

         await this._checklistService.removeChecklistStep(data);

         const ix = this.checklistSteps.indexOf(data);

         if (ix >= 0) {
            this.checklistSteps.splice(ix, 1);
         }

       } finally {
         this.isLoading = false;
       }

   }

   //
   @DetectMethodChanges()
   onSelectedStepChanged(ev) {
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onChange(event: DxValueChanged<any>): Promise<void> {

      if (isVoid(event.event)) {
         return;
      }

      const step = this.selectedChecklistStep;

      if (step) {

         step.isDirty = true;

         if (step.isCleanCopy) {
            await this.onChecklistDraftStateChange(step, false);
         }
      }
   }

   async onChecklistDraftStateChange(step: ChecklistStep, state: boolean): Promise<void> {

      if (step.isCleanCopy === state) {
         return;
      }

      if (step.isDirty) {
         return;
      }

      if (isVoid(step)) {
         this._toastr.error('Step Not Selected!', 'Toggle Draft / Clean Copy');
         return;
      }

      this.isLoading = true;

      try {
         await this._checklistService.toggleChecklistDraftState(step);
         step.isCleanCopy = state;
      } catch(e) {
         this._toastr.error('"Toggle Draft / Clean Copy" operation completed with errors', 'Checklist Editor');
      } finally {
         this.isLoading =false;
      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onChecklistStepsReordered(event: ItemReorderedEvent<ChecklistStep>): Promise<void> {

      try {

         this.isLoading = true;

         await this._checklistService.withdrawChecklist(
            this.selectedStrategy,
            this.selectedAdjustment
         );

         this.checklistSteps.forEach( (item,ix) => {
            item.seqNo = ix;
            item.isCleanCopy = false;
            item.isDirty = true;
         });

      } catch(e) {
         this._toastr.error('Reordering operation completed with errors. Please reaload selected steps', 'Checklist Editor');
      }
      finally {
         this.isLoading = false;
      }

   }

   //
   onChecklistStepDeleted(event: ItemDeletedEvent<ChecklistStep>) {
      const data = event.itemData;
      this.removeChecklistStep(data);
   }

   //
   protected getState() {
      // throw new Error('Method not implemented.');
   }

   //
   protected setState(state: any) {
      // throw new Error('Method not implemented.');
   }
}
