import {Component, ViewChild} from '@angular/core';
import {PanelBaseComponent} from "../../panels/panel-base.component";
import {PackageComparisonComponent} from "../package-comparison.component";

@Component({
    selector: 'ets-package-comparison-panel',
    template: `
      <div [ngClass]="{'ets-hidden': !this.isActive}" class="panel-container">
        <ets-package-comparison></ets-package-comparison>
      </div>
    `,
    styles: [
        `
            .panel-container {
                width: 100%;
                height: 100%;
            }
        `
    ]
})
export class PackageComparisonPanelComponent extends PanelBaseComponent{
    @ViewChild(PackageComparisonComponent)
    packageComparisonCmp: PackageComparisonComponent;

    etsOnInit(): void {
        // throw new Error('Method not implemented.');
    }
    etsOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }
    etsAfterViewInit(): void {
        // throw new Error('Method not implemented.');
    }
    protected getState() {
        // throw new Error('Method not implemented.');
    }
    protected setState(state: any) {
        // throw new Error('Method not implemented.');
    }
    protected onIsActiveChanged() {
        this.packageComparisonCmp?.onPanelActiveStateChanged(this.isActive);
    }
}
