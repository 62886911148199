<div class="ets-tabs-panel" id="wsp_{{wspId}}">
  <div #panel id="ets-panel" (scroll)="onPanelScroll($event)">
    <ets-tab-header *ngFor="let tab of tabs"
                    [tab]="tab"
                    (tabClose)="onTabCloseRequest(tab)"
                    (click)="onTabClicked(tab)"
                    (headerChanged)="onTabHeaderChanged($event)"
                    (addedToPanel)="onTabAdded($event)"
                    (symbolLinkChanged)="this.onSymbolLinkChanged($event, tab)"
      ></ets-tab-header>
  </div>
  <div>
    <span class="ets-left-paddle ets-paddle" [hidden]="!leftPaddleVisible"
          (click)="onLeftPaddleClick()">
            <i class="fas fa-chevron-circle-left fa-2x"></i></span>
    <span class="ets-right-paddle ets-paddle" [hidden]="!rightPaddleVisible"
          (click)="onRightPaddleClick()">
            <i class="fas fa-chevron-circle-right fa-2x"></i></span>
  </div>
</div>