import {InjectionToken, NgModule} from '@angular/core';

import {PackageComparisonComponent} from './package-comparison.component';
import {PackageComparisonPanelComponent} from "./panel/package-comparison-panel.component";
import {DevExtremeModule} from "../devextreme.module";
import {CommonModule} from "@angular/common";
import {AdjustmentPricingGridModule} from "../adjustment-pricing-grid/adjustment-pricing-grid.module";
import {CashFlowPortfoliosService} from "../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {
    CashFlowStrategyTemplatesService
} from "../adjustment-pricing-grid/services/cashflow-strategy-templates.service";
import {SavedPositionsService} from "../adjustment-pricing-grid/services/saved-positions.service";
import {GenericConfirmationDialogModule} from "../generic-confirmation-dialog/generic-confirmation-dialog.module";
import {PackageComparisonSettingsComponent} from "./settings/package-comparison-settings.component";
import {PackageComparisonSettingsService} from "./settings/package-comparison-settings.service";
import {CopyOrdersToService} from "../adjustment-pricing-grid/services/copy-orders-to.service";
import {PositionsAfterStateService} from "../adjustment-pricing-grid/services/positions-after-state.service";
import {HedgePositionsService} from "../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {HedgingGridModule} from "../hedging-grid/hedging-grid.module";
import {DashboardFilterPopupComponent} from "./dashboard-filter-popup/dashboard-filter-popup.component";
import {PackageComparisonFiltersService} from "./package-comparison-filters.service";
import {PackageComparisonSortingService} from "./package-comparison-sorting.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";
import {EtsCommonModule} from "../common-services/common.module";
import {PackageMovingFloaterComponent} from "./package-moving-floater/package-moving-floater.component";
import {HedgeBoardPinnedService} from "./hedge-board/hedge-board-pinned.service";
import {OverrideAtmDialogModule} from "../override-atm-dialog/override-atm-dialog.module";
import {PackageNameEditorComponent} from "./name-editor/package-name-editor.component";


@NgModule({
    imports: [
        DevExtremeModule,
        CommonModule,
        GenericConfirmationDialogModule,
        AdjustmentPricingGridModule,
        HedgingGridModule,
        EtsCommonModule,
        OverrideAtmDialogModule
    ],
    exports: [
        PackageComparisonPanelComponent
    ],
    declarations: [
        PackageComparisonComponent,
        PackageComparisonPanelComponent,
        PackageComparisonSettingsComponent,
        DashboardFilterPopupComponent,
        PackageMovingFloaterComponent,
        PackageNameEditorComponent
    ],
    providers: [
        CashFlowPortfoliosService,
        CashFlowStrategyTemplatesService,
        SavedPositionsService,
        ApgDataService,
        PackageComparisonSettingsService,

        CopyOrdersToService,
        PositionsAfterStateService,
        HedgePositionsService,

        PackageComparisonFiltersService,
        PackageComparisonSortingService,
        HedgeBoardPinnedService
    ],
})
export class PackageComparisonModule {
}
