import { ProtocolQuery } from '../../../service-model/protocol-query.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';

export class GetSessionStrategySnapshots implements ProtocolQuery {
   constructor(
      public readonly strategyId: string,
      public readonly sessionId: string,
      public readonly ticker: string,
      public readonly historyStart: number,
      public readonly batchSize: number,
      public readonly portfolioId: string,
      public readonly comboId: string,
      public readonly comboGroupId: string
   ) {
   }

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSessionStrategySnapshots`;
   }
}
