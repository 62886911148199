export interface HedgePosition {
    ticker: string;
    qty: number;
    asset?: string;
    type?: 'Call' | 'Put';
    expiration?: string;
    strike?: number;
    price?: number;
    label?: string;
    groupId: string;
    color?: string;
    groupOrder?: number;
}

export function getIdentity(pos: HedgePosition): string {
    return `${pos.ticker}|${pos.qty}|${pos.groupId}`;
}