<dx-popup [width]="850" [height]="450" [visible]="visible" [resizeEnabled]="true" title="Edit Risk Manager Rule"
   (onHidden)="onHidden()">

   <div *dxTemplate="let data of 'content'" class="popup-template" #editor>

      <div class="configuration">

         <div class="section">
            <div class="header">Rule Info</div>
            
            <div class="setting">
               <div class="input">
                  <dx-text-box [(value)]="this.ruleName" [placeholder]="'Rule Name'"></dx-text-box>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-text-area [(value)]="this.ruleDescription" [placeholder]="'Optional Description'"></dx-text-area>
               </div>
            </div>


            <div class="setting">
               <div class="input">
                  <dx-check-box [text]="'Is Session Rule'" [(value)]="this.isSessionRule" 
                     [disabled]="this.mode === 'update'"
                  ></dx-check-box>
               </div>
            </div>


         </div>

         <div class="section">
            <div class="header">Rule Spec</div>
            <div class="setting">
               <div class="input">
                  <ets-symbol-picker (instrumentSelected)="this.selectedSecurity = $event.ticker" 
                     [disabled]="this.mode === 'update' || this.isShellTotal"
                  ></ets-symbol-picker>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-select-box [dataSource]="this.accountsList" 
                     [displayExpr]="'accountCode'"
                     [valueExpr]="'accountId'" 
                     [(value)]="this.selectedAccount" 
                     [showClearButton]="true" 
                     [placeholder]="'Account'"
                     [disabled]="this.mode === 'update' || this.isShellTotal">
                  </dx-select-box>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-select-box [dataSource]="this.terminalsList" [displayExpr]="'displayName'"
                     [valueExpr]="'terminalId'" 
                     [(value)]="this.selectedTerminal" 
                     [showClearButton]="true" 
                     [placeholder]="'Terminal'"
                     (onValueChanged)="this.onTerminalChanged()"
                     [disabled]="this.mode === 'update' || this.isShellTotal">
                  </dx-select-box>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-select-box [dataSource]="this.portfoliosList" 
                     [(value)]="this.selectedPortfolio"
                     [showClearButton]="true" 
                     [displayExpr]="'portfolioName'" 
                     [valueExpr]="'portfolioId'"
                     [placeholder]="'Portfolio'"
                     [disabled]="this.mode === 'update' || this.isShellTotal"
                     (onValueChanged)="this.onPortfolioChanged()"
                  ></dx-select-box>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-select-box [dataSource]="this.combosList" 
                     [(value)]="this.selectedCombo"
                     [showClearButton]="true" [displayExpr]="'comboName'" [valueExpr]="'comboId'"
                     [placeholder]="'Combo'"
                     [disabled]="this.mode === 'update' || this.isShellTotal"
                     (onValueChanged)="this.onComboChanged()"
                  ></dx-select-box>
               </div>
            </div>
            
            <div class="setting">
               <div class="input">
                  <dx-select-box [dataSource]="this.comboGroupsList" 
                     [(value)]="this.selectedComboGroup"
                     [showClearButton]="true" [displayExpr]="'comboGroupName'" [valueExpr]="'comboGroupId'"
                     [placeholder]="'ComboGroup'"
                     [disabled]="this.mode === 'update' || this.isShellTotal"
                  ></dx-select-box>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-select-box [dataSource]="this.positionClassList" [(value)]="this.selectedPositionClass"
                     [showClearButton]="true" [showClearButton]="true" [placeholder]="'Position Class'"
                     [disabled]="this.mode === 'update' || this.isShellTotal"
                  ></dx-select-box>
               </div>
            </div>

            <div class="setting">
               <div class="input">
                  <dx-check-box text="Shell Total" [(value)]="this.isShellTotal" [disabled]="this.mode === 'update'"
                                 (onValueChanged)="onShellTotalValueChanged($event)"
                  ></dx-check-box>
               </div>
            </div>

         </div>

         <div class="section to">
            <div class="header">Trading Objectives</div>
            <div class="dx-field">
               <div class="dx-field-label">NPO</div>
               <div class="dx-field-value">
                  <dx-number-box [(value)]="tradingObjectives.npo" mode="number" [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="dx-field">
               <div class="dx-field-label">Stop Loss</div>
               <div class="dx-field-value">
                  <dx-number-box [(value)]="tradingObjectives.stopLoss" mode="number" [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="dx-field">
               <div class="dx-field-label">% TS</div>
               <div class="dx-field-value">
                  <dx-number-box [(value)]="tradingObjectives.percentTS" mode="number" [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="dx-field">
               <div class="dx-field-label">$ TS</div>
               <div class="dx-field-value">
                  <dx-number-box [(value)]="tradingObjectives.dollarTS" mode="number" [min]="0"></dx-number-box>
               </div>
            </div>
         </div>
      </div>

      <div class="buttons">

         <dx-button width="100" (onClick)="onCreateClicked()" [disabled]="isLoading">
            <div *dxTemplate="let data of 'content'">
               <span class="dx-button-text" *ngIf="!isLoading">{{ this.mode | titlecase }} </span>
            </div>
         </dx-button>

         <dx-button width="100" (onClick)="onCreateAndCloseClicked()" [disabled]="isLoading">
            <div *dxTemplate="let data of 'content'">
               <span class="dx-button-text" *ngIf="!isLoading">{{ this.mode | titlecase }}
                  &amp; Close</span>
            </div>
         </dx-button>

         <dx-button width="100" text="Cancel" (onClick)="onCancelClicked()">
         </dx-button>
      </div>

      <dx-load-panel [visible]="isLoading" [position]="{ my: 'center', at: 'center', of: editor }"></dx-load-panel>

   </div>

</dx-popup>