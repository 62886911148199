import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RiskRuleModel } from './risk-rule-model';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: "has-violations-cell",
  template: `
    <i class="has-violations" *ngIf="data.hasUviewedViolations"></i>
    <i *ngIf="!data.hasUviewedViolations"></i>
  `,
  styles: [`
    .has-violations {
      width: 20px;
      height: 18px;
      margin-top: 3px;
      content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAExoAABMaAbBTVvgAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAABuklEQVQ4T6WSz0pCURDGJ7BsKxGFPYKCWNADiCsXbUR6BDctWujivoOrFgq5cNFLFBQY1qJtf+yKitYiWqRFIhRdneY7jqSZafaDDw4z833OOV6aRIlo7YqofE1UwVnLsyNBB7bXyxDOWp4NCdiU7brPuRxDOKOm7b/BRHOXROfV9XXmTseourGBLc/Q07HpkW22xczt01MuFApGOKOGno5NR41oUYy1+2iUQSAQMAKoSWANMzo+GQmzbtxufq9UTEgwGDQCqKEnz2Hp+O9I2Kps8PqYTJoAEAqFjPo8JhLY8rVKtKK28Uhg1l5eZuflRe3MsVjMqA96mMGs2n5GfjUgQ04jk1Frj3g8bjRII51GoAOP2keR5knZ7+eu46ith2VZRoN0Pz647PMh9Fjtw0hjS8StoyO1fJFKpYy+0zo8NJ8RvBrTQwoLotJdJKKjw+TzeaOfqItHblaSj31e48xVd29cLn4rFnVsGNu2uTimBw+8yDBhRaIl2a75sLOjI6P45V2hccArgY0LycJ2e7ceDztPT9oe5bcrA3iRgSy8X7seDnNzf/9fQgayzIcs6oj6/9iskgzKfgJuUYgc+wzVPgAAAABJRU5ErkJggg==)
    }
  `]
})
export class UnviewedViolationsCellRendererComponent implements ICellRendererAngularComp {
  public data: RiskRuleModel;

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
  }

  refresh(): boolean {
    return false;
  }
}
