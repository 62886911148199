import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UserDto} from "../../../authentication/dtos/auth-result-dto.inteface";
import {SessionService} from "../../../authentication/session-service.service";
import {DetectMethodChanges, isVoid} from "../../../utils";
import {SelectionChangedEvent} from "devextreme/ui/list";
import {ChecklistEditorComponent} from "../../checklist-editor.component";
import {ChecklistStep} from "../../../adjustment-pricing-grid/popup/checklist/ChecklistStep";

@Component({
    selector: 'ets-checklist-users-condition',
    template: `
      <div class="input">
        <dx-drop-down-box
          (onClosed)="this.onClosed()"
          (onOpened)="this.onOpened()"
          [acceptCustomValue]="true"
          [disabled]="this.disabled"
          [value]="text"
          contentTemplate="content"
          fieldTemplate="field"
        >
          <div *dxTemplate="let data of 'field'">
            <dx-text-box
              [readOnly]="true"
              [value]="this.text"
            >
            </dx-text-box>
          </div>
          
          <div *dxTemplate="let data of 'content'">
            <dx-list
              (onSelectionChanged)="this.onSelectionChanged($event)"
              [(selectedItems)]="this.selectedUsers"
              [displayExpr]="'userName'"
              [items]="this.userList"
              [selectionMode]="'multiple'"
              [showSelectionControls]="true"
            >
            </dx-list>
          </div>
        </dx-drop-down-box>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChecklistUsersConditionComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _sessionData: SessionService
    ) {
    }

    private _oldValue: string;

    userList: UserDto[] = [];

    selectedUsers: UserDto[] = [];

    @Input() comp: ChecklistEditorComponent;

    get text() {
        if (this.selectedUsers.length === 0) {
            return undefined;
        }

        if (this.selectedUsers.length === 1) {
            return this.selectedUsers[0].userName;
        }

        return `${this.selectedUsers.length} users`;
    }

    get disabled(): boolean {
        return isVoid(this.step);
    }

    private _step: ChecklistStep;
    get step(): ChecklistStep {
        return this._step;
    }

    @DetectMethodChanges()
    @Input()
    set step(value: ChecklistStep) {
        this._step = value;

        if (isVoid(value)) {
            this.reset();
            return;
        }

        if (isVoid(value.userCondition)) {
            this.reset();
            return;
        }

        const parts = value.userCondition.split('|');

        parts.forEach(x => {
            const user = this.userList.find(y => y.userId === x);
            if (user) {
                this.selectedUsers.push(user);
            }
        });
    }

    ngOnInit() {
        this.userList = this._sessionData.sessionData.users.slice();
    }

    @DetectMethodChanges()
    private reset() {
        this.selectedUsers.length = 0;
    }

    onOpened() {
        this._oldValue = this.selectedUsers.map(x => x.userId).join('|');
    }

    @DetectMethodChanges()
    onClosed() {
        const users = this.selectedUsers.map(x => x.userId).join('|');
        this.step.userCondition = users;
        if (this._oldValue === users) {
            return;
        }
        this.step.isDirty = true;
        this.comp.onChange({});
    }

    onSelectionChanged($event: SelectionChangedEvent<UserDto>) {

    }
}