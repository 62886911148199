import { GridOptions, RowNode, GetContextMenuItemsParams, ColDef, ValueFormatterParams, CellClassParams, ValueGetterParams } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate, getMasterSymbolColumn, defaultPriceCellFormatter, liveQuoteFormatter } from '../ag-grid-contrib';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { PortfolioItemDto } from '../shell-communication/shell-dto-protocol';
import { StrategyState } from '../strategies/strategy-state.enum';
import { isCashSettledOptionTicker, isNullOrUndefined } from '../utils';
import { PortfolioBlock } from './PortfolioBlock';

export function getPortfolioBlockGridOptions(this: PortfolioBlock, size: 'small' | 'large'): GridOptions {

   const gridOptions: GridOptions = {
      debug: true,

      rowData: [],

      defaultColDef: centeredColumnDef,
      
      columnDefs: getColumnDefs(this, size),

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,
      
      immutableData: true,

      rowClass: 'ets-text-centered',
      
      rowSelection: 'multiple',

      rowGroupPanelShow: 'never',

      groupUseEntireRow: false,

      suppressAggFuncInHeader: true,

      // groupIncludeTotalFooter: true,

      // groupIncludeFooter: true,

      tooltipShowDelay: 1000,
      
      onGridReady: (args) => {
         if (size === 'large') {
            this.onGridReady(args);
         }
         
         if (size === 'small') {
            this.onSmallGridReady(args);
         }
      },

      getRowNodeId: (data: PortfolioItemDto) => {
         return data.portfolioItemId;
      },

      postSort: (rowNodes: RowNode[]) => {

         const nextInsertPos = rowNodes.length;
         for (let i = rowNodes.length - 1; i >= 0; i--) {
             const pfItem: PortfolioItemDto = rowNodes[i].data;
             if (pfItem && pfItem.isHedgingItem) {
                if (i !== nextInsertPos) {
                   rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                }
             }
         }
      },

      onFirstDataRendered: (args) => {
         args.columnApi.autoSizeAllColumns();
      },
      
      getContextMenuItems: (args: GetContextMenuItemsParams) => {
      
         if (!args.node) {
            return;
         }

         if (args.node.group) {
            return;
         }

         const pfItem = args.node.data as PortfolioItemDto;
         
         if (!pfItem) {
            return;
         }

         const menu = [];

         menu.push(
            'separator',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll',
         );

         return menu;
      },
   };

   return gridOptions;
}


function getColumnDefs(comp: PortfolioBlock, size: 'small' | 'large'): Partial<ColDef>[] {

   const colDefs: ColDef[] = [];

   // const symbolCol = getMasterSymbolColumn('ticker', comp.messageBus, comp.timestampsService, comp.unsubscriber, () => comp.gridApi);
   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: 'tickerDisplayName'
   };

   const itemTypeCol: ColDef = {
      headerName: 'Type',
      field: 'itemType',
      sort: 'asc',
      sortIndex: 2,
      valueFormatter: (args: ValueFormatterParams) => {
         if (!args.data) {
            return args.value;
         }

         if (args.value > 0) {
            let optType = PortfolioItemType[args.value];
            
            if (args.value >= 3) {
               if (isCashSettledOptionTicker(args.data.ticker)) {
                  optType += ' (E)';
               }
            } 

            return optType;
            
         }

         const data = args.data as PortfolioItemDto;

         if (!data || data.itemType !== PortfolioItemType.Strategy) {
            return PortfolioItemType[args.value];
         }

         return data.algoName;
      },
      cellStyle: (args: CellClassParams) => {
         
         const pfItem = args.data as PortfolioItemDto;

         if (isNullOrUndefined(pfItem)) {
            return undefined;
         }
      


         let state = pfItem.strategyState;

         if (isNullOrUndefined(state)) {
            const strategy = comp.strategyService.getById(pfItem.portfolioItemId);

            if (isNullOrUndefined(strategy)) {
               return undefined;
            }

            state = strategy.state;
         }


         const enabled = (state & StrategyState.Enabled) === state;

         const style = { 'border-bottom': null };

         if (enabled) {
            style['border-bottom'] = '1px solid limegreen';
         } else {
            style['border-bottom'] = '1px solid red';
         }
         
         return style;
      },
      minWidth: 100
   };

   const positionCol: ColDef = {
      headerName: 'Position',
      field: 'netPosition',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.netPosition;
      }
   };

   const avgPxCol: ColDef = {
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data || isNaN(params.data.avgPx)) {
            return null;
         }
         return params.data.avgPx;
      },
      valueFormatter: defaultPriceCellFormatter,
   };

   const accountNameCol: ColDef = {
      headerName: 'Account',
      field: 'accountCode',
      minWidth: 180,
      sort: 'asc',
   };

   const openPrice: ColDef = {
      headerName: 'Open Px.',
      field: 'openPrice',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data || isNaN(params.data.openPrice)) {
            return null;
         }
         return params.data.openPrice;
      },
      valueFormatter: defaultPriceCellFormatter
   };

   const liveQuoteCol: ColDef = {
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter,
   };

   colDefs.push(symbolCol);
   // if (size === 'large') {
   //    colDefs.push(itemTypeCol);
   // }
   colDefs.push(positionCol);
   if (size === 'large') {
      colDefs.push(avgPxCol);
      colDefs.push(openPrice);
      colDefs.push(accountNameCol);
   }
   colDefs.push(liveQuoteCol);

   return colDefs;
}
