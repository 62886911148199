import { formatNumber } from '@angular/common';
import { CellClassParams, ColDef, GetContextMenuItemsParams, GridOptions, GridReadyEvent, RowClickedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { centeredColumnDef, defaultMoneyCellDefinition } from 'projects/shared-components/ag-grid-contrib';
import { environment } from 'projects/shared-components/environments/environment';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { BucketSummaryDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { isNullOrUndefined } from 'util';
import { ManualPositionsComponent } from './manual-positions.component';

const IS_DASHBOARD = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

export function getTerminalsGridOptions(this: ManualPositionsComponent): GridOptions {
   const body = document.querySelector('body') as HTMLElement;

   return {

      rowData: [],

      columnDefs: getColumnDefs(),

      defaultColDef: centeredColumnDef,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      suppressAggFuncInHeader: true,

      suppressCellSelection: true,

      popupParent: body,

      groupUseEntireRow: false,

      tooltipShowDelay: 1000,

      autoGroupColumnDef: {
         headerName: 'Terminal',
         field: 'terminalName',
         minWidth: 190,
         flex: 1,
         sort: 'asc',
         tooltipField: 'terminalName',
         cellRendererParams: {
            suppressCount: true,
         },
         cellStyle: (args: CellClassParams) => {
            if (isNullOrUndefined(args.data)) {
               return undefined;
            }
            const style = { 'text-align': 'left', color: undefined };
            
            if (args.data.isArchived) {
               style.color = 'yellow';
            }
            return style;
         },
         valueGetter: (params: ValueGetterParams) => {
            if (!params.node.group) {
               return params.data.terminalName;
            }
            return params.data.shellName;
         }
      },

      getRowNodeId: (row: BucketSummaryDto) => row.terminalId,

      onGridReady: (args: GridReadyEvent) => this.onTerminalsGridReady(args),

      getContextMenuItems: (args: GetContextMenuItemsParams) => {
         return [
            'autoSizeAll',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
         ];
      },

      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node) {
            
            this.clearTerminalSelection();
            
            return;
         }

         this.onTerminalSelected(args.node);
      }
   };
}

function getColumnDefs(): ColDef[] {
   const colDefs: ColDef[] = [];

   if (IS_DASHBOARD) {
      const clientNameCol: ColDef = {
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true,
         rowGroup: true,
         rowGroupIndex: 0,
         hide: true
      };

      colDefs.push(clientNameCol);
   }

   const shellNameCol: ColDef = {
      headerName: 'Shell',
      field: 'shellName',
      enableRowGroup: true,
      rowGroup: true,
      rowGroupIndex: 0,
      hide: true
   };

   const sessionPnLCol: ColDef = Object.assign({
      headerName: 'Sess.P&L',
      field: 'sessionTotalPnL',
      minWidth: 117,
      aggFunc: 'sum',
   }, defaultMoneyCellDefinition);

   const accumulatedPnLCol: ColDef = Object.assign({
      headerName: 'Acc.P&L',
      field: 'accumulatedTotalPnL',
      minWidth: 117,
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition);


   const totalDeltaCol: ColDef = {
      headerName: 'T.Delta',
      field: 'totalDelta',
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalGammaCol: ColDef = {
      headerName: 'T. Gamma',
      field: 'totalGamma',
      aggFunc: 'sum',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalVegaCol: ColDef = {
      headerName: 'T. Vega',
      field: 'totalVega',
      aggFunc: 'sum',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalThetaCol: ColDef = {
      headerName: 'T. Theta',
      field: 'totalTheta',
      aggFunc: 'sum',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   colDefs.push(shellNameCol);
   colDefs.push(sessionPnLCol);
   colDefs.push(accumulatedPnLCol);
   colDefs.push(totalDeltaCol);
   colDefs.push(totalGammaCol);
   colDefs.push(totalVegaCol);
   colDefs.push(totalThetaCol);

   return colDefs;
}
