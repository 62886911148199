<dx-popup class="popup"
          [width]="400"
          [height]="215"
          [showTitle]="true"
          [visible]="visible"
          title="Please, confirm!"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          (onHidden)="visible = false"
          [dragEnabled]="false">
  <div *dxTemplate="let data of 'content'">
    <div class="template-container">
      <div class="message">
        <table class="aggregation-table">
          <caption>{{title}}</caption>
          <thead>
          <tr>
            <th>Terminal</th>
            <th>Asset</th>
            <th>Account</th>
            <th>Position Class</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ aggObj.terminalName || '*' }}</td>
            <td>{{ aggObj.tickerFriendlyName || '*' }}</td>
            <td>{{ aggObj.accountCode || '*' }}</td>
            <td>{{ aggObj.positionClass || '*' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="action-buttons">
        <dx-button text="YES"
                   type="success"
                   class="button confirm"
                   (onClick)="confirm()"
        ></dx-button>
        <dx-button text="NO"
                   type="default"
                   class="button cancel"
                   (onClick)="cancel()"
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>