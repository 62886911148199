import { GetContextMenuItemsParams, GridOptions, RowNode } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { ShellMessagesComponent } from './shell-messages.component';
import { centeredColumnDef } from 'projects/shared-components/ag-grid-contrib';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { TimestampsService } from '../timestamps.service';
import { SessionMessagesGrouRowInnerRendererComponent } from './group-row/shell-messages-group-row-renderer.component';
import { ShellMessageDto } from '../shell-communication/shell-dto-protocol';
import { EtsDateGridFilterComponent } from './ets-date-filter.component';
import { NoHtmlCellRendererComponent } from './no-html-cell-renderer.component';

export function getSessionMessagesGridModel(
   this: ShellMessagesComponent,
   timestampsService: TimestampsService
) {

   const columns: Partial<AgGridColumn>[] = setupColumns(timestampsService);

   return {
      rowClass: 'ets-text-centered',

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      rowGroupPanelShow: 'never',

      groupUseEntireRow: true,

      rowSelection: 'multiple',

      immutableData: true,

      suppressDragLeaveHidesColumns: true,

      suppressMakeColumnVisibleAfterUnGroup: false,

      suppressAggFuncInHeader: true,

      suppressCellSelection: true,

      frameworkComponents: {
         groupRowRenderer: SessionMessagesGrouRowInnerRendererComponent,
         etsDateFilter: EtsDateGridFilterComponent,
         noHtmlCellRenderer: NoHtmlCellRendererComponent
      },

      groupRowRendererParams: {
         innerRenderer: 'groupRowRenderer',
         suppressCount: true
      },

      getRowNodeId: (rowData: ShellMessageDto) => {
         return rowData.shellMessageId + '';
      },

      getRowStyle: (args) => {
         const data = args.data;
         if (!data) {
            return null;
         }
         return !data.isRead ? { 'font-weight': 'bold' } : { 'font-weight': 'normal' };
      },

      onRowDoubleClicked: (args) => {
         this.onRowDoubleClicked(args);
      },

      onRowClicked: (args) => {
         this.onRowClicked(args);
      },

      onGridReady: (args) => this.onGridReady(args),

      onGridSizeChanged: (args) => this.onGridSizeChanged(args),

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            // {
            //    name: 'Load Fake Messages',
            //    action: () => this.loadFakeMessages()
            // },
            // {
            //    name: 'Add Sticky',
            //    action: () => this.addFakeMessage('sticky'),
            // },
            // {
            //    name: 'Add Regular',
            //    action: () => this.addFakeMessage('regular'),
            // },
            // {
            //    name: 'Add Important',
            //    action: () => this.addFakeMessage('important'),
            // },
            // {
            //    name: 'Add With No Overlay',
            //    action: () => this.addFakeMessage('important', true),
            // },
            // {
            //    name: 'Add Sticky With No Overlay',
            //    action: () => this.addFakeMessage('sticky', true),
            // },
            // 'separator',
            {
               name: 'Ack Selected Messages',
               action: () => this.ackSelectedMessages(),
               disabled: !this.anySelected
            },
            {
               name: 'Ack All Messages',
               action: () => this.ackAllMessages(),
               disabled: !this.anyMessages
            },
            {
               name: 'Filter by this row',
               action: () => this.applyEntryFilter(params.node.data),
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}

function setupColumns(timestampsService: TimestampsService): Partial<AgGridColumn>[] {
   const cols: Partial<AgGridColumn>[] = [];

   if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      cols.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true
      });

      cols.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true
      });
   }

   cols.push(
      {
         headerName: 'Timestamp',
         field: 'timestamp',
         sort: 'desc',
         filter: 'etsDateFilter',
         valueFormatter(params: ValueFormatterParams) {
            const msg: ShellMessageDto = params.data;
            const frmtTime = timestampsService.getDefaultFormattedDateTime(msg.timestamp);
            return msg.timestamp
               ? frmtTime
               : '';
         },
         width: 160
      },
      {
         headerName: 'Domain',
         field: 'domain',
         width: 100,
         filter: 'agSetColumnFilter',
      },

      {
         headerName: 'Source',
         field: 'source',
         width: 130,
         filter: 'agSetColumnFilter',
      },

      {
         headerName: 'Message',
         field: 'message',
         headerComponentParams: {},
         cellStyle: { 'text-align': 'left' },
         flex: 1,
         filter: 'agTextColumnFilter',
         cellRenderer: 'noHtmlCellRenderer'
      },

      {
         headerName: 'Important',
         field: 'important',
         rowGroup: true,
         hide: true
      },

      {
         headerName: 'Is Read',
         field: 'isRead',
         filter: 'agSetColumnFilter',
         hide: true
      },

      {
         headerName: 'Tags',
         field: 'tags',
         filter: 'agTextColumnFilter',
         hide: true
      },
   );

   return cols;
}
