import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { StrategyIssuesBrowserComponent } from 'projects/shared-components/strategy-issues/strategy-issues-browser.component';
import { InterestBlockViewModel } from './interest-overview-block.model';

@Component({
   selector: 'ets-interest-overview-block',
   templateUrl: 'interest-overview-block.component.html',
   styleUrls: ['interest-overview-block.component.scss', '../table-styles.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class InterestOverviewBlockComponent implements OnInit, AfterViewInit {

   constructor(private _changeDetector: ChangeDetectorRef) { }

   @ViewChild(StrategyIssuesBrowserComponent) strategyIssues: StrategyIssuesBrowserComponent;

   private _viewModel: InterestBlockViewModel;
   get viewModel(): InterestBlockViewModel {
      return this._viewModel;
   }
   
   @Input() set viewModel(v: InterestBlockViewModel) {
      this._viewModel = v;
      this._changeDetector.detectChanges();
   }

   @Input() mode: string;
   

   ngOnInit() { }

   ngAfterViewInit(): void {
      this._changeDetector.detach();
   }

   showStrategyIssues() {
      const strategy = this.viewModel.strategy;

      if (this.strategyIssues) {
         this.strategyIssues.show(strategy);
      }
   }
}
