import { Logger } from './logger.interface';
import { TelemetryService } from '../telemetry/telemetry.service';


export class ConsoleLogger implements Logger {
  constructor(private readonly name: string, private readonly _telemetryService: TelemetryService) {}

  debug(message: string, data?: object): void {
    this._log(console.debug, message, data);
  }

  info(message: string, data?: object): void {
    this._log(console.info, message, data);
  }

  warn(message: string, data?: object): void {
    this._log(console.warn, message, data);
  }

  error(message: string, data?: object): void {
    this._log(console.error, message, data);
  }

  private _log(logger: (...args) => void, message: string, data?: object) {
    message = `[${this.name}] ${message}`; 
    if (data) {
      logger(message, data);
      this._telemetryService.log(message, data);
    } else {
      logger(message);
      this._telemetryService.log(message);
    }
  }
}
