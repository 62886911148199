import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SideboxLegDescriptor } from 'projects/shared-components/automation-cp/model/SideboxLegDescriptor';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-short-option-sidebox',
   templateUrl: './short-option-sidebox.component.html',
   styleUrls: ['./short-option-sidebox.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortOptionSideboxComponent implements OnInit {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService
   ) { }

   private _comboGroupId: string;

   //
   leg: SideboxLegDescriptor;

   //
   ngOnInit(): void {
      this._messageBus
         .of<PositionDto[]>('PositionDto')
         .subscribe(x => this.onPositionDto(x.payload));

      this._messageBus
         .of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .subscribe(x => this.onPortfolioItemAdded(x.payload));

      this._messageBus
         .of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .subscribe(x => this.onPortfolioItemRemoved(x.payload));
   }

   //
   setComboGroupId(comboGroupId: string): void {
      this._comboGroupId = comboGroupId;
   }

   //
   @DetectMethodChanges()
   setData(comboGroupId: string, leg: PortfolioItemDto) {
      
      this._comboGroupId = comboGroupId;

      if (isVoid(leg)) {
         return;
      }

      this.leg = new SideboxLegDescriptor(leg, 'ShortOption');
   }

   //
   private onPositionDto(payload: PositionDto[]): void {
      
      let updated = false;

      const filtered = payload
         .filter(x => !isVoid(x.comboGroupId))
         .filter(x => x.comboGroupId === this._comboGroupId);

      if (filtered.length === 0) {
         return;
      }

      if (!this.leg) {

         const leg = filtered.find(x => x.netPosition > 0);
         
         if (leg) {
            
            this.leg = new SideboxLegDescriptor(leg, 'ShortOption');
            updated = true;

         }

      } else {

         updated = this.leg.onPositionUpdate(filtered) || updated;

      }

      if (updated) {
         this._changeDetector.detectChanges();
      }
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {

      if (!msg.portfolioItem) {
         return;
      }

      if (!msg.portfolioItem.ticker.startsWith('@')) {
         return;
      }

      if (msg.portfolioItem.comboGroupId !== this._comboGroupId) {
         return;
      }

      this.leg = null;

      if (msg.portfolioItem.netPosition >= 0) {
         return;
      }

      this.leg = new SideboxLegDescriptor(msg.portfolioItem, 'ShortOption');      
   }
   
   //
   @DetectMethodChanges()
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {

      if (!this.leg) {
         return;
      }

      if (!this._comboGroupId) {
         return;
      }

      if (msg.portfolioItemId !== this.leg.legId) {
         return;
      }

      this.leg = null;
   }   

   @DetectMethodChanges()
   reset() {
      this.leg = undefined;
      this._comboGroupId = undefined;
   }

}
