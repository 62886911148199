<dx-popup
  width="80%"
  height="50%"
  [showTitle]="true"
  title="Strategy Commander"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let data of 'content'" class="alerts-container">
    <ag-grid-angular
      class="ag-theme-balham-dark"
      [gridOptions]="commanderGridModel"
    ></ag-grid-angular>
  </div>
</dx-popup>
<ets-strategy-commander-rule-editor></ets-strategy-commander-rule-editor>