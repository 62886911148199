import { 
  GetNumberOfLinkedRulesByStrategy 
} from '../shell-communication/operations/strategies/get-number-of-linked-rules-by-strategy.class';
import { KeyValueDto } from '../shell-communication/dtos/key-value-dto.interface';
import { GetStrategies } from '../shell-communication/operations/strategies/get-strategies.class';
import { StrategyDto } from '../shell-communication/dtos/strategy-dto.class';

export abstract class StrategiesServiceBackendService {
  abstract getStrategies(qry: GetStrategies, shellId?: string): Promise<StrategyDto[]>;
  abstract getNumberOfLinkedRulesByStrategy(qry: GetNumberOfLinkedRulesByStrategy, shellId?: string): Promise<KeyValueDto[]>;
}
