import {PackageComparisonModel} from "./PackageComparisonModel";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    ICashFlowAdjustmentSettingsTemplate
} from "../../adjustment-pricing-grid/model/ICashFlowAdjustmentSettingsTemplate";

import {PricingGridStrategyDescriptor} from "../../options-pricing-grid/model/pricing-grid-strategy.descriptor";
import {isVoid, makeFullExpirationDate, makeGuiFriendlyExpirationDateDte} from "../../utils";
import * as Enumerable from "linq";

export class DashboardComparisonModel {
    constructor(
        public readonly templatePackageId: string,
        public readonly models: PackageComparisonModel[]
    ) {
        this.startDate = models[0].expirationList[0]?.date;
    }

    isPinned = false;

    readonly startDate: string;

    get portfolio(): ApgPortfolio {
        return this.models[0]?.portfolio;
    }

    get shortOptionStrike() : string {
        return this.models[0].shortOptionStrike;
    }

    get template(): ICashFlowAdjustmentSettingsTemplate {
        return this.models[0]?.template;
    }

    get adjustment(): string {
        const call = this.models[0]?.adjustmentOne;
        const put = this.models[0]?.adjustmentTwo;

        return `${call?.name}(C) + ${put?.name}(P)`;
    }

    get expirations(): any[] {
        return this.models.map(x => x.expiration);
    }

    get callHedge(): PricingGridStrategyDescriptor {
        return this.models[0]?.callHedge;
    }

    get callHedgeOffset(): number {
        return this.models[0]?.callHedgeOffset;
    }

    get putHedge(): PricingGridStrategyDescriptor {
        return this.models[0]?.putHedge;
    }

    get putHedgeOffset(): number {
        return this.models[0]?.putHedgeOffset;
    }

    get total(): number {
        const total = this.models.map(x => x.getPackagePrice())
            .reduce((a,b) => a + b, 0);

        return total;
    }

    get uniqueId(): string {
        return this.models[0]?.getUniqueId();
    }

    get delta() : number {
        return this.models[0].getLatestSettings()?.delta || 0;
    }
}