// tslint:disable:no-bitwise
export enum OrderStatus {
  None = 0,
  Created = 1 << 0, // 1
  PendingNew = 1 << 1, // 2
  New = 1 << 2, // 4
  PartiallyFilled = 1 << 3, // 8
  Filled = 1 << 4, // 16
  Replaced = 1 << 5, // 32
  Canceled = 1 << 6, // 64
  Rejected = 1 << 7, // 128
  PendingCancel = 1 << 8, // 256
  PendingReplace = 1 << 9, // 512
  Withdrawn = 1 << 10, // 1024
  DoneForDay = 1 << 11, // 2048
  Expired = 1 << 12, // 4096
  Unknown = 1 << 13, // 8192
  Calculated = 1 << 14,

  Undefined = None | Unknown,
  ActionPending = PendingReplace | PendingCancel,
  Working = Created | PendingNew | New | PartiallyFilled | Replaced | PendingCancel | PendingReplace,
  Touched = Filled | PartiallyFilled,
  Completed = Canceled | Rejected | DoneForDay | Expired | Filled | Withdrawn | None | Unknown
}
