import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'floating-cell',
  template: `
    <div class="block">
      <span class="group-title">{{title}}</span>
    </div>
  `,
  styles: [
      `
      .block {
        display: inline-block;
        width: 100%;
        text-align: left;
      }

      .group-title {
        color: yellow;
      }
    `
  ]
})
export class StrategySessionHistoryAggregatedRowHeaderRendererComponent
  implements ICellRendererAngularComp {
  public constructor() {
  }

  public title: any;

  public agInit(params: any): void {
    const node = params.node;
    if (node) {
      this.title = node.key;
    }
  }

  public refresh(): boolean {
    return false;
  }
}
