<div class="root" #root id="root">

  <div class="list" *ngIf="this.isPackagesView">
    
    <ng-container *ngIf="this.packageComparisons.length === 0">
      <ng-container *ngTemplateOutlet="noPackages"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.packageComparisons.length !== 0">
      <dx-scroll-view>
        <ng-container *ngFor="let pkg of this.filteredPackages; index as i">
          <ng-container *ngTemplateOutlet="pkgCmprsnMdl; context: {pkg:pkg, ix: i}"></ng-container>
        </ng-container>
      </dx-scroll-view>
    </ng-container>
    
  </div>
  
  <div class="dashboard" [class.changes]="this.canApply()"  *ngIf="this.isDashboard">
    <ng-container *ngIf="this.dashboardPackages.length === 0">
      <ng-container *ngTemplateOutlet="dashboardEmpty"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.dashboardPackages.length !== 0">
      <ng-container *ngTemplateOutlet="dashboard"></ng-container>
    </ng-container>
  </div>
  
  <div class="hedgeboard"  *ngIf="this.isHedgeBoard" [class.changes]="this.canApply()">
    <ng-container *ngIf="this.hedgeBoardPackages.length === 0">
      <ng-container *ngTemplateOutlet="hedgeBoardEmpty"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.hedgeBoardPackages.length !== 0">
      <ng-container *ngTemplateOutlet="hedgeBoard"></ng-container>
    </ng-container>
  </div>

  <div class="controls">
    <div class="block left">
      <div style="width: 100px">
        <dx-button
          (onClick)="this.onSettingsClicked()"
          height="100%"
          icon="preferences"
          text="Settings"
          width="100%"
        ></dx-button>
      </div>
    
      <div *ngIf="this.showClientsSelector" style="width: 195px;">
        <dx-select-box
          width="100%"
          height="100%"
          placeholder="Change User Context"
          [items]="this.clientList"
          [(value)]="this.selectedClient"
          displayExpr="userName"
          [showClearButton]="true"
          (onValueChanged)="this.onClientContextChanged($event)"
        ></dx-select-box>
      </div>
      
      <div>
        <dx-button text="Clear Filters" height="100%" (onClick)="this.clearFilters()"></dx-button>
      </div>

      <div>
        <dx-button text="Clear Sorting" height="100%" (onClick)="this.clearSorting()"></dx-button>
      </div>
      
      <div *ngIf="this.canShowHedgesDashboard()">
        <dx-button text="Hedges Dashboard" height="100%" [disabled]="!this.canLoadHedgeBoard()" (onClick)="this.onHedgeBoardViewClicked()">
        </dx-button>
      </div>
      
      <div class="view-switch-btn" [class.dashboard-view]="this.isDashboardEmpty">
        <dx-button [text]="this.viewSwitchButtonText"
                   [disabled]="!this.canSwitchView()"
                   height="100%"
                   (onClick)="this.onChangeViewClick()"
        >
        </dx-button>
      </div>
      
    </div>
    
    <div class="block center">
      <div class="btn">
        <dx-button
          (onClick)="this.onApplyClicked()"
          text="Apply"
          type="default"
          [disabled]="!this.canApply()"
          width="100px" height="100%"
        ></dx-button>
      </div>
      
      <div class="btn live">
        <div class="label">Live</div>
        <div class="value">
          <dx-check-box [(value)]="this.isLiveMode" (onValueChanged)="this.onLiveModeChanged($event)">
          </dx-check-box>
        </div>
      </div>
      
      <div class="btn floater" *ngIf="this.canShowFloater()" [class.has-distance]="(this.hasFloaterDistance | async)">
        <dx-button icon="sorted" height="100%" width="100%" (onClick)="this.showFloater()"></dx-button>
      </div>
      
    </div>
    
    <div class="block right">
      
      <div class="num-of-expirations">
        <div class="label">EXP</div>
        <div class="value">
          <dx-select-box height="100%"
                         width="100%"
                         [items]="[5,6,7,8,9,10]"
                         [(value)]="this.dashboardExpirations"
                         (onValueChanged)="this.onDashboardExpirationsChanged()"
          >
          </dx-select-box>
        </div>
      </div>
      
      
      <div class="remove-all">
        <dx-button text="Remove Packages" height="100%" width="100%" (onClick)="this.removeAllPackages()">
        </dx-button>
      </div>
      
      <div class="atm">
        <div class="label">ATM</div>
        <div class="value">
          <dx-number-box
            width="100%" height="100%"
            [showClearButton]="true"
            [(value)]="this.overrideAtm"
            [inputAttr]="{style: this.overrideAtmStyle}"
            (onValueChanged)="this.onOverrideAtmChanged($event)"
          ></dx-number-box>
        </div>
      </div>
      <div class="add">
        <dx-button icon="plus"
                   text="Add"
                   (onClick)="this.addPackageComparison()"
                   width="100px"
                   height="100%"
                   [disabled]="this.isDashboard"
        ></dx-button>
      </div>
    </div>
  </div>
</div>

<dx-load-panel
  [visible]="this.isLoading"
  [position]="{my: 'center', at: 'center', of: '#root'}"
></dx-load-panel>


<!--
  HEDGEBOARD VIEW
-->

<ng-template #hedgeBoard>
  <dx-scroll-view [direction]="'both'">
    
    <div class="groups">
      <fieldset class="group" *ngFor="let grp of this.hedgeBoardViewModelGroups">
        <legend class="legend" *ngIf="!this.isPriceAdjusterActiveInHedgeBoard">
          {{grp.portfolio?.userName}} | {{grp.portfolio?.name}} <span style="color: orange">({{grp?.shortOptionStrike}})</span>  :: [{{grp.template?.templateName}}]
        </legend>
        <legend class="legend" *ngIf="this.isPriceAdjusterActiveInHedgeBoard">
          <span style="color: orange">PRICE ADJUSTER MODE</span>
        </legend>
        
        <div class="side calls">
          <table class="hedgeboard-table">
            
            <thead>
            
            <tr style="position: sticky">
              
              <th class="cell header checkbox">&nbsp;</th>
              
              <th class="cell header offset">
                <ng-container *ngTemplateOutlet="hedgeboardPlainHeader; context: {side: 'calls', model: grp, text: 'Offset', sorting: 'offset_call', filter: 'offset_call'}"></ng-container>
              </th>
              
              <th class="cell header hedge">
                <ng-container *ngTemplateOutlet="hedgeboardPlainHeader; context: {side: 'calls', model: grp, text: 'Call Hedge', sorting: 'hedge_call', filter: 'hedge_call'}"></ng-container>
              </th>
              
              <th class="cell header adjustment">
                <ng-container *ngTemplateOutlet="hedgeboardPlainHeader; context: {side: 'calls', model: grp, text: 'Adjustment', sorting: 'adjustment', filter: 'adjustment'}"></ng-container>
              </th>
              
              <th class="cell header expiration" *ngFor="let exp of grp.calls[0]?.expirations; index as i">
                <div class="header-data">
                  <div class="text">
                    <div>Exp. {{i+1}}</div>
                    <div class="text-date">
                      <div>
                        {{exp?.date | etsFriendlyDatePipe}}
                      </div>
                      <div>
                        {{exp?.dow}} {{exp?.dte}}
                      </div>
                    </div>
                  </div>
                  
                  <div class="sorting icon" [ngSwitch]="grp.getSortingState('calls', 'expiration', i)"
                       (click)="grp.changeSortingAttribute('calls','expiration', i)">
                    <div *ngSwitchCase="'asc'" class="hot">
                      <i class="fas fa-sort-up"></i>
                    </div>
                    <div *ngSwitchCase="'desc'" class="hot">
                      <i class="fas fa-sort-down"></i>
                    </div>
                    <div *ngSwitchDefault>
                      <i class="fas fa-sort"></i>
                    </div>
                  </div>
                </div>
              </th>
            
            </tr>
            
            </thead>
            
            <tbody>
            
            <tr *ngFor="let data of grp.filteredCalls">
              
              <td class="cell checkbox">
                <dx-check-box [(value)]="data.isPinned" (onValueChanged)="grp.onPinnedChanged('calls')"></dx-check-box>
              </td>
              
              <td class="cell offset">
                {{data.offset | currency:'USD':'symbol':'1.0-0'}}
              </td>
              
              <td class="cell hedge">
                {{data?.hedge?.strategyName}}
              </td>
              
              <td class="cell adjustment">{{data?.adjustment}}</td>
              
              <td *ngFor="let exp of data?.models" class="cell expiration">
                <div class="data" (click)="this.showHedgesPopup(exp)">
                  <div class="price" [class.debit]="this.getCorrectHedgePrice(exp?.getCallHedgePrice()) < 0"
                       [class.credit]="(this.getCorrectHedgePrice(exp?.getCallHedgePrice())) > 0"
                  >
                    {{ this.getCorrectHedgePrice(exp?.getCallHedgePrice()) | currency}}
                  </div>
                </div>
              </td>
              
            </tr>
            </tbody>
       
          </table>
        </div>
        
        <div class="side puts">
          <table class="hedgeboard-table">
            <thead>
            
            <tr style="position: sticky">
              
              <th class="cell header checkbox">&nbsp;</th>
              
              <th class="cell header offset">
                <ng-container *ngTemplateOutlet="hedgeboardPlainHeader; context: {side: 'puts', model: grp, text: 'Offset', sorting: 'offset_put', filter: 'offset_put'}"></ng-container>
              </th>
              
              <th class="cell header hedge">
                <ng-container *ngTemplateOutlet="hedgeboardPlainHeader; context: {side: 'puts', model: grp, text: 'Put Hedge', sorting: 'hedge_put', filter: 'hedge_put'}"></ng-container>
              </th>
              
              <th class="cell header adjustment">
                <ng-container *ngTemplateOutlet="hedgeboardPlainHeader; context: {side: 'puts', model: grp, text: 'Adjustment', sorting: 'adjustment', filter: 'adjustment'}"></ng-container>
              </th>
              
              <th class="cell header expiration" *ngFor="let exp of grp.calls[0]?.expirations; index as i">
                <div class="header-data">
                  <div class="text">
                    <div>Exp. {{i+1}}</div>
                    <div class="text-date">
                      <div>
                        {{exp?.date | etsFriendlyDatePipe}}
                      </div>
                      <div>
                        {{exp?.dow}} {{exp?.dte}}
                      </div>
                    </div>
                  </div>
                  
                  <div class="sorting icon" [ngSwitch]="grp.getSortingState('puts', 'expiration', i)"
                       (click)="grp.changeSortingAttribute('puts', 'expiration', i)">
                    <div *ngSwitchCase="'asc'" class="hot">
                      <i class="fas fa-sort-up"></i>
                    </div>
                    <div *ngSwitchCase="'desc'" class="hot">
                      <i class="fas fa-sort-down"></i>
                    </div>
                    <div *ngSwitchDefault>
                      <i class="fas fa-sort"></i>
                    </div>
                  </div>
                </div>
              </th>
            
            </tr>
            
            </thead>
            
            <tbody>
            
            <tr *ngFor="let data of grp.filteredPuts">
              
              <td class="cell checkbox">
                <dx-check-box [(value)]="data.isPinned" (onValueChanged)="grp.onPinnedChanged('puts')"></dx-check-box>
              </td>
              
              
              <td class="cell offset">
                {{data.offset | currency:'USD':'symbol':'1.0-0'}}
              </td>
              
              <td class="cell hedge">
                {{data?.hedge?.strategyName}}
              </td>
              
              <td class="cell adjustment">{{data?.adjustment}}</td>
              
              <td *ngFor="let exp of data?.models" class="cell expiration">
                <div class="data" (click)="this.showHedgesPopup(exp)">
                  <div class="price"  [class.debit]="this.getCorrectHedgePrice(exp?.getPutHedgePrice()) < 0"
                       [class.credit]="(this.getCorrectHedgePrice(exp?.getPutHedgePrice())) > 0">
                    {{ this.getCorrectHedgePrice(exp?.getPutHedgePrice()) | currency}}
                  </div>
                </div>
              </td>
            
            </tr>
            </tbody>
          
          </table>
        </div>
        
      </fieldset>
    </div>
    
    
  </dx-scroll-view>
</ng-template>


<!-- Plain Header -->
<ng-template #hedgeboardPlainHeader let-side="side" let-model="model" let-text="text" let-sorting="sorting" let-filter="filter">
  <div class="header-data">
    <div class="filter icon" [class.hot]="model.isFilterEnabled(side, filter)"
         (click)="this.showHedgeBoardFilter(side,filter, model)" [style.visibility]="filter ? 'visible' : 'hidden'">
      <i class="fas fa-filter fa-xs"></i>
    </div>
    
    <div class="text">{{ text }}</div>
    
    <ng-container *ngTemplateOutlet="hedgeboardSortingIcon; context: {side: side, sortingAttribute: sorting, model: model}"></ng-container>
  </div>
</ng-template>


<!-- Sorting icon template-->
<ng-template #hedgeboardSortingIcon let-side="side" let-model="model" let-sortingAttribute="sortingAttribute">
  <div class="sorting icon" [ngSwitch]="model.getSortingState(side, sortingAttribute)"
       (click)="model.changeSortingAttribute(side, sortingAttribute)">
    <div *ngSwitchCase="'asc'" class="hot">
      <i class="fas fa-sort-up"></i>
    </div>
    <div *ngSwitchCase="'desc'" class="hot">
      <i class="fas fa-sort-down"></i>
    </div>
    <div *ngSwitchDefault>
      <i class="fas fa-sort"></i>
    </div>
  </div>
</ng-template>



<!--------------------------------------->


<!--
    DASHBOARD VIEW
-->
<!--Dashboard Plain Header -->
<ng-template #dashboardPlainHeader let-model="model" let-text="text" let-sorting="sorting" let-filter="filter">
  <div class="header-data">
    <div class="filter icon" [class.hot]="model.isDashboardFilterEnabled(filter)"
         (click)="this.showDashboardFilter(filter, model)" [style.visibility]="filter ? 'visible' : 'hidden'">
      <i class="fas fa-filter fa-xs"></i>
    </div>
    
    <div class="text">{{ text }}</div>
    
    <ng-container *ngTemplateOutlet="dashboardSortingIcon; context: {sortingAttribute: sorting, model: model}"></ng-container>
  </div>
</ng-template>


<!--Dashboard sorting icon template-->
<ng-template #dashboardSortingIcon let-model="model" let-sortingAttribute="sortingAttribute">
  <div class="sorting icon" [ngSwitch]="model.getDashboardSortingState(sortingAttribute)"
       (click)="model.changeDashboardSortingAttribute(sortingAttribute)">
    <div *ngSwitchCase="'asc'" class="hot">
      <i class="fas fa-sort-up"></i>
    </div>
    <div *ngSwitchCase="'desc'" class="hot">
      <i class="fas fa-sort-down"></i>
    </div>
    <div *ngSwitchDefault>
      <i class="fas fa-sort"></i>
    </div>
  </div>
</ng-template>


<!--Dashboard View -->
<ng-template #dashboard>
  
  <dx-scroll-view [direction]="'both'">
    
    <table class="dashboard-table" *ngFor="let group of this.dashboardPackagesViewModelGroups">
      
      <thead>
      <tr style="position: sticky">
        <th class="cell header checkbox">&nbsp;</th>
        <th class="cell header portfolio">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Portfolio', sorting: 'portfolio', filter: 'portfolio'}"></ng-container>
        </th>
        <th class="cell header template">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Template', sorting: 'template', filter: 'template'}"></ng-container>
        </th>
        <th class="cell header adjustment">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Adjustment', sorting: 'adjustment', filter: 'adjustment'}"></ng-container>
        </th>
        <th class="cell header expiration" *ngFor="let exp of group.items[0]?.expirations; index as i">
          <div class="header-data">
            <div class="text">
              <div>Exp. {{i+1}}</div>
              <div class="text-date">
                <div>
                  {{exp?.date | etsFriendlyDatePipe}}
                </div>
                <div>
                  {{exp?.dow}} {{exp?.dte}}
                </div>
              </div>
            </div>
            
            <div class="sorting icon" [ngSwitch]="group.getDashboardSortingState('expiration', i)"
                 (click)="group.changeDashboardSortingAttribute('expiration', i)">
              <div *ngSwitchCase="'asc'" class="hot">
                <i class="fas fa-sort-up"></i>
              </div>
              <div *ngSwitchCase="'desc'" class="hot">
                <i class="fas fa-sort-down"></i>
              </div>
              <div *ngSwitchDefault>
                <i class="fas fa-sort"></i>
              </div>
            </div>
          </div>
        </th>
        
        <th class="cell header offset">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Offset', sorting: 'offset_call', filter: 'offset_call'}"></ng-container>
        </th>
        
        <th class="cell header hedge">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Call Hedge', sorting: 'hedge_call', filter: 'hedge_call'}"></ng-container>
        </th>
        
        <th class="cell header offset">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Offset', sorting: 'offset_put', filter: 'offset_put'}"></ng-container>
        </th>
        
        <th class="cell header hedge">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Put Hedge', sorting: 'hedge_put', filter: 'hedge_put'}"></ng-container>
        </th>
        
        <th class="cell separator">
          &nbsp;
        </th>
        
        <th class="cell header hedges-totals">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Calls', sorting: 'hedges_cost_calls'}"></ng-container>
        </th>
        <th class="cell header hedges-totals">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Puts', sorting: 'hedges_cost_puts'}"></ng-container>
        </th>
        <th class="cell header hedges-totals">
          <ng-container *ngTemplateOutlet="dashboardPlainHeader; context: {model: group, text: 'Total', sorting: 'hedges_cost_total'}"></ng-container>
        </th>
      </tr>
      </thead>
      
      <tbody>
      <tr *ngFor="let data of group.filteredItems">
        <td class="cell checkbox">
          <dx-check-box [(value)]="data.isPinned" (onValueChanged)="this.onDashboardModelPinChanged(data)"></dx-check-box>
        </td>
        
        <td class="cell portfolio">{{data?.portfolio?.userName}} | {{data?.portfolio?.name}} <span style="color: orange">({{data?.shortOptionStrike}})</span></td>
        
        <td class="cell template">{{data?.template?.templateName}}</td>
        
        <td class="cell adjustment">{{data?.adjustment}}</td>
        
        <td *ngFor="let exp of data?.models" class="cell expiration">
          <div class="data">
            <div class="adj popup" (click)="this.showAdjustmentPopup(exp)">
              A
            </div>
            <div class="price"  [class.debit]="exp?.getPackagePrice() < 0" [class.credit]="exp?.getPackagePrice() > 0">
              {{exp?.getPackagePrice() | currency}}
            </div>
            <div class="hedges popup" (click)="this.showHedgesPopup(exp)">
              H
            </div>
          </div>
        </td>
        
        <td class="cell offset">
          {{data.callHedgeOffset | currency:'USD':'symbol':'1.0-0'}}
        </td>
        
        <td class="cell hedge">
          {{data?.callHedge?.strategyName}}
        </td>
        
        <td class="cell offset">
          {{data?.putHedgeOffset | currency:'USD':'symbol':'1.0-0'}}
        </td>
        
        <td class="cell hedge">
          {{data?.putHedge?.strategyName}}
        </td>
        
        <td class="cell separator">
          &nbsp;
        </td>
        
        <td class="cell hedges-totals"
            [class.debit]="this.getHedgesCost(data, 'calls') < 0"
            [class.credit]="this.getHedgesCost(data, 'calls') > 0"
        >
          {{ this.getHedgesCost(data, 'calls') | currency}}
        </td>
        
        <td class="cell hedges-totals"
            [class.debit]="this.getHedgesCost(data, 'puts') < 0"
            [class.credit]="this.getHedgesCost(data, 'puts') > 0"
        >
          {{ this.getHedgesCost(data, 'puts') | currency}}
        </td>
        
        <td class="cell hedges-totals"
            [class.debit]="this.getHedgesCost(data, 'total') < 0"
            [class.credit]="this.getHedgesCost(data, 'total') > 0"
        >
          {{ this.getHedgesCost(data, 'total') | currency}}
        </td>
      </tr>
      </tbody>
    
    </table>
  
  </dx-scroll-view>
</ng-template>


<!------------------------------------------>


<!--
      NO-PACKAGES VIEW
-->
<ng-template #noPackages>
  <div class="no-data">NO PACKAGES</div>
</ng-template>

<ng-template #dashboardEmpty>
  <div class="no-data">DASHBOARD IS EMPTY</div>
</ng-template>

<ng-template #hedgeBoardEmpty>
  <div class="no-data">HEDGES DASHBOARD IS EMPTY</div>
</ng-template>


<!------------------------------------------>


<!--
        PACKAGES VIEW
-->
<!--Label Template -->
<ng-template #label let-pkg="pkg" let-text="text" let-filter='filter' let-sortingAttribute="sortingAttribute">
  <div class="label" [class.different]="this.hasDataChanges(pkg, filter)"
       [class.atm-outdated]="this.isAtmOutdated(pkg)"
  >
    <div *ngIf="filter !== undefined"
         class="icon"
         [class.enabled]="this.isFilterEnabled(filter)"
         (click)="this.onFilterRequest(filter, pkg)"
    >
      <i class="fas fa-filter fa-xs"></i>
      <span *ngIf="this.isFamilyFilterEnabled(filter)" class="family">F</span>
    </div>
    
    <div class="text">{{ text }}</div>
    
    <ng-container *ngIf="sortingAttribute && sortingAttribute !== 'adjustment'">
      <ng-container *ngTemplateOutlet="sorting; context: {pkg: pkg, sortingAttribute: sortingAttribute}"></ng-container>
    </ng-container>
  
  </div>
</ng-template>

<!--List Template-->
<ng-template #pkgCmprsnMdl let-pkg="pkg">
  
  <fieldset class="pkg" [class.changes]="this.hasChanges(pkg)">
    
    <legend class="header">
      
      <div class="pin" [class.hot]="pkg.isPinned" (click)="this.pinPackage(pkg)">
        <i class="fas fa-thumbtack fa-sm"></i>
      </div>
      
      <div class="text" #nameEditorTarget>
        {{this.getPackageTitle(pkg)}}
      </div>
      
      <div class="edit" (click)="this.showNameEditor(pkg, nameEditorTarget)">
        <i class="fas fa-pencil-alt fa-sm"></i>
      </div>
      
    </legend>
    
    <div class="cell portfolio">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Portfolio', filter: 'portfolio', sortingAttribute: 'portfolio' }">
      </ng-container>
      <div class="value">
        <div class="selector">
          <dx-select-box
            width="100%"
            height="100%"
            fieldTemplate="field"
            [items]="this.portfolioList"
            [grouped]="true"
            [value]="pkg.portfolio"
            [displayExpr]="'name'"
            (onValueChanged)="this.onPortfolioSelected($event, pkg)"
          >
            <div *dxTemplate="let data of 'field'" style="height: 100%">
              <div style="height: 100%; display: grid;">
                <dx-text-box
                  [readOnly]="true"
                  [value]="data && data.userName + '  |  ' + data.name"
                  placeholder="Select portfolio..."
                ></dx-text-box>
              </div>
            </div>
          </dx-select-box>
        </div>
        <div class="short-option">
          {{pkg.shortOptionStrike}}
        </div>
      </div>
    </div>
    
    <div class="cell template">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Template', filter: 'template', sortingAttribute: 'template' }">
      </ng-container>
      <div class="value">
        <dx-select-box
          width="100%"
          height="100%"
          [items]="pkg.templateList"
          [(value)]="pkg.template"
          [displayExpr]="'templateName'"
          (onValueChanged)="this.onTemplateSelected($event, pkg)"
          [showClearButton]="true"
        ></dx-select-box>
      </div>
    </div>
    
    <div class="cell expiration">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Expiration', filter:'expiration', sortingAttribute: 'expiration' }">
      </ng-container>
      <div class="value">
        <div class="number">
          <!--suppress JSConstantReassignment -->
          <dx-select-box
            [(value)]="pkg.expiration"
            [items]="pkg.expirationList"
            [displayExpr]="'textSeqNo'"
            [inputAttr]="{style: 'text-align: center; font-weight: bold;'}"
            (onValueChanged)="this.onExpirationSelected($event, pkg)"
          >
            <div *dxTemplate="let data of 'item'" style="font-size: 0.99em">
              Exp {{ data?.seqNo }} - {{ data?.friendlyDate }}
            </div>
          </dx-select-box>
        </div>
        <div class="friendly" [class.refresh]="this.hasDataChanges(pkg, 'expiration')">
          <div class="date">{{pkg.expiration?.friendlyDate}}</div>
        </div>
      </div>
    </div>
    
    <div class="cell adjustments">
      <div class="headers">
        <div class="adjustment">
          <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Adj.Type', filter: 'adj_call' }"></ng-container>
          <div class="value">
              <dx-select-box
                [items]="pkg.adjustmentListCall"
                [(value)]="pkg.adjustmentOne"
                fieldTemplate="field"
                (onValueChanged)="this.onAdjustmentCallChanged()"
              >
                <div *dxTemplate="let data of 'field'">
                  <dx-text-box readOnly="true"
                               [value]="pkg.getCallAdjTypeDisplayValue()"
                               [inputAttr]="{style: 'text-align: center; font-weight: bold;'}"
                  ></dx-text-box>
                </div>
                <div *dxTemplate="let data of 'item'">
                  {{data?.name}}(C)
                </div>
              </dx-select-box>
          </div>
        </div>
       
        <div class="adjustment middle">
          <div>
            <ng-container *ngTemplateOutlet="sorting; context: {pkg: pkg, sortingAttribute: 'adjustment'}"></ng-container>
          </div>
          <div>
            +
          </div>
        </div>
        
        <div class="adjustment">
          <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Adj.Type', filter: 'adj_put' }"></ng-container>
          <div class="value">
            <dx-select-box
              [items]="pkg.adjustmentListPut"
              [(value)]="pkg.adjustmentTwo"
              fieldTemplate="field"
              (onValueChanged)="this.onAdjustmentPutChanged()"
            >
              <div *dxTemplate="let data of 'field'">
                <dx-text-box readOnly="true"
                             [value]="pkg.getPutAdjTypeDisplayValue()"
                             [inputAttr]="{style: 'text-align: center; font-weight: bold;'}"
                ></dx-text-box>
              </div>
              <div *dxTemplate="let data of 'item'">
                {{data?.name}}(P)
              </div>
            </dx-select-box>
          </div>
        </div>
        
      </div>
      <div class="price" [ngClass]="this.getPnLCssClass(pkg.getAdjustmentsPrice())">
        {{pkg.getAdjustmentsPrice() | currency}}
      </div>
    </div>
    
    <div class="cell offset">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Offset', filter: 'offset_call', sortingAttribute: 'offset_call' }"></ng-container>
      <div class="value">
        <dx-number-box
          width="100%"
          height="100%"
          format="$0#.##"
          [(value)]="pkg.callHedgeOffset"
          [inputAttr]="{style: 'text-align: center;'}"
          [showClearButton]="true"
          (onValueChanged)="this.onOffsetChanged('call')"
        ></dx-number-box>
      </div>
    </div>
    
    <div class="cell hedge">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Call Hedge', filter: 'hedge_call', sortingAttribute: 'hedge_call' }">
      </ng-container>
      <div class="name-value">
          <div class="name">
            <dx-select-box
              displayExpr="strategyName"
              [grouped]="true"
              [showClearButton]="true"
              [items]="pkg.callHedgeList"
              [(value)]="pkg.callHedge"
              [inputAttr]="{style: 'text-align: center; font-weight: bold;'}"
              (onValueChanged)="this.onCallHedgeChanged($event)"
            ></dx-select-box>
          </div>
          <div class="value" [ngClass]="this.getPnLCssClass(pkg.getCallHedgePrice())">
            {{pkg.getCallHedgePrice() | currency}}
          </div>
        </div>
    </div>
    
    <div class="cell offset">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Offset', filter: 'offset_put', sortingAttribute: 'offset_put' }"></ng-container>
      <div class="value">
        <dx-number-box
          width="100%"
          height="100%"
          format="$0#.##"
          [showClearButton]="true"
          [(value)]="pkg.putHedgeOffset"
          [inputAttr]="{style: 'text-align: center;'}"
          (onValueChanged)="this.onOffsetChanged('put')"
        ></dx-number-box>
      </div>
    </div>
    
    <div class="cell hedge">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'Put Hedge', filter: 'hedge_put', sortingAttribute: 'hedge_put' }">
      </ng-container>
      <div class="name-value">
        <div class="name">
          <dx-select-box
            [grouped]="true"
            displayExpr="strategyName"
            [items]="pkg.putHedgeList"
            [(value)]="pkg.putHedge"
            [showClearButton]="true"
            [inputAttr]="{style: 'text-align: center; font-weight: bold;'}"
            (onValueChanged)="this.onPutHedgeChanged($event)"
          ></dx-select-box>
        </div>
        <div class="value" [ngClass]="this.getPnLCssClass(pkg.getPutHedgePrice())">
          {{pkg.getPutHedgePrice() | currency}}
        </div>
      </div>
    </div>
    
    <div class="cell price" [ngClass]="this.getPnLCssClass(pkg.getPackagePrice())">
      <ng-container *ngTemplateOutlet="label; context: { pkg: pkg, text: 'TOTAL', sortingAttribute: 'total' }">
      </ng-container>
      <div class="value">
        {{pkg.getPackagePrice() | currency}}
      </div>
    </div>
    
    <div class="cell buttons solution">
      <div class="btn">
        <dx-button
          (onClick)="this.showAdjustmentPopup(pkg)"
          [disabled]="!pkg.hasAdjustment()"
          height="100%"
          text="Adj"
          width="100%"
        ></dx-button>
      </div>
      <div class="btn">
        <dx-button
          (onClick)="this.showHedgesPopup(pkg)"
          [disabled]="!pkg.hasHedges()"
          height="100%"
          text="H"
          width="100%"
        ></dx-button>
      </div>
    </div>
    
    <div class="cell buttons control">
      <div class="btn">
        <dx-button
          icon="copy"
          type="default"
          height="100%"
          width="100%"
         (onClick)="this.duplicatePackage(pkg)"
        ></dx-button>
      </div>
      
      <div class="btn">
        <dx-button
          icon="remove"
          type="danger"
          height="100%"
          width="100%"
          (onClick)="this.removePackage(pkg)"
        ></dx-button>
      </div>
    </div>
    
  </fieldset>
</ng-template>

<!--Sorting Icon Template-->
<ng-template #sorting let-attribute="sortingAttribute">
  <div class="sorting" [ngSwitch]="this.getSortingState(attribute)" (click)="this.changeSortingAttribute(attribute)">
    <div *ngSwitchCase="'asc'" class="hot">
      <i class="fas fa-sort-up"></i>
    </div>
    <div *ngSwitchCase="'desc'" class="hot">
      <i class="fas fa-sort-down"></i>
    </div>
    <div *ngSwitchDefault>
      <i class="fas fa-sort"></i>
    </div>
  </div>
</ng-template>


<!-------------------------------------------->


<!--
      COMPONENTS
 -->
<ets-hg-transaction-popup context="pkg-cmprsn">
</ets-hg-transaction-popup>

<ets-adjustment-solution-popup>
</ets-adjustment-solution-popup>

<ets-package-comparison-settings>
</ets-package-comparison-settings>

<ets-generic-confirmation-dialog
  title="Attention!"
></ets-generic-confirmation-dialog>

<ets-dashboard-filter-popup>
</ets-dashboard-filter-popup>

<ets-package-moving-floater-component>
</ets-package-moving-floater-component>

<ets-override-atm-dialog>
</ets-override-atm-dialog>

<ets-package-name-editor></ets-package-name-editor>


