<div class="pricing-settings">
   <fieldset>
      <legend>Comparison Pricing</legend>
      
      <!-- Max Expirations Forward -->
      <div class="field">
         <div class="label">Expirations Look Forward</div>
         <div class="value">
            <dx-number-box 
               [showClearButton]="true" 
               [showSpinButtons]="true"
               [useLargeSpinButtons]="true"
               [(value)]="this.expirationsLookForward" 
               [step]="1" 
               [min]="1"
               >
            </dx-number-box>
         </div>
      </div>

      <!-- Selectıon Mode -->
      <div class="field">
         <div class="label">
            Pricing Selection Mode
         </div>
         <div class="value">
            <dx-select-box 
               [showClearButton]="true" 
               [items]="this.pricingModes"
               [(value)]="this.pricingMode" 
               >
            </dx-select-box>
         </div>
      </div>
   
       <!-- Price Buffer -->
      <div class="field">
         <div class="label">
            Price Buffer
         </div>
         <div class="value">
            <dx-number-box 
               [showClearButton]="true" 
               [showSpinButtons]="true"
               [useLargeSpinButtons]="true"
               [(value)]="this.pricingBuffer" 
               [min]="0"
               [step]="0.01" 
               format="$0#.00"
               >
            </dx-number-box>
         </div>
      </div>

       <!-- Diff Buffer -->
      <div class="field">
         <div class="label">
            Differential Buffer
         </div>
         <div class="value">
            <dx-number-box 
               [showClearButton]="true" 
               [showSpinButtons]="true"
               [useLargeSpinButtons]="true"
               [(value)]="this.differentialBuffer" 
               [min]="0"
               [step]="0.01" 
               format="$0#.00"
               >
            </dx-number-box>
         </div>
      </div>   
   </fieldset>
</div>