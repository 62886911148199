import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DevExtremeModule} from '../devextreme.module';
import {ResourceEditorComponent} from './resource-editor.component';
import {TimezonePickerModule} from '../timezone-picker/timezone-picker.module';
import {ResourceMenuComponent} from "./resources-menu/resource-menu.component";
import {AdjustmentPricingGridModule} from "../adjustment-pricing-grid/adjustment-pricing-grid.module";
import {AssetConditionResourceComponent} from "./conditions/asset/asset-condition.component";
import {EtsSymbolPickerModule} from "../symbol-picker/symbol-picker.module";
import {DateConditionResourceComponent} from "./conditions/date/date-condition.component";
import {DayOfWeekConditionResourceComponent} from "./conditions/day-of-week/day-of-week-condition.component";
import {UsersConditionResourceComponent} from "./conditions/users/users-condition.component";
import {ExtraPoConditionResourceComponent} from "./conditions/extra-po/extra-po-condition.component";

@NgModule({
    declarations: [
        ResourceEditorComponent,
        ResourceMenuComponent,
        AssetConditionResourceComponent,
        DateConditionResourceComponent,
        DayOfWeekConditionResourceComponent,
        UsersConditionResourceComponent,
        ExtraPoConditionResourceComponent
    ],
    imports: [
        DevExtremeModule,
        CommonModule,
        TimezonePickerModule,
        AdjustmentPricingGridModule,
        EtsSymbolPickerModule
    ],
    exports: [
        ResourceEditorComponent,
        ResourceMenuComponent,
    ],
    providers: [],
})
export class ResourceEditorModule {
}
