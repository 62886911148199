import { Component, Input, OnInit } from '@angular/core';
import { PutSpreadParameters } from './put-spread-parameters';

@Component({
   selector: 'ets-put-spread-strategy',
   templateUrl: './put-spread-strategy.component.html',
   styleUrls: ['../strategy-parameters.scss', './put-spread-strategy.component.scss']
})

export class PutSpreadStrategyComponent implements OnInit {
   constructor() { }

   @Input() parameters: PutSpreadParameters;

   @Input() cpMode: string;

   ngOnInit() { }

   getDateTimePickerMode() {
      if (!this.parameters) {
         return null;
      }

      if (this.parameters.convertToMarketSettings.actionTimeMode === 'Convert At') {
         return 'time';
      }

      if (this.parameters.convertToMarketSettings.actionTimeMode === 'Convert After') {
         return 'timespan';
      }

      return null;
   }
}
