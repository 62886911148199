import {OrderStatus} from '../../trading-model/order-status.enum';
import {MarketSide} from '../../trading-model/market-side.enum';
import {OrderType} from '../../trading-model/order-type.enum';
import {TimeInForce} from '../../trading-model/time-in-force.enum';

export enum OrderLinkType {
   Unknown = 0,
   OTO,
   OCO
}

export class OrderDto {
  orderId: string;
  strategyId: string;
  ticker: string;
  accountId: string;
  gatewayId: string;
  crspOrderId: string;
  providerOrderId: string;
  comment: string;
  created: Date;
  lastModifiedDate: Date;
  status: OrderStatus;
  side: MarketSide;
  orderType: OrderType;
  qty: number;
  filledQty: number;
  lastQty: number;
  leavesQty: number;
  lastPx: number;
  limitPrice: number;
  stopPrice: number;
  avgFillPrice: number;
  tif: TimeInForce;
  accountCode: string;
  terminalId: string;
  terminalCode: string;
  tags: string;
  sessionId: string;
  seqNum: number;
  potentialAccount?: string;
  isPossibleDuplicate: boolean;
  violationId?: string;
  riskManagerRuleId?: string;
  resolveAccountCode: string;
  lastQuote: number;
  shellId: string;
  clientId: string;
  shellName: string;
  clientName: string;
  lastMarketQuote: number;
  multiLegDescriptor: string;
  legs: any[];
  linkedOrders: string;
  portfolioId: string;
  portfolioName: string;
  comboName: string;
  comboId: string;
  comboGroupId: string;
  comboGroupName: string;
  liveQuote?: number;
  tickerDisplayName: string;
  masterOrder: string;
  legsDisplayCode: string;
  optionStrategyName: string;
  userLabel: string;
  futureSettings: string;
  convertToMarketSettings: string;
  autoLimitPrice: string;
}
