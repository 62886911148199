import { Injectable } from '@angular/core';
import { MessageBusService } from '../message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { EventStreamServiceClient } from '../event-stream/event-stream-service-client.service';
import { Logger } from '../logging/logger.interface';
import { StrategyLogMessageDto } from '../shell-communication/dtos/strategy-log-message-dto.class';
import {
   ShellConnectionStatusChangedUIMessage
} from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import { filter, takeUntil } from 'rxjs/operators';
import { EngineStrategyRemovedDto } from '../shell-communication/dtos/engine-strategy-removed-dto.class';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import {
   StrategyIssueAcknowledgedDto
} from '../shell-communication/dtos/strategy-issue-acknowledged-dto.interface';
import { Subscription, Subject } from 'rxjs';
import {
   EventStreamConnectionChangedUIMessage
} from '../ui-messages/event-stream-connection-changed-message-ui-message.interface';
import { SessionService } from '../authentication/session-service.service';
import {
   GetStrategyIssuesCount
} from '../shell-communication/operations/strategies/get-strategy-issues-count.class';
import { StrategiesIssuesBackendService } from './strategy-issues-backend-service.interface';
import { StrategiesService } from './strategies.service';
import { IssueAckedUIMessage } from '../ui-messages/issue-acked-ui-message.interface';
import { LoggerService } from '../logging/logger-factory.service';

interface PendingIssuesContainer {
   isLoading: boolean;
   data: StrategyLogMessageDto[];
}

interface StrategyIssuesBatch {
   strategyId: string;
   data: StrategyLogMessageDto[];
}

@Injectable({ providedIn: 'root' })
export class StrategiesIssuesService {

   constructor(
      private readonly _backendService: StrategiesIssuesBackendService,
      private readonly _messageBus: MessageBusService,
      private readonly _toastr: ToastrService,
      private readonly _essService: EventStreamServiceClient,
      private readonly _sessionService: SessionService,
      private readonly _strategiesService: StrategiesService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('StrategyIssuesService');
   }

   private readonly _pendingIssuesContainer: PendingIssuesContainer = { isLoading: false, data: [] };
   private readonly _logger: Logger;
   private _isInitialized: boolean;
   private _issuesByStrategyId: Record<string, number>;
   private _lastTotalCount: number = null;
   private _unsubscriber: Subject<any>;


   async init(): Promise<void> {

      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<any>('StrategyLogMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this._onStrategyLogMessageBucket(msg.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(x => x.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onShellConnectionStatusChanged(msg.payload));

      this._messageBus
         .of<EngineStrategyRemovedDto>('EngineStrategyRemovedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this._onEngineStrategyRemoved(msg.payload));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this._onClearTradingData(msg.payload));

      this._messageBus
         .of<StrategyIssueAcknowledgedDto>('StrategyIssueAcknowledgedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this._onStrategyIssueAcknowledgedDto(msg.payload));

      if (this._essService.isConnected) {
         
         await this._loadIssues();

      } else {
         
         let initializationSubscription: Subscription;
         initializationSubscription = this._messageBus
            .of<EventStreamConnectionChangedUIMessage>('EventStreamConnectionChangedUIMessage')
            .pipe(filter(x => x.payload.isConnected))
            .subscribe(msg =>
               setTimeout(async () => {
                  if (this._isInitialized) {
                     return;
                  }

                  if (initializationSubscription) {
                     initializationSubscription.unsubscribe();
                  }

                  this._isInitialized = true;
                  await this._loadIssues();
               }, 0));
      }

      this._messageBus.publishAsync({ topic: 'StrategyIssuesChanged', payload: {}});
   }

   getTotalIssuesCount(): number {
      if (this._lastTotalCount !== null) {
         return this._lastTotalCount;
      }

      let count = 0;

      if (this._issuesByStrategyId) {
         count = Object.values(this._issuesByStrategyId).reduce((acc, curr) => acc + curr, 0);
      }

      this._lastTotalCount = count;

      return count;
   }


   getStrategyIssuesCount(strategyId: string): number {
      let result = 0;
      if (this._issuesByStrategyId) {
         result = this._issuesByStrategyId[strategyId] || 0;
      }
      return result;
   }

   
   private async _onClearTradingData(msg: ClearTradingDataUIMessage): Promise<void> {
      this._logger.info('Received "ClearTradingDataUIMessage"');

      if (msg.hasErrors) {
         return;
      }

      if (!this._issuesByStrategyId) {
         this._logger.info('Service not initialized. Returning');
         return;
      }

      if (msg.strategies && msg.strategies.length > 0) {
         this._lastTotalCount = null;
         msg.strategies.forEach(stratId => {
            if (stratId in this._issuesByStrategyId) {
               delete this._issuesByStrategyId[stratId];
            }
         });
      } else {
         await this._loadIssues(msg.shellId);
      }
   }

   
   private async _loadIssues(shellId?: string): Promise<void> {
      this._logger.info(`Loading strategies issues data. ShellId=${shellId || '(empty)'}`);

      this._pendingIssuesContainer.isLoading = true;

      if (shellId) {
         Object.keys(this._issuesByStrategyId).forEach(key => {
            const strategy = this._strategiesService.getById(key);
            if (strategy === undefined) {
               delete this._issuesByStrategyId[key];
            } else {
               if (strategy.shellId === shellId) {
                  delete this._issuesByStrategyId[key];
               }
            }
         });
      } else {
         this._issuesByStrategyId = {};
      }

      const sessionTerminals = this._sessionService.loginResult;

      const terminals = sessionTerminals
         ? sessionTerminals.availableTerminals.map(x => x.terminalId)
         : [];

      const qry = new GetStrategyIssuesCount(terminals);
      
      try {
         
         const resp = await this._backendService.getStrategiesIssues(qry, shellId);
         
         if (resp.data.length > 0) {
           
            resp.data.forEach(x => {
               this._issuesByStrategyId[x.strategyId] = x.issuesCount;
            });
            const fakeDtos: any = resp.data.map(x => ({ seqNum: x.lastSeqNum, strategyId: x.strategyId }));
            this._pendingIssuesContainer.isLoading = false;
            this._onStrategyIssueMessage(fakeDtos, true);
         }

      } catch (e) {
         
         const message = '"Strategy Issues" service initialized with errors';
         this._toastr.error(message);
         this._logger.error(message, e);

      } finally {

         this._pendingIssuesContainer.isLoading = false;
         this._pendingIssuesContainer.data.length = 0;
         this._lastTotalCount = null;
         this._messageBus.publishAsync({ topic: 'StrategyIssuesChanged', payload: {} });
      }
   }

   
   private _onStrategyIssueMessage(msg: StrategyLogMessageDto[], isAfterLoad = false): void {

      if (msg.length === 0) {
         return;
      }

      if (this._pendingIssuesContainer.isLoading) {
         this._pendingIssuesContainer.data.push(...msg);
      } else {
         const lastDbMessage = msg[msg.length - 1].seqNum;
         if (isAfterLoad) {
            msg = [];
         }
         if (this._pendingIssuesContainer.data.length > 0) {
            const missedPendings = this._pendingIssuesContainer.data.filter(x => x.seqNum > lastDbMessage);
            if (missedPendings.length > 0) {
               msg.push(...missedPendings);
            }
            this._pendingIssuesContainer.data.length = 0;
         }
         msg.forEach(x => this._processStrategyLogMessage(x));
      }
   }

   
   private async _onShellConnectionStatusChanged(msg: ShellConnectionStatusChangedUIMessage): Promise<void> {
      this._logger.info('Received "ShellConnectionStatusChangedUIMessage"');
      await this._loadIssues(msg.shellId);
   }

   
   private _processStrategyLogMessage(dto: StrategyLogMessageDto): void {
      if (dto.strategyId in this._issuesByStrategyId) {
         this._issuesByStrategyId[dto.strategyId]++;
      } else {
         this._issuesByStrategyId[dto.strategyId] = 1;
      }

      this._lastTotalCount = null;
   }

   
   private _onStrategyLogMessageBucket(msg: Record<string, StrategyLogMessageDto[]>): void {
      let issuesChanged = false;

      Object.keys(msg).forEach(x => {
         const dtos = msg[x];
         if (!!dtos && dtos.length > 0) {
            const warnings = dtos.filter(y => y.category >= 2);
            if (warnings.length > 0) {
               issuesChanged = true;
               this._onStrategyIssueMessage(warnings);
            }
         }
      });

      if (issuesChanged) {
         this._messageBus.publishAsync({ topic: 'StrategyIssuesChanged', payload: {} });
      }
   }

   
   private _onStrategyIssueAcknowledgedDto(msg: StrategyIssueAcknowledgedDto): void {
      this._lastTotalCount = null;

      if (!this._issuesByStrategyId) {
         return;
      }

      if (this._sessionService.sessionData.userId !== msg.userId) {
         return;
      }

      let issuesCount = this._issuesByStrategyId[msg.strategyId];
      issuesCount -= msg.issuesCount;
      if (issuesCount < 0) {
         issuesCount = 0;
      }

      this._issuesByStrategyId[msg.strategyId] = issuesCount;

      this._messageBus.publish({
         topic: 'IssueAckedUIMessage',
         payload: {
            strategyId: msg.strategyId,
            issues: msg.issues
         } as IssueAckedUIMessage
      });
   }

   
   private _onEngineStrategyRemoved(msg: EngineStrategyRemovedDto): void {
   
      if (!this._issuesByStrategyId) {
         return;
      }

      let hasChanges = false;

      msg.strategies.forEach(removedStrategy => {
         if (removedStrategy.strategyId in this._issuesByStrategyId) {
            delete this._issuesByStrategyId[removedStrategy.strategyId];
            hasChanges = true;
         }
      });
      
      if (hasChanges) {
         this._lastTotalCount = null;
         this._messageBus.publishAsync({ topic: 'StrategyIssuesChanged', payload: {} });
      }
   }
}
