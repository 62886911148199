import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ToggleStrategyState } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { StrategyIssuesBrowserComponent } from 'projects/shared-components/strategy-issues/strategy-issues-browser.component';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';
import { ParameterOverview, StrategyOverview } from './strategy-overview.model';

@Component({
   selector: 'ets-strategy-overview-block',
   template: `
   <table #table [ngStyle]="{'background-color': this.strategy.bgColor}">
      <tbody>
         <tr *ngFor="let param of strategy.parameters">
            <td class="parameter">{{param.key}}</td>
            <td class="value" 
               [ngClass]="
                           {
                              'enabled': param.value === 'Enabled', 
                              'disabled': param.value === 'Disabled',
                              'issues': param.key === 'Issues:',
                              'errors': param.key === 'Issues:' && param.value !== '0'
                           }" (click)="this.showStrategyIssues(param)">
               {{param.value}}
            </td>
         </tr>
      </tbody>
   </table>
   <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: table}"></dx-load-panel>
   <ets-strategy-issues-browser></ets-strategy-issues-browser>
   `,
   styleUrls: ['table-styles.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class StrategyOverviewComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _shellService: ShellClientService,
   ) { }

   @ViewChild(StrategyIssuesBrowserComponent) strategyIssues: StrategyIssuesBrowserComponent;

   @Input() strategy: StrategyOverview;

   
   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }
   

   ngOnInit() { }

   @DetectMethodChanges()
   async showStrategyIssues(paramClicked: ParameterOverview) {
      if (paramClicked.key === 'Issues:') {
         
         if (paramClicked.value === '0') {
            return;
         }

         if (this.strategyIssues) {
            this.strategyIssues.show(this.strategy.strategy);
         }
      } else if (paramClicked.key === 'Status:') {
         if (this.strategy.strategy) {
            const qry = new ToggleStrategyState(this.strategy.strategy.strategyId);
            this.isLoading = true;
            try {

               await this._shellService.processCommand(qry);
            } catch (e) {
               console.error(e);
            } finally {
               this.isLoading = false;
            }
         }
      }
   }
}
