<dx-popup
  width="80%"
  height="60%"
  [showTitle]="true"
  title="Sync Override History"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let data of 'content'" class="view-container" #historyContainer>

   <as-split class="split" direction="horizontal" [gutterSize]="5">
      <as-split-area order="0" size="60">
         <ag-grid-angular
         id="history-grid"
         class="ag-theme-balham-dark"
         style="width: 100%; height: 100%;"
         [gridOptions]="historyRecordsGridModel"
         ></ag-grid-angular>
      </as-split-area>

      <as-split-area order="1" size="40">
         <as-split direction="vertical" [gutterSize]="5">
         <as-split-area order="0" [size]="strategiesGridSpace">
            <ag-grid-angular
               class="ag-theme-balham-dark"
               style="width: 100%; height: 100%;"
               [gridOptions]="detailStrategiesGridModel"
            ></ag-grid-angular>
         </as-split-area>
         <as-split-area order="1" [size]="mpGridSpace">
            <ag-grid-angular
               class="ag-theme-balham-dark"
               style="width: 100%; height: 100%;"
               [gridOptions]="detailManualPositionsGridModel"
            ></ag-grid-angular>
         </as-split-area>
         </as-split>
      </as-split-area>
   </as-split>
   
    <dx-load-panel  [visible]="isRollbackInProgress"
                    [position]="{my: 'center', at: 'center', of: historyContainer }"
    ></dx-load-panel>

  </div>
</dx-popup>

<dx-popup width="550"
          height="350"
          [showTitle]="true"
          title='Sync Override Note'
          [closeOnOutsideClick]="true"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          [(visible)]="this.notePopupSettings.isNotePopupVisible">
  <div *dxTemplate="let data of 'content'" id="session_message_details">
    <div id="popup_container">
      <p>{{this.notePopupSettings.note}}</p>
    </div>
  </div>
</dx-popup>