import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { DetectMethodChanges, DxValueChanged, isVoid } from '../utils';
import { SymbolPickerAclService } from './symbol-picker-acl.service';
import { SymbolPickerTreeService } from './symbol-picker-tree.service';

@Component({
   selector: 'ets-symbol-picker',
   templateUrl: 'symbol-picker.component.html',
   styleUrls: ['symbol-picker.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolPickerComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      public readonly aclService: SymbolPickerAclService,
      private readonly _tree: SymbolPickerTreeService,
      private readonly _tiService: TradingInstrumentsService) {
   }

   //
   @Input()
   disabled = false;

   //
   @Input()
   underlyings = false;

   @Input()
   showLastPrice = true;

   @Input()
   showClearButton = false;

   //
   @Output()
   instrumentSelected = new EventEmitter<TradingInstrument>();

   @Input()
   placeholder = 'Pick symbol...';

   //
   isPopupVisible: boolean;

   //
   mruItems: TradingInstrument[] = [];

   //
   private _selectedInstrument: TradingInstrument;
   get selectedInstrument(): TradingInstrument {
      return this._selectedInstrument;
   }
   @Input()
   set selectedInstrument(value: TradingInstrument) {
      this._selectedInstrument = value;
      this._changeDetector.detectChanges();
   }

   //
   ngOnInit(): void {
      const mruItems = [];
      
      // hardcoded for tradingmasteryhq.net
      const spy = this._tiService.getInstrumentByTicker('SPY');
      const spx = this._tiService.getInstrumentByTicker('SPX');
      const xsp = this._tiService.getInstrumentByTicker('XSP');

      mruItems.push(spy);
      mruItems.push(spx);
      mruItems.push(xsp);
      
      const favs = this._tree.getTree().find(x => x.id === 'Favorites');

      if (!isVoid(favs)) {
         favs.children.forEach(ch => {
            if (!isVoid(ch.dataObject)) {
               if (mruItems.indexOf(ch.dataObject) < 0 ) {
                  mruItems.push(ch.dataObject);
               }
            }
         });
      }

      this.mruItems = mruItems;
   }

   //
   @DetectMethodChanges()
   onInstrumentSelected(event: TradingInstrument): void {
      this.selectedInstrument = event;
      
      this.isPopupVisible = false;
      
      this.instrumentSelected.emit(this.selectedInstrument);
      
      if (isVoid(event) || this.mruItems.indexOf(event) >= 0) {
         return;
      }
      
      if (this.mruItems.length > 10) {
         this.mruItems.pop();
      }
      
      this.mruItems.unshift(event);

   }

   //
   @DetectMethodChanges()
   onDialogClosed(): void {
      this.isPopupVisible = false;
   }

   //
   @DetectMethodChanges()
   togglePopup() {
      this.isPopupVisible = !this.isPopupVisible;
   }

   //
   onMruItemSelected(ev: DxValueChanged<TradingInstrument>) {
      if (!ev.event) {
         return;
      }

      this.onInstrumentSelected(ev.value);
   }
}
