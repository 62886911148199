import { NgModule } from '@angular/core';
import { QuoteBoardComponent } from './quote-board.component';
import { AgGridModule } from 'ag-grid-angular';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
  imports: [
    CommonModule,
    EtsSymbolPickerModule,
    AgGridModule.withComponents([]),
    DevExtremeModule
  ],
  exports: [QuoteBoardComponent],
  declarations: [QuoteBoardComponent],
  providers: []
})
export class EtsQuoteBoardModule { }
