<div id="terminal-exit-action-button" class="terminal-exit">
   <dx-button width="100%" height="100%" text="Terminal Exit" class="main-button"
      (onClick)="onActionButtonClick($event)"></dx-button>

   <div class="overlay" [hidden]="!isOverlayVisible" [style.top.px]="overlayPosition.top"
      [style.left.px]="overlayPosition.left">
      <div class="button" *ngFor="let term of availableTerminals; first as isFirst; last as isLast">
         <dx-button width="100%" [text]="term.displayName" type="danger" [ngClass]="{'first': isFirst, 'last': isLast}"
            (onClick)="exitTerminal(term)"></dx-button>
      </div>
   </div>

</div>