import { PanelModel } from '../panel.model';
import { EtsTabsPanelComponent } from './tabs-panel/tabs-panel.component';
import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as shortid from 'shortid';

@Component({
   selector: 'ets-workspace-header',
   templateUrl: './workspace-header.component.html',
   styleUrls: ['./workspace-header.component.scss']
})
export class EtsWorkspaceHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

   constructor() {
      this.panels = [];
      this.panelSelected = new EventEmitter<PanelModel>();
      this.panelClosed = new EventEmitter<PanelModel>();
      this.symbolLinkChanged = new EventEmitter<any>();
      this.panelHeaderChanged = new EventEmitter<PanelModel>();
      this.addPanelContextMenuRequest = new EventEmitter();
      this.tabsMenuContextMenuRequest = new EventEmitter();
      this.expanded = new EventEmitter();
      this.sortPanels = new EventEmitter();
      this.splitRequest = new EventEmitter<MouseEvent>();
   }

   private _selfRef: any;
   private _bindedResizedCallback: any;

   wshId: string;

   @ViewChild(EtsTabsPanelComponent, { static: true })
   tabPanel: EtsTabsPanelComponent;

   @Input()
   panels: PanelModel[];

   @Input()
   isWorkspaceExpanded: boolean;

   @Output()
   panelSelected;

   @Output()
   panelClosed;

   @Output()
   symbolLinkChanged: EventEmitter<any>;

   @Output()
   panelHeaderChanged: EventEmitter<PanelModel>;

   @Output()
   addPanelContextMenuRequest;

   @Output()
   tabsMenuContextMenuRequest;

   @Output()
   expanded;

   @Output() sortPanels: EventEmitter<void>;

   @Output() splitRequest: EventEmitter<MouseEvent>;

   @ViewChild(forwardRef(() => EtsTabsPanelComponent), { static: true })
   tabsPanel: EtsTabsPanelComponent;


   ngOnInit() {
      this.wshId = shortid.generate();
      this._bindedResizedCallback = this.onResized.bind(this);
   }

   ngAfterViewInit() {
      this._selfRef = document.getElementById(`wsh_${this.wshId}`);
      // tslint:disable-next-line: no-string-literal
      const addResizeListener = window['addResizeListener'];
      addResizeListener(this._selfRef, this._bindedResizedCallback);
   }

   onResized() {
      if (this.tabPanel) {
         this.tabPanel.onResized();
      }
   }

   scrollToSelectedTab(panelId: string) {
      this.tabsPanel.scrollToSelectedTab(panelId);
   }

   ngOnDestroy() {
      if (this._selfRef) {
         // tslint:disable-next-line: no-string-literal
         const removeResizeListener = window['removeResizeListener'];
         removeResizeListener(this._selfRef, this._bindedResizedCallback);
      }
   }

   onAddPanelContextMenuRequest($event) {
      this.addPanelContextMenuRequest.emit($event);
   }

   onTabsMenuContextMenuRequest($event) {
      this.tabsMenuContextMenuRequest.emit($event);
   }

   onTabSelected($event) {
      this.panelSelected.emit($event);
   }

   onTabClosed($event) {
      this.panelClosed.emit($event);
   }

   onSymbolLinkChanged(ev) {
      this.symbolLinkChanged.emit(ev);
   }

   onTabHeaderChanged(event: PanelModel) {
      this.panelHeaderChanged.emit(event);
   }

   onExpanded() {
      this.expanded.emit();
   }

   onSortPanelsRequest() {
      this.sortPanels.emit();
   }

   onSplitRequest(event): void {
      this.splitRequest.emit(event);
   }
}
