<div class="order-conditions">
  <fieldset>
    <legend>Order Conditions</legend>

    <div class="section">
      <p>Order Conceal Strategy</p>
      <dx-select-box [items]="control.orderConcealStrategyItems"
                     [(value)]="control.orderConcealStrategy"
                     displayExpr="displayName"
                     valueExpr="strategy"
      ></dx-select-box>
    </div>

    <div class="section">
      <p>Trade-Through Proxy</p>
      <dx-check-box [(value)]="control.isTradeThroughProxy"></dx-check-box>
    </div>

    <div class="section">
      <p>Trade-Through Market</p>
      <dx-check-box [(value)]="control.isTradeThroughStop"></dx-check-box>
    </div>

    <div class="section">
      <p>Trade-Through PT</p>
      <dx-check-box [(value)]="control.isTradeThroughMIT"></dx-check-box>
    </div>

    <div class="section">
      <p>Trade-Through Limit</p>
      <dx-check-box [(value)]="control.isTradeThroughLimit"></dx-check-box>
    </div>

  </fieldset>
</div>

<div class="strategies">
  <fieldset>
    <legend>Strategies</legend>
    <ag-grid-angular style="width: 100%; height: 96%"
                     class="ag-theme-balham-dark"
                     [gridOptions]="control.innerStrategiesGridModel"
    ></ag-grid-angular>
  </fieldset>
</div>

<ets-edit-strategy-dialog (innerStrategyAdded)="onInnerStrategyAdded($event)"
                          (innerStrategyUpdated)="onInnerStrategyUpdated($event)"
></ets-edit-strategy-dialog>