<dx-popup [width]="500"
          [height]="300"
          title="Custom Exits Dialog"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          [visible]="isVisible"
          (onHidden)="onHidden()">
  <div *dxTemplate="let data of 'content'">
    <div class="content" #cepopup>
      <div class="operation-area">
        <fieldset class="npo-section">
          <legend>
            <div class="checkbox part">
              <dx-check-box [(value)]="this.useNpoOrder"></dx-check-box>
            </div>
            <div class="label part">
              NPO ({{this.buyOrSell}})
            </div>
          </legend>
          <div class="header">
            <div class="column header ticker">Ticker</div>
            <div class="column header last">Last Px.</div>
            <div class="column header qty">Qty</div>
            <div class="column header price">Price</div>
            <div class="column header points">Points</div>
          </div>
          <div class="rows">
            <div class="column ticker">{{this.npoOrder ? this.tradingInstrument?.displayName : null}}</div>
            <div class="column last" [ngStyle]="{'color': this.isNpoPriceValid ? undefined : 'red'}">{{this.npoOrder?.lastPx}}</div>
            <div class="column qty">
              <dx-number-box [value]="this.npoOrder?.qty" [disabled]="!this.npoOrder" (onValueChanged)=" this.npoOrder ? this.npoOrder.qty = $event.value : null"></dx-number-box>
            </div>
            <div class="column price">
              <div class="checkbox">
                <dx-check-box [value]="this.npoOrder?.usePrice" [disabled]="!this.npoOrder" (onValueChanged)="onNpoOrderPriceCheckboxChanged($event)"></dx-check-box>
              </div>
              <div class="value">
                <dx-number-box  [value]="this.npoOrder?.price" 
                                (onValueChanged)=" this.npoOrder ? this.npoOrder.price = $event.value : null"
                                [disabled]="!this.npoOrder ||  !this.npoOrder?.usePrice" 
                                [step]="this.tradingInstrument?.tickSize" 
                                [min]="0"
                                [showSpinButtons]="true"
                ></dx-number-box>
              </div>
            </div>
            <div class="column points">
              <div class="checkbox">
                <dx-check-box [value]="this.npoOrder?.usePoints" (onValueChanged)="this.npoOrder ? this.npoOrder.usePoints = $event.value : null" [disabled]="!this.npoOrder"></dx-check-box>
              </div>
              <div class="value">
                <dx-number-box  [value]="this.npoOrder?.points" 
                                (onValueChanged)="this.npoOrder ? this.npoOrder.points = $event.value : null"
                                [disabled]="!this.npoOrder || !this.npoOrder?.usePoints" 
                                [step]="this.tradingInstrument?.tickSize" 
                                [showSpinButtons]="true"
                                [min]="0"
                ></dx-number-box>
              </div>
            </div>  
          </div>
        </fieldset>
        <hr>
        <fieldset class="sl-section">
          <legend>
            <div class="checkbox part">
              <dx-check-box [(value)]="this.useStopLossOrder"></dx-check-box>
            </div>
            <div class="label part">
              STOP-LOSS ({{this.buyOrSell}})
            </div>
          </legend>
          <div class="header">
            <div class="column header ticker">Ticker</div>
            <div class="column header last">Last Px.</div>
            <div class="column header qty">Qty</div>
            <div class="column header price">Price</div>
            <div class="column header ticks">Points</div>
          </div>
          <div class="rows">
            <div class="column ticker">{{ this.slOrder ? this.tradingInstrument?.displayName : null}}</div>
            <div class="column last" [ngStyle]="{'color': this.isSlPriceValid ? undefined : 'red'}">{{this.slOrder?.lastPx}}</div>
            <div class="column qty">
              <dx-number-box [value]="this.slOrder?.qty" [disabled]="!this.slOrder" [readOnly]="true" (onValueChanged)=" this.slOrder ? this.slOrder.qty = $event.value : null"></dx-number-box>
            </div>
            <div class="column price">
              <div class="checkbox">
                <dx-check-box [disabled]="!this.slOrder" [value]="this.slOrder?.usePrice" (onValueChanged)="onSlOrderPriceCheckboxChanged($event)"></dx-check-box>
              </div>
              <div class="value">
                <dx-number-box  [value]="this.slOrder?.price" 
                                (onValueChanged)="this.slOrder ? this.slOrder.price = $event.value : null"
                                [disabled]="!this.slOrder || !this.slOrder?.usePrice" 
                                [step]="this.tradingInstrument?.tickSize" 
                                [showSpinButtons]="true"
                ></dx-number-box>
              </div>
            </div>
            <div class="column ticks">
              <div class="checkbox">
                <dx-check-box [disabled]="!this.slOrder" [value]="this.slOrder?.usePoints" (onValueChanged)="this.slOrder ? this.slOrder.usePoints = $event.value : null"></dx-check-box>
              </div>
              <div class="value">
                <dx-number-box  [value]="this.slOrder?.points" 
                                (onValueChanged)="this.slOrder ? this.slOrder.points = $event.value : null"
                                [disabled]="!this.slOrder || !this.slOrder?.usePoints" 
                                [step]="this.tradingInstrument?.tickSize" 
                                [showSpinButtons]="true"
                                [min]="0"
                ></dx-number-box>
              </div>
            </div>  
          </div>
        </fieldset>
      </div>
      <div class="buttons-area">
        <dx-button text="Place Orders" width="100px" [disabled]="!canPlaceOrders()" (onClick)="placeOrders()"></dx-button>
        <dx-button text="Cancel" width="80px" (onClick)="onHidden()"></dx-button>
      </div>
    </div>
    <dx-load-panel [visible]="isLoading" [position]="{my: 'center', at: 'center', of: cepopup}"></dx-load-panel>
  </div>
</dx-popup>