import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GetContextMenuItemsParams, GridOptions, MenuItemDef } from 'ag-grid-community';
import { TradingSystemsViewComponent } from './trading-systems-view.component';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   defaultPriceCellFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';

export function getStrategyPositionsGridModel(
   this: TradingSystemsViewComponent,
   displayNameService: TradingInstrumentDisplayNameService
): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Symbol',
            field: 'ticker',
            valueFormatter: (params: ValueFormatterParams) => {
               return displayNameService.getDisplayNameForTicker(params.value);
            },
            cellRenderer: 'agGroupCellRenderer'
         },
         //
         {
            headerName: 'Net Positions',
            field: 'netPosition',
            valueFormatter: defaultNumberCellFormatter
         },
         //
         {
            headerName: 'Avg. Px',
            field: 'avgPx',
            valueFormatter: defaultPriceCellFormatter
         },
         //
         {
            headerName: 'Last Price',
            field: 'lastMatchPx',
            valueFormatter: defaultPriceCellFormatter
         },
         //
         // Object.assign(
         //   { headerName: 'Open P&L', field: 'sessionUnrealizedPnL' },
         //   defaultMoneyCellDefinition
         // ),
         //
         Object.assign(
            { headerName: 'Session P&L', field: 'sessionTotalPnL' },
            defaultMoneyCellDefinition
         ),
         //
         Object.assign(
            { headerName: 'Acc. P&L', field: 'accumulatedTotalPnL' },
            defaultMoneyCellDefinition
         ),
         //
         { headerName: 'Terminal', field: 'terminalCode' },
         //
         { headerName: 'Account', field: 'accountCode' }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.popupParent,

      onGridReady: (args) => this.onStrategyPositionsGridReady(args),

      getRowNodeId: (rowData: PositionDto) => {
         return rowData.positionId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu: Partial<MenuItemDef | string>[] = [];

         if (this.securityContext.archiveStrategyPosition) {
            menu.push(
               {
                  name: 'Archive Position',
                  action: () => this.archivePosition(params.node.data),
                  disabled: !params.node || (params.node.data.netPosition !== 0)
               },
            );
            menu.push('separator');
         }

         menu.push('autoSizeAll', 'copy', 'export');

         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}
