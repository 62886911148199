import { StrategyCommanderRuleModel } from './strategy-commander-rule-model';
import { CommanderHandlersList } from './strategy-commander-rule-editor/handlers/handlers-list';
import { CommanderActionsList } from './strategy-commander-rule-editor/actions/actions-list';
import { 
  TradingInstrumentDisplayNameService 
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { StrategiesService } from '../../../../shared-components/strategies/strategies.service';

//#region Metadata Processors
namespace  Handlers {
  export function movingAverageCrossover(parameters: string, displayNameService: TradingInstrumentDisplayNameService):string {
    if (!parameters) {
      return 'N/A';
    }
    const handlerParams = JSON.parse(parameters);

    const ma1period = handlerParams.ma1Period;
    const ma2period = handlerParams.ma2Period;
    const timeframe =  handlerParams.timeframe === 'D' ? 'daily' : `${handlerParams.timeframe}min`;
    const direction  = handlerParams.direction;
    const ticker = displayNameService.getDisplayNameForTicker(handlerParams.ticker);

    const phrase = `"${ticker}" MA(${ma1period}) crosses ${direction} MA(${ma2period}) on ${timeframe} timeframe`;

    return phrase;
  }
}

namespace Actions {
  export function startStrategiesByFamily(parameters: string): string {
    if (!parameters) {
      return 'N/A';
    }
    const actionParams = JSON.parse(parameters);
    const family = actionParams.family;
    const phrase = `start all "${family}" strategies`;
    return phrase;
  }
  export function startSpecificStrategy(parameters: string, strategiesService: StrategiesService): string {
    if (!parameters) {
      return 'N/A';
    }
    const actionParams = JSON.parse(parameters);
    const strategyId = actionParams.strategyId;
    const strategyModel = strategiesService.getById(strategyId);
    let strategyName = strategyModel ? strategyModel.displayName : 'N/A';
    const phrase = `start "${strategyName}" strategy`;
    return phrase;
  }
  export function exitStrategiesByFamily(parameters: string): string {
    if (!parameters) {
      return 'N/A';
    }
    const actionParams = JSON.parse(parameters);
    const family = actionParams.family;
    const phrase = `exit all "${family}" strategies`;
    return phrase;
  }
  export function exitSpecificStrategy(parameters: string, strategiesService: StrategiesService): string {
    if (!parameters) {
      return 'N/A';
    }
    const actionParams = JSON.parse(parameters);
    const strategyId = actionParams.strategyId;
    const strategyModel = strategiesService.getById(strategyId);
    let strategyName = strategyModel ? strategyModel.displayName : 'N/A';
    const phrase = `exit "${strategyName}" strategy`;
    return phrase;
  }

}
//#endregion

export function getCommanderRuleMetadata(
  dto: StrategyCommanderRuleModel,
  displayNameService: TradingInstrumentDisplayNameService,
  strategiesService: StrategiesService
): string {

  let handlerPhrase = 'N/A';
  switch (dto.handlerTypeId) {
    case CommanderHandlersList.MovingAverageCrossover.id:
      handlerPhrase = Handlers.movingAverageCrossover(dto.handlerParameters, displayNameService);
      break;
  }

  let actionPhrase = '';
  switch (dto.actionTypeId) {
    case CommanderActionsList.StartStrategiesByFamily.id:
      actionPhrase = Actions.startStrategiesByFamily(dto.actionParameters);
      break;
    case CommanderActionsList.StartSpecificStrategy.id:
      actionPhrase = Actions.startSpecificStrategy(dto.actionParameters, strategiesService);
      break;
    case CommanderActionsList.ExitStrategiesByFamily.id:
      actionPhrase = Actions.exitStrategiesByFamily(dto.actionParameters);
      break;
    case CommanderActionsList.ExitSpecificStrategy.id:
      actionPhrase = Actions.exitSpecificStrategy(dto.actionParameters, strategiesService);
      break;
  }

  const description = `When ${handlerPhrase}, then ${actionPhrase}`;

  return description;
}
