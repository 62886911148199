import {ChangeDetectorRef, EventEmitter} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {SessionService} from "projects/shared-components/authentication/session-service.service";
import {TradingInstrument} from "projects/shared-components/trading-instruments/trading-instrument.class";
import {
    areTemplateSettingsValuesEqual,
    DxValueChanged,
    getValueOrNull,
    isNullOrUndefined, isValidNumber,
    isVoid
} from "projects/shared-components/utils";
import {ICashFlowAdjustmentSettingsTemplate} from "../../model/ICashFlowAdjustmentSettingsTemplate";
import {
    ICashFlowStrategyGlobalSettings,
    TheoreticalPriceMode,
    TheoreticalPriceTarget
} from "../../model/ICashFlowStrategyGlobalSettings";
import {
    CashFlowStrategyTemplatesService,
    TemplateSavedEventAgs
} from "../../services/cashflow-strategy-templates.service";
import {takeUntil} from "rxjs/operators";
import {AccessControlService} from "projects/shared-components/access-control-service.class";
import {EtsConstants} from "projects/shared-components/ets-constants.const";
import {ApgPortfolio, DefaultApgPortfolioId} from "../../model/ApgPortfolio";
import {ApplicationSettingsService} from "projects/shared-components/app-settings/application-settings.service";
import {Observable} from 'rxjs';
import {OptionsChainService} from "../../../option-chains.service";
import {PositionsData} from "../../positions-section/model/PositionsData";


export interface CashFlowStrategyGlobalSettingsChangedEvent {
    property: keyof CashFlowStrategyGlobalSettingsModel;
    value: any;
}


interface ContextDataProvider {
    isZonesGridLinked: boolean;
    isOperationInProgress(): boolean;
    getPositions(): PositionsData[];
    isPriceBoxTheoretical(): boolean;
    isZonesGridTheoretical(): boolean;
}


export class CashFlowStrategyGlobalSettingsModel implements ICashFlowStrategyGlobalSettings {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _unsubscriber: Observable<any>,
        private readonly _strategyTemplatesService: CashFlowStrategyTemplatesService,
        private readonly _toastr: ToastrService,
        private readonly _sessionService: SessionService,
        private readonly _accessControlService: AccessControlService,
        private readonly _applicationSettings: ApplicationSettingsService,
        private readonly _optionChainsService: OptionsChainService
    ) {

        this._strategyTemplatesService.templateSaved$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((args: TemplateSavedEventAgs) => {
                this.onTemplateAdded(args);
            });

        this.fillAvailableStrategies();
    }

    private _contextDataProvider: ContextDataProvider;

    private _settingsKeys: (keyof ICashFlowStrategyGlobalSettings)[] = [
        'priceToDestination',
        'priceToOpen',
        'isStrategyAdvancedMode',
        'isDynamicOffsets'
    ];

    parameterChanged$ = new EventEmitter<CashFlowStrategyGlobalSettingsChangedEvent>();

    readonly availableStrategies: CashFlowStrategy[] = [];

    selectedStrategy: CashFlowStrategy;

    selectedPortfolio: ApgPortfolio;

    overrideAtm: number;

    useTheoreticalPrices = false;

    theoreticalPriceIv: number;

    theoreticalPriceMode: TheoreticalPriceMode;

    theoreticalPriceTarget: TheoreticalPriceTarget;

    get isSuperUser(): boolean {
        return this._sessionService.isSuperUser;
    }

    get isOverrideAtmAvailable(): boolean {
        return true;
    }

    get isAdjustToQtyAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('a0b7425e-db38-4926-8e26-a9b8348f666f');
    }


    get strategyTemplates(): ICashFlowAdjustmentSettingsTemplate[] {

        if (isVoid(this.selectedPortfolio)) {
            return [];
        }

        let underlyingToFilter = this.selectedPortfolio.asset;

        let strategyToFilter = this.selectedPortfolio.strategy;

        const isNoPortfolio = this.selectedPortfolio.id === DefaultApgPortfolioId;

        let filteredTemplates = this._strategyTemplatesService.getTemplates();

        if (!isNoPortfolio) {
            filteredTemplates = filteredTemplates
                .filter(x => !isVoid(underlyingToFilter) ? x.underlying === underlyingToFilter : true)
                .filter(x => !isVoid(strategyToFilter) ? x.strategyName === strategyToFilter : true);
        }

        let hasDefaultTemplates: boolean;

        const hasDefaultForSelected = !isVoid(this.selectedTemplate) &&
            filteredTemplates.some(t =>
                this._strategyTemplatesService.isDefaultTemplate(t.templateId) &&
                t.underlying === this.selectedTemplate.underlying &&
                t.strategyName === this.selectedTemplate.strategyName
            );

        const hasDefaultGeneral = filteredTemplates
            .some(t =>
                t.underlying === this._applicationSettings.adjustmentPricingGrid.defaultUnderlying &&
                t.strategyName === this._applicationSettings.adjustmentPricingGrid.defaultStrategy &&
                this._strategyTemplatesService.isDefaultTemplate(t.templateId)
            );


        hasDefaultTemplates = hasDefaultGeneral && hasDefaultForSelected


        if (hasDefaultTemplates || isVoid(this.selectedPortfolio)) {
            return filteredTemplates;
        }

        if (!hasDefaultForSelected) {

            if (!isVoid(this.selectedTemplate)) {

                underlyingToFilter = this.selectedTemplate.underlying;
                strategyToFilter = this.selectedTemplate.strategyName;

                const defaultTemplate = this._strategyTemplatesService.getOrCreateDefaultTemplate(
                    underlyingToFilter,
                    strategyToFilter,
                );

                if (filteredTemplates.findIndex(x => x.templateId === defaultTemplate.templateId) < 0) {
                    filteredTemplates.unshift(defaultTemplate);
                }
            }
        }

        if (!hasDefaultGeneral && filteredTemplates.length === 0) {

            underlyingToFilter = this._applicationSettings.adjustmentPricingGrid.defaultUnderlying;
            strategyToFilter = this._applicationSettings.adjustmentPricingGrid.defaultStrategy;

            const defaultTemplate = this._strategyTemplatesService.getOrCreateDefaultTemplate(
                underlyingToFilter,
                strategyToFilter,
            );

            if (filteredTemplates.findIndex(x => x.templateId === defaultTemplate.templateId) < 0) {
                filteredTemplates.unshift(defaultTemplate);
            }

        }

        return filteredTemplates
            .filter((value, index, self) => !isVoid(value) &&
                index === self.findIndex(t => !isVoid(t) && t.templateId === value.templateId)
            );
    }


    selectedTemplate: ICashFlowAdjustmentSettingsTemplate;


    isStrategyAdvancedMode = false;


    isDynamicOffsets = false;

    private _useAdjustToQty = false;
    get useAdjustToQty(): boolean {
        return this._useAdjustToQty;
    }

    set useAdjustToQty(v: boolean) {
        this._useAdjustToQty = v;
        if (!v) {
            this.adjustToQty = null;
        }
        this.onParameterChanged({value: v, event: 'ets'}, 'useAdjustToQty');
    }


    adjustToQty: number;

    // noinspection JSUnusedGlobalSymbols
    get shouldRollDates(): boolean {
        return this.priceToDestination;
    }


    tradingInstrument: TradingInstrument;


    get underlying(): string {
        return this.tradingInstrument ? this.tradingInstrument.underlying : null;
    }


    get isStrategySelected(): boolean {
        return !isNullOrUndefined(this.selectedStrategy)
    }


    get isSymbolSelected(): boolean {
        return !isNullOrUndefined(this.tradingInstrument);
    }


    get isReadOnlyTemplate(): boolean {
        const isSuperUser = this._sessionService.isSuperUser;

        if (isSuperUser) {
            return false;
        }

        return !isVoid(this.selectedTemplate) && this.selectedTemplate.isShared;
    }


    get canChangeSymbol(): boolean {

        const noPortfolio = isVoid(this.selectedPortfolio);

        const isDefaultPortfolio = this.selectedPortfolio.id === DefaultApgPortfolioId;

        return noPortfolio || isDefaultPortfolio;

    }


    get canChangeStrategy(): boolean {
        return isVoid(this.selectedPortfolio) || this.selectedPortfolio.id === DefaultApgPortfolioId;
    }


    get isDynamicOffsetsAvailable(): boolean {
        return this._accessControlService.isSecureElementAvailable('c184f869-1740-4114-b009-99d5aeba9488');
    }


    priceToDestination = true;


    priceToOpen = false;

    get overrideAtmStyle() : string {

        let style = '';

        if (isValidNumber(this.overrideAtm)) {
            return style;
        }

        const mode = this._applicationSettings.adjustmentPricingGrid.atmWarningMode;

        if (mode !== 'Yellow Background') {
            return style;
        }

        style += 'background: yellow; color: black;';

        return style;
    }


    onParameterChanged(event: DxValueChanged<any>, setting: keyof CashFlowStrategyGlobalSettingsModel) {

        if (setting === 'tradingInstrument') {
            this.tradingInstrument = event.value;

            if (this.selectedTemplate) {
                if (this.selectedTemplate.underlying) {
                    if (event.value) {
                        if (this.selectedTemplate.underlying !== this.tradingInstrument.ticker) {
                            this.selectedTemplate = {} as any;
                        }
                    }
                }
            }
        }

        if (setting === 'selectedStrategy') {
            if (this.selectedTemplate) {
                if (this.selectedTemplate.strategyName) {
                    if (event.value) {
                        if (this.selectedTemplate.strategyName !== event.value) {
                            this.selectedTemplate = {} as any;
                        }
                    }
                }
            }
        }

        if (!event.event) {
            return;
        }

        if (setting.toLowerCase().indexOf('theoretical') >= 0) {

            if (!this.useTheoreticalPrices) {
                this.theoreticalPriceTarget = null;
                this.theoreticalPriceIv = null;
                this.theoreticalPriceMode = null;
            }

            let shouldUpdatePriceBox = false;
            let shouldUpdateZonesGrid = this._contextDataProvider.isZonesGridLinked;

            const isPriceboxTheoretical = this._contextDataProvider.isPriceBoxTheoretical();
            const isZonesGridTheoretical = this._contextDataProvider.isZonesGridTheoretical();

            if (setting === 'useTheoreticalPrices') {
                if (!this.useTheoreticalPrices) {
                    shouldUpdatePriceBox = isPriceboxTheoretical;
                    shouldUpdateZonesGrid = isZonesGridTheoretical;
                } else {
                    if (!isVoid(this.theoreticalPriceTarget)) {
                        shouldUpdatePriceBox = !this.useTheoreticalPrices && isPriceboxTheoretical
                            || this.useTheoreticalPrices && !isPriceboxTheoretical;
                        shouldUpdateZonesGrid = !isZonesGridTheoretical;
                    }
                }
            } else if (setting === 'theoreticalPriceTarget') {
                shouldUpdatePriceBox = this.theoreticalPriceTarget === 'Zones Grid' && isPriceboxTheoretical
                    || this.theoreticalPriceTarget !== 'Zones Grid' && !isPriceboxTheoretical;
                shouldUpdateZonesGrid = shouldUpdateZonesGrid && this.theoreticalPriceTarget === 'Zones Grid' && isZonesGridTheoretical;
            } else if (setting === 'theoreticalPriceIv') {
                if (!isVoid(this.theoreticalPriceTarget)) {
                    shouldUpdatePriceBox = this.theoreticalPriceTarget !== 'Zones Grid';
                }
            } else if (setting === 'theoreticalPriceMode') {
                //
            }

            if (!shouldUpdatePriceBox && !shouldUpdateZonesGrid) {
                this._changeDetector.detectChanges();
                return;
            }
        }

        this.parameterChanged$.emit({
            property: setting,
            value: event.value
        });
    }


    getSettings(): ICashFlowStrategyGlobalSettings {
        return {
            priceToDestination: getValueOrNull(this.priceToDestination),
            priceToOpen: getValueOrNull(this.priceToOpen),
            isStrategyAdvancedMode: getValueOrNull(this.isStrategyAdvancedMode),
            isDynamicOffsets: getValueOrNull(this.isDynamicOffsets),
            adjustToQty: getValueOrNull(this.adjustToQty),
            overrideAtm: getValueOrNull(this.overrideAtm),
            theoreticalPriceMode: this.useTheoreticalPrices ? this.theoreticalPriceMode : null,
            theoreticalPriceTarget: this.useTheoreticalPrices ? this.theoreticalPriceTarget : null,
            theoreticalPriceIv: this.useTheoreticalPrices ? this.theoreticalPriceIv : null,
        }
    }


    async deleteSelectedTemplate(): Promise<void> {

        const template = this.selectedTemplate;

        if (isVoid(template)) {
            this._toastr.error('Please Select Template to Delete', 'Settings Templates');
            return;
        }

        if (template.templateName === 'Default') {
            this._toastr.warning('Default template cannot be deleted', 'Settings Templates');
            return;
        }

        await this._strategyTemplatesService.removeTemplate(template, false);

        const underlying = this.selectedPortfolio.id === DefaultApgPortfolioId
            ? this._applicationSettings.adjustmentPricingGrid.defaultUnderlying
            : this.selectedPortfolio.asset;

        const strategy = this.selectedPortfolio.id === DefaultApgPortfolioId
            ? this._applicationSettings.adjustmentPricingGrid.defaultStrategy
            : this.selectedPortfolio.strategy;

        let defaultTemplate = this._strategyTemplatesService
            .getOrCreateDefaultTemplate(
                underlying,
                strategy
            );

        this.selectedTemplate = defaultTemplate;

        const event = {event: 'ets', value: defaultTemplate, oldValue: template};

        this.onParameterChanged(event, 'selectedTemplate');

        this._toastr.info(
            `Template "${template.templateName}" has been deleted. Default template was loaded instead`,
            'Settings Templates'
        );

    }


    onTemplateAdded(args: TemplateSavedEventAgs) {

        this.selectedTemplate = args.template;

        const pfID = !isVoid(this.selectedPortfolio)
            ? this.selectedPortfolio.id
            : null;

        this._strategyTemplatesService.saveLastUsedTemplate(args.template, pfID);

        const event = {
            value: this.strategyTemplates,
            property: 'strategyTemplates'
        } as CashFlowStrategyGlobalSettingsChangedEvent;

        this.parameterChanged$.emit(event);
    }


    setDefaultTemplate(asset?: string, strategy?: CashFlowStrategy, overrideLastUsed?: boolean) {

        const oldTemplate = this.selectedTemplate;

        const pfId = !isVoid(this.selectedPortfolio)
            ? this.selectedPortfolio.id
            : null;

        const lastUsedTemplateDescriptor = this._strategyTemplatesService
            .getLastUsedTemplate(pfId);

        let foundTemplate = this.strategyTemplates
            .find(x => {
                const matches = x.templateId === lastUsedTemplateDescriptor.templateId &&
                    x.underlying === lastUsedTemplateDescriptor.underlying &&
                    x.strategyName === lastUsedTemplateDescriptor.strategy;

                if (matches) {
                    return x;
                }
            });

        if (isVoid(foundTemplate) || overrideLastUsed) {

            if (!isVoid(lastUsedTemplateDescriptor) && !overrideLastUsed) {
                if (this._strategyTemplatesService.isDefaultTemplate(lastUsedTemplateDescriptor.templateId)) {
                    if (lastUsedTemplateDescriptor.strategy) {
                        strategy = lastUsedTemplateDescriptor.strategy;
                    }
                    if (lastUsedTemplateDescriptor.underlying) {
                        asset = lastUsedTemplateDescriptor.underlying;
                    }
                }
            }

            foundTemplate = this._strategyTemplatesService
                .getOrCreateDefaultTemplate(
                    asset,
                    strategy,
                );

        }

        this.selectedTemplate = foundTemplate;

        this.onParameterChanged({
            event: 'ets',
            previousValue: oldTemplate,
            value: foundTemplate
        }, 'selectedTemplate');
    }


    // noinspection JSUnusedGlobalSymbols
    setStrategy(strategy: CashFlowStrategy) {
        if (this.availableStrategies.indexOf(strategy) < 0) {
            return;
        }

        this.selectedStrategy = strategy;

        this.onParameterChanged({event: 'ets', value: strategy}, 'selectedStrategy');
    }


    applyTemplate(template: ICashFlowAdjustmentSettingsTemplate) {
        this.priceToDestination = template.globalSettings.priceToDestination || false;
        this.priceToOpen = template.globalSettings.priceToOpen || false;
        this.isStrategyAdvancedMode = template.globalSettings.isStrategyAdvancedMode || false;
        this.isDynamicOffsets = template.globalSettings.isDynamicOffsets || false;
        this.overrideAtm = undefined;
    }


    // noinspection JSUnusedGlobalSymbols
    getTemplateName(tpl: ICashFlowAdjustmentSettingsTemplate): string {
        let name = tpl.templateName;
        if (tpl.isModified) {
            name += ' *';
        }
        return name;
    }


    private fillAvailableStrategies() {

        if (this._accessControlService
            .isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.hedgedPortfolioId)
        ) {
            this.availableStrategies.push('Hedged Portfolio');
        }

        if (this._accessControlService
            .isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.reversedHedgedPortfolioId)
        ) {
            this.availableStrategies.push('Reversed Hedged Portfolio');
        }

        if (this._accessControlService
            .isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.callsId)
        ) {
            this.availableStrategies.push('Calls');
        }

        if (this._accessControlService
            .isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.putsId)
        ) {
            this.availableStrategies.push('Puts');
        }

        if (this._accessControlService
            .isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.callsAndPutsId)
        ) {
            this.availableStrategies.push('Calls & Puts');
        }
    }


    onPortfolioSelected(portfolio: ApgPortfolio) {
        this.selectedTemplate = undefined;
        this.selectedPortfolio = portfolio;
    }


    isDifferentFromTemplate(name?: keyof ICashFlowStrategyGlobalSettings): boolean {

        if (this._contextDataProvider.isOperationInProgress()) {
            return false;
        }

        if (isVoid(name)) {

            const differences = this._settingsKeys.map(x => this.isDifferentFromTemplate(x));
            return differences.some(x => x);
        }

        const template = this.selectedTemplate;

        if (isVoid(template)) {
            return false;
        }

        const originalValue = template.globalSettings[name];

        const currentValue = this[name];

        let areEqual = areTemplateSettingsValuesEqual(originalValue, currentValue);

        return !areEqual;
    }


    setContextDataProvider(ctx: ContextDataProvider) {
        this._contextDataProvider = ctx;
    }


    ///////////////////////
    specificIvVisible = false;

    get specificIvExpirations() {
        const exps = this._contextDataProvider.getPositions()
            .flatMap(x => x.positions)
            .map(x => {
                return {
                    expiration: x.selectedExpiration.optionExpirationDate,
                    role: x.role
                };
            });

        return exps;
    }

    showSpecificIv() {
        this.specificIvVisible = true;
    }

    specificIvHidden() {
        this.specificIvVisible = false;
    }
}
