import { GetTerminalWorkingOrders } from '../shell-communication/operations/orders/get-terminal-working-orders.class';
import { CloseAggregatedPosition } from '../shell-communication/operations/aggregated-positions/close-aggregated-position.class';
import { GetAggregatedExitOrders } from '../shell-communication/operations/aggregated-positions/get-aggregated-exit-orders.class';
import { GetAggregatedPositions } from '../shell-communication/operations/aggregated-positions/get-aggregated-positions.class';
import { CancelManualOrders } from '../shell-communication/operations/manual-trading/cancel-manual-orders.class';
import { OrderDto } from '../shell-communication/dtos/order-dto.class';
import { AggregatedPositionDto } from '../shell-communication/dtos/aggregated-position-dto.class';
import { DeleteArchivedPositionByAggregation } from '../shell-communication/shell-operations-protocol';

export abstract class AggregatedPositionsBackendService {
  abstract getTerminalWorkingOrders(qry: GetTerminalWorkingOrders, shellId?: string): Promise<OrderDto[]>;
  abstract closeAggregatedPosition(cmd: CloseAggregatedPosition): Promise<void>;
  abstract getAggregatedExitOrders(qry: GetAggregatedExitOrders, shellId?: string): Promise<OrderDto[]>;
  abstract getAggregatedPositions(qry: GetAggregatedPositions, shellId?: string): Promise<AggregatedPositionDto[]>;
  abstract cancelManualOrders(cmd: CancelManualOrders): Promise<void>;
  abstract deleteArchivedPositionByAggregation(cmd: DeleteArchivedPositionByAggregation): Promise<void>;
}
