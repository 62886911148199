import { ChangeDetectorRef, ComponentFactory, ComponentRef, Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ets-content-placeholder]',
})
export class EtsContentPlaceholderDirective {
   
   createComponent(cmpFactory: ComponentFactory<any>): ComponentRef<any> {
      return this._viewContainerRef.createComponent(cmpFactory);
   }
    
   constructor(
      private _viewContainerRef: ViewContainerRef) {
      }
   
   clear() {
      this._viewContainerRef.clear();
   }
}
