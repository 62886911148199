<dx-popup
  (onHidden)="this.onHidden()"
  [title]="this.title"
  [(visible)]="this.isVisible"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [width]="600"
  [height]="this.height"
>
  <div *dxTemplate="let data of 'content'" class="dialog-root">
    <div class="message">
      <div class="default-qty">
        <span class="label">Default Qty: &nbsp;</span>
        <span class="qty">{{this.defaultQty}}</span>
      </div>
      
      <div class="ledger-price">
        Ledger Price:
        <span [class.debit]="this.ledgerPrice < 0" [class.credit]="this.ledgerPrice > 0">
          {{this.ledgerPrice | currency : 'USD':'symbol':'1.2-2'}}
        </span>
      </div>
    </div>
    
    <hr class="separator">
    
    <dx-scroll-view>
      <div class="order-legs">
        
        <div class="table">
          
          <div class="leg header">
            <div class="cell ticker header">Ticker</div>
            <div class="cell side header">Side</div>
            <div class="cell qty header">Total Qty</div>
            <div class="cell qty header">Effect. Qty</div>
            <div class="cell price header">Price</div>
          </div>
          
          <div class="legs">
            <div class="leg" *ngFor="let leg of this.orderLegs">
              <div class="cell ticker">
                {{leg.tickerDisplayName}}
              </div>
              <div class="cell side" [class.buy]="leg.side === 1" [class.sell]="leg.side === -1">
                {{leg.side === 1 ? 'Buy' : 'Sell' }}
              </div>
              <div class="cell qty">{{leg.netPosition}}</div>
              <div class="cell qty">{{leg.netPosition * leg.side / this.hcf}}</div>
              <div class="cell price" [class.debit]="leg.side === 1" [class.credit]="leg.side === -1">
                {{((leg.price * leg.side * -1 ) | currency:'USD':'symbol':'1.2-2')}}
              </div>
            </div>
          </div>
          
          <div class="leg footer">
            <div class="cell ticker">&nbsp;</div>
            <div class="cell side">&nbsp;</div>
            <div class="cell qty total">&nbsp;</div>
            <div class="cell qty total">x{{this.hcf}}</div>
            <div class="cell price total" [class.debit]="this.transPrice < 0" [class.credit]="this.transPrice > 0">
              {{(this.transPrice | currency:'USD':'symbol':'1.2-2')}}
            </div>
          </div>
          
        </div>
        
        <div class="summary">
          
          <div class="column text">
            <div class="order-placement">
              Order Placement Price
            </div>
            <div class="trans-value">
              Transaction's Market Value
            </div>
          </div>
          
          <div class="column value">
            <div class="order-placement" [class.debit]="this.transMarketValue < 0" [class.credit]="this.transMarketValue > 0">
              {{(this.transPrice | currency:'USD':'symbol':'1.2-2')}}
            </div>
            <div class="trans-value" [class.debit]="this.transMarketValue < 0" [class.credit]="this.transMarketValue > 0">
              {{(this.transMarketValue | currency:'USD':'symbol':'1.2-2')}}
            </div>
          </div>
        
        </div>
        
      </div>
    </dx-scroll-view>
    
    <hr class="separator">
    
    <div class="buttons">
      <div class="btn ok">
        <dx-button (onClick)="this.onOkClicked()"
                   text="Got it! Copy Orders"
                   width="100%"
        ></dx-button>
      </div>
      <div class="btn cancel">
        <dx-button (onClick)="this.onCancelClicked()"
                   text="Cancel"
                   width="100%"
        ></dx-button>
      </div>
    </div>
    
  </div>
</dx-popup>