import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';
import { PutDebitSpreadRollStrategyComponent } from './put-debit-spread-roll/put-debit-spread-roll-strategy.component';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { OrderExtensionsModule } from '../order-extensions/order-extensions.module';

@NgModule({
   declarations: [PutDebitSpreadRollStrategyComponent],
   imports: [
      CommonModule, 
      DevExtremeModule, 
      DateTimePickerModule, 
      TimezonePickerModule,
      OrderExtensionsModule 
   ],
   exports: [PutDebitSpreadRollStrategyComponent],
   providers: [],
})
export class AdjustmentStrategiesModule {}