import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { getStrategyCommanderEditorDefaultParameters, StrategyCommanderEditorParameters } from './default-editor-parameters';
import { StartStrategiesByFamilyComponent } from './actions/start-strategies-by-family.component';
import { MovingAverageCrossoverComponent } from './handlers/moving-average-crossover.component';
import { CommanderActionsList } from './actions/actions-list';
import { CommanderHandlersList } from './handlers/handlers-list';
import { ExitStrategiesByFamilyComponent } from './actions/exit-strategies-by-family.component';
import { StartSpecificStrategyComponent } from './actions/start-specific-strategy.component';
import { ExitSpecificStrategyComponent } from './actions/exit-specific-strategy.component';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategyCommanderRuleDto } from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-dto.interface';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { StrategyCommanderRuleModel } from '../strategy-commander-rule-model';
import {
   StrategyCommanderRuleChangeProgressDto
} from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-change-progress-dto.interface';
import {
   CreateCommanderRule
} from 'projects/shared-components/shell-communication/operations/strategy-commander/create-commander-rule.class';
import {
   UpdateCommanderRule
} from 'projects/shared-components/shell-communication/operations/strategy-commander/update-commander-rule.class';
import Guid from 'devextreme/core/guid';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';



@Component({
   selector: 'ets-strategy-commander-rule-editor',
   templateUrl: 'strategy-commander-rule-editor.component.html',
   styleUrls: ['strategy-commander-rule-editor.component.scss']
})
export class StrategyCommanderRuleEditorComponent implements OnInit, OnDestroy {
   public constructor(
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _tradingInstrumentService: TradingInstrumentsService,
      private _messageBus: MessageBusService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('StrategyCommanderRuleEditorComponent');
   }

   public get selectedHandlerTypes() {
      return this._selectedHandlerTypes;
   }

   public set selectedHandlerTypes(value: any) {
      this._selectedHandlerTypes = value;
   }

   public get selectedActionTypes() {
      return this._selectedActionTypes;
   }

   public set selectedActionTypes(value: any) {
      this._selectedActionTypes = value;
   }

   public get canCreateRule(): boolean {

      if (!this.selectedHandlerTypes || this.selectedHandlerTypes.length === 0) {
         return false;
      }

      if (!this.selectedActionTypes || this.selectedActionTypes.length === 0) {
         return false;
      }

      const selectedHandler = this.selectedHandlerTypes[0];
      const selectedAction = this.selectedActionTypes[0];

      let isHandlerFine: boolean;
      switch (selectedHandler.id) {
         case CommanderHandlersList.MovingAverageCrossover.id:
            isHandlerFine = MovingAverageCrossoverComponent.checkParameters(this.parameters.handlers.maCrossover);
            break;
      }

      if (!isHandlerFine) {
         return false;
      }

      let isActionFine: boolean;
      switch (selectedAction.id) {
         case CommanderActionsList.StartStrategiesByFamily.id:
            isActionFine = StartStrategiesByFamilyComponent.checkParameters(this.parameters.actions.startStrategiesByFamily);
            break;
         case CommanderActionsList.ExitStrategiesByFamily.id:
            isActionFine = ExitStrategiesByFamilyComponent.checkParameters(this.parameters.actions.exitStrategiesByFamily);
            break;
         case CommanderActionsList.StartSpecificStrategy.id:
            isActionFine = StartSpecificStrategyComponent.checkParameters(this.parameters.actions.startSpecificStrategy);
            break;
         case CommanderActionsList.ExitSpecificStrategy.id:
            isActionFine = ExitSpecificStrategyComponent.checkParameters(this.parameters.actions.exitSpecificStrategy);
            break;
      }

      if (!isActionFine) {
         return false;
      }

      if (!this.parameters.selectedInstrument) {
         return false;
      }

      return true;
   }

   public visible: boolean;

   public listOfHandlerTypes = [
      CommanderHandlersList.MovingAverageCrossover
   ];

   public listOfActionTypes = [
      CommanderActionsList.StartStrategiesByFamily,
      CommanderActionsList.ExitStrategiesByFamily,
      CommanderActionsList.StartSpecificStrategy,
      CommanderActionsList.ExitSpecificStrategy,
   ];

   public isLoading: boolean;

   public loadingMessage = 'Loading ...';

   public parameters: StrategyCommanderEditorParameters;

   private _logger: Logger;
   private _selectedHandlerTypes: any = [];
   private _selectedActionTypes: any = [];

   public async ngOnInit(): Promise<void> {
   }

   public show(mode, rule?: StrategyCommanderRuleModel): void {
      this._resetState();

      this.visible = true;

      if (!rule) {
         return;
      }

      this.parameters.mode = mode;

      this._setupRule(rule);
   }

   public ngOnDestroy(): void {
      this._logger.debug('~');
   }

   public async onCreateClicked() {

      const ticker = this.parameters.selectedInstrument.ticker;
      this.parameters.handlers.maCrossover.ticker = ticker;

      const dto: StrategyCommanderRuleDto = {} as any;
      // set common parameters
      dto.handlerTypeId = this.selectedHandlerTypes[0].id;
      dto.handlerName = this.selectedHandlerTypes[0].text;
      dto.actionTypeId = this.selectedActionTypes[0].id;
      dto.actionName = this.selectedActionTypes[0].text;
      dto.strategyCommanderRuleId = this.parameters.originalRuleId;
      dto.isDisabled = this.parameters.isDisabled;
      dto.isRecurring = this.parameters.isRecurring;

      switch (dto.handlerTypeId) {
         case CommanderHandlersList.MovingAverageCrossover.id:
            dto.handlerParameters = MovingAverageCrossoverComponent.getParameters(this.parameters.handlers.maCrossover);
            break;
         default:
            this._toastr.error('Unknown trigger type');
            return;
      }

      switch (dto.actionTypeId) {
         case CommanderActionsList.StartStrategiesByFamily.id:
            dto.actionParameters = StartStrategiesByFamilyComponent.getParameters(this.parameters.actions.startStrategiesByFamily);
            break;
         case CommanderActionsList.ExitStrategiesByFamily.id:
            dto.actionParameters = ExitStrategiesByFamilyComponent.getParameters(this.parameters.actions.exitStrategiesByFamily);
            break;
         case CommanderActionsList.StartSpecificStrategy.id:
            dto.actionParameters = StartSpecificStrategyComponent.getParameters(this.parameters.actions.startSpecificStrategy);
            break;
         case CommanderActionsList.ExitSpecificStrategy.id:
            dto.actionParameters = ExitSpecificStrategyComponent.getParameters(this.parameters.actions.exitSpecificStrategy);
            break;
         default:
            this._toastr.error('Unknown action type');
            return;
      }

      let subscription: Subscription;
      const correlationTicket: string = new Guid().valueOf();
      this.isLoading = true;
      try {
         subscription = this._messageBus.of<StrategyCommanderRuleChangeProgressDto>('StrategyCommanderRuleChangeProgressDto')
            .subscribe(x => {
               this.loadingMessage = x.payload.progressMessage;
               if (x.payload.progressValue === 100 || x.payload.hasErrors) {
                  subscription.unsubscribe();
                  this.isLoading = false;
                  if (x.payload.hasErrors) {
                     this._toastr.error('"Create Strategy Commander Rule" operation completed with errors');
                  }
               }
            });
         const cmd = this.parameters.mode === 'create'
            ? new CreateCommanderRule(dto, correlationTicket)
            : new UpdateCommanderRule(dto, correlationTicket);
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._logger.error('Failed to create commander rule', { error: e.messsage || null });
         this._toastr.error('"Create Strategy Commander Rule" operation completed with errors');
      }
   }

   public onCancelClicked() {
      this.visible = false;
   }

   private _setupRule(rule: StrategyCommanderRuleModel) {
      if (!rule) {
         return;
      }

      // set common parameters
      this.parameters.originalRuleId = rule.strategyCommanderRuleId;
      const action = this.listOfActionTypes.find(x => x.id === rule.actionTypeId);
      const handler = this.listOfHandlerTypes.find(x => x.id === rule.handlerTypeId);
      this.selectedActionTypes = [action];
      this.selectedHandlerTypes = [handler];
      this.parameters.isRecurring = rule.isRecurring;
      this.parameters.isDisabled = rule.isDisabled;

      // set specific parameters
      switch (rule.handlerTypeId) {
         case CommanderHandlersList.MovingAverageCrossover.id:
            MovingAverageCrossoverComponent.fillParameters(rule.handlerParameters, this.parameters, this._tradingInstrumentService);
            break;
         default:
            this._toastr.error('Unknown trigger type');
            break;
      }

      switch (rule.actionTypeId) {
         case CommanderActionsList.StartStrategiesByFamily.id:
            StartStrategiesByFamilyComponent.fillParameters(rule.actionParameters, this.parameters, this._tradingInstrumentService);
            break;
         case CommanderActionsList.ExitStrategiesByFamily.id:
            ExitStrategiesByFamilyComponent.fillParameters(rule.actionParameters, this.parameters, this._tradingInstrumentService);
            break;
         case CommanderActionsList.StartSpecificStrategy.id:
            StartSpecificStrategyComponent.fillParameters(rule.actionParameters, this.parameters, this._tradingInstrumentService);
            break;
         case CommanderActionsList.ExitSpecificStrategy.id:
            ExitSpecificStrategyComponent.fillParameters(rule.actionParameters, this.parameters, this._tradingInstrumentService);
            break;
         default:
            this._toastr.error('Unknown action type');
            break;
      }
   }

   private _resetState() {
      this.loadingMessage = 'Loading ...';

      this.parameters = getStrategyCommanderEditorDefaultParameters();

      this.selectedHandlerTypes = [];
      this.selectedActionTypes = [];
   }
}
