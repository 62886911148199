<div class="column">

   <!-- Symbol -->
  <div class="ets-field">
    <div class="ets-field-label">Symbol</div>
    <div class="ets-field-value">
      <ets-symbol-picker 
          (instrumentSelected)="control.symbol = $event" 
          [selectedInstrument]="this.control.symbol"
      ></ets-symbol-picker>
    </div>
  </div>

   <!-- Multiplier -->
   <div class="ets-field">
      <div class="ets-field-label">Multiplier</div>
      <div class="ets-field-value">
      <dx-select-box [items]="control.multiplierItems"
                     [value]="control.multiplier"
                     (valueChange)="control.multiplier = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.multiplierItems)">
      </dx-select-box>
      </div>
   </div>

   <!-- Triggers -->
   <div class="triggers">
      <fieldset>
         
         <legend>Trading Triggers</legend>
         
         <div class="container">

            <!-- Long -->
            <div class="trigger">
               <div class="label">Long</div>
               <div class="input">
                  <dx-select-box [searchEnabled]="true" 
                                 [items]="this.control.longTriggers" 
                                 [(value)]="this.control.selectedLongTrigger"
                                 [displayExpr]="'displayName'"
                                 [searchExpr]="'displayName'"
                                 [acceptCustomValue]="false"
                                 [showClearButton]="true"
                                 fieldTemplate="field">

                     <div *dxTemplate="let data of 'field'">
                        <dx-text-box   [value]="data &&  (data?.displayName + '@' + data?.vendorName)"
                                       [class.disapproved-trigger]="data && !data.isApproved"
                                       [class.approved-trigger]="!data || data.isApproved"
                                       >
                        </dx-text-box>
                     </div>
                     
                     <div *dxTemplate="let data of 'item'">
                        <span [class.approved-trigger]="data?.isApproved"
                              [class.disapproved-trigger]="!data?.isApproved">
                              {{data &&  (data?.displayName + '@' + data?.vendorName)}}
                        </span>
                     </div>
                  </dx-select-box>
               </div>
            </div>

            <!-- Close Long -->
            <div class="trigger">
               <div class="label">Close Long</div>
               <div class="input">
                  <dx-select-box [searchEnabled]="true" 
                                 [items]="this.control.closeLongTriggers" 
                                 [(value)]="this.control.selectedCloseLongTrigger"
                                 [displayExpr]="'displayName'"
                                 [searchExpr]="'displayName'" 
                                 [showClearButton]="true"
                                 [acceptCustomValue]="false"
                                 fieldTemplate="field">
                     <div *dxTemplate="let data of 'field'">
                        <dx-text-box   [value]="data &&  (data?.displayName + '@' + data?.vendorName)"
                                       [class.disapproved-trigger]="data && !data.isApproved"
                                       [class.approved-trigger]="!data || data.isApproved">
                        </dx-text-box>
                     </div>
                     
                     <div *dxTemplate="let data of 'item'">
                        <span [class.approved-trigger]="data?.isApproved"
                              [class.disapproved-trigger]="!data?.isApproved">
                              {{data &&  (data?.displayName + '@' + data?.vendorName)}}
                        </span>
                     </div>
                  </dx-select-box>
               </div>
            </div>

            <!-- Short -->
            <div class="trigger">
               <div class="label">
                  Short
               </div>
               <div class="input">
                  <dx-select-box [searchEnabled]="true" 
                                 [items]="this.control.shortTriggers" 
                                 [(value)]="this.control.selectedShortTrigger"
                                 [displayExpr]="'displayName'"
                                 [searchExpr]="'displayName'" 
                                 [showClearButton]="true"
                                 [acceptCustomValue]="false"
                                 fieldTemplate="field">
                                 <div *dxTemplate="let data of 'field'">
                                    <dx-text-box   [value]="data &&  (data?.displayName + '@' + data?.vendorName)"
                                                   [class.disapproved-trigger]="data && !data.isApproved"
                                                   [class.approved-trigger]="!data || data.isApproved">
                                    </dx-text-box>
                                 </div>
                                 
                                 <div *dxTemplate="let data of 'item'">
                                    <span [class.approved-trigger]="data?.isApproved"
                                          [class.disapproved-trigger]="!data?.isApproved">
                                          {{data &&  (data?.displayName + '@' + data?.vendorName)}}
                                    </span>
                                 </div>
                  </dx-select-box>
               </div>
            </div>

            <!-- Close Short -->
            <div class="trigger">
               <div class="label">
                  Close Short
               </div>
               <div class="input">
                  <dx-select-box [searchEnabled]="true" 
                                 [items]="this.control.closeShortTriggers" 
                                 [(value)]="this.control.selectedCloseShortTrigger"
                                 [displayExpr]="'displayName'"
                                 [searchExpr]="'displayName'" 
                                 [showClearButton]="true"
                                 [acceptCustomValue]="false"
                                 fieldTemplate="field">
                                 <div *dxTemplate="let data of 'field'">
                                    <dx-text-box   [value]="data &&  (data?.displayName + '@' + data?.vendorName)"
                                                   [class.disapproved-trigger]="data && !data.isApproved"
                                                   [class.approved-trigger]="!data || data.isApproved"
                                                   >
                                    </dx-text-box>
                                 </div>
                                 
                                 <div *dxTemplate="let data of 'item'">
                                    <span [class.approved-trigger]="data?.isApproved"
                                          [class.disapproved-trigger]="!data?.isApproved">
                                          {{data &&  (data?.displayName + '@' + data?.vendorName)}}
                                    </span>
                                 </div>
                  </dx-select-box>
               </div>
            </div>
         </div>
      </fieldset>
   </div>

</div>
