import { NgModule } from '@angular/core';
import { OriginalFlagshipReportComponent } from './original-flagship/original-flagship-report.component';
import { AgGridModule } from 'ag-grid-angular';
import { NewFlagshipReportComponent } from './new-flagship/new-flagship-report.component';
import { DispositionReportComponent } from './disposition/disposition-report.component';
import { EtsSystemDetailsModule } from '../../../../shared-components/system-details/system-details.module';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    EtsSystemDetailsModule,
    DevExtremeModule
  ],
  exports: [
    OriginalFlagshipReportComponent,
    NewFlagshipReportComponent,
    DispositionReportComponent
  ],
  declarations: [
    OriginalFlagshipReportComponent,
    NewFlagshipReportComponent,
    DispositionReportComponent
  ],
  providers: []
})
export class EtsDispositionReportsModule { }
