// tslint:disable: no-string-literal

import { ParametersControlBase } from '../../parameters-control-base';
import { ICommonDispositionParameters } from '../../common-disposition-parameters';
import {
  TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { AlgoMetadataService } from '../../../algo/algo-metadata.service';
import { getInnerStrategiesGridModel } from './inner-strategy-grid-model';
import { Output, EventEmitter, Directive } from '@angular/core';
import { DialogConfig } from '../../dialog-config';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { OrderConcealStrategy } from './order-conceal-strategy';
import { DispositionType } from '../../disposition-type';
import { StrategyKind } from 'projects/shared-components/strategies/strategy-kind.enum';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { isTruthy } from 'projects/shared-components/utils';
import { isNullOrUndefined } from 'util';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class DispositionParametersControlBase<T> extends ParametersControlBase<T> implements ICommonDispositionParameters {

  protected constructor(
    protected readonly _TradingInstrumentsService: TradingInstrumentsService,
    protected readonly _Toastr: ToastrService,
    protected readonly _algoMetadataService: AlgoMetadataService
  ) {
    super(_TradingInstrumentsService, _Toastr);

    this.innerStrategiesGridModel = getInnerStrategiesGridModel.bind(this)(
      this._algoMetadataService
    );
  }

  @Output() showEditor = new EventEmitter<DialogConfig>();
  readonly orderConcealStrategyItems = [ 
    { displayName: 'Combine', strategy: OrderConcealStrategy.Combine }, 
    { displayName: 'Show All', strategy: OrderConcealStrategy.ShowAll } 
  ];
  innerStrategiesGridModel: GridOptions;
  innerStrategies: StrategyDto[] = [];
  orderConcealStrategy: OrderConcealStrategy;
  isTradeThroughProxy = false;
  isTradeThroughStop = false;
  isTradeThroughMIT = false;
  abstract dispositionType: DispositionType;
  abstract fallbackCtlValue: number;

  abstract get symbol(): TradingInstrument;
  abstract set symbol(value: TradingInstrument);

  protected _InnserStrategiesGrid: GridReadyEvent;

  onInnerStrategiesGridReady(args: GridReadyEvent): void {
    this._InnserStrategiesGrid = args;
    if (this.innerStrategies && this.innerStrategies.length > 0) {
      this._InnserStrategiesGrid.api.setRowData(this.innerStrategies);
    }
  }

  abstract onSymbolChanged(old: TradingInstrument, value: TradingInstrument): void;

  validateSpecificParameters(validationContext: any): string[] {
    const errors: string[] = [];
  
    if (isNullOrUndefined(this.orderConcealStrategy)) {
      errors.push('Must specify order conceal strategy');
    }
    
    return errors;
  }

  setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean) {
  }

  showInnerStrategyEditor(strategyDto: StrategyDto, mode: string) {
    const strategy = JSON.parse(JSON.stringify(strategyDto));

    const config: DialogConfig = {
      strategy,
      isUpdate: mode === 'update',
      strategyKind: StrategyKind.Disposition,
      editMode: 'deferred',
      commonDispositionParams: this,
      source: 'inner'
    };

    this.showEditor.emit(config);
  }

  addInnerStrategy(dto: StrategyDto) {
    this.innerStrategies.push(dto);
    this._InnserStrategiesGrid.api.setRowData(this.innerStrategies);
  }

  updateInnerStrategy(dto: StrategyDto) {
    const ix = this.innerStrategies.findIndex(x => x.strategyId === dto.strategyId);
    if (ix >= 0) {
      this.innerStrategies[ix] = dto;
    }
    this._InnserStrategiesGrid.api.applyTransaction({ update: [dto] });
  }

  removeInnerStrategy(dto: StrategyDto) {
    const ix = this.innerStrategies.findIndex(x => x === dto);
    if (ix >= 0) {
      this.innerStrategies.splice(ix, 1);
      this._InnserStrategiesGrid.api.setRowData(this.innerStrategies);
    }
  }

  protected _GetParameters(): StrategyParameters {
    const params: StrategyParameters = {};

    params.orderconcealstrategy = !isNullOrUndefined(this.orderConcealStrategy) ? this.orderConcealStrategy + '' : null;
    params.istradethroughproxy = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
    params.istradethroughmit = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;
    params.istradethroughstop = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;

    this.getParametersSpecific(params);

    return params;
  }

  protected abstract getParametersSpecific(params: StrategyParameters);

  protected _SetParameters(strategy: StrategyDto) {
    const params = {};

    Object.keys(strategy.parameters)
      .forEach(key => params[key.toLowerCase()] = strategy.parameters[key]);

    if (isTruthy(params['istradethroughproxy'])) {
      this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
    }
    if (isTruthy(params['istradethroughstop'])) {
      this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
    }
    if (isTruthy(params['istradethroughmit'])) {
      this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
    }
    if (!isNullOrUndefined(params['orderconcealstrategy'])) {
      this.orderConcealStrategy = parseInt(params['orderconcealstrategy']);
    }


    this.setParametersSpecific(params);

    if (strategy.dispositionStrategies
      && strategy.dispositionStrategies.length > 0) {
      const strats = strategy.dispositionStrategies.slice();
      this.innerStrategies.push(...strats);
    }
  }

  protected abstract setParametersSpecific(params: StrategyParameters);
}
