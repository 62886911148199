<div class="empty" *ngIf="!this.viewModel">
   <p>N/A</p>
</div>

<table [ngStyle]="{
                     'background-color': this.mode !== 'sideblock' ? this.viewModel.bgColor : 'inherit', 
                     'font-size': this.mode === 'sideblock' ? 'larger' : 'inherit' 
                  }" *ngIf="this.viewModel">
   <tbody>

      <tr>
         <td class="parameter">Long Leg:</td>
         <td class="value">
            <div class="leg-descriptor">
               <div>{{this.viewModel.longLegQty}}</div>
               <div>{{this.viewModel.longLegAsset}}</div> 
               <div>{{this.viewModel.longLegExpiration}}</div>
               <div>{{this.viewModel.longLegDaysToExpiration}} days left</div>
            </div>
         </td>
      </tr>

      <tr>
         <td class="parameter">Short Leg:</td>
         <td class="value">   
            <div class="leg-descriptor">
               <div>{{this.viewModel.shortLegQty}}</div>
               <div>{{this.viewModel.shortLegAsset}}</div> 
               <div>{{this.viewModel.shortLegExpiration}}</div>
               <div>{{this.viewModel.shortLegDaysToExpiration}} days left</div>
            </div>
         </td>
      </tr>
   </tbody>
</table>
