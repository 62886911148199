import { CashFlowStrategy } from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import { CashFlowStrategyAdjustmentType } from "./CashFlowStrategyAdjustmentType";
import { CashFlowAdjustment } from "./CashFlowAdjustment";
import * as Enumerable from "linq";


export class CashFlowStrategyAdjustmentsObject {
   cashFlowStrategy: CashFlowStrategy;
   underlying: string;
   initialStrike: string;

   adjustmentTypes: CashFlowStrategyAdjustmentType[];
   columnWidth?: string;

   setData(data: CashFlowAdjustment[]) {
      if (data.length === 0) {
         return;
      }

      this.cashFlowStrategy = data[0].cashFlowStrategy;
      
      const anyAdjustment = data.find(x => x.underlying && x.initialStrike);
      
      if (anyAdjustment) {
         this.underlying = anyAdjustment.underlying;
         this.initialStrike = anyAdjustment.initialStrike;
      }
      
      const adjTypes = Enumerable.from(data)
         .groupBy(x => x.adjustmentType)
         .select(grp => {
            const type = new CashFlowStrategyAdjustmentType();
            type.setData(grp.toArray());
            return type;
         })
         .toArray();
      
      this.adjustmentTypes = adjTypes;
   }
}
