export class StrategyScheduleParameters {
  startTime: Date;
  stopTime: Date;
  exitTime: Date;
  country: string;
  timeZone: string;
  beginDate: Date;
  endDate: Date;
  isRecurring: boolean;
  isAutostart: boolean;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
}
