<div class="na" *ngIf="!this.hasSettings">
   <p>N/A</p>
</div>

<div  class="table-wrapper"
      *ngIf="this.hasSettings"
      >
   <table>
      <tbody>
         <tr>
            <td class="parameter">Amount</td>
            <td class="value">{{this.amount | currency}}</td>
         </tr>
         <tr>
            <td class="parameter">Interest Rate</td>
            <td class="value">{{ this.interestRate | percent:'1.2-2' }}</td>
         </tr>
         <tr>
            <td class="parameter">Charge Interval</td>
            <td class="value">{{ this.interval }}</td>
         </tr>
         <tr>
            <td class="parameter">Charge Time</td>
            <td class="value">{{ this.chargeTime }}</td>
         </tr>
         <tr>
            <td class="parameter">Charge Timezone</td>
            <td class="value">{{ this.chargeTimezone }}</td>
         </tr>
         <tr>
            <td class="parameter">Account</td>
            <td class="value">{{ this.account }}</td>
         </tr>
      </tbody>
   </table>
</div>

