import { StrategyState } from './strategy-state.enum';
import { StrategyKind } from './strategy-kind.enum';
import { StrategyScheduleParameters } from './strategy-schedule-parameters.class';
import { StrategyTradingObjectives } from './strategy-trading-objectives.class';
import { StrategyParameters } from './strategy-parameters.enum';
import { StrategyPositionModel } from './strategy-position-model.class';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { StrategyDto } from '../shell-communication/dtos/strategy-dto.class';
import { Transform, Type } from 'class-transformer';
import { StrategiesSubscription } from './strategies-subscription.class';
import { StrategyFlags } from './strategy-flags.enum';
import { StrategyTriggers } from './strategy-triggers.class';
import { StrategyPositionSizing } from './strategy-position-sizing.class';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { PositionsCollection } from './positions-collection.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { EtsConstants, isDisposition } from '../ets-constants.const';
import { isTruthy, isInstrumentAboutToExpire } from '../utils';
import { isNullOrUndefined } from 'util';

export function typeStrategyModel() {
   // tslint:disable-next-line: no-use-before-declare
   return StrategyModel;
}

export function typePositionModel() {
   return StrategyPositionModel;
}

export function typePositionCollection() {
   return PositionsCollection;
}

export function typeStrategyScheduleParameters() {
   return StrategyScheduleParameters;
}

export function typeStrategyTradingObjectives() {
   return StrategyTradingObjectives;
}

export function typeStrategyTriggers() {
   return StrategyTriggers;
}

export function typePositionSizing() {
   return StrategyPositionSizing;
}

export function getJSONObjectFromString(value) {
   return value ? JSON.parse(value) : value;
}

export function makePositionsCollection(value: PositionDto[]) {
   const pc = new PositionsCollection();
   value.forEach(p => pc.addFromDto(p));
   return pc;
}

export class StrategyModel {
   constructor() {
      this.dispositionStrategies = [];
      this.positions = new PositionsCollection();
      this.observers = [];
      this.hedgedPositions = [];
   }

   private readonly observers: StrategiesSubscription[];
   private strategyState: StrategyState;


   strategyId: string;
   algoId: string;
   strategyKind: StrategyKind;
   displayName: string;
   mode: string;
   algo: string;
   description: string;

   @Type(typeStrategyScheduleParameters)
   @Transform(getJSONObjectFromString)
   scheduleParameters: StrategyScheduleParameters;

   @Type(typeStrategyTradingObjectives)
   @Transform(getJSONObjectFromString)
   tradingObjectives: StrategyTradingObjectives;

   @Type(typeStrategyTriggers)
   @Transform(getJSONObjectFromString)
   triggers: StrategyTriggers;

   @Type(typePositionSizing)
   @Transform(getJSONObjectFromString)
   positionSizing: StrategyPositionSizing;

   autostopping: boolean;
   isSession: boolean;
   tags: string;
   isExpired: boolean;
   parameters: StrategyParameters;

   @Type(typeStrategyModel)
   dispositionStrategies: StrategyModel[];
   terminalId: string;
   terminalCode: string;
   unitsAllocated: number;
   status: string;
   stateString: string;

   @Transform(makePositionsCollection)
   positions: PositionsCollection;
   netPosition: number;
   avgPx: number;
   sessionTotalPnL: number;
   accumulatedTotalPnL: number;
   sessionUnrealizedPnL: number;
   hedgedPositionsSessionTotalPnL: number;
   hedgedPositionsAccumulatedTotalPnL: number;
   hedgedPositionsAessionUnrealizedPnL: number;
   brokerage: string;
   lastPx: number;
   flags: number;
   numberOfCommanderRules: number;

   shellId: string;
   clientId: string;
   shellName: string;
   clientName: string;

   flagsData: string;

   lastMarketQuote: number;
   tradePnL: number;

   dispositionId: string;

   isAboutToExpire: boolean;

   portfolioId: string;
   portfolioName: string;
   comboId: string;
   comboName: string;
   comboGroupId: string;
   comboGroupName: string;

   hedgedPositions: PositionDto[];

   positionSizingSource: 'None' | 'Self' | 'Disposition' | 'Bucket';

   openPrice: number;

   isArchived: boolean;

   liveQuote: number;

   hasBeenActiveDuringTheSession: boolean;

   get seqNum(): number {
      return this.positions.seqNum;
   }

   get isDisposition(): boolean {
      return isDisposition(this.algoId);
   }

   get isInnerStrategy(): boolean {
      return !isNullOrUndefined(this.dispositionId);
   }

   get state(): StrategyState {
      return this.strategyState;
   }

   set state(v: StrategyState) {
      this.strategyState = v;
      this.stateString = StrategyState[v];
   }

   get delta(): number {
      const d = this.positions.getPositions()
         .map(p => p.delta)
         .reduce( (p, c) => p + c, 0);

      return d;
   }

   get hasTriggers(): boolean {
      if (!this.triggers) {
         return false;
      }
      
      if (this.triggers.filterTriggers && this.triggers.filterTriggers.length > 0) {
         return true;
      }

      if (this.triggers.conditionTriggers && this.triggers.conditionTriggers.length > 0) {
         return true;
      }

      if (this.triggers.entryTriggers && this.triggers.entryTriggers.length > 0) {
         return true;
      }
      
      if (this.triggers.exitTriggers && this.triggers.exitTriggers.length > 0) {
         return true;
      }

      return false;
   }

   setMode(): void {
      const sched: Partial<StrategyScheduleParameters> = this.scheduleParameters || {};
      if (!sched.isAutostart) {
         this.mode = 'Manual';
         return;
      }
      this.mode = 'Auto';
   }

   setAlgo(service: AlgoMetadataService): void {
      this.algo = service
         .getAlgoDescriptionBuilder(this.algoId)
         .getAlgoName(this.parameters);
   }

   setDescription(service: AlgoMetadataService): void {
      this.description = service
         .getAlgoDescriptionBuilder(this.algoId)
         .getDescription(this.parameters);
   }

   setNetPosition(): void {
      this.netPosition = this.positions.getNetPosition();
   }

   setOpenPrice(): void {
      this.openPrice = this.positions.getNetPosition();
   }

   setAvgPx(): void {
      this.avgPx = this.positions.getAvgPx();
   }

   setSessionTotalPnL(): void {
      this.sessionTotalPnL = this.positions.getSessionTotalPnL();
      const hedged = this.hedgedPositions
         .map( x => x.sessionTotalPnL)
         .reduce( (p, c) => p + c, 0);
      this.hedgedPositionsSessionTotalPnL = this.sessionTotalPnL + hedged;
   }

   setAccumulatedTotalPnL(): void {
      this.accumulatedTotalPnL = this.positions.getAccumulatedTotalPnL();
      const hedged = this.hedgedPositions
         .map( x => x.accumulatedTotalPnL)
         .reduce( (p, c) => p + c, 0);
      this.hedgedPositionsAccumulatedTotalPnL = this.accumulatedTotalPnL + hedged;
   }

   setSessionUnrealizedPnL(): void {
      this.sessionUnrealizedPnL = this.positions.getSessionUnrealizedPnL();
      const hedged = this.hedgedPositions
         .map( x => x.sessionUnrealizedPnL)
         .reduce( (p, c) => p + c, 0);
      this.hedgedPositionsAessionUnrealizedPnL = this.sessionUnrealizedPnL + hedged;
   }

   setBrokerage(): void {
      this.brokerage = this.positions.getBrokerage();
   }

   setLastPx(): void {
      this.lastPx = this.positions.getLastPx();
   }

   onParametersUpdated(dto: StrategyDto, metadataService: AlgoMetadataService): void {
      this.autostopping = dto.autostopping;
      this.displayName = dto.displayName;
      this.tags = dto.tags;
      this.unitsAllocated = dto.unitsAllocated;
      this.parameters = dto.parameters;

      this.tradingObjectives = JSON.parse(
         dto.tradingObjectives
      ) as StrategyTradingObjectives;

      this.scheduleParameters = JSON.parse(
         dto.scheduleParameters
      ) as StrategyScheduleParameters;


      this.triggers = JSON.parse(dto.triggers) as StrategyTriggers;

      this.positionSizing = JSON.parse(dto.positionSizing) as StrategyPositionSizing;

      this.isAboutToExpire = dto.isAboutToExpire;

      this.updateMetadata(metadataService);
   }

   updateMetadata(metadataService: AlgoMetadataService) {
      this.setAlgo(metadataService);
      this.setDescription(metadataService);
      this.setMode();
      if (this.dispositionStrategies && this.dispositionStrategies.length) {
         this.dispositionStrategies.forEach(x => x.updateMetadata(metadataService));
      }
   }

   updateFinancialProperties(): void {
      this.setNetPosition();
      this.setAccumulatedTotalPnL();
      this.setAvgPx();
      this.setBrokerage();
      this.setLastPx();
      this.setSessionTotalPnL();
      this.setSessionUnrealizedPnL();
      this.setOpenPrice();
   }

   updateAllProperties(metadataService: AlgoMetadataService): void {
      this.updateFinancialProperties();
      this.updateMetadata(metadataService);
      this.updateFlags();
   }

   updateFlags(): void {
      const flags: string[] = [];
      Object.keys(StrategyFlags).forEach(flag => {
         if (flag === 'None') { return; }

         if (this.hasFlag(StrategyFlags[flag])) {
            flags.push(flag);
         }
      });
      this.flagsData = flags.join(',');
   }

   addObserver(obs: StrategiesSubscription) {
      if (!this.observers.includes(obs)) {
         this.observers.push(obs);
      }
   }

   removeObserver(obs): void {
      const ix = this.observers.indexOf(obs);
      if (ix >= 0) {
         this.observers.splice(ix, 1);
      }
   }

   notifyObservers(notificationParams: ObserversNotificationParams): void {
      const self = this;
      this.observers.forEach(x => setTimeout(() => x.refreshView(self, notificationParams), 0));
   }

   hasFlag(flag: StrategyFlags): boolean {
      return (this.flags & flag) === flag;
   }

   hasRiskFlags(): boolean {
      return (this.flags & StrategyFlags.AccumulatedTO) === StrategyFlags.AccumulatedTO
         || (this.flags & StrategyFlags.SessionTO) === StrategyFlags.SessionTO
         || (this.flags & StrategyFlags.RiskRuleViolation) === StrategyFlags.RiskRuleViolation;
   }

   getTradingInstruments(): string[] {
      const tickers = [];
      if (this.isDisposition) {
         this.dispositionStrategies.forEach(inner => {
            const tis = inner.getTradingInstruments();
            tis.forEach(ti => {
               const ix = tickers.indexOf(ti);
               if (ix === -1) {
                  tickers.push(ti);
               }
            });
         });
      } else {
         // tslint:disable-next-line: no-string-literal
         const ticker = this.parameters['symbol'];
         tickers.push(ticker);
      }

      return tickers;
   }

   private _checkIfAboutToExpire(strategy: StrategyModel, tiSvc: TradingInstrumentsService, metadataService: AlgoMetadataService): boolean {
      let result = false;

      if (strategy.algoId === EtsConstants.algorithms.fusionAlgoId) {
         strategy.dispositionStrategies.forEach(is => {
            const isAboutToExpire = this._checkIfAboutToExpire(is, tiSvc, metadataService);
            if (isAboutToExpire) {
               result = isAboutToExpire;
            }
         });
      } else {
         const builder = metadataService.getAlgoDescriptionBuilder(strategy.algoId);
         const symbolProps = builder.getSymbolPropertyNames();
         symbolProps.forEach(prop => {
            const value = strategy.parameters[prop];
            if (isTruthy(value)) {
               const ti = tiSvc.getInstrumentByTicker(value);
               if (ti) {
                  const isAboutToExpire = isInstrumentAboutToExpire(ti);
                  if (isAboutToExpire) {
                     result = isAboutToExpire;
                  }
               } else {
                  result = true;
               }
            }
         });
      }

      return result;
   }
}

export interface ObserversNotificationParams {
   affectsGroupSortOrFilter?: boolean;
   redrawRows?: boolean;
   refreshCells?: boolean;
}
