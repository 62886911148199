<div [ngClass]="this.cssClassForMainContainer">
   <ng-container *ngTemplateOutlet="this.templateOutlet">
   </ng-container>    
</div>

<ng-template #autoSingle>

   <fieldset [ngClass]="this.cssClassForParametersContainer" >

      <legend *ngIf="this.isMultiDirection">{{this.strategy}}</legend>

     <!-- Spread Offset -->
     <div class="parameter spread-offset">
         <div class="label">
            Spread Offset
         </div>
         <div class="input">
            <dx-number-box 
               [min]="0"
               [(value)]="this.parameters.spreadOffset"
               [showClearButton]="true" 
               [showSpinButtons]="true" 
               [useLargeSpinButtons]="true"
               >
            </dx-number-box>
         </div>
     </div>

      <div class="parameter separator">
         <div class="label">&nbsp;</div>
         <div class="input">
            <hr class="dash">
         </div>
      </div>

      <!-- Roll [x] Days Before Expiration -->
      <div class="parameter roll-x-days-before-expiration">
         <div class="label">
            Roll [x] Days Before
         </div>
         <div class="input">
            <dx-number-box
               [min]="0"
               [(value)]="this.parameters.rollXDaysBeforeExpiration"
               [showClearButton]="true" 
               [showSpinButtons]="true" 
               [useLargeSpinButtons]="true"
               >
            </dx-number-box>
         </div>
      </div>
      
      <div class="parameter separator">
         <div class="label">&nbsp;</div>
         <div class="input">
            <hr class="dash">
         </div>
      </div>

      <!-- Roll Days To Expiration -->
      <div class="parameter roll-days-to-expiration">
         <div class="label">
            Roll Days To Expiration
         </div>
         <div class="input">
            <dx-number-box
               [min]="0" 
               [(value)]="this.parameters.rollDaysToExpiration"
               [showClearButton]="true" 
               [showSpinButtons]="true" 
               [useLargeSpinButtons]="true"
               >
            </dx-number-box>
         </div>
      </div>

   </fieldset>
</ng-template>


<ng-template #autoDouble>
         
   <div class="common">
      <fieldset class="parameters multi">
         <legend>Common</legend>
         
         <!-- Roll [x] Days Before Epxiration -->
         <div class="parameter roll-x-days-before-expiration">
            <div class="label">
               Roll [x] Days Before
            </div>
            <div class="input">
               <dx-number-box 
                  [min]="0"
                  [(value)]="this.parameters.rollXDaysBeforeExpiration"
                  [showClearButton]="true" 
                  [showSpinButtons]="true" 
                  [useLargeSpinButtons]="true"
                  (onValueChanged)="this.onParameterChanged('rollXDaysBeforeExpiration')"
                  >
               </dx-number-box>
            </div>
         </div>

         <div class="parameter separator">
            <div class="label">&nbsp;</div>
            <div class="input">
               <hr class="dash">
            </div>
         </div>

         <!-- Roll Days To Expiration -->
         <div class="parameter roll-days-to-expiration">
            <div class="label">
               Roll Days To Expiration
            </div>
            <div class="input">
               <dx-number-box
                  [min]="0"
                  [(value)]="this.parameters.rollDaysToExpiration"
                  [showClearButton]="true"
                  [showSpinButtons]="true" 
                  [useLargeSpinButtons]="true"
                  (onValueChanged)="this.onParameterChanged('rollDaysToExpiration')"
                  >
               </dx-number-box>
            </div>
         </div>
      </fieldset>

   </div>

   <div class="individual">
                  
         <fieldset class="parameters multi">
            <legend>Calls</legend>

            <!-- Spread Offset -->
            <div class="parameter spread-offset">
               <div class="label">
                  Spread Offset
               </div>
               <div class="input">
                  <dx-number-box 
                     [min]="0"
                     [(value)]="this.parameters.spreadOffset"
                     [showClearButton]="true" 
                     [showSpinButtons]="true" 
                     [useLargeSpinButtons]="true"
                     >
                  </dx-number-box>
               </div>
            </div>
            
         </fieldset>


         <fieldset class="parameters multi">
            <legend>Puts</legend>

            <!-- Spread Offset -->
            <div class="parameter spread-offset">
               <div class="label">
                  Spread Offset
               </div>
               <div class="input">
                  <dx-number-box 
                     [min]="0"
                     [(value)]="this.parameters2.spreadOffset"
                     [showClearButton]="true" 
                     [showSpinButtons]="true" 
                     [useLargeSpinButtons]="true"
                     >
                  </dx-number-box>
               </div>
            </div>

         </fieldset>

   </div>

</ng-template>
