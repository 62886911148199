<dx-popup
  (onHidden)="this.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [shading]="false"
  [showTitle]="true"
  [visible]="this.visible"
  height="480"
  title="Hedge Matrix Columns"
  width="630"
>
  <div *dxTemplate="let data of 'content'">
    <div class="root">
      <dx-scroll-view>
        <div class="container">
          
          <div class="side left">
            
            <div class="header">Pricings</div>
            
            <div class="section">
              <dx-check-box (onValueChanged)="this.toggleSelectAllHedges($event)"
                            [value]="this.allHedgesSelected"
                            text="Select All Hedges">
              </dx-check-box>
            </div>
            
            <!-- Hedge Tpl -->
            <ng-template #hedge let-hedge>
              <div class="hedge-item">
                <div class="checkbox">
                  <dx-check-box (onValueChanged)="this.onHedgeSelectionChanged($event, hedge)"
                                [value]="this.isHedgeSelected(hedge)">
                  </dx-check-box>
                </div>
                <div class="label">
                  {{ hedge.label }}
                </div>
              </div>
            </ng-template>
            
            <!-- Expiration Tpl -->
            <ng-template #expiration let-expiration>
              <div class="expiration-item">
                <div class="checkbox">
                  <dx-check-box (onValueChanged)="this.onExpirationSelectionChanged($event, expiration)"
                                [value]="this.isExpirationSelected(expiration)">
                  </dx-check-box>
                </div>
                <div class="label">
                  {{ expiration.expirationFull }}
                </div>
              </div>
            </ng-template>
            
            <div class="section hedge">
              <div class="title">Call Hedges</div>
              <div class="selector">
                <ng-container *ngFor="let cHedge of this.callHedgeList">
                  <ng-container *ngTemplateOutlet="hedge; context: {$implicit: cHedge}"></ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section hedge">
              <div class="title">Put Hedges</div>
              <div class="selector">
                <ng-container *ngFor="let cHedge of this.putHedgeList">
                  <ng-container *ngTemplateOutlet="hedge; context: {$implicit: cHedge}"></ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="row">
              <hr>
            </div>
            
            <div class="section">
              <dx-check-box (onValueChanged)="this.toggleSelectAllExpirations($event)"
                            [value]="this.allExpirationsSelected"
                            text="Select All Expirations">
              </dx-check-box>
            </div>
            
            <div class="section expiration">
              <div class="title">Call Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.callExpirationList">
                  <ng-container *ngTemplateOutlet="expiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section expiration">
              <div class="title">Put Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.putExpirationList">
                  <ng-container *ngTemplateOutlet="expiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
          
          </div>
          
          <div class="side right">
            
            <div class="header">Total P&L</div>
            
            <!-- Expiration Tpl -->
            <ng-template #pnlExpiration let-expiration>
              <div class="expiration-item">
                <div class="checkbox">
                  <dx-check-box
                    (onValueChanged)="this.onPnlExpirationSelectionChanged($event, expiration, false)"
                    [value]="this.isPnlExpirationSelected(expiration, false)">
                  </dx-check-box>
                </div>
                <div class="label">
                  {{ expiration.expirationFull }}
                </div>
                <div class="toggle">
                  <div class="label">
                    Show "All" Column
                  </div>
                  <div class="input">
                    <dx-switch
                      (onValueChanged)="this.onPnlExpirationSelectionChanged($event, expiration, true)"
                      [value]="this.isPnlExpirationSelected(expiration, true)"
                    ></dx-switch>
                  </div>
                </div>
              </div>
            </ng-template>
            
            <div class="section">
              <dx-check-box (onValueChanged)="this.toggleSelectAllPnlExpirations($event)"
                            [value]="this.allPnlExpirationsSelected"
                            text="Select All Expirations">
              </dx-check-box>
            </div>
            
            <div class="section expiration">
              <div class="title">Call Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.callPnlExpirationList">
                  <ng-container *ngTemplateOutlet="pnlExpiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section expiration">
              <div class="title">Put Expirations</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.putPnlExpirationList">
                  <ng-container *ngTemplateOutlet="pnlExpiration; context: {$implicit: item}">
                  </ng-container>
                </ng-container>
              </div>
            </div>
            
            <div class="section expiration">
              <div class="title">Grand Totals</div>
              <div class="selector">
                <ng-container *ngFor="let item of this.expirationList">
                  <div class="expiration-item">
                    <div class="checkbox">
                      <dx-check-box
                        (onValueChanged)="this.onGrandTotalExpirationSelectionChanged($event, item)"
                        [value]="this.isGrandTotalExpirationSelected(item)">
                      </dx-check-box>
                    </div>
                    <div class="label">
                      {{ item.expirationFull }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>