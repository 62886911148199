import { ProtocolQuery } from 'projects/shared-components/service-model/protocol-query.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';
import { StrategyOpenPositionSnapshotDto } from '../../dtos/strategy-open-position-snapshot-dto.class';

export class GetOpenPositionChartDataResponse {
  snapshots: StrategyOpenPositionSnapshotDto[];
  closestBarDate: Date;
}


export class GetOpenPositionChartData implements ProtocolQuery {
  constructor(
    public strategyId: string,
    public subscriberId: string,
    public startDate: Date,
    public endDate: Date) {}

    getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetOpenPositionChartData`;
    }
}
