import { RiskManagementComponent } from './risk-management.component';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate } from '@angular/common';
import { getViolationOrdersGridModel } from './violation-orders-grid-model';
import { 
  TradingInstrumentDisplayNameService 
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { centeredColumnDef, defaultTimestampFormat, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { RiskManagerRuleViolationDto } from 'projects/shared-components/shell-communication/dtos/risk-manager-rule-violation-dto.interface';

export function getViolationsGridModel(
  this: RiskManagementComponent,
  displayNameService: TradingInstrumentDisplayNameService
): GridOptions {
  return  {
    rowData: [],

    defaultColDef: centeredColumnDef,

    columnDefs: [
      {
        headerName: 'Violation Time',
        field: 'violationTime',
        width: 60,
        valueFormatter: (params: CellClassParams) => {
          return formatDate(params.value, defaultTimestampFormat, 'en-US');
        },
        cellRenderer: 'agGroupCellRenderer'
      },
      {
        headerName: 'Description',
        field: 'description'
      }
    ],

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    masterDetail: true,

    detailCellRendererParams: {
      detailGridOptions: getViolationOrdersGridModel.bind(this)(
        displayNameService
      ),
      getDetailRowData: params => {
        const ordersByViolationId = this.getOrdersByViolation(params.data);
        params.successCallback(ordersByViolationId);
      }
    },

    onSelectionChanged: args => this.onViolationSelectionChanged(args),

    onGridReady: args => this.onViolationsGridReady(args),

    getRowNodeId: (rowData: RiskManagerRuleViolationDto) => {
      return rowData.violationId;
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      const menu = [];
      if (this.securityContext.clearViolations) {
        menu.push(
          {
            name: 'Remove',
            disabled: !params.node || !params.node.data,
            action: () => this.removeViolation(params.node.data)
          },
          {
            name: 'Remove All',
            disabled: !this.hasAnyViolations,
            action: () => this.removeAllViolations()
          },
          'separator'
        );
      }

      menu.push(
        'autoSizeAll',
        'copy',
        'export'
      );

      return menu;
    }
  } as GridOptions;
}
