import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { OptionsChainService } from 'projects/shared-components/option-chains.service';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ConvertToMarketSettings, FutureTimeSettings, OptionExpirationDescriptor, PortfolioItemDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { RollLegUnderAdjustment } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged, tickersMatch } from 'projects/shared-components/utils';
import { isNullOrUndefined } from 'util';
import { TargetStrikeDialogConfig } from './TargetStrikeDialogConfig';

export class TargetStrikeDialog {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _toastr: ToastrService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _optionChains: OptionsChainService,
      private readonly _tiService: TradingInstrumentsService,
      private readonly _shellService: ShellClientService
   ) {}
   
   private _config: TargetStrikeDialogConfig;

   //
   
   private _isVisible: boolean;
   public get isVisible(): boolean {
      return this._isVisible;
   }

   @DetectSetterChanges()
   public set isVisible(v: boolean) {
      this._isVisible = v;
   }
   
   //

   
   private _isLoading: boolean;
   public get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   public set isLoading(v: boolean) {
      this._isLoading = v;
   }
   
   //

   
   private _tradingInstrument: TradingInstrument;
   public get tradingInstrument(): TradingInstrument {
      return this._tradingInstrument;
   }

   @DetectSetterChanges()
   public set tradingInstrument(v: TradingInstrument) {
      this._tradingInstrument = v;
   }
   

   //

   public orderTypeList = [
      {
         key: 'Market',
         value: OrderType.Market,
      },
      {
         key: 'Limit',
         value: OrderType.Limit
      },
      {
         key: 'AutoLimit',
         value: OrderType.AutoLimit
      }
   ];

   //
   
   private _legs: PortfolioItemDto[] = [];
   get legs(): PortfolioItemDto[] {
      return this._legs;
   }
   @DetectSetterChanges()
   set legs(v: PortfolioItemDto[]) {
      this._legs = v;
   }

   //
   
   private _legToRoll: PortfolioItemDto;
   public get legToRoll(): PortfolioItemDto {
      return this._legToRoll;
   }
   @DetectSetterChanges()
   public set legToRoll(v: PortfolioItemDto) {
      this._legToRoll = v;
   }

   //
   get longLeg(): PortfolioItemDto {
      return (this.legs || []).find(x => x.netPosition > 0);
   }
  


   //
   
   private _selectedOrderType: OrderType;
   public get selectedOrderType(): OrderType {
      return this._selectedOrderType;
   }

   @DetectSetterChanges()
   public set selectedOrderType(v: OrderType) {
      this._selectedOrderType = v;
      
      if (v !== OrderType.AutoLimit) {
         this._autoLimitPrice = null;
      }

      if (v === OrderType.Market) {
         this._limitPrice = null;
      }
   }

   //
   
   private _limitPrice: number;
   public get limitPrice(): number {
      return this._limitPrice;
   }

   @DetectSetterChanges()
   public set limitPrice(v: number) {
      this._limitPrice = v;
   }
   
   //

   private _autoLimitPrice: 'Bid' | 'Ask' | 'Mid';
   public get autoLimitPrice(): 'Bid' | 'Ask' | 'Mid' {
      return this._autoLimitPrice;
   }

   @DetectSetterChanges()
   public set autoLimitPrice(v: 'Bid' | 'Ask' | 'Mid') {
      this._autoLimitPrice = v;

      if (!isNullOrUndefined(v)) {
         this._limitPrice   = null;
      }
   }
   
   //

   private _futureTimeSettings: FutureTimeSettings = {};
   public get futureTimeSettings(): FutureTimeSettings {
      return this._futureTimeSettings;
   }

   @DetectSetterChanges()
   public set futureTimeSettings(v: FutureTimeSettings) {
      this._futureTimeSettings = v;
   }

   //

   private _convertToMarketSettings: ConvertToMarketSettings = {};
   public get convertToMarketSettings(): ConvertToMarketSettings {
      return this._convertToMarketSettings;
   }

   @DetectSetterChanges()
   public set convertToMarketSettings(v: ConvertToMarketSettings) {
      this._convertToMarketSettings = v;
   }

   //

   
   private _targetLegTicker: string;
   public get targetLegTicker(): string {
      return this._targetLegTicker;
   }

   @DetectSetterChanges()
   public set targetLegTicker(v: string) {
      this._targetLegTicker = v;
   }

   //

   public get canConvertToMarket(): boolean {
      return this.selectedOrderType && this.selectedOrderType !== 2;
   }

   //

   public get popupHeight(): number {
      let h = 525;
      
      if (this.isTargetTickerVanilla) {
         h += 100;
      }

      if (this.selectedOrderType !== OrderType.Market) {
         h += 30;
      }

      if (this.convertToMarket) {
         h += 50;
      }

      if (this.convertToMarketSettings.actionTimeMode === 'Convert At') {
         h += 86;
      }

      if (this.futurePlacement) {
         h += 50;
      }

      if (this.futureTimeSettings.actionTimeMode as any === 'Place At') {
         h += 86;
      }
      
      return h;
   }

   //

   get limitPriceColor(): any {
      let color;

      if (this.limitPrice > 0) {
         color = 'green';
      } else if (this.limitPrice < 0) {
         color = 'red';
      }

      return {
         style: `color: ${color}`
      };
   }

   //

   public get originalLeg(): string {
      if (isNullOrUndefined(this._config)) {
         return null;
      }

      if (isNullOrUndefined(this._config.legToRoll)) {
         return null;
      }

      return this._config.legToRoll.tickerDisplayName;
   }

   //

   public get legQty(): number {
      if (isNullOrUndefined(this._config)) {
         return null;
      }

      if (isNullOrUndefined(this._config.legToRoll)) {
         return null;
      }

      return this._config.legToRoll.netPosition;
   }

   //

   
   private _strike: number;
   public get strike(): number {
      return this._strike;
   }
   public set strike(v: number) {
      this._strike = v;
   }
   
   
   private _atmStrike: string;
   public get atmStrike(): string {
      return this._atmStrike;
   }
   public set atmStrike(v: string) {
      this._atmStrike = v;
   }
   

   //

   get isTargetTickerVanilla(): boolean {
      
      if (!this.isTargetTickerValid) {
         return false;
      }
       
      if (this.targetLegTicker.indexOf(' ATM') >= 10) {
         return false;
      }

      if (this.targetLegTicker.indexOf(' NEAR') >= 10) {
         return false;   
      }

      return true;
   }

   //

   get isTargetTickerValid(): boolean {
      if (isNullOrUndefined(this.targetLegTicker)) {
         return false;
      }

      if (this.targetLegTicker.indexOf('{STRIKE}') > 0) {
         return false;
      }

      return true;
   }

   //

   get showQuotes(): boolean {
      return this.isTargetTickerVanilla;
   }

   //

   expirationDescriptor: OptionExpirationDescriptor;
   
   //

   private _convertToMarket = false;
   public get convertToMarket(): boolean {
      return this._convertToMarket;
   }
   
   @DetectSetterChanges()
   public set convertToMarket(v: boolean) {
      this._convertToMarket = v;

      if (!v) {
         this.convertToMarketSettings.actionTime = null;
         this.convertToMarketSettings.actionTimeMode = null;
         this.convertToMarketSettings.timezone = null;
         this.convertToMarketSettings.rateOfChange = null;
         this.convertToMarketSettings.timesToReplace = null;
         this.convertToMarketSettings.reverseTimeDirection = null;
         this.convertToMarketSettings.replaceEvery = null;
      }
   }

   //

   
   private _futurePlacement = false;
   public get futurePlacement(): boolean {
      return this._futurePlacement;
   }

   @DetectSetterChanges()
   public set futurePlacement(v: boolean) {
      this._futurePlacement = v;

      if (!v) {
         this.futureTimeSettings.actionTime = null;
         this.futureTimeSettings.actionTimeMode = null;
         this.futureTimeSettings.timezone = null;
      }
   }
   
   //

   private _bidQuote: number;
   public get bidQuote(): number {
      return this._bidQuote;
   }
   public set bidQuote(v: number) {
      this._bidQuote = v;
   }
   
   //

   private _askQuote: number;
   public get askQuote(): number {
      return this._askQuote;
   }
   public set askQuote(v: number) {
      this._askQuote = v;
   }
   
   //

   public get midQuote(): number {
      const mid  = (this.bidQuote + this.askQuote) / 2;

      if (isNullOrUndefined(mid) || isNaN(mid)) {
         return undefined;
      }

      return mid;
   }

   //

   get bidQuoteColorOriginal(): string {
      const leg = this._config.legToRoll;

      if (!leg) {
         return undefined;
      }

      if (leg.netPosition <= 0) {
         return undefined;
      }

      return 'red';
   }

   get bidQuoteColorTarget(): string {
      const leg = this._config.legToRoll;

      if (!leg) {
         return undefined;
      }

      if (leg.netPosition >= 0) {
         return undefined;
      }

      return 'red';
   }

   get askQuoteColorOriginal(): string {
      const leg = this._config.legToRoll;

      if (!leg) {
         return undefined;
      }

      if (leg.netPosition >=  0) {
         return undefined;
      }

      return 'cornflowerblue';
   }

   
   get askQuoteColorTarget(): string {
      const leg = this._config.legToRoll;

      if (!leg) {
         return undefined;
      }

      if (leg.netPosition <=  0) {
         return undefined;
      }

      return 'cornflowerblue';
   }

   //   
   
   private _bidQuoteOriginal: number;
   public get bidQuoteOriginal(): number {
      return this._bidQuoteOriginal;
   }
   public set bidQuoteOriginal(v: number) {
      this._bidQuoteOriginal = v;
   }
   
   
   private _askQuoteOriginal: number;
   public get askQuoteOriginal(): number {
      return this._askQuoteOriginal;
   }
   public set askQuoteOriginal(v: number) {
      this._askQuoteOriginal = v;
   }
   
   public get midQuoteOriginal(): number {
      const mid  = (this.bidQuoteOriginal + this.askQuoteOriginal) / 2;

      if (isNullOrUndefined(mid) || isNaN(mid)) {
         return undefined;
      }

      return mid;
   }

   //

   public get bidQuoteTotal(): number {
      const originalSide = Math.sign(this._config.legToRoll.netPosition) * -1;
      const targetSide = originalSide * -1;
      
      let price = 0;

      if (originalSide > 0) {
         price += this.bidQuoteOriginal;
      } else {
         price -= this.askQuoteOriginal;
      }

      if (targetSide > 0) {
         price += this.bidQuote;
      } else {
         price -= this.askQuote;
      }

      return price * -1;
   }

   public get midQuoteTotal(): number {
      return (this.bidQuoteTotal + this.askQuoteTotal) / 2;
   }

   public get askQuoteTotal(): number {
      
      const originalSide = Math.sign(this._config.legToRoll.netPosition) * -1;
      const targetSide = originalSide * -1;
      let price = 0;

      if (originalSide > 0) {
         price += this.askQuoteOriginal;
      } else {
         price -= this.bidQuoteOriginal;
      }

      if (targetSide > 0) {
         price += this.askQuote;
      } else {
         price -= this.bidQuote;
      }

      return price * -1;
   }

   //

   @DetectMethodChanges()
   async show(config: TargetStrikeDialogConfig) {

      if (isNullOrUndefined(config)) {
         this._toastr.error('No config provided to roll the leg');
         this.onHidden();
         return;
      }

      if (isNullOrUndefined(config.legToRoll) || isNullOrUndefined(config.legToRoll.ticker)) {
         this.onHidden();
         return;
      }

      this._config = config;

      this.legToRoll = config.legToRoll;

      this._lastQuoteCache.subscribeTicker(config.legToRoll.ticker);

      const legTickerParts = config.legToRoll.ticker.split(' ');

      if (legTickerParts.length < 4) {
         this._toastr.error('Bad leg ticker provided');
         console.error('bad leg ticker', config.legToRoll.ticker);
         return;
      }
            
      legTickerParts[3] = '{STRIKE}';

      this.targetLegTicker = legTickerParts.join(' ');

      this._isVisible = true;

      this._tradingInstrument = this._tiService.getInstrumentByTicker(legTickerParts[1]);

      try {
         this.isLoading = true;
         const chain = await this._optionChains.getChain(legTickerParts[1]);
         const expDescriptor = chain.expirations.find( x => x.optionExpirationDate === legTickerParts[4]);
         this.expirationDescriptor = expDescriptor;
      } finally {
         this.isLoading = false;
      }
   }

   //

   onQuote(quotes: QuoteDto[]) {
      if (!this.isVisible) {
         return;
      }

      let shouldUpdate = false;

      const originalQuote = quotes.find(x => tickersMatch(x.ticker, this._config.legToRoll.ticker));

      if (!isNullOrUndefined(originalQuote)) {
         this._bidQuoteOriginal = originalQuote.bid;
         this._askQuoteOriginal = originalQuote.ask;
         shouldUpdate = true;
      }

      if (!this.targetLegTicker || this.targetLegTicker.indexOf('{STRIKE}') > 0) {
         
         if (shouldUpdate) {
            this._changeDetector.detectChanges();
         }

         return;
      }

      const targetQuote = quotes.find(x => tickersMatch(x.ticker, this.targetLegTicker));

      if (!isNullOrUndefined(targetQuote)) {
         this._bidQuote = targetQuote.bid;   
         this._askQuote = targetQuote.ask;
         shouldUpdate = true;
      }

      if (shouldUpdate) {
         this._changeDetector.detectChanges();
      }
   }

   //

   @DetectMethodChanges()
   onChange() {
      // empty method to trigger change detection
   }

   //

   onStrikeChanged(ev: DxValueChanged<number>) {
      if (this.isTargetTickerVanilla) {
         this._lastQuoteCache.unsubscribeTicker(this.targetLegTicker);
         this._bidQuote = this._askQuote = null;
      }
      
      this.setStrikeToTargetLegTicker(ev.value);

      if (this.isTargetTickerVanilla) {
         this._lastQuoteCache.subscribeTicker(this.targetLegTicker);
      }
   }

   onAtmStrikeChanged(ev: DxValueChanged<string>) {
      if (this.isTargetTickerVanilla) {
         this._lastQuoteCache.unsubscribeTicker(this.targetLegTicker);
         this._bidQuote = this._askQuote = null;
      }

      this.setStrikeToTargetLegTicker(ev.value);

      if (this.isTargetTickerVanilla) {
         this._lastQuoteCache.subscribeTicker(this.targetLegTicker);
      }
   }

   //

   @DetectMethodChanges()
   onHidden() {
      this._isVisible = false;
      this._config = null;
      this._convertToMarket = false;
      this._futurePlacement = false;
      this._tradingInstrument = null;
      this._selectedOrderType = null;
      this._targetLegTicker = null;
      this._askQuote = this._bidQuote = this._bidQuoteOriginal = this._askQuoteOriginal = null;
      this._strike = this._atmStrike = null;
      this.expirationDescriptor = null;
   }

   //

   @DetectMethodChanges()
   private setStrikeToTargetLegTicker(value: number | string) {

      const legTickerParts = this._config.legToRoll.ticker.split(' ');

      if (legTickerParts.length < 4) {
         console.error('Bad leg ticker provided');
         return;
      }
            
      legTickerParts[3] = '{STRIKE}';

      const targetTicker = legTickerParts.join(' ');
      
      if (!isNullOrUndefined(value)) {
         this.targetLegTicker = targetTicker.replace('{STRIKE}', value + '');
      }

      console.log('new target ticker: ', this.targetLegTicker);
   }

   //

   getDateTimePickerMode(context: 'convert' | 'future'): 'timespan' | 'datetime' {
      let mode: string;
      
      if (context === 'convert') {
         mode = this.convertToMarketSettings.actionTimeMode;
      }

      if (context === 'future') {
         mode = this.futureTimeSettings.actionTimeMode;
      }

      if (isNullOrUndefined(mode)) {
         return null;
      }

      if (mode.endsWith(' After')) {
         return 'timespan';
      }

      if (mode.endsWith(' At')) {
         return 'datetime';
      }

      return null;
   }

   async onOkClicked() {
      const legToRoll = this.legs.length > 0 ? this.legToRoll.portfolioItemId : this._config.legToRoll.portfolioItemId;
      console.log('leg to roll: ', legToRoll);
      console.log('target ticker: ', this.targetLegTicker);

      if (!this.validate()) {
         return;
      }

      const cmd = new RollLegUnderAdjustment(
         legToRoll,
         this.targetLegTicker,
         (this.selectedOrderType === OrderType.Market ? OrderType.Market : OrderType.Limit) as any,
         this.limitPrice,
         this.autoLimitPrice,
         this.futureTimeSettings,
         this.convertToMarketSettings
      );

      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      }  catch (e) {
         console.error(e);
         this._toastr.error(e);
      } finally {
         this.isLoading = false;
         this.isVisible = false;
      }
   }

   onCancelClicked() {
      this.isVisible = false;
      this.targetLegTicker = null;
   }


   private validate(): boolean {
      if (!this.isTargetTickerValid) {
         this._toastr.error('Target ticker is not valid');
         return false;
      }

      if (!this.selectedOrderType) {
         this._toastr.error('Please, select valid order type');
         return false;
      }

      if (this.selectedOrderType === OrderType.Limit) {
         if (!this.limitPrice) {
            this._toastr.error('Please provide limit price');
            return false;
         }
      }

      if (this.selectedOrderType === OrderType.AutoLimit) {
         if (!this.autoLimitPrice) {
            this._toastr.error('Please provide auto price');
            return false;
         }
      }

      if (this.convertToMarket) {
         if (!this.convertToMarketSettings.actionTime) {
            this._toastr.error('Please provide convert to market time');
            return false;
         }

         if (!this.convertToMarketSettings.actionTimeMode) {
            this._toastr.error('Please provide convert to market time mode');
            return false;
         }

         if (this.convertToMarketSettings.actionTimeMode === 'Convert At') {
            if (!this.convertToMarketSettings.timezone) {
               this._toastr.error('Please provide convert to market timezone');  
               return false;
            }
         }
      }

      if (this.futurePlacement) {
         if (!this.futureTimeSettings.actionTime) {
            this._toastr.error('Please provide future placement time');
            return false;
         }

         if (!this.futureTimeSettings.actionTimeMode) {
            this._toastr.error('Please provide future placement time mode');
            return false;
         }

         if (this.futureTimeSettings.actionTimeMode === 'Convert At') {
            if (!this.futureTimeSettings.timezone) {
               this._toastr.error('Please provide future placement timezone');  
               return false;
            }
         }
      }

      return true;
   }

   setLimitPrice(price: number) {
      if (this.selectedOrderType === OrderType.Limit) {
         this.limitPrice = price;
      }
   }
}
