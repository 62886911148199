import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { Subscription } from 'rxjs';
import { ParametersControlBase } from '../parameters-control-base';

@Component({
   selector: 'ets-base-comp',
   template: ''
})
// tslint:disable-next-line: component-class-suffix
export abstract class ParametersViewBase<T extends ParametersControlBase<unknown>> 
      implements OnInit, OnDestroy, AfterViewInit {
   
   constructor(private _changeDetector: ChangeDetectorRef) { }

   private _control: T;
   private _changesSubscriber: Subscription;
   private _parametersSubscriber: Subscription;

   @ViewChild(SymbolPickerComponent) symbolPicker: SymbolPickerComponent;

   get control(): T {
      return this._control;
   }

   @Input() set control(value: T) {
      if (this._changesSubscriber) { this._changesSubscriber.unsubscribe(); }
      if (this._parametersSubscriber) { this._parametersSubscriber.unsubscribe(); }
   
      this._control = value;

      this._changesSubscriber = this._control.changes$.subscribe( () => this.detectChanges() );
      this._parametersSubscriber = this._control.parametersSet$.subscribe(() => { });

      this._control.view = this;
   }
   
   ngOnInit(): void {
      this.onEtsInit();
   }

   ngOnDestroy(): void {
      if (this._changesSubscriber) { this._changesSubscriber.unsubscribe(); }
      if (this._parametersSubscriber) { this._parametersSubscriber.unsubscribe(); }
      this._changesSubscriber = null;
      this._parametersSubscriber = null;
   }

   ngAfterViewInit() { 
      this._changeDetector.detach();   
      this.onEtsAfterViewInit();
      this.detectChanges();
   }

   abstract onEtsInit();

   abstract onEtsAfterViewInit();

   detectChanges() {
      this._changeDetector.detectChanges();
   }
}
