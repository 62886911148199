import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { DevExtremeModule } from '../devextreme.module';

import { StrategyHedgedPositionsComponent } from './strategy-hedged-positions.component';

@NgModule({
   imports: [
      CommonModule,
      AgGridModule.withComponents([]),
      DevExtremeModule
   ],
   exports: [],
   declarations: [StrategyHedgedPositionsComponent],
   providers: [],
})
export class StrategyHedgedPositionsModule { }
