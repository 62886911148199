<div class="container" #panel [ngClass]="{'ets-hidden': !isActive}">
   <as-split class="split" direction="horizontal" [gutterSize]="5">
      <as-split-area order="0" [size]="this.split.terminalsSection.size">
         <ag-grid-angular  #terminalsGrid
                           class="ag-theme-balham-dark" 
                           style="width: 100%; height: 100%;"
                           [gridOptions]="this.terminalsGridOptions"
         ></ag-grid-angular>
      </as-split-area>

      <as-split-area order="1" [size]="this.split.positionsSection.size">
         <ag-grid-angular  class="ag-theme-balham-dark" 
                           style="width: 100%; height: 100%;"
                           [gridOptions]="this.positionsGridOptions"
                           #positionsGrid
         ></ag-grid-angular>
      </as-split-area>
   </as-split>
</div>

<dx-load-panel [visible]="isLoading" [position]="{ my: 'center', at: 'center', of: panel }"></dx-load-panel>

<dx-popup #addManually title="Add Position" [width]="210" [height]="510" [showTitle]="true"
   [closeOnOutsideClick]="false" [resizeEnabled]="false" [dragEnabled]="true" (onShown)="this.addPositionDialog_OnShown()" (onHidden)="this.addPositionDialog_OnClosed()">
   <div *dxTemplate="let data of 'content'">
      <div class="container add-manually" #dialog>

         <!-- Symbol -->
         <div class="ets-row">
            <div class="ets-column header">
               Symbol
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <ets-symbol-picker
                  (instrumentSelected)=" addPositionDialogSettings !== null ? addPositionDialogSettings.instrument = $event : null ">
               </ets-symbol-picker>
            </div>
         </div>

         <!-- Account -->
         <div class="ets-row">
            <div class="ets-column header">
               Account
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-select-box [items]="addPositionDialogSettings?.availableAccounts"
                  [selectedItem]="addPositionDialogSettings?.account" [value]="addPositionDialogSettings?.account"
                  (onValueChanged)="addPositionDialogSettings !== null ? addPositionDialogSettings.account = $event.value : null "
                  [displayExpr]="'accountCode'"></dx-select-box>
            </div>
         </div>

         <!-- Terminal -->
         <div class="ets-row">
            <div class="ets-column header">
               Terminal
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-select-box [items]="addPositionDialogSettings?.availableTerminals"
                  [value]="addPositionDialogSettings?.terminal"
                  (valueChange)="this.addPositionDialog_OnTerminalChange($event)"
                  [displayExpr]="'displayName'"></dx-select-box>
            </div>
         </div>

         <!-- Portfolio -->
         <div class="ets-row">
            <div class="ets-column header">
               Portfolio
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-select-box [items]="addPositionDialogSettings?.availablePortfolios"
                  [value]="addPositionDialogSettings?.portfolio"
                  (valueChange)="this.addPositionDialog_OnPortfolioChange($event)"
                  [displayExpr]="'portfolioName'"
                  [showClearButton]="true"
               ></dx-select-box>
            </div>
         </div>

         <!-- Combo -->
         <div class="ets-row">
            <div class="ets-column header">
               Combo
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-select-box [items]="addPositionDialogSettings?.availableCombos"
                  [value]="addPositionDialogSettings?.combo"
                  (valueChange)="this.addPositionDialog_OnComboChange($event)"
                  [displayExpr]="'comboName'"
                  [showClearButton]="true"
               ></dx-select-box>
            </div>
         </div>

          <!-- Combo Group -->
          <div class="ets-row">
            <div class="ets-column header">
               ComboGroup
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-select-box [items]="addPositionDialogSettings?.availableComboGroups"
                  [value]="addPositionDialogSettings?.comboGroup"
                  (valueChange)="this.addPositionDialog_OnComboGroupChange($event)"
                  [displayExpr]="'comboGroupName'"
                  [showClearButton]="true"
               ></dx-select-box>
            </div>
         </div>

         <!-- Net Position -->
         <div class="ets-row">
            <div class="ets-column header">
               Net Position
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-number-box mode="number" [value]="addPositionDialogSettings?.netPosition"
                  (onValueChanged)="addPositionDialogSettings !== null ? addPositionDialogSettings.netPosition = $event.value : null"
                  [showSpinButtons]="true" [showClearButton]="true" [step]="1"></dx-number-box>
            </div>
         </div>

         <!-- Avg. Px -->
         <div class="ets-row">
            <div class="ets-column header">
               Avg. Px
            </div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-number-box mode="number" [value]="addPositionDialogSettings?.avgPx"
                  (onValueChanged)="addPositionDialogSettings !== null ? addPositionDialogSettings.avgPx = $event.value : null"
                  [showClearButton]="true" [min]="0"></dx-number-box>
            </div>
         </div>

         <!-- Buttons -->
         <div class="ets-row buttons">
            <div class="ets-column">
               <dx-button type="normal" text="Add" (onClick)="addPositionManually()"
                  [disabled]="addPositionDialogSettings?.isLoading"></dx-button>
            </div>
            <div class="ets-column">
               <dx-button type="default" text="Close" (onClick)="closeAddPositionDialog()"
                  [disabled]="addPositionDialogSettings?.isLoading"></dx-button>
            </div>
         </div>

      </div>
      <dx-load-panel [width]="180" [position]="{ my: 'center', at: 'center', of: dialog }"
         [visible]="addPositionDialogSettings?.isLoading"></dx-load-panel>
   </div>
</dx-popup>

<ets-move-to-portfolio-dialog></ets-move-to-portfolio-dialog>

<ets-position-adjust [position]="{ my: 'center', at: 'center', of: positionsGrid }"></ets-position-adjust>

<ets-position-override></ets-position-override>