import { CtdData } from '../shell-communication/operations/shell/clear-trading-data.class';

export class ClearTradingDataUIMessage {
  constructor() {
    this.strategies = [];
    this.accounts = [];
    this.terminals = [];
  }

  data: CtdData;
  accounts: string[];
  terminals: string[];
  strategies: string[];
  deleteData: boolean;
  refreshDb: boolean;
  hasErrors: boolean;
  shellId: string;
  shellName: string;
  clientId: string;
  clientName: string;
}
