import { NgModule } from '@angular/core';
import { AlertsComponent } from './alerts.component';
import { AlertsEditorComponent } from './alerts-editor/alerts-editor.component';
import { AgGridModule } from 'ag-grid-angular';
import { EtsSymbolPickerModule } from '../../../../shared-components/symbol-picker/symbol-picker.module';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      EtsSymbolPickerModule,
      AgGridModule.withComponents([])
   ],
   exports: [
      AlertsComponent,
      AlertsEditorComponent
   ],
   declarations: [
      AlertsComponent,
      AlertsEditorComponent
   ],
   providers: []
})
export class EtsAlertsModule { }
