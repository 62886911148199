import { NgModule } from '@angular/core';
import { AppSettingsComponent } from './app-settings.component';
import { CommonModule } from '@angular/common';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { AgGridModule } from 'ag-grid-angular';
import { DevExtremeModule } from '../devextreme.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';

@NgModule({
  imports: [
    CommonModule,
    EtsSymbolPickerModule,
    AgGridModule.withComponents([]),
    DevExtremeModule,
    TimezonePickerModule
  ],
  exports: [AppSettingsComponent],
  declarations: [AppSettingsComponent],
  providers: [],
})
export class AppSettingsModule { }
