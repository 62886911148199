import { CellClassParams, ColDef, IAggFuncParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatCurrency, formatNumber } from '@angular/common';
import { AgGridColumn } from 'ag-grid-angular';
import { GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { MessageBusService } from './message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { daysToExpiration, findHCF, isCashSettledOptionTicker, isNullOrUndefined, isTruthy, isValidNumber, makeMultiLegOrderCode } from './utils';
import { TradeFlags, tradeHasFlag } from './shell-communication/dtos/trade-dto.class';
import { TimestampsService } from './timestamps.service';
import { PositionFlags, positionHasFlag } from './shell-communication/dtos/position-dto.class';
import { OrderDto } from './shell-communication/dtos/order-dto.class';

export const AG_DEFAULT_ROW_HEIGHT = 27;

export const AG_SYMBOL_COL_MIN_WIDTH = 222;

export const AG_DATE_COL_MIN_WIDTH = 165;

//

export const centeredHeader =
   `<div class="ag-cell-label-container ets-header-center" role="presentation">
  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
  </div>
</div>`;

//

export const defaultColumnDef: Partial<AgGridColumn> = { sortable: true, resizable: true };

//

export const centeredColumnDef: Partial<AgGridColumn> =
   Object.assign({ headerComponentParams: { template: centeredHeader } }, defaultColumnDef);

//

export const defaultMoneyCellDefinition = {
   valueFormatter(params: ValueFormatterParams) {
      if (!isValidNumber(params.value)) {
         return undefined;
      }
      const v = Math.abs(params.value);
      return formatCurrency(v || 0, 'en-US', '$', 'USD', '1.2-2');
   },
   cellClassRules: {
      'ets-positive-number': (params: CellClassParams) => !isNullOrUndefined(params.value) && (params.value.toFixed(2) > 0),
      'ets-negative-number': (params: CellClassParams) => !isNullOrUndefined(params.value) && (params.value.toFixed(2) < 0)
   }
};

//

export const defaultNumberCellFormatter = (params: ValueFormatterParams) => {
   return formatNumber(params.value || 0, 'en-US', '1.0-3');
};

//

export const defaultQuoteCellFormatter = (params: ValueFormatterParams) => {
   if (!params || !params.value) {
      return null;
   }
   return formatNumber(params.value, 'en-US', '1.0-7');
};

//

export const defaultPriceCellFormatter = (params: ValueFormatterParams) => {
   
   if (!isValidNumber(params.value)) {
      return '';
   }
   
   let retval = params.value;

   try {
      
      retval = (params.value).toFixed(2);

   } catch {
      //
   }

   return retval;
};

export const defaultPriceCellFormatterWithDollarSign = (params: ValueFormatterParams) => {

   if (typeof params.value !== 'number') {
      return '';
   }
   let retval = '';

   try {

      retval = formatCurrency(params.value, 'en-US', '$', 'USD', '1.2-2');
      // retval = (params.value).toFixed(2);

   } catch {
      //
   }

   return retval;
};

//

export const defaultTimestampFormat = 'ccc dd-MMM-yy hh:mm:ss.SSS a';

//

export const defaultTimestampFormatNoMs = 'ccc dd-MMM-yy hh:mm:ss a';

//

export const defaultLoadingOverlayTemplate =
   `<span class="ag-overlay-loading-center" style="background: #2d3436;">
      Please wait while data is loading
  </span>`;

//

export interface GetDetailRowDataParams {
   // details for the request,
   node: RowNode;
   data: any;

   // success callback, pass the rows back the grid asked for
   successCallback(rowData: any[]): void;
}

//

export interface DetailCellRendererParams {
   detailGridOptions: GridOptions;
   getDetailRowData: (params: GetDetailRowDataParams) => void;
   // tslint:disable-next-line: ban-types
   template: string | Function;
   autoHeight: boolean;
   refreshStrategy: 'nothing' | 'everything' | 'rows';
}

//

export interface MenuItemDef {
   name: string; // name of menu item
   disabled?: boolean; // if item should be enabled / disabled
   shortcut?: string; // shortcut (just display text, saying the shortcut here does nothing)
   action?: () => void; // function that gets executed when item is chosen
   checked?: boolean; // set to true to provide a check beside the option
   icon?: HTMLElement | string; // the icon to display beside the icon, either a DOM element or HTML string
   subMenu?: MenuItemDef[]; // if this menu is a sub menu, contains a list of sub menu item definitions
   cssClasses?: string[]; // Additional CSS classes to be applied to the menu item
   tooltip?: string; // Optional tooltip for the menu item
}

//

export function getDetailSymbolColumn(symbolField: string, messageBus: MessageBusService, timestampService: TimestampsService, unsubscriber: Subject<void>, gridApiResolver: () => GridApi): ColDef {

   messageBus.of('SessionEndedDto')
      .pipe(
         takeUntil(unsubscriber)
      )
      .subscribe( _ => {

         const gridApi = gridApiResolver();

         if (gridApi) {
            gridApi.forEachLeafNode(node => {

               if (!node.expanded) {
                  return;
               }

               const detailGrid = gridApi.getDetailGridInfo(`detail_${node.id}`);

               if (detailGrid) {
                  detailGrid.api.refreshCells({ columns: [symbolField], force: true });
               }

            });
         }
      });

   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: symbolField,
      minWidth: AG_SYMBOL_COL_MIN_WIDTH,
      valueFormatter: (params: ValueFormatterParams) => {

         if (params.node.group) {
            return null;
         }


         if (!params.value) {
            return null;
         }

         let displayName: string;

         const ticker: string = params.value;

         let addTickToTicker = false;

         if (params.data.positionId) {
            // this is a position. lets check if it has flag 'LastOverSessionReset'
            addTickToTicker = positionHasFlag(params.data, PositionFlags.LastOverSessionReset);
         }

         if (!ticker.startsWith('@')) {

            displayName = params.data.tickerDisplayName;

            if (addTickToTicker) {
               displayName = `${displayName}'`;
            }

         } else {

            const parts = ticker.split(' ');

            // @ES ESH1 3365 Call 2020-12-25
            const sExpDate = parts[4] + ' 16:20:00';
            // const expDate = Date.parse(sExpDate);
            // const nowDate = Date.now();

            // const diffIndays = dateDiffInDays2(nowDate, expDate, timestampService);
            const diffIndays = daysToExpiration(sExpDate);

            displayName = `${params.data.tickerDisplayName}`;

            if (addTickToTicker) {
               displayName = `${displayName}'`;
            }

            displayName = `${displayName} (${diffIndays}d)`;

            if (isCashSettledOptionTicker(ticker)) {
               displayName = displayName.replace(' (E)', '');
            }

            if (isNaN(diffIndays)) {
               displayName = params.data.tickerDisplayName;
            }
         }

         return displayName;
      },
      cellStyle: (args: CellClassParams) => {
         
         if (isNullOrUndefined(args.data)) {
            return undefined;
         }
         
         const style = { 'text-align': 'left', color: undefined };
         
         return style;
      },
   };

   return symbolCol;
}

//

export function getMasterSymbolColumn(symbolField: string, messageBus: MessageBusService, timestampService: TimestampsService, unsubscriber: Subject<void>, gridApiResolver: () => GridApi): ColDef {

   // let displayName: string;

   messageBus.of('SessionEndedDto')
      .pipe(
         takeUntil(unsubscriber)
      )
      .subscribe( _ => {

         const gridApi = gridApiResolver();

         if (gridApi) {
            gridApi.refreshCells({ columns: [symbolField], force: true });
         }
      });

   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: symbolField,
      tooltipField: 'tickerDisplayName',
      minWidth: AG_SYMBOL_COL_MIN_WIDTH,
      valueFormatter: (params: ValueFormatterParams) => {

         if (params.node.footer) {
            return '-= Total =-';
         }

         if (params.node.group) {
            return null;
         }

         let displayName: string;

         if (!params.value) {
            return null;
         }

         const ticker: string = params.value;

         if (!ticker.startsWith('@')) {

            displayName = params.data.tickerDisplayName;

            const rootComboTrade = params.data && tradeHasFlag(params.data, TradeFlags.ComboRoot);
            const comboOrder = params.data && params.data.multiLegDescriptor;
            if (rootComboTrade || comboOrder) {

               if (comboOrder || rootComboTrade) {
                  
                  const mlCode = (params.data as OrderDto).legsDisplayCode;

                  if (mlCode) {
                     displayName += mlCode;
                  }
               }
            }

         } else {

            const parts = ticker.split(' ');

            // @ES ESH1 3365 Call 2020-12-25
            const sExpDate = parts[4] + ' 16:20:00';
            // const expDate = Date.parse(sExpDate);
            // const nowDate = Date.now();

            // const diffIndays = dateDiffInDays2(nowDate, expDate, timestampService);
            const diffIndays = daysToExpiration(sExpDate);

            displayName = `${params.data.tickerDisplayName} (${diffIndays}d)`;

            if (isCashSettledOptionTicker(params.data[symbolField])) {
               displayName = displayName.replace(' (E)', '');
            }

            if (isNaN(diffIndays)) {
               displayName = params.data.tickerDisplayName;
            }

         }

         if (params.data.automationAttached) {
            displayName += '* ' + displayName;
         }

         return displayName;
      },
      cellRenderer: 'agGroupCellRenderer',
   };

   return symbolCol;
}

//

export function comboTotalPriceAggFunc(params: IAggFuncParams) {
   const node = params.rowNode;
   if (!node.group) {
      return;
   }

   if (!node.field) {
      return;
   }

   if (node.allLeafChildren.length === 0) {
      return;
   }

   const posAndPx = node.allLeafChildren
      .map(leaf => {
         return leaf.data;
      })
      .filter(data => {
         return !isNullOrUndefined(data);
      })
      .map(data => {
         const size = data.netPosition || data.qty;
         const price = !data.avgPx ? 0 : data.avgPx;

         return { size, price };
      });

   const qties = posAndPx.map(p => Math.abs(p.size));
   const hcf = findHCF(qties);

   const sum = posAndPx.reduce((prev, curr) => {
      const qty = curr.size / hcf;
      return (curr.price * qty) + prev;
   }, 0);

   return isNaN(sum) ? null : sum;
}

//

export function liveQuoteTotalPriceAggFunc(params: IAggFuncParams) {
   const node = params.rowNode;

   if (!node.group) {
      return;
   }

   if (!node.field) {
      return;
   }

   if (node.allLeafChildren.length === 0) {
      return;
   }

   const posAndPx = node.allLeafChildren
      .map(leaf => {
         return leaf.data;
      })
      .filter(data => {
         return !isNullOrUndefined(data);
      })
      .map(data => {

         const size = data.netPosition || data.qty;
         const price = !data.liveQuote || data.liveQuote === -1 ? 0 : data.liveQuote;

         return { size, price };
      });

   const qties = posAndPx.map(p => Math.abs(p.size));
   const hcf = findHCF(qties);

   const sum = posAndPx.reduce((prev, curr) => {
      const qty = curr.size / hcf;
      return (curr.price * qty) + prev;
   }, 0);

   return isNaN(sum) ? null : sum;
}

export const liveQuoteFormatter = (params: ValueFormatterParams) => {

   if (isNullOrUndefined(params.value) || params.value === 0) {
      return null;
   }

   if (params.value === -1) {
      return '?';
   }

   if (isNaN(params.value)) {
      return '*';
   }

   const item = params.data as any;

   if (!item) {
      return formatNumber(params.value, 'en-US', '1.2-2');
   }

   const value = formatNumber(params.value, 'en-US', '1.2-2');


   if (item.ticker) {
      if (typeof item.ticker === 'string') {
         if (item.ticker.indexOf('XSP') >= 0) {
            return `${value} (M)`;
         }
      }
   }


   let prop: 'qty' | 'netPosition' = 'qty';

   if ('netPosition' in item) {
      prop = 'netPosition';
   }

   let contracts = item[prop];

   if (contracts === 0) {
      return `${value} (L)`;
   }

   if (prop === 'qty') {
      if (item.side) {
         contracts = contracts * item.side;
      } else if (item.marketSide) {
         contracts = contracts * item.marketSide;
      }
   }

   if (contracts > 0) {
      return `${value} (B)`;
   }

   if (contracts < 0) {
      return `${value} (A)`;
   }

   return value;
};

//
export function expandFirstLevelGroups(grid: GridReadyEvent) {
   expandFirstLevelGroups2(grid.api);
}

//
export function expandFirstLevelGroups2(grid: GridApi) {
   
   if (!grid) {
      return;
   }
   
   grid.forEachNode(node => {
      if (!node.isExpandable()) {
         return;
      }

      if (!node.group) {
         return;
      }

      node.expanded = true;
   });

   setTimeout(() => {
      grid.onGroupExpandedOrCollapsed();
   }, 1);

}
