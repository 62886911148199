import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CashFlowTrackingRecordModel } from './CashFlowTrackingRecordModel';
import { CashFlowTrackingComponent } from './cashflow-tracking.component';

@Component({
   selector: "ets-adjustment-snapshot",
   template: `
    <i class="info" (click)="this.onClick()"></i>
  `,
   styles: [
      `
      .info {
         cursor: pointer;
         width: 20px;
         height: 20px;
         margin-top: 3px;
         text-align: center;
         background-repeat: no-repeat;
         content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAAAoFJREFUeJzt3c9qE2EUhvGTMYNhoJqVVXAhWIjLFtwU6SJbIVBc9FK8CC9FQQoFty0UyUrtzlYIuBApcdFQcYxMOnFhW9qaoUPzZ94zeX6rkq+FU55839BkSswAAAAweZWshRcv3z0chOnqLIeZF9UkaL999fzbyLWsHxqE6aoNK6+nN9b8GoTphpm9GbUWzHgWXIMgYjKPrKvW1p5Nc47S2919n+v72CFiCCKGIGIIIoYgYggihiBiCCKGIGIIIoYgYggihiBiCCKmFEEWaoEt1Erxq+R/P0TR0mJozUbN7kb/YvTi1Lb3+9bpJgVPdnNun1ZLi6Gtr0TnMczM6lFg6yuRPb4XFjjZeNwGaTZqIx+vVMyaT0aveeAyyJ1acGlnXFWP/F5TfE5dYi6DHPdT68Vp5vpRnNrPfva6MpdBzMy29/s2HP7/+HBotvP59+wHmhC3QTrdxDY/xZd2ylGc2ubHX9b5MShwsvG4/juk002s003OL+Bej6mLXAc5U4YQZ9weWWVFEDEEEUMQMa4u6q3lyBr3r3/hcGsvtoNDn6/4skPEEESMqyPrw9c/9uX0KHpQv2VPH90ueKLJcxXke+/EzE6KHmOqOLLEEEQMQcQQRAxBxBBEDEHEEEQMQcQQRAxBxBBEDEHEEEQMQcQQRAxBxBBEDEHEEEQMQcQQRAxBxBBEDEHEuLpzMe/d763lyFqnXx8cJra1F093sAlih4ghiBhXR9bFu9/z8vYfuq6CcPc7Zo4gYggihiBiCCKGIGIIIoYgYggihiBiCCKGIGIIIoYgYggiJvf7IXk/DxzjYYeIIYiYzCOrmgTtQZhuzHKYeVFNgnbRMwAAAMyXv+JtehvrsyZmAAAAAElFTkSuQmCC);
      }
    `
   ]
})
export class CashFlowTrackingInfoCellRendererComponent implements ICellRendererAngularComp {
   
   data: CashFlowTrackingRecordModel;
   params: ICellRendererParams;

   agInit(params: ICellRendererParams): void {
      this.data = params.data;
      this.params = params;
   }

   refresh(): boolean {
      return false;
   }

   onClick() {
      const comp = this.params.api['ets-component'] as CashFlowTrackingComponent;
      if (comp) {
         comp.showSolutionPopup(this.data);
      }
   }
}
