import { Component, Input, OnInit } from '@angular/core';
import { SellOptionsBlockOverviewViewModel as ShortOptionsBlockOverviewViewModel } from './short-options-overview-block.model';

@Component({
   selector: 'ets-short-options-overview-block',
   templateUrl: 'short-options-overview-block.component.html',
   styleUrls: ['short-options-overview-block.component.scss', '../table-styles.scss']
})

export class ShortOptionsOverviewBlockComponent implements OnInit {
   constructor() { }

   private _viewModel: ShortOptionsBlockOverviewViewModel;
   get viewModel(): ShortOptionsBlockOverviewViewModel {
      return this._viewModel;
   }

   @Input()
   set viewModel(v: ShortOptionsBlockOverviewViewModel) {
      this._viewModel = v;
   }

   @Input() mode: string;

   ngOnInit() { }
}
