<dx-popup title="Replace Order" [width]="350" [height]="210" [showTitle]="true" [closeOnOutsideClick]="false"
   [resizeEnabled]="false" [dragEnabled]="true" (onHidden)="onPopupClosed()">
   
   <div *dxTemplate="let data of 'content'">
      <div id="container">
         <div class="ets-row">
            <div class="ets-column header">Symbol</div>
            <div class="ets-column header">Market Level</div>
         </div>

         <div class="ets-row">
            <div class="ets-column"> {{ symbol }} </div>
            <div class="ets-column">  {{ bid }} / {{ ask }} </div>
         </div>

         <div class="ets-row">
            <hr>
         </div>

         <div class="ets-row">
            <div class="ets-column header">Price</div>
            <div class="ets-column header">Quantity</div>
         </div>

         <div class="ets-row">
            <div class="ets-column">
               <dx-number-box mode="number" [format]="numberFormat" [(value)]="orderPrice" [showSpinButtons]="true"
                  [showClearButton]="true" [step]="tickSize"></dx-number-box>
            </div>
            <div class="ets-column">
               <dx-number-box mode="number" [(value)]="orderQty" [min]="1" [showSpinButtons]="true"
                  [showClearButton]="true" [step]="1"></dx-number-box>
            </div>
         </div>

         <div class="ets-row buttons">
            <div class="ets-column">
               <dx-button type="normal" text="Replace" [disabled]="!canReplaceOrder" (onClick)="onReplaceClicked()">
               </dx-button>
            </div>
            <div class="ets-column">
               <dx-button type="default" text="Close Window" (onClick)="this.onPopupClosed()"></dx-button>
            </div>
         </div>
      </div>
   </div>
</dx-popup>