import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { SettingsStorageService } from '../settings-storage-service.service';
import { TimestampsService } from '../timestamps.service';
import { DetectMethodChanges, DetectSetterChanges } from '../utils';
import { DateTime } from 'luxon';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-test-panel',
   templateUrl: 'test-panel.component.html',
   styleUrls: ['test-panel.component.scss'],
})

export class TestPanelComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _timestampsService: TimestampsService
   ) {
      
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   button = {
      text: 'CP',
      stylingMode: 'text',
      onClick: () => {
         alert(new Date().getTime());
      },
   };

   
   private _color: string = 'red';
   get color(): string {
      return this._color;
   }
   // @DetectSetterChanges()
   set color(v: string) {
      this._color = v;
   }


   // @DetectMethodChanges()
   etsOnInit(): void {

   }
   
   etsOnDestroy(): void {
   }
   
   etsAfterViewInit(): void {
      this._changeDetector.detach();
   }
   
   protected getState() {
      return null;
   }
   
   protected setState(state: any) {
   }

   onEventLog(colorPickerClose: string, $event: any) {

   }

   // @DetectMethodChanges()
   onOpen() {

   }

   onColorChange($event: string) {
      this.color = $event;
   }

   onCustomButtonClick() {
      alert('asdfasdf');
   }

   visible: boolean;

   @DetectMethodChanges()
   onClick() {
      this.visible = !this.visible;
   }
}
