import { AuthResultDto } from './dtos/auth-result-dto.inteface';
import { Injectable } from '@angular/core';
import { TerminalLoginResultDto } from '../shell-communication/dtos/terminal-login-result-dto.class';
import { AvailableShellDto } from './dtos/available-shell-dto.interface';

@Injectable({ providedIn: 'root' })
export class SessionService {

   //
   connectedShell: AvailableShellDto;
   
   //
   sessionData: AuthResultDto;
   
   //
   loginResult: TerminalLoginResultDto;

   //
   get isAuthenticated(): boolean {
      return !!this.sessionData && !!this.sessionData.authToken;
   }

   //
   get authToken(): string {
      if (!this.isAuthenticated) { return undefined; }
      return this.sessionData.authToken;
   }

   //
   get isSuperUser(): boolean {
      const sd = this.sessionData;
      if (!sd) {
         return false;
      }
      return sd.isSuperUser;
   }

   //
   get login(): string {
      const sd = this.sessionData;
      if (!sd) {
         return '';
      }
      return sd.userEmail;
   }

   //
   reset() {
      this.sessionData = null;
      this.connectedShell = null;
   }

   //
   getAffectedShells(shellId: string): AvailableShellDto[] {
      let shells: AvailableShellDto[] = [];

      if (shellId) {
         const targetShell = this.sessionData.availableShells.find(s => s.shellId === shellId);
         if (targetShell) {
            shells.push(targetShell);
         }
      } else {
         shells = this.sessionData.availableShells.slice();
      }

      return shells;
   }
}
