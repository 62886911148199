<dx-popup
  width="80%"
  height="51%"
  [showTitle]="true"
  title="Unaccounted Data"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
>
  <div *dxTemplate="let data of 'content'" class="alerts-container">
    <div style="width: 100%; height: 100%; position: relative">
      <dx-tabs [dataSource]="tabs"
               [selectedIndex]="selectedTabIndex"
               (onItemClick)="selectTab($event)"
               style="position: absolute; top: 0"
      ></dx-tabs>
      <div style="position: absolute; top: 35px; width: 100%; height: calc(100% - 32px)">
        <ag-grid-angular
          [ngClass]="{'hidden': selectedTabIndex === 1}"
          class="ag-theme-balham-dark"
          [gridOptions]="positionsGridModel"
        ></ag-grid-angular>
        <ag-grid-angular
          [ngClass]="{'hidden': selectedTabIndex === 0}"
          class="ag-theme-balham-dark"
          [gridOptions]="ordersGridModel"
        ></ag-grid-angular>
      </div>
    </div>
    <dx-load-panel [visible]="isLoading" style="margin: auto; vertical-align: middle"
    ></dx-load-panel>
    <ets-allocate-item-dialog></ets-allocate-item-dialog>
  </div>
</dx-popup>