import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';

/**
 * @returns SessionHistoryStrategyInfoDto[]
 */
export class GetSessionStrategyInfosBySessionId implements ProtocolQuery {
  constructor(public readonly sessionId: string) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSessionStrategyInfosBySessionId`;
  }
}
