import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { ShellConnectionStatusChangedUIMessage } from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { MessageBusService } from '../message-bus.service';
import { filter, takeUntil } from 'rxjs/operators';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged, getPanelStateKey, isNullOrUndefined } from '../utils';
import { SettingsStorageService } from '../settings-storage-service.service';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategiesService } from '../strategies/strategies.service';
import { getUnitsGridModel } from './units-grid-model';
import { getEtalonGridModel } from './etalon-grid-model';
import { ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto, PositionSizingRecordDto } from '../shell-communication/shell-dto-protocol';
import { ToastrService } from 'ngx-toastr';
import { PositionSizingStrategy, StrategyPositionSizing } from '../strategies/strategy-position-sizing.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetAvailableBuckets, GetPositionSizingRecords } from '../shell-communication/shell-operations-protocol';
import { BucketHighlighted, BucketItemHighlighted } from '../ui-messages/ui-messages';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { TerminalDto } from '../shell-communication/dtos/terminal-dto.class';
import { SessionService } from '../authentication/session-service.service';
import { TimestampsService } from '../timestamps.service';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-positions-sizing-report',
   templateUrl: './position-sizing-report.component.html',
   styleUrls: ['./position-sizing-report.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionSizingReportComponent extends PanelBaseComponent {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _strategiesService: StrategiesService,
      private readonly _toastr: ToastrService,
      private readonly _shellClient: ShellClientService,
      private readonly _sessionService: SessionService,
      private readonly _timestampsService: TimestampsService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   private readonly _records: PositionSizingRecordDto[] = [];
   private _unsubscriber: Subject<any> = new Subject<any>();
   private _availableBuckets: GetAvailableBucketsReply;

   private _unitsGrid: GridReadyEvent;
   private _etalonGrid: GridReadyEvent;
   
   unitsGridModel: GridOptions;
   etalonGridModel: GridOptions;
   strategies: StrategyModel[];

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   private _isDetailsWindowVisible = false;
   get isDetailsWindowVisible(): boolean { return this._isDetailsWindowVisible; }
   
   @DetectSetterChanges()
   set isDetailsWindowVisible(val: boolean) { this._isDetailsWindowVisible = val; }

   sizingStrategySettingsString = 'N/A';
   switchToSettingsString = 'N/A';
   ethalonMode: 'Main' | 'Alternative' = 'Main';

   terminals: TerminalDto[] = [];
   portfolios: PortfolioDto[] = [];
   combos: ComboDto[] = [];
   comboGroups: ComboGroupDto[] = [];

   selectedTerminal: TerminalDto;
   selectedPortfolio: PortfolioDto;
   selectedCombo: ComboDto;
   selectedComboGroup: ComboGroupDto;

   selectedStrategy: StrategyModel;
   selectedPortfolioStrategy: StrategyModel;


   get isPortfolioStrategySelectorAvailable(): boolean {
      return this.selectedStrategy &&
         this.selectedStrategy.dispositionStrategies.length > 0;
   }


   get selectedStrategyHasSwitchTo(): boolean {
      if (!this.selectedStrategy) {
         return false;
      }

      return !!this.selectedStrategy.positionSizing.switchTo;
   }


   async etsOnInit() {

      this.unitsGridModel = getUnitsGridModel.bind(this)();
      this.etalonGridModel = getEtalonGridModel.bind(this)();

      try {



         this.isLoading = true;
         
         this.terminals = this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy);
         
         const qry = new GetAvailableBuckets();
         const reply = await this._shellClient.processQuery<GetAvailableBucketsReply>(qry);
         
         this._availableBuckets = reply;

      } finally {

         this.isLoading = false;

      }

      this.subscribeToMessages();
      // this._loadLastHighlightedStrategy();
   }


   etsAfterViewInit() {  }


   etsOnDestroy(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   
   onUnitsGridReady(args: GridReadyEvent): void {
      this._unitsGrid = args;
      args.api.sizeColumnsToFit();
   }


   onEtalonGridReady(args: GridReadyEvent): void {
      this._etalonGrid = args;
      args.api.sizeColumnsToFit();
   }


   @DetectMethodChanges()
   changeEthalonMode() {
      if (this.ethalonMode === 'Main') {
         this.ethalonMode = 'Alternative';
      } else {
         this.ethalonMode = 'Main';
      }

      let strategy = this.selectedStrategy;
      if (strategy) {
         if (this.selectedStrategy.isDisposition) {
            strategy = this.selectedPortfolioStrategy;
         }
      } else {
         strategy = this.selectedPortfolioStrategy;
      }

      if (strategy) {
         this.fillEtalonTable(strategy);
      }
   }


   @DetectMethodChanges()
   onTerminalChanged(ev: DxValueChanged<TerminalDto>, noAutoSelect?: boolean) {
      
      if (!ev.event) {
         return;
      }

      const terminal = ev.value;

      if (terminal) {

         const strats = this.getStrategiesByTerminal(terminal.terminalId);
         this.portfolios = this._availableBuckets.portfolios.filter(x => x.terminalId === terminal.terminalId);
         this.strategies = strats;

         if (this.strategies.length === 1 && !noAutoSelect) {
            if (ev.event !== 'msg') {
               this.onStrategyHighlighted(this.strategies[0], true);
            }
         } else {
            
            this.selectedPortfolio = undefined;
            this.selectedCombo = undefined;
            this.selectedComboGroup = undefined;
            this.selectedStrategy = undefined;
            
         }

      } else {

         this.portfolios = [];
         this.combos = [];
         this.comboGroups = [];
         this.strategies = [];

         this.selectedPortfolio = undefined;
         this.selectedCombo = undefined;
         this.selectedComboGroup = undefined;
         this.selectedStrategy = undefined;
      }

   }


   @DetectMethodChanges()
   onPortfolioChanged(ev, noAutoSelect?: boolean) {
      if (!ev.event) {
         return;
      }

      const portfolio: PortfolioDto = ev.value;

      if (portfolio) {

         const strats = this.getStrategiesByPortfolio(portfolio.portfolioId);
         this.combos = this._availableBuckets.combos.filter(x => x.portfolioId === portfolio.portfolioId);
         this.strategies = strats;

         if (this.strategies.length === 1 && !noAutoSelect) {
            if (ev.event !== 'msg') {
               this.onStrategyHighlighted(this.strategies[0], true);
            }
         } else {
            
            this.selectedCombo = undefined;
            this.selectedComboGroup = undefined;
            this.selectedStrategy = undefined;
            
         }

      } else {

         this.combos = [];
         this.comboGroups = [];
         this.strategies = [];

         this.selectedPortfolio = undefined;
         this.selectedCombo = undefined;
         this.selectedComboGroup = undefined;
         this.selectedStrategy = undefined;

      }
   
   }


   @DetectMethodChanges()
   onComboChanged(ev, noAutoSelect?: boolean) {
      if (!ev.event) {
         return;
      }

      const combo: ComboDto = ev.value;

      if (combo) {
         const strats = this.getStrategiesByCombo(combo.comboId);
         this.comboGroups = this._availableBuckets.comboGroups.filter(x => x.comboId === combo.comboId);
         this.strategies = strats;

         if (this.strategies.length === 1 && !noAutoSelect) {
            if (ev.event !== 'msg') {
               this.onStrategyHighlighted(this.strategies[0], true);
            }
         } else {

            this.selectedComboGroup = undefined;
            this.selectedStrategy = undefined;

         }

      } else {

         this.comboGroups = [];

         this.selectedCombo = undefined;
         this.selectedComboGroup = undefined;
         this.selectedStrategy = undefined;

         this.strategies = this.selectedPortfolio 
            ? this.getStrategiesByPortfolio(this.selectedPortfolio.portfolioId)
            : [];
      }
   }


   @DetectMethodChanges()
   onComboGroupChanged(ev, noAutoSelect?: boolean) {
      if (!ev.event) {
         return;
      }

      const comboGroup: ComboGroupDto = ev.value;

      if (comboGroup) {
         const strats = this.getStrategiesByComboGroup(comboGroup.comboGroupId);
         this.comboGroups = this._availableBuckets.comboGroups.filter(x => x.comboId === comboGroup.comboId);
         this.strategies = strats;

         if (this.strategies.length === 1 && !noAutoSelect) {
            if (ev.event !== 'msg') {
               this.onStrategyHighlighted(this.strategies[0], true);
            }
         } else {
            
            this.selectedStrategy = undefined;
         }

      } else {

         this.selectedComboGroup = undefined;
         this.selectedStrategy = undefined;
         
         this.strategies = this.selectedCombo
            ? this.getStrategiesByCombo(this.selectedCombo.comboId)
            : [];
      }
   }

   @DetectMethodChanges()
   onSelectedStrategyChanged(ev: DxValueChanged<StrategyModel>) {
      this.selectedStrategy = ev.value;
      if (!ev.event) {
         return;
      }
      this.onStrategyChanged(this.selectedStrategy);
   }

   @DetectMethodChanges()
   onSelectedPortfolioStrategyChanged(ev: DxValueChanged<StrategyModel>) {
      this.selectedPortfolioStrategy = ev.value;
      if (!ev.event) {
         return;
      }
      this.onStrategyChanged(this.selectedPortfolioStrategy);
   }

   private onStrategyChanged(strategy: StrategyModel) {
      if (strategy) {
         if (!strategy.isDisposition) {
            setTimeout(() => {
               this.updateStrategyInfo(strategy);
               this.loadStrategyData(strategy);
            });   
         } else {
            setTimeout(() => {
               this._unitsGrid.api.setRowData([]);
               this._etalonGrid.api.setRowData([]);
            });   
         }
      } else {
         setTimeout(() => {
            this._unitsGrid.api.setRowData([]);
            this._etalonGrid.api.setRowData([]);
         });
      }
   }

   private getStrategiesByTerminal(terminalId: string): StrategyModel[] {
      const strats = this._strategiesService
         .getAllStrategies()
         .filter(x => x.positionSizing && !isNullOrUndefined(x.positionSizing.sizingStrategy))
         .filter(x => x.terminalId === terminalId);

      return strats;
   }


   private getStrategiesByPortfolio(portfolioId: string): StrategyModel[] {
      const strats = this._strategiesService
         .getAllStrategies()
         .filter(x => x.positionSizing && !isNullOrUndefined(x.positionSizing.sizingStrategy))
         .filter(x => x.portfolioId === portfolioId);

      return strats;
   }
   
   
   private getStrategiesByCombo(comboId: string): StrategyModel[] {
      const strats = this._strategiesService
         .getAllStrategies()
         .filter(x => x.positionSizing && !isNullOrUndefined(x.positionSizing.sizingStrategy))
         .filter(x => x.comboId === comboId);

      return strats;
   }


   private getStrategiesByComboGroup(comboGroupId: string): StrategyModel[] {
      const strats = this._strategiesService
         .getAllStrategies()
         .filter(x => x.positionSizing && !isNullOrUndefined(x.positionSizing.sizingStrategy))
         .filter(x => x.comboGroupId === comboGroupId);

      return strats;
   }


   private subscribeToMessages() {
      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onStrategyHighlightedMessage(msg.payload));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onClearTradingDataMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onShellConnectionStatusChanged(msg.payload));

      this._messageBus
         .of<any>('WorkspaceClosed')
         .pipe(
            filter(msg => msg.payload.workspaceId === this.workspaceId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onRemovedFromWorkspace());

      this._messageBus.of<PositionSizingRecordDto>('PositionSizingRecordDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onPositionSizingRecordDto(msg.payload));

      this._messageBus.of<BucketHighlighted>('BucketHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId)
         )
         .subscribe(msg => this.onBucketHighlighted(msg.payload));
      
      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId),
            filter(msg => msg.payload.item.itemType === PortfolioItemType.Strategy)
         )
         .subscribe(msg => this.onBucketItemHighlighted(msg.payload));
   }
   
   
   private onBucketItemHighlighted(msg: BucketItemHighlighted): void {
      if (msg.item.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const strategy = this._strategiesService.getById(msg.item.portfolioItemId);
      this.onStrategyHighlighted(strategy);
   }


   private onBucketHighlighted(msg: BucketHighlighted): void {

      const ctx = msg.bucketContext;

      if (ctx.bucketType === 'Portfolio') {
         
         this.selectedPortfolio = this._availableBuckets.portfolios.find(x => x.portfolioId === ctx.bucketId);
         this.onPortfolioChanged({ value: this.selectedPortfolio, event: 'message' });

      } else if (ctx.bucketType === 'Combo') {
         
         this.selectedCombo =  this._availableBuckets.combos.find(x => x.comboId === ctx.bucketId);
         this.onComboChanged({ value: this.selectedCombo, event: 'message' });

      } else if (ctx.bucketType === 'ComboGroup') {
         
         this.selectedComboGroup =  this._availableBuckets.comboGroups.find(x => x.comboGroupId === ctx.bucketId);
         this.onComboGroupChanged({ value: this.selectedComboGroup, event: 'message' });
         
      } else if (ctx.bucketType === 'Terminal' || ctx.bucketType === '<No Bucket>') {
         
         const terminal = this.terminals.find(x => x.terminalId === ctx.bucketId);
         
         if (terminal) {
            this.selectedTerminal = terminal;
            this.onTerminalChanged({value: terminal, event: 'message'});
         }
      }

   }


   private onPositionSizingRecordDto(dto: PositionSizingRecordDto) {
      if (!this.selectedStrategy && !this.selectedPortfolioStrategy) {
         return;
      }

      const a = this.selectedPortfolioStrategy && (this.selectedPortfolioStrategy.strategyId === dto.strategyId);
      const b = this.selectedStrategy && (this.selectedStrategy.strategyId === dto.strategyId);

      if (a || b) {
         if (this._records.length > 0) {
            const lastRecord = this._records[this._records.length - 1];
            dto.switchHappened = lastRecord.isSwitched !== dto.isSwitched;
         }

         this._records.push(dto);

         (dto as any).sequenceNum = this._records.length;

         this._unitsGrid.api.setRowData(this._records);
      }
   }


   private async onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage) {
      const strategy = this._strategiesService.getById(message.strategyId);
      this.onStrategyHighlighted(strategy);
   }

   //

   @DetectMethodChanges()
   private async onStrategyHighlighted(strategy: StrategyModel, dontFillBuckets?: boolean) {
      if (!strategy) {
         return;
      }

      const sizingSettings = this.getPositionSizingSettings(strategy);

      if (isNullOrUndefined(sizingSettings)) {
         this.selectedPortfolioStrategy = null;
         this.selectedStrategy = null;
         this.onStrategyChanged(null);
         return;
      }


      if (this.selectedStrategy) {
         if (this.selectedStrategy.strategyId === strategy.strategyId) {
            if (this.selectedPortfolioStrategy) {
               // means disposition is higlighted and its inner strategy is currently selected
               // should reset selection to relaod data
               this.selectedPortfolioStrategy = null;
               this.selectedStrategy = null;
               this.onStrategyChanged(null);
            } else {
               return;
            }
         }
      } else if (this.selectedPortfolioStrategy) {
         if (this.selectedPortfolioStrategy.strategyId === strategy.strategyId) {
            return;
         }
      }
      
      // fill buckets
      if (strategy.terminalId && !dontFillBuckets) {
         const term = this.terminals.find(x => x.terminalId === strategy.terminalId);
         this.selectedTerminal = term;
         this.onTerminalChanged({ value: term, event: 'msg' }, true);
      }

      if (strategy.portfolioId && !dontFillBuckets) {
         const pf = this._availableBuckets.portfolios.find(x => x.portfolioId === strategy.portfolioId);
         this.selectedPortfolio = pf;
         this.onPortfolioChanged({ value: this.selectedPortfolio, event: 'msg' }, true);
      }

      if (strategy.comboId && !dontFillBuckets) {
         const combo = this._availableBuckets.combos.find(x => x.comboId === strategy.comboId);
         this.selectedCombo = combo;
         this.onComboChanged({ value: this.selectedCombo, event: 'msg' }, true);
      }
      
      if (strategy.comboGroupId && !dontFillBuckets) {
         const comboGroup = this._availableBuckets.comboGroups.find(x => x.comboGroupId === strategy.comboGroupId);
         this.selectedComboGroup = comboGroup;
         this.onComboGroupChanged({ value: this.selectedComboGroup, event: 'msg' }, true);
      }

      // set selected strategy and load data
      if (!isNullOrUndefined(strategy.dispositionId)) { // it's inner strategy

         const dispo = this._strategiesService.getById(strategy.dispositionId);

         if (dispo) {
            this.selectedStrategy = dispo;
            this.selectedPortfolioStrategy = strategy;
            this.onStrategyChanged(strategy);
         }

      } else { // it's regular strategy or disposition itself
         
         this.selectedStrategy = strategy;
         this.selectedPortfolioStrategy = null;
         this.onStrategyChanged(strategy);
      }
   }

   //

   private getPositionSizingSettings(strategy: StrategyModel): StrategyPositionSizing {
      let settings = strategy.positionSizing;
      
      if (!settings || !settings.sizingStrategy) {
         if (!isNullOrUndefined(strategy.dispositionId)) {
            const dispo = this._strategiesService.getById(strategy.dispositionId);
            settings = dispo.positionSizing;
            if (!settings || !settings.sizingStrategy) {
               return;
            }
         } else {
            return;
         }
      }
      return settings;
   }

   //

   private async onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {
      this._records.length = 0;
      this._unitsGrid.api.setRowData([]);

      if (this.selectedStrategy) {
         this.loadStrategyData(this.selectedStrategy);
      }
   }


   private onClearTradingDataMessage(message: ClearTradingDataUIMessage): void {
      this._records.length = 0;
      this._unitsGrid.api.setRowData([]);

      if (this.selectedStrategy) {
         if (message.strategies.indexOf(this.selectedStrategy.strategyId) > -1) {
            this.loadStrategyData(this.selectedStrategy);
         }
      }
   }


   private async loadStrategyData(strategy: StrategyModel) {
      this._unitsGrid.api.showLoadingOverlay();
      this._etalonGrid.api.showLoadingOverlay();
      try {
         const qry = new GetPositionSizingRecords(strategy.strategyId);
         const dtos = await this._shellClient.processQuery<PositionSizingRecordDto[]>(qry, strategy.shellId);
         this._records.length = 0;

         dtos.forEach(dto => {
            if (this._records.length > 0) {
               const lastRecord = this._records[this._records.length - 1];
               dto.switchHappened = lastRecord.isSwitched !== dto.isSwitched;
            }
            this._records.push(dto);
            (dto as any).sequenceNum = this._records.length;
         });

         this._unitsGrid.api.setRowData(this._records);
         this.fillEtalonTable(strategy);
      } catch {
         this._toastr.error(`"Load Positions Sizing Records" operation completed with errors`);
      } finally {
         this._unitsGrid.api.hideOverlay();
         this._etalonGrid.api.hideOverlay();
      }

   }


   private fillEtalonTable(strategy: StrategyModel) {
      if (!strategy) {
         this._toastr.warning('Strategy not set');
         return;
      }

      const multiplier = this.getMultiplier(strategy);

      const sizingParams = this.getPositionSizingSettings(strategy);

      const accountSize = sizingParams.baseAccountSize || 0;

      const isAlternativeMode = this.ethalonMode === 'Alternative';

      const sizingStrategy = isAlternativeMode ? sizingParams.switchTo : sizingParams.sizingStrategy;

      if (!sizingStrategy) {
         this._toastr.warning('Sizing Strategy Not Set');
         return;
      }

      let sizingStrategyRows = [];
      let altSizingStrategyRows = [];

      if (sizingStrategy === PositionSizingStrategy.FixedRatio) {

         sizingStrategyRows = this.fillFixedRatioEthalon(multiplier, sizingParams, accountSize, isAlternativeMode);

         if (sizingParams.switchTo) {
            altSizingStrategyRows = this.fillFixedFractionalEthalon(sizingParams, accountSize, !isAlternativeMode);
         }

      } else if (sizingStrategy === PositionSizingStrategy.FixedFractional) {

         sizingStrategyRows = this.fillFixedFractionalEthalon(sizingParams, accountSize, isAlternativeMode);

         if (sizingParams.switchTo) {
            altSizingStrategyRows = this.fillFixedRatioEthalon(multiplier, sizingParams, accountSize, !isAlternativeMode);
         }

      } else {

         this._toastr.warning('Strategy has unknown position sizing strategy');

         return;
      }

      if (sizingParams.switchTo) {
         if (sizingParams.switchWhenMoreEfficient) {
            if (altSizingStrategyRows.length > 0) {

               let switchRecord = null;

               const altEnumerable = isAlternativeMode ? sizingStrategyRows : altSizingStrategyRows;
               const mainEnumerable = isAlternativeMode ? altSizingStrategyRows : sizingStrategyRows;

               for (const altEl of altEnumerable) {
                  const level = altEl.increaseLevel;
                  const units = altEl.unitsToTrade;

                  const mainEl = mainEnumerable.filter(x => x.increaseLevel <= level).pop();

                  if (!mainEl) {
                     continue;
                  }

                  const mainLevel = mainEl.increaseLevel;
                  const mainUnits = mainEl.unitsToTrade;

                  if (units > mainUnits) {
                     if (mainLevel === level) {
                        switchRecord = isAlternativeMode ? altEl : mainEl;
                     } else {
                        if (isAlternativeMode) {
                           switchRecord = altEnumerable.slice().reverse().filter(x => x.increaseLevel < level)[0];
                        } else {
                           switchRecord = mainEnumerable.filter(x => x.increaseLevel > mainLevel)[0];
                        }
                     }
                     break;
                  }
               }

               if (switchRecord) {
                  switchRecord.switchLevel = true;
               }
            }

         } else if (sizingParams.switchWhenProfit) {

            const length = sizingStrategyRows.length;
            const whenProfit = sizingParams.switchWhenProfit;

            for (let index = 0; index < length; index++) {
               const mainElement = sizingStrategyRows[index];
               if (mainElement.increaseLevel >= whenProfit) {
                  mainElement.switchLevel = true;
                  break;
               }
            }
         }
      }

      this._etalonGrid.api.setRowData(sizingStrategyRows);
   }


   private fillFixedRatioEthalon(multiplier: number, sizingParams: StrategyPositionSizing, accountSize: number, isAlternativeMode?: boolean): any[] {
      const delta = isAlternativeMode ? sizingParams.altDeltaSize : sizingParams.deltaSize;

      if (!delta) {
         this._toastr.warning('Position Sizing parameter missing: "Delta"');
         return;
      }

      const rows = [];

      const unitSize = (isAlternativeMode ? sizingParams.altRatioUnitSize : sizingParams.ratioUnitSize) || 1;

      for (let index = 0; index < 100; index++) {
         const unitsToTrade = index;
         const profit = ((index * (index + 1)) / 2) * delta;

         const record = {
            recordId: index,
            unitsToTrade,
            increaseLevel: accountSize + profit,
            multiplier: unitsToTrade * unitSize + multiplier
         };

         rows.push(record);
      }

      return rows;
   }


   private fillFixedFractionalEthalon(sizingParams: StrategyPositionSizing, accountSize: number, isAlternativeMode?: boolean): any[] {
      const rows = [];

      const unitPerAmount = isAlternativeMode ? sizingParams.altUnitPerAmount : sizingParams.unitPerAmount;
      const unitPerPercent = isAlternativeMode ? sizingParams.altUnitPerPercent : sizingParams.unitPerPercent;
      const riskPerUnit = isAlternativeMode ? sizingParams.altRiskPerUnit : sizingParams.riskPerUnit;
      const unitSize = (isAlternativeMode ? sizingParams.altFractionalUnitSize : sizingParams.fractionalUnitSize) || 1;

      if (unitPerAmount) {

         for (let index = 1; index <= 100; index++) {
            const increaseLevel = accountSize + unitPerAmount * index;
            const unitsToTrade = Math.floor(increaseLevel / unitPerAmount);

            const record = {
               recordId: index,
               increaseLevel,
               unitsToTrade,
               qty: unitsToTrade * unitSize
            };

            rows.push(record);
         }
      } else if (unitPerPercent) {

         const step = riskPerUnit / (unitPerPercent / 100);

         for (let index = 1; index <= 100; index++) {
            const increaseLevel = accountSize + step * index;
            const unitsToTrade = Math.floor((step * index) / step);

            const record = {
               recordId: index,
               increaseLevel,
               unitsToTrade,
               qty: unitsToTrade * unitSize
            };

            rows.push(record);
         }
      }

      return rows;
   }


   private updateStrategyInfo(v: StrategyModel) {
      this.sizingStrategySettingsString = 'N/A';
      this.switchToSettingsString = 'N/A';

      const settings = this.getPositionSizingSettings(v);

      if (isNullOrUndefined(settings)) {
         return;
      }

      let sizingStr = PositionSizingStrategy[settings.sizingStrategy];
      if (settings.sizingStrategy === PositionSizingStrategy.FixedRatio) {
         sizingStr += `, Unit Size=${settings.ratioUnitSize}`;
         sizingStr += `, Delta=$${settings.deltaSize}`;
      } else {
         sizingStr += `, Unit Size=${settings.fractionalUnitSize}`;
         if (settings.unitPerAmount) {
            sizingStr += `, Units Per $ Amount=${settings.unitPerAmount}`;
         }
         if (settings.unitPerPercent) {
            sizingStr += `, Units Per %=${settings.unitPerPercent}`;
            sizingStr += `, Risk=${settings.riskPerUnit}`;
         }
      }

      this.sizingStrategySettingsString = sizingStr;

      if (settings.switchTo) {
         let switchToStr = PositionSizingStrategy[settings.switchTo];
         if (settings.switchTo === PositionSizingStrategy.FixedRatio) {
            switchToStr += `, Unit Size=${settings.altRatioUnitSize}`;
            switchToStr += `, Delta=$${settings.altDeltaSize}`;
         } else {
            switchToStr += `, Unit Size=${settings.altFractionalUnitSize}`;
            if (settings.altUnitPerAmount) {
               switchToStr += `, Units Per $ Amount=${settings.altUnitPerAmount}`;
            }
            if (settings.altUnitPerPercent) {
               switchToStr += `, Units Per %=${settings.altUnitPerPercent}`;
               switchToStr += `, Risk=${settings.altRiskPerUnit}`;
            }
            if (settings.switchWhenMoreEfficient) {
               switchToStr += ', Switch When More Efficient';
            } else {
               switchToStr += `, Switch When Profit=${settings.switchWhenProfit}`;
            }

            if (settings.stayWhenSwitched) {
               switchToStr += ', Stay Switched';
            }
         }
         this.switchToSettingsString = switchToStr;
      }
   }

   private getMultiplier(strategy: StrategyModel): number {
      const params = strategy.parameters;
      if (!params) {
         return undefined;
      }
      // tslint:disable-next-line: no-string-literal
      const ml = parseInt(params['multiplier']);
      return ml;
   }


   protected getState(): any { return null; }


   protected setState(state) { }
}
