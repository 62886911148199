// tslint:disable: no-string-literal
import { ParametersControlBase } from '../../../parameters-control-base';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { isTruthy } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { ICommonDispositionParameters } from '../../../common-disposition-parameters';
import { ComboDto, ComboGroupDto, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { FreezeHedgeParametersViewComponent } from './freeze-hedge-parameters-view.component';


export class FreezeHedgeAlgoParametersControl extends ParametersControlBase<FreezeHedgeParametersViewComponent> {
   

   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);
   }

   symbol: TradingInstrument;
   
   availablePortfolios: PortfolioDto[];
   availableCombos: ComboDto[];
   availableComboGroups: ComboGroupDto[];

   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;

   targetPortfolio: string;
   targetCombo: string;
   targetComboGroup: string;
   deltaFreezeInstrument: 'SPY ETF' | 'E-mini S&P500' | 'Custom' | 'Micro E-mini S&P500';
   orderType: 'Market' | 'Limit';
   customBeta: number;
   orderDistance: number;
   hedgeRounding: 'Up' | 'Down';

   get algoId(): string {
      return EtsConstants.algorithms.freezeHedgeAlgoId;
   }

   
   onStrategyTerminalChanged() {
   
      try {
         this.availablePortfolios = this.availableCombos = this.availableComboGroups = null;
         this.targetPortfolio = this.targetCombo = this.targetComboGroup = null;
   
         if (!this.holder.selectedTerminal) {
            return;
         }
   
         const portfolios = this.holder.availableBuckets.portfolios.filter(x => x.terminalId === this.holder.selectedTerminal.terminalId);
         this.availablePortfolios = portfolios;

      } finally {

         this.hasChanges();

      }
   
   }

   
   onTargetPortfolioChanged() {
   
      try {
         
         this.availableCombos = this.availableComboGroups = null;
         
         this.targetCombo = this.targetComboGroup = null;
   
         if (!this.targetPortfolio) {
            return;
         }
         
         const combos = this.holder.availableBuckets.combos.filter(x => x.portfolioId === this.targetPortfolio);
         this.availableCombos = combos;

      } finally {

         this.hasChanges();

      }

   }

   
   onTargetComboChanged() {
      
      try {
         
         this.availableComboGroups = null;
         this.targetComboGroup = null;
   
         if (!this.targetCombo) {
            return;
         }
   
         const groups = this.holder.availableBuckets.comboGroups.filter(x => x.comboId === this.targetCombo);
         this.availableComboGroups = groups;

      } finally {
         this.hasChanges();
      }

   }


   _GetParameters(): StrategyParameters {

      const params: StrategyParameters = {};

      if (this.deltaFreezeInstrument === 'SPY ETF') {
         const spyEtf = this._TradingInstrumentsService.getInstrumentByTicker('SPY');
         this.symbol = spyEtf;
      } else if (this.deltaFreezeInstrument === 'E-mini S&P500') {
         const sp500 = this._TradingInstrumentsService.getInstrumentByTicker('ESM2');
         this.symbol = sp500;
      } else if (this.deltaFreezeInstrument === 'Micro E-mini S&P500') {
         const msp500 = this._TradingInstrumentsService.getInstrumentByTicker('MESM2');
         this.symbol = msp500;
      }

      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['istradethroughmit'] = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;
      params['targetportfolio'] = isTruthy(this.targetPortfolio) ? this.targetPortfolio + '' : null;
      params['targetcombo'] = isTruthy(this.targetCombo) ? this.targetCombo + '' : null;
      params['targetcombogroup'] = isTruthy(this.targetComboGroup) ? this.targetComboGroup + '' : null;
      params['ordertype'] = isTruthy(this.orderType) ? this.orderType + '' : null;
      params['deltafreezeinstrument'] = isTruthy(this.deltaFreezeInstrument) ? this.deltaFreezeInstrument + '' : null;
      params['hedgerounding'] = isTruthy(this.hedgeRounding) ? this.hedgeRounding + '' : null;

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker + '' : null;
      params['custombeta'] = isTruthy(this.customBeta) ? this.customBeta + '' : null;
      
      params['orderdistance'] = null;
   
      if (this.orderType === 'Limit') {
         params['orderdistance'] = isTruthy(this.orderDistance) ? this.orderDistance + '' : null;
      }

      return params;
   }

   
   _SetParameters(dto: StrategyDto) {

      this.onStrategyTerminalChanged();
      
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }
    
      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughmit'])) {
         this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
      }

      if (isTruthy(params['targetportfolio'])) {
         const portfolioId = params['targetportfolio'];
         this.targetPortfolio = portfolioId;
         this.onTargetPortfolioChanged();
      }

      if (isTruthy(params['targetcombo'])) {
         const comboId = params['targetcombo'];
         this.targetCombo = comboId;
         this.onTargetComboChanged();
      }

      if (isTruthy(params['targetcombogroup'])) {
         const comboGroupId = params['targetcombogroup'];
         this.targetComboGroup = comboGroupId;
      }

      if (isTruthy(params['ordertype'])) {
         this.orderType = params['ordertype'];
      }

   
      if (isTruthy(params['deltafreezeinstrument'])) {
         this.deltaFreezeInstrument = params['deltafreezeinstrument'];
      }
      
      if (isTruthy(params['custombeta'])) {
         this.customBeta = parseFloat(params['custombeta']);
      }

      if (isTruthy(params['orderdistance'])) {
         this.orderDistance = parseFloat(params['orderdistance']);
      }

      if (isTruthy(params['hedgerounding'])) {
         this.hedgeRounding = params['hedgerounding'];
      }
   }

   
   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!this.symbol) {
         errors.push('Symbol is mandatory');
      }

      if (!this.deltaFreezeInstrument) {
         errors.push('Delta Hedge Instrument is Required');
      }

      if (!this.hedgeRounding) {
         errors.push('"Hedge Rounding" is required');
      }

      if (!this.orderType) {
         errors.push('Order type is required');
      }

      if (!this.targetPortfolio) {
         errors.push('Target portfolio is required');
      }

      if (!this.targetCombo) {
         if (this.targetComboGroup) {
            errors.push('Target combo is required');
         }
      }

      return errors;
   }

   
   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean): void {
      //
   }
}
