import { Component, Input, OnInit } from '@angular/core';
import { Actions, StrategyCommanderEditorParameters } from '../default-editor-parameters';
import StartStrategiesByFamily = Actions.StartStrategiesByFamily;
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';

@Component({
  selector: 'exit-strategies-by-family',
  template: `
    <div style="margin-top: 30px; margin-bottom: 10px">Select Family:</div>
    <dx-select-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      [items]="['Limit', 'BullBear']"
      [(value)]="parameters.family"
    ></dx-select-box>
  `
})

export class ExitStrategiesByFamilyComponent implements OnInit {
  constructor() {
  }

  @Input()
  parameters: any;

  ngOnInit() {
  }

  public static fillParameters(
    parameters: string, 
    paramsObj: StrategyCommanderEditorParameters, 
    tradingInstrumentService: TradingInstrumentsService
    ) {
    paramsObj.actions.exitStrategiesByFamily = JSON.parse(parameters);
  }

  public static getParameters(paramsObj: Actions.ExitStrategiesByFamily): string {
    return JSON.stringify(paramsObj);
  }

  static checkParameters(parameters: Actions.ExitStrategiesByFamily) {
    if (!parameters.family) {
      return false;
    }
    return true;
  }
}