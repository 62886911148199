import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { GatewayStatusPipe } from './gateway-status.pipe';
import { GatewayManagerComponent } from './gateway-manager.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
  imports: [
    CommonModule,    
    AgGridModule.withComponents([]),
    DevExtremeModule
  ],
  
  declarations: [
    GatewayStatusPipe, 
    GatewayManagerComponent
  ],
  
  exports: [
    GatewayManagerComponent
  ],

  providers: [
    { provide: 'WINDOW', useValue: window }
  ]
})
export class EtsGatewayManagerModule { }
