import { ProtectiveOptionParametersOverviewBlockDto } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { isValidNumber } from "projects/shared-components/utils";

export class ProtectiveOptionParametersOverviewBlock {
   
   constructor(
      public readonly header: string
   ) {
      this.backgroundColor = '#50015e';
    }

   get hasSettings() {
      return isValidNumber(this.rollDaysToExpiration, true);
   };

   backgroundColor: string;
   color: string;

   spreadOffset: number;
   rollXDaysBeforeExpiration: number;
   rollDaysToExpiration: number;


   //
   setDto(dto: ProtectiveOptionParametersOverviewBlockDto, slot: 'first' | 'second') {
      this.spreadOffset = slot === 'first'
         ? dto.spreadOffset
         : dto.spreadOffset2;

      this.rollXDaysBeforeExpiration = slot === 'first'
         ? dto.rollXDaysBeforeExpiration
         : dto.rollXDaysBeforeExpiration2;

      this.rollDaysToExpiration = slot === 'first'
         ?  dto.rollDaysToExpiration
         : dto.rollDaysToExpiration2;
   }

   //
   reset() {
      this.spreadOffset = undefined;
      this.rollXDaysBeforeExpiration = undefined;
      this.rollDaysToExpiration = undefined;
   }

}
