import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { InterestOverviewBlockDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, militaryTimeToAmPm } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-interest-sidebox',
   templateUrl: './interest-sidebox.component.html',
   styleUrls: ['./interest-sidebox.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterestSectionSideboxComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService
   ) { }

   hasSettings: boolean;
   strategyId: string;
   isStrategyRunning: boolean;
   amount: number;
   interestRate: number;
   interval: string;
   account: number;
   chargeTime: string;
   chargeTimezone: string;

   ngOnInit(): void { }

   //
   @DetectMethodChanges()
   setData(dto: InterestOverviewBlockDto) {
      this.hasSettings = true;

      this.strategyId = dto.strategyId;
      this.isStrategyRunning = dto.isStrategyRunning;
      this.amount = dto.amount;
      this.interestRate = dto.interestRate;
      this.interval = dto.interval;
      this.account = dto.account;
      this.chargeTime = militaryTimeToAmPm(dto.chargeTime);
      this.chargeTimezone = dto.chargeTimezone;
   }

   //
   @DetectMethodChanges()
   reset() {
      this.hasSettings = false;
      this.strategyId = null;
      this.isStrategyRunning = null;
      this.amount = null;
      this.interestRate = null;
      this.interval = null;
      this.account = null;
      this.chargeTime = null;
      this.chargeTimezone = null;
   }
}
