<dx-popup width="950px" height="790px" [showTitle]="true" title="Attach Strategy Dialog" [closeOnOutsideClick]="false"
      [resizeEnabled]="true" [dragEnabled]="true" [(visible)]="this.isVisible" 
      (onHidden)="this.onHidden()">
   <div *dxTemplate="let data of 'content'" class="dialog" #popup>

      <div class="container">
         <div class="list-of-strats">

            <div class="vblock strats">
               <fieldset>
                  <legend>Attachments</legend>
                  <dx-list [items]="this.attachmentsList" selectionMode="single"
                        [selectedItems]="this.selectedAttachment"
                        (selectedItemsChange)="this.onSelectedAttachmentChange($event)"
                        [disabled]="this.config?.mode === 'update' && this.config?.ctx === 'direct'">
                     <div *dxTemplate="let data of 'item'">
                        <div>{{data.name}}</div>
                     </div>
                  </dx-list>
               </fieldset>
            </div>

            <div class="vblock legs" *ngIf="this.config?.ctx === 'attach'">
               <fieldset>
                  <legend>Legs</legend>
                  <div *ngFor="let wrapper of this.availableLegs; index as ix" class="leg">
                     <div class="col checkbox">
                        <dx-check-box [(value)]="wrapper.leg.attachTo" (onValueChanged)="this.onAttachLegChanged(ix, $event)"
                           [disabled]="!wrapper.isAvailable">
                        </dx-check-box>
                     </div>
                     <div class="col name" [style]="wrapper.leg.getLegColor()" [class.blocked]="!wrapper.isAvailable">
                        {{wrapper.leg.displayName}}
                     </div>
                  </div>
               </fieldset>
            </div>
         </div>

         <div class="parameters">
            <fieldset>
               <legend>Parameters</legend>
               
               <div class="params put-spread" *ngIf="this.selectedAttachment[0]?.name === 'Put Spread'">
                  
                 <ets-put-spread-strategy [parameters]="this.putSpreadParameters"></ets-put-spread-strategy>

               </div>

               <div class="params long-option" *ngIf="this.selectedAttachment[0]?.name === 'Long Option'">

                  <ets-long-option-strategy [parameters]="this.longOptionParameters"></ets-long-option-strategy>
             
               </div>

               <div class="params short-option" *ngIf="this.selectedAttachment[0]?.name === 'Short Option'">

                  <ets-short-option-strategy [parameters]="this.shortOptionParameters"></ets-short-option-strategy>

               </div>
           
               <div class="params put-sread-roll" *ngIf="this.selectedAttachment[0]?.name === 'Put Debit Spread Roll'">

                  <ets-put-debit-spread-roll-strategy [parameters]="this.putDebitSpreadRollParameters"></ets-put-debit-spread-roll-strategy>

               </div>

            </fieldset>
         </div>
      </div>

      <div class="buttons">
         
         <div class="left">
            <div class="button" *ngIf="this.isDetachButtonAvailable">
               <dx-button width="100%" text="Detach" type="danger" (onClick)="this.onDetachButtonClicked()"></dx-button>
            </div>
         </div>

         <div class="right">
            <div class="button">
               <dx-button width="100%" text="Save" (onClick)="this.onOkButtonClicked()"></dx-button>
            </div>
            <div class="button">
               <dx-button width="100%" text="Cancel" (onClick)="this.onCancelButtonClicked()"></dx-button>
            </div>   
         </div>
      </div>

      <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: popup}">
      </dx-load-panel>

   </div>

</dx-popup>