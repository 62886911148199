import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GridOptions, GridReadyEvent, RowClickedEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getSessionMessagesGridModel } from './shell-messages-grid-model';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { TimestampsService } from '../timestamps.service';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from '../utils';
import { EtsDateFilter, EtsDomainFilter, EtsMessageFilter, EtsSourceFilter, EtsTagsFilter } from './filter-model';
import { ShellMessageDto } from '../shell-communication/shell-dto-protocol';
import { ShellMessagesService } from './shell-messages.service';


@Component({
   selector: 'ets-shell-messages',
   templateUrl: 'shell-messages.component.html',
   styleUrls: ['shell-messages.component.scss'],
   providers: [
      { provide: 'WINDOW', useValue: window }
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellMessagesComponent implements OnInit, OnDestroy, AfterViewInit {
   
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _messageBusService: MessageBusService,
      private _toastrService: ToastrService,
      private _timestampsService: TimestampsService,
      private _shellMessagesService: ShellMessagesService,
      @Inject('WINDOW') private _window: any,
   ) {
      const defaultTimezone = this._timestampsService.getDefaultTimezone('UTC');
      this.dateFilter = new EtsDateFilter(defaultTimezone);
      this.domainFilter = new EtsDomainFilter();
      this.sourceFilter = new EtsSourceFilter();
      this.tagsFilter = new EtsTagsFilter();
      this.messageFilter = new EtsMessageFilter();
   }
   
   private _sessionMessagesGrid: GridReadyEvent;
   private _unsubscriber: Subject<any> = new Subject<any>();

   notificationsCount: { error: number; warn: number; info: number; };
   sessionMessagesGridOptions: GridOptions;
   selectedMessage: ShellMessageDto;
         
   //

   dateFilter: EtsDateFilter;
   domainFilter: EtsDomainFilter;
   sourceFilter: EtsSourceFilter;
   tagsFilter: EtsTagsFilter;
   messageFilter: EtsMessageFilter;
   unreadFilter = false;
   
   //

   private _messageListSectionSize = 100;
   public get messageListSectionSize(): number {
      return this._messageListSectionSize;
   }

   @DetectSetterChanges()
   public set messageListSectionSize(v: number) {
      this._messageListSectionSize = v;
   }

   //
   
   private _readingPanelSectionSize = 0;
   public get readingPanelSectionSize(): number {
      return this._readingPanelSectionSize;
   }

   @DetectSetterChanges()
   public set readingPanelSectionSize(v: number) {
      this._readingPanelSectionSize = v;
   }
   
   //
   
   private _extendedMessage: string;
   public get extendedMessage(): string {
      return this._extendedMessage;
   }

   @DetectSetterChanges()
   public set extendedMessage(v: string) {
      this._extendedMessage = v;
   }
   
   //

   get anySelected(): boolean {
      if (!this._sessionMessagesGrid) {
         return false;
      }

      return this._sessionMessagesGrid.api.getSelectedRows().length > 0;
   }

   //

   get anyMessages(): boolean {
      if (!this._sessionMessagesGrid) {
         return false;
      }

      return !isNullOrUndefined(this._sessionMessagesGrid.api.getFirstDisplayedRow());
   }

   //

   ngOnInit() {
      this._unsubscriber = new Subject<any>();
      this.sessionMessagesGridOptions = getSessionMessagesGridModel.bind(this)(this._timestampsService);
   }

   //

   onValueChanged() {
      this._changeDetector.detectChanges();
   }
   
   //

   onGridReady(args: GridReadyEvent) {
      this._sessionMessagesGrid = args;

      this._shellMessagesService.shellMessageReceived$
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onShellMessage(msg));
      
      this._shellMessagesService.shellMessagesAcked$
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onShellMessagesAcked(msg));
      
      this._shellMessagesService.shellMessagesRead$
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onShellMessageRead(msg));

      this.loadAllShellMessages();
   }
   
   //

   onGridSizeChanged(args: any) {

   }

   //

   ngAfterViewInit(): void {
  
   }

   //
   
   @DetectMethodChanges()
   onRowDoubleClicked(args: RowDoubleClickedEvent) {

      if (args.node.group) {
         return;
      }

      this.selectedMessage = args.data;

      if (this.messageListSectionSize === 100) {
         this.messageListSectionSize = 50;
         this.readingPanelSectionSize = 50;
      } else {
         this.messageListSectionSize = 100;
         this.readingPanelSectionSize = 0;
      }
   }

   //

   ackAllMessages(): void {
      const rows = [];
      this._sessionMessagesGrid.api.forEachNodeAfterFilter(node => {
         if (node.group) {
            return;
         }
         const msg = node.data as ShellMessageDto;
         if (!msg.shellMessageId) {
            return;
         }
         rows.push(msg);
      });
      this.ackMessages(rows);
   }

   //

   async ackSelectedMessages() {
      const selectedRows: ShellMessageDto[] = this._sessionMessagesGrid.api.getSelectedRows();

      if (selectedRows.length === 0) {
         this._toastrService.info('Select shell messages to ack');
         return;
      }

      await this.ackMessages(selectedRows);
   }

   //

   async readAllMessages() {
      const selectedRows: ShellMessageDto[] = [];

      this._sessionMessagesGrid.api.forEachNodeAfterFilter(node => {
         if (node.group) {
            return;
         }
         
         const data: ShellMessageDto = node.data;
         
         if (!data.shellMessageId) {
            return;
         }
         
         if (data.isRead) {
            return;
         }

         selectedRows.push(data);
      });

      if (selectedRows.length === 0) {
         return;
      }

      await this.readMessages(selectedRows);

      selectedRows.forEach(x => x.isRead = true);
      
      this._sessionMessagesGrid.api.applyTransaction({update: selectedRows});
   }
   
   //

   private async ackMessages(rows: ShellMessageDto[]): Promise<void> {
      
      try {
         this._sessionMessagesGrid.api.showLoadingOverlay();
         
         await this._shellMessagesService.ackMessages(rows);
         
      } catch (error) {
         
         this._toastrService.error('"Ack Shell Messages" operation completed with errors');

      } finally {
         
         this._sessionMessagesGrid.api.hideOverlay();

      }
   }

   //

   private async readMessages(rows: ShellMessageDto[]): Promise<void> {
      try {

         this._sessionMessagesGrid.api.showLoadingOverlay();

         await this._shellMessagesService.readMessages(rows);

      } catch (error) {
         
         this._toastrService.error('"Read Shell Messages" operation completed with errors');

      } finally {
         
         this._sessionMessagesGrid.api.hideOverlay();

      }
   }

   //

   @DetectMethodChanges()
   public loadFakeMessages() {
      this.dateFilter.clear();
      this.sourceFilter.clear();
      this.domainFilter.clear();
      this.tagsFilter.clear();
      const fakes = this.getFakeMessages();
      this.setShellMessages(fakes);
   }

   //
   
   private async loadAllShellMessages(): Promise<void> {
      try {
         
         this._sessionMessagesGrid.api.showLoadingOverlay();
         
         const dtos = this._shellMessagesService.getAllMessages();
         
         this.setShellMessages(dtos);

      } catch {

         this._toastrService.error('"Load Shell Messages" operation completed with errors');

      } finally {
         
         this._sessionMessagesGrid.api.hideOverlay();
      }
      
   }

   //

   @DetectMethodChanges()
   addFakeMessage(type, noOverlay?: boolean) {

      this._shellMessagesService.addFakeMessage(type, noOverlay);
      
   }

   //

   private addImportantMessageAndRebuildRowsIfNecessary(msg: ShellMessageDto) {
      
      let groupNode;
      let alreadyHaveGroupRow = false;
      
      this._sessionMessagesGrid.api.forEachNode(node => {
         
         if (!node.group) {
            return;
         }
         
         if (node.field === 'important') {
            
            if (node.key === 'true' && !groupNode) {
               alreadyHaveGroupRow = true;
            }
            
            if (node.key === 'false' && !groupNode) {
               groupNode = node;
            }
         }
      });
            
      if (groupNode && !alreadyHaveGroupRow) {
         const existingRecords = [];
         this._sessionMessagesGrid.api.forEachLeafNode(node => {
            if (node.group) {
               return;
            }
            existingRecords.push(node.data);
         });
         existingRecords.unshift(msg);
         this._sessionMessagesGrid.api.setRowData([]);
         this._sessionMessagesGrid.api.setRowData(existingRecords);
      } else {
         this._sessionMessagesGrid.api.applyTransaction({add: [msg]});
      }
   }
   
   //

   @DetectMethodChanges()
   private onShellMessage(msg: ShellMessageDto) {

      const domainAdded = this.domainFilter.updateList(msg.domain);
      const sourceAdded = this.sourceFilter.updateList(msg.source);

      if (domainAdded || sourceAdded) {
         this._changeDetector.detectChanges();
      }

      if (msg.level) {

         this.addImportantMessageAndRebuildRowsIfNecessary(msg);

      } else {

         this._sessionMessagesGrid.api.applyTransaction({add: [msg]});
      }

      if (this._shellMessagesService.getAllMessages().length < 3) {
         setTimeout(() => {
            this._sessionMessagesGrid.api.forEachNode(node => {
               if (node.group) {
                  node.setExpanded(true);
               }
            });   
         });
      }

      this._messageBusService.publishAsync({
         topic: 'SessionMessagesGroupRowUpdate',
         payload: msg
      });
   }

   //

   @DetectMethodChanges()
   private setShellMessages(msgs: ShellMessageDto[]) {

      msgs = msgs.sort(
         (x, y) => (x.level === y.level) ? 0 : x.level ? -1 : 1
      );

      msgs.forEach(x => {
         this.domainFilter.updateList(x.domain);
         this.sourceFilter.updateList(x.source);
         this.tagsFilter.updateList(x.tags);
      });
      

      this._sessionMessagesGrid.api.applyTransaction({add: msgs});

      this._sessionMessagesGrid.api.forEachNode(node => {
         if (node.group) {
            setTimeout(() => node.setExpanded(true));
         }
      });
   }

   //

   ngOnDestroy(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //

   @DetectMethodChanges()
   onRowClicked(args: RowClickedEvent) {
      if (args.node.group) {
         this.extendedMessage = null;
         return;
      }

      const data = args.node.data;

      this.selectedMessage = data;

      this._shellMessagesService.readMessages([data]);

      this.extendedMessage = data.message;
   }

   //

   @DetectMethodChanges()
   onDateFilterChanged() {
      this.dateFilter.applyFilter(this._sessionMessagesGrid.api);
      this.domainFilter.filterList(this._sessionMessagesGrid.api);
      this.sourceFilter.filterList(this._sessionMessagesGrid.api);
      this.tagsFilter.filterList(this._sessionMessagesGrid.api);
   }

   //

   @DetectMethodChanges()
   onDomainFilterChanged() {
      this.domainFilter.applyFilter(this._sessionMessagesGrid.api);
      this.sourceFilter.filterList(this._sessionMessagesGrid.api);
      this.tagsFilter.filterList(this._sessionMessagesGrid.api);
   }

   //

   @DetectMethodChanges()
   onSourceFilterChanged() {
      this.sourceFilter.applyFilter(this._sessionMessagesGrid.api);
      this.domainFilter.filterList(this._sessionMessagesGrid.api);
      this.tagsFilter.filterList(this._sessionMessagesGrid.api);
   }

   //

   @DetectMethodChanges()
   onTagsFilterChanged() {
      this.tagsFilter.applyFilter(this._sessionMessagesGrid.api);
      this.sourceFilter.filterList(this._sessionMessagesGrid.api);
      this.domainFilter.filterList(this._sessionMessagesGrid.api);
   }

   //

   @DetectMethodChanges()
   onMessageFilterChanged(ev) {
      this.messageFilter.applyFilter(this._sessionMessagesGrid.api);
      this.domainFilter.filterList(this._sessionMessagesGrid.api);
      this.sourceFilter.filterList(this._sessionMessagesGrid.api);
      this.tagsFilter.filterList(this._sessionMessagesGrid.api);
   }

   //

   @DetectMethodChanges()
   onUnreadFilterChanged(ev) {
      const typeFilter = this._sessionMessagesGrid.api.getFilterInstance('isRead');

      if (typeFilter) {
         if (ev.value) {
            typeFilter.setModel({
               values: ['false']
            });
         } else {
            typeFilter.setModel(null);
         }
         
         this._sessionMessagesGrid.api.onFilterChanged();
      }
   }

   //

   @DetectMethodChanges()
   applyEntryFilter(data: ShellMessageDto): void {
      if (!data || !data.shellMessageId) {
         return;
      }

      const domain = data.domain;
      const source = data.source;
      // const tags = data.tags.split(',');

      this.domainFilter.domain = domain;
      this.sourceFilter.source = source;
      // this.tagsFilter.tag = tags.length > 0 ? tags[0].substring(1, tags[0].length - 2) : null;
      this.domainFilter.applyFilter(this._sessionMessagesGrid.api);
      this.sourceFilter.applyFilter(this._sessionMessagesGrid.api);
      // this.tagsFilter.applyFilter(this._sessionMessagesGrid.api);
   }

   //

   @DetectMethodChanges()
   clearAllFilters() {
      this.dateFilter.reset(this._sessionMessagesGrid.api);
      this.domainFilter.reset(this._sessionMessagesGrid.api);
      this.sourceFilter.reset(this._sessionMessagesGrid.api);
      this.messageFilter.reset(this._sessionMessagesGrid.api);
      this.unreadFilter = false;
   }

   //

   getFakeMessages(): ShellMessageDto[] {
      // tslint:disable: object-literal-key-quotes
      const fakes = [
         {
            'shellMessageId': 1,
            'timestamp': new Date('2022-06-10T05:09:31.023Z'),
            'important': false,
            'domain': 'Gateway',
            'source': 'Moreganic',
            'message': '14 Moore Place, Yonah, New Mexico',
            'isRead': false,
            'tags': '(AAS),(MSFO)'
         },
         {
            'shellMessageId': 2,
            'timestamp': new Date('2022-06-14T07:01:36.173Z'),
            'important': false,
            'domain': 'Auto-Adjustment',
            'source': 'Idego',
            'message': '5 Evans Street, Limestone, Idaho',
            'isRead': false,
            'tags': '(CASE_3),(CASH)'
         },
         {
            'shellMessageId': 3,
            'timestamp': new Date('2022-06-11T18:38:57.270Z'),
            'important': false,
            'domain': 'Shell',
            'source': 'Xleen',
            'message': '74 Lafayette Walk, Roderfield, Utah',
            'isRead': false,
            'tags': '(AAS),(CASH)'
         },
         {
            'shellMessageId': 4,
            'timestamp': new Date('2022-06-21T20:34:35.125Z'),
            'important': true,
            'domain': 'Risk Manager',
            'source': 'Frolix',
            'message': '91 Lloyd Court, Newkirk, Ohio',
            'isRead': false,
            'tags': '(ASIAN),(ETF)'
         },
         {
            'shellMessageId': 5,
            'timestamp': new Date('2022-06-16T19:14:10.720Z'),
            'important': true,
            'domain': 'Auto-Adjustment',
            'source': 'Telepark',
            'message': '42 Varet Street, Dodge, Pennsylvania',
            'isRead': false,
         },
         {
            'shellMessageId': 6,
            'timestamp': new Date('2022-06-07T07:27:33.270Z'),
            'important': false,
            'domain': 'Shell',
            'source': 'Flumbo',
            'message': '97 Stockholm Street, Tetherow, Oklahoma',
            'isRead': false,
            'tags': '(PSIZING),(LIMIT)'
         },
         {
            'shellMessageId': 7,
            'timestamp': new Date('2022-06-11T22:38:48.755Z'),
            'important': false,
            'domain': 'Auto-Adjustment',
            'source': 'Cubix',
            'message': '93 Bijou Avenue, Curtice, Tennessee',
            'isRead': false,
            'tags': '(TICK_SYSTEM)'
         },
         {
            'shellMessageId': 8,
            'timestamp': new Date('2022-06-10T21:33:57.442Z'),
            'important': false,
            'domain': 'Gateway',
            'source': 'Zillidium',
            'message': '18 Trucklemans Lane, Winston, Florida',
            'isRead': false,
            'tags': '(TAG)'
         },
         {
            'shellMessageId': 9,
            'timestamp': new Date('2022-06-16T04:19:02.776Z'),
            'important': false,
            'domain': 'Gateway',
            'source': 'Zillactic',
            'message': '95 Tech Place, Darrtown, Mississippi',
            'isRead': false,
            'tags': '(HELP_ME)'
         },
         {
            'shellMessageId': 10,
            'timestamp': new Date('2022-06-13T08:01:51.517Z'),
            'important': false,
            'domain': 'Gateway',
            'source': 'Equicom',
            'message': '70 Howard Alley, Wadsworth, Marshall Islands',
            'isRead': false,
            'tags': '(Chris)',
         },
         {
            'shellMessageId': 11,
            'timestamp': new Date('2022-06-07T14:19:12.146Z'),
            'important': true,
            'domain': 'Strategy',
            'source': 'Spherix',
            'message': '89 Nautilus Avenue, Conway, Northern Mariana Islands',
            'isRead': false,
            'tags': '(Alan)'
         },
         {
            'shellMessageId': 12,
            'timestamp': new Date('2022-06-18T07:39:25.386Z'),
            'important': true,
            'domain': 'Auto-Adjustment',
            'source': 'Qiao',
            'message': '59 Ralph Avenue, Stonybrook, Rhode Island',
            'isRead': false,
            'tags': '(Tom)'
         },
         {
            'shellMessageId': 13,
            'timestamp': new Date('2022-06-12T17:08:26.464Z'),
            'important': false,
            'domain': 'Gateway',
            'source': 'Silodyne',
            'message': '27 Middleton Street, Summerfield, South Dakota',
            'isRead': false
         },
         {
            'shellMessageId': 14,
            'timestamp': new Date('2022-06-17T21:43:51.393Z'),
            'important': false,
            'domain': 'Gateway',
            'source': 'Futurize',
            'message': '45 Kermit Place, Gibbsville, Virginia',
            'isRead': false,
         },
         {
            'shellMessageId': 15,
            'timestamp': new Date('2022-06-14T15:35:44.309Z'),
            'important': true,
            'domain': 'Risk Manager',
            'source': 'Zork',
            'message': '5 Robert Street, Belfair, Nevada',
            'isRead': false,
            'tags': '(LAST)'
         }
      ];

      return fakes.sort(
         (x, y) => (x.important === y.important) ? 0 : x.important ? -1 : 1
      );
   }

   //

   onShellMessagesAcked(messages: ShellMessageDto[]) {
      this._sessionMessagesGrid.api.applyTransaction({
         remove: messages
      });
   }

   //

   onShellMessageRead(messages: ShellMessageDto[]) {
      this._sessionMessagesGrid.api.applyTransaction({update: messages});

      this._messageBusService.publishAsync({
         topic: 'SessionMessagesGroupRowUpdate',
         payload: messages
      });
    
   }
}
