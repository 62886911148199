import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import * as Enumerable from "linq";
import {
    calculateLightness,
    DetectMethodChanges,
    DetectSetterChanges, findHCF,
    isValidNumber,
    isVoid, makeFullExpirationDate
} from "../../utils";
import {HedgeStateTransaction} from "../data-model/hedge-state-transaction";
import {
    CalculateZonesGridHedges,
    CalculateZonesGridHedgesReply
} from "../../shell-communication/shell-operations-protocol";
import {ShellClientService} from "../../shell-communication/shell-client.service";
import {BeforePositionDto} from "../../adjustment-pricing-grid/model/BeforePositionDto";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {MessageBusService} from "../../message-bus.service";
import {
    CashFlowStrategyRole,
    OptionExpirationDescriptor,
    StrategyPriceDto
} from "../../shell-communication/shell-dto-protocol";
import {SelectionChangedEvent} from "devextreme/ui/list";
import {HedgePositionGroup} from "../data-model/hedge-position-group";
import {makeOptionTicker, OptionType, parseOptionTicker} from "../../options-common/options.model";
import {HgTransactionPopupComponent} from "../transaction-popup/hg-transaction-popup.component";
import {ToastrService} from "ngx-toastr";
import {
    HgZonesGridParametersComponent,
    HgZonesGridSettings
} from "./hg-zones-grid-parameters/hg-zones-grid-parameters.component";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";
import {AtmStrikeService} from "../../common-services/atm-strike-service/atm-strike.service";
import {
    HgZonesGridColumnSelectorComponent
} from "./hg-zones-grid-column-selector/hg-zones-grid-column-selector.component";
import {OptionsChainService} from "../../option-chains.service";
import {HedgePosition} from "../data-model/hedge-position";
import {
    HgZonesGridCalculatorComponent,
    HgZonesGridCalculatorConfig
} from "./hg-zones-grid-calculator/hg-zones-grid-calculator.component";
import {SessionService} from "../../authentication/session-service.service";
import {DxPopupComponent} from "devextreme-angular/ui/popup";
import {AccessControlService} from "../../access-control-service.class";
import {HgZonesGridMenuComponent} from "./hg-zones-grid-menu/hg-zones-grid-menu.component";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";


interface HedgeLabel {
    label: string;
    color: string;
    qty: number;
    strike: number;
}

export interface HgZonesGridRow {
    strike: number;
    priceByStrategyCode: { [ix: string]: number };
    strategyCodeByLabel: { [ix: string]: string }
    expirationPriceByTicker: { [ix: string]: number }
    expirationTickerByCode: { [ix: string]: string },
    multipliers: {[ix: string]: number};
}

export interface ExpirationPrice {
    side: 'Calls' | 'Puts';
    expiration: string;
    expirationFull: string;
    expirationDescriptor: OptionExpirationDescriptor
}

const HgZonesGridParametersUIElementId = '71e61c26-dcc2-49da-9269-61705d7b423c';

@Component({
    selector: 'ets-hg-zones-grid-section',
    templateUrl: 'hg-zones-grid-section.component.html',
    styleUrls: [
        './hg-zones-grid-section.component.scss',
        '../hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgZonesGridSectionComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _shellService: ShellClientService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _sessionService: SessionService,
        private readonly _accessControl: AccessControlService
    ) {
    }

    private _hedgeStates: HedgeStateTransaction[];

    private _result: HedgePositionGroup[];

    private _portfoliosPositions: BeforePositionDto[];

    private _hedgeLabelIndex: Enumerable.IDictionary<number, HedgeLabel[]>;

    private _portfolioRoleIndex: Enumerable.IDictionary<number, CashFlowStrategyRole[]>;

    private _rowByStrategyCode: { [ix: string]: HgZonesGridRow[] } = {};

    private _currentUnderlying: string;

    private _subscribedStrategyCodes: string[];

    private _subscribedQuotes: string[];

    hedgeList: HedgePositionGroup[] = [];

    selectedHedges: HedgePositionGroup[] = [];

    selectedCallHedges: HedgePositionGroup[] = [];

    selectedPutHedges: HedgePositionGroup[] = [];

    expirationList: ExpirationPrice[] = [];

    selectedExpirations: ExpirationPrice[] = [];

    selectedCallExpirations: ExpirationPrice[] = [];

    selectedPutExpirations: ExpirationPrice[] = [];

    rows: HgZonesGridRow[] = [];

    lastReviewedState: string;

    atmAtCalculation: number;

    legsData: {
        title?: string,
        color?: string,
        legs?: HedgePosition[];
    } = {};

    @ViewChild(HgTransactionPopupComponent)
    transactionPopupCmp: HgTransactionPopupComponent;

    @ViewChild(HgZonesGridParametersComponent)
    parametersPopupCmp: HgZonesGridParametersComponent;

    @ViewChild(HgZonesGridColumnSelectorComponent)
    columnSelectorCmp: HgZonesGridColumnSelectorComponent;

    @ViewChild(HgZonesGridCalculatorComponent)
    calculatorCmp: HgZonesGridCalculatorComponent;

    @ViewChild('legsViewer')
    legsViewer: DxPopupComponent;

    @ViewChild(HgZonesGridMenuComponent)
    menuCmp: HgZonesGridMenuComponent;

    private _isLoading: boolean;
    get isLoading(): boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    get isEmpty(): boolean {
        return isVoid(this.rows);
    }

    ngOnInit() {
        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .subscribe(x => this.onQuoteDto(x.payload));

        this._messageBus.of('Hg.HedgesCleared')
            .subscribe(x => this.onHedgesCleared());
    }

    getTotalPrice(row: HgZonesGridRow) {
        if (isVoid(this.selectedHedges)) {
            return undefined;
        }

        const sum = this.selectedHedges
            .map(sh => {
                const code = row.strategyCodeByLabel[sh.label];
                const px = row.priceByStrategyCode[code];
                const ml = row.multipliers[code] || 1;
                return px * ml;
            })
            .reduce((a, b) => {
                const d = (a || 0) + (b || 0);
                return d;
            }, 0);

        return sum;
    }

    @DetectMethodChanges()
    setData(desiredHedges: HedgeStateTransaction[], portfolioPositions: BeforePositionDto[][]) {

        this._hedgeStates = desiredHedges;

        this._portfoliosPositions = portfolioPositions.flatMap(x => x);

        if (!isVoid(portfolioPositions)) {
            const poses = portfolioPositions[0];
            if (!isVoid(poses)) {
                const beforePositionDto = poses[0];
                const optionTicker = parseOptionTicker(beforePositionDto.ticker);
                if (!isVoid(optionTicker)) {
                    this._currentUnderlying = optionTicker.underlying;
                }
            }
        }
    }

    @DetectMethodChanges({isAsync: true})
    async loadDataClicked(silently?: boolean) {

        if (isVoid(this._hedgeStates)) {
            throw new Error('Cannot determine hedge positions');
        }

        if (isVoid(this._portfoliosPositions)) {
            throw new Error('Cannot determine portfolio positions');
        }

        const tickers = this._portfoliosPositions.filter(x => !isVoid(x.ticker)).map(x => x.ticker);

        if (tickers.length === 0) {
            throw new Error('Zones Grid Not Loaded: Underlying Cannot Be Determined');
        }

        const optionTicker = parseOptionTicker(tickers[0]);

        if (isVoid(optionTicker)) {
            throw new Error('Zones Grid Not Loaded: Underlying Cannot Be Determined');
        }

        const underlying = optionTicker.underlying;

        if (isVoid(underlying)) {
            throw new Error('Zones Grid Not Loaded: Underlying Cannot Be Determined');
        }

        const base = this._currentUnderlying === 'SPX' ? 10 : 1;
        const range = base * 12;
        let settings: HgZonesGridSettings = {
            step: base,
            rangeDown: range,
            range: range
        }

        if (this._accessControl.isSecureElementAvailable(HgZonesGridParametersUIElementId)) {
            const bypassParameters = this.menuCmp?.getSettings()?.bypassParameters;

            if (silently || bypassParameters) {
                const storedSettings = this.parametersPopupCmp.getSettings(underlying);
                if (!isVoid(storedSettings)) {
                    settings = storedSettings;
                } else {
                    this._toastr.warning('HG Zones Grid Parameters Not Found. Defaults Were Used Instead');
                }

            } else {
                settings = await this.parametersPopupCmp.show(underlying);
            }
        }

        if (isVoid(settings)) {
            throw Error('Zones Grid Cannot Be Loaded: Settings Not Found');
        }

        this.makeHedgeLabelIndex();

        this.makePortfolioRoleColorIndex();

        this.makeHedgeList();

        await this.makeExpirationList();

        const qry = new CalculateZonesGridHedges(
            settings.range,
            settings.step,
            settings.rangeDown,
            this.hedgeList,
            this._portfoliosPositions
        );

        this.isLoading = true;

        let result: CalculateZonesGridHedgesReply;
        try {
            result = await this._shellService.processQuery<CalculateZonesGridHedgesReply>(qry);
        } finally {
            this.isLoading = false;
        }

        this._result = result.hedges;

        this.atmAtCalculation = result.atmAtCalculation;

        const byLabel = Enumerable.from(result.hedges)
            .groupBy(x => {
                const d = x.zonesGridMarketOffset + x.baseStrike;
                return d;
            })
            .toArray();

        this._rowByStrategyCode = {};

        const rows = byLabel.map(x => {

            const prices: { [ix: string]: number } = {};
            const codesMap: { [ix: string]: string } = {};

            const expPrices: { [ix: string]: number } = {};
            const tickersMap: { [ix: string]: string } = {};

            const multipliers: {[ix: string]: number} = {};

            this.expirationList.map(exp => {
                const strike = x.key();
                const type = exp.side === 'Calls' ? OptionType.Call : OptionType.Put;

                const ticker = makeOptionTicker(
                    exp.expirationDescriptor,
                    type,
                    strike,
                );
                expPrices[ticker] = undefined;

                const code = `${strike}_${exp.expiration}_${exp.side}`;
                tickersMap[code] = ticker;
            });

            x.forEach(y => {
                prices[y.optionStrategyCode] = undefined;
                codesMap[y.label] = y.optionStrategyCode;
                multipliers[y.optionStrategyCode] = y.defaultQtyMultiplier;
            });

            const row: HgZonesGridRow = {
                strike: x.key(),
                priceByStrategyCode: prices,
                strategyCodeByLabel: codesMap,
                expirationPriceByTicker: expPrices,
                expirationTickerByCode: tickersMap,
                multipliers
            };

            for (let pricesKey in prices) {
                let container = this._rowByStrategyCode[pricesKey];
                if (isVoid(container)) {
                    container = [];
                    this._rowByStrategyCode[pricesKey] = container;
                }
                container.push(row);
            }

            return row;
        });

        this.rows = rows;

        this._messageBus.of<StrategyPriceDto[]>('StrategyPriceDto')
            .subscribe(x => this.onStrategyPrice(x.payload));

        const optionCodes = Enumerable.from(result.hedges)
            .select(x => x.optionStrategyCode)
            .distinct()
            .toArray();

        this._lastQuoteCache.subscribeStrategyCodesDiff([], optionCodes);

        const positionQuotes = Enumerable.from(result.hedges).selectMany(x => x.positions)
            .select(x => x.ticker)
            .distinct()
            .toArray();

        this._lastQuoteCache.subscribeTickers(positionQuotes);

        this._subscribedStrategyCodes = optionCodes;

        this._subscribedQuotes = positionQuotes;

        this.subscribeForAtmChanges(result.hedges);
    }

    private makeHedgeList() {
        const hedges = this._hedgeStates
            .flatMap(x => x.positionTransactions)
            .flatMap(x => x.afterState)
            .filter(x => !isVoid(x));

        this.hedgeList = hedges;
        this.selectedHedges.length = 0;
        this.selectedHedges.push(...hedges);
        this.updateSelectedHedgesBySide();
    }

    private async makeExpirationList(): Promise<void> {

        const expirations = this._hedgeStates
            .flatMap(x => x.positionTransactions)
            .flatMap(x => x.afterState)
            .filter(x => !isVoid(x))
            .flatMap(x => x.positions)
            .map(x => `${x.type} ${x.expiration}`)
            .filter((v, ix, arr) => arr.indexOf(v) === ix)
            .sort();

        if (isVoid(this._currentUnderlying)) {
            if (!isVoid(this._hedgeStates)) {
                const hedgeStateTransaction = this._hedgeStates[0];
                if (!isVoid(hedgeStateTransaction)) {
                    if (!isVoid(hedgeStateTransaction.positionTransactions)) {
                        const pTrans = hedgeStateTransaction.positionTransactions[0];
                        if (!isVoid(pTrans)) {
                            const all: HedgePosition[] = [];
                            if (!isVoid(pTrans.beforeState)) {
                                if (!isVoid(pTrans.beforeState.positions)) {
                                    all.push(...pTrans.beforeState.positions)
                                }
                            }
                            if (!isVoid(pTrans.afterState)) {
                                if (!isVoid(pTrans.afterState.positions)) {
                                    all.push(...pTrans.afterState.positions)
                                }
                            }

                            if (!isVoid(all)) {
                                const any = all[0];
                                if (!isVoid(any)) {
                                    const ticker = parseOptionTicker(any.ticker);
                                    if (!isVoid(ticker)) {
                                        this._currentUnderlying = ticker.underlying;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const chain =
            await this._optionChainService.getChain(this._currentUnderlying);

        if (isVoid(chain)) {
            this._toastr.error('Zones Grid Loaded With Errors: Cannot Determine Underlying');
            return;
        }

        const expirationObjects = expirations.map(x => {
            const parts = x.split(' ');

            const expDescriptor
                = chain.expirations.find(x => x.optionExpirationDate === parts[1]);

            const obj: ExpirationPrice = {
                side: parts[0] === 'Call' ? 'Calls' : 'Puts',
                expiration: parts[1],
                expirationFull: makeFullExpirationDate(parts[1]),
                expirationDescriptor: expDescriptor
            };

            return obj;
        });

        this.expirationList = expirationObjects;

        this.selectedExpirations.length = 0;

        this.updateSelectedExpirationsBySide();
    }

    private makeHedgeLabelIndex() {
        const hegeLegs = Enumerable.from(this._hedgeStates)
            .selectMany(x => x.positionTransactions)
            .select(x => x.afterState)
            .where(x => !isVoid(x))
            .selectMany(x => x.positions)
            .groupBy(x => x.strike);

        const labelsData = hegeLegs
            .selectMany(x => x)
            .select(x => {
                const d: HedgeLabel = {
                    label: x.label,
                    color: x.color,
                    qty: x.qty,
                    strike: x.strike
                };
                return d;
            }).groupBy(x => x.strike)
            .toDictionary(x => x.key(), x => x.toArray());

        this._hedgeLabelIndex = labelsData;
    }

    @DetectMethodChanges()
    private onStrategyPrice(payload: StrategyPriceDto[]) {

        payload.forEach(px => {
            const rows = this._rowByStrategyCode[px.strategyCode];

            if (isVoid(rows)) {
                return;
            }

            rows.forEach(row => {
                row.priceByStrategyCode[px.strategyCode] = px.price;
            });
        });
    }

    getHedgePrice(row: HgZonesGridRow, hedge: HedgePositionGroup) {
        const strategyCode = row.strategyCodeByLabel[hedge.label];
        const price = row.priceByStrategyCode[strategyCode];
        const ml = row.multipliers[strategyCode] || 1;
        return price * ml;
    }

    getPriceClass(price: number) {
        if (!isValidNumber(price, true)) {
            return undefined;
        }
        return price > 0 ? 'credit' : 'debit';
    }

    @DetectMethodChanges()
    onHedgeSelectionChanged(args: SelectionChangedEvent<HedgePositionGroup>) {


        args.removedItems.forEach(x => {
            const ix = this.selectedHedges.indexOf(x);
            if (ix < 0) {
                return;
            }
            this.selectedHedges.splice(ix, 1);
        });

        args.addedItems.forEach(x => {
            const ix = this.selectedHedges.indexOf(x);
            if (ix === -1) {
                this.selectedHedges.push(x);
            }
        });

        this.updateSelectedHedgesBySide();
    }

    @DetectMethodChanges()
    onExpirationSelectionChanged(args: SelectionChangedEvent<any>) {


        args.removedItems.forEach(x => {
            const ix = this.selectedExpirations.indexOf(x);
            if (ix < 0) {
                return;
            }
            this.selectedExpirations.splice(ix, 1);
        });

        args.addedItems.forEach(x => {
            const ix = this.selectedExpirations.indexOf(x);
            if (ix === -1) {
                this.selectedExpirations.push(x);
            }
        });

        this.updateSelectedExpirationsBySide();
    }

    updateSelectedHedgesBySide() {
        this.selectedCallHedges = this.hedgeList
            .filter(x => x.side === 'Calls')
            .filter(x => this.selectedHedges.indexOf(x) >= 0)
            .reverse();

        this.selectedPutHedges = this.hedgeList
            .filter(x => x.side === 'Puts')
            .filter(x => this.selectedHedges.indexOf(x) >= 0);
    }

    updateSelectedExpirationsBySide() {
        this.selectedCallExpirations = this.expirationList
            .filter(x => x.side === 'Calls')
            .filter(x => this.selectedExpirations.indexOf(x) >= 0);

        this.selectedPutExpirations = this.expirationList
            .filter(x => x.side === 'Puts')
            .filter(x => this.selectedExpirations.indexOf(x) >= 0);
    }

    getHedgeLabelColorsForStrike(strike: number): HedgeLabel[] {

        if (this.selectedHedges.length === 0) {
            return [{} as any];
        }

        const data = this._hedgeLabelIndex.get(strike) || [];

        const filtered = data.filter(x =>
            this.selectedHedges.some(y => y.label === x.label));

        if (filtered.length === 0) {
            return undefined;
        }

        return filtered;
    }

    getFontColorForHedgeLabels(strike: number): string {
        const labels = this.getHedgeLabelColorsForStrike(strike);
        if (isVoid(labels)) {
            return undefined;
        }
        const colors = labels.map(x => x.color);
        const number = colors.map(x => calculateLightness(x)).reduce((a, b) => a + b, 0);
        const avg = number / colors.length;
        return avg >= 100 ? 'black' : 'white';
    }

    getPortfolioRoles(row: HgZonesGridRow): CashFlowStrategyRole[] {

        if (isVoid(this._portfolioRoleIndex)) {
            return undefined;
        }

        const roles = this._portfolioRoleIndex.get(row.strike) || [];

        if (this._currentUnderlying) {
            const currentAtm = this._atmStrikeService.getCurrentAtm(this._currentUnderlying);

            if (row.strike === currentAtm) {
                if (roles.every(x => x !== ('ATM' as any))) {
                    roles.push('ATM' as any);
                }
            } else {

                const atmIx = this.rows.findIndex(x => x.strike === currentAtm);

                if (atmIx >= 0) {
                    const number = roles.findIndex(x => x === 'ATM' as any);
                    if (number >= 0) {
                        roles.splice(number, 1)
                    }
                }
            }
        }

        return roles;
    }

    @DetectMethodChanges()
    onQuoteDto(quotes: QuoteDto[]) {

    }

    private makePortfolioRoleColorIndex() {
        const rolesIx = Enumerable.from(this._portfoliosPositions)
            .select(x => {
                const optionTicker = parseOptionTicker(x.ticker);
                return {
                    strike: optionTicker.strike,
                    role: x.role
                };
            })
            .distinct(element => `${element.role}${element.strike}`)
            .groupBy(x => x.strike)
            .toDictionary(x =>
                x.key(), x =>
                x.select(x => x.role).toArray()
            );

        this._portfolioRoleIndex = rolesIx;
    }

    @DetectMethodChanges()
    clearData() {
        const strats = this._subscribedStrategyCodes || [];
        this._lastQuoteCache.subscribeStrategyCodesDiff(strats, []);

        const quotes = this._subscribedQuotes || [];
        this._lastQuoteCache.unsubscribeTickers(quotes);

        this._result = undefined;
        this._rowByStrategyCode = {};
        this.rows = [];
        this.hedgeList = [];
        this.selectedHedges = [];
        this.selectedExpirations = [];
        this.lastReviewedState = undefined;
        this.atmAtCalculation = undefined;

        this.updateSelectedHedgesBySide();
        this.updateSelectedExpirationsBySide();
    }

    getColumnSelectorTextHedges(): string {

        if (!this.canShowColumnSelector()) {
            return 'Not Available';
        }

        return `${this.selectedHedges.length} of ${this.hedgeList.length} Hedges Selected`;
    }

    getColumnSelectorTextExpirations(): string {

        if (!this.canShowColumnSelector()) {
            return 'Not Available';
        }

        const uniqueExpirations = Enumerable
            .from(this.expirationList)
            .select(x => x.expiration)
            .distinct()
            .count();

        const selectedExpirations = Enumerable.from(this.selectedExpirations)
            .select(x => x.expiration)
            .distinct()
            .count()

        return `${selectedExpirations} of ${uniqueExpirations} Expirations Selected`;
    }

    @DetectMethodChanges()
    showTransactionPopup(row: HgZonesGridRow, hedge: HedgePositionGroup) {

        if (!this._sessionService.isSuperUser) {
            return;
        }

        const strike = row.strike;

        const grp = this._result
            .filter(x => x.label === hedge.label)
            .find(x => (x.baseStrike + x.zonesGridMarketOffset) === strike);


        const strings = grp.positions.map(x => x.ticker);

        this._lastQuoteCache.subscribeTickers(strings);

        this.legsData = {
            legs: grp.positions,
            title: grp.label,
            color: grp.color
        };

        this.legsViewer.visible = true;

        setTimeout(() => {
            this._changeDetector.detectChanges();
        }, 250);
    }

    private subscribeForAtmChanges(hedges: HedgePositionGroup[]) {
        if (hedges.length === 0) {
            return;
        }

        const assets = hedges.flatMap(x => x.positions)
            .filter(x => !isVoid(x.asset));

        if (assets.length === 0) {
            return;
        }

        const asset = assets[0].asset;

        this._currentUnderlying = asset;

        this._atmStrikeService.watch(asset);
    }

    getRowRoleCss(row: HgZonesGridRow): string[] | undefined {

        const classes = [];

        const portfolioRoles = this.getPortfolioRoles(row);


        portfolioRoles.forEach((role, ix) => {

            if (role !== 'ATM' as any) {
                classes.push('the-leg');
            }

            switch (role) {
                case 'ATM' as any:
                    classes.push('current-atm');
                    break;
                case 'ShortOption':
                    classes.push('short-option');
                    break;
                case 'SpreadLongLeg':
                case 'SpreadShortLeg':
                    classes.push('spread');
                    break;
                case 'SecondSpreadLongLeg':
                case 'SecondSpreadShortLeg':
                    classes.push('second-spread');
                    break;
                case 'ProtectiveOption':
                case 'SecondProtectiveOption':
                    classes.push('protective');
                    break;
            }
        });

        return classes;
    }

    getHedgeLabel(leg: HedgeLabel) {
        if (isVoid(leg) || isVoid(leg.label)) {
            return undefined;
        }

        return `${leg.label} (${(leg.qty > 0 ? '+' : '')} ${leg.qty})`
    }

    showColumnSelector() {
        this.columnSelectorCmp.show(this);
    }

    canShowColumnSelector() {
        return !isVoid(this.hedgeList);
    }

    getExpirationPrice(exp: ExpirationPrice, row: HgZonesGridRow) {
        const code = `${row.strike}_${exp.expiration}_${exp.side}`;

        const ticker = row.expirationTickerByCode[code];

        const lastQuote = this._lastQuoteCache.getLastQuote(ticker);

        if (isVoid(lastQuote)) {
            this._lastQuoteCache.subscribeIfNotYet(ticker);
            return undefined;
        }

        return lastQuote.mid;
    }

    @DetectMethodChanges()
    showCalculator(row: HgZonesGridRow, targetCell: any, expDescriptor?: ExpirationPrice) {

        if (isVoid(this._currentUnderlying)) {
            this._toastr.error('Cannot Determine Current Underlying');
            return;
        }

        const cfg: HgZonesGridCalculatorConfig = {
            row,
            targetCell,
            underlying: this._currentUnderlying,
            expirations: this.expirationList,
            selectedExpiration: expDescriptor
        };

        if (this.calculatorCmp.visible) {
            this.calculatorCmp.hide();
        } else {
            this.calculatorCmp.show(cfg);
        }

    }

    onScroll() {
        this.calculatorCmp.hide();
    }

    isAtmOutdated() {
        if (isVoid(this._currentUnderlying)) {
            return false;
        }

        if (!isValidNumber(this.atmAtCalculation, true)) {
            return false;
        }

        const currentAtm = this._atmStrikeService.getCurrentAtm(this._currentUnderlying);

        return currentAtm !== this.atmAtCalculation;
    }

    isAtmAtCalculation(strike: number) {
        return this.atmAtCalculation === strike;
    }

    @DetectMethodChanges()
    closeLegsDialog() {
        this.legsViewer.visible = false;
        const strings = this.legsData.legs.map(x => x.ticker);
        this._lastQuoteCache.unsubscribeTickers(strings);
    }

    getLegViewerLegPrice(position: HedgePosition, legs: HedgePosition[] | undefined) {
        const numbers = legs.map(x => Math.abs(x.qty));
        const hcf = findHCF(numbers);

        const q = this._lastQuoteCache.getLastQuote(position.ticker);

        if (isVoid(q)) {
            return;
        }

        const px = (position.qty / hcf) * q.mid;

        return px;
    }

    getLegViewerTotalPrice(legs: HedgePosition[] | undefined) {
        const sum = legs.map(x => this.getLegViewerLegPrice(x, legs))
            .reduce((a, b) => a + b, 0);
        return sum;
    }

    private onHedgesCleared() {
        this.clearData();
    }

    @DetectMethodChanges()
    showMenu() {
        this.menuCmp?.show();
    }

    onPortfolioSelected(args: ApgPortfolio) {
        this.clearData();
    }
}
