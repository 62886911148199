import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DetectSetterChanges } from 'projects/shared-components/utils';
import { PutDebitSpreadRollStrategyParameters } from './put-debit-spread-roll-strategy-parameters';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';

@Component({
   selector: 'ets-put-debit-spread-roll-strategy',
   templateUrl: 'put-debit-spread-roll-strategy.component.html',
   styleUrls: ['put-debit-spread-roll-strategy.component.scss']
})
export class PutDebitSpreadRollStrategyComponent implements OnInit {
   constructor(private readonly _changeDetector: ChangeDetectorRef) { }

   @Input() parameters: PutDebitSpreadRollStrategyParameters;

   @Input() cpMode: string;
   
   private _manualMode = false;
   get manualMode() : boolean {
      return this._manualMode;
   }

   @Input()
   @DetectSetterChanges()
   set manualMode(v : boolean) {
      this._manualMode = v;
      this.parameters.manualMode = v;
   }
   
   //
   private _tickerForChains: string;
   get tickerForChains(): string {
      return this._tickerForChains;
   }

   @Input()
   @DetectSetterChanges()
   set tickerForChains(v: string) {
      this._tickerForChains = v;
      this.parameters.tickerForChains = v;
   }

   private _strategy: CashFlowStrategy;
   get strategy(): CashFlowStrategy {
      return this._strategy;
   }
   @Input()
   @DetectSetterChanges()
   set strategy(value: CashFlowStrategy) {
      this._strategy = value;
      this.parameters.strategy = value;
   }

   ngOnInit(): void {
   }
}
