<div class="short-option-section" #container>
   
   <div class="header">
      <ets-section-header [header]="this.header" 
      >
      </ets-section-header>
   </div>

   <ng-container *ngIf="this.isMultiDirection; then double; else single">
   </ng-container>

   <dx-load-panel 
      [visible]="this.isLoading" 
      [position]="{my: 'center', at: 'center', of: container}"
      >
   </dx-load-panel>
</div>

<ng-template #single>
   <div class="content single">
      
      <div class="sidebox left">
         <ets-automation-cp-short-option-sidebox #firstSidebox>
         </ets-automation-cp-short-option-sidebox>
      </div>

      <div class="parameters na">
         <div class="wrapper">
            <p>
               N/A
            </p>
         </div>
      </div>

   </div>
</ng-template>

<ng-template #double>
   <div class="content double">
      
      <div class="sidebox left">
         <ets-automation-cp-short-option-sidebox #firstSidebox>
         </ets-automation-cp-short-option-sidebox>
      </div>

      <div class="parameters na">
         <div class="wrapper">
            <p>
               N/A
            </p>
         </div>
      </div>

      <div class="sidebox right">
         <ets-automation-cp-short-option-sidebox #secondSidebox>
         </ets-automation-cp-short-option-sidebox>
      </div>

   </div>
</ng-template>
