import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class DeleteSessionHistory implements ProtocolCommand {
  constructor(public readonly sessionIds: string[]) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteSessionHistory`;
  }
}
