<div class="tab">

  <div (click)="onSortLayouts()" class="button">
    <i class="fas fa-sort-amount-up fa-lg clickable"></i>
  </div>

  <ng-container *ngFor="let tab of tabs">
    <div [ngClass]="{'active': tab.isActive}" class="button">
      <span (click)="selectTab(tab)"
            (dblclick)="onTabHeaderDoubleClick(tab)"
            *ngIf="!tab.isEditMode"
            class="header clickable">
        {{tab.header}}
      </span>
      <i (click)="closeTab(tab)"
         *ngIf="tab.isActive && !tab.isEditMode && securityContext.canCloseLayout"
         class="fas fa-times-circle close-icon"
      ></i>
      <dx-text-box (onBlur)="onFocusLost(tab)"
                   (onEnterKey)="setTabHeader(tab)"
                   (onKeyUp)="onTabKeyUp($event, tab)"
                   [(value)]="tab.proposedHeader"
                   [visible]="tab.isEditMode"
                   style="margin-top: -4px"
      ></dx-text-box>
    </div>
  </ng-container>

  <div (click)="addTab()" [ngClass]="{'ets-hidden': !securityContext.canAddLayout}" class="button">
    <i class="fas fa-plus-square fa-lg add-icon clickable"></i>
  </div>

  <div *ngIf="!this.isEtsHost" class="menu">
    <div class="btn">
      <ets-host-menu [uniqueId]="'layout-tabs-host-menu'"></ets-host-menu>
    </div>
    
    
    <div class="btn pivots" *ngIf="this.securityContext.pivotsTrackerButton">
      <ets-pivot-points-popup></ets-pivot-points-popup>
    </div>
    
    
    <div class="btn resources" *ngIf="this.securityContext.resourceButton">
      <ets-resource-menu></ets-resource-menu>
    </div>
    
  </div>

</div>
