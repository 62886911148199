import { Injectable } from '@angular/core';
import { IDatafeedChartApi } from '../../price-chart/data-feed-chart-api';
import { PnLDataFeed } from './pnl-data-feed';
import { ToastrService } from 'ngx-toastr';
import { PnLChartSubscription } from '../pnl-chart-subscription';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';

@Injectable({ providedIn: 'root' })
export class PnLDataFeedFactoryService {
  constructor(
    private _shellClient: ShellClientService,
    private _messageBus: MessageBusService,
    private _toastr: ToastrService
  ) {
  }

  public getDataFeed(componentId: string,
                     subscriptions: PnLChartSubscription[],
                     chart: () => any)
    : IDatafeedChartApi {
    const pnLDataFeed = new PnLDataFeed(
      componentId,
      subscriptions,
      this._shellClient,
      this._messageBus,
      this._toastr,
      chart
    );
    pnLDataFeed.init();
    return pnLDataFeed;
  }
}
