import { Injectable } from '@angular/core';
import { isVoid } from 'projects/shared-components/utils';
import { Observable, Subject } from 'rxjs';

export type AutomationCpTopics = 
      'Loading' 
   |  'ComboChanged'
   |  'StrategyChanged'
   |  'ModeChanged'
   |  'AdjustmentStrategyCreated'
   |  'InterestStrategyCreated'
   |  'AdjustmentStrategyRemoved'
   |  'InterestStrategyRemoved'
   |  'AdjustmentParametersUpdated'
   |  'AdjustmentStrategyLoaded';

@Injectable()
export class AutomationCpMessageBusService {

   private readonly _subjects: { [topic: string]: Subject<any>} = {};

   publish<T>(topic: AutomationCpTopics, message?: T): boolean {

      const observable = this._subjects[topic];

      if (isVoid(observable)) {
         return;
      }

      try {
         observable.next(message);
         return true;
      } catch(e) {
         console.error(e);
         return false;
      }
   }

   when<T>(topic: AutomationCpTopics): Observable<T> {
      
      let observable = this._subjects[topic];

      if (isVoid(observable)) {
         observable = new Subject<T>();
         this._subjects[topic] = observable;
      }

      return observable;
   }
}