// tslint:disable: no-string-literal

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogConfig } from './dialog-config';
import { ParametersControlBase } from './parameters-control-base';
import { ToastrService } from 'ngx-toastr';
import { AdvancedScheduleDialogComponent } from './advanced-schedule-dialog/advanced-schedule-dialog.component';
import { StrategyScheduleModel } from './parameters-controls/strategy-schedule-model';
import { DispositionParametersControlBase } from './parameters-controls/disposition/disposition-parameters-control-base';
import { DispositionType } from './disposition-type';
import Guid from 'devextreme/core/guid';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { AlgoFamily } from 'projects/shared-components/algo/algo-family.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { AlgoModel } from 'projects/shared-components/algo/algo-model.class';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { TimeZoneFamily } from 'projects/shared-components/timezones/time-zone-family.interface';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import {
   CreateStrategyAndStart
} from 'projects/shared-components/shell-communication/operations/strategies/create-strategy-and-start.class';
import { CreateStrategy } from 'projects/shared-components/shell-communication/operations/strategies/create-strategy.class';
import { UpdateStrategy } from 'projects/shared-components/shell-communication/operations/strategies/update-strategy.class';
import { TIME_ZONE_FAMILIES } from 'projects/shared-components/timezones/time-zones.const';
import { StrategyKind } from 'projects/shared-components/strategies/strategy-kind.enum';
import { isTruthy, isNullOrUndefined, isHedgingAlgo, DetectMethodChanges, DetectSetterChanges, DxValueChanged } from 'projects/shared-components/utils';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { ParametersControlService } from './parameters-control.service';
import { TriggerDto, GetTriggers, TriggerPurpose } from '../shell-communication/dtos/triggers';
import { StrategyTriggers } from '../strategies/strategy-triggers.class';
import { StrategyPositionSizing, PositionSizingStrategy } from '../strategies/strategy-position-sizing.class';
import { plainToClass } from 'class-transformer';
import { AccountDto } from '../shell-communication/dtos/account-dto.class';
import { ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto } from '../shell-communication/shell-dto-protocol';
import { GetAvailableBuckets } from '../shell-communication/shell-operations-protocol';
import { TimeZoneDescriptor } from '../timezones/time-zone-descriptor.interface';
import { EtsConstants } from '../ets-constants.const';
import { AccessControlService } from '../access-control-service.class';

class TriggerWapper {
   constructor(
      public data: TriggerDto,
      public isSelected = false
   ) {

   }
}

@Component({
   selector: 'ets-edit-strategy-dialog',
   templateUrl: 'edit-strategy-dialog.component.html',
   styleUrls: ['edit-strategy-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditStrategyDialogComponent implements OnInit, AfterViewInit {
   constructor(
      private _algoMetadataService: AlgoMetadataService,
      private _toastr: ToastrService,
      private _shellClient: ShellClientService,
      private _sessionService: SessionService,
      private readonly _parametersControlService: ParametersControlService,
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _accessControlService: AccessControlService
   ) {
   }

   private _selectedAlgoFamily: AlgoFamily;
   private _selectedAlgo: AlgoModel[] = [];
   private _parametersControl: ParametersControlBase<any>;
   private _positionSizing = new StrategyPositionSizing();
   private _allocatedUnits = 1;
   private _accountId: string;
   private _isImmediateExit = false;


   @Output() innerStrategyAdded = new EventEmitter<StrategyDto>();
   @Output() innerStrategyUpdated = new EventEmitter<StrategyDto>();
   @ViewChild(AdvancedScheduleDialogComponent) advancedSchedule: AdvancedScheduleDialogComponent;
   
   dialogConfig: DialogConfig;
   isVisible: boolean;
   title: string;
   availableTerminals: TerminalDto[] = [];
   availableAlgoFamilies: AlgoFamily[] = [];
   selectedTerminal: TerminalDto;
   timezoneFamilies: { key: string, items: TimeZoneFamily[] }[] = [];
   availableTimeZoneFamilies: TimeZoneFamily[] = [];

   
   accountItems: AccountDto[];
   
   availableTriggers: TriggerDto[] = [];

   //

   private _filterTriggersSearchString = '';
   get filterTriggersSearchString(): string {
      return this._filterTriggersSearchString;
   }
   @DetectSetterChanges()
   set filterTriggersSearchString(val: string) {
      this._filterTriggersSearchString = val;
   }
   
   //

   private _filterTriggers: TriggerWapper[] = [];
   get filterTriggers(): TriggerWapper[] {
      
      if (this._filterTriggers.length === 0) {
         this._filterTriggers = this.availableTriggers
            .filter(t => (t.triggerPurpose & TriggerPurpose.Filter) === TriggerPurpose.Filter )
            .map(t => new TriggerWapper(t));
      }
      
      let result = this._filterTriggers;

      if (this.filterTriggersSearchString) {
         result = result.filter(t =>  {
            const searchField = (t.data.displayName + '@' + t.data.vendorName) || '';
            return searchField.indexOf(this.filterTriggersSearchString) >= 0;
         });
      }

      return result;
   }
   
   //

   private _conditionTriggersSearchString = '';
   get conditionTriggersSearchString(): string {
      return this._conditionTriggersSearchString;
   }
   @DetectSetterChanges()
   set conditionTriggersSearchString(val: string) {
      this._conditionTriggersSearchString = val;
   }

   //

   private _conditionTriggers: TriggerWapper[] = [];
   get conditionTriggers(): TriggerWapper[] {

      if (this._conditionTriggers.length === 0) {

         this._conditionTriggers = this.availableTriggers
            .filter(t => (t.triggerPurpose & TriggerPurpose.Condition) === TriggerPurpose.Condition )
            .map(t => new TriggerWapper(t));

      }

      let result = this._conditionTriggers;

      if (this.conditionTriggersSearchString) {
         result = result.filter(t =>  {
            const searchField = (t.data.displayName + '@' + t.data.vendorName) || '';
            return searchField.indexOf(this.conditionTriggersSearchString) >= 0;
         });
      }

      return result;
   }

   //

   private _entryTriggersSearchString = '';
   get entryTriggersSearchString(): string {
      return this._entryTriggersSearchString;
   }
   @DetectSetterChanges()
   set entryTriggersSearchString(val: string) {
      this._entryTriggersSearchString = val;
   }

   //

   private _entryTriggers: TriggerWapper[] = [];
   get entryTriggers(): TriggerWapper[] {
      if (this._entryTriggers.length === 0) {
         this._entryTriggers = this.availableTriggers
            .filter(t => (t.triggerPurpose & TriggerPurpose.Entry) === TriggerPurpose.Entry )
            .map(t => new TriggerWapper(t));
      }

      let result = this._entryTriggers;

      if (this.entryTriggersSearchString) {
         result = result.filter(t =>  {
            const searchField = (t.data.displayName + '@' + t.data.vendorName) || '';
            return searchField.indexOf(this.entryTriggersSearchString) >= 0;
         });
      }

      return result;
   }
   
   //

   private _exitTriggersSearchString = '';
   get exitTriggersSearchString(): string {
      return this._exitTriggersSearchString;
   }
   @DetectSetterChanges()
   set exitTriggersSearchString(val: string) {
      this._exitTriggersSearchString = val;
   }

   //

   private _exitTriggers: TriggerWapper[] = [];
   get exitTriggers(): TriggerWapper[] {
      
      if (this._exitTriggers.length === 0) {
         this._exitTriggers = this.availableTriggers
            .filter(t => (t.triggerPurpose & TriggerPurpose.Exit) === TriggerPurpose.Exit )
            .map(t => new TriggerWapper(t));
      }
      
      let result = this._exitTriggers;

      if (this.exitTriggersSearchString) {
         result = result.filter(t =>  {
            const searchField = (t.data.displayName + '@' + t.data.vendorName) || '';
            return searchField.indexOf(this.exitTriggersSearchString) >= 0;
         });
      }

      return result;
   }
   
   //

   selectedFilterTriggers: TriggerWapper[] = [];
   selectedConditionTriggers: TriggerWapper[] = [];
   selectedEntryTriggers: TriggerWapper[] = [];
   selectedExitTriggers: TriggerWapper[] = [];
   entryTriggerContinuous = false;
   entryTriggerTimesCount: number;

   get selectedFilterTriggersValue(): string {
      if (this.selectedFilterTriggers.length === 1) {
         const t = this.selectedFilterTriggers[0].data;
         return `${t.displayName}@${t.vendorName}`;
      }

      return `... ${this.selectedFilterTriggers.length} items`;
   }

   availableBuckets: GetAvailableBucketsReply;
   availablePortfolios: PortfolioDto[];
   availableCombos: ComboDto[];
   availableComboGroups: ComboGroupDto[];

   selectedPortfolio: PortfolioDto;
   selectedCombo: ComboDto;
   selectedComboGroup: ComboGroupDto;


   private _isLoading = false;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(value: boolean) {
      this._isLoading = value;
   }
   

   get isImmediateExit(): boolean {
      return this._isImmediateExit;
   }

   @DetectSetterChanges()
   set isImmediateExit(value: boolean) {
      this._isImmediateExit = value;
   }
   

   get allocatedUnits(): number {
      return this._allocatedUnits;
   }
   
   @DetectSetterChanges()
   set allocatedUnits(value: number) {
      this._allocatedUnits = value;
   }
   
   
   get accountId(): string {
      return this._accountId;
   }

   @DetectSetterChanges()
   set accountId(value: string) {
      this._accountId = value;
   }

   //

   get positionSizing(): StrategyPositionSizing {
      return this._positionSizing;
   }

   @DetectSetterChanges()
   set positionSizing(v: StrategyPositionSizing) {
      this._positionSizing = v;
   }

   //

   get isPositionSizingEnabled(): boolean {
      return this.positionSizing.isPositionSizingEnabled;
   }

   @DetectSetterChanges()
   set isPositionSizingEnabled(v: boolean) {
      if (v) {
         this.positionSizing.isPositionSizingEnabled = true;
      } else {
         this.positionSizing = new StrategyPositionSizing();
      }
   }

   //

   get selectedAlgoFamily(): AlgoFamily {
      return this._selectedAlgoFamily;
   }

   @DetectSetterChanges()
   set selectedAlgoFamily(v: AlgoFamily) {
      if (this._selectedAlgoFamily === v) {
         return;
      }
      this.selectedAlgo.length = 0;
      this._selectedAlgoFamily = v;
   }

   //

   get selectedAlgo(): AlgoModel[] {
      return this._selectedAlgo;
   }

   @DetectSetterChanges()
   set selectedAlgo(value: AlgoModel[]) {
      if (value.length === 0 && this._selectedAlgo.length === 0) {
         return;
      }

      if (value.length > 0 && this._selectedAlgo.length > 0) {
         if (value[0] === this._selectedAlgo[0]) {
            return;
         }
      }

      this._selectedAlgo = value;

      if (!!this._selectedAlgo && this._selectedAlgo.length > 0) {
         this.setParametersControl(this._selectedAlgo[0].algoId);
      }
   }

   //

   get parametersControl(): ParametersControlBase<any> {
      return this._parametersControl;
   }

   @DetectSetterChanges()
   set parametersControl(v: ParametersControlBase<any>) {
      if (v === this._parametersControl) {
         return;
      }
      
      this._parametersControl = v;
      
      if (!!v) {
         v.setCommonDispositionParameters(
            this.dialogConfig.commonDispositionParams,
            this.dialogConfig.isUpdate
         );

         if (this.selectedTerminal) {
            v.onStrategyTerminalChanged();
         }

      }
   }

   //

   get isUnitsEditorAvailable() {
      return this.dialogConfig && this.dialogConfig.editMode === 'immediate'
         && !!this.parametersControl;
   }

   //

   get canChangeTerminal() {
      return this.dialogConfig && this.dialogConfig.editMode === 'immediate' && !this.dialogConfig.isUpdate
         && !this.dialogConfig.dispositionId;
   }

   //

   get isInnerStrategyForNetBetting(): boolean {
      let result = false;
      if (this.dialogConfig.editMode === 'deferred') {
         const commonDispositionParams = this.dialogConfig.commonDispositionParams;
         if (commonDispositionParams) {
            if (commonDispositionParams.dispositionType === DispositionType.NetBetting) {
               result = true;
            }
         }
      }
      return result;
   }

   //

   get isDeltaHedge(): boolean {
      const pc = this.parametersControl;
      return pc && isHedgingAlgo(pc.algoId);
   }

   //

   get isAlgoSelected(): boolean {
      return this.selectedAlgo && this.selectedAlgo.length > 0;
   }

   //

   get isFilterTriggerAvailable(): boolean {
      return (this.isAlgoSelected && this.filterTriggers.length > 0) 
         || this.filterTriggersSearchString !== '';
   }

   //
   
   get isConditionTriggerAvailable(): boolean {
      return (this.isAlgoSelected && this.conditionTriggers.length > 0)
         || this.conditionTriggersSearchString !== '';
   }
   
   //

   get isEntryTriggerAvailable(): boolean {
      return (this.isAlgoSelected && this.entryTriggers.length > 0)
         || this.entryTriggersSearchString !== '';
   }
   
   //

   get isExitTriggerAvailable(): boolean {
      return (this.isAlgoSelected && this.selectedAlgo[0]?.algoId !== EtsConstants.algorithms.remoteAlgoId
               && this.exitTriggers.length > 0) || this.exitTriggersSearchString !== '';
   }

   //

   autostopping = false;
   autostoppingCount: number;

   //

   ngOnInit(): void { }


   ngAfterViewInit() { this._changeDetector.detach(); }


   @DetectMethodChanges()
   close(): void {
      
      this.isVisible = false;
      
      this.availableAlgoFamilies = [];
      
      this.selectedAlgoFamily = null;
      this.selectedAlgo = [];
      
      this.parametersControl = null;
      
      this._filterTriggers = [];
      this._conditionTriggers = [];
      this._entryTriggers = [];
      this._exitTriggers = [];

      this.selectedFilterTriggers = [];
      this.selectedConditionTriggers = [];
      this.selectedEntryTriggers = [];
      this.selectedExitTriggers = [];
      
      this.positionSizing = new StrategyPositionSizing();
   }

   get timezoneItems(): TimeZoneDescriptor[] {
      if (!this.parametersControl) {
         return [];
      }

      if (!this.parametersControl.scheduleParameters) {
         return [];
      }

      if (!this.parametersControl.scheduleParameters.country) {
         return [];
      }

      const tzFamily = this.availableTimeZoneFamilies.find(x => x.displayName === this.parametersControl.scheduleParameters.country);

      if (!tzFamily) { return  []; }

      return tzFamily.timeZoneDescriptors;
   }
   

   @DetectMethodChanges()
   onNumberOfViolationsChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.maxPositionViolationsAllowed = ev;
      }
   }


   @DetectMethodChanges()
   onViolationMarginChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.maxPositionViolationMargin = ev;
      }
   }


   @DetectMethodChanges()
   onStrategyNameChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.displayName = ev;
      }
   }


   @DetectMethodChanges()
   onStrategyTagsChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.tags = ev;
      }
   }

   //#region  Strategy Schedule

   @DetectMethodChanges()
   onStrategyScheduleCountryChanged(ev) {
      if (this.parametersControl && this.parametersControl.scheduleParameters) {
         this.parametersControl.scheduleParameters.country = ev;
      }
   }


   @DetectMethodChanges()
   onStrategyScheduleTimezoneChanged(ev) {
      if (this.parametersControl && this.parametersControl.scheduleParameters) {
         this.parametersControl.scheduleParameters.timeZone = ev;
      }
   }


   @DetectMethodChanges()
   onStrategyScheduleStartTimeChanged(ev) {
      if (this.parametersControl && this.parametersControl.scheduleParameters) {
         this.parametersControl.scheduleParameters.startTime = ev;
      }
   }
   
   
   @DetectMethodChanges()
   onStrategyScheduleStopTimeChanged(ev) {
      if (this.parametersControl && this.parametersControl.scheduleParameters) {
         this.parametersControl.scheduleParameters.stopTime = ev;
      }
   }
   
   
   @DetectMethodChanges()
   onStrategyScheduleExitTimeChanged(ev) {
      if (this.parametersControl && this.parametersControl.scheduleParameters) {
         this.parametersControl.scheduleParameters.exitTime = ev;
      }
   }


   @DetectMethodChanges()
   onStrategyScheduleAutoStartChanged(ev) {
      if (this.parametersControl && this.parametersControl.scheduleParameters) {
         this.parametersControl.scheduleParameters.isAutostart = ev; 
      }
   }


   @DetectMethodChanges()
   onIsSessionStrategyChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.isSession = ev;
      }
   }

   //#endregion Strategy Schedule


   //#region Triggers

   @DetectMethodChanges()
   onIsEntryTriggerContinuousChanged(ev) {
      this.entryTriggerContinuous = ev;
   }


   //#endregion Triggers
   
   @DetectMethodChanges()
   onTimestampsZoneChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.timestampsZone = ev;
      }
   }
   

   //#region  Position Sizing

   @DetectMethodChanges()
   onPositionSizingUseAccountSizeInCalculationsChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.useAccountSizeInCalculations = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAccountSizeChanged(ev) {
      this.positionSizing.baseAccountSize = ev;
   }


   @DetectMethodChanges()
   onPositionSizingSizingStrategyChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.sizingStrategy = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingRatioUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.ratioUnitSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingDeltaChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.deltaSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAllowDecreaseMultiplierChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.allowDecreaseMultiplier = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingFractionalUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.fractionalUnitSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingUnitPerAmountChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.unitPerAmount = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingUnitPerPercentChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.unitPerPercent = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingRiskPerUnitChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.riskPerUnit = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingUiSwitchWhenChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.uiSwitchWhen = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingSwitchWhenProfitChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.switchWhenProfit = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingSwitchToChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.switchTo = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltRationUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altRatioUnitSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltDeltaSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altDeltaSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltAllowDecreaseMultiplierChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altAllowDecreaseMultiplier = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltFractionalUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altFractionalUnitSize = ev;
      }
   }

   
   @DetectMethodChanges()
   onPositionSizingAltUnitPerAmountChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altUnitPerAmount = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltUnitPerPercent(ev) {
      if (this.positionSizing) {
         this.positionSizing.altUnitPerPercent = ev;
      }
   }

   
   @DetectMethodChanges()
   onPositionSizingAltRiskPerUnitChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altRiskPerUnit = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingStaySwitchedChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.stayWhenSwitched = ev;
      }
   }


   //#endregion Position Sizing


   @DetectMethodChanges()
   onDoNotAutoRollChanged(ev) {
      if (this.parametersControl) {
         this.parametersControl.doNotAutoRoll = ev;
      }
   }

   //

   @DetectMethodChanges()
   onDontUseCancelConfirmations(ev) {
      if (this.parametersControl) {
         this.parametersControl.dontUseCancelConfirmations = ev;
      }
   }

   //

   @DetectMethodChanges()
   onAutoStoppingChanged(ev) {
      this.autostopping = ev;
   }

   @DetectMethodChanges()
   onAutoStoppingCountDisabledChanged(ev) {
      if (ev) {
         this.autostoppingCount = null;
      }
   }

   //#region Trading Objectives

   //#region SL-Margin

   @DetectMethodChanges()
   onTradingObjectivesIsTradeSlMarginChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.isTradeSlMargin = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesTradeSlMarginChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.tradeSlMargin = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesIsSessionSlMarginChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.isSessionSlMargin = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesSessionSlMarginChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.sessionSlMargin = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesIsAccumulatedSlMarginChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.isAccumulatedSlMargin = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesAccumulatedSlMarginChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.accumulatedSlMargin = ev;
         }
      }
   }

   //#endregion SL-Margin

   //#region NPO

   @DetectMethodChanges()
   onTradingObjectivesIsTradeNpoChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.isTradeNpo = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesTradeNpoChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.tradeNpo = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesIsSessionNpoChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.isSessionNpo = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesSessionNpoChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.sessionNpo = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesIsAccumulatedNpoChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.isAccumulatedNpo = ev;
         }
      }
   }


   @DetectMethodChanges()
   onTradingObjectivesAccumulatedNpoChanged(ev) {
      if (this.parametersControl) {
         if (this.parametersControl.tradingObjectives) {
            this.parametersControl.tradingObjectives.accumulatedNpo = ev;
         }
      }
   }

   //#endregion NPO

   //#region %-TS

      @DetectMethodChanges()
      onTradingObjectivesIsTradePercentTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isTradePercentTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesTradePercentTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.tradePercentTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesIsSessionPercentTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isSessionPercentTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesSessionPercentTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.sessionPercentTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesIsAccumulatedPercentTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isAccumulatedPercentTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesAccumulatedPercentTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.accumulatedPercentTS = ev;
            }
         }
      }
   
   //#endregion %-TS

   //#region $-TS

      @DetectMethodChanges()
      onTradingObjectivesIsTradeDollarTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isTradeDollarTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesTradeDollarTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.tradeDollarTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesIsSessionDollarTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isSessionDollarTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesSessionDollarTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.sessionDollarTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesIsAccumulatedDollarTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isAccumulatedDollarTS = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesAccumulatedDollarTsChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.accumulatedDollarTS = ev;
            }
         }
      }
   
   //#endregion %-TS

   //#region Stop-Loss

      @DetectMethodChanges()
      onTradingObjectivesIsTradeStopLossChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isTradeStopLoss = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesTradeStopLossChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.tradeStopLoss = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesIsSessionStopLossChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isSessionStopLoss = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesSessionStopLossChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.sessionStopLoss = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesIsAccumulatedStopLossChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.isAccumulatedStopLoss = ev;
            }
         }
      }
   
   
      @DetectMethodChanges()
      onTradingObjectivesAccumulatedStopLossChanged(ev) {
         if (this.parametersControl) {
            if (this.parametersControl.tradingObjectives) {
               this.parametersControl.tradingObjectives.accumulatedStopLoss = ev;
            }
         }
      }
   
   //#endregion %-TS


   //#endregion


   @DetectMethodChanges({isAsync: true})
   async addStrategy(shouldStart: boolean): Promise<void> {
      const strategyDto = this.makeStrategyDto();
      if (!strategyDto) {
         return;
      }

      if (this.dialogConfig.editMode === 'deferred') {
         try {
            this.innerStrategyAdded.emit(strategyDto);
            this._toastr.info(`"${strategyDto.displayName}" Inner Strategy was Added`);
         } catch (e) {
            this._toastr.error(`"${strategyDto.displayName}" Inner Strategy was Added with Errors`);
         }
      } else {
         const isDispoControl = this.parametersControl as DispositionParametersControlBase<unknown>;
         if (isDispoControl.innerStrategies) {
            if (isDispoControl.innerStrategies.length > 0) {
               strategyDto.dispositionStrategies.push(...isDispoControl.innerStrategies);
            }
         }

         const cmd = shouldStart
            ? new CreateStrategyAndStart([strategyDto])
            : new CreateStrategy([strategyDto]);

         this.isLoading = true;

         try {
            
            await this._shellClient.processCommand(cmd);
            
            this._toastr.info(`"${strategyDto.displayName}" Strategy was Added`);

         } catch (e) {

            console.error(e);
            this._toastr.error(`"${strategyDto.displayName}" Strategy was Not Added`);

         } finally {

            this.isLoading = false;

         }
      }
   }


   @DetectMethodChanges({isAsync: true})
   async updateStrategy(): Promise<void> {
      if (!this.dialogConfig.isUpdate) {
         return;
      }

      const strategyDto = this.makeStrategyDto();
      if (!strategyDto) {
         return;
      }

      if (this.dialogConfig.editMode === 'deferred') {
         try {
            this.innerStrategyUpdated.emit(strategyDto);
            this._toastr.info(`"${strategyDto.displayName}" Inner Strategy was Updated`);
         } catch (e) {
            this._toastr.error(`"${strategyDto.displayName}" Inner Strategy was Updated with Errors`);
         }
      } else {
         const isDispoControl = this.parametersControl as DispositionParametersControlBase<unknown>;
         if (isDispoControl.innerStrategies) {
            if (isDispoControl.innerStrategies.length > 0) {
               strategyDto.dispositionStrategies.push(...isDispoControl.innerStrategies);
            }
         }

         const cmd = new UpdateStrategy(strategyDto);
         
         this.isLoading = true;

         try {
            await this._shellClient.processCommand(cmd);
            this._toastr.info(`"${strategyDto.displayName}" Strategy was Updated`);
         } catch {
            this._toastr.error(`"${strategyDto.displayName}" Strategy was Not Updated`);
         } finally {
            this.isLoading = false;
         }
      }
   }


   @DetectMethodChanges()
   showAdvancedSchedule(): void {
      if (!this.advancedSchedule) {
         return;
      }

      if (!this.parametersControl || !this.parametersControl.scheduleParameters) {
         return;
      }

      const scheduleModel = this.parametersControl.scheduleParameters || new StrategyScheduleModel();
      const scheduleParams: StrategyScheduleModel =
         JSON.parse(
            JSON.stringify(scheduleModel)
            , (k, v) => {
               if (!!v) {
                  if (k.toLowerCase().includes('date')) {
                     return new Date(v);
                  }
               }
               return v;
            }
         );

      this.advancedSchedule.show(scheduleParams);
   }

   
   @DetectMethodChanges()
   onAdvancedScheduleSet(sched: StrategyScheduleModel): void {
      if (this.parametersControl) {
         this.parametersControl.scheduleParameters = sched;
      }
   }


   @DetectMethodChanges()
   onTerminalChanged(event) {
      
      const term = event as TerminalDto;

      if (!term) {
         this.selectedPortfolio = null;
         this.selectedCombo = null;
         this.selectedComboGroup = null;
         this.availablePortfolios = [];
         this.availableCombos = [];
         this.availableComboGroups = [];
         return;
      }

      this.selectedTerminal = event;
      this.availablePortfolios = this.availableBuckets.portfolios.filter(p => p.terminalId === event.terminalId);

      if (this.parametersControl) {
         this.parametersControl.onStrategyTerminalChanged();
      }
   }
   

   @DetectMethodChanges()
   onPortfolioChange(event: PortfolioDto) {
      
      this.selectedPortfolio = event;
      
      if (isNullOrUndefined(this.selectedPortfolio)) {
         
         this.availableCombos = [];
         this.selectedCombo = null;
         this.availableComboGroups = [];
         this.selectedComboGroup = null;
         
         return;
      }

      this.availableCombos = this.availableBuckets.combos.filter(c => c.portfolioId === event.portfolioId);
   }


   @DetectMethodChanges()
   onComboChange(event: ComboDto) {
      
      this.selectedCombo = event;
      
      if (isNullOrUndefined(this.selectedCombo)) {
         
         this.availableComboGroups = [];
         this.selectedComboGroup = null;
         
         return;
      }

      this.availableComboGroups = this.availableBuckets.comboGroups.filter(cg => cg.comboId === event.comboId);
   }

   //

   @DetectMethodChanges()
   onComboGroupChange(event: ComboGroupDto) {
      this.selectedComboGroup = event;
   }

   //

   @DetectMethodChanges({isAsync: true})
   async show(config: DialogConfig): Promise<void> {
      
      const isLeanMode = !this._accessControlService
         .isSecureElementAvailable(EtsConstants.companyServices.fullEditStrategyWindowUIElement);

      if (isLeanMode) {
         config.editMode = 'lean';
      }
      
      const tzFamiliesGroup = [
         {
            key: 'Favorites',
            items: []
         }, 
         {
            key: 'Others',
            items: []
         }
      ];
      
      this.availableTimeZoneFamilies = TIME_ZONE_FAMILIES.slice();
      this.availableTimeZoneFamilies.forEach(tzf => {
         if (tzf.displayName === 'United States') {
            tzFamiliesGroup[0].items.push(tzf);
         } else {
            tzFamiliesGroup[1].items.push(tzf);
         }
      });
      this.timezoneFamilies = tzFamiliesGroup;

      this.dialogConfig = config;

      this.title = `${(!config.isUpdate ? 'Add' : 'Edit')} Strategy Dialog`;

      const algoFamilies = this._algoMetadataService.getAlgoFamilies();
      if (config.source === 'inner') {
         const dispoIx = algoFamilies.findIndex(x => x.displayName === 'Disposition');
         if (dispoIx >= 0) {
            algoFamilies.splice(dispoIx, 1);
         }
         const adjIx = algoFamilies.findIndex(x => x.displayName === 'Adjustment');
         if (adjIx >= 0) {
            algoFamilies.splice(adjIx, 1);
         }
         const hedgIx = algoFamilies.findIndex(x => x.displayName === 'Hedging');
         if (hedgIx >= 0) {
            algoFamilies.splice(hedgIx, 1);
         }
         const interestIx = algoFamilies.findIndex(x => x.displayName === 'Interest');
         if (interestIx >= 0) {
            algoFamilies.splice(interestIx, 1);
         }
      }
      this.availableAlgoFamilies = algoFamilies;
      
      this.availableTerminals = this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy).slice();

      this.accountItems = this._sessionService.loginResult.availableAccounts.slice();
      

      try {
         this.isLoading = true;

         this._changeDetector.detectChanges();

         const bucketQry = new GetAvailableBuckets();
         const bucketsQryReply = await this._shellClient.processQuery<GetAvailableBucketsReply>(bucketQry);
         this.availableBuckets = bucketsQryReply;

         const allTriggers = await this._shellClient.processQuery<TriggerDto[]>(new GetTriggers());
         this.availableTriggers = allTriggers;

      } finally {

         this.isLoading = false;
         this._changeDetector.detectChanges();

      }

      // update list of algo-families according to context
      if (config.strategyKind !== StrategyKind.Indicator) {
         const iindicatorsIx = this.availableAlgoFamilies.findIndex(af => af.displayName === 'Indicators');

         if (iindicatorsIx >= 0) {
            this.availableAlgoFamilies.splice(iindicatorsIx, 1);
         }

         if (config.strategyKind === StrategyKind.Disposition) {
            const dispositionIx = this.availableAlgoFamilies
               .findIndex(af => af.displayName === 'Disposition');
            if (dispositionIx >= 0) {
               this.availableAlgoFamilies.splice(dispositionIx, 1);
            }
         }
      } else {
         const indicatorFamily = this.availableAlgoFamilies
            .find(af => af.displayName === 'Indicators');
         this.availableAlgoFamilies.length = 0;
         this.availableAlgoFamilies.push(indicatorFamily);
      }

      this.allocatedUnits = isNullOrUndefined(config.strategy.unitsAllocated)
         ? 1
         : config.strategy.unitsAllocated;

      //
      if (!config.isUpdate) {
         config.strategy.displayName = config.strategy.tags = null;
         config.strategy.strategyId = new Guid().valueOf();
         
         if (config.editMode === 'lean') {
            this.selectedTerminal = this._sessionService.loginResult.ownTerminal;
         } else {
            if (config.strategy.terminalId) {
               const terminal = this.availableTerminals.find(t => t.terminalId === config.strategy.terminalId);
               this.onTerminalChanged(terminal);
            }
         }
      } else {
         
         if (config.strategy.terminalId) {
            const terminal = this.availableTerminals.find(t => t.terminalId === config.strategy.terminalId);
            this.onTerminalChanged(terminal);
         }

      }

      this.syncAlgoFamilyAndAlgo(config.strategy.algoId);


      if (config.strategy) {

         const str = config.strategy;

         if (str.triggers) {
            const triggers = str.triggers;
            const strategyTriggers = JSON.parse(triggers) as StrategyTriggers;
            
            if (strategyTriggers.filterTriggers && strategyTriggers.filterTriggers.length > 0) {
               const strategyFilterTriggers = this.filterTriggers.flatMap(t => {
                  const ix = strategyTriggers.filterTriggers.findIndex(tid => t.data.triggerId === tid);
                  if (ix >= 0) {
                     t.isSelected = true;
                     return t;
                  }

                  return null;
               });
               this.selectedFilterTriggers = strategyFilterTriggers.filter(t => !isNullOrUndefined(t));
            }

            if (strategyTriggers.conditionTriggers && strategyTriggers.conditionTriggers.length > 0) {
               const strategyConditionTriggers = this.conditionTriggers.flatMap(t => {
                  const ix = strategyTriggers.conditionTriggers.findIndex(tid => t.data.triggerId === tid);
                  if (ix >= 0) {
                     t.isSelected = true;
                     return t;
                  } 

                  return null;
               });
               this.selectedConditionTriggers = strategyConditionTriggers.filter(t => !isNullOrUndefined(t));
            }
            
            if (strategyTriggers.entryTriggers && strategyTriggers.entryTriggers.length > 0) {
               const strategyEntryTriggers = this.entryTriggers.flatMap(t => {
                  const ix = strategyTriggers.entryTriggers.findIndex(tid => t.data.triggerId === tid);
                  if (ix >= 0) {
                     t.isSelected = true;
                     return t;
                  } 

                  return null;
               });

               this.selectedEntryTriggers = strategyEntryTriggers.filter(t => !isNullOrUndefined(t));
            }
            
            if (strategyTriggers.exitTriggers && strategyTriggers.exitTriggers.length > 0) {
               const strategyExitTriggers = this.exitTriggers.flatMap(t => {
                  const ix = strategyTriggers.exitTriggers.findIndex(tid => t.data.triggerId === tid);
                  
                  if (ix >= 0) {
                     t.isSelected = true;
                     return t;
                  }
                  
                  return ix >= 0 ? t : null;
               });

               this.selectedExitTriggers = strategyExitTriggers.filter(t => !isNullOrUndefined(t));
            }
            
            if (strategyTriggers.isImmediateExit) {
               this.isImmediateExit = strategyTriggers.isImmediateExit;
            }
            
            if (strategyTriggers.entryTriggerContinuous) {
               this.entryTriggerContinuous = true;
            }
            
            if (strategyTriggers.entryTriggerTimesCount) {
               this.entryTriggerTimesCount = strategyTriggers.entryTriggerTimesCount;
            }
         }

         if (str.positionSizing) {
            const sizingSettings = plainToClass(StrategyPositionSizing, JSON.parse(str.positionSizing));

            if (sizingSettings && !isNullOrUndefined(sizingSettings.sizingStrategy)) {
               sizingSettings.isPositionSizingEnabled = true;

               if ((sizingSettings.baseAccountSize || 0) > 0) {
                  sizingSettings.useAccountSizeInCalculations = true;
               }

               if (sizingSettings.switchWhenMoreEfficient) {
                  sizingSettings.uiSwitchWhen = 'efficient';
               } else if ((sizingSettings.switchWhenProfit || 0) > 0) {
                  sizingSettings.uiSwitchWhen = 'profit';
               }

               this.positionSizing = sizingSettings;
            }
         }

         if (str.parameters) {
            this.accountId = str.parameters['forceaccount'];


            this.autostopping = str.autostopping;
            this.autostoppingCount = parseInt(str.parameters['autostoppingcount']);

         }

         if (!isNullOrUndefined(this.selectedTerminal)) {
            if (!isNullOrUndefined(str.portfolioId)) {
               const pf = this.availableBuckets.portfolios.find(p => p.portfolioId === str.portfolioId);
               if (!isNullOrUndefined(pf)) {
                  this.onPortfolioChange(pf);
               }
            }
   
            if (!isNullOrUndefined(str.comboId)) {
               const combo = this.availableBuckets.combos.find(c => c.comboId === str.comboId);
               if (!isNullOrUndefined(combo)) {
                  this.onComboChange(combo);
               }
            }
   
            if (!isNullOrUndefined(str.comboGroupId)) {
               const comboGroup = this.availableBuckets.comboGroups.find(cg => cg.comboGroupId === str.comboGroupId);
               if (!isNullOrUndefined(comboGroup)) {
                  this.onComboGroupChange(comboGroup);
               }
            }
         }
      }


      if (this.parametersControl) {

         this.parametersControl.setStrategy(config);
         
         if (this.dialogConfig.editMode === 'deferred') {
            this.parametersControl.setCommonDispositionParameters(
               config.commonDispositionParams,
               config.isUpdate
            );
         }
      }

      this.isVisible = true;
   }

   //

   @DetectMethodChanges()
   boltOn(cfg: any) {
      this.dialogConfig.isBoltOn = true;

      const term = this.availableTerminals.find(x => x.terminalId === cfg.terminalId);
      this.onTerminalChanged(term);

      const strategy = new StrategyDto();
      strategy.parameters['targetportfolio'] = cfg.portfolioId;
      strategy.parameters['targetcombo'] = cfg.comboId;
      strategy.parameters['targetcombogroup'] = cfg.comboGroupId;

      const dialogConfig = {
         strategy
      };
      
      this.parametersControl.setStrategy(dialogConfig as any);

      this.availableAlgoFamilies = this.availableAlgoFamilies.filter(x => x.displayName === 'Hedging');
   }

   //
   
   @DetectMethodChanges()
   onEntryTriggerTimesCountDisabledChanged(ev) {
      if (ev) {
         this.entryTriggerTimesCount = null;
      }
   }

   //

   @DetectMethodChanges()
   onEntryTriggerContinuousDisabledChanged(ev) {
      if (ev) {
         this.entryTriggerContinuous = false;
      }
   }

   //

   @DetectMethodChanges()
   onSelectedFilterTriggerChanged(ev: DxValueChanged<boolean>, trigger: TriggerWapper) {
      const ix = this.selectedFilterTriggers.indexOf(trigger);

      if (!ev.value) {
         if (ix >= 0) {
            this.selectedFilterTriggers.splice(ix, 1);
         }
      } else {
         if (ix < 0) {
            this.selectedFilterTriggers.push(trigger);
         }
      }
   }

   //

   @DetectMethodChanges()
   onSelectedConditionTriggerChanged(ev: DxValueChanged<boolean>, trigger: TriggerWapper) {
      const ix = this.selectedConditionTriggers.indexOf(trigger);

      if (!ev.value) {
         if (ix >= 0) {
            this.selectedConditionTriggers.splice(ix, 1);
         }
      } else {
         if (ix < 0) {
            this.selectedConditionTriggers.push(trigger);
         }
      }
   }

   //

   @DetectMethodChanges()
   onSelectedEntryTriggerChanged(ev: DxValueChanged<boolean>, trigger: TriggerWapper) {
      const ix = this.selectedEntryTriggers.indexOf(trigger);

      if (!ev.value) {
         if (ix >= 0) {
            this.selectedEntryTriggers.splice(ix, 1);
         }
      } else {
         if (ix < 0) {
            this.selectedEntryTriggers.push(trigger);
         }
      }
   }

   //

   @DetectMethodChanges()
   onSelectedExitTriggerChanged(ev: DxValueChanged<boolean>, trigger: TriggerWapper) {
      const ix = this.selectedExitTriggers.indexOf(trigger);

      if (!ev.value) {
         if (ix >= 0) {
            this.selectedExitTriggers.splice(ix, 1);
         }
      } else {
         if (ix < 0) {
            this.selectedExitTriggers.push(trigger);
         }
      }
   }

   //

   getSelectedValueForTriggers(triggerType: string): string {
      
      if (triggerType === 'filter') {
         
         if (this.selectedFilterTriggers.length === 0) {
            return undefined;
         }

         if (this.selectedFilterTriggers.length === 1) {
            const t = this.selectedFilterTriggers[0].data;
            return `${t.displayName}@${t.vendorName}`;
         }
   
         return `... ${this.selectedFilterTriggers.length} items`;

      } else if (triggerType === 'condition') {
         
         if (this.selectedConditionTriggers.length === 0) {
            return undefined;
         }

         if (this.selectedConditionTriggers.length === 1) {
            const t = this.selectedConditionTriggers[0].data;
            return `${t.displayName}@${t.vendorName}`;
         }
   
         return `... ${this.selectedConditionTriggers.length} items`;

      } else if (triggerType === 'entry') {

         if (this.selectedEntryTriggers.length === 0) {
            return undefined;
         }

         if (this.selectedEntryTriggers.length === 1) {
            const t = this.selectedEntryTriggers[0].data;
            return `${t.displayName}@${t.vendorName}`;
         }
   
         return `... ${this.selectedEntryTriggers.length} items`;

      } else if (triggerType === 'exit') {

         if (this.selectedExitTriggers.length === 0) {
            return undefined;
         }

         if (this.selectedExitTriggers.length === 1) {
            const t = this.selectedExitTriggers[0].data;
            return `${t.displayName}@${t.vendorName}`;
         }
   
         return `... ${this.selectedExitTriggers.length} items`;

      }
   
   }

   //

   @DetectMethodChanges()
   private syncAlgoFamilyAndAlgo(algoId: string): void {
      const algoModel: AlgoModel = this._algoMetadataService.getAlgoModel(algoId);
      if (!algoModel) {
         return;
      }

      const family: AlgoFamily = this.availableAlgoFamilies
         .find(af => af.algoes.some(al => al.algoId === algoId));

      if (!family) {
         this.parametersControl = null;
         return;
      }

      this.selectedAlgoFamily = family;
      this.selectedAlgo = [algoModel];
   }

   //

   @DetectMethodChanges()
   private setParametersControl(algoId: string): void {
      if (!algoId) {
         return;
      }
      const pControl = this._parametersControlService.getParametersControl(algoId);
      pControl.holder = this;
      this.parametersControl = pControl;
   }

   //

   private makeStrategyDto(): StrategyDto {
      
      if (!this.parametersControl) {
         return null;
      }

      const strategy = this.parametersControl.getStrategy();

      strategy.strategyId = this.dialogConfig.isUpdate
         ? this.dialogConfig.strategy.strategyId
         : new Guid().valueOf();

      strategy.strategyKind = this.dialogConfig.strategyKind;
      strategy.terminalId = !!this.selectedTerminal ? this.selectedTerminal.terminalId : null;
      strategy.terminalCode = !!this.selectedTerminal ? this.selectedTerminal.displayName : null;
      strategy.unitsAllocated = this.allocatedUnits;
      
      if (isHedgingAlgo(strategy.algoId)) {
         strategy.portfolioId = strategy.parameters['targetportfolio'];
         strategy.comboId = strategy.parameters['targetcombo'];
         strategy.comboGroupId = strategy.parameters['targetcombogroup'];
      } else {
         strategy.portfolioId = this.selectedPortfolio ? this.selectedPortfolio.portfolioId : null;
         strategy.comboId = this.selectedCombo ? this.selectedCombo.comboId : null;
         strategy.comboGroupId = this.selectedComboGroup ? this.selectedComboGroup.comboGroupId : null;
      }


      const triggers: StrategyTriggers = {
         filterTriggers: this.selectedFilterTriggers.map(x => x.data.triggerId),
         conditionTriggers: this.selectedConditionTriggers.map(x => x.data.triggerId),
         entryTriggers: this.selectedEntryTriggers.map(x => x.data.triggerId),
         exitTriggers: this.selectedExitTriggers.map(x => x.data.triggerId),
         isImmediateExit: this.isImmediateExit,
         entryTriggerContinuous: this.entryTriggerContinuous,
         entryTriggerTimesCount: this.entryTriggerTimesCount
      };

      strategy.autostopping = this.autostopping;

      strategy.triggers = JSON.stringify(triggers);
      
      const psSettings = this.preparePositionSizingSettings(this.positionSizing);
      strategy.positionSizing = psSettings;

      this.assignCommonParameters(strategy);

      const commonErrors = this.parametersControl.validate(this);

      this.onStrategyModelValidation(strategy, commonErrors);

      if (commonErrors.length > 0) {
         commonErrors.forEach(msg => {
            this._toastr.error(msg);
         });
         return null;
      }

      if (this.dialogConfig.dispositionId) {
         if (strategy.algoId !== EtsConstants.algorithms.fusionAlgoId) {
            strategy.dispositionId = this.dialogConfig.dispositionId;
         }
      }

      return strategy;
   }

   private assignCommonParameters(dto: StrategyDto) {
      if (!dto.parameters) {
         return;
      }
      
      dto.parameters['forceaccount'] = this.accountId || null;
      dto.parameters['autostoppingcount'] = isTruthy(this.autostoppingCount) ? this.autostoppingCount + '' : null;
   }

   //
   
   private preparePositionSizingSettings(settings: StrategyPositionSizing): string {
      if (!settings || !settings.isPositionSizingEnabled) {
         return null;
      }

      if (settings.uiSwitchWhen === 'efficient') {
         settings.switchWhenMoreEfficient = true;
      }

      const cpy = JSON.parse(JSON.stringify(settings)) as StrategyPositionSizing;

      delete cpy.isPositionSizingEnabled;
      delete cpy.uiSwitchWhen;
      delete cpy.switchToItems;
      delete cpy.sizingStrategyItems;

      if (cpy.sizingStrategy === PositionSizingStrategy.FixedRatio) {
         cpy.unitPerAmount = cpy.unitPerPercent = cpy.riskPerUnit = cpy.fractionalUnitSize = null;
      } else {
         cpy.ratioUnitSize = cpy.deltaSize = null;
         cpy.allowDecreaseMultiplier = false;
      }

      if (isNullOrUndefined(cpy.switchTo)) {
         cpy.altUnitPerAmount = cpy.altUnitPerPercent = cpy.altRiskPerUnit = cpy.altFractionalUnitSize = null;
         cpy.altRatioUnitSize = cpy.altDeltaSize = null;
         cpy.altAllowDecreaseMultiplier = false;
      } else {
         if (cpy.switchTo === PositionSizingStrategy.FixedRatio) {
            cpy.altUnitPerAmount = cpy.altUnitPerPercent = cpy.altRiskPerUnit = cpy.altFractionalUnitSize = null;
         } else {
            cpy.altRatioUnitSize = cpy.altDeltaSize = null;
            cpy.altAllowDecreaseMultiplier = false;
         }
      }

      return JSON.stringify(cpy);

   }

   //
   
   private onStrategyModelValidation(strategy: StrategyDto, errors: string[]): void {
      if (!isTruthy(strategy.terminalId)) {
         if (strategy.strategyKind !== StrategyKind.Menu) {
            if (strategy.strategyKind === StrategyKind.Engine
               || (strategy.strategyKind === StrategyKind.Pocket && !this.dialogConfig.isForBucket)) {
               errors.push('Terminal is missing');
            }
         }
      }
      
      if (this.selectedEntryTriggers.length > 0) {
         if (!this.entryTriggerContinuous) {
            if (!this.entryTriggerTimesCount) {
               errors.push('Entry Trigger times must be > 0, or continuous');
            }
         }
      }

      if (this.selectedAlgo.length > 0) {
         if (!this.accountId) {
            if (isHedgingAlgo(this.selectedAlgo[0].algoId)) {
               errors.push('Hedging algos must have explicitly set account');
            }
         }   
      }
   }
}
