import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { CashFlowStrategyRole, PortfolioItemDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { isValidNumber, isVoid } from 'projects/shared-components/utils';
import { daysToExpiration } from 'projects/shared-components/utils';

export class SideboxLegDescriptor {
   constructor(
      private readonly _leg: PortfolioItemDto | PositionDto,
      private readonly _role: CashFlowStrategyRole
   ) {

      const asset = _leg.tickerDisplayName.split(' ').slice(0, 3).join(' ');
      this.asset = asset;

      const expiration = _leg.tickerDisplayName.split(' ').slice(3).join(' ');
      this.expiration = expiration;

      const sExpDate = _leg.ticker.split(' ')[4];
      const diffIndays = daysToExpiration(sExpDate);
      const dte = diffIndays;
      this._dte = dte;
   }

   //
   private _dte: number;

   get legId(): string {

      if (!this._leg) {
         return undefined;
      }
      
      const leg = this._leg as unknown;

      return leg['portfolioItemId'] ?? leg['positionId'];
   }

   //
   get qty(): number {
      return this._leg ? this._leg.netPosition : undefined;
   }

   //
   asset: string;

   //
   expiration: string;

   //
   get daysToExpiration(): string {

      if (!isValidNumber(this._dte) || this._dte < 0) {
         return 'N/A';
      }

      return `${this._dte} days left`;
   }

   onPositionUpdate(payload: PositionDto[]): boolean {
      let updated = false;
      
      if (isVoid(this._leg)) {
         return false;
      }

      payload.forEach(pos => {
         if (pos.ticker != this._leg.ticker) {
            return;
         }
         if (pos.netPosition === this._leg.netPosition) {
            return;
         }
         this._leg.netPosition = pos.netPosition;
         updated =  true;
      });

      return updated;
   }
}
