<dx-popup width="95%" height="65%" [showTitle]="true" [title]="title" [closeOnOutsideClick]="false"
   [resizeEnabled]="true" [dragEnabled]="true" [visible]="isVisible" (onShown)="onShown()" (onHidden)="onHidden()">

   <div *dxTemplate="let data of 'content'">

      <div class="ets-popup-content">

         <as-split class="split" direction="horizontal" [gutterSize]="5">
            <as-split-area order="0" [visible]="securityContext.strategiesList"
               [size]="sessionStrategiesListSectionSize">
               <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="strategiesGridModel"></ag-grid-angular>
            </as-split-area>

            <as-split-area order="1" [visible]="securityContext.historyList" [size]="historyListSectionSize">
               <ag-grid-angular id="history-grid" class="ag-theme-balham-dark" style="width: 100%; height: 100%;"
                  [gridOptions]="historyDataGridModel"></ag-grid-angular>
            </as-split-area>

            <as-split-area order="2" [visible]="securityContext.strategyTradingData" [size]="tradingDataSectionSize">
               <div style="height: 100%; position: relative">
                  <ag-grid-angular class="ag-theme-balham-dark trading-data"
                     [ngClass]="{'hidden': selectedTabIndex !== 0 }" [gridOptions]="tradesGridModel">
                  </ag-grid-angular>
                  <ag-grid-angular class="ag-theme-balham-dark trading-data"
                     [ngClass]="{'hidden': selectedTabIndex !== 1 }" [gridOptions]="snapshotsGridModel">
                  </ag-grid-angular>
                  <ag-grid-angular class="ag-theme-balham-dark trading-data"
                     [ngClass]="{'hidden': selectedTabIndex !== 2 }" [gridOptions]="messagesGridModel">
                  </ag-grid-angular>
                  <div class="tabs">
                     <dx-tabs [dataSource]="tabs" [selectedIndex]="selectedTabIndex"
                        (onItemClick)="selectTab($event)">
                     </dx-tabs>
                  </div>
               </div>
            </as-split-area>
         </as-split>

         <!-- Date Picker -->
         <dx-popup width="300" height="150" [showTitle]="true" [visible]="this.isDatePickerVisible"
            (onHidden)="onDatePickerHidden()" title="Select Dates...">

            <div *dxTemplate="let data of 'content'">

               <div class="date-picker-content">

                  <div class="dates">
                     <div class="datebox">
                        <p>Start:</p>
                        <dx-date-box [showClearButton]="true" width="100%" [(value)]="dateRange.startDate"
                           (onValueChanged)="this.onDateRangeChanged()" type="date" displayFormat="dd-MMM-yy">
                        </dx-date-box>
                     </div>

                     <div class="datebox">
                        <p>End:</p>
                        <dx-date-box [showClearButton]="true" width="100%" [(value)]="dateRange.endDate"
                           (onValueChanged)="this.onDateRangeChanged()" type="date" displayFormat="dd-MMM-yy">
                        </dx-date-box>
                     </div>
                  </div>

                  <div class="set-dates">
                     <dx-button text="Apply Filter" (onClick)="applyDateFilter()"
                        [disabled]="!(dateRange.startDate && dateRange.endDate)"></dx-button>
                  </div>

               </div>
            </div>
         </dx-popup>

         <!-- System Details -->
         <dx-popup width="auto" minWidth="285" maxWidth="95%" height="98%" [showTitle]="true" title='System Details'
            [closeOnOutsideClick]="true" [resizeEnabled]="true" [dragEnabled]="true"
            (onHidden)="systemDetailsModel = null" *ngIf="systemDetailsModel?.isSystemDetailsVisible" [visible]="true">

            <div *dxTemplate="let data of 'content'">

               <div *ngIf="systemDetailsModel?.plainSystem" class="system-details">
                  <dx-scroll-view>
                     <div [style.height.px]="systemDetailsModel.containerHeight * 31">
                        <ets-system-details [system]="systemDetailsModel?.plainSystem"
                           (heightCalculated)="onHeightCalculated($event)"></ets-system-details>
                     </div>
                  </dx-scroll-view>
               </div>

               <div *ngIf="systemDetailsModel?.disposition" class="system-details dispo">

                  <dx-scroll-view>
                     <fieldset class="family" [style.height.px]="systemDetailsModel.containerHeight * 31">
                        <legend><strong>The Disposition</strong></legend>
                        <ets-system-details [system]="systemDetailsModel?.disposition.itself"
                           (heightCalculated)="onHeightCalculated($event)"></ets-system-details>
                     </fieldset>

                     <ng-container *ngFor="let group of systemDetailsModel?.disposition?.groups">
                        <fieldset class="family" [style.height.px]="systemDetailsModel.containerHeight * 31">
                           <legend>{{group.header}}</legend>
                           <ng-container *ngFor="let strategy of group.strategies">
                              <ets-system-details [system]="strategy" (heightCalculated)="onHeightCalculated($event)">
                              </ets-system-details>
                           </ng-container>
                        </fieldset>
                     </ng-container>
                  </dx-scroll-view>
               </div>
            </div>
         </dx-popup>
      </div>
   </div>
</dx-popup>