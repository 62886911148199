import { isNullOrUndefined } from "util";
import { CashFlowAdjustment } from "./CashFlowAdjustment";
import { SolutionOrderLegActions } from "./SolutionOrderLegActions";
import { QuoteDto } from "projects/shared-components/shell-communication/dtos/quote-dto.class";
import * as Enumerable from "linq";
import { LastQuoteCacheService } from "projects/shared-components/last-quote-cache.service";
import {isVoid} from "../../utils";


type IndexByQuote = { [ix: string]: any[]; };

export class QuoteIndex {
   constructor(private _lastQuoteCache: LastQuoteCacheService) {
   }

   //
   private _indexByQuote: IndexByQuote = {};
   private _indexByQuoteZones: IndexByQuote = {};

   //
   addData(adjustments: CashFlowAdjustment[]) {

      const toSubscribe = this.addToIndex(adjustments);

      const tickersToSubscribe = Enumerable
         .from(toSubscribe)
         .distinct()
         .toArray();

      this._lastQuoteCache.subscribeTickers(tickersToSubscribe);
   }

   addDataZones(adjustments: CashFlowAdjustment[]) {

      const toSubscribe = this.addToIndexZones(adjustments);

      const tickersToSubscribe = Enumerable
         .from(toSubscribe)
         .distinct()
         .toArray();

      this._lastQuoteCache.subscribeTickers(tickersToSubscribe);
   }

   removeData(adjustments: CashFlowAdjustment[]) {

      const self = this;

      const toUnsubscribe = [];

      function f(item: any, reverse = false) {

         const container = self._indexByQuote[item.ticker];

         if (isVoid(container)) {
            return;
         }
         const ix = container.indexOf(item);

         if (ix < 0) {
            return;
         }

         container.splice(ix, 1);

         if (container.length === 0) {
            toUnsubscribe.push(item.ticker);
         }
      }

      adjustments.forEach(adj => {

         adj.beforeState.forEach(item => f(item));

         adj.afterState.forEach(item => f(item, true));

         adj.mainLegs.forEach(item => f(item));

         adj.linkedLegs.forEach(item => f(item));

         adj.secondLinkedLegs.forEach(item => f(item));

         adj.thirdLinkedLegs.forEach(item => f(item));
      });

      if (toUnsubscribe.length === 0) {
         return;
      }

      this._lastQuoteCache.unsubscribeTickers(toUnsubscribe);
   }

   removeCustomData(items: any[]) {
      const toUnsubscribe = [];

      items.forEach(item => {

         const container = this._indexByQuote[item.ticker];

         if (isVoid(container)) {
            return;
         }
         const ix = container.indexOf(item);

         if (ix < 0) {
            return;
         }

         container.splice(ix, 1);

         if (container.length === 0) {
            toUnsubscribe.push(item.ticker);
         }
      });

      if (toUnsubscribe.length > 0) {
         this._lastQuoteCache.unsubscribeTickers(toUnsubscribe);
      }
   }

   //
   reset() {

      const tickersToUnsubscribe = Enumerable
         .from(Object.keys(this._indexByQuote))
         .distinct()
         .toArray();

      this._lastQuoteCache.unsubscribeTickers(tickersToUnsubscribe);

      this._indexByQuote = {};
   }

   resetZones() {

      const tickersToUnsubscribe = Enumerable
         .from(Object.keys(this._indexByQuoteZones))
         .distinct()
         .toArray();

      this._lastQuoteCache.unsubscribeTickers(tickersToUnsubscribe);

      this._indexByQuoteZones = {};
   }

   //
   onQuote(quotes: QuoteDto[]) {
      this.processIndexWithQuotes(quotes, this._indexByQuote);
      this.processIndexWithQuotes(quotes, this._indexByQuoteZones);
   }

   private processIndexWithQuotes(quotes: QuoteDto[], index: IndexByQuote): boolean {

      let hasUpdates = false;

      quotes.forEach(q => {

         const container = index[q.ticker];

         if (!isNullOrUndefined(container)) {
            if (container.length > 0) {

               container.forEach((item) => {
                  let px = q.mid;

                  if (!px) {
                     if (item.priceMultiplier < 0) {
                        // priceMultiplier = -1, means I will be buying option, hence giving money
                        px = q.ask;
                     } else if (item.priceMultiplier > 0) {
                        // priceMultiplier = 1, means I will be selling option, hence receiving money
                        px = q.bid;
                     }
                  }

                  px = px * item.priceMultiplier;

                  item.price = px;

               });

               hasUpdates = true;
            }
         }
      });

      return hasUpdates;
   }

   //
   private addToIndex(adjustments: CashFlowAdjustment[]) {
      const toSubscribe: string[] = [];

      const self = this;

      function f(item: any, reverse = false) {
         const isNew = self.processItem(item, self._indexByQuote, reverse);
         if (isNew) {
            toSubscribe.push(item.ticker);
         }
      }

      adjustments.forEach(adj => {

         adj.beforeState.forEach(item => f(item));

         adj.afterState.forEach(item => f(item, true));

         adj.mainLegs.forEach(item => f(item));

         adj.linkedLegs.forEach(item => f(item));

         adj.secondLinkedLegs.forEach(item => f(item));

         adj.thirdLinkedLegs.forEach(item => f(item));
      });

      return toSubscribe;
   }

   private addToIndexZones(adjustments: CashFlowAdjustment[]) {
      const toSubscribe: string[] = [];

      const self = this;

      function f(item: any, reverse = false) {
         const isNew = self.processItem(item, self._indexByQuoteZones, reverse);
         if (isNew) {
            toSubscribe.push(item.ticker);
         }
      }

      adjustments.forEach(adj => {

         adj.beforeState.forEach(item => f(item));

         adj.afterState.forEach(item => f(item, true));

         adj.mainLegs.forEach(item => f(item));

         adj.linkedLegs.forEach(item => f(item));

         adj.secondLinkedLegs.forEach(item => f(item));

         adj.thirdLinkedLegs.forEach(item => f(item));
      });

      return toSubscribe;
   }

   addToQuoteIndex(extraItems: any[], isReverse: boolean) {

      const toSubscribe = [];

      extraItems.forEach(x => {
        if (this.processItem(x, this._indexByQuote, isReverse)) {
           toSubscribe.push(x.ticker);
        }
      });

      const tickersToSubscribe = Enumerable
          .from(toSubscribe)
          .distinct()
          .toArray();

      this._lastQuoteCache.subscribeTickers(tickersToSubscribe);
   }

   private processItem(item, index, reverse = false) {

      let container = index[item.ticker];

      if (isNullOrUndefined(container)) {
         container = [];
         index[item.ticker] = container;
      }

      if (item.action) {

         const action: SolutionOrderLegActions = item.action;

         switch (action) {
            case 'Buy To Close':
            case 'Buy To Open':
               item.priceMultiplier = -1;
               break;
            case 'Sell To Close':
            case 'Sell To Open':
               item.priceMultiplier = 1;
               break;
            default:
               console.error('unknown order leg action', item);
               item.priceMultiplier = 0;
         }

      } else if (item.side) {

         if (item.side === 'Long') {

            item.priceMultiplier = 1;

         } else if (item.side === 'Short') {

            item.priceMultiplier = -1;

         } else {

            item.priceMultiplier = 0;
            console.error('unknown position side', item);
         }

         if (reverse) {

            item.priceMultiplier *= -1; // for AFTER state

         }
      } else if (!isVoid(item.groupId) && !isVoid(item.color)) {

         // hedge entity

         if (item.qty !== 0) {
            const side = item.qty < 0 ? 'Short' : 'Long';

            if (side === 'Long') {

               item.priceMultiplier = 1;

            } else if (side === 'Short') {

               item.priceMultiplier = -1;

            } else {

               item.priceMultiplier = 0;
               console.error('unknown position side', item);
            }

            if (reverse) {
               item.priceMultiplier *= -1; // for AFTER state

            }
         }
      }

      if (!isVoid(item.ticker)) {
         const q = this._lastQuoteCache.getLastQuote(item.ticker);
         if (!isVoid(q)) {
            item.price = q.mid * (item.priceMultiplier || 1);
         }
      }

      container.push(item);

      return container.length === 1;
   }
}
