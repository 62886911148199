import { RiskManagementComponent } from './risk-management.component';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { RiskRuleModel } from './risk-rule-model';
import { BlockedRuleCellRendererComponent } from './blocked-rule-cell-renderer.component';
import { UnviewedViolationsCellRendererComponent } from './unviewed-violations-cell-renderer.component';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';

export function getRiskRulesGridModel(
   this: RiskManagementComponent
): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Blocked?',
            field: 'isBlocked',
            width: 50,
            cellRenderer: 'blockedRuleCellRenderer'
         },

         {
            headerName: 'New?',
            field: 'hasUviewedViolations',
            width: 50,
            cellRenderer: 'hasUnviewedViolationsCellRenderer'
         },

         {
            headerName: 'Rule Name',
            field: 'ruleName'
         },


         {
            headerName: 'Description',
            field: 'ruleDescription'
         },

         {
            headerName: 'Spec',
            field: 'aggregationSpecString'
         },

         {
            headerName: 'Trading Objectives',
            field: 'tradingObjectives'
         },

         {
            headerName: 'S/P',
            field: 'isSession',
            valueFormatter: (params: ValueFormatterParams) => {
               return params.value ? 'S' : 'P';
            },
            cellStyle(params: CellClassParams) {
               return params.value
                  ? { color: 'yellow', 'font-weight': 'bold' }
                  : null;
            },
            width: 40
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      
      frameworkComponents: {
         blockedRuleCellRenderer: BlockedRuleCellRendererComponent,
         hasUnviewedViolationsCellRenderer: UnviewedViolationsCellRendererComponent
      },



      onFirstDataRendered: (params) => params.api.sizeColumnsToFit(),

      

      onGridReady: args => this.onRiskRulesGridReady(args),

      
      
      onSelectionChanged: args => this.onRiskRulesSelectionChanged(args),

      
      
      getRowNodeId: (rowData: RiskRuleModel) => {
         return rowData.ruleId;
      },



      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         if (this.securityContext.createRule) {
            const createMenuItem = {
               name: 'Create',
               action: () => {
                  return this.showRuleEditor();
               }
            };
            menu.push(createMenuItem);
         }

         if (this.securityContext.updateRule) {
            const updateMenuItem = {
               name: 'Update',
               disabled: !params.node,
               action: () => {
                  const data = params.node ? params.node.data : null;
                  return this.showRuleEditor(data);
               }
            };
            menu.push(updateMenuItem);
         }

         if (this.securityContext.removeRule) {
            const removeMenuItem = {
               name: 'Remove',
               disabled: !params.node,
               action: () => this.removeRule(params.node.data)
            };
            menu.push(removeMenuItem);
         }

         if (this.securityContext.unblockRule) {
            const unblockMenuItem = {
               name: 'Unblock',
               disabled: !params.node || !params.node.data.isBlocked,
               action: () => this.unblockRule(params.node.data)
            };
            menu.push(unblockMenuItem);
         }

         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );

         return menu;
      }
   } as GridOptions;
}
