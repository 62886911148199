import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { GridReadyEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import {
   TradingInstrumentsService, UniqueUnderlying
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { SimexDataProviderDto } from 'projects/shared-components/shell-communication/dtos/simex-data-provider-dto.class';
import { SimexSettingsDto } from 'projects/shared-components/shell-communication/dtos/simex-settings-dto.class';
import { SetSimexSettings } from 'projects/shared-components/shell-communication/operations/simex/set-simex-settings.class';
import { RestartSimex } from 'projects/shared-components/shell-communication/operations/simex/restart-simex.class';
import { GetSimexSettings } from 'projects/shared-components/shell-communication/operations/simex/get-simex-settings.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { DetectMethodChanges, DetectSetterChanges, isTruthy } from 'projects/shared-components/utils';
import { SimexMatchingRuleDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';

interface SimexSettingsSecurityContext {
   saveSimexSettings: boolean;
   restartSimex: boolean;
}

interface MatchingRuleModel {
   uniqueUnderlying?: UniqueUnderlying;
   price?: 'MID' | 'LAST' | 'BOOK';
   tradeThrough: boolean;
}

interface MatchingRulesDialog {
   width: string;
   height: string;
   title: string;
   closeOnOutsideClick: boolean;
   visible: boolean;
   buttonText: string;
   rules: MatchingRuleModel[];
}

@Component({
   selector: 'ets-simex-settings',
   templateUrl: 'simex-settings.component.html',
   styleUrls: ['simex-settings.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimexSettingsComponent implements OnInit, AfterViewInit {

   public constructor(
      private _changeDetector: ChangeDetectorRef,
      private _tradingInstrumentsService: TradingInstrumentsService,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _accessControlSvc: AccessControlService
   ) {
   }

   private _availableMarketsGrid: GridReadyEvent;

   //

   private _isVisible = false;
   get isVisible() { return this._isVisible; }

   @DetectSetterChanges()
   set isVisible(val: boolean) {
      this._isVisible = val;
   }

   //

   private _useTTLimits: boolean;
   get useTTLimits() { return this._useTTLimits; }
   set useTTLimits(val: boolean) {
      this._useTTLimits = val;
   }

   //

   private _acceptDataOutsideOfMarketHours: boolean;
   get acceptDataOutsideOfMarketHours() { return this._acceptDataOutsideOfMarketHours; }

   @DetectSetterChanges()
   set acceptDataOutsideOfMarketHours(val: boolean) {
      this._acceptDataOutsideOfMarketHours = val;
   }

   //

   private _isLoading: boolean;
   get isLoading() { return this._isLoading; }

   @DetectSetterChanges()
   set isLoading(val: boolean) {
      this._isLoading = val;
   }

   contextPopupParent: HTMLElement;

   dataProviders: SimexDataProviderDto[] = [];

   securityContext: SimexSettingsSecurityContext;

   matchingRulesDialog: MatchingRulesDialog;

   quoteProviderConfigEditor: {
      isQuoteProviderEditorVisible: boolean;
      provider: SimexDataProviderDto,
      newConfiguration: string
   };

   //

   async ngOnInit() {
      const isAvailable = (id) =>
         this._accessControlSvc.isSecureElementAvailable(id);

      this.securityContext = {
         restartSimex: isAvailable('36a2cd71-e7a5-4ee1-be50-db54f1ef9089'),
         saveSimexSettings: isAvailable('f54637bf-517a-4373-a5d5-6c42ac5c6713')
      };

      this.matchingRulesDialog = {
         width: '35%',
         height: '50%',
         title: 'Matching Rules Editor',
         closeOnOutsideClick: false,
         visible: false,
         buttonText: 'Matching Rules',
         rules: []
      };
   }

   ngAfterViewInit() {
      this._changeDetector.detach();
   }

   //

   @DetectMethodChanges()
   toggleMatchingRulesDialog() {
      this.matchingRulesDialog.visible =
         !this.matchingRulesDialog.visible;
   }

   @DetectMethodChanges()
   addMatchingRule() {
      this.matchingRulesDialog.rules.push({tradeThrough: false});
   }

   @DetectMethodChanges()
   removeMatchingRule(rule: MatchingRuleModel) {
      const ix = this.matchingRulesDialog.rules.indexOf(rule);
      if (ix >= 0) {
         this.matchingRulesDialog.rules.splice(ix, 1);
      }
   }

   runChangeDetector() {
      this._changeDetector.detectChanges();
   }


   saveMatchingRulesSettings() {
      let hasErrors = false;
      
      this.matchingRulesDialog.rules.forEach(r => {
         if (hasErrors) {
            return;
         }

         if (!isTruthy(r.price)) {
            hasErrors = true;
            return;
         }

         if (!isTruthy(r.uniqueUnderlying)) {
            hasErrors = true;
            return;
         }
      });

      if (hasErrors) {
         this._toastr.error('Please, fix matching rules errors');
         
         return;
      }

      this.closeMatchingRulesDialog();
   }

   @DetectMethodChanges()
   closeMatchingRulesDialog() {
      this.matchingRulesDialog.visible = false;
   }

   @DetectMethodChanges()
   onUniqueUnderlyingSelectedForMatchingRule(ul: UniqueUnderlying, item: MatchingRuleModel) {
      item.uniqueUnderlying = ul;
   }

   //

   onInstrumentsGridReady(args: GridReadyEvent) {
      this._availableMarketsGrid = args;
      const instruments = this._tradingInstrumentsService.getAllTradingInstruments();
      this._availableMarketsGrid.api.setRowData(instruments);
      this._availableMarketsGrid.api.sizeColumnsToFit();
   }

   //

   @DetectMethodChanges()
   onHidden() {
      this.isVisible = false;
      this._resetState();
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async saveChangesAndRestart(): Promise<void> {
      await this._saveChanges();
      await this.restartSimex();
      this.isVisible = false;
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async saveChangesAndClose(): Promise<void> {
      await this._saveChanges();
      this.isVisible = false;
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async onShown(): Promise<void> {
      this._resetState();
      await this._loadData();
   }

   //

   @DetectMethodChanges()
   close() {
      this.isVisible = false;
   }

   //

   private async _saveChanges() {
      
      const enabledDataProviders = this.dataProviders.filter(x => x.isEnabled).map(x => x.id);
      
      const dataProviders = this.dataProviders.slice();

      const dto = new SimexSettingsDto();

      dto.enabledDataProviders = enabledDataProviders;
      dto.dataProviders = dataProviders;
      dto.useTradeThroughLimits = this.useTTLimits;
      dto.acceptDataOutsideOfMarketHours = this.acceptDataOutsideOfMarketHours;
      dto.matchingRules = this.matchingRulesDialog.rules.map(r => {
         return {
            symbol: r.uniqueUnderlying?.underlying,
            kind: r.uniqueUnderlying?.kind,
            tradeThrough: r.tradeThrough,
            price: r.price
         } as SimexMatchingRuleDto;
      });
      
      const cmd = new SetSimexSettings(dto);

      this.isLoading = true;

      try {

         await this._shellClient.processCommand(cmd);

      } catch (e) {

         this._toastr.error('"Save SIMEX Settings" command completed with errors');

      } finally {

         this.isLoading = false;

      }
   }

   //

   async restartSimex() {
      const cmd = new RestartSimex();
      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Restart Simex" operation completed with errors');
      } finally {
         this.isLoading = false;
      }
   }

   //

   private async _loadData() {
      this.isLoading = true;

      try {

         const qry = new GetSimexSettings();
         const settings = await this._shellClient.processQuery<SimexSettingsDto>(qry);
         this.setSimexSettings(settings);

      } catch (e) {

         this._toastr.error('"Simex Settings" dialog loaded with errors');

      } finally {

         this.isLoading = false;

      }
   }

   @DetectMethodChanges()
   private setSimexSettings(settings: SimexSettingsDto) {
      
      this.useTTLimits = settings.useTradeThroughLimits;
      
      this.acceptDataOutsideOfMarketHours = settings.acceptDataOutsideOfMarketHours;
      
      this.dataProviders = settings.dataProviders;
      
      const uls = this._tradingInstrumentsService.getUniqueUnderlyings();

      this.matchingRulesDialog.rules = settings.matchingRules.map(r => {
         
         const ul = uls.find(x => x.underlying === r.symbol);
         
         if (!isTruthy(ul)) {
            return null;
         }

         return {
            uniqueUnderlying: ul,
            tradeThrough: r.tradeThrough,
            price: r.price
         } as MatchingRuleModel;

      }).filter(x => isTruthy(x));
   }

   //

   private _resetState() {
      this.useTTLimits = false;
      this.acceptDataOutsideOfMarketHours = false;
      this.dataProviders = [];
   }

   //

   @DetectMethodChanges()
   editQuoteProviderConfig(provider: SimexDataProviderDto) {
      this.quoteProviderConfigEditor = {
         isQuoteProviderEditorVisible: true,
         provider,
         newConfiguration: provider.configuration
      };
   }

   //

   @DetectMethodChanges()
   onQuoteProviderConfigHidden() {
      this.quoteProviderConfigEditor = null;
   }

   //

   discardQuoteProviderConfig() {
      this.onQuoteProviderConfigHidden();
   }

   //

   saveQuoteProviderConfig() {
      if (this.quoteProviderConfigEditor) {
         const newCfg = this.quoteProviderConfigEditor.newConfiguration;
         const provider = this.quoteProviderConfigEditor.provider;
         if (provider) {
            provider.configuration = newCfg;
         }
      }
      this.onQuoteProviderConfigHidden();
   }
}
