import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { MoveToPortfolioDialogComponent } from './move-to-portfolio-dialog/move-to-portfolio-dialog.component';
import { PortfolioGroupCellRendererComponent } from './portfolios.model';
import { EtsEditStrategyDialogModule } from '../edit-strategy-dialog/edit-strategy-dialog.module';
import { PortfoliosComponent } from './portfolios.component';
import { PositionAdjustModule } from '../position-adjust/position-adjust.module';
import { PositionOverrideModule } from '../position-override/position-override.module';
import { PortfolioItemsComponent } from './portfolio-items/portfolio-items.component';
import { PortfolioItemsGroupRowRendererComponent } from './portfolio-items/portfolio-items-archived-group-row';
import { AngularSplitModule } from 'angular-split';
import {
   ExpireDialogDateCellEditorComponent,
   ExpireDialogGroupRowRendererComponent,
   ExpireDialogTimeCellEditorComponent
} from './portfolio-items/expire-dialog-group-row';
import { TransferBucketDialogComponent } from './transfer-bucket-dialog/transfer-bucket-dialog.component';
import { DevExtremeModule } from '../devextreme.module';
import { DuplicateBucketDialogComponent } from './duplicate-bucket-dialog/duplicate-bucket-dialog.component';
import { AdjustmentStrategyDialogModule } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import { PositionSizingModule } from '../position-sizing/position-sizing.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { SessionHistoryModule } from 'projects/webtrader/src/app/session-history/session-history.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      AgGridModule.withComponents([SimpleHeaderGroupRowInnerRenderer]),
      EtsEditStrategyDialogModule,
      PositionAdjustModule,
      PositionOverrideModule,
      DevExtremeModule,
      AdjustmentStrategyDialogModule,
      PositionSizingModule,
      OptionsCommonModule,
      DateTimePickerModule,
      TimezonePickerModule,
      SessionHistoryModule
   ],
   
   exports: [
      PortfoliosComponent,
      PortfolioItemsComponent,
      MoveToPortfolioDialogComponent,
   ],

   declarations: [
      PortfoliosComponent,
      PortfolioItemsComponent,
      PortfolioGroupCellRendererComponent,
      PortfolioItemsGroupRowRendererComponent,
      MoveToPortfolioDialogComponent,
      ExpireDialogGroupRowRendererComponent,
      ExpireDialogDateCellEditorComponent,
      ExpireDialogTimeCellEditorComponent,
      TransferBucketDialogComponent,
      DuplicateBucketDialogComponent,
   ]
})
export class PortfoliosModule { }
