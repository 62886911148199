import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

/**
 * @returns StrategyDto[]
 */
export class GetStrategies implements ProtocolQuery {
  constructor(public readonly terminals: string[]) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategies`;
  }
}
