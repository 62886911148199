import { Component, ViewChild } from '@angular/core';
import { FlagshipAlgoParametersControl } from './flagship-algo-parameters-control';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { ParametersViewBase } from '../parameters-view-base';

@Component({
  selector: 'ets-flagship-parameters-view',
  templateUrl: './flagship-parameters-view.component.html',
  styleUrls: ['./flagship-parameters-view.component.scss']
})
export class FlagshipParametersViewComponent extends ParametersViewBase<FlagshipAlgoParametersControl> {
   
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;
   
   onEtsInit() { }
   
   onEtsAfterViewInit() { }

}
