import { StrategyCommanderRuleDto } from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-dto.interface';
import {
  TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { StrategiesService } from '../../../../shared-components/strategies/strategies.service';
import { getCommanderRuleMetadata } from './strategy-commander-rule-metadata';

export class StrategyCommanderRuleModel {
  constructor(
    private _dto: StrategyCommanderRuleDto,
    private _displayNameService: TradingInstrumentDisplayNameService,
    private _strategiesService: StrategiesService
    ) {
  }

  get strategyCommanderRuleId(): string {
    return this._dto.strategyCommanderRuleId;
  }

  get description(): string {
    return getCommanderRuleMetadata(this, this._displayNameService, this._strategiesService);
  }

  get actionParameters(): string {
    return this._dto.actionParameters;
  }

  get handlerParameters(): string {
    return this._dto.handlerParameters;
  }

  get handlerName(): string {
    return this._dto.handlerName;
  }

  get actionName(): string {
    return this._dto.actionName;
  }

  get handlerTypeId(): string {
    return this._dto.handlerTypeId;
  }

  get actionTypeId(): string {
    return this._dto.actionTypeId;
  }

  get isRecurring(): boolean {
    return this._dto.isRecurring;
  }

  get isDisabled(): boolean {
    return this._dto.isDisabled;
  }

  setDto(dto: StrategyCommanderRuleDto) {
    this._dto = dto;
  }
}
