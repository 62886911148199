import { ChangeDetectorRef, Component } from '@angular/core';
import { LimitAlgoParametersControl } from './limit-algo-parameters-control';
import { ParametersViewBase } from '../parameters-view-base';

@Component({
   selector: 'ets-limit-parameters-view',
   templateUrl: 'limit-parameters-view.component.html',
   styleUrls: ['limit-parameters-view.component.scss']
})

export class LimitParametersViewComponent extends ParametersViewBase<LimitAlgoParametersControl> {

   constructor(cd: ChangeDetectorRef) {
      super(cd);
   }

   
   onEtsInit() {
      
   }

   onEtsAfterViewInit() {
     
   }
}
