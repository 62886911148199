// tslint:disable: no-string-literal

import { Injectable } from '@angular/core';
import { Logger } from '../logging/logger.interface';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetAvailableAlgoes } from '../shell-communication/operations/strategies/get-available-algoes.class';
import { EtsConstants } from '../ets-constants.const';
import { AlgoModel } from './algo-model.class';
import { AlgoFamily } from './algo-family.class';
import { AlgoDescriptionBuilderBase } from './algo-description-builder-base.class';
import {
   DefaultAlgoDescriptionBuilder
} from './description-builders/default-algo-description-builder.class';
import {
   BullBearAlgoDescriptionBuilder
} from './description-builders/bullbear-algo-description-builder.class';
import {
   FusionAlgoDescriptionBuilder
} from './description-builders/fusion-algo-description-builder.class';
import { LimitAlgoDescriptionBuilder } from './description-builders/limit-algo-description-builder.class';
import {
   TradingInstrumentDisplayNameService
} from '../trading-instruments/trading-instrument-display-name.service';
import { environment } from '../environments/environment';
import { ClientConsoleClientService } from '../client-console/client-console-client.service';
import { CqrsService } from '../service-model/cqrs-service.interface';
import { ProtocolQuery } from '../service-model/protocol-query.interface';
import { GetAlgoMetadata } from '../client-console/protocol/operations';
import { LoggerService } from '../logging/logger-factory.service';
import { FlagshipAlgoDescriptionBuilder } from './description-builders/flagships/flagship-algo-description-builder.class';
import { TrendLimitAlgoDescriptionBuilder } from './description-builders/trendlimit-algo-description-builder.class';
import { TradeTheLineAlgoDescriptionBuilder } from './description-builders/tradetheline-algo-description-builder.class';
import { DynamicHedgeAlgoDescriptionBuilder } from './description-builders/dynamic-hedge-algo-description-builder.class';
import { FreezeHedgeAlgoDescriptionBuilder } from './description-builders/freeze-algo-description-builder.class';
import { HedgeAlgoDescriptionBuilder } from './description-builders/hedge-algo-description-builder.class';
import { RemoteAlgoDescriptionBuilder } from './description-builders/remote-algo-description-builder';
import { ShortOptionAlgoDescriptionBuilder } from './description-builders/adjustments/short-option-algo-description-builder.class';
import { LongOptionAlgoDescriptionBuilder } from './description-builders/adjustments/long-option-algo-description-builder.class';
import { PutSpreadAlgoDescriptionBuilder } from './description-builders/adjustments/put-spread-algo-description-builder.class';
import { InterestAlgoDescriptionBuilder } from './description-builders/interest-algo-description-builder.class';

@Injectable({ providedIn: 'root' })
export class AlgoMetadataService {
   constructor(
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _shellClient: ShellClientService,
      private readonly _clientConsoleClient: ClientConsoleClientService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('AlgoMetadataService');
   }

   private readonly _logger: Logger;
   private _algoModelById: Record<string, AlgoModel>;
   private _algoFamilies: AlgoFamily[];
   private _descriptionBuilders: Record<string, AlgoDescriptionBuilderBase>;


   init(): Promise<any> {
      this._algoFamilies = [];
      this._algoModelById = {};
      this._descriptionBuilders = {};

      this._addDescriptionBuilders();

      return this._loadAlgoDescriptors();
   }

   private _loadAlgoDescriptors(): Promise<void> {
      const dashboardAppId = EtsConstants.companyServices.etsDashboardApplicationId;

      const qry: ProtocolQuery = environment.runtimeAppId === dashboardAppId
         ? new GetAlgoMetadata()
         : new GetAvailableAlgoes();

      const cqrsService: CqrsService = environment.runtimeAppId === dashboardAppId
         ? this._clientConsoleClient
         : this._shellClient;

      return cqrsService.processQuery<AlgoModel[]>(qry)
         .then(data => {
            data.forEach(model => {
               this._algoModelById[model.algoId] = model;
               let family = this._algoFamilies.find(x => x.displayName === model.category);
               if (!family) {
                  family = new AlgoFamily(model.category);
                  this._algoFamilies.push(family);
               }
               family.algoes.push(model);
            });

            this._algoFamilies.sort((a, b) => {
               if (a.displayName < b.displayName) { return -1; }
               if (a.displayName > b.displayName) { return 1; }
               return 0;
            });

            this._algoFamilies.forEach(family => {
               family.algoes.sort((a, b) => {
                  if (a.displayName < b.displayName) { return -1; }
                  if (a.displayName > b.displayName) { return 1; }
                  return 0;
               });
            });

            this._logger.info('Initialized');
         });
   }

   getAlgoDescriptionBuilder(algoId: string): AlgoDescriptionBuilderBase {
      let adb = this._descriptionBuilders[algoId];
      if (!adb) {
         adb = this._descriptionBuilders['default'];
      }
      return adb;
   }

   getAlgoModel(algoId: string): AlgoModel {
      return this._algoModelById[algoId];
   }

   getAlgoFamilies(): AlgoFamily[] {
      return this._algoFamilies.slice();
   }

   private _addDescriptionBuilders() {
      this._descriptionBuilders['default'] = new DefaultAlgoDescriptionBuilder(this._displayNameService);
      const algorithms = EtsConstants.algorithms;
      this._descriptionBuilders[algorithms.limitAlgoId] = new LimitAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.bullBearAlgoId] = new BullBearAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.fusionAlgoId] = new FusionAlgoDescriptionBuilder(this._displayNameService);

      const flagships = algorithms.flagships;
      this._descriptionBuilders[flagships.followAlgoId] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.followAlgoId);
      this._descriptionBuilders[flagships.s40AlgoId] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.s40AlgoId);
      this._descriptionBuilders[flagships.outrightAlgoId] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.outrightAlgoId);
      this._descriptionBuilders[flagships.beforeLastAlgoId] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.beforeLastAlgoId);
      this._descriptionBuilders[flagships.oppositeBeforeLastAlgoId] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.oppositeBeforeLastAlgoId);
      this._descriptionBuilders[flagships.majority] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.majority);
      this._descriptionBuilders[flagships.minority] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.minority);
      this._descriptionBuilders[flagships.zigZag] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.zigZag);
      this._descriptionBuilders[flagships.fmr] = new FlagshipAlgoDescriptionBuilder(this._displayNameService, flagships.fmr);

      this._descriptionBuilders[algorithms.trendLimitAlgoId] = new TrendLimitAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.tradeTheLineAlgoId] = new TradeTheLineAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.dynamicHedgeAlgoId] = new DynamicHedgeAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.freezeHedgeAlgoId] = new FreezeHedgeAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.hedgeAlgoId] = new HedgeAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.remoteAlgoId] = new RemoteAlgoDescriptionBuilder(this._displayNameService);
      
      this._descriptionBuilders[algorithms.adjustment.putSpreadAlgoId] = new PutSpreadAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.adjustment.longOptionAlgoId] = new LongOptionAlgoDescriptionBuilder(this._displayNameService);
      this._descriptionBuilders[algorithms.adjustment.shortOptionAlgoId] = new ShortOptionAlgoDescriptionBuilder(this._displayNameService);
      
      this._descriptionBuilders[algorithms.interestAlgoId] = new InterestAlgoDescriptionBuilder(this._displayNameService);
   }
}
