<div class="panel-container" [ngClass]="{'ets-hidden': !isActive}" #container>
   <as-split class="split" direction="horizontal" [gutterSize]="5">
      <as-split-area order="0" [size]="this.sectionSize">
         <ag-grid-angular  #portfolios
                           class="ag-theme-balham-dark" 
                           style="width: 100%; height: 100%;"
                           [gridOptions]="this.portfoliosGridOptions"
         ></ag-grid-angular>
      </as-split-area>

      <as-split-area order="1" [size]="this.sectionSize">
         <ag-grid-angular  class="ag-theme-balham-dark" 
                           style="width: 100%; height: 100%;"
                           [gridOptions]="this.combosGridOptions"
         ></ag-grid-angular>
      </as-split-area>

      <as-split-area order="2" [size]="this.sectionSize">
         <ag-grid-angular  class="ag-theme-balham-dark" 
                           style="width: 100%; height: 100%;"
                           [gridOptions]="this.comboGroupsGridOptions"
         ></ag-grid-angular>
      </as-split-area>
   </as-split>
</div>

<ets-transfer-bucket-dialog></ets-transfer-bucket-dialog>
<ets-duplicate-bucket-dialog></ets-duplicate-bucket-dialog>

<!-- Create Portfolio -->
<dx-popup   [width]="370" 
            [height]="200" 
            title="Create Portfolio" 
            [showTitle]="true" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="false" 
            [dragEnabled]="false" 
            [visible]="createPortfolioConfig.isVisible"
            (onHidden)="this.onCloseCreatePortfolioDialogClicked()"
            >
   <div *dxTemplate="let data of 'content'" class="dialog create-portfolio">
      <div class="input name">
         <dx-text-box 
            placeholder="Enter portfolio name..." 
            [(value)]="createPortfolioConfig.portfolioName">
         </dx-text-box>
      </div>
      <div class="input terminal">
         <dx-select-box 
            [dataSource]="createPortfolioConfig.terminalList" 
            [displayExpr]="'displayName'"
            [valueExpr]="'terminalId'" 
            [(value)]="createPortfolioConfig.terminalId">
         </dx-select-box>
      </div>
      <div class="buttons">
         <dx-button 
            text="Create" 
            (onClick)="this.onCreatePortfolioClicked(this.createPortfolioConfig)">
         </dx-button>
         <dx-button 
            text="Cancel" 
            (onClick)="this.onCloseCreatePortfolioDialogClicked()">
         </dx-button>
      </div>
   </div>
</dx-popup>

<!-- Create Combo -->
<dx-popup   [width]="370" 
            [height]="200" title="Create Combo" 
            [showTitle]="true" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="false" 
            [visible]="createComboConfig.isVisible"
            (onHidden)="this.onCloseCreateComboDialogClicked()"
            >
   <div *dxTemplate="let data of 'content'" class="dialog create-combo">
      <div class="input name">
         <dx-text-box 
            placeholder="Enter Combo Name..." 
            [(value)]="this.createComboConfig.comboName"
            >
         </dx-text-box>
      </div>

      <div class="input double template">
         
         <div class="template-name">
            <dx-select-box placeholder="No template"
               [items]="this.createComboConfig.templates"
               [showClearButton]="true"
               [(value)]="this.createComboConfig.templateName"
            >
            </dx-select-box>
         </div>

         <div class="auto">
            <dx-check-box text="Auto" [(value)]="this.createComboConfig.isAuto"></dx-check-box>
         </div>

      </div>
      
      <div class="buttons">
         <dx-button text="Create" (onClick)="this.onCreateComboClicked(this.createComboConfig)"></dx-button>
         <dx-button text="Cancel" (onClick)="this.onCloseCreateComboDialogClicked()"></dx-button>
      </div>
   </div>
</dx-popup>

<!-- Create ComboGroup -->
<dx-popup   [width]="370" 
            [height]="200" 
            title="Create ComboGroup" 
            [showTitle]="true" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="false" 
            [dragEnabled]="false" 
            [visible]="createComboGroupConfig.isVisible"
            (onHidden)="this.onCloseCreateComboGroupDialogClicked()"
            >
   <div *dxTemplate="let data of 'content'" class="dialog create-combogroup">
      
      <div class="name">
         
         <dx-text-box 
            placeholder="ComboGroup Name" 
            [(value)]="createComboGroupConfig.comboGroupName">
         </dx-text-box>

      </div>
      
      <div class="buttons">
         
         <dx-button 
            text="Create" 
            (onClick)="this.onCreateComboGroupClicked(this.createComboGroupConfig)">
         </dx-button>
         
         <dx-button 
            text="Cancel" 
            (onClick)="this.onCloseCreateComboGroupDialogClicked()">
         </dx-button>

      </div>
   </div>
</dx-popup>

<!-- Rename Group -->
<dx-popup   [width]="370" 
            [height]="200" 
            title="Rename Bucket" 
            [showTitle]="true" 
            [closeOnOutsideClick]="false"
            [resizeEnabled]="false" 
            [dragEnabled]="false" 
            [visible]="renameBucketConfig.isVisible"
            (onHidden)="onCancelRenameBucketClicked()"
            >
   <div *dxTemplate="let data of 'content'" class="dialog rename-bucket">
      <div class="name">
         <dx-text-box   
            placeholder="Enter New Bucket Name"  
            [(value)]="this.renameBucketConfig.newBucketName"
            >
         </dx-text-box>
      </div>
      <div class="buttons">
         
         <dx-button 
            text="Rename" 
            (onClick)="onRenameBucketClicked()"
            >
         </dx-button>

         <dx-button 
            text="Cancel" 
            (onClick)="onCancelRenameBucketClicked()"
            >
         </dx-button>
      </div>
   </div>
</dx-popup>

<ets-edit-strategy-dialog></ets-edit-strategy-dialog>

<!-- Clear Trading Data Confirmation -->
<dx-popup [width]="370"
          [height]="190"
          title="Confirm Clearing Trading Data"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="false"
          [visible]="this.clearTradingDataConfirmPopupSettings?.isVisible"
          (onHidden)="this.onClearTradingDataConfirmPopupClosed()">
  <div *dxTemplate="let data of 'content'" class="ctd">
    <div>
      <p class="quesiton">Are you sure you want to clear trading data for this bucket?</p>
      <div class="bucket">
         <div class="type">
            {{this.clearTradingDataConfirmPopupSettings?.context?.bucketType}}
         </div>
         <div class="name">
            "{{this.clearTradingDataConfirmPopupSettings?.context?.bucketName}}"
         </div>
      </div>
    </div>
    
    <div class="buttons">
      
      <dx-button  class="button yes" 
                  text="Yes" 
                  (onClick)="this.onClearTradingDataForStrategyConfirmed()"
                  type="danger">
      </dx-button>
      
      <dx-button  class="button no" 
                  text="No"
                  (onClick)="this.onClearTradingDataConfirmPopupClosed()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<!-- Delete Bucket Confirmation -->
<dx-popup [width]="370"
          [height]="190"
          title="Confirm Deleting a Bucket"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="false"
          [visible]="this.deleteBucketDialog?.isVisible"
          (onHidden)="this.deleteBucketDialog_onClosed()">
  <div *dxTemplate="let data of 'content'" class="ctd">
    <div>
      <p class="quesiton">Are you sure you want to delete this bucket?</p>
      <div class="bucket">
         <div class="type">
            {{this.deleteBucketDialog?.context?.bucketType}}
         </div>
         <div class="name">
            "{{this.deleteBucketDialog?.context?.bucketName}}"
         </div>
      </div>
    </div>
    
    <div class="buttons">
      
      <dx-button  class="button yes" 
                  text="Yes" 
                  (onClick)="this.deleteBucketDialog_onConfirmed()"
                  type="danger">
      </dx-button>
      
      <dx-button  class="button no" 
                  text="No"
                  (onClick)="this.deleteBucketDialog_onClosed()">
      </dx-button>
    </div>

  </div>
</dx-popup>

<!-- Set bucket attribute -->
<dx-popup   [width]="370" 
            [height]="450" 
            [visible]="this.positionSizingDialog.isVisible" 
            title="Bucket's Position Sizing" 
            (onHidden)="this.positionSizingDialog_onHidden()"
            >
   <div *dxTemplate="let data of 'content'" class="ps-container" #holder>
      
      <dx-scroll-view>

         <ets-position-sizing [positionSizing]="this.positionSizingDialog.positionSizing"></ets-position-sizing>
         
         <hr class="button-divider">

         <div class="buttons">
            <div class="button">
               <dx-button width="100%" text="Save" (onClick)="this.positionSizingDialog_onSave()"></dx-button>
            </div>
            <div class="button">
               <dx-button width="100%" text="Cancel" (onClick)="this.positionSizingDialog_onCancel()"></dx-button>
            </div>
         </div>   
      </dx-scroll-view>

      <dx-load-panel 
         [position]="{my: 'center', at: 'center', of: container}" 
         [visible]="this.positionSizingDialog.isLoading">
      </dx-load-panel>

   </div>
</dx-popup>

<!-- Interest Rate -->
<dx-popup   [width]="400" 
            [height]="565" 
            [visible]="this.interestRateDialog.isVisible" 
            title="Interest " 
            (onHidden)="this.interestRateDialog_onHidden()" 
            [resizeEnabled]="true"
            >
   <div *dxTemplate="let data of 'content'" class="ir-container" #holder>
   
      <ets-interest-calculator-strategy 
         [parameters]="this.interestRateDialog.parameters">
      </ets-interest-calculator-strategy>

      <div class="buttons">
         
         <div class="button">
            <dx-button 
               width="100%" 
               text="Save" 
               (onClick)="this.interestRateDialog_onSave()"></dx-button>
         </div>

         <div class="button">
            <dx-button 
               width="100%" 
               text="Cancel" 
               (onClick)="this.interestRateDialog_onCancel()">
            </dx-button>
         </div>

      </div>

      <dx-load-panel 
         [position]="{my: 'center', at: 'center', of: container}" 
         [visible]="this.interestRateDialog.isLoading">
      </dx-load-panel>
      
   </div>
</dx-popup>

<!-- History -->
<ets-session-history-popup></ets-session-history-popup>