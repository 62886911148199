import { NgModule } from '@angular/core';
import { StrategyOrdersAndTradesComponent } from './strategy-orders-and-trades.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      DevExtremeModule,
      AgGridModule.withComponents()
   ],
   exports: [StrategyOrdersAndTradesComponent],
   declarations: [StrategyOrdersAndTradesComponent],
   providers: []
})
export class StrategyOrdersAndTradesModule { }
