import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CashFlowAdjustment} from "../model/CashFlowAdjustment";
import {DetectMethodChanges, isValidNumber, isVoid, removeExtraAdjustmentSettingsForComparison} from "../../utils";
import * as Enumerable from "linq";
import {AdjustmentsGroupForZonesGrid} from "../model/AdjustmentsGroup";
import {AdjustmentPricingGridComponent} from "../adjustment-pricing-grid.component";
import {AtmStrikeService} from "../../common-services/atm-strike-service/atm-strike.service";
import {BeforePositionModel} from "../positions-section/model/BeforePositionModel";
import {AdjustmentPricingSettingsDto} from "../../shell-communication/shell-operations-protocol";
import {PriceboxRow} from "../model/PriceboxRow";
import {VisibleOffsetsSettingsService} from "../visible-offsets/visible-offsets-settings.service";
import {MessageBusService} from "../../message-bus.service";

export class ZonesGridColumn {
    constructor(
        expirationDatePrettyWithDaysToExpiration: string,
        adjustments: CashFlowAdjustment[]
    ) {

        this.expirationDatePrettyWithDaysToExpiration = expirationDatePrettyWithDaysToExpiration;

        this.expirationDatePretty = expirationDatePrettyWithDaysToExpiration.split(' ')[0];

        this.adjustments = adjustments;

        if (adjustments.length > 0) {
            this.dayOfWeek = adjustments[0].dayOfWeek;
        }
    }

    expirationDatePrettyWithDaysToExpiration: string;

    expirationDatePretty: string;

    adjustments: CashFlowAdjustment[];

    isEmpty: boolean;

    dayOfWeek: string;

    displacedStrike: boolean;

    get price(): number {
        const totalPx = this.adjustments
            .map(x => ({price: (!isValidNumber(x.price) ? NaN : (x.price / x.perContract)) * x.ratio}))
            .map(x => !isValidNumber(x.price) ? NaN : x.price)
            .reduce((a, b) => a + b, 0);

        return totalPx;
    }
}

export class ZonesGridRow {
    constructor(group: AdjustmentsGroupForZonesGrid) {

        const columns = Enumerable
            .from(group.adjustments)
            .groupBy(x => x.expirationDatePrettyWithDaysToExpiration)
            .select(x => {
                const obj = new ZonesGridColumn(
                    x.key(),
                    x.toArray()
                );

                const delta = obj.adjustments[0].zonesGridStrikeDelta;

                const destStrike = parseInt(obj.adjustments[0].destinationStrike) + delta;

                obj.displacedStrike =
                    (obj.adjustments.every(x => !x.isEmpty) && destStrike !== group.atmStrike)
                    && obj.adjustments[0].coupleTag !== '3+3';

                return obj;
            }).toArray();

        this.columns = columns;

        this.columns.forEach(ce => {
            ce.isEmpty = ce.adjustments.some(x => x.isEmpty);
        });

        this.strike = group.atmStrike;

        const roles = Enumerable.from(this.columns)
            .selectMany(x => x.adjustments)
            .selectMany(x => x.zonesGridRoles)
            .distinct()
            .toArray();

        this.roles = roles;
    }

    isEmpty: boolean;
    strike: number;
    columns: ZonesGridColumn[];
    roles: string[];
}

type ColumnHeader = { date?: string, dayOfWeek?: string, dte?: string };

@Component({
    selector: 'ets-zones-grid',
    templateUrl: 'zones-grid.component.html',
    styleUrls: [
        'zones-grid.component.scss',
        '../adjustment-pricing-grid.component.scss',
        '../adjustment-grid-common-style.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZonesGridComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _visibleOffsetsService: VisibleOffsetsSettingsService,
        private readonly _messageBusService: MessageBusService
    ) {
    }

    private _positionsSnapshot: BeforePositionModel[];

    private  _adjustments: CashFlowAdjustment[];

    get sortedRows(): ZonesGridRow[] {
        return this.rows;
    }

    @Input() collapsed = false;

    @Input() root: AdjustmentPricingGridComponent;

    rows: ZonesGridRow[] = [];

    columnHeaders: ColumnHeader[];

    priceboxColCollapsed: false;

    adjustment: string;

    loadedRow: PriceboxRow;

    showImpliedVolatility: boolean;

    private _lastUsedSettings: AdjustmentPricingSettingsDto[];

    private _lastUsedStaticVol: number;
    get staticVol(): number {
        return this._lastUsedStaticVol / 100;
    }


    get lastUsedSettings(): AdjustmentPricingSettingsDto[] {
        return this._lastUsedSettings;
    }

    set lastUsedSettings(value: AdjustmentPricingSettingsDto[]) {
        if (isVoid(value)) {
            this._lastUsedSettings = undefined;
            return;
        }

        const nv = JSON.parse(JSON.stringify(value)) as AdjustmentPricingSettingsDto[];

        this._lastUsedStaticVol = nv[0].theoreticalPriceIv;

        nv.forEach(x => removeExtraAdjustmentSettingsForComparison(x));

        this._lastUsedSettings = nv;
    }


    private _atmRow = ZonesGridRow;

    ngOnInit() {
        this.showImpliedVolatility = this._visibleOffsetsService.zonesGrid.showImpliedVolatility;
        this._visibleOffsetsService
            .zonesGridUpdated$.subscribe(() => this.onZonesGridSettingsUpdated());

        setInterval(() => {
            this._changeDetector.detectChanges();
        }, 1000);
    }


    showPopup(adjustments: any) {
        this.root.showPopup(adjustments, 'Zones Grid');
    }

    @DetectMethodChanges()
    setData(adjustments: CashFlowAdjustment[]) {

        this.capturePositions();

        this.rows = this.prepareData(adjustments);

        this.columnHeaders = Enumerable.from(this.rows)
            .selectMany(x => x.columns)
            .select(x => `${x.dayOfWeek} ${x.expirationDatePrettyWithDaysToExpiration}`)
            .distinct()
            .select(x => {
                const parts = x.split(' ');
                if (parts.length === 3) {
                    const date = parts[1];
                    const dayOfWeek = parts[0];
                    const dte = parts[2];
                    return {
                        date,
                        dayOfWeek,
                        dte,
                    } as ColumnHeader
                }

                return {
                    date: parts[1],
                    dayOfWeek: parts[0]
                }
            })
            .toArray();

        const underlying = adjustments[0].underlying;

        this._atmStrikeService.watch(underlying);
    }

    private prepareData(adjustments: CashFlowAdjustment[]): ZonesGridRow[] {
        const couples = adjustments.filter(x => !isVoid(x.coupleTag));

        if (!isVoid(couples)) {
            return this.prepareData2(couples);
        }

        return this.prepareData1(adjustments);
    }

    private prepareData1(adjustments: CashFlowAdjustment[]): ZonesGridRow[] {
        this.adjustment = Enumerable
            .from(adjustments)
            .where(x => !x.isEmpty)
            .select(x => x.adjustmentType)
            .first();

        const adjustmentsByType = Enumerable.from(adjustments)
            .groupBy(x => x.atmStrike +  (x.zonesGridStrikeDelta || 0))
            .select(grp => {
                return {
                    atmStrike: grp.key(),
                    adjustments: grp.toArray()
                }
            });

        const priceboxRows = adjustmentsByType.select(x => {
            const row = new ZonesGridRow(x);
            return row;
        }).toArray();


        return priceboxRows;
    }

    private prepareData2(adjustments: CashFlowAdjustment[]): ZonesGridRow[] {

        this.adjustment = Enumerable
            .from(adjustments)
            .select(x => x.adjustmentType)
            .distinct()
            .toJoinedString('+');

        const rows = Enumerable.from(adjustments)
            .groupBy(x => {
                const grp = x.atmStrike + (x.zonesGridStrikeDelta || 0);
                return grp;
            })
            .select(group => {
                const ajdGroup: AdjustmentsGroupForZonesGrid = {
                    adjustments: group.toArray(),
                    atmStrike: group.key()
                };
                const row = new ZonesGridRow(ajdGroup);
                return row;
            })
            .toArray();

        return rows;
    }

    @DetectMethodChanges()
    reset() {
        this.rows = [];
        this.columnHeaders = [];
        this.lastUsedSettings = undefined;
    }

    getRowRoleCss(row: ZonesGridRow): string[] | undefined {
        const classes = [];

        const ul = this.root.settingsSection.underlying;

        let currentAtm = this._atmStrikeService.getCurrentAtm(ul);

        let removeAtmRole = false;

        row.roles.forEach((x, ix) => {

            if (x !== 'ATM') {
                classes.push('the-leg');
            }

            switch (x) {
                case 'ATM':
                    if (row.strike !== currentAtm) {
                        const atmRow = this.rows.find(x => x.strike === currentAtm);
                        if (!isVoid(atmRow)) {
                            atmRow.roles.push('ATM');
                            removeAtmRole = true;
                            break;
                        }
                    }
                    classes.push('current-atm');
                    break;
                case 'ShortOption':
                    classes.push('short-option');
                    break;
                case 'SpreadLongLeg':
                case 'SpreadShortLeg':
                    classes.push('spread');
                    break;
                case 'SecondSpreadLongLeg':
                case 'SecondSpreadShortLeg':
                    classes.push('second-spread');
                    break;
                case 'ProtectiveOption':
                case 'SecondProtectiveOption':
                    classes.push('protective');
                    break;
            }
        });

        if (removeAtmRole) {
            row.roles = row.roles.filter(x => x !== 'ATM');
            setTimeout(() => {
               this._changeDetector.detectChanges();
            }, 250);
        }

        return classes;
    }

    private capturePositions() {
        this._positionsSnapshot = [];

        const positionData = this.root.positionsSection.positionsData;

        if (isVoid(positionData)) {
            return ;
        }

        let poses = positionData[0].positions;

        if (positionData.length > 1) {
            poses = poses.concat(positionData[1].positions);
        }

        this._positionsSnapshot = poses;
    }

    getStrategyCodes(): string[] {
        const adjs = this.rows.flatMap(
            x => x.columns.flatMap(
                y => y.adjustments
            ));

        const m = adjs.map(x => x.optionStrategyCode);
        return m;
    }

    @DetectMethodChanges()
    private onZonesGridSettingsUpdated() {
        this.showImpliedVolatility = this._visibleOffsetsService.zonesGrid.showImpliedVolatility;
    }
}