<span *ngIf="root.isEmptyCell(adjType.adjustments[index])" class="na price">
  N/A
</span>

<span #offsetsStrategiesTarget *ngIf="!root.isEmptyCell(adjType.adjustments[index])">
    <span *ngIf="!root.isNullOrUndefined(adjType.adjustments[index].price)"
          [ngClass]="root.getPriceColorClass(adjType.adjustments[index].price)"
          class="price">
       {{ root.getAdjustmentPrice(adjType.adjustments[index]) | currency:'USD':'symbol':'1.2-2' }}
    </span>
    <ng-container>
        <ets-visible-offsets-component
            [adjustments]="[adjType.adjustments[index]]"
            [mouseOverTarget]="offsetsStrategiesTarget">
        </ets-visible-offsets-component>
    </ng-container>
</span>
