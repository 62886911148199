<div id="container">
  <div class="sk-cube-grid">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
  <br>
  <div id="progress-message">
    {{ progressMessage }}
  </div>
  <br>
  <div>
    <dx-progress-bar
      width="40%"
      [min]="0"
      [max]="100"
      [showStatus]="false"
      [value]="progressValue">
    </dx-progress-bar>
  </div>
</div>
