import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../../utils";
import {
    OffsetsManager,
    VisibleOffsetsSettingsService,
    VisibleOffsetsShowMode
} from "../visible-offsets/visible-offsets-settings.service";

@Component({
    selector: 'ets-zones-grid-menu-component',
    templateUrl: 'zones-grid-menu.component.html',
    styleUrls: ['zones-grid-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ZonesGridMenuComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _visibleOffsetsSettingsService: VisibleOffsetsSettingsService
    ) {
    }

    visible = false;

    showSpreadOffset: boolean;
    showSpreadWidth: boolean;
    showSecondSpreadOffset: boolean;
    showSecondSpreadWidth: boolean;
    showProtectiveOptionOffset: boolean;
    showSecondProtectiveOptionOffset: boolean;

    showOffsets: boolean;

    availableShowModes: VisibleOffsetsShowMode[] = [
        'Displayed',
        'Mouse Over'
    ];

    showMode: VisibleOffsetsShowMode;

    showSingleValue = false;

    spreadOffsetFilter: number;

    spreadWidthFilter: number;

    showImpliedVolatility: boolean;

    settingsSaved$ = new EventEmitter();

    private get offsetsManager(): OffsetsManager {
        return this._visibleOffsetsSettingsService.zonesGrid;
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show() {
        this.setWhatToShow();
        this.setWhenAndHowToShow();
        this.setFilters();
        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }

    private setWhatToShow() {
        this.showSpreadOffset = this.offsetsManager.showSpreadOffset;
        this.showSpreadWidth = this.offsetsManager.showSpreadWidth;
        this.showSecondSpreadOffset = this.offsetsManager.showSecondSpreadOffset;
        this.showSecondSpreadWidth = this.offsetsManager.showSecondSpreadWidth;
        this.showProtectiveOptionOffset = this.offsetsManager.showProtectiveOptionOffset;
        this.showSecondProtectiveOptionOffset = this.offsetsManager.showSecondProtectiveOptionOffset;
        this.showSingleValue = this.offsetsManager.showSingleValue;
        this.showImpliedVolatility = this.offsetsManager.showImpliedVolatility;
    }

    private setWhenAndHowToShow() {
        this.showOffsets = this.offsetsManager.showOffsets;
        this.showMode = this.offsetsManager.showMode;
    }

    private setFilters() {
        this.spreadOffsetFilter = this.offsetsManager.spreadOffsetFilter;
        this.spreadWidthFilter = this.offsetsManager.spreadWidthFilter;
    }

    saveSettings() {
        this.saveWhatToShow();
        this.saveWhenAndHowToShow();
        this.saveFilters();
        this.close();
        this.settingsSaved$.emit();
    }

    private saveWhatToShow() {
        this.offsetsManager.showSpreadOffset = this.showSpreadOffset;
        this.offsetsManager.showSpreadWidth = this.showSpreadWidth;
        this.offsetsManager.showSecondSpreadOffset = this.showSecondSpreadOffset;
        this.offsetsManager.showSecondSpreadWidth = this.showSecondSpreadWidth;
        this.offsetsManager.showProtectiveOptionOffset = this.showProtectiveOptionOffset;
        this.offsetsManager.showSecondProtectiveOptionOffset = this.showSecondProtectiveOptionOffset;
        this.offsetsManager.showSingleValue = this.showSingleValue;
        this.offsetsManager.showImpliedVolatility = this.showImpliedVolatility;
    }

    private saveWhenAndHowToShow() {
        this.offsetsManager.showMode = this.showMode;
        this.offsetsManager.showOffsets = this.showOffsets;
    }

    private saveFilters() {
        this.offsetsManager.spreadOffsetFilter = this.spreadOffsetFilter;
        this.offsetsManager.spreadWidthFilter = this.spreadWidthFilter;
    }

    @DetectMethodChanges()
    close() {
        this.visible = false;
    }
}
