import { GridApi } from 'ag-grid-community';
import { ShellMessageDto } from '../shell-communication/shell-dto-protocol';

export class EtsDateFilter {
   
   constructor(timezone: string) {
      this.timezone = timezone;
   }

   timezone: string;
   dateFrom?: string;
   dateTo?: string;
   
   applyFilter(grid: GridApi): any {
      let filterModel = {};
      
      if (this.dateFrom && this.dateTo) {
         
         filterModel = {
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            timezone: this.timezone
         };

      } else if (this.dateFrom) {
         
         filterModel = {
            dateFrom: this.dateFrom,
            timezone: this.timezone
         };

      } else if (this.dateTo) {
         
         filterModel = {
            filterType: 'date',
            dateTo: this.dateTo,
            timezone: this.timezone
         };

      } else {
         filterModel = null;
      }

      const instance = grid.getFilterInstance('timestamp');

      instance.setModel(filterModel);

      grid.onFilterChanged();
   }

   reset(api: GridApi) {
      this.dateFrom = this.dateTo = undefined;
   }

   clear() {
      this.dateFrom = null;
      this.dateTo = null;
   }
}

export class EtsDomainFilter {
   constructor() {
      
   }

   domains: string[] = [];
   domain?: string;

   applyFilter(grid: GridApi): void {
      
      const typeFilter = grid.getFilterInstance('domain');

      if (typeFilter) {
         if (this.domain) {
            typeFilter.setModel({
               values: [this.domain]
            });   
         } else {
            typeFilter.setModel(null);
         }

         grid.onFilterChanged();
      }
   }

   reset(api: GridApi) {
      this.domain = undefined;
      // this.applyFilter(api);
   }

   updateList(type: string): boolean {
      if (this.domains.indexOf(type) >= 0) {
         return false;
      }
      this.domains.push(type);
      return true;
   }

   filterList(grid: GridApi): void {
      const domains = [];
      grid.forEachNodeAfterFilter(node => {
         if (node.group) {
            return;
         }
         const data: ShellMessageDto = node.data;
         if (!data.shellMessageId) {
            return;
         }
         if (domains.indexOf(data.domain) < 0) {
            domains.push(data.domain);
         }
      });
      this.domains = domains;
   }

   clear() {
      this.domain = null;
      this.domains = [];
   }
}

export class EtsSourceFilter {
   constructor() {
      
   }

   sources: string[] = [];
   source?: string;

   applyFilter(grid: GridApi): void {
      
      const typeFilter = grid.getFilterInstance('source');

      if (typeFilter) {
         if (this.source) {
            typeFilter.setModel({
               values: [this.source]
            });   
         } else {
            typeFilter.setModel(null);
         }

         grid.onFilterChanged();
      }
   }

   reset(api: GridApi) {
      this.source = undefined;
   }

   updateList(source: string): boolean {
      if (this.sources.indexOf(source) >= 0) {
         return false;
      }
      this.sources.push(source);
      return true;
   }

   filterList(grid: GridApi): void {
      const newSources = [];
      grid.forEachNodeAfterFilter(node => {
         if (node.group) {
            return;
         }
         const data: ShellMessageDto = node.data;
         if (!data.shellMessageId) {
            return;
         }
         if (newSources.indexOf(data.domain) < 0) {
            newSources.push(data.source);
         }
      });
      this.sources = newSources;
   }

   clear() {
      this.source = null;
      this.sources = [];
   }
}

export class EtsMessageFilter {
   searchString: string;

   applyFilter(grid: GridApi) {
      const filter = grid.getFilterInstance('message');

      if (filter) {
         if (this.searchString) {
            filter.setModel({
               type: 'contains',
               filter: this.searchString
            });
         } else {
            filter.setModel(null);
         }
         grid.onFilterChanged();
      }
   }

   reset(api: GridApi) {
      this.searchString = undefined;
   }

   clear() {
      this.searchString = undefined;
   }
}

export class EtsTagsFilter {
   tag: string;
   tags: string[] = [];

   applyFilter(grid: GridApi) {
      const filter = grid.getFilterInstance('tags');

      if (filter) {
         if (this.tag) {
            filter.setModel({
               type: 'contains',
               filter: `(${this.tag})`
            });
         } else {
            filter.setModel(null);
         }
         grid.onFilterChanged();
      }
   }

   updateList(tags: string): boolean {
      if (!tags) {
         return false;
      }

      const splittedTags = tags.split(',');

      let affected = false;
      splittedTags.forEach(t => {
         
         if (this.tags.indexOf(t) >= 0) {
            return;
         }
         
         const strippedTag = t.substring(1, t.length - 1);
         
         this.tags.push(strippedTag);
   
         affected = true;
      });

      
      return affected;
   }

   filterList(grid: GridApi): void {
      const tags = [];

      grid.forEachNodeAfterFilter(node => {
         if (node.group) {
            return;
         }
         const data: ShellMessageDto = node.data;
         if (!data.shellMessageId) {
            return;
         }

         if (!data.tags) {
            return;
         }

         const splittedTags = data.tags.split(',');

         splittedTags.forEach(t => {
            
            const strippedTag = t.substring(1, t.length - 1);
            
            if (tags.indexOf(strippedTag) < 0) {
               tags.push(strippedTag);
            }   
         });

      });

      this.tags = tags;
   }

   reset(api: GridApi) {
      this.tag = undefined;
   }

   clear() {
      this.tag = undefined;
      this.tags = [];
   }
}
