// tslint:disable: no-string-literal

import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { GlobalSettings } from 'projects/shared-components/adjustment-control-panel/adjustment-control-panel.component';
import { ActionType, OrderType } from 'projects/shared-components/multi-trade-pad/multi-trade-pad.model';
import { ConvertToMarketSettings } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { DxValueChanged, isNullOrUndefined } from 'projects/shared-components/utils';
import { OrderLeg } from '../../../multi-trade-pad/multi-leg-order/order-leg.class';
import { AdjustmentStrategy, RangeSelectStrategy, AAStrategyValidationContext, AAStrategyParameters, OrderLegWrapper } from '../../adjustment-strategy-dialog.model';


export class PutSpreadParameters implements AAStrategyParameters {
   
   constructor(private readonly _cdRef: ChangeDetectorRef) {
      this.adjustmentStrategies = ['2-Sides', 'Credit', 'Debit'];
      this.rangeSelectStrategies = ['Dollars', 'Strikes'];

      this.displayName = 'Put Spread Adjustment';
   }

   displayName: string;

   //

   changed$: EventEmitter<void> = new EventEmitter();

   //

   readonly adjustmentStrategies: AdjustmentStrategy[];

   //
   readonly rangeSelectStrategies: RangeSelectStrategy[];

   //
   private _adjustmentStrategy: AdjustmentStrategy;
   get adjustmentStrategy(): AdjustmentStrategy {
      return this._adjustmentStrategy;
   }
   set adjustmentStrategy(v: AdjustmentStrategy) {
      this._adjustmentStrategy = v;
      this._cdRef.detectChanges();
   }

   //
   private _rangeSelectStrategy: RangeSelectStrategy;
   get rangeSelectStrategy(): RangeSelectStrategy {
      return this._rangeSelectStrategy;
   }
   set rangeSelectStrategy(v: RangeSelectStrategy) {
      this._rangeSelectStrategy = v;
      this._cdRef.detectChanges();
   }

   //
   private _dollarRange: number;
   get dollarRange(): number {
      return this._dollarRange;
   }
   set dollarRange(v: number) {
      this._dollarRange = v;
      this._cdRef.detectChanges();
   }

   //
   private _strikeRange: number;
   get strikeRange(): number {
      return this._strikeRange;
   }
   set strikeRange(v: number) {
      this._strikeRange = v;
   }

   //
   private _debitCost: number;
   get debitCost(): number {
      return this._debitCost;
   }
   set debitCost(v: number) {
      this._debitCost = v;
   }

   //
   private _creditCost: number;
   get creditCost(): number {
      return this._creditCost;
   }

   set creditCost(v: number) {
      this._creditCost = v;
   }

   //
   get isCreditCostAvailable(): boolean {
      return !isNullOrUndefined(this.adjustmentStrategy)
         && this.adjustmentStrategy !== 'Debit';
   }

   get isDebitCostAvailable(): boolean {
      return !isNullOrUndefined(this.adjustmentStrategy)
         && this.adjustmentStrategy !== 'Credit';
   }

   get isDollarRangeAvailable(): boolean {
      return this.rangeSelectStrategy === 'Dollars';
   }

   get isStrikeRangeAvailable(): boolean {
      return this.rangeSelectStrategy === 'Strikes';
   }

   readonly orderTypes: { value: OrderType, name: string }[] = [
      {
         name: 'Market',
         value: OrderType.Market
      },
      {
         name: 'Limit',
         value: OrderType.Limit
      }
   ];
   
   //

   private _orderType = OrderType.Limit;
   get orderType(): OrderType {
      return this._orderType;
   }
   set orderType(v: OrderType) {
      this._orderType = v;
      if (this._orderType !== OrderType.Limit) {
         this.convertToMarket = false;
      }
   }

   //

   private _convertToMarket = false;
   get convertToMarket(): boolean {
      return this._convertToMarket;
   }
   set convertToMarket(val: boolean) {
      this._convertToMarket = val;
      if (!this._convertToMarket) {
         this.convertToMarketSettings = {};
      }
   }

   convertToMarketSettings: ConvertToMarketSettings = {};

   //
   convertSide: 'Debit' | 'Credit';
   
   //
   onChange(ev: DxValueChanged<any>) {
      this._cdRef.detectChanges();
      
      if (ev.event) {
         this.changed$.emit();
      }
   }

   //

   getParameters(commonSettings?: GlobalSettings): string {
      let params = `adjustmentstrategy::=${this.adjustmentStrategy}`;
      params += `(~)rangeselectstrategy::=${this.rangeSelectStrategy}`;
      params += `(~)strikerange::=${this.strikeRange || ''}`;
      params += `(~)dollarrange::=${this.dollarRange || ''}`;
      params += `(~)debitcost::=${Math.round(this.debitCost * 100) || ''}`;
      params += `(~)creditcost::=${Math.round(this.creditCost * 100) || ''}`;
      params += `(~)ordertype::=${this.orderType}`;
      params += `(~)converttomarketmode::=${this.convertToMarketSettings.actionTimeMode || ''}`;
      params += `(~)converttomarkettime::=${this.convertToMarketSettings.actionTime || ''}`;
      params += `(~)timezone::=${this.convertToMarketSettings.timezone || ''}`;
      params += `(~)convertside::=${this.convertSide || ''}`;

      
      return params;
   }
   

   setParameters(strategy: StrategyModel) {
      const prms = strategy.parameters;

      const adjStr = prms['adjustmentstrategy'] as AdjustmentStrategy;
      this.adjustmentStrategy = adjStr;

      const creditCost = prms['creditcost'];
      this.creditCost = parseFloat(creditCost) / 100;

      const debitCost = prms['debitcost'];
      this.debitCost = parseFloat(debitCost) / 100;

      this.displayName = strategy.displayName;

      const dollarRange = prms['dollarrange'];
      if (dollarRange) {
         this.dollarRange = parseFloat(dollarRange);
      }

      const strikeRange = prms['strikerange'];
      if (strikeRange) {
         this.strikeRange = parseFloat(dollarRange);
      }

      this.rangeSelectStrategy = prms['rangeselectstrategy'] as RangeSelectStrategy;

      const oType = prms['ordertype'];
      this.orderType = oType ? parseInt(oType) : null;

      const convertToMarketMode = prms['converttomarketmode'] as any;
      if (convertToMarketMode) {
         this.convertToMarket = true;
         this.convertToMarketSettings.actionTimeMode = convertToMarketMode;
         this.convertToMarketSettings.actionTime = prms['converttomarkettime'] as any;

         const tz = prms['timezone'];
         this.convertToMarketSettings.timezone = tz;
      
         const convertSide = prms['convertside'];
         if (convertSide) {
            this.convertSide = convertSide as any;
         }
      }
   }

   //

   validate(validationContext: AAStrategyValidationContext): string[] {
      const errors = [];

      if (!this.displayName) {
         errors.push('"Display Name" is mandatory');
      }

      if (!this.adjustmentStrategy) {
         errors.push('"Adjustment Strategy" is mandatory');
      }

      if (!this.rangeSelectStrategy) {
         errors.push('"Range Select Mode" is mandatory');
      }

      if (!this.creditCost) {
         if (this.adjustmentStrategy !== 'Debit') {
            errors.push('"Credit" is mandatory');
         }
      }

      if (!this.debitCost) {
         if (this.adjustmentStrategy !== 'Credit') {
            errors.push('"Debit" is mandatory');
         }
      }

      if (!this.strikeRange) {
         if (this.rangeSelectStrategy === 'Strikes') {
            errors.push('"Strike Range" is mandatory');
         }
      }

      if (!this.dollarRange) {
         if (this.rangeSelectStrategy === 'Dollars') {
            errors.push('"Dollar Range" is mandatory');
         }
      }

      if (isNullOrUndefined(this.orderType)) {
         errors.push('"Order Type" is mandatory');
      } else {
         if (this.orderType === OrderType.Limit) {
            if (this.convertToMarket) {
               if (isNullOrUndefined(this.convertToMarketSettings.actionTime)) {
                  errors.push('"Convert Time is requried"');
               } else {
                  const parts = this.convertToMarketSettings.actionTime.split(':');
                  if (parts.length !== 3) {
                     errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                  } else {
                     const hours = parseInt(parts[0]);
                     const minutes = parseInt(parts[1]);
                     const seconds = parseInt(parts[2]);

                     if (isNaN(hours)) {
                        errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                     } else {
                        if (isNaN(minutes) || minutes > 59) {
                           errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                        } else {
                           if (isNaN(seconds) || seconds > 59) {
                              errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                           }
                        }
                     }
                  }
               }
            }
         }
      }

      if (validationContext.legRequired) {
         if (!validationContext.legToAttachTo) {
            errors.push('Must provide leg to attach to');
         } else {
            const ticker = validationContext.legToAttachTo.ticker;
            const side = validationContext.legToAttachTo.side;
            if (ticker.indexOf('Put') < 0 || side !== MarketSide.Buy) {
               errors.push('Put Spread can only be attached to a long put leg');
            }
         }
      }
     

      return errors;
   }

   //

   reset() {
      this.displayName = 'Put Spread Adjustment';
      this.adjustmentStrategy = null;
      this.creditCost = null;
      this.debitCost = null;
      this.dollarRange = null;
      this.strikeRange = null;
      this.rangeSelectStrategy = null;
      this.orderType = OrderType.Limit;
      this.convertToMarketSettings = {};
   }

   //

   filterSuitableLegs(legs: OrderLeg[]): OrderLegWrapper[] {
      return legs.map(l => {
         const available = l.ticker.indexOf('Put') >= 0 && l.action === ActionType.Buy;
         return { leg: l, isAvailable: available } as OrderLegWrapper;
      });
   }
}
