<dx-popup     class="popup"
              title="Symbol Picker"
              [width]="300"
              [height]="400"
              [showTitle]="true"
              [closeOnOutsideClick]="true"
              [resizeEnabled]="true"
              [dragEnabled]="true"
              [visible]="isVisible"
              (onHidden)="onClosed()">
      <div *dxTemplate="let data of 'content'" #container>
        <div id="container">
          <div class="content">
            
            <div class="search-box">
               <div class="search">
                  <dx-autocomplete  
                     [dataSource]="this.groupedData"
                     [searchExpr]="['instrument.displayName']"
                     [minSearchLength]="2"
                     [searchTimeout]="250"
                     [grouped]="true"
                     [valueExpr]="'instrument.displayName'"
                     placeholder="Type instrument name"
                     (onSelectionChanged)="this.onTradingInstrumentSelected($event)">
                  </dx-autocomplete>
               </div>
               <div class="btn add">
                  <dx-button text="Add To Favorites" [disabled]="!this.canSelectInstrument()"
                              (onClick)="this.onAddToFavoritesClicked()"></dx-button>
               </div>
            </div>
            
            <div class="tree">
              <dx-tree-view   id="treeview"
                              [dataSource]="tradingInstruments"
                              [keyExpr]="'id'"
                              [displayExpr]="'header'"
                              [itemsExpr]="'children'"
                              [selectByClick]="true"
                              [selectionMode]="'single'"
                              (onItemSelectionChanged)="onItemSelectionChanged($event)">
              </dx-tree-view>

              <dx-context-menu
                  [dataSource]="[{id: 'remove', text: 'Remove From Favorites'}]" 
                  target="#treeview .dx-treeview-item"
                  (onItemClick)="contextMenuItemClick($event)">
               </dx-context-menu>   
            </div>

            <div class="buttons">
   
               <!-- <div class="btn create">
                  <dx-button text="Create New Instrument" (onClick)="this.newInstrumentConfig.isVisible = true"></dx-button>
               </div> -->
      
               <div class="btn ok">
                  <dx-button  text="OK"
                              (onClick)="okButtonClicked()"
                              width="100"
                              type="success"
                              [disabled]="!canSelectInstrument()">
                  </dx-button>
               </div>
             </div>

          </div>
   
        </div>
        <dx-load-panel [visible]="isLoading" [position]="{my: 'center', at: 'center', of: container}"></dx-load-panel>
      </div>
</dx-popup>
