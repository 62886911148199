import { NgModule } from '@angular/core';
import { ClearTradingDataComponent } from './clear-trading-data.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
  imports: [
    CommonModule,
    DevExtremeModule
  ],
  exports: [ClearTradingDataComponent],
  declarations: [ClearTradingDataComponent],
  providers: []
})
export class ClearTradingDataModule { }
