import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccessControlService } from '../../access-control-service.class';
import {DaysOfWeekMap, DetectMethodChanges, DetectSetterChanges, isVisibleInTradingSystems, isVoid} from '../../utils';
import {ResourceEditorService} from "../resource-editor.service";
import {ResourceButton} from "../resource-button.interface";
import * as Enumerable from "linq";
import {ResourceNotificationsService} from "../resource-notifications.service";
import {MessageBusService} from "../../message-bus.service";
import {DateTime} from "luxon";
import {SessionService} from "../../authentication/session-service.service";

@Component({
   selector: 'ets-resource-menu',
   templateUrl: 'resource-menu.component.html',
   styleUrls: ['resource-menu.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceMenuComponent implements OnInit, AfterViewInit {
   public constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _accessControlService: AccessControlService,
      private readonly _resourceEditorService: ResourceEditorService,
      private readonly _resourceNotificationsService: ResourceNotificationsService,
      private readonly _messageBus: MessageBusService,
      private readonly _sessionService: SessionService
   ) {
   }

   overlayTop: number;
   overlayLeft: number;
   overlayRight: number;
   overlayWidth: number;
   menuItems: ResourceButton[] = [];

   article: {
      visible?: boolean;
      title?: string;
      content?: string;
   }

   private _isOverlayVisible = false;
   get isOverlayVisible(): boolean { return this._isOverlayVisible; }

   @DetectSetterChanges()
   set isOverlayVisible(value: boolean) { this._isOverlayVisible = value; }

   async ngOnInit() {
      this._resourceNotificationsService.init();
      this._resourceEditorService.stateChanged$.subscribe(x => this._changeDetector.detectChanges());
   }

   ngAfterViewInit() { this._changeDetector.detach(); }

   @DetectMethodChanges({isAsync: true})
   async onActionButtonClick($event: any): Promise<void> {
      if (this.isOverlayVisible) {
         this.isOverlayVisible = false;
         return;
      }

      const resourceButtons = this.getAvailableResourceButtons();
      if (resourceButtons.length === 0) {
         return;
      }

      const menuItems = Enumerable.from(resourceButtons).orderBy(x => x.seqNo).toArray();
      this.menuItems = menuItems;

      const menuButton: HTMLElement = document.getElementById('resource-menu');
      const rect = menuButton.getBoundingClientRect();
      this.overlayTop = rect.bottom + 2;
      this.overlayLeft = rect.left;
      this.overlayWidth = 149;
      this.isOverlayVisible = !this.isOverlayVisible;
   }

   @DetectMethodChanges()
   async onButtonClick(button: ResourceButton) {
      if (button.type === 'Link') {
         if (button.content.startsWith('ets://')) {
            this._messageBus.publish({
               topic: 'EtsLinkClickedMessage',
               payload: {
                  address: button.content
               }
            })
         } else {
            window.open(button.content, '_blank');
         }
      } else if (button.type === 'Article') {
         this.showArticle(button);
      }

      await this._resourceNotificationsService.ackResource(button);

      this.isOverlayVisible = !this.isOverlayVisible;
   }

   @DetectMethodChanges()
   private showArticle(button: ResourceButton) {
      this.article = {
         visible: true,
         title: button.title,
         content: button.content
      }
   }

   @DetectMethodChanges()
   hideArticle() {
      this.article = {};
   }

   needsAttention(btn?: ResourceButton):boolean {
      if (isVoid(btn)) {

         const resourceButtons = this.getAvailableResourceButtons();

         return resourceButtons
             .some(x => !this._resourceNotificationsService.isAcknowledged(x));
      }

      const isAcked = this._resourceNotificationsService.isAcknowledged(btn);
      return !isAcked;
   }

   private filerThroughConditions(x: ResourceButton) {
      const asset = this.filterAsset(x);
      const date = this.filterDate(x);
      const dayOfWeek = this.filterDayOfWeek(x);
      const extraPos = this.filterExtraPos(x);
      const users = this.filterUsers(x);

      return asset && date && dayOfWeek && extraPos && users;
   }

   private filterAsset(btn: ResourceButton): boolean {
      if (isVoid(btn.conditionAsset)) {
         return true;
      }

      const payload: {asset?: string} = {};
      this._messageBus.publish({
         topic: 'Resources.Asset',
         payload
      });

      return payload.asset === btn.conditionAsset;
   }

   private filterDate(btn: ResourceButton): boolean {
      if (isVoid(btn.conditionDate)) {
         return true;
      }

      const parts = btn.conditionDate.split('|');

      if (parts.length < 3) {
         return false;
      }

      const month = parts[0];
      const day = parseInt(parts[1]);
      const timezone = parts[2];

      const dateTime = DateTime.fromObject({zone: timezone});
      const sameMonth = dateTime.monthLong === month;
      if (!sameMonth) {
         return false;
      }
      const sameDay = dateTime.day === day;
      return sameDay;
   }

   private filterDayOfWeek(btn: ResourceButton): boolean {
      if (isVoid(btn)) {
         return false;
      }

      if (isVoid(btn.conditionDayOfWeek)) {
         return true;
      }

      if (btn.conditionDayOfWeek.indexOf('|') < 0) {
         return false;
      }

      const parts = btn.conditionDayOfWeek.split('|');

      if (parts.length < 2) {
         return false;
      }

      const dayOfWeek = parts[0];
      const timezone = parts[1];

      const dateTime = DateTime.fromObject({zone: timezone});

      const zoneDayOfWeek = DaysOfWeekMap[dateTime.weekday];

      return zoneDayOfWeek === dayOfWeek;
   }

   private filterExtraPos(btn: ResourceButton): boolean {

      const payload: {hasExtraPos?: boolean} = {};

      this._messageBus.publish({
         topic: 'Resources.HasExtraPos',
         payload
      });

      if (btn.conditionHasExtraPos) {
         return payload.hasExtraPos || false;
      }

      return true;
   }

   private filterUsers(btn: ResourceButton): boolean {

      if (isVoid(btn.conditionUsers)) {
         return true;
      }

      const parts = btn.conditionUsers.split('|');

      if (parts.length === 0) {
         return true;
      }

      const available = parts.some(x => x === this._sessionService.sessionData.userId);

      return available;
   }

   private getAvailableResourceButtons(): ResourceButton[] {
      const t = this._resourceEditorService
          .getResourceButtons()
          .filter(x => x.isPublished)
          .filter(x => this.filerThroughConditions(x));

      return t;
   }
}
