import { NgModule } from '@angular/core';
import { TotalStrategyIssuesComponent } from './total-strategy-issues.component';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [TotalStrategyIssuesComponent],
  declarations: [TotalStrategyIssuesComponent],
  providers: []
})
export class TotalStrategyIssuesModule { }
