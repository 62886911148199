import { CellValueChangedEvent, Color, FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   centeredColumnDef,
   defaultLoadingOverlayTemplate
} from 'projects/shared-components/ag-grid-contrib';
import { AgGridColumn } from 'ag-grid-angular';
import { TimestampsService } from '../timestamps.service';
import { WatchlistComponent, WatchlistRow } from './watchlist.component';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { formatNumber } from '@angular/common';

export function getWatchlistGridOptions(
   this: WatchlistComponent,
   tiService: TradingInstrumentsService,
   timestampsService: TimestampsService
): GridOptions {

   const columns = setupColumns(tiService, timestampsService);

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',
      
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 1,

      onGridReady: args => this.onGridReady(args),

      getRowNodeId: (rowData: WatchlistRow) => {
         return rowData.rowId;
      },

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.api.sizeColumnsToFit();
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Add Symbol',
               action: () => this.onAddSymbol(),
            },
            {
               name: 'Remove Symbol',
               action: () => this.onRemoveSymbol(params.node.data),
               disabled: !params.node
            },
            'separator',
            {
               name: 'Reset To Favorites',
               action: () => this.resetToFavorites()
            },
            'separator',
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
         ];
      },

      onCellValueChanged: (args: CellValueChangedEvent) => {
         if (args.colDef.field !== 'ticker') {
            return;
         }

         this.onTickerChanged(args);
      },

      onRowSelected: (args: RowSelectedEvent) => {
         if (args.rowPinned) {
            return;
         }

         if (!args.node) {
            return;
         }

         if (!args.node.isSelected()) {
            return;
         }

         if (!args.node.data) {
            return;
         }

         this.onRowSelected(args.node.data);
      }
      
      // onDisplayedColumnsChanged: () => this.onStateChanged(),
      // onColumnResized: () => this.onStateChanged()

   } as GridOptions;
}

function setupColumns(
   tiService: TradingInstrumentsService,
   timestampsService: TimestampsService): Partial<AgGridColumn>[] {
   const cols: Partial<AgGridColumn>[] = [];

   cols.push(
      {
         headerName: 'Symbol',
         field: 'ticker',
         editable: true,
         valueFormatter: (params: ValueFormatterParams) => {
            const ti = tiService.getInstrumentByTicker(params.value);
            return ti ? ti.displayName : params.value;
         }
      },

      {
         headerName: 'Last',
         field: 'last',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params || !params.value) {
               return '';
            }
            return formatNumber(params.value, 'en-US', '1.2-2');
         },
         enableCellChangeFlash: true
      },

      {
         headerName: 'Bid',
         field: 'bid',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params || !params.value) {
               return '';
            }
            return formatNumber(params.value, 'en-US', '1.2-2');
         }
      },

      {
         headerName: 'Ask',
         field: 'ask',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params || !params.value) {
               return '';
            }
            return formatNumber(params.value, 'en-US', '1.2-2');
         }
      },

      {
         headerName: '$ Chg',
         field: 'dollarChange',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params || !params.value) {
               return '';
            }
            return formatNumber(params.value, 'en-US', '1.2-2');
         },
         cellStyle: (args: CellClassParams) => {
            if (!args.value) {
               return {color: ''};
            }

            if (args.value > 0) {
               return {color: 'green'};
            }

            if (args.value < 0) {
               return {color: 'red'};
            }
         }
      },

      {
         headerName: '% Chg',
         field: 'percentChange',
         valueFormatter: (params: ValueFormatterParams) => {
            if (!params || !params.value) {
               return '';
            }
            return formatNumber((params.value * 100), 'en-US', '1.2-2') + '%';
         },
         cellStyle: (args: CellClassParams) => {
            if (!args.value) {
               return {color: ''};
            }

            if (args.value > 0) {
               return {color: 'green'};
            }

            if (args.value < 0) {
               return {color: 'red'};
            }
         }
      },

      {
         headerName: 'Time',
         field: 'timestamp',
         valueFormatter: (params: ValueFormatterParams) => {
            const quote: WatchlistRow = params.data;
            if (!quote.timestamp) {
               return '';
            }
            const fmtTime = timestampsService.getDefaultShortFormattedTime(quote.timestamp);
            return fmtTime;
         }
      }
   );

   return cols;
}
