import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxNumberBoxComponent } from 'devextreme-angular/ui/number-box';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { CashFlowStrategyRole, OptionExpirationDescriptor } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { DetectMethodChanges, DetectSetterChanges, findAtmStrikeIndex, isVoid, isNullOrUndefined } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { makeOptionTicker, OptionType } from '../options.model';

interface Strikes {
   top: number[];
   bottom: number[];
   topPage: number;
   bottomPage: number;
   topTotalPages: number;
   bottomTotalPages: number;
   all: number[];
   step1: number;
   step2: number;
   step3: number;
}

@Component({
   selector: 'ets-strikes-dropdown',
   templateUrl: 'strikes-dropdown.component.html',
   styleUrls: ['strikes-dropdown.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class StrikesDropdownComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _lastQuoteCache: LastQuoteCacheService,
      private _messageBus: MessageBusService,
   ) { }

   private _unsubscriber = new Subject();
   private _currentStrikeCallTicker: string;
   private _currentStrikePutTicker: string;
   
   @Input() targetInstrument: TradingInstrument;

   @Input() inputAttr: any = {style: 'text-align: center'};
   
   //
   private _expiration: OptionExpirationDescriptor;
   get expiration(): OptionExpirationDescriptor { return this._expiration; }
   @DetectSetterChanges()
   @Input() 
   set expiration(val: OptionExpirationDescriptor) {
      
      this._expiration = val;

      if (this._expiration) {
         
         this.strikes.all = this._expiration.strikes;

      } else {
         
         const prevStrike = this.strike;         
         
         this.strike = null;
         
         if (prevStrike) {
            this.strikeChange.emit(this.strike);
            this.strikeChanged.emit({value: this.strike, previousValue: prevStrike});
         }

         const prevAtmStrike = this.atmStrike;
         this.atmStrike = null;
         this.atmMode = false;
         this.atmOffset = null;
         this.atmOffsetInDollars = false;
         
         if (prevAtmStrike) {
            this.atmStrikeChange.emit(this.atmStrike);
            this.atmStrikeChanged.emit({value: this.atmStrike, previousValue: prevAtmStrike});
         }

      }
      
      if (this.strike) {

         const ix = this.strikes.all.indexOf(this.strike);

         if (ix < 0) {
            const oldStrike = this.strike;
            this.strike = undefined;
            this.strikeChange.emit(undefined);
            this.strikeChanged.emit({ value: undefined, previousValue: oldStrike }); 
         }

      }

      this.onDropdownClosed();
   }
   
   //
   @Input() disable: boolean;

   //
   @Input() showAtm: boolean;

   //
   private _hidden = false;
   get hidden(): boolean {
      return this._hidden;
   }

   @DetectSetterChanges()
   @Input() 
   set hidden(v: boolean) {
      this._hidden = v;
      if (this._hidden) {
         this._atmMode = false;
         this.strike = null;
      }
   }

   //

   @Input() showClearButton = false;

   @Input() readonly  = false;

   getNgClassForItem(item: number): string[] {
      const result = [];
      
      if (item === -1) {
         result.push('empty');
      }

      if (item === this.currentAtm) {
         result.push('current-atm');
      }

      if (item === this.strike) {
         result.push('the-leg');
      }

      if (!isVoid(this.legRole)) {
         switch (this.legRole) {
            case 'ProtectiveOption':
            case 'SecondProtectiveOption':
               result.push('protective');
               break;
            case 'SpreadLongLeg':
            case 'SpreadShortLeg':
               result.push('spread');
               break;
            case 'SecondSpreadLongLeg':
            case 'SecondSpreadShortLeg':
               result.push('second-spread');
               break;
            case 'ShortOption':
               result.push('short-option');
               break;
            default:
               break;
         }
      }

      return result;
   }
   
   //
   @ViewChild(DxNumberBoxComponent) strikeBox: DxNumberBoxComponent;
   
   //
   private _strike : number;
   public get strike() : number {
      return this._strike;
   }
   @DetectSetterChanges()
   @Input()
   public set strike(v : number) {
      this._strike = v;
   }

   //
   
   currentAtm: number;
   
   //

   currentLeg:  number;

   //

   @Input()
   legRole: CashFlowStrategyRole;

   //
   @Input() atmStrike: string;
   
   //
   @Output() 
   strikeChange = new EventEmitter<number>();
   
   //
   @Output() 
   strikeChanged = new EventEmitter<{value: number, previousValue: number}>();
   
   //
   @Output() 
   atmStrikeChange = new EventEmitter<string>();
   
   //
   @Output() 
   atmStrikeChanged = new EventEmitter<{value: string, previousValue: string}>();

   //
   get boxHeight(): number {
      return this.showAtm ? 370 : 320;
   }

   //
   private _atmOffset = 0;
   get atmOffset() {
      return this._atmOffset;
   }

   @DetectSetterChanges()
   set atmOffset(v: number) {
      this._atmOffset = v;
   }

   //
   private _atmOffsetInDollars = false;
   get atmOffsetInDollars(): boolean {
      return this._atmOffsetInDollars;
   }

   @DetectSetterChanges()
   set atmOffsetInDollars(v: boolean) {
      this._atmOffsetInDollars = v;
   }
   
   //
   private _isLoading = false;
   
   get isLoading(): boolean { 
      return this._isLoading; 
   }
   
   @DetectSetterChanges()
   set isLoading(val: boolean) { 
      this._isLoading = val; 
   }

   //
   get isDisabled(): boolean {
      return isNullOrUndefined(this.targetInstrument) 
         || isNullOrUndefined(this.expiration) || this.disable;
   }

   //
   private _atmMode = false;
   get atmMode(): boolean {
      return this._atmMode;
   }

   @DetectSetterChanges()
   set atmMode(v: boolean) {
      this._atmMode = v;
      if (v) {
         this.strike = null;
         this.textBoxStrike = null;
         this.atmOffset = 0;
      } else {
         this.atmStrike = null;
         this.atmOffset = null;
         this.atmOffsetInDollars = false;
      }
   }

   //
   get strikeText() {
      if (this.atmMode) {
         if (!this.atmOffset) {
            return 'ATM';
         }

         const dollarPlaceHolder = this.atmOffsetInDollars ? '$' : '';
         const sign = this.atmOffset < 0 ? '-' : '+';
         const offset = Math.abs(this.atmOffset);

         return `ATM${sign}${dollarPlaceHolder}${offset}`;
      }

      return this.strike;
   }

   //
   get topStrikes(): number[] {
      
      const top = this.strikes.top;

      let currentPage = this.strikes.topPage || 1;

      const totalPages = this.strikes.topTotalPages || 1;

      if (currentPage > totalPages) {
         currentPage = totalPages;
      }

      if (currentPage <= 0) {
         currentPage = 1;
      }

      if (this.strikes.topPage !== currentPage) {
         this.strikes.topPage = currentPage;
      }

      const strikesToSkip = (currentPage - 1) * 20; 
      
      const result = top.slice(strikesToSkip, strikesToSkip + 20);

      return result;
   }

   //
   get bottomStrikes(): number[] {
      
      const bottom = this.strikes.bottom;
   
      let currentPage = this.strikes.bottomPage || 1;
      const totalPages = this.strikes.bottomTotalPages || 1;
      
      if (currentPage > totalPages) {
         currentPage = totalPages;
      }

      if (currentPage <= 0) {
         currentPage = 1;
      }

      if (this.strikes.bottomPage !== currentPage) {
         this.strikes.bottomPage = currentPage;
      }

      const strikesToSkip = (currentPage - 1) * 20; 
      
      const result = bottom.slice(strikesToSkip, strikesToSkip + 20);

      return result;
   }

   //
   strikes: Strikes = {
      top: [],
      bottom: [],
      topPage: 0,
      bottomPage: 0,
      topTotalPages: null,
      bottomTotalPages: null,
      all: [],
      step1: null,
      step2: null,
      step3: null,
   };

   private _showDecimalStrikes = false;
   public get showDecimalStrikes() {
      return this._showDecimalStrikes;
   }
   public set showDecimalStrikes(value) {
      this._showDecimalStrikes = value;
      setTimeout(() => {
         this.onDropdownOpened(null);
      }, 0);
   }

   //
   callQuote: number;
   
   //
   putQuote: number;
   
   //
   textBoxStrike: number;

   //
   private _isDropdownOpened = false;
   get isDropdownOpened(): boolean { 
      return this._isDropdownOpened; 
   }
   @DetectSetterChanges()
   set isDropdownOpened(value: boolean) { this._isDropdownOpened = value; }

   //
   ngOnInit() {

      this.validateStrike = this.validateStrike.bind(this);

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            filter(x => !!this.targetInstrument),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onQuote(x.payload));
   }

   //
   ngAfterViewInit() { 
   }

   //
   ngAfterViewChecked() {
   }

   //
   ngOnDestroy() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   @DetectMethodChanges()
   applyTextBoxStrike(): boolean {
      const tbStrike = this.textBoxStrike;
      if (!isNullOrUndefined(tbStrike)) {
         if (this.strikes.all.indexOf(tbStrike) === -1) {
            return  false;
         }
      }

      const previousValue = this.strike;
      const value = this.textBoxStrike;

      this.strike = this.textBoxStrike;
      this.isDropdownOpened = false;
      
      this.strikeChange.emit(value);
      this.strikeChanged.emit({value, previousValue});
      
      return true;
   }

   //
   @DetectMethodChanges({delay: 25})
   onStrikeClick(strike: number) {
   
      if (isVoid(strike)) {
         return;
      }
      
      setTimeout(() => {

         if (this._currentStrikeCallTicker) {
            this._lastQuoteCache.unsubscribeTicker(this._currentStrikeCallTicker);
            this._currentStrikeCallTicker = undefined;
            this.callQuote = undefined;
         }

         if (this._currentStrikePutTicker) {
            this._lastQuoteCache.unsubscribeTicker(this._currentStrikePutTicker);
            this._currentStrikePutTicker = undefined;
            this.putQuote = undefined;
         }
      
         if (isNullOrUndefined(strike)) {
            return;
         }
         
         const callTicker = makeOptionTicker(this.expiration, OptionType.Call, strike, 'American');
         const putTicker = makeOptionTicker(this.expiration, OptionType.Put, strike, 'American');
         
         this._currentStrikeCallTicker = callTicker;
         this._currentStrikePutTicker = putTicker;

         const callQuote = this._lastQuoteCache.subscribeTicker(callTicker);
         const putQuote = this._lastQuoteCache.subscribeTicker(putTicker);

         if (callQuote) {
            this.onQuote([callQuote]);
         }
         
         if (putQuote) {
            this.onQuote([putQuote]);
         }
      });

      this.textBoxStrike = strike;
      
      if (this.strikeBox && !isNullOrUndefined(strike)) {
         this.strikeBox.instance.focus();
      }
   }

   //
   validateStrike(options: { value: number }) {
      if (!options || !options.value) {
         return false;
      }
      return this.strikes.all.indexOf(options.value) >= 0;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async onDropdownOpened(event): Promise<void> {

      this.isLoading = true;

      try {

         this.strikes = await this.getStrikes(this.expiration);

         if (this.strikeBox) {
            this.strikeBox.instance.focus();
         }

      } finally {

         this.isLoading = false;

      }

   }

   //
   @DetectMethodChanges()
   onDropdownClosed() {
      this.textBoxStrike = undefined;

      if (this.atmMode) {
         const prev = this.atmStrike;

         if (!this.atmOffset) {
            this.atmStrike = 'ATM';
         } else {
            
            const dollarPlaceHolder = this.atmOffsetInDollars ? '$' : '';
            const sign = this.atmOffset < 0 ? '-' : '+';
            const offset = Math.abs(this.atmOffset);
   
            this.atmStrike = `ATM${sign}${dollarPlaceHolder}${offset}`;
         }

         if (!this.expiration) {
            this.atmStrike = null;
         }

         this.atmStrikeChange.emit(this.atmStrike);
         this.atmStrikeChanged.emit({value: this.atmStrike, previousValue: prev});
      }

      this.currentAtm = undefined;
   }

   //
   @DetectMethodChanges()
   changeTextStrike(changeValue: number, operation: '+' | '-') {
      if (!this.textBoxStrike) {
         return;
      }
     
      if (operation === '-') {
         changeValue *= -1;
      }

      const currentValue = this.textBoxStrike;
      const newValue = currentValue + changeValue;

      this.onStrikeClick(newValue);
   }

   //
   @DetectMethodChanges()
   onTextBoxStrikeValueChanged(ev) {
      if (!ev.value) {
         this.onStrikeClick(null);
      }
   }

   //
   increaseAtmOffset() {
      this.atmOffset = (this.atmOffset || 0) + 1;
   }
   
   //
   decreaseAtmOffset() {
      this.atmOffset = (this.atmOffset || 0) - 1;
   }

   //
   @DetectMethodChanges({isAsync: true})
   async moveStrikesPage(
      atmSide: 'above' | 'below',
      slideDirection: 'up' | 'down' | 'atm', 
   ) {

      if (atmSide === 'above') {

         if (slideDirection === 'up') {

            let nextPage = (this.strikes.topPage || 0) + 1;

            if (nextPage > this.strikes.topTotalPages) {
               nextPage = this.strikes.topTotalPages;
            }

            this.strikes.topPage = nextPage;

         } else if (slideDirection === 'down') {
           
            let nextPage = (this.strikes.topPage || 0) - 1;

            if (nextPage < 0) {
               nextPage = 0;
            }

            this.strikes.topPage = nextPage;
         }

      } else if (atmSide === 'below') {

         if (slideDirection === 'down') {

            let nextPage = (this.strikes.bottomPage || 0) + 1;

            if (nextPage > this.strikes.bottomTotalPages) {
               nextPage = this.strikes.bottomTotalPages;
            }

            this.strikes.bottomPage = nextPage;

         } else if (slideDirection === 'up') {
           
            let nextPage = (this.strikes.bottomPage || 0) - 1;

            if (nextPage < 0) {
               nextPage = 0;
            }

            this.strikes.bottomPage = nextPage;
         }

      }
   }

   //
   @DetectMethodChanges()
   resetStrikesPage(atmSide: 'above' | 'below',) {
      if (atmSide === 'above') {
         this.strikes.topPage = 0;
      } else if (atmSide === 'below') {
         this.strikes.bottomPage = 0;
      }
   }

   //
   navigationButtonVisibility(
      atmSide: 'above' | 'below',
      slideDirection: 'up' | 'down' | 'atm'
   ): 'hidden' | 'visible' {

      let result: 'visible' | 'hidden' = 'visible';

      if (atmSide === 'above') {

         if (slideDirection === 'up') {
            
            const nextPage = (this.strikes.topPage || 1) + 1;
            result = nextPage <= this.strikes.topTotalPages
               ? 'visible'
               : 'hidden';

         } else if (slideDirection === 'down') {
            
            const nextPage = (this.strikes.topPage || 1) - 1;
            result = nextPage >= 1
               ? 'visible'
               : 'hidden';

         }

      } else if (atmSide === 'below') {

         if (slideDirection === 'down') {

            const nextPage = (this.strikes.bottomPage || 1) + 1;
            result = nextPage <= this.strikes.bottomTotalPages
               ? 'visible'
               : 'hidden';

         } else if (slideDirection === 'up') {
           
            const nextPage = (this.strikes.bottomPage || 1) - 1;
            result = nextPage >= 1
               ? 'visible'
               : 'hidden';

         }

      }

      return result;
   }

   //
   @DetectMethodChanges()
   private onQuote(quotes: QuoteDto[]): void {
      if (!this.isDropdownOpened) {
         return;
      }

      const filtered = quotes.filter(q => q.ticker === this._currentStrikeCallTicker || q.ticker === this._currentStrikePutTicker);
      if (filtered.length === 0) {
         return;
      }
      filtered.forEach(q => {
         const mid = (q.bid + q.ask) / 2;

         if (q.ticker === this._currentStrikeCallTicker) {
            
            this.callQuote = mid;

         } else if (q.ticker === this._currentStrikePutTicker) {

            this.putQuote = mid;
         }
      });
   }

   //
   private async getStrikes(expiration: OptionExpirationDescriptor): Promise<Strikes> {

      const lastQuote = await this._lastQuoteCache.getLastQuoteWithAwait(expiration.underlyingTicker);

      const strikes = expiration.strikes;

      let centerIx = findAtmStrikeIndex(strikes, lastQuote);
     
      const currentAtm = strikes[centerIx];

      let centerStrike = currentAtm;

      const stepMultiplier = expiration.underlyingSymbol === 'SPX' ? 25 : 5;
      const lowest = Math.floor(centerStrike / stepMultiplier) * stepMultiplier;
      const biggest = (Math.floor(centerStrike / stepMultiplier) + 1) * stepMultiplier;

      const lowestDiff = Math.abs(lowest - centerStrike);
      const biggestDiff = Math.abs(biggest - centerStrike);

      if (lowestDiff > biggestDiff) {
         centerStrike = biggest;
      } else  {
         centerStrike = lowest;
      }

      if (strikes.indexOf(centerStrike) < 0) {
         do {
            centerStrike = (Math.floor(centerStrike / stepMultiplier) - 1) * stepMultiplier;
         } while (centerStrike > 0 && strikes.indexOf(centerStrike) < 0);                
      }
      
      centerIx = strikes.indexOf(centerStrike);


      //
      // Determine steps
      //
      let step1 = isVoid(expiration.strikeStep)
         ? Math.abs(strikes[centerIx] - strikes[centerIx - 1])
         : expiration.strikeStep;
      
      if ( !Number.isInteger(step1) && step1.toString().indexOf('.5') < 0 ) {
         let newStep1 = Math.floor(step1);
         if (newStep1 === 0) {
            newStep1 = Math.ceil(step1);
         }
         step1 = newStep1;
      }

      let step2 = 5;
      
      let step3 = 10;

      if (lastQuote) {
         
         if (lastQuote.lastPx <= 200) {
            step2 = 5;
            step3 = 10;
         }

         if (lastQuote.lastPx > 200 && lastQuote.lastPx <= 500) {
            step2 = 10;
            step3 = 20;
         }
         
         if (lastQuote.lastPx > 500) {
            step2 = 10;
            step3 = 50;
         }
         
      }

      //
      // Fill out top strikes
      //
      const topStrikes = [];
      
      const maxStrike = Math.max(...strikes);

      topStrikes.push(centerStrike);

      const topStrikesRange = Math.ceil((maxStrike - centerStrike + 1) / 20) * 20;

      for (let index = 1; index <= topStrikesRange; index++) {

         const element = centerStrike + (index * step1);

         if (element > maxStrike && (index % 20) === 0) {
            break;
         }

         const strike =  strikes.indexOf(element) >= 0       
            ? element
            : -1;

         topStrikes.push(strike);
         
         if (this.showDecimalStrikes) {
            const halfElement = element + 0.5;
            const halfStrike = strikes.indexOf(halfElement) >= 0 
               ? halfElement
               : -1;

            if (halfStrike > 0) {
               topStrikes.push(halfStrike);
            }
         }
      }
      
      //
      // Fill out bottom strikes
      //
      const bottomStrikes = [];

      const minStrike = Math.min(...strikes);

      const bottomStrikesRange = Math.ceil((centerStrike - minStrike + 1) / 20) * 20;

      bottomStrikes.push(centerStrike);
      
      // we use ..-1 because we already pushed centerStrke into array
      // for (let index = 1; index <= (bottomStrikesRange -1 ); index++) {
      for (let index = 1; index <= (bottomStrikesRange); index++) {

         const element = centerStrike - (index * step1);

         if (element < minStrike && (index % 20) === 0) {
            break;
         }
         
         const strike =  strikes.indexOf(element) >= 0        
            ? element
            : -1;

         bottomStrikes.push(strike); 
      }

      const topTotalPages = Math.ceil(topStrikes.length / 20);
      const bottomTotalPages = Math.ceil(bottomStrikes.length / 20);

      const result: Strikes = { 
         top: topStrikes,
         bottom: bottomStrikes, 
         topPage: 1,
         bottomPage: 1,
         topTotalPages,
         bottomTotalPages,
         all: strikes,
         step1,
         step2,
         step3,
      };


      if (this.strike) {

         const topIx = topStrikes.indexOf(this.strike) + 1;
         
         if (topIx >=0 ) {

            const page = Math.ceil(topIx / 20);
            
            result.topPage = page;

         } 
         
         const bottomIx = bottomStrikes.indexOf(this.strike) + 1;
                     
         if (bottomIx >=0 ) {
            const page = Math.ceil(bottomIx / 20);
            result.bottomPage = page;
         }

      }


      this.currentAtm = currentAtm;

      return result;
   }
}
