import {
  Bar,
  DomeCallback,
  ErrorCallback,
  GetMarksCallback,
  HistoryCallback,
  HistoryDepth,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  Mark,
  OnReadyCallback,
  ResolutionBackValues,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  ServerTimeCallback,
  SubscribeBarsCallback,
  TimescaleMark
} from '../../price-chart/data-feed-chart-api';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PnLChartSubscription } from '../pnl-chart-subscription';
import { filter, takeUntil } from 'rxjs/operators';
import { IChartingLibraryWidget } from '../../../webtrader/src/assets/tv/charting_library/charting_library.min';
import { EventEmitter } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { PnLSnapshotDto } from 'projects/shared-components/shell-communication/dtos/pnl-snapshot-dto.interface';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import { 
  ShellConnectionStatusChangedUIMessage 
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { GetPnLChartData } from 'projects/shared-components/shell-communication/operations/charts/get-pnl-chart-data.class';
import { 
  RemovePnLChartSubscription 
} from 'projects/shared-components/shell-communication/operations/charts/remove-pnl-chart-subscription.class';

const RESOLUTIONS = ['10S'];

export class PnLDataFeed implements IDatafeedChartApi, IExternalDatafeed {

  public constructor(
    private _componentId: string,
    private _subscriptions: PnLChartSubscription[],
    private _shellClient: ShellClientService,
    private _messageBus: MessageBusService,
    private _toastr: ToastrService,
    private _chartResolver: () => IChartingLibraryWidget
  ) {
  }

  public resetData: EventEmitter<void> = new EventEmitter();

  private _emptyRequests = [];
  private readonly _dataSubscribers:
    { [ix: string]: { onBar: SubscribeBarsCallback, onReset: () => void, isAccumulated: boolean } }
    = {};
  private _unsubscriber = new Subject<any>();

  public init(): void {
  }

  public onReady(callback: OnReadyCallback): void {
    this._messageBus
      .of<PnLSnapshotDto[]>('PnLSnapshotDto')
      .pipe(
         takeUntil(this._unsubscriber)
      )
      .subscribe(msg => {
        const filtered = msg.payload.filter(
          x => `${x.subscriptionId}_10S` in this._dataSubscribers
        );

        if (filtered.length > 0) {
          setTimeout(() => {
            this._onPnLSnapshotDto(filtered);
          }, 0);
        }
      });

    this._messageBus
      .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(msg => this._onClearTradingDataUIMessage(msg.payload));

    this._messageBus
      .of<ShellConnectionStatusChangedUIMessage>(
        'ShellConnectionStatusChangedUIMessage'
      )
      .pipe(
        filter(msg => msg.payload.isConnected),
        takeUntil(this._unsubscriber)
      )
      .subscribe(msg => this._onShellConnectionStatusChangedUIMessage(msg.payload) );

    setTimeout(() => {
      const configObj = {
        supported_resolutions: RESOLUTIONS
      };
      callback(configObj);
    }, 0);
  }

  public async getBars(symbolInfo: LibrarySymbolInfo,
                       resolution: ResolutionString,
                       rangeStartDate: number,
                       rangeEndDate: number,
                       onResult: HistoryCallback,
                       onError: ErrorCallback,
                       isFirstCall: boolean): Promise<void> {

    const queryCode = `${symbolInfo.ticker}_${resolution}`;

    if (this._emptyRequests.includes(queryCode)) {
      rangeStartDate = rangeStartDate - 86400 * 3;
    }

    const start = new Date(rangeStartDate * 1000);
    const end = new Date(rangeEndDate * 1000);

    const subscription = this._subscriptions.find(x => x.subscriptionId === symbolInfo.ticker);

    const qry = new GetPnLChartData(
      subscription,
      start,
      end
    );

    let pnlSnapshots: PnLSnapshotDto[];
    try {
      pnlSnapshots = await this._shellClient.processQuery<PnLSnapshotDto[]>(qry);
    } catch (e) {
      onError('Failed to get pnl snapshots from server');
      return;
    }

    if (pnlSnapshots.length > 0) {
      if (isFirstCall) {
        const subsKey = this._makeSubscriptionKey(
          `${symbolInfo.ticker}_${resolution}`
        );
      }

      const bars: Bar[] = pnlSnapshots.map(dto => {
        const pnl = subscription.isAccumulated
          ? dto.accumulatedPnL
          : dto.sessionPnL;

        const trades = subscription.isAccumulated
          ? dto.accumulatedTrades
          : dto.sessionTrades;

        return {
          time: dto.timestamp.getTime(),
          open: pnl,
          high: pnl,
          low: pnl,
          close: pnl,
          volume: trades
        };
      });

      const ix = this._emptyRequests.indexOf(queryCode);
      if (ix >= 0) {
        this._emptyRequests.splice(ix, 1);
      }

      setTimeout(() => {
        onResult(bars, { noData: false });
      }, 0);
    } else {
      let shouldFinishRequests = false;
      const ix = this._emptyRequests.indexOf(queryCode);
      if (ix < 0) {
        this._emptyRequests.push(queryCode);
      } else {
        shouldFinishRequests = true;
        this._emptyRequests.splice(ix, 1);
      }

      setTimeout(() => {
        onResult([], { noData: shouldFinishRequests });
      }, 0);
    }
  }

  public resolveSymbol(symbolName: string, onResolve: ResolveCallback
    ,                  onError: ErrorCallback): void {

    setTimeout(() => {
      const subscription = this._subscriptions.find(x => x.subscriptionId === symbolName || x.displayName === symbolName);
      if (!subscription) {
        onError('Symbol not found');
        return;
      }

      const exchange = `P&L Chart (${subscription.isAccumulated ? 'Accumulated' : 'Session'})`;

      const result: LibrarySymbolInfo = {
        name: subscription.displayName,
        data_status: 'streaming',
        full_name: subscription.displayName,
        description: subscription.displayName,
        type: 'custom_study',
        exchange,
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolName,
        supported_resolutions: RESOLUTIONS,
        seconds_multipliers: ['10'],
        has_seconds: true,
        minmov: 0.01,
        listed_exchange: exchange,
        pricescale: 0.01,
        has_intraday: true,
        has_daily: false
      };
      onResolve(result);
    });
  }

  public async subscribeBars(symbolInfo: LibrarySymbolInfo,
                             resolution: ResolutionString,
                             onTick: SubscribeBarsCallback,
                             listenerGuid: string,
                             onResetCacheNeededCallback: () => void): Promise<void> {

    const subsId = listenerGuid.split('_')[0];
    const subs = this._subscriptions.find(s => s.subscriptionId === subsId);
    if (!subs) {
      console.error(`Subscription object not found for ${listenerGuid}`);
      return;
    }

    this._dataSubscribers[listenerGuid] = {
      onBar: onTick,
      onReset: onResetCacheNeededCallback,
      isAccumulated: subs.isAccumulated
    };
  }

  public async unsubscribeBars(listenerGuid: string): Promise<void> {
    await this._unsubscribeBars(listenerGuid);
  }

  public calculateHistoryDepth(
    resolution: ResolutionString,
    resolutionBack: ResolutionBackValues,
    intervalBack: number): HistoryDepth | undefined {

    // if depth is less than 3 days, we set it explicitly to 3 days.
    // this is required for cases, when user loads chart on weekend and on small
    // timeframes backend returns no data. For example, if you request data on Sunday,
    // request range will only cover Saturday, which is non-trading day.

    const result = { resolution, resolutionBack, intervalBack };

    if (resolutionBack === 'D') {
      if (intervalBack < 3) {
        result.intervalBack = 3;
      }
    }

    return result;
  }

  public dispose(): void {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
    }

    for (const key of Object.keys(this._dataSubscribers)) {
      this._unsubscribeBars(key)
        .catch(() => {});
    }
  }

  public subscribeDepth(symbolInfo: LibrarySymbolInfo, callback: DomeCallback): string {
    return '';
  }

  public unsubscribeDepth(subscriberUID: string): void {
  }

  public getServerTime(callback: ServerTimeCallback): void {
  }

  public getTimescaleMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<TimescaleMark>,
    resolution: ResolutionString): void {
  }

  public getMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString): void {
  }

  public searchSymbols(userInput: string, exchange: string, symbolType: string
    ,                  onResult: SearchSymbolsCallback): void {
  }

  private async _unsubscribeBars(subscriptionKey: string) {
    const subsId = subscriptionKey.split('_')[0];
    const cmd = new RemovePnLChartSubscription([subsId]);
    try {
      await this._shellClient.processCommand(cmd);
    } finally {
      delete this._dataSubscribers[subscriptionKey];
      this._emptyRequests.length = 0;
    }
  }

  private _onPnLSnapshotDto(filtered: PnLSnapshotDto[]) {
    filtered.forEach(dto => {
      const subsKey = `${dto.subscriptionId}_10S`;
      const subs = this._dataSubscribers[subsKey];

      if (!subs) {
        return;
      }

      const correctTime = dto.timestamp.getTime() -
        (dto.timestamp.getTimezoneOffset() * 60 * 1000);

      const pnl = subs.isAccumulated ? dto.accumulatedPnL : dto.sessionPnL;
      const trades = subs.isAccumulated ? dto.accumulatedTrades : dto.sessionTrades;

      const bar: Bar = {
        open: pnl,
        high: pnl,
        low: pnl,
        close: pnl,
        volume: trades,
        time: correctTime
      };

      subs.onBar(bar);
    });
  }

  private _makeSubscriptionKey(listenerGuid: string) {
    return `${listenerGuid}_${this._componentId}`;
  }

  private _onClearTradingDataUIMessage(msg: ClearTradingDataUIMessage) {
    for (const dataSubscribersKey of Object.keys(this._dataSubscribers)) {
      const dataSubscriber = this._dataSubscribers[dataSubscribersKey];
      dataSubscriber.onReset();
    }
    const chart = this._chartResolver();
    if (chart) {
      chart.chart().resetData();
    }
  }

  private _onShellConnectionStatusChangedUIMessage(msg: ShellConnectionStatusChangedUIMessage) {
    if (!msg.isConnected) {
      return;
    }

    console.log('Shell connection status changed');

    for (const dataSubscribersKey of Object.keys(this._dataSubscribers)) {
      const dataSubscriber = this._dataSubscribers[dataSubscribersKey];
      dataSubscriber.onReset();
    }
    const chart = this._chartResolver();
    if (chart) {
      chart.chart().resetData();
    }
  }

}
