import {
  Bar,
  DatafeedSymbolType,
  DomeCallback,
  ErrorCallback,
  Exchange,
  GetMarksCallback,
  HistoryCallback,
  HistoryDepth,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  Mark,
  OnReadyCallback,
  ResolutionBackValues,
  ResolutionString,
  ResolveCallback,
  SearchSymbolResultItem,
  SearchSymbolsCallback,
  ServerTimeCallback,
  SubscribeBarsCallback,
  TimescaleMark} from './data-feed-chart-api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IChartingLibraryWidget } from '../../webtrader/src/assets/tv/charting_library/charting_library.min';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { PriceBarMessageDto } from 'projects/shared-components/shell-communication/dtos/price-bar-message-dto.interface';
import { TradingInstrumentKind } from 'projects/shared-components/trading-instruments/trading-instrument-kind.enum';
import { PriceBarDto } from 'projects/shared-components/shell-communication/dtos/price-bar-dto.interface';
import { GetPriceBars } from 'projects/shared-components/shell-communication/operations/price-charts/get-price-bars.class';
import { SubscribePriceBars } from 'projects/shared-components/shell-communication/operations/price-charts/subscribe-price-bars.class';
import { UnsubscribePriceBars } from 'projects/shared-components/shell-communication/operations/price-charts/unsubscribe-price-bars.class';
import * as Enumerable from 'linq';
import { PriceDataFeedBackendService } from './price-data-feed-backend-service.class';
import { Logger } from '../logging/logger.interface';
import { LoggerService } from '../logging/logger-factory.service';
import { ReloadPriceChartsUIMessage } from '../ui-messages/reload-price-charts-ui-message.interface';

const RESOLUTIONS = ['1', '5', '15', '30', '60', '240', 'D', 'W'];
const INTRADAY_MULTIPLIERS = ['1', '5', '15', '30', '60', '240'];

interface DataSubscriber {
  callback: SubscribeBarsCallback;
  onReset: () => void;
  tf: string;
  ticker: string;
  subscriptionKey: string;
}

export class PriceDataFeed implements IDatafeedChartApi, IExternalDatafeed {
  constructor(
    private _componentId: string,
    private _backendService: PriceDataFeedBackendService,
    private _messageBus: MessageBusService,
    private _tradingInstrumentsService: TradingInstrumentsService,
    private _toastr: ToastrService,
    private _chartResolver: () => IChartingLibraryWidget,
    private _onDataErrorCallback: () => void,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger(`PriceDataFeed(${this._componentId})`);
  }

  private readonly _baseBarsByTicker: { [ix: string]: PriceBarDto } = {};
  private _emptyRequests = [];
  private readonly _dataSubscribers: Record<string, DataSubscriber> = {};
  private _unsubscriber = new Subject<any>();
  private _disposed: boolean;
  private _logger: Logger;

  init(): void {}

  onReady(callback: OnReadyCallback): void {
    this._messageBus
      .of<PriceBarMessageDto>('PriceBarMessageDto')
      .pipe(
         takeUntil(this._unsubscriber)
      )
      .subscribe(msg => this._onPriceBar(msg.payload));

    this._messageBus
      .of<ReloadPriceChartsUIMessage>('ReloadPriceChartsUIMessage')
      .pipe(
         takeUntil(this._unsubscriber)
      )
      .subscribe(msg => this._onReloadPriceChartsMessage(msg.payload));

    setTimeout(() => {
      const exchanges = Enumerable.from(this._tradingInstrumentsService.getAllTradingInstruments())
        .select(x => x.exchange)
        .distinct()
        .select(x => {
          return {
            name: x,
            value: x,
            desc: x
          } as Exchange;
        })
        .toArray();
      exchanges.unshift({ value: 'All', desc: 'All Exchanges', name: 'All Exchanges' });

      const symbols_types = Enumerable.from(this._tradingInstrumentsService.getAllTradingInstruments())
        .select(ti => TradingInstrumentKind[ti.kind])
        .distinct()
        .select(x => ({ name: x, value: x } as DatafeedSymbolType))
        .toArray();

      symbols_types.unshift({ name: 'All Instruments', value: 'All' });

      const configObj = {
        exchanges,
        symbols_types,
        supported_resolutions: RESOLUTIONS
      };

      callback(configObj);
    }, 0);
  }
  
  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    rangeStartDate: number,
    rangeEndDate: number,
    onResult: HistoryCallback,
    onError: ErrorCallback,
    isFirstCall: boolean
  ): Promise<void> {
    if (this._disposed) {
      onError('Feed disposed');
      this._onDataErrorCallback();
      return;
    }

    const queryCode = `${symbolInfo.ticker}_${resolution}`;

    if (this._emptyRequests.includes(queryCode)) {
      rangeStartDate = rangeStartDate - 86400 * 3;
    }

    const start = new Date(rangeStartDate * 1000);
    const end = new Date(rangeEndDate * 1000);

    const qry = new GetPriceBars(symbolInfo.ticker, resolution, start, end);

    let priceBarDtos: PriceBarDto[];
    try {
      priceBarDtos = await this._backendService.getPriceBars(qry);
    } catch (e) {
      onError('Failed to get history from server');
      this._onDataErrorCallback();
      this._logger.error(e);
      this._toastr.error(`"Get Price Bars" operation completed with errors`, 'Price Data Feed');
      return;
    }

    if (priceBarDtos.length > 0) {
      if (isFirstCall) {
        const subsKey = this._makeSubscriptionKey(`${symbolInfo.ticker}_${resolution}`);
        this._baseBarsByTicker[subsKey] = priceBarDtos[priceBarDtos.length - 1];
      }

      const bars = priceBarDtos.map(bar => {
        return {
          time: bar.date.getTime(),
          open: bar.open,
          high: bar.high,
          low: bar.low,
          close: bar.close,
          volume: bar.volume
        } as Bar;
      });

      const ix = this._emptyRequests.indexOf(queryCode);
      if (ix >= 0) {
        this._emptyRequests.splice(ix, 1);
      }

      setTimeout(() => {
        onResult(bars, { noData: false });
      }, 0);
    } else {
      let shouldFinishRequests = false;
      const ix = this._emptyRequests.indexOf(queryCode);
      if (ix < 0) {
        this._emptyRequests.push(queryCode);
      } else {
        shouldFinishRequests = true;
        this._emptyRequests.splice(ix, 1);
      }

      setTimeout(() => {
        onResult([], { noData: shouldFinishRequests });
      }, 0);
    }
  }

  resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
    setTimeout(() => {
      
      if (symbolName.startsWith('#')) {
        const result: LibrarySymbolInfo = {
          name: symbolName,
          data_status: 'streaming',
          full_name: symbolName,
          description: symbolName,
          type: 'custom_study',
          exchange: 'STUDY',
          session: '0000-0000',
          timezone: 'Etc/UTC',
          ticker: symbolName,
          intraday_multipliers: INTRADAY_MULTIPLIERS,
          supported_resolutions: RESOLUTIONS,
          minmov: 0.01,
          listed_exchange: 'STUDY',
          pricescale: 0.01,
          has_intraday: true,
          has_daily: true
        };
        onResolve(result);
      } else {
        const ti = Enumerable.from(this._tradingInstrumentsService.getAllTradingInstruments()).firstOrDefault(
          x => x.ticker === symbolName || x.displayName === symbolName
        );

        if (!ti) {
          onError('Symbol Not Found');
          this._onDataErrorCallback();
          return;
        }

        const result: LibrarySymbolInfo = {
          name: ti.displayName,
          data_status: 'streaming',
          full_name: ti.displayName,
          description: ti.description,
          type: TradingInstrumentKind[ti.kind],
          exchange: ti.exchange,
          session: '0000-0000',
          timezone: 'Etc/UTC',
          ticker: ti.ticker,
          intraday_multipliers: INTRADAY_MULTIPLIERS,
          supported_resolutions: RESOLUTIONS,
          minmov: ti.tickSize,
          listed_exchange: ti.exchange,
          pricescale: Math.pow(10, -(ti.precision + 1)),
          has_intraday: true,
          has_daily: true
        };

        onResolve(result);
      }
    }, 0);
  }

  searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
    let items = Enumerable.from(this._tradingInstrumentsService.getAllTradingInstruments());
    
    if (symbolType) {
      if (symbolType !== 'All') {
        items = items.where(x => TradingInstrumentKind[x.kind] === symbolType);
      }
    }

    if (exchange) {
      if (exchange !== 'All') {
        items = items.where(x => x.exchange === exchange);
      }
    }

    if (userInput) {
      items = items.where(x => (x.displayName + x.description).toLowerCase().indexOf(userInput.toLowerCase()) >= 0);
    }

    const searchResult = items
      .select(ti => {
        const item: SearchSymbolResultItem = {
          ticker: ti.ticker,
          description: ti.description,
          exchange: ti.exchange,
          symbol: ti.displayName,
          type: TradingInstrumentKind[ti.kind],
          full_name: ti.ticker
        };
        return item;
      })
      .toArray();

    onResult(searchResult);
  }

  async subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): Promise<void> {
    if (this._disposed) {
      return;
    }

    const subscriptionKey = this._makeSubscriptionKey(listenerGuid);
    
    const subscriberObj: DataSubscriber = {
      callback: onTick,
      tf: resolution,
      ticker: symbolInfo.ticker,
      onReset: onResetCacheNeededCallback,
      subscriptionKey
    };

    this._logger.info(`Subscribing: ${subscriptionKey}`);

    const baseBar = this._baseBarsByTicker[subscriptionKey];

    if (!baseBar) {
      this._toastr.error('Cannot subscribe for real time chart updates');
      return;
    }

    const cmd = new SubscribePriceBars(symbolInfo.ticker, resolution, subscriptionKey, baseBar);

    try {
      await this._backendService.subscribePriceBars(cmd);
      this._dataSubscribers[subscriptionKey] = subscriberObj;
      this._logger.debug(`Added data subscriber: ${subscriptionKey}`);
    } catch (e) {
      this._logger.error(e);
      this._toastr.error('"Subscribe Price Bars" operation completed with errors', 'Price Data Feed');
    }
  }

  async unsubscribeBars(listenerGuid: string): Promise<void> {
    const key = this._makeSubscriptionKey(listenerGuid);
    if (this._disposed) {
      this._logger.info(`"Unsubscribe Price Bars" opertaion will not proceed for '${key}', because feed has been already disposed`);
      return;
    }

    try {
      await this._unsubscribeBars(key);
    } catch (e) {
      this._logger.error(e);
      this._toastr.error('"Unsubscribe Price Bars" operation completed with errors', 'Price Data Feed');
    }
  }

  calculateHistoryDepth(
    resolution: ResolutionString,
    resolutionBack: ResolutionBackValues,
    intervalBack: number
  ): HistoryDepth | undefined {
    // if depth is less than 3 days, we set it explicitly to 3 days.
    // this is required for cases, when user loads chart on weekend and on small
    // timeframes backend returns no data. For example, if you request data on Sunday,
    // request range will only cover Saturday, which is non-trading day.

    const result = { resolution, resolutionBack, intervalBack };

    if (resolutionBack === 'D') {
      if (intervalBack < 3) {
        result.intervalBack = 3;
      }
    }

    return result;
  }

  dispose(): void {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
    }

    for (const key of Object.keys(this._dataSubscribers)) {
      this._unsubscribeBars(key).catch(() => {});
    }

    this._disposed = true;
  }

  private async _unsubscribeBars(subscriptionKey: string) {
    this._logger.debug(`Un-subscribing: ${subscriptionKey}`);

    delete this._baseBarsByTicker[subscriptionKey];

    const subscriberObj = this._dataSubscribers[subscriptionKey];

    if (!subscriberObj) {
      this._logger.warn(`Cannot unsubscribe: ${subscriptionKey}. Subscriber object not registered`);
      return;
    }

    const cmd = new UnsubscribePriceBars(subscriberObj.ticker, subscriberObj.tf, subscriptionKey);

    try {
      await this._backendService.unsubscribePriceBars(cmd);
    } finally {
      delete this._dataSubscribers[subscriptionKey];
      this._logger.debug(`Removed data subscriber: ${subscriptionKey}`);
      this._emptyRequests.length = 0;
    }
  }

  private _onPriceBar(value: PriceBarMessageDto) {
    const key = value.subscriptionKey;
    if (key in this._dataSubscribers) {
      const subscriberObj = this._dataSubscribers[key];

      // const correctTime = value.priceBar.date.getTime() - value.priceBar.date.getTimezoneOffset() * 60 * 1000;

      const bar: Bar = {
        time: value.priceBar.date.getTime(),
        open: value.priceBar.open,
        high: value.priceBar.high,
        low: value.priceBar.low,
        close: value.priceBar.close,
        volume: value.priceBar.volume
      };

      subscriberObj.callback(bar);
    }
  }

  private _onReloadPriceChartsMessage(msg: ReloadPriceChartsUIMessage): void {
    this._logger.info('Received "ReloadPriceChartsUIMessage" message', msg);
    this._reloadChartData();
  }

  private _reloadChartData(): void {
    this._logger.info(`Chart will be reloaded: Id=${this._componentId}`);
    
    Object.keys(this._dataSubscribers).forEach(key => {
      const subscriber = this._dataSubscribers[key];
      if (subscriber) {
        subscriber.onReset();
      }
    });

    const chart = this._chartResolver();
    if (chart) {
      chart.chart().resetData();
    }
  }

  private _makeSubscriptionKey(listenerGuid: string) {
    return `${listenerGuid}_${this._componentId}`;
  }

  subscribeDepth(symbolInfo: LibrarySymbolInfo, callback: DomeCallback): string {
    return '';
  }

  unsubscribeDepth(subscriberUID: string): void {}
  getServerTime(callback: ServerTimeCallback): void {}
  getTimescaleMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<TimescaleMark>,
    resolution: ResolutionString
  ): void {}
  getMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString
  ): void {}
}
