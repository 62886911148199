import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { DebitSpreadParameters } from 'projects/shared-components/cashflow-strategies/debit-spread/DebitSpreadParameters';
import { DebitSpreadCashFlowStrategyComponent } from 'projects/shared-components/cashflow-strategies/debit-spread/debit-spread-cashflow-strategy.component';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, GetDebitSpreadSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetDebitSpreadSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { DebitSpreadSideboxComponent } from './legs/debit-spread-sidebox.component';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
   selector: 'ets-automation-cp-body-debit-spread-section',
   templateUrl: './debit-spread-section.component.html',
   styleUrls: ['./debit-spread-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebitSpreadSectionComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();

   //
   header = 'Debit Spread';

   //
   private _combo: ComboDto;
   public get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   @DetectSetterChanges()
   public set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   private _strategy: CashFlowStrategy;
   public get strategy(): CashFlowStrategy {
      return this._strategy;
   }
   @Input()
   @DetectSetterChanges()
   public set strategy(value: CashFlowStrategy) {
      this._strategy = value;
   }

   //
   private _automationCpMode: AutomationCpMode;
   public get automationCpMode(): AutomationCpMode {
      return this._automationCpMode;
   }
   @Input()
   @DetectSetterChanges()
   public set automationCpMode(value: AutomationCpMode) {
      this._automationCpMode = value;
   }

   //
   @ViewChild('debitSpreadParameters', { static: false })
   debitSpreadParametersComponent: DebitSpreadCashFlowStrategyComponent;

   //
   @ViewChild('firstSidebox', { static: false })
   firstSidebox: DebitSpreadSideboxComponent;

   //
   @ViewChild('secondSidebox', { static: false })
   secondSidebox: DebitSpreadSideboxComponent;

   //
   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   get isMultiDirection() {
      return this.strategy === 'Calls & Puts';
   }

   //
   ngOnInit(): void {
      this._cpMessageBus.when('AdjustmentStrategyCreated')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onComboSelected());

      this._cpMessageBus.when('AdjustmentStrategyRemoved')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.reset());

   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   getParameters(): DebitSpreadParameters[] {

      if (isVoid(this.debitSpreadParametersComponent)) {
         return undefined;
      }

      const params = this.debitSpreadParametersComponent.getParameters();

      return params;

   }

   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {
      
      this.reset();
      
      if (isVoid(this.combo)) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetDebitSpreadSectionDataModel(
            this.combo.comboId,
            this.strategy
         );

         const reply = await this._shellClient.processQuery<GetDebitSpreadSectionDataModelReply>(qry);

         this.firstSidebox.setData(reply.comboGroupId, reply.legs);

         this.debitSpreadParametersComponent.setParameters(reply);

         if (this.secondSidebox) {
            this.secondSidebox.setData(reply.comboGroupId2, reply.legs2);
         }

      } catch (e) {

         this._toastr.error(
            '"Load Debit Spread Data" operation completed with errors',
            'Debit Spread'
         );

      } finally {

         this.isLoading = false;

      }

   }

   private reset() {

      if (!this.combo) {
         this.firstSidebox.reset();
         
         if (this.secondSidebox) {
            this.secondSidebox.reset();
         }
      }

      this.debitSpreadParametersComponent.reset();
   }
}
