import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {HedgePositionsService} from "../../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {ApgPortfolio} from "../model/ApgPortfolio";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {DetectMethodChanges, getPriceClass, isVoid} from "../../utils";
import {MessageBusService} from "../../message-bus.service";
import {Subscription} from "rxjs";
import {
    HedgePriceChangedEventArgs,
    HedgesPricingService
} from "../../package-comparison/services/hedges-pricing.service";
import {ApgDataService} from "../services/apg-data.service";

@Component({
    selector: 'ets-apg-hedges-total',
    templateUrl: 'apg-hedges-total.component.html',
    styleUrls: ['apg-hedges-total.component.scss', '../adjustment-grid-common-style.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HedgePositionsService]
})

export class ApgHedgesTotalComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService,
        private readonly _apgDataService: ApgDataService,
    ) {
        const svc = new HedgesPricingService(
            this._hedgePositionsService,
            this._lastQuoteCache,
            this._messageBus,
            this._apgDataService
        );
        this._hedgePricingService = svc;
    }

    private _subscriptions: Subscription[] = [];

    private readonly _hedgePricingService: HedgesPricingService;
    private _selectedPortfolio : ApgPortfolio;

    callsPrice: number;
    putsPrice: number;
    totalPrice: number;

    getPriceClass = getPriceClass;

    ngOnInit() {
        this._subscriptions.push(
            this._hedgePricingService.hedgePriceChanged$
                .subscribe(
                (msg : HedgePriceChangedEventArgs) => this.onHedgePricesChanged(msg)
            )
        );
        this._hedgePricingService.init();
    }

    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }

    async onPortfolioSelected(portfolio: ApgPortfolio) {

        this._selectedPortfolio = portfolio;

        await this._hedgePricingService.subscribe(portfolio);
    }

    @DetectMethodChanges()
    private onHedgePricesChanged(msg: HedgePriceChangedEventArgs) {
        if (isVoid(this._selectedPortfolio)) {
            return;
        }

        if (msg.delta !== 0) {
            return;
        }

        const portfolioKey : string = `${this._selectedPortfolio.userId}@${this._selectedPortfolio.id}`;

        if (portfolioKey !== msg.portfolioKey) {
            return;
        }

        this.callsPrice = msg.calls;
        this.putsPrice = msg.puts;
        this.totalPrice = msg.total;
    }
}