import {NgModule} from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';
import {LogoutComponent} from './logout.component';

@NgModule({
  imports: [
    DevExtremeModule
  ],
  exports: [],
  declarations: [LogoutComponent],
  providers: [],
})
export class LogoutModule {
}
