import {Injectable} from '@angular/core';
import {SettingsStorageService} from '../settings-storage-service.service';
import {EtsConstants} from '../ets-constants.const';
import {IAdjustmentPricingGridSettings} from './model/AdjustmentPricingGridSettings';
import {isNullOrUndefined} from 'util';
import {isVoid} from '../utils';
import {UserSettingsService} from "../user-settings.service";

export const AppSettingsUpdatedMessageTopic = 'AppSettingsUpdated';

@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsService {
    constructor(private readonly _userSettings: UserSettingsService) {
    }

    //#region Default Timezone

    private _defaultTimezone: string;
    get defaultTimezone(): string {
        return this._defaultTimezone;
    }

    set defaultTimezone(value: string) {
        if (value === this._defaultTimezone) {
            return;
        }

        if (value) {
            this._userSettings.setValue(
                EtsConstants.storageKeys.appSettings.defaultTimezone,
                value
            );
        } else {
            this._userSettings
                .deleteValue(EtsConstants.storageKeys.appSettings.defaultTimezone);
        }

        this._defaultTimezone = value;
    }

    //#endregion


    //#region Adjustment Pricing Grid

    private _adjustmentPricingGridSettings: IAdjustmentPricingGridSettings

    get adjustmentPricingGrid(): IAdjustmentPricingGridSettings {
        return JSON.parse(JSON.stringify(this._adjustmentPricingGridSettings || {
            showChecklistDrafts: false
        }));
    }

    set adjustmentPricingGrid(value: IAdjustmentPricingGridSettings) {
        this._adjustmentPricingGridSettings = {
            defaultStrategy: value.defaultStrategy,
            defaultUnderlying: value.defaultUnderlying,
            matchExpirations: value.matchExpirations,
            prefixTemplates: value.prefixTemplates,
            linkStrikes: value.linkStrikes,
            expirationSmartMode: value.expirationSmartMode,
            showChecklistDrafts: value.showChecklistDrafts,
            useMarginEfficientAdjustment: value.useMarginEfficientAdjustment,
            useCharlesSchwabFix: value.useCharlesSchwabFix,
            atmWarningMode: value.atmWarningMode
        };

        this._userSettings
            .setValue(
                EtsConstants.storageKeys.appSettings.adjustmentPricingGrid,
                this._adjustmentPricingGridSettings
            );
    }

    //#endregion

    //
    init(): Promise<void> {
        this.initDefaultTimezone();

        this.initAdjustmentPricingGridSettings();

        return Promise.resolve();
    }

    //
    private initDefaultTimezone() {
        this._defaultTimezone =
            this._userSettings.getValue(EtsConstants.storageKeys.appSettings.defaultTimezone);
    }

    //
    private initAdjustmentPricingGridSettings() {

        const restoredApgSettings = this._userSettings
                .getValue<IAdjustmentPricingGridSettings>(EtsConstants.storageKeys.appSettings.adjustmentPricingGrid)
            || {};

        restoredApgSettings.showChecklistDrafts = restoredApgSettings.showChecklistDrafts || false;

        restoredApgSettings.defaultStrategy
            = restoredApgSettings.defaultStrategy || 'Calls & Puts';

        restoredApgSettings.defaultUnderlying
            = restoredApgSettings.defaultUnderlying || 'SPY';

        restoredApgSettings.prefixTemplates = restoredApgSettings.prefixTemplates || false;

        if (isNullOrUndefined(restoredApgSettings.matchExpirations)) {
            restoredApgSettings.matchExpirations = true;
        }

        if (isNullOrUndefined(restoredApgSettings.linkStrikes)) {
            restoredApgSettings.linkStrikes = true;
        }

        if (isVoid(restoredApgSettings.expirationSmartMode)) {
            restoredApgSettings.expirationSmartMode = {
                spread: {isEnabled: true, back: 5, forth: 20},
                secondSpread: {isEnabled: true, back: 5, forth: 20},
                protectiveOption: {isEnabled: true, back: 1, forth: 3},
                secondProtectiveOption: {isEnabled: true, back: 1, forth: 3}
            };
        }

        restoredApgSettings.useMarginEfficientAdjustment =
            restoredApgSettings.useMarginEfficientAdjustment || false;

        restoredApgSettings.useCharlesSchwabFix =
            restoredApgSettings.useCharlesSchwabFix || false;

        restoredApgSettings.atmWarningMode = restoredApgSettings.atmWarningMode || 'Yellow Background';

        this.adjustmentPricingGrid = restoredApgSettings;
    }
}
