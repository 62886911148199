import { Injectable } from '@angular/core';
import { EtsConstants } from '../ets-constants.const';
import { environment } from '../environments/environment';
import { AccessControlService } from '../access-control-service.class';

const DASHBOARD = EtsConstants.companyServices.etsDashboardApplicationId;
const WEBTRADER = EtsConstants.companyServices.etsWebTraderApplicationId;


@Injectable()
export class StrategyIssuesSecurityContextService {
  constructor(private readonly _accessControl: AccessControlService) {}

  ackIssue(mode: 'popup' | 'panel'): boolean {
    if (environment.runtimeAppId === DASHBOARD) {
      if (mode === 'popup') {
        return this._accessControl.isSecureElementAvailable('36b4365b-4b1f-4bef-9626-6e79e09ee956');
      }

      if (mode === 'panel') {
        return this._accessControl.isSecureElementAvailable('91de37bd-82af-4e32-a232-a40fc2ef65ff');        
      }
    }
    if (environment.runtimeAppId === WEBTRADER) {
      if (mode === 'popup') {
        return this._accessControl.isSecureElementAvailable('e34d2080-04be-4460-ade8-cf2009f4b3db');
      }

      if (mode === 'panel') {
        return this._accessControl.isSecureElementAvailable('f296b60f-90b3-4d1e-9064-abee7069a3ec');
      }
    }
    return false;
  }
}
