import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { getSystemDetailsCommonGridModel } from './system-details-common-grid-model';
import { getSystemDetailsSpecificGridModel } from './system-details-specific-grid-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { 
  TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { AlgoParameterModel } from 'projects/shared-components/algo/algo-parameter-model.class';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { LoggerService } from '../logging/logger-factory.service';
import { FlagshipBetStyle, FlagshipBetSequence, FlagshipAlgorithm } from '../edit-strategy-dialog/parameters-controls/flagships/flagship-algo-parameters-control';
import { MarketSide } from '../trading-model/market-side.enum';
import { TradeTheLineChartCloseMode, TradeTheLineMode } from '../edit-strategy-dialog/parameters-controls/trend/trade-the-line/tradetheline-algo-parameters-control';
import { TrendLimitMode } from '../edit-strategy-dialog/parameters-controls/trend/trend-limit/trendlimit-algo-parameters-control';

@Component({
  selector: 'ets-system-details',
  templateUrl: 'system-details.component.html',
  styleUrls: ['system-details.component.scss']
})

export class SystemDetailsComponent implements OnInit, OnDestroy {

  constructor(
    private readonly _displayNameService: TradingInstrumentDisplayNameService,
    private readonly _algoMetadataService: AlgoMetadataService,
    private readonly _messageBus: MessageBusService,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger('SystemDetailsComponent');
  }

  private readonly _logger: Logger;
  private _specificGrid: GridReadyEvent;
  private _commonGrid: GridReadyEvent;
  private _unsubscriber;

  @Input() system: StrategyModel;
  @Output() heightCalculated = new EventEmitter();
  commonParametersGridModel: GridOptions;
  specificParametersGridModel: GridOptions;

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  ngOnInit(): void {
    this._unsubscriber = new Subject<any>();
    this._messageBus.of<any>('ParameterHighlighted')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(x => this._onParameterHighlighted(x));
    this.commonParametersGridModel = getSystemDetailsCommonGridModel.bind(this)();
    this.specificParametersGridModel = getSystemDetailsSpecificGridModel.bind(this)();
  }

  onSystemDetailsSpecificGridReady(args: GridReadyEvent): void {
    this._specificGrid = args;
    const rowData = this.setupSpecificGrid();
    if (!!rowData) {
      this.heightCalculated.emit(rowData.length + 16);
    }
  }

  setupSpecificGrid(): any[] {
    const rowData = [];

    if (!this._specificGrid) {
      return;
    }

    const strategy = this.system;
    if (!strategy) {
      this._specificGrid.api.setRowData([]);
      return;
    }

    const algoModel = this._algoMetadataService.getAlgoModel(strategy.algoId);

    if (!algoModel) {
      this._logger.info(`No algo model found for algo: ${strategy.algo}`);
      return;
    }

    for (const key of Object.keys(strategy.parameters)) {
      const param: AlgoParameterModel = algoModel.parameters.find(x => x.parameterKey.toLowerCase() === key.toLowerCase());

      if (!param || param.isHidden) {
        this._logger.warn(`parameter not found: ${key}`);
        continue;
      }

      const value = strategy.parameters[key];

      const dataItem = { parameter: param.displayName, value: null };

      if (value) {
        if (param.isSymbol) {
          const dnTicker = this._displayNameService.getDisplayNameForTicker(value);
          dataItem.value = dnTicker;
        } else if (['true', 'false'].includes(value.toLowerCase())) {
          dataItem.value = value === 'true' ? 'Yes' : 'No';
        } else if (param.isEnum) {
          const eVal = this._getEnumValue(param.displayName, value);
          dataItem.value = eVal;
        } else {
          dataItem.value = value;
        }
      }
      rowData.push(dataItem);
    }

    this._specificGrid.api.setRowData(rowData);
    return rowData;
  }

  setupCommonGrid(): void {
    const strategy = this.system;

    if (!this._commonGrid) {
      return;
    }

    if (!strategy) {
      this._commonGrid.api.setRowData([]);
      return;
    }

    const rowData = [
      {
        parameter: 'Display Name',
        value: strategy.displayName
      },
      {
        parameter: 'Units',
        value: strategy.unitsAllocated
      },
      {
        parameter: 'Autostopping',
        value: strategy.autostopping ? 'Yes' : 'No'
      },
      {
        parameter: 'Country',
        value: strategy.scheduleParameters ? strategy.scheduleParameters.country : null
      },
      {
        parameter: 'Timezone ID',
        value: strategy.scheduleParameters ? strategy.scheduleParameters.timeZone : null
      },
      {
        parameter: 'Start Time',
        value: strategy.scheduleParameters ? strategy.scheduleParameters.startTime : null
      },
      {
        parameter: 'Stop Time',
        value: strategy.scheduleParameters ? strategy.scheduleParameters.stopTime : null
      },
      {
        parameter: 'Exit Time',
        value: strategy.scheduleParameters ? strategy.scheduleParameters.exitTime : null
      },
      {
        parameter: 'Trade / Sess. / Acc.  NPO',
        value: strategy.tradingObjectives ? `${strategy.tradingObjectives.tradeNpo || ''} / ${strategy.tradingObjectives.sessionNpo || ''} / ${strategy.tradingObjectives.accumulatedNpo || ''}` : ''
      },
      {
        parameter: 'Trade / Sess. / Acc. $-TS',
        value: strategy.tradingObjectives ? `${strategy.tradingObjectives.tradeDollarTS || ''} / ${strategy.tradingObjectives.sessionDollarTS || ''} / ${strategy.tradingObjectives.accumulatedDollarTS || ''}` : ''
      },
      {
        parameter: 'Trade / Sess. / Acc. %-TS',
        value: strategy.tradingObjectives ?  `${strategy.tradingObjectives.tradePercentTS || ''} / ${strategy.tradingObjectives.sessionPercentTS || ''} / ${strategy.tradingObjectives.accumulatedPercentTS || ''}` : ''
      },
      {
        parameter: 'Trade / Sess. / Acc. SL',
        value: strategy.tradingObjectives ? `${strategy.tradingObjectives.tradeStopLoss || ''} / ${strategy.tradingObjectives.sessionStopLoss || ''} / ${strategy.tradingObjectives.accumulatedStopLoss || ''}` : ''
      },
      {
        parameter: 'Tags',
        value: strategy.tags
      },
      {
        parameter: 'Terminal',
        value: strategy.terminalCode
      },
      {
        parameter: 'Algo',
        value: strategy.algo
      }
    ];

    this._commonGrid.api.setRowData(rowData);
  }

  onSystemDetailsCommonGridReady(args: GridReadyEvent): void {
    this._commonGrid = args;
    this.setupCommonGrid();
  }

  onSelectionChanged(args: SelectionChangedEvent): void {
    const selectedRows = args.api.getSelectedRows();
    if (selectedRows.length === 1) {
      this._messageBus.publish({
        topic: 'ParameterHighlighted',
        payload: selectedRows[0]
      });
    }
  }

  private _onParameterHighlighted(x: any): void {
    const common = this._commonGrid.api.getRowNode(x.parameter);
    if (common) {
      common.setSelected(true, true);
    } else {
      const specific = this._specificGrid.api.getRowNode(x.parameter);
      if (specific) {
        specific.setSelected(true, true);
      }
    }
  }

  private _getEnumValue(paramName: string, value: any) {
    if (paramName === 'Bet Style') {
      return FlagshipBetStyle[value];
    }
    if (paramName === 'Bet Sequence') {
      return FlagshipBetSequence[value];
    }
    if (paramName === 'Outright Side') {
      return MarketSide[value];
    } 
    if (paramName === 'OTR: Alternative Algo') {
      return FlagshipAlgorithm[value];
    }
    if (paramName === 'Chart Close Mode') {
      return TradeTheLineChartCloseMode[value];
    }
    if (paramName === 'Line Mode') {
      return TradeTheLineMode[value];
    }
    if (paramName === 'Trend Mode') {
      return TrendLimitMode[value];
    }
    return value;
  }
}
