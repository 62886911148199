<div [ngClass]="{'ets-hidden': !isActive}" class="container" #container>
   <div class="main_box">
      <div class="header">
         <div class="selectors">
            <div class="selector terminal">
               <dx-select-box [items]="this.terminals" 
                              displayExpr="displayName" 
                              [(value)]="this.selectedTerminal"
                              [showClearButton]="true"
                              placeholder="Terminal"
                              (onValueChanged)="this.onTerminalChanged($event)">
               </dx-select-box>
            </div>
            <div class="selector portfolio">
               <dx-select-box [items]="this.portfolios" 
                              displayExpr="portfolioName" 
                              [(value)]="this.selectedPortfolio"
                              [showClearButton]="true"
                              placeholder="Portfolio"
                              (onValueChanged)="this.onPortfolioChanged($event)">
               </dx-select-box>
            </div>
            <div class="selector combo">
               <dx-select-box [items]="this.combos" 
                              displayExpr="comboName" 
                              [(value)]="this.selectedCombo"
                              [showClearButton]="true"
                              placeholder="Combo"
                              [selectedItem]="this.selectedCombo"
                              (onValueChanged)="this.onComboChanged($event)">
               </dx-select-box>
            </div>
            <div class="selector combo_group">
               <dx-select-box [items]="this.comboGroups" 
                              displayExpr="comboGroupName" 
                              [(value)]="this.selectedComboGroup"
                              [showClearButton]="true"
                              placeholder="Combo Group"
                              [selectedItem]="this.selectedComboGroup"
                              (onValueChanged)="this.onComboGroupChanged($event)">
               </dx-select-box>
            </div>
            <div class="selector strategy">
               <!-- <p>Strategy:</p> -->
               <dx-select-box [items]="strategies" 
                              displayExpr="displayName" 
                              [value]="selectedStrategy"
                              (onValueChanged)="this.onSelectedStrategyChanged($event)"
                              [showClearButton]="true" 
                              placeholder="Strategy"
                              [selectedItem]="selectedStrategy">
               </dx-select-box>
            </div>
            <div class="selector inner_strategy">
               <!-- <p>Inner Strategy:</p> -->
               <dx-select-box [items]="selectedStrategy?.dispositionStrategies" 
                              displayExpr="displayName"
                              [value]="selectedPortfolioStrategy" 
                              (onValueChanged)="this.onSelectedPortfolioStrategyChanged($event)"
                              [disabled]="!isPortfolioStrategySelectorAvailable"
                              [showClearButton]="true" 
                              placeholder="Inner Strategy"
                              [selectedItem]="selectedPortfolioStrategy">
               </dx-select-box>
            </div>
            <div class="selector sizing_parameters">
               <dx-button  [text]="'Sizing Parameters'"
                           (onClick)="isDetailsWindowVisible = true"
                           [disabled]="!selectedStrategy && !selectedPortfolioStrategy">
               </dx-button>
            </div>
         </div>
   
      </div>
      <div class="content">
         <table>
            <thead>
               <tr>
                  <th scope="col">Acutal Units</th>
                  <th scope="col">Reference (<em>{{ethalonMode}}</em>)</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td class="units">
                     <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="unitsGridModel"></ag-grid-angular>
                  </td>
                  <td class="etalon">
                     <ag-grid-angular class="ag-theme-balham-dark" [gridOptions]="etalonGridModel"></ag-grid-angular>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>

   <dx-load-panel [position]="{my: 'center', at: 'center', of: container}"
                  [visible]="this.isLoading">
   </dx-load-panel>

</div>

<dx-popup width="550" height="350" [showTitle]="true" title='Sizing Parameters' [resizeEnabled]="true"
   [dragEnabled]="true" (onHidden)="isDetailsWindowVisible = false" [visible]="isDetailsWindowVisible">
   <div *dxTemplate="let data of 'content'" id="session_message_details">
      <div id="popup_container">
         <div>
            <style>
               .bold_label {
                  font-weight: bold;
                  font-size: larger;
                  text-decoration: underline;
               }
            </style>
            <p><span class="bold_label">Sizing Strategy</span></p>
            <p>
               {{sizingStrategySettingsString}}
            </p>
         </div>
         <br>
         <div>
            <p><span class="bold_label">Switch To</span></p>
            <p>
               {{switchToSettingsString}}
            </p>
         </div>
      </div>
   </div>
</dx-popup>