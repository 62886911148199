import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultLoadingOverlayTemplate,
   getMasterSymbolColumn
} from 'projects/shared-components/ag-grid-contrib';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { OrderStatus } from 'projects/shared-components/trading-model/order-status.enum';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { environment } from 'projects/shared-components/environments/environment';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { AgGridColumn } from 'ag-grid-angular';
import {
   SimpleHeaderGroupRowInnerRenderer
} from 'projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { OrdersAndTradesBaseComponent } from './orders-and-trades-base.component';
import { isCashSettledOptionTicker } from 'projects/shared-components/utils';


export function getSnapshotsGridOptions(this: OrdersAndTradesBaseComponent): GridOptions {

   const gridColumns: Partial<AgGridColumn>[] = setupGridColumns(this);
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: gridColumns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      tooltipShowDelay: 1000,

      frameworkComponents: {
         simpleHeaderRenderer: SimpleHeaderGroupRowInnerRenderer
      },

      groupUseEntireRow: true,

      groupRowRendererParams: {
         innerRenderer: 'simpleHeaderRenderer',
         suppressCount: true
      },

      rowGroupPanelShow: isDashboard ? 'always' : null,

      onGridReady: args => this.onSnapshotsGridReady(args),

      getRowNodeId: (rowData: OrderStateSnapshotDto) => rowData.orderStateSnapshotId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {

         const menu = [];

         menu.push(
            {
               name: 'Load More ...',
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadMoreSnapshots(50)
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreSnapshots(100)
                  },
                  {
                     name: '500',
                     action: () => this.loadMoreSnapshots(500)
                  },
                  {
                     name: '1000',
                     action: () => this.loadMoreSnapshots(1000)
                  }
               ]
            },
            'separator'
         );

         if (this.linkedContext !== 'Portfolios') {
            menu.push(
               {
                  name: 'Is Linked',
                  action: () => this.changeIsLinked(),
                  checked: this.isLinked
               },
               'separator',
            );   
         }

         menu.push(
            {
               name: 'Show Comment Popup',
               action: () => {
                  this.selectedOrderOrSnapshot = params.node.data;
                  this.isCommentPopupVisible = true;
               },
               disabled: !params.node
            },
            'separator',
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            'copy',
            'export'
         );

         if (this.linkedContext === 'Portfolios') {
            menu.push(
               'separator',
               {
                  name: 'Bucket Context',
                  icon: '<i class="fas fa-info-circle"></i>',
                  action: () => (this as any).showBucketContextHint()
               }
            );
         }
      
         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()

   } as GridOptions;
}


function setupGridColumns(comp: OrdersAndTradesBaseComponent): Partial<AgGridColumn>[] {
   
   const cols: Partial<AgGridColumn>[] = [];
   
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   if (isDashboard) {
      cols.push(
         {
            headerName: 'Client',
            field: 'clientName',
            enableRowGroup: true,
            filter: true
         },
         {
            headerName: 'Shell',
            field: 'shellName',
            enableRowGroup: true,
            filter: true,
         });
   }


   const symbolColumn = getMasterSymbolColumn('tradingInstrumentCode', comp.messageBus, comp.timestampsService, comp.unsubscriber, () => comp.tradesGridApi);
   
   cols.push(symbolColumn);

   const expirationStyle: ColDef = {
      headerName: 'Exp. Style',
      field: 'expirationStyle'
   };
   cols.push(expirationStyle);

   cols.push({
      headerName: 'Type',
      field: 'orderType',
      valueFormatter: (params: ValueFormatterParams) => {
         return OrderType[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'Status',
      field: 'status',
      valueFormatter: (params: ValueFormatterParams) => {
         
         const snapshot = params.data as OrderStateSnapshotDto;

         let status = OrderStatus[params.value];

         if (snapshot.masterOrder) {
            status += ` (${snapshot.masterOrder})`;
         }

         return status;
      },
      filter: true
   });

   cols.push({
      headerName: 'Qty',
      field: 'qty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Leaves Qty',
      field: 'leavesQty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Limit Px',
      field: 'limitPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Stop Px',
      field: 'stopPrice',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Last Px',
      field: 'lastPx',
      valueFormatter: defaultQuoteCellFormatter
   });

   cols.push({
      headerName: 'Side',
      field: 'side',
      valueFormatter: (params: ValueFormatterParams) => {
         return MarketSide[params.value];
      },
      filter: true
   });

   cols.push({
      headerName: 'Last Qty',
      field: 'lastQty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Filled',
      field: 'filledQty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Avg. Px',
      field: 'avgFillPx',
      valueFormatter: defaultNumberCellFormatter
   });

   if (comp.linkedContext === 'Portfolios') {
      cols.push({
         headerName: 'Portfolio',
         field: 'portfolioName',
         tooltipField: 'portfolioName',
      });
   
      cols.push({
         headerName: 'Combo',
         field: 'comboName',
         tooltipField: 'comboName',
      });
   
      cols.push({
         headerName: 'ComboGroup',
         field: 'comboGroupName',
         tooltipField: 'comboGroupName'
      });
   }

   cols.push({
      headerName: 'Account',
      field: 'accountCode',
      tooltipField: 'accountCode',
      filter: true
   });

   cols.push({
      headerName: 'Terminal',
      field: 'terminalCode',
      tooltipField: 'terminalCode',
      filter: true
   });

   cols.push({
      headerName: 'Comment',
      field: 'comment',
      tooltipField: 'comment',
      filter: 'agTextColumnFilter'
   });

   cols.push({
      headerName: 'Timestamp',
      field: 'lastModifiedDate',
      valueFormatter: (params: ValueFormatterParams) => {
         const snapshot: OrderStateSnapshotDto = params.data;
         const frmtTime = comp.timestampsService.getFormattedDateTimeForStrategy(snapshot.strategyId, snapshot.lastModifiedDate);
         return snapshot.lastModifiedDate
            ? frmtTime
            : '';
      },
   });

   cols.push({ headerName: 'Order Id', field: 'orderId', filter: 'agTextColumnFilter' });

   return cols;
}
