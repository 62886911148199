import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { StrategyIssuesComponent } from './strategy-issues.component';
import { StrategyIssuesBrowserComponent } from './strategy-issues-browser.component';
import { StrategyIssuesPanelComponent } from './strategy-issues-panel.component';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      AgGridModule.withComponents([])
   ],
   exports: [
      StrategyIssuesPanelComponent,
      StrategyIssuesComponent,
      StrategyIssuesBrowserComponent
   ],
   declarations: [
      StrategyIssuesPanelComponent,
      StrategyIssuesComponent,
      StrategyIssuesBrowserComponent
   ],
   providers: []
})
export class StrategyIssuesModule { }
