import {NgModule} from '@angular/core';
import {WebtraderInitializerComponent} from './wt-initializer.component';
import {CommonModule} from '@angular/common';
import {DevExtremeModule} from 'projects/shared-components/devextreme.module';
import {ResourceEditorModule} from "../../../../shared-components/resource-editor/resource-editor.module";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule
    ],
    declarations: [
        WebtraderInitializerComponent
    ],
    exports: [
        WebtraderInitializerComponent
    ]
})
export class WebtraderInitializerModule {
}
