import { Component, Input, OnInit } from '@angular/core';
import { Actions, StrategyCommanderEditorParameters } from '../default-editor-parameters';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';

@Component({
  selector: 'start-strategies-by-family',
  template: `
    <div style="margin-top: 30px; margin-bottom: 10px">Select Family:</div>
    <dx-select-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      [items]="['Limit', 'BullBear']"
      [(value)]="parameters.family"
    ></dx-select-box>
  `
})

export class StartStrategiesByFamilyComponent implements OnInit {
  constructor() {
  }

  @Input()
  parameters: any;

  ngOnInit() {
  }

  public static fillParameters(parameters: string, paramsObj: StrategyCommanderEditorParameters, tradingInstrumentService: TradingInstrumentsService) {
    paramsObj.actions.startStrategiesByFamily = JSON.parse(parameters);
  }

  public static getParameters(paramsObj: Actions.StartStrategiesByFamily): string {
    return JSON.stringify(paramsObj);
  }

  static checkParameters(parameters: Actions.StartStrategiesByFamily) {
    if (!parameters.family) {
      return false;
    }
    return true;
  }
}