<div class="order-type-settings">
   
   <fieldset>      
      
      <legend>Order Settings</legend>

      <div class="field">

         <div class="label">Order Type</div>
         
         <div class="value">
            <dx-select-box 
               [items]="this.orderTypes"
               [(value)]="this.orderType"
               >
            </dx-select-box>   
         </div>

      </div>

      <div class="field" *ngIf="this.isLimitOrder">
         
         <div class="label">Limit Price</div>
         
         <div class="value">
            <dx-select-box 
               [items]="this.autoLimitPrices"
               [(value)]="this.autoLimitPrice"
               >
            </dx-select-box>
         </div>

      </div>

      <div class="field" *ngIf="this.isLimitOrder">

         <fieldset class="convert-to-market">
            <legend>
               <dx-check-box  
                  text="Convert To Market" 
                  [(value)]="this.convertToMarket"
                  >
               </dx-check-box>
            </legend>
            
            <div *ngIf="this.convertToMarket">
               <ets-convert-to-market  
                  [hideCalendar]="true"                        
                  [settings]="this.convertToMarketSettings"
                  >
               </ets-convert-to-market>
            </div>
         </fieldset>

      </div>
      
   </fieldset>
</div>