import { formatNumber } from '@angular/common';
import { CellClassParams, ColDef, FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, GridReadyEvent, GridSizeChangedEvent, RowClickedEvent, RowDoubleClickedEvent, RowNode, RowSelectedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { isNullOrUndefined } from 'util';
import { AG_DEFAULT_ROW_HEIGHT, centeredColumnDef, defaultMoneyCellDefinition, defaultPriceCellFormatter } from '../ag-grid-contrib';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { ComboGroupDto, PortfolioItemDto } from '../shell-communication/shell-dto-protocol';
import { PortfolioItemsGroupRowRendererComponent } from './portfolio-items/portfolio-items-archived-group-row';
import { PortfoliosComponent } from './portfolios.component';

const IS_DASHBOARD = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;


export function getComboGroupsGridOptions(this: PortfoliosComponent): GridOptions {
   const body = document.querySelector('body') as HTMLElement;

   return {

      rowData: [],

      columnDefs: getColumnDefs(),

      defaultColDef: centeredColumnDef,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      suppressAggFuncInHeader: true,
      
      rowGroupPanelShow: 'never',

      suppressCellSelection: true,

      popupParent: body,

      tooltipShowDelay: 1000,

      getRowHeight: (args) => {
         if (isNullOrUndefined(args.node) || isNullOrUndefined(args.node.data)) {
            return AG_DEFAULT_ROW_HEIGHT;
         }

         return !this.showArchivedData && args.node.data.isArchived ? 0 : AG_DEFAULT_ROW_HEIGHT;
      },

      postSort: (rowNodes: RowNode[]) => {
         const nextInsertPos = rowNodes.length;
         
         for (let i = rowNodes.length - 1; i >= 0; i--) {
             const pfItem: ComboGroupDto = rowNodes[i].data;
             if (pfItem && pfItem.isArchived) {
                if (i !== nextInsertPos) {
                   rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                }
             }
         }
      },

      getRowNodeId: (row: ComboGroupDto) => row.comboGroupId,

      onGridReady: (args: GridReadyEvent) => this.onComboGroupsGridReady(args),

      getContextMenuItems: (args: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Create ComboGroup',
               disabled: !this.selectedCombo,
               action: () => this.createComboGroup()
            },
            'separator',
            {
               name: 'Rename ComboGroup',
               disabled: !args.node || !args.node.data || (this.selectedComboGroup !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.renameComboGroup()
            },
            {
               name: 'Transfer ComboGroup',
               disabled: !args.node || !args.node.data || (this.selectedComboGroup !== args.node.data) 
                  || args.node.data.isArchived,
               action: () => this.transferComboGroup(args.node.data)
            },
            {
               name: 'Duplicate ComboGroup',
               disabled: !args.node || !args.node.data || (this.selectedComboGroup !== args.node.data) 
                  || args.node.data.isArchived,
               action: () => this.duplicateComboGroup(args.node.data)
            },
            {
               name: 'Archive ComboGroup',
               disabled: !args.node || !args.node.data || (this.selectedComboGroup !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.archiveComboGroup()
            },
            {
               name: 'Delete ComboGroup',
               disabled: !args.node || !args.node.data || (this.selectedComboGroup !== args.node.data),
               action: () => this.deleteComboGroup()
            },
            'separator',
            {
               name: 'Attributes...',
               subMenu: [
                  {
                     name: 'Position Sizing',
                     action: () => this.showPositionSizingDialog(args.node.data.comboGroupId, 'ComboGroup'),
                  },
               ],
               disabled: !args.node || ! args.node.data || args.node.data.group || (args.node.data !== this.selectedComboGroup)
                || args.node.data.isArchived,
            },
            {
               name: 'Hedge/Freeze ComboGroup',
               disabled: !args.node || !args.node.data || (this.selectedComboGroup !== args.node.data)
                  || args.node.data.isArchived,
               action: () => this.hedgeComboGroup()
            },
            {
               name: 'Add Interest Calculator',
               disabled: !args.node || !args.node.data || (args.node.data !== this.selectedComboGroup),
               action: () => this.showInterestRateDialog(args.node.data.comboGroupId, 'ComboGroup')
            },
            {
               name: 'Clear Trading Data',
               disabled: !args.node || ! args.node.data || args.node.data.group || (args.node.data !== this.selectedComboGroup),
               action: () => this.clearTradingDataForBucket('ComboGroup', args.node.data.comboGroupId, args.node.data.comboGroupName)
            },
            'separator',
            {
               name: 'Bucket History',
               action: () => this.showBucketHistory(args.node.data, 'ComboGroup')
            },
            'separator',
            {
               name: 'Show/Hide Archived Data',
               action: () => this.changeShowArchivedData(),
               checked: this.showArchivedData
            },
            'separator',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll'
         ];
      },

      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node || !args.node.data) {
            this.selectedComboGroup = null;
            return;
         }
         
         if (args.node.data === this.selectedComboGroup) {
               // if we clicked already selected combogroup,
               // that means we are showing the combogroup data already and no need to reload
               return;
         }

         this.onComboGroupSelected(args.node.data);
      },

      onRowDoubleClicked: (args: RowDoubleClickedEvent) => {
         if (!args.node || !args.node.data) {
            return;
         }

         this.onComboGroupSelected(args.node.data);
      },

      onGridSizeChanged: (args: GridSizeChangedEvent) => {
         if (args.clientWidth === 0) {
            return;
         }
         
         this.setSectionSize();
      }
   };
}

function getColumnDefs(): ColDef[] {
   const colDefs: ColDef[] = [];

   const isArchivedCol: ColDef = {
      field: 'isArchived',
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return undefined;
         }
         return params.data.isArchived ? 'Archived Items' : 'Active Items';
      }
   };

   const comboGroupName: ColDef = {
      headerName: 'Name',
      field: 'comboGroupName',
      minWidth: 215,
      flex: 1,
      tooltipField: 'comboGroupName',
      valueFormatter: (args: ValueFormatterParams) => {
         if (!args.data) {
            return args.value;
         }
         if (args.data.hasAttributes) {
            return args.value + ' *';
         }
      },
      cellStyle: (args: CellClassParams) => {
         if (isNullOrUndefined(args.data)) {
            return undefined;
         }
         const style = { 'text-align': 'left', color: undefined };
         
         if (args.data.isArchived) {
            style.color = 'yellow';
         }
         return style;
      },
   };

   const runningStrategies: ColDef = {
      headerName: 'Strats.',
      field: 'runningStrategies'
   };

   const avgPxCol: ColDef = {
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.avgPx;
      },
      valueFormatter: defaultPriceCellFormatter,
      // aggFunc: comboTotalPriceAggFunc
   };

   const liveQuoteCol: ColDef = {
      headerName: 'Live Quote',
      field: 'liveQuote',
      // aggFunc: liveQuoteTotalPriceAggFunc,
      valueFormatter: (params: ValueFormatterParams) => {

         if (!params.value) {
            return null;
         }

         if (params.value === -1) {
            return '?';
         }

         const pi = params.data as PortfolioItemDto;

         if (!pi) {
            return formatNumber(params.value, 'en-US', '1.2-2');
         }

         const value = formatNumber(params.value, 'en-US', '1.2-2');

         if (pi.netPosition === 0) {
            return `${value} (L)`;
         }

         if (pi.netPosition > 0) {
            return `${value} (B)`;
         }

         if (pi.netPosition < 0) {
            return `${value} (A)`;
         }

         return value;
      },
   };

   const sessionPnLCol: ColDef = Object.assign({
      headerName: 'Sess.P&L',
      field: 'sessionPnL',
      minWidth: 117,
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition);

   const accumulatedPnLCol: ColDef = Object.assign({
      headerName: 'Acc.P&L',
      field: 'accumulatedPnL',
      minWidth: 117,
      aggFunc: 'sum'
   }, defaultMoneyCellDefinition);

   const totalDeltaCol: ColDef = {
      headerName: 'T.Delta',
      field: 'totalDelta',
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalGammaCol: ColDef = {
      headerName: 'T. Gamma',
      field: 'totalGamma',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalVegaCol: ColDef = {
      headerName: 'T. Vega',
      field: 'totalVega',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalThetaCol: ColDef = {
      headerName: 'T. Theta',
      field: 'totalTheta',
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
         if (isNullOrUndefined(params.value)) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   colDefs.push(comboGroupName);
   colDefs.push(runningStrategies);
   colDefs.push(sessionPnLCol);
   colDefs.push(accumulatedPnLCol);
   colDefs.push(totalDeltaCol);
   // colDefs.push(liveQuoteCol);
   // colDefs.push(avgPxCol);
   colDefs.push(totalGammaCol);
   colDefs.push(totalVegaCol);
   colDefs.push(totalThetaCol);
   colDefs.push(isArchivedCol);

   return colDefs;
}
