export class StrategyScheduleModel {
  startTime?: string;
  stopTime?: string;
  exitTime?: string;
  country?: string;
  timeZone?: string;
  beginDate?: Date;
  endDate?: Date;
  isRecurring?: boolean = false;
  isAutostart?: boolean = false;
  mon?: boolean = false;
  tue?: boolean = false;
  wed?: boolean = false;
  thu?: boolean = false;
  fri?: boolean = false;
}