import { PositionDto } from "projects/shared-components/shell-communication/dtos/position-dto.class";
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { isVoid } from "projects/shared-components/utils";

export class PortfolioOverviewBlock {
   constructor(
      comboGroupId: string
   ) {
      this.comboGroupId = comboGroupId;
   }

   get isAvailable(): boolean {
      return !isVoid(this.items);
   }
   
   readonly comboGroupId: string;

   readonly header = 'Portfolio';
   
   items: PortfolioItemDto[];

   setData(dtos: PortfolioItemDto[]) {
      this.items = dtos;
   }

   onPositionDto(dto: PositionDto): boolean {
      
      if (isVoid(dto)) {
         return false;
      }

      if (dto.comboGroupId !== this.comboGroupId) {
         return false;
      }

      const item = this.items.find( x => x.portfolioItemId === dto.positionId);

      if(isVoid(item)) {
         return false;
      }

      if (item.netPosition === dto.netPosition) {
         return false;
      }
      
      item.netPosition = dto.netPosition;

      return true;
   }

   //
   onPortfolioItemAdded(item: PortfolioItemAddedDto): boolean {
      if (isVoid(item)) {
         return false;
      }

      if (isVoid(item.portfolioItem)) {
         return false;
      }

      if (item.portfolioItem.comboGroupId !== this.comboGroupId) {
         return false;
      }

      const alreadyHas = this.items.some( x => x.portfolioItemId === item.portfolioItem.portfolioId)
      if (alreadyHas) {
         return false;
      }

      this.items.push(item.portfolioItem);

      return true;
   }

   onPortfolioItemRemoved(item: PortfolioItemRemovedDto): boolean {
      if (isVoid(item)) {
         return false;
      }

      if (item.comboGroupId !== this.comboGroupId) {
         return false;
      }

      const ix = this.items.findIndex(x => x.portfolioItemId === item.portfolioItemId);

      if (ix < 0) {
         return false;
      }

      this.items.splice(ix, 1);

      return true;
   }
}