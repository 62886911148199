import { AggregationSpec } from 'projects/shared-components/aggregated-positions/aggregation-spec.interface';
import { RiskManagerRuleDto } from 'projects/shared-components/shell-communication/dtos/risk-manager-rule-dto.interface';

export class RiskRuleModel {
   constructor(
      private _dto: RiskManagerRuleDto
   ) {
   }

   private _tradingObjectives: string;
   private _aggregationSpecObj: AggregationSpec;
   private _aggregationSpecString: string;
   
   hasUviewedViolations: boolean;
   
   isViolationInProgress: boolean;

   get ruleId(): string {
      return this._dto.ruleId;
   }


   get ruleName(): string {
      return this._dto.ruleName;
   }


   get ruleDescription(): string {
      return this._dto.ruleDescription;
   }


   get isBlocked(): boolean {
      return this._dto.isBlocked;
   }
   set isBlocked(v: boolean) {
      this._dto.isBlocked = v;
   }

   
   get isSession(): boolean {
      return this._dto.isSession;
   }

   
   get npo(): number {
      return this._dto.npo;
   }

   
   get percentTS(): number {
      return this._dto.percentTS;
   }

   
   get dollarTS(): number {
      return this._dto.dollarTS;
   }

   
   get stopLoss(): number {
      return this._dto.stopLoss;
   }


   get aggregationSpecObj(): AggregationSpec {
      if (!this._aggregationSpecObj) {
         this._aggregationSpecObj = JSON.parse(this._dto.aggregationSpec || '{}');
      }

      return this._aggregationSpecObj;
   }


   get aggregationSpecString(): string {

      if (!this._aggregationSpecString) {
         const spec = this.aggregationSpecObj;

         let str = '';
   
         if (spec.shellTotal) {
            str = 'Shell Total';
         } else {
            if (spec.securityId) {
               str += `Ticker: "${this._dto.securityName}"`;
            }
      
            if (spec.terminalId) {
               str += ` ,Terminal: "${this._dto.terminalName}"`;
            }
      
            if (spec.accountId) {
               str += ` ,Account: "${this._dto.accountName}"`;
            }
      
            if (spec.portfolioId) {
               str += ` ,Portfolio: "${this._dto.portfolioName}"`;
            }
            
            if (spec.comboId) {
               str += ` ,Combo: "${this._dto.comboName}"`;
            }
            
            if (spec.comboGroupId) {
               str += ` ,ComboGroup: "${this._dto.comboGroupName}"`;
            }

            if (spec.positionClass) {
               str += ` ,Position Class: "${spec.positionClass}"`;
            }

            if (str.startsWith(' ,')) {
               str = str.substr(2);
            }
         }
   
         this._aggregationSpecString = str;
      }

      return this._aggregationSpecString;
   }
   

   get tradingObjectives(): string {
      if (!this._tradingObjectives) {
         this._tradingObjectives = `NPO = ${this._dto.npo}, % TS = ${this._dto.percentTS
            }, $ TS = ${this._dto.dollarTS}, StopLoss = ${this._dto.stopLoss}`;
      }
      return this._tradingObjectives;
   }

   

   resetDescriptiveStrings() {
      this._tradingObjectives = null;
      this._aggregationSpecObj = null;
   }


   
   onRuleEdited(dto: RiskManagerRuleDto): void {
      this._dto = dto;
      this.resetDescriptiveStrings();
   }
}
