import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {SettingsStorageService} from "../../../settings-storage-service.service";
import {DetectMethodChanges, isVoid} from "../../../utils";
import {SessionService} from "../../../authentication/session-service.service";
import {isIOSUserAgent} from "ag-grid-community/dist/lib/utils/browser";

const StorageKey = 'hg.zones-grid-settings';

interface HgZonesGridSettings {
    bypassParameters?: boolean;
}

@Component({
    selector: 'ets-hg-zones-grid-menu',
    templateUrl: 'hg-zones-grid-menu.component.html',
    styleUrls: ['hg-zones-grid-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgZonesGridMenuComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _settingsStorageService: SettingsStorageService,
        private _toastr: ToastrService,
        private _sessionService: SessionService
    ) {
    }

    visible = false;

    bypassParameters = false;

    ngOnInit() {
    }

    @DetectMethodChanges()
    show() {
        this.restoreSettings();
        this.visible = true;
    }

    onApply() {
        this.saveSettings();
        this.onHidden();
    }

    onCancel() {
        this.onHidden();
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }

    private restoreSettings() {
        const settings = this.getSettings();
        if (isVoid(settings)) {
            return;
        }
        this.bypassParameters = settings?.bypassParameters || false;
    }

    private saveSettings() {
        const data: HgZonesGridSettings = {
            bypassParameters: this.bypassParameters
        };

        const userId = this._sessionService.sessionData.userId;

        this._settingsStorageService.setItem(StorageKey, data, userId);
    }

    getSettings(): HgZonesGridSettings {
        const userId = this._sessionService.sessionData.userId;

        // const data = this._settingsStorageService.getItem(StorageKey, userId);

        const settings =  this._settingsStorageService
            .getItem<HgZonesGridSettings>(StorageKey, userId);

        if (isVoid(settings)) {
            return {};
        }


        return settings;
    }
}