import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IssueAckedUIMessage } from '../ui-messages/issue-acked-ui-message.interface';

@Component({
   selector: 'strategy-issues',
   template: `
    <p *ngIf="numberOfIssues > 0" class="issues-count"
       (click)="showStrategyIssues()"
    >{{numberOfIssues}}</p>
  `,
   styles: [
      `
      .issues-count {
        text-align: center;
        color: red;
        font-weight: bold;
        cursor: pointer;
      }
    `
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyIssuesCellRendererComponent implements OnDestroy, ICellRendererAngularComp, AfterViewInit {
   constructor(
      private readonly _strategyIssuesService: StrategiesIssuesService,
      private readonly _messageBus: MessageBusService,
      private readonly _changeDetector: ChangeDetectorRef
   ) {
   }

   private readonly _unsubscriber = new Subject<void>();

   data: StrategyModel;
   panelId: string;

   get numberOfIssues(): number {
      let result = 0;
      if (!!this.data) {
         result = this._strategyIssuesService
            .getStrategyIssuesCount(this.data.strategyId);
      }
      return result;
   }

   agInit(params: ICellRendererParams): void {
      this.data = params.data;
      const issuesColumn = params.columnApi.getColumn('issues');
      if (issuesColumn) {
         const panelId = issuesColumn['ets-panel-id'];
         this.panelId = panelId;
      }

      this._messageBus.of('StrategyIssuesChanged')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(() => this._changeDetector.detectChanges());

      this._messageBus.of<IssueAckedUIMessage>('IssueAckedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((x) => this.onIssueAcked(x.payload));
   }

   ngAfterViewInit() {
      
      setTimeout(() => {
         this._changeDetector.detectChanges();
      }, 1000);
   }

   private onIssueAcked(x: IssueAckedUIMessage): void {
      if (x.strategyId === this.data.strategyId) {
         this._changeDetector.detectChanges();
      }
   }


   ngOnDestroy() {
      this._unsubscriber.next();
      this._unsubscriber.complete();
   }

   
   refresh(): boolean {
      return false;
   }

   
   showStrategyIssues(): void {
      this._messageBus.publish({
         topic: 'ShowStrategyIssuesRequest',
         payload: {
            strategy: this.data,
            panelId: this.panelId
         }
      });
   }
}
