import {ChangeDetectorRef} from "@angular/core";
import {IndividualConfig, ToastrService} from "ngx-toastr";
import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {OptionsChainService} from "projects/shared-components/option-chains.service";
import {GetOptionChainShellResponse} from "projects/shared-components/shell-communication/shell-dto-protocol";
import {TradingInstrument} from "projects/shared-components/trading-instruments/trading-instrument.class";
import {
    DetectMethodChanges,
    isVoid,
    isNullOrUndefined,
    isTruthy,
    delay,
    isDefaultTemplate, isValidNumber
} from "projects/shared-components/utils";
import {
    CashFlowStrategyParameterChangedEvent
} from "../settings-section/cash-flow-strategy-settings/model/CashFlowStrategyParameterChangedEvent";
import {
    CashFlowStrategySettingsModel
} from "../settings-section/cash-flow-strategy-settings/model/CashFlowStrategySettingsModel";
import {
    CashFlowStrategyExpirationsSettingsModel,
    ExpirationsSettingsChangedEvent
} from "../settings-section/expirations-settings/CashFlowStrategyExpirationsSettingsModel";
import {
    CashFlowStrategyGlobalSettingsChangedEvent,
    CashFlowStrategyGlobalSettingsModel
} from "../settings-section/global-settings/CashFlowStrategyGlobalSettingsModel";
import {CashFlowStrategyTemplatesService} from "../services/cashflow-strategy-templates.service";
import {ICashFlowAdjustmentSettingsTemplate} from "./ICashFlowAdjustmentSettingsTemplate";
import {DeleteTemplatePopupModel, EditTemplatePopupModel} from "./EditTemplatePopupModel";
import {
    TradingInstrumentsService
} from "projects/shared-components/trading-instruments/trading-instruments-service.interface";
import {CashFlowStrategySettingsTemplateSet} from "./CashFlowStrategySettingsTemplateSet";
import {SessionService} from "projects/shared-components/authentication/session-service.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PositionsRestoredEventArgs} from "../positions-section/model/PositionsSectionModel";
import {AssignTemplatesPopupModel} from "../settings-section/AssignTemplatesPopupModel";
import {AssignableAdjustmentTemplatesService} from "../settings-section/assignable-adjustment-templates.service";
import {AccessControlService} from "projects/shared-components/access-control-service.class";
import {ApplicationSettingsService} from "projects/shared-components/app-settings/application-settings.service";
import {AdjustmentPricingSettingsDto} from "projects/shared-components/shell-communication/shell-operations-protocol";
import {ICashFlowStrategyGlobalSettings} from "./ICashFlowStrategyGlobalSettings";
import {ICashFlowExpirationSettings} from "./ICashFlowExpirationSettings";
import {ApgContextMenuComponent} from "../context-menu/apg-context-menu.component";
import {PositionsData} from "../positions-section/model/PositionsData";
import {LastQuoteCacheService} from "../../last-quote-cache.service";

interface ValidationContext {
    target: 'Template' | 'Apply'
}

interface ContextDataProvider {
    isZonesGridLinked: boolean;
    sweetPriceIsTracking(): boolean;
    onLongRunningOperationChanged(x: boolean): void;
    onSymbolChanged(x: any): void;
    onStrategyChanged(x: any): Promise<void>;
    applyClicked(): Promise<void>;
    toggleSettingsSectionCollapsed(): void;
    onSecondSpreadChanged(x: any): Promise<void>;
    onSecondProtectiveOptionChanged(x: any): Promise<void>;
    onTemplateUpdated(tpl: ICashFlowAdjustmentSettingsTemplate): Promise<void>;
    onTemplateApplied(): Promise<void>;
    getSecondOptionsConfiguration(): any;
    getSelectedTemplate(): ICashFlowAdjustmentSettingsTemplate;
    getDefaultTemplate(underlying: string, strategy: CashFlowStrategy): ICashFlowAdjustmentSettingsTemplate;
    isOperationInProgress(): boolean;
    pasteSettings(pasteSettings: ICashFlowAdjustmentSettingsTemplate): void;
    copyPositionsAndSettings(): void;
    pastePositionsAndSettings(): Promise<void>
    getPositions(): PositionsData[];
    isPriceBoxTheoretical(): boolean;
    isZonesGridTheoretical(): boolean;
}


type CtxMenuItems = 'Copy' | 'Paste' | 'Copy With Positions' | 'Paste Positions & Settings';


export class SettingsSectionModel {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _unsubscriber: Subject<void>,
        private readonly _optionsService: OptionsChainService,
        private readonly _cashFlowStrategyTemplatesService: CashFlowStrategyTemplatesService,
        private readonly _toastr: ToastrService,
        private readonly _sessionService: SessionService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _assignableTemplatesService: AssignableAdjustmentTemplatesService,
        private readonly _accessControlService: AccessControlService,
        applicationSettings: ApplicationSettingsService,
    ) {

        this.globalSettings = new CashFlowStrategyGlobalSettingsModel(
            _changeDetector,
            _unsubscriber,
            _cashFlowStrategyTemplatesService,
            _toastr,
            _sessionService,
            _accessControlService,
            applicationSettings,
            _optionsService
        );

        this.globalSettings.parameterChanged$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(async (x: CashFlowStrategyGlobalSettingsChangedEvent) => {
                await this.onGlobalParameterChanged(x);
            });


        this.strategyHedgePortfolio = new CashFlowStrategySettingsModel(
            this as any,
        );
        this.strategyHedgePortfolio.strategyName = 'Hedged Portfolio';
        this.strategyHedgePortfolio.parameterChanged$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(async (x: CashFlowStrategyParameterChangedEvent) => {
                await this.onStrategyParameterChanged(x);
            });


        this.strategyReversedHedgePortfolio = new CashFlowStrategySettingsModel(
            this as any,
        );
        this.strategyReversedHedgePortfolio.strategyName = 'Reversed Hedged Portfolio';
        this.strategyReversedHedgePortfolio.parameterChanged$
            .pipe(takeUntil(_unsubscriber))
            .subscribe(async (x: CashFlowStrategyParameterChangedEvent) => {
                await this.onStrategyParameterChanged(x);
            });


        this.strategyCalls = new CashFlowStrategySettingsModel(
            this as any,
        );
        this.strategyCalls.strategyName = 'Calls';
        this.strategyCalls.parameterChanged$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(async (x: CashFlowStrategyParameterChangedEvent) => {
                await this.onStrategyParameterChanged(x);
            });


        this.strategyPuts = new CashFlowStrategySettingsModel(
            this as any,
        );
        this.strategyPuts.strategyName = 'Puts';
        this.strategyPuts.parameterChanged$
            .pipe(takeUntil(_unsubscriber))
            .subscribe(async (x: CashFlowStrategyParameterChangedEvent) => {
                await this.onStrategyParameterChanged(x);
            });

        this.expirationsSettings = new CashFlowStrategyExpirationsSettingsModel(this as any);
        this.expirationsSettings.parameterChanged$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((x: ExpirationsSettingsChangedEvent) => {
                this.onExpirationParameterChanged(x);
            });

        this.editTemplatePopup = new EditTemplatePopupModel(
            this._changeDetector,
            this._toastr,
            this._cashFlowStrategyTemplatesService,
        );

        this.deleteTemplatePopup = new DeleteTemplatePopupModel(
            _changeDetector
        );

        this.assignTemplatesPopup = new AssignTemplatesPopupModel(
            this._changeDetector,
            this._toastr,
            this._assignableTemplatesService
        );
    }


    private _contextDataProvider: ContextDataProvider;


    private _selectedPortfolio: any;


    globalSettings: CashFlowStrategyGlobalSettingsModel;

    //#region Strategies Settings


    strategyHedgePortfolio: CashFlowStrategySettingsModel;


    strategyReversedHedgePortfolio: CashFlowStrategySettingsModel;


    strategyCalls: CashFlowStrategySettingsModel;


    strategyPuts: CashFlowStrategySettingsModel;

    //#endregion


    expirationsSettings: CashFlowStrategyExpirationsSettingsModel;


    editTemplatePopup: EditTemplatePopupModel;

    deleteTemplatePopup: DeleteTemplatePopupModel;

    assignTemplatesPopup: AssignTemplatesPopupModel;


    hasChanges = false


    getContainerCssClass() {
        return this.hasChanges ? 'dirty' : undefined;
    }


    get canSaveTemplateAssignable(): boolean {
        return this._accessControlService.isSecureElementAvailable('baab84a2-4ea1-4503-8697-f9a62a5df1a6');
    }


    get showHedgePortfolioStrategy(): boolean {
        return this.isTemplateSelected
            && this.globalSettings.selectedStrategy === 'Hedged Portfolio';
    }


    get showReversedHedgePortfolioStrategy(): boolean {
        return this.isTemplateSelected
            && this.globalSettings.selectedStrategy === 'Reversed Hedged Portfolio';
    }


    get showCallsStrategy(): boolean {

        if (!this.isTemplateSelected) {
            return false;
        }

        if (!this.isStrategySelected) {
            return false;
        }

        return this.globalSettings.selectedStrategy.indexOf('Calls') >= 0;
    }


    get showPutsStrategy(): boolean {
        if (!this.isTemplateSelected) {
            return false;
        }

        if (!this.isStrategySelected) {
            return false;
        }

        return this.globalSettings.selectedStrategy.indexOf('Puts') >= 0;
    }


    get isTwoSideStrategy(): boolean {
        return this.showCallsStrategy && this.showPutsStrategy;
    }


    get shouldRollDates(): boolean {
        return this.globalSettings.priceToDestination;
    }


    // noinspection JSUnusedGlobalSymbols
    get canApplySettings(): boolean {
        return isNullOrUndefined(this.validate());
    }


    get canProvideSettings(): boolean {
        return this.globalSettings.isStrategySelected
            && this.globalSettings.isSymbolSelected;
    }


    get isStrategySelected(): boolean {
        return !isVoid(this.globalSettings.selectedStrategy);
    }


    get isTemplateSelected(): boolean {
        return !isVoid(this.globalSettings.selectedTemplate);
    }


    get isReadOnlyTemplate(): boolean {
        return this.globalSettings.isReadOnlyTemplate;
    }


    get showSaveTemplateButton(): boolean {
        return this._cashFlowStrategyTemplatesService
            .canDeleteTemplate(this.globalSettings.selectedTemplate);
    }

    get showEditTemplateButton(): boolean {
        return this._cashFlowStrategyTemplatesService
            .canDeleteTemplate(this.globalSettings.selectedTemplate);
    }


    get showDeleteTemplateButton(): boolean {
        return this._cashFlowStrategyTemplatesService
            .canDeleteTemplate(this.globalSettings.selectedTemplate);
    }


    get showAssignTemplatesButton(): boolean {
        return this._sessionService.isSuperUser;
    }


    get underlying(): string {
        if (this.globalSettings.tradingInstrument) {
            return this.globalSettings.tradingInstrument.underlying;
        }

        return undefined;
    }


    readonly ctxMenuItems: { text: CtxMenuItems }[] = [{text: 'Copy'}];


    ctxMenu: ApgContextMenuComponent;


    getSecondPoMismatchResolver(settings: CashFlowStrategySettingsModel): () => any {
        return () => {

            if (this._contextDataProvider.isOperationInProgress()) {
                return false;
            }

            const selectedTemplate = this.globalSettings.selectedTemplate;

            if (isVoid(selectedTemplate) || isDefaultTemplate(selectedTemplate)) {
                return false;
            }

            const positions: {
                hasSecondProtectiveOption: boolean
            }[] = this._contextDataProvider.getSecondOptionsConfiguration();

            if (isVoid(positions)) {
                return false;
            }

            let index = settings.strategyName === 'Puts' ? 1 : 0;

            if (positions.length === 1) {
                index = 0;
            }

            const hasSecondPoPositions = positions[index].hasSecondProtectiveOption;

            return hasSecondPoPositions !== (settings.secondProtectiveOptionEnabled || false);
        }
    }


    getSecondSpreadMismatchResolver(settings: CashFlowStrategySettingsModel): () => any {
        return () => {

            if (this._contextDataProvider.isOperationInProgress()) {
                return false;
            }

            const selectedTemplate = this.globalSettings.selectedTemplate;

            if (isVoid(selectedTemplate) || isDefaultTemplate(selectedTemplate)) {
                return false;
            }

            const positions = this._contextDataProvider.getSecondOptionsConfiguration();

            if (isVoid(positions)) {
                return false;
            }

            let index = settings.strategyName === 'Puts' ? 1 : 0;

            if (positions.length === 1) {
                index = 0;
            }

            const hasSecondSpread = positions[index].hasSecondSpread;

            return hasSecondSpread !== (settings.secondSpreadEnabled || false);
        }
    }


    validate(ctx?: ValidationContext): string[] {

        // Global
        if (isVoid(this.globalSettings.selectedStrategy)) {
            return [this.getErrorString('Strategy')];
        }

        if (isVoid(this.globalSettings.underlying)) {
            return [this.getErrorString('Underlying')];
        }

        if (this.globalSettings.useAdjustToQty && !isValidNumber(this.globalSettings.adjustToQty)) {
            return ['"Adjust To Qty" must be provided and be greater or equal to zero, if respective checkbox is checked'];
        }

        let strategySettings: CashFlowStrategySettingsModel[] = [];

        switch (this.globalSettings.selectedStrategy) {
            case 'Hedged Portfolio':
                strategySettings.push(this.strategyHedgePortfolio);
                break;
            case 'Calls':
                strategySettings.push(this.strategyCalls);
                break;
            case 'Puts':
                strategySettings.push(this.strategyPuts);
                break;
            case 'Reversed Hedged Portfolio':
                strategySettings.push(this.strategyReversedHedgePortfolio);
                break;
            case 'Calls & Puts':
                strategySettings.push(this.strategyCalls);
                strategySettings.push(this.strategyPuts);
                break;
        }

        if (this.globalSettings.useTheoreticalPrices) {
            if (isVoid(this.globalSettings.theoreticalPriceMode)) {
                return  ['"Theoretical Pricing Mode" is mandatory'];
            }

            if (isVoid(this.globalSettings.theoreticalPriceTarget)) {
                return  ['"Theoretical Pricing Target" is mandatory'];
            }

            if (isValidNumber(this.globalSettings.theoreticalPriceIv)) {
                if (this.globalSettings.theoreticalPriceIv <=0) {
                    return  ['"Theoretical Pricing IV" must be greater than zero or null'];
                }
            }
        }

        if (isVoid(strategySettings)) {
            return ['Unknown Strategy Selected'];
        }

        const positionsData = this._contextDataProvider
            .getPositions()
            .map(x => x.positions);

        if (positionsData.length > 0) {

            let args: PositionsRestoredEventArgs = {
                hasSpreadFirst: positionsData[0].some(x => x.role.startsWith('Spread')),

                hasSecondSpreadFirst: positionsData[0].some(x => x.role.startsWith('SecondSpread')),

                hasSecondProtectiveOptionFirst: positionsData[0].some(x => x.role.startsWith('SecondProtective')),

                hasSpreadSecond: positionsData.length > 1
                    ? positionsData[1].some(x => x.role.startsWith('Spread'))
                    : false,

                hasSecondSpreadSecond: positionsData.length > 1
                    ? positionsData[1].some(x => x.role.startsWith('SecondSpread'))
                    : false,

                hasSecondProtectiveOptionSecond: positionsData.length > 1
                    ? positionsData[1].some(x => x.role.startsWith('SecondProtective'))
                    : false
            };

            const template = this.getSettings();
            const mismatchMessage = this.getConfigurationMismatchMessage(args, template);

            if (!isVoid(mismatchMessage)) {
                return [mismatchMessage];
            }
        }

        const errors = strategySettings.map(settings => {

            // Short Option


            // Spread

            if (isNullOrUndefined(settings.spreadOffset)) {
                return this.getErrorString('Spread Offset');
            }

            if (!isTruthy(settings.spreadWidth)) {
                return this.getErrorString('Spread Width');
            }

            if (isNullOrUndefined(settings.spreadRollXDaysBeforeExpiration)) {
                if (this.shouldRollDates) {
                    if (!settings.isSpreadRollToDateOverriden) {
                        return this.getErrorString('Spread Roll [x] Days Before Expiration');
                    }
                }
            }

            if (!isTruthy(settings.spreadRollToDaysToExp)) {
                if (this.shouldRollDates) {
                    if (!settings.isSpreadRollToDateOverriden) {
                        return this.getErrorString('Spread Roll To Days To Expiration');
                    }
                }
            }

            // Second Spread

            const hasSecondSpreadMismatch = this.getSecondSpreadMismatchResolver(settings)();
            if (hasSecondSpreadMismatch) {
                if (ctx && ctx.target === 'Apply') {
                    return `${settings.strategyName}: Second Spread Configuration Mismatch`;
                }
            }

            if (settings.secondSpreadEnabled) {
                if (isNullOrUndefined(settings.secondSpreadOffset)) {
                    return this.getErrorString('2nd Spread Offset');
                }

                if (!isTruthy(settings.secondSpreadWidth)) {
                    return this.getErrorString('2nd Spread Width');
                }

                if (isNullOrUndefined(settings.secondSpreadRollXDaysBeforeExpiration)) {
                    if (this.shouldRollDates) {
                        if (!settings.isSpreadRollToDateOverriden) {
                            return this.getErrorString('2nd Spread Roll [x] Days Before Expiration');
                        }
                    }
                }

                if (!isTruthy(settings.secondSpreadRollToDaysToExp)) {
                    if (this.shouldRollDates) {
                        if (!settings.isSpreadRollToDateOverriden) {
                            return this.getErrorString('2nd Spread Roll To Days To Expiration');
                        }
                    }
                }
            }


            // Protective Put

            if (isNullOrUndefined(settings.protectiveOptionOffset)) {
                return this.getErrorString('Protective Option Offset');
            }

            if (isNullOrUndefined(settings.protectiveOptionRollXDaysBeforeExpiration)) {
                if (this.shouldRollDates) {
                    if (!settings.isProtectiveOptionRollDateOverriden) {
                        return this.getErrorString('Protective Option Roll [x] Days Before Expiration');
                    }
                }
            }

            if (!isTruthy(settings.protectiveOptionRollToDaysToExp)) {
                if (this.shouldRollDates) {
                    if (!settings.isProtectiveOptionRollDateOverriden) {
                        return this.getErrorString('Protective Option Roll To  Days To Exp');
                    }
                }
            }

            // Second Protective Put
            const hasSecondPoMismatch = this.getSecondPoMismatchResolver(settings)();
            if (hasSecondPoMismatch) {
                if (ctx && ctx.target === 'Apply') {
                    return `${settings.strategyName}: Second PO Configuration Mismatch`;
                }
            }

            if (settings.secondProtectiveOptionEnabled) {
                if (isNullOrUndefined(settings.secondProtectiveOptionOffset)) {
                    return this.getErrorString('2nd Protective Option Offset');
                }

                if (isNullOrUndefined(settings.secondProtectiveOptionRollXDaysBeforeExpiration)) {
                    if (this.shouldRollDates) {
                        if (!settings.isSecondProtectiveOptionRollDateOverriden) {
                            return this.getErrorString('2nd Protective Option Roll [x] Days Before Expiration');
                        }
                    }
                }

                if (!isTruthy(settings.secondProtectiveOptionRollToDaysToExp)) {
                    if (this.shouldRollDates) {
                        if (!settings.isSecondProtectiveOptionRollDateOverriden) {
                            return this.getErrorString('2nd Protective Option Roll To  Days To Exp');
                        }
                    }
                }
            }

            // Expirations

            if (this.shouldRollDates) {

                if (isVoid(settings.spreadOverrideRollToDaysToExp) &&
                    isVoid(settings.spreadRollToDaysToExp) &&
                    isVoid(settings.spreadEvergreenOverrideRollToDaysToExp)
                ) {
                    return 'Spread: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
                }

                if (isVoid(settings.protectiveOptionOverrideRollToDaysToExp) &&
                    isVoid(settings.protectiveOptionRollToDaysToExp) &&
                    isVoid(settings.protectiveOptionEvergreenOverrideRollToDaysToExp) &&
                    !isValidNumber(settings.protectiveOptionRollToXBusinessDaysToExp)
                ) {
                    return 'Protective Option: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
                }

                if (settings.secondSpreadEnabled) {
                    if (
                        isVoid(settings.secondSpreadOverrideRollToDaysToExp) &&
                        isVoid(settings.secondSpreadRollToDaysToExp) &&
                        isVoid(settings.secondSpreadEvergreenOverrideRollToDaysToExp)
                    ) {
                        return '2nd Spread: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
                    }
                }

                if (settings.secondProtectiveOptionEnabled) {
                    if (
                        isVoid(settings.secondProtectiveOptionOverrideRollToDaysToExp) &&
                        isVoid(settings.secondProtectiveOptionRollToDaysToExp) &&
                        isVoid(settings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp) &&
                        !isValidNumber(settings.secondProtectiveOptionRollToXBusinessDaysToExp)
                    ) {
                        return '2nd Protective Option: "Price To Destination" mode requires either "Override Roll To Date" or "Roll To Days To Exp."';
                    }
                }

                if (!isNullOrUndefined(settings.spreadRollToDaysToExp)) {
                    if (settings.spreadRollToDaysToExp < settings.spreadRollXDaysBeforeExpiration) {
                        return '"Spread: "Roll To Days To Exp." must be greater than "Roll [x] Days Before Expiration"'
                    }
                }

                if (!isNullOrUndefined(settings.protectiveOptionRollToDaysToExp)) {
                    if (settings.protectiveOptionRollToDaysToExp < settings.protectiveOptionRollXDaysBeforeExpiration) {
                        return '"Protective Option: "Roll To Days To Exp." must be greater than "Roll [x] Days Before Expiration"'
                    }
                }
            }

            const customDates = this.expirationsSettings.customDates.map(x => x.value).filter(x => !isNullOrUndefined(x));

            if (customDates.length !== this.expirationsSettings.customDates.length) {
                return this.getErrorString('Custom Dates');
            }

            if (customDates.length === 0 && this.expirationsSettings.expirationsToLookForward == 0) {
                return 'Provide "Custom Dates" or "Expirations To Look Forward"';
            }

            return null;
        });

        return errors.filter(x => !isVoid(x));
    }


    @DetectMethodChanges({isAsync: true})
    async onStrategyParameterChanged(event: CashFlowStrategyParameterChangedEvent): Promise<void> {

        if (!this.isStrategySelected) {
            return;
        }

        this.hasChanges = true;

        if (event.property === 'secondSpreadEnabled') {
            await this._contextDataProvider.onSecondSpreadChanged({include: event.value, strategyName: event.strategy})
            return;
        }

        if (event.property === 'secondProtectiveOptionEnabled') {
            await this._contextDataProvider.onSecondProtectiveOptionChanged({
                include: event.value,
                strategyName: event.strategy
            });
            return;
        }

        if (this.globalSettings.selectedStrategy !== 'Calls & Puts') {
            return;
        }

        if (event.property === 'spreadWidth') {
            return;
        }

        let oppositeStrategy: CashFlowStrategySettingsModel;

        if (event.strategy === 'Calls') {

            oppositeStrategy = this.strategyPuts;

        } else if (event.strategy === 'Puts') {

            oppositeStrategy = this.strategyCalls;

        }

        console.assert(!isNullOrUndefined(oppositeStrategy));

        if (oppositeStrategy) {
            oppositeStrategy.setParameter(event.property, event.value);
        }

    }


    @DetectMethodChanges()
    onExpirationParameterChanged(event: ExpirationsSettingsChangedEvent) {
        if (event.property === 'expirations') {
            return;
        }
        this.hasChanges = true;
    }


    @DetectMethodChanges({isAsync: true})
    async onGlobalParameterChanged(x: CashFlowStrategyGlobalSettingsChangedEvent): Promise<void> {
        if (x.property === 'selectedStrategy') {

            await this.onStrategyChanged(x.value);

        } else if (x.property === 'tradingInstrument') {

            await this.onSymbolChanged(x.value);

        } else if (x.property === 'selectedTemplate') {

            await this.onStrategyTemplateChanged();

        } else {

            this.hasChanges = true;

        }
    }


    // Called after initialization of the component, to set last loaded template
    setDefaultTemplate(asset?: string, strategy?: CashFlowStrategy, overrideLastUsed?: boolean) {
        this.globalSettings.setDefaultTemplate(asset, strategy, overrideLastUsed);
    }


    @DetectMethodChanges({isAsync: true})
    async onStrategyTemplateChanged(): Promise<void> {

        this._contextDataProvider.onLongRunningOperationChanged(true);

        try {
            // hack to allow loading pane to render
            await delay(250);
            await this.onStrategyTemplateChangedInternal();

        } finally {

            this._contextDataProvider.onLongRunningOperationChanged(false);

        }
    }


    @DetectMethodChanges({isAsync: true})
    async onSymbolChanged(ti: TradingInstrument): Promise<void> {

        const underlying = ti.underlying

        this._contextDataProvider.onLongRunningOperationChanged(true);

        // hack to let loading panel render
        await delay(250);

        const chain = await this._optionsService.getChain(underlying);

        this.onOptionChainAcquired(chain);

        this._contextDataProvider.onSymbolChanged(ti);

        if (this.isStrategySelected) {
            await this.onStrategyChanged(this.globalSettings.selectedStrategy);
        }

        this._contextDataProvider.onLongRunningOperationChanged(false);
    }


    @DetectMethodChanges({isAsync: true})
    async onStrategyChanged(strategy: CashFlowStrategy): Promise<void> {
        if (
            !this.globalSettings.selectedTemplate ||
            this.globalSettings.selectedTemplate.strategyName !== strategy
        ) {

            this.setDefaultTemplate(this.underlying, strategy, true);

            return;

        }

        await this._contextDataProvider.onStrategyChanged(strategy);
    }


    @DetectMethodChanges()
    onOptionChainAcquired(ch: GetOptionChainShellResponse): void {
        if (!ch) {
            return;
        }
        this.strategyHedgePortfolio.onOptionChainUpdated(ch.expirations);
        this.strategyCalls.onOptionChainUpdated(ch.expirations);
        this.strategyPuts.onOptionChainUpdated(ch.expirations);
        this.expirationsSettings.onOptionChainUpdated(ch.expirations);
    }


    @DetectMethodChanges()
    reset() {
        this.hasChanges = false;
    }


    getSettings(): ICashFlowAdjustmentSettingsTemplate {

        const globalSettings = this.globalSettings.getSettings();

        const strategies: CashFlowStrategySettingsModel[] = [];

        switch (this.globalSettings.selectedStrategy) {
            case 'Hedged Portfolio':
                strategies.push(this.strategyHedgePortfolio);
                break;
            case 'Calls':
                strategies.push(this.strategyCalls);
                break;
            case 'Puts':
                strategies.push(this.strategyPuts);
                break;
            case 'Reversed Hedged Portfolio':
                strategies.push(this.strategyReversedHedgePortfolio);
                break;
            case 'Calls & Puts':
                strategies.push(this.strategyCalls);
                strategies.push(this.strategyPuts);
                break;
        }

        const expirationSettings = this.expirationsSettings.getSettings();

        const settings = strategies.map(x => x.getSettings());

        /*
           no need to specify template name and underlying
           because we simply this as data model, not as actual template
        */
        const template: ICashFlowAdjustmentSettingsTemplate = {
            templateId: null,
            version: null,
            underlying: this.underlying,
            templateName: null,
            strategyName: this.globalSettings.selectedStrategy,
            globalSettings,
            settings: settings.map(x => {
                return {
                    strategyName: x.strategyName,
                    strategySettings: x,
                } as CashFlowStrategySettingsTemplateSet
            }),
            expirationSettings
        }

        const someStrategyNamesAreMissing = template.settings.some(x => isVoid(x.strategyName));

        if (someStrategyNamesAreMissing) {
            console.error('missing strategy names in settings', template);
            return null;
        }

        return template;
    }


    @DetectMethodChanges()
    private applyTemplate(template: ICashFlowAdjustmentSettingsTemplate) {

        if (isVoid(template)) {
            return;
        }

        let affectedViewModels: CashFlowStrategySettingsModel[] = [];

        switch (template.strategyName) {
            case 'Hedged Portfolio':
                affectedViewModels.push(this.strategyHedgePortfolio);
                break;
            case 'Reversed Hedged Portfolio':
                affectedViewModels.push(this.strategyReversedHedgePortfolio);
                break;
            case 'Calls':
                affectedViewModels.push(this.strategyCalls);
                break;
            case 'Puts':
                affectedViewModels.push(this.strategyPuts);
                break;
            case 'Calls & Puts':
                affectedViewModels.push(this.strategyCalls);
                affectedViewModels.push(this.strategyPuts);
                break;
        }

        if (!isVoid(affectedViewModels)) {
            this.globalSettings.applyTemplate(template);
            affectedViewModels.forEach(x => x.applyTemplate(template));
            this.expirationsSettings.applyTemplate(template);
        }

        this.hasChanges = true;
    }


    toggleCollapsed() {
        this._contextDataProvider.toggleSettingsSectionCollapsed();
    }


    @DetectMethodChanges()
    saveSettingsAsTemplate() {

        const ctx: ValidationContext = {
            target: 'Template'
        };

        const errors = this.validate(ctx);

        if (!isVoid(errors)) {
            errors.forEach(err => this._toastr.error(err));
            return;
        }

        const proposedTemplate = this.getSettings();

        proposedTemplate.underlying = this.underlying;

        proposedTemplate.strategyName = this.globalSettings.selectedStrategy;

        this.editTemplatePopup.show(proposedTemplate, 'new');
    }


    @DetectMethodChanges({isAsync: true})
    async saveTemplate(): Promise<void> {

        const ctx: ValidationContext = {
            target: 'Template'
        };

        const errors = this.validate(ctx);

        if (!isVoid(errors)) {
            errors.forEach(err => this._toastr.error(err));
            return;
        }

        const existingTemplate = this.globalSettings.selectedTemplate;

        console.assert(!isVoid(existingTemplate), 'template exists');
        console.assert(!isVoid(existingTemplate.templateId), 'template has id');
        console.assert(
            !this._cashFlowStrategyTemplatesService.isDefaultTemplate(existingTemplate),
            'template has id'
        );

        const template = this.getSettings();
        template.underlying = this.underlying;
        template.strategyName = this.globalSettings.selectedStrategy;
        template.templateName = existingTemplate.templateName;
        template.templateId = existingTemplate.templateId;
        template.isShared = existingTemplate.isShared;

        const isOK = this._cashFlowStrategyTemplatesService.saveTemplate(template);

        if (isOK) {

            this._toastr.success(`Template "${template.templateName}" was updated`, 'Settings Templates');
            await this._contextDataProvider.onTemplateUpdated(template);

        } else {
            this._toastr.error('Error occurred during template update operation', 'Settings Templates');
        }
    }


    @DetectMethodChanges({isAsync: true})
    async deleteSettingsTemplate(): Promise<void> {
        try {
            await this.deleteTemplatePopup.show(this.getSelectedTemplate());
        } catch(e) {
            return null;
        }
        await this.globalSettings.deleteSelectedTemplate();
    }


    @DetectMethodChanges()
    onPositionsRestored(args: PositionsRestoredEventArgs) {
        if (!this.isStrategySelected) {
            return;
        }

        const selectedTemplate = this.globalSettings.selectedTemplate;

        if (isVoid(selectedTemplate)) {
            return;
        }

        const isDefaultTemplateSelected = this._cashFlowStrategyTemplatesService.isDefaultTemplate(selectedTemplate);

        if (!isDefaultTemplateSelected) {

            this.showConfigurationMismatchMessageIfNeeded(args, selectedTemplate);

            return;
        }

        const strategy = this.globalSettings.selectedStrategy;

        switch (strategy) {
            case 'Calls': {
                this.strategyCalls.changeSecondSpreadState(args.hasSecondSpreadFirst, false);
                this.strategyCalls.changeSecondProtectiveOptionState(args.hasSecondProtectiveOptionFirst, false);
                break;
            }
            case 'Puts': {
                this.strategyPuts.changeSecondSpreadState(args.hasSecondSpreadFirst, false);
                this.strategyPuts.changeSecondProtectiveOptionState(args.hasSecondProtectiveOptionFirst, false);
                break;
            }
            case 'Hedged Portfolio': {
                this.strategyHedgePortfolio.changeSecondSpreadState(args.hasSecondSpreadFirst, false);
                this.strategyHedgePortfolio.changeSecondProtectiveOptionState(args.hasSecondProtectiveOptionFirst, false);
                break;
            }
            case 'Reversed Hedged Portfolio': {
                this.strategyHedgePortfolio.changeSecondSpreadState(args.hasSecondSpreadFirst, false);
                this.strategyHedgePortfolio.changeSecondProtectiveOptionState(args.hasSecondProtectiveOptionFirst, false);
                break;
            }
            case 'Calls & Puts': {
                this.strategyCalls.changeSecondSpreadState(args.hasSecondSpreadFirst, false);
                this.strategyPuts.changeSecondSpreadState(args.hasSecondSpreadSecond, false);

                this.strategyCalls.changeSecondProtectiveOptionState(args.hasSecondProtectiveOptionFirst, false);
                this.strategyPuts.changeSecondProtectiveOptionState(args.hasSecondProtectiveOptionSecond, false);
                break;
            }
            default:
                break;
        }
    }


    private showConfigurationMismatchMessageIfNeeded(args: PositionsRestoredEventArgs, template: ICashFlowAdjustmentSettingsTemplate) {

        let msg = this.getConfigurationMismatchMessage(args, template);

        if (isVoid(msg)) {
            return;
        }

        const title = 'Configuration Mismatch';

        const partialConfig: Partial<IndividualConfig> = {
            disableTimeOut: true,
            tapToDismiss: true,
            enableHtml: true,
            positionClass: 'toastr-top-wide-width'
        };

        this._toastr.error(msg, title, partialConfig);
    }


    private getConfigurationMismatchMessage(args: PositionsRestoredEventArgs, template: ICashFlowAdjustmentSettingsTemplate): string {

        const doubleSided = template.settings.length > 1;

        const hasSecondSpreadFirst = template.settings[0].strategySettings.secondSpreadEnabled || false;
        const hasSecondSpreadSecond = (doubleSided ? template.settings[1].strategySettings.secondSpreadEnabled : false) || false;

        const hasSecondPoFirst = template.settings[0].strategySettings.secondProtectiveOptionEnabled || false;
        const hasSecondPoSecond = (doubleSided ? template.settings[1].strategySettings.secondProtectiveOptionEnabled : false) || false;


        const secondSpreadFirstMatch = hasSecondSpreadFirst === args.hasSecondSpreadFirst;
        const secondSpreadSecondMatch = hasSecondSpreadSecond === args.hasSecondSpreadSecond;

        const secondPoFirstMatch = hasSecondPoFirst === args.hasSecondProtectiveOptionFirst;
        const secondPoSecondMatch = hasSecondPoSecond === args.hasSecondProtectiveOptionSecond;

        if ((secondSpreadFirstMatch && secondSpreadSecondMatch) && (secondPoFirstMatch && secondPoSecondMatch)) {
            return;
        }

        let msg = 'Current positions configuration is different from template configuration: <br/><br/>';

        if (!secondSpreadFirstMatch || !secondSpreadSecondMatch) {
            const firstStrategy = template.settings[0].strategyName;
            const secondStrategy = doubleSided ? template.settings[1].strategyName : 'N/A';

            let strategy = !secondSpreadFirstMatch ? firstStrategy : secondStrategy;
            msg += `&nbsp;&nbsp;&nbsp;&nbsp;- 2nd Spread mismatch (${strategy})<br/>`;

        }

        if (!secondPoFirstMatch || !secondPoSecondMatch) {
            const firstStrategy = template.settings[0].strategyName;
            const secondStrategy = doubleSided ? template.settings[1].strategyName : 'N/A';

            let strategy = !secondPoFirstMatch ? firstStrategy : secondStrategy;
            msg += `&nbsp;&nbsp;&nbsp;&nbsp;- 2nd PO mismatch (${strategy})<br/>`
        }

        msg += '<br/>Settings must be in sync with positions before adjustment can be applied.'
        msg += '<br/><br/>'
        msg += 'Tap to Dismiss';

        return msg;
    }


    @DetectMethodChanges({isAsync: true})
    async assignTemplates() {
        await this.assignTemplatesPopup.show();
    }


    onPortfolioSelected(portfolio: any): void {

        this._selectedPortfolio = portfolio;

        this.globalSettings.onPortfolioSelected(portfolio);

        if (!this.isTemplateSelected) {
            this.hasChanges = false;
        }

    }


    @DetectMethodChanges({isAsync: true})
    async syncSettings(snapshotSettings: AdjustmentPricingSettingsDto[]): Promise<void> {

        const globalSettings: ICashFlowStrategyGlobalSettings = {
            priceToOpen: snapshotSettings[0].priceToOpen,
            priceToDestination: snapshotSettings[0].priceToDestination,
            isStrategyAdvancedMode: snapshotSettings[0].isStrategyAdvancedMode
        };

        const strategySettings = snapshotSettings.map(s => {
            const tpl: CashFlowStrategySettingsTemplateSet = {
                strategyName: s.strategy,
                strategySettings: {
                    protectiveOptionOffset: s.protectiveOptionOffset,
                    protectiveOptionOverrideRollToDaysToExp: s.protectiveOptionOverrideRollToDaysToExp,
                    protectiveOptionRollToDaysToExp: s.protectiveOptionRollToDaysToExp,
                    protectiveOptionRollXDaysBeforeExpiration: s.protectiveOptionRollXDaysBeforeExpiration,

                    secondProtectiveOptionOffset: s.secondProtectiveOptionOffset,
                    secondProtectiveOptionOverrideRollToDaysToExp: s.secondProtectiveOptionOverrideRollToDaysToExp,
                    secondProtectiveOptionRollToDaysToExp: s.secondProtectiveOptionRollToDaysToExp,
                    secondProtectiveOptionRollXDaysBeforeExpiration: s.secondProtectiveOptionRollXDaysBeforeExpiration,

                    secondSpreadOffset: s.secondSpreadOffset,
                    secondSpreadOverrideRollToDaysToExp: s.secondSpreadOverrideRollToDaysToExp,
                    secondSpreadRollToDaysToExp: s.secondSpreadRollToDaysToExp,
                    secondSpreadRollXDaysBeforeExpiration: s.secondSpreadRollXDaysBeforeExpiration,
                    secondSpreadWidth: s.secondSpreadWidth,

                    spreadOffset: s.spreadOffset,
                    spreadOverrideRollToDaysToExp: s.spreadOverrideRollToDaysToExp,
                    spreadRollToDaysToExp: s.spreadRollToDaysToExp,
                    spreadRollXDaysBeforeExpiration: s.spreadRollXDaysBeforeExpiration,
                    spreadWidth: s.spreadWidth,

                    strategyName: s.strategy
                }
            }

            return tpl;
        });

        const expirationSettings: ICashFlowExpirationSettings = {
            customDates: isVoid(snapshotSettings[0].customDates) ? [] : snapshotSettings[0].customDates.map(x => ({value: x})),
            expirationsToLookForward: snapshotSettings[0].expirationsToLookForward,
            joinRollBuffer: snapshotSettings[0].joinRollBuffer
        };

        const template: ICashFlowAdjustmentSettingsTemplate = {

            templateId: this.underlying,
            templateName: undefined,

            strategyName: snapshotSettings.length === 2 ? 'Calls & Puts' : snapshotSettings[0].strategy,
            underlying: this.underlying,
            version: undefined,

            globalSettings,
            settings: strategySettings,
            expirationSettings,
        }

        const strategy: CashFlowStrategy = snapshotSettings.length == 2
            ? 'Calls & Puts'
            : snapshotSettings[0].strategy;

        const underlying = snapshotSettings[0].underlying;

        const tpl = this._cashFlowStrategyTemplatesService.getOrCreateDefaultTemplate(
            underlying, strategy
        );

        template.templateId = tpl.templateId;
        template.templateName = tpl.templateName;

        const ti = this._tiService.getInstrumentByTicker(underlying);

        this.globalSettings.selectedTemplate = tpl;
        this.globalSettings.tradingInstrument = ti;
        this.globalSettings.selectedStrategy = strategy;

        this.applyTemplate(template);

        this._cashFlowStrategyTemplatesService.saveLastUsedTemplate(
            tpl, this._selectedPortfolio.id
        );
    }


    setContextDataProvider(ctx: ContextDataProvider) {
        this._contextDataProvider = ctx;
        this.globalSettings.setContextDataProvider(ctx);
    }


    private getErrorString(parameter: string): string {
        return `"${parameter}" a mandatory parameter`;
    }


    private async onStrategyTemplateChangedInternal(): Promise<void> {

        this.globalSettings.tradingInstrument = undefined;
        this.globalSettings.selectedStrategy = undefined;


        let tpl = this.globalSettings.selectedTemplate;

        if (isVoid(tpl)) {
            return;
        }

        if (this._cashFlowStrategyTemplatesService.isDefaultTemplate(tpl)) {
            tpl = this._cashFlowStrategyTemplatesService.getOrCreateDefaultTemplate(
                tpl.underlying,
                tpl.strategyName
            );
            this.globalSettings.selectedTemplate = tpl;
        }

        const ti = this._tiService.getInstrumentByTicker(tpl.underlying);

        console.assert(!isVoid(ti), 'trading instrument not found ' + tpl.underlying);

        this.globalSettings.tradingInstrument = ti;

        await this.onSymbolChanged(ti);

        console.assert(
            !isVoid(this.globalSettings.tradingInstrument),
            'trading instrument was not selected'
        );

        this.globalSettings.selectedStrategy = tpl.strategyName;

        await this.onStrategyChanged(tpl.strategyName);

        this.applyTemplate(tpl);

        const pfId = !isVoid(this._selectedPortfolio)
            ? this._selectedPortfolio.id
            : null;

        const wasSaved = this._cashFlowStrategyTemplatesService
            .saveLastUsedTemplate(tpl, pfId);

        console.assert(wasSaved, 'failed to save last used template name');

        await this._contextDataProvider.onTemplateApplied();
    }


    applyPasteTemplate(tpl: ICashFlowAdjustmentSettingsTemplate): void {
        this._contextDataProvider.onLongRunningOperationChanged(true);
        try {
            this.applyTemplate(tpl);
        } finally {
            this._contextDataProvider.onLongRunningOperationChanged(false);
        }
    }


    // noinspection JSUnusedGlobalSymbols
    getSelectedTemplate(): ICashFlowAdjustmentSettingsTemplate {
        return this._contextDataProvider.getSelectedTemplate();
    }


    // noinspection JSUnusedGlobalSymbols
    getDefaultTemplate(underlying: string, strategy: CashFlowStrategy): ICashFlowAdjustmentSettingsTemplate {
        return this._contextDataProvider.getDefaultTemplate(underlying, strategy);
    }


    // noinspection JSUnusedGlobalSymbols
    isOperationInProgress(): boolean {
        return this._contextDataProvider.isOperationInProgress();
    }


    hasConfigurationMismatch(): boolean {

        if (this._contextDataProvider.isOperationInProgress()) {
            return false;
        }

        const strategies = [];

        if (this.showCallsStrategy) {
            strategies.push(this.strategyCalls);
        }

        if (this.showPutsStrategy) {
            strategies.push(this.strategyPuts);
        }

        if (this.showHedgePortfolioStrategy) {
            strategies.push(this.strategyHedgePortfolio);
        }

        if (this.showReversedHedgePortfolioStrategy) {
            strategies.push(this.strategyReversedHedgePortfolio);
        }

        const mismatches = strategies.map(s => {
            const secondPo = this.getSecondPoMismatchResolver(s);
            const secondSpread = this.getSecondSpreadMismatchResolver(s);

            return secondPo() || secondSpread();
        });

        return mismatches.some(x => x);
    }


    hasDifferencesWithTemplate(): boolean {

        let diff = this.globalSettings.isDifferentFromTemplate();

        diff = diff || this.expirationsSettings.isDifferentFromTemplate();

        if (this.showCallsStrategy) {

            diff = diff || this.strategyCalls.isDifferentFromTemplate();

            if (this.showPutsStrategy) {
                diff = diff || this.strategyPuts.isDifferentFromTemplate();
            }

            return diff;

        }

        if (this.showPutsStrategy) {

            diff = diff || this.strategyPuts.isDifferentFromTemplate();
            return diff;

        }

        if (this.showHedgePortfolioStrategy) {

            diff = diff || this.strategyHedgePortfolio.isDifferentFromTemplate();
            return diff;

        }

        if (this.showReversedHedgePortfolioStrategy) {
            diff = diff || this.strategyReversedHedgePortfolio.isDifferentFromTemplate();
            return diff;
        }

        return false;
    }


    toggleCtxMenu() {

        this.ctxMenuItems.length = 0;

        const tpl = this._contextDataProvider.getSelectedTemplate();

        if (!isVoid(tpl)) {
            this.ctxMenuItems.push({text: 'Copy'});
            this.ctxMenuItems.push({text: 'Copy With Positions'});
        }

        // this complexity related to the fact that we have to maintain
        // array reference the same to prevent redrawing ctx menu
        navigator.clipboard.readText()
            .then(text => {

                if (!isVoid(text)) {

                    const hasSettingsInClipboard = text.startsWith('apg.copy/paste.settings');

                    if (hasSettingsInClipboard) {
                        this.ctxMenuItems.push({text: 'Paste'});
                    } else {
                        const hasComboInClipboard = text.startsWith('apg.copy/paste.combination');
                        if (hasComboInClipboard) {
                            this.ctxMenuItems.push({text: 'Paste Positions & Settings'});
                        }
                    }

                }

                this.ctxMenu.toggle();

            })
            .catch(e => console.error(e));
    }


    async onCtxMenuItemClick($event: CtxMenuItems | string) {
        if ($event === 'Copy') {

            const settings = this.getSettings();

            let json = JSON.stringify(settings);

            json = 'apg.copy/paste.settings' + json;

            navigator.clipboard
                .writeText(json)
                .then(() => this._toastr.success('Copied!'))
                .catch(e => this._toastr.error(e.message));

        } else if ($event === 'Copy With Positions') {

            this._contextDataProvider.copyPositionsAndSettings();

        } else if ($event === 'Paste') {

            this._contextDataProvider.onLongRunningOperationChanged(true);

            navigator.clipboard
                .readText()
                .then(text => {

                    if (isVoid(text)) {
                        return;
                    }

                    if (!text.startsWith('apg.copy/paste.settings')) {
                        return;
                    }

                    const json = text.split('apg.copy/paste.settings')[1];

                    const pasteSettings = JSON.parse(json) as ICashFlowAdjustmentSettingsTemplate;

                    if (isVoid(pasteSettings)) {
                        console.error('bad settings in clipboard');
                        return;
                    }


                    const ul = pasteSettings.underlying;

                    if (ul !== this.underlying && !isVoid(this.underlying)) {
                        this._toastr.error('Positions you are trying to paste have ' +
                            'different underlying than currently selected');
                        return;
                    }

                    const pasteStrategy = pasteSettings.strategyName;

                    const selectedStrategy = this.globalSettings.selectedStrategy;

                    if (selectedStrategy !== pasteStrategy && !isVoid((selectedStrategy))) {
                        this._toastr.error('Positions you are trying to paste have ' +
                            'different strategy than currently selected');
                        return;
                    }


                    if (!isVoid(this.globalSettings.selectedTemplate)) {
                        this.applyTemplate(pasteSettings);
                        this._contextDataProvider.onTemplateApplied();
                    } else {
                        this._contextDataProvider.pasteSettings(pasteSettings);
                    }


                })
                .finally(() => {
                    this._contextDataProvider.onLongRunningOperationChanged(false);
                });

        } else if ($event === 'Paste Positions & Settings') {

            await this._contextDataProvider.pastePositionsAndSettings();

        }
    }

    editTemplate() {
        this.editTemplatePopup.show(this.getSelectedTemplate(), 'edit');
    }
}
