
<div class="empty" *ngIf="!this.viewModel">
   <p>N/A</p>
</div>

<table class="table" *ngIf="this.viewModel" [ngStyle]="{'font-size': this.mode === 'sideblock' ? 'larger' : 'inherit' }">
   
   <tbody>
      
      <tr>
         <td class="parameter">Status:</td>
         <td class="value" [ngClass]="
         {
            'enabled': this.viewModel.isStrategyRunning, 
            'disabled': !this.viewModel.isStrategyRunning
         }">{{this.viewModel.isStrategyRunning ? 'Enabled' : 'Disabled'}}</td>
      </tr>

      <tr>
         <td class="parameter">Issues:</td>
         <td class="value" (click)="this.showStrategyIssues()">
            <p [ngStyle]="{
               'cursor': 'pointer',
               'color':  this.viewModel.strategyIssues > 0 ? 'red' : 'inherit',
               'font-weight': 800
            }">{{this.viewModel.strategyIssues}}</p>
         </td>
      </tr>

      <tr class="amount">
         <td class="parameter">Amount:</td>
         <td class="value">{{this.viewModel.amount | currency:'USD':'symbol':'1.0-0' }}</td>
      </tr>
      
      <tr class="rate">
         <td class="parameter">Interest Rate:</td>
         <td class="value">{{this.viewModel.interestRate}}%</td>
      </tr>
      
      <tr class="interval">
         <td class="parameter">Interval:</td>
         <td class="value">{{this.viewModel.interval}}</td>
      </tr>
      
      <tr class="account">
         <td class="parameter">Account:</td>
         <td class="value">{{this.viewModel.account}}</td>
      </tr>
   </tbody>
</table>

<ets-strategy-issues-browser></ets-strategy-issues-browser>
