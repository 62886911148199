<div style="cursor: pointer; position: relative"
     id="session-messages-action-button" title="Session Messages">
  
   <i class="fas fa-inbox fa-2x" style="position: relative" (click)="onActionButtonClick($event)"></i>

  <div class="semaphore"
       style="position: absolute;top: -7px;right: -13px; padding-top: 3px;">
    <div class="badge important" *ngIf="messagesIndicator.important > 0">&nbsp;</div>
    <div class="badge regular" *ngIf="messagesIndicator.regular > 0">&nbsp;</div>
  </div>

</div>

<dx-popup width="85vw" height="85vh"
   title="Session Messages"
   [closeOnOutsideClick]="true"
   [resizeEnabled]="true"
   [dragEnabled]="true"
   [showCloseButton]="true"
   [visible]="this.isOverlayVisible"
   (onHidden)="this.onHidden()"
   >
   <div *dxTemplate="let data of 'content'">
      <ets-shell-messages></ets-shell-messages>
   </div>
</dx-popup>


<!-- Stick Popup -->
<dx-popup width="550"
          height="402"
          [showTitle]="true"
          title='Sticky Messages'
          [closeOnOutsideClick]="false"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          [showCloseButton]="this.stickyMessagesDialog.showCloseButton"
          [visible]="this.stickyMessagesDialog.isVisible"
          (onHidden)="this.stickyMessagesDialog.onHidden()"
          (onShown)="this.stickyMessagesDialog.onShown()">
  <div *dxTemplate="let data of 'content'" id="session_message_details">
   
   <div class="messagebox">

      <dx-multi-view  [animationEnabled]="true"
         [items]="this.stickyMessagesDialog.messages"
         [selectedIndex]="this.stickyMessagesDialog.currentMessageIndex">
         <div *dxTemplate="let shellMessage of 'item'">

            <div class="time">
               <span class="header">Timestamp:</span>
               <span class="value">{{this.formatMessageTime(shellMessage)}}</span>
            </div>
       
             <div class="client">
               <span class="header">Client:</span>
               <span class="value">{{shellMessage.clientName}}</span>
             </div>
       
             <div class="shell">
               <span class="header">Shell:</span>
               <span class="value">{{shellMessage.shellName}}</span>
             </div>
             
             <div class="domain">
               <span class="header">Domain:</span>
               <span class="value">{{shellMessage.domain}}</span>
             </div>
             
             <div class="source">
               <span class="header">Source:</span>
               <span class="value">{{shellMessage.source}}</span>
             </div>
             
             <hr />
       
             <div class="text">
               <dx-text-area [value]="shellMessage.message" [readOnly]="true"></dx-text-area>
             </div>
         </div>
      </dx-multi-view>

      <hr>

      <dx-box direction="row" width="100%" class="controls">
         <dxi-item [ratio]="1">
            <div class="snooze">
               <dx-drop-down-button text="Snooze" [items]="['1 min', '5 min', '15 min', '30 min', '1 hour']"
                  (onItemClick)="this.stickyMessagesDialog.snooze($event)">
               </dx-drop-down-button>
            </div>
         </dxi-item>
         <dxi-item [ratio]="1">
            <div class="slider">
               <div class="btn prev">
                  <dx-button text="Prev" [disabled]="!this.stickyMessagesDialog.canSlidePrev"
                     (onClick)="this.stickyMessagesDialog.movePrevMessage()"></dx-button>
               </div>
               <div class="msg current">
                  {{this.stickyMessagesDialog.currentMessageIndex + 1}}
               </div>
               <div> of </div>
               <div class="msg total">
                  {{this.stickyMessagesDialog.messages.length}}
               </div>
               <div class="btn next">
                  <dx-button text="Next" [disabled]="!this.stickyMessagesDialog.canSlideNext" 
                     (onClick)="this.stickyMessagesDialog.moveNextMessage()"></dx-button>
               </div>   
            </div>
         </dxi-item>
      </dx-box>
   </div>

  </div>
</dx-popup>