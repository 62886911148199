import {ProtocolQuery} from '../../service-model/protocol-query.interface';
import {PROTOCOL_NAMESPACE} from './auth-protocol-namespace.const';

export class Authenticate implements ProtocolQuery {
  constructor(
    public readonly username: string,
    public readonly password: string,
    public readonly appId: string,
    public readonly platformVersion: string,
    public readonly sessionToken?: string
  ) {
  }

  getOperationName(): string {
    return `${PROTOCOL_NAMESPACE}.Authenticate`;
  }
}
