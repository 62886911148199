<div class="position-sizing">
   <fieldset>
      <div class="container">
         <dx-check-box text="Use Account Size in Calculation" 
            [disabled]="!isPositionSizingEnabled"
            (valueChange)="this.onPositionSizingUseAccountSizeInCalculationsChanged($event)"
            [value]="positionSizing?.useAccountSizeInCalculations"></dx-check-box>
      </div>
      <div class="container">
         <div class="label">Account Size</div>
         <div class="input">
            <dx-number-box [min]="0" [showClearButton]="true"
               [disabled]="!positionSizing?.useAccountSizeInCalculations"
               [value]="positionSizing?.baseAccountSize"
               (valueChange)="this.onPositionSizingAccountSizeChanged($event)">
            </dx-number-box>
         </div>
      </div>

      <hr class="divider" style="width: 80%;">

      <!-- Main Positions Sizing Strategy -->

      <div class="container">
         <div class="label">Choose Position Sizing</div>
         <div class="input">
            <dx-select-box [items]="positionSizing.sizingStrategyItems" [showClearButton]="true"
               [value]="positionSizing?.sizingStrategy"
               (valueChange)="this.onPositionSizingSizingStrategyChanged($event)"
               [displayExpr]="'text'" [valueExpr]="'value'" [disabled]="!isPositionSizingEnabled"
               [selectedItem]="positionSizing?.sizingStrategy"></dx-select-box>
         </div>

         <div class="fixed-ratio" *ngIf="positionSizing?.sizingStrategy === 1">
            <div class="row">
               <div class="label">Unit Size</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.ratioUnitSize"
                     (valueChange)="this.onPositionSizingRatioUnitSizeChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Delta</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.deltaSize"
                     (valueChange)="this.onPositionSizingDeltaChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Allow Decrease of Multiplier</div>
               <div class="input">
                  <dx-check-box [value]="positionSizing.allowDecreaseMultiplier"
                     (valueChange)="this.onPositionSizingAllowDecreaseMultiplierChanged($event)">
                  </dx-check-box>
               </div>
            </div>
         </div>
         <div class="fixed-fractional" *ngIf="positionSizing?.sizingStrategy === 2">
            <div class="row">
               <div class="label">Unit Size</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.fractionalUnitSize"
                     (valueChange)="this.onPositionSizingFractionalUnitSizeChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Trade 1 Unit Every $ of Equity</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.unitPerAmount"
                     (valueChange)="this.onPositionSizingUnitPerAmountChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Risk X% of Equity per Trade</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.unitPerPercent"
                     (valueChange)="this.onPositionSizingUnitPerPercentChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Risk per Unit $</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.riskPerUnit"
                     (valueChange)="this.onPositionSizingRiskPerUnitChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
         </div>
      </div>

      <hr class="divider" style="width: 80%;">

      <div class="container">
         <div class="label">When...</div>
         <div class="input">
            <dx-select-box
               [items]="[{text: 'Net Profit Reached', value: 'profit'},{text: 'When More Efficient', value: 'efficient'}]"
               #when [displayExpr]="'text'" [valueExpr]="'value'"
               [selectedItem]="positionSizing?.uiSwitchWhen" [disabled]="!isPositionSizingEnabled"
               [value]="positionSizing?.uiSwitchWhen"
               (valueChange)="this.onPositionSizingUiSwitchWhenChanged($event)"
               [showClearButton]="true"></dx-select-box>
            <div *ngIf="when.value === 'profit'" class="when_net_profit">
               <span>Profit:</span>
               <div class="number_box">
                  <dx-number-box [value]="positionSizing?.switchWhenProfit"
                     (valueChange)="this.onPositionSizingSwitchWhenProfitChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
         </div>
      </div>

      <!-- Switch To -->

      <div class="container">
         <div class="label">Switch To: </div>
         <div class="input">
            <dx-select-box [items]="positionSizing.switchToItems"
               [disabled]="!isPositionSizingEnabled || !when.value" [value]="positionSizing?.switchTo"
               [displayExpr]="'text'" [valueExpr]="'value'"
               (valueChange)="this.onPositionSizingSwitchToChanged($event)"
               [showClearButton]="true" [selectedItem]="positionSizing?.switchTo">
            </dx-select-box>
         </div>
         <div class="fixed-ratio" *ngIf="positionSizing?.switchTo === 1">
            <div class="row">
               <div class="label">Unit Size</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.altRatioUnitSize"
                     (valueChange)="this.onPositionSizingAltRationUnitSizeChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Delta</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.altDeltaSize"
                     (valueChange)="this.onPositionSizingAltDeltaSizeChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Allow Decrease of Multiplier</div>
               <div class="input">
                  <dx-check-box [value]="positionSizing.altAllowDecreaseMultiplier"
                     (valueChange)="this.onPositionSizingAltAllowDecreaseMultiplierChanged($event)">
                  </dx-check-box>
               </div>
            </div>
         </div>
         <div class="fixed-fractional" *ngIf="positionSizing?.switchTo === 2">
            <div class="row">
               <div class="label">Unit Size</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.altFractionalUnitSize"
                     (valueChange)="this.onPositionSizingAltFractionalUnitSizeChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Trade 1 Unit Every $ of Equity</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.altUnitPerAmount"
                     (valueChange)="this.onPositionSizingAltUnitPerAmountChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Risk X% of Equity per Trade</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.altUnitPerPercent"
                     (valueChange)="this.onPositionSizingAltUnitPerPercent($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
            <div class="row">
               <div class="label">Risk per Unit $</div>
               <div class="input">
                  <dx-number-box [value]="positionSizing?.altRiskPerUnit"
                     (valueChange)="this.onPositionSizingAltRiskPerUnitChanged($event)"
                     [min]="0"></dx-number-box>
               </div>
            </div>
         </div>
         <dx-check-box class="stay-switched" text="Stay With When Switched"
            [value]="positionSizing?.stayWhenSwitched" [disabled]="!isPositionSizingEnabled"
            (valueChange)="this.onPositionSizingStaySwitchedChanged($event)">
         </dx-check-box>
      </div>

   </fieldset>
</div>
