import { EventEmitter, Injectable } from '@angular/core';
import { ServiceConfiguration } from './ServiceConfiguration';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { isVoid } from 'projects/shared-components/utils';
import { DefaultApgPortfolioId } from '../model/ApgPortfolio';
import { SolutionPositionDto } from '../model/SolutionPositionDto';
import { CashFlowAdjustment } from '../model/CashFlowAdjustment';
import {MessageBusService} from "../../message-bus.service";
import {UserSettingsService} from "../../user-settings.service";
import {SessionService} from "../../authentication/session-service.service";

const StorageKey = 'apg.positions.after';
const ComparisonStorageKeyLeft = 'cpg.positions.after.left';
const ComparisonStorageKeyRight = 'cpg.positions.after.right';

export interface SavedPositionsAfterState {
   portfolioId: string;
   underlying: string;
   strategy: CashFlowStrategy;
   first?: {
      adjustmentDescriptor: string;
      positions: SolutionPositionDto[];
   };
   second?: {
      adjustmentDescriptor: string;
      positions: SolutionPositionDto[];
   };
}

@Injectable()
export class PositionsAfterStateService {

   constructor(
      private _userSettingsService: UserSettingsService,
      private _messageBus: MessageBusService,
      private _sessionService: SessionService,
   ) {

   }

   private _serviceConfig: ServiceConfiguration;

   afterStateUpdated$ = new EventEmitter();

   //
   configure(cfg: ServiceConfiguration) {
      this._serviceConfig = cfg;
   }

   //
   saveState(
      pricing: CashFlowAdjustment,
      portfolioId: string,
      underlying: string,
      strategy: CashFlowStrategy,
      slot: 'first' | 'second'
   ) {

      const adjustmentId = pricing.logicalId;

      const adjustmentDate = isVoid(pricing.expirationDatePrettyWithDaysToExpiration)
         ? 'N/A'
         : pricing.expirationDatePrettyWithDaysToExpiration.split(' ')[0];

      const positions = pricing.afterState.filter(x => !isVoid(x.ticker));

      const key = this.makeStorageKey(portfolioId, underlying, strategy);
      let state = this._userSettingsService.getValue<SavedPositionsAfterState>(
          key, this._serviceConfig.userId
      );

      if (isVoid(state)) {
         state = {
            portfolioId,
            underlying,
            strategy
         }
      }

      const adjustmentDescriptor = `${adjustmentDate} (${adjustmentId})`;

      if (slot === 'first') {
         state.first = {
            adjustmentDescriptor,
            positions
         };
      } else if (slot === 'second') {
         state.second = {
            adjustmentDescriptor,
            positions
         };
      } else {
         throw new Error('Unknown slot for positions state');
      }

      this._userSettingsService.setValue(key, state, this._serviceConfig?.userId);

      this.afterStateUpdated$.emit();

      this._messageBus.publish({
         topic: 'Pkg-Cmprsn.AfterStateSelected',
         payload: {}
      });
   }

   //
   getState(portfolioId, underlying, strategy): SavedPositionsAfterState {

      const key = this.makeStorageKey(portfolioId, underlying, strategy);

      let index;

      if (this._serviceConfig.userId !== this._sessionService.sessionData.userId) {
         index = 'their-current';
      }

      const state = this._userSettingsService.getValue<SavedPositionsAfterState>(
          key, this._serviceConfig?.userId, index
      );

      return state;
   }

   //
   updateState(state: SavedPositionsAfterState) {
      const key = this.makeStorageKey(
         state.portfolioId,
         state.underlying,
         state.strategy
      );

      this._userSettingsService.setValue(key, state, this._serviceConfig.userId);

      this.afterStateUpdated$.emit();
   }

   //
   removeState(portfolioId: string, underlying: string, strategy: CashFlowStrategy) {
      const key = this.makeStorageKey(
         portfolioId,
         underlying,
         strategy
      );

      this._userSettingsService.deleteValue(key);

      this.afterStateUpdated$.emit();
   }


   //
   private makeStorageKey(portfolioId: string, underlying: string, strategy: CashFlowStrategy): string {

      console.assert(!isVoid(this._serviceConfig));

      let rootKey = StorageKey;

      if (this._serviceConfig.orientation === 'left') {
         rootKey = ComparisonStorageKeyLeft;
      } else if (this._serviceConfig.orientation === 'right') {
         rootKey = ComparisonStorageKeyRight;
      }

      const noPortfolio = isVoid(portfolioId) || portfolioId === DefaultApgPortfolioId;

      let key = noPortfolio
         ? `${rootKey}.${underlying}.${strategy}`
         : `${rootKey}.${portfolioId}.${underlying}.${strategy}`;

      return key;
   }
}
