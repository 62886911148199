import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { PanelSortEventArgs } from './panel-sort-event-args';
import { PanelSort } from './panel-sort';
import { PanelModel } from '../panel.model';
import { DetectMethodChanges } from 'projects/shared-components/utils';

interface PanelSortModel {
   panelId: string;
   header: string;
   originalIx: number;
   isActive: boolean;
}

@Component({
   selector: 'ets-panels-sort',
   templateUrl: 'panels-sort.component.html',
   styleUrls: ['panels-sort.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelsSortComponent implements OnInit {

   constructor(private readonly _changeDetector: ChangeDetectorRef) {
      this.sortPanels = new EventEmitter<PanelSortEventArgs>();
   }

   panelsToSort: PanelSortModel[];

   @Input() workspacePanels: PanelModel[];
   @Input() isVisible: boolean;
   @Input() workspaceId: string;
   @Output() sortPanels: EventEmitter<PanelSortEventArgs>;


   ngOnInit() { }

   
   onShowing() {
      this.panelsToSort = this.workspacePanels.map((p, ix) => {
         const retval: PanelSortModel = {
            panelId: p.panelId,
            originalIx: ix,
            header: p.header,
            isActive: p.isActive
         };
         return retval;
      });
   }

   @DetectMethodChanges()
   movePanel(panel, direction: 'up' | 'down') {
      
      const ix = this.panelsToSort.findIndex(p => p === panel);
         
      if (ix < 0) {
         console.warn('panel not found');
      } else {

         let newIx = ix;

         if (newIx === 0 && direction === 'up') {
            return;
         }

         if (direction === 'up') {
            newIx -= 1;
         } else if (direction === 'down') {
            newIx += 1;
         } else {
            throw new Error('Unexpected direction');
         }

         if (newIx >= this.panelsToSort.length) {
            return;
         }

         if (newIx < 0) {
            return;
         }

         const panelToMove = this.panelsToSort[ix];
         const panelToReplace = this.panelsToSort[newIx];

         this.panelsToSort[ix] = panelToReplace;
         this.panelsToSort[newIx] = panelToMove;
      }

   }

   save() {
      const sortedPanels: PanelSort[] = this.panelsToSort.map((p, ix) => {
         const panel: PanelSort = {
            panelId: p.panelId,
            newIx: ix,
            originalIx: p.originalIx
         };
         return panel;
      });

      const args: PanelSortEventArgs = {
         panels: sortedPanels,
         workspaceId: this.workspaceId
      };

      this.sortPanels.emit(args);
   }

   @DetectMethodChanges()
   onHidden() {
      
      this.isVisible = false;
      this.panelsToSort = [];
      this.sortPanels.emit();

   }
}
