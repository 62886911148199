import { Injectable } from '@angular/core';
import { ServiceConfiguration } from './ServiceConfiguration';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { isVoid } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';

const PinnedRowsStorageKey = 'apg.pinned-rows';
const ComparisonPinnedRowsStorageKeyLeft = 'cpg.pinned-rows.left';
const ComparisonPinnedRowsStorageKeyRight = 'cpg.pinned-rows.right';

@Injectable()
export class PriceboxPinnedRowsService {
   constructor(
      private readonly _settingsStorageSettings: SettingsStorageService,
   ) {
   }

   //

   private _index: Record<string, string[]> = {};

   //

   private _serviceConfig: ServiceConfiguration;

   //

   configure(cfg: ServiceConfiguration): void {
      
      this._serviceConfig = cfg;
      
      this.init();

   }
   
   //

   pinRow(portfolioId: string, rowId: string) {

      const container = this.getContainer(portfolioId);
   
      if (container.indexOf(rowId) >= 0) {
         return;
      }

      container.push(rowId);

      this.saveToStorage();

   }

   //

   unpinRow(portfolioId: string, rowId: string) {
      const container = this.getContainer(portfolioId);
   
      const ix = container.indexOf(rowId);

      if (ix < 0) {
         return;
      }

      container.splice(ix, 1);

      this.saveToStorage();
   }

   //

   isRowPinned(portfolioId: string, rowId: string): boolean {
      const container = this.getContainer(portfolioId);
      return container.indexOf(rowId) >= 0;
   }

   //

   private init() {
      
      const storageKey = this.getTemplatesStorageKey();

      const items = this._settingsStorageSettings
          .getItem<Record<string, string[]>>(
              storageKey, this._serviceConfig?.userId
          ) || {};

      this._index = items;

   }

   //
   
   private saveToStorage() {
      const storageKey = this.getTemplatesStorageKey();
      this._settingsStorageSettings.setItem(
          storageKey,
          this._index,
          this._serviceConfig?.userId
      );
   }

   //

   private getContainer(portfolioId: string): string[] {
      const containerKey = portfolioId;
      
      let container = this._index[containerKey];

      if (isVoid(container)) {
         container = [];
         this._index[containerKey] = container;
      }

      return container;
   }

   //

   private getTemplatesStorageKey(): string {
      
      console.assert(!isVoid(this._serviceConfig));

      let key = PinnedRowsStorageKey;

      if (this._serviceConfig.orientation === 'left') {
         key = ComparisonPinnedRowsStorageKeyLeft;
      }

      if (this._serviceConfig.orientation === 'right') {
         key = ComparisonPinnedRowsStorageKeyRight;
      }

      // if (this._serviceConfig.userId) {
      //    key += `${EtsConstants.storageKeys.userSeparator}${this._serviceConfig.userId}`;
      // }

      return key;
   }
}