<dx-popup
  (onHidden)="this.onHidden()"
  (onShown)="this.onShown()"
  [height]="715"
  [resizeEnabled]="true"
  [visible]="this.visible"
  [width]="1080"
  title="Tracking Settings"
>
  <div #container *dxTemplate="let data of 'content'" class="container">
    
    <div class="settings">
      <dx-scroll-view>
        <div class="content">
          
          <!-- Notification Window / Interval, Templates /  Global Sweet Px w. Excl., Filters -->
          <div class="fieldset-row schedule templates">
            
            <!-- Time Window -->
            <div class="column">
              
              <fieldset class="fieldset">
                <legend class="legend">Time Window</legend>
                
                <div class="field">
                  <div class="label">Start</div>
                  <div class="input">
                    <ets-datetime-picker
                      [(value)]="this.startTime"
                      [disabled]="this.notifyTwentyFourSeven"
                      [showTimezone]="false"
                      mode="time">
                    </ets-datetime-picker>
                  </div>
                </div>
                
                <div class="field">
                  <div class="label">Stop</div>
                  <div class="input">
                    <ets-datetime-picker
                      [(value)]="this.stopTime"
                      [disabled]="this.notifyTwentyFourSeven"
                      [showTimezone]="false"
                      mode="time"
                    >
                    </ets-datetime-picker>
                  </div>
                </div>
                
                <div class="field">
                  <div class="label">Timezone</div>
                  <div class="input">
                    <ets-timezone-picker [(timezone)]="this.timezone"
                                         [disabled]="this.notifyTwentyFourSeven"
                                         [showBorder]="false"
                                         [showCaption]="false"
                                         flow="col">
                    </ets-timezone-picker>
                  </div>
                </div>
                
                <div class="or">
                  or
                </div>
                
                <div class="field center">
                  <dx-check-box [(value)]="this.notifyTwentyFourSeven"
                                text="Notify 24/7">
                  </dx-check-box>
                </div>
                
                <hr class="separator">
                
                <div class="field">
                  <div class="label">Interval</div>
                  <div class="input">
                    <dx-select-box
                      [(value)]="this.notificationInterval"
                      [items]="this.notificationIntervalList">
                    </dx-select-box>
                  </div>
                </div>
                
                <div class="field">
                  <div class="label">Strategy</div>
                  <div class="input">
                    <dx-select-box
                      [(value)]="this.notificationStrategy"
                      [items]="this.notificationStrategyList">
                    </dx-select-box>
                  </div>
                </div>
              
              </fieldset>
            
            </div>
            
            <div class="column">
              
              <!-- Notifications -->
              <fieldset class="fieldset auto notifications">
                <legend class="legend">Global ATM</legend>
                
                <div class="field">
                  <div class="label">Override ATM</div>
                  <div class="input">
                    <dx-number-box [(value)]="this.overrideAtm" [inputAttr]="{style: 'text-align: center'}"
                                   showClearButton="true">
                    </dx-number-box>
                  </div>
                </div>
              
              </fieldset>
              
              <!-- Dynamic Templates -->
              <fieldset class="fieldset last dynamic-templates">
                <legend class="legend">Dynamic Templates</legend>
                
                <div *ngFor="let day of this.dynamicTemplates" class="field">
                  <div class="col label">{{ day.dayOfWeek }}</div>
                  <div class="col input">
                    <dx-select-box [(value)]="day.template"
                                   [displayExpr]="'templateName'"
                                   [items]="this.availableTemplates"
                                   [showClearButton]="true">
                    </dx-select-box>
                  </div>
                </div>
              
              </fieldset>
            
            </div>
            
            <div class="column">
              
              <fieldset class="fieldset auto column sweet-price global-exclusions">
                
                <legend class="legend">
                  <div>Global Sweet Price With Exclusions</div>
                </legend>
                
                <div [class.indef]="pauser1.isIndefinitely" class="content">
                  <div class="pricing">
                    <div class="global-price">
                      <div class="label">Sweet Price:</div>
                      <div class="price">
                        <dx-number-box
                          [(value)]="this.globalSweetPrice"
                          [showClearButton]="true"
                          [showSpinButtons]="true"
                          format="$#,##0.00"
                        >
                        </dx-number-box>
                      </div>
                    </div>
                    <div class="use-advanced">
                      <dx-check-box [(value)]="this.globalSweetPriceUseAdvancedPricing"
                                    text="Use Advanced Pricing">
                      </dx-check-box>
                    </div>
                  </div>
                  
                  <div class="exclusions">
                    
                    <div class="exclusion header">
                      <div class="block remove">&nbsp;</div>
                      <div class="block exp">Exp.</div>
                      <div class="block adj one">{{ this.adjustmentOneHeader }}</div>
                      <div *ngIf="!this.isSingleSided" class="block plus">&nbsp;</div>
                      <div class="block adj two">{{ this.adjustmentTwoHeader }}</div>
                    </div>
                    
                    <div *ngFor="let exl of this.globalSweetPriceExclusions; index as i" class="exclusion">
                      <div (click)="this.removeGlobalPriceExclusion(i)" class="block remove">
                        <span>x</span>
                      </div>
                      
                      <div class="block exp">
                        <dx-select-box
                          [(value)]="exl.selectedExpiration"
                          [items]="this.expirations"
                          displayExpr="name"
                          valueExpr="id"
                        >
                        </dx-select-box>
                      </div>
                      
                      <div class="block adj one">
                        <dx-select-box
                          [(value)]="exl.selectedAdjustment"
                          [disabled]="!this.adjustmentOneEnabled"
                          [displayExpr]="'name'"
                          [items]="this.adjustments1"
                          [valueExpr]="'value'">
                        </dx-select-box>
                      </div>
                      
                      <div *ngIf="!this.isSingleSided" class="block plus">
                        +
                      </div>
                      
                      <div class="block adj two">
                        <dx-select-box
                          [(value)]="exl.secondSelectedAdjustment"
                          [disabled]="!this.adjustmentTwoEnabled"
                          [displayExpr]="'name'"
                          [items]="this.adjustments2"
                          [valueExpr]="'value'"
                        >
                        </dx-select-box>
                      </div>
                    </div>
                  
                  </div>
                </div>
                
                <div [class.indef]="pauser1.isIndefinitely" class="button">
                  <dx-button (onClick)="this.addGlobalPriceExclusion()" text="Add Exclusion"></dx-button>
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser1
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.globalSweetPricePauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              </fieldset>
              
              <fieldset class="fieldset last column filters">
                <legend class="legend">Filters</legend>
                
                <div class="content">
                  
                  <div class="filter offset">
                    <div class="label">Offset Filter:</div>
                    <div class="input">
                      <dx-number-box
                        [(value)]="this.offsetFilter"
                        [min]="0"
                        [showClearButton]="true"
                        [showSpinButtons]="true"
                        format="$#,##0.00"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                  
                  <div class="filter spread-width">
                    <div class="label">Spread Width Filter:</div>
                    <div class="input">
                      <dx-number-box
                        [(value)]="this.spreadWidthFilter"
                        [min]="0"
                        [showClearButton]="true"
                        [showSpinButtons]="true"
                        format="$#,##0.00"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                  
                  <div class="filter price-zone">
                    <div class="label">Pause ATM Price Zone Notifications Greater Than</div>
                    <div class="input complex">
                      <div>DTE</div>
                      <div>
                        <dx-select-box
                          [(value)]="this.priceZoneAtmNotificationFilter"
                          [disabled]="!this.priceZoneAtmNotificationFilterEnabled"
                          [dropDownOptions]="{ wrapperAttr: {id: 'ets-pause-dte-x'}}"
                          [items]="[0,1,2,3]"
                        >
                        </dx-select-box>
                      </div>
                      <span>
                        <dx-switch [(value)]="this.priceZoneAtmNotificationFilterEnabled"></dx-switch>
                      </span>
                    </div>
                  </div>
                
                </div>
              </fieldset>
            
            </div>
          
          </div>
          
          <!-- Sweet Px. By Expiration / Sweet Px. By Adj. /  Auto Price Zones-->
          <div class="fieldset-row prices">
            
            <div class="column">
              <fieldset class="fieldset sweet-price by-expiration">
                
                <legend class="legend">
                  <div>Sweet Price By Expiration</div>
                </legend>
                
                <div [class.indef]="pauser2.isIndefinitely" class="content">
                  <div class="advanced-pricing">
                    <dx-check-box [(value)]="this.useAdvancedPricing" text="Use Advanced Pricing">
                    </dx-check-box>
                  </div>
                  
                  <div *ngFor="let sp of this.sweetPricesByExpiration; index as ix" class="field">
                    <div class="label">Expiration #{{ ix + 1 }}</div>
                    <div class="input">
                      <dx-number-box
                        [(value)]="sp.price"
                        [showClearButton]="true"
                        format="$#,##0.00"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser2
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.sweetPricesByExpirationPauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
            
            <div class="column">
              <fieldset class="fieldset column sweet-price by-adjustment">
                
                <legend class="legend">
                  <div>Sweet Price By Adjustment</div>
                </legend>
                
                <div [class.indef]="pauser3.isIndefinitely" class="content table">
                  
                  <div class="row header">
                    <div class="remove">&nbsp;</div>
                    <div class="exp">Exp.</div>
                    <div class="adj one">{{ this.adjustmentOneHeader }}</div>
                    <div *ngIf="!this.isSingleSided" class="plus">&nbsp;</div>
                    <div class="adj two">{{ this.adjustmentTwoHeader }}</div>
                    <div class="price">Price</div>
                  </div>
                  
                  <div *ngFor="let sp of this.sweetPricesByAdjustment; index as i" class="row">
                    
                    <div (click)="this.removeSweetPriceByAdjustment(i)" class="remove">
                      <span>x</span>
                    </div>
                    
                    <div class="exp">
                      <dx-select-box
                        [(value)]="sp.selectedExpiration"
                        [items]="this.expirations"
                        displayExpr="name"
                        valueExpr="id"
                      >
                      </dx-select-box>
                    </div>
                    <div class="adj one">
                      <dx-select-box [(value)]="sp.selectedAdjustment"
                                     [disabled]="!this.adjustmentOneEnabled"
                                     [displayExpr]="'name'"
                                     [items]="this.adjustments1"
                                     [valueExpr]="'value'">
                      </dx-select-box>
                    </div>
                    <div *ngIf="!this.isSingleSided" class="plus">
                      +
                    </div>
                    <div class="adj two">
                      <dx-select-box [(value)]="sp.secondSelectedAdjustment"
                                     [disabled]="!this.adjustmentTwoEnabled"
                                     [displayExpr]="'name'"
                                     [items]="this.adjustments2"
                                     [valueExpr]="'value'">
                      </dx-select-box>
                    </div>
                    <div class="price">
                      <dx-number-box
                        [(value)]="sp.price"
                        [showClearButton]="true"
                        format="$#,##0.00"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                
                </div>
                
                <div [class.indef]="pauser3.isIndefinitely" class="button">
                  <dx-button (onClick)="this.addSweetPriceByAdjustment()" text="Add"></dx-button>
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser3
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.sweetPricesByAdjustmentPauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
            
            <div class="column">
              <fieldset class="fieldset column zones custom">
                
                <legend class="legend">
                  <div>Automated Price Zones</div>
                </legend>
                
                <div [class.indef]="pauser4.isIndefinitely" class="content table">
                  
                  <div class="row header">
                    <div class="cell icon">&nbsp;</div>
                    <div class="cell nickname">Nickname</div>
                    <div class="cell price">Price</div>
                    <div class="cell range up">Down</div>
                    <div class="cell range down">Up</div>
                  </div>
                  
                  <ng-template #zone let-zone>
                    <div class="row">
                      <div class="cell icon">
                        <dx-check-box
                          (onValueChanged)="this.onChange()"
                          [(value)]="zone.enabled"
                        >
                        </dx-check-box>
                      </div>
                      <div class="cell nickname">
                        <dx-text-box
                          [disabled]="!zone.enabled"
                          [readOnly]="true"
                          [text]="zone.nickname"
                        >
                        </dx-text-box>
                      </div>
                      <div class="cell price">
                        <dx-number-box
                          [(value)]="zone.midPoint"
                          [disabled]="!zone.enabled"
                          [readOnly]="true"
                        >
                        </dx-number-box>
                      </div>
                      <div class="cell range down">
                        <dx-number-box
                          [(value)]="zone.rangeDown"
                          [disabled]="!zone.enabled"
                          [showClearButton]="true"
                        >
                        </dx-number-box>
                      </div>
                      <div class="cell range up">
                        <dx-number-box
                          [(value)]="zone.rangeUp"
                          [disabled]="!zone.enabled"
                          [showClearButton]="true"
                        >
                        </dx-number-box>
                      </div>
                    </div>
                  </ng-template>
                  
                  <ng-container *ngTemplateOutlet="zone; context: {$implicit: this.upperPoPriceZone}">
                  </ng-container>
                  
                  <ng-container *ngTemplateOutlet="zone; context: {$implicit: this.atmPriceZone}">
                  </ng-container>
                  
                  <ng-container *ngTemplateOutlet="zone; context: {$implicit: this.bottomPoPriceZone}">
                  </ng-container>
                
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser4
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.autoPriceZonesPauseTillTime"
                                           [ctx]="this">
                  </ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
          
          </div>
          
          <!--  Custom Price Zones / Hedges (x2) -->
          <div class="fieldset-row zones">
            
            <div class="column">
              <fieldset class="fieldset column zones custom">
                
                <legend class="legend">
                  <div>Custom Price Zones</div>
                </legend>
                
                <div [class.indef]="pauser5.isIndefinitely" class="content table">
                  
                  <div class="row header">
                    <div class="cell icon">&nbsp;</div>
                    <div class="cell nickname">Nickname</div>
                    <div class="cell price">Price</div>
                    <div class="cell range up">Down</div>
                    <div class="cell range down">Up</div>
                  </div>
                  
                  <ng-container *ngFor="let item of this.customPriceZones; index as i">
                    
                    <div class="row">
                      <div (click)="this.removeCustomPriceZone(i)" class="cell icon">
                        <span>x</span>
                      </div>
                      
                      <div class="cell nickname">
                        <dx-text-box
                          (onValueChanged)="this.onChange()"
                          [(value)]="item.nickname"
                          [disabled]="!item.enabled"
                        >
                        </dx-text-box>
                      </div>
                      <div class="cell price">
                        <dx-number-box
                          [(value)]="item.midPoint"
                          [disabled]="!item.enabled"
                          [min]="0"
                          [showClearButton]="true"
                        >
                        </dx-number-box>
                      </div>
                      <div class="cell range down">
                        <dx-number-box
                          [(value)]="item.rangeDown"
                          [disabled]="!item.enabled"
                          [min]="0"
                          [showClearButton]="true"
                        >
                        </dx-number-box>
                      </div>
                      <div class="cell range up">
                        <dx-number-box
                          [(value)]="item.rangeUp"
                          [disabled]="!item.enabled"
                          [min]="0"
                          [showClearButton]="true"
                        >
                        </dx-number-box>
                      </div>
                    </div>
                  
                  </ng-container>
                
                </div>
                
                <div [class.indef]="pauser5.isIndefinitely" class="button">
                  <dx-button (onClick)="this.addCustomPriceZone()" text="Add"></dx-button>
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser5
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.customPriceZonesPauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
            
            <div class="column x-2">
              <fieldset class="fieldset hedges">
                
                <legend class="legend">
                  <div>Hedges</div>
                </legend>
                
                <div [class.indef]="pauser6.isIndefinitely" class="content hedges-list">
                  
                  <fieldset class="hedges-set calls">
                    <legend class="legend">CALLS</legend>
                    <div *ngFor="let h2t of this.callHedges" class="hedge-to-track">
                      <ng-container *ngTemplateOutlet="hedge2track; context: {$implicit: h2t}"></ng-container>
                    </div>
                  </fieldset>
                  
                  
                  <fieldset class="hedges-set puts">
                    <legend class="legend">PUTS</legend>
                    <div *ngFor="let h2t of this.putHedges" class="hedge-to-track">
                      <ng-container *ngTemplateOutlet="hedge2track; context: {$implicit: h2t}"></ng-container>
                    </div>
                  </fieldset>
                
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser6
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.hedgesPauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
          
          </div>
          
          <!--  Package Comparisons (x2) / Pivots -->
          <div class="fieldset-row prices prices-2">
            
            <div class="column x-2">
              <fieldset class="fieldset packages" style="min-height: 100px">
                
                <legend class="legend">
                  <div>Package Comparisons</div>
                </legend>
                
                <div [class.indef]="pauser7.isIndefinitely" class="content">
                  <div *ngFor="let group of this.packageComparisons" class="package-list">
                    <ng-container *ngTemplateOutlet="packageToTrackGroup; context: {$implicit: group}"></ng-container>
                  </div>
                </div>
                
                <div [class.hidden]="!this.isSuperUser" class="pause">
                  <ets-pause-notifications #pauser7
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.packageComparisonsPauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
            
            <div class="column">
              <fieldset class="fieldset pivots" style="min-height: 100px">
                <legend class="legend">
                  <div class="pivots-header">
                    <div>Pivots</div>
                    <dx-switch [(value)]="this.pivotsEnabled" (onValueChanged)="this.onPivotsEnabledChanged()"></dx-switch>
                  </div>
                </legend>
                
                <div [class.indef]="pauser8.isIndefinitely || !this.pivotsEnabled" class="content">
                  <div class="pivot-settings">
                    
                    <div class="parameter direction-to-track">
                      <div class="label">Direction To Track</div>
                      <div class="input">
                        <dx-select-box [items]="['Up','Down']"
                                       [(value)]="this.pivotsDirection">
                        </dx-select-box>
                      </div>
                    </div>
                    
                    <div class="parameter direction-to-track">
                      <div class="label">Pivot Based On</div>
                      <div class="input">
                        <dx-select-box [items]="['Close','Wicks']"
                                       [(value)]="this.pivotsSource">
                        </dx-select-box>
                      </div>
                    </div>
                    
                    <div class="parameter event-to-track">
                      <div class="label">Event To Track</div>
                      <div class="input">
                        <dx-select-box [items]="['Breakout','Reversal', 'Breakout & Reversal']"
                          [(value)]="this.pivotsEventToTrack">
                        </dx-select-box>
                      </div>
                    </div>
                    
                    <div class="parameter close-or-touch">
                      <div class="label">Breakout/Reversal Type</div>
                      <div class="input">
                        <dx-select-box [items]="['On Touch','On Close']"
                          [(value)]="this.pivotsBreakoutType">
                        </dx-select-box>
                      </div>
                    </div>
                    
                    <div class="parameter timeframe">
                      <div class="label">Chart Timeframe</div>
                      <div class="input">
                        <div class="input">
                          <dx-select-box [items]="['1 min','5 min' , '10 min', '15 min']"
                            [(value)]="this.pivotsChartTimeframe">
                          </dx-select-box>
                        </div>
                      </div>
                    </div>
                    
                    <div class="parameter time-to-track">
                      <div class="label">Time To Track</div>
                      <div class="input">
                        <dx-select-box
                          [items]="['1 hour','2 hours' , '3 hours', '4 hours', '5 hours', '6 hours', 'Till Session End']"
                          [(value)]="this.pivotsTimeToTrack"
                        >
                        </dx-select-box>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="pause">
                  <ets-pause-notifications #pauser8
                                           (closed$)="this.onChange()"
                                           [(specificDateAndTime)]="this.pivotsPauseTillTime"
                                           [ctx]="this"
                  ></ets-pause-notifications>
                </div>
              
              </fieldset>
            </div>
          
          </div>
        
        </div>
      </dx-scroll-view>
    </div>
    
    <div class="divider">
      <hr/>
    </div>
    
    <div class="buttons">
      
      <div class="btn">
        <dx-button (onClick)="this.saveSettings()" [disabled]="!this.canSaveChanges" text="Save"
                   width="100%"></dx-button>
      </div>
      
      <div class="btn">
        <dx-button (onClick)="this.discardSettings()" text="Close" width="100%"></dx-button>
      </div>
    
    </div>
    
    <dx-load-panel [position]="{my: 'center', at: 'center', of: container}"
                   [visible]="this.isLoading">
    </dx-load-panel>
  
  </div>
</dx-popup>


<!-- Hedge To Track -->
<ng-template #hedge2track let-hedge>
  
  <div class="checkbox">
    <dx-check-box [(value)]="hedge.isSelected"></dx-check-box>
  </div>
  
  <div [style.color]="hedge.color" class="name">{{ hedge.name }}</div>
  
  <div class="sweet-price">
    <dx-number-box [(value)]="hedge.sweetPrice"
                   [disabled]="!hedge.isSelected"
                   [inputAttr]="{style: 'text-align: center;'}"
                   [showClearButton]="true"
                   format="$#0.00"
                   placeholder="Sweet Price"
    ></dx-number-box>
  </div>

</ng-template>

<!--Package Group -->
<ng-template #packageToTrackGroup let-group>
  
  <div class="package-items">
    <div *ngFor="let pkg of group.items" class="package-to-track">
      <ng-container *ngTemplateOutlet="packageToTrack; context: {grp: group, pkg: pkg}"></ng-container>
    </div>
  </div>
  
  <div class="package-to-track">
    <div class="checkbox hidden">
      &nbsp;
    </div>
    
    <div class="name hidden">&nbsp;</div>
    
    <div class="expiration hidden">
      &nbsp;
    </div>
    
    <div class="sweet-price" style="padding-left: 7px; width: 129px">
      <dx-button (onClick)="group.addExpiration()" [disabled]="!group.isSelected" text="Add Expiration" width="98%">
      </dx-button>
    </div>
    
    <div class="remove hidden">
      &nbsp;
    </div>
  </div>
</ng-template>

<!--Package To Track -->
<ng-template #packageToTrack let-grp="grp" let-pkg="pkg">
  
  <div [class.hidden]="pkg.isClone" class="checkbox">
    <dx-check-box [(value)]="grp.isSelected"></dx-check-box>
  </div>
  
  <div [class.hidden]="pkg.isClone" class="name">{{ pkg.name }}</div>
  
  <div class="expiration">
    <dx-select-box [(value)]="pkg.selectedExpiration"
                   [disabled]="!grp.isSelected"
                   [inputAttr]="{style: 'text-align: center;'}"
                   [items]="pkg.expirations"
                   displayExpr="name"
                   placeholder="Expiration"
    ></dx-select-box>
  </div>
  
  <div class="sweet-price">
    <dx-number-box [(value)]="pkg.sweetPrice"
                   [disabled]="!grp.isSelected"
                   [inputAttr]="{style: 'text-align: center;'}"
                   [showClearButton]="true"
                   format="$#0.00"
                   placeholder="Sweet Price"
    ></dx-number-box>
  </div>
  
  <div [class.hidden]="!pkg.isClone" class="remove">
    <div (click)="grp.removeExpiration(pkg)">
      <span>x</span>
    </div>
  </div>

</ng-template>