import { Component, OnInit } from '@angular/core';
import { GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { getSyncOverrideHistoryGridModel } from './sync-override-history-grid';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { getSyncOverrideDetailStrategiesGridModel } from './sync-override-detail-strategies-grid';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { getSyncOverrideDetailManualPositionsGridModel } from './sync-override-detail-manual-positions-grid';
import { ToastrService } from 'ngx-toastr';
import { SyncOverrideRecordDto, SyncOverrideRecordDetailDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetSyncOverrideRecords, GetSyncOverrideRecordDetails, RollbackLastOverrideOperation, DeleteSyncOverrideRecord } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';

@Component({
  selector: 'ets-sync-override-history',
  templateUrl: './sync-override-history.component.html',
  styleUrls: ['./sync-override-history.component.scss']
})
export class SyncOverrideHistoryComponent implements OnInit {
  constructor(
    private _timestampService: TimestampsService,
    private _dipslayNameService: TradingInstrumentDisplayNameService,
    private _toastr: ToastrService,
    private _shellClient: ShellClientService
  ) { }

  isVisible = false;

  historyRecordsGridModel: GridOptions;
  detailStrategiesGridModel: GridOptions;
  detailManualPositionsGridModel: GridOptions;

  strategiesGridSpace: number;
  mpGridSpace: number;

  readonly notePopupSettings = {
    note: null,
    isNotePopupVisible: false
  };

  isRollbackInProgress = false;

  private _historyRecordsGrid: GridReadyEvent;
  private _detailStrategiesGrid: GridReadyEvent;
  private _detailManualPositionsGrid: GridReadyEvent;

  ngOnInit() { 
    this.historyRecordsGridModel = getSyncOverrideHistoryGridModel.bind(this)(this._timestampService);
    this.detailStrategiesGridModel = getSyncOverrideDetailStrategiesGridModel.bind(this)();
    this.detailManualPositionsGridModel = getSyncOverrideDetailManualPositionsGridModel.bind(this)();
  }

  onGridReady(args: GridReadyEvent, grid: 'history' | 'detailStrategy' | 'detailManualPosition') {
    if (grid === 'history') {
      this._historyRecordsGrid = args;
    } else if (grid === 'detailStrategy') {
      this._detailStrategiesGrid = args;
    } else if (grid === 'detailManualPosition') {
      this._detailManualPositionsGrid = args;
    }
  }

  autoSizeColumns(grid: string): void {
    if (grid === 'history') {
      this._historyRecordsGrid.api.sizeColumnsToFit();
    } else if (grid === 'detailStrategy') {
      this._detailStrategiesGrid.api.sizeColumnsToFit();
    } else if (grid === 'detailManualPosition') {
      this._detailManualPositionsGrid.api.sizeColumnsToFit();
    }
  }

  async onShown() {
    this.mpGridSpace = 30;
    this.strategiesGridSpace = 70;

    await this._fillHistoryGrid();
  }

  onHidden() {
    this.isVisible = false;
    
    this._historyRecordsGrid.api.setRowData([]);
    this._detailStrategiesGrid.api.setRowData([]);
    this._detailManualPositionsGrid.api.setRowData([]);
  }

  showNotePopup(data: SyncOverrideRecordDto): void {
    this.notePopupSettings.note = data.note;
    this.notePopupSettings.isNotePopupVisible = true;
  }

  async onHistoryGridSelectionChanged(args: SelectionChangedEvent) {

    const selectedRows = args.api.getSelectedRows() as SyncOverrideRecordDto[];

    if (selectedRows.length === 0) {
      if (this.isVisible) {
        this._detailStrategiesGrid.api.setRowData([]);
        this._detailManualPositionsGrid.api.setRowData([]); 
      }
      return;
    }

    const data = selectedRows[0];

    try {
      this._detailStrategiesGrid.api.showLoadingOverlay();
      this._detailManualPositionsGrid.api.showLoadingOverlay();

      await this._loadHistoryRecordDetails(data);

    } catch (error) {
      this._toastr.error(error.message);
    } finally {
      this._detailStrategiesGrid.api.hideOverlay();
      this._detailManualPositionsGrid.api.hideOverlay();
    }
    
  }

  private async _loadHistoryRecordDetails(record: SyncOverrideRecordDto) {
    const qry = new GetSyncOverrideRecordDetails(record.syncOverrideRecordId);
    const details = await this._shellClient.processQuery<SyncOverrideRecordDetailDto[]>(qry);

    const manuals = details.filter(d => d.type === 'manual');
    const strats = details.filter(d => d.type === 'strategy');

    this._detailStrategiesGrid.api.setRowData(strats);
    this._detailManualPositionsGrid.api.setRowData(manuals);
  }

  private async  _fillHistoryGrid() {
    const qry = new GetSyncOverrideRecords();
    this._historyRecordsGrid.api.showLoadingOverlay();
    
    try {
      const records = await this._shellClient.processQuery<SyncOverrideRecordDto[]>(qry);
      this._historyRecordsGrid.api.setRowData(records);  
    } catch (error) {
      this._toastr.error('"Get Sync Override History Records" completed with errors');
    } finally {
      this._historyRecordsGrid.api.hideOverlay();
    }
  }

  async rollbackLastOperation() {
    let rowsCntr = 0;
    this._historyRecordsGrid.api.forEachNode( n => rowsCntr++);
    if (rowsCntr === 0) {
      this._toastr.info('No records to rollback');
      return;
    }

    const cmd = new RollbackLastOverrideOperation();

    try {
      this.isRollbackInProgress = true;
      await this._shellClient.processCommand(cmd);
      this._toastr.success('"Rollback" operation completed!');
    } catch (error) {
      this._toastr.error('"Rollback" operation completed with errors');
    } finally {
      this.isRollbackInProgress = false;
    }
  }

  async deleteRecord(data: SyncOverrideRecordDto) {
    if (!data) {
      return;
    }
    
    try {
      this.isRollbackInProgress = true;
      const cmd = new DeleteSyncOverrideRecord(data.syncOverrideRecordId);
      await this._shellClient.processCommand(cmd);
      this._historyRecordsGrid.api.applyTransaction({ remove: [data] });
    } catch (error) {
      this._toastr.error('"Delete Record" operation completed with errors');
    } finally {
      this.isRollbackInProgress = false;
    }
  }
}
