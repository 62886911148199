<div class="pad">

   <div class="container">

      <div class="section account">
         
         <p class="section-label">Account</p>
         
            
            <div class="section-input">

               <dx-select-box    [items]="this.availableAccounts"
                                 displayExpr="description" 
                                 [(value)]="this.selectedAccount"
                                 placeholder="Account"
                                 [showClearButton]="true"
                                 class="dx-input">
               </dx-select-box>
            </div>
      </div>

      <div class="section symbol">
         
         <p class="section-label">Symbol</p>
         
            <div class="section-input">

               <dx-text-box   [readOnly]="true" 
                              [value]="selectedSymbol?.displayName" 
                              (click)="this.showSymbolPicker()"
                              placeholder="Symbol"
                              class="dx-input">
               </dx-text-box>
            </div>
      </div>

      <div class="section divider"></div>

      <div class="section input-group buckets">

         <p class="section-label">Bucket</p>

         <div class="row">

            <div class="row-cell left terminal">

               <dx-select-box    [items]="this.availableTerminals"
                                 displayExpr="displayName" 
                                 [(value)]="this.selectedTerminal"
                                 (onValueChanged)="this.onTerminalChanged($event)"
                                 placeholder="Terminal"
                                 [showClearButton]="true"
                                 class="dx-input">
               </dx-select-box>
            </div>

            <div class="row-cell right portfolio">

               <dx-select-box    [items]="this.portfoliosByTerminal"
                                 displayExpr="portfolioName" 
                                 [(value)]="this.selectedPortfolio" 
                                 (onValueChanged)="this.onPortfolioChanged($event)"
                                 [showClearButton]="true"
                                 placeholder="Portfolio"
                                 class="dx-input">
               </dx-select-box>
            </div>
         </div>

         <div class="row">

            <div class="row-cell left combo">

               <dx-select-box    [items]="this.combosByPortfolio"
                                 displayExpr="comboName" 
                                 [(value)]="this.selectedCombo" 
                                 (onValueChanged)="this.onComboChanged($event)"
                                 [showClearButton]="true"
                                 placeholder="Combo"
                                 class="dx-input">
               </dx-select-box>
            </div>

            <div class="row-cell right combo-group">

               <dx-select-box    [items]="this.comboGroupsByCombo"
                                 displayExpr="comboGroupName" 
                                 [(value)]="this.selectedComboGroup" 
                                 [showClearButton]="true"
                                 placeholder="Combo Group"
                                 class="dx-input">
               </dx-select-box>
            </div>
         </div>
      </div>

      <div class="section divider"></div>

      <div class="section input-group order">
         
         <p class="section-label">Order</p>

         <div class="row">

            <div class="row-cell left type">
               
               <dx-select-box    [dataSource]="this.availableOrderTypes" 
                                 displayExpr="key"
                                 valueExpr="value"
                                 [(value)]="this.selectedOrderType"
                                 placeholder="Type"
                                 class="dx-input">
               </dx-select-box>
            </div>

            <div class="row-cell right duration">

               <dx-select-box    [dataSource]="this.availableOrderDurations" 
                                 [(value)]="this.selectedOrderDuration"
                                 displayExpr="key"
                                 valueExpr="value"
                                 placeholder="Duration"
                                 class="dx-input">
               </dx-select-box>
            </div>
         </div>

         <div class="row">

            <div class="row-cell left qty">
               
               <dx-number-box    mode="number"
                                 format="#,##0"
                                 [(value)]="this.selectedOrderQty"
                                 [showSpinButtons]="true"
                                 [useLargeSpinButtons]="false"
                                 [showClearButton]="true" 
                                 [step]="1" 
                                 [min]="0"
                                 placeholder="Qty"
                                 class="dx-input">
               </dx-number-box>      
            </div>

            <div class="row-cell right price">

               <dx-number-box    mode="number"
                                 format="#,##0.#####"
                                 [(value)]="selectedOrderPrice"
                                 [showSpinButtons]="true"
                                 [useLargeSpinButtons]="false"
                                 [showClearButton]="true"
                                 [step]="selectedSymbol?.tickSize"
                                 [min]="0"
                                 [disabled]="selectedOrderType === 2"
                                 placeholder="Price"
                                 class="dx-input">
               </dx-number-box>
            </div>
         </div>
      </div>

      <div class="section divider"></div>

      <div class="section btn buy">

         <p class="section-label price bid">{{buyButtonText}}</p>
         
            <dx-button  [disabled]="!canTrade" 
                        text="Buy"
                        width="100%"
                        height="100%"
                        (onClick)="onBuyClicked()">

               <div *dxTemplate="let data of 'content'">

                  <span class="dx-button-text" *ngIf="isOrderConfirmationPending">
                     <strong>....</strong>
                  </span>
                  
                  <span class="dx-button-text" *ngIf="!isOrderConfirmationPending">Buy</span>
               </div>
            </dx-button>
      </div>

      <div class="section last">
         <p class="section-label price last">{{last}}</p>
      </div>

      <div class="section btn sell">

         <p class="section-label price ask">{{sellButtonText}}</p>
      
               <dx-button  [disabled]="!canTrade" 
                           text="Sell"
                           width="100%"
                           height="100%"
                           (onClick)="onSellClicked()">

                  <div *dxTemplate="let data of 'content'">

                     <span class="dx-button-text" *ngIf="isOrderConfirmationPending">
                        <strong>....</strong>
                     </span>

                     <span class="dx-button-text" *ngIf="!isOrderConfirmationPending">Sell</span>
                  </div>
               </dx-button>
      </div>

      <ets-symbol-picker-dialog  [isVisible]="isSymbolPickerVisible"
                                 (instrumentSelected)="this.onSymbolSelected($event)"
                                 (closed)="this.onSymbolPickerClosed()">
      </ets-symbol-picker-dialog>
   </div>
</div>
