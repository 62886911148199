import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import { StrategyFlags } from 'projects/shared-components/strategies/strategy-flags.enum';

export class ToggleStrategyFlag implements ProtocolCommand {
  public constructor(
    public readonly strategyId: string,
    public readonly flag: StrategyFlags
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ToggleStrategyFlag`;
  }
}
