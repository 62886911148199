import {ChangeDetectorRef, EventEmitter} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {SessionService} from "projects/shared-components/authentication/session-service.service";
import {LocationService} from "projects/shared-components/location.service";
import {DetectMethodChanges, DetectSetterChanges, isValidNumber, isVoid} from "projects/shared-components/utils";
import {isNullOrUndefined} from "util";
import {Subject} from "rxjs";
import {
    PositionsStateService,
    PositionsDefaultsProvider,
    PositionsDefaultSettings
} from "../../services/positions-before-state.service";
import {AccessControlService} from "projects/shared-components/access-control-service.class";


export type DefaultSettingsMemento = {
    orderQty: number;
    protectiveOptionOverrideQty?: number;
    secondProtectiveOptionOverrideQty?: number;
    hasSecondPo: boolean;
    hasSecondSpread: boolean;
}

export type DefaultsMemento = {
    first?: DefaultSettingsMemento;
    second?: DefaultSettingsMemento;
}

export class PositionsDefaultsPopupModel {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _unsubscriber: Subject<void>,
        private readonly _bucketBeforeStateService: PositionsStateService,
        private readonly _toastr: ToastrService,
        private readonly _locationService: LocationService,
        private readonly _sessionService: SessionService,
        private readonly _accessControlService: AccessControlService
    ) {
    }

    private _portfolioId: string;

    private _strategyName: CashFlowStrategy;

    private _asset: string;

    private _originalOrderQtyMemento: DefaultsMemento;

    defaultOrderQtyUpdated$ = new EventEmitter();

    get noStrategySelected(): boolean {
        return isVoid(this._strategyName);
    }

    get isDoubleMode(): boolean {
        return this._strategyName === 'Calls & Puts'
            || this.noStrategySelected && this.selectedMode === 'Double';
    }

    // noinspection JSUnusedGlobalSymbols
    get isSecondSpreadAvailable(): boolean {
        return this._accessControlService.isSecureElementAvailable(
            '78779154-4f9c-425e-8805-d710ec52868c'
        );
    }

    // noinspection JSUnusedGlobalSymbols
    get isSecondProtectiveOptionAvailable(): boolean {
        return this._accessControlService.isSecureElementAvailable(
            '3724dd8f-725d-40a5-b673-84ced010adb2'
        );
    }

    //
    modes = ['Single', 'Double'];

    //
    selectedMode: 'Single' | 'Double' = 'Single';

    //
    get width(): number {

        const width = 355;
        if (this.isDoubleMode) {
            return width * 2;
        }

        return width;
    }

    private _isVisible: boolean;
    get isVisible(): boolean {
        return this._isVisible;
    }

    @DetectSetterChanges()
    set isVisible(v: boolean) {
        this._isVisible = v;
    }

    //
    defaults: PositionsDefaultsProvider;

    //
    show(portfolioId: string, asset: string, strategyName: CashFlowStrategy) {

        this._portfolioId = portfolioId;
        this._strategyName = strategyName;
        this._asset = asset;

        const settingsType = this.isDoubleMode
            ? 'double'
            : 'single';

        this.defaults = this._bucketBeforeStateService
            .getDefaultsProvider(
                this._portfolioId,
                asset,
                settingsType
            );

        const memento = this.getDefaultSettingsMemento(this.defaults);

        this._originalOrderQtyMemento = memento;

        this.isVisible = true;

    }

    //
    async saveSettings() {

        const isOK = this.validate();

        if (!isOK) {
            return;
        }

        await this._bucketBeforeStateService.saveDefaultsProvider(
            this._portfolioId,
            this._asset,
            this.defaults
        );

        this._toastr.success('Default settings updated', 'Positions');

        const newSnapshot = this.getDefaultSettingsMemento(this.defaults);

        this.close();

        const updatedQty = JSON.stringify(newSnapshot);
        const originalQty = JSON.stringify(this._originalOrderQtyMemento);

        if (updatedQty != originalQty) {
            this.defaultOrderQtyUpdated$.emit(newSnapshot);
        }
    }

    //
    close() {
        this.isVisible = false;
    }

    //
    onHidden() {
        this.isVisible = false;
        this.selectedMode = 'Single';
    }

    //
    private validate(): boolean {

        if (!isVoid(this.defaults.first)) {
            const error = this.validateSettings(this.defaults.first);
            if (error) {
                const header = this._strategyName === 'Calls & Puts'
                    ? 'Calls'
                    : this._strategyName;
                this._toastr.error(error, header);
                return false;
            }
        }

        if (!isVoid(this.defaults.second)) {
            const error = this.validateSettings(this.defaults.second);
            if (error) {
                const header = this._strategyName === 'Calls & Puts'
                    ? 'Puts'
                    : this._strategyName;
                this._toastr.error(error, header);
                return false;
            }
        }

        return true;
    }

    //
    private validateSettings(settings: PositionsDefaultSettings): string {

        if (isNullOrUndefined(settings.atmOffset)) {
            return '"ATM Offset" is Mandatory';
        }

        if (isNullOrUndefined(settings.spreadOffset)) {
            return '"Spread Offset" is Mandatory';
        }

        if (isVoid(settings.spreadWidth)) {
            return '"Spread Width" is Mandatory';
        }

        if (isVoid(settings.secondSpreadWidth)) {
            return '"2nd Spread Width" is Mandatory';
        }

        if (isNullOrUndefined(settings.protectiveOptionOffset)) {
            return '"Protective Option Offset" is Mandatory';
        }

        if (isNullOrUndefined(settings.secondProtectiveOptionOffset)) {
            return '"2nd Protective Option Offset" is Mandatory';
        }

        if (isNullOrUndefined(settings.spreadDaysToExpiration)) {
            return '"Spread Days to Expiration" is Mandatory';
        }

        if (isNullOrUndefined(settings.secondSpreadDaysToExpiration)) {
            return '"2nd Spread Days To Expiration" is Mandatory';
        }

        if (isNullOrUndefined(settings.protectiveOptionDaysToExpiration)) {
            if (isNullOrUndefined(settings.protectiveOptionRollXDaysAfterShorts)) {
                return '"Protective Option Must Have Either "Days To Expiration" Or "Roll [x] Days After Shorts" value';
            }
        }

        if (isNullOrUndefined(settings.protectiveOptionRollXDaysAfterShorts)) {
            if (isNullOrUndefined(settings.protectiveOptionDaysToExpiration)) {
                return '"Protective Option Must Have Either "Days To Expiration" Or "Roll [x] Days After Shorts" value';
            }
        }

        if (isNullOrUndefined(settings.secondProtectiveOptionDaysToExpiration)) {
            if (isNullOrUndefined(settings.secondProtectiveOptionRollXDaysAfterShorts)) {
                return '"2nd Protective Option Must Have Either "Days To Expiration" or "Roll [x] Days After Shorts" value';
            }
        }

        if (isNullOrUndefined(settings.secondProtectiveOptionRollXDaysAfterShorts)) {
            if (isNullOrUndefined(settings.secondProtectiveOptionDaysToExpiration)) {
                return '"2nd Protective Option Must Have Either "Days To Expiration" or "Roll [x] Days After Shorts" value';
            }
        }

        if (!isValidNumber(settings.orderQty, true)) {
            return 'Default Leg Qty is Mandatory';
        }

        if (settings.protectiveOptionOverrideQty === 0) {
            return 'Protective Option Override Qty Must Be Non Zero Number';
        }

        if (settings.secondProtectiveOptionOverrideQty === 0) {
            return '2nd Protective Option Override Qty Must Be Non Zero Number';
        }


        if (isValidNumber(settings.protectiveOptionOverrideQty) &&
            isValidNumber(settings.secondProtectiveOptionOverrideQty)
        ) {

            const totalQty = settings.protectiveOptionOverrideQty + settings.secondProtectiveOptionOverrideQty;

            if (totalQty < settings.orderQty) {
                return 'Total Qty for Protective Option Legs Must Be Equal Or Greater Than Default Leg Qty';
            }
        }

        if (isValidNumber(settings.protectiveOptionOverrideQty, true) &&
            !isValidNumber(settings.secondProtectiveOptionOverrideQty, true)
        ) {
            if (settings.protectiveOptionOverrideQty < settings.orderQty) {
                return 'Protective Option Leg Qty Must Be Equal Or Greater Than Default Leg Qty, If Provided Alone';
            }
        }

        return undefined;

    }

    //
    @DetectMethodChanges()
    onModeChanged() {
        const settingsType = this.isDoubleMode ? 'double' : 'single';
        this.defaults = this._bucketBeforeStateService
            .getDefaultsProvider(
                this._portfolioId,
                this._asset,
                settingsType
            );
    }

    private getDefaultSettingsMemento(defaults: PositionsDefaultsProvider): DefaultsMemento {
        const memento: DefaultsMemento = {};

        if (defaults.first) {
            memento.first = {
                orderQty: defaults.first.orderQty,
                protectiveOptionOverrideQty: defaults.first.protectiveOptionOverrideQty,
                secondProtectiveOptionOverrideQty: defaults.first.secondProtectiveOptionOverrideQty,
                hasSecondPo: defaults.first.includeSecondProtectiveOption || false,
                hasSecondSpread: defaults.first.includeSecondSpread || false
            };
        }

        if (defaults.second) {
            memento.second = {
                orderQty: defaults.second.orderQty,
                protectiveOptionOverrideQty: defaults.second.protectiveOptionOverrideQty,
                secondProtectiveOptionOverrideQty: defaults.second.secondProtectiveOptionOverrideQty,
                hasSecondPo: defaults.second.includeSecondProtectiveOption || false,
                hasSecondSpread: defaults.first.includeSecondSpread || false
            };
        }

        return memento;
    }

    @DetectMethodChanges()
    onChange() {

    }
}
