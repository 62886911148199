import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import {DetectMethodChanges} from "../../utils";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {HgHedgePositionsComponent} from "./hedge-positions/hg-hedge-positions.component";
import {HgPortfolioPositionsComponent} from "./portfolio-positions/hg-portfolio-positions.component";
import {ServiceConfiguration} from "../../adjustment-pricing-grid/services/ServiceConfiguration";
import {HedgePositionsService} from "./hedge-positions/hedge-positions.service";

@Component({
    selector: 'ets-hg-positions-section',
    templateUrl: 'hg-positions-section.component.html',
    styleUrls: [
        './hg-positions-section.component.scss',
        '../hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgPositionsSectionComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _hedgePositionsService: HedgePositionsService
    ) {
    }

    @Input() collapsed: boolean;

    @Output() toggleCollapsed = new EventEmitter();

    @Output() portfolioSelected = new EventEmitter<ApgPortfolio>();

    @ViewChild(HgHedgePositionsComponent) hedgePositionsCmp: HgHedgePositionsComponent;

    @ViewChild(HgPortfolioPositionsComponent) portfolioPositionsCmp: HgPortfolioPositionsComponent;

    ngOnInit() {
    }

    @DetectMethodChanges()
    onToggleCollapsed() {
        this.toggleCollapsed.emit();
    }

    async onPortfolioSelected(args: ApgPortfolio) {

        const cfg: ServiceConfiguration = {
            userId: args.userId,
            userName: args.userName,
            orientation: undefined
        };

        this._hedgePositionsService.configure(cfg);

        this.portfolioSelected.emit(args);

        await this.hedgePositionsCmp.onPortfolioSelected(args);

        await this.portfolioPositionsCmp.onPortfolioSelected(args);
    }
}