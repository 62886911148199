import { Injectable } from '@angular/core';
import { AggregatedPositionsBackendService } from 'projects/shared-components/aggregated-positions/aggregated-positions-backend.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { 
  GetTerminalWorkingOrders 
} from 'projects/shared-components/shell-communication/operations/orders/get-terminal-working-orders.class';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { 
  CloseAggregatedPosition 
} from 'projects/shared-components/shell-communication/operations/aggregated-positions/close-aggregated-position.class';
import { 
  GetAggregatedExitOrders 
} from 'projects/shared-components/shell-communication/operations/aggregated-positions/get-aggregated-exit-orders.class';
import { 
  GetAggregatedPositions 
} from 'projects/shared-components/shell-communication/operations/aggregated-positions/get-aggregated-positions.class';
import { AggregatedPositionDto } from 'projects/shared-components/shell-communication/dtos/aggregated-position-dto.class';
import { CancelManualOrders } from 'projects/shared-components/shell-communication/operations/manual-trading/cancel-manual-orders.class';
import { DeleteArchivedPositionByAggregation } from 'projects/shared-components/shell-communication/shell-operations-protocol';

@Injectable({ providedIn: 'root' })
export class WebtraderAggregatedPositionsBackendService implements AggregatedPositionsBackendService {
  constructor(private readonly _shellClient: ShellClientService) {}
  
  getTerminalWorkingOrders(qry: GetTerminalWorkingOrders): Promise<OrderDto[]> {
    return this._shellClient.processQuery(qry);
  }  
  
  closeAggregatedPosition(cmd: CloseAggregatedPosition): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }

  deleteArchivedPositionByAggregation(cmd: DeleteArchivedPositionByAggregation): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }
  
  getAggregatedExitOrders(qry: GetAggregatedExitOrders): Promise<OrderDto[]> {
    return this._shellClient.processQuery(qry);
  }
  
  getAggregatedPositions(qry: GetAggregatedPositions): Promise<AggregatedPositionDto[]> {
    return this._shellClient.processQuery(qry);
  }
  
  cancelManualOrders(cmd: CancelManualOrders): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }
}
