import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { LayoutsSortEventArgs } from './layouts-sort-event-args';
import { LayoutTab } from '../layout-tabs/layout-tab';
import { LayoutSort } from './layouts-sort';
import { DetectMethodChanges } from 'projects/shared-components/utils';

interface LayoutSortModel {
   layoutId: string;
   header: string;
   originalIx: number;
   isActive: boolean;
}

@Component({
   selector: 'ets-layouts-sort',
   templateUrl: 'layouts-sort.component.html',
   styleUrls: ['layouts-sort.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutsSortComponent implements OnInit {
   constructor(private _changeDetector: ChangeDetectorRef) {
      this.sortLayouts = new EventEmitter<LayoutsSortEventArgs>();
   }

   
   @Input() layouts: LayoutTab[];
   
   @Input() isVisible: boolean;
   
   @Output() sortLayouts: EventEmitter<LayoutsSortEventArgs>;
   
   layoutsToSort: LayoutSortModel[];
   
   
   ngOnInit() {  }

   
   onShowing() {
      this.layoutsToSort = this.layouts.map((l, ix) => {
         const retval: LayoutSortModel = {
            layoutId: l.id,
            originalIx: ix,
            header: l.header,
            isActive: l.isActive
         };
         return retval;
      });
   }


   @DetectMethodChanges()
   movePanel(panel, direction: 'up' | 'down') {
      
      const ix = this.layoutsToSort.findIndex(p => p === panel);
      
      if (ix < 0) {
         
         console.warn('panel not found');

      } else {

         let newIx = ix;

         if (newIx === 0 && direction === 'up') {
            return;
         }

         if (direction === 'up') {
            newIx -= 1;
         } else if (direction === 'down') {
            newIx += 1;
         } else {
            throw new Error('Unexpected direction');
         }

         if (newIx >= this.layoutsToSort.length) {
            return;
         }

         if (newIx < 0) {
            return;
         }

         const panelToMove = this.layoutsToSort[ix];
         const panelToReplace = this.layoutsToSort[newIx];

         this.layoutsToSort[ix] = panelToReplace;
         this.layoutsToSort[newIx] = panelToMove;
      }
   }

   
   save() {
      const sortedPanels: LayoutSort[] = this.layoutsToSort.map((layout, ix) => {
         const panel: LayoutSort = {
            layoutId: layout.layoutId,
            newIx: ix,
            originalIx: layout.originalIx
         };
         return panel;
      });

      const args: LayoutsSortEventArgs = {
         layouts: sortedPanels,
      };

      this.sortLayouts.emit(args);
   }

   @DetectMethodChanges()
   onHidden() {
      this.isVisible = false;
      this.layoutsToSort = [];
      this.sortLayouts.emit();
   }
}
