import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { centeredColumnDef } from 'projects/shared-components/ag-grid-contrib';
import { AgGridColumn } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { NotificationMessageDto } from '../shell-communication/dtos/notification-message-dto.interface';
import { TimestampsService } from '../timestamps.service';
import { JobsTrackerComponent } from './jobs-tracker.component';
import { JobProgressDto } from '../shell-communication/shell-dto-protocol';
import { isNullOrUndefined } from '../utils';

export function getJobsGridOptions(
   this: JobsTrackerComponent,
   timestampsService: TimestampsService
) {

   const columns: Partial<AgGridColumn>[] = setupColumns(timestampsService);

   return {

      rowClass: 'ets-text-centered',

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      rowGroupPanelShow: 'always',

      groupUseEntireRow: true,

      rowSelection: 'multiple',

      immutableData: true,

      suppressDragLeaveHidesColumns: true,

      getRowNodeId: (rowData: JobProgressDto) => {
         return rowData.jobId;
      },

      getRowStyle: (args) => {
         if (!args.node) {
            return;
         }

         if (!args.node.data) {
            return null;
         }

         if (args.node.data.hasErrors) {
            return { 'color': 'red' };
         }

         if (args.node.data.progress === 100 && !args.node.data.hasErrors) {
            return {'color': 'green'};
         }

         return null;
      },

      onGridReady: (args) => this.onGridReady(args),

      onGridSizeChanged: (args) => this.onGridSizeChanged(args),

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Remove Entry',
               action: () => this.removeJob(params.node.data),
               disabled: isNullOrUndefined(params.node) || params.node.group || isNullOrUndefined(params.node.data)
            },
            {
               name: 'Remove All Finished',
               action: () => this.removeAllFinishedJobs()
            },
            'seperator',
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit(),
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}

function setupColumns(timestampsService: TimestampsService): Partial<AgGridColumn>[] {
   const cols: Partial<AgGridColumn>[] = [];

   if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      cols.push({
         headerName: 'Client',
         field: 'clientName',
         enableRowGroup: true
      });

      cols.push({
         headerName: 'Shell',
         field: 'shellName',
         enableRowGroup: true
      });
   }

   cols.push(
      {
         headerName: 'Last Update',
         field: 'timestamp',
         sort: 'desc',
         valueFormatter(params: ValueFormatterParams) {
            const msg: NotificationMessageDto = params.data;
            const frmtTime = timestampsService.getDefaultFormattedDateTime(msg.timestamp);
            return msg.timestamp
               ? frmtTime
               : '';
         },
         width: 200
      },
      {
         headerName: 'Job Name',
         field: 'jobName',
         width: 210
      },
      {
         headerName: 'Progress',
         field: 'progress',
         width: 100,
         valueFormatter: (args: ValueFormatterParams) => {
            if (!args.value) {
               return '--';
            }
            return `${args.value}%`;
         }
      },
      {
         headerName: 'Status',
         field: 'message',
         headerComponentParams: {},
         cellStyle: { 'text-align': 'left' },
         flex: 1
      }
   );

   return cols;
}
