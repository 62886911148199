import { Component, Input, OnInit } from '@angular/core';
import { Handlers, StrategyCommanderEditorParameters } from '../default-editor-parameters';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';

@Component({
  selector: 'moving-average-crossover',
  template: `
    <dx-text-box
      style="margin: auto; margin-top: 20px"
      width="90%"
      [readOnly]="true"
      [value]="commonParameters.selectedInstrument?.displayName"
      (click)="commonParameters.isSymbolPickerVisible = true"
      [placeholder]="'Pick a Symbol...'"
    ></dx-text-box>
    
    <div style="margin-top: 10px; margin-bottom: 5px">Timeframe</div>
    <dx-select-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      [items]="['1', '5', '15', '30', '60', '120', '240', 'D']"
      [(value)]="specificParameters.timeframe"
    ></dx-select-box>
    
    <div style="margin-top: 10px; margin-bottom: 5px">MA #1 Period:</div>
    <dx-number-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      [(value)]="specificParameters.ma1Period"
    ></dx-number-box>
    
    <div style="margin-top: 10px; margin-bottom: 5px">MA #2 Period:</div>
    <dx-number-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      [(value)]="specificParameters.ma2Period"
    ></dx-number-box>
    
    <div style="margin-top: 10px; margin-bottom: 5px">Crossover direction</div>
    <dx-select-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      [items]="['Up', 'Down']"
      [(value)]="specificParameters.direction"
    ></dx-select-box>

    <ets-symbol-picker-dialog
      [(isVisible)]="commonParameters.isSymbolPickerVisible"
      (closed)="commonParameters.isSymbolPickerVisible = false"
      (instrumentSelected)="commonParameters.selectedInstrument = $event">
    </ets-symbol-picker-dialog>
  `
})

export class MovingAverageCrossoverComponent implements OnInit {
  constructor() {
  }

  @Input()
  commonParameters: any;
  
  @Input()
  specificParameters: any;

  ngOnInit() {
  }

  public static fillParameters(parameters: string, paramsObj: StrategyCommanderEditorParameters, tradingInstrumentService: TradingInstrumentsService) {
    const handlerParams: Handlers.MovingAverageCrossover = JSON.parse(parameters);

    paramsObj.handlers.maCrossover = handlerParams;

    const ti = tradingInstrumentService.getInstrumentByTicker(handlerParams.ticker);
    paramsObj.selectedInstrument = ti;

  }

  public static getParameters(paramsObj: Handlers.MovingAverageCrossover) {
    return JSON.stringify(paramsObj);
  }

  public static checkParameters(parameters: Handlers.MovingAverageCrossover): boolean {
    if (!parameters.timeframe) {
      return false;
    }

    if (!parameters.direction) {
      return false;
    }

    const ma1Period = parameters.ma1Period;
    if ( ma1Period === null || ma1Period <= 0) {
      return false;
    }

    const ma2Period = parameters.ma2Period;
    if ( ma2Period === null || ma2Period <= 0) {
      return false;
    }

    return true;
  }
}