<div class="ets-workspace" id="ws_{{workspaceId}}">
  <ets-workspace-panels-menu
      [style.top.px]="addPanelContextMenu.top"
      [style.left.px]="addPanelContextMenu.left"
      [maxHeight]="addPanelContextMenu.maxHeight"
      [items]="addPanelContextMenu.items"
      class="ets-addpanel-menu"
      menuKey="add-panel"
      [originKey]="workspaceId"
      *ngIf="addPanelContextMenu.isOpened"
  ></ets-workspace-panels-menu>

  <ets-workspace-panels-menu
      [style.top.px]="tabsMenuContextMenu.top"
      [style.left.px]="tabsMenuContextMenu.left"
      [maxHeight]="tabsMenuContextMenu.maxHeight"
      [items]="tabsMenuContextMenu.items"
      class="ets-tabslist-menu"
      menuKey="panels-list"
      [originKey]="workspaceId"
      *ngIf="tabsMenuContextMenu.isOpened"
  ></ets-workspace-panels-menu>

  <ets-workspace-header
      [panels]="panels"
      (addPanelContextMenuRequest)="onAddPanelContextMenuRequest($event)"
      (tabsMenuContextMenuRequest)="onTabsMenuContextMenuRequest($event)"
      (sortPanels)="onSortPanelsRequest()"
      (splitRequest)="onSplitRquest($event)"
      (expanded)="onExpanded()"
      (panelSelected)="selectedPanel = $event"
      (panelClosed)="onPanelClosed($event)"
      (symbolLinkChanged)="onSymbolLinkChanged($event)"
      (panelHeaderChanged)="onPanelHeaderChanged($event)"
      [isWorkspaceExpanded]="isWorkspaceExpanded"
  ></ets-workspace-header>

  <div class="ets-workspace-content">
      <ng-template ets-content-placeholder></ng-template>
  </div>

  <ets-pnl-chart-dialog
      *ngIf="isChartPickerDialogVisible"
      (closed)="isChartPickerDialogVisible = false"
      (chartItemSelected)="onChartItemSelected($event)"
  ></ets-pnl-chart-dialog>

  <menu #ctx class="menu">
    <li class="menu-item">
      <div [ngClass]="{'ets-hidden': !securityContext.canSplitWorkspace}">
        <button type="button" class="menu-btn" (click)="onSplitClicked('vertical')">
          <span class="menu-text">Split Horizontally</span>
        </button>
      </div>
    </li>
    <li class="menu-item">
      <div [ngClass]="{'ets-hidden': !securityContext.canSplitWorkspace}">
        <button type="button" class="menu-btn" (click)="onSplitClicked('horizontal')">
          <span class="menu-text">Split Vertically</span>
        </button>
      </div>
    </li>
    <li class="menu-item">
      <div [ngClass]="{'ets-hidden': !securityContext.canCollapseWorkspace}">
        <button type="button" class="menu-btn" (click)="onSplitClicked('close')">
          <span class="menu-text">Close Workspace</span>
        </button>
      </div>
    </li>
  </menu>

  <ets-panels-sort [workspaceId]="workspaceId" 
                   [workspacePanels]="panels"
                   [isVisible]="isPanelSortDialogVisible"
                   (sortPanels)="onSortPanelsCompleted($event)"
  ></ets-panels-sort>

</div>