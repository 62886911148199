import { Injectable } from '@angular/core';
import { MessageBusService } from './message-bus.service';
import { ShellClientService } from './shell-communication/shell-client.service';
import { SessionService } from './authentication/session-service.service';
import { AuthTokenExpiredUIMessage } from './ui-messages/auth-token-expired-ui-message.interface';
import { Logger } from './logging/logger.interface';
import { CheckShellStatus } from './shell-communication/operations/check-shell-status.class';
import { ShellStatusPollingUIMessage } from './ui-messages/shell-status-polling-ui-message.interface';
import { environment } from 'projects/shared-components/environments/environment';
import { AvailableShellDto } from './authentication/dtos/available-shell-dto.interface';
import { EtsConstants } from './ets-constants.const';
import { LoggerService } from './logging/logger-factory.service';
import { ShellConnectionStatusChangedUIMessage } from './ui-messages/shell-connection-status-changed-ui-message.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare function setInterval(callback: () => void, timeout: number): number;

@Injectable({ providedIn: 'root' })
export class ShellStatusPollingService {
  constructor(
    private _shellClient: ShellClientService,
    private _messageBus: MessageBusService,
    private _sessionService: SessionService,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger('ShellStatusPollingService');
  }

  private readonly _availableShells: AvailableShellDto[] = [];
  private readonly _pollingCommand = new CheckShellStatus();
  private _logger: Logger;
  private _pollingWorkerRef: number;
  private _unsubscriber: Subject<any>;

  init(): Promise<void> {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
    }
    
    this._unsubscriber = new Subject();
    
    this._availableShells.length = 0;
    
    if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      const sessionData = this._sessionService.sessionData;
      this._availableShells.push(...sessionData.availableShells);
    } else {
      const shell = this._sessionService.connectedShell;
      this._availableShells.push(shell);
    }

    // this subscription covers case, when we need to determine target shell for request
    // but because of the lag of polling interval, actual shell's status will be outdated. 
    this._messageBus.of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(message => {
        if (!message.payload.isConnected) {
          return;
        }
        if (this._availableShells.length > 0) {
          const matchingShell = this._availableShells.find(shell => shell.shellId === message.payload.shellId );
          if (matchingShell) {
            matchingShell.isOnline = true;
          }
        }
      });

    // explicitly poll shell status at initialization, or we can get errors
    // on layout restoration phase
    return this._pollShellStatus().then(() => {
      
      this._pollingWorkerRef = setInterval(() => this._pollShellStatus(), environment.shellStatusPollingInterval);
      
      this._messageBus
        .of<AuthTokenExpiredUIMessage>('AuthTokenExpiredUIMessage')
        .subscribe((msg) => this._onAuthTokenExpiredUIMessage(msg.payload));
      
      this._logger.info('Polling started');
    });
  }

  getOnlineShells(): AvailableShellDto[] {
    const onlineShells = this._availableShells.filter(shell => shell.isOnline);
    if (onlineShells.length === 0) {
      this._logger.warn('Client requested online shells, but no shell is online');
    }
    return onlineShells;
  }

  private _onAuthTokenExpiredUIMessage(msg: AuthTokenExpiredUIMessage) {
    this._logger.info('Received message that auth. token is expired');
    this._dispose();

  }

  private async _pollShellStatus(): Promise<void> {
    if (!this._sessionService.isAuthenticated) {
      return;
    }

    if (this._availableShells.length === 0) {
      this._logger.error('No shell for polling');
      return;
    }

    const promises = this._availableShells.map(shell => {
      const p = new Promise((res, rej) => {
        this._shellClient
          .processCommand(this._pollingCommand, shell.shellId)
          .then(() => {
            shell.isOnline = true;
            this._messageBus.publish({
              topic: 'ShellStatusPollingUIMessage',
              payload: {
                clientId: shell.clientId,
                clientName: shell.clientName,
                shellId: shell.shellId,
                shellName: shell.displayName,
                isOnline: true
              } as ShellStatusPollingUIMessage
            });
          })
          .catch(error => {
            shell.isOnline = false;
            this._logger.info('Polling error. Status: ' + error.status);
            if (error.status === 401) {
              this._dispose();
              this._messageBus.publish({
                topic: 'AuthTokenExpiredUIMessage',
                payload: {source: 'ShellStatusPollingService'}
              });
            } else {
              this._messageBus.publish({
                topic: 'ShellStatusPollingUIMessage',
                payload: {
                  clientId: shell.clientId,
                  clientName: shell.clientName,
                  shellId: shell.shellId,
                  shellName: shell.displayName,
                  isOnline: false
                } as ShellStatusPollingUIMessage
              });
            }
          })
          .finally(() => res(null) );
      });

      return p;
    });

    await Promise.all(promises);
  }

  private _dispose() {
    const pollerRef = this._pollingWorkerRef;
    
    this._pollingWorkerRef = null;

    this._availableShells.length = 0;

    if (pollerRef) {
      clearInterval(pollerRef);
      this._logger.info('Polling stopped');
    }

    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
    }
    
  }
}
