import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExpirationSmartModeSettingsComponent } from './expiration-smart/expiration-smart-settings.component';
import { OrderTypeSettingsComponent } from './order-type/order-type-settings.component';
import { RollTimeSettingsComponent } from './roll-time/roll-time-settings.component';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ComboDto, GetGlobalSettingsSectionDataModelReply, SweetSpotAdjustmentSettingsDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';
import { GetGlobalSettingsSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ToastrService } from 'ngx-toastr';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { ComparisonPricingSettingsComponent } from './comparison-pricing/comparison-pricing-settings.component';
import { SweetspotAdjustmentComponent } from './sweetspot/sweetspot-adjustment.component';

@Component({
   selector: 'ets-automation-cp-body-global-settings-section',
   templateUrl: './global-settings-section.component.html',
   styleUrls: ['./global-settings-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSettingsSectionComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();


   //
   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   header = 'Global Settings';

   //
   private _combo: ComboDto;
   get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   //
   @ViewChild('expirationSmartMode', { static: false })
   expirationSmartMode: ExpirationSmartModeSettingsComponent;

   //
   @ViewChild('orderTypeSettings', { static: false })
   orderTypeSettings: OrderTypeSettingsComponent;

   //
   @ViewChild('rollSettings', { static: false })
   rollSettings: RollTimeSettingsComponent;

   //
   @ViewChild('comparisonPricingSettings', {static: false})
   comparisonPricingSettings: ComparisonPricingSettingsComponent;

   //
   @ViewChild('sweetSpot', {static: false})
   sweetSpotAdjustmentSettings: SweetspotAdjustmentComponent;

   //
   ngOnInit(): void {
      this._cpMessageBus.when('AdjustmentStrategyCreated')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onComboSelected());

      this._cpMessageBus.when('AdjustmentStrategyRemoved')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.reset());

   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {
      this.reset();
      
      if (!this.combo) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetGlobalSettingsSectionDataModel(
            this.combo.comboId,
         );

         const reply = await this._shellClient.processQuery<GetGlobalSettingsSectionDataModelReply>(qry);

         if (reply.hasErrors) {
            this._toastr.error('Global Setings block loaded with errors');
            return;
         }

         if (this.rollSettings) {
            this.rollSettings.setData(reply.adjustmentTimeSettings);
         }

         if (this.orderTypeSettings) {
            this.orderTypeSettings.setData(reply.orderTypeSettings);
         }

         if (this.expirationSmartMode) {
            this.expirationSmartMode.setData(reply.expirationSmartMode);
         }

         if (this.comparisonPricingSettings) {
            this.comparisonPricingSettings.setData(reply.comparisonPricingSettings);
         }

         if (this.sweetSpotAdjustmentSettings) {
            this.sweetSpotAdjustmentSettings.setData(reply.sweetSpotAdjustmentSettings);
         }

      } catch (e) {

         this._toastr.error(
            '"Load Overview Data" operation completed with errors',
            'Overview'
         );

      } finally {

         this.isLoading = false;

      }
   }

   //
   @DetectMethodChanges()
   reset() {
      if (this.rollSettings) {
         this.rollSettings.reset();
      }

      if (this.orderTypeSettings) {
         this.orderTypeSettings.reset();
      }

      if (this.expirationSmartMode) {
         this.expirationSmartMode.reset();
      }

      if (this.comparisonPricingSettings) {
         this.comparisonPricingSettings.reset();
      }

      if (this.sweetSpotAdjustmentSettings) {
         this.sweetSpotAdjustmentSettings.reset();
      }
   }

   //
   getParameters(): GetGlobalSettingsSectionDataModelReply {
      const rollSettings = this.rollSettings.getParameters();
      const orderSettings = this.orderTypeSettings.getParameters();
      const expSmartMode = this.expirationSmartMode.getParameters();
      const comparisonPricing = this.comparisonPricingSettings.getParameters();
      const sweetSpotAdjustment = this.sweetSpotAdjustmentSettings.getParameters();

      const reply: GetGlobalSettingsSectionDataModelReply = {
         adjustmentTimeSettings: rollSettings,
         orderTypeSettings: orderSettings,
         expirationSmartMode: expSmartMode,
         comparisonPricingSettings: comparisonPricing,
         sweetSpotAdjustmentSettings: sweetSpotAdjustment
      };

      return reply;
   }
}
