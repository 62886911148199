import { StrategyModel } from '../strategies/strategy-model';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { GridReadyEvent, GridOptions } from 'ag-grid-community';
import { AccountOverrideOptions, TickerOverrideOptions } from './sync-override-dialog-types';
import { Component, OnInit, ViewChild } from '@angular/core';
import { getSyncOverrideGridModel } from './sync-override-trading-systems-grid';
import { TradingInstrumentDisplayNameService } from '../trading-instruments/trading-instrument-display-name.service';
import { AccessControlService } from '../access-control-service.class';
import { getSyncOverrideManualPositionsGrid } from './sync-override-manual-positions-grid';
import { StrategiesService } from '../strategies/strategies.service';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetManualPositions } from '../shell-communication/operations/manual-trading/get-manual-positions.class';
import { SyncOverrideHistoryComponent } from './history/sync-override-history.component';
import { SyncOverride } from '../shell-communication/shell-operations-protocol';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { StrategyPositionModel } from '../strategies/strategy-position-model.class';
import { ToastrService } from 'ngx-toastr';

interface SecurityContext {
   systemDetails: boolean;
}

@Component({
   selector: 'ets-sync-override-dialog',
   templateUrl: 'sync-override-dialog.component.html',
   styleUrls: ['./sync-override-dialog.component.scss']
})
export class SyncOverrideDialogComponent implements OnInit {

   constructor(
      private _displaNameService: TradingInstrumentDisplayNameService,
      private _accessControlService: AccessControlService,
      private _strategiesService: StrategiesService,
      private _shellClient: ShellClientService,
      private _tiService: TradingInstrumentsService,
      private _toastr: ToastrService
   ) { }

   @ViewChild(SyncOverrideHistoryComponent, { static: true }) syncHistory: SyncOverrideHistoryComponent;
   isVisible = false;
   isCalculating = false;
   isOverriding = false;

   securityContext: SecurityContext;
   availableTradingSystems: StrategyModel[] = [];
   availableManualPositions: PositionDto[] = [];
   selectedTradingSystems: StrategyModel[] = [];
   selectedManualPositions: PositionDto[] = [];
   operationNote: string;
   accountOverrideOptions: AccountOverrideOptions[] = [];
   shellTotal: number;

   availableTradingSystemsGridOptions: GridOptions;
   availableManualPositionsGridOptions: GridOptions;
   selectedTradingSystemsGridOptions: GridOptions;
   selectedManualPositionsGridOptions: GridOptions;


   private _availableTradingSystemsGrid: GridReadyEvent;
   private _availableManualPositionsGrid: GridReadyEvent;
   private _selectedTradingSystemsGrid: GridReadyEvent;
   private _selectedManualPositionsGrid: GridReadyEvent;

   ngOnInit() {
      this.securityContext = {
         systemDetails: true
      };

      this.availableTradingSystemsGridOptions = getSyncOverrideGridModel.bind(this)('aTS');
      this.availableManualPositionsGridOptions = getSyncOverrideManualPositionsGrid.bind(this)(this._displaNameService, 'aMP');
      this.selectedTradingSystemsGridOptions = getSyncOverrideGridModel.bind(this)('sTS');
      this.selectedManualPositionsGridOptions = getSyncOverrideManualPositionsGrid.bind(this)(this._displaNameService, 'sMP');
   }

   private _fillStrategies() {
      this._availableTradingSystemsGrid.api.showLoadingOverlay();
      try {
         const strats = this._strategiesService.getAllStrategies();
         this.availableTradingSystems.push(...strats);
         this._availableTradingSystemsGrid.api.setRowData(this.availableTradingSystems);
      } finally {
         this._availableTradingSystemsGrid.api.hideOverlay();
      }
   }

   private async _fillManualPositions() {
      this._availableManualPositionsGrid.api.showLoadingOverlay();
      try {
         const query = new GetManualPositions();
         const dtos = await this._shellClient.processQuery<PositionDto[]>(query);
         this.availableManualPositions.push(...dtos);
         this._availableManualPositionsGrid.api.setRowData(dtos);
      } finally {
         this._availableManualPositionsGrid.api.hideOverlay();
      }
   }

   onDialogShown() {

   }

   onDialogHidden() {
      this.availableTradingSystems = [];
      this.availableManualPositions = [];
      this.selectedTradingSystems = [];
      this.selectedManualPositions = [];
      this.operationNote = null;
      this.accountOverrideOptions = [];

      this._availableTradingSystemsGrid.api.setRowData([]);
      this._availableManualPositionsGrid.api.setRowData([]);
      this._selectedManualPositionsGrid.api.setRowData([]);
      this._selectedTradingSystemsGrid.api.setRowData([]);

      this.shellTotal = null;
   }

   onGridReady(args: GridReadyEvent, gridType: 'aTS' | 'aMP' | 'sTS' | 'sMP'): void {
      if (gridType === 'aTS') {
         this._availableTradingSystemsGrid = args;
      } else if (gridType === 'aMP') {
         this._availableManualPositionsGrid = args;
      } else if (gridType === 'sTS') {
         this._selectedTradingSystemsGrid = args;
      } else if (gridType === 'sMP') {
         this._selectedManualPositionsGrid = args;
      }
   }

   autoSizeColumns(gridType: 'aTS' | 'aMP' | 'sTS' | 'sMP'): void {
      if (gridType === 'aTS') {
         this._availableTradingSystemsGrid.columnApi.autoSizeAllColumns();
      } else if (gridType === 'aMP') {
         this._availableManualPositionsGrid.columnApi.autoSizeAllColumns();
      } else if (gridType === 'sTS') {
         this._selectedTradingSystemsGrid.columnApi.autoSizeAllColumns();
      } else if (gridType === 'sMP') {
         this._selectedManualPositionsGrid.columnApi.autoSizeAllColumns();
      }
   }

   selectTradingSystem() {
      if (this.accountOverrideOptions.length > 0) {
         this.accountOverrideOptions = [];
      }

      let selectedRows = this._availableTradingSystemsGrid.api.getSelectedRows() as StrategyModel[];

      if (selectedRows.length === 0) {
         selectedRows = this.availableTradingSystems.slice();
      }


      selectedRows.forEach((row: StrategyModel) => {
         this.selectedTradingSystems.push(row);

         const ix = this.availableTradingSystems.findIndex(x => x.strategyId === row.strategyId);
         if (ix >= 0) {
            this.availableTradingSystems.splice(ix, 1);
         }
      });

      this._availableTradingSystemsGrid.api.setRowData(this.availableTradingSystems);
      this._selectedTradingSystemsGrid.api.setRowData(this.selectedTradingSystems);
   }

   deselectTradingSystem() {
      if (this.accountOverrideOptions.length > 0) {
         this.accountOverrideOptions = [];
      }

      let selectedRows = this._selectedTradingSystemsGrid.api.getSelectedRows() as StrategyModel[];

      if (selectedRows.length === 0) {
         selectedRows = this.selectedTradingSystems.slice();
      }


      selectedRows.forEach((row: StrategyModel) => {
         this.availableTradingSystems.push(row);

         const ix = this.selectedTradingSystems.findIndex(x => x.strategyId === row.strategyId);
         if (ix >= 0) {
            this.selectedTradingSystems.splice(ix, 1);
         }
      });

      this._availableTradingSystemsGrid.api.setRowData(this.availableTradingSystems);
      this._selectedTradingSystemsGrid.api.setRowData(this.selectedTradingSystems);
   }

   selectManualPosition() {

      if (this.accountOverrideOptions.length > 0) {
         this.accountOverrideOptions = [];
      }

      let selectedRows = this._availableManualPositionsGrid.api.getSelectedRows() as PositionDto[];

      if (selectedRows.length === 0) {
         selectedRows = this.availableManualPositions.slice();
      }

      selectedRows.forEach((row: PositionDto) => {
         this.selectedManualPositions.push(row);

         const ix = this.availableManualPositions.findIndex(x => x.positionId === row.positionId);
         if (ix >= 0) {
            this.availableManualPositions.splice(ix, 1);
         }
      });

      this._availableManualPositionsGrid.api.setRowData(this.availableManualPositions);
      this._selectedManualPositionsGrid.api.setRowData(this.selectedManualPositions);
   }

   deselectManualPosition() {
      if (this.accountOverrideOptions.length > 0) {
         this.accountOverrideOptions = [];
      }

      let selectedRows = this._selectedManualPositionsGrid.api.getSelectedRows() as PositionDto[];

      if (selectedRows.length === 0) {
         selectedRows = this.selectedManualPositions.slice();
      }

      selectedRows.forEach((row: PositionDto) => {
         this.availableManualPositions.push(row);

         const ix = this.selectedManualPositions.findIndex(x => x.positionId === row.positionId);
         if (ix >= 0) {
            this.selectedManualPositions.splice(ix, 1);
         }
      });

      this._availableManualPositionsGrid.api.setRowData(this.availableManualPositions);
      this._selectedManualPositionsGrid.api.setRowData(this.selectedManualPositions);
   }

   recalculateTotals() {
      this.isCalculating = true;

      if (this.accountOverrideOptions.length === 0) {
         setTimeout(() => {
            try {
               this._fillTotalsPanel();
            } finally {
               this.isCalculating = false;
            }
         });
      } else {
         setTimeout(() => {
            try {
               this._updateTotalsPanel();
            } finally {
               this.isCalculating = false;
            }
         });
      }
   }

   async overridePositions() {
      const strategies = this.selectedTradingSystems.map(ts => ts.strategyId);
      const mps = this.selectedManualPositions.map(mp => mp.positionId);
      const overrideOptions = this.accountOverrideOptions.slice();
      const note = this.operationNote;

      if (!note) {
         this._toastr.warning('Please, provide note for this operation');
         return;
      }

      if (strategies.length === 0 && mps.length === 0) {
         this._toastr.warning('Nothing to override! Please provide strategies and/or manual positions');
         return;
      }

      if (overrideOptions.length === 0) {
         this._toastr.warning('Please provide sync prices');
         return;
      }

      try {
         this.isOverriding = true;
         const cmd = new SyncOverride(overrideOptions, strategies, mps, note);
         await this._shellClient.processCommand(cmd);
         this._toastr.success('"Sync Override" operation completed!');
      } catch (error) {
         this._toastr.error('"Sync Override" operation completed with errors');
      } finally {
         this.availableManualPositions = [];
         this.selectedManualPositions = [];
         this.availableTradingSystems = [];
         this.selectedTradingSystems = [];
         this.accountOverrideOptions = [];
         this.operationNote = null;

         this._availableTradingSystemsGrid.api.setRowData([]);
         this._selectedTradingSystemsGrid.api.setRowData([]);
         this._availableManualPositionsGrid.api.setRowData([]);
         this._selectedManualPositionsGrid.api.setRowData([]);

         this.isOverriding = false;
      }
   }

   closeDialog() {

   }

   onLoadDataClicked() {
      this._fillManualPositions();
      this._fillStrategies();
   }

   onShowHistoryClicked() {
      this.syncHistory.isVisible = true;
   }

   onCloseClicked() {
      this.isVisible = false;
   }

   private _updateTotalsPanel() {
      this.accountOverrideOptions.forEach(aro => {

         aro.accountTotal = 0;

         const accountId = aro.accountId;

         aro.tickers.forEach(tro => {
            const ticker = tro.ticker;
            const syncPrice = tro.syncPrice;
            const pointValue = tro.pointValue;

            this.selectedManualPositions.forEach(mp => {
               if (mp.accountId !== accountId) {
                  return;
               }

               if (mp.ticker !== ticker) {
                  return;
               }

               if (!mp.netPosition) {
                  return;
               }

               const deltaPnL = (syncPrice - mp.avgPx) * pointValue * mp.netPosition;
               const newPnL = mp.accumulatedTotalPnL + deltaPnL;

               aro.accountTotal += newPnL;
            });

            this.selectedTradingSystems.forEach(ts => {
               ts.positions.getPositions().forEach(strategyPosition => {

                  if (strategyPosition.isArchived) {
                     return;
                  }

                  if (!strategyPosition.netPosition) {
                     return;
                  }

                  if (strategyPosition.accountId !== accountId) {
                     return;
                  }

                  if (strategyPosition.ticker !== ticker) {
                     return;
                  }

                  const deltaPnL = (syncPrice - strategyPosition.avgPx) * pointValue * strategyPosition.netPosition;
                  const newPnL = strategyPosition.accumulatedTotalPnL + deltaPnL;

                  aro.accountTotal += newPnL;

               });
            });
         });
      });
   }

   private _fillTotalsPanel() {
      const accountOverrideOptions: AccountOverrideOptions[] = [];

      this.selectedTradingSystems.forEach(strategy => {
         strategy.positions.getPositions().forEach(strategyPosition => this._processPosition(strategyPosition, accountOverrideOptions));
      });

      this.selectedManualPositions.forEach(manualPosition => this._processPosition(manualPosition, accountOverrideOptions));

      this.accountOverrideOptions = accountOverrideOptions;
   }

   private _processPosition(position: PositionDto | StrategyPositionModel, accountOverrideOptions: AccountOverrideOptions[]) {
      if (position.isArchived || !position.netPosition) {
         return;
      }

      const postiionAccountId = position.accountId;
      const existingAccountIx = accountOverrideOptions.findIndex(aoo => aoo.accountId === postiionAccountId);
      let currentAccountOverrideOption: AccountOverrideOptions = null;

      if (existingAccountIx > -1) {
         currentAccountOverrideOption = accountOverrideOptions[existingAccountIx];
      } else {
         currentAccountOverrideOption = { tickers: [], accountTotal: 0 } as any;
         currentAccountOverrideOption.accountId = position.accountId;
         currentAccountOverrideOption.accountCode = position.accountCode;

         accountOverrideOptions.push(currentAccountOverrideOption);
      }

      const positionTicker = position.ticker;
      const existingTickerIx = currentAccountOverrideOption.tickers.findIndex(t => t.ticker === positionTicker);
      let currentTicker: TickerOverrideOptions = null;

      if (existingTickerIx > -1) {
         currentTicker = currentAccountOverrideOption.tickers[existingTickerIx];
      } else {
         currentTicker = { netPosition: 0 } as any;

         currentTicker.ticker = position.ticker;

         const ti = this._tiService.getInstrumentByTicker(position.ticker);
         currentTicker.displayName = ti ? ti.displayName : 'N/A';

         currentTicker.tickSize = ti ? ti.tickSize : 1;
         currentTicker.pointValue = ti ? ti.pointValue : 0;
         currentAccountOverrideOption.tickers.push(currentTicker);
      }

      currentTicker.netPosition += position.netPosition || 0;
   }
}
