import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { PanelModel } from '../../../panel.model';
import { WorkspaceSecurityContextService } from 'projects/shared-components/workspace/workspace-security-context.service';

@Component({
   selector: 'ets-tab-header',
   templateUrl: './tab-header.component.html',
   styleUrls: ['./tab-header.component.scss'],
   providers: [WorkspaceSecurityContextService]
})
export class EtsTabHeaderComponent implements OnInit, AfterViewInit {
   constructor(
      readonly securityContext: WorkspaceSecurityContextService,
      private readonly _selfRef: ElementRef
   ) {
      this.tabClose = new EventEmitter<PanelModel>();
      this.addedToPanel = new EventEmitter<{ tab: PanelModel, newWidth: number }>();
      this.headerChanged = new EventEmitter<PanelModel>();
      this.symbolLinkChanged = new EventEmitter<boolean>();
   }

   @Output() tabClose;
   @Output() symbolLinkChanged: EventEmitter<boolean>;
   @Output() addedToPanel;
   @Output() headerChanged: EventEmitter<PanelModel>;
   @Input() tab: PanelModel;
   
   isEditing = false;


   ngOnInit() { }

   onCloseClick(): void {
      this.tabClose.emit(this.tab);
   }

   ngAfterViewInit() {
      const sr = this._selfRef.nativeElement.offsetWidth;
      this.addedToPanel.emit({ tab: this.tab, newWidth: sr });
   }

   onDoubleClick(ev): void {
      this.isEditing = !this.isEditing;
   }

   onKeyUp(ev) {
      if (ev.event.key === 'Escape') {
         this.isEditing = false;
      }
   }

   onTextChanged(ev) {
      this.tab.header = ev.value;
      this.isEditing = false;
      this.headerChanged.emit(this.tab);
   }

   onSymbolLinkedClicked() {
      this.tab.isLinkedToSymbol = !this.tab.isLinkedToSymbol;
      this.symbolLinkChanged.emit(this.tab.isLinkedToSymbol);
   }
}
