<dx-scroll-view direction="vertical" [ngClass]="{'ets-hidden': !isActive}">

   <div class="panel-container" #container [class.changed]="this.hasChanges">

      <div class="leg" *ngIf="showLegHeader">
         <table class="table">
            <colgroup>
               <col span="1" name="symbol" style="width: 25%;">
               <col span="1" name="portfolio" style="width: 25%;">
               <col span="1" name="combo" style="width: 25%;">
               <col span="1" name="group" style="width: 25%;">
            </colgroup>
            <thead>
               <tr class="header">
                  <th>Symbol</th>
                  <th>Portfolio</th>
                  <th>Combo</th>
                  <th>Combo Group</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td class="part">{{this.leg?.tickerDisplayName || '--'}}</td>
                  <td class="part">{{this.leg?.portfolioName || '--'}}</td>
                  <td class="part">{{this.leg?.comboName || '--'}}</td>
                  <td class="part">{{this.leg?.comboGroupName || '--'}}</td>
               </tr>
            </tbody>
         </table>
      </div>
   
      <div class="parameters">
         <fieldset>
            <legend>Parameters</legend>
   
            <div class="params default" *ngIf="!this.strategy && !this.orderModel.order">
               <p>Strategy Not Selected</p>
            </div>
   
            <div class="params" *ngIf="this.strategy?.algoId === '2b77bb62-42d1-4bb8-8d85-d84b8c636748'">
               <ets-put-spread-strategy [parameters]="this.putSpreadParameters"></ets-put-spread-strategy>
            </div>
            
            <div class="params" *ngIf="this.strategy?.algoId === '66e80323-139f-490b-a4a7-2d2a4da7dcf0'">
               <ets-long-option-strategy [parameters]="this.longOptionParameters"></ets-long-option-strategy>
            </div>
            
            <div class="params" *ngIf="this.strategy?.algoId === '7158e24e-19bc-4228-a708-a6343054e31f'">
               <ets-short-option-strategy [parameters]="this.shortOptionParameters"></ets-short-option-strategy>
            </div>
   
            <div class="params" *ngIf="this.strategy?.algoId === '1b807506-4c14-4005-a826-55625946856b'">
               <ets-interest-calculator-strategy [parameters]="this.interestCalculatorParameters"></ets-interest-calculator-strategy>
            </div>

            <div class="params" *ngIf="this.strategy?.algoId === '1ba31a08-2619-48bc-9192-38fa2b81aafa'">
               <ets-put-debit-spread-roll-strategy [parameters]="this.rollSpreadParameters"></ets-put-debit-spread-roll-strategy>
            </div>

            <div class="params future-order" *ngIf="this.orderModel?.order">
               <fieldset *ngIf="this.orderModel.hasFutureSettings">
                  <legend>Future Time Settings</legend>
         
                  <div class="input row-2" style="margin-top: 5px;">
                     
                     <div class="cell">
                        <dx-select-box 
                                       [items]="['Place At', 'Place After']"
                                       [showClearButton]="true"
                                       [(value)]="this.orderModel.futureSettings.actionTimeMode"
                                       (onValueChanged)="this.onChange($event)"
                                       placholder="Place Mode" #place></dx-select-box>
                     </div>
                     
                     <div class="cell">
                        <ets-datetime-picker 
                                    [contextWord]="this.orderModel.futureSettings.actionTimeMode"
                                    [disabled]="!place.value"
                                    [mode]="this.orderModel.futureSettings.actionTimeMode === 'Place After' ? 'timespan' : 'datetime'"
                                    [(value)]="this.orderModel.futureSettings.actionTime"
                        ></ets-datetime-picker>
                     </div>
   
                  </div>
            
                  <div class="input row" style="margin-top: 5px;" *ngIf="this.orderModel.futureSettings.actionTimeMode === 'Place At'">
                     <ets-timezone-picker
                              [(timezone)]="this.orderModel.futureSettings.timezone"
                              [flow]="'col'"></ets-timezone-picker>
                  </div>
         
               </fieldset>
         
               <fieldset>
                  <legend>
                     <dx-check-box text="Convert To Market" [(value)]="this.orderModel.isConvertToMarket" (onValueChanged)="this.onChange()"></dx-check-box>
                  </legend>
         
                  <div [class.disabled]="!this.orderModel.isConvertToMarket">
   
                     <div class="input row-2" style="margin-top: 5px;">
                        <div class="cell">
                           <dx-select-box 
                                          [items]="['Convert At', 'Convert After']"
                                          [(value)]="this.orderModel.convertToMarket.actionTimeMode"
                                          (onValueChanged)="this.onChange($event)"
                                          placholder="Convert Mode"></dx-select-box>
                        </div>
                        <div class="cell">
                           <ets-datetime-picker
                                       [contextWord]="this.orderModel?.convertToMarket?.actionTimeMode"
                                       [mode]="this.orderModel?.convertToMarket?.actionTimeMode === 'Convert After' ? 'timespan' : 'datetime'"
                                       (onValueChanged)="this.onChange($event)"
                                       [(value)]="this.orderModel.convertToMarket.actionTime"
                           ></ets-datetime-picker>
                        </div>
                     </div>
               
                     <div class="input row" style="margin-top: 5px;" *ngIf="this.orderModel.convertToMarket.actionTimeMode === 'Convert At'">
                        <ets-timezone-picker [flow]="'col'" 
                                             [(timezone)]="this.orderModel.convertToMarket.timezone"
                        ></ets-timezone-picker>
                     </div>
   
                  </div>
               </fieldset>
         
            </div>
   
         </fieldset>
      </div>
      
      <div class="buttons" *ngIf="this.strategy">
         
         <div class="btn start">
            <dx-button type="success" width="100%" text="Enable Strategy" [disabled]="!this.canStartStrategy"
               (onClick)="this.startStrategy()"></dx-button>
         </div>
         
         <div class="btn stop">
            <dx-button type="danger" width="100%" text="Disable Strategy" [disabled]="!this.canExitStrategy"
            (onClick)="this.exitStrategy()"></dx-button>
         </div>
         
         <div class="btn update">
            <dx-button type="default" width="100%" text="Update Strategy" [disabled]="!this.canUpdateStrategy"
            (onClick)="this.updateStrategy()"></dx-button>
         </div>
      </div>

      <div class="buttons order" *ngIf="this.orderModel.order">
         <div class="btn update">
            <dx-button type="default" width="100%" text="Update Order"
            (onClick)="this.updateOrder()"></dx-button>
         </div>
      </div>


      <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: container}" ></dx-load-panel>

   </div>

</dx-scroll-view>