import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from 'projects/shared-components/page-not-found.component';
import { WebtraderAppComponent } from './wt-app.component';
import { WebtraderInitializerComponent } from './initializer/wt-initializer.component';
import { LogoutComponent } from '../../../shared-components/logout-screen/logout.component';
import { WebtraderHostComponent } from './host/host.component';
import { ValidAuthTokenGuard } from 'projects/shared-components/unspecific/valid-auth-token.guard';

const RoutesDefinition: Routes = [
  {
    path: 'init',
    component: WebtraderInitializerComponent,
    canActivate: [ValidAuthTokenGuard]
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'app',
    component: WebtraderHostComponent,
    canActivate: [ValidAuthTokenGuard]
  },
  { path: '', component: WebtraderAppComponent, pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(RoutesDefinition, { enableTracing: false, useHash: true })
  ],
  exports: [RouterModule],
  providers: [],
})
export class WebtraderRoutingModule { }
