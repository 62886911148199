import { Component, OnInit, Inject } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { getArchivedPositionsGridModel } from './archived-positions-grid-model';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { 
  TradingInstrumentDisplayNameService,  
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { 
  DeleteArchivedPositions 
} from 'projects/shared-components/shell-communication/operations/archived-positions/delete-archived-positions.class';
import { 
  GetArchivedPositions 
} from 'projects/shared-components/shell-communication/operations/archived-positions/get-archived-positions.class';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';

interface ArchivedPositionsSecurityContext {
  deletePosition: boolean;
}

@Component({
  selector: 'ets-archived-positions-component',
  templateUrl: 'archived-positions.component.html',
  styleUrls: ['archived-positions.component.scss']
})
export class ArchivedPositionsComponent implements OnInit {
  constructor(
    private _shellService: ShellClientService,
    private _toastr: ToastrService,
    private _displayNameService: TradingInstrumentDisplayNameService,
    private _accessControlSvc: AccessControlService,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger('ArchivedPositionsComponent');
  }

  positionsGridModel: GridOptions;
  isVisible = false;
  securityContext: ArchivedPositionsSecurityContext;

  private _positionsGrid: GridReadyEvent;
  private _logger: Logger;

  ngOnInit() {
    const isAvailable = (id) =>
      this._accessControlSvc.isSecureElementAvailable(id);
    this.securityContext = {
      deletePosition: isAvailable('b6616dac-51c1-4632-a34c-c9f2e983ae40')
    };
    this.positionsGridModel = getArchivedPositionsGridModel.bind(this)(
      this._displayNameService
    );
  }

  async onPositionsGridReady(args: GridReadyEvent) {
    this._positionsGrid = args;
    this._positionsGrid.api.sizeColumnsToFit();
  }

  async deletePosition(data: PositionDto) {
    const selectedRows = this._positionsGrid.api.getSelectedRows();
    if (selectedRows.length === 0) {
      return;
    }

    const selected: string[] = selectedRows.map(x => x.positionId);

    const cmd = new DeleteArchivedPositions(
      selected
    );

    this._positionsGrid.api.showLoadingOverlay();
    try {
      await this._shellService.processCommand(cmd);
      this._positionsGrid.api.batchUpdateRowData({ remove: selectedRows });
    } catch (e) {
      this._toastr.error('"Delete Archived Position" operation completed with errors');
      const errorData = { error: e.stack || e };
      this._logger.error('deletePosition()', errorData);
    } finally {
      this._positionsGrid.api.hideOverlay();
    }
  }

  onHidden() {
    this.isVisible = false;
    this._resetState();
  }

  async onShown() {
    await this._tryLoadData();
  }

  private async _tryLoadData() {
    this._resetState();

    this._positionsGrid.api.showLoadingOverlay();

    try {
      const qry = new GetArchivedPositions();
      const dtos = await this._shellService.processQuery<PositionDto[]>(qry);
      this._positionsGrid.api.setRowData(dtos);
    } catch (e) {
      this._toastr.error('"Load Archived Positions" operation completed with errors');
      const data = { error: e.stack || e };
      this._logger.error('_tryLoadData()', data);
    } finally {
      this._positionsGrid.api.hideOverlay();
    }
  }

  private _resetState() {
    if (this._positionsGrid) {
      this._positionsGrid.api.setRowData([]);
    }
  }
}
