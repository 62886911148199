import { SessionHistoryComponent } from './session-history.component';
import { GridOptions } from 'ag-grid-community';
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { InformationCategory } from '../../../../shared-components/information-category.enum';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { StrategyLogMessageDto } from 'projects/shared-components/shell-communication/dtos/strategy-log-message-dto.class';
import { TimestampsService } from 'projects/shared-components/timestamps.service';

export function getMessagesGridModel(this: SessionHistoryComponent): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Timestamp',
            field: 'timestamp',
            valueFormatter: (params: CellClassParams) => {
               const message: StrategyLogMessageDto = params.data;
               const frmtTime = this.timestampsService.getFormattedDateTimeForStrategy(message.strategyId, message.timestamp);
               return message.timestamp
                  ? frmtTime
                  : '';
            }
         },
         {
            headerName: 'Category',
            field: 'category',
            valueFormatter: (params: CellClassParams) => {
               return InformationCategory[params.value];
            },
            filter: true
         },
         {
            headerName: 'Message',
            field: 'messageText',
            headerComponentParams: {},
            cellStyle: { 'text-align': 'left' },
            filter: 'agTextColumnFilter'
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,


      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: args => this.onMessagesGridReady(args),

      getRowNodeId: (rowData: StrategyLogMessageDto) => rowData.messageId,

      getContextMenuItems: () => {
         return [
            {
               name: 'Load More ...',
               disabled: !this.selectedStrategyInfo,
               subMenu: [
                  {
                     name: '25',
                     action: () => this.loadMoreTradingData(25, 'messages')
                  },
                  {
                     name: '50',
                     action: () => this.loadMoreTradingData(50, 'messages')
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreTradingData(100, 'messages')
                  }
               ]
            },
            {
               name: 'Toggle Full Size',
               action: () => this.toggleSectionFullWidth('tradingData')
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   } as GridOptions;
}
