import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
   selector: 'ets-automation-cp-header-qty-guard',
   templateUrl: './qty-guard.component.html',
   styleUrls: ['./qty-guard.component.scss']
})
export class QtyGuardComponent implements OnInit {
   constructor() { }

   label = 'Qty Guard';

   @Output()
   submit = new EventEmitter();
   
   ngOnInit(): void { }

   onSubmitClick() {
      this.submit.emit();
   }
}
