<div class="column left">
  
  <!-- Symbol -->
  <div class="ets-field">
    <div class="ets-field-label">Symbol</div>
    <div class="ets-field-value">
      <ets-symbol-picker (instrumentSelected)="control.symbol = $event"
                         [selectedInstrument]="control.symbol"
      ></ets-symbol-picker>
    </div>
  </div>

  <!-- CTL Value & Times -->
  <div class="fields-line">
  
    <div class="ets-field inline equal">
      <div class="ets-field-label">CTL Value</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.ctlValueItems"
                      [value]="control.ctlValue"
                      (valueChange)="control.ctlValue = $event"
                      [showClearButton]="true"
                      [acceptCustomValue]="true"
                      (onCustomItemCreating)="control.onCustomNumberCreated($event, control.ctlValueItems)"
        ></dx-select-box>
      </div>
    </div>

    <div class="ets-field inline equal last">
        <div class="ets-field-label">CTL Times</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.ctlTimesItems"
                        [value]="control.ctlTimes"
                        (valueChange)="control.ctlTimes = $event"
                        [showClearButton]="true"
                        [disabled]="!control.canChangeCtlTimes"
                        (onCustomItemCreating)="control.onCustomNumberCreated($event, control.ctlTimesItems)"
          ></dx-select-box>
        </div>
    </div>

  </div>

  <!-- Multiplier -->
  <div class="ets-field">
      <div class="ets-field-label">Multiplier</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.multiplierItems"
                       [value]="control.multiplier"
                       (valueChange)="control.multiplier = $event"
                       [showClearButton]="true"
                       [acceptCustomValue]="true"
                       [disabled]="!control.canChangeMultiplier"
                       (onCustomItemCreating)="control.onCustomNumberCreated($event, control.multiplierItems)"
        ></dx-select-box>
      </div>
  </div>

  <!-- Bet Style -->
  <div class="ets-field">
    <div class="ets-field-label">Bet Style</div>
    <div class="ets-field-value">
        <dx-select-box [items]="control.betStyleItems"
                       [displayExpr]="'text'"
                       [valueExpr]="'value'"
                       [(value)]="control.betStyle"
        ></dx-select-box>
    </div>
  </div>

  <!-- Factor & MP -->
  <fieldset [ngClass]="{'ets-hidden': control.betStyle != 6}">
    <legend>"Factor" Bet Style</legend>  
    <div class="fields-line">
        <div class="ets-field inline equal">
          <div class="ets-field-label">Factor</div>
          <div class="ets-field-value">
            <dx-select-box [items]="control.factorItems"
                          [value]="control.factor"
                          (valueChange)="control.factor = $event"
                          [showClearButton]="true"
            ></dx-select-box>
        </div>
        </div>
    
        <div class="ets-field inline equal last">
          <div class="ets-field-label">Max Position</div>
          <div class="ets-field-value">
            <dx-select-box [items]="control.maxPositionItems"
                          [value]="control.maxPosition"
                          (valueChange)="control.maxPosition = $event"
                          [showClearButton]="true"
            ></dx-select-box>
          </div>
        </div>
    </div>
  </fieldset>

  <!-- Pyramid Bet Style -->
  <fieldset [ngClass]="{'ets-hidden': control.betStyle != 4}">
    <legend>"Pyramid" Bet Style</legend>
    <div class="ets-field">
      <div class="ets-field-label">"Pyramid Down" Value</div>
      <div class="ets-field-value">
        <dx-number-box [(value)]="control.pyramidDown" [min]="0"></dx-number-box>
      </div>
    </div>
  </fieldset>

  <!-- Progression Bet Style -->
  <fieldset [ngClass]="{'ets-hidden': control.betStyle != 2 && control.betStyle != 3}">
    <legend>"Progression" Bet Style</legend>
    <div class="ets-field">
      <div class="ets-field-label">Progression Sequence</div>
      <div class="ets-field-value">
        <dx-text-box [(value)]="control.progressionSequence"></dx-text-box>
      </div>
    </div>
    
    <!-- Neg. Progression Safety Start -->
    <div class="ets-field" [ngClass]="{'ets-hidden': control.betStyle != 3}">
      <div class="ets-field-label">
          <dx-check-box text="Safety Start"
          [value]="control.safetyStart"
          (valueChange)="control.safetyStart = $event"
        ></dx-check-box>
      </div>
    </div>
  </fieldset>

  <!-- HP Bet Style -->
  <fieldset [ngClass]="{'ets-hidden': control.betStyle != 5}">
    <legend>"High Probability" Bet Style</legend>
    <div class="ets-field">
      <div class="ets-field-label">Dataset Sequence</div>
      <div class="ets-field-value">
        <dx-text-box [(value)]="control.hpDatasetSequence"></dx-text-box>
      </div>
    </div>
    <div class="ets-field">
      <div class="ets-field-label">Datasets To Complete</div>
      <div class="ets-field-value">
        <dx-number-box [(value)]="control.hpDatasetsToComplete" [min]="1"></dx-number-box>
      </div>
    </div>  
  </fieldset>

  <!-- Bet Sequence -->
  <div class="ets-field">
    <div class="ets-field-label">Bet Sequence</div>
    <div class="ets-field-value">
        <dx-select-box [items]="control.betSequenceItems"
                       [displayExpr]="'text'"
                       [valueExpr]="'value'"
                       [(value)]="control.betSequence"
                       [disabled]="!control.canChangeBetSequence"
        ></dx-select-box>
    </div>
  </div>
  <div class="ets-field" [ngClass]="{'ets-hidden': control.betSequence !== 4}">
    <div class="xofy">
      <dx-number-box  [min]="0"
                      [(value)]="control.xOfyLhs"
      ></dx-number-box>
      <p>of</p>
      <dx-number-box  [min]="0"
                      [(value)]="control.xOfyRhs"
      ></dx-number-box>  
    </div>
    <div class="winnerorloser">
      <dx-select-box [items]="[{text: 'Winners', value: 1}, {text: 'Losers', value: -1}]"
                     [displayExpr]="'text'"
                     [valueExpr]="'value'"
                     [(value)]="control.xOfyType"
      ></dx-select-box>
    </div>
  </div>


  <!-- Sasmsonite -->
  <fieldset>
    <legend>
      <dx-check-box text="Use Samsonite MM"
                    #samsonite
                    [(value)]="control.useSamsoniteMM"
      ></dx-check-box>
    </legend>
    <div class="ets-field">
      <div class="ets-field-label">Wins</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.samsConsecutiveWinsItems"
                      [(value)]="control.samsNumberOfConsecutiveWins"
                      [disabled]="!samsonite.value"
        ></dx-select-box>
      </div>
    </div>
    <div class="ets-field">
      <div class="ets-field-label">Ratio</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.samsRatioItems"
                        [(value)]="control.samsRatio"
                        [disabled]="!samsonite.value"
        ></dx-select-box>
      </div>
    </div>
    <div class="ets-field">
      <div class="ets-field-label">MP</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.samsMaxTimesToIncreasItems"
                       [(value)]="control.samsMaxTimesToIncrease"
                       [disabled]="!samsonite.value"
        ></dx-select-box>
      </div>
    </div>
    <div class="ets-field">
        <div class="ets-field-label">MP-NPO</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.samsMpNpoItems"
                         [(value)]="control.samsMpNpo"
                         [disabled]="!samsonite.value"
          ></dx-select-box>
        </div>
      </div>
  </fieldset>

  <!-- Order conditions -->
  <fieldset>
      <legend>Order Conditions</legend>
      <div class="ets-field">
        <dx-check-box text="Trade-Through Proxy"
                      [value]="control.isTradeThroughProxy"
                      (valueChange)="control.isTradeThroughProxy = $event"
        ></dx-check-box>
      </div>
      <div class="ets-field">
        <dx-check-box text="Trade-Through Market"
                      [value]="control.isTradeThroughStop"
                      (valueChange)="control.isTradeThroughStop = $event"
        ></dx-check-box>
      </div>
      <div class="ets-field">
        <dx-check-box text="Trade-Through PT"
                      [value]="control.isTradeThroughMIT"
                      (valueChange)="control.isTradeThroughMIT = $event"
        ></dx-check-box>
      </div>
      <div class="ets-field">
        <dx-check-box text="Trade-Through Limits"
                      [value]="control.isTradeThroughLimit"
                      (valueChange)="control.isTradeThroughLimit = $event"
        ></dx-check-box>
      </div>
  </fieldset>

</div>

<div class="column right">
  <!-- OTR Mode -->
  <fieldset>
    <legend>
      <dx-check-box text="OTR Mode"
                    #otr
                    [(value)]="control.isOtrEnabled"
      ></dx-check-box>
    </legend>

    <!-- # of losses to go OTR -->
    <div class="fields-line">
      <div class="ets-field inline times-to-stay-otr">
        <div class="ets-field-label">
          # loses before OTR
        </div>
        <div class="ets-field-value">
          <dx-select-box
              [items]="control.otrNumberOfLossesToGoOTRItems"
              [(value)]="control.otrNumberOfLossesToGoOTR"
              [disabled]="!otr.value"
          ></dx-select-box>
        </div>
      </div>
    </div>

     <!-- OTR Algorithm -->
     <div class="fields-line">
        <div class="ets-field inline times-to-stay-otr">
          <div class="ets-field-label">
            <dx-check-box text="Use OTR Algorithm" #otrAlgo
                          [(value)]="control.otrUseOtrAlgorithm"
                          [disabled]="!otr.value"
            ></dx-check-box>
          </div>
          <div class="ets-field-value">
            <dx-select-box [disabled]="!otrAlgo.value"
                           [items]="control.otrAlgorithmItems"
                           [value]="control.otrAlternativeAlgo"
                           (valueChange)="control.otrAlternativeAlgo = $event"
                           [displayExpr]="'text'"
                           [valueExpr]="'value'"
            ></dx-select-box>
          </div>
        </div>
    </div>

    <!-- Times to stay OTR -->
    <div class="fields-line">
      <div class="ets-field inline times-to-stay-otr">
        <div class="ets-field-label">Times to Stay</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.otrTimesToStayOtrItems"
                          [disabled]="!otr.value"
                          [(value)]="control.otrTimesToStayOtr"
          ></dx-select-box>
        </div>
      </div>
    </div>
    
    <!-- OTR Betting -->
    <div class="fields-line">
        <div class="ets-field inline times-to-stay-otr">
          <div class="ets-field-label">
            <dx-check-box text="Use OTR Betting" #otrBetting
                          [(value)]="control.otrUseOtrBetting"
                          [disabled]="!otr.value"
            ></dx-check-box>
          </div>
          <div class="ets-field-value">
            <dx-text-box [(value)]="control.otrBets"
                          [disabled]="!otrBetting.value"
            ></dx-text-box>
          </div>
        </div>
    </div>
    
    <!-- Stay OTR -->
    <div class="fields-line otr-misc">
      <div class="ets-field inline left">
        <dx-check-box text="Stay OTR" class="stay-otr"
                      [(value)]="control.otrStayOTR"
                      [disabled]="!otr.value"
        ></dx-check-box>
        <dx-check-box text="Repeat Progression"
                      [(value)]="control.otrRepeatOtrProgression"
                      [disabled]="!otr.value"
        ></dx-check-box>
      </div>
      <div class="ets-field inline right">
        <dx-check-box text="Double OTR"
                      #doubleOtr
                      [(value)]="control.otrDoubleOtrBetOnLoss"
                      [disabled]="!otr.value"
        ></dx-check-box>
        <div class="max-times-block">
          <div>Max. Times Double OTR</div>
          <div>
            <dx-select-box  
              [items]="control.otrMaxTimesDoubleOtrBetOnLossItems"
              [(value)]="control.otrMaxTimesDoubleOtrBetOnLoss"
              [disabled]="!doubleOtr.value"
            ></dx-select-box>
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <!-- Alter OTR mode -->
  <fieldset>
    <legend>
      <dx-check-box text="Alternative OTR Mode"
                    #alterOtr
                    [disabled]="!otr.value"
                    [(value)]="control.isAlterOtrEnabled"
      ></dx-check-box>
    </legend>

    <div class="fields-line">
      <div class="ets-field inline times-to-stay-otr">
        <div class="ets-field-label">
          # loses before A-OTR
        </div>
        <div class="ets-field-value">
          <dx-select-box
              [(value)]="control.alterOtrNumberOfLossesToGoOTR"
              [items]="control.alterOtrNumberOfLossesToGoOTRItems"
              [disabled]="!alterOtr.value"
          ></dx-select-box>
        </div>
      </div>
    </div>

    <div class="fields-line">
      <div class="ets-field inline times-to-stay-otr">
        <div class="ets-field-label">Times to Stay</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.alterOtrTimesToStayOtrItems"
                          [disabled]="!alterOtr.value"
                          [(value)]="control.alterOtrTimesToStayOTR"
          ></dx-select-box>
        </div>
      </div>
    </div>
    
    <div class="fields-line">
      <div class="ets-field inline times-to-stay-otr">
          <div class="ets-field-label">
              <dx-check-box text="A-OTR Betting" #aotrBetting
                            [(value)]="control.alterOtrUseOtrBetting"
                            [disabled]="!alterOtr.value"
            ></dx-check-box>
          </div>
          <div class="ets-field-value">
            <dx-text-box [(value)]="control.alterOtrBets"
                         [disabled]="!aotrBetting.value"
            ></dx-text-box>    
          </div>
      </div>
    </div>

    <div class="fields-line">
        <div class="ets-field inline times-to-stay-otr">
            <div class="ets-field-label">
                # of loses to alter.
            </div>
            <div class="ets-field-value">
              <dx-select-box [disabled]="!alterOtr.value"
                              [items]="control.alterOtrNumberOfLossesToAlterItems"
                              [(value)]="control.alterOtrNumberOfLossesToAlter"
              ></dx-select-box>
            </div>
        </div>
      </div>

  </fieldset>

  <!-- Outright Side -->
  <div class="ets-field" [ngClass]="{'ets-hidden': control.algoId !== '235a0f9c-6e9d-4ba0-aba4-ca8b3a3d9246'}">
      <div class="ets-field-label">Outright Side</div>
      <div class="ets-field-value">
          <dx-select-box [items]="control.outrightSideItems"
                          [displayExpr]="'text'"
                          [valueExpr]="'value'"
                          [(value)]="control.outrightSide"
          ></dx-select-box>
      </div>
  </div>

  <div class="ets-field">
      <div class="ets-field-label">
          <dx-check-box [(value)]="control.resetOnExit"
                        [text]="'Reset On Exit'"
          ></dx-check-box>  
      </div>
  </div>
</div>