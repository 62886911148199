import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {SettingsStorageService} from "../../../settings-storage-service.service";
import {DetectMethodChanges, isVoid} from "../../../utils";
import {SessionService} from "../../../authentication/session-service.service";
import {set} from "devextreme/events/core/events_engine";

const StorageKey = 'hg.zones-grid-parameters';

export interface HgZonesGridSettings {
    rangeDown?: number;
    range: number;
    step: number;
}

@Component({
    selector: 'ets-hg-zones-grid-parameters',
    templateUrl: 'hg-zones-grid-parameters.component.html',
    styleUrls: ['hg-zones-grid-parameters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgZonesGridParametersComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _settingsStorageService: SettingsStorageService,
        private _toastr: ToastrService,
        private readonly _sessionService: SessionService
    ) {
    }

    private _resolve: (value?: (PromiseLike<HgZonesGridSettings> | HgZonesGridSettings)) => void;
    private _reject: (reason?: any) => void;

    private _underlying: string;

    visible = false;

    range: number;
    step: number;
    rangeDown: number;

    get maxRange(): number {
        return this._sessionService.isSuperUser ? undefined : 100;
    }

    get minStep(): number {
        return this._sessionService.isSuperUser ? undefined : 10;
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(underlying: string): Promise<HgZonesGridSettings> {

        this._underlying = underlying;

        this.visible = true;

        this.restoreSettings();

        return new Promise<HgZonesGridSettings>((res, rej) => {
           this._resolve = res;
           this._reject = rej;
        });
    }

    onApply() {
        if (isVoid(this.range)) {
            this._toastr.error('Please, provide the strikes range');
            return;
        }
        if (isVoid(this.step)) {
            this._toastr.error('Please, provide strikes step');
            return;
        }

        if (this.range > this.maxRange || this.rangeDown > this.maxRange) {
            this._toastr.error('Maximum range cannot be greater than 100');
            return;
        }

        if (this.step < this.minStep) {
            this._toastr.error('Minimum step cannot be less than 10');
            return;
        }

        const settings: HgZonesGridSettings = {
            range: this.range,
            step: this.step,
            rangeDown: this.rangeDown
        };
        this._resolve(settings);
        this.saveSettings();
        this.onHidden();
    }

    onCancel() {
        this._reject();
        this.onHidden();
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this.range = undefined;
        this.step = undefined;
        this.rangeDown = undefined;
    }

    private restoreSettings() {
        const settings = this.getSettings(this._underlying);
        if (isVoid(settings)) {
            return;
        }
        this.range = settings.range;
        this.step = settings.step;
        this.rangeDown = settings.rangeDown;
    }

    private saveSettings() {
        const data: HgZonesGridSettings = {
            range: this.range,
            step: this.step,
            rangeDown: this.rangeDown
        };

        if (isVoid(this._underlying)) {
            this._toastr.error('Underlying for Zones Grid settings not specified');
            this._reject();
            return;
        }

        const key = StorageKey + `.${this._underlying}`;
        const userId = this._sessionService.sessionData.userId;

        this._settingsStorageService.setItem(key, data, userId);
    }

    getSettings(underlying: string): HgZonesGridSettings {

        const key = StorageKey + `.${underlying}`

        const userId = this._sessionService.sessionData.userId;

        const settings = this._settingsStorageService
            .getItem<HgZonesGridSettings>(key, userId);

        if (isVoid(settings)) {
            return undefined;
        }

        return settings;
    }
}