<div class="ets-gateways">

   <div class="overlay" [hidden]="!isOverlayVisible" [style.left.px]="overlayPosition?.left"
      [style.bottom.px]="overlayPosition?.bottom" id="gateway-manager-overlay">
      <div class="dashboard">

         <div class="panel">
            <div>
               <div class="heading">DATA</div>
               <div class="form">
                  <div class="dx-fieldset" *ngFor="let g of dataGateways; index as i; trackBy: getGatewayId">
                     <div class="dx-field">
                        <div id="dgw_{{i}}" class="dx-field-label">{{ g.displayName }}</div>
                        <div class="dx-field-value">
                           <dx-switch [value]="g.isOnline"
                              [disabled]="g.isBlocked || !securityContext.changeGatewayStatus" (click)="g.changeState()"
                              [hoverStateEnabled]="false" [activeStateEnabled]="false">
                           </dx-switch>
                        </div>
                        <dx-context-menu
                           [items]="[{text: 'Force Disconnect', id: 'd'}, {text: 'Force Connect', id: 'c'}]"
                           [width]="80" (onItemClick)="forceGatewayStatus(g.gatewayId, $event)" [target]="'#dgw_'+i">
                        </dx-context-menu>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="panel">
            <div>
               <div class="heading">ORDERS</div>
               <div class="form">
                  <div class="dx-fieldset" *ngFor="let g of tradingGateways; index as i">
                     <div class="dx-field">
                        <div id="tgw_{{i}}" class="dx-field-label">{{ g.displayName }}</div>
                        <div class="dx-field-value">
                           <dx-switch [value]="g.isOnline"
                              [disabled]="g.isBlocked || !securityContext.changeGatewayStatus" (click)="g.changeState()"
                              [hoverStateEnabled]="false" [activeStateEnabled]="false"></dx-switch>
                        </div>
                        <dx-context-menu
                           [items]="[{text: 'Force Disconnect', id: 'd'},  {text: 'Force Connect', id: 'c'}]"
                           [width]="80" (onItemClick)="forceGatewayStatus(g.gatewayId, $event)" [target]="'#tgw_'+i">
                        </dx-context-menu>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="drawer" [style.height.px]="drawerHeight" *ngIf="securityContext.viewGatewaysLog">
         <dx-button text="Events Log" width="100%" (onClick)="isDrawerOpened = !isDrawerOpened"></dx-button>
         <div class="content">
            <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham-dark"
               [gridOptions]="logEventsGridModel"></ag-grid-angular>
         </div>
      </div>

      <div class="load-panel" [hidden]="!isLoading">
         <dx-load-indicator [visible]="true"></dx-load-indicator>
         <p>Loading ...</p>
      </div>
   </div>

   <div id="gateway-manager-action-button" class="action-button">
      <dx-button [width]="120" [text]="actionButtonText" (onClick)="onActionButtonClick($event)"></dx-button>
      <span class="badge red" [style.left.px]="badgeLeftPosition" *ngIf="notificationsCount > 0">
         {{ notificationsCount }}
      </span>
   </div>

   <dx-popup width="550" height="350" [showTitle]="true" title='Message Details' [closeOnOutsideClick]="true"
      [resizeEnabled]="true" [dragEnabled]="true" (onHidden)="isMessagePopupVisible = false"
      [visible]="isMessagePopupVisible">
      <div *dxTemplate="let data of 'content'" id="session_message_details">
         <div id="popup_container">
            <p>{{selectedEvent?.message}}</p>
         </div>
      </div>
   </dx-popup>

</div>