import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericConfirmationDialogComponent } from './generic-confirmation-dialog.component';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   declarations: [
      GenericConfirmationDialogComponent
   ],
   imports: [ CommonModule, DevExtremeModule ],
   exports: [
      GenericConfirmationDialogComponent
   ],
   providers: [],
})
export class GenericConfirmationDialogModule {} 