import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface MessageBusMessage<T> {
   topic: string;
   payload: T;
   scopeId?: string;
   resend?: boolean;
}

@Injectable({
   providedIn: 'root'
})
export class MessageBusService {
   public constructor() {
   }

   private readonly subjects: { [topic: string]: Subject<MessageBusMessage<any>>} = {};
   private readonly lastMessageCache: { [topic: string]: MessageBusMessage<any> } = {};
   private readonly scopedLastMessageCache: { [scopeId: string]: { [topic: string]: MessageBusMessage<any> } } = {};

   publishAsync<T>(message: MessageBusMessage<T>) {
      setTimeout(() => {
         try {
            this.publishInternal(message);
         } catch (e) {
            console.error(e);
         }
      });
   }

   publish<T>(message: MessageBusMessage<T>): void {
      try {
         this.publishInternal(message);
      } catch (e) {
         console.error(e);
      }
   }


   private publishInternal<T>(message: MessageBusMessage<T>): void {
      if (message.scopeId) {
         if (!(message.scopeId in this.scopedLastMessageCache)) {
            this.scopedLastMessageCache[message.scopeId] = {};
         }
         this.scopedLastMessageCache[message.scopeId][message.topic] = message;
      } else {
         this.lastMessageCache[message.topic] = message;
      }
      const subject = this.subjects[message.topic];
      if (!subject) {
         return;
      }
      subject.next(message);
   }


   of<T>(topic: string): Observable<MessageBusMessage<T>> {
      let subject = this.subjects[topic];
      if (!subject) {
         subject = new Subject<MessageBusMessage<T>>();
         this.subjects[topic] = subject;
      }
      return subject;
   }


   getLastMessage<T>(topic: string, scopeId?: string): MessageBusMessage<T> {
      if (scopeId) {
         const scopedCacheElement = this.scopedLastMessageCache[scopeId] || {};
         if (scopedCacheElement) {
            return scopedCacheElement[topic];
         }
      }

      return this.lastMessageCache[topic];
   }


   clearLastMessage(topic: string, scopeId?: string): void {
      if (scopeId) {
         const scopedCacheElement = this.scopedLastMessageCache[scopeId];
         if (scopedCacheElement) {
            delete scopedCacheElement[topic];
         }
         return;
      }
      delete this.lastMessageCache[topic];
   }
}
