import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';

import { PositionSizingComponent } from './position-sizing.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule
   ],
   exports: [PositionSizingComponent],
   declarations: [PositionSizingComponent],
   providers: [],
})
export class PositionSizingModule { }
