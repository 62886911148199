import {
   GetContextMenuItemsParams,
   GridOptions,
   CellClassParams,
   CellValueChangedEvent,
   MenuItemDef,
   ValueFormatterParams,
   ValueGetterParams,
   RowSelectedEvent,
   FirstDataRenderedEvent,
   RowNode
} from 'ag-grid-community';
import { TradingSystemsViewComponent } from './trading-systems-view.component';
import { StrategyState } from 'projects/shared-components/strategies/strategy-state.enum';
import {
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   centeredColumnDef,
   defaultPriceCellFormatter,
   liveQuoteFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { StrategyIssuesCellRendererComponent } from './strategy-issues-cell-renderer.component';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';
import { AgGridColumn } from 'ag-grid-angular';
import { StrategyFlags } from '../strategies/strategy-flags.enum';
import { InnerStrategyGroupRowRendererComponent } from './inner-strategy-group-row-renderer';

export function getInnerSystemsGridModel(this: TradingSystemsViewComponent): GridOptions {
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;
   const columns = setupColumns(this, isDashboard);

   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      suppressCellSelection: true,

      immutableData: true,

      popupParent: this.popupParent,

      tooltipShowDelay: 350,

      groupUseEntireRow: false,

      suppressAggFuncInHeader: true,

      groupIncludeTotalFooter: true,

      groupIncludeFooter: true,

      autoGroupColumnDef: {
         headerName: 'Name',
         field: 'displayName',
         flex: 1,
         minWidth: 150,
         cellRendererParams: {
            suppressCount: true,
            innerRenderer: params => {
               if (params.node.group) {
                  const isRootLevel = params.node.level === 0;
                  if (isRootLevel) {
                     // Grand Total Cells
                     const color = params.value === 'Active Items' ? '#e8e2e2' : 'yellow';
                     return `<span style="color:${color};">${params.value}</span>`;
                  }
               }
               // Non-Group Cells
               return params.value;
            },
            footerValueGetter: params => {
               const isRootLevel = params.node.level === -1;
               if (isRootLevel) {
                  return 'Grand Total';
               }
               return `Sub Total: (${params.value})`;
            },
         },
      },

      frameworkComponents: {
         strategyIssuesCellRenderer: StrategyIssuesCellRendererComponent,
         groupRowRenderer: InnerStrategyGroupRowRendererComponent
      } as any,

      getRowStyle: (args) => {
         const data: StrategyModel = args.data;

         if (!data) {
            return { background: null, color: null };
         }

         if (!data.hasFlag(StrategyFlags.MaxPositionViolation)) {
            return { background: null, color: null };
         }

         if (data.state !== StrategyState.Active) {
            return { background: 'firebrick' };
         }

         return { background: 'orange' };
      },

      onGridReady: args => this.onInnerStrategiesGridReady(args),

      onCellValueChanged: (args: CellValueChangedEvent) => {
         if (args.api && args.data) {
            const changedData = [args.data];
            args.api.applyTransaction({ update: changedData });
         }
      },
      
      onRowSelected: (args: RowSelectedEvent) => {
         if (!args.node.isSelected()) {
            return;
         }
         
         this.onInnerStrategiesRowSelected(args);
      },

      getRowNodeId: (strat: StrategyModel) => {
         return strat.strategyId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu: Partial<MenuItemDef | string>[] = [];

         const isArchivedStrategy = params.node && params.node.data && params.node.data.isArchived;

         if (this.securityContext.addStrategy) {
            menu.push({
               name: 'Add',
               action: () => this.showInnerStrategyEditor(params.node ? params.node.data : null, 'add')
            });
            menu.push('separator');
         }


         if (this.securityContext.updateStrategy && !isArchivedStrategy) {
            menu.push({
               name: 'Update',
               disabled: !canUpdateInnerStrategy(params, this),
               action: () => this.editStrategy(params.node.data, 'update', 'inner')
            });
            menu.push('separator');
         }


         if (this.securityContext.startStrategy && !isArchivedStrategy) {
            menu.push({
               name: 'Enable',
               disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
               action: () => this.startStrategy(params.node.data, true)
            });
         }

         if (this.securityContext.exitStrategy && !isArchivedStrategy) {
            menu.push({
               name: 'Disable',
               disabled: !params.node || (params.node.data.state & StrategyState.Enabled) !== params.node.data.state,
               action: () => this.exitStrategy(params.node.data, true)
            });
         }

         if (this.securityContext.removeStrategy && isArchivedStrategy) {
            menu.push({
               name: 'Remove',
               disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
               action: () => this.removeStrategy(params.node.data)
            });
         }

         if (this.securityContext.removeStrategy && !isArchivedStrategy) {
            menu.push({
               name: 'Archive',
               disabled: !params.node || (params.node.data.state & StrategyState.Enabled) === params.node.data.state,
               action: () => this.archiveStrategy(params.node.data)
            });
         }


         if (this.securityContext.stopStrategy && !isArchivedStrategy) {
            menu.push({
               name: 'Stop',
               disabled: !params.node || (params.node.data.state & StrategyState.Enabled) !== params.node.data.state,
               action: () => this.stopStrategy(params.node.data, true)
            });
         }

         if (this.securityContext.setToStart && !isArchivedStrategy) {
            menu.push({
               name: 'Set To Start',
               disabled: !params.node,
               action: () => this.setToStartStrategy(params.node.data, true)
            });
         }

         if (menu.length > 0) {
            menu.push('separator');
         }

         if (this.securityContext.systemDetails) {
            menu.push({
               name: 'System Details',
               disabled: !params.node,
               action: () => this.showSystemDetails(params.node.data)
            });
         }

         if (this.securityContext.strategyIssues) {
            menu.push({
               name: 'Show Issues',
               disabled: !params.node,
               action: () => this.showIssues(params.node.data)
            });
         }

         menu.push(
            'separator', 
            'autoSizeAll',
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit()
            },
            'separator',
            'copy', 
            'export');

         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => args.api.sizeColumnsToFit(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}

function setupColumns(comp: TradingSystemsViewComponent, isDashboard: boolean): Partial<AgGridColumn>[] {
   
   const columns: Partial<AgGridColumn>[] = [];

   if (comp.securityContext.systemDetails) {
      columns.push({
         headerName: 'Description',
         field: 'description',
         headerComponentParams: {},
         cellStyle: { 'text-align': 'left' },
         tooltipField: 'description',
         minWidth: 200
      });
   }

   columns.push({
      headerName: 'State',
      enableRowGroup: true,
      field: 'state',
      valueFormatter: (params: ValueFormatterParams) => {
         
         const v: StrategyState = params.value;
         
         let retval = StrategyState[params.value];

         switch (v) {
            case StrategyState.Activating:
               retval = 'Enabling';
               break;
            case StrategyState.Active:
               retval = 'Enabled';
               break;
            case StrategyState.Deactivating:
               retval = 'Disabling';
               break;
            case StrategyState.Inactive:
               retval = 'Disabled';
               break;
            case StrategyState.RiskViolation:
               retval = 'Risk Violation';
               break;
            default:
               break;
         }

         return retval;

      }
   });

   columns.push({ headerName: 'Algo', field: 'algo', enableRowGroup: true });

   columns.push({ headerName: 'Status', field: 'status', enableRowGroup: true });

   columns.push({ headerName: 'Position', field: 'netPosition' });

   columns.push({
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter
   });

   columns.push({
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueFormatter: defaultPriceCellFormatter
   });

   columns.push(Object.assign({ headerName: 'Session P&L', field: 'sessionTotalPnL', aggFunc: 'sum' }, defaultMoneyCellDefinition));

   columns.push(Object.assign({ headerName: 'Acc. P&L', field: 'accumulatedTotalPnL', aggFunc: 'sum' }, defaultMoneyCellDefinition));

   columns.push({
      headerName: 'S/P',
      field: 'isSession',
      enableRowGroup: true,
      valueFormatter: (params: ValueFormatterParams) => {
         return params.value ? 'S' : 'P';
      },
      cellStyle(params: CellClassParams) {
         return params.value ? { color: 'yellow', 'font-weight': 'bold' } : null;
      }
   });

   columns.push({
      headerName: 'Flags',
      field: 'flagsData',
   }
   );

   columns.push(Object.assign({ headerName: 'Trade P&L', field: 'tradePnL' }, defaultMoneyCellDefinition));

   if (comp.securityContext.strategyIssues) {
      columns.push({
         headerName: '!',
         field: 'issues',
         width: 60,
         cellRenderer: 'strategyIssuesCellRenderer'
      });
   }

   columns.push({
      headerName: 'Mode',
      field: 'mode',
      enableRowGroup: true
   });

   columns.push({
      headerName: 'Pos. Sizing',
      field: 'positionSizingSource'
   });

   columns.push({
      field: 'isArchived',
      rowGroup: true,
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return undefined;
         }

         return params.data.isArchived ? 'Archived Items' : 'Active Items';
      }
   });

   return columns;
}

function canUpdateInnerStrategy(params: GetContextMenuItemsParams, comp: TradingSystemsViewComponent): boolean {
   if (!params.node) {
      return false;
   }

   const strategy = params.node.data as StrategyModel;

   if (!strategy) {
      return false;
   }

   if ((strategy.state & StrategyState.Enabled) === strategy.state) {
      return false;
   }

   return true;
}

