<dx-popover
  (onHidden)="this.onHidden()"
  [target]="this.target"
  [width]="250"
  [(visible)]="this.visible"
  [position]="'right'"
  titleTemplate="title"
>
  <div *dxTemplate="let data of 'content'" class="wrapper">
    <div class="content">
      <div>Provide name for the package:</div>
      <div class="input">
        <div class="name">
          <dx-text-box [showClearButton]="true" [(value)]="this.name"></dx-text-box>
        </div>
        <div class="btn">
          <dx-button text="Save" (onClick)="this.onSaveClicked()"></dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popover>