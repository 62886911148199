import { ColDef, FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, RowGroupOpenedEvent, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { IWorkingOrdersComponent } from "./IWorkingOrdersComponent";
import { AgGridColumn } from "ag-grid-angular";
import { getMasterSymbolColumn, defaultNumberCellFormatter, defaultPriceCellFormatter, liveQuoteFormatter, defaultQuoteCellFormatter, GetDetailRowDataParams, centeredColumnDef, defaultLoadingOverlayTemplate, getDetailSymbolColumn } from "projects/shared-components/ag-grid-contrib";
import { OrderDto } from "projects/shared-components/shell-communication/dtos/order-dto.class";
import { FutureTimeSettings } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { MarketSide } from "projects/shared-components/trading-model/market-side.enum";
import { OrderStatus } from "projects/shared-components/trading-model/order-status.enum";
import { OrderType } from "projects/shared-components/trading-model/order-type.enum";
import { TimeInForce } from "projects/shared-components/trading-model/time-in-force.enum";
import { isCashSettledComboOrder, isCashSettledOptionTicker, isNullOrUndefined, parseOrderLegs } from "projects/shared-components/utils";
import { formatNumber } from "@angular/common";
import { SimpleHeaderGroupRowInnerRenderer } from "projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component";

export function getWorkingOrdersGridOptions(this: IWorkingOrdersComponent): GridOptions {
  
   const options: GridOptions = {};

   const columns: Partial<AgGridColumn>[] = getColumnDefs(this);
   
   options.rowData = [],
   options.defaultColDef = centeredColumnDef,
   options.columnDefs = columns,
   options.overlayLoadingTemplate = defaultLoadingOverlayTemplate,
   options.rowClass = 'ets-text-centered',
   options.rowSelection = 'multiple',
   options.rowModelType = 'clientSide',
   options.immutableData = true,
   options.tooltipShowDelay = 1000,
   options.frameworkComponents = {
      simpleHeaderRenderer: SimpleHeaderGroupRowInnerRenderer,
   };
   options.groupUseEntireRow = true,
   options.groupRowRendererParams = {
      innerRenderer: 'simpleHeaderRenderer',
      suppressCount: true
   };
   options.rowGroupPanelShow = 'never',
   options.suppressCellSelection = true,
   options.masterDetail = true,
   options.detailRowAutoHeight = true,
   options.detailCellRendererParams = {

      detailGridOptions: {
         defaultColDef: centeredColumnDef,
         groupIncludeTotalFooter: true,
         suppressAggFuncInHeader: true,
         columnDefs: [

            getDetailSymbolColumn(
               'ticker', 
               this.messageBus, 
               this.timestampsService, 
               this.unsubscriber, 
               () => this.gridApi
            ),
            
            { 
               headerName: 'Buy/Sell', 
               field: 'side' 
            },

            { 
               headerName: 'Qty', 
               field: 'qty' 
            },

            {
               headerName: 'Live Quote',
               field: 'liveQuote',
               valueFormatter: liveQuoteFormatter
            },

            {
               headerName: 'Delta',
               field: 'delta',
               hide: true,
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-3');
               },
               aggFunc: 'sum'
            },

            {
               headerName: 'T. Delta',
               field: 'totalDelta',
               aggFunc: 'sum',
               hide: true,
               valueGetter: (params: ValueGetterParams) => {
                  const leg = params.data as any;

                  if (!params.data) {
                     return null;
                  }

                  const value = leg.ticker.startsWith('@')
                     ? leg.delta * 100
                     : leg.qty;

                  return isNaN(value) ? null : value;
               },
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-2');
               }
            },

            {
               headerName: 'Gamma',
               field: 'gamma',
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-3');
               },
               hide: true
            },

            {
               headerName: 'T. Gamma',
               field: 'totalGamma',
               hide: true,
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-3');
               },
               valueGetter: (params: ValueGetterParams) => {
                  const leg = params.data;

                  if (!params.data) {
                     return null;
                  }

                  const v = leg.ticker.startsWith('@')
                     ? leg.gamma * 100
                     : leg.qty;

                  return isNaN(v) ? null : v;
               },
            },

            {
               headerName: 'Vega',
               field: 'vega',
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-3');
               },
               hide: true
            },

            {
               headerName: 'T. Vega',
               field: 'totalVega',
               hide: true,
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-3');
               },
               valueGetter: (params: ValueGetterParams) => {
                  const leg = params.data;

                  if (!params.data) {
                     return null;
                  }

                  return leg.ticker.startsWith('@')
                     ? leg.vega * 100
                     : leg.qty;
               },
            },

            {
               headerName: 'Theta',
               field: 'theta',
               valueFormatter: (params: ValueFormatterParams) => {
                  return formatNumber(params.value || 0, 'en-US', '1.0-3');
               },
               valueGetter: (params: ValueGetterParams) => {
                  const leg = params.data;

                  if (!params.data) {
                     return null;
                  }

                  return leg.ticker.startsWith('@')
                     ? leg.theta * 100
                     : leg.qty;
               },
               hide: true
            },
         ],
         getRowNodeId: (rowData) => {
            return rowData.ticker;
         },
         onFirstDataRendered: (args: FirstDataRenderedEvent) => {
            args.api.sizeColumnsToFit();
         }         
      },

      getDetailRowData: (params: GetDetailRowDataParams) => {

         const order = params.data as OrderDto;

         if (order.legs) {
            params.successCallback(order.legs);
            return;
         }

         parseOrderLegs(order, this.availableBuckets, this.lastQuoteCache);

         params.successCallback(order.legs);
         
      },

   };
   options.isRowMaster = (dataItem) => {
      const order = dataItem as OrderDto;
      return !isNullOrUndefined(order.multiLegDescriptor);
   };
   options.onGridReady = (args) => this.onGridReady(args);
   options.getRowNodeId = (rowData: OrderDto) => rowData.orderId;
   options.getContextMenuItems = (params: GetContextMenuItemsParams) => {
      const menu = [];

      menu.push('separator');

      menu.push(
         {
            name: 'Size To Fit',
            action: () => params.api.sizeColumnsToFit()
         },
         'autoSizeAll',
         'copy',
         'export'
      );

      return menu;
   };
   options.onFirstDataRendered = (args: FirstDataRenderedEvent) => {
      args.columnApi.autoSizeAllColumns();
   };
   options.onRowGroupOpened = (args: RowGroupOpenedEvent) => {
      (this as any).reclaculateHeight();
   }

   return options;

}

function getColumnDefs(comp: IWorkingOrdersComponent): Partial<AgGridColumn>[] {

   const cols: Partial<AgGridColumn>[] = [];

   // Ticker
   cols.push(
      getMasterSymbolColumn(
         'ticker', 
         comp.messageBus, 
         comp.timestampsService, 
         comp.unsubscriber, 
         () => comp.gridApi
      )
   );

   // Exp. Style
   cols.push({
      
      headerName: 'Exp. Style',
      
      colId: 'expStyle',
      
      valueGetter: (args: ValueGetterParams) => {
         
         if (!args.data) {
            return '';
         }

         if (!args.data.ticker.startsWith('@')) {

            const comboOrder = args.data && args.data.multiLegDescriptor;
            
            if (comboOrder) {
               
               if (isCashSettledComboOrder(args.data.multiLegDescriptor)) {
                  return 'European';
               }

               return 'American';
            }

            return '';
         }

         return isCashSettledOptionTicker(args.data.ticker) ? 'European' : 'American';
      }
   });

   // Status 
   cols.push({
      
      headerName: 'Status',
      
      field: 'status',
      
      valueFormatter: (params: ValueFormatterParams) => {

         const order = params.data as OrderDto;

         let status = OrderStatus[params.value];

         if (order.masterOrder) {
            status += ` (${order.masterOrder})`;
         }

         return status;

      },

      filter: true

   });

   // Order Type
   cols.push({
      
      headerName: 'Type',
      
      field: 'orderType',
      
      valueFormatter: (params: ValueFormatterParams) => {
         return OrderType[params.value];
      },
      
      filter: true
   });

   // Leaves Qty
   cols.push({
      
      headerName: 'Leaves Qty',
      
      field: 'leavesQty',
      
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.leavesQty * data.side;
      },
      
      valueFormatter: defaultNumberCellFormatter

   });

   // Order Px
   cols.push({
         headerName: 'Order Px',
         
         valueGetter(params: ValueGetterParams) {
            const data: OrderDto = params.data;

            if (data) {
               if (data.limitPrice && data.limitPrice !== 0) {
                  return data.limitPrice;
               }
               
               if (data.stopPrice && data.stopPrice !== 0) {
                  return data.stopPrice;
               }
   
               if (data.autoLimitPrice) {
                  return data.autoLimitPrice;
               }
            }
            
            
            return 0;
         },

         sort: 'desc',
         
         valueFormatter: defaultPriceCellFormatter
   });

   // Live Quote
   cols.push({
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter
   });

   cols.push({
      headerName: 'Last Qty.',
      field: 'lastQty',
      valueFormatter: defaultNumberCellFormatter
   });

   cols.push({
      headerName: 'Last Fill Px.',
      field: 'lastPx',
      valueFormatter: defaultQuoteCellFormatter
   });

   // Qty
   cols.push({
      headerName: 'Qty',
      field: 'qty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.qty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   // Side
   cols.push({
      headerName: 'Side',
      field: 'side',
      valueFormatter: (params: ValueFormatterParams) => {
         return MarketSide[params.value];
      },
      filter: true
   });

   // Filled
   cols.push({
      headerName: 'Filled',
      field: 'filledQty',
      valueGetter(params: ValueGetterParams) {
         const data: OrderDto = params.data;
         return data.filledQty * data.side;
      },
      valueFormatter: defaultNumberCellFormatter
   });

   // Avg. Px
   cols.push({
      headerName: 'Avg. Px',
      field: 'avgFillPrice',
      valueFormatter: defaultNumberCellFormatter
   });

   // Account
   cols.push({
      headerName: 'Account',
      field: 'resolveAccountCode',
      filter: true
   });

   // Comment
   cols.push({
      headerName: 'Comment',
      field: 'comment',
      filter: 'agTextColumnFilter'
   });

   // Timestamp
   cols.push({
      headerName: 'Timestamp',
      field: 'lastModifiedDate',
      valueFormatter: (params: ValueFormatterParams) => {
         
         const order: OrderDto = params.data;
         
         const frmtTime = comp
            .timestampsService
            .getFormattedDateTimeForStrategy(order.strategyId, order.lastModifiedDate);
         
         return order.lastModifiedDate
            ? frmtTime
            : '';
      },
   });

   // TIF
   cols.push({
      headerName: 'TIF',
      field: 'tif',
      valueFormatter: (params: ValueFormatterParams) => {
         return TimeInForce[params.value];
      },
      filter: true
   });

   // Order ID
   cols.push({
      headerName: 'ID',
      field: 'orderId',
      filter: 'agTextColumnFilter',
   });

   // Convert To Market
   cols.push({
      headerName: 'Convert To Market',
      field: 'convertToMarketSettings',
      valueGetter: (args: ValueGetterParams) => {
         const stngs = JSON.parse(args.data.convertToMarketSettings) as FutureTimeSettings;
         if (!stngs) {
            return null;
         }

         if (!stngs.actionTimeMode) {
            return null;
         }
         
         if (stngs.actionTimeMode.endsWith(' At')) {
            return `at ${stngs.actionTime} (${stngs.timezone})`;
         }

         if (stngs.actionTimeMode.endsWith(' After')) {
            return `after ${stngs.actionTime}`;
         }

         return null;
      }
   });
   
   // Place Order
   cols.push({
      
      headerName: 'Place Order',
      
      field: 'futureSettings',
      
      valueGetter: (args: ValueGetterParams) => {
         const stngs = JSON.parse(args.data.futureSettings) as FutureTimeSettings;
         if (!stngs) {
            return null;
         }

         if (!stngs.actionTimeMode) {
            return null;
         }
         
         if (stngs.actionTimeMode.endsWith(' At')) {
            return `at ${stngs.actionTime} (${stngs.timezone})`;
         }

         if (stngs.actionTimeMode.endsWith(' After')) {
            return `after ${stngs.actionTime}`;
         }

         return null;
      }

   });

   cols.push({
      headerName: 'Combo Group',
      field: 'comboGroupName',
      rowGroup: true,
      groupId: 0
   })
   return cols;
}
