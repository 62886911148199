import { Component, ViewChild } from '@angular/core';
import { SymbolPickerComponent } from 'projects/shared-components/symbol-picker/symbol-picker.component';
import { ParametersViewBase } from '../../parameters-view-base';
import { HedgeAlgoParametersControl } from './hedge-algo-parameters-control';

@Component({
   selector: 'ets-hedge-parameters-view',
   templateUrl: './hedge-parameters-view.component.html',
   styleUrls: ['./hedge-parameters-view.component.scss']
})
export class HedgeParametersViewComponent extends ParametersViewBase<HedgeAlgoParametersControl> {
   
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;
   
   onEtsInit() { }
   
   onEtsAfterViewInit() { }
}
