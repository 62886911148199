import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { IPanelComponent } from 'projects/shared-components/panels/panel-component.interface';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { ComboDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, getShortUUID, isVoid } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'ets-automation-cp-strategy-messages-section',
   templateUrl: './strategy-messages-section.component.html',
   styleUrls: ['./strategy-messages-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyMessagesSectionComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();

   readonly scope: IPanelComponent = {
      layoutTabId: getShortUUID()
   };

   @Input()
   combo: ComboDto;

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   header = 'Strategy Messages';

   sectionHeight = 150;

   //
   ngOnInit(): void {
      this._cpMessageBus
         .when<any>('AdjustmentStrategyLoaded')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onStrategyChanged(msg));

      this._cpMessageBus
         .when<any>('AdjustmentStrategyCreated')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onStrategyChanged(msg));

      this._cpMessageBus
         .when<any>('AdjustmentStrategyRemoved')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onStrategyChanged(msg));
   }

   //
   onStrategyChanged(msg: {strategy: StrategyDto}) {
    
      this._messageBus.publishAsync({
         topic: 'StrategyHighlightedUIMessage',
         payload: {
            strategyId: msg.strategy.strategyId
         },
         scopeId: this.scope.layoutTabId
      })
   }

   //
   onStrategyRemoved() {
    
      this._messageBus.publishAsync({
         topic: 'StrategyHighlightedUIMessage',
         payload: {
            strategyId: null
         },
         scopeId: this.scope.layoutTabId
      })
   }

   @DetectMethodChanges()
   onSectionCollapsedChanged(ev) {
      if (ev) {
         this.sectionHeight = 0;
      } else {
         this.sectionHeight = 150;
      }
   }
}
