// tslint:disable:no-bitwise
export enum StrategyFlags {
  None = 0,
  MaxPositionViolation = 1 << 0,
  PauseAutoSchedule = 1 << 1,
  RiskRuleViolation = 1 << 2,
  MaxPositionViolationsExceeded = 1 << 3,
  Frozen = 1 << 4,
  SessionTO = 1 << 5,
  AccumulatedTO = 1 << 6,
  ViolateMaxPosition = 1 << 7,
}
