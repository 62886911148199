import { GridOptions, RowNode, GetContextMenuItemsParams, ColDef, ValueFormatterParams, CellClassParams, ValueGetterParams } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate, defaultPriceCellFormatter, liveQuoteFormatter } from 'projects/shared-components/ag-grid-contrib';
import { PortfolioItemDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { PortfolioSectionComponent } from './portfolio-section.component';
import { PortfolioSideboxComponent } from './sidebox/portfolio-sidebox.component';

export function getPortfolioSectionGridOptions(this: PortfolioSectionComponent|PortfolioSideboxComponent, client: 'section' | 'sidebox'): GridOptions {

   const gridOptions: GridOptions = {
      debug: true,

      rowData: [],

      defaultColDef: centeredColumnDef,
      
      columnDefs: getColumnDefs(client),

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,
      
      immutableData: true,

      rowClass: 'ets-text-centered',
      
      rowSelection: 'multiple',

      rowGroupPanelShow: 'never',

      groupUseEntireRow: false,

      suppressAggFuncInHeader: true,

      tooltipShowDelay: 1000,
      
      onGridReady: (args) => {
         this.onGridReady(args);
      },

      getRowNodeId: (data: PortfolioItemDto) => {
         return data.portfolioItemId;
      },

      postSort: (rowNodes: RowNode[]) => {

         const nextInsertPos = rowNodes.length;
         for (let i = rowNodes.length - 1; i >= 0; i--) {
             const pfItem: PortfolioItemDto = rowNodes[i].data;
             if (pfItem && pfItem.isHedgingItem) {
                if (i !== nextInsertPos) {
                   rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                }
             }
         }
      },

      onFirstDataRendered: (args) => {
         args.api.sizeColumnsToFit();
      },
      
      getContextMenuItems: (args: GetContextMenuItemsParams) => {
      
         if (!args.node) {
            return;
         }

         if (args.node.group) {
            return;
         }

         const pfItem = args.node.data as PortfolioItemDto;
         
         if (!pfItem) {
            return;
         }

         const menu = [];

         menu.push(
            'separator',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll',
         );

         return menu;
      },
   };

   return gridOptions;
}


function getColumnDefs(size: 'section' | 'sidebox'): Partial<ColDef>[] {

   const colDefs: ColDef[] = [];

   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: 'tickerDisplayName'
   };
   
   const positionCol: ColDef = {
      headerName: 'Position',
      field: 'netPosition',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.netPosition;
      }
   };

   const avgPxCol: ColDef = {
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data || isNaN(params.data.avgPx)) {
            return null;
         }
         return params.data.avgPx;
      },
      valueFormatter: defaultPriceCellFormatter,
   };

   const accountNameCol: ColDef = {
      headerName: 'Account',
      field: 'accountCode',
      minWidth: 180,
      sort: 'asc',
   };

   const openPrice: ColDef = {
      headerName: 'Open Px.',
      field: 'openPrice',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data || isNaN(params.data.openPrice)) {
            return null;
         }
         return params.data.openPrice;
      },
      valueFormatter: defaultPriceCellFormatter
   };

   const liveQuoteCol: ColDef = {
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter,
   };

   colDefs.push(symbolCol);
   
   colDefs.push(positionCol);
   
   if (size === 'section') {
      colDefs.push(avgPxCol);
      colDefs.push(openPrice);
      colDefs.push(accountNameCol);
   }
   
   colDefs.push(liveQuoteCol);

   return colDefs;
}
