<div class="panel-container" [ngClass]="{'ets-hidden': !this.isActive}" #chk>

   <fieldset class="bold">
      <div class="context-selector">
         <div class="block strategy">
            <span>Strategy: </span>
            <dx-select-box
               [items]="this.strategies"
               [(value)]="this.selectedStrategy"
               (onValueChanged)="this.onStrategySelected($event)">
            </dx-select-box>
         </div>
         <div class="block solution">
            <span>Adjustment:</span>
            <dx-select-box
               [disabled]="!this.canSelectAdjustmentStrategy"
               [items]="this.adjustments"
               [(value)]="this.selectedAdjustment"
               (onValueChanged)="this.onAdjustmentSelected($event)"
               >
            </dx-select-box>
         </div>
      </div>
   </fieldset>

   <div class="main-area">

      <div class="block items-list">
         
         <div class="buttons">
            <dx-button
               width="100%"
               text="Add Step"
               [disabled]="!this.canAddChecklistStep"
               (onClick)="this.addChecklistStep()"
               >
            </dx-button>
         </div>
         
         <div class="items">
            <hr class="separator">
            <div>
               <dx-list
                  [items]="this.checklistSteps"
                  [selectionMode]="'single'"
                  [(selectedItems)]="this.selectedChecklistSteps"
                  (onSelectionChanged)="this.onSelectedStepChanged($event)"
                  [displayExpr]="'name'"
                  (onItemReordered)="this.onChecklistStepsReordered($event)"
                  (onItemDeleted)="this.onChecklistStepDeleted($event)"
                  [itemDragging]="{allowReordering: true}"
                  [allowItemDeleting]="true"
                  [itemDeleteMode]="'toggle'"
                  >
                  <div *dxTemplate="let item of 'item'">
                     <div class="step-name-container">
                        <div class="name" [title]="item.name">
                           <span>{{item.name}}</span>
                        </div>
                        <div *ngIf="!item.isCleanCopy && !item.isDirty">&nbsp;&nbsp;&nbsp;[DRAFT]</div>
                        <div *ngIf="item.isDirty">&nbsp;&nbsp;&nbsp;[UNSAVED]</div>
                        <div class="draft-toggle">
                           <dx-check-box
                              [disabled]="item.isDirty"
                              class="ets-draft-toggle"
                              [value]="item.isCleanCopy"
                              (valueChange)="this.onChecklistDraftStateChange(item, $event)"
                              >
                           </dx-check-box>
                        </div>
                     </div>
                  </div>
               </dx-list>
            </div>
         </div>

         <div class="save">
            <dx-button
               [disabled]="!this.hasChanges"
               width="100%"
               text="Save Changes"
               (onClick)="this.saveAllChanges()"
               >
            </dx-button>
         </div>

      </div>

      <div class="block editor-area">
         
         <fieldset class="step-name">
            <legend>Step Name</legend>
            <dx-text-box
               [value]="this.selectedChecklistStep?.name"
               (valueChange)="this.selectedChecklistStep ? this.selectedChecklistStep.name = $event : null"
               (onValueChanged)="this.onChange($event)"
               [disabled]="!this.checklistStepSelected"
               >
            </dx-text-box>
         </fieldset>
         
         <fieldset class="editor-section">
            <legend>Editor</legend>
            <div class="editor">
               <dx-html-editor
                  [disabled]="!this.checklistStepSelected"
                  height="100%"
                  valueType="html"
                  [value]="this.selectedChecklistStep?.markup"
                  (valueChange)="this.selectedChecklistStep
                                 ? this.selectedChecklistStep.markup = $event
                                 : null"
                  (onValueChanged)="this.onChange($event)"
                  >
                  <dxo-toolbar [multiline]="true">
                  <dxi-item name="undo"></dxi-item>
                  <dxi-item name="redo"></dxi-item>
                  
                  <dxi-item name="separator"></dxi-item>
                  
                  <dxi-item
                        name="size"
                        [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                  ></dxi-item>
                  <dxi-item
                     name="font"
                     [acceptedValues]="[
                        'Arial',
                        'Courier New',
                        'Georgia',
                        'Impact',
                        'Lucida Console',
                        'Tahoma',
                        'Times New Roman',
                        'Verdana'
                     ]"
                  ></dxi-item>
                  
                  <dxi-item name="separator"></dxi-item>
                  
                  <dxi-item name="bold"></dxi-item>
                  <dxi-item name="italic"></dxi-item>
                  <dxi-item name="strike"></dxi-item>
                  <dxi-item name="underline"></dxi-item>
                  
                  <dxi-item name="separator"></dxi-item>
                  
                  <dxi-item name="alignLeft"></dxi-item>
                  <dxi-item name="alignCenter"></dxi-item>
                  <dxi-item name="alignRight"></dxi-item>
                  <dxi-item name="alignJustify"></dxi-item>
                  
                  <dxi-item name="separator"></dxi-item>
                  
                  <dxi-item name="orderedList"></dxi-item>
                  <dxi-item name="bulletList"></dxi-item>
                  
                  <dxi-item name="separator"></dxi-item>
                  
                  <dxi-item
                     name="header"
                     [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                  ></dxi-item>
                  
                  <dxi-item name="separator"></dxi-item>
            
                  <dxi-item name="color"></dxi-item>
                  <dxi-item name="background"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="link"></dxi-item>
                  <dxi-item name="image"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="clear"></dxi-item>
                  <dxi-item name="codeBlock"></dxi-item>
                  <dxi-item name="blockquote"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="insertTable"></dxi-item>
                  <dxi-item name="deleteTable"></dxi-item>
                  <dxi-item name="insertRowAbove"></dxi-item>
                  <dxi-item name="insertRowBelow"></dxi-item>
                  <dxi-item name="deleteRow"></dxi-item>
                  <dxi-item name="insertColumnLeft"></dxi-item>
                  <dxi-item name="insertColumnRight"></dxi-item>
                  <dxi-item name="deleteColumn"></dxi-item>
                  </dxo-toolbar>
               </dx-html-editor>
            </div>
         </fieldset>
         
         <fieldset class="conditions-section">
            <legend>Conditions</legend>
            <div class="conditions">
               
               <!-- DTE -->
               <div class="condition">
                  <div class="label">DTE Greater Than:</div>
                  <div class="input dtegt">
                     <dx-number-box
                        [disabled]="!this.checklistStepSelected"
                        [value]="this.selectedChecklistStep?.dteGreaterThanCondition"
                        (valueChange)="this.selectedChecklistStep
                                       ? this.selectedChecklistStep.dteGreaterThanCondition = $event
                                       : null"
                        (onValueChanged)="this.onChange($event)"
                        [showClearButton]="true"
                        [min]="0"
                        [showSpinButtons]="true"
                        [useLargeSpinButtons]="true"
                        >
                     </dx-number-box>
                  </div>
               </div>

               <!-- Day Of Week -->
               <div class="condition">
                  <div class="label">Day Of Week</div>
                  <div class="input">
                     <ets-checklist-day-of-week-condition
                        [disabled]="!this.checklistStepSelected"
                        [value]="this.selectedChecklistStep?.dayOfWeekCondition"
                        (valueChange)="this.selectedChecklistStep
                                             ? this.selectedChecklistStep.dayOfWeekCondition = $event
                                             : null"
                        (valueChanged)="this.onChange($event)"
                        >
                     </ets-checklist-day-of-week-condition>
                  </div>
               </div>

               <!-- Day Of Year -->
               <div class="condition">
                  <div class="label">Day Of Year</div>
                  <div class="input">
                     <ets-checklist-day-of-year-condition
                        [disabled]="!this.checklistStepSelected"
                        [value]="this.selectedChecklistStep?.dayOfYearCondition"
                        (valueChange)="this.selectedChecklistStep
                                          ? this.selectedChecklistStep.dayOfYearCondition = $event
                                          : null"
                        (valueChanged)="this.onChange($event)"
                        >
                     </ets-checklist-day-of-year-condition>
                  </div>
               </div>

               <!-- Day Of Expiration -->
               <div class="condition">
                  <div class="label">Day Of Expiration</div>
                  <div class="input">
                     <ets-checklist-day-of-expiration-condition
                        [disabled]="!this.checklistStepSelected"
                        [value]="this.selectedChecklistStep?.dayOfExpirationCondition"
                        (valueChange)="this.selectedChecklistStep
                                             ? this.selectedChecklistStep.dayOfExpirationCondition = $event
                                             : null"
                        (valueChanged)="this.onChange($event)"
                        >
                     </ets-checklist-day-of-expiration-condition>
                  </div>
               </div>

               <!-- Protective options -->
               <div class="condition ">
                  <div class="label">Protective Options</div>
                  <div class="input protective-options">
                     <ets-checklist-protective-options-condition
                        [disabled]="!this.checklistStepSelected"
                        [value]="this.selectedChecklistStep?.protectiveOptionsCondition"
                        (valueChange)="this.selectedChecklistStep
                                             ? this.selectedChecklistStep.protectiveOptionsCondition = $event
                                             : null"
                        (valueChanged)="this.onChange($event)"
                        >
                     </ets-checklist-protective-options-condition>
                  </div>
               </div>

               <!-- Dynamic Offsets -->
               <div class="condition">
                  <div class="label">Dynamic Offsets</div>
                  <div class="input">
                     <ets-dynamic-offsets-condition
                        [disabled]="!this.checklistStepSelected"
                        [value]="this.selectedChecklistStep?.dynamicOffsetsCondition"
                        (valueChange)="this.selectedChecklistStep
                                             ? this.selectedChecklistStep.dynamicOffsetsCondition = $event
                                             : null"
                        (valueChanged)="this.onChange($event)"
                        >
                     </ets-dynamic-offsets-condition>
                  </div>
               </div>
               
               <!--  Asset -->
               <div class="condition">
                  <div class="label">Asset</div>
                  <div class="input">
                     <ets-checklist-asset-condition
                        [comp]="this"
                        [step]="this.selectedChecklistStep"
                     >
                     </ets-checklist-asset-condition>
                  </div>
               </div>
               
               <!-- Extra POs -->
               <div class="condition">
                  <div class="label">Has Extra POs Qty</div>
                  <div class="input">
                     <ets-checklist-extra-pos-condition
                        [comp]="this"
                        [step]="this.selectedChecklistStep"
                     >
                     </ets-checklist-extra-pos-condition>
                  </div>
               </div>
               
               <!-- Extra POs -->
               <div class="condition">
                  <div class="label">Users</div>
                  <div class="input">
                     <ets-checklist-users-condition
                       [comp]="this"
                       [step]="this.selectedChecklistStep"
                     >
                     </ets-checklist-users-condition>
                  </div>
               </div>

            </div>
         </fieldset>

      </div>
   </div>

</div>
<dx-load-panel
   [position]="{my: 'center', at: 'center', of: chk}"
   [(visible)]="this.isLoading"
   >
</dx-load-panel>