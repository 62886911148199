import { NgModule } from '@angular/core';
import { RolloverSchedulerComponent } from './rollover-scheduler.component';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { RolloverContractGroupRowRendererComponent } from './rollover-contract-group-row-renderer';
import { TimeEditorComponent } from './time-editor.component';
import { TimeRendererComponent } from './time-renderer.component';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      DevExtremeModule,
      AgGridModule.withComponents([
         RolloverContractGroupRowRendererComponent,
         TimeEditorComponent,
         TimeRendererComponent
      ])
   ],
   exports: [
      RolloverSchedulerComponent,
   ],
   declarations: [
      RolloverSchedulerComponent,
      RolloverContractGroupRowRendererComponent,
      TimeEditorComponent,
      TimeRendererComponent
   ],
   providers: [],
})
export class EtsRolloverSchedulerModule { }
