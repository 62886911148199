import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';

export class RemoveOpenPositionChartSubscription implements ProtocolCommand {
  constructor(
    public strategyId: string,
    public subscriberId: string) {}
  
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveOpenPositionChartSubscription`;
  }

}
