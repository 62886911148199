import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EtsSymbolPickerModule } from '../../../../shared-components/symbol-picker/symbol-picker.module';
import { TradingPadComponent } from './trading-pad.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
  imports: [
    CommonModule,
    DevExtremeModule,
    FormsModule,
    EtsSymbolPickerModule,
  ],

  declarations: [
    TradingPadComponent
  ],

  exports: [
    TradingPadComponent
  ]
})
export class EtsTradingPadModule { }
