<dx-popup
  (onHidden)="this.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [showTitle]="true"
  [visible]="this.visible"
  [shading]="false"
  height="480"
  title="Zones Grid Columns"
  width="300"
>
  <div *dxTemplate="let data of 'content'">
    <div class="container">
      <dx-scroll-view>
        
        <div class="section">
          <dx-check-box text="Select All Hedges"
                        [value]="this.allHedgesSelected"
                        (onValueChanged)="this.toggleSelectAllHedges($event)">
          </dx-check-box>
        </div>
        
        <div class="section hedge">
          <div class="title">Call Hedges</div>
          <div class="selector">
            <dx-list
              (onSelectionChanged)="this.onHedgeSelectionChanged($event)"
              [dataSource]="this.callHedgeList"
              [selectAllMode]="'allPages'"
              [selectedItems]="this.selectedCallHedges"
              [selectionMode]="'all'"
              [showSelectionControls]="true"
              displayExpr="label"
            ></dx-list>
          </div>
        </div>
        
        <div class="section hedge">
          <div class="title">Put Hedges</div>
          <div class="selector">
            <dx-list
              (onSelectionChanged)="this.onHedgeSelectionChanged($event)"
              [dataSource]="this.putHedgeList"
              [selectAllMode]="'allPages'"
              [selectedItems]="this.selectedPutHedges"
              [selectionMode]="'all'"
              [showSelectionControls]="true"
              displayExpr="label"
            ></dx-list>
          </div>
        </div>
        
        <div class="row">
          <hr>
        </div>
        
        <div class="section">
          <dx-check-box text="Select All Expirations"
                        [value]="this.allExpirationsSelected"
                        (onValueChanged)="this.toggleSelectAllExpirations($event)">
          </dx-check-box>
        </div>
        
        <div class="section expiration">
          <div class="title">Call Expirations</div>
          <div class="selector">
            <dx-list
              (onSelectionChanged)="this.onExpirationSelectionChanged($event)"
              [dataSource]="this.callExpirationList"
              [selectAllMode]="'allPages'"
              [selectedItems]="this.selectedCallExpirations"
              [selectionMode]="'all'"
              [showSelectionControls]="true"
              displayExpr="expirationFull"
            ></dx-list>
          </div>
        </div>
        
        <div class="section expiration">
          <div class="title">Put Expirations</div>
          <div class="selector">
            <dx-list
              (onSelectionChanged)="this.onExpirationSelectionChanged($event)"
              [dataSource]="this.putExpirationList"
              [selectAllMode]="'allPages'"
              [selectedItems]="this.selectedPutExpirations"
              [selectionMode]="'all'"
              [showSelectionControls]="true"
              displayExpr="expirationFull"
            ></dx-list>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>