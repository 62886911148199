<dx-popup class="popup"
          #popup
          [width]="300"
          [height]="450"
          [showTitle]="true"
          [title]="popupTitle"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="true"
          (onHidden)="close()">
  <div *dxTemplate="let data of 'content'">
    <div class="container" id="container">
      <div class="chart-details">
        <dx-text-box [placeholder]="chartNamePlaceholder"
                     [(value)]="chartTitle"
                     [disabled]="selectedChartType !== 'Combined'"
        ></dx-text-box>
        <div class="chart-and-pickers">
          <dx-select-box
            class="chart-type"
            [items]="chartTypeItems"
            [(value)]="selectedChartType"
            [placeholder]="chartTypePlaceholder"
          ></dx-select-box>
          <dx-select-box
            class="chart-picker"
            [items]="['Session', 'Accumulated']"
            [(value)]="selectedDataType"
            placeholder="Select Data Type"
          ></dx-select-box>
        </div>
      </div>
      <div class="tree">
        <dx-tree-view [items]="treeItems"
                      [searchEnabled]="true"
                      [searchExpr]="'header'"
                      [keyExpr]="'id'"
                      [displayExpr]="'header'"
                      [itemsExpr]="'children'"
                      (onItemSelectionChanged)="onSelectionChanged($event)"
                      showCheckBoxesMode="normal">
        </dx-tree-view>
      </div>
      <div class="buttons">
        <dx-button text="Create"
                   type="normal"
                   [disabled]="!isSelectionValid"
                   (onClick)="createChart(false)"
        ></dx-button>
        <dx-button text="Create & Close"
                   type="normal"
                   [disabled]="!isSelectionValid"
                   (onClick)="createChart(true)"
        ></dx-button>
      </div>
      <dx-load-panel [visible]="isLoading"
                     [position]="{ of: '#container'}"
      ></dx-load-panel>
    </div>
  </div>
</dx-popup>