import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';

import { WatchlistComponent } from './watchlist.component';

@NgModule({
   imports: [
      CommonModule,
      AgGridModule,
      EtsSymbolPickerModule
   ],
   exports: [WatchlistComponent],
   declarations: [WatchlistComponent],
   providers: [],
})
export class WatchlistModule { }
