import * as internal from 'assert';
import { CashFlowAdjustmentLogicalId } from '../adjustment-control-panel/CashFlowAdjustmentId';
import { CashFlowStrategy } from '../adjustment-control-panel/cash-flow-strategy';
import { OptionType } from '../options-common/options.model';
import { BucketType, PortfolioItemType } from '../portfolios/portfolios.model';
import { StrategyState } from '../strategies/strategy-state.enum';
import { OrderDto } from './dtos/order-dto.class';
import { RiskManagerRuleDto } from './dtos/risk-manager-rule-dto.interface';
import { StrategyDto } from './dtos/strategy-dto.class';
import { TerminalDto } from './dtos/terminal-dto.class';
import { KeyValueDto } from './dtos/key-value-dto.interface';
import {
   SweetPriceSettings
} from "../adjustment-pricing-grid/positions-section/tracking-dialog/SweetPriceTrackingSettings";
import {HedgePosition} from "../hedging-grid/data-model/hedge-position";

export class PositionSizingRecordDto {
   recordId: string;
   strategyId: string;
   ticker: string;
   units: number;
   profit: number;
   qty: string;
   timestamp: Date;
   isSwitched: boolean;
   switchHappened: boolean;
   bucketProfit?: number;
   isPassiveRecord?: boolean;
}

export class ArchivedPositionDeleted {
   strategyId: string;
   positionId: string;
}

export interface StrategyHistoryInfoDto {
   sessionId: string;
   historyRecorId: number;
   strategyId: string;
   ticker: string;
   endDate: Date;
   sessionPnL: number;
   accPnL: number;
}

export interface TriggerStateChangedDto {
   triggerId: string;
   state: boolean;
}

export interface RolloverDescriptor {
   baseSymbol: string;
   ticker: string;
   baseOffset?: number;
   specificOffset?: number;
   isSession?: boolean;
   timeOfDay?: string;
   rolloverDate?: Date;
}

export class SyncOverrideToken {
   constructor(
      public accountId: string,
      public ticker: string,
      public price: number
   ) {
   }
}

export interface SyncOverrideRecordDto {
   syncOverrideRecordId: string;
   timestamp: Date;
   note: string;
   strategies: number;
   manualPositions: number;
   beforePnL: number;
   afterPnL: number;
}

export interface SyncOverrideRecordDetailDto {
   displayName: string;
   terminal: string;
   netPosition: number;
   beforePnL: number;
   afterPnL: number;
   type: 'manual' | 'strategy';
}

export class HasUnaccountedDataDto {

}

export interface CustomExitOrderDescriptor {
   qty: number;
   price?: number;
   points?: number;
}

export interface GreeksDto {
   strike: number;
   optionType: OptionType;
   ticker: string;
   delta: number;
   gamma: number;
   theta: number;
   vega: number;
   rho: number;
   impliedVolatility: number;
}

export type CashFlowStrategyRole =
     'Asset'
   | 'ShortOption'
   | 'SpreadLongLeg'
   | 'SpreadShortLeg'
   | 'SecondSpreadLongLeg'
   | 'SecondSpreadShortLeg'
   | 'ProtectiveOption'
   | 'SecondProtectiveOption';

export type HedgingStrategyLegRole =
     'Asset'
   | 'ShortOption'
   | 'SpreadLongLeg'
   | 'SpreadShortLeg'
   | 'SecondSpreadLongLeg'
   | 'SecondSpreadShortLeg'
   | 'ProtectiveOption'
   | 'SecondProtectiveOption';

export const BucketRoleColor = {
   ShortOption: 'orange',
   SpreadShortLeg: '#1e79ee',
   SpreadLongLeg: '#1e79ee',
   SecondSpreadShortLeg: '#00CCCC',
   SecondSpreadLongLeg: '#00CCCC',
   ProtectiveOption: 'magenta',
   SecondProtectiveOption: 'magenta',
}

export interface PortfolioItemDto {
   portfolioItemId: string;
   portfolioId: string;
   itemType: PortfolioItemType;
   underlying: string;
   ticker: string;
   netPosition: number;
   sessionPnL: number;
   accumulatedPnL: number;
   avgPx: number;
   isArchived: boolean;
   comboName?: string;
   comboId?: string;
   delta?: number;
   gamma?: number;
   vega?: number;
   theta?: number;
   tickerDisplayName: string;
   underlyingDisplayName: string;
   liveQuote?: number;
   accountId?: string;
   portfolioName?: string;
   comboGroupId: string;
   comboGroupName: string;
   terminalId: string;
   isHedgingItem: boolean;
   openPrice: number;
   seqNum: number;
   terminalName: string;
   lastMatchPx: number;
   lastOverSessionReset: boolean;
   isUnderAdjustment?: boolean;
   algoName?: string;
   strategyId?: string;
   accountCode?: string;
   strategyState?: StrategyState;
   lastTraded: Date;
   algoId: string;
   bucketRole: CashFlowStrategyRole;
}

export interface PortfolioDto {
   portfolioId: string;
   portfolioName: string;
   terminalId: string;
   description?: string;
   terminalName?: string;
   clientName?: string;
   shellName?: string;
   clientId?: string;
   shellId?: string;
   sessionPnL?: number;
   accumulatedPnL?: number;
   totalDelta?: number;
   liveQuote?: number;
   totalGamma?: number;
   totalVega?: number;
   totalTheta?: number;
   isArchived?: boolean;
   lastUpdate?: number;
   hasAttributes?: boolean;
}


export interface PortfolioCreatedDto {
   portfolio: PortfolioDto;
}


export interface PortfolioRemovedDto {
   portfolioId: string;
}


export interface PortfolioItemAddedDto {
   portfolioItem: PortfolioItemDto;
}


export interface PortfolioItemRemovedDto {
   portfolioItemId: string;
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   itemType: PortfolioItemType;
}


export interface PortfolioMessageDto {
   portfolioMessageId: number;
   portfolioId: string;
   messageText: string;
   timestamp: Date;
}


export interface RiskManagementRuleEditedDto {
   riskManagementRule: RiskManagerRuleDto;
}


export interface ComboDto {
   comboId: string;
   comboName: string;
   portfolioId: string;
   sessionPnL?: number;
   accumulatedPnL?: number;
   totalDelta?: number;
   liveQuote?: number;
   totalGamma?: number;
   totalVega?: number;
   totalTheta?: number;
   isArchived?: boolean;
   lastUpdate?: number;
   hasAttributes?: boolean;
   isCfsRunning?: boolean;
}


export interface GroupRenamedDto {
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   newGroupName: string;
   groupType: 'portfolio' | 'combo' | 'group';
}


export interface ComboRemovedDto {
   comboId: string;
}

export interface ComboGroupRemovedDto {
   comboId: string;
}


export interface LedgerRecord {
   recordId: number;
   transactionType: string;
   tickerDisplayName: string;
   transactionDate: Date;
   price: number;
   qty: number;
   portfolioName: string;
   comboName: string;
   comboGroupName: string;
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   terminalId: string;
   terminalName: string;
   netPosition: string;
   isEmulator: string;
   strategyName: string;
   strategyId: string;
   ticker: string;
   itemPnL: number;
   portfolioPnL: number;
   comboPnL: number;
   note: string;
   comboGroupPnL: number;
   accountId: string;
   accountName: string;

   // UI side only attribute, to distinguish which layout tab sent message to show note
   layoutId?: string;
   hasMoreRecords?: boolean;
}

export interface ComboGroupDto {
   comboGroupId: string;
   comboGroupName: string;
   comboId: string;
   portfolioId: string;
   sessionPnL?: number;
   accumulatedPnL?: number;
   totalDelta?: number;
   liveQuote?: number;
   totalGamma?: number;
   totalVega?: number;
   totalTheta?: number;
   isArchived?: boolean;
   lastUpdate?: number;
   hasAttributes?: boolean;
   runningStrategies?: string;
}

export interface ComboGroupCreatedDto {
   comboGroup: ComboGroupDto;
}

export interface ComboCreatedDto {
   combo: ComboDto;
}


export interface GetAvailableBucketsReply {
   portfolios: PortfolioDto[];
   combos: ComboDto[];
   comboGroups: ComboGroupDto[];
}

export interface GroupDeletedDto {
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   groupType: 'portfolio' | 'combo' | 'group';
}


export interface LedgerRecordDto {
   record: LedgerRecord;
}


export interface SessionHistoryStrategyInfoDto {
   sessionHistoryStrategyInfoId: number;
   sessionHistoryId: string;
   strategyId: string;
   terminalId: string;
   ticker: string;
   displayName: string;
   sessionPnL: number;
   accumulatedPnL: number;
   terminalCode: string;
   manualPositionAccountName: string;
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   portfolioName: string;
   comboName: string;
   comboGroupName: string;

   // ui only
   itemType: 'Strategy' | 'Stock' | 'Future' | 'Call' | 'Put';
   wasActiveInSession: boolean;
   dispositionId?: string;
   isDisposition?: boolean;
}

export interface SessionHistoryDataDto {
   sessionId: string;
   startDate: Date;
   endDate?: Date;
   sessionPnL: number;
   accumulatedPnL: number;
   terminalId: string;
   terminalCode: string;
}

export interface SessionEndedDto {
   sessionId: string;
   terminalCode: string;
   endDate: Date;
   sessionPnL: number;
   accumulatedPnL: number;
   shellId: string;
   clientId: string;
   shellName: string;
   clientName: string;
   terminalId: string;
}

export interface SessionCreatedDto {
   sessionId: string;
   terminalCode: string;
   startDate: Date;
   terminalId: string;
}

export interface ServerTimeMessageDto {
   time: Date;
   country?: string;
   timeZone?: string;
   isFakeTime: boolean;
}

export interface StrategyPriceDto {
   strategyCode: string;
   price: number;
}

export interface ResubscribeOptionStrategiesDto {
   shellId: string;
   eventId: string;
   shellName: string;
   clientName: string;
}

export interface BucketSummaryDto {
   terminalId: string;
   portfolioId: string;
   bucketType: BucketType;
   comboId: string;
   comboGroupId: string;
   sessionTotalPnL: number;
   accumulatedTotalPnL: number;
   totalDelta: number;
   lastUpdate: number;
   terminalName?: string;
   portfolioName?: string;
   comboName?: string;
   comboGroupName?: string;
   shellId?: string;
   clientId?: string;
   shellName?: string;
   clientName?: string;
}


export interface ExpirationItem {
   positionId: string;
   expirationPrice: number;
   expirationTime: string;
}


export interface PositionRemoved {
   positionId: string;
   portfolioId: string;
   comboId: string;
   comboGroupId: string;
   strategyId: string;
}

export interface GuiOrderConfirmationDto {
   guiToken: string;
   title: string;
   message: string;
   isNegative: boolean;
}

export interface SimexMatchingRuleDto {
   symbol?: string;
   kind?: string;
   price?: 'MID' | 'LAST' | 'BOOK';
   tradeThrough?: boolean;
}

export interface OptionExpirationDescriptor {
   optionExpirationDate: string;
   optionExpirationDisplayDate: string;
   expirationTicker: string;
   underlyingTicker: string;
   underlyingSymbol: string;
   dateWithDaysToExpiration: string;
   daysToExpiration: number;
   strikes: number[];
   strikeStep?: number;
}

export interface GetOptionChainShellResponse {
   expirations: OptionExpirationDescriptor[];
}

export interface UserSettingsDto {
   settingsByUser: KeyValueDto[];
}

export interface JobProgressDto {
   jobId: string;
   progress: number;
   message: string;
   timestamp: Date;
   jobName: string;
   hasErrors: boolean;
}

export interface StrategyArchivedDto {
   strategyId: string;
   dispositionId: string;
}


export interface ShellMessageDto {
   shellMessageId?: number;
   timestamp?: Date;
   level?: 'Critical' | 'Success' | 'Info' | 'Warning';
   domain?: string;
   source?: string;
   message?: string;
   isRead?: boolean;
   sticky?: boolean;
   shellId?: string;
   clientId?: string;
   eventId?: string;
   shellName?: string;
   clientName?: string;
   tags?: string;
}

export interface FutureTimeSettings {
   actionTime?: string;
   actionTimeMode?: 'Convert At' | 'Convert After';
   timezone?: string;
}

export interface ConvertToMarketSettings {
   actionTime?: string;
   actionTimeMode?: 'Convert At' | 'Convert After';
   timezone?: string;
   replaceBeforeConvert?: boolean;
   timesToReplace?: number;
   replacePersistently?: boolean;
   rateOfChange?: number;
   replaceEvery?: string;
   reverseTimeDirection?: boolean;
}

export interface StrategySessionActivityMessageDto {
   strategyId: string;
   dispositionId?: string;
   hasBeenActive?: boolean;
}

//

export interface InterestBlockDto {
   strategyId: string;
   displayName: string;
   comboGroupId: string;
}

export interface PortfolioBlockDto {
   portfolioItems: PortfolioItemDto[];
   underlying: string;
   displayName: string;
   comboGroupId: string;
}

export interface ShortTermDebitSpreadBlockDto {
   spreadAdjustmentStrategyId: string;
   spreadAdjustmentStrategyId2: string;
   spreadRollStrategyId: string;
   spreadRollStrategyId2: string;
   underlying: string;
   displayName: string;
   displayName2: string;
   comboGroupId: string;
   comboGroupId2: string;
   legs: PortfolioItemDto[];
   legs2: PortfolioItemDto[];
}

export interface LongTermPutBlockDto {
   legToRoll: PortfolioItemDto;
   legToRoll2: PortfolioItemDto;
   displayName: string;
   displayName2: string;
   comboGroupId: string;
   comboGroupId2: string;
   strategyId: string;
   strategyId2: string;
}

export interface SellOptionsBlockDto {
   strategyId: string;
   strategyId2: string;
   optionType: string;
   optionType2: string;
   underlying: string;
   displayName: string;
   displayName2: string;
   comboGroupId: string;
   comboGroupId2: string;
   leg: PortfolioItemDto;
   leg2: PortfolioItemDto;
}

export interface OrdersBlockDto {
   orders: OrderDto[];
}

//
export interface GetComboDataReply {
   interestBlock: InterestBlockDto;
   portfolioBlock: PortfolioBlockDto;
   shortTermDebitSpreadBlock: ShortTermDebitSpreadBlockDto;
   longTermPutBlock: LongTermPutBlockDto;
   sellOptionsBlock: SellOptionsBlockDto;
   ordersBlock: OrdersBlockDto;
   attributes: BucketAttributes;
}

//
export interface ComboBucketCfsStatusChangedDto {
    comboId: string;
    hasRunningStrategies: boolean;
}

//
export interface ComboGroupRunningCfsStrategiesDto {
   comboGroupId: string;
   runningStrategies: string;
}

//
export interface BucketAttributes {
   qtyGuard?: number;
   positionSizing?: string
}

//
export interface GetDebitSpreadSectionDataModelReply {
   hasErrors?: boolean;

   shortOptionOffset?: number;
   spreadWidth?: number;
   rollXDaysBeforeExpiration?: number;
   rollToDaysToExpiration?: number;

   shortOptionOffset2?: number;
   spreadWidth2?: number;
   rollXDaysBeforeExpiration2?: number;
   rollToDaysToExpiration2?: number;

   legs?: PortfolioItemDto[];
   legs2?: PortfolioItemDto[];

   comboGroupId?: string;
   comboGroupId2?: string;
}

//
export interface GetProtectiveOptionSectionDataModelReply {
   hasErrors?: boolean;

   spreadOffset?: number;
   rollXDaysBeforeExpiration?: number;
   rollToDaysToExpiration?: number;

   spreadOffset2?: number;
   rollXDaysBeforeExpiration2?: number;
   rollToDaysToExpiration2?: number;

   leg?: PortfolioItemDto;
   leg2?: PortfolioItemDto;

   comboGroupId?: string;
   comboGroupId2?: string;
}

//
export interface ShortOptionOverviewBlockDto {
   header: string;
   header2: string;
   comboGroupId: string;
   comboGroupId2: string;
   leg: PortfolioItemDto;
   leg2: PortfolioItemDto;
}

export interface ProtectiveOptionOverviewBlockDto {
   header: string;
   header2: string;
   comboGroupId: string;
   comboGroupId2: string;
   leg: PortfolioItemDto;
   leg2: PortfolioItemDto;
}

export interface DebitSpreadOverviewBlockDto {
   header: string;
   header2: string;
   comboGroupId: string;
   comboGroupId2: string;
   longLeg: PortfolioItemDto;
   shortLeg: PortfolioItemDto;
   longLeg2: PortfolioItemDto;
   shortLeg2: PortfolioItemDto;
}

export interface GlobalParametersOverviewBlockDto {
   header: string;

   //
   adjustmentTimeMode: string;
   adjustmentTime: string;
   adjustmentTimezone: string;

   //
   orderType: string;
   limitPrice: string;
   convertToMarket: boolean;
   convertMode: string;
   convertTime: string;
   convertTimezone: string;
   replaceBeforeConvert: boolean;
   timesToReplace: number;
   replacePersistently: boolean;
   replaceEvery: string;
   rateOfChange: number;
   reverseTimeDirection: boolean;

   //
   esmSpread: string;
   esm2ndSpread: string;
   esmProtectiveOption: string;
   esm2ndProtectiveOption: string;
}

export interface ShortOptionParametersOverviewBlockDto {
   header: string;
   header2: string;
}

export interface DebitSpreadParametersOverviewBlockDto {
   header: string;
   header2: string;
   shortOptionOffset: number;
   shortOptionOffset2: number;
   width: number;
   width2: number;
   rollXDaysBeforeExpiration: number;
   rollXDaysBeforeExpiration2: number;
   rollDaysToExpiration: number;
   rollDaysToExpiration2: number;
}

export interface ProtectiveOptionParametersOverviewBlockDto {
   header: string;
   header2: string;
   spreadOffset: number;
   spreadOffset2: number;
   rollXDaysBeforeExpiration: number;
   rollXDaysBeforeExpiration2: number;
   rollDaysToExpiration: number;
   rollDaysToExpiration2: number;
}

export interface InterestOverviewBlockDto {
   header: string;
   strategyId: string;
   isStrategyRunning: boolean;
   amount: number;
   interestRate: number;
   interval: string;
   account: number;
   chargeTime: string;
   chargeTimezone: string;
}

export interface PortfolioOverviewBlockDto {
   comboGroupId: string;
   items: PortfolioItemDto[];
}

export interface GetOverviewSectionDataModelReply {
   hasErrors: boolean;
   shortOptionBlock: ShortOptionOverviewBlockDto;
   protectiveOptionBlock: ProtectiveOptionOverviewBlockDto;
   debitSpreadOptionBlock: DebitSpreadOverviewBlockDto;
   globalParametersBlock: GlobalParametersOverviewBlockDto;
   shortOptionParametersBlock: ShortOptionParametersOverviewBlockDto;
   debitSpreadParametersBlock: DebitSpreadParametersOverviewBlockDto;
   protectiveOptionParametersBlock: ProtectiveOptionParametersOverviewBlockDto;
   interestOverviewBlock: InterestOverviewBlockDto;
   portfolioOverviewBlock: PortfolioOverviewBlockDto;
}

export interface InterestParameters {
   amount: number;
   rate: number;
   repeat: 'Daily' | 'Weekly';
   timeOfDay: string;
   timezone: string;
   everyXDay: number;
   everyXWeek: number;
   account: string;
   mon: boolean;
   tue: boolean;
   wed: boolean;
   thu: boolean;
   fri: boolean;
}

//
export interface GetInterestSecionDataModelReply {
   hasErrors: boolean;
   sidebox: InterestOverviewBlockDto;
   parameters: InterestParameters;
   strategy: StrategyDto;
}

//
export interface GetPortfolioSectionDataModelReply {
   hasErrors: boolean;
   comboGroupId: string;
   items: PortfolioItemDto[];
}

//
export interface GetWorkingOrdersSectionDataModelReply {
   hasErrors: boolean;
   orders: OrderDto[];
   availableBuckets: GetAvailableBucketsReply;
}

//
export interface GetShortOptionSectionDataModelReply {
   hasErrors: boolean;

   leg: PortfolioItemDto;
   leg2: PortfolioItemDto;

   comboGroupId: string;
   comboGroupId2: string;
}

//
export interface GetGlobalSettingsSectionDataModelReply {
   hasErrors?: boolean;
   expirationSmartMode?: ExpirationSmartModeDto;
   orderTypeSettings?: OrderTypeSettingsDto;
   adjustmentTimeSettings?: AdjustmentTimeSettingsDto;
   comparisonPricingSettings?: ComparisonPricingSettingsDto;
   sweetSpotAdjustmentSettings?: SweetSpotAdjustmentSettingsDto;
}

export interface SweetSpotAdjustmentSettingsDto {
   aboveBufferStart?: number;
   aboveBufferEnd?: number;
   belowBufferStart?: number;
   belowBufferEnd?: number;
   startTime?: string;
   endTime?: string;
   timezone?: string;
}

export interface AdjustmentTimeSettingsDto {
   adjustmentTimeMode: string;
   adjustmentTime: string;
   adjustmentTimezone: string;
   joinRollBuffer?: number;
}

export interface OrderTypeSettingsDto {
   orderType?: string;
   limitPrice?: string;
   convertToMarket?: boolean;
   convertToMarketSettings?: ConvertToMarketSettings;
}

export interface SmartSettingsDto {
   isEnabled: boolean;
   back: number;
   forth: number;
}

export interface ExpirationSmartModeDto {
   spread?: SmartSettingsDto;
   secondSpread?: SmartSettingsDto;
   protectiveOption?: SmartSettingsDto;
   secondProtectiveOption?: SmartSettingsDto;
}

export interface ComparisonPricingSettingsDto {
   expirationsLookForward?: number;
   pricingMode?: 'Better' | 'Breakeven Or Better',
   priceBuffer?: number;
   differentialBuffer?: number;
}

//
export interface GetStrategyControlsSectionDataModelReply {
   strategy: StrategyDto;
}

//
export interface CashFlowTrackingRecordDto {
   cashFlowTrackingRecordId: number;
   clientId: string;
   shellId: string;
   portfolioId: string;
   comboId: string;
   portfolioName: string;
   comboName: string;
   isAutomatic: boolean;
   transactionDate: Date;
   expectedPremium: number;
   factPremium: number;
   underlyingPrice: number;
   strategy: CashFlowStrategy;
   adjustmentCode: string;
   expiration: string;
   daysToExpiration: number;
   pricingOption: string;
   orderId: string;
   orderRole: 'M' | 'L1' | 'L2';
   orderLegs: string;
   slippage: number;
   sessionComboPnL: number;
   accumulatedComboPnL: number;
   totalOrders: number;
   adjustmentSolutionSnapshot: string;
   orderType: string;
   adjustmentOperationId: string;
   expirationSeqNo: number;
}

//
export interface ClientForAdjustmentTemplate {
   clientId: string;
   clientName: string;
   isSelected: boolean;
}

//
export interface AdjsutmentTemplateToClientsMap {
   templateId: string;
   templateName: string;
   clients: ClientForAdjustmentTemplate[];
}

//
export interface GetAssignableTemplatesReply {
   templates: AdjsutmentTemplateToClientsMap[];
}

//
export interface GetAssignedAdjustmentTemplatesReply {
   templates: string[];
}

//
export interface ChecklistStepDto {
   checklistStepId: number;
   name: string;
   strategy: CashFlowStrategy;
   adjustment: CashFlowAdjustmentLogicalId;
   markup: string;
   seqNo: number;
   isCleanCopy: boolean;

   dteGreaterThanCondition: number;
   dayOfWeekCondition: string;
   dayOfYearCondition: string;
   dayOfExpirationCondition: string;
   protectiveOptionsCondition: string;
   dynamicOffsetsCondition: string;
   assetCondition?: string;
   userCondition?: string;
   hasExtraPosCondition: boolean;
}

//
export interface SaveChecklistStepShellReply {
   ids: {item1: number, item2: number}[];
}

//
export interface GetChecklistStepsShellReply {
   steps: ChecklistStepDto[];
}

//
export interface GetTerminalsForAutomationCpReply {
   terminals: TerminalDto[];
}

//
export interface GetPortfoliosForAutomationCpReply {
   portfolios: PortfolioDto[];
}

//
export interface GetCombosForAutomationCpReply {
   combos: ComboDto[];
}

//
export interface GetBucketSummaryForAutomationCpReply {
   sessionPnL: number;
   accumulatedPnL: number;
}

//
export interface ApgPortfolioStateChangedDto {
   apgPortfolioId: string;
   userId: string;
}

//
export interface SweetPriceAvailableDto {
   expiration: string;
   price: number;
   adjustment: string;
}

//
export interface ApgDefaultSettingsDto {
   underlying: string;
   settings: string;
}

export type TrackingTemplate = {
   templateName: string;
   templateId: string;
};

export interface TrackingStartedMessage {
   positions: string;
   sweetPriceSettings: SweetPriceSettings;
   id: string;
   template: TrackingTemplate,
}

export interface TrackingStoppedMessage {
   id: string;
   template: TrackingTemplate
}

export interface TrackingRequestErrorMessage {
   requestId: string;
   message: string;
   origin: 'portfolio' | 'template';
}

export interface TrackingTemplateUpdated {
   message: string;
}

export interface TrackingNotificationSettingsUpdated {
   message: string;
}

export interface ReloadResourcesDto {
   shellId: string;
   eventId: string;
   shellName: string;
   clientName: string;
}

export interface QuoteLimitDto {
   count: string;
}

export interface UserSettingDto {
   userId: string;
   key: string;
   value: string;
}

export interface UserSettingsChangedDto {
   settings: UserSettingDto[];
}