import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { DetectMethodChanges, getBucketRoleClass, makeFullExpirationDate, makeGuiFriendlyExpirationDate } from 'projects/shared-components/utils';
import { SolutionPositionDto } from '../model/SolutionPositionDto';

@Component({
   selector: 'ets-apg-after-state-preview',
   templateUrl: './apg-after-state-preview.component.html',
   styleUrls: [
      './apg-after-state-preview.component.scss',
      '../adjustment-grid-common-style.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApgAfterStatePreviewComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   @ViewChild(DxPopupComponent) popup: DxPopupComponent;

   title = "After State Preview";
   
   width = 400;
   
   get height(): number {
      let value = 320;
      
      if (this.state.length <= 4) {
         return value;
      }

      const extraLegs = this.state.length - 4;

      const addition = extraLegs * 27;

      return value + addition;
   }

   btnApplyText = 'Apply';
   btnCloseText = 'Close';

   state: SolutionPositionDto[] = [];
   adjustment: string;

   ngOnInit(): void { }

   @DetectMethodChanges()
   show(state: {adjustmentDescriptor: string, positions: SolutionPositionDto[]}): Promise<void> {
      
      this.state = state.positions;

      this.state.forEach(dto => {
         dto.expirationFull = makeFullExpirationDate(dto.expiration);
      });

      this.adjustment = state.adjustmentDescriptor;

      this.popup.visible = true;

      return new Promise<void>( (res, rej) => {
         this._resolver = res;
         this._rejector = rej;
      });
   }

   getRowRoleClass(item: SolutionPositionDto) {
      return getBucketRoleClass(item);
   }

   onApplyClicked() {
      if (this._resolver) {
         this._resolver();
      }
      this.closePreview();
   }

   onCloseClicked() {
      if (this._rejector) {
         this._rejector();
      }
      this.closePreview();
   }

   @DetectMethodChanges()
   private closePreview() {
      this._resolver = null;
      this._rejector = null;
      this.popup.visible = false;
   }

   private _resolver: (value: void | PromiseLike<void>) => void;
   private _rejector: (reason?: any) => void;

}
