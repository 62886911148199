import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {MessageBusService} from "../../../message-bus.service";
import {isValidNumber, isVoid} from "../../../utils";
import {Subscription} from "rxjs";
import {ZonesGridColumn, ZonesGridComponent} from "../zones-grid.component";
import {CashFlowAdjustment} from "../../model/CashFlowAdjustment";
import {AdjustmentPricingSettingsDto} from "../../../shell-communication/shell-operations-protocol";

@Component({
    selector: 'ets-zones-grid-cell',
    templateUrl: 'zones-grid-cell.component.html',
    styleUrls: [
        'zones-grid-cell.component.scss',
        '../../adjustment-grid-common-style.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZonesGridCellComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private readonly _changeDetector: ChangeDetectorRef,
                private readonly _messageBus: MessageBusService
    ) {
    }

    private _subscription: Subscription;

    @Input() column: ZonesGridColumn;
    @Input() comp: ZonesGridComponent;

    ngOnInit() {
        this._subscription = this._messageBus
            .of<string[]>('Apg.StrategyPriceUpdated')
            .subscribe(msg => this.onStrategyPriceUpdated(msg.payload));
    }

    ngOnDestroy() {
        if (isVoid(this._subscription)) {
            return;
        }
        this._subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this._changeDetector.detach();
    }

    getPriceColorClass(price: number) {
        if (!price) {
            return undefined;
        }

        if (price > 0) {
            return 'credit';
        }

        if (price < 0) {
            return 'debit';
        }

        return undefined;
    }

    hasTheoPrices(adjustments: CashFlowAdjustment[]): boolean {
        if (isVoid(adjustments)) {
            return false;
        }


        const lastUsedSettings = this.comp.root.zonesGridSection.lastUsedSettings;
        if (isVoid(lastUsedSettings)) {
            if (this.comp.root.settingsSection.globalSettings.theoreticalPriceMode !== 'Fill Blanks') {
                return false;
            }
        } else {
            if (lastUsedSettings[0].theoreticalPriceMode !== 'Fill Blanks') {
                return false;
            }
        }

        return adjustments.some(x => x.optionStrategyCode.indexOf('!') >= 0);
    }


    private onStrategyPriceUpdated(payload: string[]) {
        if (isVoid(this.column)) {
            return;
        }

        const hasIt = this.column.adjustments.some(x => payload.indexOf(x.optionStrategyCode) >= 0);

        if (hasIt) {
            this._changeDetector.detectChanges();
        }
    }

    isEmptyCell(column: ZonesGridColumn) {
        if (isVoid(column)) {
            return true;
        }

        if (column.isEmpty) {
            return true;
        }

        if (!isValidNumber(column.price)) {
            return true;
        }

        return false;
    }

    doesViolateSpreadOffset(adjustments: CashFlowAdjustment[]) {
        return false;
    }

    doesViolateSpreadWidth(adjustments: CashFlowAdjustment[]) {
        return false;
    }
}
