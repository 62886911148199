<div class="root-container">
  
  <div class="positions">
    
    <div [ngClass]="{'right': this.orientation === 'right'}"
         class="section-caption full"
    >
      <div class="header">
            <span (click)="this.viewModel.collapseWholeColumn()">
               Positions
            </span>
      </div>
      <div (click)="this.viewModel.toggleCtxMenu()" class="menu">
        <div>
          <i class="fas fa-bars"></i>
        </div>
        <div #ctxMenuPlaceHolder></div>
      </div>
    </div>
    
    <!-- Strike Lock -->
    <div *ngIf="!this.viewModel.isForceApply" class="apply-section">
      <div class="part apply">
        <dx-button
          (onClick)="this.viewModel.applyButtonClicked()"
          [disabled]="this.viewModel.isApplyButtonDisabled"
          [type]="this.viewModel.isApplyButtonDisabled ? 'normal' : 'default' "
          text="Apply"
          width="100%">
        </dx-button>
      </div>
      <div *ngIf="this.viewModel.isLiveUpdateAvailable" class="part live">
        <dx-check-box [(value)]="this.viewModel.liveStrikesUpdate" text="Live"></dx-check-box>
      </div>
      <div *ngIf="this.viewModel.isStrikesControlAvailable" class="part step">
        <div [ngClass]="{'linked': this.viewModel.isLinkStrikesEnabled}"
             [style.visibility]="this.viewModel.strikeStepVisibility"
             class="part label"
        >
          Strike Step
        </div>
        <div [style.visibility]="this.viewModel.strikeStepVisibility" class="part input">
          <dx-drop-down-box
            [disabled]="!this.viewModel.canChangeStrikes"
            [dropDownOptions]="{minWidth: 90}"
            [items]="this.viewModel?.strikesList"
            [value]="this.viewModel?.strikeStep"
          >
            
            <div *dxTemplate="let data of 'field'">
              <dx-text-box [value]="this.viewModel?.strikeStep + ''"></dx-text-box>
            </div>
            
            <div *dxTemplate="let data of 'content'" class="strike-dd">
              
              <div class="strike-button">
                
                <div (click)="this.viewModel.changeStrikeStep(1, '-', this.viewModel.underlying)"
                     class="box sign minus">
                  <i class="fas fa-minus"></i>
                </div>
                <div class="box strike-step">{{this.viewModel.underlying === 'SPX' ? 5 : 1 }}</div>
                <div (click)="this.viewModel.changeStrikeStep(1, '+', this.viewModel.underlying)"
                     class="box sign plus">
                  <i class="fas fa-plus"></i>
                </div>
              
              </div>
              
              <div class="strike-button">
                <div (click)="this.viewModel.changeStrikeStep(5, '-', this.viewModel.underlying)"
                     class="box sign minus">
                  <i class="fas fa-minus"></i>
                </div>
                <div class="box strike-step">{{this.viewModel.underlying === 'SPX' ? 10 : 5 }}</div>
                <div (click)="this.viewModel.changeStrikeStep(5, '+', this.viewModel.underlying)"
                     class="box sign plus">
                  <i class="fas fa-plus"></i>
                </div>
              </div>
              
              <div class="strike-button">
                <div (click)="this.viewModel.changeStrikeStep(10, '-', this.viewModel.underlying)"
                     class="box sign minus">
                  <i class="fas fa-minus"></i>
                </div>
                <div class="box strike-step">{{this.viewModel.underlying === 'SPX' ? 50 : 10 }}</div>
                <div (click)="this.viewModel.changeStrikeStep(10, '+', this.viewModel.underlying)"
                     class="box sign plus">
                  <i class="fas fa-plus"></i>
                </div>
              </div>
              
              <div class="strike-button">
                <div (click)="this.viewModel.changeStrikeStep(50, '-', this.viewModel.underlying)"
                     class="box sign minus">
                  <i class="fas fa-minus"></i>
                </div>
                <div class="box strike-step">{{this.viewModel.underlying === 'SPX' ? 100 : 50 }}</div>
                <div (click)="this.viewModel.changeStrikeStep(50, '+', this.viewModel.underlying)"
                     class="box sign plus">
                  <i class="fas fa-plus"></i>
                </div>
              </div>
            
            </div>
          
          </dx-drop-down-box>
        </div>
        
        <div class="part buttons">
          <div class="up-down">
            <div [style.visibility]="this.viewModel.strikesShiftButtonsVisibility" class="btn up" title="Market Up">
              <i (click)="this.viewModel.strikeDown()" class="fas fa-arrow-up fa-lg"></i>
            </div>
            <div (dblclick)="this.viewModel.showManualAtmDistanceInput()"
                 [style.visibility]="this.viewModel.atmDistanceIndicatorVisibility"
                 class="btn distance"
            >
              <div [style.display]="this.viewModel.manualAtmDistanceDisplay" class="atmInput">
                <dx-number-box #manualDistanceInput
                               (onEnterKey)="this.viewModel.applyManualAtmDistance()"
                               (onFocusOut)="this.viewModel.hideManualAtmDistanceInput()"
                               (onKeyUp)="this.viewModel.manualAtmDistanceInputOnKeyUp($event)"
                               [(value)]="this.viewModel.manualAtmDistance"
                               [showClearButton]="false"
                >
                </dx-number-box>
              </div>
              <ng-container *ngIf="!this.viewModel.showAtmTextBox">
                <span>{{ (this.viewModel.atmDistance | currency:'USD':'symbol':'1.0-0') || 'N/A' }}</span>
              </ng-container>
            </div>
            <div [style.visibility]="this.viewModel.strikesShiftButtonsVisibility" class="btn down" title="Market Down">
              <i (click)="this.viewModel.strikeUp()" class="fas fa-arrow-down fa-lg"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="this.viewModel.isForceApply" class="force-apply">
      
      <div class="question">Force apply legs as they are?</div>
      
      <div class="btn agree">
        <dx-button
          (onClick)="this.viewModel.forceApplyClicked()"
          text="Yes"
          type="danger"
          width="100%">
        </dx-button>
      </div>
      
      <div class="btn discard">
        <dx-button
          (onClick)="this.viewModel.discardForceApplyClicked()"
          text="No"
          type="default"
          width="100%">
        </dx-button>
      </div>
    </div>
    
    <div [ngClass]="{'dirty': this.viewModel.hasChanges}" class="positions-body">
      <dx-scroll-view>
        <!-- Positions -->
        <div class="positions-list">
          
          <div class="positions-list-header position">
            <div class="part label header qty">Qty</div>
            <div class="part label header side">Side</div>
            <div class="part label header type">Type</div>
            <div class="part label header expiration">Expiration</div>
            <div class="part label header strike">Strike</div>
          </div>
          
          <!-- N/A -->
          <ng-container *ngIf="!this.viewModel.arePositionsLoaded">
            <div class="na">
              N/A
            </div>
          </ng-container>
          
          
          <ng-container *ngFor="let strategy of this.viewModel.positionsData; index as ix">
            
            <div *ngFor="let position of strategy.positions"
                 [ngClass]="this.viewModel.getBucketRoleClass(position)"
                 class="position"
            >
              <div class="part qty">
                <dx-number-box [readOnly]="true" [value]="position.qty"></dx-number-box>
              </div>
              
              <div class="part side">
                <dx-text-box [readOnly]="true" [value]="position.side"></dx-text-box>
              </div>
              
              <div class="part type">
                <dx-text-box [readOnly]="true" [value]="position.type"></dx-text-box>
              </div>
              
              <div [ngClass]="{'dependent': position.isDependent}" class="part expiration">
                <dx-select-box
                  (valueChange)="this.viewModel.onPositionExpirationChange($event, position)"
                  [inputAttr]="position?.selectedExpiration?.expirationTicker === '@SPX' ? {'style': 'color: yellow'} : {}"
                  [items]="this.position.expirationsList"
                  [readOnly]="this.position.isDependent"
                  [value]="position.selectedExpiration"
                  displayExpr="dateWithDaysToExpiration"
                >
                  <div *dxTemplate="let data of 'item'">
                    <span [style.color]="this.viewModel.getExpirationColorRank(data, position)">
                      {{data?.dateWithDaysToExpiration}}
                    </span>
                  </div>
                </dx-select-box>
              </div>
              
              <div class="part strike">
                <!--suppress TypeScriptValidateTypes -->
                <ets-strikes-dropdown
                  (atmStrikeChanged)="this.viewModel.onPositionStrikeChanged()"
                  (strikeChanged)="this.viewModel.onPositionStrikeChanged()"
                  [(atmStrike)]="position.strike"
                  [(strike)]="position.strike"
                  [expiration]="position.selectedExpiration"
                  [legRole]="position.role"
                  [showAtm]="false"
                  [targetInstrument]="this.viewModel?.tradingInstrument"
                >
                </ets-strikes-dropdown>
              </div>
            </div>
            
            <ng-container *ngIf="ix === 0">
              <ng-container *ngTemplateOutlet="saveDefaults">
              </ng-container>
            </ng-container>
          
          </ng-container>
        
        </div>
        
        <ng-template #saveDefaults>
          
          <div class="action-panel">
            
            <div [style.visibility]="this.viewModel.lockParametersVisibility" class="lock">
              <dx-check-box
                [(value)]="this.viewModel.lockParameters"
                text="Lock"
              >
              </dx-check-box>
            </div>
            
            <div [style.visibility]="this.viewModel.savePositionsVisibility" class="sp">
              <dx-check-box
                [(value)]="this.viewModel.savingPositions"
                [disabled]="!this.viewModel.savePositionsCanChange"
                text="SP"
              >
              </dx-check-box>
            </div>
            
            <div class="spreads states">
              
              <ng-template #leftState>
                <div (click)="this.viewModel.applyPositionsAfterState('first')"
                     *ngIf="this.viewModel.isToggleStateButtonAvailable"
                     [ngClass]=" {
                                             'hot': this.viewModel.hasAfterStateFirst,
                                             'double-mode': this.viewModel.isDoubleMode
                                          }"
                     class="state left"
                >
                  {{this.viewModel.strategyLetterForPositionAfterStateFirst}}
                  <span *ngIf="false" class="tooltip">
                              {{this.viewModel.afterStateTooltipFirst}}
                           </span>
                </div>
              </ng-template>
              
              <ng-container *ngIf="this.viewModel.isDoubleMode">
                <ng-container *ngTemplateOutlet="leftState">
                </ng-container>
              </ng-container>
              
              <div [ngClass]="{'double-mode': this.viewModel.isDoubleMode}" class="dots left">&nbsp;</div>
              
              <ng-container *ngIf="!this.viewModel.isDoubleMode">
                <ng-container *ngTemplateOutlet="leftState">
                </ng-container>
              </ng-container>
              
              <div [style.visibility]="this.viewModel.toggleSpreadsVisibility" class="spread call">
                <dx-check-box
                  [(value)]="this.viewModel.spreadFirstEnabled"
                  [disabled]="!this.viewModel.isSpreadFirstToggleEnabled"
                  [text]="this.viewModel.spreadFirstToggleLabel">
                </dx-check-box>
              </div>
              
              <div *ngIf="this.viewModel.showSpreadSecondToggle" class="spread put">
                <dx-check-box
                  [(value)]="this.viewModel.spreadSecondEnabled"
                  [disabled]="!this.viewModel.isSpreadSecondToggleEnabled"
                  text="PS">
                </dx-check-box>
              </div>
              
              <div [ngClass]="{'double-mode': this.viewModel.isDoubleMode}" class="dots right">&nbsp;
              </div>
              
              <div (click)="this.viewModel.applyPositionsAfterState('second')"
                   *ngIf="this.viewModel.showSecondStateToggle"
                   [ngClass]=" {
                                          'hot': this.viewModel.hasAfterStateSecond,
                                          'double-mode': this.viewModel.isDoubleMode
                                       }"
                   class="state right"
              >
                P
                <span *ngIf="false" class="tooltip">
                           {{this.viewModel.afterStateTooltipSecond}}
                        </span>
              </div>
            
            </div>
            
            <div class="defaults">
              
              <div [style.visibility]="this.viewModel.saveAsDefaultButtonVisibility" class="save">
                
                <div
                  (click)="this.viewModel.saveBeforeStateAsDefault()"
                  class="btn save-state"
                  title="Save As Default"
                >
                  <i class="fas fa-save"></i>
                </div>
              
              </div>
              
              <div [style.visibility]="this.viewModel.defaultsPopupVisibility" class="edit">
                
                <div
                  (click)="this.viewModel.showBeforeStateDefaultsPopup()"
                  class="btn popup"
                  title="Show Default Settings' Popup Editor"
                >
                  <i class="fas fa-cog"></i>
                </div>
              
              </div>
            </div>
          
          </div>
        
        </ng-template>
        
        <ng-container *ngIf="this.viewModel.isPortfolioLoaded">
          
          <hr class="separator">
          
          <div class="buttons">
            
            <div (click)="this.viewModel.fullReset()"
                 [style.visibility]="this.viewModel.fullResetButtonVisibility"
                 class="btn full-reset"
            >
              Reset To Default
            </div>
            
            <div (click)="this.viewModel.clearSavedAdjustment()"
                 [ngClass]="{'disabled': !this.viewModel.hasAfterState}"
                 [style.visibility]="this.viewModel.clearSavedAdjustmentsButtonVisibility"
                 class="btn clear"
            >
              Clear Saved Adjustment
            </div>
            
            <div [ngClass]="this.viewModel.getTrackingButtonCssClass()"
                 [style.visibility]="this.viewModel.trackButtonVisibility">
              <dx-drop-down-button
                (onItemClick)="this.viewModel.onTrackMenuItemClick($event)"
                [dropDownOptions]="{width: 100, wrapperAttr: {class: 'tracking-dropdown'}}"
                [items]="this.viewModel.trackMenuItems"
                [showArrowIcon]="false"
                displayExpr="title"
                text="Track"
                width="100%"
              >
              </dx-drop-down-button>
            </div>
          
          </div>
          
          <div *ngIf="this.viewModel.isDynamicOffsetEnabled" class="buttons">
            <ets-dynamic-offsets-button
              (dynamicOffsetsResolved)="this.viewModel.onDynamicOffsetsResolved($event)"
              (resolvingDynamicOffsets)="this.viewModel.onResolvingDynamicOffsets()"
              [positionsProvider]="this.viewModel"
            >
            </ets-dynamic-offsets-button>
          </div>
        
        </ng-container>
      
      </dx-scroll-view>
    </div>
  </div>
</div>

<ets-apg-after-state-preview></ets-apg-after-state-preview>

<ets-apg-tracking-dialog
  (settingsUpdated)="this.viewModel.onSweetPriceSettingsUpdated()"
>
</ets-apg-tracking-dialog>

<dx-popup
  (onHidden)="this.viewModel.defaultSettingsPopup?.onHidden()"
  [dragEnabled]="true"
  [height]="610"
  [resizeEnabled]="true"
  [visible]="this.viewModel.defaultSettingsPopup?.isVisible"
  [width]="this.viewModel.defaultSettingsPopup?.width"
  title="Default Positions"
>
  
  <div *dxTemplate="let data of 'content'" class="default-settings-popup">
    
    <dx-scroll-view>
      <div class="defaults-container">
        
        <div *ngIf="this.viewModel
                                 .defaultSettingsPopup
                                 ?.defaults
                                 ?.first" class="settings-area first"
        >
          <ng-container *ngTemplateOutlet="defaultSettings; context: {
                            vm: this.viewModel.defaultSettingsPopup,
                            settings: this.viewModel.defaultSettingsPopup.defaults.first,
                            header: 'CALLS'

                        }">
          </ng-container>
        
        </div>
        
        <div *ngIf="this.viewModel
                  .defaultSettingsPopup
                  ?.defaults
                  ?.second"
             class="settings-area second"
        >
          <ng-container *ngTemplateOutlet="defaultSettings; context: {
                            vm: this.viewModel.defaultSettingsPopup,
                            settings: this.viewModel.defaultSettingsPopup.defaults.second,
                            header: 'PUTS'
                        }"
          >
          </ng-container>
        
        </div>
      
      </div>
      
      <div class="expiration-smart-mode">
        <fieldset>
          <legend>Expiration Smart Mode</legend>
          <div class="table">
            
            <div class="row header">
              <div class="col part">Structure Part</div>
              <div class="col days back">Back</div>
              <div class="col days forth">Forth</div>
              <div class="col enabled">Enabled</div>
            </div>
            
            <div class="row spread">
              <div class="col part">Spread</div>
              <div class="col days back">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.spread.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.spread.back">
                </dx-number-box>
              </div>
              <div class="col days forth">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.spread.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.spread.forth">
                </dx-number-box>
              </div>
              <div class="col enabled">
                <dx-check-box
                  (onValueChanged)="this.viewModel.defaultSettingsPopup.onChange()"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.spread.isEnabled">
                </dx-check-box>
              </div>
            </div>
            
            <div class="row second-spread">
              <div class="col part">2nd Spread</div>
              <div class="col days back">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondSpread.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondSpread.back"
                >
                </dx-number-box>
              </div>
              <div class="col days forth">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondSpread.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondSpread.forth"
                ></dx-number-box>
              </div>
              <div class="col enabled">
                <dx-check-box
                  (onValueChanged)="this.viewModel.defaultSettingsPopup.onChange()"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondSpread.isEnabled"
                ></dx-check-box>
              </div>
            </div>
            
            <div class="row protective">
              <div class="col part">Protective Option</div>
              <div class="col days back">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.protectiveOption.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.protectiveOption.back"
                ></dx-number-box>
              </div>
              <div class="col days forth">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.protectiveOption.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.protectiveOption.forth"
                ></dx-number-box>
              </div>
              <div class="col enabled">
                <dx-check-box
                  (onValueChanged)="this.viewModel.defaultSettingsPopup.onChange()"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.protectiveOption.isEnabled"
                ></dx-check-box>
              </div>
            </div>
            
            <div class="row second-protective">
              <div class="col part">2nd Protective Option</div>
              <div class="col days back">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondProtectiveOption.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondProtectiveOption.back"
                ></dx-number-box>
              </div>
              <div class="col days forth">
                <dx-number-box
                  [disabled]="!this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondProtectiveOption.isEnabled"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondProtectiveOption.forth"
                ></dx-number-box>
              </div>
              <div class="col enabled">
                <dx-check-box
                  (onValueChanged)="this.viewModel.defaultSettingsPopup.onChange()"
                  [(value)]="this.viewModel.defaultSettingsPopup.defaults.first.expirationSmartMode.secondProtectiveOption.isEnabled"
                ></dx-check-box>
              </div>
            </div>
            
          </div>
        </fieldset>
      </div>
    
    </dx-scroll-view>
    
    <div class="controls-area">
      
      <div *ngIf="this.viewModel.defaultSettingsPopup.noStrategySelected" class="left">
        <dx-select-box (onValueChanged)="this.viewModel.defaultSettingsPopup.onModeChanged()"
                       [(value)]="this.viewModel.defaultSettingsPopup.selectedMode"
                       [items]="this.viewModel.defaultSettingsPopup.modes">
        </dx-select-box>
      </div>
      
      <div class="right">
        
        <div class="btn">
          <dx-button
            (onClick)="this.viewModel.defaultSettingsPopup.saveSettings()"
            text="Save"
            width="100%"
          >
          </dx-button>
        </div>
        
        <div class="btn">
          <dx-button
            (onClick)="this.viewModel.defaultSettingsPopup.close()"
            text="Cancel"
            width="100%"
          >
          </dx-button>
        </div>
      
      </div>
    
    </div>
  
  </div>
</dx-popup>

<ng-template #defaultSettings let-header="header" let-settings="settings" let-vm="vm">
  
  <ng-container *ngIf="vm?.isDoubleMode">
    <ets-before-state-defaults-header [header]="header"></ets-before-state-defaults-header>
  </ng-container>
  
  
  <!-- Short Option -->
  <fieldset class="section">
    <legend>Short Option</legend>
    
    <!-- Short Option:  Custom ATM -->
    <div class="setting">
      <div class="label">
        ATM
      </div>
      <div class="input">
        <dx-number-box [(value)]="settings.customAtm"
                       [showClearButton]="true"
        ></dx-number-box>
      </div>
    </div>
    
    <!-- Short Option:  ATM offset -->
    <div class="setting">
      <div class="label">
        ATM Offset (+/-)
      </div>
      <div class="input">
        <dx-number-box [(value)]="settings.atmOffset">
        </dx-number-box>
      </div>
    </div>
    
    <!-- Short Option: Days to Expiration  -->
    <div class="setting">
      <div class="label">
        Short Option Days To Exp.
      </div>
      <div class="input">
        <dx-number-box
          (valueChange)="settings.shortOptionDaysToExpiration = $event"
          [min]="0"
          [value]="settings.shortOptionDaysToExpiration || 0"
        >
        </dx-number-box>
      </div>
    </div>
    
  </fieldset>
  
  <!-- Spread -->
  <fieldset class="section">
    <legend>Spread</legend>
    <!-- Spread: Offset -->
    <div class="setting">
      <div class="label">
        Spread Offset
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.spreadOffset"
          [min]="0"
        >
        </dx-number-box>
      </div>
    </div>
    
    <!-- Spread: Width -->
    <div class="setting">
      <div class="label">
        Spread Width
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.spreadWidth"
          [min]="1"
        >
        </dx-number-box>
      </div>
    </div>
    
    <!-- Spread: DaysToExpiration  -->
    <div class="setting">
      <div class="label">
        Spread Days To Exp.
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.spreadDaysToExpiration"
          [min]="0"
        >
        </dx-number-box>
      </div>
    </div>
  </fieldset>
  
  <!-- 2nd Spread -->
  <fieldset *ngIf="vm.isSecondSpreadAvailable" class="section">
    <legend>2nd Spread</legend>
    <!-- 2nd Spread: Offset -->
    <div class="setting">
      <div class="label">
        Include 2nd Spread
      </div>
      <div class="input">
        <dx-check-box [(value)]="settings.includeSecondSpread">
        </dx-check-box>
      </div>
    </div>
    
    <!-- 2nd Spread: Offset -->
    <div class="setting">
      <div class="label">
        2nd Spread Offset
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondSpreadOffset"
          [min]="0"
        >
        </dx-number-box>
      </div>
    </div>
    
    <!-- 2nd Spread: Width -->
    <div class="setting">
      <div class="label">
        2nd Spread Width
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondSpreadWidth"
          [min]="1"
        >
        </dx-number-box>
      </div>
    </div>
    
    <!-- 2nd Spread: DaysToExpiration  -->
    <div class="setting">
      <div class="label">
        2nd Spread Days To Exp.
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondSpreadDaysToExpiration"
          [min]="0"
        >
        </dx-number-box>
      </div>
    </div>
  </fieldset>
  
  <!-- Protective Option -->
  <fieldset class="section">
    <legend>Protective Option</legend>
    <!-- Protective Option: Offset -->
    <div class="setting">
      <div class="label">
        Protective Option Offset
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.protectiveOptionOffset"
          [min]="0"
        >
        </dx-number-box>
      </div>
    </div>
    
    <!-- Protective Option: DaysToExpiration  -->
    <div class="setting">
      <div class="label">
        Protective Option Days To Exp.
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.protectiveOptionDaysToExpiration"
          [min]="0"
          [showClearButton]="true"
          [disabled]="!this.canSetOptionDaysToExpiration('ProtectiveOption', settings)"
          (onValueChanged)="this.onChange()"
        >
        </dx-number-box>
      </div>
    </div>
    
    <!-- Protective Option: Roll [x] Days After Shorts  -->
    <div class="setting">
      <div class="label">
        Roll [x] Days After Shorts
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.protectiveOptionRollXDaysAfterShorts"
          [min]="0"
          [showClearButton]="true"
          [disabled]="!this.canSetRollXDaysAfterShorts('ProtectiveOption', settings)"
          (onValueChanged)="this.onChange()"
        >
        </dx-number-box>
      </div>
    </div>
  
  </fieldset>
  
  <fieldset *ngIf="vm.isSecondProtectiveOptionAvailable" class="section">
    <legend>2nd Protective Option</legend>
    
    <!-- 2nd Protective Option: Include -->
    <div class="setting">
      <div class="label">
        Include 2nd Protective Option
      </div>
      <div class="input">
        <dx-check-box
          [(value)]="settings.includeSecondProtectiveOption">
        </dx-check-box>
      </div>
    </div>
    
    <!-- 2nd Protective Option: Offset -->
    <div class="setting">
      <div class="label">
        2nd Protective Option Offset
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondProtectiveOptionOffset"
          [min]="0">
        </dx-number-box>
      </div>
    </div>
    
    <!-- 2nd Protective Option: DaysToExpiration  -->
    <div class="setting">
      <div class="label">
        2nd Protective Option Days To Exp.
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondProtectiveOptionDaysToExpiration"
          [min]="0"
          [showClearButton]="true"
          [disabled]="!this.canSetOptionDaysToExpiration('SecondProtectiveOption', settings)"
          (onValueChanged)="this.onChange()"
        ></dx-number-box>
      </div>
    </div>
    
    <!-- 2nd Protective Option: Roll [x] Days After Shorts  -->
    <div class="setting">
      <div class="label">
        Roll [x] Days After Shorts
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondProtectiveOptionRollXDaysAfterShorts"
          [min]="0"
          [showClearButton]="true"
          [disabled]="!this.canSetRollXDaysAfterShorts('SecondProtectiveOption', settings)"
          (onValueChanged)="this.onChange()"
        ></dx-number-box>
      </div>
    </div>
    
  </fieldset>
  
  <!-- Order Qty -->
  <fieldset class="section">
    <legend>Order Qty</legend>
    <!-- Default order qty  -->
    <div class="setting extra-gap">
      <div class="label">
        Default Order Qty
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.orderQty"
          [min]="0"
        >
        </dx-number-box>
      </div>
    </div>
    <div class="setting">
      <div class="label">
        Protective Option Overr. Qty
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.protectiveOptionOverrideQty"
          [min]="0"
          [showClearButton]="true"
        >
        </dx-number-box>
      </div>
    </div>
    <div class="setting">
      <div class="label">
        2nd Protective Option Overr. Qty
      </div>
      <div class="input">
        <dx-number-box
          [(value)]="settings.secondProtectiveOptionOverrideQty"
          [min]="0"
          [showClearButton]="true"
        >
        </dx-number-box>
      </div>
    </div>
  </fieldset>

</ng-template>

<ets-apg-context-menu
    #ctxMenu
    (menuItemClicked)="this.viewModel.onCtxMenuItemClick($event)"
    [menuItems]="this.viewModel.ctxMenuItems"
    [target]="ctxMenuPlaceHolder"
>
</ets-apg-context-menu>

<dx-popup
  (onHidden)="this.viewModel.updatePositionQtyPrompt?.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [height]="175"
  [resizeEnabled]="true"
  [showCloseButton]="false"
  [visible]="this.viewModel.updatePositionQtyPrompt?.visible"
  [width]="350"
  titleTemplate="title"
>
  <div *dxTemplate="let data of 'title'" class="title">
    Default Positions Qty Changed
  </div>
  <div *dxTemplate="let data of 'content'" class="update-prompt">
    <div class="prompt">
      Default positions quantity have been changed.<br>Do you want to update current positions?
    </div>
    <div class="checkbox">
      <dx-check-box [(value)]="this.viewModel.updatePositionQtyPrompt.generateOrders" text="Generate Orders">
      </dx-check-box>
    </div>
    <div class="buttons">
      <div class="btn yes">
        <dx-button (onClick)="this.viewModel.updatePositionQtyPrompt.onConfirm()" text="Yes" type="success"
                   width="100%">
        </dx-button>
      </div>
      <div class="btn no">
        <dx-button (onClick)="this.viewModel.updatePositionQtyPrompt.onCancel()" text="No" type="danger" width="100%">
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>
