import { NgModule } from '@angular/core';
import { RiskManagementComponent } from './risk-management.component';
import { AgGridModule } from 'ag-grid-angular';
import { BlockedRuleCellRendererComponent } from './blocked-rule-cell-renderer.component';
import { UnviewedViolationsCellRendererComponent } from './unviewed-violations-cell-renderer.component';
import { RiskRuleEditorComponent } from './risk-rule-editor/risk-rule-editor.component';
import { EtsSymbolPickerModule } from '../../../../shared-components/symbol-picker/symbol-picker.module';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      DevExtremeModule,
      AgGridModule.withComponents([
         BlockedRuleCellRendererComponent,
         UnviewedViolationsCellRendererComponent
      ]),
      EtsSymbolPickerModule,
   ],
   exports: [RiskManagementComponent],
   declarations: [
      RiskManagementComponent,
      BlockedRuleCellRendererComponent,
      UnviewedViolationsCellRendererComponent,
      RiskRuleEditorComponent
   ],
   providers: []
})
export class EtsRiskManagementModule { }
