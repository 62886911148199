import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import { BucketContext } from '../../shell-operations-protocol';

export interface CtdData {
  strategy: boolean;
  manual: boolean;
  riskManager: boolean;
  groupExits: boolean;
  sessionHistory: boolean;
  ledgerRecords: boolean;
}

export class ClearTradingData implements ProtocolCommand {
  
  data: CtdData;
  accounts: string[];
  terminals: string[];
  specificStrategies: string[];
  deleteData: boolean;
  refreshDb: boolean;
  bucketContext: BucketContext;

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ClearTradingData`;
  }
}
