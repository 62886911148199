<div class="view-container" #container [class.ets-hidden]="!this.isActive">
  
   <as-split class="split" direction="vertical" [gutterSize]="5" (dragEnd)="onSplitSizeChanged($event)">
    
    <as-split-area [size]="sectionSizes.main">
      <ag-grid-angular
        class="ag-theme-balham-dark"
        [gridOptions]="tradingSystemsGridModel"
      ></ag-grid-angular>
    </as-split-area>
    
    <as-split-area [size]="sectionSizes.inner" [visible]="showInnerStrategiesGrid">
      <ag-grid-angular
        class="ag-theme-balham-dark"
        [gridOptions]="innerSystemsGridModel"
      ></ag-grid-angular>
    </as-split-area>


    <as-split-area [size]="sectionSizes.positions" [visible]="showStrategyPositionsGrid">
      <ag-grid-angular
        class="ag-theme-balham-dark"
        [gridOptions]="strategyPositionsGridModel"
      ></ag-grid-angular>
    </as-split-area>

  </as-split>
</div>

<!-- System Details -->
<dx-popup width="auto"
          minWidth="350"
          maxWidth="95%"
          height="98%"
          [showTitle]="true"
          title='System Details'
          [closeOnOutsideClick]="true"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          (onHidden)="this.hideSystemDetails()"
          *ngIf="systemDetailsModel?.isSystemDetailsVisible"
          [visible]="true">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="systemDetailsModel?.plainSystem" class="popup_container">
      <dx-scroll-view>
        <div [style.height.px]="systemDetailsModel.containerHeight * 31">
          <ets-system-details [system]="systemDetailsModel?.plainSystem"
                              (heightCalculated)="onHeightCalculated($event)"
          ></ets-system-details>
          </div>
      </dx-scroll-view>
    </div>

    <div *ngIf="systemDetailsModel?.disposition" class="popup_container dispo">
      <dx-scroll-view>
        <fieldset class="family" [style.height.px]="systemDetailsModel.containerHeight * 31">
          <legend><strong>The Disposition</strong></legend>
          <ets-system-details [system]="systemDetailsModel?.disposition.itself"
                              (heightCalculated)="onHeightCalculated($event)"
          ></ets-system-details>
        </fieldset>

        <ng-container *ngFor="let group of systemDetailsModel?.disposition?.groups">
          <fieldset class="family" [style.height.px]="systemDetailsModel.containerHeight * 31">
            <legend>{{group.header}}</legend>
            <ng-container *ngFor="let strategy of group.strategies">
              <ets-system-details [system]="strategy"
                                  (heightCalculated)="onHeightCalculated($event)"
              ></ets-system-details>
            </ng-container>
          </fieldset>
        </ng-container>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>


<!-- Commander Rules List  -->
<dx-popup width="800"
          height="400"
          [title]="commanderRulesModel?.selectedStrategy?.displayName"
          (onHidden)="this.onCommanderRulesListHidden()"
          (onShown)="this.onCommanderRulesListShown()"
          [visible]="this.commanderRulesModel?.isVisible">
  <div *dxTemplate="let data of 'content'" #rulesList>
    <dx-list [items]="this.commanderRulesModel?.rulesList" selectionMode="none"
    ></dx-list>
    <dx-load-panel [visible]="commanderRulesModel?.isDataLoading"
                   [position]="{ my: 'center', at: 'center', of: rulesList }"
    ></dx-load-panel>
  </div>
</dx-popup>


<!-- Clear Trading Data Confirmation -->
<dx-popup [width]="400"
          [height]="200"
          title="Confirm Clearing Trading Data"
          [showTitle]="true"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="false"
          [dragEnabled]="false"
          [visible]="this.clearTradingDataConfirmPopupSettings.isVisible"
          (onHidden)="this.onClearTradingDataConfirmPopupClosed()">
  <div *dxTemplate="let data of 'content'"
       style="position: relative">
    <div>
      <p class="ctd-confirm">Are you sure you want to clear trading data for the strategy(-ies)?</p>
    </div>
    <div class="ctd-confirm-dialog-buttons">
      <dx-button class="ctd-confirm-dialog-button yes" text="Yes" (onClick)="onClearTradingDataForStrategyConfirmed()"
        type="danger"
      ></dx-button>
      <dx-button class="ctd-confirm-dialog-button no" text="No"
                 (onClick)="onClearTradingDataConfirmPopupClosed()"
      ></dx-button>
    </div>
  </div>
</dx-popup>


<ets-strategy-issues-browser></ets-strategy-issues-browser>

<ets-edit-strategy-dialog></ets-edit-strategy-dialog>

<dx-load-panel [position]="{ my: 'center', at: 'center', of: container }"
               [visible]="isLoading"
               [message]="loadingMessage"
></dx-load-panel>

<ets-custom-exits></ets-custom-exits>

<ets-move-to-portfolio-dialog></ets-move-to-portfolio-dialog>

<ets-adjustment-strategy-dialog></ets-adjustment-strategy-dialog>


<!-- Strategy Triggers  -->
<dx-popup width="800"
          height="400"
          title="Strategy Triggers"
          (onHidden)="this.onStrategyTriggersViewHidden()"
          (onShown)="this.onStrategyTriggersViewShown()"
          [visible]="this.strategyTriggersView?.isVisible">
  <div *dxTemplate="let data of 'content'" #triggersView>
   <dx-load-panel [visible]="this.strategyTriggersView?.isLoading"
                  [position]="{ my: 'center', at: 'center', of: triggersView }"
   ></dx-load-panel>
  </div>
</dx-popup>