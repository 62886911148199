import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges, isTruthy } from '../utils';
import { SettingsStorageService } from '../settings-storage-service.service';
import { Subject } from 'rxjs';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from '../message-bus.service';
import { HpDatasetDto } from '../shell-communication/dtos/hp-dataset-dto.class';
import { takeUntil, filter } from 'rxjs/operators';
import { HpDatasetsBackendService } from './hp-datasets-backend-service.interface';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { StrategyRemovedUIMessage } from '../ui-messages/strategy-removed-ui-message.interface';
import { ShellConnectionStatusChangedUIMessage } from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import { PanelBaseComponent } from '../panels/panel-base.component';
import {UserSettingsService} from "../user-settings.service";


@Component({
   selector: 'ets-hp-datasets',
   templateUrl: './hp-datasets.component.html',
   styleUrls: ['./hp-datasets.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpDatasetsPanelComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      
      private readonly _toastr: ToastrService,
      private readonly _backendService: HpDatasetsBackendService,
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
   }
   
   private _unsubscriber: Subject<any> = new Subject<any>();

   datasets: HpDatasetDto[] = [];
   
   lastUpdated: Date;
   
   contextStrategyId: string;

   
   private _loading: boolean;
   get loading(): boolean { return this._loading; }
   
   @DetectSetterChanges()
   set loading(val: boolean) {
      this._loading = val;
   }

   
   async etsOnInit() {
      this._subscribeToMessages();
      await this._loadLastHighlightedStrategy();
   }


   etsOnDestroy(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   
   etsAfterViewInit() { }

   
   private async _loadLastHighlightedStrategy(): Promise<void> {
      const lastMessage = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>(
         'StrategyHighlightedUIMessage',
         this.layoutTabId
      );
      if (lastMessage) {
         await this._onStrategyHighlightedMessage(lastMessage.payload);
      }
   }


   private async _onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage) {
      if (message.strategyId === this.contextStrategyId) {
         return;
      }

      this.contextStrategyId = message.strategyId;

      try {
         this.loading = true;
         await this._loadData();
      } catch {
         this._toastr.error('"Load HP datasets" command completed with errors');
      } finally {
         this.loading = false;
      }
   }

   
   private _subscribeToMessages() {
      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onStrategyHighlightedMessage(message.payload));

      this._messageBus.of<HpDatasetDto>('HpDatasetDto')
         .pipe(
            filter(dto => dto.payload.strategyId === this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this._onHpDatasetDto(dto.payload));

      this._messageBus
         .of<StrategyRemovedUIMessage>('StrategyRemovedUIMessage')
         .pipe(
            filter(msg => msg.payload.strategyId === this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onStrategyRemovedUIMessage(msg.payload));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors && !!this.contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onClearTradingDataMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this._onShellConnectionStatusChanged(data.payload));
   }

   
   @DetectMethodChanges()
   private _onHpDatasetDto(dto: HpDatasetDto) {
      const ix = this.datasets.findIndex(ds => ds.datasetId === dto.datasetId);
      if (ix >= 0) {
         this.datasets[ix] = dto;
      } else {
         this.datasets.push(dto);
      }
      this.lastUpdated = dto.lastUpdateTime;
   }

   
   @DetectMethodChanges()
   private async _loadData(): Promise<void> {
      if (!isTruthy(this.contextStrategyId)) {
         return;
      }

      const dtos = await this._backendService.getDatasetsForStrategy(this.contextStrategyId);
      if (dtos.length > 0) {
         this.datasets.push(...dtos);
      }
   }


   private _onClearTradingDataMessage(message: ClearTradingDataUIMessage): void {
      if (message.strategies.includes(this.contextStrategyId) || message.refreshDb) {
         this.datasets.length = 0;
      }
   }


   private _onStrategyRemovedUIMessage(msg: StrategyRemovedUIMessage): void {
      this.datasets.length = 0;
      this.contextStrategyId = null;
   }


   private async _onShellConnectionStatusChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {
      if (this.contextStrategyId) {
         const strategyId = this.contextStrategyId;
         this.contextStrategyId = null;
         await this._onStrategyHighlightedMessage({ strategyId });
      }
   }

   protected getState(): any {
      return null;
   }

   protected setState(state) {
      //
   }
}
