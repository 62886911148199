import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolCommand} from '../../../service-model/protocol-command.interface';

export class ChangeCommanderRuleState implements ProtocolCommand {
  constructor(
    public readonly rules: string[],
    public readonly action: 'enable' | 'disable',
    public readonly correlationTicketId: string
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ChangeCommanderRuleState`;
  }
}
