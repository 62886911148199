import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class AllocateUnaccountedOrders implements ProtocolCommand {
  constructor(
    public readonly terminalId: string,
    public readonly orders: string[]
  ) {
  }

  public getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.AllocateUnaccountedOrders`;
  }
}
