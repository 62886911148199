import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ManualModeParameters, PutDebitSpreadRollStrategyParameters } from '../adjustment-strategies/put-debit-spread-roll/put-debit-spread-roll-strategy-parameters';
import { PutSpreadParameters } from '../adjustment-strategy-dialog/strategy-parameters/put-spread/put-spread-parameters';
import { EtsConstants } from '../ets-constants.const';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { OptionsChainService } from '../option-chains.service';
import { TargetStrikeDialog } from '../portfolios/portfolio-items/TargetStrikeDialog/TargetStrikeDialog';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { ExitStrategies } from '../shell-communication/operations/strategies/exit-strategies.class';
import { StartStrategies } from '../shell-communication/operations/strategies/start-strategies.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from '../shell-communication/shell-dto-protocol';
import { BucketSpec, CreateAdjustmentStrategy, RollShortTermPutDebitSpreadManually, UpdateAdjustmentStrategy } from '../shell-communication/shell-operations-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { StrategyModel } from '../strategies/strategy-model';
import { StrategyState } from '../strategies/strategy-state.enum';
import { TimestampsService } from '../timestamps.service';
import { findTimezoneOrigin } from '../timezones/time-zones.const';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { OrderType } from '../trading-model/order-type.enum';
import { daysToExpiration, DetectMethodChanges, DetectSetterChanges, isVoid, isNullOrUndefined, militaryTimeToAmPm, timespanToUserFriendly } from '../utils';
import { AdjustmentBucketConfig } from './bucket-config';
import { ShortTermSpreadOverviewBlockViewModel } from './overview-blocks/short-term-spread-block/short-term-spread-overview-block.model';
import { StrategyOverview } from './overview-blocks/strategy-overview.model';


export class ShortTermDebitSpreadBlock {
   
   constructor(
      private readonly _changeDetector: ChangeDetectorRef, 
      private readonly _shellService: ShellClientService, 
      private readonly _strategyIssuesService: StrategiesIssuesService,
      private readonly _toastr: ToastrService,
      private readonly _shellClient: ShellClientService, 
      private readonly _messageBus: MessageBusService,
      private readonly _strategyService: StrategiesService,
      lastQuoteCache: LastQuoteCacheService,
      optionChains: OptionsChainService,
      tiService: TradingInstrumentsService) {
      
         this.spreadAdjustmentParameters = new PutSpreadParameters(_changeDetector);

         this.spreadRollParameters = new PutDebitSpreadRollStrategyParameters(_changeDetector, optionChains);

         this.targetStrikeDialog = new TargetStrikeDialog(
            _changeDetector,
            _toastr,
            lastQuoteCache,
            optionChains,
            tiService,
            _shellClient
            );

         this._messageBus.of<PositionDto[]>('PositionDto')
            .subscribe(x => this.onPositionDto(x.payload));
         
         this._messageBus.of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
            .subscribe(x => this.onPortfolioItemAdded(x.payload));

         this._messageBus.of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
            .subscribe(x => this.onPortfolioItemRemoved(x.payload));
   }

   private _spreadAdjustmentStrategy: StrategyModel;
   private _spreadAdjustmentStrategy2: StrategyModel;
   private _spreadRollStrategy: StrategyModel;
   private _spreadRollStrategy2: StrategyModel;
   private _legs: PortfolioItemDto[] = [];
   private _legs2: PortfolioItemDto[] = [];
   private _bucketConfig: AdjustmentBucketConfig;
   private _bucketConfig2: AdjustmentBucketConfig;

   get isVisible(): boolean {
      return !isNullOrUndefined(this.displayName);
   }

   blockId: string;
   bgColor = ' #0d0d4c';
   displayName: string;
   displayName2: string;
   sessionPnL: number;
   accumulatedPnL: number;

   get hasAdjustmentStrategy(): boolean {
      return !isNullOrUndefined(this._spreadAdjustmentStrategy);
   }

   get hasRollStrategy(): boolean {
      return !isNullOrUndefined(this._spreadRollStrategy);
   }

   get showRollStrategyManageButtons(): boolean {
      return this.hasRollStrategy && !this.spreadRollParameters.manualMode;
   }

   get showRollStrategyCreateButton(): boolean {
      return !this.hasRollStrategy && !this.spreadRollParameters.manualMode;
   }

   get showRollStrategyManualButton(): boolean {
      return this.spreadRollParameters.manualMode;
   }

   get overviewBlockColor(): 'green' | 'red' | 'grey' {
      return this.getBlockColor();
   }

   get isAdjustmentStrategyRunning(): boolean {
      if (!this._spreadAdjustmentStrategy) {
         return false;
      }
      return (this._spreadAdjustmentStrategy.state & StrategyState.Enabled) === this._spreadAdjustmentStrategy.state;
   }

   get isRollStrategyRunning(): boolean {
      if (!this._spreadRollStrategy) {
         return false;
      }
      return (this._spreadRollStrategy.state & StrategyState.Enabled) === this._spreadRollStrategy.state;
   } 

   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   get canExitAdjustmentStrategy(): boolean {
      if (!this._spreadAdjustmentStrategy) {
         return false;
      }

      if (this._spreadAdjustmentStrategy.state === StrategyState.Active) {
         return true;
      }

      return false;
   }

   //
   get canStartAdjustmentStrategy(): boolean {
      if (!this._spreadAdjustmentStrategy) {
         return false;
      }

      if (this._spreadAdjustmentStrategy.state === StrategyState.Inactive) {
         return true;
      }

      return false;
   }

   //
   get canUpdateAdjustmentStrategy(): boolean {
      return this.canStartAdjustmentStrategy;
   }

   //

   spreadAdjustmentParameters: PutSpreadParameters;

   //
   get canExitRollStrategy(): boolean {
      if (!this._spreadRollStrategy) {
         return false;
      }

      if (this._spreadRollStrategy.state === StrategyState.Active) {
         return true;
      }

      return false;
   }

   //
   get canStartRollStrategy(): boolean {
      if (!this._spreadRollStrategy) {
         return false;
      }

      if (this._spreadRollStrategy.state === StrategyState.Inactive) {
         return true;
      }

      return false;
   }

   //
   get canUpdateRollStrategy(): boolean {
      return this.canStartRollStrategy;
   }

   //
   get rollStrategyId(): string {
      return this._spreadRollStrategy ? this._spreadRollStrategy.strategyId : null;
   }

   //
   get rollStrategyId2(): string {
      return this._spreadRollStrategy2 ? this._spreadRollStrategy2.strategyId : null;
   }


   //
   spreadRollParameters: PutDebitSpreadRollStrategyParameters;
   

   //
   targetStrikeDialog: TargetStrikeDialog;
   
   //
   private _underlying: TradingInstrument;
   get underlying(): TradingInstrument {
      return this._underlying;
   }

   @DetectSetterChanges()
   set underlying(v: TradingInstrument) {
      this._underlying = v;
   }


   @DetectMethodChanges()
   setStrategies(strategies: StrategyModel[]) {
      strategies = strategies.filter(x => !isNullOrUndefined(x));

      const adjStr = strategies.find(x => x.algoId == EtsConstants.algorithms.adjustment.putSpreadAlgoId);
      if (adjStr) {
         this._spreadAdjustmentStrategy = adjStr;
         this.spreadAdjustmentParameters.setParameters(adjStr);   
      }

      const rollStr = strategies.find(x => x.algoId == EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId);
      if (rollStr) {
         this._spreadRollStrategy = rollStr;
         this.spreadRollParameters.setParameters(rollStr);   
      }
   }

   @DetectMethodChanges()
   setStrategies2(strategies2: StrategyModel[]) {
      strategies2 = strategies2.filter(x => !isNullOrUndefined(x));

      const rollStr = strategies2.find(x => x.algoId == EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId);
      if (rollStr) {
         this._spreadRollStrategy2 = rollStr;
         this.spreadRollParameters.setParameters2(rollStr);   
      }
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async startAdjustmentStrategy() {
      const cmd = new StartStrategies([this._spreadAdjustmentStrategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async exitAdjustmentStrategy() {
      const cmd = new ExitStrategies([this._spreadAdjustmentStrategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async updateAdjustmentStrategy() {
      const parameters = this.spreadAdjustmentParameters.getParameters();
      const displayName = this.spreadAdjustmentParameters.displayName;

      const cmd = new UpdateAdjustmentStrategy(
         this._spreadAdjustmentStrategy.strategyId,
         displayName,
         parameters
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(cmd);

      } catch (e) {

         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   async createAdjustmentStrategy(): Promise<void> {
      if (this._legs.length === 0) {
         this._toastr.error('Bucket has no legs');
         return;
      }
      const longLeg = this._legs.find(x => x.netPosition > 0);

      if (!longLeg) {
         this._toastr.error('Long leg of the debit spread cannot be identified');
         return;
      }

      const errors = this.spreadAdjustmentParameters.validate({
         legRequired: false,
         legToAttachTo: undefined,
         mode: 'create',
         ctx: 'direct'
      });

      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e));
         return;
      }

      const cmd = new CreateAdjustmentStrategy(
         EtsConstants.algorithms.adjustment.putSpreadAlgoId,
         this.spreadAdjustmentParameters.displayName,
         longLeg.portfolioItemId,
         this.spreadAdjustmentParameters.getParameters(),
      );

      try {
         this.isLoading = true;
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         console.error(e);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async startRollStrategy() {
      const cmd = new StartStrategies([this._spreadRollStrategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async exitRollStrategy() {
      const cmd = new ExitStrategies([this._spreadRollStrategy.strategyId]);
      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd);
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges()
   async updateRollStrategy() {
      const parameters = this.spreadRollParameters.getParameters();
      const displayName = this.spreadRollParameters.displayName;

      const cmd = new UpdateAdjustmentStrategy(
         this._spreadRollStrategy.strategyId,
         displayName,
         parameters
      );

      try {

         this.isLoading = true;
         await this._shellService.processCommand(cmd);

      } catch (e) {

         this._toastr.error(e.message);
         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }

   //
   async createRollStrategy(): Promise<void> {

      if (this._legs.length === 0) {
         this._toastr.error('Bucket has no legs');
         return;
      }

      const longLeg = this._legs.find(x => x.netPosition > 0);

      if (!longLeg) {
         this._toastr.error('Long leg of the debit spread cannot be identified');
         return;
      }

      const errors = this.spreadRollParameters.validate({
         legRequired: false,
         legToAttachTo: undefined,
         mode: 'create',
         ctx: 'direct'
      });

      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e));
         return;
      }

      const cmd = new CreateAdjustmentStrategy(
         EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId,
         this.spreadRollParameters.displayName,
         longLeg.portfolioItemId,
         this.spreadRollParameters.getParameters(),
      );

      try {
         this.isLoading = true;
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error(e.message);
         console.error(e);
      } finally {
         this.isLoading = false;
      }
   }

   //
   reset() {
      this._legs = [];
      this._legs2 = [];
      this._spreadAdjustmentStrategy = null;
      this._spreadAdjustmentStrategy2 = null;
      this._spreadRollStrategy = null;
      this._spreadRollStrategy2 = null;
      this.underlying = null;
      this.displayName = null;
      this.displayName2 = null;
      this.spreadAdjustmentParameters.reset();
      this.spreadRollParameters.reset();
      this.targetStrikeDialog.onHidden();
   }

   //
   setLegs(legs: PortfolioItemDto[]) {
      this._legs = legs || [];
      this.targetStrikeDialog.legs = this._legs;
   }

   //
   setLegs2(legs2: PortfolioItemDto[]) {
      this._legs2 = legs2 || [];
   }

   //
   getViewModel(legNo: number): ShortTermSpreadOverviewBlockViewModel {

      let longLeg = 'N/A';
      let longLegQty = null;
      let longLegAsset = 'N/A';
      let longLegExpiration = 'N/A';
      let longLegDaysToExpiration = -1;

      const legs = legNo === 1 ? this._legs : this._legs2;

      const longPosition = legs.find(x => x.netPosition > 0);
      const shortPosition = legs.find(x => x.netPosition < 0);

      if (longPosition) {
         longLeg = longPosition.tickerDisplayName;
         longLegQty = longPosition.netPosition;
         longLegAsset = longLeg.split(' ').slice(0, 3).join(' ');
         longLegExpiration = longLeg.split(' ').slice(3).join(' ');

         const sExpDate = longPosition.ticker.split(' ')[4];
         const diffIndays = daysToExpiration(sExpDate);
         longLegDaysToExpiration = diffIndays;
      }

      let shortLeg = 'N/A';
      let shortLegQty = null;
      let shortLegAsset = 'N/A';
      let shortLegExpiration = 'N/A';
      let shortLegDaysToExpiration = -1;

      if (shortPosition) {
         shortLeg = shortPosition.tickerDisplayName;
         shortLegQty = shortPosition.netPosition;
         shortLegAsset = shortLeg.split(' ').slice(0, 3).join(' ');
         shortLegExpiration = shortLeg.split(' ').slice(3).join(' ');

         const sExpDate = shortPosition.ticker.split(' ')[4];
         const diffIndays = daysToExpiration(sExpDate);
         shortLegDaysToExpiration = diffIndays;
      }

      let hasErrors = false;

      if (legs.length !== 2) {
         hasErrors = legs.length !== 2 
          || legs.every(x => x.netPosition > 0) 
          || legs.every(x => x.netPosition < 0)
          || legs.every(x => x.netPosition === 0);
      }

      return {
         bgColor: this.bgColor,
         longLeg,
         longLegQty,
         longLegAsset,
         longLegExpiration,
         longLegDaysToExpiration,
         shortLeg,
         shortLegQty,
         shortLegAsset,
         shortLegExpiration,
         shortLegDaysToExpiration,
         hasErrors
      };
   }

   //
   getStrategiesOverview(): StrategyOverview[] {

      const overviews = [];

      const adjStr = this.getAdjustmentStrategyOverviewBlock();

      if (adjStr) {
         overviews.push(adjStr);
      }
     
      const rollStr = this.getRollStrategyOverviewBlock(1);

      if (rollStr) {
         overviews.push(rollStr);
      }

      const rollStr2 = this.getRollStrategyOverviewBlock(2);

      if (rollStr2) {
         overviews.push(rollStr2);
      }

      return overviews;
   }

   //

   private getAdjustmentStrategyOverviewBlock(): StrategyOverview {
      
      if (!this._spreadAdjustmentStrategy) { 
         return null; 
      }

      const adjustMode = this.spreadAdjustmentParameters.adjustmentStrategy;

      const credit = adjustMode !== 'Debit' ? `${this.spreadAdjustmentParameters.creditCost * 100}%` : 'N/A';
      
      const debit = adjustMode !== 'Credit' ? `${this.spreadAdjustmentParameters.debitCost * 100}%` : 'N/A';
      
      const rangeMode = this.spreadAdjustmentParameters.rangeSelectStrategy;
      
      let range = 'N/A';
      
      if (rangeMode === 'Dollars') {
         range = `$${this.spreadAdjustmentParameters.dollarRange}`;
      } else if (rangeMode === 'Strikes') {
         range = `${this.spreadAdjustmentParameters.strikeRange}`;
      }

      const orderType = OrderType[this.spreadAdjustmentParameters.orderType];

      const convertToMarket = this.spreadAdjustmentParameters.convertToMarket ? 'Yes' : 'No';

      let convertMode = 'N/A';
      let convertTime = 'N/A';
      let country = 'N/A';
      let timezone = 'N/A';

      if (convertToMarket === 'Yes') {
         
         convertMode = this.spreadAdjustmentParameters.convertToMarketSettings.actionTimeMode || '';
         
         convertTime = this.spreadAdjustmentParameters.convertToMarketSettings.actionTime || '';
         
         if (convertMode.endsWith(' At')) {
            convertTime = militaryTimeToAmPm(convertTime);
         } else if (convertMode.endsWith(' After')) {
            convertTime = timespanToUserFriendly(convertTime);
         }

         const origin = findTimezoneOrigin(this.spreadAdjustmentParameters.convertToMarketSettings.timezone);
         country = origin.country;
         timezone = origin.timezone;

      }

      const overview: StrategyOverview = {
         bgColor: this.bgColor,
         parameters: [
            { key: 'Status:', value: this.isAdjustmentStrategyRunning ? 'Enabled' : 'Disabled' },
            { key: 'Issues:', value: this.getNumberOfIssues(this._spreadAdjustmentStrategy) },
            { key: 'Adjust Mode:', value: adjustMode },
            { key: 'Credit:', value: credit },
            { key: 'Debit:', value: debit },
            { key: 'Range Mode:', value: rangeMode },
            { key: 'Range:', value: range},
            { key: 'Order Type:', value: orderType },
            { key: 'Convert To Market:', value: convertToMarket },

         ],
         strategy: this._spreadAdjustmentStrategy
      };

      if (convertToMarket === 'Yes') {
         overview.parameters.push(
            { key: 'Convert Mode:', value: convertMode },
            { key: 'Convert Time:', value: convertTime },
            { key: 'Timezone Country:', value: country },
            { key: 'Timezone:', value: timezone },
         )
      }

      return overview;
   }

   //
   private getRollStrategyOverviewBlock(strNo: number): StrategyOverview {
      
      if (!this._spreadRollStrategy && !this._spreadAdjustmentStrategy2) { 
         return null; 
      }

      if (strNo === 1 && isVoid(this._spreadRollStrategy)) {
         return null;
      }

      if (strNo === 2 && isVoid(this._spreadRollStrategy2)) {
         return null;
      }

      const strategy = strNo === 1 ? this._spreadRollStrategy : this._spreadRollStrategy2;

      const rollDaysBeforeExpiration = this.spreadRollParameters.rollDaysBeforeExpiration + ' days';     
      
      const rollTimeMode = this.spreadRollParameters.rollTimeMode || '';
      
      let rollTime = this.spreadRollParameters.rollTime || '';
      
      if (rollTimeMode.includes(' Of ')) {
         rollTime = militaryTimeToAmPm(rollTime);
      } else {
         rollTime = timespanToUserFriendly(rollTime);
      }

      let rollTimezoneCountry = 'N/A';
      let rollTimezone = this.spreadRollParameters.rollTimezone || 'N/A';
      
      if (rollTimezone) {
         const rtzOrigin = findTimezoneOrigin(rollTimezone);
         rollTimezoneCountry = rtzOrigin.country;
         rollTimezone = rtzOrigin.timezone;
      }

      const expirationOffsetMode = this.spreadRollParameters.expirationOffsetMode || '';
      let expirationOffsetValue = this.spreadRollParameters.expirationOffsetValue + '';
      if (expirationOffsetMode === 'Calendar Days') {
         expirationOffsetValue = `${expirationOffsetValue} days`;
      }

      const spreadWidth = '$' + (strNo === 1 ? this.spreadRollParameters.spreadWidth : this.spreadRollParameters.spreadWidth2);
      const atmOffset = `$${this.spreadRollParameters.atmOffset}`;
      const atmNeutralZone = `${this.spreadRollParameters.atmNeutralZone * 100}%`;

      const orderType = OrderType[this.spreadRollParameters.orderType];

      const convertToMarket = this.spreadRollParameters.convertToMarket ? 'Yes' : 'No';

      let ctmMode = 'N/A';
      let ctmTime = 'N/A';
      let ctmTimezoneCountry = 'N/A';
      let ctmTimezone = 'N/A';

      if (convertToMarket === 'Yes') {
         
         ctmMode = this.spreadRollParameters.convertToMarketSettings.actionTimeMode || '';
         
         ctmTime = this.spreadRollParameters.convertToMarketSettings.actionTime || '';
         
         if (ctmMode.endsWith(' At')) {
            ctmTime = militaryTimeToAmPm(ctmTime);
         } else if (ctmMode.endsWith(' After')) {
            ctmTime = timespanToUserFriendly(ctmTime);
         }

         const origin = findTimezoneOrigin(this.spreadRollParameters.convertToMarketSettings.timezone);
         ctmTimezoneCountry = origin.country;
         ctmTimezone = origin.timezone;

      }

      const overview: StrategyOverview = {
         bgColor: this.bgColor,
         parameters: [
         ],
         strategy
      };

      overview.parameters.push(
         { key: 'Status:', value: this.isRollStrategyRunning ? 'Enabled' : 'Disabled' },
         { key: 'Issues:', value: this.getNumberOfIssues(strategy) },
         { key: 'Roll [x] Days Before Expiration:', value: rollDaysBeforeExpiration },
         { key: 'Roll Time Mode:', value: rollTimeMode },
         { key: 'Roll Time:', value: rollTime },
      )

      if (rollTimeMode === 'Time Of Day') {
         overview.parameters.push(
            { key: 'Roll Timezone:', value: rollTimezone },
            { key: 'Roll Timezone Country:', value: rollTimezoneCountry },
         );
      }

      overview.parameters.push(
         { key: 'Expiration Offset Mode:', value: expirationOffsetMode },
         { key: 'Expiration Offset Value:', value: expirationOffsetValue },
         { key: 'Spread Width:', value: spreadWidth },
         { key: 'ATM Offset:', value: atmOffset },
         // { key: 'ATM Neutral Zone:', value: atmNeutralZone },
         { key: 'Order Type:', value: orderType },
         { key: 'Convert To Market:', value: convertToMarket },
      );

      if (convertToMarket === 'Yes') {
         overview.parameters.push(
            { key: 'Convert Mode:', value: ctmMode },
            { key: 'Convert Time:', value: ctmTime },
            { key: 'Timezone Country:', value: ctmTimezoneCountry },
            { key: 'Timezone:', value: ctmTimezone }
         )
      }

      return overview;
   }


   //
   setBucket(bucketConfig: AdjustmentBucketConfig) {
      this._bucketConfig = bucketConfig;
   }
   
   //
   private getBlockColor(): 'green' | 'red' | 'grey' {
      if (!this._spreadAdjustmentStrategy) {
         return 'grey';
      }

      if (!this.isAdjustmentStrategyRunning) {
         return 'red';
      }

      if (this._legs.length !== 2) {
         return 'red';
      }

      if (this._legs.some(x => x.netPosition === 0)) {
         return 'red';
      }

      if (this._legs.every(x => x.netPosition > 0)) {
         return 'red';
      }
      
      if (this._legs.every(x => x.netPosition < 0)) {
         return 'red';
      }

      return 'green';
   }

   //
   private getNumberOfIssues(strategy: StrategyModel): number {
      let result = 0;

      if (isNullOrUndefined(strategy)) {
         return result;
      }

      result = this._strategyIssuesService.getStrategyIssuesCount(strategy.strategyId);
      
      return result;
   }

   //
   @DetectMethodChanges()
   private onPositionDto(dtos: PositionDto[]) {
      if (!this._bucketConfig) {
         return;
      }

      const filtered = dtos.filter(x => x.comboGroupId === this._bucketConfig.comboGroupId);

      if (filtered.length === 0) {
         return;
      }

      filtered.forEach(dto => {
      
         newFunction(this._legs);
         newFunction(this._legs2);

         function newFunction(legs: PortfolioItemDto[]) {
            const update = legs.find(l => l.portfolioItemId === dto.positionId);

            if (update) {

               update.netPosition = dto.netPosition;

            } else {

               if (dto.strategyId === EtsConstants.strategies.manualStrategyId) {

                  if (dto.netPosition > 0) {
                     const longLegIx = legs.findIndex(l => l.netPosition > 0);
                     dto['portfolioItemId'] = dto.positionId;
                     if (longLegIx >= 0) {
                        legs[longLegIx] = dto as any;
                     } else {
                        legs.push(dto as any);
                     }
                  } else if (dto.netPosition < 0) {
                     const shortLegIx = legs.findIndex(l => l.netPosition < 0);
                     dto['portfolioItemId'] = dto.positionId;
                     if (shortLegIx >= 0) {
                        legs[shortLegIx] = dto as any;
                     } else {
                        legs.push(dto as any);
                     }
                  }
               }
            }
         }
      });
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.portfolioItem.comboGroupId !== this._bucketConfig.comboGroupId) {
         return;
      }

      if (msg.portfolioItem.itemType !== PortfolioItemType.Strategy) {
         if (msg.portfolioItem.netPosition > 0) {
            const longLegIx = this._legs.findIndex(l => l.netPosition > 0);
            if (longLegIx >= 0) {
               this._legs[longLegIx] = msg.portfolioItem;
            }
         } else if (msg.portfolioItem.netPosition < 0) {
            const shortLegIx = this._legs.findIndex(l => l.netPosition < 0);
            if (shortLegIx >= 0) {
               this._legs[shortLegIx] = msg.portfolioItem;
            }
         }
      } else {
         const expectingAlgos = [
            EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId, 
            EtsConstants.algorithms.adjustment.putSpreadAlgoId
         ];
         
         if (expectingAlgos.includes(msg.portfolioItem.algoId)) {    
            // hack for  race condition between 'strategy added' event and 'portfolio item added' event
            let cntr = 0;

            const i = setInterval(() => {   
               cntr++;

               if (cntr > 50) {
                  clearInterval(i);
                  return;
               }

               const str = this._strategyService.getById(msg.portfolioItem.portfolioItemId);
               
               if (str) {
                  if (str.algoId === EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId) {

                     this._spreadRollStrategy = str;

                  } else if (str.algoId === EtsConstants.algorithms.adjustment.putSpreadAlgoId) {
                     
                     this._spreadAdjustmentStrategy = str;

                  }

                  clearInterval(i);
                  
                  this._changeDetector.detectChanges();
               }

            }, 50);
         }
      }
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {
      if (!this._bucketConfig) {
         return;
      }

      if (msg.itemType === PortfolioItemType.Strategy) {
         if (this._spreadAdjustmentStrategy) {
            if (msg.portfolioItemId === this._spreadAdjustmentStrategy.strategyId) {
               this._spreadAdjustmentStrategy = null;
            }   
         }
         
         if (this._spreadRollStrategy) {
            if (msg.portfolioItemId === this._spreadRollStrategy.strategyId) {
               this._spreadRollStrategy = null;
            }
         }
      } else {
         const ix = this._legs.findIndex(l => l.portfolioItemId === msg.portfolioItemId);
         if (ix >= 0) {
            this._legs.splice(ix, 1);
         }
      }
   }

   //
   async rollSpreadManually() {
      const params = this.spreadRollParameters.getParameters();
      const mmParameters = JSON.parse(params) as ManualModeParameters;
      const cmd  = new RollShortTermPutDebitSpreadManually(
         mmParameters.expiration.optionExpirationDate,
         mmParameters.spreadWidth,
         mmParameters.atmOffset,
         mmParameters.atmNeutralZone,
         mmParameters.orderType,
         mmParameters.autoLimitPrice,
         mmParameters.convertToMarket,
         mmParameters.convertToMarketSettings,
         this._bucketConfig as BucketSpec
      );

      try {

         this.isLoading = true;

         await this._shellClient.processCommand(cmd);

      } catch (e) {

         this._toastr.error('"Roll Short-Term Put Debit Spread" operation completed with errors');

         console.error(e);

      } finally {

         this.isLoading = false;

      }
   }
}
