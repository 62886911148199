import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class GetSessionStrategyMessages implements ProtocolQuery {
  constructor(
    public readonly strategyId: string,
    public readonly sessionId: string,
    public readonly historyStart: number,
    public readonly batchSize: number
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSessionStrategyMessages`;
  }
}
