import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { ComboDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { AutomationCpMode } from '../../model/AutomationCpMode';
import { DetectMethodChanges } from 'projects/shared-components/utils';

interface MenuItem {
   text: string;
   isSeparator?: boolean;
}

@Component({
   selector: 'ets-automation-cp-body-navigation',
   templateUrl: './navigation.component.html',
   styleUrls: ['./navigation.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   header = 'Navigation';

   @Output() navigate = new EventEmitter<string>();

   @Input() strategy: CashFlowStrategy;

   private _combo : ComboDto;
   get combo() : ComboDto {
      return this._combo;
   }
   @Input()
   set combo(v : ComboDto) {
      this._combo = v;
      this.onComboSelected();
   }
   
   @Input() automationCpMode: AutomationCpMode;

   menuItems: MenuItem[];

   //
   ngOnInit(): void { }

   //
   onNavigateClicked(item: MenuItem) {
      if (item.isSeparator) {
         return;
      }

      this.navigate.emit(item.text);
   }

   //
   @DetectMethodChanges()
   onComboSelected() {
      const items: MenuItem[] = [
         {text: 'Overivew'},
         {text:  undefined, isSeparator: true},
         {text: 'Working Orders'},
         {text: 'Strategy Messages'},
         {text: 'CashFlow Tracker'},
         {text:  undefined, isSeparator: true},
         {text: 'Global Settings'},
         {text: 'Short Option'},
         {text: 'Debit Spread'},
         {text: 'Protective Option'},
         {text:  undefined, isSeparator: true},
      ];

      if (this.strategy === 'Hedged Portfolio' || this.strategy === 'Reversed Hedged Portfolio') {
         items.push(
            {text: 'Portfolio'},
         )
      }

      items.push({text: 'Interest'});

      this.menuItems = items;
   }
}
