import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CashFlowAdjustment} from "../model/CashFlowAdjustment";
import {isValidNumber, isVoid} from "../../utils";
import {
    OffsetsManager,
    VisibleOffsetsSettingsService,
    VisibleOffsetsShowMode
} from "./visible-offsets-settings.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'ets-visible-offsets-component',
    templateUrl: 'visible-offsets.component.html',
    styleUrls: [
        './visible-offsets.component.scss',
        '../adjustment-grid-common-style.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisibleOffsetsComponent implements OnInit, OnDestroy {

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _visibleOffsetsSettingsService: VisibleOffsetsSettingsService,
    ) {

    }

    private _unsubscriber = new Subject();

    @Input()
    adjustments: CashFlowAdjustment[];

    @Input()
    mouseOverTarget: any;

    @Input()
    target: 'PriceBox' | 'Zones Grid';

    private get offsetsManager(): OffsetsManager {
        return this.target === 'PriceBox'
         ? this._visibleOffsetsSettingsService.priceBox
         : this._visibleOffsetsSettingsService.zonesGrid;
    }

    get showOffsets(): boolean {
        return this.offsetsManager.showOffsets;
    }
    get showMode(): VisibleOffsetsShowMode {
        return this.offsetsManager.showMode;
    }


    spreadOffsetFirst: number;
    spreadWidthFirst: number;
    secondSpreadOffsetFirst: number;
    secondSpreadWidthFirst: number;
    protectiveOptionOffsetFirst: number;
    secondProtectiveOptionOffsetFirst: number;

    spreadOffsetSecond: number;
    spreadWidthSecond: number;
    secondSpreadOffsetSecond: number;
    secondSpreadWidthSecond: number;
    protectiveOptionOffsetSecond: number;
    secondProtectiveOptionOffsetSecond: number;

    get hasSpreadOffsetSecond(): boolean {
        if (this.adjustments.length < 2) {
            return false;
        }

        if (!isValidNumber(this.adjustments[1].actualSpreadOffset)) {
            return false;
        }

        const same = this.adjustments[0].actualSpreadOffset ===
            this.adjustments[1].actualSpreadOffset;

        return !same;
    };

    get hasSpreadWidthSecond(): boolean {
        if (this.adjustments.length < 2) {
            return false;
        }

        if (!isValidNumber(this.adjustments[1].actualSpreadWidth)) {
            return false;
        }
        const same = this.adjustments[0].actualSpreadWidth ===
            this.adjustments[1].actualSpreadWidth;

        return !same;
    };

    get hasSecondSpreadOffsetSecond(): boolean {
        if (this.adjustments.length < 2) {
            return false;
        }

        if (!isValidNumber(this.adjustments[1].actualSecondSpreadOffset)) {
            return false;
        }

        const same = this.adjustments[0].actualSecondSpreadOffset ===
            this.adjustments[1].actualSecondSpreadOffset;

        return !same;
    };

    get hasSecondSpreadWidthSecond(): boolean {
        if (this.adjustments.length < 2) {
            return false;
        }

        if (!isValidNumber(this.adjustments[1].actualSecondSpreadWidth)) {
            return false;
        }

        const same = this.adjustments[0].actualSecondSpreadWidth ===
            this.adjustments[1].actualSecondSpreadWidth;

        return !same;
    };

    get hasProtectiveOptionOffsetSecond(): boolean {
        if (this.adjustments.length < 2) {
            return false;
        }

        if (!isValidNumber(this.adjustments[1].actualProtectiveOptionOffset)) {
            return false;
        }

        const same = this.adjustments[0].actualProtectiveOptionOffset ===
            this.adjustments[1].actualProtectiveOptionOffset;


        return !same;
    };

    get hasSecondProtectiveOptionOffsetSecond(): boolean {
        if (this.adjustments.length < 2) {
            return false;
        }

        if (!isValidNumber(this.adjustments[1].actualSecondProtectiveOptionOffset)) {
            return false;
        }

        const same = this.adjustments[0].actualSecondProtectiveOptionOffset ===
            this.adjustments[1].actualSecondProtectiveOptionOffset;

        return !same;
    };


    get showSpreadOffset(): boolean {
        return this.offsetsManager.showSpreadOffset;
    };

    get showSpreadWidth(): boolean {
        return this.offsetsManager.showSpreadWidth;
    }

    get showSecondSpreadOffset(): boolean {
        return this.offsetsManager.showSecondSpreadOffset;
    }

    get showSecondSpreadWidth(): boolean {
        return this.offsetsManager.showSecondSpreadWidth;
    }

    get showProtectiveOptionOffset(): boolean {
        return this.offsetsManager.showProtectiveOptionOffset;
    }

    get showSecondProtectiveOptionOffset(): boolean {
        return this.offsetsManager.showSecondProtectiveOptionOffset;
    }

    showSingleValue(part: 'so' | 'po' | 'po2'): boolean {

        let match = false;

        switch (part) {
            case "so":
                match = this.spreadOffsetFirst === this.spreadOffsetSecond;
                break;
            case "po":
                match = this.protectiveOptionOffsetFirst
                    === this.protectiveOptionOffsetSecond;
                break;
            case "po2":
                match = this.secondProtectiveOptionOffsetFirst
                    === this.secondProtectiveOptionOffsetSecond;
                break;
        }

        return match && this.offsetsManager.showSingleValue;
    }

    ngOnInit() {

        if (isVoid(this.adjustments)) {
            return;
        }

        const emitter = this.target === 'PriceBox'
            ? this._visibleOffsetsSettingsService.priceBox
            : this._visibleOffsetsSettingsService.zonesGrid;

        emitter
            .updated$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(() => this._changeDetector.detectChanges());

        this.setValues();
    }

    ngOnDestroy() {
        this._unsubscriber.next();
        this._unsubscriber.complete();
    }

    isValidNumber(n: number): boolean {
        return isValidNumber(n);
    }

    private setValues() {
        let cashFlowAdjustment = this.adjustments[0];

        this.spreadOffsetFirst = cashFlowAdjustment.actualSpreadOffset;
        this.spreadWidthFirst = cashFlowAdjustment.actualSpreadWidth;
        this.secondSpreadOffsetFirst = cashFlowAdjustment.actualSecondSpreadOffset;
        this.secondSpreadWidthFirst = cashFlowAdjustment.actualSecondSpreadWidth;
        this.protectiveOptionOffsetFirst = cashFlowAdjustment.actualProtectiveOptionOffset;
        this.secondProtectiveOptionOffsetFirst = cashFlowAdjustment.actualSecondProtectiveOptionOffset;

        if (this.adjustments.length > 1) {

            cashFlowAdjustment = this.adjustments[1];

            this.spreadOffsetSecond = cashFlowAdjustment.actualSpreadOffset;
            this.spreadWidthSecond = cashFlowAdjustment.actualSpreadWidth;
            this.secondSpreadOffsetSecond = cashFlowAdjustment.actualSecondSpreadOffset;
            this.secondSpreadWidthSecond = cashFlowAdjustment.actualSecondSpreadWidth;
            this.protectiveOptionOffsetSecond = cashFlowAdjustment.actualProtectiveOptionOffset;
            this.secondProtectiveOptionOffsetSecond = cashFlowAdjustment.actualSecondProtectiveOptionOffset;
        }
    }
}
