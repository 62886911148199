import { MarketSide } from '../trading-model/market-side.enum';
import { EtsConstants } from '../ets-constants.const';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';

export class StrategyPositionModel {
   constructor() {
      this.netPosition = 0;
      this.avgPx = 0;
      this.sessionRealizedPnL = 0;
      this.sessionUnrealizedPnL = 0;
      this.maxSessionPnL = 0;
      this.maxAccumulatedPnL = 0;
      this.sessionTotalPnL = 0;
      this.accumulatedTotalPnL = 0;
      this.sessionPerfTotalPnL = 0;
      this.sessionTradeTotalPnL = 0;
      this.lastMatchPx = 0;
   }

   positionId: string;
   strategyId: string;
   accountId: string;
   terminalId: string;
   accountCode: string;
   terminalCode: string;
   ticker: string;
   netPosition: number;
   avgPx: number;
   sessionRealizedPnL: number;
   sessionUnrealizedPnL: number;
   maxSessionPnL: number;
   maxAccumulatedPnL: number;
   sessionTotalPnL: number;
   accumulatedTotalPnL: number;
   sessionPerfTotalPnL: number;
   sessionTradeTotalPnL: number;
   marketSide: MarketSide;
   lastMatchPx: number;
   isArchived: boolean;
   delta: number;
   seqNum: number;
   openPrice: number;

   updateFinancialDataFromDto(dto: PositionDto): void {
      this.netPosition = dto.netPosition;
      this.avgPx = dto.avgPx;
      this.sessionRealizedPnL = dto.sessionRealizedPnL;
      this.sessionUnrealizedPnL = dto.sessionUnrealizedPnL;
      this.sessionTotalPnL = dto.sessionTotalPnL;
      this.accumulatedTotalPnL = dto.accumulatedTotalPnL;
      this.sessionPerfTotalPnL = dto.sessionPerfTotalPnL;
      this.sessionTradeTotalPnL = dto.sessionTradeTotalPnL;
      this.marketSide = dto.marketSide;
      this.lastMatchPx = dto.lastMatchPx;
      this.delta = dto.delta;
      this.seqNum = dto.seqNum;
      this.openPrice = dto.openPrice;
      
      if (!this.positionId || this.positionId === EtsConstants.emptyGuid) {
         this.positionId = dto.positionId;
         this.accountId = dto.accountId;
         this.terminalId = dto.terminalId;
         this.accountCode = dto.accountCode;
         this.terminalCode = dto.accountCode;
      }
   }
}
