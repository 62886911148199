<div (click)="onClick($event)" class="ets-nestedmenu">
  <dx-scroll-view [showScrollbar]="scrollBarMode">
    <ul class="list" [style.max-height.px]="maxHeight">
      <li (click)="action(item)" *ngFor="let item of menuItems">
        <a>
          <span class="icon icon-chart"></span>
          <span [ngClass]="{'active': item.isActive}">{{item.header}}</span>
        </a>
      </li>
    </ul>
  </dx-scroll-view>
</div>
