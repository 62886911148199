/**
 * @returns GatewayDto[]
 */
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class GetAvailableGateways implements ProtocolQuery {
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAvailableGateways`;
  }
}
