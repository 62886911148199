import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { BodyComponent } from './body/body.component';
import { AutomationCpMode } from './model/AutomationCpMode';
import { CashFlowStrategy } from '../adjustment-control-panel/cash-flow-strategy';
import { MessageBusService } from '../message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { AutomationCpMessageBusService } from './services/automation-cp-message-bus.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ComboDto, GetDebitSpreadSectionDataModelReply, GetProtectiveOptionSectionDataModelReply } from '../shell-communication/shell-dto-protocol';
import { DebitSpreadParameters } from '../cashflow-strategies/debit-spread/DebitSpreadParameters';
import { ProtectiveOptionParameters } from '../cashflow-strategies/protective-option/ProtectiveOptionParameters';
import { CreateCashFlowAutoAdjustmentStrategy } from '../shell-communication/shell-operations-protocol';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-automation-cp',
   templateUrl: './automation-cp.component.html',
   styleUrls: ['./automation-cp.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [
      AutomationCpMessageBusService
   ],
})
export class AutomationCpComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _shellService: ShellClientService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   private _unsubscriber = new Subject();

   //
   @ViewChild(BodyComponent)
   bodyComponent: BodyComponent;

   //
   automationCpMode: AutomationCpMode;

   //
   strategy: CashFlowStrategy;

   //
   combo: ComboDto;

   //
   get panelCssClass(): string[] {

      const classes = ['automation-cp'];

      if (!this.isActive) {
         classes.push('ets-hidden');
      }

      return classes;

   }

   //
   private createCommand(strategyId: string = null): CreateCashFlowAutoAdjustmentStrategy {

      const globalPArameters = this.bodyComponent.globalSettings.getParameters();

      const spread = this.bodyComponent.debitSpreadSection.getParameters();
      const debitSpreadParameters = this.makeDebitSpreadParameters(spread);

      const po = this.bodyComponent.protectiveOptionSection.getParameters();
      const protectiveOptionParameters = this.makeProtectiveOptionParameters(po);

      const interestParameters = this.bodyComponent.interestSection.getParameters();

      console.log({ section: 'Global', params: globalPArameters });
      console.log({ section: 'Debit Spread', params: debitSpreadParameters });
      console.log({ section: 'Protective', params: protectiveOptionParameters });
      console.log({ section: 'Interest', params: interestParameters });

      const cmd = new CreateCashFlowAutoAdjustmentStrategy(
         this.combo.comboId,
         strategyId,
         globalPArameters,
         null,
         debitSpreadParameters,
         protectiveOptionParameters,
         interestParameters
      );

      return cmd;
   }

   //
   async onCreateStrategyClicked(): Promise<void> {
      const cmd = this.createCommand();

      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd)
      } finally {
         this.isLoading = false;
      }
   }

   //
   async onUpdateStrategyClicked(strategyId: string): Promise<void> {
      const cmd = this.createCommand(strategyId);

      try {
         this.isLoading = true;
         await this._shellService.processCommand(cmd)
      } finally {
         this.isLoading = false;
      }
   }

   //
   private makeDebitSpreadParameters(params: DebitSpreadParameters[]): GetDebitSpreadSectionDataModelReply {
      const parameters = params[0] || {};
      const parameters2 = params[1] || {};

      const reply: GetDebitSpreadSectionDataModelReply = {
         shortOptionOffset: parameters.shortOptionOffset,
         spreadWidth: parameters.width,
         rollXDaysBeforeExpiration: parameters.rollXDaysBeforeExpiration,
         rollToDaysToExpiration: parameters.rollDaysToExpiration,
         shortOptionOffset2: parameters2.shortOptionOffset,
         spreadWidth2: parameters2.width,
         rollXDaysBeforeExpiration2: parameters2.rollXDaysBeforeExpiration,
         rollToDaysToExpiration2: parameters2.rollDaysToExpiration
      }

      return reply;
   }

   //
   private makeProtectiveOptionParameters(params: ProtectiveOptionParameters[]): GetProtectiveOptionSectionDataModelReply {
      const parameters = params[0] || {};
      const parameters2 = params[1] || {};

      const reply: GetProtectiveOptionSectionDataModelReply = {
         spreadOffset: parameters.spreadOffset,
         rollXDaysBeforeExpiration: parameters.rollXDaysBeforeExpiration,
         rollToDaysToExpiration: parameters.rollDaysToExpiration,
         spreadOffset2: parameters2.spreadOffset,
         rollXDaysBeforeExpiration2: parameters2.rollXDaysBeforeExpiration,
         rollToDaysToExpiration2: parameters2.rollDaysToExpiration
      }

      return reply;
   }

   //#region PanelComponentBase

   etsOnInit(): void {
      this._cpMessageBus.when<boolean>('Loading')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(x => {
            this.isLoading = x;
         });

      this._cpMessageBus.when<AutomationCpMode>('ModeChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(x => {
            this.automationCpMode = x;
            this._changeDetector.detectChanges();
         });

      this._cpMessageBus.when<CashFlowStrategy>('StrategyChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(x => {
            this.strategy = x;
            this._changeDetector.detectChanges();
         });

      this._cpMessageBus.when<ComboDto>('ComboChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(x => {
            this.combo = x;
            this._changeDetector.detectChanges();
         });
   }

   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   etsAfterViewInit(): void {
   }

   protected getState() {
   }

   protected setState(state: any) {
   }

   //#endregion

}
