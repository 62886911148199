<dx-popup (onHidden)="this.onHidden()"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          [height]="525"
          [width]="880"
          [(visible)]="this.visible"
          title="Re-group Orders Dialog"
>
  <div *dxTemplate="let data of 'content'" #wrapper class="wrapper">
    <div class="content">
      
      <div class="orders side">
        <dx-scroll-view>
          <ng-container *ngFor="let orderGroup of this.orderGroups">
            <fieldset class="order-group">
              <legend class="legend" [style.color]="orderGroup.orders[0]?.color">{{orderGroup.title}}</legend>
              <div class="orders-list">
                <ng-container *ngFor="let order of orderGroup.orders">
                  <ng-container *ngTemplateOutlet="orderTpl; context: {order: order, group: orderGroup}">
                  </ng-container>
                </ng-container>
              </div>
            </fieldset>
          </ng-container>
        </dx-scroll-view>
      </div>
      
      <div class="drop-zones side">
        
        <div class="zones-list">
          <dx-scroll-view>
            <fieldset class="zone" *ngFor="let zone of this.dropZones"
                      (drop)="this.onDrop($event, zone)"
                      (dragover)="this.onDragOver($event)"
            >
              <legend class="legend" >{{this.getOrderTitle(zone)}}</legend>
              
              <div class="zone-items">
                
                <ng-container *ngIf="zone.items.length === 0">
                  <div class="no-data">DRAG & DROP HERE</div>
                </ng-container>
                
                <ng-container *ngIf="zone.items.length > 0">
                  <div *ngFor="let order of zone.items">
                    <ng-container *ngTemplateOutlet="orderTpl; context: {order: order, reordered: true}">
                    </ng-container>
                  </div>
                </ng-container>
              
              </div>
              
              <div class="control-buttons" [class.empty]="this.zone.items.length === 0">
                <div class="remove-zone">
                  <dx-button width="100%" text="Remove Order" (onClick)="this.removeZone(zone)"></dx-button>
                </div>
                <div class="copy-orders">
                  <dx-button width="100%" text="Copy Order" (onClick)="this.copyLegs(zone)"></dx-button>
                </div>
              </div>
            
            </fieldset>
          </dx-scroll-view>
        </div>
        
        <hr class="separator">
        
        <div class="buttons">
          <div class="add-zone btn">
            <dx-button width="100%" text="Add Linked Order" (onClick)="this.addDropZone()">
            </dx-button>
          </div>
          
          <div class="copy btn">
            <dx-button width="100%" text="Copy All Orders" (onClick)="this.copyAllOrders()">
            </dx-button>
          </div>
          
          <div class="split btn">
            <dx-check-box [(value)]="this.splitQty" text="'Split Qty' Mode"
              (onValueChanged)="this.onSplitQtyModeChanged($event)">
            </dx-check-box>
          </div>
          
        </div>
        
      </div>
      
    </div>
    <dx-load-panel [position]="{my: 'center', at:'center', of: wrapper}"></dx-load-panel>
  </div>
  
</dx-popup>

<ng-template #orderTpl let-order="order" let-reordered="reordered">
  <div class="draggable"
       [class.reordered]="reordered"
       [class.moved]="order.isSettled"
       [draggable]="!reordered"
       (dragstart)=" !reordered ?  this.onDragStart($event, order) : null"
  >
    <div class="handle col">
      <i class="fas fa-grip-vertical"></i>
    </div>
    
    <div class="action col" [ngClass]="this.getActionClass(order, false)" [style.color]="order.color">
      {{order.action}}
    </div>
    
    <div class="qty col" [class.split]="this.splitQty"  [ngClass]="this.getQtyClass(order)" [style.color]="order.color">
      
      <ng-container *ngIf="reordered">
        <ng-container *ngIf="this.splitQty">
          <dx-number-box [inputAttr]="{style: 'text-align: center;'}" [value]="order.qty"
                         (valueChange)="this.onSettledQtyChanged($event, order)">
          </dx-number-box>
        </ng-container>
        <ng-container *ngIf="!this.splitQty">
          {{order.qty}}
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="!reordered">
        <ng-container *ngIf="!this.splitQty">
          {{order.qty}}
        </ng-container>
        <ng-container *ngIf="this.splitQty">
          {{order.leftOverQty}} / {{order.qty}}
        </ng-container>
      </ng-container>
    </div>
    
    <div class="underlying col" [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color">
      {{order.underlying}}
    </div>
    
    <div class="type col" [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color">
      {{order.type}}
    </div>
    
    <div class="expiration col" [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color">
      {{order.expiration | etsFriendlyDatePipe:'dte' }}
    </div>
    
    <div class="strike col" [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color">
      {{order.strike}}
    </div>
    
    <div class="remove col" *ngIf="reordered" (click)="this.removeOrder(order)">
      <i class="fa fa-times-circle"></i>
    </div>
  
  </div>
</ng-template>