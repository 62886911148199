<dx-popup width="60%"
          height="60%"
          [showTitle]="true"
          title="Strategy Issues"
          [closeOnOutsideClick]="false"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          (onHidden)="onHidden()"
          (onShown)="onShown()"
          [visible]="isVisible">
  <div *dxTemplate="let data of 'content'" #container>
    <ets-strategy-issues [mode]="'popup'"></ets-strategy-issues>
  </div>
</dx-popup>
