import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SessionService } from '../authentication/session-service.service';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { OptionsChainService } from '../option-chains.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { StrategyStateDto } from '../shell-communication/dtos/strategy-state-dto.class';
import { TerminalDto } from '../shell-communication/dtos/terminal-dto.class';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { BucketAttributes, BucketSummaryDto, ComboCreatedDto, ComboDto, ComboGroupCreatedDto, ComboGroupDto, ConvertToMarketSettings, GetAvailableBucketsReply, GetComboDataReply, GroupDeletedDto, PortfolioCreatedDto, PortfolioDto } from '../shell-communication/shell-dto-protocol';
import { CreateStrategiesInAutomatedMode, DisableStrategiesInAutomatedMode, EnableStrategiesInAutomatedMode, GetAvailableBuckets, GetComboData, SaveBucketAttributes, UpdateStrategiesInAutomatedMode } from '../shell-communication/shell-operations-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { TimestampsService } from '../timestamps.service';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from '../utils';
import { ShortOptionsBlock } from './ShortOptionsBlock';
import { LongTermOptionBlock } from './LongTermOptionBlock';
import { ShortTermDebitSpreadBlock } from './ShortTermDebitSpreadBlock';
import { PortfolioBlock } from './PortfolioBlock';
import { InterestBlock } from './InterestBlock';
import { OrdersBlock } from './OrdersBlock';
import { StrategiesIssuesService } from '../strategies/strategy-issues.service';
import { CashFlowStrategy } from './cash-flow-strategy';
import * as Enumerable from 'linq';
import { StrategyOverview } from './overview-blocks/strategy-overview.model';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { OrderType } from '../trading-model/order-type.enum';
import { StrategyModel } from '../strategies/strategy-model';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import {UserSettingsService} from "../user-settings.service";

type CpMode = 'Automated' | 'Hybrid' | 'Reference';

type OptimalExpirationMode = 'Better' | 'Breakeven Or Better';

export interface OtherSettings {
   protectQty?: number;
}

export interface RollTimeSettings {
   rollTimeModes: string[];
   rollTimeMode?: string;
   rollTime?: string;
   rollTimePickerShowTimezone?: boolean;
   rollTimezone?: string;
   joinRollBuffer?: number;
}

export interface OrderSettings {
   orderTypes: any[];
   orderType?: OrderType;
   autoLimitPrice?: string;
   convertToMarket?: boolean;
   convertToMarketSettings: ConvertToMarketSettings;
}

export interface OptimalPricingSettings {
   optimalExpirationLookForwardLimit?: number;
   optimalExpirationSelectionMode?: OptimalExpirationMode;
   optimalExpirationSelectionModeList: OptimalExpirationMode[];
   optimalExpirationPriceBuffer?: number;
}

export interface GlobalSettings {
   orderSettings: OrderSettings;
   optimalPricingUp: OptimalPricingSettings; 
   optimalPricingDown: OptimalPricingSettings;
   otherSettings: OtherSettings;
   rollTimeSettings: RollTimeSettings;
}


@Component({
   selector: 'ets-adjustment-contorl-panel',
   templateUrl: 'adjustment-control-panel.component.html',
   styleUrls: ['adjustment-control-panel.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})

export class AdjustmentControlPanelComponent extends PanelBaseComponent {
   private _availableBuckets: GetAvailableBucketsReply;

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _sessionService: SessionService,
      private readonly _timestampsService: TimestampsService,
      private readonly _strategiesService: StrategiesService,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _tiService: TradingInstrumentsService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      optionChains: OptionsChainService,
      portfolioSerivce: PortfoliosService,
      strategyIssuesService: StrategiesIssuesService,
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
      
      this.interestBlock = new InterestBlock(
         _changeDetector, 
         _sessionService, 
         _shellClient, 
         strategyIssuesService,
         _toastr,
         _strategiesService,
         _messageBus
      );
      
      this.portfolioBlock = new PortfolioBlock(this);
      
      this.shortTermDebitSpreadBlock = new ShortTermDebitSpreadBlock(
         _changeDetector, 
         _shellClient, 
         strategyIssuesService,
         _toastr, 
         _shellClient, 
         _messageBus, 
         _strategiesService,
         _lastQuoteCache, 
         optionChains, 
         _tiService
      );

      this.longTermOptionBlock = new LongTermOptionBlock(
         _changeDetector,
         _toastr,
         _lastQuoteCache,
         optionChains,
         _tiService,
         _shellClient,
         _messageBus,
         _timestampsService,
         _strategiesService,
         strategyIssuesService
      );

      this.shortOptionsBlock = new ShortOptionsBlock(
         _changeDetector, 
         _sessionService, 
         portfolioSerivce, 
         _shellClient, 
         _tiService, 
         strategyIssuesService, 
         _messageBus,
         _timestampsService,
         _toastr,
         _strategiesService,
         _shellClient
      );
      
      this.ordersBlock = new OrdersBlock(
         _changeDetector, 
         _messageBus, 
         _timestampsService, 
         _lastQuoteCache, 
         portfolioSerivce
      );

      this.resetCommonSettings();
   }

   @ViewChild('targetStrikeConfirmation') readonly confirmationDialog: DxPopupComponent;

   confirmationModel: { orders: any[], dialogHeight: number, selectedDestination: number, source: 'long-term' | 'short-term' } = {    
      orders: [], 
      dialogHeight: 0, 
      selectedDestination: 0,
      source: undefined
   };

   get strategies(): StrategyOverview[] {
      if (isNullOrUndefined(this.selectedCombo)) {
         return [];
      }
      
      const strats = [];
      
      strats.push(...this.longTermOptionBlock.getStrategiesOverview(1));
      if (this.selectedCashFlowStrategy === 'Calls & Puts') {
         strats.push(...this.longTermOptionBlock.getStrategiesOverview(2));
      }
      strats.push(...this.shortTermDebitSpreadBlock.getStrategiesOverview());
      strats.push(...this.shortOptionsBlock.getStrategiesOverview());

      return strats;
   }

   private _unsubscriber: Subject<void> = new Subject<void>();

   @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;

   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   
   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   get unsubscriber(): Subject<void> {
      return this._unsubscriber;
   }
  
   get lastQuoteCache(): LastQuoteCacheService {
      return this._lastQuoteCache;
   }

   get strategyService(): StrategiesService {
      return this._strategiesService;
   }

   
   private _isPnLsPopoverVisible: boolean;
   get isPnLsPopoverVisible(): boolean {
      return this._isPnLsPopoverVisible;
   }
   @DetectSetterChanges()
   set isPnLsPopoverVisible(v: boolean) {
      this._isPnLsPopoverVisible = v;
   }
   

   cashFlowStrategies: CashFlowStrategy[] = [
      'Calls',
      'Puts',
      'Hedged Portfolio',
      'Reversed Hedged Portfolio',
      'Calls & Puts'
   ];

   selectedCashFlowStrategy: CashFlowStrategy;

   get isSingleDirection(): boolean {
      return this.selectedCashFlowStrategy !== 'Calls & Puts';
   }

   cpModes: CpMode[] = [
      'Reference',
      'Automated',
      'Hybrid'
   ];

   selectedCpMode: CpMode;

   get showGlobalSettingsSection(): boolean {
      return this.selectedCombo && this.selectedCpMode === 'Automated';
   }

   get sessionPnlCssClass(): 'profit' | 'loss' {
      if (this.comboSessionPnL > 0 ) {
         return 'profit';
      }

      if (this.comboSessionPnL < 0) {
         return 'loss';
      }

      return undefined;
   }

   get accumulatedPnlCssClass(): 'profit' | 'loss' {
      if (this.comboAccPnL > 0 ) {
         return 'profit';
      }

      if (this.comboAccPnL < 0) {
         return 'loss';
      }

      return undefined;
   }

   private _matchingCombos: ComboDto[] = [];
   private _matchingPortfolios: PortfolioDto[] = [];
   private _matchingTerminals: TerminalDto[] = [];

   terminals: TerminalDto[] = [];
   portfolios: PortfolioDto[] = [];
   combos: ComboDto[] = [];

   selectedTerminal: TerminalDto;
   selectedPortfolio: PortfolioDto;
   selectedCombo: ComboDto;

   interestBlock: InterestBlock;
   portfolioBlock: PortfolioBlock;
   shortTermDebitSpreadBlock: ShortTermDebitSpreadBlock;
   longTermOptionBlock: LongTermOptionBlock;
   shortOptionsBlock: ShortOptionsBlock;
   ordersBlock: OrdersBlock;

   comboSessionPnL: number;
   comboAccPnL: number;

   globalSettings: GlobalSettings;
   
   bucketQtyGuard: number;

   get canChangeBucketQtyGuard(): boolean {
      return !isNullOrUndefined(this.selectedCombo);
   }

   get hasStrategiesInAutomatedMode(): boolean {
      return this.shortOptionsBlock.hasStrategy || this.shortTermDebitSpreadBlock.hasRollStrategy
      || this.interestBlock.hasStrategy;
   }

   get canEnableStrategiesInAutomatedMode(): boolean {
      return this.shortOptionsBlock.canStartStrategy;
   }

   get canDisableStrategiesInAutomatedMode(): boolean {
      return this.shortOptionsBlock.canExitStrategy;
   }

   get canUpdateStrategiesInAutomatedMode(): boolean {
      return this.shortOptionsBlock.canUpdateStrategy;
   }


   @DetectMethodChanges({isAsync: true})
   async etsOnInit(): Promise<void> {

      this._messageBus.of<StrategyStateDto>('StrategyStateDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => this._changeDetector.detectChanges());

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => {
            if (this.longTermOptionBlock) {
               if (this.longTermOptionBlock.targetStrikeDialog) {
                  this.longTermOptionBlock.targetStrikeDialog.onQuote(x.payload);
               }
            }
            if (this.shortTermDebitSpreadBlock) {
               if (this.shortTermDebitSpreadBlock.targetStrikeDialog) {
                  this.shortTermDebitSpreadBlock.targetStrikeDialog.onQuote(x.payload);
               }
             }
         });

      this._messageBus
         .of<BucketSummaryDto[]>('BucketSummaryDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onBucketSummaryDto(msg.payload));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onClearTradingDataMessage(message.payload));

      try {
         
         this.isLoading = true;
         
         const qry = new GetAvailableBuckets();
         this._availableBuckets = await this._shellClient.processQuery<GetAvailableBucketsReply>(qry);

      } catch (e) {

         this._toastr.error('"Automation CP" panel loaded with errors');
         console.log(e);

      } finally {
         this.isLoading = false;
      }

      this._messageBus.of<PortfolioCreatedDto>('PortfolioCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onPortfolioCreated(msg.payload));

      this._messageBus.of<ComboCreatedDto>('ComboCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onComboCreated(msg.payload));

      this._messageBus.of<ComboGroupCreatedDto>('ComboGroupCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onComboGroupCreated(msg.payload));

      this._messageBus.of<GroupDeletedDto>('GroupDeletedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onBucketDeleted(msg.payload));

   }
   
   @DetectMethodChanges()
   onBucketDeleted(payload: GroupDeletedDto): void {
      if (payload.groupType === 'portfolio') {
         const ix = this._availableBuckets.portfolios.findIndex(x => x.portfolioId === payload.portfolioId);
         if (ix >= 0) {
            const removedPortfolio = this._availableBuckets.portfolios.splice(ix, 1);
            if (this.selectedPortfolio === removedPortfolio[0]) {
               this.selectedPortfolio = null;
            }

            const mIx = this._matchingPortfolios.indexOf(removedPortfolio[0]);
            if (mIx >=0) {
               this._matchingPortfolios.splice(mIx, 1);
            }
         }
      } else if (payload.groupType === 'combo') {
         const ix = this._availableBuckets.combos.findIndex(x => x.comboId === payload.comboId);
         if (ix >= 0) {
            const removedCombo = this._availableBuckets.combos.splice(ix, 1);
            if (this.selectedCombo === removedCombo[0]) {
               this.selectedCombo = null;
            }

            const mIx = this._matchingCombos.indexOf(removedCombo[0]);
            if (mIx >=0) {
               this._matchingCombos.splice(mIx, 1);
            }
         }
      } else if (payload.groupType === 'group') {
         const ix = this._availableBuckets.comboGroups.findIndex(x => x.comboGroupId === payload.comboGroupId);
         if (ix >= 0) {
            this._availableBuckets.comboGroups.splice(ix, 1);
         }
      }

      this.filterBuckets();
   }
 

   @DetectMethodChanges()
   onComboGroupCreated(payload: ComboGroupCreatedDto): void {
      this._availableBuckets.comboGroups.push(payload.comboGroup);
      this.filterBuckets(payload.comboGroup);
   }

   onComboCreated(payload: ComboCreatedDto): void {
      this._availableBuckets.combos.push(payload.combo);
   }

   onPortfolioCreated(payload: PortfolioCreatedDto): void {
      this._availableBuckets.portfolios.push(payload.portfolio);

   }

   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   etsAfterViewInit(): void {
    
   }

   @DetectMethodChanges()
   onStrategyChanged() {

      this.selectedTerminal = null;
      this.terminals = [];
      this._matchingTerminals = [];
      this._matchingPortfolios = [];
      this._matchingCombos = [];

      if (!this.selectedCashFlowStrategy) {
         return;
      }

      this.filterBuckets();
   }

   @DetectMethodChanges()
   onCpModeChanged() {

      this.selectedTerminal = null;
      this.terminals = [];
      this._matchingTerminals = [];
      this._matchingPortfolios = [];
      this._matchingCombos = [];

      if (!this.selectedCashFlowStrategy) {
         return;
      }

      this.filterBuckets();
   }

   private filterBuckets(addedComboGroup?: ComboGroupDto) {

      const comboGroups = Enumerable.from(this._availableBuckets.comboGroups);

      const comboGroupsByComboId = comboGroups.groupBy(g => g.comboId);

      const matchedGroups = comboGroupsByComboId.where(gr => {
         const grps = gr.toArray();

         if (this.selectedCashFlowStrategy === 'Puts') {
            return this.doesMatchSellPutsStrategy(grps);
         }

         if (this.selectedCashFlowStrategy === 'Calls') {
            return this.doesMatchSellCallsStrategy(grps);
         }

         if (this.selectedCashFlowStrategy === 'Hedged Portfolio') {
            let doesMatch = this.doesMatchHedgePortfolioStrategy(grps);

            if (doesMatch) {
               const parentCombo = this._availableBuckets.combos.find(x => x.comboId === gr.key());
               const isAuto = parentCombo.comboName.toLowerCase().indexOf('[auto]') >= 0;
               doesMatch = this.selectedCpMode === 'Automated' && isAuto || this.selectedCpMode === 'Hybrid' && !isAuto || this.selectedCpMode === 'Reference';
            }

            return doesMatch;
         }

         if (this.selectedCashFlowStrategy === 'Calls & Puts') {
            let doesMatchCalls = this.doesMatchSellCallsStrategy(grps);
            let doesMatchPuts = this.doesMatchSellPutsStrategy(grps);
            
            if (doesMatchCalls && doesMatchPuts) {
               const parentCombo = this._availableBuckets.combos.find(x => x.comboId === gr.key());
               const isAuto = parentCombo.comboName.toLowerCase().indexOf('[auto]') >= 0;
               const doesMAtch = this.selectedCpMode === 'Automated' && isAuto || this.selectedCpMode === 'Hybrid' && !isAuto || this.selectedCpMode === 'Reference';
               return doesMAtch;
            }

         }

         return false;
      });


      if (matchedGroups.count() === 0) {
         return;
      }

      const comboIds = matchedGroups.select(x => x.key()).toArray();
      this._matchingCombos = this._availableBuckets.combos.filter(c => comboIds.includes(c.comboId));

      const portfolioIds = this._matchingCombos.map(x => x.portfolioId);
      
      this._matchingPortfolios = this._availableBuckets.portfolios.filter(p => portfolioIds.includes(p.portfolioId));

      const terminalIds = this._matchingPortfolios.map(p => p.terminalId);

      this._matchingTerminals = this._sessionService.loginResult.availableTerminals.filter(t => terminalIds.includes(t.terminalId));
      
      this.terminals = this._matchingTerminals;

      if (this.selectedTerminal) {
         this.portfolios = this._matchingPortfolios.filter(x => x.terminalId === this.selectedTerminal.terminalId);

         if (this.selectedPortfolio) {
            this.combos = this._matchingCombos.filter(x => x.portfolioId === this.selectedPortfolio.portfolioId);

            if (addedComboGroup) {
               const filteredCombos = this._matchingCombos.filter(x => x.portfolioId == this.selectedPortfolio.portfolioId);
               if (filteredCombos.length != this.combos.length) {
                  this.combos = filteredCombos;
               }
            }
         }
      }

      if (this.terminals.length === 1) {
         this.selectedTerminal = this.terminals[0];
         setTimeout(() => this.onTerminalChanged());
      }
   }

   @DetectMethodChanges()
   onTerminalChanged() {
      
      this.portfolios = [];
      this.combos = [];
      this.selectedPortfolio = null;
      this.selectedCombo = null;

      if (!this.selectedTerminal) {
         return;
      }

      const portfolios = this._matchingPortfolios.filter(x => x.terminalId === this.selectedTerminal.terminalId);
      this.portfolios = portfolios;

      if (this.portfolios.length === 1) {
         this.selectedPortfolio = this.portfolios[0];
         setTimeout(() => this.onPortfolioChanged());
      }
   }

   @DetectMethodChanges()
   onPortfolioChanged() {
      
      this.combos = [];
      this.selectedCombo = null;

      if (!this.selectedPortfolio) {
         return;
      }

      const combos = this._matchingCombos.filter(x => x.portfolioId === this.selectedPortfolio.portfolioId);
      this.combos = combos;

      if (this.combos.length === 1) {
         this.selectedCombo === this.combos[0];
         setTimeout(() => this.onComboChanged());
      }
   }

   @DetectMethodChanges({isAsync: true})
   async onComboChanged() {

      this.resetState();
      
      if (!this.selectedCombo) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetComboData(this.selectedCombo.comboId);
         const reply = await this._shellClient.processQuery<GetComboDataReply>(qry);

         this.bucketQtyGuard = reply.attributes ? reply.attributes.qtyGuard : null;

         this.ordersBlock.setBucket({
            terminalId: this.selectedTerminal.terminalId,
            portfolioId: this.selectedPortfolio.portfolioId,
            comboId: this.selectedCombo.comboId,
            comboGroupId: null,
         });

         if (reply.portfolioBlock.displayName) {

            this.portfolioBlock.blockId = reply.portfolioBlock.comboGroupId;
            this.portfolioBlock.displayName = reply.portfolioBlock.displayName;

            this.portfolioBlock.setData(reply.portfolioBlock.portfolioItems);
            
            if (reply.portfolioBlock.underlying) {
               const ul = this._tiService.getInstrumentByTicker(reply.portfolioBlock.underlying);
               this.portfolioBlock.underlying = ul;
            }

            this.portfolioBlock.setBucket({
               terminalId: this.selectedTerminal.terminalId,
               portfolioId: this.selectedPortfolio.portfolioId,
               comboId: this.selectedCombo.comboId,
               comboGroupId: reply.portfolioBlock.comboGroupId
            });
         }

         if (reply.interestBlock.displayName) {
            this.interestBlock.blockId = reply.interestBlock.comboGroupId;
            this.interestBlock.displayName = reply.interestBlock.displayName;

            if (reply.interestBlock.strategyId) {
               const interestStrategy  = this._strategiesService.getById(reply.interestBlock.strategyId);
               this.interestBlock.setStrategy(interestStrategy);
            }

            this.interestBlock.setBucket({
               terminalId: this.selectedTerminal.terminalId,
               portfolioId: this.selectedPortfolio.portfolioId,
               comboId: this.selectedCombo.comboId,
               comboGroupId: reply.interestBlock.comboGroupId
            });
         }

         if (reply.shortTermDebitSpreadBlock.displayName) {

            this.shortTermDebitSpreadBlock.blockId = reply.shortTermDebitSpreadBlock.comboGroupId;
            this.shortTermDebitSpreadBlock.displayName = reply.shortTermDebitSpreadBlock.displayName;
            this.shortTermDebitSpreadBlock.displayName2 = reply.shortTermDebitSpreadBlock.displayName2;

            if (reply.shortTermDebitSpreadBlock.spreadAdjustmentStrategyId ||
                  reply.shortTermDebitSpreadBlock.spreadRollStrategyId) {
               const adjStr  = this._strategiesService.getById(reply.shortTermDebitSpreadBlock.spreadAdjustmentStrategyId);
               const rollStr  = this._strategiesService.getById(reply.shortTermDebitSpreadBlock.spreadRollStrategyId);
               this.shortTermDebitSpreadBlock.setStrategies([adjStr, rollStr]);
            }
            if (reply.shortTermDebitSpreadBlock.spreadRollStrategyId2) {
               const rollStr  = this._strategiesService.getById(reply.shortTermDebitSpreadBlock.spreadRollStrategyId2);
               this.shortTermDebitSpreadBlock.setStrategies2([rollStr]);
            }

            if (reply.shortTermDebitSpreadBlock.underlying) {
               const ul = this._tiService.getInstrumentByTicker(reply.shortTermDebitSpreadBlock.underlying);
               this.shortTermDebitSpreadBlock.underlying = ul;
            }

            this.shortTermDebitSpreadBlock.setLegs(reply.shortTermDebitSpreadBlock.legs);
            this.shortTermDebitSpreadBlock.setLegs2(reply.shortTermDebitSpreadBlock.legs2);

            this.shortTermDebitSpreadBlock.setBucket({
               terminalId: this.selectedTerminal.terminalId,
               portfolioId: this.selectedPortfolio.portfolioId,
               comboId: this.selectedCombo.comboId,
               comboGroupId: reply.shortTermDebitSpreadBlock.comboGroupId,
            });

            const longLeg = reply.shortTermDebitSpreadBlock.legs.find(x => x.netPosition > 0);
            if (longLeg) {
               this.shortTermDebitSpreadBlock.targetStrikeDialog.show({
                  legToRoll: longLeg
               });
            }

            // this.strategies.push(...this.shortTermDebitSpreadBlock.getStrategiesOverview());
         }

         if (reply.longTermPutBlock.displayName) {
            
            this.longTermOptionBlock.blockId = reply.longTermPutBlock.comboGroupId;
            this.longTermOptionBlock.displayName = reply.longTermPutBlock.displayName;
            this.longTermOptionBlock.displayName2 = reply.longTermPutBlock.displayName2;

            if (reply.longTermPutBlock.legToRoll) {

               this.longTermOptionBlock.targetStrikeDialog.show({
                  legToRoll: reply.longTermPutBlock.legToRoll
               });
               
               this.longTermOptionBlock.setLeg(reply.longTermPutBlock.legToRoll);
            }

            if (reply.longTermPutBlock.legToRoll2) {
               this.longTermOptionBlock.setLeg2(reply.longTermPutBlock.legToRoll2);
            }

            this.longTermOptionBlock.setBucket({
               terminalId: this.selectedTerminal.terminalId,
               portfolioId: this.selectedPortfolio.portfolioId,
               comboId: this.selectedCombo.comboId,
               comboGroupId: reply.longTermPutBlock.comboGroupId,
            });
                        
            if (reply.longTermPutBlock.strategyId) {
               const adjStrategy  = this._strategiesService.getById(reply.longTermPutBlock.strategyId);
               this.longTermOptionBlock.setStrategy(adjStrategy);
            }

            if (reply.longTermPutBlock.strategyId2) {
               const adjStrategy  = this._strategiesService.getById(reply.longTermPutBlock.strategyId2);
               this.longTermOptionBlock.setStrategy2(adjStrategy);
            }

            // this.strategies.push(...this.longTermOptionBlock.getStrategiesOverview());
         }

         if (reply.sellOptionsBlock.displayName) {

            this.shortOptionsBlock.blockId = reply.sellOptionsBlock.comboGroupId;
            this.shortOptionsBlock.displayName = reply.sellOptionsBlock.displayName;
            this.shortOptionsBlock.displayName2 = reply.sellOptionsBlock.displayName2;

            this.shortOptionsBlock.setLeg(reply.sellOptionsBlock.leg);
            this.shortOptionsBlock.setLeg2(reply.sellOptionsBlock.leg2);

            if (reply.sellOptionsBlock.strategyId) {

               const adjStrategy  = this._strategiesService.getById(reply.sellOptionsBlock.strategyId);
               this.shortOptionsBlock.setStrategy(adjStrategy);

               if (this.selectedCpMode === 'Automated') {
                  this.fillGlobalSettings(adjStrategy);
               }
            }
            if (reply.sellOptionsBlock.strategyId2) {

               const adjStrategy  = this._strategiesService.getById(reply.sellOptionsBlock.strategyId2);
               this.shortOptionsBlock.setStrategy2(adjStrategy);

               if (this.selectedCpMode === 'Automated') {
                  this.fillGlobalSettings(adjStrategy);
               }
            }

            this.shortOptionsBlock.setBucket({
               terminalId: this.selectedTerminal.terminalId,
               portfolioId: this.selectedPortfolio.portfolioId,
               comboId: this.selectedCombo.comboId,
               comboGroupId: reply.sellOptionsBlock.comboGroupId,
            });

            this.shortOptionsBlock.optionType = reply.sellOptionsBlock.optionType;
            this.shortOptionsBlock.optionType2 = reply.sellOptionsBlock.optionType2;
            
            if (reply.sellOptionsBlock.underlying) {
               const ul = this._tiService.getInstrumentByTicker(reply.sellOptionsBlock.underlying);
               this.shortOptionsBlock.underlying = ul;
            }

            // this.strategies.push(...this.shortOptionsBlock.getStrategiesOverview());
         }

         this.ordersBlock.setData(reply.ordersBlock.orders);

         const hasShortTermBlock = !isNullOrUndefined(reply.shortTermDebitSpreadBlock.displayName);
         const hasLongTermBlock = !isNullOrUndefined(reply.longTermPutBlock.displayName);
         const hasShortOptionsBlock = !isNullOrUndefined(reply.sellOptionsBlock.displayName);

         let isHedgeCashFlowStrategy = true;

         if (!hasShortTermBlock || !hasLongTermBlock || !hasShortOptionsBlock) {
            isHedgeCashFlowStrategy = false;
         }

         if (!isHedgeCashFlowStrategy) {
            this._toastr.error('"Loaded bucket doest not complain to \"Cash Flow Strategy\" template"');
         }

      } catch (e) {

         this._toastr.error('"Load combo bucket data" operation completed with errors');
         console.error(e);

      } finally {

         this.isLoading = false;
      }

   }

   private resetState() {
      this.portfolioBlock.reset();
      this.interestBlock.reset();
      this.shortTermDebitSpreadBlock.reset();
      this.longTermOptionBlock.reset();
      this.shortOptionsBlock.reset();
      this.ordersBlock.reset();
      this.resetCommonSettings();

      this.comboAccPnL = null;
      this.comboSessionPnL = null;
      this.bucketQtyGuard = null;
   }

   scrollTo(tag: string) {
      const el = document.getElementById(tag);
      this.scrollView.instance.scrollToElement(el);
   }

   protected getState() {
   }
   
   protected setState(state: any) {
   }

   private onBucketSummaryDto(dtos: BucketSummaryDto[]): void {

      if (!this.selectedCombo) {
         return;
      }

      dtos.forEach(dto => {

         if (dto.bucketType === 'Combo') {

            if (dto.comboId === this.selectedCombo.comboId) {
               this.comboSessionPnL = dto.sessionTotalPnL;
               this.comboAccPnL = dto.accumulatedTotalPnL;
            }

         } else if (dto.bucketType === 'ComboGroup') {
            if (this.portfolioBlock.blockId === dto.comboGroupId) {
               this.portfolioBlock.sessionPnL = dto.sessionTotalPnL;
               this.portfolioBlock.accumulatedPnL = dto.accumulatedTotalPnL;
            }

            if (this.interestBlock.blockId === dto.comboGroupId) {
               this.interestBlock.sessionPnL = dto.sessionTotalPnL;
               this.interestBlock.accumulatedPnL = dto.accumulatedTotalPnL;
            }

            if (this.longTermOptionBlock.blockId === dto.comboGroupId) {
               this.longTermOptionBlock.sessionPnL = dto.sessionTotalPnL;
               this.longTermOptionBlock.accumulatedPnL = dto.accumulatedTotalPnL;
            }

            if (this.shortTermDebitSpreadBlock.blockId === dto.comboGroupId) {
               this.shortTermDebitSpreadBlock.sessionPnL = dto.sessionTotalPnL;
               this.shortTermDebitSpreadBlock.accumulatedPnL = dto.accumulatedTotalPnL;
            }
            
            if (this.shortOptionsBlock.blockId === dto.comboGroupId) {
               this.shortOptionsBlock.sessionPnL = dto.sessionTotalPnL;
               this.shortOptionsBlock.accumulatedPnL = dto.accumulatedTotalPnL;
            }
         }
      });
   }

   private doesMatchSellPutsStrategy(comboGroups: ComboGroupDto[]): boolean {
      if (!comboGroups || comboGroups.length < 3) {
         return false;
      }

      let hasShortPuts = false;
      let hasShortTermPutDebitSpread = false;
      let hasLongTermPut = false;

      comboGroups.forEach(gr => {
         const groupName = gr.comboGroupName.toLowerCase();
         
         if (!hasShortPuts) {
            hasShortPuts = groupName.indexOf('short') >= 0 && groupName.indexOf('put') >= 0;
         }

         if (!hasShortTermPutDebitSpread) {
            hasShortTermPutDebitSpread = groupName.includes('debit') && groupName.includes('spread');
         }

         if (!hasLongTermPut) {
            hasLongTermPut = groupName.includes('protective');
         }
      });

      return hasShortPuts && hasShortTermPutDebitSpread && hasLongTermPut; 
   }

   private doesMatchSellCallsStrategy(comboGroups: ComboGroupDto[]): boolean {
      if (!comboGroups || comboGroups.length < 3) {
         return false;
      }

      let hasShortCalls = false;
      let hasShortTermCallDebitSpread = false;
      let hasLongTermCall = false;

      comboGroups.forEach(gr => {
         const groupName = gr.comboGroupName.toLowerCase();
         
         if (!hasShortCalls) {
            hasShortCalls = groupName.indexOf('short') >= 0 && groupName.indexOf('call') >= 0;
         }

         if (!hasShortTermCallDebitSpread) {
            hasShortTermCallDebitSpread = 
               groupName.includes('debit') && groupName.includes('spread');
         }

         if (!hasLongTermCall) {
            hasLongTermCall = groupName.includes('protective');
         }
      });

      return hasShortCalls && hasShortTermCallDebitSpread && hasLongTermCall; 
   }

   private doesMatchHedgePortfolioStrategy(comboGroups: ComboGroupDto[]): boolean {
      if (!comboGroups || comboGroups.length < 3) {
         return false;
      }

      let hasShortCalls = false;
      let hasShortTermPutDebitSpread = false;
      let hasLongTermPut = false;
      let hasPortfolioOrUnderlying = false;

      comboGroups.forEach(gr => {
         const groupName = gr.comboGroupName.toLowerCase();
         
         if (!hasShortCalls) {
            hasShortCalls = groupName.indexOf('short') >= 0 && groupName.indexOf(' calls') >= 0;
         }

         if (!hasShortTermPutDebitSpread) {
            hasShortTermPutDebitSpread = groupName.includes('short-term')
             && groupName.includes(' put') && groupName.includes('debit') && groupName.includes(' spread');
         }

         if (!hasLongTermPut) {
            hasLongTermPut = groupName.includes('long-term') && groupName.includes(' put');
         }

         if (!hasPortfolioOrUnderlying) {
            hasPortfolioOrUnderlying = groupName.includes('portfolio') || groupName.includes('underlying');
         }
      });

      return hasShortCalls && hasShortTermPutDebitSpread && hasLongTermPut && hasPortfolioOrUnderlying;
   }



   @DetectMethodChanges()
   onChange() {
      this.globalSettings.rollTimeSettings.rollTimePickerShowTimezone = this.globalSettings.rollTimeSettings.rollTimeMode === 'Time Of Day';

      if (!this.globalSettings.orderSettings.convertToMarket) {
         if (this.globalSettings.orderSettings.convertToMarketSettings.actionTime) {
            this.globalSettings.orderSettings.convertToMarketSettings = {};
         }
      }
   }

   @DetectMethodChanges({isAsync: true})
   async createStrategiesInAutomatedMode() {

      if (this.selectedCpMode !== 'Automated') {
         console.error('Will not proceed. Not in automated mode')
         return;
      }

      const validationResult = this.validateStrategiesInAutomatedMode();

      if (!validationResult) {
         return;
      }

      const comboId = this.selectedCombo.comboId;
      const shortOptionName = this.shortOptionsBlock.parameters.displayName;
      const shortOptionParameters = this.shortOptionsBlock.parameters.getParameters(this.globalSettings);
      const spreadRollName = this.shortTermDebitSpreadBlock.spreadRollParameters.displayName;
      const spreadRollParameters = this.shortTermDebitSpreadBlock.spreadRollParameters.getParameters(this.globalSettings);
      const spreadRollParameters2 = this.shortTermDebitSpreadBlock.spreadRollParameters.getParameters(this.globalSettings, 2);
      const spreadAdjustName = this.shortTermDebitSpreadBlock.spreadAdjustmentParameters.displayName;
      const spreadAdjustParameters = this.shortTermDebitSpreadBlock.spreadAdjustmentParameters.getParameters(this.globalSettings);
      const longOptionName = this.longTermOptionBlock.parameters.displayName;
      const longOptionParameters = this.longTermOptionBlock.parameters.getParameters(this.globalSettings);
      const longOptionParameters2 = this.longTermOptionBlock.parameters.getParameters(this.globalSettings, 2);
      const interestName = this.interestBlock.parameters.displayName;
      const interestParameters = this.interestBlock.parameters.getParameters();
      const protectQty = this.globalSettings.otherSettings.protectQty;

      const cmd = new CreateStrategiesInAutomatedMode(
         comboId,
         shortOptionName,
         shortOptionParameters,
         spreadRollName,
         spreadRollParameters,
         spreadRollParameters2,
         spreadAdjustName,
         spreadAdjustParameters,
         longOptionName,
         longOptionParameters,
         longOptionParameters2,
         interestName,
         interestParameters,
         protectQty
      );

      try {

         this.isLoading = true;

         await this._shellClient.processCommand(cmd);

      } catch (e) {
         console.error(e);
         this._toastr.error('Operation completed with errors');
      } finally {
         this.isLoading = false;
      }
   }

   getRollTimePickerMode(): string {

      if (!this.globalSettings.rollTimeSettings.rollTimeMode) {
         return undefined;
      }

      if (this.globalSettings.rollTimeSettings.rollTimeMode === 'Time Of Day') {
         return 'time';
      }

      return 'timespan';

   }

   //
   @DetectMethodChanges({isAsync: true})
   async enableStrategiesInAutomatedMode() {
      const cmd  = new EnableStrategiesInAutomatedMode(this.selectedCombo.comboId);
      try {
         this.isLoading = true;
         await this._shellClient.processCommand(cmd)
      } catch {
         //
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async disableStrategiesInAutomatedMode() {
      const cmd  = new DisableStrategiesInAutomatedMode(this.selectedCombo.comboId);
      try {
         this.isLoading = true;
         await this._shellClient.processCommand(cmd)
      } catch {
         //
      } finally {
         this.isLoading = false;
      }
   }

   //
   @DetectMethodChanges({isAsync: true})
   async updateStrategiesInAutomatedMode() {

      if (this.selectedCpMode !== 'Automated') {
         console.error('Will not proceed. Not in automated mode')
         return;
      }

      const validationResult = this.validateStrategiesInAutomatedMode();

      if (!validationResult) {
         return;
      }

      const comboId = this.selectedCombo.comboId;
      const shortOptionName = this.shortOptionsBlock.parameters.displayName;
      const shortOptionName2 = this.shortOptionsBlock.displayName2;
      const shortOptionParameters = this.shortOptionsBlock.parameters.getParameters(this.globalSettings);
      const shortOptionParameters2 = this.shortOptionsBlock.parameters.getParameters(this.globalSettings);
      const shortOptionId = this.shortOptionsBlock.strategyId;
      const shortOptionId2 = this.shortOptionsBlock.strategyId2;
      const spreadRollName = this.shortTermDebitSpreadBlock.spreadRollParameters.displayName;
      const spreadRollName2 = this.shortTermDebitSpreadBlock.displayName2;
      const spreadRollParameters = this.shortTermDebitSpreadBlock.spreadRollParameters.getParameters(this.globalSettings);
      const spreadRollParameters2 = this.shortTermDebitSpreadBlock.spreadRollParameters.getParameters(this.globalSettings, 2);
      const spreadRollId = this.shortTermDebitSpreadBlock.rollStrategyId;
      const spreadRollId2 = this.shortTermDebitSpreadBlock.rollStrategyId2;
      const spreadAdjustName = this.shortTermDebitSpreadBlock.spreadAdjustmentParameters.displayName;
      const spreadAdjustParameters = this.shortTermDebitSpreadBlock.spreadAdjustmentParameters.getParameters(this.globalSettings);
      const longOptionName = this.longTermOptionBlock.parameters.displayName;
      const longOptionName2 = this.longTermOptionBlock.displayName2;
      const longOptionParameters = this.longTermOptionBlock.parameters.getParameters(this.globalSettings);
      const longOptionParameters2 = this.longTermOptionBlock.parameters.getParameters(this.globalSettings, 2);
      const longOptionId = this.longTermOptionBlock.strategyId;
      const longOptionId2 = this.longTermOptionBlock.strategyId2;
      const interestName = this.interestBlock.parameters.displayName;
      const interestParameters = this.interestBlock.parameters.getParameters();
      const protectQty = this.globalSettings.otherSettings.protectQty;

      const cmd = new UpdateStrategiesInAutomatedMode(
         comboId,
         shortOptionName,
         shortOptionName2,
         shortOptionParameters,
         shortOptionParameters2,
         shortOptionId,
         shortOptionId2,
         spreadRollName,
         spreadRollName2,
         spreadRollParameters,
         spreadRollParameters2,
         spreadRollId,
         spreadRollId2,
         spreadAdjustName,
         spreadAdjustParameters,
         longOptionName,
         longOptionName2,
         longOptionParameters,
         longOptionParameters2,
         longOptionId,
         longOptionId2,
         interestName,
         interestParameters,
         protectQty
      );

      try {

         this.isLoading = true;

         await this._shellClient.processCommand(cmd);

      } catch (e) {

         console.error(e);
         this._toastr.error('Operation completed with errors');

      } finally {
         this.isLoading = false;
      }
   }

   //
   private validateStrategiesInAutomatedMode(): boolean {

      let errors = this.shortOptionsBlock.parameters.validate({
         legRequired: false,
         legToAttachTo: undefined,
         mode: 'create',
         ctx: 'direct',
         globalSettings: this.globalSettings
      });
      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e, this.shortOptionsBlock.parameters.displayName || 'Short Option'));
         return false;
      }

      errors = this.shortTermDebitSpreadBlock.spreadRollParameters.validate({
         legRequired: false,
         legToAttachTo: undefined,
         mode: 'create',
         ctx: 'direct',
         globalSettings: this.globalSettings
      });
      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e, this.shortTermDebitSpreadBlock.spreadRollParameters.displayName || 'Spread Roll'));
         return false;
      }

      errors = this.longTermOptionBlock.parameters.validate({
         legRequired: false,
         legToAttachTo: undefined,
         mode: 'create',
         ctx: 'direct',
         globalSettings: this.globalSettings
      });
      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e, this.longTermOptionBlock.parameters.displayName || 'Protective Put'
            ));
         return false;
      }

      errors = this.interestBlock.parameters.validate({
         globalSettings: this.globalSettings
      });
      if (errors.length > 0) {
         errors.forEach(e => this._toastr.error(e, this.interestBlock.parameters.displayName || 'Interest'));
         return false;
      }
      
      return true;
   }

   fillGlobalSettings(strategy: StrategyModel) {
      const p = strategy.parameters as any;

   
      const oType = p['ordertype'];
      this.globalSettings.orderSettings.orderType = oType ? parseInt(oType) : null;

      const limitPrice = p['limitprice'] as any;
      this.globalSettings.orderSettings.autoLimitPrice = limitPrice;

      // const atmNeutralZone = p['atmneutralzone'];
      // this.commonSettings.orderSettings.atmNeutralZone = atmNeutralZone ? parseFloat(atmNeutralZone) : null;

      const ctmActionTimeMode = p['ctmactiontimemode'] as any;

      if (ctmActionTimeMode) {
         this.globalSettings.orderSettings.convertToMarket = true;
         this.globalSettings.orderSettings.convertToMarketSettings.actionTimeMode = ctmActionTimeMode;
         this.globalSettings.orderSettings.convertToMarketSettings.actionTime = p['ctmactiontime'];
         this.globalSettings.orderSettings.convertToMarketSettings.timezone = p['ctmtimezone'];

         const timesToReplace = p['ctmtimestoreplace'];
         this.globalSettings.orderSettings.convertToMarketSettings.timesToReplace = timesToReplace ? parseInt(timesToReplace) : null;

         const replacePersistently = p['ctmreplacepersistently'];
         this.globalSettings.orderSettings.convertToMarketSettings.replacePersistently = replacePersistently === 'true';

         if (timesToReplace || replacePersistently) {
            this.globalSettings.orderSettings.convertToMarketSettings.replaceBeforeConvert = true;
         }

         this.globalSettings.orderSettings.convertToMarketSettings.replaceEvery = p['ctmreplaceevery'];

         const roc = p['ctmrateofchange'];
         if (roc) {
            this.globalSettings.orderSettings.convertToMarketSettings.rateOfChange = parseFloat(roc);
         }

         const reverseTimeDirection = p['ctmreversedirection'];
         this.globalSettings.orderSettings.convertToMarketSettings.reverseTimeDirection = reverseTimeDirection === 'true';
      }

      this.globalSettings.rollTimeSettings.rollTimeMode = p['sellactiontimemode'];
      this.globalSettings.rollTimeSettings.rollTime = p['sellactiontime'];
      this.globalSettings.rollTimeSettings.rollTimezone = p['sellactiontimezone'];
      
      this.globalSettings.rollTimeSettings.joinRollBuffer = p.joinrollbuffer;

      this.globalSettings.optimalPricingUp.optimalExpirationLookForwardLimit = parseInt(p['optimalexpirationlookforwardlimit']) || null;
      this.globalSettings.optimalPricingUp.optimalExpirationSelectionMode = p['optimalexpirationselectionmode'] as any;
      this.globalSettings.optimalPricingUp.optimalExpirationPriceBuffer = parseFloat(p['optimalexpirationpricebuffer'])  || null;
      
      this.globalSettings.optimalPricingDown.optimalExpirationLookForwardLimit = parseInt(p['optimalexpirationlookforwardlimitdown']) || null;
      this.globalSettings.optimalPricingDown.optimalExpirationSelectionMode = p['optimalexpirationselectionmodedown'] as any;
      this.globalSettings.optimalPricingDown.optimalExpirationPriceBuffer = parseFloat(p['optimalexpirationpricebufferdown'])  || null;
   }

   private resetCommonSettings() {
      this.globalSettings = {
         
         optimalPricingUp: {
            optimalExpirationSelectionModeList: [
               'Better',
               'Breakeven Or Better'
            ]
         },

         optimalPricingDown: {
            optimalExpirationSelectionModeList: [
               'Better',
               'Breakeven Or Better'
            ]
         },

         orderSettings: {
            orderTypes: [
               {
                  text: 'Limit',
                  value: OrderType.Limit
               },
               {
                  text: 'Market',
                  value: OrderType.Market
               }
            ],
            convertToMarket: false,
            convertToMarketSettings: { },
         },

         rollTimeSettings: {
            rollTimeModes: [
               'Time Of Day',
               '[H:M:S] Before Close',
               '[H:M:S] After Open',
            ]
         },

         otherSettings: {}

      }
   }

   @DetectMethodChanges({isAsync: true})
   async updateBucketQtyGuard() {

      const attr: BucketAttributes = {
         qtyGuard:  this.bucketQtyGuard
      };

      const cmd = new SaveBucketAttributes(this.selectedCombo.comboId, 'Combo', attr);

      try {
         
         this.isLoading = true;
         
         await this._shellClient.processCommand(cmd);

      } catch (e) {
         
         console.error(e);
         this._toastr.error('Operation completed with errors');

      } finally {

         this.isLoading = false;

      }
   }

   private async onClearTradingDataMessage(message: ClearTradingDataUIMessage) {

      if (!message.data.manual && !message.refreshDb) {
         return;
      }

      await this.onComboChanged();
   }
}

