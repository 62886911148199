import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Column, GetContextMenuItemsParams, GridOptions, GridReadyEvent} from "ag-grid-community";
import {getColumnDefs, getHedgeMatrixGridOptions} from "./hedge-matrix-grid-options";
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    defaultCurrencyFormatter,
    DetectMethodChanges,
    DxValueChanged,
    isValidNumber,
    isVoid,
    makeDayOfWeekDate,
    makeGuiFriendlyExpirationDateDte
} from "../../utils";
import {TradingInstrument} from "../../trading-instruments/trading-instrument.class";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {OptionsChainService} from "../../option-chains.service";
import {ToastrService} from "ngx-toastr";
import {MessageBusService} from "../../message-bus.service";
import * as Enumerable from "linq";
import {HedgeMatrixDataService, HedgeMatrixTransLeg} from "./hedge-matrix-data.service";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {makeOptionTicker, makeOptionTickerDisplayName, parseOptionTicker} from "../../options-common/options.model";
import {TradingInstrumentsService} from "../../trading-instruments/trading-instruments-service.interface";
import {OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {AtmStrikeService} from "../../common-services/atm-strike-service/atm-strike.service";
import {HedgesPricingService} from "../../package-comparison/services/hedges-pricing.service";
import {UserSettingsService} from "../../user-settings.service";
import {Subscription} from "rxjs";
import {
    HgHedgeMatrixHedgesSelectorComponent
} from "./hg-hedge-matrix-hedges-selector/hg-hedge-matrix-hedges-selector.component";
import {isNullOrUndefined} from "util";
import {HedgeData} from "./hedge-data";
import {ComboHighlightedItem, ComboHighlightedUIMessage} from "../../ui-messages/ui-messages";
import {TimeInForce} from "../../trading-model/time-in-force.enum";
import {OrderType} from "../../trading-model/order-type.enum";
import {PortfolioItemType} from "../../portfolios/portfolios.model";
import {MarketSide} from "../../trading-model/market-side.enum";
import {ClipboardService} from "../../clipboard.service";
import {DateTime} from "luxon";
import {Hedge} from "../../package-comparison/model/PackageComparisonModel";

const ShowPositionsKey = 'hg.matrix.show-positions';
const StrikesNoKey = 'hg.matrix.strikes-no';
const StrikeStepKey = 'hg.matrix.strike-step';
const CenterStrikeKey = 'hg.matrix.center-strike';

export interface HedgeMatrixRow {
    isPinned?: boolean;
    isHedgePrice?: boolean;
    isExpiration?: boolean;
    isDayOfWeek?: boolean;
    strike?: number;
}

@Component({
    selector: 'ets-hg-hedge-matrix',
    templateUrl: 'hg-hedge-matrix.component.html',
    styleUrls: ['hg-hedge-matrix.component.scss']
})

export class HgHedgeMatrixComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _hedgeService: HedgePositionsService,
        private readonly _messageBus: MessageBusService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _optionsChainService: OptionsChainService,
        private readonly _toastr: ToastrService,
        private readonly _hedgeMatrixDataService: HedgeMatrixDataService,
        private readonly _apgDataService: ApgDataService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _userSettingsService: UserSettingsService,
        private _clipboardService: ClipboardService,
    ) {
        const svc = new HedgesPricingService(
            this._hedgePositionsService,
            this._lastQuoteCache,
            this._messageBus,
            this._apgDataService
        );
        this._hedgePricingService = svc;
    }

    private readonly _hedgePricingService: HedgesPricingService;
    private readonly _subscriptions: Subscription[] = [];

    hiddenHedges = [];
    visibleExpirations = [];
    visiblePnls = [];

    gridState: any = {};

    @ViewChild(HgHedgeMatrixHedgesSelectorComponent)
    hedgesSelectorCmp: HgHedgeMatrixHedgesSelectorComponent;

    isLoading = false;

    theGrid: GridReadyEvent;

    gridOptions: GridOptions;

    selectedPortfolio: ApgPortfolio;

    centerStrike: number;

    tradingInstrument: TradingInstrument;

    strikeSteps = [];

    strikeStep = 5;

    numOfStrikes: number = 20;

    showPortfolioPositions = false;

    activeExpiration: OptionExpirationDescriptor;

    controlPanelExpanded = true;

    reduceHeaders = false;

    get hedgeMatrixDataService(): HedgeMatrixDataService {
        return this._hedgeMatrixDataService;
    }

    get lastQuoteCache(): LastQuoteCacheService {
        return this._lastQuoteCache;
    }

    @DetectMethodChanges({isAsync: true})
    async ngOnInit() {

        this.restoreValues();

        this._hedgePricingService.init();

        this.gridOptions = getHedgeMatrixGridOptions(this);

        this._subscriptions.push(
            this._messageBus.of('Hg.RefreshQuotes')
                .subscribe(() => this.onChange())
        );

        this._subscriptions.push(
            this._messageBus.of('Hg.HedgePositionsChanged')
                .subscribe(() => this.reloadData())
        );

        this._subscriptions.push(
            this._messageBus.of('Hg.HedgesCleared')
                .subscribe(() => this.reloadData())
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }

    @DetectMethodChanges({isAsync: true})
    async onGridReady(args: GridReadyEvent) {

        this.theGrid = args;
    }

    private getRows(): any[] {

        const strikesToShow = this.getStrikesToShow();

        const rows = strikesToShow.map(x => {

            const callTicker = this.makeTickerForRow(x, 'Call');

            const putTicker = this.makeTickerForRow(x, 'Put');

            const otm = x - this.centerStrike;

            return {
                otm,
                strike: x,
                callTicker,
                putTicker
            }
        });

        return rows;
    }


    private getStrikesToShow(): number[] {

        let centerStrike = this.centerStrike;

        if (isVoid(centerStrike)) {

            centerStrike = this._atmStrikeService.getCurrentAtm(this.tradingInstrument.ticker);

            if (isVoid(centerStrike)) {
                const lastQuote = this.tradingInstrument
                    ? this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker)
                    : null;
                this._toastr.warning('Cannot Determine Correct Market ATM. Check Quotes Provider');
                return [];
            }
        }


        const upperBound = centerStrike + this.numOfStrikes * this.strikeStep;
        const lowerBound = centerStrike - this.numOfStrikes * this.strikeStep;

        const up = Enumerable
            .rangeTo(centerStrike + this.strikeStep, upperBound, this.strikeStep);

        const down = Enumerable
            .rangeTo(lowerBound, centerStrike - this.strikeStep, this.strikeStep);

        const hedgeStrikes = this.hedgeMatrixDataService.getLegStrikes();

        let portfolioPositionStrikes = this.hedgeMatrixDataService.getPortfolioPositionStrikes();

        const strikes = Enumerable.from([centerStrike])
            .concat(up)
            .concat(down)
            .concat(hedgeStrikes)
            .concat(portfolioPositionStrikes)
            .distinct()
            .orderByDescending(x => x)
            .toArray();

        return strikes;
    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: ApgPortfolio): Promise<void> {

        await this._hedgePricingService.subscribe(args);

        this.theGrid.api.setColumnDefs([]);

        this.theGrid.api.setRowData([]);

        this.selectedPortfolio = args;

        const ul = await this.getPortfolioUnderlying(args);

        if (isVoid(this.strikeStep)) {
            if (ul === 'SPX') {
                this.strikeStep = 5;
            } else {
                this.strikeStep = 1;
            }
        }

        this.strikeSteps = Enumerable
            .rangeTo(1, 50)
            .select(x => x * (ul === 'SPX' ? 5 : 1))
            .toArray();

        this._atmStrikeService.watch(ul);

        this.tradingInstrument = this._tiService.getInstrumentByTicker(ul);

        await this.hedgeMatrixDataService.onPortfolioSelected(this.selectedPortfolio);

        const hedges = this.hedgeMatrixDataService.getHedges();

        this.hedgeList = hedges.map(x => {
            const id = x.id;
            const label = x.name;
            const side = x.type;

            return {
                id,
                label,
                side
            };
        });

        this.expirationList = Enumerable.from(hedges)
            .groupBy(x => x.expiration)
            .selectMany(x => {
                const groupBy = x
                    .groupBy(y => y.type)
                    .select(y => {

                        return {
                            expiration: x.key(),
                            side: y.key(),
                            expirationFull: makeGuiFriendlyExpirationDateDte(x.key()),
                            dayOfWeek: makeDayOfWeekDate(x.key())
                        };
                    });

                return groupBy;
            })
            .orderBy(x => x.side)
            .thenBy(x => x.expiration)
            .toArray();

        this.uniqueExpirationList = Enumerable.from(this.expirationList)
            .groupBy(x => x.expiration)
            .select(x => {
                return {
                    expiration: x.key(),
                    expirationFull: makeGuiFriendlyExpirationDateDte(x.key()),
                    dayOfWeek: makeDayOfWeekDate(x.key())
                };
            }).toArray();

        this.pnlExpirationsList = Enumerable.from(hedges)
            .groupBy(x => x.expiration)
            .selectMany(x => {
                const groupBy = x
                    .groupBy(y => y.type)
                    .select(y => {

                        return {
                            expiration: x.key(),
                            side: y.key(),
                            expirationFull: makeGuiFriendlyExpirationDateDte(x.key()),
                            dayOfWeek: makeDayOfWeekDate(x.key())
                        };
                    });

                return groupBy;
            })
            .orderBy(x => x.side)
            .thenBy(x => x.expiration)
            .toArray();

        this.activeExpiration = this.hedgeMatrixDataService.activeExpiration;

        if (isVoid(this.centerStrike)) {
            this.centerStrike = this._atmStrikeService.getCurrentAtm(ul);
        }

        const data = getColumnDefs(this, false);

        this.theGrid.api.setColumnDefs(data);

        this.restoreState();

        hedges.filter(x => !x.isNew).forEach(x => {
            const col = this.theGrid.columnApi.getColumn(x.id);
            if (!col) {
                return;
            }
            this.modifyHege(col, false);
        })

        const rows = this.getRows();

        this.hedgeMatrixDataService.calculatePnls(rows.map(x => x.strike));

        this.theGrid.api.setRowData(rows);

        const pinned = this.getPinnedRows();
        this.theGrid.api.setPinnedTopRowData(pinned);

        this.theGrid.api.refreshCells({force: true});

    }

    private getPinnedRows(): HedgeMatrixRow[] {
        return [
            {isPinned: true, isExpiration: true},
            {isPinned: true, isDayOfWeek: true},
            {isPinned: true, isHedgePrice: true},
        ]
    }

    @DetectMethodChanges()
    private onChange() {
        if (isVoid(this.theGrid)) {
            return;
        }
        this.theGrid.api.refreshCells({force: true});
    }

    private makeTickerForRow(strike: number, type: 'Call' | 'Put'): string {
        if (!this.tradingInstrument) {
            return undefined;
        }

        const activeExpiration = this._hedgeMatrixDataService.activeExpiration;

        const ticker = makeOptionTicker(activeExpiration, type, strike);

        return ticker;
    }

    private async getPortfolioUnderlying(portfolio: ApgPortfolio): Promise<string> {
        const underlying = await this._apgDataService.getUnderlyingOfPortfolio(portfolio);
        return underlying;
    }

    private async reloadData(): Promise<void> {
        await this.onPortfolioSelected(this.selectedPortfolio);
    }

    async onCenterStrikeChanged(): Promise<void> {
        await this.reloadData();
        this._userSettingsService.setValue(CenterStrikeKey, this.centerStrike);
    }

    async onStrikeStepChanged(): Promise<void> {
        await this.reloadData();
        this._userSettingsService.setValue(StrikeStepKey, this.strikeStep);
    }

    async onNumberOfStrikesChanged() {
        await this.reloadData();
        this._userSettingsService.setValue(StrikesNoKey, this.numOfStrikes);
    }

    onShowPortfolioPositionsChanged() {
        if (isVoid(this.theGrid)) {
            return;
        }

        this.theGrid.api.redrawRows();

        this._userSettingsService.setValue(ShowPositionsKey, this.showPortfolioPositions);
    }

    getHedgePriceFormatted(hedge: HedgeData) {
        const px = this.getHedgePrice(hedge);
        if (!isValidNumber(px)) {
            return null;
        }
        const price = defaultCurrencyFormatter(px);
        return price;
    }

    getHedgePrice(hedge: HedgeData) {
        const id = hedge.id;
        const px = this._hedgePricingService.getHedgeGroupCost(id);
        return px;
    }


    private restoreValues() {
        const centerStrike = this._userSettingsService.getValue<number>(CenterStrikeKey);
        this.centerStrike = centerStrike;

        const strikeStep = this._userSettingsService.getValue<number>(StrikeStepKey);
        this.strikeStep = strikeStep || 5;

        const strikesNo = this._userSettingsService.getValue<number>(StrikesNoKey);
        this.numOfStrikes = strikesNo || 20;

        const showPositions = this._userSettingsService.getValue<boolean>(ShowPositionsKey);
        this.showPortfolioPositions = showPositions || false;
    }

    @DetectMethodChanges()
    toggleControlsPanel() {
        this.controlPanelExpanded = !this.controlPanelExpanded;
    }

    toggleReduceHeaders() {
        if (this.reduceHeaders) {
            this.theGrid.api.setPinnedTopRowData([]);
        } else {
            const pinnedRows = this.getPinnedRows();
            this.theGrid.api.setPinnedTopRowData(pinnedRows);
        }
    }


    //
    // Hedge Selected Dialog
    //
    hedgeList: { id: string, label: string, side: 'Call' | 'Put' }[] = [];

    expirationList: any[] = [];

    pnlExpirationsList = [];

    uniqueExpirationList: any[] = [];

    get selectedCallHedges(): number {
        return this.getSelectedHedgesCount('Call');
    }

    get selectedPutHedges(): number {
        return this.getSelectedHedgesCount('Put');
    };

    get selectedCallExpirations(): number {
        return this.getSelectedExpirationsCount('Call');
    };

    get selectedPutExpirations(): number {
        return this.getSelectedExpirationsCount('Put');
    };

    private getSelectedExpirationsCount(side: 'Call' | 'Put'): number {
        if (isVoid(this.theGrid)) {
            return undefined;
        }

        const amount = this.expirationList.filter(x => x.side === side)
            .map(x => {
                const colId = `${x.expiration}:expiration:${side}`;
                const column = this.theGrid.columnApi.getColumn(colId);
                if (isVoid(column)) {
                    return 0;
                }
                return column.isVisible() ? 1 : 0;
            })
            .reduce((p, c) => p + c, 0);

        return amount;
    }

    private getSelectedHedgesCount(side: 'Call' | 'Put'): number {
        return this.getSelectedHedges(side).length;
    }

    getSelectedHedges(side: 'Call' | 'Put'): any[] {
        if (isVoid(this.theGrid)) {
            return [];
        }

        const amount = this.hedgeList
            .filter(x => x.side === side)
            .map(x => {
                const colId = x.id;
                const column = this.theGrid.columnApi.getColumn(colId);
                if (isVoid(column) || !column.isVisible()) {
                    return undefined;
                }
                return column;
            })
            .filter(x => !isVoid(x));

        return amount;
    }


    @DetectMethodChanges()
    onHedgeSelectionChanged(hedge: any, value?: boolean) {

        const id = hedge.id;
        const column = this.theGrid.columnApi.getColumn(id);

        const transId = `${id}:trans`;
        const transColumn = this.theGrid.columnApi.getColumn(transId);

        const outcomeId = `${id}:outcome`;
        const outcomeColumn = this.theGrid.columnApi.getColumn(outcomeId);

        if (isNullOrUndefined(value)) {
            value = !column.isVisible();
        }

        if (!isVoid(column)) {
            this.theGrid.columnApi.setColumnVisible(column, value);
        }

        if (!isVoid(transColumn)) {
            this.theGrid.columnApi.setColumnVisible(transColumn, false);
        }

        if (!isVoid(outcomeColumn)) {
            this.theGrid.columnApi.setColumnVisible(outcomeColumn, false);
        }

        this.theGrid.api.refreshCells({force: true});

        // this.updateVisibleColumns();
    }


    @DetectMethodChanges()
    onExpirationSelectionChange(expiration: { expiration: string, side: 'Call' | 'Put' }, value?: boolean) {

        const id = `${expiration.expiration}:expiration:${expiration.side}`;

        const column = this.theGrid.columnApi.getColumn(id);

        if (isVoid(column)) {
            return;
        }

        if (isNullOrUndefined(value)) {
            value = !column.isVisible();
        }

        this.theGrid.columnApi.setColumnVisible(column, value);

        this.theGrid.api.forEachNode(node => {
            const strike: number = node.data.strike;
            if (!isValidNumber(node.data.strike, true)) {
                return;
            }

            const ticker = this.hedgeMatrixDataService.getTickerForCell(
                strike, expiration.expiration, expiration.side
            );

            if (!value) {
                this.lastQuoteCache.unsubscribeTicker(ticker);
            } else {
                this.lastQuoteCache.subscribeTicker(ticker);
            }
        });

        this.theGrid.api.refreshCells({force: true});

        //this.updateVisibleColumns();
    }

    toggleSelectAllHedges(args: DxValueChanged<boolean>, side?: 'Call' | 'Put') {
        if (!args.event) {
            return;
        }

        this.hedgeList
            .filter(x => x => isVoid(side) ? true : x.side === side)
            .forEach(x => this.onHedgeSelectionChanged(x, args.value));
    }

    toggleSelectAllExpirations(args: DxValueChanged<boolean>, side?: 'Call' | 'Put') {
        if (!args.event) {
            return;
        }

        this.expirationList
            .filter(x => x => isVoid(side) ? true : x.side === side)
            .forEach(x => this.onExpirationSelectionChange(x, args.value));
    }

    getColumnSelectorTextHedges() {

        const selectedHedges = this.selectedCallHedges + this.selectedPutHedges;
        const totalHedges = this.hedgeList.length;

        if (totalHedges === selectedHedges) {
            return "All Hedges Selected";
        }

        return `${selectedHedges} of ${totalHedges} Hedges Selected`;
    }

    getColumnSelectorTextExpirations() {
        const selectedExpirations = this.selectedCallExpirations + this.selectedPutExpirations;
        const totalExpirations = this.expirationList.length;

        if (totalExpirations === selectedExpirations) {
            return "All Expirations Selected";
        }

        return `${selectedExpirations} of ${totalExpirations} Expirations Selected`;
    }

    showHedgesSelector() {
        this.hedgesSelectorCmp.show(this);
    }

    isHedgeSelected(hedge: any): boolean {
        const id = hedge.id;
        const col = this.theGrid.columnApi.getColumn(id);
        return col && col.isVisible();
    }

    isExpirationSelected(expiration: any): boolean {
        const id = `${expiration.expiration}:expiration:${expiration.side}`;
        const col = this.theGrid.columnApi.getColumn(id);
        return col && col.isVisible();
    }

    modifyHege(column: Column, visibility?: boolean) {

        this.theGrid.api.showLoadingOverlay();

        try {
            const hedgeData: HedgeData = column.getColDef()['ets-data'];

            if (isVoid(hedgeData)) {
                this._toastr.error('Cannot Determine Hedge Data');
                return;
            }

            let colId = `${hedgeData.id}:trans`;
            let col = this.theGrid.columnApi.getColumn(colId);
            console.assert(!isVoid(col));

            let colVisibility = visibility;
            if (isNullOrUndefined(colVisibility)) {
                colVisibility = !col.isVisible()
            }

            this.theGrid.columnApi.setColumnVisible(col, colVisibility);

            colId = `${hedgeData.id}:outcome`;
            col = this.theGrid.columnApi.getColumn(colId);
            colVisibility = visibility;
            if (isNullOrUndefined(colVisibility)) {
                colVisibility = !col.isVisible()
            }

            this.theGrid.columnApi.setColumnVisible(col, colVisibility);

            const self = this;

            const f = !col.isVisible()
                ? () => self.hedgeMatrixDataService.onHedgeModificationFinished(hedgeData)
                : () => self.hedgeMatrixDataService.onHedgeModificationStarted(hedgeData);

            setTimeout(() => {
                f();
                self.recalculatePnls();
                self.theGrid.api.refreshCells({force: true});
            });

        } finally {
            setTimeout(() => {
                this.theGrid.api.hideOverlay()
            }, 500);
        }
    }

    addHedge(side: "Call" | "Put", column: Column) {

        const hedgeData = this.hedgeMatrixDataService.addNewHedge(side);

        const columnDefs = getColumnDefs(this, false);

        // const clickedColumnDef = column.getColDef();

        // const clickedColumnHedgeData = clickedColumnDef['ets-data'] as HedgeData;

        // const isHedgeColumn = !isVoid(clickedColumnHedgeData);

        // let insertToIndex = 2;
        //
        // if (isHedgeColumn) {
        //     const lastColumnOfTheHedgeGroup = `${clickedColumnHedgeData.id}:colgroup`;
        //     insertToIndex = columnDefs.findIndex((x : ColDef) => x.colId === lastColumnOfTheHedgeGroup)
        // }

        // const hedgeData: HedgeData = {
        //     id: getShortUUID(),
        //     type: side,
        //     name: 'New Hedge    ',
        //     color: undefined,
        //     dayOfWeek: undefined,
        //     expiration: undefined,
        //     legs: []
        // };

        // const hedgeCol = getHedgeColumn(this, hedgeData);

        // columnDefs.splice(insertToIndex, 0, hedgeCol);

        // this.theGrid.api.setColumnDefs([]);

        this.theGrid.api.setColumnDefs(columnDefs);

        this.restoreState();

        const hedgeListItem = {
            id: hedgeData.id,
            side: hedgeData.type,
            label: hedgeData.name,
        };

        this.hedgeList.push(hedgeListItem);

        this.recalculatePnls();
    }

    removeHedge(id: string) {
        this.hedgeMatrixDataService.removeHedge(id);

        const colDefs = getColumnDefs(this, false);

        this.theGrid.api.setColumnDefs(colDefs);

        const ix = this.hedgeList.findIndex(x => x.id === id);

        if (ix === -1) {
            return;
        }

        this.hedgeList.splice(ix, 1);
    }

    async copyOrders(hedge: HedgeData, destination: 'hg' | 'ml-pad' | 'tos') {
        if (destination === 'tos') {
            await this.copyToTos(hedge);
        } else {
            this.copyToEts(hedge, destination);
        }
    }

    private async copyToTos(hedge: HedgeData) {

        const cbItems = this.makeComboItems(hedge?.id);

        const cost = this.hedgeMatrixDataService.getTransCost(hedge?.id);

        if (isVoid(cbItems)) {
            this._toastr.warning('No Legs to Copy!');
            return;
        }

        let hcf = 1;
        let orderQty = 1;

        const transformedLegs = cbItems
            .map(x => {

                const optionTicker = parseOptionTicker(x.ticker);

                const qty = (x.netPosition / hcf) * x.side;

                const date = DateTime.fromFormat(optionTicker.expiration, 'yyyy-MM-dd')
                    .toFormat('dd MMM yy')
                    .toUpperCase();

                const strike = optionTicker.strike;
                const type = optionTicker.type.toUpperCase();
                const price = cost;

                return {
                    qty,
                    date,
                    strike,
                    type,
                    price
                };
            });

        const underlying = this.hedgeMatrixDataService.portfolioUnderlying;

        const quantities = transformedLegs.map(x => x.qty).join('/');
        const dates = transformedLegs.map(x => x.date).join('/');
        const strikes = transformedLegs.map(x => x.strike).join('/');
        const types = transformedLegs.map(x => x.type).join('/');

        let line: string;
        if (underlying === 'SPX') {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 (Weeklys) ${dates} ${strikes} ${types} @ LMT GTC`;
        } else {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 ${dates} ${strikes} ${types} @ LMT GTC`;
        }

        await navigator.clipboard.writeText(line);

        this._toastr.success('Orders Copied to Clipboard!');
    }

    private copyToEts(hedge: HedgeData, destination: string) {
        const cost = this.hedgeMatrixDataService.getTransCost(hedge?.id);

        const comboItems = this.makeComboItems(hedge.id);

        if (isVoid(comboItems)) {
            this._toastr.warning('No Legs to Copy!');
            return;
        }

        const msg: ComboHighlightedUIMessage = {
            items: comboItems,
            orderParams: {
                orderLimitPx: cost,
                orderDuration: TimeInForce.GTC,
                orderQty: 1,
                orderType: OrderType.Limit
            }
        };

        if (!hedge.isNew) {
            msg.hedgingGridTransaction = {
                groupId: hedge?.id
            }
        }


        const clipboardKey = destination === 'hg' ? 'hg.opg-data' : 'combo';
        this._clipboardService.put(clipboardKey, msg);
        this._toastr.success('Combo copied to clipboard');

    }

    private makeComboItems(hedgeId: string): ComboHighlightedItem[] {

        const legs = this.hedgeMatrixDataService.getTransactionLegs(hedgeId);

        const comboItems = legs.map(x => {

            const optionTicker = parseOptionTicker(x.ticker);

            const displayName = makeOptionTickerDisplayName(optionTicker);

            const optionType = optionTicker.type === 'Call'
                ? PortfolioItemType.Call
                : PortfolioItemType.Put;

            let side = MarketSide.Flat;
            if (x.qty > 0) {
                side = MarketSide.Buy;
            } else if (x.qty < 0) {
                side = MarketSide.Sell;
            }

            const item: ComboHighlightedItem = {
                accountId: null,
                comboId: null,
                portfolioId: null,
                ticker: x.ticker,
                underlying: optionTicker.underlying,
                tickerDisplayName: displayName,
                netPosition: Math.abs(x.qty),
                side: side,
                itemType: optionType,
                strategyId: null
            };

            return item;
        });

        return comboItems;
    }


    @DetectMethodChanges()
    onPnlExpirationSelectionChanged(expiration: { expiration: string, side: 'Call' | 'Put' }, value?: boolean, allInclusive?: boolean) {

        let id = `${expiration.expiration}:pnl-expiration:${expiration.side}`;

        if (allInclusive) {
            id += ':all';
        }

        const column = this.theGrid.columnApi.getColumn(id);

        if (isVoid(column)) {
            return;
        }

        if (isNullOrUndefined(value)) {
            value = !column.isVisible();
        }

        this.theGrid.columnApi.setColumnVisible(column, value);

        this.theGrid.api.refreshCells({force: true});

        // this.updateVisibleColumns();
    }

    updateVisibleColumns() {

        if (!this.theGrid) {
            return;
        }

        this.saveState();

    }

    private saveState() {
        const gridOptions = this.theGrid;

        const colState = gridOptions.columnApi.getColumnState();
        const groupState = gridOptions.columnApi.getColumnGroupState();

        this.gridState = {
            colState,
            groupState,
        }
    }


    private restoreState() {

        if (!this.gridState) {
            return;
        }

        if (!this.theGrid) {
            return;
        }

        const gridOptions = this.theGrid;

        const colState = this.gridState.colState;
        const groupState = this.gridState.groupState;

        if (colState) {
            gridOptions.columnApi.setColumnState(colState);
        }

        if (groupState) {
            gridOptions.columnApi.setColumnGroupState(groupState);
        }
    }


    isPnlExpirationSelected(expiration: any, allInclusive: boolean): boolean {
        let id = `${expiration.expiration}:pnl-expiration:${expiration.side}`;
        if (allInclusive) {
            id += ':all';
        }
        const col = this.theGrid.columnApi.getColumn(id);
        return col && col.isVisible();
    }

    toggleSelectAllPnlExpirations(args: DxValueChanged<boolean>) {
        if (!args.event) {
            return;
        }

        this.pnlExpirationsList
            .forEach(x => this.onPnlExpirationSelectionChanged(x, args.value));
    }

    recalculatePnls() {
        const strikesToShow = this.getStrikesToShow();
        this.hedgeMatrixDataService.calculatePnls(strikesToShow);
        this.theGrid.api.refreshCells();
    }

    onGrandTotalExpirationSelectionChanged(expiration: any) {
        let id = `${expiration.expiration}:grandtotalpnl`;

        const column = this.theGrid.columnApi.getColumn(id);

        if (isVoid(column)) {
            return;
        }

        this.theGrid.columnApi.setColumnVisible(column, !column.isVisible());

        this.theGrid.api.refreshCells({force: true});
    }

    isGrandTotalExpirationSelected(expiration: any) {
        let id = `${expiration.expiration}:grandtotalpnl`;
        const col = this.theGrid.columnApi.getColumn(id);
        return col && col.isVisible();
    }
}