<div
  [ngClass]="{'ets-hidden': !isActive}"
  [ngStyle]="{'border-color': this.selectedPricingModel?.expirationPriceColor}"
  class="panel-container"
>
  
  <div #pad class="pricing-pad">
    
    <div class="section symbol-n-strategy">
      
      <div class="header">
        <h4 class="header">Symbol & Strategy</h4>
      </div>
      
      <div class="body">
        
        <div class="block symbol">
          <ets-symbol-picker
            (instrumentSelected)="this.onSymbolSelected($event)"
            [selectedInstrument]="this.tradingInstrument">
          </ets-symbol-picker>
        </div>
        
        <div [class.different]="this.hasDifference('selectedStrategy')" class="block strategy">
          <div class="label">Strategy</div>
          <dx-select-box
            (onValueChanged)="this.onStrategyChanged($event)"
            [(selectedItem)]="this.selectedStrategy"
            [(value)]="this.selectedStrategy"
            [disabled]="!this.tradingInstrument"
            [items]="this.strategiesList"
            [showClearButton]="true"
            placeholder="Strategy">
          </dx-select-box>
        </div>
        
        <div class="block option-type" [class.different]="this.hasDifference('optionType')">
          <div class="label">Option Type</div>
          <dx-select-box
            (onValueChanged)="this.onChange($event)"
            [(selectedItem)]="this.optionType"
            [(value)]="this.optionType"
            [disabled]="!this.isOptionTypeAvailable"
            [items]="this.optionTypeList"
            placeholder="Option"
          ></dx-select-box>
        </div>
        
        <div [class.different]="this.hasDifference('strikeWidth')" class="block strike-width">
          <div class="label">
            Strikes Width
          </div>
          <dx-number-box
            (onValueChanged)="this.onChange($event)"
            [(value)]="this.strikeWidth"
            [disabled]="!this.isStrikeWidthAvailable"
            [showClearButton]="true"
            [showSpinButtons]="true"
          ></dx-number-box>
        </div>
        
        <div class="block expiration" style="display: none">
          <div class="label">Expiration</div>
          <dx-select-box
            [(value)]="this.selectedExpiration"
            [disabled]="!this.tradingInstrument"
            [items]="this.expirationList"
            [showClearButton]="true"
            displayExpr="dateWithDaysToExpiration"
          >
            <div *dxTemplate="let data of 'item'">
                <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                   {{ data?.dateWithDaysToExpiration }}
                </span>
            </div>
          </dx-select-box>
        </div>
        
        <div [class.different]="this.hasDifference('strikeOffset')" class="block offset">
          
          <div class="label">
            <div class="btn"
                 [class.hot]="this.strikeOffsetMode === 'Offset'"
                 [class.disabled]="!this.isOffsetAvailable"
                 (click)="this.changeOffsetMode('Offset')"
            >
              Offset
            </div>
            <div class="btn"
                 [class.hot]="this.strikeOffsetMode === 'Strike'"
                 [class.disabled]="!this.isOffsetAvailable"
                 (click)="this.changeOffsetMode('Strike')"
            >
              Strike
            </div>
          </div>
          <div [style.display]="this.strikeOffsetMode === 'Offset' ? 'block' : 'none' ">
            <dx-number-box
              (onValueChanged)="this.onChange($event)"
              [(value)]="this.strikeOffset"
              [disabled]="!this.isOffsetAvailable"
              [showClearButton]="true"
              [showSpinButtons]="true"
              [inputAttr]="{style: 'text-align: center'}"
            ></dx-number-box>
          </div>
          <div [style.display]="this.strikeOffsetMode === 'Strike' ? 'block' : 'none' ">
            <ets-strikes-dropdown
              [targetInstrument]="this.tradingInstrument"
              [expiration]="this.selectedExpiration"
              [(strike)]="this.baseStrike"
              [inputAttr]="{style: 'text-align: center'}"
            ></ets-strikes-dropdown>
          </div>
        </div>
        
        <div class="block apply">
          <dx-button
            (onClick)="this.apply()"
            [disabled]="!this.canApply"
            text="Apply"
            type="default"
          >
          </dx-button>
        </div>
      
      </div>
    </div>
    
    <hr>
    
    <div class="legs">
      
      <div class="header">
        <h4>Legs</h4>
      </div>
      
      <div class="controls">
        <div class="add-leg">
          <dx-button
            (onClick)="this.addLeg('option')"
            [disabled]="!this.tradingInstrument"
            text="Add Option"
          ></dx-button>
          
          <dx-button
            (onClick)="this.addLeg('asset')"
            [disabled]="!this.tradingInstrument" text="Add Asset">
          </dx-button>
          
          <dx-button
            (onClick)="this.reverseLegsOption()"
            [disabled]="!this.tradingInstrument"
            text="Reverse Type"
          ></dx-button>
          
          <dx-button
            (onClick)="this.reverseLegsSide()"
            [disabled]="!this.tradingInstrument"
            text="Reverse Side"
          >
          </dx-button>
          
          <dx-button
            (onClick)="this.clearLegs()"
            [disabled]="!this.tradingInstrument"
            text="Clear Legs"
          ></dx-button>
        </div>
        
        <div class="strike-step-controls">
          <div *ngIf="this.hasLegs()" class="arrows">
            <i (click)="this.moveStructureUp()" class="fas fa-caret-up arrow"></i>
            <i (click)="this.moveStructureDown()" class="fas fa-caret-down arrow"></i>
          </div>
          <div class="control step">
            <dx-number-box [(value)]="this.strikeStep"
                           [min]="0"
                           [showSpinButtons]="true"
                           [useLargeSpinButtons]="false"
                           placeholder="Step"
            ></dx-number-box>
          </div>
          <div class="control lock">
            <dx-check-box [(value)]="this.lockStrikeStep" text="Lock"></dx-check-box>
          </div>
        </div>
      
      </div>
      
      <hr class="legs-separator">
      
      <div *ngFor="let leg of this.selectedPricingModel?.getLegs(this.enableAutoSorting)" class="leg">
        
        <ng-container *ngIf="leg.isSeparator && this.showSeparatorRows">
          
          <ng-container *ngIf="this.getSeparatorType() === 'Row'">
            <div class="leg">
              <div class="column buy-sell">
                &nbsp;
              </div>
              
              <div class="column qty">
                &nbsp;
              </div>
              
              <div class="column expiration">
                &nbsp;
              </div>
              
              <div class="column put-call">
                &nbsp;
              </div>
              
              <div class="column strike">
                &nbsp;
              </div>
              
              <div class="column up-down">
                &nbsp;
              </div>
              
              <div class="column price">
                &nbsp;
              </div>
              
              <div class="column remove">
                &nbsp;
              </div>
            </div>
          </ng-container>
          
          <ng-container *ngIf="this.getSeparatorType() === 'Line'">
            <hr style="border-color: gray; width: 85%; margin-top: 15px; margin-bottom: 15px;">
          </ng-container>
        
        </ng-container>
        
        <ng-container *ngIf="!leg.isSeparator">
          
          <div (click)="this.duplicateLeg(leg)" class="column duplicate">
            <i class="far fa-clone"></i>
          </div>
          
          <div class="column buy-sell">
            
            <dx-select-box
              (onValueChanged)="this.onLegMarketSideChanged($event, this.selectedPricingModel, leg)"
              [inputAttr]="{style: this.getLegColor(leg)}"
              [items]="this.selectedPricingModel?.marketSideList"
              [value]="leg.marketSideString"
              placeholder="Buy/Sell"
            >
              <div *dxTemplate="let data of 'item'">
                <span [style.color]="data === 'Buy' ? 'cornflowerblue' : 'red'">{{ data }}</span>
              </div>
            </dx-select-box>
          </div>
          
          <div class="column qty">
            
            <dx-number-box
              (onValueChanged)="this.onLegQtyChanged($event, leg)"
              [showSpinButtons]="false"
              [useLargeSpinButtons]="false"
              [value]="leg.qty"
              placeholder="Qty"
            ></dx-number-box>
          </div>
          
          <div [class.stock]="leg.isStock" class="column expiration">
            
            <dx-text-box
              [readOnly]="true"
              [text]="this.selectedPricingModel?.expiration?.dateWithDaysToExpiration"
              placeholder="Expiration"
            ></dx-text-box>
          </div>
          
          <div [class.stock]="leg.isStock" class="column put-call">
            
            <dx-select-box
              (onValueChanged)="this.onLegOptionTypeChanged($event, this.selectedPricingModel, leg)"
              [items]="this.selectedPricingModel?.optionTypes"
              [value]="leg.optionTypeString"
              placeholder="Call/Put"
            ></dx-select-box>
          </div>
          
          <div [class.stock]="leg.isStock" class="column strike">
            <ets-strikes-dropdown
              (strikeChanged)="this.onLegStrikeChanged($event, leg)"
              [expiration]="this.selectedPricingModel.expiration"
              [strike]="leg.strike"
              [targetInstrument]="this.tradingInstrument">
            </ets-strikes-dropdown>
          </div>
          
          <div [class.stock]="leg.isStock" class="column up-down">
            <div class="btn up">
              <i (click)="this.onStrikeUp(leg)" class="fas fa-sort-up fa-lg"></i>
            </div>
            <div class="btn down">
              <i (click)="this.onStrikeDown(leg)" class="fas fa-sort-down fa-lg"></i>
            </div>
          </div>
          
          <div class="column price">
            <dx-number-box
              [inputAttr]="{'style': 'text-align: center'}" [readOnly]="true" [showSpinButtons]="false"
              [value]="leg.price"
              format="#0.00"
              placeholder="Price"
            ></dx-number-box>
          </div>
          
          <div class="column remove" style="flex: 0">
            <i (click)="this.removeLeg(leg)" class="fa fa-times-circle"></i>
          </div>
        
        </ng-container>
      
      </div>
      
      <!-- Fake leg for Total Order Qty Row -->
      <div class="leg fake">
        
        <div class="column buy-sell fake">
          <dx-select-box [items]="this.selectedPricingModel?.marketSideList"
                         placeholder="Buy/Sell"
                         value="Buy">
            <div *dxTemplate="let data of 'item'">
              <span [style.color]="data === 'Buy' ? 'cornflowerblue' : 'red'">{{ data }}</span>
            </div>
          </dx-select-box>
        </div>
        
        <div class="column qty">
          <div *ngIf="this.selectedPricingModel?.legs?.length > 0">
            <div class="block">x</div>
            <div class="block">
              <dx-number-box
                [inputAttr]="{ style: 'color: yellow; font-weight: bold; font-family: cursive' }"
                [min]="1"
                [showSpinButtons]="true"
                [step]="1"
                [useLargeSpinButtons]="false"
                [value]="this.orderQty"
                format="#,##0"
                hint="Leg's Multiplier"
              >
              </dx-number-box>
            </div>
          </div>
        
        </div>
        
        <div class="column expiration fake">
          <dx-text-box></dx-text-box>
        </div>
        
        <div class="column put-call fake">
          <dx-select-box></dx-select-box>
        </div>
        
        <div class="column strike fake">
          <ets-strikes-dropdown></ets-strikes-dropdown>
        </div>
        
        <div class="column up-down fake">
          <div class="btn up">
            <i class="fas fa-sort-up fa-lg"></i>
          </div>
          <div class="btn down">
            <i class="fas fa-sort-down fa-lg"></i>
          </div>
        </div>
        
        <div class="column price fake">
          <dx-number-box></dx-number-box>
        </div>
        
        <div class="column remove fake">
          <i class="fa fa-times-circle"></i>
        </div>
      
      
      </div>
      
      <hr class="legs-separator">
    
    </div>
    
    <br>
    
    <div class="legs-controls">
      <div class="offline">
        <div class="block toggle">
          <dx-check-box [(value)]="this.turnOnLiveUpdates" text="Turn On Live Updates"></dx-check-box>
        </div>
        <div class="block button">
          <dx-button (onClick)="this.applyOfflineChanges()"
                     [disabled]="!this.hasChanges"
                     text="Apply Offline Changes"
          ></dx-button>
        </div>
      </div>
      
      <div class="right-side">
        <div class="link-to-other-pads">
          <dx-check-box
            (onValueChanged)="this.onChange($event)"
            [(value)]="this.linkToOtherPads"
            text="Link To Other Pads"
          >
          </dx-check-box>
        </div>
        <div class="show-separator-rows">
          <dx-check-box
            (onValueChanged)="this.onChange($event)"
            [(value)]="this.showSeparatorRows"
            text="Show Gap Row"
          >
          </dx-check-box>
        </div>
        <div class="disable-sorting">
          <dx-check-box
            (onValueChanged)="this.onChange($event)"
            [(value)]="this.enableAutoSorting"
            text="Enable Auto-Sorting"
          >
          </dx-check-box>
        </div>
      </div>
    </div>
    
    
    <hr>
    
    <div class="expirations-carousel">
      
      <h4 class="header">Expirations</h4>
      
      <div class="controls">
        
        <div class="block dropdown">
          <div class="label">Select Expiration</div>
          <div class="input">
            <dx-select-box (onValueChanged)=this.onExpirationDropdownSelectionChanged($event)
                           [acceptCustomValue]="false"
                           [disabled]="this.hasChanges"
                           [inputAttr]="this.selectedPricingModel?.expiration?.expirationTicker === '@SPX' ? {'style': 'color: yellow'} : {'style': 'color: undefined'}"
                           [items]="this.allPricingModels"
                           [value]="this.selectedPricingModel"
                           displayExpr="expiration.dateWithDaysToExpiration">
              <div *dxTemplate="let data of 'item'">
                     <span [ngStyle]="{'color': data?.expirationPriceColor}">
                        {{ data?.expiration?.dateWithDaysToExpiration }}
                       / {{ (data?.price | currency:'USD':'symbol':'1.2-2') || '$--,--' }}
                       <span *ngIf="data?.expiration?.expirationTicker === '@SPX'"
                             style="color: yellow;">&nbsp;(M)</span>
                     </span>
              </div>
            </dx-select-box>
          </div>
        </div>
        
        <div class="block settings">
          <div class="label">Show Expiration Date</div>
          <div class="input">
            <dx-select-box
              (onValueChanged)="this.onChange($event)"
              [(value)]="this.showExpirationDateMode"
              [items]="this.showExpirationDateModes"
            >
            </dx-select-box>
          </div>
        </div>
      
      </div>
      
      <div class="block carousel">
        <dx-tabs (onItemClick)="this.onExpirationTabSelectionChanged($event)"
                 [disabled]="this.hasChanges"
                 [items]="this.allPricingModels"
                 [scrollByContent]="true"
                 [selectedItem]="this.selectedPricingModel"
                 [showNavButtons]="true"
                 itemTemplate="tabItem">
          <div #ppTarget *dxTemplate="let itemData of 'tabItem'" style="min-width: 50px; height: 30px;">
            <div [style.color]="(itemData.expiration.expirationTicker === '@SPX' ? 'yellow' : '')">
              {{ itemData.expiration.daysToExpiration }}d
            </div>
            
            <div [style.color]="itemData.expirationPriceColor">
              {{ (itemData.price < 0 ? itemData.price * -1 : itemData.price) | currency }}
            </div>
            
            <div *ngIf="this.showExpirationDateMode === 'Visible'">
              <div>{{ itemData.expirationDate }}</div>
              <div>{{ itemData.expirationDay }}</div>
            </div>
            
            <ng-container *ngIf="this.showExpirationDateMode === 'Mouse Over'">
              <dx-popover [hideEvent]="'mouseleave'"
                          [position]="{ my: 'bottom', at: 'top', collision: 'fit flip'}"
                          [showEvent]="'mouseenter'"
                          [target]="ppTarget"
              >
                <div *dxTemplate="let data of 'content'" class="mouseOverOffsets">
                  {{ itemData.expirationDay }}, {{ itemData.expirationDate }}
                </div>
              </dx-popover>
            </ng-container>
          
          </div>
        </dx-tabs>
      </div>
    </div>
    
    <hr>
    
    <div class="order-params">
      
      <h4 class="header">Order Parameters</h4>
      
      <div class="inputs">
        
        <div class="block order-type">
          
          <p>Order Type</p>
          
          <dx-select-box [(value)]="this.orderType"
                         [displayExpr]="'text'"
                         [items]="this.availableOrderTypes"
                         [valueExpr]="'value'"
          ></dx-select-box>
        </div>
        
        <div class="block limit-px">
          
          <p>Limit Px</p>
          
          <dx-number-box (onValueChanged)="this.setOrderLimitPx($event.value)"
                         [disabled]="!this.canSetLimitPx"
                         [format]="{ style: 'currency', currency: 'USD' }"
                         [inputAttr]="this.limitPriceColor"
                         [showSpinButtons]="true"
                         [step]="this.tradingInstrument ? this.tradingInstrument.tickSize : 0"
                         [useLargeSpinButtons]="false"
                         [value]="this.orderLimitPx"
          ></dx-number-box>
        </div>
        
        <div class="block order-duration">
          
          <p>Day/GTC</p>
          
          <dx-select-box [(value)]="this.orderDuration"
                         [displayExpr]="'text'"
                         [items]="this.availableOrderDurations"
                         [valueExpr]="'value'"
          ></dx-select-box>
        </div>
        
        <div class="block copy-btn">
          
          <dx-button (onClick)="this.copyStrategyToClipboard(this.selectedPricingModel)"
                     hint="Copy Strategy To Clipboard"
                     icon="copy">
          </dx-button>
        </div>
      </div>
    </div>
    
    <hr>
    
    <div class="final">
      <div class="block total-cost">
        <span>Total Cost: </span>
        <span [ngStyle]="{'color': this.selectedPricingModel?.price > 0 ? 'red' : 'green'}">
               {{ this.selectedPricingModel?.totalCost * this.orderQty| currency }}
            </span>
      </div>
    
    </div>
  
  </div>
  
  
  <dx-load-panel [position]="{my: 'center', at: 'center', of: pad}" [visible]="this.isLoading"></dx-load-panel>

</div>