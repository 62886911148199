<div class="container">
   <dx-drop-down-box
      [disabled]="this.disabled"
      [dropDownOptions]="{width: 230}"
      [value]="this.value"
      [acceptCustomValue]="true"
      fieldTemplate="field"
      (onOpened)="this.onOpened()"
      (onClosed)="this.onClosed()"
      width="100%"
      >

      <div *dxTemplate="let data of 'field'">
         <dx-text-box 
            [value]="this.value" 
            [readOnly]="true">
         </dx-text-box>
      </div>
      
      <div *dxTemplate="let data of 'content'">
         <div class="area">
            <div class="options">
               <dx-list [items]="this.options"
                        [selectionMode]="'multiple'" 
                        [showSelectionControls]="true"
                        [(selectedItems)]="this.selectedOptions"
                        (onSelectionChanged)="this.onOptionSelectionChanged($event)">
               </dx-list>
            </div>
            <div class="structures">

               <div  class="structure-item" 
                     *ngFor="let data of this.structures"
                     [ngClass]="{'disabled': data.disabled}"
                  >
                  <span>{{data.name}}</span>
                  <img [src]="data.icon" width="32px" height="32px" />
                  <dx-check-box [(value)]="data.selected" [disabled]="data.disabled">
                  </dx-check-box>
               </div>

            </div>
         </div>
      </div>
   </dx-drop-down-box>
   
</div>