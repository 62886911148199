import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import {DetectMethodChanges, isVoid} from 'projects/shared-components/utils';
import {HedgePosition} from "../../data-model/hedge-position";
import * as Enumerable from "linq";

@Component({
   selector: 'ets-hg-after-state-preview',
   templateUrl: './hg-after-state-preview.component.html',
   styleUrls: [
      './hg-after-state-preview.component.scss',
       '../../hedging-grid-common-styles.scss'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class HgAfterStatePreviewComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   @ViewChild(DxPopupComponent) popup: DxPopupComponent;

   title = "After State Preview";
   
   width = 400;

   private _hedges: any;
   get hedges(): {label: string, color: string, positions: HedgePosition[]}[] {

      if (isVoid(this._hedges)) {
         const poses = Enumerable.from(this.state)
             .groupBy(x => x.label)
             .select(x => {
                const poses = x.toArray();
                let color = undefined;
                if (!isVoid(poses)) {
                   color = poses[0].color;
                }
                const order = poses[0]?.groupOrder;
                const obj = {
                   label: x.key(),
                   positions: poses,
                   color: color,
                   groupOrder: order
                };
                return obj;
             })
             .orderByDescending(x => {
                let order = x.groupOrder * -1;
                if (isVoid(order)) {
                   order = x.positions[0]?.strike;
                }
                return order;
             })
             .toArray();

         this._hedges = poses;
      }


      return this._hedges;
   }
   
   get height(): number {

      let value = 350;

      if (this.hedges.length > 1) {
         value = 450;
      }

      return value;

   }

   btnApplyText = 'Apply';

   btnCloseText = 'Close';

   state: HedgePosition[] = [];

   adjustment: string;

   ngOnInit(): void { }

   @DetectMethodChanges()
   show(state: {adjustmentDescriptor: string, positions: HedgePosition[]}): Promise<void> {

      this._hedges = undefined;
      
      this.state = state.positions;

      this.adjustment = state.adjustmentDescriptor;

      this.popup.visible = true;

      return new Promise<void>( (res, rej) => {
         this._resolver = res;
         this._rejector = rej;
      });
   }

   onApplyClicked() {
      if (this._resolver) {
         this._resolver();
      }
      this.closePreview();
   }

   onCloseClicked() {
      if (this._rejector) {
         this._rejector();
      }
      this.closePreview();
   }

   @DetectMethodChanges()
   private closePreview() {
      this._resolver = null;
      this._rejector = null;
      this.popup.visible = false;
   }

   private _resolver: (value: void | PromiseLike<void>) => void;
   private _rejector: (reason?: any) => void;

}
