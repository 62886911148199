
export class ShortOptionParametersOverviewBlock {
  
   
   constructor(
      public readonly header: string,
   ) {}
   
   backgroundColor: string;
   color: string;

   get hasSettings(): boolean {
      return false;
   }

   reset() {
   }
}