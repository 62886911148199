<dx-popover
  (onHidden)="this.onHidden()"
  [target]="this.target"
  [width]="300"
  [showTitle]="true"
  [(visible)]="this.visible"
  [position]="'left'"
  titleTemplate="title"
>
  <div *dxTemplate="let data of 'title'" style="background: black;">
    <div style="text-align: center; font-size: 1.5em; margin: 2px">
      Market Move Calculator
    </div>
  </div>
  <div *dxTemplate="let data of 'content'">
    <div class="container" #root>
      
      <div class="row call-put">
        <div class="label">
          Side
        </div>
        <div class="data">
          <div class="btn"
               [class.hot]="this.optionType === 'Call'"
               (click)="this.setOptionType('Call')"
               [class.disabled]="!this.canSelectCall"
          >
            Call
          </div>
          <div class="btn"
               [class.hot]="this.optionType == 'Put'"
               (click)="this.setOptionType('Put')"
               [class.disabled]="!this.canSelectPut"
          >
            Put
          </div>
        </div>
      </div>
      
      <div class="row strike-price">
        <div class="label">
          Strike / Curr. Px
        </div>
        <div class="data">
          {{ this.row?.strike }} @ {{ this.currentPrice | currency }}
        </div>
      </div>
      
      <div class="row expiration">
        <div class="label">
          Expiration
        </div>
        <div class="data">
          <dx-select-box
            [items]="this.availableExpirations"
            [(value)]="this.selectedExpiration"
            displayExpr="expirationFull"
            (onValueChanged)="this.onExpirationChanged()"
          >
          </dx-select-box>
        </div>
      </div>
      
      <div class="row target-price">
        <div class="label">
          Target Price
        </div>
        <div class="data">
          <dx-number-box
            (onValueChanged)="this.onTargetPriceChanged($event)"
            [inputAttr]="{'style': 'text-align: center; background: white; color: black; font-weight: bold'}"
            format="currency"
            [(value)]="this.targetPrice"
            [showClearButton]="true"
          >
          </dx-number-box>
        </div>
      </div>
      
      <div class="row result move">
        <div class="label">
          Market Move
        </div>
        <div class="data">
          {{ this.marketMove | currency:'USD':'symbol':'1.0-0' }}
        </div>
      </div>
      
      <div class="row result strike">
        <div class="label">Dest. Strike</div>
        <div class="data">
          {{ this.destinationStrike }}
<!--          {{ (this.destinationTicker ? ' @ ' : '') }} {{ (this.destinationTicker ? this.destinationPrice : undefined) | currency }}-->
        </div>
      </div>
      
    </div>
    <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: root}">
    </dx-load-panel>
  </div>
</dx-popover>