import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DetectMethodChanges, isVoid} from "../../utils";
import {PackageComparisonModel} from "../model/PackageComparisonModel";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'ets-package-name-editor',
    templateUrl: 'package-name-editor.component.html',
    styleUrls: ['package-name-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PackageNameEditorComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService
    ) {
    }


    private _resolver: (value?: (PromiseLike<void> | void)) => void;

    @Input() target: any;

    visible = false;

    name: string;

    package: PackageComparisonModel;

    validator : (x: string) => boolean;

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(cfg: any) : Promise<void> {
        this.target = cfg.target;
        this.package = cfg.package;
        this.name = cfg.package.name;
        this.validator = cfg.validator;
        this.visible = true;
        return new Promise((res) => {
            this._resolver = res;
        });
    }

    @DetectMethodChanges()
    onHidden() {
        this._resolver();
        this.name = null;
        this.visible = false;
    }

    @DetectMethodChanges()
    onSaveClicked() {
        if (!isVoid(this.name)) {
            const dupName = this.validator(this.name);
            if (dupName) {
                this._toastr.error('Duplicate names are not allowed');
                return;
            }
        }
        this.package.name = this.name;
        this.visible = false;
    }
}