// noinspection JSIgnoredPromiseFromCall

import {EventEmitter, Injectable} from "@angular/core";
import {SettingsStorageService} from "../../settings-storage-service.service";
import {UserSettingsService} from "../../user-settings.service";

const rootKey = 'visible-offsets.';
const subKeyPriceBox = 'price-box.';
const subKeyZonesGrid = 'zones-grid.';
const showOffsetsKey = 'show-offsets';
const showModeKey = 'show-mode';
const showSpreadOffsetKey = 'show-spread-offset';
const showSpreadWidthKey = 'show-spread-width';
const showSecondSpreadOffsetKey = 'show-2nd-spread-offset';
const showSecondSpreadWidthKey = 'show-2nd-spread-width';
const showProtectiveOptionOffsetKey = 'show-protective-option-offset';
const showSecondProtectiveOptionOffsetKey = 'show-2nd-protective-option-offset';
const showSingleValueKey = 'show-single-value';
const spreadOffsetFilter = 'spread-offset-filter';
const spreadWidthFilter = 'spread-width-filter';
const showImpliedVolatility = 'implied-volatility';
const showZonesGridArrow = 'zones-grid-arrow';
const favoriteAdjustments = 'favorite-adjustments';

export type VisibleOffsetsShowMode = 'Mouse Over' | 'Displayed';

type OffsetManagerType = 'PriceBox' | 'Zones Grid';

export class OffsetsManager {

    constructor(
        private readonly _userSettingsService: UserSettingsService,
        private readonly _type: OffsetManagerType
    ) {
    }

    updated$ = new EventEmitter();

    get showOffsets(): boolean {
        return this.getBoolean(showOffsetsKey);
    }

    set showOffsets(v: boolean) {
        this.setBoolean(showOffsetsKey, v);
    }

    get showMode(): VisibleOffsetsShowMode {
        return this.getString(showModeKey) || 'Mouse Over';
    }

    set showMode(v: VisibleOffsetsShowMode) {
        this.setString(showModeKey, v);
    }

    get showSingleValue(): boolean {
        return this.getBoolean(showSingleValueKey);
    }

    set showSingleValue(v: boolean) {
        this.setBoolean(showSingleValueKey, v);
    }

    get showSpreadOffset(): boolean {
        return this.getBoolean(showSpreadOffsetKey);
    }

    set showSpreadOffset(v: boolean) {
        this.setBoolean(showSpreadOffsetKey, v);
    }

    get showSpreadWidth(): boolean {
        return this.getBoolean(showSpreadWidthKey);
    }

    set showSpreadWidth(v: boolean) {
        this.setBoolean(showSpreadWidthKey, v);
    }

    get showSecondSpreadOffset(): boolean {
        return this.getBoolean(showSecondSpreadOffsetKey);
    }

    set showSecondSpreadOffset(v: boolean) {
        this.setBoolean(showSecondSpreadOffsetKey, v);
    }

    get showSecondSpreadWidth(): boolean {
        return this.getBoolean(showSecondSpreadWidthKey);
    }

    set showSecondSpreadWidth(v: boolean) {
        this.setBoolean(showSecondSpreadWidthKey, v);
    }

    get showProtectiveOptionOffset(): boolean {
        return this.getBoolean(showProtectiveOptionOffsetKey);
    }

    set showProtectiveOptionOffset(v: boolean) {
        this.setBoolean(showProtectiveOptionOffsetKey, v);
    }

    get showSecondProtectiveOptionOffset(): boolean {
        return this.getBoolean(showSecondProtectiveOptionOffsetKey);
    }

    set showSecondProtectiveOptionOffset(v: boolean) {
        this.setBoolean(showSecondProtectiveOptionOffsetKey, v);
    }

    get spreadOffsetFilter(): number {
        return this.getNumber(spreadOffsetFilter);
    }

    set spreadOffsetFilter(v: number) {
        this.setNumber(spreadOffsetFilter, v);
    }

    get spreadWidthFilter(): number {
        return this.getNumber(spreadWidthFilter);
    }

    set spreadWidthFilter(v: number) {
        this.setNumber(spreadWidthFilter, v);
    }

    get showImpliedVolatility(): boolean {
        return this.getBoolean(showImpliedVolatility);
    }

    set showImpliedVolatility(v: boolean) {
        this.setBoolean(showImpliedVolatility, v);
    }

    get showZonesGridArrow(): boolean {
        return this.getBoolean(showZonesGridArrow);
    }

    set showZonesGridArrow(v: boolean) {
        this.setBoolean(showZonesGridArrow, v);
    }

    get favoriteAdjustments() : string {
        return this.getString(favoriteAdjustments);
    }

    set favoriteAdjustments(v: string) {
        this.setString(favoriteAdjustments, v);
    }



    private getBoolean(key: string): boolean {
        const fullKey = this.getFullKey(key);
        const v = this._userSettingsService.getValue<boolean>(fullKey);
        return v || false;
    }

    private setBoolean(key: string, value: boolean) {
        const fullKey = this.getFullKey(key);
        this._userSettingsService.setValue(fullKey, value);
        this.updated$.emit();
    }

    private getString(key: string): any {
        const fullKey = this.getFullKey(key);
        const v = this._userSettingsService.getValue<string>(fullKey);
        return v;
    }

    private setString(key: string, value: any) {
        const fullKey = this.getFullKey(key);
        this._userSettingsService.setValue(fullKey, value);
        this.updated$.emit();
    }

    private getNumber(key: string): number {
        const fullKey = this.getFullKey(key);
        const v = this._userSettingsService.getValue<number>(fullKey);
        return v;
    }

    private setNumber(key: string, value: number) {
        const fullKey = this.getFullKey(key);
        this._userSettingsService.setValue(fullKey, value);
        this.updated$.emit();
    }

    private getFullKey(key: string) {
        const subKey = this._type === 'PriceBox' ? subKeyPriceBox : subKeyZonesGrid;
        const fullKey = rootKey + subKey + key;
        return fullKey;
    }

}

@Injectable()
export class VisibleOffsetsSettingsService {
    constructor(
        userSettingsService: UserSettingsService
    ) {

        this.priceBox = new OffsetsManager(userSettingsService, 'PriceBox');

        this.zonesGrid = new OffsetsManager(userSettingsService, 'Zones Grid');

        this.priceBox.updated$.subscribe(x => {
            this.priceBoxUpdated$.emit();
        });

        this.zonesGrid.updated$.subscribe(x => {
            this.zonesGridUpdated$.emit();
        });
    }

    priceBoxUpdated$ = new EventEmitter();

    zonesGridUpdated$ = new EventEmitter();

    readonly priceBox: OffsetsManager;

    readonly zonesGrid: OffsetsManager;
}
