import {CashFlowAdjustment} from "./CashFlowAdjustment";
import {isValidNumber} from "../../utils";


export class PriceboxColumn {

    constructor(
        expirationDatePrettyWithDaysToExpiration: string,
        adjustments: CashFlowAdjustment[]
    ) {

        this.expirationDatePrettyWithDaysToExpiration = expirationDatePrettyWithDaysToExpiration;

        this.expirationDatePretty = expirationDatePrettyWithDaysToExpiration.split(' ')[0];

        this.adjustments = adjustments;

        if (adjustments.length > 0) {
            this.dayOfWeek = adjustments[0].dayOfWeek;
        }
    }

    expirationDatePrettyWithDaysToExpiration: string;

    expirationDatePretty: string;

    adjustments: CashFlowAdjustment[];

    isEmpty: boolean;

    dayOfWeek: string;

    get price(): number {
        const totalPx = this.adjustments
            .map(x => ({price: (!isValidNumber(x.price) ? NaN : (x.price / x.perContract)) * x.ratio}))
            .map(x => !isValidNumber(x.price) ? NaN : x.price)
            .reduce((a, b) => a + b, 0);

        return totalPx;
    }
}
