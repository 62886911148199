import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, DxValueChanged, isVoid} from "../../../utils";
import {HgZonesGridSectionComponent} from "../hg-zones-grid-section.component";
import {HedgePositionGroup} from "../../data-model/hedge-position-group";

@Component({
    selector: 'ets-hg-zones-grid-column-selector',
    templateUrl: 'hg-zones-grid-column-selector.component.html',
    styleUrls: ['./hg-zones-grid-column-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgZonesGridColumnSelectorComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef
    ) {
    }

    cmp: HgZonesGridSectionComponent;

    visible: boolean;

    get allHedgesSelected(): boolean {
        return this.cmp.selectedHedges.length === this.cmp.hedgeList.length;
    }

    get allExpirationsSelected(): boolean {
        return this.cmp.selectedExpirations.length === this.cmp.expirationList.length;
    }


    get callHedgeList(): HedgePositionGroup[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.hedgeList.filter(x => x.side === 'Calls');
    }

    get putHedgeList(): HedgePositionGroup[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.hedgeList.filter(x => x.side === 'Puts');
    }

    get selectedCallHedges() {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.selectedCallHedges;
    }

    get selectedPutHedges() {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.selectedPutHedges;
    }

    get callExpirationList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.expirationList.filter(x => x.side === 'Calls');
    }

    get putExpirationList(): any[] {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.expirationList.filter(x => x.side === 'Puts');
    }

    get selectedCallExpirations() {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.selectedCallExpirations;
    }

    get selectedPutExpirations() {
        if (isVoid(this.cmp)) {
            return [];
        }
        return this.cmp.selectedPutExpirations;
    }

    ngOnInit() {

    }

    @DetectMethodChanges()
    show(p: HgZonesGridSectionComponent) {
        this.cmp = p;
        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }

    onHedgeSelectionChanged($event: any) {
        this.cmp.onHedgeSelectionChanged($event);
    }

    onExpirationSelectionChanged($event: any) {
        this.cmp.onExpirationSelectionChanged($event);
    }

    @DetectMethodChanges()
    toggleSelectAllHedges(event: DxValueChanged<boolean>) {
        if (isVoid(event.event)) {
            return;
        }

        if (event.value) {
            this.cmp.selectedHedges.length = 0;
            this.cmp.selectedHedges.push(...this.cmp.hedgeList);
        } else {
            this.cmp.selectedHedges.length = 0;
        }

        this.cmp.updateSelectedHedgesBySide();
    }

    toggleSelectAllExpirations(event: DxValueChanged<boolean>) {
        if (isVoid(event.event)) {
            return;
        }

        if (event.value) {
            this.cmp.selectedExpirations.length = 0;
            this.cmp.selectedExpirations.push(...this.cmp.expirationList);
        } else {
            this.cmp.selectedExpirations.length = 0;
        }

        this.cmp.updateSelectedExpirationsBySide();
    }
}