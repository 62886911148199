import {Injectable} from '@angular/core';
import {HedgePositionModel} from "./positions-section/hedge-positions/hedge-position.model";
import {findAtmStrikeIndex, getShortUUID} from "../utils";
import {OptionStrategy} from "../options-common/options.model";
import {OptionsChainService} from "../option-chains.service";
import {LastQuoteCacheService} from "../last-quote-cache.service";

export interface HgOptionStrategyConfig {
    strikeWidth: number;
    baseQty: number;
    optionType: "Calls" | "Puts";
    strikeOffset: number;
    underlying: string;
    strategy: OptionStrategy;
    color: string;
}

@Injectable()
export class HgOptionStrategyService {

    constructor() {
    }


    async makeOptionStrategy(cfg: HgOptionStrategyConfig,
                             chainService: OptionsChainService,
                             lastQuoteCache: LastQuoteCacheService
    ): Promise<HedgePositionModel[]> {

        const strategy: OptionStrategy = cfg.strategy;
        const underlying: string = cfg.underlying;
        const strikeOffset: number = cfg.strikeOffset;
        const optionType: 'Calls' | 'Puts' = cfg.optionType;
        const baseQty: number = cfg.baseQty;
        const strikeWidth = cfg.strikeWidth;
        const color = cfg.color;

        const chain = await chainService.getChain(underlying);
        const nearestExp = chain.expirations[0];
        const lastQuote = await lastQuoteCache.getLastQuoteWithAwait(underlying);
        const centerIx = findAtmStrikeIndex(nearestExp.strikes, lastQuote);
        const expirationList = chain.expirations;

        function pickStrike(distance: number) {

            if (distance === 0) {
                return nearestExp.strikes[centerIx];
            }

            const centerStrike = nearestExp.strikes[centerIx];

            let targetStrike = centerStrike + distance;

            return targetStrike;
        }

        const legs: HedgePositionModel[] = [];

        if (strategy === 'Butterfly') {

            const offset = strikeOffset || 0;

            const atmStrike = nearestExp.strikes[centerIx];

            const oType = optionType !== 'Puts'
                ? 'Call'
                : 'Put';

            const multiplier = oType === 'Put' ? -1 : 1;

            const groupId = getShortUUID();

            const label = oType + ' Butterfly';

            const leg1 = new HedgePositionModel();
            leg1.underlying = underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = oType;
            leg1.label =  label
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = underlying
            leg2.qty = -2 * baseQty;
            leg2.side = 'Sell';
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + strikeWidth * multiplier;
            leg2.type = oType;
            leg2.label =  label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = underlying
            leg3.side = 'Buy';
            leg3.qty = 1 * baseQty;
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + strikeWidth * multiplier;
            leg3.type = oType;
            leg3.label =  label;
            leg3.groupId = groupId;

            legs.push(leg1, leg2, leg3);

            if (oType === 'Call') {
                legs.reverse();
            }
        }
        else if (strategy === 'Condor') {

            const offset = strikeOffset || 0;
            const width = strikeWidth;

            const oType = optionType !== 'Puts' ? 'Call' : 'Put';

            const multiplier = optionType === 'Calls' ? 1 : -1;

            const strikeAtm = nearestExp.strikes[centerIx];
            const firstStrike = strikeAtm + offset * multiplier;


            const label =  oType + ' Condor';
            const groupId = getShortUUID();


            const leg1 = new HedgePositionModel();
            leg1.underlying = underlying;
            leg1.side = 'Buy';
            leg1.qty = 1 * baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = firstStrike;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;


            const leg2 = new HedgePositionModel();
            leg2.underlying = underlying;
            leg2.side = 'Buy';
            leg2.qty = 1 * baseQty;
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = underlying;
            leg3.side = 'Buy';
            leg3.qty = 1 * baseQty;
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + width * 2 * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;

            const leg4 = new HedgePositionModel();
            leg4.underlying = underlying;
            leg4.side = 'Buy';
            leg4.qty = 1 * baseQty;
            leg4.expirationDescriptor = nearestExp;
            leg4.expirationList = expirationList;
            leg4.strike = leg3.strike + width * multiplier;
            leg4.type = oType;
            leg4.label = label;
            leg4.groupId = groupId;

            legs.push(leg1, leg2, leg3, leg4);

            if (optionType === 'Calls') {
                legs.reverse();
            }

        }
        else if (strategy === 'Option - Long') {

            const strike = optionType === 'Calls'
                ? pickStrike(strikeOffset || 0)
                : pickStrike(-strikeOffset || 0)

            const oType = optionType !== 'Puts' ? 'Call' : 'Put';

            const leg1 = new HedgePositionModel();
            leg1.underlying = underlying;
            leg1.side = 'Buy';
            leg1.qty = 1 * baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = strike;
            leg1.type = oType;
            leg1.label = 'Long ' + oType;
            leg1.groupId = getShortUUID();

            legs.push(leg1);

        }
        else if (strategy === 'Option - Short') {
            const strike = optionType === 'Calls'
                ? pickStrike(strikeOffset)
                : pickStrike(-strikeOffset || 0);

            const oType = optionType !== 'Puts' ? 'Call' : 'Put';

            const leg1 = new HedgePositionModel();
            leg1.underlying = underlying;
            leg1.side = 'Sell';
            leg1.qty = -1 * baseQty;
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = strike;
            leg1.type = oType;
            leg1.label = 'Short ' + oType;
            leg1.groupId = getShortUUID();

            legs.push(leg1);

        }
        else if (strategy === 'Vertical') {

            const atmStrike = nearestExp.strikes[centerIx];
            const offset = strikeOffset || 0;

            const oType = optionType !== 'Puts' ? 'Call' : 'Put';
            const multiplier = optionType === 'Puts' ? -1 : 1;

            const label = oType + ' Vertical';

            const groupId = getShortUUID();

            const leg1 = new HedgePositionModel();
            leg1.underlying = underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = underlying;
            leg2.side = 'Sell';
            leg2.qty = -1 * baseQty;
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + strikeWidth * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;

            legs.push(leg2, leg1);

            if (oType === 'Put') {
                legs.reverse();
            }
        }
        else if (strategy === 'Slingshot') {

            let offset = strikeOffset || 0;

            const atmStrike = nearestExp.strikes[centerIx];

            const oType = optionType !== 'Puts'
                ? 'Call'
                : 'Put';

            const multiplier = oType === 'Put' ? -1 : 1;

            const label = oType === 'Call' ? 'Call Slingshot' : 'Put Slingshot';
            const groupId = getShortUUID();

            const leg1 = new HedgePositionModel();
            leg1.underlying = underlying
            leg1.qty = 1 * baseQty;
            leg1.side = 'Buy';
            leg1.expirationDescriptor = nearestExp;
            leg1.expirationList = expirationList;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.type = oType;
            leg1.label = label;
            leg1.groupId = groupId;

            const leg2 = new HedgePositionModel();
            leg2.underlying = underlying
            leg2.qty = -2 * baseQty;
            leg2.side = 'Sell';
            leg2.expirationDescriptor = nearestExp;
            leg2.expirationList = expirationList;
            leg2.strike = leg1.strike + strikeWidth * multiplier;
            leg2.type = oType;
            leg2.label = label;
            leg2.groupId = groupId;

            const leg3 = new HedgePositionModel();
            leg3.underlying = underlying
            leg3.qty = 2 * baseQty;
            leg3.side = 'Buy';
            leg3.expirationDescriptor = nearestExp;
            leg3.expirationList = expirationList;
            leg3.strike = leg2.strike + strikeWidth * multiplier;
            leg3.type = oType;
            leg3.label = label;
            leg3.groupId = groupId;

            legs.push(leg1, leg2, leg3);

            if (oType === 'Call') {
                legs.reverse();
            }

        }
        else {
            console.error('Unknown strategy');
            return undefined;
        }

        legs.forEach(l => l.color = color);

        return legs;
    }
}