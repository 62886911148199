import * as Enumerable from "linq";
import { PriceboxColumn } from "./PriceboxColumn";
import { AdjustmentsGroup } from "./AdjustmentsGroup";

const AdvancedCouples = [
   '3+1', 
   '3+2a',
   '3+2b',
   '3+2c',
   '3+2d',

   '1+3',
   '2a+3',
   '2b+3',
   '2c+3',
   '2d+3'
];

export class PriceboxRow {

   constructor(
      group: AdjustmentsGroup
   ) {

      Enumerable.from(group.adjustments)
         .groupBy(x => x.cashFlowStrategy)
         .forEach((grp, ix) => {
            
            const randomAdj = grp.firstOrDefault();
            const adjType = randomAdj.adjustmentType;
            const strategy = randomAdj.cashFlowStrategy;

            if (ix == 0) {
               this.adjustmentType = adjType;
               this.cashFlowStrategy = strategy;
            } else {
               this.adjustmentType2 = adjType;
               this.cashFlowStrategy2 = strategy;
               this.isAdvanced = this.isAdvancedRow(randomAdj.coupleTag);
            }
         });

      const columns = Enumerable
         .from(group.adjustments)
         .groupBy(x => x.expirationDatePrettyWithDaysToExpiration)
         .select(x => {
            const obj = new PriceboxColumn(
               x.key(),
               x.toArray()
            );
            return obj;
         }).toArray();

      this.columns = columns;
      
      this.columns.forEach(ce => {
         ce.isEmpty = ce.adjustments.some(x => x.isEmpty);
      });
   }

   isEmpty: boolean;
   isAdvanced: boolean;
   adjustmentType: string;
   adjustmentType2: string;
   cashFlowStrategy: string;
   cashFlowStrategy2: string;
   columns: PriceboxColumn[];

   private isAdvancedRow(coupleTag: string): boolean {
      return AdvancedCouples.indexOf(coupleTag) >= 0;  
   }
}
