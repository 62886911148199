import { Component, OnInit } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, Promise, IFilterParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { isNullOrUndefined } from 'util';
import { DateTime } from 'luxon';
import { TimestampsService } from '../timestamps.service';

@Component({
   selector: 'ets-date-grid-filter',
   template: `
    <input
          type="radio"
          name="year"
          [value]="'All'"
/>
   `
})

export class EtsDateGridFilterComponent implements IFilterAngularComp {


   private _model: { dateTo?: string, dateFrom?: string; timezone?: string; };
   private _initParams: IFilterParams;

   isFilterActive(): boolean {
      return !isNullOrUndefined(this._model);
   }

   doesFilterPass(params: IDoesFilterPassParams): boolean {
      
      if (!this._model) {
         return true;
      }
      
      const ts: Date = params.data.timestamp;

      if (!(ts instanceof Date)) {
         return true;
      }

      let doesPass = false;

      const timezone = this._model.timezone;

      if (this._model.dateFrom && this._model.dateTo) {

         const dateFrom = DateTime.fromISO(this._model.dateFrom, { zone: timezone });
         const dateTo = DateTime.fromISO(this._model.dateTo, { zone: timezone });
         const timestampInZone = DateTime.fromJSDate(ts, { zone: timezone });

         doesPass = timestampInZone >= dateFrom && timestampInZone <= dateTo;

      } else if (this._model.dateFrom) {
         
         const dateFrom = DateTime.fromISO(this._model.dateFrom, { zone: timezone });
         const timestampInZone = DateTime.fromJSDate(ts, { zone: timezone });
         doesPass = timestampInZone >= dateFrom;

      } else if (this._model.dateTo) {

         const dateTo = DateTime.fromISO(this._model.dateTo, { zone: timezone });
         const timestampInZone = DateTime.fromJSDate(ts, { zone: timezone });
         doesPass = timestampInZone <= dateTo;

      }

      return doesPass;

   }
   
   getModel() {
      return this._model;
   }

   setModel(model: any): void | Promise<void> {
      this._model = model;
   }
   
   agInit(params: IFilterParams): void {
      this._initParams = params;   
   }   

   updateFilter() {

   }
}
