import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    findHCF,
    getBucketRoleClass,
    getChangingPositionsForZeroAdjustmentCase,
    getPriceClass,
    getQtyClass,
    isValidNumber,
    isVoid
} from 'projects/shared-components/utils';
import {isNullOrUndefined} from 'util';
import {SolutionPositionDto} from '../../model/SolutionPositionDto';
import {CashFlowAdjustment} from '../../model/CashFlowAdjustment';
import {CashFlowStrategyRole} from "../../../shell-communication/shell-dto-protocol";
import {AdjustmentPricingGridComponent} from "../../adjustment-pricing-grid.component";
import {AdjustmentSolutionPopupComponent} from "../../solution-popup/adjustment-solution-popup.component";
import {AdjustmentPricingSettingsDto} from "../../../shell-communication/shell-operations-protocol";

@Component({
    selector: 'ets-adjustment-grid-positions-state',
    templateUrl: './adjustment-grid-positions-state.component.html',
    styleUrls: [
        './adjustment-grid-positions-state.component.scss',
        '../../adjustment-grid-common-style.scss'
    ]
})
export class AdjustmentGridPositionsStateComponent implements OnInit, OnDestroy {

    constructor(private _changeDetector: ChangeDetectorRef) {
    }

    private _positions: SolutionPositionDto[];
    get positions(): SolutionPositionDto[] {
        return this._positions;
    }

    @Input()
    set positions(value: SolutionPositionDto[]) {
        this._positions = value;
    }

    @Input()
    forTotals: SolutionPositionDto[];

    @Input()
    marketDelta: number;

    @Input()
    header: string;

    @Input()
    adjustmentPricing: CashFlowAdjustment;

    @Input()
    readOnly = false;

    @Input()
    qtyMultiplier = 1;

    @Input()
    isPriceToOpen: boolean;

    @Input() root: AdjustmentSolutionPopupComponent;

    get extraPositions(): SolutionPositionDto[] {
        return this.adjustmentPricing.extraPositions;
    }

    get extraType(): 'increase' | 'decrease' {
        return this.adjustmentPricing.extraType;
    }

    hcf = 1;

    extraPositionsHcf = 1;

    rollQty: number;

    changeQty: number;

    get hasExtras(): boolean {
        if (this.header === 'BEFORE') {
            return !isVoid(this.extraPositions) && this.extraType === 'decrease';
        }

        if (this.header === 'AFTER') {
            return !isVoid(this.extraPositions) && this.extraType === 'increase';
        }
    }

    get showExtraTotals(): boolean {
        return this.header === 'AFTER' && !isVoid(this.extraPositions);
    }

    get showAssetTotal(): boolean {
        return (this.showSubTotals && this.showMarketDelta)
    }

    get showMarketDelta(): boolean {
        return !this.readOnly && !isNullOrUndefined(this.marketDelta);
    }

    get showSubTotals(): boolean {
        return !this.readOnly && !isNullOrUndefined(this.forTotals);
    }

    get assetName(): string {
        if (isVoid(this.positions)) {
            return 'N/A';
        }
        return this.positions[0].underlying;
    }

    get showGrandTotalInReadOnlyMode(): boolean {
        return this.readOnly && !isNullOrUndefined(this.forTotals);
    }

    ngOnInit(): void {

        this.hcf = findHCF(this.positions.map(x => Math.abs(x.qty)));

        if (!isVoid(this.extraPositions)) {
            this.extraPositionsHcf = findHCF(this.extraPositions.map(x => Math.abs(x.qty)));
        }

        if (!this.isPriceToOpen) {

            if (!isVoid(this.extraPositions) && this.showExtraTotals) {

                const afterPositions = this.extraType === 'increase'
                    ? this.positions
                    : this.forTotals;

                const so = afterPositions.find(x => x.role === 'ShortOption');
                const so2 = this.extraPositions.find(x => x.role === 'ShortOption');

                const rollQty = Math.abs(so.qty) - Math.abs(so2.qty);
                this.rollQty = rollQty;

                const changeQty = Math.abs(so2.qty);
                this.changeQty = changeQty;
            }
        }
    }

    ngOnDestroy(): void {
    }

    getPositionRoleClass(pos: SolutionPositionDto): string {
        return getBucketRoleClass(pos);
    }

    getQtyClass(position: SolutionPositionDto): any {
        let qtyClass = getQtyClass(position);
        if (this.readOnly) {
            if (!isNullOrUndefined(this.forTotals)) {
                if (Array.isArray(qtyClass)) {
                    qtyClass.push('readonly', 'after');
                } else {
                    qtyClass = [qtyClass, 'readonly', 'after'];
                }
            }
        }

        return qtyClass;
    }

    getPriceClass(item: Partial<{ price: number }>) {
        return getPriceClass(item);
    }

    getAssetPriceClass() {
        return getPriceClass({price: this.marketDelta});
    }

    getCssClassForTotalPrice(type: 'row' | 'col' | 'rowcol' | 'grand' | 'extra-row' | 'extra-col' | 'extra-rowcol' | 'extra-grand', ix?: number, position?: SolutionPositionDto) {

        if (type === 'row') {

            const total = this.getRowTotalPrice(ix, position);
            return getPriceClass({price: total});

        } else if (type === 'col') {

            const total = this.getColumnTotalPrice();
            return getPriceClass({price: total});

        } else if (type === 'rowcol') {

            const total = this.getRowColTotalPrice();
            return getPriceClass({price: total});

        } else if (type === 'grand') {

            const total = this.adjustmentPricing.logicalId === '#0'
                ? this.getGrandTotalForZeroAdjustmentCase()
                : this.getGrandTotalPrice();

            return getPriceClass({price: total});

        } else if (type === 'extra-row') {
            const total = this.getExtraRowTotalPrice(ix);
            return getPriceClass({price: total});
        } else if (type === 'extra-col') {
            const total = this.getExtraColumnTotalPrice();
            return getPriceClass({price: total});
        } else if (type === 'extra-rowcol') {
            const total = this.getExtraRowColTotalPrice();
            return getPriceClass({price: total});
        } else if (type === 'extra-grand') {
            const total = this.getExtraTotalPrice();
            return getPriceClass({price: total});
        }
    }

    getTotalPrice(type: "row" | "col" | "rowcol" | "grand" | "extra-col" | "extra-rowcol", ix?: number, position?: SolutionPositionDto) {
        switch (type) {
            case "extra-col":
                return this.getExtraColumnTotalPrice();
            case "extra-rowcol":
                return this.getExtraRowColTotalPrice();
            case 'row':
                return this.getRowTotalPrice(ix, position);
            case 'col':
                return this.getColumnTotalPrice();
            case 'rowcol':
                return this.getRowColTotalPrice();
            case 'grand':
                return this.getGrandTotalPrice();
        }
    }

    private getRowTotalPrice(ix: number, position: SolutionPositionDto): number {

        if (this.positions.length <= ix) {
            return undefined;
        }

        if (this.adjustmentPricing.logicalId === '#0') {
            const changingPositions = getChangingPositionsForZeroAdjustmentCase(this.forTotals, this.positions);
            const hcf = findHCF(changingPositions.map(x => x.qty));
            let total = undefined;
            if (!isVoid(position)) {
                const rolePositions = changingPositions.filter(x => x.role === position.role);
                total = rolePositions.map(x => {
                    const m = Math.sign(x.qty);
                    const px = (x.qty / hcf) * (x.price * m);
                    return px;
                }).reduce((p, c) => p + c, 0);
            }
            return total;
        }

        const afterPositions = this.getBaseForPricing(this.positions);
        const beforePositions = this.getBaseForPricing(this.forTotals);

        const targetLegRole = this.positions[ix].role;

        let beforePosition: { price: number, qty: number, ticker: string } =
            beforePositions.find(x => x.role === targetLegRole);

        let afterPosition: { price: number, qty: number, ticker: string } =
            afterPositions.find(x => x.role === targetLegRole);

        const isVoidBefore = isVoid(beforePosition) || isVoid(beforePosition.ticker);
        const isVoidAfter = isVoid(afterPosition) || isVoid(afterPosition.ticker);

        if (isVoidBefore && isVoidAfter) {
            return undefined;
        }

        const beforeHcf = findHCF(beforePositions.map(x => x.qty)) || 1;

        const afterHcf = findHCF(afterPositions.map(x => x.qty));

        if (isVoidBefore) {
            const res = (afterPosition.qty / afterHcf) * afterPosition.price
            return res;
        }

        if (isVoidAfter) {
            const res = (beforePosition.qty / beforeHcf) * beforePosition.price
            return res;
        }

        // noinspection JSObjectNullOrUndefined
        if (beforePosition.ticker === afterPosition.ticker) {
            if (beforePosition.qty === afterPosition.qty) {
                return undefined;
            }
        }

        const beforePositionQty = beforePosition.qty;
        const afterPositionQty = afterPosition.qty;

        // noinspection JSObjectNullOrUndefined
        const beforePositionPx = isNullOrUndefined(beforePosition)
            ? undefined
            : beforePosition.price * beforePositionQty;

        // noinspection JSObjectNullOrUndefined
        const afterPositionPx = isNullOrUndefined(afterPosition)
            ? undefined
            : afterPosition.price * afterPositionQty;

        let totalPx = (beforePositionPx / beforeHcf) + (afterPositionPx / afterHcf);

        return totalPx;
    }

    private getColumnTotalPrice() {

        const positions = this.getBaseForPricing(this.positions);

        const hcf = findHCF(positions.map(x => x.qty));

        const grandTotal = positions
            .map(x => x.price * x.qty / hcf)
            .reduce((p, c) => p + c, 0);

        return grandTotal;
    }

    private getExtraColumnTotalPrice() {
        const positions = this.getBaseForPricing(this.extraPositions);

        const grandTotal = positions
            .map(x => x.price * x.qty / this.extraPositionsHcf)
            .reduce((p, c) => p + c, 0);

        return grandTotal;
    }

    private getRowColTotalPrice() {

        const positions = this.positions;

        const grandGrandTotal = positions
            .map((pos, i) => this.getRowTotalPrice(i, pos) || 0)
            .reduce((p, c) => p + c, 0);

        return grandGrandTotal;

    }

    private getExtraRowColTotalPrice() {

        const positions = this.extraPositions;

        const grandGrandTotal = positions
            .map((_, i) => this.getExtraRowTotalPrice(i) || 0)
            .reduce((p, c) => p + c, 0);

        return grandGrandTotal;

    }

    private getGrandTotalPrice() {

        if (this.adjustmentPricing) {
            if (this.adjustmentPricing.logicalId === '#0') {
                return this.getGrandTotalForZeroAdjustmentCase();
            }
        }

        if (this.readOnly) {

            if (isVoid(this.adjustmentPricing)) {
                return null;
            }

            return this.adjustmentPricing.price;
        }

        let rowColTotal = this.getRowColTotalPrice();

        if (!isVoid(this.extraPositions)) {
            rowColTotal *= this.rollQty;
        }

        let extra = this.getExtraTotalPrice();

        if (!isVoid(this.extraPositions)) {
            extra *= this.changeQty;
        }

        let grandTotal = rowColTotal + (extra || 0) + (this.marketDelta || 0);

        if (isValidNumber(extra)) {
            grandTotal /= (this.rollQty + this.changeQty);
        }

        return grandTotal;
    }

    getExtraTotalPrice(): number {

        const positions = this.extraPositions;

        if (isVoid(positions)) {
            return null;
        }

        const extraTotal = positions
            .map(x => (x.price * Math.abs(x.qty) / this.extraPositionsHcf))
            .reduce((p, c) => p + c, 0);

        return extraTotal;
    }

    private getBaseForPricing(positions: SolutionPositionDto[])
        : { ticker: string, price: number, qty: number, role: CashFlowStrategyRole }[] {

        if (isVoid(positions)) {
            return [];
        }

        const mapped = positions.map(x => ({
            ticker: x.ticker,
            price: x.price,
            qty: Math.abs(x.qty),
            role: x.role
        })).filter(x => !isVoid(x.ticker));

        return mapped;
    }

    protected readonly isValidNumber = isValidNumber;

    protected readonly Math = Math;

    private getExtraRowTotalPrice(ix: number): number {
        if (this.extraPositions.length < ix) {
            return undefined;
        }

        const position = this.extraPositions[ix];

        return position.price * position.qty / this.extraPositionsHcf;
    }

    private getGrandTotalForZeroAdjustmentCase() {

        const changingPositions = getChangingPositionsForZeroAdjustmentCase(this.forTotals, this.positions);

        const hcf = findHCF(changingPositions.map(x => x.qty));

        const total = changingPositions.map(x => {
            const m = Math.sign(x.qty);
            const px = (x.qty / hcf) * (x.price * m);
            return px;
        }).reduce((p, c) => p + c, 0);

        return total;
    }

    isTheoPrice(position: SolutionPositionDto) {
        if (isVoid(position)) {
            return false;
        }

        let lastUsedSettings : AdjustmentPricingSettingsDto[];

        if (this.root?.source === 'PriceBox') {
            lastUsedSettings = this.root?.root?.priceboxSection.lastUsedSettings;
        } else if (this.root?.source === 'Zones Grid') {
            lastUsedSettings = this.root?.root?.zonesGridSection.lastUsedSettings;
        }

        if (isVoid(lastUsedSettings)) {
            if (this.root?.root?.settingsSection?.globalSettings?.theoreticalPriceMode !== 'Fill Blanks') {
                return false;
            }
        } else {
            if (lastUsedSettings[0].theoreticalPriceMode !== 'Fill Blanks') {
                return false;
            }
        }

        return position.ticker.indexOf(' !') > 0;
    }
}
