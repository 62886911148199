import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ResourceButton} from "../../resource-button.interface";
import {DetectSetterChanges, DxValueChanged, isVoid} from "../../../utils";
import {ResourceEditorComponent} from "../../resource-editor.component";

@Component({
    selector: 'ets-extra-po-condition-resource',
    template: `
      <div>
        <p class="label">
          Has Extra PO Qty
        </p>
        <div class="input cb">
          <dx-check-box 
            [(value)]="this.extraPos"
            (onValueChanged)="this.onValueChanged($event)"
            [disabled]="this.disabled"
          >
          </dx-check-box>
        </div>
      </div>
    `,
    styles: [
        '.input {text-align: center}'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExtraPoConditionResourceComponent implements OnInit, AfterViewInit {
    constructor(
        private _changeDetector: ChangeDetectorRef
    ) {
    }

    extraPos = false;

    @Input() comp: ResourceEditorComponent;

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._changeDetector.detach();
    }

    get disabled(): boolean {
        return isVoid(this.resource);
    }

    private _resource: ResourceButton;
    get resource(): ResourceButton {
        return this._resource;
    }

    @Input()
    @DetectSetterChanges()
    set resource(value: ResourceButton) {
        this.extraPos = false;

        this._resource = value;

        if (isVoid(this._resource)) {
            return;
        }

        this.extraPos = this._resource.conditionHasExtraPos || false;
    }

    async onValueChanged($event: DxValueChanged<boolean>) {
        const resource = this.resource;
        if (isVoid(resource)) {
            return;
        }
        if (isVoid($event.event)) {
            return;
        }
        resource.conditionHasExtraPos = this.extraPos;
        resource.hasChanges = true;
        await this.comp.onChange({});
    }
}