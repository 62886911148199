import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { CreateAdjustmentStrategy, UpdateAdjustmentStrategy } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged } from 'projects/shared-components/utils';
import { PutDebitSpreadRollStrategyParameters } from '../adjustment-strategies/put-debit-spread-roll/put-debit-spread-roll-strategy-parameters';
import { SessionService } from '../authentication/session-service.service';
import { OrderLeg } from '../multi-trade-pad/multi-leg-order/order-leg.class';
import { ActionType } from '../multi-trade-pad/multi-trade-pad.model';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { MarketSide } from '../trading-model/market-side.enum';
import { AttachLeg, AttachmentStrategy, AttachStrategyDialogConfig, AAStrategyValidationContext, OrderLegWrapper } from './adjustment-strategy-dialog.model';
import { LongOptionParameters } from './strategy-parameters/long-option/long-option-parameters';
import { PutSpreadParameters } from './strategy-parameters/put-spread/put-spread-parameters';
import { ShortOptionParameters } from './strategy-parameters/short-option/short-option-parameters';

@Component({
   selector: 'ets-adjustment-strategy-dialog',
   templateUrl: 'adjustment-strategy-dialog.component.html',
   styleUrls: ['adjustment-strategy-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdjustmentStrategyDialogComponent implements OnInit {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _sessionService: SessionService,
      private readonly _portfolioService: PortfoliosService
   ) {
      this.putSpreadParameters = new PutSpreadParameters(this._changeDetector);
      this.longOptionParameters = new LongOptionParameters(this._changeDetector);
      this.shortOptionParameters = new ShortOptionParameters(this._changeDetector);
      this.putDebitSpreadRollParameters = new PutDebitSpreadRollStrategyParameters(this._changeDetector, null);

      this.shortOptionParameters.changed$.subscribe( x => {
         this.hasChanges = true;
         this._changeDetector.detectChanges();
      });
   }

   private _config: AttachStrategyDialogConfig;

   //

   @Input() legs: OrderLeg[] = [];

   //

   @Output() attchmentCreated: EventEmitter<CreateAdjustmentStrategy> = new EventEmitter();

   //
   hasChanges: boolean;

   //

   get availableLegs(): OrderLegWrapper[] {
      const legs = [];

      if (this.selectedAttachment.length === 0) {
         return legs;
      }

      const attachment = this.selectedAttachment[0];

      if (attachment.id === EtsConstants.algorithms.adjustment.putSpreadAlgoId) {

         return this.putSpreadParameters.filterSuitableLegs(this.legs);

      } else if (attachment.id === EtsConstants.algorithms.adjustment.longOptionAlgoId) {

         return this.longOptionParameters.filterSuitableLegs(this.legs);

      } else if (attachment.id === EtsConstants.algorithms.adjustment.shortOptionAlgoId) {

         return this.shortOptionParameters.filterSuitableLegs(this.legs);

      }

      return legs;

   }

   //

   get isDetachButtonAvailable(): boolean {

      if (!this.config) {
         return false;
      }

      return this.config.ctx === 'attach' && this.config.mode === 'update';
   }

   //

   private _isLoading: boolean;
   public get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   public set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //

   private _isVisible: boolean;

   get isVisible(): boolean {
      return this._isVisible;
   }

   @DetectSetterChanges()
   set isVisible(v: boolean) {
      this._isVisible = v;
   }

   //

   get config(): AttachStrategyDialogConfig {
      return this._config;
   }

   //

   attachmentsList: AttachmentStrategy[] = [
      {
         name: 'Put Spread',
         id: '2b77bb62-42d1-4bb8-8d85-d84b8c636748'
      },
      {
         name: 'Long Option',
         id: '66e80323-139f-490b-a4a7-2d2a4da7dcf0'
      },
      {
         name: 'Short Option',
         id: '7158e24e-19bc-4228-a708-a6343054e31f'
      },
      {
         name: 'Put Debit Spread Roll',
         id: '1ba31a08-2619-48bc-9192-38fa2b81aafa'
      },
   ];

   //

   private _selectedAttachment: AttachmentStrategy[] = [];
   get selectedAttachment(): AttachmentStrategy[] {
      return this._selectedAttachment;
   }

   @DetectSetterChanges()
   set selectedAttachment(v: AttachmentStrategy[]) {
      this._selectedAttachment = v;
   }

   //

   putSpreadParameters: PutSpreadParameters;

   //

   longOptionParameters: LongOptionParameters;

   //

   shortOptionParameters: ShortOptionParameters;

   //

   putDebitSpreadRollParameters: PutDebitSpreadRollStrategyParameters;

   //

   onSelectedAttachmentChange(v: AttachmentStrategy[]) {

      if (this.config) {
         if (this.config.mode === 'update') {
            return;
         }
      }

      if (this.selectedAttachment === v) {
         return;
      }

      this.selectedAttachment = v;

      if (this.selectedAttachment.length > 0) {

         if (this.selectedAttachment[0].name === 'Put Spread') {
            this.putSpreadParameters.reset();
         }

         if (this.selectedAttachment[0].name === 'Long Option') {
            this.longOptionParameters.reset();
         }

         if (this.selectedAttachment[0].name === 'Short Option') {
            this.shortOptionParameters.reset();
            this.shortOptionParameters.setAttachmentConifg(this._config);
         }

         if (this.selectedAttachment[0].name === 'Put Debit Spread Roll') {
            this.putDebitSpreadRollParameters.reset();
         }
      }
   }

   //

   async ngOnInit() {
      await this.shortOptionParameters.init();
   }

   //

   async show(config: AttachStrategyDialogConfig) {

      if (!config) {
         return;
      }

      this._config = config;

      if (this.config.mode === 'update') {
         if (this.config.strategy) {
            this.editStrategy(this.config.strategy);
         }
      }

      this.isVisible = true;
   }

   //

   @DetectMethodChanges()
   onHidden() {
      this._config = null;
      this.isVisible = false;
      this.hasChanges = false;
      this.putSpreadParameters.reset();
      this.longOptionParameters.reset();
      this.shortOptionParameters.reset();
      this.selectedAttachment = [];
   }

   //

   @DetectMethodChanges({isAsync: true})
   async onOkButtonClicked(): Promise<void> {

      if (!this.config) {
         return;
      }

      let parameters;
      let displayName;

      const validationContext = this.getVAlidationContext();

      if (this.selectedAttachment[0].name === 'Put Spread') {

         const errors = this.putSpreadParameters.validate(validationContext);

         if (errors.length > 0) {
            errors.forEach(x => this._toastr.error(x, 'Put Spread'));
            return;
         }

         parameters = this.putSpreadParameters.getParameters();
         displayName = this.putSpreadParameters.displayName;

      } else if (this.selectedAttachment[0].name === 'Long Option') {

         const errors = this.longOptionParameters.validate(validationContext);

         if (errors.length > 0) {
            errors.forEach(x => this._toastr.error(x, 'Long Option'));
            return;
         }

         parameters = this.longOptionParameters.getParameters();
         displayName = this.longOptionParameters.displayName;

      } else if (this.selectedAttachment[0].name === 'Short Option') {

         const errors = this.shortOptionParameters.validate(validationContext);

         if (errors.length > 0) {
            errors.forEach(x => this._toastr.error(x, 'Short Option'));
            return;
         }

         parameters = this.shortOptionParameters.getParameters();
         displayName = this.shortOptionParameters.displayName;

      } else if (this.selectedAttachment[0].name === 'Put Debit Spread Roll') {

         const errors = this.putDebitSpreadRollParameters.validate(validationContext);

         if (errors.length > 0) {
            errors.forEach(x => this._toastr.error(x, 'Put Debit Spread Roll'));
            return;
         }

         parameters = this.putDebitSpreadRollParameters.getParameters();
         displayName = this.putDebitSpreadRollParameters.displayName;
      }


      if (this.config.mode === 'create' || this.config.ctx === 'attach') {
         const cmd = new CreateAdjustmentStrategy(
            this.selectedAttachment[0].id,
            displayName,
            this._config.item ? this._config.item.portfolioItemId : null,
            parameters
         );

         if (this._config.ctx === 'attach') {
            const legToAttach = this.legs.find(l => l.attachTo);
            if (legToAttach) {
               cmd.legToAttachTo = legToAttach.ticker;
            }
            this.attchmentCreated.emit(cmd);
            this.onHidden();

         } else {

            try {
               this.isLoading = true;
               await this._shellClient.processCommand(cmd);
               this.onHidden();
            } catch (e) {
               console.error(e);
            } finally {
               this.isLoading = false;
            }
         }
      } else {

         const cmd = new UpdateAdjustmentStrategy(
            this._config.strategy.strategyId,
            displayName,
            parameters
         );

         try {
            this.isLoading = true;
            await this._shellClient.processCommand(cmd);
            this.onHidden();
         } catch (e) {
            console.error(e);
         } finally {
            this.isLoading = false;
         }
      }

   }

   private getVAlidationContext(): AAStrategyValidationContext {
      let legToAttachTo: AttachLeg;

      if (this._config.item) {
         legToAttachTo = {
            side: Math.sign(this._config.item.netPosition) as MarketSide,
            ticker: this._config.item.ticker
         };
      } else {
         const leg = this.legs.find(x => x.attachTo);
         if (leg) {
            legToAttachTo = {
               side: (leg.action as number) as MarketSide,
               ticker: leg.ticker
            };
         }
      }

      const isDirectUpdate = this._config.mode === 'update' && this._config.ctx === 'direct';
      if (!legToAttachTo) {
         if (!isDirectUpdate) {
            this._toastr.error('Cannot determine leg to attach to');
            return;
         }
      }

      const validationContext: AAStrategyValidationContext = {
         legToAttachTo,
         legRequired: !isDirectUpdate,
         ctx: this._config.ctx,
         mode: this._config.mode
      };

      return validationContext;
   }

   //

   @DetectMethodChanges()
   onCancelButtonClicked() {
      this.legs.forEach(x => x.attachTo = false);
      this.onHidden();
   }

   //

   private editStrategy(strategy: StrategyModel) {

      const selectedAttachment = this.attachmentsList.find(x => x.id === strategy.algoId);

      if (!selectedAttachment) {
         return;
      }

      if (selectedAttachment.id === EtsConstants.algorithms.adjustment.putSpreadAlgoId) {
         this.putSpreadParameters.setParameters(strategy);
      } else if (selectedAttachment.id === EtsConstants.algorithms.adjustment.longOptionAlgoId) {
         this.longOptionParameters.setParameters(strategy);
      } else if (selectedAttachment.id === EtsConstants.algorithms.adjustment.shortOptionAlgoId) {
         this.shortOptionParameters.setParameters(strategy);
      } else if (selectedAttachment.id === EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId) {
         this.putDebitSpreadRollParameters.setParameters(strategy);
      }


      this._selectedAttachment = [selectedAttachment];
   }

   @DetectMethodChanges()
   onDetachButtonClicked() {

      if (!this.config) {
         return;
      }

      if (this.config.clearAttachmentCallback) {
         this.config.clearAttachmentCallback();
      }

      this.onHidden();
   }

   @DetectMethodChanges()
   onAttachLegChanged(legIx: number, ev: DxValueChanged<boolean>) {
      if (!ev.event) {
         return;
      }

      if (ev.value) {
         this.availableLegs.filter( (val, ix) => ix !== legIx ).forEach(x => x.leg.attachTo = false);
      } else {
         this.availableLegs.forEach(x => x.leg.attachTo = false);
      }
   }
}
