import { ProtocolCommand } from '../../../service-model/protocol-command.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';

export class AddManualPosition implements ProtocolCommand {
   constructor(
      public readonly ticker: string,
      public readonly terminalId: string,
      public readonly accountId: string,
      public readonly netPosition: number,
      public readonly avgPx: number,
      public readonly portfolioId: string,
      public readonly comboId: string,
      public readonly comboGroupId: string
   ) {
   }

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.AddManualPosition`;
   }
}
