import { parseOptionTicker } from 'projects/shared-components/options-common/options.model';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { CashFlowStrategyRole, PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto, PositionRemoved } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { daysToExpiration, isVoid } from 'projects/shared-components/utils';
import { PositionArchived } from 'projects/shared-components/shell-communication/dtos/position-archived.class';


export class DebitSpreadLegsOverviewBlock {

   constructor(
      header: string, 
      comboGroupId: string,
   ) {
      
      this.header = header;
      this.comboGroupId = comboGroupId;

      this.backgroundColor = '#050543fa';
   }

   readonly header: string;
   backgroundColor: string;
   color: string;
   portfolioId: string;
   comboId: string;
   readonly comboGroupId: string;
   
   longLegId: string;
   longLegTicker: string;
   longLegTickerDisplayName: string;
   longLegQty: number;
   longLegAsset: string;
   longLegExpirationDate: string;
   longLegDaysToExpiration: number;
   
   shortLegId: string;
   shortLegTicker: string;
   shortLegTickerDisplayName: string;
   shortLegQty: number;
   shortLegAsset: string;
   shortLegExpirationDate: string;
   shortLegDaysToExpiration: number;


   get hasLegs(): boolean {
      return this.hasLongLeg || this.hasShortLeg
   }

   get hasLongLeg(): boolean {
      return !isVoid(this.longLegId);
   }

   get hasShortLeg(): boolean {
      return !isVoid(this.shortLegId);
   }

   get hasErrors(): boolean {
      
      if (!this.hasLegs) {
         return false;
      }

      if (this.longLegQty <= 0) {
         return true;
      }

      if (this.shortLegQty >= 0) {
         return true;
      }

      if (!this.longLegTicker) {
         return true;
      }

      if (!this.longLegTicker.startsWith('@')) {
         return true;
      }

      if (!this.shortLegTicker.startsWith('@')) {
         return true;
      }

      return false;
   }

   setDto(dtos: PositionDto[]) {
      if (isVoid(dtos)) {
         return;
      }

      dtos.filter(x => !isVoid(x)).forEach(x => {
         if (x.netPosition > 0) {
            this.longLegId = x.positionId;
            this.setProperties(x, 'SpreadLongLeg');
         } else if (x.netPosition < 0) {
            this.shortLegId = x.positionId;
            this.setProperties(x, 'SpreadShortLeg')
         }
      });
   }

   setPortfolioItem(items: PortfolioItemDto[]) {
      if (isVoid(items)) {
         return;
      }

      items.filter(x => !isVoid(x)).forEach(x => {
         if (x.netPosition > 0) {
            this.longLegId = x.portfolioItemId;
            this.setProperties(x, 'SpreadLongLeg');
         } else if (x.netPosition < 0) {
            this.shortLegId = x.portfolioItemId;
            this.setProperties(x, 'SpreadShortLeg')
         }
      });
   }

   onPositionUpdate(dto: PositionDto): boolean {

      if (!this.hasLegs) {

         if (this.comboGroupId !== dto.comboGroupId) {
            return false;
         }

         if (dto.ticker) {
            return false;
         }

         if (!dto.ticker.startsWith('@')) {
            return false;
         }

         this.setDto([dto]);

         return true;
 
      } else {

         let result = false;

         if (dto.positionId === this.longLegId) {
            if (dto.netPosition !== this.longLegQty) {
               this.longLegQty = dto.netPosition;
               result = true;
            }
         } else if (dto.positionId === this.shortLegId) {
            if (dto.netPosition !== this.shortLegQty) {
               this.shortLegQty = dto.netPosition;
               result = true;
            }
         }
   
         return result;
      }
   }

   onPortfolioItemRemoved(msg: PortfolioItemRemovedDto): boolean {

      let result = false;
      if (msg.portfolioItemId === this.longLegId) {
         this.resetLegProperties('SpreadLongLeg');
         result = true;
      } else if (msg.portfolioItemId === this.shortLegId) {
         this.resetLegProperties('SpreadShortLeg');
         result = true;
      }

      return result;
   }

   onPortfolioItemAdded(msg: PortfolioItemAddedDto): boolean {

      if (!msg.portfolioItem) {
         return false;
      }

      if (msg.portfolioItem.comboGroupId !== this.comboGroupId) {
         return false;
      }

      if (!msg.portfolioItem.ticker.startsWith('@')) {
         return false;
      }

      this.setPortfolioItem([msg.portfolioItem]);

      return true;
   }

   onPositionArchived(msg: PositionArchived): boolean {
      
      if (isVoid(msg)) {
         return;
      }

      let result = false;

      if (msg.positionId === this.longLegId) {
         this.resetLegProperties('SpreadLongLeg');
         result = true;
      } else if (msg.positionId === this.shortLegId) {
         this.resetLegProperties('SpreadShortLeg');
         result = true;
      }

      return result;
   }

   onPositionRemoved(msg: PositionRemoved): boolean {
      
      if (isVoid(msg)) {
         return;
      }

      let result = false;

      if (msg.positionId === this.longLegId) {
         this.resetLegProperties('SpreadLongLeg');
         result = true;
      } else if (msg.positionId === this.shortLegId) {
         this.resetLegProperties('SpreadShortLeg');
         result = true;
      }

      return result;

   }

   private resetLegProperties(role: CashFlowStrategyRole) {
      if (role === 'SpreadLongLeg') {
         this.longLegId = null;
         this.longLegTicker = null;
         this.longLegTickerDisplayName = null;
         this.longLegAsset = null;
         this.longLegQty = null;
         this.longLegExpirationDate = null;
         this.longLegDaysToExpiration = null;
          
      } else if (role === 'SpreadShortLeg') {
         this.shortLegId = null;
         this.shortLegTicker = null;
         this.shortLegTickerDisplayName = null;
         this.shortLegAsset = null;
         this.shortLegQty = null;
         this.shortLegExpirationDate = null;
         this.shortLegDaysToExpiration = null;
      }
   }

   private setProperties(item: PortfolioItemDto | PositionDto, role: CashFlowStrategyRole) {

      const ticker = item.ticker;
      const tickerDisplayName = item.tickerDisplayName;
      const portfolioId = item.portfolioId;
      const comboId = item.comboId;
     
      const optTicker = parseOptionTicker(item.ticker);
      const expiration = item.tickerDisplayName.split(' ').slice(3).join(' ');
      const sExpDate = item.ticker.split(' ')[4];
      const diffIndays = daysToExpiration(sExpDate);
     
      const asset = `${optTicker.underlying} ${optTicker.type} ${optTicker.strike}`;
      const qty = item.netPosition;
      const expirationDate = expiration;
      const dte = diffIndays;
      



      this.portfolioId = portfolioId;
      this.comboId = comboId;

      if (role === 'SpreadLongLeg') {
         this.longLegTicker = ticker;
         this.longLegTickerDisplayName = tickerDisplayName;
         this.longLegAsset = asset;
         this.longLegQty = qty;
         this.longLegExpirationDate = expirationDate;
         this.longLegDaysToExpiration = dte;
      } else if (role === 'SpreadShortLeg') {
         this.shortLegTicker = ticker;
         this.shortLegTickerDisplayName = tickerDisplayName;
         this.shortLegAsset = asset;
         this.shortLegQty = qty;
         this.shortLegExpirationDate = expirationDate;
         this.shortLegDaysToExpiration = dte;
      }
   }
}
