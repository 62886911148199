import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {StrategyCommanderRuleDto} from '../../dtos/strategy-commander-rule-dto.interface';

export class CreateCommanderRule implements ProtocolCommand {
  public constructor(
    public readonly strategyCommanderRule: StrategyCommanderRuleDto,
    public readonly correlationTicketId: string
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateCommanderRule`;
  }
}
