import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DxPopupComponent} from "devextreme-angular/ui/popup";
import {DetectMethodChanges, findHCF, getBucketRoleClass, makeGuiFriendlyExpirationDate} from "../../utils";
import {SolutionPositionDto} from "../model/SolutionPositionDto";
import {CashFlowStrategy} from "../../adjustment-control-panel/cash-flow-strategy";
import {CashFlowStrategyRole, OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {OptionsChainService} from "../../option-chains.service";
import {TradingInstrumentsService} from "../../trading-instruments/trading-instruments-service.interface";
import {TradingInstrument} from "../../trading-instruments/trading-instrument.class";
import {makeOptionTicker} from "../../options-common/options.model";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {MessageBusService} from "../../message-bus.service";
import {QuoteDto} from "../../shell-communication/dtos/quote-dto.class";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

const ReadOnlyRoles: CashFlowStrategyRole[] = [
    'ShortOption',
    'SpreadLongLeg',
    'SpreadShortLeg'
]

class PositionWrapper {
    constructor(private _position: SolutionPositionDto) {
    }

    get ticker(): string {
        return this._position.ticker;
    }

    get side() {
        return this._position.side;
    }

    get qty(): number {
        return this._position.qty;
    }

    get underlying(): string {
        return this._position.underlying;
    }

    get type(): 'Call' | 'Put' {
        return this._position.type
    };

    get strike(): number {
        return this._position.strike
    };

    set strike(v: number) {
        this._position.strike = v
    };

    get expiration(): string {
        return this._position.expiration
    };

    get role(): CashFlowStrategyRole {
        return this._position.role
    };

    get strategy(): CashFlowStrategy {
        return this._position.strategy
    };

    get changing(): boolean {
        return this._position.changing || false;
    }

    expirationObj: OptionExpirationDescriptor;

    bid: number;
    mid: number;
    ask: number;

    updateTicker() {
        this.bid = this.mid = this.ask = null;
        this._position.expiration = makeGuiFriendlyExpirationDate(this.expirationObj.optionExpirationDate);
        this._position.ticker = makeOptionTicker(this.expirationObj, this.type,
            this.strike, 'American');
    }
}

@Component({
    selector: 'ets-after-state-detalization',
    templateUrl: 'after-state-detalization.component.html',
    styleUrls: ['after-state-detalization.component.scss', '../adjustment-grid-common-style.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AfterStateDetalizationComponent implements OnInit {
    constructor(private _changeDetector: ChangeDetectorRef,
                private _optionChainsService: OptionsChainService,
                private _tiService: TradingInstrumentsService,
                private _lastQuoteCache: LastQuoteCacheService,
                private _messageBus: MessageBusService
    ) {
    }

    private _resolver: (value?: (PromiseLike<void> | void)) => void;
    private _rejector: (reason?: any) => void;
    private _unsubscriber: Subject<any>;


    @ViewChild(DxPopupComponent) popup: DxPopupComponent;

    height = 480;

    calls: PositionWrapper[] = [];

    puts: PositionWrapper[] = [];

    expirationsList: OptionExpirationDescriptor[] = [];

    tradingInstrument: TradingInstrument;

    getTotals(legs: PositionWrapper[], attr: keyof PositionWrapper) {
        const hcf = findHCF(legs.map(x => x.qty));
        return legs.map(x => {
            const px = x[attr] as number;
            const qty = x.qty * -1;
            return (px * qty) / hcf;
        }).reduce((p: number, c: number) => p + c, 0);
    }

    ngOnInit() {
    }

    async show(data: { positions: SolutionPositionDto[]; strategy: CashFlowStrategy }[]): Promise<void> {

        let extraHeight = (data[0].positions.length - 4) * 30 * 2;
        if (extraHeight < 0) {
            extraHeight = 0;
        }
        this.height = 480 + extraHeight;

        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

        this._unsubscriber = new Subject<any>();

        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onQuotes(x.payload))

        this.calls = data.find(x => x.strategy === 'Calls').positions.map(x => new PositionWrapper(x));
        this.puts = data.find(x => x.strategy === 'Puts').positions.map(x => new PositionWrapper(x));

        const chain = await this._optionChainsService.getChain(this.calls[0].underlying);
        this.expirationsList = chain.expirations;

        this.calls.forEach(pos => {
            pos.expirationObj = chain.expirations
                .find(exp => makeGuiFriendlyExpirationDate(exp.optionExpirationDate) === pos.expiration);
        });

        const callTickers = this.calls.map(x => x.ticker);

        this.puts.forEach(pos => {
            pos.expirationObj = chain.expirations
                .find(exp => makeGuiFriendlyExpirationDate(exp.optionExpirationDate) === pos.expiration);
        });

        const putTickers = this.puts.map(x => x.ticker);

        const existingQuotes = this._lastQuoteCache.subscribeTickers(callTickers.concat(putTickers));

        for (let existingQuotesKey in existingQuotes) {
            this.calls.concat(this.puts).forEach(p => {
               if (existingQuotesKey === p.ticker) {
                   p.bid = existingQuotes[existingQuotesKey].bid;
                   p.ask = existingQuotes[existingQuotesKey].ask;
                   p.mid = existingQuotes[existingQuotesKey].mid;
               }
            });
        }

        this.tradingInstrument = this._tiService.getInstrumentByTicker(this.calls[0].underlying);

        this.popup.visible = true;

        setTimeout(() => {
            this._changeDetector.detectChanges();
        });

        return new Promise<void>((res, rej) => {
            this._resolver = res;
            this._rejector = rej;
        });
    }

    @DetectMethodChanges()
    onApplyClicked() {

        this.calls.forEach(x => {
            x.updateTicker();
        })

        this.puts.forEach(x => {
            x.updateTicker();
        });

        this._resolver();

        this.close();
    }

    private close() {
        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
            this._unsubscriber = null;
        }

        this._lastQuoteCache.unsubscribeTickers(this.calls.concat(this.puts).map(x => x.ticker));

        this.calls = [];
        this.puts = [];
        this.expirationsList = [];
        this._resolver = null;
        this._rejector = null;
        this.tradingInstrument = null;
        this.popup.visible = false;
    }

    getRoleClass(row: PositionWrapper) {
        return getBucketRoleClass(row);
    }

    @DetectMethodChanges()
    onChange() {
    }

    @DetectMethodChanges()
    onExpirationChanged(row: PositionWrapper) {

        if (row.role.indexOf('Spread') >= 0) {

            const searchStr = row.role.startsWith('Second')
                ? 'SecondSpread'
                : 'Spread';

            this.calls.filter(x => x.role.startsWith(searchStr))
                .forEach(x => {
                    x.expirationObj = row.expirationObj;

                    const unsub = [x.ticker, row.ticker];
                    x.updateTicker();
                    row.updateTicker();
                    const sub = [x.ticker, row.ticker];
                    this._lastQuoteCache.subscribeTickersDiff(unsub, sub);
                });

            this.puts.filter(x => x.role.startsWith(searchStr))
                .forEach(x => {
                    x.expirationObj = row.expirationObj;

                    const unsub = [x.ticker, row.ticker];
                    x.updateTicker();
                    row.updateTicker();
                    const sub = [x.ticker, row.ticker];
                    this._lastQuoteCache.subscribeTickersDiff(unsub, sub);
                });

        } else {

            const other = row.strategy === 'Calls'
                ? this.puts.find(x => x.role === row.role)
                : this.calls.find(x => x.role === row.role);

            other.expirationObj = row.expirationObj;

            const unsub = [other.ticker, row.ticker];
            other.updateTicker();
            row.updateTicker();
            const sub = [other.ticker, row.ticker];
            this._lastQuoteCache.subscribeTickersDiff(unsub, sub);
        }
    }

    isExpirationChangeDisabled(row: PositionWrapper) {
        return row.role === 'SpreadShortLeg' || row.role === 'SecondSpreadShortLeg';
    }

    isReadonly(row: PositionWrapper) {
        return ReadOnlyRoles.some(x => x === row.role) || !row.changing;
    }

    private onQuotes(payload: QuoteDto[]) {
        let hasMatch = false;

        payload.forEach(quote => {
            let match = this.calls.find(c => c.ticker === quote.ticker);
            if (match) {
                match.bid = quote.bid;
                match.mid = quote.mid;
                match.ask = quote.ask;
                hasMatch = true;
            }

            match = this.puts.find(p => p.ticker === quote.ticker);
            if (match) {
                match.bid = quote.bid;
                match.mid = quote.mid;
                match.ask = quote.ask;
                hasMatch = true;
            }
        });

        if (hasMatch) {
            this._changeDetector.detectChanges();
        }
    }

    @DetectMethodChanges()
    onStrikeChanged(row: PositionWrapper) {
        const unsub = [row.ticker];
        row.updateTicker();
        const sub = [row.ticker];
        this._lastQuoteCache.subscribeTickersDiff(unsub, sub);
        const q = this._lastQuoteCache.getLastQuote(sub[0]);
        if (q) {
            row.bid = q.bid;
            row.mid = q.mid;
            row.ask = q.ask;
        }
    }
}
