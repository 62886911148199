<dx-popup   [width]="this.width" 
            [height]="this.height" 
            [resizeEnabled]="this.resizeEnabled"
            [title]="this.title"
            [shading]="false"
            [showCloseButton]="false"
            (onHidden)="this.onDialogClosed()"
            >
   <div *dxTemplate="let data of 'content'" class="content">
      
      <div class="body">
      
         <ng-container *ngTemplateOutlet="positions; context: {
            header: 'Tracking Now',
            state: this.formerState,
            template: this.formerTemplate,
            strategy: this.formerStrategy,
            underlying: this.formerUnderlying,
            target: 'Former'
         }">
         </ng-container>

         <ng-container *ngIf="this.hasCurrentState">
            <ng-container *ngTemplateOutlet="positions; context: {
               header:'Start Tracking',
               state: this.currentState,
               template: this.currentTemplate,
               strategy: this.currentStrategy,
               underlying: this.currentUnderlying,
               target: 'Current'
            }">
            </ng-container>
         </ng-container>
         
      </div>

      <div [ngClass]="this.footerCssClass">
         <dx-button text="Close" width="100%" type="default" (onClick)="this.onDialogClosed()">
         </dx-button>
      </div>

      <dx-load-panel [visible]="this.isLoading"></dx-load-panel>
   </div>
</dx-popup>

<ng-template #positions let-header="header" let-state="state" let-template="template" 
   let-strategy="strategy" let-underlying="underlying" let-target="target">

   <div class="state">
      
      <div class="header">
         <div>
            {{header}}
         </div>
         <div class="details">
            (<span>{{strategy}}</span>, <span>{{underlying}}</span>)
         </div>
      </div>
   
      <div class="positions">
   
         <table>
            <thead>
               <tr>
                  <th>Side</th>
                  <th>Type</th>
                  <th>Expiration</th>
                  <th>Strike</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let row of state[0]" [ngClass]="this.getRowRoleClass(row)">
                  <td>{{row.side}}</td>
                  <td>{{row.type}}</td>
                  <td>{{row.expirationFull}}</td>
                  <td>{{row.strike}}</td>
               </tr>

               <ng-container *ngIf="state.length == 2">
                  <tr>
                     <td colspan="4" rowspan="2" class="separator">
                        &nbsp;
                     </td>
                  </tr>
                  <tr>
                  </tr>

                  <tr *ngFor="let row of state[1]" [ngClass]="this.getRowRoleClass(row)">
                     <td>{{row.side}}</td>
                     <td>{{row.type}}</td>
                     <td>{{row.expirationFull}}</td>
                     <td>{{row.strike}}</td>
                  </tr>
               </ng-container>

            </tbody>
         </table>
   
      </div>

      <div class="template">
         <div class="tpl-header">
            Template
         </div>
         <div class="tpl-name">
            "{{template}}"
         </div>
      </div>

      <div class="btn-select">
         <dx-button text="Select" width="100%" (onClick)="this.onStateSelected(target)">
         </dx-button>
      </div>
   </div>

   
</ng-template>