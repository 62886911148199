import {formatDate} from '@angular/common';
import {isNullOrUndefined} from 'util';
import {OptionExpirationDescriptor} from '../shell-communication/shell-dto-protocol';
import {daysToExpiration, INDEX_SYMBOLS, isFuturesTicker, isVoid, makeGuiFriendlyExpirationDate} from '../utils';

//
export enum OptionType {
   Unkown,
   Call,
   Put
}

//
export function makeOptionTicker(expirationDescriptor: OptionExpirationDescriptor,
                                 optionType: OptionType | string,
                                 strike: number | string,
                                 expStyle: 'American' | 'European' = 'American'
) {

   if (!expirationDescriptor || !optionType || !strike) {
      return undefined;
   }

   const option = expirationDescriptor.expirationTicker;
   const underlying = expirationDescriptor.underlyingTicker;
   const type = typeof optionType === 'string' ? optionType : OptionType[optionType];
   const date = expirationDescriptor.optionExpirationDate;

   let ticker = `${option} ${underlying} ${type} ${strike} ${date}`;

   if (!isCashOnlySettledInstrument(underlying)) {
      if (expStyle === 'European') {
         ticker = ticker + ' $';
      }
   }

   return ticker;
}

//
export function makeOptionDisplayName(expirationDescriptor: OptionExpirationDescriptor, optionType: OptionType, strike: number | string, expStyle: 'American' | 'European') {

   if (!expirationDescriptor || !optionType || !strike) {
      return undefined;
   }

   let underlying = expirationDescriptor.underlyingSymbol;
   const type = OptionType[optionType];
   const date = expirationDescriptor.dateWithDaysToExpiration;

   const isCashOnlySettled = isCashOnlySettledInstrument(underlying);

   if (underlying === 'SPX') {
      if (expirationDescriptor.expirationTicker === '@SPX') {
         underlying = underlying + '(M)';
      } else if (expirationDescriptor.expirationTicker === '@SPXW') {
         underlying = underlying + '(W)';
      }
   }

   let displayName = `${underlying} ${type} ${strike} ${date}`;

   if (!isCashOnlySettled) {
      if (expStyle === 'European') {
         displayName = displayName + ' (E)';
      }
   }

   return displayName;
}


export function makeOptionTickerDisplayName(ticker: OptionTicker, expStyle = 'American') {
   let underlying = ticker.underlying;
   const type = ticker.type;
   const date = makeGuiFriendlyExpirationDate(ticker.expiration);

   const isCashOnlySettled = isCashOnlySettledInstrument(underlying);

   if (underlying === 'SPX') {
      if (ticker.expirationTicker === '@SPX') {
         underlying = underlying + '(M)';
      } else if (ticker.expirationTicker === '@SPXW') {
         underlying = underlying + '(W)';
      }
   }

   let displayName = `${underlying} ${type} ${ticker.strike} ${date}`;

   if (!isCashOnlySettled) {
      if (expStyle === 'European') {
         displayName = displayName + ' (E)';
      }
   }

   return displayName;
}

//
export function makeOptionExpirationDescriptor(
   dateString: string,
   expirationTicker: string,
   underlyingTicker: string,
   underlyingSymbol: string,
   strikes: number[]
): OptionExpirationDescriptor {

   if (!expirationTicker && !expirationTicker.startsWith('@')) {
      throw new Error(`Bad expiration ticker: ${expirationTicker}`);
   }

   const diffIndays = daysToExpiration(dateString);

   const displayDate = formatDate(dateString, 'ccc dd-MMM-yy', 'en-US');
   const displayDateWithExpirationDays = `${displayDate} (${diffIndays}d)`;

   const exp: OptionExpirationDescriptor = {
      optionExpirationDate: dateString,
      optionExpirationDisplayDate: displayDate,
      daysToExpiration: diffIndays,
      dateWithDaysToExpiration: displayDateWithExpirationDays,
      expirationTicker,
      underlyingTicker,
      underlyingSymbol,
      strikes,
   };

   return exp;
}

//
export function isCashOnlySettledInstrument(symbol: string): boolean {

   if (isNullOrUndefined(symbol)) {
      return false;
   }

   if (isFuturesTicker(symbol)) {
      return false;
   }

   if (INDEX_SYMBOLS.indexOf(symbol) >= 0) {
      return true;
   }

   return false;
}

//
export interface OptionTicker {
   underlying: string;
   expiration: string;
   type: 'Call' | 'Put';
   strike: number;
   expirationTicker: string;
   ticker: string;
}

//
export function parseOptionTicker(ticker: string): OptionTicker {
   if (isVoid(ticker)) {
      return undefined;
   }

   const parts = ticker.split(' ');

   if (parts.length < 5) {
      return undefined;
   }

   return {
      expirationTicker: parts[0],
      underlying: parts[1],
      type: parts[2] as any,
      strike: parseFloat(parts[3]),
      expiration: parts[4],
      ticker: ticker,
   };
}

export function zipOptionTicker(ticker: OptionTicker) : string {
   const t = `${ticker.expirationTicker} ${ticker.underlying} ${ticker.type} ${ticker.strike} ${ticker.expiration}`;
   return t;
}

export type OptionStrategy =
    'BOS' |
    'Butterfly' |
    'Butterfly - Double' |
    'Butterfly - Triple' |
    'Collar' |
    'Condor' |
    'Condor - Double' |
    'Calendar' |
    'Fence' |
    'Iron Butterfly' |
    'Iron Condor' |
    'Married Stock' |
    'Ratio Back Spread' |
    'Risk Reversal' |
    'Straddle' |
    'Strangle' |
    'Option - Long' |
    'Option - Short' |
    'Ladder' |
    'Ladder - Calendarized' |
    'Slingshot' |
    'Slingshot - Modified' |
    'Slingshot - Double' |
    'Sponsored Long' |
    'Vertical' |
    'Vertical - Double';