<div class="container">
   <div class="column left">
   
      <fieldset class="hedge-target">
         <legend>Freeze Bucket</legend>
         
         <div class="ets-field target">
            <p class="portfolio-label">Portfolio <span style="font-style: italic;">(select strategy terminal first)</span></p>
            <div>
               <dx-select-box [items]="control.availablePortfolios" displayExpr="portfolioName" valueExpr="portfolioId" [(value)]="control.targetPortfolio"
                  (onValueChanged)="control.onTargetPortfolioChanged()" [showClearButton]="true"></dx-select-box>
            </div>
         </div>
         
         <div class="ets-field target">
            <p>Combo</p>
            <div>
               <dx-select-box [items]="control.availableCombos" displayExpr="comboName" valueExpr="comboId" [(value)]="control.targetCombo"
                  (onValueChanged)="control.onTargetComboChanged()" [showClearButton]="true"></dx-select-box>
            </div>
         </div>
   
         <div class="ets-field target">
            <p>Group</p>
            <div>
               <dx-select-box [items]="control.availableComboGroups" displayExpr="comboGroupName" valueExpr="comboGroupId" 
                              [(value)]="control.targetComboGroup" [showClearButton]="true"></dx-select-box>
            </div>
         </div>   
      </fieldset>
   
   </div>
   
   <div class="column right">
      <fieldset class="hedge-instrument">
         <legend>Freeze Instrument</legend>
         <dx-select-box [items]="['SPY ETF', 'E-mini S&P500', 'Micro E-mini S&P500', 'Custom']" 
                        [(value)]="control.deltaFreezeInstrument"></dx-select-box>
         <div class="custom-hedge" *ngIf="control.deltaFreezeInstrument === 'Custom'">
            <ets-symbol-picker (instrumentSelected)="control.symbol = $event"></ets-symbol-picker>
         </div>
         <div class="beta">
            <div class="box">Custom Beta</div>
            <div class="box">
               <dx-number-box [min]="0" [step]="0.1" [showSpinButtons]="true" [inputAttr]="{'text-align': 'center'}"
                  [(value)]="control.customBeta"></dx-number-box>
            </div>
         </div>
      </fieldset>
   
      <fieldset class="hedge-rounding">
         <legend>Hedge Qty Rounding</legend>
         <dx-radio-group   [items]="['Up', 'Down']" 
                           layout="horizontal" 
                           [(value)]="control.hedgeRounding">
         </dx-radio-group>
      </fieldset>

      <fieldset class="order-type">
         <legend>Order Settings</legend>
         <div class="ets-field order-type">
            <div class="ets-field-label">Order Type</div>
            <div class="ets-field-value">
               <dx-select-box [items]="['Market', 'Limit']" [(value)]="control.orderType"></dx-select-box>
            </div>
         </div>
         
         <div class="ets-field" *ngIf="control.orderType === 'Limit'">
            <div class="ets-field-label">Distance From Top (%)</div>
            <div class="ets-field-value">
               <dx-number-box [(value)]="control.orderDistance" [showSpinButtons]="true" [showClearButton]="true"
                              [min]="0"></dx-number-box>
            </div>
         </div>   
         
      </fieldset>   
   </div>
</div>
