import {TradingInstrument} from './trading-instrument.class';
import { EventEmitter } from '@angular/core';

export interface UniqueUnderlying {
   displayName: string;
   underlying: string;
   kind: string;
}

export abstract class TradingInstrumentsService {
  abstract newInstrument: EventEmitter<TradingInstrument>;
  abstract register(ti: TradingInstrument);
  abstract init(): Promise<void>;
  abstract getAllTradingInstruments(): TradingInstrument[];
  abstract getInstrumentByTicker(ticker: string): TradingInstrument;
  abstract getInstrumentByDisplayName(name: string): TradingInstrument;
  abstract getUniqueUnderlyings(): UniqueUnderlying[];
}
