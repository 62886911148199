import {Injectable} from '@angular/core';
import {LayoutModule} from './layout.module';
import {AccessControlService} from '../access-control-service.class';
import {environment} from '../environments/environment';
import {EtsConstants} from '../ets-constants.const';

@Injectable()
export class LayoutSecurityContextService {
    constructor(private readonly _accessControl: AccessControlService) {
    }

    get canAddLayout(): boolean {
        if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
            return this._accessControl.isSecureElementAvailable('e87a0ee6-1447-407a-8a92-53de2a321855');
        }
        if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
            return this._accessControl.isSecureElementAvailable('840969d7-76f2-4ec2-8799-a70c4b2def49');
        }
        return false;
    }

    get canChangeLayoutHeader(): boolean {
        if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
            return this._accessControl.isSecureElementAvailable('c6602938-6c8b-40e6-a49a-444bdadbc153');
        }
        if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
            return this._accessControl.isSecureElementAvailable('0cd0b84f-fc7d-41f1-b351-513585d808c1');
        }
        return false;
    }

    get canCloseLayout(): boolean {
        if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
            return this._accessControl.isSecureElementAvailable('01666d94-97e0-4546-a5a4-72ec77447003');
        }
        if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
            return this._accessControl.isSecureElementAvailable('65be87ad-b002-41c0-9583-beb472e56e14');
        }
        return false;
    }

    get resourceButton(): boolean {
        if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
            return false;
        }
        if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
            return this._accessControl.isSecureElementAvailable('77339632-04fc-4f65-a7b2-c30c23e7ef26');
        }
        return false;
    }

    get pivotsTrackerButton(): boolean {
        if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
            return false;
        }
        if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
            return this._accessControl.isSecureElementAvailable('a26ee1bf-0b79-4276-97fe-e1533b4b40e2');
        }
        return false;
    }
}
