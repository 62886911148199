import { ProtocolCommand } from '../../../service-model/protocol-command.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';

export class AdjustManualPosition implements ProtocolCommand {
   constructor(
      public readonly positionId: string,
      public readonly desiredQty: number
   ) {
   }

   guiToken: string;

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.AdjustManualPosition`;
   }
}
