import { Component, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges, findAtmStrikeIndex, getPanelStateKey, isNullOrUndefined } from '../utils';
import { SettingsStorageService } from '../settings-storage-service.service';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { GreeksDto, OptionExpirationDescriptor } from '../shell-communication/shell-dto-protocol';
import { ToastrService } from 'ngx-toastr';
import { ColumnVisibleEvent, GetContextMenuItemsParams, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getOptionsBoardGridModel } from './option-chain-grid-model';
import { SymbolPickerComponent } from '../symbol-picker/symbol-picker.component';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { ChainLine, COLUMNS, OptionContract } from './option-types';
import { OptionsChainService } from '../option-chains.service';
import { makeOptionDisplayName, makeOptionTicker, OptionType } from '../options-common/options.model';
import { ComboHighlightedItem, ComboHighlightedUIMessage, SymbolHighlighted } from '../ui-messages/ui-messages';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { ClipboardService } from '../clipboard.service';
import { TimeInForce } from '../trading-model/time-in-force.enum';
import { OrderType } from '../trading-model/order-type.enum';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import {UserSettingsService} from "../user-settings.service";

interface PanelState {
   // gridColimns: ColumnState[];
   // underlying: string;
   // expiration: string;
   // chain: ChainLine[];
   // loadCalls: boolean;
   // loadPuts: boolean;
   // hiddenCalls: string[];
   // hiddenPuts: string[];
   isLinkedToSymbol: boolean;
}

@Component({
   selector: 'ets-option-chain',
   templateUrl: 'option-chain.component.html',
   styleUrls: ['option-chain.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionChainComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _tiService: TradingInstrumentsService,
      private readonly _toastr: ToastrService,
      private readonly _optionChainsService: OptionsChainService,

      private readonly _clipboardService: ClipboardService,
   ) { 

      super(_changeDetector, _userSettingsService, _messageBus);

   }
   
   private _grid: GridReadyEvent;
   private _unsubscriber: Subject<void>;
   private _itmPollingInterval: number;
   private _dynamicStrikesInterval: number;
   private _hiddenCallColumns: string[] = [];
   private _hiddenPutColumns: string[] = [];
   private _loadCalls = true;
   private _loadPuts = true;
   private _chainsByExpiration: Record<string, ChainLine[]> = {};
   
   @ViewChild(SymbolPickerComponent, { static: true }) symbolPicker: SymbolPickerComponent;
   
   @ViewChild('customStrikeDialog', { static: true }) customStrikeDialog: DxPopupComponent;
   

   underlying: TradingInstrument;
   
   numOfStrikes = 1;
   
   optionsGridModel: GridOptions;
   
   lastPx: number;
   
   lastChange: number;
   
   private _expiration: OptionExpirationDescriptor;
   get expiration(): OptionExpirationDescriptor {
      return this._expiration;
   }
   
   @DetectSetterChanges()
   set expiration(value: OptionExpirationDescriptor) {
      this._expiration = value;
   }
   
   expirations: OptionExpirationDescriptor[];
   
   customStrikePrice: number;
   
   quoteProvider: string;
   
   strikeStep: number;
   
   centerStrike: number;

   
   private _loadingMessage = 'Loading...';
   get loadingMessage(): string { return this._loadingMessage; }
   
   @DetectSetterChanges()
   set loadingMessage(value: string) { this._loadingMessage = value; }
   
   
   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   get loadCalls() {
      return this._loadCalls;
   }

   @DetectSetterChanges()
   set loadCalls(v: boolean) {
      if (this._loadCalls === v) {
         return;
      }
      this._loadCalls = v;
      this.onLoadCallsChanged();
   }

   get loadPuts() {
      return this._loadPuts;
   }
   
   @DetectSetterChanges()
   set loadPuts(v: boolean) {
      if (this._loadPuts === v) {
         return;
      }
      this._loadPuts = v;
      this.onLoadPutsChanged();
   }

   @DetectMethodChanges({isAsync: true})
   async onSymbolSelected(ti: TradingInstrument) {
      
      if (this.underlying) {
         this._lastQuoteCache.unsubscribeTicker(this.underlying.ticker);
      }

      this.underlying = ti;

      this._lastQuoteCache.subscribeTicker(this.underlying.ticker);

      await this.prepareChains();
   }


   etsOnInit() {
      this._unsubscriber = new Subject();

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onQuote(x.payload));

      this._messageBus.of<GreeksDto>('GreeksDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onGreeks(x.payload));


      this._messageBus
         .of<SymbolHighlighted>('SymbolHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter( _ => this.isLinkedToSymbol),
            filter( x => x.scopeId === this.layoutTabId),
         )
         .subscribe(x => this.onSymbolHighlightedMessage(x.payload));
      this.optionsGridModel = getOptionsBoardGridModel.bind(this)();
   }

   
   etsAfterViewInit() {  }
   
   
   @DetectMethodChanges({isAsync: true})
   onSymbolHighlightedMessage(x: SymbolHighlighted): void {
      const ti = this._tiService.getInstrumentByTicker(x.ticker);
      if (!ti) {
         return;
      }

      try {
         this.onSymbolSelected(ti);
         this.symbolPicker.selectedInstrument = ti;
      } catch {
         //
      }
   }


   etsOnDestroy() {
      this.unsubscribeCurrentChains();

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
      
      clearInterval(this._itmPollingInterval);
      clearInterval(this._dynamicStrikesInterval);
   }


   onGridReady(args: GridReadyEvent) {
      this._grid = args;

      args.api.sizeColumnsToFit();

      const key = getPanelStateKey(this);
      const item = this._userSettingsService.getValue(key);
      if (!item) {
         this.saveState();
      } else {
         this.restoreState();
      }

      this._itmPollingInterval = setInterval(() => this.updateInTheMoneyChain(), 1000) as any;
      this._dynamicStrikesInterval = setInterval(() => this.applyDynamicStrikes(), 3000) as any;
   }


   loadChain() {

      if (!this.loadCalls && !this.loadPuts) {
         this._toastr.error('Select Calls and/or Puts');
         return;
      }

      this.optionsGridModel = getOptionsBoardGridModel.bind(this)(this.loadCalls, this.loadPuts);

      const alreadySubscribed = this.getSubscribedTickers();

      this._grid.api.setRowData([]);

      this.loadingMessage = 'Building option chains...';

      this.isLoading = true;

      setTimeout(async () => {

         const totalLines  = await this.getActualChain();

         if (totalLines.length === 0) {
            this._toastr.error('No chains found for instrument');
            return;
         }

         const toSubscribe = totalLines.flatMap(x =>  {
            const tickers = [];
            if (x.call) {
               tickers.push(x.call.ticker);
            }
            if (x.put) {
               tickers.push(x.put.ticker);
            }
            return tickers;
         });

         this.subscribeTickersDiff(alreadySubscribed, toSubscribe);

         this._grid.api.setRowData(totalLines);

         this.isLoading = false;

      }, 0);

   }


   @DetectMethodChanges()
   addCustomStrike() {
      if (this.customStrikeDialog) {
         this.customStrikeDialog.visible = true;
      }
   }


   
   removeStrike(node: RowNode) {
      if (!node) {
         this._toastr.error('Strike not selected');
         return;
      }

      if (!node.data) {
         this._toastr.error('Non stike row selected');
         return;
      }

      const chainLine = node.data as ChainLine;

      if (chainLine.put) {
         this._lastQuoteCache.unsubscribeTicker(chainLine.put.ticker);
      }

      if (chainLine.call) {
         this._lastQuoteCache.unsubscribeTicker(chainLine.call.ticker);
      }

      this._grid.api.removeItems([node]);

      this.saveState();
   }


   @DetectMethodChanges()
   onAddCustomStrikeClicked() {
      this.customStrikeDialog.visible = false;

      if (!this.customStrikePrice) {
         this._toastr.error('Custom Strike Price Not Selected');
         return;
      }

      if (!this.expiration) {
         this._toastr.error('Expiration date not selected');
         return;
      }

      const lines = this._chainsByExpiration[this.expiration.dateWithDaysToExpiration];

      if (isNullOrUndefined(lines)) {
         this._toastr.error('No options loaded for selected expiration');
         return;
      }

      const line = lines.find(x => x.strike === this.customStrikePrice);

      if (isNullOrUndefined(line)) {
         this._toastr.error('Selected strike price not available');
         return;
      }
      
      if (this._grid) {
         const customLine: ChainLine = {
            put: line.put,
            strike: line.strike,
            call: line.call,
            isCustomAdded: true
         };
         this._grid.api.applyTransaction({ add: [customLine] });
      }

      this._lastQuoteCache.subscribeTicker(line.call.ticker);
      this._lastQuoteCache.subscribeTicker(line.put.ticker);

      this.saveState();
   }


   onColumnVisibilityChanged(args: ColumnVisibleEvent): void {
      if (args.visible) {
         const parts = args.column.getId().split('.');
         if (parts.length === 2) {
            if (parts[0] === 'call') {
               const ix = this._hiddenCallColumns.indexOf(parts[1]);
               if (ix >= 0) {
                  this._hiddenCallColumns.splice(ix, 1);
               }
            } else if (parts[0] === 'put') {
               const ix = this._hiddenPutColumns.indexOf(parts[1]);
               if (ix >= 0) {
                  this._hiddenPutColumns.splice(ix, 1);
               }
            }
         }
      }
   }


   private subscribeChain(chain: ChainLine[]) {
      const tickers = [];
      chain.forEach(cl => {
         if (cl.call) {
            tickers.push(cl.call.ticker);
         }

         if (cl.put) {
            tickers.push(cl.put.ticker);
         }
      });
      this._lastQuoteCache.subscribeTickers(tickers);
   }


   private async prepareChains() {

      if (!this.underlying) {
         return;
      }

      const ti = this.underlying;

      this.loadingMessage = 'Loading options chains...';

      this.isLoading = true;

      try {

         const chain = await this._optionChainsService.getChain(ti.ticker);

         const mapByDate: Record<string, ChainLine[]> = {};

         chain.expirations.forEach( exp =>  {
            
            const lines = exp.strikes.map(strike => {
               const callTicker = makeOptionTicker(exp, OptionType.Call, strike, 'American');
               const callDisplayName = makeOptionDisplayName(exp, OptionType.Call, strike, 'American');

               const callContract: OptionContract = {
                  ticker: callTicker,
                  displayName: callDisplayName
               };
   
               const putTicker = makeOptionTicker(exp, OptionType.Put, strike, 'American');
               const putDisplayName = makeOptionDisplayName(exp, OptionType.Put, strike, 'American');
               const putContract: OptionContract = {
                  displayName: putDisplayName,
                  ticker: putTicker
               };
   
               const line: ChainLine = {
                  strike,
                  call: callContract,
                  put: putContract
               };

               return line;
            });
            mapByDate[exp.dateWithDaysToExpiration] = lines;
         });

         this._chainsByExpiration = mapByDate;

         this.expirations = chain.expirations;

         this.expirations.forEach(key => {
            
            const container = this._chainsByExpiration[key.dateWithDaysToExpiration];

            if (isNullOrUndefined(container)) {
               return;
            }

            this._chainsByExpiration[key.dateWithDaysToExpiration] = container.sort( (a, b) => a.strike - b.strike);

         });
         

      } catch (e) {

         this._toastr.error(e.error.error);

      } finally {

         this.isLoading = false;

      }
   }

   onCellCopy(args: GetContextMenuItemsParams) {
      this.copyOptionContractToClipboard(args);
   }


   copyOptionContractToClipboard(args: GetContextMenuItemsParams) {
      
      const chainLine = args.node.data as ChainLine;

      if (isNullOrUndefined(chainLine)) {
         return;
      }

      const colDef = args.column.getColDef();

      if (!colDef) {
         return;
      }

      if (!colDef.field) {
         return;
      }

      if (!colDef.field.endsWith('.ask') 
            && !colDef.field.endsWith('.bid')) {
         return;
      }
      
      const item: ComboHighlightedItem = {
         accountId: null,
         comboId: null,
         portfolioId: null,
         ticker: colDef.field.startsWith('call') ? chainLine.call.ticker : chainLine.put.ticker,
         underlying: this.underlying.ticker,
         tickerDisplayName: colDef.field.startsWith('call') ? chainLine.call.displayName : chainLine.put.displayName,
         netPosition: colDef.field.endsWith('.ask') ? -1 : 1,
         itemType: colDef.field.startsWith('call') ? PortfolioItemType.Call : PortfolioItemType.Put,
         strategyId: null
      };

      const msg: ComboHighlightedUIMessage = {
         items: [item],
         orderParams: {
            orderDuration: TimeInForce.GTC,
            orderQty: 1,
            orderType: OrderType.Limit
         }
      };

      this._clipboardService.put('combo', msg);
      this._toastr.success('Option copied to clipboard');
   }


   onLoadCallsChanged() {
      if (!this.loadCalls) {
         COLUMNS.forEach(col => {
            const column = this._grid.columnApi.getColumn(`call.${col}`);
            if (column) {
               if (!column.isVisible()) {
                  if (this._hiddenCallColumns.indexOf(col) === -1) {
                     this._hiddenCallColumns.push(col);
                  }
               }
               this._grid.columnApi.setColumnVisible(column, false);
            }
         });

      } else {
         COLUMNS.forEach(col => {
            const column = this._grid.columnApi.getColumn(`call.${col}`);
            if (column) {
               if (!column.isVisible()) {
                  if (this._hiddenCallColumns.indexOf(col) === -1) {
                     this._grid.columnApi.setColumnVisible(column, true);
                  }
               }
            }
         });
      }

      setTimeout(() => this.saveState(), 0);
   }


   onLoadPutsChanged() {
      if (!this.loadPuts) {
         COLUMNS.forEach(col => {
            const column = this._grid.columnApi.getColumn(`put.${col}`);
            if (column) {
               if (!column.isVisible()) {
                  if (this._hiddenPutColumns.indexOf(col) === -1) {
                     this._hiddenPutColumns.push(col);
                  }
               }
               this._grid.columnApi.setColumnVisible(column, false);
            }
         });

      } else {
         COLUMNS.forEach(col => {
            const column = this._grid.columnApi.getColumn(`put.${col}`);
            if (column) {
               if (!column.isVisible()) {
                  if (this._hiddenPutColumns.indexOf(col) === -1) {
                     this._grid.columnApi.setColumnVisible(column, true);
                  }
               }
            }
         });
      }

      setTimeout(() => this.saveState(), 0);
   }



   @DetectMethodChanges()
   private onQuote(quotes: QuoteDto[]) {
      if (!this.underlying) {
         return;
      }

      const nodesToUpdate = [];

      quotes.forEach(x => {

         if (x.ticker.startsWith('@')) {

            if (x.strikePrice) {
               const node = this._grid.api.getRowNode(x.strikePrice + '');

               if (!node) {
                  return;
               }

               if (!node.data) {
                  return;
               }

               const chainLine = node.data as ChainLine;

               let option: OptionContract;

               if (chainLine.call) {
                  if (chainLine.call.ticker === x.ticker) {
                     option = chainLine.call;
                  }
               }

               if (!option) {
                  if (chainLine.put) {
                     if (chainLine.put.ticker === x.ticker) {
                        option = chainLine.put;
                     }
                  }
               }

               if (!option) {
                  return;
               }

               if (x.askPx.length > 0) {
                  option.ask = x.askPx[0];
               }

               if (x.bidPx.length > 0) {
                  option.bid = x.bidPx[0];
               }

               if (x.askQx.length > 0) {
                  option.askSize = x.askQx[0];
               }

               if (x.bidQx.length > 0) {
                  option.bidSize = x.bidQx[0];
               }

               option.oi = x.openInterest;

               nodesToUpdate.push(node);
            }
         } else {
            if (x.ticker !== this.underlying.ticker || x.isLevel2) {
               return;
            }

            this.lastPx = x.lastPx;
            this.quoteProvider = x.provider;

            if (x.open > 0) {
               this.lastChange = ((x.lastPx - x.open) / (x.open || 1)) * 100 || 0;
            } else {
               this.lastChange = 0;
            }
         }
      });

      if (nodesToUpdate.length > 0) {
         this._grid.api.refreshCells({ rowNodes: nodesToUpdate, force: true });
      }
   }


   
   private onGreeks(x: GreeksDto): void {
      const node: RowNode = this._grid.api.getRowNode(x.strike + '');

      if (!node) {
         return;
      }

      const line: ChainLine = node.data;

      const option: OptionContract = x.optionType === OptionType.Call ? line.call : line.put;

      if (option.ticker !== x.ticker) {
         return;
      }

      option.delta = x.delta;
      option.gamma = x.gamma;
      option.theta = x.theta;
      option.vega = x.vega;
      option.iv = x.impliedVolatility;

      this._grid.api.refreshCells({ rowNodes: [node] });
   }


   
   private updateInTheMoneyChain() {
      const grid = this._grid;

      if (!grid) {
         return;
      }

      if (!this.underlying) {
         return;
      }

      const lastPx = this.lastPx;

      if (!lastPx) {
         return;
      }

      const nodesToRefresh: RowNode[] = [];

      grid.api.forEachNode(node => {
         let nodeAdded = false;

         const cl = node.data as ChainLine;
         if (cl.call) {
            const shouldHl = cl.strike <= lastPx;
            if (cl.call.itm !== shouldHl) {
               cl.call.itm = shouldHl;
               nodesToRefresh.push(node);
               nodeAdded = true;
            }
         }

         if (cl.put) {
            const shouldHl = cl.strike >= lastPx;
            if (cl.put.itm !== shouldHl) {
               cl.put.itm = shouldHl;
               if (!nodeAdded) {
                  nodesToRefresh.push(node);
               }
            }
         }
      });

      if (nodesToRefresh.length > 0) {
         grid.api.redrawRows({ rowNodes: nodesToRefresh });
      }

   }


   
   private async applyDynamicStrikes(): Promise<void> {
      const grid = this._grid;

      if (!grid) {
         return;
      }

      if (!this.underlying) {
         return;
      }

      const lastPx = this.lastPx;

      if (!lastPx) {
         return;
      }

      const rows: ChainLine[] = [];
      this._grid.api.forEachLeafNode( node => rows.push(node.data) );

      if (rows.length === 0) {
         return;
      }


      const chain = await this.getActualChain();

      if (chain.length === 0) {
         return;
      }

      const toAdd = chain.filter( (v, ix, arr) => rows.findIndex(r => r.strike === v.strike ) === -1);
      const toRemove = rows.filter( (v, ix, arr) => chain.findIndex( ch => ch.strike === v.strike && !v.isCustomAdded  ) === -1 );

      const toSubscribe = [];
      toAdd.forEach(x => {
         if (x.call) {
            toSubscribe.push(x.call.ticker);
         }
         if (x.put) {
            toSubscribe.push(x.put.ticker);
         }
      });

      const toUnsubscribe = [];
      toRemove.forEach(x => {
         if (x.call) {
            toUnsubscribe.push(x.call.ticker);
         }
         if (x.put) {
            toUnsubscribe.push(x.put.ticker);
         }
      });

      if (toSubscribe.length > 0 || toUnsubscribe.length > 0) {
         this.subscribeTickersDiff(toUnsubscribe, toSubscribe);
      }


      if (toAdd.length  > 0) {
         
         this._grid.api.applyTransactionAsync({add: toAdd});
      }

      if (toRemove.length > 0) {
         
         this._grid.api.applyTransactionAsync({remove: toRemove});
      }
   }

   
   
   private async getActualChain(): Promise<ChainLine[]> {
      
      const exp = this.expiration;
      
      const chains = this._chainsByExpiration[exp.dateWithDaysToExpiration];

      if (!chains) {
         return [];
      }

      const lastQuote = await this._lastQuoteCache.getLastQuoteWithAwait(this.underlying.ticker);


      const centerIx = findAtmStrikeIndex(exp.strikes, lastQuote);
      
      let totalLines: ChainLine[] = [];

      if (this.strikeStep) {
         const topStrike = chains[centerIx].strike - this.numOfStrikes * this.strikeStep;
         const bottomStrike = chains[centerIx].strike + this.numOfStrikes * this.strikeStep;

         for (let index = topStrike; index <= bottomStrike; index += this.strikeStep) {
            const element = chains.find(x => x.strike === index);
            if (element) {
               totalLines.push(element);
            }
         }
      } else {
         const topEdge = centerIx - this.numOfStrikes;
         const bottomEdge = centerIx + this.numOfStrikes;
         totalLines = chains.slice(topEdge, bottomEdge);
      }

      return totalLines;
   }


   protected getState(): PanelState {
      // if (!this._grid) {
      //    return;
      // }

      const key = getPanelStateKey(this);
      const state: PanelState = {
         isLinkedToSymbol: this.isLinkedToSymbol
      };

      return state;
      // const gridState = this._grid.columnApi.getColumnState();

      // if (!chain) {
      //    chain = [];
      //    this._grid.api.forEachNode(node => chain.push(node.data));
      // }

      // const state: PanelState = {
      //    gridColimns: gridState,
      //    underlying: this.underlying ? this.underlying.ticker : null,
      //    expiration: this.expirationDate,
      //    chain,
      //    loadCalls: this.loadCalls,
      //    loadPuts: this.loadPuts,
      //    hiddenCalls: this._hiddenCallColumns,
      //    hiddenPuts: this._hiddenPutColumns
      // };
   }


   protected setState(state: PanelState): void {

      if (!this._grid) {
         return;
      }

   
      if (state.isLinkedToSymbol) {
         this.isLinkedToSymbol = state.isLinkedToSymbol;
      }
   }


   private unsubscribeCurrentChains() {
      
      const tickers = this.getSubscribedTickers();
      this._lastQuoteCache.unsubscribeTickers(tickers);
   }

   
   private getSubscribedTickers(): string[] {
 
      const tickers: string[] = [];

      this._grid.api.forEachNode(node => {
         
         const data: ChainLine = node.data;
         
         if (isNullOrUndefined(data)) {
            return;
         }

         if (data.call) {
            tickers.push(data.call.ticker);
         }

         if (data.put) {
            tickers.push(data.put.ticker);
         }

      });

      return tickers;
   }

   
   private subscribeTickersDiff(alreadySubscribed: string[], toSubscribe: string[]) {
   
      const subscribe = toSubscribe.filter(x => !alreadySubscribed.includes(x));
      const unsubscribe = alreadySubscribed.filter(x => !toSubscribe.includes(x));

      this._lastQuoteCache.unsubscribeTickers(unsubscribe);
      this._lastQuoteCache.subscribeTickers(subscribe);
   }
}
