<dx-popup
  [width]="1050"
  [height]="400"
  [visible]="visible"
  [resizeEnabled]="true"
  title="Edit Commander Rule"
  (onHidden)="visible = false">

  <div *dxTemplate="let data of 'content'" class="popup-template" #container>
    <div class="configuration">

      <!--List of Handlers-->
      <div class="section">
        <div class="header">Select Condition</div>
        <dx-list
          [dataSource]="listOfHandlerTypes"
          [selectionMode]="'single'"
          [(selectedItemKeys)]="selectedHandlerTypes"
          [disabled]="parameters.mode === 'update'"
        ></dx-list>
      </div>

      <!-- Selected Handler Controls -->
      <div class="section">
        <div class="header">Condition Parameters</div>
        <moving-average-crossover *ngIf="selectedHandlerTypes[0]?.id === '826ab7a8-b903-4bf2-a475-35d3ca9f5e6e'"
                                  [commonParameters]="parameters"
                                  [specificParameters]="parameters.handlers.maCrossover"
        ></moving-average-crossover>
      </div>

      <!-- List of Actions -->
      <div class="section">
        <div class="header">Select Action</div>
        <dx-list
          [dataSource]="listOfActionTypes"
          [selectionMode]="'single'"
          [(selectedItemKeys)]="selectedActionTypes"
          [disabled]="parameters.mode === 'update'"
        ></dx-list>
      </div>

      <!-- Selected Actions Controls -->
      <div class="section">
        <div class="header">Action Parameters</div>
        <start-strategies-by-family *ngIf="selectedActionTypes[0]?.id === 'bcf088dd-d5ea-4d32-b9c0-c0aa216dec2b'"
                                    [parameters]="parameters.actions.startStrategiesByFamily"
        ></start-strategies-by-family>
        <exit-strategies-by-family *ngIf="selectedActionTypes[0]?.id === '942ddb03-074f-4c15-ad52-76a87c1eebbd'"
                                   [parameters]="parameters.actions.exitStrategiesByFamily"
        ></exit-strategies-by-family>
        <start-specific-strategy *ngIf="selectedActionTypes[0]?.id === 'f563f50a-5ad3-4b10-be12-ab8c1252f575'"
                                    [parameters]="parameters.actions.startSpecificStrategy"
        ></start-specific-strategy>
        <exit-specific-strategy *ngIf="selectedActionTypes[0]?.id === '1339844d-c421-4080-b7f7-18d37cb055bb'"
                                 [parameters]="parameters.actions.exitSpecificStrategy"
        ></exit-specific-strategy>
      </div>

    </div>

    <div class="buttons">
      <div class="checkboxes">
        <dx-check-box
          text="Is Recurring"
          [(value)]="parameters.isRecurring"
        ></dx-check-box>

        <dx-check-box
          text="Is Disabled"
          [(value)]="parameters.isDisabled"
        ></dx-check-box>
      </div>

      <dx-button width="100"
                 (onClick)="onCreateClicked()"
                 [disabled]="!canCreateRule"
                 [text]="parameters.mode === 'update' ? 'Update' : 'Create'"
      ></dx-button>

      <dx-button width="100"
                 text="Cancel"
                 (onClick)="onCancelClicked()"
      ></dx-button>
    </div>

    <dx-load-panel
      [position]="{ my: 'center', at: 'center', of: container }"
      [visible]="isLoading"
      [message]="loadingMessage"
    ></dx-load-panel>
  </div>

</dx-popup>