import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../../utils";
import {
    OffsetsManager,
    VisibleOffsetsSettingsService,
    VisibleOffsetsShowMode
} from "../visible-offsets/visible-offsets-settings.service";
import {FavoriteAdjustment} from "./FavoriteAdjustment";

@Component({
    selector: 'ets-pricebox-menu-component',
    templateUrl: 'pricebox-menu.component.html',
    styleUrls: ['pricebox-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PriceboxMenuComponent implements OnInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _visibleOffsetsSettingsService: VisibleOffsetsSettingsService
    ) {
    }

    visible = false;

    showSpreadOffset: boolean;
    showSpreadWidth: boolean;
    showSecondSpreadOffset: boolean;
    showSecondSpreadWidth: boolean;
    showProtectiveOptionOffset: boolean;
    showSecondProtectiveOptionOffset: boolean;

    showOffsets: boolean;

    availableShowModes: VisibleOffsetsShowMode[] = [
        'Displayed',
        'Mouse Over'
    ];

    showMode: VisibleOffsetsShowMode;

    showSingleValue = false;

    spreadOffsetFilter: number;
    spreadWidthFilter: number;

    showImpliedVolatility: boolean;
    showZonesGridArrow: boolean;

    settingsSaved$ = new EventEmitter();

    availableCallAdjustments : string[] = [
        '#1(C)',
        '#2A(C)',
        '#2B(C)',
        '#3(C)',
        '#3DO(C)'
    ];

    availablePutAdjustments : string[] = [
        '#1(P)',
        '#2A(P)',
        '#2B(P)',
        '#3(P)',
        '#3DO(P)',
    ];

    favoriteAdjustments : FavoriteAdjustment[] = [];

    private get offsetsManager(): OffsetsManager {
        return this._visibleOffsetsSettingsService.priceBox;
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show() {
        this.setWhatToShow();
        this.setWhenAndHowToShow();
        this.setFilters();
        this.setFavorites();
        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }

    private setWhatToShow() {
        this.showSpreadOffset = this.offsetsManager.showSpreadOffset;
        this.showSpreadWidth = this.offsetsManager.showSpreadWidth;
        this.showSecondSpreadOffset = this.offsetsManager.showSecondSpreadOffset;
        this.showSecondSpreadWidth = this.offsetsManager.showSecondSpreadWidth;
        this.showProtectiveOptionOffset = this.offsetsManager.showProtectiveOptionOffset;
        this.showSecondProtectiveOptionOffset = this.offsetsManager.showSecondProtectiveOptionOffset;
        this.showSingleValue = this.offsetsManager.showSingleValue;
        this.showImpliedVolatility = this.offsetsManager.showImpliedVolatility;
        this.showZonesGridArrow= this.offsetsManager.showZonesGridArrow;
    }

    private setWhenAndHowToShow() {
        this.showOffsets = this.offsetsManager.showOffsets;
        this.showMode = this.offsetsManager.showMode;
    }

    private setFilters() {
        this.spreadOffsetFilter = this.offsetsManager.spreadOffsetFilter;
        this.spreadWidthFilter = this.offsetsManager.spreadWidthFilter;
    }

    private setFavorites() {
        const json = this.offsetsManager.favoriteAdjustments;
        let obj : FavoriteAdjustment[] = JSON.parse(json) || [];
        this.favoriteAdjustments = obj || [];
    }

    saveSettings() {
        this.saveWhatToShow();
        this.saveWhenAndHowToShow();
        this.saveFilters();
        this.saveFavorites();
        this.close();
        this.settingsSaved$.emit();
    }

    private saveWhatToShow() {
        this.offsetsManager.showSpreadOffset = this.showSpreadOffset;
        this.offsetsManager.showSpreadWidth = this.showSpreadWidth;
        this.offsetsManager.showSecondSpreadOffset = this.showSecondSpreadOffset;
        this.offsetsManager.showSecondSpreadWidth = this.showSecondSpreadWidth;
        this.offsetsManager.showProtectiveOptionOffset = this.showProtectiveOptionOffset;
        this.offsetsManager.showSecondProtectiveOptionOffset = this.showSecondProtectiveOptionOffset;
        this.offsetsManager.showSingleValue = this.showSingleValue;
        this.offsetsManager.showImpliedVolatility = this.showImpliedVolatility;
        this.offsetsManager.showZonesGridArrow = this.showZonesGridArrow;
    }

    private saveWhenAndHowToShow() {
        this.offsetsManager.showMode = this.showMode;
        this.offsetsManager.showOffsets = this.showOffsets;
    }

    private saveFilters() {
        this.offsetsManager.spreadOffsetFilter = this.spreadOffsetFilter;
        this.offsetsManager.spreadWidthFilter = this.spreadWidthFilter;
    }

    private saveFavorites() {
        const favs = JSON.stringify(this.favoriteAdjustments || []);
        this.offsetsManager.favoriteAdjustments = favs;
    }

    @DetectMethodChanges()
    close() {
        this.visible = false;
    }

    @DetectMethodChanges()
    addFavoriteAdjustment() {
        this.favoriteAdjustments.push({});
    }

    @DetectMethodChanges()
    removeFavoriteAdjustment(fav: FavoriteAdjustment) {
        const number = this.favoriteAdjustments.indexOf(fav);
        if (number<0) {
            return;
        }
        this.favoriteAdjustments.splice(number, 1);
    }

    onValueChanged($event: any) {
        console.log($event);
    }
}
