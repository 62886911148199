import { CashFlowStrategy } from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import { makeOptionTicker, OptionType, parseOptionTicker } from "projects/shared-components/options-common/options.model";
import { CashFlowStrategyRole, GetOptionChainShellResponse, OptionExpirationDescriptor } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { getNearestStrike, isVoid } from "projects/shared-components/utils";
import { BeforePositionDto } from "../../model/BeforePositionDto";
import { OptionsChainService } from "projects/shared-components/option-chains.service";


export class BeforePositionModel {
 
   constructor(private readonly _chain: GetOptionChainShellResponse) {

      if (_chain.expirations.length === 0) {
         throw new Error('Empty chain');
      }

      this.qty = undefined;
      this.type = undefined;
      this.role = undefined;

   }

   //
   private readonly _dependentPositions: BeforePositionModel[] = [];

   //
   strategy: CashFlowStrategy;

   //
   isDependent = false;

   //
   get expirationsList(): OptionExpirationDescriptor[] {
      return this._chain ? this._chain.expirations : [];
   }

   //
   selectedExpiration: OptionExpirationDescriptor;

   //
   get underlyingSymbol(): string {
      return this.selectedExpiration ? this.selectedExpiration.underlyingSymbol : null;
   }

   //
   get expirationTicker(): string {
      return this.selectedExpiration ? this.selectedExpiration.expirationTicker : null;
   }

   //
   get side(): 'Long' | 'Short' | 'Flat' {
      if (isVoid(this.qty)) {
         return 'Flat';
      }

      return this.qty > 0 ? 'Long' : 'Short';
   }

   //
   qty = 0;

   //
   type: 'Call' | 'Put';

   //
   strike: number;

   //
   get expirationDate(): string {

      if (isVoid(this.selectedExpiration)) {
         return undefined;
      }

      return this.selectedExpiration.dateWithDaysToExpiration;

   }

   //
   role: CashFlowStrategyRole = 'N/A' as any;

   //
   onExpirationChanged(exp: OptionExpirationDescriptor) {
      this.selectedExpiration = exp;

      this._dependentPositions.forEach(x => x.selectedExpiration = exp);

   }

   //
   getTicker() {
      if (!this.selectedExpiration) {
         return undefined;
      }

      const expStyle = this.selectedExpiration.underlyingSymbol === 'SPX' ? 'European' : 'American';

      const ticker = makeOptionTicker(
         this.selectedExpiration, 
         OptionType[this.type], 
         this.strike, 
         expStyle
      );

      return ticker;
   }

   //
   addDependentPosition(position:BeforePositionModel) {
      position.isDependent = true;
      this._dependentPositions.push(position);
   }

   //
   validate(): boolean {
      if (isVoid(this.strategy)) {
         return false;
      }
      
      if (isVoid(this.expirationTicker)) {
         return false;
      }
     
      if (isVoid(this.underlyingSymbol)) {
         return false;
      }

      if (isVoid(this.selectedExpiration)) {
         return false;
      }

      if (isVoid(this.type)) {
         return false;
      }

      if (isVoid(this.strike)) {
         return false;
      }
      
      return true;
   }

   //
   asDto(): BeforePositionDto {
      
      const dto = {
         qty: this.qty,
         role: this.role,
         strategy: this.strategy,
         ticker: this.getTicker()
      } as BeforePositionDto;

      return dto;
   }

   static fromDto(dto: BeforePositionDto, chain: GetOptionChainShellResponse): BeforePositionModel {
      const model = new BeforePositionModel(chain);

      const ticker = parseOptionTicker(dto.ticker);

      model.strategy = dto.strategy;
      model.role = dto.role;
      model.type = ticker.type;
      model.qty = dto.qty;

      const expiration = chain.expirations.find(x => x.optionExpirationDate === ticker.expiration);

      model.selectedExpiration = expiration;
      model.strike = ticker.strike;

      return model;
   }
}
 