import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {StrategyDto} from '../../dtos/strategy-dto.class';

export class CreateStrategy implements ProtocolCommand {
  constructor(public readonly strategies: StrategyDto[]) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateStrategy`;
  }
}
