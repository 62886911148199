<div class="root-container parameters">

    <fieldset class="expirations">

        <legend class="colorcode-expirations">Expirations</legend>

        <!-- Exp. to look forward -->
        <div [class.different]="this.settings.isDifferentFromTemplate()"
             class="parameter"
        >
            <p class="part label">Exp. to Look Forward</p>
            <div class="part input">
                <dx-number-box
                        (onValueChanged)="this.settings.onParameterChanged($event, 'expirationsToLookForward')"
                        [(value)]="this.settings.expirationsToLookForward"
                        [disabled]="this.disabled"
                        [min]="0"
                        [showClearButton]="true"
                        [showSpinButtons]="true">
                </dx-number-box>
            </div>
        </div>

        <!-- Custom Dates -->
        <div class="parameter custom-dates">

            <fieldset>

                <legend>Custom Dates</legend>
                <p class="part label add-button">
                    <dx-button (onClick)="this.settings.addCustomDate()"
                               [disabled]="this.disabled"
                               text="Add Date"
                               width="100%"></dx-button>
                </p>
                <div class="part input">
                    <div *ngFor="let cd of this.settings.customDates; let i=index" class="custom-date">
                        <div (click)="this.settings.removeCustomDate(i)" class="part close-icon">
                            <i class="fas fa-times"></i>
                        </div>
                        <div class="part date-selector">
                            <dx-select-box
                                    (onValueChanged)="this.settings.onParameterChanged($event, 'customDates')"
                                    [(value)]="cd.value"
                                    [disabled]="this.disabled"
                                    [displayExpr]="'dateWithDaysToExpiration'"
                                    [items]="this.settings.expirations"
                                    [valueExpr]="'optionExpirationDate'">
                            </dx-select-box>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

    </fieldset>

</div>
