import { NgModule } from '@angular/core';
import { EditStrategyDialogComponent } from './edit-strategy-dialog.component';
import { LimitParametersViewComponent } from './parameters-controls/limit/limit-parameters-view.component';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';
import { AdvancedScheduleDialogComponent } from './advanced-schedule-dialog/advanced-schedule-dialog.component';
import { BullBearParametersViewComponent } from './parameters-controls/bullbear/bullbear-parameters-view.component';
import { FusionParametersViewComponent } from './parameters-controls/disposition/fusion/fusion-parameters-view.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { FlagshipParametersViewComponent } from './parameters-controls/flagships/flagship-parameters-view.component';
import { TrendLimitParametersViewComponent } from './parameters-controls/trend/trend-limit/trendlimit-parameters-view.component';
import { TradeTheLineParametersViewComponent } from './parameters-controls/trend/trade-the-line/tradetheline-parameters-view.component';
import { DynamicHedgeParametersViewComponent } from './parameters-controls/hedging/dynamic-hedge/dynamic-hedge-parameters-view.component';
import { FreezeHedgeParametersViewComponent } from './parameters-controls/hedging/freeze-hedge/freeze-hedge-parameters-view.component';
import { HedgeParametersViewComponent } from './parameters-controls/hedging/hedge/hedge-parameters-view.component';
import { DevExtremeModule } from '../devextreme.module';
import { RemoteAlgoParametersViewComponent } from './parameters-controls/remote/remote-algo-parameters-view.component';
import { DateTimePickerModule } from '../datetime-picker/datetime-picker.module';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';

@NgModule({
  imports: [
    CommonModule, 
    EtsSymbolPickerModule,
    AgGridModule.withComponents([]),
    DevExtremeModule,
    DateTimePickerModule,
    TimezonePickerModule
  ],
  exports: [EditStrategyDialogComponent],
  declarations: [
    EditStrategyDialogComponent,
    AdvancedScheduleDialogComponent,
    LimitParametersViewComponent,
    BullBearParametersViewComponent,
    FusionParametersViewComponent,
    FlagshipParametersViewComponent,
    TrendLimitParametersViewComponent,
    TradeTheLineParametersViewComponent,
    DynamicHedgeParametersViewComponent,
    FreezeHedgeParametersViewComponent,
    HedgeParametersViewComponent,
    RemoteAlgoParametersViewComponent
  ],
  providers: []
})
export class EtsEditStrategyDialogModule {
}
