// tslint:disable: no-string-literal
import { ParametersControlBase } from '../../parameters-control-base';
import { DispositionType } from '../../disposition-type';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { DetectParameterChanges, isTruthy } from 'projects/shared-components/utils';
import { ICommonDispositionParameters } from '../../common-disposition-parameters';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { BullBearParametersViewComponent } from './bullbear-parameters-view.component';

type BBMODE = 'BASIC' | 'TRAIL' | 'RESET';

export class BullBearAlgoParametersControl extends ParametersControlBase<BullBearParametersViewComponent> {

   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);

      for (let i = 1; i < 51; i++) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.maxLevelsItems.push(i);
         this.ctlTimesItems.push(i);
      }

      for (let i = 60; i < 110; i += 10) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.maxLevelsItems.push(i);
      }

      for (let i = 125; i < 225; i += 25) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.maxLevelsItems.push(i);
      }

      for (let i = 250; i < 550; i += 50) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.maxLevelsItems.push(i);
      }
   }

   private _symbol: TradingInstrument;
   private _multiplier: number;
   private _factor: number;
   private _bbMode: BBMODE;
   private _isDirectionalResetEnabled = false;
   private _isTrailFreqEnabled = false;
   private _isTrailValueEnabled = false;
   private _isWlTriggerEnabled = false;
   private _immediateStart = false;
   private _useFactor = false;
   private _useBracketing = false;
   private _ctlValue: number;
   private _ctlTimes: number;
   private _commonDispositionParameters: ICommonDispositionParameters;


   filter: number;
   profitTarget: number;
   tradeLevel: number;
   maxPosition: number;
   mode: 'BULL' | 'BEAR' | 'BULLBEAR';
   delayedStart: number;
   directionalReset = false;
   trendMPS = false;
   maxLevels: number;
   wlTrigger: number;
   trailValue: number;
   trailFreq: number;
   explicitWaterline: number;
   mmContractsAddedPer: number;
   mmMaxScaleLevels: number;
   mmContractsDecreasePer: number;
   mmIsRegress = false;
   mmResetOnMaxLevel = false;
   mmIsReverse = false;
   mmContractsDecreaseNum: number;
   isNetBettingMode = false;
   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isRenkoCounter = false;
   ctlTimesItems: number[] = [];
   ctlValueItems: number[] = [];
   mmMaxScaleLevelsItems: number[] = [];
   trailFreqItems: number[] = [];
   trailValueItems: number[] = [];
   wlTriggerItems: number[] = [];
   maxLevelsItems: number[] = [];
   maxPositionItems: number[] = [];
   factorItems: number[] = [];
   multiplierItems: number[] = [];
   tradeLevelItems: number[] = [];
   profitTargetItems: number[] = [];
   filterItems: number[] = [];
   strategyModeItems = ['BULL', 'BEAR', 'BULLBEAR'];
   bbModeItems = ['BASIC', 'RESET', 'TRAIL'];


   get symbol(): TradingInstrument {
      return this._symbol;
   }

   @DetectParameterChanges()
   set symbol(value: TradingInstrument) {
      if (this._symbol === value) {
         return;
      }
      const oldOne = this._symbol;
      this._symbol = value;
      const newOne = value;
      if (isTruthy(newOne)) {
         if (isTruthy(oldOne)) {
            if (newOne.underlying !== oldOne.underlying) {
               this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
            }
         } else {
            this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
         }
      }
   }

   get multiplier(): number {
      return this._multiplier;
   }

   @DetectParameterChanges()
   set multiplier(value: number) {
      if (this._multiplier === value) {
         return;
      }
      this._multiplier = value;
      this._updateMaxPositionsItems();
   }

   get factor(): number {
      return this._factor;
   }

   @DetectParameterChanges()
   set factor(value: number) {
      if (value === this._factor) {
         return;
      }
      this._factor = value;
      this._updateMaxPositionsItems();
   }

   get bbMode(): BBMODE {
      return this._bbMode;
   }

   @DetectParameterChanges()
   set bbMode(value: BBMODE) {
      if (this._bbMode === value) {
         return;
      }

      this._bbMode = value;

      if (!isTruthy(value)) {
         return;
      }

      if (value === 'BASIC') {
         this.isWlTriggerEnabled = false;
         this.isTrailValueEnabled = false;
         this.isTrailFreqEnabled = false;
         this.isDirectionalResetEnabled = false;
      } else if (value === 'RESET') {
         this.isWlTriggerEnabled = true;
         this.isTrailValueEnabled = false;
         this.isTrailFreqEnabled = false;
         this.isDirectionalResetEnabled = true;
      } else if (value === 'TRAIL') {
         this.isWlTriggerEnabled = true;
         this.isTrailValueEnabled = true;
         this.isTrailFreqEnabled = true;
         this.isDirectionalResetEnabled = false;
      }

   }

   get isDirectionalResetEnabled(): boolean {
      return this._isDirectionalResetEnabled;
   }

   @DetectParameterChanges()
   set isDirectionalResetEnabled(value: boolean) {
      if (this._isDirectionalResetEnabled === value) {
         return;
      }
      this._isDirectionalResetEnabled = value;
      if (!value) {
         this.directionalReset = false;
      }
   }

   get isTrailFreqEnabled(): boolean {
      return this._isTrailFreqEnabled;
   }

   @DetectParameterChanges()
   set isTrailFreqEnabled(value: boolean) {
      if (this._isTrailFreqEnabled === value) {
         return;
      }
      this._isTrailFreqEnabled = value;
      if (!value) {
         this.trailFreq = null;
      }
   }

   get isTrailValueEnabled(): boolean {
      return this._isTrailValueEnabled;
   }

   @DetectParameterChanges()
   set isTrailValueEnabled(value: boolean) {
      if (this._isTrailValueEnabled === value) {
         return;
      }
      this._isTrailValueEnabled = value;
      if (!value) {
         this.trailValue = null;
      }
   }

   get isWlTriggerEnabled(): boolean {
      return this._isWlTriggerEnabled;
   }

   @DetectParameterChanges()
   set isWlTriggerEnabled(value: boolean) {
      if (this._isWlTriggerEnabled === value) {
         return;
      }
      this._isWlTriggerEnabled = value;
      if (!value) {
         this.wlTrigger = null;
      }
   }

   get immediateStart(): boolean {
      return this._immediateStart;
   }

   @DetectParameterChanges()
   set immediateStart(value: boolean) {
      if (this._immediateStart === value) {
         return;
      }
      this._immediateStart = value;
      if (value) {
         this.delayedStart = null;
      }
   }

   get useFactor(): boolean {
      return this._useFactor;
   }

   @DetectParameterChanges()
   set useFactor(value: boolean) {
      if (this._useFactor === value) {
         return;
      }
      this._useFactor = value;
      if (!value) {
         this.factor = null;
         this.maxPosition = null;
         this.trendMPS = false;
         this.maxLevels = null;
         this.profitTarget = null;
         this.tradeLevel = null;
      }
   }

   get useBracketing(): boolean {
      return this._useBracketing;
   }

   @DetectParameterChanges()
   set useBracketing(value: boolean) {
      if (this._useBracketing === value) {
         return;
      }
      this._useBracketing = value;
      if (!value) {
         this.ctlValue = null;
         this.ctlTimes = null;
         this.isRenkoCounter = false;
      }
   }

   get ctlValue(): number {
      return this._ctlValue;
   }

   @DetectParameterChanges()
   set ctlValue(value: number) {
      if (this._ctlValue === value) {
         return;
      }
      this._ctlValue = value;
      if (isTruthy(this._ctlValue)) {
         if (!this.useBracketing) {
            this.useBracketing = true;
         }
      }
   }

   get ctlTimes(): number {
      return this._ctlTimes;
   }

   @DetectParameterChanges()
   set ctlTimes(value: number) {
      if (this._ctlTimes === value) {
         return;
      }
      this._ctlTimes = value;
      if (isTruthy(value)) {
         if (!this.useBracketing) {
            this.useBracketing = true;
         }
      }
   }

   get algoId(): string {
      return '52e421a5-0cdd-4c84-8430-b282b55ef9a8';
   }


   @DetectParameterChanges()
   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean) {
      if (!parameters) {
         return;
      }
      switch (parameters.dispositionType) {
         case DispositionType.NetBetting: {
            this.symbol = parameters.symbol;
            this.isNetBettingMode = true;
            break;
         }
         case DispositionType.TheBlend: {
            break;
         }
         case DispositionType.Fusion: {
            break;
         }
      }
      this._commonDispositionParameters = parameters;
   }

   
   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!isTruthy(this.symbol)) {
         errors.push('Symbol is required');
      }

      if (!isTruthy(this.bbMode)) {
         errors.push('Pick BBMode');
      }

      if (!isTruthy(this.mode)) {
         errors.push('Pick Strategy Mode');
      }

      if (this.bbMode === 'TRAIL' || this.bbMode === 'RESET') {
         if ((this.wlTrigger || 0) <= 0) {
            errors.push('Trigger should be greater than 0');
         }

         if (this.bbMode === 'TRAIL') {
            if ((this.trailValue || 0) <= 0) {
               errors.push('Please specify correct Trail Value');
            }
         }
      }

      if (this.immediateStart) {
         if ((this.explicitWaterline || 0) <= 0) {
            errors.push('Waterline value must be provided when "Immediate Start" is checked!');
         }
      }

      if (this.useFactor) {
         const passed: boolean = (((this.tradeLevel || 0) > 0 &&
            this.factor || 0) > 0 && this.maxPosition || 0) > 0;

         if (!passed) {
            errors.push('Please complete entering all necessary parameters to use position sizing (F, TL, etc.)');
         }
      }

      if ((this.maxLevels || 0) > 0) {
         if ((this.profitTarget || 0) <= 0) {
            errors.push('Please specify Trailing PT parameter');
         }
      }

      if (this.mmIsReverse) {
         if ((this.mmContractsDecreaseNum || 0) === 0) {
            errors.push('Decrease Contracts is required with reverse MM');
         }

         if ((this.mmContractsDecreasePer || 0) === 0) {
            errors.push('Contracts Decrease Per is rqeuired with reverse MM');
         }

         if (!this.mmIsRegress) {
            const mult = this.multiplier || 0;
            const ctsDecrNum = this.mmContractsDecreaseNum || 0;
            const maxScaleLvls = this.mmMaxScaleLevels || 0;

            if ((maxScaleLvls * ctsDecrNum) >= mult - 1 || maxScaleLvls === 0) {
               this._Toastr.warning('Minimum Mulitplier will be "1"!');
            }
         }
      }

      const needMAxScaleLevels = isTruthy(this.mmContractsDecreasePer)
         || isTruthy(this.mmContractsAddedPer);

      if (needMAxScaleLevels) {
         if ((this.mmMaxScaleLevels || 0) === 0) {
            errors.push('Max Scale Levels is required');
         }
      }

      if (this.useBracketing) {
         if (!isTruthy(this.ctlValue)) {
            errors.push('Bracketing mode requires CTL Value');
         }

         if (!isTruthy(this.ctlTimes)) {
            errors.push('Bracketing mode requires CTL Times');
         }
      }

      return errors;
   }

   
   @DetectParameterChanges()
   onCustomNumberCreated(data, collection: number[]) {
      const newItem = parseFloat(data.text);
      if (!isNaN(newItem)) {
         const isNew = collection.indexOf(newItem) === -1;
         data.customItem = newItem;
         if (isNew) {
            collection.push(newItem);
         }
      }
   }

   
   protected _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker : null;
      params['filter'] = isTruthy(this.filter) ? this.filter + '' : null;
      params['profittarget'] = isTruthy(this.profitTarget) ? this.profitTarget + '' : null;
      params['tradelevel'] = isTruthy(this.tradeLevel) ? this.tradeLevel + '' : null;
      params['multiplier'] = isTruthy(this.multiplier) ? this.multiplier + '' : null;
      params['factor'] = isTruthy(this.factor) ? this.factor + '' : null;
      params['maxposition'] = isTruthy(this.maxPosition) ? this.maxPosition + '' : null;
      params['mode'] = isTruthy(this.mode) ? this.mode : null;
      params['bbmode'] = isTruthy(this.bbMode) ? this.bbMode : null;
      params['immediatestart'] = isTruthy(this.immediateStart) ? this.immediateStart + '' : null;
      params['delayedstart'] = isTruthy(this.delayedStart) ? this.delayedStart + '' : null;
      params['usefactor'] = isTruthy(this.useFactor) ? this.useFactor + '' : null;
      params['directionalreset'] = isTruthy(this.directionalReset) ? this.directionalReset + '' : null;
      params['trendmps'] = isTruthy(this.trendMPS) ? this.trendMPS + '' : null;
      params['maxlevels'] = isTruthy(this.maxLevels) ? this.maxLevels + '' : null;
      params['wltrigger'] = isTruthy(this.wlTrigger) ? this.wlTrigger + '' : null;
      params['trailvalue'] = isTruthy(this.trailValue) ? this.trailValue + '' : null;
      params['trailfreq'] = isTruthy(this.trailFreq) ? this.trailFreq + '' : null;
      params['explicitwaterline'] = isTruthy(this.explicitWaterline) ? this.explicitWaterline + '' : null;
      params['mmcontractsaddedper'] = isTruthy(this.mmContractsAddedPer) ? this.mmContractsAddedPer + '' : null;
      params['mmmaxscalelevels'] = isTruthy(this.mmMaxScaleLevels) ? this.mmMaxScaleLevels + '' : null;
      params['mmcontractsdecreaseper'] = isTruthy(this.mmContractsDecreasePer) ? this.mmContractsDecreasePer + '' : null;
      params['mmisregress'] = isTruthy(this.mmIsRegress) ? this.mmIsRegress + '' : null;
      params['mmresetonmaxlevel'] = isTruthy(this.mmResetOnMaxLevel) ? this.mmResetOnMaxLevel + '' : null;
      params['mmisreverse'] = isTruthy(this.mmIsReverse) ? this.mmIsReverse + '' : null;
      params['mmcontractsdecreasenum'] = isTruthy(this.mmContractsDecreaseNum) ? this.mmContractsDecreaseNum + '' : null;
      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['usebracketing'] = isTruthy(this.useBracketing) ? this.useBracketing + '' : null;
      params['ctlvalue'] = isTruthy(this.ctlValue) ? this.ctlValue + '' : null;
      params['ctltimes'] = isTruthy(this.ctlTimes) ? this.ctlTimes + '' : null;
      params['isrenkocounter'] = isTruthy(this.isRenkoCounter) ? this.isRenkoCounter + '' : null;

      return params;
   }

   
   protected _SetParameters(dto: StrategyDto) {
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         const ticker = params['symbol'];
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         this.symbol = instrument;
      }
      if (isTruthy(params['filter'])) {
         this.filter = parseFloat(params['filter']);
      }
      if (isTruthy(params['profittarget'])) {
         this.profitTarget = parseFloat(params['profittarget']);
      }
      if (isTruthy(params['tradelevel'])) {
         this.tradeLevel = parseFloat(params['tradelevel']);
      }
      if (isTruthy(params['multiplier'])) {
         this.multiplier = parseInt(params['multiplier']);
      }
      if (isTruthy(params['factor'])) {
         this.factor = parseFloat(params['factor']);
      }
      if (isTruthy(params['maxposition'])) {
         this.maxPosition = parseInt(params['maxposition']);
      }
      if (isTruthy(params['mode'])) {
         this.mode = params['mode'];
      }
      if (isTruthy(params['bbmode'])) {
         this.bbMode = params['bbmode'];
      }
      if (isTruthy(params['immediatestart'])) {
         this.immediateStart = params['immediatestart'].toLowerCase() === 'true';
      }
      if (isTruthy(params['delayedstart'])) {
         this.factor = parseFloat(params['delayedstart']);
      }
      if (isTruthy(params['usefactor'])) {
         this.useFactor = params['usefactor'].toLowerCase() === 'true';
      }
      if (isTruthy(params['directionalreset'])) {
         this.directionalReset = params['directionalreset'].toLowerCase() === 'true';
      }
      if (isTruthy(params['trendmps'])) {
         this.trendMPS = params['trendmps'].toLowerCase() === 'true';
      }
      if (isTruthy(params['maxlevels'])) {
         this.maxLevels = parseInt(params['maxlevels']);
      }
      if (isTruthy(params['wltrigger'])) {
         this.wlTrigger = parseFloat(params['wltrigger']);
      }
      if (isTruthy(params['trailvalue'])) {
         this.trailValue = parseFloat(params['trailvalue']);
      }
      if (isTruthy(params['trailfreq'])) {
         this.trailFreq = parseFloat(params['trailfreq']);
      }
      if (isTruthy(params['explicitwaterline'])) {
         this.explicitWaterline = parseFloat(params['explicitwaterline']);
      }
      if (isTruthy(params['mmcontractsaddedper'])) {
         this.mmContractsAddedPer = parseFloat(params['mmcontractsaddedper']);
      }
      if (isTruthy(params['mmmaxscalelevels'])) {
         this.mmMaxScaleLevels = parseFloat(params['mmmaxscalelevels']);
      }
      if (isTruthy(params['mmcontractsdecreaseper'])) {
         this.mmContractsDecreasePer = parseFloat(params['mmcontractsdecreaseper']);
      }
      if (isTruthy(params['mmisregress'])) {
         this.mmIsRegress = params['mmisregress'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mmresetonmaxlevel'])) {
         this.mmResetOnMaxLevel = params['mmresetonmaxlevel'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mmisreverse'])) {
         this.mmIsReverse = params['mmisreverse'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mmcontractsdecreasenum'])) {
         this.mmContractsDecreaseNum = parseFloat(params['mmcontractsdecreasenum']);
      }
      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }
      if (isTruthy(params['usebracketing'])) {
         this.useBracketing = params['usebracketing'].toLowerCase() === 'true';
      }
      if (isTruthy(params['ctlvalue'])) {
         this.ctlValue = parseFloat(params['ctlvalue']);
      }
      if (isTruthy(params['ctltimes'])) {
         this.ctlTimes = parseFloat(params['ctltimes']);
      }
      if (isTruthy(params['isrenkocounter'])) {
         this.isRenkoCounter = params['isrenkocounter'].toLowerCase() === 'true';
      }
   }

   
   private _updateTickSizeDependentDropdowns(tickSize: number, precision: number): void {
      this.filter = null;
      this.profitTarget = null;
      this.tradeLevel = null;
      this.trailValue = null;
      this.trailFreq = null;
      this.wlTrigger = null;
      this.ctlValue = null;
      this.delayedStart = null;

      this.filterItems = [];
      this.profitTargetItems = [];
      this.tradeLevelItems = [];
      this.trailValueItems = [];
      this.trailFreqItems = [];
      this.wlTriggerItems = [];
      this.ctlValueItems = [];

      const formatter = new Intl.NumberFormat('en-IN', {
         minimumFractionDigits: precision,
         maximumFractionDigits: precision
      });

      for (let i = 1; i < 50; i++) {
         let step = i * tickSize;
         step = parseFloat(formatter.format(step));
         this.filterItems.push(step);
         this.profitTargetItems.push(step);
         this.tradeLevelItems.push(step);
         this.trailValueItems.push(step);
         this.trailFreqItems.push(step);
         this.wlTriggerItems.push(step);
         this.ctlValueItems.push(step);
      }
   }

   
   private _updateMaxPositionsItems(): void {
      if (!isTruthy(this.factor) || !isTruthy(this.multiplier)
         || this.multiplier === 0) {
         this.maxPosition = null;
         this.maxPositionItems = [];

         return;
      }

      const newItems = [];

      for (let i = 1; i < 50; i++) {
         if (this.factor === 1) {
            const item = i * this.multiplier;
            newItems.push(item);
         } else {
            const item = Math.pow(this.factor, i) * this.multiplier;
            newItems.push(item);
         }
      }

      this.maxPositionItems = newItems;
      this.maxPosition = null;
   }

   
   private _checkMaxPosition(): boolean {
      const correctMaxPosition: number[] = [];
      if (isTruthy(this.factor) && isTruthy(this.multiplier)) {
         for (let i = 0; i < 100; i++) {
            if (this.factor === 1) {
               const val = this.multiplier * (i + 1);
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            } else {
               const pow = Math.pow(this.factor, i);
               const val = this.multiplier * pow;
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            }
         }
      }

      const curMP = this.maxPosition || 0;
      let mpIsCorrect = false;
      for (const mp of correctMaxPosition) {
         if (mp === curMP) {
            mpIsCorrect = true;
            break;
         }
      }
      return mpIsCorrect;
   }

   onStrategyTerminalChanged(): void {
   }
}
