import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AutomationCpMessageBusService } from '../services/automation-cp-message-bus.service';

@Component({
   selector: 'ets-automation-cp-header',
   templateUrl: './header.component.html',
   styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
   constructor(
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   @Output()
   submit = new EventEmitter();

   ngOnInit(): void { }

   onSubmit() {
      this.submit.emit();
   }
}
