import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, RowNode, RowSelectedEvent } from 'ag-grid-community';
import { SessionHistoryComponent } from './session-history.component';
import { SessionHistoryAggregatedRowHeaderRenderer } from './session-history-aggregated-row-header-renderer.component';
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { centeredColumnDef, defaultMoneyCellDefinition, defaultLoadingOverlayTemplate, defaultTimestampFormatNoMs } from 'projects/shared-components/ag-grid-contrib';
import { SessionHistoryDataDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';


export function getHistoryDataGridOptions(this: SessionHistoryComponent) {
   
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Terminal',
            field: 'terminalCode',
            enableRowGroup: true,
            rowGroup: true,
            hide: true
         },
         {
            headerName: 'Started',
            field: 'startDate',
            valueFormatter: (params: CellClassParams) => {
               const sessionData: SessionHistoryDataDto = params.data;
               const frmtTime = this.timestampsService.getZonedFormattedDateTime(
                  sessionData.startDate,
                  defaultTimestampFormatNoMs
               );
               return frmtTime;
            },
            hide: true
         },
         {
            headerName: 'Ended',
            field: 'endDate',
            valueFormatter: (params: CellClassParams) => {
               if (!params.value) {
                  return '< Active >';
               }
               
               const sessionData: SessionHistoryDataDto = params.data;
               const frmtTime = this.timestampsService.getZonedFormattedDateTime(
                  sessionData.endDate,
                  defaultTimestampFormatNoMs
               );
               return frmtTime;
            },
            sort: 'desc'
         },
         //
         Object.assign(
            {
               headerName: 'Session P&L',
               field: 'sessionPnL'
            },
            defaultMoneyCellDefinition
         ),
         //
         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accumulatedPnL'
            },
            defaultMoneyCellDefinition
         )
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      rowGroupPanelShow: 'never',

      groupUseEntireRow: true,

      suppressDragLeaveHidesColumns: true,

      suppressMakeColumnVisibleAfterUnGroup: false,

      suppressAggFuncInHeader: true,

      suppressCellSelection: true,

      frameworkComponents: {
         sessionHistoryGroupRowRenderer: SessionHistoryAggregatedRowHeaderRenderer
      },

      groupRowRendererParams: {
         innerRenderer: 'sessionHistoryGroupRowRenderer',
         suppressCount: true
      },

      onGridReady: args => this.onHistoryListGridReady(args),
      

      onFirstDataRendered: (args: FirstDataRenderedEvent) => args.api.sizeColumnsToFit(),

      postSort: (rowNodes: RowNode[]) => {
         let nextInsertPos = 0;
         for (let i = 0; i < rowNodes.length; i++) {
            const sessionData: SessionHistoryDataDto = rowNodes[i].data;
            if (sessionData && !sessionData.endDate) {
               rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
               nextInsertPos++;
            }
         }
      },

      onRowSelected: (args: RowSelectedEvent) => {
         
         if (!args.node || args.node.group || !args.node.isSelected()) {
            return;
         }

         this.onSessionRowSelected(args.node.data);
         
      },

      getRowNodeId: (rowData: SessionHistoryDataDto) => {
         return rowData.sessionId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         
         if (this.securityContext.resetSession) {
            menu.push(
               {
                  name: 'Reset',
                  disabled: !params.node || !params.node.data || !!params.node.data.endDate,
                  action: () => this.resetSession(params.node.data)
               }
            );
         }
         
         if (this.securityContext.deleteSession) {
            menu.push(
               {
                  name: 'Delete',
                  disabled: !params.node || !params.node.data || !params.node.data.endDate,
                  action: () => this.deleteSession(params.node.data)
               },
               {
                  name: 'Delete All Finished',
                  action: () => this.deleteAllFinishedSessions()
               }
            );
         }

         menu.push({
            name: 'Toggle "Show All Data"',
            checked: this.showAllData,
            action: () => this.toggleShowAllData()
         });

         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );

         return menu;
      }
   } as GridOptions;
}
