import { AfterViewInit, Component, OnDestroy, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { getHistoryDataGridOptions as getHistoryDataGridOptions } from './sessions-list-gird-model';
import { GridReadyEvent, SelectionChangedEvent, GridOptions } from 'ag-grid-community';
import { ToastInjector, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { getTradesGridModel } from './history-trades-grid-model';
import { getSnapshotsGridModel } from './history-snapshots-grid-model';
import { getMessagesGridModel } from './history-messages-grid-model';
import { takeUntil } from 'rxjs/operators';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { StrategyLogMessageDto } from 'projects/shared-components/shell-communication/dtos/strategy-log-message-dto.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { DeleteSessionHistory } from 'projects/shared-components/shell-communication/operations/session-data/delete-session-history.class';
import {
   GetSessionHistoryData
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-history-data.class';
import {
   GetSessionStrategyInfosBySessionId
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-infos-by-session-id.class';
import {
   GetSessionStrategyTrades
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-trades.class';
import {
   GetSessionStrategySnapshots
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-snapshots.class';
import {
   GetSessionStrategyMessages
} from 'projects/shared-components/shell-communication/operations/session-data/get-session-strategy-messages.class';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { ResetSession } from 'projects/shared-components/shell-communication/operations/session-data/reset-session.class';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { SessionCreatedDto, SessionEndedDto, SessionHistoryDataDto, SessionHistoryStrategyInfoDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { getSessionHistoryBucketItemsGridOptions, getSessionHistoryComboGroupsGridOptions, getSessionHistoryCombosGridOptions, getSessionHistoryPortfoliosGridOptions } from './session-details-grid-options';
import * as Enumerable from 'linq';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { isNullOrUndefined } from 'util';
import { BucketType } from 'projects/shared-components/portfolios/portfolios.model';

export interface SessionHistoryConfig {
   bucketType: BucketType;
   data: Partial<SessionHistoryStrategyInfoDto>;
}

interface SessionHistorySecurityContext {
   sessionsList: boolean;
   resetSession: boolean;
   deleteSession: boolean;
   sessionDetails: boolean;
   sessionStrategyDetails: boolean;
}

@Component({
   selector: 'ets-session-history',
   templateUrl: 'session-history.component.html',
   styleUrls: ['session-history.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
   
   constructor(
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _messageBus: MessageBusService,
      private _accessControlSvc: AccessControlService,
      private _timestampsService: TimestampsService,
      private _changeDetector: ChangeDetectorRef,
      private _sessionService: SessionService,
      loggerService: LoggerService,
   ) {
      this._logger = loggerService.createLogger('SessionHistoryComponent');
   }

   private _logger: Logger;

   
   private _config: SessionHistoryConfig;
   private _historyListGrid: GridReadyEvent;
   private _portfoliosGrid: GridReadyEvent;
   private _combosGrid: GridReadyEvent;
   private _comboGroupsGrid: GridReadyEvent;
   private _bucketItemsGrid: GridReadyEvent;
   private _tradesGrid: GridReadyEvent;
   private _snapshotsGrid: GridReadyEvent;
   private _messagesGrid: GridReadyEvent;

   private _selectedSessionItems: Enumerable.IEnumerable<SessionHistoryStrategyInfoDto> = Enumerable.empty();

   private _selectedStrategyTradingData: {
      trades: TradeDto[];
      snapshots: OrderStateSnapshotDto[];
      messages: StrategyLogMessageDto[];
   };

   private _unsubscriber = new Subject<any>();
   private _sessionsList: SessionHistoryDataDto[];
   private _panelWasShown = false;

   @Input() isPanelContext = false; 

   securityContext: SessionHistorySecurityContext;
   
   
   historyDataGridOptions: GridOptions;
   portfoliosGridOptions: GridOptions;
   combosGridOptions: GridOptions;
   comboGroupsGridOptions: GridOptions;
   bucketItemsGridOptions: GridOptions;
   
   tradesGridOptions: GridOptions;
   snapshotsGridOptions: GridOptions;
   messagesGridOptions: GridOptions;
   
   selectedStrategyInfo: SessionHistoryStrategyInfoDto;
   
   historyListSectionSize;
   sessionBucketsListSectionSize;
   sessionDetailsSectionSize;
   bucketSectionSize;
   tradingDataSectionSize;
   bucketItemsSectionSize;
   
   tabs = [
      { text: 'Trades' },
      { text: 'Snapshots' },
      { text: 'Messages' }
   ];
   
   
   selectedTabIndex = 0;
   showAllData = false;

   get sessionService(): SessionService {
      return this._sessionService;
   }

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   get showPortfolios(): boolean {
      if (!this._config) {
         return true;
      }

      if (this._config.bucketType === 'Portfolio') {
         return true;
      }

      return false;
   }

   get showCombos(): boolean {
      if (!this._config) {
         return true;
      }

      if (this.showPortfolios || this._config.bucketType === 'Combo') {
         return true;
      }

      return false;
   }

   get showComboGroups(): boolean {
      if (!this._config) {
         return true;
      }

      if (this.showCombos || this._config.bucketType === 'ComboGroup') {
         return true;
      }

      return false;
   }


   ngOnInit() {
      const isAvailable = (id) =>
         this._accessControlSvc.isSecureElementAvailable(id);

      this.securityContext = {
         sessionsList: isAvailable('f6e9a358-5284-4c17-b8fb-888a3eba7ae9'),
         resetSession: isAvailable('ff3f8c3e-8eb7-4907-877d-19b639766e01'),
         deleteSession: isAvailable('5933c93a-f7a5-4312-a28e-7ee8badf5091'),
         sessionDetails: isAvailable('9e35c5c9-dd09-46ab-a99a-e273663a2525'),
         sessionStrategyDetails: isAvailable('4170d964-bd8b-4069-ad77-993490f1e7f0')
      };

      this.historyDataGridOptions = getHistoryDataGridOptions.bind(this)();
      this.portfoliosGridOptions = getSessionHistoryPortfoliosGridOptions.bind(this)();
      this.combosGridOptions = getSessionHistoryCombosGridOptions.bind(this)();
      this.comboGroupsGridOptions = getSessionHistoryComboGroupsGridOptions.bind(this)();
      this.bucketItemsGridOptions = getSessionHistoryBucketItemsGridOptions.bind(this)();

      this.tradesGridOptions = getTradesGridModel.bind(this)();
      this.snapshotsGridOptions = getSnapshotsGridModel.bind(this)();
      this.messagesGridOptions = getMessagesGridModel.bind(this)();
   }


   ngAfterViewInit() { this._changeDetector.detach(); }


   @DetectMethodChanges()
   onHidden(): void {
      this.resetState();
   }


   ngOnDestroy() {
      this._logger.debug('~');
   }


   onHistoryListGridReady(args: GridReadyEvent) {
      this._historyListGrid = args;
      this._historyListGrid.api.sizeColumnsToFit();
   }


   onBucketItemsGridReady(args: GridReadyEvent): void {
      this._bucketItemsGrid = args;
      this._bucketItemsGrid.api.sizeColumnsToFit();
   }
   

   onComboGroupsGridReady(args: GridReadyEvent): void {
      this._comboGroupsGrid = args;
      this._comboGroupsGrid.api.sizeColumnsToFit();
   }
   

   onCombosGridReady(args: GridReadyEvent): void {
      this._combosGrid = args;
      this._combosGrid.api.sizeColumnsToFit();
   }
   
   
   onPortfoliosGridReady(args: GridReadyEvent): void {
      this._portfoliosGrid = args;
      this._portfoliosGrid.api.sizeColumnsToFit();
   }

   
   onTradesGridReady(args: GridReadyEvent) {
      this._tradesGrid = args;
   }


   onSnapshotsGridReady(args: GridReadyEvent) {
      this._snapshotsGrid = args;
   }


   onMessagesGridReady(args: GridReadyEvent) {
      this._messagesGrid = args;

      if (this.isPanelContext) {
         setTimeout(() => this.onShown(), 0);
      }
   }


   @DetectMethodChanges()
   setSectionSize() {
      if (this._panelWasShown) {
         return;
      }

      this._panelWasShown = true;
      
      this.assignDefaultSectionSizes();

   }


   @DetectMethodChanges({isAsync: true})
   async selectTab($event: any): Promise<void> {
      if (this.selectedTabIndex === $event.itemIndex) {
         return;
      }

      this.selectedTabIndex = $event.itemIndex;
      
      if (this.selectedStrategyInfo) {
         let shouldLoadData = true;
         switch (this.selectedTabIndex) {
            case 0: {
               shouldLoadData =
                  this._selectedStrategyTradingData.trades.length === 0;
               break;
            }
            case 1: {
               shouldLoadData =
                  this._selectedStrategyTradingData.snapshots.length === 0;
               break;
            }
            case 2: {
               shouldLoadData =
                  this._selectedStrategyTradingData.messages.length === 0;
               break;
            }
         }
         if (shouldLoadData) {
            await this.loadTradingDataForSelectedStrategyInfo(this.selectedStrategyInfo);
         }
      }
   }


   @DetectMethodChanges({isAsync: true})
   async onHistoryListSelectionChanged(args: SelectionChangedEvent): Promise<void> {
      
      if (!this.securityContext.sessionDetails) {
         return;
      }

      const selectedRows = args.api.getSelectedRows();
      
      if (selectedRows.length > 0) {
         const selectedRow: SessionHistoryDataDto = selectedRows[0];
         await this.tryLoadSessionStrategyInfoes(selectedRow.sessionId);
      }
   }


   @DetectMethodChanges()
   onSessionRowSelected(data: SessionHistoryDataDto) {
      if (!data) {
         return;
      }

      this.tryLoadSessionStrategyInfoes(data.sessionId);
   }


   @DetectMethodChanges()
   onPortfolioRowSelected(data: any) {
      const portfolioId: string = data.portfolioId;
      
      if (!this._selectedSessionItems) {
         this._toastr.info('"Session Data" not loaded');
         return;
      }

      // set combos
      const portfolioItems = this._selectedSessionItems
                  .where(x => isNullOrUndefined(x.dispositionId) )
                  .where(x => x.portfolioId === portfolioId);
      
      const combos = portfolioItems
         .groupBy(x => x.comboId)
         .where(gr => gr.any(x => x.wasActiveInSession))
         .select(gr => {
            const item = {
               comboId: gr.key(),
               comboName: gr.first().comboName,
               portfolioId: gr.first().portfolioId,
               sessionPnL: gr.sum(x => x.sessionPnL),
               accumulatedPnL: gr.sum(x => x.accumulatedPnL)
            };
            
            return item;
         })
         .toArray();
      
      this._combosGrid.api.deselectAll();
      this._comboGroupsGrid.api.deselectAll();

      this._combosGrid.api.setRowData(combos);
      this._comboGroupsGrid.api.setRowData([]);

      this._bucketItemsGrid.api.setRowData(portfolioItems.toArray());
   }


   @DetectMethodChanges()
   onComboRowSelected(data: any) {
      const portfolioId: string = data.portfolioId;
      const comboId: string = data.comboId;
      
      if (!this._selectedSessionItems) {
         this._toastr.info('"Session Data" not loaded');
         return;
      }

      // set combos
      const comboItems = this._selectedSessionItems
            .where(x => isNullOrUndefined(x.dispositionId) )
            .where(x => {
               return x.portfolioId === portfolioId && x.comboId === comboId;
            });
      
      const comboGroups = comboItems
         .groupBy(x => x.comboGroupId)
         .where(gr => gr.any(x => x.wasActiveInSession))
         .select(gr => {
            const item = {
               comboGroupId: gr.key(),
               comboGroupName: gr.first().comboGroupName,
               comboId: gr.first().comboId,
               portfolioId: gr.first().portfolioId,
               sessionPnL: gr.sum(x => x.sessionPnL),
               accumulatedPnL: gr.sum(x => x.accumulatedPnL)
            };
            
            return item;
         })
         .toArray();

      this._comboGroupsGrid.api.deselectAll();

      this._comboGroupsGrid.api.setRowData(comboGroups);
      
      const arrComboItems = comboItems.toArray();
      this._bucketItemsGrid.api.setRowData(arrComboItems);
   }

   
   @DetectMethodChanges()
   onComboGroupRowSelected(data: any) {
      const portfolioId: string = data.portfolioId;
      const comboId: string = data.comboId;
      const comboGroupId: string = data.comboGroupId;
      
      if (!this._selectedSessionItems) {
         this._toastr.info('"Session Data" not loaded');
         return;
      }

      const comboGroupItems = this._selectedSessionItems
         .where(x => isNullOrUndefined(x.dispositionId) )
         .where(x => {
            if (this.showAllData) {
               return true;
            }
            return x.wasActiveInSession;
         })
         .where(x => {
            return x.comboGroupId === comboGroupId
               && x.comboId === comboId
               && x.portfolioId === portfolioId;
         })
         .toArray();

      this._bucketItemsGrid.api.setRowData(comboGroupItems);
   }

   
   @DetectMethodChanges({isAsync: true})
   async onBucketItemRowSelected(data: SessionHistoryStrategyInfoDto) {
      
      if (!data) {
         this._toastr.info('Session Detail Row not Selected');
         return;
      }


      if (!this.securityContext.sessionStrategyDetails) {
         return;
      }

   
      this._selectedStrategyTradingData = { messages: [], snapshots: [], trades: [] };
      
      this.selectedStrategyInfo = data;
      
      await this.loadTradingDataForSelectedStrategyInfo(data);
   }
      
   
   async loadMoreTradingData(batchSize: number, dataType: 'trades' | 'snapshots' | 'messages'): Promise<void> {
      
      if (!this.selectedStrategyInfo) {
         this._toastr.info('Select a strategy first', 'Strategy Not Selected');
         return;
      }

      let historyStart = 0;

      switch (dataType) {
         case 'trades': {
            const tradesContainer = this._selectedStrategyTradingData.trades;
            if (tradesContainer.length > 0) {
               const lastTrade = tradesContainer[tradesContainer.length - 1];
               historyStart = lastTrade.seqNum;
            }
            await this.tryLoadSessionStrategyTrades(
               this.selectedStrategyInfo.sessionHistoryId,
               this.selectedStrategyInfo.strategyId,
               this.selectedStrategyInfo.strategyId === EtsConstants.strategies.manualStrategyId
                  ? this.selectedStrategyInfo.ticker
                  : null,
               historyStart,
               batchSize,
               this.selectedStrategyInfo.portfolioId,
               this.selectedStrategyInfo.comboId,
               this.selectedStrategyInfo.comboGroupId
            );
            break;
         }
         case 'snapshots': {
            const snapshotsContainer = this._selectedStrategyTradingData.snapshots;
            if (snapshotsContainer.length > 0) {
               const lastTrade = snapshotsContainer[snapshotsContainer.length - 1];
               historyStart = lastTrade.seqNum;
            }
            await this.tryLoadSessionStrategySnapshots(
               this.selectedStrategyInfo.sessionHistoryId,
               this.selectedStrategyInfo.strategyId,
               this.selectedStrategyInfo.strategyId === EtsConstants.strategies.manualStrategyId
                  ? this.selectedStrategyInfo.ticker
                  : null,
               historyStart,
               batchSize,
               this.selectedStrategyInfo.portfolioId,
               this.selectedStrategyInfo.comboId,
               this.selectedStrategyInfo.comboGroupId
            );
            break;
         }
         case 'messages': {
            break;
         }
      }
   }

   
   @DetectMethodChanges()
   toggleSectionFullWidth(section?: 'history' | 'strategy' | 'tradingData'): void {

      const isAnySectionFullWidth = this.historyListSectionSize === 100 
                                    || this.sessionBucketsListSectionSize === 100 
                                    || this.sessionDetailsSectionSize === 100;

      if (isAnySectionFullWidth) {

         this.assignDefaultSectionSizes();

      } else {

         switch (section) {
            case 'history': {
               this.historyListSectionSize = 100;
               this.sessionBucketsListSectionSize = 0;
               this.sessionDetailsSectionSize = 0;
               break;
            }
            case 'strategy': {
               this.historyListSectionSize = 0;
               this.sessionBucketsListSectionSize = 100;
               this.sessionDetailsSectionSize = 0;
               break;
            }
            case 'tradingData': {
               this.historyListSectionSize = 0;
               this.sessionBucketsListSectionSize = 0;
               this.bucketItemsSectionSize = 0;
               this.sessionDetailsSectionSize = 100;
               this.tradingDataSectionSize = 100;
               break;
            }
         }

      }
   }


   @DetectMethodChanges()
   toggleShowAllData() {
      this.showAllData = !this.showAllData;
      
      const selectedRows = this._historyListGrid.api.getSelectedRows();
      
      if (selectedRows.length === 0) {
         return;
      }
      
      const selectedRow = selectedRows[0];

      this.onSessionRowSelected(selectedRow);
   }

   
   @DetectMethodChanges({isAsync: true})
   async onShown(config?: any) {
      // because of the race conditions during data binding stage, we have to
      // set popup parents programmatically here for all grids

      this.resetState();

      this._config = config;

      // const parent: HTMLElement = document.getElementById('session-history-panel');
      
      // this._historyListGrid.api.setPopupParent(parent);
      // this._portfoliosGrid.api.setPopupParent(parent);
      // this._combosGrid.api.setPopupParent(parent);
      // this._comboGroupsGrid.api.setPopupParent(parent);
      // this._bucketItemsGrid.api.setPopupParent(parent);
      // this._tradesGrid.api.setPopupParent(parent);
      // this._snapshotsGrid.api.setPopupParent(parent);
      // this._messagesGrid.api.setPopupParent(parent);

      this.subscribeToMessages();
      await this.tryLoadHistoryListData();
   }

   
   @DetectMethodChanges({isAsync: true})
   async resetSession(row: SessionHistoryDataDto): Promise<void> {
      
      if (!row) {
         return;
      }

      const cmd = new ResetSession([row.terminalId]);
      this._historyListGrid.api.showLoadingOverlay();

      try {
         
         await this._shellClient.processCommand(cmd);

      } catch (e) {

         this._toastr.error('"Reset Session" command completed with errors');
         const data = { error: e.stack || e, terminal: row.terminalId };
         this._logger.error('resetSession()', data);

      } finally {
         
         this._historyListGrid.api.hideOverlay();

      }
   }

   
   @DetectMethodChanges({isAsync: true})
   async deleteSession(row: SessionHistoryDataDto): Promise<void> {
      
      if (!row) {
         return;
      }

      const cmd = new DeleteSessionHistory([row.sessionId]);
      
      this._historyListGrid.api.showLoadingOverlay();

      try {
         
         await this._shellClient.processCommand(cmd);
         
         const ix = this._sessionsList.findIndex(x => x.sessionId === row.sessionId);
         
         if (ix >= 0) {
            this._sessionsList.splice(ix, 1);
         }
         
         this._historyListGrid.api.applyTransaction({remove: [row]});
         this._toastr.info('"Delete Session" operation completed');

      } catch (e) {

         this._toastr.error('"Delete Session" command completed with errors');
         const data = { error: e.stack || e, terminal: row.sessionId };
         this._logger.error('deleteSession()', data);

      } finally {

         this._historyListGrid.api.hideOverlay();

      }
   }
   
   
   @DetectMethodChanges({isAsync: true})
   async deleteAllFinishedSessions(): Promise<void> {
      const sessions = this._sessionsList || [];
      
      if (sessions.length === 0) {
         return;
      }

      const sessionsToDelete = sessions.filter(x => !!x.endDate);

      if (sessionsToDelete.length === 0) {
         this._toastr.info('No finished sessions to delete');
         return;
      }

      const sessionIds = sessionsToDelete.map(x => x.sessionId);
      const cmd = new DeleteSessionHistory(sessionIds);
      
      this._historyListGrid.api.showLoadingOverlay();

      try {

         await this._shellClient.processCommand(cmd);
         
         sessionsToDelete.forEach(s => {
            const ix = this._sessionsList.findIndex(x => x.sessionId === s.sessionId);
            if (ix >= 0) {
               this._sessionsList.splice(ix, 1);
            }
         });

         this._historyListGrid.api.applyTransaction({remove: sessionsToDelete});

         this._toastr.info('"Delete All Finished Sessions" operation completed');

      } catch (e) {

         this._toastr.error('"Delete All Finished Sessions" command completed with errors');

         const data = { error: e.stack || e, terminal: sessionsToDelete };
         
         this._logger.error('deleteAllFinishedSessions()', data);

      } finally {

         this._historyListGrid.api.hideOverlay();

      }
   }

   getInnerStrategiesRecords(data: SessionHistoryStrategyInfoDto): SessionHistoryStrategyInfoDto[] {
      const reocrds = this._selectedSessionItems.where(x => x.dispositionId === data.strategyId).toArray();
      return reocrds;
   }
   
   private async loadHistoryListData(): Promise<SessionHistoryDataDto[]> {
      
      const qry =  new GetSessionHistoryData();
      
      if (this._config) {
         if (this._config.bucketType === 'Portfolio') {
            qry.bucketType = 'Portfolio';
            qry.bucketId = this._config.data.portfolioId;
         } else if (this._config.bucketType === 'Combo') {
            qry.bucketType = 'Combo';
            qry.bucketId = this._config.data.comboId;
         } else if (this._config.bucketType === 'ComboGroup') {
            qry.bucketType = 'ComboGroup';
            qry.bucketId = this._config.data.comboGroupId;
         }
      }
            
      return this._shellClient.processQuery<SessionHistoryDataDto[]>(qry);
   }

      
   private async loadSessionStrategyInfoes(sessionId: string): Promise<SessionHistoryStrategyInfoDto[]> {

      const qry = new GetSessionStrategyInfosBySessionId(sessionId);
      return this._shellClient.processQuery<SessionHistoryStrategyInfoDto[]>(qry);

   }

   
   private async loadTradingDataForSelectedStrategyInfo(sessionStrategyInfo: SessionHistoryStrategyInfoDto) {
      
      if (this.selectedTabIndex === 0) { // Trades

         await this.tryLoadSessionStrategyTrades(
            sessionStrategyInfo.sessionHistoryId,
            sessionStrategyInfo.strategyId,
            this.selectedStrategyInfo.strategyId === EtsConstants.strategies.manualStrategyId
               ? this.selectedStrategyInfo.ticker
               : null,
            null,
            null,
            sessionStrategyInfo.portfolioId,
            sessionStrategyInfo.comboId,
            sessionStrategyInfo.comboGroupId
         );
      
      } else if (this.selectedTabIndex === 1) { // Snapshots
      
         await this.tryLoadSessionStrategySnapshots(
            sessionStrategyInfo.sessionHistoryId,
            sessionStrategyInfo.strategyId,
            this.selectedStrategyInfo.strategyId === EtsConstants.strategies.manualStrategyId
               ? this.selectedStrategyInfo.ticker
               : null,
            null,
            null,
            sessionStrategyInfo.portfolioId,
            sessionStrategyInfo.comboId,
            sessionStrategyInfo.comboGroupId
         );
      
      } else if (this.selectedTabIndex === 2) { // Messages
      
         await this.tryLoadSessionStrategyMessages(
            sessionStrategyInfo.sessionHistoryId,
            sessionStrategyInfo.strategyId
         );
      }
   }

   
   private async tryLoadHistoryListData(): Promise<void> {
      
      this._historyListGrid.api.showLoadingOverlay();

      try {

         const dtos = await this.loadHistoryListData();
         this._sessionsList = dtos.sort( (a, b) => a.terminalCode.localeCompare(b.terminalCode));
         this._historyListGrid.api.setRowData(this._sessionsList);

         if (this._config) {
            this.expandTerminalGroups();
         }


      } catch (e) {

         this._toastr.error('"Session History" dialog loaded with errors');
         const data = { error: e.stack || e };
         this._logger.error('_tryLoadHistoryListData()', data);

      } finally {

         this._historyListGrid.api.hideOverlay();

      }
   }


   private expandTerminalGroups() {
      

      if (!this._historyListGrid) {
         return;
      }

      this._historyListGrid.api.forEachNode(node => {
         if (!node.isExpandable()) {
            return;
         }

         node.expanded = true;
      });

      setTimeout(() => {
         this._historyListGrid.api.onGroupExpandedOrCollapsed();
               }, 50);
   }

   
   private async tryLoadSessionStrategyInfoes(sessionId: string): Promise<void> {

      this._portfoliosGrid.api.setRowData([]);
      this._combosGrid.api.setRowData([]);
      this._comboGroupsGrid.api.setRowData([]);
      this._bucketItemsGrid.api.setRowData([]);
      this._messagesGrid.api.setRowData([]);
      this._snapshotsGrid.api.setRowData([]);
      this._tradesGrid.api.setRowData([]);

      this.selectedStrategyInfo = null;

      try {

         this._portfoliosGrid.api.showLoadingOverlay();
         this._combosGrid.api.showLoadingOverlay();
         this._comboGroupsGrid.api.showLoadingOverlay();

         const dtos = await this.loadSessionStrategyInfoes(sessionId);

         dtos.forEach(x => x.itemType = getItemType(x) );

         const dtosEnum = Enumerable.from(dtos);
         
         this._selectedSessionItems = dtosEnum;

         if (!this._config) {
            
            // make portfolios
            const portfolios = dtosEnum
            .where(x => isNullOrUndefined(x.dispositionId))
            .groupBy(x => x.portfolioId)
            .where(gr => gr.any(x => x.wasActiveInSession))
            .select(gr =>  {
               const item = { 
                  portfolioId: gr.key(), 
                  portfolioName: gr.first().portfolioName,
                  sessionPnL: gr.sum(x => x.sessionPnL),   
                  accumulatedPnL: gr.sum(x => x.accumulatedPnL)
               };
               return item;
            })
            .toArray();

            if (this._portfoliosGrid) {
               this._portfoliosGrid.api.setRowData(portfolios);
            }

         } else {

            if (this._config.bucketType === 'Portfolio') {

                // make portfolios
               const portfolios = dtosEnum
               .where(x => isNullOrUndefined(x.dispositionId))
               .where(x => x.portfolioId === this._config.data.portfolioId)
               .groupBy(x => x.portfolioId)
               .where(gr => gr.any(x => x.wasActiveInSession))
               .select(gr =>  {
                  const item = { 
                     portfolioId: gr.key(), 
                     portfolioName: gr.first().portfolioName,
                     sessionPnL: gr.sum(x => x.sessionPnL),   
                     accumulatedPnL: gr.sum(x => x.accumulatedPnL)
                  };
                  return item;
               })
               .toArray();

               if (this._portfoliosGrid) {
                  this._portfoliosGrid.api.setRowData(portfolios);
               }


               // this.onPortfolioRowSelected({portfolioId: this._config.data.portfolioId});
   
            } else if (this._config.bucketType === 'Combo') {

                // set combos
               const combos = this._selectedSessionItems
                  .where(x => isNullOrUndefined(x.dispositionId) )
                  .where(x => x.comboId === this._config.data.comboId)
                  .groupBy(x => x.comboId)
                  .where(gr => gr.any(x => x.wasActiveInSession))
                  .select(gr => {

                     const item = {
                        comboId: gr.key(),
                        comboName: gr.first().comboName,
                        portfolioId: gr.first().portfolioId,
                        sessionPnL: gr.sum(x => x.sessionPnL),
                        accumulatedPnL: gr.sum(x => x.accumulatedPnL)
                     };

                     return item;
               })
               .toArray();

               this._combosGrid.api.setRowData(combos);
                  
               // this.onComboRowSelected({
               //    portfolioId: this._config.data.portfolioId,
               //    comboId: this._config.data.comboId
               // });
   
            } else if (this._config.bucketType === 'ComboGroup') {

               // set comboGroups
               const comboGroups = this._selectedSessionItems
                  .where(x => isNullOrUndefined(x.dispositionId) )
                  .where(x => x.comboGroupId === this._config.data.comboGroupId)
                  .groupBy(x => x.comboGroupId)
                  .where(gr => gr.any(x => x.wasActiveInSession))
                  .select(gr => {
                     const item = {
                        comboGroupId: gr.key(),
                        comboGroupName: gr.first().comboGroupName,
                        comboId: gr.first().comboId,
                        portfolioId: gr.first().portfolioId,
                        sessionPnL: gr.sum(x => x.sessionPnL),
                        accumulatedPnL: gr.sum(x => x.accumulatedPnL)
                     };
                     
                     return item;
                })
                .toArray();
 
               this._comboGroupsGrid.api.setRowData(comboGroups);
   
               // this.onComboGroupRowSelected({
               //    portfolioId: this._config.data.portfolioId,
               //    comboId: this._config.data.comboId,
               //    comboGroupId: this._config.data.comboGroupId
               // });
   
            }
   

         }
      
      } catch (e) {

         this._selectedSessionItems = Enumerable.empty();
         this._toastr.error('Session strategies loaded with errors');
         const data = { error: e.stack || e };
         this._logger.error('_tryLoadSessionStrategyInfoes()', data);

      } finally {

         this._portfoliosGrid.api.hideOverlay();
         this._combosGrid.api.hideOverlay();
         this._comboGroupsGrid.api.hideOverlay();

      }
   }

   
   private async tryLoadSessionStrategyTrades(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number,
      portfolioId?: string,
      comboId?: string,
      comboGroupId?: string ): Promise<void> {

      const gridApi = this._tradesGrid.api;
      
      gridApi.showLoadingOverlay();
      
      try {
         const dtos = await this.loadSessionStrategyTrades(
            sessionId,
            strategyId,
            ticker,
            historyStart,
            batchSize,
            portfolioId,
            comboId,
            comboGroupId
         );
         const tradesContainer = this._selectedStrategyTradingData.trades;
         tradesContainer.push(...dtos);

         setTimeout(() => gridApi.setRowData(tradesContainer), 0);

      } catch (e) {

         this._toastr.error('"Session Strategy Trades" were loaded with errors');
         const data = { error: e.stack || e, strategyId, batchSize };
         this._logger.error('_tryLoadSessionStrategyTrades()', data);

      } finally {

         gridApi.hideOverlay();

      }
   }


   private async loadSessionStrategyTrades(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number,
      portfolioId?: string,
      comboId?: string,
      comboGroupId?: string): Promise<TradeDto[]> {

      const qry = new GetSessionStrategyTrades(
         strategyId,
         sessionId,
         ticker,
         historyStart || 0,
         batchSize || 100,
         portfolioId,
         comboId,
         comboGroupId
      );

      return this._shellClient.processQuery<TradeDto[]>(qry);
   }


   private async loadSessionStrategySnapshots(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number,
      portfolioId?: string,
      comboId?: string,
      comboGroupId?: string): Promise<OrderStateSnapshotDto[]> {

      const qry = new GetSessionStrategySnapshots(
         strategyId,
         sessionId,
         ticker,
         historyStart || 0,
         batchSize || 100,
         portfolioId,
         comboId,
         comboGroupId
      );

      return this._shellClient.processQuery<OrderStateSnapshotDto[]>(qry);
   }


   private async loadSessionStrategyMessages(
      sessionId: string,
      strategyId: string,
      historyStart?: number,
      batchSize?: number): Promise<StrategyLogMessageDto[]> {

      const qry = new GetSessionStrategyMessages(
         strategyId,
         sessionId,
         historyStart || 0,
         batchSize || 100
      );

      return this._shellClient.processQuery<StrategyLogMessageDto[]>(qry);
   }


   private subscribeToMessages() {

      this._unsubscriber = new Subject<any>();

      this._messageBus.of<SessionEndedDto>('SessionEndedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onSessionEndedMessage(message.payload));

      this._messageBus.of<SessionCreatedDto>('SessionCreatedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onSessionCreatedMessage(message.payload));


      this._messageBus.of('DefaultTimezoneChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => {
            if (this._tradesGrid) {
               this._tradesGrid.api.refreshCells({force: true});
            }
            
            if (this._snapshotsGrid) {
               this._snapshotsGrid.api.refreshCells({force: true});
            }

            if (this._messagesGrid) {
               this._messagesGrid.api.refreshCells({force: true});
            }
         });
   }


   private async tryLoadSessionStrategySnapshots(
      sessionId: string,
      strategyId: string,
      ticker: string,
      historyStart?: number,
      batchSize?: number,
      portfolioId?: string,
      comboId?: string,
      comboGroupId?: string): Promise<void> {

      const gridApi = this._snapshotsGrid.api;
      
      gridApi.showLoadingOverlay();
      
      try {
         const dtos = await this.loadSessionStrategySnapshots(
            sessionId,
            strategyId,
            ticker,
            historyStart,
            batchSize,
            portfolioId,
            comboId,
            comboGroupId
         );
         const snapshotsContainer = this._selectedStrategyTradingData.snapshots;
         snapshotsContainer.push(...dtos);

         setTimeout(() => gridApi.setRowData(snapshotsContainer), 0);

      } catch (e) {

         this._toastr.error('"Session Strategy Snapshots" were loaded with errors');
         const data = { error: e.stack || e, strategyId, batchSize };
         this._logger.error('_tryLoadSessionStrategySnapshots()', data);

      } finally {

         gridApi.hideOverlay();

      }
   }

   
   private async tryLoadSessionStrategyMessages(
      sessionId: string,
      strategyId: string,
      historyStart?: number,
      batchSize?: number): Promise<void> {

      const gridApi = this._messagesGrid.api;
      
      gridApi.showLoadingOverlay();

      try {
         
         if (strategyId === EtsConstants.strategies.manualStrategyId) {
            gridApi.setRowData([]);
         } else {
            const dtos = await this.loadSessionStrategyMessages(
               sessionId,
               strategyId,
               historyStart,
               batchSize
            );
            const messagesContainer = this._selectedStrategyTradingData.messages;
            messagesContainer.push(...dtos);

            setTimeout(() => gridApi.setRowData(messagesContainer), 0);
         }

      } catch (e) {

         this._toastr.error('"Session Strategy Messages" were loaded with errors');
         const data = { error: e.stack || e, strategyId, batchSize };
         this._logger.error('_tryLoadSessionStrategyMessages()', data);

      } finally {
         
         gridApi.hideOverlay();

      }
   }

   
   private onSessionEndedMessage(message: SessionEndedDto): void {

      const session = this._sessionsList.find(x => x.sessionId === message.sessionId);
      
      if (!session) {
         return;
      }
      
      session.endDate = message.endDate;
      session.accumulatedPnL = message.accumulatedPnL;
      session.sessionPnL = message.sessionPnL;

      this._historyListGrid.api.applyTransaction({update: [session]});
   }

   
   private onSessionCreatedMessage(message: SessionCreatedDto): void {
      const item = message as SessionHistoryDataDto;
      this._sessionsList.push(item);
      this._historyListGrid.api.applyTransaction({add: [item]});
   }

   
   private resetState() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
         this._unsubscriber = null;
      }

      this._messagesGrid.api.setRowData([]);
      this._snapshotsGrid.api.setRowData([]);
      this._tradesGrid.api.setRowData([]);

      this._bucketItemsGrid.api.setRowData([]);
      this._comboGroupsGrid.api.setRowData([]);
      this._combosGrid.api.setRowData([]);
      this._portfoliosGrid.api.setRowData([]);

      this._historyListGrid.api.setRowData([]);

      this._config = null;
      
   }

   
   @DetectMethodChanges()
   private assignDefaultSectionSizes() {

      this.historyListSectionSize = 33;
      this.sessionBucketsListSectionSize = 33;
      this.sessionDetailsSectionSize = 33;
      this.bucketSectionSize = 33.3;
      this.bucketItemsSectionSize = 50;
      this.tradingDataSectionSize = 50;

   }
}


function getItemType(x: SessionHistoryStrategyInfoDto): 'Strategy' | 'Stock' | 'Future' | 'Call' | 'Put' {
   
   if (x.strategyId !== EtsConstants.strategies.manualStrategyId) {
      return 'Strategy';
   }
   
   if (x.ticker.startsWith('@')) {
      
      if (x.ticker.indexOf(' Call ') > 0) {
         return 'Call';
      }

      return 'Put';

   } else  {
      if (x.displayName.indexOf(' ') > 0) {
         return 'Future';
      }

      return 'Stock';
   }
}

