<dx-popup [width]="300" [height]="300" title="Duplicate Bucket" [showTitle]="true" [closeOnOutsideClick]="false"
   [resizeEnabled]="true" [dragEnabled]="true" [(visible)]="this.isVisible" (onHidden)="this.onHidden()">
   
   <div *dxTemplate="let data of 'content'" #container>

      <div class="content">

         <div class="portfolio-list">

            <dx-select-box [items]="this.terminalList"
                              [selectedItem]="this.selectedTerminal"
                              (onValueChanged)="this.onTerminalSelected()"
                              [placeholder]="'Select Terminal'"
                              [displayExpr]="'displayName'"
                              [showClearButton]="true"
                              [(value)]="this.selectedTerminal"
                              style="margin-bottom: 10px;">
            </dx-select-box>

            <dx-select-box [items]="this.portfolioList"
                           [selectedItem]="this.selectedPortfolio"
                           (onValueChanged)="this.onPortfolioSelected()"
                           [placeholder]="'Select Portfolio'"
                           [displayExpr]="'portfolioName'"
                           [(value)]="this.selectedPortfolio"
                           [showClearButton]="true"
                           style="margin-bottom: 10px;"
                           *ngIf="this.canSetPortfolio">
            </dx-select-box>
   
         </div>

         <!-- Combos -->
         <div style="margin-bottom: 10px;">

            <dx-select-box [items]="this.comboList"
                           displayExpr="comboName"
                           placeholder="Select Combo"
                           [acceptCustomValue]="false"
                           [(value)]="this.selectedCombo"
                           [showClearButton]="true"
                           *ngIf="this.canSetCombo">
            </dx-select-box>

         </div>

         <div class="options">

            <dx-text-box class="new-name" placeholder="Give bucket new name" [(value)]="this.newName"></dx-text-box>
         </div>
      
      </div>


      <div class="footer">
         
         <div class="buttons">
            <dx-button text="OK" width="100px" (onClick)="onOkButtonClicked()"></dx-button>
         </div>

      </div>

      <dx-load-panel [visible]="this.isLoading" 
                     [position]="{my: 'center', at: 'center', of: container}"
      ></dx-load-panel>

   </div>
</dx-popup>