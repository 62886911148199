import { Injectable } from '@angular/core';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { BeforePositionDto } from '../model/BeforePositionDto';
import { PositionsData } from '../positions-section/model/PositionsData';
import { ServiceConfiguration } from './ServiceConfiguration';
import { isVoid } from 'projects/shared-components/utils';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ApgPortfolioStateChanged } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { parseOptionTicker } from 'projects/shared-components/options-common/options.model';
import {UserSettingsService} from "../../user-settings.service";

const StorageKey = 'apg.saved-positions';
const ComparisonStorageKeyLeft = 'cpg.saved-positions.left';
const ComparisonStorageKeyRight = 'cpg.saved-positions.right';

@Injectable()
export class SavedPositionsService {

   constructor(
      private _userSettingsService: UserSettingsService,
      private _shellClientService: ShellClientService,
      private _sessionService: SessionService
   ) {
   }

   private _serviceConfig: ServiceConfiguration;

   //
   save(portfolioId: string, underlying: string, strategy: CashFlowStrategy, positions: PositionsData[]) {

      const positionDatas = positions.map(x => {
         const dtos = x.positions.map(y => y.asDto());
         return dtos;
      });

      this.saveRaw(portfolioId, underlying, strategy, positionDatas);
   }

   private saveRaw(portfolioId: string, underlying: string, strategy: CashFlowStrategy, positionDatas: BeforePositionDto[][]) {
      const key = this.makeStorageKey(portfolioId, underlying, strategy);

      this._userSettingsService.setValue(key, positionDatas, this._serviceConfig?.userId);

      if (this._serviceConfig) {
         if (this._serviceConfig.userId) {
            if (this._serviceConfig.userId === this._sessionService.sessionData.userId) {
               const areValid = this.validatePositions(positionDatas);
               if (areValid) {
                  const cmd = new ApgPortfolioStateChanged(portfolioId);
                  this._shellClientService.processCommand(cmd).catch(e => {
                     console.error('Portfolio state changed update completed with errors');
                  });
               }
            }
         }
      }
   }

   //
   getPositions(portfolioId: string, underlying: string, strategy: CashFlowStrategy): BeforePositionDto[][] {
      const key = this.makeStorageKey(portfolioId, underlying, strategy);
      
      const result = this._userSettingsService.getValue<BeforePositionDto[][]>(
          key, this._serviceConfig?.userId) || [];
      return result;
   }

   //
   clear(portfolioId: string, underlying: string, strategy: CashFlowStrategy) {
      const key = this.makeStorageKey(portfolioId, underlying, strategy);
      this._userSettingsService.deleteValue(key);
   }

   //
   configure(cfg: ServiceConfiguration) {
      this._serviceConfig = cfg;
    }
 

   //
   makeStorageKey(portfolioId: string, underlying: string, strategy: CashFlowStrategy): string {
      
      console.assert(!isVoid(this._serviceConfig));

      let rootKey = StorageKey;
      
      if (this._serviceConfig.orientation === 'left') {
         rootKey = ComparisonStorageKeyLeft;
      } else if (this._serviceConfig.orientation === 'right') {
         rootKey = ComparisonStorageKeyRight;
      }

      let key = `${rootKey}.${portfolioId}.${underlying}.${strategy}`;

      // if (this._serviceConfig.userId) {
      //    key += `${EtsConstants.storageKeys.userSeparator}${this._serviceConfig.userId}`;
      // }
   
      return key;
   }

   private validatePositions(pos: BeforePositionDto[][]) {
      let valid = true;

      pos.forEach(poses => {
         poses.forEach(position => {
            const parsedTicker = parseOptionTicker(position.ticker);
            if (!parsedTicker) {
               valid = false;
            }
         });
      });

      return valid;
   }
}