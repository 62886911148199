import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate } from '@angular/common';
import { InformationCategory } from '../information-category.enum';
import { centeredColumnDef, defaultTimestampFormat, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { StrategyIssueDto } from 'projects/shared-components/shell-communication/dtos/strategy-issue-dto.class';
import { StrategyIssuesComponent } from './strategy-issues.component';

export function getStrategyIssuesGridModel(
   this: StrategyIssuesComponent
): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: '',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            width: 80
         },
         {
            headerName: 'Timestamp',
            field: 'timestamp',
            valueFormatter: (params: ValueFormatterParams) => {
               return params.value
                  ? formatDate(params.value, defaultTimestampFormat, 'en-US')
                  : '';
            },
            width: 160
         },
         {
            headerName: 'Category',
            field: 'category',
            valueFormatter: (params: ValueFormatterParams) => {
               return InformationCategory[params.value];
            },
            width: 120,
            filter: true
         },
         {
            headerName: 'Message',
            field: 'messageText',
            headerComponentParams: {},
            cellStyle: { 'text-align': 'left' },
            width: 750,
            filter: 'agTextColumnFilter'
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'multiple',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: args => this.onGridReady(args),

      getRowNodeId: (rowData: StrategyIssueDto) => rowData.issueId,

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         if (this.securityContext.ackIssue(this.mode)) {
            menu.push(
               {
                  name: 'Acknowledge Selected Issue(s)',
                  action: () => this.ackSelectedIssues()
               }
            );
            menu.push(
               {
                  name: 'Acknowledge All Issues',
                  action: () => this.ackAllIssues()
               }
            );
            menu.push('separator');
         }
         menu.push(
            {
               name: 'Load More ...',
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadMoreIssues(50)
                  },
                  {
                     name: '100',
                     action: () => this.loadMoreIssues(100)
                  },
                  {
                     name: '500',
                     action: () => this.loadMoreIssues(500)
                  }
               ]
            }
         );
         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );
         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),

      onRowDoubleClicked: (args) => {
         this.selectedIssue = args.data;
         this.isDetailsWindowVisible = true;
      }

   } as GridOptions;
}
