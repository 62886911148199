import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AgEditorComponent, ICellRendererAngularComp } from 'ag-grid-angular';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import {
   TradingInstrumentDisplayNameService,
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { defaultCurrencyFormatter, DetectMethodChanges } from 'projects/shared-components/utils';
import { IAfterGuiAttachedParams, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'ets-expire-dialog-group-row',
   template: `
    <div class="wrapper">
      
    <div class="block">
        <span class="group-title"> {{ this.initParams.node.key }} </span>
      </div>
      
      <div class="block checkbox">
         <dx-check-box text="Apply To All" *ngIf="this.initParams.node.field === 'expirationDisplayDate'"
                        value="true"
                        (onValueChanged)="this.onCheckboxChanged($event)"
         ></dx-check-box>
      </div>

    </div>
  `,
   styles: [
      `
      .wrapper {
        display: inline-block;
        width: 100%;
        text-align: left;
      }

      .block {
        display: inline-block;
      }

      .group-title {
        color: yellow;
      }

      .checkbox {
         position: absolute;
         right: 8px;
         bottom: 6px;
      }
    `
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line: component-class-suffix
export class ExpireDialogGroupRowRendererComponent implements ICellRendererAngularComp, OnDestroy {
   constructor(
      private readonly _messageBus: MessageBusService,
      private readonly _tickerFriendlyNameService: TradingInstrumentDisplayNameService,
      private readonly _changeDetector: ChangeDetectorRef
   ) {
   }

   initParams: ICellRendererParams;

   @DetectMethodChanges()
   agInit(params: ICellRendererParams): void {
      
      this.initParams = params;

      this._changeDetector.detach();
   }

   ngOnDestroy() {
   }


   refresh(): boolean {
      return false;
   }

   onCheckboxChanged($event) {
      const comp = (this.initParams as any).component;
      console.assert(comp);
      comp.expirationDialog.noApplyToAll[this.initParams.node.key]  = !$event.value;
   }
}




@Component({
   selector: 'ets-grid-datepicker',
   template: `
   <dx-date-box type="date" dateSerializationFormat="dd-MMM-yy" [(value)]="this.date" 
                  displayFormat="dd-MMM-yy">
   </dx-date-box>
   `
})
export class ExpireDialogDateCellEditorComponent implements AgEditorComponent {
   
   date: string;
   
   getValue() {
      return this.date;
   }
   
   isPopup?(): boolean {
      return false;
   }
   
   
   agInit(params: ICellEditorParams): void {
      this.date = params.value;
   }
}




@Component({
   selector: 'ets-grid-timepicker',
   template: `
   <dx-date-box type="time" pickerType="rollers" dateSerializationFormat="HH:mm" [(value)]="this.time" 
                  displayFormat="hh:mm a">
   </dx-date-box>
   `
})
export class ExpireDialogTimeCellEditorComponent implements AgEditorComponent {
   
   time: string;
   
   getValue() {
      return this.time;
   }
   
   isPopup?(): boolean {
      return false;
   }
   
   
   agInit(params: ICellEditorParams): void {
      this.time = params.value;
   }
}
