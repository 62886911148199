import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GameOutcome } from '../game-outcome';
import { DispositionReportComponent } from './disposition-report.component';
import { DispositionReportEntryModel } from './disposition-report-entry-model';
import { WinLoss } from '../win-loss';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { 
  defaultMoneyCellDefinition, 
  defaultQuoteCellFormatter, 
  centeredColumnDef, 
  defaultLoadingOverlayTemplate 
} from 'projects/shared-components/ag-grid-contrib';
import { formatDate } from '@angular/common';

export function getFlatColumnDefs(disposition: StrategyModel = null) {
  const columnDefs = [];

  columnDefs.push(
    {
      headerName: '#',
      field: 'sequenceNum',
      width: 80,
      hide: true,
    });

  columnDefs.push(
    {
      headerName: 'Outcome',
      field: 'outcome',
      valueGetter(args: ValueGetterParams) {
        const outcome: GameOutcome = args.data.outcome;
        return GameOutcome[outcome];
      }
    }
  );

  if (disposition) {
    for (let i = 0; i <= disposition.dispositionStrategies.length - 1; i++) {
      const innerStrategy = disposition.dispositionStrategies[i];
      if (!innerStrategy) {
        continue;
      }
      const innerStrategyName = innerStrategy.displayName;
      columnDefs.push(
        {
          headerName: `* ${innerStrategyName} (Side) *`,
          valueGetter(args: ValueGetterParams) {
            const index = i;
            const data: DispositionReportEntryModel = args.data;
            if (!data.innerGames || (data.innerGames.length - 1) < i) {
              return '';
            }
            const side: MarketSide = data.innerGames[index].side;
            return MarketSide[side];
          }
        }
      );
      columnDefs.push(
        {
          headerName: `* ${innerStrategyName} (Bet) *`,
          valueGetter(args: ValueGetterParams) {
            const index = i;
            const data: DispositionReportEntryModel = args.data;
            if (!data.innerGames || (data.innerGames.length - 1) < i) {
              return '';
            }
            return data.innerGames[index].actualBet;
          }
        }
      );
      columnDefs.push(
        {
          headerName: `* ${innerStrategyName} (CTL) *`,
          valueGetter(args: ValueGetterParams) {
            const index = i;
            const data: DispositionReportEntryModel = args.data;
            if (!data.innerGames || (data.innerGames.length - 1) < i) {
              return '';
            }
            return data.innerGames[index].ctlValueView;
          }
        }
      );
    }
  }

  columnDefs.push(
    {
      headerName: 'Disposition (Side)',
      field: 'side',
      valueGetter(args: ValueGetterParams) {
        const side: MarketSide = args.data.side;
        return MarketSide[side];
      }
    }
  );

  columnDefs.push(
    {
      headerName: 'Disposition (Bet)',
      field: 'actualBet'
    }
  );

  columnDefs.push(
    {
      headerName: 'Win/Loss',
      field: 'winLoss',
      valueGetter(args: ValueGetterParams) {
        const winLoss = args.data.winLoss;
        return WinLoss[winLoss];
      },
      cellStyle(args: CellClassParams) {
        if (args.value === 'Win') {
          return { color: 'green' };
        }
        if (args.value === 'Loss') {
          return { color: 'red' };
        }
        return null;
      }
    }
  );

  columnDefs.push(
    Object.assign(
      {
        headerName: 'Total P&L',
        field: 'totalPnL'
      },
      defaultMoneyCellDefinition
    )
  );

  columnDefs.push(
    Object.assign(
      {
        headerName: 'Perfect P&L',
        field: 'perfectPnL'
      },
      defaultMoneyCellDefinition
    )
  );

  columnDefs.push(
    Object.assign(
      {
        headerName: 'Slippage',
        field: 'slippageView'
      },
      defaultQuoteCellFormatter
    )
  );

  columnDefs.push(
    {
      headerName: 'CTL Size',
      field: 'ctlValueView',
      valueFormatter: defaultQuoteCellFormatter
    }
  );

  columnDefs.push(
    {
      headerName: 'Timestamp',
      field: 'timestamp',
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return '';
        }
        return formatDate(params.value || null, 'yyyy-MMM-dd HH:mm:ss', 'en-US');
      }
    }
  );

  return columnDefs;
}

export function getFlatDispositionReportGridModel(
  this: DispositionReportComponent,
): GridOptions {

  const columnDefs = getFlatColumnDefs();

  return  {
    rowData: [],

    defaultColDef: Object.assign({ filter: true }, centeredColumnDef),

    columnDefs,

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    onGridReady: args => this.onGamesFlatGridReady(args),

    getRowNodeId: (rowData: DispositionReportEntryModel) => {
      return rowData.seqNum + '';
    },

    getRowStyle: (args) => {
      const data = args.data;
      if (!data) {
        return null;
      }
      return data.isLast ? { background: 'yellow', color: 'black' } : null;
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [
        {
          name: 'Load More ...',
          subMenu: [
            {
              name: '50',
              action: () => this.loadEarlierGames(50)
            },
            {
              name: '100',
              action: () => this.loadEarlierGames(100)
            },
            {
              name: '500',
              action: () => this.loadEarlierGames(500)
            },
            {
              name: '1000',
              action: () => this.loadEarlierGames(1000)
            }
          ]
        },
        'separator',
        'autoSizeAll',
        'copy',
        'export'
      ];
    }
  } as GridOptions;
}
