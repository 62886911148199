<dx-popup
    [closeOnOutsideClick]="false"
    [dragEnabled]="true"
    [height]="this.height"
    [resizeEnabled]="true"
    [shading]="true"
    [showCloseButton]="true"
    title="After State Detalization"
    width="670px"
>
    <div *dxTemplate="let data of 'content'">
        <div class="container">
            <div class="content">
                <div class="calls">
                    <div class="row header">
                        <ng-container *ngTemplateOutlet="headerRow"></ng-container>
                    </div>
                    <div *ngFor="let row of this.calls" [ngClass]="this.getRoleClass(row)" class="row">
                        <ng-container *ngTemplateOutlet="rowTpl; context: {$implicit: row}"></ng-container>
                    </div>
                    <div class="row total">
                        <ng-container *ngTemplateOutlet="totalRow; context: {
                            $implicit: {
                                bid: this.getTotals(this.calls, 'bid'),
                                mid: this.getTotals(this.calls, 'mid'),
                                ask: this.getTotals(this.calls, 'ask')
                            }
                        }"></ng-container>
                    </div>
                </div>
                <hr class="separator">
                <div class="puts">
                    <div class="row header">
                        <ng-container *ngTemplateOutlet="headerRow"></ng-container>
                    </div>
                    <div *ngFor="let row of this.puts" [ngClass]="this.getRoleClass(row)" class="row">
                        <ng-container *ngTemplateOutlet="rowTpl; context: {$implicit: row}"></ng-container>
                    </div>
                    <div class="row total">
                        <ng-container *ngTemplateOutlet="totalRow; context: {
                            $implicit: {
                                bid: this.getTotals(this.puts, 'bid'),
                                mid: this.getTotals(this.puts, 'mid'),
                                ask: this.getTotals(this.puts, 'ask')
                            }
                        }"></ng-container>
                    </div>
                </div>
                <hr class="separator">
                <div class="row total grand">
                    <ng-container *ngTemplateOutlet="totalRow; context: {
                            $implicit: {
                                bid: this.getTotals(this.calls, 'bid') + this.getTotals(this.puts, 'bid'),
                                mid: this.getTotals(this.calls, 'mid') + this.getTotals(this.puts, 'mid'),
                                ask: this.getTotals(this.calls, 'mid') + this.getTotals(this.puts, 'ask')
                            }
                        }"></ng-container>
                </div>
            </div>
            <div class="footer">
                <div class="btn apply">
                    <dx-button (onClick)="this.onApplyClicked()" text="Continue"
                               width="100%">
                    </dx-button>
                </div>
            </div>
        </div>
    </div>
</dx-popup>

<ng-template #headerRow>
    <div class="cell qty">Qty</div>
    <div class="cell side">Side</div>
    <div class="cell type">Type</div>
    <div class="cell expiration">Expiration</div>
    <div class="cell strike">Strike</div>
    <div class="cell bid">Bid</div>
    <div class="cell bid">Mid</div>
    <div class="cell bid">Ask</div>
</ng-template>

<ng-template #totalRow let-totalObj>
    <div class="cell qty">&nbsp;</div>
    <div class="cell side">&nbsp;</div>
    <div class="cell type">&nbsp;</div>
    <div class="cell expiration">&nbsp;</div>
    <div class="cell strike">&nbsp;</div>
    <div [class.credit]="totalObj.bid > 0" [class.debit]="totalObj.bid < 0" class="cell bid">
        {{totalObj.bid | currency:'US':'$':'1.2-2'}}
    </div>
    <div [class.credit]="totalObj.mid > 0" [class.debit]="totalObj.mid < 0" class="cell mid">
        {{totalObj.mid | currency:'US':'$':'1.2-2'}}
    </div>
    <div [class.credit]="totalObj.ask > 0" [class.debit]="totalObj.ask < 0" class="cell ask">
        {{totalObj.ask | currency:'US':'$':'1.2-2'}}
    </div>
</ng-template>

<ng-template #rowTpl let-row>
    <div class="cell qty">
        <dx-number-box [readOnly]="true" [value]="row.qty"></dx-number-box>
    </div>
    <div class="cell side">
        <dx-text-box [readOnly]="true" [value]="row.side"></dx-text-box>
    </div>
    <div class="cell type">
        <dx-text-box [readOnly]="true" [value]="row.type"></dx-text-box>
    </div>
    <div class="cell expiration">
        <dx-select-box
            [(value)]="row.expirationObj"
            [inputAttr]="row?.expirationObj?.expirationTicker === '@SPX' ? {'style': 'color: yellow'} : {}"
            [items]="this.expirationsList"
            [ngClass]="{'readonly': this.isExpirationChangeDisabled(row) || this.isReadonly(row)}"
            [readOnly]="this.isExpirationChangeDisabled(row) || this.isReadonly(row)"
            (onValueChanged)="this.onExpirationChanged(row)"
            displayExpr="dateWithDaysToExpiration"
        >
            <div *dxTemplate="let data of 'item'">
                <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                    {{data?.dateWithDaysToExpiration}}
                </span>
            </div>
        </dx-select-box>
    </div>
    <div class="cell strike">
        <ets-strikes-dropdown
            (strikeChanged)="this.onStrikeChanged(row)"
            [(strike)]="row.strike"
            [disable]="this.isReadonly(row)"
            [expiration]="row.expirationObj"
            [showAtm]="true"
            [targetInstrument]="this.tradingInstrument"
        >
        </ets-strikes-dropdown>
    </div>
    <div class="cell bid">{{ row.bid | currency:'US':'$':'1.2-2' }}</div>
    <div class="cell bid">{{ row.mid | currency:'US':'$':'1.2-2' }}</div>
    <div class="cell bid">{{ row.ask | currency:'US':'$':'1.2-2' }}</div>
</ng-template>
