import { Injectable } from '@angular/core';
import { BucketType } from 'projects/shared-components/portfolios/portfolios.model';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { LedgerRecord } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetLedgerRecords, GetLedgerRecordsReply, SaveLedgerRecordNote } from 'projects/shared-components/shell-communication/shell-operations-protocol';

export interface LedgerSpec {
   bucketType?: BucketType;
   terminalId?: string;
   portfolioId?: string; 
   comboId?: string;
   comboGroupId?: string; 
   itemId?: string; 
   masterRecordId?: number;
   startingRecordId?: number;
   bucketName?: string;
}

@Injectable()
export class LedgerService {
   constructor(
      private _shellClient: ShellClientService
   ) { }

   getLedgerRecords(numberOfRecordsToLoad: number, spec: LedgerSpec): Promise<GetLedgerRecordsReply> {

      const qry = new GetLedgerRecords(spec, numberOfRecordsToLoad);

      return this._shellClient.processQuery<GetLedgerRecordsReply>(qry);
   }

   saveRecordNote(ledgerRecordId: number, note: string): Promise<void> {
      const cmd = new SaveLedgerRecordNote(ledgerRecordId, note);
      return this._shellClient.processCommand(cmd);
   }

}
