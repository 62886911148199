<fieldset class="expiration-smart-mode-settings">
   <legend>Expiration Smart Mode</legend>

   <table>
      <thead>
         <tr>
            <th class="leg">Leg</th>
            <th>
               Back
            </th>
            <th>
               Forth
            </th>
            <th>&nbsp;</th>
         </tr>
      </thead>

      <tbody>
         <!-- Spread -->
         <tr class="row">
            <td class="label">Spread</td>
            <td class="box">
               <div>
                  <dx-number-box 
                     [(value)]="this.spread.back"
                     [disabled]="!this.spread.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="box">
               <div>
                  <dx-number-box
                     [(value)]="this.spread.forth"
                     [disabled]="!this.spread.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="enabled">
               <dx-check-box
                  [(value)]="this.spread.isEnabled"
                  (onValueChanged)="this.onChanges()"
                  >
               </dx-check-box>
            </td>
         </tr>

         <!-- 2nd Spread -->
         <tr class="row">
            <td class="label">2nd Spread</td>
            <td class="box">
               <div>
                  <dx-number-box 
                     [(value)]="this.secondSpread.back"
                     [disabled]="!this.secondSpread.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="box">
               <div>
                  <dx-number-box
                     [(value)]="this.secondSpread.forth"
                     [disabled]="!this.secondSpread.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="enabled">
               <dx-check-box
                  [(value)]="this.secondSpread.isEnabled"
                  (onValueChanged)="this.onChanges()"
                  >
               </dx-check-box>
            </td>
         </tr>

         <!-- Protective Option -->
         <tr class="row">
            <td class="label">Protective Option</td>
            <td class="box">
               <div>
                  <dx-number-box 
                     [(value)]="this.protectiveOption.back"
                     [disabled]="!this.protectiveOption.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="box">
               <div>
                  <dx-number-box
                     [(value)]="this.protectiveOption.forth"
                     [disabled]="!this.protectiveOption.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="enabled">
               <dx-check-box
                  [(value)]="this.protectiveOption.isEnabled"
                  (onValueChanged)="this.onChanges()"
                  >
               </dx-check-box>
            </td>
         </tr>

         <!-- 2nd Protective Option -->
         <tr class="row">
            <td class="label">2nd Protective Option</td>
            <td class="box">
               <div>
                  <dx-number-box 
                     [(value)]="this.secondProtectiveOption.back"
                     [disabled]="!this.secondProtectiveOption.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="box">
               <div>
                  <dx-number-box
                     [(value)]="this.secondProtectiveOption.forth"
                     [disabled]="!this.secondProtectiveOption.isEnabled"
                     >
                  </dx-number-box>
               </div>
            </td>
            <td class="enabled">
               <dx-check-box
                  [(value)]="this.secondProtectiveOption.isEnabled"
                  (onValueChanged)="this.onChanges()"
                  >
               </dx-check-box>
            </td>
         </tr>
      </tbody>
   </table>

</fieldset>