<div class="ets-workspace-header" id="wsh_{{wshId}}">
    <ets-tabs-menu (contextMenu)="this.onTabsMenuContextMenuRequest($event)"
                   (sortPanels)="this.onSortPanelsRequest()"
    ></ets-tabs-menu>
    <ets-workspace-toolbar (contextMenu)="this.onAddPanelContextMenuRequest($event)"
                           (expanded)="this.onExpanded()"
                           [isExpanded]="this.isWorkspaceExpanded"
                           (splitRequest)="this.onSplitRequest($event)"
    ></ets-workspace-toolbar>
  <ets-tabs-panel [tabs]="panels"
                  (tabClicked)="this.onTabSelected($event)"
                  (tabHeaderChanged)="this.onTabHeaderChanged($event)"
                  (tabClosed)="this.onTabClosed($event)"
                  (symbolLinkChanged)="this.onSymbolLinkChanged($event)"
   ></ets-tabs-panel>
</div>
