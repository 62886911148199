import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InterestSectionSideboxComponent } from './sidebox/interest-sidebox.component';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ComboDto, GetInterestSecionDataModelReply, InterestOverviewBlockDto, InterestParameters } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetInterestSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { InterestCalculatorParameters } from 'projects/shared-components/adjustment-strategy-dialog/strategy-parameters/interest/interest-calculator-strategy-parameters';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { EngineStrategyAddedDto } from 'projects/shared-components/shell-communication/dtos/engine-strategy-added-dto.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { EngineStrategyRemovedDto } from 'projects/shared-components/shell-communication/dtos/engine-strategy-removed-dto.class';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'ets-automation-cp-interest-section',
   templateUrl: './interest-section.component.html',
   styleUrls: ['./interest-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterestSectionComponent implements OnInit, OnDestroy {

   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _sessionService: SessionService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();
   private _interestStrategy: StrategyDto;

   header: string = 'Interest';

   @ViewChild('sidebox', { static: true }) sidebox: InterestSectionSideboxComponent;

   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(value: boolean) {
      this._isLoading = value;
   }

   //
   private _combo: ComboDto;
   public get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   public set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   //
   parameters: InterestCalculatorParameters;

   ngOnInit(): void {
      this.parameters = new InterestCalculatorParameters(this._changeDetector, this._sessionService);

      this._cpMessageBus.when('AdjustmentStrategyCreated')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onComboSelected());

      this._cpMessageBus.when('AdjustmentStrategyRemoved')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.reset());

   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {

      this.reset();
      
      if (isVoid(this.combo)) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetInterestSectionDataModel(
            this.combo.comboId
         );

         const reply = await this._shellClient.processQuery<GetInterestSecionDataModelReply>(qry);

         if (reply.hasErrors) {
            return;
         }

         const dto: InterestOverviewBlockDto = reply.sidebox;

         this.sidebox.setData(dto);

         this._interestStrategy = reply.strategy;

         this.parameters.setParametersAuto(reply.parameters);

      } catch (e) {

         this._toastr.error(
            '"Load Protective Option Data" operation completed with errors',
            'Protective Option'
         );

      } finally {

         this.isLoading = false;

      }
   }

   //
   @DetectMethodChanges()
   reset() {
      this.sidebox.reset();
      this.parameters = new InterestCalculatorParameters(this._changeDetector, this._sessionService);
   }

   //
   getParameters(): InterestParameters {
      return this.parameters.getParametersAuto();
   }

   //
   @DetectMethodChanges()
   onStrategyCreated(dto: EngineStrategyAddedDto) {
      if (!this.combo) {
         return;
      }

      if (this.combo.comboId !== dto.strategy.comboId) {
         return;
      }

      if (dto.strategy.algoId !== EtsConstants.algorithms.interestAlgoId) {
         return;
      }

      this._interestStrategy = dto.strategy;
   }

   //
   @DetectMethodChanges()
   onStrategyRemoved(dto: EngineStrategyRemovedDto) {

      if (!this._interestStrategy) {
         return;
      }

      const ix = dto.strategies.findIndex(x => x.strategyId === this._interestStrategy.strategyId);

      if (ix < 0) {
         return;
      }

      this._interestStrategy = null;

      this.reset();
   }
}
