import {Pipe, PipeTransform} from "@angular/core";
import {daysToExpiration, isVoid, makeGuiFriendlyExpirationDate} from "../utils";
import {parseOptionTicker} from "../options-common/options.model";

@Pipe({
    name: 'etsFriendlyTickerPipe'
})

export class FriendlyTickerPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {

        if (isVoid(value)) {
            return value;
        }

        const optionTicker = parseOptionTicker(value);

        const exp = makeGuiFriendlyExpirationDate(optionTicker.expiration);

        let result = `${optionTicker.underlying} ${optionTicker.type} ${exp} ${optionTicker.strike}`;

        if (!isVoid(args)) {
            if (args.indexOf('dte') >= 0) {
                const dte = daysToExpiration(optionTicker.expiration);
                result += `(${dte}d)`;
            }
        }

        return result;
    }
}