import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetectMethodChanges } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-section-header',
   template: `
    <div class="header" [style.color]="this.color">
      <div class="line">&nbsp;</div>
      <div class="text">
         <span>{{ this.header | uppercase }}</span>
      </div>
      <ng-container *ngIf="this.collapsible">
         <dx-button [icon]="this.icon" (onClick)="this.onButtonClicked()"></dx-button>
      </ng-container>
      <div class="line">&nbsp;</div>
   </div>
   `,
   styles: [`
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      font-size: 1.2em;
   }

   .header .line {
         flex: 1;
         border-bottom: 3px solid;
         margin-top: -18px;
   }

   .header .text {
         font-weight: bold;
         text-align: center;
         margin: 5px;
      }
   `],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeaderComponent implements OnInit {
   
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }

   @Input() header: string;

   @Input() collapsible: boolean;

   @Input() color: string;

   @Output() onCollapsedChanged = new EventEmitter<boolean>();

   isCollapsed = false;

   get icon() {
      return this.isCollapsed ? 'chevrondown' : 'chevronup';
   }

   ngOnInit(): void { }

   @DetectMethodChanges()
   onButtonClicked() {
      this.isCollapsed = !this.isCollapsed;
      this.onCollapsedChanged.emit(this.isCollapsed);
   }

}
