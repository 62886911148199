import {HedgePositionModel} from "../hedging-grid/positions-section/hedge-positions/hedge-position.model";

export class SpreadLegWrapper {
    constructor(private readonly _leg: HedgePositionModel, commonQty: number) {
        this._commonQty = commonQty * Math.sign(this._leg.qty);
    }

    private _commonQty: number;

    get underlying() : string {
        return this._leg.underlying;
    }

    get action(): string {
        return this._leg.qty > 0 ? 'Buy' : 'Sell';
    }

    get qty() : number {
        return this._commonQty;
    }

    get expiration() : string {
        return this._leg.expiration;
    }

    get type() : string {
        return this._leg.type;
    }

    get strike() : number {
        return this._leg.strike;
    }
}