import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';
import { 
  AllocateUnaccountedOrders 
} from 'projects/shared-components/shell-communication/operations/unaccounted/allocate-unaccounted-orders.class';
import { 
  AllocateUnaccountedPosition 
} from 'projects/shared-components/shell-communication/operations/unaccounted/allocate-unaccounted-position.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';


@Component({
  selector: 'ets-allocate-item-dialog',
  templateUrl: 'allocate-item-dialog.component.html',
  styleUrls: ['allocate-item-dialog.component.scss']
})

export class AllocateItemDialogComponent implements OnInit {
  constructor(
    private _toastr: ToastrService,
    private _shellClient: ShellClientService,
    private _sessionService: SessionService,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger('AllocateItemDialogComponent');
  }

  isLoading: boolean;
  isVisible = false;
  availableTerminals: TerminalDto[] = [];
  selectedTerminals: string[] = [];
  selectedOrders: OrderDto[] = [];
  selectedPosition: PositionDto;

  private _logger: Logger;

  ngOnInit() {
    this.availableTerminals =
      this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy);
  }

  async allocate(): Promise<void> {
    if (this.selectedTerminals.length !== 1) {
      this._toastr.warning('Please, select a terminal to allocate to');
      return;
    }

    let cmd: ProtocolCommand = null;
    if (this.selectedOrders.length) {
      cmd = new AllocateUnaccountedOrders(
        this.selectedTerminals[0],
        this.selectedOrders.map(x => x.orderId)
      );
    } else if (this.selectedPosition) {
      cmd = new AllocateUnaccountedPosition(
        this.selectedTerminals[0],
        this.selectedPosition.accountId,
        this.selectedPosition.ticker
      );
    } else {
      this._toastr.error('Select entity to allocate');
      return;
    }

    this.isLoading = true;
    try {
      await this._shellClient.processCommand(cmd);
      this.isVisible = false;
    } catch (e) {
      this._toastr.error('Failed to allocate selected items');
      const data = { error: e.stack || e };
      this._logger.error('Failed to allocate item', data);
    } finally {
      this.isLoading = false;
    }
  }

  onHidden() {
    this._resetState();
    this.isVisible = false;
  }

  onShown() {
  }

  private _resetState() {
    this.selectedOrders = [];
    this.selectedPosition = null;
    this.selectedTerminals = [];
  }
}
