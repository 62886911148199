import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StrategyScheduleModel } from '../parameters-controls/strategy-schedule-model';
import { ToastrService } from 'ngx-toastr';
import { DetectMethodChanges } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-advanced-schedule-dialog',
   templateUrl: 'advanced-schedule-dialog.component.html',
   styleUrls: ['advanced-schedule-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdvancedScheduleDialogComponent implements OnInit, AfterViewInit {
   constructor(private _toastr: ToastrService, 
      private _changeDetector: ChangeDetectorRef) {
   }


   isVisible: boolean;

   parameters: StrategyScheduleModel;

   @Output() advancedScheduleSet = new EventEmitter<StrategyScheduleModel>();

   ngOnInit() { }

   ngAfterViewInit() { this._changeDetector.detach(); }

   @DetectMethodChanges()
   show(parameters: StrategyScheduleModel) {
      this.parameters = parameters;
      this.isVisible = true;
   }


   @DetectMethodChanges()
   onCancelClicked() {
      this.onHidden();
   }


   @DetectMethodChanges()
   onSaveClicked() {
      if (!this._validate()) {
         return;
      }
      this.advancedScheduleSet.emit(this.parameters);
      this.isVisible = false;
   }

   @DetectMethodChanges()
   onHidden() {
      this.isVisible = false;
      this.parameters = {};
   }

   @DetectMethodChanges()
   onWeekdayChanged(ev, dayOfWeek: 'mon' | 'tue' | 'wed' | 'thu' | 'fri') {
      if (!this.parameters) {
         return;
      }
      
      this.parameters[dayOfWeek] = ev;
   }


   private _validate(): boolean {
      if (!!this.parameters.beginDate && !!this.parameters.endDate) {
         if (this.parameters.endDate.getTime() <=
            this.parameters.beginDate.getTime()) {
            this._toastr.error('"End Date" must be greater than "Begin Date"');

            return false;
         }
      }

      if (!this.parameters.mon &&
         !this.parameters.tue &&
         !this.parameters.wed &&
         !this.parameters.thu &&
         !this.parameters.fri) {
         this.parameters.isRecurring = false;
      }

      return true;

   }
}
