<div class="container empty" 
   *ngIf="this.viewModel.noLegs" 
   [ngStyle]="{'background-color': this.mode !== 'sideblock' ? this.viewModel.bgColor : 'inherit'}">
   <p>No Legs</p>
</div>

<div class="container">
   <table class="table" *ngIf="!this.viewModel.noLegs" 
                        [ngStyle]="{
                                       'background-color': this.mode !== 'sideblock' ? this.viewModel.bgColor : 'inherit', 
                                       'font-size': this.mode === 'sideblock' ? 'larger' : 'inherit'
                                    }">
      <tbody>
         <tr>
            <td class="parameter">Short Opt.:</td>
            <td class="value">   
               <div class="leg-descriptor">
                  <div>{{this.viewModel.shortOptionQty}}</div>
                  <div>{{this.viewModel.shortOptionAsset}}</div> 
                  <div>{{this.viewModel.shortOptionExpiration}}</div>
                  <div>{{this.viewModel.shortOptionDaysToExpiration}} days left</div>
               </div>
            </td>
         </tr>
      </tbody>
   </table>
</div>


<!-- <div class="empty" *ngIf="!this.viewModel">
   <p>N/A</p>
</div>

<table class="table" [ngStyle]="{
                                    'background-color': this.mode !== 'sideblock' ? this.viewModel.bgColor : 'inherit', 
                                    'font-size': this.mode === 'sideblock' ? 'larger' : 'inherit' 
                                 }" *ngIf="this.viewModel">
   <tbody>
      <tr>
         <td class="parameter">Qty:</td>
         <td class="value">{{this.viewModel.qty}}</td>
      </tr>
      <tr>
         <td class="parameter">Asset:</td>
         <td class="value">{{this.viewModel.asset}}</td>
      </tr>
      <tr>
         <td class="parameter">Type:</td>
         <td class="value">{{this.viewModel.type}}</td>
      </tr>
      <tr>
         <td class="parameter">Strike:</td>
         <td class="value">{{this.viewModel.strike}}</td>
      </tr>
      <tr>
         <td class="parameter">Expiration:</td>
         <td class="value">{{this.viewModel.expiration}}</td>
      </tr>
      <tr>
         <td class="parameter">Days to Exp.:</td>
         <td class="value">{{this.viewModel.daysToExpiration}}</td>
      </tr>
   </tbody>
</table> -->
