import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { StrategyGameRecordDto } from 'projects/shared-components/shell-communication/dtos/strategy-game-record-dto.class';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import {
   TradingInstrumentDisplayNameService,

} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ToastrService } from 'ngx-toastr';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { StrategiesService } from '../../../../../shared-components/strategies/strategies.service';
import { SystemDetailsComponent } from '../../../../../shared-components/system-details/system-details.component';
import {
   GetStrategyGameRecordsWeb
} from 'projects/shared-components/shell-communication/operations/game-records/get-strategy-game-records-web.class';
import { filter, takeUntil } from 'rxjs/operators';
import { MarkLastGameRecordMessage } from 'projects/shared-components/shell-communication/dtos/mark-last-game-record-message.class';
import { isNullOrUndefined } from 'util';
import { WinLoss } from '../win-loss';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import { isFlagshipAlgo } from 'projects/shared-components/ets-constants.const';
import { FlagshipBetStyle, FlagshipBetSequence } from 'projects/shared-components/edit-strategy-dialog/parameters-controls/flagships/flagship-algo-parameters-control';
import { isTruthy, correctedDateAsUTC } from 'projects/shared-components/utils';
import { GetDeletedStrategiesFlagshipReports, GetStrategyGameRecordsByDateRange } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { plainToClass } from 'class-transformer';
import { AlgoMetadataService } from 'projects/shared-components/algo/algo-metadata.service';
import { PendingDataContainer, TickerModel, DATA_LOAD_MODE, DateRange } from '../common-reports.mode';
import { FlagshipReportEntryModel } from './flagship-report-entry-model.class';
import { TimestampsService } from 'projects/shared-components/timestamps.service';


@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FlagshipReportBase implements OnInit, OnDestroy {
   constructor(
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      private readonly _tradingInstruments: TradingInstrumentsService,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _messageBus: MessageBusService,
      private readonly _strategiesService: StrategiesService,
      private readonly _algoMetadataService: AlgoMetadataService,
      private readonly _timestampsService: TimestampsService,
   ) {
   }

   private readonly _pendingDataContainer: PendingDataContainer = {
      isLoading: false,
      data: []
   };
   
   private _grid: GridReadyEvent;
   private _selectedStrategy: StrategyModel;
   private _unsubscriber: Subject<any>;
   private readonly _reportEntries: FlagshipReportEntryModel[] = [];
   private _ticksOrPoints = false;
   private _isDeletedMode = false;

   @ViewChild(SystemDetailsComponent)
   systemDetails: SystemDetailsComponent;
   isVisible = false;
   title = 'Original Flagship Report';
   gamesGridModel: GridOptions;
   availableStrategies: StrategyModel[] = [];
   isLoading: boolean;
   availableTickersOfStrategy: TickerModel[] = [];
   percentWins: number;
   netSlippage: number;
   isDatePickerVisible = false;
   selectedTicker: TickerModel;
   readonly dataLoadModes = [
      {
         text: 'Date Range',
         value: 'Date'
      },
      {
         text: 'Active Session',
         value: 'Session'
      }
   ];
   selectedDataLoadMode: { text: string, value: DATA_LOAD_MODE } = null;
   dateRange: DateRange = {};

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   get isDeletedMode() {
      return this._isDeletedMode;
   }

   set isDeletedMode(value: boolean) {
      if (this._isDeletedMode === value) {
         return;
      }
      this._isDeletedMode = value;
      setTimeout(() => this._onModeChange(), 0);
   }

   get ticksOrPoints(): boolean {
      return this._ticksOrPoints;
   }

   set ticksOrPoints(value: boolean) {
      if (this._ticksOrPoints === value) {
         return;
      }

      this._ticksOrPoints = value;

      const tickSize = this._getTickSize();
      if (this._reportEntries.length > 0) {
         if (value) {
            this._reportEntries.forEach(x => x.changeTickPointView(tickSize));
         } else {
            this._reportEntries.forEach(x => x.changeTickPointView(null));
         }
      }
      this._grid.api.refreshCells();
   }

   get selectedStrategy(): StrategyModel {
      return this._selectedStrategy;
   }

   set selectedStrategy(strategy: StrategyModel) {
      if (this._selectedStrategy === strategy) {
         return;
      }
      this._selectedStrategy = strategy;

      this._clearGames();

      if (!strategy) {
         return;
      }

      const parameters = strategy.parameters;
      if (!parameters) {
         return;
      }

      setTimeout(() => {
         const symbols = Object.keys(parameters)
            .filter(x => x.endsWith('symbol'))
            .map(x => parameters[x])
            .map(x => (
               {
                  ticker: x,
                  displayName: this._displayNameService.getDisplayNameForTicker(x)
               }));

         this.availableTickersOfStrategy = symbols;

         if (!!this.systemDetails) {
            this.systemDetails.system = strategy;
            this.systemDetails.setupCommonGrid();
            this.systemDetails.setupSpecificGrid();
         }
      }, 0);
   }

   get betStyle(): string {
      let result = 'N/A';
      const str = this._selectedStrategy;
      if (!str) {
         return result;
      }
      if (!isFlagshipAlgo(str.algoId)) {
         return result;
      }
      const params = str.parameters;
      const style = FlagshipBetStyle[params.betstyle];
      if (isTruthy(style)) {
         result = style;
      }
      return result;
   }

   get betSequence(): string {
      let result = 'N/A';
      const str = this._selectedStrategy;
      if (!str) {
         return result;
      }
      if (!isFlagshipAlgo(str.algoId)) {
         return result;
      }
      const params = str.parameters;
      const seq = FlagshipBetSequence[params.betsequence];
      if (isTruthy(seq)) {
         result = seq;
      }
      return result;
   }

   onHidden(): void {
      this.isVisible = false;

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._resetState(true);
   }

   ngOnInit(): void {
      this.gamesGridModel = this.getReportGridModel();
   }

   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   onGamesGridReady(args: GridReadyEvent): void {
      this._grid = args;
   }

   onShown(): void {
      this._setTitle();
      this._setAvailableStrategiesToActiveStrategies();
      this._subscribeToMessages();
   }

   onLoadDataButtonClick() {
      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      if (!this.selectedDataLoadMode) {
         this._toastr.error('Data Load Mode Not Selected');
         return;
      }

      if (this.selectedDataLoadMode.value === 'Date') {
         this.isDatePickerVisible = true;
      } else if (this.selectedDataLoadMode.value === 'Session') {
         this._loadStrategyGameRecordsForActiveSession();
      } else {
         this._toastr.error('Unknown Data Load Mode');
      }
   }

   async applyDateFilter(startDate?: Date, endDate?: Date) {
      if (isNullOrUndefined(startDate)) {
         this._toastr.error('Incorrect date range');
         return;
      }

      if (isNullOrUndefined(endDate)) {
         this._toastr.error('Incorrect date range');
         return;
      }

      this._clearGames();

      startDate = correctedDateAsUTC(startDate);
      endDate = correctedDateAsUTC(endDate);

      if (!this.isDeletedMode) {
         this._loadStrategyGameRecordsByDateRange(startDate, endDate);
      } else {
         if (this.availableStrategies.length === 0) {
            await this._tryLoadDeletedStrategiesByDateRange(startDate, endDate);
         } else {
            this._loadStrategyGameRecordsByDateRange(startDate, endDate);
         }
      }
      this.isDatePickerVisible = false;
   }

   async loadEarlierGames(batchSize: number): Promise<any> {
      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }

      if (!this.selectedDataLoadMode || this.selectedDataLoadMode.value !== 'Session') {
         this._toastr.error('Selected "Data Load Mode" Not Supported');
         return;
      }

      this._grid.api.showLoadingOverlay();

      try {
         const historyStop = this._reportEntries.length
            ? this._reportEntries[0].seqNum
            : 0;
         const qry = new GetStrategyGameRecordsWeb(
            this.selectedStrategy.strategyId,
            this.selectedTicker.ticker,
            historyStop,
            batchSize,
            this.isDeletedMode
         );
         const loadedMessages = await this._shellClient.processQuery<StrategyGameRecordDto[]>(qry);
         if (loadedMessages.length === 0) {
            this._toastr.info('All messages loaded');
            return;
         }
         this._processStrategyGameRecords(loadedMessages, true);
         this._updateStatistics();
         this._grid.api.setRowData(this._reportEntries);
      } catch (error) {
         const errorMessage = 'Failed to load strategy messages';
         this._toastr.error(errorMessage);
      } finally {
         this._grid.api.hideOverlay();
      }
   }

   canLoadData() {
      return this.selectedStrategy
         && this.selectedTicker
         && this.selectedDataLoadMode;
   }

   private _onModeChange() {

      if (!this.isVisible) {
         return;
      }

      setTimeout(() => {
         this.isLoading = true;
         this._resetState(false);
         if (this.isDeletedMode) {
            setTimeout(() => this.isDatePickerVisible = true, 0);
         } else {
            this._setAvailableStrategiesToActiveStrategies();
         }
         this.isLoading = false;
      }, 0);
   }

   private _setAvailableStrategiesToActiveStrategies() {
      this.availableStrategies = this._strategiesService
         .getAllStrategies()
         .filter(str => !str.dispositionStrategies || str.dispositionStrategies.length === 0);
   }

   private async _tryLoadDeletedStrategiesByDateRange(startDate: Date, endDate: Date) {
      if (!startDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!endDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      const grid = this._grid;

      if (!grid) {
         this._toastr.error('Grid Not Initialized');
         return;
      }

      this._clearGames();

      grid.api.showLoadingOverlay();

      try {
         const qry = new GetDeletedStrategiesFlagshipReports(startDate, endDate, false);
         const list = await this._shellClient.processQuery<StrategyDto[]>(qry);
         const modelsList: StrategyModel[] = list.map(x => {
            const model = plainToClass(StrategyModel, x);
            model.updateMetadata(this._algoMetadataService);
            return model;
         });

         this.selectedStrategy = this.selectedTicker = null;
         this.availableStrategies.length = 0;
         this.availableStrategies.push(...modelsList);

      } catch (e) {
         this._toastr.error('"Strategies List" was loaded with errors');
         const data = { error: e.stack || e, dateRange: { startDate, endDate } };
         console.error('_tryLoadDeletedStrategiesByDateRange()', data);
      } finally {
         grid.api.hideOverlay();
      }

      this._setTitle();
   }

   private _subscribeToMessages(): void {
      this._unsubscriber = new Subject<any>();

      this._messageBus.of<StrategyGameRecordDto[]>('StrategyGameRecordDto')
         .pipe(
            filter(x => {
               return this.selectedStrategy
                  && isTruthy(this.selectedTicker)
                  && (this.selectedDataLoadMode && this.selectedDataLoadMode.value === 'Session');
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => setTimeout(() => this._onStrategyGameRecord(message.payload), 0));

      this._messageBus.of<MarkLastGameRecordMessage>('MarkLastGameRecordMessage')
         .pipe(
            filter(x => {
               return this.selectedStrategy
                  && isTruthy(this.selectedTicker)
                  && (this.selectedDataLoadMode && this.selectedDataLoadMode.value === 'Session');
            }),
            filter(x => {
               return x.payload.strategyId === this.selectedStrategy.strategyId;
            }),
            takeUntil(this._unsubscriber)
         )
         .subscribe((message) => {
            setTimeout(() => {
               this._onMarkLastGameRecordMessage(message.payload);
            }, 750);
         });
   }

   private async _loadStrategyGameRecordsByDateRange(startDate: Date, endDate: Date) {
      if (!startDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!endDate) {
         this._toastr.error('Date Range not set');
         return;
      }

      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }
      const grid = this._grid;

      if (!grid) {
         this._toastr.error('Grid Not Initialized');
         return;
      }

      this._clearGames();

      grid.api.showLoadingOverlay();

      const qry = new GetStrategyGameRecordsByDateRange(
         this.selectedStrategy.strategyId,
         this.selectedTicker.ticker,
         startDate,
         endDate,
         this.isDeletedMode
      );

      try {
         const dtos = await this._shellClient.processQuery<StrategyGameRecordDto[]>(qry);
         this._onStrategyGameRecord(dtos);
      } catch (err) {
         const message = '"Load Strategy Games" operation completed with errors';
         this._toastr.error(message);
         const error = { error: err.stack || err, message: err.message || message };
         console.error(message, error);
      } finally {
         grid.api.hideOverlay();
      }
   }

   private _loadStrategyGameRecordsForActiveSession(): void {
      if (!this.selectedTicker) {
         this._toastr.error('Ticker Not Selected');
         return;
      }
      if (!this.selectedStrategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }

      this._clearGames();

      this.isLoading = true;

      this._pendingDataContainer.data.length = 0;
      this._pendingDataContainer.isLoading = true;

      const strategyId = this.selectedStrategy.strategyId;
      const ticker = this.selectedTicker.ticker;

      const qry = new GetStrategyGameRecordsWeb(
         strategyId,
         ticker,
         0,
         100,
         this.isDeletedMode
      );

      this._shellClient.processQuery<StrategyGameRecordDto[]>(qry)
         .then((data) => {
            this._pendingDataContainer.isLoading = false;
            this._onStrategyGameRecord(data);
         })
         .catch((err) => {
            const message = '"Load Strategy Games" operation completed with errors';
            this._toastr.error(message);
            const error = { error: err.stack || err, message: err.message || message };
            console.error(message, error);
         })
         .then(() => {
            this.isLoading = false;
            this._pendingDataContainer.isLoading = false;
            this._pendingDataContainer.data.length = 0;
         });
   }

   private _onStrategyGameRecord(dtos: StrategyGameRecordDto[]): void {

      if (this._pendingDataContainer.isLoading) {
         this._pendingDataContainer.data.push(...dtos);
         return;
      }
      if (this._pendingDataContainer.data.length > 0) {
         if (dtos.length > 0) {
            const lastDtoNum = dtos[dtos.length - 1].seqNum;
            const filteredPendingItems = this._pendingDataContainer.data.filter(x => x.seqNum > lastDtoNum);
            dtos.push(...filteredPendingItems);
         } else {
            dtos.push(...this._pendingDataContainer.data);
         }

         this._pendingDataContainer.data.length = 0;
      }

      const filtered = dtos.filter(x => x.strategyId === this.selectedStrategy.strategyId
         && x.ticker === this.selectedTicker.ticker);

      this._processStrategyGameRecords(filtered);

      this._grid.api.setRowData(this._reportEntries);

      this._updateStatistics();
   }

   private _getTickSize(): number {
      if (!this.selectedTicker) {
         return null;
      }
      const instrumentByTicker = this._tradingInstruments.getInstrumentByTicker(this.selectedTicker.ticker);
      if (!instrumentByTicker) {
         return null;
      }
      return instrumentByTicker.tickSize;
   }

   private _updateStatistics(): void {
      const entries = this._reportEntries.slice();
      if (entries.length === 0) {
         return;
      }

      const validGames = entries.filter(x => !isNullOrUndefined(x.actualBet));
      const wins = validGames.filter(x => x.winLoss === WinLoss.Win).length;

      this.percentWins = wins / validGames.length;
      this.netSlippage = entries.map(x => x.slippage).reduce((acc, curr) => acc + curr, 0);
   }

   private _onMarkLastGameRecordMessage(dto: MarkLastGameRecordMessage): void {
      const game = this._reportEntries.find(x => x.gameId === dto.gameId);
      if (game) {
         game.isLast = true;
         this._grid.api.applyTransaction({ update: [game] });
      }
   }

   private _processStrategyGameRecords(dtos: StrategyGameRecordDto[], unshift?: boolean): void {
      const models = dtos.map(x => {
         const model = new FlagshipReportEntryModel(x);
         if (this.ticksOrPoints) {
            model.changeTickPointView(this._getTickSize());
         }
         return model;
      });

      if (!unshift) {
         this._reportEntries.push(...models);
      } else {
         this._reportEntries.unshift(...models);
      }
   }

   private _resetState(isHardReset: boolean) {

      this._clearGames();

      this.selectedTicker = this.selectedStrategy = null;

      this.availableStrategies = [];
      this.availableTickersOfStrategy = [];

      this.systemDetails.system = null;
      this.systemDetails.setupCommonGrid();
      this.systemDetails.setupSpecificGrid();

      if (isHardReset) {
         this.isDeletedMode = false;
         this._setTitle();
         this.selectedDataLoadMode = null;
         this.dateRange = {};
      }
   }

   private _setTitle() {
      if (!this.isDeletedMode) {
         this.title = 'Original Flagship Report';
      } else {
         this.title = 'Original Flagship Report (Deleted Strategies)';
      }
   }

   private _clearGames() {
      this._reportEntries.length = 0;
      if (this._grid) {
         this._grid.api.setRowData([]);
      }
   }

   abstract getReportGridModel(): GridOptions;
}
