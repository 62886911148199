<dx-popup
  title="Sync Override"
  [width]="'95%'"
  [height]="'95%'"
  [showTitle]="true"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [(visible)]="isVisible"
  (onShown)="onDialogShown()"
  (onHidden)="onDialogHidden()"
>
  <div *dxTemplate="let data of 'content'" #syncOverrideContainer style="height: 100%;">
    <div class="main-area">
      <div class="left-side">
        <div class="selecting-area">
          <div class="available">
            <div class="trading-systems">
              <p class="title">Available Trading Systems</p>
              <div class="grid">
                <ag-grid-angular style="width: 100%; height: 100%"
                  class="ag-theme-balham-dark"
                  [gridOptions]="availableTradingSystemsGridOptions"
                ></ag-grid-angular>
              </div>
            </div>
            <div class="manual-positions">
              <p class="title">Available Manual Positions</p>
              <div class="grid">
                <ag-grid-angular style="width: 100%; height: 100%"
                  class="ag-theme-balham-dark"
                  [gridOptions]="availableManualPositionsGridOptions"
                ></ag-grid-angular>
              </div>
            </div>
          </div>

          <div class="separator">
            <div class="trading-systems">
              <div class="select-button">
                <dx-button text=">" (onClick)="selectTradingSystem()"></dx-button>
              </div>
              <div class="deselect-button">
                <dx-button text="<" (onClick)="deselectTradingSystem()"></dx-button>
              </div>
            </div>
            <div class="manual-positions">
              <div class="select-button">
                <dx-button text=">" (onClick)="selectManualPosition()"></dx-button>
              </div>
              <div class="deselect-button">
                <dx-button text="<" (onClick)="deselectManualPosition()"></dx-button>
              </div>
            </div>
          </div>
          
          <div class="selected">
            <div class="trading-systems">
              <p class="title">Selected Trading Systems</p>
              <div class="grid">
                <ag-grid-angular style="width: 100%; height: 100%"
                  class="ag-theme-balham-dark"
                  [gridOptions]="selectedTradingSystemsGridOptions"
                ></ag-grid-angular>
              </div>
            </div>
            <div class="manual-positions">
              <p class="title">Selected Manual Positions</p>
              <div class="grid">
                <ag-grid-angular style="width: 100%; height: 100%"
                  class="ag-theme-balham-dark"
                  [gridOptions]="selectedManualPositionsGridOptions"
                ></ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div class="note-area">
          <span>Note for the operation:</span>
          <dx-text-box [(value)]="this.operationNote"></dx-text-box>
        </div>
      </div>
      <div class="right-side">
        <p class="title">Sync Override Settings</p>
        <div class="list" #listOfAccounts>
          <dx-list [focusStateEnabled]="false" [activeStateEnabled]="false" [dataSource]="accountOverrideOptions"
                  itemTemplate="accountItem">
            <div *dxTemplate="let ai of 'accountItem'">
              <div class="account-item">
                <fieldset>
                  <legend>{{ai.accountCode}}</legend>
                  <div class="account-header">
                    <div class="account-column">Ticker</div>
                    <div class="account-column">Net Pos.</div>
                    <div class="account-column">Sync Px.</div>
                  </div>
                  <div class="account-tickers" *ngFor="let item of ai.tickers">
                    <div class="account-column">
                      {{item.displayName}}
                    </div>
                    <div class="account-column">
                      {{item.netPosition}}
                    </div>
                    <div class="account-column">
                      <dx-number-box [(value)]="item.syncPrice" [min]="0" [step]="item.tickSize"></dx-number-box>
                    </div>
                  </div>
                  <div class="account-footer">
                    <div class="account-column">Accnt.Total:</div>
                    <div class="account-column">&nbsp;</div>
                    <div class="account-column" [ngStyle]="{'color': ai.accountTotal >= 0 ? 'green' : 'red'}">
                      ${{ai.accountTotal}}
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </dx-list>
          <dx-load-panel  [position]="{ my: 'center', at: 'center', of: listOfAccounts }" 
                    [visible]="isCalculating"
          ></dx-load-panel>
        </div>
        <div class="separator">
          <hr />
        </div>
        <div class="totals">
          <!-- <div class="shell-total-title">Shell Total</div>
          <div class="shell-total-number">$1.880</div> -->
          <div class="shell-total-button">
            <dx-button type="default" text="CALCULATE TOTALS" (onClick)="recalculateTotals()"
              [disabled]="isCalculating"></dx-button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="buttons-area">
      <dx-button text="Override" (onClick)="overridePositions()" [disabled]="isCalculating" ></dx-button>
      <dx-button text="Close" [disabled]="isCalculating" (onClick)="onCloseClicked()"></dx-button>
    </div>

    <div class="data-buttons">
      <dx-button text="Load Data" (onClick)="onLoadDataClicked()" [disabled]="isCalculating"></dx-button>
      <dx-button text="Show History" (onClick)="onShowHistoryClicked()" [disabled]="isCalculating"></dx-button>
    </div>

    <dx-load-panel [visible]="isOverriding"
                    [position]="{my: 'center', at: 'center', of: syncOverrideContainer}"
    ></dx-load-panel>

  </div>
</dx-popup>

<ets-sync-override-history></ets-sync-override-history>