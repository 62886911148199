<dx-popup
  (onHidden)="this.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [showCloseButton]="false"
  [showTitle]="true"
  [visible]="this.visible"
  height="auto"
  title="HG Zones Grid Settings"
  width="16vw"
>
  <div *dxTemplate="let data of 'content'">
    <div class="container">
      
      <div class="row inputs">
        
        <div class="setting">
          <div class="input">
            <dx-check-box text="Bypass Parameters" [(value)]="this.bypassParameters">
            </dx-check-box>
          </div>
        </div>
      </div>
      
      <div class="row">
        <hr>
      </div>
      
      <div class="row buttons">
        
        <div class="button">
          <dx-button (onClick)="this.onApply()" text="Apply" width="100%">
          </dx-button>
        </div>
        <div class="button">
          <dx-button (onClick)="this.onCancel()" text="Cancel" width="100%">
          </dx-button>
        </div>
      </div>
    
    </div>
  </div>
</dx-popup>