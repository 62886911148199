import { NgModule } from '@angular/core';
import { StrategySessionHistoryComponent } from './strategy-session-history.component';
import { AgGridModule } from 'ag-grid-angular';
import {
   StrategySessionHistoryAggregatedRowHeaderRendererComponent
} from './strategy-session-history-aggregated-row-header-renderer.component';
import { CommonModule } from '@angular/common';
import { EtsSystemDetailsModule } from '../system-details/system-details.module';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      AgGridModule.withComponents([StrategySessionHistoryAggregatedRowHeaderRendererComponent]),
      EtsSystemDetailsModule,
      DevExtremeModule,
   ],
   exports: [StrategySessionHistoryComponent],
   declarations: [
      StrategySessionHistoryComponent,
      StrategySessionHistoryAggregatedRowHeaderRendererComponent
   ],
   providers: []
})
export class StrategySessionHistoryModule { }
