import { Component } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { getOriginalReportGridModel } from './original-report-games-grid-model';
import { FlagshipReportBase } from '../flagship/flagship-report-base.class';

@Component({
   selector: 'ets-original-flagship-report',
   templateUrl: 'original-flagship-report.component.html',
   styleUrls: ['original-flagship-report.component.scss']
})
export class OriginalFlagshipReportComponent extends FlagshipReportBase {
   getReportGridModel(): GridOptions {
      return getOriginalReportGridModel.bind(this)();
   }
}
