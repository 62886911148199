import { Injectable } from '@angular/core';
import { IDatafeedChartApi } from '../price-chart/data-feed-chart-api';
import { ToastrService } from 'ngx-toastr';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { OpenPositionDataFeed } from './open-position-data-feed';
import { LoggerService } from '../logging/logger-factory.service';
import { StrategiesService } from '../strategies/strategies.service';
import { OpenPositionSnapshotsBackendService } from './open-position-snapshots-backend.interface';

@Injectable({ providedIn: 'root' })
export class OpenPositionDataFeedFactoryService {
  constructor(
    private _backendService: OpenPositionSnapshotsBackendService,
    private _messageBus: MessageBusService,
    private _toastr: ToastrService,
    private _loggerService: LoggerService,
    private _strategiesService: StrategiesService
  ) {
  }

  getDataFeed(componentId: string, chartResolver: () => any): IDatafeedChartApi {
    const openPositionDataFeed = new OpenPositionDataFeed(
      componentId,
      this._backendService,
      this._messageBus,
      this._toastr,
      this._strategiesService,
      chartResolver,
      this._loggerService
    );
    openPositionDataFeed.init();
    return openPositionDataFeed;
  }
}
