import {EventEmitter, Injectable} from '@angular/core';
import {ResourceButton} from './resource-button.interface';
import {isVoid} from '../utils';
import {SettingsStorageService} from "../settings-storage-service.service";
import {UserSettingsService} from "../user-settings.service";

const StorageKey = 'resources.acks';

@Injectable({providedIn: 'root'})
export class ResourceNotificationsService {

    constructor(
        private _userSettingsService: UserSettingsService
    ) {
    }

    private _lastChangedIx: Record<string, number> = {};

    init() {
        const record = this._userSettingsService
            .getValue<Record<string,number>>(StorageKey) || {};
        this._lastChangedIx = record; //JSON.parse(record);
    }

    async ackResource(button: ResourceButton) {
        this._lastChangedIx[button.id] = Math.floor(Date.now() / 1000);
        await this.saveAcks(this._lastChangedIx);
    }

    isAcknowledged(button: ResourceButton): boolean {
        if (isVoid(button.lastChanged)) {
            return true;
        }

        const ackTime = this._lastChangedIx[button.id];

        if (isVoid(ackTime)) {
            return false;
        }

        return ackTime >= button.lastChanged;
    }

    private async saveAcks(ix: Record<string, number>): Promise<void> {
        this._userSettingsService.setValue(StorageKey, ix);
    }
}
