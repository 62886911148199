import { ColDef, FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { AG_DATE_COL_MIN_WIDTH, AG_SYMBOL_COL_MIN_WIDTH, centeredColumnDef, defaultLoadingOverlayTemplate, defaultMoneyCellDefinition, defaultNumberCellFormatter, defaultPriceCellFormatter, DetailCellRendererParams, GetDetailRowDataParams } from 'projects/shared-components/ag-grid-contrib';
import { LedgerRecord } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { isTruthy } from 'projects/shared-components/utils';
import { LedgerNote2CellRendererComponent, LedgerNoteCellRendererComponent } from './ledger-note-cell-renderer';
import { LedgerComponent } from './ledger.component';
import { LedgerSpec } from './ledger.service';

const TRANSTYPE_COL_MIN_WIDTH = 120;

export function getLedgerGridOptions(this: LedgerComponent) {
   const body = document.querySelector('body') as HTMLElement;

   const opts: GridOptions = {
      rowData: [],
      
      defaultColDef: centeredColumnDef,

      columnDefs: getColumnsDefinitions(this),
      
      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      masterDetail: true,

      detailRowAutoHeight: true,

      tooltipShowDelay: 1000,

      frameworkComponents: {
         ledgerNoteCellRenderer: LedgerNoteCellRendererComponent,
         ledgerNote2CellRenderer: LedgerNote2CellRendererComponent,
      },

      detailCellRendererParams: {

         detailGridOptions: {

            defaultColDef: centeredColumnDef,

            rowClass: 'ets-text-centered',

            rowSelection: 'single',

            animateRows: true,

            popupParent: body,

            columnDefs: getDetailColumnDefs(this),

            getRowNodeId: (data: LedgerRecord) => {
               return data.recordId + '';
            },

            onFirstDataRendered: (args: FirstDataRenderedEvent) => {
               args.columnApi.autoSizeAllColumns();
            },

            getContextMenuItems: (args: GetContextMenuItemsParams) => {
               return [
                  {
                     name: 'Size To Fit',
                     action: () => args.api.sizeColumnsToFit()
                  },
                  'autoSizeAll',
                  'copy',
                  'export'
               ];
            },
         },

         getDetailRowData: (args: GetDetailRowDataParams) => {
   
            const ledgerRecord = args.data as LedgerRecord;

            if (!ledgerRecord) {
               return;               
            }

            
            this.isLoading = true;

            const portfolioId = ledgerRecord.portfolioId;
            const comboId = ledgerRecord.comboId;
            const comboGroupId = ledgerRecord.comboGroupId;
            const itemId = `${ledgerRecord.strategyId}|${ledgerRecord.ticker}`;
            const masterRecordId = ledgerRecord.recordId;

            const subSpec: LedgerSpec = {
               portfolioId,
               comboId,
               comboGroupId,
               itemId,
               masterRecordId
            };

            if (isTruthy(subSpec.comboGroupId)) {
               subSpec.bucketType = 'ComboGroup';
            } else if (isTruthy(subSpec.comboId)) {
               subSpec.bucketType = 'Combo';
            } else if (isTruthy(subSpec.portfolioId)) {
               subSpec.bucketType = 'Portfolio';
            } else {
               subSpec.bucketType = '<No Bucket>';
            }

            this.loadSubRecords(subSpec)
               .then((data) => {
                  args.successCallback(data);
               })
               .catch(err => console.error(err))
               .finally(() => this.isLoading = false);
         }

      } as DetailCellRendererParams,

      onGridReady: args => this.onGridReady(args),

      onFirstDataRendered: (params) => {
         params.columnApi.autoSizeAllColumns();
      },

      getRowNodeId: (rowData: LedgerRecord) => rowData.recordId + '',

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Show All Records',
               action: () => this.showAllRecords()
            },
            'separator',
            {
               name: 'Load More ...',
               subMenu: [
                  {
                     name: '50',
                     action: () => this.loadRecords(50, true)
                  },
                  {
                     name: '100',
                     action: () => this.loadRecords(100, true)
                  },
                  {
                     name: '500',
                     action: () => this.loadRecords(500, true)
                  },
                  {
                     name: '1000',
                     action: () => this.loadRecords(1000, true)
                  }
               ]
            },
            'separator',
            {
               name: 'Add Note',
               disabled: !params.node || !params.node.data,
               action: () =>  this.addNoteToRecord(params.node.data)
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export',
            'seprator',
            {
               name: 'Bucket Context',
               icon: '<i class="fas fa-info-circle"></i>',
               action: () => this.showBucketContextHint()
            }
         ];
      },

      getRowStyle: (args) => {
         const data: LedgerRecord = args.data;
         if (!data) {
            return null;
         }
         return data.isEmulator ? { background: 'orange', color: 'black' } : null;
      }
   };

   return opts;
}

function getColumnsDefinitions(comp: LedgerComponent): ColDef[] {
   const cols: ColDef[] = [];

   const clientNameCol: ColDef = {
      headerName: 'Client',
      field: 'clientName'
   };

   const shellNameCol: ColDef = {
      headerName: 'Shell',
      field: 'shellName'
   };

   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: 'tickerDisplayName',
      minWidth: AG_SYMBOL_COL_MIN_WIDTH,
      cellRenderer: 'agGroupCellRenderer',
      sortable: false,
      valueFormatter: (args: ValueFormatterParams) => {
         if (!args.data) {
            return args.value;
         }
         if (args.data.hasMoreRecords) {
            return '... ' + args.value;
         }
         return args.value;
      }
   };
   cols.push(symbolCol);

   const transactionDateCol: ColDef = {
      headerName: 'Trans. Date',
      field: 'transactionDate',
      minWidth: AG_DATE_COL_MIN_WIDTH,
      valueFormatter: (params: ValueFormatterParams) => {
        const msg: LedgerRecord = params.data;
        const frmtTime = comp.timestampsService.getDefaultFormattedDateTime(msg.transactionDate);
        return msg.transactionDate
          ? frmtTime
          : '';
      },
      sort: 'asc',
      sortIndex: 0,
   };
   cols.push(transactionDateCol);

   const transactionTypeCol: ColDef = {
      headerName: 'Trans. Type',
      field: 'transactionType',
      minWidth: TRANSTYPE_COL_MIN_WIDTH,
      tooltipField: 'transactionType',
      cellRenderer: 'ledgerNote2CellRenderer',
      sortable: false,
   };
   cols.push(transactionTypeCol);

   const priceCol: ColDef = {
      headerName: 'Price',
      field: 'price',
      valueFormatter: defaultPriceCellFormatter,
      sortable: false,
   };
   cols.push(priceCol);

   const qtyCol: ColDef = {
      headerName: 'Qty',
      field: 'qty',
      sortable: false,
      valueFormatter: defaultNumberCellFormatter
   };
   cols.push(qtyCol);

   const netPosCol: ColDef = {
      headerName: 'Net Position',
      field: 'netPosition',
      sortable: false,
   };
   cols.push(netPosCol);

   const terminalCol: ColDef = {
      headerName: 'Terminal',
      field: 'terminalName',
      tooltipField: 'terminalName',
      sortable: false,
      // sort: 'asc',
      // sortIndex: 1,
   };
   cols.push(terminalCol);

   const portfolioCol: ColDef = {
      headerName: 'Portfolio',
      field: 'portfolioName',
      tooltipField: 'portfolioName',
      filter: 'agSetColumnFilter',
      sortable: false,
      // sort: 'asc',
      // sortIndex: 2,
   };
   cols.push(portfolioCol);

   const comboNameCol: ColDef = {
      headerName: 'Combo',
      field: 'comboName',
      tooltipField: 'comboName',
      filter: 'agSetColumnFilter',
      sortable: false,
      // sort: 'asc',
      // sortIndex: 3,
   };
   cols.push(comboNameCol);

   const comboGroupNameCol: ColDef = {
      headerName: 'Group',
      field: 'comboGroupName',
      tooltipField: 'comboGroupName',
      filter: 'agSetColumnFilter',
      sortable: false,
      // sort: 'asc',
      // sortIndex: 4,
   };
   cols.push(comboGroupNameCol);
   
   const accountCol: ColDef = {
      headerName: 'Account',
      field: 'accountCode',
      tooltipField: 'accountCode',
      sortable: false,
   };
   cols.push(accountCol);

   const strategyCol: ColDef = {
      headerName: 'Strategy',
      field: 'strategyName',
      tooltipField: 'strategyName',
      sortable: false,
   };
   cols.push(strategyCol);

   let itemPnLCol: ColDef = {
      headerName: 'P&L',
      field: 'itemPnL',
      sortable: false,

   };
   itemPnLCol = Object.assign(itemPnLCol, defaultMoneyCellDefinition);
   cols.push(itemPnLCol);

   let portfolioPnLCol: ColDef = {
      headerName: 'Portfolio P&L',
      field: 'portfolioPnL',
      sortable: false,

   };
   portfolioPnLCol = Object.assign(portfolioPnLCol, defaultMoneyCellDefinition);
   cols.push(portfolioPnLCol);

   let comboPnLCol: ColDef = {
      headerName: 'Combo P&L',
      field: 'comboPnL',
      sortable: false,
   };
   comboPnLCol = Object.assign(comboPnLCol, defaultMoneyCellDefinition);
   cols.push(comboPnLCol);

   let comboGroupPnLCol: ColDef = {
      headerName: 'ComboGroup P&L',
      field: 'comboGroupPnL',
      sortable: false,
   };
   comboGroupPnLCol = Object.assign(comboGroupPnLCol, defaultMoneyCellDefinition);
   cols.push(comboGroupPnLCol);

   let terminalPnLCol: ColDef = {
      headerName: 'Terminal P&L',
      field: 'terminalPnL',
      hide: true,
      sortable: false,

   };
   terminalPnLCol = Object.assign(terminalPnLCol, defaultMoneyCellDefinition);
   cols.push(terminalPnLCol);
   
   let accountPnLCol: ColDef = {
      headerName: 'Account P&L',
      field: 'accountPnL',
      hide: true,
      sortable: false,

   };
   accountPnLCol = Object.assign(accountPnLCol, defaultMoneyCellDefinition);
   cols.push(accountPnLCol);

   return cols;
}

function getDetailColumnDefs(comp: LedgerComponent): ColDef[] {
   const cols: ColDef[] = [];

   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: 'tickerDisplayName',
      minWidth: AG_SYMBOL_COL_MIN_WIDTH
   };
   cols.push(symbolCol);

   const transactionDateCol: ColDef = {
      headerName: 'Trans. Date',
      field: 'transactionDate',
      minWidth: AG_DATE_COL_MIN_WIDTH,
      valueFormatter: (params: ValueFormatterParams) => {
        const msg: LedgerRecord = params.data;
        const frmtTime = comp.timestampsService.getDefaultFormattedDateTime(msg.transactionDate);
        return msg.transactionDate
          ? frmtTime
          : '';
      },
   };
   cols.push(transactionDateCol);

   const transactionTypeCol: ColDef = {
      headerName: 'Trans. Type',
      field: 'transactionType',
      minWidth: TRANSTYPE_COL_MIN_WIDTH,
      tooltipField: 'transactionType',
   };
   cols.push(transactionTypeCol);

   const priceCol: ColDef = {
      headerName: 'Price',
      field: 'price',
      valueFormatter: defaultPriceCellFormatter
   };
   cols.push(priceCol);

   const qtyCol: ColDef = {
      headerName: 'Qty',
      field: 'qty'
   };
   cols.push(qtyCol);

   const netPosCol: ColDef = {
      headerName: 'Net Position',
      field: 'netPosition'
   };
   cols.push(netPosCol);

   const strategyCol: ColDef = {
      headerName: 'Strategy',
      field: 'strategyName',
      tooltipField: 'strategyName',
   };
   cols.push(strategyCol);

   let itemPnLCol: ColDef = {
      headerName: 'P&L',
      field: 'itemPnL'
   };
   itemPnLCol = Object.assign(itemPnLCol, defaultMoneyCellDefinition);
   cols.push(itemPnLCol);

   let portfolioPnLCol: ColDef = {
      headerName: 'Portfolio P&L',
      field: 'portfolioPnL'
   };
   portfolioPnLCol = Object.assign(portfolioPnLCol, defaultMoneyCellDefinition);
   cols.push(portfolioPnLCol);

   let comboPnLCol: ColDef = {
      headerName: 'Combo P&L',
      field: 'comboPnL'
   };
   comboPnLCol = Object.assign(comboPnLCol, defaultMoneyCellDefinition);
   cols.push(comboPnLCol);

   let comboGroupPnLCol: ColDef = {
      headerName: 'ComboGroup P&L',
      field: 'comboGroupPnL'
   };
   comboGroupPnLCol = Object.assign(comboGroupPnLCol, defaultMoneyCellDefinition);
   cols.push(comboGroupPnLCol);

   const terminalCol: ColDef = {
      headerName: 'Terminal',
      field: 'terminalName',
      tooltipField: 'terminalName',
   };
   cols.push(terminalCol);

   const portfolioCol: ColDef = {
      headerName: 'Portfolio',
      field: 'portfolioName',
      tooltipField: 'portfolioName',
      filter: 'agSetColumnFilter',
   };
   cols.push(portfolioCol);

   const comboNameCol: ColDef = {
      headerName: 'Combo',
      field: 'comboName',
      tooltipField: 'comboName',
      filter: 'agSetColumnFilter',
   };
   cols.push(comboNameCol);

   const comboGroupNameCol: ColDef = {
      headerName: 'Group',
      field: 'comboGroupName',
      tooltipField: 'comboGroupName',
      filter: 'agSetColumnFilter',
   };
   cols.push(comboGroupNameCol);

   return cols;
}
