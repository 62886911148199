import { Component, Input, OnInit } from '@angular/core';
import { CashFlowStrategyExpirationsSettingsModel } from './CashFlowStrategyExpirationsSettingsModel';

@Component({
   selector: 'ets-adjustment-grid-expirations-settings',
   templateUrl: './adjustment-grid-expirations-settings.component.html',
   styleUrls: [
      './adjustment-grid-expirations-settings.component.scss',
      '../../adjustment-grid-common-style.scss',
      '../adjustment-grid-parameters-style.scss',
   ],
})
export class AdjustmentGridExpirationsSettingsComponent implements OnInit {
   constructor(
   ) { }

   //
   @Input() settings: CashFlowStrategyExpirationsSettingsModel;

   //
   @Input() disabled: boolean;

   //
   ngOnInit(): void { }

}
