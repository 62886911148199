import {Injectable} from '@angular/core';
import {getShortUUID, isNullOrUndefined, isVoid, removeFromArray} from "../utils";
import * as Enumerable from "linq";
import {SessionService} from "../authentication/session-service.service";
import {MessageBusService} from "../message-bus.service";
import {PricingGridStrategyDescriptor} from "./model/pricing-grid-strategy.descriptor";
import {UserSettingsService} from "../user-settings.service";

export interface OpgTemplate {
    templateId: string;
    templateName: string;
    underlying: string;
    descriptors: PricingGridStrategyDescriptor[];
}

const CallTemplatesStorageKey: string = 'opg.templates.call';
const PutTemplatesStorageKey: string = 'opg.templates.put';

@Injectable({providedIn: 'root'})
export class OptionPricingGridTemplatesService {

    constructor(
        private readonly _userSettingsService: UserSettingsService,
        private readonly _sessionService: SessionService,
        private readonly _messageBusService: MessageBusService
    ) {
    }

    private _callTemplates: {[ix: string]: OpgTemplate[] } = {};
    private _putTemplates: { [ix: string]: OpgTemplate[] } = {};

    init() {
        this._callTemplates = {};
        this._putTemplates = {};
    }

    getCallTemplates(userId?: string, refresh?: boolean): OpgTemplate[] {

        refresh = false;

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        let container = this._callTemplates[userId];

        if (isVoid(container) || refresh) {
            container = this._userSettingsService.getValue<OpgTemplate[]>(CallTemplatesStorageKey, userId) || [];

            let hadEmptyId = false;

            container.forEach(x => {
                x.descriptors.forEach(d => {
                    if (isVoid(d.strategyId)) {
                        hadEmptyId = true;
                        d.strategyId = getShortUUID();
                    }
                });
            });

            this._callTemplates[userId] = container;

            if (hadEmptyId) {
                this._userSettingsService.setValue(CallTemplatesStorageKey, container.slice(), userId);
            }
        }

        const sorted = Enumerable
            .from(container)
            .orderBy(x => x.templateName.toLowerCase())
            .toArray();
        return sorted;
    }

    getPutTemplates(userId?: string, refresh?: boolean): OpgTemplate[] {

        refresh = false

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        let container = this._putTemplates[userId];

        if (isVoid(container) || refresh) {
            container = this._userSettingsService.getValue<OpgTemplate[]>(PutTemplatesStorageKey, userId) || [];

            let hadEmptyId = false;

            container.forEach(x => {
                x.descriptors.forEach(d => {
                    if (isVoid(d.strategyId)) {
                        hadEmptyId = true;
                        d.strategyId = getShortUUID();
                    }
                });
            });

            this._putTemplates[userId] = container;

            if (hadEmptyId) {
                this._userSettingsService.setValue(PutTemplatesStorageKey, container.slice(), userId);
            }
        }

        const sorted = Enumerable
            .from(container)
            .orderBy(x => x.templateName.toLowerCase())
            .toArray();

        return sorted;
    }

    save(tpl: OpgTemplate, targetSide: 'calls' | 'puts', userId?: string) {

        if (isVoid(tpl)) {
            return;
        }

        tpl.descriptors.forEach(x => {
            x.templateId = tpl.templateId;
            x.templateName = tpl.templateName;
        });

        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return;
        }

        const sideContainer = targetSide === 'calls' ? this._callTemplates : this._putTemplates;

        if (isNullOrUndefined(sideContainer)) {
            return;
        }

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        const container = sideContainer[userId];

        if (isNullOrUndefined(container)) {
            return;
        }

        if (container.findIndex(x => x.templateId ===  tpl.templateId) === -1) {
            container.push(tpl);
        }

        const storageKey= targetSide === 'calls' ? CallTemplatesStorageKey : PutTemplatesStorageKey;

        this._userSettingsService.setValue(storageKey, container.slice(), userId);

        this._messageBusService.publish({
            topic: 'Opg.TemplatesChanged',
            payload: {
                template: tpl,
                side: targetSide,
                userId: userId
            }
        });
    }

    remove(tpl: OpgTemplate, targetSide: 'calls' | 'puts', userId?: string) {

        if (targetSide !== 'calls' && targetSide !== 'puts') {
            return;
        }

        const sideContainer = targetSide === 'calls'
            ? this._callTemplates
            : this._putTemplates;

        if (isNullOrUndefined(sideContainer)) {
            return;
        }

        if (isVoid(userId)) {
            userId = this._sessionService.sessionData.userId;
        }

        const container = sideContainer[userId];

        if (isNullOrUndefined(container)) {
            return;
        }

        removeFromArray(container, tpl);

        const storageKey= targetSide === 'calls'
            ? CallTemplatesStorageKey
            : PutTemplatesStorageKey;

        this._userSettingsService.setValue(storageKey, container.slice(), userId);

        this._messageBusService.publish({
            topic: 'Opg.TemplatesChanged',
            payload: {
                template: tpl,
                side: targetSide,
                userId: userId
            }
        });
    }

}