<div class="container" [ngClass]="{'ets-hidden': !this.isActive}">
   <div class="automation-cp" #root>
   
      <div class="header-section">
   
         <ets-automation-cp-header>
         </ets-automation-cp-header>
   
      </div>
   
      <div class="strategy-controls">
         <ets-automationcp-adj-strategy-controls
            [combo]="this.combo"
            (onCreateStrategy)="this.onCreateStrategyClicked()"
            (onUpdateStrategy)="this.onUpdateStrategyClicked($event)"
            >
         </ets-automationcp-adj-strategy-controls>
      </div>
   
      <div class="body-section">
   
         <ets-automation-cp-body
            [combo]="this.combo"
            [automationCpMode]="this.automationCpMode"
            [strategy]="this.strategy"
            >
         </ets-automation-cp-body>
   
      </div>
   
      <dx-load-panel
         [position]="{my: 'center', at: 'center', of: root}"
         [visible]="this.isLoading"
         >
      </dx-load-panel>
   
   </div>
</div>