import { Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DetectMethodChanges } from '../utils';

@Component({
  template: `
    <dx-popup   class="popup"
            [width]="500"
            [height]="200"
            [showTitle]="false"
            [closeOnOutsideClick]="false"
            [resizeEnabled]="false"
            [dragEnabled]="false"
            [visible]="true">
        <div *dxTemplate="let data of 'content'">
            <div class="message">
                <p>
                    Your session has been expired. Click 'OK' to re-login
                </p>
            </div>
            <div class="ok-button">
                <dx-button  
                        text="OK"
                        (onClick)="logout()"
                        width="100"
                        type="default"></dx-button>
            </div>
        </div>
    </dx-popup>
    `,
  styles: [
    `
      .message {
        position: relative;
        height: 68%;
      }

      .ok-button {
        position: relative;
        text-align: right;
        margin-right: 25px;
      }

      p {
        font-size: 1.5em;
        text-align: center;
        padding-top: 50px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent {

  constructor(private router: Router, private _changeDetector: ChangeDetectorRef) {}

  @DetectMethodChanges({isAsync: true})
  async logout(): Promise<void> {
    await this.router.navigate(['/login']);
  }
}
