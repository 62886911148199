import { Injectable } from '@angular/core';
import { Logger } from '../../../../shared-components/logging/logger.interface';
import { ShellClientService } from '../../../../shared-components/shell-communication/shell-client.service';
import { SecureElementDto } from '../../../../shared-components/shell-communication/dtos/secure-element-dto.class';
import {
   GetShellSecurityContext
} from '../../../../shared-components/shell-communication/operations/access-control/get-shell-security-context.class';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';

@Injectable({ providedIn: 'root' })
export class WebtraderAccessControlService implements AccessControlService {

   constructor(
      private readonly _shellClient: ShellClientService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('AccessControlService');
   }

   private readonly _logger: Logger;
   private _context: Record<string, SecureElementDto>;


   init(): Promise<any> {
      this._context = {};
      const qry = new GetShellSecurityContext();
      return this._shellClient.processQuery<SecureElementDto[]>(qry)
         .then(data => {
            data.forEach(elem => this._context[elem.secureElementId] = elem);
            this._logger.info('Initialized');
         });
   }

   isSecureElementAvailable(id: string): boolean {
      return id in this._context;
   }
}
