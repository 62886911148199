import { NgModule } from '@angular/core';
import { UnaccountedDataComponent } from './unaccounted-data.component';
import { AgGridModule } from 'ag-grid-angular';
import { AllocateItemDialogComponent } from './allocate-dialog/allocate-item-dialog.component';
import { CommonModule } from '@angular/common';
import { UnaccountedDataIndicatorComponent } from './indicator/unaccounted-data-indicator.component';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      AgGridModule.withComponents([]),
      DevExtremeModule
   ],
   exports: [UnaccountedDataComponent, UnaccountedDataIndicatorComponent],
   declarations: [UnaccountedDataComponent, AllocateItemDialogComponent, UnaccountedDataIndicatorComponent],
   providers: []
})
export class UnaccountedDataModule { }
