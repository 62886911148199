<div class="root-container">
  
  <div class="color-picker">
    <dx-color-box
      [(value)]="this.selectedColor"
      [openOnFieldClick]="true"
      [showDropDownButton]="false"
      (onValueChanged)="this.onColorSelected($event.value)"
      [inputAttr]="this.getColorBoxInputAttr()"
    >
    </dx-color-box>
  </div>
  
  <div class="presets">
    <div #btn>
      <dx-button (onClick)="this.onPaletteButtonClicked()" icon="palette"></dx-button>
    </div>
    
    <div class="popover">
      <dx-popover
        (onHidden)="this.onPaletteHidden()"
        [target]="btn"
        [visible]="this.paletteVisible"
        contentTemplate="popoverContent"
      >
        <div *dxTemplate="let data of 'popoverContent'" class="palette-popover">
          <div class="container">
            <div class="side" *ngIf="this.showCallPalette">
              <div class="palette">
                <div
                  (click)="this.onColorSelected(color)"
                  *ngFor="let color of this.callPalette"
                  class="color-block"
                  [style.background]="color"
                >&nbsp;</div>
              </div>
            </div>
            <div class="side" *ngIf="this.showPutPalette">
              <div class="palette">
                <div
                  (click)="this.onColorSelected(color)"
                  *ngFor="let color of this.putPalette"
                  class="color-block"
                  [style.background]="color"
                >&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </dx-popover>
    </div>
  </div>
</div>