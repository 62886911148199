import { AlgoDescriptionBuilderBase } from '../../algo-description-builder-base.class';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { 
  TradingInstrumentDisplayNameService 
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { FlagshipBetStyle, FlagshipBetSequence } from 'projects/shared-components/edit-strategy-dialog/parameters-controls/flagships/flagship-algo-parameters-control';
import { toNegativeInfinity } from 'linq';

export class FlagshipAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
  
  constructor(displayNameService: TradingInstrumentDisplayNameService, private _algoId: string) {
    super(displayNameService);  
  }

  getSymbolPropertyNames(): string[] {
    return ['symbol'];
  }

  getAlgoName(parameters: StrategyParameters): string {
    let name = 'Flagship';
    switch (this._algoId) {
      case EtsConstants.algorithms.flagships.followAlgoId: {
        name = 'F-Follow';
        break;
      } 
      case EtsConstants.algorithms.flagships.s40AlgoId: {
        name = 'F-S40';
        break;
      }
      case EtsConstants.algorithms.flagships.outrightAlgoId: { 
        name = 'F-Outright';
        break;
      }
      case EtsConstants.algorithms.flagships.beforeLastAlgoId: {
        name = 'F-Before Last';
        break;
      }
      case EtsConstants.algorithms.flagships.oppositeBeforeLastAlgoId: {
        name = 'F-Opposite Before Last';
        break;
      }
      case EtsConstants.algorithms.flagships.majority: {
        name = 'F-Majority';
        break;
      }
      case EtsConstants.algorithms.flagships.minority: {
        name = 'F-Minority';
        break;
      }
      case EtsConstants.algorithms.flagships.zigZag: {
        name = 'F-ZigZag';
        break;
      }
      case EtsConstants.algorithms.flagships.fmr: {
        name = 'F-FMR';
        break;
      }
      default:
        break;
    }
    return name;
  }
  
  getDescription(parameters: StrategyParameters): string {
    const defaultValue = 'N/A';
    
    if (!parameters) {
      return defaultValue;
    }

    let builder = '';
    let symbol = parameters.symbol || defaultValue;
    if (symbol !== defaultValue) {
      symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
    }
    
    builder += `[${symbol}] : `;
    builder += `M-${parameters.multiplier || defaultValue}`;
    builder += `, CTlv-${parameters.ctlvalue}`;
    builder += `, CTlt-${parameters.ctltimes}`;
    
    const style = this._getShortBetStyle(Number(parameters.betstyle));
    builder += `, Style-${style}`;

    const seq = this._getShortBetSequence(Number(parameters.betsequence));
    builder += `, Seq-${seq}`;

    return builder;
  }

  private _getShortBetStyle(betStyle: FlagshipBetStyle): string {
    switch (betStyle) {
      case FlagshipBetStyle.Factor: return 'Factor';
      case FlagshipBetStyle.Flat: return 'Flat';
      case FlagshipBetStyle.HighProbability: return 'HP';
      case FlagshipBetStyle.Pyramid: return 'Pyr';
      case FlagshipBetStyle.PositiveProgression: return '+Progr';
      case FlagshipBetStyle.NegativeProgression: return '-Progr';
      default: return 'N/A';
    }
  }

  private _getShortBetSequence(betsequence: FlagshipBetSequence): string {
    switch (betsequence) {
      case FlagshipBetSequence.Everyone: return 'Every';
      case FlagshipBetSequence.LastWasWinner: return 'LastWin';    
      case FlagshipBetSequence.XofY: return 'X of Y';
      default: return 'N/A';
    }
  }
}
