<dx-popup
  width="80%"
  height="60%"
  [showTitle]="true"
  title="Risk Management"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [visible]="isVisible"
  (onHidden)="onHidden()"
  (onShown)="onShown()"
>
  <div *dxTemplate="let data of 'content'" id="risk_management_popup" class="view-container">
    
      <as-split class="split" direction="vertical" [gutterSize]="5">
        <as-split-area [size]="rulesSectionSize">
          <ag-grid-angular
            class="ag-theme-balham-dark"
            [gridOptions]="riskRulesGridModel"
          ></ag-grid-angular>
        </as-split-area>
        <as-split-area [size]="violationsSectionSize">
          <ag-grid-angular
            class="ag-theme-balham-dark"
            [gridOptions]="violationsGridModel"
          ></ag-grid-angular>
        </as-split-area>
      </as-split>
    </div>
  <
</dx-popup>

<ets-risk-rule-editor></ets-risk-rule-editor>