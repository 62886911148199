import {StrategyParameters} from '../../strategies/strategy-parameters.enum';
import {AlgoDescriptionBuilderBase} from '../algo-description-builder-base.class';

export class BullBearAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
  
  getSymbolPropertyNames(): string[] {
    return ['symbol'];
  }


  getAlgoName(parameters: StrategyParameters): string {
    const defaultValue = 'N/A';
    if (!parameters) {
      return defaultValue;
    }
    const mode = parameters.mode || defaultValue;
    return mode;
  }

  getDescription(parameters: StrategyParameters): string {
    const defaultValue = 'N/A';
    if (!parameters) {
      return defaultValue;
    }
    let builder = '';
    let symbol = parameters.symbol || defaultValue;
    if (symbol !== defaultValue) {
      symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
    }
    builder += `[${symbol}]`;

    let mode = parameters.mode || defaultValue;
    if (mode === 'BULLBEAR') {
      mode = 'BULL/BEAR';
    }

    let bbMode = parameters.bbmode || defaultValue;
    if (bbMode !== defaultValue) {
      bbMode = bbMode.substring(0, 1).toUpperCase();
    }

    const multiplier = parameters.multiplier || defaultValue;
    builder += (': ' + 'SMode-' + mode + ', BBMode-' + bbMode + ', M-' + multiplier);

    if (bbMode === 'TRAIL' || bbMode === 'RESET') {
      builder += (', T-' + parameters.wltrigger || defaultValue);

      if (bbMode === 'TRAIL') {
        builder += (', TV-' + parameters.trailvalue || defaultValue);
        builder += (', TF-' + parameters.trailfreq || defaultValue);
      }
    }

    const eWL = parseFloat(parameters.explicitwaterline || defaultValue);
    if (!isNaN(eWL)) {
      if (eWL > 0) {
        builder += (', eWL-' + eWL);
      }
    }

    const immStart = (parameters.immediatestart || defaultValue).toLowerCase() === 'true';
    if (immStart) {
      if (immStart) {
        builder += ', IS';
      }
    }

    const delayedStart = parseFloat(parameters.delayedstart || defaultValue);
    if (!isNaN(delayedStart)) {
      if (delayedStart > 0) {
        builder += (', DS-' + delayedStart);
      } else if (delayedStart < 0) {
        builder += (', DS-(' + Math.abs(delayedStart) + ')');
      }
    }

    const useFactor = (parameters.usefactor || defaultValue).toLocaleLowerCase() === 'true';
    if (useFactor) {
      builder += (', F-' + parameters.factor || defaultValue);
      builder += (', TL-' + parameters.tradelevel || defaultValue);
      builder += (', MP-' + parameters.maxposition || defaultValue);
    }

    const fe = parseFloat(parameters.fe1 || defaultValue);
    if (!isNaN(fe)) {
      if (fe > 0) {
        builder += (', FE-' + fe);
      }
    }

    const contractAddedPer = parseFloat(parameters.mmcontractaddedper || defaultValue);
    if (!isNaN(contractAddedPer)) {
      if (contractAddedPer > 0) {
        builder += (', CAP-' + parameters.mmcontractaddedper || defaultValue);
        builder += (', MSL-' + parameters.mmmaxscalelevels || defaultValue);

        const resetEnabled = (parameters.mmisreset || defaultValue).toLowerCase() === 'true';
        if (resetEnabled) {
          builder += (', R');
        }
      }
    }

    const decreasePer = parseFloat(parameters.mmContractsDecreasePer || defaultValue);
    if (!isNaN(decreasePer)) {
      if (decreasePer > 0) {
        builder += (', DeP-' + parameters.mmContractsDecreasePer || defaultValue);
        builder += (', CnD-' + parameters.mmContractDecreaseNum || defaultValue);
        builder += (', MSL-' + parameters.mmMaxScaleLevels || defaultValue);

        const resetEnabled = (parameters.mmIsReset || defaultValue).toLowerCase() === 'true';
        if (resetEnabled) {
          builder += (', R');
        }

        const regressMM = (parameters.mmIsRegress || defaultValue).toLowerCase() === 'true';
        if (regressMM) {
          builder += (', R');
        }
      }
    }

    if (eWL !== 0) {
      builder += ', Curr.WL-' + eWL;
    } else {
      builder += (', Curr.WL-' + 0);
    }

    return builder;
  }
}
