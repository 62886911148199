import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {delay, DetectMethodChanges, DxValueChanged, isValidNumber, isVoid} from "../../../utils";
import {OptionExpirationDescriptor} from "../../../shell-communication/shell-dto-protocol";
import {LastQuoteCacheService} from "../../../last-quote-cache.service";
import {OptionsChainService} from "../../../option-chains.service";
import {ExpirationPrice, HgZonesGridRow, HgZonesGridSectionComponent} from "../hg-zones-grid-section.component";
import {makeOptionTicker, OptionTicker, OptionType, parseOptionTicker} from "../../../options-common/options.model";
import {MessageBusService} from "../../../message-bus.service";
import {QuoteDto} from "../../../shell-communication/dtos/quote-dto.class";
import {ToastrService} from "ngx-toastr";
import {AtmStrikeService} from "../../../common-services/atm-strike-service/atm-strike.service";
import {DxPopoverComponent} from "devextreme-angular";

export interface HgZonesGridCalculatorConfig {
    targetCell: HTMLTableCellElement;
    underlying: string;
    expirations: ExpirationPrice[];
    selectedExpiration: ExpirationPrice;
    row: HgZonesGridRow;
}

@Component({
    selector: 'ets-hg-zones-grid-calculator',
    templateUrl: 'hg-zones-grid-calculator.component.html',
    styleUrls: ['./hg-zones-grid-calculator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgZonesGridCalculatorComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _atmStrikeService: AtmStrikeService
    ) {
    }

    private _currentTicker: string;

    @Input() target: any;

    visible = false;

    isLoading: boolean;

    expirationList: ExpirationPrice[] = [];

    availableExpirations: ExpirationPrice[] = [];

    row: { strike: number };

    optionType: 'Call' | 'Put';

    selectedExpiration: ExpirationPrice;

    currentPrice: number = undefined;

    targetPrice: number = undefined;

    marketMove: number;

    destinationStrike: number;

    destinationTicker: string;

    destinationPrice: number;

    canSelectCall = true;

    canSelectPut = true;

    @Input() section!: HgZonesGridSectionComponent;

    get currentTicker(): string {
        if (isVoid(this._currentTicker)) {
            if (!isVoid(this.selectedExpiration)) {
                if (!isVoid(this.optionType)) {
                    const ticker = makeOptionTicker(
                        this.selectedExpiration.expirationDescriptor,
                        this.optionType === 'Call' ? OptionType.Call : OptionType.Put,
                        this.row.strike
                    );
                    this._currentTicker = ticker;
                }
            }
        }
        return this._currentTicker;
    }

    ngOnInit() {
        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .subscribe(msg => this.onQuote(msg.payload));
    }

    @DetectMethodChanges()
    show(cfg: HgZonesGridCalculatorConfig) {

        this.target = cfg.targetCell;
        this.row = cfg.row;
        this.expirationList = cfg.expirations;
        this.selectedExpiration = cfg.selectedExpiration;

        if (!isVoid(this.selectedExpiration)) {
            this.optionType = this.selectedExpiration.side === 'Puts' ? 'Put' : 'Call';

            this.availableExpirations = this.expirationList
                .filter(x => x.side === this.selectedExpiration.side);

            if (this.optionType === 'Call') {
                this.canSelectPut = false;
            } else if (this.optionType === 'Put') {
                this.canSelectCall = false;
            }
        }

        if (!isVoid(this.currentTicker)) {
            const q = this._lastQuoteCache.getLastQuote(this.currentTicker);
            if (isVoid(q)) {
                this._lastQuoteCache.subscribeIfNotYet(this.currentTicker);
            } else {
                this.currentPrice = q.mid;
            }
        }

        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.canSelectPut = this.canSelectCall = true;
        this.expirationList = [];
        this.availableExpirations = [];
        this._currentTicker = undefined;
        this.row = undefined;
        this.selectedExpiration = undefined;
        this.currentPrice = undefined;
        this.targetPrice = undefined;
        this.target = undefined;
        this.optionType = undefined;
        this.resetResult();
        this.visible = false;
    }

    @DetectMethodChanges()
    setOptionType(oType: 'Call' | 'Put') {

        this.optionType = oType;

        const side = oType === 'Call' ? 'Calls' : 'Puts';

        this.availableExpirations = this.expirationList.filter(x => x.side === side);

        if (!isVoid(this.selectedExpiration)) {
            const sameExp = this.availableExpirations.find(x => x.expiration === this.selectedExpiration.expiration);
            this.selectedExpiration = sameExp;
        }

        if (isVoid(this.selectedExpiration)) {
            if (this.availableExpirations.length === 1) {
                this.selectedExpiration = this.availableExpirations[0];
            }
        }

        this.changeTicker();

    }

    private changeTicker() {

        this.currentPrice = undefined;
        this._currentTicker = undefined;

        this.destinationPrice = undefined;
        this.destinationTicker = undefined;
        this.destinationStrike = undefined;
        this.marketMove = undefined;

        if (!isVoid(this.currentTicker)) {
            const lq = this._lastQuoteCache.getLastQuote(this.currentTicker);
            if (isVoid(lq)) {
                this._lastQuoteCache.subscribeIfNotYet(this.currentTicker);
            } else {
                this.currentPrice = lq.mid;
            }
        }
    }

    @DetectMethodChanges()
    hide() {
        this.visible = false;
    }

    private onQuote(payload: QuoteDto[]) {

        if (isVoid(this.currentTicker)) {
            return;
        }

        const quoteDto = payload.find(x => x.ticker === this.currentTicker);

        let shouldUpdate = false;

        if (!isVoid(quoteDto)) {
            this.currentPrice = quoteDto.mid;
            shouldUpdate = true;
        }

        if (!isVoid(this.destinationTicker)) {
            const destQuote = payload.find(x => x.ticker === this.destinationTicker);
            if (!isVoid(destQuote)) {
                this.destinationPrice = destQuote.mid;
                shouldUpdate = true;
            }
        }

        if (!shouldUpdate) {
            return;
        }

        this._changeDetector.detectChanges();
    }

    @DetectMethodChanges({isAsync: true})
    async onTargetPriceChanged($event: DxValueChanged<number>) {

        if (!isValidNumber(this.targetPrice, true)) {
            this.resetResult();
            return;
        }

        this.isLoading = true;

        await delay(250);

        try {
            await this.calculateMarketMove();
        } finally {
            this.isLoading = false;
        }

    }

    private async calculateMarketMove(): Promise<void> {

        this.resetResult();

        let rowIx = this.section.rows.indexOf(this.row as any);

        const ul = this.selectedExpiration.expirationDescriptor.underlyingSymbol;

        const targetPx = this.targetPrice;

        if (!isValidNumber(targetPx)) {
            throw new Error('Bad Target Price');
        }

        let currentPx = this.currentPrice;

        if (!isValidNumber(currentPx)) {
            currentPx = this._atmStrikeService.getCurrentAtm(ul);
            if (!isValidNumber(currentPx)) {
                throw new Error('Cannot Determine Reference Price');
            }
        }

        if (Math.trunc(targetPx) === Math.trunc(currentPx)) {
            this.marketMove = 0;
            this.destinationTicker = this.currentTicker;
            return;
        }

        const strikeStep = ul === 'SPX' ? 5 : 1;

        let strikeStepSign = 1;

        if (this.optionType === 'Call') {
            if (targetPx < currentPx) {
                strikeStepSign = -1;
            }
        } else if (this.optionType === 'Put') {
            if (targetPx > currentPx) {
                strikeStepSign = -1;
            }
        }

        const ticker = parseOptionTicker(this.currentTicker);

        if (isVoid(ticker)) {
            throw new Error('Cannot Determine Current Ticker');
        }

        let cntr = 0;

        let move: number;
        let destStrike = this.row.strike;

        const resultSign = targetPx > currentPx ? 1 : -1;

        let lastResult: any;

        do {

            rowIx = rowIx + strikeStepSign;

            if (rowIx >= this.section.rows.length) {
                break;
            }

            if (rowIx < 0) {
                break;
            }

            const newStrike = this.section.rows[rowIx].strike;

            const newTicker = makeOptionTicker(
                this.selectedExpiration.expirationDescriptor,
                ticker.type,
                newStrike
            );

            const q = await this._lastQuoteCache.getLastQuoteWithAwait(newTicker);

            if (isVoid(q)) {
                continue;
            }

            const diff = q.mid - targetPx;

            if (Math.sign(diff) !== resultSign) {
                lastResult = { strike: newStrike, diff: Math.abs(diff) };
                continue;
            }

            let endStrike= newStrike;

            if (!isVoid(lastResult)) {

                const strikeDistance = endStrike - lastResult.strike;

                const noBetween = ul === 'SPX' && strikeDistance === 5
                    || strikeDistance === 1;

                if (noBetween) {
                    if (lastResult.diff < Math.abs(diff)) {
                        endStrike = lastResult.strike;
                    }
                } else {

                    let middle = strikeDistance / 2;

                    if (!Number.isInteger(middle)) {
                        middle = Math.floor(middle / strikeDistance) * strikeDistance;
                    }

                    if (!isValidNumber(middle, true)) {
                        middle = strikeDistance;
                    }

                    endStrike = newStrike - middle;
                }
            }

            const currentAtm = this._atmStrikeService.getCurrentAtm(ul);
            move =  this.row.strike - endStrike;
            destStrike = currentAtm + move;

            this.destinationTicker = makeOptionTicker(
                this.selectedExpiration.expirationDescriptor,
                ticker.type,
                destStrike
            );

            this.destinationPrice = q.mid;

            break;

        } while (cntr < 25);

        if (isVoid(move) || isVoid(destStrike)) {
            this._toastr.error('Failed To Calculate Market Move And/Or Destination Strike');
        }

        this.marketMove = move;

        this.destinationStrike = destStrike;
    }

    @DetectMethodChanges()
    onExpirationChanged() {
        this.changeTicker();
    }

    private resetResult() {
        this.marketMove = undefined;
        this.destinationStrike = undefined;
        this.destinationTicker = undefined;
        this.destinationPrice = undefined;
    }
}