import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { EtsSymbolPickerModule } from '../../../../shared-components/symbol-picker/symbol-picker.module';
import { StrategyCommanderComponent } from './strategy-commander.component';
import { StrategyCommanderRuleEditorComponent } from './strategy-commander-rule-editor/strategy-commander-rule-editor.component';
import { StartStrategiesByFamilyComponent } from './strategy-commander-rule-editor/actions/start-strategies-by-family.component';
import { MovingAverageCrossoverComponent } from './strategy-commander-rule-editor/handlers/moving-average-crossover.component';
import { StartSpecificStrategyComponent } from './strategy-commander-rule-editor/actions/start-specific-strategy.component';
import { ExitSpecificStrategyComponent } from './strategy-commander-rule-editor/actions/exit-specific-strategy.component';
import { ExitStrategiesByFamilyComponent } from './strategy-commander-rule-editor/actions/exit-strategies-by-family.component';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      EtsSymbolPickerModule,
      AgGridModule.withComponents([])
   ],
   exports: [StrategyCommanderComponent, StrategyCommanderRuleEditorComponent],
   declarations: [
      StrategyCommanderComponent,
      StrategyCommanderRuleEditorComponent,

      // handlers
      MovingAverageCrossoverComponent,

      // actions
      StartStrategiesByFamilyComponent,
      StartSpecificStrategyComponent,
      ExitStrategiesByFamilyComponent,
      ExitSpecificStrategyComponent,
   ],
   providers: []
})
export class EtsStrategyCommanderModule { }
