import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthTokenExpiredUIMessage } from '../../../shared-components/ui-messages/auth-token-expired-ui-message.interface';
import { MessageBusService } from '../../../shared-components/message-bus.service';
import { Logger } from '../../../shared-components/logging/logger.interface';
import { SessionService } from '../../../shared-components/authentication/session-service.service';
import { TelemetryService } from 'projects/shared-components/telemetry/telemetry.service';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';

@Component({
   selector: 'ets-root',
   templateUrl: './wt-app.component.html',
   styleUrls: ['./wt-app.component.scss']
})
export class WebtraderAppComponent implements OnInit, AfterViewInit {
   private readonly _logger: Logger;

   constructor(
      private readonly _router: Router,
      private readonly _messageBus: MessageBusService,
      private readonly _sessionService: SessionService,
      private readonly _telemetryService: TelemetryService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('AppComponent');
   }

   ngOnInit() {
     
   }

   async ngAfterViewInit(): Promise<void> {
      this._telemetryService.init();

      this._messageBus
         .of<AuthTokenExpiredUIMessage>('AuthTokenExpiredUIMessage')
         .subscribe(data => this.onAuthTokenChanged(data.payload));

      await this._router.navigate(['/login']);
   }


   private async onAuthTokenChanged(message: AuthTokenExpiredUIMessage) {
      this._logger.info('Received message that auth. token is expired', message);
      this._sessionService.reset();
      this._logger.info('User session has been reset');
      const targetRoute = message.doNotShowLogoutScreen ? '/login' : '/logout';
      await this._router.navigate([targetRoute], { skipLocationChange: targetRoute === '/logout' });
   }
}
