import { Injectable } from '@angular/core';
import { WorkspacePanelsMenu } from './workspace-panels-menu';
import { WorkspacePanels } from './workspace-panels';
import { WorkspaceSecurityContextService } from '../workspace-security-context.service';
import { environment } from 'projects/shared-components/environments/environment';

@Injectable()
export class WorkspacePanelsMenuService {

   constructor(private _securityContext: WorkspaceSecurityContextService) {
      const items = [
         {
            id: WorkspacePanels.TradingSystemsView.toString(),
            header: 'Strategies',
            isAvailable: () => this._securityContext.tradingSystemsViewPanel
         },
         {
            id: WorkspacePanels.StrategyOrdersAndTrades.toString(),
            header: 'Strategy Orders & Trades',
            isAvailable: () => this._securityContext.strategyOrdersAndTradesPanel
         },
         {
            id: WorkspacePanels.StrategyMessages.toString(),
            header: 'Strategy Messages',
            isAvailable: () => this._securityContext.strategyMessagesPanel
         },
         {
            id: WorkspacePanels.StrategyIssues.toString(),
            header: 'Strategy Issues',
            isAvailable: () => this._securityContext.strategyIssuesPanel,
         },
         {
            id: WorkspacePanels.ManualPositions.toString(),
            header: 'Manual Positions',
            isAvailable: () => this._securityContext.manualPositionsPanel
         },
         {
            id: WorkspacePanels.ManualOrdersAndTrades.toString(),
            header: 'Manual Orders & Trades',
            isAvailable: () => this._securityContext.manualOrdersAndTradesPanel
         },
         {
            id: WorkspacePanels.AggregatedPositions.toString(),
            header: 'Aggregated Positions',
            isAvailable: () => this._securityContext.aggregatedPositionsPanel
         },
         {
            id: WorkspacePanels.QuoteBoard.toString(),
            header: 'Quote Board',
            isAvailable: () => this._securityContext.quoteBoardPanel
         },
         {
            id: WorkspacePanels.PnLChart.toString(),
            header: 'Chart (P&L)',
            isAvailable: () => this._securityContext.pnlChartPanel
         },
         {
            id: WorkspacePanels.PriceChart.toString(),
            header: 'Chart (Price)',
            isAvailable: () => this._securityContext.priceChartPanel
         },
         {
            id: WorkspacePanels.HpDatasets.toString(),
            header: 'HP Datasets',
            isAvailable: () => this._securityContext.hpDatasetsPanel
         },
         {
            id: WorkspacePanels.OpenPositionChart.toString(),
            header: 'Chart (Open Position)',
            isAvailable: () => this._securityContext.openPositionChart
         },
         {
            id: WorkspacePanels.PositionSizingReport.toString(),
            header: 'Position Sizing Report',
            isAvailable: () => this._securityContext.positionsSizingReport
         },
         {
            id: WorkspacePanels.StrategyTriggers.toString(),
            header: 'Strategy Triggers',
            isAvailable: () => this._securityContext.strategyTriggers
         },
         {
            id: WorkspacePanels.OptionChain.toString(),
            header: 'Option Chain',
            isAvailable: () => this._securityContext.optionsBoard
         },
         {
            id: WorkspacePanels.Portfolios.toString(),
            header: 'Portfolios',
            isAvailable: () => this._securityContext.portfolios
         },
         {
            id: WorkspacePanels.MultiTradePad.toString(),
            header: 'Multi-Leg Trading Pad',
            isAvailable: () => this._securityContext.multiTradePad
         },
         {
            id: WorkspacePanels.Ledger.toString(),
            header: 'Ledger',
            isAvailable: () => this._securityContext.ledger
         },
         {
            id: WorkspacePanels.OptionsPricingPad.toString(),
            header: 'Options Pricing Pad',
            isAvailable: () => this._securityContext.optionsPricingPad
         },
         {
            id: WorkspacePanels.OptionsPricingGrid.toString(),
            header: 'Options Pricing Grid',
            isAvailable: () => this._securityContext.optionsPricingGrid
         },
         {
            id: WorkspacePanels.StrategyHedgedPositions.toString(),
            header: 'Strategy Hedged Positions',
            isAvailable: () => this._securityContext.strategyHedgedPositions
         },
         {
            id: WorkspacePanels.PortfolioItems.toString(),
            header: 'Portfolios Items',
            isAvailable: () => this._securityContext.portfolioItems
         },
         {
            id: WorkspacePanels.Watchlist.toString(),
            header: 'Watchlist',
            isAvailable: () => this._securityContext.watchList
         },
         {
            id: WorkspacePanels.SessionHistory.toString(),
            header: 'Session History',
            isAvailable: () => this._securityContext.sessionHistory
         },
         {
            id: WorkspacePanels.PortfolioOrdersAndTrades.toString(),
            header: 'Portfolio Orders & Trades',
            isAvailable: () => this._securityContext.portfolioOrdersAndTrades
         },
         {
            id: WorkspacePanels.AdjustmentStrategies.toString(),
            header: 'Adjustment Strategies',
            isAvailable: () => this._securityContext.adjustmentStrategies
         },
         {
            id: WorkspacePanels.ParametersPanel.toString(),
            header: 'Parameters Panel',
            isAvailable: () => this._securityContext.parametersPanel
         },
         {
            id: WorkspacePanels.MarginRequirements.toString(),
            header: 'Margin Requirements',
            isAvailable: () => this._securityContext.marginRequirements
         },
         {
            id: WorkspacePanels.ShellMessages.toString(),
            header: 'Shell Messages',
            isAvailable: () => this._securityContext.shellMessages
         },
         {
            id: WorkspacePanels.FutureOrders.toString(),
            header: 'Future Orders',
            isAvailable: () => this._securityContext.futureOrders
         },
         {
            id: WorkspacePanels.TestPanel.toString(),
            header: '++ Test ++',
            isAvailable: () => !environment.production
         },
         {
            id: WorkspacePanels.AutomationCp.toString(),
            header: 'Automation CP',
            isAvailable: () => this._securityContext.automationCp
         },
         {
            id: WorkspacePanels.AdjustmentPricingGridPanel.toString(),
            header: 'Adjustment Pricing Grid',
            isAvailable: () => this._securityContext.adjustmentPricingGrid
         },
         {
            id: WorkspacePanels.CashFlowTracking.toString(),
            header: 'Cash Flow Tracking',
            isAvailable: () => this._securityContext.cashflowTracking
         },
         {
            id: WorkspacePanels.AdjustmentComparisonGrid.toString(),
            header: 'Adjustment Comparison Grid',
            isAvailable: () => this._securityContext.adjustmentComparisonGrid
         },
         {
            id: WorkspacePanels.ChecklistEditorPanel.toString(),
            header: 'Checklist Editor',
            isAvailable: () => this._securityContext.checklistEditor
         },
         {
            id: WorkspacePanels.ResourceEditorPanel.toString(),
            header: 'Resource Editor',
            isAvailable: () => this._securityContext.resourceEditor
         },
         {
            id: WorkspacePanels.HedgingGrid.toString(),
            header: 'Hedging Grid',
            isAvailable: () => this._securityContext.hedgingGrid
         },
         {
            id: WorkspacePanels.PackageComparisonPanel.toString(),
            header: 'Package Comparison',
            isAvailable: () => this._securityContext.packageComparison
         },
         {
            id: WorkspacePanels.PositionModifier.toString(),
            header: 'Position Modifier',
            isAvailable: () => this._securityContext.positionModifier
         },
      ];

      this._menuItems = items.sort( (a, b) => a.header.localeCompare(b.header));
    }

   private _menuItems: any[];


   public getMenu(): WorkspacePanelsMenu {

      return {
         isOpened: false,
         items: this._menuItems
      };
   }
}
