<div class="roll-time-settings">

   <fieldset>
      <legend>Adjustment Time Settings</legend>
   
      <div class="field">
         
         <div class="value">
               
            <div class="input roll-mode">
               <p>Mode</p>
               <dx-select-box 
                  [items]="this.rollTimeModes"
                  [(value)]="this.rollTimeMode"
                  >
               </dx-select-box>
            </div>
            
            <div class="input roll-time">
               <p>Time</p>
               <ets-datetime-picker 
                  [contextWord]="this.rollTimeMode"
                  [mode]="this.rollTimePickerMode"
                  [(value)]="this.rollTime" 
                  [showTimezone]="false"
                  >
               </ets-datetime-picker>
   
               <div *ngIf="this.showTimezonePicker">
                  <ets-timezone-picker
                     [(timezone)]="this.rollTimezone"
                     [flow]="'col'"
                     >
                  </ets-timezone-picker>
               </div>
            </div>
   
            <div class="input buffer">
               <p>"Join Roll" Buffer (days)</p>
               <dx-number-box 
                  [(value)]="this.joinRollBuffer"
                  [showClearButton]="true"
                  [useLargeSpinButtons]="true"
                  [showSpinButtons]="true"
                  >
               </dx-number-box>               
            </div>
         </div>
      </div>

   </fieldset>


</div>