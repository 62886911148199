<div class="container">
   <div class="column left">

      <fieldset>
         <legend>Hedge Target</legend>
         <dx-radio-group   [items]="['Cash Value', 'Deltas']" 
                           layout="horizontal" 
                           [(value)]="control.hedgeTarget">
         </dx-radio-group>
      </fieldset>
      
      <fieldset class="delta-settings" *ngIf="this.control.hedgeTarget === 'Deltas'">
         <legend>Delta Settings</legend>
         <div class="column">
            <div class="ets-field">
               <p>Mode</p>
               <dx-radio-group   [items]="['+Delta', '-Delta', '+/-Delta']" 
                                 layout="vertical"
                                 [(value)]="control.deltaHedgeMode">
               </dx-radio-group>
            </div>   
         </div>
         <div class="column adjust">
            <div class="ets-field">
               <p>Adjust When</p>
               <dx-number-box [(value)]="control.deltaAdjustWhen" 
                              [min]="1" 
                              [showSpinButtons]="true"
                              [showClearButton]="true"
               ></dx-number-box>
            </div>
            <div class="ets-field adjust-to">
               <p>Adjust To</p>
               <dx-number-box [(value)]="control.deltaAdjustTo" 
                              [min]="0" [showSpinButtons]="true" 
                              [showClearButton]="true"></dx-number-box>
            </div>
         </div>
      </fieldset>
   
      <fieldset class="hedge-target">
         <legend>Hedge Target</legend>
         
         <div class="ets-field target">
            <p class="portfolio-label">Portfolio <span style="font-style: italic;">(select strategy terminal first)</span></p>
            <div>
               <dx-select-box    [items]="control.availablePortfolios"
                                 displayExpr="portfolioName" 
                                 valueExpr="portfolioId" 
                                 [(value)]="control.targetPortfolio"
                                 (onValueChanged)="control.onTargetPortfolioChanged()" 
                                 [showClearButton]="true"></dx-select-box>
            </div>
         </div>
         
         <div class="ets-field target">
            <p>Combo</p>
            <div>
               <dx-select-box    [items]="control.availableCombos" 
                                 displayExpr="comboName" 
                                 valueExpr="comboId" 
                                 [(value)]="control.targetCombo"
                                 (onValueChanged)="control.onTargetComboChanged()" 
                                 [showClearButton]="true"></dx-select-box>
            </div>
         </div>
   
         <div class="ets-field target">
            <p>Group</p>
            <div>
               <dx-select-box [items]="control.availableComboGroups" 
                              displayExpr="comboGroupName" 
                              valueExpr="comboGroupId" 
                              [(value)]="control.targetComboGroup" 
                              [showClearButton]="true"></dx-select-box>
            </div>
         </div>
   
      </fieldset>
   
   </div>
   
   <div class="column right">
      <fieldset class="hedge-instrument">
         <legend>Hedge Instrument</legend>
         <div class="ets-field-value symbol">
            <ets-symbol-picker (instrumentSelected)="control.symbol = $event"
            ></ets-symbol-picker>
          </div>
          <div class="instrument-type">
            <dx-radio-group [items]="['Underlying', 'Option']" layout="horizontal" [(value)]="control.hedgeInstrument">
            </dx-radio-group>
         </div>
      </fieldset>

      <fieldset class="hedge-rounding">
         <legend>Hedge Qty Rounding</legend>
         <dx-radio-group   [items]="['Up', 'Down']" 
                           layout="horizontal" 
                           [(value)]="control.hedgeRounding">
         </dx-radio-group>
      </fieldset>

      <fieldset class="option-contract" *ngIf="control.hedgeInstrument === 'Option'">
         <legend>Option Contract Settings</legend>
         <div class="ets-field box epxiration">
            <p>Days To Expiration</p>
            <dx-number-box [min]="0" step="1" 
                           [showSpinButtons]="true" 
                           [(value)]="control.hedgeOptionDaysToExpiration"
                           [showClearButton]="true"></dx-number-box>
         </div>
         <div class="ets-field box strike">
            <p>% OTM Strike</p>
            <dx-number-box [min]="0" 
                           step="1" 
                           [showSpinButtons]="true" 
                           [showClearButton]="true"
                           [(value)]="control.hedgeOptionPercentOtmStrike"></dx-number-box>
         </div>
      </fieldset>

      <fieldset class="beta">
         <legend>Custom Beta</legend>
         <div class="box">
            <dx-number-box [min]="0" [step]="0.1" 
                           [showSpinButtons]="true" 
                           [showClearButton]="true"
                           [inputAttr]="{'text-align': 'center'}"
                           [(value)]="control.customBeta"></dx-number-box>
         </div>
      </fieldset>
   
      <fieldset class="order-type">
         <legend>Order Settings</legend>
         <div class="ets-field order-type">
            <div class="ets-field-label">Order Type</div>
            <div class="ets-field-value">
               <dx-select-box [items]="['Market', 'Limit']" [(value)]="control.orderType"></dx-select-box>
            </div>
         </div>   
         <div class="ets-field" *ngIf="control.orderType === 'Limit'">
            <div class="ets-field-label">Distance From Top of the Book (%)</div>
            <div class="ets-field-value">
               <dx-number-box [(value)]="control.orderDistance" [showSpinButtons]="true" [showClearButton]="true"
                              [min]="0"></dx-number-box>
            </div>
         </div>
         <div class="ets-field" *ngIf="control.orderType === 'Limit'">
            <div class="ets-field-label">Cancel on Delta Deviation (%)</div>
            <div class="ets-field-value">
               <dx-number-box [(value)]="control.deltaDeviationCancel" [showSpinButtons]="true" [showClearButton]="true"
                              [min]="0"></dx-number-box>
            </div>
         </div>
      </fieldset>   
   </div>
</div>
