import {NgModule} from '@angular/core';

import {PivotPointsPopupComponent} from './pivot-points-popup.component';
import {CommonModule} from "@angular/common";
import {DevExtremeModule} from "../devextreme.module";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule
    ],
    exports: [
        PivotPointsPopupComponent
    ],
    declarations: [PivotPointsPopupComponent],
    providers: [],
})
export class PivotPointsPopupModule {
}
