import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolCommand} from '../../../service-model/protocol-command.interface';

export class UnfreezeStrategies implements ProtocolCommand {
  constructor(public readonly strategies: string[]) {
  }
  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.UnfreezeStrategies`;
  }
}
