import { StrategyParameters } from '../strategies/strategy-parameters.enum';
import {
  TradingInstrumentDisplayNameService 
} from '../trading-instruments/trading-instrument-display-name.service';

export abstract class AlgoDescriptionBuilderBase {
  constructor(protected DisplayNameService: TradingInstrumentDisplayNameService) { }
  abstract getAlgoName(parameters: StrategyParameters);
  abstract getDescription(parameters: StrategyParameters);
  abstract getSymbolPropertyNames(): string[];
}
