import { formatCurrency } from '@angular/common';
import { isValidNumber, isVoid } from 'projects/shared-components/utils';

export class PnlValue {

   private _value: number;
   private _viewValue: string;

   get cssClass(): 'profit' | 'loss' {
      if (this._value > 0) {
         return 'profit';
      }

      if (this._value < 0) {
         return 'loss';
      }

      return undefined;
   }

   get viewValue(): string {
      if (isVoid(this._viewValue)) {
         this._viewValue = formatCurrency(
            this._value || 0,
            'en-US',
            '$',
            'USD',
            '1.2-2'
         );
      }

      return this._viewValue;
   }

   onValueChanged(value: number) {
      this._value = value;
      this._viewValue = undefined;
   }
}
