import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from '../devextreme.module';
import { ChecklistEditorComponent } from './checklist-editor.component';
import { ChecklistDayOfWeekConditionComponent } from './conditions/day-of-week/checklist-day-of-week-condition.component';
import { TimezonePickerModule } from '../timezone-picker/timezone-picker.module';
import { ChecklistDayOfYearConditionComponent } from './conditions/day-of-year/checklist-day-of-year-condition.component';
import { ChecklistDayOfExpirationConditionComponent } from './conditions/day-of-expiration/checklist-day-of-expiration-condition.component';
import { ChecklistProtectiveOptionsConditionComponent } from './conditions/second-option/protective-options-condition.component';
import { ChecklistDynamicOffsetsConditionComponent } from './conditions/dynamic-offsets/checklist-dynamic-offsets-condition.component';
import {ChecklistAssetConditionComponent} from "./conditions/asset/asset-condition.component";
import {EtsSymbolPickerModule} from "../symbol-picker/symbol-picker.module";
import {ChecklistExtraPosConditionComponent} from "./conditions/extra-pos/extra-pos-condition.component";
import {ChecklistUsersConditionComponent} from "./conditions/users/users-condition.component";

@NgModule({
   declarations: [
      ChecklistEditorComponent,
      ChecklistDayOfWeekConditionComponent,
      ChecklistDayOfYearConditionComponent,
      ChecklistDayOfExpirationConditionComponent,  
      ChecklistProtectiveOptionsConditionComponent,
      ChecklistDynamicOffsetsConditionComponent,
      ChecklistAssetConditionComponent,
      ChecklistExtraPosConditionComponent,
      ChecklistUsersConditionComponent
   ],
   imports: [ 
      DevExtremeModule,
      CommonModule,
      TimezonePickerModule,
       EtsSymbolPickerModule
   ],
   exports: [
      ChecklistEditorComponent
   ],
   providers: [],
})
export class ChecklistEditorModule {}