<div #pad class="pricing-pad">
  
  <!-- Review Changes Template -->
  <ng-template #reviewChangesBtn>
    
    <ng-container [ngSwitch]="this.getChangesState()">
      <ng-container *ngSwitchCase="'Review'">
        <ng-container *ngTemplateOutlet="reviewChanges"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Merge'">
        <ng-container *ngTemplateOutlet="mergeChanges"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Colors & Labels'">
        <ng-container *ngTemplateOutlet="colorsAndLabels"></ng-container>
      </ng-container>
    </ng-container>
    
    <ng-template #reviewChanges>
      <dx-button (onClick)="this.onReviewChangesClicked()"
                 [disabled]="!this.canReviewChanges()"
                 text="Review Changes"
                 type="default"
                 width="100%"
      ></dx-button>
    </ng-template>
    <ng-template #mergeChanges>
      <dx-button (onClick)="this.onSyncPositionsClicked()"
                 [disabled]="this.isMergeDialogVisible || !this.canSyncPositions()"
                 text="Sync Positions"
                 type="default"
                 width="100%"
      ></dx-button>
    </ng-template>
    <ng-template #colorsAndLabels>
      <dx-button (onClick)="this.onSyncColorAndLabelsClicked()"
                 [disabled]="!this.canSyncLabelsAndColors()"
                 text="Sync Positions"
                 type="default"
                 width="100%"
      ></dx-button>
    </ng-template>
  </ng-template>
  
  <!--  Full Version -->
  <div [style.display]="this.isShortVersion ? 'none' : 'block'" [style.height.%]="100">
    <div class="legs">
      
      <div class="header" [style.display]="this.expandConfigurator ? 'none' : 'block'">
        <h4>Strategy & Pricing Settings</h4>
      </div>
      
      <div class="section symbol-n-strategy" [style.display]="this.expandConfigurator ? 'none' : 'block'">
        
        <div class="body">
          
          <div class="block symbol">
            <ets-symbol-picker
              [disabled]="true"
              [selectedInstrument]="this.tradingInstrument"
            ></ets-symbol-picker>
          </div>
          
          <div class="block strategy">
            <div class="label">Strategy</div>
            <dx-select-box
              (onValueChanged)="this.onStrategyChanged()"
              [(value)]="this.selectedStrategy"
              [disabled]="!this.tradingInstrument || this.isMergeMode"
              [items]="this.strategyList"
              placeholder="Strategy"
            ></dx-select-box>
          </div>
          
          <div class="block option-type">
            <div class="label">Option Type</div>
            <dx-select-box
              (onValueChanged)="this.onOptionTypeChanged()"
              [(value)]="this.selectedOptionType"
              [disabled]="!this.selectedStrategy || this.isMergeMode"
              [items]="this.optionTypeList"
              placeholder="Option"
            ></dx-select-box>
          </div>
          
          <div class="block strike-width">
            <div class="label">
              Strikes Width
            </div>
            <dx-number-box
              (onValueChanged)="this.onStrikeWidthChanged()"
              [(value)]="this.strikeWidth"
              [disabled]="!this.selectedStrategy || this.isMergeMode"
              [showClearButton]="true"
              [showSpinButtons]="true"
            ></dx-number-box>
          </div>
          
          <div class="block expiration">
            <div class="label">Expiration</div>
            <dx-select-box
              [(value)]="this.selectedExpiration"
              [disabled]="!this.selectedStrategy || this.isMergeMode"
              [items]="this.expirationList"
              [showClearButton]="true"
              displayExpr="dateWithDaysToExpiration"
            >
              <div *dxTemplate="let data of 'item'">
                <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                   {{ data?.dateWithDaysToExpiration }}
                </span>
              </div>
            </dx-select-box>
          </div>
          
          <div class="block offset">
            <div class="label">
              <div class="btn"
                   [class.hot]="this.strikeOffsetMode === 'Offset'"
                   [class.disabled]="!this.selectedStrategy || this.isMergeMode"
                   (click)="this.changeOffsetMode('Offset')"
              >
                Offset
              </div>
              <div class="btn"
                   [class.hot]="this.strikeOffsetMode === 'Strike'"
                   [class.disabled]="!this.selectedStrategy || this.isMergeMode"
                   (click)="this.changeOffsetMode('Strike')"
              >
                Strike
              </div>
            </div>
            <div [style.display]="this.strikeOffsetMode === 'Offset' ? 'block' : 'none' ">
              <dx-number-box
                (onValueChanged)="this.onOffsetChanged()"
                [(value)]="this.strikeOffset"
                [disabled]="!this.selectedStrategy || this.isMergeMode"
                [showClearButton]="true"
                [showSpinButtons]="true"
                [inputAttr]="this.dxCenterTextStyleObj"
              ></dx-number-box>
            </div>
            <div [style.display]="this.strikeOffsetMode === 'Strike' ? 'block' : 'none' ">
              <ets-strikes-dropdown
                [targetInstrument]="this.tradingInstrument"
                [expiration]="this.selectedExpiration"
                [(strike)]="this.baseStrike"
                [inputAttr]="this.dxCenterTextStyleObj"
              >
              </ets-strikes-dropdown>
            </div>
          </div>
          
          <div class="block base-qty">
            <div class="label" style="text-align: center">
              Base Qty
            </div>
            <dx-number-box
              (onValueChanged)="this.onBaseQtyChanged()"
              [(value)]="this.baseQty"
              [disabled]="!this.selectedStrategy || this.isMergeMode"
              [min]="1"
              [showSpinButtons]="true"
              [inputAttr]="this.dxCenterTextStyleObj"
            ></dx-number-box>
          </div>
          
          <div class="block add">
            <dx-button (onClick)="this.onAddStrategyClicked()"
                       [disabled]="!this.canAddStrategy()"
                       text="Add"
                       type="normal"
            ></dx-button>
          </div>
        
        </div>
      
      </div>
      
      <hr style="width: 50%; border-color: dimgray" [style.display]="this.expandConfigurator ? 'none' : 'block'">
      
      <div class="controls">
        
        <div class="right side">
          <div class="legs-management">
            <dx-button (onClick)="this.onAddOptionClicked('Call')"
                       [disabled]="this.isMergeMode"
                       text="Add Call"
            ></dx-button>
            
            <dx-button (onClick)="this.onAddOptionClicked('Put')"
                       [disabled]="this.isMergeMode"
                       text="Add Put"
            ></dx-button>
            
            <dx-button (onClick)="this.onPasteComboClicked()"
                       [disabled]="!this.canPasteCombo()"
                       text="Paste"
                       icon="paste"
            ></dx-button>
            
            <dx-button (onClick)="this.onClearLegsClicked()"
                       [disabled]="this.isMergeMode"
                       text="Clear Legs"
            ></dx-button>
            
            <dx-button (onClick)="this.onReloadHedgesClicked()"
                       text="Re-load Hedges"
                       [disabled]="this.isMergeDialogVisible"
            ></dx-button>
          
          </div>
          <div class="legs-misc">
            
            <div class="disable-sorting">
              <dx-check-box
                (onValueChanged)="this.onAutoSortChanged()"
                [(value)]="this.autoSort"
                text="Auto-Sort"
              >
              </dx-check-box>
            </div>

            <div class="disable-sorting">
              <dx-button text="Custom Sort" icon="sorted" [disabled]="!this.canShowCustomSortDialog" (onClick)="this.showCustomSortDialog()"></dx-button>
            </div>
            
            <div class="pto">
              <dx-check-box
                [(value)]="this.priceToOpen"
                [disabled]="this.isMergeMode"
                text="Price To Open"
              ></dx-check-box>
            </div>
          
          </div>
        </div>
        
        <div class="left side">
          <div class="cb hide-strategy">
            <ng-container *ngIf="this.expandConfigurator">
              <i (click)="this.toggleExpandConfigurator()" class="fas fa-compress-arrows-alt expand-icon"></i>
              <span>Back To Normal Size</span>
            </ng-container>
            <ng-container *ngIf="!this.expandConfigurator">
              <i (click)="this.toggleExpandConfigurator()" class="fas fa-expand-arrows-alt expand-icon"></i>
              <span>Expand Full Size</span>
            </ng-container>
          </div>
          
          <div class="btn-review-changes">
            <ng-container *ngTemplateOutlet="reviewChangesBtn"></ng-container>
          </div>
        </div>
      </div>
      
      <br>
      
      <div class="scroll-area">
        <dx-scroll-view>
          <div class="scroll-data">
            <ng-container *ngIf="this.hasCalls">
              <ng-container *ngTemplateOutlet="positions; context: {$implicit: {side: 'Call'}}"></ng-container>
            </ng-container>
            
            <ng-container *ngIf="this.hasPuts">
              <hr style="border: 3px solid dimgray; width: 85%; margin-top: 20px; margin-bottom: 20px;">
              <ng-container *ngTemplateOutlet="positions; context: {$implicit: {side: 'Put'}}"></ng-container>
            </ng-container>
          </div>
        </dx-scroll-view>
      </div>
    
    </div>
  </div>
  
  <!--  Short Version -->
  <div [style.display]="this.isShortVersion ? 'block' : 'none'" [style.height.%]="99">
    <div class="btn-review-changes-short">
      <ng-container *ngTemplateOutlet="reviewChangesBtn"></ng-container>
    </div>
    
    <div class="totals">
      <div class="call total">
        <div class="label">
          Calls Total
        </div>
        <div class="value">
          <div class="owned"
               [style.display]="this.getGrandTotalsDisplay('Call', 'owned')"
               [style.visibility]="this.getGrandTotalsVisibility('Call', 'owned')"
          >
            <dx-number-box
                #callTotalOwn
                [value]="this.getGrandTotalsValue('Call', 'owned')"
                [readOnly]="true"
                [inputAttr]="this.getPriceBoxStyleInputAttr(callTotalOwn.value)"
                format="$#0.00"
            ></dx-number-box>
          </div>
          
          <div class="trans"
               [style.display]="this.getGrandTotalsDisplay('Call', 'trans')"
               [style.visibility]="this.getGrandTotalsVisibility('Call', 'trans')"
          >
            <dx-number-box
                #callTotalTrans
                [value]="this.getGrandTotalsValue('Call', 'trans')"
                [readOnly]="true"
                [inputAttr]="this.getPriceBoxStyleInputAttr(callTotalTrans.value)"
                format="$#0.00"
            ></dx-number-box>
          </div>
        </div>
      </div>
      
      <div class="put total">
        <div class="label">
          Puts Total
        </div>
        <div class="value">
          <div class="owned"
               [style.display]="this.getGrandTotalsDisplay('Put', 'owned')"
               [style.visibility]="this.getGrandTotalsVisibility('Put', 'owned')"
          >
            <dx-number-box
                #putTotalOwn
                [value]="this.getGrandTotalsValue('Put', 'owned')"
                [readOnly]="true"
                [inputAttr]="this.getPriceBoxStyleInputAttr(putTotalOwn.value)"
                format="$#0.00"
            ></dx-number-box>
          </div>
          
          <div class="trans"
               [style.display]="this.getGrandTotalsDisplay('Put', 'trans')"
               [style.visibility]="this.getGrandTotalsVisibility('Put', 'trans')"
          >
            <dx-number-box
                #putTotalTrans
                [value]="this.getGrandTotalsValue('Put', 'trans')"
                [readOnly]="true"
                [inputAttr]="this.getPriceBoxStyleInputAttr(putTotalTrans.value)"
                format="$#0.00"
            ></dx-number-box>
          </div>
        </div>
      </div>
      
      <div class="all total">
        <div class="label">
          Grand Total
        </div>
        <div class="value">
          <div class="owned"
               [style.display]="this.getGrandTotalsDisplay('Grand', 'owned')"
               [style.visibility]="this.getGrandTotalsVisibility('Grand', 'owned')"
          >
            <dx-number-box
                #grandTotalOwn
                [value]="this.getGrandTotal(callTotalOwn?.value, putTotalOwn?.value)"
                [readOnly]="true"
                [inputAttr]="this.getPriceBoxStyleInputAttr(grandTotalOwn.value)"
                format="$#0.00"
            ></dx-number-box>
          </div>
          
          <div class="trans"
               [style.display]="this.getGrandTotalsDisplay('Grand', 'trans')"
               [style.visibility]="this.getGrandTotalsVisibility('Grand', 'trans')"
          >
            <dx-number-box
                #grandTotalTrans
                [value]="this.getGrandTotal(callTotalTrans?.value, putTotalTrans?.value)"
                [readOnly]="true"
                [inputAttr]="this.getPriceBoxStyleInputAttr(grandTotalTrans.value)"
                format="$#0.00"
            ></dx-number-box>
          </div>
        </div>
      </div>
    </div>
    
    <hr class="totals-separator">
    
    <div class="short-version">
      <dx-scroll-view>
        <div style="padding-bottom: 5px; height: 99%">
          <ng-container *ngIf="this.hasCalls">
            <ng-container *ngTemplateOutlet="shortVersion; context: {$implicit: {side: 'Call'}}"></ng-container>
          </ng-container>
          
          <ng-container *ngIf="this.hasPuts">
            <hr style="border-color: gray; width: 85%; margin-top: 10px; margin-bottom: 10px;">
            <ng-container *ngTemplateOutlet="shortVersion; context: {$implicit: {side: 'Put'}}"></ng-container>
          </ng-container>
        </div>
      </dx-scroll-view>
    </div>
    
  </div>
</div>

<!--Full Version Template-->
<ng-template #positions let-ctx>
  
  <div *ngFor="let pGroup of this.getLegGroups(ctx.side); first as isFirst; last as isLast"
       [class.different]="this.doesGroupHaveAnyChanges(pGroup)"
       class="positions-group"
  >
    <div class="group-common">
      <div class="common-attr reset">
        <dx-button text="Reset" icon="revert"
                   (onClick)="this.resetGroup(pGroup)"
                   [disabled]="!this.canRevertGroup(pGroup)"
        ></dx-button>
      </div>
      
      <div class="common-attr hedge-color" [class.removed]="this.isGroupRemoved(pGroup)">
        <div>Color</div>
        <div [style.width.px]="50">
          <ets-color-picker
            (onValueChanged)="this.onHedgeColorChanged($event, pGroup)"
            [(selectedColor)]="pGroup.groupColor"
            [showCallPalette]="ctx.side === 'Call'"
            [showPutPalette]="ctx.side === 'Put'"
            [removeColors]="this.usedColors"
          ></ets-color-picker>
        </div>
      </div>
      
      <div class="common-attr hedge-label" [class.removed]="this.isGroupRemoved(pGroup)">
        <div>Label</div>
        <div>
          <dx-text-box
            (onValueChanged)="this.onLabelChanged(pGroup, $event)"
            [(value)]="pGroup.groupLabel"
            [inputAttr]="this.getLabelInputAttr(pGroup)"
          ></dx-text-box>
        </div>
      </div>
      <div #btnMerge class="common-attr merge" [class.removed]="this.isGroupRemoved(pGroup)">
        <dx-button
          (onClick)="this.showMergeDialog(ctx.side,pGroup, btnMerge)"
          [disabled]="!this.canMerge()"
          text="Merge"
        ></dx-button>
      </div>
      <div #btnMerge class="common-attr merge" [class.removed]="this.isGroupRemoved(pGroup)">
        <dx-button
          (onClick)="this.onHarvestClicked(ctx.side,pGroup, btnMerge)"
          text="Harvest"
        ></dx-button>
      </div>
    </div>
    
    <div *ngFor="let leg of pGroup.legs" class="leg">
      
      <ng-container>
        <div
          [class.removed]="leg.removed"
          [style.visibility]="(pGroup.selectedForMerge && !leg.removed) ? 'visible' : 'hidden'"
          class="column duplicate"
        >
          <dx-check-box
            (onValueChanged)="this.onSelectedForMergeChanged(leg)"
            [(value)]="leg.selectedForMerge">
          </dx-check-box>
        </div>
        <div
          [class.removed]="leg.removed"
          [style.visibility]="(pGroup.selectedForHarvest && !leg.removed) ? 'visible' : 'hidden'"
          class="column duplicate"
        >
          <dx-check-box
            (onValueChanged)="this.onSelectedForHarvestChanged(leg, pGroup)"
            [(value)]="leg.selectedForHarvest">
          </dx-check-box>
        </div>
      </ng-container>
      
      <div [class.removed]="leg.removed" class="column buy-sell">
        <dx-select-box
          (valueChange)="this.onLegSideChanging(leg, $event)"
          (onValueChanged)="this.onLegSideChanged(leg)"
          [(value)]="leg.side"
          [disabled]="this.isMergeMode"
          [inputAttr]="{style: leg.sideStyle}"
          [items]="['Buy', 'Sell']"
          placeholder="Buy/Sell"
        >
          <div *dxTemplate="let data of 'item'">
            <span [style.color]="data === 'Buy' ? 'cornflowerblue' : 'red'">{{ data }}</span>
          </div>
        </dx-select-box>
      </div>
      
      <div [class.removed]="leg.removed" class="column qty">
        <div class="owned">
          <dx-number-box
              (onValueChanged)="this.onChange()"
              (valueChange)="this.onLegQtyChange(leg, $event)"
              (onFocusIn)="this.onQtyFocusIn(leg)"
              [disabled]="this.isMergeMode && !leg.selectedForMerge"
              [showSpinButtons]="false"
              [useLargeSpinButtons]="false"
              [value]="leg.absQty"
              [min]="1"
              [readOnly]="leg.selectedForTrans"
              [inputAttr]="this.dxCenterTextStyleObj"
              placeholder="Qty"
          ></dx-number-box>
        </div>
        <div class="split" *ngIf="leg.selectedForTrans">
          <dx-number-box
              #transQtyBox
              (onFocusOut)="this.onQtyFocusOut(leg)"
              (onInitialized)="this.onQtyBoxInit(transQtyBox)"
              [(value)]="leg.transQty"
              [min]="0"
              [inputAttr]="this.dxCenterTextStyleObj"
          ></dx-number-box>
        </div>
      </div>
      
      <div [class.removed]="leg.removed" class="column expiration">
        <dx-select-box
          (valueChange)="this.onLegExpirationChanging(leg, $event)"
          (onValueChanged)="this.onLegExpirationChanged(pGroup, leg)"
          [(value)]="leg.expirationDescriptor"
          [disabled]="this.isMergeMode"
          [inputAttr]="leg.expirationStyle"
          [items]="leg.expirationList"
          [showClearButton]="true"
          displayExpr="dateWithDaysToExpiration"
        >
          <div *dxTemplate="let data of 'item'">
                <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                   {{ data?.dateWithDaysToExpiration }}
                </span>
          </div>
        </dx-select-box>
      </div>
      
      <div [class.removed]="leg.removed" class="column put-call">
        <dx-select-box
          (onValueChanged)="this.onChange()"
          [(value)]="leg.type"
          [disabled]="this.isMergeMode"
          [inputAttr]="this.dxCenterTextStyleObj"
          [items]="['Call', 'Put']"
          [readOnly]="true"
          placeholder="Call/Put"
        ></dx-select-box>
      </div>
      
      <div [class.removed]="leg.removed" class="column strike">
        <ets-strikes-dropdown
          (strikeChange)="this.onLegStrikeChanging(leg, $event)"
          (strikeChanged)="this.onLegStrikeChanged(leg)"
          [(strike)]="leg.strike"
          [disable]="this.isMergeMode"
          [expiration]="leg.expirationDescriptor"
          [inputAttr]="this.dxCenterTextStyleObj"
          [targetInstrument]="this.tradingInstrument"
        ></ets-strikes-dropdown>
      </div>
      
      <div class="column price" [class.removed]="leg.removed">
        
        <div class="owned"
             [style.display]="this.getOwnPriceboxDisplay()"
             [style.visibility]="this.getPriceboxVisibility(leg, 'owned')"
        >
          <dx-number-box
              #pxOwn
              [readOnly]="true"
              [value]="this.getLegPrice(leg, 'owned')"
              [inputAttr]="this.getPriceBoxStyleInputAttr(pxOwn.value)"
              format="$#0.00"
          ></dx-number-box>
        </div>
        
        <div class="trans"
             [style.display]="this.getTransPriceboxDisplay(ctx.side)"
             [style.visibility]="this.getPriceboxVisibility(leg, 'trans')"
        >
          <dx-number-box
              #pxTrans
              [readOnly]="true"
              [value]="this.getLegPrice(leg, 'trans')"
              [inputAttr]="this.getPriceBoxStyleInputAttr(pxTrans.value)"
              format="$#0.00"
          ></dx-number-box>
        </div>
      
      </div>
      
      <div [class.disabled]="this.isMergeMode" class="column remove">
        <i (click)="this.removeLeg(leg, ctx.side)" *ngIf="!leg.removed" class="fa fa-times-circle"></i>
        <i (click)="this.restoreLeg(leg)" *ngIf="leg.removed" class="fa fa-undo"></i>
      </div>
    
    </div>
    
    <!--    Totals Row -->
    <div class="leg total">
      
      <div [style.visibility]="'hidden'" class="column duplicate">
        <dx-check-box></dx-check-box>
      </div>
      
      <div class="column buy-sell">
        <div style="width: 100%; text-align: center">
          <dx-button
            (onClick)="this.addLeg(pGroup, ctx.side)"
            [disabled]="this.isMergeMode"
            text="Add Option"
          ></dx-button>
        </div>
      </div>
      
      <div [class.removed]="this.isGroupRemoved(pGroup)" class="column qty">
        <dx-number-box [style.visibility]="'hidden'"></dx-number-box>
      </div>
      
      <div [class.removed]="this.isGroupRemoved(pGroup)" class="column expiration">
        <dx-check-box (onValueChanged)="this.onExpirationsLinkedChanged(pGroup)"
                      [(value)]="pGroup.expirationsLinked"
                      text="Linked">
        </dx-check-box>
      </div>
      
      <div [class.removed]="this.isGroupRemoved(pGroup)" class="column put-call">
        <dx-select-box
          [style.visibility]="'hidden'"
        ></dx-select-box>
      </div>
      
      <div [class.removed]="this.isGroupRemoved(pGroup)" class="column strike">
        <ets-strikes-dropdown
          [style.visibility]="'hidden'"
        ></ets-strikes-dropdown>
      </div>
      
      <div class="column price">
        
        <div class="owned"
             [style.display]="this.getOwnPriceboxDisplay()"
             [style.visibility]="this.getPriceboxVisibility(pGroup, 'owned', ctx.side)"
        >
          <dx-number-box
              #totalPxOwned
              [value]="this.getGroupTotalPrice(pGroup, 'owned')"
              [readOnly]="true"
              format="$#0.00"
              [inputAttr]="this.getPriceBoxStyleInputAttr(totalPxOwned.value)"
          ></dx-number-box>
        </div>
        
        <div class="trans"
             [style.display]="this.getTransPriceboxDisplay(ctx.side)"
             [style.visibility]="this.getPriceboxVisibility(pGroup, 'trans', ctx.side)"
        >
          <dx-number-box
              #totalPxTrans
              [value]="this.getGroupTotalPrice(pGroup,'trans')"
              [readOnly]="true"
              format="$#0.00"
              [inputAttr]="this.getPriceBoxStyleInputAttr(totalPxTrans.value)"
          ></dx-number-box>
        </div>
        
      </div>
      
      <div [class.disabled]="this.isMergeMode" class="column remove total-x">
        <i (click)="this.removeWholeGroup(pGroup, ctx.side)" *ngIf="!this.isGroupRemoved(pGroup)"
           class="fa fa-times-circle"></i>
        <i (click)="this.restoreWholeGroup(pGroup)" *ngIf="this.isGroupRemoved(pGroup)" class="fa fa-undo"></i>
      </div>
    </div>
    
    <br *ngIf="!isLast">
  
  </div>
  
  <ets-hg-merge-dialog (packageSelected$)="this.onPackageSelectedForMerge($event)">
  </ets-hg-merge-dialog>

</ng-template>

<!--Short Version Template-->
<ng-template #shortVersion let-ctx>
  
  <div *ngFor="let pGroup of this.getLegGroups(ctx.side); first as isFirst; last as isLast"
       [class.different]="this.doesGroupHaveAnyChanges(pGroup)"
       class="positions-group"
  >
    
    <div [class.removed]="this.isGroupRemoved(pGroup)" [style.color]="pGroup.groupColor"
         class="group-label"
    >
      <div>{{ pGroup.groupLabel }}</div>
    </div>
    
    <div *ngFor="let leg of pGroup.legs" class="leg">
      
      <div [class.removed]="leg.removed" [style.color]="pGroup.groupColor" class="column buy-sell">
        <dx-text-box
          [inputAttr]="{style: 'text-align: center; color: ' + pGroup.groupColor}"
          [readOnly]="true"
          [value]="leg.side"
        ></dx-text-box>
      </div>
      
      <div [class.removed]="leg.removed" [style.color]="pGroup.groupColor" class="column qty">
        <div class="owned">
          <dx-number-box
            [value]="leg.qty"
            [readOnly]="true"
            [inputAttr]="{style: 'text-align: center; color: ' + pGroup.groupColor}"
            placeholder="Qty"
          ></dx-number-box>
        </div>
        <div class="split" *ngIf="leg.selectedForTrans">
          <dx-number-box
            [readOnly]="true"
            [value]="leg.transQty * (leg.side === 'Buy' ? 1 : -1)"
            [inputAttr]="{style: 'text-align: center; color: ' + pGroup.groupColor}"
          ></dx-number-box>
        </div>
      </div>
      
      <div [class.removed]="leg.removed" [style.color]="pGroup.groupColor" class="column expiration">
        <dx-text-box
          [inputAttr]="{style: 'text-align: center; color: ' + pGroup.groupColor}"
          [readOnly]="true"
          [value]="leg.expirationDescriptor?.dateWithDaysToExpiration"
        ></dx-text-box>
      </div>
      
      <div [class.removed]="leg.removed" [style.color]="pGroup.groupColor" class="column put-call">
        <dx-text-box
          [inputAttr]="{style: 'text-align: center; color: ' + pGroup.groupColor}"
          [readOnly]="true"
          [value]="leg.type"
        ></dx-text-box>
      </div>
      
      <div [class.removed]="leg.removed" [style.color]="pGroup.groupColor" class="column strike">
        <dx-number-box
          [inputAttr]="{style: 'text-align: center; color: ' + pGroup.groupColor}"
          [readOnly]="true"
          [value]="leg.strike"
        ></dx-number-box>
      </div>
      
      <div class="column price" [class.removed]="leg.removed">
        
        <div class="owned"
             [style.display]="this.getOwnPriceboxDisplay()"
             [style.visibility]="this.getPriceboxVisibility(leg, 'owned')"
        >
          <dx-number-box
              #pxOwn
              [readOnly]="true"
              [value]="this.getLegPrice(leg, 'owned')"
              format="$#0.00"
              [inputAttr]="{style: 'text-align: center; color: ' + this.getPnLColor(pxOwn.value)}"
          ></dx-number-box>
        </div>
        
        <div class="trans"
             [style.display]="this.getTransPriceboxDisplay(ctx.side)"
             [style.visibility]="this.getPriceboxVisibility(leg, 'trans')"
        >
          <dx-number-box
              #pxTrans
              [value]="this.getLegPrice(leg, 'trans')"
              [readOnly]="true"
              format="$#0.00"
              [inputAttr]="{style: 'text-align: center; color: ' + this.getPnLColor(pxTrans.value)}"
          ></dx-number-box>
        </div>
      
      </div>
    
    </div>
    
    <div class="total leg">
      
      <div class="column buy-sell">
        <span>&nbsp;</span>
      </div>
      
      <div class="column qty">
        <span>&nbsp;</span>
      </div>
      
      <div [style.color]="pGroup.groupColor" class="column expiration">
        <span>&nbsp;</span>
      </div>
      
      <div [style.color]="pGroup.groupColor" class="column put-call">
        <span>&nbsp;</span>
      </div>
      
      <div [style.color]="pGroup.groupColor" class="column strike">
        <span>&nbsp;</span>
      </div>
      
      <div class="column price">
        
        <div class="owned"
             [style.display]="this.getOwnPriceboxDisplay()"
             [style.visibility]="this.getPriceboxVisibility(pGroup, 'owned', ctx.side)"
        >
          <dx-number-box
              #totalPxOwned
              [value]="this.getGroupTotalPrice(pGroup, 'owned')"
              [readOnly]="true"
              [inputAttr]="this.getPriceBoxStyleInputAttr(totalPxOwned.value)"
              format="$#0.00"
          ></dx-number-box>
        </div>
        
        <div class="trans"
             [style.display]="this.getTransPriceboxDisplay(ctx.side)"
             [style.visibility]="this.getPriceboxVisibility(pGroup, 'trans', ctx.side)"
        >
          <dx-number-box
              #totalPxTrans
              [value]="this.getGroupTotalPrice(pGroup,'trans')"
              [readOnly]="true"
              [inputAttr]="this.getPriceBoxStyleInputAttr(totalPxTrans.value)"
              format="$#0.00"
          ></dx-number-box>
        </div>
      
      </div>
    
    </div>
  
  </div>
</ng-template>

<ets-generic-confirmation-dialog title="Please Confirm Action">
</ets-generic-confirmation-dialog>

<ets-hedges-sorting-dialog></ets-hedges-sorting-dialog>

<ets-orders-chain-dialog></ets-orders-chain-dialog>