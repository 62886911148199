import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';

import { PositionAdjustComponent } from './position-adjust.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule
   ],
   exports: [PositionAdjustComponent],
   declarations: [PositionAdjustComponent],
   providers: [],
})
export class PositionAdjustModule { }
