// tslint:disable: no-string-literal

import { ParametersControlBase } from '../../parameters-control-base';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { ToastrService } from 'ngx-toastr';
import { DetectMethodChanges, DetectSetterChanges, DetectParameterChanges, isTruthy } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { isNullOrUndefined } from 'util';
import { ICommonDispositionParameters } from '../../common-disposition-parameters';
import { DispositionType } from '../../disposition-type';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { LimitParametersViewComponent } from './limit-parameters-view.component';

export class LimitAlgoParametersControl extends ParametersControlBase<LimitParametersViewComponent> {

   constructor(tradingInstrumentsService: TradingInstrumentsService, toastr: ToastrService) {
      super(tradingInstrumentsService, toastr);

      for (let i = 1; i < 51; i++) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.timesToStayOtrItems.push(i);
         this.maxTimesDoubleOtrItems.push(i);
      }

      for (let i = 60; i < 110; i += 10) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.timesToStayOtrItems.push(i);
      }

      for (let i = 125; i < 225; i += 25) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.timesToStayOtrItems.push(i);
      }

      for (let i = 250; i < 550; i += 50) {
         this.multiplierItems.push(i);
         this.factorItems.push(i);
         this.mmMaxScaleLevelsItems.push(i);
         this.timesToStayOtrItems.push(i);
      }
   }

   private _symbol: TradingInstrument;
   private _multiplier: number;
   private _factor: number;
   private _isCtlEnabled = false;
   private _ctlValue: number;
   private _isOtrEnabled = false;
   private _timesToStayOtr: number;
   private _otrBet1: number;
   private _commonDispositionParameters: ICommonDispositionParameters;


   fe1: number;
   fe2: number;
   profitTarget: number;
   tradeLevel: number;
   maxPosition: number;
   mmContractsAddedPer: number;
   mmMaxScaleLevels: number;
   mmContractsDecreasePer: number;
   mmIsRegress = false;
   mmResetOnMaxLevel = false;
   mmIsReverse = false;
   mmContractsDecreaseNum: number;
   otrBet2: number;
   otrBet3: number;
   otrBet4: number;
   otrBet5: number;
   stayOtr = false;
   repeatOtrProgression = false;
   doubleOtrBetOnLoss = false;
   maxTimesDoubleOtrBetOnLoss: number;
   isTradeThroughProxy = false;
   isTradeThroughStop = false;
   isTradeThroughMIT = false;
   isNetBettingMode = false;
   mmMaxScaleLevelsItems: number[] = [];
   timesToStayOtrItems: number[] = [];
   ctlValueItems: number[] = [];
   maxPositionItems: number[] = [];
   factorItems: number[] = [];
   multiplierItems: number[] = [];
   tradeLevelItems: number[] = [];
   profitTargetItems: number[] = [];
   fe1Items: number[] = [];
   fe2Items: number[] = [];
   maxTimesDoubleOtrItems: number[] = [];

   getSymbolToSet(): TradingInstrument { return this._symbol; }

   get symbol(): TradingInstrument {
      return this._symbol;
   }

   @DetectParameterChanges()
   set symbol(value: TradingInstrument) {
      if (this._symbol === value) {
         return;
      }
      const oldOne = this._symbol;
      this._symbol = value;
      const newOne = value;
      if (isTruthy(newOne)) {
         if (isTruthy(oldOne)) {
            if (newOne.underlying !== oldOne.underlying) {
               this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
            }
         } else {
            this._updateTickSizeDependentDropdowns(newOne.tickSize, newOne.precision);
         }
      }
   }

   get multiplier(): number {
      return this._multiplier;
   }

   @DetectParameterChanges()
   set multiplier(value: number) {
      if (this._multiplier === value) {
         return;
      }
      this._multiplier = value;
      this._updateMaxPositionsItems();
   }

   get factor(): number {
      return this._factor;
   }


   @DetectParameterChanges()
   set factor(value: number) {
      if (this._factor === value) {
         return;
      }
      this._factor = value;
      this._updateMaxPositionsItems();
   }

   get isCtlEnabled(): boolean {
      return this._isCtlEnabled;
   }


   @DetectParameterChanges()
   set isCtlEnabled(value: boolean) {
      if (this._isCtlEnabled === value) {
         return;
      }
      this._isCtlEnabled = value;
      if (!value) {
         this.ctlValue = null;
      }
   }

   get ctlValue(): number {
      return this._ctlValue;
   }


   @DetectParameterChanges()
   set ctlValue(value: number) {
      if (this._ctlValue === value) {
         return;
      }
      this._ctlValue = value;
      if (!!this.ctlValue && !this.isCtlEnabled) {
         this.isCtlEnabled = true;
      }
   }

   get isOtrEnabled(): boolean {
      return this._isOtrEnabled;
   }

   @DetectParameterChanges()
   set isOtrEnabled(value: boolean) {
      if (this._isOtrEnabled === value) {
         return;
      }
      this._isOtrEnabled = value;
      if (!value) {
         this.timesToStayOtr = null;
         this.otrBet1 = null;
         this.otrBet2 = null;
         this.otrBet3 = null;
         this.otrBet4 = null;
         this.otrBet5 = null;
         this.stayOtr = false;
         this.repeatOtrProgression = false;
         this.doubleOtrBetOnLoss = false;
         this.maxTimesDoubleOtrBetOnLoss = null;
      }
   }

   get timesToStayOtr(): number {
      return this._timesToStayOtr;
   }

   @DetectParameterChanges()
   set timesToStayOtr(value: number) {
      if (this._timesToStayOtr === value) {
         return;
      }
      this._timesToStayOtr = value;
      if (!!value && !this.isOtrEnabled) {
         this.isOtrEnabled = true;
      }
   }

   get otrBet1(): number {
      return this._otrBet1;
   }

   @DetectParameterChanges()
   set otrBet1(value: number) {
      if (this._otrBet1 === value) {
         return;
      }
      this._otrBet1 = value;
      if (isTruthy(value) && value !== null && !this.isOtrEnabled) {
         this.isOtrEnabled = true;
      }
   }

   get algoId(): string {
      return EtsConstants.algorithms.limitAlgoId;
   }

   
   _GetParameters(): StrategyParameters {
      const params: StrategyParameters = {};

      params['symbol'] = isTruthy(this.symbol) ? this.symbol.ticker : null;
      params['fe1'] = isTruthy(this.fe1) ? this.fe1 + '' : null;
      params['fe2'] = isTruthy(this.fe2) ? this.fe2 + '' : null;
      params['profittarget'] = isTruthy(this.profitTarget) ? this.profitTarget + '' : null;
      params['tradelevel'] = isTruthy(this.tradeLevel) ? this.tradeLevel + '' : null;
      params['multiplier'] = isTruthy(this.multiplier) ? this.multiplier + '' : null;
      params['factor'] = isTruthy(this.factor) ? this.factor + '' : null;
      params['maxposition'] = isTruthy(this.maxPosition) ? this.maxPosition + '' : null;
      params['ctlvalue'] = isTruthy(this.ctlValue) ? this.ctlValue + '' : null;
      params['mmcontractsaddedper'] = isTruthy(this.mmContractsAddedPer) ? this.mmContractsAddedPer + '' : null;
      params['mmmaxscalelevels'] = isTruthy(this.mmMaxScaleLevels) ? this.mmMaxScaleLevels + '' : null;
      params['mmcontractsdecreaseper'] = isTruthy(this.mmContractsDecreasePer) ? this.mmContractsDecreasePer + '' : null;
      params['mmisregress'] = isTruthy(this.mmIsRegress) ? this.mmIsRegress + '' : null;
      params['mmresetonmaxlevel'] = isTruthy(this.mmResetOnMaxLevel) ? this.mmResetOnMaxLevel + '' : null;
      params['mmisreverse'] = isTruthy(this.mmIsReverse) ? this.mmIsReverse + '' : null;
      params['mmcontractsdecreasenum'] = isTruthy(this.mmContractsDecreaseNum) ? this.mmContractsDecreaseNum + '' : null;
      params['timestostayotr'] = isTruthy(this.timesToStayOtr) ? this.timesToStayOtr + '' : null;
      params['otrbet1'] = !isNullOrUndefined(this.otrBet1) && !isNaN(this.otrBet1) ? this.otrBet1 + '' : null;
      params['otrbet2'] = !isNullOrUndefined(this.otrBet2) && !isNaN(this.otrBet2) ? this.otrBet2 + '' : null;
      params['otrbet3'] = !isNullOrUndefined(this.otrBet3) && !isNaN(this.otrBet3) ? this.otrBet3 + '' : null;
      params['otrbet4'] = !isNullOrUndefined(this.otrBet4) && !isNaN(this.otrBet4) ? this.otrBet4 + '' : null;
      params['otrbet5'] = !isNullOrUndefined(this.otrBet5) && !isNaN(this.otrBet5) ? this.otrBet5 + '' : null;
      params['stayotr'] = isTruthy(this.stayOtr) ? this.stayOtr + '' : null;
      params['repeatotrprogression'] = isTruthy(this.repeatOtrProgression) ? this.repeatOtrProgression + '' : null;
      params['doubleotrbetonloss'] = isTruthy(this.doubleOtrBetOnLoss) ? this.doubleOtrBetOnLoss + '' : null;
      params['maxtimesdoubleotrbetonloss'] = isTruthy(this.maxTimesDoubleOtrBetOnLoss) ? this.maxTimesDoubleOtrBetOnLoss + '' : null;
      params['istradethroughproxy'] = isTruthy(this.isTradeThroughProxy) ? this.isTradeThroughProxy + '' : null;
      params['istradethroughstop'] = isTruthy(this.isTradeThroughStop) ? this.isTradeThroughStop + '' : null;
      params['istradethroughmit'] = isTruthy(this.isTradeThroughMIT) ? this.isTradeThroughMIT + '' : null;

      return params;
   }

   
   _SetParameters(dto: StrategyDto) {
      const params = {};

      Object.keys(dto.parameters)
         .forEach(key => params[key.toLowerCase()] = dto.parameters[key]);

      if (isTruthy(params['symbol'])) {
         
         const ticker = params['symbol'];
         
         const instrument = this._TradingInstrumentsService.getInstrumentByTicker(ticker);
         
         this.symbol = instrument;
      }
      if (isTruthy(params['fe1'])) {
         this.fe1 = parseFloat(params['fe1']);
      }
      if (isTruthy(params['fe2'])) {
         this.fe2 = parseFloat(params['fe2']);
      }
      if (isTruthy(params['profittarget'])) {
         this.profitTarget = parseFloat(params['profittarget']);
      }
      if (isTruthy(params['tradelevel'])) {
         this.tradeLevel = parseFloat(params['tradelevel']);
      }
      if (isTruthy(params['multiplier'])) {
         this.multiplier = parseInt(params['multiplier']);
      }
      if (isTruthy(params['factor'])) {
         this.factor = parseFloat(params['factor']);
      }
      if (isTruthy(params['maxposition'])) {
         this.maxPosition = parseInt(params['maxposition']);
      }
      if (isTruthy(params['ctlvalue'])) {
         this.ctlValue = parseFloat(params['ctlvalue']);
      }
      if (isTruthy(params['mmcontractsaddedper'])) {
         this.mmContractsAddedPer = parseFloat(params['mmcontractsaddedper']);
      }
      if (isTruthy(params['mmmaxscalelevels'])) {
         this.mmMaxScaleLevels = parseInt(params['mmmaxscalelevels']);
      }
      if (isTruthy(params['mmcontractsdecreaseper'])) {
         this.mmContractsDecreasePer = parseFloat(params['mmcontractsdecreaseper']);
      }
      if (isTruthy(params['mmisregress'])) {
         this.mmIsRegress = params['mmisregress'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mmresetonmaxlevel'])) {
         this.mmResetOnMaxLevel = params['mmresetonmaxlevel'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mmisreverse'])) {
         this.mmIsReverse = params['mmisreverse'].toLowerCase() === 'true';
      }
      if (isTruthy(params['mmcontractsdecreasenum'])) {
         this.mmContractsDecreaseNum = parseInt(params['mmcontractsdecreasenum']);
      }
      if (isTruthy(params['timestostayotr'])) {
         this.timesToStayOtr = parseInt(params['timestostayotr']);
      }
      if (!isNullOrUndefined(params['otrbet1'])) {
         this.otrBet1 = parseInt(params['otrbet1']);
      }
      if (!isNullOrUndefined(params['otrbet2'])) {
         this.otrBet2 = parseInt(params['otrbet2']);
      }
      if (!isNullOrUndefined(params['otrbet3'])) {
         this.otrBet3 = parseInt(params['otrbet3']);
      }
      if (!isNullOrUndefined(params['otrbet4'])) {
         this.otrBet4 = parseInt(params['otrbet4']);
      }
      if (!isNullOrUndefined(params['otrbet5'])) {
         this.otrBet5 = parseInt(params['otrbet5']);
      }
      if (isTruthy(params['stayotr'])) {
         this.stayOtr = params['stayotr'].toLowerCase() === 'true';
      }
      if (isTruthy(params['repeatotrprogression'])) {
         this.repeatOtrProgression = params['repeatotrprogression'].toLowerCase() === 'true';
      }
      if (isTruthy(params['doubleotrbetonloss'])) {
         this.doubleOtrBetOnLoss = params['doubleotrbetonloss'].toLowerCase() === 'true';
      }
      if (isTruthy(params['maxtimesdoubleotrbetonloss'])) {
         this.maxTimesDoubleOtrBetOnLoss = parseInt(params['maxtimesdoubleotrbetonloss']);
      }
      if (isTruthy(params['istradethroughproxy'])) {
         this.isTradeThroughProxy = params['istradethroughproxy'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughstop'])) {
         this.isTradeThroughStop = params['istradethroughstop'].toLowerCase() === 'true';
      }
      if (isTruthy(params['istradethroughmit'])) {
         this.isTradeThroughMIT = params['istradethroughmit'].toLowerCase() === 'true';
      }
   }

   
   validateSpecificParameters(validationContext: any): string[] {
      const errors: string[] = [];

      if (!isTruthy(this.symbol)) {
         errors.push('Symbol is required');
      }

      if (!isTruthy(this.profitTarget) || this.profitTarget === 0) {
         errors.push('ProfitTarget is required');
      }

      if (!isTruthy(this.tradeLevel) || this.tradeLevel === 0) {
         errors.push('TradeLevel is required');
      }

      if (!isTruthy(this.multiplier) || this.multiplier === 0) {
         errors.push('Multiplier is required');
      }

      if (!isTruthy(this.factor) || this.factor === 0) {
         errors.push('Factor is required');
      }

      if (!isTruthy(this.maxPosition) || this.maxPosition === 0) {
         errors.push('MaxPosition is required');
      } else {
         if (!this._checkMaxPosition()) {
            errors.push('Incorrect MaxPosition value');
         }
      }

      if (this.isOtrEnabled) {
         if (!isTruthy(this.timesToStayOtr) || this.timesToStayOtr === 0) {
            errors.push('TimesToStrayOTR is required');
         }


         const otrBets = [
            (isNullOrUndefined(this.otrBet1) || isNaN(this.otrBet1)) ? -1 : this.otrBet1,
            (isNullOrUndefined(this.otrBet2) || isNaN(this.otrBet2)) ? -1 : this.otrBet2,
            (isNullOrUndefined(this.otrBet3) || isNaN(this.otrBet3)) ? -1 : this.otrBet3,
            (isNullOrUndefined(this.otrBet4) || isNaN(this.otrBet4)) ? -1 : this.otrBet4,
            (isNullOrUndefined(this.otrBet5) || isNaN(this.otrBet5)) ? -1 : this.otrBet5
         ];

         const otrBetsSum = otrBets.reduce((acc, curr) => acc + curr, 0);
         if (otrBetsSum === -5) {
            errors.push('At least one "OTR Bet X" value is required');
         }

         let gapCounter = 0;
         for (let i = 1; i <= otrBets.length; i++) {

            const otrBet = otrBets[i - 1];
            if (otrBet >= 0) {
               gapCounter++;
            }

            if (gapCounter !== i) {
               const sum = otrBets.slice(i - 1).reduce((acc, curr) => acc + curr, 0);
               if (sum > 0) {
                  errors.push('OTR Bets sequence should not contain empty cells in between of bets');
                  break;
               }
            }
         }
      }

      if (this.mmIsReverse) {
         if (!isTruthy(this.mmContractsDecreaseNum) || this.mmContractsDecreaseNum === 0) {
            errors.push('Decrease Contracts is required with reverse MM');
         }

         if (!isTruthy(this.mmContractsDecreasePer) || this.mmContractsDecreasePer === 0) {
            errors.push('Contracts Decrease Per is rqeuired with reverse MM');
         }

         if (!this.mmIsRegress) {
            const multiplier = this.multiplier || 0;
            const contractsDecreaseNum = this.mmContractsDecreaseNum || 0;
            const maxScaleLevels = this.mmMaxScaleLevels || 0;

            if (maxScaleLevels * contractsDecreaseNum >= multiplier - 1 ||
               maxScaleLevels === 0) {
               this._Toastr.info('Minimum multiplier will be 1');
            }
         }
      }

      if (isTruthy(this.mmContractsDecreasePer) || isTruthy(this.mmContractsAddedPer)) {
         if (!isTruthy(this.mmMaxScaleLevels) || this.mmMaxScaleLevels === 0) {
            errors.push('Max Scale Levels is required');
         }
      }

      return errors;
   }

   
   
   setCommonDispositionParameters(parameters: ICommonDispositionParameters, isUpdate: boolean) {
      if (!parameters) {
         return;
      }
      switch (parameters.dispositionType) {
         case DispositionType.NetBetting: {
            this.symbol = parameters.symbol;
            this.profitTarget = this.tradeLevel = parameters.fallbackCtlValue;
            this.isNetBettingMode = true;
            break;
         }
         case DispositionType.TheBlend: {
            break;
         }
         case DispositionType.Fusion: {
            break;
         }
      }
      this._commonDispositionParameters = parameters;
   }

   
   onCustomNumberCreated(data, collection: number[]) {
      const newItem = parseFloat(data.text);
      if (!isNaN(newItem)) {
         const isNew = collection.indexOf(newItem) === -1;
         data.customItem = newItem;
         if (isNew) {
            collection.push(newItem);
         }
      }
   }

   
   private _updateTickSizeDependentDropdowns(tickSize: number, precision: number) {
      this.fe1 = null;
      this.fe2 = null;
      this.profitTarget = null;
      this.tradeLevel = null;
      this.ctlValue = null;

      this.fe1Items = [];
      this.fe2Items = [];
      this.profitTargetItems = [];
      this.tradeLevelItems = [];
      this.ctlValueItems = [];

      const formatter = new Intl.NumberFormat('en-IN', {
         minimumFractionDigits: precision,
         maximumFractionDigits: precision
      });

      const items = [];
      for (let i = 1; i < 50; i++) {
         let step = i * tickSize;
         step = parseFloat(formatter.format(step));
         this.fe1Items.push(step);
         this.fe2Items.push(step);
         this.profitTargetItems.push(step);
         this.tradeLevelItems.push(step);
         this.ctlValueItems.push(step);
      }
   }

   
   private _updateMaxPositionsItems() {
      if (!isTruthy(this.factor) || !isTruthy(this.multiplier)
         || this.multiplier === 0) {
         this.maxPosition = null;
         this.maxPositionItems = [];

         return;
      }

      const newItems = [];

      for (let i = 1; i < 50; i++) {
         if (this.factor === 1) {
            const item = i * this.multiplier;
            newItems.push(item);
         } else {
            const item = Math.pow(this.factor, i) * this.multiplier;
            newItems.push(item);
         }
      }

      this.maxPositionItems = newItems;
      this.maxPosition = null;
   }

   
   private _checkMaxPosition(): boolean {
      const correctMaxPosition: number[] = [];
      if (isTruthy(this.factor) && isTruthy(this.multiplier)) {
         for (let i = 0; i < 100; i++) {
            if (this.factor === 1) {
               const val = this.multiplier * (i + 1);
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            } else {
               const pow = Math.pow(this.factor, i);
               const val = this.multiplier * pow;
               if (val > 0) {
                  correctMaxPosition.push(val);
                  if (val >= 10000) {
                     break;
                  }
               }
            }
         }
      }

      const curMP = this.maxPosition || 0;
      let mpIsCorrect = false;
      for (const mp of correctMaxPosition) {
         if (mp === curMP) {
            mpIsCorrect = true;
            break;
         }
      }
      return mpIsCorrect;
   }


   onStrategyTerminalChanged(): void {
   }

}
