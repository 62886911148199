import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { UnaccountedDataComponent } from './unaccounted-data.component';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import {
   TradingInstrumentDisplayNameService
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {
   centeredColumnDef,
   defaultNumberCellFormatter,
   defaultQuoteCellFormatter,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   defaultPriceCellFormatter
} from 'projects/shared-components/ag-grid-contrib';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';

export function getUnaccountedPositionsGridModel(
   this: UnaccountedDataComponent,
   displayNameService: TradingInstrumentDisplayNameService
): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Symbol',
            field: 'ticker',
            valueFormatter: (params: ValueFormatterParams) => {
               return displayNameService.getDisplayNameForTicker(params.value);
            },
            cellRenderer: 'agGroupCellRenderer'
         },
         //
         {
            headerName: 'Net Positions',
            field: 'netPosition',
            valueFormatter: defaultNumberCellFormatter
         },
         //
         {
            headerName: 'Avg. Px',
            field: 'avgPx',
            valueFormatter: defaultPriceCellFormatter
         },
         //
         {
            headerName: 'Last Price',
            field: 'lastMatchPx',
            valueFormatter: defaultPriceCellFormatter
         },
         //
         // Object.assign(
         //   { headerName: 'Open P&L', field: 'sessionUnrealizedPnL' },
         //   defaultMoneyCellDefinition
         // ),
         //
         Object.assign(
            { headerName: 'Session P&L', field: 'sessionTotalPnL' },
            defaultMoneyCellDefinition
         ),
         //
         Object.assign(
            { headerName: 'Acc. P&L', field: 'accumulatedTotalPnL' },
            defaultMoneyCellDefinition
         ),
         //
         { headerName: 'Terminal', field: 'terminalCode' },
         //
         { headerName: 'Account', field: 'accountCode' }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,





      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      popupParent: this.contextPopupParent,

      onGridReady: (args) => this.onPositionsGridReady(args),

      getRowNodeId: (rowData: PositionDto) => {
         return rowData.positionId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];
         if (this.securityContext.allocatePositions) {
            menu.push(
               {
                  name: 'Allocate',
                  action: () => this.showAllocatePositionDialog(params.node.data),
                  disabled: !params.node
               }
            );
         }
         menu.push(
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );
         return menu;
      }
   } as GridOptions;
}
