<div class="column left">

  <div class="ets-field">
    <div class="ets-field-label">Symbol</div>
    <div class="ets-field-value">
      <ets-symbol-picker (instrumentSelected)="control.symbol = $event"
                         [disabled]="control.isNetBettingMode"
                         [selectedInstrument]="control.symbol"
                         #symbolPicker
      ></ets-symbol-picker>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">FE 1</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.fe1Items"
                     [value]="control.fe1"
                     (valueChange)="control.fe1 = $event"
                     [showClearButton]="true"
                     [disabled]="control.isNetBettingMode"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.fe1Items)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">FE 2</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.fe2Items"
                     [value]="control.fe2"
                     (valueChange)="control.fe2 = $event"
                     [showClearButton]="true"
                     [disabled]="control.isNetBettingMode"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.fe2Items)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Profit Target</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.profitTargetItems"
                     [value]="control.profitTarget"
                     (valueChange)="control.profitTarget = $event"
                     [showClearButton]="true"
                     [disabled]="control.isNetBettingMode"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.profitTargetItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Trade Level</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.tradeLevelItems"
                     [value]="control.tradeLevel"
                     (valueChange)="control.tradeLevel = $event"
                     [showClearButton]="true"
                     [disabled]="control.isNetBettingMode"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.tradeLevelItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Multiplier</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.multiplierItems"
                     [value]="control.multiplier"
                     (valueChange)="control.multiplier = $event"
                     [showClearButton]="true"
                     [acceptCustomValue]="true"
                     (onCustomItemCreating)="control.onCustomNumberCreated($event, control.multiplierItems)"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Factor</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.factorItems"
                     [value]="control.factor"
                     (valueChange)="control.factor = $event"
                     [showClearButton]="true"
      ></dx-select-box>
    </div>
  </div>

  <div class="ets-field">
    <div class="ets-field-label">Max. Position</div>
    <div class="ets-field-value">
      <dx-select-box [items]="control.maxPositionItems"
                     [value]="control.maxPosition"
                     (valueChange)="control.maxPosition = $event"
                     [showClearButton]="true"
      ></dx-select-box>
    </div>
  </div>

  <fieldset>
    <legend>Threaded</legend>
    <div class="ets-field">
      <div class="ets-field-label">
        <dx-check-box text="Use CTL"
                      [value]="control.isCtlEnabled"
                      (valueChange)="control.isCtlEnabled = $event"
                      [disabled]="control.isNetBettingMode"
        ></dx-check-box>
      </div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.ctlValueItems"
                       [disabled]="!control.isCtlEnabled || control.isNetBettingMode"
                       [value]="control.ctlValue"
                       (valueChange)="control.ctlValue = $event"
                       [showClearButton]="true"
                       [acceptCustomValue]="true"
                       (onCustomItemCreating)="control.onCustomNumberCreated($event, control.ctlValueItems)"
        ></dx-select-box>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <legend>Order Conditions</legend>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Proxy"
                    [value]="control.isTradeThroughProxy"
                    (valueChange)="control.isTradeThroughProxy = $event"
                    [disabled]="control.isNetBettingMode"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Market"
                    [value]="control.isTradeThroughStop"
                    (valueChange)="control.isTradeThroughStop = $event"
                    [disabled]="control.isNetBettingMode"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through PT"
                    [value]="control.isTradeThroughMIT"
                    (valueChange)="control.isTradeThroughMIT = $event"
                    [disabled]="control.isNetBettingMode"
      ></dx-check-box>
    </div>
    <div class="ets-field">
      <dx-check-box text="Trade-Through Limits"
                    [value]="control.isTradeThroughLimit"
                    (valueChange)="control.isTradeThroughLimit = $event"
                    [disabled]="control.isNetBettingMode"
      ></dx-check-box>
    </div>
  </fieldset>

</div>

<div class="column right">
  <fieldset>
    <legend>Money Management</legend>
    <div class="ets-field">
      <div class="ets-field-label">Contracts Added Per</div>
      <div class="ets-field-value">
        <dx-text-box [value]="control.mmContractsAddedPer"
                     (valueChange)="control.mmContractsAddedPer = $event"
                     [disabled]="control.mmIsReverse"
        ></dx-text-box>
      </div>
    </div>

    <div class="ets-field">
      <div class="ets-field-label">Max. Scale Levels</div>
      <div class="ets-field-value">
        <dx-select-box [items]="control.mmMaxScaleLevelsItems"
                       [value]="control.mmMaxScaleLevels"
                       (valueChange)="control.mmMaxScaleLevels = $event"
        ></dx-select-box>
      </div>
    </div>

    <div class="ets-field">
      <div class="ets-field-label">Decrease Per</div>
      <div class="ets-field-value">
        <dx-text-box [value]="control.mmContractsDecreasePer"
                     (valueChange)="control.mmContractsDecreasePer = $event"
                     [disabled]="!control.mmIsReverse"
        ></dx-text-box>
      </div>
    </div>

    <div class="ets-field">
      <div class="ets-field-label">Contracts To Decrease</div>
      <div class="ets-field-value">
        <dx-text-box [value]="control.mmContractsDecreaseNum"
                     (valueChange)="control.mmContractsDecreaseNum = $event"
                     [disabled]="!control.mmIsReverse"
        ></dx-text-box>
      </div>
    </div>

    <div class="fields-line mm-checkboxes">
      <dx-check-box text="Reset"
                    [value]="control.mmResetOnMaxLevel"
                    (valueChange)="control.mmResetOnMaxLevel = $event"
      ></dx-check-box>
      <dx-check-box text="Reverse"
                    [value]="control.mmIsReverse"
                    (valueChange)="control.mmIsReverse = $event"
      ></dx-check-box>
      <dx-check-box text="Regress"
                    [value]="control.mmIsRegress"
                    (valueChange)="control.mmIsRegress = $event"
      ></dx-check-box>
    </div>

  </fieldset>

  <fieldset>
    <legend>
      <dx-check-box text="Enable OTR Mode"
                    #otr
                    [(value)]="control.isOtrEnabled"
      ></dx-check-box>
    </legend>
    <div class="fields-line">
      <div class="ets-field inline times-to-stay-otr">
        <div class="ets-field-label">Times to Stay OTR</div>
        <div class="ets-field-value">
          <dx-select-box [items]="control.timesToStayOtrItems"
                         [disabled]="!otr.value"
                         [(value)]="control.timesToStayOtr"
          ></dx-select-box>
        </div>
      </div>
    </div>
    <div class="fields-line otr-bets">
      <div class="ets-field inline otr">
        <p>1</p>
        <dx-number-box [showClearButton]="true"
                       [(value)]="control.otrBet1"
                       [disabled]="!otr.value"
        ></dx-number-box>
      </div>
      <div class="ets-field inline otr">
        <p>2</p>
        <dx-number-box [showClearButton]="true"
                       [(value)]="control.otrBet2"
                       [disabled]="!otr.value"
        ></dx-number-box>
      </div>
      <div class="ets-field inline otr">
        <p>3</p>
        <dx-number-box [showClearButton]="true"
                       [(value)]="control.otrBet3"
                       [disabled]="!otr.value"
        ></dx-number-box>
      </div>
      <div class="ets-field inline otr">
        <p>4</p>
        <dx-number-box [showClearButton]="true"
                       [(value)]="control.otrBet4"
                       [disabled]="!otr.value"
        ></dx-number-box>
      </div>
      <div class="ets-field inline otr">
        <p>5</p>
        <dx-number-box [showClearButton]="true"
                       [(value)]="control.otrBet5"
                       [disabled]="!otr.value"
        ></dx-number-box>
      </div>
    </div>
    <div class="fields-line otr-misc">
      <div class="ets-field inline left">
        <dx-check-box text="Stay OTR" class="stay-otr"
                      [(value)]="control.stayOtr"
                      [disabled]="!otr.value"
        ></dx-check-box>
        <dx-check-box text="Repeat Progression"
                      [(value)]="control.repeatOtrProgression"
                      [disabled]="!otr.value"
        ></dx-check-box>
      </div>
      <div class="ets-field inline right">
        <dx-check-box text="Double OTR"
                      [(value)]="control.doubleOtrBetOnLoss"
                      [disabled]="!otr.value"
        ></dx-check-box>
        <div class="max-times-block">
          <span>Max. Times Double OTR</span>
          <dx-select-box [items]="control.maxTimesDoubleOtrItems"
                         [(value)]="control.maxTimesDoubleOtrBetOnLoss"
                         [disabled]="!otr.value"
          ></dx-select-box>
        </div>
      </div>
    </div>
  </fieldset>

</div>
