import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { MessageBusService } from '../message-bus.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { StrategiesSubscription } from '../strategies/strategies-subscription.class';
import { StrategiesService } from '../strategies/strategies.service';
import { TradingInstrumentDisplayNameService } from '../trading-instruments/trading-instrument-display-name.service';
import { isAdjustmentAlgo } from '../utils';
import { TradingSystemsSecurityContextService } from './trading-systems-security-context.service';
import { TradingSystemsViewComponent } from './trading-systems-view.component';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-adjustemnt-strategies-view',
   templateUrl: 'trading-systems-view.component.html',
   styleUrls: ['trading-systems-view.component.scss']
})

export class AdjustmentStrategiesViewComponent extends TradingSystemsViewComponent {
   constructor(
      changeDetector: ChangeDetectorRef,
      userSettingsService: UserSettingsService,
      messageBus: MessageBusService,
      securityContext: TradingSystemsSecurityContextService,
      displayNameService: TradingInstrumentDisplayNameService,
      strategiesService: StrategiesService,
      shellClient: ShellClientService,
      toastr: ToastrService,
      algoMetadataService: AlgoMetadataService,
   ) {
      super(changeDetector, userSettingsService, messageBus, securityContext, displayNameService
         , strategiesService, shellClient, toastr, algoMetadataService);
   }

   viewType: 'trading' | 'adjustment' = 'adjustment';

   getStrategiesSubscription(): StrategiesSubscription {
      
      const subs = this._strategiesService.subscribe(
         (strat) => isAdjustmentAlgo(strat.algoId)
         , 'main'
         ,  this
      );

      return subs;
   }
}
