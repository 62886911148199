import { StrategySessionHistoryComponent } from './strategy-session-history.component';
import { GridOptions, GetContextMenuItemsParams } from 'ag-grid-community';
import { defaultMoneyCellDefinition, defaultLoadingOverlayTemplate, centeredColumnDef } from 'projects/shared-components/ag-grid-contrib';
import { StrategyModel } from '../strategies/strategy-model';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';

export function getStrategiesListGridModel(
   this: StrategySessionHistoryComponent
) {
   const columns = [];
   columns.push(
      {
         headerName: 'Name',
         field: 'displayName',
         cellRenderer: 'agGroupCellRenderer',
         filter: 'agTextColumnFilter'
      },
      {
         headerName: 'Algo',
         field: 'algo',
         enableRowGroup: true,
         filter: true,
      },
      {
         headerName: 'Portfolio',
         field: 'portfolioName',
         enableRowGroup: true,
         filter: true
      },
      {
         headerName: 'Combo',
         field: 'comboName',
         enableRowGroup: true,
         filter: true
      },
      {
         headerName: 'ComboGroup',
         field: 'comboGroupName',
         enableRowGroup: true,
         filter: true
      },
      {
         headerName: 'Terminal',
         field: 'terminalCode',
         enableRowGroup: true,
         filter: true
      },
   );
   if (this.securityContext.systemDetails) {
      columns.push(

         {
            headerName: 'Description',
            field: 'description',
            filter: 'agTextColumnFilter'
         }
      );
   }
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: columns,

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      suppressCellSelection: true,

      masterDetail: true,

      isRowMaster: (data: StrategyModel) => {
         return data ? data.dispositionStrategies && data.dispositionStrategies.length > 0 : false;
      },

      detailCellRendererParams: {
         detailGridOptions: {
            defaultColDef: centeredColumnDef,
            rowData: [],
            columnDefs: [
               {
                  headerName: 'Name',
                  field: 'displayName'
               },
               {
                  headerName: 'Algo',
                  field: 'algo',
                  enableRowGroup: true,
               },
               {
                  headerName: 'Terminal',
                  field: 'terminalCode',
                  enableRowGroup: true,
               },
               {
                  headerName: 'Description',
                  field: 'description'
               }
            ],
            overlayLoadingTemplate: defaultLoadingOverlayTemplate,
            rowClass: 'ets-text-centered',
            rowSelection: 'single',
            rowModelType: 'clientSide',
            getRowNodeId: (rowData: StrategyModel) => rowData.strategyId,
            onFirstDataRendered: (args) => {
               args.api.sizeColumnsToFit();
            },
            onSelectionChanged: args => this.onStrategiesSelectionChanged(args),
         },

         getDetailRowData: (args) => {
            args.successCallback(args.data.dispositionStrategies || []);
         },

      },

      frameworkComponents: {
         simpleHeaderRenderer: SimpleHeaderGroupRowInnerRenderer,
      } as any,

      rowGroupPanelShow: 'always',

      groupUseEntireRow: true,

      groupRowRendererParams: {
         innerRenderer: 'simpleHeaderRenderer',
         suppressCount: true
      },


      onGridReady: args => this.onStrategiesGridReady(args),

      onSelectionChanged: args => this.onStrategiesSelectionChanged(args),

      getRowNodeId: (rowData: StrategyModel) => {
         return rowData.strategyId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];

         if (this.securityContext.loadStrategiesByDateRange) {
            menu.push(
               {
                  name: 'Load By Date Range',
                  action: () => {
                     this.dateRange.ctx = 'strategy';
                     this.isDatePickerVisible = true;
                  }
               }
            );
         }

         if (this.securityContext.systemDetails) {
            menu.push(
               {
                  name: 'System Details',
                  disabled: !params.node || params.node.group || !params.node.data,
                  action: () => this.showSystemDetails(params.node.data)
               }
            );
         }

         menu.push(
            {
               name: 'Toggle Full Size',
               action: () => this.toggleSectionFullWidth('strategy')
            },
            'separator',
            'autoSizeAll',
            'copy',
            'export'
         );

         return menu;
      }
   } as GridOptions;
}
