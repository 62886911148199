<dx-popup [visible]="this.visible" width="27vw"
          title="Settings" (onHidden)="this.onHidden()">
  <div *dxTemplate="let data of 'content'" #view class="root">
    
    <div class="settings">
      
      <div class="portfolios">
        <dx-select-box
          [items]="this.portfolioList"
          [(value)]="this.selectedPortfolio"
          (onValueChanged)="this.onSettingsPortfolioSelected($event)"
          [grouped]="true"
          [displayExpr]="'name'"
          fieldTemplate="field"
        >
          <div *dxTemplate="let data of 'field'">
            <dx-text-box
              placeholder="Select portfolio ..."
              [readOnly]="true"
              [value]="data && data.userName + '  |  ' + data.name"
            ></dx-text-box>
          </div>
        </dx-select-box>
      </div>
      
      <div class="trees">
        <dx-scroll-view>
          
          <fieldset class="frame">
            <legend class="legend">Packages/Dashboard</legend>
            <div class="tree">
              <dx-tree-view #tree
                            [selectionMode]="'multiple'"
                            [selectByClick]="true"
                            [selectNodesRecursive]="true"
                            [showCheckBoxesMode]="'normal'"
                            [items]="this.treeItems"
                            (onItemSelectionChanged)="this.onSettingsSelectionChanged($event)"
              ></dx-tree-view>
            </div>
          </fieldset>
          
          <fieldset class="frame">
            <legend class="legend">Hedges Dashboard</legend>
            <div class="tree">
              <dx-tree-view #hbTree
                            [selectionMode]="'multiple'"
                            [selectByClick]="true"
                            [selectNodesRecursive]="true"
                            [showCheckBoxesMode]="'normal'"
                            [items]="this.hedgeBoardTree"
                            (onItemSelectionChanged)="this.onSettingsSelectionChanged($event)"
              ></dx-tree-view>
            </div>
          </fieldset>
        </dx-scroll-view>
      </div>
      
    
    </div>
    
    <div class="buttons">
      <div class="btn">
        <dx-button text="Save" width="100%" (onClick)="this.onSaveClick(tree, hbTree)"></dx-button>
      </div>
      <div class="btn">
        <dx-button text="Cancel" width="100%" (onClick)="this.onCancelClick()"></dx-button>
      </div>
    </div>
    
    <dx-load-panel
      [visible]="this.isLoading"
      [position]="{my: 'center', at: 'center', of: view}"
    >
    </dx-load-panel>
  
  
  </div>
</dx-popup>