// tslint:disable:no-bitwise
export enum StrategyState {
  Unknown = 0,
  Inactive = 1,
  Activating = 2,
  Active = 4,
  Deactivating = 8,
  Dead = 16,
  RiskViolation = 32,

  Enabled = Activating | Active | Deactivating
}
