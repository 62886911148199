<dx-popup
   [width]="this.viewModel.width"
   [height]="this.viewModel.height"
   [visible]="this.viewModel.isVisible"
   [maxWidth]="this.viewModel.maxWidth"
   [maxHeight]="this.viewModel.maxHeight"
   [resizeEnabled]="true"
   title="Attention! Complete the checklist!"
   (onHidden)="this.viewModel.onPopupHidden()"
   >
   <div  *dxTemplate="let data of 'content'" class="checklist-popup" #checklistpopup>
      
      <div class="checklists-container">

         <fieldset class="block checklist" *ngFor="let checklist of this.viewModel.checklists">
            <legend class="header">
               {{this.viewModel.getChecklistTitle(checklist)}}
            </legend>

            <dx-scroll-view>
               <ng-container *ngFor="let step of checklist">
                  <div
                     class="checklist-content" 
                     [innerHTML]="step?.markup | allowStyles">
                  </div>
                  <br>
               </ng-container>
            </dx-scroll-view>

         </fieldset>

      </div>

      <div class="block buttons">
         <dx-button 
            text="I Confirm That I Have Completed All Steps" 
            (onClick)="this.viewModel.onConfirmed()"
            >
         </dx-button>
      </div>

      <dx-load-panel [(visible)]="this.viewModel.isLoading"
         [position]="{my: 'center', at: 'center', of: checklistpopup}">
      </dx-load-panel>
   </div>
</dx-popup>