import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'ets-before-state-defaults-header',
   template: `
      <div class="header">
         <span class="border left">&nbsp;</span>
         <span class="text">{{this.header}}</span>
         <span class="border right">&nbsp;</span>
      </div>
   `,
   styles: [`
      .header {
         display: flex;
         flex-direction: row;
      }

      .text {
         font-weight: 800;
         font-size: 1.2em;
      }

      .border {
         border: 2px solid;
         height: 1px;
         margin-top: 7px;
         width: 100%;
      }

      .border.left {
         margin-right: 5px;
      }
      .border.right {
         margin-left: 5px;
      }
   `]
})
export class BeforeStatePopupDefaultsHeaderComponent implements OnInit {
   constructor() { }

   @Input() header: string;

   ngOnInit(): void { }
}
