<dx-context-menu
        [target]="this.target"
        cssClass="ctxMenu"
        [visible]="this.visible"
        [displayExpr]="'text'"
        [dataSource]="this.menuItems"
        (onHidden)="this.visible = false"
        (onItemClick)="this.onMenuItemClicked($event)"
        [closeOnOutsideClick]="false"
>
    <dxo-position
            [my]="'right top'"
            [at]="'right bottom'"
            [of]="this.target"
    >
    </dxo-position>
</dx-context-menu>
