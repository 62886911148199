import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SettingsStorageService } from '../settings-storage-service.service';
import { DetectMethodChanges } from '../utils';
import { ToastrService } from 'ngx-toastr';
import { ShellClientService } from '../shell-communication/shell-client.service';
import { GetStrategyTriggers } from '../shell-communication/shell-operations-protocol';
import { MessageBusService } from '../message-bus.service';
import { TriggerStateChangedDto } from '../shell-communication/shell-dto-protocol';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { StrategiesService } from '../strategies/strategies.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import {UserSettingsService} from "../user-settings.service";

interface Trigger {
   id: string;
   parentId: string;
   triggerId: string;
   displayName: string;
   state: boolean;
   triggerType: string;
}


@Component({
   selector: 'ets-strategy-triggers',
   templateUrl: './strategy-triggers.component.html',
   styleUrls: ['./strategy-triggers.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyTriggersComponent extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private _toastr: ToastrService,
      private _shellClient: ShellClientService,
      private readonly _strategyService: StrategiesService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
    }
   

   private _unsubscriber: Subject<any>;


   triggers: Trigger[] = [];

   contextStrategyId: string;


   async etsOnInit() {

      this._unsubscriber = new Subject<any>();

      this._messageBus.of<TriggerStateChangedDto>('TriggerStateChangedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this._onTriggerStateChanged(x.payload));

      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onStrategyHighlightedMessage(message.payload));

      await this._loadLastHighlightedStrategy();
   }

   
   etsAfterViewInit() { }

   
   etsOnDestroy() {
      this._unsubscriber.next();
      this._unsubscriber.complete();
   }

   
   @DetectMethodChanges()
   private async _loadStrategyTriggers(strategyId: string) {
      this.isLoading = true;
      try {
         const strategy = this._strategyService.getById(strategyId);
         if (!strategy) {
            throw new Error('Strategy Not Registered');
         }
         const qry = new GetStrategyTriggers(strategyId);
         const triggers = await this._shellClient.processQuery<Trigger[]>(qry, strategy.shellId);
         this.triggers = triggers;
      } catch (e) {
         this._toastr.error('"Load Strategy Triggers" operation completed with errors');
      } finally {
         this.isLoading = false;
      }
   }

   
   private async _loadLastHighlightedStrategy(): Promise<void> {
      const lastMessage = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>(
         'StrategyHighlightedUIMessage',
         this.layoutTabId
      );
      if (lastMessage) {
         await this._onStrategyHighlightedMessage(lastMessage.payload);
      }
   }

   
   private async _onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage): Promise<any> {
      if (message.strategyId === this.contextStrategyId) {
         return;
      }

      this.contextStrategyId = message.strategyId;

      await this._loadStrategyTriggers(this.contextStrategyId);
   }

   
   @DetectMethodChanges()
   private _onTriggerStateChanged(dto: TriggerStateChangedDto): void {
      const trgr = this.triggers.find(x => x.triggerId === dto.triggerId);
      if (trgr) {
         trgr.state = dto.state;
      }
   }

   
   protected getState(): any { return null; }

   
   protected setState() {  }
}
