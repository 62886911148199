import { 
  StrategiesTradingDataBackendService 
} from 'projects/shared-components/strategies/strategies-trading-data-backend-service.interface';
import { Injectable } from '@angular/core';
import { GetTradingDataResponseWeb } from 'projects/shared-components/shell-communication/dtos/get-trading-data-response-web.class';
import { RequestOrderStatus } from 'projects/shared-components/shell-communication/operations/orders/request-order-status.class';
import { 
  GetStrategyLogMessagesWeb 
} from 'projects/shared-components/shell-communication/operations/strategies/get-strategy-log-messages-web.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { StrategyIssueDto } from 'projects/shared-components/shell-communication/dtos/strategy-issue-dto.class';
import { AckStrategyIssue } from 'projects/shared-components/shell-communication/operations/strategies/ack-strategy-issue.class';
import { GetStrategyIssuesWeb } from 'projects/shared-components/shell-communication/operations/strategies/get-strategy-issues-web.class';
import { CancelAllCustomExitOrders, CancelCustomExitOrder, GetAvailableBuckets, GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { GetAvailableBucketsReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';


@Injectable({ providedIn: 'root' })
export class WebtraderStrategiesTradingDataBackendService extends StrategiesTradingDataBackendService {
  
  constructor(private readonly _shellClient: ShellClientService) {
    super();
  }
  
  getWorkingOrders(qry: GetWorkingOrdersWeb): Promise<GetTradingDataResponseWeb> {
    return this._shellClient.processQuery(qry);
  }
  
  requestOrderStatus(orders: { orderId: string, shellId: string }[]): Promise<void> {
    const ordersByShell: Record<string, string[]> = {};
    orders.forEach(orderShell =>  {
      if (! (orderShell.shellId in ordersByShell)) {
        ordersByShell[orderShell.shellId] = [];
      }
      ordersByShell[orderShell.shellId].push(orderShell.orderId);
    });

    const promises = [];
    for (const shellId of Object.keys(ordersByShell)) {
      const shellOrders = ordersByShell[shellId];
      const cmd = new RequestOrderStatus(shellOrders);
      const promise = this._shellClient.processCommand(cmd);
      promises.push(promise);
    }
    return Promise.all(promises) as any;
  }
  
  getOrderStateSnapshots(qry: GetOrderStateSnapshotsWeb): Promise<GetTradingDataResponseWeb> {
    return this._shellClient.processQuery(qry);
  }
  
  getTrades(qry: GetTradesWeb): Promise<GetTradingDataResponseWeb> {
    return this._shellClient.processQuery(qry);
  }
  
  getMessages(qry: GetStrategyLogMessagesWeb): Promise<GetTradingDataResponseWeb> {
    return this._shellClient.processQuery(qry);
  }

  ackIssues(cmd: AckStrategyIssue): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }

  getIssues(qry: GetStrategyIssuesWeb): Promise<StrategyIssueDto[]> {
    return this._shellClient.processQuery(qry);
  }

  cancelAllCustomExitOrders(cmd: CancelAllCustomExitOrders): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }
  cancelCustomExitOrder(cmd: CancelCustomExitOrder): Promise<void> {
    return this._shellClient.processCommand(cmd);
  }
  getAvailableBuckets(): Promise<GetAvailableBucketsReply> {
   return this._shellClient.processQuery<GetAvailableBucketsReply>(new GetAvailableBuckets());
}
}
