import {EventEmitter} from '@angular/core';
import {LastQuoteCacheService} from '../../last-quote-cache.service';
import {isCashOnlySettledInstrument, OptionStrategy, OptionType} from '../../options-common/options.model';
import {BucketType} from '../../portfolios/portfolios.model';
import {TerminalDto} from '../../shell-communication/dtos/terminal-dto.class';
import {OptionExpirationDescriptor} from '../../shell-communication/shell-dto-protocol';
import {TradingInstrumentKind} from '../../trading-instruments/trading-instrument-kind.enum';
import {TradingInstrument} from '../../trading-instruments/trading-instrument.class';
import {
    ALPHABET,
    DetectMethodChanges,
    DetectSetterChanges,
    isNullOrUndefined,
    isTruthy,
    TextValueMap
} from '../../utils';
import {
    ActionType,
    IMultiTradePadComponent,
    LegType,
    OrderDestination,
    OrderDuration,
    OrderType
} from '../multi-trade-pad.model';
import {OrderLeg} from './order-leg.class';
import * as Enumerable from "linq";

//

export class MultiLegOrder {

    constructor(public root: IMultiTradePadComponent, sourceOrder?: MultiLegOrder) {


        this._legs = [];

        this._rollingLegs = [];

        this._expirationsList = [];

        this.optionTypeList = [
            {text: 'Call', value: OptionType.Call},
            {text: 'Put', value: OptionType.Put}
        ];

        this.actionTypeList = [
            {text: 'Buy', value: ActionType.Buy},
            {text: 'Sell', value: ActionType.Sell},
        ];

        this.orderTypeList = [
            {text: 'Limit', value: OrderType.Limit},
            {text: 'Market', value: OrderType.Market},
            {text: 'AutoLimit', value: OrderType.AutoLimit}
        ];

        this.orderDurationList = [
            {text: 'Day', value: OrderDuration.DAY},
            {text: 'GTC', value: OrderDuration.GTC},
        ];

        this._rollingQty = 1;

        this._orderType = OrderType.Limit;

        this._orderDuration = OrderDuration.GTC;

        const destination = new OrderDestination(this);
        destination.letter = ALPHABET[this.destinations.length];
        this.destinations.push(destination);
        destination.referenceGridOpened.subscribe(dest => this.showBucketItems.emit(dest));
    }

    //

    destinationAdded: EventEmitter<OrderDestination> = new EventEmitter<OrderDestination>();
    destinationRemoved = new EventEmitter<number>();
    destinationPressed = new EventEmitter<{ ix: number, state: boolean }>();
    showBucketItems = new EventEmitter<OrderDestination>();

    //

    get _changeDetector() {
        return this.root.changeDetector;
    }

    //
    private _tradingInstrument: TradingInstrument;

    get tradingInstrument(): TradingInstrument {
        return this._tradingInstrument;
    }

    @DetectSetterChanges()
    set tradingInstrument(val: TradingInstrument) {
        this._tradingInstrument = val;

        if (!isNullOrUndefined(val)) {
            if (isCashOnlySettledInstrument(val.underlying)) {
                this._expirationStyle = 'European';
            } else {
                this._expirationStyle = 'American';
            }
        }
    }

    //
    private _legs: OrderLeg[];

    get legs(): OrderLeg[] {
        return this._legs;
    }

    @DetectMethodChanges()
    set legs(val: OrderLeg[]) {
        this._legs = val;
        this._legsByGroup = undefined;
    }

    //
    private _rollingLegs: OrderLeg[];

    get rollingLegs(): OrderLeg[] {
        return this._rollingLegs;
    }

    @DetectMethodChanges()
    set rollingLegs(val: OrderLeg[]) {
        this._rollingLegs = val;
    }

    //
    private _orderType: OrderType;

    get orderType(): OrderType {
        return this._orderType;
    }

    @DetectSetterChanges()
    set orderType(val: OrderType) {
        this._orderType = val;
    }

    //
    private _orderDuration: OrderDuration;

    get orderDuration(): OrderDuration {
        return this._orderDuration;
    }

    @DetectSetterChanges()
    set orderDuration(val: OrderDuration) {
        this._orderDuration = val;
    }

    //
    private _limitPrice: number;

    get limitPrice(): number {
        return this._limitPrice;
    }

    @DetectSetterChanges()
    set limitPrice(val: number) {
        this._limitPrice = val;
    }

    //

    autoLimitPrice: 'Bid' | 'Ask' | 'Mid';

    //
    lastPx?: number;

    //

    get canChangeExpirationStyle(): boolean {
        let canChange = !isNullOrUndefined(this.tradingInstrument)
            && !isCashOnlySettledInstrument(this.tradingInstrument.underlying);

        if (canChange) {
            if (this.legs.some(l => l.legType === LegType.Asset)) {
                canChange = false;
            }
        }

        return canChange;
    }

    //

    get canAddAsset(): boolean {
        return !this.hasUnderlyingLeg && (this.tradingInstrument && this.tradingInstrument.kind !== TradingInstrumentKind.Index);
    }

    //

    destinations: OrderDestination[] = [];

    //
    private _selectedStrategy: OptionStrategy;

    get selectedStrategy(): OptionStrategy {
        return this._selectedStrategy;
    }

    @DetectSetterChanges()
    set selectedStrategy(val: OptionStrategy) {
        this._selectedStrategy = val;
    }

    //
    private _linkExpirations = false;

    get linkExpirations(): boolean {
        return this._linkExpirations;
    }

    @DetectSetterChanges()
    set linkExpirations(val: boolean) {
        this._linkExpirations = val;
    }

    //
    private _linkRollingExpirations = false;

    get linkRollingExpirations(): boolean {
        return this._linkRollingExpirations;
    }

    @DetectSetterChanges()
    set linkRollingExpirations(val: boolean) {
        this._linkRollingExpirations = val;
    }

    //
    private _linkExecDates = false;

    get linkExecDates(): boolean {
        return this._linkExecDates;
    }

    @DetectSetterChanges()
    set linkExecDates(val: boolean) {
        this._linkExecDates = val;
    }

    //
    private _totalBid: number;

    get totalBid(): number {
        return this._totalBid;
    }

    @DetectSetterChanges()
    set totalBid(val: number) {
        this._totalBid = val;
    }

    //
    private _totalAsk: number;

    get totalAsk(): number {
        return this._totalAsk;
    }

    @DetectSetterChanges()
    set totalAsk(val: number) {
        this._totalAsk = val;
    }

    //
    private _totalMid: number;

    get totalMid(): number {
        return this._totalMid;
    }

    @DetectSetterChanges()
    set totalMid(val: number) {
        this._totalMid = val;
    }

    //
    private _rollingTotalBid: number;

    get rollingTotalBid(): number {
        return this._rollingTotalBid;
    }

    @DetectSetterChanges()
    set rollingTotalBid(val: number) {
        this._rollingTotalBid = val;
    }

    //
    private _rollingTotalAsk: number;

    get rollingTotalAsk(): number {
        return this._rollingTotalAsk;
    }

    @DetectSetterChanges()
    set rollingTotalAsk(val: number) {
        this._rollingTotalAsk = val;
    }

    //
    private _rollingTotalMid: number;

    get rollingTotalMid(): number {
        return this._rollingTotalMid;
    }

    @DetectSetterChanges()
    set rollingTotalMid(val: number) {
        this._rollingTotalMid = val;
    }

    //
    private _expirationsList: OptionExpirationDescriptor[];

    get expirationsList(): OptionExpirationDescriptor[] {
        return this._expirationsList;
    }

    @DetectSetterChanges()
    set expirationsList(val: OptionExpirationDescriptor[]) {
        this._expirationsList = val;
    }

    //
    optionTypeList: TextValueMap<OptionType>[];

    //
    actionTypeList: TextValueMap<ActionType>[];

    //
    orderTypeList: TextValueMap<OrderType>[];

    //
    orderDurationList: TextValueMap<OrderDuration>[];


    //
    expirationStyleList: ('American' | 'European')[] = [
        'American',
        'European'
    ];

    //

    private _expirationStyle: 'American' | 'European' = 'American';
    get expirationStyle(): 'American' | 'European' {
        return this._expirationStyle;
    }

    @DetectSetterChanges()
    set expirationStyle(v: 'American' | 'European') {
        this._expirationStyle = v;
    }


    //
    private _rollingQty = 1;

    get rollingQty(): number {
        return this._rollingQty;
    }

    @DetectSetterChanges()
    set rollingQty(val: number) {
        this._rollingQty = val;
    }

    //
    private _isLoadingData: boolean;

    get isLoadingData(): boolean {
        return this._isLoadingData;
    }

    @DetectSetterChanges()
    set isLoadingData(val: boolean) {
        this._isLoadingData = val;
    }

    //
    get hasUnderlyingLeg(): boolean {
        return this.legs.findIndex(x => x.legType === LegType.Asset) >= 0;
    }

    //

    grandTotalBid: number;

    //
    grandTotalAsk: number;

    //

    grandTotalMid: number;

    //
    get isRolling(): boolean {
        return this.rollingLegs.length > 0;
    }

    //
    get limitPriceAbs(): number {
        return Math.abs(this.limitPrice);
    }

    //
    get limitPriceColor(): any {
        let color;

        if (this.limitPrice > 0) {
            color = 'green';
        } else if (this.limitPrice < 0) {
            color = 'red';
        }

        return {
            style: `color: ${color}`
        };
    }

    //
    get marketValue(): number {

        if (!this.tradingInstrument) {
            return undefined;
        }

        let multiplier = this.tradingInstrument.pointValue;

        if (this.tradingInstrument.kind !== TradingInstrumentKind.Futures) {
            multiplier *= 100;
        }

        return this.totalMid * multiplier;
    }

    //
    get marketValueAbs() {
        return Math.abs(this.marketValue);
    }

    //
    get limitValue(): number {
        if (!this.tradingInstrument) {
            return undefined;
        }

        if (this.orderType !== OrderType.Limit) {
            return undefined;
        }

        let multiplier = this.tradingInstrument.pointValue;

        if (this.tradingInstrument.kind !== TradingInstrumentKind.Futures) {
            multiplier *= 100;
        }

        return this.limitPrice * multiplier;
    }

    //
    get limitValueAbs() {
        return Math.abs(this.limitValue);
    }

    //

    getDestination(): OrderDestination {
        return this.destinations[this.destinations.length - 1];
    }

    //

    getDestinations(): OrderDestination[] {
        return this.destinations.filter(x => !x.hidden);
    }

    //

    addDestination(destination?: OrderDestination, silently?: boolean) {

        let destinationToAdd: OrderDestination;

        if (isNullOrUndefined(destination)) {
            destinationToAdd = new OrderDestination(this);
        } else {
            destinationToAdd = destination.clone();
        }

        destinationToAdd.letter = ALPHABET[this.destinations.length];

        this.destinations.push(destinationToAdd);

        destinationToAdd.referenceGridOpened.subscribe(dest => this.showBucketItems.emit(dest));

        this._changeDetector.detectChanges();

        if (silently) {
            return;
        }

        this.destinationAdded.emit(destinationToAdd);
    }

    //

    removeDestination(ix: number, silently: boolean) {

        if (ix < 0) {
            return;
        }

        this.destinations.splice(ix, 1);

        this.markDestinationWithLetters();

        this._changeDetector.detectChanges();

        if (silently) {
            return;
        }

        this.destinationRemoved.emit(ix);
    }

    //

    duplicateDestination(ix: number) {

        if (ix < 0) {
            return;
        }

        const dest = this.destinations[ix];
        this.addDestination(dest);
    }

    //

    pressDestination(index: number, state: boolean, silently: boolean) {
        this.destinations.forEach((dest, ix) => {
            if (state) {
                dest.hidden = ix !== index;
                dest.pressed = ix === index;
            } else {
                dest.hidden = false;
                dest.pressed = false;
            }
        });

        this._changeDetector.detectChanges();

        if (silently) {
            return;
        }

        this.destinationPressed.emit({ix: index, state});
    }

    //
    @DetectMethodChanges()
    setLimitPrice(price: number) {

        if (isNullOrUndefined(price)) {

            this.limitPrice = undefined;

            return;
        }

        if (this.orderType === OrderType.Market) {

            this.limitPrice = undefined;

            return;
        }

        const px = parseFloat(price.toFixed(2));
        this.limitPrice = px;
    }

    //
    getSubscribedTickersLegs(): string[] {
        if (this.legs.length === 0) {
            return [];
        }

        const tickers = this.legs.map(x => x.ticker);
        return tickers;
    }

    //
    onLimitPriceChanged(ev) {
        if (ev.event === undefined) {
            return;
        }

        this.limitPrice = ev.value;
    }

    //
    getSubscribedTickersRollingLegs(): string[] {
        if (this.rollingLegs.length === 0) {
            return [];
        }

        const tickers = this.rollingLegs.map(x => x.ticker);
        return tickers;
    }

    //

    private markDestinationWithLetters() {
        this.destinations.forEach((dest, ix) => {
            dest.letter = ALPHABET[ix];
        });
    }

    //


    @DetectMethodChanges()
    addBucket(bucketType: BucketType, bucket: any) {

        this.destinations.forEach(dest => {
            dest.addBucket(bucketType, bucket);
        });

        this.legs.forEach(leg => leg.addBucket(bucketType, bucket));

    }

    //
    @DetectMethodChanges()
    removeBucket(bucketType: BucketType, bucketId: string) {

        this.destinations.forEach(dest => {
            dest.removeBucket(bucketType, bucketId);
        });

        this.legs.forEach(leg => leg.removeBucket(bucketType, bucketId));
    }

    //
    @DetectMethodChanges()
    setTerminalForLegs(terminal: TerminalDto) {

        this.legs.forEach(leg => leg.setTerminal(terminal));

    }

    //
    onExecMillisecondsChanged(args) {
        if (!this.linkExecDates) {
            return;
        }

        this.legs.forEach(leg => leg.execMilliseconds = args.value);
    }

    //

    @DetectMethodChanges()
    onExecDateChanged(args) {
        if (!this.linkExecDates) {
            return;
        }

        this.legs.forEach(leg => leg.execDate = args.value);
    }

    //

    @DetectMethodChanges()
    reverseLegsSide() {

        if (this.legs.length === 0) {
            return;
        }

        this.legs.forEach(leg => leg.reverseSide());
    }

    //

    @DetectMethodChanges()
    reverseLegsOptionType() {
        if (this.legs.length === 0) {
            return;
        }

        this.legs.forEach(leg => leg.reverseOptionType());
    }

    //
    getOrderType() {
        return OrderType[this.orderType];
    }

    getTIF() {
        return OrderDuration[this.orderDuration];
    }

    copyDataFromOtherOrder(sourceOrder: MultiLegOrder) {

        if (!sourceOrder) {
            return;
        }

        this.destinations = sourceOrder.destinations.map(d => d.clone());

        this.destinations
            .forEach(dest => dest.referenceGridOpened
                .subscribe((dest1) => this.showBucketItems.emit(dest1)));

        this._orderType = sourceOrder.orderType;

        this._orderDuration = sourceOrder.orderDuration;
    }

    @DetectMethodChanges()
    dispose(lqc: LastQuoteCacheService) {
        const tickers = [];

        const legTickers = this.getSubscribedTickersLegs().filter(x => isTruthy(x));

        if (legTickers.length > 0) {
            tickers.push(...legTickers);
        }

        const rollingLegTickers = this.getSubscribedTickersRollingLegs();

        if (rollingLegTickers.length > 0) {
            tickers.push(...rollingLegTickers);
        }


        if (this.tradingInstrument) {
            tickers.push(this.tradingInstrument.ticker);
        }

        lqc.unsubscribeTickers(tickers);
    }

    duplicateLeg(leg: OrderLeg) {
        const clone = leg.clone();
        const ix = this.legs.indexOf(leg);
        if (ix >= 0) {
            if (ix === this.legs.length-1) {
                this.legs.push(clone);
            } else {
                this.legs.splice(ix, 0, clone);
            }
        }
        this._legsByGroup = undefined;
    }

    private _legsByGroup = undefined;

    getLegsByGroup(sort?: boolean): { groupLabel: string | undefined, legs: OrderLeg[] }[] {

        if (isNullOrUndefined(this._legsByGroup)) {

            const grps = Enumerable.from(this.legs)
                .where(x => x.legType !== LegType.Separator)
                .groupBy(x => x.groupLabel)
                .select(x => {
                    const label = x.key();
                    const legs = x.toArray();
                    if (sort) {
                        legs.sort((a, b) => {
                            const bStrike = typeof b.strike === 'string' ? parseFloat(b.strike) : b.strike;
                            const aStrike = typeof  a.strike === 'string' ? parseFloat(a.strike) : a.strike;

                            return bStrike - aStrike;
                        });
                    }
                    return {
                        groupLabel: x.key(),
                        legs: legs
                    };
                }).toArray();

            this._legsByGroup = grps;
        }

        return  this._legsByGroup;
    }

    resetLegGroups() {
        this._legsByGroup = undefined;
    }
}
