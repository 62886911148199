import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {PanelBaseComponent} from '../panels/panel-base.component';
import {MessageBusService} from '../message-bus.service';
import {SettingsStorageService} from '../settings-storage-service.service';
import {ChecklistStep} from "../adjustment-pricing-grid/popup/checklist/ChecklistStep";
import {ToastrService} from 'ngx-toastr';
import {AccessControlService} from '../access-control-service.class';
import {DetectMethodChanges, DxValueChanged, isVoid} from '../utils';
import {ItemDeletedEvent, ItemReorderedEvent} from 'devextreme/ui/list';
import {ResourceEditorService} from "./resource-editor.service";
import {ResourceButton} from "./resource-button.interface";
import {ResourceButtonType} from "./resource-button.type";
import DevExpress from "devextreme";
import ItemDeletingEvent = DevExpress.ui.dxList.ItemDeletingEvent;
import {UserSettingsService} from "../user-settings.service";

@Component({
    selector: 'ets-resource-editor',
    templateUrl: './resource-editor.component.html',
    styleUrls: ['./resource-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceEditorComponent extends PanelBaseComponent {
    constructor(
        protected readonly _changeDetector: ChangeDetectorRef,
        protected readonly _userSettingsService: UserSettingsService,
        protected readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _aclService: AccessControlService,
        private readonly _resourceEditorService: ResourceEditorService
    ) {
        super(_changeDetector, _userSettingsService, _messageBus);
    }

    buttonTypesList: ResourceButtonType[] = ['Link', 'Article'];

    get resourceButtons(): ResourceButton[] {
        return this._resourceEditorService.getResourceButtons();
    }

    selectedItemKeys: string[] = [];

    get isButtonSelected(): boolean {
        return !isVoid(this.selectedItemKeys);
    }

    // selectedButton: ResourceButton;
    get selectedButton(): ResourceButton {
        if (this.selectedItemKeys.length === 0) {
            return undefined;
        }
        return this.resourceButtons.find(x => x.id === this.selectedItemKeys[0]);
    }

    //
    get hasChanges(): boolean {
        return this.resourceButtons.some(x => x.hasChanges);
    }

    //
    @DetectMethodChanges({isAsync: true})
    async etsOnInit(): Promise<void> {
    }

    //
    etsOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }

    //
    etsAfterViewInit(): void {
        // throw new Error('Method not implemented.');
    }

    //
    @DetectMethodChanges({isAsync: true})
    async saveAllChanges() {
        await this._resourceEditorService.saveResourceButtons();
    }

    //
    @DetectMethodChanges()
    addButton() {
        const btn = this._resourceEditorService.newResourceButton();
        this.selectedItemKeys.length = 0;
        this.selectedItemKeys.push(btn.id);
    }

    //
    @DetectMethodChanges({isAsync: true})
    async onChange(event: DxValueChanged<any>): Promise<void> {

        if (isVoid(event.event)) {
            return;
        }

        const step = this.selectedButton;

        if (step) {

            step.hasChanges = true;

            if (step.isPublished) {
                await this.onButtonDraftStateChange(step, false);
            }
        }
    }

    @DetectMethodChanges({isAsync: true})
    async onButtonDraftStateChange(step: ResourceButton, state: boolean): Promise<void> {

        if (step.isPublished === state) {
            return;
        }

        if (step.hasChanges) {
            return;
        }

        if (isVoid(step)) {
            this._toastr.error('Step Not Selected!', 'Toggle Draft / Clean Copy');
            return;
        }

        this.isLoading = true;

        try {
            await this._resourceEditorService.toggleButtonDraftState(step);
        } catch (e) {
            this._toastr.error('"Toggle Draft / Clean Copy" operation completed with errors', 'Resource Editor');
        } finally {
            this.isLoading = false;
        }
    }

    //
    @DetectMethodChanges({isAsync: true})
    async onButtonsReordered(event: ItemReorderedEvent<ResourceButton>): Promise<void> {

        try {
            this.isLoading = true;
            this._resourceEditorService.onButtonsReordered(event);

        } catch (e) {
            this._toastr.error('Reordering operation completed with errors. Please reload selected steps', 'Checklist Editor');
        } finally {
            this.isLoading = false;
        }

    }

    //
    @DetectMethodChanges({isAsync: true})
    async onButtonDeleted(event: ItemDeletedEvent<ChecklistStep>) {

    }

    //
    protected getState() {
        // throw new Error('Method not implemented.');
    }

    //
    protected setState(state: any) {
        // throw new Error('Method not implemented.');
    }

    @DetectMethodChanges({isAsync: true})
    async onButtonDeleting(event: ItemDeletingEvent<ResourceButton>) {
        event.cancel = new Promise((res, rej) =>{
            this.isLoading = true;
            return this._resourceEditorService
                .removeResourceButton(event.itemData)
                .then(value => res())
                .catch(err => {
                    rej();
                    this._toastr.error('"Remove Resource Button" operation completed with errors');
                })
                .finally(() => this.isLoading = false)
        });
    }

    @DetectMethodChanges({isAsync: true})
    async onAttentionChanged(btn: ResourceButton, ev: DxValueChanged<boolean>) {
        if (isVoid(btn)) {
            return;
        }

        if (isVoid(ev.event)) {
            return;
        }

        if (btn.attention) {
            btn.lastChanged = Math.floor(Date.now()/1000);
        } else {
            btn.lastChanged = null;
        }

        btn.hasChanges = true;

        await this._resourceEditorService.saveResourceButtons();
    }
}
