import { Injectable } from '@angular/core';
import { AccessControlService } from '../access-control-service.class';
import { environment } from '../environments/environment';
import { EtsConstants } from '../ets-constants.const';

@Injectable()
export class AggregatedPositionsSecurityContextService {
  constructor(private readonly _accessControlService: AccessControlService) { 
    
  }
  
  get requestOrderStatus(): boolean {
      if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
        return false;
      }   
      if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
        return this._isAvailable('2bec2293-8122-495f-b544-098828262df3');
      }
      return false;
  }
  
  get cancelManualOrder(): boolean {
    if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      return false;
    }   
    if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
      return this._isAvailable('36e7a264-e853-4f0f-b9e6-e150ce827466');
    }
    return false;  
  }

  get closeAggregatedPosition(): boolean {
    if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      return false;
    }   
    if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
      return this._isAvailable('d50e784e-8d67-4954-9012-e4c644f0b07a');
    }
    return false;  
  }

  get showPositions(): boolean {
    if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      return this._isAvailable('5d88109e-0a62-4afe-9907-9932608f1a33');
    }   
    if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
      return this._isAvailable('4462b69f-b0b9-4ae8-a16b-2295eeeb84ca');
    }
    return false;  
  }

  get showOrders(): boolean {
    if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
      return this._isAvailable('6312f074-edee-43ed-be1a-ac0b91b8b620');
    }   
    if (environment.runtimeAppId === EtsConstants.companyServices.etsWebTraderApplicationId) {
      return this._isAvailable('3b42050a-2763-471d-81fc-7fdd62ab72cb');
    }
    return false; 
  }

  private _isAvailable(secureElementId: string) {
    return this._accessControlService.isSecureElementAvailable(secureElementId);
  }
}
