import { Component, OnInit, Input, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { getStrategyIssuesGridModel } from './strategy-issues-grid-model';
import { ToastrService } from 'ngx-toastr';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { GetStrategyIssuesWeb } from 'projects/shared-components/shell-communication/operations/strategies/get-strategy-issues-web.class';
import { StrategyIssueDto } from 'projects/shared-components/shell-communication/dtos/strategy-issue-dto.class';
import { AckStrategyIssue } from 'projects/shared-components/shell-communication/operations/strategies/ack-strategy-issue.class';
import { StrategyIssuesSecurityContextService } from './strategy-issues-security-context.service';
import { MessageBusService } from '../message-bus.service';
import { Subject } from 'rxjs';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { filter, takeUntil, map, throttleTime } from 'rxjs/operators';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { ShellConnectionStatusChangedUIMessage } from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import { StrategyRemovedUIMessage } from '../ui-messages/strategy-removed-ui-message.interface';
import { StrategiesTradingDataBackendService } from '../strategies/strategies-trading-data-backend-service.interface';
import {  DetectSetterChanges } from '../utils';
import { StrategyLogMessageDto } from '../shell-communication/dtos/strategy-log-message-dto.class';
import { IssueAckedUIMessage } from '../ui-messages/issue-acked-ui-message.interface';
import { BucketItemHighlighted } from '../ui-messages/ui-messages';
import { PortfolioItemType } from '../portfolios/portfolios.model';


@Component({
   selector: 'ets-strategy-issues',
   templateUrl: 'strategy-issues.component.html',
   styleUrls: ['strategy-issues.component.scss'],
   providers: [StrategyIssuesSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyIssuesComponent implements OnInit, OnDestroy {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService,

      private _securityContext: StrategyIssuesSecurityContextService,
      private readonly _backendClient: StrategiesTradingDataBackendService,
      private readonly _toastr: ToastrService,
   ) {
      
   }
   
   private _issuesIX: StrategyIssueDto[] = [];
   private _grid: GridReadyEvent;
   private _unsubscriber: Subject<any> = new Subject<any>();
   private _stateChanged = new EventEmitter();
   private _contextStrategyId: string;
   private _pendingDataContainer: StrategyLogMessageDto[];


   @Input() mode: 'popup' | 'panel';
   @Input() layoutTabId: string;
   @Input() panelId: string;
   @Input() workspaceId: string;
   @Input() isActive: boolean;

   strategyIssuesGridModel: GridOptions;
   selectedIssue: StrategyIssueDto;
   
   private _isDetailsWindowVisible = false;
   get isDetailsWindowVisible(): boolean { return this._isDetailsWindowVisible; }
   
   @DetectSetterChanges()
   set isDetailsWindowVisible(val: boolean) { 
      this._isDetailsWindowVisible = val;
    }
   
   
   private _operationInProgress = false;
   get operationInProgress(): boolean { return this._operationInProgress; }
   
   @DetectSetterChanges()
   set operationInProgress(v: boolean) { this._operationInProgress = v; }
   
   
   get securityContext(): StrategyIssuesSecurityContextService { return this._securityContext; }

   
   async ngOnInit(): Promise<void> {
      this.strategyIssuesGridModel = getStrategyIssuesGridModel.bind(this)();
   }

   
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   
   async show(strategy: StrategyModel): Promise<void> {
      this._contextStrategyId = strategy.strategyId;
      this._issuesIX.length = 0;
      if (!this._grid) {
         // if grid is not set, that this is first time initialization
         // we defer loading of issues to after grid initialization
         // otherwise - we can safely load issues
         return;
      }
      await this._loadStrategyIssues();
   }

   
   
   hide(): void {
      this._contextStrategyId = null;
      this._issuesIX.length = 0;
      this._grid.api.setRowData([]);
   }

   
   async onGridReady(args: GridReadyEvent): Promise<void> {
      this._grid = args;

      if (this.mode === 'panel') {
         this._subscribeToMessages();
         await this._loadLastHighlightedStrategy();
      }

      this._messageBus.of<IssueAckedUIMessage>('IssueAckedUIMessage')
         .pipe(
            filter(x => this._contextStrategyId === x.payload.strategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this._onIssueAckedUIMessage(x.payload));
   }

   
   async loadMoreIssues(batchSize: number): Promise<void> {

      if (!this._contextStrategyId) {
         console.warn('Strategy not set');
         return;
      }

      let historyStart = 0;

      if (this._issuesIX.length > 0) {
         const lastIssue = this._issuesIX[this._issuesIX.length - 1];
         if (lastIssue) {
            historyStart = lastIssue.seqNum;
         }
      }

      const qry = new GetStrategyIssuesWeb(
         this._contextStrategyId,
         historyStart,
         batchSize
      );


      this._grid.api.showLoadingOverlay();
      
      try {
         
         this._pendingDataContainer = [];
         
         const moreIssues = await this._backendClient.getIssues(qry);
         
         if (moreIssues.length > 0 || this._pendingDataContainer.length > 0) {

            const lastSeqNum = moreIssues.length > 0 ? moreIssues[moreIssues.length - 1].seqNum : -1;

            const filteredPendings = this._pendingDataContainer
               .filter(t => t.seqNum > lastSeqNum)
               .filter(x => x.category > 1)
               .map(x => this._mapMessageToIssue(x));

            const totalMessages = moreIssues.concat(filteredPendings);
            this._issuesIX.push(...totalMessages);
            this._grid.api.setRowData(this._issuesIX);

         } else {
            
            this._toastr.info('All issues loaded');

         }
      } catch (e) {
         
         this._toastr.error('"Load Strategy Issues" operation completed with errors');

      } finally {

         this._pendingDataContainer = null;
         this._grid.api.hideOverlay();

      }
   }

   
   async ackSelectedIssues(): Promise<void> {
      const selectedRows: StrategyIssueDto[] = this._grid.api.getSelectedRows();

      if (selectedRows.length === 0) {
         return;
      }

      await this._ackIssues(selectedRows);
   }

   
   async ackAllIssues(): Promise<void> {
      await this._ackIssues(this._issuesIX.slice());
   }

   
   onStateChanged(): void {
      this._stateChanged.emit();
   }

   
   private _onIssueAckedUIMessage(msg: IssueAckedUIMessage): void {
      if (this._contextStrategyId !== msg.strategyId) {
         return;
      }
      this._issuesIX = this._issuesIX.filter(x => msg.issues.indexOf(x.issueId) === -1);
      this._grid.api.setRowData(this._issuesIX);
   }

   
   private async _loadStrategyIssues(): Promise<void> {
      if (!this._contextStrategyId) {
         return;
      }

      if (!this._grid) {
         return;
      }

      this._issuesIX.length = 0;
      this._grid.api.setRowData([]);
      await this.loadMoreIssues(100);
   }

   
   private async _ackIssues(selectedRows: StrategyIssueDto[]): Promise<void> {
      
      this.operationInProgress = true;

      const cmd = new AckStrategyIssue(
         this._contextStrategyId,
         selectedRows.map(x => x.issueId)
      );

      try {
         
         await this._backendClient.ackIssues(cmd);

      } catch (e) {

         this._toastr.error('"Acknowledge Issues" operation completed with errors');

      } finally {
         
         this.operationInProgress = false;

      }
   }

   
   private _subscribeToMessages() {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
      this._unsubscriber = new Subject<any>();
      this._messageBus
         .of<StrategyHighlightedUIMessage>('StrategyHighlightedUIMessage')
         .pipe(
            filter(msg => msg.scopeId === this.layoutTabId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onStrategyHighlightedMessage(message.payload));

      this._messageBus
         .of<any>('StrategyLogMessageDto')
         .pipe(
            filter(x => this._contextStrategyId in x.payload),
            map(x => x.payload[this._contextStrategyId]),
            filter(x => x.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => setTimeout(() => this._onStrategyLogMessage(message), 0));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            filter(message => !message.payload.hasErrors && !!this._contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this._onClearTradingDataMessage(message.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(message => message.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe(data => this._onShellConnectionStatusChanged(data.payload));

      this._stateChanged
         .pipe(
            throttleTime(250),
            takeUntil(this._unsubscriber)
         )
         .subscribe(() => this._saveState());


      this._messageBus
         .of<StrategyRemovedUIMessage>('StrategyRemovedUIMessage')
         .pipe(
            filter(msg => msg.payload.strategyId === this._contextStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onStrategyRemovedUIMessage(msg.payload) );


      this._messageBus.of<BucketItemHighlighted>('BucketItemHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId),
            filter(msg => msg.payload.item.itemType === PortfolioItemType.Strategy)
         )
         .subscribe(msg => this.onBucketItemHighlighted(msg.payload));
   }

   //

   private async onBucketItemHighlighted(msg: BucketItemHighlighted): Promise<void> {
      if (msg.item.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const strategyId = msg.item.portfolioItemId;

      const strategyHighlightedMessage: StrategyHighlightedUIMessage = { strategyId };
      
      await this._onStrategyHighlightedMessage(strategyHighlightedMessage);
   }

   //
   
   private async _onStrategyHighlightedMessage(message: StrategyHighlightedUIMessage): Promise<void> {
      
      if (message.strategyId === this._contextStrategyId) {
         return;
      }
      
      this._contextStrategyId = message.strategyId;
      
      await this._loadStrategyIssues();
   }

   
   private _onStrategyLogMessage(dtos: StrategyLogMessageDto[]): void {
      const pendingDataContainer = this._pendingDataContainer;
      if (pendingDataContainer) {
         pendingDataContainer.push(...dtos);
      } else {
         const issues = dtos.filter(x => x.category > 1).map(x => this._mapMessageToIssue(x));
         this._issuesIX.push(...issues);
         this._grid.api.applyTransactionAsync({ add: issues });
      }
   }

   
   private _onClearTradingDataMessage(message: ClearTradingDataUIMessage): void {
      if (message.hasErrors) {
         return;
      }

      if (message.strategies.includes(this._contextStrategyId) || message.refreshDb) {
         this._issuesIX.length = 0;
         this._grid.api.setRowData(this._issuesIX);
      }
   }

   
   private async _onShellConnectionStatusChanged(data: ShellConnectionStatusChangedUIMessage): Promise<void> {
      if (this._contextStrategyId) {
         const strategyId = this._contextStrategyId;
         this._contextStrategyId = null;
         await this._onStrategyHighlightedMessage({ strategyId });
      }
   }

   
   private _saveState(): void {

   }

   
   private _restoreState(): void {
      // if (!this._grid) {
      //    return;
      // }

      // const key = getPanelStateKey(this);
      // const item = this._layoutService.getItem(key);
      // if (!item) {
      //    return;
      // }
      // let state: PanelState;
      // try {
      //    state = JSON.parse(item);
      // } catch (e) {
      //    this._toastr.error('"Strategy Messages" panel was restored with errors');
      //    return;
      // }
      // const isOK = this._grid.columnApi.setColumnState(state.columnsState);
      // if (!isOK) {
      //    this._toastr.error('"Strategy Messages" panel was restored with errors');
      // }
   }

   
   private _onStrategyRemovedUIMessage(msg: StrategyRemovedUIMessage) {
      this._issuesIX.length = 0;
      this._grid.api.setRowData([]);
      this._contextStrategyId = null;
   }

   
   private _mapMessageToIssue(message: StrategyLogMessageDto): StrategyIssueDto {
      const issue = {
         issueId: message.messageId,
         strategyId: message.strategyId,
         category: message.category,
         messageText: message.messageText,
         timestamp: message.timestamp,
         seqNum: message.seqNum,
         sessionId: message.sessionId
      } as StrategyIssueDto;

      return issue;
   }

   
   private async _loadLastHighlightedStrategy(): Promise<void> {
      const lastMessage = this._messageBus.getLastMessage<StrategyHighlightedUIMessage>(
         'StrategyHighlightedUIMessage',
         this.layoutTabId
      );
      if (lastMessage) {
         await this._onStrategyHighlightedMessage(lastMessage.payload);
      }
   }
}
