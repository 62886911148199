<div class="sidebox">
   
   <ng-container *ngIf="this.leg; else noLeg">
      
      <div class="leg">
      
         <div class="label">
            Position: 
         </div>
         
         <div class="data">
            <div class="qty">
               {{this.leg.qty}}
            </div>
            <div class="asset">
               {{this.leg.asset}}
            </div>
            <div class="exp">
               {{this.leg.expiration}}
            </div>
            <div class="dte">
               {{this.leg.daysToExpiration}}
            </div>
         </div>
      </div>
   </ng-container>
   
   <ng-template #noLeg>
      <div class="na">
         <p>N/A</p>
      </div>
   </ng-template>
    
</div>
