import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'etsMarketSidePipe'
})
export class MarketSidePipe implements PipeTransform {
    transform(value: any, ...args: any[]) {

        if (typeof value === 'object') {
            return undefined;
        }

        let val: number;

        if (typeof value === 'string') {
            val = parseInt(value);
        } else {
            val = value;
        }

        if (val == 0) {
            return 'Flat';
        }

        if (val > 0) {
            return 'Long';
        }

        if (val < 0) {
            return 'Short';
        }

        return undefined;
    }

}