import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {DetectMethodChanges, isVoid} from "../../../../utils";
import {DxPopoverComponent} from "devextreme-angular";
import {formatDate} from "@angular/common";
import {DateTime, Duration, DurationObject} from "luxon";
import {ToastrService} from "ngx-toastr";
import {ApgTrackingDialogComponent} from "../apg-tracking-dialog.component";
import {MessageBusService} from "../../../../message-bus.service";
import {DateTimePickerComponent} from "../../../../datetime-picker/datetime-picker.component";

type PunchBoxValue = '15m' | '30m' | '45m' | '1h' | '2h' | '3h' | '4h' | '5h' | '6h' | 'For This Session' | 'Pause Indefinitely';

@Component({
    selector: 'ets-pause-notifications',
    templateUrl: 'pause-notifications.component.html',
    styleUrls: ['pause-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PauseNotificationsComponent  implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService,
        private readonly _messageBus: MessageBusService,
    ) {
    }

    position = 'right';

    get isPaused() : boolean {
        return !isVoid(this._specificDateAndTime) && !this.isExpired();
    }

    @ViewChild(DateTimePickerComponent, {static: false})
    dateTimePicker: DateTimePickerComponent;

    @ViewChild(DxPopoverComponent, {static: false})
    popover: DxPopoverComponent;

    get pausedUntilText() : string {
        if (isVoid(this.specificDateAndTime)) {
            return null;
        }

        if (!this.isPaused) {
            return null;
        }

        const isIndef = this.isIndefinitely;
        
        if (isIndef) {
            return 'Paused Indefinitely';
        }

        const dt = formatDate(this.specificDateAndTime, 'EEE dd-MMM-yy hh:mm aaa', 'en-US');

        return `Resumes at ${dt}`;
    }

    private _specificDateAndTime : string;
    get specificDateAndTime(): string {
        return this._specificDateAndTime;
    }

    get isIndefinitely(): boolean {
        const duration = DateTime.fromISO(this.specificDateAndTime).diffNow('minutes');
        const isIndef = duration.minutes >  Math.pow(10,6);
        return isIndef;
    }

    @Input()
    set specificDateAndTime(value: string) {
        this._specificDateAndTime = value;
        if (this.isExpired()) {
            this._specificDateAndTime = null;
        }
        if (isVoid(this._specificDateAndTime)) {
            this.dateTimePicker?.onClearClicked();
        }
    }

    @Input() ctx : ApgTrackingDialogComponent;

    @Output()
    specificDateAndTimeChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    closed$ = new EventEmitter<string>();

    get buttonText() : string {
        return this.isPaused ? 'Resume Now' : 'Pause';
    }

    ngOnInit() {
        this._messageBus.of<any>('ApgTrackingDialog.TimezoneChanged')
            .subscribe(msg => this.onTimezoneChanged(msg.payload));
    }

    @DetectMethodChanges()
    toggleDialog() {

        if (this.popover.visible) {
            this.onHidden();
            return;
        }

        if (this.isPaused) {
            this.specificDateAndTime = null;
            this.onHidden();
            return;
        }

        this.popover.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.popover.visible = false;
        const time = this.specificDateAndTime;
        setTimeout(() => {
            this.specificDateAndTimeChange.emit(time);
            this.closed$.emit(time);
        });
    }

    @DetectMethodChanges()
    onApplyClicked(value?: PunchBoxValue) {
        if (!isVoid(value)) {
            let period: DurationObject = {};
            switch (value) {
                case "15m":
                    period = {minutes: 15};
                    break;
                case "30m":
                    period = {minutes: 30};
                    break;
                case "45m":
                    period = {minutes: 45};
                    break;
                case "1h":
                    period = {hours: 1};
                    break;
                case "2h":
                    period = {hours: 2};
                    break;
                case "3h":
                    period = {hours: 3};
                    break;
                case "4h":
                    period = {hours: 4};
                    break;
                case "5h":
                    period = {hours: 5};
                    break;
                case "6h":
                    period = {hours: 6};
                    break;
                case "For This Session":
                    const endOfSession = DateTime.local().setZone('America/New_York')
                        .set({
                            hour: 16,
                            minute: 0,
                            second: 0
                        });
                    const diff = endOfSession.diffNow('minutes');
                    if (diff.minutes < 0) {
                        this._toastr.error('Trading Session is Already Over');
                        return;
                    }
                    period = {minutes: diff.minutes};
                    break;
                case "Pause Indefinitely":
                    period = {minutes: Math.pow(10,7)};
                    break;
            }

            const tz = this.ctx?.timezone || 'UTC';
            const dt = DateTime.local()
                .setZone(tz)
                .plus(period)
                .toISO({suppressMilliseconds: true, includeOffset: false});

            this.specificDateAndTime = dt;
        }
        else {
            if (isVoid(this.specificDateAndTime)) {
                this._toastr.warning('Please provide valid date & time, or click one of the punch buttons');
                return;
            }
        }
        this.popover.visible = false;
    }

    private isExpired() : boolean {

        if (isVoid(this.specificDateAndTime)) {
            return false;
        }

        const tz = this.ctx?.timezone || 'UTC';

        const isoTime = DateTime.utc().setZone(tz).toISO({suppressMilliseconds: true, includeOffset: false});

        return this.specificDateAndTime <= isoTime;
    }

    @DetectMethodChanges()
    private onTimezoneChanged(payload: { previous: string, current: string }) {

        if (isVoid(this.specificDateAndTime)) {
            return;
        }

        const previous = payload.previous || 'UTC';
        const current = payload.current || 'UTC';

        const newZoneDt = DateTime.fromISO(this.specificDateAndTime, {zone: previous})
            .setZone(current);

        const diffNow = newZoneDt.diffNow('second').seconds;

        if (diffNow < 0) {
            this.specificDateAndTime = null;
            return;
        }

        this.specificDateAndTime = newZoneDt.toISO({suppressMilliseconds: true, includeOffset: false})
    }

    onClear() {
        this.specificDateAndTime = null;
        this.onHidden();
    }
}