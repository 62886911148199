<div class="header">
   
   <div class="bucket-selector">

      <ets-automation-cp-header-bucket-selector>
      </ets-automation-cp-header-bucket-selector>
      
   </div>

   <div class="pnls">

      <ets-automation-cp-header-combo-pnls>
      </ets-automation-cp-header-combo-pnls>

   </div>

   <div class="qty-guard" *ngIf="false">

      <ets-automation-cp-header-qty-guard
         (submit)="this.onSubmit()">
      </ets-automation-cp-header-qty-guard>

   </div>

</div>