import { NgModule } from '@angular/core';
import { TradingSystemsViewComponent } from './trading-systems-view.component';
import { AgGridModule } from 'ag-grid-angular';
import { StrategyIssuesCellRendererComponent } from './strategy-issues-cell-renderer.component';
import { EtsSystemDetailsModule } from '../system-details/system-details.module';
import { EtsEditStrategyDialogModule } from '../edit-strategy-dialog/edit-strategy-dialog.module';
import { StrategyRulesCellRendererComponent } from './strategy-commander-rules-cell-renderer.component';
import { CommonModule } from '@angular/common';
import { SimpleHeaderGroupRowInnerRenderer } from '../unspecific/ag-grid-contrib/group-row-inner-renderer.component';
import { AgGridContribModule } from '../unspecific/ag-grid-contrib/ag-grid-contrib.module';
import { StrategyIssuesModule } from '../strategy-issues/strategy-issues.module';
import { SyncOverrideDialogModule } from '../sync-override-dialog/sync-override-dialog.module';
import { CustomExitsComponent } from './custom-exits/custom-exits.component';
import { PortfoliosModule } from '../portfolios/portfolios.module';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from '../devextreme.module';
import { AdjustmentStrategiesViewComponent } from './adjustment-strategies-view.component';
import { AdjustmentStrategyDialogModule } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import { InnerStrategyGroupRowRendererComponent } from './inner-strategy-group-row-renderer';
import { StrategyTriggersCellRendererComponent } from './strategy-triggers-cell-renderer.component';

@NgModule({
   imports: [
      CommonModule,
      EtsSystemDetailsModule,
      StrategyIssuesModule,
      AgGridContribModule,
      SyncOverrideDialogModule,
      AgGridModule.withComponents([
         StrategyIssuesCellRendererComponent,
         StrategyRulesCellRendererComponent,
         SimpleHeaderGroupRowInnerRenderer,
         InnerStrategyGroupRowRendererComponent,
         StrategyTriggersCellRendererComponent
      ]),
      EtsEditStrategyDialogModule,
      AngularSplitModule,
      PortfoliosModule,
      DevExtremeModule,
      AdjustmentStrategyDialogModule
   ],
   exports: [TradingSystemsViewComponent, AdjustmentStrategiesViewComponent],
   declarations: [
      TradingSystemsViewComponent,
      StrategyIssuesCellRendererComponent,
      StrategyRulesCellRendererComponent,
      CustomExitsComponent,
      AdjustmentStrategiesViewComponent,
      InnerStrategyGroupRowRendererComponent,
      StrategyTriggersCellRendererComponent
   ],
   providers: []
})
export class TradingSystemsViewModule { }
