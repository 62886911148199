<dx-popup 
      [width]="this.dialogConfig?.editMode === 'lean' ? 957 : 1400" 
      [height]="this.dialogConfig?.editMode === 'lean' ? 700 : 770" 
      [showTitle]="true" 
      [title]="title" 
      [closeOnOutsideClick]="false"
      [resizeEnabled]="false" 
      [dragEnabled]="true" 
      [visible]="isVisible" 
      (onHidden)="close()">

   <div *dxTemplate="let data of 'content'">

      <div class="container" [ngClass]="{'inner': dialogConfig.editMode === 'deferred'}">

         <div class="content">
            
            <div class="upper">

               <div class="section leftcol" [class.lean]="this.dialogConfig?.editMode === 'lean'">
                  
                  <div class="family-selector">
                     <fieldset>
                        <legend>Select Family</legend>
                        <dx-select-box [items]="this.availableAlgoFamilies" [value]="this.selectedAlgoFamily"
                           (valueChange)="this.selectedAlgoFamily = $event" displayExpr="displayName"
                           [disabled]="dialogConfig?.isUpdate"></dx-select-box>
                     </fieldset>
                  </div>

                  <div class="family-members">
                     <dx-list [items]="this.selectedAlgoFamily?.algoes" selectionMode="single" [selectedItems]="this.selectedAlgo"
                        (selectedItemsChange)="this.selectedAlgo = $event" [disabled]="dialogConfig?.isUpdate">
                        <div *dxTemplate="let data of 'item'">
                           <div>{{data.displayName}}</div>
                        </div>
                     </dx-list>
                  </div>

                  <div class="target-bucket">

                     <fieldset>
                        <legend>Target Bucket</legend>
                        
                        <dx-select-box [items]="this.availablePortfolios" 
                           [value]="this.selectedPortfolio"
                           (valueChange)="this.onPortfolioChange($event)"
                           displayExpr="portfolioName" [searchEnabled]="true" [showClearButton]="true"
                           [disabled]="!parametersControl || !canChangeTerminal || isDeltaHedge" placeholder="Select Portfolio">
                        </dx-select-box>
                        
                        <dx-select-box [items]="this.availableCombos" 
                           [value]="this.selectedCombo" 
                           (valueChange)="this.onComboChange($event)"
                           displayExpr="comboName" [searchEnabled]="true" [showClearButton]="true"
                           [disabled]="!parametersControl || !canChangeTerminal || isDeltaHedge" placeholder="Select Combo">
                        </dx-select-box>

                        <dx-select-box [items]="this.availableComboGroups" 
                           [value]="this.selectedComboGroup" 
                           (valueChange)="this.onComboGroupChange($event)"
                           displayExpr="comboGroupName" [searchEnabled]="true" [showClearButton]="true"
                           [disabled]="!parametersControl || !canChangeTerminal || isDeltaHedge" placeholder="Select ComboGroup">
                        </dx-select-box>
                     </fieldset>

                  </div>

                  <div class="account">
                     <fieldset>
                        <legend>Account</legend>
                        <dx-select-box [items]="this.accountItems" [(value)]="this.accountId"
                                       valueExpr="accountId" displayExpr="description" [searchEnabled]="true" [showClearButton]="true"
                           placeholder="Select Account...">
                        </dx-select-box>
                     </fieldset>
                  </div>

                  <div class="timestampszone">
                     <fieldset>
                        <legend>Timestamps Zone</legend>
                        <dx-select-box [items]="parametersControl?.timestampsZoneItems"
                           [value]="parametersControl?.timestampsZone"
                           (valueChange)="this.onTimestampsZoneChanged($event)"
                           [searchEnabled]="true" [showClearButton]="true"
                        ></dx-select-box>
                     </fieldset>
                  </div>

                  <div class="units" *ngIf="dialogConfig.editMode === 'immediate'">
                     <fieldset>
                        <legend>Allocate Units</legend>
                        <dx-number-box [disabled]="!isUnitsEditorAvailable || isDeltaHedge" [showSpinButtons]="true"
                           [showClearButton]="true" [(value)]="allocatedUnits" [step]="1" [min]="0">
                        </dx-number-box>
                     </fieldset>
                  </div>

                  <div class="mp-violations">
                     <fieldset>
                        <legend>Max. Position Violations</legend>
                        <div class="mp-violation-num">
                           <div class="label">
                              # of Violations:
                           </div>
                           <div class="input">
                              <dx-number-box [showSpinButtons]="true"
                                 [value]="parametersControl?.maxPositionViolationsAllowed"
                                 (valueChange)="this.onNumberOfViolationsChanged($event)"
                                 [disabled]="isDeltaHedge"
                                 [min]="1"></dx-number-box>
                           </div>
                        </div>
                        
                        <div class="mp-violation-margin">
                           <div class="label">
                              Violation Margin:
                           </div>
                           <div class="input">
                              <dx-number-box [showSpinButtons]="true"
                                 [value]="parametersControl?.maxPositionViolationMargin"
                                 (valueChange)="this.onViolationMarginChanged($event)"
                                 [disabled]="isDeltaHedge"
                                 [min]="0"></dx-number-box>
                           </div>
                        </div>

                     </fieldset>
                  </div>

               </div>

               <div class="section centercol" [class.lean]="this.dialogConfig?.editMode === 'lean'">
                  
                  <div class="metadata" [ngClass]="{'ets-disabled': !parametersControl}">
                     
                     <div class="name section first" tabindex="-1">
                        <p>Name</p>
                        <dx-text-box placeholder="Strategy Name" [disabled]="!parametersControl"
                           [value]="parametersControl?.displayName"
                           (valueChange)="this.onStrategyNameChanged($event)">
                        </dx-text-box>
                     </div>
                                          
                     <div class="terminal section last" tabindex="-1" *ngIf=" dialogConfig.editMode === 'immediate' ">
                        <p>Terminal</p>
                        <dx-select-box [items]="availableTerminals" displayExpr="displayName" 
                           [value]="selectedTerminal"
                           (valueChange)="this.onTerminalChanged($event)"
                           [disabled]="!parametersControl || !canChangeTerminal || dialogConfig?.isBoltOn" placeholder="Pick a terminal">
                        </dx-select-box>
                     </div>
                  </div>

                  <div class="schedule" [ngClass]="{'ets-disabled': !parametersControl}"
                     *ngIf="!isInnerStrategyForNetBetting">
                     <fieldset>
                        <legend>Strategy Schedule</legend>
                        
                        <div class="time-area">
                           <div class="country">
                              <dx-select-box 
                                 placeholder="Select Country" 
                                 displayExpr="displayName"
                                 [disabled]="!parametersControl"
                                 [items]="this.timezoneFamilies"
                                 [grouped]="true"
                                 [value]="parametersControl?.scheduleParameters?.country" valueExpr="displayName"
                                 (valueChange)="this.onStrategyScheduleCountryChanged($event)"
                                 [showClearButton]="true" #tzFamilies>
                              </dx-select-box>
                           </div>
                           <div class="timezone">
                              <dx-select-box placeholder="Select Timezone" [disabled]="!parametersControl"
                                 [items]="this.timezoneItems"
                                 [value]="parametersControl?.scheduleParameters?.timeZone" displayExpr="displayName"
                                 valueExpr="nodaTimeZoneId"
                                 (valueChange)="this.onStrategyScheduleTimezoneChanged($event)"
                                 [showClearButton]="true">
                              </dx-select-box>
                           </div>
                        </div>

                        <div class="sse">
                           <div class="start sse-item first">
                              <ets-datetime-picker mode="time" 
                                                   placholder="Start Time"
                                                   [showTimezone]="false" 
                                                   [value]="parametersControl?.scheduleParameters?.startTime"
                                                   (valueChange)="this.onStrategyScheduleStartTimeChanged($event)">
                              </ets-datetime-picker>
                           </div>
                           
                           <div class="stop sse-item">
                              <ets-datetime-picker mode="time" 
                                                   placholder="Stop Time"
                                                   [showTimezone]="false" 
                                                   [value]="parametersControl?.scheduleParameters?.stopTime"
                                                   (valueChange)="this.onStrategyScheduleStopTimeChanged($event)">
                              </ets-datetime-picker>
                           </div>

                           <div class="exit sse-item last">
                              <ets-datetime-picker mode="time" 
                                                   placholder="Exit Time"
                                                   [showTimezone]="false" 
                                                   [value]="parametersControl?.scheduleParameters?.exitTime"
                                                   (valueChange)="this.onStrategyScheduleExitTimeChanged($event)">
                              </ets-datetime-picker>
                           </div>
                        </div>
                        
                        <div class="misc">
                           <dx-check-box text="Autostart"
                              [disabled]="!parametersControl || !parametersControl?.scheduleParameters?.timeZone"
                              tabindex="-1" [value]="parametersControl?.scheduleParameters?.isAutostart || false"
                              (valueChange)="this.onStrategyScheduleAutoStartChanged($event)">
                           </dx-check-box>
                           <dx-check-box text="Session Strategy" class="session-strategy"
                              [disabled]="!parametersControl || !parametersControl?.scheduleParameters?.timeZone"
                              tabindex="-1" [value]="parametersControl?.isSession || false"
                              (valueChange)="this.onIsSessionStrategyChanged($event)">
                           </dx-check-box>

                           <span class="advanced-text" *ngIf="parametersControl?.hasAdvancedSchedule()">...
                              click [Advanced] to see rest of schedule settings ...</span>
                           <dx-button text="Advanced"
                              [disabled]="!parametersControl || !parametersControl?.scheduleParameters?.timeZone"
                              tabindex="-1" (onClick)="showAdvancedSchedule()"></dx-button>
                        </div>

                     </fieldset>
                  </div>

                  <div class="parameters">
                     <fieldset class="parameters-wrapper">
                        <legend>Parameters</legend>
                        
                        <ets-limit-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.algoId === '63b9803e-dc5f-407a-a089-ea846a221be4' ">
                        </ets-limit-parameters-view>
                        
                        <ets-bullbear-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.algoId === '52e421a5-0cdd-4c84-8430-b282b55ef9a8' ">
                        </ets-bullbear-parameters-view>
                        
                        <ets-fusion-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.algoId === 'bc529650-7ff8-47c7-8713-55ef4ce8055c'">
                        </ets-fusion-parameters-view>
                        
                        <ets-flagship-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.isFlagshipFamily()">
                        </ets-flagship-parameters-view>
                        
                        <ets-trendlimit-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.algoId === '390fcd11-e0e4-4662-ae1c-123bf420e805'">
                        </ets-trendlimit-parameters-view>
                        
                        <ets-tradetheline-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.algoId === '52411e71-e0ec-45eb-8b78-a580da6bcaad'">
                        </ets-tradetheline-parameters-view>
                        
                        <ets-dynamic-hedge-parameters-view [control]="parametersControl"
                           *ngIf="parametersControl?.algoId === 'ad38a4cc-7212-4446-9a2c-19a57f7fa973'">
                        </ets-dynamic-hedge-parameters-view>
                        
                        <ets-freeze-hedge-parameters-view   [control]="parametersControl"
                                                            
                           *ngIf="parametersControl?.algoId === '8094d698-0cb0-4692-95f3-40ded27750b4'">
                        </ets-freeze-hedge-parameters-view>
                        
                        <ets-hedge-parameters-view   [control]="parametersControl"
                                                            
                           *ngIf="parametersControl?.algoId === 'eb0234e9-280b-4aa9-9716-e820a8bf05a3'">
                        </ets-hedge-parameters-view>
                        
                        <ets-remote-algo-parameters-view *ngIf="parametersControl?.algoId === '21ffdad7-b234-420d-a6c3-5d8112006396'"
                                                         [control]="parametersControl"
                                                         >
                        </ets-remote-algo-parameters-view>
                     </fieldset>
                  </div>

               </div>

               <div class="section rightcol" [class.lean]="this.dialogConfig?.editMode === 'lean'">

                  <div class="triggers">
                     
                     <fieldset>
                        <legend>Triggers</legend>
                        <div class="container">
                           
                           <!-- Filter -->
                           <div class="trigger">
                              <div class="label">System Filters</div>
                              <div class="input">
                                 <dx-drop-down-box fieldTemplate="field" 
                                                   [disabled]="!this.isFilterTriggerAvailable"
                                                   (onClosed)=" this.filterTriggersSearchString = '' ">
                                    
                                    <div *dxTemplate="let data of 'field'">
                                       <dx-text-box [value]="this.getSelectedValueForTriggers('filter')"></dx-text-box>
                                    </div>

                                    <div *dxTemplate="let data of 'content'">
                                       
                                       <dx-text-box placeholder="Type to search..." 
                                                   valueChangeEvent="keyup"
                                                   [(value)]="this.filterTriggersSearchString">
                                       </dx-text-box>

                                       <hr>

                                       <div  class="trigger-list-item" 
                                             [class.disapproved-trigger]="!t.data.isApproved" 
                                             *ngFor="let t of this.filterTriggers">
                                          <span>
                                             <dx-check-box  [(value)]="t.isSelected" 
                                                            (onValueChanged)="this.onSelectedFilterTriggerChanged($event, t)">
                                             </dx-check-box>
                                          </span>
                                          <span>{{t.data.displayName}}@{{t.data.vendorName}}</span>
                                       </div>
                                    </div>
                                 </dx-drop-down-box>
                              </div>
                           </div>

                           <!-- Condition -->
                           <div class="trigger">
                              <div class="label">System Conditions</div>
                              <div class="input">
                                 <dx-drop-down-box fieldTemplate="field" 
                                                   [disabled]="!this.isConditionTriggerAvailable"
                                                   (onClosed)=" this.conditionTriggersSearchString = '' ">
                                    
                                    <div *dxTemplate="let data of 'field'">
                                       <dx-text-box [value]="this.getSelectedValueForTriggers('condition')"></dx-text-box>
                                    </div>

                                    <div *dxTemplate="let data of 'content'">
                                       
                                       <dx-text-box 
                                          placeholder="Type to search..." 
                                          valueChangeEvent="keyup"
                                          [(value)]="this.conditionTriggersSearchString">
                                       </dx-text-box>

                                       <hr>

                                       <div  class="trigger-list-item" 
                                             [class.disapproved-trigger]="!t.data.isApproved" 
                                             *ngFor="let t of this.conditionTriggers">
                                          <span>
                                             <dx-check-box  [(value)]="t.isSelected" 
                                                            (onValueChanged)="this.onSelectedConditionTriggerChanged($event, t)">
                                             </dx-check-box>
                                          </span>
                                          <span>{{t.data.displayName}}@{{t.data.vendorName}}</span>
                                       </div>
                                    </div>
                                 </dx-drop-down-box>
                              </div>
                           </div>

                           <!-- Entry -->
                           <div class="trigger">
                              <div class="label">Entry Triggers</div>
                              <div class="input">

                                 <dx-drop-down-box fieldTemplate="field" 
                                                   [disabled]="!this.isEntryTriggerAvailable"
                                                   (onClosed)=" this.entryTriggersSearchString = '' ">
                                    
                                    <div *dxTemplate="let data of 'field'">
                                       <dx-text-box [value]="this.getSelectedValueForTriggers('entry')"></dx-text-box>
                                    </div>
                                    
                                    <div *dxTemplate="let data of 'content'">

                                       <dx-text-box 
                                          placeholder="Type to search..." 
                                          valueChangeEvent="keyup"
                                          [(value)]="this.entryTriggersSearchString">
                                       </dx-text-box>

                                       <hr>

                                       <div  class="trigger-list-item" 
                                             [class.disapproved-trigger]="!t.data.isApproved" 
                                             *ngFor="let t of this.entryTriggers">
                                          <span>
                                             <dx-check-box  [(value)]="t.isSelected" 
                                                            (onValueChanged)="this.onSelectedEntryTriggerChanged($event, t)">
                                             </dx-check-box>
                                          </span>
                                          <span>{{t.data.displayName}}@{{t.data.vendorName}}</span>
                                       </div>
                                    </div>
                                 </dx-drop-down-box>

                              </div>

                              <div class="entry-spec">
                                 
                                 <div class="continuous-entry">
                                    <dx-check-box  [text]="'Continuous'" #cbCont 
                                                   [value]="this.entryTriggerContinuous"
                                                   [disabled]="selectedEntryTriggers.length === 0"
                                                   (disabledChange)="onEntryTriggerContinuousDisabledChanged($event)"
                                                   (valueChange)="this.onIsEntryTriggerContinuousChanged($event)">
                                    </dx-check-box>
                                 </div>
                                 
                                 <div class="times-to-listen">
                                    <span>[x] times:</span>
                                    <dx-number-box    [min]="1" 
                                                      [step]="1" 
                                                      [(value)]="this.entryTriggerTimesCount" 
                                                      [showSpinButtons]="true" 
                                                      [disabled]="cbCont.value || selectedEntryTriggers.length === 0"
                                                      (disabledChange)="this.onEntryTriggerTimesCountDisabledChanged($event)" >
                                    </dx-number-box>
                                 </div>
                              </div>

                           </div>

                           <!-- Exit -->
                           <div class="trigger">
                              <div class="label">Exit Triggers</div>

                              <div class="input">
                                 <dx-drop-down-box fieldTemplate="field" 
                                                   (onClosed)=" this.exitTriggersSearchString = '' "
                                                   [disabled]="!this.isExitTriggerAvailable">

                                    <div *dxTemplate="let data of 'field'">
                                       <dx-text-box [value]="this.getSelectedValueForTriggers('exit')"></dx-text-box>
                                    </div>

                                    <div *dxTemplate="let data of 'content'">
                                       
                                       <dx-text-box 
                                          placeholder="Type to search..." 
                                          valueChangeEvent="keyup"
                                          [(value)]="this.exitTriggersSearchString">
                                       </dx-text-box>

                                       <hr>

                                       <div  class="trigger-list-item" 
                                             [class.disapproved-trigger]="!t.data.isApproved" 
                                             *ngFor="let t of this.exitTriggers">
                                          <span>
                                             <dx-check-box  [(value)]="t.isSelected" 
                                                            (onValueChanged)="this.onSelectedExitTriggerChanged($event, t)">
                                             </dx-check-box>
                                          </span>
                                          <span>{{t.data.displayName}}@{{t.data.vendorName}}</span>
                                       </div>
                                    </div>
                                 </dx-drop-down-box>

                                 <dx-check-box  class="immediate-exit" 
                                                text="Immediate Exit" 
                                                [(value)]="isImmediateExit" 
                                                [disabled]="this.selectedExitTriggers.length === 0" >
                                 </dx-check-box>
                              </div>
                           </div>
                        </div>
                     </fieldset>
                  </div>

                  <div class="position-sizing">
                     <fieldset>
                        <legend>
                           <dx-check-box text="Position Sizing" [(value)]="isPositionSizingEnabled" [disabled]="isDeltaHedge">
                           </dx-check-box>
                        </legend>
                        <div class="container">
                           <dx-check-box text="Use Account Size in Calculation" 
                              [disabled]="!isPositionSizingEnabled"
                              (valueChange)="this.onPositionSizingUseAccountSizeInCalculationsChanged($event)"
                              [value]="positionSizing?.useAccountSizeInCalculations"></dx-check-box>
                        </div>
                        <div class="container">
                           <div class="label">Account Size</div>
                           <div class="input">
                              <dx-number-box [min]="0" [showClearButton]="true"
                                 [disabled]="!positionSizing?.useAccountSizeInCalculations"
                                 [value]="positionSizing?.baseAccountSize"
                                 (valueChange)="this.onPositionSizingAccountSizeChanged($event)">
                              </dx-number-box>
                           </div>
                        </div>

                        <hr style="width: 80%;">

                        <!-- Main Positions Sizing Strategy -->

                        <div class="container">
                           <div class="label">Choose Position Sizing</div>
                           <div class="input">
                              <dx-select-box [items]="positionSizing.sizingStrategyItems" [showClearButton]="true"
                                 [value]="positionSizing?.sizingStrategy"
                                 (valueChange)="this.onPositionSizingSizingStrategyChanged($event)"
                                 [displayExpr]="'text'" [valueExpr]="'value'" [disabled]="!isPositionSizingEnabled"
                                 [selectedItem]="positionSizing?.sizingStrategy"></dx-select-box>
                           </div>

                           <div class="fixed-ratio" *ngIf="positionSizing?.sizingStrategy === 1">
                              <div class="row">
                                 <div class="label">Unit Size</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.ratioUnitSize"
                                       (valueChange)="this.onPositionSizingRatioUnitSizeChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Delta</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.deltaSize"
                                       (valueChange)="this.onPositionSizingDeltaChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Allow Decrease of Multiplier</div>
                                 <div class="input">
                                    <dx-check-box [value]="positionSizing.allowDecreaseMultiplier"
                                       (valueChange)="this.onPositionSizingAllowDecreaseMultiplierChanged($event)">
                                    </dx-check-box>
                                 </div>
                              </div>
                           </div>
                           <div class="fixed-fractional" *ngIf="positionSizing?.sizingStrategy === 2">
                              <div class="row">
                                 <div class="label">Unit Size</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.fractionalUnitSize"
                                       (valueChange)="this.onPositionSizingFractionalUnitSizeChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Trade 1 Unit Every $ of Equity</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.unitPerAmount"
                                       (valueChange)="this.onPositionSizingUnitPerAmountChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Risk X% of Equity per Trade</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.unitPerPercent"
                                       (valueChange)="this.onPositionSizingUnitPerPercentChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Risk per Unit $</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.riskPerUnit"
                                       (valueChange)="this.onPositionSizingRiskPerUnitChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <hr style="width: 80%;">

                        <div class="container">
                           <div class="label">When...</div>
                           <div class="input">
                              <dx-select-box
                                 [items]="[{text: 'Net Profit Reached', value: 'profit'},{text: 'When More Efficient', value: 'efficient'}]"
                                 #when [displayExpr]="'text'" [valueExpr]="'value'"
                                 [selectedItem]="positionSizing?.uiSwitchWhen" [disabled]="!isPositionSizingEnabled"
                                 [value]="positionSizing?.uiSwitchWhen"
                                 (valueChange)="this.onPositionSizingUiSwitchWhenChanged($event)"
                                 [showClearButton]="true"></dx-select-box>
                              <div *ngIf="when.value === 'profit'" class="when_net_profit">
                                 <span>Profit:</span>
                                 <div class="number_box">
                                    <dx-number-box [value]="positionSizing?.switchWhenProfit"
                                       (valueChange)="this.onPositionSizingSwitchWhenProfitChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <!-- Switch To -->

                        <div class="container">
                           <div class="label">Switch To: </div>
                           <div class="input">
                              <dx-select-box [items]="positionSizing.switchToItems"
                                 [disabled]="!isPositionSizingEnabled || !when.value" [value]="positionSizing?.switchTo"
                                 [displayExpr]="'text'" [valueExpr]="'value'"
                                 (valueChange)="this.onPositionSizingSwitchToChanged($event)"
                                 [showClearButton]="true" [selectedItem]="positionSizing?.switchTo">
                              </dx-select-box>
                           </div>
                           <div class="fixed-ratio" *ngIf="positionSizing?.switchTo === 1">
                              <div class="row">
                                 <div class="label">Unit Size</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.altRatioUnitSize"
                                       (valueChange)="this.onPositionSizingAltRationUnitSizeChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Delta</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.altDeltaSize"
                                       (valueChange)="this.onPositionSizingAltDeltaSizeChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Allow Decrease of Multiplier</div>
                                 <div class="input">
                                    <dx-check-box [value]="positionSizing.altAllowDecreaseMultiplier"
                                       (valueChange)="this.onPositionSizingAltAllowDecreaseMultiplierChanged($event)">
                                    </dx-check-box>
                                 </div>
                              </div>
                           </div>
                           <div class="fixed-fractional" *ngIf="positionSizing?.switchTo === 2">
                              <div class="row">
                                 <div class="label">Unit Size</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.altFractionalUnitSize"
                                       (valueChange)="this.onPositionSizingAltFractionalUnitSizeChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Trade 1 Unit Every $ of Equity</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.altUnitPerAmount"
                                       (valueChange)="this.onPositionSizingAltUnitPerAmountChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Risk X% of Equity per Trade</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.altUnitPerPercent"
                                       (valueChange)="this.onPositionSizingAltUnitPerPercent($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="label">Risk per Unit $</div>
                                 <div class="input">
                                    <dx-number-box [value]="positionSizing?.altRiskPerUnit"
                                       (valueChange)="this.onPositionSizingAltRiskPerUnitChanged($event)"
                                       [min]="0"></dx-number-box>
                                 </div>
                              </div>
                           </div>
                           <dx-check-box class="stay-switched" text="Stay With When Switched"
                              [value]="positionSizing?.stayWhenSwitched" [disabled]="!isPositionSizingEnabled"
                              (valueChange)="this.onPositionSizingStaySwitchedChanged($event)">
                           </dx-check-box>
                        </div>

                     </fieldset>
                  </div>

                  <div class="other-settings">
                     <fieldset>
                        <legend>Other Settings</legend>
                        
                        <div class="auto-roll">
                           <dx-check-box text="Do Not Auto-Roll" [value]="parametersControl?.doNotAutoRoll || false"
                              (valueChange)="this.onDoNotAutoRollChanged($event)">
                           </dx-check-box>
                        </div>

                        <div class="autostopping" *ngIf="!isInnerStrategyForNetBetting">
                           <div class="block">
                              <dx-check-box text="Autostop"
                                     [disabled]="!parametersControl"
                                     tabindex="-1"
                                     [value]="this.autostopping"
                                     (valueChange)="this.onAutoStoppingChanged($event)"
                                     #autostoppingCb>
                              </dx-check-box>
                           </div>
                           <div class="block">
                              <div class="sub-block">[x] times:</div>
                              <div class="sub-block">
                                 <dx-number-box [(value)]="this.autostoppingCount"
                                                [min]="1"
                                                [showSpinButtons]="true"
                                                [disabled]="!this.autostopping"
                                                (disabledChange)="this.onAutoStoppingCountDisabledChanged($event)">
                                 </dx-number-box>
                              </div>
                           </div>
                        </div>

                        <div>
                           <dx-check-box text="Don't Use Cancel Confirmations" [value]="this.parametersControl?.dontUseCancelConfirmations || false"
                              (valueChange)="this.onDontUseCancelConfirmations($event)"></dx-check-box>
                        </div>
                      
                     </fieldset>
                  </div>
               </div>

            </div>

            <div class="bottom">

               <div class="section leftcol" [class.lean]="this.dialogConfig?.editMode === 'lean'">
                  <div class="sl-margin">
                     <fieldset>
                        <legend>SL Margins</legend>
                        <div class="margin-container">
                           <div class="item label">
                              <dx-check-box text="Trade: "
                                 [disabled]="!parametersControl || !parametersControl.isSession || isDeltaHedge" tabindex="-1"
                                 [value]="parametersControl?.tradingObjectives?.isTradeSlMargin || false"
                                 (valueChange)="this.onTradingObjectivesIsTradeSlMarginChanged($event)"
                                 #tradeSlMargin></dx-check-box>
                           </div>
                           <div class="item input">
                              <dx-number-box [showSpinButtons]="true"
                                 [disabled]="!parametersControl || !parametersControl.isSession || !tradeSlMargin.value || isDeltaHedge"
                                 [value]="parametersControl?.tradingObjectives?.tradeSlMargin"
                                 (valueChange)="this.onTradingObjectivesTradeSlMarginChanged($event)"
                                 [min]="1" [max]="100" [step]="1" tabindex="-1"></dx-number-box>
                           </div>
                        </div>
                        <div class="margin-container">
                           <div class="item label">
                              <dx-check-box text="Session: "
                                 [disabled]="!parametersControl || !parametersControl.isSession || isDeltaHedge" tabindex="-1"
                                 [value]="parametersControl?.tradingObjectives?.isSessionSlMargin || false"
                                 (valueChange)="this.onTradingObjectivesIsSessionSlMarginChanged($event)"
                                 #sessionSlMargin></dx-check-box>
                           </div>
                           <div class="item input">
                              <dx-number-box [showSpinButtons]="true"
                                 [disabled]="!parametersControl || !parametersControl.isSession || !sessionSlMargin.value || isDeltaHedge"
                                 [value]="parametersControl?.tradingObjectives?.sessionSlMargin"
                                 (valueChange)="this.onTradingObjectivesSessionSlMarginChanged($event)"
                                 [min]="1" [max]="100" [step]="1" tabindex="-1"></dx-number-box>
                           </div>
                        </div>
                        <div class="margin-container">
                           <div class="item label">
                              <dx-check-box text="Acc: " [disabled]="!parametersControl || isDeltaHedge" tabindex="-1"
                                 [value]="parametersControl?.tradingObjectives?.isAccumulatedSlMargin || false"
                                 (valueChange)="this.onTradingObjectivesIsAccumulatedSlMarginChanged($event)"
                                 #accumulatedSlMargin></dx-check-box>
                           </div>
                           <div class="item input">
                              <dx-number-box [showSpinButtons]="true"
                                 [disabled]="!parametersControl || !accumulatedSlMargin.value"
                                 [value]="parametersControl?.tradingObjectives?.accumulatedSlMargin"
                                 (valueChange)="this.onTradingObjectivesAccumulatedSlMarginChanged($event)"
                                 [min]="1" [max]="100" [step]="1" tabindex="-1"></dx-number-box>
                           </div>
                        </div>
                     </fieldset>
                  </div>
               </div>

               <div class="section centercol" [class.lean]="this.dialogConfig?.editMode === 'lean'">

                  <div class="tos-and-buttons">
                     
                     <div class="tos">
                        <div class="to" [ngClass]="{'ets-disabled': !parametersControl}"
                           *ngIf="!isInnerStrategyForNetBetting">

                           <div class="to-item npo first">
                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Trade NPO"
                                       [disabled]="!parametersControl || parametersControl.isDisposition || isDeltaHedge" tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isTradeNpo || false"
                                       (valueChange)="this.onTradingObjectivesIsTradeNpoChanged($event)"
                                       #tradeNpo></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !tradeNpo.value || parametersControl.isDisposition || isDeltaHedge"
                                       tabindex="-1" [value]="parametersControl?.tradingObjectives?.tradeNpo"
                                       (valueChange)="this.onTradingObjectivesTradeNpoChanged($event)">
                                    </dx-number-box>
                                 </div>
                              </div>
                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Sess. NPO"
                                       [disabled]="!parametersControl || !parametersControl.isSession || isDeltaHedge" 
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isSessionNpo || false"
                                       (valueChange)="this.onTradingObjectivesIsSessionNpoChanged($event)"
                                       #sessionNpo></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !parametersControl.isSession || !sessionNpo.value || isDeltaHedge"
                                       tabindex="-1" [value]="parametersControl?.tradingObjectives?.sessionNpo"
                                       (valueChange)="this.onTradingObjectivesSessionNpoChanged($event)">
                                    </dx-number-box>
                                 </div>
                              </div>
                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Acc. NPO" [disabled]="!parametersControl" tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isAccumulatedNpo || false"
                                       [disabled]="isDeltaHedge"
                                       (valueChange)="this.onTradingObjectivesIsAccumulatedNpoChanged($event)"
                                       #accumulatedNpo></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !accumulatedNpo.value || isDeltaHedge" tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.accumulatedNpo"
                                       (valueChange)="this.onTradingObjectivesAccumulatedNpoChanged($event)">
                                    </dx-number-box>
                                 </div>
                              </div>
                           </div>

                           <!-- %-TS -->
                           <div class="to-item percent-ts">

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Trade %-TS"
                                       [disabled]="!parametersControl || !parametersControl.tradingObjectives.canSetTradePercentTS || parametersControl.isDisposition || isDeltaHedge"
                                       tabindex="-1" #tradePercentTS
                                       [value]="parametersControl?.tradingObjectives?.isTradePercentTS || false"
                                       (valueChange)="this.onTradingObjectivesIsTradePercentTsChanged($event)">
                                    </dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !tradePercentTS.value || parametersControl.isDisposition"
                                       [value]="parametersControl?.tradingObjectives?.tradePercentTS"
                                       (valueChange)="this.onTradingObjectivesTradePercentTsChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Sess. %-TS"
                                       [disabled]="!parametersControl || !parametersControl.isSession || !parametersControl.tradingObjectives.canSetSessionPercentTS || isDeltaHedge"
                                       tabindex="-1" #sessionPercentTS
                                       [value]="parametersControl?.tradingObjectives?.isSessionPercentTS || false"
                                       (valueChange)="this.onTradingObjectivesIsSessionPercentTsChanged($event)">
                                    </dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !parametersControl.isSession || !sessionPercentTS.value"
                                       [value]="parametersControl?.tradingObjectives?.sessionPercentTS"
                                       (valueChange)="this.onTradingObjectivesSessionPercentTsChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Acc. %-TS"
                                       [disabled]="!parametersControl || !parametersControl.tradingObjectives.canSetAccumulatedPercentTS || isDeltaHedge"
                                       tabindex="-1" #accumulatedPercentTS
                                       [value]="parametersControl?.tradingObjectives?.isAccumulatedPercentTS || false"
                                       (valueChange)="this.onTradingObjectivesIsAccumulatedPercentTsChanged($event)">
                                    </dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !accumulatedPercentTS.value"
                                       [value]="parametersControl?.tradingObjectives?.accumulatedPercentTS"
                                       (valueChange)="this.onTradingObjectivesAccumulatedPercentTsChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>
                           </div>

                           <!-- Dollar TS -->
                           <div class="to-item dollar-ts">
                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Trade $-TS"
                                       [disabled]="!parametersControl || parametersControl.isDisposition || isDeltaHedge" 
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isTradeDollarTS || false"
                                       (valueChange)="this.onTradingObjectivesIsTradeDollarTsChanged($event)"
                                       #tradeDollarTS></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !tradeDollarTS.value || parametersControl.isDisposition"
                                       [value]="parametersControl?.tradingObjectives?.tradeDollarTS"
                                       (valueChange)="this.onTradingObjectivesTradeDollarTsChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Sess. $-TS"
                                       [disabled]="!parametersControl || !parametersControl.isSession || isDeltaHedge"  
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isSessionDollarTS || false"
                                       (valueChange)="this.onTradingObjectivesIsSessionDollarTsChanged($event)"
                                       #sessionDollarTS></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !parametersControl.isSession || !sessionDollarTS.value"
                                       [value]="parametersControl?.tradingObjectives?.sessionDollarTS"
                                       (valueChange)="this.onTradingObjectivesSessionDollarTsChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Acc. $-TS" [disabled]="!parametersControl || isDeltaHedge" 
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isAccumulatedDollarTS || false"
                                       (valueChange)="this.onTradingObjectivesIsAccumulatedDollarTsChanged($event)"
                                       #accumulatedDollarTS></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !accumulatedDollarTS.value"
                                       [value]="parametersControl?.tradingObjectives?.accumulatedDollarTS"
                                       (valueChange)="this.onTradingObjectivesAccumulatedDollarTsChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>
                           </div>

                           <!-- Stop-Loss -->
                           <div class="to-item stop-loss last">
                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Trade SL"
                                       [disabled]="!parametersControl || parametersControl.isDisposition || isDeltaHedge" 
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isTradeStopLoss || false"
                                       (valueChange)="this.onTradingObjectivesIsTradeStopLossChanged($event)"
                                       #tradeSl></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !tradeSl.value || parametersControl.isDisposition"
                                       [value]="parametersControl?.tradingObjectives?.tradeStopLoss"
                                       (valueChange)="this.onTradingObjectivesTradeStopLossChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Sess. SL"
                                       [disabled]="!parametersControl || !parametersControl.isSession || isDeltaHedge" 
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isSessionStopLoss || false"
                                       (valueChange)="this.onTradingObjectivesIsSessionStopLossChanged($event)"
                                       #sessionSL></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !parametersControl.isSession || !sessionSL.value"
                                       [value]="parametersControl?.tradingObjectives?.sessionStopLoss"
                                       (valueChange)="this.onTradingObjectivesSessionStopLossChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>

                              <div class="to-container">
                                 <div class="label">
                                    <dx-check-box text="Acc. SL" [disabled]="!parametersControl || isDeltaHedge" 
                                       tabindex="-1"
                                       [value]="parametersControl?.tradingObjectives?.isAccumulatedStopLoss || false"
                                       (valueChange)="this.onTradingObjectivesIsAccumulatedStopLossChanged($event)"
                                       #accumulatedSL></dx-check-box>
                                 </div>
                                 <div class="input">
                                    <dx-number-box [showSpinButtons]="true"
                                       [disabled]="!parametersControl || !accumulatedSL.value"
                                       [value]="parametersControl?.tradingObjectives?.accumulatedStopLoss"
                                       (valueChange)="this.onTradingObjectivesAccumulatedStopLossChanged($event)"
                                       tabindex="-1"></dx-number-box>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="buttons" [class.lean]="this.dialogConfig?.editMode === 'lean'">
                        <div class="button">
                           <dx-button text="Add" [disabled]="!parametersControl || dialogConfig?.isUpdate"
                              (onClick)="addStrategy(false)" type="success"></dx-button>
                        </div>
                        <div class="button">
                           <dx-button text="Update" [disabled]="!dialogConfig?.isUpdate || !parametersControl"
                              (onClick)="updateStrategy()" type="default"></dx-button>
                        </div>
                        <div class="button">
                           <dx-button text="Close" (onClick)="close()" type="danger"></dx-button>
                        </div>
                     </div>

                  </div>
               </div>

            </div>

         </div>
      </div>
      <ets-advanced-schedule-dialog (advancedScheduleSet)="onAdvancedScheduleSet($event)"></ets-advanced-schedule-dialog>
      <dx-load-panel [visible]="isLoading"></dx-load-panel>
   </div>
</dx-popup>