import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { StrategyPositionSizing } from '../strategies/strategy-position-sizing.class';
import { DetectMethodChanges } from '../utils';

@Component({
   selector: 'ets-position-sizing',
   templateUrl: 'position-sizing.component.html',
   styleUrls: ['position-sizing.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class PositionSizingComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }
   
   isDeltaHedge = false;

   isPositionSizingEnabled = true;

   @Input() positionSizing;

   ngOnInit() { }

   
   //#region  Position Sizing

   @DetectMethodChanges()
   onPositionSizingUseAccountSizeInCalculationsChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.useAccountSizeInCalculations = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAccountSizeChanged(ev) {
      this.positionSizing.baseAccountSize = ev;
   }


   @DetectMethodChanges()
   onPositionSizingSizingStrategyChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.sizingStrategy = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingRatioUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.ratioUnitSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingDeltaChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.deltaSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAllowDecreaseMultiplierChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.allowDecreaseMultiplier = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingFractionalUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.fractionalUnitSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingUnitPerAmountChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.unitPerAmount = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingUnitPerPercentChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.unitPerPercent = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingRiskPerUnitChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.riskPerUnit = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingUiSwitchWhenChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.uiSwitchWhen = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingSwitchWhenProfitChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.switchWhenProfit = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingSwitchToChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.switchTo = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltRationUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altRatioUnitSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltDeltaSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altDeltaSize = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltAllowDecreaseMultiplierChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altAllowDecreaseMultiplier = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltFractionalUnitSizeChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altFractionalUnitSize = ev;
      }
   }

   
   @DetectMethodChanges()
   onPositionSizingAltUnitPerAmountChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altUnitPerAmount = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingAltUnitPerPercent(ev) {
      if (this.positionSizing) {
         this.positionSizing.altUnitPerPercent = ev;
      }
   }

   
   @DetectMethodChanges()
   onPositionSizingAltRiskPerUnitChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.altRiskPerUnit = ev;
      }
   }


   @DetectMethodChanges()
   onPositionSizingStaySwitchedChanged(ev) {
      if (this.positionSizing) {
         this.positionSizing.stayWhenSwitched = ev;
      }
   }


   //#endregion Position Sizing

   @DetectMethodChanges()
   reset() {
      this.positionSizing = new StrategyPositionSizing();
   }


}
