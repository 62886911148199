import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TIME_ZONES_LIST, TIME_ZONE_FAMILIES } from '../timezones/time-zones.const';
import { TimestampsService } from '../timestamps.service';
import { DetectMethodChanges, DetectSetterChanges } from '../utils';
import { AdjustmentPricingGridSettingsModel } from './model/AdjustmentPricingGridSettings';
import { AppSettingsUpdatedMessageTopic, ApplicationSettingsService } from './application-settings.service';
import { LocationService } from '../location.service';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { SessionService } from '../authentication/session-service.service';
import { MessageBusService } from '../message-bus.service';
import { TimeZoneDescriptor } from '../timezones/time-zone-descriptor.interface';
import { AccessControlService } from '../access-control-service.class';

@Component({
   selector: 'ets-app-settings',
   templateUrl: 'app-settings.component.html',
   styleUrls: ['./app-settings.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSettingsComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _timestampsService: TimestampsService,
      private readonly _applicationSettingsService: ApplicationSettingsService,
      private readonly _locationService: LocationService,
      private readonly _tiService: TradingInstrumentsService,
      private readonly _sessionService: SessionService,
      private readonly _messageBus: MessageBusService,
      private readonly _accessControlService: AccessControlService
   ) {
   }

   //
   private _isVisible = false;
   get isVisible() {
      return this._isVisible;
   }
   @DetectSetterChanges()
   set isVisible(v: boolean) {
      this._isVisible = v;
   }

   //
   private _isLoading = false;
   get isLoading() {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   timestampsZoneItems: string[] = TIME_ZONES_LIST;

   //
   private _timezones : TimeZoneDescriptor[];
   get timezones() : TimeZoneDescriptor[] {
      return this._timezones;
   }
   @DetectSetterChanges()
   set timezones(v : TimeZoneDescriptor[]) {
      this._timezones = v;
   }
   
   //
   private _timestampsDefaultZone: string;
   get timestampsDefaultZone() {
      return this._timestampsDefaultZone;
   }
   @DetectSetterChanges()
   set timestampsDefaultZone(v: string) {
      this._timestampsDefaultZone = v;
   }

   //
   adjustmentPricingGrid: AdjustmentPricingGridSettingsModel;

   //
   @DetectMethodChanges()
   ngOnInit() {
      this.restoreTimezoneSettings();
      this.restoreApgSettings();
   }

   //
   @DetectMethodChanges()
   onHidden() {
      this.timestampsDefaultZone = undefined;
      this.isVisible = false;
   }

   //
   @DetectMethodChanges()
   onShown() {
      this.restoreTimezoneSettings();
      this.restoreApgSettings();
   }

   //
   @DetectMethodChanges()
   saveChangesAndClose() {
      this.saveChanges();
      this.close();
   }

   //
   @DetectMethodChanges()
   close() {
      this.isVisible = false;
   }

   //
   @DetectMethodChanges()
   onChanges() {
   }

   //
   private saveChanges() {
      this.saveTimezoneSettings();
      this.saveAdjustmentPricingGridSettings();
      
      this._messageBus.publishAsync({
         topic: AppSettingsUpdatedMessageTopic,
         payload: {}
      });
   }

   //
   private saveAdjustmentPricingGridSettings() {
      this._applicationSettingsService.adjustmentPricingGrid = this.adjustmentPricingGrid;
   }

   //
   private saveTimezoneSettings() {
      this._applicationSettingsService.defaultTimezone = this.timestampsDefaultZone;
   }

   //
   private restoreApgSettings() {

      const apgSettings =
         this._applicationSettingsService.adjustmentPricingGrid;

      this.adjustmentPricingGrid = new AdjustmentPricingGridSettingsModel(
         this._locationService,
         this._tiService,
         this._sessionService,
         this._accessControlService,
         apgSettings,
          this._changeDetector
      );
   }

   //
   private restoreTimezoneSettings() {
      this.timezones = TIME_ZONE_FAMILIES.flatMap(x => x.timeZoneDescriptors);
      this.timestampsDefaultZone = this._timestampsService.getDefaultTimezone();
   }

   @DetectMethodChanges()
   onCharlesSchwabFixChanged() {
      if (this.adjustmentPricingGrid.useCharlesSchwabFix) {
         this.adjustmentPricingGrid.useMarginEfficientAdjustment = false;
      }
   }

   @DetectMethodChanges()
   onTimezoneChanged($event: string) {

   }
}
