<div class="global-settings-section">
   
   <div class="header">
      <ets-section-header [header]="this.header">
      </ets-section-header>
   </div>

   <div class="body">
      
      <div class="section roll-time-settings">
         <ets-automation-cp-sweetspot-adjustment #sweetSpot>
         </ets-automation-cp-sweetspot-adjustment>

         <ets-automation-cp-global-settings-roll-time-settings #rollSettings>
         </ets-automation-cp-global-settings-roll-time-settings>
      </div>
      
      <div class="section order-type-settings">
         <ets-automation-cp-order-type-settings #orderTypeSettings>
         </ets-automation-cp-order-type-settings>
      </div>
      
      <div class="section expiration-smart-mode">
         <ets-automation-cp-global-settngs-expiraion-smart #expirationSmartMode>
         </ets-automation-cp-global-settngs-expiraion-smart>
      </div>

      <div class="section comparison-pricing-settings">
         <ets-automation-cp-comparison-pricing-settings #comparisonPricingSettings>
         </ets-automation-cp-comparison-pricing-settings>
      </div>

   </div>

</div>