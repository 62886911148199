import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community';
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GameOutcome } from '../game-outcome';
import { NewFlagshipReportComponent } from './new-flagship-report.component';
import { 
  centeredColumnDef, 
  defaultMoneyCellDefinition, 
  defaultQuoteCellFormatter, 
  defaultLoadingOverlayTemplate 
} from 'projects/shared-components/ag-grid-contrib';
import { isNullOrUndefined } from 'util';
import { formatDate } from '@angular/common';
import { FlagshipReportEntryModel } from '../flagship/flagship-report-entry-model.class';

export function getNewReportGridModel(this: NewFlagshipReportComponent): GridOptions {
  return {
    rowData: [],

    defaultColDef: Object.assign({ filter: true }, centeredColumnDef),    

    columnDefs: [
      {
        headerName: '#',
        field: 'sequenceNum',
        width: 80,
        cellStyle(args: CellClassParams) {
          return { background: 'cornflowerblue', color: 'white' };
        },
        hide: true,
      },
      {
        headerName: 'Up',
        field: 'actualBet',
        valueGetter(args: ValueGetterParams) {
          const outcome: GameOutcome = args.data.outcome;
          const bet = args.data.actualBet;
          if (isNullOrUndefined(bet)) {
            return '';
          }
          if (Math.sign(bet) === 1) {
            return Math.abs(bet) + '';
          }
          return '';
        },
        cellStyle(args: CellClassParams) {
          const outcome: GameOutcome = args.data.outcome;
          let retval = { background: 'white', color: 'black' };
          if (outcome === GameOutcome.Up) {
            retval = { background: 'yellow', color: 'black' };
          }
          return retval;
        }
      },
      //
      {
        headerName: 'Down',
        field: 'actualBet',
        valueGetter(args: ValueGetterParams) {
          const outcome: GameOutcome = args.data.outcome;
          const bet = args.data.actualBet;
          if (isNullOrUndefined(bet)) {
            return '';
          }
          if (Math.sign(bet) === -1) {
            return Math.abs(bet) + '';
          }
          return '';
        },
        cellStyle(args: CellClassParams) {
          const outcome: GameOutcome = args.data.outcome;
          let retval = { background: 'white', color: 'black' };
          if (outcome === GameOutcome.Down) {
            retval = { background: 'yellow', color: 'black' };
          }
          return retval;
        }
      },
      //
      Object.assign(
        {
          headerName: 'Total P&L',
          field: 'totalPnL'
        },
        defaultMoneyCellDefinition
      ),
      //
      Object.assign(
        {
          headerName: 'Perfect P&L',
          field: 'perfectPnL'
        },
        defaultMoneyCellDefinition
      ),
      //
      Object.assign(
        {
          headerName: 'Slippage',
          field: 'slippageView'
        },
        defaultQuoteCellFormatter
      ),
      //
      {
        headerName: 'CTL Size',
        field: 'ctlValueView',
        valueFormatter: defaultQuoteCellFormatter
      },
      {
        headerName: 'Timestamp',
        field: 'timestamp',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return '';
          }
          return formatDate(params.value || null, 'yyyy-MMM-dd HH:mm:ss', 'en-US');
        }
      },
    ],

    overlayLoadingTemplate: defaultLoadingOverlayTemplate,

    rowClass: 'ets-text-centered',

    rowSelection: 'single',

    rowModelType: 'clientSide',

    immutableData: true,

    onGridReady: args => this.onGamesGridReady(args),

    getRowNodeId: (rowData: FlagshipReportEntryModel) => {
      return rowData.seqNum + '';
    },

    getRowStyle: (args) => {
      const data = args.data;
      if (!data) {
        return null;
      }
      return data.isLast ? { background: 'cyan', color: 'black' } : null;
    },

    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [
        {
          name: 'Load Earlier Games...',
          subMenu: [
            {
              name: '50',
              action: () => this.loadEarlierGames(50)
            },
            {
              name: '100',
              action: () => this.loadEarlierGames(100)
            },
            {
              name: '500',
              action: () => this.loadEarlierGames(500)
            },
            {
              name: '1000',
              action: () => this.loadEarlierGames(1000)
            }
          ],
          disabled: !this.selectedDataLoadMode || this.selectedDataLoadMode.value === 'Date'
        },
        'separator',
        'autoSizeAll',
        'copy',
        'export'
      ];
    }
  } as GridOptions;
}
