import { GridOptions, ValueFormatterParams, CellValueChangedEvent } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate } from 'projects/shared-components/ag-grid-contrib';
import { RolloverSchedulerComponent } from './rollover-scheduler.component';
import { RolloverContractGroupRowRendererComponent } from './rollover-contract-group-row-renderer';
import { formatDate } from '@angular/common';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { isNullOrUndefined } from 'util';
import { TimeRendererComponent } from './time-renderer.component';
import { TimeEditorComponent } from './time-editor.component';
import { RolloverDescriptor } from 'projects/shared-components/shell-communication/shell-dto-protocol';

export function getPositionalRulesGridModel(
   this: RolloverSchedulerComponent,
   displayNameService: TradingInstrumentDisplayNameService): GridOptions {
   return {
      rowData: [],
      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Contract',
            field: 'ticker',
            valueFormatter(params: ValueFormatterParams) {
               return displayNameService.getDisplayNameForTicker(params.value);
            },
         },
         {
            headerName: 'Specific Offset',
            field: 'specificOffset',
            editable: true,
            valueParser: params => {
               const result = Number(params.newValue);
               if (!result || isNaN(result) || result > 0) {
                  return null;
               }
               return result;
            },
            onCellValueChanged: (args: CellValueChangedEvent) => {
               if (args.oldValue === args.newValue) {
                  return;
               }
               this.onOffsetChanged({ symbol: args.data.baseSymbol, isSession: args.data.isSession });
            }
         },
         {
            headerName: 'Calculated Rollover Date',
            field: 'rolloverDate',
            valueFormatter(params: ValueFormatterParams) {
               if (isNullOrUndefined(params.value)) {
                  return null;
               }
               const fmt = formatDate(params.value, 'dd-MMM-yyyy', 'en-US');
               return fmt;
            },
            cellStyle: { color: 'yellow' }
         },
         {
            headerName: 'Time Of Day (24hrs)',
            field: 'timeOfDay',
            editable: true,
            // cellRenderer: 'timeRenderer',
            cellEditor: 'timeEditor'
         },
         {
            field: 'baseSymbol',
            hide: true,
            rowGroup: true,
         }
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      groupUseEntireRow: true,

      rowGroupPanelShow: 'never',

      frameworkComponents: {
         rolloverContractGroupRowRenderer: RolloverContractGroupRowRendererComponent,
         timeRenderer: TimeRendererComponent,
         timeEditor: TimeEditorComponent
      },

      groupRowRendererParams: {
         innerRenderer: 'rolloverContractGroupRowRenderer',
         suppressCount: true
      },

      onGridReady: args => this.onPositionalRulesGridReady(args),

      getRowNodeId: (rowData: RolloverDescriptor) => {
         return rowData.ticker;
      },

      getRowHeight: (params) => {
         if (params.node.group) {
            return 32;
         }
         return 28;
      },
   };
}
