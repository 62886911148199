import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DetectSetterChanges, isValidNumber, isVoid} from "../../utils";
import {ShellClientService} from "../../shell-communication/shell-client.service";
import {GetImpliedVolatility, GetImpliedVolatilityReply} from "../../shell-communication/shell-operations-protocol";
import {ZonesGridColumn, ZonesGridComponent} from "../zones-grid/zones-grid.component";

@Component({
    selector: 'ets-volatility-header',
    template: `
      <dx-load-indicator width="18" height="18" [visible]="this.loading"></dx-load-indicator>
      <div class="volatility" *ngIf="!this.loading">
        IV={{this.volatility | percent:'1.2-2'}}
      </div>
    `,
    styles: [
        `
        .volatility {
            font-size: 85%;
        }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class VolatilityHeaderComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _shellClient: ShellClientService
    ) {
    }

    @Input() underlying: string;

    @Input() expiration: string;

    @Input() root: { staticVol: number };

    private _volatility: number;
    get volatility(): number {
        return this.root.staticVol|| this._volatility;
    }

    @DetectSetterChanges()
    set volatility(v: number) {
        this._volatility = v;
    }

    private _loading = true;
    get loading(): boolean {
        return this._loading;
    }

    @DetectSetterChanges()
    set loading(v: boolean) {
        this._loading = v;
    }

    async ngOnInit() {
        const qry = new GetImpliedVolatility(
            this.underlying,
            this.expiration
        );

        try  {
            const reply = await this._shellClient
                .processQuery<GetImpliedVolatilityReply>(qry);
            this.volatility = reply.volatility;
        } catch {
          this.volatility = NaN;
        } finally {
            this.loading = false;
        }
    }

    ngOnDestroy() {
    }
}