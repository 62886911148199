import { SimexMatchingRuleDto } from '../shell-dto-protocol';
import {SimexDataProviderDto} from './simex-data-provider-dto.class';

export class SimexSettingsDto {
  constructor() {
    this.dataProviders = [];
    this.enabledDataProviders = [];
    this.matchingRules = [];
  }

  enabledDataProviders: string[];
  useTradeThroughLimits: boolean;
  dataProviders: SimexDataProviderDto[];
  acceptDataOutsideOfMarketHours: boolean;
  matchingRules: SimexMatchingRuleDto[];
}
