import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class GetCommanderRulesLinkedToStrategy implements ProtocolQuery {
  constructor(public readonly strategyId: string) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetCommanderRulesLinkedToStrategy`;
  }
}
