import {Injectable} from '@angular/core';
import {UserSettingsService} from "../../user-settings.service";

const StorageKey = 'pkg-cmprsn.hedgeboard.pinned'

@Injectable()
export class HedgeBoardPinnedService {

    constructor(
        private readonly _userSettings: UserSettingsService,
    ) {
    }

    private _index : string[] = [];

    save(packages: string[]) {
        this._index = packages;
        this._userSettings.setValue(StorageKey, this._index);
    }

    isPinned(packageId: string): boolean {
        return this._index.includes(packageId);
    }
}