import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SettingsStorageService } from '../settings-storage-service.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { MessageBusService } from '../message-bus.service';
import {UserSettingsService} from "../user-settings.service";

@Component({
   selector: 'ets-strategy-issues-panel',
   template: `
  <div class="panel-container" [ngClass]="{'ets-hidden': !isActive}">
    <ets-strategy-issues [mode]="'panel'" 
                         [panelId]="panelId" 
                         [workspaceId]="workspaceId" 
                         [layoutTabId]="layoutTabId"
    ></ets-strategy-issues>
  </div>
  `,
   styles: [`
  .panel-container {
    height: 100%;
    ag-grid-angular {
      height: 100%;
    }
  }  
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategyIssuesPanelComponent extends PanelBaseComponent {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
    }
 

   etsOnInit(): void {
   
   }
   
   etsOnDestroy(): void {
   
   }
   etsAfterViewInit(): void {
   
   }
   
   protected getState() {

      return null;
   }
   
   protected setState(state: any) {
   
   }

}
