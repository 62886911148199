import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { WorkspacePanelsMenuItem } from './workspace-panels-menu-item';
import { WorkspacePanelsMenuItemClickedUIMessage } from './workspace-panels-menu-item-clicked-message';
import { MessageBusService } from 'projects/shared-components/message-bus.service';

@Component({
  selector: 'ets-workspace-panels-menu',
  templateUrl: './workspace-panels-menu.component.html',
  styleUrls: ['./workspace-panels-menu.component.scss']
})
export class WorkspacePanelsMenuComponent implements AfterViewInit {
  public constructor(private _messageBus: MessageBusService,
                     private _selfRef: ElementRef) {
    this.items = [];
    this.maxHeight = null;
  }

  /**
   * Unique key to distinguish between multiple possible origin components, e.g multiple workspace can request context menu
   */
  @Input() public originKey: string;

  /**
   * Describes type of context menu, e.g: panels list, available panels etc.
   */
  @Input() public menuKey: string;

  @Input() public items: WorkspacePanelsMenuItem[];

  @Input() public maxHeight?: number;

  public get scrollBarMode(): string {
    return this.maxHeight > 0 ? 'always' : 'never';
  }

  public get menuItems(): WorkspacePanelsMenuItem[] {
    return this.items.filter(x => x.isAvailable());
  }

  public onClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public action(item: WorkspacePanelsMenuItem) {
    if (item.isAvailable) {
      const message = new WorkspacePanelsMenuItemClickedUIMessage(
        item.id,
        this.menuKey,
        this.originKey);

      this._messageBus.publish({
        payload: message,
        topic: 'WorkspacePanelsMenuItemClickedUIMessage'
      });
    }
  }

  public ngAfterViewInit() {
    if (this.menuKey !== 'add-panel') {
      return;
    }
    const self: HTMLElement = this._selfRef.nativeElement;
    const left: string = self.style.left;
    const numberPart = left.substr(0, left.indexOf('px'));
    try {
      const parsedNumber = parseFloat(numberPart) || 175;
      const adjustedLeft = parsedNumber - self.scrollWidth;
      self.style.left = `${adjustedLeft}px`;
    } catch (e) {
      //
    }
  }
}
