import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
   AdjustManualPosition
} from 'projects/shared-components/shell-communication/operations/manual-trading/adjust-manual-position.class';
import { CloseManualPosition } from 'projects/shared-components/shell-communication/operations/manual-trading/close-manual-position.class';
import {
   ReverseManualPosition
} from 'projects/shared-components/shell-communication/operations/manual-trading/reverse-manual-position.class';
import { ManualTradingSecurityContextService } from '../manual-trading/manual-trading-security-context.service';
import { ManualTradingBackendService } from '../manual-trading/manual-trading-backend.service';
import { DetectMethodChanges, DetectSetterChanges } from '../utils';
import { isNullOrUndefined } from 'util';

interface PositionToAdjust {
   positionId?: string;
   netPosition?: number;
   symbol?: string;
}

@Component({
   selector: 'ets-position-adjust',
   templateUrl: './position-adjust.component.html',
   styleUrls: ['./position-adjust.component.scss'],
   providers: [ManualTradingSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionAdjustComponent implements OnInit, AfterViewInit, OnDestroy {
   constructor(
      public securityContext: ManualTradingSecurityContextService,
      private readonly _backendClient: ManualTradingBackendService,
      private readonly _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef
   ) {
   }

   private _unsubscriber: Subject<any>;

   @Input() position: any;

   row: PositionToAdjust = {};
   
   private _adjustQty: number;
   get adjustQty(): number {
      return this._adjustQty;
   }

   @DetectSetterChanges()
   set adjustQty(value: number) {
      this._adjustQty = value;
   }
   
   
   private _isTradeConfirmationPending: boolean;
   get isTradeConfirmationPending(): boolean { return this._isTradeConfirmationPending; }
   
   @DetectSetterChanges()
   set isTradeConfirmationPending(val: boolean) { this._isTradeConfirmationPending = val; }
   

   private _isVisible = false;
   get isVisible(): boolean { return this._isVisible; }
   
   @DetectSetterChanges()
   set isVisible(value: boolean) { this._isVisible = value; }

   
   get canAdjustPosition(): boolean {
      if (this.isTradeConfirmationPending) {
         return false;
      }

      return (
         !isNullOrUndefined(this.adjustQty) && this.adjustQty !== this.row.netPosition
      );
   }

   
   get canCloseOrReversePosition(): boolean {
      if (this.isTradeConfirmationPending) {
         return false;
      }

      if (!this.row) {
         return false;
      }
      
      return this.row.netPosition !== 0;
   }



   @DetectMethodChanges()
   show(positionToAdjust: PositionToAdjust) {
      this.row = positionToAdjust;
      this.adjustQty = this.row.netPosition;
      this.isVisible = true;
   }
   
   @DetectMethodChanges()
   onHidden() {
      this.row = null;
      this.adjustQty = null;
      this.isVisible = false;
   }
   
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   

   ngOnInit(): void {

   }


   ngAfterViewInit() { this._changeDetector.detach(); }
   
   
   @DetectMethodChanges({isAsync: true})
   async adjustPosition(): Promise<void> {
      const position = this.row as any; 

      const cmd = new AdjustManualPosition((position.positionId || position.portfolioItemId), this.adjustQty);

      this.isTradeConfirmationPending = true;
      
      try {
         await this._backendClient.adjustManualPosition(cmd);
      } catch (error) {
         this._toastr.error('Error occurred during position adjust operation');
      } finally {
         this.isTradeConfirmationPending = false;
      }
   }

   
   @DetectMethodChanges({isAsync: true})
   async closePosition(): Promise<any> {
      const position = this.row as any;

      const cmd = new CloseManualPosition(position.positionId || position.portfolioItemId);

      this.isTradeConfirmationPending = true;

      try {
         await this._backendClient.closeManualPosition(cmd);
      } catch (error) {
         this._toastr.error('Error ocured during position adjust operation');
      } finally {
         this.isTradeConfirmationPending = false;
      }
   }

   
   @DetectMethodChanges({isAsync: true})
   async reversePosition(): Promise<any> {
      const position = this.row as any;

      const cmd = new ReverseManualPosition(position.positionId || position.portfolioItemId);

      this.isTradeConfirmationPending = true;

      try {
         await this._backendClient.reverseManualPosition(cmd);
      } catch (error) {
         this._toastr.error('Error occurred during position adjust operation');
      } finally {
         this.isTradeConfirmationPending = false;
      }
   }
   
}
