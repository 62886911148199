import { parseOptionTicker } from 'projects/shared-components/options-common/options.model';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto, PositionRemoved } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { daysToExpiration, isVoid } from 'projects/shared-components/utils';
import { LegsOverviewBlockBase } from './LegsOverviewBlockBase';
import { PositionArchived } from 'projects/shared-components/shell-communication/dtos/position-archived.class';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';


export class ShortOptionLegsOverviewBlock extends LegsOverviewBlockBase {

   constructor(
      public readonly header: string, 
      public readonly comboGroupId: string,
   ) {
      super();
      
      this.header = header;
      this.comboGroupId = comboGroupId;

      this.backgroundColor = 'rgb(153 102 31)';
   }

   get hasLeg(): boolean {
      return !isVoid(this.legId);
   }

   get hasErrors(): boolean {
      
      if (!this.hasLeg) {
         return false;
      }

      if (this.qty >= 0) {
         return true;
      }

      if (!this.ticker) {
         return true;
      }

      if (!this.ticker.startsWith('@')) {
         return true;
      }

      return false;
   }

   setDto(dto: PositionDto) {
      if (isVoid(dto)) {
         return;
      }
      this.legId = dto.positionId;
      this.setProperties(dto);
   }

   setPortfolioItem(item: PortfolioItemDto) {
      if (isVoid(item)) {
         return;
      }
      this.legId = item.portfolioItemId;
      this.setProperties(item);
   }

   onPositionUpdate(dto: PositionDto): boolean {

      if (!this.hasLeg) {

         if (this.comboGroupId !== dto.comboGroupId) {
            return false;
         }

         if (dto.ticker) {
            return false;
         }

         if (!dto.ticker.startsWith('@')) {
            return false;
         }

         this.setDto(dto);

         return true;
 
      } else {

         if (dto.positionId !== this.legId) {
            return false;
         }
   
         if (dto.netPosition == this.qty) {
            return false;
         }
   
         this.qty = dto.netPosition;
   
         return true;

      }
   }

   onPortfolioItemRemoved(msg: PortfolioItemRemovedDto): boolean {

      if (msg.portfolioItemId !== this.legId) {
         return false;
      }

      this.resetLegProperties();

      return true;
   }

   onPortfolioItemAdded(msg: PortfolioItemAddedDto): boolean {

      if (!msg.portfolioItem) {
         return false;
      }

      if (msg.portfolioItem.comboGroupId !== this.comboGroupId) {
         return false;
      }

      if (!msg.portfolioItem.ticker.startsWith('@')) {
         return false;
      }

      this.setPortfolioItem(msg.portfolioItem);

      return true;
   }

   onPositionArchived(msg: PositionArchived): boolean {
      
      if (isVoid(msg)) {
         return;
      }

      if (msg.positionId !== this.legId) {
         return false;
      }

      this.resetLegProperties();

      return true;
   }

   onPositionRemoved(msg: PositionRemoved): boolean {
      
      if (isVoid(msg)) {
         return;
      }

      if (msg.positionId !== this.legId) {
         return false;
      }

      this.resetLegProperties();

      return true;
   }

   private resetLegProperties() {   
      this.legId = null;
      this.ticker = null;
      this.tickerDisplayName = null;
      this.asset = null;
      this.qty = null;
      this.expirationDate = null;
      this.daysToExpiration = null;
   }

   private setProperties(item: PortfolioItemDto | PositionDto) {

      this.ticker = item.ticker;
      this.tickerDisplayName = item.tickerDisplayName;
      this.portfolioId = item.portfolioId;
      this.comboId = item.comboId;

      const expiration = item.tickerDisplayName.split(' ').slice(3).join(' ');
      const sExpDate = item.ticker.split(' ')[4];
      const diffIndays = daysToExpiration(sExpDate);
      const dte = diffIndays;

      const optTicker = parseOptionTicker(item.ticker);

      this.asset = `${optTicker.underlying} ${optTicker.type} ${optTicker.strike}`;
      this.qty = item.netPosition;
      this.expirationDate = expiration;
      this.daysToExpiration = dte;
   }

}
