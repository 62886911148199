import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { getWorkingOrdersGridOptions } from './working-orders-grid-options';
import { IWorkingOrdersComponent } from './IWorkingOrdersComponent';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { Subject } from 'rxjs';
import { WorkingOrdersIndex } from 'projects/shared-components/unspecific/working-orders-index.class';
import { DetectMethodChanges, isValidNumber, isVoid, parseOrderLegs, tickersMatch } from 'projects/shared-components/utils';
import { ComboDto, GetAvailableBucketsReply, GetWorkingOrdersSectionDataModelReply, GreeksDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ToastrService } from 'ngx-toastr';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { GetAvailableBuckets, GetWorkingOrdersSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { isNullOrUndefined } from 'util';
import { filter, map, takeUntil } from 'rxjs/operators';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';

@Component({
   selector: 'ets-automation-cp-body-working-orders-section',
   templateUrl: './working-orders-section.component.html',
   styleUrls: ['./working-orders-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingOrdersSectionComponent implements OnInit, IWorkingOrdersComponent {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService,
      private readonly _timestampsService: TimestampsService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _toastr: ToastrService,
      private readonly _shellClient: ShellClientService
   ) { }

   //
   private _grid: GridApi;

   //
   private _unsubscriber = new Subject();

   //
   private _ordersIx = new WorkingOrdersIndex()

   //
   header = 'Working Orders';

   //
   private _combo: ComboDto;
   get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   @Input()
   automationCpMode: AutomationCpMode;

   //
   gridOptions: GridOptions;

   //
   private _sectionHeight;
   get sectionHeight(): number {
      
      if (!isValidNumber(this._sectionHeight)) {

         let h = 90;

         let count = 0;
         let detailHeight = 0;

         if (this._grid) {
            
            this._grid.forEachNode((node: RowNode) => {
               
               if (node.master && node.expanded) {
                  const d: OrderDto = node.data;
                  if (d) {
                     count += d.legs.length + 4;
                     // detailHeight += node.detailNode.rowHeight * 0.7;
                  }
               }

               if (!isNullOrUndefined(node.rowTop)) {
                  count += 1;
               }
            });
         }

         count -= 1;
         if (count < 0) {
            count = 0;
         }
         const extr = count * 26;

         h += extr;
         h += detailHeight;

         this._sectionHeight = h;

      }

      return this._sectionHeight;
   }

   //
   ngOnInit(): void {
      this.gridOptions = getWorkingOrdersGridOptions.bind(this)();

      this._messageBus.of<OrderDto[]>('OrderDto')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(x => EtsConstants.strategies.manualStrategyId in x.payload),
            map(x => x.payload[EtsConstants.strategies.manualStrategyId]),
            filter(x => x.length > 0),
         )
         .subscribe(dtos => this.onOrderDto(dtos));

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onQuoteMessage(x.payload));


      this._messageBus.of<GreeksDto>('GreeksDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onGreeks(x.payload));         
   }

   //
   ngOnDestroy(): void {
      this._unsubscriber.next();
      this._unsubscriber.complete();
   }

   //
   reclaculateHeight() {
      this._sectionHeight = null;
      this._changeDetector.detectChanges();
   }

   //
   onGridReady(args: GridReadyEvent) {
      this._grid = args.api;
   }

   //
   @DetectMethodChanges()
   onSectionCollapsedChanged(ev) { 
      if (ev) {
         this._sectionHeight = 0;
      } else {
         this._sectionHeight = null;
      }
   }

   //#region IWorkingOrdersComponent 

   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   unsubscriber: Subject<void> = new Subject<void>();

   get gridApi(): GridApi {
      return this._grid;
   }

   get lastQuoteCache(): LastQuoteCacheService {
      return this._lastQuoteCache;
   }

   availableBuckets: any;

   //#endregion

   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {

      this.reset();
      
      if (isVoid(this.combo)) {
         return;
      }

      if (this._grid) {
         this._grid.showLoadingOverlay();
      }

      try {

         const qry = new GetWorkingOrdersSectionDataModel(
            this.combo.comboId
         );

         const qry2 = new GetAvailableBuckets();

         const qryAwaiter = this._shellClient
            .processQuery<GetWorkingOrdersSectionDataModelReply>(qry);
         
         const qry2Awaiter = this._shellClient
            .processQuery<GetAvailableBucketsReply>(qry2);

         const results = await Promise.all([qryAwaiter, qry2Awaiter]);

         const qry1Result = results[0];
         const qry2Result = results[1];
         

         this.availableBuckets = qry2Result;

         if (this._grid) {
            const ordersState = this._ordersIx.calculateState(qry1Result.orders);
            this._grid.setRowData(ordersState);
            this.expandGroups(this._grid);
         }

         this._sectionHeight = null;

      } catch (e) {

         this._toastr.error(
            '"Load Portfolio Data" operation completed with errors',
            'Protective Option'
         );

      } finally {

         if (this._grid) {
            this._grid.hideOverlay();
         }

      }
   }

   //
   @DetectMethodChanges()
   onOrderDto(payload: OrderDto[]): void {

      if (!this.combo) {
         return;
      }

      const filtered = payload.filter(o => o.comboId === this.combo.comboId);

      const calcState = this._ordersIx.calculateState(filtered);

      this._sectionHeight = null;

      if (this._grid) {
         this._grid.setRowData(calcState);
         this.expandGroups(this._grid);
      }
   }

   //
   reset() {
      this._ordersIx.reset();
     
      if (!this._grid) {
         return;
      }
     
      this._grid.setRowData([]);
   }

   private expandGroups(grid: GridApi) {
   
      if (!grid) {
         return;
      }
      
      grid.forEachNode(node => {
         if (!node.isExpandable()) {
            return;
         }
   
         if (!node.group) {
            return;
         }
   
         node.expanded = true;
      });
   
      setTimeout(() => {
         grid.onGroupExpandedOrCollapsed();
         this._sectionHeight = null;
         this._changeDetector.detectChanges();
      }, 1);
   
   }

   //
   private onQuoteMessage(quotes: QuoteDto[]) {

      if (!this.combo) {
         return;
      }

      this._grid.forEachLeafNode(node => {

         const order = node.data as OrderDto;

         if (!order) {
            return;
         }

         if (order.multiLegDescriptor) {

            if (!order.legs || order.legs.length === 0) {
               parseOrderLegs(order, this.availableBuckets, this.lastQuoteCache);
            }

            order.legs.forEach(leg => {
               
               const quote = quotes.find(q => tickersMatch(q.ticker, leg.ticker));

               if (!quote) {
                  return;
               }

               if (quote.ticker.indexOf('@XSP') >= 0)  {

                  leg.liveQuote = quote.mid;

               } else {

                  if (leg.qty > 0) {

                     leg.liveQuote = quote.ask;
   
                  } else if (leg.qty < 0) {
   
                     leg.liveQuote = quote.bid;
   
                  } else {
   
                     leg.liveQuote = quote.lastPx;
   
                  }
               }

            });

            const sum = order.legs
               .map(data => {

                  return { qty: data.qty, liveQuote: data.liveQuote };

               }).reduce((prev, curr) => {

                  return (curr.liveQuote * curr.qty) + prev;

               }, 0);

            order.liveQuote = isNaN(sum) ? null : sum;

         } else {

            const quote = quotes.find(q => tickersMatch(q.ticker, order.ticker));

            if (quote) {

               if (quote.ticker.indexOf('@XSP') >= 0) {

                  order.liveQuote = quote.mid;

               } else {
                  if (order.side === MarketSide.Buy) {

                     order.liveQuote = quote.ask;

                  } else if (order.qty === MarketSide.Sell) {

                     order.liveQuote = quote.bid;

                  } else {

                     order.liveQuote = quote.lastPx;
                  }
               }

            }
         }

         this._grid.applyTransaction({ update: [order] });
      });
   }

   //
   private onGreeks(x: GreeksDto): void {

      if (!this.combo) {
         return;
      }

      this._grid.forEachNode(node => {

         if (!node.expanded) {
            return;
         }

         const detailGridInfo = this._grid.getDetailGridInfo(`detail_${node.id}`);

         if (detailGridInfo) {

            const legNode = detailGridInfo.api.getRowNode(x.ticker);

            if (legNode) {
               const data = legNode.data;

               if (data) {
                  data.delta = x.delta;
                  data.gamma = x.gamma;
                  data.vega = x.vega;
                  data.theta = x.theta;

                  detailGridInfo.api.refreshCells({ rowNodes: [legNode], force: true });
               }
            }
         }

      });
   }
}
