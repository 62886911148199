import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DebitSpreadSectionComponent } from './content/debit-spread/debit-spread-section.component';
import { ProtectiveOptionSectionComponent } from './content/protective-option/protective-option-section.component';
import { AutomationCpMode } from '../model/AutomationCpMode';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { ShortOptionSectionComponent } from './content/short-option/short-option-section.component';
import { OverviewSectionComponent } from './content/overview/overview-section.component';
import { ComboDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { GlobalSettingsSectionComponent } from './content/global-settings/global-settings-section.component';
import { InterestSectionComponent } from './content/interest/interest-section.component';
import { DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';

@Component({
   selector: 'ets-automation-cp-body',
   templateUrl: './body.component.html',
   styleUrls: ['./body.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyComponent implements OnInit {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef
   ) { }

   //
   private _automationCpMode: AutomationCpMode;
   public get automationCpMode(): AutomationCpMode {
      return this._automationCpMode;
   }
   @Input()
   @DetectSetterChanges()
   public set automationCpMode(value: AutomationCpMode) {
      this._automationCpMode = value;
   }
   
   //
   private _strategy: CashFlowStrategy;
   public get strategy(): CashFlowStrategy {
      return this._strategy;
   }
   @Input()
   @DetectSetterChanges()
   public set strategy(value: CashFlowStrategy) {
      this._strategy = value;
   }

   //
   private _combo: ComboDto;
   public get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   @DetectSetterChanges()
   public set combo(value: ComboDto) {
      this._combo = value;
   }

   @ViewChild(DxScrollViewComponent)
   scroll: DxScrollViewComponent;

   //
   @ViewChild(OverviewSectionComponent) 
   overviewSection: OverviewSectionComponent;

   //
   @ViewChild(GlobalSettingsSectionComponent)
   globalSettings: GlobalSettingsSectionComponent;

   //
   @ViewChild(ShortOptionSectionComponent) 
   shortOptionSection: ShortOptionSectionComponent;

   //
   @ViewChild(DebitSpreadSectionComponent) 
   debitSpreadSection: DebitSpreadSectionComponent;

   //
   @ViewChild(ProtectiveOptionSectionComponent) 
   protectiveOptionSection: ProtectiveOptionSectionComponent;

   //
   @ViewChild(InterestSectionComponent)
   interestSection: InterestSectionComponent;

   get visibility(): 'hidden' | 'visible' {
      return isVoid(this.combo) ? 'hidden' : 'visible';
   }

   //
   ngOnInit(): void { }

   onNavigateClicked(item: string) {
      let el: Element;

      switch(item) {
         case 'Overview': 
            el = document.getElementById('overview');
            break;

         case 'Working Orders':
            el = document.getElementById('working-orders');
            break;

         case 'Strategy Messages':
            el = document.getElementById('strategy-messages');
            break;

         case 'CashFlow Tracker':
            el = document.getElementById('cashflow-tracker');
            break;

         case 'Global Settings':
            el = document.getElementById('global-settings');
            break;

         case 'Short Option':
            el = document.getElementById('short-option');
            break;
         
         case 'Debit Spread':
            el = document.getElementById('debit-spread');
            break;

         case 'Protective Option':
            el = document.getElementById('protective-option');
            break;

         case 'Portfolio':
            el = document.getElementById('portfolio');
            break;
         
         case 'Interest':
            el = document.getElementById('interest');
            break;
         
         case 'Position Sizing':
            break;
      }

      this.scrollTo(el);
   }

   //
   @DetectMethodChanges()
   private scrollTo(element: Element) {
      if (isVoid(element)) {
         return;
      }

      this.scroll.instance.scrollToElement(element);
   }
}
