<div class="combo-pnls">
   <div class="session">
         
      <div class="label">
         <span>
            {{this.sessionPnlSectionHeader}}
         </span>
      </div>
      
      <div class="value">
         <span [ngClass]="this.sessionPnl.cssClass">
            {{this.sessionPnl.viewValue}}
         </span>
      </div>

   </div>

   <div class="accumulated">
      
      <div class="label">
         <span>
            {{this.accumulatedPnlSectionHeader}}
         </span>
      </div>
      
      <div class="value">
         <span [ngClass]="this.accumulatedPnl.cssClass">
            {{this.accumulatedPnl.viewValue}}
         </span>
      </div>

   </div>
</div>