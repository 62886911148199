import { NgModule } from '@angular/core';
import { SyncOverrideDialogComponent } from './sync-override-dialog.component';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { SyncOverrideHistoryComponent } from './history/sync-override-history.component';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from '../devextreme.module';

@NgModule({
   imports: [
      CommonModule,
      AngularSplitModule,
      AgGridModule.withComponents([]),
      DevExtremeModule
   ],
   exports: [SyncOverrideDialogComponent, SyncOverrideHistoryComponent],
   declarations: [SyncOverrideDialogComponent, SyncOverrideHistoryComponent],
   providers: [],
})
export class SyncOverrideDialogModule { }
