import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';

/**
 * @returns OrderDto[]
 */
export class GetRiskManagerRuleViolationOrders implements ProtocolQuery {
  constructor(public readonly violationId?: string) {}

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetRiskManagerRuleViolationOrders`;
  }
}
