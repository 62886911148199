import { NgModule } from '@angular/core';
import { ArchivedPositionsComponent } from './archived-positions.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { ArchivedPositionsGroupRowInnerRenderer } from './archived-group-row-cell-renderer';
import { DevExtremeModule } from 'projects/shared-components/devextreme.module';

@NgModule({
  imports: [
    AgGridModule.withComponents([ArchivedPositionsGroupRowInnerRenderer]),
    DevExtremeModule,
    CommonModule
  ],
  exports: [ArchivedPositionsComponent],
  declarations: [
    ArchivedPositionsComponent,
    ArchivedPositionsGroupRowInnerRenderer
  ],
  providers: []
})
export class ArchivedPositionsModule { }
