import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { ToastrService } from 'ngx-toastr';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { takeUntil } from 'rxjs/operators';
import { MarketSide } from 'projects/shared-components/trading-model/market-side.enum';
import { OrderType } from 'projects/shared-components/trading-model/order-type.enum';
import { Subject } from 'rxjs';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { SendManualOrder } from 'projects/shared-components/shell-communication/operations/manual-trading/send-manual-order.class';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import { SymbolPickerDialogComponent } from 'projects/shared-components/symbol-picker/symbol-picker-dialog.component';
import { GetAvailableBuckets } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ComboCreatedDto, ComboDto, ComboGroupCreatedDto, ComboGroupDto, GetAvailableBucketsReply, GroupDeletedDto, PortfolioCreatedDto, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { ArrayWrapper, DetectMethodChanges, DetectSetterChanges, DxValueChanged, isLimitOrder, isMarketOrder, isNullOrUndefined, isTruthy } from 'projects/shared-components/utils';
import { ManualTradingBackendService } from 'projects/shared-components/manual-trading/manual-trading-backend.service';
import { OrderDuration } from 'projects/shared-components/multi-trade-pad/multi-trade-pad.model';


@Component({
   selector: 'ets-trading-pad',
   templateUrl: './trading-pad.component.html',
   styleUrls: ['./trading-pad.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class TradingPadComponent implements OnInit, OnDestroy, AfterViewInit {

   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _tradingInstrumentService: TradingInstrumentsService,
      private readonly _sessionService: SessionService,
      private readonly _manualTradingService: ManualTradingBackendService
   ) {

   }

   private _selectedSymbol: TradingInstrument;

   private _unsubscriber = new Subject();

   private _availableBuckets: GetAvailableBucketsReply = {
      portfolios: [],
      combos: [],
      comboGroups: []
   };

   //

   availableOrderTypes = [
      {
         key: 'Market',
         value: OrderType.Market
      },
      {
         key: 'Market on Open',
         value: OrderType.MarketOnOpen
      },
      {
         key: 'Limit',
         value: OrderType.Limit
      },
      {
         key: 'Limit on Open',
         value: OrderType.LimitOnOpen
      },
      {
         key: 'Stop',
         value: OrderType.Stop
      }
   ];

   //

   availableOrderDurations = [
      {
         key: 'Day',
         value: OrderDuration.DAY
      },
      {
         key: 'GTC',
         value: OrderDuration.GTC
      }
   ];


   private _isSymbolPickerVisible: boolean;
   get isSymbolPickerVisible(): boolean {
      return this._isSymbolPickerVisible;
   }

   @DetectSetterChanges()
   set isSymbolPickerVisible(v: boolean) {
      this._isSymbolPickerVisible = v;
   }

   private _selectedTerminal: TerminalDto;
   get selectedTerminal(): TerminalDto { return this._selectedTerminal; }

   @DetectSetterChanges()
   set selectedTerminal(v: TerminalDto) {
      this._selectedTerminal = v;
   }

   private _selectedAccount: AccountDto;
   get selectedAccount(): AccountDto { return this._selectedAccount; }

   @DetectSetterChanges()
   set selectedAccount(v: AccountDto) {
      this._selectedAccount = v;
   }

   private _selectedOrderQty: number;
   get selectedOrderQty(): number { return this._selectedOrderQty; }

   @DetectSetterChanges()
   set selectedOrderQty(v: number) {
      this._selectedOrderQty = v;
   }

   //

   private _selectedPortfolio: PortfolioDto;
   get selectedPortfolio(): PortfolioDto { return this._selectedPortfolio; }

   @DetectSetterChanges()
   set selectedPortfolio(v: PortfolioDto) {
      this._selectedPortfolio = v;
   }

   //

   private _selectedCombo: ComboDto;
   get selectedCombo(): ComboDto { return this._selectedCombo; }

   @DetectSetterChanges()
   set selectedCombo(value: ComboDto) {
      this._selectedCombo = value;
   }

   //

   private _selectedComboGroup: ComboGroupDto;
   get selectedComboGroup(): ComboGroupDto { return this._selectedComboGroup; }

   @DetectSetterChanges()
   set selectedComboGroup(value: ComboGroupDto) {
      this._selectedComboGroup = value;
   }

   //

   private readonly _availableAccounts: ArrayWrapper<AccountDto>
      = new ArrayWrapper<AccountDto>('accountCode', 'accountId');
   
   get availableAccounts(): AccountDto[] { 
      return this._availableAccounts.data; 
   }

   //

   private readonly _availableTerminals: ArrayWrapper<TerminalDto>
      = new ArrayWrapper<TerminalDto>('displayName', 'terminalId');
   
   get availableTerminals(): TerminalDto[] { 
      return this._availableTerminals.data; 
   }

   //

   private readonly _portfoliosByTerminal: ArrayWrapper<PortfolioDto>
      = new ArrayWrapper<PortfolioDto>('portfolioName', 'portfolioId');

   get portfoliosByTerminal(): PortfolioDto[] { 
      return this._portfoliosByTerminal.data; 
   }

   //

   private readonly _combosByPortfolio: ArrayWrapper<ComboDto>
      = new ArrayWrapper<ComboDto>('comboName', 'comboId');

   get combosByPortfolio(): ComboDto[] {
      return this._combosByPortfolio.data; 
   }

   //

   private readonly _comboGroupsByCombo: ArrayWrapper<ComboGroupDto>
      = new ArrayWrapper<ComboGroupDto>('comboGroupName', 'comboGroupId');
   
   get comboGroupsByCombo(): ComboGroupDto[] { 
      return this._comboGroupsByCombo.data; 
   }

   //

   private _bid: string;
   get bid(): string { return this._bid; }

   @DetectSetterChanges()
   set bid(v: string) {
      this._bid = v;
   }

   //

   private _last: string;
   get last(): string { return this._last; }

   @DetectSetterChanges()
   set last(v: string) {
      this._last = v;
   }

   //

   private _ask: string;
   get ask(): string { return this._ask; }

   @DetectSetterChanges()
   set ask(v: string) {
      this._ask = v;
   }

   //

   private _isOrderConfirmationPending: boolean;
   get isOrderConfirmationPending(): boolean { return this._isOrderConfirmationPending; }

   @DetectSetterChanges()
   set isOrderConfirmationPending(val: boolean) { this._isOrderConfirmationPending = val; }

   //

   @ViewChild(SymbolPickerDialogComponent, { static: false }) symbolPickerDialog: SymbolPickerDialogComponent;

   //

   get selectedSymbol(): TradingInstrument {
      return this._selectedSymbol;
   }

   @DetectSetterChanges()
   set selectedSymbol(value: TradingInstrument) {

      this._selectedSymbol = value;

      this.bid = this.ask = this.last = '--';

      this.selectedOrderPrice = null;

      if (value) {

         const lq = this._lastQuoteCache.subscribeTicker(value.ticker);
         if (!isNullOrUndefined(lq)) {
            this.onQuote([lq]);
         }

      }
   }

   //

   get canTrade(): boolean {

      if (!this.tradeCommonPrerequisitesPresent()) {
         return false;
      }

      if (!this.selectedOrderType) {
         return false;
      }

      if (this.isOrderConfirmationPending) {
         return false;
      }

      if (!isMarketOrder(this.selectedOrderType)) {
         return this.canPlaceLimitOrStop;
      }

      if (isMarketOrder(this.selectedOrderType)) {
         return this.canPlaceMarket;
      }

      return false;
   }

   //

   get buyButtonText(): string {
      return `${this.ask}`;
   }

   //

   get sellButtonText(): string {
      return `${this.bid}`;
   }

   //

   private _selectedOrderType: OrderType;
   get selectedOrderType(): OrderType {
      return this._selectedOrderType;
   }

   @DetectSetterChanges()
   set selectedOrderType(value: OrderType) {
      
      this._selectedOrderType = value;

      if (!isTruthy(this.selectedSymbol)) {
         return;
      }

      if (value !== OrderType.Market) {

         this.bid = this.ask = !isNullOrUndefined(this.selectedOrderPrice)
            ? this.selectedOrderPrice.toString()
            : '--';

      } else {

         this.selectedOrderPrice = undefined;
         this.bid = this.ask = '--';

      }

      const lastQuote = this._lastQuoteCache.getLastQuote(this.selectedSymbol.ticker);

      if (lastQuote) {
         
         this.onQuote([lastQuote]);

         if (value !== OrderType.Market) {

            this.selectedOrderPrice = lastQuote.lastPx;
         }
      }
   }

   //

   private _selectedOrderDuration: OrderDuration;
   get selectedOrderDuration(): OrderDuration { return this._selectedOrderDuration; }

   @DetectSetterChanges()
   set selectedOrderDuration(value: OrderDuration) {
      this._selectedOrderDuration = value;
   }

   //

   private _selectedOrderPrice: number;
   get selectedOrderPrice(): number {
      return this._selectedOrderPrice;
   }

   @DetectSetterChanges()
   set selectedOrderPrice(value: number) {
      this._selectedOrderPrice = value;
      this.bid = this.ask = !!value ? value.toString() : '--';
   }


   async ngOnInit(): Promise<void> {

      const terminals = this._sessionService.loginResult.availableTerminals.filter(terminal => !terminal.isProxy);
      this._availableTerminals.setData(terminals);

      const accounts = this._sessionService.loginResult.availableAccounts;
      this._availableAccounts.setData(accounts);

      this.bid = this.ask = this.last = '--';

      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onQuote(msg.payload));

      this._messageBus
         .of<PortfolioCreatedDto>('PortfolioCreatedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dto => this.onPortfolioCreatedMessage(dto.payload));

      this._messageBus
         .of<ComboCreatedDto>('ComboCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onComboCreatedMessage(msg.payload));

      this._messageBus
         .of<ComboGroupCreatedDto>('ComboGroupCreatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onComboGroupCreatedMessage(msg.payload));

      this._messageBus
         .of<GroupDeletedDto>('GroupDeletedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onGroupDeletedMessage(msg.payload));

      this.selectedOrderDuration = OrderDuration.DAY;
      this.selectedOrderType = OrderType.Limit;

      await this.getAvailablePortfolios();
   }

   //

   @DetectMethodChanges()
   private onPortfolioCreatedMessage(dto: PortfolioCreatedDto): void {
      
      this._availableBuckets.portfolios.push(dto.portfolio);
      
      if (this.selectedTerminal) {

         if (this.selectedTerminal.terminalId === dto.portfolio.terminalId) {
            
            this._portfoliosByTerminal.insert(dto.portfolio);
         }
      }
   }

   //

   @DetectMethodChanges()
   private onComboCreatedMessage(msg: ComboCreatedDto): void {
      
      this._availableBuckets.combos.push(msg.combo);

      if (this.selectedPortfolio) {
         
         if (this.selectedPortfolio.portfolioId === msg.combo.portfolioId) {
            
            this._combosByPortfolio.insert(msg.combo);
         }
      } 
   }
   
   //

   @DetectMethodChanges()
   private onComboGroupCreatedMessage(msg: ComboGroupCreatedDto): void {
      this._availableBuckets.comboGroups.push(msg.comboGroup);

      if (this.selectedCombo) {
         
         if (this.selectedCombo.comboId === msg.comboGroup.comboId) {
            
            this._comboGroupsByCombo.insert(msg.comboGroup);
         }
      }
   }

   //

   @DetectMethodChanges()
   private onGroupDeletedMessage(dto: GroupDeletedDto): void {
      
      switch (dto.groupType) {
         case 'combo':
            {
               const ix = this._availableBuckets.combos.findIndex(x => x.comboId === dto.comboId);
               
               if (ix >= 0) {
                  
                  this._availableBuckets.combos.splice(ix, 1);

               }

               this._combosByPortfolio.remove(dto.comboId);
            }
            break;

         case 'group':
            {
               const ix = this._availableBuckets.comboGroups.findIndex(x => x.comboGroupId === dto.comboGroupId);
               
               if (ix >= 0) {
                  this._availableBuckets.comboGroups.splice(ix, 1);
               }

               this._comboGroupsByCombo.remove(dto.comboGroupId);
            }
            break;

         case 'portfolio':
            {
               const ix = this._availableBuckets.portfolios.findIndex(x => x.portfolioId === dto.portfolioId);
               
               if (ix >= 0) {
                  this._availableBuckets.portfolios.splice(ix, 1);
               }

               this._portfoliosByTerminal.remove(dto.portfolioId);               
            }
            break;

         default:
            return;
      }
   }

   //

   ngAfterViewInit() {
      this._changeDetector.detach();
   }

   //

   ngOnDestroy(): void {
      this._unsubscriber.next();
      this._unsubscriber.complete();
   }

   //

   @DetectMethodChanges()
   showSymbolPicker() {
      this.isSymbolPickerVisible = true;
   }

   //

   @DetectMethodChanges()
   onSymbolSelected(ev) {
      this.selectedSymbol = ev;
   }

   //

   @DetectMethodChanges()
   onSymbolPickerClosed() {
      this.isSymbolPickerVisible = false;
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async placeOrder(side: MarketSide): Promise<void> {

      if (!isMarketOrder(this.selectedOrderType)) {

         if (isLimitOrder(this.selectedOrderType)) {

            if (!this.limitToMarketGuard(side)) {
               
               if (this.selectedOrderType === OrderType.Limit) {
                  const message = 'Not allowed! This limit order will be executed as market';
                  this._toastr.warning(message);
                  return;
               }
            }
         } else if (this.selectedOrderType === OrderType.Stop) {
            
            if (!this.stopToMarketGuard(side)) {

               const message = 'Not allowed! This stop order will be executed as market';
               this._toastr.warning(message);

               return;
            }
         }
      }

      const cmd = new SendManualOrder(
         this.selectedTerminal.terminalId,
         this.selectedSymbol.ticker,
         this.selectedOrderType,
         this.selectedOrderDuration,
         side,
         this.selectedOrderQty,
         this.selectedOrderPrice,
         this.selectedAccount?.accountId,
         this.selectedPortfolio?.portfolioId,
         this.selectedCombo?.comboId,
         this.selectedComboGroup?.comboGroupId
      );

      this.isOrderConfirmationPending = true;

      try {

         await this._manualTradingService.sendManualOrder(cmd);

      } catch (e) {

         const errorMessage = `"Send Order" operation completed with errors`;
         console.error(e);
         this._toastr.error(errorMessage);

      } finally {

         this.isOrderConfirmationPending = false;

      }
   }

   //

   get canPlaceLimitOrStop(): boolean {
      const retval =
         [OrderType.Limit, OrderType.LimitOnOpen, OrderType.Stop].includes(this.selectedOrderType)
         && isTruthy(this.selectedOrderPrice)
         && this.selectedOrderPrice > 0
         && this.tradeCommonPrerequisitesPresent();

      return retval;
   }

   //

   get canPlaceMarket(): boolean {

      const retval = isMarketOrder(this.selectedOrderType)
         && this.tradeCommonPrerequisitesPresent();

      return retval;
   }

   //

   tradeCommonPrerequisitesPresent(): boolean {
      const retval = this.selectedOrderQty > 0
         && isTruthy(this.selectedOrderDuration)
         && isTruthy(this.selectedSymbol)
         && isTruthy(this.selectedTerminal)
         && (this.bid !== '--' && this.ask !== '--');

      return retval;
   }

   //

   @DetectMethodChanges()
   onTerminalChanged(args: DxValueChanged<TerminalDto>) {

      const terminal = args.value;

      if (isNullOrUndefined(terminal)) {

         this._portfoliosByTerminal.clear();
         this.selectedPortfolio = undefined;

         return;
      }

      const filtered = this._availableBuckets.portfolios.filter(x => x.terminalId === terminal.terminalId);

      this.selectedPortfolio = undefined;
      this.selectedCombo = undefined;
      this.selectedComboGroup = undefined;

      this._portfoliosByTerminal.setData(filtered);
   }
   //

   @DetectMethodChanges()
   onPortfolioChanged(args: DxValueChanged<PortfolioDto>) {

      const portfolio = args.value;

      if (isNullOrUndefined(portfolio)) {

         this._combosByPortfolio.clear();
         this.selectedCombo = undefined;

         return;
      }

      const combosByPortfolio = this._availableBuckets.combos.filter(x => x.portfolioId === portfolio.portfolioId);

      this.selectedCombo = undefined;
      this.selectedComboGroup = undefined;

      this._combosByPortfolio.setData(combosByPortfolio);
   }

   //

   @DetectMethodChanges()
   onComboChanged(args: DxValueChanged<ComboDto>) {

      const combo = args.value;

      if (isNullOrUndefined(combo)) {

         this._comboGroupsByCombo.clear();
         this.selectedComboGroup = undefined;

         return;
      }

      const comboGroupsByCombo = this._availableBuckets.comboGroups.filter(x => x.comboId === combo.comboId);
      
      this.selectedComboGroup = undefined;
      
      this._comboGroupsByCombo.setData(comboGroupsByCombo);

   }

   //

   @DetectMethodChanges({ isAsync: true })
   async onBuyClicked(): Promise<void> {

      await this.placeOrder(MarketSide.Buy);

   }

   //

   @DetectMethodChanges({ isAsync: true })
   async onSellClicked(): Promise<void> {

      await this.placeOrder(MarketSide.Sell);

   }

   //

   private async getAvailablePortfolios(): Promise<void> {

      const qry = new GetAvailableBuckets();

      const reply = await this._shellClient.processQuery<GetAvailableBucketsReply>(qry);

      this._availableBuckets = reply;
   }

   //

   @DetectMethodChanges()
   private onQuote(dtos: QuoteDto[]) {
      if (!this.selectedSymbol) {
         return;
      }

      const filtered = dtos.filter(x => !x.isLevel2 && this.selectedSymbol.ticker === x.ticker);

      if (!filtered.length) {
         return;
      }

      if (!this.selectedOrderType) {
         return;
      }

      const quoteDto = filtered[0];
      this.last = quoteDto.lastPx.toString();

      if (this.selectedOrderType !== OrderType.Market) {

         if (!this.selectedOrderPrice) {
            this.selectedOrderPrice = quoteDto.lastPx;
         }

         return;
      }

      const askPrice = quoteDto.ask;
      const bidPrice = quoteDto.bid;
      this.ask = askPrice.toString();
      this.bid = bidPrice.toString();

      const instrument = this._tradingInstrumentService.getInstrumentByTicker(quoteDto.ticker);
      if (instrument) {
         this.last = ((askPrice - bidPrice) || 0).toFixed(instrument.precision);
      }

   }

   //

   private limitToMarketGuard(side: MarketSide): boolean {
      let retVal = false;
      const last = parseFloat(this.last);
      if (isNaN(last)) {
         return retVal;
      }

      if (side === MarketSide.Buy) {
         retVal = last >= this.selectedOrderPrice;
      } else if (side === MarketSide.Sell) {
         retVal = last <= this.selectedOrderPrice;
      }

      return retVal;
   }

   //

   private stopToMarketGuard(side: MarketSide): boolean {
      let retVal = false;
      const last = parseFloat(this.last);
      if (isNaN(last)) {
         return retVal;
      }

      if (side === MarketSide.Sell) {
         retVal = last >= this.selectedOrderPrice;
      } else if (side === MarketSide.Buy) {
         retVal = last <= this.selectedOrderPrice;
      }

      return retVal;
   }

}
