import {OrderToRegroupWrapper} from "./OrderToRegroupWrapper";
import {OrderToCopy} from "../adjustment-pricing-grid/services/copy-orders-to.service";

export class OrdersGroupToRegroup {
    constructor(
        public readonly title: OrderToCopy,
        public readonly orders: OrderToRegroupWrapper[]
    ) {
        this.orders.forEach(x => x.group = this);
    }
}

