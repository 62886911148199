import { FirstDataRenderedEvent, GetContextMenuItemsParams, GridOptions, RowClickedEvent, RowSelectedEvent } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate, defaultMoneyCellDefinition, GetDetailRowDataParams } from 'projects/shared-components/ag-grid-contrib';
import { SessionHistoryStrategyInfoDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { isNullOrUndefined } from 'util';
import { SessionHistoryComponent } from './session-history.component';

export function getSessionHistoryPortfoliosGridOptions(this: SessionHistoryComponent): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Portfolio',
            field: 'portfolioName',
         },

         Object.assign(
            {
               headerName: 'S. P&L',
               field: 'sessionPnL'
            },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accumulatedPnL',
            },
            defaultMoneyCellDefinition
         ),
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: (args) => this.onPortfoliosGridReady(args),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.api.sizeColumnsToFit();
      },

      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node || args.node.group || !args.node.data) {
            return;
         }

         this.onPortfolioRowSelected(args.node.data);
      },

      getRowNodeId: (rowData) => {
         return rowData.portfolioId;
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit
            },
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   };
}

export function getSessionHistoryCombosGridOptions(this: SessionHistoryComponent): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Combo',
            field: 'comboName',
         },

         Object.assign(
            {
               headerName: 'S. P&L',
               field: 'sessionPnL'
            },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accumulatedPnL',
            },
            defaultMoneyCellDefinition
         ),
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: (args) => this.onCombosGridReady(args),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => args.api.sizeColumnsToFit(),

      getRowNodeId: (rowData) => {
         return rowData.comboId;
      },

      onRowClicked: (args: RowClickedEvent) => {
         
         if (!args.node || args.node.group || !args.node.data) {
            return;
         }

         this.onComboRowSelected(args.node.data);
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit
            },
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   };
}

export function getSessionHistoryComboGroupsGridOptions(this: SessionHistoryComponent): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Combo Group',
            field: 'comboGroupName',
         },

         Object.assign(
            {
               headerName: 'S. P&L',
               field: 'sessionPnL'
            },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accumulatedPnL',
            },
            defaultMoneyCellDefinition
         ),
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      onGridReady: (args) => this.onComboGroupsGridReady(args),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.api.sizeColumnsToFit();
      },

      getRowNodeId: (rowData) => {
         return rowData.comboGroupId;
      },

      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node || args.node.group || !args.node.data) {
            return;
         }
         this.onComboGroupRowSelected(args.node.data);
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit
            },
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   };
}

export function getSessionHistoryBucketItemsGridOptions(this: SessionHistoryComponent): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Bucket Item',
            field: 'displayName',
            filter: 'agTextColumnFilter',
            cellRenderer: 'agGroupCellRenderer',
         },

         {
            headerName: 'Type',
            field: 'itemType',
            filter: true
         },

         Object.assign(
            {
               headerName: 'Session P&L',
               field: 'sessionPnL',
               aggFunc: 'sum'
            },
            defaultMoneyCellDefinition
         ),

         Object.assign(
            {
               headerName: 'Acc. P&L',
               field: 'accumulatedPnL',
               aggFunc: 'sum'
            },
            defaultMoneyCellDefinition
         ),

         {
            headerName: 'Portfolio',
            field: 'portfolioName',
         },

         {
            headerName: 'Combo',
            field: 'comboName'
         },

         {
            headerName: 'Combo Group',
            field: 'comboGroupName'
         },
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      masterDetail: true,

      detailRowAutoHeight: true,

      detailCellRendererParams: {

         detailGridOptions: {

            rowData: [],

            defaultColDef: centeredColumnDef,

            columnDefs: [
               {
                  headerName: 'Bucket Item',
                  field: 'displayName',
                  filter: 'agTextColumnFilter',
                  cellRenderer: 'agGroupCellRenderer',
               },

               {
                  headerName: 'Type',
                  field: 'itemType',
                  filter: true
               },
      
               Object.assign(
                  {
                     headerName: 'Session P&L',
                     field: 'sessionPnL',
                     aggFunc: 'sum'
                  },
                  defaultMoneyCellDefinition
               ),
      
               Object.assign(
                  {
                     headerName: 'Acc. P&L',
                     field: 'accumulatedPnL',
                     aggFunc: 'sum'
                  },
                  defaultMoneyCellDefinition
               ),
            ],

            rowSelection: 'single',

            immutableData: true,

            rowClass: 'ets-text-centered',

            onFirstDataRendered: (args: FirstDataRenderedEvent) => {
               args.columnApi.autoSizeAllColumns();
            },
      
            getRowNodeId: (rowData: SessionHistoryStrategyInfoDto) => {
               return rowData.sessionHistoryStrategyInfoId + '';
            },

            onRowSelected: (args: RowSelectedEvent) => {
               if (!args.node || args.node.group || !args.node.data) {
                  return;
               } 
      
               if (!args.node.isSelected())  {
                  return;
               }
      
               this.onBucketItemRowSelected(args.node.data);
            },
         },

         getDetailRowData: (params: GetDetailRowDataParams) => {
            const records = this.getInnerStrategiesRecords(params.data);
            params.successCallback(records);
         }
      },

      isRowMaster: (dataItem) => dataItem.isDisposition,

      onGridReady: (args) => this.onBucketItemsGridReady(args),

      onFirstDataRendered: (args: FirstDataRenderedEvent) => {
         args.columnApi.autoSizeAllColumns();
      },

      getRowNodeId: (rowData: SessionHistoryStrategyInfoDto) => {
         return rowData.sessionHistoryStrategyInfoId + '';
      },

      onRowSelected: (args: RowSelectedEvent) => {
         if (!args.node || args.node.group || !args.node.data) {
            return;
         } 

         if (!args.node.isSelected())  {
            return;
         }

         this.onBucketItemRowSelected(args.node.data);
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Size To Fit',
               action: () => params.api.sizeColumnsToFit
            },
            'autoSizeAll',
            'copy',
            'export'
         ];
      }
   };
}

