<div [id]="this.uniqueId" class="host-menu">

   <dx-button text="Menu" width="100%" height="100%" class="main-button" (onClick)="onActionButtonClick($event)">
   </dx-button>

   <div  class="overlay"
         [hidden]="!isOverlayVisible"
         [style.top.px]="this.overlayTop"
         [style.left.px]="this.overlayLeft"
         [style.width.px]="this.overlayWidth"
         >
      <div *ngFor="let button of this.menuItems">
         <dx-button  class="button"
                     [type]="button.buttonType"
                     [text]="button.header"
                     [visible]="button.isVisible"
                     (onClick)="button.action()"
         ></dx-button>
      </div>
   </div>

</div>

<ets-session-history-popup #sessionHistory></ets-session-history-popup>

<ets-risk-management #riskManagement></ets-risk-management>

<!-- <ets-alerts #alerts></ets-alerts> -->

<ets-simex-settings #simex></ets-simex-settings>

<ets-strategy-commander #commander></ets-strategy-commander>

<ets-unaccounted-data #unaccounted></ets-unaccounted-data>

<ets-clear-trading-data #clearData></ets-clear-trading-data>

<ets-original-flagship-report #ofsr></ets-original-flagship-report>

<ets-new-flagship-report #nfsr></ets-new-flagship-report>

<ets-disposition-report #dfsr></ets-disposition-report>

<ets-archived-positions-component #archived></ets-archived-positions-component>

<!-- <ets-triggers #triggers></ets-triggers> -->

<ets-app-settings #appSettings></ets-app-settings>

<ets-strategy-session-history #strategyHistory></ets-strategy-session-history>

<ets-rollover-scheduler #rolloverScheduler></ets-rollover-scheduler>

<ets-sync-override-dialog #syncOverride></ets-sync-override-dialog>

<ets-apg-defaults-dialog></ets-apg-defaults-dialog>