import {AlgoDescriptionBuilderBase} from '../algo-description-builder-base.class';
import {StrategyParameters} from '../../strategies/strategy-parameters.enum';
import { TradeTheLineMode, TradeTheLineChartCloseMode } from 'projects/shared-components/edit-strategy-dialog/parameters-controls/trend/trade-the-line/tradetheline-algo-parameters-control';

export class TradeTheLineAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {

  getSymbolPropertyNames(): string[] {
    return ['symbol'];
  }
  
  getAlgoName(parameters: StrategyParameters): string {
    return 'Trade The Line';
  } 
  
  getDescription(parameters: StrategyParameters): string {
    let res = '';
    const defaultValue = 'N/A';
    let symbol: string = parameters.symbol || defaultValue;

    if (symbol !== defaultValue) {
      symbol = this.DisplayNameService.getDisplayNameForTicker(symbol);
    }

    res += `[${symbol}]: `;
    
    res += `Mode-${TradeTheLineMode[parameters.mode]}, `;

    res += `M-${parameters.multiplier}, `;

    res += `TF-${[parameters.charttimeframe]}, `;

    res += `Close Mode-${TradeTheLineChartCloseMode[parameters.chartclosemode]}`;

    if (parameters.disasterpricebull) {
      res += `, DP(Bull)-${parameters.disasterpricebull}`;
    }

    if (parameters.disasterpricebear) {
      res += `, DP(Bear)-${parameters.disasterpricebear}`;
    }

    return res;
  }

}
