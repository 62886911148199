import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged } from 'projects/shared-components/utils';


@Component({
   selector: 'ets-dynamic-offsets-condition',
   template: `
<div class="container">
   <dx-select-box 
      [disabled]="this.disabled"
      [items]="this.values"
      [(value)]="this.value"
      [showClearButton]="true"
      (onValueChanged)="this.onValueChanged($event)">
   </dx-select-box>
   
</div>
   `,
   styles: [`
   `],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistDynamicOffsetsConditionComponent implements OnInit {
   constructor(
      private _changeDetector: ChangeDetectorRef
   ) { }
   private _oldValue: string;

   @Input() disabled: boolean;

   values: string[] = [
      'Yes',
      'No'
   ];

   private _value: string;
   public get value(): string {
      return this._value;
   }
   @Input()
   @DetectSetterChanges()
   public set value(value: string) {
      this._value = value;
   }

   @Output()
   valueChange = new EventEmitter<string>();

   @Output() 
   valueChanged = new EventEmitter<DxValueChanged<string>>();

   @DetectMethodChanges()
   ngOnInit(): void { }

   @DetectMethodChanges()
   onValueChanged(arg: DxValueChanged<string>) {
      this.valueChange.emit(arg.value);
      this.valueChanged.emit(arg);
   }

   onOpened() {
      this._oldValue = this.value;
   }

   onClosed() {
      if (this.value === this._oldValue) {
         return;
      }
      this.valueChange.emit(this.value);
      this.valueChanged.emit({value: this.value, event: 'ets'});
   }

   private reset() {
      this.value = null;
   }
}
