import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashFlowTrackingComponent } from './cashflow-tracking.component';
import { AgGridModule } from 'ag-grid-angular';
import { DevExtremeModule } from '../devextreme.module';
import { AdjustmentPricingGridModule } from '../adjustment-pricing-grid/adjustment-pricing-grid.module';
import { CashFlowTrackingPanelComponent } from './cashflow-tracking-panel.component';
import { CashFlowTrackingInfoCellRendererComponent } from './cashflow-tracking-info-cell-renderer.component';

@NgModule({
   declarations: [
      CashFlowTrackingComponent,
      CashFlowTrackingPanelComponent,
      CashFlowTrackingInfoCellRendererComponent
   ],
   imports: [ 
      CommonModule,
      AgGridModule.withComponents([CashFlowTrackingInfoCellRendererComponent]),
      DevExtremeModule,
      AdjustmentPricingGridModule
   ],
   exports: [
      CashFlowTrackingComponent,
      CashFlowTrackingPanelComponent
   ],
   providers: [],
})
export class CashFlowTrackingModule {} 
