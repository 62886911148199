import {NgModule} from '@angular/core';
import {EtsStrategyMessagesModule} from '../strategy-messages/strategy-messages.module';
import {ManualTradingModule} from '../manual-trading/manual-trading.module';
import {EtsQuoteBoardModule} from '../quote-board/quote-board.module';
import {TradingSystemsViewModule} from '../trading-systems/trading-systems-view.module';
import {StrategyOrdersAndTradesModule} from '../strategy-orders-and-trades/strategy-orders-and-trades.module';
import {AggregatedPositionsModule} from '../aggregated-positions/aggregated-positions.module';
import {EtsPnLChartModule} from '../pnl-chart/pnl-chart.module';
import {EtsPriceChartModule} from '../price-chart/price-chart.module';
import {WorkspaceComponent} from './workspace.component';
import {EtsTabHeaderComponent} from './header/tabs-panel/tab-header/tab-header.component';
import {EtsTabsPanelComponent} from './header/tabs-panel/tabs-panel.component';
import {EtsWorkspaceToolbarComponent} from './header/toolbar/workspace-toolbar.component';
import {EtsWorkspaceHeaderComponent} from './header/workspace-header.component';
import {EtsContentPlaceholderDirective} from './content-placeholder.directive';
import {EtsTabsMenuComponent} from './header/tabs-menu/tabs-menu.component';
import {WorkspacePanelsMenuComponent} from './panels-menu/workspace-panels-menu.component';
import {WorkspacePanelsMenuService} from './panels-menu/workspace-panels-menu.service';
import {CommonModule} from '@angular/common';
import {WorkspaceSecurityContextService} from './workspace-security-context.service';
import {StrategyIssuesModule} from '../strategy-issues/strategy-issues.module';
import {EtsHpDatasetsModule} from '../hp-datasets/hp-datasets.module';
import {EtsOpenPositionChartModule} from '../open-position-chart/open-position-chart.module';
import {PanelsSortComponent} from './panels-sort/panels-sort.component';
import {PositionSizingReportModule} from '../position-sizing-report/position-sizing-report.module';
import {StrategyTriggersModule} from '../strategy-triggers/strategy-triggers.module';
import {OptionChainModule} from '../option-chain/option-chain.module';
import {PortfoliosModule} from '../portfolios/portfolios.module';
import {MultiTradePadModule} from '../multi-trade-pad/multi-trade-pad.module';
import {LedgerModule} from 'projects/webtrader/src/app/ledger/ledger.module';
import {OptionsPricingPadModule} from '../options-pricing-pad/options-pricing-pad.module';
import {OptionsPricingGridModule} from '../options-pricing-grid/options-pricing-grid.module';
import {StrategyHedgedPositionsModule} from '../strategy-hedged-positions/strategy-hedged-positions.module';
import {WatchlistModule} from '../watchlist/watchlist.module';
import {SessionHistoryModule} from 'projects/webtrader/src/app/session-history/session-history.module';
import {DevExtremeModule} from '../devextreme.module';
import {ParametersPanelModule} from '../parameters-panel/parameters-panel.module';
import {MarginRequirementsModule} from '../margin-requirements/margin-requirements.module';
import {EtsShellMessagesModule} from '../shell-messages/shell-messages.module';
import {TestPanelModule} from '../test-panel/test-panel.module';
import {AdjustmentControlPanelModule} from '../adjustment-control-panel/adjustment-control-panel.module';
import {AdjustmentPricingGridModule} from '../adjustment-pricing-grid/adjustment-pricing-grid.module';
import {CashFlowTrackingModule} from '../cashflow-tracking/cashflow-tracking.module';
import {AutomationCpModule} from '../automation-cp/automation-cp.module';
import {ChecklistEditorModule} from '../checklist-editor/checklist-editor.module';
import {ResourceEditorModule} from "../resource-editor/resource-editor.module";
import {HedgingGridModule} from "../hedging-grid/hedging-grid.module";
import {PackageComparisonModule} from "../package-comparison/package-comparison.module";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule,

        // panels
        EtsStrategyMessagesModule,
        ManualTradingModule,
        EtsQuoteBoardModule,
        TradingSystemsViewModule,
        StrategyOrdersAndTradesModule,
        AggregatedPositionsModule,
        EtsPnLChartModule,
        EtsPriceChartModule,
        StrategyIssuesModule,
        EtsHpDatasetsModule,
        EtsOpenPositionChartModule,
        PositionSizingReportModule,
        StrategyTriggersModule,
        OptionChainModule,
        PortfoliosModule,
        MultiTradePadModule,
        LedgerModule,
        OptionsPricingPadModule,
        OptionsPricingGridModule,
        StrategyHedgedPositionsModule,
        WatchlistModule,
        SessionHistoryModule,
        ParametersPanelModule,
        MarginRequirementsModule,
        EtsShellMessagesModule,
        TestPanelModule,
        AdjustmentControlPanelModule,
        AutomationCpModule,
        AdjustmentPricingGridModule,
        CashFlowTrackingModule,
        ChecklistEditorModule,
        ResourceEditorModule,
        HedgingGridModule,
        PackageComparisonModule
    ],

    declarations: [
        WorkspaceComponent,
        EtsTabHeaderComponent,
        EtsTabsPanelComponent,
        EtsWorkspaceToolbarComponent,
        EtsWorkspaceHeaderComponent,
        EtsContentPlaceholderDirective,
        EtsTabsMenuComponent,
        WorkspacePanelsMenuComponent,
        PanelsSortComponent
    ],

    providers: [
        WorkspacePanelsMenuService,
        WorkspaceSecurityContextService
    ],

    exports: [
        WorkspaceComponent
    ]
})
export class WorkspaceModule {
}
