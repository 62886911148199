import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { SettingsStorageService } from 'projects/shared-components/settings-storage-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { OrderDto } from 'projects/shared-components/shell-communication/dtos/order-dto.class';
import { OrderStateSnapshotDto } from 'projects/shared-components/shell-communication/dtos/order-state-snapshot-dto.class';
import { TradeDto } from 'projects/shared-components/shell-communication/dtos/trade-dto.class';
import { GetOrderStateSnapshotsWeb, GetTradesWeb, GetWorkingOrdersWeb, ManualPositionsContext } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { ManualPositionHighlightedUIMessage, SymbolHighlighted } from 'projects/shared-components/ui-messages/ui-messages';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ManualTradingBackendService } from '../manual-trading-backend.service';
import { ManualTradingSecurityContextService } from '../manual-trading-security-context.service';
import { OrdersAndTradesLinkedContext } from '../manual-trading.model';
import { OrdersAndTradesBaseComponent } from './orders-and-trades-base.component';
import {UserSettingsService} from "../../user-settings.service";

@Component({
   selector: 'ets-manual-orders-and-trades',
   templateUrl: './orders-and-trades-base.component.html',
   styleUrls: ['./orders-and-trades-base.component.scss'],
   providers: [ManualTradingSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualOrdersAndTradesComponent extends OrdersAndTradesBaseComponent {

   constructor(
      _changeDetector: ChangeDetectorRef,
      userSettingsService: UserSettingsService,
      _messageBus: MessageBusService,
      _backendClient: ManualTradingBackendService,
      _tickerDisplayNameService: TradingInstrumentDisplayNameService,
      _toastr: ToastrService,
      _lastQuoteCache: LastQuoteCacheService,
      _timestampsService: TimestampsService,
      securityContext: ManualTradingSecurityContextService,
   ) {
      super(_changeDetector, userSettingsService, _messageBus, _backendClient, _tickerDisplayNameService
         , _toastr, _lastQuoteCache, _timestampsService, securityContext);
   }

   private _linkedContext: OrdersAndTradesLinkedContext = 'All';

   private _manualPositionsContext: ManualPositionHighlightedUIMessage;

   private _symbolContext: string;

   //

   get linkedContext(): OrdersAndTradesLinkedContext {
      return this._linkedContext;
   }

   @DetectMethodChanges()
   changeIsLinked(): void {

      this.isLinked = !this.isLinked;

      if (this.isLinked) {

         return;

      }

      this._linkedContext = 'All';
      this._symbolContext = undefined;
      this._manualPositionsContext = undefined;

      this.resetLinkedContext();

   }

   protected subscribeSpecificMessages(): void {
      
      this._messageBus
         .of<ManualPositionHighlightedUIMessage>('ManualPositionHighlightedUIMessage')
         .pipe(
            filter(x => x.scopeId === this.layoutTabId),
            filter(_ => this.isLinked),
            takeUntil(this._unsubscriber)
         )
         .subscribe(message => this.onManualPositionHighlighted(message.payload));

      this._messageBus
         .of<SymbolHighlighted>('SymbolHighlighted')
         .pipe(
            filter(x => x.scopeId === this.layoutTabId),
            filter(() => this.isLinkedToSymbol),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onSymbolHighlightedMessage(x.payload));
   }

   protected prepareWorkingOrdersQuery(): GetWorkingOrdersWeb {

      let qry: GetWorkingOrdersWeb;

      if (this.linkedContext === 'Manual Positions') {

         console.assert(!isNullOrUndefined(this._manualPositionsContext), 'manual positions context');

         qry = new GetWorkingOrdersWeb(
            EtsConstants.strategies.manualStrategyId,
            this.linkedContext,
            null,
            this._manualPositionsContext as ManualPositionsContext,
         );

      } else if (this.linkedContext === 'Symbol') {

         console.assert(!isNullOrUndefined(this._symbolContext), 'symbol context');

         qry = new GetWorkingOrdersWeb(
            EtsConstants.strategies.manualStrategyId,
            this.linkedContext,
            this._symbolContext,
            null,
            null
         );

      } else if (this.linkedContext === 'All') {

         qry = new GetWorkingOrdersWeb(
            EtsConstants.strategies.manualStrategyId,
            this.linkedContext,
         );

      } 

      return qry;
   }

   protected prepareTradesQuery(historyStop?: number, batchSize?: number): GetTradesWeb {

      historyStop = historyStop || 0;
      batchSize = batchSize || 100;

      let qry: GetTradesWeb;

      if (this.linkedContext === 'Manual Positions') {

         qry = new GetTradesWeb(
            EtsConstants.strategies.manualStrategyId,
            historyStop,
            batchSize,
            this.linkedContext,
            null,
            this._manualPositionsContext as ManualPositionsContext,
            null
         );
      } else if (this.linkedContext === 'Symbol') {

         qry = new GetTradesWeb(
            EtsConstants.strategies.manualStrategyId,
            historyStop,
            batchSize,
            this.linkedContext,
            this._symbolContext,
            null,
            null
         );

      } else if (this.linkedContext === 'All') {

         qry = new GetTradesWeb(
            EtsConstants.strategies.manualStrategyId,
            historyStop,
            batchSize,
            this.linkedContext,
         );

      }

      return qry;
   }

   protected prepareSnapshotsQuery(historyStop?: number, batchSize?: number): GetOrderStateSnapshotsWeb {

      let qry: GetOrderStateSnapshotsWeb = null;

      historyStop = historyStop || 0;
      batchSize = batchSize || 100;

      if (this.linkedContext === 'Manual Positions') {

         qry = new GetOrderStateSnapshotsWeb(
            EtsConstants.strategies.manualStrategyId,
            historyStop,
            batchSize,
            this.linkedContext,
            null,
            this._manualPositionsContext as ManualPositionsContext,
         );

      } else if (this.linkedContext === 'Symbol') {

         qry = new GetOrderStateSnapshotsWeb(
            EtsConstants.strategies.manualStrategyId,
            historyStop,
            batchSize,
            this.linkedContext,
            this._symbolContext,
            null,
            null
         );

      } else if (this.linkedContext === 'All') {

         qry = new GetOrderStateSnapshotsWeb(
            EtsConstants.strategies.manualStrategyId,
            historyStop,
            batchSize,
            this.linkedContext,
         );

      }

      return qry;


   }

   protected filterTradingEntities<T extends TradeDto | OrderDto | OrderStateSnapshotDto>(entities: T[]): T[] {

      let filtered: T[] = entities.filter(x => isNullOrUndefined(x.portfolioId));

      if (this.linkedContext === 'All') {

         filtered = filtered;

      } else if (this.linkedContext === 'Manual Positions') {

         console.assert(!isNullOrUndefined(this._manualPositionsContext));

         filtered = filtered.filter((entity: any) =>
            this._manualPositionsContext.ticker === entity.tradingInstrumentCode || entity.ticker
            && this._manualPositionsContext.terminalId === entity.terminalId
            && this._manualPositionsContext.accountId === entity.accountId);

      } else if (this.linkedContext === 'Symbol') {

         console.assert(!isNullOrUndefined(this._symbolContext));

         filtered = filtered.filter((entity: any) => this._symbolContext === entity.tradingInstrumentCode || entity.ticker);
      }

      return filtered;

   }

   private onManualPositionHighlighted(message: ManualPositionHighlightedUIMessage): void {

      if (!this.isLinked) {
         return;
      }

      this._linkedContext = 'Manual Positions';
      this._manualPositionsContext = message;

      this.loadManualTradingData();
   }

   private onSymbolHighlightedMessage(x: SymbolHighlighted): void {
      if (!this.isLinked) {
         return;
      }

      this._linkedContext = 'Symbol';
      this._symbolContext = x.ticker;

      this.loadManualTradingData();
   }

   protected async resetLinkedContext(): Promise<void> {
      this._linkedContext = 'All';
      this._manualPositionsContext = this._symbolContext = undefined;

      await this.loadManualTradingData();
   }
}
