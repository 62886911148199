import { ProtocolCommand } from '../../../service-model/protocol-command.interface';
import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';
import { PositionClass } from '../../dtos/aggregated-position-dto.class';

export class CloseAggregatedPosition implements ProtocolCommand {
   public constructor(
      public accountId: string,
      public terminalId: string,
      public tradingInstrumentCode: string,
      public positionClass: PositionClass
   ) {
   }

   getOperationName(): string {
      return `${SHELL_PROTOCOL_OPERATIONS_NS}.CloseAggregatedPosition`;
   }
}
