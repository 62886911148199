import {DropZoneOrder, OrderToRegroupWrapper} from "./OrderToRegroupWrapper";

export class DropZone {
    constructor() {
        this.items = [];
    }

    title: string;

    items: DropZoneOrder[];

    add(order: DropZoneOrder) {
        this.items.push(order);
    }
}