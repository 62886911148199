import {GatewayStatus} from '../../gateways/gateway-status.enum';
import {InformationCategory} from '../../unspecific/information-category.enum';

export class GatewayManagerMessageDto {
  source: string;
  displayName: string;
  gatewayStatus: GatewayStatus;
  message: string;
  timestamp: Date;
  gatewayId?: string;
  category: InformationCategory;
}
