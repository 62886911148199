import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, ViewChild } from '@angular/core';
import { ColumnState, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil, throttleTime } from 'rxjs/operators';
import { SettingsStorageService } from '../../settings-storage-service.service';
import { MessageBusService } from '../../message-bus.service';
import { PositionDto, PositionFlags, positionHasFlag } from '../../shell-communication/dtos/position-dto.class';
import { QuoteDto } from '../../shell-communication/dtos/quote-dto.class';
import { ShellClientService } from '../../shell-communication/shell-client.service';
import { AddInterestRecord, ArchivePortfolioItem, BucketContext, ChangeAttachedLeg, ExcludePortfolioItem, ExpireOptions, GetBucketItems, GetLegUnderAdjustment, ToggleCashSettled, TogglePositionFlag } from '../../shell-communication/shell-operations-protocol';
import { TimestampsService } from '../../timestamps.service';
import { ClearTradingDataUIMessage } from '../../ui-messages/clear-trading-data-ui-message.class';
import { DetectMethodChanges, DetectSetterChanges, getPanelStateKey, isAdjustmentAlgo, isHedgingAlgo, isNullOrUndefined, tickersMatch } from '../../utils';
import { PortfolioItemType } from '../portfolios.model';
import { getPortfolioItemsGridModel } from './portfolio-items-grid-options';
import { getExpirationDialogGridOptions } from './expiration-dialog-grid-options';
import { BucketItemHighlighted, ClearPortfolioItemsUIMessage, ShowBucketPositionsUIMessage, ShowStrategyHistoryUIMessage } from '../../ui-messages/ui-messages';
import { MoveToPortfolioDialogComponent } from '../move-to-portfolio-dialog/move-to-portfolio-dialog.component';
import { PositionAdjustComponent } from '../../position-adjust/position-adjust.component';
import { PositionOverrideComponent } from '../../position-override/position-override.component';
import { MoveToPortfolioDialogConfig, PortfolioEntityType } from '../move-to-portfolio-dialog/move-to-portfolio-dialog.model';
import { LastQuoteCacheService } from '../../last-quote-cache.service';
import { ExpirationItem, PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { StrategyParametersUpdatedDto } from 'projects/shared-components/shell-communication/dtos/strategy-parameters-updated-dto.class';
import { StrategiesService } from 'projects/shared-components/strategies/strategies.service';
import { StartStrategies } from 'projects/shared-components/shell-communication/operations/strategies/start-strategies.class';
import { ExitStrategies } from 'projects/shared-components/shell-communication/operations/strategies/exit-strategies.class';
import { StrategyStateDto } from 'projects/shared-components/shell-communication/dtos/strategy-state-dto.class';
import { SetStrategiesToStart } from 'projects/shared-components/shell-communication/operations/strategies/set-strategies-to-start.class';
import { RollNextMonth } from 'projects/shared-components/shell-communication/operations/strategies/roll-next-month.class';
import { OverrideExitStrategies } from 'projects/shared-components/shell-communication/operations/strategies/override-exit-strategies.class';
import { OverrideResetStrategies } from 'projects/shared-components/shell-communication/operations/strategies/override-reset-strategies.class';
import { ClearTradingData } from 'projects/shared-components/shell-communication/operations/shell/clear-trading-data.class';
import { AdjustmentStrategyDialogComponent } from 'projects/shared-components/adjustment-strategy-dialog/adjustment-strategy-dialog.component';
import { ShellConnectionStatusChangedUIMessage } from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { InterestCalculatorParameters } from 'projects/shared-components/adjustment-strategy-dialog/strategy-parameters/interest/interest-calculator-strategy-parameters';
import { TargetStrikeDialog } from './TargetStrikeDialog/TargetStrikeDialog';
import { TargetStrikeDialogConfig } from './TargetStrikeDialog/TargetStrikeDialogConfig';
import { OptionsChainService } from 'projects/shared-components/option-chains.service';
import { AttachStrategyDialogConfig } from 'projects/shared-components/adjustment-strategy-dialog/adjustment-strategy-dialog.model';
import { cashFlowPositionsSort } from 'projects/shared-components/adjustment-pricing-grid/model/CashFlowPositionsSort';
import {UserSettingsService} from "../../user-settings.service";

//

interface PanelState {
   gridState: ColumnState[];
}


//

interface PortfolioItemUpdateToken {
   sessionPnL?: number;
   accumulatedPnL?: number;
   netPosition?: number;
   avgPx?: number;
   delta?: number;
   gamma?: number;
   vega?: number;
   theta?: number;
   lastMatchPx?: number;
   lastOverSessionReset?: boolean;
   openPrice?: number;
   lastTraded?: Date;
}

//

interface ExpirationDialogItem {
   itemId: string;
   tickerDisplayName: string;
   underlyingDisplayName: string;
   expirationDisplayDate: string;
   netPosition: number;
   openPrice: number;
   accumulatedPnL: number;
   portfolioName: string;
   comboName: string;
   comboGroupName: string;
   expirationPrice?: number;
   expirationTime?: string;
   expirationDifference?: number;
}

//

interface InterestRecordDialogConfig {
   strategyId?: string;
   isVisible?: boolean;
   isLoading?: boolean;
   parameters: InterestCalculatorParameters;
}

@Component({
   selector: 'ets-portfolio-positions',
   templateUrl: 'portfolio-items.component.html',
   styleUrls: ['portfolio-items.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioItemsComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _messageBus: MessageBusService,
      protected readonly _userSettingsService: UserSettingsService,

      private readonly _toastr: ToastrService,
      private readonly _timestampsService: TimestampsService,
      private readonly _shellClient: ShellClientService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _tradingInstrumentsService: TradingInstrumentsService,
      private readonly _strategyService: StrategiesService,
      private readonly _sessionService: SessionService,
      private readonly _optionChainsService: OptionsChainService
   ) {
      super(_changeDetector, _userSettingsService, _messageBus);
      this.interestRecordDialogConfig =  {
         parameters: new InterestCalculatorParameters(this._changeDetector, this._sessionService),
      };
   }

   //

   private _unsubscriber: Subject<void>;
   private _grid: GridReadyEvent;
   private _stateChanged = new EventEmitter();
   private _currentBucketToShow: BucketContext;
   private _pendingDataContainer: any[];

   //

   @ViewChild(PositionAdjustComponent) positionAdjustDialog: PositionAdjustComponent;
   @ViewChild(PositionOverrideComponent) positionOverrideDialog: PositionOverrideComponent;
   @ViewChild(MoveToPortfolioDialogComponent) addToPortfolioDialog: MoveToPortfolioDialogComponent;
   @ViewChild(AdjustmentStrategyDialogComponent) attachStrategyDialog: AdjustmentStrategyDialogComponent;

   //

   gridOptions: GridOptions;

   //

   interestRecordDialogConfig: InterestRecordDialogConfig;

   //

   clearTradingDataConfirmPopupSettings: { strategies?: string[], isVisible?: boolean } = {};

   //

   targetStrikeDialog: TargetStrikeDialog;

   //

   private _hideClosedPositions : boolean;
   get hideClosedPositions() : boolean {
      return this._hideClosedPositions;
   }
   @DetectSetterChanges()
   set hideClosedPositions(v : boolean) {
      this._hideClosedPositions = v;
   }

   //

   readonly expirationDialog: {
      isVisible?: boolean
      grid?: GridReadyEvent,
      gridOptions?: GridOptions,
      data?: ExpirationDialogItem[],
      noApplyToAll: {},
      isLoading?: boolean;
   } = { noApplyToAll: {} };

   //

   get gridApi(): GridApi {
      return this._grid.api;
   }

   //

   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   //

   get timestampsService(): TimestampsService {
      return this._timestampsService;
   }

   //

   get strategyService(): StrategiesService {
      return this._strategyService;
   }

   //

   get sessionService(): SessionService {
      return this._sessionService;
   }

   //

   get unsubscriber(): Subject<void> {
      return this._unsubscriber;
   }

   //

   get tradingInstrumentsService(): TradingInstrumentsService {
      return this._tradingInstrumentsService;
   }

   //

   etsOnInit() {

      this.targetStrikeDialog = new TargetStrikeDialog(this._changeDetector,
         this._toastr, this._lastQuoteCache, this._optionChainsService,
         this._tradingInstrumentsService, this._shellClient);

      this._unsubscriber = new Subject();
      this.gridOptions = getPortfolioItemsGridModel.bind(this)();
      this.expirationDialog.gridOptions = getExpirationDialogGridOptions.bind(this)();
   }

   //

   etsOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //

   etsAfterViewInit() {

      const msg = 
         this._messageBus.getLastMessage<ShowBucketPositionsUIMessage>('ShowBucketPositionsUIMessage', this.layoutTabId);

      if (isNullOrUndefined(msg)) {
         return;
      }

      setTimeout(() => this.onShowBucketPositionsMessage(msg.payload));
      
   }

   //

   async onGridReady(args: GridReadyEvent): Promise<void> {
      this._grid = args;

      const key = getPanelStateKey(this);
      
      const item = this._userSettingsService.getValue(key);

      if (!item) {
         this.saveState();
      } else {
         this.restoreState();
      }

      args.columnApi.autoSizeAllColumns();
      
      this.subscribeToMessages();
   }

   //

   onStateChanged(): void {
      this._stateChanged.emit();
   }

   //

   onClearPortfolioItemsUIMessage() {
      this._grid.api.setRowData([]);
   }

   //

   showAttachAdjustmentDialog(pfItem: PortfolioItemDto): void {
      const dialog = this.attachStrategyDialog;

      if (!dialog) {
         return;
      }

      const config: AttachStrategyDialogConfig = {
         mode: 'create',
         ctx: 'direct',
         item: pfItem,
         targetBucket: {
            portfolioId: pfItem.portfolioId,
            comboId: pfItem.comboId,
         }
      };

      dialog.show(config);
   }

   //

   showAdjustPositionDialog(pfItem: PortfolioItemDto) {
      if (!this.positionAdjustDialog) {
         return;
      }
      
      this.positionAdjustDialog.show({
         positionId: pfItem.portfolioItemId,
         netPosition: pfItem.netPosition,
         symbol: pfItem.tickerDisplayName
      });
   }

   //

   showOverridePositionDialog(pfItem: PortfolioItemDto) {

      if (!this.positionOverrideDialog) {
         return;
      }


      if (pfItem.itemType === PortfolioItemType.Strategy) {
         this._toastr.error('Item cannot be overridden');
         return;
      }

      const positionOverrideToken = {
         positionId: pfItem.portfolioItemId,
         ticker: pfItem.ticker,
         avgPx: pfItem.avgPx,
         netPosition: pfItem.netPosition,
         tickerDisplayName: pfItem.tickerDisplayName
      };

      this.positionOverrideDialog.show(positionOverrideToken);
   }

   //

   removeItem(data: PortfolioItemDto, action: 'exclude' | 'archive' | 'transfer'): void {

      if (action === 'exclude') {

         const cmd = new ExcludePortfolioItem(data.portfolioItemId, data.portfolioId);
         this._shellClient.processCommand(cmd);

      } else if (action === 'archive') {

         const cmd = new ArchivePortfolioItem(data.portfolioItemId, data.portfolioId);
         this._shellClient.processCommand(cmd);

      } else if (action === 'transfer') {

         if (!this.addToPortfolioDialog) {
            return;
         }

         const cfg: MoveToPortfolioDialogConfig = {
            itemId: data.portfolioItemId,
            itemType: PortfolioEntityType.PortfolioItem,
            itemAvgPx: data.itemType === PortfolioItemType.Strategy ? null : data.avgPx,
            itemNetPosition: data.itemType === PortfolioItemType.Strategy ? null : Math.abs(data.netPosition),
            itemTerminalId: data.terminalId,
            itemPortfolioId: data.portfolioId,
            itemComboId: data.comboId,
            itemComboGroupId: data.comboGroupId,
         };

         this.addToPortfolioDialog.show(cfg);

      }
   }

   //

   async onBucketItemSelected(data: PortfolioItemDto) {
      
      this._messageBus.publishAsync<BucketItemHighlighted>({
         topic: 'BucketItemHighlighted',
         payload: {
            item: data
         },
         scopeId: this.layoutTabId
      });

      let affectedNodes: RowNode[];

      try {

         affectedNodes = (await this.changeBaseLegHighligting(data) || []);

      } catch {

      } finally {

         if (affectedNodes.length > 0) {
            this._grid.api.redrawRows({rowNodes: affectedNodes});
         }

      }
   }

   //

   async resetBaseLegHighlighting() {
      const nodes = this.resetHighlightedRows();

      if (nodes.length > 0) {
         this._grid.api.redrawRows({rowNodes: nodes});
      }

   }

   //

   private async changeBaseLegHighligting(data: PortfolioItemDto): Promise<RowNode[]> {

      const rowsToUpdate = [];
      const nodes = this.resetHighlightedRows();
      rowsToUpdate.push(...nodes);

      if (data.itemType === PortfolioItemType.Strategy) {
         
         const strategyModel = this.strategyService.getById(data.portfolioItemId);

         const isAdjustment = isAdjustmentAlgo(strategyModel.algoId);

         if (!isAdjustment) {
            return rowsToUpdate;
         }

         const qry = new GetLegUnderAdjustment(strategyModel.strategyId);

         let positionsUndeAdjustment: PositionDto[];
         
         try {

            this.isLoading = true;
            
            const positionsDto = await this._shellClient.processQuery<PositionDto[]>(qry);
            
            positionsUndeAdjustment = positionsDto;

         } finally {
            
            this.isLoading = false;

         }

         if (!positionsUndeAdjustment || positionsUndeAdjustment.length === 0) {
            return rowsToUpdate;
         }

         positionsUndeAdjustment.forEach(pua => {
            
            this._grid.api.forEachNode(rowNode => {
   
               if (rowNode.group) {
                  return;
               }
   
               const pfItem = rowNode.data as PortfolioItemDto;
               
               if (!pfItem) {
                  return;
               }
   
               const sameTicker = pfItem.ticker === pua.ticker;
               const sameAccount = pfItem.accountId === pua.accountId;
               const sameTerminal = pfItem.terminalId === pua.terminalId;
               const samePortfolio = pfItem.portfolioId === pua.portfolioId;
               const sameCombo = pfItem.comboId === pua.comboId;
               const sameComboGroup = pfItem.comboGroupId === pua.comboGroupId;
   
               if (sameTicker && sameAccount && sameTerminal && samePortfolio && sameCombo && sameComboGroup) {
                  pfItem.isUnderAdjustment = true;
                  rowsToUpdate.push(rowNode);
               }
            });

         });
      }

      return rowsToUpdate;
   }

   //

   private resetHighlightedRows(): RowNode[] {
      const nodes = [];
      this._grid.api.forEachNode(node => {
         if (!node.data) {
            return;
         }

         if (node.group) {
            return;
         }
         
         const pfItem = node.data as PortfolioItemDto;
         
         if (pfItem.isUnderAdjustment) {
            pfItem.isUnderAdjustment = false;
            nodes.push(node);
         }
      });
      return nodes;
   }

   //

   async togglePositionFlag(data: PortfolioItemDto, flag: PositionFlags): Promise<void> {
      
      if (isNullOrUndefined(data)) {
         this._toastr.error('Bad Portfolio Item Data', 'Portfolio Items');
         return;
      }

      if (!data.portfolioItemId) {
         this._toastr.error('Bad Portfolio Item ID', 'Portfolio Items');
         return;
      }

      if (data.itemType === PortfolioItemType.Strategy) {
         this._toastr.error('Operation Not Supported for Strategies', 'Portfolio Items');
         return;
      }

      const cmd = new TogglePositionFlag(data.portfolioItemId, flag);

      try {
         
         await this._shellClient.processCommand(cmd);
      } catch (e) {

         this._toastr.error('"Toggle Position Last Over Session Reset" operation completed with errors', 'Portfolio Items');
         console.error(e);
      }
   }

   //#region Strategies

   editStrategy(data: StrategyModel, mode: string) {
      if (!isHedgingAlgo(data.algoId)) {
         return;
      }

      this.attachStrategyDialog.show({
         mode: 'update',
         ctx: 'direct',
         item: null,
         strategy: data
      });
   }

   @DetectMethodChanges({isAsync: true})
   async enableStrategy(data: StrategyModel): Promise<void> {
      
      const cmd = new StartStrategies([data.strategyId]);

      try {
         
         await this._shellClient.processCommand(cmd);

      } catch (e) {
         
         this._toastr.error('"Start Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('startStrategy()', errorData);

      }
   }

   @DetectMethodChanges({isAsync: true})
   async disableStrategy(data: StrategyModel): Promise<void> {

      const cmd = new ExitStrategies([data.strategyId]);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Exit Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('exitStrategy()', errorData);
      }
   }

   async setToStartStrategy(data: StrategyModel) {
      const cmd = new SetStrategiesToStart(
         [data.strategyId]
      );

      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Set Strategy To Start" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('setToStartStrategy()', errorData);
      } finally {
         this.isLoading = false;
      }
   }

   async rollNextMonth(data: StrategyModel) {
      const cmd = new RollNextMonth(data.strategyId);
      this.isLoading = true;
      
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         const errorData = {
            error: (e.stack ? e.stack : e), strategyId: data.strategyId
         };
         console.error('Failed to roll strategy next month', errorData);
         this._toastr.error('"Roll Next Month" command completed with errors');
      } finally {
         this.isLoading = false;
      }
   }

   requestSessionHistoryDialog(data: StrategyModel) {
      this._messageBus.publish({
         topic: 'ShowStrategyHistoryUIMessage',
         payload: {
            strategies: [data]
         } as ShowStrategyHistoryUIMessage
      });
   }

   clearTradingDataForStrategy(data: StrategyModel) {

   }

   async overrideExitStrategy(data: StrategyModel) {
      const cmd = new OverrideExitStrategies([data.strategyId]);

      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Override Exit Strategy" operation completed with errors');
         const d = { error: e.stack || e };
         console.error('overrideExitStrategy()', d);
      } finally {
         this.isLoading = false;
      }
   }

   async overrideResetStrategy(data: StrategyModel) {
      const cmd = new OverrideResetStrategies([data.strategyId]);

      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Override Reset Strategy" operation completed with errors');
         const d = { error: e.stack || e };
         console.error('overrideResetStrategy()', d);
      } finally {
         this.isLoading = false;
      }
   }

   //

   async toggleCashSettled(data: PortfolioItemDto) {
      const cmd = new ToggleCashSettled(data.portfolioItemId);
      
      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch {
         this._toastr.error('"Toggle Cash Settled" operation completed with errors');
      } finally {
         this.isLoading = false;
      }
   }
   
   //

   private subscribeToMessages() {

      this._messageBus
         .of<ClearPortfolioItemsUIMessage>('ClearPortfolioItemsUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId)
         )
         .subscribe(message => this.onClearPortfolioItemsUIMessage());


      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(message => !message.payload.hasErrors)
         )
         .subscribe(message => this.onClearTradingDataMessage(message.payload));


      this._messageBus
         .of<any>('WorkspaceClosed')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.payload.workspaceId === this.workspaceId),
         )
         .subscribe(msg => this.onRemovedFromWorkspace());


      this._stateChanged
         .pipe(
            takeUntil(this._unsubscriber),
            throttleTime(250)
         )
         .subscribe(() => this.saveState());

      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onQuoteMessage(msg.payload));

      this._messageBus
         .of<ShowBucketPositionsUIMessage>('ShowBucketPositionsUIMessage')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId)
         )
         .subscribe(message => this.onShowBucketPositionsMessage(message.payload));


      this._messageBus
         .of<PositionDto[]>('PositionDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(dtos => setTimeout(() => this.onPositionDto(dtos.payload)));

      this._messageBus
         .of<any>('StrategyPositionChanged')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onStrategyPositionChanged(msg.payload));

      this._messageBus
         .of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onPortfolioItemAddedMessage(msg.payload));


      this._messageBus
         .of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onPortfolioItemRemovedMessage(msg.payload));


      this._messageBus
            .of<StrategyParametersUpdatedDto>('StrategyParametersUpdatedDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(msg => this.onStrategyParametersUpdate(msg.payload));

      this._messageBus.of<StrategyStateDto[]>('StrategyStateDto')
            .pipe(
               takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onStrategyStateChanged(msg.payload));
      
      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onShellConnectionStatusChangedUIMessage(msg.payload));

   }

   private async onShellConnectionStatusChangedUIMessage(payload: ShellConnectionStatusChangedUIMessage): Promise<void> {
      if (!payload.isConnected) {
         return;
      }

      await this.loadCurrentBucketItems();
   }

   private onStrategyStateChanged(payload: StrategyStateDto[]): void {
      if (!this._grid) {
         return;
      }

      const nodes = [];
      
      payload.forEach(x => {
         const node = this._grid.api.getRowNode(x.strategyId);
         if (node) {
            node.data.strategyState = x.state;
            nodes.push(node);
         }
      });

      if (nodes.length > 0) {
         this.refreshStrategyRelatedCells(nodes);
      }
   }

   //

   private async onShowBucketPositionsMessage(msg: ShowBucketPositionsUIMessage) {

      if (!this._grid) {
         return;
      }

      const bucketContext = msg.bucketContext;

      this._currentBucketToShow = bucketContext;

      await this.loadCurrentBucketItems();
   }

   //

   private async loadCurrentBucketItems(): Promise<void> {
      const contextOfRequest = this._currentBucketToShow;

      if (!contextOfRequest) {
         console.debug('context not selected');
         return;
      }

      console.debug(`portfolio-items|show bucket positions: bucketType = ${contextOfRequest.bucketType}, bucketName: ${contextOfRequest.bucketName}, bucketId = ${contextOfRequest.bucketId}`);

      try {

         this.isLoading = true;

         this._grid.api.setRowData([]);

         const qry = new GetBucketItems(contextOfRequest.bucketType, contextOfRequest.bucketId);

         this._pendingDataContainer = [];

         let items = await this._shellClient.processQuery<PortfolioItemDto[]>(qry);

         const active = items.filter(x => !x.isArchived);

         const tickers = active
            .filter(x => x.itemType !== PortfolioItemType.Strategy)
            .map(x => x.ticker);

         this._lastQuoteCache.subscribeTickers(tickers);

         items = items.sort((a, b) => {

            if (a.isArchived && b.isArchived) {
               return 0;
            }

            if (!a.isArchived && !b.isArchived) {
               return 0;
            }

            if (a.isArchived) {
               return 1;
            }

            if (b.isArchived) {
               return -1;
            }
         });

         const contextBeforeSettingsRows = this._currentBucketToShow;

         if (contextBeforeSettingsRows.bucketId !== contextOfRequest.bucketId) {
            console.debug(`portfolio-items|context bucket changed. was bucketId=${contextOfRequest.bucketId}, is=${contextBeforeSettingsRows.bucketId}`);
            return;
         }

         items = cashFlowPositionsSort(items, 'asc');

         this._grid.api.setRowData(items);
         
         if (this._pendingDataContainer.length > 0) {

            console.debug(`portfolios-items|pending items = ${this._pendingDataContainer.length}`);

            const maxSeqNum = Math.max(...active.map(x => x.seqNum));

            console.debug(`portfolios-items|maxSeqNum of received items = ${maxSeqNum}`);

            const raceItems = this._pendingDataContainer.filter(x => x.seqNum > maxSeqNum);

            console.debug(`portfolios-items|race items detected = ${raceItems.length}`);

            if (raceItems.length > 0) {

               const raceStrategies: StrategyModel[] = raceItems.filter(x => 'positions' in x);

               console.debug(`portfolios-items|race stategies = ${raceStrategies.length}`);

               const racePositions: PositionDto[] = raceItems.filter(x => !('positions' in x));

               console.debug(`portfolios-items|race positions = ${racePositions.length}`);

               this._pendingDataContainer = null;

               if (raceStrategies.length > 0) {
                  raceStrategies.forEach(x => this.onStrategyPositionChanged(x));
               }

               if (racePositions.length > 0) {
                  this.onPositionDto(racePositions);
               }
            }
         } else {
            console.debug(`portfolios-items|no pending items`);
            this._pendingDataContainer = null;
         }

         this._grid.api.forEachNode(node => {
            if (node.group) {
               if (node.field === 'isArchived' && node.key === 'Active Items') {
                  setTimeout(() => node.setExpanded(true));
               }
            }
         });

      } catch (e) {

         this._currentBucketToShow = undefined;

      } finally {

         this.isLoading = false;

      }
   }

   //

   private onClearTradingDataMessage(message: ClearTradingDataUIMessage): void {
      if (message.hasErrors) {
         return;
      }

      this._grid.api.setRowData([]);
      this._currentBucketToShow = undefined;
   }

   //

   private onQuoteMessage(quotes: QuoteDto[]) {
      if (!this._grid) {
         return;
      }

      const nodes = [];

      this._grid.api.forEachLeafNode(node => {

         const pos = node.data as PositionDto;

         const quote = quotes.find(q => tickersMatch(pos.ticker, q.ticker));

         if (quote) {

            if (quote.ticker.indexOf('@XSP') >= 0) {

               pos.liveQuote = quote.mid;

            } else {

               if (pos.netPosition > 0) {

                  pos.liveQuote = quote.bid;

               } else if (pos.netPosition < 0) {

                  pos.liveQuote = quote.ask;

               } else {

                  pos.liveQuote = quote.lastPx;

               }
            }

            nodes.push(node);
         }
      });

      if (nodes.length > 0) {
         this._grid.api.refreshCells({ columns: ['liveQuote'], rowNodes: nodes });
      }

      this.targetStrikeDialog.onQuote(quotes);
   }
   
   //

   private onStrategyParametersUpdate(msg: StrategyParametersUpdatedDto) {
      if (!this._grid) {
         return;
      }

      const node = this._grid.api.getRowNode(msg.strategy.strategyId);

      if (!node) {
         return;
      }

      const pfItem = node.data as PortfolioItemDto;

      // tslint:disable-next-line: no-string-literal
      const symbolParam = msg.strategy.parameters['symbol'];

      if (!symbolParam) {
         return;
      }

      pfItem.underlying = symbolParam;
      const ti = this._tradingInstrumentsService.getInstrumentByTicker(symbolParam);
      pfItem.underlyingDisplayName =  this._tradingInstrumentsService.getInstrumentByTicker(ti.underlying)?.displayName;

      this._grid.api.applyTransaction({update: [pfItem]});
   }

   //

   private onPortfolioItemAddedMessage(msg: PortfolioItemAddedDto): void {

      if (!this._grid) {
         return null;
      }

      if (!this._currentBucketToShow) {
         return;
      }

      if (this._currentBucketToShow.bucketType === 'ComboGroup') {
         if (msg.portfolioItem.comboGroupId !== this._currentBucketToShow.bucketId) {
            return null;
         }
      } else if (this._currentBucketToShow.bucketType === 'Combo') {
         if (msg.portfolioItem.comboId !== this._currentBucketToShow.bucketId) {
            return null;
         }
      } else if (this._currentBucketToShow.bucketType === 'Portfolio') {
         if (msg.portfolioItem.portfolioId !== this._currentBucketToShow.bucketId) {
            return null;
         }
      }

      if (msg.portfolioItem.itemType !== PortfolioItemType.Strategy) {
         this._lastQuoteCache.subscribeTicker(msg.portfolioItem.ticker);
      }

      this._grid.api.applyTransaction({ add: [msg.portfolioItem] });
   }

   //

   private onPortfolioItemRemovedMessage(msg: PortfolioItemRemovedDto): void {

      this._grid.api.applyTransaction({ remove: [{ portfolioItemId: msg.portfolioItemId }] });

   }

   //

   private onPositionDto(dtos: PositionDto[]): void {

      if (!this._grid) {
         return;
      }

      if (this._pendingDataContainer) {
         this._pendingDataContainer.push(...dtos);
      }

      const items = dtos.map(dto => this.processPositionDto(dto)).filter(x => !isNullOrUndefined(x));

      this._grid.api.applyTransactionAsync({ update: items }, (tran) => {
         this._grid.api.refreshCells({
            columns: ['ticker', 'openPrice'],
            rowNodes: tran.update,
            force: true
         });
      });
   }

   //

   private processPositionDto(dto: PositionDto): PortfolioItemDto {

      if (!this._grid) {
         return null;
      }

      if (!this._currentBucketToShow) {
         return;
      }

      if (dto.strategyId !== EtsConstants.strategies.manualStrategyId) {
         return;
      }

      if (this._currentBucketToShow.bucketType === 'ComboGroup') {
         if (dto.comboGroupId !== this._currentBucketToShow.bucketId) {
            return null;
         }
      } else if (this._currentBucketToShow.bucketType === 'Combo') {
         if (dto.comboId !== this._currentBucketToShow.bucketId) {
            return null;
         }
      } else if (this._currentBucketToShow.bucketType === 'Portfolio') {
         if (dto.portfolioId !== this._currentBucketToShow.bucketId) {
            return null;
         }
      }

      const node = this._grid.api.getRowNode(dto.positionId);

      if (!node) {
         return;
      }

      const pfItem: PortfolioItemDto = node.data;

      if (!pfItem) {
         return;
      }

      const updateToken: PortfolioItemUpdateToken = {};

      updateToken.sessionPnL = dto.sessionTotalPnL;
      updateToken.accumulatedPnL = dto.accumulatedTotalPnL;
      updateToken.netPosition = dto.netPosition;
      updateToken.avgPx = dto.avgPx;
      updateToken.delta = dto.delta;
      updateToken.gamma = dto.gamma;
      updateToken.vega = dto.vega;
      updateToken.theta = dto.theta;
      updateToken.lastMatchPx = dto.lastMatchPx;
      updateToken.lastOverSessionReset = positionHasFlag(dto, PositionFlags.LastOverSessionReset);
      updateToken.openPrice = dto.openPrice;
      updateToken.lastTraded = dto.lastTraded;

      this.updatePortfolioItem(pfItem, updateToken);

      return pfItem;

   }

   //

   private onStrategyPositionChanged(strategy: StrategyModel): void {

      if (!this._grid) {
         return null;
      }

      if (!this._currentBucketToShow) {
         return;
      }

      if (this._pendingDataContainer) {
         this._pendingDataContainer.push(strategy);
         return;
      }


      if (this._currentBucketToShow.bucketType === 'ComboGroup') {
         if (strategy.comboGroupId !== this._currentBucketToShow.bucketId) {
            return;
         }
      } else if (this._currentBucketToShow.bucketType === 'Combo') {
         if (strategy.comboId !== this._currentBucketToShow.bucketId) {
            return;
         }
      } else if (this._currentBucketToShow.bucketType === 'Portfolio') {
         if (strategy.portfolioId !== this._currentBucketToShow.bucketId) {
            return;
         }
      }

      const node = this._grid.api.getRowNode(strategy.strategyId);

      if (!node) {
         return;
      }

      const pfItem: PortfolioItemDto = node.data;

      if (!pfItem) {
         return;

      }
      const updateToken: PortfolioItemUpdateToken = {};

      updateToken.sessionPnL = strategy.sessionTotalPnL;
      updateToken.accumulatedPnL = strategy.accumulatedTotalPnL;
      updateToken.netPosition = strategy.netPosition;
      updateToken.avgPx = strategy.avgPx;
      updateToken.delta = strategy.delta;
      // updateToken.gamma = strategy.gamma;
      // updateToken.vega = strategy.vega;
      // updateToken.theta = strategy.theta;
      updateToken.lastMatchPx = strategy.lastPx;
      updateToken.openPrice = strategy.openPrice;

      this.updatePortfolioItem(pfItem, updateToken);
      this.refreshStrategyRelatedCells([node]);
   }

   //

   private refreshStrategyRelatedCells(nodes: RowNode[]) {
      this._grid.api.refreshCells({
         force: true,
         rowNodes: nodes,
         columns: [
            'itemType', 
            'sessionPnL', 
            'accumulatedPnL', 
            'netPosition', 
            'avgPx', 
            'delta', 
            'gamma', 
            'vega', 
            'theta', 
            'lastMatchPx',
            'openPrice'
         ]
      });
   }

   //

   private updatePortfolioItem(pfItem: PortfolioItemDto, updateToken: PortfolioItemUpdateToken) {

      if (!pfItem) {
         return;
      }

      pfItem.sessionPnL = updateToken.sessionPnL;
      pfItem.accumulatedPnL = updateToken.accumulatedPnL;
      pfItem.netPosition = updateToken.netPosition;
      pfItem.avgPx = updateToken.avgPx;
      pfItem.delta = updateToken.delta;
      pfItem.gamma = updateToken.gamma;
      pfItem.vega = updateToken.vega;
      pfItem.theta = updateToken.theta;
      pfItem.lastMatchPx = updateToken.lastMatchPx;
      pfItem.lastOverSessionReset = updateToken.lastOverSessionReset;
      pfItem.openPrice = updateToken.openPrice;
      pfItem.lastTraded = updateToken.lastTraded;
   }

   //

   protected getState(): any {
      const gridState = this._grid.columnApi.getColumnState();
      
      const state: PanelState = {
         gridState,
      };

      return state;
   }

   //

   protected setState(state: PanelState) {
      if (!this._grid) {
         return;
      }
      
      const isGridOK = this._grid.columnApi.setColumnState(state.gridState);
      
      if (!isGridOK) {
         this._toastr.error('"Trading Systems View" panel was restored with errors');
      }
      
    }

   //

   onExpirationDialogGridReady(args: GridReadyEvent): void {
      this.expirationDialog.grid = args;
   }

   //

   @DetectMethodChanges()
   showExpirationDialog(item: PortfolioItemDto) {
      const data: ExpirationDialogItem[] = [
         {
            itemId: item.portfolioItemId,
            tickerDisplayName: item.tickerDisplayName,
            underlyingDisplayName: item.underlyingDisplayName,
            expirationDisplayDate: item.tickerDisplayName.split(' ')[3],
            netPosition: item.netPosition,
            openPrice: item.openPrice,
            accumulatedPnL: item.accumulatedPnL,
            portfolioName: item.portfolioName,
            comboName: item.comboName,
            comboGroupName: item.comboGroupName,
            expirationTime: '16:20'
         }
      ];

      this.expirationDialog.noApplyToAll = {};

      this.expirationDialog.data = data;

      if (this.expirationDialog.grid) {
         this.expirationDialogSetLastPx(data);
         this.expirationDialog.grid.api.setRowData(data);
         this.expirationDialog.grid.api.expandAll();
         setTimeout(() => this.expirationDialog.grid.api.sizeColumnsToFit());
      }

      this.expirationDialog.isVisible = true;
   }

   //

   onExpirationDialogShown() {

      if (this.expirationDialog.data && this.expirationDialog.data.length) {
         this.expirationDialogSetLastPx(this.expirationDialog.data);
         this.expirationDialog.grid.api.setRowData(this.expirationDialog.data);
         this.expirationDialog.grid.api.expandAll();
         setTimeout(() => this.expirationDialog.grid.api.sizeColumnsToFit());
      }

   }

   //

   @DetectMethodChanges()
   onExpirationDialogHidden() {
      this.expirationDialog.isVisible = false;
      this.expirationDialog.data = null;
   }

   //

   onExpirationDialogPriceEntered(value: number, item: ExpirationDialogItem) {

      if (this.expirationDialog.noApplyToAll[item.expirationDisplayDate]) {

         this.expirationDialogSetNetDiff(item);

      } else {
         this.expirationDialog.grid.api.forEachNode(node => {
            if (node.group) {
               return;
            }
            if (!node.data) {
               return;
            }

            const data: ExpirationDialogItem = node.data;

            if (data.expirationDisplayDate === item.expirationDisplayDate
               && data.underlyingDisplayName === item.underlyingDisplayName) {
               data.expirationPrice = value;

               this.expirationDialogSetNetDiff(data);

            }
         });
      }

      this.expirationDialog.grid.api.refreshCells();
   }

   //

   onExpirationDialogTimeEntered(value: string, item: ExpirationDialogItem) {

      if (this.expirationDialog.noApplyToAll[item.expirationDisplayDate]) {
         return;
      }

      this.expirationDialog.grid.api.forEachNode(node => {
         if (node.group) {
            return;
         }
         if (!node.data) {
            return;
         }

         const data: ExpirationDialogItem = node.data;

         if (data.expirationDisplayDate === item.expirationDisplayDate
            && data.underlyingDisplayName === item.underlyingDisplayName) {
            data.expirationTime = value;
         }
      });

      this.expirationDialog.grid.api.refreshCells();
   }

   //

   expirationDialogRefillActualLastPrices() {
      const items = [];
      this.expirationDialog.grid.api.forEachNode(node => {
         if (node.data) {
            items.push(node.data);
         }
      });

      this.expirationDialogSetLastPx(items);

      this.expirationDialog.grid.api.applyTransactionAsync({ update: items });
   }

   //

   async expirationDialogOnExpireBtnClicked() {
      if (!this.expirationDialog.data) {
         return;
      }

      const expirationItems = this.expirationDialog.data.map(x => {
         return {
            expirationTime: x.expirationTime,
            expirationPrice: x.expirationPrice,
            positionId: x.itemId
         } as ExpirationItem;
      });

      const cmd = new ExpireOptions(expirationItems);

      try {

         this.expirationDialog.isLoading = true;
         await this._shellClient.processCommand(cmd);
         this.onExpirationDialogHidden();

      } catch (e) {

      } finally {

         this.expirationDialog.isLoading = false;

      }
   }
   
   //

   showBucketContextHint() {
      const config = { positionClass: 'toastr-bottom-wide-width' };

      if (this._currentBucketToShow) {

         const msg = `Bucket Type: [${this._currentBucketToShow.bucketType}], Name: "${this._currentBucketToShow.bucketName}"`;
         this._toastr.info(msg, 'Portfolio Items', config);

      } else {

         this._toastr.info('Bucket is unknown', 'Portfolio Items', config);

      }
   }

   //

   @DetectMethodChanges()
   async onClearTradingDataForStrategyConfirmed() {
      const strategies = this.clearTradingDataConfirmPopupSettings.strategies;
      if (!strategies
         || strategies.length === 0) {
         this._toastr.error('Strategies Not Selected');
         return;
      }

      this.onClearTradingDataConfirmPopupClosed();

      const cmd = new ClearTradingData();
      cmd.specificStrategies = strategies;
      cmd.deleteData = false;

      try {
         this._messageBus.publish({
            topic: 'SpecificDatabaseCleanupStarted',
            payload: {}
         });
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Clear Trading Data" Operation Completed With Errors');
      }
   }



   @DetectMethodChanges()
   onClearTradingDataConfirmPopupClosed() {
      this.clearTradingDataConfirmPopupSettings = {};
   }

   //

   private expirationDialogSetLastPx(items: ExpirationDialogItem[]): void {

      items.forEach(item => {
         const lq = this._lastQuoteCache.getLastQuote(item.underlyingDisplayName);
         if (lq) {
            item.expirationPrice = lq.lastPx;
         } else {
            this._lastQuoteCache.subscribeTicker(item.underlyingDisplayName);
         }

         this.expirationDialogSetNetDiff(item);
      });
   }

   //

   private expirationDialogSetNetDiff(item: ExpirationDialogItem) {

      if (isNullOrUndefined(item.openPrice)) {
         return;
      }

      if (isNullOrUndefined(item.expirationPrice)) {
         return;
      }

      const parts = item.tickerDisplayName.split(' ');
      const option = parts[1];
      const strike = parseFloat(parts[2]);
      const diff = Math.abs(item.expirationPrice - strike);

      const isExpiredInTheMoney = option === 'Call'
         ? strike < item.expirationPrice
         : strike > item.expirationPrice;


      if (isExpiredInTheMoney) {
         if (item.netPosition > 0) {

            item.expirationDifference = diff - item.openPrice;

         } else if (item.netPosition < 0) {

            item.expirationDifference = item.openPrice - diff;

         } else {
            item.expirationDifference = 0;
         }

      } else {

         if (item.netPosition > 0) {

            item.expirationDifference = item.openPrice * -1;

         } else if (item.netPosition < 0) {

            item.expirationDifference = item.openPrice;

         } else {

            item.expirationDifference = 0;
         }
      }
   }

   //

   @DetectMethodChanges()
   addInterestRecordManually(data: PortfolioItemDto) {
      this.interestRecordDialogConfig.strategyId = data.portfolioItemId;
      this.interestRecordDialogConfig.isVisible = true;
   }


   @DetectMethodChanges({isAsync: true})
   async interestRecordDialog_onSave() {
      const strategyId = this.interestRecordDialogConfig.strategyId;
      const amount = this.interestRecordDialogConfig.parameters.amount;
      const rate = this.interestRecordDialogConfig.parameters.rate;
      const daysInYear = this.interestRecordDialogConfig.parameters.daysInYear;
      const daysOfInterest = this.interestRecordDialogConfig.parameters.daysOfInterest;

      if (isNullOrUndefined(strategyId)) {
         this._toastr.error('Cannot determine strategy');
         return;
      }
      
      if (isNullOrUndefined(amount)) {
         this._toastr.error('Please provide amount');
         return;
      }
      
      if (isNullOrUndefined(rate)) {
         this._toastr.error('Please provide rate');
         return;
      }
      
      if (isNullOrUndefined(daysInYear)) {
         this._toastr.error('Please provide days in year');
         return;
      }

      if (isNullOrUndefined(daysOfInterest)) {
         this._toastr.error('Please provide days of interest');
         return;
      }

      const cmd = new AddInterestRecord(
         strategyId,
         amount,
         rate,
         daysInYear,
         daysOfInterest
      );

      try {
         this.interestRecordDialogConfig.isLoading = true;
         this._changeDetector.detectChanges();
         await this._shellClient.processCommand(cmd);
      } catch {
         this._toastr.error('"Add Interest Record" operation completed with errors');
      } finally {
         this.interestRecordDialogConfig.isLoading = false;
      }

      
   }

   @DetectMethodChanges()
   interestRecordDialog_onCancel() {
      this.interestRecordDialogConfig.isVisible = false;
   }

   @DetectMethodChanges()
   interestRecordDialog_onHidden() {
      this.interestRecordDialogConfig.isVisible = false;
      this.interestRecordDialogConfig.parameters.reset();
   }

   //

   @DetectMethodChanges()
   showRollLegDialog(data: PortfolioItemDto) {
      
      const config: TargetStrikeDialogConfig = {
         legToRoll: data
      };

      this.targetStrikeDialog.show(config);
   }

   @DetectMethodChanges()
   async changeAttachedLeg(data: PortfolioItemDto) {
      const cmd = new ChangeAttachedLeg(data.portfolioItemId);
      try {
         
         this.isLoading = true;
         
         await this._shellClient.processCommand(cmd);

      } catch (e) {
         
         this._toastr.error('"Change attached leg" operation completed with errors');

      } finally {

         this.isLoading = false;

      }
   }
}

