import {DEFAULT_CURRENCY_CODE, Provider} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {EtsHeadersInterceptor} from 'projects/shared-components/unspecific/ets-headers.interceptor';
import {} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import {WebtraderAccessControlService} from './global-services/wt-access-control.service';
import {WebtraderLastQuoteCacheBackendService} from './global-services/wt-last-quote-cache-backend.service';
import {WebtraderManualTradingBackendService} from './global-services/wt-manual-trading-backend.service';
import {WebtraderStrategiesServiceBackendService} from './global-services/wt-strategies-service-backend.service';
import {WebtraderAggregatedPositionsBackendService} from './global-services/wt-aggregated-positions-backend.service';
import {
    StrategiesTradingDataBackendService
} from 'projects/shared-components/strategies/strategies-trading-data-backend-service.interface';
import {
    WebtraderStrategiesTradingDataBackendService
} from './global-services/wt-strategies-trading-data-backend.service';
import {
    StrategiesIssuesBackendService
} from 'projects/shared-components/strategies/strategy-issues-backend-service.interface';
import {WebtraderStrategiesIssuesBackendService} from './global-services/wt-strategies-issues-backend.service';
import {
    PriceDataFeedBackendService
} from 'projects/shared-components/price-chart/price-data-feed-backend-service.class';
import {WebtraderPriceDataFeedBackendService} from './global-services/wt-price-data-feed-backend.service';
import {AccessControlService} from 'projects/shared-components/access-control-service.class';
import {
    AggregatedPositionsBackendService
} from 'projects/shared-components/aggregated-positions/aggregated-positions-backend.service';
import {
    TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import {LastQuoteCacheBackendService} from 'projects/shared-components/last-quote-cache-backend-service.class';
import {ManualTradingBackendService} from 'projects/shared-components/manual-trading/manual-trading-backend.service';
import {
    StrategiesServiceBackendService
} from 'projects/shared-components/strategies/strategies-service-backend.service';
import {HpDatasetsBackendService} from 'projects/shared-components/hp-datasets/hp-datasets-backend-service.interface';
import {WebtraderHpDatasetsBackendService} from './global-services/wt-hp-dataset-backend.service';
import {
    OpenPositionSnapshotsBackendService
} from 'projects/shared-components/open-position-chart/open-position-snapshots-backend.interface';
import {
    WebtraderOpenPositionSnapshotsBackendService
} from './global-services/wt-open-position-snapshots-backend.service';
import {WebtraderTradingInstrumentsService} from './global-services/wt-trading-instruments.service';

export const WebtraderTypeRegistrations: Provider[] = [
    {provide: HTTP_INTERCEPTORS, useClass: EtsHeadersInterceptor, multi: true},

    {provide: TradingInstrumentsService, useClass: WebtraderTradingInstrumentsService},
    {provide: AccessControlService, useClass: WebtraderAccessControlService},
    {provide: LastQuoteCacheBackendService, useClass: WebtraderLastQuoteCacheBackendService},
    {provide: ManualTradingBackendService, useClass: WebtraderManualTradingBackendService},
    {provide: StrategiesServiceBackendService, useClass: WebtraderStrategiesServiceBackendService},
    {provide: AggregatedPositionsBackendService, useClass: WebtraderAggregatedPositionsBackendService},
    {provide: StrategiesTradingDataBackendService, useClass: WebtraderStrategiesTradingDataBackendService},
    {provide: StrategiesIssuesBackendService, useClass: WebtraderStrategiesIssuesBackendService},
    {provide: PriceDataFeedBackendService, useClass: WebtraderPriceDataFeedBackendService},
    {provide: HpDatasetsBackendService, useClass: WebtraderHpDatasetsBackendService},
    {provide: OpenPositionSnapshotsBackendService, useClass: WebtraderOpenPositionSnapshotsBackendService},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'USD'}
];
