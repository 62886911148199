<div class="root-container" #container>
  
  <div class="section-caption">
    Hedge Positions
  </div>
  
  <div class="positions-section">
    
    <!-- N/A -->
    <ng-container *ngIf="!this.hasLegs">
      <div class="na">
        N/A
      </div>
      <ng-container *ngIf="this.hasPortfolio">
        <ng-container *ngTemplateOutlet="separator2"></ng-container>
      </ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.hasCalls">
      <ng-container *ngTemplateOutlet="positions; context: {$implicit: {side: 'Call'}}"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.hasLegs && this.hasCalls">
        <ng-container *ngTemplateOutlet="separator2"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.hasPuts">
      <ng-container *ngTemplateOutlet="positions; context: {$implicit: {side: 'Put'}}"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.hasLegs && this.hasPuts && !this.hasCalls">
      <ng-container *ngTemplateOutlet="separator2"></ng-container>
    </ng-container>
    
    <ng-container *ngIf="this.hasPortfolio">
      <div class="buttons">
        <div class="btn clear-after-state">
          <dx-button
            (onClick)="this.clearAfterState()"
            [disabled]="!this.hasAnyAfterState()"
            text="Clear Saved State"
            type="normal"
            width="100%"
          ></dx-button>
        </div>
        
        <div class="btn clear-all">
          <dx-button text="Clear Hedges" width="100%" (onClick)="this.clearHedges()"></dx-button>
        </div>

        <div class="btn defaults">
          <dx-button width="100%" text="Load Defaults" (onClick)="this.resetToDefaults()">
          </dx-button>
        </div>
      </div>
    </ng-container>
  
  </div>
  
  <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: container}">
  </dx-load-panel>
</div>

<ets-hg-after-state-preview></ets-hg-after-state-preview>

<ng-template #separator2>
  <div class="separator double">
    <div (click)="this.showAfterStatePreview('first')" [class.hot]="this.hasAfterState('first')"
         class="button"
    >
      C
    </div>
    <div class="line double">
      <hr style="width: 100px">
    </div>
    <div (click)="this.showAfterStatePreview('second')" [class.hot]="this.hasAfterState('second')"
         class="button"
    >
      P
    </div>
  </div>
</ng-template>

<ng-template #positions let-ctx>
  <ng-container *ngFor="let pGroup of this.getLegGroups(ctx.side); last as isLast">
    
    <div [style.border-color]="pGroup.groupColor" [style.color]="pGroup.groupColor" class="group-label">
      <span>{{ pGroup.groupLabel }}</span>
      <span>
        <i (click)="this.removeHedge(pGroup, ctx.side)" class="fa fa-times-circle remove-group"></i>
      </span>
    </div>
    
    <div *ngFor="let hedgePosition of pGroup.legs" class="position">
      
      <div class="part side">
        <dx-text-box [inputAttr]="{'style': ('color: ' + pGroup.groupColor)}"
                     [readOnly]="true"
                     [value]="hedgePosition.side"
        ></dx-text-box>
      </div>
      
      <div class="part qty">
        <dx-number-box [inputAttr]="{'style': ('color: ' + pGroup.groupColor)}"
                       [readOnly]="true"
                       [value]="hedgePosition.qty"
        ></dx-number-box>
      </div>
      
      <div class="part type">
        <dx-text-box [inputAttr]="{'style': ('color: ' + pGroup.groupColor)}"
                     [readOnly]="true"
                     [value]="hedgePosition.type"
        ></dx-text-box>
      </div>
      
      <div [ngClass]="{'dependent': false}" class="part expiration">
        <dx-select-box
          [inputAttr]="{'style': ('color: ' + pGroup.groupColor)}"
          [items]="hedgePosition.expirationList"
          [readOnly]="true"
          [value]="hedgePosition.expirationDescriptor"
          displayExpr="dateWithDaysToExpiration"
        >
          <div *dxTemplate="let data of 'item'">
                    <span [style.color]="this.getExpirationColorRank(data, hedgePosition)">
                      {{ data?.dateWithDaysToExpiration }}
                    </span>
          </div>
        </dx-select-box>
      </div>
      
      <div class="part strike">
        <dx-number-box
          [inputAttr]="{'style': ('color: ' + pGroup.groupColor)}"
          [readOnly]="true"
          [value]="hedgePosition.strike"
        ></dx-number-box>
      </div>
    </div>
    
  </ng-container>
</ng-template>

<ets-generic-confirmation-dialog title="Please Confirm Action">
</ets-generic-confirmation-dialog>
