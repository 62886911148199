import {NgModule} from '@angular/core';

import {OverrideAtmDialogComponent} from './override-atm-dialog.component';
import {DevExtremeModule} from "../devextreme.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule
    ],
    exports: [OverrideAtmDialogComponent],
    declarations: [OverrideAtmDialogComponent],
    providers: [],
})
export class OverrideAtmDialogModule {
}
