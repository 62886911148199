import { Injectable } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { AdjsutmentTemplateToClientsMap, GetAssignableTemplatesReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { AssignAdjustmentTemplates, GetAssignableAdjustmentTemplates } from 'projects/shared-components/shell-communication/shell-operations-protocol';


@Injectable()
export class AssignableAdjustmentTemplatesService {
   
   constructor(
      private readonly _shellService: ShellClientService,
   ) {
      
   }
   //
   async getSharedTemplates(): Promise<AdjsutmentTemplateToClientsMap[]> {

      const qry = new GetAssignableAdjustmentTemplates();

      const reply = await this._shellService.processQuery<GetAssignableTemplatesReply>(qry);
      
      return reply.templates;
   }

   //
   saveSharedTemplatesAssignments(templates: AdjsutmentTemplateToClientsMap[]): Promise<void> {
      const cmd = new AssignAdjustmentTemplates(templates);
      return this._shellService.processCommand(cmd);
   }
}