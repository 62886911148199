<div style="cursor: pointer; position: relative" id="jobs-tracker-action-button" title="Jobs Tracker">
   
   <span (click)="this.onActionButtonClick()">
      <dx-load-indicator title="Has Jobs Running" [visible]="this.hasRunningJobs"></dx-load-indicator>
      <i title="No Running Jobs" class="far fa-check-square fa-2x" *ngIf="!this.hasRunningJobs" [style.cursor]="'pointer'" [style.color]="iconColor"></i>
   </span>

   <div class="overlay" [hidden]="!isOverlayVisible" [style.left.px]="overlayPosition?.left"
      [style.bottom.px]="overlayPosition?.bottom">
      <div class="content">
         <ag-grid-angular style="width: 100%;height: 100%" class="ag-theme-balham-dark"
            [gridOptions]="this.jobsGridOptions"></ag-grid-angular>
      </div>
   </div>
</div>
