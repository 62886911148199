<div class="host-menu" id="resource-menu">

  <dx-button (onClick)="onActionButtonClick($event)"
             class="main-button"
             height="100%"
             text="Resources"
             width="100%"
             [type]="this.needsAttention() ? 'danger' : 'normal'"
  >
  </dx-button>

  <div [hidden]="!isOverlayVisible"
       [style.left.px]="this.overlayLeft"
       [style.top.px]="this.overlayTop"
       [style.width.px]="this.overlayWidth"
       class="overlay"
  >
    <div *ngFor="let button of this.menuItems">
      <dx-button (onClick)="this.onButtonClick(button)"
                 [text]="button.title"
                 [type]="this.needsAttention(button) ? 'danger' : 'normal'"
                 class="button"
      ></dx-button>
    </div>
  </div>

</div>

<dx-popup (onHidden)="this.hideArticle()"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [resizeEnabled]="true"
          [title]="this.article?.title"
          [visible]="this.article?.visible"
          height="90vh"
          width="60vw">
  <div *dxTemplate="let data of 'content'" style="position: relative">
    <dx-scroll-view>
      <div [innerHTML]="this.article?.content | allowStyles"></div>
    </dx-scroll-view>
  </div>
</dx-popup>
