import {CashFlowStrategy} from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import {SessionService} from "projects/shared-components/authentication/session-service.service";
import {LocationService} from "projects/shared-components/location.service";
import {TradingInstrument} from "projects/shared-components/trading-instruments/trading-instrument.class";
import {
    TradingInstrumentsService
} from "projects/shared-components/trading-instruments/trading-instruments-service.interface";
import {isNullOrUndefined} from "util";
import {IExpirationSmartModeSettings, ExpirationSmartModeSettings} from "./ExpirationSmartModeSettings";
import {AccessControlService} from "projects/shared-components/access-control-service.class";
import {ChangeDetectorRef} from "@angular/core";
import {set} from "devextreme/events/core/events_engine";

export interface IAdjustmentPricingGridSettings {
    defaultUnderlying?: string;
    defaultStrategy?: CashFlowStrategy;
    matchExpirations?: boolean;
    prefixTemplates?: boolean;
    linkStrikes?: boolean;
    expirationSmartMode?: IExpirationSmartModeSettings;
    showChecklistDrafts?: boolean;
    useMarginEfficientAdjustment?: boolean;
    useCharlesSchwabFix?: boolean;
    atmWarningMode?: AtmWarningMode;
}

export type AtmWarningMode = 'Yellow Background' | 'Popup' | 'None';

export class AdjustmentPricingGridSettingsModel implements IAdjustmentPricingGridSettings {

    constructor(
        locationService: LocationService,
        tiService: TradingInstrumentsService,
        private readonly _sessionService: SessionService,
        private readonly _accessControlService: AccessControlService,
        settings: IAdjustmentPricingGridSettings,
        private readonly _changeDetector: ChangeDetectorRef
    ) {

        //FIXME: tradingmasteryhq.net
        if (!locationService.isEtsHost) {
            if (!_sessionService.isSuperUser) {

                this.strategies = [
                    'Calls & Puts'
                ];

                if (_sessionService.login) {
                    if (_sessionService.login.toLowerCase().startsWith('karen@')) {
                        this.strategies.push('Puts');
                    }
                }
            }
        }

        this.fillAtmWarningModes();

        this.defaultStrategy = settings.defaultStrategy || 'Calls & Puts';
        this.prefixTemplates = settings.prefixTemplates || false;
        this.matchExpirations = isNullOrUndefined(settings.matchExpirations) || true;
        this.tradingInstrument = tiService.getInstrumentByTicker(settings.defaultUnderlying);
        this.linkStrikes = isNullOrUndefined(settings.linkStrikes) ? true : settings.linkStrikes;
        this.expirationSmartMode = new ExpirationSmartModeSettings(settings.expirationSmartMode);
        this.showChecklistDrafts = settings.showChecklistDrafts;
        this._useMarginEfficientAdjustment = settings.useMarginEfficientAdjustment;
        this.useCharlesSchwabFix = settings.useCharlesSchwabFix;
        this.atmWarningMode = settings.atmWarningMode;
    }

    get showChecklistDraftsAvailable(): boolean {
        return this._accessControlService.isSecureElementAvailable('0d492b1a-c29c-410d-b193-33988dd809f0');
    }

    strategies: CashFlowStrategy[] = [
        'Hedged Portfolio',
        'Reversed Hedged Portfolio',
        'Calls',
        'Puts',
        'Calls & Puts'
    ];

    tradingInstrument: TradingInstrument;

    defaultStrategy: CashFlowStrategy;

    prefixTemplates = false;

    matchExpirations = true;

    linkStrikes: boolean;

    expirationSmartMode: IExpirationSmartModeSettings;

    showChecklistDrafts = false;

    private _useMarginEfficientAdjustment = false;

    get useMarginEfficientAdjustment(): boolean {
        return this._useMarginEfficientAdjustment;
    }

    set useMarginEfficientAdjustment(value: boolean) {
        this._useMarginEfficientAdjustment = value;
        this._changeDetector.detectChanges();
    }

    useCharlesSchwabFix = false;

    atmWarningModes: AtmWarningMode[] = []

    atmWarningMode: AtmWarningMode;

    get defaultUnderlying(): string {
        return this.tradingInstrument ? this.tradingInstrument.ticker : null;
    }

    getParameters(): IAdjustmentPricingGridSettings {
        return {
            defaultStrategy: this.defaultStrategy,
            prefixTemplates: this.prefixTemplates,
            defaultUnderlying: this.tradingInstrument.ticker,
            matchExpirations: this.matchExpirations,
            linkStrikes: this.linkStrikes,
            expirationSmartMode: this.expirationSmartMode,
            showChecklistDrafts: this.showChecklistDrafts,
            useMarginEfficientAdjustment: this._useMarginEfficientAdjustment,
            useCharlesSchwabFix: this.useCharlesSchwabFix,
            atmWarningMode: this.atmWarningMode
        };
    }

    private fillAtmWarningModes() {
        if (this._accessControlService.isSecureElementAvailable('ee6ad2a1-a145-4682-8cd2-bf471bd6c840') ||
            this._sessionService.isSuperUser) {
            this.atmWarningModes.push('Yellow Background');
        }

        if (this._accessControlService.isSecureElementAvailable('3ddfa162-26bd-4577-b321-3a6157305888') ||
            this._sessionService.isSuperUser) {
            this.atmWarningModes.push('Popup');
        }

        if (this._accessControlService.isSecureElementAvailable('b2e3f7c2-5bde-412c-bae5-b0348b3ad3f2') ||
            this._sessionService.isSuperUser) {
            this.atmWarningModes.push('None');
        }
    }
}
