import { EventEmitter } from "@angular/core";
import { OptionExpirationDescriptor } from "projects/shared-components/shell-communication/shell-dto-protocol";
import {
   areTemplateSettingsValuesEqual,
   DxValueChanged,
   getValueOrNull,
   isDefaultTemplate,
   isVoid
} from "projects/shared-components/utils";
import { ICashFlowAdjustmentSettingsTemplate } from "../../model/ICashFlowAdjustmentSettingsTemplate";
import { ICashFlowExpirationSettings } from "../../model/ICashFlowExpirationSettings";


export interface ExpirationsSettingsChangedEvent {
   property: keyof CashFlowStrategyExpirationsSettingsModel;
   value: any,
   customDatesAction?: 'add' | 'remove'
}


interface ContextDataProvider {
   getSelectedTemplate(): ICashFlowAdjustmentSettingsTemplate;
   isOperationInProgress(): boolean;
}


export class CashFlowStrategyExpirationsSettingsModel implements ICashFlowExpirationSettings {

   constructor(
       private readonly _contextProvider: ContextDataProvider
   ) {
   }


   parameterChanged$ = new EventEmitter<ExpirationsSettingsChangedEvent>();


   expirations: OptionExpirationDescriptor[] = [];


   customDates: { value?: string }[] = [];


   joinRollBuffer: number;


   expirationsToLookForward: number


   onOptionChainUpdated(expirations: OptionExpirationDescriptor[]) {
      this.expirations = expirations;
      this.parameterChanged$.emit({
         property: 'expirations',
         value: expirations
      });
   }


   addCustomDate() {
      const value = {};
      this.customDates.push(value);
      this.parameterChanged$.emit({
         property: 'customDates',
         value: value,
         customDatesAction: 'add'
      });
   }


   removeCustomDate(ix: number) {
      const v = this.customDates.splice(ix, 1);
      this.parameterChanged$.emit({
         property: 'customDates',
         value: v,
         customDatesAction: 'remove'
      });
   }


   onParameterChanged(event: DxValueChanged<any>, setting: keyof CashFlowStrategyExpirationsSettingsModel) {
      if (!event.event) {
         return;
      }

      this.parameterChanged$.emit({
         property: setting,
         value: event.value
      });
   }


   applyTemplate(template: ICashFlowAdjustmentSettingsTemplate) {
      this.joinRollBuffer = template.expirationSettings.joinRollBuffer;
      this.expirationsToLookForward = template.expirationSettings.expirationsToLookForward;
      this.customDates = template.expirationSettings.customDates || [];
   }


   getSettings(): ICashFlowExpirationSettings {
      return {
         joinRollBuffer: getValueOrNull(this.joinRollBuffer),
         expirationsToLookForward: getValueOrNull(this.expirationsToLookForward),
         customDates: (this.customDates || []).slice(),
      }
   }


   isDifferentFromTemplate(): boolean {

      if (this._contextProvider.isOperationInProgress()) {
         return false;
      }

      const template = this._contextProvider.getSelectedTemplate();

      if (isVoid(template)) {
         return false;
      }

      const originalValue = template.expirationSettings.expirationsToLookForward;

      const currentValue = this.expirationsToLookForward;

      let areEqual = areTemplateSettingsValuesEqual(originalValue,currentValue);

      return !areEqual;
   }
}
