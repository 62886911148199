<div class="panel-container" [ngClass]="{'ets-hidden': !isActive}" #container>
   
   <div class="combo-selector">
      
      <!-- Bucket Selector Row -->
      <div class="section selectors">

         <!-- Strategies -->
         <div class="bucket">
            <p>Cash Flow Strategies</p>
            <dx-select-box [items]="this.cashFlowStrategies" 
                           [(value)]="this.selectedCashFlowStrategy" 
                           (onValueChanged)="this.onStrategyChanged()"
                           [showClearButton]="true"></dx-select-box>
         </div>

         <!-- Mode -->
         <div class="bucket">
            <p>Mode</p>
            <dx-select-box [items]="this.cpModes"
                           [(value)]="this.selectedCpMode"
                           (onValueChanged)="this.onCpModeChanged()" 
                           [showClearButton]="true"
                           ></dx-select-box>
         </div>

         <!-- Terminal -->
         <div class="bucket">
            <p>Terminal</p>
            <dx-select-box [items]="this.terminals" 
                           [(value)]="this.selectedTerminal" 
                           (onValueChanged)="this.onTerminalChanged()"
                           [showClearButton]="true"
                           displayExpr="displayName"></dx-select-box>
         </div>

         <!-- Portfolio -->
         <div class="bucket">
            <p>Portfolio</p>
            <dx-select-box [items]="this.portfolios" 
                           [(value)]="this.selectedPortfolio" 
                           (onValueChanged)="this.onPortfolioChanged()"
                           [showClearButton]="true"
                           displayExpr="portfolioName"></dx-select-box>
         </div>

         <!-- Combo -->
         <div class="bucket">
            <p>Combo</p>
            <dx-select-box [items]="this.combos" 
                           [(value)]="this.selectedCombo" 
                           (onValueChanged)="this.onComboChanged()"
                           [showClearButton]="true"
                           displayExpr="comboName"></dx-select-box>
         </div>

      </div>

      <!-- PnLs -->
      <div class="section pnls" #target (click)="this.isPnLsPopoverVisible = !this.isPnLsPopoverVisible">
         <div class="labels">
            <div class="section left">Sess.PnL</div>
            <div class="section middle">
               &nbsp;
            </div>
            <div class="section right">Acc.PnL</div>
         </div>
         <div class="values">
            <div class="section left pnl" [ngClass]="{
                                                         'profit': this.comboSessionPnL > 0, 
                                                         'loss': this.comboSessionPnL < 0
                                                     }">
                  {{this.comboSessionPnL | currency:'USD':'symbol':'1.2-2'}}
            </div>
            <div class="section middle">
               &nbsp;|&nbsp;
            </div>
            <div class="section right pnl" [ngClass]="{
                                                         'profit': this.comboAccPnL > 0, 
                                                         'loss': this.comboAccPnL < 0
                                                      }">
                  {{this.comboAccPnL | currency:'USD':'symbol':'1.2-2'}}
            </div>
         </div>
      </div>

      <!-- Bucket Qty Guard -->
      <div class="section bucket-qty-guard">
         <div class="labels">
            <p>Bucket Qty Guard</p>
         </div>
         <div class="value">
            <div class="section input">
               <dx-number-box [(value)]="this.bucketQtyGuard" 
                              [min]="1" 
                              [step]="1" 
                              [showSpinButtons]="true"
                              [useLargeSpinButtons]="false" format="#,##0"
                              [disabled]="!this.canChangeBucketQtyGuard">
               </dx-number-box>
            </div>
            <div class="section button">
               <dx-button text="Update" type="normal" (onClick)="this.updateBucketQtyGuard()"
                  [disabled]="!this.canChangeBucketQtyGuard"></dx-button>
            </div>
         </div>
      </div>

      <dx-popover
         [target]="target"
         position="bottom"
         [width]="415"
         [(visible)]="this.isPnLsPopoverVisible"
      >
         <div *dxTemplate="let data = model; of: 'content'">
         <table class="pnl-table">
            <thead>
               <tr>
                  <th class="name-col">Bucket</th>
                  <th class="pnl-head">PnL</th>
               </tr>
            </thead>
            <tbody>
               
               <!-- Portfolio -->
               <tr>
                  <td class="name-col">{{this.portfolioBlock.displayName}}</td>
                  <td class="value-col">
                     <div class="col session">
                        S: <span class="pnl" [ngClass]="{'profit': this.portfolioBlock.sessionPnL > 0, 'loss': this.portfolioBlock.sessionPnL < 0}">{{this.portfolioBlock.sessionPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                     <div class="col">&nbsp;|&nbsp;</div>
                     <div class="col accumulated">
                        A: <span class="pnl" [ngClass]="{'profit': this.portfolioBlock.accumulatedPnL > 0, 'loss': this.portfolioBlock.accumulatedPnL < 0}">{{this.portfolioBlock.accumulatedPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                  </td>
               </tr>

               <!-- Interest -->
               <tr>
                  <td class="name-col">{{this.interestBlock.displayName}}</td>
                  <td class="value-col">
                     <div class="col session">
                        S: <span class="pnl" [ngClass]="{'profit': this.interestBlock.sessionPnL > 0, 'loss': this.interestBlock.sessionPnL < 0}">{{this.interestBlock.sessionPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                     <div class="col"c>&nbsp;|&nbsp;</div>
                     <div class="col accumulated">
                        A: <span class="pnl" [ngClass]="{'profit': this.interestBlock.accumulatedPnL > 0, 'loss': this.interestBlock.accumulatedPnL < 0}">{{this.interestBlock.accumulatedPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                  </td>
               </tr>

               <!-- Long Term -->
               <tr>
                  <td class="name-col">{{this.longTermOptionBlock.displayName}}</td>
                  <td class="value-col">
                     <div class="col session">
                        S: <span class="pnl" [ngClass]="{'profit': this.longTermOptionBlock.sessionPnL > 0, 'loss': this.longTermOptionBlock.sessionPnL < 0}">{{this.longTermOptionBlock.sessionPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                     <div class="col">&nbsp;|&nbsp;</div>
                     <div class="col accumulated">
                        A: <span class="pnl" [ngClass]="{'profit': this.longTermOptionBlock.accumulatedPnL > 0, 'loss': this.longTermOptionBlock.accumulatedPnL < 0}">{{this.longTermOptionBlock.accumulatedPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                  </td>
               </tr>
               
               <!-- Short Term -->
               <tr>
                  <td class="name-col">{{this.shortTermDebitSpreadBlock.displayName}}</td>
                  <td class="value-col">
                     <div class="col session">
                        S: <span class="pnl" [ngClass]="{'profit': this.shortTermDebitSpreadBlock.sessionPnL > 0, 'loss': this.shortTermDebitSpreadBlock.sessionPnL < 0}">{{this.shortTermDebitSpreadBlock.sessionPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                     <div class="col">&nbsp;|&nbsp;</div>
                     <div class="col accumulated">
                        A: <span class="pnl" [ngClass]="{'profit': this.shortTermDebitSpreadBlock.accumulatedPnL > 0, 'loss': this.shortTermDebitSpreadBlock.accumulatedPnL < 0}">{{this.shortTermDebitSpreadBlock.accumulatedPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                  </td>
               </tr>

               <!-- Short Options -->
               <tr>
                  <td class="name-col">{{this.shortOptionsBlock.displayName}}</td>
                  <td class="value-col">
                     <div class="col session">
                        S: <span class="pnl" [ngClass]="{'profit': this.shortOptionsBlock.sessionPnL > 0, 'loss': this.shortOptionsBlock.sessionPnL < 0}">{{this.shortOptionsBlock.sessionPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                     <div class="col">&nbsp;|&nbsp;</div>
                     <div class="col accumulated">
                        A: <span class="pnl" [ngClass]="{'profit': this.shortOptionsBlock.accumulatedPnL > 0, 'loss': this.shortOptionsBlock.accumulatedPnL < 0}">{{this.shortOptionsBlock.accumulatedPnL | currency:'USD':'symbol':'1.2-2'}}</span>
                     </div>
                  </td>
               </tr>

            </tbody>
         </table>
         </div>
      </dx-popover>

   </div>

   <div class="control-panel">
      <dx-box direction="row" height="100%" width="100%">
         
         <dxi-item ratio="1" class="navigation"> 
            <fieldset>
               <legend>Navigation</legend>
               <ul class="menu">
                  
                  <li *ngIf="this.selectedCombo" class="menu-item" (click)="this.scrollTo('overview')">
                     Overview
                  </li>

                  <li *ngIf="this.selectedCombo" class="menu-item" (click)="this.scrollTo('ordersBlock')">
                     Working Orders
                  </li>

                  <li *ngIf="this.showGlobalSettingsSection" class="menu-item" (click)="this.scrollTo('commonSettings')">
                     Common Settings
                  </li>
                  
                  <li *ngIf="this.shortOptionsBlock.isVisible" class="menu-item" (click)="this.scrollTo('sellOptionsBlock')">
                     {{ this.shortOptionsBlock.displayName || 'Short Options'}}
                  </li>
                  
                  <li *ngIf="this.shortTermDebitSpreadBlock.isVisible" class="menu-item" (click)="this.scrollTo('shortTermPutSpreadBlock')">
                     {{ this.shortTermDebitSpreadBlock.displayName || 'Short Term Put Spread'}}
                  </li>
                  
                  <li *ngIf="this.longTermOptionBlock.isVisible" class="menu-item" (click)="this.scrollTo('shortTermPutSpreadBlock')">
                     {{ this.longTermOptionBlock.displayName || 'Long Term Put'}}
                  </li>

                  <li *ngIf="this.portfolioBlock.isVisible && !this.showGlobalSettingsSection" class="menu-item" (click)="this.scrollTo('portfolioBlock')">
                     {{this.portfolioBlock.displayName || 'Portfolio'}}
                  </li>
                  
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  
                  <li *ngIf="this.interestBlock.isVisible" class="menu-item" (click)="this.scrollTo('interestBlock')">
                     {{this.interestBlock.displayName || 'Interest'}}
                  </li>

                  <li class="menu-item" *ngIf="this.interestBlock.isVisible">
                     Position Sizing *
                  </li>
               </ul>
            </fieldset>
         </dxi-item>
         
         <dxi-item ratio="4" class="contents"> 
      
            <dx-scroll-view direction="vertical">

               <!-- Overview -->
               <fieldset class="section" id="overview">
                  <legend>Overview</legend>
                  
                  <div class="content overview">

                     <table style="width: 100%; height: 1px; border-spacing: 0 1em; border-collapse: separate;">
                       
                        <colgroup *ngIf="this.portfolioBlock.isVisible">
                           <col span="1" name="col1" style="width: 20%;">
                           <col span="1" name="col2" style="width: 20%;">
                           <col span="1" name="col3" style="width: 20%;">
                           <col span="1" name="col4" style="width: 20%;">
                           <col span="1" name="col5" style="width: 20%;">
                        </colgroup>

                        <colgroup *ngIf="!this.portfolioBlock.isVisible">
                           <col span="1" name="col1" style="width: 25%;">
                           <col span="1" name="col2" style="width: 25%;">
                           <col span="1" name="col3" style="width: 25%;">
                           <col span="1" name="col4" style="width: 25%;">
                        </colgroup>

                        <tr>
                           
                           <td [attr.rowspan]="this.isSingleDirection ? 1 : 2" class="overview-block" *ngIf="this.portfolioBlock.isVisible">
                              <fieldset [style.border-color]="this.portfolioBlock.overviewBlockColor">
                                 <legend>{{this.portfolioBlock.displayName || 'Portfolio'}}</legend>
                                 <ets-portfolio-overview-block style="width: 99%;" [viewModel]="this.portfolioBlock.getViewModel()"></ets-portfolio-overview-block>
                              </fieldset>
                           </td>
                           
                           <td [attr.rowspan]="this.isSingleDirection ? 1 : 2" class="overview-block">
                              <fieldset [style.border-color]="this.interestBlock.overviewBlockColor" [style.display]="this.interestBlock.isVisible ? 'inherit' : 'none'">
                                 <legend>{{this.interestBlock.displayName || 'Interest'}}</legend>
                                 <ets-interest-overview-block [viewModel]="this.interestBlock.getViewModel()"></ets-interest-overview-block>
                              </fieldset>
                           </td>
                           
                           <td class="overview-block">
                              <fieldset 
                                 [style.border-color]="this.longTermOptionBlock.overviewBlockColor" 
                                 [style.display]="this.longTermOptionBlock.isVisible ? 'inherit' : 'none'"
                                 >
                                 <legend>{{ this.longTermOptionBlock.displayName || 'Protective Option'}}</legend>
                                 <ets-long-term-put-overview-block 
                                    [viewModel]="this.longTermOptionBlock.getViewModel(1)"
                                    >
                                 </ets-long-term-put-overview-block>
                              </fieldset>
                           </td>
                           
                           <td class="overview-block">
                              <fieldset 
                                 [style.border-color]="this.shortTermDebitSpreadBlock.overviewBlockColor" 
                                 [style.display]="this.shortTermDebitSpreadBlock.isVisible ? 'inherit' : 'none'"
                                 >
                                 <legend>{{ this.shortTermDebitSpreadBlock.displayName || 'Debit Spread'}}</legend>
                                 <ets-short-term-spread-overview-block 
                                    [viewModel]="this.shortTermDebitSpreadBlock.getViewModel(1)"
                                    >
                                 </ets-short-term-spread-overview-block>      
                              </fieldset>
                           </td>
   
                           <td class="overview-block">
                              <fieldset 
                                 [style.border-color]="this.shortOptionsBlock.overviewBlockColor" 
                                 [style.display]="this.shortOptionsBlock.isVisible ? 'inherit' : 'none'"
                                 >
                                 <legend>{{ this.shortOptionsBlock.displayName || 'Short Option'}}</legend>
                                 <ets-short-options-overview-block 
                                    [viewModel]="this.shortOptionsBlock.getViewModel(1)">
                                 </ets-short-options-overview-block>
                              </fieldset>
                           </td>

                        </tr>

                        <tr *ngIf="!this.isSingleDirection">
   
                           <td class="overview-block">
                              <fieldset 
                                 [style.border-color]="this.longTermOptionBlock.overviewBlockColor" 
                                 [style.display]="this.longTermOptionBlock.isVisible ? 'inherit' : 'none'"
                                 >
                                 <legend>{{ this.longTermOptionBlock.displayName2 || 'Long Term Option'}}</legend>
                                 <ets-long-term-put-overview-block 
                                    [viewModel]="this.longTermOptionBlock.getViewModel(2)"
                                    >
                                 </ets-long-term-put-overview-block>
                              </fieldset>
                           </td>
                           
                           <td class="overview-block">
                              <fieldset 
                                 [style.border-color]="this.shortTermDebitSpreadBlock.overviewBlockColor" 
                                 [style.display]="this.shortTermDebitSpreadBlock.isVisible ? 'inherit' : 'none'"
                                 >
                                 <legend>{{ this.shortTermDebitSpreadBlock.displayName2 || 'Debit Spread'}}</legend>
                                 <ets-short-term-spread-overview-block 
                                    [viewModel]="this.shortTermDebitSpreadBlock.getViewModel(2)"
                                    >
                                 </ets-short-term-spread-overview-block>      
                              </fieldset>
                           </td>
   
                           <td class="overview-block">
                              <fieldset 
                                 [style.border-color]="this.shortOptionsBlock.overviewBlockColor" 
                                 [style.display]="this.shortOptionsBlock.isVisible ? 'inherit' : 'none'"
                                 >
                                 <legend>{{ this.shortOptionsBlock.displayName2 || 'Short Option'}}</legend>
                                 <ets-short-options-overview-block 
                                    [viewModel]="this.shortOptionsBlock.getViewModel(2)">
                                 </ets-short-options-overview-block>
                              </fieldset>
                           </td>
                           
                        </tr>
                        
                        <!-- separator -->
                        <tr *ngIf="this.selectedCombo">
                           <td [attr.colspan]="this.isSingleDirection ? 5 : 4">
                              <hr style="width: 40%" />
                           </td>
                        </tr>

                        <ng-container *ngIf="this.isSingleDirection">
                           <tr>
                              <td class="overview-block" *ngFor="let strat of this.strategies | slice:0:5">
                                 <fieldset [style.border-color]="'green'">
                                    <legend>{{strat.strategy?.displayName}}</legend>
                                    <ets-strategy-overview-block [strategy]="strat"></ets-strategy-overview-block>
                                 </fieldset>
                              </td>
                           </tr>
                           
                           <tr *ngIf="this.strategies.length > 5">
                              <td class="overview-block" *ngFor="let strat of this.strategies | slice:5">
                                 <fieldset [style.border-color]="'green'">
                                    <legend>{{strat.strategy?.displayName}}</legend>
                                    <ets-strategy-overview-block [strategy]="strat"></ets-strategy-overview-block>
                                 </fieldset>
                              </td>
                           </tr>
                        </ng-container>

                        <ng-container *ngIf="!this.isSingleDirection">
                           <tr>
                              <td class="overview-block" *ngFor="let strat of this.strategies | slice:0:4">
                                 <fieldset [style.border-color]="'green'">
                                    <legend>{{strat.strategy?.displayName}}</legend>
                                    <ets-strategy-overview-block [strategy]="strat"></ets-strategy-overview-block>
                                 </fieldset>
                              </td>
                           </tr>
                           
                           <tr *ngIf="this.strategies.length > 4">
                              <td class="overview-block" *ngFor="let strat of this.strategies | slice:4">
                                 <fieldset [style.border-color]="'green'">
                                    <legend>{{strat.strategy?.displayName}}</legend>
                                    <ets-strategy-overview-block [strategy]="strat"></ets-strategy-overview-block>
                                 </fieldset>
                              </td>
                           </tr>
                        </ng-container>

                     </table>
                  </div>

                  <dx-load-panel [visible]="this.portfolioBlock.isLoading" [position]="{my: 'center', at: 'center', of: '#portfolioBlock'}"></dx-load-panel>

               </fieldset>

               <!-- Orders -->
               <fieldset class="section" id="ordersBlock" *ngIf="this.selectedCombo">
                  <legend>
                     Working Orders
                     <dx-button style="margin-top: -3px"  [icon]="this.ordersBlock.collapsed ? 'chevrondown' : 'chevronup'" (onClick)="this.ordersBlock.collapsed = !this.ordersBlock.collapsed"></dx-button>
                  </legend>

               
                  <div class="content orders" [style.height.px]="this.ordersBlock.height" [style.display]="this.ordersBlock.collapsed ? 'none' : 'block'">
                     <ag-grid-angular style="width: 100%; height: 100%" 
                              class="ag-theme-balham-dark"
                              [gridOptions]="this.ordersBlock.gridOptions">
                     </ag-grid-angular>
                  </div>                  
               </fieldset>

                <!-- Global Settings * -->
                <fieldset class="section" id="globalSettings" *ngIf="this.showGlobalSettingsSection">
                  <legend>
                     Global Settings
                  </legend>
               
                  <div class="content">
                     
                     <div class="parameters global-settings">

                        <div class="column roll-settings">
                           <fieldset>
                              <legend>Roll Settings</legend>

                              <div class="row">
                                 <div class="col1">Roll Time & Mode</div>
                                 <div class="col2">
                                    <div class="roll-mode">
                                       <p>Mode</p>
                                       <dx-select-box [items]="this.globalSettings.rollTimeSettings.rollTimeModes"
                                          [(value)]="this.globalSettings.rollTimeSettings.rollTimeMode"
                                          (onValueChanged)="this.onChange()"></dx-select-box>
                                    </div>
                                    <br>
                                    <div class="roll-time">
                                       <p>Time</p>
                                       <ets-datetime-picker 
                                                            [contextWord]="this.globalSettings.rollTimeSettings.rollTimeMode"
                                                            [mode]="this.getRollTimePickerMode()"
                                                            [(value)]="this.globalSettings.rollTimeSettings.rollTime" 
                                                            [showTimezone]="false">
                                       </ets-datetime-picker>
                                       <div style="margin-top: 5px;" *ngIf="this.globalSettings.rollTimeSettings.rollTimePickerShowTimezone">
                                          <ets-timezone-picker
                                                   [(timezone)]="this.globalSettings.rollTimeSettings.rollTimezone"
                                                   [flow]="'col'">
                                          </ets-timezone-picker>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div class="row">
                                 <div class="col1">Join Roll Buffer</div>
                                 <div class="col2">
                                    <dx-number-box [showClearButton]="true"
                                                   [showSpinButtons]="true"
                                                   [useLargeSpinButtons]="true"
                                                   [min]="0"
                                                   format="#0 days"
                                                   [(value)]="this.globalSettings.rollTimeSettings.joinRollBuffer"
                                                   [step]="1"></dx-number-box>
                                 </div>
                              </div>
       
                           </fieldset>
                        </div>

                        <div class="column order-settings">
                           <fieldset>
      
                              <legend>Order Settings</legend>
      
                              <div class="row">
                                 <div class="col1">Order Type</div>
                                 <div class="col2">
                                    <dx-select-box [items]="this.globalSettings.orderSettings.orderTypes"
                                                   displayExpr="text"
                                                   valueExpr="value"
                                                   [(value)]="this.globalSettings.orderSettings.orderType"
                                                   (onValueChanged)="this.onChange($event)">
                                    </dx-select-box>   
                                 </div>
                              </div>
                              
                              <div class="row" style="margin-top: 5px;" *ngIf="this.globalSettings.orderSettings.orderType === 1">

                                    <div class="row">
                                          <div class="col1">Limit Price</div>
                                          <div class="col2">
                                             <dx-select-box [items]="['Bid', 'Mid', 'Ask']"
                                                            [(value)]="this.globalSettings.orderSettings.autoLimitPrice">
                                             </dx-select-box>
                                          </div>
                                    </div>
                     
                                    <div class="row">
                                       <fieldset class="ctm">
                                          <legend>
                                             <dx-check-box  #ctm 
                                                            text="Convert To Market" 
                                                            [(value)]="this.globalSettings.orderSettings.convertToMarket"
                                                            (onValueChanged)="this.onChange()"
                                                            >
                                             </dx-check-box>
                                          </legend>
                                          
                                          <div *ngIf="ctm.value">
                                             <ets-convert-to-market  [hideCalendar]="true"                        
                                                                     [settings]="this.globalSettings.orderSettings.convertToMarketSettings">
                                             </ets-convert-to-market>
                                          </div>
                                       </fieldset>
                                    </div>
                                       
                              </div>
      
                           </fieldset>
                        </div>

                        <div class="column comparison-pricing">
                           <fieldset>
                        
                              <legend>Comparison Pricing</legend>

                              <div>

                                 <!-- Max Expirations Forward -->
                                 <div class="input">
                                    <div class="col">Max. Expirations Forward</div>
                                    <div class="col input">
                                       <dx-number-box [showClearButton]="true" 
                                                      [showSpinButtons]="true"
                                                      [useLargeSpinButtons]="true"
                                                      [(value)]="this.globalSettings.optimalPricingUp.optimalExpirationLookForwardLimit" 
                                                      (onValueChanged)="this.onChange($event)"
                                                      [step]="1" [min]="1"></dx-number-box>
                                    </div>
                                 </div>
                     
                                 <!-- Selectıon Mode -->
                                 <div class="input">
                                    <div class="col">Expiration Selection Mode</div>
                                    <div class="col input">
                                       <dx-select-box [showClearButton]="true" 
                                                      [items]="this.globalSettings.optimalPricingUp.optimalExpirationSelectionModeList"
                                                      [(value)]="this.globalSettings.optimalPricingUp.optimalExpirationSelectionMode" 
                                                      (onValueChanged)="this.onChange($event)"></dx-select-box>
                                    </div>
                                 </div>
                                       
                                 <!-- Price Buffer -->
                                 <div class="input">
                                    <p>Price Buffer</p>
                                    <dx-number-box [showClearButton]="true" 
                                                   [showSpinButtons]="true"
                                                   [useLargeSpinButtons]="true"
                                                   [(value)]="this.globalSettings.optimalPricingUp.optimalExpirationPriceBuffer" 
                                                   (onValueChanged)="this.onChange($event)"
                                                   [min]="0"
                                                   [step]="0.01" 
                                                   format="0#.00"></dx-number-box>
                                 </div>

                              </div>

                           </fieldset>
                        </div>

                     </div>

                     <div class="strategy-buttons">
      
                        <div class="create-button" *ngIf="!this.hasStrategiesInAutomatedMode">
                           <div class="btn create">
                              <dx-button  type="default" 
                                          width="100%" 
                                          text="Create Strategies" 
                                          (onClick)="this.createStrategiesInAutomatedMode()"></dx-button>
                           </div>
                        </div>
                        
                        <div class="manage-buttons" *ngIf="this.hasStrategiesInAutomatedMode">
                           <div class="btn start">
                              <dx-button type="success" width="100%" text="Enable Strategies" [disabled]="!this.canEnableStrategiesInAutomatedMode"
                                 (onClick)="this.enableStrategiesInAutomatedMode()"></dx-button>
                           </div>
                           
                           <div class="btn stop">
                              <dx-button type="danger" width="100%" text="Disable Strategies" [disabled]="!this.canDisableStrategiesInAutomatedMode"
                              (onClick)="this.disableStrategiesInAutomatedMode()"></dx-button>
                           </div>
                           
                           <div class="btn update">
                              <dx-button type="default" width="100%" text="Update Strategies" [disabled]="!this.canUpdateStrategiesInAutomatedMode"
                              (onClick)="this.updateStrategiesInAutomatedMode()"></dx-button>
                           </div>
                        </div>

                     </div>

                  </div>
               </fieldset>

               <!-- Short Options -->
               <fieldset class="section" id="sellOptionsBlock" *ngIf="this.shortOptionsBlock.isVisible">
                  
                  <legend>{{ this.shortOptionsBlock.displayName || 'Short Options'}}</legend>
                  
                  <div class="content">
                     <dx-box direction="row">
                        
                        <dxi-item class="cheatsheet first" ratio="1" height="100%">
                           <p class="cheatsheet-text">
                              <ets-short-options-overview-block 
                                 mode="sideblock" 
                                 [viewModel]="this.shortOptionsBlock.getViewModel(1)">
                              </ets-short-options-overview-block>
                           </p>
                        </dxi-item>

                        <dxi-item class="parameters" ratio="3" height="100%">

                           <dx-box direction="row">
                              
                              <dxi-item height="100%" ratio="1">

                                 <div class="underlying"  *ngIf="this.selectedCpMode !== 'Automated'">
                                    <div class="label-and-price">
                                       <div class="label">Underlying: </div>
                                       <div class="price">
                                          <ets-last-price [asset]="this.shortOptionsBlock.underlying"></ets-last-price>
                                       </div>
                                    </div>
                                    <ets-symbol-picker [disabled]="true" [selectedInstrument]="this.shortOptionsBlock.underlying"></ets-symbol-picker>
                                 </div>
      
                                 <ng-container *ngIf="this.isSingleDirection; else shortDouble">
                                    <ets-short-option-strategy 
                                       mode="cp" 
                                       [cpMode]="this.selectedCpMode" 
                                       [parameters]="this.shortOptionsBlock.parameters">
                                    </ets-short-option-strategy>                                    
                                 </ng-container>
                                 <ng-template #shortDouble>
                                    <div class="na">
                                       N/A
                                    </div>
                                 </ng-template>
                                                                  
                                 <div class="strategy-buttons" *ngIf="this.selectedCpMode !== 'Automated'">
      
                                    <div class="create-button">
                                       <div class="btn create" *ngIf="!this.shortOptionsBlock.hasStrategy">
                                          <dx-button type="default" width="100%" text="Create Strategy" [disabled]="this.shortOptionsBlock.hasStrategy"
                                             (onClick)="this.shortOptionsBlock.createStrategy()"></dx-button>
                                       </div>
                                    </div>
                                    
                                    <div class="manage-buttons" *ngIf="this.shortOptionsBlock.hasStrategy">
                                       <div class="btn start">
                                          <dx-button type="success" width="100%" text="Enable Strategy" [disabled]="!this.shortOptionsBlock.canStartStrategy"
                                             (onClick)="this.shortOptionsBlock.startStrategy()"></dx-button>
                                       </div>
                                       
                                       <div class="btn stop">
                                          <dx-button type="danger" width="100%" text="Disable Strategy" [disabled]="!this.shortOptionsBlock.canExitStrategy"
                                          (onClick)="this.shortOptionsBlock.exitStrategy()"></dx-button>
                                       </div>
                                       
                                       <div class="btn update">
                                          <dx-button type="default" width="100%" text="Update Strategy" [disabled]="!this.shortOptionsBlock.canUpdateStrategy"
                                          (onClick)="this.shortOptionsBlock.updateStrategy()"></dx-button>
                                       </div>
                                    </div>
      
                                 </div>

                              </dxi-item>

                           </dx-box>
                        </dxi-item>

                        <dxi-item *ngIf="!this.isSingleDirection" class="cheatsheet second" ratio="1" height="100%">
                           <p class="cheatsheet-text">
                              <ets-short-options-overview-block 
                                 mode="sideblock" 
                                 [viewModel]="this.shortOptionsBlock.getViewModel(2)">
                              </ets-short-options-overview-block>
                           </p>
                        </dxi-item>

                     </dx-box>
                  </div>
                  
                  <dx-load-panel 
                     [visible]="this.shortOptionsBlock.isLoading" 
                     [position]="{my: 'center', at: 'center', of: '#sellOptionsBlock'}">
                  </dx-load-panel>

               </fieldset>

               <!-- Short-Term Debit Spread -->
               <fieldset class="section" id="shortTermPutSpreadBlock" *ngIf="this.shortTermDebitSpreadBlock.isVisible">
                  <legend>{{ this.shortTermDebitSpreadBlock.displayName || 'Short-Term Debit Spread'}}</legend>
                  <div class="content">
                     
                     <dx-box direction="row">
                        
                        <dxi-item class="cheatsheet first" ratio="1" height="100%">
                           <p class="cheatsheet-text">
                              <ets-short-term-spread-overview-block mode="sideblock" [viewModel]="this.shortTermDebitSpreadBlock.getViewModel(1)">
                              </ets-short-term-spread-overview-block>
                           </p>
                        </dxi-item>
                        
                        <dxi-item class="parameters" ratio="3" height="100%">

                           <div class="underlying"  *ngIf="this.selectedCpMode !== 'Automated'">
                              <div class="label-and-price">
                                 <div class="label">Underlying: </div>
                                 <div class="price">
                                    <ets-last-price [asset]="this.shortTermDebitSpreadBlock.underlying"></ets-last-price>
                                 </div>
                              </div>
                              <ets-symbol-picker [disabled]="true" [selectedInstrument]="this.shortTermDebitSpreadBlock.underlying"></ets-symbol-picker>
                           </div>

                           <dx-box direction="row">
                              
                              <dxi-item ratio="4">

                                 <div class="roll-leg-dialog"  *ngIf="this.selectedCpMode !== 'Automated'">
                                    <fieldset>
                                       <legend>Long Leg's Target Strike</legend>
                                       
                                       <!-- Original Leg -->
                                       <div class="row original">
                                          <div class="col label">
                                             Select Leg:
                                          </div>
                                          <div class="col input">
                                             <dx-select-box [items]="[this.shortTermDebitSpreadBlock.targetStrikeDialog.longLeg]"
                                                [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.legToRoll"
                                                fieldTemplate="field"
                                                itemTemplate="item"
                                                >
                                                <div *dxTemplate="let data of 'item'">
                                                   <div>{{data ? data.tickerDisplayName +  ' x ' + data.netPosition : null }}</div>
                                               </div>
                                               <div *dxTemplate="let data of 'field'">
                                                    <dx-text-box 
                                                        [text]="data ? data.tickerDisplayName +  ' x ' + data.netPosition : null" 
                                                        [readOnly]="true"
                                                    >
                                                    </dx-text-box>
                                            </div>
                                             </dx-select-box>
                                          </div>
                                       </div>
                                 
                                       <!-- Strike -->
                                       <div class="row stike">
                                          
                                          <div class="col label">
                                             Target Strike
                                          </div>
                                 
                                          <div class="col input">
                                             <ets-strikes-dropdown 
                                                [targetInstrument]="this.shortTermDebitSpreadBlock.targetStrikeDialog.tradingInstrument"
                                                [expiration]="this.shortTermDebitSpreadBlock.targetStrikeDialog.expirationDescriptor" 
                                                [(strike)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.strike"
                                                [(atmStrike)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.atmStrike"
                                                [showAtm]="true"
                                                (strikeChanged)="this.shortTermDebitSpreadBlock.targetStrikeDialog.onStrikeChanged($event)"
                                                (atmStrikeChanged)="this.shortTermDebitSpreadBlock.targetStrikeDialog.onAtmStrikeChanged($event)"
                                                [hidden]="!this.shortTermDebitSpreadBlock.targetStrikeDialog.isVisible">
                                             </ets-strikes-dropdown>            
                                          </div>
                                       </div>
                                 
                                       <!-- Quotes -->
                                       <div class="row quotes" *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.showQuotes">
                                 
                                          <fieldset>
                                             <legend>Quotes</legend>
                                             
                                             <!-- Header -->
                                             <div class="row header">
                                                <div class="col label">&nbsp;</div>
                                                <div class="col input">
                                                   <div class="quote bid">
                                                      <div class="label">BID</div>
                                                   </div>
                                                   <div class="quote mid">
                                                      <div class="label">MID</div>
                                                   </div>
                                                   <div class="quote ask">
                                                      <div class="label">ASK</div>
                                                   </div>
                                                </div>
                                             </div>
                                             
                                             <!-- Original -->
                                             <div class="row">
                                                <div class="col label">Original</div>
                                                <div class="col input">
                                                   <div class="quote bid" [style.color]="this.shortTermDebitSpreadBlock.targetStrikeDialog.bidQuoteColorOriginal">
                                                      <div class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.bidQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                   <div class="quote mid">
                                                      <div class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.midQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                   <div class="quote ask" [style.color]="this.shortTermDebitSpreadBlock.targetStrikeDialog.askQuoteColorOriginal">
                                                      <div class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.askQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                </div>
                                             </div>
                                 
                                             <!-- Desired -->
                                             <div class="row">
                                                <div class="col label">Desired</div>
                                                <div class="col input">
                                                   <div class="quote bid" [style.color]="this.shortTermDebitSpreadBlock.targetStrikeDialog.bidQuoteColorTarget">
                                                      <div class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.bidQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                   <div class="quote mid">
                                                      <div class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.midQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                   <div class="quote ask" [style.color]="this.shortTermDebitSpreadBlock.targetStrikeDialog.askQuoteColorTarget">
                                                      <div class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.askQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                </div>
                                             </div>
                                 
                                             <!-- Total -->
                                             <div class="row total">
                                                <div class="col label">&nbsp;</div>
                                                <div class="col input">
                                                   <div class="quote bid">
                                                      <div (click)="this.shortTermDebitSpreadBlock.targetStrikeDialog.setLimitPrice(this.shortTermDebitSpreadBlock.targetStrikeDialog.bidQuoteTotal)" class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.bidQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                   <div class="quote mid">
                                                      <div (click)="this.shortTermDebitSpreadBlock.targetStrikeDialog.setLimitPrice(this.shortTermDebitSpreadBlock.targetStrikeDialog.midQuoteTotal)" class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.midQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                   <div class="quote ask">
                                                      <div (click)="this.shortTermDebitSpreadBlock.targetStrikeDialog.setLimitPrice(this.shortTermDebitSpreadBlock.targetStrikeDialog.askQuoteTotal)" class="value">{{this.shortTermDebitSpreadBlock.targetStrikeDialog.askQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                                                   </div>
                                                </div>
                                             </div>
                                          </fieldset>
                                 
                                       </div>
                                 
                                       <!-- Order Type -->
                                       <div class="row order-type">
                                          <div class="col label">
                                             Order Type
                                          </div>
                                          <div class="col input">
                                             <dx-select-box [items]="this.shortTermDebitSpreadBlock.targetStrikeDialog.orderTypeList"
                                                   valueExpr="value"
                                                   displayExpr="key"
                                                   [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.selectedOrderType">
                                             </dx-select-box>
                                          </div>
                                       </div>
                                 
                                       <!-- Order Price -->
                                       <div class="row price" *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.selectedOrderType !== 2">
                                          
                                          <div class="limit-price" *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.selectedOrderType === 1">
                                             <div class="col label">Limit Px.</div>
                                             <div class="col input">
                                                <dx-number-box [min]="0.01" [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.limitPrice" [showSpinButtons]="true" 
                                                               [showClearButton]="true"
                                                               [format]="{ style: 'currency', currency: 'USD' }" [inputAttr]="this.shortTermDebitSpreadBlock.targetStrikeDialog.limitPriceColor">
                                                </dx-number-box>
                                             </div>
                                          </div>
                                 
                                          <div class="auto-price" *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.selectedOrderType === 17">
                                             <div class="col label">
                                                Auto Px.
                                             </div>
                                             <div class="col input">
                                                <dx-select-box [items]="['Bid', 'Mid', 'Ask']" [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.autoLimitPrice"></dx-select-box>
                                             </div>
                                          </div>
                                 
                                       </div>
                                 
                                       <!-- Future Settings -->
                                       <div class="row future-settings">
                                          
                                          <div class="col label">
                                             <dx-check-box text="Future Placement" [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.futurePlacement"></dx-check-box>
                                          </div>
                                          
                                          <div class="col input" style="margin-top: 5px;">
                                             <div *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.futurePlacement">
                                                <div>
                                                   <dx-select-box 
                                                                  [items]="['Place At', 'Place After']"
                                                                  [showClearButton]="true"
                                                                  [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.futureTimeSettings.actionTimeMode"
                                                                  (onValueChanged)="this.shortTermDebitSpreadBlock.targetStrikeDialog.onChange($event)"
                                                                  placholder="Place Mode" #place></dx-select-box>
                                                </div>
                                                         
                                                <div>
                                                   <ets-datetime-picker
                                                               [disabled]="!place.value"
                                                               [contextWord]="this.shortTermDebitSpreadBlock.targetStrikeDialog.futureTimeSettings.actionTimeMode"
                                                               [mode]="this.shortTermDebitSpreadBlock.targetStrikeDialog.getDateTimePickerMode('future')"
                                                               [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.futureTimeSettings.actionTime"
                                                   ></ets-datetime-picker>
                                                </div>
                                 
                                                <div>
                                                   <ets-timezone-picker
                                                      *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.futureTimeSettings.actionTimeMode === 'Place At'"
                                                      [(timezone)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.futureTimeSettings.timezone"
                                                      [flow]="'col'"></ets-timezone-picker>
                                                </div>
                                             </div>
                                          </div>
                                    
                                       </div>
                                 
                                       <!-- Convert To Market -->
                                       <div class="row to-market">
                                          
                                          <div class="col label">
                                             <dx-check-box  text="Convert To Market" 
                                                            [disabled]="!this.shortTermDebitSpreadBlock.targetStrikeDialog.canConvertToMarket" 
                                                            [(value)]="this.shortTermDebitSpreadBlock.targetStrikeDialog.convertToMarket"></dx-check-box>
                                          </div>
                                 
                                          <div class="col input">
                                             <ets-convert-to-market 
                                                *ngIf="this.shortTermDebitSpreadBlock.targetStrikeDialog.convertToMarket"
                                                [settings]="this.shortTermDebitSpreadBlock.targetStrikeDialog.convertToMarketSettings"
                                                flow="block"
                                             ></ets-convert-to-market>
                                          </div>
                                 
                                       </div>
                                       
                                       <div class="row buttons">
                                          <div class="col label">&nbsp;</div>
                                          <div class="col input">
                                             <dx-button width="50%" type="default" text="Execute" (onClick)="this.showTargetStrikeVerification('short-term')"></dx-button>
                                             <dx-button width="50%" text="Cancel" (onClick)="this.shortTermDebitSpreadBlock.targetStrikeDialog.onCancelClicked()"></dx-button>
                                          </div>
                                       </div>                        
                                    </fieldset>
                                 </div>

                                 <div class="debit-spread-roll">
                                    <fieldset>
                                       <legend>Debit Spread</legend>
                                       
                                       <div class="manual-switch"  *ngIf="this.selectedCpMode !== 'Automated'">
                                          <div class="cell label">Manual Mode</div>
                                          <div class="cell input">
                                             <dx-switch [(value)]="this.shortTermDebitSpreadBlock.spreadRollParameters.manualMode"></dx-switch>
                                          </div>
                                       </div>
                                       
                                       <div class="strategy" id="putDebitSpreadRoll">
                                          <ets-put-debit-spread-roll-strategy 
                                             [parameters]="this.shortTermDebitSpreadBlock.spreadRollParameters"
                                             [tickerForChains]="'@SPY SPY Call 344 2022-10-22'"
                                             [cpMode]="this.selectedCpMode"
                                             [strategy]="this.selectedCashFlowStrategy"
                                             >
                                          </ets-put-debit-spread-roll-strategy>
                                          <dx-load-panel [visible]="this.shortTermDebitSpreadBlock.spreadRollParameters.isLoading" 
                                                         [position]="{my: 'center', at: 'center', of: '#putDebitSpreadRoll'}">
                                          </dx-load-panel>
                                       </div>
                                       
                                       <div class="buttons"  *ngIf="this.selectedCpMode !== 'Automated'">
                                          <div class="has-strategy" *ngIf="this.shortTermDebitSpreadBlock.showRollStrategyManageButtons">
                                             <div class="button">
                                                <dx-button  width="100%" 
                                                            text="Enable" 
                                                            type="success" 
                                                            [disabled]="!this.shortTermDebitSpreadBlock.canStartRollStrategy"
                                                            (onClick)="this.shortTermDebitSpreadBlock.startRollStrategy()">
                                                </dx-button>
                                             </div>
                                             
                                             <div class="button">
                                                <dx-button  width="100%" 
                                                            text="Disable" 
                                                            type="danger" 
                                                            [disabled]="!this.shortTermDebitSpreadBlock.canExitRollStrategy"
                                                            (onClick)="this.shortTermDebitSpreadBlock.exitRollStrategy()"></dx-button>
                                             </div>
                                             
                                             <div class="button">
                                                <dx-button  width="100%" 
                                                            text="Update" 
                                                            type="default" 
                                                            [disabled]="!this.shortTermDebitSpreadBlock.canUpdateRollStrategy"
                                                            (onClick)="this.shortTermDebitSpreadBlock.updateRollStrategy()">
                                                </dx-button>
                                             </div>
                                          </div>

                                          <div class="no-strategy"  *ngIf="this.shortTermDebitSpreadBlock.showRollStrategyCreateButton">
                                             <dx-button  width="100%" 
                                                         type="default" 
                                                         text="Create Strategy" 
                                                         [disabled]="this.shortTermDebitSpreadBlock.hasRollStrategy"
                                                         (onClick)="this.shortTermDebitSpreadBlock.createRollStrategy()">
                                             </dx-button>
                                          </div>
                                          <div class="manual-mode" *ngIf="this.shortTermDebitSpreadBlock.showRollStrategyManualButton">
                                             <dx-button  width="100%" 
                                                         type="danger" 
                                                         text="Execute" 
                                                         (onClick)="this.shortTermDebitSpreadBlock.rollSpreadManually()">
                                             </dx-button>
                                          </div>
                                       </div>

                                    </fieldset>
                                 </div>

                              </dxi-item>

                              <dxi-item ratio="3"  *ngIf="this.selectedCpMode !== 'Automated'">
                                 <fieldset>
                                    <legend>Put Spread Adjustment</legend>
                                    
                                    <ets-put-spread-strategy [parameters]="this.shortTermDebitSpreadBlock.spreadAdjustmentParameters"></ets-put-spread-strategy>
                                    
                                    <div class="strategy-buttons">
         
                                       <div class="create-button">
                                          <div class="btn create" *ngIf="!this.shortTermDebitSpreadBlock.hasAdjustmentStrategy">
                                             <dx-button type="default" width="100%" text="Create Strategy" [disabled]="this.shortTermDebitSpreadBlock.hasAdjustmentStrategy"
                                                (onClick)="this.shortTermDebitSpreadBlock.createAdjustmentStrategy()"></dx-button>
                                          </div>
                                       </div>
         
                                       <div class="manage-buttons" *ngIf="this.shortTermDebitSpreadBlock.hasAdjustmentStrategy">
                                          <div class="btn start">
                                             <dx-button type="success" width="100%" text="Enable Strategy" [disabled]="!this.shortTermDebitSpreadBlock.canStartAdjustmentStrategy"
                                                (onClick)="this.shortTermDebitSpreadBlock.startAdjustmentStrategy()"></dx-button>
                                          </div>
                                          
                                          <div class="btn stop">
                                             <dx-button type="danger" width="100%" text="Disable Strategy" [disabled]="!this.shortTermDebitSpreadBlock.canExitAdjustmentStrategy"
                                             (onClick)="this.shortTermDebitSpreadBlock.exitAdjustmentStrategy()"></dx-button>
                                          </div>
                                          
                                          <div class="btn update">
                                             <dx-button type="default" width="100%" text="Update Strategy" [disabled]="!this.shortTermDebitSpreadBlock.canUpdateAdjustmentStrategy"
                                             (onClick)="this.shortTermDebitSpreadBlock.updateAdjustmentStrategy()"></dx-button>
                                          </div>
                                          
                                       </div>
   
                                    </div>
                                 </fieldset>
                              </dxi-item>

                           </dx-box>                           
                        </dxi-item>

                        <dxi-item *ngIf="!this.isSingleDirection" class="cheatsheet second" ratio="1" height="100%">
                           <p class="cheatsheet-text">
                              <ets-short-term-spread-overview-block 
                                 mode="sideblock" 
                                 [viewModel]="this.shortTermDebitSpreadBlock.getViewModel(2)">
                              </ets-short-term-spread-overview-block>
                           </p>
                        </dxi-item>

                     </dx-box>

                  </div>
                  <dx-load-panel [visible]="this.shortTermDebitSpreadBlock.isLoading" [position]="{my: 'center', at: 'center', of: '#shortTermPutSpreadBlock'}"></dx-load-panel>
               </fieldset>

               <!-- Long-Term Option -->
               <fieldset class="section" id="longTermPutBlock" *ngIf="this.longTermOptionBlock.isVisible">
                  
                  <legend>{{ this.longTermOptionBlock.displayName || 'Protective Option'}}</legend>
                  
                  <div class="content">
                     <dx-box direction="row">
                        
                        <dxi-item class="cheatsheet first" ratio="1" height="100%">
                           <ets-long-term-put-overview-block 
                              mode="sideblock" 
                              [viewModel]="this.longTermOptionBlock.getViewModel()">
                           </ets-long-term-put-overview-block>
                        </dxi-item>

                        <dxi-item class="parameters" ratio="3" height="100%">

                           <div class="roll-leg-dialog" *ngIf="this.selectedCpMode !== 'Automated' ">
                              <fieldset>
                                 <legend>Target Strike</legend>
   
                                 <!-- Original Leg -->
                                 <div class="row original">
                                    <div class="col label">
                                       Original Leg
                                    </div>
                                    <div class="col input">
                                       {{this.longTermOptionBlock.targetStrikeDialog.originalLeg}}
                                    </div>
                                 </div>
                           
                                 <!-- Strike -->
                                 <div class="row stike">
                                    
                                    <div class="col label">
                                       Target Strike
                                    </div>
                           
                                    <div class="col input">
                                       <ets-strikes-dropdown 
                                          [targetInstrument]="this.longTermOptionBlock.targetStrikeDialog.tradingInstrument"
                                          [expiration]="this.longTermOptionBlock.targetStrikeDialog.expirationDescriptor" 
                                          [(strike)]="this.longTermOptionBlock.targetStrikeDialog.strike"
                                          [(atmStrike)]="this.longTermOptionBlock.targetStrikeDialog.atmStrike"
                                          [showAtm]="true"
                                          (strikeChanged)="this.longTermOptionBlock.targetStrikeDialog.onStrikeChanged($event)"
                                          (atmStrikeChanged)="this.longTermOptionBlock.targetStrikeDialog.onAtmStrikeChanged($event)"
                                          [hidden]="!this.longTermOptionBlock.targetStrikeDialog.isVisible">
                                       </ets-strikes-dropdown>            
                                    </div>
                                 </div>
                           
                                 <!-- Quotes -->
                                 <div class="row quotes" *ngIf="this.longTermOptionBlock.targetStrikeDialog.showQuotes">
                           
                                    <fieldset>
                                       <legend>Quotes</legend>
                                       
                                       <!-- Header -->
                                       <div class="row header">
                                          <div class="col label">&nbsp;</div>
                                          <div class="col input">
                                             <div class="quote bid">
                                                <div class="label">BID</div>
                                             </div>
                                             <div class="quote mid">
                                                <div class="label">MID</div>
                                             </div>
                                             <div class="quote ask">
                                                <div class="label">ASK</div>
                                             </div>
                                          </div>
                                       </div>
                                       
                                       <!-- Original -->
                                       <div class="row">
                                          <div class="col label">Original</div>
                                          <div class="col input">
                                             <div class="quote bid" [style.color]="this.longTermOptionBlock.targetStrikeDialog.bidQuoteColorOriginal">
                                                <div class="value">{{this.longTermOptionBlock.targetStrikeDialog.bidQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                             <div class="quote mid">
                                                <div class="value">{{this.longTermOptionBlock.targetStrikeDialog.midQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                             <div class="quote ask" [style.color]="this.longTermOptionBlock.targetStrikeDialog.askQuoteColorOriginal">
                                                <div class="value">{{this.longTermOptionBlock.targetStrikeDialog.askQuoteOriginal | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                          </div>
                                       </div>
                           
                                       <!-- Desired -->
                                       <div class="row">
                                          <div class="col label">Desired</div>
                                          <div class="col input">
                                             <div class="quote bid" [style.color]="this.longTermOptionBlock.targetStrikeDialog.bidQuoteColorTarget">
                                                <div class="value">{{this.longTermOptionBlock.targetStrikeDialog.bidQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                             <div class="quote mid">
                                                <div class="value">{{this.longTermOptionBlock.targetStrikeDialog.midQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                             <div class="quote ask" [style.color]="this.longTermOptionBlock.targetStrikeDialog.askQuoteColorTarget">
                                                <div class="value">{{this.longTermOptionBlock.targetStrikeDialog.askQuote | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                          </div>
                                       </div>
                           
                                       <!-- Total -->
                                       <div class="row total">
                                          <div class="col label">&nbsp;</div>
                                          <div class="col input">
                                             <div class="quote bid">
                                                <div (click)="this.longTermOptionBlock.targetStrikeDialog.setLimitPrice(this.longTermOptionBlock.targetStrikeDialog.bidQuoteTotal)" class="value">{{this.longTermOptionBlock.targetStrikeDialog.bidQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                             <div class="quote mid">
                                                <div (click)="this.longTermOptionBlock.targetStrikeDialog.setLimitPrice(this.longTermOptionBlock.targetStrikeDialog.midQuoteTotal)" class="value">{{this.longTermOptionBlock.targetStrikeDialog.midQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                             <div class="quote ask">
                                                <div (click)="this.longTermOptionBlock.targetStrikeDialog.setLimitPrice(this.longTermOptionBlock.targetStrikeDialog.askQuoteTotal)" class="value">{{this.longTermOptionBlock.targetStrikeDialog.askQuoteTotal | currency:'USD':'symbol':'1.2-2'}}</div>
                                             </div>
                                          </div>
                                       </div>
                                    </fieldset>
                           
                                 </div>
                           
                                 <!-- Order Type -->
                                 <div class="row order-type">
                                    <div class="col label">
                                       Order Type
                                    </div>
                                    <div class="col input">
                                       <dx-select-box [items]="this.longTermOptionBlock.targetStrikeDialog.orderTypeList"
                                             valueExpr="value"
                                             displayExpr="key"
                                             [(value)]="this.longTermOptionBlock.targetStrikeDialog.selectedOrderType">
                                       </dx-select-box>
                                    </div>
                                 </div>
                           
                                 <!-- Order Price -->
                                 <div class="row price" *ngIf="this.longTermOptionBlock.targetStrikeDialog.selectedOrderType !== 2">
                                    
                                    <div class="limit-price" *ngIf="this.longTermOptionBlock.targetStrikeDialog.selectedOrderType === 1">
                                       <div class="col label">Limit Px.</div>
                                       <div class="col input">
                                          <dx-number-box [min]="0.01" [(value)]="this.longTermOptionBlock.targetStrikeDialog.limitPrice" [showSpinButtons]="true" 
                                                         [showClearButton]="true"
                                                         [format]="{ style: 'currency', currency: 'USD' }" [inputAttr]="this.longTermOptionBlock.targetStrikeDialog.limitPriceColor">
                                          </dx-number-box>
                                       </div>
                                    </div>
                           
                                    <div class="auto-price" *ngIf="this.longTermOptionBlock.targetStrikeDialog.selectedOrderType === 17">
                                       <div class="col label">
                                          Auto Px.
                                       </div>
                                       <div class="col input">
                                          <dx-select-box [items]="['Bid', 'Mid', 'Ask']" [(value)]="this.longTermOptionBlock.targetStrikeDialog.autoLimitPrice"></dx-select-box>
                                       </div>
                                    </div>
                           
                                 </div>
                           
                                 <!-- Future Settings -->
                                 <div class="row future-settings">
                                    
                                    <div class="col label">
                                       <dx-check-box text="Future Placement" [(value)]="this.longTermOptionBlock.targetStrikeDialog.futurePlacement"></dx-check-box>
                                    </div>
                                    
                                    <div class="col input" style="margin-top: 5px;">
                                       <div *ngIf="this.longTermOptionBlock.targetStrikeDialog.futurePlacement">
                                          <div>
                                             <dx-select-box 
                                                            [items]="['Place At', 'Place After']"
                                                            [showClearButton]="true"
                                                            [(value)]="this.longTermOptionBlock.targetStrikeDialog.futureTimeSettings.actionTimeMode"
                                                            (onValueChanged)="this.longTermOptionBlock.targetStrikeDialog.onChange($event)"
                                                            placholder="Place Mode" #place></dx-select-box>
                                          </div>
                                                   
                                          <div>
                                             <ets-datetime-picker
                                                         [contextWord]="this.longTermOptionBlock.targetStrikeDialog.futureTimeSettings.actionTimeMode"
                                                         [disabled]="!place.value"
                                                         [mode]="this.longTermOptionBlock.targetStrikeDialog.getDateTimePickerMode('future')"
                                                         [(value)]="this.longTermOptionBlock.targetStrikeDialog.futureTimeSettings.actionTime"
                                             ></ets-datetime-picker>
                                          </div>
                           
                                          <div>
                                             <ets-timezone-picker
                                                *ngIf="this.longTermOptionBlock.targetStrikeDialog.futureTimeSettings.actionTimeMode === 'Place At'"
                                                [(timezone)]="this.longTermOptionBlock.targetStrikeDialog.futureTimeSettings.timezone"
                                                [flow]="'col'"></ets-timezone-picker>
                                          </div>
                                       </div>
                                    </div>
                              
                                 </div>
                           
                                 <!-- Convert To Market -->
                                 <div class="row to-market">
                                    
                                    <div class="col label">
                                       <dx-check-box text="Convert To Market" [disabled]="!this.longTermOptionBlock.targetStrikeDialog.canConvertToMarket" [(value)]="this.longTermOptionBlock.targetStrikeDialog.convertToMarket"></dx-check-box>
                                    </div>
                           
                                    <div class="col input">
   
                                       <ets-convert-to-market 
                                          *ngIf="this.longTermOptionBlock.targetStrikeDialog.convertToMarket"
                                          [settings]="this.longTermOptionBlock.targetStrikeDialog.convertToMarketSettings"
                                       ></ets-convert-to-market>
   
                                    </div>
                           
                                 </div>
                                 
                                 <div class="row buttons">
                                    <div class="col label">&nbsp;</div>
                                    <div class="col input">
                                       <dx-button width="50%" text="Execute" type="default" (onClick)="this.showTargetStrikeVerification('long-term')"></dx-button>
                                       <dx-button width="50%" text="Cancel" (onClick)="this.longTermOptionBlock.targetStrikeDialog.onCancelClicked()"></dx-button>
                                    </div>
                                 </div>                        
                              </fieldset>
                           </div>

                           <div>
                              <fieldset>
                                 <legend>Long-Term Option</legend>
                                 <ets-long-option-strategy 
                                    [parameters]="this.longTermOptionBlock.parameters" 
                                    [cpMode]="this.selectedCpMode"
                                    [strategy]="this.selectedCashFlowStrategy"
                                    >
                                 </ets-long-option-strategy>
                              </fieldset>
                           </div>

                        </dxi-item>

                        <dxi-item *ngIf="!this.isSingleDirection" class="cheatsheet second" ratio="1" height="100%">
                           <ets-long-term-put-overview-block 
                              mode="sideblock" 
                              [viewModel]="this.longTermOptionBlock.getViewModel()">
                           </ets-long-term-put-overview-block>
                        </dxi-item>

                     </dx-box>
                  </div>
                  
                  <dx-load-panel [visible]="this.longTermOptionBlock.isLoading" [position]="{my: 'center', at: 'center', of: '#longTermPutBlock'}"></dx-load-panel>
               </fieldset>

               <!-- Portfolio -->
               <fieldset class="section" id="portfolioBlock" *ngIf="this.portfolioBlock.isVisible">
                  <legend>{{this.portfolioBlock.displayName || 'Portfolio'}}</legend>
                  
                  <div class="content" [style.height.px]="this.portfolioBlock.height">
                     <dx-box direction="row" height="100%">
                        <dxi-item class="cheatsheet" ratio="1" height="100%">
                           <ag-grid-angular style="width: 100%; height: 100%" 
                                             class="ag-theme-balham-dark"
                                             [gridOptions]="this.portfolioBlock.smallGridOptions">
                           </ag-grid-angular>
                        </dxi-item>
                        <dxi-item class="parameters" ratio="3" height="100%">
                           
                           <div class="underlying">
                              <div class="label-and-price">
                                 <div class="label">Underlying: </div>
                                 <div class="price">
                                    <ets-last-price style="float: right" [asset]="this.portfolioBlock.underlying"></ets-last-price>
                                 </div>
                              </div>
                              <ets-symbol-picker [disabled]="true" [selectedInstrument]="this.portfolioBlock.underlying"></ets-symbol-picker>
                           </div>

                           <ag-grid-angular style="width: 90%; height: 100%" 
                              class="ag-theme-balham-dark"
                              [gridOptions]="this.portfolioBlock.gridOptions">
                           </ag-grid-angular>
                        </dxi-item>
                     </dx-box>
                  </div>
                  
                  <dx-load-panel [visible]="this.portfolioBlock.isLoading" [position]="{my: 'center', at: 'center', of: '#portfolioBlock'}"></dx-load-panel>
               </fieldset>

               <!-- Interest -->
               <fieldset class="section" id="interestBlock" *ngIf="this.interestBlock.isVisible">

                  <legend>{{this.interestBlock.displayName || 'Interest'}}</legend>
                  
                  <div class="content">
                     
                     <dx-box direction="row">
                        
                        <dxi-item class="cheatsheet first" ratio="1" height="100%">
                           <ets-interest-overview-block 
                              mode="sideblock" 
                              [viewModel]="this.interestBlock.getViewModel()">
                           </ets-interest-overview-block>
                        </dxi-item>
                        
                        <dxi-item class="parameters" ratio="3" height="100%">
                           
                           <ets-interest-calculator-strategy [parameters]="this.interestBlock.parameters"></ets-interest-calculator-strategy>
                           
                           <div class="strategy-buttons">

                              <div class="create-button">
                                 <div class="btn create" *ngIf="!this.interestBlock.hasStrategy && this.selectedCpMode !== 'Automated'">
                                    <dx-button type="default" width="100%" text="Create Strategy" [disabled]="this.interestBlock.hasStrategy"
                                       (onClick)="this.interestBlock.createStrategy()"></dx-button>
                                 </div>
                              </div>

                              
                              <div class="manage-buttons" *ngIf="this.interestBlock.hasStrategy">
                                 <div class="btn start">
                                    <dx-button type="success" width="100%" text="Enable Strategy" [disabled]="!this.interestBlock.canStartStrategy"
                                       (onClick)="this.interestBlock.startStrategy()"></dx-button>
                                 </div>
                                 
                                 <div class="btn stop">
                                    <dx-button type="danger" width="100%" text="Disable Strategy" [disabled]="!this.interestBlock.canExitStrategy"
                                    (onClick)="this.interestBlock.exitStrategy()"></dx-button>
                                 </div>
                                 
                                 <div class="btn update">
                                    <dx-button type="default" width="100%" text="Update Strategy" [disabled]="!this.interestBlock.canUpdateStrategy"
                                    (onClick)="this.interestBlock.updateStrategy()"></dx-button>
                                 </div>
                                 
                              </div>
                           </div>
                           
                        </dxi-item>

                     </dx-box>
                  </div>
                  
                  <dx-load-panel [visible]="this.interestBlock.isLoading" [position]="{my: 'center', at: 'center', of: '#interestBlock'}"></dx-load-panel>
               </fieldset>

               <!-- Position Sizing -->
               <fieldset class="section" id="positionSizingBlock" *ngIf="this.interestBlock.isVisible">
                  <legend>Position Sizing</legend>
                  
                  <div class="content">
                     
                     <dx-box direction="row">
                        
                        <dxi-item class="cheatsheet" ratio="1" height="100%">
                           Cheatsheet (TBD)
                        </dxi-item>
                        
                        <dxi-item class="parameters" ratio="3" height="100%">
                           Main Content (TBD)
                        </dxi-item>

                     </dx-box>
                  </div>

                  <dx-load-panel [visible]="this.interestBlock.isLoading" [position]="{my: 'center', at: 'center', of: '#interestBlock'}"></dx-load-panel>
            </fieldset>
   
            </dx-scroll-view>
         
         </dxi-item>
      </dx-box>
   </div>

   <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: container}"></dx-load-panel>

   <!-- Trade Confirmation Dialog -->
   <dx-popup #targetStrikeConfirmation   width="95%" 
      [height]="300" 
      title="Trade Confirmation" 
      [showTitle]="true" 
      [closeOnOutsideClick]="false" 
      [resizeEnabled]="true" 
      [dragEnabled]="true">

         <ng-template #confirmation let-model>

         <div class="order-header">
            <span>{{model?.orderName}}</span>
            <span *ngIf="model?.linkType">&nbsp;({{model?.linkType}})</span>
            <span *ngIf="model?.hasAttachment">&nbsp;(+AA)</span>
            <span *ngIf="model?.hasFutureSettings || model?.hasConvertSettings">&nbsp;(+FO)</span>
         </div>

         <div class="root-destination" *ngIf="model?.root">
            
            <div class="cell terminal" *ngIf="model?.root?.terminal">
               
               <span class="title">Terminal:</span>
               
               <span class="value">{{model?.root?.terminal}}</span>
            </div>
            
            <div class="cell portfolio" *ngIf="model?.root?.portfolio">
               
               &nbsp;|&nbsp;

               <span class="title">Portfolio: </span>
               
               <span class="value">{{model?.root?.portfolio}}</span>
            </div>
            
            <div class="cell combo" *ngIf="model?.root?.combo">
               
               &nbsp;|&nbsp;

               <span class="title">Combo: </span>
               
               <span class="value">{{model?.root?.combo}}</span>
            </div>
            
            <div class="cell combo-group" *ngIf="model?.root?.comboGroup">
               
               &nbsp;|&nbsp;
               
               <span class="title">ComboGroup: </span>
               
               <span class="value">{{model?.root?.comboGroup}}</span>
            </div>
         </div>

         <table>

            <thead>
               <tr>
                  <td class="cell symbol" width="20%">Symbol</td>
                  <td class="cell side" width="5%">Side</td>
                  <td class="cell qty" width="5%" title="Total Qty">T.Qty</td>
                  <td class="cell eff-qty" width="5%" title="Effective Qty">E.Qty</td>
                  <td class="cell terminal" width="12%">Terminal</td>
                  <td class="cell portfolio" width="18%">Portfolio</td>
                  <td class="cell combo" width="18%">Combo</td>
                  <td class="cell combo-group" width="17%">Combo Group</td>
               </tr>
            </thead>

            <tbody>
               
               <tr *ngFor="let leg of model?.legs">

                  <td class="cell ellipsis symbol" [class.put]="leg.symbol.indexOf(' Put ') > 0"
                     #symbolCell
                     (mouseenter)="this.toggleTooltip(leg, 'symbol')"
                     (mouseleave)="this.toggleTooltip(leg, 'symbol')">
                     <span>{{leg.symbol}}</span>
                     <dx-tooltip [target]="symbolCell" [visible]="leg.tooltip_symbol">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.symbol}}
                        </div>
                     </dx-tooltip>
                  </td>
                  
                  <td class="cell side" [class.buy]="leg.side === 'Buy'" 
                                          [class.sell]="leg.side === 'Sell'">
                     {{leg.side}}
                  </td>
                  
                  <td class="cell qty">
                     {{leg.totalQty}}
                  </td>

                  <td class="cell eff-qty">
                     {{leg.effectiveQty}}
                  </td>
                  
                  <td class="cell ellipsis terminal">
                     <span>{{leg.terminal}}</span>
                  </td>
                  
                  <td class="cell ellipsis portfolio" #pfCell
                     (mouseenter)="this.toggleTooltip(leg, 'portfolio')"
                     (mouseleave)="this.toggleTooltip(leg, 'portfolio')">
                     <span>{{leg.portfolio}}</span>   
                     <dx-tooltip [target]="pfCell" [visible]="leg.tooltip_portfolio">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.portfolio}}
                        </div>
                     </dx-tooltip>
                  </td>
                  
                  <td class="cell ellipsis combo" #comboCell
                     (mouseenter)="this.toggleTooltip(leg, 'combo')"
                     (mouseleave)="this.toggleTooltip(leg, 'combo')">
                     
                     <span>{{leg.combo}}</span>
                     <dx-tooltip [target]="comboCell" [visible]="leg.tooltip_combo">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.combo}}
                        </div>
                     </dx-tooltip>
                  </td>
                  
                  <td class="cell ellipsis combo-group" #comboGroupCell
                     (mouseenter)="this.toggleTooltip(leg, 'comboGroup')"
                     (mouseleave)="this.toggleTooltip(leg, 'comboGroup')">
                     <span>{{leg.comboGroup}}</span>
                     <dx-tooltip [target]="comboGroupCell" [visible]="leg.tooltip_comboGroup">
                        <div *dxTemplate="let data of 'content'">
                           {{leg.comboGroup}}
                        </div>
                     </dx-tooltip>
                  </td>
               </tr>

               <tr *ngIf="model?.totalQty > 1">
                  <td class="cell symbol"></td>
                  <td class="cell side"></td>
                  <td class="cell qty"></td>
                  <td class="cell eff-qty">
                     <span class="total">
                        x {{model?.totalQty}}
                     </span>
                  </td>
                  <td class="cell terminal"></td>
                  <td class="cell portfolio"></td>
                  <td class="cell combo"></td>
                  <td class="cell combo-group"></td>
               </tr>
               

            </tbody>

         </table>

         <hr>

         <div class="order-params">
               
            <div class="cell account">
               <span class="title">Account:</span> 
               <span class="value">{{model?.account}}</span>
            </div>

            
            <div class="cell terminal" *ngIf="!model?.root">
               
               &nbsp;|&nbsp;

               <span class="title">Terminal:</span>
               
               <span class="value">{{model?.terminal}}</span>
            </div>

            <div class="cell order-duration">
               &nbsp;|&nbsp;

               <span class="title duration">Duration:</span> 
               
               <span class="value duration">{{model?.duration}}</span>
            </div>
            
            <div class="cell order-type">
               
               &nbsp;|&nbsp;
               
               <span class="title order-type">Type:</span> 
               
               <span class="value order-type">{{model?.type}}</span>
            </div>

            <div class="cell order-price" *ngIf="model?.type === 'Limit'">
               
               &nbsp;|&nbsp;

               <span class="title order-price">Limit Px:</span> 
               
               <span class="value order-price">{{model?.limitPx}}</span>
            </div>

            <div class="cell market-value">
               
               &nbsp;|&nbsp;

               <span class="title">Market Value:</span>
               
               <span class="value" [class.credit-trade]="model?.marketValue > 0" 
                                    [class.debit-trade]="model?.marketValue < 0">
                  {{ model?.marketValue | currency }}
               </span>
            </div>

            <div class="cell limit-value" *ngIf="model?.type === 'Limit'">
               &nbsp;|&nbsp;

               <span class="title">Limit Value:</span>
               
               <span class="value" [class.credit-trade]="model?.limitValue > 0" 
                                    [class.debit-trade]="model?.limitValue < 0">
                  {{ model?.limitValue | currency }}
               </span>
            </div>
         </div>

         </ng-template>

         <div *dxTemplate="let data of 'content'" style="position: relative" class="verify-dialog">

         <div *ngFor="let orderModel of this.confirmationModel.orders; index as ix" 
                  [style.display]="ix !== this.confirmationModel.selectedDestination ? 'none' : 'unset' ">
            
            <ng-container *ngTemplateOutlet="confirmation; context: {$implicit: orderModel?.mainOrder}"></ng-container>

            <div class="order linked" *ngIf="this.linkedOrderAType">
               <ng-container *ngTemplateOutlet="confirmation; context: {$implicit: orderModel?.linkedOrderA}"></ng-container>
            </div>

            <div class="order linked" *ngIf="this.linkedOrderBType">
               <ng-container *ngTemplateOutlet="confirmation; context: {$implicit: orderModel?.linkedOrderB}"></ng-container>
            </div>

         </div>

         <div class="bottom">
            <div class="destinations">
               <span *ngFor="let orderModel of this.confirmationModel.orders; index as ix; last as isLast" class="destination-selector"
                  [class.active]="this.confirmationModel.selectedDestination === ix" (click)="this.selectVerifyDestination(ix)">
                  Dest.: "{{orderModel?.mainOrder?.destinationName}}" 
                  <span *ngIf="!isLast">&nbsp; | &nbsp;</span>
               </span>
            </div>

            <div>
               <dx-button text="Place Order" [disabled]="false" (onClick)="this.sendTargetStrikeOrders()"></dx-button>
            </div>

         </div>

         <dx-load-panel [visible]="this.sendingOrder"></dx-load-panel>

         </div>

   </dx-popup>

</div>