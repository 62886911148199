import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { PositionDto, PositionFlags } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { ColumnState, GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import { ArchivePosition } from 'projects/shared-components/shell-communication/operations/archived-positions/archive-position.class';
import { DetectMethodChanges, getPanelStateKey, tickersMatch } from 'projects/shared-components/utils';
import { PositionArchived } from 'projects/shared-components/shell-communication/dtos/position-archived.class';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { GetAccounts } from 'projects/shared-components/shell-communication/operations/accounts/get-accounts.class';
import { TradingInstrument } from 'projects/shared-components/trading-instruments/trading-instrument.class';
import {
   CheckIfManualPositionExists
} from 'projects/shared-components/shell-communication/operations/manual-trading/check-if-manual-position-exists.class';
import { AddManualPosition } from 'projects/shared-components/shell-communication/operations/manual-trading/add-manual-position.class';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { ManualTradingBackendService } from '../manual-trading-backend.service';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { ManualTradingSecurityContextService } from '../manual-trading-security-context.service';
import { MoveToPortfolioDialogComponent } from 'projects/shared-components/portfolios/move-to-portfolio-dialog/move-to-portfolio-dialog.component';
import { PositionAdjustComponent } from 'projects/shared-components/position-adjust/position-adjust.component';
import { isNullOrUndefined } from 'util';
import { PositionOverrideComponent } from 'projects/shared-components/position-override/position-override.component';
import { BucketSummaryDto, ComboDto, ComboGroupDto, GetAvailableBucketsReply, PortfolioDto, PositionRemoved } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { MoveToPortfolioDialogConfig, PortfolioEntityType } from 'projects/shared-components/portfolios/move-to-portfolio-dialog/move-to-portfolio-dialog.model';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';
import { TerminalExposureChangedUIMessage } from 'projects/shared-components/ui-messages/terminal-exposure-changed-ui-message.interface';
import { getTerminalsGridOptions } from './terminals-grid-options';
import { getPositionGridColumnDefs, getPositionsGridOptions } from './manual-positions-grid-options';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { GetNoBucketManualPositions, GetNoBucketSummaries, ToggleCashSettled, TogglePositionFlag } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { AgGridAngular } from 'ag-grid-angular';
import { ManualPositionHighlightedUIMessage } from 'projects/shared-components/ui-messages/ui-messages';
import {UserSettingsService} from "../../user-settings.service";

interface PanelState {
   columnsState: ColumnState[];
}

@Component({
   selector: 'ets-manual-positions',
   templateUrl: './manual-positions.component.html',
   styleUrls: ['./manual-positions.component.scss'],
   providers: [ManualTradingSecurityContextService],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualPositionsComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _securityContext: ManualTradingSecurityContextService,
      private readonly _tradingInstrumentsSerivce: TradingInstrumentsService,
      private readonly _backendClient: ManualTradingBackendService,
      private readonly _toastr: ToastrService,
      private readonly _sessionService: SessionService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _timestampsService: TimestampsService,
      private readonly _shellClient: ShellClientService
   ) {

      super(_changeDetector, _userSettingsService, _messageBus);

      this._pendingDataContainer = { isLoading: false, data: [] };
      this.contextPopupParent = document.querySelector('body');
   }

   private readonly _pendingDataContainer: {
      isLoading: boolean;
      data: PositionDto[];
   };

   private _unsubscriber: Subject<any> = new Subject();

   private _terminalsGrid: GridReadyEvent;

   private _positionsGrid: GridReadyEvent;

   private _stateChanged = new EventEmitter();

   private _contextId: string;

   private _availableBuckets: GetAvailableBucketsReply;

   //

   @ViewChild(PositionOverrideComponent) overrideDialog: PositionOverrideComponent;
   @ViewChild('addManually') addPositionManuallyDialog: DxPopupComponent;
   @ViewChild(MoveToPortfolioDialogComponent) addToPortfolioDialog: MoveToPortfolioDialogComponent;
   @ViewChild(PositionAdjustComponent) positionAdjustCmpnt: PositionAdjustComponent;
   @ViewChild('terminalsGrid', {static: false}) terminalsGridEl: ElementRef<AgGridAngular>;

   //

   contextPopupParent: HTMLElement;

   //

   addPositionDialogSettings: {
      instrument: TradingInstrument
      avgPx: number,
      netPosition: number,
      terminal: TerminalDto,
      account: AccountDto,
      portfolio?: PortfolioDto,
      combo?: ComboDto,
      comboGroup?: ComboGroupDto,
      availableTerminals: TerminalDto[],
      availableAccounts: AccountDto[],
      availablePortfolios: PortfolioDto[],
      availableCombos: ComboDto[],
      availableComboGroups: ComboGroupDto[]
      isLoading: boolean
   };

   //

   readonly split = {
      terminalsSection: {
         size: 30
      },
      positionsSection: {
         size: 70
      }
   };

   //

   terminalsGridOptions: GridOptions;

   //

   positionsGridOptions: GridOptions;

   //

   get securityContext(): ManualTradingSecurityContextService { return this._securityContext; }

   //

   get unsubscriber(): Subject<void> { return this._unsubscriber; }

   //

   get positionsGrid(): GridApi {

      if (this._positionsGrid) {
         return this._positionsGrid.api;
      }

      return undefined;
   }

   //

   get messageBus(): MessageBusService {
      return this._messageBus;
   }

   //

   get lastQuoteCache(): LastQuoteCacheService {
      return this._lastQuoteCache;
   }

   //

   get timestampsSerivce(): TimestampsService {
      return this._timestampsService;
   }

   //

   get positionsGridApi(): GridApi {
      return this._positionsGrid?.api;
   }

   //

   get temrinalsGridApi(): GridApi {
      return this._terminalsGrid?.api;
   }

   //

   get sessionService(): SessionService {
      return this._sessionService;
   }

   //

   async etsOnInit(): Promise<void> {
      this.terminalsGridOptions = getTerminalsGridOptions.bind(this)();
      this.positionsGridOptions = getPositionsGridOptions.bind(this)();
      this._availableBuckets = await this._backendClient.getAvailableBuckets();
   }

   //

   etsOnDestroy(): void {
      const unsubscriber = this._unsubscriber;
      if (unsubscriber) {
         unsubscriber.next();
         unsubscriber.complete();
      }
   }

   //

   etsAfterViewInit() { }

   //

   @DetectMethodChanges()
   adjustPosition(params: GetContextMenuItemsParams) {
      if (!params || !params.node) {
         return;
      }

      const position: PositionDto = params.node.data;

      console.assert(!isNullOrUndefined(this.positionAdjustCmpnt));

      this.positionAdjustCmpnt.show({
         netPosition: position.netPosition,
         positionId: position.positionId,
         symbol: position.tickerDisplayName
      });
   }

   //

   async onTerminalSelected(node: RowNode): Promise<void> {
      if (!node.data && !node.group) {
         return;
      }

      let selectedTerminalId: string;

      const col = this._positionsGrid.columnApi.getColumn('terminalCode');

      if (node.group) {
      
         this._contextId = node.allLeafChildren[0].data.shellId;
         
         if (isNullOrUndefined(col)) {
            this._positionsGrid.api.setColumnDefs([]);
            const colDefs = getPositionGridColumnDefs(this, true);
            this._positionsGrid.api.setColumnDefs(colDefs);
            this._positionsGrid.columnApi.autoSizeAllColumns();
         }

      } else {

         if (!isNullOrUndefined(col)) {
            this._positionsGrid.api.setColumnDefs([]);
            const colDefs = getPositionGridColumnDefs(this, false);
            this._positionsGrid.api.setColumnDefs(colDefs);
            this._positionsGrid.columnApi.autoSizeAllColumns();
         }

         const terminal = node.data as TerminalDto;
         
         this._contextId = terminal.terminalId;

         selectedTerminalId = terminal.terminalId;
      }

      this.isLoading = true;

      try {

         const cmd = new GetNoBucketManualPositions(selectedTerminalId);

         this._pendingDataContainer.data = [];
         this._pendingDataContainer.isLoading = true;
         this._positionsGrid.api.setRowData([]);

         const positions = await this._shellClient.processQuery<PositionDto[]>(cmd);

         this._pendingDataContainer.isLoading = false;

         this.onPositionDtoMessage(positions);

      } finally {

         this.isLoading = false;
      }

      const message: ManualPositionHighlightedUIMessage = {
         terminalId: selectedTerminalId
      };

      this._messageBus.publishAsync({
         topic: 'ManualPositionHighlightedUIMessage',
         payload: message,
         scopeId: this.layoutTabId
      });

   }

   //

   async positionSelected(node: RowNode): Promise<void> {
      if (!node.data || node.group) {
         return;
      }

      const position: PositionDto = node.data;

      const message: ManualPositionHighlightedUIMessage = {
         terminalId: position.terminalId,
         accountId: position.accountId,
         ticker: position.ticker
      };

      this._messageBus.publishAsync({
         topic: 'ManualPositionHighlightedUIMessage',
         payload: message,
         scopeId: this.layoutTabId
      });
   }

   //

   clearTerminalSelection() {
      this._positionsGrid.api.setRowData([]);
      this._contextId = null;
   }

   //

   async archivePosition(position: PositionDto): Promise<any> {
      if (!position) {
         this._toastr.info('Please, select position');
         return;
      }

      if (position.netPosition !== 0) {
         this._toastr.info('Open position cannot be archived');
         return;
      }

      const cmd = new ArchivePosition(position.positionId);
      try {
         await this._backendClient.archivePosition(cmd);
      } catch (error) {
         const errorMessage = 'Error occurred during "Archive Position" operation';
         console.error(errorMessage, { error });
         this._toastr.error(errorMessage);
      }
   }

   //

   @DetectMethodChanges()
   showOverrideDialog(position: PositionDto): void {

      const tickSize = this._tradingInstrumentsSerivce.getInstrumentByTicker(position.ticker)
         .tickSize;

      const selectedPosition = {
         ticker: position.ticker,
         positionId: position.positionId,
         tickerDisplayName: position.tickerDisplayName,
         netPosition: position.netPosition,
         avgPx: position.avgPx,
         tickSize
      };

      this.overrideDialog.show(selectedPosition);
   }

   //

   async onTerminalsGridReady(args): Promise<any> {
      this._terminalsGrid = args;
      args.columnApi.autoSizeAllColumns();
   }

   //

   async onPositionsGridReady(args): Promise<any> {

      this._positionsGrid = args;
      args.columnApi.autoSizeAllColumns();

      const key = getPanelStateKey(this);
      const item = this._userSettingsService.getValue(key);

      if (!item) {
         this.saveState();
      } else {
         this.restoreState();
      }

      this.subscribeToMessages();

      try {

         await this.loadTerminalsData();

      } catch {
         this._toastr.error('"Manual Positions" panel is failed to initialize');
      }
   }

   //

   onStateChanged(): void {
      this._stateChanged.emit();
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async showAddPositionManuallyDialog(): Promise<void> {
      
      this.isLoading = true;
      try {
         
         const accountsQuery = new GetAccounts();
         const accountDtos = await this._backendClient.getAccounts(accountsQuery);

         const terminals = this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy);

         let terminal: TerminalDto = null;
         const selectedNodes = this._terminalsGrid.api.getSelectedNodes();

         if (selectedNodes.length > 0) {
            const nonGroupNode = selectedNodes.find(x => !x.group);
            if (nonGroupNode) {
               const summary = nonGroupNode.data as BucketSummaryDto;
               if (summary) {
                  terminal = terminals.find(x => x.terminalId === summary.terminalId);
               }
            }
         }

         this.addPositionDialogSettings = {
            instrument: null,
            avgPx: null,
            account: null,
            netPosition: null,
            terminal,
            portfolio: null,
            availableTerminals: terminals,
            availableAccounts: accountDtos,
            availablePortfolios: [],
            availableCombos: [],
            availableComboGroups: [],
            isLoading: false
         };

         this.addPositionManuallyDialog.visible = true;

      } catch (e) {
         
         this._toastr.error('Operation \'Show "Add Manual Positions" dialog\' completed with errors');
         
      } finally {
         
         this.isLoading = false;

      }


   }

   //

   @DetectMethodChanges()
   addPositionDialog_OnTerminalChange(term: TerminalDto) {

      if (!this.addPositionDialogSettings) {
         return;
      }
      
      this.addPositionDialogSettings.terminal = term;

      this.addPositionDialogSettings.portfolio = null;
      this.addPositionDialogSettings.availablePortfolios = [];
            
      if (!term) {
         return;
      }

      const portfolios = this._availableBuckets.portfolios.filter(x => x.terminalId === term.terminalId);
      this.addPositionDialogSettings.availablePortfolios = portfolios;
   }

   //

   @DetectMethodChanges()
   addPositionDialog_OnPortfolioChange(pf: PortfolioDto) {

      if (!this.addPositionDialogSettings) {
         return;
      }

      this.addPositionDialogSettings.portfolio = pf;

      this.addPositionDialogSettings.combo = null;
      this.addPositionDialogSettings.availableCombos = [];
      
      if (!pf) {
         return;   
      }

      const combos = this._availableBuckets.combos.filter(x => x.portfolioId === pf.portfolioId);
      this.addPositionDialogSettings.availableCombos = combos;
   }
   
   //

   @DetectMethodChanges()
   addPositionDialog_OnComboChange(combo: ComboDto) {

      if (!this.addPositionDialogSettings) {
         return;
      }

      this.addPositionDialogSettings.combo = combo;
      
      this.addPositionDialogSettings.comboGroup = null;
      this.addPositionDialogSettings.availableComboGroups = [];

      if (!combo) {
         return;
      }

      const comboGroups = this._availableBuckets.comboGroups.filter(x => x.comboId === combo.comboId);
      this.addPositionDialogSettings.availableComboGroups = comboGroups;
   }

   //

   addPositionDialog_OnComboGroupChange(comboGroup: ComboGroupDto) {
      if (!this.addPositionDialogSettings) {
         return;
      }
      
      this.addPositionDialogSettings.comboGroup = comboGroup;
   }

   //

   addPositionDialog_OnShown() {
      if (this.addPositionDialogSettings.terminal) {
         this.addPositionDialog_OnTerminalChange(this.addPositionDialogSettings.terminal);
      }
   }
   
   //

   addPositionDialog_OnClosed() {
      this.addPositionDialogSettings = null;
      this.addPositionManuallyDialog.visible = false;
   }

   //

   @DetectMethodChanges()
   closeAddPositionDialog(): void {
      this.addPositionManuallyDialog.visible = false;
      this.addPositionDialogSettings = null;
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async addPositionManually(): Promise<void> {
      const settings = this.addPositionDialogSettings;
      
      if (!settings) {
         return;
      }
      
      if (!settings.instrument) {
         this._toastr.error('Please Select Instrument');
         return;
      }
      
      if (!settings.terminal) {
         this._toastr.error('Please Select Terminal');
         return;
      }
      
      if (!settings.account) {
         this._toastr.error('Please Select Account');
         return;
      }
      
      if (!settings.netPosition) {
         this._toastr.error('Please Set Net Position');
         return;
      }
      
      if (!settings.avgPx) {
         this._toastr.error('Please Set Avg.Px');
         return;
      }

      const portfolioId = settings.portfolio ? settings.portfolio.portfolioId : null;
      const comboId = settings.combo ? settings.combo.comboId : null;
      const comboGroupId = settings.comboGroup ? settings.comboGroup.comboGroupId : null;

      try {

         settings.isLoading = true;

         const checkPositionQry = new CheckIfManualPositionExists(
            settings.instrument.ticker,
            settings.terminal.terminalId,
            settings.account.accountId,
            portfolioId,
            comboId,
            comboGroupId
         );

         const existingPosition = await this._backendClient.checkIfManualPositionExists(checkPositionQry);
         if (existingPosition) {
            this._toastr.error('Cannot add new position: such position already exist!');
            return;
         }


         const addPositionCommand = new AddManualPosition(
            settings.instrument.ticker,
            settings.terminal.terminalId,
            settings.account.accountId,
            settings.netPosition,
            settings.avgPx,
            portfolioId,
            comboId,
            comboGroupId
         );

         await this._backendClient.addManualPosition(addPositionCommand);

         this._toastr.success('Position Added!');
      } catch (e) {
         this._toastr.error('"Add New Position" operation completed with errors');
         console.error('addPositionManually()', { data: e.stack });
      } finally {
         settings.isLoading = false;
      }
   }

   //

   async rollPosition(data: PositionDto) {
      await this._backendClient.rollManualPosition(data.positionId);
   }

   //

   @DetectMethodChanges()
   addToPortfolio(target: 'existing' | 'new', position: PositionDto): void {

      if (!this.addToPortfolioDialog) {
         this._toastr.error('"Move To Portfolio" dialog not loaded');
         return;
      }

      const addToPortfoliodDialogConfig: MoveToPortfolioDialogConfig = {
         itemType: PortfolioEntityType.Position,
         itemId: position.positionId,
         itemNetPosition: position.netPosition,
         itemAvgPx: position.avgPx,
         itemTerminalId: position.terminalId,
         itemPortfolioId: position.portfolioId,
         itemComboId: position.comboId,
         itemComboGroupId: position.comboGroupId
      };

      this.addToPortfolioDialog.show(addToPortfoliodDialogConfig);
   }

   //

   async toggleCashSettled(data: PositionDto) {
      const cmd = new ToggleCashSettled(data.positionId);
      
      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch {
         this._toastr.error('"Toggle Cash Settled" operation completed with errors');
      } finally {
         this.isLoading = false;
      }
   }

   //

   private async onShellConnectionStatusChangedUIMessage(msg: ShellConnectionStatusChangedUIMessage): Promise<any> {
      console.info('Received "ShellConnectionStatusChangedUIMessage"');
      // await this.loadManualPositionsData(msg.shellId);
   }

   //

   private async onClearTradingDataUIMessage(message: ClearTradingDataUIMessage): Promise<any> {

      if (message.hasErrors) {
         return;
      }

      if (!message.data.manual && !message.refreshDb) {
         return;
      }

      await this.loadTerminalsData();
   }

   //

   private async loadTerminalsData(): Promise<void> {
      this.isLoading = true;

      try {
         
         this._positionsGrid.api.setRowData([]);
         const cmd = new GetNoBucketSummaries();
         const summaries = await this._shellClient.processQuery<BucketSummaryDto[]>(cmd);
         this._terminalsGrid.api.setRowData(summaries);
         this.expandTerminalGroups();

      } finally {

         this.isLoading = false;

      }

   }

   //

   expandTerminalGroups() {
      
      if (!this._terminalsGrid) {
         return;
      }

      this._terminalsGrid.api.forEachNode(node => {
         if (!node.isExpandable()) {
            return;
         }

         node.expanded = true;
      });
      
      if (!isNullOrUndefined(this.terminalsGridEl)) {
         const terminalsGridElement = this.terminalsGridEl as any;
         if (!isNullOrUndefined(terminalsGridElement._nativeElement)) {
            setTimeout(() => {
               if (terminalsGridElement._nativeElement.clientHeight > 0) {
                  this._terminalsGrid.api.onGroupExpandedOrCollapsed();
               }
            }, 50);
         }
      }
   }

   //

   async togglePositionFlag(data: PositionDto, flag: PositionFlags): Promise<void> {
      
      if (isNullOrUndefined(data)) {
         this._toastr.error('Bad Position', 'Manual Positions');
         return;
      }

      if (!data.positionId) {
         this._toastr.error('Bad Position ID', 'Manual Positions');
         return;
      }

      const cmd = new TogglePositionFlag(data.positionId, flag);

      try {
         
         await this._shellClient.processCommand(cmd);
      } catch (e) {

         this._toastr.error('"Toggle Position Last Over Session Reset" operation completed with errors', 'Manual Positions');
         console.error(e);
      }
   }

   //

   private onBucketSummaryDto(summaries: BucketSummaryDto[]) {

      const rows = [];

      summaries.filter(dto => dto.bucketType === '<No Bucket>').forEach(dto => {
         const node: RowNode = this._terminalsGrid.api.getRowNode(dto.terminalId);

         if (isNullOrUndefined(node)) {
            return;
         }

         const data = node.data as BucketSummaryDto;
         data.sessionTotalPnL = dto.sessionTotalPnL;
         data.accumulatedTotalPnL = dto.accumulatedTotalPnL;

         rows.push(data);
      });


      if (rows.length > 0) {
         this._terminalsGrid.api.applyTransactionAsync({ update: rows });
      }
   }

   //

   private onPositionDtoMessage(positions: PositionDto[]): void {

      if (this._pendingDataContainer.isLoading) {

         this._pendingDataContainer.data.push(...positions);

         return;

      }

      if (this._pendingDataContainer.data.length) {
         positions.push(...this._pendingDataContainer.data);
         this._pendingDataContainer.data.length = 0;
      }

      const actualStateOfPositions: Record<string, PositionDto> = {};

      positions.forEach(dto => {

         if (dto.isArchived) {
            return;
         }

         if (!isNullOrUndefined(dto.portfolioId)) {
            return;
         }

         if (isNullOrUndefined(dto.terminalId) || isNullOrUndefined(dto.accountId)) {
            return;
         }

         if (dto.terminalId !== this._contextId) {
            if (dto.shellId !== this._contextId) {
               return;
            }
         }

         actualStateOfPositions[dto.positionId] = dto;
      });

      const reducedPositions = Object.values(actualStateOfPositions);

      if (reducedPositions.length === 0) {
         return;
      }

      const add: PositionDto[] = [];
      const update: PositionDto[] = [];

      reducedPositions.forEach(dto => {

         const node: RowNode = this._positionsGrid.api.getRowNode(dto.positionId);

         if (node) {
            update.push(dto);
         } else {
            add.push(dto);
            this._lastQuoteCache.subscribeIfNotYet(dto.ticker);
         }

         const lq = this._lastQuoteCache.getLastQuote(dto.ticker);

         if (lq) {

            if (lq.ticker.startsWith('@XSP')) {

               dto.liveQuote = lq.mid;

            } else {
               if (dto.netPosition > 0) {

                  dto.liveQuote = lq.bid;

               } else if (dto.netPosition < 0) {

                  dto.liveQuote = lq.ask;

               } else {

                  dto.liveQuote = lq.lastPx;

               }
            }

         }
      });

      this._positionsGrid.api.applyTransactionAsync({ add, update }, (tran) => {
         this._positionsGrid.api.refreshCells({
            columns: ['ticker', 'expStyle'], 
            rowNodes: tran.update, 
            force: true
         });
      });
   }

   //

   private onPositionArchived(message: PositionArchived): void {
      this.removeSpecificPosition(message.positionId);
   }

   //

   private subscribeToMessages(): void {

      this._messageBus
         .of<PositionDto[]>('PositionDto')
         .pipe(
            map(msg => msg.payload.filter(x => x.strategyId === EtsConstants.strategies.manualStrategyId && !x.isArchived)),
            filter(positions => positions.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(positions => setTimeout(() => this.onPositionDtoMessage(positions), 0));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) => setTimeout(() => this.onClearTradingDataUIMessage(msg.payload), 0));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            filter(x => x.payload.isConnected),
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) => setTimeout(() => this.onShellConnectionStatusChangedUIMessage(msg.payload), 0));

      this._messageBus
         .of<PositionArchived>('PositionArchived')
         .pipe(
            filter(x => x.payload.strategyId === EtsConstants.strategies.manualStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onPositionArchived(x.payload));

      this._messageBus
         .of<PositionRemoved>('PositionRemoved')
         .pipe(
            filter(x => x.payload.strategyId === EtsConstants.strategies.manualStrategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.removeSpecificPosition(x.payload.positionId));

      this._stateChanged.pipe(
         throttleTime(250),
         takeUntil(this._unsubscriber)
      ).subscribe(() => this.saveState());


      this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe((msg) => this.onQuoteMessage(msg.payload));

      this._messageBus
         .of<TerminalExposureChangedUIMessage>('TerminalExposureChangedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onTerminalExposureChanged(msg.payload));

      this._messageBus
         .of<BucketSummaryDto[]>('BucketSummaryDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(msg => this.onBucketSummaryDto(msg.payload));
   }

   //

   private onTerminalExposureChanged(msg: TerminalExposureChangedUIMessage): void {

   }

   //

   private removeSpecificPosition(positionId: string) {
      this._positionsGrid.api.applyTransactionAsync({ remove: [{ positionId }] });
   }

   //

   private onQuoteMessage(quotes: QuoteDto[]) {

      this._positionsGrid.api.forEachLeafNode(node => {

         const pos = node.data as PositionDto;

         const quote = quotes.find(q => tickersMatch(pos.ticker, q.ticker));

         if (quote) {
            if (pos.netPosition > 0) {

               pos.liveQuote = quote.bid;

            } else if (pos.netPosition < 0) {

               pos.liveQuote = quote.ask;

            } else {

               pos.liveQuote = quote.lastPx;

            }
         }

         this._positionsGrid.api.refreshCells({ columns: ['liveQuote'], force: true });

      });
   }

   //

   protected getState(): PanelState {

      const state = {} as PanelState;

      // if (!this._grid) {
      //    return null;
      // }

      // const key = getPanelStateKey(this);
      // const gridState = this._grid.columnApi.getColumnState();
      // const state: PanelState = { columnsState: gridState };

      return state;
   }

   //

   protected setState(state: PanelState): void {

      // if (!this._grid) {
      //    return;
      // }

      // const isOK = this._grid.columnApi.setColumnState(state.columnsState);

      // if (!isOK) {
      //    this._toastr.error('"Manual Positions" panel was restored with errors');
      // }
   }
}

