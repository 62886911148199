import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as Enumerable from 'linq';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from 'projects/shared-components/authentication/session-service.service';
import {ClipboardService} from 'projects/shared-components/clipboard.service';
import {LastQuoteCacheService} from 'projects/shared-components/last-quote-cache.service';
import {OptionsChainService} from 'projects/shared-components/option-chains.service';
import {
    isCashOnlySettledInstrument,
    makeOptionDisplayName,
    makeOptionExpirationDescriptor,
    makeOptionTicker,
    OptionStrategy,
    OptionType
} from 'projects/shared-components/options-common/options.model';
import {BucketType, PortfolioItemType} from 'projects/shared-components/portfolios/portfolios.model';
import {QuoteDto} from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import {TerminalDto} from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import {
    ComboCreatedDto,
    ComboGroupCreatedDto,
    ConvertToMarketSettings,
    FutureTimeSettings,
    GroupDeletedDto,
    OptionExpirationDescriptor,
    PortfolioCreatedDto,
    PortfolioItemDto
} from 'projects/shared-components/shell-communication/shell-dto-protocol';
import {
    BucketContext,
    CreateAdjustmentStrategy,
    GetBucketItems,
    MultiLegOrderDto,
    OrderLegDto
} from 'projects/shared-components/shell-communication/shell-operations-protocol';
import {SymbolPickerComponent} from 'projects/shared-components/symbol-picker/symbol-picker.component';
import {TimestampsService} from 'projects/shared-components/timestamps.service';
import {TradingInstrumentKind} from 'projects/shared-components/trading-instruments/trading-instrument-kind.enum';
import {TradingInstrument} from 'projects/shared-components/trading-instruments/trading-instrument.class';
import {
    TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import {MarketSide} from 'projects/shared-components/trading-model/market-side.enum';
import {TimeInForce} from 'projects/shared-components/trading-model/time-in-force.enum';
import {
    BucketHighlighted,
    BucketItemHighlighted,
    CashFlowAdjustmentSpec,
    ComboHighlightedItem,
    ComboHighlightedUIMessage,
    MultiLegOrderDataUIMessage,
    SymbolHighlighted
} from 'projects/shared-components/ui-messages/ui-messages';
import {
    convertCashSettledTickerToNormal,
    convertTickerToCashSettled,
    DetectMethodChanges,
    DetectSetterChanges,
    DxValueChanged,
    findAtmStrikeIndex,
    findHCF,
    fromKVPString,
    isCashSettledOptionTicker,
    isTruthy,
    isValidNumber,
    isVoid,
    tickersMatch
} from 'projects/shared-components/utils';
import {Subject} from 'rxjs';
import {isNullOrUndefined, isUndefined} from 'util';
import {
    ActionType,
    DestinationBucketDescriptor,
    IMultiTradePadComponent,
    LegType,
    MultiLegOrderVerificationModel,
    OrderDestination,
    OrderDuration,
    OrderLegVerificationModel,
    OrderType
} from '../multi-trade-pad.model';
import {OrderLeg} from './order-leg.class';
import {MultiLegOrder} from './multi-leg-order.class';
import {ShellClientService} from 'projects/shared-components/shell-communication/shell-client.service';
import {MessageBusService} from 'projects/shared-components/message-bus.service';
import {takeUntil} from 'rxjs/operators';
import {EtsConstants} from 'projects/shared-components/ets-constants.const';
import {StrategyModel} from 'projects/shared-components/strategies/strategy-model';
import {
    AdjustmentStrategyDialogComponent
} from 'projects/shared-components/adjustment-strategy-dialog/adjustment-strategy-dialog.component';
import {GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {StrategiesService} from 'projects/shared-components/strategies/strategies.service';
import {getBucketReferenceGridOptions} from './bucket-reference-grid-options';
import {
    AttachStrategyDialogConfig
} from 'projects/shared-components/adjustment-strategy-dialog/adjustment-strategy-dialog.model';
import {SettingsStorageService} from "../../settings-storage-service.service";
import DevExpress from "devextreme";

function convertLegToDto(leg: OrderLeg): OrderLegDto {
    return {
        action: leg.action as unknown,
        qty: leg.qty,
        ticker: leg.ticker,
        execMilliseconds: leg.execMilliseconds,
        execDate: leg.execDate,
        execPrice: leg.execPrice,
        terminalId: leg.terminal ? leg.terminal.terminalId : null,
        portfolioId: leg.portfolio ? leg.portfolio.portfolioId : null,
        comboId: leg.combo ? leg.combo.comboId : null,
        comboGroupId: leg.comboGroup ? leg.comboGroup.comboGroupId : null,
    } as OrderLegDto;
}

//

class FutureTimeModel {

    constructor(private _changeDetector: ChangeDetectorRef) {
    }

    timezone: string;
    actionTime: string;
    actionTimeMode: string;

    asSettings(): FutureTimeSettings {
        if (this.actionTime && this.actionTimeMode) {
            return {
                actionTime: this.actionTime,
                actionTimeMode: this.actionTimeMode,
                timezone: this.timezone
            } as FutureTimeSettings;
        }

        return null;
    }

    @DetectMethodChanges()
    apply(futureSettings: FutureTimeSettings) {
        this.actionTime = futureSettings.actionTime;
        this.actionTimeMode = futureSettings.actionTimeMode;

        const timezone = futureSettings.timezone;
        this.timezone = timezone;
    }

    @DetectMethodChanges()
    onChange(ev: DxValueChanged<any>) {
        //
    }

    reset() {
        this.timezone = null;
        this.actionTime = null;
        this.actionTimeMode = null;
    }

    getDatetimePickerMode() {
        if (!this.actionTimeMode) {
            return '';
        }

        if (this.actionTimeMode.endsWith(' At')) {
            return 'datetime';
        }

        if (this.actionTimeMode.endsWith(' After')) {
            return 'timespan';
        }

        return '';
    }

}

//
class ConvertToMarketModel {

    constructor(private _changeDetector: ChangeDetectorRef) {
    }

    timezone: string;
    actionTime: string;
    actionTimeMode: string;
    timesToReplace?: number;
    rateOfChange?: number;
    replaceEvery?: string;
    reverseTimeDirection?: boolean;
    replacePersistently = false;

    asSettings(): ConvertToMarketSettings {
        if (this.actionTime && this.actionTimeMode) {
            return {
                actionTime: this.actionTime,
                actionTimeMode: this.actionTimeMode,
                timezone: this.timezone,
                timesToReplace: this.timesToReplace,
                rateOfChange: this.rateOfChange,
                replaceEvery: this.replaceEvery,
                reverseTimeDirection: this.reverseTimeDirection,
                replacePersistently: this.replacePersistently
            } as ConvertToMarketSettings;
        }

        return null;
    }

    @DetectMethodChanges()
    apply(settings: ConvertToMarketSettings) {
        this.actionTime = settings.actionTime;
        this.actionTimeMode = settings.actionTimeMode;

        const timezone = settings.timezone;
        this.timezone = timezone;

        this.timesToReplace = settings.timesToReplace;
        this.rateOfChange = settings.rateOfChange;
        this.replaceEvery = settings.replaceEvery;
        this.reverseTimeDirection = settings.reverseTimeDirection;
        this.replacePersistently = settings.replacePersistently;
    }

    @DetectMethodChanges()
    onChange(ev: DxValueChanged<any>) {
        //
    }

    @DetectMethodChanges()
    reset() {
        this.timezone = null;
        this.actionTime = null;
        this.actionTimeMode = null;
        this.timesToReplace = null;
        this.rateOfChange = null;
        this.replaceEvery = null;
        this.replacePersistently = false;
        this.reverseTimeDirection = false;
    }

    getDatetimePickerMode() {
        if (!this.actionTimeMode) {
            return '';
        }

        if (this.actionTimeMode.endsWith(' At')) {
            return 'datetime';
        }

        if (this.actionTimeMode.endsWith(' After')) {
            return 'timespan';
        }

        return '';
    }

}


//

class FutureOrderDialog {
    constructor(private _changeDetector: ChangeDetectorRef, private _toastr: ToastrService) {
        this.futureTimeSettings = new FutureTimeModel(_changeDetector);
        this.convertToMarketSettings = new ConvertToMarketModel(_changeDetector);
    }

    futureOrderApplied$ = new EventEmitter();
    futureOrderReset$ = new EventEmitter();

    //

    private _isVisible: boolean;
    get isVisible(): boolean {
        return this._isVisible;
    }

    @DetectSetterChanges()
    set isVisible(v: boolean) {
        this._isVisible = v;
    }

    //

    showResetButton = false;

    //

    futureTimeSettings: FutureTimeModel;

    //

    convertToMarketSettings: ConvertToMarketModel;

    //

    private _convertToMarket = false;
    get convertToMarket(): boolean {
        return this._convertToMarket;
    }

    @DetectSetterChanges()
    set convertToMarket(v: boolean) {
        this._convertToMarket = v;

        if (!v) {
            this.convertToMarketSettings.reset();
        }
    }

    //

    private _futureOrder = false;
    public get futureOrder(): boolean {
        return this._futureOrder;
    }

    @DetectSetterChanges()
    public set futureOrder(v: boolean) {
        this._futureOrder = v;
        if (!v) {
            this.futureTimeSettings.actionTime = null;
            this.futureTimeSettings.actionTimeMode = null;
            this.futureTimeSettings.timezone = null;
        }
    }


    //

    @DetectMethodChanges()
    show(futureSettings: FutureTimeSettings, convertSettings: FutureTimeSettings) {
        if (futureSettings) {
            this.futureOrder = true;
            this.showResetButton = true;
            this.futureTimeSettings.apply(futureSettings);
        }

        if (convertSettings) {
            this.showResetButton = true;
            this.convertToMarketSettings.apply(convertSettings);
        }
        this.isVisible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.isVisible = false;
    }

    @DetectMethodChanges()
    onOkClicked() {
        if (this.futureOrder) {
            const futErrors = this.validate(this.futureTimeSettings);

            if (futErrors.length > 0) {
                this._toastr.error('Correct time settings for order placement in future');
                return;
            }
        }

        if (this.convertToMarket) {
            const convertErrors = this.validate(this.convertToMarketSettings);

            if (convertErrors.length > 0) {
                this._toastr.error('Correct time settings for convert to market');
                return;
            }

        }

        this.futureOrderApplied$.emit();
        this.isVisible = false;
    }

    @DetectMethodChanges()
    onCancelClicked() {
        this.isVisible = false;
        this.reset();
    }

    onResetClicked() {
        this.futureOrderReset$.emit();
        this.onCancelClicked();
    }

    private validate(settings: FutureTimeModel | ConvertToMarketModel): string[] {
        const errors = [];

        if (isNullOrUndefined(settings.actionTime)) {

            errors.push('"Convert Time is required"');

        } else {
            const parts = settings.actionTime.split(':');
            if (parts.length !== 3) {
                errors.push('"Convert Time" is incorrect. Use "hh:mm:ss" pattern');
            } else {
                const hours = parseInt(parts[0]);
                const minutes = parseInt(parts[1]);
                const seconds = parseInt(parts[2]);

                if (isNaN(hours)) {
                    errors.push('"Convert Time" is incorrect. Use "hh:mm:ss" pattern');
                } else {
                    if (isNaN(minutes) || minutes > 59) {
                        errors.push('"Convert Time" is incorrect. Use "hh:mm:ss" pattern');
                    } else {
                        if (isNaN(seconds) || seconds > 59) {
                            errors.push('"Convert Time" is incorrect. Use "h:mm:ss" pattern');
                        }
                    }
                }
            }
        }

        return errors;
    }

    @DetectMethodChanges()
    reset() {
        this.convertToMarketSettings.reset();
        this.futureTimeSettings.reset();
        this.convertToMarket = false;
        this.showResetButton = false;
        this.futureOrderReset$.emit();
    }

    getDialogHeight() {
        let height = 538;

        const futureSettingsMode = this.futureTimeSettings.actionTimeMode || '';
        if (futureSettingsMode.endsWith(' At')) {
            height += 90; // 410
        }

        const convertSettingsMode = this.convertToMarketSettings.actionTimeMode || '';
        if (convertSettingsMode.endsWith(' At')) {
            height += 90; // 500
        }

        return height;
    }
}

//


type OptionStrategySettings =
      'mlpad.option-type'
    | 'mlpad.width'
    | 'mlpad.offset'
    | 'mlpad.instrument';

//

@Component({
    selector: 'ets-multi-leg-order',
    templateUrl: 'multi-leg-order.component.html',
    styleUrls: ['multi-leg-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiLegOrderComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _optionChainsService: OptionsChainService,
        private readonly _toastr: ToastrService,
        private readonly _sessionService: SessionService,
        private readonly _clipboardService: ClipboardService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _timestampService: TimestampsService,
        private readonly _shellClient: ShellClientService,
        protected readonly _messageBus: MessageBusService,
        private readonly _strategiesService: StrategiesService,
        private readonly _settingsStorageService: SettingsStorageService
    ) {
        this.futureOrderDialog = new FutureOrderDialog(_changeDetector, _toastr);

        this.futureOrderDialog.futureOrderApplied$.subscribe(() => {
            this.futureOrderSettings = this.futureOrderDialog.futureTimeSettings.asSettings();
            this.convertToMarketSettings = this.futureOrderDialog.convertToMarketSettings.asSettings();
        });

        this.futureOrderDialog.futureOrderReset$.subscribe(() => {
            this.futureOrderSettings = null;
            this.convertToMarketSettings = null;
            this._changeDetector.detectChanges();
        });
    }

    private _unsubscriber: Subject<any> = new Subject<any>();
    private _priceUpdateInterval: number;
    private _bucketReferenceGrid: GridReadyEvent;

    //

    private _groupTotals = {};

    //

    get bucketReferenceGrid(): GridReadyEvent {
        return this._bucketReferenceGrid;
    }

    //

    cashFlowSpec: CashFlowAdjustmentSpec;
    attachment: CreateAdjustmentStrategy;
    futureOrderSettings: FutureTimeSettings;
    convertToMarketSettings: ConvertToMarketSettings;

    //

    futureOrderDialog: FutureOrderDialog;

    //

    @Input() comp: IMultiTradePadComponent;

    //

    @Input() orderRole: string;

    //

    @Output() destinationAdded: EventEmitter<OrderDestination> = new EventEmitter<OrderDestination>();
    @Output() destinationRemoved: EventEmitter<number> = new EventEmitter<number>();
    @Output() destinationPressed: EventEmitter<{ ix: number, state: boolean }> = new EventEmitter<{
        ix: number,
        state: boolean
    }>();
    @Output() linkedOrdersPasted = new EventEmitter<MultiLegOrderDataUIMessage>();

    //

    @ViewChild('symbolPicker', {static: false}) readonly symbolPicker: SymbolPickerComponent;

    //

    @ViewChild(AdjustmentStrategyDialogComponent) attachStrategyDialog: AdjustmentStrategyDialogComponent;

    //

    get messageBus(): MessageBusService {
        return this._messageBus;
    }

    //

    get timestampsService(): TimestampsService {
        return this._timestampService;
    }

    //

    get unsubscriber(): Subject<any> {
        return this._unsubscriber;
    }

    //

    get strategyService(): StrategiesService {
        return this._strategiesService;
    }

    //

    get gridApi(): GridApi {
        if (!this._bucketReferenceGrid) {
            return null;
        }
        return this._bucketReferenceGrid.api;
    }

    //

    get isLiveAccountSelected(): boolean {
        let res = true;

        if (this.orderModel) {
            if (this.orderModel.destinations) {
                res = this.orderModel.destinations.some(x => !x.account || !x.account.isPaperTrading);
            }
        }

        return res;
    }

    //

    private _orderModel: MultiLegOrder;

    get orderModel(): MultiLegOrder {
        return this._orderModel;
    }

    @DetectSetterChanges()
    set orderModel(val: MultiLegOrder) {
        this._orderModel = val;
    }

    //

    readonly availableStrategies: OptionStrategy[] = [
        'BOS',
        'Butterfly',
        'Butterfly - Double',
        'Butterfly - Triple',
        'Collar',
        'Condor',
        'Condor - Double',
        'Iron Butterfly',
        'Iron Condor',
        'Ladder',
        'Ladder - Calendarized',
        'Married Stock',
        'Option - Long',
        'Option - Short',
        'Ratio Back Spread',
        'Risk Reversal',
        'Slingshot',
        'Slingshot - Modified',
        'Slingshot - Double',
        'Sponsored Long',
        'Straddle',
        'Strangle',
        'Vertical',
        'Vertical - Double'
    ];

    get isOptionTypeAvailable(): boolean {

        if (!this.orderModel.selectedStrategy) {
            return false;
        }

        switch (this.orderModel.selectedStrategy) {
            case "Collar":
            case "Risk Reversal":
            case "Iron Butterfly":
            case "Iron Condor":
            case "Married Stock":
            case "Strangle":
            case "Straddle":
            case 'Vertical - Double':
            case 'Condor - Double':
            case 'Butterfly - Double':
            case 'Slingshot - Double':
                return false;
            default:
                return true;
        }
    }


    get optionTypeList(): any[] {
        if (isVoid(this.orderModel.selectedStrategy)) {
            return [];
        }

        const v: any[] = ['Calls', 'Puts'];

        switch (this.orderModel.selectedStrategy) {
            case "Butterfly - Double":
            case 'Collar':
            case 'Ladder':
            case 'Risk Reversal':
            case 'Iron Butterfly':
            case 'Iron Condor':
            case 'Strangle':
            case 'Straddle':
            case 'Condor - Double':
            case 'Vertical - Double':
            case 'Slingshot - Double':
                v.push('Calls & Puts');
                break;
        }

        return v;
    }

    get isStrikeWidthAvailable(): boolean {
        const hasNecessary =
            !isVoid(this.orderModel.tradingInstrument) &&
            !isVoid(this.orderModel.selectedStrategy);

        if (!hasNecessary) {
            return false;
        }

        switch (this.orderModel.selectedStrategy) {
            case "Married Stock":
            case "Straddle":
            case "Option - Long":
            case "Option - Short":
                return false;
            default:
                return true;
        }
    }

    //

    get isOffsetAvailable(): boolean {
        switch (this.orderModel.selectedStrategy) {
            case 'BOS':
            case "Butterfly":
            case "Butterfly - Double":
            case "Butterfly - Triple":
            case "Condor":
            case "Risk Reversal":
            case "Vertical":
            case "Option - Long":
            case "Option - Short":
            case "Ladder":
            case "Ladder - Calendarized":
            case 'Slingshot':
            case 'Slingshot - Modified':
            case 'Slingshot - Double':
            case 'Sponsored Long':
            case 'Condor - Double':
            case 'Vertical - Double':
                return true;
        }

        return false;
    }

    strikeOffsetMode: 'Offset' | 'Strike' = 'Offset';

    baseStrike: number;

    selectedExpiration: OptionExpirationDescriptor;

    //

    bucketReferenceGridOptions: GridOptions;

    //

    private _isLoading = false;

    get isLoading(): boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    //

    private _isLinkedMode = false;

    get isLinkedMode(): boolean {
        return this._isLinkedMode;
    }

    @DetectSetterChanges()
    set isLinkedMode(val: boolean) {
        this._isLinkedMode = val;
    }

    //

    private _isRollMode = false;

    get isRollMode(): boolean {
        return this._isRollMode;
    }

    @DetectSetterChanges()
    set isRollMode(val: boolean) {
        this._isRollMode = val;

        if (val) {
            if (this.isMultiBucket) {
                this._isMultiBucket = false;
            }
        }
    }

    //

    private _isMultiBucket = false;

    get isMultiBucket(): boolean {
        return this._isMultiBucket;
    }

    @DetectSetterChanges()
    set isMultiBucket(val: boolean) {

        this._isMultiBucket = val;

        this.isLoading = true;

        const dest = this.orderModel.getDestination();

        setTimeout(() => {
            try {
                if (val) {
                    if (this.orderModel.legs.length > 0) {
                        if (dest.terminal) {
                            this.orderModel.setTerminalForLegs(dest.terminal);
                        }
                    }
                } else {
                    this.orderModel.setTerminalForLegs(null);
                }

            } finally {
                this.isLoading = false;
            }
        });
    }

    //

    private _isCumulative = false;

    get isCumulative(): boolean {
        return this._isCumulative;
    }

    @DetectSetterChanges()
    set isCumulative(val: boolean) {
        this._isCumulative = val;
    }

    //

    get changeDetector(): ChangeDetectorRef {
        return this._changeDetector;
    }

    //

    get hasSomethingInClipboard(): boolean {
        return this._clipboardService.hasKey('combo') ||
            this._clipboardService.hasKey('multi-combo');
    }

    //

    get orderHasLegs(): boolean {
        const om = this.orderModel;

        if (!om) {
            return false;
        }

        return om.legs.length > 0;

    }

    //

    get canAddDestination(): boolean {
        return !this.isRollMode && !this.isMultiBucket;
    }

    //

    get isMultiDestination(): boolean {
        return this.orderModel.destinations.length > 1;
    }

    //

    strikeWidth: number = 10;

    strikeOffset: number = 20;

    optionType: 'Calls' | 'Puts' | 'Calls & Puts' = 'Calls';

    showRowGap = true;

    enableAutoSort = true;

    //

    ngOnInit() {

        this.bucketReferenceGridOptions = getBucketReferenceGridOptions.bind(this)();

        this.orderModel = this.makeEmptyOrderInstance();

        this.subscribeToMessages();

        this._priceUpdateInterval = setInterval(() => {

            this.updateTotalPrice(this.orderModel);

        }, 1000) as any;
    }

    //

    ngAfterViewInit() {
        this.restoreInstrument();
    }

    private restoreInstrument() {

        setTimeout(async () => {
            const item = this._settingsStorageService.getItem<string>('mlpad.instrument');

            if (isVoid(item)) {
                return;
            }

            const ti = this._tiService.getInstrumentByTicker(item);

            if (isVoid(ti)) {
                return;
            }

            await this.onSymbolSelected(ti);
        });
    }

//

    ngOnDestroy() {

        clearInterval(this._priceUpdateInterval);

        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

        this.orderModel.dispose(this._lastQuoteCache);

    }

    //

    private subscribeToMessages() {

        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onQuote(x.payload));

        this._messageBus.of<PortfolioCreatedDto>('PortfolioCreatedDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onPortfolioCreatedMessage(msg.payload));

        this._messageBus
            .of<ComboCreatedDto>('ComboCreatedDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((msg) => this.onComboCreatedMessage(msg.payload));

        this._messageBus
            .of<ComboGroupCreatedDto>('ComboGroupCreatedDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((msg) => this.onComboGroupCreatedMessage(msg.payload));

        this._messageBus.of<GroupDeletedDto>('GroupDeletedDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onGroupDeletedMessage(msg.payload));


        this._messageBus.of('ClipboardUpdated')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(_ => this._changeDetector ? this._changeDetector.detectChanges() : null);
    }

    //

    async processSymbolHighlightedMessage(msg: SymbolHighlighted): Promise<void> {

        const ti = this._tiService.getInstrumentByTicker(msg.ticker);

        if (isNullOrUndefined(ti)) {
            return;
        }

        await this.onSymbolSelected(ti);

    }

    //

    @DetectMethodChanges({isAsync: true})
    async onSymbolSelected(ti: TradingInstrument): Promise<void> {

        const order = this.orderModel;

        if (!ti) {
            order.lastPx = undefined;
            return;
        }

        if (isTruthy(order.tradingInstrument)) {
            this._lastQuoteCache.unsubscribeTicker(order.tradingInstrument.ticker);
        }

        const lq = this._lastQuoteCache.subscribeTicker(ti.ticker);

        if (lq) {
            order.lastPx = lq.lastPx;
        } else {
            order.lastPx = undefined;
        }

        order.isLoadingData = true;

        try {

            order.tradingInstrument = ti;

            const instrument = this.orderModel.tradingInstrument?.ticker;
            this._settingsStorageService.setItem('mlpad.instrument', instrument);

            order.legs
                .filter(leg => leg.legType === LegType.Option)
                .filter(leg => leg.ticker)
                .forEach(leg => {
                    this._lastQuoteCache.unsubscribeTicker(leg.ticker);
                });

            order.rollingLegs
                .filter(leg => leg.legType === LegType.Option)
                .filter(leg => leg.ticker)
                .forEach(leg => {
                    this._lastQuoteCache.unsubscribeTicker(leg.ticker);
                });


            order.legs.length = 0;
            order.rollingLegs.length = 0;

            order.totalAsk = null;
            order.totalBid = null;
            order.totalMid = null;

            const chain = await this._optionChainsService.getChain(ti.ticker);

            order.expirationsList = chain.expirations.slice();

            this.selectedExpiration = order.expirationsList[0];


        } finally {
            order.isLoadingData = false;
        }
    }

    @DetectMethodChanges()
    onChange() {
    }

    //

    @DetectMethodChanges({isAsync: true})
    async onOptionStrategyApplied(): Promise<void> {

        this.clearLegs('main', true);

        if (!this.orderModel.selectedStrategy) {
            return;
        }

        if (this.orderModel.expirationsList.length === 0) {
            this._toastr.error('No strikes and/or expirations available');
            return;
        }

        if (!this.orderModel.tradingInstrument) {
            this._toastr.error('Trading Instrument Not Selected');
            return;
        }

        this.isLoading = true;

        try {

            await this.applyOptionStrategy();

        } finally {

            this.isLoading = false;
        }
    }

    //

    showFutureTimeSettingsDialog() {
        this.futureOrderDialog.show(this.futureOrderSettings, this.convertToMarketSettings);
    }

    //

    private async applyOptionStrategy() {

        this.saveOptionStrategySettings();

        await this.setupPricingModel();

        this.orderModel.legs.forEach(leg => {

            if (leg.legType === LegType.Asset) {

                leg.ticker = this.orderModel.tradingInstrument.ticker;
                leg.displayName = this.orderModel.tradingInstrument.displayName;

            } else if (leg.legType === LegType.Option) {

                const exp = leg.expiration ? leg.expiration : {} as any;

                const optionDisplayName = makeOptionDisplayName(exp, leg.optionType, leg.strike, this.orderModel.expirationStyle);
                const optionTicker = makeOptionTicker(exp, leg.optionType, leg.strike, this.orderModel.expirationStyle);

                leg.ticker = optionTicker;
                leg.displayName = optionDisplayName;

            }

            this._lastQuoteCache.subscribeTicker(leg.ticker);

        });
    }

    //

    private getAvailableTerminals(): TerminalDto[] {
        return this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy);
    }

    //

    @DetectMethodChanges()
    private updateTotalPrice(order: MultiLegOrder) {
        this.updateLegTotals(order);
        this.updateRollingLegTotals(order);
    }

    //

    getGroupTotals(groupLabel: string) {
        const totals = this._groupTotals[groupLabel] || {};
        return totals;
    }

    @DetectMethodChanges()
    private updateLegTotals(order: MultiLegOrder) {

        if (order.legs.length === 0) {
            order.totalAsk = order.totalBid = order.totalMid = null;
            return;
        }

        const prices = this.calculateLegTotals(order.legs);

        const isMultiLegOrder = this.isRollMode || order.legs.length > 1;

        // if (isMultiLegOrder) {
            order.totalAsk = prices.askTotal * -1;
            order.totalBid = prices.bidTotal * -1;
            order.totalMid = prices.midTotal * -1;
        // } else {
        //     order.totalAsk = Math.abs(prices.askTotal);
        //     order.totalBid = Math.abs(prices.bidTotal);
        //     order.totalMid = Math.abs(prices.midTotal);
        // }

        order.getLegsByGroup().forEach(grp => {
            const grpPrices = this.calculateLegTotals(grp.legs);

            grpPrices.bidTotal *= -1;
            grpPrices.midTotal *= -1;
            grpPrices.askTotal *= -1;

            this._groupTotals[grp.groupLabel] = grpPrices;
        });
    }

    //

    @DetectMethodChanges()
    private updateRollingLegTotals(order: MultiLegOrder) {

        if (order.rollingLegs.length === 0) {
            order.rollingTotalAsk = order.rollingTotalBid = order.rollingTotalMid = null;
            return;
        }

        const prices = this.calculateLegTotals(order.rollingLegs);
        order.rollingTotalAsk = prices.askTotal * -1;
        order.rollingTotalBid = prices.bidTotal * -1;
        order.rollingTotalMid = prices.midTotal * -1;

        const totalLegs = order.legs.concat(order.rollingLegs);
        const totalPrices = this.calculateLegTotals(totalLegs);
        order.grandTotalAsk = totalPrices.askTotal * -1;
        order.grandTotalBid = totalPrices.bidTotal * -1;
        order.grandTotalMid = totalPrices.midTotal * -1;
    }


    private calculateLegTotals(legs: OrderLeg[]): { bidTotal: number, askTotal: number, midTotal: number } {
        const qties = legs.map(o => o.qty || 1);

        const hcf = findHCF(qties);

        // calculate ASK
        const askPrices = legs.map(leg => {
            let price = 0;

            if (leg.action === ActionType.Buy) {

                const ask = (this.comp.isEmulationMode ? leg.execPrice : leg.ask) || 0;
                const qty = leg.qty || 0;
                price = ask * qty / hcf;

            } else if (leg.action === ActionType.Sell) {

                const bid = (this.comp.isEmulationMode ? leg.execPrice : leg.bid) || 0;
                const qty = leg.qty || 0;
                price = (bid * qty / hcf) * -1;

            }

            return price;
        });

        // calculate BID
        const bidPrices = legs.map(leg => {
            let price = 0;

            if (leg.action === ActionType.Buy) {
                const bid = (this.comp.isEmulationMode ? leg.execPrice : leg.bid) || 0;
                const qty = leg.qty || 0;
                price = bid * qty / hcf;

            } else if (leg.action === ActionType.Sell) {

                const ask = (this.comp.isEmulationMode ? leg.execPrice : leg.ask) || 0;
                const qty = leg.qty || 0;
                price = (ask * qty / hcf) * -1;

            }

            return price;
        });

        const bidTotal = bidPrices.reduce((a, b) => a + b, 0);
        const askTotal = askPrices.reduce((a, b) => a + b, 0);
        const midTotal = ((askTotal + bidTotal) / 2);

        return {bidTotal, askTotal, midTotal};
    }

    //

    @DetectMethodChanges()
    copyLegsToClipboard() {
        const om = this.orderModel;

        if (!om) {
            return;
        }

        const items = om.legs.map(leg => {
            const expStyle = isCashSettledOptionTicker(leg.ticker) ? 'European' : 'American';
            const i: ComboHighlightedItem = {
                underlying: leg.legType === LegType.Asset ? leg.ticker : leg.expiration.underlyingTicker,
                ticker: leg.ticker,
                tickerDisplayName: makeOptionDisplayName(leg.expiration, leg.optionType, leg.strike, expStyle),
                side: (leg.action as number) as MarketSide,
                itemType: leg.optionType === OptionType.Call ? PortfolioItemType.Call : PortfolioItemType.Put,
                strategyId: EtsConstants.strategies.manualStrategyId,
                netPosition: leg.qty
            };

            return i;
        });

        const msg: ComboHighlightedUIMessage = {
            spec: {
                itemId: `${EtsConstants.strategies.manualStrategyId}|${om.tradingInstrument.ticker}`
            },
            items,
            orderParams: {}
        };

        this._clipboardService.put('combo', msg);
        this._toastr.success('Combo copied to clipboard');

    }

    //

    @DetectMethodChanges({isAsync: true})
    async pasteComboFromClipboard(): Promise<void> {

        const msg = this._clipboardService.get('combo') as ComboHighlightedUIMessage;

        if (msg) {

            await this.processComboHighlightedMessage(msg);

        } else {

            const mlOrderData = <MultiLegOrderDataUIMessage>this._clipboardService.get('multi-combo');

            if (mlOrderData) {
                this.linkedOrdersPasted.emit(mlOrderData);

            } else {

                this._toastr.warning('Clipboard is empty');

            }
        }

        this.orderModel.resetLegGroups();
    }


    //

    @DetectMethodChanges({isAsync: true})
    async processComboHighlightedMessage(msg: ComboHighlightedUIMessage): Promise<void> {

        const order = this.orderModel;

        if (!msg.items) {
            return;
        }

        const comboItems = msg.items
            .filter(x => x.itemType !== PortfolioItemType.Strategy);

        if (comboItems.length === 0) {
            return;
        }

        const firstItem = comboItems[0] as ComboHighlightedItem;
        const ul = firstItem.underlying;

        // load an instrument if not selected or changing
        if (!order.tradingInstrument || order.tradingInstrument.ticker !== ul) {

            if (order.tradingInstrument) {

                if (order.legs.length > 0) {
                    if (this.isCumulative) {
                        this._toastr.error('Cannot load bucket, because it has more than one underlying', 'Multi-Trade Pad');
                        return;
                    }
                }
            }

            const ti = this._tiService.getInstrumentByTicker(ul);

            if (!ti) {
                this._toastr.error('Unable to determine trading instrument');
                return;
            }

            await this.onSymbolSelected(ti);

            this.symbolPicker.selectedInstrument = ti;
        }


        const accountId = firstItem.accountId;

        const setAccount = this.comp.availableAccounts.find(acc => acc.accountId === accountId);

        if (setAccount) {
            const dest = order.getDestination();
            console.assert(!isUndefined(dest));
            dest.account = setAccount;
        }

        const terminalId = firstItem.terminalId;
        const portfolioId = firstItem.portfolioId;
        const comboId = firstItem.comboId;
        const comboGroupId = firstItem.comboGroupId;

        const bucketCtx: BucketContext = {};

        if (comboGroupId) {
            bucketCtx.bucketType = 'ComboGroup',
                bucketCtx.bucketId = comboGroupId;
        } else if (comboId) {
            bucketCtx.bucketType = 'Combo',
                bucketCtx.bucketId = comboId;
        } else if (portfolioId) {
            bucketCtx.bucketType = 'ComboGroup',
                bucketCtx.bucketId = portfolioId;
        } else if (terminalId) {
            bucketCtx.bucketType = 'Terminal',
                bucketCtx.bucketId = terminalId;
        }

        this.populateBucketDropboxes(bucketCtx);

        if (!this.isCumulative) {
            this.clearLegs('main');
        }

        comboItems.forEach(comboHlItem => {

            const existingIx = order.legs.findIndex(x => x.ticker === comboHlItem.ticker);

            if (existingIx >= 0) {
                return;
            }

            if (isNullOrUndefined(comboHlItem.ticker)) {
                return;
            }

            const dest = order.getDestination();

            console.assert(!isNullOrUndefined(dest));

            if (!isNullOrUndefined(dest.portfolio)) {
                const itemPortfolioSet = !isNullOrUndefined(comboHlItem.portfolioId);

                const portfoliosAreDifferent = (dest.portfolio.portfolioId !== comboHlItem.portfolioId);

                if (itemPortfolioSet && portfoliosAreDifferent) {
                    if (!this.isMultiBucket) {
                        this._toastr.error('Legs From Different Portfolios not Allowed');
                        return;
                    }
                }
            }

            if (!isNullOrUndefined(dest.combo)) {

                const itemComboSet = !isNullOrUndefined(comboHlItem.comboId);

                const combosAreDifferent = (dest.combo.comboId !== comboHlItem.comboId);

                if (itemComboSet && combosAreDifferent) {
                    if (!this.isMultiBucket) {
                        this._toastr.error('Legs From Different Combos not Allowed');
                        return;
                    }
                }

            }

            if (!isNullOrUndefined(dest.comboGroup)) {

                const itemComboGroupSet = !isNullOrUndefined(comboHlItem.comboGroupId);

                const comboGroupsAreDifferent = (dest.comboGroup.comboGroupId !== comboHlItem.comboGroupId);

                if (itemComboGroupSet && comboGroupsAreDifferent) {
                    if (!this.isMultiBucket) {
                        this._toastr.error('Legs From Different Combos not Allowed');
                        return;
                    }
                }

            }

            this._lastQuoteCache.subscribeTicker(comboHlItem.ticker);

            const leg = new OrderLeg(this.orderModel);
            leg.ticker = comboHlItem.ticker;
            leg.displayName = comboHlItem.tickerDisplayName;
            leg.legType = comboHlItem.ticker.startsWith('@') ? LegType.Option : LegType.Asset;
            leg.qty = Math.abs(comboHlItem.netPosition || 0);

            leg.portfoliosList = dest.portfoliosList;
            leg.comboList = dest.comboList;
            leg.comboGroupList = dest.comboGroupList;

            if (comboHlItem.side) {
                leg.action = comboHlItem.side === MarketSide.Buy ? ActionType.Buy : ActionType.Sell;
            } else {
                leg.action = comboHlItem.netPosition > 0 ? ActionType.Sell : ActionType.Buy;
            }

            if (this.isMultiBucket) {
                if (comboHlItem.terminalId) {
                    const terminalDto = this.getAvailableTerminals().find(x => x.terminalId === comboHlItem.terminalId);
                    leg.terminal = terminalDto;
                }

                if (comboHlItem.portfolioId) {
                    const portfolioDto = leg.portfoliosList.find(x => x.portfolioId === comboHlItem.portfolioId);
                    leg.portfolio = portfolioDto;
                }

                if (comboHlItem.comboId) {
                    const combo = leg.comboList.find(x => x.comboId === comboHlItem.comboId);
                    leg.combo = combo;
                }

                if (comboHlItem.comboGroupId) {
                    const comboGroupDto = leg.comboGroupList.find(x => x.comboGroupId === comboHlItem.comboGroupId);
                    leg.comboGroup = comboGroupDto;
                }
            }

            if (leg.ticker.startsWith('@')) {

                // @ES ESH1 Call 3365 2020-12-25
                const parts = comboHlItem.ticker.split(' ');

                const expiration = parts[4];

                const existingExpiration = order.expirationsList.find(x => x.optionExpirationDate === expiration);

                if (existingExpiration) {

                    leg.expiration = existingExpiration;

                } else {

                    const expirationTicker = parts[0];
                    const underlyingTicker = parts[1];
                    const underlyingSymbol = order.tradingInstrument.underlying;

                    const expDescriptor = makeOptionExpirationDescriptor(
                        expiration,
                        expirationTicker,
                        underlyingTicker,
                        underlyingSymbol,
                        order.expirationsList[0].strikes
                    );
                    order.expirationsList.unshift(expDescriptor);
                    leg.expiration = expDescriptor;
                }

                leg.strike = parseFloat(parts[3]);
                leg.optionType = parts[2] === 'Call' ? OptionType.Call : OptionType.Put;

                // set expiration style
                if (this.orderModel.canChangeExpirationStyle) {
                    const expStyle = isCashSettledOptionTicker(leg.ticker) ? 'European' : 'American';
                    this.orderModel.expirationStyle = expStyle;
                }
                leg.displayName = makeOptionDisplayName(leg.expiration, leg.optionType, leg.strike, this.orderModel.expirationStyle);
            }

            // set prices if there's a last quote in the cache, or we have to wait till the first quote arrives
            const lastQuote = this._lastQuoteCache.getLastQuote(comboHlItem.ticker);

            if (lastQuote) {
                leg.bid = lastQuote.bid;
                leg.ask = lastQuote.ask;
            }

            order.legs.push(leg);

            if (this.isRollMode) {
                const rollingLeg: OrderLeg = new OrderLeg(order);
                rollingLeg.copyFrom(leg);
                rollingLeg.changeSide();
                order.rollingLegs.push(rollingLeg);
            }
        });

        if (msg.orderParams) {

            if (!isNullOrUndefined(msg.orderParams.orderDuration)) {
                if (msg.orderParams.orderDuration === TimeInForce.Day) {
                    order.orderDuration = OrderDuration.DAY;
                } else if (msg.orderParams.orderDuration === TimeInForce.GTC) {
                    order.orderDuration = OrderDuration.GTC;
                }
            }

            if (!isNullOrUndefined(msg.orderParams.orderType)) {
                order.orderType = msg.orderParams.orderType as number;
            }

            if (!isNullOrUndefined(msg.orderParams.orderLimitPx)) {
                order.limitPrice = msg.orderParams.orderLimitPx;
            }

            if (!isNullOrUndefined(msg.orderParams.orderQty)) {
                if (this.isRollMode) {
                    order.rollingQty = msg.orderParams.orderQty;
                } else {
                    const dest = order.getDestination();
                    if (dest) {
                        dest.qty = msg.orderParams.orderQty;
                    }
                }
            }
        }

        if (msg.cashFlowSpecs) {
            this.cashFlowSpec = msg.cashFlowSpecs;
        }

        this.orderModel.resetLegGroups();
    }

    //

    async processBucketItemHighlightedMessage(msg: BucketItemHighlighted): Promise<void> {

        if (this.isCumulative) {
            if (this.orderModel.tradingInstrument) {
                if (this.orderModel.tradingInstrument.underlying !== msg.item.underlying) {
                    this._toastr.error('Cannot mix items with different underlyings');
                    return;
                }

                if (this.orderModel.legs.length > 0) {
                    let expStyle: 'European' | 'American';

                    if (isCashOnlySettledInstrument(msg.item.underlying)) {
                        expStyle = 'European';
                    } else {
                        expStyle = msg.item.ticker.endsWith(' $') ? 'European' : 'American';
                    }

                    if (this.orderModel.expirationStyle !== expStyle) {
                        this._toastr.error('Cannot mix items with different expiration styles');
                        return;
                    }
                }
            }
        }

        const item: ComboHighlightedItem = {
            itemType: msg.item.itemType,
            ticker: msg.item.ticker,
            tickerDisplayName: msg.item.tickerDisplayName,
            underlying: msg.item.underlying,
            accountId: msg.item.accountId,
            netPosition: msg.item.netPosition,
            portfolioId: msg.item.portfolioId,
            comboId: msg.item.comboId,
            comboGroupId: msg.item.comboGroupId,
            terminalId: msg.item.terminalId
        } as ComboHighlightedItem;

        const message: ComboHighlightedUIMessage = {
            items: [item]
        };

        await this.processComboHighlightedMessage(message);
    }

    //

    async processBucketHighlightedMessage(x: BucketHighlighted): Promise<void> {

        this.populateBucketDropboxes(x.bucketContext);

        if (x.bucketContext.bucketType !== 'ComboGroup') {
            return;
        }

        this.isLoading = true;

        try {

            const bucketId = x.bucketContext.bucketId;

            const qry = new GetBucketItems(x.bucketContext.bucketType, bucketId, true, true, true);
            const dtos = await this._shellClient.processQuery<PortfolioItemDto[]>(qry);

            const groupByUnderlying = Enumerable.from(dtos).groupBy(dto => dto.underlying);

            if (groupByUnderlying.count() > 1) {
                this._toastr.error('Cannot load bucket, because it has more than one underlying', 'Multi-Trade Pad');
                return;
            }

            const groupByExpirationStyle = Enumerable.from(dtos).groupBy(dto => dto.ticker.endsWith(' $'));

            if (groupByExpirationStyle.count() > 1) {
                this._toastr.error('Cannot load bucket, because it has more than one expiration style legs', 'Multi-Trade Pad');
                return;
            }

            const msg: ComboHighlightedUIMessage = {
                items: dtos.filter(dto => dto.netPosition !== 0).map(dto => {
                    return {
                        itemType: dto.itemType,
                        ticker: dto.ticker,
                        tickerDisplayName: dto.tickerDisplayName,
                        underlying: dto.underlying,
                        accountId: dto.accountId,
                        netPosition: dto.netPosition,
                        portfolioId: dto.portfolioId,
                        comboId: dto.comboId,
                        comboGroupId: dto.comboGroupId
                    } as ComboHighlightedItem;
                })
            };

            await this.processComboHighlightedMessage(msg);

        } finally {

            this.isLoading = false;
        }
    }

    //

    @DetectMethodChanges()
    private onPortfolioCreatedMessage(dto: PortfolioCreatedDto): void {
        this.orderModel.addBucket('Portfolio', dto.portfolio);
    }

    //

    @DetectMethodChanges()
    private onComboGroupCreatedMessage(msg: ComboGroupCreatedDto): void {
        this.orderModel.addBucket('ComboGroup', msg.comboGroup);
    }

    //

    @DetectMethodChanges()
    private onComboCreatedMessage(msg: ComboCreatedDto): void {
        this.orderModel.addBucket('Combo', msg.combo);
    }

    //

    @DetectMethodChanges()
    private onGroupDeletedMessage(dto: GroupDeletedDto): void {

        switch (dto.groupType) {

            case 'combo': {
                this.orderModel.removeBucket('Combo', dto.comboId);
            }
                break;

            case 'group': {
                this.orderModel.removeBucket('ComboGroup', dto.comboGroupId);
            }
                break;


            case 'portfolio': {
                this.orderModel.removeBucket('Portfolio', dto.portfolioId);
            }
                break;


            default:
                break;
        }

    }

    //

    private populateBucketDropboxes(bucketCtx: BucketContext): void {

        if (isNullOrUndefined(bucketCtx) || isNullOrUndefined(bucketCtx.bucketId)) {
            console.error('ml|order|cannot populate dropboxes for highlighted bucket: bucket is null');
            return;
        }

        if (!this.orderModel) {
            console.error('ml|order|cannot populate dropboxes for highlighted bucket: order model not set');
            return;
        }

        if (!this.isLinkedMode) {
            console.error('ml|order|cannot populate dropboxes for highlighted bucket: not linked mode');
            return;
        }

        const dest = this.orderModel.getDestination();

        console.assert(!isNullOrUndefined(dest));

        const bucketComboGroup = this.comp.availableComboGroups.find(cg => cg.comboGroupId === bucketCtx.bucketId);
        dest.comboGroup = bucketComboGroup;

        const comboId = bucketComboGroup ? bucketComboGroup.comboId : bucketCtx.bucketId;
        const bucketCombo = this.comp.availableCombos.find(c => c.comboId === comboId);
        dest.combo = bucketCombo;

        const portfolioId = bucketCombo ? bucketCombo.portfolioId : bucketCtx.bucketId;
        const bucketPortfolio = this.comp.availablePortfolios.find(p => p.portfolioId === portfolioId);
        dest.portfolio = bucketPortfolio;

        const terminalId = bucketPortfolio ? bucketPortfolio.terminalId : bucketCtx.bucketId;
        const bucketTerminal = this.comp.availableTerminals.find(t => t.terminalId === terminalId);
        dest.terminal = bucketTerminal;
    }

    //

    @DetectMethodChanges()
    clearLegs(section: 'main' | 'rolling', maintainStrategy?: boolean) {

        const order = this.orderModel;

        if (section === 'rolling') {
            if (order.isRolling) {
                if (order.rollingLegs) {
                    if (order.rollingLegs.length > 0) {
                        const tickers = order.getSubscribedTickersRollingLegs();
                        order.rollingLegs.length = 0;
                        order.rollingQty = 1;
                        this._lastQuoteCache.unsubscribeTickers(tickers);
                    }
                }
            }
        } else if (section === 'main') {

            const tickers = order.getSubscribedTickersLegs();
            this._lastQuoteCache.unsubscribeTickers(tickers);

            order.legs.length = 0;

            order.destinations.forEach(x => x.qty = 1);
        }

        order.limitPrice = null;

        if (!maintainStrategy && section !== 'rolling') {
            order.selectedStrategy = null;
        }

        this.updateTotalPrice(order);
        this.orderModel.resetLegGroups();
    }

    //

    private makeEmptyOrderInstance(sourceOrder?: MultiLegOrder): MultiLegOrder {
        const order = new MultiLegOrder(this.comp, sourceOrder);

        order.destinationAdded
            .pipe(takeUntil(this._unsubscriber))
            .subscribe((dest: OrderDestination) => {
                this.destinationAdded.emit(dest);
            });

        order.destinationRemoved
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => {
                this.destinationRemoved.emit(x);
            });

        order.destinationPressed
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => {
                this.destinationPressed.emit(x);
            });

        order.showBucketItems.pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.loadBucketReferenceData(x));

        return order;
    }

    @DetectMethodChanges()
    private onQuote(quotes: QuoteDto[]) {
        this.onQuoteProcessOrder(quotes);
    }

    //

    private onQuoteProcessOrder(quotes: QuoteDto[]) {

        if (!this.orderModel.tradingInstrument) {
            return;
        }

        quotes.forEach(quote => {

            const isOptionQuote = quote.ticker.startsWith('@');

            if (quote.ticker === this.orderModel.tradingInstrument.ticker) {
                this.orderModel.lastPx = quote.lastPx;
            }

            const legs = this.orderModel.legs.concat(this.orderModel.rollingLegs);

            legs.forEach(leg => {
                if (!isOptionQuote) {
                    if (leg.legType === LegType.Asset) {
                        if (tickersMatch(this.orderModel.tradingInstrument.ticker, quote.ticker)) {
                            leg.bid = quote.bid;
                            leg.ask = quote.ask;
                        }
                    }
                } else {
                    if (leg.legType === LegType.Option) {
                        if (tickersMatch(leg.ticker, quote.ticker)) {
                            leg.bid = quote.bid;
                            leg.ask = quote.ask;
                        }
                    }
                }
            });
        });
    }

    //

    validateOrder(): string[] {
        const order = this.orderModel;

        const errors: string[] = [];

        if (!order.tradingInstrument) {
            errors.push('Underlying not selected');
        }

        if (order.legs.length === 0) {
            errors.push('Order has no legs');
        }

        if (this.isRollMode) {
            if (order.rollingLegs.length === 0) {
                errors.push('Rolling Legs Not Provided');
            }

            if (order.rollingLegs.length !== order.legs.length) {
                errors.push('Number of Legs To Roll Don\'t Match Up');
            }
        }

        const dest = this.orderModel.getDestination();

        console.assert(!isNullOrUndefined(dest));

        if (!dest.account) {
            errors.push('Account not set');
        }

        if (!dest.terminal) {
            errors.push('Terminal not set');
        }

        if (isNullOrUndefined(order.orderType)) {
            if (!this.comp.isEmulationMode) {
                errors.push('Order Type Not Set');
            }
        }

        if (isNullOrUndefined(order.orderDuration)) {
            if (!this.comp.isEmulationMode) {
                errors.push('Order Duration Not Set');
            }
        }

        if (!order.limitPrice) {
            if (!this.comp.isEmulationMode) {
                if (order.orderType === OrderType.Limit) {
                    errors.push('Limit price not set');
                }
            }
        } else {

            const tickPattern = Math.abs(order.limitPrice / order.tradingInstrument.tickSize);

            const fitsPattern = (tickPattern - Math.round(tickPattern)) <= 0.0000001;

            if (!fitsPattern) {
                errors.push('Limit Price does not match trading instrument\'s price pattern');
            }

            const mainLegs = order.legs.map(l => ({
                bid: l.bid,
                ask: l.ask,
                type: l.legType,
                qty: l.qty,
                action: l.action
            }));
            let rollingLegs = [];
            if (this.isRollMode && order.rollingLegs.length > 0) {
                rollingLegs = order.rollingLegs.map(l => ({
                    bid: l.bid,
                    ask: l.ask,
                    type: l.legType,
                    qty: l.qty,
                    action: l.action
                }));
            }

            const totalLegs = mainLegs.concat(rollingLegs);

            const values = totalLegs.map(leg => {
                const mid = leg.type === LegType.Asset ? (leg.bid / 100 + leg.ask / 100) / 2 : (leg.bid + leg.ask) / 2;
                const val = mid * leg.qty * leg.action;
                return val;
            });
            const sum = values.reduce((p, c) => p + c, 0);

            if (totalLegs.length > 1) {
                if (Math.sign(sum) === Math.sign(order.limitPrice)) {
                    if (Math.sign(order.limitPrice) === -1) {
                        errors.push('Credit trade cannot have debit price');
                    } else {
                        errors.push('Debit trade cannot have credit price');
                    }
                }
            }
        }

        const legsHaveErrors = order.legs.some(leg => !this.validateLeg(leg));

        if (legsHaveErrors) {
            errors.push('Correct Entered Legs Data');
        }

        if (this.isRollMode) {
            const rollingLegsHaveErrors = order.rollingLegs.some(leg => !this.validateLeg(leg));

            if (rollingLegsHaveErrors) {
                errors.push('Correct Entered Rolling Legs Data');
            }
        }

        return errors;
    }

    //

    private validateLeg(leg: OrderLeg): boolean {

        if (leg.legType === LegType.Option) {

            const actionValid = !isNullOrUndefined(leg.action);

            const qtyValid = !isNullOrUndefined(leg.qty) && leg.qty > 0;

            const tickerValid = !isNullOrUndefined(leg.ticker);

            let legValid = actionValid && qtyValid && tickerValid;

            if (this.comp.isEmulationMode) {

                const execPriceValid = !isNullOrUndefined(leg.execPrice) && !isNaN(leg.execPrice);
                const execDateValid = !isNaN(Date.parse(leg.execDate));

                legValid = legValid && execPriceValid && execDateValid;

            } else {

                let priceValid = false;

                if (leg.qty > 0) {
                    priceValid = !isNullOrUndefined(leg.ask) && leg.ask !== 0;
                } else if (leg.qty) {
                    priceValid = !isNullOrUndefined(leg.bid) && leg.bid !== 0;
                }

                // legValid = legValid && priceValid;
                legValid = legValid && true;

            }

            return legValid;

        } else if (leg.legType === LegType.Asset) {

            const actionValid = !isNullOrUndefined(leg.action);

            const qtyValid = !isNullOrUndefined(leg.qty) && leg.qty > 0;

            const tickerValid = !isNullOrUndefined(leg.ticker);

            let legValid = actionValid && qtyValid && tickerValid;

            if (this.comp.isEmulationMode) {

                const execPriceValid = !isNullOrUndefined(leg.execPrice) && !isNaN(leg.execPrice);

                legValid = legValid && execPriceValid;

            } else {

                let priceValid = false;

                if (leg.qty > 0) {
                    priceValid = !isNullOrUndefined(leg.ask) && leg.ask !== 0;
                } else if (leg.qty) {
                    priceValid = !isNullOrUndefined(leg.bid) && leg.bid !== 0;
                }

                legValid = legValid && priceValid;

            }

            return legValid;

        } else if (leg.legType === LegType.Separator) {
            return true;
        }

        return false;
    }

    //

    @DetectMethodChanges()
    addEmptyLegToOrder(legType: LegType): void {

        const order = this.orderModel;

        const leg = new OrderLeg(this.orderModel);
        leg.legType = legType;
        leg.isCustomExpiration = false;

        if (legType === LegType.Asset) {
            leg.qty = 100;

            if (order.tradingInstrument) {
                if (order.tradingInstrument.kind !== TradingInstrumentKind.Stock) {
                    leg.qty = 1;
                }
                leg.ticker = order.tradingInstrument.ticker;
                leg.displayName = order.tradingInstrument.displayName;
            }

            if (this.isRollMode) {

                order.rollingLegs.unshift(leg);

            } else {

                order.legs.unshift(leg);
            }

            if (order.legs.length === 1) {
                this.orderModel.expirationStyle = 'American';
            }

        } else if (legType === LegType.Option) {

            if (order.hasUnderlyingLeg) {
                if (order.tradingInstrument.kind !== TradingInstrumentKind.Stock) {
                    this._toastr.error('Multi-leg orders only available for stocks');
                    return;
                }
            }

            leg.qty = 1;

            if (this.isRollMode) {

                order.rollingLegs.push(leg);

            } else {

                order.legs.push(leg);
            }

        } else {
            this._toastr.error('Not supported leg type', 'Multi-Trade Pad');
            console.error('Unknown leg type');
            return;
        }

        if (this.isMultiBucket) {
            const dest = this.orderModel.getDestination();

            if (dest.terminal) {

                const terminalDto = this.getAvailableTerminals().find(t => t.terminalId === dest.terminal.terminalId);

                if (!isNullOrUndefined(terminalDto)) {
                    leg.terminal = terminalDto;
                }
            }
        }

        if (!isNullOrUndefined(leg.ticker)) {

            const lq = this._lastQuoteCache.getLastQuote(leg.ticker);

            if (!isNullOrUndefined(lq)) {
                this.onQuote([lq]);
            }
        }

        this.updateTotalPrice(order);
        this.orderModel.resetLegGroups();
    }

    //

    @DetectMethodChanges()
    onOrderTypeChanged() {
        const order = this.orderModel;

        if (order.orderType !== OrderType.Limit) {
            order.limitPrice = undefined;
        }

        if (order.orderType !== OrderType.AutoLimit) {
            order.autoLimitPrice = undefined;
        }

    }

    //

    @DetectMethodChanges()
    removeLeg(order: MultiLegOrder, leg: OrderLeg) {
        const ix = order.legs.indexOf(leg);

        if (ix >= 0) {
            const removed = order.legs.splice(ix, 1);
            if (removed.length > 0) {
                if (removed[0].legType !== LegType.Asset) {
                    const ticker = removed[0].ticker;
                    this._lastQuoteCache.unsubscribeTicker(ticker);
                }
            }

            if (order.legs.length === 0) {
                order.totalAsk = order.totalBid = order.totalMid = null;
                order.limitPrice = null;
                order.selectedStrategy = null;
            }

        } else {
            if (order.rollingLegs.length > 0) {
                const rix = order.rollingLegs.indexOf(leg);

                if (rix >= 0) {
                    const rollingRemoved = order.rollingLegs.splice(rix, 1);
                    if (rollingRemoved.length > 0) {
                        const ticker = rollingRemoved[0].ticker;
                        this._lastQuoteCache.unsubscribeTicker(ticker);
                    }
                }

                if (order.rollingLegs.length === 0) {
                    order.rollingTotalAsk = order.rollingTotalBid = order.rollingTotalMid = null;
                    order.limitPrice = null;
                    order.selectedStrategy = null;
                }
            }
        }

        if (this.isRollMode) {
            this.updateRollingLegTotals(order);
        }

        this.updateTotalPrice(order);
        this.orderModel.resetLegGroups();

    }

    //

    @DetectMethodChanges()
    onLegQuoteParameterChanged(ev: {
        value,
        previousValue
    }, parameter: 'expiration' | 'strike' | 'type' | 'atmStrike', leg: OrderLeg, order: MultiLegOrder) {

        // tslint:disable-next-line: no-string-literal

        let oldExpiration: OptionExpirationDescriptor;
        let oldType;
        let oldStrike;

        let newExpiration: OptionExpirationDescriptor;
        let newStrike;
        let newType;

        if (parameter === 'expiration') {
            if (ev.value) {
                newExpiration = ev.value;
            } else {
                newExpiration = null;
            }

            if (leg.isCustomExpiration) {
                newExpiration = ev.value;
            } else {
                newExpiration = leg.expiration;
            }

            if (!newExpiration) {
                return;
            }

            if (ev.previousValue) {
                oldExpiration = ev.previousValue;
            } else {
                oldExpiration = null;
            }

            oldStrike = newStrike = leg.strike;
            oldType = newType = leg.optionType;

        } else if (parameter === 'strike') {

            newStrike = ev.value;

            oldStrike = ev.previousValue;
            oldType = newType = leg.optionType;

            if (leg.expiration) {
                oldExpiration = newExpiration = leg.expiration;
            } else {
                oldExpiration = newExpiration = null;
            }

            this.orderModel.resetLegGroups();

        } else if (parameter === 'atmStrike') {

            newStrike = ev.value;

            oldStrike = ev.previousValue;
            oldType = newType = leg.optionType;

            if (leg.expiration) {
                oldExpiration = newExpiration = leg.expiration;
            } else {
                oldExpiration = newExpiration = null;
            }

        } else if (parameter === 'type') {
            newType = leg.optionType;

            if (isNullOrUndefined(newType)) {
                return;
            }

            oldType = ev.previousValue;
            oldStrike = newStrike = leg.strike;

            if (leg.expiration) {
                oldExpiration = newExpiration = leg.expiration;
            } else {
                oldExpiration = newExpiration = null;
            }

        } else {
            this._toastr.error('Parameters does not affect instrument');
        }

        const oldTicker = makeOptionTicker(oldExpiration, oldType, oldStrike, this.orderModel.expirationStyle);
        const newTicker = makeOptionTicker(newExpiration, newType, newStrike, this.orderModel.expirationStyle);

        leg.bid = leg.ask = null;

        if (newTicker) {
            leg.ticker = newTicker;
            leg.displayName = makeOptionDisplayName(newExpiration, newType, newStrike, this.orderModel.expirationStyle);

            if (!this.comp.isEmulationMode) {
                this._lastQuoteCache.subscribeTicker(newTicker);
            }
        } else {
            leg.ticker = undefined;
            leg.bid = leg.ask = undefined;
        }

        if (oldTicker) {
            this._lastQuoteCache.unsubscribeTicker(oldTicker);
        }

        // check if expirations are linked and you need to change them all
        if (parameter === 'expiration') {

            const legIx = order.legs.indexOf(leg);
            const rollingIx = order.rollingLegs.indexOf(leg);

            if (legIx >= 0 && order.linkExpirations) {
                const optionLegs = order.legs.filter(x => x.legType === LegType.Option);

                if (optionLegs.length === 0) {
                    return;
                }

                if (leg.isCustomExpiration) {

                    optionLegs.filter(l => l !== leg)
                        .filter(l => l.isCustomExpiration)
                        .forEach(v => {
                            v.expiration = newExpiration;
                        });

                } else {

                    const exp = order.expirationsList.find(x => x.optionExpirationDate === newExpiration.optionExpirationDate);

                    optionLegs.filter(l => l !== leg).forEach((v, ix) => {
                        v.expiration = exp;
                    });

                }

            }

            if (rollingIx >= 0 && order.linkRollingExpirations) {
                const optionLegs = order.rollingLegs.filter(x => x.legType === LegType.Option);

                if (optionLegs.length === 0) {
                    return;
                }

                const exp = order.expirationsList.find(x => x.optionExpirationDate === newExpiration.optionExpirationDate);

                optionLegs.filter(l => l !== leg).forEach((v, ix) => {
                    v.expiration = exp;
                });

            }

        }


        //
        if (parameter === 'strike') {
            if (this.orderModel.selectedStrategy === 'BOS') {
                this.recalculateBosStrategy();
            } else if (this.orderModel.selectedStrategy === 'Sponsored Long') {
                this.recalculateSponsoredLongStrategy();
            }

        }
    }

    private recalculateBosStrategy() {
        const legs = Enumerable.from(this.orderModel.legs);

        const optionType = legs.first().optionType;

        if (optionType === OptionType.Call || optionType === OptionType.Put) {
            let debitSpread: Enumerable.IEnumerable<OrderLeg>;
            let creditSpread: Enumerable.IEnumerable<OrderLeg>;

            if (optionType === OptionType.Call) {
                debitSpread = legs.take(2);
                creditSpread = legs.skip(2).take(2);
            } else if (optionType === OptionType.Put) {
                debitSpread = legs.reverse().take(2);
                creditSpread = legs.reverse().skip(2).take(2);
            }

            const debitSpreadWidth = debitSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);


            const creditSpreadWidth = creditSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);

            const creditSpreadQty = Math.abs(debitSpreadWidth) / Math.abs(creditSpreadWidth);

            if (isValidNumber(creditSpreadQty)) {
                creditSpread.forEach(x => x.qty = creditSpreadQty);
            }
        }
    }

    private recalculateSponsoredLongStrategy() {
        const legs = Enumerable.from(this.orderModel.legs);

        const optionType = legs.first().optionType;

        if (optionType === OptionType.Call || optionType === OptionType.Put) {
            let debitSpread: Enumerable.IEnumerable<OrderLeg>;
            let creditSpread: Enumerable.IEnumerable<OrderLeg>;

            if (optionType === OptionType.Call) {
                debitSpread = legs.take(2);
                creditSpread = legs.skip(1).take(2);
            } else if (optionType === OptionType.Put) {
                debitSpread = legs.reverse().take(2);
                creditSpread = legs.reverse().skip(1).take(2);
            }

            const debitSpreadWidth = debitSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);


            const creditSpreadWidth = creditSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);

            const creditSpreadQty = Math.abs(debitSpreadWidth) / Math.abs(creditSpreadWidth);

            if (isValidNumber(creditSpreadQty)) {
                creditSpread.forEach(x => x.qty = creditSpreadQty);
            }
        }
    }

//

    onExpirationStyleChanged() {
        const expStyle = this.orderModel.expirationStyle;

        if (!this.orderModel.tradingInstrument) {
            return;
        }

        if (this.orderModel.tradingInstrument.kind !== TradingInstrumentKind.Stock
            && this.orderModel.tradingInstrument.kind !== TradingInstrumentKind.Index) {
            return;
        }

        this.orderModel.legs.forEach(leg => {
            if (!isNullOrUndefined(leg.ticker)) {

                if (!leg.ticker.startsWith('@')) {
                    return;
                }

                if (isCashOnlySettledInstrument(this.orderModel.tradingInstrument.underlying)) {
                    if (!isCashSettledOptionTicker(leg.ticker)) {
                        leg.ticker = convertCashSettledTickerToNormal(leg.ticker);
                    }
                    return;
                }

                if (expStyle === 'American') {
                    if (isCashSettledOptionTicker(leg.ticker)) {
                        leg.ticker = convertCashSettledTickerToNormal(leg.ticker);
                    }
                } else if (expStyle === 'European') {
                    if (!isCashSettledOptionTicker(leg.ticker)) {
                        leg.ticker = convertTickerToCashSettled(leg.ticker);
                    }
                }

            }

            if (!isNullOrUndefined(leg.displayName)) {

                if (!leg.ticker.startsWith('@')) {
                    return;
                }

                if (expStyle === 'American') {
                    if (leg.displayName.indexOf(' (E)') > 0) {
                        leg.displayName = leg.displayName.replace(' (E)', '');
                    }
                } else if (expStyle === 'European') {
                    if (leg.displayName.indexOf(' (E)') < 0) {
                        leg.displayName = leg.displayName + ' (E)';
                    }
                }
            }

        });
    }

    //

    @DetectMethodChanges()
    onCustomExpirationDateSelected(ev: {
        value: string,
        previousValue: string,
        event: any
    }, order: MultiLegOrder, leg: OrderLeg) {

        const date = ev.value;
        const expirationTicker = '@' + order.tradingInstrument.ticker;
        const underlyingTicker = order.tradingInstrument.ticker;
        const underlyingSymbol = order.tradingInstrument.underlying;

        const stirkes = order.expirationsList[0].strikes;

        const descriptor = makeOptionExpirationDescriptor(
            date,
            expirationTicker,
            underlyingTicker,
            underlyingSymbol,
            stirkes
        );

        leg.expiration = descriptor;

        if (order.linkExpirations) {
            order.legs.filter(x => x.isCustomExpiration && x !== leg).forEach(x => {
                x.expiration = descriptor;
                this.onLegQuoteParameterChanged({value: descriptor, previousValue: null}, 'expiration', x, order);
            });
        }

        this.onLegQuoteParameterChanged({value: descriptor, previousValue: null}, 'expiration', leg, order);
    }

    //

    resetAttachment() {
        this.attachment = null;
        this.orderModel.legs.forEach(x => x.attachTo = false);
    }

    //
    resetCashFlowSpec() {
        this.cashFlowSpec = undefined;
    }

    //

    getOrderDto(): MultiLegOrderDto[] {

        const dtos = this.orderModel.destinations.map(dest => {
            const orderDto: MultiLegOrderDto = {
                limitPrice: this.orderModel.limitPrice * -1,
                orderType: this.orderModel.orderType,
                underlying: this.orderModel.tradingInstrument.ticker,
                legs: this.orderModel.legs
                    .filter(x => x.legType !== LegType.Separator)
                    .map(convertLegToDto),
                tif: this.orderModel.orderDuration as number,
                isEmulatorTrade: this.comp.isEmulationMode,
                isMultiBucket: this.isMultiBucket,
                accountId: dest.account.accountId,
                portfolioId: dest.portfolio ? dest.portfolio.portfolioId : null,
                portfolioName: dest.portfolio ? dest.portfolio.portfolioName : null,
                terminalId: dest.terminal.terminalId,
                comboName: dest.combo ? dest.combo.comboName : null,
                comboId: dest.combo ? dest.combo.comboId : null,
                comboGroupId: dest.comboGroup ? dest.comboGroup.comboGroupId : null,
                comboGroupName: dest.comboGroup ? dest.comboGroup.comboGroupName : null,
                qty: dest.qty,
                autoLimitPrice: this.orderModel.autoLimitPrice
            };

            if (orderDto.orderType === OrderType.AutoLimit) {
                orderDto.orderType = OrderType.Limit;
            }

            const mainLegsHCF = findHCF(orderDto.legs.map(tl => Math.abs(tl.qty)));
            orderDto.legs.forEach(l => l.qty /= mainLegsHCF);
            orderDto.qty *= mainLegsHCF;

            if (this.isRollMode && !this.comp.isEmulationMode) {
                if (this.orderModel.rollingLegs && this.orderModel.rollingLegs.length > 0) {
                    orderDto.legs.forEach(x => x.qty *= orderDto.qty);

                    const rollingLegs = this.orderModel.rollingLegs.map(convertLegToDto);
                    rollingLegs.forEach(l => l.qty *= this.orderModel.rollingQty);

                    const totalLegs = orderDto.legs.concat(rollingLegs);

                    const totalLegsHCF = findHCF(totalLegs.map(tl => Math.abs(tl.qty)));
                    totalLegs.forEach(tl => tl.qty /= totalLegsHCF);

                    orderDto.legs = totalLegs;
                    orderDto.qty = totalLegsHCF;
                }
            }

            return orderDto;
        });

        return dtos;
    }

    //

    getVerificationModels(): any[] {
        const models = this.orderModel
            .destinations
            .map(dest => this.getViewModelForVerification(dest));
        return models;
    }

    //

    private getViewModelForVerification(dest: OrderDestination) {

        const mainLegs = this.orderModel
            .legs
            .filter(x => x.legType !== LegType.Separator)
            .slice();

        let rollingLegs = [];

        if (this.isRollMode) {
            if (this.orderModel.rollingLegs.length > 0) {
                rollingLegs = this.orderModel
                    .rollingLegs
                    .filter(x => x.legType !== LegType.Separator)
                    .slice();
            }
        }

        const mainLegModels = mainLegs.map(leg => {

            const legModel: OrderLegVerificationModel = {};
            legModel.symbol = leg.displayName;
            legModel.side = ActionType[leg.action];
            legModel.totalQty = (leg.qty * dest.qty) * leg.action;

            if (this.isMultiBucket) {

                legModel.terminal = leg.terminal?.displayName || '';
                legModel.portfolio = leg.portfolio?.portfolioName || '';
                legModel.combo = leg.combo?.comboName || '';
                legModel.comboGroup = leg.comboGroup?.comboGroupName || '';

            } else {

                legModel.terminal = this.getTerminalName(dest.terminal.terminalId);
                legModel.portfolio = dest.portfolio?.portfolioName || '';
                legModel.combo = dest.combo?.comboName || '';
                legModel.comboGroup = dest.comboGroup?.comboGroupName || '';
            }

            return legModel;
        });

        const rollingLegModels = rollingLegs.map(leg => {

            const legModel: OrderLegVerificationModel = {};
            legModel.symbol = leg.displayName;
            legModel.side = ActionType[leg.action];
            legModel.totalQty = (leg.qty * this.orderModel.rollingQty) * leg.action;

            if (this.isMultiBucket) {

                legModel.terminal = leg.terminal?.displayName || '';
                legModel.portfolio = leg.portfolio?.portfolioName || '';
                legModel.combo = leg.combo?.comboName || '';
                legModel.comboGroup = leg.comboGroup?.comboGroupName || '';

            } else {

                legModel.terminal = this.getTerminalName(dest.terminal.terminalId);
                legModel.portfolio = dest.portfolio?.portfolioName || '';
                legModel.combo = dest.combo?.comboName || '';
                legModel.comboGroup = dest.comboGroup?.comboGroupName || '';
            }

            return legModel;
        });

        const totalLegs = mainLegModels.concat(rollingLegModels);

        const hcf = findHCF(totalLegs.map(tl => Math.abs(tl.totalQty)));

        totalLegs.forEach(tl => tl.effectiveQty = tl.totalQty / hcf);

        const account = this.comp.availableAccounts
            .find(acc => acc.accountId === dest.account.accountId)?.accountCode || 'N/A';

        const terminal = this.comp.availableTerminals
            .find(term => term.terminalId === dest.terminal.terminalId)?.displayName || 'N/A';

        const duration = OrderDuration[this.orderModel.orderDuration];

        const type = OrderType[this.orderModel.orderType];

        const limitPx = this.orderModel.limitPrice && this.orderModel.orderType === OrderType.Limit
            ? this.orderModel.limitPrice + ''
            : '--';

        const autoLimitPx = this.orderModel.autoLimitPrice;

        const marketValue = (this.orderModel.marketValue * hcf) || undefined;

        const limitValue = (this.orderModel.limitValue * hcf) || undefined;

        let root: DestinationBucketDescriptor;

        if (this.isMultiBucket) {
            root = {};
            root.terminal = terminal;
            root.portfolio = dest.portfolio?.portfolioName;
            root.combo = dest.combo?.comboName;
            root.comboGroup = dest.comboGroup?.comboGroupName;
        }

        const model: MultiLegOrderVerificationModel = {
            root,
            orderName: this.orderRole,
            destinationName: dest.letter,
            legs: totalLegs,
            account,
            terminal,
            duration,
            type,
            limitPx,
            autoLimitPx,
            marketValue,
            limitValue,
            totalQty: hcf,
            hasAttachment: !isNullOrUndefined(this.attachment),
            hasFutureSettings: !isNullOrUndefined(this.futureOrderSettings),
            hasConvertSettings: !isNullOrUndefined(this.convertToMarketSettings),
        };

        return model;
    }


    @DetectMethodChanges()
    onRollModeChange(val: boolean) {
        if (!val) {
            this.orderModel.rollingLegs.length = 0;
        } else {
            if (this.orderModel.destinations.length > 1) {
                this._toastr.error('Roll mode is incompatible with multi-destination order', 'Multi-Leg Trade Pad');
                return;
            }
        }

        this.isRollMode = val;
    }


    private getTerminalName(terminalId: string): string {
        return this.comp.availableTerminals.find(x => x.terminalId === terminalId)?.displayName || '';
    }


    @DetectMethodChanges()
    recreateOrderModel(sourceOrder?: MultiLegOrder) {

        this.orderModel = this.makeEmptyOrderInstance();

        if (sourceOrder) {
            this.orderModel.copyDataFromOtherOrder(sourceOrder);
            this.onSymbolSelected(sourceOrder.tradingInstrument);
        }
    }

    showAttachStrategyDialog() {
        const validationErrors = this.validateOrder();

        if (validationErrors.length > 0) {
            validationErrors.forEach(x => this._toastr.error(x, this.orderRole));
            return;
        }

        const dialog = this.attachStrategyDialog;

        if (!dialog) {
            return;
        }

        if (this.attachment) {

            const fakeModel = new StrategyModel();
            fakeModel.algoId = this.attachment.algoId;
            fakeModel.displayName = this.attachment.displayName;
            fakeModel.parameters = fromKVPString(this.attachment.parameters);


            const config: AttachStrategyDialogConfig = {
                mode: 'update',
                ctx: 'attach',
                strategy: fakeModel,
                clearAttachmentCallback: () => {
                    this.attachment = null;
                    this.orderModel.legs.forEach(x => x.attachTo = false);
                }
            };

            const pfId = fakeModel.parameters['refportfolioid'];
            const cbId = fakeModel.parameters['refcomboid'];

            const targetBucket = {
                portfolioId: pfId,
                comboId: cbId
            };

            config.targetBucket = targetBucket;

            dialog.show(config);

        } else {

            const config: AttachStrategyDialogConfig = {
                mode: 'create',
                ctx: 'attach'
            };

            const dest = this.orderModel.getDestination();

            if (dest) {
                config.targetBucket = {
                    portfolioId: dest.portfolio ? dest.portfolio.portfolioId : null,
                    comboId: dest.combo ? dest.combo.comboId : null,
                };
            }

            dialog.show(config);

        }
    }

    onAttachmentCreated(cmd: CreateAdjustmentStrategy) {
        this.attachment = cmd;
    }


    onDestinationAccountChanged() {

        if (!this.orderModel) {
            return;
        }

        if (!this.orderModel.destinations) {
            return;
        }


        if (this.orderModel.tradingInstrument.kind !== TradingInstrumentKind.Stock
            && this.orderModel.tradingInstrument.kind !== TradingInstrumentKind.Index) {
            return;
        }

        const hasLiveAccount = this.orderModel.destinations.some(x => x.account && !x.account.isPaperTrading);

        let defaultExpStyle: 'American' | 'European' = 'American';

        if (this.orderModel.tradingInstrument.kind === TradingInstrumentKind.Index) {
            defaultExpStyle = 'European';
        }

        this.orderModel.expirationStyle = defaultExpStyle;

    }

    @DetectMethodChanges()
    resetFutureOrderSettings() {
        this.futureOrderDialog.reset();
    }

    private async loadBucketReferenceData(dest: OrderDestination) {

        if (isNullOrUndefined(dest)) {
            console.error('Cannot show empty destination');
            return;
        }

        let bucketType: BucketType;
        let bucketId: string;

        if (dest.comboGroup) {
            bucketType = 'ComboGroup';
            bucketId = dest.comboGroup.comboGroupId;
        } else if (dest.combo) {
            bucketType = 'Combo';
            bucketId = dest.combo.comboId;
        } else if (dest.portfolio) {
            bucketType = 'Portfolio';
            bucketId = dest.portfolio.portfolioId;
        } else if (dest.terminal) {
            bucketType = 'Terminal';
            bucketId = dest.terminal.terminalId;
        }

        if (isNullOrUndefined(bucketType) || isNullOrUndefined(bucketId)) {
            this._toastr.error('Cannot load bucket items');
            return;
        }

        try {
            dest.referenceGrid.api.showLoadingOverlay();
            const qry = new GetBucketItems(bucketType, bucketId);
            let items = await this._shellClient.processQuery<PortfolioItemDto[]>(qry);
            items = items.filter(x => !x.isArchived);
            dest.referenceGrid.api.setRowData(items);

        } finally {
            dest.referenceGrid.api.hideOverlay();
        }

    }

    getBucketReferenceGridOptions(dest: OrderDestination): GridOptions {
        return getBucketReferenceGridOptions.bind(this)(dest);
    }

    async setupPricingModel() {

        const order = this.orderModel;

        if (isNullOrUndefined(order)) {
            return;
        }

        const strategy = order.selectedStrategy;

        if (isNullOrUndefined(strategy)) {
            return;
        }

        const underlying = order.tradingInstrument;

        const nearestExp = this.selectedExpiration || order.expirationsList[0];

        const lastQuote = await this._lastQuoteCache
            .getLastQuoteWithAwait(order.tradingInstrument.ticker);

        const centerIx = findAtmStrikeIndex(nearestExp.strikes, lastQuote);

        const legs: OrderLeg[] = [];

        const atmStrike = this.strikeOffsetMode === 'Offset'
            ? nearestExp.strikes[centerIx]
            : this.baseStrike;

        const offset = this.strikeOffsetMode === 'Offset' ? (this.strikeOffset || 0) : 0;


        function pickStrike(distance: number) {

            if (distance === 0) {
                return atmStrike;
            }

            let targetStrike = atmStrike + distance;

            return targetStrike;
        }

        if (strategy === 'BOS') {
            const width = this.strikeWidth;

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const multiplier = this.optionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const step = this.orderModel.tradingInstrument?.ticker === 'SPX' ? 5 : 1;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = firstStrike;
            leg1.optionType = oType;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.optionType = oType;

            const creditSpreadQty = width / step;

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Sell;
            leg3.qty = creditSpreadQty;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + step * multiplier;
            leg3.optionType = oType;

            const leg4 = new OrderLeg(order);
            leg4.legType = LegType.Option;
            leg4.action = ActionType.Buy;
            leg4.qty = creditSpreadQty;
            leg4.expiration = nearestExp;
            leg4.strike = leg3.strike + step * multiplier;
            leg4.optionType = oType;

            legs.push(leg1, leg2, leg3, leg4);

            if (this.optionType === 'Puts') {
                legs.reverse();
            }

        }
        else if (strategy === 'Butterfly') {

            const optionType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const multiplier = optionType === OptionType.Put ? -1 : 1;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.optionType = optionType;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.optionType = optionType;

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Buy;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.optionType = optionType;

            legs.push(leg1, leg2, leg3);

            if (optionType === OptionType.Call) {
                legs.reverse();
            }

        }
        else if (strategy === 'Butterfly - Double') {

            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const secondBfType = this.optionType !== 'Calls' ? OptionType.Put : OptionType.Call;

            let multiplier;
            if (firstBfType === secondBfType) {
                multiplier = firstBfType === OptionType.Put ? -1 : 1;
            } else {
                multiplier = 1;
            }

            // 1st butterfly
            const bf1GroupLabel = 'bf1';

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.optionType = firstBfType;
            leg1.groupLabel = bf1GroupLabel;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.optionType = firstBfType;
            leg2.groupLabel = bf1GroupLabel;

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Buy;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.optionType = firstBfType;
            leg3.groupLabel = bf1GroupLabel;


            let leg4, leg5, leg6: OrderLeg;

            // 2nd butterfly
            const bf2GroupLabel = 'bf2';

            if (firstBfType !== secondBfType) {
                multiplier *= -1;

                leg4 = new OrderLeg(order);
                leg4.legType = LegType.Option;
                leg4.action = ActionType.Buy;
                leg4.qty = 1;
                leg4.expiration = nearestExp;
                leg4.strike = atmStrike + offset * multiplier;
                leg4.optionType = secondBfType;
                leg4.groupLabel = bf2GroupLabel;

            } else {
                leg4 = new OrderLeg(order);
                leg4.legType = LegType.Option;
                leg4.action = ActionType.Buy;
                leg4.qty = 1;
                leg4.expiration = nearestExp;
                leg4.strike = leg3.strike;
                leg4.optionType = secondBfType;
                leg4.groupLabel = bf2GroupLabel;

            }

            leg5 = new OrderLeg(order);
            leg5.legType = LegType.Option;
            leg5.action = ActionType.Sell;
            leg5.qty = 2;
            leg5.expiration = nearestExp;
            leg5.strike = leg4.strike + this.strikeWidth * multiplier;
            leg5.optionType = secondBfType;
            leg5.groupLabel = bf2GroupLabel;

            leg6 = new OrderLeg(order);
            leg6.legType = LegType.Option;
            leg6.action = ActionType.Buy;
            leg6.qty = 1;
            leg6.expiration = nearestExp;
            leg6.strike = leg5.strike + this.strikeWidth * multiplier;
            leg6.optionType = secondBfType;
            leg6.groupLabel = bf2GroupLabel;

            const legSeparator = new OrderLeg(order);
            legSeparator.legType = LegType.Separator;
            legSeparator.bid = legSeparator.ask = 0;

            if (firstBfType === secondBfType) {
                legs.push(leg1, leg2, leg3, leg4, leg5, leg6);
                if (firstBfType === OptionType.Call) {
                    legs.reverse();
                }
            } else {
                legs.unshift(leg3, leg2, leg1);
                // legs.push(legSeparator);
                legs.push(leg4, leg5, leg6);
            }
        }
        else if (strategy === 'Butterfly - Triple') {

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            // Center BF
            const centerBfLabel = 'centerBF';
            const centerBody = new OrderLeg(order);
            centerBody.legType = LegType.Option;
            centerBody.action = ActionType.Sell;
            centerBody.qty = 2;
            centerBody.expiration = nearestExp;
            centerBody.strike = atmStrike;
            centerBody.optionType = oType;
            centerBody.groupLabel = centerBfLabel;

            const centerAboveWing = new OrderLeg(order);
            centerAboveWing.legType = LegType.Option;
            centerAboveWing.action = ActionType.Buy;
            centerAboveWing.qty = 1;
            centerAboveWing.expiration = nearestExp;
            centerAboveWing.strike = centerBody.strike + this.strikeWidth;
            centerAboveWing.optionType = oType;
            centerAboveWing.groupLabel = centerBfLabel;

            const centerBelowWing = new OrderLeg(order);
            centerBelowWing.legType = LegType.Option;
            centerBelowWing.action = ActionType.Buy;
            centerBelowWing.qty = 1;
            centerBelowWing.expiration = nearestExp;
            centerBelowWing.strike = centerBody.strike - this.strikeWidth;
            centerBelowWing.optionType = oType;
            centerBelowWing.groupLabel = centerBfLabel;

            // Above BF
            const aboveBFLabel = 'aboveBF';
            const aboveBelowWing = new OrderLeg(order);
            aboveBelowWing.legType = LegType.Option;
            aboveBelowWing.action = ActionType.Buy;
            aboveBelowWing.qty = 1;
            aboveBelowWing.expiration = nearestExp;
            aboveBelowWing.strike = centerAboveWing.strike + offset;
            aboveBelowWing.optionType = oType;
            aboveBelowWing.groupLabel = aboveBFLabel;

            const aboveBody = new OrderLeg(order);
            aboveBody.legType = LegType.Option;
            aboveBody.action = ActionType.Sell;
            aboveBody.qty = 2;
            aboveBody.expiration = nearestExp;
            aboveBody.strike = aboveBelowWing.strike + this.strikeWidth;
            aboveBody.optionType = oType;
            aboveBody.groupLabel = aboveBFLabel;

            const aboveAboveWing = new OrderLeg(order);
            aboveAboveWing.legType = LegType.Option;
            aboveAboveWing.action = ActionType.Buy;
            aboveAboveWing.qty = 1;
            aboveAboveWing.expiration = nearestExp;
            aboveAboveWing.strike = aboveBody.strike + this.strikeWidth;
            aboveAboveWing.optionType = oType;
            aboveAboveWing.groupLabel = aboveBFLabel;


            // Below BF
            const labelBelowBF = 'belowBF';
            const belowAboveWing = new OrderLeg(order);
            belowAboveWing.legType = LegType.Option;
            belowAboveWing.action = ActionType.Buy;
            belowAboveWing.qty = 1;
            belowAboveWing.expiration = nearestExp;
            belowAboveWing.strike = centerBelowWing.strike - offset;
            belowAboveWing.optionType = oType;
            belowAboveWing.groupLabel = labelBelowBF;

            const belowBody = new OrderLeg(order);
            belowBody.legType = LegType.Option;
            belowBody.action = ActionType.Sell;
            belowBody.qty = 2;
            belowBody.expiration = nearestExp;
            belowBody.strike = belowAboveWing.strike - this.strikeWidth;
            belowBody.optionType = oType;
            belowBody.groupLabel = labelBelowBF;

            const belowBelowWing = new OrderLeg(order);
            belowBelowWing.legType = LegType.Option;
            belowBelowWing.action = ActionType.Buy;
            belowBelowWing.qty = 1;
            belowBelowWing.expiration = nearestExp;
            belowBelowWing.strike = belowBody.strike - this.strikeWidth;
            belowBelowWing.optionType = oType;
            belowBelowWing.groupLabel = labelBelowBF;

            legs.push(
                aboveAboveWing, aboveBody, aboveBelowWing,
                centerAboveWing, centerBody, centerBelowWing,
                belowAboveWing, belowBody, belowBelowWing,
            );
        }
        else if (strategy === 'Collar') {

            const strikeAbove = pickStrike(this.strikeWidth);

            const leg0 = new OrderLeg(order);
            leg0.action = ActionType.Buy;
            leg0.qty = 100;
            leg0.legType = LegType.Asset;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.optionType = OptionType.Put;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeAbove;
            leg2.optionType = OptionType.Call;

            if (underlying.kind !== TradingInstrumentKind.Stock) {
                console.error('Underlying leg not added because of the underlying is not a stock');
            } else {
                legs.unshift(leg0);
            }

        }
        else if (strategy === 'Condor') {

            const width = this.strikeWidth;

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const multiplier = this.optionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = firstStrike;
            leg1.optionType = oType;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.optionType = oType;

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + width * 2 * multiplier;
            leg3.optionType = oType;

            const leg4 = new OrderLeg(order);
            leg4.legType = LegType.Option;
            leg4.action = ActionType.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = leg3.strike + width * multiplier;
            leg4.optionType = oType;

            legs.push(leg1, leg2, leg3, leg4);

            if (this.optionType === 'Calls') {
                legs.reverse();
            }

        }
        else if (strategy === 'Condor - Double') {

            const width = this.strikeWidth;

            const optionTypes = [OptionType.Call, OptionType.Put];

            for (const oType of optionTypes) {

                const multiplier = oType === OptionType.Call ? 1 : -1;

                const firstStrike = atmStrike + offset * multiplier;

                const leg1 = new OrderLeg(order);
                leg1.legType = LegType.Option;
                leg1.action = ActionType.Buy;
                leg1.qty = 1;
                leg1.expiration = nearestExp;
                leg1.strike = firstStrike;
                leg1.optionType = oType;
                leg1.groupLabel = 'Condor ' + oType.toString();

                const leg2 = new OrderLeg(order);
                leg2.legType = LegType.Option;
                leg2.action = ActionType.Sell;
                leg2.qty = 1;
                leg2.expiration = nearestExp;
                leg2.strike = leg1.strike + width * multiplier;
                leg2.optionType = oType;
                leg2.groupLabel = 'Condor ' + oType.toString();

                const leg3 = new OrderLeg(order);
                leg3.legType = LegType.Option;
                leg3.action = ActionType.Sell;
                leg3.qty = 1;
                leg3.expiration = nearestExp;
                leg3.strike = leg2.strike + width * 2 * multiplier;
                leg3.optionType = oType;
                leg3.groupLabel = 'Condor ' + oType.toString();

                const leg4 = new OrderLeg(order);
                leg4.legType = LegType.Option;
                leg4.action = ActionType.Buy;
                leg4.qty = 1;
                leg4.expiration = nearestExp;
                leg4.strike = leg3.strike + width * multiplier;
                leg4.optionType = oType;
                leg4.groupLabel = 'Condor ' + oType.toString();

                legs.push(leg1, leg2, leg3, leg4);

                if (oType === OptionType.Call) {
                    legs.reverse();
                    const legSeparator = new OrderLeg(order);
                    legSeparator.legType = LegType.Separator;
                    legs.push(legSeparator);
                }
            }
        }
        else if (strategy === 'Iron Butterfly') {

            const strikeAbove1 = pickStrike(this.strikeWidth);
            const strikeBelow1 = pickStrike(-this.strikeWidth);

            const labelCalls = 'callBF';
            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strikeAbove1;
            leg1.optionType = OptionType.Call;
            leg1.groupLabel = labelCalls;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = atmStrike;
            leg2.optionType = OptionType.Call;
            leg2.groupLabel = labelCalls;

            const labelPuts = 'putsBF';
            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = atmStrike;
            leg3.optionType = OptionType.Put;
            leg3.groupLabel = labelPuts;

            const leg4 = new OrderLeg(order);
            leg4.legType = LegType.Option;
            leg4.action = ActionType.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = strikeBelow1;
            leg4.optionType = OptionType.Put;
            leg4.groupLabel = labelPuts;

            legs.push(leg1, leg2, leg3, leg4);

        }
        else if (strategy === 'Iron Condor') {

            const strikeAbove1 = pickStrike(this.strikeWidth);
            const strikeAbove2 = pickStrike(this.strikeWidth * 2);
            const strikeBelow1 = pickStrike(-this.strikeWidth);
            const strikeBelow2 = pickStrike(-this.strikeWidth * 2);

            const labelCalls = 'calls'
            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strikeAbove2;
            leg1.optionType = OptionType.Call;
            leg1.groupLabel = labelCalls;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeAbove1;
            leg2.optionType = OptionType.Call;
            leg2.groupLabel = labelCalls;

            const labelPuts = 'puts';
            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = strikeBelow1;
            leg3.optionType = OptionType.Put;
            leg3.groupLabel = labelPuts;

            const leg4 = new OrderLeg(order);
            leg4.legType = LegType.Option;
            leg4.action = ActionType.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = strikeBelow2;
            leg4.optionType = OptionType.Put;
            leg4.groupLabel = labelPuts;


            legs.push(leg1, leg2, leg3, leg4);

        }
        else if (strategy === 'Married Stock') {

            const leg0 = new OrderLeg(order);
            leg0.action = ActionType.Buy;
            leg0.qty = 100;
            leg0.legType = LegType.Asset;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.optionType = OptionType.Put;

            legs.push(leg1);

            if (underlying.kind !== TradingInstrumentKind.Stock) {
                console.error('Underlying leg not added because of the underlying is not a stock');
            } else {
                legs.unshift(leg0);
            }

        }
        else if (strategy === 'Option - Long') {

            const strike = this.optionType === 'Calls'
                ? pickStrike(offset || 0)
                : pickStrike(-offset || 0);

            const oType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strike;
            leg1.optionType = oType;

            legs.push(leg1);

        }
        else if (strategy === 'Option - Short') {

            const strike = this.optionType === 'Calls'
                ? pickStrike(offset)
                : pickStrike(-offset || 0);

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Sell;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strike;
            leg1.optionType = oType;

            legs.push(leg1);
        }
        else if (strategy === 'Ratio Back Spread') {

            const strikeBelow = pickStrike(-this.strikeWidth);
            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 2;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.optionType = firstBfType;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeBelow;
            leg2.optionType = firstBfType;

            legs.push(leg1, leg2);

        }
        else if (strategy === 'Straddle') {

            const labelCalls = 'calls';
            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.optionType = OptionType.Call;
            leg1.groupLabel = labelCalls;

            const labelPuts = 'puts';
            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Buy;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = atmStrike;
            leg2.optionType = OptionType.Put;
            leg2.groupLabel = labelPuts;

            legs.push(leg1, leg2);

        }
        else if (strategy === 'Strangle') {

            const labelCals = 'calls';
            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset;
            leg1.optionType = OptionType.Call;
            leg1.groupLabel = labelCals;

            const labelPuts = 'puts';
            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Buy;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = atmStrike - offset;
            leg2.optionType = OptionType.Put;
            leg2.groupLabel = labelPuts;

            legs.push(leg1, leg2);

        }
        else if (strategy === 'Vertical') {

            const optionType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const multiplier = this.optionType === 'Puts' ? -1 : 1;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.optionType = optionType;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.optionType = optionType;

            legs.push(leg2, leg1);

            if (optionType === OptionType.Put) {
                legs.reverse();
            }
        }
        else if (strategy === 'Vertical - Double') {

            const optionTypes = [OptionType.Call, OptionType.Put];

            for (const optionType of optionTypes) {

                const multiplier = optionType === OptionType.Put ? -1 : 1;

                const leg1 = new OrderLeg(order);
                leg1.legType = LegType.Option;
                leg1.action = ActionType.Buy;
                leg1.qty = 1;
                leg1.expiration = nearestExp;
                leg1.strike = atmStrike + offset * multiplier;
                leg1.optionType = optionType;
                leg1.groupLabel = 'Vertical ' + optionType.toString();

                const leg2 = new OrderLeg(order);
                leg2.legType = LegType.Option;
                leg2.action = ActionType.Sell;
                leg2.qty = 1;
                leg2.expiration = nearestExp;
                leg2.strike = leg1.strike + this.strikeWidth * multiplier;
                leg2.optionType = optionType;
                leg2.groupLabel = 'Vertical ' + optionType.toString();

                legs.push(leg1, leg2);

                if (optionType === OptionType.Call) {
                    legs.reverse();
                }
            }
        }
        else if (strategy === 'Risk Reversal') {

            const strikeAbove1 = pickStrike(offset);
            const strikeAbove2 = pickStrike(offset + this.strikeWidth);
            const strikeBelow1 = pickStrike(-offset);
            const strikeBelow2 = pickStrike(- (offset + this.strikeWidth));

            const labelCalls = 'calls';

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Sell;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strikeAbove2;
            leg1.optionType = OptionType.Call;
            leg1.groupLabel = labelCalls;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Buy;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeAbove1;
            leg2.optionType = OptionType.Call;
            leg2.groupLabel = labelCalls;

            const labelPuts = 'puts';

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = strikeBelow1;
            leg3.optionType = OptionType.Put;
            leg3.groupLabel = labelPuts;

            const leg4 = new OrderLeg(order);
            leg4.legType = LegType.Option;
            leg4.action = ActionType.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = strikeBelow2;
            leg4.optionType = OptionType.Put;
            leg4.groupLabel = labelPuts;

            legs.push(leg1, leg2, leg3, leg4);

        }
        else if (strategy === 'Ladder') {

            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const secondBfType = this.optionType !== 'Calls' ? OptionType.Put : OptionType.Call;

            const callMultiplier = 1;
            const putMultiplier = -1;

            let multiplier = this.optionType === 'Puts' ? putMultiplier : callMultiplier;

            const offset = this.strikeOffset || 0;

            if (firstBfType !== secondBfType) {

                const labelCalls = 'calls';

                for (let i = 0; i < 6; i++) {
                    const leg = new OrderLeg(order);
                    leg.legType = LegType.Option;
                    leg.action = ActionType.Buy;
                    leg.qty = 1;
                    leg.expiration = nearestExp;
                    leg.strike = atmStrike + (offset + this.strikeWidth * i) * multiplier;
                    leg.optionType = firstBfType;
                    leg.groupLabel = labelCalls;

                    legs.unshift(leg);
                }

                const labelPuts = 'puts';

                for (let i = 0; i < 6; i++) {
                    const leg = new OrderLeg(order);
                    leg.legType = LegType.Option;
                    leg.action = ActionType.Buy;
                    leg.qty = 1;
                    leg.expiration = nearestExp;
                    leg.strike = atmStrike - (offset + this.strikeWidth * i) * multiplier;
                    leg.optionType = secondBfType;
                    leg.groupLabel = labelPuts;

                    legs.push(leg);
                }
            } else {

                for (let i = 0; i < 12; i++) {
                    const leg = new OrderLeg(order);
                    leg.legType = LegType.Option;
                    leg.action = ActionType.Buy;
                    leg.qty = 1;
                    leg.expiration = nearestExp;

                    if (i === 0) {
                        leg.strike = atmStrike + offset * multiplier;
                    } else {
                        leg.strike = atmStrike + (offset + this.strikeWidth * i) * multiplier;
                    }
                    leg.optionType = firstBfType;

                    if (multiplier === 1) {
                        legs.unshift(leg);
                    } else {
                        legs.push(leg);
                    }
                }
            }
        }
        else if (strategy === 'Ladder - Calendarized') {

            const optionType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const callMultiplier = 1;
            const putMultiplier = -1;

            let multiplier = this.optionType === 'Puts' ? putMultiplier : callMultiplier;

            for (let i = 0; i < 12; i++) {

                const leg = new OrderLeg(order);

                leg.legType = LegType.Option;

                leg.action = ActionType.Buy;

                leg.qty = 1;

                let expiration;

                if (i < order.expirationsList.length) {
                    expiration = order.expirationsList[i];
                } else {
                    expiration = order.expirationsList[order.expirationsList.length - 1];
                }

                leg.expiration = expiration;

                leg.strike = atmStrike + offset * multiplier;

                leg.optionType = optionType;

                legs.push(leg);
            }

        }
        else if (strategy === 'Slingshot') {

            const oType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const multiplier = oType === OptionType.Put ? -1 : 1;

            // 1st butterfly

            const label1st = '1st';

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.optionType = oType;
            leg1.groupLabel = label1st;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.optionType = oType;
            leg2.groupLabel = label1st;

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Buy;
            leg3.qty = 2;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.optionType = oType;
            leg3.groupLabel = label1st;

            legs.push(leg1,leg2, leg3);

            if (oType === OptionType.Call) {
                legs.reverse();
            }

        }
        else if (strategy === 'Slingshot - Modified') {

            const oType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const multiplier = oType === OptionType.Put ? -1 : 1;

            // 1st butterfly

            const label1st = '1st';

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.optionType = oType;
            leg1.groupLabel = label1st;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.optionType = oType;
            leg2.groupLabel = label1st;

            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Buy;
            leg3.qty = 2;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.optionType = oType;
            leg3.groupLabel = label1st;

            const leg4 = new OrderLeg(order);
            leg4.legType = LegType.Option;
            leg4.action = ActionType.Sell;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = leg3.strike + this.strikeWidth * multiplier;
            leg4.optionType = oType;
            leg4.groupLabel = label1st;

            legs.push(leg1,leg2, leg3, leg4);

            if (oType === OptionType.Call) {
                legs.reverse();
            }

        }
        else if (strategy === 'Slingshot - Double') {

            const optionTypes = [OptionType.Call, OptionType.Put];

            for (const oType of optionTypes) {

                const multiplier = oType === OptionType.Put ? -1 : 1;

                const leg1 = new OrderLeg(order);
                leg1.legType = LegType.Option;
                leg1.action = ActionType.Buy;
                leg1.qty = 1;
                leg1.expiration = nearestExp;
                leg1.strike = atmStrike + offset * multiplier;
                leg1.optionType = oType;
                leg1.groupLabel = 'Slingshot ' + oType.toString();

                const leg2 = new OrderLeg(order);
                leg2.legType = LegType.Option;
                leg2.action = ActionType.Sell;
                leg2.qty = 2;
                leg2.expiration = nearestExp;
                leg2.strike = leg1.strike + this.strikeWidth * multiplier;
                leg2.optionType = oType;
                leg2.groupLabel = 'Slingshot ' + oType.toString();

                const leg3 = new OrderLeg(order);
                leg3.legType = LegType.Option;
                leg3.action = ActionType.Buy;
                leg3.qty = 2;
                leg3.expiration = nearestExp;
                leg3.strike = leg2.strike + this.strikeWidth * multiplier;
                leg3.optionType = oType;
                leg3.groupLabel = 'Slingshot ' + oType.toString();

                legs.push(leg1,leg2, leg3);

                if (oType === OptionType.Call) {
                    legs.reverse();
                }
            }

        }
        else if (strategy === 'Sponsored Long') {
            const width = this.strikeWidth;

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const multiplier = this.optionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const step = this.orderModel.tradingInstrument?.ticker === 'SPX' ? 5 : 1;

            const leg1 = new OrderLeg(order);
            leg1.legType = LegType.Option;
            leg1.action = ActionType.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = firstStrike;
            leg1.optionType = oType;

            const creditSpreadQty = width / step;

            const leg2 = new OrderLeg(order);
            leg2.legType = LegType.Option;
            leg2.action = ActionType.Sell;
            leg2.qty = creditSpreadQty;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.optionType = oType;


            const leg3 = new OrderLeg(order);
            leg3.legType = LegType.Option;
            leg3.action = ActionType.Buy;
            leg3.qty = creditSpreadQty;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + step * multiplier;
            leg3.optionType = oType;

            legs.push(leg1, leg2, leg3);

            if (this.optionType === 'Puts') {
                legs.reverse();
            }
        }else {
            console.error('Unknown strategy');
            return undefined;
        }

        order.legs = legs;
    }

    getSeparatorType() {
        const allSame = this.orderModel.legs
            .filter(x => x.legType !== LegType.Separator)
            .map(x => x.optionType)
            .every((v, ix, arr) => v === arr[0]);

        return allSame ? 'Row' : 'Line';
    }


    onStrategyChanged($event: DxValueChanged<OptionStrategy>) {
        setTimeout(() => {

            this.restoreOptionStrategySettings();

            const cpOnlyStrats: OptionStrategy[] = [
                'Collar',
                'Risk Reversal',
                'Iron Butterfly',
                'Iron Condor',
                'Straddle',
                'Strangle',
                'Slingshot - Double',
                'Vertical - Double',
                'Butterfly - Double',
                'Condor - Double'
            ];

            const ix = cpOnlyStrats.indexOf($event.value);
            if (ix >= 0) {
                this.optionType = 'Calls & Puts';
            } else if ($event.value === 'Married Stock') {
                this.optionType = 'Puts';
            }

            if (!this.isOffsetAvailable) {
                this.strikeOffset = undefined;
            }

            if (!this.isStrikeWidthAvailable) {
                this.strikeWidth = null;
            }

            this._changeDetector.detectChanges();
        });
    }



    private saveOptionStrategySettings() {

        const keys: OptionStrategySettings[] = [
            'mlpad.width',
            'mlpad.offset',
            'mlpad.option-type'
        ];

        keys.forEach(x => {
            const key = x + `.${this.orderModel.tradingInstrument.ticker}.${this.orderModel.selectedStrategy}`;

            switch (x) {
                case "mlpad.option-type":
                    this._settingsStorageService.setItem(key, this.optionType);
                    break;
                case "mlpad.width":
                    this._settingsStorageService.setItem(key, this.strikeWidth);
                    break;
                case "mlpad.offset":
                    this._settingsStorageService.setItem(key, this.strikeOffset);
                    break;
            }
        });
    }

    private restoreOptionStrategySettings() {

        const keys: OptionStrategySettings[] = [
            'mlpad.width',
            'mlpad.offset',
            'mlpad.option-type'
        ];

        keys.forEach(x => {
            const key = x + `.${this.orderModel.tradingInstrument.ticker}.${this.orderModel.selectedStrategy}`;
            const val = this._settingsStorageService.getItem<any>(key);

            switch (x) {
                case "mlpad.option-type":
                    this.optionType = val;
                    break;
                case "mlpad.width":
                    const sw = val; // parseInt(val);
                    this.strikeWidth = isValidNumber(sw) ? sw : undefined;
                    break;
                case "mlpad.offset":
                    const so = val; // parseInt(val);
                    this.strikeOffset = isValidNumber(so) ? so : undefined;
                    break;

            }
        });
    }

    canApplyStrategy() {
        const hasBasics =
            !isVoid(this.orderModel.tradingInstrument) &&
            !isVoid(this.orderModel.selectedStrategy) &&
            !isVoid(this.optionType);

        const isWidthOK = this.isStrikeWidthAvailable
            ?  isValidNumber(this.strikeWidth, true)
            : true;

        return hasBasics && isWidthOK;
    }

    @DetectMethodChanges()
    onAutoSortChanged() {
        this.orderModel.resetLegGroups();
    }

    @DetectMethodChanges()
    changeOffsetMode(offsetMode: 'Offset' | 'Strike') {
        this.strikeOffsetMode = offsetMode;
        if (offsetMode === 'Offset') {
            this.baseStrike = undefined;
        } else if (offsetMode === 'Strike') {
            this.strikeOffset = undefined;
        }
    }
}
