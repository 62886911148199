import { CashFlowStrategy } from "projects/shared-components/adjustment-control-panel/cash-flow-strategy";
import { CashFlowStrategySettingsTemplateSet } from "./CashFlowStrategySettingsTemplateSet";
import { ICashFlowExpirationSettings } from "./ICashFlowExpirationSettings";
import { ICashFlowStrategyGlobalSettings } from "./ICashFlowStrategyGlobalSettings";

//
export const CASH_FLOW_STRATEGY_SETTINGS_TEMPLATE_VERSION = 2;

//
export interface ICashFlowAdjustmentSettingsTemplate {
   version: number;
   templateId: string;
   templateName: string;
   underlying: string;
   strategyName: CashFlowStrategy;
   globalSettings: ICashFlowStrategyGlobalSettings;
   settings: CashFlowStrategySettingsTemplateSet[];
   expirationSettings: ICashFlowExpirationSettings;

   isModified?: boolean;
   isShared?: boolean;
}
