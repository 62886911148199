import { SyncOverrideHistoryComponent } from './sync-override-history.component';
import { GridOptions, CellClassParams, RowSelectedEvent, SelectionChangedEvent, GetContextMenuItemsParams, ValueFormatterParams } from 'ag-grid-community';
import { centeredColumnDef, defaultLoadingOverlayTemplate, defaultNumberCellFormatter, defaultMoneyCellDefinition } from 'projects/shared-components/ag-grid-contrib';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { SyncOverrideRecordDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { SimpleHeaderGroupRowInnerRenderer } from 'projects/shared-components/unspecific/ag-grid-contrib/group-row-inner-renderer.component';


export function getSyncOverrideHistoryGridModel(
   this: SyncOverrideHistoryComponent,
   timestampsService: TimestampsService
): GridOptions {
   return {
      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: [
         {
            headerName: 'Timestamp',
            field: 'timestamp',
            valueFormatter: (params: ValueFormatterParams) => {
               const frmtTime = timestampsService.getDefaultFormattedDateTime(params.value);
               return frmtTime;
            }
         },
         {
            headerName: 'Note',
            field: 'note',
            headerComponentParams: {},
            cellStyle: (args) => {
               return {
                  'text-align': 'left',
               };
            }
         },
         {
            headerName: 'Strats',
            field: 'strategies',
            valueFormatter: defaultNumberCellFormatter
         },
         {
            headerName: 'MPs',
            field: 'manualPositions',
            valueFormatter: defaultNumberCellFormatter
         },
         Object.assign(
            { headerName: 'Before P&L', field: 'beforePnL' },
            defaultMoneyCellDefinition
         ),
         Object.assign(
            { headerName: 'After P&L', field: 'afterPnL' },
            defaultMoneyCellDefinition
         ),
      ],

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      immutableData: true,

      groupUseEntireRow: true,

      frameworkComponents: {
         simpleGroupRowRenderer: SimpleHeaderGroupRowInnerRenderer
      },

      groupRowRendererParams: {
         innerRenderer: 'simpleGroupRowRenderer',
         suppressCount: true
      },

      suppressCellSelection: true,

      onGridReady: args => this.onGridReady(args, 'history'),

      onSelectionChanged: (args: SelectionChangedEvent) => this.onHistoryGridSelectionChanged(args),

      getRowNodeId: (data: SyncOverrideRecordDto) => {
         return data.syncOverrideRecordId;
      },

      onFirstDataRendered: () => this.autoSizeColumns('history'),

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         return [
            {
               name: 'Show Note Popup',
               action: () => this.showNotePopup(params.node.data),
               disabled: !params.node
            },
            'separator',
            {
               name: 'Rollback Last Operation',
               action: () => this.rollbackLastOperation(),
            },
            {
               name: 'Delete Record',
               action: () => this.deleteRecord(params.node.data),
               disabled: !params.node
            },
            'separator',
            'autoSizeAll', 'copy', 'export'];
      },


   } as GridOptions;
}
