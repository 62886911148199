import {
  Bar,
  DomeCallback,
  ErrorCallback,
  GetMarksCallback,
  HistoryCallback,
  HistoryDepth,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  Mark,
  OnReadyCallback,
  ResolutionBackValues,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  ServerTimeCallback,
  SubscribeBarsCallback,
  TimescaleMark,
  SearchSymbolResultItem,
  Exchange
} from '../price-chart/data-feed-chart-api';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { filter, takeUntil } from 'rxjs/operators';
import { IChartingLibraryWidget } from '../../webtrader/src/assets/tv/charting_library/charting_library.min';
import { EventEmitter } from '@angular/core';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import { ShellConnectionStatusChangedUIMessage } from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { StrategyOpenPositionSnapshotDto } from '../shell-communication/dtos/strategy-open-position-snapshot-dto.class';
import {
  GetOpenPositionChartData,
  GetOpenPositionChartDataResponse
} from '../shell-communication/operations/charts/get-open-position-chart-data.class';
import { RemoveOpenPositionChartSubscription } from '../shell-communication/operations/charts/remove-open-position-chart-subscription.class';
import { LoggerService } from '../logging/logger-factory.service';
import { Logger } from '../logging/logger.interface';
import * as Enumerable from 'linq';
import { StrategiesService } from '../strategies/strategies.service';
import { StrategyModel } from '../strategies/strategy-model';
import { OpenPositionSnapshotsBackendService } from './open-position-snapshots-backend.interface';

const RESOLUTIONS = ['10S'];

interface DataSubscriber {
  onBar?: SubscribeBarsCallback;
  onReset?: () => void;
  subscriberId: string;
  strategyId: string;
  shellId?: string;
  pendingData: StrategyOpenPositionSnapshotDto[];
}

export class OpenPositionDataFeed implements IDatafeedChartApi, IExternalDatafeed {
  public constructor(
    private _componentId: string,
    private _backendService: OpenPositionSnapshotsBackendService,
    private _messageBus: MessageBusService,
    private _toastr: ToastrService,
    private _strategiesService: StrategiesService,
    private _chartResolver: () => IChartingLibraryWidget,
    loggerService: LoggerService
  ) {
    this._logger = loggerService.createLogger(`OpenPositionDataFeed(${this._componentId})`);
  }

  private readonly _dataSubscribers: Record<string, DataSubscriber> = {};
  public resetData: EventEmitter<void> = new EventEmitter();
  private _emptyRequests = [];
  private _unsubscriber = new Subject<any>();
  private _logger: Logger;
  private _disposed: boolean;

  // isListeningToSelectedStrategy: boolean;

  // changeListeningToSelectedStrategy(): void {
  //   this.isListeningToSelectedStrategy = !this.isListeningToSelectedStrategy;
  // }

  init(): void {}

  onReady(callback: OnReadyCallback): void {
    this._messageBus
      .of<StrategyOpenPositionSnapshotDto[]>('StrategyOpenPositionSnapshotDto')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(msg => {
        const filtered = msg.payload.filter(x => x.strategyId in this._dataSubscribers);

        if (filtered.length > 0) {
         this._onOpenPositionSnapshotDto(filtered);
        }
      });

    this._messageBus
      .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(msg => {
        setTimeout(() => this._onClearTradingDataUIMessage(msg.payload));
      });

    this._messageBus
      .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
      .pipe(
        filter(x => x.payload.isConnected),
        takeUntil(this._unsubscriber)
      )
      .subscribe(msg => {
        setTimeout(() => this._onShellConnectionStatusChangedUIMessage(msg.payload));
      });

    setTimeout(() => {
      const allStrategies: Enumerable.IEnumerable<StrategyModel> = Enumerable.from(
        this._strategiesService.getAllStrategies()
      );

      const exchanges = allStrategies
        .select(str => `${str.clientName} | ${str.shellName}`)
        .distinct()
        .select(x => {
          return {
            name: x,
            value: x,
            desc: x
          } as Exchange;
        })
        .toArray();

      exchanges.unshift({ value: 'All', desc: 'All Shells', name: 'All Shells' });

      const configObj = {
        exchanges,
        supported_resolutions: RESOLUTIONS
      };

      callback(configObj);
    }, 0);
  }

  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    rangeStartDate: number,
    rangeEndDate: number,
    onResult: HistoryCallback,
    onError: ErrorCallback,
    isFirstCall: boolean
  ): Promise<void> {
    
    const queryCode = `${symbolInfo.ticker}_${resolution}`;

    const strategyId = symbolInfo.ticker;
    const shellId = (symbolInfo as any).shellId;
    const subscriberId = this._componentId;
    const start = new Date(rangeStartDate * 1000);
    const end = new Date(rangeEndDate * 1000);

    this._logger.info(`Getting bars for ${queryCode}`);

    const subscriberObj: DataSubscriber = this._dataSubscribers[symbolInfo.ticker] || {
      subscriberId,
      strategyId,
      pendingData: [],
      shellId
    };

    if (subscriberObj.onBar !== undefined) {
      this._logger.info('Subscriber Object already registered');
    } else {      
      this._dataSubscribers[subscriberObj.strategyId] = subscriberObj;
    }

    let qryResponse: GetOpenPositionChartDataResponse;

    try {
      const qry = new GetOpenPositionChartData(strategyId, subscriberId, start, end);
      qryResponse = await this._backendService.getSnapshots(qry);
    } catch (e) {
      onError('Failed to get open position snapshots from server');
      delete this._dataSubscribers[subscriberObj.strategyId];
      return;
    }

    if (qryResponse.snapshots.length > 0) {

      const bars: Bar[] = qryResponse.snapshots.map(dto => {
        return {
          time: dto.timestamp.getTime() - dto.timestamp.getTimezoneOffset() * 60 * 1000,
          open: dto.netPosition,
          high: dto.netPosition,
          low: dto.netPosition,
          close: dto.netPosition,
          volume: dto.netPosition
        };
      });
  
      setTimeout(() => onResult(bars, { noData: true }), 0);
    } else {
      if (qryResponse.closestBarDate) {
        setTimeout(() => {
          const lastBarTime = qryResponse.closestBarDate.getTime() - qryResponse.closestBarDate.getTimezoneOffset() * 60 * 1000;
          const nextTime = lastBarTime / 1000;
          onResult([], { nextTime, noData: false });
        });
      } else {
        setTimeout(() => onResult([], { noData: true }), 0);
      }
    }
  }

  resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
    setTimeout(() => {
      let strategy = this._strategiesService.getById(symbolName);

      if (!strategy) {
        strategy = this._strategiesService.getAllStrategies().find(str => {
          const n = `${str.displayName}@${str.terminalCode}`;
          return n === symbolName;
        });

        if (!strategy) {
          onError('Symbol not found');
          return;
        }
      }

      const symbolCode = strategy.strategyId;
      const description = `${strategy.displayName}@${strategy.terminalCode}`;
      const shellId = strategy.shellId;

      const result: LibrarySymbolInfo = {
        name: strategy.displayName,
        data_status: 'streaming',
        full_name: description,
        description,
        type: 'custom_study',
        exchange: `${strategy.clientName} | ${strategy.shellName}`,
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolCode,
        supported_resolutions: RESOLUTIONS,
        seconds_multipliers: ['10'],
        has_seconds: true,
        minmov: 1,
        listed_exchange: `${strategy.clientName} | ${strategy.shellName}`,
        pricescale: 1,
        has_intraday: true,
        has_daily: false
      };

      (result as any).shellId = shellId;

      onResolve(result);
    });
  }

  async subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): Promise<void> {
    if (this._disposed) {
      return;
    }

    const strategyId = listenerGuid.split('_')[0];

    this._logger.info(`Subscribing: ${listenerGuid}`);

    const subscriberObj = this._dataSubscribers[strategyId];

    if (!subscriberObj) {
      this._logger.info(`Subscriber object not found for StrategyId=${strategyId}`);
      return;
    }

    subscriberObj.onBar = onTick;
    subscriberObj.onReset = onResetCacheNeededCallback;

    this._logger.debug(`Added data subscriber: ${listenerGuid}`);
  }

  async unsubscribeBars(listenerGuid: string): Promise<void> {
    if (this._disposed) {
      return;
    }

    await this._unsubscribeBars(listenerGuid);
  }

  calculateHistoryDepth(
    resolution: ResolutionString,
    resolutionBack: ResolutionBackValues,
    intervalBack: number
  ): HistoryDepth | undefined {
    // if depth is less than 3 days, we set it explicitly to 3 days.
    // this is required for cases, when user loads chart on weekend and on small
    // timeframes backend returns no data. For example, if you request data on Sunday,
    // request range will only cover Saturday, which is non-trading day.

    const result = { resolution, resolutionBack, intervalBack };

    if (resolutionBack === 'D') {
      if (intervalBack < 3) {
        result.intervalBack = 3;
      }
    }

    return result;
  }

  dispose(): void {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber.complete();
    }

    for (const key of Object.keys(this._dataSubscribers)) {
      this._unsubscribeBars(key).catch(() => {});
    }

    this._disposed = true;
  }

  subscribeDepth(symbolInfo: LibrarySymbolInfo, callback: DomeCallback): string {
    return '';
  }

  unsubscribeDepth(subscriberUID: string): void {}

  getServerTime(callback: ServerTimeCallback): void {}

  getTimescaleMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<TimescaleMark>,
    resolution: ResolutionString
  ): void {}

  getMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString
  ): void {}

  searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
    let items: Enumerable.IEnumerable<StrategyModel> = Enumerable.from(this._strategiesService.getAllStrategies());

    if (exchange) {
      if (exchange !== 'All') {
        items = items.where(str => `${str.clientName} | ${str.shellName}` === exchange);
      }
    }

    if (userInput) {
      items = items.where(
        x => `${x.displayName}${x.algo}${x.terminalCode}`.toLowerCase().indexOf(userInput.toLowerCase()) >= 0
      );
    }

    const searchResult = items
      .select(strategy => {
        const item: SearchSymbolResultItem = {
          ticker: strategy.strategyId,
          description: strategy.algo,
          exchange: `${strategy.clientName} | ${strategy.shellName}`,
          symbol: `${strategy.displayName}@${strategy.terminalCode}`,
          type: null,
          full_name: `${strategy.displayName}@${strategy.terminalCode}`
        };
        return item;
      })
      .toArray();

    onResult(searchResult);
  }

  private async _unsubscribeBars(listenerGuid: string) {
    const strategyId = listenerGuid.split('_')[0];

    this._logger.debug(`Un-subscribing: ${listenerGuid}`);

    const subscriberObj = this._dataSubscribers[strategyId];

    if (!subscriberObj) {
      this._logger.info(
        `"Unsubscribe Bars" operation failed. Subscriber object not found for StrategyId=${strategyId}`
      );
      return;
    }

    delete this._dataSubscribers[strategyId];

    const cmd = new RemoveOpenPositionChartSubscription(subscriberObj.strategyId, subscriberObj.subscriberId);

    try {
      await this._backendService.unsubscribeUpdates(cmd);
    } finally {
      this._logger.debug(`Removed data subscriber: ${listenerGuid}`);
      this._emptyRequests.length = 0;
    }
  }

  private _onOpenPositionSnapshotDto(snapshots: StrategyOpenPositionSnapshotDto[]) {
    if (snapshots.length === 0) {
      return;
    }

    const strategyId = snapshots[0].strategyId;

    const subscriberObj = this._dataSubscribers[strategyId];

    if (!subscriberObj) {
      this._logger.error(`Subscriber object not found for StrategyId=${strategyId}. Snapshots not processed`);
      return;
    }

    if (!subscriberObj.onBar) {
      subscriberObj.pendingData.push(...snapshots);
      return;
    }

    if (subscriberObj.pendingData.length !== 0) {
      const firstTimestamp = snapshots[0].timestamp;
      const pendingSnapshots = subscriberObj.pendingData.filter(x => x.timestamp < firstTimestamp);
      snapshots.unshift(...pendingSnapshots);
      subscriberObj.pendingData.length = 0;
    }

    snapshots.forEach(dto => {
      const correctTime = dto.timestamp.getTime() - dto.timestamp.getTimezoneOffset() * 60 * 1000;

      const netPosition = dto.netPosition;

      const bar: Bar = {
        open: netPosition,
        high: netPosition,
        low: netPosition,
        close: netPosition,
        volume: netPosition,
        time: correctTime
      };

      subscriberObj.onBar(bar);
    });
  }

  private _onClearTradingDataUIMessage(msg: ClearTradingDataUIMessage) {
    this._logger.info('Received "ClearTradingDataUIMessage" message', msg);
    this._reloadDataIfNecessary(msg.shellId);
  }

  private _onShellConnectionStatusChangedUIMessage(msg: ShellConnectionStatusChangedUIMessage) {
    this._logger.info('Received "ShellConnectionStatusChangedUIMessage" message', msg);

    if (!msg.isConnected) {
      return;
    }

    this._reloadDataIfNecessary(msg.shellId);
  }

  private _reloadDataIfNecessary(shellId: string): void {
    let shouldReloadChart: boolean;

    for (const dataSubscribersKey of Object.keys(this._dataSubscribers)) {
      const dataSubscriber = this._dataSubscribers[dataSubscribersKey];

      const targetShell = dataSubscriber.shellId;

      const shellIdMatches = targetShell === shellId;

      shouldReloadChart = !shouldReloadChart && shellIdMatches;

      if (shellIdMatches) {
        dataSubscriber.onReset();
      }
    }

    if (shouldReloadChart) {
      this._logger.info(
        `Chart will be reloaded, because shell matches. ShellId=${shellId}, ComponentId=${this._componentId}`
      );
      const chart = this._chartResolver();
      if (chart) {
        chart.chart().resetData();
      }
    }
  }
}
