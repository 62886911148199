import {SortingAttribute} from "./sorting.attribute";

export interface SortingRule {
    order: 'asc' | 'desc' | undefined;
    attribute: SortingAttribute;
    data?: any
}

export function getSortingWeight(attr: SortingAttribute, view: 'packages' | 'dashboard', index?: number) {
    if (view === 'packages') {
        switch (attr) {
            case "portfolio":
                return 0;
            case "template":
                return 1;
            case "expiration":
                return 2;
            case "adjustment":
                return 3;
            case "hedge_call":
                return 4;
            case "hedge_put":
                return 5;
            case "total":
                return 6;
            default:
                break;
        }
    } else if (view === 'dashboard') {
        switch (attr) {
            case "portfolio":
                return 0;
            case "template":
                return 1;
            case "adjustment":
                return 2;
            case "expiration":
                return 3 + index;
            case "hedge_call":
                return 9; // possible 8 expirations taken into account
            case "hedge_put":
                return 10;
            default:
                break;
        }
    }
    return  100;
}