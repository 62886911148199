import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { TradingInstrumentDisplayNameService } from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { IANAZone, DateTime } from 'luxon';
import { MessageBusService } from 'projects/shared-components/message-bus.service';

@Component({
  selector: 'ets-rollover-offset',
  template: `
    <div class="wrapper">
      <div class="block">
        <span class="group-title">{{groupTitle}}</span>
      </div>
      <div class="block action-panel">
        <span>Base Offset (days):</span>  
        <div class="offset-box">
          <dx-number-box [showSpinButtons]="true" [(value)]="baseOffset"
            (onValueChanged)="onBaseOffsetChanged($event)" [max]="0"></dx-number-box>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: inline-block;
        width: 100%;
        text-align: left;
        vertical-align: middle;
      }

      .block {
        display: inline-block;
      }

      .group-title {
        color: yellow;
      }

      .action-panel {
        position: absolute;
        right: 1px;
        margin-top: -3px;
      }

      .action-panel > span {
        font-weight: bold;
        padding-right: 5px;
      }

      .offset-box {
          display: inline-block;
          width: 80px;
      }
    `
  ]
})
export class RolloverContractGroupRowRendererComponent implements ICellRendererAngularComp {
  
  constructor(
    private readonly _messageBus: MessageBusService) {    
  }

  initParams: ICellRendererParams;
  baseOffset = 0;
  isSession = false;

  get groupTitle(): string {
    return this.initParams.node.key;
  }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.initParams = params;
    if (params.node.allLeafChildren.length > 0) {
      const baseOffset = params.node.allLeafChildren[0].data.baseOffset;
      this.baseOffset = baseOffset;
      this.isSession = params.node.allLeafChildren[0].data.isSession;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  onBaseOffsetChanged(event) {
    const val: number = event.value;
    this.initParams.node.allLeafChildren.forEach(lc => {
      lc.data.baseOffset = val;
    });

    this._messageBus.publish({
      topic: 'BaseOffsetChanged',
      payload: { symbol: this.initParams.value, isSession: this.isSession }
    });
  }
}
