import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LastPriceComponent } from './last-price.component';

@NgModule({
   imports: [
      CommonModule
   ],
   exports: [LastPriceComponent],
   declarations: [LastPriceComponent],
   providers: [],
})
export class LastPriceModule { }
