import { Component, Input, OnInit } from '@angular/core';
import { Actions, StrategyCommanderEditorParameters } from '../default-editor-parameters';
import { StrategiesService } from '../../../../../../shared-components/strategies/strategies.service';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';

@Component({
  selector: 'exit-specific-strategy',
  template: `
    <div style="margin-top: 30px; margin-bottom: 10px">Select Strategy:</div>
    <dx-select-box
      width="90%"
      style="margin: auto; margin-top: 10px;"
      valueExpr="value"
      displayExpr="text"
      [items]="strategiesList"
      [(value)]="parameters.strategyId"
    ></dx-select-box>
  `
})

export class ExitSpecificStrategyComponent implements OnInit {
  constructor(private _strategiesService: StrategiesService) {
  }

  @Input()
  parameters: any;

  strategiesList: any[];

  public static fillParameters(
    parameters: string, 
    paramsObj: StrategyCommanderEditorParameters, 
    tradingInstrumentService: TradingInstrumentsService
    ) {
    paramsObj.actions.exitSpecificStrategy = JSON.parse(parameters);
  }

  public static getParameters(paramsObj: Actions.ExitSpecificStrategy): string {
    return JSON.stringify(paramsObj);
  }

  static checkParameters(parameters: Actions.ExitSpecificStrategy) {
    if (!parameters.strategyId) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    var objects = this._strategiesService.getAllStrategies().map( s => {
      return {
        value: s.strategyId,
        text: s.displayName
      }
    });
    this.strategiesList = objects;
  }
}
