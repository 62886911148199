import { StrategiesIssuesBackendService } from 'projects/shared-components/strategies/strategy-issues-backend-service.interface';
import { 
  GetStrategyIssuesCount 
} from 'projects/shared-components/shell-communication/operations/strategies/get-strategy-issues-count.class';
import { 
  GetStrategyIssuesCountResponse 
} from 'projects/shared-components/shell-communication/dtos/get-strategy-issues-count-response.interface';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class WebtraderStrategiesIssuesBackendService implements StrategiesIssuesBackendService {
  constructor(private readonly _shellClient: ShellClientService) {}
  
  getStrategiesIssues(qry: GetStrategyIssuesCount): Promise<GetStrategyIssuesCountResponse> {
    return this._shellClient.processQuery(qry);
  }
}
