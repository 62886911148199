<div class="root-container">
  
  <div class="section-caption">
    Portfolios Positions
  </div>
  
  <div class="positions-section">
    <div class="positions" *ngFor="let poses of this.portfolioPositions; last as isLast">
      <div class="position" *ngFor="let pos of poses" [ngClass]="this.getRoleClass(pos)">
        <span class="qty">{{ pos.qty }}</span>
        <span class="ticker">{{ this.getHumanFriendlyOptionTicker(pos) }}</span>
      </div>
      <hr *ngIf="!isLast" style="width: 80%; border-color: dimgray">
    </div>
  </div>
</div>


