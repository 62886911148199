import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimezonePickerComponent } from '../timezone-picker/timezone-picker.component';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from '../utils';
import { CalendarModel, DateTimePickerMode, TimeModel } from './datetime-picker.model';

@Component({
   selector: 'ets-datetime-picker',
   templateUrl: 'datetime-picker.component.html',
   styleUrls: ['datetime-picker.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateTimePickerComponent implements OnInit, AfterViewInit {
  
   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _toastr: ToastrService
   ) {
      this.calendar = new CalendarModel(this._changeDetector);
      this.time = new TimeModel(this._changeDetector);
   }

   private _memento = {
      value: null,
      timezone: null,
      isActual: false
   };

   @ViewChild(TimezonePickerComponent) timezonePicker: TimezonePickerComponent;

   //

   
   private _contextWord : string;
   get contextWord() : string {
      return this._contextWord;
   }

   @Input()
   @DetectSetterChanges()
   set contextWord(v : string) {
      this._contextWord = v;
      if (this.time) {
         this.time.contextWord = this.contextWord;
      }
   }
   
   //

   private _disabled: boolean;
   get disabled(): boolean {
      return this._disabled;
   }
   @Input()
   @DetectSetterChanges()
   set disabled(v: boolean) {
      this._disabled = v;
   }
   
   get isDisabled(): boolean {
      return this.disabled || !this.mode;
   }
   
   //

   @Input() placeholder: string;

   //

   @Input() showTodayButton = true;

   //

   private _mode: DateTimePickerMode;
   get mode(): DateTimePickerMode {
      return this._mode;
   }
   @Input()
   @DetectSetterChanges({delay: 1})
   set mode(v: DateTimePickerMode) {
      this._mode = v;
      if (this.time) {
         this.time.setMode(v);
      }
   }
   
   //

   @Input() showTimezone = false;

   //

   dropdownOptions: any;
   
   //

   get showDatePicker(): boolean {
      return this.mode === 'date' || this.mode === 'datetime';
   }

   get showTimePicker(): boolean {
      return this.mode === 'time' || this.mode === 'datetime';
   }

   get showPeriodPicker(): boolean {
      return this.mode !== 'timespan';
   }

   //

   private _timezone: string;
   get timezone(): string {
      return this._timezone;
   }

   @Input()
   set timezone(v: string) {
      if (this._timezone === v || !this.showTimezone) {
         return;
      }
      this._timezone = v;
      this.timezoneChange.emit(this.timezone);
   }
   
   //

   @Output() timezoneChange = new EventEmitter<string>();

   //

   private _value: string;
   
   get value(): string {
      return this._value;
   }

   @Input() 
   @DetectSetterChanges()
   set value(v: string) {
      
      if (this._value === v && !this._memento.isActual) {
         return;
      }

      const previousValue = this._value;
      
      this._value = v;
      
      if (!this._value) {
         if (this.calendar) {
            this.calendar.reset();
         }
         if (this.time) {
            this.time.reset();
         }

      } else {
         if (this.mode === 'date') {
            if (this.calendar) {
               this.calendar.setValue(v);
            }
         } else if (this.mode === 'datetime') {
            if (this.calendar) {
               this.calendar.setValue(v);
            }
            if (this.time) {
               this.time.setValue(v);  
            } 
         } else {
            if (this.time) {
               this.time.setValue(v);
            }
         }
      }

      this.valueChange.emit(v);
      this.valueChanged.emit({value: v, previousValue});
   }
   
   @Output() valueChange = new EventEmitter<string>();
   @Output() valueChanged = new EventEmitter<{value: string, previousValue: string}>();

   //
   
   calendar: CalendarModel;

   //
   
   time: TimeModel;

   //

   get displayText(): string {

      if (this.isOpened) {
         return;
      }
      
      let text = 'Select mode...';

      if (this.mode === 'date') {
         text = this.getDisplayTextForDateMode();
      } else if (this.mode === 'datetime') {
         text = this.getDisplayTextForDateTimeMode();
      } else if (this.mode === 'time') {
         text = this.getDisplayTextForTimeMode();
      } else if (this.mode === 'timespan') {
         text = this.getDisplayTextForTimespanMode();
      }

      return text;
   }

   //

   private _isOpened: boolean;
   get isOpened(): boolean {
      return this._isOpened;
   }

   @DetectSetterChanges()
   set isOpened(v: boolean) {
      this._isOpened = v;
   }

   //

   ngOnInit() {
      // if (this.mode !== 'datetime' && this.mode !== 'time' && this.mode !== 'timespan' && this.mode !== 'date') {
      //    throw Error(`Unsupported datetime picker mode: ${this.mode}`);
      // }
      this.time.contextWord = this.contextWord;
      this.time.setMode(this.mode);
      this.dropdownOptions = this.getDropDownOptions();
    }
   
    ngAfterViewInit(): void {
      this._changeDetector.detach();
   }

   onOpening(ev) {

   }

   onOpened() {
      this.saveState();
   }

   @DetectMethodChanges()
   onClosed() {
      this.rollbackState();
   }
   onOkClicked() {
      
      if (this.mode === 'date') {
         const calendarErrors: string[] = this.calendar.validate();
         if (calendarErrors.length > 0) {
            calendarErrors.forEach(e => this._toastr.error(e));
            return;
         }
      } else if (this.mode === 'datetime')  {

         const calendarErrors: string[] = this.calendar.validate();
         const timeErrors: string[] = this.time.validate();

         if (calendarErrors.length > 0) {
            calendarErrors.forEach(e => this._toastr.error(e));
         }

         if (timeErrors.length > 0) {
            timeErrors.forEach(e => this._toastr.error(e));
         }
         
         if (calendarErrors.length > 0 || timeErrors.length > 0) {
            return;
         }

      } else {
         
         const timeErrors: string[] = this.time.validate();
         
         if (timeErrors.length > 0) {
            timeErrors.forEach(e => this._toastr.error(e));
         }

         if (timeErrors.length > 0) {
            return;
         }
      }

      if (this.showTimezone) {
         if (!this.timezone) {
            this._toastr.error('Timezone not set');
            return;
         }
      }

      this._memento.isActual = false;
      this.value = this.makeValue();
      this.isOpened = false;
   }
   onClearClicked() {
      this._memento.isActual = false;
      this.calendar.reset();
      this.time.reset();
      if (this.timezonePicker) {
         this.timezonePicker.reset();
      }
      this.value = null;
      this.isOpened = false;
   }
   onCancelClicked() {
      this.rollbackState();
      this.isOpened = false;
   }
   private makeValue(): string {
      let value;
      
      if (this.mode === 'date') {
         const date = this.calendar.getValue();
         value = date;
      } else if (this.mode === 'datetime') {
         if (this.calendar && this.time) {
            const date = this.calendar.getValue();
            const time = this.time.getValue();
            value = `${date}T${time}`;
         }
      } else {
         if (this.time) {
            value = this.time.getValue();
         }
      }

      return value;
   }
   private getDisplayTextForTimespanMode(): string {
      let text = this.time.getDisplayText();
      if (text.indexOf('Pick') >= 0 && !isNullOrUndefined(this.placeholder)) {
         text = this.placeholder;
      }
      return text;
   }
   private getDisplayTextForTimeMode(): string {
      let text = this.time.getDisplayText();
      if (text.indexOf('Pick') >= 0 && !isNullOrUndefined(this.placeholder)) {
         text = this.placeholder;
      }
      return text;
   }
   private getDisplayTextForDateTimeMode(): string {
      const dateText = this.calendar.getDisplayText();
      const timeText = this.time.getDisplayText();

      if (dateText.indexOf('Pick') >= 0) {
         return isNullOrUndefined(this.placeholder) ? 'Pick date and time...' : this.placeholder;
      }

      return `${dateText} ${timeText}`;
   }
   private getDisplayTextForDateMode(): string {
      const dateText = this.calendar.getDisplayText();
      if (dateText.indexOf('Pick') >= 0) {
         return isNullOrUndefined(this.placeholder) ? 'Pick date...' : this.placeholder;
      }
      return dateText;
   }
   private getDropDownOptions(): any {
      let minHeight = 100;
      
      if (this.mode === 'date') {
         minHeight = 300;
      } else if (this.mode === 'datetime') {
         minHeight = 520;
         if (!this.showTimezone) {
            minHeight = 430;
         }
      } else if (this.mode === 'time') {
         if (this.showTimezone) {
            minHeight = 245;
         }
      }

      return {
         minWidth: 280,
         minHeight
       };
   }
   private saveState() {
      this._memento.value = this.value;
      this._memento.timezone = this.timezone;
      this._memento.isActual = true;
   }
   private rollbackState() {
      if (!this._memento.isActual) {
         return;
      }
      this.value = this._memento.value;
      this.timezone = this._memento.timezone;
   }
}
