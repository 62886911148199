import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ClearTradingDataProgressUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-progress-ui-message.interface';
import { ClearTradingData, CtdData } from 'projects/shared-components/shell-communication/operations/shell/clear-trading-data.class';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';
import { AccountDto } from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import {
   ShellConnectionStatusChangedUIMessage
} from 'projects/shared-components/ui-messages/shell-connection-status-changed-ui-message.interface';
import { GetAccounts } from 'projects/shared-components/shell-communication/operations/accounts/get-accounts.class';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { DetectMethodChanges, DetectSetterChanges } from 'projects/shared-components/utils';


@Component({
   selector: 'ets-clear-trading-data',
   templateUrl: 'clear-trading-data.component.html',
   styleUrls: ['clear-trading-data.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearTradingDataComponent implements OnInit, OnDestroy {

   public get canSendRequest(): boolean {
      return this.dataStrategy ||
         this.dataManual ||
         this.dataRiskManager ||
         this.dataGroupExits;
   }

   public constructor(
      private _toastr: ToastrService,
      private _shellClient: ShellClientService,
      private _messageBus: MessageBusService,
      private _sessionService: SessionService,
      private _changeDetector: ChangeDetectorRef
   ) {
   }

   private _wasShownPassively: boolean;
   private _unsubscriber: Subject<any>;

   private _dataStrategy = false;
   get dataStrategy(): boolean {
      return this._dataStrategy;
   }

   @DetectSetterChanges()
   set dataStrategy(v: boolean) {
      this._dataStrategy = v;
   }
   
   private _dataManual = false;
   get dataManual(): boolean { return this._dataManual; }
   
   @DetectSetterChanges()
   set dataManual(v: boolean) {  
      this._dataManual = v; 
   }
   
   private _dataRiskManager = false;
   get dataRiskManager(): boolean {
      return this._dataRiskManager;
   }
   
   @DetectSetterChanges()
   set dataRiskManager(v: boolean) {
      this._dataRiskManager = v;
   }

   private _dataGroupExits = false;
   get dataGroupExits(): boolean {
      return this._dataGroupExits;
   }

   @DetectSetterChanges()
   set dataGroupExits(v: boolean) {
      this._dataGroupExits = v;
   }


   private _dataSessionHistory = false;
   get dataSessionHistory(): boolean {
      return this._dataSessionHistory;
   }

   @DetectSetterChanges()
   set dataSessionHistory(v: boolean) {
      this._dataSessionHistory = v;
   }

   
   private _dataLedgerRecords = false;
   get dataLedgerRecords(): boolean {
      return this._dataLedgerRecords;
   }
   
   @DetectSetterChanges()
   set dataLedgerRecords(v: boolean) {
      this._dataLedgerRecords = v;
   }


   private _terminals: TerminalDto[];
   get terminals(): TerminalDto[] {
      return this._terminals;
   }

   @DetectSetterChanges()
   set terminals(v: TerminalDto[]) {
      this._terminals = v;
   }
   
   private _accounts: AccountDto[];
   get accounts(): AccountDto[] { 
      return this._accounts; 
   }
   
   @DetectSetterChanges()
   set accounts(v: AccountDto[]) {
      this._accounts = v;
   }

   selectedAccounts: AccountDto[] = [];
   selectedTerminals: TerminalDto[] = [];

   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }
   
   private _clearDataProgressMessage: string;
   get clearDataProgressMessage(): string {
      return this._clearDataProgressMessage;
   }

   @DetectSetterChanges()
   set clearDataProgressMessage(v: string) {
      this._clearDataProgressMessage = v;
   }

   private _isClearingData: boolean;
   get isClearingData(): boolean {
      return this._isClearingData;
   }

   @DetectSetterChanges()
   set isClearingData(v: boolean) {
      this._isClearingData = v;
   }
   
   
   private _isVisible = false;
   get isVisible(): boolean {
      return this._isVisible;
   }

   @DetectSetterChanges()
   set isVisible(v: boolean) {
      this._isVisible = v;
   }


   ngOnInit() {
      this._messageBus.of('SpecificDatabaseCleanupStarted')
         .subscribe(() => this.onSpecificDatabaseCleanupStarted());
   }


   @DetectMethodChanges()
   onSpecificDatabaseCleanupStarted(): void {
      this._subscribeToMessages();
      this._wasShownPassively = true;
      this.isClearingData = true;
      this.isVisible = true;
   }


   @DetectMethodChanges({isAsync: true})
   async onShown() {
      if (!this._wasShownPassively) {
         this._resetState();
         this._subscribeToMessages();
         await this._tryLoadData();
      } else {
         // we need to reset this here because it was reset above
         this.isClearingData = true;
      }
   }

   @DetectMethodChanges({isAsync: true})
   async onClearTradingDataClicked(type: 'archive' | 'delete' | 'refresh') {
      const command = new ClearTradingData();

      if (type === 'refresh') {
         command.refreshDb = true;
      } else {
         if (type === 'delete') {
            command.deleteData = true;
         }

         const ctdData: CtdData = {
            strategy: this.dataStrategy,
            manual: this.dataManual,
            riskManager: this.dataRiskManager,
            groupExits: this.dataGroupExits,
            sessionHistory: this.dataSessionHistory,
            ledgerRecords: this.dataLedgerRecords
         };

         command.data = ctdData;

         if (this.selectedTerminals.length > 0) {

            command.terminals = this.selectedTerminals.map(x => x.terminalId);

            if (this.selectedAccounts.length > 0) {
               command.accounts = this.selectedAccounts.map(x => x.accountId);
            }
         }
      }

      this.clearDataProgressMessage = 'Processing ...';
      this.isClearingData = true;
      await this._shellClient.processCommand(command);
   }

   
   ngOnDestroy(): void {
   }

   
   @DetectMethodChanges({delay: 25})
   onHidden() {
      setTimeout(() => {
         this.isVisible = false;
         this._resetState();
      });
   }


   @DetectMethodChanges()
   private _onClearTradingDataUIMessage(msg: ClearTradingDataUIMessage) {
      this.isClearingData = false;

      if (this.isVisible) {
         if (!msg.hasErrors) {
            this.onHidden();
         }
      }
   }

   @DetectMethodChanges()
   private _onClearTradingDataProgressMessage(msg: ClearTradingDataProgressUIMessage) {
      this.clearDataProgressMessage = `${msg.message} - ${(msg.progress * 100).toFixed(2)}%`;
   }

   @DetectMethodChanges()
   private _resetState() {
      this._wasShownPassively = false;

      this.dataStrategy = this.dataManual = this.dataRiskManager
         = this.dataGroupExits = this.dataSessionHistory = this.dataLedgerRecords = false;

      this.selectedAccounts = [];
      this.selectedTerminals = [];

      this.isClearingData = false;
      this.clearDataProgressMessage = null;

      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }


   @DetectMethodChanges()
   private _onShellConnectionStatusChangedUIMessage(msg: ShellConnectionStatusChangedUIMessage) {
      if (msg.isConnected) {
         return;
      }
      this.isClearingData = false;
      this._toastr.error('Server connection lost during operation. Please repeat command after connection restored');
      this.onHidden();
   }

   
   @DetectMethodChanges({isAsync: true})
   private async _tryLoadData() {
      try {
         this.isLoading = true;
         const query = new GetAccounts();
         const dtos = await this._shellClient.processQuery<AccountDto[]>(query);
         this.accounts =
            dtos
               .sort((a, b) => b.accountCode.localeCompare(a.accountCode))
               .sort((a, b) => a.isPaperTrading ? -1 : 1);

         const terminals = this._sessionService.loginResult.availableTerminals
            .filter(x => !x.isProxy);

         this.terminals = terminals.sort((a, b) => a.displayName.localeCompare(b.displayName));

      } catch (e) {
         this._toastr.error('"Clear Trading Data" dialog loaded with errors');
         const data = { error: e.stack || e };
         console.error('Failed to load clear-trading-data dialog', data);
      } finally {
         this.isLoading = false;
      }
   }

   private _subscribeToMessages() {
      this._unsubscriber = new Subject<any>();

      this._messageBus.of<ClearTradingDataProgressUIMessage>('ClearTradingDataProgressUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onClearTradingDataProgressMessage(msg.payload));


      this._messageBus.of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(msg => setTimeout(() => this._onClearTradingDataUIMessage(msg.payload), 250));


      this._messageBus.of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(
            takeUntil(this._unsubscriber)
         ).subscribe(msg => this._onShellConnectionStatusChangedUIMessage(msg.payload));
   }
}
