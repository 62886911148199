import {Injectable} from '@angular/core';
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {Subject} from "rxjs";
import {UserSettingsService} from "../../user-settings.service";

export interface PackageComparisonSettings {
    portfolioId?: string;
    userId?: string;
    templates?: string[];
    adjustmentCall?: string[];
    adjustmentPut?: string[];
    hedgesCall?: string[];
    hedgesPut?: string[];
}

export interface HedgeBoardSettings {
    portfolioId?: string;
    userId?: string;
    templates?: string[];
    adjustmentCall?: string[];
    adjustmentPut?: string[];
    hedgesCall?: string[];
    hedgesPut?: string[];
    offsetsCall?: number[];
    offsetsPut?: number[];
}


const PackageComparisonSettingsStorageKey = 'pkg-cmprsn.settings';
const HedgeBoardSettingsStorageKey = 'pkg-cmprsn.settings.hedgeboard';

@Injectable()
export class PackageComparisonSettingsService {

    constructor(
        private _userSettingsService: UserSettingsService
    ) {
    }

    settingsUpdated = new Subject<PackageComparisonSettings>();
    hedgeboardSettingsUpdated = new Subject<HedgeBoardSettings>();

    save(settings: PackageComparisonSettings) {
        this._userSettingsService
            .setValue(PackageComparisonSettingsStorageKey, settings, settings?.userId);
        this.settingsUpdated.next(settings);
    }

    saveHedgeBoardSettings(settings: HedgeBoardSettings) {
        this._userSettingsService
            .setValue(HedgeBoardSettingsStorageKey, settings, settings?.userId);
        this.hedgeboardSettingsUpdated.next(settings);
    }

    get(portfolio: ApgPortfolio): PackageComparisonSettings {

        const userId = portfolio?.userId;

        const settings = this._userSettingsService.getValue<PackageComparisonSettings>(
            PackageComparisonSettingsStorageKey,
            userId
        );

        return settings;
    }

    getHedgeBoardSettings(portfolio: ApgPortfolio): HedgeBoardSettings {

        const settings = this._userSettingsService.getValue<HedgeBoardSettings>(
            HedgeBoardSettingsStorageKey,
            portfolio?.userId
        );

        return settings;
    }
}