import { ChangeDetectorRef } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { MessageBusService } from '../message-bus.service';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { PortfolioItemDto } from '../shell-communication/shell-dto-protocol';
import { StrategiesService } from '../strategies/strategies.service';
import { TimestampsService } from '../timestamps.service';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import { DetectSetterChanges, tickersMatch } from '../utils';
import { AdjustmentControlPanelComponent } from './adjustment-control-panel.component';
import { AdjustmentBucketConfig } from './bucket-config';
import { PortfolioBlockViewModel } from './overview-blocks/portfolio-block/portfolio-block.model';
import { getPortfolioBlockGridOptions } from './portfolio-block-grid-options';


export class PortfolioBlock {
   private _bucketConfig: AdjustmentBucketConfig;

   constructor(private readonly _comp: AdjustmentControlPanelComponent) {

      this.gridOptions = getPortfolioBlockGridOptions.bind(this)('large');
      this.smallGridOptions = getPortfolioBlockGridOptions.bind(this)('small');
   }

   
   private _grid: GridReadyEvent;
   private _smallGrid: GridReadyEvent;

   private _portfolioItems: PortfolioItemDto[] = [];

   get isVisible(): boolean {
      return !isNullOrUndefined(this.displayName);
   }
   blockId: string;
   displayName: string;
   sessionPnL: number;
   accumulatedPnL: number;

   get overviewBlockColor(): 'green' | 'red' | 'grey' {
      return this.getBlockColor();
   }

   get height(): number {
      let h = 170;

      const extr = this._portfolioItems.length * 22;

      h += extr;

      return h;
   }

   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }

   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   get gridApi(): GridApi {
      return this._grid ? this._grid.api : null;
   }

   get messageBus(): MessageBusService {
      return this._comp.messageBus;
   }

   get timestampsService(): TimestampsService {
      return this._comp.timestampsService;
   }

   get unsubscriber(): Subject<void> {
      return this._comp.unsubscriber;
   }

   get strategyService(): StrategiesService {
      return this._comp.strategyService;
   }

   

   gridOptions: GridOptions;
   smallGridOptions: GridOptions;


   private _underlying: TradingInstrument;
   get underlying(): TradingInstrument {
      return this._underlying;
   }

   @DetectSetterChanges()
   set underlying(v: TradingInstrument) {
      this._underlying = v;
   }


   onGridReady(args: GridReadyEvent) {
      this._grid = args;
      if (this._portfolioItems) {
         this._grid.api.setRowData(this._portfolioItems);
      }
   }
   
   onSmallGridReady(args: GridReadyEvent) {
      this._smallGrid = args;
      if (this._portfolioItems) {
         this._smallGrid.api.setRowData(this._portfolioItems);
      }
   }

   setData(portfolioItems: PortfolioItemDto[]) {
      this._portfolioItems = portfolioItems;
            
      if (this._grid) {
         this._grid.api.setRowData(portfolioItems);
      }
      
      if (this._smallGrid) {
         this._smallGrid.api.setRowData(portfolioItems);
      }

      const tickers = portfolioItems.map(x => x.ticker);
      this._comp.lastQuoteCache.subscribeTickers(tickers);
   }

   //
   getViewModel(): PortfolioBlockViewModel {
      return {
         portfolioItems: this._portfolioItems
      };
   }

   //
   setBucket(bucketConfig: AdjustmentBucketConfig) {
      this._bucketConfig = bucketConfig;
   }

   //
   reset() {
      
      this.underlying = null;
      
      this.displayName = null;
      
      if (this._grid) {
         this._grid.api.setRowData([]);
         this._portfolioItems = [];
      }
   }

   //
   private getBlockColor(): 'green' | 'red' | 'grey' {

      if (!this._portfolioItems || this._portfolioItems.length === 0) {
         return 'grey';
      }

      return 'green';

   }
}
