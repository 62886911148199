import {ProtocolCommand} from '../../../service-model/protocol-command.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class SetShellBaseTime implements ProtocolCommand {
  public constructor(
    public readonly country: string,
    public readonly timeZone: string,
    public readonly isReset: boolean,
    public readonly baseTime?: string
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.SetShellBaseTime`;
  }
}
