import {  formatNumber } from '@angular/common';
import { CellClassParams, ColDef, Color, GridApi, RowDoubleClickedEvent, RowNode, RowSelectedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { AG_SYMBOL_COL_MIN_WIDTH, centeredColumnDef, 
   defaultLoadingOverlayTemplate, 
   defaultMoneyCellDefinition, 
   defaultNumberCellFormatter, 
   defaultPriceCellFormatter, 
   liveQuoteFormatter} from '../../ag-grid-contrib';
import { environment } from '../../environments/environment';
import { EtsConstants } from '../../ets-constants.const';
import { PortfolioItemType } from '../portfolios.model';
import { PortfolioItemsComponent } from './portfolio-items.component';
import { BucketRoleColor, PortfolioItemDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { PortfolioItemsGroupRowRendererComponent } from './portfolio-items-archived-group-row';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { daysToExpiration, isAdjustmentAlgo, isCashSettledOptionTicker, isHedgingAlgo, isInstrumentExpired } from 'projects/shared-components/utils';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { StrategyState } from 'projects/shared-components/strategies/strategy-state.enum';
import { PositionFlags } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { DateTime } from 'luxon';

export function getPortfolioItemsGridModel(this: PortfolioItemsComponent): GridOptions {
   
   const isDashboard = environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId;

   const gridOptions: GridOptions = {
      rowData: [],

      defaultColDef: centeredColumnDef,
      
      columnDefs: getColumnDefs(this),

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,
      
      immutableData: true,

      rowClass: 'ets-text-centered',
      
      rowSelection: 'single',

      rowGroupPanelShow: 'never',

      groupUseEntireRow: false,

      suppressAggFuncInHeader: true,

      tooltipShowDelay: 1000,

      autoGroupColumnDef: getAutoGroupColumnDefForSymbolColumn(
         'ticker', this.messageBus, this.timestampsService, this.unsubscriber, () => this.gridApi
      ),
      
      frameworkComponents: {
         groupRowRenderer: PortfolioItemsGroupRowRendererComponent
      } as any,
      
      onGridReady: (args) => this.onGridReady(args),

      getRowNodeId: (data: PortfolioItemDto) => {
         return data.portfolioItemId;
      },

      postSort: (rowNodes: RowNode[]) => {

         rowNodes.sort()

         const nextInsertPos = rowNodes.length;
         
         for (let i = rowNodes.length - 1; i >= 0; i--) {
             const pfItem: PortfolioItemDto = rowNodes[i].data;
             if (pfItem && pfItem.isHedgingItem) {
                if (i !== nextInsertPos) {
                   rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                }
             }
         }
      },
      
      getContextMenuItems: (args: GetContextMenuItemsParams) => {
      
         if (!args.node) {
            return;
         }

         if (args.node.group) {
            return;
         }

         const pfItem = args.node.data as PortfolioItemDto;
         
         if (!pfItem) {
            return;
         }

         const menu = [];

         if (pfItem.itemType !== PortfolioItemType.Strategy) {
            if (true) {
               menu.push({
                  name: 'Target Strike...',
                  action: () => this.showRollLegDialog(args.node.data),
                  disabled: !args.node
               });
               menu.push({
                  name: 'Attach to this leg',
                  action: () => this.changeAttachedLeg(args.node.data),
                  disabled: !args.node
               });
               menu.push('separator');
            }

            menu.push(
               {
                  name: 'Increase/Decrease Position',
                  disabled: (() => {
                     const noData = !args.node || !args.node.data;
                     if (noData) {
                        return true;
                     }
                     const data = args.node.data as PortfolioItemDto;
   
                     if (data.isArchived) {
                        return true;
                     }
   
                     return data.itemType === PortfolioItemType.Strategy;
                  })(),
                  action: () => this.showAdjustPositionDialog(args.node.data)
               },
               {
                  name: 'Override Position',
                  disabled: (() => {
                     const noData = !args.node || !args.node.data;
                     if (noData) {
                        return true;
                     }
                     const data = args.node.data as PortfolioItemDto;
   
                     if (data.isArchived) {
                        return true;
                     }
   
                     return data.itemType === PortfolioItemType.Strategy;
                  })(),
                  action: () => this.showOverridePositionDialog(args.node.data)
               },
               {
                  name: 'Expire Position',
                  disabled: (() => {
                     const noData = !args.node || !args.node.data;
                     if (noData) {
                        return true;
                     }
                     const data = args.node.data as PortfolioItemDto;
   
                     if (data.isArchived) {
                        return true;
                     }
   
                     if (!data.ticker.startsWith('@')) {
                        return true;
                     }
                     
                     const ti = this.tradingInstrumentsService.getInstrumentByTicker(data.ticker);
   
                     const isExpired = isInstrumentExpired(ti);
               
                     if (!isExpired) {
                        return true;
                     }
   
                     return false;
                  })(),
                  action: () => this.showExpirationDialog(args.node.data)
               },
               {
                  name: 'Toggle Position Flags...',
                  subMenu: getPositionFlagsSubMenu(args, this)
               },
               'separator',
               {
                  name: 'Attach Adjustment Strategy',
                  action: () => this.showAttachAdjustmentDialog(args.node.data),
                  disabled: !args.node || !args.node.data || args.node.data.isArchived || args.node.data.itemType === PortfolioItemType.Strategy
               },
               'separator',
               {
                  name: 'Remove Item ...',
                  disabled: !args.node || args.node.aggData || args.node.data.isArchived || args.node.data.isGroupItem,
                  subMenu: [
                     {
                        name: 'Exclude',
                        action: () => this.removeItem(args.node.data, 'exclude'),
                        disabled: !args.node || !args.node.data || args.node.data.aggData
                     },
                     {
                        name: 'Transfer',
                        action: () => this.removeItem(args.node.data, 'transfer'),
                        disabled: !args.node || !args.node.data || args.node.data.aggData
                     },
                     {
                        name: 'Archive',
                        action: () => this.removeItem(args.node.data, 'archive'),
                        disabled: !args.node || !args.node.data || args.node.data.aggData || args.node.data.netPosition !== 0
                     }
                  ]
               },
            );
         } else {

            const strategy = this.strategyService.getById(pfItem.portfolioItemId);
            
            menu.push(
               {
                  name: 'Update Strategy',
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
                  action: () => this.editStrategy(strategy, 'update')
               },
               'separator',
               {
                  name: 'Enable Strategy',
                  action: () => this.enableStrategy(strategy),
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state
               },
               {
                  name: 'Disable Strategy',
                  action: () => this.disableStrategy(strategy),
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) !== strategy.state
               },
               {
                  name: 'Set On Schedule',
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
                  action: () => this.setToStartStrategy(strategy)
               },
               'separator',
               {
                  name: 'Roll Next Month',
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
                  action: () => this.rollNextMonth(strategy)
               },
               'separator',
               {
                  name: 'Show History',
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
                  action: () => this.requestSessionHistoryDialog(strategy)
               },
               {
                  name: 'Clear Trading Data',
                  disabled: isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
                  action: () => this.clearTradingDataForStrategy(strategy)
               },
            );

            if (strategy.algoId === EtsConstants.algorithms.interestAlgoId) {
               const mi = {
                  name: 'Add Interest Record Manually',
                  action: () => this.addInterestRecordManually(args.node.data)
               };
               menu.push(mi);
            }

            if (isAdjustmentAlgo(strategy.algoId) || isHedgingAlgo(strategy.algoId)) {
               menu.push(
                  'separator',
                  {
                     name: 'Remove Item',
                     disabled: !args.node || args.node.aggData || args.node.data.isArchived || args.node.data.isGroupItem || 
                        isNullOrUndefined(strategy) || (strategy.state & StrategyState.Enabled) === strategy.state,
                     action: () => this.removeItem(args.node.data, 'archive')
                  },
                  'separator'
               );
            } else {
               menu.push(
                  'separator',
                  {
                     name: 'Remove Item...',
                     disabled: !args.node || args.node.aggData || args.node.data.isArchived || args.node.data.isGroupItem,
                     subMenu: [
                        {
                           name: 'Exclude',
                           action: () => this.removeItem(args.node.data, 'exclude'),
                           disabled: !args.node || !args.node.data || args.node.data.aggData
                        },
                        {
                           name: 'Transfer',
                           action: () => this.removeItem(args.node.data, 'transfer'),
                           disabled: !args.node || !args.node.data || args.node.data.aggData
                        },
                        {
                           name: 'Archive',
                           action: () => this.removeItem(args.node.data, 'archive'),
                           disabled: !args.node || !args.node.data || args.node.data.aggData
                        }
                     ]
                  },
                  'separator'
               );
            }

            const overrideActions = [];
            overrideActions.push({
               name: 'Disable',
               disabled: isNullOrUndefined(strategy),
               action: () => this.overrideExitStrategy(strategy)
            });
   
            overrideActions.push({
               name: 'Reset',
               disabled: isNullOrUndefined(strategy),
               action: () => this.overrideResetStrategy(strategy)
            });

            menu.push(
               {
                  name: 'Override',
                  subMenu: overrideActions
               }
            );
         }
         
         menu.push(
            'separator',
            {
               name: this.hideClosedPositions ? 'Show Closed Positions' : 'Hide Closed Positions',
               action: () => {
                  this.hideClosedPositions = !this.hideClosedPositions;
                  args.api.resetRowHeights();
               }
            }
         )

         menu.push(
            'separator',
            {
               name: 'Size To Fit',
               action: () => args.api.sizeColumnsToFit()
            },
            'autoSizeAll',
            {
               name: 'Bucket Context',
               icon: '<i class="fas fa-info-circle"></i>',
               action: () => this.showBucketContextHint()
            }
         );

         return menu;
      },

      getRowHeight: (args) => {
         if (!this.hideClosedPositions) {
            return undefined;
         }

         const item = args.data as PortfolioItemDto;

         if (isNullOrUndefined(item)) {
            return undefined;
         }

         if (item.itemType === PortfolioItemType.Strategy) {
            return undefined;
         }

         if (item.netPosition !== 0) {
            return undefined;
         }

         return this.hideClosedPositions ? 0 : undefined;
      },

      getRowStyle: (args) => {
         
         if (!args.node) {
            return;
         }

         if (!args.node.data) {
            return;
         }

         if (args.node.data.isHedgingItem) {
            return { 'font-weight': 'bold', 'font-family': 'cursive' };
         }

         if (args.node.data.isUnderAdjustment) {
            return { border: '1px dashed yellow' };
         } else {
            return null;
         }

      },

      onRowSelected: (args: RowSelectedEvent) => {
         
         if (!args.node) {
            return;
         }

         if (!args.node.isSelected()) {
            return;
         }

         if (args.node.group || !args.node.data) {
            this.resetBaseLegHighlighting();
         } else {
            this.onBucketItemSelected(args.node.data);
         }

      },
      
      onRowDoubleClicked: (args: RowDoubleClickedEvent) => {
         if (!args.node) {
            return;
         }

         if (!args.node.isSelected()) {
            return;
         }

         if (args.node.group || !args.node.data) {
            this.resetBaseLegHighlighting();
         } else {
            this.onBucketItemSelected(args.node.data);
         }
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged(),
   };

   return gridOptions;
}

function getColumnDefs(comp: PortfolioItemsComponent): Partial<ColDef>[] {

   const colDefs: ColDef[] = [];

   const isArchivedCol: ColDef = {
      field: 'isArchived',
      rowGroup: true,
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return undefined;
         }

         return params.data.isArchived ? 'Archived Items' : 'Active Items';
      }
   };

   const underlyingCol: ColDef = {
      headerName: 'Underlying',
      field: 'underlyingDisplayName',
   };

   const itemTypeCol: ColDef = {
      headerName: 'Type',
      field: 'itemType',
      valueFormatter: (args: ValueFormatterParams) => {
         if (!args.data) {
            return args.value;
         }

         if (args.value > 0) {
            let optType = PortfolioItemType[args.value];
            
            if (args.value >= 3) {
               if (isCashSettledOptionTicker(args.data.ticker)) {
                  optType += ' (E)';
               }
            } 

            return optType;
            
         }

         const data = args.data as PortfolioItemDto;

         if (!data || data.itemType !== PortfolioItemType.Strategy) {
            return PortfolioItemType[args.value];
         }

         return data.algoName;
      },
      cellStyle: (args: CellClassParams) => {
         
         const pfItem = args.data as PortfolioItemDto;

         if (isNullOrUndefined(pfItem)) {
            return undefined;
         }
      
         const style = { 'border-bottom': null, 'color': 'inherited' };

         if (pfItem.bucketRole && pfItem.netPosition !== 0) {
            style.color = BucketRoleColor[pfItem.bucketRole];
         }


         let state = pfItem.strategyState;

         if (isNullOrUndefined(state)) {
            const strategy = comp.strategyService.getById(pfItem.portfolioItemId);

            if (isNullOrUndefined(strategy)) {
               return style;
            }

            state = strategy.state;
         }

         const enabled = (state & StrategyState.Enabled) === state;
         const dead = (state & StrategyState.Dead) === state;

         if (enabled) {
            style['border-bottom'] = '2px solid limegreen';
         } else {
            style['border-bottom'] = '2px solid ' + (dead ? 'red' : 'grey');
         }
         
         return style;
      },
      minWidth: 100
   };

   const positionCol: ColDef = {
      headerName: 'Position',
      field: 'netPosition',
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.netPosition;
      },
      comparator: (valueA, valueB, nodeA: RowNode, nodeB: RowNode, isDescending: boolean) => {
         const itemA = nodeA.data as PortfolioItemDto;
         const itemB = nodeB.data as PortfolioItemDto;

         if (isNullOrUndefined(itemA) && isNullOrUndefined(itemB)) {
            return 0;
         }
         
         if (isNullOrUndefined(itemA)) {
            return -1;
         }

         if (isNullOrUndefined(itemB)) {
            return 1;
         }

         if (itemA.netPosition === 0 && itemB.netPosition === 0) {
            return itemA.lastTraded > itemB.lastTraded ? -1 : 1;
         }

         if (itemA.netPosition !== 0 && itemB.netPosition === 0) {
            return -1;
         }

         if (itemA.netPosition === 0 && itemB.netPosition !== 0) {
            return 1;
         }

         return itemA.lastTraded > itemB.lastTraded ? -1 : 1;

      },
      valueFormatter: defaultNumberCellFormatter
   };

   const avgPxCol: ColDef = {
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data || isNaN(params.data.avgPx)) {
            return null;
         }
         return params.data.avgPx;
      },
      valueFormatter: defaultPriceCellFormatter,
   };

   const liveQuoteCol: ColDef = {
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: liveQuoteFormatter,
   };

   const sessionPnLCol: ColDef = Object.assign(
      {
         headerName: 'Sess. P&L',
         field: 'sessionPnL',
         minWidth: 117,
         aggFunc: 'sum'
      },
      defaultMoneyCellDefinition,
   );

   const accumulatedPnLCol: ColDef = Object.assign(
      {
         headerName: 'Acc. P&L',
         field: 'accumulatedPnL',
         minWidth: 117,
         aggFunc: 'sum'
      },
      defaultMoneyCellDefinition
   );

   const totalDeltaCol: ColDef = {
      headerName: 'Delta',
      field: 'delta',
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
         if (!params.value) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalThetaCol: ColDef = {
      headerName: 'Theta',
      field: 'theta',
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
         if (!params.value) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const totalGammaCol: ColDef = {
      headerName: 'Gamma',
      field: 'gamma',
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
         if (!params.value) {
            return '';
         }
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };
   
   const totalVegaCol: ColDef = {
      headerName: 'Vega',
      field: 'vega',
      aggFunc: 'sum',
      valueFormatter: (params: ValueFormatterParams) => {
         if (!params.value) {
            return '';
         }
         
         return formatNumber(params.value || 0, 'en-US', '1.0-0');
      }
   };

   const accountNameCol: ColDef = {
      headerName: 'Account',
      field: 'accountCode',
      minWidth: 180,
   };

   const terminalNameCol: ColDef = {
      headerName: 'Terminal',
      field: 'terminalName',
      minWidth: 215,
   };

   const portfolioNameCol: ColDef = {
      headerName: 'Portfolio',
      field: 'portfolioName',
      minWidth: 215,
   };

   const comboNameCol: ColDef = {
      headerName: 'Combo',
      field: 'comboName',
      minWidth: 215,
   };

   const comboGroupNameCol: ColDef = {
      headerName: 'Combo Group',
      field: 'comboGroupName',
      minWidth: 215,
   };

   const lastMatchPxCol: ColDef = {
      headerName: 'Last Match Px.',
      field: 'lastMatchPx',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.lastMatchPx;
      },
      valueFormatter: defaultPriceCellFormatter
   };

   const openPrice: ColDef = {
      headerName: 'Open Px.',
      field: 'openPrice',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data || isNaN(params.data.openPrice)) {
            return null;
         }
         return params.data.openPrice;
      },
      valueFormatter: defaultPriceCellFormatter
   };
   
   colDefs.push(itemTypeCol);
   colDefs.push(sessionPnLCol);
   colDefs.push(accumulatedPnLCol);
   colDefs.push(positionCol);
   colDefs.push(avgPxCol);
   colDefs.push(liveQuoteCol);
   colDefs.push(lastMatchPxCol);
   colDefs.push(openPrice);
   colDefs.push(accountNameCol);
   colDefs.push(terminalNameCol);
   colDefs.push(portfolioNameCol);
   colDefs.push(comboNameCol);
   colDefs.push(comboGroupNameCol);
   colDefs.push(totalDeltaCol);
   colDefs.push(totalThetaCol);
   colDefs.push(totalGammaCol);
   colDefs.push(totalVegaCol);
   colDefs.push(underlyingCol);
   colDefs.push(isArchivedCol);

   return colDefs;
}

function getAutoGroupColumnDefForSymbolColumn(symbolField: string, messageBus: MessageBusService, timestampService: TimestampsService, unsubscriber: Subject<void>, gridApiResolver: () => GridApi): ColDef {

   messageBus.of('SessionEndedDto')
      .pipe(
         takeUntil(unsubscriber)
      )
      .subscribe(msg => {

         const gridApi = gridApiResolver();

         if (gridApi) {
            gridApi.refreshCells({ columns: [symbolField], force: true });
         }
      });

   const symbolCol: ColDef = {
      headerName: 'Symbol',
      field: symbolField,
      minWidth: AG_SYMBOL_COL_MIN_WIDTH,
      tooltipField: symbolField,
      flex: 1,
      cellRendererParams: {
         suppressCount: true,
         innerRenderer: params => {
            if (params.node.group) {
               const isRootLevel = params.node.level === 0;
               if (isRootLevel) {
                  // Grand Total Cells
                  const color = params.value === 'Active Items' ? '#e8e2e2' : 'yellow';
                  return `<span style="color:${color};">${params.value}</span>`;
               }
            }
            // Non-Group Cells
            return params.value;
         },
         footerValueGetter: params => {
            const isRootLevel = params.node.level === -1;
            if (isRootLevel) {
               return 'Grand Total';
            }
            return `Sub Total: (${params.value})`;
         },
      },
      valueGetter: (params: ValueGetterParams) => {

         if (params.node.footer) {
            return null;
         }

         if (params.node.group) {
            return null;
         }

         if (!params.data) {
            return null;
         }

         let displayName: string;

         const ticker: string = params.data[symbolField];

         if (!ticker.startsWith('@')) {

            displayName = params.data.tickerDisplayName;
            
            if (params.data.lastOverSessionReset) {
               displayName = `${'\u0027'} ${displayName}`;
            }

         } else {

            const parts = ticker.split(' ');

            // @ES ESH1 3365 Call 2020-12-25
            const sExpDate = parts[4];
            const diffInDays = daysToExpiration(sExpDate);

            displayName = `${params.data.tickerDisplayName}`;
            
            if (isCashSettledOptionTicker(params.data.ticker)) {
               displayName = displayName.replace(' (E)', '');
            }

            let modifiers = '';
            
            if (params.data.lastOverSessionReset) {
               modifiers = `${'\u0027'}${modifiers}`;
            }
            
            if (modifiers) {
               displayName = `${modifiers} ${displayName}`;
            }

            displayName = `${displayName} (${diffInDays}d)`;

         }

         return displayName;
      },
      cellStyle: (args: CellClassParams) => {
         const result = {
            color: 'inherit'
         };

         const row = args.data as PortfolioItemDto;
         if (!row) {
            return result;
         }
         
         if (row.bucketRole) {
            result.color = BucketRoleColor[row.bucketRole];
         }
         
         return result;
      }
   };

   return symbolCol;
}

function getPositionFlagsSubMenu(args: GetContextMenuItemsParams, comp: PortfolioItemsComponent) {
   const menu = [];
   menu.push(
      {
         name: 'Last Over Session Reset',
         action: () => comp.togglePositionFlag(args.node.data, PositionFlags.LastOverSessionReset),
         disabled: !args.node || !args.node.data || args.node.data.isArchived || args.node.data.itemType === PortfolioItemType.Strategy
      }
   );
   
   if (args.node) {
      if (!args.node.group) {
         if (args.node.data) {
            const data: PortfolioItemDto = args.node.data;
            const available = isCashSettledFlagAvailable(data, comp.sessionService);
            if (available) {
               menu.push(
                  {
                     name: 'Cash Settled Expiration',
                     action: () => comp.toggleCashSettled(args.node.data),
                     disabled: !args.node || !args.node.data || args.node.data.isArchived || args.node.data.itemType === PortfolioItemType.Strategy
                  }
               );
            }
         }
      }
   }

   return menu;
}

function isCashSettledFlagAvailable(pfItem: PortfolioItemDto, ss: SessionService): boolean {
   
   if (!pfItem.ticker.startsWith('@')) {
      return false;
   }

   const accounts = ss.loginResult.availableAccounts;
   const itemAccount = accounts.find(x => x.accountId === pfItem.accountId);
   
   if (isNullOrUndefined(itemAccount)) {
      return false;
   }

   return itemAccount.isPaperTrading;
}
