<div class="layout-container">

  <ng-container *ngTemplateOutlet="layout; context: {$implicit: rootSection.layoutRoot}"></ng-container>

  <ng-template #layout let-section>

    <as-split class="split" [direction]="section.direction" [gutterSize]="5" (dragEnd)="onSizeChanged($event, section)">

      <ng-container *ngFor="let sec of section.subsections">

        <ng-container *ngTemplateOutlet="areaTpl; context: {area: sec}"></ng-container>

        <ng-template #simpleTpl let-area="area">
          <as-split-area [size]="area.size" [order]="area.order" [visible]="!area.isHidden">
            <ets-workspace [workspaceId]="area.workspaceId"
                           [layoutTabId]="area.layoutTabId"
                           (splitArea)="onSplitArea($event, area)"
                           (expandAreaRequest)="onExpandAreaRequest(area)"
                           [isInitial]="area.isInitial"
            ></ets-workspace>
          </as-split-area>
        </ng-template>

        <ng-template #complexTpl let-area="area">
          <as-split-area [size]="area.size" [order]="area.order" [visible]="!area.isHidden">
            <ng-container *ngTemplateOutlet="layout; context: {$implicit: sec}"></ng-container>
          </as-split-area>
        </ng-template>

        <ng-template #areaTpl let-area="area">
          <ng-container *ngIf="!area.subsections">
            <ng-container *ngTemplateOutlet="simpleTpl; context: {area: area}"></ng-container>
          </ng-container>

          <ng-container *ngIf="area.subsections">
            <ng-container *ngTemplateOutlet="complexTpl; context: {area: this.area}"></ng-container>
          </ng-container>
        </ng-template>

      </ng-container>

    </as-split>

  </ng-template>

</div>
