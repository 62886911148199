<dx-popup (onHidden)="this.onHidden()" [dragEnabled]="true"
          [resizeEnabled]="true"
          [visible]="this.visible"
          [width]="510"
          [height]="520"
          title="Price Box Section Settings"
>
  <div *dxTemplate="let data of 'content'" class="container">

    <div class="settings">
      
      <div class="col">
        <fieldset class="section">
          <legend class="section-header">What To Show</legend>
          <div class="option">
            <div class="label">Spread Offset</div>
            <div class="input">
              <dx-check-box [(value)]="this.showSpreadOffset"></dx-check-box>
            </div>
          </div>
    
          <div class="option">
            <div class="label">Spread Width</div>
            <div class="input">
              <dx-check-box [(value)]="this.showSpreadWidth"></dx-check-box>
            </div>
          </div>
    
          <div class="option">
            <div class="label">2nd Spread Offset</div>
            <div class="input">
              <dx-check-box [(value)]="this.showSecondSpreadOffset"></dx-check-box>
            </div>
          </div>
    
          <div class="option">
            <div class="label">2nd Spread Width</div>
            <div class="input">
              <dx-check-box [(value)]="this.showSecondSpreadWidth"></dx-check-box>
            </div>
          </div>
    
          <div class="option">
            <div class="label">Protective Option Offset</div>
            <div class="input">
              <dx-check-box [(value)]="this.showProtectiveOptionOffset"></dx-check-box>
            </div>
          </div>
    
          <div class="option">
            <div class="label">2nd Protective Option Offset</div>
            <div class="input">
              <dx-check-box [(value)]="this.showSecondProtectiveOptionOffset"></dx-check-box>
            </div>
          </div>
        </fieldset>
    
        <fieldset class="section">
          <legend class="section-header">
            When And How To Show
          </legend>
          <div class="option">
            <div class="label">Show Offsets</div>
            <div class="input">
              <dx-switch [(value)]="this.showOffsets"></dx-switch>
            </div>
          </div>
          <div class="option">
            <div class="label">Show Mode</div>
            <div class="input">
              <dx-select-box [items]="this.availableShowModes" [(value)]="this.showMode">
              </dx-select-box>
            </div>
          </div>
          <div class="option">
            <div class="label">Show Single Value If Both Match</div>
            <div class="input">
              <dx-switch [(value)]="this.showSingleValue"></dx-switch>
            </div>
          </div>
    
        </fieldset>
    
        <fieldset class="section filters">
          <legend class="section-header">
            Filters
          </legend>
          <div class="option">
            <div class="label">Spread Offset</div>
            <div class="input">
              <dx-number-box [showClearButton]="true" [min]="0"
                             [(value)]="this.spreadOffsetFilter"
                             format="$#,##0.##"
              ></dx-number-box>
            </div>
          </div>
          <div class="option">
            <div class="label">Spread Width</div>
            <div class="input">
              <dx-number-box [showClearButton]="true" [min]="0"
                             [(value)]="this.spreadWidthFilter"
                             format="$#,##0.##"
              ></dx-number-box>
            </div>
          </div>
        </fieldset>
        
      </div>
      
      <div class="col">
        <fieldset class="section">
          <legend class="section-header">
            Miscellaneous
          </legend>
          <div class="option">
            <div class="label">Show IV</div>
            <div class="input">
              <dx-switch [(value)]="this.showImpliedVolatility"></dx-switch>
            </div>
          </div>
          <div class="option">
            <div class="label">Show Zones Grid Arrow</div>
            <div class="input">
              <dx-switch [(value)]="this.showZonesGridArrow"></dx-switch>
            </div>
          </div>
        </fieldset>
        
        <fieldset class="section favorite-adjustments">
          <legend class="section-header">
            Favorite Adjustments
          </legend>
          <div class="favorites-list">
            <div class="fav-adj" *ngFor="let fav of this.favoriteAdjustments">
              <div class="remove" (click)="this.removeFavoriteAdjustment(fav)">
                <span>x</span>
              </div>
              <div class="adj one">
                <dx-select-box [items]="this.availableCallAdjustments"
                               [(value)]="fav.oneAdjustment"
                               [inputAttr]="{style: 'text-align: center;'}"
                               (onValueChanged)="this.onValueChanged($event)"
                ></dx-select-box>
              </div>
              <div class="plus">+</div>
              <div class="adj two">
                <dx-select-box [items]="this.availablePutAdjustments"
                               [(value)]="fav.twoAdjustment"
                               [inputAttr]="{style: 'text-align: center;'}"
                ></dx-select-box>
              </div>
            </div>
          </div>
          <div class="add-btn">
            <dx-button text="Add" (onClick)="this.addFavoriteAdjustment()"></dx-button>
          </div>
        </fieldset>
        
      </div>
    </div>
    

    <div class="buttons">
      <div class="button save">
        <dx-button text="Save" width="100%" (onClick)="this.saveSettings()"></dx-button>
      </div>
      <div class="button close">
        <dx-button text="Close" width="100%" (onClick)="this.close()"></dx-button>
      </div>
    </div>

  </div>
</dx-popup>
