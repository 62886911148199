import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { TerminalDto } from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, GetAvailableBucketsReply, PortfolioDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetAvailableBuckets, BucketItemRelocateType as BucketItemRelocateType, BucketItemTransferSpec, BucketItemTransferType, BucketSpec, TransferBucket, TransferBucketItem, DuplicateBucket, DuplicateBucketSpec } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { DetectMethodChanges, DetectSetterChanges, isNullOrUndefined } from 'projects/shared-components/utils';
import { DuplicateBucketDialogConfig } from './duplicate-bucket-dialog.model';

@Component({
   selector: 'ets-duplicate-bucket-dialog',
   templateUrl: './duplicate-bucket-dialog.component.html',
   styleUrls: ['./duplicate-bucket-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class DuplicateBucketDialogComponent implements OnInit, AfterViewInit {

   constructor(
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _changeDetector: ChangeDetectorRef,
      private _sessionService: SessionService) {

   }

   private _availableBuckets: GetAvailableBucketsReply;

   //

   config: Partial<DuplicateBucketDialogConfig> = {};

   //

   terminalList: TerminalDto[] = [];

   //

   portfolioList: PortfolioDto[] = [];

   //

   comboList: ComboDto[] = [];

   //

   newName: string;

   //

   private _selectedTerminal: TerminalDto;
   get selectedTerminal(): TerminalDto { return this._selectedTerminal; }

   @DetectSetterChanges()
   set selectedTerminal(v: TerminalDto) {
      this._selectedTerminal = v;
   }

   //

   private _selectedPortfolio: PortfolioDto;
   get selectedPortfolio(): PortfolioDto { return this._selectedPortfolio; }

   @DetectSetterChanges()
   set selectedPortfolio(v: PortfolioDto) {
      this._selectedPortfolio = v;
   }

   //

   private _isLoading = false;
   get isLoading(): boolean { return this._isLoading; }

   @DetectSetterChanges()
   set isLoading(value: boolean) { this._isLoading = value; }

   //

   private _selectedCombo: ComboDto;
   get selectedCombo(): ComboDto { return this._selectedCombo; }

   @DetectSetterChanges()
   set selectedCombo(v: ComboDto) {
      this._selectedCombo = v;
   }

   //

   private _isVisible;
   get isVisible(): boolean { return this._isVisible; }

   @DetectSetterChanges()
   set isVisible(value: boolean) { this._isVisible = value; }

   //

   get canSetPortfolio(): boolean {
      return this.config.bucketType !== 'Portfolio';
   }

   //

   get canSetCombo(): boolean {
      return this.canSetPortfolio && this.config.bucketType !== 'Combo';
   }

   //

   ngOnInit() { }

   //

   ngAfterViewInit() { this._changeDetector.detach(); }

   //

   @DetectMethodChanges({ isAsync: true })
   async show(config: DuplicateBucketDialogConfig) {

      this.isVisible = true;

      try {

         const qry = new GetAvailableBuckets();

         this._availableBuckets = await this._shellClient.processQuery<GetAvailableBucketsReply>(qry);

         this.terminalList = this._sessionService.loginResult.availableTerminals.filter(x => !x.isProxy).slice();

         this.config = config;

         if (config.originalTerminal) {
            const terminal = this.terminalList.find(x => x.terminalId === config.originalTerminal);
            this.selectedTerminal = terminal;
         }

         if (config.originalPortfolio) {
            const portfolio = this.portfolioList.find(x => x.portfolioId === config.originalPortfolio);
            this.selectedPortfolio = portfolio;
         }

         if (config.originalCombo) {
            const combo = this.comboList.find(x => x.comboId === config.originalCombo);
            this.selectedCombo = combo;
         }

      } finally {

         this.isLoading = false;

      }

   }

   //

   @DetectMethodChanges()
   onHidden() {
      this.config = {};

      this.selectedTerminal = undefined;
      this.selectedPortfolio = undefined;
      this.selectedCombo = undefined;

      this.terminalList = [];
      this.portfolioList = [];
      this.comboList = [];

   }

   //

   @DetectMethodChanges()
   onTerminalSelected() {

      this.selectedPortfolio = null;

      this.selectedCombo = null;

      this.comboList = [];

      if (!this.selectedTerminal) {
         return;
      }

      const portfolios = this._availableBuckets.portfolios.filter(X => X.terminalId === this.selectedTerminal.terminalId);
      this.portfolioList = portfolios;
   }

   //

   @DetectMethodChanges()
   onPortfolioSelected() {

      this.selectedCombo = null;

      if (!this.selectedPortfolio) {
         return;
      }

      const portfolioCombos = this._availableBuckets.combos.filter(c => c.portfolioId === this.selectedPortfolio.portfolioId);
      this.comboList = portfolioCombos;
   }

   //

   @DetectMethodChanges({ isAsync: true })
   async onOkButtonClicked() {

      const cmd = this.makeCommand();

      const errors = this.validateCommandIsCorrect(cmd);

      if (errors.length > 0) {

         errors.forEach(x => this._toastr.error(x));

         return;
      }

      try {

         await this._shellClient.processCommand(cmd);
         this.isVisible = false;

      } catch (e) {

         console.error(e);
         this._toastr.error('"Duplicate Bucket" command completed with error');

      }

   }

   //

   private validateCommandIsCorrect(cmd: DuplicateBucket): string[] {
      const errors: string[] = [];

      const spec = cmd.duplicateSpec;

      if (!spec) {
         errors.push('Duplicate spec is necessary');
         return errors;
      }

      if (isNullOrUndefined(spec.bucketId)) {
         errors.push('Bucket ID is not set');
      }

      if (isNullOrUndefined(spec.bucketType)) {
         errors.push('Bucket Type is not set');
      }


      const targetBucketErrors = this.validateDuplicateSpec(spec);

      if (targetBucketErrors.length > 0) {
         errors.push(...targetBucketErrors);
      }

      return errors;
   }

   //

   private validateDuplicateSpec(duplicateSpec: DuplicateBucketSpec): string[] {

      const errors: string[] = [];

      const sourceTerminalId = this.config.originalTerminal || null;
      const sourcePortfolioId = this.config.originalPortfolio || null;
      const sourceComboId = this.config.originalCombo || null;

      const destTerminalId = duplicateSpec.terminalId || null;
      const destPortfolioId = duplicateSpec.portfolioId || null;
      const destComboId = duplicateSpec.comboId || null;

      if (!destTerminalId) {
         errors.push('Destination terminal is required');
      }

      if (duplicateSpec.bucketType === 'ComboGroup') {
         if (!destComboId) {
            errors.push('Target Combo Must be Provided');
         }
      }

      if (duplicateSpec.bucketType === 'Combo') {
         if (!destPortfolioId) {
            errors.push('Target Portfolio must be provided');
         }
      }

      if (duplicateSpec.bucketType === 'Portfolio') {
         if (!destTerminalId) {
            errors.push('Target Terminal must be provided');
         }
      }

      // if (sourceTerminalId === destTerminalId) {
      //    if (sourcePortfolioId === destPortfolioId) {
      //       if (sourceComboId === destComboId) {
      //          errors.push('Destination bucket is same as source');
      //       }
      //    }
      // }

      return errors;
   }

   //

   private makeCommand(): DuplicateBucket {
      const duplicateSpec: DuplicateBucketSpec = {};

      duplicateSpec.bucketId = this.config.bucketId;
      duplicateSpec.bucketType = this.config.bucketType;
      duplicateSpec.includingItems = true;

      if (this.selectedTerminal) {
         duplicateSpec.terminalId = this.selectedTerminal.terminalId;
      }

      if (this.selectedPortfolio) {
         duplicateSpec.portfolioId = this.selectedPortfolio.portfolioId;
      }

      if (this.selectedCombo) {
         duplicateSpec.comboId = this.selectedCombo.comboId;
      }

      if (this.newName) {
         duplicateSpec.newName = this.newName;
      }

      const cmd = new DuplicateBucket(duplicateSpec);

      return cmd;
   }

}
