import * as Enumerable from 'linq';
import { Subject } from 'rxjs';
import { Component, ViewChild, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { StrategiesService } from '../strategies/strategies.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import {
   TradingInstrumentDisplayNameService,
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { ColumnState, GridOptions, GridReadyEvent, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { getTradingSystemsGridModel } from './trading-systems-grid-model';
import { getStrategyPositionsGridModel } from './strategy-positions-grid-model';
import { DetectMethodChanges, DetectSetterChanges, getPanelStateKey, isHedgingAlgo, isNullOrUndefined, isVisibleInTradingSystems } from 'projects/shared-components/utils';
import { EtsConstants } from 'projects/shared-components/ets-constants.const';
import { throttleTime, takeUntil, filter } from 'rxjs/operators';
import { StartStrategies } from 'projects/shared-components/shell-communication/operations/strategies/start-strategies.class';
import { TogglePauseStrategy } from 'projects/shared-components/shell-communication/operations/strategies/pause-strategy.class';
import { StopStrategies } from 'projects/shared-components/shell-communication/operations/strategies/stop-strategies.class';
import { RemoveStrategies } from 'projects/shared-components/shell-communication/operations/strategies/remove-strategies.class';
import { SetStrategiesToStart } from 'projects/shared-components/shell-communication/operations/strategies/set-strategies-to-start.class';
import { ExitStrategies } from 'projects/shared-components/shell-communication/operations/strategies/exit-strategies.class';
import {
   OverrideExitStrategies
} from 'projects/shared-components/shell-communication/operations/strategies/override-exit-strategies.class';
import {
   OverrideResetStrategies
} from 'projects/shared-components/shell-communication/operations/strategies/override-reset-strategies.class';
import { CreateStrategy } from 'projects/shared-components/shell-communication/operations/strategies/create-strategy.class';
import { ClearTradingData } from 'projects/shared-components/shell-communication/operations/shell/clear-trading-data.class';
import { StrategyKind } from 'projects/shared-components/strategies/strategy-kind.enum';
import { StrategyPositionModel } from 'projects/shared-components/strategies/strategy-position-model.class';
import { ArchivePosition } from 'projects/shared-components/shell-communication/operations/archived-positions/archive-position.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { PositionArchived } from 'projects/shared-components/shell-communication/dtos/position-archived.class';
import { RollNextMonth } from 'projects/shared-components/shell-communication/operations/strategies/roll-next-month.class';
import { StrategiesSubscription } from 'projects/shared-components/strategies/strategies-subscription.class';
import { StrategyCommanderRuleDto } from 'projects/shared-components/shell-communication/dtos/strategy-commander-rule-dto.interface';
import { getCommanderRuleMetadata } from '../../webtrader/src/app/strategy-commander/strategy-commander-rule-metadata';
import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';
import { UnfreezeStrategies } from 'projects/shared-components/shell-communication/operations/strategies/unfreeze-strategies.class';
import { FreezeStrategies } from 'projects/shared-components/shell-communication/operations/strategies/freeze-strategies.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { StrategyIssuesBrowserComponent } from '../strategy-issues/strategy-issues-browser.component';
import { EditStrategyDialogComponent } from '../edit-strategy-dialog/edit-strategy-dialog.component';
import { DialogConfig } from '../edit-strategy-dialog/dialog-config';
import {
   GetCommanderRulesLinkedToStrategy
} from 'projects/shared-components/shell-communication/operations/strategies/get-commander-rules-linked-to-strategy.class';
import { TradingSystemsSecurityContextService } from './trading-systems-security-context.service';
import { StrategyFlags } from '../strategies/strategy-flags.enum';
import { ToggleStrategyFlag } from '../shell-communication/operations/strategies/toggle-strategy-flag.class';
import { getInnerSystemsGridModel } from './inner-systems-grid-model';
import { StrategyRemovedUIMessage } from '../ui-messages/strategy-removed-ui-message.interface';
import { ShowStrategyHistoryUIMessage } from '../ui-messages/ui-messages';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { CustomExitsComponent } from './custom-exits/custom-exits.component';
import { MoveToPortfolioDialogComponent } from '../portfolios/move-to-portfolio-dialog/move-to-portfolio-dialog.component';
import { MoveToPortfolioDialogConfig, PortfolioEntityType } from '../portfolios/move-to-portfolio-dialog/move-to-portfolio-dialog.model';
import { ExecuteCustomAlgoOperation } from '../shell-communication/operations/strategies/execute-custom-algo-operation.class';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { StrategyHighlightedUIMessage } from '../ui-messages/strategy-highlighted-ui-message.interface';
import { StrategySubscriptionViewAdapter } from '../strategies/strategy-subscription-view-adapter.interface';
import { AdjustmentStrategyDialogComponent } from '../adjustment-strategy-dialog/adjustment-strategy-dialog.component';
import { ArchiveStrategy } from '../shell-communication/shell-operations-protocol';
import {UserSettingsService} from "../user-settings.service";


interface SectionSizes {
   main: number;
   inner: number;
   positions: number;
}


interface PanelState {
   strategiesGridState: ColumnState[];
   positionsGridState: ColumnState[];
   innerStrategiesGridState: ColumnState[];
   showStrategyPositionsGrid: boolean;
   sectionSizes: SectionSizes;
}

function gridsReadyWatcher(res, rej): void {
   let attempts = 0;
   const intRef = setInterval(() => {
      attempts++;
      const stratsGrid = !!this._strategiesGrid;
      const positionsGrid = !!this._positionsGrid;
      const innersGrid = !!this._innerStrategiesGrid;

      if (stratsGrid && positionsGrid && innersGrid) {
         clearInterval(intRef);
         res();
      } else {
         if (attempts >= 4 * 5) {
            clearInterval(intRef);
            rej();
         }
      }
   }, 250);
}

@Component({
   selector: 'ets-trading-systems-view',
   styleUrls: ['./trading-systems-view.component.scss'],
   templateUrl: './trading-systems-view.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class TradingSystemsViewComponent extends PanelBaseComponent implements  StrategySubscriptionViewAdapter {
   
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _securityContext: TradingSystemsSecurityContextService,
      private readonly _displayNameService: TradingInstrumentDisplayNameService,
      protected readonly _strategiesService: StrategiesService,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _algoMetadataService: AlgoMetadataService,
   ) {

      super(_changeDetector, _userSettingsService, _messageBus);
      
      this.strategies = [];
      this.commanderRulesModel = {};
    
   }
   
   private _positionsGrid: GridReadyEvent;
   private _stateChanged = new EventEmitter();
   private _unsubscriber: Subject<any>;
   private _strategiesSubscription: StrategiesSubscription;
   private _strategiesGrid: GridReadyEvent;
   private _innerStrategiesGrid: GridReadyEvent;
   private _showStrategyPositionsGrid = false;
   private _showInnerStrategiesGrid = false;
   private _selectedStrategy: StrategyModel;

   
   @ViewChild(StrategyIssuesBrowserComponent, { static: true }) strategyIssuesBrowser: StrategyIssuesBrowserComponent;
   @ViewChild(EditStrategyDialogComponent, { static: true }) editStrategyDialog: EditStrategyDialogComponent;
   @ViewChild(CustomExitsComponent, { static: true }) customExitsDialog: CustomExitsComponent;
   @ViewChild(MoveToPortfolioDialogComponent, { static: true }) addToPortfolioDialog: MoveToPortfolioDialogComponent;
   @ViewChild(AdjustmentStrategyDialogComponent) attachStrategyDialog: AdjustmentStrategyDialogComponent;
   
   popupParent: HTMLElement;
   
   strategies: StrategyModel[];

   viewType: 'trading' | 'adjustment' = 'trading';

   systemDetailsModel: {
      isSystemDetailsVisible?: boolean,
      plainSystem?: StrategyModel,
      disposition?: {
         itself: StrategyModel,
         groups: { header: string, strategies: StrategyModel[] }[],
      },
      containerHeight?: number;
   };

   commanderRulesModel: {
      isVisible?: boolean,
      rulesList?: string[]
      isDataLoading?: boolean;
      selectedStrategy?: StrategyModel
   };

   strategyTriggersView: {
      isVisible?: boolean,
      isLoading?: boolean;
      selectedStrategy?: StrategyModel
   } = {};

   loadingMessage = 'Loading ...';
   
   tradingSystemsGridModel: GridOptions;
   
   innerSystemsGridModel: GridOptions;
   
   strategyPositionsGridModel: GridOptions;
   
   sectionSizes: SectionSizes = {
      main: 80,
      inner: 35,
      positions: 20
   };


   clearTradingDataConfirmPopupSettings: { strategies?: string[], isVisible?: boolean } = {};

   get securityContext(): TradingSystemsSecurityContextService {
      return this._securityContext;
   }


   get strategiesService(): StrategiesService {
      return this._strategiesService;
   }


   get showStrategyPositionsGrid(): boolean {
      return this._showStrategyPositionsGrid;
   }

   @DetectSetterChanges()
   set showStrategyPositionsGrid(value: boolean) {
      this._showStrategyPositionsGrid = value;
      if (this._positionsGrid) {
         if (value) {
            if (this._selectedStrategy) {
               const positions = this._selectedStrategy.positions.getPositions();
               this._positionsGrid.api.setRowData(positions);
            }
         } else {
            this._positionsGrid.api.setRowData([]);
         }
      }

      this.onStateChanged();
   }

   get showInnerStrategiesGrid(): boolean {
      return this._showInnerStrategiesGrid;
   }

   @DetectSetterChanges()
   set showInnerStrategiesGrid(v: boolean) {
      if (this._showInnerStrategiesGrid === v) {
         return;
      }
      
      this._showInnerStrategiesGrid = v;

      if (this._innerStrategiesGrid) {
         if (this._showInnerStrategiesGrid) {
            this._populateInnerStrategiesGrid(this._selectedStrategy);
         } else {
            const subs = this._innerStrategiesGrid['ets-inner-subscription'];
            if (subs) {
               this._strategiesService.unsubscribe(subs);
            }
         }
      }

      this.onStateChanged();
   }

   // #region IStrategySubscriptionViewAdapter implementation

   get isPositionsGridVisible(): boolean {
      return this.showStrategyPositionsGrid;
   }

   get isInnerStrategiesGridVisible(): boolean {
      return this.showInnerStrategiesGrid;
   }

   get positionsGrid(): GridReadyEvent {
      return this._positionsGrid;
   }

   get strategiesGrid(): GridReadyEvent {
      return this._strategiesGrid;
   }

   get innerStrategiesGrid(): GridReadyEvent {
      return this._innerStrategiesGrid;
   }

   get selectedStrategy(): StrategyModel {
      return this._selectedStrategy;
   }

   // #endregion

   etsOnInit(): void {
      this.popupParent = document.querySelector('body');
      this.tradingSystemsGridModel = getTradingSystemsGridModel.bind(this)();
      this.strategyPositionsGridModel = getStrategyPositionsGridModel.bind(this)(
         this._displayNameService
      );
      this.innerSystemsGridModel = getInnerSystemsGridModel.bind(this)();
      this._subscribeToMessages();
   }


   @DetectMethodChanges({isAsync: true})
   async etsAfterViewInit(): Promise<void> {
      
      this._changeDetector.detach();

      this.isLoading = false;

      const resolver = gridsReadyWatcher.bind(this);
      
      try {
         
         await new Promise(resolver);

      } catch (error) {
         
         this._toastr.error('"Strategies" panel failed to initialize. Please, reload');
         return;
      }

      this._strategiesSubscription = this.getStrategiesSubscription();

      const key = getPanelStateKey(this);
      
      const item = this._userSettingsService.getValue(key);
      if (!item) {
         this.saveState();
      } else {
         this.restoreState();
      }

      this._stateChanged.pipe(
         throttleTime(250),
         takeUntil(this._unsubscriber)
      )
      .subscribe(() => this.saveState());
   }
   
   getStrategiesSubscription(): StrategiesSubscription {
      
      const subs = this._strategiesService.subscribe(
         (strat) => isVisibleInTradingSystems(strat)
         , 'main'
         , this
      );

      return subs;
   }


   @DetectMethodChanges()
   requestSessionHistoryDialog(data: StrategyModel): void {
      const grid = this.strategiesGrid;
      const rows = grid.api.getSelectedRows();
      if (rows.length > 0) {
         this._messageBus.publish({
            topic: 'ShowStrategyHistoryUIMessage',
            payload: {
               strategies: rows
            } as ShowStrategyHistoryUIMessage
         });
      } else {
         this._toastr.error('No selected strategies');
      }
   }


   @DetectMethodChanges({isAsync: true})
   async startStrategy(data: StrategyModel, isInner?: boolean): Promise<void> {
      const grid = isInner ? this._innerStrategiesGrid : this._strategiesGrid;
      const selected = grid.api.getSelectedRows().map(x => x.strategyId);
      
      if (selected.length === 0) { return; }
      
      const cmd = new StartStrategies(selected);

      try {
         
         await this._shellClient.processCommand(cmd);

      } catch (e) {
         
         this._toastr.error('"Start Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('startStrategy()', errorData);

      }
   }


   etsOnDestroy(): void {
      
      this._strategiesService.unsubscribe(this._strategiesSubscription);
      if (this._innerStrategiesGrid) {
         const subs = this._innerStrategiesGrid['ets-inner-subscription'];
         if (subs) {
            this._strategiesService.unsubscribe(subs);
         }
      }
      this._messageBus.clearLastMessage('StrategyHighlightedUIMessage', this.layoutTabId);
      this._unsubscriber.next();
      this._unsubscriber.complete();

   }


   @DetectMethodChanges({isAsync: true})
   async pauseStrategy(data: StrategyModel): Promise<void> {
      const selected = this._strategiesGrid.api.getSelectedRows().map(x => x.strategyId);
      
      if (selected.length === 0) {
         return;
      }

      const cmd = new TogglePauseStrategy(
         selected
      );

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Pause Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('pauseStrategy()', errorData);
      }
   }


   onSelectionChanged(): void {
      const selectedRows = this._strategiesGrid.api.getSelectedRows();

      if (selectedRows.length === 1) {
         const selectedStrategy: StrategyModel = selectedRows[0];
         
         this._selectedStrategy = selectedStrategy;

         this._messageBus.publish<StrategyHighlightedUIMessage>({
            topic: 'StrategyHighlightedUIMessage',
            payload: {
               strategyId: selectedStrategy.strategyId,
               strategyName: selectedStrategy.displayName,
               strategy: selectedStrategy
            },
            scopeId: this.layoutTabId
         });

         if (this._positionsGrid) {
            const positions = selectedStrategy.positions.getPositions();
            this._positionsGrid.api.setRowData(positions);
         }

         if (selectedStrategy.isDisposition) {
            if (!this.showInnerStrategiesGrid) {
               this.showInnerStrategiesGrid = true;
            } else {
               this._populateInnerStrategiesGrid(selectedStrategy);
            }
         } else {
            if (!selectedStrategy.isInnerStrategy) {
               this.showInnerStrategiesGrid = false;  
            }
         }

      } else {

         if (this._positionsGrid) {
            this._positionsGrid.api.setRowData([]);
            this.showStrategyPositionsGrid = false;
         }

         if (this._innerStrategiesGrid) {
            if (this.showInnerStrategiesGrid) {
               if (this._innerStrategiesGrid.api.getSelectedRows().length === 0) {
                  this._innerStrategiesGrid.api.setRowData([]);
                  this.showInnerStrategiesGrid = false;
               }
            }
         }

         this._selectedStrategy = null;
   
      }

      if (selectedRows.length > 0) {
         if (!isNullOrUndefined(this._innerStrategiesGrid)) {
            this._innerStrategiesGrid.api.deselectAll();
         }
      }
   }


   private _populateInnerStrategiesGrid(selectedStrategy: StrategyModel) {

      const oldSubs: StrategiesSubscription = this._innerStrategiesGrid['ets-inner-subscription'];

      if (!isNullOrUndefined(oldSubs)) {
         this._strategiesService.unsubscribe(oldSubs);
      }

      this._innerStrategiesGrid.api.setRowData([]);

      if (isNullOrUndefined(selectedStrategy) || !selectedStrategy.isDisposition) {
         return;
      }

      // wrpap in timeout to let inner strategies to finish reset its current data
      setTimeout(() => {
         const subs = this._strategiesService
         .subscribe(
            (strat) => strat.dispositionId === selectedStrategy.strategyId, 
            'inner', 
            this);

         this._innerStrategiesGrid['ets-inner-subscription'] = subs;

         this._innerStrategiesGrid.api.forEachNode(node => {
            if (node.group) {
               if (node.field === 'isArchived' && node.key === 'Active Items') {
                  setTimeout(() => node.setExpanded(true));
               }
            }
         });
      });
   }


   onInnerStrategiesRowSelected(args: RowSelectedEvent): void {

      const selectedRows = args.api.getSelectedRows();

      if (selectedRows.length !== 1) {
         return;
      }

      const strategy = selectedRows[0] as StrategyModel;

      this._messageBus.publish({
         topic: 'StrategyHighlightedUIMessage',
         payload: {
            strategyId: strategy.strategyId,
            scopeId: this.layoutTabId,
            strategyName: strategy.displayName,
            strategy
         },
         scopeId: this.layoutTabId
      });
   }


   @DetectMethodChanges()
   onCommanderRulesListHidden(): void {
      this.commanderRulesModel.isVisible = false;
      this.commanderRulesModel.isDataLoading = false;
      this.commanderRulesModel.rulesList = [];
      this.commanderRulesModel.selectedStrategy = null;
   }


   @DetectMethodChanges({isAsync: true})
   async stopStrategy(data: StrategyModel, isInner?: boolean): Promise<void> {
      const grid = isInner ? this._innerStrategiesGrid : this._strategiesGrid;
      const selected = grid.api.getSelectedRows().map(x => x.strategyId);

      if (selected.length === 0) {
         return;
      }

      const cmd = new StopStrategies(
         selected
      );

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Stop Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('stopStrategy()', errorData);
      }
   }



   @DetectMethodChanges({isAsync: true})
   async removeStrategy(data: StrategyModel): Promise<void> {
      const grid =  data.isInnerStrategy ? this._innerStrategiesGrid : this._strategiesGrid;
      const selected = grid.api.getSelectedRows().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new RemoveStrategies(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Remove Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('removeStrategy()', errorData);
      }
   }

   @DetectMethodChanges({isAsync: true})
   async archiveStrategy(data: StrategyModel): Promise<void> {
      const grid =  data.isInnerStrategy ? this._innerStrategiesGrid : this._strategiesGrid;
      const selected = grid.api.getSelectedRows().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new ArchiveStrategy(selected[0]);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Archive Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('archiveStrategy()', errorData);
      }
   }



   @DetectMethodChanges({isAsync: true})
   async setToStartStrategy(data: StrategyModel, isInner?: boolean): Promise<void> {
      const grid = isInner ? this._innerStrategiesGrid : this._strategiesGrid;

      const selected = grid.api.getSelectedRows().map(x => x.strategyId);

      if (selected.length === 0) {
         return;
      }

      const cmd = new SetStrategiesToStart(
         selected
      );

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Set Strategy To Start" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('setToStartStrategy()', errorData);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async exitStrategy(data: StrategyModel, isInner?: boolean): Promise<void> {
      const grid = isInner ? this._innerStrategiesGrid : this._strategiesGrid;

      const selected = grid.api.getSelectedRows().map(x => x.strategyId);

      if (selected.length === 0) {
         return;
      }

      const cmd = new ExitStrategies(
         selected
      );

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Exit Strategy" operation completed with errors');
         const errorData = { error: e.stack || e };
         console.error('exitStrategy()', errorData);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async startAllStrategies(): Promise<void> {
      const selected = this._strategiesService.getAllStrategies().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new StartStrategies(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Start All Strategies" operation completed with errors');
         const data = { error: e.stack || e };
         console.error('startAllStrategies()', data);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async exitAllStrategies(): Promise<void> {
      const selected = this._strategiesService.getAllStrategies().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new ExitStrategies(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Exit All Strategies" operation completed with errors');
         const data = { error: e.stack || e };
         console.error('exitAllStrategies()', data);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async stopAllStrategies(): Promise<void> {
      const selected = this._strategiesService.getAllStrategies().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new StopStrategies(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Stop All Strategies" operation completed with errors');
         const data = { error: e.stack || e };
         console.error('stopAllStrategies()', data);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async setAllStrategiesToStart(): Promise<void> {
      const selected = this._strategiesService.getAllStrategies().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new SetStrategiesToStart(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Set All Strategies To Start" operation completed with errors');
         const data = { error: e.stack || e };
         console.error('setAllStrategiesToStart()', data);
      }
   }


   @DetectMethodChanges()
   onHeightCalculated(event: number): void {
      if (this.systemDetailsModel) {
         const height = this.systemDetailsModel.containerHeight || 0;
         if (event > height) {
            this.systemDetailsModel.containerHeight = event;
         }
      }
   }


   @DetectMethodChanges({isAsync: true})
   async overrideExitStrategy(strategy: StrategyModel): Promise<void> {
      const selected = this._strategiesGrid.api.getSelectedRows().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new OverrideExitStrategies(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Override Exit Strategy" operation completed with errors');
         const data = { error: e.stack || e };
         console.error('overrideExitStrategy()', data);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async overrideResetStrategy(strategy: StrategyModel): Promise<void> {
      const selected = this._strategiesGrid.api.getSelectedRows().map(x => x.strategyId);
      if (selected.length === 0) {
         return;
      }

      const cmd = new OverrideResetStrategies(selected);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Override Reset Strategy" operation completed with errors');
         const data = { error: e.stack || e };
         console.error('overrideResetStrategy()', data);
      }
   }


   @DetectMethodChanges()
   showSystemDetails(strategy: StrategyModel): void {
      if (strategy.dispositionStrategies && strategy.dispositionStrategies.length) {
         const byAlgoId = Enumerable.from(strategy.dispositionStrategies)
            .groupBy(x => x.algoId);

         const groups = byAlgoId
            .select(x => ({
               header: this._algoMetadataService.getAlgoModel(x.key()).displayName,
               strategies: x.getSource()
            })
            )
            .toArray();

         this.systemDetailsModel = {
            disposition: { itself: strategy, groups }
         };
      } else {
         this.systemDetailsModel = { plainSystem: strategy };
      }

      this.systemDetailsModel.isSystemDetailsVisible = true;
   }

   @DetectMethodChanges()
   hideSystemDetails() {
      this.systemDetailsModel = {};
   }


   @DetectMethodChanges({isAsync: true})
   async duplicateStrategy(data: StrategyModel): Promise<void> {
      const dto = this._makeStrategyDtoFromModel(data, 'duplicate');
      if (!!data.dispositionStrategies && data.dispositionStrategies.length) {
         const innerStrats = data.dispositionStrategies.map(x => this._makeStrategyDtoFromModel(x, 'duplicate'));
         dto.dispositionStrategies = innerStrats;
      }

      const cmd = new CreateStrategy([dto]);
      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Duplicate Strategy" command completed with errors');
         const err = { error: e.stack || e };
         console.error('"Duplicate Strategy" command completed with errors', err);
      } finally {
         this.isLoading = false;
      }
   }



   @DetectMethodChanges()
   async clearTradingDataForStrategy(data: StrategyModel): Promise<void> {
      const selected: string[] = this._strategiesGrid.api.getSelectedRows().map(x => x.strategyId);

      if (selected.length === 0) {
         return;
      }

      this.clearTradingDataConfirmPopupSettings.strategies = selected;
      this.clearTradingDataConfirmPopupSettings.isVisible = true;
   }



   @DetectMethodChanges()
   async onClearTradingDataForStrategyConfirmed() {
      const strategies = this.clearTradingDataConfirmPopupSettings.strategies;
      if (!strategies
         || strategies.length === 0) {
         this._toastr.error('Strategies Not Selected');
         return;
      }

      this.onClearTradingDataConfirmPopupClosed();

      const cmd = new ClearTradingData();
      cmd.specificStrategies = strategies;
      cmd.deleteData = false;

      try {
         this._messageBus.publish({
            topic: 'SpecificDatabaseCleanupStarted',
            payload: {}
         });
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('"Clear Trading Data" Operation Completed With Errors');
      }
   }



   @DetectMethodChanges()
   onClearTradingDataConfirmPopupClosed() {
      this.clearTradingDataConfirmPopupSettings = {};
   }


   @DetectMethodChanges()
   showIssues(data: StrategyModel) {
      this.strategyIssuesBrowser.show(data);
   }


   @DetectMethodChanges({isAsync: true})
   async editStrategy(model: StrategyModel, mode: 'add' | 'update', source: 'main' | 'inner'): Promise<void> {
      
      if (this.viewType === 'trading') {

         await this.editStrategyTradingView(model, mode, source);

      } else if (this.viewType === 'adjustment') {

         this.editStrategyAdjustmentView(model);
         
      }

   }

   private editStrategyAdjustmentView(model: StrategyModel) {
      if (!this.attachStrategyDialog) {
         this._toastr.error('"Adjustment Strategy Dialog" not found');
         return;
      }

      this.attachStrategyDialog.show({
         mode: 'update',
         ctx: 'direct',
         item: null,
         strategy: model
      });
   }

   //

   private async editStrategyTradingView(model: StrategyModel, mode: 'add' | 'update', source: 'main' | 'inner'): Promise<void> {
      
      if (!this.editStrategyDialog) {
         return;
      }

      let dispositionId = null;
      if (source === 'inner') {
         if (this.selectedStrategy) {
            if (this.selectedStrategy.isDisposition) {
               dispositionId = this.selectedStrategy.strategyId;
            }
         }   
      }

      const strategy = model || new StrategyModel();
      const dto = this._makeStrategyDtoFromModel(strategy, 'edit');
      const config: DialogConfig = {
         strategy: dto,
         isUpdate: mode === 'update',
         strategyKind: strategy.strategyKind || StrategyKind.Engine,
         editMode: 'immediate',
         source,
         dispositionId
      }; 

      this.isLoading = true;
      await this.editStrategyDialog.show(config);
      setTimeout(() => {
         this.isLoading = false;
      }, 50);
   }


   onStrategyPositionsGridReady(args: GridReadyEvent): void {
      this._positionsGrid = args;
      this._positionsGrid.api.sizeColumnsToFit();
   }


   onStrategiesGridReady(event: GridReadyEvent): void {
      this._strategiesGrid = event;
      this._strategiesGrid.api.sizeColumnsToFit();

      this._strategiesGrid.api['ets-panel-id'] = this.panelId;

      // this hack is required to correctly show strategy issues compoenent 
      const issuesColumn = this._strategiesGrid.columnApi.getColumn('issues');
      if (issuesColumn) {
         issuesColumn['ets-panel-id'] = this.panelId;
      }

      // this hack is required to correctly show commander rules compoenent 
      const numberOfCommanderRulesColumn = this._strategiesGrid.columnApi.getColumn('numberOfCommanderRules');
      if (numberOfCommanderRulesColumn) {
         numberOfCommanderRulesColumn['ets-panel-id'] = this.panelId;
      }
   }


   onInnerStrategiesGridReady(event: GridReadyEvent): void {
      this._innerStrategiesGrid = event;
      this._innerStrategiesGrid.api.sizeColumnsToFit();

      // this hack is required to correctly show strategy issues compoenent 
      const issuesColumn = this._innerStrategiesGrid.columnApi.getColumn('issues');
      if (issuesColumn) {
         issuesColumn['ets-panel-id'] = this.panelId;
      }
   }


   onStateChanged(): void {
      setTimeout(() => this._stateChanged.emit(), 0);
   }


   @DetectMethodChanges({isAsync: true})
   async archivePosition(position: StrategyPositionModel): Promise<void> {
      if (!position) {
         this._toastr.info('Please, select position');
         return;
      }

      if (position.netPosition !== 0) {
         this._toastr.info('Open position cannot be archived');
         return;
      }

      const cmd = new ArchivePosition(position.positionId);
      try {
         await this._shellClient.processCommand(cmd);
      } catch (error) {
         const errorMessage = 'Error occurred during "Archive Position" operation';
         console.error(errorMessage, { error });
         this._toastr.error(errorMessage);
      }
   }


   @DetectMethodChanges({isAsync: true})
   async changeFreezeOnStrategy(command: 'freeze' | 'unfreeze'): Promise<void> {
      const selected = this._strategiesGrid.api.getSelectedRows();
      if (selected.length === 0) {
         return;
      }

      let cmd: ProtocolCommand;
      if (command === 'unfreeze') {
         const strategies = selected.filter(s => s.status.toLowerCase() === 'frozen')
            .map(x => x.strategyId);
         cmd = new UnfreezeStrategies(strategies);
      } else {
         const strategies = selected
            .filter(s => s.status.toLowerCase() !== 'frozen')
            .map(x => x.strategyId);
         cmd = new FreezeStrategies(strategies);
      }

      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         console.error('Failed to freeze/unfreeze strategy', e);
         this._toastr.error('"Freeze/Unfreeze" strategy command completed with errors');
      } finally {
         this.isLoading = false;
      }
   }


   @DetectMethodChanges({isAsync: true})
   async changeFreezeAllStrategies(command: 'freeze' | 'unfreeze'): Promise<void> {
      if (!command) {
         return;
      }

      let cmd: ProtocolCommand;

      if (command === 'freeze') {
         const strategies = this._strategiesService
            .getAllStrategies()
            .filter(s => s.status.toLowerCase() !== 'frozen')
            .map(s => s.strategyId);

         cmd = new FreezeStrategies(strategies);
      } else if (command === 'unfreeze') {
         const strategies = this._strategiesService
            .getAllStrategies()
            .filter(s => s.status.toLowerCase() === 'frozen')
            .map(s => s.strategyId);

         cmd = new UnfreezeStrategies(strategies);
      }

      this.isLoading = true;
      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         console.error('Failed to freeze/unfreeze strategy', e);
         this._toastr.error('"Freeze/Unfreeze" strategy command completed with errors');
      } finally {
         this.isLoading = false;
      }
   }


   @DetectMethodChanges()
   onSplitSizeChanged($event: { gutterNum: number; sizes: Array<number> }): void {
      this.sectionSizes.main = $event.sizes[0];
      this.sectionSizes.inner = $event.sizes[1];
      this.sectionSizes.positions = $event.sizes[2];
      this._stateChanged.emit();
   }


   @DetectMethodChanges({isAsync: true})
   async onCommanderRulesListShown(): Promise<void> {
      const selectedStrategy = this.commanderRulesModel.selectedStrategy;
      if (this._selectedStrategy == null) {
         this._toastr.warning('Please, select a strategy');
         return;
      }
      const qry = new GetCommanderRulesLinkedToStrategy(selectedStrategy.strategyId);
      try {
         this.commanderRulesModel.isDataLoading = true;
         const dtos = await this._shellClient.processQuery<StrategyCommanderRuleDto[]>(qry, selectedStrategy.shellId);
         const descriptions = dtos.map(dto => getCommanderRuleMetadata(dto as any, this._displayNameService, this._strategiesService));
         this.commanderRulesModel.rulesList = descriptions;
      } catch {
         this._toastr.error('"Load Commander Rules For Strategy" operation completed with errors');
      } finally {
         this.commanderRulesModel.isDataLoading = false;
      }
   }


   @DetectMethodChanges()
   async rollNextMonth(data: StrategyModel): Promise<void> {
      if (!data) {
         return;
      }
      
      const cmd = new RollNextMonth(data.strategyId);
      this.isLoading = true;
      
      this._changeDetector.detectChanges();

      try {

         await this._shellClient.processCommand(cmd);

      } catch (e) {
         
         const errorData = {
            error: (e.stack ? e.stack : e), strategyId: data.strategyId
         };
         console.error('Failed to roll strategy next month', errorData);
         this._toastr.error('"Roll Next Month" command completed with errors');

      } finally {
         this.isLoading = false;
      }
   }


   @DetectMethodChanges()
   showCustomExistsDialog(strategy: StrategyModel) {
      if (!strategy) {
         this._toastr.error('Strategy Not Selected');
         return;
      }
      if (strategy.isDisposition) {
         this._toastr.error('Please Select Inner Strategy of Disposition');
         return;
      }
      this.customExitsDialog.show(strategy);
   }


   @DetectMethodChanges({isAsync: true})
   async toggleStrategyFlag(strategy: StrategyModel, flag: StrategyFlags): Promise<void> {
      this.isLoading = true;
      const cmd = new ToggleStrategyFlag(strategy.strategyId, flag);
      try {
         await this._shellClient.processCommand(cmd, strategy.shellId);
      } catch (error) {

      } finally {
         this.isLoading = false;
      }
   }



   @DetectMethodChanges()
   addToPortfolio(mode: 'existing' | 'new', strategy: StrategyModel): void {

      if (!this.addToPortfolioDialog) {
         this._toastr.error('"Move To Portfolio" dialog not loaded"');

         return;
      }


      const cfg: MoveToPortfolioDialogConfig = {
         itemId: strategy.strategyId,
         itemType: PortfolioEntityType.Strategy,
         itemTerminalId: strategy.terminalId,
         itemPortfolioId: strategy.portfolioId,
         itemComboId: strategy.comboId,
         itemComboGroupId: strategy.comboGroupId,
      };
      

      this.addToPortfolioDialog.show(cfg);
   }

   private getContextDispositionId(): string {
      if (this.selectedStrategy) {
         
         if (!this.selectedStrategy.isDisposition) {
            return undefined;
         }

         return this.selectedStrategy.strategyId;

      } else {

         const innerStrats = [];
         
         this._innerStrategiesGrid.api.forEachLeafNode(node => {
            if (!node.data) {
               return;
            }
            innerStrats.push(node.data);
         });

         if (innerStrats.length === 0) {
            return undefined;
         }

         return innerStrats[0].dispositionId;
      }
   }


   showInnerStrategyEditor(model: StrategyModel, mode: 'add' | 'update') {
      
      model = model || new StrategyModel();

      const dto = this._makeStrategyDtoFromModel(model, 'edit');
      
      const dispoId = this.getContextDispositionId();

      if (!dispoId) {
         
         if (mode === 'add') {
            this._toastr.error('Cannot determine target strategy container');
            return;
         
         }
      }

      const dispo = this._strategiesService.getById(dispoId);

      if (!dispo) {
         this._toastr.error('Cannot determine target strategy container');
         return;
      }

      dto.terminalId = dispo.terminalId;

      const strategy = JSON.parse(JSON.stringify(dto));
  
      const config: DialogConfig = {
        strategy,
        isUpdate: mode === 'update',
        strategyKind: StrategyKind.Engine,
        editMode: 'immediate',
        source: 'inner',
        dispositionId: dispoId
      };
  
      this.editStrategyDialog.show(config);
    }


   @DetectMethodChanges()
   async hedgeActionsExitWithLimit() {
      const cmd = new ExecuteCustomAlgoOperation(
         this.selectedStrategy.strategyId,
         '4d7ca4c6-8b6c-4a56-9a9d-c611fb01165b',
         {}
      );

      this.isLoading = true;
      
      this._changeDetector.detectChanges();

      try {
         
         await this._shellClient.processCommand(cmd);

      } finally {
         
         this.isLoading = false;

      }
      
   }


   private _makeStrategyDtoFromModel(model: StrategyModel, purpose: 'edit' | 'duplicate'): StrategyDto {
      if (!purpose) {
         throw new Error('Strategy copy purpose not provided');
      }

      const existingStrats = this._strategiesService
         .getAllStrategies()
         .filter(x => x.displayName.startsWith(model.displayName));

      const dto = new StrategyDto();
      dto.strategyId = model.strategyId;
      dto.displayName = purpose === 'duplicate'
         ? `${model.displayName} (${existingStrats.length})`
         : model.displayName;
      dto.algoId = model.algoId;
      dto.parameters = model.parameters;
      dto.tags = model.tags;
      dto.strategyKind = model.strategyKind;
      dto.terminalId = model.terminalId;
      dto.terminalCode = model.terminalCode;
      dto.unitsAllocated = model.unitsAllocated;
      dto.autostopping = model.autostopping;
      dto.isSession = model.isSession;
      dto.tradingObjectives = JSON.stringify(model.tradingObjectives);
      dto.scheduleParameters = JSON.stringify(model.scheduleParameters);
      dto.triggers = JSON.stringify(model.triggers);
      dto.positionSizing = JSON.stringify(model.positionSizing);
      dto.portfolioId = model.portfolioId;
      dto.comboId = model.comboId;
      dto.comboGroupId = model.comboGroupId;

      if (purpose === 'edit') {
         if (model.dispositionStrategies.length > 0) {
            const innerStrats = model.dispositionStrategies.map(x => this._makeStrategyDtoFromModel(x, 'edit'));
            dto.dispositionStrategies = innerStrats;
         }
      }

      return dto;
   }


   protected getState(): PanelState {
      if (!this._strategiesGrid) {
         return;
      }

      
      const strategiesGridState = this._strategiesGrid.columnApi.getColumnState();
      const positionsGridState = this._positionsGrid.columnApi.getColumnState();
      const innerStrategiesGridState = this._innerStrategiesGrid.columnApi.getColumnState();
      const state: PanelState = {
         strategiesGridState,
         positionsGridState,
         innerStrategiesGridState,
         showStrategyPositionsGrid: this.showStrategyPositionsGrid,
         sectionSizes: this.sectionSizes
      };

      return state;
   }


   private _onStrategyPositionArchived(x: PositionArchived): void {
      const positions = this._selectedStrategy.positions.getPositions();
      this._positionsGrid.api.setRowData(positions);
   }


   protected setState(state: PanelState): void {
      if (!this._strategiesGrid) {
         return;
      }

      
      const isStrategiesOK = this._strategiesGrid.columnApi.setColumnState(state.strategiesGridState);
      const isPositionsOK = this._positionsGrid.columnApi.setColumnState(state.positionsGridState);
      const isInnerStratsOK = this._innerStrategiesGrid.columnApi.setColumnState(state.innerStrategiesGridState);
      
      if (!isPositionsOK || !isStrategiesOK || !isInnerStratsOK) {
         this._toastr.error('"Trading Systems View" panel was restored with errors');
      }
      
      this.showStrategyPositionsGrid = state.showStrategyPositionsGrid;
      
      if (state.sectionSizes) {
         this.sectionSizes = state.sectionSizes;
      }
   }


   private _subscribeToMessages(): void {
      this._unsubscriber = new Subject<any>();

      this._messageBus.of<any>('ShowStrategyIssuesRequest')
         .pipe(
            filter(msg => msg.payload.panelId === this.panelId),
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) => this.showIssues(msg.payload.strategy));

      this._messageBus.of<any>('ShowStrategyCommanderRulesRequest')
         .pipe(
            filter(msg => msg.payload.panelId === this.panelId),
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) => this.showLinkedCommanderRules(msg.payload.strategy));
         
      this._messageBus.of<any>('ShowStrategyTriggersRequest')
         .pipe(
            filter(msg => msg.payload.panelId === this.panelId),
            takeUntil(this._unsubscriber)
         )
         .subscribe((msg) => this.showTriggers(msg.payload.strategy));


      this._messageBus.of<PositionArchived>('PositionArchived')
         .pipe(
            filter(x => this._showStrategyPositionsGrid),
            filter(x => !!this._selectedStrategy),
            filter(x => this._selectedStrategy.strategyId === x.payload.strategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this._onStrategyPositionArchived(x.payload));

      this._messageBus.of<any>('WorkspaceClosed')
         .pipe(
            filter(msg => msg.payload.workspaceId === this.workspaceId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onRemovedFromWorkspace());

      this._messageBus.of<any>('DispositionParametersUpdated')
         .pipe(
            filter(msg => this._selectedStrategy && this._selectedStrategy.strategyId === msg.payload.strategyId),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onDispositionParametersUpdated());

      this._messageBus.of<StrategyRemovedUIMessage>('StrategyRemovedUIMessage')
         .pipe(
            filter(msg => this._selectedStrategy && this._selectedStrategy.strategyId === msg.payload.strategyId && msg.payload.isDisposition),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => setTimeout(() => this._onDispositionStrategyRemoved(msg.payload), 0));
   }

   
   private _onDispositionStrategyRemoved(msg: StrategyRemovedUIMessage): void {
      if (this._showInnerStrategiesGrid) {
         if (this._innerStrategiesGrid) {
            this._innerStrategiesGrid.api.setRowData([]);
         }
      }
   }


   private _onDispositionParametersUpdated(): void {
      if (this._innerStrategiesGrid) {
         if (this._selectedStrategy) {
            this._populateInnerStrategiesGrid(this._selectedStrategy);
         }
      }
   }


   @DetectMethodChanges()
   private showLinkedCommanderRules(strategy: StrategyModel): void {
      this.commanderRulesModel.isVisible = true;
      this.commanderRulesModel.selectedStrategy = strategy;
   }

   @DetectMethodChanges()
   private showTriggers(strategy: StrategyModel): void {
      this.strategyTriggersView.isVisible = true;
      this.strategyTriggersView.selectedStrategy = strategy;
   }

   @DetectMethodChanges()
   onStrategyTriggersViewHidden(): void {
      this.strategyTriggersView.isVisible = false;
      this.strategyTriggersView.selectedStrategy = null;
   }

   @DetectMethodChanges({isAsync: true})
   async onStrategyTriggersViewShown(): Promise<void> {
      const selectedStrategy = this.strategyTriggersView.selectedStrategy;
      
      if (selectedStrategy == null) {
         this._toastr.warning('Please, select a strategy');
         return;
      }
      
      this.strategyTriggersView.isLoading = true;

      setTimeout(() => {
         this._toastr.error('"Load Strategy Triggers" operation compelted with errors');
         this.strategyTriggersView.isLoading = false;
         this._changeDetector.detectChanges();
      }, 1500);
      
   }
}
