<dx-popup
  [closeOnOutsideClick]="false"
  [height]="this.height"
  [resizeEnabled]="true"
  [showCloseButton]="false"
  [title]="this.title"
  [width]="this.width"
>
  <div *dxTemplate="let data of 'content'">
    <div class="container">
      
      <div class="header">
        <div class="title">
          After State
        </div>
        <div class="details">
          <div class="adjustment-type">
            <span class="value"> "{{ this.adjustment }}" </span>
          </div>
        </div>
      </div>
      
      <hr style="border-color: dimgray; width: 80%">
      
      <div class="content">
        
        <dx-scroll-view>
          <div class="state">
            <table>
              <ng-container *ngFor="let group of this.hedges">
                <tr class="group-name" [style.color]="group.color">
                  <td colspan="5">{{ group.label }}</td>
                </tr>
                <tr class="headers" [style.color]="group.color">
                  <td>Qty</td>
                  <td>Side</td>
                  <td>Type</td>
                  <td>Expiration</td>
                  <td>Strike</td>
                </tr>
                <tr *ngFor="let row of group.positions" class="row" [style.color]="group.color">
                  <td>{{ row.qty }}</td>
                  <td>{{ row.qty | etsMarketSidePipe }}</td>
                  <td>{{ row.type }}</td>
                  <td>{{ row.expiration | etsFriendlyDatePipe:'full' }}</td>
                  <td>{{ row.strike }}</td>
                </tr>
                <tr class="footer">
                  <td colspan="5">&nbsp;</td>
                </tr>
              </ng-container>
            
            </table>
          </div>
        </dx-scroll-view>
      
      </div>
      
      <hr style="border-color: dimgray; width: 80%">
      
      <div class="footer">
        <div class="btn apply">
          <dx-button (onClick)="this.onApplyClicked()" [text]="this.btnApplyText"
                     width="100%">
          </dx-button>
        </div>
        <div class="btn close">
          <dx-button (onClick)="this.onCloseClicked()" [text]="this.btnCloseText"
                     width="100%">
          </dx-button>
        </div>
      </div>
    
    </div>
  </div>
</dx-popup>
