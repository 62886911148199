import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {RowFilter} from "./model/row.filter";

@Injectable()
export class PackageComparisonFiltersService {

    constructor() {
    }

    private _filters: RowFilter[] = [];

    get filters() : ReadonlyArray<RowFilter> {
        return this._filters;
    }

    get filtersChanged$() : Observable<void> {
        return this._filtersChanged$;
    }
    private readonly _filtersChanged$ : Subject<void> = new Subject<void>();

    clearFilters(): void {
        this._filters.length = 0;
        this.onFiltersChanged();
    }

    removeAt(existingCallHedge: number) {
        this._filters.splice(existingCallHedge, 1);
        this.onFiltersChanged();
    }

    addFilter(rowFilter: RowFilter) {
        this._filters.push(rowFilter);
        this.onFiltersChanged();
    }

    private onFiltersChanged() {
        this._filtersChanged$.next();
    }

    replaceAt(ix: number, rowFilter: RowFilter) {
        this._filters[ix] = rowFilter;
        this.onFiltersChanged();
    }

    remove(hasAdjustments: RowFilter[]) {
        hasAdjustments.forEach(x => {
            const ix = this._filters.indexOf(x);
            if (ix === -1) {
                return;
            }
            this._filters.splice(ix, 1);
        });
        this.onFiltersChanged();
    }
}