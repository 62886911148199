import {HedgeBoardComparisonModel} from "./HedgeBoardComparisonModel";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    ICashFlowAdjustmentSettingsTemplate
} from "../../adjustment-pricing-grid/model/ICashFlowAdjustmentSettingsTemplate";
import {DetectMethodChanges, isVoid} from "../../utils";
import {SortingRule} from "../model/sorting.rule";
import {SortingAttribute} from "../model/sorting.attribute";
import {RowFilterAttribute} from "../model/RowFilterAttribute";
import {RowFilter} from "../model/row.filter";
import {PackageComparisonHedgeBoardFiltersService} from "./hedgeboard-filtering.service";
import {PackageComparisonHedgeboardSortingService} from "./hedgeboard-sorting.service";
import * as Enumerable from "linq";
import {ChangeDetectorRef, EventEmitter} from "@angular/core";
import {HedgeBoardPinnedService} from "./hedge-board-pinned.service";


export class HedgeBoardViewModelGroup {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _isInvertedCallback: () => boolean
    ) {
        this._sortingServiceCalls = new PackageComparisonHedgeboardSortingService();
        this._sortingServicePuts = new PackageComparisonHedgeboardSortingService();
        this._filterServiceCalls = new PackageComparisonHedgeBoardFiltersService();
        this._filterServicePuts = new PackageComparisonHedgeBoardFiltersService();
    }

    private readonly _sortingServiceCalls : PackageComparisonHedgeboardSortingService;
    private readonly _filterServiceCalls: PackageComparisonHedgeBoardFiltersService;
    private readonly _sortingServicePuts : PackageComparisonHedgeboardSortingService;
    private readonly _filterServicePuts: PackageComparisonHedgeBoardFiltersService;

    pinnedChanged$ = new EventEmitter();

    get shortOptionStrike() : string {
        if (!isVoid(this.calls)) {
            return this.calls[0].shortOptionStrike;
        }

        if (!isVoid(this.puts)) {
            return this.puts[0].shortOptionStrike;
        }

        return 'N/A';
    }

    get rowFiltersCalls(): ReadonlyArray<RowFilter> {
        return this._filterServiceCalls.filters;
    };

    get sortingRulesCalls(): ReadonlyArray<SortingRule> {
        return this._sortingServiceCalls.sortingRules;
    }

    get rowFiltersPuts(): ReadonlyArray<RowFilter> {
        return this._filterServicePuts.filters;
    };

    get sortingRulesPuts(): ReadonlyArray<SortingRule> {
        return this._sortingServicePuts.sortingRules;
    }

    get portfolio() : ApgPortfolio {
        let  model = this.calls[0];
        if (isVoid(model)) {
            model = this.puts[0];
        }
        return model?.portfolio;
    }

    get template(): ICashFlowAdjustmentSettingsTemplate {
        let  model = this.calls[0];
        if (isVoid(model)) {
            model = this.puts[0];
        }
        return model?.template;
    }

    calls: HedgeBoardComparisonModel[] = [];

    puts: HedgeBoardComparisonModel[] = [];

    private _filteredCalls : HedgeBoardComparisonModel[] = [];
    get filteredCalls() : HedgeBoardComparisonModel[] {
        if (isVoid(this._filteredCalls)) {
            let originalPackages = this.calls
                .slice();

            let packages = originalPackages;

            if (!isVoid(this.rowFiltersCalls)) {
                this.rowFiltersCalls.forEach(f => {
                    switch (f.attribute) {
                        case "portfolio":
                            packages = packages
                                .filter(x => {
                                    const key = `${x.portfolio?.userId}@${x.portfolio?.id}`;
                                    return key === f.value;
                                });
                            break;
                        case "template":
                            packages = packages.filter(x => x.template?.templateId === f.value);
                            break;
                        case "adjustment":
                            packages = packages.filter(x => x.adjustment === f.value);
                            break;
                        case "adj_call":
                            packages = packages.filter(x => x.adjustment?.indexOf(f.value) >= 0);
                            break;
                        case "adj_put":
                            packages = packages.filter(x => x.adjustment?.indexOf(f.value) >= 0);
                            break;
                        case "hedge_call":
                        case "hedge_put":
                            packages = packages.filter(x => x.hedge?.strategyId === f.value);
                            break;
                        case "hedge_call_family":
                            packages = packages.filter(x => x.hedge?.templateId === f.value);
                            break;
                        case "hedge_put_family":
                            packages = packages.filter(x => x.hedge?.templateId === f.value);
                            break;
                        case "offset_call":
                        case "offset_put":
                            packages = packages.filter(x => x.offset === f.value);
                            break;
                    }
                });
            }

            if (!isVoid(this.sortingRulesCalls)) {
                if (packages.length > 1) {
                    let enumerable = Enumerable.from(packages);
                    for (let sortingRule of this.sortingRulesCalls) {
                        switch (sortingRule.attribute) {
                            case "portfolio":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.portfolio?.name?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.portfolio?.name?.toLowerCase());
                                break;
                            case "template":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.template?.templateName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.template?.templateName?.toLowerCase());
                                break;
                            case "hedge_call":
                            case "hedge_put":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.hedge?.strategyName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.hedge?.strategyName?.toLowerCase());
                                break;
                            case "expiration":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => {
                                        let price = x.models[sortingRule.data]?.getCallHedgePrice();
                                        if (this._isInvertedCallback()) {
                                            price *= -1;
                                        }
                                        return price  || Number.MAX_SAFE_INTEGER;
                                    })
                                    : enumerable.orderByDescending(x => {
                                        let price = x.models[sortingRule.data]?.getCallHedgePrice();
                                        if (this._isInvertedCallback()) {
                                            price *= -1;
                                        }
                                        return price || Number.MIN_SAFE_INTEGER;
                                    });
                                break;
                            case "adjustment":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.adjustment)
                                    : enumerable.orderByDescending(x => x.adjustment);
                                break;
                            case "hedges_cost_calls":
                            case "hedges_cost_puts":
                            case "hedges_cost_total":
                                break;
                            case "offset_call":
                            case "offset_put":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.offset || Number.MAX_SAFE_INTEGER)
                                    : enumerable.orderByDescending(x => x.offset || Number.MIN_SAFE_INTEGER);
                                break;
                        }
                    }
                    packages = enumerable.toArray();
                }
            }

            packages = packages.sort((a, b) => {
                const aPin = a?.isPinned ? 1 : 0;
                const bPin = b?.isPinned ? 1 : 0;
                return bPin - aPin;
            });

            if (packages.length === 0) {
                this._filteredCalls = originalPackages
            }

            this._filteredCalls = packages;
        }

        return this._filteredCalls;
    }

    private _filteredPuts : HedgeBoardComparisonModel[] = [];
    get filteredPuts() : HedgeBoardComparisonModel[] {
        if (isVoid(this._filteredPuts)) {
            let originalPackages = this.puts
                .slice();

            let packages = originalPackages;

            if (!isVoid(this.rowFiltersPuts)) {
                this.rowFiltersPuts.forEach(f => {
                    switch (f.attribute) {
                        case "portfolio":
                            packages = packages
                                .filter(x => {
                                    const key = `${x.portfolio?.userId}@${x.portfolio?.id}`;
                                    return key === f.value;
                                });
                            break;
                        case "template":
                            packages = packages.filter(x => x.template?.templateId === f.value);
                            break;
                        case "adjustment":
                            packages = packages.filter(x => x.adjustment === f.value);
                            break;
                        case "adj_call":
                            packages = packages.filter(x => x.adjustment?.indexOf(f.value) >= 0);
                            break;
                        case "adj_put":
                            packages = packages.filter(x => x.adjustment?.indexOf(f.value) >= 0);
                            break;
                        case "hedge_call":
                        case "hedge_put":
                            packages = packages.filter(x => x.hedge?.strategyId === f.value);
                            break;
                        case "hedge_call_family":
                            packages = packages.filter(x => x.hedge?.templateId === f.value);
                            break;
                        case "hedge_put_family":
                            packages = packages.filter(x => x.hedge?.templateId === f.value);
                            break;
                        case "offset_call":
                        case "offset_put":
                            packages = packages.filter(x => x.offset === f.value);
                            break;
                    }
                });
            }

            if (!isVoid(this.sortingRulesPuts)) {
                if (packages.length > 1) {
                    let enumerable = Enumerable.from(packages);
                    for (let sortingRule of this.sortingRulesPuts) {
                        switch (sortingRule.attribute) {
                            case "portfolio":
                                enumerable = sortingRule.order === 'asc'
                                        ? enumerable.orderBy(x => x.portfolio?.name?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.portfolio?.name?.toLowerCase());
                                break;
                            case "template":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.template?.templateName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.template?.templateName?.toLowerCase());
                                break;
                            case "hedge_call":
                            case "hedge_put":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.hedge?.strategyName?.toLowerCase())
                                    : enumerable.orderByDescending(x => x.hedge?.strategyName?.toLowerCase());
                                break;
                            case "expiration":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => {
                                        let price = x.models[sortingRule.data]?.getPutHedgePrice();
                                        if (this._isInvertedCallback()) {
                                            price *= -1;
                                        }
                                        return price || Number.MAX_SAFE_INTEGER;
                                    })
                                    : enumerable.orderByDescending(x => {
                                        let price = x.models[sortingRule.data]?.getPutHedgePrice();
                                        if (this._isInvertedCallback()) {
                                            price *= -1;
                                        }
                                        return price || Number.MIN_SAFE_INTEGER;
                                    });
                                break;
                            case "adjustment":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.adjustment)
                                    : enumerable.orderByDescending(x => x.adjustment);
                                break;
                            case "hedges_cost_calls":
                            case "hedges_cost_puts":
                            case "hedges_cost_total":
                                break;
                            case "offset_call":
                            case "offset_put":
                                enumerable = sortingRule.order === 'asc'
                                    ? enumerable.orderBy(x => x.offset || Number.MAX_SAFE_INTEGER)
                                    : enumerable.orderByDescending(x => x.offset || Number.MIN_SAFE_INTEGER);
                                break;
                        }
                    }
                    packages = enumerable.toArray();
                }
            }

            packages = packages.sort((a, b) => {
                const aPin = a?.isPinned ? 1 : 0;
                const bPin = b?.isPinned ? 1 : 0;
                return bPin - aPin;
            });

            if (packages.length === 0) {
                this._filteredPuts = originalPackages
            }

            this._filteredPuts = packages;
        }

        return this._filteredPuts;
    }


    getSortingState(side: 'calls' | 'puts', attribute: SortingAttribute, data?: any)  {
        const rules = side === 'calls' ? this.sortingRulesCalls : this.sortingRulesPuts;
        return this.getSortingStateInternal(rules, attribute, data);
    }

    @DetectMethodChanges()
    changeSortingAttribute(side: 'calls' | 'puts', attribute: SortingAttribute, data?: any) {
        const rules = side === 'calls' ? this.sortingRulesCalls : this.sortingRulesPuts;
        try {
            this.changeSortingAttributeInternal(side,rules, attribute, data);
        } finally {
            if (side === 'calls') this._filteredCalls = undefined;
            if (side === 'puts') this._filteredPuts = undefined;
        }
    }

    isFilterEnabled(side: 'calls' | 'puts', attribute: RowFilterAttribute) : boolean {
        const filters = side === 'calls' ? this.rowFiltersCalls : this.rowFiltersPuts;
        return this.isFilterEnabledInternal(filters, attribute);
    }

    private getSortingStateInternal(rules: ReadonlyArray<SortingRule>, attribute: SortingAttribute, data = undefined) {
        const sortingRule = rules
            .find(x => x.attribute === attribute && x.data === data);
        return sortingRule?.order;
    }

    private isFilterEnabledInternal(filters: ReadonlyArray<RowFilter>, attribute: RowFilterAttribute) {

        if (isVoid(attribute)) {
            return false;
        }

        if (isVoid(filters)) {
            return false;
        }

        let isEnabled = filters.findIndex(x => x.attribute === attribute) >= 0;

        if (!isEnabled && attribute.startsWith('hedge_')) {
            const attr = attribute + '_family';
            isEnabled = filters.findIndex(x => x.attribute === attr) >= 0;
        } else if (!isEnabled && attribute === 'adjustment') {
            isEnabled = filters.some(x => x.attribute.startsWith('adj_'));
        }

        return isEnabled;
    }

    private changeSortingAttributeInternal(side: 'calls' | 'puts', rules: ReadonlyArray<SortingRule>, attribute: SortingAttribute, data?: any) {
        const sortingRule = rules.find(x => x.attribute === attribute);

        const sortingService = side === 'calls'
            ? this._sortingServiceCalls
            : this._sortingServicePuts;

        if (isVoid(sortingRule)) {
            const rule: SortingRule = {
                attribute,
                order: 'asc',
                data
            };
            sortingService.addSortingRule(rule);
        } else if (sortingRule.order === 'asc') {
            sortingRule.order = 'desc';
        } else if (sortingRule.order === 'desc') {
            const ix = rules.indexOf(sortingRule);
            sortingService.removeAt(ix);
        }

        sortingService.sortRules();
    }

    @DetectMethodChanges()
    disableFilter(side: 'calls' | 'puts', filter: RowFilterAttribute) {
        const filters = side === 'calls' ? this.rowFiltersCalls : this.rowFiltersPuts;

        const service = side === 'calls'
            ? this._filterServiceCalls
            : this._filterServicePuts;

        const ix = filters.findIndex(x => x.attribute === filter);

        if (ix < 0) {
            if (filter === 'adjustment') {
                const adjFilters = filters
                    .filter(x => x.attribute.startsWith('adj_'));
                service.remove(adjFilters);
            }
            return;
        }

        service.removeAt(ix);

        if (side === 'calls') this._filteredCalls = undefined;
        if (side === 'puts') this._filteredPuts = undefined;
    }

    @DetectMethodChanges()
    onFilterRequest(side: 'calls' | 'puts', filter: RowFilter) {

        const service = side === 'calls' ? this._filterServiceCalls : this._filterServicePuts;

        const filters = side === 'calls' ? this.rowFiltersCalls : this.rowFiltersPuts;

        try {

            if (isVoid(filter)) {
                service.clearFilters();
                return;
            }

            if (filter === 'adjustment') {
                const hasAdjustments = filters
                    .filter(x => x.attribute.startsWith('adj_'));

                if (hasAdjustments) {
                    service.remove(hasAdjustments);
                    return;
                }
            }

            const ix = filters.findIndex(x => x.attribute === filter.attribute);

            if (ix < 0) {
                service.addFilter(filter);
            } else {
                const existing = filters[ix];
                if (existing.value === filter.value) {
                    service.removeAt(ix);
                } else {
                    service.replaceAt(ix, filter);
                }
            }
        } finally {
            if (side === 'calls') this._filteredCalls = undefined;
            if (side === 'puts') this._filteredPuts = undefined;
        }

    }

    @DetectMethodChanges()
    onPinnedChanged(side: 'calls' | 'puts') {
        if (side === 'calls') {
            this._filteredCalls = undefined;
        } else if (side === 'puts') {
            this._filteredPuts = undefined;
        }
        this.pinnedChanged$.emit();
    }

    @DetectMethodChanges()
    clearSorting() {
        this._sortingServicePuts.clearSortingRules();
        this._sortingServiceCalls.clearSortingRules();

        this._filteredPuts = undefined;
        this._filteredCalls = undefined;
    }

    @DetectMethodChanges()
    clearFilters() {
        this._filterServiceCalls.clearFilters();
        this._filterServicePuts.clearFilters();

        this._filteredPuts = undefined;
        this._filteredCalls = undefined;
    }

    resetSortingState() {
        this._filteredCalls = undefined;
        this._filteredPuts = undefined;
    }
}