import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { DevExtremeModule } from '../devextreme.module';

import { MarginRequirementsComponent } from './margin-requirements.component';

@NgModule({
   imports: [
      CommonModule,
      DevExtremeModule,
      AgGridModule.withComponents([])
   ],
   exports: [],
   declarations: [MarginRequirementsComponent],
   providers: [],
})
export class MarginRequirementsModule { }
