<div #container [ngClass]="{'ets-hidden': !isActive}" class="panel-container">
  
  <div class="split">
    
    <div [ngClass]="{'collapsed': !this.callsSidePanelVisible}" class="side-panel calls">
      <ng-container *ngTemplateOutlet="sidePanel; context: {$implicit: {header: 'CALLS', targetSide: 'calls' }}">
      </ng-container>
    </div>
    
    <div class="center">
      
      <div class="control-panel">
        
        <div class="block asset">
          
          <div class="ul-exp">
            
            <div class="underlying">
            <ets-symbol-picker (instrumentSelected)="this.onSymbolSelected($event)"
                               [selectedInstrument]="this.tradingInstrument"
                               [disabled]="!this.canChangeUnderlying()"
            ></ets-symbol-picker>
          </div>
            
            <div class="expiration-date">
              <p class="label">Expiration</p>
              <dx-select-box
                (onValueChanged)="this.onExpirationSelected($event)" [(value)]="this.selectedExpiration"
                [dataSource]="this.availableExpirations" [disabled]="!this.tradingInstrument || !this.canChangeExpiration()"
                [inputAttr]="this.selectedExpiration?.expirationTicker === '@SPX' ? {'style': 'color: yellow'} : {'style': 'color: undefined'}"
                displayExpr="dateWithDaysToExpiration"
              >
                <div *dxTemplate="let data of 'item'">
                          <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                             {{ data?.dateWithDaysToExpiration }}
                          </span>
                </div>
              </dx-select-box>
            </div>
            
          </div>
          
          <div class="default-qty">
            <div class="qty">
              <div class="label">Default Qty</div>
              <div class="input">
                <dx-number-box [inputAttr]="{style: 'text-align: center;'}"
                               [(value)]="this.defaultQty"
                               (onValueChanged)="this.onDefaultQtyChanged($event)"
                               [min]="1"
                ></dx-number-box>
              </div>
            </div>
            <div class="scroll">
              <dx-check-box text="Auto Scroll/Size" [(value)]="this.autoSizeColumns"></dx-check-box>
            </div>
          </div>
          
        </div>
        
        <div class="block strike-step ets-dx-number">
          <p class="label">Strike Step</p>
          <dx-number-box
            (onValueChanged)="this.onStrikeStepChanged($event)"
            [(value)]="this.strikeStep"
            [disabled]="!this.subControlsEnabled" [min]="0.5"
            [showSpinButtons]="true" [step]="0.5"
            [useLargeSpinButtons]="false"></dx-number-box>
        </div>
        
        <div class="block center-strike">
          <p class="label">ATM Strike</p>
          <ets-strikes-dropdown
            (strikeChanged)="this.onAtmStrikeChanged($event)"
            [expiration]="this.expiration"
            [showClearButton]="true"
            [strike]="this.atmStrike"
            [targetInstrument]="this.tradingInstrument"
          >
          </ets-strikes-dropdown>
        </div>
        
        <div class="block num-of-strikes ets-dx-number">
          <p class="label"># of Strikes</p>
          <dx-number-box
            (onValueChanged)="this.onStrikesNumberChanged($event)"
            [(value)]="this.numOfStrikes"
            [disabled]="!this.subControlsEnabled"
            [inputAttr]="{'style': 'text-align: center'}" [min]="0"
            [showSpinButtons]="true"
            [useLargeSpinButtons]="false">
          </dx-number-box>
        </div>
        
        <div class="block target-range">
          
          <div class="row top">
            <div class="side">
              
              <ng-container *ngIf="!this.isCustomRange">
                <div class="start-end">
                  <div class="block target-range-start">
                    <p class="label">Call Offset</p>
                    <div class="range">
                      <dx-number-box
                        [inputAttr]="{style: 'text-align: center'}"
                        (onValueChanged)="this.onTargetRangeChanged()"
                        [(value)]="this.callOffset"
                        [disabled]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                        [showClearButton]="true"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                  
                  <div class="block target-range-end">
                    <p class="label">Call Range</p>
                    <div class="range">
                      <dx-number-box
                        (onValueChanged)="this.onTargetRangeChanged()"
                        [inputAttr]="{style: 'text-align: center'}"
                        [(value)]="this.callRange"
                        [disabled]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                        [showClearButton]="true"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                </div>
              </ng-container>
              
              <ng-container *ngIf="this.isCustomRange">
                <div class="start-end">
                  <div class="block target-range-start">
                    <p class="label">C. Range Start</p>
                    <div class="range">
                      <ets-strikes-dropdown
                        (strikeChanged)="this.onTargetRangeChanged()"
                        [(strike)]="this.callsTargetRangeStart"
                        [disable]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                        [expiration]="this.expiration"
                        [showClearButton]="true"
                        [targetInstrument]="this.tradingInstrument"
                      >
                      </ets-strikes-dropdown>
                    </div>
                  </div>
                  
                  <div class="block target-range-end">
                    <p class="label">C. Range End</p>
                    <div class="range">
                      <ets-strikes-dropdown
                        (strikeChanged)="this.onTargetRangeChanged()"
                        [(strike)]="this.callsTargetRangeEnd"
                        [disable]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                        [expiration]="this.expiration"
                        [showClearButton]="true"
                        [targetInstrument]="this.tradingInstrument"
                      >
                      </ets-strikes-dropdown>
                    </div>
                  </div>
                </div>
              </ng-container>
              
            </div>
            <div class="side">
              
              <ng-container *ngIf="!this.isCustomRange">
                <div class="start-end">
                  <div class="block target-range-start">
                    <p class="label">Put Offset</p>
                    <div class="range">
                      <dx-number-box
                        [inputAttr]="{style: 'text-align: center'}"
                        (onValueChanged)="this.onTargetRangeChanged()"
                        [(value)]="this.putOffset"
                        [disabled]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                        [showClearButton]="true"
                      >
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="block target-range-end">
                    <p class="label">Put Range</p>
                    <div class="range">
                      <dx-number-box
                        [inputAttr]="{style: 'text-align: center'}"
                        (onValueChanged)="this.onTargetRangeChanged()"
                        [(value)]="this.putRange"
                        [disabled]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                        [showClearButton]="true"
                      ></dx-number-box>
                    </div>
                  </div>
                </div>
              </ng-container>
              
              <ng-container *ngIf="this.isCustomRange">
                  <div class="start-end">
                    <div class="block target-range-start">
                      <p class="label">P. Range Start</p>
                      <div class="range">
                        <ets-strikes-dropdown
                          (strikeChanged)="this.onTargetRangeChanged()"
                          [(strike)]="this.putsTargetRangeStart"
                          [disable]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                          [expiration]="this.expiration"
                          [showClearButton]="true"
                          [targetInstrument]="this.tradingInstrument"
                        >
                        </ets-strikes-dropdown>
                      </div>
                    </div>
                    <div class="block target-range-end">
                      <p class="label">P. Range End</p>
                      <div class="range">
                        <ets-strikes-dropdown
                          (strikeChanged)="this.onTargetRangeChanged()"
                          [(strike)]="this.putsTargetRangeEnd"
                          [disable]="this.pricingRows.length === 0 || !this.subControlsEnabled"
                          [expiration]="this.expiration"
                          [showClearButton]="true"
                          [targetInstrument]="this.tradingInstrument"
                        >
                        </ets-strikes-dropdown>
                      </div>
                    </div>
                  </div>
                </ng-container>
            
            </div>
          </div>
          
          <div class="row bottom">
            <div class="checkbox">
              <div class="propagate">
                <dx-check-box
                  (onValueChanged)="this.togglePropagateRange('calls')"
                  [(value)]="this.callsPropagateRange"
                  text="Prop. To Puts"
                >
                </dx-check-box>
              </div>
            </div>
            <div class="checkbox">
              <dx-check-box
                (onValueChanged)="this.onTargetRangeChanged()"
                [(value)]="this.isCustomRange"
                text="Custom Range"
              ></dx-check-box>
            </div>
            <div class="checkbox">
              <div class="propagate">
                <dx-check-box
                  (onValueChanged)="this.togglePropagateRange('puts')"
                  [(value)]="this.putsPropagateRange"
                  text="Prop. To Calls"
                >
                </dx-check-box>
              </div>
            </div>
          </div>
        
        </div>
        
        <div *ngIf="this.mode === 'opg'" class="block percentage-profit ets-dx-number">
          <p class="label">% - Profit</p>
          <dx-number-box
            (onValueChanged)="this.onPercentProfitChanged($event)"
            [(value)]="this.percentageProfit"
            [disabled]="this.pricingRows.length === 0 || !this.subControlsEnabled" [min]="0"
            [showSpinButtons]="true" [step]="1"
            [useLargeSpinButtons]="false"></dx-number-box>
        </div>
        
        <div *ngIf="this.mode === 'opg'" class="block r-multiple ets-dx-number">
          <p class="label">R Multiple</p>
          <div>
            <dx-number-box
              (onValueChanged)="this.onRMultipleChanged($event)"
              [(value)]="this.rMultiple"
              [disabled]="!this.subControlsEnabled"
              [inputAttr]="{'style': 'text-align: center'}"
              [min]="1" [showSpinButtons]="true"
              [step]="0.1" [useLargeSpinButtons]="false"
            >
            </dx-number-box>
          </div>
        </div>
        
        <div  class="block show-itm">
          <p class="label">ITM Data</p>
          <div class="cb">
            <dx-check-box
              (onValueChanged)="this.onShowItmDataChanged($event)"
              [(value)]="this.showItmData"
              [disabled]="!this.subControlsEnabled">
            </dx-check-box>
          </div>
        </div>
      
      </div>
      
      <hr class="separator"/>
      
      <div class="board">
        <ag-grid-angular [gridOptions]="this.gridOptions" class="ag-theme-balham-dark"
                         style="width: 100%; height: 100%;">
        </ag-grid-angular>
      </div>
    
    </div>
    
    <div [ngClass]="{'collapsed': !this.putsSidePanelVisible}" class="side-panel puts">
      <ng-container *ngTemplateOutlet="sidePanel; context: {$implicit: {header: 'PUTS', targetSide: 'puts' }}">
      </ng-container>
    </div>
  
  </div>

</div>

<ng-template #sidePanel let-ctx>
  
  <div *ngIf="!this.isSidePanelVisible(ctx.targetSide)" class="content empty">
    <div (click)="this.toggleSidePanel(ctx.targetSide)" class="side-header collapsed">
      <p>{{ ctx.header }}</p>
    </div>
  </div>
  
  <div *ngIf="this.isSidePanelVisible(ctx.targetSide)" class="content">
    <div (click)="this.toggleSidePanel(ctx.targetSide)" class="side-header">
      <p>{{ ctx.header }}</p>
    </div>
    
    <ng-container *ngIf="this.mode === 'pmd'">
      
      <hr class="separator">
      
      <div class="hedge-selector">
        
        <div class="title">
          <div class="text">{{ this.getHedgesSectionTitle(ctx.targetSide) }}</div>
          <div class="icon" (click)="this.onChangeHedgesModeClicked(ctx.targetSide)">
            <i class="fas fa-exchange-alt fa-sm"></i>
          </div>
        </div>
        
        <div class="existing" [style.display]="this.getPmdHedgeMode(ctx.targetSide) === 'existing' ? 'block':'none'">
          
          <div class="portfolio">
            <div class="label" [class.right]="ctx.targetSide === 'puts'">
              Select Portfolio
            </div>
            
            <div class="input">
              <dx-select-box
                [items]="this.portfolioList"
                [value]="this.getSelectedPortfolio(ctx.targetSide)"
                (valueChange)="this.onPortfolioSelecting($event, ctx.targetSide)"
                [grouped]="true"
                [displayExpr]="'name'"
                fieldTemplate="field"
              >
                <div *dxTemplate="let data of 'field'">
                  <div>
                    <dx-text-box [readOnly]="true"
                                 [value]="data && data.userName + '  |  ' + data.name"
                                 placeholder="Select portfolio...">
                    </dx-text-box>
                  </div>
                </div>
              </dx-select-box>
            </div>
          </div>
          
          <div class="hedge">
            <div class="label" [class.right]="ctx.targetSide === 'puts'">
              Select Hedge
            </div>
            <div class="input" [class.right]="ctx.targetSide === 'puts'">
              <div class="selector">
                <dx-select-box placeholder="Hedge"
                               [items]="this.getHedgesList(ctx.targetSide)"
                               [value]="this.getSelectedHedge(ctx.targetSide)"
                               (onValueChanged)="this.onHedgeSelected($event, ctx.targetSide)"
                               [displayExpr]="'label'"
                >
                </dx-select-box>
              </div>
              <div class="price"
                   [class.debit]="this.getHedgesCost(ctx.targetSide) < 0"
                   [class.credit]="this.getHedgesCost(ctx.targetSide) > 0"
              >
                {{ this.getHedgesCost(ctx.targetSide)
                  | currency:'USD':'symbol':'1.2-2' }}
              </div>
            </div>
          </div>
        </div>
        
        <div class="manual" [style.display]="this.getPmdHedgeMode(ctx.targetSide) === 'existing' ? 'none':'block'">
          
          <div class="legs-list">
            
            <div class="leg header">
              <div class="block qty">Qty</div>
              <div class="block side">Side</div>
              <div class="block strike">Strike</div>
              <div class="block remove" (click)="this.removeCustomLeg(null, ctx.targetSide)">
                <i class="far fa-trash-alt fa-xs"></i>
              </div>
            </div>
            
            <div class="leg" *ngFor="let leg of this.getCustomLegs(ctx.targetSide)">
              <div class="block qty">
                <dx-number-box [inputAttr]="{style: 'text-align: center'}"
                               [(value)]="leg.qty"
                               [min]="1"
                ></dx-number-box>
              </div>
              <div class="block side">
                <dx-select-box [items]="this.customLegSideList"
                               [displayExpr]="'name'"
                               [(value)]="leg.side"
                               [inputAttr]="this.getStyleForCustomLegSide(leg)"
                >
                  <div *dxTemplate="let data of 'item'">
                      <span [style.color]="data?.name === 'Buy' ? 'cornflowerblue' : 'red'"> {{ data?.name }}
                      </span>
                  </div>
                </dx-select-box>
              </div>
              <div class="block strike">
                <ets-strikes-dropdown [expiration]="this.selectedExpiration"
                                      [targetInstrument]="this.tradingInstrument"
                                      [(strike)]="leg.strike"
                ></ets-strikes-dropdown>
              </div>
              <div class="block remove" (click)="this.removeCustomLeg(leg, ctx.targetSide)">
                <i class="far fa-trash-alt fa-sm"></i>
              </div>
            </div>
          </div>
          
          <hr class="separator" [style.width.%]="30">
          
          <div>
            <dx-button text="Add Leg" width="100%" (onClick)="this.addCustomLeg(ctx.targetSide)"></dx-button>
          </div>
        </div>
        
      </div>
      
    </ng-container>
    
    <hr class="separator">
    
    <div class="strategies-list">
    
      <dx-scroll-view [direction]="'vertical'">
        <div class="scroll-container">
          <div class="template">
            <p class="label">Load Template</p>
            <div class="controls">
              <div class="template-list">
                <dx-select-box
                  (onValueChanged)="this.onTemplateSelected(ctx.targetSide, $event)"
                  [items]="this.getTemplates(ctx.targetSide)"
                  [value]="ctx.targetSide === 'calls' ? this.callsSelectedTemplate : this.putsSelectedTemplate"
                  [showClearButton]="true"
                  displayExpr="templateName"
                  placeholder="Template"
                >
                </dx-select-box>
              </div>
              <div class="template-controls">
                <div class="template-save icon">
                  <dx-button
                    (onClick)="this.newTemplate(ctx.targetSide)"
                    [disabled]="!this.canNewTemplate(ctx.targetSide)"
                    text="New"
                    type="success"
                    width="100%"
                  >
                  </dx-button>
                </div>
                <div class="template-save icon">
                  <dx-button
                    (onClick)="this.saveTemplate(ctx.targetSide)"
                    [disabled]="!this.canSaveTemplate(ctx.targetSide)"
                    text="Save"
                    type="default"
                    width="100%"
                  >
                  </dx-button>
                </div>
                <div class="template-rename icon">
                  <dx-button
                    (onClick)="this.editTemplate(ctx.targetSide)"
                    [disabled]="!this.canRenameTemplate(ctx.targetSide)"
                    text="Edit"
                    width="100%"
                  >
                  </dx-button>
                </div>
                <div class="template-remove icon">
                  <dx-button
                    (onClick)="this.removeTemplate(ctx.targetSide)"
                    [disabled]="!this.canRemoveTemplate(ctx.targetSide)"
                    text="Delete"
                    type="danger"
                    width="100%"
                  >
                  </dx-button>
                </div>
              </div>
            </div>
          </div>
          
          <hr class="template-separator">
          
          <div class="apply-all">
            <div class="apply">
              <dx-button
                (onClick)="this.applyAllStrategies(ctx.targetSide)"
                [disabled]="!this.canApplyAllStrategies(ctx.targetSide)"
                text="Apply All Strategies"
                type="default"
                width="100%">
              </dx-button>
            </div>
            <div class="live">
              <dx-check-box text="Live"
                            [value]="this.getLiveStatus(ctx.targetSide)"
                            (valueChange)="this.setLiveStatus(ctx.targetSide, $event)"
                ></dx-check-box>
            </div>
          </div>
          
          <hr class="template-separator">
          
          <div *ngFor="let strategy of this.getGridStrategies(ctx.targetSide); index as i" class="comparison-strategy">
            
            <div class="header">
              <div>
                <h4 (dblclick)="strategy.onHeaderDblClick($event)"
                    *ngIf="!strategy.isEditingHeader"
                >
                  {{ strategy.strategyName }}
                </h4>
                
                <div style="margin-bottom: 5px;">
                  <dx-text-box
                      #iam
                      (onBlur)="strategy.onHeaderCancelEditing()"
                      (onInitialized)="strategy.onHeaderTextBox(iam)"
                      (onKeyUp)="strategy.onTextBoxKeyUp($event, iam.text)"
                      (onValueChanged)="strategy.onHeaderChanged($event)"
                      *ngIf="strategy.isEditingHeader"
                      [inputAttr]="{'style': 'text-align: center'}"
                      [value]="strategy.strategyName">
                  </dx-text-box>
                </div>
              </div>
            </div>
            
            <div class="controls">
              <div class="block leg-buttons">
                <div class="btn add-leg">
                  <dx-button (onClick)="strategy.addLeg()"
                             [disabled]="!this.tradingInstrument || !this.selectedExpiration" hint="Add Leg"
                             icon="add">
                  </dx-button>
                </div>
                <div class="btn reverse">
                  <dx-button (onClick)="strategy.reverseLegsSide()" [disabled]="strategy.strategyLegs.length === 0"
                             hint="Reverse Side"
                             icon="pulldown">
                  </dx-button>
                </div>
              </div>
              
              <div class="block strategy">
                <dx-select-box
                  (onValueChanged)="strategy.onStrategySelected($event)"
                  [(value)]="strategy.selectedStrategy"
                  [dataSource]="this.strategiesList"
                  [disabled]="!this.tradingInstrument || !this.selectedExpiration"
                  [grouped]="true"
                  [showClearButton]="true"
                  displayExpr="strategy" placeholder="Strategy" valueExpr="strategy">
                </dx-select-box>
              </div>
              
              <div class="block width ets-dx-number">
                <dx-number-box [(value)]="strategy.width" [showSpinButtons]="true" placeholder="Width">
                </dx-number-box>
              </div>
            </div>
            
            <table class="legs">
              <thead>
              <tr class="leg">
                <td class="block remove">
                </td>
                <td class="block side">Side</td>
                <td class="block qty">Qty</td>
                <td class="block width">Width</td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let leg of strategy.strategyLegs" class="leg">
                <td class="block remove">
                  <i (click)="strategy.removeLeg(leg)" *ngIf="leg.type === 'leg'"
                     class="far fa-trash-alt icon"></i>
                </td>
                <td class="block side">
                  <dx-select-box (onValueChanged)="strategy.onSomethingChanged()" *ngIf="leg.type === 'leg'"
                                 [(value)]="leg.side" [dataSource]="['Buy', 'Sell']"
                                 [inputAttr]="{style: this.getLegColor(leg)}"
                  >
                    <div *dxTemplate="let data of 'item'">
                      <span [style.color]="data === 'Buy' ? 'cornflowerblue' : 'red'"> {{ data }}
                      </span>
                    </div>
                  </dx-select-box>
                </td>
                <td class="block qty ets-dx-number">
                  <dx-number-box
                    (onValueChanged)="strategy.onSomethingChanged()" *ngIf="leg.type === 'leg'"
                    [(value)]="leg.qty" [inputAttr]="{'style': 'text-align: center'}" [min]="1"
                    [showSpinButtons]="true"
                    [step]="1"
                    [useLargeSpinButtons]="false">
                  </dx-number-box>
                </td>
                <td class="block width">
                  <dx-number-box
                    (onValueChanged)="strategy.onWidthChanged()" *ngIf="leg.type === 'width'"
                    [(value)]="leg.width"
                    [inputAttr]="{'style': 'text-align: center'}" [min]="0.5"
                    [showSpinButtons]="true" [useLargeSpinButtons]="false">
                  </dx-number-box>
                </td>
              </tr>
              </tbody>
            </table>
            
            <div class="buttons">
              <div class="btn remove">
                <dx-button (onClick)="this.removeStrategy(ctx.targetSide,strategy)" text="Remove">
                </dx-button>
              </div>
              
              <div class="btn apply">
                <dx-button
                  (onClick)="strategy.applyLegs()"
                  [disabled]="!strategy.canApplyLegs" text="Apply"
                  type="default"
                >
                </dx-button>
              </div>
            </div>
            
            <hr class="strategy-separator">
          
          </div>
          
          <div class="buttons">
            <dx-button (onClick)="this.addStrategy(ctx.targetSide)" text="Add Strategy">
            </dx-button>
          </div>
        
        </div>
      </dx-scroll-view>
    
    </div>
  </div>
</ng-template>

<dx-load-panel
  [position]="{ my: 'center', at: 'center', of: container }"
  [visible]="isLoading"
>
</dx-load-panel>

<dx-popup
  (onHidden)="this.editTemplatePopupModel.onCancelClicked()"
  [closeOnOutsideClick]="false"
  [visible]="this.editTemplatePopupModel.visible"
  height="150" title="Save As Template"
  width="350"
>
  <div *dxTemplate="let data of 'content'" class="opg-edit-template">
    
    <div class="input">
      <dx-text-box
        [(value)]="this.editTemplatePopupModel.templateName"
        placeholder="Template name">
      </dx-text-box>
    </div>
    
    <div class="buttons">
      <div class="btn">
        <dx-button (onClick)="this.editTemplatePopupModel.onSaveClicked()" text="Save" width="100%">
        </dx-button>
      </div>
      <div class="btn">
        <dx-button (onClick)="this.editTemplatePopupModel.onCancelClicked()" text="Cancel" width="100%">
        </dx-button>
      </div>
    </div>
  
  </div>
</dx-popup>

<ets-generic-confirmation-dialog
  confirmText="Yes"
  rejectText="No"
  title="Delete Template?"
>
</ets-generic-confirmation-dialog>

<ets-orders-verification-dialog>
</ets-orders-verification-dialog>