// tslint:disable: no-string-literal

import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { GlobalSettings } from 'projects/shared-components/adjustment-control-panel/adjustment-control-panel.component';
import { ExpirationOffsetMode, RollTimeMode } from 'projects/shared-components/adjustment-strategies/put-debit-spread-roll/put-debit-spread-roll-strategy-parameters';
import { OrderLeg } from 'projects/shared-components/multi-trade-pad/multi-leg-order/order-leg.class';
import { ActionType, OrderType } from 'projects/shared-components/multi-trade-pad/multi-trade-pad.model';
import { ConvertToMarketSettings } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { StrategyModel } from 'projects/shared-components/strategies/strategy-model';
import { DetectMethodChanges, DetectSetterChanges, DxValueChanged } from 'projects/shared-components/utils';
import { isNullOrUndefined } from 'util';
import { AAStrategyParameters, AAStrategyValidationContext, OrderLegWrapper } from '../../adjustment-strategy-dialog.model';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';

export class LongOptionParameters implements AAStrategyParameters {
   
   constructor(private _changeDetector: ChangeDetectorRef) { 
      
      this.displayName = 'Long Option Adjustment';
      
   }

   strategy: CashFlowStrategy;

   get isSingleDirection(): boolean {
      return this.strategy !== 'Calls & Puts';
   }

   private _expirationOffsetModesRegular = [
      'Calendar Days',
      'Sequential'
   ];

   private _expirationOffsetModesAutomated =[
      'Calendar Days',
   ];

   //
   displayName = 'Protective Option';

   //
   changed$: EventEmitter<void> = new EventEmitter();

   //
   rollDaysBeforeExpiration: number;

   //
   private _rollTimeMode : RollTimeMode;
   get rollTimeMode() : RollTimeMode {
      return this._rollTimeMode;
   }

   @DetectSetterChanges()
   set rollTimeMode(v : RollTimeMode) {
      this._rollTimeMode = v;
   }
   
   //

   rollTimeModes: RollTimeMode[] = [
      'Time Of Day',
      '[H:M:S] Before Close',
      '[H:M:S] After Open',
   ];

   //

   rollTime: string;

   //

   rollTimezone: string;

   //

   get rollTimePickerMode(): string {

      if (!this.rollTimeMode) {
         return undefined;
      }

      if (this.rollTimeMode === 'Time Of Day') {
         return 'time';
      }

      return 'timespan';

   }

   //

   get rollTimePickerShowTimezone(): boolean {
      return this.rollTimePickerMode === 'time';
   }


   //
   expirationOffsetModes: ExpirationOffsetMode[] = [];

   //
   expirationOffsetMode: ExpirationOffsetMode;

   //
   private _expirationOffsetValue : number;
   get expirationOffsetValue() : number {
      return this._expirationOffsetValue;
   }
   @DetectSetterChanges()
   set expirationOffsetValue(v : number) {
      this._expirationOffsetValue = v;
   }

   //
   get expirationValueOffsetFormat(): string {
      if (this.expirationOffsetMode === 'Sequential') {
         return undefined;
      }

      return ' #0d';
   }

   //
   expirationPreference: 'Near' | 'Far' = 'Near';

   //
   atmNeutralZone: number;
   
   //
   readonly orderTypes: { value: OrderType, name: string }[] = [
      {
         name: 'Market',
         value: OrderType.Market
      },
      {
         name: 'Limit',
         value: OrderType.Limit
      }
   ];
   
   //
   private _orderType = OrderType.Limit;
   get orderType(): OrderType {
      return this._orderType;
   }
   set orderType(v: OrderType) {
      this._orderType = v;
      if (this._orderType !== OrderType.Limit) {
         this.convertToMarket = false;
      }
   }

   //
   private _convertToMarket = false;
   get convertToMarket(): boolean {
      return this._convertToMarket;
   }
   set convertToMarket(val: boolean) {
      this._convertToMarket = val;

      if (!this._convertToMarket) {
         this.convertToMarketSettings = {};
      }
   }

   //
   debitSpreadOffset: number;
   
   debitSpreadOffset2: number;

   //
   convertToMarketSettings: ConvertToMarketSettings = {};

   //
   onChange(ev: DxValueChanged<any>) {
      this._changeDetector.detectChanges();
      
      if (ev.event) {
         this.changed$.emit();
      }
   }

   //
   getParameters(commonSettings?: GlobalSettings, strategyNo?: number): string {
      let params = `rolldaysbeforeexpiration::=${this.rollDaysBeforeExpiration}`;

      if (commonSettings) {
         const rollSettings = commonSettings.rollTimeSettings;
         params += `(~)rolltimemode::=${rollSettings.rollTimeMode || ''}`;
         params += `(~)rolltime::=${rollSettings.rollTime || ''}`;
         params += `(~)rolltimezone::=${rollSettings.rollTimezone || ''}`;
      } else {
         params += `(~)rolltimemode::=${this.rollTimeMode || ''}`;
         params += `(~)rolltime::=${this.rollTime || ''}`;
         params += `(~)rolltimezone::=${this.rollTimezone || ''}`;
      }

      params += `(~)expirationoffsetmode::=${this.expirationOffsetMode || ''}`;
      params += `(~)expirationoffsetvalue::=${this.expirationOffsetValue || ''}`;
      params += `(~)expirationpreference::=${this.expirationPreference || ''}`;

      params += `(~)ordertype::=${this.orderType || ''}`;
      params += `(~)converttomarketmode::=${this.convertToMarketSettings.actionTimeMode || ''}`;
      params += `(~)converttomarkettime::=${this.convertToMarketSettings.actionTime || ''}`;
      params += `(~)timezone::=${this.convertToMarketSettings.timezone || ''}`;

      if (strategyNo === 2) {
         params += `(~)debitspreadoffset::=${this.debitSpreadOffset2 || ''}`;
      } else {
         params += `(~)debitspreadoffset::=${this.debitSpreadOffset || ''}`;
      }

      return params;
   }

   //
   setParameters(strategy: StrategyModel) {
      const p = strategy.parameters as any;
    
      this.displayName = strategy.displayName;

      this.rollDaysBeforeExpiration = parseInt(p.rolldaysbeforeexpiration);

      this.rollTimeMode = p.rolltimemode;
      this.rollTimezone = p.rolltimezone;
      this.rollTime = p.rolltime;

      this.expirationOffsetMode = p.expirationoffsetmode;
      if (!isNullOrUndefined(p.expirationoffsetvalue)) {
         this.expirationOffsetValue = parseInt(p.expirationoffsetvalue);
      }

      const oType = p['ordertype'];
      this.orderType = oType ? parseInt(oType) : null;

      const convertToMarketMode = p['converttomarketmode'] as any;
      if (convertToMarketMode) {
         this.convertToMarket = true;
         this.convertToMarketSettings.actionTimeMode = convertToMarketMode;
         this.convertToMarketSettings.actionTime = p['converttomarkettime'];

         const tz = p['timezone'];
         this.convertToMarketSettings.timezone = tz;
      }

      if (p.debitspreadoffset) {
         this.debitSpreadOffset = parseInt(p.debitspreadoffset);
      }
   }

   //
   setParameters2(strategy: StrategyModel) {
      const p = strategy.parameters as any;

      if (p.debitspreadoffset) {
         this.debitSpreadOffset2 = parseInt(p.debitspreadoffset);
      }

      setTimeout(() => {

         // const old = this.expirationOffsetValue;
         
         // this.expirationOffsetValue = null;
         
         // setTimeout(() => {
         //    this.expirationOffsetValue = old;
         // }, 250);

         this.onChange({});


      }, 250);
   }

   //
   validate(validationContext: AAStrategyValidationContext): string[] {
      const errors = [];

      if (!this.displayName) {
         errors.push('"Display Name" is mandatory');
      }

      const orderType = validationContext.globalSettings 
         ? validationContext.globalSettings.orderSettings.orderType 
         : this.orderType;

      if (isNullOrUndefined(orderType)) {
         errors.push('"Order Type" is mandatory');
      } else {
         if (orderType === OrderType.Limit) {
            const convertToMarket = validationContext.globalSettings 
               ? validationContext.globalSettings.orderSettings.convertToMarket 
               : this.convertToMarket;

            if (convertToMarket) {
               
               const ctmSettings = validationContext.globalSettings 
                  ? validationContext.globalSettings.orderSettings.convertToMarketSettings
                  : this.convertToMarketSettings;

               if (isNullOrUndefined(ctmSettings.actionTime)) {
                  errors.push('"Convert Time is requried"');
               } else {
                  const parts = ctmSettings.actionTime.split(':');
                  if (parts.length !== 3) {
                     errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                  } else {
                     const hours = parseInt(parts[0]);
                     const minutes = parseInt(parts[1]);
                     const seconds = parseInt(parts[2]);

                     if (isNaN(hours)) {
                        errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                     } else {
                        if (isNaN(minutes) || minutes > 59) {
                           errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                        } else {
                           if (isNaN(seconds) || seconds > 59) {
                              errors.push('"Convert Time" is incorrect. Use "HH:mm:ss" pattern');
                           }
                        }
                     }
                  }
               }
            }
         }
      }
      
      if (this.expirationOffsetMode === 'Calendar Days') {
         let diff = this.expirationOffsetValue - this.rollDaysBeforeExpiration;

         const errorMessage = 'Roll vs Offset validation failed';
         
         if (isNaN(diff)) {
            errors.push(errorMessage);
         } else {
            if (diff <= 0) {
               errors.push(errorMessage);
            }
         }
      }

      if (isNullOrUndefined(this.expirationPreference)) {
         errors.push('"Expiration Preference" is mandatory');
      }

      return errors;
   }

   //
   reset() {
      this.displayName = 'Long Option Adjustment';
      this.expirationOffsetMode = null;
      this._expirationOffsetValue = null;
      this.rollDaysBeforeExpiration = null;
      this.rollTime = null;
      this.rollTimeMode =null;
      this.rollTimezone = null;
      this.orderType = OrderType.Limit;
      this.debitSpreadOffset = null;
      this.debitSpreadOffset2 = null;
      this.convertToMarketSettings = {};
   }

   //
   filterSuitableLegs(legs: OrderLeg[]): OrderLegWrapper[] {
      return legs.map(l => {
         const available = l.action === ActionType.Buy;
         return { leg: l, isAvailable: available } as OrderLegWrapper;
      });
   }

   //
   getExpirationOffsetModes(cpMode: string) {
      if (cpMode === 'Automated') {
         this.expirationOffsetMode = 'Calendar Days';
         return this._expirationOffsetModesAutomated;
      } else {
         return this._expirationOffsetModesRegular;
      }
   }

   setDetector(cd: ChangeDetectorRef) {
      this._changeDetector = cd;
   }
}
