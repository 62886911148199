import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DevExtremeModule } from '../devextreme.module';
import { OptionsCommonModule } from '../options-common/options-common.module';
import { EtsSymbolPickerModule } from '../symbol-picker/symbol-picker.module';

import { OptionsPricingPadComponent } from './options-pricing-pad.component';

@NgModule({
   imports: [
      CommonModule,
      EtsSymbolPickerModule,
      OptionsCommonModule,
      DevExtremeModule
   ],
   exports: [OptionsPricingPadComponent],
   declarations: [OptionsPricingPadComponent],
   providers: [],
})
export class OptionsPricingPadModule { }
