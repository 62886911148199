import {ProtocolCommand} from '../../service-model/protocol-command.interface';
import {PROTOCOL_NAMESPACE} from './auth-protocol-namespace.const';

export class DismissToken implements ProtocolCommand {
  constructor(
    public readonly authToken: string,
    public readonly reason?: string) {
  }

  getOperationName(): string {
    return `${PROTOCOL_NAMESPACE}.DismissToken`;
  }
}
