import {Injectable} from "@angular/core";
import {MessageBusService} from "../../message-bus.service";
import {UserSettingObject, UserSettingsService} from "../../user-settings.service";
import {SessionService} from "../../authentication/session-service.service";
import {isApgPortfolioSetting, isVoid} from "../../utils";
import {ApgPortfolio} from "../model/ApgPortfolio";
import {filter} from "rxjs/operators";
import {LastUsedTemplate} from "./cashflow-strategy-templates.service";
import {BeforePositionDto} from "../model/BeforePositionDto";

@Injectable({providedIn: 'root'})
export class ApgOriginalClientPositionsService {

    constructor(
        private readonly _messageBus: MessageBusService,
        private readonly _userSettings: UserSettingsService,
        private readonly _sessionService: SessionService,
    ) {

        this._userSettings.userSettingChanged$
            .pipe(
                filter(x => isApgPortfolioSetting(x.key)),
                filter(x => {
                    const users = this._sessionService.sessionData.users;
                    if (isVoid(users)) {
                        return false;
                    }
                    return users.some(usr => usr.userId === x.userId);
                })
            )
            .subscribe(x => this.onUserSettingChanged(x));
    }

    getTheirOriginalPositions(selectedPortfolio: ApgPortfolio): BeforePositionDto[][] {
        const ul = this.getTheirSnapshotTemplate(selectedPortfolio)?.underlying;
        const key = `apg.saved-positions.${selectedPortfolio.id}.${ul}.${selectedPortfolio.strategy}`;
        const positions = this._userSettings
            .getValue<BeforePositionDto[][]>(key, selectedPortfolio.userId, 'their-snapshot') || [];
        return positions;
    }

    getTheirSnapshotTemplate(selectedPortfolio: ApgPortfolio): LastUsedTemplate {
        const key = `apg.last-used-template.${selectedPortfolio.id}`;

        const template = this._userSettings
            .getValue<LastUsedTemplate>(key, selectedPortfolio.userId, 'their-snapshot');

        return template;
    }

    getTheirCurrentPositions(selectedPortfolio: ApgPortfolio): BeforePositionDto[][] {

        let ul = this.getTheirCurrentTemplate(selectedPortfolio)?.underlying;

        if (isVoid(ul)) {
            ul = this.getTheirSnapshotTemplate(selectedPortfolio)?.underlying;
        }

        const key = `apg.saved-positions.${selectedPortfolio.id}.${ul}.${selectedPortfolio.strategy}`;

        const positions = this._userSettings
            .getValue<BeforePositionDto[][]>(
                key, selectedPortfolio.userId, 'their-current') || [];

        return positions;
    }

    getTheirCurrentTemplate(selectedPortfolio: ApgPortfolio): LastUsedTemplate {

        const key = `apg.last-used-template.${selectedPortfolio.id}`;

        const template = this._userSettings
            .getValue<LastUsedTemplate>(key, selectedPortfolio.userId, 'their-current');

        return template;
    }

    updateSnapshotStateWithCurrent(portfolio: ApgPortfolio): void {

        let tpl = this.getTheirCurrentTemplate(portfolio);

        if (isVoid(tpl)) {
            tpl = this.getTheirSnapshotTemplate(portfolio);
        }

        const positionsKey = `apg.saved-positions.${portfolio.id}.${tpl?.underlying}.${portfolio.strategy}`;

        this._userSettings.applyTheirCurrentState(positionsKey, portfolio.userId);

        const tplKey = `apg.last-used-template.${portfolio.id}`;

        this._userSettings.applyTheirCurrentState(tplKey, portfolio.userId);


    }

    resetToSnapshotState(portfolio: ApgPortfolio) {
        let tpl = this.getTheirSnapshotTemplate(portfolio);

        const key = `apg.saved-positions.${portfolio.id}.${tpl?.underlying}.${portfolio.strategy}`;

        this._userSettings.resetToSnapshotState(key, portfolio.userId);

        const tplKey = `apg.last-used-template.${portfolio.id}`;

        this._userSettings.resetToSnapshotState(tplKey, portfolio.userId);
    }

    private onUserSettingChanged(x: UserSettingObject) {
        if (x.userId === this._sessionService.sessionData?.userId) {
            return;
        }

        if (x.key.startsWith('apg.saved-positions')) {
            this._messageBus.publish({
                topic: 'Apg.ClientSavedPositionsChanged',
                payload: {}
            });
        }

        if (x.key.startsWith('apg.last-used-template')) {
            this._messageBus.publish({
                topic: 'Apg.LastUsedTemplateChanged',
                payload: {}
            });
        }
    }

}