export interface MoveToPortfolioDialogConfig  {
   itemType: PortfolioEntityType;
   itemId: string;
   itemNetPosition?: number;
   itemAvgPx?: number;
   itemTerminalId: string;
   itemPortfolioId: string;
   itemComboId: string;
   itemComboGroupId: string;
}

export enum PortfolioEntityType {
   Unknown,
   Strategy,
   Position,
   PortfolioItem
}


export type TransferStrategyCode = 'With P&L' | 'Without P&L' | 'Split';
