import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';

export class GetStrategyGameRecordsWeb implements ProtocolQuery {
  constructor(
    public readonly strategyId: string,
    public readonly ticker: string,
    public readonly historyStop: number,
    public readonly batchSize: number,
    public readonly forDeletedStrategy?: boolean
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategyGameRecordsWeb`;
  }
}
