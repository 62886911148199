import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ShortOptionSideboxComponent } from './legs/short-option-sidebox.component';
import { DetectMethodChanges, DetectSetterChanges, isVoid } from 'projects/shared-components/utils';
import { GetShortOptionSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { ComboDto, GetShortOptionSectionDataModelReply } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';

@Component({
   selector: 'ets-automation-cp-body-short-option-section',
   templateUrl: './short-option-section.component.html',
   styleUrls: ['./short-option-section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortOptionSectionComponent implements OnInit, OnDestroy {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _cpMessageBus: AutomationCpMessageBusService
   ) { }

   private _unsubscriber = new Subject();

   //
   header = 'Short Option';
   
   //
   private _combo: ComboDto;
   public get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   public set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => {
         this.onComboSelected();
      }, 0);
   }
   
   //
   @Input() 
   automationCpMode: AutomationCpMode;

   //
   @Input() 
   strategy: CashFlowStrategy;

   //
   @ViewChild('firstSidebox', {static: false})
   firstSidebox: ShortOptionSideboxComponent;

   //
   @ViewChild('secondSidebox', {static: false})
   secondSidebox: ShortOptionSideboxComponent;

   //
   private _isLoading : boolean;
   get isLoading() : boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v : boolean) {
      this._isLoading = v;
   }

   //
   get isMultiDirection() {
      return this.strategy === 'Calls & Puts';
   }

   //
   ngOnInit(): void {
      this._cpMessageBus.when('AdjustmentStrategyCreated')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onComboSelected());

      this._cpMessageBus.when('AdjustmentStrategyRemoved')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.reset());

   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }
   
   //
   @DetectMethodChanges({isAsync: true})
   async onComboSelected(): Promise<void> {

      this.reset();
      
      if (isVoid(this.combo)) {
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetShortOptionSectionDataModel(
            this.combo.comboId,
            this.strategy
         );

         const reply = await this._shellClient
            .processQuery<GetShortOptionSectionDataModelReply>(qry);

         this.firstSidebox.setData(reply.comboGroupId, reply.leg);

         if (this.secondSidebox) {
            this.secondSidebox.setData(reply.comboGroupId2, reply.leg2);
         }

      } catch(e) {

         this._toastr.error(
            '"Load Short Option Data" operation completed with errors', 
            'Short Option'
         );

      } finally {
         
         this.isLoading = false;

      }

   }

   private reset() {
      this.firstSidebox.reset();
      if (this.secondSidebox) {
         this.secondSidebox.reset();
      }
   }
}
