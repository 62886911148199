import { ProtocolCommand } from 'projects/shared-components/service-model/protocol-command.interface';

import { SHELL_PROTOCOL_OPERATIONS_NS } from '../shell-protocol-namespace.const';

export class ResetSession implements ProtocolCommand {
  constructor(public terminals: string[]) { }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ResetSession`;
  }
}
