import { StrategiesServiceBackendService } from 'projects/shared-components/strategies/strategies-service-backend.service';
import { GetStrategies } from 'projects/shared-components/shell-communication/operations/strategies/get-strategies.class';
import { StrategyDto } from 'projects/shared-components/shell-communication/dtos/strategy-dto.class';
import { 
  GetNumberOfLinkedRulesByStrategy 
} from 'projects/shared-components/shell-communication/operations/strategies/get-number-of-linked-rules-by-strategy.class';
import { KeyValueDto } from 'projects/shared-components/shell-communication/dtos/key-value-dto.interface';
import { Injectable } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';

@Injectable( {providedIn: 'root'} )
export class WebtraderStrategiesServiceBackendService extends StrategiesServiceBackendService {
  constructor(private readonly _shellClient: ShellClientService) {
    super();
  }
  
  getStrategies(qry: GetStrategies): Promise<StrategyDto[]> {
    return this._shellClient.processQuery(qry);
  }
  
  getNumberOfLinkedRulesByStrategy(qry: GetNumberOfLinkedRulesByStrategy): Promise<KeyValueDto[]> {
    return this._shellClient.processQuery(qry);
  }
}
