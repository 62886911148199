export enum WorkspacePanels {
   TradingSystemsView = 1,
   QuoteBoard = 2,
   StrategyMessages = 3,
   StrategyOrdersAndTrades = 4,
   ManualOrdersAndTrades = 5,
   ManualPositions = 6,
   AggregatedPositions = 7,
   PnLChart = 8,
   PriceChart = 9,
   StrategyIssues = 10,
   HpDatasets = 11,
   OpenPositionChart = 12,
   PositionSizingReport = 13,
   StrategyTriggers = 14,
   OptionChain = 15,
   Portfolios =  16,
   MultiTradePad = 17,
   Ledger = 18,
   OptionsPricingPad = 19,
   OptionsPricingGrid = 20,
   StrategyHedgedPositions = 21,
   PortfolioItems = 22,
   Watchlist = 23,
   SessionHistory = 24,
   PortfolioOrdersAndTrades = 25,
   AdjustmentStrategies = 26,
   ParametersPanel = 27,
   MarginRequirements = 28,
   ShellMessages = 29,
   FutureOrders = 30,
   TestPanel = 31,
   AdjsutmentControlPanel = 32,
   AutomationCp = 33,
   AdjustmentPricingGridPanel = 34,
   CashFlowTracking = 35,
   AdjustmentComparisonGrid = 36,
   ChecklistEditorPanel = 37,
   ResourceEditorPanel = 38,
   HedgingGrid = 39,
   PackageComparisonPanel = 40,
   PositionModifier = 41
}
