<div #container class="container">
  <ag-grid-angular
    style="width: 100%; height: 100%"
    class="ag-theme-balham-dark"
    [gridOptions]="strategyIssuesGridModel"
  ></ag-grid-angular>

  <dx-load-panel [position]="{ my: 'center', at: 'center', of: container }"
                  [visible]="operationInProgress"
                  message="Please wait..."
  ></dx-load-panel>

  <dx-popup width="550"
          height="350"
          [showTitle]="true"
          title='Message Details'
          [closeOnOutsideClick]="true"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          (onHidden)="isDetailsWindowVisible = false"
          [visible]="isDetailsWindowVisible">
  <div *dxTemplate="let data of 'content'" id="session_message_details">
    <div id="popup_container">
      <p>{{selectedIssue.messageText}}</p>
    </div>
  </div>
</dx-popup>

</div>
