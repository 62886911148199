import { Injectable } from '@angular/core';
import { Logger } from '../logging/logger.interface';
import { Subject } from 'rxjs';
import { AlgoMetadataService } from '../algo/algo-metadata.service';
import { MessageBusService } from '../message-bus.service';
import { StrategyModel } from './strategy-model';
import { filter, takeUntil } from 'rxjs/operators';
import { StrategyStateDto } from '../shell-communication/dtos/strategy-state-dto.class';
import { StrategyStatusDto } from '../shell-communication/dtos/strategy-status-dto.class';
import { PositionDto } from '../shell-communication/dtos/position-dto.class';
import { EngineStrategyAddedDto } from '../shell-communication/dtos/engine-strategy-added-dto.class';
import { EngineStrategyRemovedDto } from '../shell-communication/dtos/engine-strategy-removed-dto.class';
import { ClearTradingDataUIMessage } from '../ui-messages/clear-trading-data-ui-message.class';
import { ShellConnectionStatusChangedUIMessage } from '../ui-messages/shell-connection-status-changed-ui-message.interface';
import { StrategyParametersUpdatedDto } from '../shell-communication/dtos/strategy-parameters-updated-dto.class';
import { PositionArchived } from '../shell-communication/dtos/position-archived.class';
import { StrategyFlagsChangedDto } from '../shell-communication/dtos/strategy-flags-changed-dto.class';
import { StrategiesSubscription } from './strategies-subscription.class';
import { StrategySubscriptionViewAdapter } from './strategy-subscription-view-adapter.interface';
import { plainToClass } from 'class-transformer';
import { GetStrategies } from '../shell-communication/operations/strategies/get-strategies.class';
import { StrategyDto } from '../shell-communication/dtos/strategy-dto.class';
import { StrategyPositionModel } from './strategy-position-model.class';
import { EtsConstants } from '../ets-constants.const';
import { SessionService } from '../authentication/session-service.service';
import { StrategiesServiceBackendService } from './strategies-service-backend.service';
import { UpdateNumberOfLinkedCommanderRulesDto } from '../shell-communication/dtos/update-number-of-linked-commander-rules-dto';
import { LoggerService } from '../logging/logger-factory.service';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { StrategyTradePnLDto } from '../shell-communication/dtos/strategy-trade-pnl-dto.class';
import { isNullOrUndefined } from 'util';
import { ArchivedPositionDeleted, PortfolioItemAddedDto, PortfolioItemRemovedDto, StrategyArchivedDto, StrategySessionActivityMessageDto } from '../shell-communication/shell-dto-protocol';
import { isHedgingAlgo, isTruthy, tickersMatch } from '../utils';
import { TradingInstrumentsService } from '../trading-instruments/trading-instruments-service.interface';
import { PortfolioItemType } from '../portfolios/portfolios.model';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';


@Injectable({ providedIn: 'root' })
export class StrategiesService {
   constructor(
      private readonly _backendService: StrategiesServiceBackendService,
      private readonly _messageBus: MessageBusService,
      private readonly _algoMetadataService: AlgoMetadataService,
      private readonly _sessionService: SessionService,
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _tiSvc: TradingInstrumentsService,
      loggerService: LoggerService
   ) {
      this._unsubscriber = new Subject<any>();
      this._logger = loggerService.createLogger('StrategiesService');
      this._strategySubscriptions = [];
      this._strategiesIX = {};
   }


   private readonly _logger: Logger;
   private readonly _strategySubscriptions: StrategiesSubscription[];
   private _strategiesIX: Record<string, StrategyModel>;
   private _unsubscriber: Subject<any>;



   async init(): Promise<any> {
      this._strategySubscriptions.length = 0;
      this._strategiesIX = {};
      this.subscribeToMessages();
      try {
         await this.loadStrategies();
      } catch (error) {
         this._logger.error('init()', error);
         throw error;
      }
   }



   getAllStrategies(): StrategyModel[] {
      return Object.values(this._strategiesIX);
   }



   getById(strategyId: string): StrategyModel {
      let returnValue: StrategyModel = this._strategiesIX[strategyId];

      if (isNullOrUndefined(returnValue)) {
         returnValue = Object.values(this._strategiesIX)
            .filter(x => x.dispositionStrategies.length > 0)
            .flatMap(x => x.dispositionStrategies)
            .find(is => is.strategyId === strategyId);
      }

      return returnValue;
   }



   subscribe(
      predicate: (strategy: StrategyModel) => boolean,
      type: 'main' | 'inner',
      viewAdapter: StrategySubscriptionViewAdapter
   ): StrategiesSubscription {
      const subscription = new StrategiesSubscription(predicate, viewAdapter, type);
      this.fillSubscription(subscription);
      this._strategySubscriptions.push(subscription);
      return subscription;
   }



   unsubscribe(subscription: StrategiesSubscription): void {
      if (!subscription) {
         return;
      }

      const ix = this._strategySubscriptions.indexOf(subscription);

      if (ix < 0) {
         return;
      }

      this._strategySubscriptions.splice(ix, 1);

      subscription.dispose(this);
   }



   private subscribeToMessages(): void {
      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject<any>();

      this._messageBus
         .of<StrategyStateDto[]>('StrategyStateDto')
         .pipe(
            filter(messages => messages.payload.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this._onStrategyStateUpdate(msg.payload));

      this._messageBus
         .of<StrategyStatusDto[]>('StrategyStatusDto')
         .pipe(
            filter(messages => messages.payload.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this._onStrategyStatusUpdate(x.payload));

      this._messageBus
         .of<StrategySessionActivityMessageDto[]>('StrategySessionActivityMessageDto')
         .pipe(
            filter(messages => messages.payload.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this._onStrategySessionActivityUpdate(x.payload));

      this._messageBus
         .of<StrategyTradePnLDto[]>('StrategyTradePnLDto')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(messages => messages.payload.length > 0),
         )
         .subscribe(x => this._onStrategyTradePnLUpdate(x.payload));

      this._messageBus
         .of<PositionDto[]>('PositionDto')
         .pipe(
            filter(messages => messages.payload.length > 0),
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this._onStrategyPositionUpdate(x.payload));

      this._messageBus
         .of<EngineStrategyAddedDto>('EngineStrategyAddedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onEngineStrategyAdded(x.payload));

      this._messageBus
         .of<EngineStrategyRemovedDto>('EngineStrategyRemovedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onEngineStrategyRemoved(x.payload));

      this._messageBus
         .of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onTradingDataCleanup(x.payload));

      this._messageBus
         .of<ShellConnectionStatusChangedUIMessage>('ShellConnectionStatusChangedUIMessage')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onShellStatusConnectionChanged(x.payload));

      this._messageBus
         .of<StrategyParametersUpdatedDto>('StrategyParametersUpdatedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onStrategyParametersUpdated(x.payload));

      this._messageBus
         .of<PositionArchived>('PositionArchived')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onPositionArchived(x.payload));

      this._messageBus
         .of<ArchivedPositionDeleted>('ArchivedPositionDeleted')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onArchivedPositionDeleted(x.payload));

      this._messageBus
         .of<StrategyFlagsChangedDto>('StrategyFlagsChangedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this._onStrategyFlagsChanged(x.payload));

      this._messageBus
         .of<UpdateNumberOfLinkedCommanderRulesDto>('UpdateNumberOfLinkedCommanderRulesDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this.updateNumberOfLinkedStrategyCommanderRules(x.payload));


      this._messageBus
         .of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this.onPortfolioItemAdded(x.payload));

      this._messageBus
         .of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(x => this.onPortfolioItemRemoved(x.payload));

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onQuoteMessage(x.payload));
   }
   
   private async _getStrategiesByTerminalFilter(terminals: string[], shellId = ''): Promise<StrategyModel[]> {
      const qry = new GetStrategies(terminals);
      const data = await this._backendService.getStrategies(qry, shellId);
      const models = data.map(x => {
         try {
            const model = plainToClass(StrategyModel, x);
            return model;
         } catch (ex) {
            this._logger.error('Failed to map dto to model (StrategyModel)', ex);
            throw ex;
         }
      });

      return models;
   }




   private _onStrategyStateUpdate(messages: StrategyStateDto[]): void {
      messages.forEach(message => {
         let strategy = this._strategiesIX[message.strategyId];
         if (!strategy) {
            if (message.dispositionId) {
               const disposition = this._strategiesIX[message.dispositionId];
               if (disposition) {
                  strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
               }
            }

            if (!strategy) {
               return;
            }
         }
         strategy.state = message.state;

         const isInnerstrategy = !isNullOrUndefined(strategy.dispositionId);
         strategy.notifyObservers({ affectsGroupSortOrFilter: !isInnerstrategy });
      });
   }


   private onQuoteMessage(payload: QuoteDto[]): void {
      Object.values(this._strategiesIX).forEach(strategy => {
         
         this.setLiveQuote(strategy, payload);
         
         if (strategy.isDisposition) {
            strategy.dispositionStrategies.forEach(innerStrategy => {
               this.setLiveQuote(innerStrategy, payload);
            });
         } 
      });
   }

   
   private setLiveQuote(strategy: StrategyModel, quotes: QuoteDto[]) {
      const tis = strategy.getTradingInstruments();
      if (tis.length > 1) {
         strategy.liveQuote = NaN;
      } else {
         const quote = quotes.find(q => tickersMatch(q.ticker, tis[0]));
         if (quote) {
            strategy.liveQuote = quote.lastPx;
         }
      }

      strategy.notifyObservers({ affectsGroupSortOrFilter: false, refreshCells: true });
   }


   private _onStrategyStatusUpdate(messages: StrategyStatusDto[]): void {
      messages.forEach(message => {
         let strategy = this._strategiesIX[message.strategyId];
         if (strategy === undefined) {
            if (message.dispositionId) {
               const disposition = this._strategiesIX[message.dispositionId];
               if (disposition) {
                  strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
               }
            }
            if (!strategy) {
               return;
            }
         }
         strategy.status = message.status;

         const isInnerstrategy = !isNullOrUndefined(strategy.dispositionId);
         strategy.notifyObservers({ affectsGroupSortOrFilter: !isInnerstrategy });
      });
   }

   private _onStrategySessionActivityUpdate(messages: StrategySessionActivityMessageDto[]): void {
      messages.forEach(message => {
         let strategy = this._strategiesIX[message.strategyId];
         if (strategy === undefined) {
            if (message.dispositionId) {
               const disposition = this._strategiesIX[message.dispositionId];
               if (disposition) {
                  strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
               }
            }
            if (!strategy) {
               return;
            }
         }
         strategy.hasBeenActiveDuringTheSession = message.hasBeenActive;

         const isInnerstrategy = !isNullOrUndefined(strategy.dispositionId);

         strategy.notifyObservers({ affectsGroupSortOrFilter: !isInnerstrategy, refreshCells: true });
      });
   }




   private _onStrategyTradePnLUpdate(messages: StrategyTradePnLDto[]): void {
      messages.forEach(message => {
         let strategy = this._strategiesIX[message.strategyId];
         if (isNullOrUndefined(strategy)) {
            if (message.dispositionId) {
               const disposition = this._strategiesIX[message.dispositionId];
               if (disposition) {
                  strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
               }
            }
            if (!strategy) {
               return;
            }
         }
         strategy.tradePnL = message.pnL;
         strategy.notifyObservers({});
      });
   }



   private _onStrategyPositionUpdate(messages: PositionDto[]): void {
      messages
         .map(message => {

            let strategy = this._strategiesIX[message.strategyId];

            let isHedgePosition = null;

            if (!strategy) {
               if (message.dispositionId) {
                  const disposition = this._strategiesIX[message.dispositionId];
                  if (disposition) {
                     strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
                  }
               }

               if (!strategy) {
                  
                  strategy = this._strategiesIX[message.hedgeStrategyId];
                  
                  if (strategy) {
                     isHedgePosition = true;
                  }

                  if (!strategy) {
                     return null;
                  }
               }
            }

            if (isHedgePosition) {
               const posIx = strategy.hedgedPositions.findIndex(hp => hp.positionId === message.positionId);
               if (posIx >= 0) {
                  strategy.hedgedPositions[posIx] = message;
               } else {
                  strategy.hedgedPositions.push(message);
               }
               
               return strategy;
            }

            let targetPosition: StrategyPositionModel;
            const strategyPositions = message.isArchived ? strategy.positions.getArchivedPositions() : strategy.positions.getPositions();
            if (strategyPositions.length === 1) {
               // if (strategyPositions[0].ticker === message.ticker) {
               if (strategyPositions[0].positionId === message.positionId) {
                  targetPosition = strategyPositions[0];
               }
            } else {
               // const ix = strategyPositions.findIndex(x => x.ticker === message.ticker);
               const ix = strategyPositions.findIndex(x => x.positionId === message.positionId);
               if (ix >= 0) {
                  targetPosition = strategyPositions[ix];
               }
            }

            if (!targetPosition) {
               strategy.positions.addFromDto(message);
            } else {

               targetPosition.updateFinancialDataFromDto(message);

               if (message.isArchived) {
                  strategy.positions.adjustRolloverPnL(message);
               }
            }

            return strategy;

         })
         .filter(x => !isNullOrUndefined(x))
         .forEach(strategy => {
            strategy.updateFinancialProperties();
            strategy.notifyObservers({ affectsGroupSortOrFilter: true });
            this._messageBus.publish({
               topic: 'StrategyPositionChanged',
               payload: strategy
            });
         });
   }



   private _onEngineStrategyAdded(message: EngineStrategyAddedDto): void {
      const model = plainToClass(StrategyModel, message.strategy);
      this.addStrategyToIndex(model, true);
   }



   private _onEngineStrategyRemoved(message: EngineStrategyRemovedDto): void {
      message.strategies.forEach(removedStrategy => {
         let toRemove = this._strategiesIX[removedStrategy.strategyId];
         if (isNullOrUndefined(toRemove)) {
            const dispo = this._strategiesIX[removedStrategy.dispositionId];
            if (dispo) {
               const ix = dispo.dispositionStrategies.findIndex(inner => inner.strategyId === removedStrategy.strategyId);
               if (ix >= 0) {
                  toRemove = dispo.dispositionStrategies[ix];
                  dispo.dispositionStrategies.splice(ix, 1);
               }
            }
         }

         if (!isNullOrUndefined(toRemove)) {
            delete this._strategiesIX[removedStrategy.strategyId];
            this.updateStrategiesSubscriptions(toRemove, 'remove');
            this._messageBus.publish({
               topic: 'StrategyRemovedUIMessage',
               payload: {
                  strategyId: toRemove.strategyId,
                  isDisposition: toRemove.isDisposition
               }
            });
         }
      });
   }


   private _onTradingDataCleanup(message: ClearTradingDataUIMessage): void {
      if (message.hasErrors) {
         return;
      }

      if ((message.strategies && message.strategies.length) || message.refreshDb) {
         if (message.refreshDb) {
            Object.values(this._strategiesIX)
               .filter(strategy => strategy.shellId === message.shellId)
               .forEach((strategy) => this._clearStrategyOnTradingDataCleanup(strategy));
         } else {
            message.strategies.map(strId => this._strategiesIX[strId])
               .filter(strategy => !!strategy)
               .forEach(strategy => this._clearStrategyOnTradingDataCleanup(strategy));
         }
      }
   }



   private _clearStrategyOnTradingDataCleanup(strategy: StrategyModel): void {
      strategy.positions.clear();
      strategy.hedgedPositions.length = 0;
      strategy.updateAllProperties(this._algoMetadataService);
      strategy.notifyObservers({ redrawRows: true });

      if (strategy.dispositionStrategies.length > 0) {
         strategy.dispositionStrategies
            .forEach((innerStrategy) => this._clearStrategyOnTradingDataCleanup(innerStrategy));
      }
   }



   private async _onShellStatusConnectionChanged(message: ShellConnectionStatusChangedUIMessage): Promise<any> {
      if (!message.isConnected) {
         return;
      }
      try {
         await this.loadStrategies(message.shellId);
      } catch (error) {
         this._logger.error('_onShellStatusConnectionChanged()', error);
      }
   }



   private _onStrategyParametersUpdated(message: StrategyParametersUpdatedDto): void {
      const strategy = this._strategiesIX[message.strategy.strategyId];
      
      if (!strategy) {
         if (message.strategy.dispositionId) {
            const dispo = this._strategiesIX[message.strategy.dispositionId];
            if (dispo) {
               const is = dispo.dispositionStrategies.find(x => x.strategyId === message.strategy.strategyId);
               if (is) {
                  is.onParametersUpdated(message.strategy, this._algoMetadataService);
                  is.notifyObservers({});
                  this._messageBus.publish({
                     topic: 'DispositionParametersUpdated',
                     payload: { strategyId: dispo.strategyId }
                  });
               }
            }
         }
         return;
      }

      strategy.onParametersUpdated(message.strategy, this._algoMetadataService);
      const innerStrategies = message.strategy.dispositionStrategies;
      if (innerStrategies && innerStrategies.length > 0) {
         innerStrategies.forEach((is: StrategyDto) => {
            const ix = strategy.dispositionStrategies.findIndex(x => x.strategyId === is.strategyId);
            if (ix >= 0) {
               const innerStrategyModel = strategy.dispositionStrategies[ix];
               innerStrategyModel.onParametersUpdated(is, this._algoMetadataService);
            } else {
               const innerModel = plainToClass(StrategyModel, is);
               strategy.dispositionStrategies.push(innerModel);
               innerModel.updateAllProperties(this._algoMetadataService);
            }
         });

         // remove removed strategies
         const innerIds = innerStrategies.map(is => is.strategyId);
         strategy.dispositionStrategies.forEach(dis => {
            if (!innerIds.includes(dis.strategyId)) {
               const ix = strategy.dispositionStrategies.findIndex(x => x === dis);
               if (ix >= 0) {
                  strategy.dispositionStrategies.splice(ix, 1);
               }
            }
         });
      }

      strategy.notifyObservers({ refreshCells: true });

      if (strategy.dispositionStrategies && strategy.dispositionStrategies.length > 0) {
         strategy.dispositionStrategies.forEach(is => {
            is.notifyObservers({});
         });
      }

      this._messageBus.publish({
         topic: 'DispositionParametersUpdated',
         payload: { strategyId: strategy.strategyId }
      });
   }



   private _onPositionArchived(message: PositionArchived): void {
      if (message.strategyId === EtsConstants.strategies.manualStrategyId) {
         return;
      }

      let strategy = this._strategiesIX[message.strategyId];

      if (strategy === undefined) {
         if (message.dispositionId) {

            const disposition = this._strategiesIX[message.dispositionId];
            if (disposition) {
               strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
            }
         }

         if (!strategy) {
            return;
         }
      }


      strategy.positions.archivePosition(message.positionId);

      const ix = strategy.positions.getPositions().findIndex(x => x.positionId === message.positionId);

      strategy.updateFinancialProperties();
      strategy.notifyObservers({});

      this._messageBus.publish({
         topic: 'StrategyPositionChanged',
         payload: strategy
      });

   }



   private _onArchivedPositionDeleted(message: ArchivedPositionDeleted) {
      if (message.strategyId === EtsConstants.strategies.manualStrategyId) {
         return;
      }

      const strategy = this._strategiesIX[message.strategyId];

      if (!isTruthy(strategy)) {
         return;
      }

      const removed = strategy.positions.removeArchivedPosition(message.positionId);
      if (removed) {
         strategy.updateFinancialProperties();
         strategy.notifyObservers({});
      }
   }



   private _onStrategyFlagsChanged(message: StrategyFlagsChangedDto): void {
      let strategy = this._strategiesIX[message.strategyId];
      if (strategy === undefined) {
         if (message.dispositionId) {
            const disposition = this._strategiesIX[message.dispositionId];
            if (disposition) {
               strategy = disposition.dispositionStrategies.find(is => is.strategyId === message.strategyId);
            }
         }
         if (!strategy) {
            return;
         }
      }
      strategy.flags = message.flags;
      strategy.updateFlags();
      strategy.notifyObservers({});
   }



   private addStrategyToIndex(strategy: StrategyModel, postActions = false): void {
      if (!strategy.isInnerStrategy) {
         this._strategiesIX[strategy.strategyId] = strategy;
      } else {
         const dispo = this._strategiesIX[strategy.dispositionId];
         if (dispo) {
            dispo.dispositionStrategies.push(strategy);
         }
      }
      
      strategy.updateAllProperties(this._algoMetadataService);
      strategy.positions.getPositions().forEach(p => this._lastQuoteCache.subscribeTicker(p.ticker));

      if (strategy.isDisposition) {
         strategy.dispositionStrategies.forEach(innerStrategy => {
            innerStrategy.updateAllProperties(this._algoMetadataService);
            innerStrategy.positions.getPositions().forEach(p => this._lastQuoteCache.subscribeTicker(p.ticker));
         });
      }

      if (isHedgingAlgo(strategy.algoId)) {
         return;
      }
      if (postActions) {
         this.updateStrategiesSubscriptions(strategy, 'add');
      }
   }



   private updateStrategiesSubscriptions(strategy: StrategyModel, action: 'add' | 'remove'): void {
      if (action === 'add') {
         this._strategySubscriptions.forEach(subs => {
            if (subs.predicate(strategy)) {
               subs.add(strategy);
            }
         });
      } else if (action === 'remove') {
         this._strategySubscriptions.forEach(subs => {
            if (subs.predicate(strategy)) {
               subs.remove(strategy);
            }
         });
      }
   }



   private async loadStrategies(shellId = ''): Promise<void> {
      this._logger.info(`Loading strategies. ShellID=${shellId}`);

      if (shellId) {
         this._logger.info(`Removed strategies from index for ShellID=${shellId}`);
         const strategiesForShell = Object.values(this._strategiesIX).filter(strategy => strategy.shellId === shellId);
         strategiesForShell.forEach(strategy => delete this._strategiesIX[strategy.strategyId]);
      } else {
         this._logger.info('Resetting strategies index');
         this._strategiesIX = {};
      }

      const sessionTerminals = this._sessionService.loginResult;
      const terminals = sessionTerminals ? sessionTerminals.availableTerminals.map(x => x.terminalId) : [];
      const data = await this._getStrategiesByTerminalFilter(terminals, shellId);
      data.forEach(strat => this.addStrategyToIndex(strat));
      this._strategySubscriptions.forEach(x => this.refreshSubscription(x));
   }



   private refreshSubscription(subscription: StrategiesSubscription): void {
      subscription.reset();
      this.fillSubscription(subscription);
   }



   private fillSubscription(subscription: StrategiesSubscription): void {
      const strats = Object
         .values(this._strategiesIX)
         .flatMap(strategy => {
            if (strategy.dispositionStrategies.length === 0) {
               return strategy;
            }
            return [strategy].concat(strategy.dispositionStrategies);
         })
         .filter(strategy => subscription.predicate(strategy))
         .sort( (a: StrategyModel, b: StrategyModel) => {
            if (a.isArchived && b.isArchived) {
               return 0;
            }

            if (!a.isArchived && !b.isArchived) {
               return 0;
            }

            if (a.isArchived) {
               return 1;
            }

            if (b.isArchived) {
               return -1;
            }
         });

      subscription.add(...strats);
   }


   private async updateNumberOfLinkedStrategyCommanderRules(msg: UpdateNumberOfLinkedCommanderRulesDto): Promise<void> {
      const strategyId = msg.strategyId;
      const issuesNum = msg.numberOfLinkedRules;
      const strategyModel = this._strategiesIX[strategyId];
      if (strategyModel) {
         strategyModel.numberOfCommanderRules = issuesNum;
      }
   }



   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {
      if (msg.portfolioItem.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const s = this._strategiesIX[msg.portfolioItem.portfolioItemId];

      if (s) {
         s.portfolioId = msg.portfolioItem.portfolioId;
         s.portfolioName = msg.portfolioItem.portfolioName;
         s.comboId = msg.portfolioItem.comboId;
         s.comboName = msg.portfolioItem.comboName;
         s.comboGroupId = msg.portfolioItem.comboGroupId;
         s.comboGroupName = msg.portfolioItem.comboGroupName;
         s.notifyObservers({ affectsGroupSortOrFilter: true, refreshCells: true });
      }

   }



   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {
      if (msg.itemType !== PortfolioItemType.Strategy) {
         return;
      }

      const s = this._strategiesIX[msg.portfolioItemId];

      if (isNullOrUndefined(s)) {
         return;
      }

      s.portfolioId = null;
      s.comboId = null;
      s.comboName = null;
      s.portfolioName = null;
      s.comboGroupId = null;
      s.comboGroupName = null;
      s.notifyObservers({ affectsGroupSortOrFilter: true, refreshCells: true });

   }

}
