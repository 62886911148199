import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GetContextMenuItemsParams, GridOptions, RowClickedEvent } from 'ag-grid-community';
import {
   centeredColumnDef,
   defaultMoneyCellDefinition,
   defaultLoadingOverlayTemplate,
   defaultPriceCellFormatter,
   getDetailSymbolColumn,
   defaultQuoteCellFormatter,
   defaultNumberCellFormatter} from 'projects/shared-components/ag-grid-contrib';
import { PositionDto, PositionFlags } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { ManualPositionsComponent } from './manual-positions.component';
import { formatNumber } from '@angular/common';
import { AgGridColumn } from 'ag-grid-angular';
import { PortfolioItemDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { isNullOrUndefined } from 'util';
import { SessionService } from 'projects/shared-components/authentication/session-service.service';
import { isCashSettledOptionTicker } from 'projects/shared-components/utils';

export function getPositionsGridOptions(this: ManualPositionsComponent): GridOptions {
   return {

      rowData: [],

      defaultColDef: centeredColumnDef,

      columnDefs: getGridColumnsDefs(this),

      overlayLoadingTemplate: defaultLoadingOverlayTemplate,

      rowClass: 'ets-text-centered',

      rowSelection: 'single',

      rowModelType: 'clientSide',

      rowGroupPanelShow: 'always',

      immutableData: true,

      popupParent: this.contextPopupParent,

      tooltipShowDelay: 1000,

      autoGroupColumnDef: {
         headerName: 'Account',
         field: 'accountCode',
         tooltipField: 'accountCode',
         cellRendererParams: {
            suppressCount: true,
         },
         cellStyle: (args: CellClassParams) => {
            if (isNullOrUndefined(args.data)) {
               return undefined;
            }
            const style = { 'text-align': 'left', color: undefined };
            
            if (args.data.isArchived) {
               style.color = 'yellow';
            }
            return style;
         },
         valueGetter: (params: ValueGetterParams) => {
            if (!params.node.group) {
               return params.data.terminalName;
            }
            return params.data.accountCode;
         }
      },

      onGridReady: (args) => this.onPositionsGridReady(args),

      getRowNodeId: (rowData: PositionDto) => {
         return rowData.positionId;
      },

      
      onRowClicked: (args: RowClickedEvent) => {
         if (!args.node || args.node.group) {
            return;
         }

         this.positionSelected(args.node);
      },

      getContextMenuItems: (params: GetContextMenuItemsParams) => {
         const menu = [];

         if (this.securityContext.overridePosition) {
            menu.push({
               name: 'Override Position',
               action: () => this.showOverrideDialog(params.node.data),
               disabled: !params.node
            });
         }

         if (this.securityContext.archivePosition) {
            menu.push({
               name: 'Archive Position',
               action: () => this.archivePosition(params.node.data),
               disabled: !params.node || params.node.data.netPosition !== 0
            });
         }

         if (this.securityContext.rollPosition) {
            menu.push({
               name: 'Roll Position',
               action: () => this.rollPosition(params.node.data),
               disabled: !params.node || params.node.data.netPosition === 0
            });
         }


         if (this.securityContext.addNewPosition) {
            menu.push({
               name: 'Add New Position',
               action: () => this.showAddPositionManuallyDialog()
            });   
         }

         menu.push({
            name: 'Adjust',
            action: () => this.adjustPosition(params)
         });

         menu.push( 
            {
            name: 'Toggle Position Flags...',
            subMenu: getPositionFlagsSubMenu(params, this)
         });

         menu.push('separator');

         menu.push({
            name: 'Move To Portfolio...',
            action: () => this.addToPortfolio('existing', params.node.data),
            disabled: !params.node || params.node.group || !params.node.data
         });

         menu.push('separator');

         menu.push('autoSizeAll', 'copy', 'export');

         return menu;
      },

      onDisplayedColumnsChanged: () => this.onStateChanged(),

      onColumnResized: () => this.onStateChanged()
   } as GridOptions;
}

export function getPositionGridColumnDefs(comp: ManualPositionsComponent, includeTerminalColumn: boolean) {
   return getGridColumnsDefs(comp, includeTerminalColumn);
}

function getGridColumnsDefs(comp: ManualPositionsComponent, includeTerminalColumn?: boolean): Partial<AgGridColumn>[] {
   
   const columns = [];

   const symbolCol = getDetailSymbolColumn('ticker', comp.messageBus, comp.timestampsSerivce, comp.unsubscriber, () => comp.positionsGridApi);

   const positionCol = {
      headerName: 'Position',
      field: 'netPosition',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.netPosition;
      },
      valueFormatter: defaultNumberCellFormatter
   };

   const liveQuoteCol = {
      headerName: 'Live Quote',
      field: 'liveQuote',
      valueFormatter: (params: ValueFormatterParams) => {
         if (!params.value) {
            return params.value;
         }
         
         if (params.value === -1) {
            return '?';
         }

         const pi = params.data as PortfolioItemDto;
         
         if (!pi) {
            return formatNumber(params.value, 'en-US', '1.2-2');
         }

         const value = formatNumber(params.value, 'en-US', '1.2-2');

         if (pi.ticker.indexOf('@XSP') >= 0) {
            
            return `${value} (M)`;

         } else {

            if (pi.netPosition === 0) {
               return `${value} (L)`;
            } 
            
            if (pi.netPosition > 0) {
               return `${value} (B)`;
            } 
            
            if (pi.netPosition < 0) {
               return `${value} (A)`;
            }
         }


         return value;
      },
   };

   const avgPxCol =  {
      headerName: 'Avg. Px',
      field: 'avgPx',
      valueGetter: (params: ValueGetterParams) => {
         if (!params.data) {
            return null;
         }
         return params.data.avgPx;
      },
      valueFormatter: defaultPriceCellFormatter,
   };

   const sessionPnLCol =  Object.assign(
      { headerName: 'Session P&L', field: 'sessionTotalPnL', aggFunc: 'sum' },
      defaultMoneyCellDefinition
   );

   const accumulatedPnLCol = Object.assign(
      { headerName: 'Acc. P&L', field: 'accumulatedTotalPnL', aggFunc: 'sum' },
      defaultMoneyCellDefinition
   );

   const terminalCol = { headerName: 'Terminal', field: 'terminalCode' };

   const accountCol = { headerName: 'Account', field: 'accountCode', enableRowGroup: true };
   
   const lastMatchPxCol =  {
      headerName: 'Last Match Px.',
      field: 'lastMatchPx',
      valueFormatter: defaultQuoteCellFormatter
   };

   const expirationStyle: ColDef = {
      headerName: 'Exp. Style',
      colId: 'expStyle',
      valueGetter: (args: ValueGetterParams) => {
         if (!args.data) {
            return '';
         }

         if (!args.data.ticker.startsWith('@')) {
            return '';
         }

         return isCashSettledOptionTicker(args.data.ticker) ? 'European' : 'American';
      }
   };

   columns.push(symbolCol);
   columns.push(expirationStyle);
   columns.push(positionCol);
   columns.push(liveQuoteCol);
   columns.push(avgPxCol);
   columns.push(sessionPnLCol);
   columns.push(accumulatedPnLCol);

   if (includeTerminalColumn) {
      columns.push(terminalCol);
   }
   columns.push(accountCol);
   columns.push(lastMatchPxCol);

   return columns;
}

function getPositionFlagsSubMenu(args: GetContextMenuItemsParams, comp: ManualPositionsComponent) {
   const menu = [];
   menu.push(
      {
         name: 'Last Over Session Reset',
         action: () => comp.togglePositionFlag(args.node.data, PositionFlags.LastOverSessionReset),
         disabled: !args.node || !args.node.data || args.node.data.isArchived
      }
   );
   
   if (args.node) {
      if (!args.node.group) {
         if (args.node.data) {
            const data: PositionDto = args.node.data;
            const available = isCashSettledFlagAvailable(data, comp.sessionService);
            if (available) {
               menu.push(
                  {
                     name: 'Cash Settled Expiration',
                     action: () => comp.toggleCashSettled(args.node.data),
                     disabled: !args.node || !args.node.data || args.node.data.isArchived
                  }
               );
            }
         }
      }
   }

   return menu;
}

function isCashSettledFlagAvailable(pfItem: PositionDto, ss: SessionService) {
   
   const accounts = ss.loginResult.availableAccounts;
   const itemAccount = accounts.find(x => x.accountId === pfItem.accountId);
   
   if (isNullOrUndefined(itemAccount)) {
      return false;
   }

   return itemAccount.isPaperTrading;
}
