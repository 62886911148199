import { EventEmitter } from '@angular/core';
import { LastQuoteCacheService } from 'projects/shared-components/last-quote-cache.service';
import { arraysEqual, isVoid } from 'projects/shared-components/utils';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { QuoteDto } from 'projects/shared-components/shell-communication/dtos/quote-dto.class';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { parseOptionTicker } from 'projects/shared-components/options-common/options.model';

export class DynamicOffsetCalculator {
   constructor(
      private readonly _lastQuoteCache: LastQuoteCacheService,
      private readonly _messageBus: MessageBusService
   ) { }

   private _unsubscriber: Subscription;

   private _currentTickers: string[] = [];

   private _lastQuotes: Record<string, QuoteDto> = {};

   private _underlying: string;

   offsetChanged$ = new BehaviorSubject<number>(undefined);

   //
   
   lastOffset: number;

   //

   start(tickers: string[]) {

      if (arraysEqual(this._currentTickers, tickers)) {
         return;
      }

      this.stop();
      
      if (this._currentTickers.length > 0) {
         this._lastQuoteCache.unsubscribeTickers(this._currentTickers);
      }
      
      this._currentTickers = tickers;
      
      this.lastOffset = undefined;

      const optTicker = parseOptionTicker(this._currentTickers[0]);

      if (!isVoid(optTicker)) {
         this._underlying = optTicker.underlying;
      }

      this._lastQuotes = {};
      
      this._currentTickers.forEach(x => this._lastQuotes[x] = null);

      this._lastQuoteCache.subscribeTickers(tickers);

      this._unsubscriber = this._messageBus
         .of<QuoteDto[]>('QuoteDto')
         .subscribe(msg => {

            const dtos = msg.payload;

            dtos
               .filter(x => this._currentTickers.indexOf(x.ticker) >= 0)
               .forEach(x => {
                  this._lastQuotes[x.ticker] = x;
               });

            const quotes = Object.values(this._lastQuotes).filter(x => !isVoid(x));
               
            const quotesCount = quotes.length;

            if (quotesCount !== tickers.length) {
               return;
            }

            const sum = quotes.map(x => x.mid).reduce((p, c) => p + c, 0);
            
            const offset = this.prepareOffsetValue(sum);

            this.lastOffset = offset;

            this.offsetChanged$.next(offset);

         });
   }

   //

   getOffset(tickers: string[]): Promise<number> {

      if (tickers.length === 0) {
         return Promise.reject('No tickers to watch');
      }

      this.start(tickers);
      
      const prms = new Promise<number>( (res, rej) =>  {
         
         const quotes = Object.values(this._lastQuotes).filter(x => !isVoid(x));
               
         const quotesCount = quotes.length;

         if (quotesCount !== tickers.length) {
            
            let attempts = 0;

            const interval = setInterval(() => {

               attempts++;

               if (attempts > 20) {
                  rej('Timeout awaiting quotes for: ' + tickers.join(','));
                  clearInterval(interval);
               }

               const quotes = Object.values(this._lastQuotes).filter(x => !isVoid(x));  

               const quotesCount = quotes.length;

               if (quotesCount !== tickers.length) {
                  return;
               }

               const sum = quotes.map(x => x.mid).reduce((p, c) => p + c, 0);

               const offset = this.prepareOffsetValue(sum);

               res(offset);

               clearInterval(interval);

            }, 100);

         } else {
            
            const sum = quotes.map(x => x.mid).reduce((p, c) => p + c, 0);
            
            const offset = this.prepareOffsetValue(sum);
            
            res(offset);

         };
      });
   
      return prms;
   }

   //

   stop() {
      if (this._unsubscriber) {
         this._unsubscriber.unsubscribe();
      }
   }

   //

   private prepareOffsetValue(value: number) {
      
      if (value < 0) {
         value = 0;
      }

      if (isVoid(this._underlying)) {
         return Math.round(value);
      }
       
      let step = this._underlying === 'SPX' ? 5 : 1;
      
      const offset = Math.round(Math.round(value) / step) * step;

      return offset;
   }
}
