import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolCommand} from '../../../service-model/protocol-command.interface';

export class ArchivePosition implements ProtocolCommand {
  constructor(public readonly positionId: string) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.ArchivePosition`;
  }
}
