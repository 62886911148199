import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ClipboardService} from '../clipboard.service';
import {EtsConstants} from '../ets-constants.const';
import {LastQuoteCacheService} from '../last-quote-cache.service';
import {MessageBusService} from '../message-bus.service';
import {OptionsChainService} from '../option-chains.service';
import {makeOptionDisplayName, OptionStrategy, OptionType} from '../options-common/options.model';
import {PanelBaseComponent} from '../panels/panel-base.component';
import {PortfolioItemType} from '../portfolios/portfolios.model';
import {QuoteDto} from '../shell-communication/dtos/quote-dto.class';
import {OptionExpirationDescriptor, StrategyPriceDto} from '../shell-communication/shell-dto-protocol';
import {SymbolPickerComponent} from '../symbol-picker/symbol-picker.component';
import {TradingInstrument} from '../trading-instruments/trading-instrument.class';
import {TradingInstrumentsService} from '../trading-instruments/trading-instruments-service.interface';
import {MarketSide} from '../trading-model/market-side.enum';
import {OrderType} from '../trading-model/order-type.enum';
import {TimeInForce} from '../trading-model/time-in-force.enum';
import {ComboHighlightedItem, ComboHighlightedUIMessage, SymbolHighlighted} from '../ui-messages/ui-messages';
import {
    DetectMethodChanges,
    DetectSetterChanges,
    DxValueChanged,
    findAtmStrikeIndex,
    isCashSettledOptionTicker,
    isNullOrUndefined,
    isTruthy,
    isValidNumber,
    isVoid,
    wrapInPromise
} from '../utils';
import {PricingPadLeg, PricingPadStrategy} from './options-pricing-pad-model';
import {TradingInstrumentKind} from '../trading-instruments/trading-instrument-kind.enum';
import {UserSettingsService} from "../user-settings.service";
import {OrderLeg} from "../multi-trade-pad/multi-leg-order/order-leg.class";
import {ActionType, LegType} from "../multi-trade-pad/multi-trade-pad.model";
import * as Enumerable from "linq";

interface PanelState {
    isLinkedToSymbol: boolean;
}

type ExpirationDateMode = 'None' | 'Mouse Over' | 'Visible';

const SettingsKey = 'options-pricing-pad';

type OptionStrategySettings =
      'opp.option-type'
    | 'opp.width'
    | 'opp.offset';

interface Settings {
    enableAutoSorting: boolean;
    showExpirationDateMode: ExpirationDateMode;
    symbol: string;
    strategy: OptionStrategy;
}

type AppliedSettings = {
    selectedStrategy?: OptionStrategy,
    strikeWidth?: number,
    strikeOffset?: number,
    optionType?: LegsOptionType
};

type OnMoveLegsSynchronouslyParams = {
    direction: 'up' | 'down',
    layoutId: string,
    pad: OptionsPricingPadComponent,
    strikeStep: number
};

type LegsOptionType = 'Calls' | 'Puts' | 'Calls & Puts';

@Component({
    selector: 'ets-options-pricing-pad',
    templateUrl: './options-pricing-pad.component.html',
    styleUrls: ['./options-pricing-pad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OptionsPricingPadComponent extends PanelBaseComponent {

    constructor(
        protected readonly _changeDetector: ChangeDetectorRef,
        protected readonly _userSettingsService: UserSettingsService,
        protected readonly _messageBus: MessageBusService,
        private _optionsChainService: OptionsChainService,
        private _lastQuoteCache: LastQuoteCacheService,
        private _toastr: ToastrService,
        private _clipboardService: ClipboardService,
        private _tiService: TradingInstrumentsService,
    ) {
        super(_changeDetector, _userSettingsService, _messageBus);
    }

    private _availableExpirations: OptionExpirationDescriptor[] = [];
    private _orderType = OrderType.Limit;
    private _unsubscriber: Subject<void>;
    private _pricingModelByStrategyCode: Record<string, PricingPadStrategy> = {};

    @ViewChild(SymbolPickerComponent) symbolPicker: SymbolPickerComponent;

    strategiesList: OptionStrategy[] = [
        'BOS',
        'Butterfly',
        'Butterfly - Double',
        'Butterfly - Triple',
        'Collar',
        'Condor',
        'Condor - Double',
        'Iron Butterfly',
        'Iron Condor',
        'Ladder',
        'Married Stock',
        'Option - Long',
        'Option - Short',
        'Ratio Back Spread',
        'Risk Reversal',
        'Slingshot',
        'Slingshot - Modified',
        'Slingshot - Double',
        'Sponsored Long',
        'Straddle',
        'Strangle',
        'Vertical',
        'Vertical - Double',
    ];

    //
    selectedStrategy: OptionStrategy;

    optionType: LegsOptionType = 'Calls';

    get isOptionTypeAvailable(): boolean {

        if (!this.selectedStrategy) {
            return false;
        }

        switch (this.selectedStrategy) {
            case "Collar":
            case "Risk Reversal":
            case "Iron Butterfly":
            case "Iron Condor":
            case "Married Stock":
            case "Strangle":
            case "Straddle":
            case 'Condor - Double':
            case 'Butterfly - Double':
            case 'Slingshot - Double':
            case 'Vertical - Double':
                return false;
            default:
                return true;
        }
    }

    get optionTypeList(): LegsOptionType[] {

        if (isVoid(this.selectedStrategy)) {
            return [];
        }

        const list = [];
        list.push('Calls', 'Puts');
        switch (this.selectedStrategy) {
            case "Butterfly - Double":
            case 'Collar':
            case 'Ladder':
            case 'Risk Reversal':
            case 'Iron Condor':
            case 'Iron Butterfly':
            case 'Straddle':
            case 'Strangle':
            case 'Condor - Double':
            case 'Vertical - Double':
            case 'Slingshot - Double':
                list.push('Calls & Puts');
                break;
        }

        return list;
    }

    //
    tradingInstrument: TradingInstrument;

    //
    selectedPricingModel: PricingPadStrategy;

    //
    allPricingModels: PricingPadStrategy[] = [];

    //
    orderDuration = TimeInForce.GTC;

    //
    availableOrderDurations: any[] = [
        {value: TimeInForce.Day, text: 'Day'},
        {value: TimeInForce.GTC, text: 'GTC'},
    ];

    //
    get orderType(): OrderType {
        return this._orderType;
    }

    @DetectSetterChanges()
    set orderType(v: OrderType) {
        this._orderType = v;

        if (v !== OrderType.Limit) {
            this.setOrderLimitPx(null);
        }
    }

    strikeStep: number;

    lockStrikeStep = false;

    availableOrderTypes: any[] = [
        {value: OrderType.Limit, text: 'Limit'},
        {value: OrderType.Market, text: 'Market'},
    ];

    //
    orderQty = 1;

    //
    orderLimitPx: number;

    //
    lastPx: number;

    //
    turnOnLiveUpdates = true;

    enableAutoSorting = false;

    showSeparatorRows = true;

    linkToOtherPads = false;

    //
    strikeWidth: number;

    strikeOffset: number;

    showExpirationDateModes: ExpirationDateMode[] = [
        'None',
        'Visible',
        'Mouse Over'
    ];

    showExpirationDateMode: ExpirationDateMode = 'None';

    strikeOffsetMode: 'Offset' | 'Strike' = 'Offset';

    baseStrike: number;

    selectedExpiration: OptionExpirationDescriptor;

    get expirationList(): OptionExpirationDescriptor[] {
        return this._availableExpirations;
    }

    get isStrikeWidthAvailable(): boolean {
        const hasNecessary = !isVoid(this.tradingInstrument) &&
            !isVoid(this.selectedStrategy);

        if (!hasNecessary) {
            return false;
        }

        switch (this.selectedStrategy) {
            case "Married Stock":
            case "Straddle":
            case "Option - Long":
            case "Option - Short":
                return false;
            default:
                return true;
        }
    }


    get isOffsetAvailable(): boolean {
        switch (this.selectedStrategy) {
            case 'BOS':
            case "Butterfly":
            case "Butterfly - Double":
            case "Butterfly - Triple":
            case 'Condor':
            case 'Condor - Double':
            case "Risk Reversal":
            case "Vertical":
            case 'Vertical - Double':
            case "Option - Long":
            case "Option - Short":
            case "Ladder":
            case 'Slingshot':
            case 'Slingshot - Modified':
            case 'Slingshot - Double':
            case 'Sponsored Long':
                return true;
        }

        return false;
    }

    //
    private _hasChanges = false;
    get hasChanges(): boolean {
        return this._hasChanges;
    }

    //
    @DetectSetterChanges()
    set hasChanges(value: boolean) {
        this._hasChanges = value;
    }

    //
    get canSetLimitPx(): boolean {
        return this.orderType === OrderType.Limit;
    }

    //
    get limitPriceColor(): any {
        let color;

        if (this.orderLimitPx > 0) {
            color = 'green';
        } else if (this.orderLimitPx < 0) {
            color = 'red';
        }

        return {
            style: `color: ${color}`
        };
    }

    get canApply(): boolean {

        const hasBasics =
            !isVoid(this.tradingInstrument) &&
            !isVoid(this.selectedStrategy) &&
            !isVoid(this.optionType);

        const isWidthOK = this.isStrikeWidthAvailable
            ?  isValidNumber(this.strikeWidth, true)
            : true;

        const isOffsetOK = this.isOffsetAvailable
            ? isValidNumber(this.strikeOffset || 0) || isValidNumber(this.baseStrike, true)
            : true;

        return hasBasics && isOffsetOK && isWidthOK;
    };

    hasLegs(): boolean {
        if (isVoid(this.selectedPricingModel)) {
            return false;
        }

        if (isVoid(this.selectedPricingModel.legs)) {
            return false;
        }

        return true;
    }

    //
    getLegColor(leg: PricingPadLeg): string {

        if (leg.marketSide === MarketSide.Sell) {
            return 'color: red';
        }

        if (leg.marketSide === MarketSide.Buy) {
            return 'color: cornflowerblue';
        }

        return undefined;

    }

    //
    etsOnInit() {

        this._unsubscriber = new Subject();

        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onQuote(msg.payload));

        this._messageBus.of<StrategyPriceDto[]>('StrategyPriceDto')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onStrategyPrice(msg.payload));

        this._messageBus
            .of<SymbolHighlighted>('SymbolHighlighted')
            .pipe(
                takeUntil(this._unsubscriber),
                filter(msg => msg.scopeId === this.layoutTabId),
                filter(_ => this.isLinkedToSymbol),
            )
            .subscribe(msg => this.onSymbolHighlightedMessage(msg.payload));

        this._messageBus
            .of<OnMoveLegsSynchronouslyParams>('OptionPricingPadMove')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(msg => this.onMoveLegsSynchronously(msg.payload));

        this.restoreState();
    }

    //
    async etsAfterViewInit() {
        await this.restoreSettings();
    }

    //
    @DetectMethodChanges({isAsync: true})
    async onSymbolHighlightedMessage(x: SymbolHighlighted): Promise<void> {

        const ti = this._tiService.getInstrumentByTicker(x.ticker);

        if (!ti) {
            return;
        }

        if (!isNullOrUndefined(this.tradingInstrument)) {
            if (this.tradingInstrument.ticker === ti.ticker) {
                return;
            }
        }

        try {

            await this.onSymbolSelected(ti);
            this.symbolPicker.selectedInstrument = ti;

        } catch {
            //
        }
    }

    //
    etsOnDestroy() {

        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

        const allTickers = this.getSubscribedTickers(this.allPricingModels);

        if (!isNullOrUndefined(this.tradingInstrument)) {
            allTickers.push(this.tradingInstrument.ticker);
        }

        this._lastQuoteCache.unsubscribeTickers(allTickers);

        const codesToUnsubscribe = this.allPricingModels.map(x => x.strategyCode);

        this.changeOptionStrategiesSubscription(codesToUnsubscribe, []);
    }

    //
    @DetectMethodChanges({isAsync: true})
    async onSymbolSelected(symbol: TradingInstrument): Promise<void> {

        this.isLoading = true;

        try {

            await this.setNewSymbol({symbol, alreadyLoading: true});

        } finally {

            setTimeout(() => {
                this.isLoading = false;
            }, 1000);

        }

    }

    //
    @DetectMethodChanges({delay: 1})
    clearLegs() {

        this._appliedSettings = {};

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {
            try {

                this.unsubscribeCodesForPricingModels([this.selectedPricingModel]);

                const oldCodes = modelsToUpdate.map(x => x.strategyCode);

                modelsToUpdate.forEach(x => x.clearLegs());

                if (oldCodes.length > 0) {

                    this.changeOptionStrategiesSubscription(oldCodes, []);

                }

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }

        });
    }

    //
    @DetectMethodChanges({delay: 1})
    reverseLegsOption() {

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                this.unsubscribeTickersForPricingModels([this.selectedPricingModel]);

                const oldCodes = modelsToUpdate.map(x => x.getStrategyCodeAndResetIt());

                this.changeOptionStrategiesSubscription(oldCodes, []);

                modelsToUpdate.forEach(x => x.reverseLegsOption());

                this.subscribeTickersForPricingModels([this.selectedPricingModel]);

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                setTimeout(() => {
                    this.changeOptionStrategiesSubscription([], newCodes);
                }, 2000);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });
    }

    //
    @DetectMethodChanges({delay: 1})
    reverseLegsSide() {

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                const oldCodes = modelsToUpdate.map(x => x.getStrategyCodeAndResetIt());

                modelsToUpdate.forEach(x => x.reverseLegsSide());

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({isAsync: true})
    async addLeg(legType: 'option' | 'asset', leg?: PricingPadLeg): Promise<void> {

        let modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        await wrapInPromise(async () => {

            try {

                if (this.allPricingModels.length === 0) {

                    const models = this._availableExpirations.map(async (expiration) => {

                        const model = new PricingPadStrategy(
                            undefined,
                            expiration,
                            this.tradingInstrument,
                            this._lastQuoteCache
                        );

                        return model;
                    });


                    console.assert(
                        models.length === this._availableExpirations.length,
                        'pricingModels.length === availableExpirations.length'
                    );

                    this.allPricingModels = await Promise.all(models);

                    await this.onExpirationChanged({
                        expiration: this.allPricingModels[0].expiration,
                        alreadyLoading: true
                    });

                    modelsToUpdate = this.turnOnLiveUpdates
                        ? this.allPricingModels
                        : [this.selectedPricingModel];
                }

                const toUnsubscribe = modelsToUpdate.map(x => x.strategyCode);

                if (toUnsubscribe.length > 0) {
                    this.changeOptionStrategiesSubscription(toUnsubscribe, []);
                }

                let addedLeg: PricingPadLeg;
                if (isVoid(leg)) {
                    addedLeg = this.selectedPricingModel.addEmptyLeg(legType);
                } else {
                    addedLeg = this.selectedPricingModel.duplicateLeg(leg);
                }

                modelsToUpdate
                    .filter(x => x !== this.selectedPricingModel)
                    .forEach(x => {
                        x.addLeg(addedLeg);
                    });

                if (legType === 'asset') {
                    if (this.selectedPricingModel) {
                        this.subscribeTickersForPricingModels([this.selectedPricingModel]);
                    }
                }

                if (!isVoid(leg)) {
                    const newCodes = modelsToUpdate.map(x => x.strategyCode);
                    this.changeOptionStrategiesSubscription([], newCodes);
                    // const newTickers = this.getSubscribedTickers(modelsToUpdate);
                    // this._lastQuoteCache.subscribeTickers(newTickers);
                }

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({delay: 1})
    removeLeg(leg: PricingPadLeg) {

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                this.unsubscribeTickersForPricingModels([this.selectedPricingModel]);

                const oldCodes = modelsToUpdate.map(x => x.strategyCode);

                modelsToUpdate.forEach(x => x.removeLeg(legLinkId));

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.subscribeTickersForPricingModels([this.selectedPricingModel]);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({isAsync: true})
    private async onOptionStrategySelected(args: {
        value: OptionStrategy,
        alreadyLoading: boolean
    }): Promise<void> {

        if (!args.alreadyLoading) {
            this.isLoading = true;
        }

        if (isNullOrUndefined(args.value)) {

            this.clearLegs();

            return;
        }

        await wrapInPromise(async () => {
            try {

                const lastQuote = await this._lastQuoteCache.getLastQuoteWithAwait(this.tradingInstrument.ticker);

                const etalonLegs = this.setupPricingModel(
                    args.value,
                    this.selectedExpiration || this._availableExpirations[0],
                    this.tradingInstrument,
                    lastQuote
                );

                if (this.allPricingModels.length === 0) {

                    const models = this._availableExpirations.map(async (expiration) => {

                        const model = new PricingPadStrategy(
                            etalonLegs,
                            expiration,
                            this.tradingInstrument,
                            this._lastQuoteCache
                        );

                        return model;
                    });


                    console.assert(
                        models.length === this._availableExpirations.length,
                        'pricingModels.length === availableExpirations.length'
                    );

                    this.allPricingModels = await Promise.all(models);

                    await this.onExpirationChanged({
                        expiration: this.allPricingModels[0].expiration,
                        alreadyLoading: args.alreadyLoading
                    });

                    this.subscribeTickersForPricingModels([this.selectedPricingModel]);

                    if (args.value) {
                        const newCodes = this.allPricingModels.map(x => x.strategyCode);
                        this.changeOptionStrategiesSubscription([], newCodes);
                    }

                } else {

                    const modelsToUpdate = this.turnOnLiveUpdates
                        ? this.allPricingModels
                        : [this.selectedPricingModel];

                    this.hasChanges = !this.turnOnLiveUpdates;

                    this.unsubscribeTickersForPricingModels([this.selectedPricingModel]);

                    const oldCodes = modelsToUpdate.map(x => x.strategyCode);

                    modelsToUpdate.forEach(model => model.setLegs(etalonLegs));

                    this.subscribeTickersForPricingModels([this.selectedPricingModel]);

                    const newCodes = modelsToUpdate.map(x => x.strategyCode);

                    this.changeOptionStrategiesSubscription(oldCodes, newCodes);

                }
            } finally {

                if (!args.alreadyLoading) {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            }
        });
    }

    moveStructureUp() {
        setTimeout(() => {
            this.onStrikeUp(null);
        });
        if (this.linkToOtherPads) {
            this._messageBus.publishAsync({
                topic: 'OptionPricingPadMove',
                payload: {
                    pad: this,
                    direction: 'up',
                    layoutId: this.layoutTabId,
                    strikeStep: this.strikeStep
                } as OnMoveLegsSynchronouslyParams
            });
        }
    }

    moveStructureDown() {
        setTimeout(() => {
            this.onStrikeDown(null);
        });
        if (this.linkToOtherPads) {
            this._messageBus.publishAsync({
                topic: 'OptionPricingPadMove',
                payload: {
                    pad: this,
                    direction: 'down',
                    layoutId: this.layoutTabId,
                    strikeStep: this.strikeStep
                } as OnMoveLegsSynchronouslyParams
            });
        }
    }

    //
    @DetectMethodChanges({delay: 1})
    onStrikeUp(leg: PricingPadLeg) {

        if (!isValidNumber(this.strikeStep, true)) {
            this._toastr.error('Please, provide valid strike step', 'Settings Error');
            return;
        }

        let shouldLock = isVoid(leg) || this.lockStrikeStep;

        if (isVoid(this.selectedPricingModel)) {
            return;
        }

        if (isVoid(this.selectedPricingModel.legs)) {
            return;
        }

        if (isVoid(leg)) {
            leg = this.selectedPricingModel.legs[0];
        }

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            console.error('leg not found');
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                const oldTickers = shouldLock
                    ? this.getSubscribedTickers(modelsToUpdate)
                    : this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);

                this._lastQuoteCache.unsubscribeTickers(oldTickers);

                const oldCodes = modelsToUpdate.map(x => x.strategyCode);

                this.selectedPricingModel.strikeUp(this.strikeStep, legLinkId, shouldLock);
                const newLegs = this.selectedPricingModel.legs.slice();
                modelsToUpdate.forEach(x => x.setLegs(newLegs));

                const newTickers = shouldLock
                    ? this.getSubscribedTickers(modelsToUpdate)
                    : this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);

                this._lastQuoteCache.subscribeTickers(newTickers);

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({delay: 1})
    onStrikeDown(leg: PricingPadLeg) {

        if (!isValidNumber(this.strikeStep, true)) {
            this._toastr.error('Please, provide valid strike step', 'Settings Error');
            return;
        }

        let shouldLock = isVoid(leg) || this.lockStrikeStep;

        if (isVoid(this.selectedPricingModel)) {
            return;
        }

        if (isVoid(this.selectedPricingModel.legs)) {
            return;
        }

        if (isVoid(leg)) {
            leg = this.selectedPricingModel.legs[0];
        }

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            console.error('leg not found');
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                const oldTickers = shouldLock
                    ? this.getSubscribedTickers(modelsToUpdate)
                    : this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);

                this._lastQuoteCache.unsubscribeTickers(oldTickers);

                const oldCodes = modelsToUpdate.map(x => x.strategyCode);

                this.selectedPricingModel.strikeDown(this.strikeStep, legLinkId, shouldLock);
                const newLegs = this.selectedPricingModel.legs.slice();
                modelsToUpdate.forEach(x => x.setLegs(newLegs));

                const newTickers = shouldLock
                    ? this.getSubscribedTickers(modelsToUpdate)
                    : this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);

                this._lastQuoteCache.subscribeTickers(newTickers);

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({isAsync: true})
    async onExpirationTabSelectionChanged(ev): Promise<void> {

        if (!ev.event) {
            return;
        }

        const pricingModel: PricingPadStrategy = ev.itemData;

        if (!pricingModel || !pricingModel.expiration) {
            return;
        }

        const expiration = pricingModel.expiration;

        if (!expiration) {
            return;
        }

        await this.onExpirationChanged({expiration});

    }

    //
    @DetectMethodChanges({isAsync: true})
    async onExpirationDropdownSelectionChanged(ev): Promise<void> {

        if (!ev.event) {
            return;
        }

        const pricingModel: PricingPadStrategy = ev.value;

        if (!pricingModel || !pricingModel.expiration) {
            return;
        }

        const expiration = pricingModel.expiration;
        if (!expiration) {
            return;
        }

        await this.onExpirationChanged({expiration});
    }

    //
    @DetectMethodChanges({isAsync: true})
    async onExpirationChanged(args: {
        expiration: OptionExpirationDescriptor,
        alreadyLoading?: boolean
    }): Promise<void> {

        if (!args.alreadyLoading) {
            this.isLoading = true;
        }

        await wrapInPromise(() => {
            try {

                const model = this.allPricingModels.find(x => x.expiration === args.expiration);

                this.unsubscribeTickersForPricingModels([this.selectedPricingModel]);

                this.selectedPricingModel = model;

                if (model) {

                    this.subscribeTickersForPricingModels([this.selectedPricingModel]);

                    const limitPx = model.price;

                    this.setOrderLimitPx(limitPx * -1);
                }

            } finally {

                if (!args.alreadyLoading) {

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            }

        });
    }

    //
    @DetectMethodChanges({delay: 1})
    onLegMarketSideChanged(ev, pricingModel: PricingPadStrategy, leg: PricingPadLeg) {

        if (isNullOrUndefined(ev.event)) {
            return;
        }

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                const oldCodes = modelsToUpdate.map(x => x.getStrategyCodeAndResetIt());

                modelsToUpdate.forEach(x => x.changeLegSide(legLinkId, ev.value));

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({delay: 1})
    onLegOptionTypeChanged(ev, pricingModel: PricingPadStrategy, leg: PricingPadLeg) {

        if (isNullOrUndefined(ev.event)) {
            return;
        }

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                const oldTickers = this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);
                this._lastQuoteCache.unsubscribeTickers(oldTickers);

                const oldCodes = modelsToUpdate.map(x => x.getStrategyCodeAndResetIt());

                modelsToUpdate.forEach(x => x.changeLegOptionType(legLinkId, ev.value, this._lastQuoteCache));

                const newTickers = this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);
                this._lastQuoteCache.subscribeTickers(newTickers);

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }

        });

    }

    //
    @DetectMethodChanges({delay: 1})
    onLegQtyChanged(ev, leg: PricingPadLeg) {

        if (isNullOrUndefined(ev.event)) {
            return;
        }

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {
            try {

                const oldCodes = modelsToUpdate.map(x => x.getStrategyCodeAndResetIt());

                modelsToUpdate.forEach(x => x.changeLegQty(legLinkId, ev.value));

                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });

    }

    //
    @DetectMethodChanges({delay: 25})
    onLegStrikeChanged(ev, leg: PricingPadLeg) {

        const legLinkId = leg.linkId;

        if (!legLinkId) {
            return;
        }

        const modelsToUpdate = this.turnOnLiveUpdates
            ? this.allPricingModels
            : [this.selectedPricingModel];

        this.hasChanges = !this.turnOnLiveUpdates;

        this.isLoading = true;

        setTimeout(() => {

            try {

                const oldTickers = this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);
                this._lastQuoteCache.unsubscribeTickers(oldTickers);

                const oldCodes = modelsToUpdate.map(x => x.strategyCode);

                modelsToUpdate.forEach(x => x.changeLegStrike(legLinkId, ev.value, this._lastQuoteCache));

                if (this.selectedStrategy === 'BOS') {
                    this.recalculateBosStrategy(modelsToUpdate);
                } else if (this.selectedStrategy === 'Sponsored Long') {
                    this.recalculateSponsoredLongStrategy(modelsToUpdate);
                }


                const newCodes = modelsToUpdate.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

                const newTickers = this.getSubscribedTickersByLeg(modelsToUpdate, legLinkId);
                this._lastQuoteCache.subscribeTickers(newTickers);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        });
    }

    private recalculateBosStrategy(modelsToUpdate: PricingPadStrategy[]) {
        modelsToUpdate.forEach(pm => {
            const legs = Enumerable.from(pm.legs);
            let debitSpread: Enumerable.IEnumerable<PricingPadLeg>;
            let creditSpread: Enumerable.IEnumerable<PricingPadLeg>;
            if (this.optionType === 'Calls') {
                debitSpread = legs.reverse().take(2);
                creditSpread = legs.reverse().skip(2).take(2);
            } else if (this.optionType === 'Puts') {
                debitSpread = legs.take(2);
                creditSpread = legs.skip(2).take(2);
            }

            const debitSpreadWidth = debitSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);


            const creditSpreadWidth = creditSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);

            const creditSpreadQty = Math.abs(debitSpreadWidth) / Math.abs(creditSpreadWidth);

            if (isValidNumber(creditSpreadQty)) {
                creditSpread.forEach(x => x.qty = creditSpreadQty);
            }
        });
    }


    private recalculateSponsoredLongStrategy(modelsToUpdate: PricingPadStrategy[]) {
        modelsToUpdate.forEach(pm => {

            const legs = Enumerable.from(pm.legs);

            let debitSpread: Enumerable.IEnumerable<PricingPadLeg>;

            let creditSpread: Enumerable.IEnumerable<PricingPadLeg>;

            if (this.optionType === 'Calls') {
                debitSpread = legs.reverse().take(2);
                creditSpread = legs.reverse().skip(1).take(2);
            } else if (this.optionType === 'Puts') {
                debitSpread = legs.take(2);
                creditSpread = legs.skip(1).take(2);
            }

            const debitSpreadWidth = debitSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);


            const creditSpreadWidth = creditSpread
                .select((x, ix) => (x.strike as number) * ((ix % 2 === 0) ? 1 : -1))
                .aggregate(0, (a: number, b: number) => a - b);

            const creditSpreadQty = Math.abs(debitSpreadWidth) / Math.abs(creditSpreadWidth);

            if (isValidNumber(creditSpreadQty)) {
                creditSpread.forEach(x => x.qty = creditSpreadQty);
            }
        });
    }

//
    @DetectMethodChanges()
    loadLegsToMLPad(model: PricingPadStrategy) {
        this.isLoading = true;

        setTimeout(() => {

            try {

                const items = model.legs.map(leg => {
                    const i: ComboHighlightedItem = {
                        underlying: leg.isStock ? leg.ticker : leg.expiration.underlyingTicker,
                        ticker: leg.ticker,
                        tickerDisplayName: null,
                        side: leg.marketSide,
                        itemType: leg.optionType === OptionType.Call ? PortfolioItemType.Call : PortfolioItemType.Put,
                        strategyId: EtsConstants.strategies.manualStrategyId,
                        netPosition: leg.qty
                    };

                    return i;
                });

                const msg: ComboHighlightedUIMessage = {
                    spec: {
                        itemId: `${EtsConstants.strategies.manualStrategyId}|${this.tradingInstrument.ticker}`
                    },
                    items,
                    orderParams: {
                        orderType: this.orderType,
                        orderDuration: this.orderDuration,
                        orderLimitPx: this.orderLimitPx,
                        orderQty: this.orderQty
                    }
                };

                this._messageBus.publish<ComboHighlightedUIMessage>({
                    topic: 'ComboHighlightedUIMessage',
                    payload: msg,
                    scopeId: this.layoutTabId
                });

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            }
        }, 0);

    }

    //
    @DetectMethodChanges()
    setOrderLimitPx(price: number) {

        if (isNullOrUndefined(price)) {
            this.orderLimitPx = null;
            return;
        }

        const px = parseFloat(price.toFixed(2));
        this.orderLimitPx = px;
    }

    //
    copyStrategyToClipboard(strategy: PricingPadStrategy) {
        this.isLoading = true;

        setTimeout(() => {
            try {

                const descriptor = this.selectedPricingModel.expiration;

                const items = strategy.legs.map(leg => {
                    const expStlye = isCashSettledOptionTicker(leg.ticker) ? 'European' : 'American';
                    const i: ComboHighlightedItem = {
                        underlying: leg.isStock ? leg.ticker : leg.expiration.underlyingTicker,
                        ticker: leg.ticker,
                        tickerDisplayName: makeOptionDisplayName(descriptor, leg.optionType, leg.strike, expStlye),
                        side: leg.marketSide,
                        itemType: leg.optionType === OptionType.Call ? PortfolioItemType.Call : PortfolioItemType.Put,
                        strategyId: EtsConstants.strategies.manualStrategyId,
                        netPosition: leg.qty
                    };

                    return i;
                });

                const msg: ComboHighlightedUIMessage = {
                    spec: {
                        itemId: `${EtsConstants.strategies.manualStrategyId}|${this.tradingInstrument.ticker}`
                    },
                    items,
                    orderParams: {
                        orderType: this.orderType,
                        orderDuration: this.orderDuration,
                        orderLimitPx: this.orderLimitPx,
                        orderQty: this.orderQty
                    }
                };

                this._clipboardService.put('combo', msg);
                this._toastr.success('Combo copied to clipboard');

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            }
        });
    }

    //
    @DetectMethodChanges()
    applyOfflineChanges() {

        this.isLoading = true;

        setTimeout(() => {
            try {

                const oldCodes = this.allPricingModels.map(x => x.strategyCode);

                const actualLegs = this.selectedPricingModel.legs.slice();

                this.allPricingModels
                    .filter(pm => pm !== this.selectedPricingModel)
                    .forEach(pm => pm.setLegs(actualLegs));

                const newCodes = this.allPricingModels.map(x => x.strategyCode);

                this.changeOptionStrategiesSubscription(oldCodes, newCodes);

            } finally {

                setTimeout(() => {
                    this.isLoading = false;
                    this.hasChanges = false;
                }, 1000);

            }
        }, 0);

    }

    //
    private changeOptionStrategiesSubscription(unsubscribe: string[], subscribe: string[]) {

        const filteredUnsubscribe = unsubscribe.filter(x => !isNullOrUndefined(x));

        filteredUnsubscribe.forEach(x => delete this._pricingModelByStrategyCode[x]);

        const filteredSubsribe = subscribe.filter(x => !isNullOrUndefined(x));

        filteredSubsribe.forEach(x => {
            const model = this.allPricingModels.find(y => y.strategyCode === x);
            if (!isNullOrUndefined(model)) {
                this._pricingModelByStrategyCode[x] = model;
            }
        });

        this._lastQuoteCache.subscribeStrategyCodesDiff(filteredUnsubscribe, filteredSubsribe);

    }

    //

    private unsubscribeTickersForPricingModels(pricingModels: PricingPadStrategy[]) {

        const tickers = pricingModels.filter(x => !isNullOrUndefined(x)).flatMap(x => x.tickers);

        if (tickers.length > 0) {

            this._lastQuoteCache.unsubscribeTickers(tickers);

        }
    }

    //
    private subscribeTickersForPricingModels(pricingModels: PricingPadStrategy[]) {

        const tickers = pricingModels.filter(x => !isNullOrUndefined(x)).flatMap(x => x.tickers);

        if (tickers.length > 0) {

            this._lastQuoteCache.subscribeTickers(tickers);

        }
    }

    //
    private unsubscribeCodesForPricingModels(pricingModels: PricingPadStrategy[]) {

        const codes = pricingModels.filter(x => !isNullOrUndefined(x)).map(x => x.strategyCode);

        if (codes.length > 0) {

            codes.forEach(x => delete this._pricingModelByStrategyCode[x]);
            this.changeOptionStrategiesSubscription(codes, []);

        }
    }

    //
    // smart change detection inside
    private onQuote(x: QuoteDto[]): void {

        let detectChanges = false;

        if (this.tradingInstrument) {

            const lastQ = x.find(q => q.ticker === this.tradingInstrument.ticker);

            if (lastQ) {
                if (this.lastPx !== lastQ.lastPx) {
                    this.lastPx = lastQ.lastPx;
                    detectChanges = true;
                }
            }
        }

        if (this.selectedPricingModel) {
            if (this.selectedPricingModel.onQuote(x)) {
                detectChanges = true;
            }
        }

        if (detectChanges) {
            this._changeDetector.detectChanges();
        }
    }

    // smart change detection inside
    private onStrategyPrice(dtos: StrategyPriceDto[]): void {

        let detectChanges = false;

        dtos.forEach(x => {

            const pricingModel = this._pricingModelByStrategyCode[x.strategyCode];

            if (isNullOrUndefined(pricingModel)) {
                return;
            }

            if (isNullOrUndefined(pricingModel.strategyCode)) {
                return;
            }

            if (pricingModel.strategyCode !== x.strategyCode) {
                return;
            }


            const diff = Math.abs(pricingModel.price - x.price);

            if (!(isNullOrUndefined(pricingModel.price) || diff > Number.EPSILON)) {
                return;
            }

            pricingModel.price = x.price;

            detectChanges = true;

            if (!isNullOrUndefined(this.selectedPricingModel)) {
                if (this.selectedPricingModel === pricingModel) {
                    if (!isTruthy(this.orderLimitPx)) {
                        this.setOrderLimitPx(pricingModel.price * -1);
                    }
                }
            }

        });

        if (detectChanges) {
            this._changeDetector.detectChanges();
        }
    }

    //
    private getSubscribedTickers(pricingModels: PricingPadStrategy[]): string[] {
        return pricingModels.flatMap(x => x.tickers);
    }

    //
    private getSubscribedTickersByLeg(pricingModels: PricingPadStrategy[], legLinkId: string): string[] {

        if (!legLinkId) {
            return [];
        }

        const tickers = pricingModels
            .flatMap(model => model.legs.find(x => x.linkId === legLinkId))
            .map(leg => leg ? leg.ticker : null)
            .filter(ticker => !isNullOrUndefined(ticker));

        return tickers;
    }

    //
    @DetectMethodChanges({isAsync: true})
    private async setNewSymbol(args: { symbol: TradingInstrument, alreadyLoading: boolean }): Promise<void> {

        const lq = this._lastQuoteCache.subscribeTicker(args.symbol.ticker);

        if (lq) {

            this.lastPx = lq.lastPx;

        }

        const oldTickers = this.getSubscribedTickers(this.allPricingModels);

        if (!isNullOrUndefined(this.tradingInstrument)) {
            oldTickers.push(this.tradingInstrument.ticker);
        }

        this._lastQuoteCache.unsubscribeTickers(oldTickers);

        this.changeOptionStrategiesSubscription(this.allPricingModels.map(x => x.strategyCode), []);

        this.allPricingModels = [];

        this.selectedPricingModel = null;

        this.tradingInstrument = args.symbol;

        const ticker = args.symbol.ticker;

        const chain = await this._optionsChainService.getChain(ticker);

        this._availableExpirations = chain.expirations.slice();

        this.selectedExpiration = this._availableExpirations[0];
    }

    //
    protected getState(): PanelState {

        const state: PanelState = {
            isLinkedToSymbol: this.isLinkedToSymbol
        };

        return state;
    }

    //
    protected setState(state: PanelState) {

        if (state) {
            this.isLinkedToSymbol = state.isLinkedToSymbol;
        }

    }

    //
    setupPricingModel(strategy: OptionStrategy, expiration: OptionExpirationDescriptor, underlying: TradingInstrument, lastQuote: QuoteDto): PricingPadLeg[] {

        if (isNullOrUndefined(strategy)) {
            return;
        }

        const nearestExp = expiration;

        const centerIx = findAtmStrikeIndex(expiration.strikes, lastQuote);

        const atmStrike = this.strikeOffsetMode === 'Offset'
            ? nearestExp.strikes[centerIx]
            : this.baseStrike;

        const legs: PricingPadLeg[] = [];

        function pickStrike(distance: number) {

            if (distance === 0) {
                return atmStrike;
            }

            let targetStrike = atmStrike + distance;

            return targetStrike;
        }

        const offset = this.strikeOffset || 0;

        if (strategy === 'BOS') {
            const width = this.strikeWidth;

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const multiplier = this.optionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const step = this.tradingInstrument?.ticker === 'SPX' ? 5 : 1;

            const leg1 =  new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = firstStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = oType;

            const leg2 =  new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = oType;

            const creditSpreadQty = width / step;

            const leg3 =  new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Sell;
            leg3.qty = creditSpreadQty;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + step * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = oType;

            const leg4 = new PricingPadLeg(underlying);
            leg4.marketSide = MarketSide.Buy;
            leg4.qty = creditSpreadQty;
            leg4.expiration = nearestExp;
            leg4.strike = leg3.strike + step * multiplier;
            leg4.expectedStrike = leg4.strike;
            leg4.optionType = oType;

            legs.push(leg1, leg2, leg3, leg4);

            if (oType === OptionType.Call) {
                legs.reverse();
            }
        }
        else if (strategy === 'Butterfly') {

            const optionType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const multiplier = optionType === OptionType.Put ? - 1 : 1;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = optionType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = optionType;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Buy;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = optionType;

            legs.push(leg1, leg2, leg3);

            if (optionType === OptionType.Call) {
                legs.reverse();
            }
        }
        else if (strategy === 'Butterfly - Double') {

            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const secondBfType = this.optionType !== 'Calls' ? OptionType.Put : OptionType.Call;

            let multiplier;
            if (firstBfType === secondBfType) {
                multiplier = firstBfType === OptionType.Put ? -1 : 1;
            } else {
                multiplier = 1;
            }

            // 1st butterfly

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = firstBfType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = firstBfType;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Buy;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = firstBfType;

            let leg4: PricingPadLeg, leg5: PricingPadLeg, leg6: PricingPadLeg;

            // 2nd butterfly
            if (firstBfType !== secondBfType) {
                multiplier *= -1;

                leg4 = new PricingPadLeg(underlying);
                leg4.marketSide = MarketSide.Buy;
                leg4.qty = 1;
                leg4.expiration = nearestExp;
                leg4.strike = atmStrike + offset * multiplier;
                leg4.expectedStrike = leg4.strike;
                leg4.optionType = secondBfType;

            } else {
                leg4 = new PricingPadLeg(underlying);
                leg4.marketSide = MarketSide.Buy;
                leg4.qty = 1;
                leg4.expiration = nearestExp;
                leg4.strike = leg3.strike;
                leg4.expectedStrike = leg4.strike;
                leg4.optionType = secondBfType;
            }

            leg5 = new PricingPadLeg(underlying);
            leg5.marketSide = MarketSide.Sell;
            leg5.qty = 2;
            leg5.expiration = nearestExp;
            leg5.strike = leg4.strike + this.strikeWidth * multiplier;
            leg5.expectedStrike = leg5.strike;
            leg5.optionType = secondBfType;

            leg6 = new PricingPadLeg(underlying);
            leg6.marketSide = MarketSide.Buy;
            leg6.qty = 1;
            leg6.expiration = nearestExp;
            leg6.strike = leg5.strike + this.strikeWidth * multiplier;
            leg6.expectedStrike = leg6.strike;
            leg6.optionType = secondBfType;

            const legSeparator = new PricingPadLeg(underlying, true);

            if (firstBfType === secondBfType) {
                legs.push(leg1, leg2, leg3, legSeparator, leg4, leg5, leg6);
                if (firstBfType === OptionType.Call) {
                    legs.reverse();
                }
            }
            else {
                legs.unshift(leg3, leg2, leg1);
                legs.push(legSeparator);
                legs.push(leg4, leg5, leg6);
            }
        }
        else if (strategy === 'Butterfly - Triple') {

            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const secondBfType = this.optionType !== 'Calls' ? OptionType.Put : OptionType.Call;

            // Center BF
            const centerBody = new PricingPadLeg(underlying);
            centerBody.marketSide = MarketSide.Sell;
            centerBody.qty = 2;
            centerBody.expiration = nearestExp;
            centerBody.strike = atmStrike;
            centerBody.expectedStrike = centerBody.strike;
            centerBody.optionType = firstBfType;

            const centerAboveWing = new PricingPadLeg(underlying);
            centerAboveWing.marketSide = MarketSide.Buy;
            centerAboveWing.qty = 1;
            centerAboveWing.expiration = nearestExp;
            centerAboveWing.strike = centerBody.strike + this.strikeWidth;
            centerAboveWing.expectedStrike = centerAboveWing.strike;
            centerAboveWing.optionType = firstBfType;

            const centerBelowWing = new PricingPadLeg(underlying);
            centerBelowWing.marketSide = MarketSide.Buy;
            centerBelowWing.qty = 1;
            centerBelowWing.expiration = nearestExp;
            centerBelowWing.strike = centerBody.strike - this.strikeWidth;
            centerBelowWing.expectedStrike = centerBelowWing.strike;
            centerBelowWing.optionType = firstBfType;

            // Above BF
            const aboveBelowWing = new PricingPadLeg(underlying);
            aboveBelowWing.marketSide = MarketSide.Buy;
            aboveBelowWing.qty = 1;
            aboveBelowWing.expiration = nearestExp;
            aboveBelowWing.strike = centerAboveWing.strike + offset;
            aboveBelowWing.expectedStrike = aboveBelowWing.strike;
            aboveBelowWing.optionType = firstBfType;

            const aboveBody = new PricingPadLeg(underlying);
            aboveBody.marketSide = MarketSide.Sell;
            aboveBody.qty = 2;
            aboveBody.expiration = nearestExp;
            aboveBody.strike = aboveBelowWing.strike + this.strikeWidth;
            aboveBody.expectedStrike = aboveBody.strike;
            aboveBody.optionType = firstBfType;

            const aboveAboveWing = new PricingPadLeg(underlying);
            aboveAboveWing.marketSide = MarketSide.Buy;
            aboveAboveWing.qty = 1;
            aboveAboveWing.expiration = nearestExp;
            aboveAboveWing.strike = aboveBody.strike + this.strikeWidth;
            aboveAboveWing.expectedStrike = aboveAboveWing.strike;
            aboveAboveWing.optionType = firstBfType;

            // Below BF
            const belowAboveWing = new PricingPadLeg(underlying);
            belowAboveWing.marketSide = MarketSide.Buy;
            belowAboveWing.qty = 1;
            belowAboveWing.expiration = nearestExp;
            belowAboveWing.strike = centerBelowWing.strike - offset;
            belowAboveWing.expectedStrike = belowAboveWing.strike;
            belowAboveWing.optionType = firstBfType;

            const belowBody = new PricingPadLeg(underlying);
            belowBody.marketSide = MarketSide.Sell;
            belowBody.qty = 2;
            belowBody.expiration = nearestExp;
            belowBody.strike = belowAboveWing.strike - this.strikeWidth;
            belowBody.expectedStrike = belowBody.strike;
            belowBody.optionType = firstBfType;

            const belowBelowWing = new PricingPadLeg(underlying);
            belowBelowWing.marketSide = MarketSide.Buy;
            belowBelowWing.qty = 1;
            belowBelowWing.expiration = nearestExp;
            belowBelowWing.strike = belowBody.strike - this.strikeWidth;
            belowBelowWing.expectedStrike = belowBelowWing.strike;
            belowBelowWing.optionType = firstBfType;

            const legSeparator = new PricingPadLeg(underlying, true);
            const legSeparator1 = new PricingPadLeg(underlying, true);

            legs.push(
                aboveAboveWing, aboveBody, aboveBelowWing,
                legSeparator,
                centerAboveWing, centerBody, centerBelowWing,
                legSeparator1,
                belowAboveWing, belowBody, belowBelowWing,
            );
        }
        else if (strategy === 'Collar') {

            const strikeAbove = pickStrike(this.strikeWidth);

            const leg0 = new PricingPadLeg(underlying);
            leg0.marketSide = MarketSide.Buy;
            leg0.qty = 100;
            leg0.isStock = true;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Put;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeAbove;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = OptionType.Call;

            const legSeparator = new PricingPadLeg(underlying, true);
            legs.push(leg2, legSeparator, leg1);

            if (underlying.kind !== TradingInstrumentKind.Stock) {
                console.error('Underlying leg not added because of the underlying is not a stock');
            } else {
                legs.unshift(leg0);
            }

        }
        else if (strategy === 'Condor') {

            const width = this.strikeWidth;

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const multiplier = this.optionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = firstStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = oType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = oType;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + width * 2 * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = oType;

            const leg4 = new PricingPadLeg(underlying);
            leg4.marketSide = MarketSide.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = leg3.strike + width * multiplier;
            leg4.expectedStrike = leg4.strike;
            leg4.optionType = oType;

            legs.push(leg1, leg2, leg3, leg4);

            if (this.optionType === 'Calls') {
                legs.reverse();
            }

        }
        else if (strategy === 'Condor - Double') {

            const width = this.strikeWidth;

            const types = ['Calls', 'Puts'];

            for (const optionType of types) {

                const oType = optionType === 'Calls' ? OptionType.Call : OptionType.Put;

                const multiplier = optionType === 'Calls' ? 1 : -1;

                const firstStrike = atmStrike + offset * multiplier;

                const leg1 = new PricingPadLeg(underlying);
                leg1.marketSide = MarketSide.Buy;
                leg1.qty = 1;
                leg1.expiration = nearestExp;
                leg1.strike = firstStrike;
                leg1.expectedStrike = leg1.strike;
                leg1.optionType = oType;

                const leg2 = new PricingPadLeg(underlying);
                leg2.marketSide = MarketSide.Sell;
                leg2.qty = 1;
                leg2.expiration = nearestExp;
                leg2.strike = leg1.strike + width * multiplier;
                leg2.expectedStrike = leg2.strike;
                leg2.optionType = oType;

                const leg3 = new PricingPadLeg(underlying);
                leg3.marketSide = MarketSide.Sell;
                leg3.qty = 1;
                leg3.expiration = nearestExp;
                leg3.strike = leg2.strike + width * 2 * multiplier;
                leg3.expectedStrike = leg3.strike;
                leg3.optionType = oType;

                const leg4 = new PricingPadLeg(underlying);
                leg4.marketSide = MarketSide.Buy;
                leg4.qty = 1;
                leg4.expiration = nearestExp;
                leg4.strike = leg3.strike + width * multiplier;
                leg4.expectedStrike = leg4.strike;
                leg4.optionType = oType;

                legs.push(leg1, leg2, leg3, leg4);

                if (optionType === 'Calls') {
                    legs.reverse();
                    const legSeparator = new PricingPadLeg(underlying, true);
                    legs.push(legSeparator);
                }
            }
        }
        else if (strategy === 'Iron Butterfly') {

            const strikeAbove1 = pickStrike(this.strikeWidth);
            const strikeBelow1 = pickStrike(-this.strikeWidth);

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strikeAbove1;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Call;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = atmStrike;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = OptionType.Call;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = atmStrike;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = OptionType.Put;

            const leg4 = new PricingPadLeg(underlying);
            leg4.marketSide = MarketSide.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = strikeBelow1;
            leg4.expectedStrike = leg4.strike;
            leg4.optionType = OptionType.Put;

            const legSeparator = new PricingPadLeg(underlying, true);
            legs.push(leg1, leg2, legSeparator, leg3, leg4);

        }
        else if (strategy === 'Iron Condor') {

            const strikeAbove1 = pickStrike(this.strikeWidth);
            const strikeAbove2 = pickStrike(this.strikeWidth * 2);
            const strikeBelow1 = pickStrike(-this.strikeWidth);
            const strikeBelow2 = pickStrike(-this.strikeWidth * 2);

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strikeAbove2;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Call;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeAbove1;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = OptionType.Call;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = strikeBelow1;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = OptionType.Put;

            const leg4 = new PricingPadLeg(underlying);
            leg4.marketSide = MarketSide.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = strikeBelow2;
            leg4.expectedStrike = leg4.strike;
            leg4.optionType = OptionType.Put;

            const legSeparator = new PricingPadLeg(underlying, true);
            legs.push(leg1, leg2, legSeparator, leg3, leg4);

        }
        else if (strategy === 'Married Stock') {

            const leg0 = new PricingPadLeg(underlying);
            leg0.marketSide = MarketSide.Buy;
            leg0.qty = 100;
            leg0.isStock = true;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Put;

            legs.push(leg1);

            if (underlying.kind !== TradingInstrumentKind.Stock) {
                console.error('Underlying leg not added because of the underlying is not a stock');
            } else {
                legs.unshift(leg0);
            }
        }
        else if (strategy === 'Option - Long') {

            const strike = this.optionType === 'Calls'
                ? pickStrike(this.strikeOffset || 0)
                : pickStrike(-this.strikeOffset || 0);

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = oType;

            legs.push(leg1);
        }
        else if (strategy === 'Option - Short') {
            const strike = this.optionType === 'Calls'
                ? pickStrike(this.strikeOffset)
                : pickStrike(-this.strikeOffset || 0);

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Sell;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = oType;

            legs.push(leg1);
        }
        else if (strategy === 'Ratio Back Spread') {

            const strikeBelow = pickStrike(-this.strikeWidth);

            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 2;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = firstBfType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeBelow;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = firstBfType;

            legs.push(leg1, leg2);

        }
        else if (strategy === 'Straddle') {

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Call;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Buy;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = atmStrike;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = OptionType.Put;

            const legSeparator = new PricingPadLeg(underlying, true);
            legs.push(leg1, legSeparator,  leg2);

        }
        else if (strategy === 'Strangle') {

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + this.strikeWidth || 0;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Call;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Buy;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = atmStrike - this.strikeWidth || 0;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = OptionType.Put;

            const legSeparator = new PricingPadLeg(underlying, true);
            legs.push(leg1, legSeparator, leg2);
        }
        else if (strategy === 'Vertical') {

            const optionType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const multiplier = this.optionType === 'Puts' ? -1 : 1;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = optionType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = optionType;

            legs.push(leg2, leg1);

            if (optionType === OptionType.Put) {
                legs.reverse();
            }
        }
        else if (strategy === 'Vertical - Double') {

            const oTypes = [OptionType.Call, OptionType.Put];

            for (const optionType of oTypes) {

                const multiplier = optionType === OptionType.Put ? -1 : 1;

                const leg1 = new PricingPadLeg(underlying);
                leg1.marketSide = MarketSide.Buy;
                leg1.qty = 1;
                leg1.expiration = nearestExp;
                leg1.strike = atmStrike + offset * multiplier;
                leg1.expectedStrike = leg1.strike;
                leg1.optionType = optionType;

                const leg2 = new PricingPadLeg(underlying);
                leg2.marketSide = MarketSide.Sell;
                leg2.qty = 1;
                leg2.expiration = nearestExp;
                leg2.strike = leg1.strike + this.strikeWidth * multiplier;
                leg2.expectedStrike = leg2.strike;
                leg2.optionType = optionType;

                legs.push(leg1, leg2);

                if (optionType === OptionType.Call) {
                    legs.reverse();
                    const legSeparator = new PricingPadLeg(underlying, true);
                    legs.push(legSeparator);
                }
            }
        }
        else if (strategy === 'Risk Reversal') {

            const strikeAbove1 = pickStrike(offset);
            const strikeAbove2 = pickStrike(offset + this.strikeWidth);
            const strikeBelow1 = pickStrike(-offset);
            const strikeBelow2 = pickStrike(- (offset + this.strikeWidth));

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Sell;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = strikeAbove2;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = OptionType.Call;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Buy;
            leg2.qty = 1;
            leg2.expiration = nearestExp;
            leg2.strike = strikeAbove1;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = OptionType.Call;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Sell;
            leg3.qty = 1;
            leg3.expiration = nearestExp;
            leg3.strike = strikeBelow1;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = OptionType.Put;

            const leg4 = new PricingPadLeg(underlying);
            leg4.marketSide = MarketSide.Buy;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = strikeBelow2;
            leg4.expectedStrike = leg4.strike;
            leg4.optionType = OptionType.Put;

            const legSeparator = new PricingPadLeg(underlying, true);
            legs.push(leg1, leg2, legSeparator, leg3, leg4);

        }
        else if (strategy === 'Ladder') {

            const firstBfType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;
            const secondBfType = this.optionType !== 'Calls' ? OptionType.Put : OptionType.Call;

            const callMultiplier = 1;
            const putMultiplier = -1;

            let multiplier = this.optionType === 'Puts' ? putMultiplier : callMultiplier;

            if (firstBfType !== secondBfType) {
                for (let i = 0; i < 6; i++) {
                    const leg = new PricingPadLeg(underlying);
                    leg.marketSide = MarketSide.Buy;
                    leg.qty = 1;
                    leg.expiration = nearestExp;
                    leg.strike = atmStrike + (offset + this. strikeWidth * i) * multiplier;
                    leg.expectedStrike = leg.strike;
                    leg.optionType = firstBfType;

                    legs.unshift(leg);
                }

                if (this.optionType === 'Calls & Puts') {
                    const legSeparator = new PricingPadLeg(underlying, true);
                    legs.push(legSeparator);
                }

                for (let i = 0; i < 6; i++) {
                    const leg = new PricingPadLeg(underlying);
                    leg.marketSide = MarketSide.Buy;
                    leg.qty = 1;
                    leg.expiration = nearestExp;
                    leg.strike = atmStrike - (offset + this. strikeWidth * i) * multiplier;
                    leg.expectedStrike = leg.strike;
                    leg.optionType = secondBfType;

                    legs.push(leg);
                }
            }
            else {
                for (let i = 0; i < 12; i++) {
                    const leg = new PricingPadLeg(underlying);
                    leg.marketSide = MarketSide.Buy;
                    leg.qty = 1;
                    leg.expiration = nearestExp;

                    if (i === 0) {
                        leg.strike = atmStrike + offset * multiplier;
                    } else {
                        leg.strike = atmStrike + (offset + this. strikeWidth * i) * multiplier;
                    }

                    leg.expectedStrike = leg.strike;

                    leg.optionType = firstBfType;

                    if (multiplier === 1) {
                        legs.unshift(leg);
                    }
                    else {
                        legs.push(leg);
                    }
                }
            }
        }
        else if (strategy === 'Slingshot') {

            const optionType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const multiplier = optionType === OptionType.Put ? -1 : 1;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = optionType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = optionType;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Buy;
            leg3.qty = 2;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = optionType;

            legs.push(leg1,leg2, leg3);

            if (optionType === OptionType.Call) {
                legs.reverse();
            }
        }
        else if (strategy === 'Slingshot - Modified') {

            const optionType = this.optionType !== 'Puts'
                ? OptionType.Call
                : OptionType.Put;

            const multiplier = optionType === OptionType.Put ? -1 : 1;

            const leg1 = new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = atmStrike + offset * multiplier;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = optionType;

            const leg2 = new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = 2;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + this.strikeWidth * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = optionType;

            const leg3 = new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Buy;
            leg3.qty = 2;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + this.strikeWidth * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = optionType;


            const leg4 = new PricingPadLeg(underlying);
            leg4.marketSide = MarketSide.Sell;
            leg4.qty = 1;
            leg4.expiration = nearestExp;
            leg4.strike = leg3.strike + this.strikeWidth * multiplier;
            leg4.expectedStrike = leg4.strike;
            leg4.optionType = optionType;

            legs.push(leg1,leg2, leg3, leg4);

            if (optionType === OptionType.Call) {
                legs.reverse();
            }
        }
        else if (strategy === 'Slingshot - Double') {

            const optionTypes = [OptionType.Call, OptionType.Put];

            for (const optionType of optionTypes) {

                const multiplier = optionType === OptionType.Put ? -1 : 1;

                const leg1 = new PricingPadLeg(underlying);
                leg1.marketSide = MarketSide.Buy;
                leg1.qty = 1;
                leg1.expiration = nearestExp;
                leg1.strike = atmStrike + offset * multiplier;
                leg1.expectedStrike = leg1.strike;
                leg1.optionType = optionType;

                const leg2 = new PricingPadLeg(underlying);
                leg2.marketSide = MarketSide.Sell;
                leg2.qty = 2;
                leg2.expiration = nearestExp;
                leg2.strike = leg1.strike + this.strikeWidth * multiplier;
                leg2.expectedStrike = leg2.strike;
                leg2.optionType = optionType;

                const leg3 = new PricingPadLeg(underlying);
                leg3.marketSide = MarketSide.Buy;
                leg3.qty = 2;
                leg3.expiration = nearestExp;
                leg3.strike = leg2.strike + this.strikeWidth * multiplier;
                leg3.expectedStrike = leg3.strike;
                leg3.optionType = optionType;

                legs.push(leg1,leg2, leg3);

                if (optionType === OptionType.Call) {
                    legs.reverse();
                    const legSeparator = new PricingPadLeg(underlying, true);
                    legs.push(legSeparator);
                }
            }
        }
        else if (strategy === 'Sponsored Long') {
            const width = this.strikeWidth;

            const oType = this.optionType !== 'Puts' ? OptionType.Call : OptionType.Put;

            const multiplier = this.optionType === 'Calls' ? 1 : -1;

            const firstStrike = atmStrike + offset * multiplier;

            const step = this.tradingInstrument?.ticker === 'SPX' ? 5 : 1;

            const leg1 =  new PricingPadLeg(underlying);
            leg1.marketSide = MarketSide.Buy;
            leg1.qty = 1;
            leg1.expiration = nearestExp;
            leg1.strike = firstStrike;
            leg1.expectedStrike = leg1.strike;
            leg1.optionType = oType;

            const creditSpreadQty = width / step;

            const leg2 =  new PricingPadLeg(underlying);
            leg2.marketSide = MarketSide.Sell;
            leg2.qty = creditSpreadQty;
            leg2.expiration = nearestExp;
            leg2.strike = leg1.strike + width * multiplier;
            leg2.expectedStrike = leg2.strike;
            leg2.optionType = oType;


            const leg3 =  new PricingPadLeg(underlying);
            leg3.marketSide = MarketSide.Buy;
            leg3.qty = creditSpreadQty;
            leg3.expiration = nearestExp;
            leg3.strike = leg2.strike + step * multiplier;
            leg3.expectedStrike = leg3.strike;
            leg3.optionType = oType;

            legs.push(leg1, leg2, leg3);

            if (oType === OptionType.Call) {
                legs.reverse();
            }
        }
        else {
            console.error('Unknown strategy');
            return undefined;
        }

        return legs;
    }

    private _appliedSettings: AppliedSettings = {};

    async apply() {
        this._appliedSettings = {
            selectedStrategy: this.selectedStrategy,
            strikeWidth: this.strikeWidth,
            strikeOffset: this.strikeOffset,
            optionType: this.optionType
        };

        this.saveSettings();
        this.saveOptionStrategySettings();

        await this.onOptionStrategySelected({value: this.selectedStrategy, alreadyLoading: false})
    }

    hasDifference(attr: 'selectedStrategy' | 'strikeWidth' | 'strikeOffset' | 'optionType'): boolean {
        if (isVoid(this._appliedSettings)) {
            return false;
        }

        const voidFunc = (attr === 'selectedStrategy' || attr === 'optionType')
            ? isVoid
            : (x) => !isValidNumber(x, false);

        const value = this._appliedSettings[attr];
        if (voidFunc(value)) {
            return false;
        }

        const hasDiff = this[attr] !== this._appliedSettings[attr];

        return hasDiff;
    }

    @DetectMethodChanges()
    onChange(event: DxValueChanged<any>) {
        if (isVoid(event.event)) {
            return;
        }

        this.saveSettings();
    }

    private saveSettings() {
        const settings: Settings = {
            enableAutoSorting: this.enableAutoSorting,
            showExpirationDateMode: this.showExpirationDateMode,
            symbol: isVoid(this.tradingInstrument) ? null : this.tradingInstrument.ticker,
            strategy: this.selectedStrategy,
        };

        this._userSettingsService.setValue(SettingsKey, settings);
    }

    private async restoreSettings() {
        const settings = this._userSettingsService.getValue<Settings>(SettingsKey);
        // const settings = JSON.parse(strSettings || '{}') as Settings;

        if (isVoid(settings)) {
            return;
        }

        if (!isVoid(settings.symbol)) {
            const ti = this._tiService.getInstrumentByTicker(settings.symbol);
            if (!isVoid(ti)) {
                await this.onSymbolSelected(ti);
            }
        }

        if (!isVoid(settings.strategy)) {
            this.selectedStrategy = settings.strategy;
        }


        if (!isVoid(settings.enableAutoSorting)) {
            this.enableAutoSorting = settings.enableAutoSorting;
        }

        if (!isVoid(settings.showExpirationDateMode)) {
            this.showExpirationDateMode = settings.showExpirationDateMode;
        }
    }

    async duplicateLeg(leg: PricingPadLeg) {
        await this.addLeg(null, leg);
    }

    @DetectMethodChanges()
    onStrategyChanged($event: DxValueChanged<OptionStrategy>) {
        setTimeout(() => {

            this.restoreOptionStrategySettings();

            const cpOnlyStrats: OptionStrategy[] = [
                'Collar',
                'Butterfly - Double',
                'Risk Reversal',
                'Iron Butterfly',
                'Iron Condor',
                'Straddle',
                'Strangle',
                'Condor - Double',
                'Slingshot - Double',
                'Vertical - Double'
            ];

            let ix = cpOnlyStrats.indexOf($event.value);

            if (ix >= 0) {
                this.optionType = 'Calls & Puts';
            } else if ($event.value === 'Married Stock') {
                this.optionType = 'Puts';
            }

            if (!this.isOffsetAvailable) {
                this.strikeOffset = undefined;
            }

            if (!this.isStrikeWidthAvailable) {
                this.strikeWidth = null;
            }

            this._changeDetector.detectChanges();
        });

        if (isVoid($event.value)) {
            this.clearLegs();
        }

        this.saveSettings();
    }

    private onMoveLegsSynchronously(args: OnMoveLegsSynchronouslyParams) {
        if (!this.linkToOtherPads) {
            return;
        }

        if (this === args.pad) {
            return;
        }

        if (this.layoutTabId !== args.layoutId) {
            return;
        }

        if (this.tradingInstrument !== args.pad.tradingInstrument) {
            return;
        }

        this.strikeStep = args.strikeStep;

        if (args.direction === 'up') {
            this.onStrikeUp(null);
        }
        else if (args.direction === 'down') {
            this.onStrikeDown(null);
        }
    }

    getSeparatorType() {
        const every = this.selectedPricingModel.legs
            .filter(x => !x.isSeparator && !x.isStock)
            .map(x => x.optionType)
            .every( (v,ix,arr) => v === arr[0]);

        return every ? 'Row' : 'Line';
    }

    private saveOptionStrategySettings() {

        const keys: OptionStrategySettings[] = [
            'opp.width',
            'opp.offset',
            'opp.option-type'
        ];

        keys.forEach(x => {
            const key = x + `.${this.tradingInstrument.ticker}.${this.selectedStrategy}`;

            switch (x) {
                case "opp.option-type":
                    this._userSettingsService.setValue(key, this.optionType);
                    break;
                case "opp.width":
                    this._userSettingsService.setValue(key, this.strikeWidth);
                    break;
                case "opp.offset":
                    this._userSettingsService.setValue(key, this.strikeOffset);
                    break;
            }
        });
    }

    private restoreOptionStrategySettings() {

        const keys: OptionStrategySettings[] = [
            'opp.width',
            'opp.offset',
            'opp.option-type'
        ];

        keys.forEach(x => {
            const key = x + `.${this.tradingInstrument.ticker}.${this.selectedStrategy}`;
            const val = this._userSettingsService.getValue(key) as any;

            switch (x) {
                case "opp.option-type":
                    this.optionType = val as any;
                    break;
                case "opp.width":
                    const sw = val; //parseInt(val);
                    this.strikeWidth = isValidNumber(sw) ? sw : undefined;
                    break;
                case "opp.offset":
                    const so = val; // parseInt(val);
                    this.strikeOffset = isValidNumber(so) ? so : undefined;
                    break;
            }
        });
    }

    @DetectMethodChanges()
    changeOffsetMode(offsetMode: 'Offset' | 'Strike') {
        this.strikeOffsetMode = offsetMode;
        if (offsetMode === 'Offset') {
            this.baseStrike = undefined;
        } else if (offsetMode === 'Strike') {
            this.strikeOffset = undefined;
        }
     }
}
