import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PortfolioBlockViewModel } from './portfolio-block.model';

@Component({
   selector: 'ets-portfolio-overview-block',
   templateUrl: 'portfolio-overview-block.component.html',
   styleUrls: ['portfolio-overview-block.component.scss', '../table-styles.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class PortfolioOverviewBlockComponent implements OnInit, AfterViewInit {

   constructor(private _changeDetector: ChangeDetectorRef) { }
      
   private _viewModel: PortfolioBlockViewModel = { portfolioItems: [] };
   get viewModel(): PortfolioBlockViewModel {
      return this._viewModel;
   }
   
   @Input() set viewModel(v: PortfolioBlockViewModel) {
      this._viewModel = v;
      this._changeDetector.detectChanges();
   }
   

   ngOnInit() { }

   ngAfterViewInit(): void {
      this._changeDetector.detach();
   }

}
