<div class="overview-section" #section>

   <div class="header">
      <ets-section-header [header]="this.header">
      </ets-section-header>
   </div>

   <div class="body">
      
      <div class="legs">
         
         <div class="leg-blocks">
            <ng-container 
               *ngIf="this.isSingleDirection; then legBlocksSingle; else legBlocksDouble">
            </ng-container> 
         </div>

      </div>
      
      <div class="separator">
         <hr>
      </div>
      
      <div class="parameters">
         <ng-container 
            *ngIf="this.isSingleDirection; then parameterBlocksSingle; else parameterBlocksDouble">
         </ng-container> 
      </div>

   </div>

   <dx-load-panel 
      [visible]="this.isLoading" 
      [position]="{my: 'center', at: 'center', of: section}"
      >
   </dx-load-panel>

</div>

<!-- Legs Section -->
<ng-template #legBlocksSingle>
   <div class="side">
      
      <div class="blocks">

         <!-- Interest -->
         <ng-container *ngTemplateOutlet="interestBlock; context: {$implicit: this.interestOverview}"></ng-container>
        
         <!-- Potfolio -->
         <ng-container *ngIf="this.showPortfolioOverview">
            <ng-container *ngTemplateOutlet="portfolioBlock; context: {$implicit: this.portfolioOverview}">
            </ng-container>
         </ng-container>

         <!-- SO -->
         <ng-container *ngTemplateOutlet="vanillaLegBlock; context: {$implicit: this.shortOptionLegs}">
         </ng-container>
         
         
         <!-- Spread -->
         <ng-container *ngTemplateOutlet="spreadLegBlock; context: {$implicit: this.debitSpreadLegs}">
         </ng-container>
         
         <!-- Protective -->
         <ng-container *ngTemplateOutlet="vanillaLegBlock; context: {$implicit: this.protectiveOptionLegs}">
         </ng-container>

      </div>

   </div>
</ng-template>

<ng-template #legBlocksDouble>
   
   <div class="double-container">
      
      <div class="common">
         <!-- Interest -->
         <ng-container *ngTemplateOutlet="interestBlock; context: {$implicit: this.interestOverview}"></ng-container>
      </div>
      
      <div class="sides">
         
            <div class="side">
               
               <div class="blocks">
         
                  <!-- SO -->
                  <ng-container *ngTemplateOutlet="vanillaLegBlock; context: {$implicit: this.shortOptionLegs}">
                  </ng-container>
                  
                  
                  <!-- Spread -->
                  <ng-container *ngTemplateOutlet="spreadLegBlock; context: {$implicit: this.debitSpreadLegs}">
                  </ng-container>
                  
                  <!-- Protective -->
                  <ng-container *ngTemplateOutlet="vanillaLegBlock; context: {$implicit: this.protectiveOptionLegs}">
                  </ng-container>
         
               </div>
         
            </div>
            
            <div class="side">
               
               <div class="blocks">
         
                  <!-- SO -->
                  <ng-container *ngTemplateOutlet="vanillaLegBlock; context: {$implicit: this.shortOptionLegs2}">
                  </ng-container>
                  
                  
                  <!-- Spread -->
                  <ng-container *ngTemplateOutlet="spreadLegBlock; context: {$implicit: this.debitSpreadLegs2}">
                  </ng-container>
                  
                  <!-- Protective -->
                  <ng-container *ngTemplateOutlet="vanillaLegBlock; context: {$implicit: this.protectiveOptionLegs2}">
                  </ng-container>
         
               </div>
         
            </div>
         
      </div>

   </div>

</ng-template>



<ng-template #vanillaLegBlock let-data> 
   <fieldset class="overview-block leg">
      
      <legend class="title">{{data?.header}}</legend>

      <div class="na" *ngIf="!data?.hasLeg">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasLeg"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr>
                  <td class="parameter">Position</td>
                  <td class="value">   
                     <div class="leg-descriptor">
                        <div>{{data?.qty}}</div>
                        <div>{{data?.asset}}</div> 
                        <div>{{data?.expirationDate}}</div>
                        <div>{{data?.daysToExpiration}} days left</div>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>

<ng-template #spreadLegBlock let-data> 
   <fieldset class="overview-block leg">
      
      <legend class="title">{{data?.header}}</legend>

      <div class="na" *ngIf="!data?.hasLegs">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasLegs"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr>
                  <td class="parameter">Long Leg</td>
                  <td class="value" *ngIf="data?.hasLongLeg">
                     <div class="leg-descriptor">
                        <div>{{data?.longLegQty}}</div>
                        <div>{{data?.longLegAsset}}</div> 
                        <div>{{data?.longLegExpirationDate}}</div>
                        <div>{{data?.longLegDaysToExpiration}} days left</div>
                     </div>
                  </td>
                  <td class="value" *ngIf="!data?.hasLongLeg">
                     <div class="leg-descriptor na">
                        <div>N/A</div>
                     </div>
                  </td>
               </tr>

               <tr>
                  <td colspan="2">
                     <hr style="width: 50%; text-align: center;">
                  </td>
               </tr>

               <tr>
                  <td class="parameter">Short Leg</td>
                  <td class="value" *ngIf="data?.hasShortLeg">   
                     <div class="leg-descriptor">
                        <div>{{data?.shortLegQty}}</div>
                        <div>{{data?.shortLegAsset}}</div> 
                        <div>{{data?.shortLegExpirationDate}}</div>
                        <div>{{data?.shortLegDaysToExpiration}} days left</div>
                     </div>
                  </td> 
                  <td class="value" *ngIf="!data?.hasShortLeg">   
                     <div class="leg-descriptor na">
                        <div>N/A</div>
                     </div>
                  </td>
               </tr>

            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>

<ng-template #interestBlock let-data> 
   <fieldset class="overview-block leg" [ngClass]="{'doubled': !this.isSingleDirection}">
      
      <legend class="title">{{data?.header}}</legend>

      <div class="na" *ngIf="!data?.hasSettings">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasSettings"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr>
                  <td class="parameter">Amount</td>
                  <td class="value">{{data?.amount | currency}}</td>
               </tr>
               <tr>
                  <td class="parameter">Interest Rate</td>
                  <td class="value">{{ data?.interestRate | percent:'1.2-2' }}</td>
               </tr>
               <tr>
                  <td class="parameter">Charge Interval</td>
                  <td class="value">{{ data?.interval }}</td>
               </tr>
               <tr>
                  <td class="parameter">Charge Time</td>
                  <td class="value">{{ data?.chargeTime }}</td>
               </tr>
               <tr>
                  <td class="parameter">Charge Timezone</td>
                  <td class="value">{{ data?.chargeTimezone }}</td>
               </tr>
            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>

<ng-template #portfolioBlock let-data> 
   <fieldset class="overview-block leg">
      
      <legend class="title">{{data?.header}}</legend>

      <div class="na" *ngIf="!data?.isAvailable">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.isAvailable"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr *ngFor="let item of data?.items">
                  <td class="parameter" [style.width]="'30%'">{{item?.ticker}}</td>
                  <td class="value" [style.width]="'40%'">{{item?.netPosition || 0}} sh.</td>
                  <td class="price" [style.width]="'30%'">
                     <ets-last-price [showLabel]="false" [asset]="item?.ticker"></ets-last-price>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>



<!-- Parameters Section -->
<ng-template #parameterBlocksSingle>
   <div class="side">
      <div class="blocks parameters">

         <!-- Global Settings  -->
         <ng-container *ngTemplateOutlet="globalParametersTpl; context: {$implicit: this.globalParametersBlock}">
         </ng-container>
         
         <!-- Short Option  -->
         <ng-container *ngTemplateOutlet="strategyParameters; context: {$implicit: this.shortOptionParameters}">
         </ng-container>

         <!-- Debit Spread  -->
         <ng-container *ngTemplateOutlet="debitSpreadParametersTpl; context: {$implicit: this.debitSpreadParameters}">
         </ng-container>

         <!-- Protective Option  -->
         <ng-container *ngTemplateOutlet="protectiveOptionParametersTpl; context: {$implicit: this.protectiveOptionParameters}">
         </ng-container>

      </div>
   </div>
</ng-template>

<ng-template #parameterBlocksDouble>
   
   <div class="double-container">
      <div class="common">
         <!-- Global Settings  -->
         <ng-container *ngTemplateOutlet="globalParametersTpl; context: {$implicit: this.globalParametersBlock}">
         </ng-container>
      </div>

      <div class="sides">
         
         <div class="side">
            <div class="blocks parameters">
                     
               <!-- Short Option  -->
               <ng-container *ngTemplateOutlet="strategyParameters; context: {$implicit: this.shortOptionParameters}">
               </ng-container>
      
               <!-- Debit Spread  -->
               <ng-container *ngTemplateOutlet="debitSpreadParametersTpl; context: {$implicit: this.debitSpreadParameters}">
               </ng-container>
      
               <!-- Protective Option  -->
               <ng-container *ngTemplateOutlet="protectiveOptionParametersTpl; context: {$implicit: this.protectiveOptionParameters}">
               </ng-container>
      
            </div>
         </div>
         
         <div class="side">
            <div class="blocks parameters">
               
               <!-- Short Option  -->
               <ng-container *ngTemplateOutlet="strategyParameters; context: {$implicit: this.shortOptionParameters2}">
               </ng-container>
      
               <!-- Debit Spread  -->
               <ng-container *ngTemplateOutlet="debitSpreadParametersTpl; context: {$implicit: this.debitSpreadParameters2}">
               </ng-container>
      
               <!-- Protective Option  -->
               <ng-container *ngTemplateOutlet="protectiveOptionParametersTpl; context: {$implicit: this.protectiveOptionParameters2}">
               </ng-container>
      
            </div>
         </div>         
      </div>
   </div>
   
</ng-template>

<ng-template #globalParametersTpl let-data>
   <fieldset class="overview-block parameter" [ngClass]="{'doubled': !this.isSingleDirection}">
      <legend class="title">{{data?.header}}</legend>
      
      <div class="na" *ngIf="!data?.hasSettings">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasSettings"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <!-- Adjustment Time & Date -->
               <tr>
                  <td colspan="2" class="section-header">Adjustment Time Settings</td>
               </tr>
               <tr>
                  <td class="parameter">Adj. Time Mode</td>
                  <td class="value">{{data?.adjustmentTimeMode}}</td>
               </tr>
               <tr>
                  <td class="parameter">Adj. Time</td>
                  <td class="value">{{data?.adjustmentTime}}</td>
               </tr>
               <tr *ngIf="data?.showTimezone">
                  <td class="parameter">Adj. Timezone</td>
                  <td class="value">{{data?.adjustmentTimezone}}</td>
               </tr>

               <!-- Order -->
               <tr>
                  <td colspan="2" class="section-header">Order Settings</td>
               </tr>
               <tr>
                  <td class="parameter">Order Type</td>
                  <td class="value">{{data?.orderType}}</td>
               </tr>
               <tr *ngIf="data?.showLimitPrice">
                  <td class="parameter">Limit Price</td>
                  <td class="value">{{data?.limitPrice}}</td>
               </tr>
               <tr>
                  <td class="parameter">Convert To Market</td>
                  <td class="value">{{data?.convertToMarket ? 'Yes' : 'No'}}</td>
               </tr>
               <ng-container *ngIf="data?.showConvertToMarketSettings">
                  <tr>
                     <td class="parameter">Convert Mode</td>
                     <td class="value">{{data?.convertMode}}</td>
                  </tr>
                  <tr>
                     <td class="parameter">Convert Time</td>
                     <td class="value">{{data?.convertTime}}</td>
                  </tr>
                  
                  <ng-container *ngIf="data?.showConvertTimezone">
                     <tr>
                        <td class="parameter">Convert Timezone</td>
                        <td class="value">{{data?.convertTimezone}}</td>
                     </tr>
                  </ng-container>

                  <tr>
                     <td class="parameter">Replace Before Convert</td>
                     <td class="value">{{data?.replaceBeforeConvert ? 'Yes' : 'No'}}</td>
                  </tr>

                  <ng-container *ngIf="data?.replaceBeforeConvert">
                     <tr *ngIf="data?.timesToReplace">
                        <td class="parameter">Times to Replace</td>
                        <td class="value">{{data?.timesToReplace}}</td>
                     </tr>
                     <tr>
                        <td class="parameter">Replace Persistently</td>
                        <td class="value">{{data?.replacePersistently ? 'Yes' : 'No'}}</td>
                     </tr>
                     <tr>
                        <td class="parameter">Replace Every (time)</td>
                        <td class="value">{{data?.replaceEvery}}</td>
                     </tr>
                     <tr>
                        <td class="parameter">Rate of Change</td>
                        <td class="value">${{data?.rateOfChange}}</td>
                     </tr>
                     <tr>
                        <td class="parameter">Reverse Time Direction</td>
                        <td class="value">{{data?.reverseTimeDirection ? 'Yes' : 'No'}}</td>
                     </tr>
                  </ng-container>               
               </ng-container>
               
               <!-- Expiration Smart Mode -->
               <tr>
                  <td colspan="2" class="section-header">Expiration Smart Mode</td>
               </tr>
               <tr>
                  <td class="parameter">Spread</td>
                  <td class="value">{{data.esmSpread}}</td>
               </tr>
               <tr>
                  <td class="parameter">2nd Spread</td>
                  <td class="value">{{data.esm2ndSpread}}</td>
               </tr>
               <tr>
                  <td class="parameter">Protective Opt.</td>
                  <td class="value">{{data?.esmProtectiveOption}}</td>
               </tr>
               <tr>
                  <td class="parameter">2nd Protective Opt.</td>
                  <td class="value">{{data?.esm2ndProtectiveOption}}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </fieldset>
</ng-template>

<ng-template #debitSpreadParametersTpl let-data>

   <fieldset class="overview-block parameter">
      
      <legend class="title">{{data?.header}}</legend>

      <div class="na" *ngIf="!data?.hasSettings">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasSettings"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr>
                  <td class="parameter">Short Option Offset</td>
                  <td class="value">${{data?.shortOptionOffset || 0}}</td>
               </tr>
               <tr>
                  <td class="parameter">Width</td>
                  <td class="value">${{data?.width || 0}}</td>
               </tr>
               <tr>
                  <td class="parameter">Roll [x] Days Before</td>
                  <td class="value">{{data?.rollXDaysBeforeExpiration || 0}} days</td>
               </tr>
               <tr>
                  <td class="parameter">Roll Days To Exp.</td>
                  <td class="value">{{data?.rollDaysToExpiration || 0}} days</td>
               </tr>
            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>

<ng-template #protectiveOptionParametersTpl let-data>

   <fieldset class="overview-block parameter">
      
      <legend class="title">{{data?.header}}</legend>

      <div class="na" *ngIf="!data?.hasSettings">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasSettings"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr>
                  <td class="parameter">Spread Offset</td>
                  <td class="value">${{data?.spreadOffset || 0}}</td>
               </tr>
               <tr>
                  <td class="parameter">Roll [x] Days Before</td>
                  <td class="value">{{data?.rollXDaysBeforeExpiration || 0}} days</td>
               </tr>
               <tr>
                  <td class="parameter">Roll Days To Exp.</td>
                  <td class="value">{{data?.rollDaysToExpiration || 0}} days</td>
               </tr>
            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>

<ng-template #strategyParameters let-data>
   
   <fieldset class="overview-block parameter">
      <legend class="title">{{data?.header}}</legend>
         
      <div class="na" *ngIf="!data?.hasSettings">
         <p>N/A</p>
      </div>

      <div  class="table-wrapper"
            *ngIf="data?.hasSettings"
            [style.background]="data?.backgroundColor" 
            [style.color]="data?.color"
            >
         <table>
            <tbody>
               <tr>
                  <td class="parameter">Adj. Time Mode</td>
                  <td class="value">{{data?.adjustmentTimeMode}}</td>
               </tr>
               <tr>
                  <td class="parameter">Adj. Time</td>
                  <td class="value">{{data?.adjustmentTime}}</td>
               </tr>
               <tr *ngIf="data?.showTimezone">
                  <td class="parameter">Adj. Timezone</td>
                  <td class="value">{{data?.adjustmentTimezone}}</td>
               </tr>
            </tbody>
         </table>
      </div>

   </fieldset>
</ng-template>
