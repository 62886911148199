import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import { BucketType } from 'projects/shared-components/portfolios/portfolios.model';

/**
 * @returns SessionHistoryDataDto[]
 */
export class GetSessionHistoryData implements ProtocolQuery {
   public bucketType: BucketType;
   public bucketId: string;
  public getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSessionHistoryData`;
  }
}
