import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccessControlService } from 'projects/shared-components/access-control-service.class';
import { RiskManagementComponent } from '../risk-management/risk-management.component';
import { SimexSettingsComponent } from '../simex-settings/simex-settings.component';
import { StrategyCommanderComponent } from '../strategy-commander/strategy-commander.component';
import { UnaccountedDataComponent } from '../unaccounted-data/unaccounted-data.component';
import { ClearTradingDataComponent } from '../clear-trading-data/clear-trading-data.component';
import { OriginalFlagshipReportComponent } from '../disposition-reports/original-flagship/original-flagship-report.component';
import { NewFlagshipReportComponent } from '../disposition-reports/new-flagship/new-flagship-report.component';
import { DispositionReportComponent } from '../disposition-reports/disposition/disposition-report.component';
import { ArchivedPositionsComponent } from '../archived-positions/archived-positions.component';
import { AppSettingsComponent } from 'projects/shared-components/app-settings/app-settings.component';
import { StrategySessionHistoryComponent } from 'projects/shared-components/strategy-session-history/strategy-session-history.component';
import { RolloverSchedulerComponent } from '../rollover-sheduler/rollover-scheduler.component';
import { SyncOverrideDialogComponent } from 'projects/shared-components/sync-override-dialog/sync-override-dialog.component';
import { SessionHistoryPopupComponent } from '../session-history/popup/session-history-popup.component';
import { DetectSetterChanges } from 'projects/shared-components/utils';
import { ApgDefaultsDialogComponent } from 'projects/shared-components/apg-defaults-dialog/apg-defaults-dialog.component';
import {
   NormalizingCalculatorComponent
} from "../../../../shared-components/options-pricing-grid/normalizing-calculator/normalizing-calculator.component";
import {MessageBusService} from "../../../../shared-components/message-bus.service";

interface MenuItem {
   header: string;
   isVisible: boolean;
   buttonType?: 'danger' | 'normal' | 'success' | 'default';
   action: () => void;
}

export interface EtsLinkClickedMessage {
   address: string;
}

@Component({
   selector: 'ets-host-menu',
   templateUrl: 'host-menu.component.html',
   styleUrls: ['host-menu.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostMenuComponent implements OnInit, AfterViewInit {
   public constructor(
      private readonly _accessControlService: AccessControlService,
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService
   ) {
   }

   @Input()
   uniqueId: string;

   @ViewChild(SessionHistoryPopupComponent)
   sessionHistoryDialog: SessionHistoryPopupComponent;

   @ViewChild(RiskManagementComponent)
   riskManagementDialog: RiskManagementComponent;

   @ViewChild(SimexSettingsComponent)
   simexSettingsDialog: SimexSettingsComponent;

   @ViewChild(StrategyCommanderComponent)
   strategyCommanderDialog: StrategyCommanderComponent;

   @ViewChild(UnaccountedDataComponent)
   unaccountedDataDialog: UnaccountedDataComponent;

   @ViewChild(ClearTradingDataComponent)
   clearTradingDataDialog: ClearTradingDataComponent;

   @ViewChild(OriginalFlagshipReportComponent)
   originalFlagshipReportDialog: OriginalFlagshipReportComponent;

   @ViewChild(NewFlagshipReportComponent)
   newFlagshipReportDialog: NewFlagshipReportComponent;

   @ViewChild(DispositionReportComponent)
   dispositionReportDialog: DispositionReportComponent;

   @ViewChild(ArchivedPositionsComponent)
   archivedPositionsDialog: ArchivedPositionsComponent;

   @ViewChild(AppSettingsComponent)
   appSettingsDialog: AppSettingsComponent;

   @ViewChild(StrategySessionHistoryComponent)
   strategySessionHistoryDialog: StrategySessionHistoryComponent;

   @ViewChild(RolloverSchedulerComponent)
   rolloverSchedulerDialog: RolloverSchedulerComponent;

   @ViewChild(SyncOverrideDialogComponent)
   syncOverrideDialog: SyncOverrideDialogComponent;

   @ViewChild(ApgDefaultsDialogComponent)
   apgDefaultsDialog: ApgDefaultsDialogComponent;

   overlayTop: number;
   overlayLeft: number;
   overlayRight: number;
   overlayWidth: number;
   menuItems: MenuItem[];

   private _isOverlayVisible = false;
   get isOverlayVisible(): boolean { return this._isOverlayVisible; }

   @DetectSetterChanges()
   set isOverlayVisible(value: boolean) { this._isOverlayVisible = value; }

   //
   ngOnInit() {
      const isAvailable = (id) =>
         this._accessControlService.isSecureElementAvailable(id);

      const menuItems = [
         {
            header: 'Session History',
            isVisible: isAvailable('1f27e4b4-030b-4832-a0dc-91b10744ab4b'),
            action: () => {
               this.sessionHistoryDialog.show(null);
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Strategy History',
            isVisible: isAvailable('ad6d3d04-65f9-4f32-9319-360faca42411'),
            action: () => {
               this.strategySessionHistoryDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Risk Management',
            isVisible: isAvailable('1501df8b-4611-4cf9-aa68-1eb39f303630'),
            action: () => {
               this.riskManagementDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'SIMEX',
            isVisible: isAvailable('ca52daad-5955-4cc0-bb27-349f40f7c1c1'),
            action: () => {
               this.simexSettingsDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Strategy Commander',
            isVisible: isAvailable('9f6b2ce9-cc06-4aa7-8e5b-530e570ed34c'),
            action: () => {
               this.strategyCommanderDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Unaccounted Data',
            isVisible: isAvailable('600e90cf-e5c7-4448-9727-78744e0456dd'),
            action: () => {
               this.unaccountedDataDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Flagship Report (Original)',
            isVisible: isAvailable('698cf542-7eef-4f31-9bcd-7b77c599c444'),
            action: () => {
               this.originalFlagshipReportDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Flagship Report (New)',
            isVisible: isAvailable('74d72789-0c37-4ff5-b258-f9fdf902ccbc'),
            action: () => {
               this.newFlagshipReportDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Flagship Report (Disposition)',
            isVisible: isAvailable('7f0a7147-fd71-4cf3-89cc-e06d8210ba0b'),
            action: () => {
               this.dispositionReportDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Archived Positions',
            isVisible: isAvailable('50e8d5be-ada6-46a0-92a5-546a42ad7b9f'),
            action: () => {
               this.archivedPositionsDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'App Settings',
            isVisible: isAvailable('80fb1dda-78f5-4198-9b1f-4238e1fbb304'),
            action: () => {
               this.appSettingsDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Rollover Scheduler',
            isVisible: isAvailable('1c1de88e-7e20-4415-9e7f-d2dd81981a75'),
            action: () => {
               this.rolloverSchedulerDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'Sync Override',
            isVisible: isAvailable('552d4812-597f-4bca-a2d1-f29613767e39'),
            action: () => {
               this.syncOverrideDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
         {
            header: 'APG Defaults',
            isVisible: isAvailable('733e018f-8bc8-4778-81cf-de6f1d826981'),
            action: () => {
               this.apgDefaultsDialog.isVisible = true;
               this.isOverlayVisible = false;
            }
         },
      ];

      this.menuItems = menuItems.sort((a, b) => a.header.localeCompare(b.header));

      this.menuItems.push(
         {
            header: 'Clear Trading Data',
            isVisible: isAvailable('eaa077cd-778b-4db8-bc84-e7d6095d47b0'),
            action: () => {
               this.clearTradingDataDialog.isVisible = true;
               this.isOverlayVisible = false;
            },
            buttonType: 'danger'
         }
      );
   }

   //
   ngAfterViewInit() { this._changeDetector.detach(); }

   //
   onActionButtonClick($event: any): void {
      const menuButton: HTMLElement = document.getElementById(this.uniqueId);
      const rect = menuButton.getBoundingClientRect();
      this.overlayTop = rect.bottom + 2;

      if (this.uniqueId.indexOf('default') >= 0) {
         this.overlayLeft = rect.left - 5;
      } else {
         this.overlayLeft = rect.left - 1;
      }

      this.overlayWidth = 150;
      this.isOverlayVisible = !this.isOverlayVisible;
   }
}
