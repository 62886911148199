import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { GetUnaccountedData } from 'projects/shared-components/shell-communication/operations/unaccounted/get-unaccounted-data.class';
import { UnaccountedDataDto } from 'projects/shared-components/shell-communication/dtos/unaccounted-data-dto.class';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClearTradingDataUIMessage } from 'projects/shared-components/ui-messages/clear-trading-data-ui-message.class';

@Component({
  selector: 'ets-unaccounted-data-indicator',
  template: `
    <div *ngIf="isVisible" class="indicator">! UNACCOUNTED DATA !</div>
  `,
  styles: [`
    .indicator {
      font-weight: bold;
      padding: 3px;
      background: red;
    }
  `]
})
export class UnaccountedDataIndicatorComponent implements OnInit, OnDestroy {
  constructor(
    private _shellClient: ShellClientService,
    private _messageBus: MessageBusService
  ) { }

  private _unsubscriber = new Subject<any>();
  
  isVisible = false;

  async ngOnInit() {
    this._unsubscriber = new Subject();

    this._messageBus.of('HasUnaccountedDataDto')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => this._onHasUnaccountedDataDto());

    this._messageBus.of('AllUnaccountedDataAllocatedUIMessage')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => this.isVisible = false);

    this._messageBus.of<ClearTradingDataUIMessage>('ClearTradingDataUIMessage')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => this.isVisible = false);

    const qry = new GetUnaccountedData();
    const data = await this._shellClient.processQuery<UnaccountedDataDto>(qry);
    this.isVisible = this.isVisible || data.positions.length > 0 || data.orders.length > 0;
  }

  ngOnDestroy() {
    if (this._unsubscriber) {
      this._unsubscriber.next();
      this._unsubscriber. complete();
    }
  }

  private _onHasUnaccountedDataDto(): void {
    this.isVisible = true;
  }
}
