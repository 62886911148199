<div class="qty-guard">
   
   <div class="label">
      <span>
         {{this.label}}
      </span>
   </div>

   <div class="input">

      <div class="value">
         <dx-number-box 
            [showSpinButtons]="true"
            >
         </dx-number-box>
      </div>

      <div class="submit">
         <dx-button 
            width="100%" 
            text="Submit"
            (onClick)="this.onSubmitClick()"
            >
         </dx-button>
      </div>

   </div>
</div>