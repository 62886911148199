/**
 * @returns PnLSnapshotDto[]
 */
import {SHELL_PROTOCOL_OPERATIONS_NS} from '../shell-protocol-namespace.const';
import {ProtocolQuery} from '../../../service-model/protocol-query.interface';
import {PnLChartSubscriptionDto} from '../../dtos/pnl-chart-subscription-dto.interface';

export class GetPnLChartData implements ProtocolQuery {
  public constructor(
    public readonly subscription: PnLChartSubscriptionDto,
    public readonly startDate: Date,
    public readonly endDate: Date
  ) {
  }

  getOperationName(): string {
    return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPnLChartData`;
  }
}
