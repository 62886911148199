import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { WorkspaceSecurityContextService } from '../../workspace-security-context.service';

@Component({
  selector: 'ets-workspace-toolbar',
  templateUrl: './workspace-toolbar.component.html',
  styleUrls: ['./workspace-toolbar.component.scss'],
  providers: [WorkspaceSecurityContextService]
})
export class EtsWorkspaceToolbarComponent implements OnInit {
  constructor(
    public securityContext: WorkspaceSecurityContextService
  ) {
    this.contextMenu = new EventEmitter();
    this.expanded = new EventEmitter();
    this.splitRequest = new EventEmitter<MouseEvent>();
  }

  @Output()
  public contextMenu;
  @Output()
  public expanded;

  @Output() splitRequest: EventEmitter<MouseEvent>;

  @Input() isExpanded: boolean;

  ngOnInit() { }

  public onContextMenu($event): void {
    this.contextMenu.emit($event);
    $event.preventDefault();
    $event.stopPropagation();
  }

  public onExpandButtonClicked($event): void {
    this.expanded.emit();
    $event.preventDefault();
    $event.stopPropagation();
  }

  onSplitWorkspaceRequest(event: MouseEvent): void {
    this.splitRequest.emit(event);
    event.preventDefault();
    event.stopPropagation();
  }
}
