<span class="ets-tab" [ngClass]="{'active': tab?.isActive}">
   
   <i class="fas fa-link" 
      *ngIf="this.tab.isSymbolLinkCompatible"
      [ngClass]="{'linked': this.tab.isLinkedToSymbol}"
      (click)="this.onSymbolLinkedClicked()" ></i>
   
   <span class="ets-tab-text" *ngIf="!isEditing" (dblclick)="onDoubleClick($event)">{{tab?.header}}</span>
   <span class="ets-tab-editor" *ngIf="isEditing">
      <dx-text-box (onValueChanged)="onTextChanged($event)" (onKeyUp)="onKeyUp($event)"></dx-text-box>
   </span>
   <span class="ets-tab-close-button" (click)="this.onCloseClick()" *ngIf="securityContext.canClosePanel"></span>
</span>