import { DebitSpreadParametersOverviewBlockDto } from "projects/shared-components/shell-communication/shell-dto-protocol";
import { isValidNumber } from "projects/shared-components/utils";

export class DebitSpreadParametersOverviewBlock {

   constructor(
      public readonly header: string,
   ) {
      this.backgroundColor = '#050543fa';
   }

   get hasSettings() {
      return isValidNumber(this.width);
   };

   backgroundColor: string;
   color: string;

   shortOptionOffset: number;
   width: number;
   rollXDaysBeforeExpiration: number;
   rollDaysToExpiration: number;


   //
   setDto(dto: DebitSpreadParametersOverviewBlockDto, slot: 'first' | 'second') {
      this.shortOptionOffset = slot === 'first' 
         ? dto.shortOptionOffset 
         : dto.shortOptionOffset2;

      this.width = slot === 'first' 
         ? dto.width 
         : dto.width2;

      this.rollXDaysBeforeExpiration = slot === 'first' 
         ? dto.rollXDaysBeforeExpiration 
         : dto.rollXDaysBeforeExpiration2;

      this.rollDaysToExpiration = slot === 'first' 
         ? dto.rollDaysToExpiration 
         : dto.rollDaysToExpiration2;
   }

   //
   reset() {
      this.shortOptionOffset = undefined;
      this.width = undefined;
      this.rollXDaysBeforeExpiration = undefined;
      this.rollDaysToExpiration = undefined;
   }
}
