import { Component, Input, OnInit } from '@angular/core';
import { InterestCalculatorParameters } from './interest-calculator-strategy-parameters';

@Component({
   selector: 'ets-interest-calculator-strategy',
   templateUrl: 'interest-calculator-strategy.component.html',
   styleUrls: ['interest-calculator-strategy.component.scss']
})

export class InterestCalculatorStrategyComponent implements OnInit {
   constructor() { }

   @Input() parameters: InterestCalculatorParameters;
   @Input() manualMode: boolean;
   @Input() auto: boolean;

   ngOnInit() { }
}
