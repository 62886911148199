import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, isValidNumber} from "../utils";
import {ToastrService} from "ngx-toastr";
import {AtmStrikeService} from "../common-services/atm-strike-service/atm-strike.service";

@Component({
    selector: 'ets-override-atm-dialog',
    templateUrl: 'override-atm-dialog.component.html',
    styleUrls: ['override-atm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OverrideAtmDialogComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService,
        private readonly _atmService : AtmStrikeService
    ) {
    }

    private _rejecter: (reason?: any) => void;
    private _resolver: (number?: number) => void;

    title = "Provide Override ATM";

    visible = false;

    overrideAtm : number;

    get currentAtm() : number {
        return this._atmService.getCurrentAtm('SPX');
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    async show() : Promise<number> {
        this.visible = true;
        return new Promise<number>((res, rej) => {
            this._resolver = res;
            this._rejecter = rej;
        });
    }

    @DetectMethodChanges()
    useMarketAtmClicked() {
        this._rejecter();
        this.visible = false;
    }

    @DetectMethodChanges()
    useCustomAtmClicked() {
        if (!isValidNumber(this.overrideAtm)) {
            this._toastr.error('Please, provide valid override ATM');
            return;
        }
        this._resolver(this.overrideAtm);
        this.visible = false;
    }

    onHidden() {
        this._resolver = null;
        this._rejecter = null;
    }

    onInitialized($event: any) {
        $event?.component?.registerKeyHandler('escape', (args) => args?.stopPropagation());
    }
}