<div class="content-area">

   <ng-container *ngIf="this.parameters.isSingleDirection; then single; else double">
   </ng-container>

</div>

<ng-template #single>
      
   <!-- roll [x] days before expiration -->
   <div class="row-2">
      <div class="label">
         Roll [x] Days Before Expiration 
      </div>
      <div class="input">
         <dx-number-box [min]="0" 
                        [step]="1" 
                        [(value)]="this.parameters.rollDaysBeforeExpiration" 
                        format="0#d"></dx-number-box>
      </div>
   </div>
   
    <!-- roll time -->
    <div class="row-2" *ngIf="this.cpMode !== 'Automated'">
      <div class="label">Roll Time & Mode</div>
      <div class="input">
         <div class="roll-mode">
            <p>Mode</p>
            <dx-select-box [items]="this.parameters.rollTimeModes"
               [(value)]="this.parameters.rollTimeMode" (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
         </div>
         <br>
         <div class="roll-time">
            <p>Time</p>
            <ets-datetime-picker [contextWord]="this.parameters.rollTimeMode"
                                 [mode]="this.parameters.rollTimePickerMode"
                                 [(value)]="this.parameters.rollTime" [showTimezone]="false">
            </ets-datetime-picker>
            <div style="margin-top: 5px;" *ngIf="this.parameters.rollTimePickerShowTimezone">
               <ets-timezone-picker
                        [(timezone)]="this.parameters.rollTimezone"
                        [flow]="'col'">
               </ets-timezone-picker>
            </div>
         </div>
      </div>
   </div>
   

   <!-- epxiration to roll to offset -->
   <div class="row-2">
      <div class="label">Roll To Days To Expiration</div>
      <div class="input">
         <div class="expiration-offset-mode">
            <p>Mode</p>
            <dx-select-box [items]="this.parameters.getExpirationOffsetModes(this.cpMode)"
               [(value)]="this.parameters.expirationOffsetMode" (onValueChanged)="this.parameters.onChange($event)"></dx-select-box>
         </div>
         <br>
         <div class="expiration-offset-value">
            <p>Value</p>
            <dx-number-box [(value)]="this.parameters.expirationOffsetValue"
               [min]="1" [step]="1"
               [format]="this.parameters.expirationValueOffsetFormat"></dx-number-box>
         </div>
         <br>
         <div class="expiration-offset-rolltobuffer">
            <p>Expiration Preference (if unavailble)</p>
            <dx-select-box [items]="['Near', 'Far']" [(value)]="this.parameters.expirationPreference"></dx-select-box>
         </div>
      </div>
   </div>

    <!-- Put Debit Spread Offset -->
    <div class="row-2">
      <div class="label">Put Debit Spread Offset</div>
      <div class="input">
         <dx-number-box [(value)]="this.parameters.debitSpreadOffset"
            [min]="0" format="$#0"></dx-number-box>
      </div>
   </div>
   
   <div class="input" *ngIf="this.cpMode !== 'Automated'">
      <p>Order Type</p>
      <dx-select-box [items]="this.parameters.orderTypes" displayExpr="name" valueExpr="value" 
                     [(value)]="this.parameters.orderType"
                     (onValueChanged)="this.parameters.onChange($event)"
      ></dx-select-box>
   
      <div *ngIf="this.parameters.orderType === 1" style="margin-top: 10px">
         
         <fieldset style="margin-top: 5px;">
            <legend>
               <dx-check-box #ctm text="Convert To Market" [(value)]="this.parameters.convertToMarket"
                  (onValueChanged)="this.parameters.onChange($event)"></dx-check-box>
            </legend>
      
            <ets-convert-to-market *ngIf="ctm.value" [settings]="this.parameters.convertToMarketSettings"
               [hideCalendar]="true"></ets-convert-to-market>
         </fieldset>
         
      </div>
      
   </div>
</ng-template>

<ng-template #double>
   
   <!-- roll [x] days before expiration -->
   <div class="row-2">
      <div class="label">
         Roll [x] Days Before Expiration 
      </div>
      <div class="input">
         <dx-number-box 
            [min]="0" 
            [step]="1" 
            [(value)]="this.parameters.rollDaysBeforeExpiration" 
            format="0#d"
            (onValueChanged)="this.parameters.onChange($event)"
            >
         </dx-number-box>
      </div>
   </div>
   
   <!-- epxiration to roll to offset -->
   <div class="row-2">
      <div class="label">Roll To Days To Expiration</div>
      <div class="input">
         <div class="expiration-offset-mode">
            <p>Mode</p>
            <dx-select-box 
               [items]="this.parameters.getExpirationOffsetModes(this.cpMode)"
               [(value)]="this.parameters.expirationOffsetMode" 
               (onValueChanged)="this.parameters.onChange($event)"
               >
            </dx-select-box>
         </div>
         
         <br>
         
         <div class="expiration-offset-value">
            <p>Value</p>
            <dx-number-box 
               [(value)]="this.parameters.expirationOffsetValue"
               [min]="1" [step]="1"
               [format]="this.parameters.expirationValueOffsetFormat"
               (onValueChanged)="this.parameters.onChange($event)"
               >
            </dx-number-box>
         </div>
         
         <br>
         
         <div class="expiration-offset-rolltobuffer">
            <p>Expiration Preference (if unavailble)</p>
            <dx-select-box 
               [items]="['Near', 'Far']" 
               [(value)]="this.parameters.expirationPreference"
               (onValueChanged)="this.parameters.onChange($event)"
               >
            </dx-select-box>
         </div>
      </div>
   </div>

   <div class="individual">

      <fieldset class="calls">
         <legend>Calls</legend>
         <!-- Put Debit Spread Offset -->
         <div class="row-2">
            <div class="label">Put Debit Spread Offset</div>
            <div class="input">
               <dx-number-box 
                  [(value)]="this.parameters.debitSpreadOffset"
                  [min]="0" format="$#0"
                  (onValueChanged)="this.parameters.onChange($event)"
                  >
               </dx-number-box>
            </div>
         </div>
      </fieldset>
      
      <fieldset class="puts">
         <legend>Puts</legend>
         <!-- Put Debit Spread Offset -->
         <div class="row-2">
            <div class="label">Put Debit Spread Offset</div>
            <div class="input">
               <dx-number-box 
                  [(value)]="this.parameters.debitSpreadOffset2"
                  [min]="0" format="$#0"
                  (onValueChanged)="this.parameters.onChange($event)"
                  >
               </dx-number-box>
            </div>
         </div>
      </fieldset>

   </div>

</ng-template>
