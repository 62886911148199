import { ToastrService } from 'ngx-toastr';
import { GatewayType } from './gateway-type';
import { GatewayDto } from 'projects/shared-components/shell-communication/dtos/gateway-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { Logger } from 'projects/shared-components/logging/logger.interface';
import { ChangeGatewayStatus } from 'projects/shared-components/shell-communication/operations/gateways/change-gateway-status.class';
import { GatewayStatus } from 'projects/shared-components/gateways/gateway-status.enum';

export class GatewayModel {
   constructor(
      private _dto: GatewayDto,
      private _shellClient: ShellClientService,
      private _toastr: ToastrService,
      private _logger: Logger
   ) {
   }

   get displayName(): string {
      return this._dto.displayName;
   }

   get gatewayId(): string {
      return this._dto.gatewayId;
   }

   get isOnline(): boolean {
      return this._dto.status === GatewayStatus.Online;
   }

   get isBlocked(): boolean {
      return (
         this._dto.status === GatewayStatus.Disconnecting ||
         this._dto.status === GatewayStatus.Connecting ||
         this._dto.status === GatewayStatus.Syncing
      );
   }

   get status(): GatewayStatus {
      return this._dto.status;
   }

   set status(value: GatewayStatus) {
      this._dto.status = value;
   }

   get gatewayType(): GatewayType {
      return this._dto.gatewayType;
   }

   onValueChange($event) {
      console.log($event);
      this.changeState();
   }

   async changeState(): Promise<void> {
      let status = this._dto.status;
      if (status === GatewayStatus.Offline) {
         status = GatewayStatus.Online;
         this._dto.status = GatewayStatus.Connecting;
      } else if (status === GatewayStatus.Online) {
         status = GatewayStatus.Offline;
         this._dto.status = GatewayStatus.Disconnecting;
      }

      const cmd = new ChangeGatewayStatus(this._dto.gatewayId, status);

      try {
         await this._shellClient.processCommand(cmd);
      } catch (e) {
         this._toastr.error('Gateway operation completed with errors');
         const extraData = {
            gatewayId: this._dto.gatewayId,
            desiredState: status,
            error: e instanceof Error ? e.stack : e
         };
         this._logger.error('Failed to changed state', extraData);
      }
   }
}
