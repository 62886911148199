import {
  Component,
} from '@angular/core';

import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ets-time-editor-cell',
  template: `
    <div class="mood">
      <dx-number-box [showSpinButtons]="true" tabindex="0" min="0" max="23" [(value)]="hours"></dx-number-box> :
      <dx-number-box [showSpinButtons]="true" tabindex="1" min="0" max="59" [(value)]="minutes"></dx-number-box> :
      <dx-number-box [showSpinButtons]="true" tabindex="2" min="0" max="59" [(value)]="seconds"></dx-number-box>
    </div>
  `,
  styles: [
    ` 
    .mood {
      text-align: center;
    }

    dx-number-box {
      display: inline-block;
      width: 60px;
    }
    `,
  ],
})
export class TimeEditorComponent implements ICellEditorAngularComp {
  private params: any;

  hours = 0;
  minutes = 0;
  seconds = 0;

  agInit(params: any): void {
    this.params = params;
    const value: string = this.params.value;
    if (!value) {
      return;
    }
    const parts = value.split(':');
    if (parts.length > 0) {
      this.hours = Number(parts[0]);
    }
    if (parts.length > 1) {
      this.minutes = Number(parts[1]);
    }
    if (parts.length > 2) {
      this.seconds = Number(parts[2]);
    }
  }

  getValue(): any {
    return this.hours.toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' 
            + this.minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' 
            + this.seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 });
  }

  isPopup(): boolean {
    return false;
  }
}
