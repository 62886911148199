import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CashFlowStrategy } from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import { AutomationCpMode } from 'projects/shared-components/automation-cp/model/AutomationCpMode';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { ShellClientService } from 'projects/shared-components/shell-communication/shell-client.service';
import { ComboDto, DebitSpreadOverviewBlockDto, DebitSpreadParametersOverviewBlockDto, GetOverviewSectionDataModelReply, GlobalParametersOverviewBlockDto, InterestOverviewBlockDto, PortfolioItemAddedDto, PortfolioItemRemovedDto, PortfolioOverviewBlockDto, PositionRemoved, ProtectiveOptionOverviewBlockDto, ProtectiveOptionParametersOverviewBlockDto, ShortOptionOverviewBlockDto, ShortOptionParametersOverviewBlockDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { GetOverviewSectionDataModel } from 'projects/shared-components/shell-communication/shell-operations-protocol';
import { DetectMethodChanges, DetectSetterChanges, isVoid, militaryTimeToAmPm, timespanToUserFriendly } from 'projects/shared-components/utils';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PositionArchived } from 'projects/shared-components/shell-communication/dtos/position-archived.class';
import { DebitSpreadLegsOverviewBlock } from './legs/DebitSpreadLegsOverviewBlock';
import { ProtectiveOptionLegsOverviewBlock } from './legs/ProtectiveOptionLegsOverviewBlock';
import { ShortOptionLegsOverviewBlock } from './legs/ShortOptionLegsOverviewBlock';
import { GlobalParametersOverviewBlock } from './parameters/GlobalParametersOverviewBlock';
import { ShortOptionParametersOverviewBlock } from './parameters/ShortOptionParametersOverviewBlock';
import { DebitSpreadParametersOverviewBlock } from './parameters/DebitSpreadParametersOverviewBlock';
import { ProtectiveOptionParametersOverviewBlock } from './parameters/ProtectiveOptionParametersOverviewBlock';
import { InterestOverviewBlock } from './parameters/InterestOverviewBlock';
import { PortfolioOverviewBlock } from './parameters/PortfolioOverviewBlock';
import { AutomationCpMessageBusService } from 'projects/shared-components/automation-cp/services/automation-cp-message-bus.service';

@Component({
   selector: 'ets-automation-cp-body-overview-section',
   templateUrl: './overview-section.component.html',
   styleUrls: ['./overview-section.component.scss', './overview-common-styles.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewSectionComponent implements OnInit, OnDestroy {
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _shellClient: ShellClientService,
      private readonly _toastr: ToastrService,
      private readonly _messageBus: MessageBusService,
      private readonly _cpMessageBus: AutomationCpMessageBusService

   ) { }

   //
   private _unsubscriber = new Subject();

   //
   header = 'Overview';

   //
   @Input()
   automationCpMode: AutomationCpMode;

   //
   @Input()
   strategy: CashFlowStrategy;

   //
   private _combo: ComboDto;
   get combo(): ComboDto {
      return this._combo;
   }
   @Input()
   set combo(value: ComboDto) {
      this._combo = value;
      setTimeout(() => this.onComboSelected());
   }

   //
   get isSingleDirection(): boolean {
      return this.strategy !== 'Calls & Puts';
   }

   //
   private _isLoading: boolean;
   get isLoading(): boolean {
      return this._isLoading;
   }
   @DetectSetterChanges()
   set isLoading(v: boolean) {
      this._isLoading = v;
   }

   //
   portfolioOverview: PortfolioOverviewBlock;

   //
   interestOverview: InterestOverviewBlock;

   //
   shortOptionLegs: ShortOptionLegsOverviewBlock;

   //
   debitSpreadLegs: DebitSpreadLegsOverviewBlock;

   //
   protectiveOptionLegs: ProtectiveOptionLegsOverviewBlock;

   //
   globalParametersBlock: GlobalParametersOverviewBlock;

   //
   shortOptionParameters: ShortOptionParametersOverviewBlock;

   //
   debitSpreadParameters: DebitSpreadParametersOverviewBlock;

   //
   protectiveOptionParameters: ProtectiveOptionParametersOverviewBlock;

   //
   shortOptionLegs2: ShortOptionLegsOverviewBlock;

   //
   debitSpreadLegs2: DebitSpreadLegsOverviewBlock;

   //
   protectiveOptionLegs2: ProtectiveOptionLegsOverviewBlock;

   //
   shortOptionParameters2: ShortOptionParametersOverviewBlock;

   //
   debitSpreadParameters2: DebitSpreadParametersOverviewBlock;

   //
   protectiveOptionParameters2: ProtectiveOptionParametersOverviewBlock;

   //
   get showPortfolioOverview(): boolean {
      return this.strategy === 'Hedged Portfolio'
         || this.strategy === 'Reversed Hedged Portfolio';
   }

   //
   ngOnInit(): void {

      this._messageBus.of<PositionDto[]>('PositionDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onPositionDto(msg.payload));
      
      this._messageBus.of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onPortfolioItemAdded(msg.payload));
      
      this._messageBus.of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onPortfolioItemRemoved(msg.payload));

      this._messageBus.of<PositionArchived>('PositionArchived')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onPositionArchived(msg.payload));

      this._messageBus.of<PositionRemoved>('PositionRemoved')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(msg => this.onPositionRemoved(msg.payload));


      this._cpMessageBus.when('AdjustmentStrategyCreated')
            .pipe(
               takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.onComboSelected());

      this._cpMessageBus.when('AdjustmentStrategyRemoved')
            .pipe(
               takeUntil(this._unsubscriber)
            )
            .subscribe(x => this.reset());

      this._cpMessageBus.when('AdjustmentParametersUpdated')
         .pipe(
            takeUntil(this._unsubscriber)
         )
         .subscribe(x => this.onStrategyParametersUpdated());
   }

   //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //
   @DetectMethodChanges({ isAsync: true })
   async onComboSelected(): Promise<void> {

      if (isVoid(this.combo)) {
         this.reset();
         return;
      }

      this.isLoading = true;

      try {

         const qry = new GetOverviewSectionDataModel(
            this.combo.comboId,
            this.strategy
         );

         const reply = await this._shellClient.processQuery<GetOverviewSectionDataModelReply>(qry);

         if (reply.hasErrors) {
            this._toastr.error('Overview blocks loaded with errors');
            return;
         }

         if (this.showPortfolioOverview) {
            const portfolioOverview = this.makePortfolioOverview(reply.portfolioOverviewBlock);
            this.portfolioOverview = portfolioOverview;
         }

         const interestOverview = this.makeInterestParametersOverview(reply.interestOverviewBlock);
         this.interestOverview = interestOverview;

         //
         const soLegsOverview = this.makeShortOptionLegsOverview(reply.shortOptionBlock, 'first');
         this.shortOptionLegs = soLegsOverview;
         
         const soLegsOverview2 = this.makeShortOptionLegsOverview(reply.shortOptionBlock, 'second');
         this.shortOptionLegs2 = soLegsOverview2;

         //
         const spreadLegsOverview = this.makeSpreadLegsOverview(reply.debitSpreadOptionBlock, 'first');
         this.debitSpreadLegs = spreadLegsOverview;

         const spreadLegsOverview2 = this.makeSpreadLegsOverview(reply.debitSpreadOptionBlock, 'second');
         this.debitSpreadLegs2 = spreadLegsOverview2;

         //
         const poLegsOverview = this.makeProtectiveOptionLegsOverview(reply.protectiveOptionBlock, 'first');
         this.protectiveOptionLegs = poLegsOverview;
         
         const poLegsOverview2 = this.makeProtectiveOptionLegsOverview(reply.protectiveOptionBlock, 'second');
         this.protectiveOptionLegs2 = poLegsOverview2;

         //
         const globalParameters = this.makeGlobalParametersOverview(reply.globalParametersBlock);
         this.globalParametersBlock = globalParameters;

         //
         const shortOptionParameters = this.makeShortOptionParametersOverview(reply.shortOptionParametersBlock, 'first');
         this.shortOptionParameters = shortOptionParameters;
                  
         const shortOptionParameters2 = this.makeShortOptionParametersOverview(reply.shortOptionParametersBlock, 'second');
         this.shortOptionParameters2 = shortOptionParameters2;

         //
         const debitSpreadParameters = this.makeDebitSpreadParametersOverview(reply.debitSpreadParametersBlock, 'first');
         this.debitSpreadParameters = debitSpreadParameters;
         
         const debitSpreadParameters2 = this.makeDebitSpreadParametersOverview(reply.debitSpreadParametersBlock, 'second');
         this.debitSpreadParameters2 = debitSpreadParameters2;

         //
         const protectiveOptionParameters = this.makeProtectiveOptionParametersOverview(
            reply.protectiveOptionParametersBlock,
            'first'
         );
         this.protectiveOptionParameters = protectiveOptionParameters;
         
         const protectiveOptionParameters2 = this.makeProtectiveOptionParametersOverview(
            reply.protectiveOptionParametersBlock,
            'second'
         );
         this.protectiveOptionParameters2 = protectiveOptionParameters2;

      } catch (e) {

         this._toastr.error(
            '"Load Overview Data" operation completed with errors',
            'Overview'
         );

      } finally {

         this.isLoading = false;
         this._changeDetector.detectChanges();

      }
   }

   //
   @DetectMethodChanges()
   private reset() {
      if (this.interestOverview) {
         this.interestOverview.reset();
      }

      if (this.globalParametersBlock) {
         this.globalParametersBlock.reset();
      }

      if (this.shortOptionParameters) {
         this.shortOptionParameters.reset();
      }

      if (this.debitSpreadParameters) {
         this.debitSpreadParameters.reset();
      }

      if (this.protectiveOptionParameters) {
         this.protectiveOptionParameters.reset();
      }
   }

   //
   private makeShortOptionLegsOverview(
      shortOptionBlock: ShortOptionOverviewBlockDto,
      slot: 'first' | 'second'
   ): ShortOptionLegsOverviewBlock {

      if (isVoid(shortOptionBlock)) {
         return;
      }

      const header = slot === 'first' 
         ? shortOptionBlock.header 
         : shortOptionBlock.header2;

      const comboGroupId = slot === 'first' 
         ? shortOptionBlock.comboGroupId 
         : shortOptionBlock.comboGroupId2;

      const leg = slot === 'first' 
         ? shortOptionBlock.leg 
         : shortOptionBlock.leg2;

      const overview = new ShortOptionLegsOverviewBlock(header, comboGroupId);
      overview.setPortfolioItem(leg);

      return overview;
   }

   //
   private makeSpreadLegsOverview(
      debitSpreadBlockDto: DebitSpreadOverviewBlockDto,
      slot: 'first' | 'second'
   ): DebitSpreadLegsOverviewBlock {

      if (isVoid(debitSpreadBlockDto)) {
         return;
      }

      const header = slot === 'first' 
         ? debitSpreadBlockDto.header 
         : debitSpreadBlockDto.header2;

      const comboGroupId = slot === 'first' 
         ? debitSpreadBlockDto.comboGroupId 
         : debitSpreadBlockDto.comboGroupId2;

      const longLeg = slot === 'first' 
         ? debitSpreadBlockDto.longLeg 
         : debitSpreadBlockDto.longLeg2;

      const shortLeg = slot === 'first' 
         ? debitSpreadBlockDto.shortLeg 
         : debitSpreadBlockDto.shortLeg2;

      const overview = new DebitSpreadLegsOverviewBlock(header, comboGroupId);
      overview.setPortfolioItem([longLeg, shortLeg]);

      return overview;
   }

   //
   private makeProtectiveOptionLegsOverview(
      shortOptionBlock: ProtectiveOptionOverviewBlockDto,
      slot: 'first' | 'second'
   ): ProtectiveOptionLegsOverviewBlock {

      if (isVoid(shortOptionBlock)) {
         return;
      }

      const header = slot === 'first' 
         ? shortOptionBlock.header 
         : shortOptionBlock.header2;

      const comboGroupId = slot === 'first' 
         ? shortOptionBlock.comboGroupId 
         : shortOptionBlock.comboGroupId2;

      const leg = slot === 'first' 
         ? shortOptionBlock.leg 
         : shortOptionBlock.leg2;

      const overview = new ProtectiveOptionLegsOverviewBlock(header, comboGroupId);
      overview.setPortfolioItem(leg);

      return overview;
   }

   //
   private makeGlobalParametersOverview(
      dto: GlobalParametersOverviewBlockDto
   ): GlobalParametersOverviewBlock {

      const block = new GlobalParametersOverviewBlock(
         dto.header
      );

      if (isVoid(dto)) {
         return block;
      }
      
      block.setDto(dto);

      return block;
   }
   
   //
   private makePortfolioOverview(
      dto: PortfolioOverviewBlockDto
   ): PortfolioOverviewBlock {

      const block = new PortfolioOverviewBlock(dto.comboGroupId);
      block.setData(dto.items);
      return block;
   }

   //
   private makeShortOptionParametersOverview(
      dto: ShortOptionParametersOverviewBlockDto,
      slot: 'first' | 'second'
   ): ShortOptionParametersOverviewBlock {

      const header = slot === 'first' ? dto.header : dto.header2;

      const block = new ShortOptionParametersOverviewBlock(
         header
      );

      return block;
   }

   //
   private makeDebitSpreadParametersOverview(
      dto: DebitSpreadParametersOverviewBlockDto,
      slot: 'first' | 'second'
   ): DebitSpreadParametersOverviewBlock {

      if (isVoid(dto)) {
         return;
      }

      const header = slot === 'first' ? dto.header : dto.header2;

      const block = new DebitSpreadParametersOverviewBlock(
         header
      );

      block.setDto(dto, slot);

      return block;
   }

   //
   private makeProtectiveOptionParametersOverview(
      dto: ProtectiveOptionParametersOverviewBlockDto,
      slot: 'first' | 'second'
   ): ProtectiveOptionParametersOverviewBlock {

      const header = slot === 'first' ? dto.header : dto.header2;

      const block = new ProtectiveOptionParametersOverviewBlock(
         header
      );

      block.setDto(dto, slot);

      return block;
   }

   //
   private makeInterestParametersOverview(
      dto: InterestOverviewBlockDto
   ): InterestOverviewBlock{

      const block = new InterestOverviewBlock(
         dto.header
      );

      block.setDto(dto);

      return block;
   }

   //
   private onPositionDto(dtos: PositionDto[]) {

      if (!this.combo) {
         return;
      }

      const filtered = dtos.filter(x => x.comboId === this.combo.comboId);

      if (filtered.length === 0) {
         return;
      }

      let needsUpdate = false;

      filtered.forEach(dto => {

         if (this.portfolioOverview) {
            if (this.portfolioOverview.onPositionDto(dto)) {
               needsUpdate = true;
            }
         }

         if (this.shortOptionLegs) {
            if (this.shortOptionLegs.onPositionUpdate(dto)) {
               needsUpdate = true;
            }
         }

         if (this.shortOptionLegs2) {
            if (this.shortOptionLegs2.onPositionUpdate(dto)) {
               needsUpdate = true;
            }
         }

         if (this.debitSpreadLegs) {
            if (this.debitSpreadLegs.onPositionUpdate(dto)) {
               needsUpdate = true;
            }
         }

         if (this.debitSpreadLegs2) {
            if (this.debitSpreadLegs2.onPositionUpdate(dto)) {
               needsUpdate = true;
            }
         }

         if (this.protectiveOptionLegs) {
            if (this.protectiveOptionLegs.onPositionUpdate(dto)) {
               needsUpdate = true;
            }
         }

         if (this.protectiveOptionLegs2) {
            if (this.protectiveOptionLegs2.onPositionUpdate(dto)) {
               needsUpdate = true;
            }
         }
      });

      if (needsUpdate) {
         this._changeDetector.detectChanges();
      }
   }

   //
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {

      if (!this.combo) {
         return;
      }

      if (msg.comboId !== this.combo.comboId) {
         return;
      }

      let needsUpdate = false;

      if (this.portfolioOverview) {
         if (this.portfolioOverview.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.shortOptionLegs) {
         if (this.shortOptionLegs.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.shortOptionLegs2) {
         if (this.shortOptionLegs2.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs) {
         if (this.debitSpreadLegs.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }
      }
      
      if (this.debitSpreadLegs2) {
         if (this.debitSpreadLegs2.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs) {
         if (this.protectiveOptionLegs.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }

      }

      if (this.protectiveOptionLegs2) {
         if (this.protectiveOptionLegs2.onPortfolioItemRemoved(msg)) {
            needsUpdate = true;
         }

      }

      if (needsUpdate) {
         this._changeDetector.detectChanges();
      }
   }

   //
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {

      if (!this.combo) {
         return;
      }

      if (!msg.portfolioItem) {
         return;
      }

      if (msg.portfolioItem.comboId !== this.combo.comboId) {
         return;
      }

      let needsUpdate = false;

      if (this.portfolioOverview) {
         if (this.portfolioOverview.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (this.shortOptionLegs) {
         if (this.shortOptionLegs.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (this.shortOptionLegs2) {
         if (this.shortOptionLegs2.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs) {
         if (this.debitSpreadLegs.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs2) {
         if (this.debitSpreadLegs2.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs) {
         if (this.protectiveOptionLegs.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs2) {
         if (this.protectiveOptionLegs2.onPortfolioItemAdded(msg)) {
            needsUpdate = true;
         }
      }

      if (needsUpdate) {
         this._changeDetector.detectChanges();
      }
   }

   //
   private onPositionArchived(msg: PositionArchived) {
      
      if (!this.combo) {
         return;
      }

      if (msg.comboId !== this.combo.comboId) {
         return;
      }

      let needsUpdate = false;

      if (this.shortOptionLegs) {
         if (this.shortOptionLegs.onPositionArchived(msg)) {
            needsUpdate = true;
         }
      }

      if (this.shortOptionLegs2) {
         if (this.shortOptionLegs2.onPositionArchived(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs) {
         if (this.debitSpreadLegs.onPositionArchived(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs2) {
         if (this.debitSpreadLegs2.onPositionArchived(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs) {
         if (this.protectiveOptionLegs.onPositionArchived(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs2) {
         if (this.protectiveOptionLegs2.onPositionArchived(msg)) {
            needsUpdate = true;
         }
      }

      if (needsUpdate) {
         this._changeDetector.detectChanges();
      }
   }

   //
   private onPositionRemoved(msg: PositionRemoved) {
      
      if (!this.combo) {
         return;
      }

      if (msg.comboId !== this.combo.comboId) {
         return;
      }

      let needsUpdate = false;

      if (this.shortOptionLegs) {
         if (this.shortOptionLegs.onPositionRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.shortOptionLegs2) {
         if (this.shortOptionLegs2.onPositionRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs) {
         if (this.debitSpreadLegs.onPositionRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.debitSpreadLegs2) {
         if (this.debitSpreadLegs2.onPositionRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs) {
         if (this.protectiveOptionLegs.onPositionRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (this.protectiveOptionLegs2) {
         if (this.protectiveOptionLegs2.onPositionRemoved(msg)) {
            needsUpdate = true;
         }
      }

      if (needsUpdate) {
         this._changeDetector.detectChanges();
      }
   }

   //
   private onStrategyParametersUpdated(): void {
      this.onComboSelected();
   }
}
