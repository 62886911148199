import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SideboxLegDescriptor } from 'projects/shared-components/automation-cp/model/SideboxLegDescriptor';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { PositionDto } from 'projects/shared-components/shell-communication/dtos/position-dto.class';
import { PortfolioItemAddedDto, PortfolioItemDto, PortfolioItemRemovedDto } from 'projects/shared-components/shell-communication/shell-dto-protocol';
import { DetectMethodChanges } from 'projects/shared-components/utils';
import { isVoid } from 'projects/shared-components/utils';

@Component({
   selector: 'ets-automation-cp-debit-spread-sidebox',
   templateUrl: './debit-spread-sidebox.component.html',
   styleUrls: ['./debit-spread-sidebox.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebitSpreadSideboxComponent implements OnInit {
   
   constructor(
      private readonly _changeDetector: ChangeDetectorRef,
      private readonly _messageBus: MessageBusService
   ) { }

   private _comboGroupId: string;


   //
   longLeg: SideboxLegDescriptor;

   //
   shortLeg: SideboxLegDescriptor;

   //
   ngOnInit(): void {
      this._messageBus
         .of<PositionDto[]>('PositionDto')
         .subscribe(x => this.onPositionDto(x.payload));

      this._messageBus
         .of<PortfolioItemAddedDto>('PortfolioItemAddedDto')
         .subscribe(x => this.onPortfolioItemAdded(x.payload));

      this._messageBus
         .of<PortfolioItemRemovedDto>('PortfolioItemRemovedDto')
         .subscribe(x => this.onPortfolioItemRemoved(x.payload));
   }

   //
   setComboGroupId(comboGroupId: string): void {
      this._comboGroupId = comboGroupId;
   }

   @DetectMethodChanges()
   setData(comboGroupId: string, legs: PortfolioItemDto[]) {
      
      this._comboGroupId = comboGroupId;

      if (isVoid(legs)) {
         return;
      }
      
      legs.forEach(leg => {
         if (leg.netPosition > 0) {

            this.longLeg = new SideboxLegDescriptor(leg, 'SpreadLongLeg');

         } else if (leg.netPosition < 0) {
            
            this.shortLeg = new SideboxLegDescriptor(leg, 'SpreadShortLeg');

         }
      });
   }

   //
   private onPositionDto(payload: PositionDto[]): void {
      
      let updated = false;

      const filtered = payload
         .filter(x => !isVoid(x.comboGroupId))
         .filter(x => x.comboGroupId === this._comboGroupId);

      if (filtered.length === 0) {
         return;
      }

      if (!this.longLeg) {

         const longLeg = filtered.find(x => x.netPosition > 0);
         
         if (longLeg) {
            
            this.longLeg = new SideboxLegDescriptor(longLeg, 'SpreadLongLeg');
            updated = true;

         }

      } else {

         updated = this.longLeg.onPositionUpdate(filtered) || updated;

      }

      if (!this.shortLeg) {

         const shortLeg = filtered.find(x => x.netPosition < 0);
         
         if (shortLeg) {
            
            this.shortLeg = new SideboxLegDescriptor(shortLeg, 'SpreadShortLeg');
            updated = true;

         }

      } else {
         
         updated = this.shortLeg.onPositionUpdate(filtered) || updated;

      }

      if (updated) {
         this._changeDetector.detectChanges();
      }
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemRemoved(msg: PortfolioItemRemovedDto) {

      if (this.longLeg) {
         if (msg.portfolioItemId === this.longLeg.legId) {
            this.longLeg = null;
            return;
         }
      }
      
      if (this.shortLeg) {
         if (msg.portfolioItemId === this.shortLeg.legId) {
            this.shortLeg = null;
            return;
         }
      }
   }

   //
   @DetectMethodChanges()
   private onPortfolioItemAdded(msg: PortfolioItemAddedDto) {

      if (!msg.portfolioItem) {
         return;
      }

      if (msg.portfolioItem.comboGroupId !== this._comboGroupId) {
         return;
      }

      if (!msg.portfolioItem.ticker.startsWith('@')) {
         return;
      }


      if (msg.portfolioItem.netPosition > 0) {
         this.longLeg = new SideboxLegDescriptor(msg.portfolioItem, 'SpreadLongLeg');
      } else if (msg.portfolioItem.netPosition < 0) {
         this.shortLeg = new SideboxLegDescriptor(msg.portfolioItem, 'SpreadShortLeg');
      }
   }

   //
   @DetectMethodChanges()
   reset() {
      this.longLeg = this.shortLeg = undefined;
   }
}
