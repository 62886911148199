import {
   AfterViewInit,
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   Input,
   OnInit,
   ViewChild
} from '@angular/core';
import {PositionsSectionModel} from './model/PositionsSectionModel';
import {ApgAfterStatePreviewComponent} from '../after-state-preview/apg-after-state-preview.component';
import {ApgTrackingDialogComponent} from './tracking-dialog/apg-tracking-dialog.component';
import {ApgContextMenuComponent} from "../context-menu/apg-context-menu.component";
import {DxNumberBoxComponent} from "devextreme-angular/ui/number-box";
import {CashFlowStrategyRole} from "../../shell-communication/shell-dto-protocol";
import {PositionsDefaultSettings} from "../services/positions-before-state.service";
import {DetectMethodChanges, isVoid} from "../../utils";

@Component({
   selector: 'ets-adjustment-pricing-grid-positions-section',
   templateUrl: './adjustment-pricing-grid-positions-section.component.html',
   styleUrls: [
      './adjustment-pricing-grid-positions-section.component.scss',
      '../adjustment-grid-common-style.scss',
   ]
})
export class AdjustmentPricingGridPositionsSectionComponent implements OnInit, AfterViewInit {
   constructor(
       private readonly _changeDetector: ChangeDetectorRef
   ) { }


   @Input()
   viewModel: PositionsSectionModel;


   @Input()
   orientation: 'left' | 'right';


   @ViewChild(ApgAfterStatePreviewComponent)
   afterStatePreview: ApgAfterStatePreviewComponent;


   @ViewChild(ApgTrackingDialogComponent)
   trackingDialog: ApgTrackingDialogComponent;


   @ViewChild('ctxMenu')
   ctxMenu: ApgContextMenuComponent;

   @ViewChild('manualDistanceInput', {static: false})
   manualDistanceInput: DxNumberBoxComponent;


   ngOnInit(): void { }


   ngAfterViewInit(): void {
      this.viewModel.orientation = this.orientation;
      this.viewModel.afterStatePreview = this.afterStatePreview;
      this.viewModel.trackingDialog = this.trackingDialog;
      this.viewModel.ctxMenu = this.ctxMenu;
      this.viewModel.manualDistanceInput = this.manualDistanceInput;
   }

   canSetRollXDaysAfterShorts(leg: CashFlowStrategyRole, settings: PositionsDefaultSettings) {
      if (leg === 'ProtectiveOption') {
         return isVoid(settings.protectiveOptionDaysToExpiration);
      } else if (leg === 'SecondProtectiveOption') {
         return isVoid(settings.secondProtectiveOptionDaysToExpiration);
      }
   }

   canSetOptionDaysToExpiration(leg: CashFlowStrategyRole, settings: PositionsDefaultSettings) {
      if (leg === 'ProtectiveOption') {
         return isVoid(settings.protectiveOptionRollXDaysAfterShorts);
      } else if (leg === 'SecondProtectiveOption') {
         return isVoid(settings.secondProtectiveOptionRollXDaysAfterShorts);
      }
   }

   @DetectMethodChanges()
   onChange() {

   }
}
