import { Component, EventEmitter, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IDatafeedChartApi, Timezone } from '../../price-chart/data-feed-chart-api';
import {
   ChartingLibraryWidgetOptions,
   IChartingLibraryWidget,
   widget
} from '../../../webtrader/src/assets/tv/charting_library/charting_library.min';
import { ToastrService } from 'ngx-toastr';
import * as shortid from 'shortid';
import { PnLDataFeedFactoryService } from './pnl-data-feed-factory.service';
import { filter, takeUntil, throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SettingsStorageService } from '../../settings-storage-service.service';
import { MessageBusService } from 'projects/shared-components/message-bus.service';
import { getPanelStateKey } from 'projects/shared-components/utils';
import { EtsPnLChartStateInfo } from './pnl-chart-state-info.interface';
import { TimestampsService } from 'projects/shared-components/timestamps.service';
import { SymbolHighlighted } from 'projects/shared-components/ui-messages/ui-messages';
import { TradingInstrumentsService } from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import { PanelBaseComponent } from 'projects/shared-components/panels/panel-base.component';
import {UserSettingsService} from "../../user-settings.service";

interface PnLChartState {
   subscriptions: EtsPnLChartStateInfo;
   chartState: any;
   isLinkedToSymbol: boolean;
}


@Component({
   selector: 'ets-pnl-chart-2',
   templateUrl: 'pnl-chart-2.component.html',
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class PnLChart2Component extends PanelBaseComponent {
   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,

      private readonly _toastr: ToastrService,
      private readonly _timestampsService: TimestampsService,
      private readonly _dataFeedFactory: PnLDataFeedFactoryService,
      private readonly _tiService: TradingInstrumentsService,
   ) {
      
      super(_changeDetector, _userSettingsService, _messageBus);

      this.chartId = shortid.generate();
   }
   
   private _stateChanged = new EventEmitter();
   private _isChartInitialized: boolean;
   private _datafeed: IDatafeedChartApi;
   private _tvWidget: IChartingLibraryWidget | null = null;
   private _stateInfo: EtsPnLChartStateInfo;
   private _unsubscriber: Subject<any> = new Subject<any>();

   
   readonly chartId: string;
   

   get stateInfo(): EtsPnLChartStateInfo {
      return this._stateInfo;
   }

   set stateInfo(value: EtsPnLChartStateInfo) {
      this._stateInfo = value;
      if (value) {
         if (value.panelId) {
            this.panelId = value.panelId;
         }
         this._stateChanged.emit();
      }
   }

   etsOnInit(): void {
      this._messageBus
         .of<SymbolHighlighted>('SymbolHighlighted')
         .pipe(
            takeUntil(this._unsubscriber),
            filter(msg => msg.scopeId === this.layoutTabId),
            filter(_ => this.isLinkedToSymbol),
         )
         .subscribe(msg => this.onSymbolHighlightedMessage(msg.payload));
   }

   
   onSymbolHighlightedMessage(x: SymbolHighlighted): void {
      const ti = this._tiService.getInstrumentByTicker(x.ticker);
      if (!ti) {
         return;
      }

      try {
         //
      } catch {
         //
      }
   }

   
   etsAfterViewInit(): void {
      if (!this._isChartInitialized) {
         this._initChart();
         this._isChartInitialized = true;
      }
   }

   
   etsOnDestroy(): void {
      const feed: any = this._datafeed;
      if (feed) {
         feed.dispose();
      }

      this._unsubscriber.next();
      this._unsubscriber.complete();
   }


   private _initChart(): void {
      this.isLoading = true;

      this._stateChanged.pipe(
         throttleTime(250),
         takeUntil(this._unsubscriber)
      ).subscribe(() => this.saveState());

      const key = getPanelStateKey(this);
      const state = this._userSettingsService.getValue<PnLChartState>(key);
      // let state: PnLChartState;
      // if (item) {
      //    try {
      //       state = JSON.parse(item);
      //    } catch (e) {
      //       this._toastr.error('"PnL Chart" panel was restored with errors');
      //       this._settingsService.removeItem(key);
      //       this.isLoading = false;
      //       return;
      //    }
      // }

      if (state) {
         this.stateInfo = state.subscriptions;
         this.isLinkedToSymbol = state.isLinkedToSymbol;
      }

      const containerId = `pnlchart_${this.chartId}`;

      const dataFeed = this._dataFeedFactory.getDataFeed(
         containerId,
         this.stateInfo.subscriptions,
         () => this._tvWidget);

      this._datafeed = dataFeed;

      const firstSubscription = this.stateInfo.subscriptions[0];

      const defaultTimezone = this._timestampsService.getDefaultTimezone('Etc/UTC') as Timezone;

      const widgetOptions: ChartingLibraryWidgetOptions = {
         symbol: firstSubscription.subscriptionId,
         datafeed: dataFeed,
         timezone: defaultTimezone,
         interval: '10S',
         container_id: containerId,
         library_path: '/assets/tv/charting_library/',
         locale: 'en',
         disabled_features: [
            'header_widget',
            'context_menus',
            'use_localstorage_for_settings',
            'create_volume_indicator_by_default',
            'delete_button_in_legend',
            'left_toolbar',
            'show_hide_button_in_legend'
         ],
         enabled_features: [
            'no_min_chart_width',
            'cl_feed_return_all_data',
            'seconds_resolution'
         ],
         charts_storage_url: 'https://saveload.tradingview.com',
         charts_storage_api_version: '1.1',
         client_id: 'ets',
         user_id: 'ets',
         fullscreen: false,
         autosize: true,
         theme: 'dark',
         saved_data: state && state.chartState ? state.chartState : undefined,
         studies_access: {
            type: 'white',
            tools: [
               { name: 'Sessions' },
               { name: 'Overlay' },
               { name: 'Compare' }
            ]
         },
         debug: true,
         overrides: {
            'mainSeriesProperties.style': 2
         },
         studies_overrides: { 'Overlay.style': 2 }
      };

      this._tvWidget = new widget(widgetOptions);

      this._tvWidget.onChartReady(() => {
         this.isLoading = false;

         this._tvWidget.subscribe('onAutoSaveNeeded', () => {
            this.saveState();
         });

         if (!state) {
            if (this.stateInfo.subscriptions.length > 1) {
               const rest = this.stateInfo.subscriptions.slice(1);
               rest.forEach(s => {
                  this._tvWidget.chart()
                     .createStudy('Overlay', true, false, [s.subscriptionId]);
               });
            }
         }
      });
   }


   protected getState(): any {

      const tvWidget = this._tvWidget;

      if (!tvWidget) { return; }
      
      let state: PnLChartState = null;

      tvWidget.save((s) => {
         state = { 
            subscriptions: this.stateInfo, 
            chartState: s, 
            isLinkedToSymbol: this.isLinkedToSymbol 
         };
      });

      return state;
   }


   protected setState(state) { }
}
