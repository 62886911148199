import { StrategyParameters } from 'projects/shared-components/strategies/strategy-parameters.enum';
import { AlgoDescriptionBuilderBase } from '../algo-description-builder-base.class';

export class InterestAlgoDescriptionBuilder extends AlgoDescriptionBuilderBase {
   
   getAlgoName(parameters: StrategyParameters) {
      return 'Interest';
   }
   
   getDescription(parameters: StrategyParameters) {
      return 'Interest Strategy';
   }
   
   getSymbolPropertyNames(): string[] {
      return ['symbol'];
   }

}
