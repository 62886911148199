import {HedgePosition} from "../../../hedging-grid/data-model/hedge-position";
import {ChangeDetectorRef} from "@angular/core";
import * as Enumerable from "linq";

export interface IHedgeToTrack {
    hedgeId: string;
    sweetPrice: number;
}

export class HedgeToTrack implements IHedgeToTrack {
    constructor(
        private readonly _positions : HedgePosition[],
        private readonly _changeDetector: ChangeDetectorRef
    ) {
    }

    get name() : string {
        return this._positions[0].label;
    }

    get color() : string {
        return this._positions[0].color;
    }

    get type(): 'Call' | 'Put' {
        return this._positions[0].type;
    }

    get hedgeId() : string {
        return this._positions[0].groupId;
    }

    get baseStrike() : number {
        if (this.type === 'Call') {
            return Enumerable.from(this.positions)
                .orderBy(x => x.strike)
                .first()
                .strike;
        } else {
            return Enumerable.from(this.positions)
                .orderByDescending(x => x.strike)
                .first()
                .strike;
        }
    }

    private _isSelected = false;
    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        this._isSelected = value;
        if (!this._isSelected) {
            this.sweetPrice = null;
        }
        setTimeout(() => this._changeDetector.detectChanges());
    }

    get positions() : HedgePosition[] {
        return this._positions;
    }

    sweetPrice : number;
}